import * as d3 from 'd3'

export default {

  methods: {
    /*
      load_Commissioning()
      draw_CommListBox()
    */

    load_Commissioning(step) {

      if(this.innerSvg) this.innerSvg.remove()
      if(this.innerSvg) this.commissioningList.remove()

      this.innerSvg = this.svg.append('svg')
      this.commissioningBox.moveToFront()

      // After loading the SVG, perform initialization
      this.loadSvg(
        this.innerSvg, {
        x   : 0,
        y   : 0,
        url : `/China1/Utility_Commissioning/svg/STEP-${step.no}.svg`
      }).then(() => {

        this.innerSvg
        .style('opacity', 0)
        .transition().duration(150).style('opacity', 1)

        let no_ = this.rawData.filter(f => f.step == step.no).length
        this.svg.select('#list_box_filter').transition().duration(150).attr('height', 5)
        this.svg.select('#list_box_background').transition().duration(150).attr('height', 5)
        
        setTimeout(() => {
          this.draw_CommListBox(step) // ---> below
        }, 200)

        this.rawData.filter(f => f.step == step.no).forEach((d,i) => {
          let xy_ = d3.select(`#PNT-${d.sn}`).attr('visibility', 'hidden')
          d.cx = xy_.attr('cx')
          d.cy = xy_.attr('cy')
        })
      })     
    },
    


    draw_CommListBox(step) {

      this.commissioningList = this.svg.append('svg')
      let ListCommItem = this.commissioningList
      let y_ = 292
      let gap = 30
      
      this.rawData.filter(f => f.step == step.no).forEach(d => {
        d.lineY = y_ + gap
        d.listY = y_+1

        ListCommItem
        .append("line")
        .attr('id', `Line${d.sn}`)
        .attr('x1', 25).attr('y1', 292 + gap)
        .attr('x2', 345).attr('y2', 292 + gap)
        .attr('stroke', '#bcbcbc').attr('stroke-width', 0.5).attr('opacity', 0)

        ListCommItem
        .append('g')
        .attr('id', `LIST_${d.sn}`)
        .attr('transform', `translate(25, ${292 + 1})`)
        .attr('opacity', 0).style('cursor', 'pointer')

        // When a menu in a menu box is clicked
        .on('click', () => {
          d3.select(`#TITLE-${d.sn}`)
          .transition().duration(350)
          .attr('transform', `translate(${d.cx}, ${d.cy})scale(0)`)

          .transition().delay(150).duration(350)
          .attr('transform', `translate(0, 0)scale(1)`)
        })
        .call(this.call_CommListBox, d, d.sn) // ---> Event.js

        d3.select(`#LIST_${d.sn}`)
        .append("rect")
        .attr('x', 0).attr('y', 0).attr('width', 320).attr('height', gap-2).attr('fill', '#fff')

        // Shadow effect to a menu box
        d3.select(`#LIST_${d.sn}`)
        .append("rect")
        .attr('x', 0).attr('y', 0).attr('opacity', 0.7)
        .attr('width', 7).attr('height', gap-2).attr('fill',`url(#${this.Legend[d.cat-1].name})`)

        d3.select(`#LIST_${d.sn}`)
        .append("text")
        .attr('x', 12).attr('y', (gap/2) + 4).attr('font-size',11).attr('fill','#000').text(d.name)

        y_ += gap
      })


      // Animation effects for commissioning menu header and list
      this.rawData.filter(f => f.step == step.no).forEach((d,i) => {

        d3.select(`#Line${d.sn}`)
        .transition().duration(100+(i*25))
        .attr('x1', 25).attr('y1', d.lineY)
        .attr('x2', 345).attr('y2', d.lineY)
        .attr('opacity', 1)

        d3.select(`#LIST_${d.sn}`)
        .transition().duration(100+(i*25))
        .attr('transform', `translate(25, ${d.listY})`)
        .attr('opacity', 1)
      })

      let no_ = this.rawData.filter(f => f.step == step.no).length
      this.svg.select('#list_box_filter').transition().duration(200)
      .attr('height', (no_ * gap ))
      this.svg.select('#list_box_background').transition().duration(200)
      .attr('height', (no_ * gap ))
    },

    
  }
}