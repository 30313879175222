import * as d3 from 'd3'

export default {
  methods: {

    drawForms() {

      this.svgGroup = this.svg
      .append('g')
      .attr('id', 'common_header_area')
      .attr('transform', 'translate(0, 0)')

      // China1 project logo 
      this.loadSvg(
        this.svgGroup, {
        x   : 30,
        y   : 30,
        url : '/China1/icons/3d_Logo.svg'
      })

      // title
      this.svgGroup
        .append('text')
        .attr('transform', `translate(40, 105)`)
        .style('font-family', 'roboto').style('font-size', 16).style('fill', '#333').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text('HCC 1 PROJECT - Level 3 Summary')

      this.svgGroup
        .append('text')
        .attr('transform', `translate(40, 130)`)
        .style('font-family', 'roboto').style('font-size', 13).style('fill', '#44A9DF').attr('text-anchor', 'start')
        .text('HCC - 2023-01-11 Rev 2')
    },

  }
}
           