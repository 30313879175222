import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs
export default {
  data: () => ({
    props:[
      {Lv3:'FL01', x:65, y:0},
      {Lv3:'KO01', x:30, y:50},
      {Lv3:'LD00DA', x:40, y:50},
      {Lv3:'RCxx', x:-20, y:50},
      {Lv3:'ME01', x:-20, y:50},
      {Lv3:'ME02', x:-20, y:50},
      {Lv3:'ME04', x:-20, y:50},
      {Lv3:'ME05', x:-20, y:50},
      {Lv3:'MW01', x:-20, y:50},
      {Lv3:'MW02', x:-20, y:50},
      {Lv3:'MW04', x:-20, y:50},
      {Lv3:'MW05', x:-20, y:50},
    ]
  }),
  methods: {
    Draw_Charts() {
      let svg = this.svg
      let Lv3Map = this.DataItems.map(d => d.GWBS_LV3)
      let props = this.props.filter(d => d.Lv3 == Lv3Map)[0]
      // console.log(Lv3Map)
      // console.log(props)
      svg
      .append('g')
      .attr('transform', `translate(20, 20)`)
      
     // Button & Label
      let buttonWrap = svg
      .append('g')
      .attr('id', 'button_wrapper')
      .attr('transform', `translate(0, 0)`)
      .style('font-family', 'roboto')
      .style('cursor', 'pointer') 
      .on('mouseover', function(){ 
        d3.select(this).transition().duration(100).style('opacity',.5) 
      })
      .on('mouseout', function(){ 
        d3.select(this).transition().duration(100).style('opacity',1) 
      })
      .on('click', () => {
        let request_ = this.getRequestColumnProps('Lv3', {}, this.ColumnProps)
        // console.log(request_)
        
        if(request_) this.$emit('request-action', request_)
        // let request_ = {
        //   dataType: 'row',    
        //   action: {
        //     type: 'direct',
        //     target: 'slide-modal',
        //     component: 'ServiceDashboard',
        //     id: 46,
        //     no: ''
        //   },
        //   filters: { 
        //     GWBS_LV3: this.GWBS_LV3,
        //     ...this.FilterValues,
        //     ...this.inFilters,
        //   },
        //   iFilters: {}
        // }
        // this.$emit('request-action', request_)
      })

      buttonWrap
      .append('rect')
      .attr('transform', `translate(0, 0)`)
      .attr('rx', 3)
      .attr('ry', 3)
      .attr('width', 40)
      .attr('height', 14)
      .attr('fill', '#e5e5e5')

      buttonWrap
      .append('text')
      .attr('transform', `translate(20, 8)`)
      .style('font-family', 'Roboto-Regular, Roboto')
      .style('font-size', 9)
      .style('fill', '#000')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text('More')
    },
  }
}
