// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  data:() => ({
    thead: [
      { key: 'SUMMARY'    , html: 'Summary'       , row: 1,rowspan: 2 ,hColor:'#424242', },
      { key: 'PLANNED'  , html: 'Planned<br>(B25)', row: 1,rowspan: 2 ,hColor:'#424242', },
      { key: '' , html: 'Actual'       , row:1,colspan: 2  ,hColor:'#424242', },
      { key: 'DAILY_CHANGE' , html: `Daily<br>Change`, row: 1,rowspan: 2  , },
      
      { key: 'ACTUAL_YESTERDAY'   , html: 'Last' , row: 2, color: '#DCEDC8'},
      { key: 'ACTUAL_TODAY' , html: 'Today' , row: 2,color: '#C8E6C9'},
    ],
    theadrows: 2,
    tbody: [
      { key: 'SUMMARY', w: 310, align: 'left' },
      { key: 'PLANNED', w: 60,},
      { key: 'ACTUAL_YESTERDAY', w: 60},
      { key: 'ACTUAL_TODAY', w: 60},
      { key: 'DAILY_CHANGE',  w: 60 },

      
    ],
    width: 500,

    selectedStartDate: null,
    selectedStartColumn: null,

    selectedFinishDate: null,
    selectedFinishColumn: null,

    filteredList: null,

    name: null,
    actualText1:null,
    actualText2:null
  }),
  methods: {
    Draw_Charts() {
      let svg = this.svg

      this.filteredList = this.DataItems

      let list = this.filteredList

      let this_ = this

      let TableNameD =  [...new Set(this.DataItems.map(d=> d.NAME))]

      this.name = TableNameD
      this.actualText1 = TableNameD == 'Daily' ? 'Yesterday' : 'Last Week'
      this.actualText2 = TableNameD == 'Daily' ? 'Today' : 'This Week'

      // console.log(this.name)

      let overallSummary = this.svg
      .append('g')
      .attr('class','moduleName')
      .attr('transform', `translate(0, 0)`)

      let group = svg.append('g').attr('class', 'ogm_table_group__')
      let table_group = group.append('g').attr('transform', `translate(0.5, 0)`)
      
      let table_obj = table_group.append('foreignObject')
        .attr('y',0)
        .attr('width', '100%')
        .attr('height', '300px')
        .append('xhtml:body')
        .style('position', 'fixed')

      let table = table_obj.append('table')
      .attr('id', 'commodity_summary_table_')
      .style('border-collapse', 'collapse')
      .style('font-size', '10px')
      .style('width', '420px')
      .style('table-layout', 'fixed')

      let colgroup = table.append('colgroup')
      this.tbody.forEach(k => {
        colgroup.append('col').style('width', k.w + 'px')
      })

      let thead = table.append('thead')
      for (let r = 1; r <= this.theadrows; r++) {
        let row = this.thead.filter(d=> d.row === r)

        let tr = thead.append('tr')

        row.forEach(item => {

          tr.append('th')
          .attr('class', 'ogm_table_group__th')
          .attr('rowspan', () => item.rowspan ? item.rowspan : '')
          .attr('colspan', () => item.colspan ? item.colspan : '')
          .style('border', 'solid 0.7px #eee')
          .style('height', '25px')
          .style('padding', '3px')
          .style('background', item.color ? item.color : '#FAFAFA')
          .style('color',item.hColor ? item.hColor : '#616161')
          .html(() => item.key == 'DAILY_CHANGE' ? `${this.name}<br>Change` : item.key == 'ACTUAL_YESTERDAY' ? `${this.actualText1}` :item.key == 'ACTUAL_TODAY' ? `${this.actualText2}` : item.html)
        })        
      }

      let tbodykeys = this.tbody

      let disc = ''
      
      let tbody = table.append('tbody').attr('id', 'commodity_summay_tbody_')

      let addClass = ''

      for (let r = 0; r < list.length; r++) {
        let tr = tbody.append('tr')

        tbodykeys.forEach((k, i) => {

          if (list[r][k.key] == disc) return
          else {
            if (i == 0) {
              disc = list[r][k.key]
            }
          }
          let td = tr.append('td')
            .style('border', 'solid 0.1px #eee')
            .style('height', '21px')
            .style('text-align', k.align ? k.align : 'center')
            .style('padding', '0 3px 0 3px')
            .html(list[r][k.key] ? list[r][k.key] : 0)

          if(i === 0) {
            td.style('white-space', 'nowrap')
              .style('overflow', 'hidden')
              .style('text-overflow', 'ellipsis')
              .attr('title', list[r][k.key])
          }
          // td.on('mouseover', () => tr.style('cursor', 'pointer'))
          // td.on('click', () => {
          //   // console.log(list[r].GWBS_LV3,list[r].WCCODE)
          //   let dataSource = {
          //     GWBS_LV3: list[r].GWBS_LV3,
          //     WCCODE: list[r].WCCODE
          //   }
          //   let request_ = this.getRequestColumnProps('status', dataSource, this.ColumnProps)
          //   this.$emit('request-action', request_)
          // })
          if(k.key.includes('CHANGE')) {
            td
            .style('color', list[r][k.key] > 0 ? '#64B5F6' :list[r][k.key] < 0 ? '#F44336' : '#BDBDBD')
            // .style('color', list[r][k.key] < 0 ? 'red' : list[r][k.key] === 0 ?  '#ccc' : '#000')
            .html(list[r][k.key] == null ? 0 : list[r][k.key] > 0 ? '▲ '+ list[r][k.key] : list[r][k.key] == 0 ? '- '+ list[r][k.key] :'▼ '+ list[r][k.key])

          }
          if(r === 0 && k.key.includes('CHANGE')&& i === 4) {
            // console.log(k.key)
            td
            .style('color', list[r][k.key] > 0 ? '#64B5F6' :list[r][k.key] < 0 ? '#F44336' : '#BDBDBD')
            // .html(list[r][k.key] == null ? 0 : list[r][k.key] > 0 ? '▲ '+ list[r][k.key] : list[r][k.key] == 0 ? '- '+ list[r][k.key] :'▼ '+ list[r][k.key])
            .html(list[r][k.key] == 0 ? '- '+ list[r][k.key] : list[r][k.key])
          } 

          // else {
          //   if (k.type == 'float') {
          //     let regexp = /\B(?=(\d{3})+(?!\d))/g
          //     if (isNaN(Math.floor(list[r][k.key]))) td.html('')
          //     else td.html(Math.round(Math.floor(list[r][k.key] * 100) / 100).toString().replace(regexp, ','))
          //   } else td.html(list[r][k.key] ? list[r][k.key] : 0)
          // }
        })
      }
    },
  }
}
