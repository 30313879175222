// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  data:() => ({
    thead: [
      { key: 'QCF_DESCR', html: 'Test Package', row: 1,  },
      { key: 'QCF', html: 'QCF', row: 1,  },
      { key: 'TOTAL', html: 'Total', row: 1, },
      { key: 'COMPLETED', html: 'Act.', row: 1, },
      { key: 'REMAIN', html: 'Rem.', row: 1, rowspan: 1, },
      { key: 'PROG', html: 'Prog(%)', row: 1, },
    ],
    theadrows: 1,
    tbody: [
      { key: 'QCF_DESCR', w: 220, align: 'left'},
      { key: 'QCF', w: 40, align: 'center'},
      { key: 'TOTAL', w: 40,type: 'float', align: 'center'},
      { key: 'COMPLETED', w: 40,type: 'float', align: 'center'},
      { key: 'REMAIN', w: 40, type: 'float', align: 'center'},
      { key: 'PROG', w: 70},
    ],
    width: 450,

    selectedStartDate: null,
    selectedStartColumn: null,

    selectedFinishDate: null,
    selectedFinishColumn: null,

    filteredList: null
  }),
  methods: {
    Draw_Charts() {
      let svg = this.svg

      this.filteredList = this.DataItems

      let list = this.filteredList

      let group = svg.append('g').attr('class', 'testpack_table_group__')
      let table_group = group.append('g').attr('transform', `translate(0.5, 0)`)
      
      let table_obj = table_group.append('foreignObject')
        .attr('y',0)
        .attr('width', '100%')
        .attr('height', '370px')
        .append('xhtml:body')
        .style('position', 'fixed')

      const reducer = (previousValue, currentValue) => previousValue + currentValue

      let widths = this.tbody.map(d => d.w)
      let sumWidth = widths.reduce(reducer)

      let table = table_obj.append('table')
      .attr('id', 'commodity_summary_table_')
      .style('border-collapse', 'collapse')
      .style('font-size', '10px')
      .style('width', `${sumWidth}px`)
      .style('table-layout', 'fixed')

      let colgroup = table.append('colgroup')
      this.tbody.forEach(k => {
        colgroup.append('col').style('width', k.w + 'px')
      })
      let thead = table.append('thead')
      for (let r = 1; r <= this.theadrows; r++) {
        let row = this.thead.filter(d=> d.row === r)
        let tr = thead.append('tr')

        row.forEach(item => {
          tr.append('th')
          .attr('class', 'ogm_table_group__th')
          .attr('rowspan', () => item.rowspan ? item.rowspan : '')
          .attr('colspan', () => item.colspan ? item.colspan : '')
          .style('height', '20px')
          .style('padding', '3px')
          .style('background', item.color ? item.color : '#FAFAFA')
          .html(item.html)
        })        
      }
      
      let tbodykeys = this.tbody
      
      let tbody = table.append('tbody').attr('id', 'testpack_summay_tbody_')

      let addClass = ''

      for (let r = 0; r < list.length; r++) {
        let tr = tbody.append('tr')

        tbodykeys.forEach((k, i) => {

          let td = tr.append('td')
            .style('border', 'solid 0.1px #eee')
            .style('height', '18px')
            .style('text-align', k.align ? k.align : 'center')
            .style('color', '#000000')
            .style('padding', '0 3px 0 3px')

          if(i === 0) {
              td.style('color','#000000')
              td.style('background-color', list[r][k.key] == 'Certificate' ? '#E3F2FD' : list[r][k.key] == 'Checksheet' ? '#F1F8E9' : '#FFF8E1')
            }
          if(i === 1) {
            td.style('white-space', 'nowrap')
              .style('overflow', 'hidden')
              .style('text-overflow', 'ellipsis')
              .attr('title', list[r][k.key])
          }

          if(i === 2) {
            td.style('color', '#9E9E9E')
          }
          if(i === 3) {
            td.style('color', '#4CAF50')
          }
          if(i === 4) {
            td.style('color', '#F44336')
              .attr('title', list[r][k.key])
          }

          if(k.key.includes('PROG')) {
            addClass = ''
            td
            .style('position', 'relative')
            .style('color', list[r][k.key] < 0 ? 'red' : list[r][k.key] === 100 ? '#fff' : addClass == 'caution' ? 'red' : '#000')
            .style('z-index', '1')
            .html(list[r][k.key] == null ? 0+'%' :list[r][k.key]+'%')

            td.append('div')
            .style('position', 'absolute')
            .style('left', 0)
            .style('top', 0)
            .style('width', list[r][k.key] > 0 ? list[r][k.key] + '%' : 0)
            .style('height', '17px')
            .style('background-color', list[r][k.key] < 100 ? k.key.includes('PLAN') ? '#E0E0E0' : '#FFCA28' : '#4CAF50')
            .style('z-index', '-1')
          }
          else {
            if (k.type == 'float') {
              let regexp = /\B(?=(\d{3})+(?!\d))/g
              if (isNaN(Math.floor(list[r][k.key]))) td.html('')
              else td.html(Math.round(Math.floor(list[r][k.key] * 100) / 100).toString().replace(regexp, ','))
            } else td.html(list[r][k.key])
          }
        })
      }
    },
  }
}
