import Defs from '../../../../../includes/primitives/colorDefs'
import * as d3 from 'd3'

export default {
  data: () => ({
    timeline: {
      startDate: '',
      endDate: '',
      width: null,
      scale: null,
      monthWidth: 22,

      values: { year: [], month: [], },
      float: { start: 0, end: 2, },
    },

    styles: {
      margin:{ top: 120, left: 360,},
      text: { year: 15, month: 9, },
    },

    barChart: {
      topMargin: 30,
      charts:{
        FSR: { color: 'gray', size: 2, },
        EXE: { color: '#83D2F5', size: 6, },
        CSU: { color: '#F7BACF', size: 4, },
      }
    },

    milestone: {
      text: 0,
      icon: {
        triangle: {path: 'M-8 -1 l-5 -10 h10 z', x: 8, y: 0},
        diamond: {path:'M-5-10l-5-5,5-5,5,5Z', x: 5, y: 10},
        water1: {path:'M7.889,2A3.892,3.892,0,0,0,4,5.889,4.3,4.3,0,0,0,5.64,9.058a7.682,7.682,0,0,1,1.2,1.186A6.749,6.749,0,0,1,7.889,12a6.749,6.749,0,0,1,1.05-1.756,7.682,7.682,0,0,1,1.2-1.186,4.3,4.3,0,0,0,1.64-3.169A3.892,3.892,0,0,0,7.889,2Zm0,1.659A2.229,2.229,0,1,1,5.659,5.889,2.229,2.229,0,0,1,7.889,3.659Z', x: -8.5, y: -13},
        flag: {path:'M9.81,17.45v-10h.563v10Zm.945-5.679V7.45L15.481,9.6Z', x: -11, y: -20},
        arrow: {path: 'M10.4,4.438l-.389.938-3.432,8.15-.389.912.925-.349L10.4,12.856l3.284,1.233.925.349-.389-.912-3.432-8.15Zm0,2.185,2.654,6.314L10.544,12l-.147-.054L10.249,12l-2.507.938Z', x: -10.5, y: -14},
      },
      margin: { top: 50, },                                        
    },

    cutoff: '',
    // y_Coordinate: 150,
  }),

  
  methods: {

    set_Gradient(){
      // set Gradient color for the Timeline
      let lightBlue = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `shadowTimeline`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      lightBlue.append('stop').attr('stop-color', '#3d3d3d').attr('offset', '0').attr('stop-opacity', 0.5)
      lightBlue.append('stop').attr('stop-color', '#fff').attr('offset', '1').attr('stop-opacity', 0.3)
      
      Defs.setDefs_Gradients(this.svg, 'timeline', 'LinearA4', 'LightGray', [0.5, 1.0, 0.5, -0.2], this.localId)
    },


  }
}
