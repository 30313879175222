// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  data: () => ({
    props: [
      { module: 'ME01', moduleDescr:'Flash Gas Compression',x: 100, y: 190 },
      { module: 'ME02', moduleDescr:'MEG & Fuel Gas',x: 100, y: 190 },
      { module: 'ME04', moduleDescr:'Power Generation',x: 120, y: 170 },
      { module: 'ME05', moduleDescr:'E/I Building',x: 100, y: 190 },
      { module: 'MW01', moduleDescr:'Reception Facilities & Production Separation',x: 100, y: 190 },
      { module: 'MW02', moduleDescr:'HC Dewpointing & Condensate Stabilisation',x: 100, y: 180 },
      { module: 'MW04', moduleDescr:'Power Generation',x: 120, y: 170 },
      { module: 'MW05', moduleDescr:'Utility',x: 100, y: 190 },
      { module: 'FL01', moduleDescr:'Flare Stack',x: 150, y: 150 },
      { module: 'KO01', moduleDescr:'Flare KO Drum',x: 100, y: 180 },
      { module: 'LD00DA', moduleDescr:'Laydown',x: 100, y: 100 },
      { module: 'RCxx', moduleDescr:'Topsides Piperack',x: 90, y: 180 },

      { module: 'HL02', x: 20, y: 170 },
      { module: 'HL03', x: 20, y: 170 },
      { module: 'LQ00', x: 80, y: 170 }
    ]
  }),
  methods: {
    Draw_Charts() {
      let data = null, style = null, tmp = null
      let overallSummary = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0.5)`)
      let module = this.FilterValues && this.FilterValues.GWBS_LV3 ? this.FilterValues.GWBS_LV3 : 'ME01'
      let props = this.props.filter(d => d.module === module)[0]

      overallSummary
      .append('text')
      .attr('transform', `translate(10,25)`)
      .style('font-size', 16).style('font-family', 'roboto').attr('fill', '#44a9df')
      .text(`${module}  Summary`)

      overallSummary
      .append('text')
      .attr('transform', `translate(10,385)`)
      .attr('text-anchor', 'start')
      // .attr('text-anchor', 'middle')
      .style('font-size', 13).style('font-family', 'roboto').attr('fill', '#0277BD')
      .text(props.moduleDescr)

      overallSummary
      .append('image')
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Modules/${module}.png`)
      // .attr('transform', props ? `translate(${props.x} ${props.y}) ` : `translate(40, 180) `)
      .attr('transform', `translate(${props.x} ${props.y}) `)

      // Overall Plan
      tmp = this.DataItems[0]
      style = {
        id: 'CERT',
        x: 110,
        y: 100,
        name: tmp.PLAN,
        sort: 'N',
      }
      data = [
        {title: 'Completed',  value: tmp.PLAN },
        {title: 'Remain',     value: 100-tmp.PLAN }
      ]
      let Circle = {
        CircleDiameter: 35,
        CircleColorSet: ['Gray', 'LightGray'],
        CircleHoleSize: 25,
        CircleStackSize: 2,
        CircleSliceSize: 5,
        CircleValueSize: 14,
        CircleTitleSize: 14,
        CircleLegendSpace: 80,
      }
      let Guideline = {
        GuideDisplay: 'N',
      }

      let chartSumamry = overallSummary.append('g').attr('transform', `translate(0, 10)`)


      chartSumamry
      .append('text')
      .attr('transform', `translate(${style.x}, ${style.y - Circle.CircleDiameter - 15})`)
      .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'middle')
      .text('Plan Cuml.(%)')

      this.Chart_PieSimple(chartSumamry, data, style, Circle, Guideline)

      style = {
        id: 'SHI',
        x: 285,
        y: 100,
        name: tmp.ACTUAL,
        sort: 'N',
      }
      data = [
        {title: 'Completed',  value: tmp.ACTUAL },
        {title: 'Remain',     value: 100-tmp.ACTUAL }
      ]
      Circle.CircleColorSet = ['LightBlue', 'LightGray']
      
      chartSumamry
      .append('text')
      .attr('transform', `translate(${style.x}, ${style.y - Circle.CircleDiameter - 15})`)
      .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'middle')
      .text('Actual Cuml.(%)')

      this.Chart_PieSimple(chartSumamry, data, style, Circle, Guideline)


      let box = chartSumamry
      .append('g')
      .attr('transform', `translate(160, 75)`)
      
      box
      .append('rect')
      .attr('rx', 4)
      .attr('ry', 4)
      .attr('width', 80)
      .attr('height', 50)
      .attr('fill-opacity', 0)
      .attr('stroke', '#dadada')
      .attr('stroke-width', .5)

      box
      .append('text')
      .attr('transform', `translate(40, 12)`)
      .style('font-size', 9)
      .style('font-family', 'roboto')
      .attr('fill', '#959595')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text('Cuml. Delta (%)')

      box
      .append('text')
      .attr('transform', `translate(40, 33)`)
      .style('font-size', 18)
      .style('font-family', 'roboto')
      .attr('fill', tmp.DELTA >= 0 ? '#1e88e5' : '#bf360c')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text(tmp.DELTA)
    },
  }
}
