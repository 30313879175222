import * as d3 from 'd3'

export default {
  data: () => ({
    resent: false,
  }),
  methods: {
    drawTagButtons() {
      let tagButton = this.svg
      .append('g')
      .attr('id', 'tag_button_wrapper')
      .attr('transform', `translate(1055, 93)`)
      .style('visibility', 'hidden')
      .style('font-size', 10)
      .style('font-family', 'roboto')

      let blockOnoffBtn = tagButton
      .append('g')
      .attr('class', 'block_onoff_btn_group')
      .attr('transform', `translate(0, 0)`)
      this.drawBlockButton(blockOnoffBtn)

      let tagOnoffBtn = tagButton
      .append('g')
      .attr('class', 'tag_onoff_btn_group')
      .attr('transform', `translate(103, 0)`)
      this.drawTagButton(tagOnoffBtn)

      let tagDescrOnoffBtn = tagButton
      .append('g')
      .attr('class', 'tagdescr_onoff_btn_group')
      .attr('transform', `translate(197, 0)`)
      this.drawTagDescrButton(tagDescrOnoffBtn)

      let listModalBtn = tagButton
      .append('g')
      .attr('class', '_list_modal_btn_group')
      .attr('transform', `translate(333, 0)`)
      this.drawListModalButton(listModalBtn)
    },
    drawBlockButton(selection) {
      selection
      .on('mouseover', (_, i, a) => { d3.select(a[i]).style('cursor', 'pointer') })
      .on('mouseout', (_, i, a) => { d3.select(a[i]).style('cursor', 'default') })
      .on('click', () => {
        let bg = d3.select('.blockonoff_btn_bg')
        let slider = d3.select('.blockonoff_btn_slider')
        let tagtext = d3.select('.blockonoff_btn_text')
        // console.log('BlockButton')

        if(tagtext.text() == 'ON') {//시작할때는 OFF 니까 ON이라면 OFF
          bg.transition().attr('fill', '#D3D3D3')
          slider.transition().attr('transform', 'translate(9, 9)')
          tagtext.transition().attr('transform', `translate(18, 12.5)`).style('fill', '#707070').text('OFF')

          d3.select('.NEW_BLOCK_NO').style('visibility', 'hidden')
          d3.select('#BLOCK_LINE').style('visibility', 'hidden')

        } else {
          bg.transition().attr('fill', '#4FBE79')
          slider.transition().attr('transform', 'translate(31, 9)')
          tagtext.transition().attr('transform', `translate(5, 12.5)`).style('fill', '#fff').text('ON')
          
          d3.select('.NEW_BLOCK_NO').style('visibility', 'visible')
          d3.select('#BLOCK_LINE').style('visibility', 'visible')
        }
      })

      selection
      .append('rect')
      .attr('class', 'blockonoff_btn_bg')
      .attr('width', 40)
      .attr('height', 18)
      .attr('fill', '#D3D3D3')
      .attr('rx', '10')

      selection
      .append('circle')
      .attr('class', 'blockonoff_btn_slider')
      .attr('transform', 'translate(9, 9)') //시작할때의 값
      .attr("r", 6)
      .attr('fill', '#fff')
      .on('click', this.closeEQPopup)

      selection
      .append('text')
      .attr('class', 'blockonoff_btn_text')
      .attr('transform', `translate(18, 12.5)`) //시작할때의 값
      .style('fill', '#707070')
      .text('OFF')

      selection
      .append('text')
      .attr('transform', `translate(45, 12.5)`)
      .style('fill', '#000')
      .text('Block No.')

    },
    drawTagButton(selection) {
      selection
      .on('mouseover', (_, i, a) => { d3.select(a[i]).style('cursor', 'pointer') })
      .on('mouseout', (_, i, a) => { d3.select(a[i]).style('cursor', 'default') })
      .on('click', (e) => {
        let bg = d3.select('.tagonoff_btn_bg')
        let slider = d3.select('.tagonoff_btn_slider')
        let tagtext = d3.select('.tagonoff_btn_text')
        // console.log('TagButton')

        if(tagtext.text() == 'ON') {
          bg.transition().attr('fill', '#D3D3D3')
          slider.transition().attr('transform', 'translate(9, 9)')
          tagtext.transition().attr('transform', `translate(18, 12.5)`).style('fill', '#707070').text('OFF')

        } else {
          bg.transition().attr('fill', '#4FBE79')
          slider.transition().attr('transform', 'translate(31, 9)')
          tagtext.transition().attr('transform', `translate(5, 12.5)`).style('fill', '#fff').text('ON')
        }
        
        setTimeout(() => { this.tagBTAction('TAG', tagtext.text()) })
      })

      selection
      .append('rect')
      .attr('class', 'tagonoff_btn_bg')
      .attr('width', 40)
      .attr('height', 18)
      .attr('fill', '#D3D3D3')
      .attr('rx', '10')

      selection
      .append('circle')
      .attr('class', 'tagonoff_btn_slider')
      .attr('transform', 'translate(9, 9)')
      .attr("r", 6)
      .attr('fill', '#fff')
      .on('click', this.closeEQPopup)

      selection
      .append('text')
      .attr('class', 'tagonoff_btn_text')
      .attr('transform', `translate(18, 12.5)`)
      .style('fill', '#707070')
      .text('OFF')

      selection
      .append('text')
      .attr('transform', `translate(45, 12.5)`)
      .style('fill', '#000')
      .text('Tag No.')

    },
    drawTagDescrButton(selection) {
      selection
      .on('mouseover', (_, i, a) => { d3.select(a[i]).style('cursor', 'pointer') })
      .on('mouseout', (_, i, a) => { d3.select(a[i]).style('cursor', 'default') })
      .on('click', () => {
        let bg = d3.select('.tagDescronoff_btn_bg')
        let slider = d3.select('.tagDescronoff_btn_slider')
        let tagtext = d3.select('.tagDescronoff_btn_text')
        console.log('DescrButton')
        
        if(tagtext.text() == 'ON') {
          bg.transition().attr('fill', '#D3D3D3')
          slider.transition().attr('transform', 'translate(9, 9)')
          tagtext.transition().attr('transform', `translate(18, 12.5)`).style('fill', '#707070').text('OFF')

        } else {
          bg.transition().attr('fill', '#4FBE79')
          slider.transition().attr('transform', 'translate(31, 9)')
          tagtext.transition().attr('transform', `translate(5, 12.5)`).style('fill', '#fff').text('ON')
        }
        
        setTimeout(() => { this.tagBTAction('TAG_DESCR', tagtext.text()) })
      })

      selection
      .append('rect')
      .attr('class', 'tagDescronoff_btn_bg')
      .attr('width', 40)
      .attr('height', 18)
      .attr('fill', '#D3D3D3')
      .attr('rx', '10')

      selection
      .append('circle')
      .attr('class', 'tagDescronoff_btn_slider')
      .attr('transform', 'translate(9, 9)')
      .attr("r", 6)
      .attr('fill', '#fff')
      .on('click', this.closeEQPopup)

      selection
      .append('text')
      .attr('class', 'tagDescronoff_btn_text')
      .attr('transform', `translate(18, 12.5)`)
      .style('fill', '#707070')
      .text('OFF')

      selection
      .append('text')
      .attr('transform', `translate(47, 12.5)`)
      .style('fill', '#000')
      .text('Tag Description')
    },
    drawListModalButton(selection) {
      selection
      .on('mouseover', (_, i, a) => { d3.select(a[i]).style('cursor', 'pointer') })
      .on('mouseout', (_, i, a) => { d3.select(a[i]).style('cursor', 'default') })
      .on('click', () => {
        // let request_ = this.getRequestColumnProps(colName, dataSource, this.ColumnProps)
        // if(request_) this.$emit('request-action', request_)

        // console.log(this.currentLevel) 로 들어오는지 확인
        
        let dataSource = { LV4: this.currentLevel }
        // let request_ = {
        //   dataType: 'row',
        //   action: {
        //     type: 'direct',
        //     target: 'slide-modal',
        //     component: 'ServiceDatatable',
        //     id: 166,
        //     no: ''
        //   },
        //   filters: {
        //     LV4: this.currentLevel
        //   },
        //   iFilters: {}
        // }
        let request_ = this.getRequestColumnProps('roomlevel', dataSource, this.ColumnProps)
        this.$emit('request-action', request_)
      })
      
      selection
      .append('rect')
      .attr('width', 80)
      .attr('height', 18)
      .attr('fill', '#58585B')
      .attr('rx', '3')
      .on('click', this.closeEQPopup)

      selection
      .append('text')
      .attr('transform', `translate(6.5, 12.5)`)
      .style('fill', '#fff')
      .text('Equipment List')

    },
    showTagButtons() {
      d3.select('#tag_button_wrapper').style('visibility', 'visible')
    },
    hideTagButtons() {
      d3.select('#tag_button_wrapper').style('visibility', 'hidden')
    },
    tagBTAction(colName, action) { //200812 TAG일때 TAG_DESCR 보여주고 TAG_DESCR 일때 TAG보여줌
      if(this.resent) {
        this.resent = false
        return
      }

      if(colName == 'TAG') {
        if(d3.select('.tagDescronoff_btn_text').text() == 'ON') {
          this.resent = true
          d3.select('.tagdescr_onoff_btn_group').dispatch('click', { second: true })
        }
      } else {
        if(d3.select('.tagonoff_btn_text').text() == 'ON') {
          this.resent = true
          d3.select('.tag_onoff_btn_group').dispatch('click', { second: true })
        }
      }

      if(action == 'ON') {
        setTimeout(() => {
          d3.selectAll(`[class*="eq_tag__"]`).each((_, i, a) => {
            // Separator: __, Class: [header]__[LV4]__[SN]
            let name = d3.select(a[i]).attr('class').split('__')
            let data = this.dataSet.find(d => d.LV4 == name[1] && d.SN == parseInt(name[2]))
            d3.select(a[i]).text(data[colName])
          })
        }, 100)
      } else {
        d3.selectAll(`[class*="eq_tag__"]`).each((_, i, a) => { d3.select(a[i]).text('') })
      }
    },
  }
}