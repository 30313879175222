export default {
  width: 100,
  gab: 10,
  attrs: [
    { 
      text: 'Total',
      refColumn: 'TOTAL',
      fColor: '#F7BACF', 
      tColor: '#FF75A5'
    },
    { 
      text: 'Not Started',
      refColumn: 'NOTSTARTED',
      fColor: '#BDBCBC', 
      tColor: '#818181'
    },
    { 
      text: 'Started',
      refColumn: 'STARTED',
      fColor: '#FFDA00',
      tColor: '#FAAA18'
    },
    { 
      text: 'Issued',
      refColumn: 'ISSUED',
      fColor: '#82B22E', 
      tColor: '#4CAE4E'
    },
  ]
}
