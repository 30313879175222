import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {  
    Draw_Charts() {
      
      let data = null
      let procedures = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0)`)

      let style = {
        CircleCenterX: 150,
        CircleCenterY: 250,

        ra: 80,
        angleSize: 180,
        angleStart: 270,
        angleEnd: 450,

        bgColor: '#F4F5F5',
        ftColor: '#44A9DF',
        stroke: 25,

        needleColor: '#333',
        proColor: '#F7BACF',

        percentage: this.dataSet[0].PROG,
      }
      this.Chart_Gauge_01(procedures, style) // ---------------------------->
      
      procedures
      .append('text')
      .attr('transform', `translate(150,50)`).style('font-family', 'roboto').style('font-size', 12)
      .attr('fill', '#333').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(`${this.dataSet[0].TYPE}`)

      procedures
      .append('text')
      .attr('transform', `translate(150,80)`).style('font-family', 'roboto').style('font-size', 11)
      .attr('fill', '#757575').attr('text-anchor', 'end').attr('alignment-baseline', 'end').text('Total')

      procedures
      .append('text')
      .attr('transform', `translate(150,96)`).style('font-family', 'roboto').style('font-size', 11)
      .attr('fill', '#757575').attr('text-anchor', 'end').attr('alignment-baseline', 'end').text('Completed')

      procedures
      .append('text')
      .attr('transform', `translate(150,112)`).style('font-family', 'roboto').style('font-size', 11)
      .attr('fill', '#757575').attr('text-anchor', 'end').attr('alignment-baseline', 'end').text('Remain')

      procedures
      .append('text')
      .attr('transform', `translate(155,80)`).style('font-family', 'roboto').style('font-size', 11)
      .attr('fill', '#44A9DF').attr('text-anchor', 'start').attr('alignment-baseline', 'end').text(this.dataSet[0].TOTAL)

      procedures
      .append('text')
      .attr('transform', `translate(155,96)`).style('font-family', 'roboto').style('font-size', 11)
      .attr('fill', '#44A9DF').attr('text-anchor', 'start').attr('alignment-baseline', 'end').text(this.dataSet[0].ACTUAL)

      procedures
      .append('text')
      .attr('transform', `translate(155,112)`).style('font-family', 'roboto').style('font-size', 11)
      .attr('fill', '#44A9DF').attr('text-anchor', 'start').attr('alignment-baseline', 'end').text(this.dataSet[0].REMAIN)
    },
  }
}
