export default {
  watch: {
    // Canvas: { handler(val) { if(!val || Object.keys(val).length === 0) return; this.register(this.draw); }, deep: true },
    // Text: { handler(val) { if(!val || Object.keys(val).length === 0) return; this.register(this.draw); }, deep: true },
    Queries: { handler(val) { if(!val || Object.keys(val).length === 0) return; this.drawSummary(this.cmmSharedArea); }, deep: true },
    // DataItems: { handler(val) { if(!val || val.length === 0) return; this.register(this.draw); }, deep: true },

    selectedItems() {
      this.$emit('selectedItems', [ ...this.selectedItems ])
    },
    FilterValues: {
      handler() { this.search() },
      deep: true
    },

    // callback will be called from the popup-free modal (EqFilteredList)
    callback: {
      handler(d) {
        if(!d|| Object.keys(d).length === 0) return
        
        let data_ = JSON.stringify(d)
        if(this.prevcallback == data_) return

        this.prevcallback = data_
        
        this.indicatorMoveTo(d)
        // declared in the Declare.mixin //
        this.callHLSPopup(d, ['equipment-search', 'share'])
      },
      deep: true
    },
  },
}
