import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  data: () => ({
  }),

  methods: {
    Canvas_Division_Lv3(lv1, lv2) {
      
      this.CANVAS = this.svg.append('svg')

      this.CANVAS
      .attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight)
      .style('opacity', 1)

      let style = null

      this.CANVAS
      .append('image')
      .attr('transform', `translate(0,-11)`)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Canvas/Division_Lv3_${lv2}.svg`)
      .style('pointer-events', 'none')

      // console.log(lv1, lv2)
   
      // Block Status
      this.BlockStatus_Coordinate.filter(f=> f.Lv1 == lv1 && f.Lv2 == lv2 && f.Lv3 !== 'OVERALL').forEach((prog,sn) => {
        // console.log(this.Queries.MasterProg)
        let itemSet = []
        style = {
          x: prog.x,
          y: prog.y,
          headTextSize: 16,
          headTitle: prog.NAME,
          headDescr: prog.SUBNAME,
          prog: this.Queries.MasterProg.find(f=> f.Lv1 == prog.Lv1 && f.Lv2 == prog.Lv2 && f.Lv3 == prog.Lv3),
        }
        this.Status.forEach((d,i) => {
          itemSet.push({
            name: d.title,
            total: this.dataSet.filter(f => f.Lv1 == prog.Lv1 && f.Lv2 == prog.Lv2 && f.Lv3 == prog.Lv3 && f.EREC == 'Y').length,
            actual: this.dataSet.filter(f => f.Lv1 == prog.Lv1 && f.Lv2 == prog.Lv2 && f.Lv3 == prog.Lv3 && f.EREC == 'Y' && f.STATUS == d.code).length,
            bColor: this.Status[i].bColor,
            opacity: this.Status[i].opacity,
          })
          
        })
        this.blockStatus(itemSet)
        this.Bar_Quantity_01(this.CANVAS, itemSet, style, sn) // <---- Chart Library
      })
      if(lv1 == 'HULL'){
        this.drawProgress(this.CANVAS, lv2 == 'HULL-FOR' ? 'HULL-FOR' : (lv2 == 'LQ' ? 'LQ' : 'HULL-AFT'))
      }else if(lv1 == 'TOPSIDE'){
        this.drawProgress(this.CANVAS, lv2 == 'MODULES' ? 'MODULES' : 'COMMON')
      }
    },
  }
}