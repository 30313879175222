import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  data: () => ({
    modules_group1: ['MW01', 'MW02', 'MW04', 'MW05'],
    modules_group2: ['ME01', 'ME02', 'ME04', 'ME05'],
  }),
  methods: {
    Draw_Charts() {
      const modules_g1 = this.modules_group1
      const modules_g2 = this.modules_group2

      const list = this.DataItems

      let distance = 155
      let rectHeight = 140
      let imgWidth = 180
      let imgHeight = 140

      let itemBox = this.svg
      .append('g')
      .attr('class', 'itemImageBox')
      .attr('transform', `translate(0, 30)`)

      itemBox
      .append('rect')
      .attr('transform', `translate(0.5, -30)`)
      .attr('width', 540)
      .attr('height', 730)
      .attr('stroke', '#E0E0E0')
      .attr('stroke-width', .5)
      .style('fill', '#fff')

      itemBox
      .append('rect')
      .attr('width', 210)
      .attr('height', 30)
      .attr('transform', `translate(10, -40)`)
      .attr('fill', '#ffffff')

      itemBox
      .append('text')
      .attr('transform', `translate(15, -24)`)
      .style('font-family', 'roboto')
      .style('font-size', 16)
      .style('fill', '#757575')
      .style('font-weight', 'bold')
      .text('Topsides Modules Preview')

      let itemBoxWrap = itemBox
      .append('g')
      .attr('class', 'itemImageBoxWrap')
      .attr('transform', `translate(30, 30)`)

      let itemBox_g1 = itemBoxWrap

      modules_g1.forEach((module, i) => {
        
        itemBox_g1
        .append('rect')
        .attr('transform', `translate(0, ${i*distance})`)
        .attr('width', 23).attr('height', rectHeight).attr('fill', '#B3E5FC')

        itemBox_g1
        .append('text')
        .attr('transform', `translate(13, ${i*distance + (rectHeight / 2)} )rotate(-90)`)
        .style('font-size', 11)
        .style('font-family', 'roboto')
        .attr('fill', '#000')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(module)

        let img = itemBox_g1
        .append('image')
        .attr('id', `Image___${this.localId}`)
        .attr('clip-path', 'url(#mask_image)')
        .attr('preserveAspectRatio', 'none')
        .attr('transform', `translate(30, ${distance * i})`)
        .style('filter', 'url(#dropshadow)')
        
        let findModule = list.find(d => d.GWBS_LV3 == module)

        if (findModule) {
          img.attr('href', findModule.DATAURL)
          .attr('height', imgHeight)
          .attr('width', imgWidth) 

          .on('click', () => {
            let request_ = {
              dataType: 'row',
              action: {
                type: 'direct',
                target: 'imgPopup',
                component: 'JModalOverlayImgPopup',
                id: 1,
                no: ''
              },
              imgPath: findModule.PATH,
              dimention: findModule.DIMENTION
            }
            this.$emit('request-action', request_)
            //--ataglance에서 dashboard.onrequestation.mixin.js에 넣어줘야한다 dashboard page에서는 img popup이 뜨게 되어있지않음 (note: planning topsides modules images popup add)
          })
        }
        else {
          // img.attr('xlink:href', require('../../../../../../src/assets/svg/img/noimage.png'))
          itemBox_g1
          .append('text')
          .attr('transform', `translate(${imgWidth/2+20}, ${distance * i+75})`)
          .style('font-size', 20)
          .style('fill', '#d1d1d1')
          .style('font-weight',500)
          .attr('text-anchor', 'middle')
          .text('No Image')
        }
      })

      let itemBoxWrap2 = itemBox
      .append('g')
      .attr('class', 'itemImageBoxWrap2')
      .attr('transform', `translate(300, 30)`)
      let itemBox_g2 = itemBoxWrap2
      modules_g2.forEach((module, i) => {
        
        itemBox_g2
        .append('rect')
        .attr('transform', `translate(0, ${i*distance})`)
        .attr('width', 23).attr('height', rectHeight).attr('fill', '#B3E5FC')

        itemBox_g2
        .append('text')
        .attr('transform', `translate(13, ${i*distance + (rectHeight / 2)} )rotate(-90)`)
        .style('font-size', 11)
        .style('font-family', 'roboto')
        .attr('fill', '#000')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(module)

        let img = itemBox_g2
        .append('image')
        .attr('id', `Image___${this.localId}`)
        .attr('clip-path', 'url(#mask_image)')
        .attr('preserveAspectRatio', 'none')
        .attr('transform', `translate(30, ${distance * i})`)
        .style('filter', 'url(#dropshadow)')
        
        let findModule = list.find(d => d.GWBS_LV3 == module)
        if (findModule) {
          img.attr('href', findModule.DATAURL).attr('height', imgHeight).attr('width', imgWidth)
          // let imgWidthD = findModule.DIMENTION.split(',')[0]
          // let imgHeightD = findModule.DIMENTION.split(',')[1]
          
          // if (imgHeightD > imgWidthD) img.attr('width', imgWidth)
          // if (imgHeightD < imgWidthD) img.attr('height', imgHeight)
          .on('click', () => {
            let request_ = {
              dataType: 'row',
              action: {
                type: 'direct',
                target: 'imgPopup',
                component: 'JModalOverlayImgPopup',
                id: 1,
                no: ''
              },
              imgPath: findModule.PATH,
              dimention: findModule.DIMENTION
            }
            this.$emit('request-action', request_)
          })
        }
        else {
          // img.attr('xlink:href', require('../../../../../../src/assets/svg/img/noimage.png'))
          itemBox_g2
          .append('text')
          .attr('transform', `translate(${imgWidth/2+20}, ${distance * i+75})`)
          .style('font-size', 20)
          .style('fill', '#d1d1d1')
          .style('font-weight',500)
          .attr('text-anchor', 'middle')
          .text('No Image')
        }
      })
    }
  }
}
