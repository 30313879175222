import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {
    
    Draw_Charts() {
      let data = null, 
          style = null, 
          cellX = null, 
          tmp = null, 
          Circle = null, 
          Guideline = null,
          item = null

      let overallMCStatus = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0)`)

      let maxValue =Math.max(...this.DataItems.map(d=>d.DONE_PROG), ...this.DataItems.map(d => d.NOT_DONE_PROG))
      maxValue = this.getMaxBound(maxValue)

      let barScale = d3.scaleLinear()
      .domain([0, maxValue])
      .range([130, 0])

      let overallPriority = this.svg
      .append('g')
      .attr('transform', `translate(40.5, 134.5)`)

      
      for(var i=0; i<6; i++) {

        overallPriority
        .append('text')
        .attr('transform', `translate(${10}, ${barScale((maxValue/5)*i)})`)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#bcbcbc').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text((maxValue/5)*i+'%')
      }

      // -------------------------------------------Tube Chart

      let priority = [...new Set(this.DataItems.map(d => d.PRIORITY))]
      let chartX = 0

      priority.forEach((p,i) => {
      data = this.DataItems.find(f => f.PRIORITY == p)

      style = {
        text:p,
        x:        40,
        y:        120,
        color:    'LightBlue',
        height:   130,
        tickness: 50,
        base:   { tSize: 13, height: 40, padding: 30,},
        legend: { actualColor:'#44A9DF', remainColor: '#EC407A', valueSize: 11, tSize: 10},
        value:  {TOTAL:data.TOTAL, ACTUAL:data.DONE, REMAIN:data.NOT_DONE, PROG:data.DONE_PROG}
      }
      let group = overallMCStatus.append('g').attr('class', `group_${p}_`).attr('transform', `translate(${chartX}, 0)`)

      this.Chart_Tube_02(group, style) // ---------------------------> 
      chartX += 100
      })

      // -------------------------------------------Table
      overallPriority
      .append('line').attr('x1', -36).attr('x2', 600).attr('y1', 220).attr('y2', 220)
      .attr('stroke', '#616161').attr('stroke-width', 0.2)
      overallPriority
      .append('line').attr('x1', -36).attr('x2', 600).attr('y1', 242).attr('y2', 242)
      .attr('stroke', '#616161').attr('stroke-width', 0.2)
      overallPriority
      .append('line').attr('x1', -36).attr('x2', 600).attr('y1', 264).attr('y2', 264)
      .attr('stroke', '#616161').attr('stroke-width', 0.2)

      overallPriority
      .append('line').attr('x1', 16).attr('x2', 16).attr('y1', 220).attr('y2', 264)
      .attr('stroke', '#616161').attr('stroke-width', 0.2)
      // overallPriority
      // .append('line').attr('x1', 85).attr('x2', 85).attr('y1', 220).attr('y2', 260)
      // .attr('stroke', '#616161').attr('stroke-width', 0.2)
      // overallPriority
      // .append('line').attr('x1', 164).attr('x2', 164).attr('y1', 220).attr('y2', 260)
      // .attr('stroke', '#616161').attr('stroke-width', 0.2)
      // overallPriority
      // .append('line').attr('x1', 240).attr('x2', 240).attr('y1', 220).attr('y2', 260)
      // .attr('stroke', '#616161').attr('stroke-width', 0.2)
      // overallPriority
      // .append('line').attr('x1', 324).attr('x2', 324).attr('y1', 220).attr('y2', 260)
      // .attr('stroke', '#616161').attr('stroke-width', 0.2)
      // overallPriority
      // .append('line').attr('x1', 410).attr('x2', 410).attr('y1', 220).attr('y2', 260)
      // .attr('stroke', '#616161').attr('stroke-width', 0.2)

      overallPriority
      .append('text').attr('transform', `translate(${10}, 232)`)
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#EC407A').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
      .text('Not Done')

      overallPriority
      .append('text').attr('transform', `translate(${10}, 254)`)
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#616161').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
      .text('Done')
    },
  }
}