import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  data: () => ({
  }),

  
  methods: {

    Arrow_Phase(selection, _st, _len, _color, _height, _arrowSize, _opacity) {
      selection
      .append('path')
      .attr('d', `M${_st}, 0 
                  L${_st + _len - _arrowSize}, 0 
                  L${_st + _len}, ${_height/2}   
                  L${_st + _len - _arrowSize}, ${_height} 
                  L${_st}, ${_height} 
                  L${_st + _arrowSize}, ${_height/2} Z
      `)
      .attr('fill', _color).style('opacity', _opacity)
    },

    Arrow_PhaseTrans(selection, _st, _len, _color, _height, _arrowSize, _opacity, _duration) {
      selection
      .append('path')
      .attr('d', `M${_st}, 0 
                  L${_st - _arrowSize}, 0 
                  L${_st}, ${_height/2}   
                  L${_st - _arrowSize}, ${_height}   
                  L${_st}, ${_height}  
                  L${_st + _arrowSize}, ${_height/2} Z
      `)
      .attr('fill', _color).style('opacity', _opacity)
      .transition().duration(_duration)
      .attr('d', `M${_st}, 0 
                  L${_st + _len - _arrowSize}, 0 
                  L${_st + _len}, ${_height/2}  
                  L${_st + _len - _arrowSize}, ${_height}   
                  L${_st}, ${_height}  
                  L${_st + _arrowSize}, ${_height/2} Z
      `)
    },
    Arrow_MilestoneProg(selection, _st, _len, _prog, _height, _arrowSize, _condition, _duration) {
      selection
      .append('path')
      .attr('d', `M${_st}, 0 
                  L${_st - _arrowSize}, 0 
                  L${_st}, ${_height/2}   
                  L${_st - _arrowSize}, ${_height}   
                  L${_st}, ${_height}  
                  L${_st + _arrowSize}, ${_height/2} Z
      `)
      .attr('fill',() =>{
        if(_prog < 30) return '#FF0000'
        if(_prog < 50) return '#E65100'
        if(_prog < 70) return '#FFD452'
        if(_prog < 90) return '#FFFF00'
        if(_prog <= 100) return '#80BC26'
      })
      .attr('stroke', _condition && _prog ? '#FF0000' : '')

      .transition().duration(_duration)
      .attr('d', `M${_st}, 0 
                  L${_st + _len - _arrowSize}, 0 
                  L${_st + _len}, ${_height/2}  
                  L${_st + _len - _arrowSize}, ${_height}   
                  L${_st}, ${_height}  
                  L${_st + _arrowSize}, ${_height/2} Z
      `)
    },

    Progress_Icon(selection, _num, _x, _y, _scale) {
      
      selection
      .append('polygon')
      .attr('id', 'Mask_MW01')
      .attr('transform', `translate(${_x}, ${_y}) scale(${_scale})`)
      .attr('points', () => {
        if (_num > 0)   return `17,25 17,13 23,13 13,1 3,13 9,13 9,25`
        if (_num == 0)  return `23,16 3,16 3,10 13.9,10 23,10`
        if (_num < 0)   return `9,1 9,13 3,13 13,25 23,13 17,13 17,1`
      })
      .attr('fill', () => {
        if (_num > 0)  return `#4CAE4E`
        if (_num == 0) return `#bcbcbc`
        if (_num < 0)  return `#EC407A`
      })

    },

  }
}
