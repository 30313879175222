<template>
  <div class="j_dashboard_container_viewer__" :style="styleContainer">
    <div class="header" :class="classHeader" :style="styleHeader">{{ container.title }}</div>
    <component
      v-for="chart in chartItems" 
      v-bind="vBinds[chart.ChartNo]"
      class="chart"
      :ref="`__ref__chart_item_${chart.ChartNo}`"
      :mode-export="modeExport"
      :ChartType="chart.ChartType"
      :is="CAT_CODE_CHART_COMPONENT(chart)"
      :key="chart.index"
      :style="styleChart(chart.ChartNo)"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import '@/assets/stylus/ui/component/_jDashboardContainerViewer.styl'
import __C from '@/primitives/_constant_'
import { ApplicationService } from '@/services'
import JLibChartComponents from '@/components/JLibChartComponents'

export default {
  name: "j-dashboard-container-viewer",
  components: {
    ...JLibChartComponents
  },
  props: {
    modeExport: Boolean,
    filters: {
      type: Object,
      default: () => ({})
    },
    target: {
      type: Object,
      default: () => ({
        code: 'Modal',  // Page | Modal
        type: 'viewer', // viewer | export
        id: Number,
        no: String
      })
    },
  },
  data: () => ({
    // Service Instances -------------
    appService: null,
    
    // general data props ------------
    container: {},
    chartItems: [],

    vBinds: {}
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']),

    __C_() { return __C },
  
    classHeader() {
      let class_ = []

      if(this.container.fontStyle) class_.push(this.container.fontStyle)
      if(class_.length > 0) return class_.join(' ')
      return ''
    },
    styleContainer() {
      let style_ = []
      if(this.container.width) style_.push(`width: ${this.container.width}px;`)
      if(this.container.height) style_.push(`height: ${this.container.height}px;`)
      if(this.container.borderWeight && this.container.borderColor) style_.push(`border: solid ${this.container.borderWeight}px ${this.container.borderColor};`)
      if(this.container.fillColor) style_.push(`background-color: ${this.container.fillColor};`)

      if(style_.length > 0) return style_.join(' ')
      return ''
    },
    styleHeader() {
      let style_ = []

      if(this.container.titleX) style_.push(`left: ${this.container.titleX}px;`)
      if(this.container.titleY) style_.push(`top: ${this.container.titleY}px;`)
      if(this.container.font) style_.push(`font-family: ${this.container.font};`)
      if(this.container.fontSize) style_.push(`font-size: ${this.container.fontSize}px;`)
      if(this.container.color) style_.push(`color: ${this.container.color};`)

      if(style_.length > 0) return style_.join(' ')
      return ''
    },
  },
  watch: {
    target: {
      handler(val) {
        if(!val) return
        this.getContainer()
      }
    }
  },
  created() {
    this.appService = new ApplicationService()
  },
  mounted() {
    if(this.target.id) this.getContainer()
  },
  methods: {
    CAT_CODE_CHART_COMPONENT(chart) {
      let componentName__ = {
        DNT: () => 'JLibChartDonut',
        PIE: () => 'JLibChartPie',
        AXS: () => 'JLibChartBarAxis',
        SLD: () => 'JLibChartBarSolid',
        PRO: () => 'JLibChartBarProgress',
        DLT: () => 'JLibChartBarDelta',
        HIS: () => 'JLibChartHistogram',
        RAD: () => 'JLibChartRadar',
        SKY: () => 'JLibChartSkyline',
        TSM: () => 'JLibChartTableSummary',
        ICC: () => 'Icicle',
        GAU: () => 'Gauge',
        GNT: () => 'Gantt',
        CUS: () => chart.UrlEntrance,
        LIBSVGCC: () => chart.UrlEntrance
      }
      return componentName__[chart.CatCode]()
    },

    getCustomComponent(chartNo) {
      let chartData = this.chartItems.find(chartData_ => chartData_.ChartNo == chartNo)
      if(!chartData) return ''
      return chartData.UrlEntrance
    },
    getContainer() {
      let params_ = new URLSearchParams()
      params_.append('idx', this.target.id)
      params_.append('containerNo', this.target.no)
      params_.append('fValues', this.filters.filters ? JSON.stringify(this.filters.filters) : '')
      params_.append('iFilters', this.filters.iFilters ? JSON.stringify(this.filters.iFilters) : '')
   
      this.appService.getContainer(params_, (res) => {
        this.container = {}
        this.chartItems = []

        if(res.chartItems.length === 0) return
        
        setTimeout(() => {
          this.container = res.container
          this.chartItems = res.chartItems
          setTimeout(() => { this.setBinds() })
        })
      })
    },
    setBinds() {
      let vBinds__ = {}

      this.chartItems.forEach(chart_ => {
        let props_ = this.setChartPropsData(chart_)
        // Not pass the iFilters. iFilters will be defined as every charts own.
        props_.FilterValues = this.filters
        vBinds__[chart_.ChartNo] = props_
      })

      this.vBinds = vBinds__
    },
    setChartPropsData(chart) {
      let ref_ = `__ref__chart_item_${chart.ChartNo}`
      
      if(!this.$refs[ref_]) return null  
      if(typeof this.$refs[ref_][0] == 'undefined') return null
      if(typeof this.$refs[ref_][0].$options == 'undefined') return null 

      let extractDataItems = () => (
        chart.QApplied == __C.DATABASE.QUERY_APPLIED_JSON ? 
        (chart.JsonString ? JSON.parse(chart.JsonString) : []) :
        (chart.QResultSummary ? chart.QResultSummary : [])
      )
      let extractSqlValues = () => {
        let values_ = { 
          milestone: (
            chart.MilestoneQApplied == 'J' ? 
            (chart.MilestoneQJson ? JSON.parse(chart.MilestoneQJson) : []) : 
            chart.QResultMilestone
          ), 
          // note: '2019-11-01' // deprecated
        }
        // sql-result 'Note' is changed to Title Sql, and its result 
        // is stored in a specified column 'TitleSqlText' at the point
        // of getting the chart data.
        return values_
      }
      let extractValue = () => {
        if(chart.catCode == __C.CHART.CAT_CODE_DONUT) return chart.Values

        let keyNames = Object.keys(chart).filter(k => k.toLowerCase().indexOf('value') === 0)
        let data = {}
        keyNames.forEach(k => { data[k] = chart[k] })

        return data
      }
      let extractData = (typeName) => {
        // check the data one column attributes (attributes-in-a-column type)
        if(chart[typeName]) return chart[typeName]

        let keyNames = Object.keys(chart).filter(k => k.toLowerCase().indexOf(typeName.toLowerCase()) === 0)
        let data = {}
        keyNames.forEach(k => { data[k] = chart[k] })

        return data
      }

      let props__ = {
        ColumnProps : () => chart.JsonProps ? JSON.parse(chart.JsonProps) : [], 
        DataItems   : () => extractDataItems()                                , 
        Queries     : () => chart[__C.CHART.ATTR_NAME_Q_RESULT_EXTRAS] || {}  , 
        Cutoff      : () => chart[__C.CHART.ATTR_NAME_CUTOFF] || ''           , 
        Axis        : () => extractData(__C.CHART.ATTR_NAME_AXIS)             , 
        AxisY       : () => extractData(__C.CHART.ATTR_NAME_AXIS)             , 
        Bar         : () => extractData(__C.CHART.ATTR_NAME_BAR)              , 
        Boxes       : () => extractData(__C.CHART.ATTR_NAME_BOXES)            , 
        Canvas      : () => extractData(__C.CHART.ATTR_NAME_CANVAS)           , 
        Circle      : () => extractData(__C.CHART.ATTR_NAME_CIRCLE)           , 
        Compare     : () => extractData(__C.CHART.ATTR_NAME_COMPARE)          , 
        Delta       : () => extractData(__C.CHART.ATTR_NAME_DELTA)            , 
        Guideline   : () => extractData(__C.CHART.ATTR_NAME_GUIDELINE)        , 
        Legends     : () => extractData(__C.CHART.ATTR_NAME_LEGENDS)          , 
        Line        : () => extractData(__C.CHART.ATTR_NAME_LINE)             , 
        Milestone   : () => extractData(__C.CHART.ATTR_NAME_MILESTONE)        , 
        Note        : () => extractData(__C.CHART.ATTR_NAME_NOTE)             , 
        Package     : () => extractData(__C.CHART.ATTR_NAME_PACKAGE)          , 
        Timeline    : () => extractData(__C.CHART.ATTR_NAME_TIMELINE)         , 
        Title       : () => extractData(__C.CHART.ATTR_NAME_TITLE)            , 
        Status      : () => chart.StyleAttrs                                  , 
        Value       : () => extractValue()                                    , 
        Values      : () => extractSqlValues()                                , 
        Radar       : () => chart[__C.CHART.ATTR_NAME_RADAR] || {}            , 
        Text        : () => chart[__C.CHART.ATTR_NAME_TEXT] || {}             , 
        TableSummary: () => chart[__C.CHART.ATTR_NAME_TABLE_SUMMARY] || {}    , 
        Table       : () => chart[__C.CHART.ATTR_NAME_TABLE] || {}            , 
        Styles      : () => chart[__C.CHART.ATTR_NAME_STYLE] || []            , 
        Columns     : () => chart[__C.CHART.ATTR_NAME_COLUMNS] || []          , 
        Header      : () => chart[__C.CHART.ATTR_NAME_HEADER] ? JSON.parse(chart[__C.CHART.ATTR_NAME_HEADER]) : [], 
        Footer      : () => chart[__C.CHART.ATTR_NAME_FOOTER] || []           ,
        RoleProps   : () => chart.config ? JSON.parse(chart.config) : {}      , 
      }
      let propKeys__ = Object.keys(props__)
      let propData_ = {}

      this.$refs[ref_][0].$options._propKeys.forEach(k => {
        if(propKeys__.indexOf(k) < 0) return
        propData_[k] = props__[k]()
      })

      return propData_
    },
    styleChart(chartNo) {
      let styleData_ = this.container.items.find(item_ => item_.chartNo == chartNo)
      if(!styleData_) return ''

      let style_ = []
      
      style_.push(`top: ${styleData_.y ? styleData_.y : 0}px;`)
      style_.push(`left: ${styleData_.x ? styleData_.x : 0}px;`)

      return style_.join(' ')
    },
  }
}
</script>
