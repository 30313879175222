
// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  data: () => ({
    thead: [
      { key: 'CONTENT_OF_BID_SECTION', html: 'Package', row: 1, rowspan: 2 },
      { key: 'STATUS', html: 'Status', row: 1, rowspan: 2 },
      { key: 'BID_SECTION_PACK', html: 'Bid Section', row: 1, rowspan: 2 },
      { key: 'BID_SECTION_DESCR', html: 'Bid Section Description', row: 1, rowspan: 2 },
      { key: 'TOTAL', html: 'Unit<br/>Total', row: 1, rowspan: 2 },
      { key: 'ITB', html: 'ITB', row:1, colspan: 3 },
      { key: 'LOA', html: 'LOA', row: 1, colspan: 3 },
      { key: 'AWARD_SUBCONTRACTOR', html: 'Award<br/>Sub-Contractor', row: 1, rowspan: 2 },

      { key: 'ITB_COMPLETE', html: 'Complete', row: 2 },
      { key: 'ITB_ONTRACK', html: 'On Track', row: 2 },
      { key: 'ITB_BEHINDPLAN', html: 'Behind Plan', row: 2 },

      { key: 'LOA_COMPLETE', html: 'Complete', row: 2 },
      { key: 'LOA_ONTRACK', html: 'On Track', row: 2 },
      { key: 'LOA_BEHINDPLAN', html: 'Behind Plan', row: 2 },
      { key: 'COLOR', html: '', row: 1 , rowspan: 2 },
    ],
    tbody: [
      
      { key: 'CONTENT_OF_BID_SECTION', w: 30, align: 'center', rowspan: 'Y' },
      { key: 'STATUS', w: 45, align: 'center', rowspan: 'Y' },
      { key: 'BID_SECTION_PACK', w: 30, align: 'center' },
      { key: 'BID_SECTION_DESCR', w: 100, align: 'left' },
      { key: 'TOTAL', w: 25, align: 'center' },
      { key: 'ITB_COMPLETE', w: 32, align: 'center' },
      { key: 'ITB_ONTRACK', w: 32, align: 'center' },
      { key: 'ITB_BEHINDPLAN', w: 35, align: 'center' },
      { key: 'LOA_COMPLETE', w: 32, align: 'center' },
      { key: 'LOA_ONTRACK', w: 32, align: 'center' },
      { key: 'LOA_BEHINDPLAN', w: 35, align: 'center' },
      { key: 'AWARD_SUBCONTRACTOR', w: 190, align: 'center' },
      { key: 'COLOR', w: 20, align: 'center' },
    ],
    
    tableWidth: 1230,
    
    scrollProps: {
      w: 932,
      h: 300,
      g: 0,
      bar: {
        align : 'right',
        gap   : 3,
        radius: 2,
        width : 4,
        fill  : '#9E9E9E',
        stroke: '#9E9E9E',
      }
    }
  }),
  methods: {
    Draw_Charts() {
      if(!this.DataItems || this.DataItems.length === 0) return

      let tableData = this.svg

        let table_group = tableData.append('g').attr('transform', `translate(0, 0)`).attr('class', `block_table_group_`)

        // SET HERE!
        let list = this.DataItems.filter(d => d.BID_SECTION_PACK)
        let tableViewHeight = 96 +700 // thead + tbody
        // let tableViewHeight = 96 + (list.length * 18) // thead + tbody
  
        // if (tableViewHeight > this.scrollProps.h) this.setScroll(table_group, this.scrollProps)
  
        let table_obj = table_group.append('foreignObject')
          .attr('y',0)
          .attr('width', '100%')
          .attr('height', `${tableViewHeight}px`)
          .append('xhtml:body')
  
        let table = table_obj.append('table')
                    .style('border', 'solid 0.7px #E0E0E0')
                    .style('border-collapse', 'collapse')
                    .style('font-size', '11px')
                    .style('width', `${this.tableWidth}px`)
                    .style('table-layout', 'fixed')
  
        let colgroup = table.append('colgroup')
        this.tbody.forEach(k => {
          colgroup.append('col').style('width', k.w + 'px')
        })
  
        let maxRow = Math.max(...this.thead.map(d => d.row))
  
        let thead = table.append('thead')
        for (let r = 1; r <= maxRow; r++) {
          let row = this.thead.filter(d=> d.row === r)
          let tr = thead.append('tr').style('background', '#F5F5F5')
  
          row.forEach(item => {
            tr.append('th')
            .style('height', '18px')
            .attr('class', 'completion_dashboard_table_group___th')
            .attr('rowspan', () => item.rowspan ? item.rowspan : '')
            .attr('colspan', () => item.colspan ? item.colspan : '')
            .style('border', 'solid 0.7px #E0E0E0')
            .style('padding', ['Complete','On Track','Behind Plan'].includes(item.html) ? '2px': '2px')
            .style('font-weight','bold')
            .style('font-size', ['Complete','On Track','Behind Plan'].includes(item.html) ? '10px': '11px')
            .style('color', ['Complete','On Track','Behind Plan'].includes(item.html) ? '#039BE5':'#2c3e50')
            .html(item.html)
          })        
        }
          
        let tbodykeys = this.tbody
        let tbody = table.append('tbody')
        let bidpackage = ''
        let status = ''
        let status_bidpackage = ''

        for (let r = 0; r < list.length; r++) {
          let tr = tbody.append('tr')
          // tr.on('mouseover', () => {
          //   tr.style('cursor', 'pointer')
          //   tr.style('background-color', '#C5E1A5')
          // })
          // tr.on('mouseout', () => {
          //   tr.style('background-color', '#fff')
          // })
          //tr mouseover color change

          tbodykeys.forEach((k, i) => {
            if (i == 0 && list[r][k.key] == bidpackage) return
            if (i == 1 && list[r][k.key] == status && status_bidpackage == list[r]['CONTENT_OF_BID_SECTION']) return
            else {
              if (i == 0) {
                bidpackage = list[r][k.key]
              }else if (i == 1) {
                status = list[r][k.key]
                status_bidpackage = list[r]['CONTENT_OF_BID_SECTION']
              }
            }

            let td = tr.append('td')
            .style('border', 'solid 0.7px #E0E0E0')
            .style('height', '22px')
            .style('text-align', k.align ? k.align : 'right')
            .style('padding', '0 3px 0 3px')
            .attr('rowspan', () => {
              if (k.rowspan == 'Y') {
                // let filtered = list.filter(d => d[k.key] == list[r][k.key])
                let filtered = i == 0 ? list.filter(d => d[k.key] == list[r][k.key]) : list.filter(d => d['CONTENT_OF_BID_SECTION'] == list[r]['CONTENT_OF_BID_SECTION'] && d['STATUS'] == list[r]['STATUS'] )
                return filtered.length
              }
            })
            if(i === 2) {
              td.style('cursor', 'pointer').style('text-decoration', 'underline')
              .on('mouseover', () => {
                td.style('opacity',0.5)
              })
              .on('mouseout', () => {
                td.style('opacity', 1)
              })
              .on('click', () => {
                // console.log(`${data.BID_SECTION_PACK}`)
                let dataSource = {
                  BID_SECTION_PACK: list[r].BID_SECTION_PACK
                }
                let request_ = this.getRequestColumnProps('subcontractor', dataSource, this.ColumnProps)
                this.$emit('request-action', request_)
              })
            }
            if(i === 3) {
              td.style('white-space', 'nowrap')
                .style('overflow', 'hidden')
                .style('text-overflow', 'ellipsis')
                .attr('title', list[r][k.key])
            }
            if(k.key.includes('COLOR')){
              td
              .style('position', 'relative')
              .style('z-index', '1')

              td.append('div')
              .style('position', 'absolute')
              .style('left', '9px')
              .style('top', '3px')
              .style('width', '15px')
              .style('height', '15px')
              .style('border-radius', '50%')
              .style('background-color', `${list[r].STATUS =='COMPLETE' ? '#81C784' : list[r].STATUS =='ONTRACK' ? '#FFF176':list[r].STATUS =='BEHINDPLAN' ?'#EF9A9A': '#fff' }`)
              .style('z-index', '-1')
            }
            if (!list[r][k.key]) return
            td.html(list[r][k.key])
          })
        }
    },
  }
}
