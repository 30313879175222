// -------------------------------------------------------------------------------------- Global
import { SafeIdMixin } from '../../../../includes/mixins/safeid.mixin'                // ATAGlance Framework Globals
import gmx_Controller from '../../../../includes/mixins/Controller.mixin'       // SVG Control
// Global Drawings
import gmx_Canvas from '../../../../includes/mixins/Draw_Canvas.mixin'
import gmx_Titles from '../../../../includes/mixins/Draw_Titles.mixin'
import gmx_Legens from '../../../../includes/mixins/Draw_Legends.mixin'
import gmx_Notes from '../../../../includes/mixins/Draw_Notes.mixin'
// Global Functions
import gmx_Miscellaneous from '../../../../includes/mixins/Miscellaneous.mixins'
import gmx_Movable from '../../../../includes/mixins/Movable.mixin'

// -------------------------------------------------------------------------------------- Local
import lmx_ReactiveEvents from './Realtime_Monitor.mixin' // Reactive Events
import lmx_Declares from './Declares.mixin'
import lmx_Elements from './Draw_Elements.mixin'          // Common functions for Drawing the Charts
// Charting
import lmx_Embossed from './Chart_Embossed.mixin'
import lmx_Normal from './Chart_Normal.mixin'
import lmx_Compare from './Chart_Compare.mixin'

export default {
  mixins: [
    SafeIdMixin,
    gmx_Controller,
    gmx_Canvas,
    gmx_Titles,
    gmx_Legens,
    gmx_Notes,
    gmx_Miscellaneous,
    gmx_Movable,

    lmx_ReactiveEvents,
    lmx_Declares,
    lmx_Elements,
    lmx_Embossed,
    lmx_Normal,
    lmx_Compare,
  ],
}
