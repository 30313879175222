import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  data: () => ({
    scrollProps: {
      w: 506,
      h: 380,
      g: 0,
      bar: {
        align : 'right',
        gap   : 3,
        radius: 2,
        width : 7,
        fill  : '#E0E0E0',
        stroke: '#BDBDBD',
      }
    },
    style: {
      x:        0,
      y:        0,
      width:    70,
      title:    {text: '', x: 28, tSize: 11, tColor: '#333'},
      cell:     [3, 315, 355, 395, 410],
      lineHeight: 20,
      bColor:   '#8dcaf0', 
      sColor:   '#44A9DF', 
      tColor1:  '#fff', 
      tColor2:  '#757575', 
      opacity:  1,
    }
  }),
  methods: {
    Draw_Charts() {
      let svg = this.svg
      let data = this.DataItems

      let group = svg.append('g').attr('class', 'group__')
      .attr('transform', `translate(0.5, 0.5)`).style('font-family','roboto')

      let table_group = group.append('g').attr('transform', `translate(0.5, 0.5)`)
      this.setScroll(table_group, this.scrollProps)

      this.style.cell[5] = this.style.cell[4] + (this.style.width / 2)
      this.style.cell[6] = this.style.cell[4] + this.style.width

      let discITR = table_group
      .append('g').attr('class', 'table')
      .attr('transform', `translate(${this.style.x}, ${this.style.y})`)

      discITR
      .append('text')
      .attr('x', this.style.title.x).attr('y', -10)
      .style('font-family', 'roboto').style('font-size', this.style.title.tSize).style('fill', this.style.title.tColor)
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(this.style.title.text)

      discITR
      .append('text')
      .attr('x', this.style.cell[0]+25).attr('y', 8)
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#1565C0').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text('Description')

      discITR
      .append('text')
      .attr('x', this.style.cell[1]).attr('y', 8)
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#1565C0').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
      .text('Total')

      discITR
      .append('text')
      .attr('x', this.style.cell[2]).attr('y', 8)
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#1565C0').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
      .text('Issued')

      discITR
      .append('text')
      .attr('x', this.style.cell[3]).attr('y', 8)
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#1565C0').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
      .text('Rem.')

      discITR
      .append('text')
      .attr('x', this.style.cell[4]).attr('y', 15)
      .style('font-family', 'roboto').style('font-size', 9).style('fill', '#bcbcbc').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text('0')

      discITR
      .append('text')
      .attr('x', this.style.cell[5]).attr('y', 15)
      .style('font-family', 'roboto').style('font-size', 9).style('fill', '#bcbcbc').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text('50')

      discITR
      .append('text')
      .attr('x', this.style.cell[6]).attr('y', 15)
      .style('font-family', 'roboto').style('font-size', 9).style('fill', '#bcbcbc').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text('100')

      discITR
      .append('line')
      .attr('x1', this.style.cell[4]).attr('y1', 20).attr('x2', this.style.cell[4]).attr('y2', 23).style('stroke', '#bcbcbc').style('stroke-width', 0.5)
      discITR
      .append('line')
      .attr('x1', this.style.cell[5]).attr('y1', 20).attr('x2', this.style.cell[5]).attr('y2', 23).style('stroke', '#bcbcbc').style('stroke-width', 0.5)
      discITR
      .append('line')
      .attr('x1', this.style.cell[6]).attr('y1', 20).attr('x2', this.style.cell[6]).attr('y2', 23).style('stroke', '#bcbcbc').style('stroke-width', 0.5)
      
      discITR
      .append('line')
      .attr('x1', this.style.cell[4]).attr('y1', 20).attr('x2', this.style.cell[4]).attr('y2', 23).style('stroke', '#bcbcbc').style('stroke-width', 0.5)
      
      let drawY = 30
      data.forEach((d,i) => {
        d.y = drawY

        discITR
        .append('text')
        .attr('x', this.style.cell[0]).attr('y', drawY)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#333').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text(d.DESCRIPTION)
        //Total
        discITR
        .append('text')
        .attr('id', `disc_Tot_${this.localId}_${i}`)
        .attr('transform', `translate(${this.style.cell[1]}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text(d.TOTAL.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))

        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100)
          .attr('transform', `translate(${315+2}, ${d.y}) scale(1.3)`).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100)
          .attr('transform', `translate(${315}, ${d.y}) scale(1)`).style('fill', '#757575')
        })
        .on('click', () => { 

          let columnProps = this.getRequestColumnProps('request', {}, this.ColumnProps)
          let request_ = {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: columnProps.action.id,
              no: ''
            },
            filters: { 
              ...this.FilterValues,
              MERGED_SUB_TYPE: d.DESCRIPTION,
              SUBCON : 'SEI'
            },
            iFilters: {
              filterString : '',
              inputFilter  : ''
            }
          }
          this.$emit('request-action', request_)
        })

        //Actual=Completed
        discITR
        .append('text')
        .attr('id', `disc_Act_${this.localId}_${i}`)
        .attr('transform', `translate(${this.style.cell[2]}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#039BE5').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text(d.ISSUED.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100)
          .attr('transform', `translate(${355+2}, ${d.y}) scale(1.3)`).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100)
          .attr('transform', `translate(${355}, ${d.y}) scale(1)`).style('fill', '#039BE5')
        })
        .on('click', () => { 
          let columnProps = this.getRequestColumnProps('request', {}, this.ColumnProps)
          let request_ = {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: columnProps.action.id,
              no: ''
            },
            filters: { 
              ...this.FilterValues,
              MERGED_SUB_TYPE: d.DESCRIPTION,
              SUBCON : 'SEI',
              MERGED_DOCFROM:'Aconex'
            },
            iFilters: {
              filterString : '',
              inputFilter  : ''
            }
          }
          this.$emit('request-action', request_)
        })
        //REMAIN
        discITR
        .append('text')
        .attr('id', `disc_Rem_${this.localId}_${i}`)
        .attr('transform', `translate(${this.style.cell[3]}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', () => d.REMAIN == 0 ? '#757575' : '#F44336' )
        .attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text(d.REMAIN.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100)
          .attr('transform', `translate(${395+2}, ${d.y}) scale(1.3)`).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100)
          .attr('transform', `translate(${395}, ${d.y}) scale(1)`).style('fill', () => d.REMAIN == 0 ? '#757575' : '#F44336' )
        })
        .on('click', () => { 
          let columnProps = this.getRequestColumnProps('request', {}, this.ColumnProps)

          let request_ = {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: columnProps.action.id,
              no: ''
            },
            filters: { 
              ...this.FilterValues,
              MERGED_SUB_TYPE: d.DESCRIPTION,
              SUBCON : 'SEI',
              SEI_ISSUE_CODE:'Not Issued' 
            },
            iFilters: {
              filterString : '',
              inputFilter  : ''
            }
          }
          this.$emit('request-action', request_)
        })

        discITR
        .append('rect')
        .attr('transform', `translate(${this.style.cell[4]}, ${drawY - 7})`)
        .attr('width', this.style.width).attr('height', 12)
        .attr('fill', '#F4F5F5')

        discITR
        .append('rect')
        .attr('transform', `translate(${this.style.cell[4]}, ${drawY - 7})`)
        .attr('width', 0).attr('height', 12)
        .attr('stroke-width', 0.3).attr('stroke', this.style.sColor).attr('fill', this.style.bColor).style('opacity', this.style.opacity)
        .transition().duration(500).attr('width', (d.PROG/100) * this.style.width)

        discITR
        .append('text')
        .attr('y', drawY) 
        .attr('x', () => {
          if (d.PROG < 25) return this.style.cell[4]; else return this.style.cell[4] + 20
        })
        .style('font-family', 'roboto').style('font-size', 10).attr('alignment-baseline', 'middle')
        .text(`${d.PROG.toFixed(1)}%`)
        .style('fill', () => {
          if (d.PROG < 25) return this.style.tColor2; else return this.style.tColor1
        })
        .attr('text-anchor', () => {
          if (d.PROG < 25) return 'start'; else return 'end'
        })
        .transition().duration(500)
        .attr('x', () => {
          if (d.PROG < 25) return this.style.cell[4] + d.PROG+1; else return this.style.cell[4] + ((d.PROG/100) * this.style.width) -1
        })
        drawY += this.style.lineHeight
      })
    },
  }
}