/* 
  ### Chart Controllers ### 
  Predefined component's behaviors for the Filter & Action
*/

//00_Home
  import CHV2_LUN_Popup              from '../10_China1/SystemCompletion/Lun_Popup/index.vue'
//01.Project
  import CHV2_InformationGuide       from '../10_China1/Project/Information_Guide/index.vue'
  import CHV2_InformationGuideV2     from '../10_China1/Project/InformationGuide_v2/index.vue'
  import CHV2_Construction_Photo     from '../10_China1/Project/Construction_Photo/index.vue'
//02_Engineering
  import CHV2_AreaSummary            from '../10_China1/Engineering/Area_Summary/Progress/index.vue'
  import CHV2_AreaSummaryV1          from '../10_China1/Engineering/Area_Summary_v1/Progress/index.vue'
//03.Planning
  import CHV2_Level1ScheduleNew      from '../10_China1/Planning/Level1ScheduleNew/index.vue'
//04_Construction
//05_Procurement
//06_QualityControl
//08.SystemCompletion
  import CHV2_UtilityCommissioning   from '../10_China1/SystemCompletion/UtilityCommissioning/index.vue'
  import CHV2_UtilityCommissioningV2 from '../10_China1/SystemCompletion/UtilityCommissioningV2/index.vue'
  import CHV2_CsuSequence_P1         from '../10_China1/SystemCompletion/Startup_Sequence/CSU_P1/index.vue'
  import CHV2_CsuSequence_P2_GPPE    from '../10_China1/SystemCompletion/Startup_Sequence/CSU_P2_GPPE/index.vue'
  import CHV2_CsuSequence_P2_LDPE    from '../10_China1/SystemCompletion/Startup_Sequence/CSU_P2_LDPE/index.vue'
  import CHV2_CsuSequence_P2_PP      from '../10_China1/SystemCompletion/Startup_Sequence/CSU_P2_PP/index.vue'
  import CHV2_CsuSequence_P3         from '../10_China1/SystemCompletion/Startup_Sequence/CSU_P3/index.vue'
  import CHV2_LiveUpdateNotice       from '../10_China1/SystemCompletion/LiveUpdateNotice/index.vue'
  import CHV2_Owner_Overall_Status   from '../10_China1/SystemCompletion/OwnerOverallStatus/index.vue'
  import CHV2_SkylineSubsys          from '../10_China1/SystemCompletion/SkylineSubsys/index.vue'
  import CHV2_SkylineNew             from '../10_China1/SystemCompletion/SkylineNew/index.vue'
  import CHV2_SkylineSubsys2          from '../10_China1/SystemCompletion/SkylineSubsys2/index.vue'
  import CHV2_SkylineNew2             from '../10_China1/SystemCompletion/SkylineNew2/index.vue'

//09.Temp

export default {

  //00_Home
    CHV2_LiveUpdateNotice,
    CHV2_LUN_Popup,

  //01.Project
    CHV2_InformationGuide,
    CHV2_InformationGuideV2,
    CHV2_Construction_Photo,

  //02_Engineering
    CHV2_AreaSummary,
    CHV2_AreaSummaryV1,

  //03.Planning
    CHV2_Level1ScheduleNew,

  //04_Construction
  //05_Procurement
  //06_QualityControl

  //08.SystemCompletion
    CHV2_UtilityCommissioning,
    CHV2_UtilityCommissioningV2,
    CHV2_CsuSequence_P1,
    CHV2_CsuSequence_P2_GPPE,
    CHV2_CsuSequence_P2_LDPE,
    CHV2_CsuSequence_P2_PP,
    CHV2_CsuSequence_P3,
    CHV2_Owner_Overall_Status,
    CHV2_SkylineSubsys,
    CHV2_SkylineNew,
    CHV2_SkylineSubsys2,
    CHV2_SkylineNew2,
  //09.Temp
}
