import * as d3 from 'd3'

export default {
  methods: {
    Export_Topside(d,k) {


      if (d.stage == 'Division_Lv3' && 
      [ 'Mask_TS_Common', 'Mask_TS_Modules' ].includes(d.mask)) {
        setTimeout(() => {
          // Remove SVG
          this.removeSvg(this.CANVAS, 10)
        },1000);

        // Title & Keyplan
        d3.select(`#Title`).text(`Topside_${d.level}`)
        d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Keyplan/TOPSIDE_${d.level}.png`)


        // Hidden
        this.hiddenGroupID('#Mask_Overall', 0, 10)
        this.hiddenGroupID('#Mask_Topside', 0, 10)
        this.hiddenGroupID('#Mask_Hull', 0, 10)

        this.hiddenGroupID('#Area_AFT', 0, 1000)
        this.hiddenGroupID('#Area_FOR', 0, 1000)
        this.hiddenGroupID('#Area_LQ', 0, 1000)
        this.hiddenGroupID('#Area_VS', 0, 1000)
        this.hiddenGroupID('#Menu_Back', 0, 0)

        this.hiddenGroupID('#Mask_TS_Common', 0, 0)
        this.hiddenGroupID('#Mask_TS_Modules', 0, 0)

        
        if(d.level == 'COMMON') {

          // hide module
          this.hiddenGroupID('#MW01', 0, 0)
          this.hiddenGroupID('#MW02', 0, 0)
          this.hiddenGroupID('#MW04', 0, 0)
          this.hiddenGroupID('#MW05', 0, 0)
          this.hiddenGroupID('#ME01', 0, 0)
          this.hiddenGroupID('#ME02', 0, 0)
          this.hiddenGroupID('#ME04', 0, 0)
          this.hiddenGroupID('#ME05', 0, 0)

          setTimeout(() => {
          // move module
          d3.select(`#FL01`).transition().duration(1000).attr('transform', `translate(420, 269) scale(0.75)`)
          d3.select(`#KO01`).transition().duration(1000).attr('transform', `translate(261, 630) scale(0.75)`)
          d3.select(`#TSRC01`).transition().duration(1000).attr('transform', `translate(579, 580) scale(0.75)`)
          d3.select(`#TSRC02`).transition().duration(1000).attr('transform', `translate(749, 612) scale(0.75)`)
          d3.select(`#TSRC03`).transition().duration(1000).attr('transform', `translate(907, 659) scale(0.75)`)
          d3.select(`#TSRC04`).transition().duration(1000).attr('transform', `translate(1082, 691) scale(0.75)`)
          d3.select(`#TSRC05`).transition().duration(1000).attr('transform', `translate(1234, 722) scale(0.75)`)
          d3.select(`#LD00DA`).transition().duration(1000).attr('transform', `translate(1294, 592) scale(0.75)`)
         },1000);
        }

        if (d.level == 'MODULES') {

          // hide module
          this.hiddenGroupID('#FL01',   0, 0)
          this.hiddenGroupID('#KO01',   0, 0)
          this.hiddenGroupID('#TSRC01', 0, 0)
          this.hiddenGroupID('#TSRC02', 0, 0)
          this.hiddenGroupID('#TSRC03', 0, 0)
          this.hiddenGroupID('#TSRC04', 0, 0)
          this.hiddenGroupID('#TSRC05', 0, 0)
          this.hiddenGroupID('#LD00DA', 0, 0)
          setTimeout(() => {
          // move module
          d3.select(`#MW01`).transition().duration(1000).attr('transform', `translate(581, 357) scale(0.75)`)
          d3.select(`#MW02`).transition().duration(1000).attr('transform', `translate(797, 393) scale(0.75)`)
          d3.select(`#MW04`).transition().duration(1000).attr('transform', `translate(1069, 419) scale(0.75)`)
          d3.select(`#MW05`).transition().duration(1000).attr('transform', `translate(1273, 488) scale(0.75)`)
          d3.select(`#ME01`).transition().duration(1000).attr('transform', `translate(287, 583) scale(0.75)`)
          d3.select(`#ME02`).transition().duration(1000).attr('transform', `translate(498, 614) scale(0.75)`)
          d3.select(`#ME04`).transition().duration(1000).attr('transform', `translate(774, 626) scale(0.75)`)
          d3.select(`#ME05`).transition().duration(1000).attr('transform', `translate(981, 707) scale(0.75)`)
        },1000);
        }

        setTimeout(() => {

          // Mask Display
          if(d.level == 'COMMON') {
            this.displayGroupID(`#Mask_FL01`,   0, 10, 0)
            this.displayGroupID(`#Mask_KO01`,   0, 10, 0)
            this.displayGroupID(`#Mask_TSRC01`, 0, 10, 0)
            this.displayGroupID(`#Mask_TSRC02`, 0, 10, 0)
            this.displayGroupID(`#Mask_TSRC03`, 0, 10, 0)
            this.displayGroupID(`#Mask_TSRC04`, 0, 10, 0)
            this.displayGroupID(`#Mask_TSRC05`, 0, 10, 0)
            this.displayGroupID(`#Mask_LD00DA`, 0, 10, 0)
          }
          if(d.level == 'MODULES') {
            this.displayGroupID(`#Mask_MW01`, 0, 10, 0)
            this.displayGroupID(`#Mask_MW02`, 0, 10, 0)
            this.displayGroupID(`#Mask_MW04`, 0, 10, 0)
            this.displayGroupID(`#Mask_MW05`, 0, 10, 0)
            this.displayGroupID(`#Mask_ME01`, 0, 10, 0)
            this.displayGroupID(`#Mask_ME02`, 0, 10, 0)
            this.displayGroupID(`#Mask_ME04`, 0, 10, 0)
            this.displayGroupID(`#Mask_ME05`, 0, 10, 0)
          }

          this.Lev1 = 'TOPSIDE'
          this.Lev2 = d.level
          this.stage = this.STG_Division_Lv3
          this.fncName = 'Goto_TS_Division_Lv3'
          this.StageCode() // AREA / STAGE
          
          this.displayGroupID(`#Menu_Back`, 0, 200, 1)

          // ### Call Canvas ###
          this.Canvas_Division_Lv3(this.Lev1, this.Lev2)
        }, 1000);
        
      }
     
      if (d.stage == 'Division_Lv3' && 
      [ 'Mask_KO01' , 'Mask_FL01' , 'Mask_TSRC01' , 'Mask_TSRC02' ,
        'Mask_TSRC03' , 'Mask_TSRC04' , 'Mask_TSRC05' , 'Mask_LD00DA' , 'Mask_MW01' , 
        'Mask_MW02' , 'Mask_MW04' , 'Mask_MW05' ,  'Mask_ME01' , 'Mask_ME02' ,
        'Mask_ME04' ,  'Mask_ME05' ].includes(d.mask)) {

          setTimeout(() => {
            // Remove SVG
            this.removeSvg(this.CANVAS, 10)
          },1000);

          this.hiddenGroupID(`#Mask_${d.level}`, 0, 0)
          
          // Title & Keyplan
          d3.select(`#Title`).text(`TOPSIDE_${d.level}`)
          d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Keyplan/TOPSIDE_${d.level}.png`)

          // Hidden
          this.hiddenGroupID('#Menu_Back', 0, 10)
          
          setTimeout(() => {
            // Remove SVG
            this.removeSvg(this.Dashboard_Overall, 10)
          },1300);

          setTimeout(() => {

            this.Activate_Topside_Module(d.level)

            this.Lev1 = 'TOPSIDE'
            this.Lev3 = d.level
            this.stage = this.STG_Activate
            this.fncName = 'Goto_TS_Activate'
            this.StageCode() // AREA / STAGE
            this.displayGroupID(`#Menu_Back`, 0, 200, 1)

            // ### Display Canvas ###
            this.Canvas_Topside_Activate(this.Lev3) 
          }, 1000);
   
      }

      if (d.stage == 'Lv4_Block_Division' && 
      [ 'MW01', 'MW02', 'MW04', 'MW05', 'ME01', 'ME02',  
        'ME04', 'ME05',  'KO01' , 'FL01' , 'RC01' , 'RC02' ,
        'RC03' , 'RC04' , 'RC05' ].includes(d.level)) {

          setTimeout(() => {
            // Remove SVG
            this.removeSvg(this.CANVAS, 10)
          },1000);

          // Hidden
          this.hiddenGroupID('#Menu_Back', 0, 10)
          this.hiddenGroupID(`#Mask_Mod_${d.level}`, 0, 10)
          setTimeout(() => {
          // Explode
          this.TOPSIDE.filter(f => f.Lv3 == d.level).forEach(d => {
            d3.select(`#Blk_${d.Blk}`)
            .transition().duration(1000).attr('transform', `translate(${d.x2}, ${d.y2}) scale(0.5)`)
          })
        }, 1000);
          setTimeout(() => {

            let coordinate = this.Coordinate.find(f=> f.l2 == d.level)

            // Display
            this.draw_BlockCode_Module(d.level, coordinate.x, coordinate.y)

            this.Lev1 = 'TOPSIDE'
            this.stage = this.STG_Lv4_Block_Division           
            this.fncName = 'Goto_Topside_ModuleDivision'
            this.StageCode() // AREA / STAGE
            this.displayGroupID(`#Menu_Back`, 0, 200, 1)
          }, 1000);
   
      }
      
    },
  }
}
           