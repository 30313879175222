// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  data:() => ({
    props:[
      { module: 'ME01', moduleDescr:'Flash Gas Compression',x: -20, y: 100 },
      { module: 'ME02', moduleDescr:'MEG & Fuel Gas',x: -20, y: 100 },
      { module: 'ME04', moduleDescr:'Power Generation',x: 0, y: 90 },
      { module: 'ME05', moduleDescr:'E/I Building',x: -10, y: 100 },
      { module: 'MW01', moduleDescr:'Reception Facilities & Production Separation',x: -20, y: 100 },
      { module: 'MW02', moduleDescr:'HC Dewpointing & Condensate Stabilisation',x: -10, y: 100 },
      { module: 'MW04', moduleDescr:'Power Generation',x: 0, y: 90 },
      { module: 'MW05', moduleDescr:'Utility',x: -10, y: 100 },
      { module: 'FL01', moduleDescr:'Utility',x: 30, y: 100 },
      { module: 'KO01', moduleDescr:'Utility',x: -10, y: 100 },
      { module: 'LD00DA', moduleDescr:'Utility',x: -10, y: 100 },
      { module: 'RCxx', moduleDescr:'Utility',x: -30, y: 110 },
    ]
  }),
  methods: {
    Draw_Charts() {
      let data = null, style = null, tmp = null
      //Image 원래는 module만 있었는데 common도 추가 작업했음!! 210525 
      let moduleMap = this.DataItems.map(d => d.GWBS_LV3)
      // let module = this.FilterValues && this.FilterValues.GWBS_LV3 ? this.FilterValues.GWBS_LV3 : 'ME04'
      let props = this.props.filter(d => d.module == moduleMap)[0]

      let overallSummary = this.svg
      .append('g')
      .attr('class','moduleName')
      .attr('transform', `translate(40, 50)`)

      overallSummary
      .append('image')
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Modules/${moduleMap}.png`)
      // .attr('transform', `translate(0, ${ ['ME04','MW04'].includes(moduleMap) ? 40 : 100}) `)
      .attr('transform', `translate(${props.x} ${props.y}) `)

      // Overall Plan
      tmp = this.DataItems[0]
      style = {
        id: 'CERT',
        x: 0,
        y: 0,
        ty: 5,
        name: tmp.PLAN,
        sort: 'N',
      }
      data = [
        {title: 'Completed',  value: tmp.PLAN },
        {title: 'Remain',     value: 100-tmp.PLAN }
      ]
      let Circle = {
        CircleDiameter: 28,
        CircleColorSet: ['Gray', 'LightGray'],
        CircleHoleSize: 20,
        CircleStackSize: 2,
        CircleSliceSize: 5,
        CircleValueSize: 14,
        CircleTitleSize: 12,
        CircleLegendSpace: 80,
      }
      let Guideline = {
        GuideDisplay: 'N',
      }
      overallSummary
      .append('text')
      .attr('transform', `translate(${style.x}, ${style.y - Circle.CircleDiameter - 5})`)
      .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'middle')
      .text('Plan (%)')

      this.Chart_PieSimple(overallSummary, data, style, Circle, Guideline)

      style = {
        id: 'SHI',
        x: 128,
        y: 0,
        ty: 5,
        name: tmp.ACTUAL,
        sort: 'N',
      }
      data = [
        {title: 'Completed',  value: tmp.ACTUAL },
        {title: 'Remain',     value: 100-tmp.ACTUAL }
      ]
      Circle.CircleColorSet = ['LightBlue', 'LightGray']
      
      overallSummary
      .append('text')
      .attr('transform', `translate(${style.x}, ${style.y - Circle.CircleDiameter - 5})`)
      .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'middle')
      .text('Actual (%)')

      this.Chart_PieSimple(overallSummary, data, style, Circle, Guideline)

      let deltabox = overallSummary
      .append('g')
      .attr('transform', `translate(40, -50)`)

      deltabox
      .append('text')
      .attr('transform', `translate(25, 52)`)
      .style('font-size', 9)
      .style('font-family', 'roboto')
      .attr('fill', '#959595')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text('Delta (%)')

      deltabox
      .append('text')
      .attr('transform', `translate(25, 70)`)
      .style('font-size', 14)
      .style('font-family', 'roboto')
      .attr('fill', tmp.DELTA >= 0 ? '#1e88e5' : '#bf360c')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text(`${tmp.DELTA > 0 ? '▲ ' : tmp.DELTA === 0 ? '': '▼ '}` + tmp.DELTA)

      //Week
      let week = overallSummary
      .append('g')
      .attr('id', 'week')
      .attr('transform', `translate(-28, 40)`)
      
      week
      .append('rect')
      .attr('rx', 4)
      .attr('ry', 4)
      .attr('width', 185)
      .attr('height', 30)
      .attr('fill-opacity', 0)
      .attr('stroke', '#dadada')
      .attr('stroke-width', .5)

      week
      .append('rect')
      .attr('transform', `translate(141, -5)`)
      .attr('width', 40)
      .attr('height', 10)
      .attr('fill', '#fff')

      week
      .append('text')
      .attr('transform', `translate(161, 0)`)
      .style('font-size', 9)
      .style('font-family', 'roboto')
      .attr('fill', '#959595')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text('Weekly')

      week
      .append('text')
      .attr('transform', `translate(28, 17)`)
      .style('font-size', 12)
      .style('font-family', 'roboto')
      .attr('fill', '#333')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text(tmp.PLAN_WK)

      week
      .append('text')
      .attr('transform', `translate(90, 17)`)
      .style('font-size', 12)
      .style('font-family', 'roboto')
      .attr('fill', tmp.DELTA_WK >= 0 ? '#1e88e5' : '#bf360c')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text(`${tmp.DELTA_WK > 0 ? '▲ ' : tmp.DELTA_WK === 0 ? '': '▼ '}` + Number(tmp.DELTA_WK).toFixed(2))

      week
      .append('text')
      .attr('transform', `translate(159, 17)`)
      .style('font-size', 12)
      .style('font-family', 'roboto')
      .attr('fill', '#333')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text(tmp.ACTUAL_WK)
    },
  }
}
