import * as d3 from 'd3'

export default {

  methods: {
    /*
      load_Lev1()
      setCoordinate_Lev1()
      unload_Lev1()
    */
      load_Lev1() {

      this.stage_Header.select(`#HOME`).attr('visibility', 'hidden')
      this.stage_Header.select(`#BACK`).attr('visibility', 'hidden')

      this.innerSvg = this.svg.append('svg')
      this.setPriority()

      this.Areas.forEach(d=> {
        d3.select(`#PLAN-${d.code}`)
        .transition().duration(300)
        .style('opacity', 0)
      })

      this.innerSvg
      .append('image')
      .attr('id','lev1_background')
      .attr('transform', `translate(-330, -330) scale(1.3)`)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/China1/Construction_Photo/images/MAINPLANT.jpg`)
      .style('opacity', 0)
      
      d3.select('#lev1_background')
      .transition().duration(500)
      .attr('transform', `translate(-90, -110) scale(1)`)
      .style('opacity', 1)

      // After loading the SVG, perform initialization
      this.loadSvg(
        this.innerSvg, {
        x   : 0,
        y   : 0,
        url : '/China1/Construction_Photo/svg/Main_Plant.svg'
      }).then(() => {
        this.Package.forEach(d => {
          this.innerSvg.select(`#BTN-${d.code}`).style('opacity', 0)
          this.innerSvg.select(`#AREA-${d.code}`).attr('transform', `translate(${d.x}, ${d.y})scale(0)`).style('opacity', 0)
          this.innerSvg.select(`#BOX-${d.code}`).style('opacity', 0)
        })
        this.setCoordinate_Lev1() // ---> below
        // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        // PAGESVG 에서는 drawNotes,drawTexts 는 loadsvg 가 제일 마지막에 실행이되므로
        // this.loadSvg().then 에서 처리해야 제일 상단에 그려진다.
        // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        this.drawNotes()
        this.drawTexts() 
      })
    },
    


    setCoordinate_Lev1() {
      // Set mouse events on the package button
      this.Package.forEach(d => {
        this.innerSvg
        .select(`#BTN-${d.code}`)
        .style('cursor', 'pointer')
        .on('click', () => {
          // Hide elements that appear when hovering over with mouse
          this.innerSvg.select(`#AREA-${d.code}`).attr('visibility', 'hidden')
          this.innerSvg.select(`#BOX-${d.code}`).attr('visibility', 'hidden')
          this.innerSvg.select(`#package_group_${d.code}`).attr('visibility', 'hidden')
          
          this.routeTo({
            path  : 'Lev1',
            package: d.code
          }, {
            path: 'Lev2',
            package: d.code
          })
        })
        .call(this.call_Coordinate, d) // ---> in Event.js
        setTimeout(() => { this.draw_package(this.innerSvg, d) }, 400)
        //this.draw_package(this.innerSvg, d) // ---> in draw_Package.js
      })
    },



    unload_Lev1() {
      this.innerSvg.select('#lev1_background')
      .transition().duration(500)
      .attr('transform', `translate(-330, -330) scale(1.3)`)
      .style('opacity', 0)

      return new Promise(resolve => {
        setTimeout(() => {
          this.innerSvg.remove()
          resolve() 
        }, 500)
      })
    },

  }
}