import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {
    draw_BlockCode_Lv3(lv3, x, y) {
      // Draw border of code
      // The border of code provides two types Rect and ellipse.

      this.svg_BlockCode = this.svg.append('svg')
      this.svg_BlockCode
      .attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight)
      .attr('viewBox', `0 0 ${this.Canvas.CanvasWidth} ${this.Canvas.CanvasHeight}`)
      .attr('opacity', 1)

      let BlockCodes = this.svg_BlockCode
      .append('g')
      .attr('id', 'BlockCodes')
      .attr('transform', `translate(${x}, ${y}) scale(1)`)

      this.dataSet.filter(f => f.Lv3 == lv3).forEach(data => {
        if (data.shape == 'rect') {
          BlockCodes
          .append('rect')
          .attr('x', data.x - data.width/2).attr('y', data.y)
          .attr('width', data.width).attr('height', data.height)
          .attr('stroke', data.color).attr('stroke-width', data.stroke).attr('fill', data.bColor)
        }  
        if (data.shape == 'ellipse') {
          BlockCodes
          .append('ellipse')
          .attr('cx', data.x).attr('cy', data.y + (data.height/2))
          .attr('rx', data.width/2).attr('ry', data.height/2)
          .attr('stroke', data.color).attr('stroke-width', data.stroke).attr('fill', data.bColor)
        }
        BlockCodes
        .append('text')
        .attr('transform', `translate(${data.x}, ${data.y + data.padding})`)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', data.tColor).attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging')
        .text(`${data.BLOCK}`)
      })
    },
    

    draw_BlockCode_Lv4(lv2, lv4, x, y) {
      
      // Draw border of code
      // The border of code provides two types Rect and ellipse.
      this.svg_BlockCode = this.svg.append('svg')
      this.svg_BlockCode
      .attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight)
      .attr('viewBox', `0 0 ${this.Canvas.CanvasWidth} ${this.Canvas.CanvasHeight}`)
      .attr('opacity', 1)

      let BlockCodes = this.svg_BlockCode
      .append('g')
      .attr('id', 'BlockCodes')
      .attr('transform', `translate(${x}, ${y})`)

      this.dataSet.filter(f => f.Lv2 == lv2 && f.Lv4 == lv4).forEach(data => {
        if (data.shape == 'rect') {
          BlockCodes
          .append('rect')
          .attr('x', data.x - data.width/2).attr('y', data.y)
          .attr('width', data.width).attr('height', data.height)
          .attr('stroke', data.color).attr('stroke-width', data.stroke).attr('fill', data.bColor)
        }  
        if (data.shape == 'ellipse') {
          BlockCodes
          .append('ellipse')
          .attr('cx', data.x).attr('cy', data.y + (data.height/2))
          .attr('rx', data.width/2).attr('ry', data.height/2)
          .attr('stroke', data.color).attr('stroke-width', data.stroke).attr('fill', data.bColor)
        }
        BlockCodes
        .append('text')
        .attr('transform', `translate(${data.x}, ${data.y + data.padding})`)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', data.tColor).attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging')
        .text(`${data.BLOCK}`)
      })
    },

    draw_BlockCode_Module(lv3, x, y) {     
      // Draw border of code
      // The border of code provides two types Rect and ellipse.

      this.svg_BlockCode = this.svg.append('svg')
      this.svg_BlockCode
      .attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight)
      .attr('viewBox', `0 0 ${this.Canvas.CanvasWidth} ${this.Canvas.CanvasHeight}`)
      .attr('opacity', 1)

      let BlockCodes = this.svg_BlockCode
      .append('g')
      .attr('id', 'BlockCodes')
      .attr('transform', `translate(${x}, ${y}) scale(1)`)
      
      this.dataSet.filter(f => f.Lv3 == lv3).forEach(data => {
        if (data.x) {
          if (data.shape == 'rect') {
            BlockCodes
            .append('rect')
            .attr('x', data.x - (data.width+20)/2).attr('y', data.y)
            .attr('width', data.width+20).attr('height', data.height)
            .attr('stroke', data.color).attr('stroke-width', data.stroke).attr('fill', data.bColor)
          }  
          if (data.shape == 'ellipse') {
            BlockCodes
            .append('ellipse')
            .attr('cx', data.x).attr('cy', data.y + (data.height/2))
            .attr('rx', data.width/2).attr('ry', data.height/2)
            .attr('stroke', data.color).attr('stroke-width', data.stroke).attr('fill', data.bColor)
          }
          BlockCodes
          .append('text')
          .attr('transform', `translate(${data.x}, ${data.y + data.padding})`)
          .style('font-family', 'roboto').style('font-size', 10).style('fill', data.tColor).attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging')
          .text(`${data.BLOCK}`)
        }
      })
    },
  }
}