
import * as d3 from 'd3'

export default {
  data: () => ({
  }),
  methods: {
    setEvent_Overall() {
      this.SendRequestAction = (selection, tag) => {
        
        selection
        .on('mouseover', (_, i, a) => {
          let el = d3.select(a[i])

          el.style('cursor', 'pointer')
          if(el.node().tagName == 'g') el.style('opacity', .5)
          else el.attr('fill', '#81d4fa')
        })
        .on('mouseout', (_, i, a) => {
          let el = d3.select(a[i])
          
          el.style('cursor', 'pointer')
          if(el.node().tagName == 'g') el.style('opacity', 1)
          else el.attr('fill', '#333')
        })
        .on('click', () => {
          // this.openEQPopup(tag) SVg Equipment Popup 안쓰고 component popup쓰기로함 
          let request_ = this.getRequestColumnProps('eqpopup', { TAG: tag }, this.ColumnProps)
          // if(request_) {
          //   request_.filters = {
          //     ...request_.filters,
          //     ...this.FilterValues
          //   }
          //   this.$emit('request-action', request_)
          // }
          this.$emit('request-action', request_)
        })
      }

      // Goto_Division_Lv1
      // ---------------------------------------------------------------------------------------------------------------------
      this.Goto_Division_Lv1 = (selection) => {
        selection
        .on('mouseover', () => {
          if(this.stage != this.STG_Overall) return
          d3.select(`#Mask_Overall`).transition().style('opacity', 0.5)
        })
        .on('mouseout', () => {
          if(this.stage != this.STG_Overall) return
          d3.select(`#Mask_Overall`).transition().style('opacity', 0)
        })
        .on('click', () => {
          if(this.stage != this.STG_Overall) return

          // Remove SVG
          this.removeSvg(this.CANVAS, 1000)

          // Hidden
          this.hiddenGroupID('#Mask_Overall', 0, 10)

          d3.select(`#Topside_Group`).transition().duration(1000).attr('transform', `translate(0, -100)`)
          d3.select(`#Area_AFT`).transition().duration(1000).attr('transform', `translate(441, 692) scale(0.75)`)
          d3.select(`#Area_MS`).transition().duration(1000).attr('transform',  `translate(857, 495) scale(0.75)`)
          d3.select(`#Area_FOR`).transition().duration(1000).attr('transform', `translate(709, 619) scale(0.75)`)
          d3.select(`#Area_LQ`).transition().duration(1000).attr('transform',  `translate(947, 479) scale(0.75)`)
          d3.select(`#Area_VS`).transition().duration(1000).attr('transform',  `translate(1003, 576) scale(0.75)`)

          setTimeout(() => {

            // Display
            this.displayGroupID(`#Menu_Back`, 10, 10, 1)
            this.displayGroupID(`#Keyplan_Group`, 10, 10, 1)
            this.displayGroupID(`#Legends`, 10, 10, 1)
            this.displayGroupID(`#Mask_Topside`, 10, 10, 0)
            this.displayGroupID(`#Mask_Hull`, 10, 10, 0)

            this.stage = this.STG_Division_Lv1
            this.stage = this.STG_Division_Lv1
            let dStage = {
              stage: this.STG_Division_Lv1,
              mask : 'OVERALL',
              level: ''
            }
            this.selectedItems.push(dStage)
            this.fncName = 'Goto_Division_Lv1'
            this.StageCode() // AREA / STAGE
            this.displayGroupID(`#Menu_Back`, 0, 200, 1)

            // ### Call Canvas ###
            this.drawProgress('Hull & Top')
          }, 1000);
        })
      }

      // Goto_Hull_Division_Lv2
      // ---------------------------------------------------------------------------------------------------------------------
      this.Goto_Hull_Division_Lv2 = (selection, mask) => {
        selection
        .on('mouseover', () => {
          if(this.stage != this.STG_Division_Lv1) return
          d3.select(`#${mask}`).transition().style('opacity',0.5)
        })
        .on('mouseout', () => {
          if(this.stage != this.STG_Division_Lv1) return
          d3.select(`#${mask}`).transition().style('opacity',0)
        })
        .on('click', () => {
          if(this.stage != this.STG_Division_Lv1) return

          // Remove SVG
          this.removeSvg(this.CANVAS, 1000)

          // Title & Keyplan
          d3.select(`#Title`).text('Hull & LQ')
          d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Keyplan/HULL.jpg`)

          // Hidden
          this.hiddenGroupID('#Menu_Back', 0, 10)

          this.hiddenGroupID('#Mask_Topside', 0, 10)
          this.hiddenGroupID('#Mask_Hull', 0, 10)
          this.hiddenGroupID('#Topside_Group', 0, 1000)

          d3.select(`#Area_AFT`).transition().duration(1000).attr('transform',`translate(304, 670) scale(0.75)`)
          d3.select(`#Area_MS`).transition().duration(1000).attr('transform', `translate(962, 379) scale(0.75)`)
          d3.select(`#Area_FOR`).transition().duration(1000).attr('transform',`translate(692, 548) scale(0.75)`)
          d3.select(`#Area_LQ`).transition().duration(1000).attr('transform', `translate(1051,195) scale(0.75)`)
          d3.select(`#Area_VS`).transition().duration(1000).attr('transform', `translate(1108,460) scale(0.75)`)
  
          setTimeout(() => {

            // Mask Display
            this.displayGroupID(`#Mask_Hull_AFT`, 10, 10, 0)
            this.displayGroupID(`#Mask_Hull_FOR`, 10, 10, 0)
            this.displayGroupID(`#Mask_Hull_MS`, 10, 10, 0)
            this.displayGroupID(`#Mask_Hull_LQ`, 10, 10, 0)

            this.Lev1 = 'HULL'
            this.stage = this.STG_Division_Lv2
            let dStage = {
              stage: this.STG_Division_Lv2,
              mask : mask,
              level: ''
            }
            this.selectedItems.push(dStage)
            this.fncName = 'Goto_Hull_Division_Lv2'
            this.StageCode() // AREA / STAGE
            this.displayGroupID(`#Menu_Back`, 0, 200, 1)

            // ### Call Canvas ###
            this.drawProgress('Hull & LQ')
          }, 1000);

        })
      }

      // Goto_TS_Division_Lv2
      // ---------------------------------------------------------------------------------------------------------------------
      this.Goto_TS_Division_Lv2 = (selection, mask) => {
        selection
        .on('mouseover', () => {
          if(this.stage != this.STG_Division_Lv1) return
          d3.select(`#${mask}`).transition().style('opacity',0.5)
        })
        .on('mouseout', () => {
          if(this.stage != this.STG_Division_Lv1) return
          d3.select(`#${mask}`).transition().style('opacity',0)
        })
        .on('click', () => {
          if(this.stage != this.STG_Division_Lv1) return

          // Remove SVG
          this.removeSvg(this.CANVAS, 1000)

          // Title & Keyplan
          d3.select(`#Title`).text('Topside')
          d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Keyplan/TOPSIDES.jpg`)

          // Hidden
          this.hiddenGroupID('#Menu_Back', 0, 10)

          this.hiddenGroupID('#Mask_Topside', 0, 10)
          this.hiddenGroupID('#Mask_Hull', 0, 10)

          this.hiddenGroupID('#Area_AFT', 0, 1000)
          this.hiddenGroupID('#Area_MS', 0, 1000)
          this.hiddenGroupID('#Area_FOR', 0, 1000)
          this.hiddenGroupID('#Area_LQ', 0, 1000)
          this.hiddenGroupID('#Area_VS', 0, 1000)

          d3.select(`#Topside_Group`).transition().duration(1000).attr('transform', `translate(0, 0)`)

          d3.select(`#LD00DA`).transition().duration(1000).attr('transform', `translate(1200, 656) scale(0.75)`)
          d3.select(`#TSRC05`).transition().duration(1000).attr('transform', `translate(1164, 675) scale(0.75)`)
          d3.select(`#TSRC04`).transition().duration(1000).attr('transform', `translate(1097, 699) scale(0.75)`)
          d3.select(`#TSRC03`).transition().duration(1000).attr('transform', `translate(1013, 725) scale(0.75)`)
          d3.select(`#TSRC02`).transition().duration(1000).attr('transform', `translate(934, 744) scale(0.75)`)
          d3.select(`#TSRC01`).transition().duration(1000).attr('transform', `translate(851, 770) scale(0.75)`)

          d3.select(`#MW01`).transition().duration(1000).attr('transform', `translate(450, 381) scale(0.75)`)
          d3.select(`#MW02`).transition().duration(1000).attr('transform', `translate(538, 362) scale(0.75)`)
          d3.select(`#MW04`).transition().duration(1000).attr('transform', `translate(716, 265) scale(0.75)`)
          d3.select(`#MW05`).transition().duration(1000).attr('transform', `translate(764, 275) scale(0.75)`)
          d3.select(`#ME01`).transition().duration(1000).attr('transform', `translate(515, 420) scale(0.75)`)
          d3.select(`#ME02`).transition().duration(1000).attr('transform', `translate(586, 403) scale(0.75)`)
          d3.select(`#ME04`).transition().duration(1000).attr('transform', `translate(766, 294) scale(0.75)`)
          d3.select(`#ME05`).transition().duration(1000).attr('transform', `translate(820, 315) scale(0.75)`)
          
          d3.select(`#FL01`)  .transition().duration(1000).attr('transform', `translate(767, 545) scale(0.75)`)
          d3.select(`#KO01`)  .transition().duration(1000).attr('transform', `translate(754, 823) scale(0.75)`)

          setTimeout(() => {

            // Mask Display
            this.displayGroupID(`#Mask_TS_Common`, 10, 10, 0)
            this.displayGroupID(`#Mask_TS_Modules`, 10, 10, 0)


            this.Lev1 = 'TOPSIDES'
            this.stage = this.STG_Division_Lv2
            let dStage = {
              stage: this.STG_Division_Lv2,
              mask : mask,
              level: ''
            }
            this.selectedItems.push(dStage)
            this.stage = this.STG_Division_Lv2
            this.fncName = 'Goto_TS_Division_Lv2'
            this.StageCode() // AREA / STAGE
            this.displayGroupID(`#Menu_Back`, 0, 200, 1)

            // ### Call Canvas ###
            this.drawProgress('Module & Common')
          }, 1000);
          
        })
      }

      // Goto_TS_Common_Division_Lv3
      // ---------------------------------------------------------------------------------------------------------------------
      this.Goto_TS_Division_Lv3 = (selection, mask, lv2) => {
        
        selection
        .on('mouseover', () => {
          if(this.stage != this.STG_Division_Lv2) return
          d3.select(`#${mask}`).transition().style('opacity',0.5)
        })
        .on('mouseout', () => {
          if(this.stage != this.STG_Division_Lv2) return
          d3.select(`#${mask}`).transition().style('opacity',0)
        })
        .on('click', () => {
          if(this.stage != this.STG_Division_Lv2) return

          // Remove SVG
          this.removeSvg(this.CANVAS, 1000)
      
          // Title & Keyplan
          d3.select(`#Title`).text(`Topside_${lv2}`)
          d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Keyplan/TOPSIDE_${lv2}.jpg`)

          // Hidden
          this.hiddenGroupID('#Menu_Back', 0, 0)

          this.hiddenGroupID('#Mask_TS_Common', 0, 0)
          this.hiddenGroupID('#Mask_TS_Modules', 0, 0)

          
          if(lv2 == 'COMMON') {

            // hide module
            this.hiddenGroupID('#MW01', 0, 0)
            this.hiddenGroupID('#MW02', 0, 0)
            this.hiddenGroupID('#MW04', 0, 0)
            this.hiddenGroupID('#MW05', 0, 0)
            this.hiddenGroupID('#ME01', 0, 0)
            this.hiddenGroupID('#ME02', 0, 0)
            this.hiddenGroupID('#ME04', 0, 0)
            this.hiddenGroupID('#ME05', 0, 0)

            // move module
            d3.select(`#FL01`)  .transition().duration(1000).attr('transform', `translate(347, 436) scale(0.75)`)
            d3.select(`#KO01`)  .transition().duration(1000).attr('transform', `translate(375, 760) scale(0.75)`)
            d3.select(`#TSRC01`).transition().duration(1000).attr('transform', `translate(514, 646) scale(0.75)`)
            d3.select(`#TSRC02`).transition().duration(1000).attr('transform', `translate(663, 596) scale(0.75)`)
            d3.select(`#TSRC03`).transition().duration(1000).attr('transform', `translate(805, 552) scale(0.75)`)
            d3.select(`#TSRC04`).transition().duration(1000).attr('transform', `translate(954, 504) scale(0.75)`)
            d3.select(`#TSRC05`).transition().duration(1000).attr('transform', `translate(1080, 458) scale(0.75)`)
            d3.select(`#LD00DA`).transition().duration(1000).attr('transform', `translate(1184, 415) scale(0.75)`)
          }

          if (lv2 == 'MODULES') {

            // hide module
            this.hiddenGroupID('#FL01',   0, 0)
            this.hiddenGroupID('#KO01',   0, 0)
            this.hiddenGroupID('#TSRC01', 0, 0)
            this.hiddenGroupID('#TSRC02', 0, 0)
            this.hiddenGroupID('#TSRC03', 0, 0)
            this.hiddenGroupID('#TSRC04', 0, 0)
            this.hiddenGroupID('#TSRC05', 0, 0)
            this.hiddenGroupID('#LD00DA', 0, 0)

            // move module
            d3.select(`#MW01`).transition().duration(1000).attr('transform', `translate(288, 612) scale(0.75)`)
            d3.select(`#MW02`).transition().duration(1000).attr('transform', `translate(524, 537) scale(0.75)`)
            d3.select(`#MW04`).transition().duration(1000).attr('transform', `translate(769, 415) scale(0.75)`)
            d3.select(`#MW05`).transition().duration(1000).attr('transform', `translate(975, 367) scale(0.75)`)
            d3.select(`#ME01`).transition().duration(1000).attr('transform', `translate(499, 729) scale(0.75)`)
            d3.select(`#ME02`).transition().duration(1000).attr('transform', `translate(718, 656) scale(0.75)`)
            d3.select(`#ME04`).transition().duration(1000).attr('transform', `translate(965, 522) scale(0.75)`)
            d3.select(`#ME05`).transition().duration(1000).attr('transform', `translate(1177, 485) scale(0.75)`)
          }

          setTimeout(() => {

            // Mask Display
            if(lv2 == 'COMMON') {
              this.displayGroupID(`#Mask_FL01`,   0, 10, 0)
              this.displayGroupID(`#Mask_KO01`,   0, 10, 0)
              this.displayGroupID(`#Mask_TSRC01`, 0, 10, 0)
              this.displayGroupID(`#Mask_TSRC02`, 0, 10, 0)
              this.displayGroupID(`#Mask_TSRC03`, 0, 10, 0)
              this.displayGroupID(`#Mask_TSRC04`, 0, 10, 0)
              this.displayGroupID(`#Mask_TSRC05`, 0, 10, 0)
              this.displayGroupID(`#Mask_LD00DA`, 0, 10, 0)
            }
            if(lv2 == 'MODULES') {
              this.displayGroupID(`#Mask_MW01`, 0, 10, 0)
              this.displayGroupID(`#Mask_MW02`, 0, 10, 0)
              this.displayGroupID(`#Mask_MW04`, 0, 10, 0)
              this.displayGroupID(`#Mask_MW05`, 0, 10, 0)
              this.displayGroupID(`#Mask_ME01`, 0, 10, 0)
              this.displayGroupID(`#Mask_ME02`, 0, 10, 0)
              this.displayGroupID(`#Mask_ME04`, 0, 10, 0)
              this.displayGroupID(`#Mask_ME05`, 0, 10, 0)
            }

            this.Lev1 = 'TOPSIDES'
            this.Lev2 = lv2
            this.stage = this.STG_Division_Lv3
            let dStage = {
              stage: this.STG_Division_Lv3,
              mask : mask,
              level: lv2
            }
            this.selectedItems.push(dStage)
            this.fncName = 'Goto_TS_Division_Lv3'
            this.StageCode() // AREA / STAGE
            
            this.displayGroupID(`#Menu_Back`, 0, 200, 1)
            // ### Call Canvas ###
            this.drawProgress(lv2 == 'COMMON' ? 'Topside Common' : 'Topside Modules')
          }, 1000);
        })
      }

    // Goto_TS_Common_Division_Lv3
      // ---------------------------------------------------------------------------------------------------------------------
      this.Goto_TS_Activate = (selection, mask, _mod) => {
        selection
        .on('mouseover', () => {
          if(this.stage != this.STG_Division_Lv3) return
          d3.select(`#${mask}`).transition().style('opacity',0.5)
        })
        .on('mouseout', () => {
          if(this.stage != this.STG_Division_Lv3) return
          d3.select(`#${mask}`).transition().style('opacity',0)
        })
        .on('click', () => {
          if(this.stage != this.STG_Division_Lv3) return

          // Remove SVG
            this.removeSvg(this.CANVAS, 1000)

          this.hiddenGroupID(`#Mask_${_mod}`, 0, 0)
          
          // Title & Keyplan
          d3.select(`#Title`).text(`TOPSIDE_${_mod}`)
          d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Keyplan/TOPSIDE_${_mod}.jpg`)

          // Hidden
          this.hiddenGroupID('#Menu_Back', 0, 10)
 
          // Remove SVG
          setTimeout(() => {
          this.removeSvg(this.Dashboard_Overall, 1000) 
        }, 1000)
          setTimeout(() => {
            if(['LD00DA', 'KO01','FL01'].includes(_mod)) this.Activate_Topside_Module_Single(_mod)
            else this.Activate_Topside_Module(_mod)
            
            this.Lev1 = 'TOPSIDES'
            this.Lev4 = _mod
            this.stage = this.STG_Activate
            let dStage = {
              stage: this.STG_Division_Lv3,
              mask : mask,
              level: _mod
            }
            this.selectedItems.push(dStage)
            this.fncName = 'Goto_TS_Activate'
            this.StageCode() // AREA / STAGE
            // this.displayGroupID(`#Menu_Back`, 0, 200, 1)
          }, 950);
          
        })
      }
      // Goto_AFT_Divison_Lv3
      // ---------------------------------------------------------------------------------------------------------------------
      this.Goto_Hull_Activate = (selection, mask, lv2) => {
        selection
        .on('mouseover', () => {
          if(this.stage != this.STG_Division_Lv2) return
          d3.select(`#${mask}`).transition().style('opacity',0.5)
        })
        .on('mouseout', () => {
          if(this.stage != this.STG_Division_Lv2) return
          d3.select(`#${mask}`).transition().style('opacity',0)
        })
        .on('click', () => {
          if(this.stage != this.STG_Division_Lv2) return
          
          // Title & Keyplan
          d3.select(`#Title`).text(`Hull-${lv2}`)
          d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Keyplan/HULL_${lv2}.jpg`)

           // Hidden
           this.hiddenGroupID('#Menu_Back', 0, 10)

           this.hiddenGroupID('#Mask_Hull_AFT', 0, 10)
           this.hiddenGroupID('#Mask_Hull_FOR', 0, 10)
           this.hiddenGroupID('#Mask_Hull_LQ', 0, 10)
          
           // Remove SVG
           this.removeSvg(this.Dashboard_Overall, 1000) 

           // Activate
           if(lv2 =='AFT') this.Activate_Hull_AFT()
           if(lv2 =='MS') this.Activate_Hull_MS()
           if(lv2 =='FOR') this.Activate_Hull_FOR()
           if(lv2 =='LQ') this.Activate_Hull_LQ()
           this.Stage = this.STG_Activate
           let dStage = {
            stage: this.STG_Division_Lv3,
            mask : mask,
            level: lv2
           }
           this.selectedItems.push(dStage)
           this.fncName = 'Goto_Hull_Activate'
           this.StageCode() // AREA / STAGE
           this.displayGroupID(`#Menu_Back`, 0, 200, 1)
        })
      }
    },
  }
}