import * as d3 from 'd3'

export default {
  methods: {
    drawNoContents() {
      let noData = this.svg
      .append('g')
      .attr('transform', `translate(${this.Canvas.CanvasWidth / 2}, 200)`)
      noData
      .append('text')
      .style('font-weight', 500)
      .style('font-size', 30)
      .attr('text-anchor', 'middle')
      .style('font-style', 'italic')
      .attr('alignment-baseline', 'middle')
      .attr('fill', '#CFCFCF')
      .text('No Data Available.')
    },
    drawContents() {
      let headerStyle = {
        title: 'A ITR',
        x: 5,
        y: 2,
        width: 50,
        height: 18,
        radius: 3,
        tSize: 11,
        tColor: '#333',
        bColor: '#E0E0DF',
        progSize: 15,
      }
      let style__ = {
        tx: [200, 255, 310],
        ty: 40,
        gx: [320, 360, 400],
        x: [200, 255, 310],
        y: [10, 25, 40],
        width: 80,
      }
      let chartArea___ = this.svg
      .append('g')
      .attr('id', 'outfitting_status___chart_area')
      .attr('transform', `translate(0, 0)`)

      let prevHeight = 0
      let level1NameRows = [].concat.apply([], this.level1Names.map((_, i) => i % 2 ? [] : [this.level1Names.slice(i, i + 2)]))

      level1NameRows.forEach((names, i) => {
        let areaRow___ = chartArea___
        .append('g')
        .attr('transform', `translate(5, ${prevHeight})`)

        names.forEach((name, j) => {
          let itemData = this.DataItems.filter(d => d.DISC == name)
          let discArea = areaRow___
          .append('g')
          .attr('transform', `translate(${j * 435}, 0)`)

          discArea
          .append('text')
          .attr('transform', `translate(5, 12.5)`)
          .style('font-family', 'roboto')
          .style('font-weight', 500)
          .style('font-size', 12)
          .attr('text-anchor', 'start')
          .attr('alignment-baseline', 'middle')
          .attr('fill', '#000')
          .text(name)

          discArea.append('line').attr('x1', 0).attr('y1', 22).attr('x2', 410).attr('y2', 22).attr('stroke-width', 0.3).attr('stroke', '#333')

          discArea
          .append('text')
          .attr('transform', `translate(${style__.tx[0]}, ${style__.ty})`)
          .style('font-family', 'roboto').style('font-size', 10).style('fill', '#333').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
          .text('Item')
    
          discArea
          .append('text')
          .attr('transform', `translate(${style__.tx[1]}, ${style__.ty})`)
          .style('font-family', 'roboto').style('font-size', 10).style('fill', '#333').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
          .text('Complete')
    
          discArea
          .append('text')
          .attr('transform', `translate(${style__.tx[2]}, ${style__.ty})`)
          .style('font-family', 'roboto').style('font-size', 10).style('fill', '#333').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
          .text('Remained')

          // Bar Gauge
          discArea
          .append('text')
          .attr('transform', `translate(${style__.gx[0]}, ${style__.ty})`)
          .style('font-family', 'roboto').style('font-size', 8).style('fill', '#bcbcbc').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
          .text('0')
    
          discArea
          .append('text')
          .attr('transform', `translate(${style__.gx[1]}, ${style__.ty})`)
          .style('font-family', 'roboto').style('font-size', 8).style('fill', '#bcbcbc').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
          .text('50')
    
          discArea
          .append('text')
          .attr('transform', `translate(${style__.gx[2]}, ${style__.ty})`)
          .style('font-family', 'roboto').style('font-size', 8).style('fill', '#bcbcbc').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
          .text('100')
    
          discArea
          .append('line')
          .attr('x1', style__.gx[0]).attr('y1', style__.ty + 5).attr('x2', style__.gx[0]).attr('y2', style__.ty + 10).style('stroke', '#bcbcbc').style('stroke-width', 0.5)
          
          discArea
          .append('line')
          .attr('x1', style__.gx[1]).attr('y1', style__.ty + 5).attr('x2', style__.gx[1]).attr('y2', style__.ty + 10).style('stroke', '#bcbcbc').style('stroke-width', 0.5)
          
          discArea
          .append('line')
          .attr('x1', style__.gx[2]).attr('y1', style__.ty + 5).attr('x2', style__.gx[2]).attr('y2', style__.ty + 10).style('stroke', '#bcbcbc').style('stroke-width', 0.5)
          

          let discItemHeight = 0
          itemData.forEach((item, k) => {
            let discItem = discArea
            .append('g')
            .attr('transform', `translate(0, ${discItemHeight * k + 50})`)

            headerStyle.title = item.ITEM
            this.HeaderFlexible(discItem, item.PROG || 0, headerStyle)
            
            let summaryTable = discItem
            .append('g')
            .attr('transform', `translate(5, 23)`)
                  
            summaryTable
            .append('rect')
            .attr('transform', `translate(0, 0)`)
            .style('width', 120).
            style('height', 22)
            .style('fill', '#83D2F5').attr('rx', 3).attr('ry', 3).style('opacity', .7)

            summaryTable
            .append('text') 
            .attr('transform', `translate(35, 10)`)
            .style('font-family', 'roboto').style('font-size', 10)
            .style('fill', '#454545').style('text-anchor', 'end').style('alignment-baseline', 'middle')
            .text('Total')

            summaryTable
            .append('text')
            .attr('transform', `translate(55, 12)`)
            .style('font-family', 'roboto').style('font-size', 16)
            .style('fill', '#fff').style('text-anchor', 'start').style('alignment-baseline', 'middle')
            .style('cursor', 'pointer')
            .text((item.TOTAL || 0).toLocaleString())
            .on('mouseover', () => {})
            .on('mouseout', () => {})
            .on('click', () => { this.$emit('request-action', {}) })
                        
            // Fabrication
            discItem
            .append('text')
            .attr('transform', `translate(${style__.x[0]}, ${style__.y[0]})`)
            .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
            .text('Fabrication')
            
            
            discItem
            .append('text')
            .attr('transform', `translate(${style__.x[1]}, ${style__.y[0]})`)
            .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').style('text-decoration', 'underline').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
            .text(item.FAB_COM)
            .on('mouseover', (d, i, a) => {
              d3.select(a[i])
              .style('cursor', 'pointer')
              .style('fill', '#000')
            })
            .on('mouseout', (d, i, a) => {
              d3.select(a[i])
              .style('cursor', 'default')
              .style('fill', '#757575')
            })
            .on('click', () => {

                  let request_ = {
                    dataType: 'row',
                    action: {
                      type: 'direct',
                      target: 'slide-modal',
                      component: 'ServiceDatatable',
                      id: 112,
                      no: ''
                    },
                    filters: { 
                    },
                    iFilters: {
                      filterString : '',
                      inputFilter  : ''
                    }
                  }
                this.$emit('request-action', request_)
              
            })
      
            discItem
            .append('text')
            .attr('transform', `translate(${style__.x[2]}, ${style__.y[0]})`)
            .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
            .text(item.FAB_COM)
            .on('click', () => {
  
                  let request_ = {
                    dataType: 'row',
                    action: {
                      type: 'direct',
                      target: 'slide-modal',
                      component: 'ServiceDatatable',
                      id: 112,
                      no: ''
                    },
                    filters: { 
                    },
                    iFilters: {
                      filterString : '',
                      inputFilter  : ''
                    }
                  }
                this.$emit('request-action', request_)
              
            })
            // Delivery
            discItem
            .append('text')
            .attr('transform', `translate(${style__.x[0]}, ${style__.y[1]})`)
            .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
            .text('Delivery')
            
      
            discItem
            .append('text')
            .attr('transform', `translate(${style__.x[1]}, ${style__.y[1]})`)
            .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
            .text(item.DEL_COM)
            .on('click', () => {
          
                  let request_ = {
                    dataType: 'row',
                    action: {
                      type: 'direct',
                      target: 'slide-modal',
                      component: 'ServiceDatatable',
                      id: 112,
                      no: ''
                    },
                    filters: { 
                    },
                    iFilters: {
                      filterString : '',
                      inputFilter  : ''
                    }
                  }
                this.$emit('request-action', request_)
              
            })
      
            discItem
            .append('text')
            .attr('transform', `translate(${style__.x[2]}, ${style__.y[1]})`)
            .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
            .text(item.DEL_COM)
            .on('click', () => {
             
                  let request_ = {
                    dataType: 'row',
                    action: {
                      type: 'direct',
                      target: 'slide-modal',
                      component: 'Piping Support List',
                      id: 112,
                      no: ''
                    },
                    filters: { 
                    },
                    iFilters: {
                      filterString : '',
                      inputFilter  : ''
                    }
                  }
                this.$emit('request-action', request_)
              
            })

            // Installed
            discItem
            .append('text')
            .attr('transform', `translate(${style__.x[0]}, ${style__.y[2]})`)
            .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
            .text('Installed')
      
            discItem
            .append('text')
            .attr('transform', `translate(${style__.x[1]}, ${style__.y[2]})`)
            .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
            .text(item.INST_COM)
            .on('click', () => {
             
                  let request_ = {
                    dataType: 'row',
                    action: {
                      type: 'direct',
                      target: 'slide-modal',
                      component: 'Piping Support List',
                      id: 112,
                      no: ''
                    },
                    filters: { 
                    },
                    iFilters: {
                      filterString : '',
                      inputFilter  : ''
                    }
                  }
                this.$emit('request-action', request_)
              
            })
      
            discItem
            .append('text')
            .attr('transform', `translate(${style__.x[2]}, ${style__.y[2]})`)
            .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
            .text(item.INST_COM)
            .on('click', () => {
         
                  let request_ = {
                    dataType: 'row',
                    action: {
                      type: 'direct',
                      target: 'slide-modal',
                      component: 'Piping Support List',
                      id: 112,
                      no: 496
                    },
                    filters: { 
                    },
                    iFilters: {
                      filterString : '',
                      inputFilter  : ''
                    }
                  }
                this.$emit('request-action', request_)
              
            })

            // bar background
            discItem
            .append('rect')
            .attr('transform', `translate(${style__.x[2] + 10}, ${style__.y[0] - 7})`)
            .attr('width', style__.width).attr('height', 12)
            .attr('fill', '#F4F5F5')

            discItem
            .append('rect')
            .attr('transform', `translate(${style__.x[2] + 10}, ${style__.y[1] - 7})`)
            .attr('width', style__.width).attr('height', 12)
            .attr('fill', '#F4F5F5')

            discItem
            .append('rect')
            .attr('transform', `translate(${style__.x[2] + 10}, ${style__.y[2] - 7})`)
            .attr('width', style__.width).attr('height', 12)
            .attr('fill', '#F4F5F5')
    
            // bar progress
            discItem
            .append('rect')
            .attr('transform', `translate(${style__.x[2] + 10}, ${style__.y[0] - 7})`)
            .attr('width', 0).attr('height', 12)
            .attr('stroke-width', 0.3).attr('stroke', '#44A9DF').attr('fill', '#B4E3FA')
            .transition().duration(500).attr('width', item.FAB_PROG ? (item.FAB_PROG/100) * style__.width : 0)

            discItem
            .append('rect')
            .attr('transform', `translate(${style__.x[2] + 10}, ${style__.y[1] - 7})`)
            .attr('width', 0).attr('height', 12)
            .attr('stroke-width', 0.3).attr('stroke', '#44A9DF').attr('fill', '#B4E3FA')
            .transition().duration(500).attr('width', item.DEL_PROG ? (item.DEL_PROG/100) * style__.width : 0)

            discItem
            .append('rect')
            .attr('transform', `translate(${style__.x[2] + 10}, ${style__.y[2] - 7})`)
            .attr('width', 0).attr('height', 12)
            .attr('stroke-width', 0.3).attr('stroke', '#44A9DF').attr('fill', '#B4E3FA')
            .transition().duration(500).attr('width', item.INST_PROG ? (item.INST_PROG/100) * style__.width : 0)
    
            discItem
            .append('text')
            .attr('y', style__.y[0]) 
            .attr('x', item.FAB_PROG && item.FAB_PROG > 0 ? style__.x[2] + 12 : style__.x[2] + 12)
            .style('font-family', 'roboto').style('font-size', 9).attr('alignment-baseline', 'middle')
            .text(`${(item.FAB_PROG || 0).toFixed(1)}%`)
            .style('fill', (item.FAB_PROG || 0) < 25 ? '#757575' : '#fff')
            .attr('text-anchor', (item.FAB_PROG || 0) < 25 ? 'start' : 'end')
            .transition().duration(500)
            .attr('x', (item.FAB_PROG || 0) < 25 ? style__.x[2] + 12 + (item.FAB_PROG || 0) : style__.x[2] + 12 + ((item.FAB_PROG/100) * style__.width) - 3)
    
            discItem
            .append('text')
            .attr('y', style__.y[1]) 
            .attr('x', item.DEL_PROG && item.DEL_PROG > 0 ? style__.x[2] + 12 : style__.x[2] + 12)
            .style('font-family', 'roboto').style('font-size', 9).attr('alignment-baseline', 'middle')
            .text(`${(item.DEL_PROG || 0).toFixed(1)}%`)
            .style('fill', (item.DEL_PROG || 0) < 25 ? '#757575' : '#fff')
            .attr('text-anchor', (item.DEL_PROG || 0) < 25 ? 'start' : 'end')
            .transition().duration(500)
            .attr('x', (item.DEL_PROG || 0) < 25 ? style__.x[2] + 12 + (item.DEL_PROG || 0) : style__.x[2] + 12 + ((item.DEL_PROG/100) * style__.width) - 3)
    
            discItem
            .append('text')
            .attr('y', style__.y[2]) 
            .attr('x', item.INST_PROG && item.INST_PROG > 0 ? style__.x[2] + 12 : style__.x[2] + 12)
            .style('font-family', 'roboto').style('font-size', 9).attr('alignment-baseline', 'middle')
            .text(`${(item.INST_PROG || 0).toFixed(1)}%`)
            .style('fill', (item.INST_PROG || 0) < 25 ? '#757575' : '#fff')
            .attr('text-anchor', (item.INST_PROG || 0) < 25 ? 'start' : 'end')
            .transition().duration(500)
            .attr('x', (item.INST_PROG || 0) < 25 ? style__.x[2] + 12 + (item.INST_PROG || 0) : style__.x[2] + 12 + ((item.INST_PROG/100) * style__.width) - 3)

            discItemHeight += discItem.node().getBoundingClientRect().height + 10
          })
        })

        prevHeight += areaRow___.node().getBoundingClientRect().height + 40
      })
    },
  }
}
           