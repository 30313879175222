import * as d3 from 'd3'

export default {
  data: () => ({
    Back_Dashboard_Overall: null,
    Back_Dashboard_Area: null,
    Back_Hull_Activate: null,
    Back_Hull_SideBlocks: null,
    Back_Hull_ModuleDivision: null,
    Back_Hull_ModuleSummary: null,

    BackButtonUse: 'on',
 
  }),
  computed: {
  },

  methods: {
    
    setEvent_Back() {

      // Set Event Listener Back Button
      this.btnGoBack = (selection) => {
        selection
        .on('mouseover', () => {
          if (this.BackButtonUse != 'on') return
          d3.select(`#Menu_Back`).transition().duration(200).attr('opacity', 0.5)
        })
        .on('mouseout', () => {
          if (this.BackButtonUse != 'on') return
          d3.select(`#Menu_Back`).transition().duration(200).attr('opacity', 1)
        })
        .on('click', () => {
          d3.select(`#Menu_Back`).transition().duration(200).attr('opacity', 1)

          if (this.BackButtonUse != 'on') return
          this.BackButtonUse = 'off'

          switch(this.Stage) {
            case 'AREA': // 
              this.AreaSummary_to_Overall()
            break;

            case 'MODULE': // 
              this.Area_to_AreaSummary()
            break;

            case 'DECK': // 
              this.Module_to_Area()
            break;

            case 'EQUIPMENT': // 
              this.Deck_to_Module()
            break;

            case 'SVG': //
              this.Equipment_to_Deck()
            break;
          }
        })
      }
      



       // AreaSummary_to_Overall --------------------------------------------------------------
       this.AreaSummary_to_Overall = () => {
        
        // remove SVG_ModuleDeck
        this.removeSvg(this.SVG_AreaSummary) 
  
        setTimeout(() => {
          d3.select(`#MD2_Topside`).transition().duration(1000).attr('transform', `translate(157,345) scale(0.4)`)
          d3.select(`#MD2_Deckbox`).transition().duration(1000).attr('transform', `translate(118,401) scale(0.4)`)
          d3.select(`#MD2_Hull`).transition().duration(1000).attr('transform', `translate(128,415) scale(0.4)`)
        }, 300);

        setTimeout(() => {
          d3.select(`#MD2_FPU`).transition().duration(750).attr('transform', `translate(0,-300) scale(1.5)`)
        }, 1300);

        setTimeout(() => {
          this.Stage = 'AREA'
          this.BackButtonUse = 'on'
        }, 2100);
      }




      // Area_to_AreaSummary --------------------------------------------------------------
      this.Area_to_AreaSummary = () => {
        
        // remove SVG_ModuleDeck
        this.removeSvg(this.SVG_Area) 
        this.removeSvg(this.SVG_Activate)

        // this.Dashboard_Overall()
        this.SVG_Overall = this.svg.append('svg')

        if (this.Area == 'Hull') {
          this.SVG_Overall
          .append('image')
          .attr('id', 'MD2_Hull')
          .attr('transform', `translate(235,210) scale(0.93)`)
          .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/Demo/Equipment_Layout/MD2/Overall/Hull.png`)
          .style('opacity', 0)
          .style('cursor', 'pointer')
          .call(s => this.Event_Area(s,'Hull'))

          d3.select(`#MD2_Hull`)
          .transition()
          .duration(1000)
          .attr('transform', `translate(128,644) scale(0.4)`)
          .style('opacity', 1)
        } else {
          this.SVG_Overall
          .append('image')
          .attr('id', 'MD2_Hull')
          .attr('transform', `translate(128,644) scale(0.4)`)
          .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/Demo/Equipment_Layout/MD2/Overall/Hull.png`)
          .style('opacity', 0)
          .style('cursor', 'pointer')
          .call(s => this.Event_Area(s,'Hull'))
        }

        if (this.Area == 'Deckbox') {
          this.SVG_Overall
          .append('image')
          .attr('id', 'MD2_Deckbox')
          .attr('transform', `translate(235,210) scale(0.93)`)
          .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/Demo/Equipment_Layout/MD2/Overall/Deckbox.png`)
          .style('opacity', 0)
          .style('cursor', 'pointer')
          .call(s => this.Event_Area(s,'Deckbox'))

          d3.select(`#MD2_Deckbox`)
          .transition()
          .duration(1000)
          .attr('transform', `translate(118,431) scale(0.4)`)
          .style('opacity', 1)
        } else {
          this.SVG_Overall
          .append('image')
          .attr('id', 'MD2_Deckbox')
          .attr('transform', `translate(118,431) scale(0.4)`)
          .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/Demo/Equipment_Layout/MD2/Overall/Deckbox.png`)
          .style('opacity', 0)
          .style('cursor', 'pointer')
          .call(s => this.Event_Area(s,'Deckbox'))
        }

        if (this.Area == 'Topside') {
          this.SVG_Overall
          .append('image')
          .attr('id', 'MD2_Topside')
          .attr('transform', `translate(120,100) scale(0.93)`)
          .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/Demo/Equipment_Layout/MD2/Overall/Topside.png`)
          .style('opacity', 0)
          .style('cursor', 'pointer')
          .call(s => this.Event_Area(s,'Topside'))

          d3.select(`#MD2_Topside`)
          .transition()
          .duration(1000)
          .attr('transform', `translate(157,142) scale(0.4)`)
          .style('opacity', 1)
        } else {
          this.SVG_Overall
          .append('image')
          .attr('id', 'MD2_Topside')
          .attr('transform', `translate(157,142) scale(0.4)`)
          .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/Demo/Equipment_Layout/MD2/Overall/Topside.png`)
          .style('opacity', 0)
          .style('cursor', 'pointer')
          .call(s => this.Event_Area(s,'Topside'))
        }

        d3.select(`#keyPlan`).transition().duration(500).style('opacity', 0)
        
        setTimeout(() => {
          d3.select(`#MD2_Topside`).transition().duration(500).style('opacity', 1)
          d3.select(`#MD2_Deckbox`).transition().duration(500).style('opacity', 1)
          d3.select(`#MD2_Hull`).transition().duration(500).style('opacity', 1)
        }, 1000);

        setTimeout(() => {
          this.Charts_AreaSummary() // --> Chart
          this.Stage = 'AREA'
          this.BackButtonUse = 'on'
        }, 1500);
      }





      // Module_to_Area --------------------------------------------------------------
      this.Module_to_Area = () => {
        
        // remove SVG_ModuleDeck
        this.removeSvg(this.SVG_Module) 
  
        // Explode
        setTimeout(() => {
          if(this.Area == 'Hull') {
            this.displayGroupID(`#MISC`, 0, 300, 1)
          }
          this.MODULES[this.Area].forEach(mod => {
            d3.select(`#${mod.module}`)
            .transition().duration(1000)
            .style('opacity', 1)
            .attr('transform', ()=> {
              if (this.Area == 'Hull') return `translate(${mod.x1}, ${mod.y1}) scale(${0.25})`; else return `translate(${mod.x1}, ${mod.y1})`
            })
          })
        }, 300);

        setTimeout(() => {
          d3.select(`#Deckbox_Group`)
          .transition().duration(1000)
          .attr('transform', `translate(-400,-300) scale(1.5)`)
        }, 1000);

        setTimeout(() => {
          this.Charts_Area() // --> Chart
          this.Stage = 'MODULE'
          this.BackButtonUse = 'on'
        }, 1300);
      }






      // Deck_to_Module --------------------------------------------------------------
      this.Deck_to_Module = () => {
        
        // remove SVG_ModuleDeck
        this.removeSvg(this.SVG_ModuleDeck) 

        this.DECKS[this.Area].filter(f=> f.module == this.Module).forEach(d => {
          d3.select(`#${d.deck}`)
          .transition()
          .duration(1000)
          .attr('transform', `translate(${d.x1}, ${d.y1}) scale(0.25)`)
        })
    
        setTimeout(() => {
          let m = this.MODULES[this.Area].find(f=> f.module == this.Module)
          d3.select(`#${m.module}`)
          .transition()
          .duration(1000)
          .attr('transform', `translate(${m.x2}, ${m.y2})`)
        }, 1000);

        setTimeout(() => {
          this.MODULES[this.Area].forEach(d => {
            if(d.module == this.Module) {
              this.hiddenGroupID(`#Key_${d.module}`, 0, 500)
            } else {
              if (d.item == 'Y') this.displayGroupID(`#${d.module}`, 0, 500, 1); else this.displayGroupID(`#${d.module}`, 0, 500, 0.3)
            }            
          })
          this.hiddenGroupID(`#Legends`, 0, 500) // Legends
        }, 2000);

        setTimeout(() => {
          this.Charts_ModuleDivision() // --> Chart
          this.Stage = 'DECK'
          this.BackButtonUse = 'on'
        }, 2500);
      }





      // Equipment_to_Deck --------------------------------------------------------------
      this.Equipment_to_Deck = () => {
        
        // remove SVG_dataTable
        this.removeSvg(this.SVG_dataTable) 

        // remove SVG file of Equipments
        if(this.innerSvg) {

          this.innerSvg
          .transition().duration(500).attr('opacity', 0)

          setTimeout(() => {
            this.innerSvg.html()
            this.innerSvg.remove()
            this.innerSvg = null
          }, 500);
        }
    
        setTimeout(() => {
          let m = this.MODULES[this.Area].find(f=> f.module == this.Module)
          d3.select(`#${this.Module}`)
          .transition()
          .duration(1000)
          .attr('transform', `translate(${m.x3}, ${m.y3})`)
          
          let dk = this.DECKS[this.Area].find(f=> f.deck == this.Deck)
          d3.select(`#${this.Deck}`)
          .transition()
          .duration(1000)
          .attr('transform', `translate(${dk.x2}, ${dk.y2-250}) scale(0.25)`)
        }, 700);

        setTimeout(() => {
          this.DECKS[this.Area].filter(f=> f.module == this.Module).forEach(d => {
            if(d.deck !== this.Deck) {
              this.displayGroupID(`#${d.deck}`, 0, 300, 1)
            }
          })
        }, 1700);

        setTimeout(() => {
          this.Charts_ModuleDeck(this.Module) // --> Chart
          this.Stage = 'EQUIPMENT'
          this.BackButtonUse = 'on'
        }, 2000);
      }

      
    },
    
  }
}