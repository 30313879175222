import * as d3 from 'd3'
import __M from 'moment'
import DonutData from '../primitives/donutProps'

export default {
  data: () => ({
    chartMounted: false,

    filtering: {},
    
    scaleY: null,
    maxValue: 0,
    cutoff: null,
    
    timelineFloat: {
      start: 0,
      end: 0
    },
    timelineFor: 'skyline',    // history | skyline
    timelineKeyName: 'date',
    timelineWeekEnd: 'friday',
    
  }),
  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },
  methods: {
    onCallbackData(d) {
      d3.select(`#${this.localId}`).select(`.TEXT___${d.refCode}`).text(d.count ? d.count : '')
    },
    setDefaultValues(daterange) {
      this.filtering = {}
      
      let cutoff = __M(this.Queries.SQL2[0].CDATE).format('YYYY-MM-DD')
      this.cutoff = cutoff

      this.timelineKeyName = this.activity
      this.timeline.weekCount = []

      // force ranging the timeline -------
      let timelineRange_ = daterange
      this.setTimelineEnv(timelineRange_)
      // -------------------------- -------

      this.dataSet.forEach(d => {
        let date__ = this.getTimelineCDate(d[this.timelineKeyName])
        d.cDate = date__ ? date__.eDate : null
        d.sDate = date__ ? date__.sDate : null
        d.date = d3.timeParse('%Y-%m-%d')(d[this.timelineKeyName])
        d.monthDate = new Date(__M(d[this.timelineKeyName]).set('date', 1).format('YYYY-MM-DD'))
      })

      this.timeline.height = this.timeline.style.week.height
      if (this.timeline.style.month.display == 'Y') this.timeline.height += this.timeline.style.month.height
      if (this.timeline.style.year.display == 'Y') this.timeline.height += this.timeline.style.year.height
      
      let lightGray = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `lightGray`)
      .attr('x1', 0.772).attr('x2', 0.228).attr('y1', 0.228).attr('y2', 0.919)
      lightGray.append('stop').attr('stop-color', '#fff').attr('offset', '0').attr('stop-opacity', 1)
      lightGray.append('stop').attr('stop-color', '#d0d2d3').attr('offset', '1').attr('stop-opacity', 1)

      
      let fillWhite = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `fillWhite`)
      .attr('x1', 0.772).attr('x2', 0.228).attr('y1', 0.228).attr('y2', 0.919)
      fillWhite.append('stop').attr('stop-color', '#fff').attr('offset', '1').attr('stop-opacity', 1)
      fillWhite.append('stop').attr('stop-color', '#fff').attr('offset', '1').attr('stop-opacity', 1)

      let fillOrange = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `fillOrange`)
      .attr('x1', 0.772).attr('x2', 0.228).attr('y1', 0.228).attr('y2', 0.919)
      fillOrange.append('stop').attr('stop-color', '#FFA726').attr('offset', '1').attr('stop-opacity', 1)
      fillOrange.append('stop').attr('stop-color', '#FFA726').attr('offset', '1').attr('stop-opacity', 1)

      
      let fillYellow = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `fillYellow`)
      .attr('x1', 0.772).attr('x2', 0.228).attr('y1', 0.228).attr('y2', 0.919)
      fillYellow.append('stop').attr('stop-color', '#FFEB3B').attr('offset', '1').attr('stop-opacity', 1)
      fillYellow.append('stop').attr('stop-color', '#FFEB3B').attr('offset', '1').attr('stop-opacity', 1)

      let fillRed = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `fillRed`)
      .attr('x1', 0.772).attr('x2', 0.228).attr('y1', 0.228).attr('y2', 0.919)
      fillRed.append('stop').attr('stop-color', '#D32F2F').attr('offset', '1').attr('stop-opacity', 1)
      fillRed.append('stop').attr('stop-color', '#D32F2F').attr('offset', '1').attr('stop-opacity', 1)

      
      let fillBlue = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `fillBlue`)
      .attr('x1', 0.772).attr('x2', 0.228).attr('y1', 0.228).attr('y2', 0.919)
      fillBlue.append('stop').attr('stop-color', '#00B0FF').attr('offset', '1').attr('stop-opacity', 1)
      fillBlue.append('stop').attr('stop-color', '#00B0FF').attr('offset', '1').attr('stop-opacity', 1)

      
      let fillLightGreen = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `fillLightGreen`)
      .attr('x1', 0.772).attr('x2', 0.228).attr('y1', 0.228).attr('y2', 0.919)
      fillLightGreen.append('stop').attr('stop-color', '#689F38').attr('offset', '1').attr('stop-opacity', 1)
      fillLightGreen.append('stop').attr('stop-color', '#689F38').attr('offset', '1').attr('stop-opacity', 1)

      
      let fillGreen = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `fillGreen`)
      .attr('x1', 0.772).attr('x2', 0.228).attr('y1', 0.228).attr('y2', 0.919)
      fillGreen.append('stop').attr('stop-color', '#2E7D32').attr('offset', '1').attr('stop-opacity', 1)
      fillGreen.append('stop').attr('stop-color', '#2E7D32').attr('offset', '1').attr('stop-opacity', 1)


    },
    setTimelineEnv() {
      let dataSet = JSON.parse(JSON.stringify(this.DataItems))

      const datasource_ = dataSet.filter(d => d[this.activity] != null)

      let datasource = [
        { [this.activity]: new Date(Math.min.apply(null,datasource_.map(d => new Date(d[this.activity]))))},
        { [this.activity]: new Date(Math.max.apply(null,datasource_.map(d => new Date(d[this.activity]))))},
      ]

      this.timeline.year = []
      this.timeline.month = []
      this.timeline.week = []
      this.timeline.months = []

      if(datasource.length === 0) {
        console.log('[USER: setTimelineEnv()] Timeline data is not available in the "DateItems".')
        return
      }
      


      let dates_ = datasource.map(d_ => __M(d_[this.timelineKeyName]).toDate())
      
      let sDate_ = __M(Math.min(...dates_)).add(-this.timelineFloat.start, 'weeks').toDate()
      let eDate_ = __M(__M(Math.max(...dates_)).add(this.timelineFloat.end, 'weeks').format('YYYY-MM-DD 23:59:59')).toDate()

      // let weekNames = ['sunday', 'monday', 'tuesday', 'wednesday ', 'thursday', 'friday', 'saturday']
      // let weekDay = weekNames.findIndex(n_ => n_ == this.timelineWeekEnd)
      // let diff_ = weekDay - sDate_.getDay()
      // sDate_ = __M(sDate_).add(diff_ < 0 ? 7 + diff_ : diff_, 'days').toDate()

      // diff_ = weekDay - eDate_.getDay()
      // eDate_ = __M(eDate_).add(diff_ < 0 ? 7 + diff_ : diff_, 'days').toDate()

      // ### Set Timeline-Values for the Week ### ---------------------------
      let nextDate = __M(__M(sDate_).format('YYYY-MM-DD 23:59:59')).toDate()
      while(nextDate <= eDate_) {
        let value_ = {
          sDate: __M(__M(nextDate).add(-6, 'days').format('YYYY-MM-DD')).toDate(),
          mDate: null,
          eDate: nextDate
        }
        value_.mDate = new Date(d3.mean([value_.sDate, value_.eDate]))
        
        this.timeline.week.push({ name: '', ...value_ })
        nextDate = __M(nextDate).add(7, 'days').toDate()
      }
      // ### ------------------------------------- --------------------------

      

      let sMonth_ = __M(Math.min(...dates_)).add(-this.timelineFloat.start, 'month').toDate()
      let eMonth_ = __M(__M(Math.max(...dates_)).add(this.timelineFloat.end, 'month').format('YYYY-MM-DD 09:00:00')).toDate()


      let monthSets = []
      let nextMonth = __M(__M(sMonth_).format('YYYY-MM-DD 09:00:00')).toDate()
      while(nextMonth <= eMonth_) {

        monthSets.push(nextMonth)
        nextMonth = __M(nextMonth).add(1, 'month').toDate()
        
      }


      
      // ### Set Timeline-Values for the Month ### --------------------------
      eDate_ = null
      let months_ = []
      let months__ = []
      this.timeline.week.forEach((w_, i) => {
        if(eDate_ != d3.timeFormat('%Y-%m')(w_.eDate)) {
          eDate_ = d3.timeFormat('%Y-%m')(w_.eDate)

          if(i > 0) {
            months_.push(months__)
            months__ = []
          }
        }
        months__.push(w_)
        if(i === this.timeline.week.length - 1) months_.push(months__)
      })
      months_.forEach(m_ => {
        this.timeline.month.push({
          name: d3.timeFormat('%b')(m_[0].eDate), 
          sDate: m_[0].sDate, 
          mDate: new Date(d3.mean([ m_[0].sDate, m_[m_.length - 1].eDate ])),
          eDate: m_[m_.length - 1].eDate
        })
      })


      monthSets.forEach(m_ => {
        this.timeline.months.push({
          name: d3.timeFormat('%b')(new Date(m_)),
          date: new Date(m_)
        })
      })


      
      // ### Set Timeline-Values for the Year ### ---------------------------
      eDate_ = null
      let years_ = []
      let years__ = []
      this.timeline.week.forEach((w_, i) => {
        if(eDate_ != d3.timeFormat('%Y')(w_.eDate)) {
          eDate_ = d3.timeFormat('%Y')(w_.eDate)

          if(i > 0) {
            years_.push(years__)
            years__ = []
          }
        }
        years__.push(w_)
        if(i === this.timeline.week.length - 1) years_.push(years__)
      })
      years_.forEach(m_ => {
        this.timeline.year.push({
          name: d3.timeFormat('%Y')(m_[m_.length - 1].eDate), 
          sDate: m_[0].sDate, 
          mDate: new Date(d3.mean([ m_[0].sDate, m_[m_.length - 1].eDate ])),
          eDate: m_[m_.length - 1].eDate
        })
      })
      // ### ------------------------------------- --------------------------

      
      // ### Set necessary ENV-Values for the Skyline ### -------------------
      this.timeline.length = this.timeline.months.length * (this.skyline.box.width + (this.skyline.box.gap))



      // get min & max values of the timeline-scale
      this.timeline.scale = d3.scaleTime()
      .domain([
        this.timeline.months[0].date, 
        this.timeline.months[this.timeline.months.length - 1].date
      ])
      .rangeRound([0, ((this.timeline.months.length -1) * (this.skyline.box.width + (this.skyline.box.gap)))])
    },

    getTimelineCDate(date) {
      let d_ = new Date(date)
      let cdate = this.timeline.week.find(w_ => d_ >= w_.sDate && d_ <= w_.eDate)
      // return cdate ? __M(__M(cdate.eDate).format('YYYY-MM-DD')).toDate() : null
      return cdate ? cdate : null
    },
    setData() {
      this.dataSet = JSON.parse(JSON.stringify(this.DataItems.filter(f => !!f[this.activity])))
    },
    setFilter(type, colName, value) {
      if(type == 'multi') {
        // init the object for the column if not present
        if(!this.filtering[colName]) this.filtering[colName] = []

        let index_ = this.filtering[colName].findIndex(v_ => v_ == value)
        if(index_ >= 0) {
          this.filtering[colName][index_] = null
          this.filtering[colName] = this.filtering[colName].filter(v_ => !!v_)
        } else {
          this.filtering[colName].push(value)
        }

      } else if(type == 'multi-pretended') {
        // init the object for the column if not present
        if(!this.filtering[colName]) this.filtering[colName] = value
        else delete this.filtering[colName]

      } else {  // for the single select or a value required
        if(this.searchFields.includes(colName)) {
          this.searchFields.forEach(c_ => {
            if(Object.keys(this.filtering).findIndex(k_ => k_ == c_) >= 0) this.filtering[c_] = ''
          })
        }

        this.filtering[colName] = value
      }
    },
    visible(values) {
      let visible_ = false
      

      if (this.filtering['STATUS'] && this.filtering['STATUS'].includes(values['STATUS'])) {
        visible_ = true
      }
      if (this.filtering['STATUS'] && this.filtering['STATUS'].includes('Total')) {
        visible_ = true
      }
      
      return visible_
    },
  }
}