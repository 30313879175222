import * as d3 from 'd3'

export default {
  methods: {

    draw_Forms() {

      this.stage_Header = this.svg
      .append('g')
      .attr('id', 'common_header_area')
      .attr('transform', 'translate(0, 0)')

      // China1 project logo 
      this.loadSvg(
        this.stage_Header, {
        x   : 20,
        y   : 20,
        url : '/China1/Information_Guide/svg/3d_Logo.svg'
      })

      this.stage_Header
        .append('text')
        .attr('transform', `translate(25, 90)`)
        .style('font-family', 'roboto').style('font-size', 16).style('fill', '#333')
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text('Facility Map Information Guide - ')

      this.stage_Header
        .append('text')
        .attr('id', 'el_location_subtitle')
        .attr('transform', `translate(255, 90)`)
        .style('font-family', 'roboto').style('font-size', 16).
        style('fill', '#44A9DF').attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle').attr('font-weight', 400)
        .text('Main Plant')

      this.draw_Toggle_v1(this.stage_Header, {
          variable: 'Toggle', // variable for the value of toggle status
          id: 'Language', // Group ID
          status: 'on',
          x: 10,
          y: 115,
          width: 40,
          text: 'English/中文',
          ccolor: '#fff',
          on: {
            value: 'EN', // <- on
            bcolor: '#44A9DF',
            tcolor: '#fff',
            text_x: 26, text_y: 9.5,
            slide_x: 51, slide_y: 9,
          },
          off: {
            value: 'CN', // <- off
            bcolor: '#D92129',
            tcolor: '#fff',
            text_x: 40, text_y: 9.5,
            slide_x: 29, slide_y: 9,
          }      
        },
        this.call_Language
      )
      this.button_Home(this.stage_Header, {x: 150, y: 110, scale: 0.8,}, this.call_Home)
    },

    rebuild_Text() {
      this.Areas.forEach((d,i) => {
        d3.select(`#Line${d.NO}`).remove()
        d3.select(`#LIST_${d.NO}`).remove()
      })
      this.draw_ListboxDetail()
    },

    
  }
}
