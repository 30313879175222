export default {
  width: 140,
  gab: 30,
  attrs: [
    { 
      text: 'Plan',
      refColumn: 'PLAN',
      fColor: '#9E9E9E', 
      tColor: '#333333'
    },
    { 
      text: 'Actual',
      refColumn: 'ACTUAL',
      fColor: '#43A047', 
      tColor: '#2E7D32'
    },
  ]
}
