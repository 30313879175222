import Defs from '../../../../../../includes/primitives/colorDefs'
import Filter from '../../../../../../includes/primitives/Filter_Defs'

export default {
  data: () => ({

   
  }),

  methods: {

    set_Gradients() {

      let defs = this.svg.append('defs')


    }
  }
}