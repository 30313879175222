import * as d3 from 'd3'

export default {
  methods: {
    /*
      call_Coordinate()
      call_AreaList()
    */

    call_Coordinate(selection, d) {
      selection
      .on('mouseover', () => {
        this.innerSvg.select(`#BOX-${d.code}`)
        .transition().duration(250)
        .style('opacity', .5)

        this.innerSvg.select(`#AREA-${d.code}`)
        .transition().duration(250)
        .attr('transform', `translate(0, 0) scale(1)`)
        .style('opacity', 1)
        
        if (this.route.to.path !== 'Lev1') return

        // Animation effect for displaying the Package Group
        this.innerSvg.select(`#package_group_${d.code}`) 
        .transition().duration(250)
        .style('opacity', 1)

        // Animation effect for scrolling the Package box
        this.innerSvg.select(`#package_box_${d.code}`)
        .transition().duration(500)
        .attr('height', d.height)

        // Animation effect for expanding the area list by package
        this.Areas.filter(f => f.package == d.code).forEach((a,i) => {
          this.innerSvg.select(`#package_area_list_${a.code}`)
          .transition().duration(i*d.duration)
          .attr('transform', `translate(${5}, ${15 + (i*15)})`)
          .style('opacity', 1)
        })
      })

      .on('mouseout', () => {
        this.innerSvg.select(`#BOX-${d.code}`)
        .transition().duration(250)
        .style('opacity', 0)

        this.innerSvg.select(`#AREA-${d.code}`)
        .transition().duration(250)
        .attr('transform', `translate(${d.x}, ${d.y}) scale(0)`)
        .style('opacity', 0)

        if (this.route.to.path !== 'Lev1') return
        
        // Animation effect for displaying the Package Group
        this.innerSvg.select(`#package_group_${d.code}`)
        .transition().duration(250)
        .style('opacity', 0)

        // Animation effect for scrolling the Package box
        this.innerSvg.select(`#package_box_${d.code}`)
        .transition().duration(500)
        .attr('height', 0)

        // Animation effect for expanding the area list by package
        this.Areas.filter(f => f.package == d.code).forEach((a,i) => {
          this.innerSvg.select(`#package_area_list_${a.code}`)
          .attr('transform', `translate(${5}, ${15})`)
          .style('opacity', 0)
        })
      })
    },


    call_SEIList(selection, d) {
      selection
      .on('mouseover', () => {
        d3.select(`#SEI-${d.SEI}`).transition().duration(300).attr('font-weight', 'bold')
        d3.select(`#BOX-${d.SEI}`).transition().duration(300).style('opacity', 0.7)
        d3.select(`#NO-${d.SEI}`).selectAll('polygon').transition().duration(300).style('fill', '#83D2F5')
      })
      .on('mouseout', () => {
        d3.select(`#SEI-${d.SEI}`).transition().duration(300).attr('font-weight', 'normal')
        d3.select(`#BOX-${d.SEI}`).transition().duration(300).style('opacity', 0)
        d3.select(`#NO-${d.SEI}`).selectAll('polygon').transition().duration(300).style('fill', '#eaeaea')
      })
    },


    call_PhotoList(selection, d) {
      selection
      .on('mouseover', () => {
        d3.select(`#BOX-${d.SEI}`).transition().duration(300).style('opacity', 1)
        d3.select(`#NO-${d.SEI}`).selectAll('polygon').transition().duration(300).style('fill', '#83D2F5')
        
      })
      .on('mouseout', () => {
        d3.select(`#BOX-${d.SEI}`).transition().duration(300).style('opacity', 0)
        d3.select(`#NO-${d.SEI}`).selectAll('polygon').transition().duration(300).style('fill', '#eaeaea')
      })
    },
    
  }
}