export default {
  width: 130,
  gab: 10,
  attrs: [
    { 
      text1: 'Total',
      text2: '',
      refColumn: 'TOTAL',
      codeValue   : 'Total', // fixed
      filterColumn: 'TOTAL',
      fill : 'lightGray', 
      fColor: 'Transparent', 
      tColor: '#212121',
      on: true
    },
    { 
      text1: 'Not started',
      text2: '',
      refColumn: 'NOT_STARTED',
      codeValue   : 'Not Started',
      filterColumn: 'RFSU_STATUS',
      fill : 'fillWhite', 
      fColor: '#BDBCBC', 
      tColor: '#818181',
      on: true
    },
    { 
      text1: 'In progress',
      text2: '',
      refColumn: 'IN_PROGRESS',
      codeValue   : 'In progress',
      filterColumn: 'RFSU_STATUS',
      fill : 'fillYellow', 
      fColor: '#FFD600',
      tColor: '#fff',
      on: true
    },
    { 
      text1: 'B ITR Completed',
      refColumn: 'BITR_COMPLETED',
      codeValue   : 'B ITR Completed',
      filterColumn: 'RFSU_STATUS',
      fill : 'fillBlue', 
      fColor: '#66CCFF', 
      tColor: '#fff',
      on: true
    },
    { 
      text1: 'CTP/SAT Complete',
      text2: '',
      refColumn: 'CTPSAT_COMPLETED',
      codeValue   : 'CTPSAT Completed',
      filterColumn: 'RFSU_STATUS',
      fill : 'fillBlueblue', 
      fColor: '#448AFF', 
      tColor: '#fff',
      on: true
    },
    { 
      text1: 'WD Complete',
      refColumn: 'WD_COMPLETE',
      codeValue   : 'WD Complete',
      filterColumn: 'RFSU_STATUS',
      fill : 'fillLightGreen', 
      fColor: '#C0CA33', 
      tColor: '#fff',
      on: true
    },
    { 
      text1: 'RFSU/RFU Complete',
      text2: '',
      refColumn: 'RFSU_COMPLETE',
      codeValue   : 'Full RFDC Complete',
      filterColumn: 'RFSU_STATUS',
      fill : 'fillGreen', 
      fColor: '#43A047', 
      tColor: '#fff',
      on: true
    },
  ]
}
