import Defs from '../../../../../../includes/primitives/colorDefs'

export default {
  data: () => ({
    Package: [
      { 
        code: 'P1', 
        x: 967,  y: 458,
        px: 1042, py: 318, width: 230, height:160,  duration: 60,
        scale_in: 1.2, scale_out: 0.7, 
      },
      { 
        code: 'P2', 
        x: 653,  y: 485,
        px: 773,  py: 271, width: 230, height:70,   duration: 130,
        scale_in: 1.1, scale_out: 0.65,
      },
      { 
        code: 'P3', 
        x: 860,  y: 673,
        px: 840,  py: 697, width: 295, height:220,  duration: 35,
        scale_in: 1.3, scale_out: 0.9, 
      },
    ],


    SEIs: [
      { XY: 1, SN: 0,  AREA: '111300', SEI: '111300', SEI_ENG: 'Common area',                                                         },
      { XY: 1, SN: 1,  AREA: '111300', SEI: '111342', SEI_ENG: 'Sanitary Sewage Sump',                                                },
      { XY: 1, SN: 2,  AREA: '111300', SEI: '111394', SEI_ENG: 'Co-product Operator Shelter',                                         },
      
      { XY: 1, SN: 0,  AREA: '121300', SEI: '121300', SEI_ENG: 'Common area',                                                         },
      { XY: 2, SN: 1,  AREA: '121300', SEI: '121301', SEI_ENG: 'GPPE Interconnecting Pipe Rack',                                      },
      { XY: 3, SN: 2,  AREA: '121300', SEI: '121302', SEI_ENG: 'GPPE Feed And Utility Area  A',                                       },
      { XY: 3, SN: 3,  AREA: '121300', SEI: '121303', SEI_ENG: 'GPPE Feed And Utility Area B',                                        },
      { XY: 1, SN: 4,  AREA: '121300', SEI: '121304', SEI_ENG: 'GPPE Extrusion Building',                                             },
      { XY: 4, SN: 5,  AREA: '121300', SEI: '121305', SEI_ENG: 'GPPE Thermal Oxidizer Blower Area',                                   },
      { XY: 4, SN: 6,  AREA: '121300', SEI: '121306', SEI_ENG: 'GPPE Pellet Dryers Classifiers And Surge Bin Area',                   },
      { XY: 2, SN: 7,  AREA: '121300', SEI: '121307', SEI_ENG: 'GPPE Pellet Transfer Compressor Area',                                },
      { XY: 2, SN: 8,  AREA: '121300', SEI: '121308', SEI_ENG: 'GPPE Elutriator And Pellet Silos Area',                               },
      { XY: 3, SN: 9,  AREA: '121300', SEI: '121311', SEI_ENG: 'GPPE Reaction And Purge Bin Area Of Line 1',                          },
      { XY: 3, SN: 10, AREA: '121300', SEI: '121312', SEI_ENG: 'GPPE Vent Gas Recovery And Propylene Refrigeration Area Of Line 1',   },
      { XY: 4, SN: 11, AREA: '121300', SEI: '121313', SEI_ENG: 'GPPE Granules Conveying System Of Line 1',                            },
      { XY: 1, SN: 12, AREA: '121300', SEI: '121321', SEI_ENG: 'GPPE Reaction And Purge Bin Area Of Line 2',                          },
      { XY: 1, SN: 13, AREA: '121300', SEI: '121322', SEI_ENG: 'GPPE Vent Gas Recovery And Propylene Refrigeration Area Of Line 2',   },
      { XY: 1, SN: 14, AREA: '121300', SEI: '121323', SEI_ENG: 'GPPE Granules Conveying System Of Line 2',                            },
      { XY: 4, SN: 15, AREA: '121300', SEI: '121332', SEI_ENG: 'GPPE CHEMICAL STORAGE',                                               },
      { XY: 1, SN: 16, AREA: '121300', SEI: '121342', SEI_ENG: 'GPPE Waste Water/Storm Water Area',                                   },
      { XY: 4, SN: 17, AREA: '121300', SEI: '121391', SEI_ENG: 'GPPE RIE 5',                                                          },
      { XY: 3, SN: 18, AREA: '121300', SEI: '121392', SEI_ENG: 'GPPE Substation',                                                     },
       
      { XY: 2, SN: 0,  AREA: '121400', SEI: '121400', SEI_ENG: 'Common area',                                                         },
      { XY: 1, SN: 1,  AREA: '121400', SEI: '121401', SEI_ENG: 'Compressor House',                                                    },
      { XY: 1, SN: 2,  AREA: '121400', SEI: '121402', SEI_ENG: 'Reaction Area',                                                       },
      { XY: 1, SN: 3,  AREA: '121400', SEI: '121403', SEI_ENG: 'Hp Recycle Area',                                                     },
      { XY: 1, SN: 4,  AREA: '121400', SEI: '121404', SEI_ENG: 'Purge Gas Area',                                                      },
      { XY: 1, SN: 5,  AREA: '121400', SEI: '121405', SEI_ENG: 'Extrusion Area',                                                      },
      { XY: 4, SN: 6,  AREA: '121400', SEI: '121406', SEI_ENG: 'Compressor Lubrication Cooling Oil',                                  },
      { XY: 4, SN: 7,  AREA: '121400', SEI: '121407', SEI_ENG: 'Initiator Mixing',                                                    },
      { XY: 1, SN: 8,  AREA: '121400', SEI: '121408', SEI_ENG: 'Chilled Water',                                                       },
      { XY: 1, SN: 9,  AREA: '121400', SEI: '121409', SEI_ENG: 'Flare Knock-Out',                                                     },
      { XY: 1, SN: 10, AREA: '121400', SEI: '121410', SEI_ENG: 'Utility Water Area',                                                  },
      { XY: 4, SN: 11, AREA: '121400', SEI: '121411', SEI_ENG: 'Modifier and Solvent System',                                         },
      { XY: 2, SN: 12, AREA: '121400', SEI: '121412', SEI_ENG: 'Vent Gas Treatment Package ',                                         },
      { XY: 2, SN: 13, AREA: '121400', SEI: '121413', SEI_ENG: 'Pellet Degassing Silos',                                              },
      { XY: 2, SN: 14, AREA: '121400', SEI: '121414', SEI_ENG: 'Blend And Bagging Silos',                                             },
      { XY: 1, SN: 15, AREA: '121400', SEI: '121415', SEI_ENG: 'At Line-LAB',                                                         },
      { XY: 1, SN: 16, AREA: '121400', SEI: '121442', SEI_ENG: 'Sump Area',                                                           },
      { XY: 1, SN: 17, AREA: '121400', SEI: '121491', SEI_ENG: 'LDPE RIE 3',                                                          },
      { XY: 3, SN: 18, AREA: '121400', SEI: '121492', SEI_ENG: 'LDPE Substation',                                                     },
      
      { XY: 2, SN: 0,  AREA: '121900', SEI: '121900', SEI_ENG: 'Common area',                                                         },
      { XY: 2, SN: 1,  AREA: '121900', SEI: '121901', SEI_ENG: 'PP BATTERY LIMIT PIPE RACK',                                          },
      { XY: 1, SN: 2,  AREA: '121900', SEI: '121902', SEI_ENG: 'PP TEAL FACILITY AREA',                                               },
      { XY: 4, SN: 3,  AREA: '121900', SEI: '121903', SEI_ENG: 'PP GRANULES TRANSFER AND EXTRUSION BUILDING',                         },
      { XY: 2, SN: 4,  AREA: '121900', SEI: '121904', SEI_ENG: 'PP PELLET SILOS AREA',                                                },
      { XY: 4, SN: 5,  AREA: '121900', SEI: '121905', SEI_ENG: 'PP PELLET ELUTRIATION AND BAGGING AREA',                              },
      { XY: 1, SN: 6,  AREA: '121900', SEI: '121906', SEI_ENG: 'PP HYDROGEN COMPRESSOR UNIT',                                         },
      { XY: 1, SN: 7,  AREA: '121900', SEI: '121907', SEI_ENG: 'PP OIL/GREASE STORAGE AND MIXING,PROPYLENE DRYER AREA',               },
      { XY: 1, SN: 8,  AREA: '121900', SEI: '121908', SEI_ENG: 'PP OIL TREATMENT,CHILLED WATER AND STEAM CONDENSATE DRUM AREA',       },
      { XY: 2, SN: 9,  AREA: '121900', SEI: '121909', SEI_ENG: 'PP REGENERATIVE THERMAL OXIDIZER AREA',                               },
      { XY: 3, SN: 10, AREA: '121900', SEI: '121910', SEI_ENG: 'CPP-1 POLYMERIZATION AREA',                                           },
      { XY: 4, SN: 11, AREA: '121900', SEI: '121911', SEI_ENG: 'CPP-1 PROPYLENE FEED AND STEAM CONDENSATE DRUM AREA',                 },
      { XY: 4, SN: 12, AREA: '121900', SEI: '121912', SEI_ENG: 'CPP-1 CATALYST PREPARATION AND METERING AREA',                        },
      { XY: 4, SN: 13, AREA: '121900', SEI: '121913', SEI_ENG: 'CPP-1 BULK POLYMERIZATION AREA',                                      },
      { XY: 4, SN: 14, AREA: '121900', SEI: '121914', SEI_ENG: 'CPP-1 GAS PHASE REACTORS,POLYMER DEGASSING AND GRANULES DRYING AREA', },
      { XY: 3, SN: 15, AREA: '121900', SEI: '121915', SEI_ENG: 'CPP-1 POLYMERIZATION AREA PIPE RACK',                                 },
      { XY: 4, SN: 16, AREA: '121900', SEI: '121916', SEI_ENG: 'CPP-1 TEAL SCRUBBER AND RECYCLE GAS COMPRESSOR AREA',                 },
      { XY: 4, SN: 17, AREA: '121900', SEI: '121917', SEI_ENG: 'CPP-1 BLOWDOWN AREA',                                                 },
      { XY: 1, SN: 18, AREA: '121900', SEI: '121920', SEI_ENG: 'CPP-2 POLYMERIZATION AREA',                                           },
      { XY: 1, SN: 19, AREA: '121900', SEI: '121921', SEI_ENG: 'CPP-2 PROPYLENE FEED AREA',                                           },
      { XY: 1, SN: 20, AREA: '121900', SEI: '121922', SEI_ENG: 'CPP-2 CATALYST PREPARATION AND METERING AREA',                        },
      { XY: 3, SN: 21, AREA: '121900', SEI: '121923', SEI_ENG: 'CPP-2 BULK POLYMERIZATION AREA',                                      },
      { XY: 2, SN: 22, AREA: '121900', SEI: '121924', SEI_ENG: 'CPP-2 POLYMER DEGASSING AND GRANULES DRYING AREA',                    },
      { XY: 1, SN: 23, AREA: '121900', SEI: '121925', SEI_ENG: 'CPP-2 POLYMERIZATION AREA PIPE RACK',                                 },
      { XY: 1, SN: 24, AREA: '121900', SEI: '121926', SEI_ENG: 'CPP-2 TEAL SCRUBBER AND RECYCLE GAS COMPERSSOR AREA',                 },
      { XY: 3, SN: 25, AREA: '121900', SEI: '121927', SEI_ENG: 'CPP-2 BLOWDOWN AND ETHYLENE COMPRESSOR AREA',                         },
      { XY: 1, SN: 26, AREA: '121900', SEI: '121931', SEI_ENG: 'PP NEW LUBE/WASTE OIL DRUM STORAGE',                                  },
      { XY: 2, SN: 27, AREA: '121900', SEI: '121932', SEI_ENG: 'PP CHEMICAL STORAGE',                                                 },
      { XY: 2, SN: 28, AREA: '121900', SEI: '121942', SEI_ENG: 'PP WASTE WATER/STORM WATER AREA',                                     },
      { XY: 1, SN: 29, AREA: '121900', SEI: '121991', SEI_ENG: 'PP RIE 4',                                                            },
      { XY: 1, SN: 30, AREA: '121900', SEI: '121992', SEI_ENG: 'PP Substation',                                                       },
      
      { XY: 2, SN: 0,  AREA: '127700', SEI: '127700', SEI_ENG: 'Common area',                                                         },
      { XY: 4, SN: 1,  AREA: '127700', SEI: '127742', SEI_ENG: 'Co-product Waste Water Sump',                                         },
      { XY: 4, SN: 2,  AREA: '127700', SEI: '127791', SEI_ENG: 'RIE-2',                                                               },
      { XY: 4, SN: 3,  AREA: '127700', SEI: '127792', SEI_ENG: 'Co-product Substation',                                               },
            
      { XY: 4, SN: 1,  AREA: '127800', SEI: '127800', SEI_ENG: 'GHU ',                                                                },

      { XY: 2, SN: 0,  AREA: '127900', SEI: '127900', SEI_ENG: 'Common area',                                                         },
      { XY: 3, SN: 1,  AREA: '127900', SEI: '127901', SEI_ENG: 'Furnaces Area',                                                       },
      { XY: 1, SN: 2,  AREA: '127900', SEI: '127902', SEI_ENG: 'Quench Area',                                                         },
      { XY: 4, SN: 3,  AREA: '127900', SEI: '127903', SEI_ENG: 'Compression Area',                                                    },
      { XY: 4, SN: 4,  AREA: '127900', SEI: '127904', SEI_ENG: 'Cold Fraction Area',                                                  },
      { XY: 2, SN: 5,  AREA: '127900', SEI: '127905', SEI_ENG: 'Hot Fraction Area',                                                   },
      { XY: 2, SN: 6,  AREA: '127900', SEI: '127906', SEI_ENG: 'PSA Area',                                                            },
      { XY: 4, SN: 7,  AREA: '127900', SEI: '127907', SEI_ENG: 'Spent Caustic Area',                                                  },
      { XY: 3, SN: 8,  AREA: '127900', SEI: '127908', SEI_ENG: 'Steam & Condensate Area',                                             },
      { XY: 1, SN: 9,  AREA: '127900', SEI: '127909', SEI_ENG: 'Benzene Removal Area',                                                },
      { XY: 3, SN: 10, AREA: '127900', SEI: '127942', SEI_ENG: 'Waste Water Sump',                                                    },
      { XY: 3, SN: 11, AREA: '127900', SEI: '127982', SEI_ENG: 'OR 2nd Substation',                                                   },
      { XY: 2, SN: 12, AREA: '127900', SEI: '127991', SEI_ENG: 'RIE-1',                                                               },
      { XY: 2, SN: 13, AREA: '127900', SEI: '127992', SEI_ENG: 'OR 1st Substation',                                                   },
             
      { XY: 4, SN: 1,  AREA: '136200', SEI: '136200', SEI_ENG: 'DIB ',                                                                },
      
      { XY: 2, SN: 0,  AREA: '151000', SEI: '151000', SEI_ENG: 'Common area',                                                         },
      { XY: 4, SN: 1,  AREA: '151000', SEI: '152000', SEI_ENG: 'Central Laboratory and Environmental Monitoring Station',             },
      { XY: 3, SN: 2,  AREA: '151000', SEI: '154000', SEI_ENG: 'Administration Office Building B (including canteen & locker room)',  },
      { XY: 3, SN: 3,  AREA: '151000', SEI: '180401', SEI_ENG: 'Waste Water Pump Station 1',                                          },
      { XY: 4, SN: 4,  AREA: '151000', SEI: '183100', SEI_ENG: 'Area B+ Substation',                                                  },
      { XY: 3, SN: 5,  AREA: '151000', SEI: '186000', SEI_ENG: 'Control Center Building',                                             },
      { XY: 3, SN: 6,  AREA: '151000', SEI: '181001', SEI_ENG: 'Infra Telecom equipment room Water and Firewater Pump Station1 area', },
      { XY: 1, SN: 7,  AREA: '151000', SEI: '381006', SEI_ENG: 'Firewater Pump Station (B+)',                                         },
      { XY: 3, SN: 8,  AREA: '151000', SEI: '150403', SEI_ENG: 'Infra Gate and Gate house 3',                                         },
      { XY: 2, SN: 9,  AREA: '151000', SEI: '150406', SEI_ENG: 'Infra Gate and Gate house 6',                                         },
      { XY: 2, SN: 10, AREA: '151000', SEI: '152014', SEI_ENG: 'Infra Environmental Monitoring station2 ',                            },
      { XY: 1, SN: 11, AREA: '151000', SEI: '154001', SEI_ENG: 'Infra Connecting Corridor Overhead Bridge',                           },
      
      { XY: 3, SN: 0,  AREA: '152400', SEI: '150401', SEI_ENG: 'Infra Gate and Gate house 1',                                         },
      { XY: 1, SN: 1,  AREA: '152400', SEI: '150402', SEI_ENG: 'Infra Gate and Gate house 2',                                         },
      { XY: 1, SN: 2,  AREA: '152400', SEI: '152400', SEI_ENG: 'Common area',                                                         },
      { XY: 4, SN: 3,  AREA: '152400', SEI: '152200', SEI_ENG: 'Maintenance Workshop',                                                },
      { XY: 4, SN: 4,  AREA: '152400', SEI: '152300', SEI_ENG: 'Warehouse Management Room and Spare Parts Warehouse',                 },
      { XY: 3, SN: 5,  AREA: '152400', SEI: '180404', SEI_ENG: 'Waste Water Pump Station 4 For Chemical Warehouse',                   },
      { XY: 3, SN: 6,  AREA: '152400', SEI: '181200', SEI_ENG: 'FF & Gas Protection Station',                                         },
      { XY: 1, SN: 7,  AREA: '152400', SEI: '182200', SEI_ENG: 'Hazardous waste temporary warehouse ',                                },
      { XY: 1, SN: 8,  AREA: '152400', SEI: '252703', SEI_ENG: 'Truck Scale3',                                                        },
      
      { XY: 1, SN: 1,  AREA: '152700', SEI: '152700', SEI_ENG: 'Main plant Dispatch Area',                                            },
      { XY: 4, SN: 2,  AREA: '152700', SEI: '150404', SEI_ENG: 'Infra Gate and Gate house 4',                                         },

      { XY: 1, SN: 0,  AREA: '152800', SEI: '152800', SEI_ENG: 'Polymer Bagging and Logistics',                                       },
      { XY: 2, SN: 1,  AREA: '152800', SEI: '152801', SEI_ENG: 'No.1 Warehouse',                                                      },
      { XY: 1, SN: 2,  AREA: '152800', SEI: '152802', SEI_ENG: 'No.2 Warehouse',                                                      },
      { XY: 2, SN: 3,  AREA: '152800', SEI: '152803', SEI_ENG: 'No.1 Package Plant',                                                  },
      { XY: 2, SN: 4,  AREA: '152800', SEI: '152804', SEI_ENG: 'No.2 Package Plant',                                                  },
      { XY: 3, SN: 5,  AREA: '152800', SEI: '152805', SEI_ENG: 'Auxiliary Facility',                                                  },
      { XY: 3, SN: 6,  AREA: '152800', SEI: '252701', SEI_ENG: 'Truck Scale1',                                                        },
      { XY: 1, SN: 7,  AREA: '152800', SEI: '252702', SEI_ENG: 'Truck Scale2',                                                        },
  
      { XY: 1, SN: 0,  AREA: '170000', SEI: '170000', SEI_ENG: 'Common area',                                                         },
      { XY: 2, SN: 1,  AREA: '170000', SEI: '124700', SEI_ENG: 'Propylene Tank Farm',                                                 },
      { XY: 4, SN: 2,  AREA: '170000', SEI: '170100', SEI_ENG: 'Ethylene Cryogenic Tank Farm',                                        },
      { XY: 2, SN: 3,  AREA: '170000', SEI: '170300', SEI_ENG: 'Pressurized Tank Farm 1',                                             },
      { XY: 1, SN: 4,  AREA: '170000', SEI: '180402', SEI_ENG: 'Waste Water Pump Station 2',                                          },
      { XY: 4, SN: 5,  AREA: '170000', SEI: '181301', SEI_ENG: 'Foam Station 1',                                                      },
      { XY: 1, SN: 6,  AREA: '170000', SEI: '270100', SEI_ENG: 'Offspec Ethylene Tank Farm',                                          },
      { XY: 1, SN: 7,  AREA: '170000', SEI: '270300', SEI_ENG: 'Pressurized Tank Farm 2',                                             },
      { XY: 4, SN: 8,  AREA: '170000', SEI: '370100', SEI_ENG: 'Hexene-1 Tank Farm',                                                  },
      { XY: 1, SN: 9,  AREA: '170000', SEI: '170091', SEI_ENG: '7RIE 7(W/OS)',                                                        },
      { XY: 2, SN: 10, AREA: '170000', SEI: '170092', SEI_ENG: 'Tank Farm 1st Substation',                                            },
      
      { XY: 4, SN: 1,  AREA: '178000', SEI: '178000', SEI_ENG: 'Loading/Unloading Station',                                           },
      { XY: 2, SN: 2,  AREA: '178000', SEI: '178001', SEI_ENG: 'Station Building',                                                    },
      
      { XY: 3, SN: 1,  AREA: '178900', SEI: '152004', SEI_ENG: 'Environmental Monitoring station1',                                   },
      { XY: 1, SN: 2,  AREA: '178900', SEI: '166800', SEI_ENG: 'Ammonia Water Tank Farm',                                             },
      { XY: 4, SN: 3,  AREA: '178900', SEI: '178600', SEI_ENG: 'Elevated Flare',                                                      },
      { XY: 1, SN: 4,  AREA: '178900', SEI: '178900', SEI_ENG: 'Ground Flare',                                                        },
      { XY: 4, SN: 5,  AREA: '178900', SEI: '178992', SEI_ENG: 'Substation',                                                          },
      { XY: 3, SN: 6,  AREA: '178900', SEI: '190400', SEI_ENG: 'Infra Eastward Pipeline',                                             },
      { XY: 4, SN: 7,  AREA: '178900', SEI: '178903', SEI_ENG: 'Infra Flare Gas Recovery Unit',                                       },


      { XY: 1, SN: 1,  AREA: '179000', SEI: '179000-01', SEI_ENG: 'Overall Level1 Pipe Rack 1',                                        },
      { XY: 1, SN: 2,  AREA: '179000', SEI: '179000-02', SEI_ENG: 'Overall Level1 Pipe Rack 2',                                        },

      { XY: 2, SN: 1,  AREA: '179100', SEI: '179100-03', SEI_ENG: 'Overall Level1 Pipe Rack 3',                                  },
      { XY: 3, SN: 2,  AREA: '179100', SEI: '179100-04', SEI_ENG: 'Overall Level1 Pipe Rack 4',                                  },
      { XY: 3, SN: 3,  AREA: '179100', SEI: '179100-05', SEI_ENG: 'Overall Level1 Pipe Rack 5',                                  },
      { XY: 3, SN: 4,  AREA: '179100', SEI: '179100-06', SEI_ENG: 'Overall Level1 Pipe Rack 6',                                  },
      { XY: 2, SN: 5,  AREA: '179100', SEI: '179100-07', SEI_ENG: 'Overall Level1 Pipe Rack 7',                                  },
      
      { XY: 4, SN: 1,  AREA: '180200', SEI: '180200', SEI_ENG: 'Cooling Water System 1',                                              },
      { XY: 4, SN: 2,  AREA: '180292', SEI: '180292', SEI_ENG: 'Substation',                                                          },
      { XY: 4, SN: 3,  AREA: '180600', SEI: '180600', SEI_ENG: 'Storm Water Pump Station and Accident Buffer Basin',                  },
      { XY: 4, SN: 4,  AREA: '181000', SEI: '181000', SEI_ENG: 'Water and Firewater Pump Station 1',                                  },
      { XY: 4, SN: 5,  AREA: '182400', SEI: '182400', SEI_ENG: 'Wastewater Pre-Treatment Unit',                                       },
      { XY: 4, SN: 6,  AREA: '182400', SEI: '182492', SEI_ENG: 'Substation',                                                          },

      { XY: 4, SN: 1,  AREA: '183000', SEI: '183000', SEI_ENG: 'Main Substation',                                                     },
      { XY: 3, SN: 2,  AREA: '183000', SEI: '187501', SEI_ENG: 'Radio Antenna tower incl Telecom equipment room',                     },
      
      { XY: 4, SN: 0,  AREA: '184300', SEI: '184300', SEI_ENG: 'Common area',                                                         },
      { XY: 1, SN: 1,  AREA: '184300', SEI: '184301', SEI_ENG: 'Chemical Water Treatment',                                            },
      { XY: 4, SN: 2,  AREA: '184300', SEI: '184302', SEI_ENG: 'Condensate Water Treatment',                                          },
      { XY: 2, SN: 3,  AREA: '184300', SEI: '184303', SEI_ENG: 'Acid & Caustic Station',                                              },
      
      { XY: 1, SN: 0,  AREA: '184800', SEI: '184800', SEI_ENG: 'Common area',                                                         },
      { XY: 2, SN: 1,  AREA: '184800', SEI: '184891', SEI_ENG: 'RIE-6',                                                               },
      { XY: 2, SN: 2,  AREA: '184800', SEI: '184892', SEI_ENG: 'Substation',                                                          },
      
      { XY: 3, SN: 0,  AREA: '185300', SEI: '185300', SEI_ENG: 'Common area',                                                         },
      { XY: 4, SN: 1,  AREA: '185300', SEI: '185392', SEI_ENG: 'Substation',                                                          },
      { XY: 2, SN: 2,  AREA: '185300', SEI: '185394', SEI_ENG: 'Operator Shelter',                                                    },
      
      { XY: 2, SN: 0,  AREA: '277000', SEI: '277000', SEI_ENG: 'Common area',                                                         },
      { XY: 2, SN: 1,  AREA: '277000', SEI: '168000', SEI_ENG: 'Naphtha Tank Farm',                                                   },
      { XY: 2, SN: 2,  AREA: '277000', SEI: '177100', SEI_ENG: 'Intermediate Tank Farm 1',                                            },
      { XY: 1, SN: 3,  AREA: '277000', SEI: '179600', SEI_ENG: 'Vapor Gas Recovery Unit',                                             },
      { XY: 1, SN: 4,  AREA: '277000', SEI: '180403', SEI_ENG: 'Waste Water Pump Station 3',                                          },
      { XY: 1, SN: 5,  AREA: '277000', SEI: '181302', SEI_ENG: 'Foam Station 2',                                                      },
      { XY: 1, SN: 6,  AREA: '277000', SEI: '181303', SEI_ENG: 'Foam Station 3',                                                      },
      { XY: 1, SN: 7,  AREA: '277000', SEI: '181304', SEI_ENG: 'Foam Station 4',                                                      },
      { XY: 4, SN: 8,  AREA: '277000', SEI: '277082', SEI_ENG: 'Tank Farm 2nd Substation',                                            },
      
      { XY: 1, SN: 1,  AREA: '277100', SEI: '170800', SEI_ENG: 'Mogas Tank Farm',                                                     },
      { XY: 1, SN: 2,  AREA: '277100', SEI: '277100', SEI_ENG: 'Intermediate Tank Farm 2',                                            },
      { XY: 2, SN: 3,  AREA: '277100', SEI: '377100', SEI_ENG: 'Intermediate Tank Farm 3',                                            },
      
      { XY: 3, SN: 1,  AREA: '280200', SEI: '280200', SEI_ENG: 'Common area',                                                         },
      { XY: 4, SN: 2,  AREA: '280200', SEI: '280292', SEI_ENG: 'Substation',                                                          },
      { XY: 2, SN: 3,  AREA: '280200', SEI: '280294', SEI_ENG: 'Operator Shelter',                                                    },
      
      { XY: 4, SN: 1,  AREA: '281000', SEI: '281000', SEI_ENG: 'Water and Firewater Pump Station 2',                                  },
      { XY: 3, SN: 2,  AREA: '281000', SEI: '281001', SEI_ENG: 'Infra Telecom equipment room Water and Firewater Pump Station2 area', },
      { XY: 3, SN: 3,  AREA: '281000', SEI: '150405', SEI_ENG: 'Infra Gate and Gate house 5',                                         },

    
    ],
    
    
    Areas: [ 
      { sn: 1,  package: 'P1', code: '111300', link: 'Y', name: 'BTX - Benzene Toluene Unit', },
      { sn: 2,  package: 'P1', code: '127700', link: 'Y', name: 'BD - Butadiene Unit', },
      { sn: 3,  package: 'P1', code: '127800', link: 'Y', name: 'GHU - Gasoline Hydrotreating Unit', },
      { sn: 4,  package: 'P1', code: '127900', link: 'Y', name: 'OR - Olefins Unit', },
      { sn: 5,  package: 'P1', code: '136200', link: 'Y', name: 'DIB - DI-Isobutylene Unit', },
      { sn: 6,  package: 'P1', code: '170000', link: 'Y', name: 'Pressurized Tank Farm Common Area', },
      { sn: 7,  package: 'P1', code: '184300', link: 'Y', name: 'Demineralized Water Unit', },
      { sn: 8,  package: 'P1', code: '184800', link: 'Y', name: 'Boiler Unit', },
      { sn: 9,  package: 'P1', code: '277000', link: 'Y', name: 'Atmospheric Tank Farm Common Area', },
      { sn: 10, package: 'P1', code: '277100', link: 'Y', name: 'Intermediate Tank Farm 2', },

      { sn: 11, package: 'P2', code: '121300', link: 'Y', name: 'GPPE - Gas Phase Polyethylene Unit', },
      { sn: 12, package: 'P2', code: '121400', link: 'Y', name: 'LDPE - Low Density Polyethylene Unit', },
      { sn: 13, package: 'P2', code: '121900', link: 'Y', name: 'PP - Polypropylene Unit', },
      { sn: 14, package: 'P2', code: '152800', link: 'Y', name: 'Polymer Bagging and Logistics', },

      { sn: 15, package: 'P3', code: '151000', link: 'Y', name: 'Common Area of Management Facilities(B+)', },
      { sn: 16, package: 'P3', code: '152400', link: 'Y', name: 'Chemical Warehouses', },
      { sn: 17, package: 'P3', code: '152700', link: 'Y', name: 'Main plant Dispatch Area', },
      { sn: 18, package: 'P3', code: '178000', link: 'Y', name: 'Loading/Unloading Station', },
      { sn: 19, package: 'P3', code: '178900', link: 'Y', name: 'Ground Flare', },

      { sn: 20, package: 'P3', code: '179000', link: 'Y', name: 'Overall Level1 Pipe Rack 1,2', },
      { sn: 21, package: 'P3', code: '179100', link: 'Y', name: 'Overall Level1 Pipe Rack 3,4,5,6,7', },

      { sn: 22, package: 'P3', code: '180200', link: 'Y', name: 'Cooling Water System 1', },
      { sn: 23, package: 'P3', code: '180292', link: 'Y', name: 'Substation',  },
      { sn: 24, package: 'P3', code: '180600', link: 'Y', name: 'Storm Water Pump Station and Accident Buffer Basin', },
      { sn: 25, package: 'P3', code: '181000', link: 'Y', name: 'Water and Firewater Pump Station 1', },
      { sn: 26, package: 'P3', code: '182400', link: 'Y', name: 'Wastewater Pre-Treatment Unit', },
      { sn: 27, package: 'P3', code: '183000', link: 'Y', name: 'Main Substation', },
      { sn: 28, package: 'P3', code: '185300', link: 'Y', name: 'Air Compression Station', },
      { sn: 29, package: 'P3', code: '280200', link: 'Y', name: 'Cooling Water System 2', },
      { sn: 30, package: 'P3', code: '281000', link: 'Y', name: 'Water and Firewater Pump Station 2', },



      // { sn: 30, package: '', code: '150200', link: 'N', name: 'Infra Overall Grading Layout Landscaping Road layout', },
      // { sn: 30, package: '', code: '150300', link: 'N', name: 'Infra Main UG Piping for whole Plant P1P2P3', },
      // { sn: 30, package: '', code: '150400', link: 'N', name: 'Infra Fence for all area P1P2P3', },
    ],
     
  }),

  methods: {

    set_Gradients() {
      let defs = this.svg.append('defs')

      let legendB = defs.append('linearGradient')
      .attr('id', `BTN`).attr('x1', 0).attr('x2', 1).attr('y1', 0).attr('y2', 1)
      legendB.append('stop').attr('stop-color', '#D2D8D9').attr('offset', '0').attr('stop-opacity', 1)
      legendB.append('stop').attr('stop-color', '#FFFFFF').attr('offset', '1').attr('stop-opacity', 1)

      let legendC = defs.append('linearGradient')
      .attr('id', `PHOTO`).attr('x1', 0).attr('x2', 1).attr('y1', 0).attr('y2', 1)
      legendC.append('stop').attr('stop-color', '#B4E3FA').attr('offset', '0').attr('stop-opacity', 1)
      legendC.append('stop').attr('stop-color', '#83D2F5').attr('offset', '1').attr('stop-opacity', 1)
    },

  }
}