import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {

    Draw_Charts() {

      let data = null, style = null, tmp = null

      let overallSummary = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0.5)`)

      
      // Overall Certificate of Inspection
      style = {
        id: 'CERT',
        x: 115,
        y: 125,
        name: 'Certificate',
        sort: 'N',
      }
      tmp = this.dataSet.find(f=> f.TYPE == 'OVERALL')
      data = [
        {title: 'Completed',  value: tmp.PROG,      qty: tmp.ACTUAL},
        {title: 'Remain',     value: 100-tmp.PROG,  qty: tmp.REMAIN}
      ]
      let Circle = {
        CircleDiameter: 60,
        CircleColorSet: ['LightBlue', 'LightGray', ],
        CircleHoleSize: 40,
        CircleStackSize: 2,
        CircleSliceSize: 5,
        CircleTitleSize: 12,
        CircleLegendSpace: 80,
      }
      let Guideline = {
        GuideDisplay: 'Y',
      }
      overallSummary
      .append('text')
      .attr('transform', `translate(${style.x}, ${style.y - Circle.CircleDiameter - 15})`)
      .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'middle')
      .text('Overall Certificate of Inspection')

      this.Chart_Pie(overallSummary, data, style, Circle, Guideline) // ---------------------------> 


      // Quarters Habitable Letter
      style = {
        id: 'LT',
        x: 300,
        y: 125,
        name: 'Letter',
        sort: 'N',
      }
      tmp = this.dataSet.find(f=> f.TYPE == 'QUARTERS')
      data = [
        {title: 'Completed',  value: tmp.PROG,      qty: tmp.ACTUAL},
        {title: 'Remain',     value: 100-tmp.PROG,  qty: tmp.REMAIN}
      ]
      
      overallSummary
      .append('text')
      .attr('transform', `translate(${style.x}, ${style.y - Circle.CircleDiameter - 15})`)
      .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'middle')
      .text('Quarters Habitable Letter')

      this.Chart_Pie(overallSummary, data, style, Circle, Guideline) // ---------------------------> 


      // SHI Shipyard
      style = {
        id: 'SHI',
        x: 485,
        y: 125,
        name: 'SHI Shipyard',
        sort: 'N',
      }
      tmp = this.dataSet.find(f=> f.TYPE == 'SHI')
      data = [
        {title: 'Completed',  value: tmp.PROG,      qty: tmp.ACTUAL},
        {title: 'Remain',     value: 100-tmp.PROG,  qty: tmp.REMAIN}
      ]
      
      overallSummary
      .append('text')
      .attr('transform', `translate(${style.x}, ${style.y - Circle.CircleDiameter - 15})`)
      .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'middle')
      .text('Quarters Habitable Letter')

      this.Chart_Pie(overallSummary, data, style, Circle, Guideline) // ---------------------------> 

    },
  }
}
           