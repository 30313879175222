<template>
  <div :id="localId"></div>
</template>

<script>
import mx_Core from './includes/mixins/importer'

export default {
  name: "j-chart-donut",
  mixins: [mx_Core],
  props: {
    id: String,

    FilterValues: {
      type: Object,
      default: () => ({})
    },

    Canvas: { type: Object, default: () => ({}) },
    DataItems: { type: Array, default: () => ([]) },
  },
  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
  },
  mounted() {
    this.register(this.draw)
  },
  methods: {
    draw() {
      this.clear()
      this.setDefaultValues()
      this.drawCanvas()

      this.drawChart()

      this.complete()

      this.postComplete()
    },
    async drawChart() {
      await this.loadSvg()
    },
    postComplete() {
      setTimeout(() => {
        let chart_ = this.innerSvg.node().getBoundingClientRect()
        this.resizableCanvasWidth = chart_.width
        this.resizableCanvasHeight = chart_.height
        this.resizeCanvas()

        let dimention = { 
          width: this.resizableCanvasWidth,
          height: this.resizableCanvasHeight,
          isreal: true,
        }
        
        this.$emit('resize', { dimention })
      }, 500);
    }
  }
}
</script>