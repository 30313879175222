import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  data: () => ({

    GWBS_LV3: 'ME05'

  }),
  methods: {
    Draw_Charts() {

      let svg = this.svg

      svg
      .append('g')
      .attr('transform', `translate(20, 20)`)
      
     // Button & Label
        let buttonWrap = svg
        .append('g')
        .attr('id', 'button_wrapper')
        .attr('transform', `translate(0, 0)`)
        .style('font-family', 'roboto')
        .style('cursor', 'pointer') 
        .on('mouseover', function(){ 
          d3.select(this).transition().duration(100).style('opacity',.5) 
        })
        .on('mouseout', function(){ 
          d3.select(this).transition().duration(100).style('opacity',1) 
        })
        .on('click', () => {
          let request_ = {
            dataType: 'row',    
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDashboard',
              id: 46,
              no: ''
            },
            filters: { 
              GWBS_LV3: this.GWBS_LV3,
              ...this.FilterValues,
              ...this.inFilters,
            },
            iFilters: {}
          }
          this.$emit('request-action', request_)
        })

        buttonWrap
        .append('rect')
        .attr('transform', `translate(0, 0)`)
        .attr('rx', 3)
        .attr('ry', 3)
        .attr('width', 40)
        .attr('height', 14)
        .attr('fill', '#e5e5e5')

        buttonWrap
        .append('text')
        .attr('transform', `translate(20, 8)`)
        .style('font-family', 'Roboto-Regular, Roboto')
        .style('font-size', 9)
        .style('fill', '#000')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text('More')
      

    },
  }
}
