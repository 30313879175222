import * as d3 from 'd3'

export default {
  data: () => ({
    dataSet: [],
    pauData: [],
    Module:[],
    modTotal: 0, modSailedaway: 0, modRemain: 0,
    pauTotal: 0, pauSailedaway: 0, pauRemain: 0,
  }),
  computed: {

    ready2draw() {

      // console.log('------------------------------')
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)

      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },

  },



  methods: {
    setDefaultValues(){
      this.dataSet = JSON.parse(JSON.stringify(this.DataItems[0]))
      this.pauData = JSON.parse(JSON.stringify(this.Queries.SQL1))

      this.pauData.sort(function(a, b) { 
        return a.MOD < b.MOD ? -1 : a.MOD > b.MOD ? 1 : 0;
      })

      this.pauData.forEach((d,i) => {
        if (i == 0) {
          this.Module.push(d.MOD)
        } else {
          if (d.MOD == this.pauData[i-1].MOD) {
            return
          } else {
            this.Module.push(d.MOD)
          }
        }
      })
      
      // Count Module
      this.Module.forEach( d => {
        let total = this.pauData.filter(f => f.MOD == d).length
        let sailedAway = this.pauData.filter(f => f.MOD == d && f.SA_ACTUAL != null).length
        
        if (total == sailedAway) this.modSailedaway += 1
      })
      this.modTotal = this.Module.length
      this.modRemain = this.modTotal - this.modSailedaway

      // Count PAU
      this.pauTotal = this.pauData.length
      this.pauSailedaway = this.pauData.filter(f => f.SA_ACTUAL != null).length
      this.pauRemain = this.pauTotal - this.pauSailedaway



      // console.log(
      //   this.modTotal,
      //   this.modSailedaway,
      //   this.modRemain,
      // )
      // console.log(
      //   this.pauTotal,
      //   this.pauSailedaway,
      //   this.pauRemain,
      // )

    },

    
    
    
  }
}