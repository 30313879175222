export default {
  methods: {
    drawProgress() {
      this.CANVAS = this.svg.append('svg').attr('id','Progress_Chart')
      this.CANVAS
      .attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight)
      .style('opacity', 1)

      let style = null
      
        // Block Status
        let itemSet = []
        style = {
          x : 50,
          y : 470,
          headTextSize: 16,
          headTitle: 'Hull Common',
          // headDescrEQ3D: d.NAMEDESC,
        }
        
        let localDataSet = this.Queries.SQL2

        this.Status.forEach((d__, i) => {
          let tColName = this.Status[i]['colName']['total']
          let aColName = this.Status[i]['colName']['actual']

          itemSet.push({
            name: d__.title,
            total: localDataSet.reduce((a, b) => a + (b[tColName] || 0), 0),
            actual: localDataSet.reduce((a, b) => a + (b[aColName] || 0), 0),
            filter: {
              STATUS: d__.code,
              LV2: localDataSet[0].LV2, 
              //Bar_Quantity_02의 조건에 따라서
              //if (d.filter.LV2 === 'HULL-COMMON') request_.iFilters.filterString = `[LV1] = 'HULL' AND [LV3] IN ('FOR','AFT')`을 넣어주기 위해서 LV2가 있는곳의 LV2를 가져온다
            },
            bColor: this.Status[i].bColor,
            opacity: this.Status[i].opacity,
          })
        })
        // console.log(itemSet)
        this.Bar_Quantity_02(this.CANVAS, itemSet, style, 0) // <---- Chart Library
      
    }
  }
}