import * as d3 from 'd3'

export default {
  methods: {
    drawBackground() {
      let backgroundImage = this.chartArea
      .append('g')
      .attr('class','backgroundImage')

      backgroundImage
      .append('image')
      .attr('class', 'image_yard_background_1')
      .attr('transform', `translate(0, 0)`)
      .style('opacity', 0)

      backgroundImage
      .append('image')
      .attr('class', 'image_yard_background_2')
      .attr('transform', `translate(0, 0)`)
      .style('opacity', 1)

      // backgroundImage
      // .append('image')
      // .attr('class', 'image_yard_background_3')
      // .attr('transform', `translate(0, 0)`)
      // .style('opacity', 1)

      // backgroundImage
      // .append('image')
      // .attr('class', 'image_yard_background_4')
      // .attr('transform', `translate(0, 0)`)
      // .style('opacity', 1)
    },
    loadBackground() {
      let num = 1
      let opstatus = parseInt(d3.select(`.image_yard_background_${num}`).style('opacity'))
      // let area = this.floatGroup

      if(opstatus === num - 1) {
        var fadeIn = `.image_yard_background_${num}`
        var fadeOut = `.image_yard_background_${num + 1}`
      } else {
        fadeIn = `.image_yard_background_${num +1 }`
        fadeOut = `.image_yard_background_${num}`
      }
      num += 1

      switch(this.stage) {
        case 'OverAll':
          var bgname = 'LV0'
        break
        case 'Mainplant':
          bgname = 'LV1'
        break
        case 'Offplots':
          bgname = 'LV1_OP'
        break
      
        case 'P1':
          bgname = 'LV2_P1'
        break
        case 'P2':
          bgname = 'LV2_P2'
        break
        case 'P3':
          bgname = 'LV2_P3'
        break

        //P2
        case 'PP':
          bgname = 'LV3_PP'
        break
        case 'LDPE':
          bgname = 'LV3_LDPE'
        break  
        case 'GPPE':
          bgname = 'LV3_GPPE'
        break  

        //P1
        case 'OR':
          bgname = 'LV3_OR'
        break
        case 'DW':
          bgname = 'LV3_DW'
        break
        case 'BOILER':
          bgname = 'LV3_BOILER'
        break
        case 'BTX':
          bgname = 'LV3_BTX'
        break
        case 'GHU':
          bgname = 'LV3_GHU'
        break
        case 'BD':
          bgname = 'LV3_BD'
        break
        case 'DIB':
          bgname = 'LV3_DIB'
        break
      }

      d3.select(fadeOut)
      .transition().duration(400)
      .attr('transform', `translate(0, 0) scale(1)`)
      .style('opacity', 0)

      d3.select(fadeIn)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/China1/SiteLayout/${bgname}.jpg`)
      .transition().delay(50).duration(400)
      .attr('transform', `translate(-30, -30) scale(1)`)
      .style('opacity', 1)
      
      return new Promise(resolve => {
        setTimeout(() => { resolve(true) }, 500)
      })
    }
  }
}