import * as d3 from 'd3'

export default {
  data: () => ({
    worktype: 'PO',
    worktypeOptions: [],
    filteredList: [],
  }),
  computed: {
    ready2draw() {

      // console.log('------------------------------')
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)

      return (
        // this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },
  watch: {
    worktype() {
      let dataSet = JSON.parse(JSON.stringify(this.DataItems))
      this.filteredList = []
      this.filteredList = dataSet.filter(d => d.WORK_TYPE_CODE == this.worktype)

      this.Draw_Charts(this.filteredList)


    }
  },
  methods: {
    setDefaultValues(){
      this.worktypeOptions = this.Queries.SQL1
      let dataSet = JSON.parse(JSON.stringify(this.DataItems))
      this.filteredList = dataSet.filter(d => d.WORK_TYPE_CODE == this.worktype)
    },
    
  }
}