<template>
  <div :id="localId"></div>
</template>

<script>
import * as d3 from 'd3'
import mx_Core from './includes/mixins/importer'            // Global & Local Mixins

export default {
  name: "j-chart-donut",
  mixins: [mx_Core],
  props: {
    id: String,
    
    FilterValues: {
      type: Object,
      default: () => ({
        MOD: 'MODULE-NO',
        MOD_DESC: 'Module Description',
      })
    },
    
    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },
    DataItems: { type: Array, default: () => ([]) },
    Queries: {type: Object, default: () => ({}) },
    ColumnProps: { type: Array, default: () => ([]) },

    values: {
      type: Object,
      default: () => ({})
    }
  },
  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
  },
  mounted() {
    this.register(this.draw)
  },

  methods: {
    draw() {
      // Check all the necessary values are available
      if(!this.ready2draw) return

      this.clear()

      this.setStyles()
      this.setDefaultValues()

      this.drawCanvas()
      this.drawChart()

      setTimeout(() => {
        this.drawTexts()
      }, 200);

      this.drawDonuts()
      this.draw_LineCharts()
      // this.draw_DropdownMenu()
      
      // 'complete' must be executed to finish the parent process.
      // It sends 'this.localId' & XmlString for some case to make 
      // displaying image easy.
      this.complete()
      this.postComplete()

      this.chartMounted = true
    },
    drawChart() {
      this.SkylineCharts = this.svg
      .append('g')
      .attr('id', 'skyline_chart_area')
      .attr('transform', `translate(0.5, 0.5)`)
      
      this.draw_Charts()
      this.draw_Timeline()
      this.draw_Legends()
      this.drawCutoff()
      
      // this.Chart_filtering()
    },
    redraw() {
      this.setDefaultValues()
      this.draw_Charts()
      // this.draw_LineCharts()
    },
    postComplete() {
      let chart_ = d3.select('#skyline_chart_area').node().getBoundingClientRect()
      let width_ = chart_.width + 80
      let height_ = chart_.height + 80

      this.resizableCanvasWidth = width_ < 980 ? 980 : width_
      this.resizableCanvasHeight = height_ < 995 ? 995 : height_
      this.resizeCanvas()

      let dimention = { 
        width: Math.ceil(width_), 
        height: Math.ceil(height_), 
        isreal: true, 
      }

      this.$emit('resize', dimention)
    }
  }
}
</script>