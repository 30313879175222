import * as d3 from 'd3'

export default {
  methods: {
    setEvent_Block() {
      this.Goto_Block_Summary = (selection, d) => {
        selection
        .on('mouseover', () => {
          if(![this.STG_Lv3_Block_Division, this.STG_Lv4_Block_Division].includes(this.stage)) return
          d3.select(`#Blk_${d.Blk}`).transition().style('opacity', 0.5)
        })
        .on('mouseout', () => {
          if(this.stage != this.STG_Lv3_Block_Division && this.stage != this.STG_Lv4_Block_Division) return
          d3.select(`#Blk_${d.Blk}`).transition().style('opacity', 1)
        })
        .on('click', () => {
          if(this.stage != this.STG_Lv3_Block_Division && this.stage != this.STG_Lv4_Block_Division) return

          // let request_ = {
          //   dataType: 'row',
          //   action: {
          //     type: 'direct',
          //     target: 'slide-modal',
          //     component: 'ServiceSvgTabs',
          //     id: 286,
          //     no: 'LIBSVGTB-0001'
          //   },
          //   filters: { 
          //     GWBS_LV1: d.Lv1, //받을값을 보낸다
          //     GWBS_LV2: d.Lv2,
          //     GWBS_LV3: d.Lv3,
          //     GWBS_LV4: d.Lv4,
          //     BLOCK: d.Blk,
          //     MBLOCK: d.Blk,
          //     DESC: '',
          //   },
          //   iFilters: {
          //     filterString : '',
          //     inputFilter  : ''
          //   }
          // }
          let dataSource = { GWBS_LV1: d.Lv1,GWBS_LV2: d.Lv2,GWBS_LV3: d.Lv3,GWBS_LV4: d.Lv4,BLOCK: d.Blk,MBLOCK: d.Blk, DESC: '', }
          let request_ = this.getRequestColumnProps('qc3ddivision', dataSource, this.ColumnProps)
          this.$emit('request-action', request_)
        })
      }
    },
  }
}