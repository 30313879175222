import * as d3 from 'd3'
import { svg } from 'd3'
// ------------------------------------------------------------------------------- Global

export default {
  methods: {
    Draw_Charts() {
      // this.loadSvg('/TORTUE/Division/SVG/HullCommon1.svg').then(source => {
      //   this.innerSvg.select('#_780').style('opacity', 0.5)
      // })
      this.loadSvg(0, 0, '/TORTUE/Common/Project_Logo_r1.svg')

      let Form_Hull = this.svg

      Form_Hull
      .append('text')
      .attr('transform', `translate(30, 90)`)
      .style('font-family', 'roboto').style('font-size', 16).style('fill', '#333').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Block Division of ')

      Form_Hull
      .append('text')
      .attr('id', 'Title')
      .attr('transform', `translate(155, 90)`)
      .style('font-family', 'roboto').style('font-size', 16).style('fill', '#44A9DF').attr('text-anchor', 'start').attr('alignment-baseline', 'middle').attr('font-weight', 400)
      .text('Hull Common')

      Form_Hull
      .append('image')
      .attr('id', 'Legends')
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Common/Legend_Division.svg`)
      .attr('transform', `translate(45.5,330.5)`).style('opacity', 1)

      Form_Hull
      .append('image')
      .attr('id', 'Hull_Common_Image')
      .attr('transform', `translate(432, 651)`)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/HullCommon.png`)

      /***Text***/
      Form_Hull
      .append('text')
      .attr('transform', `translate(524, 100)`)
      .style('font-family', 'roboto').style('font-size', 12).style('fill', '#44A9DF').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Hull Piperack')
      Form_Hull
      .append('text')
      .attr('transform', `translate(424, 234)`)
      .style('font-family', 'roboto').style('font-size', 12).style('fill', '#44A9DF').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Stool')
      Form_Hull
      .append('text')
      .attr('transform', `translate(337, 450)`)
      .style('font-family', 'roboto').style('font-size', 12).style('fill', '#44A9DF').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Mini Stool')
      Form_Hull
      .append('text')
      .attr('transform', `translate(1373, 948)`)
      .style('font-family', 'roboto').style('font-size', 12).style('fill', '#44A9DF').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Deflector')

      setTimeout(() => {
        let Overall = this.svg
        .append('g')
        .attr('id', 'Hull_Common_Group')
        .attr('opacity', 1)
        .attr('transform', `translate(0,0) scale(1)`)

        /*---------------------------------------IMAGES*/
        this.Coordinate.forEach(d => {
          Overall
          .append('image')
          .attr('id', `Blk_${d.Blk}`)
          .attr('opacity', 1).style('cursor', 'pointer')
          .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Blocks/Hull/${d.Blk}.png`)
          .attr('transform', `translate(${d.x1}, ${d.y1}) scale(${['089','090'].includes(d.Blk) ? 0.7 : 0.5})`)
          .call(s => { this.Goto_Block_Summary(s, d) })
        })

        /*---------------------------------------CODE BLOCK BOX*/
        this.dataSet.forEach(data => {
          if (data.shape == 'rect') {
            Overall
            .append('rect')
            .attr('x', data.x - data.width/2).attr('y', data.y)
            .attr('width', data.width).attr('height', data.height)
            .attr('stroke', data.color).attr('stroke-width', data.stroke).attr('fill', data.bColor)
          }  
          if (data.shape == 'ellipse') {
            Overall
            .append('ellipse')
            .attr('cx', data.x).attr('cy', data.y + (data.height/2))
            .attr('rx', data.width/2).attr('ry', data.height/2)
            .attr('stroke', data.color).attr('stroke-width', data.stroke).attr('fill', data.bColor)
          }
          Overall
          .append('text')
          .attr('transform', `translate(${data.x}, ${data.y + data.padding})`)
          .style('font-family', 'roboto').style('font-size', 10).style('fill', data.tColor).attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging')
          .text(`${data.BLOCK}`)
        })
      },50)
    },
    setEvent_Block() {
      this.Goto_Block_Summary = (selection, d) => {
        selection
        .on('mouseover', () => {
          d3.select(`#Blk_${d.Blk}`).transition().style('opacity', 0.5)
        })
        .on('mouseout', () => {
          d3.select(`#Blk_${d.Blk}`).transition().style('opacity', 1)
        })
        .on('click', () => {

          let request_ = {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceSvgTabs',
              id: 286,
              no: 'LIBSVGTB-0001'
            },
            filters: { 
              GWBS_LV1: d.Lv2, //받을값을 보낸다
              BLOCK: d.Blk,
              MBLOCK: d.Blk,
              DESC: '',
              // DESC: `${d.AREA} (${d.STATUS})`,
            },
            iFilters: {
              filterString : '',
              inputFilter  : ''
            }
          }
          this.$emit('request-action', request_)
        })
      }
    },
  }
}
