export default {
  width: 150,
  gab: 10,
  attrs: [
    { 
      text1: 'Total Subsystems',
      text2: 'with Cyber Security',
      refColumn: 'TOTAL',
      codeValue   : 'Total', // fixed
      fill : 'lightGray',
      fColor: 'Transparent', 
      tColor: '#212121',
      on: true
    },
    { 
      text1: 'Subsystems with ',
      text2: 'Cyber Security',
      text3: 'and No MC Started',
      refColumn: 'NOTSTARTED',
      codeValue   : 'Subsystems with CyberSecurity and No MC Started',
      fill : 'fillWhite',
      fColor: '#BDBCBC', 
      tColor: '#818181',
      on: true
    },
    { 
      text1: 'Subsystems with',
      text2: 'Cyber Security',
      text3: 'and Prerequisite MC Started',
      refColumn: 'APROGRESS',
      codeValue   : 'Subsystems with CyberSecurity and CyberSecurity Prerequisite MC Started',
      fill : 'fillYellow',
      fColor: '#FFD600',
      tColor: '#fff',
      on: true
    },
    { 
      text1: 'Subsystems with',
      text2: 'Cyber Security',
      text3: 'and Prerequisite MC Complete',
      refColumn: 'ACOMPLETED',
      codeValue   : 'Subsystems with CyberSecurity and CyberSecurity Prerequisite MC Complete',
      fill : 'fillLightBlue',
      fColor: '#81D4FA',
      tColor: '#fff',
      on: true
    },
    { 
      text1: 'Subsystems with',
      text2: 'Cyber Security',
      text3: 'and Partial MC Complete',
      refColumn: 'PAR_CYBERSECURITY',
      codeValue   : 'Subsystems with CyberSecurity and Partial MC Complete' ,
      fill : 'fillLightGreen',
      fColor: '#8BC34A',
      tColor: '#fff',
      on: true
    },
    { 
      text1: 'Subsystems with',
      text2: 'Cyber Security',
      text3: 'and Full MC Complete',
      refColumn: 'CYBERSECURITY',
      codeValue   : 'Subsystems with CyberSecurity and Full MC Complete',
      fill : 'fillGreen',
      fColor: '#43A047',
      tColor: '#fff',
      on: true
    }
  ]
}
