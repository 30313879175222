import * as d3 from 'd3'

export default {
  data: () => ({
    dataSet: [],
    defaultset: { 
      no:359,
      width: 550,
      height: 46,
      color: '#d2e5fe',
      linkIcon: 'N',
      iconX: 915,
      iconY: 2.5,
      iconW: 20,
      iconH: 18,
      filer: 'Y',
      fX: '10',
      fY: '0',
      fFontSize: 13,
      fColor: '#000',
    },
  }),
  computed: {
    ready2draw() {

      // console.log('------------------------------')
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)

      return (
        // this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },
  methods: {
    setDefaultValues(){
        this.dataSet = JSON.parse(JSON.stringify(this.DataItems))
    },
  }
}