import gmx_zMixins         from '../../../../../includes/Jmixins/zMixins'

// Locally declared common Data-Props & SVG Drawing Support

import lmx_watching           from './Watching'

// Charts 
import lmx_LocalValues          from './set_LocalValues'
import lmx_Forms                from './draw_Forms'
import lmx_Router               from './Router'
import lmx_Events               from './Events'
import lmx_styles               from '../primitives/styles'

import lmx_Package              from './draw_Package'
import lmx_Level1               from './draw_Level1'
import lmx_Level2               from './draw_Level2'
import lmx_Level3               from './draw_Level3'


export default {
  mixins: [
    gmx_zMixins, 

    // Local Mixins
    lmx_watching,
    lmx_LocalValues,
    lmx_Forms,
    lmx_Router,
    lmx_Events,
    lmx_styles,

    lmx_Package,
    lmx_Level1,
    lmx_Level2,
    lmx_Level3,
    
  ],
}
