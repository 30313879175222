import * as d3 from 'd3'

export default {
  data: () => ({
    dataSet: [],
    innerSvg: null,
    keyPlanSvg: null,
    Module: '41M001',

    rowHeight: 19,
  }),
  computed: {
    ready2draw() {

      // console.log('------------------------------')
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)

      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },
  methods: {
    setDefaultValues(){
      this.dataSet = JSON.parse(JSON.stringify(this.DataItems))
      this.dataSet.forEach(data => {
        //
      })

      this.svg
      .append('clipPath')
      .attr('id', 'activity_overflow_none_milestone')
      .append('rect')
      .attr('x', 20)
      .attr('y', 0)
      .attr('width', 190)
      .attr('height', this.rowHeight)
    },
  }
}