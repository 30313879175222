import * as d3 from 'd3'
// ------------------------------------------------------------------------------- Global
// Global Data & Defs
export default {
  methods: {
    Draw_Charts() {
      
      let data = null, 
          style = null, 
          cellX = null, 
          tmp = null, 
          Circle = null, 
          Guideline = null,
          item = null

      let overallMCStatus = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0)`)

      // Header_01
      data  = this.dataSet.find(f=> f.TYPE == 'B' && f.DISC == 'Overall').PROG
      style = {
        title: 'B Check Sheets',
        x: 30,
        y: 40,
        width: 90,
        height: 18,
        radius: 3,
        tSize: 11,
        tColor: '#333',
        bColor: '#E0E0DF',
        progSize: 15,
      }
      this.Header_01( overallMCStatus, data, style) // ---------------------------> 

      // SummaryTable_01
      item = this.dataSet.find(f=> f.TYPE == 'B' && f.DISC == 'Overall')
      style = {
        id:       'BITR',
        title:    ['Total', 'Complete', 'Remain'],
        cell:     [50, 110, 160],
        x:        30,
        y:        60,
        y1:       10,
        y2:       23,
        width:    170,
        height:   32,
        radius:   3,
        opacity:  0.7,
        bColor:   '#83D2F5',
        tSize:    9,
        tColor:   '#757575',
        vSize:    11,
        vColor:   '#fff',
        value:    [item.TOTAL, item.ACTUAL, item.REMAIN],
        // requests: [
        //   {
        //     dataType: 'row',
        //     action: {
        //       type: 'direct',
        //       target: 'slide-modal',
        //       component: 'ServiceDatatable',
        //       id: 104,
        //       no: ''
        //     },
        //     filters: { 
        //       // SUBSYSTEM: this.FilterValues.SYS
        //     },
        //     iFilters: {
        //       filterString : "[STAGE] = 'A'",
        //       inputFilter  : ''
        //     }
        //   },
        //   {
        //     dataType: 'row',
        //     action: {
        //       type: 'direct',
        //       target: 'slide-modal',
        //       component: 'ServiceDatatable',
        //       id: 104,
        //       no: ''
        //     },
        //     filters: { 
        //       // SUBSYSTEM: this.FilterValues.SYS
        //     },
        //     iFilters: {
        //       filterString : "[STAGE] = 'A' AND [STATUS] = 'Y'",
        //       inputFilter  : ''
        //     }
        //   },
        //   {
        //     dataType: 'row',
        //     action: {
        //       type: 'direct',
        //       target: 'slide-modal',
        //       component: 'ServiceDatatable',
        //       id: 104,
        //       no: ''
        //     },
        //     filters: { 
        //       // SUBSYSTEM: this.FilterValues.SYS
        //     },
        //     iFilters: {
        //       filterString : "[STAGE] = 'A' AND [STATUS] = 'N'",
        //       inputFilter  : ''
        //     }
        //   },
        // ]
      }
      this.SummaryTable_01(overallMCStatus, style) // ---------------------------> 

      // WeekPerformance_01
      data = this.dataSet.filter(f=> f.TYPE == 'WEEK')
      style = { x: 310, y: 40, }
      data.forEach(d=> {
        d.date = d.DATE
        if(d.DISC =='LWK' || d.DISC =='TWK') d.name = 'Total'; else d.name = d.DISC
        d.value = d.ACTUAL
      })
      this.WeekPerformance_01(overallMCStatus, data, style) // ---------------------------> 

      // Chart_Tube_01
      data = this.dataSet.find(f=> f.TYPE == 'B' && f.DISC == 'Overall')
      style = {
        x:        40,
        y:        120,
        color:    'LightBlue',
        height:   150,
        tickness: 60,
        base:   { tSize: 14, height: 40, padding: 30,},
        legend: { actualColor:'#44A9DF', remainColor: '#F7BACF', valueSize: 11, tSize: 10},
        value:  {TOTAL:data.TOTAL, ACTUAL:data.ACTUAL, REMAIN:data.REMAIN, PROG:data.PROG}
      }
      this.Chart_Tube_01(overallMCStatus, style) // ---------------------------> 

      // Bar_Progress_01
      data = this.dataSet.filter(f=> f.TYPE == 'B' && f.DISC !== 'Overall')
      style = {
        x:        270,
        y:        140,
        width:    100,
        title:    {text: 'B Check Sheets by discipline', x: 28, tSize: 11, tColor: '#333'},
        cell:     [70, 110, 150, 190, 200, 250, 300],
        lineHeight: 25,
        bColor:   '#B4E3FA', 
        sColor:   '#44A9DF', 
        tColor1:  '#fff', 
        tColor2:  '#757575', 
        opacity: 1,
        // requests: [
        //   {
        //     dataType: 'row',
        //     action: {
        //       type: 'direct',
        //       target: 'slide-modal',
        //       component: 'ServiceDatatable',
        //       id: 104,
        //       no: ''
        //     },
        //     filters: { 
        //       // SUBSYSTEM: this.FilterValues.SYS
        //     },
        //     iFilters: {
        //       filterString : "[STAGE] = 'A'",
        //       inputFilter  : ''
        //     }
        //   },
        //   {
        //     dataType: 'row',
        //     action: {
        //       type: 'direct',
        //       target: 'slide-modal',
        //       component: 'ServiceDatatable',
        //       id: 104,
        //       no: ''
        //     },
        //     filters: { 
        //       // SUBSYSTEM: this.FilterValues.SYS
        //     },
        //     iFilters: {
        //       filterString : "[STAGE] = 'A' AND [STATUS] = 'Y'",
        //       inputFilter  : ''
        //     }
        //   },
        //   {
        //     dataType: 'row',
        //     action: {
        //       type: 'direct',
        //       target: 'slide-modal',
        //       component: 'ServiceDatatable',
        //       id: 104,
        //       no: ''
        //     },
        //     filters: { 
        //       // SUBSYSTEM: this.FilterValues.SYS
        //     },
        //     iFilters: {
        //       filterString : "[STAGE] = 'A' AND [STATUS] = 'N'",
        //       inputFilter  : ''
        //     }
        //   },
        // ]
      }
      this.Bar_Progress_GWBS_B(overallMCStatus, data, style) // ---------------------------> 
    },
  }
}