import * as d3 from 'd3'

export default {
  methods: {
    Export_Hull_LQ(d,k) {

      // if (d.stage == 'Division_Lv3' && d.mask == 'HULL-LQ') {
  
      //   setTimeout(() => {
      //     // Remove SVG
      //     this.removeSvg(this.CANVAS, 10)
      //   },1000);
      //   // Hidden
        
      //   this.hiddenGroupID('#Menu_Back', 0, 10)

      //   this.hiddenGroupID('#Mask_LQ_Group', 0, 10)

      //   setTimeout(() => {
      //   // Explode
      //   this.Hull_LQ.forEach(d => {
      //     d3.select(`#Blk_${d.Blk}`)
      //     .transition().duration(1000).attr('transform', `translate(${d.x2}, ${d.y2}) scale(0.5)`)
      //   })
      // },1000);
      // setTimeout(() => {

      //   // Display
      //   this.displayGroupID(`#Mask_LQ00`, 0, 10, 0)
      //   this.displayGroupID(`#Mask_HD01`, 0, 10, 0)

      //   this.Lev1 = 'HULL'
      //   this.Lev2 = 'LQ'
      //   this.stage = this.STG_Division_Lv3
      //   this.fncName = 'Goto_LQ_Division_Lv3'
      //   this.StageCode() // AREA / STAGE
      //   this.displayGroupID(`#Menu_Back`, 0, 200, 1)

      //   // ### Call Canvas ###
      //   this.Canvas_Division_Lv3(this.Lev1, this.Lev2)
      // }, 1000);

      // }

      if (d.stage == 'Lv3_Block_Division' && ['Mask_HD01'].includes(d.mask)) {
        setTimeout(() => {
          // Remove SVG
          this.removeSvg(this.CANVAS, 10)
        },1000);
        this.Lev2 = 'LQ'
          // Title & Keyplan
          d3.select(`#Title`).text(`HULL_LQ_${d.level}`)
          d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Keyplan/HULL_LQ_${d.level}.png`)

        // Hidden
        this.hiddenGroupID('#Menu_Back', 0, 10)

        this.hiddenGroupID('#Mask_LQ00', 0, 10)
        this.hiddenGroupID('#Mask_HD01', 0, 10)

        let coordinate = this.Coordinate.find(f=> f.l2 == this.Lev2 && f.code == d.level)

        setTimeout(() => {
          this.Hull_LQ.filter(f=> f.Lv3 !== d.level).forEach(d => {
            this.hiddenGroupID(`#Blk_${d.Blk}`, 0, 300)
          })
          // Scale
          d3.select(`#Hull_LQ_Group`)
          .transition().duration(1000).attr('transform', `translate(${coordinate.x}, ${coordinate.y}) scale(1)`)

          // Explode
          this.Hull_LQ.filter(f=> f.Lv3 == d.level).forEach(d => {
            d3.select(`#Blk_${d.Blk}`)
            .transition().duration(1000).attr('transform', `translate(${d.x3}, ${d.y3}) scale(0.5)`)
          })
        }, 1000);
        setTimeout(() => {

          this.draw_BlockCode_Lv3(d.level, coordinate.x, coordinate.y)

          this.Lev1 = 'HULL'; this.Lev2 = 'LQ'; this.Lev3 = d.level; this.Lev4 = null; 
          this.stage = this.STG_Lv3_Block_Division
          this.fncName = 'Goto_LQ_BlockDivison_Lv3'
          this.StageCode() // AREA / STAGE
          this.displayGroupID(`#Menu_Back`, 0, 200, 1)
        }, 1300);
      }

      if (d.stage == 'Division_Lv4' && d.level == 'LQ00') {
        setTimeout(() => {
          // Remove SVG
          this.removeSvg(this.CANVAS, 10)
        },1000);

        // Title & Keyplan
        d3.select(`#Title`).text(`HULL-LQ-LQ00`)
        d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Keyplan/HULL_LQ00.png`)

        // Hidden
        this.hiddenGroupID('#Menu_Back', 0, 10)

        this.hiddenGroupID('#Mask_LQ00', 0, 10)
        this.hiddenGroupID('#Mask_HD01', 0, 10)

        setTimeout(() => {

          this.Hull_LQ.filter(f=> f.Lv3 !== d.level).forEach(d => {
            this.hiddenGroupID(`#Blk_${d.Blk}`, 0, 300)
          })

          // Explode
          this.Hull_LQ.filter(f=> f.Lv3 == d.level).forEach(d => {
            d3.select(`#Blk_${d.Blk}`)
            .transition().duration(1000).attr('transform', `translate(${d.x3}, ${d.y3}) scale(0.5)`)
          })
      }, 1000);

      setTimeout(() => {
        // Display
        this.displayGroupID(`#Mask_LV01`, 0, 10, 0)
        this.displayGroupID(`#Mask_LV23`, 0, 10, 0)
        this.displayGroupID(`#Mask_LV45`, 0, 10, 0)
        this.displayGroupID(`#Mask_LV67`, 0, 10, 0)

        this.Lev1 = 'HULL'; this.Lev2 = 'LQ'
        this.stage = this.STG_Division_Lv4
        this.fncName = 'Goto_LQ_Division_Lv4'
        this.StageCode() // AREA / STAGE
        this.displayGroupID(`#Menu_Back`, 0, 200, 1)

         // ### Call Canvas ###
        this.Canvas_Division_Lv4(this.Lev2)
      }, 1000);  
      }
  
      if (d.stage == 'Lv4_Block_Division' && ['Mask_LV67', 'Mask_LV45',
       'Mask_LV23', 'Mask_LV01'].includes(d.mask)) {
       setTimeout(() => {
         // Remove SVG
         this.removeSvg(this.CANVAS, 10)
       },1000);
       this.Lev1 = 'HULL'
       this.Lev2 = 'LQ'

      // Title & Keyplan
      d3.select(`#Title`).text(`Hull-${d.level}`)
      d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Keyplan/HULL_LQ_${d.level}.png`)
      // Hidden
      this.hiddenGroupID('#Menu_Back', 0, 10)
      
      this.hiddenGroupID('#Mask_LV01', 0, 10)
      this.hiddenGroupID('#Mask_LV23', 0, 10)
      this.hiddenGroupID('#Mask_LV45', 0, 10)
      this.hiddenGroupID('#Mask_LV67', 0, 10)
       
      let coordinate = this.Coordinate.find(f=> f.l2 == this.Lev2 && f.code == d.level)

       setTimeout(() => {

        this.Hull_LQ.filter(f=> f.Lv4 !== d.level).forEach(d => {
          this.hiddenGroupID(`#Blk_${d.Blk}`, 0, 300)
        })
         
        // Scale
        d3.select(`#Hull_LQ_Group`)
        .transition().duration(1000).attr('transform', `translate(${coordinate.x}, ${coordinate.y}) scale(1)`)

        // Explode
        this.Hull_LQ.filter(f=> f.Lv4 == d.level).forEach(d => {
          d3.select(`#Blk_${d.Blk}`)
          .transition().duration(1000).attr('transform', `translate(${d.x4}, ${d.y4}) scale(0.5)`)
        })
       }, 1000);

       setTimeout(() => {
        this.draw_BlockCode_Lv4('LQ', d.level, coordinate.x, coordinate.y)

        this.Lev1 = 'HULL'; this.Lev2 = 'LQ'; this.Lev3 = 'LQ00'; this.Lev4 = d.level; 
        this.stage = this.STG_Lv4_Block_Division
        this.fncName = 'Goto_LQ_BlockDivison_Lv4'
        this.StageCode() // AREA / STAGE
        this.displayGroupID(`#Menu_Back`, 0, 200, 1)
      }, 1300);
     }   
      
    },

  }
}
           