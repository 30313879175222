// -------------------------------------------------------------------------------------- Global
import { SafeIdMixin } from '../../../../../../includes/mixins/safeid.mixin'                // ATAGlance Framework Globals
import gmx_Controller from '../../../../../../includes/mixins/Controller.mixin'       // SVG Control
// Global Drawings
import gmx_Canvas from '../../../../../../includes/mixins/Draw_Canvas.mixin'
// Global Functions
import gmx_Miscellaneous from '../../../../../../includes/mixins/Miscellaneous.mixins'
import gmx_Movable from '../../../../../../includes/mixins/Movable.mixin'
import gmx_Texts from '../../../../../../includes/mixins/Text_Draw.mixin'


// -------------------------------------------------------------------------------------- Functional Charts
import Cht_Charts_Pie from           '../../../../include/ChartLibrary/Charts_Pie.mixin'



// Locally declared common Data-Props & SVG Drawing Support
import lmx_ReactiveEvents from './Realtime_Monitor.mixin' // Reactive Events
import lmx_Declares from './Declares.mixin'
import lmx_Charts from './Draw_Charts.mixin'


export default {
  mixins: [
    SafeIdMixin,
    gmx_Controller,
    gmx_Canvas,
    gmx_Miscellaneous,
    gmx_Movable,
    gmx_Texts,


    // Functional Charts
    Cht_Charts_Pie,

    lmx_ReactiveEvents,
    lmx_Declares,
    lmx_Charts,
  ],
}
