import * as d3 from 'd3'

export default {
  methods: {
    
    call_CWA(selection, d) {
      selection
      .on('click', ()=> { this.link_Action(d.CWA)
      })
      .on('mouseover', () => {
        d3.select(`#CWA-${d.CWA}`).selectAll('rect').transition().duration(150).style('opacity', .9)
        d3.select(`#CWA-${d.CWA}`).selectAll('path').transition().duration(150).style('opacity', .9)
        d3.select(`#CWA-${d.CWA}`).selectAll('polygon').transition().duration(150).style('opacity', .9)
      })
      .on('mouseout', () => {
        d3.select(`#CWA-${d.CWA}`).selectAll('rect').transition().duration(150).style('opacity', .5)
        d3.select(`#CWA-${d.CWA}`).selectAll('path').transition().duration(150).style('opacity', .5)
        d3.select(`#CWA-${d.CWA}`).selectAll('polygon').transition().duration(150).style('opacity', .5)
       })
    },


    call_Search(input=null) {
      
      let data_List = this.rawData
      this.search.results = []

      // apply search input ------------------------
      if(input != null) this.search.input = input
      input = input.toLowerCase()
      
      this.search.results = input ? data_List.filter(f => this.search.targets.some(col => f['CWA'].toLowerCase().includes(input))) : []
      this.data_Filtering() // ---> below
    },

    call_Options(selection, d) {
      selection
      .on('click', ()=> {
        d3.select(`#checked_${d.code}`)
        .style('visibility', () => {
          if(d.checked == true) {
            d.checked = false
            d3.select(`#checked_${d.code}`).style('visibility', 'hidden')
            return 'hidden'
          } else {
            d.checked = true
            d3.select(`#checked_${d.code}`).style('visibility', 'visible')
            return 'visible'
          }
        })
        this.data_Filtering()
      })
      .on('mouseover', () => {
        d3.select(`#checkBox_${d.code}`).style('opacity', .7)
        d3.select(`#Text_${d.code}`).style('opacity', .7)
      })
      .on('mouseout', () => {
        d3.select(`#checkBox_${d.code}`).style('opacity', 1)
        d3.select(`#Text_${d.code}`).style('opacity', 1)
      })
    },


    data_Filtering() {

      this.rawData.forEach(d => {

        let chk_ = this.Legends.find(f => f.code == d.STATUS).checked
        d.visibility = 'N'

        if (this.search.input == '') {
          if (chk_) d.visibility = 'Y'
        } else {
          if(this.search.results.find(f => f.CWA == d.CWA) && chk_) d.visibility = 'Y'
        }
      })

      // Apply visibility based on preprocessed data
      this.rawData.forEach(d => {
        d3.select(`#CWA-${d.CWA}`)
        .attr('visibility', d.visibility == 'Y' ? 'visible' : 'hidden')
      })
    },



    link_Action(code_) {
      // Get Modal Number
      let dataSource = {KEY1:code_}
      let columnProps = this.getRequestColumnProps('request', dataSource, this.ColumnProps)
      let filterString = ''
      
      let request_ = {
        dataType: 'row',
        action: {
          type: 'direct',
          target: columnProps.action.target,
          component: columnProps.action.component,
          id: columnProps.action.id,
          no: columnProps.action.no,
        },
        filters     : {
          ...this.FilterValues,
          ...columnProps.filters,
        },
        iFilters    : {
          filterString : filterString || '',
          inputFilter  : ''
        }
      }
      
      this.$emit('request-action', request_)
    },
  }
}