<template>
  <div>
    <div class="worktype-selectbox">

      <span class="select-title">Work Type</span>
      <select v-model="worktype">
        <option v-for="type in worktypeOptions" :key="type.WORK_TYPE_CODE" :value="type.WORK_TYPE_CODE">{{type.WORK_TYPE_DESCR}}</option>
      </select>

    </div>
    <div :id="localId"></div>
  </div>
</template>

<script>

import mx_Core from './includes/mixins/importer'            // Global & Local Mixins

export default {
  name: "j-chart-donut",
  mixins: [mx_Core],
  props: {
    id: String,
    modeExport: Boolean,

    FilterValues: {
      type: Object,
      default: () => ({
        MOD: 'MOD-NO',
        MOD_DESC: 'Module Description',
      })
    },

    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },
    DataItems: { type: Array, default: () => ([]) },
    ColumnProps: { type: Array, default: () => ([]) },
    Queries: { type: Object, default: () => ({}) },
  },

  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
  },

  mounted() {
    this.register(this.draw)
  },

  methods: {
    draw() {
      if (!this.ready2draw) return

      this.clear()
      this.setDefaultValues()
      this.drawCanvas()

      this.drawChart()
      this.drawTexts()
      this.complete()
    },

    drawChart() {
      this.Draw_Charts()
    },

  }
}
</script>
<style scoped>
.worktype-selectbox{
  position: absolute;
  right: 70px;
  top: 50px
}
.select-title {
  margin-right: 8px;
}
select {
  width:200px;
  padding:5px;
  border:1px solid #999;
  /* font-family:'Nanumgothic'; */
  border-radius:3px;
  -webkit-appearance: auto;
  -moz-appearance: auto;
  appearance : auto;
}
</style>