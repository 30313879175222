// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {
    Draw_Charts() {
      let data = null, style = null, tmp = null
      let overallSummary = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0.5)`)
      
      // overallSummary
      // .attr('transform', `translate(10,25)`)
      // .append('text')
      // .style('font-size',16).style('font-family','roboto').attr('fill','#000')
      // .text('LQ')

      overallSummary
      .append('image')
      .attr('opacity', 1)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Modules/LQ.png`)
      .attr('transform', `translate(10, 50)`)

      // Overall Plan
      tmp = this.DataItems[0]
      style = {
        id: 'CERT',
        x: 190,
        y: 55,
        ty: 59,
        name: tmp.PLAN,
        sort: 'N',
      }
      data = [
        {title: 'Completed',  value: tmp.PLAN },
        {title: 'Remain',     value: 100-tmp.PLAN }
      ]
      let Circle = {
        CircleDiameter: 28,
        CircleColorSet: ['Gray', 'LightGray'],
        CircleHoleSize: 20,
        CircleStackSize: 2,
        CircleSliceSize: 5,
        CircleValueSize: 14,
        CircleTitleSize: 12,
        CircleLegendSpace: 80,
      }
      let Guideline = {
        GuideDisplay: 'N',
      }
      overallSummary
      .append('text')
      .attr('transform', `translate(${style.x}, ${style.y - Circle.CircleDiameter - 10})`)
      .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'middle')
      .text('Plan (%)')

      this.Chart_PieSimple(overallSummary, data, style, Circle, Guideline)

      style = {
        id: 'SHI',
        x: 328,
        y: 55,
        ty: 59,
        name: tmp.ACTUAL,
        sort: 'N',
      }
      data = [
        {title: 'Completed',  value: tmp.ACTUAL },
        {title: 'Remain',     value: 100-tmp.ACTUAL }
      ]
      Circle.CircleColorSet = ['LightBlue', 'LightGray']
      
      overallSummary
      .append('text')
      .attr('transform', `translate(${style.x}, ${style.y - Circle.CircleDiameter - 10})`)
      .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'middle')
      .text('Actual (%)')

      this.Chart_PieSimple(overallSummary, data, style, Circle, Guideline)


      let box = overallSummary
      .append('g')
      .attr('transform', `translate(236, 40)`)

      box
      .append('text')
      .attr('transform', `translate(25, 12)`)
      .style('font-size', 9)
      .style('font-family', 'roboto')
      .attr('fill', '#959595')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text('Delta (%)')

      box
      .append('text')
      .attr('transform', `translate(25, 30)`)
      .style('font-size', 14)
      .style('font-family', 'roboto')
      .attr('fill', tmp.DELTA >= 0 ? '#1e88e5' : '#bf360c')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text(tmp.DELTA)
    },
  }
}
           