<template>
  <div :id="localId"></div>
</template>

<script>
import * as d3 from 'd3'

import Defs from '../../includes/primitives/Color_Defs'     // SVG Color Defines for Gradation Effect
import mx_Core from './includes/mixins/importer'            // Global & Local Mixins

export default {
  name: "j-chart-bar-progress",
  mixins: [mx_Core],
  props: {
    ChartType: String,

    // Chart Data
    Database: { type: Object, default: () => ({}) },
    Canvas: { type: Object, default: () => ({}) },
    Title: { type: Object, default: () => ({}) },
    Bar: { type: Object, default: () => ({}) },
    Value: { type: Object, default: () => ({}) },
    // Compare: { type: Object, default: () => ({}) },
    Note: { type: Object, default: () => ({}) },

    // Database
    DataItems: { type: Array, default: () => ([]) },

    values: {
      type: Object,
      default: () => ({
        milestone: [],
        note: ''
      })
    }
  },
  created() {
    this.localId = `${this.id || 'j-chart-bar-progress'}__${this.safeId('')}`
  },
  mounted() {
    this.register(this.draw)
  },

  methods: {
    draw() {
      // Check all the necessary values are available
      if(!this.ready2draw) return

      this.clear()
      if (this.ChartType !== this.__C_.CHART.TYPE_CODE_NORMAL && this.ChartType !== this.__C_.CHART.TYPE_CODE_EMBOSSED) {
        this.chart_Compare()
        return;
      }
      // Local - running at the local mixin 'DeclaresNDrawingSupport.mixin.mixin' ---
      this.setDefaultValues()
      this.setScaleXInfo()
      this.setScaleYInfo()
      // ----------------------------------------------------------------------------

      this.drawCanvas()

      // This 'forEach' loop should be placed in each process of drawing,
      // not control & out of the function for each.
      this.DataItems.forEach((item, i) => {
        this.getOutstand = item.total - item.actual
        this.get_BarLength = this.Canvas.CanvasChartWidth * (item.actual / item.total)

        if (this.ChartType == this.__C_.CHART.TYPE_CODE_NORMAL) this.chart_Normal(i)
        if (this.ChartType == this.__C_.CHART.TYPE_CODE_EMBOSSED) this.chart_Embossed(i)

        this.drawPoint_y += this.setThickness + this.Bar.BarDistance
      })

      

      this.drawTitles()
      this.drawNotes()

      this.complete()
    },
  }
}
</script>