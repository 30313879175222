import * as d3 from 'd3'

export default {
  methods: {
    
    setEvent_Note() {

      this.btnNote = (selection) => {
        selection
        .on('mouseover', () => {
          d3.select(`#Note_Btn`).transition().duration(100).style('opacity', 0.5)
          return d3.select(`#Note_Text`).transition().duration(100).style('opacity', 1)
        })
        .on('mouseout', () => {
          d3.select(`#Note_Btn`).transition().duration(100).style('opacity', 1)
          return d3.select(`#Note_Text`).transition().duration(100).style('opacity', 0)
        })
      }
    },
  }
}