<template>
  <div :id="localId"></div>
</template>

<script>
import * as d3 from 'd3'
import mx_Core from './includes/mixins/importer'            // Global & Local Mixins

export default {
  name: "j-chart-donut",
  mixins: [mx_Core],
  props: {
    id: String,
    modeExport: Boolean,

    FilterValues: {
      type: Object,
      default: () => ({
        MOD: 'MOD-NO',
        MOD_DESC: 'Module Description',
        GWBS_LV2: 'ME01'
      })
    },

    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },
    DataItems: { type: Array, default: () => ([]) },
    ColumnProps: { type: Array, default: () => ([]) },
  },
  data:() => ({
    isComplete: false
  }),
  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
  },

  mounted() {
    this.register(this.draw)
  },

  methods: {
    draw() {
      if (!this.ready2draw) return

      this.clear()
      this.setDefaultValues()
      this.drawCanvas()

      this.drawChart()
      this.drawTexts()
      this.complete()
      // this.postComplete()
    },

    drawChart() {
      this.Draw_Charts()

      
    },

    postComplete() {
      if (this.isComplete) return
      let chart_ = d3.select('#wkreport_table_').node().getBoundingClientRect()

      let width_ = chart_.width + 60
      let height_ = this.groupY + 100

      this.resizableCanvasWidth = width_
      this.resizableCanvasHeight = height_
      this.resizeCanvas()

      let dimention = { 
        width: Math.ceil(width_), 
        height: Math.ceil(height_), 
        isreal: true, 
      }
      this.$emit('resize', dimention)
      this.isComplete = true

    }

  }
}
</script>