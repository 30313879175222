import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  data: () => ({
    
  }),

  methods: {

    // ===============================================================================================================================================

    // Canvas_Hull_Activate(lv2) {
      
    //   this.CANVAS = this.svg.append('svg')

    //   this.CANVAS
    //   .attr('width', this.Canvas.CanvasWidth)
    //   .attr('height', this.Canvas.CanvasHeight)
    //   .style('opacity', 1)

    //   let style = null


    //   // console.log(lv2)
   
    //   // Block Status
    //   let Coordinate = this.BlockStatus_Coordinate.find(f=> f.Lv1 == 'ACTIVATE' && f.Lv2 == lv2)
    //     // console.log(Coordinate.Lv1, Coordinate.Lv2, Coordinate.Lv3)
    //     let itemSet = []
    //     style = {
    //               x: Coordinate.x,
    //               y: Coordinate.y,
    //               headTextSize: 16,
    //               headTitle: Coordinate.NAME,
    //               prog: this.Queries.MasterProg.find(f=> f.Lv2 == lv2 && f.Lv3 == 'OVERALL'),
    //     }

    //     this.Status.forEach((d,i) => {
    //       itemSet.push({
    //         name: d.title,
    //         total: this.dataSet.filter(f => f.Lv2 == lv2 && f.EREC == 'Y').length,
    //         actual: this.dataSet.filter(f => f.Lv2 == lv2 && f.EREC == 'Y' && f.STATUS == d.code).length,
    //         bColor: this.Status[i].bColor,
    //         opacity: this.Status[i].opacity,
    //       })
          
    //     })
    //     this.blockStatus(itemSet)

    //     this.Bar_Quantity_02(this.CANVAS, itemSet, style, 1) // <---- Chart Library


      




  
      
    // },



  }
}