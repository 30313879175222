import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  data:() =>({
    actualText1:null,
    actualText2:null,
    actualText3:null,
    attrs: [
      { 
        text: 'Plan',
        refColumn: 'PLAN',
        fColor: '#616161', 
        tColor: '#424242',
      },
      { 
        text: 'Forecast',
        refColumn: 'FORECAST',
        fColor: '#64B5F6',
        tColor: '#ffffff'
      },
      { 
        text: 'Actual',
        refColumn: 'ACTUAL',
        fColor: '#E91E63',
        tColor: '#ffffff'
      }
    ],
    Tattrs: [
      { 
        text: 'Total Equipment Lift',
        refColumn: 'TOTAL',
        fColor: '#616161', 
        tColor: '#424242',
      }
    ],
    Dattrs: [
      { 
        text: 'Total Required',
        refColumn: 'TOTAL_REQUIRED',
        fColor: '#616161', 
        tColor: '#424242',
      },
      { 
        text: 'In Progress',
        refColumn: 'IN_PROGRESS',
        fColor: '#64B5F6',
        tColor: '#ffffff'
      },
      { 
        text: 'Complete',
        refColumn: 'COMPLETE',
        fColor: '#E91E63',
        tColor: '#ffffff'
      }
    ],
    Eattrs: [
      { 
        text: 'Plan',
        refColumn: 'PLAN',
        fColor: '#616161', 
        tColor: '#424242',
      },
      { 
        text: 'Forecast',
        refColumn: 'FORECAST',
        fColor: '#64B5F6',
        tColor: '#ffffff'
      },
      { 
        text: 'Actual',
        refColumn: 'ACTUAL',
        fColor: '#E91E63',
        tColor: '#ffffff'
      }
    ],
  }),
  methods: {  
    Draw_Charts() {
      
      let data = null
      let Summary = this.svg
      .append('g')
      .attr('id', 'summary_wrap')
      .attr('transform', `translate(0.5, 20.5)`)
      .style('font-family', 'roboto').style('cursor', 'pointer')
      
      let boxW = 60
      //Delivery/Dressup/Lift/Total
      let TableNameD = [...new Set(this.DataItems.map(d=>d.NAME))]
      this.actualText1 = TableNameD == 'Delivery' ? 'Plan' : TableNameD =='Lift'? 'Total':TableNameD =='Total'?'Total Equipment Lift':'Total Required'
      this.actualText2 = TableNameD == 'Delivery' ? 'Forecast' : TableNameD =='Lift'? 'Actual':TableNameD =='Total'?'':'In Progress'
      this.actualText3 = TableNameD == 'Delivery' ? 'Act. On site' : TableNameD =='Lift'? '':TableNameD =='Total'?'':'Completed'
      
      let values_ = this.DataItems[0]

      if(TableNameD=='Lift'){
        this.attrs.forEach((attr, i) => {

          let SummaryGroup = Summary
          .append('g')
          .attr('transform', `translate(${0, 0})`)
          
          SummaryGroup
          .append('rect')
          .attr('x', 90*i).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', boxW).attr('height', 40).attr('fill', 'Transparent').attr('stroke', '#CFD8DC').attr('stroke-width',0.5)
          
          SummaryGroup
          .append('text')
          .attr('transform', `translate(${90*i+(boxW/2)},-5)`).style('font-size', 11)
          .attr('fill', '#9E9E9E').attr('text-anchor', 'middle').attr('alignment-baseline', 'end')
          .text(attr.text)

          SummaryGroup
          .append('text')
          .attr('transform', `translate(${90*i+(boxW/2)},25)`).style('font-size', 20)
          .attr('fill', attr.fColor).attr('text-anchor', 'middle').attr('alignment-baseline', 'end')
          .text(values_[attr.refColumn])

          SummaryGroup.on('click', () => {
            if(values_[attr.refColumn] == 0) return

            let filterArr = []
              if (attr.refColumn == 'PLAN') filterArr.push(` [LIFTING_PLAN] <= (SELECT [CUTOFF] FROM [C01_Project].[DataSource].[Document] WHERE [PHASE] ='CON' AND [CODE] = 'HLI') `)
              if (attr.refColumn == 'FORECAST') filterArr.push(` [LIFTING_FORECAST] <= (SELECT [CUTOFF] FROM [C01_Project].[DataSource].[Document] WHERE [PHASE] ='CON' AND [CODE] = 'HLI') `)
              if (attr.refColumn == 'ACTUAL') filterArr.push(` [LIFTING_ACTUAL] <= (SELECT [CUTOFF] FROM [C01_Project].[DataSource].[Document] WHERE [PHASE] ='CON' AND [CODE] = 'HLI') `)

                let request_ = {
                  dataType: 'row',
                  action: {
                    type: 'direct',
                    target: 'slide-modal',
                    component: 'ServiceDatatable',
                    id: 381,
                    no: ''
                  },
                  filters: { 
                    ...this.FilterValues
                  },
                  iFilters: {
                    filterString : filterArr.join(' AND '),
                    inputFilter  : ''
                  }
                }
                this.$emit('request-action', request_)
          })
        })
      }
      else if(TableNameD=='Total'){
        this.Tattrs.forEach((attr, i) => {

          let SummaryGroup = Summary
          .append('g')
          .attr('transform', `translate(${0, 0})`)
          
          SummaryGroup
          .append('line')
          .attr('x1', 40).attr('y1', 16).attr('x2', 200).attr('y2', 16).attr('stroke-width', 1).attr('stroke', '#BBDEFB')
          SummaryGroup
          .append('text')
          .attr('transform', `translate(${120},40)`).style('font-size', 18).style('font-weight','bold')
          .attr('fill', '#42A5F5').attr('text-anchor', 'middle').attr('alignment-baseline', 'end')
          .text(attr.text)

          SummaryGroup
          .append('text')
          .attr('transform', `translate(${120},5)`).style('font-size', 30)
          .attr('fill','#0D47A1').attr('text-anchor', 'middle').attr('alignment-baseline', 'end')
          .text(values_[attr.refColumn])

          SummaryGroup.on('click', () => {
            if(values_[attr.refColumn] == 0) return
            // console.log(attr.refColumn)
            let dataSource = {
              LOA_STATUS: attr.refColumn
            }
            let request_ = this.getRequestColumnProps('HeavyLifing', attr.refColumn == 'TOTAL' ? {} : dataSource, this.ColumnProps)
            this.$emit('request-action', request_)
          })
        })
      }
      else if(TableNameD=='Dressup'){
        this.Dattrs.forEach((attr, i) => {
          let SummaryGroup = Summary
          .append('g')
          .attr('transform', `translate(${0, 0})`)
          
          SummaryGroup
          .append('rect')
          .attr('x', 90*i).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', boxW).attr('height', 40).attr('fill', 'Transparent').attr('stroke', '#CFD8DC').attr('stroke-width',0.5)
          
          SummaryGroup
          .append('text')
          .attr('transform', `translate(${90*i+(boxW/2)},-5)`).style('font-size', 11)
          .attr('fill', '#9E9E9E').attr('text-anchor', 'middle').attr('alignment-baseline', 'end')
          .text(attr.text)

          SummaryGroup
          .append('text')
          .attr('transform', `translate(${90*i+(boxW/2)},25)`).style('font-size', 20)
          .attr('fill', attr.fColor).attr('text-anchor', 'middle').attr('alignment-baseline', 'end')
          .text(values_[attr.refColumn])

          SummaryGroup.on('click', () => {
            if(values_[attr.refColumn] == 0) return

            let filterArr = []
              if (attr.refColumn == 'TOTAL_REQUIRED') filterArr.push(` [DRESSUP_REQ] = 'Y' `)
              if (attr.refColumn == 'IN_PROGRESS') filterArr.push(` [DRESSUP_REQ] = 'Y' AND [DRESSUP_PROG] > 0 `)
              if (attr.refColumn == 'COMPLETE') filterArr.push(` [DRESSUP_REQ] = 'Y' AND ([DRESSUP_PROG] = 100 OR [STATUS] = 'COMPLETE') `)

                let request_ = {
                  dataType: 'row',
                  action: {
                    type: 'direct',
                    target: 'slide-modal',
                    component: 'ServiceDatatable',
                    id: 381,
                    no: ''
                  },
                  filters: { 
                    ...this.FilterValues
                  },
                  iFilters: {
                    filterString : filterArr.join(' AND '),
                    inputFilter  : ''
                  }
                }
                this.$emit('request-action', request_)
          })
        })
      }else{
        this.Eattrs.forEach((attr, i) => {
          let SummaryGroup = Summary
          .append('g')
          .attr('transform', `translate(${0, 0})`)
          
          SummaryGroup
          .append('rect')
          .attr('x', 90*i).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', boxW).attr('height', 40).attr('fill', 'Transparent').attr('stroke', '#CFD8DC').attr('stroke-width',0.5)
          
          SummaryGroup
          .append('text')
          .attr('transform', `translate(${90*i+(boxW/2)},-5)`).style('font-size', 11)
          .attr('fill', '#9E9E9E').attr('text-anchor', 'middle').attr('alignment-baseline', 'end')
          .text(attr.text)

          SummaryGroup
          .append('text')
          .attr('transform', `translate(${90*i+(boxW/2)},25)`).style('font-size', 20)
          .attr('fill', attr.fColor).attr('text-anchor', 'middle').attr('alignment-baseline', 'end')
          .text(values_[attr.refColumn])

          SummaryGroup.on('click', () => {
            if(values_[attr.refColumn] == 0) return

            let filterArr = []
              if (attr.refColumn == 'PLAN') filterArr.push(` [DELIVERY_PLAN] <= (SELECT [CUTOFF] FROM [C01_Project].[DataSource].[Document] WHERE [PHASE] ='CON' AND [CODE] = 'HLI') `)
              if (attr.refColumn == 'FORECAST') filterArr.push(` [DELIVERY_FORECAST] <= (SELECT [CUTOFF] FROM [C01_Project].[DataSource].[Document] WHERE [PHASE] ='CON' AND [CODE] = 'HLI') `)
              if (attr.refColumn == 'ACTUAL') filterArr.push(` [DELIVERY_ACTUAL] <= (SELECT [CUTOFF] FROM [C01_Project].[DataSource].[Document] WHERE [PHASE] ='CON' AND [CODE] = 'HLI') `)

                let request_ = {
                  dataType: 'row',
                  action: {
                    type: 'direct',
                    target: 'slide-modal',
                    component: 'ServiceDatatable',
                    id: 381,
                    no: ''
                  },
                  filters: { 
                    ...this.FilterValues
                  },
                  iFilters: {
                    filterString : filterArr.join(' AND '),
                    inputFilter  : ''
                  }
                }
                this.$emit('request-action', request_)
          })
        })
      }
      

      
      // proceduresDate
      // .append('text')
      // .attr('transform', `translate(30,20)`).style('font-family', 'roboto').style('font-size', 11)
      // .attr('fill', this.dataSet[0].DELTA > 0 ? '#64B5F6' :this.dataSet[0].DELTA === 0 ? '#BDBDBD' :'#F44336').attr('text-anchor', 'middle').attr('alignment-baseline', 'end')
      // // .text(`${this.dataSet[0].DELTA > 0 ? '▲ ' : this.dataSet[0].DELTA === 0 ? 0: '▼ '}` + Number(this.dataSet[0].DELTA).toFixed(0))
      // .text(this.dataSet[0].DELTA == null ? 0 : this.dataSet[0].DELTA > 0 ? '▲ '+ this.dataSet[0].DELTA : this.dataSet[0].DELTA == 0 ? '- '+ this.dataSet[0].DELTA :'▼ '+ this.dataSet[0].DELTA)
      
    },
  }
}
