import * as d3 from 'd3'

export default {
  data: () => ({
    dataSet: [],
    dataMinValue1: 0,
    dataMaxValue1: 0,
    dataAvgValue: 0,

    dataMinValue2: 0,
    dataMaxValue2: 0,

    axisYHeight: 160,
    axisXWidth: 260,
    scaleY1: '',
    scaleY2: '',
    lineFunc: '',
    lineZero: '',

    scaleXTimeline: '',
    dataXTimelineValues: '',
    dataXTimelineValuesMonth: '',

    tickValues1: [],
    tickValues2: [],
    lastDate: 0,
    lastValue: 0,
  }),
  computed: {
    ready2draw() {

      // console.log('------------------------------')
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)

      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },
  methods: {
    setDefaultValues(){
    
      this.dataSet = JSON.parse(JSON.stringify(this.DataItems))
      
      // Get tickValues1 / tickValues2
      this.tickValues1 = this.dataSet.filter(d => typeof d.CUMULATE != 'undefined').map(d => ({ date: d.CDATE, remain: d.CUMULATE }))
      this.tickValues2 = this.dataSet.filter(d => typeof d.PERIOD != 'undefined').map(d => ({ date: d.CDATE, week: d.PERIOD }))

      // getMaxBound for remain / week
      this.dataMaxValue1 = this.getMaxBound(Math.max(...this.tickValues1.map(d => d.remain)))
      this.dataMaxValue2 = this.getMaxBound(Math.max(...this.tickValues2.map(d => d.week)))

      // Get Average
      this.tickValues2.forEach(d => { this.dataAvgValue += Number(d.week)})
      if (this.dataAvgValue == 0) this.dataAvgValue = 0; else this.dataAvgValue = this.dataAvgValue/8

      this.lastWeek = this.tickValues2[this.tickValues2.length-1].week
      this.lastValue = this.tickValues1[this.tickValues1.length-1].remain
      this.lastDate = this.tickValues1[this.tickValues1.length-1].date


      this.scaleY1 = d3
          .scaleLinear()
          .domain([this.dataMinValue1, this.dataMaxValue1])
          .range([this.axisYHeight, 0])
      
      this.scaleY2 = d3
          .scaleLinear()
          .domain([this.dataMinValue2, this.dataMaxValue2])
          .range([this.axisYHeight, 0])

      // ### DAY #######
      // convert string date to datetime
      // use whole dates for the length of the data, not truncated as tickValues
      this.dataXTimelineValues = this.dataSet.map(d => this.parseDate(d.CDATE))

      // ### MONTH #######
      // set default month-parser
      let _mParser__ = d3.timeFormat('%Y-%m')
      // set month start point
      let prevMonth_ = _mParser__(this.dataXTimelineValues[0])

      let daysForAMonth = []
      this.dataXTimelineValuesMonth = []

      this.dataXTimelineValues.forEach(day => {
        if(prevMonth_ != _mParser__(day)) {
          this.dataXTimelineValuesMonth.push(new Date(d3.mean(daysForAMonth)))
          // init. daysForAMonth to calculate the next month's 
          // position value.
          daysForAMonth = []
          // set new month to compare
          prevMonth_ = _mParser__(day)
        }
        // stack the days for a month
        daysForAMonth.push(day)
      })
      // set last calculated month
      this.dataXTimelineValuesMonth.push(new Date(d3.mean(daysForAMonth)))
      // #######



      this.scaleXTimeline = d3.scaleTime()
          // get two of the min & max values from the 'dataXTimelineValues'
          .domain(d3.extent(this.dataXTimelineValues, (d) => d))
          .range([0, this.axisXWidth]) 

      this.lineFunc = d3.line()
          .x(d => this.scaleXTimeline(this.parseDate(d.date))+10)
          .y(d => this.scaleY1(d.remain))
      
      this.lineZero = d3.line()
          .x(d => this.scaleXTimeline(this.parseDate(d.date))+10)
          .y(this.axisYHeight)
    },
 
    
  }
}