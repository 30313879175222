import * as d3 from 'd3'

export default {
  data: () => ({
    innerSvg: null,
  }),
  computed: {
    ready2draw() {

      // console.log('------------------------------')
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)

      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },
  methods: {
    loadSvg() {
      return d3.xml(`${this.__HOST_NAME_RESOURCE}/TORTUE/svg/spoolSummary.svg`).then(source => {
        if(this.innerSvg) {
          this.innerSvg.html()
          this.innerSvg.remove()
          this.innerSvg = null
        }
    
        this.innerSvg = source.documentElement
        this.svg.node().append(this.innerSvg)
        this.innerSvg = d3.select(this.innerSvg)

        return new Promise(resolve => {
          resolve(source)
        })
      })
    }
  }
}