import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {

    
    draw_Forms() {

      let overall = this.svg
      overall
      .append('image')
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/Demo/Common/BP_Logo.svg`)
      .attr('transform', `translate(30,20)`)

      overall
      .append('text')
      .attr('transform', `translate(30, 90)`)
      .style('font-family', 'roboto').style('font-size', 16).style('fill', '#333').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Block Division of MD2 FPU')

      overall
      .append('text')
      .attr('transform', `translate(30, 105)`)
      .style('font-family', 'roboto').style('font-size', 12).style('fill', '#BCBCBC').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('BSEE Equipment List')

      

      overall
      .append('image')
      .attr('id', 'Legends')
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/Demo/Equipment_Layout/MD2/Legends.svg`)
      .attr('transform', `translate(40.5,300.5)`).style('opacity', 0)


      // button Menu_Back
      let Menu_Back = this.svg

      .append('g')
      .attr('id', 'Menu_Back')
      .attr('transform', `translate(250, 81)`)
      .attr('opacity', 1)
      .style('cursor', 'pointer')
      .style('visibility', 'display') // hidden
      .call(s => this.btnGoBack(s)) // --------------------------------------------------------------## btnGoBack

      Menu_Back
      .append('rect')
      .attr('transform', `translate(0, 0)`)
      .style('width', 16).style('height', 16).style('fill', '#44A9DF')

      Menu_Back
      .append('text')
      .attr('transform', `translate(8, 12)`)
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').attr('text-anchor', 'middle')
      .text('B')


      // keyplan area for Module
      let keyPlan = overall 
      .append('g')
      .attr('transform', `translate(30.5, 130.5)`)

      keyPlan
      .append('image')
      .attr('id', 'keyPlan')
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/Demo/Equipment_Layout/MD2/keyplan_Topside.svg`)
      .attr('transform', `translate(0,0)`).style('opacity', 0)

      // --------------------------------------------------------> Hull
      keyPlan // NP1UH
      .append('path')
      .attr('id', 'Key_NP1UH')
      .attr('transform', `translate(-15.5, -64)`)
      .attr('d', 'M40.955,79.561H98.4V99.444h0v.068H65.185l-7.254,7.256v1.893H38.018V82.5A2.937,2.937,0,0,1,40.955,79.561Z').attr('fill', '#6acdf6').attr('opacity', 0.5).attr('visibility', 'hidden')

      keyPlan // NP2UH
      .append('path')
      .attr('id', 'Key_NP2UH')
      .attr('transform', `translate(-15.5, -64)`)
      .attr('d', 'M127.5,82.5v57.449H107.553v-33.22L100.3,99.473H98.4V79.561h26.162A2.938,2.938,0,0,1,127.5,82.5Z').attr('fill', '#6acdf6').attr('opacity', 0.5).attr('visibility', 'hidden')

      keyPlan // NP3UH
      .append('path')
      .attr('id', 'Key_NP3UH')
      .attr('transform', `translate(-15.5, -64)`)
      .attr('d', 'M124.545,169.045H67.1V149.161h0v-.068h33.22l7.254-7.254v-1.893h19.913v26.161A2.938,2.938,0,0,1,124.545,169.045Z').attr('fill', '#6acdf6').attr('opacity', 0.5).attr('visibility', 'hidden')

      keyPlan // NP4UH
      .append('path')
      .attr('id', 'Key_NP4UH')
      .attr('transform', `translate(-15.5, -64)`)
      .attr('d', 'M38,166.106V108.657H57.948v33.221l7.255,7.254H67.1v19.913H40.934A2.939,2.939,0,0,1,38,166.106Z').attr('fill', '#6acdf6').attr('opacity', 0.5).attr('visibility', 'hidden')

      keyPlan // C10UH
      .append('path')
      .attr('id', 'Key_C10UH')
      .attr('transform', `translate(-15.5, -64)`)
      .attr('d', 'M44.237,79.63H54.943a2.939,2.939,0,0,1,2.938,2.937V96.6a2.939,2.939,0,0,1-2.938,2.938H40.906A2.938,2.938,0,0,1,37.968,96.6V82.567a2.937,2.937,0,0,1,2.938-2.937').attr('fill', '#6acdf6').attr('opacity', 0.5).attr('visibility', 'hidden')

      keyPlan // C20UH
      .append('path')
      .attr('id', 'Key_C20UH')
      .attr('transform', `translate(-15.5, -64)`)
      .attr('d', 'M113.732,79.63h10.706a2.938,2.938,0,0,1,2.938,2.937V96.6a2.939,2.939,0,0,1-2.938,2.938H110.4a2.938,2.938,0,0,1-2.938-2.938V82.567A2.937,2.937,0,0,1,110.4,79.63').attr('fill', '#6acdf6').attr('opacity', 0.5).attr('visibility', 'hidden')

      keyPlan // C30UH
      .append('path')
      .attr('id', 'Key_C30UH')
      .attr('transform', `translate(-15.5, -64)`)
      .attr('d', 'M44.237,149.126H54.943a2.938,2.938,0,0,1,2.938,2.937V166.1a2.94,2.94,0,0,1-2.938,2.938H40.906a2.939,2.939,0,0,1-2.938-2.938V152.063a2.937,2.937,0,0,1,2.938-2.937').attr('fill', '#6acdf6').attr('opacity', 0.5).attr('visibility', 'hidden')

      keyPlan // C40UH
      .append('path')
      .attr('id', 'Key_C40UH')
      .attr('transform', `translate(-15.5, -64)`)
      .attr('d', 'M113.732,149.126h10.706a2.937,2.937,0,0,1,2.938,2.937V166.1a2.939,2.939,0,0,1-2.938,2.938H110.4a2.939,2.939,0,0,1-2.938-2.938V152.063a2.937,2.937,0,0,1,2.938-2.937').attr('fill', '#6acdf6').attr('opacity', 0.5).attr('visibility', 'hidden')

      // --------------------------------------------------------> Deckbox
      keyPlan // N10UH
      .append('path')
      .attr('id', 'Key_N10UH')
      .attr('transform', `translate(-40.034 -151.189)`)
      .attr('d', 'M136.905,176.547v1.538h2.326v4.378h5.694v8.35H139.1v2.906H72.288v-3.769H70.182V182.4h7.9V176.62h.132a2.293,2.293,0,1,1,.13-4.575h-.13v-2.288l5.908-5.907H108.13l3.645,3.645v4.616h-.105a2.335,2.335,0,0,1,2.351,2.218v.038h20.617l.049-.038a2.218,2.218,0,0,1,4.436,0,2.446,2.446,0,0,1-2.34,2.356')
      .attr('fill', '#6acdf6').attr('opacity', 0.5).attr('visibility', 'hidden')

      keyPlan // E10UH
      .append('path')
      .attr('id', 'Key_E10UH')
      .attr('transform', `translate(-54.567 -162.559)`)
      .attr('d', 'M161.676,233.532v2.3H122.892v-30.74h36.566a2.312,2.312,0,0,1,2.219,2.4c0,1.323-.887,2.04-2.146,2.458l.187-.064-8.322,2.813v18.891h7.689a2.566,2.566,0,0,1,2.592,2.309')
      .attr('fill', '#6acdf6').attr('opacity', 0.5).attr('visibility', 'hidden')

      keyPlan // S10UH
      .append('path')
      .attr('id', 'Key_S10UH')
      .attr('transform', `translate(-40.066 -174.26)`)
      .attr('d', 'M70.3,247.529v19.105l6.039,6.038v2.008h9.084v8.129h20.24v-2.8h3.39v-5.331h-.033v-2.468H122.2a2.055,2.055,0,0,0,4.1,0h13.123l5.644-5.644a2.528,2.528,0,0,0,2.057-2.529,2.224,2.224,0,0,0-2.243-2.054v-9.079a2.312,2.312,0,0,0,2.218-2.4v-2.982Z')
      .attr('fill', '#6acdf6').attr('opacity', 0.5).attr('visibility', 'hidden')

      keyPlan // W10UH
      .append('path')
      .attr('id', 'Key_W10UH')
      .attr('transform', `translate(-40.018 -162.559)`)
      .attr('d', 'M70.249,235.828V229.41h2.024V205.088h36.071v30.74H70.125')
      .attr('fill', '#6acdf6').attr('opacity', 0.5).attr('visibility', 'hidden')

      // --------------------------------------------------------> Topside
      keyPlan // PM1UT
      .append('rect')
      .attr('id', 'Key_PM1UT')
      .attr('transform', `translate(29,23)`)
      .attr('width', 42).attr('height', 19.57)
      .attr('fill', '#6acdf6').attr('opacity', 0.5).attr('visibility', 'hidden')

      keyPlan // CM1UT
      .append('path')
      .attr('id', 'Key_CM1UT')
      .attr('transform', `translate(74,22)`)
      .attr('width', 32).attr('height', 20)
      .attr('fill', '#6acdf6').attr('opacity', 0.5).attr('visibility', 'hidden')

      keyPlan // GM1UT
      .append('path')
      .attr('id', 'Key_GM1UT')
      .attr('transform', `translate(52,75)`)
      .attr('width', 35).attr('height', 26)
      .attr('fill', '#6acdf6').attr('opacity', 0.5).attr('visibility', 'hidden')

      keyPlan // WM1UT
      .append('rect')
      .attr('id', 'Key_WM1UT')
      .attr('transform', `translate(81,47)`)
      .attr('width', 24).attr('height', 25)
      .attr('fill', '#6acdf6').attr('opacity', 0.5).attr('visibility', 'hidden')
    },


  

  }
}
           