import * as d3 from 'd3'
import { style } from 'd3'
import __M from 'moment'
export default {
  data: () => ({
    timelineScale: null,

    margin: {
      cutoffTop: 60,
      cutoffLeft: 50,
      chartTop: 160,
      timelineLeft: 100
    },
    
    thead: [     
      { key: 'NO', html: 'No.', color: '#E0E0E0'},
      { key: 'LMS_NO', html: 'LMS No.', color: '#E0E0E0' },
      { key: 'LMS_DESCR', html: 'Description', color: '#E0E0E0'},
      { key: 'PLAN', html: 'Plan', color: '#E0E0E0'},
      { key: 'FORECAST', html: 'Forecast<br/>/ Actual', color: '#E0E0E0'},
      { key: 'STATUS', html: 'Status', color: '#E0E0E0'},
      { key: 'KMS_CNT', html: 'KMS<br>Detail', color: '#E0E0E0'},
    ],
    tbody: [
      { key: 'NO', w: 30  },
      { key: 'LMS_NO', w: 60 },
      { key: 'LMS_DESCR', w: 250, align: 'left'},
      { key: 'PLAN', w: 80 },
      { key: 'FORECAST', w: 80 },
      { key: 'STATUS', w: 80 },
      { key: 'KMS_CNT', w: 60 },
    ],
    tableWidth: 0,
    chartWidth: 800,

    filteredLms: [],
    selectedAll: true,
    
    scrollProps: {
      w: 750,
      h: 750,
      g: 0,
      bar: {
        align : 'right',
        gap   : 3,
        radius: 2,
        width : 8,
        fill  : '#9E9E9E',
        stroke: '#9E9E9E',
      }
    },
    
    legends: [
      {
        function: 'Business',
        bColor  : '#F8CBAD'
      },
      {
        function: 'Commercial',
        bColor  : '#BFBFBF',
        tColor  : '#fff'
      },
      {
        function: 'Completions',
        bColor  : '#9966FF',
        tColor  : '#fff'
      },
      {
        function: 'Construction',
        bColor  : '#00B0F0',
        tColor  : '#fff'
      },
      {
        function: 'DTC',
        bColor  : '#FFC000'
      },
      {
        function: 'Engineering',
        bColor  : '#E2EFDA'
      },
      {
        function: 'Infrastructure',
        bColor  : '#FF80AB'
      },
      {
        function: 'Mfg.',
        bColor  : '#B4C6E7',
        tColor  : '#fff'
      },
      {
        function: 'Olefins',
        bColor  : '#92D050',
        tColor  : '#fff'
      },
      {
        function: 'Polymers',
        bColor  : '#C65911',
        tColor  : '#fff'
      },
      {
        function: 'Supply Chain',
        bColor  : '#FFD600'
      }
    ]

  }),
  methods: {
    drawCharts(){

      // Chart를 그릴 기본 값 - PLAN 2023
      // let filtered = this.DataItems.filter(f => {
      //   return new Date(f.PLAN) >= new Date('2023-01-01') && new Date(f.PLAN) <= new Date('2023-12-31')
      //   // return f.LMS_NO == 'MFG-08'
      // })
      this.filteredLms = [...new Set(this.DataItems.map(d => d.LMS_NO))]
      
      this.drawTable()
      this.drawIcircleChart()
      this.drawButton()
      this.draw_Legends()
    },
    onFilteredLms(lms){
      if (lms == 'ALL') {
        this.filteredLms = this.selectedAll ? [...new Set(this.DataItems.map(d => d.LMS_NO))] : []
      }else {
        let idx = this.filteredLms.indexOf(lms)
  
        if (idx == -1){
          this.filteredLms.push(lms)
        }else {
          this.filteredLms.splice(idx, 1)
        }
      }
      
      d3.selectAll('.table_tr_rows').style('background', '#fff')
      
      let list = this.Queries.SQL1
      list.forEach(row => {
        if (this.filteredLms.includes(row.LMS_NO)) d3.select(`#${row.LMS_NO}`).style('background', this.legends.find(f => f.function == row.FUNCTIONS).bColor)
      })

      this.drawIcircleChart()
    },
    draw_Legends() {

      let legends = this.svg
      .append('g')
      .attr('transform', `translate(${this.tableWidth + 15},${this.margin.chartTop - 70})`)
      .attr('id', `legends`)
      .attr('fill', 'Transparent')

      let drawX = 0
      let drawY = 0

      this.legends.forEach((leg, i) => {
        let idx = i
        if (i > 5) {
          idx = i - 6
          drawY = 30
        }

        drawX = idx * 90

        let legend = legends.append('g')
        .attr('transform', `translate(${drawX},${drawY})`)
        
        legend.append('rect') 
        .attr('x', 0).attr('y', 12)
        .attr('width', 80).attr('height', 18)
        .attr('rx', 2)
        .attr('fill', leg.bColor)
        .attr('stroke', leg.bColor)
        .attr('stroke-width', 3)

        
        legend
        .append('g')
        .append('text')
        .attr('x', 40)
        .attr('y', 16)
        .style('font-size', 10)
        .attr('fill', leg.tColor ? leg.tColor : '#424242')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'hanging')
        .text(leg.function)

      })

      let moyangG = legends.append('g')
      .attr('transform', `translate(700, 50)`)
      .attr('class', 'moyang_group')

      moyangG.append('rect')
      .attr('width', 8)
      .attr('height', 8)
      .attr('fill', '#616161')

      moyangG.append('text')
      .attr('x', 10).attr('y', 8)
      .attr('fill', '#616161')
      .style('font-size', 10)
      .text('Start')

      
      moyangG.append('circle')
      .attr('cx', 50)
      .attr('cy', 5)
      .attr('r', 3.5)
      .attr('fill', '#fff')
      .attr('stroke', '#616161')
      .attr('stroke-width', 2)

      moyangG.append('text')
      .attr('x', 58).attr('y', 8)
      .attr('fill', '#616161')
      .style('font-size', 10)
      .text('In Progress')


      moyangG.append('text')
      .attr('x', 120).attr('y', 9)
      .attr('fill', '#616161')
      .style('font-size', 14)
      .text('★')
      
      moyangG.append('text')
      .attr('x', 134).attr('y', 8)
      .attr('fill', '#616161')
      .style('font-size', 10)
      .text('Completed')


    },
    drawButton(){

      const buttonGroup = this.svg.append('g')
      .attr('transform', `translate(10,${this.margin.chartTop - 25})`)

      buttonGroup.append('rect')
      .attr('rx', 2)
      .attr('width', 85)
      .attr('height', 20)
      .attr('fill', '#424242')

      buttonGroup.append('text')
      .attr('x', 4).attr('y', 14)
      .attr('fill', '#fff')
      .text('Select All LMS')
      .style('font-size', 12)

      buttonGroup.style('cursor', 'pointer')
      .style('opacity', () => {
        return this.selectedAll ? 1 : 0.6
      })

      // buttonGroup.on('mouseover', () => buttonGroup.style('cursor', 'pointer').style('opacity',0.8))
      // buttonGroup.on('mouseout', () => buttonGroup.style('cursor', 'pointer').style('opacity',1))
      
      buttonGroup.on('click', () => {
        this.selectedAll = !this.selectedAll

        this.onFilteredLms('ALL')

        if (this.selectedAll) buttonGroup.style('opacity',1)
        else buttonGroup.style('opacity',0.6)
      })

    },
    drawTable(){
      d3.selectAll(`.icircle_table_group_`).remove()
      
      let list = this.Queries.SQL1

      let tableWidth = 0
      this.tbody.forEach(k => {
        tableWidth += k.w
      })
      this.tableWidth = tableWidth
      this.scrollProps.w = tableWidth

      let group = this.svg.append('g').attr('class', 'icircle_table_group_')
      let tableGroup = group.append('g').attr('transform', `translate(0, ${this.margin.chartTop})`)

      let y_ = (list.length * 20 ) + 42
      this.setScroll(tableGroup, this.scrollProps)
      
      let table_obj = tableGroup.append('foreignObject')
        .attr('width', `${tableWidth}px`)
        .attr('height', `${y_}px`)
        .append('xhtml:body')
        .style('position', 'fixed')

      let table = table_obj.append('table')
        .attr('id', 'icircle_table_group_table_')
        .style('border-collapse', 'collapse')
        .style('font-size', '10px')
        .style('width', `${this.tableWidth}px`)
        .style('table-layout', 'fixed')

      let colgroup = table.append('colgroup')
      this.tbody.forEach(k => {
        colgroup.append('col').style('width', k.w + 'px')
      })

      let thead = table.append('thead')
        let tr = thead.append('tr')

        this.thead.forEach(item => {
            tr.append('th')
            .attr('class', 'icircle_table_group_thead_')
            .style('border', 'solid 0.7px #eee')
            .style('height', '40px')
            .style('padding', '0')
            .style('background', '#F5F5F5')
            .html(item.html)
        })
        
      let tbodykeys = this.tbody

      let tbody = table.append('tbody').attr('id', 'icircle_table_group_tbody_')
      if (list.length == 0) {
        
        let tr = tbody.append('tr')

        tr.append('td')
          .attr('colspan', 5)
          .style('background', '#F5F5F5')
          .style('height', '24px')
          .style('text-align', 'center')
          .style('color', '#424242')
          .style('padding', '0 3px 0 3px')
          .style('font-style', 'italic')
          .html('No data')
          
        
        return
      }

      for (let r = 0; r < list.length; r++) {
        let tr = tbody.append('tr')
        
        tr.on('mouseover', () => {
          // tr.style('background', '#FFF8E1')

          tr.style('opacity',0.8)

          d3.selectAll('.lms_groups_').style('opacity',0.1)
          d3.select(`#${list[r]['LMS_NO']}_group_`).style('opacity',1)
          d3.select(`.lms_label_${list[r]['LMS_NO']}`).style('opacity',1)

        })

        
        tr.on('mouseout', () => {
          tr.style('opacity',1)
          d3.selectAll('.lms_groups_').style('opacity', 1)
          d3.select(`.lms_label_${list[r]['LMS_NO']}`).style('opacity',0)
          

          if(!this.filteredLms.includes(list[r].LMS_NO)) return

          return this.legends.find(f => f.function == list[r].FUNCTIONS).bColor
        })

        tbodykeys.forEach((k, i) => {

          let td = tr.append('td')
            .style('border', 'solid 0.1px #eee')
            .style('height', '20px')
            .style('text-align', k.align ? k.align : 'center')
            .style('padding', '0 3px 0 3px')
          
          if(i == 1) {
            td
            .attr('class', 'table_tr_rows')
            .attr('id', list[r].LMS_NO)
            .style('background', () => {
              if(!this.filteredLms.includes(list[r].LMS_NO)) return

              return this.legends.find(f => f.function == list[r].FUNCTIONS).bColor
            })
          }
          
          if(i == 2) {
            td.style('white-space', 'nowrap')
              .style('overflow', 'hidden')
              .style('text-overflow', 'ellipsis')
              .attr('title', list[r][k.key])
          }

          if(i == 6) {
            td.style('text-decoration', 'underline')
            
            td.on('mouseover', () => td.style('cursor', 'pointer').style('opacity',0.5))
            td.on('mouseout', () => td.style('cursor', 'pointer').style('opacity',1))
            td.on('click', () => {
              let request_ = this.getRequestColumnProps('request', { LMS_NO: list[r]['LMS_NO'] }, this.ColumnProps)
              this.$emit('request-action', request_)
            })
          } else {
            td.on('mouseover', () => td.style('cursor', 'pointer').style('opacity',0.5))
            td.on('mouseout', () => td.style('cursor', 'pointer').style('opacity',1))
            td.on('click', () => {
              this.onFilteredLms(list[r]['LMS_NO'], list[r]['FUNCTIONS'])
            })
          }
        
          td.html(list[r][k.key])
        })
      }
    },
    drawIcircleChart(){
      d3.selectAll(`.__group_icircle_chart_`).remove()
      d3.selectAll(`.__group_timeline_`).remove()

      let cutoffs = [...new Set(this.DataItems.map(d => d.CUTOFF))]
      let lmsNos = this.filteredLms
      // let lmsNos = [...new Set(this.DataItems.map(d => d.LMS_NO))]

      let filtered = this.DataItems.filter(f => {
        return lmsNos.includes(f.LMS_NO)
      })


      cutoffs.sort((a,b) => {
        return new Date(a) - new Date(b)
      })

      let dataSet = JSON.parse(JSON.stringify(filtered))

      let dates = [
        ...dataSet.map(m => m.PLAN),
        ...dataSet.map(m => m.FORECAST)
      ]
      let dateRange = {
        minDate: dataSet.length == 0 ? new Date('2023-01-01') : new Date(__M(new Date(Math.min.apply(null,dates.map(d => new Date(d))))).add(-2, 'month')),
        maxDate: dataSet.length == 0 ? new Date('2023-12-31') : new Date(__M(new Date(Math.max.apply(null,dates.map(d => new Date(d))))).add(2, 'month').endOf('month'))
      }

      // For Timeline - get x position
      let timelineScale = d3.scaleTime()
      .domain([__M(dateRange.minDate).toDate(), __M(dateRange.maxDate).toDate()])
      .range([0, this.chartWidth]) 
      // .clamp(true)(Date.now())
      this.timelineScale = timelineScale

      this.drawTimeline(dateRange)

      // Cutoff Sclae      
      let cutoffScale = d3.scaleBand()
      .domain(cutoffs)
      .range([0, cutoffs.length * 20])

      
      let lineLength = d3.line()
        .x(d => timelineScale(new Date(d.FORECAST)))
        .y(d => cutoffScale(d.CUTOFF))

      
      let drawGroup = this.svg.append('g')
      .attr('transform', `translate(${this.margin.timelineLeft + this.tableWidth}, ${this.margin.cutoffTop + this.margin.chartTop + 10})`)
      .attr('class', '__group_icircle_chart_')


      let cutoffGroup = drawGroup.append('g')
      .attr('transform', `translate(${-this.margin.cutoffLeft}, 0)`)
      .attr('class', '__group_icircle_chart_cutoff')

      cutoffGroup.append('rect')
      .attr('width', 90).attr('height', 40)
      .attr('x', -40).attr('y', -70)
      .attr('fill', '#F5F5F5')

      cutoffGroup.append('text')
      .attr('x', -15).attr('y', -44)
      .attr('fill', '#333')
      .text('Cutoff')
      .style('font-size', 10)

      cutoffs.forEach(c => {
        cutoffGroup
        .append('text')
        .attr('x', 0)
        .attr('y', cutoffScale(c))
        .style('font-size', 10)
        .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .attr('class', 'TESTSTARCLASS')
        .text(() => {
          return __M(new Date(c)).format('MMM-YYYY')
        })
      })


      lmsNos.forEach(lms => {
        let lmsGroup = drawGroup.append('g')
        .attr('transform', `translate(0,0)`)
        .attr('class', `lms_groups_`)
        .attr('id', `${lms}_group_`)

        let func = this.DataItems.filter(f => f.LMS_NO == lms)[0].FUNCTIONS

        let legend = this.legends.find(f => f.function == func) ? this.legends.find(f => f.function == func) : 
        {
          function: 'No',
          bColor  : '#fff'
        }

        let filtered = dataSet.filter(f => f.LMS_NO == lms)

        filtered.sort((a,b) => {
          return new Date(a.CUTOFF) - new Date(b.CUTOFF)
        })

        
        let codes = [...new Set(filtered.map(d => d.STATUS_CODE))]

        const linedata = []
        filtered.forEach((f, i) => {
          if (!codes.find(f => f == 'N')) return
          const obj = {}
          obj.CUTOFF = f.CUTOFF
          obj.FORECAST = i == 0 ?f.PLAN: f.FORECAST
          linedata.push(obj)
        })


        
        lmsGroup.append('path')
        .attr('stroke-width', 1)
        .attr('stroke', legend.bColor)
        .attr('class', 'LINEDATAPATH')
        .attr('fill', 'none')
        .attr('d', () => {
          return lineLength(linedata)
        })

        filtered.forEach((item, idx) => {
          // let forLineData = [{
          //   CUTOFF: item.CUTOFF,
          //   FORECAST: item.FORECAST
          // }]
          
          // console.log(lineLength())
          // console.log([...forLineData], lineLength([...forLineData]))
          
          if (codes.find(f => f == 'N')){
            if (idx == 0) {
              lmsGroup.append('rect')
              .attr('x', this.timelineScale(new Date(item.PLAN))-3)
              .attr('y', (cutoffScale(item.CUTOFF)))
              .attr('width', 7).attr('height', 7).attr('fill', legend.bColor)
            }else {
              if (item.STATUS_CODE == 'C' && idx == (filtered.length - 1)){
                
                lmsGroup
                .append('text')
                .attr('x', this.timelineScale(new Date(item.FORECAST)))
                .attr('y', cutoffScale(item.CUTOFF))
                .style('font-size', 14).style('fill', legend.bColor)
                .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
                .attr('class', 'TESTSTARCLASS')
                .text('★')

              }else {
                lmsGroup.append('circle')
                .attr('cx', this.timelineScale(new Date(item.FORECAST)))
                .attr('cy', cutoffScale(item.CUTOFF))
                .attr('r', 2.5)
                .attr('fill', '#fff')
                .attr('stroke', legend.bColor).style('stroke-width', 1.5)
              }
            }

          }else {
            // STATUS CODE가 전부 C이면 CUTOFF가 제일 작은 ROW만 별로 그리기
            if (idx == 0 ){
              lmsGroup
              .append('text')
              .attr('x', this.timelineScale(new Date(item.FORECAST)))
              .attr('y', cutoffScale(item.CUTOFF))
              .style('font-size', 14).style('fill', legend.bColor)
              .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
              .text('★')
            }
          }

        })

        let labelPlan = this.DataItems.filter(f => f.LMS_NO == lms)[0].PLAN

        let lmsLable = lmsGroup.append('g')
        .attr('class', `lms_label_${lms}`)
        .style('opacity', 0)
        .attr('transform', `translate(${this.timelineScale(new Date(labelPlan))-3}, -24)`)

        lmsLable.append('rect')
        .attr('x', -25)
        .attr('width', 50).attr('height', 11)
        .attr('fill', legend.bColor)

        lmsLable.append('text')
        .attr('x', 0).attr('y', 9)
        .style('font-size', 10).attr('text-anchor', 'middle')
        .attr('fill', legend.tColor ? legend.tColor : '#424242')
        .text(lms)

        // Add hover event
        lmsGroup.style('cursor', 'default')
        lmsGroup.on('mouseover', () => {
          d3.selectAll('.lms_groups_').style('opacity',0.1)
          lmsGroup.style('opacity',1)

          lmsLable.style('opacity',1)
        })

        
        lmsGroup.on('mouseout', () => {
          d3.selectAll('.lms_groups_').style('opacity', 1)

          lmsLable.style('opacity', 0)
        })
      })


    },
    
    drawTimeline(dateRange){

      let timelineValues = {
        year: [],
        month: [],
      }

      // Get start, end
      let startDate = __M(new Date(dateRange.minDate)).startOf('month').toDate()
      let endDate = __M(new Date(dateRange.maxDate)).endOf('month').toDate()


      // Get timeline months
      let months = endDate.getMonth() - startDate.getMonth()
      let years = endDate.getYear() - startDate.getYear()
      months = months + (years*12)

      
      let prevYearDate_ = startDate

      for(var i=0; i<months; i++) {
        let _nextMonthDate__ = new Date(new Date(startDate).setMonth(startDate.getMonth() + i))

        // For the Year ---
        if(d3.timeFormat('%Y')(prevYearDate_) != d3.timeFormat('%Y')(_nextMonthDate__)) {
          let midDateYear_ = new Date(
            d3.mean([
              prevYearDate_,                                                                    // 1st date of the year
              new Date(new Date(_nextMonthDate__).setMonth(_nextMonthDate__.getMonth() + 1) - 1)  // last date of the year
            ])
          )
          timelineValues.year.push({ name: d3.timeFormat('%Y')(midDateYear_), value: new Date(midDateYear_) })
          prevYearDate_ = _nextMonthDate__
        }
      }

      // Year remained
      let midDateYear_ = new Date(
        d3.mean([
          prevYearDate_,  // 1st date of the year
          endDate        // last date of the year
        ])
      )
      timelineValues.year.push({ name: d3.timeFormat('%Y')(midDateYear_), value: new Date(midDateYear_) })

      for(i=0; i<=months; i++) {
        let _nextMonthDate__ = new Date(new Date(startDate).setMonth(startDate.getMonth() + i))

        // For the Month ---
        let endMonth_ = new Date(new Date(_nextMonthDate__).setMonth(_nextMonthDate__.getMonth() + 1) - 1)
        let midDate = new Date(
          d3.mean([
            _nextMonthDate__, // 1st date of the month
            endMonth_         // last date of the month
          ])
        )
        timelineValues.month.push({ 
          name: d3.timeFormat('%m')(midDate),
          value: new Date(midDate),
          endDate: endMonth_
        })
      }


      // Draw timeline
      let startX = this.timelineScale(startDate)
      let endX = this.timelineScale(endDate)
      
      let g = this.svg
      .append('g')
      .attr('transform', `translate(${this.margin.timelineLeft + this.tableWidth}, ${this.margin.chartTop})`)
      .attr('class', '__group_timeline_')
  
      g
      .append('path') // shadow
      .attr('d', `M${startX + 50},10 H ${endX - 50} L ${endX},20 H${startX} Z`).attr('fill', `#F5F5F5`) 

      g
      .append('rect')
      .attr('x', startX).attr('y', 0).attr('width', this.chartWidth).attr('height', 40).attr('fill', `#F5F5F5`)

      g
      .append('g')
      .selectAll('text')
      .data(timelineValues.year)
      .enter()
      .append('text')
      .attr('x', d => this.timelineScale(d.value))
      .attr('y', 15).style('font-size', 13).style('fill', '#1976D2').attr('font-weight', 500)
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(d => d.name.toUpperCase())

      // Timeline Text using the scale function
      // Month
      g
      .append('g')
      .selectAll('text')
      .data(timelineValues.month)
      .enter()
      .append('text')
      .attr('x', d => this.timelineScale(d.value))
      .attr('y', 30).style('font-size', 10).style('fill', '#333')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(d => d.name.toUpperCase())

      // Timeline Seperator
      g
      .append('g')
      .selectAll('path')
      .data(timelineValues.month)
      .enter()
      .append('path')
      .attr('d', (d,i) => {
        if (timelineValues.month.length-1 > i) {
          return `M${this.timelineScale(d.endDate)}, 36 V26`
        }
      })
      .attr('stroke', '#757575').attr('stroke-width', 0.3)
      
    },
  }
}
