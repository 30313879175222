import * as d3 from 'd3'

export default {

  methods: {
    /*
      load_Lev1()
      setCoordinate_Lev1()
      unload_Lev1()
    */
      load_Lev1() {

      this.stage_Header.select(`#HOME`).attr('visibility', 'hidden')
      this.stage_Header.select(`#BACK`).attr('visibility', 'hidden')

      this.innerSvg = this.svg.append('svg')
      this.setPriority()

      this.Areas.forEach(d=> {
        d3.select(`#PLAN-${d.code}`)
        .transition().duration(300)
        .style('opacity', 0)
      })

      this.innerSvg
      .append('image')
      .attr('id','lev1_background')
      .attr('transform', `translate(-330, -330) scale(1.3)`)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/China1/Area_Summary/images/MAINPLANT.jpg`)
      .style('opacity', 0)
      
      d3.select('#lev1_background')
      .transition().duration(500)
      .attr('transform', `translate(-90, -110) scale(1)`)
      .style('opacity', 1)

      d3.select('#HeaderTitle_Area')
      .transition().duration(500)
      .attr('fill', '#F35E90')
      .text(`Main Plant - Package (overall)`)
      
      // After loading the SVG, perform initialization
      this.loadSvg(
        this.innerSvg, {
        x   : 0,
        y   : 0,
        url : '/China1/Area_Summary/svg/Main_Plant.svg'
      }).then(() => {
        this.Package.forEach(d => {
          this.innerSvg.select(`#BTN-${d.code}`).style('opacity', 0)
          this.innerSvg.select(`#AREA-${d.code}`).attr('transform', `translate(${d.x}, ${d.y})scale(0)`).style('opacity', 0)
          this.innerSvg.select(`#BOX-${d.code}`).style('opacity', 0)
        })
        this.summary_Overall()
        this.setCoordinate_Lev1() // ---> below
        // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        // PAGESVG 에서는 drawNotes,drawTexts 는 loadsvg 가 제일 마지막에 실행이되므로
        // this.loadSvg().then 에서 처리해야 제일 상단에 그려진다.
        // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        this.drawNotes()
        this.drawTexts() 
      })
    },
    
    summary_Overall() {

      let x = 30
      let y = 360
      let initY = y - 15

      let packageSummary = this.innerSvg
      .append('g')
      .attr('id', `Group_Summary`)
      .attr('transform', `translate(${this.styles.LEV1_X},${this.styles.LEV1_Y})`)
      .style('cursor', 'pointer')
      .call(this.call_Link_Summary, 'request1', 'OV', 'OV') // ------->

      //main title
      packageSummary
      .append('text')
      .attr('x', 4).attr('y', y).attr('fill', '#000').attr('font-weight', 500).attr('font-size', 13)
      .text(this.styles.LEV1_TITLE)

      y = y + 8

      //title,bar,text
      this.packageSum.filter(f=> f.PKG == 'TOTAL').forEach((d,i) => {
        //title
        packageSummary
        .append('text')
        .attr('x', 4).attr('y', y + 9).attr('fill', '#000').attr('font-size', 8.5)
        .text(d.DISC)

        //bar tot rect
        packageSummary
        .append('rect')
        .attr('x', 200).attr('y', y).attr('width', 100).attr('height', 11).attr('fill', '#F4F5F5')

        //bar actual rect
        packageSummary
        .append('rect')
        .attr('id', `bar-${i}`)
        .attr('x', 200).attr('y', y).attr('width', 0).attr('height', 11).attr('fill', '#83D2F5')

        //bar actual text
        packageSummary
        .append('text')
        .attr('id', `actual-${i}`)
        .attr('x', 201).attr('y', y + 9).attr('fill', '#757575').attr('font-size', 10)
        .text(0)

        //bar remain text
        packageSummary
        .append('text')
        .attr('id', `remain-${i}`)
        .attr('x', 301).attr('y', y + 9).attr('fill', '#757575').attr('font-size', 10)
        .text(d.TOTAL)

        //click event
        // packageSummary
        // .append('rect')
        // .attr('id', `link_${d.CODE}`)
        // .attr('x', 4).attr('y', y).attr('width', 325).attr('height', 11).attr('fill',`transparent`)
        // .attr('opacity', .5)
        // .call(this.linkEvent_Summary, d, 'request1') // ------->
        
        y = y + 18
      })
  
      packageSummary
      .append('rect')
      .attr('x', 4).attr('y', initY).attr('width', this.styles.WIDTH).attr('height', (this.packageSum.filter(f=> f.PKG == 'TOTAL').length * 18) + 15).attr('fill',`transparent`) // transparent
      .attr('opacity', .5)

      // animate
      this.packageSum.filter(f=> f.PKG == 'TOTAL').forEach((d,i) => {

        d3.select(`#bar-${i}`)
        .transition()
        .ease(d3.easeLinear)
        .duration(1500)
        .attr('width', 100 * (d.PRO/100))

        d3.select(`#actual-${i}`)
        .transition()
        .ease(d3.easeLinear)
        .duration(1500)
        .tween("text", function() {
          const interpolator = d3.interpolateNumber(0, d.ACTUAL)
          return function(t) {
            d3.select(`#actual-${i}`).text(Math.round(interpolator(t)))
          }
        })

        d3.select(`#remain-${i}`)
        .transition()
        .ease(d3.easeLinear)
        .duration(1500)
        .tween("text", function() {
          const interpolator = d3.interpolateNumber(d.TOTAL, d.REMAIN)
          return function(t) {
            d3.select(`#remain-${i}`).text(Math.round(interpolator(t)))
          }
        })
      })

    },


    setCoordinate_Lev1() {
      // Set mouse events on the package button
      this.Package.forEach(d => {
        this.innerSvg
        .select(`#BTN-${d.code}`)
        .style('cursor', 'pointer')
        .on('click', () => {
          // Hide elements that appear when hovering over with mouse
          this.innerSvg.select(`#AREA-${d.code}`).attr('visibility', 'hidden')
          this.innerSvg.select(`#BOX-${d.code}`).attr('visibility', 'hidden')
          this.innerSvg.select(`#package_group_${d.code}`).attr('visibility', 'hidden')
          
          this.routeTo({
            path: 'Lev1',
            package: d.code
          }, {
            path: 'Lev2',
            package: d.code
          })

        })
        .call(this.call_Coordinate, d) // ---> in Event.js
        this.draw_package(this.innerSvg, d) // ---> in draw_Package.js
      })
    },



    unload_Lev1() {
      this.innerSvg.select('#lev1_background')
      .transition().duration(500)
      .attr('transform', `translate(-330, -330) scale(1.3)`)
      .style('opacity', 0)

      return new Promise(resolve => {
        setTimeout(() => {
          this.innerSvg.remove()
          resolve() 
        }, 500)
      })
    },

  }
}