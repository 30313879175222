import gmx_zMixins         from '../../../../../../includes/Jmixins/zMixins'

// Locally declared common Data-Props & SVG Drawing Support

import lmx_LocalValues          from './set_LocalValues'
import lmx_Watching             from './Watching'

import lmx_Styles               from '../primitives/styles'
import lmx_Events               from './Events'

import lmx_Forms                from './draw_Forms'
import draw_Diagram             from './draw_Diagram'


export default {
  mixins: [
    gmx_zMixins, 

    // Local Mixins
    
    lmx_Styles,
    lmx_Watching,
    lmx_Events,

    lmx_LocalValues,
    lmx_Forms,
    draw_Diagram,
  ],
}
