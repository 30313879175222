export default {
  width: 100,
  gab: 6,
  attrs: [
    { 
      text: 'Total',
      refColumn: 'TOTAL',
      codeValue   : 'Total', // fixed
      fill : 'lightGray', 
      fColor: 'Transparent', 
      tColor: '#212121',
      on: true
    },
    { 
      text: 'Not Started',
      refColumn: 'NOTSTARTED',
      codeValue   : 'Not Started',
      fill : 'fillWhite', 
      fColor: '#BDBCBC', 
      tColor: '#818181',
      on: true
    },
    { 
      text: 'In Progress',
      refColumn: 'INPROGRESS',
      codeValue   : 'A ITR in progress',
      fill : 'fillYellow', 
      fColor: '#FFD600',
      tColor: '#fff',
      on: true
    },
    { 
      text: 'A ITR Completed',
      refColumn: 'AITR_COMPLETED',
      codeValue   : 'A ITR Completed',
      fill : 'fillBlue', 
      fColor: '#66CCFF', 
      tColor: '#fff',
      on: true
    },
    { 
      text: 'Partial MC Complete',
      refColumn: 'PARTIAL_MC_COMPLETE',
      codeValue   : 'Partial MC Complete',
      fill : 'fillLightGreen', 
      fColor: '#8BC34A', 
      tColor: '#fff',
      on: true
    },
    { 
      text: 'Full MC Complete',
      refColumn: 'FULL_MC_COMPLETE',
      codeValue   : 'Full MC Complete',
      fill : 'fillGreen', 
      fColor: '#43A047', 
      tColor: '#fff',
      on: true
    },
  ]
}
