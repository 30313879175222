import * as d3 from 'd3'

export default {
  data: () => ({
    colorDomain: ['SHIP_DATE', 'SA_ACTUAL', 'LO_ACTUAL', 'none'],
    colors: ['#feed57', '#cddc37','#83d2f5', '#fff'],
    colorScale: null,
    dataSet: [],
    legendData: [
      {value: 'none',      text: 'Not Arrival', bgColor: '#fff', color: '#949494'},
      {value: 'SHIP_DATE', text: 'Wating Load-out', bgColor: '#feed57', color: '#000'},
      {value: 'LO_ACTUAL', text: 'Load-out', bgColor: '#83d2f5', color: '#fff'},
      {value: 'SA_ACTUAL', text: 'Sail-away', bgColor: '#cddc37', color: '#fff'},
    ],
    textScale: null,
    textColors: ['#000', '#fff','#fff', '#949494'],
  }),
  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {
    setDefaultValues(){
      this.dataSet = JSON.parse(JSON.stringify(this.DataItems))
      let parseTime = d3.timeParse('%Y-%m-%d')
      let yearFormat = d3.timeFormat('%Y')
      let dateFormat = d3.timeFormat('%m-%d')

      this.dataSet = this.dataSet.map(d => ({
        MOD: d.MOD,
        PAU: d.PAU,
        CERT: d.CERT,
        SHIP: d.SHIP,
        SHIP_NAME: d.SHIP_NAME,
        
        LO_PLAN: d.LO_PLAN,
        LO_ACTUAL: d.LO_ACTUAL,

        //time data
        SHIP_DATE: parseTime(d.SHIP_DATE),
        PLAN_YEAR: yearFormat(parseTime(d.SA_PLAN)),
        SA_PLAN: dateFormat(parseTime(d.SA_PLAN)),
        SA_ACTUAL: parseTime(d.SA_ACTUAL),

      }))

      this.dataSet = d3.nest()
      .key(d => d.PLAN_YEAR)
      .key(d => d.SHIP)
      .entries(this.dataSet)

      this.setColorScale()
      this.setTextColorScale()
    },
    setColorScale(){
      this.colorScale = d3.scaleOrdinal()
      .domain(this.colorDomain)
      .range(this.colors)
    },
    setTextColorScale(){
      this.textScale = d3.scaleOrdinal()
      .domain(this.colorDomain)
      .range(this.textColors)
    },
    isfilterColorData(_d) {
      let shipDate = ['SHIP_DATE', 'LO_ACTUAL', 'SA_ACTUAL']
      let eachVesselFilter = []
      Array.from({length: _d.values.length}, (d_, i_) => i_).forEach((d_, i_) => {
        eachVesselFilter.push(shipDate.filter(actual => {
          return actual == 'SHIP_DATE' ? _d.values[i_][actual] <  new Date(Date.now() + 12096e5)  : _d.values[i_][actual]
        }).pop())})
      return eachVesselFilter.every(d => d == eachVesselFilter[0]) && !!eachVesselFilter[0] ? eachVesselFilter[0] : 'none'
    }
  }
}