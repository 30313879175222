import values from 'core-js/fn/array/values'
import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  data: () => ({
    margin: { top: 80, left: 100, bottom: 50, right: 50 },
    width: 1500,
    height: 380,
    filter: [],
    legend: 
    [
      { column: 'CUM_PLAN_PROG'   , color: '#616161', shape: 'line', text: 'Cum. Plan Prog.', axis: 'right', lineShape: 'dash', x: 350, y: 25 },
      { column: 'CUM_ACTUAL_PROG' , color: '#388E3C', shape: 'line', text: 'Cum. Actual Prog.', axis: 'right',  x: 490, y: 25},
      { column: 'WK_PLAN_PROG'    , color: '#E0E0E0', shape: 'rect', text: 'Wk Plan Prog.', axis: 'left', x: 80, y: 25 },
      { column: 'WK_ACTUAL_PROG'  , color: '#9CCC65', shape: 'rect', text: 'Wk Actual Prog.', axis: 'left', x: 210, y: 25 },
      { column: 'WKS4_TREND'      , color: '#29B6F6', shape: 'line', text: '4Weeks Trend', axis: 'right',lineShape: 'dash', x: 630, y: 25 }
    ],
    table: {
      display: 'Y',
      bottom: 500,
      dwarY:45,
      gapFromChart: 0,
      cellWidth: 0,
      labelX: -90,

      width:1350, 
      hHeight: 21,
      lineHeight: 21,

      sColor:'#757575', 
      sWidth: 0.5, 
  
      headers: [
        {name: 'Cum. Plan Prog.'  , colName: 'CUM_PLAN_PROG'   , x: 175, y: 13,  col: 'Y', link: 'N', align: 'middle',  tSize: 10, tColor: '#000', dColor: '#757575', dSize: 10, markupFill: '#9e9d24'}, 
        {name: 'Cum. Actual Prog.', colName: 'CUM_ACTUAL_PROG'  , x: 110, y: 20,  col: 'Y', link: 'N', align: 'middle',  tSize: 10, tColor: '#000', dColor: '#757575', dSize: 10, markupFill: '#9c27b0'},
        {name: 'Wk Plan Prog.'    , colName: 'WK_PLAN_PROG'    , x: 250, y: 13,  col: 'Y', link: 'N', align: 'middle',  tSize: 10, tColor: '#000', dColor: '#757575', dSize: 10, markupFill: '#e0e0e0'},
        {name: 'Wk Actual Prog.'  , colName: 'WK_ACTUAL_PROG'   , x: 110, y: 20,  col: 'Y', link: 'N', align: 'middle',  tSize: 10, tColor: '#000', dColor: '#757575', dSize: 10, markupFill: '#e53935'}, 
        {name: '4Weeks Trend'     , colName: 'WKS4_TREND'  , x: 110, y: 20,  col: 'Y', link: 'N', align: 'middle',  tSize: 10, tColor: '#000', dColor: '#757575', dSize: 10, markupFill: '#9c27b0'},
        
      ],
    },
    
    scrollProps: {
      w: 990, // set clip width
      h: 560, // full clip height
      g: 0,
      bar: {
        align : 'bottom',
        gap   : 3,
        radius: 2,
        height : 6,
        fill  : '#9E9E9E',
        stroke: '#9E9E9E',
      }
    }
  }),
  methods: {
    Draw_Charts() {
      //원래는 this.DataItems[0] === 0 이면 return 하면되는데 이상한 데이터가 들어와서 이렇게 작업함 
      if (!this.DataItems[0].WK_NO) { 
        //console.log(this.Text.Text[0].text)
        let svg = this.svg
        svg.append('rect')
        .attr('x', 0).attr('y', 0).attr('width', 980).attr('height', 360).attr('fill', '#fff')
        svg.append('text').attr('x', 980/2).attr('y', 360/2).attr('text-anchor', 'middle')
        .text('N/A').attr('font-size', '30px')
        .attr('fill', '#ccc')

        return
      }
      const list = this.DataItems
        .map(v => {
          return {
            CATEGORY: v['WK_NO'],
            VALUES: [
              { KEY: 'CUM_PLAN_PROG', VALUE: v['CUM_PLAN_PROG'] },
              { KEY: 'CUM_ACTUAL_PROG', VALUE: v['CUM_ACTUAL_PROG'] },
              { KEY: 'WK_PLAN_PROG', VALUE: v['WK_PLAN_PROG'] },
              { KEY: 'WK_ACTUAL_PROG', VALUE: v['WK_ACTUAL_PROG'] },
              { KEY: 'WKS4_TREND', VALUE: v['WKS4_TREND'] }
            ]
          }
        })

      this.filter = this.legend.map(d => d.column)

      const width = this.width - 30
      const height = this.height - 15

      const svg = this.svg
        .append('svg')
        .attr('width', `${width}`)
        .attr('height', `${height}`)


      // svg
      //   .append('g')
      //   .attr('id','chart_progress')
      //   .attr('transform', `translate(${this.margin.left},${this.margin.top})`)

        
      let viewGroup = svg.append('g')
      .attr('id','vierGroupForScroll_')
      .attr('transform', `translate(0,0)`)

      // this.setHorizonScroll(viewGroup, this.scrollProps, 'test', viewGroup)
      

      this.setScroll(viewGroup, this.scrollProps)

      let legendGoup = svg
        .append('g')
        .attr('id','chart_legend')
        .attr('transform', `translate(120,20)`)

      const legendName = this.legend
      const legends = legendGoup.selectAll('.legend').data(legendName)

      const filter = this.filter
      const render = this.render

      const legend = legends
        .enter()
        .append('g')
        .attr('class', 'legend')
        .attr('transform', (d, i) => {
          return `translate(${d.x}, ${d.y})`
        })
        .style('user-select', 'none')
        .on('click', function (d) {
          const el = d3.select(this)
          const text = el.select('text')
          const rect = el.select('rect')
          const column = d.column
          if (!filter.includes(column)) {
            text.attr('fill', '#424242')
            rect.style('fill', d.color)
            filter.push(column)
          } else {
            text.attr('fill', '#efefef')
            rect.style('fill', '#efefef')
            const idx = filter.indexOf(column)
            filter.splice(idx, 1)
          }
          const newValue = list.map(d => {
            const obj = {
              CATEGORY: d['CATEGORY'],
              VALUES: d['VALUES'].filter(v => filter.includes(v['KEY']))
            }
            return obj
            
          })
          render(viewGroup, newValue)
        })
      legend.append('rect')
        .attr('x', 0)
        .attr('width', 14)
        .attr('height', d => d.shape === 'line' ? 2 : 6)
        .attr('y', d => d.shape === 'line' ? 4 : 4)
        .style('fill', d => d.color)

      legend.append('text')
        .attr('x', 16)
        .attr('y', 6)
        .attr('dy', '.35em')
        .attr('text-anchor', 'start')
        .attr('font-size', '9px')
        .attr('font-family', 'arial')
        .style('cursor', 'pointer')
        .text(d => d.text)

        render(viewGroup, list)
        this.drawTable(viewGroup, list)
    },
    drawTable(group, list){
      let valueX = 0

      let chartWidth = 1350
      let columnWidth = chartWidth / list.length

      let tableGroup = group
      .append('g').attr('transform', `translate(-100,335)`).attr('class', 'tableGroup_')

      let thead = list[0].VALUES

      let tableFormGroup = tableGroup.append('g').attr('transform', `translate(100.5,-1.5)`).attr('class', 'tableFormGroup_')
      for(let i = 0; i< this.table.headers.length+1; i++ ){
        // row effect
        if( i != this.table.headers.length - 0){
          tableFormGroup
          .append('rect')
          .attr('transform', `translate(${this.table.labelX},${this.table.hHeight-30 + (this.table.lineHeight * i)})`)
          .attr('fill', '#fff')
          .attr('width', this.table.width + Math.abs(this.table.labelX))
          .attr('height', this.table.lineHeight)
          .on('mouseover', (_, i, a) => { d3.select(a[i]).transition().duration(100).style('fill', '#F4F5F5') })
          .on('mouseout', (_, i, a) => { d3.select(a[i]).transition().duration(100).style('fill', '#fff') })  
        }
        // line
        tableFormGroup
        .append('path')
        .attr('d', `M${this.table.labelX},${this.table.hHeight -32 + (this.table.lineHeight * i+2)}H${this.table.width}`)
        .style('stroke', i == this.table.headers.length-5 ? this.table.sColor : i == this.table.headers.length-0 ? this.table.sColor : i == this.table.headers.length-3 ? this.table.sColor : '#BCBCBC')//아래 위 줄 두개 색깔 다르게 하기
        .style('stroke-width', this.table.sWidth)
      }
      let titleGroup = tableGroup.append('g').attr('transform', `translate(0,2.5)`).attr('class', 'titleGroup_')
      this.legend.forEach((d, i) => {
        titleGroup.append('text')
        .attr('transform', `translate(90,${i * 21})`).style('font-size', '10px').attr('text-anchor', 'end').attr('fill', '#757575')
        .text(d.text)
      })

      // console.log(columnWidth)
      let tbodyGroup = tableGroup.append('g').attr('transform', `translate(${columnWidth < 32 ? 105: columnWidth > 90 ? 140 : 120},3.5)`).attr('class', 'tbodyGroup_')
      // row 5개 옆으로 이동시키는 tbody
      // console.log(list)
      list.forEach(col => {
      let valueY = 0
      // console.log(col)
        col.VALUES.forEach((row, rowIdx) => {
          // console.log(row)
          tbodyGroup.append('text')
          .attr('transform', `translate(${valueX},${valueY})`)
          .style('font-size', '10px')
          // .style('fill', Number(row.VALUE).toFixed(1) == 0 ? '#E0E0E0' : '#2c3e50')
          .style('fill', row.KEY == 'CUM_PLAN_PROG'? '#616161' : row.KEY == 'CUM_ACTUAL_PROG' ? '#388E3C' : row.KEY == 'WK_PLAN_PROG'?'#9E9E9E': row.KEY == 'WK_ACTUAL_PROG' ? '#9CCC65' : '#29B6F6' )
          // .text(row.VALUE ? Number(row.VALUE).toFixed(1): '')
          .text(row.VALUE == 100 ? Number(row.VALUE).toFixed(0): row.VALUE ?Number(row.VALUE).toFixed(1) : '')
          valueY += rowIdx * 1 +20
        })
        valueX += columnWidth
      })
    },
    render (group, dataset) {
      const { top, left, bottom, right } = this.margin
      const width = this.width - left - right
      const height = this.height - top - bottom

      // const g = d3.select(this.$el).select('svg g')
      
      group.selectAll('.chartGroup_').remove()

      const g = group
      .append('g').attr('transform', `translate(0,10)`).attr('class', 'chartGroup_')

      const y0 = d3.scaleLinear().range([ height, 0 ])
      const y1 = d3.scaleLinear().range([ height, 0 ])

      const x0 = d3.scaleBand().range([ 0, width ])
      const x1 = d3.scaleBand().range([ 0, width ])

      const leftColumns = this.legend.filter(d => d.axis === 'left').map(d => d['column'])
      const leftArr = dataset.map(d => d['VALUES']).flat().filter(v => v['VALUE'] && leftColumns.includes(v['KEY'])).map(v => v['VALUE'])
      // console.log(leftArr)

      const leftValues = Math.max.apply(null, leftArr)

      const rightColumns = this.legend.filter(d => d.axis === 'right').map(d => d['column'])
      const rightArr = dataset.map(d => d['VALUES']).flat().filter(v => v['VALUE'] && rightColumns.includes(v['KEY'])).map(v => v['VALUE'])
      const rightValues = Math.max.apply(null, rightArr)

      const max = {
        left: leftArr.length === 0 ? 0 : leftValues,
        right: rightArr.length === 0 ? 0 : rightValues
      }

      max.left = max.left === 0 ? 100 : max.left * 1.5
      max.right = max.right === 0 ? 100 : max.right

      const axisLeftRange = d3.range(6).map(d => { const v = max.left / 5 * d; return String(v).indexOf('.') !== -1 ? v : Math.ceil(v) })
      const axisRightRange = d3.range(6).map(d => { const v = max.right / 5 * d; return String(v).indexOf('.') !== -1 ? v : Math.ceil(v) })

      const categoryNames = dataset.map(d => d.CATEGORY)

      // const duration = 0
      const bars = this.legend.filter(d => d.shape === 'rect').map(d => d['column'])

      y0.domain([ 0, max.left ])
      y1.domain([ 0, max.right ])

      x0.domain(categoryNames)

      x1.domain(dataset[0].VALUES.filter(d => bars.includes(d.KEY)).map(d => d.KEY))
        .rangeRound([ 0, x0.bandwidth() ]).padding(0.15)

      const xAxis = d3.axisBottom(x0) .tickFormat(d => d).tickValues(x0.domain())
      //xAxis의 데이터 WK값을 다보여줄것인지 작게 보여줄것인지


      const yAxis = {
        left: d3.axisLeft(y0).tickValues(axisLeftRange),
        right: d3.axisRight(y1).tickValues(axisRightRange)
      }
      // g.selectAll('g').remove()
      // g.selectAll('.bar').remove()
      // g.selectAll('.line').remove()
      // g.selectAll('.tick').remove()

      g.append('g')
        .attr('class', 'grid')
        .call(d3.axisLeft(y0).tickValues(axisLeftRange).tickSize(-width).tickFormat(''))
        .selectAll('.tick line')
        .attr('stroke', (d, k) => k > 0 ? '#efefef' : '#ccc')
        .attr('stroke-width', 1)
        .attr('shape-rendering', 'crispEdges')

      g.append('g')
        .style('font-size', '10px')
        .attr('class', 'x axis')
        .attr('transform', `translate(0,${height + 1})`)
        .call(xAxis)
        .selectAll('text')
        .attr('y', 0)
        .attr('x', -55)
        .attr('dy', '.35em')
        .attr('transform', 'rotate(-90)')
        .style('text-anchor', 'start')
        .style('fill','#424242')
        
      g.append('text')
        .attr('class', 'yaxis_left_text')
        .style('font-size', '10px')
        .attr('transform', `translate(-34,${height/2}),rotate(-90)`)
        .style('text-anchor', 'middle')
        .style('fill','#9E9E9E')
        .text('Weekly Progress')

        g.append('text')
        .attr('class', 'yaxis_right_text')
        .style('font-size', '10px')
        .attr('transform', `translate(${width+38},${height/2}),rotate(-90)`)
        .style('text-anchor', 'middle')
        .style('fill','#9E9E9E')
        .text('Cumulative Progress')

      g.append('g')
        .attr('class', 'y axis axis-left')
        .style('font-size', '10px')
        .call(yAxis.left)

      g.append('g')
        .attr('class', 'y axis axis-right')
        .style('font-size', '10px')
        .attr('transform', `translate(${width},0)`)
        .call(yAxis.right)


      g.selectAll('.domain').remove()
      g.selectAll('.y .tick').attr('color', '#878787')
      g.selectAll('.y .tick line').remove()
      g.selectAll('.x .tick line').remove()

      const barGroups = g.selectAll('.bar')
        .data(dataset)
        .enter()
        .append('g')
        .attr('class', 'bar')
        .attr('transform', d => `translate(${x0(d.CATEGORY)},0)`)

      barGroups.selectAll('rect')
        .data(d => d.VALUES.filter(v => bars.includes(v.KEY)))
        .enter()
        .append('rect')
        .attr('width', x1.bandwidth() < 1 ? 1 : x1.bandwidth())
        .attr('x', d => x1(d.KEY))
        .attr('y', d => y0(d.VALUE))
        .attr('height', d => height - y0(d.VALUE) < 0 ? 0 : isNaN(height - y0(d.VALUE)) ? 0 : height - y0(d.VALUE))
        .attr('fill', d => {
          const color = this.legend.find(v => v['column'] === d['KEY']).color
          return color
        })
        .style('opacity', 0)

      barGroups.selectAll('text')
        .data(d => d.VALUES.filter(v => bars.includes(v.KEY)))
        .enter()
        .append('text')
        .attr('x', d => x1(d.KEY))
        .attr('dx', x1.bandwidth() / 2)
        .attr('y', d => y0(d.VALUE))
        .attr('dy', '-.5em')
        .attr('fill', d => d3.rgb(this.legend.find(v => v['column'] === d.KEY).color).darker(1))
        .attr('text-anchor', 'middle')
        .attr('font-size', '10px')
        .text(d => d.VALUE > 0 ? d.VALUE : null)
        .style('opacity', 0)

      barGroups.selectAll('rect')
        .transition()
        .style('opacity', 1)

      barGroups.selectAll('text')
        .transition()
        .style('opacity', 0)

      const lines = this.legend.filter(d => d.shape === 'line').map(d => d['column']).filter(d => this.filter.includes(d))
      const lineLength = d3.line()
        .x(d => x0(d.KEY))
        .y(d => y1(d.VALUE))
        .defined(d => {
          return d.VALUE >= 0
          // d.VALUE && d.VALUE >= 0
        })
      const linedata = []
      lines.forEach(c => {
        const obj = {}
        obj.KEY = c
        obj.COLOR = this.legend.find(v => v['column'] === c).color
        obj.VALUES = dataset
          .map(d => {
            d.VALUES.find(v => {
              return v.KEY === c
            })
            
            return {
              KEY: d.CATEGORY,
              VALUE: d.VALUES.find(v => v.KEY === c).VALUE
            }
          })
        obj.LINESHAPE = this.legend.find(v => v['column'] === c).lineShape
        linedata.push(obj)
      })

      const lineGroups = g
        .append('g')
        .attr('class', 'line')
        .attr('transform', `translate(${x0.bandwidth() / 2},0)`)

      linedata.forEach(v => {
        const line = lineGroups
          .append('path')
          .datum(v)
          .attr('class', d => `line-${d.KEY}`)
          .attr('fill', 'none')
          .attr('stroke', d => d.COLOR)
          .attr('stroke-width', 1)
          .attr('shape-rendering', 'geometricPrecision')
          .attr('d', d => lineLength(d.VALUES))
        v.LENGTH = line.node().getTotalLength()
      })

      g.selectAll('.line path')
        .attr('stroke-dasharray', d => '')
        .attr('stroke-dashoffset', d => d.LENGTH)
        .transition()
        .attr('stroke-dashoffset', 0)
        .attr('stroke-dasharray', d => {
          const legend = this.legend.find(v => v['column'] === d['KEY'])
          if (legend) return legend.lineShape && legend.lineShape === 'dash' ? '4,3' : d.LENGTH + ' ' + d.LENGTH
          else return ''
        })
        .attr('d', d => lineLength(d.VALUES))

      g.selectAll('.line circle')
        .attr('opacity', 0)
        .transition()
        .attr('opacity', 1)
      g.selectAll('.line text')
        .attr('opacity', 0)
        .transition()
        .attr('opacity', 0)
    },
  }
}
