import * as d3 from 'd3'

export default {
  methods: {
    Export_Hull_MS(d,k) {

      if (d.stage == 'Division_Lv3' && d.mask == 'MS' ) {
  
        // setTimeout(() => {
        //   // Remove SVG
        //   this.removeSvg(this.CANVAS, 10)
        // },1000);

        // Hidden
        this.hiddenGroupID('#Menu_Back', 0, 0)
        this.hiddenGroupID('#Mask_Lev1', 0, 0)
        this.hiddenGroupID('#Mask_Lev2', 0, 0)
        this.hiddenGroupID('#Mask_Lev3', 0, 0)
        this.hiddenGroupID('#Mask_Lev4', 0, 0)

        setTimeout(() => {
        // Explode
        this.Hull_Mechinaery.forEach(v => {
          if(v.BLK == `${d.level}_EQ`) {
            d3.select(`#Blk_${d.level}_EQ`).transition().duration(1000).attr('transform', `translate(${v.x5}, ${v.y5}) scale(${1})`)
          } else {
            this.hiddenGroupID(`#Blk_${v.BLK}`, 0, 500)
          }
        })
      },3200);

      setTimeout(() => {
        this.removeSvg(d3.select('#Progress_Chart'), 10)
      }, 5000)

      setTimeout(() => {
      this.loadSvg(0, 0, `/TORTUE/Equipment/SVG/${d.level}.svg`).then((innerSvg) => {
        this.Draw_EQ_Code(innerSvg, d.level)
        this.Draw_EQ_Tag(innerSvg, d.level)
        this.Draw_EQ_Block(innerSvg, d.level)
        this.innerSvg = innerSvg
      })
        this.Lev4 = d.level
        this.stage = this.STG_Division_Lv3//this.STG_Layout
        this.fncName = 'Goto_MS_Level'
        this.StageCode() // AREA / STAGE
        this.displayGroupID(`#Menu_Back`, 0, 200, 1)
        this.drawProgress(d.level, false)
      }, 5100);

      }

    },

  }
}
           