export default {
  data: () => ({
    Coordinate: {
      tcolor: '#0D47A1',
      lcolor: '#0D47A1',
      Text: [
        { code: 'S11' ,x:300 ,y:117,},
        { code: 'S9'  ,x:590 ,y:130,},
        { code: 'S12' ,x:450 ,y:285,},
        { code: 'S3'  ,x:450 ,y:395,},
        { code: 'S4'  ,x:304 ,y:285,},
        { code: 'S2'  ,x:710 ,y:455,},
        { code: 'S5'  ,x:300 ,y:750,},
        { code: 'S1'  ,x:890 ,y:580,},
        { code: 'S13' ,x:890 ,y:980,},
        { code: 'S14' ,x:1130,y:994,},
        { code: 'S15' ,x:1335,y:975,},
        { code: 'S16' ,x:1183,y:1230,},
        { code: 'S17' ,x:1140,y:1380,},
        { code: 'S6'  ,x:130 ,y:520,},
        { code: 'S7'  ,x:142 ,y:805,},
        { code: 'S8'  ,x:1145,y:290,},
        { code: 'S10' ,x:1145,y:405,},
        { code: 'S18' ,x:1145,y:510,},
        { code: 'S19' ,x:1145,y:620,},
        { code: 'S20' ,x:1145,y:725,},
        { code: 'S21' ,x:1145,y:830,},
      ],
    },
    pathes: {
      QualityStation:{
        tcolor: '#FFA000',
        items: [
          {code: 'Area1', bcolor: '#FFF176',lcolor: '#FFF176', x:360,y:285,path: 'M 269 253 L 745 251 L 747 291 L 888 291 L 893 436 L 1078 436 L 1079 950 L 1428 950 L 1429 1200 L 1560 1200 L 1560 1461 L 1081 1461 L 1081 1341 L 983 1341 L 981 1200 L 844 1200 L 839 870 L 405 875 L 402 1258 L 352 1255 L 255 1098 L 265 457 L 255 417 L 256 285 L 269 251 Z'},
          {code: 'Area2', bcolor: '#FFF176',lcolor: '#FFF176', x:175,y:520,path: 'M 100 479 L 190 431 L 209 436 L 209 766 L 127 766 L 98 727 L 99 479 Z'},
          {code: 'Area3', bcolor: '#FFF176',lcolor: '#FFF176', x:630,y:130,path: 'M 564 106 L 570 102 L 653 107 L 656 113 L 656 244 L 562 243 L 564 104 Z'},
          {code: 'Area4', bcolor: '#FFF176',lcolor: '#FFF176', x:440,y:117 ,path: 'M 260 87 L 455 87 L 555 97 L 555 142 L 407 144 L 404 246 L 260 245 L 260 87 Z'},
        ]
      },
      SubconExecution:{
        tcolor: '#D50000',
        items: [
          { code: 'HZDJ'  , bcolor: '#29B6F6',lcolor: '#29B6F6',x:300 ,y:117,path: 'M 263 93 L 452 89 L 554 101 L 554 242 L 264 243 L 263 93 Z' },
          { code: 'HNEDI' , bcolor: '#29B6F6',lcolor: '#29B6F6',x:590 ,y:130,path: 'M 565 100 L 658 109 L 657 243 L 565 243 L 565 100 Z' },
          { code: 'SFCC'  , bcolor: '#29B6F6',lcolor: '#29B6F6',x:450 ,y:285,path: 'M 743 254 L 269 253 L 260 286 L 257 414 L 271 461 L 270 629 L 414 629 L 412 720 L 655 719 L 657 536 L 405 532 L 403 357 L 840 357 L 841 541 L 1077 546 L 1077 953 L 847 952 L 845 1191 L 988 1194 L 989 1243 L 1077 1243 L 1081 484 L 1120 484 L 1120 571 L 1247 571 L 1247 484 L 1080 484 L 1080 439 L 888 436 L 889 292 L 744 287 L 745 253 Z' },
          { code: 'SNEI'  , bcolor: '#29B6F6',lcolor: '#29B6F6',x:450 ,y:395,path: 'M 415 368 L 832 368 L 832 864 L 669 864 L 669 524 L 415 524 L 415 368' },
          // { code: 'SNEI'  , bcolor: '#29B6F6',lcolor: '#29B6F6',x:450 ,y:395,path: 'M 413 366 L 655 366 L 655 524 L 413 524 L 413 366 Z' },
          // { code: 'SNEI'  , bcolor: '#29B6F6',lcolor: '#29B6F6',x:710 ,y:395,path: 'M 668 366 L 833 367 L 833 867 L 668 867 L 668 368 Z' },
          { code: 'FCC'   , bcolor: '#29B6F6',lcolor: '#29B6F6',x:300 ,y:750,path: 'M 268 640 L 399 640 L 402 857 L 402 734 L 655 734 L 654 869 L 400 869 L 399 1252 L 357 1254 L 261 1096 L 262 843 L 268 817 L 268 640 Z' },
          { code: 'STCC'  , bcolor: '#29B6F6',lcolor: '#29B6F6',x:890 ,y:580,path: 'M 845 557 L 1076 557 L 1076 941 L 845 941 L 845 557 Z' },
          { code: 'CC7'   , bcolor: '#29B6F6',lcolor: '#29B6F6',x:1130,y:994, path: 'M 1088 954 L 1280 954 L 1280 1194 L 1088 1194 L 1088 954 Z' },
          { code: 'WDECE' , bcolor: '#29B6F6',lcolor: '#29B6F6',x:1335,y:995, path: 'M 1292 954 L 1428 954 L 1428 1194 L 1292 1194 L 1292 954 Z' },
          { code: 'SYPEC' , bcolor: '#29B6F6',lcolor: '#29B6F6',x:1183,y:1222,path: 'M 1088 1204 L 1560 1205 L 1560 1335 L 1136 1335 L 1136 1244 L 1088 1244 L 1088 1204 Z' },
          { code: 'CNCEC' , bcolor: '#29B6F6',lcolor: '#29B6F6',x:1140,y:1380,path: 'M 987 1257 L 1128 1257 L 1128 1346 L 1561 1348 L 1561 1461 L 1082 1461 L 1082 1340 L 987 1340 L 987 1257 Z' },
          { code: 'GDHJ'  , bcolor: '#29B6F6',lcolor: '#29B6F6',x:130 ,y:520,path: 'M 103 491 L 110 480 L 197 431 L 206 437 L 203 647 L 147 647 L 147 730 L 106 730 L 103 491 Z' },
          { code: 'ZGLY'  , bcolor: '#29B6F6',lcolor: '#29B6F6',x:142 ,y:805,path: 'M 158 661 L 203 661 L 203 758 L 159 758 L 158 659 Z' },
          { code: 'SPC'   , bcolor: '#29B6F6',lcolor: '#29B6F6',x:1145,y:620,path: 'M 1119 595 L 1246 595 L 1246 678 L 1119 678 L 1119 595 Z' },
          { code: 'SLT'   , bcolor: '#29B6F6',lcolor: '#29B6F6',x:1145,y:725,path: 'M 1119 700 L 1246 700 L 1246 787 L 1119 787 L 1119 700 Z' },
          { code: 'GDMY'  , bcolor: '#29B6F6',lcolor: '#29B6F6',x:1145,y:830,path: 'M 1119 806 L 1246 806 L 1246 895 L 1119 895 L 1119 806 Z' }
        ]
      },
      Subcontract: {
        tcolor: '#0D47A1',//red:#FFCDD2, #EF9A9A/blue:#B3E5FC,#81D4FA/yellow:#FFF9C4,#FFF59D
        items: [
          { code: 'S11' , bcolor: '#FFCDD2',lcolor: '#EF9A9A',x:300 ,y:117,path: 'M 263 93 L 452 89 L 554 101 L 554 242 L 264 243 L 263 93 Z' },
          { code: 'S9'  , bcolor: '#FFCDD2',lcolor: '#EF9A9A',x:590 ,y:130,path: 'M 565 100 L 658 109 L 657 243 L 565 243 L 565 100 Z' },
          { code: 'S12' , bcolor: '#FFCDD2',lcolor: '#EF9A9A',x:450 ,y:285,path: 'M 413 255 L 743 254 L 743 293 L 885 293 L 885 440 L 1078 440 L 1078 547 L 841 545 L 841 357 L 411 357 L 411 255 Z' },
          { code: 'S3'  , bcolor: '#FFECB3',lcolor: '#FFE082',x:450 ,y:395,path: 'M 413 366 L 655 366 L 655 524 L 413 524 L 413 366 Z' },
          { code: 'S4'  , bcolor: '#FFECB3',lcolor: '#FFE082',x:304 ,y:285,path: 'M 268 256 L 400 256 L 403 533 L 656 536 L 655 722 L 413 723 L 413 628 L 270 628 L 269 461 L 258 411 L 259 287 L 268 257 Z' },
          { code: 'S2'  , bcolor: '#B3E5FC',lcolor: '#81D4FA',x:710 ,y:395,path: 'M 668 366 L 833 367 L 833 867 L 668 867 L 668 368 Z' },
          { code: 'S5'  , bcolor: '#FFECB3',lcolor: '#FFE082',x:300 ,y:750,path: 'M 268 640 L 399 640 L 402 865 L 402 734 L 655 734 L 654 869 L 400 869 L 399 1252 L 357 1254 L 261 1096 L 262 843 L 268 817 L 268 640 Z' },
          { code: 'S1'  , bcolor: '#B3E5FC',lcolor: '#81D4FA',x:890 ,y:580,path: 'M 845 557 L 1076 557 L 1076 941 L 845 941 L 845 557 Z' },
          { code: 'S13' , bcolor: '#B3E5FC',lcolor: '#81D4FA',x:890 ,y:985,path: 'M 846 954 L 1076 954 L 1076 1244 L 988 1244 L 988 1194 L 845 1194 L 846 954 Z' },
          { code: 'S14' , bcolor: '#B3E5FC',lcolor: '#81D4FA',x:1130,y:994, path: 'M 1088 954 L 1280 954 L 1280 1194 L 1088 1194 L 1088 954 Z' },
          { code: 'S15' , bcolor: '#B3E5FC',lcolor: '#81D4FA',x:1335,y:995, path: 'M 1292 954 L 1428 954 L 1428 1194 L 1292 1194 L 1292 954 Z' },
          { code: 'S16' , bcolor: '#FFCDD2',lcolor: '#EF9A9A',x:1183,y:1222,path: 'M 1088 1204 L 1560 1205 L 1560 1335 L 1136 1335 L 1136 1244 L 1088 1244 L 1088 1204 Z' },
          { code: 'S17' , bcolor: '#FFCDD2',lcolor: '#EF9A9A',x:1140,y:1380,path: 'M 987 1257 L 1128 1257 L 1128 1346 L 1561 1348 L 1561 1461 L 1082 1461 L 1082 1340 L 987 1340 L 987 1257 Z' },
          { code: 'S6'  , bcolor: '#FFCDD2',lcolor: '#EF9A9A',x:130 ,y:520,path: 'M 103 491 L 110 480 L 197 431 L 206 437 L 203 647 L 147 647 L 147 730 L 106 730 L 103 491 Z' },
          { code: 'S7'  , bcolor: '#FFCDD2',lcolor: '#EF9A9A',x:142 ,y:805,path: 'M 158 661 L 203 661 L 203 758 L 159 758 L 158 659 Z' },
          { code: 'S8'  , bcolor: '#FFCDD2',lcolor: '#EF9A9A',x:1145,y:290,path: 'M 1119 267 L 1246 267 L 1246 356 L 1119 356 L 1119 267 Z' },
          { code: 'S10' , bcolor: '#FFCDD2',lcolor: '#EF9A9A',x:1145,y:405,path: 'M 1119 379 L 1246 379 L 1246 464 L 1119 464 L 1119 379 Z' },
          { code: 'S18' , bcolor: '#FFCDD2',lcolor: '#EF9A9A',x:1145,y:510,path: 'M 1119 483 L 1246 483 L 1246 571 L 1119 571 L 1119 483 Z' },
          { code: 'S19' , bcolor: '#FFCDD2',lcolor: '#EF9A9A',x:1145,y:620,path: 'M 1119 595 L 1246 595 L 1246 678 L 1119 678 L 1119 595 Z' },
          { code: 'S20' , bcolor: '#C8E6C9',lcolor: '#A5D6A7',x:1145,y:725,path: 'M 1119 700 L 1246 700 L 1246 787 L 1119 787 L 1119 700 Z' },
          { code: 'S21' , bcolor: '#C8E6C9',lcolor: '#A5D6A7',x:1145,y:830,path: 'M 1119 806 L 1246 806 L 1246 895 L 1119 895 L 1119 806 Z' }
        ]
      },
      Supervision:{
        tcolor: '#D50000',
        items: [
          {code: 'WP1', bcolor: '#E57373',lcolor: '#E57373', x: 885,y: 580,path: 'M 845 554 L 1074 554 L 1074 942 L 845 940 L 845 554 Z'},
          {code: 'WP2', bcolor: '#E57373',lcolor: '#E57373', x: 705,y: 395,path: 'M 667 366 L 832 366 L 832 865 L 667 865 L 667 367 Z'},
          {code: 'WP3', bcolor: '#E57373',lcolor: '#E57373', x: 450,y: 395,path: 'M 414 368 L 653 366 L 655 524 L 412 524 L 401 540 L 400 735 L 654 736 L 654 865 L 402 865 L 403 538 L 412 520 L 413 368 Z'},
          {code: 'WP4', bcolor: '#E57373',lcolor: '#E57373', x: 440,y: 567,path: 'M 272 257 L 402 255 L 401 534 L 657 534 L 660 549 L 1082 551 L 1082 597 L 1244 597 L 1244 676 L 1120 676 L 1120 597 L 1082 597 L 1080 550 L 659 550 L 659 725 L 400 727 L 399 1254 L 361 1253 L 260 1097 L 261 846 L 265 823 L 267 462 L 260 415 L 261 290 L 270 255 Z'},
          {code: 'WP5', bcolor: '#E57373',lcolor: '#E57373', x: 450,y: 285,path: 'M 413 257 L 740 257 L 743 297 L 882 297 L 887 442 L 1074 442 L 1074 544 L 842 544 L 839 354 L 413 354 L 413 257 Z'},
          // {code: 'WP6', bcolor: '#E57373',lcolor: '#E57373', x: 302,y: 117,path: 'M 266 93 L 455 93 L 551 102 L 551 138 L 400 138 L 400 242 L 265 242 L 235 254 L 235 530 L 203 532 L 203 760 L 129 760 L 104 724 L 104 482 L 191 439 L 203 441 L 203 532 L 235 530 L 235 254 L 265 242 L 266 93 Z'},//down
          {code: 'WP6', bcolor: '#E57373',lcolor: '#E57373', x: 302,y: 117,path: 'M 556 245 L 554 101 L 456 89 L 265 94 L 264 242 L 240 252 L 238 534 L 206 534 L 206 440 L 197 433 L 111 482 L 104 493 L 106 729 L 132 760 L 206 760 L 206 531 L 237 532 L 241 250 L 264 244 L 743 252 L 744 293 L 886 292 L 888 439 L 1083 438 L 1083 268 L 1247 268 L 1247 356 L 1120 356 L 1120 268 L 1084 268 L 1083 380 L 1246 380 L 1246 463 L 1120 463 L 1120 380 L 1083 380 L 1083 700 L 1247 700 L 1247 786 L 1120 786 L 1120 700 L 1083 700 L 1083 809 L 1246 809 L 1246 893 L 1120 893 L 1120 809 L 1083 809 L 1079 439 L 887 439 L 886 295 L 746 291 L 744 254 L 556 245 Z'},//down
          {code: 'WP7', bcolor: '#E57373',lcolor: '#E57373', x: 597,y: 175,path: 'M 566 101 L 660 109 L 659 246 L 744 253 L 748 289 L 886 292 L 891 432 L 1082 435 L 1082 485 L 1245 485 L 1245 569 L 1121 571 L 1121 486 L 1081 484 L 1081 437 L 886 434 L 889 297 L 748 287 L 743 253 L 562 246 L 563 97 Z'},
          {code: 'WP8', bcolor: '#E57373',lcolor: '#E57373', x: 890,y: 985,path: 'M 848 956 L 1422 956 L 1422 1189 L 1422 1205 L 1554 1205 L 1554 1456 L 1088 1456 L 1088 1335 L 988 1335 L 988 1204 L 1422 1204 L 1422 1195 L 850 1195 L 848 954 Z'}//down
        ]
      },
    },
    radioOptions: {
      values: [
        { colName: 'Subcontract'    , text: 'Bid Section'     , value: '', x: 25  ,textWidth: 107, on: false },
        { colName: 'SubconExecution', text: 'Subcontractor'   , value: '', x: 145 ,textWidth: 107, on: false },
        { colName: 'QualityStation' , text: 'Quality Station' , value: '', x: 265 ,textWidth: 107, on: false },
        { colName: 'Supervision'    , text: 'Supervision WP'  , value: '', x: 385 ,textWidth: 107, on: true },
        
      ]
    },
    styleFilter: {
      over: {
        stroke: '#bcbcbc',
        fill: '#fff',
        color: '#BDBDBD',
        //opacity:0.5
      },
      out: {
        stroke: '#bcbcbc',
        fill: '#fff',
        color: '#000',
        //opacity:0.5
      },
      selected: {
        stroke: '#424242',
        fill: '#757575',
        color: '#fff',
        opacity:1
      }
    },
    Legend: [
        {package:'P1',x:1350,y:40, color:'#B3E5FC', stroke:'#81D4FA',tcolor:'#424242'},
        {package:'P2',x:1400,y:40, color:'#FFECB3', stroke:'#FFE082',tcolor:'#424242'},
        {package:'P3',x:1450,y:40, color:'#FFCDD2', stroke:'#EF9A9A',tcolor:'#424242'},
    ],
    AreaText:[
      { STAGE: '1', AREA: 'FF & Gas Stn.', x: 430, y: 134, },
      { STAGE: '2', AREA: 'Main SS', x: 583, y: 233, },
      { STAGE: '3', AREA: '#1 WH', x: 321, y: 452, },
      { STAGE: '4', AREA: 'CWS 2', x: 595, y: 345, },
      { STAGE: '5', AREA: 'LDPE', x: 610, y: 515, },
      { STAGE: '6', AREA: 'PP', x: 625, y: 712, },
      { STAGE: '7', AREA: 'GPPE', x: 609, y: 857, },
      { STAGE: '8', AREA: 'Ethylene Cyro TF', x: 853, y: 1042, },
      { STAGE: '9', AREA: 'Presurised.TF', x: 853, y: 1113, },
      { STAGE: '10', AREA: 'Propylene TF', x: 853, y: 1186, },
      { STAGE: '11', AREA: 'Presurised.', x: 1006, y: 1170, },
      { STAGE: '11', AREA: 'TF II', x: 1006, y: 1187, },
      { STAGE: '12', AREA: 'Naphtha TF', x: 1102, y: 1080, },
      { STAGE: '13', AREA: 'Intermediate TFI', x: 1102, y: 1186, },
      { STAGE: '14', AREA: 'Mogas TF', x: 1302, y: 1033, },
      { STAGE: '15', AREA: 'Intermediate TF II', x: 1302, y: 1111, },
      { STAGE: '16', AREA: 'Intermediate TF III', x: 1302, y: 1187, },
      { STAGE: '17', AREA: 'WW PS #2', x: 994, y: 1304, },
      { STAGE: '18', AREA: 'WW & FW', x: 670, y: 345, },
      { STAGE: '19', AREA: 'Air Compressor', x: 762, y: 345, },
      { STAGE: '20', AREA: 'Demin Water', x: 730, y: 426, },
      { STAGE: '21', AREA: 'Boiler', x: 771, y: 537, },
      { STAGE: '22', AREA: 'CWS 1', x: 1027, y: 512, },
      { STAGE: '23', AREA: 'Olefins', x: 1018, y:  930, },
      { STAGE: '24', AREA: 'WW PS #1', x: 1002, y: 1006, },
      { STAGE: '25', AREA: 'Ground Flare & Elevated Flare', x: 1348, y: 1325, },
      { STAGE: '26', AREA: 'L/O Station, WWT, SW Pump Station', x: 1306, y: 1449, },
      { STAGE: '27', AREA: 'GHU', x: 781, y: 675, },
      { STAGE: '28', AREA: 'BTX', x: 784, y: 619, },
      { STAGE: '29', AREA: 'BD', x: 798, y: 795, },
      { STAGE: '30', AREA: 'DIB', x: 789, y: 857, },
      { STAGE: '31', AREA: '#2 WH', x: 333, y: 1083, },
      { STAGE: '32', AREA: '#1 Pkg Plant', x: 298, y: 604, },
      { STAGE: '33', AREA: 'Admin B+', x: 127, y: 633, },
      { STAGE: '34', AREA: '#2 Pkg Plant', x: 297, y: 823, },
      { STAGE: '35', AREA: 'Chemical Whse.& Maint. Workshop', x: 303, y: 230, },
    ],
    Description:[
      {DESC:'Other construction and installation works :',x: -150, y: 230,deco:'underline'},
      {DESC:'High Quality Decoration of Buildings',x: 0, y: 280,},
      {DESC:'Factory Greening',x: 0, y: 395,},
      {DESC:'Overall interconnecting pipe(A.G) 1',x: 0, y: 500,},
      {DESC:'Overall interconnecting pipe(A.G) 2,',x: 0, y: 605,},
      {DESC:'Eastward pipeline',x: 0, y: 625,},
      {DESC:'Heavy Lifting',x: 0, y: 710,},
      {DESC:'CO-Site Temporary Construction Facilities',x: 0, y: 820,},
      {DESC:'(TCF) extension, including office building',x: 0, y: 840,},
      {DESC:'and membrane structure corridor',x: 0, y: 860,},
    ]
  }),
}