import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {

  methods: {
    Activate_Overall() {

      this.Activate_OverallnMasks()

      // d3.select(`#Area_AFT`).transition().duration(500).style('visibility', 'visible').style('opacity', 1)
      // d3.select(`#Area_FOR`).transition().duration(500).style('visibility', 'visible').style('opacity', 1)
      d3.select(`#Topside_Group`).transition().duration(500).style('visibility', 'visible').style('opacity', 1)
      // d3.select(`#Area_LQ`).transition().duration(500).style('visibility', 'visible').style('opacity', 1)
      // d3.select(`#Area_VS`).transition().duration(500).style('visibility', 'visible').style('opacity', 1)
      d3.select(`#Title`).text('Topsides')
      d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Keyplan/TOPSIDE.png`)
      this.displayGroupID(`#Mask_TS_Common`, 10, 10, 0)
      this.displayGroupID(`#Mask_TS_Modules`, 10, 10, 0)
      this.displayGroupID(`#Legends`, 10, 10, 1)
      // ### Call Canvas ###
      this.Lev1 = 'TOPSIDE'
      this.stage = this.STG_Division_Lv2
      let dStage = {
        stage: this.STG_Division_Lv2,
         mask : 'OVERALL',
        level: ''
      }
      this.selectedItems.push(dStage)
      this.fncName = 'Goto_TS_Division_Lv2'
      this.StageCode() // AREA / STAGE

      // ### Call Canvas ###
      this.Canvas_Topside_Division_Lv2()
    },


    Activate_OverallnMasks() {

      this.Dashboard_Overall = this.svg.append('svg')

      this.Dashboard_Overall
      .attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight)
      .style('opacity', 1)
      .attr('x', 0).attr('y', 0)

      // this.Dashboard_Overall
      // .append('image')
      // .attr('id', 'Area_AFT')
      // .attr('transform', `translate(418, 517) scale(0.75)`)
      // .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Area/HULL_AFT.png`)
      // .style('visibility', 'hidden').style('opacity', 0)

      // this.Dashboard_Overall
      // .append('image')
      // .attr('id', 'Area_FOR')
      // .attr('transform', `translate(727, 502) scale(0.75)`)
      // .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Area/HULL_FOR.png`)
      // .style('visibility', 'hidden').style('opacity', 0)

      
      let Topside_Group = this.Dashboard_Overall
      .append('g')
      .attr('id', 'Topside_Group')
      .attr('transform', `translate(0,0) scale(1)`)
      .attr('opacity', 0).style('visibility', 'hidden')
        
        Topside_Group
        .append('image')
        .attr('id', 'FL01')
        .attr('transform', `translate(451, 384) scale(0.75)`).style('visibility', 'visible').style('opacity', 1)
        .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Area/FL01.png`)
        
        Topside_Group
        .append('image')
        .attr('id', 'MW01')
        .attr('transform', `translate(783, 221) scale(0.75)`).style('visibility', 'visible').style('opacity', 1)
        .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Area/MW01.png`)
      
        Topside_Group
        .append('image')
        .attr('id', 'MW02')
        .attr('transform', `translate(870, 232) scale(0.75)`).style('visibility', 'visible').style('opacity', 1)
        .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Area/MW02.png`)

        Topside_Group
        .append('image')
        .attr('id', 'MW04')
        .attr('transform', `translate(1076, 244) scale(0.75)`).style('visibility', 'visible').style('opacity', 1)
        .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Area/MW04.png`)

        Topside_Group
        .append('image')
        .attr('id', 'MW05')
        .attr('transform', `translate(1133, 284) scale(0.75)`).style('visibility', 'visible').style('opacity', 1)
        .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Area/MW05.png`)


        Topside_Group
        .append('image')
        .attr('id', 'TSRC01')
        .attr('transform', `translate(494, 676) scale(0.75)`).style('visibility', 'visible').style('opacity', 1)
        .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Area/TSRC01.png`)

        Topside_Group
        .append('image')
        .attr('id', 'TSRC02')
        .attr('transform', `translate(591, 694) scale(0.75)`).style('visibility', 'visible').style('opacity', 1)
        .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Area/TSRC02.png`)

        Topside_Group
        .append('image')
        .attr('id', 'TSRC03')
        .attr('transform', `translate(680, 727) scale(0.75)`).style('visibility', 'visible').style('opacity', 1)
        .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Area/TSRC03.png`)

        Topside_Group
        .append('image')
        .attr('id', 'TSRC04')
        .attr('transform', `translate(780, 744) scale(0.75)`).style('visibility', 'visible').style('opacity', 1)
        .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Area/TSRC04.png`)

        Topside_Group
        .append('image')
        .attr('id', 'TSRC05')
        .attr('transform', `translate(857, 759) scale(0.75)`).style('visibility', 'visible').style('opacity', 1)
        .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Area/TSRC05.png`)

        Topside_Group
        .append('image')
        .attr('id', 'KO01')
        .attr('transform', `translate(384, 670) scale(0.75)`).style('visibility', 'visible').style('opacity', 1)
        .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Area/KO01.png`)

        Topside_Group
        .append('image')
        .attr('id', 'ME01')
        .attr('transform', `translate(744, 265) scale(0.75)`).style('visibility', 'visible').style('opacity', 1)
        .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Area/ME01.png`)

        Topside_Group
        .append('image')
        .attr('id', 'ME02')
        .attr('transform', `translate(828, 272) scale(0.75)`).style('visibility', 'visible').style('opacity', 1)
        .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Area/ME02.png`)

        Topside_Group
        .append('image')
        .attr('id', 'ME04')
        .attr('transform', `translate(1037, 271) scale(0.75)`).style('visibility', 'visible').style('opacity', 1)
        .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Area/ME04.png`)

        Topside_Group
        .append('image')
        .attr('id', 'ME05')
        .attr('transform', `translate(1095, 322) scale(0.75)`).style('visibility', 'visible').style('opacity', 1)
        .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Area/ME05.png`)
        
        Topside_Group
        .append('image')
        .attr('id', 'LD00DA')
        .attr('transform', `translate(897, 779) scale(0.75)`).style('visibility', 'visible').style('opacity', 1)
        .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Area/LD00DA.png`)





      // this.Dashboard_Overall
      // .append('image')
      // .attr('id', 'Area_LQ')
      // .attr('transform', `translate(983, 512) scale(0.75)`)
      // .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Area/HULL_LQ.png`)
      // .style('visibility', 'hidden').style('opacity', 0)

      // this.Dashboard_Overall
      // .append('image')
      // .attr('id', 'Area_VS')
      // .attr('transform', `translate(926, 583) scale(0.75)`)
      // .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Area/HULL_VS.png`)
      // .style('visibility', 'hidden').style('opacity', 0)





      // // Goto_Division_Lv1
      // this.Dashboard_Overall
      // .append('polygon')
      // .attr('id', 'Mask_Overall')
      // .attr('transform', `translate(414, 225) scale(1)`)
      // .attr('points', `0,346 0,395 34,444 626.5,563.5 748.5,474.5 748.5,422.5 770.5,404.5 770.5,385.5 713.5,374.5 713.5,335.5 655.5,335.5 674.5,297.5 660.5,269.5 618.5,281.5 602.5,324.5 514.5,298.5 513.5,269.5 477.5,264.5 467.5,292.5 435.5,291.5 427.5,344.5 343.5,328.5 345.5,276.5 269.5,244.5 209.5,240.5 163.5,266.5 150.5,0.5 127.5,0.5 113.5,11.5 92.5,299.5 70.5,271.5 30.5,292.5 26.5,317.5`)
      // .attr('fill', '#fff').style('opacity', 0).style('visibility', 'hidden').style('cursor', 'pointer')
      // .call(this.Goto_Division_Lv1) // <--------



      // // Goto_Division_Lv2
      // this.Dashboard_Overall
      // .append('polygon')
      // .attr('id', 'Mask_Topside')
      // .attr('transform', `translate(433, 120) scale(1)`)
      // .attr('points', `0,355 70,378 267,414 362,417 430,458 570,473 649,419 649,385 602,389 602,347 581,335 580,319 554,319 514,306 502,324 492,268 458,265 450,293 417,292 411,345 329,331 325,277 267,249 191,241 142,270 131,0 107,0 93,11 71,295 64,280 52,270 16,286`)
      // .attr('fill', '#fff').style('opacity', 0).style('visibility', 'hidden').style('cursor', 'pointer')
      // .call(s => { this.Goto_TS_Division_Lv2(s, 'Mask_Topside') }) // <--------

      // this.Dashboard_Overall
      // .append('polygon')
      // .attr('id', 'Mask_Hull')
      // .attr('transform', `translate(412, 648) scale(1)`)
      // .attr('points', `0,69 0,123 32,170 633,292 752,202 752,150 772,127 763,107 722,102 714,62 656,62 656,36 674,36 674,10 660,0 616,11 594,120 576,116 569,79 506,103 317,61 315,43 193,19 181,40 119,27 104,15 66,24`)
      // .attr('fill', '#fff').style('opacity', 0).style('visibility', 'hidden').style('cursor', 'pointer')
      // .call(s => { this.Goto_Hull_Division_Lv2(s, 'Mask_Hull') }) // <--------



      // // Goto_Hull_Activate
      // this.Dashboard_Overall
      // .append('polygon')
      // .attr('id', 'Mask_Hull_AFT')
      // .attr('transform', `translate(359,473) scale(1)`)
      // .attr('points', `0,60 0,106 38,158 317,213 393,160 400,121 400,79 394,60 311,45 311,26 192,4 184,10 181,31 162,29 162,21 112,12 104,0 72,10 20,36`)
      // .attr('fill', '#fff').style('opacity', 0).style('visibility', 'hidden').style('cursor', 'pointer')
      // .call(s => { this.Goto_Hull_Activate(s, 'Mask_Hull_AFT', 'AFT') }) // <--------

      // this.Dashboard_Overall
      // .append('polygon')
      // .attr('id', 'Mask_Hull_FOR')
      // .attr('transform', `translate(839,492) scale(1)`)
      // .attr('points', `0,139 0,224 318,288 435,202 435,150 322,133 322,64 342,36 360,31 366,7 336,0 298,37 302,124 262,118 262,89 252,80 218,90 206,105 83,80 36,102`)
      // .attr('fill', '#fff').style('opacity', 0).style('visibility', 'hidden').style('cursor', 'pointer')
      // .call(s => { this.Goto_Hull_Activate(s, 'Mask_Hull_FOR', 'FOR') }) // <--------

      // this.Dashboard_Overall
      // .append('polygon')
      // .attr('id', 'Mask_Hull_LQ')
      // .attr('transform', `translate(1103,292) scale(1)`)
      // .attr('points', `0,207 22,216 56,189 77,200 101,200 104,186 149,166 176,150 183,134 193,130 202,112 192,97 165,90 149,90 149,56 122,48 90,54 83,0 47,2 47,80 32,93 32,135 23,139 5,180`)
      // .attr('fill', '#fff').style('opacity', 0).style('visibility', 'hidden').style('cursor', 'pointer')
      // .call(s => { this.Goto_Hull_Activate(s, 'Mask_Hull_LQ', 'LQ') }) // <--------


      


      // Mask_TS_Common
      this.Dashboard_Overall
      .append('polygon')
      .attr('id', 'Mask_TS_Common')
      .attr('transform', `translate(371, 385) scale(1)`)
      .attr('points', `0,362 107,396 142,356 526,432 512,458 583,484 660,434 660,395 623,383 581,390 155,279 136.5,0.5 102.5,0.5 74.5,271.5 18.5,292.5`)
      .attr('fill', '#fff').style('opacity', 0).style('visibility', 'hidden').style('cursor', 'pointer')
      .call(s => { this.Goto_TS_Division_Lv3(s, 'Mask_TS_Common', 'COMMON') }) // <--------

        this.Dashboard_Overall
        .append('polygon')
        .attr('id', 'Mask_KO01')
        .attr('transform', `translate(255, 624) scale(1)`).attr('points', `9,21 0,82.6 91.1,113 134.8,80.6 160,49.1 149.2,28.4 69.6,20.1 59.2,0 42.9,0`)
        .attr('fill', '#fff').style('opacity', 0).style('visibility', 'hidden').style('cursor', 'pointer')
        .call(s => { this.Goto_TS_Activate(s, 'Mask_KO01', 'KO01') }) // <--------

        this.Dashboard_Overall
        .append('polygon')
        .attr('id', 'Mask_FL01')
        .attr('transform', `translate(410, 265) scale(1)`).attr('points', `40,0 25,19 33,43 0,322 64,353 109,326 104,281 79,278 69,4`)
        .attr('fill', '#fff').style('opacity', 0).style('visibility', 'hidden').style('cursor', 'pointer')
        .call(s => { this.Goto_TS_Activate(s, 'Mask_FL01', 'FL01') }) // <--------

        this.Dashboard_Overall
        .append('polygon')
        .attr('id', 'Mask_TSRC01')
        .attr('transform', `translate(571, 573) scale(1)`).attr('points', `9,20 0,82 91,113 133,82 160,49 149,28 70,20 59,0 43,0`)
        .attr('fill', '#fff').style('opacity', 0).style('visibility', 'hidden').style('cursor', 'pointer')
        .call(s => { this.Goto_TS_Activate(s, 'Mask_TSRC01', 'RC01') }) // <--------

        this.Dashboard_Overall
        .append('polygon')
        .attr('id', 'Mask_TSRC02')
        .attr('transform', `translate(743, 608) scale(1)`).attr('points', `0,12 0,58 94,80 109,69 109,19 12,0`)
        .attr('fill', '#fff').style('opacity', 0).style('visibility', 'hidden').style('cursor', 'pointer')
        .call(s => { this.Goto_TS_Activate(s, 'Mask_TSRC02', 'RC02') }) // <--------

        this.Dashboard_Overall
        .append('polygon')
        .attr('id', 'Mask_TSRC03')
        .attr('transform', `translate(902, 654) scale(1)`).attr('points', `0,13 0,45 104,68 118,56 118,23 8,0`)
        .attr('fill', '#fff').style('opacity', 0).style('visibility', 'hidden').style('cursor', 'pointer')
        .call(s => { this.Goto_TS_Activate(s, 'Mask_TSRC03', 'RC03') }) // <--------

        this.Dashboard_Overall
        .append('polygon')
        .attr('id', 'Mask_TSRC04')
        .attr('transform', `translate(1077, 687) scale(1)`).attr('points', `0,12 0,47 84,65 98,55 98,17 12,0`)
        .attr('fill', '#fff').style('opacity', 0).style('visibility', 'hidden').style('cursor', 'pointer')
        .call(s => { this.Goto_TS_Activate(s, 'Mask_TSRC04', 'RC04') }) // <--------

        this.Dashboard_Overall
        .append('polygon')
        .attr('id', 'Mask_TSRC05')
        .attr('transform', `translate(1230, 717) scale(1)`).attr('points', `0,8 0,48 78,66 90,56 90,16 11,0`)
        .attr('fill', '#fff').style('opacity', 0).style('visibility', 'hidden').style('cursor', 'pointer')
        .call(s => { this.Goto_TS_Activate(s, 'Mask_TSRC05', 'RC05') }) // <--------

        this.Dashboard_Overall
        .append('polygon')
        .attr('id', 'Mask_LD00DA')
        .attr('transform', `translate(1288, 584) scale(1)`).attr('points', `0,57 0,76.8 58,93 138,35.7 138,8.6 96.7,0 24.9,35.4`)
        .attr('fill', '#fff').style('opacity', 0).style('visibility', 'hidden').style('cursor', 'pointer')
        .call(s => { this.Goto_TS_Activate(s, 'Mask_LD00DA', 'LD00DA') }) // <--------



      // Mask_TS_Modules
      this.Dashboard_Overall
      .append('polygon')
      .attr('id', 'Mask_TS_Modules')
      .attr('transform', `translate(734, 213) scale(1)`)
      .attr('points', `419,57 408,25 373,25 358,47 326,49 317.01,109.5 255.09,109.5 224,24 131,0 99,0 13,67 0,124 13,139 187,177 256,112 255.45,110.5 316.86,110.5 315,123 296,177 335,219 360,224 375,207 434,218 524,170 524,107`)
      .attr('fill', '#fff').style('opacity', 0).style('visibility', 'hidden').style('cursor', 'pointer')
      .call(s => { this.Goto_TS_Division_Lv3(s, 'Mask_TS_Modules', 'MODULES') }) // <--------

        this.Dashboard_Overall
        .append('polygon')
        .attr('id', 'Mask_MW01')
        .attr('transform', `translate(576, 350) scale(1)`).attr('points', `4,37 0,99 4,108 91,125 127,99 127,41 102,4 56,0`)
        .attr('fill', '#fff').style('opacity', 0).style('visibility', 'hidden').style('cursor', 'pointer')
        .call(s => { this.Goto_TS_Activate(s, 'Mask_MW01', 'MW01') }) // <--------

        this.Dashboard_Overall
        .append('polygon')
        .attr('id', 'Mask_MW02')
        .attr('transform', `translate(790, 387) scale(1)`).attr('points', `0,31 0,110 46,138 87,130 129,98 105,22 69,4 27,0`)
        .attr('fill', '#fff').style('opacity', 0).style('visibility', 'hidden').style('cursor', 'pointer')
        .call(s => { this.Goto_TS_Activate(s, 'Mask_MW02', 'MW02') }) // <--------

        this.Dashboard_Overall
        .append('polygon')
        .attr('id', 'Mask_MW04')
        .attr('transform', `translate(1064, 415) scale(1)`).attr('points', `4,99 0,127 5,137 67,151 97,131 97,82 78,65 72,4 31,0 18,84`)
        .attr('fill', '#fff').style('opacity', 0).style('visibility', 'hidden').style('cursor', 'pointer')
        .call(s => { this.Goto_TS_Activate(s, 'Mask_MW04', 'MW04') }) // <--------

        this.Dashboard_Overall
        .append('polygon')
        .attr('id', 'Mask_MW05')
        .attr('transform', `translate(1267, 485) scale(1)`).attr('points', `0,43 0,104 84,128 133,96 124,40 89,14 33,0 29,23`)
        .attr('fill', '#fff').style('opacity', 0).style('visibility', 'hidden').style('cursor', 'pointer')
        .call(s => { this.Goto_TS_Activate(s, 'Mask_MW05', 'MW05') }) // <--------

        this.Dashboard_Overall
        .append('polygon')
        .attr('id', 'Mask_ME01')
        .attr('transform', `translate(281, 574) scale(1)`).attr('points', `0,77 6,89 93,109 126,89 126,11 37,0 20,16`)
        .attr('fill', '#fff').style('opacity', 0).style('visibility', 'hidden').style('cursor', 'pointer')
        .call(s => { this.Goto_TS_Activate(s, 'Mask_ME01', 'ME01') }) // <--------

        this.Dashboard_Overall
        .append('polygon')
        .attr('id', 'Mask_ME02')
        .attr('transform', `translate(493, 608) scale(1)`).attr('points', `0,19 0,97 92,124 131,91 124,37 77,6 26,0`)
        .attr('fill', '#fff').style('opacity', 0).style('visibility', 'hidden').style('cursor', 'pointer')
        .call(s => { this.Goto_TS_Activate(s, 'Mask_ME02', 'ME02') }) // <--------

        this.Dashboard_Overall
        .append('polygon')
        .attr('id', 'Mask_ME04')
        .attr('transform', `translate(766, 623) scale(1)`).attr('points', `31,0 24,81 3,96 0,131 49,166 64,166 99,131 99,91 79,47 79,0`)
        .attr('fill', '#fff').style('opacity', 0).style('visibility', 'hidden').style('cursor', 'pointer')
        .call(s => { this.Goto_TS_Activate(s, 'Mask_ME04', 'ME04') }) // <--------

        this.Dashboard_Overall
        .append('polygon')
        .attr('id', 'Mask_ME05')
        .attr('transform', `translate(974, 699) scale(1)`).attr('points', `11,29 11,73 0,85 0,98 77,119 112,95 112,29 92,29 92,11 37,0`)
        .attr('fill', '#fff').style('opacity', 0).style('visibility', 'hidden').style('cursor', 'pointer')
        .call(s => { this.Goto_TS_Activate(s, 'Mask_ME05', 'ME05') }) // <--------
        



    },


  }
}