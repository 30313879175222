import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {

    
    Draw_Charts() {
      
      let data = null


      let regulatory = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0.5)`)


      let drawX = [0, 120, 220, 320, 420]
      let drawY = 0

      let table = regulatory
      .append('g')
      .attr('transform', `translate(0, 30)`)
      
      // Line for Header
      table.append('line')
      .attr('x1', 0).attr('x2', 600).attr('y1', 0).attr('y2', 0).attr('stroke-width', 2).attr('stroke', '#F4F5F5')

      table.append('line')
      .attr('x1', 0).attr('x2', 600).attr('y1', 40).attr('y2', 40).attr('stroke-width', 2).attr('stroke', '#F4F5F5')

      // Header
      let style = {
        y: drawY,
        strokeWidth: 2,
        textAlign: 'middle',
        fontSize: 10,
        height: 40,
        stroke: '#F4F5F5',
        verticalAlign: 'middle',
        lineHeignt: 12,
      }

      style.x = drawX[0]
      style.text = `System`
      style.width = 120
      this.multiLineText(table, style)

      style.x = drawX[1]
      style.text = `Total Number of Testpacks Approved`
      style.width = 100
      this.multiLineText(table, style)

      style.x = drawX[2]
      style.text = `Total Hydrotest Withnessed / Approved`
      style.width = 100
      this.multiLineText(table, style)

      style.x = drawX[3]
      style.text = `Remaining`
      style.width = 100
      this.multiLineText(table, style)

      style.x = drawX[4]
      style.text = `Progress`
      style.width = 180
      this.multiLineText(table, style)

      // Data Cells
      drawY = 56
      let sysTotal = 0, sysActual = 0

      this.dataSet.forEach(d=> {
        d.REMAIN = d.TOTAL - d.ACTUAL
        d.PROG = (d.ACTUAL / d.TOTAL) * 100
        
        table.append('text')
        .attr('transform', `translate(10, ${drawY})`)
        .style('font-size', 10).style('font-family', 'roboto').attr('fill', '#757575').style('text-anchor', 'start').text(d.SYSTEM)

        table.append('text')
        .attr('transform', `translate(${drawX[1]+50}, ${drawY})`)
        .style('font-size', 10).style('font-family', 'roboto').attr('fill', '#757575').style('text-anchor', 'middle').text(d.TOTAL)

        table.append('text')
        .attr('transform', `translate(${drawX[2]+50}, ${drawY})`)
        .style('font-size', 10).style('font-family', 'roboto').attr('fill', '#757575').style('text-anchor', 'middle').text(d.ACTUAL)

        table.append('text')
        .attr('transform', `translate(${drawX[3]+50}, ${drawY})`)
        .style('font-size', 10).style('font-family', 'roboto').attr('fill', '#757575').style('text-anchor', 'middle').text(d.REMAIN)

        

        table
        .append('rect')
        .attr('transform', `translate(${drawX[4]+3}, ${drawY-10})`)
        .attr('width', 135).attr('height', 13).attr('fill', '#F4F5F5').attr('stroke', 0)

        table
        .append('rect')
        .attr('transform', `translate(${drawX[4]+3}, ${drawY-10})`)
        .attr('width', (d.PROG/100)*135).attr('height', 13).attr('fill', '#83D2F5').attr('stroke', 0)

        table.append('text')
        .attr('transform', `translate(${drawX[4]+5+((d.PROG/100)*135)}, ${drawY})`)
        .style('font-size', 10).style('font-family', 'roboto').attr('fill', '#757575').style('text-anchor', 'start').text(`${d.PROG.toFixed(1)}%`)




        table.append('line')
        .attr('x1', 0).attr('x2', 600).attr('y1', drawY + 9).attr('y2', drawY + 9).attr('stroke-width', 1.5).attr('stroke', '#F4F5F5')

        sysTotal += d.TOTAL
        sysActual += d.ACTUAL
        drawY += 26
      })

      table.append('line')
      .attr('x1', drawX[1]).attr('x2', drawX[1]).attr('y1', 30).attr('y2', 170).attr('stroke-width', 1.5).attr('stroke', '#F4F5F5')
      table.append('line')
      .attr('x1', drawX[2]).attr('x2', drawX[2]).attr('y1', 30).attr('y2', 170).attr('stroke-width', 1.5).attr('stroke', '#F4F5F5')
      table.append('line')
      .attr('x1', drawX[3]).attr('x2', drawX[3]).attr('y1', 30).attr('y2', 170).attr('stroke-width', 1.5).attr('stroke', '#F4F5F5')
      table.append('line')
      .attr('x1', drawX[4]).attr('x2', drawX[4]).attr('y1', 30).attr('y2', 170).attr('stroke-width', 1.5).attr('stroke', '#F4F5F5')

      table.append('text')
      .attr('transform', `translate(50, ${drawY})`)
      .style('font-size', 10).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'start').text('TOTAL')

      table.append('text')
      .attr('transform', `translate(${drawX[1]+50}, ${drawY})`)
      .style('font-size', 10).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'middle').text(sysTotal)

      table.append('text')
      .attr('transform', `translate(${drawX[2]+50}, ${drawY})`)
      .style('font-size', 10).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'middle').text(sysActual)

      table.append('text')
      .attr('transform', `translate(${drawX[3]+50}, ${drawY})`)
      .style('font-size', 10).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'middle').text(sysTotal-sysActual)

      table
      .append('rect')
      .attr('transform', `translate(${drawX[4]+3}, ${drawY-10})`)
      .attr('width', 135).attr('height', 13).attr('fill', '#F4F5F5').attr('stroke', 0)

      table
      .append('rect')
      .attr('transform', `translate(${drawX[4]+3}, ${drawY-10})`)
      .attr('width', ((sysActual/sysTotal))*135).attr('height', 13).attr('fill', '#83D2F5').attr('stroke', 0)

      table.append('text')
      .attr('transform', `translate(${drawX[4]+5+(((sysActual/sysTotal))*135)}, ${drawY})`)
      .style('font-size', 10).style('font-family', 'roboto').attr('fill', '#757575').style('text-anchor', 'start').text(`${(sysActual/sysTotal*100).toFixed(1)}%`)

    },
  }
}
           