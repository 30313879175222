import * as d3 from 'd3'

export default {
  methods: {
    
    draw_ListboxHeader() {

      if(this.stage_Box) this.stage_Box.remove()
      this.stage_Box = this.innerSvg

      .append('g')
      .attr('id', 'menubox_group')
      .attr('transform', `translate(10, 210)`)

      this.stage_Box.append("rect")
      .attr('x', 0).attr('y', 0)
      .attr('width', 280).attr('height', 30)
      .attr('rx', 2).attr('ry', 2)
      .style('filter', 'url(#dropshadow)')

      this.stage_Box.append("rect")
      .attr('x', 0).attr('y', 0)
      .attr('width', 280).attr('height', 30)
      .attr('rx', 2).attr('ry', 2)
      .attr('fill','url(#menu_box)')

      this.stage_Box.append("text")
      .attr('id', 'list_title')
      .attr('x', 10).attr('y', 17)
      .attr('font-size',11).attr('fill','#000')
      .text('PACKAGE LIST - P1')

      this.stage_Box.append("rect")
      .attr('id', 'list_box_filter')
      .attr('x', 0).attr('y', 30)
      .attr('width', 280).attr('height', 5)
      .style('filter', 'url(#dropshadow)')

      this.stage_Box.append("rect")
      .attr('id', 'list_box_background')
      .attr('x', 0).attr('y', 30)
      .attr('width', 280).attr('height', 5)
      .attr('fill','#FBFBFB')
    },

    draw_ListboxDetail() {
      this.stage_List = this.innerSvg
      let y_ = 240
      let gap = 30

      this.Legend.filter(fa => fa.CHECKED == true).forEach(fa => {
 
        this.Queries.SQL1.filter(fb => fb.PACKAGE == fa.CODE).forEach((d,i) => {
          d.LINEY = y_ + gap
          d.LISTY = y_ + 1

          this.stage_List
          .append("line")
          .attr('id', `Line${d.NO}`)
          .attr('x1', 10).attr('y1', 215 + gap)
          .attr('x2', 290).attr('y2', 215 + gap)
          .attr('stroke', '#bcbcbc').attr('stroke-width', 0.5).attr('opacity', 0)

          this.stage_List
          .append('g')
          .attr('id', `LIST_${d.NO}`)
          .attr('transform', `translate(10, ${215 + 1})`)
          .attr('opacity', 0).style('cursor', 'pointer')
          // When a menu in a menu box is clicked
          .on('click', () => {
            this.goto_ImformationPage(d)
          })
          .call(this.call_AreaList, d.CODE, d.x, d.y) // ---> Event.js

          d3.select(`#LIST_${d.NO}`)
          .append("rect")
          .attr('x', 0).attr('y', 0).attr('width', 280).attr('height', gap-2).attr('fill', '#fff')

          // Shadow effect to a menu box
          d3.select(`#LIST_${d.NO}`)
          .append("rect")
          .attr('x', 0).attr('y', 0).attr('opacity', 0.7)
          .attr('width', 7).attr('height', gap-2).attr('fill',`url(#PACKAGE_${fa.CODE})`)

          d3.select(`#LIST_${d.NO}`)
          .append("text")
          .attr('class', `AREATEXT_${d.NO}`)
          .attr('x', 13).attr('y', (gap/2) + 3).attr('font-size',11).attr('fill','#000')
          .text(this.Toggle.status == 'EN'? d.NAME:d.CNAME)

          y_ += gap
        })
        // Animation effects for commissioning menu header and list
        this.Queries.SQL1.filter(fb => fb.PACKAGE == fa.CODE).forEach((d,i) => {

          d3.select(`#Line${d.NO}`)
          .transition().duration(300+(i*15))
          .attr('x1', 10).attr('y1', d.LINEY)
          .attr('x2', 290).attr('y2', d.LINEY)
          .attr('opacity', 1)

          d3.select(`#LIST_${d.NO}`)
          .transition().duration(300+(i*15))
          .attr('transform', `translate(10, ${d.LISTY})`)
          .attr('opacity', 1)
        })
        let no_ = this.Queries.SQL1.filter(fb => fb.PACKAGE == fa.CODE).length
        this.svg.select('#list_box_filter').transition().duration(300)
        .attr('height', (no_ * gap ))
        this.svg.select('#list_box_background').transition().duration(300)
        .attr('height', (no_ * gap ))    
      })  
    }
  }
}
