import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {

    Draw_Charts() {
      
      let chartArea = this.svg
      .append('g')
      .attr('transform', `translate(70, 80.5)`)
  
      // draw 0 and maxBounds on Axis Y1
      let gridY = 0
      let value1 = this.dataMaxValue1
      let value2 = this.dataMaxValue

      for(var i=0; i<11; i++) {

        chartArea
        .append('text') // Axis 1
        .attr('x', -10).attr('y',gridY).style('font-family', 'roboto').attr('font-size', 9).attr('text-anchor','end').attr('alignment-baseline','middle')
        .attr('fill', () => { if(value2 < 0) return '#bcbcbc'; else return '#757575' })
        .text( () => { if(value1 < 0) return -value1; else return value1 })

        chartArea
        .append('text') // Axis 2
        .attr('x', this.axisXWidth+30).style('font-family', 'roboto').attr('y', gridY).attr('font-size', 9).attr('text-anchor','start').attr('alignment-baseline','middle')
        .attr('fill', () => { if(value2 < 0) return '#bcbcbc'; else return '#757575' })
        .text( () => { if(value2 < 0) return -value2; else return value2 })

        chartArea
        .append('line')
        .attr('stroke', '#bcbcbc').attr('stroke-width', 0.3).attr('opacity', 1).attr('x1', 0).attr('x2', this.axisXWidth+20).attr('y1', gridY).attr('y2', gridY)

        gridY += (this.axisYHeight/10)
        value1 -= (this.dataMaxValue1/5)
        value2 -= (this.dataMaxValue/5)
      }


      // draw line chart in the line-group-box
      let raisedChart = chartArea
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .selectAll()
      .data(this.tickValues2)
      .enter()
      .append('line')
      .attr('stroke', '#E0E0DF')
      .attr('stroke-width', 10).attr('opacity', 1)
      .attr('x1', d => this.scaleXTimeline(this.parseDate(d.date))+10)
      .attr('x2', d => this.scaleXTimeline(this.parseDate(d.date))+10)
      .attr('y1', this.scaleRaised(0))
      .attr('y2', this.scaleRaised(0))
      .transition()
      .duration(500)
      .attr('y1', this.scaleRaised(0))
      .attr('y2', d => this.scaleRaised(d.raised))


      let ClosedChart = chartArea
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .selectAll()
      .data(this.tickValues3)
      .enter()
      .append('line')
      .attr('stroke', '#B4E3FA')
      .attr('stroke-width', 10).attr('opacity', 1)
      .attr('x1', d => this.scaleXTimeline(this.parseDate(d.date))+10)
      .attr('x2', d => this.scaleXTimeline(this.parseDate(d.date))+10)
      .attr('y1', this.scaleClosed(0))
      .attr('y2', this.scaleClosed(0))
      .transition()
      .duration(500)
      .attr('y1', this.scaleClosed(0))
      .attr('y2', d => this.scaleClosed(d.closed))

      

      let raisedValue = chartArea
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .selectAll()
      .data(this.tickValues2)
      .enter()
      .append('text')
      .attr('x', d => this.scaleXTimeline(this.parseDate(d.date))+10)
      .attr('y', this.scaleRaised(0))
      .style('font-family', 'roboto')
      .attr('font-size', 9)
      .attr('text-anchor','middle')
      .attr('alignment-baseline','middle')
      .attr('fill','#bcbcbc')
      .text(d => d.raised)
      .transition()
      .duration(500)
      .attr('y', d => this.scaleRaised(d.raised)-5)

      let closedValue = chartArea
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .selectAll()
      .data(this.tickValues3)
      .enter()
      .append('text')
      .attr('x', d => this.scaleXTimeline(this.parseDate(d.date))+10)
      .attr('y', this.scaleClosed(0))
      .style('font-family', 'roboto')
      .attr('font-size', 9)
      .attr('text-anchor','middle')
      .attr('alignment-baseline','middle')
      .attr('fill','#44A9DF')
      .text(d => d.closed)
      .transition()
      .duration(500)
      .attr('y', d => this.scaleClosed(d.closed)+7)

      let activeValue = chartArea
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .selectAll()
      .data(this.tickValues1)
      .enter()
      .append('text')
      .attr('x', d => this.scaleXTimeline(this.parseDate(d.date))+10)
      .attr('y', this.scaleActive(0))
      .style('font-family', 'roboto')
      .attr('font-size', 9)
      .attr('text-anchor','middle')
      .attr('alignment-baseline','middle')
      .attr('fill','#bcbcbc')
      .text(d => d.active)
      .transition()
      .duration(500)
      .attr('y', d => this.scaleActive(d.active)-5)

      let lines = chartArea
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .append('path')
      .attr('d', this.lineZero(this.tickValues1))
      .style('stroke', '#757575').style('stroke-width', 1).attr('opacity', 1)
      .style('fill', 'none')
      .transition()
      .duration(500)
      .attr('d', this.lineFunc(this.tickValues1))

      let markup = chartArea
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .selectAll()
      .data(this.tickValues1)
      .enter()
      .append('circle')
      .attr('fill', '#fff')
      .attr('stroke', '#757575')
      .attr('stroke-width', 1)
      .attr('cx', d => this.scaleXTimeline(this.parseDate(d.date))+10)
      .attr('cy', this.axisYHeight/2)
      .attr('r', 2)
      .transition()
      .duration(500)
      .attr('cy', d => this.scaleActive(d.active))
      

      chartArea // Axis X
      .append('line').attr('x1', 0).attr('x2', this.axisXWidth + 20).attr('y1', this.axisYHeight/2).attr('y2', this.axisYHeight/2).attr('stroke-width', .5).attr('stroke', '#333')

      // put timeline-raised & month text under the aixs X(Timeline)
      chartArea // raised
      .append('g')
      .attr('transform', `translate(0, ${this.axisYHeight + 4})`)
      .selectAll()
      .data(this.dataXTimelineValues)
      .enter()
      .append('text')
      .attr('x', d => this.scaleXTimeline(d) + 10).attr('y', 6).style('font-family', 'roboto').attr('font-size', 9).attr('fill','#757575').style('font-family','roboto').attr('text-anchor', 'middle')
      .text(d => d3.timeFormat('%d')(d))

      chartArea // month
      .append('g')
      .attr('transform', `translate(0, ${this.axisYHeight + 7})`)
      .selectAll()
      .data(this.dataXTimelineValuesMonth)
      .enter()
      .append('text')
      .attr('x', d => this.scaleXTimeline(d) + 10).attr('y', 15).style('font-family', 'roboto').attr('font-size', 10).attr('fill','#333').style('font-family','roboto').attr('text-anchor', 'middle')
      .text(d => d3.timeFormat('%b')(d))



      let axes = this.svg
      .append('g')
      .attr('transform', `translate(0,0.5)`)

      axes
      .append('text') 
      .attr('transform', `translate(30, 180) rotate(-90)`).style('font-family', 'roboto').attr('font-size', 10).attr('text-anchor','middle').attr('fill','#333').text('Outstanding Active Punch (Line)')

      axes
      .append('text') 
      .attr('transform', `translate(425, 180) rotate(-90)`).style('font-family', 'roboto').attr('font-size', 10).attr('text-anchor','middle').attr('fill','#333').text('Weekly Raised / Closed Punch (Bar)')


      let legend = this.svg
      .append('g')
      .attr('transform', `translate(140,50)`)
      
      legend
      .append('rect')
      .attr('transform', `translate(0, 5)`)
      .attr('width', 8).attr('height', 8).attr('fill','#E0E0DF')
      legend
      .append('text') 
      .attr('transform', `translate(13, 10)`).style('font-family', 'roboto').attr('font-size', 10).attr('text-anchor','start').attr('alignment-baseline','middle').attr('fill','#757575').text('Raised (Weelky)')

      legend
      .append('rect')
      .attr('transform', `translate(100, 5)`)
      .attr('width', 8).attr('height', 8).attr('fill','#B4E3FA')
      legend
      .append('text') 
      .attr('transform', `translate(113, 10)`).attr('font-size', 10).attr('text-anchor','start').attr('alignment-baseline','middle').attr('fill','#757575').text('Closed (Weelky)')


      legend
      .append('line')
      .attr('x1', 203).attr('x2', 215).attr('y1', 10).attr('y2', 10).attr('stroke-width', 1).attr('stroke', '#333')

      legend
      .append('circle')
      .attr('cx', 209).attr('cy', 10).attr('r', 2)
      .attr('fill', '#fff').attr('stroke-width', 1).attr('stroke', '#757575')
      
      legend
      .append('text') 
      .attr('transform', `translate(220, 10)`).style('font-family', 'roboto').attr('font-size', 10).attr('text-anchor','start').attr('alignment-baseline','middle').attr('fill','#757575').text(`Active`)


    },
  }
}
           