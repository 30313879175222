import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  data: () => ({
    skyline: {
      sizeType    : 'auto',
      fixedSize   : 450,
      minSize     : 470,
      startFloat  : 2,      
      endFloat    : 2,      
      weekEnd     : 'thursday',
      font        : 'roboto',
      marginTop   : 200     ,
      

      box: {
                  font      : 'roboto', 
                  width     : 86,     
                  height    : 15,     
                  gap       : 1,
                  radius    : 2,
                  sWidth    : 0.5,
                  opacity   : 1,
                  tSize     : 9

      },
    }

  }),
  methods: {

    Draw_Charts() {

      let BoxesChart = this.svg
      .append('g')
      .attr('transform', `translate(10,30)`)


      BoxesChart
      .append('text')
      .attr('x', 0)
      .attr('y', 0)
      .attr('font-family', this.skyline.box.font)
      .attr('font-size', 12)
      // .attr('font-weight', 'normal')
      .attr('fill', '#000')
      // .attr('text-anchor', 'start')
      // .style('pointer-events', 'none')
      .text('All associated MC Status')



      this.dataSet.forEach((d, i) => {
        let drawY = this.skyline.box.height * i
        let x1 = 0
        let y1 = drawY + 10

        


        let Boxes = BoxesChart
        .append('g')
        .attr('id', `SYS_${d.SUBSYSTEM}`)
        .attr('transform', `translate(${x1},${y1})`)
        .style('cursor', 'pointer')
        // .on('mouseover', function() {
        //   console.log(d3.select(this))
        //   d3.select(this).transition().duration(150)
        //   .attr('transform', `translate(${x1-(this.skyline.box.width/4)},${y1-(this.skyline.box.height/4)}) scale(1.5)`)

        //   let width = (d.SUBSYSTEM_DESCR.length * 6) + 30

        //   d3.select('#tooltip_rect').attr('width', width)
        //   d3.select('#tooltip_text').text(d.SUBSYSTEM_DESCR).attr('x', width/2)

        //   d3.select('.tooltip_group')
        //   .attr('transform', `translate(${x1 + 50}, ${y1 - 30})`)
        //   .style('opacity', 1)
          
        // })
        // .on('mouseout', function(){
        //   d3.select(this).transition().duration(150)
        //   .attr('transform', `translate(${x1},${y1}) scale(1)`)
          
        //   d3.select('.tooltip_group').style('opacity', 0)
        // })
        .on('click', () => {
          let request_ = this.getRequestColumnProps('request', { SUBSYSTEM: d.SUBSYSTEM,  DESC: d.SUBSYSTEM_DESCR }, this.ColumnProps)
          this.$emit('request-action', request_)

          
          // let request_ = {
          //   dataType: 'row',
          //   action: {
          //     type: 'direct',    // important!
          //     target: 'slide-modal',
          //     component: 'ServiceDatatable',
          //     id: 64,
          //     no: ''
          //   },
          //   filters     : {
          //     MOD: this.dataSet[this.target].MOD,
          //   },
          //   iFilters    : {
          //     filterString : ``,
          //     inputFilter  : ''
          //   }
          // }
          // this.$emit('request-action', request_)
        })

        if (this.skyline.box.width) {
          
          Boxes
          .append('rect') // the background color should be white(#fff)
          .attr('rx', this.skyline.box.radius)
          .attr('ry', this.skyline.box.radius)
          .attr('width', this.skyline.box.width)
          .attr('height', this.skyline.box.height)
          .attr('fill', '#fff')
          .attr('stroke', 'Transparent')
          .attr('stroke-width', this.skyline.box.sWidth)

          Boxes
          .append('rect') // Fill Color
          .attr('rx', this.skyline.box.radius)
          .attr('ry', this.skyline.box.radius)
          .attr('width', () => {
            return d.B_OV_PROG ? this.skyline.box.width * (d.B_OV_PROG/100) : 0
          })
          .attr('height', this.skyline.box.height)
          .attr('fill', '#FFD600')
          .attr('fill-opacity', this.skyline.box.opacity)
          .attr('stroke', 'Transparent')
          .attr('stroke-width', this.skyline.box.sWidth)
          
          Boxes
          .append('rect') // Stroke
          .attr('rx', this.skyline.box.radius)
          .attr('ry', this.skyline.box.radius)
          .attr('width', this.skyline.box.width)
          .attr('height', this.skyline.box.height)
          .attr('fill', 'Transparent')
          .attr('stroke', '#ccc')
          .attr('stroke-width', this.skyline.box.sWidth)
        }

        Boxes
        .append('text')
        .attr('x', 42)
        .attr('y', this.skyline.box.height / 2 + 1)
        .attr('font-family', this.skyline.box.font)
        .attr('font-size', this.skyline.box.tSize)
        .attr('font-weight', 'normal')
        .attr('fill', '#000')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .style('pointer-events', 'none')
        .text(d.SUBSYSTEM)


        // =====================================
        // Draw Losh Button

        if (!d.LOSH) return

        let loshgroup = BoxesChart
        .append('g')
        .attr('transform', `translate(100, ${y1})`)

        
        loshgroup
        .append('rect') // Stroke
        .attr('rx', this.skyline.box.radius)
        .attr('ry', this.skyline.box.radius)
        .attr('width', 30)
        .attr('height', this.skyline.box.height)
        .attr('fill', '#69BAE5')
        .attr('stroke', '#ccc')
        .attr('stroke-width', this.skyline.box.sWidth)

        
        loshgroup
        .append('text')
        .attr('x', 15)
        .attr('y', this.skyline.box.height / 2 + 1)
        .attr('font-family', this.skyline.box.font)
        .attr('font-size', this.skyline.box.tSize)
        .attr('font-weight', 'normal')
        .attr('fill', '#fff')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .style('pointer-events', 'none')
        .text('LOSH')

        loshgroup
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100).style('opacity', '0.7')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100).style('opacity', '1')
        })
        .on('click', () => {
          let request_ = {
            dataType: 'row',
            action: {
              name: 'Download',
              type: 'direct',
              target: 'Download',
              component: 'none',
              id: 0,
              no: ''
            },
            filters     : {
              METADATA: d.SUBSYSTEM,
              folder: 'LOSH'
            },
            iFilters    : {
              filterString : '',
              inputFilter  : ''
            }
          }
          this.$emit('request-action', request_)
        })


      })


      
      // let systemsummary = this.svg
      // .append('g')
      // .attr('transform', `translate(120, 0.5)`)

      // systemsummary
      // .append('g')
      // .attr('transform', 'translate(30, 100)')

      // systemsummary.append('rect').attr('class', 'download_btn_rect')
      // .attr('transform', `translate(0, 0)`)
      // .style('width', 90).style('height', 45)
      // .style('fill', '#BDBDBD').attr('rx', 3).attr('ry', 3)
      // .style('box-shadow', '12px 12px 2px 1px rgba(0, 0, 255, .2)')

      // // Titles
      // systemsummary.append('text')
      // .attr('transform', 'translate(45, 28)')
      // .style('font-family', 'roboto').style('font-size', 18).style('fill', '#fff').style('text-anchor', 'middle')
      // .text('LOSH')

      // if (this.DataItems[0].LOSH) d3.select('.download_btn_rect').style('fill', '#29B6F6')
      // else return

      // systemsummary
      // .style('cursor', 'pointer')
      // .on('mouseover', function() {
      //   d3.select(this).transition().duration(100).style('opacity', '0.7')
      // })
      // .on('mouseout', function(){
      //   d3.select(this).transition().duration(100).style('opacity', '1')
      // })
      // .on('click', () => {
      //   let request_ = {
      //     dataType: 'row',
      //     action: {
      //       name: 'Download',
      //       type: 'direct',
      //       target: 'Download',
      //       component: 'none',
      //       id: 0,
      //       no: ''
      //     },
      //     filters     : {
      //       METADATA: this.FilterValues.SUBSYSTEM,
      //       folder: 'LOSH'
      //     },
      //     iFilters    : {
      //       filterString : '',
      //       inputFilter  : ''
      //     }
      //   }
      //   this.$emit('request-action', request_)
      // })


    }
  }
}
