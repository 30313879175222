import * as d3 from 'd3'

export default {
  methods: {
    setTagStatus(status) {
      let style = this.eqStyle.tagControl

      d3.select(`#${this.localId}`).select('.tagbtn_bg')
      .transition()
      .attr('fill', style[status].bcolor)

      d3.select(`#${this.localId}`).select('.tagbtn_slider')
      .transition()
      .attr('transform', `translate(${style[status].slidex}, ${style[status].slidey})`)

      d3.select(`#${this.localId}`).select('.tagbtn_text')
      .transition()
      .attr('transform', `translate(${style[status].textx}, ${style[status].texty})`)
      .style('fill', style[status].tcolor)
      .text(status.toUpperCase())
    },
    drawTagButton(selection) {
      let style = this.eqStyle.tagControl

      selection
      .append('rect')
      .attr('transform', `translate(${style.labelWidth}, 0)`)
      .attr('class', 'tagbtn_bg')
      .attr('width', 40)
      .attr('height', 18)
      .attr('fill', style.on.bcolor)
      .attr('rx', '10')

      selection
      .append('circle')
      .attr('class', 'tagbtn_slider')
      .attr('transform', `translate(${style.on.slidex}, ${style.on.slidey})`)
      .attr("r", 6)
      .attr('fill', '#fff')

      selection
      .append('text')
      .attr('class', 'tagbtn_text')
      .attr('transform', `translate(${style.on.textx}, ${style.on.texty})`)
      .style('fill', style.on.tcolor)
      .style('alignment-baseline', 'middle')
      .text('ON')

      selection
      .append('text')
      .attr('transform', `translate(0, 9)`)
      .style('text-anchor', 'start')
      .style('alignment-baseline', 'middle')
      .style('fill', '#000')
      .text('Tag')

      selection.on('click', () => { setTimeout(() => { this.tagButtonAction(this.tagact() == 'ON' ? 'OFF' : 'ON') }) })
    },
    tagButtonAction(action) { 
      if(action == 'ON') {
        this.innerSvg.selectAll(`[class*="tag_"]`).each((_, i, a) => {
          let name = d3.select(a[i]).attr('class').split('_')
          let data = this.dataset.eqList.find(d => d.LV2 == name[1] && d.LV3 == name[2] && d.AREA_UNIT== name[3] && d.SN == parseInt(name[4]))
          d3.select(a[i]).text(data.TAG_DESCR_SHORT)
        })
      } else {
        d3.selectAll(`[class*="tag_"]`).each((_, i, a) => { d3.select(a[i]).text('') })
      }

      let tagact__ = action.toLowerCase()
      if(this.tagact != tagact__) this.setTagStatus(tagact__)
    },
    tagact() { return d3.select(`#${this.localId}`).select('.tagbtn_text').text() }
  }
}