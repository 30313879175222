import * as d3 from 'd3'
import __M from 'moment'
export default {
  data: () => ({
    y_coordinate: 0,
    height: {
      mainTimeline: 100,
      subTimeline: 90,
      barLarge: 35,
      barSmall: 25,
    },
    margin: {
      left: 10,
      mainTimeline: 50,
      subTimeline: 100,
      barPhase: 15,
      milestone: 100,
      info: 20,
      foldButton: 50
    }
  }),
  methods: {
    drawChart(){
      this.packages.forEach(p => {
        if (this.collapsed[p.P6_LV1]) this.drawPackage(p)
      })

    },
    drawPackage(p) {
      // draw milestone
      let d__ = this.DataItems.filter(d => d.ACTIVITY_TYPE == 'MS' && d.LEVEL == '0' && d.P6_LV1 == p.P6_LV1)
      // d__.forEach( (d, i) => { this.drawMileston(d, i) })
      this.drawMileston(d__)

      // draw timeline & button
      d__ = this.DataItems.filter(d => d.LEVEL == '0' && d.P6_LV1 == p.P6_LV1 && d.ITEM_DESC == 'Overall')
      this.drawSubTimeline(d__)
      this.drawLv0Info(d__)

      // draw bar
      d__ = this.DataItems.filter(d => d.ACTIVITY_TYPE == 'TASK' && d.LEVEL == '0' && d.P6_LV1 == p.P6_LV1)
      d__.forEach(d => { this.drawBar(d) })

      if (!this.areas) return
      this.areas.filter(a => a.P6_LV1 == p.P6_LV1).forEach(a => {
        if (this.collapsed[`${a.P6_LV1}${a.P6_LV2}`]) this.drawArea(a)
      })
    },
    drawArea(a) {
      // draw milestone
      let d__ = this.DataItems.filter(d => d.ACTIVITY_TYPE == 'MS' && d.LEVEL == '1' && d.P6_LV1 == a.P6_LV1 && d.P6_LV2 == a.P6_LV2)
      // d__.forEach(d => { this.drawMileston(d) })
      this.drawMileston(d__)

      // draw timeline & button
      d__ = this.DataItems.filter(d => d.LEVEL == '1' && d.P6_LV1 == a.P6_LV1 && d.P6_LV2 == a.P6_LV2 && d.ITEM_DESC == 'Overall')
      
      this.drawSubTimeline(d__)
      let fd = this.DataItems.filter(d => d.ACTIVITY_TYPE == 'TASK' && d.LEVEL == '1' && d.P6_LV1 == a.P6_LV1 && d.P6_LV2 == a.P6_LV2)
      this.drawLv1Info(d__, fd)


      // draw bar
      d__ = this.DataItems.filter(d => d.ACTIVITY_TYPE == 'TASK' && d.LEVEL == '1' && d.P6_LV1 == a.P6_LV1 && d.P6_LV2 == a.P6_LV2)

      d__.forEach(d => {
        this.drawBar(d)
        
        if (!this.phases) return
        this.phases.filter(ph => ph.P6_LV1 == a.P6_LV1 && ph.P6_LV2 == a.P6_LV2 && ph.P6_LV3 == d.ITEM_DESC).forEach(ph => {
          if (this.collapsed[`${ph.P6_LV1}${ph.P6_LV2}${ph.P6_LV3}`]) this.drawPhase(ph)
        })
      })
    },
    drawPhase(ph) {
      // draw bar
      let d__ = this.DataItems.filter(d => d.ACTIVITY_TYPE == 'TASK' && d.LEVEL == '2' && d.P6_LV1 == ph.P6_LV1 && d.P6_LV2 == ph.P6_LV2 && d.P6_LV3 == ph.P6_LV3)
      d__.forEach(d => { this.drawBar(d, 'barSmall') })
      
      this.y_coordinate += this.height.barSmall
    },
    drawLv0Info(__d){
      let key = 'P6_LV1'
      let d = __d[0]
      let g = this.svgGroup
      .append('g')
      .attr('transform', `translate(${this.margin.left +20}, ${this.y_coordinate - 30})`)
      .attr('class', '__info_')
      
      g.append('text')
      .attr('x', 0).attr('y', 0)
      .text(d[key])
      .style('font-size', 16).style('fill', '#000')
      .style('font-weight', 'bold')
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .style('cursor', 'pointer')
      

      g
      .append('g')
      .attr('transform', `translate(-25, 20)`)
      .append('image')
      .attr('opacity', 1)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/China1/Level2/${d.P6_LV1}.png`)
      .attr('width', '230px')
        
      if (!this.areas) return
      let area1 = this.areas.filter(a => a.P6_LV1 == d.P6_LV1)[0]
      if (!area1) return


    },
    drawLv1Info(__d, list){
      let d = __d[0]

      let g = this.svgGroup
      .append('g')
      .attr('transform', `translate(${!d.BL0_SD && !d.PLAN_SD ? this.margin.left : this.timeScale(d.BL0_SD < d.PLAN_SD ? new Date(d.BL0_SD) : new Date(d.PLAN_SD))}, ${this.y_coordinate - 10.1})`)
      .attr('class', '__info_')


      let titles = d.P6_LV2.split(' ')
      let ty = 0
      titles.forEach(t => {
        
        g.append('text')
        .attr('x', 0).attr('y', ty)
        .text(t)
        .style('font-size', 14).style('fill', '#000')
        .style('font-wieght', 'bold')
        .attr('text-anchor', 'end').attr('alignment-baseline', 'middle')

        ty += 16
      })
      
    },
    drawSubTimeline(d, type='subTimeline'){
      let d__ = d[0]
      this.y_coordinate += this.margin[type]

      let timeStart = d__.BL0_SD ? 'BL0_SD' : 'PLAN_SD'
      let timeEnd = 'PLAN_FD'

      
      let timelineValues = {
        year: [],
        month: [],
      }

      // Get start, end
      let startDate = __M(new Date(d__[timeStart])).startOf('month').add(-this.subTimelineFloat.start, 'months').toDate()
      let endDate = __M(new Date(d__[timeEnd])).endOf('month').add(this.subTimelineFloat.end, 'months').toDate()

      // Get timeline months
      let months = endDate.getMonth() - startDate.getMonth()
      let years = endDate.getYear() - startDate.getYear()
      months = months + (years*12)

      
      let prevYearDate_ = startDate

      for(var i=0; i<months; i++) {
        let _nextMonthDate__ = new Date(new Date(startDate).setMonth(startDate.getMonth() + i))

        // For the Year ---
        if(d3.timeFormat('%Y')(prevYearDate_) != d3.timeFormat('%Y')(_nextMonthDate__)) {
          let midDateYear_ = new Date(
            d3.mean([
              prevYearDate_,                                                                    // 1st date of the year
              new Date(new Date(_nextMonthDate__).setMonth(_nextMonthDate__.getMonth() + 1) - 1)  // last date of the year
            ])
          )
          timelineValues.year.push({ name: d3.timeFormat('%Y')(midDateYear_), value: new Date(midDateYear_) })
          prevYearDate_ = _nextMonthDate__
        }
      }

      // Year remained
      let midDateYear_ = new Date(
        d3.mean([
          prevYearDate_,  // 1st date of the year
          endDate        // last date of the year
        ])
      )
      timelineValues.year.push({ name: d3.timeFormat('%Y')(midDateYear_), value: new Date(midDateYear_) })

      4
      for(i=0; i<=months; i++) {
        let _nextMonthDate__ = new Date(new Date(startDate).setMonth(startDate.getMonth() + i))

        // For the Month ---
        let endMonth_ = new Date(new Date(_nextMonthDate__).setMonth(_nextMonthDate__.getMonth() + 1) - 1)
        let midDate = new Date(
          d3.mean([
            _nextMonthDate__, // 1st date of the month
            endMonth_         // last date of the month
          ])
        )
        timelineValues.month.push({ 
          name: d3.timeFormat('%b')(midDate), 
          value: new Date(midDate),
          endDate: endMonth_
        })
      }


      // Draw timeline
      let startX = this.timeScale(startDate)
      let endX = this.timeScale(endDate)
      let timelineWidth = endX - startX
      
      let g = this.svgGroup
      .append('g')
      .attr('transform', `translate(${this.margin.left}, ${this.y_coordinate})`)
      .attr('class', '__group_timeline_')
  
      g
      .append('path') // shadow
      .attr('d', `M${startX + 50},10 H ${endX - 50} L ${endX},20 H${startX} Z`).attr('fill', `url(#shadowTimeline)`) 

      g
      .append('rect')
      .attr('x', startX).attr('y', 0).attr('width', timelineWidth).attr('height', 40).attr('fill', `url(#${this.localId}__LinearA4LightGray0)`)

      g
      .append('g')
      .selectAll('text')
      .data(timelineValues.year)
      .enter()
      .append('text')
      .attr('x', d => this.timeScale(d.value))
      .attr('y', 15).style('font-size', 15).style('fill', '#EC407A').attr('font-weight', 500)
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(d => d.name.toUpperCase())

      // Timeline Text using the scale function
      // Month
      g
      .append('g')
      .selectAll('text')
      .data(timelineValues.month)
      .enter()
      .append('text')
      .attr('x', d => this.timeScale(d.value))
      .attr('y', 30).style('font-size', 10).style('fill', '#333')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(d => d.name.toUpperCase())

      // Timeline Seperator
      g
      .append('g')
      .selectAll('path')
      .data(timelineValues.month)
      .enter()
      .append('path')
      .attr('d', (d,i) => {
        if (timelineValues.month.length-1 > i) {
          return `M${this.timeScale(d.endDate)}, 36 V26`
        }
      })
      .attr('stroke', '#757575').attr('stroke-width', 0.3)
      
      this.y_coordinate += this.height[type]
    },
    drawBar(d, type='barLarge') {
      if (!(d.PLAN_SD && d.PLAN_FD) || !(d.BL0_SD && d.BL0_FD)) return
      
      // draw bars
      let g = this.svgGroup
      .append('g')
      .attr('transform', `translate(${this.margin.left}, ${this.y_coordinate})`)
      .attr('class', '__bar_')
      
      // BL0_SD, PLAN_SD 중 큰 값 계산해서 x로 사용
      let barText = g.append('text')
      .attr('x', this.timeScale(d.BL0_SD < d.PLAN_SD ? new Date(d.BL0_SD) : new Date(d.PLAN_SD)) - 10).attr('y', 8)
      .text(`${d.ITEM_DESC}`).style('font-size', 10).style('fill', '#9E9E9E')
      .attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
      
      // draw - baseline
      let barBaseline = g.append('rect')
      .attr('x', this.timeScale(new Date(d.BL0_SD))).attr('y', 0)
      .attr('width', this.timeScale(new Date(d.BL0_FD))-this.timeScale(new Date(d.BL0_SD)))
      .attr('height', 3).attr('fill', '#ccc') 

      // draw - current bar
      let barCurrent = g.append('rect')
      .attr('x', this.timeScale(new Date(d.PLAN_SD))).attr('y', 5)
      .attr('width', this.timeScale(new Date(d.PLAN_FD))-this.timeScale(new Date(d.PLAN_SD)))
      .attr('height', type == 'barSmall' ? 6 : 10).attr('fill', type == 'barSmall' ? '#4DB6AC' : '#B3E5FC') 

      // draw Plan Progress(BL0_SD) text
      if (d.PLAN_PRO && d.BL0_SD && d.BL0_FD) {
        // BL0_SD, PLAN_SD 중 큰 값 계산해서 x로 사용
        let barProgPlan = g.append('text')
        .attr('x', this.timeScale(d.BL0_FD < d.PLAN_FD ? new Date(d.PLAN_FD) : new Date(d.BL0_FD)) + 10).attr('y', 10)
        .style('font-size', 10).style('fill', '#9E9E9E')
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text(`Plan ${d.PLAN_PRO}%`)
      }

      if (d.PLAN_PRO && d.ACT_PRO && d.BL0_SD && d.BL0_FD && d.PLAN_FD && d.PLAN_SD){
        g.append('text')
        .attr('x', this.timeScale(d.BL0_FD < d.PLAN_FD ? new Date(d.PLAN_FD) : new Date(d.BL0_FD)) + 55).attr('y', 10)
        .style('font-size', 10).style('fill', '#9E9E9E')
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text(' / ')
      }

      // draw Actaul Progress (PLAN_SD, PLAN_FD) text
      if (d.ACT_PRO && d.PLAN_FD && d.PLAN_SD){
        let barProg = g.append('text')
        .attr('x',  () => {
          if (d.BL0_FD && d.PLAN_PRO) {
            // BL0_FD, PLAN_FD 비교 -> 더 늦은 스케쥴 기준으로 그리기
            if (new Date(d.BL0_FD) < new Date(d.PLAN_FD)) {
              return this.timeScale(new Date(d.PLAN_FD)) + 64
            }
            else return this.timeScale(new Date(d.BL0_FD)) + 64
          }else {
            return this.timeScale(new Date(d.PLAN_FD)) + 10
          }
        })
        .attr('y', 10)
        .style('font-size', 10).style('fill', '#D50000')
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text(`Actual ${d.ACT_PRO}%`)
      }
      
      if (this.timeScale(new Date(d.BL0_SD))){
        let plSDate = g.append('text')
        .attr('x', this.timeScale(new Date(d.BL0_SD))).attr('y', -4)
        .style('font-size', 8).style('fill', '#9E9E9E')
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text(`${__M(d.BL0_SD).format('MM/DD')}`)
      }
      
      if (this.timeScale(new Date(d.BL0_FD))){

        let plFDate = g.append('text')
        .attr('x', this.timeScale(new Date(d.BL0_FD))).attr('y', -4)
        .style('font-size', 8).style('fill', '#9E9E9E')
        .attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text(`${__M(d.BL0_FD).format('MM/DD')}`)
      }
      
      if (this.timeScale(new Date(d.PLAN_SD))){
        let planSDate = g.append('text')
        .attr('x', this.timeScale(new Date(d.PLAN_SD))).attr('y', 21)
        .style('font-size', 8).style('fill', '#9E9E9E')
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text(`${__M(d.PLAN_SD).format('MM/DD')}`)
      }

      if (this.timeScale(new Date(d.PLAN_FD))){
        let planFDate = g.append('text')
        .attr('x', this.timeScale(new Date(d.PLAN_FD))).attr('y', 21)
        .style('font-size', 8).style('fill', '#9E9E9E')
        .attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text(`${__M(d.PLAN_FD).format('MM/DD')}`)
      }

      // Add Y cordinate
      this.y_coordinate += this.height[type]
    },
    drawMileston(__d, type='milestone') {

      // draw milestone
      let g = this.svgGroup
      .append('g')
      .attr('transform', `translate(${this.margin.left}, ${this.y_coordinate + this.margin[type]})`)
      .attr('class', '__milestone_')

      __d.forEach((d, i) => {
        // milestone Plan
        if (d.MS_PLAN != d.MS_ACT) {
          let filter = __d.filter(f => {
            // 현 MS와 비슷한 기간의 MS가 있을 때, P6_LV5_SEQ 를 비교하여 더 큰놈이 그려지기
            return new Date(d.MS_PLAN) >= __M(new Date(f.MS_PLAN)).add(-15, 'days').toDate() &&
            new Date(d.MS_PLAN) <= __M(new Date(f.MS_PLAN)).add(15, 'days').toDate()
          })
          if (filter.length > 1) {
            let minSeq = Math.min.apply(Math, filter.map(m => m.P6_LV5_SEQ))
            let minSeqs = filter.map(m => m.P6_LV5_SEQ)

            let msPlan = g
            .append('g')
            .attr('id', 'item_indicator')
            .attr('transform', `translate(${this.timeScale(__M(new Date(d.MS_PLAN)).toDate())-3}, 0), rotate(-180) scale(.2)`)

            msPlan
            .append('path')
            .attr('d', this.pointerPath)
            .attr('fill', '#424242')
            .attr('stroke', '#424242')
            .attr('stroke-width', 1)
            .attr('opacity', minSeq == d.P6_LV5_SEQ ? 1 : 0.4)
            
            g
            .append('text')
            .attr('x', this.timeScale(__M(new Date(d.MS_PLAN)).toDate())-8)
            .attr('y', () => {
              let seq = minSeqs.findIndex(f => f == d.P6_LV5_SEQ)

              if (seq == 0) return -20
              else return -20 + (seq * -10)
            })
            .style('font-size', 9).style('fill', '#424242')
            .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
            .text(d.ITEM_DESC)
          }else {

            let msPlan = g
            .append('g')
            .attr('id', 'item_indicator')
            .attr('transform', `translate(${this.timeScale(__M(new Date(d.MS_PLAN)).toDate())-3}, 0), rotate(-180) scale(.2)`)
  
            msPlan
            .append('path')
            .attr('d', this.pointerPath)
            .attr('fill', '#424242')
            .attr('stroke', '#424242')
            .attr('stroke-width', 1)
            
            g
            .append('text')
            .attr('x', this.timeScale(__M(new Date(d.MS_PLAN)).toDate())-8).attr('y', -20)
            .style('font-size', 9).style('fill', '#424242')
            .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
            .text(d.ITEM_DESC)
          }
          
        }
        // milestone Actual
        if (d.MS_ACT) {
          let filter = __d.filter(f => {
            // 현 MS와 비슷한 기간의 MS가 있을 때, P6_LV5_SEQ 를 비교하여 더 큰놈이 그려지기
            return new Date(d.MS_ACT) >= __M(new Date(f.MS_ACT)).add(-15, 'days').toDate() &&
            new Date(d.MS_ACT) <= __M(new Date(f.MS_ACT)).add(15, 'days').toDate()
          })
          
          if (filter.length > 1){
            // SEQ 의 최소값을 찾아서 최소값만 opacity가 1, 나머지는 0.5 처리
            let minSeq = Math.min.apply(Math, filter.map(m => m.P6_LV5_SEQ))
            let minSeqs = filter.map(m => m.P6_LV5_SEQ)

            let msActual = g
            .append('g')
            .attr('id', 'item_indicator')
            .attr('transform', `translate(${this.timeScale(__M(new Date(d.MS_ACT)).toDate())-3}, 0), rotate(-180) scale(.2)`)

            msActual
            .append('path')
            .attr('d', this.pointerPath)
            .attr('fill', '#D50000')
            .attr('stroke', '#D50000')
            .attr('stroke-width', 1)
            .attr('opacity', minSeq == d.P6_LV5_SEQ ? 1 : 0.4)

            
            g
            .append('text')
            .attr('x', this.timeScale(__M(new Date(d.MS_ACT)).toDate())-8)
            .attr('y', () => {
              let seq = minSeqs.findIndex(f => f == d.P6_LV5_SEQ)

              if (seq == 0) return -20
              else return -20 + (seq * -10)
            })
            .style('font-size', 9).style('fill', '#D50000')
            .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
            .text(d.ITEM_DESC)

            
          }else {
            
            let msActual = g
            .append('g')
            .attr('id', 'item_indicator')
            .attr('transform', `translate(${this.timeScale(__M(new Date(d.MS_ACT)).toDate())-3}, 0), rotate(-180) scale(.2)`)

            msActual
            .append('path')
            .attr('d', this.pointerPath)
            .attr('fill', '#D50000')
            .attr('stroke', '#D50000')
            .attr('stroke-width', 1)
            
            g
            .append('text')
            .attr('x', this.timeScale(__M(new Date(d.MS_ACT)).toDate())-8).attr('y', -20)
            .style('font-size', 9).style('fill', '#D50000')
            .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
            .text(d.ITEM_DESC)
          }
          
          
          
        }
      })
      
    }
  }
}