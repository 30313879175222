export default {
  data: () => ({
    eqRegions: [
      { package: 'P1', area: 'BD' , code: 'A', fill: '#ec407a', path: 'M 393 533 L 1170 111 L 1277 285 L 501 712 L 392 533 Z' },
      { package: 'P1', area: 'BD' , code: 'B', fill: '#ec407a', path: 'M 504 712 L 984 455 L 1071 608 L 593 858 L 501 709 Z' },
      { package: 'P1', area: 'BD' , code: 'C', fill: '#ec407a', path: 'M 596 861 L 1072 613 L 1148 739 L 679 996 L 594 859 Z' },
      { package: 'P1', area: 'BD' , code: 'D', fill: '#ec407a', path: 'M 982 453 L 1282 291 L 1455 573 L 1155 736 L 982 458 Z' },

      { package: 'P1', area: 'BTX', code: 'A', fill: '#ec407a', path: 'M 373 581 L 766 368 L 889 561 L 1271 357 L 1362 505 L 584 925 Z' },
      { package: 'P1', area: 'BTX', code: 'B', fill: '#ec407a', path: 'M 766 369 L 1149 159 L 1272 357 L 888 559 Z' },

      { package: 'P1', area: 'DIB', code: 'A', fill: '#ec407a', path: 'M 385 634 L 1209 185 L 1385 474 L 561 921 L 384 634 Z' },
      { package: 'P1', area: 'BOILER', code: 'A', fill: '#ec407a', path: 'M 351 530 L 1116 112 L 1396 580 L 635 1000 L 352 531 Z' },
      { package: 'P1', area: 'GHU', code: 'A', fill: '#ec407a', path: 'M 339 650 L 1250 154 L 1435 459 L 524 952 L 339 651 Z' },

      { package: 'P1', area: 'OR', code: 'A', fill: '#ec407a', path: 'M 413 337 L 587 242 L 716 454 L 543 546 L 413 336 Z' },
      { package: 'P1', area: 'OR', code: 'B', fill: '#ec407a', path: 'M 588 243 L 730 165 L 859 380 L 717 452 L 589 242 Z' },
      { package: 'P1', area: 'OR', code: 'C', fill: '#ec407a', path: 'M 730 164 L 871 85 L 1000 299 L 856 376 L 732 164 Z' },
      { package: 'P1', area: 'OR', code: 'D', fill: '#ec407a', path: 'M 543 549 L 716 452 L 827 637 L 655 731 L 542 545 Z' },
      { package: 'P1', area: 'OR', code: 'E', fill: '#ec407a', path: 'M 716 453 L 857 380 L 968 562 L 828 636 L 719 451 Z' },
      { package: 'P1', area: 'OR', code: 'F', fill: '#ec407a', path: 'M 655 731 L 828 638 L 940 820 L 856 865 L 829 820 L 744 871 L 653 729 Z' },
      { package: 'P1', area: 'OR', code: 'G', fill: '#ec407a', path: 'M 835 633 L 969 562 L 1052 698 L 914 774 L 828 636 Z' },
      { package: 'P1', area: 'OR', code: 'H', fill: '#ec407a', path: 'M 741 869 L 833 818 L 856 863 L 941 816 L 1018 947 L 846 1043 L 743 869 Z' },
      { package: 'P1', area: 'OR', code: 'I', fill: '#ec407a', path: 'M 914 775 L 1058 700 L 1158 871 L 1019 943 L 915 774 Z' },
      { package: 'P1', area: 'OR', code: 'J', fill: '#ec407a', path: 'M 1059 702 L 1199 623 L 1305 796 L 1161 869 L 1056 702 Z' },

      { package: 'P1', area: 'FUR', code: 'A', fill: '#ec407a', path: 'M 581 382 L 806 259 L 1107 751 L 884 871 L 582 380 Z' },

      { package: 'P1', area: 'STF', code: 'A', fill: '#ec407a', path: 'M 338 620 L 581 489 L 672 643 L 430 773 L 337 622 Z' },
      { package: 'P1', area: 'STF', code: 'B', fill: '#ec407a', path: 'M 430 772 L 669 641 L 732 741 L 492 873 L 431 771 Z' },
      { package: 'P1', area: 'STF', code: 'C', fill: '#ec407a', path: 'M 492 873 L 734 743 L 796 845 L 554 976 L 490 871 Z' },
      { package: 'P1', area: 'STF', code: 'D', fill: '#ec407a', path: 'M 583 489 L 705 421 L 762 520 L 639 585 L 580 492 Z' },
      { package: 'P1', area: 'STF', code: 'E', fill: '#ec407a', path: 'M 639 585 L 762 519 L 919 776 L 796 843 L 638 585 Z' },
      { package: 'P1', area: 'STF', code: 'F', fill: '#ec407a', path: 'M 708 420 L 1013 253 L 1137 459 L 832 629 L 705 422 Z' },
      { package: 'P1', area: 'STF', code: 'G', fill: '#ec407a', path: 'M 832 629 L 1138 462 L 1228 606 L 921 775 L 832 628 Z' },
      { package: 'P1', area: 'STF', code: 'H', fill: '#ec407a', path: 'M 1014 254 L 1231 136 L 1315 271 L 1098 389 L 1015 255 Z' },
      { package: 'P1', area: 'STF', code: 'I', fill: '#ec407a', path: 'M 1100 388 L 1316 272 L 1383 384 L 1166 499 L 1100 388 Z' },
      { package: 'P1', area: 'STF', code: 'J', fill: '#ec407a', path: 'M 1167 500 L 1386 384 L 1450 487 L 1231 608 L 1169 499 Z' },

      { package: 'P1', area: 'DW', code: 'A', fill: '#ec407a', path: 'M 350 608 L 815 355 L 903 503 L 950 477 L 989 540 L 1180 437 L 1148 374 L 1284 305 L 1393 485 L 736 846 L 613 662 L 436 750 L 352 609 Z' },
      { package: 'P1', area: 'DW', code: 'B', fill: '#ec407a', path: 'M 809 359 L 1189 152 L 1289 315 L 1153 385 L 1180 431 L 991 541 L 959 480 L 908 504 L 808 357 Z' },
      { package: 'P1', area: 'DW', code: 'C', fill: '#ec407a', path: 'M 436 750 L 619 663 L 736 846 L 557 942 L 436 750 Z' },

      { package: 'P2', area: 'LDPE', code: 'A', fill: '#ec407a', path: 'M 651 438 L 907 299 L 973 418 L 724 557 L 653 436 Z' },
      { package: 'P2', area: 'LDPE', code: 'B', fill: '#ec407a', path: 'M 723 557 L 884 476 L 1025 716 L 871 800 L 728 559 Z' },
      { package: 'P2', area: 'LDPE', code: 'C', fill: '#ec407a', path: 'M 922 537 L 954 518 L 976 566 L 1014 549 L 1061 622 L 996 662 L 920 539 Z' },
      { package: 'P2', area: 'LDPE', code: 'D', fill: '#ec407a', path: 'M 1018 545 L 1086 509 L 1131 586 L 1063 625 L 1014 544 Z' },
      { package: 'P2', area: 'LDPE', code: 'E', fill: '#ec407a', path: 'M 1094 514 L 1159 481 L 1201 548 L 1130 586 L 1091 517 Z' },
      { package: 'P2', area: 'LDPE', code: 'F', fill: '#ec407a', path: 'M 1158 475 L 1201 451 L 1170 391 L 1216 368 L 1297 503 L 1203 548 L 1158 474 Z' },
      { package: 'P2', area: 'LDPE', code: 'G', fill: '#ec407a', path: 'M 1070 378 L 1186 318 L 1212 362 L 1095 420 L 1066 377 Z' },
      { package: 'P2', area: 'LDPE', code: 'H', fill: '#ec407a', path: 'M 882 478 L 1064 378 L 1094 426 L 920 534 L 884 480 Z' },
      //{ package: 'P2', area: 'LDPE', code: 'I', fill: '#ec407a', path: 'M 463 840 L 782 663 L 888 826 L 554 1005 L 461 838 Z' },
      { package: 'P2', area: 'LDPE', code: 'I', fill: '#ec407a', path: 'M 617 746 L 786 659 L 875 803 L 715 890 Z' },
      { package: 'P2', area: 'LDPE', code: 'J', fill: '#ec407a', path: 'M 922 505 L 1168 374 L 1213 451 L 966 579 L 922 506 Z' },

      { package: 'P2', area: 'GPPE', code: 'A', fill: '#ec407a', path: 'M 1108 260 L 1278 168 L 1344 276 L 1172 365 L 1105 260 Z' },
      { package: 'P2', area: 'GPPE', code: 'B', fill: '#ec407a', path: 'M 786 651 L 878 597 L 873 581 L 975 522 L 1074 682 L 878 793 L 789 653 Z' },
      { package: 'P2', area: 'GPPE', code: 'C', fill: '#ec407a', path: 'M 690 485 L 896 374 L 937 446 L 728 556 L 688 486 Z' },
      { package: 'P2', area: 'GPPE', code: 'D', fill: '#ec407a', path: 'M 733 555 L 935 446 L 972 510 L 832 608 L 838 620 L 787 650 L 730 557 Z' },
      //{ package: 'P2', area: 'GPPE', code: 'E', fill: '#ec407a', path: 'M 336 604 L 650 432 L 823 719 L 482 902 L 314 612 Z' },
      { package: 'P2', area: 'GPPE', code: 'E', fill: '#ec407a', path: 'M 526 554 L 678 468 L 830 715 L 675 799 Z' },
      { package: 'P2', area: 'GPPE', code: 'F', fill: '#ec407a', path: 'M 1054 435 L 1225 342 L 1272 415 L 1093 509 L 1050 437 Z' },
      { package: 'P2', area: 'GPPE', code: 'G', fill: '#ec407a', path: 'M 1223 343 L 1286 315 L 1404 514 L 1344 544 L 1223 347 Z' },
      { package: 'P2', area: 'GPPE', code: 'H', fill: '#ec407a', path: 'M 968 482 L 1050 439 L 1171 638 L 1091 680 L 967 479 Z' },
      { package: 'P2', area: 'GPPE', code: 'I', fill: '#ec407a', path: 'M 1093 511 L 1270 412 L 1350 545 L 1173 635 L 1092 510 Z' },

      //{ package: 'P2', area: 'PP', code: 'A', fill: '#ec407a', path: 'M 314 643 L 641 465 L 782 685 L 452 863 L 316 643 Z' },
      { package: 'P2', area: 'PP', code: 'A', fill: '#ec407a', path: 'M 494 541 L 646 458 L 786 689 L 634 771 Z' },
      { package: 'P2', area: 'PP', code: 'B', fill: '#ec407a', path: 'M 655 463 L 863 347 L 966 520 L 760 630 L 652 463 Z' },
      { package: 'P2', area: 'PP', code: 'C', fill: '#ec407a', path: 'M 767 630 L 966 520 L 1047 658 L 842 771 L 762 634 Z' },
      { package: 'P2', area: 'PP', code: 'D', fill: '#ec407a', path: 'M 843 771 L 1048 661 L 1119 778 L 913 888 L 841 770 Z' },
      { package: 'P2', area: 'PP', code: 'E', fill: '#ec407a', path: 'M 867 353 L 955 309 L 1071 498 L 982 548 L 860 352 Z' },
      { package: 'P2', area: 'PP', code: 'F', fill: '#ec407a', path: 'M 957 305 L 1233 148 L 1286 233 L 996 386 L 952 305 Z' },
      { package: 'P2', area: 'PP', code: 'G', fill: '#ec407a', path: 'M 1000 380 L 1282 224 L 1327 296 L 1044 448 L 1000 381 Z' },
      { package: 'P2', area: 'PP', code: 'H', fill: '#ec407a', path: 'M 1044 449 L 1324 299 L 1363 364 L 1082 514 L 1041 449 Z' },
      { package: 'P2', area: 'PP', code: 'I', fill: '#ec407a', path: 'M 1081 510 L 1361 360 L 1404 434 L 1127 582 L 1078 510 Z' },
      { package: 'P2', area: 'PP', code: 'J', fill: '#ec407a', path: 'M 1289 494 L 1406 430 L 1491 571 L 1376 635 L 1288 495 Z' },

      { package: 'P3', area: 'WFS', code: 'A', fill: '#ec407a', path: 'M 381 430 L 549 340 L 588 410 L 421 498 L 381 429 Z' },
      { package: 'P3', area: 'WFS', code: 'B', fill: '#ec407a', path: 'M 551 341 L 603 311 L 643 379 L 593 406 L 551 340 Z' },
      { package: 'P3', area: 'WFS', code: 'C', fill: '#ec407a', path: 'M 735 392 L 891 309 L 934 377 L 776 460 L 736 390 Z' },
      { package: 'P3', area: 'WFS', code: 'D', fill: '#ec407a', path: 'M 1137 843 L 1200 808 L 1235 862 L 1171 897 L 1139 844 Z' },
      { package: 'P3', area: 'WFS', code: 'E', fill: '#ec407a', path: 'M 1389 781 L 1550 693 L 1596 766 L 1436 854 L 1392 781 Z' },

      { package: 'P3', area: 'STF', code: 'A', fill: '#ec407a', path: 'M 303 238 L 432 168 L 470 230 L 339 300 L 303 238 Z' },
      { package: 'P3', area: 'STF', code: 'B', fill: '#ec407a', path: 'M 1087 849 L 1366 699 L 1434 818 L 1158 970 L 1088 851 Z' },
      { package: 'P3', area: 'STF', code: 'C', fill: '#ec407a', path: 'M 1367 700 L 1521 616 L 1593 733 L 1439 818 L 1366 700 Z' },
      { package: 'P3', area: 'STF', code: 'D', fill: '#ec407a', path: 'M 1161 973 L 1248 920 L 1316 1028 L 1223 1077 L 1158 970 Z' },

      { package: 'P3', area: 'MF', code: 'A', fill: '#ec407a', path: 'M 819 941 L 950 874 L 981 923 L 863 988 L 820 953 L 819 941 Z' },
      { package: 'P3', area: 'EPF', code: 'A', fill: '#ec407a', path: 'M 472 499 L 962 231 L 1229 669 L 738 937 L 470 497 Z' },
    ] 
  }),
  methods: {
    loadArea() {
      this.innerSvg = this.svg.append('svg')
      this.setPriority()
      
      let background = this.innerSvg
      .append('image')
      .attr('class','el_page_area_sector_background')
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/China1/EquipmentLayout/Sector/AREA_${this.route.to.package}_${this.route.to.area}.jpg`)
      .style('opacity', 0)
      .on('load', this.imageAreaLoaded)

      if(
        this.route.from && 
        this.route.from.level < this.route.to.level
      ) background.attr('transform', `translate(${this.zoom.out.x}, ${this.zoom.out.y}) scale(${1-this.scale})`)
      else background.attr('transform', `translate(${this.zoom.in.x}, ${this.zoom.in.y}) scale(${1+this.scale})`)
    },
    postLoadArea() {
      // draw area pathes
      this.eqRegions.filter(r => (
        r.package == this.route.to.package &&
        r.area == this.route.to.area
      )).forEach(r => {
        this.innerSvg
        .append('path')
        .attr('d', r.path)
        .style('fill', r.fill)
        .style('opacity', 0)
        .style('cursor', 'pointer')
        .on('click', () => {
          this.routeTo({
            level    : 3,
            lname    : 'equipment',
            package  : this.route.to.package,
            area     : this.route.to.area,
            equipment: r.code,
          }, {
            level  : 2,
            lname  : 'area',
            package: this.route.to.package,
            area   : this.route.to.area,
          })
        })
        .call(this.callFuncRegionalAction)
      })

      /**
       * Svg Path Tool to get the clicked mouse point (x, y)
       */
      // this.createPathReigon(this.innerSvg, {
      //   w: 1600,
      //   h: 1120,
      //   strokeWidth: 2,
      //   strokeColor: '#ff00ff'
      // })
    },
    unloadArea() {
      let background = this.innerSvg.select('.el_page_area_sector_background')
      .transition().duration(350)

      if(
        this.route.from && 
        this.route.from.level < this.route.to.level
        ) background.attr('transform', `translate(${this.zoom.in.x}, ${this.zoom.in.y}) scale(${1+this.scale})`)
        else background.attr('transform', `translate(${this.zoom.out.x}, ${this.zoom.out.y}) scale(${1-this.scale})`)
  
      this.innerSvg
      .transition().delay(100).duration(250)
      .style('opacity', 0)

      return new Promise(resolve => {
        setTimeout(() => {
          this.innerSvg.remove()
          resolve() 
        }, 500)
      })
    },
    imageAreaLoaded() {
      this.innerSvg.select('.el_page_area_sector_background')
      .transition().duration(350)
      .attr('transform', 'translate(0, 0) scale(1)')
      .style('opacity', 1)

      setTimeout(() => { this.postLoadArea() }, 400)
    }
  }
}