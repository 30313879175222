import * as d3 from 'd3'

export default {
  methods: {
    draw_Charts() {

      d3.select(`#${this.localId}`).select('.skyline_boxes').remove()

      let self = this
      let BoxesChart = this.SkylineCharts
      .append('g')
      .attr('class', 'skyline_boxes')
      .attr('transform', `translate(${this.timeline.baseX},0)`)
    
      d3.selection.prototype.moveToFront = function() {
        return this.each(function(){
        this.parentNode.appendChild(this);
        });
      }

      let priorityItem = this.style.find(s => s.group == 'Priority').item

      this.timeline.week.forEach((w, i) => {
        let drawY = this.timeline.baseY - this.skyline.box.height - (this.skyline.box.gap*2)

        let drawLengthX = 0

        this.dataSet.filter(f => f.cDate == w.eDate).forEach(d => {
          let x1 = this.timeline.scale(d.sDate) + this.skyline.box.gap
          let y1 = drawY

          drawLengthX = x1

          let Boxes = BoxesChart
          .append('g')
          .attr('id', `SYS_${d.SUBSYSTEM}`)
          .attr('transform', `translate(${x1},${y1})`)
          .style('cursor', 'pointer')
          .on('mouseover', function() {
            d3.select(this).moveToFront().transition().duration(150)
            .attr('transform', `translate(${x1-(self.skyline.box.width/4)},${y1-(self.skyline.box.height/4)}) scale(1.5)`)

            let width = (d.SUBSYSTEM_DESCR.length * 6) + 30

            d3.select('#tooltip_rect').attr('width', width)
            d3.select('#tooltip_text').text(d.SUBSYSTEM_DESCR).attr('x', width/2)

            d3.select('.tooltip_group')
            .attr('transform', `translate(${x1 + 50}, ${y1 - 30})`)
            .style('opacity', 1)
            
          })
          .on('mouseout', function(){
            d3.select(this).transition().duration(150)
            .attr('transform', `translate(${x1},${y1}) scale(1)`)
            
            d3.select('.tooltip_group').style('opacity', 0)
          })
          .on('click', () => {
            let requestTrimmed_ = this.trimFilterPure()

            let request_ = this.getRequestColumnProps('rebase', { ...requestTrimmed_.filters,  SUBSYSTEM: d.SUBSYSTEM,  DESC: d.SUBSYSTEM_DESCR }, this.ColumnProps)
            this.$emit('request-action', request_)
          })

          if (this.skyline.box.width) {
            
            Boxes
            .append('rect') // the background color should be white(#fff)
            .attr('rx', d.radius)
            .attr('ry', d.radius)
            .attr('width', this.skyline.box.width)
            .attr('height', this.skyline.box.height)
            .attr('fill', '#fff')
            .attr('stroke', 'Transparent')
            .attr('stroke-width', d.sWidth)

            Boxes
            .append('rect') // Fill Color
            .attr('rx', d.radius)
            .attr('ry', d.radius)
            .attr('width', () => d.progress == 'Y' ? d.A_PG ? this.skyline.box.width * (d.A_PG/100) : 0 : this.skyline.box.width)
            .attr('height', this.skyline.box.height)
            .attr('fill', d.bColor)
            .attr('fill-opacity', d.opacity)
            .attr('stroke', 'Transparent')
            .attr('stroke-width', d.sWidth)

            
            Boxes
            .append('rect') // Stroke
            .attr('rx', d.radius)
            .attr('ry', d.radius)
            .attr('width', this.skyline.box.width)
            .attr('height', this.skyline.box.height)
            .attr('fill', 'Transparent')
            .attr('stroke', d.BATCH == 'Priority Batch' ? '#000' : d.sColor)
            .attr('stroke-width', d.BATCH == 'Priority Batch' ? 1.5 : d.sWidth)
          }
          

          Boxes
          .append('text')
          .attr('x', this.timeline.weekSize/2 - 1)
          .attr('y', this.skyline.box.height/2 + 1)
          .attr('font-family', this.skyline.box.font)
          .attr('font-size', d.tSize)
          .attr('font-weight', d.BATCH == 'Priority Batch' ? 'bold' : 'normal')
          .attr('fill', priorityItem.find(pi => pi.codeValue == d.PRIORITY).tColor ? priorityItem.find(pi => pi.codeValue == d.PRIORITY).tColor : '#000')
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          .style('pointer-events', 'none')
          .text(d.SUBSYSTEM)

          drawY = drawY - this.skyline.box.height - (this.skyline.box.gap*2)
        })

        let length = this.dataSet.filter(f => f.cDate == w.eDate).length
        if (length == 0) return
        BoxesChart
          .append('text')
          .attr('x', function () {
            return drawLengthX + self.skyline.box.width/2
          })
          .attr('y', drawY + 10)
          .attr('font-family', this.skyline.box.font)
          .attr('font-size', 11)
          .attr('fill', '#64B5F6')
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          .style('pointer-events', 'none')
          .text(length)
      })

      this.Chart_filtering()
      

    }, 
    Chart_filtering() {
      this.dataSet.forEach(d => { 
        if(this.visible(d)) d3.select(`#${this.localId}`).select(`#SYS_${d.SUBSYSTEM}`).style('pointer-events', 'auto').transition().duration(500).style('opacity', 1)
        else d3.select(`#${this.localId}`).select(`#SYS_${d.SUBSYSTEM}`).style('pointer-events', 'none').transition().duration(500).style('opacity', .1)
      })
    }
  }
}
