import * as d3 from 'd3'

export default {
  methods: {

    call_Summary(selection, d) {
      selection
      .on('mouseover', function() {
        d3.select(this).transition().duration(150).attr('fill', 'red')
      })
      .on('mouseout', function() {
        d3.select(this).transition().duration(150).attr('fill', '#757575')
      })
      .on('click', ()=> {
        // console.log(d.unit, d.sSys, d.item, d.cols)

        let item_ = d.item.substr(0,1)
        let type = (d.item.substr(1,1) == 'P') ? 'P' : 'ITR'
        let dataSource = {}

        let reqtext = `JSON${type}`

        let request_ = this.getRequestColumnProps(reqtext, dataSource, this.ColumnProps)
        if(!request_) return

        let filterString_ = ''

        filterString_ += `[SYS_OWNER_ID] = '${d.unit}' AND [CATEGORY] = '${item_}'`

        if (d.cols == 'ACT') {filterString_ += ` AND [STATUS] = 'C'`}
        else if (d.cols == 'REM') {filterString_ += ` AND [STATUS] <> 'C'`}

        if (d.sSys !== 'OV') {filterString_ += ` AND [SUBSYS_NO] = '${d.sSys}'`}

        if(request_.iFilters.filterString) {
          request_.iFilters.filterString += filterString_ ? ` AND ${filterString_} ` : ''
        } else {
          request_.iFilters.filterString = filterString_||''
        }
        
        request_ = {...request_,
          filters : {
            ...this.FilterValues,
            ...request_.filters,
          },
          iFilters: { 
            filterString : request_.iFilters.filterString||'',
            inputFilter : request_.iFilters.inputFilter,},
        }
        this.$emit('request-action', request_)
      })
    },


    link_Action(link_) {
      // set for link key value   ex) let dataSource = {KEY1:d.PKG,KEY2:d.DISC...}
      let dataSource = link_.code == 'UNIT'? dataSource = {KEY1: link_.scope, KEY2: link_.unit, KEY3: link_.unit_No}:{}
      // if delValue is 'Y', set dataSource
      // if(this.delValue.length > 0){
      //   this.delValue.forEach((d, i) => {
      //     dataSource = {...dataSource, [`KEY${i+1}`]: this.rawData[_style.idx][d]}
      //   })
      // }
      let reqtext = `JSON${(link_.code =='SSMCC'||link_.code =='WD')? 'SSMCC':link_.code}`  // JSONSSMCC , JSONWD, JSONA, JSONAP,JSONUNIT
      // set for request from A/G jsonProps
      let request_ = this.getRequestColumnProps(reqtext, dataSource, this.ColumnProps)
      if(!request_) return
      // if filterCol is exist, set filterString_
      let filterString_ = ''

      let scope_ = link_.scope == 'P1'? 'P1' : link_.scope == 'P2'? 'P2' : link_.scope == 'P3'? 'P3' : 'OV'
      let coltype_ = link_.coltype
      let code_ = link_.code
      let unit_ = link_.unit

      // Grassroots UNIT SUMMARY
      if( code_ == 'SSMCC' || link_.code == 'WD') {
        if( coltype_ == 'TOT'){
          filterString_ += `[UNIT] = '${unit_}' AND [SSMCC_TOT] = '${coltype_}'`
        } else if ( code_ == 'SSMCC' & coltype_ == 'ACT'){
          filterString_ += `[UNIT] = '${unit_}' AND [SSMCC_ACT] = '${coltype_}'`
        } else if ( code_ == 'SSMCC' & coltype_ == 'REM'){
          filterString_ += `[UNIT] = '${unit_}' AND [SSMCC_ACT] = ''`
        } else if ( code_ == 'WD' & coltype_ == 'ACT'){
          filterString_ += `[UNIT] = '${unit_}' AND [SSMCC_WD] = '${code_}'`
        } else if ( code_ == 'WD' & coltype_ == 'REM'){
          filterString_ += `[UNIT] = '${unit_}' AND [SSMCC_WD] = ''`
        }
      } else if ( code_ == 'UNIT') {
        filterString_ += `[SCOPE] = '${scope_}'`
      // SHEET & PUNCH
      } else {
        code_ = (link_.code == 'A'||link_.code == 'B')? link_.code : link_.code.substr(0,1)

        if(coltype_ == 'TOT'){
          filterString_ += `[UNIT] = '${unit_}' AND [CATEGORY] = '${code_}'`
        } else if(coltype_ == 'ACT'){
          filterString_ += `[UNIT] = '${unit_}' AND [CATEGORY] = '${code_}' AND [STATUS] = 'C'`
        } else {
          filterString_ += `[UNIT] = '${unit_}' AND [CATEGORY] = '${code_}' AND [STATUS] <> 'C'`
        }
      }
      // if A/G iFilters is exist, (filterString_ + iFilters)
      if(request_.iFilters.filterString) {
        request_.iFilters.filterString += filterString_ ? ` AND ${filterString_} ` : ''
      } else {
        request_.iFilters.filterString = filterString_||''
      }
      
      // combine filterString 
      request_ = {...request_,
        filters : {
          ...this.FilterValues,
          ...request_.filters,
        },
        iFilters: { 
          filterString : request_.iFilters.filterString||'',
          inputFilter : request_.iFilters.inputFilter,},
      }
      this.$emit('request-action', request_)
    },
    
  }
}