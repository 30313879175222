import * as d3 from 'd3'
import { chordTranspose } from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({
    props:[
      {common:'FL01', x:70, y:10},
      {common:'KO01', x:30, y:60},
      {common:'LD00DA', x:40, y:50},
      {common:'RCxx', x:10, y:50}
    ]
  }),
  methods: {
    drawChart() {
      let commonMap = this.DataItems.map(d => d.LV3)
      let props = this.props.filter(d => d.common == commonMap)[0]
      let mainGroup = this.svg
      .append('g')
      .attr('transform', `translate(0, 0)`)

      let blockPhoto = mainGroup
      .append('g')
      const path = `${this.__HOST_NAME_RESOURCE}/TORTUE/Modules/AreaDashboard/${commonMap}.png`

      blockPhoto
      .append('image')
      .attr('id', 'menuIcon')
      .attr('transform', `translate(${props.x}, ${props.y}) `)
      .attr('xlink:href', path)
      .style('cursor', 'pointer')
      .on('mouseover', () => blockPhoto.style('opacity', 0.7))
      .on('mouseleave', () => blockPhoto.style('opacity', 1))
      .on('click', () => {

        let request_ = this.getRequestColumnProps('common', {}, this.ColumnProps)
        if(request_) this.$emit('request-action', request_)
        // let request_ = {
        //   dataType: 'row',
        //   action: {
        //     type: 'direct',
        //     target: 'slide-modal',
        //     component: "ServiceDashboard",
        //     id: 84,
        //     no: ""
        //   },
        //   filters: {}
        // }

        // this.$emit('request-action', request_)
      })
      if(!this.DataItems || this.DataItems.length === 0) return
      let item = this.DataItems[0]
  
      let data = mainGroup
      .append('g')
      .attr('transform', `translate(0, 190)`)

      data
      .append('rect')
      .attr('x', 4).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', 190).attr('height', 54)
      .attr('fill', '#83d2f5')

      data
      .append('text')
      .attr('x', 7).attr('y', 28)
      .style('font-family','roboto').style('font-size',10).attr('text-anchor', 'start').attr('fill', '#424242')
      .text('Cum.')
      data
      .append('text')
      .attr('x', 7).attr('y', 48)
      .style('font-family','roboto').style('font-size',10).attr('text-anchor', 'start').attr('fill', '#424242')
      .text('Week.')

      data
      .append('text')
      .attr('x', 49).attr('y', 13)
      .style('font-family','roboto').style('font-size',10).attr('text-anchor', 'start').attr('fill', '#424242')
      .text('Plan')

      data
      .append('text')
      .attr('x', 80).attr('y', 30)
      .style('font-family','roboto').style('font-size',11).attr('text-anchor', 'end').attr('fill', '#FFF')
      .text(item.PLAN + '%')
      data
      .append('text')
      .attr('x', 80).attr('y', 48)
      .style('font-family','roboto').style('font-size',11).attr('text-anchor', 'end').attr('fill', '#FFF')
      .text(item.PLAN_WK + '%')

      data
      .append('text')
      .attr('x', 99).attr('y', 13)
      .style('font-family','roboto').style('font-size',10).attr('text-anchor', 'start').attr('fill', '#424242')
      .text('Actual')

      data
      .append('text')
      .attr('x', 134).attr('y', 30)
      .style('font-family','roboto').style('font-size',11).attr('text-anchor', 'end').attr('fill', '#FFF')
      .text(item.ACTUAL + '%')
      data
      .append('text')
      .attr('x', 134).attr('y', 48)
      .style('font-family','roboto').style('font-size',11).attr('text-anchor', 'end').attr('fill', '#FFF')
      .text(item.ACTUAL_WK + '%')

      data
      .append('text')
      .attr('x', 180).attr('y', 13)
      .style('font-family','roboto').style('font-size',10).attr('text-anchor', 'end').attr('fill', '#424242')
      .text('Delta')

      data
      .append('text')
      .attr('x', 184).attr('y', 30)
      .style('font-family','roboto').style('font-size',11).attr('text-anchor', 'end').attr('fill', '#FFF')
      .text(item.DELTA + '%')
      data
      .append('text')
      .attr('x', 184).attr('y', 48)
      .style('font-family','roboto').style('font-size',11).attr('text-anchor', 'end').attr('fill', '#FFF')
      .text(item.DELTA_WK + '%')
    
      let ogmdata = mainGroup
      .append('g')
      .attr('transform', `translate(5, 250)`)

      ogmdata
      .append('rect')
      .attr('x', 0).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', 86).attr('height', 36)
      .attr('fill', '#ffffff').attr('stroke', '#8BC34A') .attr('stroke-width', 0.5)
      ogmdata
      .append('text')
      .attr('x', 86/2).attr('y', 13)
      .style('font-family','roboto').style('font-size',10).attr('text-anchor', 'middle').attr('fill', '#424242')
      .text(item.TEXT2 ? item.TEXT2 : '')

      // console.log(item.TEXT2_PROG)
      const barScale = d3.scaleLinear()
      .domain([0,100])
      .range([0,86])

      //PROG Background Bar
      ogmdata
      .append('rect')
      .attr('x', 0).attr('y', 18).attr('width', 86).attr('height', 18)
      .attr('fill', '#fff').attr('stroke', '#8BC34A') .attr('stroke-width', 0.5)

      //PROG Actual Bar
      ogmdata
      .append('rect')
      .attr('x', 0).attr('y', 18)
      .attr('width',0)
      .attr('height',18)
      .attr('fill','#8BC34A')

      .transition().duration(150)
      .attr('width',barScale(item.ACTUAL))

      ogmdata
      .append('text')
      .attr('x', 86/2).attr('y', 31)
      .style('font-family','roboto').style('font-size',11).attr('text-anchor', 'middle')
      .attr('fill',item.ACTUAL >=60 ? '#fff' : '#424242')
      .text(item.TEXT2_PROG ? item.TEXT2_PROG + '%' : '')

      ogmdata
      .append('rect')
      .attr('x', 102).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', 86).attr('height', 18)
      .attr('fill', '#fff').attr('stroke', '#9E9E9E') .attr('stroke-width', 0.5)
      ogmdata
      .append('text')
      .attr('x', 145).attr('y', 13)
      .style('font-family','roboto').style('font-size',10).attr('text-anchor', 'middle').attr('fill', '#424242')
      .text(item.TEXT1 ? item.TEXT1 : '')

      ogmdata
      .append('rect')
      .attr('x', 102).attr('y', 18).attr('width', 86).attr('height', 18)
      .attr('fill', '#9E9E9E').attr('stroke', '#9E9E9E') .attr('stroke-width', 0.5)
      ogmdata
      .append('text')
      .attr('x', 145).attr('y', 31)
      .style('font-family','roboto').style('font-size',11).attr('text-anchor', 'middle').attr('fill', '#FFF')
      .text(item.TEXT1_DATE ? item.TEXT1_DATE : '')
    },
  }
}
