 /* 
  ### Chart Controllers ### 
  Predefined component's behaviors for the Filter & Action
*/
import System_ChartOptions from '../../09_Projects/TCO/00_System/ChartOptions'
import System_Dropdown from '../../09_Projects/TCO/00_System/Dropdown'
import System_SubMenu_01 from '../../09_Projects/TCO/00_System/SubMenu_01'
  
/* 
  ### Chart Customized ### 
  Unregulated chart components, not on the role of the controller.
*/
// Common
import CMN_HistoryTable from        '../../01_Charts/99_Common/HistoryTable'
import CMN_TBD from                 '../../01_Charts/99_Common/TBD'
import CMN_Up2UText from            '../../01_Charts/99_Common/Up2UText'
import CMN_3DViewerEmbedded from    '../../01_Charts/99_Common/3DViewerEmbedded'

//Homepage
import Home_DataSource from '../../09_Projects/Tortue/00_Homepage/DataSource'
import Home_ProjectProgress from '../../09_Projects/Tortue/00_Homepage/OverallProgress'
import Home_KeyMilestone from '../../09_Projects/Tortue/00_Homepage/KeyMilestone'
import Home_Observation from '../../09_Projects/Tortue/00_Homepage/Observation'
import Home_OverallSummary from '../../09_Projects/Tortue/00_Homepage/OverallSummary'
import Home_OncomingSailaway from '../../09_Projects/Tortue/00_Homepage/OncomingSailaway'
import Home_Yard             from '../../09_Projects/Tortue/00_Homepage/Yard'
import Home_Milestones  from '../../09_Projects/Tortue/00_Homepage/MilestoneImg'
import Home_Milestones_R  from '../../09_Projects/Tortue/00_Homepage/Milestone'

import Home_MC_Pie  from '../../09_Projects/Tortue/00_Homepage/Pie'
import Home_MC_SummaryProg  from '../../09_Projects/Tortue/00_Homepage/SummaryProg'
import Home_MC_ITR  from '../../09_Projects/Tortue/00_Homepage/MC_ITRStatus'
import Home_MC_Punch  from '../../09_Projects/Tortue/00_Homepage/MC_PunchTrends'
import Home_MoreButton  from '../../09_Projects/Tortue/00_Homepage/MoreButton'
import Home_PriorityChart  from '../../09_Projects/Tortue/00_Homepage/PriorityChart'
import Home_TubeChart  from '../../09_Projects/Tortue/00_Homepage/TubeChart'
import Home_WorkForceTable  from '../../09_Projects/Tortue/00_Homepage/WorkForceTable'

import Home_performanceLineChart  from '../../09_Projects/Tortue/00_Homepage/performanceLineChart'
import Home_performanceBarChart_ITR  from '../../09_Projects/Tortue/00_Homepage/performanceBarChart_ITR'
import Home_performanceLineChart_punch  from '../../09_Projects/Tortue/00_Homepage/performanceLineChart_punch'
import Home_performanceBarChart_punch  from '../../09_Projects/Tortue/00_Homepage/performanceBarChart_punch'
import Home_performanceCompletionChart  from '../../09_Projects/Tortue/00_Homepage/performanceCompletionChart'
import Home_mcskyline_performance  from '../../09_Projects/Tortue/00_Homepage/MC_Skyline_performance'

import Home_performanceLineChart_B  from '../../09_Projects/Tortue/00_Homepage/performanceLineChart_B'
import Home_performanceBarChart_BITR  from '../../09_Projects/Tortue/00_Homepage/performanceBarChart_BITR'
import Home_performanceLineChart_Bpunch  from '../../09_Projects/Tortue/00_Homepage/performanceLineChart_Bpunch'
import Home_performanceBarChart_Bpunch  from '../../09_Projects/Tortue/00_Homepage/performanceBarChart_Bpunch'
import Home_performanceCompletionChart_B  from '../../09_Projects/Tortue/00_Homepage/performanceCompletionChart_B'
import Home_ProceduresGauge  from '../../09_Projects/Tortue/00_Homepage/ProceduresGauge'
import Home_ProceduresGauge_Daily  from '../../09_Projects/Tortue/00_Homepage/ProceduresGauge_Daily'
import Home_SummaryTable  from '../../09_Projects/Tortue/00_Homepage/SummaryTable'

// HSSE
// import HSSE_SafetyPyramid from '../../09_Projects/MD2/03_HSSE/Pyramid'
import HSSE_SafetyPyramid from '../../09_Projects/Tortue/01_HSSE/Pyramid'
import HSSE_LSR from '../../09_Projects/Tortue/01_HSSE/LSR_IMG'
import HSSE_TSC from '../../09_Projects/Tortue/01_HSSE/tortueSafetyCharter'
import HSSE_Dashboard from '../../09_Projects/Tortue/01_HSSE/HSSEDashboard'

//Planning
import Plan_ConstructionBlockSkyline from '../../09_Projects/Tortue/01_Planning/ConstructionBlockSkyline'
import Plan_ProgressSummaryTable from '../../09_Projects/Tortue/01_Planning/ProgressSummaryTable'
import Plan_ModuleSummarySchedule from '../../09_Projects/Tortue/01_Planning/ModuleSummarySchedule'
import Plan_CommoditySummarySchedule from '../../09_Projects/Tortue/01_Planning/CommoditySummarySchedule'
import Plan_SummaryByArea from '../../09_Projects/Tortue/01_Planning/SummaryByArea'
import Plan_ModuleKeyMilestones from '../../09_Projects/Tortue/01_Planning/ModuleKeyMilestones'
import TopsidesGanttChart from '../../09_Projects/Tortue/01_Planning/TopsidesGanttChart'
import Plan_FilterPhase from '../../09_Projects/Tortue/01_Planning/FilterPhase'
import Plan_SummaryByOutfittingCommodity from '../../09_Projects/Tortue/01_Planning/SummaryByOutfittingCommodity'
import Plan_SpoolSummary from '../../09_Projects/Tortue/01_Planning/SpoolSummary'

//Engineering
import Eng_CMDRStatus from            '../../09_Projects/Tortue/02_Engineering/CMDRStatus'
import Eng_IFCDrawingList from            '../../09_Projects/Tortue/02_Engineering/IFCDrawingList'
import Eng_TotalPlanActualRemainL from    '../../09_Projects/Tortue/02_Engineering/TotalPlanActualRemainL'
import Eng_TotalPlanActualRemainM from    '../../09_Projects/Tortue/02_Engineering/TotalPlanActualRemainM'
import Eng_TotalPlanActualRemainS from    '../../09_Projects/Tortue/02_Engineering/TotalPlanActualRemainS'
import Eng_CDREHistoryChart from    '../../09_Projects/Tortue/02_Engineering/CDREHistoryChart'
import Eng_TQHistoryChart from    '../../09_Projects/Tortue/02_Engineering/TQHistoryChart'
import Eng_SDIDashboard from    '../../09_Projects/Tortue/02_Engineering/SDIDashboard'

// Procurement
import Proc_EquipmentLayout from '../../09_Projects/DEMO/05_Procurement/Equipment_Layout'
import Proc_EquipmentTable from '../../09_Projects/Tortue/03_Procurement/EquipmentTable'
import Proc_Equipment3DLayout from '../../09_Projects/Tortue/03_Procurement/Equipment3DLayout'
import Proc_EquipmentRoom from '../../09_Projects/Tortue/03_Procurement/EquipmentRoom'
import Proc_ProgressBar from '../../09_Projects/Tortue/03_Procurement/progressBar'
import Proc_Lookahead from '../../09_Projects/Tortue/03_Procurement/lookahead'
import Proc_OWL from '../../09_Projects/Tortue/03_Procurement/owl'
import Proc_OverallScurve from '../../09_Projects/Tortue/03_Procurement/Overall_Scurve'
import Proc_FATScurve from '../../09_Projects/Tortue/03_Procurement/FAT_Scurve'
import Proc_IRNScurve from '../../09_Projects/Tortue/03_Procurement/IRN_Scurve'
import Proc_ETAScurve from '../../09_Projects/Tortue/03_Procurement/ETA_Scurve'
import Proc_IRNLookahead from '../../09_Projects/Tortue/03_Procurement/IRN_Lookahead'
import Proc_ETALookahead from '../../09_Projects/Tortue/03_Procurement/ETA_Lookahead'
import Proc_EquipmentHullCommon from '../../09_Projects/Tortue/03_Procurement/EquipmentHullCommon'


// Construction
import Con_BlockDivision from         '../../09_Projects/Tortue/04_Construction/BlockDivision'
import Con_BlockSummary from          '../../09_Projects/Tortue/04_Construction/BlockSummary'
import Con_ActivityGanttChart from    '../../09_Projects/Tortue/04_Construction/ActivityGanttChart'
import Con_ActivityGanttChart_R1 from    '../../09_Projects/Tortue/04_Construction/ActivityGanttChart_R1'
import Con_ActivityGanttChartLegend from    '../../09_Projects/Tortue/04_Construction/ActivityGanttChartLegend'
import Con_MeasurementBlock from      '../../09_Projects/Tortue/04_Construction/MeasurementBlock'
import Con_ShopDrawingActivities from '../../09_Projects/Tortue/04_Construction/ShopDrawingActivities'
import Con_ShopDrawingStatus from '../../09_Projects/Tortue/04_Construction/ShopDrawingStatus'
import Con_ShopDrawingStatusOther from '../../09_Projects/Tortue/04_Construction/ShopDrawingStatusOther'
import Con_OutfittingStatus from      '../../09_Projects/Tortue/04_Construction/OutfittingStatus'
import Con_OutfittingStatus_new from      '../../09_Projects/Tortue/04_Construction/OutfittingStatus_new'
import Con_Outfitting from            '../../09_Projects/Tortue/04_Construction/Outfitting'
import Con_ErectionTimeline from      '../../09_Projects/Tortue/04_Construction/ErectionTimeline'
import Con_OverallDashboard from      '../../09_Projects/Tortue/04_Construction/DashboardOverall'
import Con_OverallDashboardTopside from      '../../09_Projects/Tortue/04_Construction/DashboardOverallTopside'
import Con_OverallDashboardTopside_L from      '../../09_Projects/Tortue/04_Construction/DashboardOverallTopside_L'
import Con_OverallDashboardTopside_S from      '../../09_Projects/Tortue/04_Construction/DashboardOverallTopside_S'
import Con_OverallDashboardHull from      '../../09_Projects/Tortue/04_Construction/DashboardOverallHull'
import Con_OverallDashboardHull_L from      '../../09_Projects/Tortue/04_Construction/DashboardOverallHull_L'
import Con_OverallDashboardHull_S from      '../../09_Projects/Tortue/04_Construction/DashboardOverallHull_S'
import Con_OverallDashboardModules_S from      '../../09_Projects/Tortue/04_Construction/DashboardOverallModules_S'
import Con_OverallDashboardModule_S from      '../../09_Projects/Tortue/04_Construction/DashboardOverallModule_S'
import Con_OverallDashboardCommon_S from      '../../09_Projects/Tortue/04_Construction/DashboardOverallCommon_S'
import Con_OverallDashboardLQ_S from      '../../09_Projects/Tortue/04_Construction/DashboardOverallLQ_S'
import Con_OverallDashboardLQ from      '../../09_Projects/Tortue/04_Construction/DashboardOverallLQ'
import Con_ProgressSummaryOverall from      '../../09_Projects/Tortue/04_Construction/ProgressSummaryOverall'
import Con_ProgressSummaryTopside from      '../../09_Projects/Tortue/04_Construction/ProgressSummaryTopside'
import Con_ProgressSummaryHull from      '../../09_Projects/Tortue/04_Construction/ProgressSummaryHull'
import Con_OutfittingCommoditiesStatus from      '../../09_Projects/Tortue/04_Construction/OutfittingCommoditiesStatus'
import Con_OutfittingCommoditiesStatusL from      '../../09_Projects/Tortue/04_Construction/OutfittingCommStatus_L'
import Con_OgmDashboardHull from      '../../09_Projects/Tortue/04_Construction/OgmDashboardHull'
import Con_OgmDashboardTopside from      '../../09_Projects/Tortue/04_Construction/OgmDashboardTopside'
import Con_SimpleText from      '../../09_Projects/Tortue/04_Construction/SimpleText'
import Con_OgmDisc from      '../../09_Projects/Tortue/04_Construction/OgmDisc'
import Con_OutfittingCommStatus from      '../../09_Projects/Tortue/04_Construction/OutfittingCommStatus'
import Con_OutfittingCommStatus_S from      '../../09_Projects/Tortue/04_Construction/OutfittingCommStatus_S'
import Con_SpoolSummarySize from      '../../09_Projects/Tortue/04_Construction/SpoolSummarySize'
import Con_SpoolSummarySize_S from      '../../09_Projects/Tortue/04_Construction/SpoolSummarySize_S'
import Con_SpoolSummarySize_L from      '../../09_Projects/Tortue/04_Construction/SpoolSummarySize_L'
import Con_SpoolSummaryArea from      '../../09_Projects/Tortue/04_Construction/SpoolSummaryArea'
import Con_AreaDashboard_Hull_AFT from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L2_Hull'
import Con_AreaDashboard_Hull_FOR from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L2_HullFOR'
import Con_AreaDashboard_Module from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L2_Module'
import Con_AreaDashboard_Common from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L2_Common'
import Con_AreaDashboard_L3_ME01 from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L3_ME01'
import Con_AreaDashboard_L3_ME02 from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L3_ME02'
import Con_AreaDashboard_L3_ME04 from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L3_ME04'
import Con_AreaDashboard_L3_ME05 from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L3_ME05'
import Con_AreaDashboard_L3_MW01 from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L3_MW01'
import Con_AreaDashboard_L3_MW02 from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L3_MW02'
import Con_AreaDashboard_L3_MW04 from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L3_MW04'
import Con_AreaDashboard_L3_MW05 from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L3_MW05'
import Con_AreaDashboard_L3_Common from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L3_Common'
import Con_MoreBtn from      '../../09_Projects/Tortue/04_Construction/MoreBtn'
import Con_MoreBtn_ME02 from      '../../09_Projects/Tortue/04_Construction/MoreBtn_ME02'
import Con_MoreBtn_ME04 from      '../../09_Projects/Tortue/04_Construction/MoreBtn_ME04'
import Con_MoreBtn_ME05 from      '../../09_Projects/Tortue/04_Construction/MoreBtn_ME05'
import Con_MoreBtn_MW01 from      '../../09_Projects/Tortue/04_Construction/MoreBtn_MW01'
import Con_MoreBtn_MW02 from      '../../09_Projects/Tortue/04_Construction/MoreBtn_MW02'
import Con_MoreBtn_MW04 from      '../../09_Projects/Tortue/04_Construction/MoreBtn_MW04'
import Con_MoreBtn_MW05 from      '../../09_Projects/Tortue/04_Construction/MoreBtn_MW05'
import Con_AreaDashboardBarchart from      '../../09_Projects/Tortue/04_Construction/AreaDashboardBarchart'
import Con_LQCOM_OGM from      '../../09_Projects/Tortue/04_Construction/LQCOM_OGM'
import Con_LQCOM_OGM_Modal from      '../../09_Projects/Tortue/04_Construction/LQCOM_OGM_Modal'
import Con_LQCOM_ImgModal from      '../../09_Projects/Tortue/04_Construction/LQCOM_ImgModal'
import Con_LQCOM_Level from      '../../09_Projects/Tortue/04_Construction/LQCOM_Level'
import Con_LQCOM_OvBlockBar from      '../../09_Projects/Tortue/04_Construction/LQCOM_OvBlockBar'
import Con_LQCOM_OvEqBar from      '../../09_Projects/Tortue/04_Construction/LQCOM_OvEqBar'
import Con_LQCOM_OVIMG from      '../../09_Projects/Tortue/04_Construction/LQCOM_OVIMG'
import Con_HULLFORCOM_OVIMG from      '../../09_Projects/Tortue/04_Construction/HULLFORCOM_OVIMG'
import Con_HULLAFTCOM_OVIMG from      '../../09_Projects/Tortue/04_Construction/HULLAFTCOM_OVIMG'
import Con_LQCOM_Shop from      '../../09_Projects/Tortue/04_Construction/LQCOM_Shop'
import Con_LQCOM_Shop_Modal from      '../../09_Projects/Tortue/04_Construction/LQCOM_Shop_Modal'
import Con_LQCOM_ProgChart from      '../../09_Projects/Tortue/04_Construction/LQCOM_ProgChart'
import Con_SimpleRect from      '../../09_Projects/Tortue/04_Construction/SimpleRect'
import Con_ImagesBox from                 '../../09_Projects/Tortue/04_Construction/ImagesBox'
import Con_ImagesBox_6 from                 '../../09_Projects/Tortue/04_Construction/ImagesBox_6'
import Con_ImagesBox_8 from                 '../../09_Projects/Tortue/04_Construction/ImagesBox_8'
import Con_ModuleSummary from                 '../../09_Projects/Tortue/04_Construction/ModuleSummary'
import Con_PipingItem from                '../../09_Projects/Tortue/04_Construction/PipingItem'
import Con_PipingOverall from             '../../09_Projects/Tortue/04_Construction/PipingOverall'
import Con_DiscStatusTrackingIcon from    '../../09_Projects/Tortue/04_Construction/DiscStatusTrackingIcon'//piping
import Con_DiscStatusTrackingIcon_architecture from    '../../09_Projects/Tortue/04_Construction/DiscStatusTrackingIcon_architecture'
import Con_DiscStatusTrackingIcon_electrical from    '../../09_Projects/Tortue/04_Construction/DiscStatusTrackingIcon_electrical'
import Con_DiscStatusTrackingIcon_hvac from    '../../09_Projects/Tortue/04_Construction/DiscStatusTrackingIcon_hvac'
import Con_DiscStatusTrackingIcon_instrument from    '../../09_Projects/Tortue/04_Construction/DiscStatusTrackingIcon_instrument'
import Con_DiscStatusTrackingIcon_insulation from    '../../09_Projects/Tortue/04_Construction/DiscStatusTrackingIcon_insulation'
import Con_DiscStatusTrackingIcon_mechanical from    '../../09_Projects/Tortue/04_Construction/DiscStatusTrackingIcon_mechanical'
import Con_DiscStatusTrackingIcon_painting from    '../../09_Projects/Tortue/04_Construction/DiscStatusTrackingIcon_painting'
import Con_DiscStatusTrackingIcon_safety from    '../../09_Projects/Tortue/04_Construction/DiscStatusTrackingIcon_safety'
import Con_DiscStatusTrackingIcon_structure from    '../../09_Projects/Tortue/04_Construction/DiscStatusTrackingIcon_structure'
import Con_DiscItemSummary from    '../../09_Projects/Tortue/04_Construction/DiscItemSummary'
import Con_DiscItemSummaryModal from    '../../09_Projects/Tortue/04_Construction/DiscItemSummaryModal'
import Con_DiscStatusSummaryTopsides from    '../../09_Projects/Tortue/04_Construction/DiscStatusSummaryTopsides'
import Con_TopsideLevel from    '../../09_Projects/Tortue/04_Construction/TopsideLevel'
import Con_TopsideModule_ME01 from    '../../09_Projects/Tortue/04_Construction/TopsideModules_ME01'
import Con_BlockTable from    '../../09_Projects/Tortue/04_Construction/BlockTable'
import Con_HullCommonDashboard from    '../../09_Projects/Tortue/04_Construction/HullCommonDashboard'
import Con_HullCommonDivision from    '../../09_Projects/Tortue/04_Construction/HullCommonDivision'
import Con_CommoditySummary from    '../../09_Projects/Tortue/04_Construction/CommoditySummary'
import Con_CommoditySummaryHull from    '../../09_Projects/Tortue/04_Construction/CommoditySummaryHull'
import Con_CommoditySummaryTable from    '../../09_Projects/Tortue/04_Construction/CommoditySummaryTable'
import Con_DiscIconStatusChart from    '../../09_Projects/Tortue/04_Construction/DiscIconStatusChart'
import Con_TopsideModulesChartAll from    '../../09_Projects/Tortue/04_Construction/TopsideModulesChartAll'
import Con_IntegrationSummaryTablee from    '../../09_Projects/Tortue/04_Construction/IntegrationSummaryTablee'
import Con_IntegrationSummaryPie from    '../../09_Projects/Tortue/04_Construction/IntegrationSummaryPie'
import Con_PunchTable from    '../../09_Projects/Tortue/04_Construction/PunchTable'
import Con_DisciplineItem from    '../../09_Projects/Tortue/04_Construction/DisciplineItem'
import Con_WorkforceChart from    '../../09_Projects/Tortue/04_Construction/WorkforceChart'
import Con_DisciplineItemSchedule from    '../../09_Projects/Tortue/04_Construction/DisciplineItemSchedule'

// Quality Control
import QC_WeldingRepairTrendChart from    '../../09_Projects/Tortue/06_QualityControl/WeldingRepairTrendChart'
import QC_WeldingRepairSummaryUT from    '../../09_Projects/Tortue/06_QualityControl/WeldingRepairSummaryUT'
import QC_WeldingRepairSummaryRT from    '../../09_Projects/Tortue/06_QualityControl/WeldingRepairSummaryRT'
import QC_WeldingRepairSummaryPAUT from    '../../09_Projects/Tortue/06_QualityControl/WeldingRepairSummaryPAUT'
import QC_WeldingRepairSummaryMT from    '../../09_Projects/Tortue/06_QualityControl/WeldingRepairSummaryMT'
import QC_3DBlockDivision from    '../../09_Projects/Tortue/06_QualityControl/3DBlockDivision'
import QC_DashboardBarchart from    '../../09_Projects/Tortue/06_QualityControl/DashboardBarchart'


// System Completion
import SC_MCSkyline from              '../../09_Projects/Tortue/09_SystemCompletion/MC_Skyline'
import SC_MCSkylineRev from              '../../09_Projects/Tortue/09_SystemCompletion/MC_Skyline_Rev'
import SC_MCSkylineB32 from              '../../09_Projects/Tortue/09_SystemCompletion/MC_Skyline_B32'
import SC_WDSkyline from              '../../09_Projects/Tortue/09_SystemCompletion/WD_Skyline'
import SC_WDSkylineRev from              '../../09_Projects/Tortue/09_SystemCompletion/WD_SkylineRev'
import SC_WD_Skyline_B32 from              '../../09_Projects/Tortue/09_SystemCompletion/WD_Skyline_B32'
import SC_ElectricalSkyline from              '../../09_Projects/Tortue/09_SystemCompletion/ElectricalSkyline'
import SC_RfdcSkyline from              '../../09_Projects/Tortue/09_SystemCompletion/RFDC_Skyline'
import SC_RfdcSummary from              '../../09_Projects/Tortue/09_SystemCompletion/RFDC_Summary'
import SC_RfdcDonut from              '../../09_Projects/Tortue/09_SystemCompletion/RFDC_Donut'
import SC_WeeklySummaryDonut from              '../../09_Projects/Tortue/09_SystemCompletion/WeeklySummaryDonut'
import SC_RfdcAssociated from              '../../09_Projects/Tortue/09_SystemCompletion/RFDC_Associated'
import SC_WeeklyReport from              '../../09_Projects/Tortue/09_SystemCompletion/WeeklyReport'
import SC_PipingTestpackSkyline from              '../../09_Projects/Tortue/09_SystemCompletion/Piping_Testpack_Skyline'
import SC_MCSkyline_Home from              '../../09_Projects/Tortue/09_SystemCompletion/MC_Skyline_Home'
import SC_TestpackSummary from              '../../09_Projects/Tortue/09_SystemCompletion/TestpackSummary'
import SC_TestpackQCFSummary from              '../../09_Projects/Tortue/09_SystemCompletion/TestpackQCFSummary'
import SC_TestpackList from              '../../09_Projects/Tortue/09_SystemCompletion/TestpackList'
import SC_TestpackDownload from              '../../09_Projects/Tortue/09_SystemCompletion/downloadTestpack'
import SC_OverallMCDashboard from     '../../09_Projects/Tortue/09_SystemCompletion/OverallMCDashboard'
import SC_OvImage from      '../../09_Projects/Tortue/09_SystemCompletion/OverallImage'
import SC_TopsideSummary from      '../../09_Projects/Tortue/09_SystemCompletion/TopsideSummary'
import SC_PunchDiscipline from      '../../09_Projects/Tortue/09_SystemCompletion/PunchDiscipline'
import SC_PunchStatus from      '../../09_Projects/Tortue/09_SystemCompletion/PunchStatus'
import SC_PunchTable from      '../../09_Projects/Tortue/09_SystemCompletion/PunchTable'
import SC_PunchDiscRows from      '../../09_Projects/Tortue/09_SystemCompletion/PunchDisciplineRows'
import SC_PunchElectrical from      '../../09_Projects/Tortue/09_SystemCompletion/punch_electrical'
import SC_PunchMecandpip from      '../../09_Projects/Tortue/09_SystemCompletion/punch_mepi'
import SC_PunchOverall from      '../../09_Projects/Tortue/09_SystemCompletion/punch_overall'
import SC_OnlyPieChart from      '../../09_Projects/Tortue/09_SystemCompletion/MC_OnlyPieChart'
import SC_MC_PieChart from      '../../09_Projects/Tortue/09_SystemCompletion/MC_PieChart'
import SC_MC_OnlyPiePunchChart from      '../../09_Projects/Tortue/09_SystemCompletion/MC_OnlyPiePunchChart'
import SC_HomeOverallTable from      '../../09_Projects/Tortue/09_SystemCompletion/HomeOverallTable'
import SC_SkylineLegend from      '../../09_Projects/Tortue/09_SystemCompletion/SkylineLegend'
import SC_MC_SysStatus from      '../../09_Projects/Tortue/09_SystemCompletion/MC_SysStatus'
import SC_MC_SysStatus_Home from      '../../09_Projects/Tortue/09_SystemCompletion/MC_SysStatus_Home'
import SC_MC_Equipment_Comm from      '../../09_Projects/Tortue/09_SystemCompletion/EquipmentRoom_Commencement'
import SC_MC_Equipment_Pack from      '../../09_Projects/Tortue/09_SystemCompletion/EquipmentRoom_Package'
import SC_MC_SatCompletion from      '../../09_Projects/Tortue/09_SystemCompletion/SatCompletion'
import SC_MC_LoopTestSkyline from      '../../09_Projects/Tortue/09_SystemCompletion/LoopTestSkyline'
import SC_MC_KsgSkyline from      '../../09_Projects/Tortue/09_SystemCompletion/KsgSkyline'
import SC_MC_SoftwareSkyline from      '../../09_Projects/Tortue/09_SystemCompletion/SoftwareSkyline'
import SC_ICFTestSkyline from      '../../09_Projects/Tortue/09_SystemCompletion/ICFTestSkyline'
import SC_QCFSummary from      '../../09_Projects/Tortue/09_SystemCompletion/QCFSummary'
import SC_QCFSummary_A from      '../../09_Projects/Tortue/09_SystemCompletion/QCFSummary_A'
import SC_QCFTable from      '../../09_Projects/Tortue/09_SystemCompletion/QCFTable'
import SC_QCFSummary_ICFTEST from      '../../09_Projects/Tortue/09_SystemCompletion/QCFSummary_ICFTEST'
import SC_QCFTable_ICFTEST from      '../../09_Projects/Tortue/09_SystemCompletion/QCFTable_ICFTEST'
import SC_QCFSummary_A_ICFTEST from      '../../09_Projects/Tortue/09_SystemCompletion/QCFSummary_A_ICFTEST'
import SC_QCFTable_A_ICFTEST from      '../../09_Projects/Tortue/09_SystemCompletion/QCFTable_A_ICFTEST'
import SC_LeakTestSkyline from      '../../09_Projects/Tortue/09_SystemCompletion/LeakTestSkyline'
import SC_QCFSummary_CYBER from      '../../09_Projects/Tortue/09_SystemCompletion/QCFSummary_CYBER'
import SC_QCFTable_CYBER from      '../../09_Projects/Tortue/09_SystemCompletion/QCFTable_CYBER'
import SC_QCFSummary_A_CYBER from      '../../09_Projects/Tortue/09_SystemCompletion/QCFSummary_A_CYBER'
import SC_QCFTable_A_CYBER from      '../../09_Projects/Tortue/09_SystemCompletion/QCFTable_A_CYBER'
import SC_CyberSecuritySkyline from      '../../09_Projects/Tortue/09_SystemCompletion/CyberSecuritySkyline'
import SC_PccSkyline from      '../../09_Projects/Tortue/09_SystemCompletion/PccSkyline'
import SC_QCFSummary_TELECOMS from      '../../09_Projects/Tortue/09_SystemCompletion/QCFSummary_TELECOMS'
import SC_QCFTable_TELECOMS from      '../../09_Projects/Tortue/09_SystemCompletion/QCFTable_TELECOMS'
import SC_TelecomsSkyline from      '../../09_Projects/Tortue/09_SystemCompletion/TelecomsSkyline'
import SC_QCFSummary_SOFTWARE from      '../../09_Projects/Tortue/09_SystemCompletion/QCFSummary_SOFTWARE'
import SC_QCFTable_SOFTWARE from      '../../09_Projects/Tortue/09_SystemCompletion/QCFTable_SOFTWARE'
import SC_RFSU_IMAGE from '../../09_Projects/Tortue/09_SystemCompletion/RFSU_Image'
import SC_RFSU_Skyline from '../../09_Projects/Tortue/09_SystemCompletion/RFSU_Skyline'

import SC_QCFSummary_B_SOFTWARE from      '../../09_Projects/Tortue/09_SystemCompletion/QCFSummary_B_SOFTWARE'
import SC_QCFTable_B_SOFTWARE from      '../../09_Projects/Tortue/09_SystemCompletion/QCFTable_B_SOFTWARE'
import SC_WeektReportTable from      '../../09_Projects/Tortue/09_SystemCompletion/WeektReportTable'
import SC_WeeklyReportTable_MC from      '../../09_Projects/Tortue/09_SystemCompletion/WeeklyReportTable_MC'
import SC_WeeklyReportTableChart from      '../../09_Projects/Tortue/09_SystemCompletion/WeeklyReportTableChart'
import SC_QCFSummary_KSGA from      '../../09_Projects/Tortue/09_SystemCompletion/QCFSummary_KSGA'
import SC_QCFTable_KSGA from      '../../09_Projects/Tortue/09_SystemCompletion/QCFTable_KSGA'
import SC_QCFSummary_PCCA from      '../../09_Projects/Tortue/09_SystemCompletion/QCFSummary_PCC_A'
import SC_QCFSummary_PCCB from      '../../09_Projects/Tortue/09_SystemCompletion/QCFSummary_PCC_B'
import SC_QCFTable_PCCA from      '../../09_Projects/Tortue/09_SystemCompletion/QCFTable_PCC_A'
import SC_QCFTable_PCCB from      '../../09_Projects/Tortue/09_SystemCompletion/QCFTable_PCC_B'
import SC_Sys_MC_ITRB_MePi from      '../../09_Projects/Tortue/09_SystemCompletion/Sys_MC_ITRB'
import SC_Disc_Package from      '../../09_Projects/Tortue/09_SystemCompletion/Disc_Package'
import SC_WD_durationTable from      '../../09_Projects/Tortue/09_SystemCompletion/WD_durationTable'
import SC_ProgressCurve from      '../../09_Projects/Tortue/09_SystemCompletion/ProgressCurve'
import SC_ProgressCurveOverall from      '../../09_Projects/Tortue/09_SystemCompletion/ProgressCurveOverall'
import SC_MonthBarChart from      '../../09_Projects/Tortue/09_SystemCompletion/MonthBarChart'
import SC_WeeklyReportTable_WD from      '../../09_Projects/Tortue/09_SystemCompletion/WeeklyReportTable_WD'
import SC_MC_DB_ITRStatus_New from      '../../09_Projects/Tortue/09_SystemCompletion/MC_DB_ITRStatus_New'
import SC_MC_DB_ITRStatus_Boundary from      '../../09_Projects/Tortue/09_SystemCompletion/MC_DB_ITRStatus_Boundary'
import SC_PackageChart from      '../../09_Projects/Tortue/09_SystemCompletion/PackageChart'
import SC_CategoryChart from      '../../09_Projects/Tortue/09_SystemCompletion/CategoryChart'
import SC_BarTitle from      '../../09_Projects/Tortue/09_SystemCompletion/BarTitle'
import SC_LQPieChart from      '../../09_Projects/Tortue/09_SystemCompletion/LQPieChart'
import SC_LQTable from      '../../09_Projects/Tortue/09_SystemCompletion/LQTable'
import SC_LQRoom from      '../../09_Projects/Tortue/09_SystemCompletion/LQRoom'


import SC_CommissioningSkyline from   '../../09_Projects/MD2/09_SystemCompletion/CommissioningSkyline'
  
import SC_MC_DB_SysStatus from        '../../09_Projects/MD2/09_SystemCompletion/MC_DB_SysStatus'
import SC_MC_DB_ITRStatus from        '../../09_Projects/MD2/09_SystemCompletion/MC_DB_ITRStatus'
import SC_MC_DB_ITRStatus_B from        '../../09_Projects/MD2/09_SystemCompletion/MC_DB_ITRStatus_B'
import SC_MC_DB_PunchTrends from      '../../09_Projects/MD2/09_SystemCompletion/MC_DB_PunchTrends'
import SC_MC_DB_PunchTrends_NoChart from      '../../09_Projects/MD2/09_SystemCompletion/MC_DB_PunchTrends_noChart'
  
import SC_Table_Lookahead from        '../../09_Projects/MD2/09_SystemCompletion/Table_Lookahead'
import SC_Table_MostOpen from         '../../09_Projects/MD2/09_SystemCompletion/Table_MostOpen'
import SC_Table_PassedPunch from      '../../09_Projects/MD2/09_SystemCompletion/Table_PassedPunch'
  
import SC_Disc_DB_ITRStatus from      '../../09_Projects/MD2/09_SystemCompletion/Disc_DB_ITRStatus'
import SC_Disc_DB_Package from        '../../09_Projects/MD2/09_SystemCompletion/Disc_DB_Package'
  
import SC_SChart_ITR from             '../../09_Projects/MD2/09_SystemCompletion/SChart_ITR'
import SC_SChart_Punch from           '../../09_Projects/MD2/09_SystemCompletion/SChart_Punch'
import SC_Chart_Punch from            '../../09_Projects/MD2/09_SystemCompletion/Chart_Punch'
import SC_Punch_Disc from             '../../09_Projects/MD2/09_SystemCompletion/Punch_Discipline'
  
import SC_Sys_MC_Overall from         '../../09_Projects/MD2/09_SystemCompletion/Sys_MC_Overall'
import SC_Sys_MC_Overall_No_Desc from         '../../09_Projects/MD2/09_SystemCompletion/Sys_MC_Overall_No_Desc'
import SC_Sys_MC_Overall_B2 from         '../../09_Projects/MD2/09_SystemCompletion/Sys_MC_Overall_B2'
import SC_Sys_MC_Overall_AllPunch from         '../../09_Projects/MD2/09_SystemCompletion/Sys_MC_Overall_AllPunch'
import SC_Sys_MC_ITRA from            '../../09_Projects/MD2/09_SystemCompletion/Sys_MC_ITRA'
import SC_Sys_MC_ITRB from            '../../09_Projects/MD2/09_SystemCompletion/Sys_MC_ITRB'
import SC_Sys_MC_PunchChart from      '../../09_Projects/MD2/09_SystemCompletion/Sys_MC_PunchChart'
import SC_Sys_MC_PunchDisc from       '../../09_Projects/MD2/09_SystemCompletion/Sys_MC_PunchDisc'
import SC_Sys_SC_Overall from         '../../09_Projects/MD2/09_SystemCompletion/Sys_SC_Overall'
import SC_Sys_SC_ITRB from            '../../09_Projects/MD2/09_SystemCompletion/Sys_SC_ITRB'
import Sys_SC_Boundary from           '../../09_Projects/MD2/09_SystemCompletion/Sys_SC_Boundary'


import SC_Sys_DownloadBtn from         '../../09_Projects/MD2/09_SystemCompletion/Sys_DownloadBtn'
  
import SC_Reg_COISkyline from         '../../09_Projects/MD2/11_Regulatory/COI_Skyline'

// Commissioning  
import COM_OverallSummary from        '../../09_Projects/MD2/10_Commissioning/OverallSummary'
import COM_Procedures from            '../../09_Projects/MD2/10_Commissioning/Procedures'
import COM_ITR_B from                 '../../09_Projects/MD2/10_Commissioning/ITR_B'
import COM_Discipline from            '../../09_Projects/MD2/10_Commissioning/Discipline'

// Regulatory  
import RGT_COI_Skyline from           '../../09_Projects/MD2/11_Regulatory/COI_Skyline'
import RGT_Milestones from            '../../09_Projects/MD2/11_Regulatory/Milestones'
import RGT_OverallSummary from        '../../09_Projects/MD2/11_Regulatory/OverallSummary'
import RGT_RTP from                   '../../09_Projects/MD2/11_Regulatory/RTP'
import RGT_USCG from                  '../../09_Projects/MD2/11_Regulatory/USCG'

/* 
  ### Chart Customized - From the TCO Project ### 
*/
//Homepage
import Home_ModuleStatus from '../../09_Projects/TCO/00_Homepage/ModuleStatus'
import Home_OverallProgress from '../../09_Projects/TCO/00_Homepage/OverallProgress'
import Home_SailawayStatus from '../../09_Projects/TCO/00_Homepage/SailawayStatus'

//Planning
import Const_Milestones from '../../09_Projects/TCO/03_Planning/Milestones'






//------------------------------------------------------------------------------------------------ China1
import CH_Project_visionwheel from '../../09_Projects/Tortue/01_HSSE/visionWheel'
import CH_TaskSchedule from '../../09_Projects/China1/03_Planning/TaskScheduleImage'
import CH_LevelSchedule from '../../09_Projects/China1/03_Planning/level1scheduleImage'
import CH_Level2Schedule from '../../09_Projects/China1/03_Planning/Level2Schedule'
import CH_Level2SchedulePackage from '../../09_Projects/China1/03_Planning/Level2SchedulePackage'
import CH_LeadershipTeam from '../../09_Projects/China1/01_Project/leadershipTeam'
import CH_Project_yardDashbaord from '../../09_Projects/Tortue/01_HSSE/yardDashboard'

//01_Project
import CH_Project_vision from '../../09_Projects/China1/01_Project/vision'
import CH_ImageSVGDownload from '../../09_Projects/China1/01_Project/ImageSVGDownload'
import CH_ImageSVGDownload_GPPE from '../../09_Projects/China1/01_Project/ImageSVGDownload_GPPE'
import CH_ImageSVGDownload_LDPE from '../../09_Projects/China1/01_Project/ImageSVGDownload_LDPE'
import CH_ImageSVGDownload_P3 from '../../09_Projects/China1/01_Project/ImageSVGDownload_P3'
import CH_ImageSVGDownload_PP from '../../09_Projects/China1/01_Project/ImageSVGDownload_PP'

//00_Home
import CH_Project_yard from '../../09_Projects/China1/00_Home/Yard'
import CH_Project_SiteDashboard from '../../09_Projects/China1/00_Home/SiteDashboard'
import CH_MonthlyChart from '../../09_Projects/China1/00_Home/MonthlyChart'
import CH_MonthlyChart2 from '../../09_Projects/China1/00_Home/MonthlyChart2'
import CH_MonthlyChart3 from '../../09_Projects/China1/00_Home/MonthlyChart3'
import CH_MonthlyChartLegend4 from '../../09_Projects/China1/00_Home/MonthlyChartLegend4'
import CH_SSHETable from '../../09_Projects/China1/00_Home/SSHETable'
import CH_PTD from '../../09_Projects/China1/00_Home/PTD'
import CH_MOVE_BTN from '../../09_Projects/China1/00_Home/MoveButton'
import CH_InterfacePieChart from '../../09_Projects/China1/00_Home/InterfacePieChart'
import CH_HomePsrStackChart from '../../09_Projects/China1/00_Home/HomePsrStackChart' 
import CH_NormalTable from '../../09_Projects/China1/00_Home/NormalTable' 
import CH_ProgTable from '../../09_Projects/China1/00_Home/ProgTable' 
import CH_Pyramid from '../../09_Projects/China1/00_Home/Pyramid' 
import CH_SWSTable from '../../09_Projects/China1/00_Home/SWSTable' 
import CH_ScurveChart from '../../09_Projects/China1/00_Home/ScurveChart' 
import CH_FreeText from '../../09_Projects/China1/00_Home/FreeText' 
import CH_FreeText2 from '../../09_Projects/China1/00_Home/FreeText2' 
import CH_FreeText_2 from '../../09_Projects/China1/00_Home/FreeText_2' 
import CH_FreeText2_2 from '../../09_Projects/China1/00_Home/FreeText2_2' 
import CH_NormalNoData from '../../09_Projects/China1/00_Home/NormalNoData' 
import CH_ProgNoData from '../../09_Projects/China1/00_Home/ProgNoData' 

//03_Planning
import CH_LMS_Skyline from '../../09_Projects/China1/03_Planning/LeaderMilestone'
import CH_LMS_GanttChart from '../../09_Projects/China1/03_Planning/LMSGanttChart'
import CH_TeamOrganization from '../../09_Projects/China1/03_Planning/TeamOrganization'
import CH_PackageTable from '../../09_Projects/China1/03_Planning/PackageTable'
import CH_SummaryTable from '../../09_Projects/China1/03_Planning/SummaryTable'
import CH_ImagePageSvg from '../../09_Projects/China1/03_Planning/ImagePageSvg'
import CH_IcircleChart from '../../09_Projects/China1/03_Planning/icircleChart'

//02_Engineering
import CH_Interface from '../../09_Projects/China1/02_Engineering/Interface'
import CH_InterfaceTable from '../../09_Projects/China1/02_Engineering/InterfaceTable'
import CH_summaryTable from '../../09_Projects/China1/02_Engineering/summaryTable'
import CH_summaryTable_impact from '../../09_Projects/China1/02_Engineering/summaryTable_impact'
import CH_summaryTable_progress from '../../09_Projects/China1/02_Engineering/summaryTable_progress'
import CH_summaryTable_received from '../../09_Projects/China1/02_Engineering/summaryTable_received'
import CH_summaryTable_responded from '../../09_Projects/China1/02_Engineering/summaryTable_responded'
import CH_InterfaceInfo from '../../09_Projects/China1/02_Engineering/InterfaceInfo'
import CH_AreaInfo from '../../09_Projects/China1/02_Engineering/AreaInfo'
import CH_AreaTable from '../../09_Projects/China1/02_Engineering/AreaTable'
import CH_InterfaceRelationMap from '../../09_Projects/China1/02_Engineering/InterfaceRelationMap'
import CH_InterfaceTeam from '../../09_Projects/China1/02_Engineering/InterfaceTeam'
import CH_TQSummayChart from '../../09_Projects/China1/02_Engineering/TQSummayChart'
import CH_TQWKEndingChart from '../../09_Projects/China1/02_Engineering/TQWKEndingChart'
import CH_TQSummaryTable1 from '../../09_Projects/China1/02_Engineering/TQSummaryTable1'
import CH_TQSummaryTable2 from '../../09_Projects/China1/02_Engineering/TQSummaryTable2'
import CH_TQSummaryBox from '../../09_Projects/China1/02_Engineering/TQSummaryBox'
import CH_TQSummaryTable1_Origin from '../../09_Projects/China1/02_Engineering/TQSummaryTable1_Origin'
import CH_TQSummaryTable2_Origin from '../../09_Projects/China1/02_Engineering/TQSummaryTable2_Origin'
import CH_TQSummaryTable1_Package from '../../09_Projects/China1/02_Engineering/TQSummaryTable1_Package'
import CH_TQSummaryTable2_Package from '../../09_Projects/China1/02_Engineering/TQSummaryTable2_Package'
import CH_DEVSummaryBox from '../../09_Projects/China1/02_Engineering/DEVSummaryBox'
import CH_summaryTableDEV from '../../09_Projects/China1/02_Engineering/summaryTableDEV'
import CH_summaryTableDEV_impact from '../../09_Projects/China1/02_Engineering/summaryTableDEV_impact'
import CH_summaryTableDEV_progress from '../../09_Projects/China1/02_Engineering/summaryTableDEV_progress'
import CH_summaryTableDEV_received from '../../09_Projects/China1/02_Engineering/summaryTableDEV_received'
import CH_summaryTableDEV_responded from '../../09_Projects/China1/02_Engineering/summaryTableDEV_responded'
import CH_DEVSummaryTable1_Disc from '../../09_Projects/China1/02_Engineering/DEVSummaryTable1_Disc'
import CH_DEVSummaryTable2_Disc from '../../09_Projects/China1/02_Engineering/DEVSummaryTable2_Disc'
import CH_DEVSummaryTable1_Origin from '../../09_Projects/China1/02_Engineering/DEVSummaryTable1_Origin'
import CH_DEVSummaryTable2_Origin from '../../09_Projects/China1/02_Engineering/DEVSummaryTable2_Origin'
import CH_DEVSummaryTable1_Package from '../../09_Projects/China1/02_Engineering/DEVSummaryTable1_Package'
import CH_DEVSummaryTable2_Package from '../../09_Projects/China1/02_Engineering/DEVSummaryTable2_Package'
import CH_SatusTable from '../../09_Projects/China1/02_Engineering/SatusTable'
import CH_MDRCategoryChart from '../../09_Projects/China1/02_Engineering/MDRCategoryChart'
import CH_MDRStackChart from '../../09_Projects/China1/02_Engineering/MDRStackChart'
import CH_MDRSummaryTable from '../../09_Projects/China1/02_Engineering/MDRSummaryTable'
import CH_MDRSummaryTable_Aconex from '../../09_Projects/China1/02_Engineering/MDRSummaryTable_Aconex'
import CH_OverdueBarChart from '../../09_Projects/China1/02_Engineering/OverdueBarChart'
import CH_ReviewBarChart from '../../09_Projects/China1/02_Engineering/ReviewBarChart'
import CH_SupplierStackChart from '../../09_Projects/China1/02_Engineering/SupplierStackChart'
import CH_DocumentStackChart from '../../09_Projects/China1/02_Engineering/DocumentStackChart'
import CH_DocumentStepStackChart from '../../09_Projects/China1/02_Engineering/DocumentStepStackChart'
import CH_DocumentSummaryTable from '../../09_Projects/China1/02_Engineering/DocumentSummaryTable'
import CH_ColumnBarChart from '../../09_Projects/China1/02_Engineering/ColumnBarChart'
import CH_OneBarChart from '../../09_Projects/China1/02_Engineering/OneBarChart'
import CH_ColumnBarOneColor from '../../09_Projects/China1/02_Engineering/ColumnBarOneColor'
import CH_MDRCategoryChart_Disc from '../../09_Projects/China1/02_Engineering/MDRCategoryChart_Disc'
import CH_MDRCategoryChart_Sub from '../../09_Projects/China1/02_Engineering/MDRCategoryChart_Sub'
import CH_LSSummaryBox from '../../09_Projects/China1/02_Engineering/LSSummaryBox'
import CH_LSSummaryTable from '../../09_Projects/China1/02_Engineering/LSSummaryTable'
import CH_LSSummaryTable_Disc from '../../09_Projects/China1/02_Engineering/LSSummaryTable_Disc'
import CH_CategoryChart_ShortScroll from '../../09_Projects/China1/02_Engineering/CategoryChart_ShortScroll'



//04_Construction
import CH_Project_PillingTable from '../../09_Projects/China1/04_Construction/PillingTable'
import CH_Project_CivilTable from '../../09_Projects/China1/04_Construction/CivilTable'
import CH_Project_StatusComboChart from '../../09_Projects/China1/04_Construction/StatusComboChart'
import CH_HeavyLiftingProgram from '../../09_Projects/China1/04_Construction/HeavyLiftingProgram'
import CH_TextSummaryBox from '../../09_Projects/China1/04_Construction/TextSummaryBox'
import CH_HComboChart from '../../09_Projects/China1/04_Construction/HComboChart'
import CH_HSummaryTable from '../../09_Projects/China1/04_Construction/HSummaryTable'
import CH_HTable from '../../09_Projects/China1/04_Construction/HTable'
import CH_HTable_Dressup from '../../09_Projects/China1/04_Construction/HTable_Dressup'
import CH_HTable_Lifting from '../../09_Projects/China1/04_Construction/HTable_Lifting'
import CH_HeavyLiftSkyline from '../../09_Projects/China1/04_Construction/HeavyLiftSkyline'
import CH_HStackChart from '../../09_Projects/China1/04_Construction/HStackChart'
import CH_PunchTable from    '../../09_Projects/China1/04_Construction/PunchTable'
import CH_MajorStatus from    '../../09_Projects/China1/04_Construction/MajorStatus'
import CH_PhaseSummary from    '../../09_Projects/China1/04_Construction/PhaseSummary'

//05_Procurement
import CH_Project_PsrComboChart from '../../09_Projects/China1/05_Procurement/PsrComboChart'
import CH_Project_GanttChart from '../../09_Projects/China1/05_Procurement/GanttChart'
import CH_Project_PsrComboChart_DJS from '../../09_Projects/China1/05_Procurement/PsrComboChart_DJS'
import CH_Project_PsrComboChart_MR from '../../09_Projects/China1/05_Procurement/PsrComboChart_MR'
import CH_LOA from '../../09_Projects/China1/05_Procurement/LoaPage'
import CH_Project_PsrBarChart from '../../09_Projects/China1/05_Procurement/PsrBarChart'
import CH_Project_PsrStackChart from '../../09_Projects/China1/05_Procurement/PsrStackChart'
import CH_Project_RosTable from '../../09_Projects/China1/05_Procurement/RosTable'
import CH_EquipmentLayout from '../../09_Projects/China1/05_Procurement/EquipmentLayout_V1'
import CH_progressBarScroll from '../../09_Projects/China1/05_Procurement/progressBarScroll'

//06_QualityControl
import CH_JianLiVisualMap from '../../09_Projects/China1/06_QualityControl/JianLiVisualMap'
import CH_JianLiVisualMap_R from '../../09_Projects/China1/06_QualityControl/JianLiVisualMap_R'
import CH_SiteMapIamge from '../../09_Projects/China1/06_QualityControl/SiteMapIamge'
import CH_QCDashboardTable from '../../09_Projects/China1/06_QualityControl/QCDashboardTable'
import CH_QCDashboardTable_Con from '../../09_Projects/China1/06_QualityControl/QCDashboardTable_Con'
import CH_QCDashboardTable_Field from '../../09_Projects/China1/06_QualityControl/QCDashboardTable_Field'
import CH_QCDashboardTable_Weld from '../../09_Projects/China1/06_QualityControl/QCDashboardTable_Weld'
import CH_QCDashboradStackChart from '../../09_Projects/China1/06_QualityControl/QCDashboradStackChart'


//07_SystemCompletion
import CH_SCDMRTable from '../../09_Projects/China1/07_SystemCompletion/DMRTable'
import CH_SCMapKeyplan from '../../09_Projects/China1/07_SystemCompletion/Map'
import CH_SCPunchDiscipline from '../../09_Projects/China1/07_SystemCompletion/PunchDiscipline'
import CH_SCPunchStatus from '../../09_Projects/China1/07_SystemCompletion/PunchStatus'
import CH_SCMC_SysStatus from '../../09_Projects/China1/07_SystemCompletion/MC_SysStatus'
import CH_SCMC_SysStatus_Home from '../../09_Projects/China1/07_SystemCompletion/MC_SysStatus_Home'
import CH_SCMC_DB_ITRStatus from '../../09_Projects/China1/07_SystemCompletion/MC_DB_ITRStatus'
import CH_SCMC_DB_ITRStatus_B from '../../09_Projects/China1/07_SystemCompletion/MC_DB_ITRStatus_B'
import CH_SCMC_DB_ITRStatus_UD from '../../09_Projects/China1/07_SystemCompletion/MC_DB_ITRStatus_UD'
import CH_SC_Sys_SC_ITRB from '../../09_Projects/China1/07_SystemCompletion/Sys_SC_ITRB'

export default {
  // Common
  CMN_HistoryTable,
  CMN_TBD,
  CMN_Up2UText,
  CMN_3DViewerEmbedded,

  // System
  System_ChartOptions,
  System_Dropdown,
  System_SubMenu_01,

  //Homepage
  Home_ProjectProgress,
  Home_KeyMilestone,
  Home_Observation,
  Home_DataSource,
  Home_Milestones,
  Home_Milestones_R,
  
  Home_MC_Pie,
  Home_MC_SummaryProg,
  Home_MC_ITR,
  Home_MC_Punch,
  Home_MoreButton,
  Home_PriorityChart,
  Home_TubeChart,
  Home_WorkForceTable,

  Home_performanceLineChart,
  Home_performanceBarChart_ITR,
  Home_performanceLineChart_punch,
  Home_performanceBarChart_punch,
  Home_performanceCompletionChart,
  Home_mcskyline_performance,

  Home_performanceLineChart_B,
  Home_performanceBarChart_BITR,
  Home_performanceLineChart_Bpunch,
  Home_performanceBarChart_Bpunch,  
  Home_performanceCompletionChart_B,
  
  Home_ProceduresGauge,
  Home_ProceduresGauge_Daily,
  Home_SummaryTable,

  // HSSE
  HSSE_SafetyPyramid,
  HSSE_LSR,
  HSSE_TSC,
  HSSE_Dashboard,
  
  //Planning
  Plan_ProgressSummaryTable,
  Plan_ModuleSummarySchedule,
  Plan_CommoditySummarySchedule,
  Plan_SummaryByArea,
  Plan_ModuleKeyMilestones,
  TopsidesGanttChart,
  Plan_FilterPhase,
  Plan_SummaryByOutfittingCommodity,
  Plan_SpoolSummary,

  //Engineering
  Eng_CMDRStatus,
  Eng_IFCDrawingList,
  Eng_TotalPlanActualRemainL,
  Eng_TotalPlanActualRemainM,
  Eng_TotalPlanActualRemainS,
  Eng_CDREHistoryChart,
  Eng_TQHistoryChart,
  Eng_SDIDashboard,

  // Procurement
  Proc_EquipmentLayout,
  Proc_EquipmentTable,
  Proc_Equipment3DLayout,
  Proc_EquipmentRoom,
  Proc_ProgressBar,
  Proc_Lookahead,
  Proc_OWL,
  Proc_OverallScurve,
  Proc_FATScurve,
  Proc_IRNScurve,
  Proc_ETAScurve,
  Proc_IRNLookahead,
  Proc_ETALookahead,
  Proc_EquipmentHullCommon,
      
  // Construction
  Con_BlockDivision,
  Con_BlockSummary,
  Con_ActivityGanttChart,
  Con_ActivityGanttChart_R1,
  Con_ActivityGanttChartLegend,
  Con_MeasurementBlock,
  Con_ShopDrawingActivities,
  Con_ShopDrawingStatus,
  Con_ShopDrawingStatusOther,
  Con_OutfittingStatus,
  Con_OutfittingStatus_new,
  Con_Outfitting,
  Con_ErectionTimeline,
  Con_OverallDashboard,
  Con_OverallDashboardTopside,
  Con_OverallDashboardTopside_L,
  Con_OverallDashboardTopside_S,
  Con_OverallDashboardHull,
  Con_OverallDashboardHull_L,
  Con_OverallDashboardHull_S,
  Con_OverallDashboardModules_S,
  Con_OverallDashboardLQ_S,
  Con_OverallDashboardLQ,
  Con_ProgressSummaryOverall,
  Con_ProgressSummaryTopside,
  Con_ProgressSummaryHull,
  Con_OutfittingCommoditiesStatus,
  Con_OutfittingCommoditiesStatusL,
  Con_OgmDisc,
  Con_OgmDashboardHull,
  Con_OgmDashboardTopside,
  Con_SimpleText,
  Con_OutfittingCommStatus,
  Con_OutfittingCommStatus_S,
  Con_SpoolSummarySize,
  Con_SpoolSummarySize_S,
  Con_SpoolSummarySize_L,
  Con_SpoolSummaryArea,
  Con_AreaDashboard_Hull_AFT,
  Con_AreaDashboard_Hull_FOR,
  Con_AreaDashboard_L3_ME01,
  Con_AreaDashboard_L3_ME02,
  Con_AreaDashboard_L3_ME04,
  Con_AreaDashboard_L3_ME05,
  Con_AreaDashboard_L3_MW01,
  Con_AreaDashboard_L3_MW02,
  Con_AreaDashboard_L3_MW04,
  Con_AreaDashboard_L3_MW05,
  Con_MoreBtn,
  Con_MoreBtn_ME02,
  Con_MoreBtn_ME04,
  Con_MoreBtn_ME05,
  Con_MoreBtn_MW01,
  Con_MoreBtn_MW02,
  Con_MoreBtn_MW04,
  Con_MoreBtn_MW05,
  Con_AreaDashboardBarchart,
  Con_LQCOM_OGM,
  Con_LQCOM_OGM_Modal,
  Con_LQCOM_ImgModal,
  Con_LQCOM_Level,
  Con_LQCOM_OvBlockBar,
  Con_LQCOM_OvEqBar,
  Con_HULLFORCOM_OVIMG,
  Con_LQCOM_OVIMG,
  Con_HULLAFTCOM_OVIMG,
  Con_LQCOM_Shop,
  Con_LQCOM_Shop_Modal,
  Con_LQCOM_ProgChart,
  Con_SimpleRect,
  Con_ImagesBox,  
  Con_ImagesBox_6,
  Con_ImagesBox_8,
  Con_ModuleSummary,
  Con_PipingItem,   
  Con_PipingOverall,
  Con_DiscStatusTrackingIcon,
  Con_DiscStatusTrackingIcon_architecture,
  Con_DiscStatusTrackingIcon_electrical,
  Con_DiscStatusTrackingIcon_hvac,
  Con_DiscStatusTrackingIcon_instrument,
  Con_DiscStatusTrackingIcon_insulation,
  Con_DiscStatusTrackingIcon_mechanical,
  Con_DiscStatusTrackingIcon_painting,
  Con_DiscStatusTrackingIcon_safety,
  Con_DiscStatusTrackingIcon_structure,
  Con_DiscItemSummary,
  Con_DiscItemSummaryModal,
  Con_DiscStatusSummaryTopsides,
  Con_TopsideLevel,
  Con_TopsideModule_ME01,
  Con_BlockTable,
  Con_HullCommonDashboard,
  Con_HullCommonDivision,
  Con_AreaDashboard_Module,
  Con_AreaDashboard_Common,
  Con_AreaDashboard_L3_Common,
  Con_OverallDashboardModule_S,
  Con_OverallDashboardCommon_S,
  Con_CommoditySummary,
  Con_CommoditySummaryTable,
  Con_CommoditySummaryHull,
  Con_DiscIconStatusChart,
  Con_TopsideModulesChartAll,
  Con_IntegrationSummaryTablee,
  Con_IntegrationSummaryPie,
  Con_PunchTable,
  Con_DisciplineItem,
  Con_WorkforceChart,
  Con_DisciplineItemSchedule,
  
  // Quality Control
  QC_WeldingRepairTrendChart,
  QC_WeldingRepairSummaryUT,
  QC_WeldingRepairSummaryRT,
  QC_WeldingRepairSummaryPAUT,
  QC_WeldingRepairSummaryMT,
  QC_3DBlockDivision,
  QC_DashboardBarchart,
  
  // System Completion
  SC_MCSkyline,
  SC_MCSkylineRev,
  SC_MCSkylineB32,
  SC_WDSkyline,
  SC_WDSkylineRev,
  SC_WD_Skyline_B32,
  SC_ElectricalSkyline,
  SC_RfdcSkyline,
  SC_RfdcSummary,
  SC_RfdcDonut,
  SC_WeeklySummaryDonut,
  SC_RfdcAssociated,
  SC_WeeklyReport,
  SC_PipingTestpackSkyline,
  SC_MCSkyline_Home,
  SC_TestpackSummary,
  SC_TestpackQCFSummary,
  SC_TestpackList,
  SC_TestpackDownload,
  SC_OverallMCDashboard,
  SC_RFSU_IMAGE,
  SC_RFSU_Skyline,
  SC_OvImage,
  SC_TopsideSummary,
  SC_PunchDiscipline,
  SC_PunchStatus,
  SC_PunchTable,
  SC_PunchDiscRows,
  SC_PunchElectrical,
  SC_PunchMecandpip,
  SC_PunchOverall,
  SC_OnlyPieChart,
  SC_HomeOverallTable,
  SC_SkylineLegend,
  SC_MC_SysStatus, 
  SC_MC_SysStatus_Home,
  SC_MC_Equipment_Comm,
  SC_MC_Equipment_Pack,
  SC_MC_SatCompletion,
  SC_MC_LoopTestSkyline,
  SC_MC_KsgSkyline,
  SC_MC_SoftwareSkyline,
  SC_ICFTestSkyline,
  SC_QCFSummary,
  SC_QCFSummary_A,
  SC_QCFTable,
  SC_QCFSummary_ICFTEST,
  SC_QCFTable_ICFTEST,
  SC_QCFSummary_A_ICFTEST,
  SC_QCFTable_A_ICFTEST,
  SC_MC_PieChart,
  SC_MC_OnlyPiePunchChart,
  SC_LeakTestSkyline,
  SC_QCFSummary_CYBER,
  SC_QCFTable_CYBER,
  SC_QCFSummary_A_CYBER,
  SC_QCFTable_A_CYBER,
  SC_CyberSecuritySkyline,
  SC_PccSkyline,
  SC_QCFSummary_TELECOMS,
  SC_QCFTable_TELECOMS,
  SC_TelecomsSkyline,
  SC_QCFSummary_SOFTWARE,
  SC_QCFTable_SOFTWARE,
  SC_QCFSummary_B_SOFTWARE,
  SC_QCFTable_B_SOFTWARE,
  SC_WeektReportTable,
  SC_WeeklyReportTable_MC,
  SC_WeeklyReportTableChart,
  SC_QCFSummary_KSGA,
  SC_QCFTable_KSGA,
  SC_QCFSummary_PCCA,
  SC_QCFSummary_PCCB,
  SC_QCFTable_PCCA,
  SC_QCFTable_PCCB,
  SC_Sys_MC_ITRB_MePi,
  SC_Disc_Package,
  SC_WD_durationTable,
  SC_ProgressCurve,
  SC_ProgressCurveOverall,
  SC_MonthBarChart,
  SC_WeeklyReportTable_WD,
  SC_MC_DB_ITRStatus_New,
  SC_MC_DB_ITRStatus_Boundary,
  SC_PackageChart,
  SC_CategoryChart,     
  SC_BarTitle,
  SC_LQPieChart,
  SC_LQTable,
  SC_LQRoom,
  


  
  SC_CommissioningSkyline,
  SC_MC_DB_SysStatus,
  SC_MC_DB_ITRStatus,
  SC_MC_DB_ITRStatus_B,
  SC_MC_DB_PunchTrends,
  SC_MC_DB_PunchTrends_NoChart,

  SC_Table_Lookahead,
  SC_Table_MostOpen,
  SC_Table_PassedPunch,

  SC_Disc_DB_ITRStatus,
  SC_Disc_DB_Package,

  SC_SChart_ITR,
  SC_SChart_Punch,
  SC_Chart_Punch,
  SC_Punch_Disc,

  SC_Sys_MC_Overall,
  SC_Sys_MC_Overall_No_Desc,
  SC_Sys_MC_Overall_B2,
  SC_Sys_MC_Overall_AllPunch,
  SC_Sys_MC_ITRA,
  SC_Sys_MC_ITRB,
  SC_Sys_MC_PunchChart,
  SC_Sys_MC_PunchDisc,
  
  SC_Sys_SC_Overall,
  SC_Sys_SC_ITRB,
  Sys_SC_Boundary,

  SC_Sys_DownloadBtn,

  SC_Reg_COISkyline,



  // Commissioning
  COM_OverallSummary,
  COM_Procedures,
  COM_ITR_B,
  COM_Discipline,

  // Regulatory
  RGT_COI_Skyline,
  RGT_Milestones,
  RGT_OverallSummary,
  RGT_RTP,   
  RGT_USCG,  


  // ### For the Tortue Project
  // Planning
  Plan_ConstructionBlockSkyline,

  // ### From the TCO Project
  //Homepage
  Home_ModuleStatus,
  Home_OncomingSailaway,
  Home_OverallProgress,
  Home_OverallSummary,
  Home_SailawayStatus,
  Home_Yard,
  //Planning
  Const_Milestones,


  // --------------------------------------------------------------China1 Project
  CH_Project_visionwheel,
  CH_TaskSchedule,
  CH_Project_yardDashbaord,
  CH_LevelSchedule,
  CH_Level2Schedule,
  CH_Level2SchedulePackage,
  
  //01_Project
  CH_LeadershipTeam,
  CH_Project_vision,
  CH_LMS_Skyline,
  CH_LMS_GanttChart,
  CH_ImageSVGDownload,
  CH_ImageSVGDownload_GPPE,
  CH_ImageSVGDownload_LDPE,
  CH_ImageSVGDownload_P3,
  CH_ImageSVGDownload_PP,
  CH_IcircleChart,

  
  //00_Home
  CH_Project_yard,
  CH_Project_SiteDashboard,
  CH_MonthlyChart,
  CH_MonthlyChart2,
  CH_MonthlyChart3,
  CH_MonthlyChartLegend4,
  CH_SSHETable,
  CH_PTD,
  CH_MOVE_BTN,
  CH_InterfacePieChart,
  CH_HomePsrStackChart,
  CH_NormalTable,
  CH_ProgTable,
  CH_Pyramid,
  CH_SWSTable,
  CH_ScurveChart,
  CH_FreeText,
  CH_FreeText2,
  CH_FreeText_2, 
  CH_FreeText2_2,
  CH_NormalNoData,
  CH_ProgNoData,
  
  //02_Engineering
  CH_Interface,
  CH_InterfaceTable,
  CH_summaryTable,
  CH_summaryTable_impact,
  CH_summaryTable_progress,
  CH_summaryTable_received,
  CH_summaryTable_responded,
  CH_InterfaceInfo,
  CH_AreaInfo,
  CH_AreaTable,
  CH_InterfaceRelationMap,
  CH_InterfaceTeam,
  CH_TQSummayChart,
  CH_TQWKEndingChart,
  CH_TQSummaryTable1,
  CH_TQSummaryTable2,
  CH_TQSummaryBox,
  CH_TQSummaryTable1_Origin, 
  CH_TQSummaryTable2_Origin,
  CH_TQSummaryTable1_Package,
  CH_TQSummaryTable2_Package,
  CH_DEVSummaryBox,
  CH_summaryTableDEV,
  CH_summaryTableDEV_impact,
  CH_summaryTableDEV_progress, 
  CH_summaryTableDEV_received, 
  CH_summaryTableDEV_responded,
  CH_DEVSummaryTable1_Disc,
  CH_DEVSummaryTable2_Disc,
  CH_DEVSummaryTable1_Origin, 
  CH_DEVSummaryTable2_Origin,
  CH_DEVSummaryTable1_Package,
  CH_DEVSummaryTable2_Package,
  CH_SatusTable,
  CH_MDRCategoryChart,
  CH_MDRStackChart,
  CH_MDRSummaryTable,
  CH_MDRSummaryTable_Aconex,
  CH_OverdueBarChart,
  CH_ReviewBarChart,
  CH_SupplierStackChart,
  CH_DocumentStackChart,
  CH_DocumentStepStackChart,
  CH_DocumentSummaryTable,
  CH_ColumnBarChart,
  CH_OneBarChart,
  CH_ColumnBarOneColor,
  CH_MDRCategoryChart_Disc,
  CH_MDRCategoryChart_Sub,
  CH_LSSummaryBox,
  CH_LSSummaryTable,
  CH_LSSummaryTable_Disc,


  //Planning
  CH_TeamOrganization,
  CH_PackageTable,
  CH_SummaryTable,
  CH_ImagePageSvg,
  
  //04_Construction
  CH_Project_PillingTable,
  CH_Project_CivilTable,
  CH_Project_StatusComboChart,
  CH_HeavyLiftingProgram,
  CH_TextSummaryBox,
  CH_HComboChart,
  CH_HSummaryTable,
  CH_HTable,
  CH_HTable_Dressup,
  CH_HTable_Lifting,
  CH_HeavyLiftSkyline,
  CH_HStackChart,
  CH_PunchTable,
  CH_CategoryChart_ShortScroll,
  CH_MajorStatus, 
  CH_PhaseSummary,

  
  //05_Procurement
  CH_Project_PsrComboChart,
  CH_Project_GanttChart,
  CH_LOA,
  CH_Project_PsrComboChart_DJS,
  CH_Project_PsrBarChart,
  CH_Project_PsrStackChart,
  CH_Project_RosTable,
  CH_Project_PsrComboChart_MR,
  CH_EquipmentLayout,
  CH_progressBarScroll,

  
  
  //06_QualityControl
  CH_JianLiVisualMap,
  CH_JianLiVisualMap_R,
  CH_SiteMapIamge,
  CH_QCDashboardTable,
  CH_QCDashboardTable_Con,
  CH_QCDashboardTable_Field,
  CH_QCDashboardTable_Weld, 
  CH_QCDashboradStackChart, 





  //07_SystemCompletion
  CH_SCDMRTable,
  CH_SCMapKeyplan,
  CH_SCPunchDiscipline,
  CH_SCPunchStatus,
  CH_SCMC_SysStatus, 
  CH_SCMC_SysStatus_Home,
  CH_SCMC_DB_ITRStatus,
  CH_SCMC_DB_ITRStatus_B,
  CH_SCMC_DB_ITRStatus_UD,
  CH_SC_Sys_SC_ITRB,
}
