import axios from 'axios'
// import requestHeader from './request.header'
import url from './request.url'

export default class AccountApi {
    
  constructor() {
  }

  signin(creds, callback) {
    return axios.post(url.application.account.signin, creds).then(res => {
      callback(!!res.data ? res.data : {})
    }).catch(error => {
      if(!!error && !!error.response) {
        callback(!!error.response.data ? error.response.data : {})
      } else {
        callback({})
      }
    })
  }
}
