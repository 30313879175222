export default {
  width: 150,
  gab: 10,
  attrs: [
    { 
      text1: 'Total Subsystems',
      text2: 'with Loops',
      refColumn: 'TOTAL',
      codeValue   : 'Total', // fixed
      fill : 'lightGray',
      fColor: 'Transparent', 
      tColor: '#212121',
      on: true
    },
    { 
      text1: 'Subsystems with',
      text2: 'Loops and No MC Started',
      refColumn: 'NOTSTARTED',
      codeValue   : 'Subsystems with Loops and No MC Started',
      fill : 'fillWhite',
      fColor: '#BDBCBC', 
      tColor: '#818181',
      on: true
    },
    { 
      text1: 'Subsystems with Loops & Loop',
      text2: 'Prerequisite MC Started',
      refColumn: 'APROGRESS',
      codeValue   : 'Subsystems with Loops and Loop Prerequisite MC Started',
      fill : 'fillYellow',
      fColor: '#FFD600',
      tColor: '#fff',
      on: true
    },
    { 
      text1: 'Subsystems with Loops and',
      text2: 'Loop Prerequisite MC Complete',
      refColumn: 'ACOMPLETED',
      codeValue   : 'Subsystems with Loops and Loop Prerequisite MC Complete',
      fill : 'fillLightBlue',
      fColor: '#81D4FA',
      tColor: '#fff',
      on: true
    },
    { 
      text1: 'Subsystems with Loops',
      text2: 'and Partial MC Complete',
      refColumn: 'PAR_LOOPTEST',
      codeValue   : 'Subsystems with Loops and Partial MC Complete',
      fill : 'fillLightGreen',
      fColor: '#8BC34A',
      tColor: '#fff',
      on: true
    },
    { 
      text1: 'Subsystems with Loops',
      text2: 'and Full MC Complte',
      refColumn: 'LOOPTEST',
      codeValue   : 'Subsystems with Loops and Full MC Complete',
      fill : 'fillGreen',
      fColor: '#43A047',
      tColor: '#fff',
      on: true
    }
  ]
}
