import * as d3 from 'd3'

export default {
  methods: {

    draw_ITR_Chart(selection, unit, d_) {
      
      let chartBase = this.colHeight-60
      let chtWidth = 220

      let ITRChart = selection
      .append('g')
      .attr('transform', `translate(${d_.x + 25}, ${d_.y - chartBase})`)
      .attr('font-family', 'Segoe UI')

      ITRChart
      .append('text')
      .attr('x', 110).attr('y', -22)
      .attr('font-size', 11).attr('fill', '#333').attr('text-anchor', 'middle')
      .text(`${d_.item} CheckSheets`)

      ITRChart
      .append('text')
      .attr('x', 110).attr('y', -7)
      .attr('font-size', 9).attr('fill', '#bcbcbc').attr('text-anchor', 'middle')
      .text(`Rundown Chart`)

      ITRChart
      .append('text')
      .attr('id', `NoData_${d_.idx}_${d_.item}`)
      .attr('x', 110).attr('y', 50)
      .attr('font-size', 15).attr('fill', '#757575').attr('text-anchor', 'middle')
      .text('No data to display').attr('opacity', 0)


      let data = this.Rundown.filter(f=> f.UNIT == unit.UNIT && f.SUBSYS_NO == 'OV' && f.ITEM == d_.item)

      // Scale X -----------------------------------------------------------------------
      this.scaleX = d3.scaleBand()
      .domain(data.map(d => d.CDATE ))
      .range([0, 200])

      ITRChart
      .append('line')
      .attr('x1', 0).attr('y1', chartBase).attr('x2', chtWidth).attr('y2', chartBase)
      .attr('stroke', '#bcbcbc').attr('stroke-width', 1).attr('stroke-opacity', .3)


      // Primary -----------------------------------------------------------------------
      let maxPrimary = this.getMaxBound_v2(data[0].TOTAL)
      this.scalePrimary = d3.scaleLinear()
      .domain([0, maxPrimary])
      .range([chartBase, 0])
      
      ITRChart
      .append('line')
      .attr('x1', 0).attr('y1', 0).attr('x2', 0).attr('y2', chartBase)
      .attr('stroke', '#bcbcbc').attr('stroke-width', 1).attr('stroke-opacity', .3)

      this.Grids.forEach((d,i)=> {
        ITRChart
        .append('line')
        .attr('x1', -3).attr('x2', 0).attr('y1', d).attr('y2', d)
        .attr('stroke', '#bcbcbc').attr('stroke-width', .5)

        ITRChart
        .append('text')
        .attr('id', `Primary_${d_.idx}_${d_.item}_${i}`)
        .attr('transform', `translate(${-4}, ${d + 1})`)
        .attr('font-size', 7).attr('opacity', 1)
        .attr('fill', '#000').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text(((maxPrimary) * (i*2)/10))
      })



      // Secondary -----------------------------------------------------------------------
      let maxSecondary =  this.getMaxBound_v2(d3.max(data, c => c.CLOSED_WK))
      this.scaleSecondary = d3.scaleLinear()
      .domain([0, maxSecondary])
      .range([chartBase, 0])
      
      ITRChart
      .append('line')
      .attr('x1', chtWidth).attr('y1', 0).attr('x2', chtWidth).attr('y2', chartBase)
      .attr('stroke', '#bcbcbc').attr('stroke-width', 1).attr('stroke-opacity', .3)

      this.Grids.forEach((d,i)=> {
        ITRChart
        .append('line')
        .attr('x1', chtWidth+3).attr('x2', chtWidth)
        .attr('y1', d).attr('y2', d)
        .attr('stroke', '#bcbcbc').attr('stroke-width', .5)

        ITRChart
        .append('text')
        .attr('id', `Secondary_${d_.idx}_${d_.item}_${i}`)
        .attr('transform', `translate(${chtWidth+4}, ${d + 1})`)
        .attr('font-size', 7).attr('opacity', 1)
        .attr('fill', '#757575').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text(((maxSecondary) * (i*2)/10))
      })


      // Bar Chart -----------------------------------------------------------------------
      data.forEach((d,i)=> {
        ITRChart
        .append('rect')
        .attr('id', `Bar_${d_.idx}_${d_.item}_${i}`)
        .attr('x', this.scaleX(d.CDATE) + 2.5)
        .attr('y', this.scaleSecondary(d.CLOSED_WK))
        .attr('height', chartBase - this.scaleSecondary(d.CLOSED_WK))
        .attr('width', 5)
        .attr('fill', '#E0E0DF').attr('opacity', .5)
      })
      

      // Line Chart -----------------------------------------------------------------------
      let last_Date = null
      let last_Cum = null

      let lineFunc = d3.line()
      .x(d => this.scaleX(d.CDATE) + 5)
      .y(d => this.scalePrimary(d.REMAIN_CUM))

      ITRChart
      .append('g')
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')

      ITRChart
      .append('path')
      .attr('id', `Line_${d_.idx}_${d_.item}`)
      .attr('d', lineFunc(data))
      .attr('stroke', d_.color).attr('stroke-width', 1)
      .style('fill', 'none').attr('opacity', 1)

      ITRChart
      .selectAll(`circle_`)
      .data(data)
      .enter()
      .append('circle')
      .attr('id', (d,i)=> `Line_${d_.idx}_${d_.item}_${i}`)
      .attr('cx', d => {
        last_Date = this.scaleX(d.CDATE)
        return this.scaleX(d.CDATE) + 5
      })
      .attr('cy', d=> {
        last_Cum = this.scalePrimary(d.REMAIN_CUM)
        return this.scalePrimary(d.REMAIN_CUM)
      })
      .attr('r', 1.5)
      .attr('stroke', d_.color).attr('stroke-width', 1).attr('stroke-opacity', 1)
      .attr('fill', '#fff').attr('opacity', 1)

      // Last Remaining
      ITRChart
      .append('text')
      .attr('id', `Remain_${d_.idx}_${d_.item}`)
      .attr('x', last_Date + 10)
      .attr('y', last_Cum + 3)
      .attr('font-size', 10).attr('fill', '#333').attr('text-anchor', 'start')
      .text(unit[`${d_.item}_REM`])
    },

  }
}