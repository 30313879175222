import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {

    
    Draw_Charts() {
      
      let data = null, style = null, item = null

      let overallSummary = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0.5)`)

      overallSummary
      .append('line')
      .attr('x1',380).attr('x2', 380).attr('y1',40).attr('y2', 220).attr('stroke', '#bcbcbc').attr('stroke-width', 0.2)

      overallSummary
      .append('line')
      .attr('x1',660).attr('x2', 660).attr('y1',40).attr('y2', 220).attr('stroke', '#bcbcbc').attr('stroke-width', 0.2)

      overallSummary
      .append('line')
      .attr('x1',940).attr('x2', 940).attr('y1',40).attr('y2', 220).attr('stroke', '#bcbcbc').attr('stroke-width', 0.2)




        let weekQuantity = this.svg
        .append('g')
        .attr('transform', `translate(0.5, 0.5)`)

        weekQuantity
        .append('text')
        .attr('transform', `translate(30, 80)`)
        .style('font-family', 'roboto').style('font-size', 24).style('fill', '#333').attr('text-anchor', 'start')
        .text('Dashboard')

        weekQuantity
        .append('text')
        .attr('transform', `translate(30, 95)`)
        .style('font-family', 'roboto').style('font-size', 12).style('fill', '#bcbcbc').attr('text-anchor', 'start')
        .text('Commissioning Overall Dashboard')

        weekQuantity
        .append('text')
        .attr('transform', `translate(30, 180)`)
        .style('font-family', 'roboto').style('font-size', 12).style('fill', '#333').attr('text-anchor', 'start')
        .text('MCs')

        weekQuantity
        .append('text')
        .attr('transform', `translate(30, 200)`)
        .style('font-family', 'roboto').style('font-size', 12).style('fill', '#333').attr('text-anchor', 'start')
        .text('RFDCs')
        
          // WeekPerformance_01
          data = this.dataSet.filter(f=> f.TYPE == 'MC')
          style = {
                    x: 90,
                    y: 140,
          }
          data.forEach(d=> {
            d.date = d.DATE
            if(d.DISC =='LWK' || d.DISC =='TWK') d.name = 'Total'; else d.name = d.DISC
            d.value = d.ACTUAL
          })
          this.WeekPerformance_01(weekQuantity, data, style) // ---------------------------> 

          // WeekPerformance_03
          data = this.dataSet.filter(f=> f.TYPE == 'RFDC')
          style = {
                    x: 90,
                    y: 160,
          }
          data.forEach(d=> {
            d.date = d.DATE
            if(d.DISC =='LWK' || d.DISC =='TWK') d.name = 'Total'; else d.name = d.DISC
            d.value = d.ACTUAL
          })
          this.WeekPerformance_03(weekQuantity, data, style) // ---------------------------> 

      


        //MCs Status ----------------------------------------------------------------------------------------------------------------------------------
        let McStatus = this.svg
        .append('g')
        .attr('transform', `translate(425.5, 0.5)`)

          // Header_01
          data  = this.Queries.SQL1.find(f=> f.TYPE == 'MC')
          style = {
            title: 'MCs',
            x: 0,
            y: 40,
            width: 40,
            height: 18,
            radius: 3,
            tSize: 11,
            tColor: '#333',
            bColor: '#E0E0DF',
            progSize: 15,
          }
          this.Header_01(McStatus, data.PROG, style) // ---------------------------> 

          McStatus
          .append('text')
          .attr('transform', `translate(${0}, ${200})`)
          .style('font-family', 'roboto').style('font-size', 10).style('fill', '#bcbcbc')
          .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
          .text('Final MC')

          McStatus
          .append('text')
          .attr('transform', `translate(${100}, ${200})`)
          .style('font-family', 'roboto').style('font-size', 10).style('fill', '#bcbcbc')
          .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
          .text('Forecast Final MC')

          McStatus
          .append('text')
          .attr('transform', `translate(${0}, ${215})`)
          .style('font-family', 'roboto').style('font-size', 12).style('fill', '#333')
          .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
          .text(data.PLAN)

          McStatus
          .append('text')
          .attr('transform', `translate(${100}, ${215})`)
          .style('font-family', 'roboto').style('font-size', 12).style('fill', '#333')
          .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
          .text(data.FORECAST)


          // SummaryTable_01
          item = this.Queries.SQL1.find(f=> f.TYPE == 'MC')
          style = {
            id:       'MC',
            title:    ['Total', 'Closed', 'Remain'],
            cell:     [40, 90, 140],
            x:        0,
            y:        60,
            y1:       10,
            y2:       23,
            width:    155,
            height:   32,
            radius:   3,
            opacity:  0.7,
            bColor:   '#83D2F5',
            tSize:    9,
            tColor:   '#757575',
            vSize:    11,
            vColor:   '#fff',
            value:    [item.TOTAL, item.ACTUAL, item.REMAIN]
          }
          this.SummaryTable_01(McStatus, style) // ---------------------------> 


          // Bar_Quantity_03
          data  = this.Queries.SQL2.filter(f=> f.TYPE == 'MC')
          data = data.map(d => d.WEEK)

          style = {
            x: 0,
            y: 170,
            barWidth: 10,
            barHeight: 50,
            barSpace: 7,
            tSize: 9,
            tColor: '#757575',
            bColor: '#E0E0DF',
            sColor: '#BDBCBC',
            sWidth: 0.3,
          }
          this.Bar_Quantity_03(McStatus, data, style) // ---------------------------> 

          



          //RFDS Status ----------------------------------------------------------------------------------------------------------------------------------
          let RfdcStatus = this.svg
          .append('g')
          .attr('transform', `translate(705.5, 0.5)`)
  
            // Header_01
            data  = this.Queries.SQL1.find(f=> f.TYPE == 'RFDC')
            style = {
              title: 'RFDCs',
              x: 0,
              y: 40,
              width: 50,
              height: 18,
              radius: 3,
              tSize: 11,
              tColor: '#333',
              bColor: '#E0E0DF',
              progSize: 15,
            }
            this.Header_01(RfdcStatus, data.PROG, style) // ---------------------------> 
            
            RfdcStatus
            .append('text')
            .attr('transform', `translate(${0}, ${200})`)
            .style('font-family', 'roboto').style('font-size', 10).style('fill', '#bcbcbc')
            .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
            .text('Final MC')

            RfdcStatus
            .append('text')
            .attr('transform', `translate(${100}, ${200})`)
            .style('font-family', 'roboto').style('font-size', 10).style('fill', '#bcbcbc')
            .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
            .text('Forecast Final MC')

            RfdcStatus
            .append('text')
            .attr('transform', `translate(${0}, ${215})`)
            .style('font-family', 'roboto').style('font-size', 12).style('fill', '#333')
            .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
            .text(data.PLAN)

            RfdcStatus
            .append('text')
            .attr('transform', `translate(${100}, ${215})`)
            .style('font-family', 'roboto').style('font-size', 12).style('fill', '#333')
            .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
            .text(data.FORECAST)
  
            // SummaryTable_01
            item = this.Queries.SQL1.find(f=> f.TYPE == 'RFDC')
            style = {
              id:       'MC',
              title:    ['Total', 'Closed', 'Remain'],
              cell:     [40, 90, 140],
              x:        0,
              y:        60,
              y1:       10,
              y2:       23,
              width:    155,
              height:   32,
              radius:   3,
              opacity:  0.7,
              bColor:   '#83D2F5',
              tSize:    9,
              tColor:   '#757575',
              vSize:    11,
              vColor:   '#fff',
              value:    [item.TOTAL, item.ACTUAL, item.REMAIN]
            }
            this.SummaryTable_01(RfdcStatus, style) // ---------------------------> 
  
  
            // Bar_Quantity_03
            data  = this.Queries.SQL2.filter(f=> f.TYPE == 'RFDC')
            data = data.map(d => d.WEEK)
  
            style = {
              x: 0,
              y: 170,
              barWidth: 10,
              barHeight: 50,
              barSpace: 7,
              tSize: 9,
              tColor: '#757575',
              bColor: '#E0E0DF',
              sColor: '#BDBCBC',
              sWidth: 0.3,
            }
            this.Bar_Quantity_03(RfdcStatus, data, style) // --------------------------->


          //RFDS Status ----------------------------------------------------------------------------------------------------------------------------------
          let OrcStatus = this.svg
          .append('g')
          .attr('transform', `translate(970.5, 0.5)`)
  
            data = this.Queries.SQL1.find(f=> f.TYPE == 'ORC')

            OrcStatus
            .append('text')
            .attr('transform', `translate(${0}, ${50})`)
            .style('font-family', 'roboto').style('font-size', 14).style('fill', '#333')
            .attr('text-anchor', 'start').attr('alignment-baseline', 'middle').text('Certification Status ORCs')

            OrcStatus
            .append('text')
            .attr('id', 'ORC_total')
            .attr('transform', `translate(${0}, ${70})`)
            .style('font-family', 'roboto').style('font-size', 10).style('fill', '#bcbcbc')
            .attr('text-anchor', 'start').attr('alignment-baseline', 'alphabetic')
            .text('Total')

            OrcStatus
            .append('text')
            .attr('transform', `translate(${this.getNodeElValue('#ORC_total', 'width') + 2}, ${70})`)
            .style('font-family', 'roboto').style('font-size', 12).style('fill', '#44A9DF')
            .attr('text-anchor', 'start').attr('alignment-baseline', 'alphabetic')
            .text(data.TOTAL)

            OrcStatus
            .append('text')
            .attr('id', 'ORC_closed')
            .attr('transform', `translate(${70}, ${70})`)
            .style('font-family', 'roboto').style('font-size', 10).style('fill', '#bcbcbc')
            .attr('text-anchor', 'start').attr('alignment-baseline', 'alphabetic')
            .text('Closed')

            OrcStatus
            .append('text')
            .attr('transform', `translate(${70 + this.getNodeElValue('#ORC_closed', 'width') + 2}, ${70})`)
            .style('font-family', 'roboto').style('font-size', 12).style('fill', '#44A9DF')
            .attr('text-anchor', 'start').attr('alignment-baseline', 'alphabetic')
            .text(data.ACTUAL)

            OrcStatus
            .append('text')
            .attr('id', 'ORC_remain')
            .attr('transform', `translate(${140}, ${70})`)
            .style('font-family', 'roboto').style('font-size', 10).style('fill', '#bcbcbc')
            .attr('text-anchor', 'start').attr('alignment-baseline', 'alphabetic')
            .text('Remain')

            OrcStatus
            .append('text')
            .attr('transform', `translate(${140 + this.getNodeElValue('#ORC_closed', 'width') + 2}, ${70})`)
            .style('font-family', 'roboto').style('font-size', 12).style('fill', '#44A9DF')
            .attr('text-anchor', 'start').attr('alignment-baseline', 'alphabetic')
            .text(data.REMAIN)




            OrcStatus
            .append('text')
            .attr('transform', `translate(${0}, ${100})`)
            .style('font-family', 'roboto').style('font-size', 10).style('fill', '#bcbcbc')
            .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
            .text('Final MC')

            OrcStatus
            .append('text')
            .attr('transform', `translate(${100}, ${100})`)
            .style('font-family', 'roboto').style('font-size', 10).style('fill', '#bcbcbc')
            .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
            .text('Forecast Final MC')

            OrcStatus
            .append('text')
            .attr('transform', `translate(${0}, ${115})`)
            .style('font-family', 'roboto').style('font-size', 12).style('fill', '#333')
            .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
            .text(data.PLAN)

            OrcStatus
            .append('text')
            .attr('transform', `translate(${100}, ${115})`)
            .style('font-family', 'roboto').style('font-size', 12).style('fill', '#333')
            .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
            .text(data.FORECAST)

    },
  }
}
           