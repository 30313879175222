import * as d3 from 'd3'

export default {
  methods: {
    /*
      draw_Toggle(selection) - Drawing boggle button
      toggle_Status(status) - Action toggle button
      toggle_Action(status) - Animation for toggle button 
    */

    draw_Toggle(selection) {
      let styleStatus = this.Toggle.status.toLowerCase()
      let style = this.Toggle
      selection
      .append('rect')
      .attr('transform', `translate(${style.labelWidth}, 0)`)
      .attr('class', 'tagbtn_bg')
      .attr('width', 40).attr('height', 18)
      .attr('fill', style[styleStatus].bcolor)
      .attr('rx', '10')

      selection
      .append('circle')
      .attr('class', 'tagbtn_slider')
      .attr('transform', `translate(${style[styleStatus].slidex}, ${style[styleStatus].slidey})`)
      .attr("r", 6).attr('fill', '#fff')

      selection
      .append('text')
      .attr('class', 'tagbtn_text')
      .attr('transform', `translate(${style[styleStatus].textx}, ${style[styleStatus].texty})`)
      .style('fill', style[styleStatus].tcolor).style('alignment-baseline', 'middle')
      .text(this.Toggle.status)

      selection
      .append('text')
      .attr('transform', `translate(-45, 9)`)
      .style('text-anchor', 'start').style('alignment-baseline', 'middle')
      .style('fill', '#000')
      .text('English/中文')

      selection.on('click', () => { 
        (this.Toggle.status == 'EN') ? this.Toggle.status = 'CN' : this.Toggle.status = 'EN'
        this.toggle_Status(this.Toggle.status) // ---> below

        this.selectedItems = { 
          ...this.selectedItems,
          lang: this.Toggle.status 
        }
      })
    },


    toggle_Status(status) { 

      d3.selectAll(`[class*="AREATEXT_"]`).each((_, i, a) => {
        let name = d3.select(a[i]).attr('class').split('_')
        let data = this.Queries.SQL1.find(d => d.NO === parseInt(name[1]) + 1)
        if(data) {
          if(status == 'EN') {
            d3.select(a[i]).text(`${data.NAME}`)
          } else {  
            d3.select(a[i]).text(`${data.CNAME}`)
          }
        }
      })
      this.toggle_Action(this.Toggle.status.toLowerCase()) // ---> below
      this.rebuild_Text()
    },

    rebuild_Text() {
      this.Queries.SQL1.forEach((d,i) => {
        d3.select(`#Line${d.NO}`).remove()
        d3.select(`#LIST_${d.NO}`).remove()
      })
      this.draw_ListboxDetail()
    },

    toggle_Action(status) {
      let style = this.Toggle

      d3.select('.tagbtn_bg')
      .transition()
      .attr('fill', style[status].bcolor)

      d3.select('.tagbtn_slider')
      .transition()
      .attr('transform', `translate(${style[status].slidex}, ${style[status].slidey})`)

      d3.select('.tagbtn_text')
      .transition()
      .attr('transform', `translate(${style[status].textx}, ${style[status].texty})`)
      .style('fill', style[status].tcolor)
      .text(status.toUpperCase())
    },
  }
}