import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({

    margin: { top: 30, left: 35, bottom: 30, right: 10 },
    width: 800,
    height: 240,
    dateRange: [],
    chart: {},
    max: null,
    column: 'DISC',
    value: 'ACTUAL'

  }),
  methods: {
    drawChart() {
      //원래는 this.DataItems[0] === 0 이면 return 하면되는데 이상한 데이터가 들어와서 이렇게 작업함 
      if (!this.DataItems[0].DISC && !this.DataItems[0].ACTUAL) { 
        // console.log(this.Text.Text[0].text)
        let svg = this.svg
        svg.append('rect')
        .attr('x', 0).attr('y', 0).attr('width', 800).attr('height', 240).attr('fill', '#fff')
        svg.append('text').attr('x', 800/2).attr('y', 240/2).attr('text-anchor', 'middle')
        .text('N/A').attr('font-size', '30px')
        .attr('fill', '#ccc')

        return
      }
      this.dateRange = this.DataItems.map(d => d[this.column])

      const { top, left, bottom, right } = this.margin
      const width = this.width - left - right
      const height = this.height - top - bottom
      const range = this.dateRange

      const x0 = d3.scaleBand()
        .rangeRound([0, width])
        .padding(0.5)

      const y = d3.scaleLinear()
        .range([height, 0])

      const xAxis = d3.axisBottom(x0)
        .tickFormat(d => d)

      const yLeftAxis = d3.axisLeft(y)

      const columns = range

      let svg = this.svg
        .append('g')
        .attr('width', this.width)
        .attr('height', this.height)
        .attr('viewBox', `0 0 ${this.width} ${this.height}`)
        // .attr('preserveAspectRatio', 'xMinYMin meet')
        .style('will-change', 'transform')

      const g = svg.append('g')
        .attr('transform', `translate(${left},${top + 10})`)

      g.append('g')
        .attr('class', 'x axis')
        .attr('transform', `translate(0, ${height})`)

      g.append('g')
        .attr('class', 'y axis axis-left')

      g.append('g')
        .attr('transform', `translate(${width}, 0)`)
        .attr('class', 'y axis axis-right')

      const grid = g.append('g').attr('class', 'grid')

      this.chart = {
        x0, y, xAxis, yLeftAxis, columns, g, grid
      }

      const leftMax = d3.max(this.DataItems, d => d[this.value])

      this.max = leftMax

      const axisLeftRange = d3.range(5).map(d => {
        const v = leftMax / 4 * d
        return String(v).indexOf('.') !== -1 ? v : Math.ceil(v)
      })
      console.log(columns)
      x0.domain(columns)

      y.domain([0, leftMax === 0 ? 100 : leftMax])

      // yLeftAxis.tickValues(axisLeftRange)

      const duration = 500

      const barGroups = g.selectAll('.layer')
        .data(this.DataItems)
      // console.log(this.DataItems)
      barGroups
        .enter()
        .append('g')
        .attr('class', 'layer')
        .attr('transform', d => `translate(${x0(d[this.column])},0)`)

      barGroups
        .enter().append('rect')
        .attr('width', x0.bandwidth())
        .attr('x', d => x0(d[this.column]))
        .attr('y', d => y(0))
        .attr('height', d => height - y(0))
        .style('fill', '#FF8A65')
        .transition()
        .duration(duration)
        .attr('y', d => y(d[this.value]))
        .attr('height', d => height - y(d[this.value]))

      barGroups
        .enter()
        .append('text')
        .attr('class', `bar-text`)
        .attr('fill', d => d3.rgb('#FF8A65').darker(1))
        .attr('x', d => x0(d[this.column]) + x0.bandwidth() / 2)
        .attr('text-anchor', 'middle')
        .attr('y', d => y(d[this.value]) - 15)
        .style('font-size', 11)
        .text(d => d[this.value].toLocaleString('en')+'%')
        // .text(d => d[this.value] && d[this.value] !== 0 ? d[this.value].toLocaleString('en') : null)

      g.select('.x.axis')
        .transition()
        .duration(duration)
        .call(xAxis)

      g.select('.y.axis.axis-left')
        .transition()
        .duration(duration)
        // .call(yLeftAxis)

      if (leftMax !== 0) {
        grid.call(d3.axisLeft(y)
          .tickValues(axisLeftRange)
          .tickSize(-width).tickFormat('')
        )
      }

      grid
        .selectAll('.tick line')
        .attr('stroke', (d, k) => k > 0 ? '#fff' : '#ccc')

      grid.selectAll('.domain').remove()
      g.selectAll('.axis line').style('visibility', 'hidden').style('stroke', '#757575').style('stroke-width', 0.5)
      // g.selectAll('.axis .domain').style('visibility', 'hidden')
      g.selectAll('.axis .domain').style('stroke', '#ccc')
      g.selectAll('.axis text').style('fill', '#878787')
      g.selectAll('.x.axis text').style('fill', '#E65100').style('font-size', 12).style('font-weight','bold')
    }, 
  }
}
