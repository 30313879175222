<template>
  <div :id="localId"></div>
</template>

<script>
import * as d3 from 'd3'
import mx_Core from './includes/mixins/importer'            // Global & Local Mixins

export default {
  name: "j-chart-donut",
  mixins: [mx_Core],
  props: {
    id: String,
    
    // Chart Data
    Canvas: { type: Object, default: () => ({}) },

    // Database
    DataItems: { type: Array, default: () => ([]) },
    Queries: {type: Object, default: () => ({}) },

    values: {
      type: Object,
      default: () => ({
        SkylineCharts:'',
      })
    }
  },
  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
  },
  mounted() {
    this.register(this.draw)
  },

  methods: {
    draw() {
      // Check all the necessary values are available
      if (!this.ready2draw) return

      this.clear()
      this.setStyles()
      this.setDefaultValues()
      this.drawCanvas()

      this.drawChart()
      this.complete()
    },

    drawChart() {
      this.SkylineCharts = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0.5)`)
      
      this.draw_Timeline()
      this.draw_SkylineChart()
      this.draw_LineCharts()
      this.draw_Legends()

      // this.draw_SystemBoundary()
    },

  }
}
</script>