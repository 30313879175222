import * as d3 from 'd3'
import ShopData from '../primitives/donutProps'
// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  data: () => ({
    eqStatus: [
      { code: 'PO',          title: 'PO',                          colName: { total: 'PO_TOTAL'                 , actual: 'PO_ACTUAL'     }, bColor: '#84C7E5', sColor: '#00838F', tColor: '#333', opacity: 1.00, },
      { code: 'FAT',         title: 'FAT',                         colName: { total: 'FAT_TOTAL'                , actual: 'FAT_ACTUAL'    }, bColor: '#2281C5', sColor: '#9F368B', tColor: '#fff', opacity: 1.00, },
      { code: 'IRN',         title: 'IRN',                         colName: { total: 'IRN_TOTAL'                , actual: 'IRN_ACTUAL'    }, bColor: '#CC93C1', sColor: '#9F368B', tColor: '#333', opacity: 1.00, },
      { code: 'ETA',         title: 'ON Site',                     colName: { total: 'ONSITE_TOTAL'             , actual: 'ONSITE_ACTUAL' }, bColor: '#F6D15E', sColor: '#F9A825', tColor: '#333', opacity: 1.00, },
      { code: 'INST',        title: 'Installed',                   colName: { total: 'INST_TOTAL'               , actual: 'INST_ACTUAL'   }, bColor: '#8DBA2D', sColor: '#558B2F', tColor: '#fff', opacity: 1.00, },
      { code: 'NOT_PO',      title: 'Not PO',                      colName: { total: 'NOT_PO_CNT'               , actual: ''              }, bColor: '#ffffff', sColor: '#BDBCBC', tColor: '#333', opacity: 1.00, },
      { code: 'ROS_PAST',    title: 'ETA is past from ROS',        colName: { total: 'ETA_PAST_FROM_ROS'        , actual: ''              }, bColor: '#F7BACF', sColor: '#BDBCBC', tColor: '#333', opacity: 1.00, },
      { code: 'UPBLOCK_PAST',title: 'ETA is past from  Upper Blk.',colName: { total: 'ETA_PAST_FROM_UPPERBLOCK' , actual: ''              }, bColor: '#EC407A', sColor: '#BDBCBC', tColor: '#fff', opacity: 1.00, },
    ],
    thead: [
      { key: 'ITEM', html: 'Block Stage', row: 1, rowspan: 2 },
      { key: 'PLAN', html: 'Plan', row:1, colspan: 3 },
      { key: 'ACTUAL', html: 'Actual', row: 1, colspan: 3 },
      { key: 'DELTA', html: 'Delta<br/>(%)', row: 1, rowspan: 2 },

      { key: 'PLAN_SD', html: 'Start', row: 2 },
      { key: 'PLAN_FD', html: 'Finish', row: 2 },
      { key: 'PLAN_PROG', html: 'Prog(%)', row: 2 },

      { key: 'ACTUAL_SD', html: 'Start', row: 2 },
      { key: 'ACTUAL_FD', html: 'Finish', row: 2 },
      { key: 'ACTUAL_PROG', html: 'Prog(%)', row: 2 },
    ],
    tbody: [
      { key: 'ITEM', w: 110, align: 'left' },
      { key: 'PLAN_SD', w: 65, align: 'center' },
      { key: 'PLAN_FD', w: 65, align: 'center' },
      { key: 'PLAN_PROG', w: 65, align: 'center' },
      { key: 'ACTUAL_SD', w: 65, align: 'center' },
      { key: 'ACTUAL_FD', w: 65, align: 'center' },
      { key: 'ACTUAL_PROG', w: 65, align: 'center' },
      { key: 'DELTA', w: 60, align: 'center' },
    ],
    tableWidth: 550,
    
    scrollProps: {
      w: 932,
      h: 300,
      g: 0,
      bar: {
        align : 'right',
        gap   : 3,
        radius: 2,
        width : 4,
        fill  : '#9E9E9E',
        stroke: '#9E9E9E',
      }
    }
  }),
  methods: {
    Draw_Charts() {
      if(!this.DataItems || this.DataItems.length === 0) return

      let tableData = this.svg

        let table_group = tableData.append('g').attr('transform', `translate(0, 0)`).attr('class', `block_table_group_`)

        // SET HERE!
        let list = this.DataItems.filter(d => d.BLOCK)
        let tableViewHeight = 96 + (list.length * 18) // thead + tbody
  
        // if (tableViewHeight > this.scrollProps.h) this.setScroll(table_group, this.scrollProps)
  
        let table_obj = table_group.append('foreignObject')
          .attr('y',0)
          .attr('width', '100%')
          .attr('height', `${tableViewHeight}px`)
          .append('xhtml:body')
  
        let table = table_obj.append('table')
                    .style('border', 'solid 0.7px #BDBDBD')
                    .style('border-collapse', 'collapse')
                    .style('font-size', '10px')
                    .style('width', `${this.tableWidth}px`)
                    .style('table-layout', 'fixed')
  
        let colgroup = table.append('colgroup')
        this.tbody.forEach(k => {
          colgroup.append('col').style('width', k.w + 'px')
        })
  
        let maxRow = Math.max(...this.thead.map(d => d.row))
  
        let thead = table.append('thead')
        for (let r = 1; r <= maxRow; r++) {
          let row = this.thead.filter(d=> d.row === r)
          let tr = thead.append('tr').style('background', '#F5F5F5')
  
          row.forEach(item => {
            tr.append('th')
            .attr('class', 'completion_dashboard_table_group___th')
            .attr('rowspan', () => item.rowspan ? item.rowspan : '')
            .attr('colspan', () => item.colspan ? item.colspan : '')
            .style('border', 'solid 0.7px #eee')
            .style('padding', ['Start','Finish','Prog(%)'].includes(item.html) ? '2px': '5px')
            .style('font-weight','normal')
            .style('font-size', ['Start','Finish','Prog(%)'].includes(item.html) ? '10px': '11px')
            .style('color', ['Start','Finish','Prog(%)'].includes(item.html) ? '#039BE5':'#2c3e50')
            .html(item.html)
          })        
        }
          
        let tbodykeys = this.tbody
        let tbody = table.append('tbody')
  
        for (let r = 0; r < list.length; r++) {
          let tr = tbody.append('tr')
          // tr.on('mouseover', () => {
          //   tr.style('cursor', 'pointer')
          //   tr.style('background-color', '#C5E1A5')
          // })
          // tr.on('mouseout', () => {
          //   tr.style('background-color', '#fff')
          // })
          //tr mouseover color change

          tr.style('background-color', `${list[r].ACTUAL_PROG === 100 ? '#C5E1A5' : '#fff'}`)
          tbodykeys.forEach((k, i) => {
            let td = tr.append('td')
              .style('border', 'solid 0.7px #eee')
              .style('height', '22px')
              .style('text-align', k.align ? k.align : 'right')
              .style('padding', '0 3px 0 3px')
  
            if(i === 0) {
              td.style('white-space', 'nowrap')
                .style('overflow', 'hidden')
                .style('text-overflow', 'ellipsis')
                .attr('title', list[r][k.key])
            }
            if (!list[r][k.key]) return
            td.html(list[r][k.key])
          })
        }
    },
  }
}
