import * as d3 from 'd3'

export default {
  methods: {
    Activate_Hull_AFT() {

      // Remove SVG
      this.removeSvg(this.CANVAS, 1000)

      this.svgModeling = this.svg.append('svg')
      this.svgModeling
      .attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight)
      .attr('viewBox', `0 0 ${this.Canvas.CanvasWidth} ${this.Canvas.CanvasHeight}`)
      .attr('opacity', 1)

      // Overall_Hull_Group
      let Hull_AFT = this.svgModeling
      .append('g')
      .attr('id', 'Hull_AFT_Group')
      .attr('opacity', 1)
      .attr('transform', `translate(0,0) scale(1)`)

      setTimeout(() => {
          Hull_AFT
          .append('image')
          .attr('opacity', 1).style('cursor', 'pointer')
          .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Blocks/Hull/HULL_AFT.png`)
          .attr('transform', `translate(505.943,440.637) scale(1)`)
      }, 600);
      setTimeout(() => {
        this.loadSvg(0, 0, `/TORTUE/Equipment/SVG/HULL_AFT.svg`).then((innerSvg) => {
            this.Draw_EQ_Code(innerSvg, 'AFT')
            this.Draw_EQ_Block(innerSvg, 'AFT')
            this.Draw_EQ_Tag(innerSvg, 'AFT')
            this.innerSvg = innerSvg // SVG  
        })
        
        this.Lev1 = 'HULL'
        this.Lev2 = 'HULL-AFT'
        this.stage = this.STG_Activate
        this.fncName = 'Activate_Hull_AFT'
        this.StageCode() // AREA / STAGE
        this.displayGroupID(`#Menu_Back`, 0, 200, 1)

        // ### Call Canvas ###
        this.drawProgress('AFT', false)

        // Internal-Filter-Value for the common control
        this.inFilters.LV1 = this.Lev1
        this.inFilters.LV4 = 'AFT'        
      }, 1000)
    },

  }
}
           