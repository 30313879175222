import __C from '../../primitives/_constant_'

const URL_HEADER = `${__C.HOST_NAME_API}/v0`
const WEBSOCKET_HEADER = `${__C.WEBSOCKET_API}/v0/ws`
const Url = {
  system: {
    config: {
      query2json  : `${URL_HEADER}/sysconfig/query2json`,
      codegroup   : `${URL_HEADER}/sysconfig/codegroup`,
      codeindex   : `${URL_HEADER}/sysconfig/codeindex`,
      navRelated  : `${URL_HEADER}/sysconfig/navRelated`,
      dbTransfer  : `${URL_HEADER}/sysconfig/dbTransfer`,
      phaseTrans  : `${URL_HEADER}/sysconfig/phaseTrans`,
      groupTrans  : `${URL_HEADER}/sysconfig/groupTrans`,
      subitemTrans: `${URL_HEADER}/sysconfig/subitemTrans`,
      navphase    : `${URL_HEADER}/sysconfig/navphase`,
      navgroup    : `${URL_HEADER}/sysconfig/navgroup`,
      navitem     : `${URL_HEADER}/sysconfig/navitem`,
      navsubitem  : `${URL_HEADER}/sysconfig/navsubitem`,
      subitemmenu : `${URL_HEADER}/sysconfig/subitem/menu`,
      origin      : `${URL_HEADER}/sysconfig/origin`,
      devUp      : `${URL_HEADER}/sysconfig/devUp`,
      catmenu: {
        chart   : `${URL_HEADER}/sysconfig/catmenu/chart`,
        numChild: `${URL_HEADER}/sysconfig/catmenu/numChild`
      },
      wbutton       : `${URL_HEADER}/sysconfig/wbutton`,
      iconRelated   : `${URL_HEADER}/sysconfig/iconRelated`,
      icons         : `${URL_HEADER}/sysconfig/icons`,
      iconsUpload   : `${URL_HEADER}/sysconfig/icons/upload`,
      chart         : `${URL_HEADER}/sysconfig/chart`,
      charts        : `${URL_HEADER}/sysconfig/charts`,
      chartsUpload  : `${URL_HEADER}/sysconfig/charts/upload`,
      setpriorities : `${URL_HEADER}/sysconfig/setpriorities`,
      chartlib: {
        list        : `${URL_HEADER}/chartlib/list`,
        thumbs      : `${URL_HEADER}/chartlib/thumbs`,
        donut       : `${URL_HEADER}/chartlib/donut`,
        pie         : `${URL_HEADER}/chartlib/pie`,
        barsolid    : `${URL_HEADER}/chartlib/barsolid`,
        barnormal   : `${URL_HEADER}/chartlib/barnormal`,
        barprogress : `${URL_HEADER}/chartlib/barprogress`,
        bardelta    : `${URL_HEADER}/chartlib/bardelta`,
        radar       : `${URL_HEADER}/chartlib/radar`,
        histogram   : `${URL_HEADER}/chartlib/histogram`,
        skyline     : `${URL_HEADER}/chartlib/skyline`,
        tsummary    : `${URL_HEADER}/chartlib/tsummary`,
        custom      : `${URL_HEADER}/chartlib/custom`,
        validateq   : `${URL_HEADER}/chartlib/validateq`,
      },
      pagecomp: {
        idx         : `${URL_HEADER}/page/idx`,
        dashadmin   : `${URL_HEADER}/page/dashadmin`,
        intdg       : `${URL_HEADER}/page/intdg`,
        custom      : `${URL_HEADER}/page/custom`,
        previewdl   : `${URL_HEADER}/page/previewdl`,
        svgprops    : `${URL_HEADER}/page/svgprops`,
        rowsperpage : `${URL_HEADER}/page/rowsperpage`
      },
      database: {
        sqlquery: `${URL_HEADER}/database/sqlquery`,
        sqlexec : `${URL_HEADER}/database/sqlexec`
      },
      dbexec: {
        sqlexec : `${URL_HEADER}/dbexec/sqlexec`
      },
      icon                 : `${URL_HEADER}/sysconfig/icon`,
      uploadList           : `${URL_HEADER}/sysconfig/upload`,
      discSummary          : `${URL_HEADER}/sysconfig/discSummary`,
      item3D               : `${URL_HEADER}/sysconfig/item3D`,
      stage3D              : `${URL_HEADER}/sysconfig/stage3D`,
      blockErection        : `${URL_HEADER}/sysconfig/blockErection`,
      blockErectionStage   : `${URL_HEADER}/sysconfig/blockErectionStage`,
      commodity            : `${URL_HEADER}/sysconfig/commodity`,
      eqInstallation       : `${URL_HEADER}/sysconfig/eqInstallation`,
      eqInstallationStage  : `${URL_HEADER}/sysconfig/eqInstallationStage`,
      pipeInstallation     : `${URL_HEADER}/sysconfig/pipeInstallation`,
      pipeInstallationStage: `${URL_HEADER}/sysconfig/pipeInstallationStage`,
      pressureSummary      : `${URL_HEADER}/sysconfig/pressureSummary`,
      modalListTab         : `${URL_HEADER}/sysconfig/modalListTab`,
      pressureStage        : `${URL_HEADER}/sysconfig/pressureStage`,
    },
    code: {
      chartoptions    : `${URL_HEADER}/code/chartoptions`,
      datatableoptions: `${URL_HEADER}/code/dtoptions`,
      dashboardoptions: `${URL_HEADER}/code/dashoptions`,
      companies       : `${URL_HEADER}/code/companies`,
      compusers       : `${URL_HEADER}/code/compusers`,
      department      : `${URL_HEADER}/code/dept`,
      galleryoptions  : `${URL_HEADER}/code/galoptions`,


      position        : `${URL_HEADER}/code/position`,
      subitemphase    : `${URL_HEADER}/code/subitemphase`,
      subitemgroup    : `${URL_HEADER}/code/subitemgroup`,
      subitemitem     : `${URL_HEADER}/code/subitemitem`,
      sys: {
        columns  : `${URL_HEADER}/code/sys/columns`,
        columnsq : `${URL_HEADER}/code/sys/columnsq`,
        tables   : `${URL_HEADER}/code/sys/tables`,
        databases: `${URL_HEADER}/code/sys/databases`
      },
      navlist        : `${URL_HEADER}/code/navlist`,
      datasourcelist : `${URL_HEADER}/code/datasourcelist`,
      getNotification: `${URL_HEADER}/code/getNotification`,
      commentlist    : `${URL_HEADER}/code/commentlist`,
      getUrlData     : `${URL_HEADER}/code/getUrlData`,
      getComment     : `${URL_HEADER}/code/getComments`,
      putNotification: `${URL_HEADER}/code/putNotification`,
      upNotification : `${URL_HEADER}/code/upNotification`,
      delNotification: `${URL_HEADER}/code/delNotification`
    },
    values: {
      columns  : `${URL_HEADER}/sysvalues/columns`,
      tables   : `${URL_HEADER}/sysvalues/tables`,
      databases: `${URL_HEADER}/sysvalues/databases`
    },
    layout: {
      navigation: {
        menu: ''
      }
    },
    filebrowser : {
      resources : `${URL_HEADER}/filebrowser/resources`,
      raw       : `${URL_HEADER}/filebrowser/raw`
    },
    service: {
      workorder : `${URL_HEADER}/syssrv/workorder`,
      workorders: `${URL_HEADER}/syssrv/workorders`,
      wofile    : `${URL_HEADER}/syssrv/wofile`,
    },
  },
  service: {
    control: {
      putUpItems : `${URL_HEADER}/svccontrol/putUpItems`,
      editUpItems: `${URL_HEADER}/svccontrol/editUpItems`,
      delUpItems : `${URL_HEADER}/svccontrol/delUpItems`,
      updateItems: `${URL_HEADER}/svccontrol/updateItems`,
      member     : `${URL_HEADER}/svccontrol/member`,
      memberAdmin: `${URL_HEADER}/svccontrol/memberAdmin`,
      bpmembers  : `${URL_HEADER}/svccontrol/bpmembers`,
      members    : `${URL_HEADER}/svccontrol/members`,
      getOtpSecret  : `${URL_HEADER}/svccontrol/getOtpSecret`,
      chkOtpSecret  : `${URL_HEADER}/svccontrol/chkOtpSecret`,
      getOtpVerify  : `${URL_HEADER}/svccontrol/getOtpVerify`,
      regOtpSecret  : `${URL_HEADER}/svccontrol/regOtpSecret`,
      updOtpSecret  : `${URL_HEADER}/svccontrol/updOtpSecret`,
      delOtpSecret  : `${URL_HEADER}/svccontrol/delOtpSecret`,
    }
  },
  application: {
    construction: {
      moduleprogs: `${URL_HEADER}/construction/moduleprogs`
    },
    account: {
      signin: `${URL_HEADER}/auth/signin`,
      reqhist: `${URL_HEADER}/app/acct/reqhist`,
      reqmember: `${URL_HEADER}/auth/request`,
      uniqMember: `${URL_HEADER}/auth/uniq`,
      forgotMember: `${URL_HEADER}/auth/forgot`,
    },
    board: {
      reports: `${URL_HEADER}/app/board/reports`,
      report: `${URL_HEADER}/app/board/report`,
      file: `${URL_HEADER}/app/board/file`,
    },
    config: {
      routes: `${URL_HEADER}/aconf/routes`
    },
    layout: {
      navigation: {
        menu: `${URL_HEADER}/app/nav/menu`
      }
    },
    create:  {
      export: `${URL_HEADER}/app/create/export`,
      excel: `${URL_HEADER}/app/create/excel`
    },
    idgservice: {
      init        : `${URL_HEADER}/app/idgservice/init`,
      patronage   : `${URL_HEADER}/app/idgservice/patronage`,
      chainoptions: `${URL_HEADER}/app/idgservice/chainoptions`
    },
    dashboard: {
      get     : `${URL_HEADER}/app/dashboard/get`,
      contqres: `${URL_HEADER}/app/dashboard/contqres`,   // contqres: Container Query Result
    },
    tabs: {
      check   : `${URL_HEADER}/app/pagesvg/tabs`
    },
    pagesvg: {
      get: `${URL_HEADER}/app/pagesvg/get`,
    },
    chart: {
      dntValues: `${URL_HEADER}/app/chart/dntValues`
    },
    reqhist: {
      overview: `${URL_HEADER}/app/reqhist/overview`
    }
  },
  communicate: {
    comments: `${URL_HEADER}/communicate/comments`,
    comment: `${URL_HEADER}/communicate/comment`,
    editcomment: `${URL_HEADER}/communicate/editcomment`,
  },
  subsystemcomment: {
    comments: `${URL_HEADER}/subsystemcomment/comments`,
    comment: `${URL_HEADER}/subsystemcomment/comment`,
    editcomment: `${URL_HEADER}/subsystemcomment/editcomment`,
  },
  notice: {
    message: {
      membersunread: `${URL_HEADER}/notice/message/members/unread`,
      members: `${URL_HEADER}/notice/message/members`,
      onchat: {
        members: `${URL_HEADER}/notice/message/onchat/members`,
      },
      notices: `${URL_HEADER}/notice/message/notices`,
      onchats: `${URL_HEADER}/notice/message/onchats`,
      one2one: `${URL_HEADER}/notice/message/one2one`,
      group: `${URL_HEADER}/notice/message/group`,
      unreads: `${URL_HEADER}/notice/message/unreads`,
      unreadsnum: `${URL_HEADER}/notice/message/unreadsnum`,
      send: `${URL_HEADER}/notice/message/send`
    }
  },
  pdf: {
    create: `${URL_HEADER}/pdf/create`,
  },
  download: `${URL_HEADER}/download`,
  file: {
    download: `${URL_HEADER}/file/download`,
    gallery: {
      list: `${URL_HEADER}/file/gallery/list`,
      filterlist: `${URL_HEADER}/file/gallery/filterlist`,
      eqfilterlist: `${URL_HEADER}/file/gallery/eqfilterlist`,
      edit: `${URL_HEADER}/file/gallery/edit`,
      chedit: `${URL_HEADER}/file/gallery/chedit`,
      eqedit: `${URL_HEADER}/file/gallery/eqedit`,
      conedit: `${URL_HEADER}/file/gallery/conedit`,
      renderingedit: `${URL_HEADER}/file/gallery/renderingedit`,
      noticeedit: `${URL_HEADER}/file/gallery/noticeedit`,
      files: `${URL_HEADER}/file/gallery/files`,
      chfiles: `${URL_HEADER}/file/gallery/chfiles`,
      eqfiles: `${URL_HEADER}/file/gallery/eqfiles`,
      confiles: `${URL_HEADER}/file/gallery/confiles`,
      renderingfiles: `${URL_HEADER}/file/gallery/renderingfiles`,
      noticefiles: `${URL_HEADER}/file/gallery/noticefiles`,
      file: `${URL_HEADER}/file/gallery/file`,
      chfile: `${URL_HEADER}/file/gallery/chfile`,
      eqfile: `${URL_HEADER}/file/gallery/eqfile`,
      confile: `${URL_HEADER}/file/gallery/confile`,
      renderingfile: `${URL_HEADER}/file/gallery/renderingfile`,
      noticefile: `${URL_HEADER}/file/gallery/noticefile`,
      mainfiles: `${URL_HEADER}/file/gallery/mainfiles`,
    }
  },
  token: {
    check: `${URL_HEADER}/token/check`
  },
  fetch: {
    gallery: {
      selectfiles: `${URL_HEADER}/fetch/gallery/selectfiles`,
    },
    excel: {
      get: `${URL_HEADER}/fetch/excel/get`,
      history: `${URL_HEADER}/fetch/excel/history`,
      modal: `${URL_HEADER}/fetch/excel/modal`,
      modalPost: `${URL_HEADER}/fetch/excel/modalPost`
    },
    file: {
      resources: `${URL_HEADER}/fetch/file/resources`,
      image: `${URL_HEADER}/fetch/file/image`,
      raw: `${URL_HEADER}/fetch/file/raw`      
    }
  },
  export: {
    container: {
      get: `${URL_HEADER}/export/container/get`
    },
    dashboard: {
      get: `${URL_HEADER}/export/dashboard/get`
    },
    datatable: {
      get: `${URL_HEADER}/export/datatable/get`
    },
    pagesvg: {
      get: `${URL_HEADER}/export/pagesvg/get`
    },
  },
  websocket: {
    messenger: `${WEBSOCKET_HEADER}/messenger`,
  },
  threed: {
    users: `${URL_HEADER}/3d/users`,
  },
  stream: {
    video: `${URL_HEADER}/stream/[ID]/video`,
  },

  china1: {
    mr: {
      comment: `${URL_HEADER}/china1/mr/comment`,
      status: `${URL_HEADER}/china1/mr/status`,
    }
  },
  md2: {
    filter: {
      // system completion
      scarea: `${URL_HEADER}/service/filter/scarea`,
    },

    // system completion
    sc: {
      // Certificate of Inspection
      coiitem: `${URL_HEADER}/service/sc/coiitem`,
    }
  },
  tortue: {
    // planning
    cms: {
      leveloptions: `${URL_HEADER}/tortue/cms/leveloptions`,
    }
  }
}

export default Url
