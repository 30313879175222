export default {
  watch: {
    ChartType(typeName) { if(!typeName) return; this.register(this.draw); },
    Canvas: { handler(val) { if(!val || Object.keys(val).length === 0) return; this.register(this.draw); }, deep: true },
    LeftItems: { handler(val) { if(!val || val.length === 0) return; this.register(this.draw); }, deep: true },
    RightItems: { handler(val) { if(!val || val.length === 0) return; this.register(this.draw); }, deep: true },
    BottomPyramidItems: { handler(val) { if(!val || val.length === 0) return; this.register(this.draw); }, deep: true },
    BottomPyramidSubItems: { handler(val) { if(!val || val.length === 0) return; this.register(this.draw); }, deep: true },
  },
}
