import * as d3 from 'd3'

export default {
  data: () => ({
    prevActual: null,
  }),

  methods: {
    draw_LineCharts() {
      if(this.skyline.line.display != 'Y') return
      if(!this.chartMounted) this.setLineScale()
      else {
        d3.select(`#${this.localId}`).select('.skyline_line_actual').remove()
        if(!this.Queries.SQL3 || this.Queries.SQL3.length === 0) return
      }

      let LineChart = this.SkylineCharts
      .append('g')
      .attr('class', 'skyline_line_actual')
      .attr('transform', `translate(${this.timeline.baseX},0)`)

      // // PLAN
      // LineChart
      // .append('g')
      // .attr('transform', `translate(0, 0)`)
      // .append('path')
      // .attr('d', prevLineFunc(this.prevActual))
      // .style('stroke', '#bcbcbc').style('stroke-width', 1.5).attr('opacity', .5)
      // .style('fill', 'none')
      // .transition()
      // .duration(500)
      // .attr('d', this.lineFunc(this.timeline.plan))

      // LineChart
      // .append('g')
      // .attr('transform', `translate(0, 0)`)
      // .selectAll('circle')
      // .data(this.timeline.plan)
      // .enter()

      // .append('circle')
      // .attr('cx', d => this.timeline.scale(d.eDate))
      // .attr('cy', d => this.scaleY(0))
      // .attr('r', 3)
      // .style('stroke', '#bcbcbc').style('stroke-width', 1.5).attr('stroke-opacity', 0.5)
      // .style('fill', '#fff')
      // .transition()
      // .duration(500)
      // .attr('cx', d => this.timeline.scale(d.eDate))
      // .attr('cy', d => this.scaleY(d.value))

      // Actual
      LineChart
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .append('path')
      .attr('d', this.lineZero(this.timeline.actual))
      .style('stroke', '#FF75A5').style('stroke-width', .5).attr('opacity', .95)
      .style('fill', 'none')
      .transition()
      .duration(500)
      .attr('d', this.lineFunc(this.timeline.actual))

      LineChart
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .selectAll('circle')
      .data(this.timeline.actual)
      .enter()

      .append('circle')
      .attr('cx', d => this.timeline.scale(d.eDate))
      .attr('cy', this.scaleY(0))
      .attr('r', 3)
      .style('stroke', '#FF75A5').style('stroke-width', 1.5).attr('stroke-opacity', 0.5)
      .style('fill', '#fff')
      .transition()
      .duration(500)
      .attr('cx', d => this.timeline.scale(d.eDate))
      .attr('cy', d => this.scaleY(d.value))
    }, 
  }
}
