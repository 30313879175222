import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({
    
  }),


  methods: {

    ProgressTable_01(selection, _dataSet, _colStyle, _cellStyle, _label, _display) {

      let _self = this
      let len = _dataSet.length
      len = (len+1)*18 + 15
      let ProgTable_01 = selection
      .append('g')
      .attr('transform', `translate(${_cellStyle.x}, ${_cellStyle.y})`)

      ProgTable_01
      .append('rect')
      .attr('transform', `translate(0,-30)`)
      .attr('width', 310).attr('height', len).attr('fill', '#fff').attr('stroke', '#bcbcbc').attr('stroke-width', 0.3)

      ProgTable_01
      .append('rect')
      .attr('id', `${_cellStyle.id}_label_border`)
      .attr('transform', `translate(15,-31)`)
      .attr('width', 100).attr('height', 2).attr('fill', '#fff').attr('stroke', '#fff').attr('stroke-width', 0.3)

      ProgTable_01
        .append('text')
        .attr('id', `${_cellStyle.id}_label_text`)
        .attr('transform', `translate(20, -35)`)
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#333').attr('text-anchor', 'start').attr('alignment-baseline', 'hanging')
        .text(_label)

        d3.select(`#${_cellStyle.id}_label_border`).attr('width', this.getNodeElValue(`#${_cellStyle.id}_label_text`, 'width') + 5)

      let barPad = 0, barAlign = null

      // Header
      _colStyle.forEach((d,i) => {
        ProgTable_01
        .append('text')
        .attr('transform', `translate(${_colStyle[i].x}, -15)`)
        .style('font-family', 'roboto').style('font-size', _cellStyle.fSize[0]).style('fill', d.tColor).attr('text-anchor', d.align).attr('alignment-baseline', 'hanging')
        .text(d.name)
      })
      // console.log(_dataSet)
      _dataSet.forEach((d,i) => {
        d.y = i * 18
        if (d.PROG < 25) {
          barPad = 1
          barAlign = 'start'
        } else {
          barPad = -1
          barAlign = 'end'
        }

        ProgTable_01
        .append('text')
        .attr('transform', `translate(${_colStyle[0].x}, ${d.y})`)
        .style('font-family', 'roboto').style('font-size', _cellStyle.fSize[1]).style('fill', _cellStyle.tColor).attr('text-anchor', 'end').attr('alignment-baseline', 'hanging')
        .text(d.DISC)
        
        // Total
        ProgTable_01
        .append('text')
        .attr('id', `${_cellStyle.id}_${d.DISC}_Total_${i}`)
        .attr('transform', `translate(${_colStyle[1].x}, ${d.y})`)
        .style('font-family', 'roboto').style('font-size', _cellStyle.fSize[1]).style('fill', _cellStyle.tColor).attr('text-anchor', 'end').attr('alignment-baseline', 'hanging')
        .text(d.TOTAL)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          let node = _self.getNodeElValue(`#${_cellStyle.id}_${d.DISC}_Total_${i}`,'width') / 5.5
          d3.select(this).transition().duration(100)
          .attr('transform', `translate(${_colStyle[1].x + node}, ${d.y}) scale(1.3)`).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100)
          .attr('transform', `translate(${_colStyle[1].x}, ${d.y}) scale(1)`).style('fill', _cellStyle.tColor)
        })
        .on('click', () => { 
          if(_cellStyle.requests) {
            _cellStyle.requests[0].filters.DISC = d.DISC
            this.$emit('request-action', _cellStyle.requests[0])
          }
        })

        // Actual
        ProgTable_01
        .append('text')
        .attr('id', `${_cellStyle.id}_${d.DISC}_Actual_${i}`)
        .attr('transform', `translate(${_colStyle[2].x}, ${d.y})`)
        .style('font-family', 'roboto').style('font-size', _cellStyle.fSize[1]).style('fill', _cellStyle.tColor).attr('text-anchor', 'end').attr('alignment-baseline', 'hanging')
        .text(d.ACTUAL)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          let node = _self.getNodeElValue(`#${_cellStyle.id}_${d.DISC}_Actual_${i}`,'width') / 5.5
          d3.select(this).transition().duration(100)
          .attr('transform', `translate(${_colStyle[2].x + node}, ${d.y}) scale(1.3)`).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100)
          .attr('transform', `translate(${_colStyle[2].x}, ${d.y}) scale(1)`).style('fill', _cellStyle.tColor)
        })
        .on('click', () => { 
          if(_cellStyle.requests) {
            _cellStyle.requests[1].filters.DISC = d.DISC
            this.$emit('request-action', _cellStyle.requests[1])
          }
        })

        // Remain
        ProgTable_01
        .append('text')
        .attr('id', `${_cellStyle.id}_${d.DISC}_Remain_${i}`)
        .attr('transform', `translate(${_colStyle[3].x}, ${d.y})`)
        .style('font-family', 'roboto').style('font-size', _cellStyle.fSize[1]).style('fill', _cellStyle.tColor).attr('text-anchor', 'end').attr('alignment-baseline', 'hanging')
        .text(d.REMAIN)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          let node = _self.getNodeElValue(`#${_cellStyle.id}_${d.DISC}_Remain_${i}`,'width') / 5.5
          d3.select(this).transition().duration(100)
          .attr('transform', `translate(${_colStyle[3].x + node}, ${d.y}) scale(1.3)`).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100)
          .attr('transform', `translate(${_colStyle[3].x}, ${d.y}) scale(1)`).style('fill', _cellStyle.tColor)
        })
        .on('click', () => { 
          if(_cellStyle.requests) {
            _cellStyle.requests[2].filters.DISC = d.DISC
            this.$emit('request-action', _cellStyle.requests[2])
          }
        })

        // Bar Chart
        ProgTable_01
        .append('rect')
        .attr('transform', `translate(${_colStyle[4].x}, ${(d.y) -2})`)
        .attr('width', 100).attr('height', 12).attr('fill', '#F4F5F5').attr('opacity', 0.7)

        ProgTable_01
        .append('rect')
        .attr('transform', `translate(${_colStyle[4].x}, ${(d.y) -2})`)
        .attr('width', d.PROG).attr('height', 11).attr('fill', _cellStyle.barColor).attr('opacity', 0.7)

        ProgTable_01
        .append('text')
        .style('font-family', 'roboto').style('font-size', _cellStyle.fSize[2]).style('fill', _cellStyle.tColor).attr('alignment-baseline', 'hanging')
        .text(`${Number(d.PROG.toFixed(1))}%`)
        .attr('text-anchor', barAlign)
        .attr('transform', `translate(${_colStyle[4].x + d.PROG + barPad}, ${d.y})`)
      })

    },

  }
}
