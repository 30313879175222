import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({

  }),

  methods: {
    
    drawChart() {

      let lightPink = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `lightPink`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      lightPink.append('stop').attr('stop-color', '#fce3eb').attr('offset', '0')
      lightPink.append('stop').attr('stop-color', '#fcafc9').attr('offset', '1')

      let lightGreen = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `lightGreen`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      lightGreen.append('stop').attr('stop-color', '#ddf1b6').attr('offset', '0')
      lightGreen.append('stop').attr('stop-color', '#c6e481').attr('offset', '1')

      let lightBlue = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `lightBlue`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      lightBlue.append('stop').attr('stop-color', '#d4eaf3').attr('offset', '0')
      lightBlue .append('stop').attr('stop-color', '#a3d0e8').attr('offset', '1')

      let ganttGroup = this.svg
      .append('g')
      .attr('transform', `translate(0, 0)`)

      // Legends -------------------------------------------------------------------------------------------------------------------------
      let legends = ganttGroup
      .append('g')
      .attr('transform', `translate(10, 10)`)

      legends
      .append('text').attr('x',0).attr('y',0).style('font-family','roboto').style('font-size',11).attr('fill', '#333').text('Legends')

      legends
      .append('path').attr('d', 'M5,8 H21 L25,12 L21,16 H5 L9,12 Z').attr('fill', `url(#lightBlue)`) 
      legends
      .append('text').attr('x',30).attr('y',15).style('font-family','roboto').style('font-size',8.5).attr('fill', '#757575').text('Ahead Schedule')

      legends
      .append('path').attr('d', 'M5,23 H21 L25,27 L21,31 H5 L9,27 Z').attr('fill', `url(#lightPink)`) 
      legends
      .append('text').attr('x',30).attr('y',30).style('font-family','roboto').style('font-size',8.5).attr('fill', '#757575').text('Behind Schedule')

      legends
      .append('rect').attr('x', 5).attr('y', 38).attr('width', 20).attr('height', 8).attr('fill', `url(#lightGreen)`)
      legends
      .append('text').attr('x',30).attr('y',45).style('font-family','roboto').style('font-size',8.5).attr('fill', '#757575').text('Completion 100%')

        
    }, 
  }
}
