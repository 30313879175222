// -------------------------------------------------------------------------------------- Global
import { SafeIdMixin } from '../../../../../../includes/mixins/safeid.mixin'                // ATAGlance Framework Globals
import gmx_Controller from '../../../../../../includes/mixins/Controller.mixin'       // SVG Control
// Global Drawings
import gmx_Canvas from '../../../../../../includes/mixins/Draw_Canvas.mixin'
import gmx_Texts from '../../../../../../includes/mixins/Text_Draw.mixin'
import gmx_Dropdown from '../../../../../../includes/mixins/Dropdown.mixin'
// Global Functions
import gmx_Miscellaneous from '../../../../../../includes/mixins/Miscellaneous.mixins'
import gmx_Movable from '../../../../../../includes/mixins/Movable.mixin'

// -------------------------------------------------------------------------------------- Local

// Locally declared common Data-Props & SVG Drawing Support
import lmx_ReactiveEvents from        './Realtime_Monitor.mixin' // Reactive Events
import lmx_Miscellaneous from         '../../../../include/mixins/Miscellaneous.mixins'
  
  

import lmx_DataStyles from            './DataStyles.mixin'
import lmx_Declares from              './Declares.mixin'
import lmx_Forms from                 './Draw_Forms.mixin'
import lmx_Contents from              './Draw_Contents.mixin'
import Export_PDF from                './Export_PDF.mixin'



export default {
  mixins: [
    SafeIdMixin,
    gmx_Controller,
    gmx_Canvas,
    gmx_Texts,
    gmx_Dropdown,
    gmx_Miscellaneous,
    gmx_Movable,

    lmx_ReactiveEvents,
    lmx_Miscellaneous,
    Export_PDF,

    // Mixin
    lmx_DataStyles,
    lmx_Declares,
    lmx_Forms,
    lmx_Contents,

  ],
}
