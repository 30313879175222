import * as d3 from 'd3'

export default {
  methods: {

    draw_Forms() {

      this.Area_Canvas = this.svg
      .append('g')
      .attr('transform', 'translate(0, 0)')
      .attr('font-family', 'Segoe UI')

      this.Area_Canvas
      .append('text')
      .attr('x', 20).attr('y', 40)
      .attr('font-size', 24).attr('fill', '#333').attr('font-weight', 400)
      .text(`Owner Overall Summary by CWA`)

      this.Area_Canvas
      .append('text')
      .attr('x', 20).attr('y', 60)
      .attr('font-size', 14).attr('fill', '#757575')
      .text(`Progress with simplified skyline and rundown charts`)



      this.draw_Legends()


      // ---------------------------------------------------------------------------------------
      let unitGroup = this.Area_Canvas
      .append('g')
      .attr('transform', `translate(20.5, 130.5)`)
      .attr('font-family', 'Segoe UI').style('cursor', 'default')

      let x = 30, y = 150
      this.rawData.forEach((unit,i)=> {

        this.draw_Header(unitGroup, unit, x, y, i)
        this.draw_Skyline(unitGroup, unit, x, y, i)

        this.draw_Summary(unitGroup, unit, {
          idx: i,
          x: this.cols[0], 
          y: y, 
        })

        this.draw_ITR_Chart(unitGroup, unit, {
          idx: i,
          x: this.cols[1], y: y,
          item: 'A', color: '#44A9DF',
        })

        this.draw_ITR_Chart(unitGroup, unit, {
          idx: i,
          x: this.cols[2], y: y,
          item: 'B', color: '#FFDA00',
        })

        y += this.colHeight
      })


      this.draw_ballon()
    },


    draw_ballon(){
      // ballon
      let ballon = this.Area_Canvas
      .append('g')
      .attr('id', `Ballon_Group`)
      .attr('transform', `translate(0.5, 0)`)
      .attr('font-family', 'Segoe UI').attr('opacity', 0)
      .style('cursor', 'default')

      ballon
      .append('path')
      .attr('id', `Ballon_Border`)
      .attr('transform', `translate(-7, -22)`)
      .attr('d', `M74.2,0.2h-72c-1.1,0-2,0.9-2,2v11c0,1.1,0.9,2,2,2H4l2.8,7l-0.2-7h67.6c1.1,0,2-0.9,2-2v-11 C76.2,1.1,75.3,0.2,74.2,0.2z`)
      .attr('fill', '#fff').attr('stroke', '#333').attr('stroke-width', .5).attr('stroke-opacity', 1)

      ballon
      .append('text')
      .attr('id', `Ballon_Text`)
      .attr('x', -3.5).attr('y', -10.5)
      .attr('font-family', 'roboto')
      .attr('font-size', 11).attr('fill', '#333').attr('font-weight', 500)
      .text(`21100-U-6102`)
    }
  }
}