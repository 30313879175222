import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({

    Options: {
      linkDisplay: 'Y',
    }
  }),


  methods: {
    
    drawChart() {

      let modulePau = this.svg
      .append('g')
      .attr('id', `a`)
      .attr('transform', `translate(0,0)`)

      modulePau
      .append('rect')
      .attr('x', 0).attr('y', 0).attr('rx', 5).attr('ry', 5).attr('width', 300).attr('height', 150)
      .attr('fill', '#EC407A')

      // Text of Title
      modulePau
      .append('text')
      .attr('x', 15).attr('y', 25).attr('fill', '#fff') .style('font-size', 20).style('font-family', 'roboto').attr('text-anchor', 'start').attr('alignment-baseline', 'middle').text('System')
      modulePau
      .append('text')
      .attr('x', 16).attr('y', 40).attr('fill', '#BDBCBC') .style('font-size', 10).style('font-family', 'roboto').attr('text-anchor', 'start').attr('alignment-baseline', 'middle').text('No. of System')
      modulePau
      .append('text')
      .attr('x', 15).attr('y', 100).attr('fill', '#fff') .style('font-size', 20).style('font-family', 'roboto').attr('text-anchor', 'start').attr('alignment-baseline', 'middle').text('Area')
      modulePau
      .append('text')
      .attr('x', 16).attr('y', 115).attr('fill', '#BDBCBC') .style('font-size', 10).style('font-family', 'roboto').attr('text-anchor', 'start').attr('alignment-baseline', 'middle').text('No. of Area')

      // Text of Status
      modulePau
      .append('text')
      .attr('x', 160).attr('y', 40).attr('fill', '#F7BACF') .style('font-size', 9).style('font-family', 'roboto').attr('text-anchor', 'end').attr('alignment-baseline', 'middle').text('Total')
      modulePau
      .append('text') 
      .attr('x', 220).attr('y', 40).attr('fill', '#F7BACF') .style('font-size', 9).style('font-family', 'roboto').attr('text-anchor', 'end').attr('alignment-baseline', 'middle').text('Completed')
      modulePau
      .append('text')
      .attr('x', 280).attr('y', 40).attr('fill', '#F7BACF') .style('font-size', 9).style('font-family', 'roboto').attr('text-anchor', 'end').attr('alignment-baseline', 'middle').text('Outstnading')

      modulePau
      .append('text')
      .attr('x', 160).attr('y', 115).attr('fill', '#F7BACF') .style('font-size', 9).style('font-family', 'roboto').attr('text-anchor', 'end').attr('alignment-baseline', 'middle').text('Total')
      modulePau
      .append('text')
      .attr('x', 220).attr('y', 115).attr('fill', '#F7BACF') .style('font-size', 9).style('font-family', 'roboto').attr('text-anchor', 'end').attr('alignment-baseline', 'middle').text('Completed')
      modulePau
      .append('text')
      .attr('x', 280).attr('y', 115).attr('fill', '#F7BACF') .style('font-size', 9).style('font-family', 'roboto').attr('text-anchor', 'end').attr('alignment-baseline', 'middle').text('Outstnading')


      // Module Status
      modulePau
      .append('text')
      .attr('x', 160).attr('y', 57).attr('fill', '#fff') .style('font-size', 14).style('font-family', 'roboto').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
      .text(this.modTotal)
      modulePau
      .append('text')
      .attr('x', 220).attr('y', 57).attr('fill', '#fff') .style('font-size', 14).style('font-family', 'roboto').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
      .text(this.modSailedaway)
      modulePau
      .append('text')
      .attr('x', 280).attr('y', 57).attr('fill', '#fff') .style('font-size', 14).style('font-family', 'roboto').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
      .text(this.modRemain)

      // PAU Status
      modulePau
      .append('text')
      .attr('x', 160).attr('y', 132).attr('fill', '#fff') .style('font-size', 14).style('font-family', 'roboto').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
      .text(this.pauTotal)
      modulePau
      .append('text')
      .attr('x', 220).attr('y', 132).attr('fill', '#fff') .style('font-size', 14).style('font-family', 'roboto').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
      .text(this.pauSailedaway)
      modulePau
      .append('text')
      .attr('x', 280).attr('y', 132).attr('fill', '#fff') .style('font-size', 14).style('font-family', 'roboto').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
      .text(this.pauRemain)

      
      modulePau
      .append('rect')
      .attr('x', 518).attr('y', 0).attr('rx', 5).attr('ry', 5).attr('width', 606).attr('height', 150)
      .attr('fill', '#EC407A')

      // Text of Title
      modulePau
      .append('text')
      .attr('x', 533).attr('y', 25).attr('fill', '#fff') .style('font-size', 20).style('font-family', 'roboto').attr('text-anchor', 'start').attr('alignment-baseline', 'middle').text('Regulatory')
      modulePau
      .append('text')
      .attr('x', 534).attr('y', 40).attr('fill', '#BDBCBC') .style('font-size', 10).style('font-family', 'roboto').attr('text-anchor', 'start').attr('alignment-baseline', 'middle').text('No. of')
    },
  }
}
