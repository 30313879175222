import * as d3 from 'd3'
import { contourDensity } from 'd3'
export default {
  methods: {
    drawAreaStatus() {
      this.DataItems.forEach(item => {
        let target = this.innerSvg.select(`#${item.AREA}`)
        if(target.empty()) return

        // let translate = (target.attr('transform') || '').replace(/\s/g, '').match(/translate(.*?)\)/g)
        let translate = (target.attr('transform') || '').match(/translate(.*?)\)/g)
        let dimention = translate[0].match(/-?\d+\.?(\d+)?/g)

        let style = [
          { name: 'Structure' , bColor:'#83D2F5', },
          { name: 'Piping'    , bColor:'#E53935', },
          { name: 'EIT'       , bColor:'#FFDA00', },
          { name: 'HVAC'      , bColor:'#8BC248', },
          { name: 'Painting'  , bColor:'#F7BACF', },
          { name: 'Others'    , bColor:'#2196F3', },
        ]
        let data = [
          { TOTAL: item.STR_TOTAL || 0, ACTUAL: item.STR_ACTUAL || 0 },
          { TOTAL: item.PIP_TOTAL || 0, ACTUAL: item.PIP_ACTUAL || 0 },
          { TOTAL: item.EIT_TOTAL || 0, ACTUAL: item.EIT_ACTUAL || 0 },
          { TOTAL: item.HV_TOTAL  || 0, ACTUAL: item.HV_ACTUAL  || 0 },
          { TOTAL: item.PAT_TOTAL || 0, ACTUAL: item.PAT_ACTUAL || 0 },
          { TOTAL: item.OTH_TOTAL || 0, ACTUAL: item.OTH_ACTUAL || 0 },
        ]
        let _self = this
        let chart = this.innerSvg.select('#SummarybyArea_P3') // jiyoun XD name
        .append('g')
        .attr('transform', `translate(${Number(dimention[0]) + 100}, ${Number(dimention[1]) + 20})`) 

        // Area No.
        chart
        .append('text')
        .attr('transform', `translate(-100, 0)`) 
        .style('font-family', 'Roboto-Regular, Roboto')
        .style('font-size', 18)
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text(item.AREA)
        console.log(item)

        chart
        .append('text')
        .attr('transform', `translate(-100, -17)`) 
        .style('font-family', 'Roboto-Regular, Roboto')
        .style('font-size', 11)
        .style('fill','#0277BD')
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text(item.AREA_DESCR)

        // Plan Cuml Label & Value
        chart
        .append('text')
        .attr('transform', `translate(-99, 15)`)
        .style('font-family', 'Roboto-Regular, Roboto')
        .style('font-size', 9)
        .style('fill', '#bdbcbc')
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text('Plan Cuml.')

        chart
        .append('text')
        .attr('transform', `translate(-50, 14)`)
        .style('font-family', 'Roboto-Regular, Roboto')
        .style('font-size', 11)
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text(`${item.PLAN_PROG.toFixed(1)}%`)

        // Actual Cuml Label & Value
        chart
        .append('text')
        .attr('transform', `translate(-10, 15)`)
        .style('font-family', 'Roboto-Regular, Roboto')
        .style('font-size', 9)
        .style('fill', '#bdbcbc')
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text('Actual Cuml.')
        
        chart
        .append('text')
        .attr('transform', `translate(75, 14)`)
        .style('font-family', 'Roboto-Regular, Roboto')
        .style('font-size', 11)
        .style('fill', '#44a9df')
        .attr('text-anchor', 'end')
        .attr('alignment-baseline', 'middle')
        .text(`${item.ACTUAL_PROG.toFixed(1)}%`)

        // Button & Label
        let buttonWrap = chart
        .append('g')
        .attr('id', 'button_wrapper')
        .attr('transform', `translate(38, -10)`)
        .style('font-family', 'roboto')
        .style('cursor', 'pointer') 
        .on('mouseover', function(){ 
          d3.select(this).transition().duration(100).style('opacity',.5) 
        })
        .on('mouseout', function(){ 
          d3.select(this).transition().duration(100).style('opacity',1) 
        })
        .on('click', () => {
          if (['HULL-FOR', 'HULL-AFT', 'HULL-COMMON', 'LQ'].includes(item.AREA)) {
            let request_ = this.getRequestColumnProps('level2', { GWBS_LV2: item.AREA }, this.ColumnProps)
            if(request_) this.$emit('request-action', request_)
          } else {
            let request_ = this.getRequestColumnProps('area', { GWBS_LV3: item.AREA }, this.ColumnProps)
            if(request_) this.$emit('request-action', request_)
          }
        })

        buttonWrap
        .append('rect')
        .attr('transform', `translate(0, 0)`)
        .attr('rx', 3)
        .attr('ry', 3)
        .attr('width', 40)
        .attr('height', 14)
        .attr('fill', '#e5e5e5')

        buttonWrap
        .append('text')
        .attr('transform', `translate(20, 8)`)
        .style('font-family', 'Roboto-Regular, Roboto')
        .style('font-size', 9)
        .style('fill', '#000')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text('More')

        this.Bar_Activity_02(chart, item.AREA, -55, 15, data, 10, 100, 13, 10, style, 'off')

        let requestImgs = ['MW01', 'MW02', 'MW04', 'MW05', 'ME01', 'ME02', 'ME04', 'ME05','FL01','KO01','Rcxx','LQ', 'HULL-FOR', 'HULL-AFT','HULL-COMMON']

        if (requestImgs.includes(item.AREA) || requestImgs.includes(item.GWBS_LV2)) {
          d3.select(`#img_${item.AREA}`)
          .on('mouseover', function(){ 
            d3.select(this).style('cursor', 'pointer')
          })
          .on('click', () => {
            let request_ = this.getRequestColumnProps('area', { GWBS_LV3: item.AREA }, this.ColumnProps)
            if(request_) this.$emit('request-action', request_)
          })

          d3.select(`#img_${item.GWBS_LV2}`)
          .on('mouseover', function(){ 
            d3.select(this).style('cursor', 'pointer')
          })
          .on('click', () => {
            let request_ = this.getRequestColumnProps('level2', { GWBS_LV2: item.AREA }, this.ColumnProps)
            if(request_) this.$emit('request-action', request_)
          })
        }
          
          target.remove()
        })
    }
  }
}
