import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  methods: {
    drawChart() {
      this.svg
      .append('image')
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Images/SafetyPyramid.png`)
      .attr('transform', `translate(0,0) scale(.8)`)
    },
  }
}