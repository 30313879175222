import * as d3 from 'd3'

export default {

  methods: {
    /*
      load_Lev3()
      async load_Photos()
      draw_PhotoTitle()
      draw_SEI_List()
      unload_Lev3()
    */
    load_Lev3() {

      // Calculate the scale value and coordinates in 'set_LocalValues.js'
      let area_ = this.Areas.find(f => f.code == this.route.to.area)
      
      d3.select('#HeaderTitle_Area')
      .transition().duration(500)
      .attr('fill', '#44A9DF')
      .text(`${area_.code} - ${area_.name}`)

      this.innerSvg = this.svg.append('svg')
      this.setPriority()
      
      this.Areas.forEach(d=> {
        if(d.code == area_.code) {
          d3.select(`#PLAN-${d.code}`)
          .transition().duration(300)
          .style('opacity', .7)
        }
        else {
          d3.select(`#PLAN-${d.code}`)
          .transition().duration(300)
          .style('opacity', 0)
        }
      })

      // After loading the SVG, perform initialization
      this.loadSvg(
        this.innerSvg, {
        x   : 0,
        y   : 0,
        url : `/China1/Construction_Photo/svg/${this.route.to.area}.svg`
      }).then(() => {
        
        this.rawData.filter(f=> f.AREA == this.route.to.area).forEach((d,i)=> {
          d3.select(`#BOX-${d.SEI}`).transition().duration(10).style('opacity', 0)
          d3.select(`#CODE-${d.SEI}`).transition().duration(10).style('opacity', 0)
          d3.select(`#PLATE-${d.SEI}`).transition().duration(10).attr('font-weight', 'bold')
          d.cx =  d3.select(`#PLATE-${d.SEI}`).select('circle').attr('cx')
          d.cy =  d3.select(`#PLATE-${d.SEI}`).select('circle').attr('cy')
        })
        this.draw_SEI_List()
        this.load_Photos()
      })
    },

    async load_Photos(){

      let photoCount = this.Areas.find(f=> f.code == this.route.to.area).photoNo - 1
      
      this.Photos.filter(f=> f.AREA == this.route.to.area).forEach(async(d,i) => {
        
        let sei = this.rawData.find(f=> f.SEI == d.SEI)
        d3.select(`#FRAME-${i}`).style('opacity', 0)

        if(i > photoCount || !(sei)) return
        
        let img_x = d3.select(`#FRAME-${i}`).node().getBBox().x
        let img_y = d3.select(`#FRAME-${i}`).node().getBBox().y

        this.innerSvg
        .append('image')
        .attr('id',`photo_${i}`)
        .attr('transform', `translate(${img_x}, ${img_y})`)
        .attr('xlink:href', `${this.__HOST_NAME}/shared/gallery/photo/${d.NAME}`)
        .style('width', 253)
        .style('cursor', 'pointer')
        .call(this.call_PhotoList, sei)
        await this.handleImage(`#photo_${i}`)

        this.draw_PhotoTitle({sei: d.SEI, no: i, x: img_x, y: img_y})
      })
    },
    
    draw_PhotoTitle(d){

      let img_height = d3.select(`#photo_${d.no}`).node().getBBox().height
      let sei = this.rawData.find(f=> f.SEI == d.sei)

      this.innerSvg
      .append('g')
      .attr('id', `Photo_Group_${d.no}`)
      .attr('transform', `translate(${d.x}, ${d.y})`)

      d3.select(`#Photo_Group_${d.no}`)
      .append('text')
      .attr('x', 5).attr('y', 3)
      .attr('fill', '#000').attr('font-size', 12)
      .attr('font-family', 'roboto')
      .text(sei.SEI)
      .call(this.draw_gmx_TextBox_v1, {
        selection: this.innerSvg, 
        id: `#Photo_Group_${d.no}`,
        x: 0,
        y: 0,
        padding: {left: 5, right: 5, top: 3, bottom: 3},
        color: `url(#PHOTO)`,
        border:{ color: '#fff', size: 2, rx: 0, ry: 0 },
        opacity: 1 
      })

      this.draw_gmx_TextBox_Wrap_v1(
        this.innerSvg, 
        {
          text: sei.SEI_ENG,
          width: 230,
          x: -1,
          y: img_height-15,
          id: {parents: `#Photo_Group_${d.no}`, group: `Pgroup_${d.no}`, rect: `Prect_${d.no}`},
          box: {visible: 'Y', color: '#fff', opacity: 0.8},
          padding: {left: 5, right: 5, top: 5, bottom: 5},
          font:{color: '#333', size: 10,},
          border:{color: '#ccc', size: 0, rx: 0, ry: 0},
        },
        null
      )
    },

    draw_SEI_List() {
      let sei_List = this.innerSvg
      .append('g')
      .attr('id', 'sei_List_group')
      // .attr('transform', `translate(10, 140)`)
      .attr('transform', `translate(10, 320)`)

      this.rawData.filter(f=> f.AREA == this.route.to.area).forEach((d,i)=> {

        sei_List.append('g')
        .attr('id', `SEI-${d.SEI}`)
        .attr('transform', `translate(0, ${i*22})`)
        .style('cursor', 'pointer')
        .call(this.call_SEIList, d)
        
        .append("rect")
        .attr('x', 12).attr('y', -11)
        .attr('width', 45).attr('height', 14).attr('opacity', 0.7)
        .style('fill', 'url(#PHOTO)' )

        d3.select(`#SEI-${d.SEI}`)
        .append('text')
        .attr('x', 15).attr('y', 0)
        .attr('fill', '#000').attr('font-size', 11)
        .text(d.SEI)

        d3.select(`#SEI-${d.SEI}`)
        .append('text')
        .attr('x', 63).attr('y', 0)
        .attr('fill', '#757575').attr('font-size', 10)
        .attr('font-family', 'roboto')
        .text(d.SEI_ENG)
        .call(this.draw_gmx_TextBox_v1, {
          selection: sei_List, 
          id: `#SEI-${d.SEI}`,
          x: 0,
          y: 0,
          padding: {left: 0, right: 5, top: 4, bottom: 4},
          color: '#fff',
          border:{ color: '#333', size: 0, rx: 0, ry: 0 },
          opacity: 1
        })
      })
    },

    unload_Lev3() {
      return new Promise(resolve => {
        setTimeout(() => {
          this.innerSvg.remove()
          resolve() 
        }, 350)
      })
    },
    
  }
}