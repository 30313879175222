import * as d3 from 'd3'

export default {
  methods: {
    Export_Hull_LQ(d,k) {

      if (d.stage == 'Division_Lv3' && d.mask == 'LQ' ) {
  
        // setTimeout(() => {
        //   // Remove SVG
        //   this.removeSvg(this.CANVAS, 10)
        // },1000);

        // Hidden
        this.hiddenGroupID('#Menu_Back', 0, 10)
        this.hiddenGroupID('#Mask_LQ01', 0, 10)
        this.hiddenGroupID('#Mask_LQ02', 0, 10)
        this.hiddenGroupID('#Mask_LQ03', 0, 10)
        this.hiddenGroupID('#Mask_LQ04', 0, 10)
        this.hiddenGroupID('#Mask_LQ05', 0, 10)
        this.hiddenGroupID('#Mask_LQ06', 0, 10)
        this.hiddenGroupID('#Mask_LQ07', 0, 10)

        setTimeout(() => {
        // Explode
        this.Hull_LQ.forEach(v => {
          // f(d.BLK == i_level)
          // Jiyoun 2020-08-04
          // 단순비교로는 소속된 하위 이미지를 찾을 수 없으므로, include를 사용하여
          // 관련 이미지 모두 처리할 수 있도록 조건절 수정
          if(v.BLK.includes(d.level)) {
            // 따라서, _level 대신 d.BLK을 id검색으로 사용
            d3.select(`#Blk_${v.BLK}`).transition().duration(1000).attr('transform', `translate(${v.x5}, ${v.y5}) scale(${1})`)
          } else {
            this.hiddenGroupID(`#Blk_${v.BLK}`, 0, 500)
          }
         })
        },3200);

        setTimeout(() => {
          this.removeSvg(d3.select('#Progress_Chart'), 10)
        }, 5000)


      setTimeout(() => {

        this.loadSvg(0, 0, `/TORTUE/Equipment/SVG/${d.level}.svg`).then((innerSvg) => {
          this.Draw_EQ_Code(innerSvg, d.level)
          this.Draw_EQ_Tag(innerSvg, d.level)
          this.Draw_EQ_Block(innerSvg, d.level)
          this.innerSvg = innerSvg
       })

        this.Lev1 = 'HULL'
        this.Lev2 = 'LQ'
        this.Lev4 = d.level
        this.stage = this.STG_Division_Lv3
        this.fncName = 'Goto_LQ_Level'
        this.StageCode() // AREA / STAGE
        this.displayGroupID(`#Menu_Back`, 0, 200, 1)

        // ### Call Canvas ###
        this.drawProgress(d.level, false)
      }, 5100);

      }

    },

  }
}
           