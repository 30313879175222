import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({
    
  }),


  methods: {
    drawButtonMove(parentSelection) {
      if(this.modeExport) return

      parentSelection
      .append('image')
      .attr('id', 'Prev')
      .attr('transform', `translate(300,-7)`)
      .attr('width', 12).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/icon/svg/Prev.svg`)
      .style('cursor', 'pointer')
      .on('mouseover', () => {
        d3.select(`#Prev`).transition().duration(100).attr('transform', `translate(300,-7) scale(1.2)`)
      })
      .on('mouseout', () => {
        d3.select(`#Prev`).transition().duration(100).attr('transform', `translate(300,-7)`)
      })
      .on('click', () => {
        this.moveX += 308

        if (this.moveX == 0) {
          d3.select(`#Prev`).attr('visibility', 'hidden')
          d3.select(`#Next`).attr('visibility', 'display')
        } else {
          d3.select(`#Prev`).attr('visibility', 'display')
          d3.select(`#Next`).attr('visibility', 'display')
        }
        d3.select(`#moduleStatus`).transition().duration(500)
        .attr('transform', () => {
          // console.log(this.moveX)
          return `translate(${this.moveX},0)`
        })

      })

      parentSelection
      .append('image')
      .attr('id', 'Next')
      .attr('transform', `translate(320,-7)`)
      .attr('width', 12).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/icon/svg/Next.svg`)
      .style('cursor', 'pointer')
      .on('mouseover', () => {
        d3.select(`#Next`).transition().duration(100).attr('transform', `translate(320,-7) scale(1.2)`)
      })
      .on('mouseout', () => {
        d3.select(`#Next`).transition().duration(100).attr('transform', `translate(320,-7)`)
      })
      .on('click', () => {
        this.moveX -= 308
        if (this.moveX < -5852) this.moveX = -5852

        if (this.moveX == -5852) {
          d3.select(`#Next`).attr('visibility', 'hidden')
          d3.select(`#Prev`).attr('visibility', 'display')
        } else {
          d3.select(`#Next`).attr('visibility', 'display')
          d3.select(`#Prev`).attr('visibility', 'display')
        }
        d3.select(`#moduleStatus`).transition().duration(500)
        .attr('transform', () => {
          // console.log(this.moveX)
          return `translate(${this.moveX},0)`
        })

      })
    },
    
    drawChart() {

      let drawX = 0
      let drawY = 0

      let arrow = this.svg
      .append('g')
      .attr('transform', `translate(10,20)`)

      arrow
      .append('text')
      .attr('x', 0).attr('y', 0)
      .style('font-family', 'roboto').attr('font-size', 16).attr('fill', '#333').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Status of System Completion by Module')

      this.drawButtonMove(arrow)

      this.dataSet.forEach((data,i) => {

        // Module images
        let module = this.ModuleStatus
        .append('g')
        .attr('transform', `translate(${drawX + 20},15)`)
          
          module
          .append('image')
          .attr('id', `MOD_${i}`)
          .attr('opacity', 1)
          .attr('x', 0).attr('y', 20).attr('width', 200).attr('height', 150)
          .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TCO/Modules/Images/size_500/${data.MOD}.jpg`)
          .style('cursor', 'pointer')
          .on('mouseover', () => {
            d3.select(`#MOD_${i}`).transition().duration(300).attr('opacity', 0.5)
          })
          .on('mouseout', () => {
            d3.select(`#MOD_${i}`).transition().duration(300).attr('opacity', 1)
          })
          .on('click', () => {
            this.controlTarget(this.target, i)
            console.log(this.dataSet[this.target].MOD, this.dataSet[i].MOD)
          })
          
          
          module
          .append('text')
          .attr('x', 20).attr('y', 170)
          .style('font-family', 'roboto').attr('font-size', 15).attr('fill', '#333').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
          .text(data.MOD)

          module
          .append('text')
          .attr('x', 20).attr('y', 182)
          .style('font-family', 'roboto').attr('font-size', 11).attr('fill', '#BDBCBC').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
          .text(data.MOD_DESC)

        // Line
        let vLine = this.ModuleStatus
        .append('g')
        .attr('transform', `translate(${drawX - 1},0)`)
        
          vLine
          .append('line')
          .attr('x1', -1.5).attr('y1', 50)
          .attr('x2', -1.5).attr('y2', 450).attr('stroke', '#BCBCBC').attr('stroke-width', 0.3)
        
        //Summary
        let summary = this.ModuleStatus
        .append('g')
        .attr('transform', `translate(${drawX + 40},220)`)

          summary
          .append('text')
          .attr('x', 120).attr('y', -5)
          .style('font-family', 'roboto').attr('font-size', 8).attr('fill', '#BDBCBC').attr('text-anchor', 'start').attr('alignment-baseline', 'middle').text('Total')

          summary
          .append('text')
          .attr('x', 160).attr('y', -5)
          .style('font-family', 'roboto').attr('font-size', 8).attr('fill', '#BDBCBC').attr('text-anchor', 'start').attr('alignment-baseline', 'middle').text('Remain')

          summary // Construction
          .append('rect')
          .attr('x', 0).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', 70).attr('height', 22)
          .attr('stroke', '#bcbcbc')
          .attr('stroke-width', () => {
            if (data.OV_A_PP == 100) return 0; else return 0.5
          })
          .attr('fill', () => {
            if (data.OV_A_PP == 100) return '#31A1D8'; else return '#fff'
          })

          summary
          .append('text')
          .attr('x', 35).attr('y', 11)
          .style('font-family', 'roboto').attr('font-size', 11).attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
          .attr('fill', ()=> {
            if (data.OV_A_PP == 100) return '#fff'; else return '#bcbcbc'
          })
          .text('Construction')

          summary
          .append('text')
          .attr('x', 60).attr('y', 40)
          .style('font-family', 'roboto').attr('font-size', 16).attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
          .attr('fill', () => {
            if (data.OV_A_PP == 100) return '#44A9DF'; else return '#bcbcbc'
          })
          .text(`${data.OV_A_PP}%`)


          summary // System Completion
          .append('rect')
          .attr('x', 75).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', 115).attr('height', 75).attr('fill', '#5EBCE9').attr('stroke-width', 0)
          summary
          .append('rect')
          .attr('x', 75).attr('y', 80).attr('rx', 3).attr('ry', 3).attr('width', 115).attr('height', 20).attr('fill', '#94D5F5').attr('stroke-width', 0)
          summary
          .append('rect')
          .attr('x', 75).attr('y', 105).attr('rx', 3).attr('ry', 3).attr('width', 115).attr('height', 20).attr('fill', '#B4E3FA').attr('stroke-width', 0)


          summary // PCOW
          .append('rect')
          .attr('x', 195).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', 40).attr('height', 75).attr('fill', '#F4F5F5').attr('stroke-width', 0)
          summary
          .append('rect')
          .attr('x', 195).attr('y', 80).attr('rx', 3).attr('ry', 3).attr('width', 40).attr('height', 20).attr('fill', '#F4F5F5').attr('stroke-width', 0)

          summary
          .append('text')
          .attr('x', 195 + 20).attr('y', 11)
          .style('font-family', 'roboto').attr('font-size', 11).attr('fill', '#818181').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
          .text('PCOW')

          // Values
          summary.append('text')
          .attr('x', 75 + 5).attr('y', 11)
          .style('font-family', 'roboto').attr('font-size', 11).attr('fill', '#fff').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
          .text('System Completion')

          // A1
          summary.append('text')
          .attr('x', 75 + 5).attr('y', 31)
          .style('font-family', 'roboto').attr('font-size', 10).attr('fill', '#333').attr('text-anchor', 'start').attr('alignment-baseline', 'middle').text('A1')


          // Punch A
          summary .append('text')
          .attr('x', 75 + 5).attr('y', 51)
          .style('font-family', 'roboto').attr('font-size', 10).attr('fill', '#fff').attr('text-anchor', 'start').attr('alignment-baseline', 'middle').text('Punch A')

          // Punch B
          summary .append('text')
          .attr('x', 75 + 5).attr('y', 66)
          .style('font-family', 'roboto').attr('font-size', 10).attr('fill', '#fff').attr('text-anchor', 'midstartdle').attr('alignment-baseline', 'middle').text('Punch B')

          // B1
          summary .append('text')
          .attr('x', 75 + 5).attr('y', 90)
          .style('font-family', 'roboto').attr('font-size', 10).attr('fill', '#333').attr('text-anchor', 'start').attr('alignment-baseline', 'middle').text('B1')

          // D1
          summary .append('text')
          .attr('x', 75 + 5).attr('y', 116)
          .style('font-family', 'roboto').attr('font-size', 10).attr('fill', '#333').attr('text-anchor', 'start').attr('alignment-baseline', 'middle').text('D1')

        drawX += 308
      })
    },


  }
}
