
// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  data: () => ({
    thead: [
      { key: 'PERIOD', html: 'Period', row: 1, rowspan: 2 },
      { key: 'WELD', html: 'Weld', row: 1, rowspan: 2 },
      { key: 'DESC', html: 'NDT', row: 1, rowspan: 2 },
      { key: 'ACCEPT', html: 'Accept', row: 1, rowspan: 2 },
      { key: 'REJECT', html: 'Reject', row: 1, rowspan: 2 },
      { key: 'REPAIR_RATE', html: 'Repair Rate', row: 1, rowspan: 2 },

    ],
    tbody: [
      
      { key: 'PERIOD', w: 70, align: 'center', rowspan: 'Y' },
      { key: 'WELD', w: 85, align: 'center', rowspan: 'Y',type: 'number' },
      { key: 'DESC', w: 85, align: 'center' },
      { key: 'ACCEPT', w: 85, align: 'center',type: 'number'  },
      { key: 'REJECT', w: 85, align: 'center' ,type: 'number' },
      { key: 'REPAIR_RATE', w: 85, align: 'center',type: 'number'  },

    ],
    
    tableWidth: 580,
  }),
  methods: {
    Draw_Charts() {
      let tableData = this.svg

        let table_group = tableData.append('g').attr('transform', `translate(0, 0)`).attr('class', `block_table_group_`)

        // SET HERE!
        let list = this.DataItems
        let tableViewHeight = 96 +700 // thead + tbody
        // let tableViewHeight = 96 + (list.length * 18) // thead + tbody
  
  
        let table_obj = table_group.append('foreignObject')
          .attr('y',0)
          .attr('width', '100%')
          .attr('height', `${tableViewHeight}px`)
          .append('xhtml:body')
  
        let table = table_obj.append('table')
                    .style('border', 'solid 0.7px #eee')
                    .style('border-collapse', 'collapse')
                    .style('font-size', '11px')
                    .style('width', `${this.tableWidth}px`)
                    .style('table-layout', 'fixed')
  
        let colgroup = table.append('colgroup')
        this.tbody.forEach(k => {
          colgroup.append('col').style('width', k.w + 'px')
        })
  
        let maxRow = Math.max(...this.thead.map(d => d.row))
  
        let thead = table.append('thead')
        for (let r = 1; r <= maxRow; r++) {
          let row = this.thead.filter(d=> d.row === r)
          let tr = thead.append('tr').style('background', '#F5F5F5')
  
          row.forEach(item => {
            tr.append('th')
            .style('height', '24px')
            .attr('class', 'completion_dashboard_table_group___th')
            .attr('rowspan', () => item.rowspan ? item.rowspan : '')
            .attr('colspan', () => item.colspan ? item.colspan : '')
            .style('border', 'solid 0.7px #eee')
            .style('padding', '3px')
            .style('font-weight','bold')
            .style('font-size', '11px')
            .style('color', '#fff')
            .style('background','#607D8B')
            .html(item.html)
          })        
        }
          
        let tbodykeys = this.tbody
        let tbody = table.append('tbody')
        if (list.length == 0) {
          let tr = tbody.append('tr')
          tr.append('td')
            .attr('colspan', 6)
            .style('background', '#F5F5F5')
            .style('height', '24px')
            .style('text-align', 'center')
            .style('color', '#424242')
            .style('padding', '0 3px 0 3px')
            .style('font-style', 'italic')
            .html('No data')
          
          return
        }
        let bidpackage = ''
        let status = ''
        let status_bidpackage = ''

        for (let r = 0; r < list.length; r++) {
          let tr = tbody.append('tr')
          // tr.on('mouseover', () => {
          //   tr.style('cursor', 'pointer')
          //   tr.style('background-color', '#C5E1A5')
          // })
          // tr.on('mouseout', () => {
          //   tr.style('background-color', '#fff')
          // })
          //tr mouseover color change

          tbodykeys.forEach((k, i) => {
            if (i == 0 && list[r][k.key] == bidpackage) return
            if (i == 1 && list[r][k.key] == status && status_bidpackage == list[r]['PERIOD']) return
            else {
              if (i == 0) {
                bidpackage = list[r][k.key]
              }else if (i == 1) {
                status = list[r][k.key]
                status_bidpackage = list[r]['PERIOD']
              }
            }

            let td = tr.append('td')
            .style('border', 'solid 0.7px #E4E4E4')
            .style('height', '22px')
            .style('text-align', k.align ? k.align : 'right')
            .style('padding', '0 3px 0 3px')
            .style('background', r==3 ? '#ECEFF1':r==2 ? '#ECEFF1':'#fff' )
            .attr('rowspan', () => {
              if (k.rowspan == 'Y') {
                // let filtered = list.filter(d => d[k.key] == list[r][k.key])
                let filtered = i == 0 ? list.filter(d => d[k.key] == list[r][k.key]) : list.filter(d => d['PERIOD'] == list[r]['PERIOD'] && d['WELD'] == list[r]['WELD'] )
                return filtered.length
              }
            })
            // td.html(list[r][k.key])
            td.html(k.type && k.type == 'number' && list[r][k.key] ? list[r][k.key].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : list[r][k.key])

          })
        }
    },
  }
}
