import * as d3 from 'd3'
import { json } from 'd3'

export default {
  methods: {
    
    setEvent_Overall() {

      // Goto_Division_Lv1
      // ---------------------------------------------------------------------------------------------------------------------
      this.Goto_Division_Lv1 = (selection) => {
        selection
        .on('mouseover', () => {
          if(this.stage != this.STG_Overall) return
          d3.select(`#Mask_Overall`).transition().style('opacity', 0.5)
        })
        .on('mouseout', () => {
          if(this.stage != this.STG_Overall) return
          d3.select(`#Mask_Overall`).transition().style('opacity', 0)
        })
        .on('click', () => {
          if(this.stage != this.STG_Overall) return

          // Remove SVG
          this.removeSvg(this.CANVAS, 1000)

          // Hidden
          this.hiddenGroupID('#Mask_Overall', 0, 10)

          d3.select(`#Topside_Group`).transition().duration(1000).attr('transform', `translate(0, -100)`)
          d3.select(`#Area_AFT`).transition().duration(1000).attr('transform', `translate(418, 668) scale(0.75)`)
          d3.select(`#Area_FOR`).transition().duration(1000).attr('transform', `translate(727, 653) scale(0.75)`)
          d3.select(`#Area_LQ`).transition().duration(1000).attr('transform', `translate(983, 663) scale(0.75)`)
          d3.select(`#Area_VS`).transition().duration(1000).attr('transform', `translate(926, 734) scale(0.75)`)

          setTimeout(() => {

            // Display
            this.displayGroupID(`#Menu_Back`, 10, 10, 1)
            this.displayGroupID(`#Keyplan_Group`, 10, 10, 1)
            this.displayGroupID(`#Legends`, 10, 10, 1)
            this.displayGroupID(`#Mask_Topside`, 10, 10, 0)
            this.displayGroupID(`#Mask_Hull`, 10, 10, 0)

            this.stage = this.STG_Division_Lv1
            let dStage = {
              stage: this.STG_Division_Lv1,
              mask : 'OVERALL',
              level: ''
            }
            this.selectedItems.push(dStage)
            this.fncName = 'Goto_Division_Lv1'
            this.StageCode() // AREA / STAGE
            this.displayGroupID(`#Menu_Back`, 0, 200, 1)

            // ### Call Canvas ###
            this.Canvas_Overall_Division_Lv1()
          }, 1000);
        })
      }


      // Goto_Hull_Division_Lv2
      // ---------------------------------------------------------------------------------------------------------------------
      this.Goto_Hull_Division_Lv2 = (selection, mask) => {
        selection
        .on('mouseover', () => {
          if(this.stage != this.STG_Division_Lv1) return
          d3.select(`#${mask}`).transition().style('opacity',0.5)
        })
        .on('mouseout', () => {
          if(this.stage != this.STG_Division_Lv1) return
          d3.select(`#${mask}`).transition().style('opacity',0)
        })
        .on('click', () => {
          if(this.stage != this.STG_Division_Lv1) return

          // Remove SVG
          this.removeSvg(this.CANVAS, 1000)

          // Title & Keyplan
          d3.select(`#Title`).text('Hull & LQ')
          d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Keyplan/HULL.png`)

          // Hidden
          this.hiddenGroupID('#Menu_Back', 0, 10)

          this.hiddenGroupID('#Mask_Topside', 0, 10)
          this.hiddenGroupID('#Mask_Hull', 0, 10)

          this.hiddenGroupID('#Topside_Group', 0, 1000)

          d3.select(`#Area_AFT`).transition().duration(1000).attr('transform', `translate(364, 477) scale(0.75)`)
          d3.select(`#Area_FOR`).transition().duration(1000).attr('transform', `translate(842,496) scale(0.75)`)
          d3.select(`#Area_LQ`).transition().duration(1000).attr('transform', `translate(1109,295) scale(0.75)`)
          d3.select(`#Area_VS`).transition().duration(1000).attr('transform', `translate(1041,577) scale(0.75)`)

          setTimeout(() => {

            // Mask Display
            this.displayGroupID(`#Mask_Hull_AFT`, 10, 10, 0)
            this.displayGroupID(`#Mask_Hull_FOR`, 10, 10, 0)
            this.displayGroupID(`#Mask_Hull_LQ`, 10, 10, 0)

            this.Lev1 = 'HULL'
            this.stage = this.STG_Division_Lv2
            let dStage = {
              stage: this.STG_Division_Lv2,
              mask : mask,
              level: ''
            }
            this.selectedItems.push(dStage)
            this.fncName = 'Goto_Hull_Division_Lv2'
            this.StageCode() // AREA / STAGE
            this.displayGroupID(`#Menu_Back`, 0, 200, 1)

            // ### Call Canvas ###
            this.Canvas_Hull_Division_Lv2()
          }, 1000);

        })
      }

      // Goto_TS_Division_Lv2
      // ---------------------------------------------------------------------------------------------------------------------
      this.Goto_TS_Division_Lv2 = (selection, mask) => {
        selection
        .on('mouseover', () => {
          if(this.stage != this.STG_Division_Lv1) return
          d3.select(`#${mask}`).transition().style('opacity',0.5)
        })
        .on('mouseout', () => {
          if(this.stage != this.STG_Division_Lv1) return
          d3.select(`#${mask}`).transition().style('opacity',0)
        })
        .on('click', () => {
          if(this.stage != this.STG_Division_Lv1) return

          // Remove SVG
          this.removeSvg(this.CANVAS, 1000)

          // Title & Keyplan
          d3.select(`#Title`).text('Topside')
          d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Keyplan/TOPSIDE.png`)

          // Hidden
          this.hiddenGroupID('#Menu_Back', 0, 10)

          this.hiddenGroupID('#Mask_Topside', 0, 10)
          this.hiddenGroupID('#Mask_Hull', 0, 10)

          this.hiddenGroupID('#Area_AFT', 0, 1000)
          this.hiddenGroupID('#Area_FOR', 0, 1000)
          this.hiddenGroupID('#Area_LQ', 0, 1000)
          this.hiddenGroupID('#Area_VS', 0, 1000)

          d3.select(`#Topside_Group`).transition().duration(1000).attr('transform', `translate(0, 0)`)

          d3.select(`#FL01`)  .transition().duration(1000).attr('transform', `translate(451, 384) scale(0.75)`)
          d3.select(`#FR01`)  .transition().duration(1000).attr('transform', `translate(485.47, 676.52) scale(0.75)`)
          d3.select(`#KO01`)  .transition().duration(1000).attr('transform', `translate(384, 670) scale(0.75)`)
          d3.select(`#TSRC01`).transition().duration(1000).attr('transform', `translate(494, 676) scale(0.75)`)
          d3.select(`#TSRC02`).transition().duration(1000).attr('transform', `translate(591, 694) scale(0.75)`)
          d3.select(`#TSRC03`).transition().duration(1000).attr('transform', `translate(680, 727) scale(0.75)`)
          d3.select(`#TSRC04`).transition().duration(1000).attr('transform', `translate(780, 744) scale(0.75)`)
          d3.select(`#TSRC05`).transition().duration(1000).attr('transform', `translate(857, 759) scale(0.75)`)
          d3.select(`#LD00DA`).transition().duration(1000).attr('transform', `translate(897, 779) scale(0.75)`)


          d3.select(`#MW01`).transition().duration(1000).attr('transform', `translate(783, 221) scale(0.75)`)
          d3.select(`#MW02`).transition().duration(1000).attr('transform', `translate(870, 232) scale(0.75)`)
          d3.select(`#MW04`).transition().duration(1000).attr('transform', `translate(1076, 244) scale(0.75)`)
          d3.select(`#MW05`).transition().duration(1000).attr('transform', `translate(1133, 284) scale(0.75)`)
          d3.select(`#ME01`).transition().duration(1000).attr('transform', `translate(744, 265) scale(0.75)`)
          d3.select(`#ME02`).transition().duration(1000).attr('transform', `translate(828, 272) scale(0.75)`)
          d3.select(`#ME04`).transition().duration(1000).attr('transform', `translate(1037, 271) scale(0.75)`)
          d3.select(`#ME05`).transition().duration(1000).attr('transform', `translate(1095, 322) scale(0.75)`)

          setTimeout(() => {

            // Mask Display
            this.displayGroupID(`#Mask_TS_Common`, 10, 10, 0)
            this.displayGroupID(`#Mask_TS_Modules`, 10, 10, 0)


            this.Lev1 = 'TOPSIDE'
            this.stage = this.STG_Division_Lv2
            let dStage = {
              stage: this.STG_Division_Lv2,
              mask : mask,
              level: ''
            }
            this.selectedItems.push(dStage)
            this.fncName = 'Goto_TS_Division_Lv2'
            this.StageCode() // AREA / STAGE
            this.displayGroupID(`#Menu_Back`, 0, 200, 1)

            // ### Call Canvas ###
            this.Canvas_Topside_Division_Lv2()
          }, 1000);
          
        })
      }

      // Goto_TS_Common_Division_Lv3
      // ---------------------------------------------------------------------------------------------------------------------
      this.Goto_TS_Division_Lv3 = (selection, mask, lv2) => {
        
        selection
        .on('mouseover', () => {
          if(this.stage != this.STG_Division_Lv2) return
          d3.select(`#${mask}`).transition().style('opacity',0.5)
        })
        .on('mouseout', () => {
          if(this.stage != this.STG_Division_Lv2) return
          d3.select(`#${mask}`).transition().style('opacity',0)
        })
        .on('click', () => {
          if(this.stage != this.STG_Division_Lv2) return

          // Remove SVG
          this.removeSvg(this.CANVAS, 1000)

          // Title & Keyplan
          d3.select(`#Title`).text(`Topside_${lv2}`)
          d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Keyplan/TOPSIDE_${lv2}.png`)

          // Hidden
          this.hiddenGroupID('#Menu_Back', 0, 0)

          this.hiddenGroupID('#Mask_TS_Common', 0, 0)
          this.hiddenGroupID('#Mask_TS_Modules', 0, 0)

          
          if(lv2 == 'COMMON') {

            // hide module
            this.hiddenGroupID('#MW01', 0, 0)
            this.hiddenGroupID('#MW02', 0, 0)
            this.hiddenGroupID('#MW04', 0, 0)
            this.hiddenGroupID('#MW05', 0, 0)
            this.hiddenGroupID('#ME01', 0, 0)
            this.hiddenGroupID('#ME02', 0, 0)
            this.hiddenGroupID('#ME04', 0, 0)
            this.hiddenGroupID('#ME05', 0, 0)

            // move module
            d3.select(`#FL01`)  .transition().duration(1000).attr('transform', `translate(420, 269) scale(0.75)`)
            d3.select(`#FR01`)  .transition().duration(1000).attr('transform', `translate(539, 489) scale(0.75)`)
            d3.select(`#KO01`)  .transition().duration(1000).attr('transform', `translate(261, 630) scale(0.75)`)
            d3.select(`#TSRC01`).transition().duration(1000).attr('transform', `translate(579, 580) scale(0.75)`)
            d3.select(`#TSRC02`).transition().duration(1000).attr('transform', `translate(749, 612) scale(0.75)`)
            d3.select(`#TSRC03`).transition().duration(1000).attr('transform', `translate(907, 659) scale(0.75)`)
            d3.select(`#TSRC04`).transition().duration(1000).attr('transform', `translate(1082, 691) scale(0.75)`)
            d3.select(`#TSRC05`).transition().duration(1000).attr('transform', `translate(1234, 722) scale(0.75)`)
            d3.select(`#LD00DA`).transition().duration(1000).attr('transform', `translate(1294, 592) scale(0.75)`)
          }

          if (lv2 == 'MODULES') {

            // hide module
            this.hiddenGroupID('#FL01',   0, 0)
            this.hiddenGroupID('#FR01',   0, 0)
            this.hiddenGroupID('#KO01',   0, 0)
            this.hiddenGroupID('#TSRC01', 0, 0)
            this.hiddenGroupID('#TSRC02', 0, 0)
            this.hiddenGroupID('#TSRC03', 0, 0)
            this.hiddenGroupID('#TSRC04', 0, 0)
            this.hiddenGroupID('#TSRC05', 0, 0)
            this.hiddenGroupID('#LD00DA', 0, 0)

            // move module
            d3.select(`#MW01`).transition().duration(1000).attr('transform', `translate(581, 357) scale(0.75)`)
            d3.select(`#MW02`).transition().duration(1000).attr('transform', `translate(797, 393) scale(0.75)`)
            d3.select(`#MW04`).transition().duration(1000).attr('transform', `translate(1069, 419) scale(0.75)`)
            d3.select(`#MW05`).transition().duration(1000).attr('transform', `translate(1273, 488) scale(0.75)`)
            d3.select(`#ME01`).transition().duration(1000).attr('transform', `translate(287, 583) scale(0.75)`)
            d3.select(`#ME02`).transition().duration(1000).attr('transform', `translate(498, 614) scale(0.75)`)
            d3.select(`#ME04`).transition().duration(1000).attr('transform', `translate(774, 626) scale(0.75)`)
            d3.select(`#ME05`).transition().duration(1000).attr('transform', `translate(981, 707) scale(0.75)`)

          }

          setTimeout(() => {

            // Mask Display
            if(lv2 == 'COMMON') {
              this.displayGroupID(`#Mask_FL01`,   0, 10, 0)
              this.displayGroupID(`#Mask_FR01`,   0, 10, 0)
              this.displayGroupID(`#Mask_KO01`,   0, 10, 0)
              this.displayGroupID(`#Mask_TSRC01`, 0, 10, 0)
              this.displayGroupID(`#Mask_TSRC02`, 0, 10, 0)
              this.displayGroupID(`#Mask_TSRC03`, 0, 10, 0)
              this.displayGroupID(`#Mask_TSRC04`, 0, 10, 0)
              this.displayGroupID(`#Mask_TSRC05`, 0, 10, 0)
              this.displayGroupID(`#Mask_LD00DA`, 0, 10, 0)
            }
            if(lv2 == 'MODULES') {
              this.displayGroupID(`#Mask_MW01`, 0, 10, 0)
              this.displayGroupID(`#Mask_MW02`, 0, 10, 0)
              this.displayGroupID(`#Mask_MW04`, 0, 10, 0)
              this.displayGroupID(`#Mask_MW05`, 0, 10, 0)
              this.displayGroupID(`#Mask_ME01`, 0, 10, 0)
              this.displayGroupID(`#Mask_ME02`, 0, 10, 0)
              this.displayGroupID(`#Mask_ME04`, 0, 10, 0)
              this.displayGroupID(`#Mask_ME05`, 0, 10, 0)
            }
  
            this.Lev1 = 'TOPSIDE'
            this.Lev2 = lv2
            this.stage = this.STG_Division_Lv3
            let dStage = {
              stage: this.STG_Division_Lv3,
              mask : mask,
              level: lv2
            }
            this.selectedItems.push(dStage)
            this.fncName = 'Goto_TS_Division_Lv3'
            this.StageCode() // AREA / STAGE
            
            this.displayGroupID(`#Menu_Back`, 0, 200, 1)

            // ### Call Canvas ###
            this.Canvas_Division_Lv3(this.Lev1, this.Lev2)
          }, 1000);
          
        })
      }


      // Goto_TS_Common_Division_Lv3
      // ---------------------------------------------------------------------------------------------------------------------
      this.Goto_TS_Activate = (selection, mask, mod) => {
        
        selection
        .on('mouseover', () => {
          if(this.stage != this.STG_Division_Lv3) return
          d3.select(`#${mask}`).transition().style('opacity',0.5)
        })
        .on('mouseout', () => {
          if(this.stage != this.STG_Division_Lv3) return
          d3.select(`#${mask}`).transition().style('opacity',0)
        })
        .on('click', () => {
          if(this.stage != this.STG_Division_Lv3) return

          // Remove SVG
          this.removeSvg(this.CANVAS, 1000)

          this.hiddenGroupID(`#Mask_${mod}`, 0, 0)
          
          // Title & Keyplan
          d3.select(`#Title`).text(`TOPSIDE_${mod}`)
          d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Keyplan/TOPSIDE_${mod}.png`)

          // Hidden
          this.hiddenGroupID('#Menu_Back', 0, 10)
         

          // Remove SVG
          this.removeSvg(this.Dashboard_Overall, 1000) 

          setTimeout(() => {

            this.Activate_Topside_Module(mod)

            this.Lev1 = 'TOPSIDE'
            this.Lev3 = mod
            this.stage = this.STG_Activate
            let dStage = {
              stage: this.STG_Division_Lv3,
              mask : mask,
              level: mod
            }
            this.selectedItems.push(dStage)
            this.fncName = 'Goto_TS_Activate'
            this.StageCode() // AREA / STAGE
            this.displayGroupID(`#Menu_Back`, 0, 200, 1)

            // ### Display Canvas ###
            this.Canvas_Topside_Activate(this.Lev3) 
          }, 1000);
          
        })
      }


      // Goto_AFT_Divison_Lv3
      // ---------------------------------------------------------------------------------------------------------------------
      this.Goto_Hull_Activate = (selection, mask, lv2) => {
        selection
        .on('mouseover', () => {
          if(this.stage != this.STG_Division_Lv2) return
          d3.select(`#${mask}`).transition().style('opacity',0.5)
        })
        .on('mouseout', () => {
          if(this.stage != this.STG_Division_Lv2) return
          d3.select(`#${mask}`).transition().style('opacity',0)
        })
        .on('click', () => {
          if(this.stage != this.STG_Division_Lv2) return
          
          // Title & Keyplan
          d3.select(`#Title`).text(`Hull-${lv2}`)
          d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Keyplan/HULL_${lv2}.png`)

           // Hidden
           this.hiddenGroupID('#Menu_Back', 0, 10)

           this.hiddenGroupID('#Mask_Hull_AFT', 0, 10)
           this.hiddenGroupID('#Mask_Hull_FOR', 0, 10)
           this.hiddenGroupID('#Mask_Hull_LQ', 0, 10)
          
           // Remove SVG
           this.removeSvg(this.Dashboard_Overall, 1000) 

           // Activate
           if(lv2 =='AFT') this.Activate_Hull_AFT()
           if(lv2 =='FOR') this.Activate_Hull_FOR()
           if(lv2 =='LQ') this.Activate_Hull_LQ()
    
           this.Stage = this.STG_Activate
           let dStage = {
            stage: this.STG_Division_Lv3,
            mask : mask,
            level: lv2
           }
           this.selectedItems.push(dStage)
           this.fncName = 'Goto_Hull_Activate'
           this.StageCode() // AREA / STAGE
           this.displayGroupID(`#Menu_Back`, 0, 200, 1)
        })
      }

    },
  }
}