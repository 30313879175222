/**
 * Event Call Functions
 */

import * as d3 from 'd3'

export default {
  methods: {
    callFuncSearchFilter(selection) {
      selection
      .on('mouseover', (d, i, a) => {
        if(this.displayDataFilter.searchOption == d.colName) return

        let directionEls_ = d3.select(a[i]).attr('id').split('__')

        d3.select(`#${this.localId}`)
        .select(`.search_circle__${directionEls_[1]}`)
        .style('stroke', this.eqStyle.search.over.stroke)
        .style('fill', this.eqStyle.search.over.fill)

        d3.select(`#${this.localId}`)
        .select(`.search_text__${directionEls_[1]}`)
        .style('fill', '#000')
      })
      .on('mouseout', (d, i, a) => {
        if(this.displayDataFilter.searchOption == d.colName) return

        let directionEls_ = d3.select(a[i]).attr('id').split('__')

        d3.select(`#${this.localId}`)
        .select(`.search_circle__${directionEls_[1]}`)
        .style('stroke', this.eqStyle.search.out.stroke)
        .style('fill', this.eqStyle.search.out.fill)

        d3.select(`#${this.localId}`)
        .select(`.search_text__${directionEls_[1]}`)
        .style('fill', this.eqStyle.search.out.color)
      })
      .on('click', (d) => {
        if(this.displayDataFilter.searchOption == d.colName) return

        let prevIndex_ = d.optionValues.findIndex(v_ => v_.colName == this.displayDataFilter.searchOption)
        let index_ = d.optionValues.findIndex(v_ => v_.colName == d.colName)

        // set previous option to be unselected
        d3.select(`#${this.localId}`)
        .select(`.search_circle__${prevIndex_}`)
        .style('stroke', this.eqStyle.search.out.stroke)
        .style('fill', this.eqStyle.search.out.fill)

        d3.select(`#${this.localId}`)
        .select(`.search_text__${prevIndex_}`)
        .style('fill', this.eqStyle.search.out.color)

        // set current option to be selected
        d3.select(`#${this.localId}`)
        .select(`.search_circle__${index_}`)
        .style('stroke', this.eqStyle.search.selected.stroke)
        .style('fill', this.eqStyle.search.selected.fill)

        d3.select(`#${this.localId}`)
        .select(`.search_text__${index_}`)
        .style('fill', this.eqStyle.search.selected.color)

        this.displayDataFilter.searchOption = d.colName
        this.equipmentFilter('search')
      })
    },
    callFuncSearchInput(selection) {
      selection
      .on('keydown', (_, i, a) => {
        if(d3.event.keyCode === 13){
          // trimDivText is declared in Declares.mixins
          this.trimDivText(d3.select(a[i]))     
          setTimeout(() => { this.setCaret(document.getElementById('search_input')) })
        }
        // equipmentFilter is declared in page_equipment.mixin
        this.register(() => { this.equipmentFilter('search') }, 500) 
      })
      .on('keyup', (_, i, a) => {
        if(d3.event.keyCode === 13){
          this.trimDivText(d3.select(a[i]))
          this.setCaret(document.getElementById('search_input'))
        }
      })
    },
    callFuncLegend(selection) {
      selection
      .on('click', (d, i, a) => {
        d.on = !d.on
        if(d.on) d3.select(a[i]).style('opacity', 1)
        else d3.select(a[i]).style('opacity', 0.3)

        this.setFilter('multi', d.refColumn, d.codeValue)
        this.search(false)
      })
    },
    callFuncFilter(selection) {
      selection
      .on('mouseover', (_, i, a) => {
        d3.select(a[i]).style('cursor', 'pointer')

        let directionEls_ = d3.select(a[i]).attr('id').split('__')

        d3.select(`#${this.localId}`)
        .select(`.option_circle__${directionEls_[1]}__${directionEls_[2]}`)
        .style('stroke', this.eqStyle.search.over.stroke)
        .style('fill', this.eqStyle.search.over.fill)

        d3.select(`#${this.localId}`)
        .select(`.option_text__${directionEls_[1]}__${directionEls_[2]}`)
        .style('fill', '#000')
      })
      .on('mouseout', (d, i, a) => { 
        d3.select(a[i]).style('cursor', 'default')

        let index_ = d.filters[d.name].values.findIndex(v_ => v_.text == d.text)
        let values_ = JSON.parse(JSON.stringify(d.filters[d.name].values))
        let directionEls_ = d3.select(a[i]).attr('id').split('__')

        if(values_[index_].on) {
          d3.select(`#${this.localId}`)
          .select(`.option_circle__${directionEls_[1]}__${directionEls_[2]}`)
          .style('stroke', this.eqStyle.search.selected.stroke)
          .style('fill', this.eqStyle.search.selected.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.option_text__${directionEls_[1]}__${directionEls_[2]}`)
          .style('fill', this.eqStyle.search.selected.color)
          
        } else {
          d3.select(`#${this.localId}`)
          .select(`.option_circle__${directionEls_[1]}__${directionEls_[2]}`)
          .style('stroke', this.eqStyle.search.out.stroke)
          .style('fill', this.eqStyle.search.out.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.option_text__${directionEls_[1]}__${directionEls_[2]}`)
          .style('fill', this.eqStyle.search.out.color)
        }
      })
      .on('click', (d, i, a) => {
        let index_ = d.filters[d.name].values.findIndex(v_ => v_.text == d.text)
        let values_ = JSON.parse(JSON.stringify(d.filters[d.name].values))
        let length_ = values_.length

        if(d.type == 'single') {
          var prevIndex_ = d.filters[d.name].values.findIndex(v_ => v_.on)
          for(let i = 0; i<length_; i++) {
            if(values_[i].text == d.text) values_[i].on = true
            else values_[i].on = false
          } 
        } else {
          values_[index_].on = !values_[index_].on
        }

        d.filters[d.name].values = values_

        let directionEls_ = d3.select(a[i]).attr('id').split('__')

        if(prevIndex_ >= 0 && prevIndex_ != index_) {
          d3.select(`#${this.localId}`)
          .select(`.option_circle__${directionEls_[1]}__${prevIndex_}`)
          .style('stroke', this.eqStyle.search.out.stroke)
          .style('fill', this.eqStyle.search.out.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.option_text__${directionEls_[1]}__${prevIndex_}`)
          .style('fill', this.eqStyle.search.out.color)
        }

        if(values_[index_].on) {
          d3.select(`#${this.localId}`)
          .select(`.option_circle__${directionEls_[1]}__${directionEls_[2]}`)
          .style('stroke', this.eqStyle.search.selected.stroke)
          .style('fill', this.eqStyle.search.selected.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.option_text__${directionEls_[1]}__${directionEls_[2]}`)
          .style('fill', this.eqStyle.search.selected.color)
          
        } else {
          d3.select(`#${this.localId}`)
          .select(`.option_circle__${directionEls_[1]}__${directionEls_[2]}`)
          .style('stroke', this.eqStyle.search.out.stroke)
          .style('fill', this.eqStyle.search.out.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.option_text__${directionEls_[1]}__${directionEls_[2]}`)
          .style('fill', this.eqStyle.search.out.color)
        }

        if(d.colName == 'DATE') {
          if(this.activity == d.value) return 

          this.activity = d.value
          this.activityChanged()

        } else {
          this.setFilter(d.type, d.colName, d.value)
          this.search(false)
        }
      })
    },
    callFuncRegionalAction(selection) {
      let prev = {
        opacity: selection.style('opacity')
      }

      selection
      .on('mouseover', () => {
        selection
        .transition().duration(150)
        .style('opacity', .3)
      })
      .on('mouseout', () => {
        selection
        .transition().duration(150)
        .style('opacity', prev.opacity)
      })
    }
  }
}