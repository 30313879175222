import * as d3 from 'd3'

export default {
  methods: {
    Export_Topside(d,k) {

      if (d.stage == 'Division_Lv3' && 
      [ 'Mask_TS_Common', 'Mask_TS_Modules' ].includes(d.mask) ) {

        setTimeout(() => {
          // Remove SVG
          this.removeSvg(this.CANVAS, 10)
        },1000);

        // Title & Keyplan
        d3.select(`#Title`).text(`Topside_${d.level}`)
        d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Keyplan/TOPSIDE_${d.level}.jpg`)

        // Hidden
        this.hiddenGroupID('#Menu_Back', 0, 0)

        this.hiddenGroupID('#Mask_TS_Common', 0, 0)
        this.hiddenGroupID('#Mask_TS_Modules', 0, 0)

        
        if(d.level == 'COMMON') {

          // hide module
          this.hiddenGroupID('#MW01', 0, 0)
          this.hiddenGroupID('#MW02', 0, 0)
          this.hiddenGroupID('#MW04', 0, 0)
          this.hiddenGroupID('#MW05', 0, 0)
          this.hiddenGroupID('#ME01', 0, 0)
          this.hiddenGroupID('#ME02', 0, 0)
          this.hiddenGroupID('#ME04', 0, 0)
          this.hiddenGroupID('#ME05', 0, 0)

          setTimeout(() => {
          // move module
          d3.select(`#FL01`)  .transition().duration(1000).attr('transform', `translate(347, 436) scale(0.75)`)
          d3.select(`#KO01`)  .transition().duration(1000).attr('transform', `translate(375, 760) scale(0.75)`)
          d3.select(`#TSRC01`).transition().duration(1000).attr('transform', `translate(514, 646) scale(0.75)`)
          d3.select(`#TSRC02`).transition().duration(1000).attr('transform', `translate(663, 596) scale(0.75)`)
          d3.select(`#TSRC03`).transition().duration(1000).attr('transform', `translate(805, 552) scale(0.75)`)
          d3.select(`#TSRC04`).transition().duration(1000).attr('transform', `translate(954, 504) scale(0.75)`)
          d3.select(`#TSRC05`).transition().duration(1000).attr('transform', `translate(1080, 458) scale(0.75)`)
          d3.select(`#LD00DA`).transition().duration(1000).attr('transform', `translate(1184, 415) scale(0.75)`)
         },1000);
        }

        if (d.level == 'MODULES') {

          // hide module
          this.hiddenGroupID('#FL01',   0, 0)
          this.hiddenGroupID('#KO01',   0, 0)
          this.hiddenGroupID('#TSRC01', 0, 0)
          this.hiddenGroupID('#TSRC02', 0, 0)
          this.hiddenGroupID('#TSRC03', 0, 0)
          this.hiddenGroupID('#TSRC04', 0, 0)
          this.hiddenGroupID('#TSRC05', 0, 0)
          this.hiddenGroupID('#LD00DA', 0, 0)
          setTimeout(() => {
          // move module
          d3.select(`#MW01`).transition().duration(1000).attr('transform', `translate(288, 612) scale(0.75)`)
          d3.select(`#MW02`).transition().duration(1000).attr('transform', `translate(524, 537) scale(0.75)`)
          d3.select(`#MW04`).transition().duration(1000).attr('transform', `translate(769, 415) scale(0.75)`)
          d3.select(`#MW05`).transition().duration(1000).attr('transform', `translate(975, 367) scale(0.75)`)
          d3.select(`#ME01`).transition().duration(1000).attr('transform', `translate(499, 729) scale(0.75)`)
          d3.select(`#ME02`).transition().duration(1000).attr('transform', `translate(718, 656) scale(0.75)`)
          d3.select(`#ME04`).transition().duration(1000).attr('transform', `translate(965, 522) scale(0.75)`)
          d3.select(`#ME05`).transition().duration(1000).attr('transform', `translate(1177, 485) scale(0.75)`)
        },1000);
        }

        setTimeout(() => {

          // Mask Display
          if(d.level == 'COMMON') {
            this.displayGroupID(`#Mask_FL01`,   0, 10, 0)
            this.displayGroupID(`#Mask_KO01`,   0, 10, 0)
            this.displayGroupID(`#Mask_TSRC01`, 0, 10, 0)
            this.displayGroupID(`#Mask_TSRC02`, 0, 10, 0)
            this.displayGroupID(`#Mask_TSRC03`, 0, 10, 0)
            this.displayGroupID(`#Mask_TSRC04`, 0, 10, 0)
            this.displayGroupID(`#Mask_TSRC05`, 0, 10, 0)
            this.displayGroupID(`#Mask_LD00DA`, 0, 10, 0)
          }
          if(d.level == 'MODULES') {
            this.displayGroupID(`#Mask_MW01`, 0, 10, 0)
            this.displayGroupID(`#Mask_MW02`, 0, 10, 0)
            this.displayGroupID(`#Mask_MW04`, 0, 10, 0)
            this.displayGroupID(`#Mask_MW05`, 0, 10, 0)
            this.displayGroupID(`#Mask_ME01`, 0, 10, 0)
            this.displayGroupID(`#Mask_ME02`, 0, 10, 0)
            this.displayGroupID(`#Mask_ME04`, 0, 10, 0)
            this.displayGroupID(`#Mask_ME05`, 0, 10, 0)
          }

          this.Lev1 = 'TOPSIDES'
          this.Lev2 = d.level
          this.stage = this.STG_Division_Lv3
          this.fncName = 'Goto_TS_Division_Lv3'
          this.StageCode() // AREA / STAGE
          
          this.displayGroupID(`#Menu_Back`, 0, 200, 1)

          // ### Call Canvas ###
          this.drawProgress(d.level == 'COMMON' ? 'Topside Common' : 'Topside Modules')
        }, 1000);
        
      }

      if (d.stage == 'Division_Lv3' && 
      [ 'Mask_LD00DA' , 'Mask_KO01' , 'Mask_FL01' , 
      'Mask_MW01' , 'Mask_MW02' , 'Mask_MW04' , 'Mask_MW05', 
      'Mask_ME01' , 'Mask_ME02' , 'Mask_ME04' , 'Mask_ME05'  ].includes(d.mask) ) {

        setTimeout(() => {
          // Remove SVG
          this.removeSvg(this.CANVAS, 10)
        },1000);

        this.hiddenGroupID(`#Mask_${d.level}`, 0, 0)        

          
        // Title & Keyplan
        d3.select(`#Title`).text(`TOPSIDE_${d.level}`)
        d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Keyplan/TOPSIDE_${d.level}.jpg`)

        // Hidden
        this.hiddenGroupID('#Menu_Back', 0, 10)

        setTimeout(() => {
          // Remove SVG
          this.removeSvg(this.Dashboard_Overall, 10) 
        },1000);

      
        setTimeout(() => {

          if(['LD00DA', 'KO01','FL01'].includes(d.level)) this.Activate_Topside_Module_Single(d.level)
          else this.Activate_Topside_Module(d.level)

          this.Lev1 = 'TOPSIDES'
          this.Lev4 = d.level
          this.stage = this.STG_Activate
          this.fncName = 'Goto_TS_Activate'
          this.StageCode() // AREA / STAGE
        }, 1300);
        
      }

      if (d.stage == 'Division_Lv3' && 
      ['MW01DD', 'MW01DC', 'MW01DB', 'MW01DA',
       'MW02DD', 'MW02DC', 'MW02DB', 'MW02DA', 
       'MW04DB', 'MW04DA',
       'MW05DC', 'MW05DB', 'MW05DA', 
       'ME01DC', 'ME01DB', 'ME051A', 
       'ME02DC', 'ME02DB', 'ME02A', 
       'ME04DB', 'ME04DA',
       'ME05DE', 'ME05DD', 'ME05DC', 'ME05DB', 'ME05DA'].includes(d.level) ) {

        setTimeout(() => {
         // Remove SVG
         this.removeSvg(this.CANVAS, 10)
        }, 3000)
        // Hidden
        this.hiddenGroupID('#Menu_Back', 0, 10)

         // Hidden
         setTimeout(() => {
         d3.selectAll(`[id*="Mask_${d.mask}"]`).each((_, i, a) => {
           this.hiddenGroupID(`#${d3.select(a[i]).attr('id')}`, 0, 10)
         })
        }, 3100)
      
        setTimeout(() => {
          this.TOPSIDES.forEach(v => {
            if(v.BLK == d.level) {
              d3.select(`#Blk_${d.level}`).transition().duration(1000).attr('transform', `translate(${v.x5}, ${v.y5}) scale(${1})`)
            } else {
              this.hiddenGroupID(`#Blk_${v.BLK}`, 0, 500)
            }
          })
        }, 3200)

        setTimeout(() => {
          this.removeSvg(d3.select('#Progress_Chart'), 10)
        }, 5000)

        setTimeout(() => {

          this.loadSvg(0, 0, `/TORTUE/Equipment/SVG/${d.level}.svg`).then((innerSvg) => {
            this.Draw_EQ_Code(innerSvg, d.level)
            this.Draw_EQ_Tag(innerSvg, d.level)
            this.innerSvg = innerSvg
         })
         
          this.Lev1 = 'Topside'
          this.Lev2 = 'Module'
          this.Lev3 = d.level
          this.Lev4 = d.mask
          this.stage = this.STG_Division_Lv3

          this.fncName = 'Goto_TS_Level'
          this.StageCode() // AREA / STAGE
          this.displayGroupID(`#Menu_Back`, 0, 200, 1)

          // ### Call Canvas ###
          this.drawProgress(d.level, false)     
        }, 5100);        
      }
    },
  }
}
           