import * as d3 from 'd3'

export default {
  methods: {
    Goto_Screen_Dirty(val) { //Lnb direct filter working
      // let currentLevel = val.Lv3 ? 'Lv3' : 'Lv2'
      let del = []
      this.selectedItems.forEach((d,index) => {
         del.push(index)
      })
      del.forEach(d => {
        this.selectedItems.pop()
      })
      this.Goto_Plag = true
  
      this.displayGroupID(`#Keyplan_Group`, 10, 10, 1)
      this.displayGroupID(`#Legends`, 10, 10, 1)
      this.removeSvg(this.CANVAS, 1000)
      this.removeSvg(this.Dashboard_Overall, 10) 
      this.removeSvg(d3.select('#BlockCodes'), 0)
      if(['HULL-AFT','HULL-FOR','LQ'].includes(val.Lv2)) {
        // Title & Keyplan
        if(val.Lv2 =='LQ'){
          d3.select(`#Title`).text(`Hull-${val.Lv2}`)
        }else{
          d3.select(`#Title`).text(`${val.Lv2}`)
        }
        if(val.Lv2 == 'HULL-AFT'){
          d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Keyplan/HULL_AFT.png`)
        }else if(val.Lv2 == 'HULL-FOR'){
          d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Keyplan/HULL_FOR.png`)
        }else{
          d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Keyplan/HULL_${val.Lv2}.png`)
        }
        this.removeSvg(this.CANVAS, 1000)
        // Hidden
        this.hiddenGroupID('#Menu_Back', 0, 10)
        this.hiddenGroupID('#Mask_Hull_AFT', 0, 10)
        this.hiddenGroupID('#Mask_Hull_FOR', 0, 10)
        this.hiddenGroupID('#Mask_Hull_LQ', 0, 10)
        // Remove SVG
        this.removeSvg(this.Dashboard_Overall, 1000) 
        if(this.svgModeling && !this.svgModeling.empty()) this.removeSvg(this.svgModeling, 1000)
        // Activate
        if(val.Lv2 =='HULL-AFT') this.Activate_Hull_AFT()
        if(val.Lv2 =='HULL-FOR') this.Activate_Hull_FOR()
        if(val.Lv2 =='LQ') this.Activate_Hull_LQ()

        let ti = null
        if (val.Lv2 == 'HULL-AFT') ti = 'AFT'
        if (val.Lv2 == 'HULL-FOR') ti = 'FOR'
        if (val.Lv2 == 'LQ') ti = val.Lv2
        this.stage = this.STG_Activate
        let dStage = {
          stage: this.STG_Division_Lv3,
          mask : `Mask_Hull_${ti}`,
          level: ti
        }
        this.selectedItems.push(dStage)
        this.fncName = 'Goto_Hull_Activate'
        this.StageCode() // AREA / STAGE
        this.displayGroupID(`#Menu_Back`, 0, 200, 1)


      } else if (['COMMON','MODULES'].includes(val.Lv2)) {
        this.stage = val.Lv2 == 'COMMON' ? 'COMMON' : 'MODULES'
        let dStage = {
          stage: this.STG_Division_Lv3,
          mask : val.Lv2 == 'COMMON' ? 'Mask_TS_Common' : 'Mask_TS_Modules',
          level: val.Lv2
        }
        this.selectedItems.push(dStage)
        if(this.svgModeling && !this.svgModeling.empty()) this.removeSvg(this.svgModeling, 1000)
        setTimeout(()=>{
          this.Activate_OverallnMasks()

          d3.select(`#Area_AFT`).transition().duration(500).style('visibility', 'visible').style('opacity', 1)
          d3.select(`#Area_FOR`).transition().duration(500).style('visibility', 'visible').style('opacity', 1)
          d3.select(`#Topside_Group`).transition().duration(500).style('visibility', 'visible').style('opacity', 1)
          d3.select(`#Area_LQ`).transition().duration(500).style('visibility', 'visible').style('opacity', 1)
          d3.select(`#Area_VS`).transition().duration(500).style('visibility', 'visible').style('opacity', 1)
    
          this.displayGroupID(`#Mask_Overall`, 10, 10, 0)

            // Title & Keyplan
          d3.select(`#Title`).text(`Topside_${val.Lv2}`)
          d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Keyplan/TOPSIDE_${val.Lv2}.png`)

          // Hidden
          this.hiddenGroupID('#Menu_Back', 0, 0)
          this.hiddenGroupID('#Mask_TS_Common', 0, 0)
          this.hiddenGroupID('#Mask_TS_Modules', 0, 0)

          if(val.Lv2 == 'COMMON') {
            // hide module
            this.hiddenGroupID('#MW01', 0, 0)
            this.hiddenGroupID('#MW02', 0, 0)
            this.hiddenGroupID('#MW04', 0, 0)
            this.hiddenGroupID('#MW05', 0, 0)
            this.hiddenGroupID('#ME01', 0, 0)
            this.hiddenGroupID('#ME02', 0, 0)
            this.hiddenGroupID('#ME04', 0, 0)
            this.hiddenGroupID('#ME05', 0, 0)
            this.hiddenGroupID('#Mask_Overall', 0, 10)
            this.hiddenGroupID('#Area_AFT', 0, 1000)
            this.hiddenGroupID('#Area_FOR', 0, 1000)
            this.hiddenGroupID('#Area_LQ', 0, 1000)
            this.hiddenGroupID('#Area_VS', 0, 1000)

            // move module
            d3.select(`#FL01`)  .transition().duration(1000).attr('transform', `translate(420, 269) scale(0.75)`)
            d3.select(`#FR01`)  .transition().duration(1000).attr('transform', `translate(539, 489) scale(0.75)`)
            d3.select(`#KO01`)  .transition().duration(1000).attr('transform', `translate(261, 630) scale(0.75)`)
            d3.select(`#TSRC01`).transition().duration(1000).attr('transform', `translate(579, 580) scale(0.75)`)
            d3.select(`#TSRC02`).transition().duration(1000).attr('transform', `translate(749, 612) scale(0.75)`)
            d3.select(`#TSRC03`).transition().duration(1000).attr('transform', `translate(907, 659) scale(0.75)`)
            d3.select(`#TSRC04`).transition().duration(1000).attr('transform', `translate(1082, 691) scale(0.75)`)
            d3.select(`#TSRC05`).transition().duration(1000).attr('transform', `translate(1234, 722) scale(0.75)`)
            d3.select(`#LD00DA`).transition().duration(1000).attr('transform', `translate(1294, 592) scale(0.75)`)
          }

          if (val.Lv2 == 'MODULES') {
            // hide module
            this.hiddenGroupID('#FL01',   0, 0)
            this.hiddenGroupID('#FR01',   0, 0)
            this.hiddenGroupID('#KO01',   0, 0)
            this.hiddenGroupID('#TSRC01', 0, 0)
            this.hiddenGroupID('#TSRC02', 0, 0)
            this.hiddenGroupID('#TSRC03', 0, 0)
            this.hiddenGroupID('#TSRC04', 0, 0)
            this.hiddenGroupID('#TSRC05', 0, 0)
            this.hiddenGroupID('#LD00DA', 0, 0)
            this.hiddenGroupID('#Mask_Overall', 0, 10)
            this.hiddenGroupID('#Area_AFT', 0, 1000)
            this.hiddenGroupID('#Area_FOR', 0, 1000)
            this.hiddenGroupID('#Area_LQ', 0, 1000)
            this.hiddenGroupID('#Area_VS', 0, 1000)

            // move module
            d3.select(`#MW01`).transition().duration(1000).attr('transform', `translate(581, 357) scale(0.75)`)
            d3.select(`#MW02`).transition().duration(1000).attr('transform', `translate(797, 393) scale(0.75)`)
            d3.select(`#MW04`).transition().duration(1000).attr('transform', `translate(1069, 419) scale(0.75)`)
            d3.select(`#MW05`).transition().duration(1000).attr('transform', `translate(1273, 488) scale(0.75)`)
            d3.select(`#ME01`).transition().duration(1000).attr('transform', `translate(287, 583) scale(0.75)`)
            d3.select(`#ME02`).transition().duration(1000).attr('transform', `translate(498, 614) scale(0.75)`)
            d3.select(`#ME04`).transition().duration(1000).attr('transform', `translate(774, 626) scale(0.75)`)
            d3.select(`#ME05`).transition().duration(1000).attr('transform', `translate(981, 707) scale(0.75)`)
          }

          setTimeout(() => {
            // Mask Display
            if(val.Lv2 == 'COMMON') {
              this.displayGroupID(`#Mask_FL01`,   0, 10, 0)
              this.displayGroupID(`#Mask_FR01`,   0, 10, 0)
              this.displayGroupID(`#Mask_KO01`,   0, 10, 0)
              this.displayGroupID(`#Mask_TSRC01`, 0, 10, 0)
              this.displayGroupID(`#Mask_TSRC02`, 0, 10, 0)
              this.displayGroupID(`#Mask_TSRC03`, 0, 10, 0)
              this.displayGroupID(`#Mask_TSRC04`, 0, 10, 0)
              this.displayGroupID(`#Mask_TSRC05`, 0, 10, 0)
              this.displayGroupID(`#Mask_LD00DA`, 0, 10, 0)
            }
            if(val.Lv2 == 'MODULES') {
              this.displayGroupID(`#Mask_MW01`, 0, 10, 0)
              this.displayGroupID(`#Mask_MW02`, 0, 10, 0)
              this.displayGroupID(`#Mask_MW04`, 0, 10, 0)
              this.displayGroupID(`#Mask_MW05`, 0, 10, 0)
              this.displayGroupID(`#Mask_ME01`, 0, 10, 0)
              this.displayGroupID(`#Mask_ME02`, 0, 10, 0)
              this.displayGroupID(`#Mask_ME04`, 0, 10, 0)
              this.displayGroupID(`#Mask_ME05`, 0, 10, 0)
            }
            this.Lev1 = 'TOPSIDE'
            this.Lev2 = val.Lv2
            this.stage = this.STG_Division_Lv3
            this.fncName = 'Goto_TS_Division_Lv3'
            this.StageCode() // AREA / STAGE
            this.displayGroupID(`#Menu_Back`, 0, 200, 1)

            // ### Call Canvas ###
            this.Canvas_Division_Lv3(this.Lev1, this.Lev2)
          }, 1000);
        },1000)

      } else {
        this.removeSvg(this.CANVAS, 1000)
        this.removeSvg(this.Dashboard_Overall, 1000) 
        if(this.svgModeling && !this.svgModeling.empty()) this.removeSvg(this.svgModeling, 1000)
        this.hiddenGroupID(`#Keyplan_Group`, 0, 0)
        this.hiddenGroupID(`#Legends`, 0, 0)
        this.hiddenGroupID('#Menu_Back', 0, 0)
        this.removeSvg(d3.select('#BlockCodes'), 0)
        d3.select(`#Title`).text(`${val.Lv2}`)

      }
    }
  },
}