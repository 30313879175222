import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {
    
    Draw_Charts() {
      
      let data = null, 
          style = null, 
          cellX = null, 
          tmp = null, 
          Circle = null, 
          Guideline = null,
          item = null

      let overallMCStatus = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0)`)



      // Header_01
      data  = this.DataItems[0].PROG
      style = {
        title: this.DataItems[0].CATEGORY,
        x: 30,
        y: 50,
        width: 50,
        height: 18,
        radius: 3,
        tSize: 11,
        tColor: '#333',
        bColor: '#E0E0DF',
        progSize: 15,
      }
      this.Header_01( overallMCStatus, data, style) // ---------------------------> 


      // SummaryTable_01
      item = this.DataItems[0]
      style = {
        id:       item.CATEGORY,
        title:    ['Total', 'Complete', 'Remain'],
        cell:     [50, 110, 160],
        x:        30,
        y:        70,
        y1:       10,
        y2:       23,
        width:    170,
        height:   32,
        radius:   3,
        opacity:  0.7,
        bColor:   '#83D2F5',
        tSize:    10,
        tColor:   '#757575',
        vSize:    11,
        vColor:   '#fff',
        value:    [item.TOTAL, item.COMPETED, item.REMAIN],
        requests: 
        [
          // {
          //   dataType: 'row',
          //   action: {
          //     type: 'direct',
          //     target: 'slide-modal',
          //     component: 'ServiceDatatable',
          //     id: 104,
          //     no: ''
          //   },
          //   filters: { 
          //     // SUBSYSTEM: this.FilterValues.SYS
          //   },
          //   iFilters: {
          //     filterString : "[STAGE] = 'A'",
          //     inputFilter  : ''
          //   }
          // },
          // {
          //   dataType: 'row',
          //   action: {
          //     type: 'direct',
          //     target: 'slide-modal',
          //     component: 'ServiceDatatable',
          //     id: 104,
          //     no: ''
          //   },
          //   filters: { 
          //     // SUBSYSTEM: this.FilterValues.SYS
          //   },
          //   iFilters: {
          //     filterString : "[STAGE] = 'A' AND [STATUS] = 'Y'",
          //     inputFilter  : ''
          //   }
          // },
          // {
          //   dataType: 'row',
          //   action: {
          //     type: 'direct',
          //     target: 'slide-modal',
          //     component: 'ServiceDatatable',
          //     id: 104,
          //     no: ''
          //   },
          //   filters: { 
          //     // SUBSYSTEM: this.FilterValues.SYS
          //   },
          //   iFilters: {
          //     filterString : "[STAGE] = 'A' AND [STATUS] = 'N'",
          //     inputFilter  : ''
          //   }
          // },
        ]
      }
      this.drawTable(overallMCStatus, style) // ---------------------------> 


      // Chart_Tube_01
      data = this.DataItems[0]
      style = {
        x:        40,
        y:        120,
        color:    'LightBlue',
        height:   150,
        tickness: 60,
        base:   { tSize: 14, height: 40, padding: 30,},
        legend: { actualColor:'#44A9DF', remainColor: '#EC407A', valueSize: 11, tSize: 10},
        value:  {TOTAL:data.TOTAL, ACTUAL:data.COMPETED, REMAIN:data.REMAIN, PROG:data.PROG}
      }
      this.Chart_Tube_01(overallMCStatus, style) // ---------------------------> 

    },
    

    drawTable(selection, d) {
      let _self = this
      let summaryTable = selection
      .append('g')
      .attr('id', '')
      .attr('transform', `translate(${d.x}, ${d.y})`)
      
      summaryTable
      .append('rect')
      .attr('transform', `translate(0, 0)`)
      .style('width', d.width).style('height', d.height).style('fill', d.bColor).attr('rx', d.radius).attr('ry', d.radius).style('opacity', d.opacity)

      summaryTable
      .append('text') 
      .attr('transform', `translate(${d.cell[0]}, ${d.y1})`)
      .style('font-family', 'roboto').style('font-size', d.tSize)
      .style('fill', d.tColor).style('text-anchor', 'end').style('alignment-baseline', 'middle').text(d.title[0])

      summaryTable
      .append('text') 
      .attr('transform', `translate(${d.cell[1]}, ${d.y1})`)
      .style('font-family', 'roboto').style('font-size', d.tSize)
      .style('fill', d.tColor).style('text-anchor', 'end').style('alignment-baseline', 'middle').text(d.title[1])

      summaryTable
      .append('text') 
      .attr('transform', `translate(${d.cell[2]}, ${d.y1})`)
      .style('font-family', 'roboto').style('font-size', d.tSize)
      .style('fill', d.tColor).style('text-anchor', 'end').style('alignment-baseline', 'middle').text(d.title[2])

      summaryTable // Total
      .append('text')
      .attr('id', `${d.id}_Total}`)
      .attr('transform', `translate(${d.cell[0]}, ${d.y2})`)
      .style('font-family', 'roboto').style('font-size', d.vSize)
      .style('fill', d.vColor).style('text-anchor', 'end').style('alignment-baseline', 'middle')
      .text(d.value[0])
      // .style('cursor', 'pointer')
      // .on('mouseover', function() {
      //   let node = _self.getNodeElValue(`#${d.id}_Total`,'width') / 5.5
      //   d3.select(this).transition().duration(100)
      //   .attr('transform', `translate(${d.cell[0] + node}, ${d.y2}) scale(1.3)`).style('fill', '#EC407A')
      // })
      // .on('mouseout', function(){
      //   d3.select(this).transition().duration(100)
      //   .attr('transform', `translate(${d.cell[0]}, ${d.y2}) scale(1)`).style('fill', d.vColor)
      // })
      // .on('click', () => { 
      //   this.$emit('request-action', d.requests ? d.requests[0] : {})
      // })
      
      summaryTable // Actual
      .append('text')
      .attr('id', `${d.id}_Actual`)
      .attr('transform', `translate(${d.cell[1]}, ${d.y2})`)
      .style('font-family', 'roboto').style('font-size', d.vSize)
      .style('fill', d.vColor).style('text-anchor', 'end').style('alignment-baseline', 'middle')
      .text(d.value[1])
      // .style('cursor', 'pointer')
      // .on('mouseover', function() {
      //   let node = _self.getNodeElValue(`#${d.id}_Actual`,'width') / 5.5
      //   d3.select(this).transition().duration(100)
      //   .attr('transform', `translate(${d.cell[1] + node}, ${d.y2}) scale(1.3)`).style('fill', '#EC407A')
      // })
      // .on('mouseout', function(){
      //   d3.select(this).transition().duration(100)
      //   .attr('transform', `translate(${d.cell[1]}, ${d.y2}) scale(1)`).style('fill', d.vColor)
      // })
      // .on('click', () => { 
      //   this.$emit('request-action', d.requests ? d.requests[1] : {})
      // })

      summaryTable // Remain
      .append('text')
      .attr('id', `${d.id}_Remain`)
      .attr('transform', `translate(${d.cell[2]}, ${d.y2})`)
      .style('font-family', 'roboto').style('font-size', d.vSize)
      .style('fill', d.vColor).style('text-anchor', 'end').style('alignment-baseline', 'middle')
      .text(d.value[2])
      // .style('cursor', 'pointer')
      // .on('mouseover', function() {
      //   let node = _self.getNodeElValue(`#${d.id}_Remain`,'width') / 5.5
      //   d3.select(this).transition().duration(100)
      //   .attr('transform', `translate(${d.cell[2] + node}, ${d.y2}) scale(1.3)`).style('fill', '#EC407A')
      // })
      // .on('mouseout', function(){
      //   d3.select(this).transition().duration(100)
      //   .attr('transform', `translate(${d.cell[2]}, ${d.y2}) scale(1)`).style('fill', d.vColor)
      // })
      // .on('click', () => { 
      //   this.$emit('request-action', d.requests ? d.requests[2] : {})
      // })
    },
  }
}