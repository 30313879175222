// -------------------------------------------------------------------------------------- Global
import { SafeIdMixin } from '../../../../../../includes/mixins/safeid.mixin'                // ATAGlance Framework Globals
import gmx_Controller from '../../../../../../includes/mixins/Controller.mixin'       // SVG Control
// Global Drawings
import gmx_Canvas from '../../../../../../includes/mixins/Draw_Canvas.mixin'
import gmx_Texts from '../../../../../../includes/mixins/Text_Draw.mixin'
// Global Functions
import gmx_Miscellaneous from '../../../../../../includes/mixins/Miscellaneous.mixins'
import gmx_Movable from '../../../../../../includes/mixins/Movable.mixin'

// -------------------------------------------------------------------------------------- Local
// Locally declared common Data-Props & SVG Drawing Support
import lmx_ReactiveEvents from        './Realtime_Monitor.mixin' // Reactive Events
import lmx_Miscellaneous from         '../../../../include/mixins/Miscellaneous.mixins'
  
// Custom Charts  
import chart_BarCharts from           '../../../../include/ChartLibrary/Tortue_BarCharts.mixin'
import chart_SummaryTables from       '../../../../include/ChartLibrary/Tortue_SummaryTables.mixin'
import chart_Geometrical from         '../../../../include/ChartLibrary/Tortue_Geometrical.mixin'

import lmx_MovablePopup from              './MovablePopup.mixin'
import lmx_DataStyles from            './DataStyles.mixin'
import lmx_Declares from              './Declares.mixin'
import lmx_Codes from                 './Draw_Codes.mixin'
import lmx_Forms from                 './Draw_Forms.mixin'
import lmx_Popup from                 './Draw_EQ_Popup'
import lmx_Tag_Buttons from           './Draw_Tag_Buttons'
import lmx_Search_Button from         './Draw_Search_Button.mixin'
import lmx_Progress from              './Draw_Progress.mixin'

// Events
import event_PDF from                 './Event_PDF_mixin'

import event_Block from               './Event_Block.mixin'
import draw_overall from              './draw_overall.mixin'
import draw_event from                './draw_event.mixin'
import draw_back from                './draw_back.mixin'

export default {
  mixins: [
    SafeIdMixin,
    gmx_Controller,
    gmx_Canvas,
    gmx_Texts,
    gmx_Miscellaneous,
    gmx_Movable,
    
    lmx_MovablePopup,
    lmx_ReactiveEvents,
    lmx_Miscellaneous,

    // Chart Library
    chart_BarCharts,
    chart_SummaryTables,
    chart_Geometrical,

    // Mixin
    lmx_DataStyles,
    lmx_Declares,
    lmx_Codes,
    lmx_Forms,
    lmx_Popup,
    lmx_Tag_Buttons,
    lmx_Search_Button,
    lmx_Progress,

    // Mouse Event
    event_PDF,

    event_Block,
    draw_overall,
    draw_event,
    draw_back,

  ],
}
