import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  data: () => ({
    props:[
      {key: 'FAT_PLAN_WK', x: 90, y: 20, tx: 60, cy: 0},
      {key: 'FAT_PLAN_CUM', x: 300, y: 40, tx: 180, cy: 0},
      {key: 'FAT_FORECAST_WK', x: 500, y: 60, tx: 500, cy: 0},
      {key: 'FAT_FORECAST_CUM', x: 550, y: 80, tx: 550, cy: 0},
    ],
    width: 600,
    height: 300,
  }),
  methods: {
    Draw_Charts() {

      const svg = this.svg
      const g = svg.append('g').attr('transform', `translate(0, 0)`)

      let data = this.DataItems
      let keys = Object.keys(data[0])

      let line = g.append('g').attr('class', 'line-group').attr('transform', `translate(0.5, 0.5)`)
      
      line.append('rect')
        .attr('x', 20)
        .attr('y', 30)
        .attr('width', 570)
        .attr('height', 105)
        .attr('fill', 'Transparent')
        .attr('stroke-width', '0.5px')
        .attr('stroke', '#ccc')
      
      line.append('line')
        .attr('x1', 20)
        .attr('y1', 55)
        .attr('x2', 590)
        .attr('y2', 55)
        .attr('stroke-width', '0.5px')
        .attr('stroke', '#ccc')

      line.append('line')
        .attr('x1', 20)
        .attr('y1', 95)
        .attr('x2', 590)
        .attr('y2', 95)
        .attr('stroke-width', '0.5px')
        .attr('stroke', '#ccc')

      line.append('line')
        .attr('x1', 75)
        .attr('y1', 75)
        .attr('x2', 590)
        .attr('y2', 75)
        .attr('stroke-width', '0.5px')
        .attr('stroke', '#ccc')

      line.append('line')
        .attr('x1', 75)
        .attr('y1', 115)
        .attr('x2', 590)
        .attr('y2', 115)
        .attr('stroke-width', '0.5px')
        .attr('stroke', '#ccc')

      line.append('line')
        .attr('x1', 75)
        .attr('y1', 55)
        .attr('x2', 75)
        .attr('y2', 135)
        .attr('stroke-width', '0.5px')
        .attr('stroke', '#ccc')

      line.append('line')
        .attr('x1', 130)
        .attr('y1', 30)
        .attr('x2', 130)
        .attr('y2', 135)
        .attr('stroke-width', '0.5px')
        .attr('stroke', '#ccc')



      let lefthead = g.append('g').attr('class', 'lefthead-group').attr('transform', `translate(50, 50)`)

      lefthead.append('text')
            .attr('x', 25)
            .attr('y', -3)
            .attr('text-anchor', 'middle')
            .attr('fill', '#212121')
            .style('font-size', 10)
            .style('font-weight', 'bold')
            .text('Lookahead')

      lefthead.append('text')
            .attr('x', 0)
            .attr('y', 30)
            .attr('text-anchor', 'middle')
            .attr('fill', '#212121')
            .style('font-size',10)
            .style('font-weight', 'bold')
            .text('Plan')

      lefthead.append('text')
            .attr('x', 50)
            .attr('y', 20)
            .attr('text-anchor', 'middle')
            .attr('fill', '#212121')
            .style('font-size',10)
            .style('font-weight', 'bold')
            .text('Weekly')

      lefthead.append('text')
            .attr('x', 50)
            .attr('y', 40)
            .attr('text-anchor', 'middle')
            .attr('fill', '#212121')
            .style('font-size',10)
            .style('font-weight', 'bold')
            .text('Cum.')

      lefthead.append('text')
            .attr('x', 0)
            .attr('y', 70)
            .attr('text-anchor', 'middle')
            .attr('fill', '#212121')
            .style('font-size',10)
            .style('font-weight', 'bold')
            .text('Forecast')

      lefthead.append('text')
            .attr('x', 50)
            .attr('y', 60)
            .attr('text-anchor', 'middle')
            .attr('fill', '#212121')
            .style('font-size',10)
            .style('font-weight', 'bold')
            .text('Weekly')

      lefthead.append('text')
            .attr('x', 50)
            .attr('y', 80)
            .attr('text-anchor', 'middle')
            .attr('fill', '#212121')
            .style('font-size',10)
            .style('font-weight', 'bold')
            .text('Cum.')


      let thead = g.append('g').attr('class', 'thead-group').attr('transform', `translate(150, 47)`)
      let head = data.map(d => d.CUTOFF)

      head.forEach((k, i) => {
        thead.append('text')
          .attr('x', i * (460 / head.length))
          .attr('y', 0)
          .attr('text-anchor', 'middle')
          .style('font-size',10)
          .attr('fill', () => '#212121')
          .style('font-weight', 'bold')
          .text(k)
      })


      // tbody
      let tbody = g.append('g').attr('class', 'tbody-group').attr('transform', `translate(150, 50)`)

        keys.forEach((k, i) => {
          let not = ['CUTOFF', 'WK_NO']
          if (!not.includes(k)) {
              let row = data.map(m => {
                let obj = {VALUE: m[k], CUTOFF: m['CUTOFF'], WK_NO: m['WK_NO'], KEY: k}
                return obj
              })

              row.forEach((r, ri) => {

                let value_ = tbody.append('text')
                  .attr('x', ri * (460 / row.length))
                  .attr('y', () => this.props.filter(f => f.key === k)[0].y)
                  .attr('text-anchor', 'middle')
                  .attr('fill', () => r === 0 ? '#ccc' : '#757575')
                  .style('font-size', '10px')
                  .text(r.VALUE)
                  .style('cursor', () => {
                    return r.VALUE === 0 ? 'default' : 'pointer'
                  })
                  .on('mouseover', () => {
                    if (r.VALUE === 0) return
                    value_.transition().duration(200).attr('fill', 'red')
                    .style('font-size','12px')
                    .style('font-weight', 'bold')
                  })
                  .on('mouseout', () => {
                    if (r.VALUE === 0) return
                    value_.transition().duration(200).attr('fill', () => r === 0 ? '#ccc' : '#757575')
                    .style('font-weight', 'normal')
                    .style('font-size', '10px')
                  })
                  .on('click', () => {
                    if (r.VALUE === 0) return
                    let key = r.KEY.split('_')
                    let col = key[0] + '_' + key[1]

                    let request_ = {
                      dataType: 'row',
                      action: {
                        type: 'direct',
                        target: 'slide-modal',
                        component: 'ServiceDatatable',
                        id: 185,
                        no: ''
                      },
                      filters: { 
                      },
                      iFilters: {
                        filterString : `[PKG_BULK] = 'P' AND [${col}] <= (SELECT [CUTOFF] FROM [T03_Planning].[dbo].[WEEK_NO] WHERE [WK_NO] = '${r.WK_NO}') ${r.KEY.includes('WK') ? ` AND [${col}] > (SELECT DATEADD(DD, -7 ,[CUTOFF]) FROM [T03_Planning].[dbo].[WEEK_NO] WHERE [WK_NO] = '${r.WK_NO}')` : `` }`,
                      }
                    }
                    this.$emit('request-action', request_)
                  })
              })
          }
        })
    },
  }
}
