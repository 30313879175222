import * as d3 from 'd3'
import __C from '../../../../../../includes/primitives/_constant_'
export default {
  data: () => ({
    innerSvg: null,
    stage_Header: null,
    stage_Lev1: null,
    stage_Lev2: null,
    stage_Lev3: null,
    selectedItems: null,

    zoom: {
      width: 1300, 
      height: 910,
      in:   {scale: null, x: null, y: null},
      out:  {scale: null, x: null, y: null},
    },
    
    SEIs: [],
    currentSEI: '',
    currentDISC: '',
    currentPKG: '',
    whereClause: '',
    rawData: null,
    packageSum: null,
    styles: null,
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {
    /*
      set_LocalValues()
      setPriority()
      loadSvg()
    */

    set_LocalValues(){
      
      if(this.getSelectItems) {
        this.pageOptions = JSON.parse(this.getSelectItems)
      }

      this.rawData = JSON.parse(JSON.stringify(this.DataItems))
      this.packageSum = JSON.parse(JSON.stringify(this.Queries.SQL1))
      this.styles = JSON.parse(JSON.stringify(this.Queries.SQL2[0]))

      this.SEIs.forEach(d=> {
        let data = this.rawData.find(f=> f.SEI == d.SEI)
        if(data) {
          d.PKG = data.PKG
          d.TOTAL = data.TOTAL
          d.ACTUAL = data.ACTUAL
          d.REMAIN = data.REMAIN
          d.PRO = data.PRO
        } else {
          d.PKG = ''
          d.TOTAL = 0
          d.ACTUAL = 0
          d.REMAIN = 0
          d.PRO = 0
        }
      })
    },

    setPriority() {
      if(this.stage_Header) this.stage_Header.moveToFront()
    },

    loadSvg(selection, data) {
      return d3.xml(`${this.__HOST_NAME_RESOURCE}${data.url}`).then(source => {
        let svg_ = source.documentElement
        selection.node().append(svg_)

        svg_ = d3.select(svg_)
        .attr('x', data.x)
        .attr('y', data.y)

        return new Promise(resolve => {
          resolve(svg_)
        })
      })
    },

    //Calculate the scale value and coordinates when the image is displayed
    setZoomScale(width_, height_, in_, out_) {
      this.zoom.in.scale = in_
      this.zoom.in.x = (this.Canvas.CanvasWidth - (width_ * in_)) / 2
      this.zoom.in.y = (this.Canvas.CanvasHeight - (height_ * in_)) / 2

      this.zoom.out.scale = out_
      this.zoom.out.x = (this.Canvas.CanvasWidth - (width_ * out_)) / 2
      this.zoom.out.y = (this.Canvas.CanvasHeight - (height_ * out_)) / 2
    }
  }
}