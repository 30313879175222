import * as d3 from 'd3'
import ShopData from '../primitives/donutProps'
// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  data: () => ({
    eqStatus: [
      { code: 'PO',          title: 'PO',                          colName: { total: 'PO_TOTAL'                 , actual: 'PO_ACTUAL'     }, bColor: '#84C7E5', sColor: '#00838F', tColor: '#333', opacity: 1.00, },
      { code: 'FAT',         title: 'FAT',                         colName: { total: 'FAT_TOTAL'                , actual: 'FAT_ACTUAL'    }, bColor: '#2281C5', sColor: '#9F368B', tColor: '#fff', opacity: 1.00, },
      { code: 'IRN',         title: 'IRN',                         colName: { total: 'IRN_TOTAL'                , actual: 'IRN_ACTUAL'    }, bColor: '#CC93C1', sColor: '#9F368B', tColor: '#333', opacity: 1.00, },
      { code: 'ETA',         title: 'ON Site',                     colName: { total: 'ONSITE_TOTAL'             , actual: 'ONSITE_ACTUAL' }, bColor: '#F6D15E', sColor: '#F9A825', tColor: '#333', opacity: 1.00, },
      { code: 'INST',        title: 'Installed',                   colName: { total: 'INST_TOTAL'               , actual: 'INST_ACTUAL'   }, bColor: '#8DBA2D', sColor: '#558B2F', tColor: '#fff', opacity: 1.00, },
      { code: 'NOT_PO',      title: 'Not PO',                      colName: { total: 'NOT_PO_CNT'               , actual: ''              }, bColor: '#ffffff', sColor: '#BDBCBC', tColor: '#333', opacity: 1.00, },
      { code: 'ROS_PAST',    title: 'ETA is past from ROS',        colName: { total: 'ETA_PAST_FROM_ROS'        , actual: ''              }, bColor: '#F7BACF', sColor: '#BDBCBC', tColor: '#333', opacity: 1.00, },
      { code: 'UPBLOCK_PAST',title: 'ETA is past from  Upper Blk.',colName: { total: 'ETA_PAST_FROM_UPPERBLOCK' , actual: ''              }, bColor: '#EC407A', sColor: '#BDBCBC', tColor: '#fff', opacity: 1.00, },
    ],
    thead: [
      { key: 'ITEM', html: 'Block Stage', row: 1, rowspan: 2 },
      { key: 'PLAN', html: 'Plan', row:1, colspan: 3 },
      { key: 'ACTUAL', html: 'Actual', row: 1, colspan: 3 },
      { key: 'DELTA', html: 'Delta<br/>(%)', row: 1, rowspan: 2 },

      { key: 'PLAN_SD', html: 'Start', row: 2 },
      { key: 'PLAN_FD', html: 'Finish', row: 2 },
      { key: 'PLAN_PROG', html: 'Prog(%)', row: 2 },

      { key: 'ACTUAL_SD', html: 'Start', row: 2 },
      { key: 'ACTUAL_FD', html: 'Finish', row: 2 },
      { key: 'ACTUAL_PROG', html: 'Prog(%)', row: 2 },
    ],
    tbody: [
      { key: 'ITEM', w: 120, align: 'left' },
      { key: 'PLAN_SD', w: 70, align: 'center' },
      { key: 'PLAN_FD', w: 70, align: 'center' },
      { key: 'PLAN_PROG', w: 70, align: 'center' },
      { key: 'ACTUAL_SD', w: 70, align: 'center' },
      { key: 'ACTUAL_FD', w: 70, align: 'center' },
      { key: 'ACTUAL_PROG', w: 70, align: 'center' },
      { key: 'DELTA', w: 70, align: 'center' },
    ],
    tableWidth: 600,
    
    scrollProps: {
      w: 932,
      h: 300,
      g: 0,
      bar: {
        align : 'right',
        gap   : 3,
        radius: 2,
        width : 4,
        fill  : '#9E9E9E',
        stroke: '#9E9E9E',
      }
    }
  }),
  methods: {
    Draw_Charts() {
      this.drawSummaryProgImage() //-------DataItems
      this.drawBarProg()          //-------SQL1
      this.drawShopProg()         //-------SQL2
      this.drawTable()            //-------SQL3
    },
    drawSummaryProgImage(){
      if(!this.DataItems || this.DataItems.length === 0) return
      let data = null, style = null, tmp = null
      let distance = 200
      // let blockfilter = this.DataItems.map(d => d.BLOCK)

      let imageHoverBox = this.svg
      imageHoverBox.append('rect').attr('class', '_level_background_bar__')
      .attr('x', 23).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', 200).attr('height', 197).attr('fill', '#eee').attr('opacity', '0.7')

      let blocks = [...new Set(this.DataItems.map(d => d.BLOCK))]
      // console.log(blockfilter)//map으로 block만 모으고 중복된값없애는 set으로 다시!

      blocks.forEach((block,i) => {

        //textBox
        let rectText = this.svg
        .append('g')
        .attr('transform', `translate(0, ${i*distance})`)

        rectText
        .append('rect')
        .attr('transform', `translate(0, 0)`)
        .attr('width', 23).attr('height', distance-3).attr('fill', '#B3E5FC')

        rectText
        .append('text')
        .attr('transform', `translate(${23/2}, ${distance/2})rotate(-90)`)
        .style('font-size', 11)
        .style('font-family', 'roboto')
        .attr('fill', '#000')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(block)

        //Summary Image + Prog
        let overallSummary = this.svg
        .append('g')
        .attr('transform', `translate(55, ${i*distance})`)

        overallSummary
        .append('image')
        .attr('opacity', 1)
        .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Blocks/Topsides/${block}.png`)
        .attr('transform', `translate(${['E1D','W1E','W5D','E5E','W4A','W4B'].includes(block) ? 12 : ['W1D','E5B','E5C','E5D','E4B','E4A','E2D','E2B','E2C','E5A'].includes(block) ? 0 : -20} , ${100}) scale(${['E1D','E2D','W5D'].includes(block) ? 1 : 0.6})`)

        // Plan
        tmp = this.DataItems.filter(d => d.BLOCK === block)[0] //filter로 0을 해야 그안의 data쓸수있다
        // console.log(tmp)
        style = {
          id: 'CERT',
          x: 10,
          y: 55,
          ty: 59,
          name: tmp.PLAN,
          sort: 'N',
        }
        data = [
          {title: 'Completed',  value: tmp.PLAN },
          {title: 'Remain',     value: 100-tmp.PLAN }
        ]
        let Circle = {
          CircleDiameter: 30,
          CircleColorSet: ['Gray', 'LightGray'],
          CircleHoleSize: 20,
          CircleStackSize: 2,
          CircleSliceSize: 5,
          CircleValueSize: 14,
          CircleTitleSize: 10,
          CircleLegendSpace: 80,
        }
        let Guideline = {
          GuideDisplay: 'N',
        }
        overallSummary
        .append('text')
        .attr('transform', `translate(${style.x + 5}, ${style.y - Circle.CircleDiameter - 10})`)
        .style('font-size', 10).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'middle')
        .text('Plan (%)')

        this.Chart_PieSimple(overallSummary, data, style, Circle, Guideline)

        style = {
          id: 'SHI',
          x: 130,
          y: 55,
          ty: 59,
          name: tmp.ACTUAL,
          sort: 'N',
        }
        data = [
          {title: 'Completed',  value: tmp.ACTUAL },
          {title: 'Remain',     value: 100-tmp.ACTUAL }
        ]
        Circle.CircleColorSet = ['LightBlue', 'LightGray']
        
        overallSummary
        .append('text')
        .attr('transform', `translate(${style.x+ 5}, ${style.y - Circle.CircleDiameter - 10})`)
        .style('font-size', 10).style('font-family', 'roboto').attr('fill', '#212121').style('text-anchor', 'middle')
        .text('Actual (%)')

        this.Chart_PieSimple(overallSummary, data, style, Circle, Guideline)

        let deltaBox = overallSummary
        .append('g')
        .attr('transform', `translate(45, 40)`)

        deltaBox
        .append('text')
        .attr('transform', `translate(25, 12)`)
        .style('font-size', 9)
        .style('font-family', 'roboto')
        .attr('fill', '#959595')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text('Delta (%)')

        deltaBox
        .append('text')
        .attr('transform', `translate(25, 30)`)
        .style('font-size', 12)
        .style('font-family', 'roboto')
        .attr('fill', tmp.DELTA >= 0 ? '#1e88e5' : '#bf360c')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(tmp.DELTA)

        // Mask
        let mask = overallSummary.append('rect').attr('class', '_level_group_mask__')
        .attr('transform', `translate(-30, 0)`).attr('width', distance).attr('height', distance-3).attr('fill', 'Transparent')

        mask.on('mouseover', () => {
          mask.style('cursor', 'pointer')
          d3.select('._level_background_bar__').transition().duration(100).attr('y', `${i*distance}`)
        })

        mask.on('click', () => { 
          let dataSource = {
            LV2: 'TOPSIDES MODULES',
            BLOCK: block,
            GWBS_LV3: tmp.GWBS_LV3
          }
          let request_ = this.getRequestColumnProps('topsidelevel', dataSource, this.ColumnProps)
          this.$emit('request-action', request_)
        })        
        
      });
    },
    drawBarProg(){
      if(!this.Queries.SQL1 || this.Queries.SQL1.length === 0) return
      let blocks1 = [...new Set(this.Queries.SQL1.map(d => d.BLOCK))]

      blocks1.forEach((block1, i)=>{

        let equipments_ = this.Queries.SQL1
        let svg = this.svg
        let eqstatusGroup = svg.append('g').attr('id',`${block1}_eqstatusGroup`).attr('transform', `translate(240, ${200*i})`)
        
        let eqItemSet = []
        let eqStyle = {
          x: 10,
          y: 10,
          headTextSize: 12,
          headTitle: '',
          subTextSize: 14
        }

        this.eqStatus.forEach((d__, i) => {
          if (!equipments_.filter(d => d.BLOCK === block1)[0]) return
          let tColName = this.eqStatus[i]['colName']['total']
          let aColName = this.eqStatus[i]['colName']['actual']

          eqItemSet.push({
            name: d__.title,
            total: equipments_.filter(d => d.BLOCK === block1)[0][tColName],
            actual: equipments_.filter(d => d.BLOCK === block1)[0][aColName],
            filter: {
              STATUS: d__.code,
              BLOCK: equipments_.filter(d => d.BLOCK === block1)[0].BLOCK
            },
            bColor: this.eqStatus[i].bColor,
            opacity: this.eqStatus[i].opacity,
          })
        })
        this.Bar_Quantity_02(eqstatusGroup, eqItemSet, eqStyle, 0) // draw equipment bar chart

      });
    },
    drawShopProg(){
      if(!this.Queries.SQL2 || this.Queries.SQL2.length === 0) return
      let blocks2 = [...new Set(this.Queries.SQL2.map(d => d.GWBS_LEVEL))]

      blocks2.forEach((block2, i) => {

        let arcProps = {
          angleSize: 360,
          angleStart: 0,
          bColor: '#F4F5F5',
          stroke: 8,
          Ra: 38
        }
        let values_ = this.Queries.SQL2.filter(d => d.GWBS_LEVEL === block2)[0]
        
        let donutGroup = this.svg
        .append('g')
        .attr('class', `${block2}_donut_group_`)
        .attr('transform', `translate(440, ${200*i +90})`)

        donutGroup
        .append('text')
        .attr('transform', `translate(${-10}, ${-58})`)
        .style('font-family', 'roboto')
        .style('font-size', 13)
        .style('fill', '#2c3e50')
        .attr('text-anchor', 'start')
        .text('Shop Drawing Status')
  
        ShopData.attrs.forEach((attr, i) => {
          let arc = d3.arc()
          .outerRadius(d => d.Ra)
          .innerRadius(d => d.Ra - d.stroke)
          .startAngle(d => d.angleStart * Math.PI / 180)
          .endAngle(d => d.angleEnd || d.angleStart * Math.PI / 180)
          let x__ = ShopData.width * i + ShopData.gab
          let donut_ = donutGroup
          .append('g')
          .attr('class', `_donut_chart__${attr.refColumn.toLowerCase()}`)
          .attr('transform', `translate(${x__}, 0)`)
  
          // Draw Background Donut Chart
          donut_
          .append('circle')
          .attr('cx', 0).attr('cy', 0).attr('r', arcProps.Ra-3)
          .attr('stroke', arcProps.bColor).attr('stroke-width', arcProps.stroke)
          .attr('fill', '#fff')
          // .attr('fill', 'url(#lightGray)')
  
          let circleRate = values_['TOTAL'] ? (values_[attr.refColumn] || 0) / values_['TOTAL'] : 0
  
          // Draw Foreground Donut Chart
          donut_
          .datum(arcProps)
          .append('path')
          .attr('d', arc)
          .attr('fill', attr.fColor)
          .attr('opacity', 0.5)
          .transition()
          .duration(1500)
          .attrTween('d',(d) => {
            let endAngle_ = ((d.angleSize * circleRate) + d.angleStart) * Math.PI / 180
            let interpolate = d3.interpolate(d.angleStart * Math.PI / 180, endAngle_)
            return function(t) {
              d.angleEnd = interpolate(t)
              return arc(d);
            }
          })
  
          donut_
          .append('text')
          .attr('transform', `translate(0, 1)`)
          .style('font-family', 'roboto')
          .style('font-size', 12)
          .style('fill', attr.tColor)
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          .text(values_[attr.refColumn] || 0)
  
          // Legend
          donut_
          .append('text')
          .attr('transform', `translate(0, 50)`)
          .style('font-family', 'roboto')
          .style('font-size', 9)
          .style('fill', '#757575')
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          .text(attr.text)

          donut_.on('mouseover', () => {
            donut_.style('cursor', 'pointer')
          })
  
          donut_.on('click', () => {
            let status = attr.text
            let dataSource = {
              GWBS_LV3: values_.GWBS_LV3,
              GWBS_LEVEL: values_.GWBS_LEVEL
            }
            if (status === 'Plan') dataSource.PLANNED = 'Y'
            if (status === 'Actual') dataSource.STATUS = 'Completed'
            if (status === 'Remain') dataSource.STATUS = 'Not Started'
  
            let request_ = this.getRequestColumnProps('topsideSDI', dataSource, this.ColumnProps)
            this.$emit('request-action', request_)
          })
        })
  
        let progGroup = donutGroup
        .append('g')
        .attr('class', '_group_progs')
        .attr('transform', `translate(410, ${-30})`)
        
        // Plan
        progGroup.append('text').attr('x', 0).attr('y', 18).attr('text-anchor', 'middle')
        .text('Plan')
        .style('font-size', 12)
        .attr('fill', '#ccc')
  
        progGroup.append('rect')
        .attr('x', 30).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', 70).attr('height', 25).attr('fill', '#EEEEEE')
  
        progGroup.append('text').attr('x', 65).attr('y', 18).attr('text-anchor', 'middle')
        .text(values_.PLAN_PROG + '%')
        .style('font-size', 12)
        .attr('fill', '#00B0FF')
  
        // Actual
        progGroup.append('text').attr('x', 0).attr('y', 67).attr('text-anchor', 'middle')
        .text('Actual')
        .style('font-size', 12)
        .attr('fill', '#66BB6A')
  
        progGroup.append('rect')
        .attr('x', 30).attr('y', 50).attr('rx', 3).attr('ry', 3).attr('width', 70).attr('height', 25).attr('fill', '#66BB6A')
  
        progGroup.append('text').attr('x', 65).attr('y', 67).attr('text-anchor', 'middle')
        .text(values_.ACTUAL_PROG + '%')
        .style('font-size', 12)
        .attr('fill', '#fff')
      });
    },
    drawTable(){
      if(!this.Queries.SQL3 || this.Queries.SQL3.length === 0) return
      let tableData = this.svg

      let blocks3 = [...new Set(this.Queries.SQL3.map(d => d.BLOCK))]

      blocks3.forEach((block3, i)=>{
        let table_group = tableData.append('g').attr('transform', `translate(980, ${200*i+20})`).attr('class', `${block3}_table_group_`)

        // SET HERE!
        let list = this.Queries.SQL3.filter(d => d.BLOCK === block3)
        let tableViewHeight = 96 + (list.length * 18) // thead + tbody
  
        // if (tableViewHeight > this.scrollProps.h) this.setScroll(table_group, this.scrollProps)
  
        let table_obj = table_group.append('foreignObject')
          .attr('y',0)
          .attr('width', '100%')
          .attr('height', `${tableViewHeight}px`)
          .append('xhtml:body')
  
        let table = table_obj.append('table')
                    .style('border', 'solid 0.7px #eee')
                    .style('border-collapse', 'collapse')
                    .style('font-size', '10px')
                    .style('width', `${this.tableWidth}px`)
                    .style('table-layout', 'fixed')
  
        let colgroup = table.append('colgroup')
        this.tbody.forEach(k => {
          colgroup.append('col').style('width', k.w + 'px')
        })
  
        let maxRow = Math.max(...this.thead.map(d => d.row))
  
        let thead = table.append('thead')
        for (let r = 1; r <= maxRow; r++) {
          let row = this.thead.filter(d=> d.row === r)
          let tr = thead.append('tr').style('background', '#FAFAFA')
  
          row.forEach(item => {
            tr.append('th')
            .attr('class', 'completion_dashboard_table_group___th')
            .attr('rowspan', () => item.rowspan ? item.rowspan : '')
            .attr('colspan', () => item.colspan ? item.colspan : '')
            .style('border', 'solid 0.7px #eee')
            .style('padding', ['Start','Finish','Prog(%)'].includes(item.html) ? '2px': '5px')
            .style('font-weight','normal')
            .style('font-size', ['Start','Finish','Prog(%)'].includes(item.html) ? '10px': '11px')
            .style('color', ['Start','Finish','Prog(%)'].includes(item.html) ? '#039BE5':'#2c3e50')
            .html(item.html)
          })        
        }
          
        let tbodykeys = this.tbody
        let tbody = table.append('tbody')
  
        for (let r = 0; r < list.length; r++) {
          let tr = tbody.append('tr')
  
          tr.style('background-color', `${list[r].ACTUAL_PROG === 100 ? '#C5E1A5' : '#fff'}`)
          tbodykeys.forEach((k, i) => {
            let td = tr.append('td')
              .style('border', 'solid 0.7px #eee')
              .style('height', '22px')
              .style('text-align', k.align ? k.align : 'right')
              .style('padding', '0 3px 0 3px')
  
            if(i === 0) {
              td.style('white-space', 'nowrap')
                .style('overflow', 'hidden')
                .style('text-overflow', 'ellipsis')
                .attr('title', list[r][k.key])
            }
            if(i === 7) {
              td.style('color', `${list[r][k.key] < 0 ? '#FF0000' : '#424242'}`)
            }
  
            if (!list[r][k.key]) return
            td.html(list[r][k.key])
  
          })
        }
      });
    }
  }
}
