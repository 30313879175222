import * as d3 from 'd3'

export default {
  data: () => ({
    axisYHeight: 140,
    axisXWidth: 190,
    TrapezoidX:383,
    TrapezoidY:48,
    TrapezoidC:24,

    tickPreWeek: [],
    tickThisWeek: [],
    tickGridX: [],

    maxValue:null,
    scaleGridX: null,
    scaleX: null,
    scaleYParent: null,
    scaleYChild: null,

    selectCode:null,
    colnames: ['PRE_WEEK', 'THIS_WEEK']
  }),
  computed: {
    ready2draw() {
      // console.log('------------------------------')
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0 
      )
    },
  },
  methods: {
    setDefaultValues(){
      // Data stringify
      //원래는 그냥 map이었는데 데이터가 없는게 있어서 filter를 추가함 있는것만 가지고 오게
      this.tickPreWeek = this.Queries.SQL2.filter(d=> d.PRE_WEEK).map(d => d.PRE_WEEK) //배열로 하나만 가져오게 만들자
      this.tickThisWeek = this.Queries.SQL2.filter(d=> d.THIS_WEEK).map(d => d.THIS_WEEK)

      this.maxValue = this.getMaxBound(Math.max(...this.tickPreWeek, ...this.tickThisWeek))
      //getMaxBound 최대값을 구해놓고 446이렇게 되면 grid가 안예쁘니까 딱 떨어지는 숫자로 만들어주자!
      
      this.scaleX = d3.scaleLinear()
      .domain([0, this.maxValue])
      .range([0, this.axisXWidth])

      // The scale spacing the groups:
      this.scaleYParent = d3.scaleBand() //전체에서 우리는 칼럼이 4개니까 4칸중의 하나
      .domain(this.codeDescr.map(d => d.DESCR))
      .rangeRound([0, this.axisYHeight])
      .paddingInner(0.2);

      // The scale for spacing each group's bar:
      this.scaleYChild = d3.scaleBand() //그 4칸중의 하나를 우리는 2개로 나눠야됨
      .domain(['W1', 'W2'])
      .rangeRound([0, this.scaleYParent.bandwidth()])
      .padding(0.05)

      //gridX 작업과 axis값 넣기
      let factor = 5
      let width = this.maxValue / factor
      this.tickGridX = Array.from({ length: factor+1 }, (_, i) => width * i)
      this.scaleGridX = d3.scaleLinear() 
      .domain([0, this.maxValue])
      .range([0, this.axisXWidth])
    },
  }
}