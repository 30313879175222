import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({

    props:[
            {head1: ''    ,     head2: 'Estimate',  x: 0, cx: 277, cy: -5},
            {head1: 'Current',  head2: 'Qty',       x: 65, cx: 342, cy: 0},
            {head1: 'Actual',   head2: 'Qty',       x: 130, cx: 407, cy: 0},
            {head1: '', head2: 'Prog(%)',   x: 200,  cx: 520, cy: -5},
            {head1: 'Actual',   head2: 'Qty',       x: 410, cx: 690, cy: 0},
            {head1: '',  head2: 'Prog(%)',   x: 1000,  cx: 800, cy: -5},
    ],
    head1: ['DSME', 'Current', 'Actual', 'Delivery', 'Actual', 'Install',],
    head2: ['Estimate', 'Qty', 'Qty', 'Prog(%)', 'Qty', 'Prog(%)',],

    disc: null,
  }),
  

  methods: {
    
    drawChart() {
      this.disc = [...new Set(this.dataSet.map(d => d.DISC))]

      let PreOutfitting = this.svg
      .append('g')
      .attr('transform', `translate(0, 10.5)`)

      PreOutfitting
      .append('rect')
      .attr('x',220).attr('y',40).attr('width',375).attr('height',30).attr('stroke-width', 0).attr('fill', '#F4F5F5').attr('opacity', 0.4)
      PreOutfitting
      .append('rect')
      .attr('x',640).attr('y',40).attr('width',240).attr('height',30).attr('stroke-width', 0).attr('fill', '#F4F5F5')

      PreOutfitting.append('line').attr('x1',220).attr('y1',40).attr('x2',595).attr('y2',40).attr('stroke-width', 0.3).attr('stroke', '#333')
      PreOutfitting.append('line').attr('x1',20).attr('y1',70).attr('x2',595).attr('y2',70).attr('stroke-width', 0.3).attr('stroke', '#333')
     
      PreOutfitting.append('line').attr('x1',640).attr('y1',40).attr('x2',880).attr('y2',40).attr('stroke-width', 0.3).attr('stroke', '#333')
      PreOutfitting.append('line').attr('x1',640).attr('y1',70).attr('x2',880).attr('y2',70).attr('stroke-width', 0.3).attr('stroke', '#333')
    
      PreOutfitting
      .append('text')
      .attr('x', 420).attr('y', 35).style('font-family','roboto').style('font-size',11).attr('text-anchor', 'middle').attr('fill', '#333').text('Fabrication Delivery')

      PreOutfitting
      .append('text')
      .attr('x', 770).attr('y', 35).style('font-family','roboto').style('font-size',11).attr('text-anchor', 'middle').attr('fill', '#333').text('Installation')

      let sumLineY1 = 0
      let sumLineY2 = 0
      PreOutfitting
        .append('g')
        .attr('transform', `translate(20, 70)`)
        .selectAll('line')
        .data(this.disc)
        .enter()
        .append('line')
        .attr('x1', 0)
        .attr('y1', (d,i) => {
          const filter = this.dataSet.filter(s => s.DISC === this.disc[i])
          return sumLineY1 += (18 * filter.length)
        })
        .attr('x2', 860)
        .attr('y2', (d,i) => {
          const filter = this.dataSet.filter(s => s.DISC === this.disc[i])
          return sumLineY2 += (18 * filter.length)
        })
        .attr('stroke-width', 0.3)
        .attr('stroke', '#bcbcbc')


      let sumY = 0
      // Discipline Name
      PreOutfitting
        .append('g')
        .attr('transform', `translate(70, 83)`)
        .selectAll('text')
        .data(this.disc)
        .enter()
        .append('text')
        .attr('x', 0)
        .attr('y', (d,i) => {
          const filter = this.dataSet.filter(s => s.DISC === this.disc[i - 1])
          return sumY += i > 0 ? (18 * filter.length) : 0
        })
        .style('font-family','roboto')
        .style('font-size',10)
        .attr('text-anchor', 'middle')
        .attr('fill', '#000')
        .text(d => d)

      // Work Type Name
      PreOutfitting
        .append('g')
        .attr('transform', `translate(140, 83)`)
        .selectAll('text')
        .data(this.dataSet)
        .enter()
        .append('text')
        .attr('x', 0)
        .attr('y', (d,i) => i*18)
        .style('font-family','roboto')
        .style('font-size',10)
        .attr('text-anchor', 'start')
        .attr('fill', '#333')
        .text(d => d.OUTFIT)


      PreOutfitting
        .append('g')
        .attr('transform', `translate(-20, 52)`)
        .selectAll('text')
        .data(this.props)
        .enter()
        .append('text')
        .attr('x', d => d.cx)
        .attr('y', 0)
        .style('font-family','roboto')
        .style('font-size',10)
        .attr('text-anchor', 'middle')
        .attr('fill', '#757575')
        .text(d => d.head1)

      PreOutfitting
        .append('g')
        .attr('transform', `translate(-20, 63)`)
        .selectAll('text')
        .data(this.props)
        .enter()
        .append('text')
        .attr('x', d => d.cx)
        .attr('y', d => d.cy)
        .style('font-family','roboto')
        .style('font-size',10)
        .attr('text-anchor', 'middle')
        .attr('fill', '#757575')
        .text(d => d.head2)


        let Items = PreOutfitting
        .append('g')
        .attr('transform', `translate(275,83)`)

        this.dataSet.forEach((data,r) => {
          let values_ = Object.values(data)
          
          let sub_Item_ = Items
          .append('g')
          .attr('transform', `translate(0,0)`)
          .selectAll('text')
          .data(values_.slice(2,8))
          .enter()

          sub_Item_
          .append('text')
          .attr('x', (d,i) => this.props[i].x)
          .attr('y', r*18)
          .style('font-family','roboto')
          .style('font-size',10)
          .attr('text-anchor', 'end')
          .attr('fill', '#757575')
          .text((d,i) => {
            if (i == 3 || i == 5 ) return ''
            else return d.toLocaleString()
          })
          
          let sub_prog_ = Items
          sub_prog_
          .append('rect')
          .attr('x', 150).attr('y', (r*18)-9).attr('width',170).attr('height',12).attr('stroke-width', 0).attr('fill', '#F4F5F5')

          sub_prog_
          .append('rect')
          .attr('x', 150).attr('y', (r*18)-9)
          .attr('width', 0)
          .attr('height',12).attr('stroke-width', 0).attr('opacity', 0.5)
          .attr('fill', () => {
            if (values_[5] == 100) return '#CDDC37'
            return '#B4E3FA'
          })
          .transition().duration(500).attr('width', (values_[5]*0.01) * 170)
      
          sub_prog_
          .append('text')
          .attr('x', 180).attr('y', r*18).style('font-family','roboto').style('font-size',10).attr('text-anchor', 'end').attr('fill', '#757575')
          .text(`${values_[5]}%`)
          .transition().duration(500)
          .attr('x', `${ values_[5] < 20 ? values_[5] === 0 ? 150 : 170 : 150 + (values_[5]*0.01) * 170 } `)
          .attr('text-anchor', `${ values_[5] < 20 ? 'start' : 'end'}`)

          sub_prog_
          .append('rect')
          .attr('x', 435).attr('y', (r*18)-9).attr('width',170).attr('height',12).attr('stroke-width', 0).attr('fill', '#F4F5F5')
          
          sub_prog_
          .append('rect')
          .attr('x', 435).attr('y', (r*18)-9)
          .attr('width', 0)
          .attr('height',12).attr('stroke-width', 0).attr('opacity', 0.5)
          .attr('fill', () => {
            if (values_[7] == 100) return '#CDDC37'
            return '#B4E3FA'
          })
          .transition().duration(500).attr('width', (values_[7]*0.01) * 170)
      
          sub_prog_
          .append('text')
          .attr('x', 435)
          .attr('y', r*18).style('font-family','roboto').style('font-size',10).attr('text-anchor', 'end').attr('fill', '#757575')
          .text(`${values_[7]}%`)
          .transition().duration(500)
          .attr('x', `${ values_[7] < 10 ? values_[7] === 0 ? 435 : 450 : 435 + (values_[7]*0.01) * 170 } `)
          .attr('text-anchor', `${ values_[7] < 20 ? 'start' : 'end'}`)
        })
          
    }, 

  }
}
