import * as d3 from 'd3'
import Data from '../primitives/donutProps'

export default {
  data: () => ({
    refColumn: 'SUBSYSTEM_STATUS'
  }),
  methods: {
    drawDonuts() {
      if(!this.Queries.SQL5 || this.Queries.SQL5.length === 0) return

      d3.select(`._donut_group__for_all`).remove()

      let arcProps = {
        angleSize: 360,
        angleStart: 0,
        bColor: '#F4F5F5',
        stroke: 8,
        Ra: 30
      }
      let values_ = this.Queries.SQL5[0]

      let donutGroup = this.SkylineCharts
      .append('g')
      .attr('class', '_donut_group__for_all')
      .attr('transform', `translate(70, 135)`)

      let cicleGroup = donutGroup
      .append('g')
      .attr('class', '_cicle')
      .attr('transform', `translate(-5, 60)`)

      cicleGroup
      .append('text')
      .attr('transform', `translate(-13,72)`)
      .style('font-size', 10).style('fill', '#424242').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Open B2 Punch + Open B3 Punch')
      cicleGroup
      .append('rect')
      .attr('transform', `translate(-35, ${64})`)
      .attr('width', 16).attr('height', 16).attr('rx',50).attr('fill', '#FF6D00')
      cicleGroup
      .append('text')
      .attr('transform', `translate(-30,73)`)
      .style('font-size', 10).style('fill', '#ffffff').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('0')
      
      cicleGroup
      .append('text')
      .attr('transform', `translate(180,72)`)
      .style('font-size', 10).style('fill', '#424242').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Open B ITR + Open B1 Punch')
      cicleGroup
      .append('rect')
      .attr('transform', `translate(155, ${64})`)
      .attr('width', 16).attr('height', 16).attr('rx',50).attr('fill', '#FF4081')
      cicleGroup
      .append('text')
      .attr('transform', `translate(160,73)`)
      .style('font-size', 10).style('fill', '#ffffff').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('0')


      Data.attrs.forEach((attr, i) => {
        let arc = d3.arc()
        .outerRadius(d => d.Ra)
        .innerRadius(d => d.Ra - d.stroke)
        .startAngle(d => d.angleStart * Math.PI / 180)
        .endAngle(d => d.angleEnd || d.angleStart * Math.PI / 180)


        // let x__ = Data.width * i + (i > 0 ? Data.gab : 0)
        let x__ = Data.width * i
        let donut_ = donutGroup 
        .append('g')
        .attr('class', `_donut_chart__${attr.refColumn.toLowerCase()}`)
        .attr('transform', `translate(${x__}, 0)`)

        // Draw Background Donut Chart
        donut_
        .append('circle')
        .attr('cx', 0).attr('cy', 0).attr('r', arcProps.Ra-3)
        .attr('stroke', arcProps.bColor).attr('stroke-width', arcProps.stroke)
        .attr('fill', `url(#${attr.fill})`)

        let circleRate = values_[attr.refColumn] / values_['TOTAL']

        // Draw Foreground Donut Chart
        donut_
        .datum(arcProps)
        .append('path')
        .attr('d', arc)
        .attr('fill', attr.fColor)
        .attr('opacity', 0.8)
        .transition()
        .duration(1500)
        .attrTween('d',(d) => {
          let endAngle_ = ((d.angleSize * circleRate) + d.angleStart) * Math.PI / 180
          let interpolate = d3.interpolate(d.angleStart * Math.PI / 180, endAngle_)
          return function(t) {
            d.angleEnd = interpolate(t)
            return arc(d);
          }
        })
        

        donut_
        .append('text')
        .attr('transform', `translate(0, 1)`)
        .style('font-family', 'roboto')
        .style('font-size', 13)
        .style('fill', attr.tColor)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(values_[attr.refColumn] ? values_[attr.refColumn] : 0)

        // Legend
        donut_
        .append('text')
        .attr('transform', `translate(0, 42)`)
        .style('font-family', 'roboto')
        .style('font-size', 10)
        .style('fill', '#757575')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(attr.text1)

        donut_
        .append('text')
        .attr('transform', `translate(0, 52)`)
        .style('font-family', 'roboto')
        .style('font-size', 10)
        .style('fill', '#757575')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(attr.text2)

        donut_
        .append('text')
        .attr('transform', `translate(0, 62)`)
        .style('font-family', 'roboto')
        .style('font-size', 10)
        .style('fill', '#757575')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(attr.text3)
        
        donut_.on('click', (d, i, a) => {
          if (!values_[attr.refColumn] || values_[attr.refColumn] == 0) return

          if (attr.codeValue == 'Total') {
            // Total Init Donuts
            attr.on = !attr.on

            let attrs = []

            if (!attr.on) {
              // Total false, all off
              Data.attrs.forEach(attr => {
                attr.on = false
                d3.select(`._donut_chart__${attr.refColumn.toLowerCase()}`).style('opacity', 0.3)
                attrs.push(attr.codeValue)
                this.filtering[this.refColumn] = []
              })
            } else {
              // Total true, all on
              Data.attrs.forEach(attr => {
                attr.on = true
                d3.select(`._donut_chart__${attr.refColumn.toLowerCase()}`).style('opacity', 1)
                attrs.push(attr.codeValue)
              })

              this.filtering[this.refColumn] = []
              this.filtering[this.refColumn] = attrs
            }

          } else {
            attr.on = !attr.on
            if(attr.on) d3.select(a[i]).style('opacity', 1)
            else d3.select(a[i]).style('opacity', 0.3)
    
            this.setFilter('multi', this.refColumn, attr.codeValue)
            // this.search(false)
            
          }

          // console.log(this.filtering)
          this.Chart_filtering()

        })
        .on('mouseover', (_, i, a) => { d3.select(a[i]).style('cursor', 'pointer') })

        if(attr.on) this.setFilter('multi', this.refColumn, attr.codeValue)
      })
      
      let loopValues_ = this.Queries.SQL6[0]

      let loopGroup = this.SkylineCharts
      .append('g')
      .attr('class', 'loopGroup')
      .attr('transform', `translate(30, 290)`)

      loopGroup.append('rect')
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', 323)
        .attr('height', 260)
        .attr('fill', '#fff')

      let loopColors = [
        {key: 'Total Loops', text: 'Total I&C Function Test', background: '#fff', fColor: '#757575'},
        {key: 'ICFT based on Loop Test Prerequisites', text: 'I&C Function Test available based on I&C Function Test Prerequisites', background: '#81D4FA', fColor: '#fff'},
        {key: 'ICFT available based on Partial MC Completed', text: 'I&C Function Test available based on Partial MC Complete', background: '#8BC34A', fColor: '#fff'},
        {key: 'ICFT available based on Full MC Completed', text: 'I&C Function Test available based on Full MC Complete', background: '#43A047', fColor: '#fff'},
        {key: 'Incomplete ICFT', text: 'Incomplete I&C Function Test', background: '#E0E0E0', fColor: '#757575'},
        {key: 'Complete ICFT', text: 'Complete I&C Function Test', background: '#000', fColor: '#fff'},
      ]

      let loopHeight = 30
      loopColors.forEach((value, idx) => {

        loopGroup.append('rect')
        .attr('x', 0)
        .attr('y', loopHeight * idx)
        .attr('rx', 3)
        .attr('width', 50)
        .attr('height', 25)
        .attr('fill', value.background)
        .attr('stroke', '#ccc')
        .attr('stroke-width', 1)
        
        loopGroup.append('text')
        .attr('transform', `translate(25 ${(loopHeight * idx) + 13})`)
        .style('font-family', 'roboto')
        .style('font-size', 12)
        .style('fill', value.fColor)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(loopValues_[value.key])


        loopGroup.append('text')
        .attr('transform', `translate(60 ${(loopHeight * idx) + 13})`)
        .style('font-family', 'roboto')
        .style('font-size', 12)
        .style('fill', '#212121')
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text(value.text)

      })

      let data = null, 
          style = null

      let overallMCStatus = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0)`)
    
      data = this.Queries.SQL7.filter(f=> f.TYPE == 'WEEK')

      style = {
                x: 50,
                y: 480,
      }
      data.forEach(d=> {
        d.date = d.DATE
        if(d.DISC =='LWK' || d.DISC =='TWK') d.name = 'Total'; else d.name = d.DISC
        d.value = d.ACTUAL
      })
      
      this.WeekPerformance_01(overallMCStatus, data, style)
    },

    WeekPerformance_01(selection, _data, _style) {

      _data.forEach((data,i) => {
        if (i ==0 || i == _data.length -1) {
          data.width = 35
          data.tColor = '#333'
        } else {
          data.width = 25
          data.tColor = '#bcbcbc'
        }
      })

      let weekStatus = selection
      .append('g')
      .attr('transform', `translate(${_style.x}, ${_style.y})`)

      
      weekStatus
      .append('text')
      .attr('x', 0).attr('y', 8)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#333').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text('Last Week')

      weekStatus
      .append('text')
      .attr('x', 140).attr('y', 8)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#44A9DF').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text('This Week')

      weekStatus
      .append('line')
      .attr('x1', 35).attr('y1', 8).attr('x2', 110).attr('y2', 8).style('stroke', '#757575').style('stroke-width', 0.3)
      weekStatus
      .append('line')
      .attr('x1', 170).attr('y1', 8).attr('x2', 245).attr('y2', 8).style('stroke', '#757575').style('stroke-width', 0.3)

      let drawX = 0
      _data.forEach((d,i)=> {
        d.x = drawX

        weekStatus
        .append('text')
        .attr('x', drawX).attr('y', 22)
        .style('font-family', 'roboto').style('font-size', 9).style('fill', d.tColor).attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text(d.name)

        weekStatus
        .append('rect')
        .attr('transform', `translate(${drawX - (d.width/2)},30)`)
        .attr('width', d.width).attr('height', 14)
        .attr('stroke-width', .3)
        .attr('stroke', () => { if (d.name == 'Total') return '#44A9DF'; else return '#bcbcbc' })
        .attr('fill', () => { if(d.name != 'Total' && d.value > 0) return '#F4F5F5'; else return '#fff' })

        weekStatus
        .append('text')
        .attr('id', `weekValue_${this.localId}_${i}`)
        .attr('transform', `translate(${drawX}, 38)`)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text(() => {
          if(d.value > 0) return d.value
        })
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100)
          .attr('transform', `translate(${d.x}, 38) scale(1.3)`).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100)
          .attr('transform', `translate(${d.x}, 38) scale(1)`).style('fill', '#757575')
        })
        .on('click', () => { 
          let MODAL_FILTER_STRING = this.ColumnProps.find(props_ => props_.text == 'MODAL_FILTER_STRING')
          let filtercondition = `${MODAL_FILTER_STRING ? MODAL_FILTER_STRING.value : ''}`

          let weekcondition = ``
          if (d.DISC == 'LWK' || d.DISC == 'TWK') {
            weekcondition = ` AND [STATUS_DATE] > DATEADD(WW,-1,'${d.DATE}') AND [STATUS_DATE] <= DATEADD(WW,0,'${d.DATE}')`
          } else {
            weekcondition = ` AND [STATUS_DATE] = '${d.DATE}'`
          }

          
          let request_ = {
            component: 'ServiceDatatable',
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: MODAL_FILTER_STRING.modalId,
              no: ''
            },
            filters     : {
                  ...this.FilterValues,
              // targetFilter.colName : TAG | PUNCH_NUMBER
              // if the filter value is empty, filter will be trimmed by store module.
              TAG_NO: this.filtering.TAG_NO || '',  
              PUNCH_NO: this.filtering.PUNCH_NO || '',
            },
            iFilters    : {
              filterString: `${filtercondition} ${weekcondition}`,
              inputFilter  : ''
            }
          }

          this.$emit('request-action', request_)
        })

        drawX += d.width + 3
        if(i==7) drawX += 10
      })
    },
  }
}