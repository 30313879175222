import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global L450H
// Global Data & Defs

export default {
  methods: {
    Activate_Deckbox() {

      this.Area = 'Deckbox'

      this.SVG_Activate = this.svg.append('svg')
      this.SVG_Activate
      .attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight)
      .attr('viewBox', `0 0 ${this.Canvas.CanvasWidth} ${this.Canvas.CanvasHeight}`)
      .attr('opacity', 1)


      // Area_Group
      let Deckbox_Group = this.SVG_Activate
      .append('g')
      .attr('id', 'Deckbox_Group')
      .attr('transform', `translate(-1100,-800) scale(2.5)` )
      .attr('opacity', 0)
      .style('cursor', 'pointer')
      .call(s => this.Event_Module(s)) // ------>

      this.MODULES.Deckbox.forEach(mod => {
        // Draw the Block image & Codes
        // ### Module
        let ModuleGroup = Deckbox_Group
        .append('g')
        .attr('id', `${mod.module}`)
        .attr('transform', `translate(${mod.x1}, ${mod.y1}) scale(${1})`)
        .attr('opacity', 1)
        .style('cursor', 'pointer')
        .call(s => {
          if(mod.item == 'Y') return this.Event_Deck(s, mod.module) // ------>
        })

        if(this.DECKS.Deckbox.filter(f=> f.module == mod.module).length == 0) {
          ModuleGroup
          .append('image')
          .attr('opacity', 1)
          .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/Demo/Equipment_Layout/MD2/Deckbox/${mod.module}.png`)
          .attr('transform', `translate(0,0) scale(${0.25})`)

        } else {
          this.DECKS.Deckbox.filter(f=> f.module == mod.module).forEach(d => {
            ModuleGroup
            .append('image')
            .attr('id', `${d.deck}`)
            .attr('opacity', 1)
            .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/Demo/Equipment_Layout/MD2/Deckbox/${d.deck}.png`)
            .attr('transform', `translate(${d.x1}, ${d.y1}) scale(${0.25})`)
            .call(s => this.Event_Equipment(s, mod.module, d.deck)) // ------>
          })
        }
      })

       d3.select('#Deckbox_Group')
      .transition()
      .delay(300)
      .duration(1000)
      .attr('transform', `translate(-400,-300) scale(1.5)` )
      .attr('opacity', 1)

    },
  }
}
           