<template>
  <div :id="localId" class="svg_wrapper"></div>
</template>

<script>
import mx_Core from './includes/mixins/zMixins'        

export default {
  name: "j-information-guide-v1",
  mixins: [mx_Core],
  props: {
    id: String,
    modeExport: Boolean,
    getSelectItems: String,  

    FilterValues: { type: Object, default: () => ({}) },
    ColumnProps: { type: Array, default: () => ([]) },
    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },
    Note: { type: Object, default: () => ({}) },
    DataItems: { type: Array, default: () => ([]) },
    Queries: { type: Object, default: () => ({}) },

    callback: { type: Object, default: () => ({}) },
  },

  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
  },

  mounted() {
    this.register(this.draw)
  },

  methods: {
    draw() {
      if (!this.ready2draw) {
        return
      }
      this.clear()
      this.set_LocalValues()
      this.drawCanvas()
      this.drawing()
      this.drawTexts()
      this.drawNotes()
      this.complete()
    },

    drawing() {
      this.set_Gradients()
      this.draw_Forms()
      this.load_MainPlant()
      this.draw_Checkbox()
      this.draw_ListboxHeader()
      setTimeout(() => { this.draw_ListboxDetail() }, 350)

      if(this.selectedItems) {
        if(this.selectedItems.lang) {
          this.Toggle.status = this.selectedItems.lang
          this.toggle_Status(this.Toggle.status)
        }
      }
    },

  }
}
</script>

<style lang="scss" scoped>
  .svg_wrapper {
    min-width: 1600px;
    min-height: 1120px;
    max-width: 1600px;
    max-height: 1120px;

    overflow: hidden;
  }
</style>