export default {
  width: 120,
  gab: 10,
  attrs: [
    { 
      text: 'Total',
      refColumn: 'TOTAL',
      codeValue   : 'Total', // fixed
      fill : 'lightGray', 
      fColor: 'Transparent', 
      tColor: '#212121',
      on: true
    },
    { 
      text: 'To Go',
      refColumn: 'TOGO',
      codeValue   : 'To go',
      fill : 'lightGray', 
      fColor: 'Transparent', 
      tColor: '#818181',
      on: true
    },
    { 
      text: 'Delivered',
      refColumn: 'DELIVERED',
      codeValue   : 'Delivered',
      fill : 'fillLightGreen', 
      fColor: '#8BC34A',
      tColor: '#fff',
      on: true
    },
    { 
      text: 'Dressup',
      refColumn: 'DRESSUP',
      codeValue   : 'Dressup',
      fill : 'fillYellow', 
      fColor: '#FFD600', 
      tColor: '#fff',
      on: true
    },
    { 
      text: 'Complete',
      refColumn: 'LIFTING',
      codeValue   : 'Complete',
      fill : 'fillBlue', 
      fColor: '#66CCFF', 
      tColor: '#fff',
      on: true
    }
  ]
}
