<template>
  <div :id="localId"></div>
</template>

<script>
import * as d3 from 'd3'
import mx_Core from './includes/mixins/importer'            // Global & Local Mixins

export default {
  name: "j-chart-donut",
  mixins: [mx_Core],
  data: () =>  ({
    counters: '',
    scrollLeft: 0
  }),
  props: {
    id: String,
    
    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },
    DataItems: { type: Array, default: () => ([]) },
    Queries: {type: Object, default: () => ({}) },
    ColumnProps: { type: Array, default: () => ([]) },
    values: {
      type: Object,
      default: () => ({})
    },
    FilterValues: {
      type: Object,
      default: () => ({})
    },
  },
  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
    
  },
  mounted() {
    this.register(this.draw)
    
    setTimeout(() => {
      document.getElementById('contentScrollTarget').scrollLeft = this.timeline.scale(new Date(this.cutoff))
    }, 1000)

    this.counters = setInterval(() => {
      this.scrollLeft = document.getElementById('contentScrollTarget').scrollLeft
    }, 1100)
  },

  methods: {
    draw() {
      this.on4Weeks = false
      this.filteredList = JSON.parse(JSON.stringify(this.DataItems))
      // Check all the necessary values are available
      if(!this.ready2draw) return

      
      let jsonProps = this.ColumnProps.find(props_ => props_.text == 'ACTIVITY_COLUMN')
      if(jsonProps) this.activity = jsonProps.value

      this.clear()
      this.setStyles()

      
      // let keys = Object.keys(this.filteredList[0])
      // let hasKey = keys.find(d => d =='MC_PLAN')
      // if (hasKey) {
      //   this.activity = 'MC_PLAN'
      //   this.filterCodes.Schedule.values.forEach(value => {
      //     value.on = false
      //   })
      //   this.filterCodes.Schedule.values[0].on = true
      // }
      
      // let dataSet = JSON.parse(JSON.stringify(this.DataItems.filter(f => !!f[this.activity])))
      let dataSet = JSON.parse(JSON.stringify(this.filteredList.filter(f => !!f[this.activity])))

      dataSet.forEach(d => {
        if (d.SUBSYSTEM && d.SUBSYSTEM.length > 10) {
          this.maxValue = d.SUBSYSTEM.length
        }
      })
      let daterange = [
        { [this.activity]: dataSet.length > 0 ? new Date(Math.min.apply(null,dataSet.map(d => new Date(d[this.activity])))) : '2021-06-11' },
        { [this.activity]: dataSet.length > 0 ? new Date(Math.max.apply(null,dataSet.map(d => new Date(d[this.activity])))) : '2022-04-08' },
      ]
      if (daterange[1][this.activity] < new Date(this.Queries.SQL2[0].CDATE)) daterange[1][this.activity] = new Date(this.Queries.SQL2[0].CDATE)

      // let minDate = new Date(Math.min(dataSet.map(data => new Date(data[this.activity]))))
      // let maxDate = new Date(Math.max(dataSet.map(data => new Date(data[this.activity]))))

      if (this.maxValue != 0 ) {
        let fixed_width = 38
        this.skyline.box.width = parseInt((fixed_width * this.maxValue) / 7)
      }  

      this.setDefaultValues(daterange)   
      this.drawCanvas()
      this.drawChart()
      this.drawTexts()
      this.draw_LineCharts()
      // this.drawDonuts()

      // 'complete' must be executed to finish the parent process.
      // It sends 'this.localId' & XmlString for some case to make 
      // displaying image easy.
      this.complete()
      // this.chartMounted = true
    },
    redraw() {
      let dataSet = JSON.parse(JSON.stringify(this.filteredList.filter(f => !!f[this.activity])))
      let daterange = [
        { [this.activity]: dataSet.length > 0 ? new Date(Math.min.apply(null,dataSet.map(d => new Date(d[this.activity])))) : '2021-06-11' },
        { [this.activity]: dataSet.length > 0 ? new Date(Math.max.apply(null,dataSet.map(d => new Date(d[this.activity])))) : '2022-04-08' },
      ]
      this.setDefaultValues(daterange)
      this.draw_Table()
      this.draw_Timeline()
      this.draw_Charts()
      this.draw_Legends()
      this.drawDonuts()
      if (this.on4Weeks) this.draw_4wks_LineCharts()
      else this.draw_LineCharts()

      

      this.postComplete()
    },
    drawChart() {
      this.SkylineCharts = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0.5)`)

        this.draw_Table()
        this.draw_Timeline()
        this.draw_Charts()
        this.draw_LineCharts()
        this.draw_Legends()
        this.drawDonuts()
        this.postComplete()

    },
    postComplete() {
      let chart_ = d3.select('#skyline_boxes_timeline').node().getBoundingClientRect()
      let width_ = chart_.width + 400
      let height_ = this.realHeight + 400

      if (width_ < 950) width_ = 1225

      this.resizableCanvasWidth = width_
      this.resizableCanvasHeight = height_
      this.resizeCanvas()

      let dimention = { 
        width: Math.ceil(width_), 
        height: Math.ceil(height_), 
        isreal: true, 
      }
      this.$emit('resize', dimention)
    },
    destroyed(){
      clearInterval(this.counters)
    }
  }
}
</script>