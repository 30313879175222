import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  data: () => ({
  }),

  methods: {

    Canvas_Topside_Activate(lv3) {
      
      this.CANVAS = this.svg.append('svg')

      this.CANVAS
      .attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight)
      .style('opacity', 1)

      let style = null
      // console.log(lv3)

      // Block Status
      this.BlockStatus_Coordinate.filter(f => f.Lv3 == lv3).forEach((prog,sn) =>{
        let itemSet = []
        style = {
          x: 1090,
          y: 490,
          headTextSize: 16,
          headTitle: prog.NAME,
          headDescr: prog.SUBNAME,
          prog: this.Queries.MasterProg.find(f=> f.Lv3 == lv3),
        }
        this.Status.forEach((d,i) => {
          itemSet.push({
            name: d.title,
            total: this.dataSet.filter(f => f.Lv3 == lv3 && f.EREC == 'Y').length,
            actual: this.dataSet.filter(f => f.Lv3 == lv3 && f.EREC == 'Y' && f.STATUS == d.code).length,
            bColor: this.Status[i].bColor,
            opacity: this.Status[i].opacity,
          })
        })
        this.blockStatus(itemSet)
        this.Bar_Quantity_03(this.CANVAS, itemSet, style, 1) // <---- Chart Library
      })
      
      this.drawProgress(this.CANVAS, null, lv3)
    },
  }
}