import * as d3 from 'd3'

export default {
  methods: {
    
    draw_Checkbox() {

      if(this.check_Box) this.check_Box.remove()
      this.check_Box = this.svg

      // Package Group
      let box_ = this.check_Box
      .append('g')
      .attr('id', 'package_group')
      .attr('transform', `translate(10, 150)`)
      
      box_
      .append('rect')
      .attr('rx', 3)
      .attr('ry', 3)
      .attr('width', 280)
      .attr('height', 50)
      .attr('fill', '#fff')
      .attr('stroke', '#dadada')
      .attr('stroke-width', .5)

      //labelWidth
      box_
      .append('rect')
      .attr('transform', `translate(10, -5)`)
      .attr('width', 50)
      .attr('height', 10)
      .attr('fill', '#fff')

      //label
      box_
      .append('text')
      .attr('transform', `translate(20, 1)`)
      .style('font-size', 12)
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .text('Pakcage')
      
      let x_ = 25

      this.Legend.forEach((d, i) => {
        // 1. package legend //
        
        box_
        .append('rect')
        .attr('transform', `translate(${x_},20)`)
        .attr('rx', 3).attr('ry', 3)
        .style('width', 15).style('height', 15)
        .style('fill', d.COLOR)

        
        // 2. package checkbox//
        box_
        .append('rect')
        .attr('id', `package_checkbox__${d.CODE}`)
        .attr('transform', `translate(${x_ + 25},22)`)
        .attr('rx', 2)
        .attr('ry', 2)
        .attr('stroke', '#333')
        .attr('stroke-width', .5)
        .style('width', 12)
        .style('height', 12)
        .style('fill-opacity', 0)  

        // 3. package checkbox checked status
        box_
        .append('text')
        .attr('id', `package_checkbox_checked__${d.CODE}`)
        .attr('transform', `translate(${x_ + 28},28)`)
        .style('fill', '#999')
        .style('font-size', 13)
        .style('text-anchor', 'start')
        .style('alignment-baseline', 'middle')
        .text(d.CHECKED?'v':'')

        // 4. package label //
        box_
        .append('text')
        .attr('id', `package_label__${d.CODE}`)
        .attr('transform', `translate(${x_ + 42},28.7)`)
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .style('font-family', 'roboto')
        .style('font-size', 12)
        .style('fill', '#000')
        .text(d.NAME)

        // 3. package checkbox action area//
        box_
        .datum(d)
        .append('rect')
        .attr('id', `package_checkbox_area__${d.CODE}`)
        .attr('transform', `translate(${x_ + 20},19)`)
        .style('width', 40)
        .style('height', 20)
        // .attr('stroke', '#333')
        // .attr('stroke-width', .5)
        .style('cursor', 'pointer')
        .style('fill-opacity', 0)         
        .call(this.call_CheckList, d.CODE) // ---> Event.js

        x_ += 80
      })

    }
  }
}
