import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  data: () => ({
    props:[
      // {key: 'Partial_MC', x: 90, y: 20, tx: 60, cy: 0, color:'#7CB342'},
      // {key: 'Full_MC', x: 300, y: 40, tx: 180, cy: 0,color:'#43A047' },
      {key: 'MC_Complete', x: 300, y: 40, tx: 180, cy: 0,color:'#7CB342' },
    ],
    width: 850,
    height: 300,
  }),
  methods: {
    Draw_Charts() {

      const svg = this.svg
      const g = svg.append('g').attr('transform', `translate(0, 0)`)

      let data = this.DataItems
      let keys = Object.keys(data[0])

      let line = g.append('g').attr('class', 'line-group').attr('transform', `translate(0.5, 0.5)`)
      
      line.append('line')
      .attr('x1', 0).attr('y1', 25).attr('x2', 1270).attr('y2', 25)
      .attr('stroke-width', '0.5px').attr('stroke', '#ccc')

      line.append('line')
      .attr('x1', 0).attr('y1', 45).attr('x2', 1270).attr('y2', 45)
      .attr('stroke-width', '0.5px').attr('stroke', '#ccc')

      // line.append('line')
      // .attr('x1', 0).attr('y1', 65).attr('x2', 1270).attr('y2', 65)
      // .attr('stroke-width', '0.5px').attr('stroke', '#ccc')

      line.append('line')
      .attr('x1', 75).attr('y1', 25).attr('x2', 75).attr('y2', 45)
      .attr('stroke-width', '0.5px').attr('stroke', '#ccc')

      let lefthead = g.append('g').attr('class', 'lefthead-group').attr('transform', `translate(0, 20)`)

      // lefthead
      // .append('rect')
      // .attr('transform', `translate(0, 6)`)
      // .attr('width', 76).attr('height', 20).attr('fill', '#7CB342')

      lefthead
      .append('rect')
      .attr('transform', `translate(0, 6)`)
      .attr('width', 76).attr('height', 20).attr('fill', '#4CAF50')

      lefthead.append('text')
      .attr('x', 70)
      .attr('y', 20)
      .attr('text-anchor', 'end')
      .attr('fill', '#fff')
      .style('font-size',11)
      .text('MC Cum.')

      // lefthead.append('text')
      // .attr('x', 63)
      // .attr('y', 40)
      // .attr('text-anchor', 'end')
      // .attr('fill', '#fff')
      // .style('font-size',11)
      // .text('Full MC')



      // let thead = g.append('g').attr('class', 'thead-group').attr('transform', `translate(100, 17)`)
      // let head = data.map(d => d.title)

      // head.forEach((k, i) => {
      //   thead.append('text')
      //   .attr('x', i * (460 / head.length))
      //     .attr('y', 0)
      //     .attr('text-anchor', 'middle')
      //     .style('font-size',10)
      //     .attr('fill', () => '#212121')
      //     .style('font-weight', 'bold')
      //     .text(k)
      // })


      // tbody
      let tbody = g.append('g').attr('class', 'tbody-group').attr('transform', `translate(110, 0)`)

        keys.forEach((k, i) => {
          // console.log(k)
          let not = ['title']
          if (!not.includes(k)) {
              let row = data.map(m => {
                let obj = {VALUE: m[k],title: m['title'], KEY: k}
                return obj
              })
            row.forEach((r, ri) => {
              // console.log(this.props.filter(f => f.key === 'Partial_MC')[0])
              let value_ = tbody.append('text')
              .attr('x', ri * (1180 / row.length))
                .attr('y', () => this.props.filter(f => f.key === k)[0].y)
                .attr('text-anchor', 'middle')
                // .attr('fill', () => k == 'Partial_MC' ? '#689F38' :'#2E7D32')
                .attr('fill', () => r === 0 ? '#757575' : '#616161')
                .style('font-size', '11px')
                .text(r.VALUE)
            })
          }
        })
    },
  }
}
