import Defs from '../../../../../../includes/primitives/Color_Defs'

export default {
  data: () => ({
    dataSet: [],
    lineChartData: [],
    activity: 'LIFTING_FORECAST_WK',
    inputFilter: {
      values: [
        { colName: 'TAG_NO'    , text: 'TAG No.' , value: '', x: 0   , textWidth: 67, on: true }
      ]
    },
    filterCodes: {
    },
    skyline: {
                sizeType    : 'auto',
                fixedSize   : 450,
                minSize     : 700,
                startFloat  : 2,      // Fixed
                endFloat    : 2,      // Fixed
                weekEnd     : 'friday',
                font        : 'roboto',
                marginTop   : 350    ,

                box: {
                            font      : 'roboto', 
                            width     : 60,     // Fixed
                            height    : 15,     // Fixed
                            gap       : 1,      // Fixed
                },
                line : {
                            display   : 'Y',
                            height    : 390,
                            gap       : 1,
                },
                legend: {
                            x         : 25,
                            y         : 50,
                            font      : 'roboto', 
                            group: {
                                      tSize     : 11,
                                      tColot    : '#333',
                                      lineSpace : 15,
                            },
                            item: {
                                      tSize     : 10,
                                      tColor    : '#757575',
                                      space     : 10,
                            },
                            notes: {
                                      x         : 10,
                                      y         : 150,
                                      tSize     : 10,
                                      tColor    : '#bcbcbc',
                                      space     : 15,
                            },
                },
                dropdown: {
                            x         : 25,
                            y         : 90,
                            option: [
                                      {text: 'Steel Sutting',   value: 'SC'},
                                      {text: 'Block Assembly',  value: 'Assembly'},
                                      {text: 'Painting',        value: 'Painting'},
                                      {text: 'Block Erection',  value: 'Erection'},
                            ],
                },
    },
    timeline: { 
                week        : [],
                month       : [],
                year        : [],
                plan        : [],
                actual      : [],
                scale       : null,     

                weekCount   : [],
                weekSize    : null,    // Auto
                length      : null,    // Auto
                height      : null,    // Auto
                font        : 'roboto', 
                baseX       : 250,
                baseY       : 801,

                style: {
                        week: {
                                display   : 'Y',
                                height    : 15,
                                line      : 12,
                                sWidth    : 0.5,
                                sColor    : '#BCBCBC',
                                tSize     : 9,
                                tColor    : '#757575',
                        },
                        month: {
                                display   : 'Y',
                                height    : 14,
                                line      : 15,
                                sWidth    : 0.5,
                                sColor    : '#BCBCBC',
                                tSize     : 10,
                                tColor    : '#333',
                        },
                        year: {
                                display   : 'Y',
                                height    : 10,
                                line      : 20,
                                sWidth    : 0.5,
                                sColor    : '#BCBCBC',
                                tSize     : 11,
                                tColor    : '#000',
                        },
                },

    },
    style:[ 
      {
        group: 'Dress up required',
        refColumn   : 'DRESSUP_REQ',
        position: { x: 0, y: 190, rect: 'Y' },
        item: [
          {
            name        : 'Y',
            refColumn   : 'DRESSUP_REQ',
            codeValue   : 'Y',
            dataColumn  : '',
            tStyle      : 'regular',
            tSize       : 9,
            tColor      : '#D50000',
            sWidth      : 0.5,
            sColor      : '#bcbcbc',
            bColor      : '#fff',
            opacity     : 1,
            radius      : 2,
            space       : 60,
            on          : false,
          },
          {
            name        : 'N',
            refColumn   : 'DRESSUP_REQ',
            codeValue   : 'N',
            dataColumn  : '',
            tStyle      : 'regular',
            tSize       : 9,
            tColor      : '#F57F17',
            sWidth      : 0.5,
            sColor      : '#bcbcbc',
            bColor      : '#fff',
            opacity     : 1,
            radius      : 2,
            space       : 50,
            on          : false,
          }
        ],
      },
      {
        group: 'Main Crane(ton)',
        refColumn   : 'CRANE_MAIN_FILTER',
        position: { x: 0, y: 130, rect: 'Y' },
        item: [
          {
            name        : '4,000',
            refColumn   : 'CRANE_MAIN_FILTER',
            codeValue   : '4,000',
            dataColumn  : 'DRESSUP_PROG',
            tStyle      : 'regular',
            tSize       : 9,
            tColor      : '#000',
            sWidth      : 1,
            sColor      : '#D50000',
            bColor      : '#fff',
            opacity     : 1,
            radius      : 2,
            space       : 75,
            eventWidth  : 70,
            on          : false,
          },
          {
            name        : '1,600',
            refColumn   : 'CRANE_MAIN_FILTER',
            codeValue   : '1,600',
            dataColumn  : 'DRESSUP_PROG',
            tStyle      : 'regular',
            tSize       : 9,
            tColor      : '#000',
            sWidth      : 1,
            sColor      : '#66CCFF',
            bColor      : '#fff',
            opacity     : 1,
            radius      : 2,
            space       : 75,
            eventWidth  : 70,
            on          : false,
          },
          {
            name        : '1,250',
            refColumn   : 'CRANE_MAIN_FILTER',
            codeValue   : '1,250',
            dataColumn  : 'DRESSUP_PROG',
            tStyle      : 'regular',
            tSize       : 9,
            tColor      : '#000',
            sWidth      : 1,
            sColor      : '#00C853',
            bColor      : '#fff',
            opacity     : 1,
            radius      : 2,
            space       : 75,
            eventWidth  : 70,
            on          : false,
          },
          {
            name        : '800',
            refColumn   : 'CRANE_MAIN_FILTER',
            codeValue   : '800',
            dataColumn  : 'DRESSUP_PROG',
            tStyle      : 'regular',
            tSize       : 9,
            tColor      : '#000',
            sWidth      : 1,
            sColor      : '#E65100',
            bColor      : '#fff',
            opacity     : 1,
            radius      : 2,
            space       : 75,
            eventWidth  : 70,
            on          : false,
          },
          {
            name        : '750',
            refColumn   : 'CRANE_MAIN_FILTER',
            codeValue   : '750',
            dataColumn  : 'DRESSUP_PROG',
            tStyle      : 'regular',
            tSize       : 9,
            tColor      : '#000',
            sWidth      : 1,
            sColor      : '#1A237E',
            bColor      : '#fff',
            opacity     : 1,
            radius      : 2,
            space       : 75,
            eventWidth  : 70,
            on          : false,
          },
          {
            name        : '400',
            refColumn   : 'CRANE_MAIN_FILTER',
            codeValue   : '400',
            dataColumn  : 'DRESSUP_PROG',
            tStyle      : 'regular',
            tSize       : 9,
            tColor      : '#000',
            sWidth      : 1,
            sColor      : '#AD1457',
            bColor      : '#fff',
            opacity     : 1,
            radius      : 2,
            space       : 75,
            eventWidth  : 70,
            on          : false,
          },
          {
            name        : '280',
            refColumn   : 'CRANE_MAIN_FILTER',
            codeValue   : '280',
            dataColumn  : 'DRESSUP_PROG',
            tStyle      : 'regular',
            tSize       : 9,
            tColor      : '#000',
            sWidth      : 1,
            sColor      : '#FBC02D',
            bColor      : '#fff',
            opacity     : 1,
            radius      : 2,
            space       : 75,
            eventWidth  : 70,
            on          : false,
          },
          {
            name        : 'Less then 280',
            refColumn   : 'CRANE_MAIN_FILTER',
            codeValue   : 'Less then 280',
            dataColumn  : 'DRESSUP_PROG',
            tStyle      : 'regular',
            tSize       : 9,
            tColor      : '#000',
            sWidth      : 1,
            sColor      : '#000',
            bColor      : '#fff',
            opacity     : 1,
            radius      : 2,
            space       : 75,
            eventWidth  : 100,
            on          : false,
          },
        ]
      },
              
    ],
    styleFilter: {
      over: {
        stroke: '#ffb300',
        fill: '#ffd54f',
        color: '#fff'
      },
      out: {
        stroke: '#bcbcbc',
        fill: '#fff',
        color: '#c9c9c9'
      },
      selected: {
        stroke: '#1565c0',
        fill: '#64b5f6',
        color: '#757575'
      }
    },
    table: {
      values1: [
        { text: 'Delively at site forecast'  , colName: 'DELIVERY_FORECAST_WK'      },
        { text: 'Delively at site actual'    , colName: 'DELIVERY_ACTUAL_WK'      },
        { text: 'Lifting forecast'           , colName: 'LIFTING_FORECAST_WK'      },
        { text: 'Lifting actual'             , colName: 'LIFTING_ACTUAL_WK' },
      ],
      values2: [
        { text: 'Delively at site forecast'  , colName: 'DELIVERY_FORECAST_CUM'     },
        { text: 'Delively at site actual'    , colName: 'DELIVERY_ACTUAL_CUM'     },
        { text: 'Lifting forecast'           , colName: 'LIFTING_FORECAST_CUM'     },
        { text: 'Lifting Actual '            , colName: 'LIFTING_ACTUAL_CUM'     }
      ]
    }
  }),
  methods: {
    setStyles() {
      let lightBlue = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `shadowTimeline`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      lightBlue.append('stop').attr('stop-color', '#3d3d3d').attr('offset', '0').attr('stop-opacity', 0.5)
      lightBlue.append('stop').attr('stop-color', '#fff').attr('offset', '1').attr('stop-opacity', 0.3)

      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['LightGray'], [`LightGray`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['YellowGreen'], [`YellowGreen`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['LightBlue'], [`LightBlue`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['Pink'], [`Pink`, 0.5, 1.0, 0.5, -0.2], this.localId)
    },
  }
}