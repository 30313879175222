// -------------------------------------------------------------------------------------- Global
import { SafeIdMixin } from '../../../../../../includes/mixins/safeid.mixin'                // ATAGlance Framework Globals
import gmx_Controller from '../../../../../../includes/mixins/Controller.mixin'       // SVG Control
// Global Drawings
import gmx_Canvas from '../../../../../../includes/mixins/Draw_Canvas.mixin'
// Global Functions
import gmx_Miscellaneous from '../../../../../../includes/mixins/Miscellaneous.mixins'
import gmx_Movable from '../../../../../../includes/mixins/Movable.mixin'

// -------------------------------------------------------------------------------------- Local
// Locally declared common Data-Props & SVG Drawing Support
import lmx_ReactiveEvents from './Realtime_Monitor.mixin' // Reactive Events
import lmx_Declares from './Declares.mixin'
import lmx_Charts from './Draw_Charts.mixin'
import lmx_Sailaway from './Draw_Sailaway.mixin'
import lmx_Values from './Draw_Values.mixin'
import lmx_ControlTarget from './ControlTarget.mixin'
import lmx_ChartsOverall from './Draw_ChartsOverall.mixin'
import lmx_ChartsActions from './ChartsActions.mixin'
export default {
  mixins: [
    SafeIdMixin,
    gmx_Controller,
    gmx_Canvas,
    gmx_Miscellaneous,
    gmx_Movable,

    lmx_ReactiveEvents,
    lmx_Declares,
    lmx_Charts,
    lmx_Sailaway,
    lmx_Values,
    lmx_ControlTarget,
    lmx_ChartsOverall,
    lmx_ChartsActions,
  ],
}
