import * as d3 from 'd3'

export default {
  methods: {

      setEvent_Hull_LQ() {

      // Goto_LQ
      // ---------------------------------------------------------------------------------------------------------------------
      this.Goto_LQ_Level = (selection, _mask, _level) => {
        selection
        .on('mouseover', () => {
          if(this.stage != this.STG_Activate) return
          d3.select(_mask).transition().style('opacity', 0.5)
        })
        .on('mouseout', () => {
          if(this.stage != this.STG_Activate) return
          d3.select(_mask).transition().style('opacity', 0)
        })
        .on('click', () => {
          if(this.stage != this.STG_Activate) return
          // Remove SVG
          this.removeSvg(this.CANVAS, 1000)
    
          // Hidden
          this.hiddenGroupID('#Menu_Back', 0, 10)
          this.hiddenGroupID('#Mask_LQ01', 0, 10)
          this.hiddenGroupID('#Mask_LQ02', 0, 10)
          this.hiddenGroupID('#Mask_LQ03', 0, 10)
          this.hiddenGroupID('#Mask_LQ04', 0, 10)
          this.hiddenGroupID('#Mask_LQ05', 0, 10)
          this.hiddenGroupID('#Mask_LQ06', 0, 10)
          this.hiddenGroupID('#Mask_LQ07', 0, 10)
    
          // Explode
          setTimeout(() => {
            this.Hull_LQ.forEach(d => {
              // f(d.BLK == i_level)
              // Jiyoun 2020-08-04
              // 단순비교로는 소속된 하위 이미지를 찾을 수 없으므로, include를 사용하여
              // 관련 이미지 모두 처리할 수 있도록 조건절 수정
              if(d.BLK.includes(_level)) {
                // 따라서, _level 대신 d.BLK을 id검색으로 사용
                d3.select(`#Blk_${d.BLK}`).transition().duration(1000).attr('transform', `translate(${d.x5}, ${d.y5}) scale(${1})`)
              } else {
                this.hiddenGroupID(`#Blk_${d.BLK}`, 0, 500)
              }
            })
          }, 700);
  
          setTimeout(() => {
            this.loadSvg(0, 0, `/TORTUE/Equipment/SVG/${_level}.svg`).then((innerSvg) => {
              this.Draw_EQ_Code(innerSvg, _level)
              this.Draw_EQ_Tag(innerSvg, _level)
              this.Draw_EQ_Block(innerSvg, _level)
              this.innerSvg = innerSvg
            })
            this.Lev1 = 'HULL'
            this.Lev2 = 'LQ'
            this.Lev4 = _level
            this.stage = this.STG_Division_Lv3
            let dStage = {
              stage: this.STG_Division_Lv3,
              mask : this.Lev2,
              level: _level
            }
            this.selectedItems.push(dStage)
            this.fncName = 'Goto_LQ_Level'
            this.StageCode() // AREA / STAGE
            this.displayGroupID(`#Menu_Back`, 0, 200, 1)
            // ### Call Summary ###
            this.drawProgress(_level, false)

            // Internal-Filter-Value for the common control
            this.inFilters.LV1 = this.Lev1
            this.inFilters.LV4 = _level
          }, 1600);
        })
      }
    },
  }
}