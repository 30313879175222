import * as d3 from 'd3'
import { thresholdFreedmanDiaconis } from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  data:() => ({
    props:[
      { item: 'P1A',  itemColor:'#01579B' },
      { item: 'P1B',  itemColor:'#1976D2' },
      { item: 'P1',   itemColor:'#03A9F4' },
      { item: 'P2',   itemColor:'#4FC3F7' },
      { item: 'P3',   itemColor:'#81D4FA' },
    ]
  }),
  methods: {  
    Draw_Charts() {
      let data = null, 
          style = null

      let overallPunchStatus = this.svg
      .append('g').attr('class', 'overall')
      .attr('transform', `translate(25.5, 0)`)

      // -----------------------------------------------legend
      let priortyLegend = overallPunchStatus
      .append('g').attr('class', 'legend')
      .attr('transform', `translate(680, 50)`)

      this.props.forEach((d, i) =>{
        priortyLegend
        .append('rect')
        .attr('transform', `translate(${50*i}, 2)`)
        .attr('width', 15).attr('height', 10).attr('fill', d.itemColor)

        priortyLegend
        .append('text')
        .attr('transform', `translate(${50*i+18}, 8)`)
        .style('font-family', 'Roboto-Regular, Roboto').style('font-size', 9)
        .style('fill', '#616161').attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text(d.item)//priorty
      })
      // -----------------------------------------------table priorty text and table Line 
      let prorityItem = overallPunchStatus
      .append('g').attr('class', 'prorityItem')
      .attr('transform', `translate(10.5, 270.5)`)

      this.props.forEach((d, i) =>{
        prorityItem
        .append('rect')
        .attr('transform', `translate(-20, ${20*i-6})`)
        .attr('width', 10).attr('height', 10).attr('fill', d.itemColor)

        prorityItem
        .append('text')
        .attr('transform', `translate(-4, ${20*i})`)
        .style('font-family', 'Roboto-Regular, Roboto').style('font-size', 11)
        .style('fill', '#01579B').attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text(d.item)//priorty

        prorityItem
        .append('line').attr('x1', -24).attr('x2', 925).attr('y1', 20*i+8).attr('y2', 20*i+8)
        .attr('stroke', '#9E9E9E').attr('stroke-width', 0.2)
      })
      prorityItem
      .append('line').attr('x1', 20.5).attr('x2', 20.5).attr('y1', -10).attr('y2', 88)
      .attr('stroke', '#9E9E9E').attr('stroke-width', 0.2)

      prorityItem
        .append('line').attr('x1', -24).attr('x2', 925).attr('y1', -11).attr('y2', -11)
        .attr('stroke', '#9E9E9E').attr('stroke-width', 0.2)
      

      // -----------------------------------------------gridlines
      let maxValue = Math.max(...this.DataItems.map(d => d.A_OV_COM_WK))
      maxValue = this.getMaxBound(maxValue)
      // console.log(maxValue)
      //Scale for the radius
      let barScale = d3.scaleLinear()
      .domain([0, maxValue])
      .range([150, 0])

      let overallPunch = overallPunchStatus
      .append('g').attr('class', 'gridlines')
      .attr('transform', `translate(${-15.5}, ${80.5})`)
      
      for(var i=0; i<6; i++) {
        overallPunch
        .append('line')
        .attr('x1', 50 - 5).attr('x2', 950)
        .attr('y1', barScale((maxValue/5)*i)).attr('y2', barScale((maxValue/5)*i))
        .attr('stroke', '#E0E0E0')
        .attr('stroke-width', i == 0 ? 0.8 :  0.2)

        overallPunch
        .append('text')
        .attr('transform', `translate(${50 - 7}, ${barScale((maxValue/5)*i)})`)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#bcbcbc').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text((maxValue/5)*i)
      }

      // -----------------------------------------------chart
      let weekD = [...new Set(this.DataItems.map(d => d.WK_NO))]
      let Cdate = [...new Set(this.DataItems.map(d => d.CDATE))]
      let chartX = 0
      weekD.forEach((p, i) => {
        console.log(i)
        data = this.DataItems.filter(f => f.WK_NO == p)
        console.log(data)
        style = {
                  week:p,
                  max:maxValue,
                  x: 10,
                  y: 80,
                  stX: 50,
                  barLen: 150,
                  width: 18,
                  space:5,
                  pColor:   ['#01579B', '#1976D2','#03A9F4','#4FC3F7','#81D4FA','#B3E5FC'],
                  bColor:   ['#F7BACF', '#FCE3EB'],
                  sColor:   ['#EC407A', '#F7BACF'],
                  tColor:   ['#fff','#757575'], 
                  opacity:  1,
        }
        let group = overallPunchStatus.append('g').attr('class', `chart_group_${p}_`).attr('transform', `translate(${chartX}, 80)`)

        this.Bar_Punch(group, data, style) // ---------------------------> 
        chartX += 150

        // ---------------------------------------------table
        overallPunchStatus
        .append('text').attr('transform', `translate(${150*i+72}, 244)`)
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#01579B').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text(p)//weekNo
        
      })
      Cdate.forEach((c, i) => {
        overallPunchStatus
        .append('text').attr('transform', `translate(${150*i+132}, 244)`)
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#01579B').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text(`(${c})`)//cDate
      })
      
    },
    Bar_Punch(selection, _data, _style) {
      
      let overallPunch = selection
      let barScale = d3.scaleLinear()
      .domain([0, _style.max])
      .range([_style.barLen, 0])
      
      let x = _style.stX
      _data.forEach((d,i) => {
        overallPunch
        .append('path')
        .attr('d', ` M ${x}, ${_style.barLen}
                    V ${barScale(d.A_OV_COM_WK)}
                    H ${x + _style.width}
                    V ${_style.barLen} Z
                  `
        ).style('fill', _style.pColor[i]).attr('stroke-width', 0.5)
        // .attr('stroke', _style.pColor[1])

        // overallPunch
        // .append('text')
        // .attr('transform', `translate(${x + _style.width-10}, ${_style.barLen + 12})`)
        // .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        // .text(`${d.PRIORITY}`)

        overallPunch
        .append('text')
        .attr('transform', `translate(${x + (_style.width/2)}, ${barScale(d.A_OV_COM_WK) - 6})`)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text(`${d.A_OV_COM_WK}`)

        x += _style.width + _style.space

        overallPunch
        .append('text')
        .attr('transform', `translate(${100}, ${20*i+190})`)
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#616161').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text(`${d.A_OV_COM_WK}`)
      })
    },
  }
}
