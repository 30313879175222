/**
 * Event Call Functions
 */

import * as d3 from 'd3'

export default {
  methods: {

    call_timeSchedule(selection, d) {
      selection
      .on('mouseover', () => {
        this.stage_Header.select(`#STEP-${d.no}`)
        .transition().duration(150)
        .style('opacity', .5)

        this.stage_Header.select(`#TEXT-${d.no}`)
        .transition().duration(150)
        .style('fill', 'red').style('font-weight', 'bold')
      })

      .on('mouseout', () => {
        this.stage_Header.select(`#STEP-${d.no}`)
        .transition().duration(150)
        .style('opacity', 1)

        this.stage_Header.select(`#TEXT-${d.no}`)
        .transition().duration(150)
        .style('fill', () => {
          if (this.CurrentStep == d.no) return 'red'; else; return '#757575'
        })
        .style('font-weight', () => {
          if (this.CurrentStep == d.no) return 'bold'; else; return 'normal'
        })
      })
    },



    call_CommListBox(selection, d, i) {
      selection
      .on('mouseover', () => {
        this.stage_Header.select(`#LIST_${i}`)
        .transition().duration(150)
        .style('opacity', .3)
      })

      .on('mouseout', () => {
        this.stage_Header.select(`#LIST_${i}`)
        .transition().duration(150)
        .style('opacity', 1)
      })
    },
  }
}