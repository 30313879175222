import * as d3 from 'd3'

export default {

  methods: {
    /*
      load_Lev2()
      setCoordinate_Lev2()
      unload_Lev2()
    */
    load_Lev2() {
      this.stage_Header.select(`#HOME`).attr('visibility', 'visible')
      this.stage_Header.select(`#BACK`).attr('visibility', 'visible')

      this.Areas.forEach(d=> {
        if(d.package == this.route.to.package) {
          d3.select(`#PLAN-${d.code}`)
          .transition().duration(300)
          .style('opacity', .7)
        }
        else {
          d3.select(`#PLAN-${d.code}`)
          .transition().duration(300)
          .style('opacity', 0)
        }
      })

      d3.select('#HeaderTitle_Area')
      .transition().duration(500)
      .attr('fill', '#F35E90')
      .text(`Main Plant - Package (${this.route.to.package})`)

      // Calculate the scale value and coordinates in 'set_LocalValues.js'
      let pkg = this.Package.find(f => f.code == this.route.to.package)
      this.setZoomScale(this.zoom.width, this.zoom.height, pkg.scale_in, pkg.scale_out)

      this.innerSvg = this.svg.append('svg')
      this.setPriority()
      
      this.innerSvg
      .append('image')
      .attr('id','lev2_background')
      .attr('transform', `translate(${this.zoom.in.x + 100}, ${this.zoom.in.y}) scale(${this.zoom.in.scale})`)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/China1/Construction_Photo/images/${this.route.to.package}.png`)
      .style('opacity', 0)
      .style('stroke','red').style('stroke-width',2)

      d3.select('#lev2_background')
      .transition().duration(500)
      .attr('transform', `translate(${ this.zoom.out.x + 100}, ${ this.zoom.out.y}) scale(${this.zoom.out.scale})`)
      .style('opacity', 1)

      // After loading the SVG, perform initialization
      this.loadSvg(
        this.innerSvg, {
        x   : 0,
        y   : 0,
        url : `/China1/Construction_Photo/svg/${this.route.to.package}.svg`
      }).then(() => {
        this.Areas.filter(f=> f.package == this.route.to.package).forEach(d => {
          let xy_ = d3.select(`#XY-${d.code}`).attr('visibility', 'hidden')
          d.x = xy_.attr('cx')
          d.y = xy_.attr('cy')

          this.innerSvg.select(`#BTN-${d.code}`).style('opacity', 0)
          this.innerSvg.select(`#AREA-${d.code}`).attr('transform', `translate(${d.x}, ${d.y})scale(0)`).style('opacity', 0)
          this.innerSvg.select(`#BOX-${d.code}`).style('opacity', 0)
        })
        this.setCoordinate_Lev2() // ---> this function below
        this.draw_AreaListMenu(this.route.to.package) // ---> in draw_Package.js
      })
    },
    
    setCoordinate_Lev2() {
      // Set mouse events on the area button
      this.Areas.filter(f=> f.package == this.route.to.package).forEach(d => {
        this.innerSvg
        .select(`#BTN-${d.code}`)
        .style('cursor', 'pointer')
        .on('click', () => {
          this.innerSvg.select(`#AREA-${d.code}`).attr('visibility', 'hidden')
          this.innerSvg.select(`#BOX-${d.code}`).attr('visibility', 'hidden')

          this.routeTo({
            path  : 'Lev2', package: d.package
          }, {
            path: 'Lev3',  package: d.package, area: d.code
          })
        })
        .call(this.call_Coordinate, d) // ---> in Event.js
      })
    },

    unload_Lev2() {
      this.innerSvg.select('#lev2_background')
      .transition().duration(500)
      .attr('transform', `translate(${this.zoom.in.x}, ${this.zoom.in.y}) scale(${this.zoom.in.scale})`)
      .style('opacity', 0)

      return new Promise(resolve => {
        setTimeout(() => {
          this.innerSvg.remove()
          resolve() 
        }, 350)
      })
    },
    
  }
}