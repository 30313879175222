import * as d3 from 'd3'
import Data from '../primitives/donutProps'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {

    Draw_Charts() {
      let arcProps = {
        angleSize: 360,
        angleStart: 0,
        bColor: '#F4F5F5',
        stroke: 8,
        Ra: 30
      }

      let values_ = this.dataSet[0]

      let donutGroup = this.svg
      .append('g')
      .attr('class', '_donut_group__for_all')
      .attr('transform', `translate(30, 70)`)



      // Data.attrs.forEach((attr, i) => {
      //   let arc = d3.arc()
      //   .outerRadius(d => d.Ra)
      //   .innerRadius(d => d.Ra - d.stroke)
      //   .startAngle(d => d.angleStart * Math.PI / 180)
      //   .endAngle(d => d.angleEnd || d.angleStart * Math.PI / 180)


      //   let x__ = Data.width * i
      //   let y__ = 0
      //   let donut_ = donutGroup 
      //   .append('g')
      //   .attr('class', `_donut_chart__${attr.refColumn.toLowerCase()}`)
      //   .attr('transform', `translate(${x__}, ${y__})`)

      //   // Draw Background Donut Chart
      //   donut_
      //   .append('circle')
      //   .attr('cx', 0).attr('cy', 0).attr('r', arcProps.Ra-3)
      //   .attr('stroke', arcProps.bColor).attr('stroke-width', arcProps.stroke)
      //   .attr('fill', attr.fill)

      //   let circleRate = values_[attr.refColumn] / 100

      //   // Draw Foreground Donut Chart
      //   donut_
      //   .datum(arcProps)
      //   .append('path')
      //   .attr('d', arc)
      //   .attr('fill', attr.fColor)
      //   .attr('opacity', 0.8)
      //   .transition()
      //   .duration(1500)
      //   .attrTween('d',(d) => {
      //     let endAngle_ = ((d.angleSize * circleRate) + d.angleStart) * Math.PI / 180
      //     let interpolate = d3.interpolate(d.angleStart * Math.PI / 180, endAngle_)
      //     return function(t) {
      //       d.angleEnd = interpolate(t)
      //       return arc(d);
      //     }
      //   })
        

      //   donut_
      //   .append('text')
      //   .attr('transform', `translate(0, 1)`)
      //   .style('font-family', 'roboto')
      //   .style('font-size', 13)
      //   .style('fill', attr.tColor)
      //   .attr('text-anchor', 'middle')
      //   .attr('alignment-baseline', 'middle')
      //   .text(values_[attr.refColumn] ? values_[attr.refColumn] : 0)

      //   // Legend
      //   donut_
      //   .append('text')
      //   .attr('transform', `translate(0, -45)`)
      //   .style('font-family', 'roboto')
      //   .style('font-size', 13)
      //   .style('fill', '#757575')
      //   .attr('text-anchor', 'middle')
      //   .attr('alignment-baseline', 'middle')
      //   .text(attr.text1)

      //   donut_
      //   .append('text')
      //   .attr('transform', `translate(0, 52)`)
      //   .style('font-family', 'roboto')
      //   .style('font-size', 10)
      //   .style('fill', '#757575')
      //   .attr('text-anchor', 'middle')
      //   .attr('alignment-baseline', 'middle')
      //   .text(attr.text2)


        
      // })

      Data.attrs.forEach((attr, i) => {
        let arc = d3.arc()
        .outerRadius(d => d.Ra)
        .innerRadius(d => d.Ra - d.stroke)
        .startAngle(d => d.angleStart * Math.PI / 180)
        .endAngle(d => d.angleEnd || d.angleStart * Math.PI / 180)


        let x__ = attr.refColumn.includes('PARTIALLY') ? attr.refColumn.includes('COMPLETED') ? 610 : 710 : Data.width * i + (i > 0 ? Data.gab : 0)
        let y__ = attr.refColumn.includes('PARTIALLY') ? 115 : 0
        let donut_ = donutGroup 
        .append('g')
        .attr('class', `_donut_chart__${attr.refColumn.toLowerCase()}`)
        .attr('transform', `translate(${x__}, ${y__})`)

        // Draw Background Donut Chart
        donut_
        .append('circle')
        .attr('cx', 0).attr('cy', 0).attr('r', arcProps.Ra-3)
        .attr('stroke', arcProps.bColor).attr('stroke-width', arcProps.stroke)
        .attr('fill', `url(#${attr.fill})`)

        let circleRate = values_[attr.refColumn] / values_['TOTAL']

        // Draw Foreground Donut Chart
        donut_
        .datum(arcProps)
        .append('path')
        .attr('d', arc)
        .attr('fill', attr.fColor)
        .attr('opacity', 0.8)
        .transition()
        .duration(1500)
        .attrTween('d',(d) => {
          let endAngle_ = ((d.angleSize * circleRate) + d.angleStart) * Math.PI / 180
          let interpolate = d3.interpolate(d.angleStart * Math.PI / 180, endAngle_)
          return function(t) {
            d.angleEnd = interpolate(t)
            return arc(d);
          }
        })
        

        donut_
        .append('text')
        .attr('transform', `translate(0, 1)`)
        .style('font-family', 'roboto')
        .style('font-size', 13)
        .style('fill', attr.tColor)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(values_[attr.refColumn] ? values_[attr.refColumn] : 0)

        // Legend
        donut_
        .append('text')
        .attr('transform', `translate(0, 42)`)
        .style('font-family', 'roboto')
        .style('font-size', 10)
        .style('fill', '#757575')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(attr.text)

        donut_
        .append('text')
        .attr('transform', `translate(0, 52)`)
        .style('font-family', 'roboto')
        .style('font-size', 10)
        .style('fill', '#757575')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(attr.text2)
        


      })


      
      let lightGray = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `lightGray`)
      .attr('x1', 0.772).attr('x2', 0.228).attr('y1', 0.228).attr('y2', 0.919)
      lightGray.append('stop').attr('stop-color', '#fff').attr('offset', '0').attr('stop-opacity', 1)
      lightGray.append('stop').attr('stop-color', '#d0d2d3').attr('offset', '1').attr('stop-opacity', 1)

      
      let fillWhite = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `fillWhite`)
      .attr('x1', 0.772).attr('x2', 0.228).attr('y1', 0.228).attr('y2', 0.919)
      fillWhite.append('stop').attr('stop-color', '#fff').attr('offset', '1').attr('stop-opacity', 1)
      fillWhite.append('stop').attr('stop-color', '#fff').attr('offset', '1').attr('stop-opacity', 1)

      
      let fillYellow = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `fillYellow`)
      .attr('x1', 0.772).attr('x2', 0.228).attr('y1', 0.228).attr('y2', 0.919)
      fillYellow.append('stop').attr('stop-color', '#FFC400').attr('offset', '1').attr('stop-opacity', 1)
      fillYellow.append('stop').attr('stop-color', '#FFC400').attr('offset', '1').attr('stop-opacity', 1)

      
      let fillBlue = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `fillBlue`)
      .attr('x1', 0.772).attr('x2', 0.228).attr('y1', 0.228).attr('y2', 0.919)
      fillBlue.append('stop').attr('stop-color', '#00B0FF').attr('offset', '1').attr('stop-opacity', 1)
      fillBlue.append('stop').attr('stop-color', '#00B0FF').attr('offset', '1').attr('stop-opacity', 1)

      
      let fillLightGreen = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `fillLightGreen`)
      .attr('x1', 0.772).attr('x2', 0.228).attr('y1', 0.228).attr('y2', 0.919)
      fillLightGreen.append('stop').attr('stop-color', '#689F38').attr('offset', '1').attr('stop-opacity', 1)
      fillLightGreen.append('stop').attr('stop-color', '#689F38').attr('offset', '1').attr('stop-opacity', 1)

      
      let fillGreen = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `fillGreen`)
      .attr('x1', 0.772).attr('x2', 0.228).attr('y1', 0.228).attr('y2', 0.919)
      fillGreen.append('stop').attr('stop-color', '#2E7D32').attr('offset', '1').attr('stop-opacity', 1)
      fillGreen.append('stop').attr('stop-color', '#2E7D32').attr('offset', '1').attr('stop-opacity', 1)

    }
  }
}
