import * as d3 from 'd3'

export default {
  data: () => ({
    dataSet: [],
    defaultset: { 
      width: 1500,
      height: 30,
      color: '#0D47A1',
      linkIcon: 'N',
      path: '/#/service/procurement/3d_visualization/equipment_3d_layout/equipment_3d_layout',
      iconX: 915,
      iconY: 2.5,
      iconW: 20,
      iconH: 18,
      filer: 'Y',
      fKey: 'RFSU',
      fX: '10',
      fY: '0',
      fFontSize: 13,
      fColor: '#000',
      filters: {
        LV2: 'LQ',
        LV3: 'LQ00'
      }
    }
  }),
  computed: {
    ready2draw() {

      // console.log('------------------------------')
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)

      return (
        Object.keys(this.Canvas).length > 0
      )
    },
  }
}