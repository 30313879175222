import * as d3 from 'd3'
import __C from '../../../../../../includes/primitives/_constant_'
import __M from 'moment'

export default {
  data: () => ({
    scaleY: null,
    labelY: 45,
    labelYGap: 18,
  }),

  methods: {
    draw_Table() {
      this.putTableForm()
      this.putTableValues()
    }, 
    putTableForm() {
      d3.select(`#skyline_boxes_table`).remove()

      // let drawX = 0
      let drawY = 30

      let dataTable = this.SkylineCharts
      .append('g')
      .attr('class', 'table_form')
      .attr('transform', `translate(${this.timeline.baseX},${this.timeline.baseY + 60})`)
      .attr('id', `skyline_boxes_table`)

      // Vertical Line
      dataTable
      .append('line').attr('x1', -200).attr('x2', -200).attr('y1', 20).attr('y2', 305).attr('stroke-width', 0.5).attr('stroke', '#757575')
      dataTable
      .append('line').attr('x1', 0).attr('x2', 0).attr('y1', 20).attr('y2', 305).attr('stroke-width', 0.5).attr('stroke', '#757575')

      // Horizontal Line
      // drawY = 0
      // dataTable
      // .append('line').attr('x1', 0).attr('x2', this.timeline.length).attr('y1', drawY).attr('y2', drawY).attr('stroke-width', 0.5).attr('stroke', '#757575')
      drawY = 20

      dataTable
      .append('line').attr('x1', -200).attr('x2', this.timeline.length).attr('y1', drawY).attr('y2', drawY).attr('stroke-width', 0.5).attr('stroke', '#757575')
      drawY += 15

      drawY = 35
      dataTable
      .append('line').attr('x1', -200).attr('x2', this.timeline.length).attr('y1', drawY).attr('y2', drawY).attr('stroke-width', 0.5).attr('stroke', '#757575')
      drawY += 18

      dataTable
      .append('line').attr('x1', -200).attr('x2', this.timeline.length).attr('y1', drawY).attr('y2', drawY).attr('stroke-width', 0.5).attr('stroke', '#bcbcbc')
      drawY += 18

      dataTable
      .append('line').attr('x1', -200).attr('x2', this.timeline.length).attr('y1', drawY).attr('y2', drawY).attr('stroke-width', 0.5).attr('stroke', '#bcbcbc')
      drawY += 18

      dataTable
      .append('line').attr('x1', -200).attr('x2', this.timeline.length).attr('y1', drawY).attr('y2', drawY).attr('stroke-width', 0.5).attr('stroke', '#bcbcbc')
      drawY += 18

      dataTable
      .append('line').attr('x1', -200).attr('x2', this.timeline.length).attr('y1', drawY).attr('y2', drawY).attr('stroke-width', 0.5).attr('stroke', '#bcbcbc')
      drawY += 18

      dataTable
      .append('line').attr('x1', -200).attr('x2', this.timeline.length).attr('y1', drawY).attr('y2', drawY).attr('stroke-width', 0.5).attr('stroke', '#bcbcbc')
      drawY += 18

      dataTable
      .append('line').attr('x1', -200).attr('x2', this.timeline.length).attr('y1', drawY).attr('y2', drawY).attr('stroke-width', 0.8).attr('stroke', '#9E9E9E')
      drawY += 18

      dataTable
      .append('line').attr('x1', -200).attr('x2', this.timeline.length).attr('y1', drawY).attr('y2', drawY).attr('stroke-width', 0.5).attr('stroke', '#bcbcbc')
      drawY += 18

      dataTable
      .append('line').attr('x1', -200).attr('x2', this.timeline.length).attr('y1', drawY).attr('y2', drawY).attr('stroke-width', 0.5).attr('stroke', '#bcbcbc')
      drawY += 18

      dataTable
      .append('line').attr('x1', -200).attr('x2', this.timeline.length).attr('y1', drawY).attr('y2', drawY).attr('stroke-width', 0.5).attr('stroke', '#bcbcbc')
      drawY += 18

      dataTable
      .append('line').attr('x1', -200).attr('x2', this.timeline.length).attr('y1', drawY).attr('y2', drawY).attr('stroke-width', 0.8).attr('stroke', '#9E9E9E')
      drawY += 18

      dataTable
      .append('line').attr('x1', -200).attr('x2', this.timeline.length).attr('y1', drawY).attr('y2', drawY).attr('stroke-width', 0.5).attr('stroke', '#bcbcbc')
      drawY += 18

      dataTable
      .append('line').attr('x1', -200).attr('x2', this.timeline.length).attr('y1', drawY).attr('y2', drawY).attr('stroke-width', 0.5).attr('stroke', '#bcbcbc')
      drawY += 18

      dataTable
      .append('line').attr('x1', -200).attr('x2', this.timeline.length).attr('y1', drawY).attr('y2', drawY).attr('stroke-width', 0.5).attr('stroke', '#bcbcbc')
      drawY += 18

      dataTable
      .append('line').attr('x1', -200).attr('x2', this.timeline.length).attr('y1', drawY).attr('y2', drawY).attr('stroke-width', 0.5).attr('stroke', '#bcbcbc')
      drawY += 18

      dataTable
      .append('line').attr('x1', -200).attr('x2', this.timeline.length).attr('y1', drawY).attr('y2', drawY).attr('stroke-width', 0.5).attr('stroke', '#757575')

      dataTable
      .append('g')
      .attr('transform', `translate(30,0)`)
      .selectAll('text')
      .data(this.table.values)
      .enter()
      .append('text')
      .attr('x', -220)
      .attr('y', (_, i) => this.labelY + this.labelYGap * i)
      .style('font-family', this.timeline.font)
      .style('font-size', this.timeline.style.month.tSize)
      .attr('fill', d => d.tColor || this.timeline.style.month.tColor)
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .text(d => d.text)

      dataTable
      .append('g').attr('transform', `translate(0,0)`)
      .append('text')
      .attr('x', -130)
      .attr('y', 28)
      .style('font-family', this.timeline.font)
      .style('font-size', this.timeline.style.month.tSize)
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .text('Week No.')


      dataTable
      .append('g')
      .selectAll('path')
      .data(this.timeline.month)
      .enter()
      .append('path')
      .attr('d', (d, i) => `M${this.timeline.scale(d.eDate)}, 20 V 305`)
      .attr('stroke', (d,i) => {
        if (i == this.timeline.month.length-1) return '#757575'; else return '#bcbcbc'
      })
      .attr('stroke-width', 0.5)

      let singleWidth = this.timeline.length / this.timeline.week.length

      dataTable
      .append('rect')
      .attr('x', this.timeline.scale(new Date(this.cutoff)) - singleWidth + 7)
      .attr('y', 20).attr('width', this.timeline.length / this.timeline.week.length)
      .attr('height', 15)
      .attr('fill', '#90CAF9').attr('opacity', .5)

      let headerData = this.Queries.SQL4

      // Weeks Text & Timeline Seperator for the Week
      dataTable
      .append('g')
      .selectAll('text')
      .data(this.timeline.week)
      .enter()
      .append('text')
      .attr('x', d => this.timeline.scale(d.mDate))
      .attr('y', 23)
      .style('font-family', this.timeline.font)
      .style('font-size', this.timeline.style.week.tSize)
      .attr('fill', d => {
        if (this.cutoff == __M(d.eDate).format('YYYY-MM-DD')) return '#212121'
        else return '#bababa'
      })
      // .attr('fill', this.timeline.style.week.tColor)
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'hanging')
      .text(d => {
        let addWeeks = __M(d.eDate).add(-1, 'weeks')
        return `M${__M(addWeeks).year()}_${__M(addWeeks).week()}`
      })

      dataTable
      .append('g')
      .selectAll('path')
      .data(this.timeline.week)
      .enter()
      .append('path')
      .attr('d', (d, i) => `M${this.timeline.scale(d.eDate)}, 20 V 305`)
      .attr('stroke', this.timeline.style.week.sColor)
      .attr('stroke-width', 0.5)
    },
    putTableValues() {
      d3.select(`#${this.localId}`).select('.table_values').remove()

      if(!this.Queries.SQL3 || this.Queries.SQL3.length === 0) return

      let dataTable = this.SkylineCharts
      .append('g')
      .attr('class', 'table_values')
      .attr('transform', `translate(${this.timeline.baseX},${this.timeline.baseY + 60})`)

      let prevWeek_ = null
      // Group of all values
      dataTable
      .append('g')
      .selectAll('g')
      .data(this.timeline.week)
      .enter()
      .append('g')
      .attr('transform', (_, i) => `translate(${this.timeline.weekSize * i}, 45)`)
      .selectAll('text')
      .data(d => {
        let data_ = {...this.Queries.SQL3.find(d___ => __M(d.eDate).format('YYYY-MM-DD') == __M(d___[this.timelineKeyName]).day(4).format('YYYY-MM-DD'))}

        if(!data_) {
          if(!prevWeek_) return {}
          
          data_ = prevWeek_
          Object.keys(data_).forEach(k_ => { if(!['A_CUM_PG'].includes(k_)) data_[k_] = 0 })
        } else prevWeek_ = { ...data_ }

        // data_.A_CUM_PG = cumm_ ? cumm_.A_CUM_PG : data_.A_CUM_PG
        
        return this.table.values.map((v_, j) => ({
          date: __M(data_[this.activity]).format('YYYY-MM-DD'),
          y: this.labelYGap * j,
          tColor: v_.tColor || '#757575',
          value: v_.colName == 'A_CUM_PG' && __M(d.eDate).format('YYYY-MM-DD') > this.Queries.SQL2[0].CDATE ? 0 : data_[v_.colName]
        }))
      })
      .enter()
      .append('text')
      .attr('transform', d => `translate(${this.timeline.weekSize-2},${d.y})`)
      .attr('font-family', 'roboto')
      .attr('font-size', 9)
      .attr('fill', d => d.tColor)
      .attr('text-anchor', 'end')
      .attr('alignment-baseline', 'middle')
      .text(d => d.value ? Number(d.value).toLocaleString() : '')
      .on('mouseover', (d, j, a) => {
        if (d.date != __M(this.Queries.SQL2[0].CDATE).format('YYYY-MM-DD')) return
        let node = 2
        d3.select(a[j]).style('cursor', 'pointer')
        d3.select(a[j]).transition().duration(100)
        .attr('transform', `translate(${this.timeline.weekSize-2+node}, ${d.y}) scale(1.5)`).style('fill', '#EC407A')
      })
      .on('mouseout', (d, j, a) => {
        if (d.date != __M(this.Queries.SQL2[0].CDATE).format('YYYY-MM-DD')) return
        d3.select(a[j]).transition().duration(100)
        .attr('transform', `translate(${this.timeline.weekSize-2}, ${d.y}) scale(1)`).style('fill', d.tColor)
      })
      .on('click', (d, j) => {
        console.log(d, this.Queries.SQL2[0].CDATE)

        // let conditions = ` ${this.activity} = '${__M(d.date).format('YYYY-MM-DD')}' `
        let conditions = ''

        if (d.date != __M(this.Queries.SQL2[0].CDATE).format('YYYY-MM-DD')) return

        // j는 세로 Status
        console.log(j)
        if (j == 0) conditions += ` [CATEGORY] = 'A' `  // Total
        else if (j == 1) conditions += ` [CATEGORY] = 'A' AND [STATUS_CODE] = 'C' `   // Completed
        else if (j == 2) conditions += ` [CATEGORY] = 'A' AND [STATUS_CODE] = 'N' `   // Remain

        else if (j == 3) conditions += ` [CATEGORY] = 'A' AND [WD_PLAN] <= '${d.date}' `  // Total up to cutoff
        else if (j == 4) conditions += ` [CATEGORY] = 'A' AND [WD_PLAN] <= '${d.date}' AND [STATUS_CODE] = 'C'  `   // Complete up to cutoff
        else if (j == 5) conditions += ` [CATEGORY] = 'A' AND [WD_PLAN] <= '${d.date}' AND [STATUS_CODE] = 'N'  `   // Remain up to cutoff
        

        else if (j == 10) conditions += ` [CATEGORY] = 'A' AND [STATUS_CODE] = 'N' `   // Punch A
        else if (j == 11) conditions += ` [CATEGORY] = 'A' AND [WD_PLAN] <= '${d.date}' AND [STATUS_CODE] = 'N' `   // Punch B1
        else if (j == 12) conditions += ` [CATEGORY] = 'B1' AND [STATUS_CODE] = 'N' `   // Punch B2
        else if (j == 13) conditions += ` [CATEGORY] = 'B2' AND [STATUS_CODE] = 'N' `   // Punch B3
        else if (j == 14) conditions += ` [CATEGORY] = 'B3' AND [STATUS_CODE] = 'N'  `   // Punch B3
        else return



        let request_ = {
          dataType: 'row',
          action: {
            type: 'direct',
            target: 'slide-modal',
            component: 'ServiceDatatable',
            id: j == 10 || j == 11 || j == 12 || j == 13 || j == 14 ? 254 : 255, // PUNCH : QCF
            no: ''
          },
          filters     : {},
          iFilters    : {
            filterString : conditions,
            inputFilter  : ''
          }
        }
        this.$emit('request-action', request_)
      })
    }
  }
}
