
import * as d3 from 'd3'
import Data from '../primitives/donutProps'

export default {
  methods: {
    drawInformation() {
      this.chartArea
      .append('g')
      .attr('class', 'overall_info')
      .attr('transform', `translate(15, 15)`)
    },
    loadInformation(sender='in-yard') {
      let infoPannel = d3.select('.overall_info')
      
      infoPannel.selectAll('*').remove()
      infoPannel.transition().delay(150).duration(250).style('opacity', 1)

      if(this.stage == 'OverAll') this.drawOverallInfo()
      else this.drawOtherInfo(sender)
    },
    closeInformation() {
      d3.select('.overall_info')
      .transition().duration(150)
      .style('opacity', 0)
    },
    // ###---------------------------------------- Yard Layout Overall Infomation
    drawOverallInfo() {
      let yardLayout = d3.select('.overall_info')
      let box = yardLayout
      .append('g')
      .attr('class', 'info_content_main_overall')

      box
      .append('image')
      .attr('class', 'overall_prog')
      .attr('transform', `translate(0, 0)`)
      .attr('xlink:href', require('../../../../../../src/assets/svg/prog.svg'))

      box
      .append('rect')
      .attr('class', 'issue_notice')
      .attr('width', 188)
      .attr('height', 110)
      .attr('fill', '#fff')
      .attr('rx', '5')
      .attr('transform', `translate(18, 178)`)
      .attr('filter', 'url(#dropshadow)')
      box
      .append('text')
      .attr('transform', `translate(24, 190)`)
      .attr('fill', '#EC407A')
      .attr('text-anchor', 'start')
      .style('font-size', 11)
      .style('font-family', 'roboto')
      .attr('alignment-baseline', 'middle')
      .text('Issues in last week')

      this.drawOverallSummary()
      this.drawOverallIssues()
    },
    drawOverallSummary() {
      let overallSummary = d3.select('.info_content_main_overall')
      .append('g')
      .attr('transform', `translate(0, 96)`)
      
      // Overall Plan
      let tmp = this.Queries.SQL5[0]
      let style = {
        id: 'CERT',
        x: 55,
        y: 0,
        ty: 4,
        name: tmp.PLAN,
        sort: 'N',
      }
      let data = [
        {title: 'Completed',  value: tmp.PLAN },
        {title: 'Remain',     value: 100-tmp.PLAN }
      ]
      let Circle = {
        CircleDiameter: 28,
        CircleColorSet: ['Gray', 'LightGray'],
        CircleHoleSize: 19,
        CircleStackSize: 2,
        CircleValueSize: 10,
        CircleSliceSize: 10,
        CircleTitleSize: 11,
        CircleLegendSpace: 50,
      }
      let Guideline = {
        GuideDisplay: 'N',
      }
      overallSummary
      .append('text')
      .attr('transform', `translate(${style.x}, ${style.y - Circle.CircleDiameter - 5})`)
      .style('font-size', 10).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'middle')
      .text('Plan (%)')

      this.Chart_PieSimple(overallSummary, data, style, Circle, Guideline)

      style = {
        id: 'SHI',
        x: 170,
        y: 0,
        ty: 4,
        name: tmp.ACTUAL,
        sort: 'N',
      }
      data = [
        {title: 'Completed',  value: tmp.ACTUAL },
        {title: 'Remain',     value: 100-tmp.ACTUAL }
      ]
      Circle.CircleColorSet = ['LightBlue', 'LightGray']
      
      overallSummary
      .append('text')
      .attr('transform', `translate(${style.x}, ${style.y - Circle.CircleDiameter - 5})`)
      .style('font-size', 10).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'middle')
      .text('Actual (%)')

      this.Chart_PieSimple(overallSummary, data, style, Circle, Guideline)

      // Delta
      let box = overallSummary
      .append('g')
      .attr('id', 'delta')
      .attr('transform', `translate(74, -16)`)

      box
      .append('text')
      .attr('transform', `translate(40, 12)`)
      .style('font-size', 9)
      .style('font-family', 'roboto')
      .attr('fill', '#959595')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text('Delta (%)')

      box
      .append('text')
      .attr('transform', `translate(40, 26)`)
      .style('font-size', 11)
      .style('font-family', 'roboto')
      .attr('fill', tmp.DELTA >= 0 ? '#1e88e5' : '#bf360c')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text(`${tmp.DELTA > 0 ? '▲ ' : tmp.DELTA === 0 ? '': '▼ '}` + tmp.DELTA)
      
      //Week
      let week = overallSummary
      .append('g')
      .attr('id', 'week')
      .attr('transform', `translate(26, 40)`)
      
      week
      .append('rect')
      .attr('rx', 4)
      .attr('ry', 4)
      .attr('width', 170)
      .attr('height', 24)
      .attr('fill-opacity', 0)
      .attr('stroke', '#dadada')
      .attr('stroke-width', .5)

      week
      .append('rect')
      .attr('transform', `translate(126, -5)`)
      .attr('width', 40)
      .attr('height', 10)
      .attr('fill', '#fff')

      week
      .append('text')
      .attr('transform', `translate(146, 0)`)
      .style('font-size', 8)
      .style('font-family', 'roboto')
      .attr('fill', '#959595')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text('Weekly')

      week
      .append('text')
      .attr('transform', `translate(26, 13)`)
      .style('font-size', 11)
      .style('font-family', 'roboto')
      .attr('fill', '#333')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text(tmp.PLAN_WK)

      week
      .append('text')
      .attr('transform', `translate(88, 13)`)
      .style('font-size', 11)
      .style('font-family', 'roboto')
      .attr('fill', tmp.DELTA_WK >= 0 ? '#1e88e5' : '#bf360c')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text(`${tmp.DELTA_WK > 0 ? '▲ ' : tmp.DELTA_WK === 0 ? '': '▼ '}` + tmp.DELTA_WK)

      week
      .append('text')
      .attr('transform', `translate(146, 13)`)
      .style('font-size', 11)
      .style('font-family', 'roboto')
      .attr('fill', '#333')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text(tmp.ACTUAL_WK)

    },
    drawOverallIssues() {
    },

    // ###----------------------------------------- Area Infomation Box 
    drawOtherInfo(sender) {
      let infobox = d3.select('.overall_info')

      // Back Button
      infobox
      .append('image')
      .attr('class', 'Back_Btn')
      .attr('transform', `translate(1340, 0)`)
      .style('cursor', 'pointer')
      .attr('xlink:href', require('../../../../../../src/assets/svg/icons/back_btn3.svg'))
      .on('click', () => { 
        let del = []
        this.selectedItems.forEach((d,index) => {
          del.push(index)
        })
        del.forEach(d => {
          this.selectedItems.pop()
        })
        this.gotoStage('OverAll') 
      })

      // Information Box
      infobox
      .append('rect')
      .attr('class', '_infobox_box_bg')
      .attr('width', 250)
      .attr('height', 800)
      .attr('fill', '#fff')
      .attr('rx', 5)
      .style('filter', 'url(#dropshadow)')

      infobox
      .append('text')
      .attr('class', 'information_title')
      .attr('transform', `translate(6.5, 12)`)
      .style('font-family', 'roboto')
      .style('font-size', 10)
      .style('fill', '#959595')
      .text(`${this.stage.toUpperCase()} OVERVIEW`)

      infobox
      .append('text')
      .attr('transform', `translate(200, 12)`)
      .style('font-family', 'roboto')
      .style('font-size', 10)
      .style('fill', '#44A9DF')
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .text('Show All')
      .style('cursor', 'pointer')
      .on('mouseover', (_, i, a) => { d3.select(a[i]).style('fill', '#000') })
      .on('mouseout', (_, i, a) => { d3.select(a[i]).style('fill', '#44A9DF') })
      .on('click', () => { 
        let dStage = {
          stage: this.stage,
          item: 'ALL'
        }
        this.selectedItems.push(dStage)
        this.cValue = 3         
        this.loadInformation()
      
      })

      let infoOverall = infobox
      .append('g')
      .attr('class', 'info_content_overall')
      .attr('transform', `translate(6.5, 35.5)`)

      infoOverall
      .append('rect')
      .attr('width', 238)
      .attr('height', 225)
      .attr('rx', 5)
      .attr('stroke', '#dadada')
      .attr('stroke-width', .5)
      .style('fill', '#fff')

      infoOverall
      .append('rect')
      .attr('transform', `translate(5, -3)`)
      .attr('width', 190)
      .attr('height', 10)
      .style('fill', '#fff')

      infoOverall
      .append('text')
      .attr('transform', `translate(10, 3)`)
      .style('font-family', 'roboto')
      .style('font-size', 11)
      .style('fill', '#000')
      .text('Overall Progress & Status of Activity')

      let infoDiscProgress = infobox
      .append('g')
      .attr('class', 'info_content_discprog')
      .attr('transform', `translate(6.5, 270.5)`)

      infoDiscProgress
      .append('rect')
      .attr('width', 238)
      .attr('height', 300)
      .attr('rx', 5)
      .attr('stroke', '#dadada')
      .attr('stroke-width', .5)
      .style('fill', '#fff')

      infoDiscProgress
      .append('rect')
      .attr('transform', `translate(5, -3)`)
      .attr('width', 120)
      .attr('height', 10)
      .style('fill', '#fff')

      infoDiscProgress
      .append('text')
      .attr('transform', `translate(10, 3)`)
      .style('font-family', 'roboto')
      .style('font-size', 11)
      .style('fill', '#000')
      .text('Discpline Progress')

      //image DataURL clipPath
      // console.log(this.stage)
      // let LV1 = (
      //   this.stage == 'Topside' ? 'TOPSIDES' : (
      //     this.stage == 'LQ' ? 'LQ' : 'HULL & LQ'
      //   )
      // )
      
      let LV1 = 'TOPSIDES'

      if (this.stage == 'Modules') LV1 = 'TOPSIDES MODULES'
      else if (this.stage == 'Common') LV1 = 'TOPSIDES COMMON'
      else if (this.stage == 'LQ') LV1 = 'LQ'
      else LV1 = 'HULL & LQ'
      
      let item = this.Queries.SQL6.find(q => q.GWBS_LV1 == LV1)
      // console.log(item)
      let infoImage = infobox
      .append('g')
      .attr('class', 'info_content_image')
      .attr('transform', `translate(6.5, 580.5)`)
      
      //image --------------------이미지가 있을때와 없을때로 구분 작업
      if(item && item.DATAURL){
        infoImage
        .append('image')
        .attr('id', `Info__${this.localId}`)
        .attr('clip-path', 'url(#mask_infolevel_image')
        .attr('preserveAspectRatio', 'none')
        .attr('transform','translate(0,0)')
        .attr('href', item.DATAURL)
        // .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}${item.PATH}`)
        .attr('width', 238)
        .attr('height', 210)
        .on('click', () => {
          let request_ = {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'imgPopup',
              component: 'JModalOverlayImgPopup',
              id: 1,
              no: ''
            },
            imgPath: item.PATH,
            dimention: item.DIMENTION
          }
          this.$emit('request-action', request_)
        })
      }else{
        infoImage
        .append('g')
        .append('rect')
        .attr('rx', '5')
        .attr('width', 238)
        .attr('height', 210)
        .attr('transform', `translate(0, 0)`)
        .attr('rx', 5)
        .attr('stroke', '#dadada')
        .attr('stroke-width', .5)
        .style('fill', '#fff')
        .attr('opacity', 0.9)
      }
      //-------------------------------------------------------------------처음작업 이미지만 안나오게 만듬
      // infoImage
      // .append('image')
      // .attr('id', `Info__${this.localId}`)
      // .attr('clip-path', 'url(#mask_infolevel_image')
      // .attr('transform','translate(0,0)')
      // .attr('href', item && item.DATAURL ? item.DATAURL : '')
      // .attr('height', 210)

      // infoImage
      // .append('g')
      // .append('rect')
      // .attr('rx', '5')
      // .attr('width', 238)
      // .attr('height', 210)
      // .attr('transform', `translate(0, 0)`)
      // .attr('rx', 5)
      // .attr('stroke', '#dadada')
      // .attr('stroke-width', .5)
      // .style('fill', '#fff')
      // .attr('opacity', 0.9)

      this.drawOverallProgress(sender)
      this.drawDiscProgress(sender)
    },
    // Ovarall Pie Chart Status of activity
    drawOverallProgress(sender) {
      let drawbox = d3.select('.info_content_overall')

      if(sender == 'in-yard') this.levelCode = ''
      else {
        let titletxt = d3.select('.information_title')
        let title = titletxt.text()
        titletxt.text(title + ` (${this.levelCode})`)
      }

      if(this.stage == 'Modules') {
        if(sender == 'in-yard') var item__ = this.DataItems.filter(d => d.LV2 == 'MODULES' && d.LV3 == 'OVERALL')
        else item__ = this.DataItems.filter(d => d.LV3 == this.levelCode)

      } else if(this.stage == 'Hull') {
        if(sender == 'in-yard') item__ = this.DataItems.filter(d => d.LV1 == 'HULL' && d.LV2 == 'OVERALL')
        else item__ = this.DataItems.filter(d => d.LV2 == this.levelCode) 
      } 
      else if(this.stage == 'Common') {
        if(sender == 'in-yard') item__ = this.DataItems.filter(d => d.LV2 == 'COMMON' && d.LV3 == 'OVERALL')
        else item__ = this.DataItems.filter(d => d.LV3 == this.levelCode)
      }
      else {
        item__ = this.DataItems.filter(d => d.LV2 == 'LQ')
      }


      let item = {
        PLAN_PROG: 0,
        ACTUAL_PROG: 0,
        DELTA: 0,
        PLAN_PROG_WK: 0,
        ACTUAL_PROG_WK: 0,
        DELTA_WK:0,
        FAB_START_TOTAL: 0,
        FAB_START_ACTUAL: 0,
        FAB_COMP_TOTAL: 0,
        FAB_COMP_ACTUAL: 0,
        PAINTING_TOTAL: 0,
        PAINTING_ACTUAL: 0,
        BLOCKASSY_TOTAL: 0,
        BLOCKASSY_ACTUAL: 0,
        EREC_TOTAL: 0,
        EREC_ACTUAL: 0
      }
      
      item__.forEach(d => {
        Object.keys(item).forEach(k => {
          if(typeof d[k] == 'undefined') return
          item[k] += d[k]
        })
      })

      item.PLAN_PROG = item.PLAN_PROG / item__.length
      item.ACTUAL_PROG = item.ACTUAL_PROG / item__.length
      item.DELTA = item.DELTA / item__.length
      item.PLAN_PROG_WK = item.PLAN_PROG_WK / item__.length
      item.ACTUAL_PROG_WK = item.ACTUAL_PROG_WK / item__.length
      item.DELTA_WK = item.DELTA_WK / item__.length

      this.drawDonuts(drawbox, item.PLAN_PROG, item.ACTUAL_PROG, item.DELTA, item.PLAN_PROG_WK, item.ACTUAL_PROG_WK, item.DELTA_WK)

      let chart = drawbox
      .append('g')
      .attr('transform', `translate(120, 108)`) 

      let style = [
        { name: 'Fab. Commenced'     , bColor:'#44A9DF', opacity: 0.25 },
        { name: 'Fab. Completed'     , bColor:'#44A9DF', opacity: 0.40 },
        { name: 'Painting Completed' , bColor:'#44A9DF', opacity: 0.55 },
        { name: 'Assembly Completed' , bColor:'#44A9DF', opacity: 0.70 },
        { name: 'Erec. Commenced'    , bColor:'#44A9DF', opacity: 0.85 },
      ]
      let data = [
        { TOTAL: item.FAB_START_TOTAL || 0, ACTUAL: item.FAB_START_ACTUAL || 0 },
        { TOTAL: item.FAB_COMP_TOTAL  || 0, ACTUAL: item.FAB_COMP_ACTUAL  || 0 },
        { TOTAL: item.PAINTING_TOTAL  || 0, ACTUAL: item.PAINTING_ACTUAL  || 0 },
        { TOTAL: item.BLOCKASSY_TOTAL || 0, ACTUAL: item.BLOCKASSY_ACTUAL || 0 },
        { TOTAL: item.EREC_TOTAL      || 0, ACTUAL: item.EREC_ACTUAL      || 0 },
      ]
      this.Bar_Activity_01(chart, item.LV3, 0, 25, data, 10, 65, 13, 10, style, 'off')
    },
    drawDonuts(selection, PLAN, ACTUAL, DELTA ,PLAN_WK, ACTUAL_WK, DELTA_WK) {
      let arcProps = {
        angleSize: 360,
        angleStart: 0,
        bColor: '#F4F5F5',
        stroke: 8,
        Ra: 30
      }
      let values_ = { PLAN, ACTUAL, DELTA,PLAN_WK, ACTUAL_WK, DELTA_WK }
      
      let donutGroup = selection
      .append('g')
      .attr('class', '_donut_group__for_all')
      .attr('transform', `translate(20, 60)`)

      donutGroup
      .append('text')
      .attr('transform','translate(100,-20)')
      .style('font-size', 9)
      .style('font-family', 'roboto')
      .attr('fill', '#757575')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text('Delta (%)')
      donutGroup
      .append('text')
      .attr('transform', `translate(100,3)`)
      .style('font-size', 12)
      .style('font-family', 'roboto')
      .attr('fill', DELTA >= 0 ? '#1e88e5' : '#bf360c')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text(`${DELTA > 0 ? '▲ ' : DELTA === 0 ? '': '▼ '}` + DELTA)

      //Week
      let week = donutGroup
      .append('g')
      .attr('id', 'week')
      .attr('transform', `translate(0, 40)`)

      week
      .append('rect')
      .attr('rx', 4)
      .attr('ry', 4)
      .attr('width', 200)
      .attr('height', 30)
      .attr('fill-opacity', 0)
      .attr('stroke', '#dadada')
      .attr('stroke-width', .5)

      week
      .append('rect')
      .attr('transform', `translate(156, -5)`)
      .attr('width', 40)
      .attr('height', 10)
      .attr('fill', '#fff')

      week
      .append('text')
      .attr('transform', `translate(175, 0)`)
      .style('font-size', 9)
      .style('font-family', 'roboto')
      .attr('fill', '#959595')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text('Weekly')

      week
      .append('text')
      .attr('transform', `translate(30, 17)`)
      .style('font-size', 12)
      .style('font-family', 'roboto')
      .attr('fill', '#333')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text(PLAN_WK)

      week
      .append('text')
      .attr('transform', `translate(100, 17)`)
      .style('font-size', 12)
      .style('font-family', 'roboto')
      .attr('fill', DELTA_WK >= 0 ? '#1e88e5' : '#bf360c')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text(`${DELTA_WK > 0 ? '▲ ' : DELTA_WK === 0 ? '': '▼ '}` + DELTA_WK)

      week
      .append('text')
      .attr('transform', `translate(170, 17)`)
      .style('font-size', 12)
      .style('font-family', 'roboto')
      .attr('fill', '#333')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text(ACTUAL_WK)

      Data.attrs.forEach((attr, i) => {
        let arc = d3.arc()
        .outerRadius(d => d.Ra)
        .innerRadius(d => d.Ra - d.stroke)
        .startAngle(d => d.angleStart * Math.PI / 180)
        .endAngle(d => d.angleEnd || d.angleStart * Math.PI / 180)
        let x__ = Data.width * i + Data.gab
        let donut_ = donutGroup
        .append('g')
        .attr('class', `_donut_chart__${attr.refColumn.toLowerCase()}`)
        .attr('transform', `translate(${x__}, 0)`)

        // Draw Background Donut Chart
        donut_
        .append('circle')
        .attr('cx', 0).attr('cy', 0).attr('r', arcProps.Ra-3)
        .attr('stroke', arcProps.bColor).attr('stroke-width', arcProps.stroke)
        .attr('fill', 'url(#lightGray)')

        // Draw Foreground Donut Chart
        donut_
        .datum(arcProps)
        .append('path')
        .attr('d', arc)
        .attr('fill', attr.fColor)
        .attr('opacity', 0.5)
        .transition()
        .duration(1000)
        .attrTween('d',(d) => {
          let endAngle_ = ((d.angleSize * (values_[attr.refColumn] / 100)) + d.angleStart) * Math.PI / 180
          let interpolate = d3.interpolate(d.angleStart * Math.PI / 180, endAngle_)
          return function(t) {
            d.angleEnd = interpolate(t)
            return arc(d);
          }
        })

        donut_
        .append('text')
        .attr('transform', `translate(0, 1)`)
        .style('font-family', 'roboto')
        .style('font-size', 12)
        .style('fill', attr.tColor)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(values_[attr.refColumn].toFixed(2) || 0)

        // Legend
        donut_
        .append('text')
        .attr('transform', `translate(0, -40)`)
        .style('font-family', 'roboto')
        .style('font-size', 9)
        .style('fill', '#757575')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(attr.text + ' (%)')
      })
    },
    // Discipline Bar progress
    drawDiscProgress(sender) {
      let drawbox = d3.select('.info_content_discprog')

      if(this.stage == 'Modules') {
        if(sender == 'in-yard') var item__ = this.Queries.SQL4.filter(d => d.LV2 == 'MODULES' && d.LV3 == 'OVERALL')
        else item__ = this.Queries.SQL4.filter(d => d.LV3 == this.levelCode)

      } else if(this.stage == 'Hull') {
        if(sender == 'in-yard') item__ = this.Queries.SQL4.filter(d => d.LV1 == 'HULL' && d.LV2 == 'OVERALL' && d.LV3 == 'OVERALL')
        else item__ = this.Queries.SQL4.filter(d => d.LV2 == this.levelCode)
      } 
      else if(this.stage == 'Common') {
        if(sender == 'in-yard') item__ = this.Queries.SQL4.filter(d => d.LV2 == 'COMMON' && d.LV3 == 'OVERALL')
        else item__ = this.Queries.SQL4.filter(d => d.LV3 == this.levelCode)
      }
      else {
        item__ = this.Queries.SQL4.filter(d => d.LV1 == 'HULL' && d.LV2 == 'LQ' && d.LV3 == 'OVERALL')
      }

      // Bar_Quantity_02
      let style = {
              x: -10,
              y: 10,
              cell: [70, 88, 130, 180],
              bColor: '#B4E3FA', 
              sColor:'#44A9DF', 
              tColor1: '#fff', 
              tColor2: '#757575', 
              opacity: 1,
              length: 110,
              lineHeight: 25,
              min: 25
      }
      // console.log(item__)
      this.Bar_Quantity_02_Percent(drawbox, item__, style) // ---------------------------> 
    },
    drawImage() {
      let drawbox = d3.select('.infoImage')
    },
  }
}
