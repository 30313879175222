// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {
    Draw_Charts() {
      let data = null, style = null, tmp = null
      let overallSummary = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0.5)`)
      
      overallSummary
      .append('image')
      .attr('opacity', 1)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Modules/ModuleW380.png`)
      .attr('transform', `translate(20, 126)`)

      // Overall Plan
      tmp = this.DataItems[0]
      style = {
        id: 'CERT',
        x: 200,
        y: 55,
        ty: 59,
        name: tmp.PLAN,
        sort: 'N',
      }
      data = [
        {title: 'Completed',  value: tmp.PLAN },
        {title: 'Remain',     value: 100-tmp.PLAN }
      ]
      let Circle = {
        CircleDiameter: 28,
        CircleColorSet: ['Gray', 'LightGray'],
        CircleHoleSize: 20,
        CircleStackSize: 2,
        CircleSliceSize: 5,
        CircleValueSize: 14,
        CircleTitleSize: 12,
        CircleLegendSpace: 80,
      }
      let Guideline = {
        GuideDisplay: 'N',
      }
      overallSummary
      .append('text')
      .attr('transform', `translate(${style.x}, ${style.y - Circle.CircleDiameter - 5})`)
      .style('font-size', 10).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'middle')
      .text('Plan (%)')

      this.Chart_PieSimple(overallSummary, data, style, Circle, Guideline)

      style = {
        id: 'SHI',
        x: 348,
        y: 55,
        ty: 59,
        name: tmp.ACTUAL,
        sort: 'N',
      }
      data = [
        {title: 'Completed',  value: tmp.ACTUAL },
        {title: 'Remain',     value: 100-tmp.ACTUAL }
      ]
      Circle.CircleColorSet = ['LightBlue', 'LightGray']
      
      overallSummary
      .append('text')
      .attr('transform', `translate(${style.x}, ${style.y - Circle.CircleDiameter - 5})`)
      .style('font-size', 10).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'middle')
      .text('Actual (%)')

      this.Chart_PieSimple(overallSummary, data, style, Circle, Guideline)

      let box = overallSummary
      .append('g')
      .attr('transform', `translate(253, 40)`)

      box
      .append('text')
      .attr('transform', `translate(25, 12)`)
      .style('font-size', 9)
      .style('font-family', 'roboto')
      .attr('fill', '#959595')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text('Delta (%)')

      box
      .append('text')
      .attr('transform', `translate(25, 30)`)
      .style('font-size', 12)
      .style('font-family', 'roboto')
      .attr('fill', tmp.DELTA >= 0 ? '#1e88e5' : '#bf360c')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text(`${tmp.DELTA > 0 ? '▲ ' : tmp.DELTA === 0 ? '': '▼ '}` + tmp.DELTA)

      //Week
      let week = overallSummary
      .append('g')
      .attr('id', 'week')
      .attr('transform', `translate(175, 96)`)
      
      week
      .append('rect')
      .attr('rx', 4)
      .attr('ry', 4)
      .attr('width', 200)
      .attr('height', 30)
      .attr('fill-opacity', 0)
      .attr('stroke', '#dadada')
      .attr('stroke-width', .5)

      week
      .append('rect')
      .attr('transform', `translate(156, -5)`)
      .attr('width', 40)
      .attr('height', 10)
      .attr('fill', '#fff')

      week
      .append('text')
      .attr('transform', `translate(176, 0)`)
      .style('font-size', 9)
      .style('font-family', 'roboto')
      .attr('fill', '#959595')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text('Weekly')

      week
      .append('text')
      .attr('transform', `translate(27, 17)`)
      .style('font-size', 12)
      .style('font-family', 'roboto')
      .attr('fill', '#333')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text(tmp.PLAN_WK)

      week
      .append('text')
      .attr('transform', `translate(100, 17)`)
      .style('font-size', 12)
      .style('font-family', 'roboto')
      .attr('fill', tmp.DELTA_WK >= 0 ? '#1e88e5' : '#bf360c')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text(`${tmp.DELTA_WK > 0 ? '▲ ' : tmp.DELTA_WK === 0 ? '': '▼ '}` + tmp.DELTA_WK)

      week
      .append('text')
      .attr('transform', `translate(176, 17)`)
      .style('font-size', 12)
      .style('font-family', 'roboto')
      .attr('fill', '#333')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text(tmp.ACTUAL_WK)
    },
  }
}