import Defs from '../../../../../../includes/primitives/Color_Defs'
import Data from '../../../../../../includes/primitives/Color_Data'


import * as d3 from 'd3'

export default {
  data: () => ({
    dataSet: [],


    Erection: [
      { SN: 1, 
        title: 'Temp supports layout.', 
        Contents: [
          {y: 0,   height: 50, tColor: '#333333', text: 'The whole ship is divided into two sections of bow and stern to be built in the dock, and the docks are arranged according to the two sections.'},
          {y: 70,  height: 50, tColor: '#bcbcbc', text: '整船分为艏艉两个总段在坞内建造，<br /> 坞墩按照两个总段布置'},
        ],
      },
      { SN: 2, 
        title: 'The bottom Mega blocks installation.', 
        Contents: [
          {y: 0, height: 50, tColor: '#333333', text: 'The main hull section is loaded, and the double bottom section is hoisted and positioned.'},
          {y: 50, height: 50, tColor: '#bcbcbc',text: '主船体总段搭载，双层底总段吊装定位'},
        ],
      },
      { SN: 3, 
        title: 'Middle Mega blocks erection.', 
        Contents: [
          {y: 0, height: 40, tColor: '#333333', text: 'Carried in the middle section of the longitudinal wall.'},
          {y: 30, height: 50, tColor: '#bcbcbc',text: '中纵壁总段搭载'},
        ],
      },
      { SN: 4, 
        title: 'Bulkhead blocks erection.', 
        Contents: [
          {y: 0, height: 40, tColor: '#333333', text: 'The transverse bulkhead is carried in sections.'},
          {y: 30, height: 50, tColor: '#bcbcbc', text: '横舱壁分段搭载'},
        ],
      },
      { SN: 5, 
        title: 'Lower starboard and portside Mega blocks erection.', 
        Contents: [
          {y: 0, height: 40, tColor: '#333333', text: 'Carrying on the lower side of the total section.'},
          {y: 30, height: 50, tColor: '#bcbcbc', text: '下舷侧总段搭载'},
        ],
      },
      { SN: 6, 
        title: 'Upper starboard and portside Mega blocks erection.', 
        Contents: [
          {y: 0, height: 40, tColor: '#333333', text: 'Carrying on the upper side section.'},
          {y: 30, height: 50, tColor: '#bcbcbc',text: '上舷侧总段搭载'},
        ],
      },
      { SN: 7, 
        title: 'Main deck mega blocks erection.', 
        Contents: [
          {y: 0, height: 40, tColor: '#333333', text: 'The main section of the main deck.'},
          {y: 30, height: 50, tColor: '#bcbcbc',text: '主甲板总段搭载'},
        ],
      },
      { SN: 8, 
        title: 'The construction of the main hull is carried out according to the sequence', 
        Contents: [
          {y: 0, height: 160, tColor: '#333333', text: `The bottom Mega blocks<br />
                                    - Middle Mega blocks <br />
                                    - Bulkhead blocks <br />
                                    - Lower starboard and portside Mega blocks <br />
                                    - Upper starboard and portside Mega blocks <br />
                                    - Main deck mega blocks.<br />
                                    One Hull section bottom,<br />
                                    then next section bottom, then from bottom to top.<br />`
          },
          {y: 160, height: 75, tColor: '#bcbcbc',text: `项目主船体坞内建造按照双层底总段 <br />
                                    -中纵壁总段-横舱壁 <br />
                                    -下舷侧总段 <br />
                                    -上舷侧总段-艏艉 <br />
                                    总段-主甲板总段的顺序完成搭载 <br />` 
          },
          {y: 250, height: 120, tColor: '#333333', text: `AFT. and FWD. mega blocks will be lifted by 
                                    600t gantry crane, and equipment will be installation 
                                    before up level blocks lifting, equipment installed in 
                                    the dry dock, if the equipment delivery late, 
                                    Its installation will by temporary access or hatch. 
                                    The external welding seam and overboard pipes 
                                    should carry out tightness test before undocking.`
          },
          {y: 380, height: 100, tColor: '#bcbcbc',text: `艏艉总段采用600 吨龙门吊合拢，<br />
                                    同时设备需要在其顶部分段搭载前安装到位，<br />
                                    设备安装在坞内进行，<br />
                                    如其到货推迟，将通过工艺孔或者天窗进行安装。<br />
                                    在下水前完成涉及外板及出海管的密性试验<br />`
          },
        ],
      },
      { SN: 9, 
        title: 'Structure items installation before FWD undock.', 
        Contents: [
          {y: 0, height: 60, tColor: '#333333',text: `The module stool, crane pedestal, mooring balcony, fairlead and other structure installation before FWD undock`},
          {y: 50, height: 50, tColor: '#bcbcbc',text: '主甲板模块基座、<br />甲板吊机底座、<br />艏总段导链器以及其他结构在艏段出坞前安装。'},
        ],
      },
      { SN: 10, 
        title: 'The FWD part marine pipe rack will lifting in dry dock.', 
        Contents: [
          {y: 0, height: 100, tColor: '#333333',text: `Move marine pipe rack to near the dock, 
                                    then lifting on board in the dock by gantry crane.
                                    The FWD part marine pipe rack will lifting in dry dock 
                                    and the AFT part marine pipe rack lifting 
                                    in mating dock`
          },
          {y: 90, height: 100, tColor: '#bcbcbc',text: `在坞内完成管廊吊装。<br />
                                    艏总段部分管廊在中远坞内吊装，<br />
                                    艉总段部分管廊在大合拢坞内吊装。`
          },
        ],
      },
      { SN: 11, 
        title: 'Open the door for FWD part undocking.', 
        Contents: [
          {y: 0, height: 50, tColor: '#333333',text: 'Put water in the dock, open the dock door, and the bow section is ready to leave the dock.'},
          {y: 50, height: 50, tColor: '#bcbcbc',text: '坞内放水，开坞门，艏总段准备出坞'},
        ],
      },
      { SN: 12, 
        title: 'FWD part undocking.', 
        Contents: [
          {y: 0, height: 50, tColor: '#333333',text: 'With the assistance of tugs, the bow section is towed out of the dock first.'},
          {y: 50, height: 50, tColor: '#bcbcbc',text: '通过拖轮辅助，艏总段先拖带出坞'},
        ],
      },
      { SN: 13, 
        title: 'The FWD part berthed at the wharf and the AFT part moved in the dock,the marine piperack installation and equipment installation on FOR part', 
        Contents: [
          {y: 0, height: 80, tColor: '#333333',text: `The FWD part berthed at the wharf and the AFT part moved in the dock. 
                                    Resetting arrange the support blocks before the AFT moved.
                                    The marine piperack installation and equipment installation on FOR part.`
          },
          {y: 90, height: 50, tColor: '#bcbcbc',text: `艏总段出坞后在码头靠泊进行后续工程，<br />
                                      艉总段在坞内移位坐墩。<br />
                                      艉总段移位前对坞内坞墩进行重新布置。<br />
                                      前进部分的海上吊架安装和设备安装。`
          },
        ],
      },
      { SN: 14, 
        title: 'The mooring balcony and riser items installed in the dock of the AFT part.', 
        Contents: [
          {y: 0, height: 100, tColor: '#333333', text: `The mooring balcony, fairleads, riser balcony, I-tube, 
                                  deflection spool and hydraulic tubing installed 
                                  in the dock of the AFT part. The riser protector will be installed in the dock at
                                  the same time`
          },
          {y: 90, height: 50, tColor: '#bcbcbc',text: `
                                    艉总段在坞内安装锚机平台及舷侧立管平台，<br />
                                    舷侧立管保护装置同时在坞内安装完毕。`
          },
        ],
      },
      { SN: 15, 
        title: 'Open the door for AFT part undocking.', 
        Contents: [
          {y: 0, height: 70, tColor: '#333333',text: 'Put water in the dock, open the dock door, and the stern section leaves the dock.'},
          {y: 50, height: 50, tColor: '#bcbcbc',text: `坞内放水，开坞门，艉总段出坞。`
          },
        ],
      },
      { SN: 16, 
        title: 'AFT and FWD hull are towed to mating dock.', 
        Contents: [
          {y: 0, height: 70, tColor: '#333333',text: 'At first, the AFT main hull will be towed to mating dock. Next, The FWD main hull will be towed.'},
          {y: 50, height: 50, tColor: '#bcbcbc',text: `艏艉两段先后分别通过拖轮拖带前往艏艉总段大合拢坞。`
          },
        ],
      },
      { SN: 17, 
        title: 'Arrived to the mating dock.', 
        Contents: [
          {y: 0, height: 50, tColor: '#333333',text: 'Arrived to the mating dock, Docking.(The dock detail update after the resources are confirm, ZPMC dry dock as preferred)'},
          {y: 50, height: 50, tColor: '#bcbcbc',text: `艏艉两段到达大合拢坞并通过移船小车及绞车拖拉进坞，<br />
                                                      首选振华坞进行大合拢。`
          },
          {y: 100, height: 0, tColor: '#333333',text: 'ZPMC dry dock size: 350m*76m*11.9m, with two 500t gantry crane for lifting.'},
          {y: 150, height: 50, tColor: '#bcbcbc',text: `振华干船坞尺寸：350m*76m*11.9m，<br />
                                                      配置两台500t 龙门吊。`
          },
        ],
      },
      { SN: 18, 
        title: 'Complete positioning docking in water.', 
        Contents: [
          {y: 0, height: 80, tColor: '#333333',text: 'Close the dock door, complete the floating positioning and docking in the dock, and the tooling card board is sealed in place.'},
          {y: 70, height: 50, tColor: '#bcbcbc',text: `关坞门，在坞内完成浮态定位对接，工装卡板封固到位。`
          },
        ],
      },
      { SN: 19, 
        title: 'Structure welding and external painting', 
        Contents: [
          {y: 0, height: 80, tColor: '#333333',text: 'After the docking on the water is completed, drain the water outside the dock. Complete the welding of the closing seam and the painting of the outer panel in the dock.'},
          {y: 70, height: 50, tColor: '#bcbcbc',text: `水上对接完成后，向坞外排水。<br />
                                                      在坞内完成合拢缝焊接、外板油漆等工作。`
          },
        ],
      },
      { SN: 20, 
        title: 'The docking project is over, Undocking', 
        Contents: [
          {y: 0, height: 50, tColor: '#333333',text: 'After the operation in the dock is completed, water is poured into the dock, and the project is released.'},
          {y: 50, height: 50, tColor: '#bcbcbc',text: `坞内作业完成后，向坞内放水，项目出坞。`
          },
        ],
      },
      { SN: 21, 
        title: 'Towing the FPSO to COSCO Qidong.', 
        Contents: [
          {y: 0, height: 50, tColor: '#333333',text: 'The project is towed back to Qidong Wharf and berthed, and the related operations of the wharf continue.'},
          {y: 50, height: 50, tColor: '#bcbcbc',text: `项目拖带回启东码头并靠泊，继续码头相关作业。`
          },
        ],
      },
      { SN: 22, 
        title: 'The LQ erection on the slipway.',
        Contents: [
          {y: 0, height: 90, tColor: '#333333',text: `The LQ erection on the slipway, and then lift to the hull by floating crane . The FIE will install before LQ lifting.`},
          {y: 70, height: 50, tColor: '#bcbcbc',text: `
                                    在滑道上架设起居室，然后通过浮吊将其举升至船体。<br />
                                    FIE 将在起居区吊装之前安装。`
          },
        ],
      },
      { SN: 23, 
        title: 'Lifeboat davit & Helideck installation', 
        Contents: [
          {y: 0, height: 70, tColor: '#333333',text: 'Lifeboat davit & Helideck lifting by 200T crane onto the LQ after LQ lifting.'},
          {y: 50, height: 50, tColor: '#bcbcbc',text: `救生艇平台及直升机平台通过码头<br /> 200T 吊机吊装至生活区上`
          },
        ],
      },
      { SN: 24, 
        title: 'Piperack lifting on board by 1,800t float crane.', 
        Contents: [
          {y: 0, height: 50, tColor: '#333333',text: 'Move piperack to the front of the wharf by SPMT, then lifting on board by floating crane.'},
          {y: 50, height: 50, tColor: '#bcbcbc',text: `通过SPMT 将管廊移至码头前沿，<br />
                                                      通过浮吊进行上部管廊吊装。`
          },
        ],
      },
      { SN: 25, 
        title: 'Modules lifting on board - ME04, ME05, MW04.', 
        Contents: [
          {y: 0, height: 50, tColor: '#333333',text: 'Modules(ME04, ME05, MW04) lifting on board by 5,000t float crane.'},
          {y: 50, height: 50, tColor: '#bcbcbc',text: `用5,000t浮式起重机吊装的模块 - ME04, ME05, MW04`
          },
        ],
      },
      { SN: 26, 
        title: 'Laydown lifting by gantry crane.', 
        Contents: [
          {y: 0, height: 70, tColor: '#333333',text: 'Laydown lifting by gantry crane. Expansion loop to be considered as loose part installation to avoid lifting clash.'},
          {y: 50, height: 50, tColor: '#bcbcbc',text: `通过龙门吊吊装。`
          },
        ],
      },
      { SN: 27, 
        title: 'Modules lifting on board - MW05', 
        Contents: [
          {y: 0, height: 50, tColor: '#333333',text: 'MW05 Module lifting on board by 5,000t float crane.'},
          {y: 30, height: 50, tColor: '#bcbcbc',text: `用5,000t浮式起重机吊装的模块 - MW05`
          },
        ],
      },
      { SN: 28, 
        title: 'Modules lifting on board - MW01, MW02, ME01, ME02', 
        Contents: [
          {y: 0, height: 50, tColor: '#333333',text: 'Modules(MW02, ME01, ME02) lifting on board by 5000t float crane. MW01 Module lifting on board by 7,500t float crane.'},
          {y: 50, height: 50, tColor: '#bcbcbc',text: `用5,000t浮式起重机吊装的模块 - MW02, ME01, ME02.<br />
                                                        用7,500t浮式起重机吊装的模块 - MW01.`
          },
        ],
      },
      // { SN: 28, 
      //   title: 'Modules( ', 
      //   title2: ' , MW02, ME01, ME02) lifting on board by float cranes(', 
      //   title3: ', 5000t).', 
      //   span1: 'MW01',
      //   span2: '7500t',
      //   Contents: [
      //     {y: 0, height: 50, tColor: '#333333',text: 'Move Modules to the front of the wharf by winch and hydraulic trailer, then lifting on board by floating crane.'},
      //     {y: 50, height: 50, tColor: '#bcbcbc',text: `通过液压小车将模块移至码头前沿，<br />
      //                                                   通过浮吊进行上部模块吊装。`
      //     },
      //   ],
      // },
      { SN: 29, 
        title: 'Flare tower Erection.', 
        Contents: [
          {y: 0, height: 100, tColor: '#333333',text: 'Flare tower fabrication in the workshop and erection on the mega blocks assembly area of dry dock north. Flare lifting by float crane, AFT platform area main deck equipment install of AFT prior flare lifting.'},
          {y: 90, height: 150, tColor: '#bcbcbc',text: `火炬臂分段在车间制作完成后涂装，<br />然后运送至海工坞北总组场地总组，<br />
                                                      然后通过600T龙门吊吊运至坞口，<br /> 艉部主甲板设备安装完成后火炬臂由浮吊吊装上船。`
          },
        ],
      },
      { SN: 30, 
        title: 'Integration and MC', 
        Contents: [
          {y: 0, height: 50, tColor: '#333333',text: 'Integration and MC after the module lifting.<br /> (To be update when necessary)'},
          {y: 50, height: 50, tColor: '#bcbcbc',text: `模块吊装上船后的总装集成及机械完工。`
          },
        ],
      },
      { SN: 31, 
        title: 'Project Commissioning, Test and Delivery.', 
        Contents: [
          {y: 0, height: 50, tColor: '#333333',text: 'Commissioning, Sea-trial, Inclining test during the sea-trial & Delivery.'},
          {y: 50, height: 50, tColor: '#bcbcbc',text: `项目调试、海上试验、倾斜试验及交付。`
          },
        ],
      },
    ],
  }),

  methods: {
    setStyles() {

      let lightBlue = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `shadowTimeline`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      lightBlue.append('stop').attr('stop-color', '#3d3d3d').attr('offset', '0').attr('stop-opacity', 0.5)
      lightBlue.append('stop').attr('stop-color', '#fff').attr('offset', '1').attr('stop-opacity', 0.3)

      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['LightGray'], [`LightGray`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['YellowGreen'], [`YellowGreen`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['LightBlue'], [`LightBlue`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['Pink'], [`Pink`, 0.5, 1.0, 0.5, -0.2], this.localId)
    },
  }
}