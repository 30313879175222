export default {
  methods: {
    drawHeaderInfo() {
      if(this.cmmHeaderArea) this.cmmHeaderArea.remove()

      this.cmmHeaderArea = this.svg
      .append('g')
      .attr('id', 'common_header_area')
      .attr('transform', 'translate(0, 0)')

      // China1 project logo 
      this.loadSvg({
        selection : this.cmmHeaderArea,
        x         : 30,
        y         : 30,
        path      : '/China1/EquipmentLayout/logo.svg'
      })

      // Equipment Layout title //
      this.cmmHeaderArea
      .append('text')
      .attr('transform', `translate(40, 105)`)
      .style('font-family', 'roboto').style('font-size', 16).style('fill', '#333').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Heavy Lifting Program')

      // Tag Button Controller
      let tagcntr = this.cmmHeaderArea
      .append('g')
      .attr('id', 'tagbtn_control_wrapper')
      .attr('transform', `translate(1509, 80)`)
      .style('font-size', 10)
      .style('font-family', 'roboto')
      .style('cursor', 'pointer')
      
      this.drawTagButton(tagcntr)
    },
    drawSharedInfo(__data) {
      /**
       * Put Shared Data & Controllers (Tools) on the main SVG layer.
       */



      /**
       * Put Shared Data & Controllers (Tools) on the left side of the current page.
       */

      if(this.cmmSharedArea) this.cmmSharedArea.remove()

      this.cmmSharedArea = this.svg
      .append('g')
      .attr('id', 'common_shared_area')
      .attr('transform', 'translate(30, 155.5)')

      this.cmmSharedArea
      .append('rect')
      .attr('rx', 5).attr('ry', 5)
      .style('width', 271).style('height', 342)
      .style('fill', '#fff')
      .style('filter', 'url(#drop_shadow_el)')
      

      // Heavy Lifting Program Summary ----------------------------
      this.drawSummary(this.cmmSharedArea)
      // ----------------------------------------------------------

      // Construction Stage ---------------------------------------
      let box_ = this.drawGroupBox(this.cmmSharedArea, {
        x         : 10.3,
        y         : 130.3,
        width     : 249,
        height    : 120.5,
        label     : 'Construction Stage',
        labelWidth: 100,
      })
      
      let constStageData = Object.keys(this.displayDataFilter.stage).map(k => ({
        code: k,
        ...this.displayDataFilter.stage[k]
      }))
      constStageData.forEach((s, i) => {
        let y_ = (16*i) + (2*i)

        // 1. construction stage legend //
        if(s.code == 'CUR') {
          this.eqStyle.Stage.forEach((d_, j) => {
            box_
            .append('rect')
            .attr('transform', `translate(${j%2==0?10:19}, ${j<2?15:24})`)
            .attr('rx', 1).attr('ry', 1)
            .style('width', 7).style('height', 7)
            .style('fill', d_.bcolor)
          })
        } else {
          box_
          .append('rect')
          .attr('transform', `translate(10, ${y_ + (i?22:17)})`)
          .attr('rx', 3).attr('ry', 3)
          .style('width', 16).style('height', 16)
          .style('fill', this.eqStyle.Stage.find(d__ => d__.code == s.code).bcolor)
        }
          
        // 2. construction stage label //
        box_
        .append('text')
        .attr('id', 'construction_stage_label')
        .attr('transform', `translate(40, ${y_ + (i?32:25)})`)
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .style('font-family', 'roboto')
        .style('font-size', 9)
        .style('fill', '#000')
        .text(s.name)

        // 3. construction stage checkbox //
        this.drawCheckboxAction(box_, {
          x: 29.3,
          y: y_ + (i?27:19),
          d: s,
          class: `construction_stage_checkbox`,
          action: {
            x: -20,
            y: -2,
            w: s.width,
            h: 12,
            callFunc: () => {}
          }
        })
      })

      // current stage separator //
      box_
      .append('line')
      .attr('x1', 40).attr('y1', 36)
      .attr('x2', 170).attr('y2', 36)
      .style('stroke', '#ccc')
      .style('stroke-width', .5)
      // ----------------------------------------------------------

      // Search Controller ----------------------------------------
      let data__ = {
        x         : 10.3,
        y         : 263,
        width     : 249,
        height    : 65.5,
        label     : 'Search',
        labelWidth: 50,
        targets: [
          { text: 'Equipment Name' },
          { text: 'Tag No.'        },
        ],
        callFunc: this.search,
      }
      this.drawSearchToolFree(this.cmmSharedArea, data__)
      // ----------------------------------------------------------
    },
    drawSummary(selection) {
      if(!selection) return

      this.setSummaryData()

      let box__ = selection.select('#el_stage_status_box')
      if(box__) box__.remove()

      box__ = selection
      .append('g')
      .attr('id', 'el_stage_status_box')
      .attr('transform', 'translate(0, 0)')

       // draw total
      let req = this.buildRequest(this.reqTitle.summary.total)
      req.action.no = 'Heavy Lifting Items'
      
      let d = {
        x         : 10,
        y         : 10,
        width     : 120,
        height    : 32,
        radius    : 3,
        bColor    : '#83D2F5',
        value     : this.dataset.summary.total,
        valuex    : 70,
        valuey    : 18,
        valueSize : 20,
        valueColor: '#fff',
        vanchor   : 'end',
        linkable  : true,
        label     : 'Total',
        labelx    : 75,
        labely    : 20,
        labelSize : 9,
        labelColor: '#e3f5fd',
        request   : req,
      }
      this.drawBoxedSummarySingle(box__, d)
      
      // draw installed
      d.x = 140
      d.bColor = '#F57AA4'
      d.value = this.dataset.summary.installed
      d.label = 'Installed'
      d.labelColor = '#FCE3EB',

      this.drawBoxedSummarySingle(box__, d)

      let body = []
      this.dataset.summary.items.forEach(d_ => {
        body.push({
          ...d_,
          TITLE_PLAN       : `Equipment Summary - Plan on ${d_.STAGE}`,
          TITLE_ACTUAL     : `Equipment Summary - Actual on ${d_.STAGE}`,
        })
      })

      this.tableBarProgress(box__, {
        data: {
          header: [
            { text: ''      , colName: 'STAGE' , width: 57, vcolor: '#757575' },
            { text: 'Plan'  , colName: 'PLAN'  , width: 25, vcolor: '#757575', linkable: true, values: [], filterStringCol: 'FILTER_STR_PLAN'  , titleCol: 'TITLE_PLAN'  , queryNameCol: 'QUERY_NAME'   },
            { text: 'Actual', colName: 'ACTUAL', width: 35, vcolor: '#757575', linkable: true, values: [], filterStringCol: 'FILTER_STR_ACTUAL', titleCol: 'TITLE_ACTUAL', queryNameCol: 'QUERY_NAME' },
            { text: 'Delta' , colName: 'DELTA' , width: 30, vcolor: '#F44336' },
            { type: 'space' , width: 10 },
            { type: 'bar'   , colName: 'PROG'  , width: 62 },
          ],
          body
        },
        form: {
          x         : 40,
          y         : d.y + d.height + 10,
          title     : { text: '', x: 28, tSize: 11, tColor: '#333' },
          lineHeight: 17,
          bColor    : '#B4E3FA', 
          sColor    : '#44A9DF', 
          tColor1   : '#053a54', 
          tColor2   : '#757575', 
          opacity   : 1,
        },
        request: req,
      })
    },
    drawCheckboxAction(selection, data) {
      let checkbox = selection
      .append('g')
      .attr('class', data.class)
      .attr('transform', `translate(${data.x}, ${data.y})`)

      // check box
      checkbox
      .append('rect')
      .attr('class', `stage_checkbox__${data.d.code}`)
      .attr('transform', 'translate(0, 0)')
      .attr('rx', 2)
      .attr('ry', 2)
      .attr('stroke', this.eqStyle.search.out.stroke)
      .attr('stroke-width', .5)
      .style('width', 8)
      .style('height', 8)
      .style('fill-opacity', 0)

      // check box checked status
      checkbox
      .append('text')
      .attr('class', `stage_checkbox_checked__${data.d.code}`)
      .attr('transform', `translate(2, 4)`)
      // .style('fill', '#999')
      .style('font-size', 8)
      .style('text-anchor', 'start')
      .style('alignment-baseline', 'middle')
      .text(data.d.checked?'v':'')

      // action area
      checkbox
      .datum(data.d)
      .append('rect')
      .attr('class', `stage_action_area__${data.d.code}`)
      .attr('transform', `translate(${data.action.x}, ${data.action.y})`)
      .style('width', data.action.w)
      .style('height', data.action.h)
      .style('cursor', 'pointer')
      .style('fill-opacity', 0)
      // .call(data.action.callFunc)
      .call(selection => {
        selection.on('mouseover', d => {
          this.svg.select(`.stage_checkbox__${d.code}`).attr('stroke', '#555')
        })
        selection.on('mouseout', d => {
          this.svg.select(`.stage_checkbox__${d.code}`).attr('stroke', this.eqStyle.search.out.stroke)
        })
        selection.on('click', d => {
          let checked = this.svg.select(`.stage_checkbox_checked__${d.code}`).text()

          this.svg.select(`.stage_checkbox_checked__${d.code}`).text(checked ? '' : 'v')
          this.displayDataFilter.stage[d.code].checked = !checked
          checked = !checked

          if(!checked) {
            this.equipmentFilter()
            return
          }

          if(d.code == 'CUR') {
            Object.keys(this.displayDataFilter.stage).forEach(k => {
              if(k == 'CUR' || !this.displayDataFilter.stage[k].checked) return

              this.svg.select(`.stage_checkbox_checked__${k}`).text('')
              this.displayDataFilter.stage[k].checked = false
            })
          } else {
            this.svg.select('.stage_checkbox_checked__CUR').text('')
            this.displayDataFilter.stage.CUR.checked = false

            if(d.code == 'DOL') {
              this.svg.select('.stage_checkbox_checked__DO').text('')
              this.displayDataFilter.stage.DO.checked = false

              this.svg.select('.stage_checkbox_checked__EFL').text('')
              this.displayDataFilter.stage.EFL.checked = false

            } else if(d.code == 'DO') {
              this.svg.select('.stage_checkbox_checked__DOL').text('')
              this.displayDataFilter.stage.DOL.checked = false

              this.svg.select('.stage_checkbox_checked__EFL').text('')
              this.displayDataFilter.stage.EFL.checked = false

            } else if(d.code == 'EFL') {
              this.svg.select('.stage_checkbox_checked__DOL').text('')
              this.displayDataFilter.stage.DOL.checked = false

              this.svg.select('.stage_checkbox_checked__DO').text('')
              this.displayDataFilter.stage.DO.checked = false
            }
          }

          this.equipmentFilter()
          this.indicatorMoveTo(this.prevReq)
        })
      })
    },
    putIndicator() {
      // draw indicator ---------------------------
      this.indicator = this.innerSvg.select(`#CODE`)
      .append('g')
      .attr('id', 'item_indicator')
      .attr('transform', 'translate(0, 0)')
      .style('opacity', 0)

      // shadow //
      this.indicator
      .append('g')
      .attr('id', 'item_indicator_shadow')
      .attr('transform', 'translate(0, 1) scale(1.12)')
      .append('path')
      .attr('d', this.indipath)
      .attr('fill', '#000')
      .attr('fill-opacity', .08)

      this.indicator
      .append('g')
      .attr('id', 'item_indicator_shadow')
      .attr('transform', 'translate(0, 1) scale(1.1)')
      .append('path')
      .attr('d', this.indipath)
      .attr('fill', '#000')
      .attr('fill-opacity', .08)

      this.indicator
      .append('g')
      .attr('id', 'item_indicator_shadow')
      .attr('transform', 'translate(0, 1) scale(1.07)')
      .append('path')
      .attr('d', this.indipath)
      .attr('fill', '#000')
      .attr('fill-opacity', .08)

      // indicator //
      this.indicator
      .append('path')
      .attr('d', this.indipath)
      .attr('fill', '#fa2c72')
      .attr('stroke', '#970234')
      .attr('stroke-width', 1)
      // -----------------------------------------
    }
  }
}