import * as d3 from 'd3'
import __M from 'moment'

export default {
  methods: {
    Draw_Table() {
      if(this.table.display != 'Y') return

      let dataTable = this.svg
      .append('g')
      .attr('class', 'table_form')
      .attr('transform', `translate(
        ${this.Canvas.CanvasChartX},
        ${this.Canvas.CanvasChartY + this.axisYHeight + this.table.gapFromChart}
      )`)

      // reset coordinates by timeline scale
      this.table.headers.forEach((h_, i) => { h_.x = this.table.cellWidth * i })
      this.table.timeline = {}
      this.table.timeline.week = this.timeline.week
      this.table.timeline.month = this.timeline.month
      this.table.timeline.year = this.timeline.year
      this.table.timeline.scale = this.timeline.scale
      this.DataTable_Timeline1(dataTable, this.dataSet, this.table)
    }, 
    putTableForm() {
      let dataTable = this.svg
      .append('g')
      .attr('class', 'table_form')
      .attr('transform', `translate(
        ${this.Canvas.CanvasChartX},
        ${this.Canvas.CanvasChartY + this.axisYHeight + this.table.gapFromChart}
      )`)

      // Horizontal Line
      dataTable
      .selectAll('path')
      .data(this.Queries.SQL1)
      .enter()
      .append('path')
      .attr('d', (_, i) => `M0,${this.table.lineHeight  * i}H${this.axisXWidth}`)
      .attr('stroke', '#757575')
      .attr('stroke-width', 0.5)

      // Vertical Line
      this.dataXTimelineValues.forEach(v_ => {
        dataTable
        .append('path')
        .attr('d', d => `M${this.scaleXTimeline(v_)},0V${this.table.height}`)
        .attr('stroke', '#757575')
        .attr('stroke-width', 0.5)
      })
    },
    putTableValues() {
      let dataTable = this.svg
      .append('g')
      .attr('class', 'table_text_value')
      .attr('transform', `translate(
        ${this.Canvas.CanvasChartX},
        ${this.Canvas.CanvasChartY + this.axisYHeight + this.table.gapFromChart + 0.5}
      )`)
      
      // Line Titles
      dataTable
      .selectAll('text')
      .data(this.Queries.SQL1)
      .enter()
      .append('text')
      .attr('transform', (_, i) => `translate(-80,${this.table.lineHeight  * i + 10})`)
      .attr('fill','#757575')
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .style('font-family','roboto')
      .style('font-size', 12)
      .text(d => d.text)

      // Month Text & Timeline Seperator for the Month
      dataTable
      .append('g')
      .selectAll('text')
      .data(this.timeline.month)
      .enter()
      .append('text')
      .attr('x', d => this.timeline.scale(d.mDate))
      .attr('y', 5)
      .style('font-family', this.timeline.font)
      .style('font-size', this.timeline.style.month.tSize)
      .attr('fill', this.timeline.style.month.tColor)
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'hanging')
      .text(d => d.name.toUpperCase())
    }
  }
}
