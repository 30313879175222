import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  data: () => ({
    
  }),

  methods: {

    // Canvas_Levels() {
      
    //   this.CANVAS = this.svg.append('svg')

    //   this.CANVAS
    //   .attr('width', this.Canvas.CanvasWidth)
    //   .attr('height', this.Canvas.CanvasHeight)
    //   .style('opacity', 1)
    //   .style('pointer-events', 'none')

    //   let style = null


    //   this.CANVAS
    //   .append('image')
    //   .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/SVG/Elevation.svg`)

     
    //   // Block Status
    //   this.Level_Coordinate.forEach((lev,sn) => {
    //     // console.log(prog.Lv1, prog.Lv2, prog.Lv4)
    //     let itemSet = []
    //     style = {
    //               x: lev.x,
    //               y: lev.y + 12,
    //               headTextSize: 16,
    //               headTextColor: '#44A9DF',
    //               subText: 'Status of Equipment',
    //               headTitle: `${lev.name}`,
    //               progDisplay: 'N',
    //               deltaDisplay: 'N',
    //     }

    //     this.StatusSummary.forEach((d,i) => {
    //       itemSet.push({
    //         name: d.title,
    //         total: this.dataSet.filter(f => f.EV == lev.name).length,
    //         actual: this.dataSet.filter(f => f.EV == lev.name && f.STATUS == d.code).length,
    //         bColor: d.bColor,
    //         opacity: d.opacity,
    //       })
    //     })

    //     this.Bar_Quantity_02(this.CANVAS, itemSet, style, sn) // <---- Chart Library
    //   })


    // },


  }
}