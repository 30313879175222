import * as d3 from 'd3'

export default {
  watch: {
    scrollLeft(val){
      d3.select(`._donut_group__for_all`)
      .transition().duration(300).attr('transform', `translate(${val + 70},135)`)

      d3.select(`._global_elements__text__text_`).transition().duration(300).attr('transform', `translate(${val},0)`)

      // d3.select(`._datasource_texts`).transition().duration(300).attr('x', val)
      d3.select(`._global_elements__text__sql_`).transition().duration(300).attr('transform', `translate(${val},0)`)

      d3.select(`._donut_group__for_all_rect`).transition().duration(300).attr('x', val)
    }
  },
  methods: {
    draw_Legends() {
      d3.select(`#skyline_boxes_legends`).remove()

      let Legends = this.SkylineCharts
      .append('g')
      .attr('transform', `translate(${this.skyline.legend.x},${this.skyline.legend.y})`)
      .attr('id', `skyline_boxes_legends`)
      .attr('fill', 'Transparent')

      Legends
      .append('rect')
      .attr('x', 0).attr('y', 0)
      .attr('width', 750).attr('height', 170)
      .attr('class', `_donut_group__for_all_rect`)
      .attr('fill', '#fff')

      Legends
      .append('rect')
      .attr('x', 0).attr('y', 450)
      .attr('width', 240).attr('height', 150)
      .attr('fill', '#fff')

    
      this.putSearch()
    },
    putSearch() {
      d3.select(`#skyline_boxes_legends_searchs`).remove()

      let searchArea_ = this.SkylineCharts
      .append('g')
      .attr('transform', `translate(20, 555)`)
      .attr('id', `skyline_boxes_legends_searchs`)

      searchArea_
      .append('rect')
      .attr('rx', 4)
      .attr('ry', 4)
      .attr('width', 181)
      .attr('height', 80)
      .attr('fill-opacity', 0)
      .attr('stroke', '#dadada')
      .attr('stroke-width', .5)

      searchArea_
      .append('rect')
      .attr('transform', `translate(10, -5)`)
      .attr('width', 55)
      .attr('height', 10)
      .attr('fill', '#fff')

      searchArea_
      .append('text')
      .attr('transform', `translate(20, 1)`)
      .style('font-family', this.skyline.legend.font)
      .style('font-size', this.skyline.legend.group.tSize)
      .style('fill', this.skyline.legend.group.tColot)
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .text('Search')

      searchArea_
      .append('rect')
      .attr('transform', `translate(15, 40)`)
      .attr('width', 150)
      .attr('height', 18)
      .attr('fill', '#fff')
      .attr('stroke', '#dadada')
      .attr('stroke-width', .5)

      let searchOptions_ = searchArea_
      .append('g')
      .attr('transform', `translate(20, 20)`)

      // ### draw search options (filtering target) in the search group box
      this.inputFilter.values.forEach((v_, i) => {

        searchOptions_
        .append('circle')
        .attr('class', `search_circle__${i}`)
        .attr('cx', v_.x)
        .attr('cy', 0)
        .attr('r', 3)
        .style('stroke', v_.on ? this.styleFilter.selected.stroke : this.styleFilter.out.stroke)
        .style('stroke-width', .5)
        .style('fill', v_.on ? this.styleFilter.selected.fill : this.styleFilter.out.fill)

        searchOptions_
        .append('text')
        .attr('class', `search_text__${i}`)
        .attr('transform', `translate(${v_.x + 7}, 1)`)
        .style('font-family', this.skyline.legend.font)
        .style('font-size', this.skyline.legend.item.tSize)
        .style('fill', v_.on ? this.styleFilter.selected.color : this.styleFilter.out.color)
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text(v_.text)

        searchOptions_
        .datum({ filter: this.inputFilter, ...v_})
        .append('rect')
        .attr('id', `search_mask__${i}`)
        .attr('transform', `translate(${v_.x - 5}, -8)`)
        .attr('width', v_.textWidth)
        .attr('height', 16)
        .attr('fill', '#000')
        .attr('fill-opacity', 0)
        .call(this.callFuncSearchFilter)

        // if(v_.on) this.setFilter(this.filterCodes[k_].attrs.type, v_.colName, v_.value)
      })
      // ### draw search input in the search group box
      searchArea_
      .append("foreignObject")
      .attr('x', 15)
      .attr('y', 40)
      .attr('width', 150)
      .attr('height', 18)
      .append('xhtml:div')
      .append('div')
      .attr('id', 'search_input')     // for the javascript document.getElementById
      .attr('class', 'search_input')  // for the d3 general purpose
      .attr('contentEditable', true)
      .attr('style', `padding: 2px 5px; font-size: 10px; line-height: 14px; text-align: left; color: ${this.skyline.legend.item.tColor}`)
      .call(this.callFuncSearchInput)

      // mask for the icon background area
      searchArea_
      .append('rect')
      .attr('transform', `translate(140, 41)`)
      .attr('width', 24)
      .attr('height', 16)
      .attr('fill', '#fff')

      searchArea_
      .append('text')
      .attr('class', 'delete_search_text')
      .attr('transform', `translate(135, 49)`)
      .style('font-family', this.skyline.legend.font)
      .style('font-size', this.skyline.legend.group.tSize)
      .style('fill', this.skyline.legend.group.tColot)
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .text('x')
      .style('cursor', 'pointer')
      .style('opacity', .8)
      .on('mouseover', (_, i, a) => { d3.select(a[i]).transition().duration(100).style('opacity', .7) })
      .on('mouseout', (_, i, a) => { d3.select(a[i]).transition().duration(100).style('opacity', .3) })
      .on('click', () => { 
        d3.select(`#${this.localId}`).select('.search_input').text('')
        this.search() 
      })

      searchArea_
      .append('image') 
      .attr('transform', `translate(148,43)`)
      .attr('xlink:href', require('../../../../../../src/assets/svg/icons/iconmonstr-magnifier-2.svg'))
      .attr('width', 12)
      .attr('height', 12)
      .style('cursor', 'pointer')
      .style('opacity', .3)
      .on('mouseover', (_, i, a) => { d3.select(a[i]).transition().duration(100).style('opacity', .7) })
      .on('mouseout', (_, i, a) => { d3.select(a[i]).transition().duration(100).style('opacity', .3) })
      .on('click', () => { this.search() })
    },

    // Event Call Functions ---------------------------------------------
    callFuncSearchFilter(selection) {
      selection
      .on('mouseover', (_, i, a) => {
        d3.select(a[i]).style('cursor', 'pointer')

        let directionEls_ = d3.select(a[i]).attr('id').split('__')

        d3.select(`#${this.localId}`)
        .select(`.search_circle__${directionEls_[1]}`)
        .style('stroke', this.styleFilter.over.stroke)
        .style('fill', this.styleFilter.over.fill)

        d3.select(`#${this.localId}`)
        .select(`.search_text__${directionEls_[1]}`)
        .style('fill', '#000')
      })
      .on('mouseout', (d, i, a) => { 
        d3.select(a[i]).style('cursor', 'default')

        let index_ = d.filter.values.findIndex(v_ => v_.text == d.text)
        let values_ = JSON.parse(JSON.stringify(d.filter.values))
        let directionEls_ = d3.select(a[i]).attr('id').split('__')

        if(values_[index_].on) {
          d3.select(`#${this.localId}`)
          .select(`.search_circle__${directionEls_[1]}`)
          .style('stroke', this.styleFilter.selected.stroke)
          .style('fill', this.styleFilter.selected.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.search_text__${directionEls_[1]}`)
          .style('fill', this.styleFilter.selected.color)
          
        } else {
          d3.select(`#${this.localId}`)
          .select(`.search_circle__${directionEls_[1]}`)
          .style('stroke', this.styleFilter.out.stroke)
          .style('fill', this.styleFilter.out.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.search_text__${directionEls_[1]}`)
          .style('fill', this.styleFilter.out.color)
        }
      })
      .on('click', (d, i, a) => {
        let index_ = d.filter.values.findIndex(v_ => v_.text == d.text)
        let values_ = JSON.parse(JSON.stringify(d.filter.values))
        let length_ = values_.length

        var prevIndex_ = d.filter.values.findIndex(v_ => v_.on)
        for(let i = 0; i<length_; i++) {
          if(values_[i].text == d.text) values_[i].on = true
          else values_[i].on = false
        } 

        d.filter.values = values_

        if(prevIndex_ != index_) {
          d3.select(`#${this.localId}`)
          .select(`.search_circle__${prevIndex_}`)
          .style('stroke', this.styleFilter.out.stroke)
          .style('fill', this.styleFilter.out.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.search_text__${prevIndex_}`)
          .style('fill', this.styleFilter.out.color)
        }

        if(values_[index_].on) {
          d3.select(`#${this.localId}`)
          .select(`.search_circle__${index_}`)
          .style('stroke', this.styleFilter.selected.stroke)
          .style('fill', this.styleFilter.selected.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.search_text__${index_}`)
          .style('fill', this.styleFilter.selected.color)
          
        } else {
          d3.select(`#${this.localId}`)
          .select(`.search_circle__${index_}`)
          .style('stroke', this.styleFilter.out.stroke)
          .style('fill', this.styleFilter.out.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.search_text__${index_}`)
          .style('fill', this.styleFilter.out.color)
        }

        let text = d3.select(`#${this.localId}`).select('.search_input').text()
        if (text.length > 0) {
          d3.select(`#${this.localId}`).select('.search_input').text('')
          this.search()
          // -- don't call Chart_filtering() after calling search(), 
          // -- it will be called in search() once for the right condition.
          // this.Chart_filtering()
        }
      })
    },
    callFuncSearchInput(selection) {
      selection
      .on('keydown', (_, i, a) => {
        if(d3.event.keyCode === 13){
          this.trimDivText(d3.select(a[i]))   // declared in Declares.mixins
          setTimeout(() => { this.setCaret(document.getElementById('search_input')) })
        }
        // this.register(this.search, 1000)       // declared in Declares.mixins
      })
      .on('keyup', (_, i, a) => {
        if(d3.event.keyCode === 13){
          this.trimDivText(d3.select(a[i]))
          this.setCaret(document.getElementById('search_input'))
          setTimeout(() => { this.search() }, 100)
        }
      })
    },
    callFuncLegend(selection) {
      selection
      .on('click', (d, i, a) => {
        d.on = !d.on
        if(d.on) d3.select(a[i]).style('opacity', 1)
        else d3.select(a[i]).style('opacity', 0.3)

        this.setFilter('multi', d.refColumn, d.codeValue)
        // this.search(false)
        this.Chart_filtering()
      })
    },
    callFuncFilter(selection) {
      selection
      .on('mouseover', (_, i, a) => {
        d3.select(a[i]).style('cursor', 'pointer')

        let directionEls_ = d3.select(a[i]).attr('id').split('__')

        d3.select(`#${this.localId}`)
        .select(`.option_circle__${directionEls_[1]}__${directionEls_[2]}`)
        .style('stroke', this.styleFilter.over.stroke)
        .style('fill', this.styleFilter.over.fill)

        d3.select(`#${this.localId}`)
        .select(`.option_text__${directionEls_[1]}__${directionEls_[2]}`)
        .style('fill', '#000')
      })
      .on('mouseout', (d, i, a) => { 
        d3.select(a[i]).style('cursor', 'default')

        let index_ = d.filters[d.name].values.findIndex(v_ => v_.text == d.text)
        let values_ = JSON.parse(JSON.stringify(d.filters[d.name].values))
        let directionEls_ = d3.select(a[i]).attr('id').split('__')

        if(values_[index_].on) {
          d3.select(`#${this.localId}`)
          .select(`.option_circle__${directionEls_[1]}__${directionEls_[2]}`)
          .style('stroke', this.styleFilter.selected.stroke)
          .style('fill', this.styleFilter.selected.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.option_text__${directionEls_[1]}__${directionEls_[2]}`)
          .style('fill', this.styleFilter.selected.color)
          
        } else {
          d3.select(`#${this.localId}`)
          .select(`.option_circle__${directionEls_[1]}__${directionEls_[2]}`)
          .style('stroke', this.styleFilter.out.stroke)
          .style('fill', this.styleFilter.out.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.option_text__${directionEls_[1]}__${directionEls_[2]}`)
          .style('fill', this.styleFilter.out.color)
        }
      })
      .on('click', (d, i, a) => {
        let index_ = d.filters[d.name].values.findIndex(v_ => v_.text == d.text)
        let values_ = JSON.parse(JSON.stringify(d.filters[d.name].values))
        let length_ = values_.length

        if(d.type == 'single') {
          var prevIndex_ = d.filters[d.name].values.findIndex(v_ => v_.on)
          for(let i = 0; i<length_; i++) {
            if(values_[i].text == d.text) values_[i].on = true
            else values_[i].on = false
          } 
        } else {
          values_[index_].on = !values_[index_].on
        }

        d.filters[d.name].values = values_

        let directionEls_ = d3.select(a[i]).attr('id').split('__')

        if(prevIndex_ >= 0 && prevIndex_ != index_) {
          d3.select(`#${this.localId}`)
          .select(`.option_circle__${directionEls_[1]}__${prevIndex_}`)
          .style('stroke', this.styleFilter.out.stroke)
          .style('fill', this.styleFilter.out.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.option_text__${directionEls_[1]}__${prevIndex_}`)
          .style('fill', this.styleFilter.out.color)
        }

        if(values_[index_].on) {
          d3.select(`#${this.localId}`)
          .select(`.option_circle__${directionEls_[1]}__${directionEls_[2]}`)
          .style('stroke', this.styleFilter.selected.stroke)
          .style('fill', this.styleFilter.selected.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.option_text__${directionEls_[1]}__${directionEls_[2]}`)
          .style('fill', this.styleFilter.selected.color)
          
        } else {
          d3.select(`#${this.localId}`)
          .select(`.option_circle__${directionEls_[1]}__${directionEls_[2]}`)
          .style('stroke', this.styleFilter.out.stroke)
          .style('fill', this.styleFilter.out.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.option_text__${directionEls_[1]}__${directionEls_[2]}`)
          .style('fill', this.styleFilter.out.color)
        }

        if(d.colName == 'DATE') {
          if(this.activity == d.value) return 

          this.activity = d.value
          this.activityChanged()

        } else {
          // this.setFilter(d.type, d.colName, d.value)
          // this.search(false)
          // this.Chart_filtering()
        }
      })
    },
  }
}
