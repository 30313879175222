import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  data:() =>({
    actualText1:null,
    actualText2:null
  }),
  methods: {  
    Draw_Charts() {
      
      let data = null
      let procedures = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0)`)

      let style = {
        CircleCenterX: 150,
        CircleCenterY: 170,

        ra: 80,
        angleSize: 180,
        angleStart: 270,
        angleEnd: 450,

        bgColor: '#F4F5F5',
        ftColor: '#44A9DF',
        stroke: 25,

        needleColor: '#333',
        proColor: '#F48FB1',

        percentage: this.dataSet[0].PROG,
      }
      this.Chart_Gauge_01(procedures, style) // ---------------------------->
      
      let boxW = 54

      let TableNameD = [...new Set(this.DataItems.map(d=>d.NAME))]
      this.actualText1 = TableNameD == 'Daily' ? 'Yesterday' : 'Last Week'
      this.actualText2 = TableNameD == 'Daily' ? 'Today' : 'This Week'

      let proceduresDate = procedures
      .append('g')
      .attr('transform','translate(116,40)')

      proceduresDate.append('rect')
      .attr('x', -boxW).attr('y', 6).attr('rx', 3).attr('ry', 3).attr('width', boxW).attr('height', 20).attr('fill', '#64B5F6')
      proceduresDate
      .append('text')
      .attr('transform', `translate(-27,0)`).style('font-family', 'roboto').style('font-size', 11)
      .attr('fill', '#616161').attr('text-anchor', 'middle').attr('alignment-baseline', 'end').text(this.actualText1)
      proceduresDate
      .append('text')
      .attr('transform', `translate(-28,20)`).style('font-family', 'roboto').style('font-size', 11)
      .attr('fill', '#fff').attr('text-anchor', 'middle').attr('alignment-baseline', 'end').text(this.dataSet[0].YESTERDAY)

      proceduresDate
      .append('text')
      .attr('transform', `translate(30,20)`).style('font-family', 'roboto').style('font-size', 11)
      .attr('fill', this.dataSet[0].DELTA > 0 ? '#64B5F6' :this.dataSet[0].DELTA === 0 ? '#BDBDBD' :'#F44336').attr('text-anchor', 'middle').attr('alignment-baseline', 'end')
      // .text(`${this.dataSet[0].DELTA > 0 ? '▲ ' : this.dataSet[0].DELTA === 0 ? 0: '▼ '}` + Number(this.dataSet[0].DELTA).toFixed(0))
      .text(this.dataSet[0].DELTA == null ? 0 : this.dataSet[0].DELTA > 0 ? '▲ '+ this.dataSet[0].DELTA : this.dataSet[0].DELTA == 0 ? '- '+ this.dataSet[0].DELTA :'▼ '+ this.dataSet[0].DELTA)

      proceduresDate.append('rect')
      .attr('x', boxW+5).attr('y', 6).attr('rx', 3).attr('ry', 3).attr('width', boxW).attr('height', 20).attr('fill', '#64B5F6')
      proceduresDate
      .append('text')
      .attr('transform', `translate(85,0)`).style('font-family', 'roboto').style('font-size', 11)
      .attr('fill', '#616161').attr('text-anchor', 'middle').attr('alignment-baseline', 'end').text(this.actualText2)
      proceduresDate
      .append('text')
      .attr('transform', `translate(86,20)`).style('font-family', 'roboto').style('font-size', 11)
      .attr('fill', '#fff').attr('text-anchor', 'middle').attr('alignment-baseline', 'end').text(this.dataSet[0].TODAY)

      let proceduresText = procedures
      .append('g')
      .attr('transform','translate(150,150)')
      
      proceduresText
      .append('text')
      .attr('transform', `translate(-10,75)`).style('font-family', 'roboto').style('font-size', 11)
      .attr('fill', '#616161').attr('text-anchor', 'end').attr('alignment-baseline', 'end').text('Total')

      proceduresText
      .append('text')
      .attr('transform', `translate(-10,105)`).style('font-family', 'roboto').style('font-size', 11)
      .attr('fill', '#616161').attr('text-anchor', 'end').attr('alignment-baseline', 'end').text('Completed')
      
      proceduresText
      .append('text')
      .attr('transform', `translate(-10,135)`).style('font-family', 'roboto').style('font-size', 11)
      .attr('fill', '#616161').attr('text-anchor', 'end').attr('alignment-baseline', 'end').text('Remain')

      proceduresText.append('rect')
      .attr('x', 0).attr('y', 61).attr('rx', 3).attr('ry', 3).attr('width', boxW).attr('height', 20).attr('fill', '#EEEEEE')
      proceduresText
      .append('text')
      .attr('transform', `translate(38,75)`).style('font-family', 'roboto').style('font-size', 11)
      .attr('fill', '#424242').attr('text-anchor', 'end').attr('alignment-baseline', 'end').text(this.dataSet[0].TOTAL)

      proceduresText.append('rect')
      .attr('x', 0).attr('y', 91).attr('rx', 3).attr('ry', 3).attr('width', boxW).attr('height', 20).attr('fill', '#64B5F6')
      proceduresText
      .append('text')
      .attr('transform', `translate(38,105)`).style('font-family', 'roboto').style('font-size', 11)
      .attr('fill', '#fff').attr('text-anchor', 'end').attr('alignment-baseline', 'end').text(this.dataSet[0].COMPLETED)

      proceduresText.append('rect')
      .attr('x', 0).attr('y', 121).attr('rx', 3).attr('ry', 3).attr('width', boxW).attr('height', 20).attr('fill', 'Transparent').attr('stroke', '#ccc').attr('stroke-width',0.5)
      proceduresText
      .append('text')
      .attr('transform', `translate(38,135)`).style('font-family', 'roboto').style('font-size', 11)
      .attr('fill', '#F44336').attr('text-anchor', 'end').attr('alignment-baseline', 'end').text(this.dataSet[0].REMAIN)
    },
  }
}
