import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({

    Cert: [
      {name: 'Walkdown' , code: 'WD',  tColor: '#ffffff', x: 0,    },
      {name: 'MCC'      , code: 'FMC', tColor: '#E0E0DF', x: 101.6,},
    ]
  }),


  methods: {
    
    drawCertificate() {

      this.Cert.forEach(d => {
        d.Total = this.DataItems[0][`${d.code}_T`]
        d.Actual = this.DataItems[0][`${d.code}_A`]
        d.Remain = this.DataItems[0][`${d.code}_T`] - this.DataItems[0][`${d.code}_A`]
        d.Prog = (this.DataItems[0][`${d.code}_A`] / this.DataItems[0][`${d.code}_T`]) * 100
        d.len = 115 * (this.DataItems[0][`${d.code}_A`] / this.DataItems[0][`${d.code}_T`])
        d.y = 30 + ((1-(this.DataItems[0][`${d.code}_A`] / this.DataItems[0][`${d.code}_T`]))*115)
      })

      let color_ = this.svg
      .append('defs')
      .append('linearGradient')
      .attr('id', `color_`)
      .attr('x1', 0).attr('y1', 0.5).attr('x2', 1).attr('y2', 0.5)
      color_.append('stop').attr('stop-color', '#ec407a').attr('offset', '0')
      color_.append('stop').attr('stop-color', '#76203d').attr('offset', '1')
      
      let Certifiates = this.svg
      .append('g')
      .attr('id', `a`)
      .attr('transform', `translate(310,0.5)`)

      this.Cert.forEach((d,i) => {
        Certifiates
        .append('rect')
        .attr('x', d.x).attr('y', 0).attr('rx', 5).attr('ry', 5).attr('width', 96.6).attr('height', 150).attr('fill', '#EE6090')

        Certifiates
        .append('text')
        .attr('x', d.x +15).attr('y', 13).attr('fill', '#ffffff') .style('font-size', 10).style('font-family', 'roboto').attr('text-anchor', 'start').text(d.name)

        Certifiates
        .append('rect')
        .attr('x', d.x+20).attr('y', 30).attr('rx', 5).attr('ry', 5).attr('width', 32).attr('height', 115).attr('fill', '#F7BACF').attr('opacity', 0.3)

        Certifiates
        .append('rect')
        .attr('x', d.x+20).attr('y', d.y).attr('rx', 5).attr('ry', 5).attr('width', 32).attr('height', d.len).attr('fill', 'url(#color_)')


        Certifiates
        .append('line')
        .attr('x1', d.x+55).attr('y1', d.y).attr('x2', d.x+60).attr('y2', d.y).attr('stroke-width', 0.5).attr('stroke', '#F7BACF')

        Certifiates
        .append('line')
        .attr('x1', d.x+55).attr('y1', 30).attr('x2', d.x+60).attr('y2', 30).attr('stroke-width', 0.5).attr('stroke', '#F7BACF')

        Certifiates
        .append('line')
        .attr('x1', d.x+55).attr('y1', 145).attr('x2', d.x+60).attr('y2', 145).attr('stroke-width', 0.5).attr('stroke', '#F7BACF')

        Certifiates
        .append('line')
        .attr('x1', d.x+57).attr('y1', 30).attr('x2', d.x+57).attr('y2', 145).attr('stroke-width', 0.5).attr('stroke', '#F7BACF')

        // Values
        Certifiates
        .append('text')
        .attr('x', d.x + 36).attr('y', d.y + 12).attr('fill', '#ffffff') .style('font-size', 10).style('font-family', 'roboto').attr('text-anchor', 'middle').text(`${d.Prog.toFixed(1)}%`)

        Certifiates
        .append('text')
        .attr('id', `FDMCWD_${i}`)
        .attr('transform', `translate(${d.x + 36}, 25)`)
        .attr('fill', '#333') .style('font-size', 9).style('font-family', 'roboto').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle').text(d.Total)
        .style('cursor', 'pointer')
        .on('mouseover', () => {
          d3.select(`#FDMCWD_${i}`).transition().duration(100).attr('transform', `translate(${d.x + 36}, 25) scale(1.2)`)
        })
        .on('mouseout', () => {
          d3.select(`#FDMCWD_${i}`).transition().duration(100).attr('transform', `translate(${d.x + 36}, 25)`)
        })
        .on('click', () => {
          this.chartsActions(d.name, 'total')
        })

        Certifiates
        .append('text')
        .attr('id', `FM-DAC1_${i}`)
        .attr('transform', `translate(${d.x + 62}, ${30 + ((d.y-30)/2)})`)
        .attr('fill', '#FCE3EB') .style('font-size', 9).style('font-family', 'roboto').attr('text-anchor', 'start').attr('alignment-baseline', 'middle').text(d.Remain)
        .style('cursor', 'pointer')
        .on('mouseover', () => {
          d3.select(`#FM-DAC1_${i}`).transition().duration(100).attr('transform', `translate(${d.x + 62}, ${30 + ((d.y-30)/2)}) scale(1.2)`)
        })
        .on('mouseout', () => {
          d3.select(`#FM-DAC1_${i}`).transition().duration(100).attr('transform', `translate(${d.x + 62}, ${30 + ((d.y-30)/2)})`)
        })
        .on('click', () => {
          this.chartsActions(d.name, 'remain')
        })

        Certifiates
        .append('text')
        .attr('id', `FMC1_${i}`)
        .attr('transform', `translate(${d.x + 62}, ${d.y + ((145-d.y)/2)})`)
        .attr('fill', '#FCE3EB') .style('font-size', 9).style('font-family', 'roboto').attr('text-anchor', 'start').attr('alignment-baseline', 'middle').text(d.Actual)
        .style('cursor', 'pointer')
        .on('mouseover', () => {
          d3.select(`#FMC1_${i}`).transition().duration(100).attr('transform', `translate(${d.x + 62}, ${d.y + ((145-d.y)/2)}) scale(1.2)`)
        })
        .on('mouseout', () => {
          d3.select(`#FMC1_${i}`).transition().duration(100).attr('transform', `translate(${d.x + 62}, ${d.y + ((145-d.y)/2)})`)
        })
        .on('click', () => {
          this.chartsActions(d.name, 'actual')
        })
      })
    },



    chartsActions(name, status) {
      if (status == 'total') {
        
        let request_ = {
          dataType: 'row',
          action: {
            type: 'direct',
            target: 'slide-modal',
            component: 'ServiceDatatable',
            id: 72,
            no: ''
          },
          filters     : {
          },
          iFilters    : {
            filterString : `B.RFS = '2020' AND CERT_TYPE = '${name}'`,
            inputFilter  : ''
          }
        }
        this.$emit('request-action', request_)
      }

      if (status == 'actual') {
        let request_ = {
          dataType: 'row',
          action: {
            type: 'direct',
            target: 'slide-modal',
            component: 'ServiceDatatable',
            id: 72,
            no: ''
          },
          filters     : {
          },
          iFilters    : {
            filterString : `B.RFS = 2020 AND CERT_TYPE = '${name}' AND STATUS = 'Cert Accepted'`,
            inputFilter  : ''
          }
        }
        this.$emit('request-action', request_)
      }

      if (status == 'remain') {
        let request_ = {
          dataType: 'row',
          action: {
            type: 'direct',
            target: 'slide-modal',
            component: 'ServiceDatatable',
            id: 72,
            no: ''
          },
          filters     : {
          },
          iFilters    : {
            filterString : `B.RFS = '2020' AND CERT_TYPE = '${name}' AND STATUS = 'Cert Not Started'`,
            inputFilter  : ''
          }
        }
        this.$emit('request-action', request_)
      }
    },
    

  }
}
