import * as d3 from 'd3'
import __M from 'moment'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({
    thead: [     
      { key: 'MONTH', html: 'Month', row: 1, rowspan: 1 , color: '#E0E0E0'},
      { key: 'PRIORITY', html: 'Priority', row: 1, rowspan: 1, color: '#E0E0E0' },
      { key: 'SUBSYSTEM', html: 'Subysystem No.', row: 1, rowspan: 1 , color: '#E0E0E0'},
      { key: 'SUBSYSTEM_DESCR', html: 'Subsystem Description', row: 1, rowspan: 1 , color: '#E0E0E0'},
      { key: 'WD_PLAN', html: 'WD Plan', row: 1, rowspan: 1 , color: '#E0E0E0'},
      { key: 'WD_ACTUAL_DATE', html: 'WD Actual', row: 1, rowspan: 1 , color: '#E0E0E0'},
      { key: 'WD_DELTA', html: 'WD Delta', row: 1, rowspan: 1 , color: '#E0E0E0'},
      { key: 'WD_TIMES', html: 'WD Times', row: 1, rowspan: 1 , color: '#E0E0E0'},
      { key: 'MC_APPROVED', html: 'MC Approval', row: 1, rowspan: 1 , color: '#E0E0E0'},
      { key: 'WD_TO_MC_WK', html: 'Weeks', row: 1, rowspan: 1 , color: '#E0E0E0'},
      { key: 'WD_TO_MC_DAYS', html: 'Days', row: 1, rowspan: 1 , color: '#E0E0E0'},
      { key: 'BAR', html: '2 weeks', row: 1, rowspan: 1 , color: '#C8E6C9'},
      { key: '', html: '4 weeks', row: 1, rowspan: 1 , color: '#FFECB3'},
      { key: '', html: '6 weeks', row: 1, rowspan: 1 , color: '#FFD54F'},
      { key: '', html: '8 weeks', row: 1, rowspan: 1 , color: '#FFB74D'},
      { key: '', html: '10 weeks', row: 1, rowspan: 1 , color: '#FFCDD2'},
      { key: '', html: '12 weeks', row: 1, rowspan: 1 , color: '#EF9A9A'},
      { key: '', html: '> 12 weeks', row: 1, rowspan: 1 , color: '#E57373'},
    ],
    theadrows: 1,
    tbody: [
      { key: 'MONTH', w: 65 },
      { key: 'PRIORITY', w: 50 },
      { key: 'SUBSYSTEM', w: 100, background: '#FAFAFA'},
      { key: 'SUBSYSTEM_DESCR', w: 230, align: 'left' },
      { key: 'WD_PLAN', w: 80 },
      { key: 'WD_ACTUAL_DATE', w: 80 },
      { key: 'WD_DELTA', w: 50 },
      { key: 'WD_TIMES', w: 60 },
      { key: 'MC_APPROVED', w: 80 },
      { key: 'WD_TO_MC_WK', w: 50 },
      { key: 'WD_TO_MC_DAYS', w: 50 },
      { key: 'BAR', w: 70 },
      { key: '', w: 70 },
      { key: '', w: 70 },
      { key: '', w: 70 },
      { key: '', w: 70 },
      { key: '', w: 70 },
      { key: '', w: 70 },
    ],
    width: 1260,
    selectedStartDate: null,
    selectedStartColumn: null,
    selectedFinishDate: null,
    selectedFinishColumn: null,
    filteredList: null
  }),
  methods: {
    drawTable() {
      let svg = this.svg
      this.filteredList = this.DataItems
      let list = this.filteredList
      let this_ = this
      
      let getOptionList = (column) => {
        let optionList = JSON.parse(JSON.stringify(this.DataItems))

        let sorted = optionList.sort(function(a,b){
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(a[column]) - new Date(b[column])
        }).filter(d => d[column] != null)
        return [...new Set(sorted.map(d => __M(new Date(d[column])).format('DD-MMM-YY')))]
      }
      
      let group = svg.append('g').attr('class', 'ogm_table_group__')
      
      let table_group = group.append('g').attr('transform', `translate(0.5, 30)`)
      let actionColumns = ['SUBSYSTEM']
      
      let table_obj = table_group.append('foreignObject')
        .attr('y',0)
        .attr('width', '100%')
        .attr('height', '100%')
        .append('xhtml:body')
        .style('position', 'fixed')

      let table = table_obj.append('table')
      .attr('id', 'commodity_summary_table_')
      .style('border-collapse', 'collapse')
      .style('font-size', '10px')
      .style('width', '1000px')
      .style('table-layout', 'fixed')

      let colgroup = table.append('colgroup')
      this.tbody.forEach(k => {
        colgroup.append('col').style('width', k.w + 'px')
      })

      let thead = table.append('thead')
      for (let r = 1; r <= this.theadrows; r++) {
        let row = this.thead.filter(d=> d.row === r)
        let tr = thead.append('tr')

        row.forEach(item => {
            tr.append('th')
            .attr('class', 'ogm_table_group__th')
            .attr('rowspan', () => item.rowspan ? item.rowspan : '')
            .attr('colspan', () => item.colspan ? item.colspan : '')
            .style('border', 'solid 0.7px #eee')
            .style('height', '25px')
            .style('padding', '3px')
            .style('background', item.color ? item.color : '#FAFAFA')
            .html(item.html)
        })        
      }
        
      let tbodykeys = this.tbody

      let disc = ''
      
      let tbody = table.append('tbody').attr('id', 'commodity_summay_tbody_')

      let addClass = ''

      for (let r = 0; r < list.length; r++) {
        let tr = tbody.append('tr')
        tbodykeys.forEach((k, i) => {

          if (list[r][k.key] == disc) return
          else {
            if (i == 0) {
              disc = list[r][k.key]
            }
          }

          let td = tr.append('td')
            .style('border', 'solid 0.1px #eee')
            .style('height', '23px')
            .style('text-align', k.align ? k.align : 'center')
            .style('padding', '0 3px 0 3px')
            .style('background', k.background ? k.background : 'none')
            .attr('rowspan', () => {
              if (i == 0) {
                let filtered = list.filter(d => d.MONTH == list[r][k.key])
                return filtered.length
              }
            })

          if(i === 3) {
            td.style('white-space', 'nowrap')
              .style('overflow', 'hidden')
              .style('text-overflow', 'ellipsis')
              .attr('title', list[r][k.key])
          }
          if(i === 6) {
            td.style('color', list[r][k.key] < 0 ? '#2196F3' :  '#D50000')
          }
          if(i === 7) {
            td.style('font-weight', list[r][k.key] > 1 ? 'bold' :  'normal')
          }
          if(actionColumns.includes(k.key)) {
            td.style('text-decoration', 'underline').style('color','#039BE5')
            td.on('mouseover', () => td.style('cursor', 'pointer').style('opacity',0.5))
            td.on('mouseout', () => td.style('cursor', 'pointer').style('opacity',1))
            td.on('click', () => {
              // console.log(k.key, list[r])
              let request_ = this.getRequestColumnProps('SUBSYSTEM', { SUBSYSTEM: list[r].SUBSYSTEM }, this.ColumnProps)
              
              this.$emit('request-action', request_)
            })
          }
          if(k.key.includes('PROG')) {
            addClass = ''
            if (k.key.includes('ACTUAL_PROG')) {
              let diff = list[r]['PLAN_PROG'] - list[r]['ACTUAL_PROG']
              // addClass = diff >= 30 ? 'caution' : ''
            }
            td
            .style('position', 'relative')
            .style('color', list[r][k.key] < 0 ? 'red' : list[r][k.key] === 100 ? '#fff' : addClass == 'caution' ? 'red' : '#000')
            .style('z-index', '1')
            .html(list[r][k.key] == null ? 0+'%' :list[r][k.key]+'%')

            td.append('div')
            .style('position', 'absolute')
            .style('left', 0)
            .style('top', 0)
            .style('width', list[r][k.key] > 0 ? list[r][k.key] + '%' : 0)
            .style('height', '22px')
            .style('background-color', list[r][k.key] < 100 ? k.key.includes('PLAN') ? '#E0E0E0' : '#FFCA28' : '#4CAF50')
            .style('z-index', '-1')
          }
          if(k.key.includes('BAR')) {
            addClass = 490
            td
            .style('position', 'relative')
            // .style('color', list[r][k.key] < 0 ? 'red' : list[r][k.key] === 100 ? '#fff' : addClass == 'caution' ? 'red' : '#000')
            .style('z-index', '1')
            // .html(list[r][k.key] == null ? 0 :list[r][k.key])
            td.append('div')
            .style('position', 'absolute')
            .style('left', 0)
            .style('top', 0)
            // .style('width', list[r][k.key] > 98 ? 490+ 'PX' : 5 * list[r][k.key] + 'PX')
            .style('width', list[r][k.key] > 98 ? 490+ 'PX' : list[r][k.key] <= 14 ? 70+ 'PX' : list[r][k.key] <= 28 ? 140+ 'PX' :list[r][k.key] <= 42 ? 210+ 'PX': list[r][k.key] <= 56 ? 280+ 'PX':list[r][k.key] <= 70 ? 350+ 'PX':list[r][k.key] <= 84 ? 420+ 'PX':5 * list[r][k.key] + 'PX')
            .style('height', '22px').style('z-index', '-1')
            // .style('background-color', list[r][k.key] < 100 ? k.key.includes('PLAN') ? '#E0E0E0' : '#FFCA28' : '#4CAF50')
            .style('background-color', list[r][k.key] <= 14 ? '#C8E6C9' : list[r][k.key] <= 28 ? '#FFECB3' : list[r][k.key] <= 42 ? '#FFD54F' : list[r][k.key] <= 56 ? '#FFB74D' : list[r][k.key] <= 70 ?'#FFCDD2': list[r][k.key] <= 84 ? '#EF9A9A' : '#E57373')
            
          }
          else {
            if (k.type == 'float') {
              let regexp = /\B(?=(\d{3})+(?!\d))/g
              if (isNaN(Math.floor(list[r][k.key]))) td.html('')
              else td.html((Math.floor(list[r][k.key] * 100) / 100).toString().replace(regexp, ','))
            } else td.html(list[r][k.key])
          }
        })
      }
    },
    // getFilteredList() {
    //   this.filteredList = null
    //   this.filteredList = this.DataItems.filter(d => {
    //     if (this.selectedStartDate && this.selectedFinishDate) return d[this.selectedStartColumn] >= this.selectedStartDate && d[this.selectedFinishColumn] <= this.selectedFinishDate
    //     else if (this.selectedStartDate && !this.selectedFinishDate) return d[this.selectedStartColumn] >= this.selectedStartDate
    //     else if (this.selectedFinishDate && !this.selectedStartDate) return d[this.selectedFinishColumn] <= this.selectedFinishDate
    //     else return this.DataItems
    //   })

    //   // this.redrawTable()
    //   // this.draw_Timeline2021()
    // },

    redrawTable() {
      d3.select('#commodity_summay_tbody_').remove()

      let list = this.filteredList

      let tbodykeys = this.tbody

      let disc = ''
      
      let tbody = d3.select('#commodity_summary_table_').append('tbody').attr('id', 'commodity_summay_tbody_')

      for (let r = 0; r < list.length; r++) {
        let tr = tbody.append('tr')

        tbodykeys.forEach((k, i) => {

          if (list[r][k.key] == disc) return
          else {
            if (i == 0) {
              disc = list[r][k.key]
            }
          }

          let td = tr.append('td')
            .style('border', 'solid 0.1px #eee')
            .style('height', '23px')
            .style('text-align', k.align ? k.align : 'center')
            .style('padding', '0 3px 0 3px')
            .attr('rowspan', () => {
              if (i == 0) {
                let filtered = list.filter(d => d.MONTH == list[r][k.key])
                return filtered.length
              }
            })

          if(i === 2) {
            td.style('white-space', 'nowrap')
              .style('overflow', 'hidden')
              .style('text-overflow', 'ellipsis')
              .attr('title', list[r][k.key])
          }

          td.on('mouseover', () => tr.style('cursor', 'pointer'))
          td.on('click', () => {
            let dataSource = {
              GWBS_LV2: list[r].GWBS_LV2,
              GWBS_LV3: list[r].GWBS_LV3,
              WCCODE: list[r].WCCODE
            }
            let request_ = this.getRequestColumnProps('status', dataSource, this.ColumnProps)
            this.$emit('request-action', request_)
          })

          if(k.key.includes('PROG')) {
            td
            .style('position', 'relative')
            .style('color', list[r][k.key] < 0 ? 'red' : list[r][k.key] === 100 ? '#fff' : '#000')
            .style('z-index', '1')
            .html(list[r][k.key]+'%')

            td.append('div')
            .style('position', 'absolute')
            .style('left', 0)
            .style('top', 0)
            .style('width', list[r][k.key] > 0 ? list[r][k.key] + '%' : 0)
            .style('height', '90%')
            .style('background-color', list[r][k.key] < 100 ? k.key.includes('PLAN') ? '#E0E0E0' : '#FFCA28' : '#4CAF50')
            .style('z-index', '-1')
          }
          else {
            td.html(list[r][k.key])
          }
        })
      }
    }
  }
}
