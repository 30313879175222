import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  data: () => ({
    legend: 
    [
      { column: 'PILING_PLAN_CUM', color: '#E57373', shape: 'line', text: 'Piling Plan', axis: 'left', lineShape: 'dash', x: 0, y: 0 },
      { column: 'PILING_ACT_CUM', color: '#D32F2F', shape: 'line', text: 'Piling Act.', axis: 'left', x: 0, y: 13  },
      { column: 'UG_PLAN_CUM', color: '#F06292', shape: 'line', text: 'UG Plan', axis: 'left', lineShape: 'dash', x: 0, y: 26 },
      { column: 'UG_ACT_CUM', color: '#C2185B', shape: 'line', text: 'UG Act.', axis: 'left', x: 0, y: 39 },
      { column: 'STI_PLAN_CUM', color: '#9575CD', shape: 'line', text: 'STI Plan', axis: 'left', lineShape: 'dash', x: 0, y: 52},
      { column: 'STI_ACT_CUM', color: '#512DA8', shape: 'line', text: 'STI Act.', axis: 'left', x: 0, y: 65 },
      { column: 'FP_PLAN_CUM', color: '#7986CB', shape: 'line', text: 'FF Plan', axis: 'left', lineShape: 'dash', x: 0, y: 78 },
      { column: 'FP_ACT_CUM', color: '#303F9F', shape: 'line', text: 'FF Act.', axis: 'left', lineShape: 'dash', x: 0, y: 91},
      { column: 'PIPI_PLAN_CUM', color: '#64B5F6', shape: 'line', text: 'Pip. Install. Plan', axis: 'left', lineShape: 'dash', x: 0, y: 104 },
      { column: 'PIPI_ACT_CUM', color: '#1976D2', shape: 'line', text: 'Pip. Install. Act.', axis: 'left', lineShape: 'dash', x: 0, y: 117},
      { column: 'CONCF_PLAN_CUM', color: '#4FC3F7', shape: 'line', text: 'Concrete Components Fab. Plan', axis: 'left', lineShape: 'dash', x: 0, y: 130 },
      { column: 'CONCF_ACT_CUM', color: '#0288D1', shape: 'line', text: 'Concrete Components Fab. Act.', axis: 'left', lineShape: 'dash', x: 0, y: 143},
      { column: 'PNLI_PLAN_CUM', color: '#80DEEA', shape: 'line', text: 'Panel Install. Plan', axis: 'left', lineShape: 'dash', x: 0, y: 156 },
      { column: 'PNLI_ACT_CUM', color: '#0097A7', shape: 'line', text: 'Panel Install. Act.', axis: 'left', lineShape: 'dash', x: 0, y: 169},
      { column: 'EIEQ_PLAN_CUM', color: '#4DB6AC', shape: 'line', text: 'E&I EQ Plan', axis: 'left', lineShape: 'dash', x: 0, y: 182 },
      { column: 'EIEQ_ACT_CUM', color: '#00796B', shape: 'line', text: 'E&I EQ Act.', axis: 'left', lineShape: 'dash', x: 0, y: 195},
      { column: 'STLBI_PLAN_CUM', color: '#81C784', shape: 'line', text: 'Steel Beam Install. Plan', axis: 'left', lineShape: 'dash', x: 0, y: 208 },
      { column: 'STLBI_ACT_CUM', color: '#388E3C', shape: 'line', text: 'Steel Beam Install. Act.', axis: 'left', lineShape: 'dash', x: 0, y: 221},
      { column: 'ITR_PLAN_CUM', color: '#AED581', shape: 'line', text: 'ITR Plan', axis: 'left', lineShape: 'dash', x: 0, y: 234 },
      { column: 'ITR_ACT_CUM', color: '#689F38', shape: 'line', text: 'ITR Act.', axis: 'left', lineShape: 'dash', x: 0, y: 247},
      { column: 'CONPUR_PLAN_CUM', color: '#DCE775', shape: 'line', text: 'Concrete Pouring Plan', axis: 'left', lineShape: 'dash', x: 0, y: 260 },
      { column: 'CONPUR_ACT_CUM', color: '#AFB42B', shape: 'line', text: 'Concrete Pouring Act.', axis: 'left', lineShape: 'dash', x: 0, y: 273},
      { column: 'CABLY_PLAN_CUM', color: '#FFD54F', shape: 'line', text: 'Cable Laying Plan', axis: 'left', lineShape: 'dash', x: 0, y: 286 },
      { column: 'CABLY_ACT_CUM', color: '#FFA000', shape: 'line', text: 'Cable Laying Act.', axis: 'left', lineShape: 'dash', x: 0, y:299},
      { column: 'CABTER_PLAN_CUM', color: '#FFB74D', shape: 'line', text: 'Cable Ter. Plan', axis: 'left', lineShape: 'dash', x: 0, y: 312 },
      { column: 'CABTER_ACT_CUM', color: '#F57C00', shape: 'line', text: 'Cable Ter. Act.', axis: 'left', lineShape: 'dash', x: 0, y: 325},
      { column: 'MAEQ_PLAN_CUM', color: '#FF8A65', shape: 'line', text: 'MA Equip. Plan', axis: 'left', lineShape: 'dash', x: 0, y: 338 },
      { column: 'MAEQ_ACT_CUM', color: '#E64A19', shape: 'line', text: 'MA Equip. Act.', axis: 'left', lineShape: 'dash', x: 0, y: 351},
      { column: 'PIPFAB_PLAN_CUM', color: '#A1887F', shape: 'line', text: 'Pip. Fab. Plan', axis: 'left', lineShape: 'dash', x: 0, y: 364 },
      { column: 'PIPFAB_ACT_CUM', color: '#5D4037', shape: 'line', text: 'Pip. Fab. Act.', axis: 'left', lineShape: 'dash', x: 0, y: 377},
      { column: 'SPI_PLAN_CUM', color: '#90A4AE', shape: 'line', text: 'Steel Platform Install. Plan', axis: 'left', lineShape: 'dash', x: 0, y: 390 },
      { column: 'SPI_ACT_CUM', color: '#455A64', shape: 'line', text: 'Steel Platform Install. Act.', axis: 'left', lineShape: 'dash', x: 0, y: 403},
    ],
    margin: { top: 70, left: 50, bottom: 50, right: 50 },
    width: 1020,
    height: 380,
    filter: []
  }),
  methods: {
    Draw_Charts() {
      const list = this.DataItems
        .map(v => {
          return {
            CATEGORY: v['WK_NO'],
            VALUES: [
              { KEY: 'PILING_PLAN_CUM', VALUE: v['PILING_PLAN_CUM'] },
              { KEY: 'PILING_ACT_CUM', VALUE: v['PILING_ACT_CUM'] },
              { KEY: 'UG_PLAN_CUM', VALUE: v['UG_PLAN_CUM'] },
              { KEY: 'UG_ACT_CUM', VALUE: v['UG_ACT_CUM'] },
              { KEY: 'STI_PLAN_CUM', VALUE: v['STI_PLAN_CUM'] },
              { KEY: 'STI_ACT_CUM', VALUE: v['STI_ACT_CUM'] },
              { KEY: 'FP_PLAN_CUM', VALUE: v['FP_PLAN_CUM'] },
              { KEY: 'FP_ACT_CUM', VALUE: v['FP_ACT_CUM'] },             
              { KEY: 'PIPI_PLAN_CUM', VALUE: v['PIPI_PLAN_CUM'] },
              { KEY: 'PIPI_ACT_CUM', VALUE: v['PIPI_ACT_CUM'] },
              { KEY: 'CONCF_PLAN_CUM', VALUE: v['CONCF_PLAN_CUM'] },
              { KEY: 'CONCF_ACT_CUM', VALUE: v['CONCF_ACT_CUM'] },
              { KEY: 'PNLI_PLAN_CUM', VALUE: v['PNLI_PLAN_CUM'] },
              { KEY: 'PNLI_ACT_CUM', VALUE: v['PNLI_ACT_CUM'] },
              { KEY: 'EIEQ_PLAN_CUM', VALUE: v['EIEQ_PLAN_CUM'] },
              { KEY: 'EIEQ_ACT_CUM', VALUE: v['EIEQ_ACT_CUM'] },
              { KEY: 'STLBI_PLAN_CUM', VALUE: v['STLBI_PLAN_CUM'] },
              { KEY: 'STLBI_ACT_CUM', VALUE: v['STLBI_ACT_CUM'] },
              { KEY: 'ITR_PLAN_CUM', VALUE: v['ITR_PLAN_CUM'] },
              { KEY: 'ITR_ACT_CUM', VALUE: v['ITR_ACT_CUM'] },
              { KEY: 'CONPUR_PLAN_CUM', VALUE: v['CONPUR_PLAN_CUM'] },
              { KEY: 'CONPUR_ACT_CUM', VALUE: v['CONPUR_ACT_CUM'] },
              { KEY: 'CABLY_PLAN_CUM', VALUE: v['CABLY_PLAN_CUM'] },
              { KEY: 'CABLY_ACT_CUM', VALUE: v['CABLY_ACT_CUM'] },
              { KEY: 'CABTER_PLAN_CUM', VALUE: v['CABTER_PLAN_CUM'] },
              { KEY: 'CABTER_ACT_CUM', VALUE: v['CABTER_ACT_CUM'] },
              { KEY: 'MAEQ_PLAN_CUM', VALUE: v['MAEQ_PLAN_CUM'] },
              { KEY: 'MAEQ_ACT_CUM', VALUE: v['MAEQ_ACT_CUM'] },
              { KEY: 'PIPFAB_PLAN_CUM', VALUE: v['PIPFAB_PLAN_CUM'] },
              { KEY: 'PIPFAB_ACT_CUM', VALUE: v['PIPFAB_ACT_CUM'] },
              { KEY: 'SPI_PLAN_CUM', VALUE: v['SPI_PLAN_CUM'] },
              { KEY: 'SPI_ACT_CUM', VALUE: v['SPI_ACT_CUM'] },
            ]
          }
        })

      this.filter = this.legend.map(d => d.column)

      const width = this.width - 30
      const height = this.height - 15

      const svg = this.svg
        .append('svg')
        .attr('width', `${width}`)
        .attr('height', `${height}`)

      svg
        .append('g')
        .attr('transform', `translate(${50},${70})`)

      // let packcage = this.DataItems.map(d=>d.AREA)[0]
      // let pItem =this.items.filter(d=>d.code==packcage)[0]

      let legendGoup = svg
        .append('g').attr('class','legendGoup')
        .attr('transform', `translate(1000,0)`)

      const legendName = this.legend
      const legends = legendGoup.selectAll('.legend').data(legendName)

      const filter = this.filter
      const render = this.render

      const legend = legends
        .enter()
        .append('g')
        .attr('class', 'legend')
        .attr('transform', (d, i) => {
          return `translate(${d.x}, ${d.y})`
        })
        .style('user-select', 'none')
        .on('click', function (d) {
          const el = d3.select(this)
          const text = el.select('text')
          const rect = el.select('rect')
          const column = d.column
          if (!filter.includes(column)) {
            text.attr('fill', '#000')
            rect.style('fill', d.color)
            filter.push(column)
          } else {
            text.attr('fill', '#efefef')
            rect.style('fill', '#efefef')
            const idx = filter.indexOf(column)
            filter.splice(idx, 1)
          }

          const newValue = list.map(d => {
            const obj = {
              CATEGORY: d['CATEGORY'],
              VALUES: d['VALUES'].filter(v => filter.includes(v['KEY']))
            }
            return obj
          })
          render(newValue)
          
        })

      legend.append('rect')
        .attr('x', 0)
        .attr('width', 14)
        .attr('height', d => d.shape === 'line' ? 2 : 6)
        .attr('y', d => d.shape === 'line' ? 4 : 4)
        .style('fill', d => d.color)
        

      legend.append('text')
        .attr('x', 16)
        .attr('y', 6)
        .attr('dy', '.35em')
        .attr('text-anchor', 'start')
        .attr('font-size', '9px')
        .attr('font-family', 'arial')
        .style('cursor', 'pointer')
        .text(d => d.text)

        render(list)
        // 'drawMilestone' uses pre-defined scale to get X coordinate
      // instead of its own.
      
      let categoryNames = this.DataItems.map(d => d.WK_NO)
      let x0 = d3.scaleBand().range([ 0, 920 ])
      x0.domain(categoryNames)
      
      this.drawMilestone({
        s: this.svg,                  // Selection where the AxisY-Wrapper(SVG Group) to be placed.
        x: 57,                        // X coordinate of the Axis wrapper(SVG Group)
        y: 260+70,   // Y coordinate of the Axis wrapper(SVG Group)height
        w: 920,    // Chart Area Width
        scaleX: x0
      })
      },
    render (dataset) {
      const { top, left, bottom, right } = this.margin
      const width = this.width - left - right
      const height = this.height - top - bottom

      const g = d3.select(this.$el).select('svg g')

      const y0 = d3.scaleLinear().range([ height, 0 ])
      const y1 = d3.scaleLinear().range([ height, 0 ])

      const x0 = d3.scaleBand().range([ 0, width ])
      const x1 = d3.scaleBand().range([ 0, width ])

      const leftColumns = this.legend.filter(d => d.axis === 'left').map(d => d['column'])
      const leftArr = dataset.map(d => d['VALUES']).flat().filter(v => v['VALUE'] && leftColumns.includes(v['KEY'])).map(v => v['VALUE'])
      const leftValues = Math.max.apply(null, leftArr)

      // const rightColumns = this.legend.filter(d => d.axis === 'right').map(d => d['column'])
      // const rightArr = dataset.map(d => d['VALUES']).flat().filter(v => v['VALU E'] && rightColumns.includes(v['KEY'])).map(v => v['VALUE'])
      // const rightValues = Math.max.apply(null, rightArr)

      const max = {
        left: leftArr.length === 0 ? 0 : leftValues
        // right: rightArr.length === 0 ? 0 : rightValues
      }

      max.left = max.left === 0 ? 100 : max.left
      // max.right = max.right === 0 ? 100 : max.right

      const axisLeftRange = d3.range(6).map(d => { const v = max.left / 5 * d; return String(v).indexOf('.') !== -1 ? v : Math.ceil(v) })
      // const axisRightRange = d3.range(6).map(d => { const v = max.right / 5 * d; return String(v).indexOf('.') !== -1 ? v : Math.ceil(v) })

      const categoryNames = dataset.map(d => d.CATEGORY)

      const yAxis = {
        left: d3.axisLeft(y0).tickValues(axisLeftRange),
        // right: d3.axisRight(y1).tickValues(axisRightRange)
      }

      // const duration = 0
      const bars = this.legend.filter(d => d.shape === 'rect').map(d => d['column'])

      y0.domain([ 0, max.left ])
      // y1.domain([ 0, max.right ])

      x0.domain(categoryNames)

      x1.domain(dataset[0].VALUES.filter(d => bars.includes(d.KEY)).map(d => d.KEY))
        .rangeRound([ 0, x0.bandwidth() ]).padding(0.15)

      // const xAxis = d3.axisBottom(x0).tickFormat(d => d).tickValues(x0.domain().filter((d,i) => { return !(i%5)}))
      const xAxis = d3.axisBottom(x0).tickFormat(d => d).tickValues(x0.domain())


      g.selectAll('g').remove()

      g.append('g')
        .attr('class', 'grid')
        .call(d3.axisLeft(y0).tickValues(axisLeftRange).tickSize(-width).tickFormat(''))
        .selectAll('.tick line')
        .attr('stroke', (d, k) => k > 0 ? '#efefef' : '#ccc')
        .attr('stroke-width', 1)
        .attr('shape-rendering', 'crispEdges')

      g.append('g')
        .style('font-size', '10px')
        .attr('class', 'x axis')
        .attr('transform', `translate(0,${height + 1})`)
        .call(xAxis)
        .selectAll('text')
        .attr('y', 0)
        .attr('x', -55)
        .attr('dy', '.35em')
        .attr('transform', 'rotate(-90)')
        .style('text-anchor', 'start')
        

      g.append('g')
        .attr('class', 'y axis axis-left')
        .style('font-size', '10px')
        .call(yAxis.left)

      // g.append('g')
      //   .attr('class', 'y axis axis-right')
      //   .style('font-size', '10px')
      //   .attr('transform', `translate(${width},0)`)
      //   .call(yAxis.right)


      g.selectAll('.domain').remove()
      // g.selectAll('.x .tick').remove()
      g.selectAll('.y .tick').attr('color', '#878787')
      g.selectAll('.y .tick line').remove()
      g.selectAll('.x .tick line').remove()

      const barGroups = g.selectAll('.bar')
        .data(dataset)
        .enter()
        .append('g')
        .attr('class', 'bar')
        .attr('transform', d => `translate(${x0(d.CATEGORY)},0)`)

      barGroups.selectAll('rect')
        .data(d => d.VALUES.filter(v => bars.includes(v.KEY)))
        .enter()
        .append('rect')
        .attr('width', x1.bandwidth())
        .attr('x', d => x1(d.KEY))
        .attr('y', d => y0(d.VALUE))
        .attr('height', d => height - y0(d.VALUE) < 0 ? 0 : height - y0(d.VALUE))
        .attr('fill', d => {
          const color = this.legend.find(v => v['column'] === d['KEY']).color
          return color
        })
        .style('opacity', 0)

      barGroups.selectAll('text')
        .data(d => d.VALUES.filter(v => bars.includes(v.KEY)))
        .enter()
        .append('text')
        .attr('x', d => x1(d.KEY))
        .attr('dx', x1.bandwidth() / 2)
        .attr('y', d => y0(d.VALUE))
        .attr('dy', '-.5em')
        .attr('fill', d => d3.rgb(this.legend.find(v => v['column'] === d.KEY).color).darker(1))
        .attr('text-anchor', 'middle')
        .attr('font-size', '10px')
        .text(d => d.VALUE > 0 ? d.VALUE : null)
        .style('opacity', 0)

      barGroups.selectAll('rect')
        .transition()
        .style('opacity', 1)

      barGroups.selectAll('text')
        .transition()
        .style('opacity', 0)

      const lines = this.legend.filter(d => d.shape === 'line').map(d => d['column']).filter(d => this.filter.includes(d))
      const lineLength = d3.line()
        .x(d => x0(d.KEY))
        .y(d => y0(d.VALUE))
        .defined(d => {
          return d.VALUE >= 0
        })
      const linedata = []
      lines.forEach(c => {
        const obj = {}
        obj.KEY = c
        obj.COLOR = this.legend.find(v => v['column'] === c).color
        obj.VALUES = dataset
          .map(d => {
            d.VALUES.find(v => {
              return v.KEY === c
            })
            
            return {
              KEY: d.CATEGORY,
              VALUE: d.VALUES.find(v => v.KEY === c).VALUE
            }
          })
        obj.LINESHAPE = this.legend.find(v => v['column'] === c).lineShape
        linedata.push(obj)
      })

      const lineGroups = g
        .append('g')
        .attr('class', 'line')
        .attr('transform', `translate(${x0.bandwidth() / 2},0)`)

      linedata.forEach(v => {
        const line = lineGroups
          .append('path')
          .datum(v)
          .attr('class', d => `line-${d.KEY}`)
          .attr('fill', 'none')
          .attr('stroke', d => d.COLOR)
          .attr('stroke-width', 1)
          .attr('shape-rendering', 'geometricPrecision')
          .attr('d', d => lineLength(d.VALUES))
        v.LENGTH = line.node().getTotalLength()
      })

      //line cercle
      // const circles = lineGroups
      //   .selectAll('g')
      //   .data(linedata)
      //   .enter()
      //   .append('g')
      //   .attr('data-key', d => d.KEY)

      // circles
      //   .selectAll('circle')
      //   .data(d => d.VALUES)
      //   .enter()
      //   .append('circle')
      //   .attr('class', d => !d.VALUE ? 'not-value' : '')
      //   .attr('cx', d => x0(d.KEY))
      //   .attr('cy', d => y1(d.VALUE))
      //   .attr('r', function (d) {
      //     const wrapper = d3.select(this).node().parentNode
      //     const legend = linedata.find(v => v.KEY === wrapper.dataset.key)
      //     if (legend) return legend.LINESHAPE && legend.LINESHAPE === 'dash' ? 0 : '.15em'
      //     else return '.15em'
      //   })
      //   .attr('fill', '#fff')
      //   .attr('stroke-width', '.1em')
      //   .attr('stroke', function (d) {
      //     const wrapper = d3.select(this).node().parentNode
      //     return linedata.find(v => v.KEY === wrapper.dataset.key).COLOR
      //   })
      //   .attr('shape-rendering', 'geometricPrecision')
      //   .style('display', d => !d.VALUE ? 'none' : '')

      // circles
      //   .selectAll('text')
      //   .data(d => d.VALUES)
      //   .enter()
      //   .append('text')
      //   .attr('x', d => x0(d.KEY))
      //   .attr('y', d => y1(d.VALUE))
      //   .attr('dy', '-1em')
      //   .attr('text-anchor', 'middle')
      //   .attr('font-size', '10px')
      //   .attr('fill', function (d) {
      //     const wrapper = d3.select(this).node().parentNode
      //     return d3.rgb(linedata.find(v => v.KEY === wrapper.dataset.key).COLOR).darker(1)
      //   })
      //   .text(d => d.VALUE && d.VALUE > 0 ? d.VALUE : null)

      g.selectAll('.line path')
        .attr('stroke-dasharray', d => '')
        .attr('stroke-dashoffset', d => d.LENGTH)
        .transition()
        .attr('stroke-dashoffset', 0)
        .attr('stroke-dasharray', d => {
          const legend = this.legend.find(v => v['column'] === d['KEY'])
          if (legend) return legend.lineShape && legend.lineShape === 'dash' ? '4,3' : d.LENGTH + ' ' + d.LENGTH
          else return ''
        })
        .attr('d', d => lineLength(d.VALUES))

      g.selectAll('.line circle')
        .attr('opacity', 0)
        .transition()
        .attr('opacity', 1)
      g.selectAll('.line text')
        .attr('opacity', 0)
        .transition() 
        .attr('opacity', 0)
    }
  }
}
