import * as d3 from 'd3'

export default {
  data: () => ({
    dataSet: [],

  }),
  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },
  methods: {
    setDefaultValues(){
      this.dataSet = JSON.parse(JSON.stringify(this.DataItems))
    },
    trimFilterPure() {
      let filters_ = JSON.parse(JSON.stringify(this.filtering))

      Object.keys(filters_).forEach(k_ => {
        if(typeof filters_[k_] == 'object') {
          if(Object.keys(filters_[k_]).length === 0) delete filters_[k_]
        } else {
          if(!filters_[k_]) delete filters_[k_]
        }
      })

      return filters_
    },
  }
}