const Topsides = [
  
  { Blk: 'E2ST', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME02', Lv4: null, x: 980, y: 816.55, x1: 742.14, y1: 577.093, x2: 686.14, y2: 789.09, },
  { Blk: 'E2A1', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME02', Lv4: null, x: 680, y: 674, x1: 733.25, y1: 541.63, x2: 625, y2: 623.13, },
  { Blk: 'E2A2', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME02', Lv4: null, x: 980, y: 687.53, x1: 786.22, y1: 548.38, x2: 714.5, y2: 638.75, },
  { Blk: 'E2M1', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME02', Lv4: null, x: 980, y: 589, x1: 770.5, y1: 547.38, x2: 714.5, y2: 563.38, },
  { Blk: 'E2B', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME02', Lv4: null, x: 980, y: 490, x1: 725.63, y1: 496.75, x2: 672.88, y2: 440.63, },
  { Blk: 'E2C', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME02', Lv4: null, x: 980, y: 367, x1: 727.88, y1: 483, x2: 672.88, y2: 335, },
  { Blk: 'E2D', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME02', Lv4: null, x: 980, y: 260, x1: 736.25, y1: 469.63, x2: 680.25, y2: 238.63, },
  { Blk: 'E1ST1', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME01', Lv4: null, x: 660, y: 625, x1: 767.45, y1: 511, x2: 560, y2: 518, },
  { Blk: 'E1ST', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME01', Lv4: null, x: 1070, y: 822, x1: 760.86, y1: 606.787, x2: 769.03, y2: 778.79, },
  { Blk: 'E1A1', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME01', Lv4: null, x: 760, y: 645, x1: 731, y1: 571.75, x2: 695.34, y2: 611.75, },
  { Blk: 'E1A2', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME01', Lv4: null, x: 1070, y: 687, x1: 802.22, y1: 578.25, x2: 817.25, y2: 630.25, },
  { Blk: 'E1B1', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME01', Lv4: null, x: 760, y: 499, x1: 757.85, y1: 522.38, x2: 722, y2: 451.88, },
  { Blk: 'E1B2', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME01', Lv4: null, x: 1070, y: 530, x1: 804.85, y1: 528.5, x2: 822.5, y2: 469.5, },
  { Blk: 'E1M1', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME01', Lv4: null, x: 1070, y: 436, x1: 799.75, y1: 544.13, x2: 807.75, y2: 400, },
  { Blk: 'E1C1', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME01', Lv4: null, x: 760, y: 321, x1: 757.75, y1: 514.63, x2: 735.5, y2: 308, },
  { Blk: 'E1C2', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME01', Lv4: null, x: 1070, y: 333, x1: 825.1, y1: 526, x2: 848.5, y2: 329, },
  { Blk: 'E1D', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME01', Lv4: null, x: 1070, y: 225, x1: 866.75, y1: 515.75, x2: 855, y2: 220, },
  { Blk: 'W5ST', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW05', Lv4: null, x: 708, y: 774, x1: 747.78, y1: 578.715, x2: 712.78, y2: 744.72, },
  { Blk: 'W5A1', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW05', Lv4: null, x: 708, y: 664, x1: 735.25, y1: 537.25, x2: 687, y2: 612.25, },
  { Blk: 'W5A2', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW05', Lv4: null, x: 1050, y: 706, x1: 799, y1: 553.75, x2: 824, y2: 643, },
  { Blk: 'W5M1', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW05', Lv4: null, x: 708, y: 558, x1: 764.38, y1: 572, x2: 729.38, y2: 537, },
  { Blk: 'W5B1', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW05', Lv4: null, x: 708, y: 447, x1: 733, y1: 498, x2: 689.13, y2: 421.75, },
  { Blk: 'W5B2', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW05', Lv4: null, x: 1050, y: 508, x1: 800, y1: 515.5, x2: 827, y2: 452, },
  { Blk: 'W5C1', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW05', Lv4: null, x: 708, y: 320, x1: 733, y1: 485.75, x2: 691.13, y2: 312, },
  { Blk: 'W5C2', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW05', Lv4: null, x: 1050, y: 342, x1: 811, y1: 488.25, x2: 823.88, y2: 321.25, },
  { Blk: 'W5D', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW05', Lv4: null, x: 708, y: 205, x1: 832.88, y1: 464.25, x2: 797.88, y2: 190, },
  { Blk: 'W5ST1', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW05', Lv4: null, x: 1180, y: 664, x1: 881.99, y1: 542.384, x2: 1000, y2: 631.38, },
  { Blk: 'W4ST1', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW04', Lv4: null, x: 700, y: 660, x1: 770.25, y1: 571.719, x2: 577.07, y2: 597.72, },
  { Blk: 'W4ST', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW04', Lv4: null, x: 1046, y: 784.12, x1: 779.5, y1: 600.607, x2: 780, y2: 772.61, },
  { Blk: 'W4A1', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW04', Lv4: null, x: 800, y: 660, x1: 759.63, y1: 565, x2: 729.19, y2: 650.75, },
  { Blk: 'W4A2', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW04', Lv4: null, x: 1046, y: 691, x1: 822.23, y1: 572.75, x2: 841, y2: 670, },
  { Blk: 'W4B', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW04', Lv4: null, x: 1046, y: 557, x1: 777.44, y1: 552.63, x2: 770.37, y2: 550, },
  { Blk: 'W4SU', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW04', Lv4: null, x: 1046, y: 429, x1: 793, y1: 537, x2: 793, y2: 420, },
  { Blk: 'W4C', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW04', Lv4: null, x: 1046, y: 320, x1: 803.71, y1: 414, x2: 796.53, y2: 260, },
  { Blk: 'W2ST', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW02', Lv4: null, x: 700, y: 810.13, x1: 742.24, y1: 594.615, x2: 691.24, y2: 789.62, },
  { Blk: 'W2A1', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW02', Lv4: null, x: 700, y: 710, x1: 732.88, y1: 551.63, x2: 657.86, y2: 656, },
  { Blk: 'W2A2', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW02', Lv4: null, x: 1040, y: 749, x1: 815, y1: 571, x2: 779, y2: 677.38, },
  { Blk: 'W2M1', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW02', Lv4: null, x: 700, y: 611, x1: 785, y1: 564.63, x2: 734, y2: 585, },
  { Blk: 'W2B1', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW02', Lv4: null, x: 700, y: 511, x1: 736.25, y1: 518, x2: 673.13, y2: 498, },
  { Blk: 'W2B2', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW02', Lv4: null, x: 1040, y: 545, x1: 815.22, y1: 535, x2: 797, y2: 515, },
  { Blk: 'W2C1', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW02', Lv4: null, x: 700, y: 372, x1: 736.25, y1: 502, x2: 678, y2: 359, },
  { Blk: 'W2C2', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW02', Lv4: null, x: 1040, y: 405, x1: 805.72, y1: 524.13, x2: 809, y2: 394, },
  { Blk: 'W2D', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW02', Lv4: null, x: 700, y: 263, x1: 741.85, y1: 454, x2: 690.85, y2: 217, },
  { Blk: 'W2ST1', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW02', Lv4: null, x: 1191.68, y: 697.58, x1: 874.28, y1: 519.145, x2: 1010.28, y2: 629.15, },
  { Blk: 'W1ST1', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW01', Lv4: null, x: 595, y: 660, x1: 735.41, y1: 494.023, x2: 468, y2: 570.02, },
  { Blk: 'W1ST', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW01', Lv4: null, x: 1070, y: 839.75, x1: 754.03, y1: 582.872, x2: 765.63, y2: 813.87, },
  { Blk: 'W1A1', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW01', Lv4: null, x: 709, y: 744, x1: 732, y1: 541.49, x2: 673, y2: 695.75, },
  { Blk: 'W1A2', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW01', Lv4: null, x: 1070, y: 778, x1: 802.47, y1: 547.5, x2: 843, y2: 724.67, },
  { Blk: 'W1M1', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW01', Lv4: null, x: 709, y: 644, x1: 839.25, y1: 560.93, x2: 782.25, y2: 629.71, },
  { Blk: 'W1M2', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW01', Lv4: null, x: 1070, y: 678, x1: 865.13, y1: 558.25, x2: 878.5, y2: 644.5, },
  { Blk: 'W1B1', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW01', Lv4: null, x: 709, y: 526, x1: 753.1, y1: 509.63, x2: 687.38, y2: 494.25, },
  { Blk: 'W1B2', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW01', Lv4: null, x: 1070, y: 560, x1: 820, y1: 525, x2: 846.5, y2: 521.92, },
  { Blk: 'W1C1', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW01', Lv4: null, x: 709, y: 378, x1: 752.25, y1: 491, x2: 693.63, y2: 368.5, },
  { Blk: 'W1C2', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW01', Lv4: null, x: 1070, y: 412, x1: 819, y1: 509.49, x2: 836.5, y2: 400, },
  { Blk: 'W1D', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW01', Lv4: null, x: 1070, y: 278, x1: 769, y1: 484, x2: 781, y2: 250, },
  { Blk: 'W1E', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW01', Lv4: null, x: 1070, y: 175, x1: 791, y1: 457.25, x2: 803, y2: 170, },
  { Blk: 'E4ST1', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME04', Lv4: null, x: 690, y: 660, x1: 790.06, y1: 565.526, x2: 590, y2: 599.53, },
  { Blk: 'E4ST', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME04', Lv4: null, x: 1030, y: 839.4, x1: 779.5, y1: 606.949, x2: 764.96, y2: 811.95, },
  { Blk: 'E4A1', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME04', Lv4: null, x: 770, y: 679, x1: 760, y1: 573, x2: 720.48, y2: 638, },
  { Blk: 'E4A2', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME04', Lv4: null, x: 1030, y: 701, x1: 820.73, y1: 578.13, x2: 831, y2: 657.13, },
  { Blk: 'E4B', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME04', Lv4: null, x: 1030, y: 572, x1: 771.47, y1: 563, x2: 757.23, y2: 533, },
  { Blk: 'E4SU', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME04', Lv4: null, x: 1030, y: 455, x1: 803.85, y1: 536.25, x2: 777.85, y2: 424.25, },
  { Blk: 'E4C', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME04', Lv4: null, x: 1030, y: 337.51, x1: 804.23, y1: 420, x2: 789.69, y2: 257, },
  { Blk: 'E5ST', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME05', Lv4: null, x: 975, y: 837, x1: 758.83, y1: 628.21, x2: 696.38, y2: 794.5, },
  { Blk: 'E5A1', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME05', Lv4: null, x: 700, y: 701, x1: 750, y1: 625, x2: 649, y2: 681.5, },
  { Blk: 'E5A2', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME05', Lv4: null, x: 975, y: 724, x1: 822, y1: 639.25, x2: 765, y2: 707.25, },
  { Blk: 'E5B', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME05', Lv4: null, x: 975, y: 605, x1: 774, y1: 601, x2: 712, y2: 573, },
  { Blk: 'E5C', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME05', Lv4: null, x: 975, y: 480, x1: 774, y1: 575, x2: 712, y2: 448, },
  { Blk: 'E5D', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME05', Lv4: null, x: 975, y: 359, x1: 774, y1: 542, x2: 712, y2: 317, },
  { Blk: 'E5E', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME05', Lv4: null, x: 975, y: 242, x1: 774, y1: 517, x2: 712, y2: 203, },
  { Blk: 'FL01', Lv1: 'TOPSIDE', Lv2: 'COMMON', Lv3: 'FL01', Lv4: null, x: 772, y: 657, x1: 752, y1: 203, x2: 632, y2: 238, },
  { Blk: 'FR01', Lv1: 'TOPSIDE', Lv2: 'COMMON', Lv3: 'FL01', Lv4: null, x: 989, y: 808, x1: 830.25, y1: 756.88, x2: 868, y2: 775, },
  { Blk: 'KO01', Lv1: 'TOPSIDE', Lv2: 'COMMON', Lv3: 'KO01', Lv4: null, x: 910, y: 564.75, x1: 737, y1: 515, x2: 737, y2: 515, },
  { Blk: 'LD00DA', Lv1: 'TOPSIDE', Lv2: 'COMMON', Lv3: 'LD00DA', Lv4: null, x: 900, y: 564.75, x1: 724, y1: 499, x2: 724, y2: 499, },
  { Blk: 'RC01', Lv1: 'TOPSIDE', Lv2: 'COMMON', Lv3: 'RC01', Lv4: null, x: 920, y: 560.48, x1: 741, y1: 492, x2: 741, y2: 492, },
  { Blk: 'RC02', Lv1: 'TOPSIDE', Lv2: 'COMMON', Lv3: 'RC02', Lv4: null, x: 920, y: 560.52, x1: 749, y1: 491, x2: 749, y2: 491, },
  { Blk: 'RC03', Lv1: 'TOPSIDE', Lv2: 'COMMON', Lv3: 'RC03', Lv4: null, x: 920, y: 560.01, x1: 738, y1: 505, x2: 738, y2: 505, },
  { Blk: 'RC04', Lv1: 'TOPSIDE', Lv2: 'COMMON', Lv3: 'RC04', Lv4: null, x: 920, y: 555.01, x1: 760, y1: 508, x2: 760, y2: 508, },
  { Blk: 'RC05', Lv1: 'TOPSIDE', Lv2: 'COMMON', Lv3: 'RC05', Lv4: null, x: 920, y: 555.91, x1: 762, y1: 507, x2: 762, y2: 507, },
  
  // { Blk: 'ME02IH', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME02', Lv4: null, x: 946, y: 816.55, x1: 742.14, y1: 577.093, x2: 686.14, y2: 789.09, },
  // { Blk: 'E2A', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME02', Lv4: null, x: 946, y: 687.53, x1: 734.5, y1: 574.151, x2: 678.5, y2: 652.15, },
  // { Blk: 'E2M1', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME02', Lv4: null, x: 946, y: 589, x1: 770.5, y1: 547.38, x2: 714.5, y2: 563.38, },
  // { Blk: 'E2B', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME02', Lv4: null, x: 946, y: 488, x1: 725.75, y1: 528.13, x2: 670.75, y2: 467.13, },
  // { Blk: 'E2C', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME02', Lv4: null, x: 946, y: 365, x1: 726.38, y1: 483, x2: 670.38, y2: 338, },
  // { Blk: 'E2D', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME02', Lv4: null, x: 946, y: 260, x1: 736.25, y1: 469.63, x2: 680.25, y2: 238.63, },
  // { Blk: 'ME01_ST', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME01', Lv4: null, x: 680, y: 580, x1: 767.45, y1: 511, x2: 617.62, y2: 518, },
  // { Blk: 'ME01IH', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME01', Lv4: null, x: 1040, y: 798.69, x1: 760.86, y1: 606.787, x2: 769.03, y2: 778.79, },
  // { Blk: 'E1A', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME01', Lv4: null, x: 1040, y: 664.59, x1: 731, y1: 594.418, x2: 739.17, y2: 641.42, },
  // { Blk: 'E1B', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME01', Lv4: null, x: 1040, y: 520, x1: 758.32, y1: 559.604, x2: 766.5, y2: 500, },
  // { Blk: 'E1M1', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME01', Lv4: null, x: 1040, y: 425, x1: 799.75, y1: 544.13, x2: 807.75, y2: 400, },
  // { Blk: 'E1C', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME01', Lv4: null, x: 1040, y: 325, x1: 757.75, y1: 514.13, x2: 765.75, y2: 300, },
  // { Blk: 'E1D', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME01', Lv4: null, x: 1040, y: 230, x1: 866.75, y1: 515.75, x2: 855, y2: 220, },
  // { Blk: 'MW05IH', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW05', Lv4: null, x: 750, y: 767.27, x1: 747.78, y1: 578.715, x2: 712.78, y2: 744.72, },
  // { Blk: 'W5A', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW05', Lv4: null, x: 750, y: 662, x1: 735.25, y1: 559.88, x2: 700.25, y2: 610.88, },
  // { Blk: 'W5M1', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW05', Lv4: null, x: 750, y: 548, x1: 764.38, y1: 572, x2: 729.38, y2: 527, },
  // { Blk: 'W5B', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW05', Lv4: null, x: 750, y: 430, x1: 733.96, y1: 515.658, x2: 698.96, y2: 400, },
  // { Blk: 'W5C', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW05', Lv4: null, x: 750, y: 310, x1: 733, y1: 485.75, x2: 698, y2: 288.75, },
  // { Blk: 'W5D', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW05', Lv4: null, x: 750, y: 188, x1: 832.88, y1: 464.25, x2: 797.88, y2: 190, },
  // { Blk: 'MW05_ST', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW05', Lv4: null, x: 1170, y: 670, x1: 881.99, y1: 542.384, x2: 998.99, y2: 631.38, },
  // { Blk: 'MW04_ST', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW04', Lv4: null, x: 685, y: 615.29, x1: 770.25, y1: 571.719, x2: 631.07, y2: 595.72, },
  // { Blk: 'MW04IH', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW04', Lv4: null, x: 1008.59, y: 784.12, x1: 779.5, y1: 600.607, x2: 772.32, y2: 772.61, },
  // { Blk: 'W4A', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW04', Lv4: null, x: 1008.59, y: 670.24, x1: 760, y1: 595.32, x2: 752.82, y2: 654.32, },
  // { Blk: 'W4B', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW04', Lv4: null, x: 1008.59, y: 521.79, x1: 777.55, y1: 536.828, x2: 770.37, y2: 492.83, },
  // { Blk: 'MW04_SUPT', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW04', Lv4: null, x: 1008.59, y: 350.54, x1: 803.71, y1: 414, x2: 796.53, y2: 289, },
  // { Blk: 'MW02IH', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW02', Lv4: null, x: 740, y: 810.13, x1: 742.24, y1: 594.615, x2: 691.24, y2: 789.62, },
  // { Blk: 'W2A', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW02', Lv4: null, x: 740, y: 720, x1: 733, y1: 584.701, x2: 682, y2: 680, },
  // { Blk: 'W2M1', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW02', Lv4: null, x: 740, y: 590, x1: 785, y1: 564.63, x2: 734, y2: 580, },
  // { Blk: 'W2B', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW02', Lv4: null, x: 740, y: 502, x1: 736.25, y1: 539.25, x2: 685.25, y2: 480, },
  // { Blk: 'W2C', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW02', Lv4: null, x: 740, y: 372, x1: 736.73, y1: 501.875, x2: 685.73, y2: 360, },
  // { Blk: 'W2D', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW02', Lv4: null, x: 740, y: 263, x1: 741.85, y1: 454, x2: 690.85, y2: 217, },
  // { Blk: 'MW02_ST', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW02', Lv4: null, x: 1191.68, y: 697.58, x1: 874.28, y1: 519.145, x2: 1010.28, y2: 629.15, },
  // { Blk: 'MW01_ST', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW01', Lv4: null, x: 663, y: 630, x1: 735.41, y1: 494.023, x2: 603, y2: 570.02, },
  // { Blk: 'MW01IH', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW01', Lv4: null, x: 1028.59, y: 839.75, x1: 754.03, y1: 582.872, x2: 765.63, y2: 813.87, },
  // { Blk: 'W1A', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW01', Lv4: null, x: 1028.59, y: 706.17, x1: 732, y1: 572.381, x2: 743.59, y2: 685.38, },
  // { Blk: 'W1B', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW01', Lv4: null, x: 1028.59, y: 580, x1: 752.24, y1: 527.476, x2: 761.83, y2: 540, },
  // { Blk: 'W1C', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW01', Lv4: null, x: 1028.59, y: 440, x1: 752.53, y1: 492.063, x2: 764.12, y2: 400, },
  // { Blk: 'W1D', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW01', Lv4: null, x: 1028.59, y: 340, x1: 769, y1: 484, x2: 781, y2: 323, },
  // { Blk: 'W1E', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW01', Lv4: null, x: 1028.59, y: 215, x1: 791, y1: 457.25, x2: 803, y2: 236.25, },
  // { Blk: 'ME04_ST', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME04', Lv4: null, x: 695, y: 625, x1: 790.06, y1: 565.526, x2: 642.52, y2: 599.53, },
  // { Blk: 'ME04IH', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME04', Lv4: null, x: 1006.46, y: 839.4, x1: 779.5, y1: 606.949, x2: 764.96, y2: 811.95, },
  // { Blk: 'E4A', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME04', Lv4: null, x: 1006.46, y: 698.24, x1: 760, y1: 592.067, x2: 745.46, y2: 663.07, },
  // { Blk: 'E4B', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME04', Lv4: null, x: 1006.46, y: 524.69, x1: 767.5, y1: 535.876, x2: 752.96, y2: 477.88, },
  // { Blk: 'ME04_SUPT', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME04', Lv4: null, x: 1006.46, y: 337.51, x1: 804.23, y1: 420, x2: 789.69, y2: 257, },
  // { Blk: 'ME05IH', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME05', Lv4: null, x: 966, y: 837, x1: 758.83, y1: 628.21, x2: 696.38, y2: 794.5, },
  // { Blk: 'E5A', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME05', Lv4: null, x: 966, y: 721, x1: 751, y1: 625, x2: 689, y2: 686, },
  // { Blk: 'E5B', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME05', Lv4: null, x: 966, y: 605, x1: 774, y1: 601, x2: 712, y2: 573, },
  // { Blk: 'E5C', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME05', Lv4: null, x: 966, y: 480, x1: 774, y1: 575, x2: 712, y2: 448, },
  // { Blk: 'E5D', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME05', Lv4: null, x: 966, y: 359, x1: 774, y1: 542, x2: 712, y2: 317, },
  // { Blk: 'E5E', Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME05', Lv4: null, x: 966, y: 242, x1: 774, y1: 517, x2: 712, y2: 203, },
  // { Blk: 'FL01', Lv1: 'TOPSIDE', Lv2: 'COMMON', Lv3: 'FL01', Lv4: null, x: 772, y: 657, x1: 752, y1: 203, x2: 632, y2: 238, },
  // { Blk: 'FR01', Lv1: 'TOPSIDE', Lv2: 'COMMON', Lv3: 'FL01', Lv4: null, x: 989, y: 808, x1: 830.25, y1: 756.88, x2: 868, y2: 775, },
  // { Blk: 'KO01', Lv1: 'TOPSIDE', Lv2: 'COMMON', Lv3: 'KO01', Lv4: null, x: 910, y: 564.75, x1: 737, y1: 515, x2: 737, y2: 515, },
  // { Blk: 'LD00DA', Lv1: 'TOPSIDE', Lv2: 'COMMON', Lv3: 'LD00DA', Lv4: null, x: 900, y: 564.75, x1: 724, y1: 499, x2: 724, y2: 499, },
  // { Blk: 'RC01', Lv1: 'TOPSIDE', Lv2: 'COMMON', Lv3: 'RC01', Lv4: null, x: 920, y: 560.48, x1: 741, y1: 492, x2: 741, y2: 492, },
  // { Blk: 'RC02', Lv1: 'TOPSIDE', Lv2: 'COMMON', Lv3: 'RC02', Lv4: null, x: 920, y: 560.52, x1: 749, y1: 491, x2: 749, y2: 491, },
  // { Blk: 'RC03', Lv1: 'TOPSIDE', Lv2: 'COMMON', Lv3: 'RC03', Lv4: null, x: 920, y: 560.01, x1: 738, y1: 505, x2: 738, y2: 505, },
  // { Blk: 'RC04', Lv1: 'TOPSIDE', Lv2: 'COMMON', Lv3: 'RC04', Lv4: null, x: 920, y: 555.01, x1: 760, y1: 508, x2: 760, y2: 508, },
  // { Blk: 'RC05', Lv1: 'TOPSIDE', Lv2: 'COMMON', Lv3: 'RC05', Lv4: null, x: 920, y: 555.91, x1: 762, y1: 507, x2: 762, y2: 507, },

]

export default Topsides