import * as d3 from 'd3'

export default {
  methods: {
    Activate_Hull_FOR() {

      // Remove SVG
      this.removeSvg(this.CANVAS, 1000)

      this.svgModeling = this.svg.append('svg')
      this.svgModeling
      .attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight)
      .attr('viewBox', `0 0 ${this.Canvas.CanvasWidth} ${this.Canvas.CanvasHeight}`)
      .attr('opacity', 1)

      // Overall_Hull_Group
      let Hull_FOR = this.svgModeling
      .append('g')
      .attr('id', 'Hull_FOR_Group')
      .attr('opacity', 1)
      .attr('transform', `translate(0,0) scale(1)`)

      setTimeout(() => {
        this.Hull_FOR.forEach(d => {
          Hull_FOR
          .append('image')
          .attr('id', `Blk_${d.Blk}`)
          .attr('opacity', 1).style('cursor', 'pointer')
          .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Blocks/Hull/${d.Blk}.png`)
          .attr('transform', `translate(${d.x1}, ${d.y1}) scale(${0.5})`)
          .call(s => { this.Goto_Block_Summary(s, d) }) // <--------
        })
      }, 1000);
      setTimeout(() => {
        this.Hull_FOR.forEach(d => {
          d3.select(`#Blk_${d.Blk}`)
          .transition().duration(1000).attr('transform', `translate(${d.x2}, ${d.y2}) scale(0.5)`)
        })
      }, 1000);
      setTimeout(() => {
        this.Masks_Hull_FOR()
        // Display
        this.displayGroupID(`#Mask_HL03`, 0, 10, 0)
        this.displayGroupID(`#Mask_MD00f`, 0, 10, 0)
        this.displayGroupID(`#Mask_VS4_05`, 0, 10, 0)

        this.Lev1 = 'HULL'; this.Lev2 = 'HULL-FOR'
        this.stage = this.STG_Division_Lv3
        this.fncName = 'Activate_Hull_FOR + Goto_FOR_Division_Lv3'
        this.StageCode() // AREA / STAGE
        this.displayGroupID(`#Menu_Back`, 0, 200, 1)

        // ### Call Canvas ###
        this.Canvas_Division_Lv3(this.Lev1, this.Lev2)
      }, 1000);
      // setTimeout(() => {
      //   // Mask_FOR 
      //   Hull_FOR 
      //   .append('polygon')
      //   .attr('id', 'Mask_FOR_Group')
      //   .attr('points', '0,100.69 0,216.31 422.68,301 578,192.05 578,125.47 426.81,94.5 426.81,0 402.03,0 402.03,76.44 98.55,31.53 39.71,52.69 ')
      //   .attr('transform', `translate(481,411)`)
      //   .attr('fill', '#fff')
      //   .style('opacity', 0)
      //   .style('cursor', 'pointer')
      //   .call(s => { this.Goto_FOR_Division_Lv3(s) }) // <--------

      //   this.Masks_Hull_FOR() // ######

      //   this.Lev1 = 'HULL'
      //   this.Lev2 = 'HULL-FOR'
      //   this.stage = this.STG_Activate
      //   // this.fncName = 'Activate_Hull_FOR'
      //   this.StageCode() // AREA / STAGE
      //   this.displayGroupID(`#Menu_Back`, 0, 200, 1)

      //   // ### Call Canvas ###
      //   this.Canvas_Hull_Activate(this.Lev2)
      // }, 1000);
    },

    Masks_Hull_FOR() {
      // Goto_Division_FOR_Lv3

      this.svgModeling  
      .append('polygon')
      .attr('id', 'Mask_HL03')
      .attr('points', '0,68.16 0,183.26 424.18,270 581,156.42 581,88.81 411.79,44.94 96.95,0')
      .attr('fill', '#fff').attr('transform', `translate(481,444)`)
      .style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(this.Goto_FOR_Division_Lv4) // <--------

      this.svgModeling 
      .append('polygon')
      .attr('id', 'Mask_MD00f')
      .attr('points', '19.24,0 0,41.32 329.95,110 343,63')
      .attr('fill', '#fff').attr('transform', `translate(512,153)`)
      .style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_FOR_BlockDivison_Lv3(s, 'Mask_MD00f', 'MD00f') }) // <--------

      this.svgModeling  
      .append('polygon')
      .attr('id', 'Mask_VS4_05')
      .attr('points', '0,84.73 0,203 116,117.24 116,0')
      .attr('fill', '#fff').attr('transform', `translate(796,254)`)
      .style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_FOR_BlockDivison_Lv3(s, 'Mask_VS4_05', 'VS04-5') }) // <--------


      // Goto_Division_FOR_Lv4
      this.svgModeling
      .append('polygon')
      .attr('id', 'Mask_HL03_Main')
      .attr('points', '0,56 0,87 459,187 544,123 544,91 373,44 156,0 77,0')
      .attr('fill', '#fff').attr('transform', `translate(594,195) scale(0.8)`).style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_FOR_BlockDivison_Lv4(s, 'Mask_HL03_Main', 'MDK') }) // <--------

      this.svgModeling
      .append('polygon')
      .attr('id', 'Mask_HL03_Forward')
      .attr('points', '0,79 0,207 114,128 178,71 178,14 142,0 101,0')
      .attr('fill', '#fff').attr('transform', `translate(1104,555) scale(0.8)`).style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_FOR_BlockDivison_Lv4(s, 'Mask_HL03_After', 'FOR') }) // <--------

      this.svgModeling
      .append('polygon')
      .attr('id', 'Mask_HL03_Port')
      .attr('points', '0,14 0,91 277,152 322,175 449,202 477,91 345,54 20,0')
      .attr('fill', '#fff').attr('transform', `translate(762,370) scale(0.8)`).style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_FOR_BlockDivison_Lv4(s, 'Mask_HL03_Port', 'PRT') }) // <--------

      this.svgModeling
      .append('polygon')
      .attr('id', 'Mask_HL03_Starboard')
      .attr('points', '0,0 0,95 188,133 188,158 197,161 215,138 314,158 316,173 368,185 370,204 429,214 454,107 443,84')
      .attr('fill', '#fff').attr('transform', `translate(460,585) scale(0.8)`).style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_FOR_BlockDivison_Lv4(s, 'Mask_HL03_Starboard', 'STB') }) // <--------

      this.svgModeling
      .append('polygon')
      .attr('id', 'Mask_HL03_Bulkhead')
      .attr('points', '-0.74,22.82 -0.74,104.5 29.58,113.4 29.58,124.24 48.16,141.27 80.68,118.82 113.97,128.11 119.39,152.89 133.32,160.63 168.16,138.95 202.23,147.47 202.23,171.45 271.9,179.98 395,209 487.26,151.34 487.26,84.76 103.13,0 67.52,24.37 13.32,10.44')
      .attr('fill', '#fff').attr('transform', `translate(612,480) scale(0.8)`).style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_FOR_BlockDivison_Lv4(s, 'Mask_HL03_Bulkhead', 'BLK') }) // <--------

      this.svgModeling
      .append('polygon')
      .attr('id', 'Mask_HL03_Bottom')
      .attr('points', '0.66,63.9 0.66,125.06 420.55,206 527.66,134.95 497.97,80.97 93.68,0')
      .attr('fill', '#fff').attr('transform', `translate(580,750) scale(0.8)`).style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_FOR_BlockDivison_Lv4(s, 'Mask_HL03_Bottom', 'BTM') }) // <--------
    },
  }
}
           