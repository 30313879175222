// -------------------------------------------------------------------------------------- Global
import { SafeIdMixin } from '../../../../../../includes/mixins/safeid.mixin'                // ATAGlance Framework Globals
import gmx_Controller from '../../../../../../includes/mixins/Controller.mixin'       // SVG Control
// Global Drawings
import gmx_Canvas from '../../../../../../includes/mixins/Draw_Canvas.mixin'
// Global Functions
import gmx_Timeline from '../../../../../../includes/mixins/Timeline.mixin'
import gmx_Miscellaneous from '../../../../../../includes/mixins/Miscellaneous.mixins'
import gmx_Scrollable from '../../../../../../includes/mixins/Scrollable.mixin'

// -------------------------------------------------------------------------------------- Local
// Locally declared common Data-Props & SVG Drawing Support
import lmx_ReactiveEvents from './Realtime_Monitor.mixin' // Reactive Events
import lmx_Miscellaneous from         './../../../../include/mixins/Miscellaneous.mixins'
  
// Charts 
import Chart_Pie from                 '../../../../include/ChartLibrary/Charts_Pie.mixin'
import Chart_Radar from               '../../../../include/ChartLibrary/Charts_Radar.mixin'
import Chart_ProgressTables from      '../../../../include/ChartLibrary/Charts_ProgressTables.mixin'

// Custom Charts      
import Custom_Charts from             '../../../../include/ChartLibrary/Custom_Charts.mixin'
import Custom_Header from             '../../../../include/ChartLibrary/Custom_Header.mixin'
import Custom_SummaryTable from       '../../../../include/ChartLibrary/Custom_SummaryTable.mixin'

import lmx_ChartStyleData from './ChartStyleData.mixin'
import lmx_Declares from './Declares.mixin'
import lmx_Timeline from './Draw_Timeline.mixin'
import lmx_Charts from './Draw_Charts.mixin'
import lmx_LineCharts from './Draw_LineCharts.mixin'
import lmx_SystemBoundary from './Draw_SystemBoundary.mixin'
import lmx_Legends from './Draw_Legends.mixin'
import lmx_SubSystemList from './Draw_SubSystemList.mixin'


export default {
  mixins: [
    SafeIdMixin,
    gmx_Controller,
    gmx_Canvas,
    gmx_Timeline,
    gmx_Miscellaneous,
    gmx_Scrollable,
    
    lmx_ReactiveEvents,
    lmx_Miscellaneous,
    
    Chart_Pie,
    Chart_Radar,
    Chart_ProgressTables,

    Custom_Charts,
    Custom_Header,
    Custom_SummaryTable,

    lmx_ChartStyleData,
    lmx_Declares,
    lmx_Timeline,
    lmx_Charts,
    lmx_LineCharts,
    lmx_SystemBoundary,
    lmx_Legends,
    lmx_SubSystemList,
  ],
}
