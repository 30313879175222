import { log10 } from 'core-js/core/number';
import * as d3 from 'd3'
export default {
  data: () => ({
    floatGroup: null,
    path: {
      // ME01: '/topside_dashboard/me01_flash_gas_compression',
    }
  }),
  methods: {
    drawFloats() {
      this.floatGroup = this.chartArea
      .append('g')
      .attr('class', 'float_group')
      .attr('transform', `translate(0, 0)`)
      .style('opacity', 0)
    },
    loadFloats() {
      setTimeout(() => {
        this.floatGroup.selectAll("*").remove()

        if(this.stage == 'OverAll'){
          // this.drawShopTooltipGroup()
          this.drawAreaTooltipGroup()
        }
        if(this.stage == 'Mainplant'||this.stage == 'Offplots'){
          this.drawItemTooltipGroup()
        }
        // if(this.stage == 'P1'){
        //   this.drawPackageTooltipGroup()
        // }
        // if(this.stage == 'P2'){
        //   this.drawPackageTooltipGroup()
        // }
        // if(this.stage == 'PP'){
        //   this.drawPackageAreaTooltipGroup()
        // }
        // else{
        //   this.drawItemTooltipGroup()
        // }
        this.floatGroup
        .transition().duration(250)
        .style('opacity', 1)
      }, 150);
    },
    closeFloats() {
      this.floatGroup
      .transition().duration(150)
      .style('opacity', 0)
    },
    drawAreaTooltipGroup() {
      this.elProps.coordinates.filter((c, i) => c.STAGE == this.stage).forEach(c => {
        let Lv1data = this.DataItems.find(d => d.LV1 == c.LV1 & d.LV2 == c.LV2 )

        // console.log(Lv1data)
        let areaTooltip = this.floatGroup
        .append('g')
        .attr('class', 'area_tooltip')
        .attr('transform', `translate(${c.x}, ${c.y})`)
        .attr('filter', 'url(#dropshadow)')
        .style('cursor', 'pointer')
        .on('mouseover', () => {
          d3.select(`#Mask_${c.LV2}`).transition().style('opacity', 0.3)
        })
        .on('mouseout', () => {
          d3.select(`#Mask_${c.LV2}`).transition().style('opacity', 0)
        })
        .on('click', () => {
          let dStage = {
            stage: c.GOTO,
            item: ''
          }
          this.selectedItems.push(dStage)
          this.cValue = this.cValue == 1 ? 2 : 1
          this.gotoStage(c.GOTO)
        })
      
  
        areaTooltip
        .append('rect')
        .attr('width',Lv1data.LV2 == 'MAINPLANT' ? 490 :220)
        .attr('height',132)
        .attr('fill', '#fff')
        .attr('rx', '5')
        .attr('opacity', 0.95)

        areaTooltip
        .append('rect')
        .attr('width',Lv1data.LV2 == 'MAINPLANT' ? 490 :220)
        .attr('height',132)
        .attr('fill', '#fff')
        .attr('rx', '5')
        .attr('opacity', 0.95)
  
        // areaTooltip
        // .append('path')
        // // .attr('transform', `translate(30, -6) rotate(0)`) //top
        // .attr('transform', `translate(30, 106) rotate(180)`) //under
        // .attr('fill', '#fff')
        // .attr('d', 'M5,0l5,6H0Z')
        // .attr('opacity', 0.95)
        // ---------------------------------------------------------------------------------
        if(c.LV2 =='MAINPLANT'){
          areaTooltip
          .append('polygon')
          .attr('id', 'Mask_MAINPLANT')
          .attr('transform', `translate(-70, 300)`)
          .attr('points', `51.932,0 229.117,7.757 178.631,62.612 51.932,59.213 16.01,52.417 0,36.398 2.903,18.922 35.427,12.126`)
          .attr('fill', '#FFCC80').style('visibility', 'visible').style('opacity', 0)
        }
        if(c.LV2 =='OP'){
        areaTooltip
        .append('polygon')
        .attr('id', 'Mask_OP')
        .attr('transform', `translate(-720, 315)`)
        .attr('points', `53.264,200.437 129.063,205.003 144.811,185 127.436,185.375 124.231,189.001 77.282,186.752 119.686,139 42.698,138.633 87.936,92.625 34.689,92.238 81.185,44.371 186.061,44 194.936,34.25 523.826,7.107 581.811,53.125 588.561,53.125 588.686,73 689.436,90.25 1014.686,240.875 1063.936,374 1079.186,380.5 1108.186,450.5 1178.936,474 1195.936,472.75 1198.686,465.75 1217.186,461 1217.186,453 1177.436,379.5 1146.186,339.5 1118.686,331.25 1114.436,312.25 1100.436,305.5 1068.936,306 1076.436,343.75 1065.936,345.75 1065.25,362.895 1017.561,237.472 690.436,87.875 591.561,71.375 591.561,49.625 582.311,50 529.107,6.694 554.189,4.166 567.811,20.875 586.936,18.125 571.686,0 193.936,31.75 184.186,41.5 79.436,41.5 25.561,96.25 80.064,95.76 34.936,141.625 0,177.15 41.186,179.75 70.436,182.5`)
        .attr('fill', '#BA68C8').style('visibility', 'visible').style('opacity', 0)
        }
        //------------------------------------------------------------tooltip inner tex
        areaTooltip
        .append('rect')
        .attr('transform', `translate(5, 5)`)
        .attr('width',210)
        .attr('height',20)
        .attr('rx',3)
        .style('fill', '#e0e0df')
        areaTooltip
        .append('text')
        .attr('id', 'value_code_item')
        .attr('transform', `translate(112, 19)`)
        .style('font-size', 12)
        .style('fill', '#000')
        .attr('text-anchor', 'middle')
        .text(Lv1data.LV2 == 'MAINPLANT' ? 'Main Plant': 'Offplots' )

        let areaPieChart = areaTooltip 
        .append('g').attr('class','piechart')
        .attr('transform', `translate(0, 80)`)

        let style = {
          id: 'CERT',
          x: 40,y: 0,ty: 4,
          name: Number(Lv1data.OV_PLAN_PROG).toFixed(1),
          sort: 'N',
        }
        let data = [
          {title: 'Completed',  value: Lv1data.OV_PLAN_PROG },
          {title: 'Remain',     value: 100-Lv1data.OV_PLAN_PROG }
        ]
        let Circle = {
          CircleDiameter: 33,
          CircleColorSet: ['SkyBlue', 'LightGray'],
          CircleHoleSize: 22,
          CircleStackSize: 2,
          CircleValueSize: 12,
          CircleSliceSize: 12,
          CircleTitleSize: 12,
          CircleLegendSpace: 60,
        }
        let Guideline = {
          GuideDisplay: 'N',
        }
        areaPieChart
        .append('text')
        .attr('transform', `translate(${style.x}, ${style.y - Circle.CircleDiameter - 5})`)
        .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#757575').style('text-anchor', 'middle')
        .text('Plan (%)')
        this.Chart_PieSimple(areaPieChart, data, style, Circle, Guideline)
        //--------------------
        style = {
          id: 'SHI',
          x: 175,y: 0,ty: 4,
          name: Number(Lv1data.OV_ACTUAL_PROG).toFixed(1),
          sort: 'N',
        }
        data = [
          {title: 'Completed',  value: Lv1data.OV_ACTUAL_PROG },
          {title: 'Remain',     value: 100-Lv1data.OV_ACTUAL_PROG }
        ]
        Circle.CircleColorSet = ['Red', 'LightGray']
        
        areaPieChart
        .append('text')
        .attr('transform', `translate(${style.x}, ${style.y - Circle.CircleDiameter - 5})`)
        .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#757575').style('text-anchor', 'middle')
        .text('Actual (%)')
        this.Chart_PieSimple(areaPieChart, data, style, Circle, Guideline)

        // Delta
        let deltaText = areaPieChart
        .append('g')
        .attr('id', 'delta')
        .attr('transform', `translate(67, -16)`)
        deltaText
        .append('text')
        .attr('transform', `translate(40, 12)`)
        .style('font-size', 11)
        .attr('fill', '#757575')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text('Delta (%)')
        deltaText
        .append('text')
        .attr('transform', `translate(19 ,27)`)
        .style('font-size', 15).attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        // .attr('fill', Lv1data.OV_DELTA >= 0 ? '#43A047' : '#D50000')
        // .text(`${Lv1data.OV_DELTA > 0 ? '▲ ' : Lv1data.OV_DELTA === 0 ? '': '▼ '}` + Number(Lv1data.OV_DELTA).toFixed(1))
        .attr('fill',Lv1data.OV_ACTUAL_PROG == 0? '#BDBDBD': Lv1data.OV_ACTUAL_PROG ==100?'#4C8A76': Lv1data.OV_DELTA >=-2 ?'#4C8A76': Lv1data.OV_DELTA >=-5 ?'#F6AD1D' : Lv1data.OV_DELTA <-5 ?'#E84D24':'#000')
        .text(`${Lv1data.OV_ACTUAL_PROG ==100? '▼ ' :Lv1data.OV_ACTUAL_PROG == 0? '○ ':'● '}`)
        deltaText
        .append('text')
        .attr('transform', `translate(60 ,27)`)
        .style('font-size', 12).attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .attr('fill',Lv1data.OV_ACTUAL_PROG == 0? '#BDBDBD': Lv1data.OV_ACTUAL_PROG ==100?'#4C8A76': Lv1data.OV_DELTA >=-2 ?'#4C8A76': Lv1data.OV_DELTA >=-5 ?'#F6AD1D' : Lv1data.OV_DELTA <-5 ?'#E84D24':'#000')
        .text(Number(Lv1data.OV_DELTA).toFixed(1))


        if(Lv1data.LV2 == 'MAINPLANT'){
          let progBarGroup = areaPieChart
          .append('g').attr('class','progresBarGroup')
          .attr('transform', `translate(226, -65)`).style('font-family','roboto')
    
          let progBar = progBarGroup
          .append('g').attr('class','progresBar')
          .attr('transform', `translate(50, 0)`)
    
          //DED, Procurement, Construction 글자
          this.Status.forEach((d,i)=>{
            progBarGroup
            .append('text')
            .attr('transform', `translate(0, ${37+i*30})`) 
            .style('font-size',11).attr('text-anchor', 'start').attr('fill','#424242')
            .text(d.text)
            progBarGroup
            .append('line')
            .attr('x1', 0).attr('y1', `${48+i*32}`).attr('x2', 250).attr('y2', `${48+i*32}`).style('stroke', '#E0E0E0').style('stroke-width', 0.5)
    
            progBar
            .append('text')
            .attr('transform', `translate(156, ${37+i*30})`)
            .style('font-size', 15).attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
            // .attr('fill', Lv1data[d.refColumn] > 0 ? '#43A047' : Lv1data[d.refColumn] < 0 ?'#D50000' :'#E0E0E0')
            // .text(`${Lv1data[d.refColumn] > 0 ? '▲ ' :Lv1data[d.refColumn] === 0 ? '' : '▼ '}`+Number(Lv1data[d.refColumn]).toFixed(1)+'%')
            .attr('fill',Lv1data[d.refColumn]== 0? '#BDBDBD': Lv1data[d.refColumn] ==100?'#4C8A76': Lv1data[d.refColumn] >=-2 ?'#4C8A76': Lv1data[d.refColumn] >=-5 ?'#F6AD1D' : Lv1data[d.refColumn] <-5 ?'#E84D24':'#000')
            .text(`${Lv1data[d.refColumn] ==100? '▼ ' :Lv1data[d.refColumn] === 0 ? '○ ' : '● '}`)
            progBar
            .append('text')
            .attr('transform', `translate(205, ${37+i*30})`)
            .style('font-size', 11).attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
            .attr('fill',Lv1data[d.refColumn]== 0? '#BDBDBD': Lv1data[d.refColumn] ==100?'#4C8A76': Lv1data[d.refColumn] >=-2 ?'#4C8A76': Lv1data[d.refColumn] >=-5 ?'#F6AD1D' : Lv1data[d.refColumn] <-5 ?'#E84D24':'#000')
            .text(Number(Lv1data[d.refColumn]).toFixed(1)+'%')
          
          })

          //DED, Procurement, Construction 에 해당하는 bar progress
          const barScale = d3.scaleLinear()
          .domain([0,100])
          .range([0,40])
          
          this.attrs.forEach((attr, i) => {
            //PROG Background Bar
            progBar
            .append('rect')
            .attr('x', 70).attr('y', `${18+i*16}`).attr('width', 40).attr('height', 10)
            .attr('fill', '#fff').attr('stroke', attr.fColor) .attr('stroke-width', 0.5)
            //PROG Actual Bar
            progBar
            .append('rect')
            .attr('x', 70).attr('y', `${18+i*16}`)
            .attr('width',0).attr('height',10).attr('fill',attr.tColor)
            .transition().duration(100)
            .attr('width',Number(barScale(Lv1data[attr.refColumn])).toFixed(1))
    
            progBar
            .append('text')
            .attr('transform', `translate(60, ${27+i*16})`) 
            .style('font-size',11).attr('text-anchor', 'end').attr('fill',attr.tColor)
            .text(attr.text)
    
            progBar
            .append('text')
            .attr('transform', `translate(120, ${27+i*16})`) 
            .style('font-size',11).attr('text-anchor', 'start').attr('fill','#424242')
            .text(Lv1data[attr.refColumn] > 0? Number(Lv1data[attr.refColumn]).toFixed(1) +'%':'')
          })
        }
      })
    },
    drawItemTooltipGroup() {
      
      this.elProps.coordinates.filter(c => c.STAGE == this.stage && c.DIRECT != null).forEach(c => {
        this.state = c.PARENT
        // let data = this.Queries.SQL2.find(d => (typeof c.LV2 != 'undefined' && d.LV2 == c.LV2))
        let data = this.DataItems.find(d => (typeof c.LV2 != 'undefined' && d.LV2 == c.LV2 && d.LV3 == 'OVERALL'))
        // console.log(data)

        // let areaItemTooltip = this.floatGroup
        // .append('g')
        // .attr('class', 'area_item_tooltip')
        // .attr('transform', `translate(${c.x}, ${c.y})`)
        // .attr('filter', 'url(#dropshadow)')
        // // .style('cursor', 'pointer')
        // .on('click', () => { 
        //   // console.log(this.levelCode, c.STAGE)
        //   if(c.STAGE == 'Modules' || c.STAGE == 'Common') return
        //   this.levelCode = c.LV3 || c.LV2 
        //   let dStage = {
        //     stage: this.stage,
        //     item: c.LV3 || c.LV2
        //   }
        //   this.selectedItems.push(dStage)
        //   let ran = Math.random()
        //   this.cValue = this.cValue == ran ? ran + 1 : ran          
        //   this.loadInformation('item')
        // })
        
        let areaItemTooltip = this.floatGroup
        .append('g')
        .attr('class', 'areaItem_tooltip')
        .attr('transform', `translate(${c.x}, ${c.y})`)
        .style('font-family', 'Roboto-Regular, Roboto')
        .attr('filter', 'url(#dropshadow)')
        if(this.stage == 'Mainplant'){
        areaItemTooltip
        .style('cursor', 'pointer')
        .on('mouseover', () => {
          if(c.LV2 == 'P1' || c.LV2 == 'P3'  ){
            d3.select(`#Mask_${c.LV2}`).transition().style('opacity', 0.3)
            d3.select(`#Mask_${c.LV2}_Cross`).transition().style('opacity', 0.3)
          }else{
            d3.select(`#Mask_${c.LV2}`).transition().style('opacity', 0.3)
          }
        })
        .on('mouseout', () => {
          if(c.LV2 == 'P1' || c.LV2 == 'P3'  ){
            d3.select(`#Mask_${c.LV2}`).transition().style('opacity', 0)
            d3.select(`#Mask_${c.LV2}_Cross`).transition().style('opacity', 0)
          }else{
            d3.select(`#Mask_${c.LV2}`).transition().style('opacity', 0)
          }
        })
        .on('click', () => {
          let dStage = {
            stage: c.GOTO,
            item: ''
          }
          this.selectedItems.push(dStage)
          this.cValue = this.cValue == 2 ? 3 : 2
          this.gotoStage(c.GOTO)
        })
      }

        areaItemTooltip
        .append('rect')
        .attr('width', 280)
        .attr('height', 150) 
        .attr('fill', '#fff')
        .attr('rx', '5')
        .attr('opacity', 0.9)
  
        // if(c.DIRECT == 'R'){
        //   areaItemTooltip
        //   .append('path')
        //   .attr('transform', `translate(166, 6) rotate(90)`)
        //   .attr('fill', '#fff')
        //   .attr('d', 'M5,0l5,6H0Z')
        //   .attr('opacity', 0.9)
        // } else{
        //   areaItemTooltip
        //   .append('path')
        //   .attr('transform', `translate(-6, 18) rotate(-90)`)
        //   .attr('fill', '#fff')
        //   .attr('d', 'M5,0l5,6H0Z')
        //   .attr('opacity', 0.9)
        // }

        if(c.LV2 == 'P2'){
          areaItemTooltip
        .append('polygon')
        .attr('id',`Mask_P2`)
        .attr('transform', `translate(-260, -280)`)
        .attr('points', `0,66.648 403.68,256 475.827,223.27 370.804,171.215 492,114.137 246,0 124.685,56.603 68.521,31.945`)
        .attr('fill', `${c.COLOR}`).style('visibility', 'visible').style('opacity', 0)

        } else if(c.LV2 == 'P1'){
          areaItemTooltip
          .append('polygon')
          .attr('id','Mask_P1')
          .attr('transform', `translate(-364, 164)`)
          .attr('points', `1.853,52.492 83.131,12.31 173.999,55.232 290.894,0 480,88.565 362.127,145.187 326.511,127.378 
          246.145,168`)
          .attr('fill', `${c.COLOR}`).style('visibility', 'visible').style('opacity', 0)

          areaItemTooltip
          .append('polygon')
          .attr('id','Mask_P1_Cross')
          .attr('transform', `translate(0, 175)`)
          // .attr('points', `0,133.516 284.281,0 403,57.046 118.268,191`)
          .attr('points', `1.06,134.15 286.49,1.03 403.71,56.66 238.81,134.81 261.99,146.07 214.3,171.23 187.81,157.32 120.26,191.76 `)
          .attr('fill', `#FFA726`).style('visibility', 'visible').style('opacity', 0)
          
        } else {

        areaItemTooltip
        .append('polygon')
        .attr('id','Mask_P3')
        .attr('transform', `translate(-890, -345)`)
        .attr('points', `0.74,107.33 90.14,64.28 199.41,20.58 268.95,53.69 310.67,33.82 
        331.2,44.42 397.42,11.3 466.3,44.42 560.34,0.05 804.05,115.28 1033.85,6.01 1278.22,124.55 960.34,273.56 937.16,290.11 
        809.94,227.58 872.65,196.23 899.23,210.03 949.29,186.29 927.61,173.39 1094.84,95.45 970.97,37.52 802.97,116.87 614.45,26.29 
        496.39,82.42 406.58,39.84 199.1,136.23 148,110.68 59.74,150.55 136.39,195.06 297.81,270.55 258.35,288.79 229.87,287.46 
        111.99,230.51 134.06,194.68 56.65,150.16 64.31,145.74 `)
        .attr('fill', `${c.COLOR}`).style('visibility', 'visible').style('opacity', 0)

        areaItemTooltip
        .append('polygon')
        .attr('id','Mask_P3_Cross')
        .attr('transform', `translate(-200, -305)`)
        .attr('points', `1.06,134.15 286.49,1.03 403.71,56.66 238.81,134.81 261.99,146.07 214.3,171.23 187.81,157.32 120.26,191.76 `)
        .attr('fill', `#FFA726`).style('visibility', 'visible').style('opacity', 0)
        }

        //------------------------------------------------------------tooltip inner textWrap
        let textWrap = areaItemTooltip
        .append('g')
        .attr('transform', `translate(0, 0)`)

        //title
        textWrap
        .append('rect')
        .attr('transform', `translate(6, 6)`)
        .attr('width',66)
        .attr('height',28)
        .attr('rx',3)
        .style('fill', data.LV2 == 'P1' ?'#EF9A9A' : data.LV2 == 'P2' ? '#B39DDB' : data.LV2 == 'P3' ? '#FFF176': '#DCE775')

        textWrap
        .append('text')
        .attr('transform', `translate(40, 23)`)
        .style('font-size', 12.5)
        .style('fill', '#000')
        .attr('text-anchor', 'middle')
        .text(this.stage == 'Mainplant' ? data.LV3_DESCR : data.LV2)
        // .text(this.DataItems.filter((d) => {
        //   return this.stage === 'Mainplant' ? d.LV2 == data.LV2 :d.LV3 == data.LV3})[0].LV3_DESCR)

        //###------------------------------------------------------tooitip Progress
        let tooltipchart = textWrap
        .append('g')
        .attr('transform', `translate(74, 6)`) 

        tooltipchart
        .append('rect')
        .attr('transform', `translate(0, 0)`)
        .attr('width',200)
        .attr('height',28)
        .attr('rx',3)
        .style('fill', '#E0E0E0')
        .attr('opacity', 0.9)
        // Plan Cuml Label & Value
        tooltipchart
        .append('text')
        .attr('transform', `translate(8, 17)`)
        .style('font-size', 11)
        .style('fill', '#757575')
        .attr('text-anchor', 'start')
        .text('Plan: ')
        tooltipchart
        .append('text')
        .attr('transform', `translate(70, 18)`)
        .style('font-size', 12)
        .style('fill', '#2196F3')
        .attr('text-anchor', 'end')
        .text(data.OV_PLAN_PROG == 0 ? '' :Number(data.OV_PLAN_PROG).toFixed(1)+'%')
        // .text(this.DataItems.filter((d) => { return this.stage === 'Mainplant'? d.LV2 === data.LV2 : d.LV3 === data.LV3})[0].PLAN_PROG.toFixed(1)+'%')
        
        // Actual Cuml Label & Value
        tooltipchart
        .append('text')
        .attr('transform', `translate(100, 17)`)
        .style('font-size', 11)
        .style('fill', '#757575')
        .attr('text-anchor', 'start')
        .text('Actual: ')
        tooltipchart
        .append('text')
        .attr('transform', `translate(180, 18)`)
        .style('font-size', 12)
        .style('fill','#E53935').attr('text-anchor', 'end')
        .text(data.OV_ACTUAL_PROG  == 0 ? '' : Number(data.OV_ACTUAL_PROG).toFixed(1)+'%')


        let progBarGroup = areaItemTooltip
        .append('g').attr('class','progresBarGroup')
        .attr('transform', `translate(10, 30)`).style('font-family','roboto')

        let progBar = progBarGroup
        .append('g').attr('class','progresBar')
        .attr('transform', `translate(50, 0)`)

        //DED, Procurement, Construction 글자
        this.Status.forEach((d,i)=>{
          progBarGroup
          .append('text')
          .attr('transform', `translate(0, ${37+i*30})`) 
          .style('font-size',11).attr('text-anchor', 'start').attr('fill','#424242')
          .text(d.text)
          progBarGroup
          .append('line')
          .attr('x1', 0).attr('y1', `${48+i*32}`).attr('x2', 250).attr('y2', `${48+i*32}`).style('stroke', '#E0E0E0').style('stroke-width', 0.5)

          progBar
          .append('text')
          .attr('transform', `translate(162, ${37+i*30})`)
          .style('font-size', 15).attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
          .attr('fill',data[d.refColumn]== 0? '#BDBDBD': data[d.refColumn] ==100?'#4C8A76': data[d.refColumn] >=-2 ?'#4C8A76': data[d.refColumn] >=-5 ?'#F6AD1D' : data[d.refColumn] <-5 ?'#E84D24':'#000')
          .text(`${data[d.refColumn] ==100? '▼ ' :data[d.refColumn] === 0 ? '○ ' : '● '}`)
          progBar
          .append('text')
          .attr('transform', `translate(212, ${37+i*30})`)
          .style('font-size', 11).attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
          .attr('fill',data[d.refColumn]== 0? '#BDBDBD': data[d.refColumn] ==100?'#4C8A76': data[d.refColumn] >=-2 ?'#4C8A76': data[d.refColumn] >=-5 ?'#F6AD1D' : data[d.refColumn] <-5 ?'#E84D24':'#000')
          .text(Number(data[d.refColumn]).toFixed(1)+'%')
        
        })
        
        //DED, Procurement, Construction 에 해당하는 bar progress
        const barScale = d3.scaleLinear()
        .domain([0,100])
        .range([0,40])
        
        this.attrs.forEach((attr, i) => {
          //PROG Background Bar
          progBar
          .append('rect')
          .attr('x', 70).attr('y', `${18+i*16}`).attr('width', 40).attr('height', 10)
          .attr('fill', '#fff').attr('stroke', attr.fColor) .attr('stroke-width', 0.5)
          //PROG Actual Bar
          progBar
          .append('rect')
          .attr('x', 70).attr('y', `${18+i*16}`)
          .attr('width',0).attr('height',10).attr('fill',attr.tColor)
          .transition().duration(100)
          .attr('width',Number(barScale(data[attr.refColumn])).toFixed(1))

          progBar
          .append('text')
          .attr('transform', `translate(60, ${27+i*16})`) 
          .style('font-size',11).attr('text-anchor', 'end').attr('fill',attr.tColor)
          .text(attr.text)

          progBar
          .append('text')
          .attr('transform', `translate(120, ${27+i*16})`) 
          .style('font-size',11).attr('text-anchor', 'start').attr('fill','#424242')
          .text(data[attr.refColumn] > 0? Number(data[attr.refColumn]).toFixed(1) +'%':'')
        })
      })
      
    },
    drawPackageTooltipGroup() {
      // console.log(this.stage)
      this.elProps.coordinates.filter(c => c.STAGE == this.stage && c.DIRECT != null).forEach(c => {
        // console.log(c)
        this.state = c.PARENT
        let data = this.DataItems.find(d => (typeof c.LV2 != 'undefined' && d.LV2 == c.LV2 && d.LV3 == c.LV3 ))
        // console.log(data)

        let PackageTooltip = this.floatGroup
        .append('g')
        .attr('class', 'pack_tooltip')
        .attr('transform', `translate(${c.x}, ${c.y})`)
        .style('font-family', 'Roboto-Regular, Roboto')
        .attr('filter', 'url(#dropshadow)')
        .style('cursor', 'pointer')
        .on('mouseover', () => {
          d3.select(`#Mask_${c.LV3}`).transition().style('opacity', 0.2)
        })
        .on('mouseout', () => {
          d3.select(`#Mask_${c.LV3}`).transition().style('opacity', 0)
        })
        .on('click', () => {
          // console.log(this.selectedItems)
          // console.log(this.stage)
          let dStage = {
            stage: c.GOTO,
            item: ''
          }
          this.selectedItems.push(dStage)
          this.cValue = this.cValue == 3 ? 4 : 3
          this.gotoStage(c.GOTO)
        })

        PackageTooltip
        .append('rect')
        .attr('width', 280)
        .attr('height', 150) // topside일때의 flaot의 height 달라지게
        .attr('fill', '#fff')
        .attr('rx', '5')
        .attr('opacity', 0.9)
  
        // if(c.DIRECT == 'R'){
        //   PackageTooltip
        //   .append('path')
        //   .attr('transform', `translate(166, 6) rotate(90)`)
        //   .attr('fill', '#fff')
        //   .attr('d', 'M5,0l5,6H0Z')
        //   .attr('opacity', 0.9)
        // } else{
        //   PackageTooltip
        //   .append('path')
        //   .attr('transform', `translate(-6, 18) rotate(-90)`)
        //   .attr('fill', '#fff')
        //   .attr('d', 'M5,0l5,6H0Z')
        //   .attr('opacity', 0.9)
        // }

        if(c.LV3 == 'PP'){
          PackageTooltip
          .append('polygon')
          .attr('id', 'Mask_PP')
          .attr('transform', `translate(-130, 280)`)
          .attr('points', `0,154.777 326.965,0.008 585.869,120.074 257.103,278.008`)
          .attr('fill', `${c.COLOR}`).style('visibility', 'visible').style('opacity', 0)
        }
        if(c.LV3 == 'LDPE'){
          PackageTooltip
          .append('polygon')
          .attr('id', 'Mask_LDPE')
          .attr('transform', `translate(65, 175)`)
          .attr('points', `2.311,155.233 328.338,0 546.146,101.808 220.575,260`)
          .attr('fill', `${c.COLOR}`).style('visibility', 'visible').style('opacity', 0)
        }
        if(c.LV3 == 'GPPE'){
          PackageTooltip
          .append('polygon')
          .attr('id', 'Mask_GPPE')
          .attr('transform', `translate(-220, 230)`)
          .attr('points', `0,155.689 328.794,0 520,89.936 190.895,248 `)
          .attr('fill', `${c.COLOR}`).style('visibility', 'visible').style('opacity', 0)
        }
        if(c.LV3 == 'DW'){
          PackageTooltip
          .append('polygon')
          .attr('id', 'Mask_DW')
          .attr('transform', `translate(-280, 180)`)
          .attr('points', `0,81.667 167.667,0 239.333,34.667 71,116.333`)
          .attr('fill', `${c.COLOR}`).style('visibility', 'visible').style('opacity', 0)
        }
        if(c.LV3 == 'BOILER'){
          PackageTooltip
          .append('polygon')
          .attr('id', 'Mask_BOILER')
          .attr('transform', `translate(-502, 215)`)
          .attr('points', `0,79 169.667,0 277,51 110.667,132`)
          .attr('fill', `${c.COLOR}`).style('visibility', 'visible').style('opacity', 0)
        }
        if(c.LV3 == 'BTX'){
          PackageTooltip
          .append('polygon')
          .attr('id', 'Mask_BTX')
          .attr('transform', `translate(160, -103)`)
          .attr('points', `0,79 167.333,0 247.333,35.5 77.667,117.167`)
          .attr('fill', `${c.COLOR}`).style('visibility', 'visible').style('opacity', 0)
        }
        if(c.LV3 == 'GHU'){
          PackageTooltip
          .append('polygon')
          .attr('id', 'Mask_GHU')
          .attr('transform', `translate(-70, -180)`)
          .attr('points', `0,79 167.667,0 234,28.333 63,111 `)
          .attr('fill', `${c.COLOR}`).style('visibility', 'visible').style('opacity', 0)
        }
        if(c.LV3 == 'BD'){
          PackageTooltip
          .append('polygon')
          .attr('id', 'Mask_BD')
          .attr('transform', `translate(-307, -285)`)
          .attr('points', `0,79 169.667,0 277,51 110.667,132`)
          .attr('fill', `${c.COLOR}`).style('visibility', 'visible').style('opacity', 0)
        }
        if(c.LV3 == 'OR'){
          PackageTooltip
          .append('polygon')
          .attr('id', 'Mask_OR')
          .attr('transform', `translate(-820, 155)`)
          .attr('points', `0,111.667 236,0 620.333,182.333 381,292 `)
          .attr('fill', `${c.COLOR}`).style('visibility', 'visible').style('opacity', 0)
        }
        if(c.LV3 == 'DIB'){
          PackageTooltip
          .append('polygon')
          .attr('id', 'Mask_DIB')
          .attr('transform', `translate(-550, -245)`)
          .attr('points', `0,79 167.667,0 234,28.333 63,111 `)
          .attr('fill', `${c.COLOR}`).style('visibility', 'visible').style('opacity', 0)
        }
        if(c.LV3 == 'FUR'){
          PackageTooltip
          .append('polygon')
          .attr('id', 'Mask_FUR')
          .attr('transform', `translate(-245, 210)`)
          .attr('points', `0,35.333 72.667,0 243.397,82.888 171,115`)
          .attr('fill', `${c.COLOR}`).style('visibility', 'visible').style('opacity', 0)
        }
        //------------------------------------------------------------tooltip inner textWrap
        let textWrap = PackageTooltip
        .append('g')
        .attr('transform', `translate(0, 0)`)

        //title
        textWrap
        .append('rect')
        .attr('transform', `translate(8, 6)`)
        .attr('width',64)
        .attr('height',28)
        .attr('rx',3)
        .style('fill', data.LV3 == c.LV3 ? c.COLOR : '#cdcdcd' )

        let currentLv = 
        textWrap
        .append('text')
        .attr('transform', `translate(38, 23)`)
        .style('font-size', 11)
        .style('fill', '#000')
        .attr('text-anchor', 'middle')
        .text(data.LV3)

        //###------------------------------------------------------tooitip Progress
        let tooltipchart = textWrap
        .append('g')
        .attr('transform', `translate(74, 6)`) 

        tooltipchart
        .append('rect')
        .attr('transform', `translate(0, 0)`)
        .attr('width',200)
        .attr('height',28)
        .attr('rx',3)
        .style('fill', '#E0E0E0')
        .attr('opacity', 0.9)
        // Plan Cuml Label & Value
        tooltipchart
        .append('text')
        .attr('transform', `translate(8, 17)`)
        .style('font-size', 11)
        .style('fill', '#757575')
        .attr('text-anchor', 'start')
        .text('Plan: ')
        tooltipchart
        .append('text')
        .attr('transform', `translate(70, 18)`)
        .style('font-size', 12)
        .style('fill', '#2196F3')
        .attr('text-anchor', 'end')
        .text(data.OV_PLAN_PROG == 0 ? '' :Number(data.OV_PLAN_PROG).toFixed(1)+'%')
        // .text(this.DataItems.filter((d) => { return this.stage === 'Mainplant'? d.LV2 === data.LV2 : d.LV3 === data.LV3})[0].PLAN_PROG.toFixed(1)+'%')
        
        // Actual Cuml Label & Value
        tooltipchart
        .append('text')
        .attr('transform', `translate(100, 17)`)
        .style('font-size', 11)
        .style('fill', '#757575')
        .attr('text-anchor', 'start')
        .text('Actual: ')
        tooltipchart
        .append('text')
        .attr('transform', `translate(180, 18)`)
        .style('font-size', 12)
        .style('fill','#E53935').attr('text-anchor', 'end')
        .text(data.OV_ACTUAL_PROG == 0 ? '' :Number(data.OV_ACTUAL_PROG).toFixed(1)+'%')

        let progBarGroup = PackageTooltip
        .append('g').attr('class','progresBarGroup')
        .attr('transform', `translate(10, 30)`).style('font-family','roboto')

        let progBar = progBarGroup
        .append('g').attr('class','progresBar')
        .attr('transform', `translate(50, 0)`)

        //DED, Procurement, Construction 글자
        this.Status.forEach((d,i)=>{
          progBarGroup
          .append('text')
          .attr('transform', `translate(0, ${37+i*30})`) 
          .style('font-size',11).attr('text-anchor', 'start').attr('fill','#424242')
          .text(d.text)
          progBarGroup
          .append('line')
          .attr('x1', 0).attr('y1', `${48+i*32}`).attr('x2', 250).attr('y2', `${48+i*32}`).style('stroke', '#E0E0E0').style('stroke-width', 0.5)

          progBar
          .append('text')
          .attr('transform', `translate(165, ${37+i*30})`)
          .style('font-size', 11)
          .attr('fill', data[d.refColumn] > 0 ? '#43A047' : data[d.refColumn] < 0 ?'#D50000' :'#E0E0E0')
          .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
          .text(`${data[d.refColumn] > 0 ? '▲ ' :data[d.refColumn] === 0 ? '' : '▼ '}`+Number(data[d.refColumn]).toFixed(1)+'%')
        })
        
        //DED, Procurement, Construction 에 해당하는 bar progress
        const barScale = d3.scaleLinear()
        .domain([0,100])
        .range([0,40])
        
        this.attrs.forEach((attr, i) => {
          //PROG Background Bar
          progBar
          .append('rect')
          .attr('x', 70).attr('y', `${18+i*16}`).attr('width', 40).attr('height', 10)
          .attr('fill', '#fff').attr('stroke', attr.fColor) .attr('stroke-width', 0.5)
          //PROG Actual Bar
          progBar
          .append('rect')
          .attr('x', 70).attr('y', `${18+i*16}`)
          .attr('width',0).attr('height',10).attr('fill',attr.tColor)
          .transition().duration(100)
          .attr('width',Number(barScale(data[attr.refColumn])).toFixed(1))

          progBar
          .append('text')
          .attr('transform', `translate(60, ${27+i*16})`) 
          .style('font-size',11).attr('text-anchor', 'end').attr('fill',attr.tColor)
          .text(attr.text)

          progBar
          .append('text')
          .attr('transform', `translate(120, ${27+i*16})`) 
          .style('font-size',11).attr('text-anchor', 'start').attr('fill','#424242')
          .text(data[attr.refColumn] > 0? Number(data[attr.refColumn]).toFixed(1) +'%':'')
        })
      })
    },
    drawPackageAreaTooltipGroup() {
      // this.elProps.coordinates.filter(c => c.LV3 == this.stage && c.DIRECT != null).forEach(c => {
      this.elProps.coordinates.filter(c => c.STAGE == this.stage && c.DIRECT != null).forEach(c => {
        // console.log(c,"------")
        this.state = c.STAGE
        let data = this.Queries.SQL2.find(d => (typeof c.LV2 != 'undefined' && d.LV2 == c.LV2 && d.LV3 == c.LV3 ))
        // console.log(data,"-------")

        let areaItemTooltip = this.floatGroup
        .append('g')
        .attr('class', 'area_tooltip')
        .attr('transform', `translate(${c.x}, ${c.y})`)
        .attr('filter', 'url(#dropshadow)')
        // .style('cursor', 'pointer')
        // .on('click', () => {
        //   let dStage = {
        //     stage: c.GOTO,
        //     item: ''
        //   }
        //   this.selectedItems.push(dStage)
        //   this.cValue = this.cValue == 3 ? 4 : 3
        //   this.gotoStage(c.GOTO)
        // })

    
        areaItemTooltip
        .append('rect')
        .attr('width', 160)
        .attr('height', 40) // topside일때의 flaot의 height 달라지게
        .attr('fill', '#fff')
        .attr('rx', '5')
        .attr('opacity', 0.9)
  
        if(c.DIRECT == 'R'){
          areaItemTooltip
          .append('path')
          .attr('transform', `translate(166, 6) rotate(90)`)
          .attr('fill', '#fff')
          .attr('d', 'M5,0l5,6H0Z')
          .attr('opacity', 0.9)
        } else{
          areaItemTooltip
          .append('path')
          .attr('transform', `translate(-6, 18) rotate(-90)`)
          .attr('fill', '#fff')
          .attr('d', 'M5,0l5,6H0Z')
          .attr('opacity', 0.9)
        }
        //------------------------------------------------------------topside에서는 button이 생기게 만들자
        // if(data.LV2 == (c.STAGE).toUpperCase() && data.LV2 == 'MODULES')
          //  summary button 눌렀을때
          let summaryBtn = areaItemTooltip
          .append('g')
          .attr('class', 'summary_Button')
          .attr('transform', `translate(0, 0)`)
          .style('cursor', 'pointer')
          .on('click', () => { 
            // console.log(data.LV3)
            this.levelCode = c.LV3 || c.LV2 
            let dStage = {
              stage: this.stage,
              item: c.LV3 || c.LV2
            }
            this.selectedItems.push(dStage)
            let ran = Math.random()
            this.cValue = this.cValue == ran ? ran + 1 : ran          
            this.loadInformation('item')
          })
          // summaryBtn
          // .append('rect')
          // .attr('transform', `translate(8, 6)`)
          // .attr('width', 69)
          // .attr('height', 19)
          // .attr('rx',3)
          // .attr('fill', '#B2DFDB')
          // summaryBtn
          // .append('text')
          // .attr('transform', `translate(42, 18)`)
          // .style('font-size', 10)
          // .style('fill', '#424242')
          // .attr('text-anchor', 'middle')
          // .text('Summary')

          //  dashboard button 눌렀을때 각 페이지로 이동
          let linkBtn = areaItemTooltip
          .append('g')
          .attr('class', 'link_Button')
          .attr('transform', `translate(0, 0)`)
          .style('cursor', 'pointer')
          .on('click', () => {

            // console.log(data.LV3) //이걸로 data.LV3을 클릭하는지 확인해보고

            let request_ = this.getRequestColumnProps(data.LV3, {}, this.ColumnProps)
            if(request_) this.$emit('request-action', request_)

            // dashboard modal 로 나오게 바꾼다! (원래는 path로 페이지 이동이었지만 지금은 columnProps로 각각의 module modal id값 바뀌어야하기때문에 바뀌었음)
            
            // let request_ = {
            //   dataType: 'row',    
            //   action: {
            //     type: 'direct',
            //     target: 'url',
            //     path: `/service/construction/construction_dashboards${this.path[data.LV3]}`
            //   },
            //   filters: {}
            // }
            // this.$emit('request-action', request_)
          })
          // linkBtn
          // .append('rect')
          // .attr('transform', `translate(82, 6)`)
          // .attr('width', 69)
          // .attr('height', 19)
          // .attr('rx',3)
          // .attr('fill', '#80CBC4')
          // linkBtn
          // .append('text')
          // .attr('transform', `translate(116, 18)`)
          // .style('font-size', 10)
          // .style('fill', '#fff')
          // .attr('text-anchor', 'middle')
          // .text('Dashboard')

        //------------------------------------------------------------tooltip inner textWrap
        let textWrap = areaItemTooltip
        .append('g')
        .attr('transform', `translate(0, 0)`)

        //title
        textWrap
        .append('rect')
        .attr('transform', `translate(8, 6)`)
        .attr('width',60)
        .attr('height',28)
        .attr('rx',3)
        .style('fill', data.LV2 == 'P1' ?'#EF9A9A' : data.LV2 == 'P2' ? '#B39DDB' :'#FFF176' )

        textWrap
        .append('text')
        .attr('transform', `translate(38, 23)`)
        .style('font-size', 11)
        .style('fill', '#000')
        .attr('text-anchor', 'middle')
        .text(this.DataItems.filter((d) => {
          return this.stage === 'Hccp' ? d.LV2 == data.LV2 :d.LV3 == data.LV3})[0].LV3)
        
        // textWrap
        // .append('rect')
        // .attr('transform', `translate(8, 33)`)
        // .attr('width',144)
        // .attr('height',20)
        // .attr('rx',3)
        // .style('fill', '#e0e0df')
        // console.log(data)
        // textWrap
        // .append('text')
        // .attr('transform', `translate(80, 47)`)
        // .style('font-size', 11)
        // .style('fill', '#000')
        // .attr('text-anchor', 'middle')
        // .text(this.DataItems.filter((d) => {
        //   return this.stage === 'Hccp' ? d.LV2 == data.LV2 :d.LV3 == data.LV3})[0].LV3_DESCR)

        //###------------------------------------------------------tooitip Progress
        let tooltipchart = textWrap
        .append('g')
        .attr('transform', `translate(70, 6)`) 

        tooltipchart
        .append('rect')
        .attr('transform', `translate(0, 0)`)
        .attr('width',82)
        .attr('height',28)
        .attr('rx',3)
        .style('fill', '#83d2f5')
        .attr('opacity', 0.9)
        // Plan Cuml Label & Value
        tooltipchart
        .append('text')
        .attr('transform', `translate(8, 8)`)
        .style('font-family', 'Roboto-Regular, Roboto')
        .style('font-size', 10)
        .style('fill', '#757575')
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text('Plan')
        tooltipchart
        .append('text')
        .attr('transform', `translate(8, 20)`)
        .style('font-family', 'Roboto-Regular, Roboto')
        .style('font-size', 11)
        .style('fill', '#fff')
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text(this.DataItems.filter((d) => { return this.stage === 'Hccp'? d.LV2 === data.LV2 : d.LV3 === data.LV3})[0].PLAN_PROG.toFixed(1)+'%')
        
        // Actual Cuml Label & Value
        tooltipchart
        .append('text')
        .attr('transform', `translate(76, 8)`)
        .style('font-family', 'Roboto-Regular, Roboto')
        .style('font-size', 10)
        .style('fill', '#757575')
        .attr('text-anchor', 'end')
        .attr('alignment-baseline', 'middle')
        .text('Actual')
        tooltipchart
        .append('text')
        .attr('transform', `translate(76, 20)`)
        .style('font-family', 'Roboto-Regular, Roboto')
        .style('font-size', 11)
        // .style('fill', this.DataItems.filter((d) => { return this.stage === 'Modules' || this.stage === 'Common' ? d.LV3 === data.LV3 : d.LV2 === data.LV2})[0].PLAN_PROG > this.DataItems.filter((d) => { return this.stage == 'Topside' ? d.LV3 === data.LV3 : d.LV2 === data.LV2})[0].ACTUAL_PROG ? '#B71C1C' :'#fff')
        .attr('text-anchor', 'end')
        .attr('alignment-baseline', 'middle')
        .text(this.DataItems.filter((d) => {
          return this.stage === 'Hccp' ? d.LV2 === data.LV2 : d.LV3 === data.LV3})[0].ACTUAL_PROG.toFixed(1)+'%')
      })
    },
  },
}