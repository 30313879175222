import * as d3 from 'd3'
// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  data:() => ({
    thead: [
      // 42A5F5
      // 90CAF9
      { key: 'Estimated', html: 'Estimated', row: 1, colspan: 2, bColor: '#90CAF9' },
      { key: 'Earned Hrs', html: 'Earned Hrs', row: 1, colspan: 2, bColor: '#83d2f5' },
      { key: 'Complete', html: '% Complete', row: 1, colspan: 3, bColor: '#90CAF9' },
      { key: 'This Week Progress', html: 'This Week Progress', row: 1, colspan: 2, bColor: '#83d2f5' },

      
      { key: 'EST_DOCS', html: 'Docs', row: 2, bColor: '#90CAF9' },
      { key: 'EST_HOURS', html: 'Hours', row: 2, bColor: '#90CAF9' },

      { key: 'EAR_TARGET', html: 'Target', row: 2, bColor: '#83d2f5' },
      { key: 'EAR_ACTUAL', html: 'Actual', row: 2, bColor: '#83d2f5' },

      { key: 'COM_TARGET', html: 'Target', row: 2, bColor: '#90CAF9' },
      { key: 'COM_ACTUAL', html: 'Actual', row: 2, bColor: '#90CAF9' },
      { key: 'COM_DELATA', html: 'Delta', row: 2, bColor: '#90CAF9' },

      { key: 'PWK_TARGET', html: 'Target', row: 2, bColor: '#83d2f5' },
      { key: 'PWK_ACTUAL', html: 'Actual', row: 2, bColor: '#83d2f5' },
    ],
    tbody: [
      { key: 'EST_DOCS', w: 60, align: 'center', bColor: '#F2F2F2'},
      { key: 'EST_HOURS', w: 60,align: 'center', bColor: '#F2F2F2'},

      { key: 'EAR_TARGET', w: 60, align: 'center', bColor: '#D9D9D9'},
      { key: 'EAR_ACTUAL', w: 60, align: 'center', bColor: '#D9D9D9'},
      
      { key: 'COM_TARGET', w: 60, align: 'center', bColor: '#F2F2F2'},
      { key: 'COM_ACTUAL', w: 60, align: 'center', bColor: '#F2F2F2'},
      { key: 'COM_DELATA', w: 60, align: 'center', bColor: '#F2F2F2', tColor: '#FF3D00'},

      { key: 'PWK_TARGET', w: 60, align: 'center', bColor: '#D9D9D9'},
      { key: 'PWK_ACTUAL', w: 60, align: 'center', bColor: '#D9D9D9'},
    ],
    
    prevThead: [
      { key: 'ISSUE', html: 'Issue', bColor: '#90CAF9' },
      { key: 'TARGET', html: 'Target', bColor: '#90CAF9' },
      { key: 'COMPLETED', html: 'Completed', bColor: '#90CAF9' },
      { key: 'DELATA', html: 'Delta', bColor: '#90CAF9' },
      { key: 'TOGO', html: 'To Go', bColor: '#90CAF9' },
    ],
    prevTbody: [
      { key: 'ISSUE', w: 80, align: 'center', type: 'text' },
      { key: 'TARGET', w: 80,align: 'center' },
      { key: 'COMPLETED', w: 80, align: 'center' },
      { key: 'DELATA', w: 80, align: 'center' },
      { key: 'TOGO', w: 80, align: 'center' },
    ],


    
    prsntThead: [
      { key: 'ISSUE', html: 'Issue', bColor: '#90CAF9' },
      { key: 'TARGET', html: 'Target', bColor: '#90CAF9' },
      { key: 'COMPLETED', html: 'Completed', bColor: '#90CAF9' },
      { key: 'DELTA', html: 'Delta', bColor: '#90CAF9' },
      { key: 'OVERDUE', html: 'Overdue', bColor: '#90CAF9', tColor: '#FF3D00' },
      { key: 'AHEAD', html: 'Ahead', bColor: '#90CAF9', tColor: '#43A047' },
      { key: 'TOGO', html: 'To Go', bColor: '#90CAF9' },
    ],
    prsntTbody: [
      { key: 'ISSUE', w: 80, align: 'center', type: 'text' },
      { key: 'TARGET', w: 80,align: 'center' },
      { key: 'COMPLETED', w: 80, align: 'center' },
      { key: 'DELTA', w: 80, align: 'center' },
      { key: 'OVERDUE', w: 80, align: 'center', tColor: '#FF3D00' },
      { key: 'AHEAD', w: 80, align: 'center', tColor: '#43A047' },
      { key: 'TOGO', w: 80, align: 'center' },
    ],
  }),
  methods: {
    Draw_Charts() {

      let svg = this.svg


      // Draw Estimated Table
      let list = this.DataItems

      let group = svg.append('g').attr('class', 'table_group__')
      let table_group = group.append('g').attr('transform', `translate(0.5, 20)`)
      
      let y_ = (this.DataItems.length * 20) + 100

      let table_obj = table_group.append('foreignObject')
        .attr('y',0)
        .attr('width', '100%')
        .attr('height', `${y_}px`)
        .append('xhtml:body')
        .style('position', 'fixed')

      const reducer = (previousValue, currentValue) => previousValue + currentValue
      let widths = this.tbody.map(d => d.w)
      let sumWidth = widths.reduce(reducer)

      let table = table_obj.append('table')
      .style('border-collapse', 'collapse')
      .style('font-size', '11px')
      .style('width', `${sumWidth}px`)
      .style('table-layout', 'fixed')

      let colgroup = table.append('colgroup')
      this.tbody.forEach(k => {
        colgroup.append('col').style('width', k.w + 'px')
      })

      
      let maxRow = Math.max(...this.thead.map(d => d.row))
  
      let thead = table.append('thead')
      for (let r = 1; r <= maxRow; r++) {
        let row = this.thead.filter(d=> d.row === r)
        let tr = thead.append('tr').style('background', '#F5F5F5')

        row.forEach(item => {
          tr.append('th')
          .style('height', '18px')
          .attr('class', 'table_group___th')
          .attr('rowspan', () => item.rowspan ? item.rowspan : '')
          .attr('colspan', () => item.colspan ? item.colspan : '')
          .style('border', 'none')
          .style('padding', '2px')
          .style('font-weight', 'bold')
          .style('font-size', '11px')
          .style('color', '#263238')
          .style('background', item.bColor == '#83d2f5' ? `rgba( 131, 210, 245, 0.7 )` : item.bColor)
          .html(item.html)
        })
      }

      
      let tbody = table.append('tbody')

      let tbodykeys = this.tbody
      if (list.length == 0) {
        let tr = tbody.append('tr')
        tr.append('td')
          .attr('colspan', 9)
          .style('background', '#F5F5F5')
          .style('height', '48px')
          .style('text-align', 'center')
          .style('color', '#263238')
          .style('padding', '0 3px 0 3px')
          .style('font-style', 'italic')
          .html('No data')
      } else {
        for (let r = 0; r < list.length; r++) {
          let tr = tbody.append('tr')
          tr.on('mouseover', () => tr.style('background-color', '#FAFAFA' ))
          tr.on('mouseout', () => tr.style('background-color', 'Transparent' ))
          tbodykeys.forEach((k, i) => {
            let td = tr.append('td')
              .style('background', k.bColor ? k.bColor : '#FAFAFA')
              .style('height', '48px')
              .style('text-align', k.align ? k.align : 'center')
              .style('color', k.tColor ? k.tColor : '#263238')
              .style('padding', '0 3px 0 3px')

              if (k.key == 'EST_DOCS'){
                td.style('cursor', 'pointer')
                .on('mouseover', () => {
                  td.style('opacity',0.5)
                })
                .on('mouseout', () => {
                  td.style('opacity', 1)
                })
                .on('click', () => {

                  // Get Modal Number
                  let columnProps = this.getRequestColumnProps('request', {}, this.ColumnProps)

                  let request_ = {
                    dataType: 'row',
                    action: {
                      type: 'direct',
                      target: 'slide-modal',
                      component: 'ServiceDatatable',
                      id: columnProps.action.id,
                      no: ''
                    },
                    filters     : {...this.FilterValues},
                    iFilters    : {
                      filterString : columnProps.iFilters.filterString,
                      inputFilter  : ''
                    }
                  }
                  
                  this.$emit('request-action', request_)
                })

              }
            
              td.html(list[r][k.key].toLocaleString('en-us'))

          })
        }
      }
    },
    Draw_Charts_Prev() {

      // Draw Estimated Table
      let list = this.Queries.SQL1

      let group = this.svg.append('g').attr('class', 'table_group__')

      group.append('text').attr('transform', `translate(600.5, 14)`)
      .attr('font-size', 12)
      .text('Previous Week (Qty)')

      let table_group = group.append('g').attr('transform', `translate(600.5, 20)`)
      
      let y_ = (list.length * 20) + 100

      let table_obj = table_group.append('foreignObject')
        .attr('y',0)
        .attr('width', '100%')
        .attr('height', `${y_}px`)
        .append('xhtml:body')
        .style('position', 'fixed')

      const reducer = (previousValue, currentValue) => previousValue + currentValue
      let widths = this.prevTbody.map(d => d.w)
      let sumWidth = widths.reduce(reducer)

      let table = table_obj.append('table')
      .style('border-collapse', 'collapse')
      .style('font-size', '11px')
      .style('width', `${sumWidth}px`)
      .style('table-layout', 'fixed')

      let colgroup = table.append('colgroup')
      this.prevTbody.forEach(k => {
        colgroup.append('col').style('width', k.w + 'px')
      })

  
      let thead = table.append('thead')
      let tr = thead.append('tr').style('background', '#F5F5F5')

      this.prevThead.forEach(item => {
        tr.append('th')
        .style('height', '40px')
        .attr('class', 'table_group___th')
        .style('border', 'none')
        .style('padding', '2px')
        .style('font-weight', 'bold')
        .style('font-size', '11px')
        .style('color', '#263238')
        .style('background', item.bColor ? item.bColor : '#F0F1F3')
        .html(item.html)
      })
        

      
      let tbody = table.append('tbody')

      let tbodykeys = this.prevThead
      if (list.length == 0) {
        
        let tr = tbody.append('tr')

        tr.append('td')
          .attr('colspan', 5)
          .style('background', '#F5F5F5')
          .style('height', '40px')
          .style('text-align', 'center')
          .style('color', '#263238')
          .style('padding', '0 3px 0 3px')
          .style('font-style', 'italic')
          .html('No data')

      }else {

        for (let r = 0; r < list.length; r++) {
          let tr = tbody.append('tr')
          tr.on('mouseover', () => tr.style('background-color', '#FAFAFA' ))
          tr.on('mouseout', () => tr.style('background-color', 'Transparent' ))
          tbodykeys.forEach((k, i) => {
            let td = tr.append('td')
              .style('background', r == 0 ? '#F2F2F2' : '#D9D9D9')
              .style('height', '24px')
              .style('text-align', k.align ? k.align : 'center')
              .style('color', k.tColor ? k.tColor : '#263238')
              .style('padding', '0 3px 0 3px')
            
              
              if (i != 0 && i != 3){
                td.style('cursor', 'pointer')
                .on('mouseover', () => {
                  td.style('opacity',0.5)
                })
                .on('mouseout', () => {
                  td.style('opacity', 1)
                })
                .on('click', () => {

                  // Get Modal Number
                  let columnProps = this.getRequestColumnProps('request', {}, this.ColumnProps)

                  let filterString = ''

                  
                  if (k.key == 'TARGET' && list[r].ISSUE == 'IFR') filterString = ` [IFRT] <= DATEADD(WK,-1,[C01_Project].[dbo].[GET_CUTOFF]([DATADATE],6)) `
                  if (k.key == 'COMPLETED' && list[r].ISSUE == 'IFR') filterString = ' [IFR] <= DATEADD(WK,-1,[C01_Project].[dbo].[GET_CUTOFF]([DATADATE],6)) '
                  if (k.key == 'TOGO' && list[r].ISSUE == 'IFR') filterString = ' ([IFR] IS NULL OR [IFR] > DATEADD(WK,-1,[C01_Project].[dbo].[GET_CUTOFF]([DATADATE],6))) '


                  if (k.key == 'TARGET' && list[r].ISSUE == 'IFU') filterString = ` [IFUT] <= DATEADD(WK,-1,[C01_Project].[dbo].[GET_CUTOFF]([DATADATE],6)) ` 
                  if (k.key == 'COMPLETED' && list[r].ISSUE == 'IFU') filterString = ' [IFU] <= DATEADD(WK,-1,[C01_Project].[dbo].[GET_CUTOFF]([DATADATE],6)) '
                  if (k.key == 'TOGO' && list[r].ISSUE == 'IFU') filterString = ' ([IFU] IS NULL OR [IFU] > DATEADD(WK,-1,[C01_Project].[dbo].[GET_CUTOFF]([DATADATE],6))) '

                  let request_ = {
                    dataType: 'row',
                    action: {
                      type: 'direct',
                      target: 'slide-modal',
                      component: 'ServiceDatatable',
                      id: columnProps.action.id,
                      no: ''
                    },
                    filters     : {...this.FilterValues},
                    iFilters    : {
                      filterString : filterString,
                      inputFilter  : ''
                    }
                  }
                  
                  this.$emit('request-action', request_)
                })

              }

            
              td.html(k.type != 'text' ? list[r][k.key].toLocaleString('en-us') : list[r][k.key])
  
          })
        }
      }
      
    },
    Draw_Charts_Prsnt() {

      // Draw Present Table
      let list = this.Queries.SQL2

      let group = this.svg.append('g').attr('class', 'table_group__')

      group.append('text').attr('transform', `translate(1070.5, 14)`)
      .attr('font-size', 12)
      .text('Present Week (Qty)')

      let table_group = group.append('g').attr('transform', `translate(1070.5, 20)`)
      
      let y_ = (list.length * 20) + 100

      let table_obj = table_group.append('foreignObject')
        .attr('y',0)
        .attr('width', '100%')
        .attr('height', `${y_}px`)
        .append('xhtml:body')
        .style('position', 'fixed')

      const reducer = (previousValue, currentValue) => previousValue + currentValue
      let widths = this.prsntTbody.map(d => d.w)
      let sumWidth = widths.reduce(reducer)

      let table = table_obj.append('table')
      .style('border-collapse', 'collapse')
      .style('font-size', '11px')
      .style('width', `${sumWidth}px`)
      .style('table-layout', 'fixed')

      let colgroup = table.append('colgroup')
      this.prsntTbody.forEach(k => {
        colgroup.append('col').style('width', k.w + 'px')
      })

  
      let thead = table.append('thead')
      let tr = thead.append('tr').style('background', '#F5F5F5')

      this.prsntThead.forEach(item => {
        tr.append('th')
        .style('height', '40px')
        .attr('class', 'table_group___th')
        .style('border', 'none')
        .style('padding', '2px')
        .style('font-weight', 'bold')
        .style('font-size', '11px')
        .style('color', item.tColor ? item.tColor : '#263238')
        .style('background', item.bColor ? item.bColor : '#F0F1F3')
        .html(item.html)
      })
        

      
      let tbody = table.append('tbody')

      let tbodykeys = this.prsntTbody
      if (list.length == 0) {
        
        let tr = tbody.append('tr')

        tr.append('td')
          .attr('colspan', 5)
          .style('background', '#F5F5F5')
          .style('height', '40px')
          .style('text-align', 'center')
          .style('color', '#263238')
          .style('padding', '0 3px 0 3px')
          .style('font-style', 'italic')
          .html('No data')

      }else {

        for (let r = 0; r < list.length; r++) {
          let tr = tbody.append('tr')
          tr.on('mouseover', () => tr.style('background-color', '#FAFAFA' ))
          tr.on('mouseout', () => tr.style('background-color', 'Transparent' ))
          tbodykeys.forEach((k, i) => {
            let td = tr.append('td')
              .style('background', r == 0 ? '#F2F2F2' : '#D9D9D9')
              .style('height', '24px')
              .style('text-align', k.align ? k.align : 'center')
              .style('color', k.tColor ? k.tColor : '#263238')
              .style('padding', '0 3px 0 3px')

              if (i != 0 && i != 3){
                td.style('cursor', 'pointer')
                .on('mouseover', () => {
                  td.style('opacity',0.5)
                })
                .on('mouseout', () => {
                  td.style('opacity', 1)
                })
                .on('click', () => {

                  // Get Modal Number
                  let columnProps = this.getRequestColumnProps('request', {}, this.ColumnProps)

                  let filterString = ''
                  

                  if (k.key == 'TARGET' && list[r].ISSUE == 'IFR') filterString = ' [IFRT] <= [C01_Project].[dbo].[GET_CUTOFF]([DATADATE],6) '
                  if (k.key == 'COMPLETED' && list[r].ISSUE == 'IFR') filterString = ' [IFR] <= [C01_Project].[dbo].[GET_CUTOFF]([DATADATE],6) '
                  if (k.key == 'OVERDUE' && list[r].ISSUE == 'IFR') filterString = ' [IFRT] <= [DATADATE] AND [IFR] IS NULL '
                  if (k.key == 'AHEAD' && list[r].ISSUE == 'IFR') filterString = ' [IFRT] > [C01_Project].[dbo].[GET_CUTOFF]([DATADATE],6) AND [IFR] IS NOT NULL '
                  if (k.key == 'TOGO' && list[r].ISSUE == 'IFR') filterString = ' [IFR] IS NULL '


                  if (k.key == 'TARGET' && list[r].ISSUE == 'IFU') filterString = ' [IFUT] <= [C01_Project].[dbo].[GET_CUTOFF]([DATADATE],6) '
                  if (k.key == 'COMPLETED' && list[r].ISSUE == 'IFU') filterString = ' [IFU] <= [C01_Project].[dbo].[GET_CUTOFF]([DATADATE],6) '
                  if (k.key == 'OVERDUE' && list[r].ISSUE == 'IFU') filterString = ' [IFUT] <= [DATADATE] AND [IFU] IS NULL '
                  if (k.key == 'AHEAD' && list[r].ISSUE == 'IFU') filterString = ' [IFUT] > [C01_Project].[dbo].[GET_CUTOFF]([DATADATE],6) AND [IFU] IS NOT NULL '
                  if (k.key == 'TOGO' && list[r].ISSUE == 'IFU') filterString = ' [IFU] IS NULL '

                  if(columnProps.iFilters.filterString) filterString += ` AND ${columnProps.iFilters.filterString} `
                  
                  let request_ = {
                    dataType: 'row',
                    action: {
                      type: 'direct',
                      target: 'slide-modal',
                      component: 'ServiceDatatable',
                      id: columnProps.action.id,
                      no: ''
                    },
                    filters     : {...this.FilterValues},
                    iFilters    : {
                      filterString : filterString,
                      inputFilter  : ''
                    }
                  }
                  
                  this.$emit('request-action', request_)
                })

              }
            
              td.html(k.type != 'text' ? list[r][k.key].toLocaleString('en-us') : list[r][k.key])
  
          })
        }
      }
      
    },
  }
}
