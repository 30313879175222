import * as d3 from 'd3'

export default {
  methods: {

    draw_Forms(cwa,callback) {
      let data = this.CWA_List.find(f=> f.CWA == cwa)

      this.formSelection = this.svg
      .append('svg')

      this.formSelection
      .append('text')
      .attr('x', 70).attr('y', 170).attr('fill', '#333').attr('font-size', 11)
      .text('KEY PLAN')

      this.loadSvg(
        this.formSelection, {
        x: 10,
        y: 10,
        url: '/China1/LiveUpdateNotice/LUN_SVG/Keyplan.svg'
      }).then((svg) => {
        d3.select(`#AREAS`).style('visibility', 'hidden')
        d3.select(`#PLAN-${data.AREA}`).style('visibility', 'visible')
      })
      
      this.innerSvg = this.svg

      this.loadSvg(
        this.innerSvg, {
        x: 0,
        y: 0,
        url: `/China1/LiveUpdateNotice/LUN_SVG/LUN_${data.AREA}.svg`
      }).then((svg) => {

        this.formSelection.moveToFront()
        
        d3.select(`#CODE`).style('visibility', 'hidden')
        d3.select(`#AREA`).style('visibility', 'hidden')
        d3.select(`#BOX`).style('visibility', 'hidden')

        d3.select(`#AREA-${cwa}`).style('visibility', 'visible')
        d3.select(`#BOX-${cwa}`).style('visibility', 'visible')

        d3.select(`#BOX-${cwa}`).selectAll('rect')
        .style('fill', this.Legends.find(f => f.code == data.STATUS).color)
        d3.select(`#BOX-${cwa}`).selectAll('path')
        .style('fill', this.Legends.find(f => f.code == data.STATUS).color)
        d3.select(`#BOX-${cwa}`).selectAll('polygon')
        .style('fill', this.Legends.find(f => f.code == data.STATUS).color)
        d3.select(`#BOX-${cwa}`).selectAll('ellipse')
        .style('fill', this.Legends.find(f => f.code == data.STATUS).color)

        // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        // PAGESVG 에서는 drawNotes,drawTexts 는 loadsvg 가 제일 마지막에 실행이되므로
        // this.loadSvg().then 에서 처리해야 제일 상단에 그려진다.
        // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        this.drawNotes()
        this.drawTexts() 
      })
    },
  }
}