<template>
  <div :id="localId"></div>
</template>

<script>
import * as d3 from 'd3'
import mx_Core from './includes/mixins/importer'            // Global & Local Mixins

export default {
  name: "j-chart-donut",
  mixins: [mx_Core],
  props: {
    id: String,
    modeExport: Boolean,
    getSelectItems: String,

    FilterValues: { type: Object, default: () => ({}) },

    Canvas: { type: Object, default: () => ({}) },
    DataItems: { type: Array, default: () => ([]) },
    Text: { type: Object, default: () => ({}) },
    Queries: { type: Object, default: () => ({}) },
    ColumnProps: { type: Array, default: () => ([]) },

    callback: { type: Object, default: () => ({}) },
  },

  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
  },

  mounted() {
    this.register(this.draw)
  },

  methods: {
    draw() {
      if (!this.ready2draw) return

      this.clear()
      this.drawCanvas()

      this.setDefaultValue()

      this.drawChart()
      setTimeout(() => {
        this.drawTexts()
      }, 200);
      
      // if (this.getSelectItems) {
      //   setTimeout(() => {
      //     this.setEvent_PDF()
      //   }, 300)
      // } 
      this.complete()
    },

    drawChart() {
      this.Draw_Charts()

      if(this.getSelectItems) {
        
        console.log("this.getSelectItems", this.getSelectItems)
      
        // let d = JSON.parse(this.getSelectItems)
        // this.setStatus(d)
      }
    },

  }
}
</script>