import * as d3 from 'd3'

export default {
  methods: {

    draw_Data() {
      let summaryData = this.rawData
      let summaryTop = this.summaryTop
      let summaryValues = this.summaryValues
      let titleHeight = this.styles.titleHeight
      let lineHeight = this.styles.lineHeight
      let boxHeight = titleHeight + lineHeight * summaryValues.length

      let Summary = this.sCanvasArea
      .append('g')
      .attr('id', 'summary')
      .attr('transform', `translate(0, 0)`)
      
      let List = Summary
      .append('g')
      .attr('id', 'list')
      
      summaryValues.forEach((d,i) => {
        List
        .append('text')
        .attr('x', 10).attr('y', titleHeight + (lineHeight * (i + 1 / 2)))
        .attr('font-size', this.styles.bFontSize).attr('fill', '#333')
        .attr('alignment-baseline', 'middle')
        .text(d.title)
      })
      
      let startX = this.getNodeElValue('#list', 'width') + 20
      let boxWidth = summaryTop.reduce((acc, obj) => acc + obj.colWidth, 0) + startX
      let interval = 0

      Summary
      .append('rect')
      .attr('width', boxWidth).attr('height', titleHeight)
      .attr('fill', '#EEE')

      Summary
      .append('line')
      .attr('x1', 0).attr('y1', 0)
      .attr('x2', boxWidth).attr('y2', 0)
      .attr('stroke', '#999')
      
      Summary
      .append('line')
      .attr('x1', 0).attr('y1', titleHeight)
      .attr('x2', boxWidth).attr('y2', titleHeight)
      .attr('stroke', '#999')

      Summary
      .append('line')
      .attr('x1', 0).attr('y1', boxHeight)
      .attr('x2', boxWidth).attr('y2', boxHeight)
      .attr('stroke', '#999')

      summaryTop.forEach((d,i) => {
        let Part = Summary
        .append('g')
        .attr('id', `part_${i}`)

        Part
        .append('text')
        .attr('id', `colTitle_${i}`)
        .attr('x', startX + (d.colWidth / 2) + interval).attr('y', d.children == '' ? titleHeight / 2 : titleHeight / 4)
        .attr('font-size', this.styles.hFontSize).attr('fill', '#777')
        .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text(d.title)

        Part
        .append('line')
        .attr('x1', startX + interval).attr('y1', 0)
        .attr('x2', startX + interval).attr('y2', boxHeight)
        .attr('stroke', '#999')

        if(d.children == '') {
          let ref = d.ref[0]
          let col = ''

          if (d.merge[0] == 'Y') {
            Part
            .append('text')
            .attr('x', startX + (d.colWidth / 2) + interval)
            .attr('y', titleHeight + (lineHeight * (summaryValues.length * (1 / 2))))
            .attr('font-size', this.styles.bFontSize).attr('fill', d.color)
            .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
            .text(summaryData[0][`${ref}`].toLocaleString())
            .style('cursor', 'pointer')
            .on('click', () => this.link_Data({type: d.link, col: '', ref: ref, val: summaryData[0][`${ref}`],}))
          } else {
            summaryValues.forEach((e,k) => {
              if (ref) {
                col = `${e.col}_${ref}`

                Part
                .append('text')
                .attr('x', startX + (d.colWidth / 2) + interval)
                .attr('y', titleHeight + (lineHeight * (k + 1 / 2)))
                .attr('font-size', this.styles.bFontSize).attr('fill', d.color)
                .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
                .text(summaryData[0][`${e.col}_${ref}`].toLocaleString())
                .style('cursor', 'pointer')
                .on('click', () => this.link_Data({type: d.link, col: e.col, ref: ref, val: summaryData[0][`${e.col}_${ref}`],}))
              }
            })
          }
        } else {
          let childWidth = d.colWidth / d.children.length

          d.children.forEach((c,j) => {
            Part
            .append('text')
            .attr('x', startX + interval + childWidth * (j + 1 / 2))
            .attr('y', titleHeight * (3 / 4))
            .attr('font-size', this.styles.hFontSize).attr('fill', '#777')
            .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
            .text(c)

            let ref = d.ref[j]
            let col = ''

            if (d.merge[j] == 'Y') {
              Part
              .append('text')
              .attr('x', startX + interval + childWidth * (j + 1 / 2))
              .attr('y', titleHeight + (lineHeight * (summaryValues.length * (1 / 2))))
              .attr('font-size', this.styles.bFontSize).attr('fill', d.color[j])
              .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
              .text(summaryData[0][`${ref}`].toLocaleString())
              .style('cursor', 'pointer')
              .on('click', () => this.link_Data({type: d.link, col: '', ref: ref, val: summaryData[0][`${ref}`],}))
            } else {
              summaryValues.forEach((e,k) => {
                if (ref) {
                  Part
                  .append('text')
                  .attr('x', startX + interval + childWidth * (j + 1 / 2))
                  .attr('y', titleHeight + (lineHeight * (k + 1 / 2)))
                  .attr('font-size', this.styles.bFontSize).attr('fill', d.color[j])
                  .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
                  .text(summaryData[0][`${e.col}_${ref}`].toLocaleString())
                  .style('cursor', 'pointer')
                  .on('click', () => this.link_Data({type: d.link, col: e.col, ref: ref, val: summaryData[0][`${e.col}_${ref}`],}))
                }
              })
            }
          })

          if (d.div !== '') {
            Part
            .append('line')
            .attr('x1', startX + interval).attr('y1', titleHeight / 2)
            .attr('x2', startX + interval + d.colWidth).attr('y2', titleHeight / 2)
            .attr('stroke', '#999')
          }
        }
        
        interval = interval + d.colWidth
      })
    },
    
  }
}