import * as d3 from 'd3'

export default {
  data: () => ({
    prevActual: null,
  }),
  methods: {
    draw_LineCharts() {
      d3.select(`#${this.localId}`).select('.skyline_line_actual').remove()

      if(!this.chartMounted) this.setLineScale()

      let LineChart = this.SkylineCharts
      .append('g')
      .attr('class', 'skyline_line_actual')
      .attr('transform', `translate(${this.timeline.baseX},0)`)

      // PLAN
      let lineChartPlan = LineChart
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .attr('class', 'lineChartPlan')
      .style('opacity', 1)

      lineChartPlan.append('path')
      .attr('d', this.lineZero(this.timeline.plan))
      .style('stroke', '#BDBDBD').style('stroke-width', 2.5).style('stroke-dasharray', '4,4').attr('opacity', .5).style('fill', 'none')
      .transition()
      .duration(500)
      .attr('d', this.lineFunc(this.timeline.plan))

      lineChartPlan
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .selectAll('circle')
      .data(this.timeline.plan)
      .enter()

      .append('circle')
      .attr('cx', d => this.timeline.scale(d.eDate))
      .attr('cy', d => this.scaleY(0))
      .attr('r', 3)
      .style('stroke', '#424242').style('stroke-width', 2).attr('stroke-opacity', 0.5)
      .style('fill', '#fff')
      .transition()
      .duration(500)
      .attr('cx', d => this.timeline.scale(d.eDate))
      .attr('cy', d => this.scaleY(d.value))

      lineChartPlan
      .append('g')
      .attr('class', 'uptoactual_rect_group')
      .attr('transform', `translate(0, 0)`)
      .selectAll('rect')
      .data(this.timeline.plan)
      .enter()
      .append('rect')
      .attr('x', d => this.timeline.scale(d.eDate))
      .attr('y', d => this.scaleY(0))
      .attr('rx', 3)
      .attr('width', 40)
      .attr('height', 15)
      .style('fill', '#F5F5F5')
      .style('stroke', '#BDBDBD').style('stroke-width', 1).attr('opacity', .95)
      .style('opacity', '0.65')
      .transition()
      .duration(500)
      .attr('x', d => this.timeline.scale(d.eDate)- 20)
      .attr('y', d => this.scaleY(d.value)-18)
      

      
      lineChartPlan
      .append('g')
      .attr('class', 'ChartActualValueGroup')
      .attr('transform', `translate(0, 0)`)
      .selectAll('text')
      .data(this.timeline.plan)
      .enter()
      .append('text')
      .attr('id', 'ChartActualValue')
      .attr('x', d => this.timeline.scale(d.eDate))
      .attr('y', d => this.scaleY(0))
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#757575').attr('text-anchor', 'middle')
      .style('font-weight', 'bold')
      .text(d => d.value ? d.value.toLocaleString('en') : 0)
      .transition()
      .duration(500)
      .attr('x', d => this.timeline.scale(d.eDate))
      .attr('y', d => this.scaleY(d.value)-7)
      



      // Actual
      let lineChartActual = LineChart
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .attr('class', 'lineChartActual')

      lineChartActual.append('path')
      .attr('d', this.lineZero(this.timeline.actual))
      .style('stroke', '#2962FF').style('stroke-width', 2).attr('opacity', .95)
      .style('fill', 'none')
      .transition()
      .duration(500)
      .attr('d', this.lineFunc(this.timeline.actual))

      lineChartActual
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .selectAll('circle')
      .data(this.timeline.actual)
      .enter()

      .append('circle')
      .attr('cx', d => this.timeline.scale(d.eDate))
      .attr('cy', this.scaleY(0))
      .attr('r', 3)
      .style('stroke', '#40C4FF').style('stroke-width', 2).attr('stroke-opacity', 0.5)
      .style('fill', '#fff')
      .transition()
      .duration(500)
      .attr('cx', d => this.timeline.scale(d.eDate))
      .attr('cy', d => this.scaleY(d.value))

      
      lineChartActual
      .append('g')
      .attr('class', 'uptoactual_rect_group')
      .attr('transform', `translate(0, 0)`)
      .selectAll('rect')
      .data(this.timeline.actual)
      .enter()
      .append('rect')
      .attr('x', d => this.timeline.scale(d.eDate))
      .attr('y', d => this.scaleY(0))
      .attr('rx', 3)
      .attr('width', 40)
      .attr('height', 15)
      .style('fill', '#fff')
      .style('stroke', '#40C4FF').style('stroke-width', 1).attr('opacity', .95)
      .style('opacity', '0.65')
      .transition()
      .duration(500)
      .attr('x', d => this.timeline.scale(d.eDate)- 20)
      .attr('y', d => this.scaleY(d.value)-18)
      

      lineChartActual
      .append('g')
      .attr('class', 'ChartActualValueGroup')
      .attr('transform', `translate(0, 0)`)
      .selectAll('text')
      .data(this.timeline.actual)
      .enter()
      .append('text')
      .attr('id', 'ChartActualValue')
      .attr('x', d => this.timeline.scale(d.eDate))
      .attr('y', d => this.scaleY(0))
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#2962FF').attr('text-anchor', 'middle')
      .style('font-weight', 'bold')
      .text(d => d.value ? d.value.toLocaleString('en') : 0)
      .transition()
      .duration(500)
      .attr('x', d => this.timeline.scale(d.eDate))
      .attr('y', d => this.scaleY(d.value)-7)


      // CUR PLAN
      let lineChartPlanCur = LineChart
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .attr('class', 'lineChartPlanUp')
      .style('opacity', 1)

      lineChartPlanCur.append('path')
      .attr('d', this.lineZero(this.timeline.uptoplan))
      .style('stroke', '#424242').style('stroke-width', 2.5).style('stroke-dasharray', '4,4').attr('opacity', .5).style('fill', 'none')
      .transition()
      .duration(500)
      .attr('d', this.lineFunc(this.timeline.uptoplan))

      lineChartPlanCur
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .selectAll('circle')
      .data(this.timeline.uptoplan)
      .enter()

      .append('circle')
      .attr('cx', d => this.timeline.scale(d.eDate))
      .attr('cy', d => this.scaleY(0))
      .attr('r', 3)
      .style('stroke', '#424242').style('stroke-width', 2).attr('stroke-opacity', 0.5)
      .style('fill', '#fff')
      .transition()
      .duration(500)
      .attr('cx', d => this.timeline.scale(d.eDate))
      .attr('cy', d => this.scaleY(d.value))

      lineChartPlanCur
      .append('g')
      .attr('class', 'uptoactual_rect_group')
      .attr('transform', `translate(0, 0)`)
      .selectAll('rect')
      .data(this.timeline.uptoplan)
      .enter()
      .append('rect')
      .attr('x', d => this.timeline.scale(d.eDate))
      .attr('y', d => this.scaleY(0))
      .attr('rx', 3)
      .attr('width', 40)
      .attr('height', 15)
      .style('fill', '#fff')
      .style('stroke', '#BDBDBD').style('stroke-width', 1).attr('opacity', .95)
      .style('opacity', '0.65')
      .transition()
      .duration(500)
      .attr('x', d => this.timeline.scale(d.eDate)- 17)
      .attr('y', d => this.scaleY(d.value)-18)

      
      lineChartPlanCur
      .append('g')
      .attr('class', 'ChartActualValueGroup')
      .attr('transform', `translate(0, 0)`)
      .selectAll('text')
      .data(this.timeline.uptoplan)
      .enter()
      .append('text')
      .attr('id', 'ChartActualValue')
      .attr('x', d => this.timeline.scale(d.eDate))
      .attr('y', d => this.scaleY(0))
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#000000').attr('text-anchor', 'middle')
      .style('font-weight', 'bold')
      .text(d => d.value ? d.value.toLocaleString('en') : 0)
      .transition()
      .duration(500)
      .attr('x', d => this.timeline.scale(d.eDate))
      .attr('y', d => this.scaleY(d.value)-7)
      



      // Actual CUR
      let lineChartActualCur = LineChart
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .attr('class', 'lineChartActualUp')

      lineChartActualCur.append('path')
      .attr('d', this.lineZero(this.timeline.uptoactual))
      .style('stroke', '#2E7D32').style('stroke-width', 2).attr('opacity', .95)
      .style('fill', 'none')
      .transition()
      .duration(500)
      .attr('d', this.lineFunc(this.timeline.uptoactual))

      lineChartActualCur
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .selectAll('circle')
      .data(this.timeline.uptoactual)
      .enter()
      .append('circle')
      .attr('cx', d => this.timeline.scale(d.eDate))
      .attr('cy', this.scaleY(0))
      .attr('r', 3)
      .style('stroke', '#40C4FF').style('stroke-width', 2).attr('stroke-opacity', 0.5)
      .style('fill', '#fff')
      .transition()
      .duration(500)
      .attr('cx', d => this.timeline.scale(d.eDate))
      .attr('cy', d => this.scaleY(d.value))


      lineChartActualCur
      .append('g')
      .attr('class', 'actualcur_rect_group')
      .attr('transform', `translate(0, 0)`)
      .selectAll('rect')
      .data(this.timeline.uptoactual)
      .enter()
      .append('rect')
      .attr('x', d => this.timeline.scale(d.eDate))
      .attr('y', d => this.scaleY(0))
      .attr('rx', 3)
      .attr('width', 40)
      .attr('height', 15)
      .style('fill', '#fff')
      .style('stroke', '#2E7D32').style('stroke-width', 1).attr('opacity', .95)
      .style('opacity', '0.65')
      .transition()
      .duration(500)
      .attr('x', d => this.timeline.scale(d.eDate)- 17)
      .attr('y', d => this.scaleY(d.value)-18)
      

      lineChartActualCur
      .append('g')
      .attr('class', 'actualcur_text_group')
      .attr('transform', `translate(0, 0)`)
      .selectAll('text')
      .data(this.timeline.uptoactual)
      .enter()
      .append('text')
      .attr('id', 'ChartActualValue')
      .attr('x', d => this.timeline.scale(d.eDate))
      .attr('y', d => this.scaleY(0))
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#2E7D32').attr('text-anchor', 'middle')
      .style('font-weight', 'bold')
      .text(d => d.value ? d.value.toLocaleString('en') : 0)
      .transition()
      .duration(500)
      .attr('x', d => this.timeline.scale(d.eDate))
      .attr('y', d => this.scaleY(d.value)-7)
      
    }, 
    draw_4wks_LineCharts() {
      d3.select(`#${this.localId}`).select('.skyline_line_actual').remove()

      if(this.skyline.line.display != 'Y') return
    
      if(!this.Queries.SQL5 || this.Queries.SQL5.length === 0) return

      if(!this.chartMounted) this.setLineScale()

      let LineChart = this.SkylineCharts
      .append('g')
      .attr('class', 'skyline_line_actual')
      .attr('transform', `translate(${this.timeline.baseX},0)`)

      // PLAN
      let lineChartPlan = LineChart
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .attr('class', 'lineChartPlan')
      .style('opacity', 1)

      lineChartPlan.append('path')
      .attr('d', this.lineZero(this.timeline.plan))
      .style('stroke', '#424242').style('stroke-width', 2.5).style('stroke-dasharray', '4,4').attr('opacity', .5).style('fill', 'none')
      .transition()
      .duration(500)
      .attr('d', this.lineFunc(this.timeline.plan))

      lineChartPlan
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .selectAll('circle')
      .data(this.timeline.plan)
      .enter()

      .append('circle')
      .attr('cx', d => this.timeline.scale(d.eDate))
      .attr('cy', d => this.scaleY(0))
      .attr('r', 3)
      .style('stroke', '#424242').style('stroke-width', 2).attr('stroke-opacity', 0.5)
      .style('fill', '#fff')
      .transition()
      .duration(500)
      .attr('cx', d => this.timeline.scale(d.eDate))
      .attr('cy', d => this.scaleY(d.value))

      lineChartPlan
      .append('g')
      .attr('class', 'lineChartPlan_rect_group')
      .attr('transform', `translate(0, 0)`)
      .selectAll('rect')
      .data(this.timeline.plan)
      .enter()
      .append('rect')
      .attr('x', d => this.timeline.scale(d.eDate))
      .attr('y', d => this.scaleY(0))
      .attr('rx', 3)
      .attr('width', 40)
      .attr('height', 15)
      .style('fill', '#fff')
      .style('stroke', '#BDBDBD').style('stroke-width', 1).attr('opacity', .95)
      .style('opacity', '0.65')
      .transition()
      .duration(500)
      .attr('x', d => this.timeline.scale(d.eDate) - 20)
      .attr('y', d => this.scaleY(d.value)-18)

      
      lineChartPlan
      .append('g')
      .attr('class', 'ChartActualValueGroup')
      .attr('transform', `translate(0, 0)`)
      .selectAll('text')
      .data(this.timeline.plan)
      .enter()
      .append('text')
      .attr('id', 'ChartActualValue')
      .attr('x', d => this.timeline.scale(d.eDate))
      .attr('y', d => this.scaleY(0))
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#000000').attr('text-anchor', 'middle')
      .style('font-weight', 'bold')
      .text(d => d.value ? d.value.toLocaleString('en') : 0)
      .transition()
      .duration(500)
      .attr('x', d => this.timeline.scale(d.eDate))
      .attr('y', d => this.scaleY(d.value)-7)


      


      // Actual
      let lineChartActual = LineChart
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .attr('class', 'lineChartActual')

      lineChartActual.append('path')
      .attr('d', this.lineZero(this.timeline.actual))
      .style('stroke', '#40C4FF').style('stroke-width', 2).attr('opacity', .95)
      .style('fill', 'none')
      .transition()
      .duration(500)
      .attr('d', this.lineFunc(this.timeline.actual))

      lineChartActual
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .selectAll('circle')
      .data(this.timeline.actual)
      .enter()

      .append('circle')
      .attr('cx', d => this.timeline.scale(d.eDate))
      .attr('cy', this.scaleY(0))
      .attr('r', 3)
      .style('stroke', '#40C4FF').style('stroke-width', 2).attr('stroke-opacity', 0.5)
      .style('fill', '#fff')
      .transition()
      .duration(500)
      .attr('cx', d => this.timeline.scale(d.eDate))
      .attr('cy', d => this.scaleY(d.value))

      
      lineChartActual
      .append('g')
      .attr('class', 'actualcur_rect_group')
      .attr('transform', `translate(0, 0)`)
      .selectAll('rect')
      .data(this.timeline.actual)
      .enter()
      .append('rect')
      .attr('x', d => this.timeline.scale(d.eDate))
      .attr('y', d => this.scaleY(0))
      .attr('rx', 3)
      .attr('width', 40)
      .attr('height', 15)
      .style('fill', '#fff')
      .style('stroke', '#2E7D32').style('stroke-width', 1).attr('opacity', .95)
      .style('opacity', '0.65')
      .transition()
      .duration(500)
      .attr('x', d => this.timeline.scale(d.eDate) - 20)
      .attr('y', d => this.scaleY(d.value)-18)
      
      

      lineChartActual
      .append('g')
      .attr('class', 'ChartActualValueGroup')
      .attr('transform', `translate(0, 0)`)
      .selectAll('text')
      .data(this.timeline.actual)
      .enter()

      // .append('rect')
      // .attr('width', 40)
      // .attr('height', 15)
      // .attr('x', d => this.timeline.scale(d.eDate))
      // .attr('y', d => this.scaleY(0))
      // .attr('fill', '#fff')

      .append('text')
      .attr('id', 'ChartActualValue')
      .attr('x', d => this.timeline.scale(d.eDate))
      .attr('y', d => this.scaleY(0))
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#EC407A').attr('text-anchor', 'middle')
      .style('font-weight', 'bold')
      .text(d => d.value ? d.value.toLocaleString('en') : 0)
      .transition()
      .duration(500)
      .attr('x', d => this.timeline.scale(d.eDate))
      .attr('y', d => this.scaleY(d.value)-7)

      
      // Trend
      let lineChartTrend = LineChart
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .attr('class', 'lineChartTrend')

      lineChartTrend.append('path')
      .attr('d', this.lineZero(this.timeline.trend))
      .style('stroke', '#0D47A1').style('stroke-width', 2).style('stroke-dasharray', '4,4').attr('opacity', .95)
      .style('fill', 'none')
      .transition()
      .duration(500)
      .attr('d', this.lineFunc(this.timeline.trend))

      lineChartTrend
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .selectAll('circle')
      .data(this.timeline.trend)
      .enter()

      .append('circle')
      .attr('cx', d => this.timeline.scale(d.eDate))
      .attr('cy', this.scaleY(0))
      .attr('r', 3)
      .style('stroke', '#0D47A1').style('stroke-width', 2).attr('stroke-opacity', 0.5)
      .style('fill', '#fff')
      .transition()
      .duration(500)
      .attr('cx', d => this.timeline.scale(d.eDate))
      .attr('cy', d => this.scaleY(d.value))
      
    }, 
  }
}
