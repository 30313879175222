import * as d3 from 'd3'
import { svg } from 'd3'
// ------------------------------------------------------------------------------- Global

export default {
  methods: {
    Draw_Charts() {
      let chart = this.svg

      let fText = chart
      .append('g')
      .attr('transform', `translate(10, 10)`)

      fText
      .append('text')
      .attr('x', 5).attr('y', 5)
      .style('font-family','roboto').style('font-size',20).attr('text-anchor', 'start').attr('fill', '#000')
      .text(this.FilterValues && this.FilterValues.PHASE ? this.FilterValues.PHASE : 'Construction S-Curve')


    },
  }
}
