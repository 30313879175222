<template>
  <div :id="localId"></div>
</template>

<script>
import mx_Core from './includes/mixins/importer'
import * as d3 from 'd3'

export default {
  name: "j-svg-page",
  mixins: [mx_Core],
  props: {
    id: String,
    getSelectItems: String,  
    
    // ### Necessaries for the SVG-Page ###
    Action: { type: Object, default: () => ({}) },
    FilterValues: { type: Object, default: () => ({}) },
    // ### ---------------------------- ###

    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },
    
    DataItems: { type: Array, default: () => ([]) },
    Queries: { type: Object, default: () => ({}) },
    ColumnProps: { type: Array, default: () => ([]) },

    values: { type: Object, default: () => ({}) }
  },
  created() {
    this.localId = this.id || 'j-svg-page__' + this.safeId('')
  },
  mounted() {
    this.register(this.draw)
  },
  methods: {
    clearSvgGroup(){
      d3.select(`#__svg_group_`).remove()
    },
    draw() {
      // Check all the necessary values are available
      if (!this.ready2draw) return

      this.clear()
      this.initValues()
      this.drawCanvas()
      this.setGradient()
      this.setDefaultValues()
      this.drawTexts()
      this.drawTimeline()
      this.drawChart()     
      //Configure the process for PDF output
      if(this.getSelectItems) {
        this.getItems = JSON.parse(this.getSelectItems)

        if(this.getItems.LEVEL == 'DRAW'){
          this.areas.filter(a => a.P6_LV1 == this.getItems.P6_LV1).forEach(a => {
            let key = `${a.P6_LV1}${a.P6_LV2}`
            this.collapsed[key] = !this.collapsed[key]
          })
        } else if(this.getItems.LEVEL == 'BAR'){
          if(this.getItems.CALLFLAG){
            this.packages.forEach(p => {
              this.collapsed[p.P6_LV1] = true

              this.areas.filter(a => a.P6_LV1 == p.P6_LV1).forEach(a => {
                this.collapsed[`${a.P6_LV1}${a.P6_LV2}`] = true
                this.phases.filter(ph => ph.P6_LV1 == a.P6_LV1 && ph.P6_LV2 == a.P6_LV2).forEach(ph => {
                  this.collapsed[`${ph.P6_LV1}${ph.P6_LV2}${ph.P6_LV3}`] = true
                })
              })
              // History for collapse click 
              // History clear time is not set yet ( very hard to set it )
              // 2023.06.22 by sw
              // for ( let i in this.getItems.COLL ) {
              //   if (this.getItems.COLL.hasOwnProperty(i)) {
              //     let key = `${i}`
              //     this.collapsed[key] = i[this.getItems.COLL[i]]
              //   }
              // }
            })
          } else {
            this.areas.filter(a => a.P6_LV1 == this.getItems.P6_LV1).forEach(a => {
            let key = `${a.P6_LV1}${a.P6_LV2}`
            this.collapsed[key] = !this.collapsed[key]
            })
            this.pdfredraw()
            this.phases.filter(ph => ph.P6_LV1 == this.getItems.P6_LV1 && ph.P6_LV2 == this.getItems.P6_LV2 && ph.P6_LV3 == this.getItems.P6_LV3)
              .forEach(ph => {
                let key = `${ph.P6_LV1}${ph.P6_LV2}${ph.P6_LV3}`
                this.collapsed[key] = !this.collapsed[key]
            })
            
            Object.keys(this.getItems.COLL).forEach(co => {
              let key = `${co}`
              this.collapsed[key] = true
            })
          }
        } else if(this.getItems.LEVEL == 'COLL'){
          this.packages.forEach(p => {
            this.collapsed[p.P6_LV1] = true

            this.areas.filter(a => a.P6_LV1 == p.P6_LV1).forEach(a => {
              this.collapsed[`${a.P6_LV1}${a.P6_LV2}`] = !this.getItems.FLAG
              this.phases.filter(ph => ph.P6_LV1 == a.P6_LV1 && ph.P6_LV2 == a.P6_LV2).forEach(ph => {
                this.collapsed[`${ph.P6_LV1}${ph.P6_LV2}${ph.P6_LV3}`] = !this.getItems.FLAG
              })
            })
          })

        }
        this.pdfredraw()
      }
      // 'complete' must be executed to finish the parent process.
      // It sends 'this.localId' & XmlString for some case to make 
      // displaying image easy.
      this.complete()
      this.postComplete()
    },
    redraw() {
      this.clearSvgGroup()
      this.setDefaultValues()
      this.drawTimeline()
      this.drawChart()
      this.postComplete()
    },
    pdfredraw() {
      this.clearSvgGroup()
      this.setDefaultValues()
      this.drawTimeline()
      this.drawChart()
    },
    postComplete() {
      let chart_ = d3.select('#__svg_group_').node().getBoundingClientRect()
      this.resizableCanvasWidth = chart_.width + 200 // will be fixed
      // this.resizableCanvasHeight = chart_.height + 150 // will be fixed
      this.resizableCanvasHeight = this.y_coordinate + 50
      this.resizeCanvas()

      let dimention = { 
        width: this.resizableCanvasWidth,
        height: this.resizableCanvasHeight, 
        isreal: true, 
      }
      
      this.$emit('resize', dimention)
    },
  }
}
</script>