import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {
    
    Draw_Charts() {
      
      let data = null


      let regulatory = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0.5)`)

      // Chart_Tube_01
      data = this.dataSet[0]
      let style = {
        x:        20,
        y:        45,
        color:    'LightBlue',
        height:   130,
        tickness: 45,
        base:   { tSize: 13, height: 30, padding: 23,},
        legend: { actualColor:'#44A9DF', remainColor: '#F7BACF', valueSize: 11, tSize: 10},
        value:  {TOTAL:data.TOTAL, ACTUAL:data.ACTUAL, REMAIN:data.REMAIN, PROG:data.PROG}
      }
      this.Chart_Tube_01(regulatory, style) // ---------------------------> 


      let drawX = [20, 40, 340]
      let drawY = 55

      let table = regulatory
      .append('g')
      .attr('transform', `translate(200, 50)`)

      table
      .append('text')
      .attr('transform', `translate(0, 0)`)
      .style('font-size', 12).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'start')
      .text('Overall Certificate of Inspection')
      
      // Line for Header
      table.append('line')
      .attr('x1', 0).attr('x2', 380).attr('y1', 10).attr('y2', 10).attr('stroke-width', 0.5).attr('stroke', '#bcbcbc')

      table.append('line')
      .attr('x1', 0).attr('x2', 380).attr('y1', 35).attr('y2', 35).attr('stroke-width', 0.5).attr('stroke', '#bcbcbc')

      // Header
      table.append('text')
      .attr('transform', `translate(${drawX[0]}, 27)`)
      .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#757575').style('text-anchor', 'middle').text('No')

      table.append('text')
      .attr('transform', `translate(150, 27)`)
      .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#757575').style('text-anchor', 'start').text('RTP')

      table.append('text')
      .attr('transform', `translate(${drawX[2]}, 27)`)
      .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#757575').style('text-anchor', 'middle').text('Forecast')

      // Data Cells
      this.Queries.SQL1.forEach(d=> {
        table.append('text')
        .attr('transform', `translate(${drawX[0]}, ${drawY})`)
        .style('font-size', 10).style('font-family', 'roboto').attr('fill', '#757575').style('text-anchor', 'middle').text(d.NO)

        let style = {
          x: drawX[1],
          y: drawY - 13,
          text: `${d.RTP.trim()}`,
          width: 250,
          strokeWidth: 0,
          textAlign: 'start',
          fontSize: 10,
          height: 25,
          verticalAlign: 'top',
          lineHeignt: 20,
        }

        let reValue = this.multiLineText(table, style)

        table.append('text')
        .attr('transform', `translate(${drawX[2]}, ${drawY})`)
        .style('font-size', 10).style('font-family', 'roboto').attr('fill', '#757575').style('text-anchor', 'middle').text(d.FORECAST)

        drawY += reValue.height
      })

    },
  }
}
           