import * as d3 from 'd3'

export default {
  data: () => ({
    dataSet: [],
    
    PACKAGE: '',
    REF_CODE: ''
  }),
  computed: {
    ready2draw() {

      return (
        // this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },
  methods: {
    setDefaultValues(){
      this.PACKAGE = this.FilterValues ? this.FilterValues.PACKAGE : 'P1'
      
      let refcode = this.ColumnProps[0].link.action.commentProps.refCode
      this.ColumnProps[0].link.action.commentProps.refCode = refcode + `_${this.PACKAGE}`
      this.REF_CODE = this.ColumnProps[0].link.action.commentProps.refCode

      this.dataSet = JSON.parse(JSON.stringify(this.DataItems.filter(d => d.REF_CODE == this.REF_CODE)))

      this.svg
      .append('clipPath')
      .attr('id', 'overflow_free2')
      .append('rect')
      .attr('x', 20)
      .attr('y', 0)
      .attr('width', 190)
      .attr('height', this.rowHeight)
    },
  }
}