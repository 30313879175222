import * as d3 from 'd3'
// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  data:() => ({
    selectedStartDate: null,
    selectedStartColumn: null,

    selectedFinishDate: null,
    selectedFinishColumn: null,

    filteredList: null,
  }),
  methods: {
    Draw_Charts() {
      
      if(this.ColumnProps.length > 0) var props = this.ColumnProps.find(props_ => props_.value == 'comprops').props
      else props = this.defaultset

      let propWidth = props.width/6

      let Summary = this.svg
      .append('g')
      .attr('id', 'summary_wrap')
      .attr('transform', `translate(0.5,0.5)`).style('font-family', 'roboto')

      let values_ = this.DataItems
      let keys_ = Object.keys(values_[0])
      // console.log(keys_)

      this.attrs.forEach((attr,i)=>{
        Summary
        .append('text')
        .attr('transform', `translate(${attr.xLocation}, 18)`)
        .style('font-size', 11.5).style('fill', i ==4 ||i ==5 ?'#D05D67':'#716F71').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text( attr.text)
      })
      
      values_.forEach((value,i) => {
        keys_.forEach((key, k) => {
          
          // console.log(value, key)
          // console.log(value[key])
          let attr = this.attrs.find(f => f.refColumn == key)

          // console.log(attr)
          let SummaryBox = Summary
          .append('g')
          .attr('class', 'summary-box')
          .attr('transform', `translate(${k ==2?-15 :k ==3 ? -30 :k ==4 ? -45 :k ==5 ? -60 :0}, 30)`)
          if(k == 0){
            SummaryBox
            .append('polygon').attr('transform', `translate(${(propWidth*k+10)}, ${50*i})`)
            .attr('points',`0,0 ${propWidth+5},0 ${propWidth+5-20},48 0,48`)
            .attr('fill', value.LS_SUMMARY =='LS' ? '#3B505D': value.LS_SUMMARY =='NA' ? '#B0BEC5': value.LS_SUMMARY =='TYPE III'? '#38A2AA': '#E1BF5B')
          }
          if(k > 0){
            SummaryBox
            .append('polygon').attr('transform', `translate(${(propWidth*k)}, ${50*i})`)
            .attr('points',`20,0 ${propWidth},0 ${propWidth-20},48 0,48`)
            .attr('fill', value.LS_SUMMARY =='LS' ? '#3B505D': value.LS_SUMMARY =='NA' ? '#B0BEC5': value.LS_SUMMARY =='TYPE III'? '#38A2AA': '#E1BF5B')
          }
          SummaryBox
          .append('text')
          .attr('transform', `translate(${k==0? 30 :(propWidth*k)+80}, ${50*i+27 })`)
          .style('font-size', 16)
          .style('fill', '#ffffff')
          .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
          .text(value[attr.refColumn] == 'LS_SUMMARY' ? 'LS' :value[attr.refColumn])

          if(k != 0){
            SummaryBox
            .style('cursor', 'pointer')
            .on('click', () => {
              
            if(values_[attr.refColumn] == 0) return

            let filterArr = []
              // if (value['LS_SUMMARY'] != 'LS') filterArr.push(` [LS_SUMMARY] = '${value['LS_SUMMARY']}' `)
              // if (key == 'APPROVED') filterArr.push(` [STATUS_E] = 'Approved' `)
              // if (key == 'UNDER_REVIEW') filterArr.push(` [STATUS_E] = 'Under review' `)
              // if (key == 'REJECTED') filterArr.push(` [STATUS_E] = 'Rejected `)
              // if (key == 'CANCELLED') filterArr.push( ` [STATUS_E] = 'Cancelled' `)


              if (key == 'ISSUED' && value['LS_SUMMARY'] == 'LS') filterArr.push(` [STATUS_E] IN ('Approved','Under review','Rejected','Cancelled') `)
              if (key == 'ISSUED' && value['LS_SUMMARY'] == 'TYPE III') filterArr.push(` [EM_REV_SIGN] ='III' AND [STATUS_E] IN ('Approved','Under review','Rejected','Cancelled') `)
              if (key == 'ISSUED' && value['LS_SUMMARY'] == 'TYPE I, II') filterArr.push(` [EM_REV_SIGN] IN ('I','II') AND [STATUS_E] IN ('Approved','Under review','Rejected','Cancelled') `)
              if (key == 'ISSUED' && value['LS_SUMMARY'] == 'NA') filterArr.push(` [EM_REV_SIGN] ='NA' AND [STATUS_E] IN ('Approved','Under review','Rejected','Cancelled') `)
              
              if (key == 'APPROVED' && value['LS_SUMMARY'] == 'LS') filterArr.push(` [STATUS_E] = 'Approved' `)
              if (key == 'APPROVED' && value['LS_SUMMARY'] == 'TYPE III') filterArr.push(` [STATUS_E] = 'Approved' AND [EM_REV_SIGN] ='III' `)
              if (key == 'APPROVED' && value['LS_SUMMARY'] == 'TYPE I, II') filterArr.push(` [STATUS_E] = 'Approved' AND [EM_REV_SIGN] IN ('I','II') `)
              if (key == 'APPROVED' && value['LS_SUMMARY'] == 'NA') filterArr.push(` [STATUS_E] = 'Approved' AND [EM_REV_SIGN] ='NA' `)
              
              if (key == 'UNDER_REVIEW' && value['LS_SUMMARY'] == 'LS') filterArr.push(` [STATUS_E] = 'Under review' `)
              if (key == 'UNDER_REVIEW' && value['LS_SUMMARY'] == 'TYPE III') filterArr.push(` [STATUS_E] = 'Under review' AND [EM_REV_SIGN] ='III' `)
              if (key == 'UNDER_REVIEW' && value['LS_SUMMARY'] == 'TYPE I, II') filterArr.push(` [STATUS_E] = 'Under review' AND [EM_REV_SIGN] IN ('I','II') `)
              if (key == 'UNDER_REVIEW' && value['LS_SUMMARY'] == 'NA') filterArr.push(` [STATUS_E] = 'Under review' AND [EM_REV_SIGN] ='NA' `)
              
              if (key == 'REJECTED' && value['LS_SUMMARY'] == 'LS') filterArr.push(` [STATUS_E] = 'Rejected' `)
              if (key == 'REJECTED' && value['LS_SUMMARY'] == 'TYPE III') filterArr.push(` [STATUS_E] = 'Rejected' AND [EM_REV_SIGN] ='III' `)
              if (key == 'REJECTED' && value['LS_SUMMARY'] == 'TYPE I, II') filterArr.push(` [STATUS_E] = 'Rejected' AND [EM_REV_SIGN] IN ('I','II')`)
              if (key == 'REJECTED' && value['LS_SUMMARY'] == 'NA') filterArr.push(` [STATUS_E] = 'Rejected' AND [EM_REV_SIGN] ='NA' `)
              
              if (key == 'CANCELLED' && value['LS_SUMMARY'] == 'LS') filterArr.push( ` [STATUS_E] = 'Cancelled' `)
              if (key == 'CANCELLED' && value['LS_SUMMARY'] == 'TYPE III') filterArr.push( ` [STATUS_E] = 'Cancelled' AND [EM_REV_SIGN] ='III' `)
              if (key == 'CANCELLED' && value['LS_SUMMARY'] == 'TYPE I, II') filterArr.push( ` [STATUS_E] = 'Cancelled' AND [EM_REV_SIGN] IN ('I','II') `)
              if (key == 'CANCELLED' && value['LS_SUMMARY'] == 'NA') filterArr.push( ` [STATUS_E] = 'Cancelled' AND [EM_REV_SIGN] ='NA' `)

                let request_ = {
                  dataType: 'row',
                  action: {
                    type: 'direct',
                    target: 'slide-modal',
                    component: 'ServiceDatatable',
                    id: props.no,
                    no: ''
                  },
                  filters: { 
                    //...this.FilterValues
                  },
                  iFilters: {
                    filterString : filterArr.join(' AND '),
                    inputFilter  : ''
                  }
                }
                this.$emit('request-action', request_)
          })
        }
        })
      })
    },
  }
}

