import * as d3 from 'd3'
import __C from '../../../../../../includes/primitives/_constant_'
export default {
  data: () => ({
    stage_Header: null,
    innerSvg: null,
    commissioningBox: null,
    commissioningList: null,

    CurrentStep: '',

    search_Input : '',
    searched_Data: [],
    
    rawData: [],
    gridArray: [],
    Legends: [
      {x: 10, color: '#ffffff', title: 'Interface'},
      {x: 10, color: '#70AF23', title: 'Building E&I Ststem'},
      {x: 10, color: '#8D8D8D', title: 'Comm. Activity'},
      {x: 10, color: '#44A9DF', title: 'Utility'},
      {x: 10, color: '#FABC18', title: 'Process'},
      {x: 10, color: '#1C1C1C', title: 'Leadership Milestone'},
      {x: 10, color: '#A27D4C', title: 'CSU Key Milestone'},
      {x: 10, color: '#FF6196', title: 'Temporary'},
    ]
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {
    /*
      set_LocalValues()
      loadSvg()
      zoom()
    */

    set_LocalValues(){
      this.rawData = JSON.parse(JSON.stringify(this.DataItems))
    },


    loadSvg(selection, d) {
      return d3.xml(`${this.__HOST_NAME_RESOURCE}${d.url}`).then(source => {
        let svg_ = source.documentElement
        selection.node().append(svg_)

        svg_ = d3.select(svg_)
        .attr('x', d.x)
        .attr('y', d.y)
        .attr('transform', `translate(${d.x}, ${d.y}) scale(2)`)


        return new Promise(resolve => {
          resolve(svg_)
        })
      })
    },


    zoom() {
      let transform = d3.event.transform
      let el = this.innerSvg.select('#StartUp-Wrapper')
      el.attr('transform', transform)
    },

  }
}