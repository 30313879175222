<template>
  <div :id="localId" class="svg_wrapper"></div>
</template>

<script>
import mx_Core  from './includes/mixins/importer' // Global & Local Mixins

export default {
  name: "k-chart-el",
  mixins: [mx_Core],
  props: {
    id: String,
    modeExport: Boolean,
    getSelectItems: String,  
    
    FilterValues: { type: Object, default: () => ({}) },
    ColumnProps: { type: Array, default: () => ([]) },

    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },
    Queries: { type: Object, default: () => ({}) },
    DataItems: { type: Array, default: () => ([]) },
    
    callback: { type: Object, default: () => ({}) },
  },
  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
  },
  mounted() {
    this.register(this.draw)
  },
  methods: {
    draw() {
      if (!this.ready2draw) return

      this.clear()
      this.setDefaultValues()
      this.drawCanvas()
      this.drawChart()
      this.drawTexts()
      this.complete()
    },
    drawChart() {
      this.drawHeaderInfo()
      this.drawSharedInfo()

      if(this.getSelectItems) {
        let pageOptions = JSON.parse(this.getSelectItems)

        if(pageOptions.route) this.routeTo(pageOptions.route)
        else this.routeTo({ level: 0, lname: 'main'})

      } else this.routeTo({ level: 0, lname: 'main'})
    },
    reforming(code) {
      // this.loadSvg(`${this.__HOST_NAME_RESOURCE}/TCO/Equipment/${this.FilterValues.MOD}/${this.FilterValues.MOD}_${code}.svg`).then(() => {
      //   this.Draw_Code()
      // })
    },
  }
}
</script>

<style lang="stylus" scoped>
.svg_wrapper {
  width: 1600px;
  height: 1120px;

  overflow: hidden;
}
</style>