import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  methods: {
    draw_ModuleInfo(seletion, data, x, y) {
      let moduleSummary = seletion
      .append('g')
      .attr('transform', `translate(${x}, ${y - 63})`)
      
      // moduleSummary
      // .append('image')
      // .attr('id', `img_${data.MODULE}`)
      // .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Modules/${data.MODULE}.png`)
      // .attr('x', 30).attr('y', -140).attr('width', 230).attr('height', 150).attr('opacity', 1)
      // .on('mouseover', () => {
      //   moduleSummary.style('cursor', 'pointer')
      //   d3.select(`#img_${data.MODULE}`)
      //   .transition().duration(300).attr('opacity', .5)
      // })
      // .on('mouseout', () => { 
      //   moduleSummary.style('cursor', 'default')
      //     d3.select(`#img_${data.MODULE}`)
      //     .transition().duration(300).attr('opacity', 1)
      // })
      // .on('click', () => { 
      //   let request_
      //   if (data.MODULE === 'LQ' || data.MODULE === 'HULL-FOR' || data.MODULE === 'HULL-AFT') request_ = this.getRequestColumnProps('level2', { GWBS_LV2: data.MODULE }, this.ColumnProps)
      //   else request_ = this.getRequestColumnProps('area', { GWBS_LV3: data.MODULE }, this.ColumnProps)
      //   if(request_) this.$emit('request-action', request_)
      // })

      moduleSummary
      .append('text')
      .attr('x', 20).attr('y', 15).style('font-size', 20).style('fill', '#44A9DF').attr('text-anchor', 'start').text(data.MODULE)
      moduleSummary
      .append('text')
      .attr('x', 20).attr('y', 30).style('font-size', 14).style('fill', '#757575').attr('text-anchor', 'start').text(data.MODULE_DESCR)

      // Progress Summary for Construction & MC -------------------------------------
      // draw Rects
      moduleSummary
      .append('rect')
      .attr('x', 20).attr('y', 35).attr('rx', 3).attr('ry', 3).attr('width', 70).attr('height', 40).attr('fill',  `url(#${this.localId}__LinearA4${'LightBlue'}0)`).attr('opacity', 0.7)
      moduleSummary
      .append('rect')
      .attr('x', 95).attr('y', 35).attr('rx', 3).attr('ry', 3).attr('width', 130).attr('height', 40).attr('fill', `url(#${this.localId}__LinearA4${'LightBlue'}0)`).attr('opacity', 0.7)
      moduleSummary
      .append('rect')
      .attr('x', 230).attr('y', 35).attr('rx', 3).attr('ry', 3).attr('width', 45).attr('height', 40).attr('fill', `url(#${this.localId}__LinearA4${'LightGray'}0)`).attr('opacity', 0.9)

      // draw Titles
      moduleSummary
      .append('text')
      .attr('x', 55).attr('y', 47).style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'middle').text('Construction')
      moduleSummary
      .append('text')
      .attr('x', 160).attr('y', 47).style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'middle').text('Mechanical Completion')
      moduleSummary
      .append('text')
      .attr('x', 252).attr('y', 47).style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'middle').text('A Punch')

      // Draw Values
      moduleSummary // Progress(%)
      .append('text')
      .attr('x', 85).attr('y', 67).style('font-size', 16).style('fill', '#fff').attr('text-anchor', 'end')
      .transition().duration(1000)
      .tween("text", function() {
        var i = d3.interpolateNumber(1, data.CON_PROG || 0)
        return function(t) {
          d3.select(this).text(`${Number(i(t).toFixed(1)).toLocaleString()}%`)
        }
      })

      moduleSummary 
      .append('text')
      .attr('x', 103).attr('y', 67).style('font-size', 16).style('fill', '#757575').attr('text-anchor', 'start').text('A1')

      moduleSummary // A1 QVD
      .append('text')
      .attr('x', 220).attr('y', 67).style('font-size', 16).style('fill', '#fff').attr('text-anchor', 'end').text('N/A')
      // .transition().duration(1000)
      // .tween("text", function() {
      //   var i = d3.interpolateNumber(1, data.A1_O)
      //   return function(t) {
      //   d3.select(this).text(`${data.A1_T} / ${Number(i(t)).toFixed().toLocaleString()}`)
      //   }
      // })

      moduleSummary // Punch(Active)
      .append('text')
      .attr('x', 267).attr('y', 67).style('font-size', 16).style('fill', '#fff').attr('text-anchor', 'end').text('N/A')
      .transition().duration(1000)
      // .tween("text", function() {
      //   var i = d3.interpolateNumber(1, data.PA)
      //   return function(t) {
      //     d3.select(this).text(Number(i(t)).toFixed().toLocaleString())
      //   }
      // })

      let buttonWrap = moduleSummary
      .append('g')
      .attr('id', 'button_wrapper')
      .on('mouseover', function(){ 
        d3.select(this).transition().duration(100).style('opacity',.5) 
      })
      .on('mouseout', function(){ 
        d3.select(this).transition().duration(100).style('opacity',1) 
      })
      .on('click', () => {
        let request_
        if (data.MODULE === 'LQ' || data.MODULE === 'HULL-FOR' || data.MODULE === 'HULL-AFT') request_ = this.getRequestColumnProps('level2', { GWBS_LV2: data.MODULE }, this.ColumnProps)
        else request_ = this.getRequestColumnProps('area', { GWBS_LV3: data.MODULE }, this.ColumnProps)
        if(request_) this.$emit('request-action', request_)
      })

      buttonWrap
      .append('rect')
      .attr('transform', `translate(235, -148)`)
      .attr('rx', 3)
      .attr('ry', 3)
      .attr('width', 40)
      .attr('height', 14)
      .attr('fill', '#e5e5e5')
      .style('cursor', 'pointer') 

      buttonWrap
      .append('text')
      .attr('transform', `translate(255, -140)`)
      .style('font-family', 'Roboto-Regular, Roboto')
      .style('font-size', 9)
      .style('fill', '#000')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text('More')
      .style('cursor', 'pointer') 

    }, 
  }
}
