import * as d3 from 'd3'

export default {
  data: () => ({
    dataSet: [],
    filters:null
  }),
  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },
  methods: {
    setDefaultValues(){
        this.dataSet = JSON.parse(JSON.stringify(this.DataItems))
        this.filters = JSON.parse(JSON.stringify(this.Queries.SQL1[0]))
        
        this.dataSet.forEach(data => {
         //
        })
  
    },
 
    
  }
}