import * as d3 from 'd3'

export default {
  methods: {

    draw_Forms() {

      this.stage_Header = this.svg
      .append('image')
      .attr('id','id_mainplant_background')
      .attr('transform', `translate(-330, -330) scale(1.3)`)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/China1/Utility_Commissioning/images/MAINPLANT_852.png`)
      .style('opacity', 0)

      d3.select('#id_mainplant_background')
      .transition().duration(350)
      .attr('transform', `translate(-90, -110) scale(1)`)
      .style('opacity', 1)

      // China1 project logo 
      this.stage_Header = this.svg
      this.loadSvg(
        this.stage_Header, {
        x   : 20,
        y   : 20,
        url : '/China1/icons/3d_Logo.svg'
      })

      // title
      this.stage_Header 
      .append('text')
      .attr('transform', `translate(30, 95)`)
      .style('font-family', 'roboto').style('font-size', 16).style('fill', '#333').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Utility Commissioning Sequence')

      
      // After loading the SVG, perform initialization
      this.stage_Header = this.svg
      this.loadSvg(
        this.stage_Header, {
        x   : 0,
        y   : 0,
        url : '/China1/Utility_Commissioning/svg2/TimeLine.svg'
      }).then(() => {
        this.stage_Header.select(`#LEGENDS`).selectAll('text')
        .style('font-family', 'roboto')
        .style('font-size', 11)

        this.Schedule.forEach(d => {
          this.stage_Header.select(`#STEP-${d.no}`)
          .style('cursor', 'pointer')
          .on('click', () => {
            
            d3.select(`#STEP-${this.CurrentStep}-TEXT`)
            .style('fill', '#757575').style('font-weight', 'normal')

            this.CurrentStep = d.no
            d3.select(`#STEP-${d.no}-TEXT`)
            .style('fill', '#B5549B').style('font-weight', 'bold')

            this.load_InitialSVG(d.no)
            this.load_KeyActivities(d)
          })
          .call(this.call_timeSchedule, d) // ---> Event.js
        })
        // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        // PAGESVG 에서는 drawNotes,drawTexts 는 loadsvg 가 제일 마지막에 실행이되므로
        // this.loadSvg().then 에서 처리해야 제일 상단에 그려진다.
        // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        this.drawNotes()
        this.drawTexts() 
      })
    },


    draw_MenuboxHeader() {

      this.commissioningBox = this.svg.append('svg')
      let menuBox = this.commissioningBox

      .append('g')
      .attr('id', 'menubox_group')
      .attr('transform', `translate(25, 260)`)

        menuBox.append("rect")
        .attr('x', 0).attr('y', 0)
        .attr('width', 320).attr('height', 35)
        .attr('rx', 5).attr('ry', 5)
        .style('filter', 'url(#dropshadow)')

        menuBox.append("rect")
        .attr('x', 0).attr('y', 0)
        .attr('width', 320).attr('height', 35)
        .attr('rx', 5).attr('ry', 5)
        .attr('fill','url(#menu_box)')

        menuBox.append("text")
        .attr('x', 10).attr('y', 20)
        .attr('font-size',14).attr('fill','#000')
        .text('Pre-Commissioning Start')

        menuBox.append("rect")
        .attr('id', 'list_box_filter')
        .attr('x', 0).attr('y', 32)
        .attr('width', 320).attr('height', 5)
        .style('filter', 'url(#dropshadow)')

        menuBox.append("rect")
        .attr('id', 'list_box_background')
        .attr('x', 0).attr('y', 32)
        .attr('width', 320).attr('height', 5)
        .attr('fill','#FBFBFB')
    },
  }
}
           