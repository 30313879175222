import * as d3 from 'd3'

export default {
  data: () => ({
    route: {
      from: null,
      to: null,
    },
    routeHistory: [],
    loadFunc: null,   // store the selection list for loading
    unloadFunc: null, // store the selection list for unloading
  }),

  mounted() {
    this.loadFunc = {
      Lev1: this.load_Lev1,
      Lev2: this.load_Lev2,
      Lev3: this.load_Lev3
    }
    this.unloadFunc = {
      Lev1: this.unload_Lev1,
      Lev2: this.unload_Lev2,
      Lev3: this.unload_Lev3
    }
  },

  methods: {
    routeTo(from, to) {

      let from_ = from
      let to_ = to

      // Action to be taken when the back button is clicked
      if(to == 'back') {     
        let len = this.routeHistory.length

        from_ = this.routeHistory[len-1]
        this.routeHistory.splice(-1)

        len -= 1
        to_ = this.routeHistory[len-1]

      } else { this.routeHistory.push(to_) }

      this.selectedItems = {
        ...this.selectedItems,
        route: to_
      }

      this.route.from = from_
      this.route.to = to_
      

      // console.log('from: ', this.route.from.path, 'to: ', this.route.to.path)
      // console.log('routeHistory: ', this.routeHistory)
     
      if(from_.path) {
        this.unloadFunc[from_.path]().then(() => {
          this.loadFunc[to_.path]()
        })
      } else this.loadFunc[to_.path]()

    },
   
  }
}