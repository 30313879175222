export default {
  data: () => ({
    areaBox: {
      x: 1570,
      y: 110,
      w: 175,
      h: 172,
      lineItems: [5, 3, 1, 1, 7]
    },
    areaRegions: [
      { package: 'P1', code: 'BTX', fill: '#42A5F5', path: 'M 452 635 L 603 565 L 648 615 L 497 687 L 451 635 Z' },
      { package: 'P1', code: 'BD' , fill: '#42A5F5', path: 'M 533 726 L 684 655 L 747 725 L 596 796 L 534 726 Z' },
      { package: 'P1', code: 'DIB' , fill: '#42A5F5', path: 'M 597 797 L 747 725 L 785 766 L 633 837 L 597 797 Z' },
      { package: 'P1', code: 'BOILER' , fill: '#42A5F5', path: 'M 388 564 L 536 492 L 601 564 L 450 634 L 387 563 Z' },
      { package: 'P1', code: 'GHU' , fill: '#42A5F5', path: 'M 497 686 L 650 615 L 685 655 L 534 726 L 497 686 Z' },
      { package: 'P1', code: 'OR' , fill: '#42A5F5', path: 'M 606 564 L 816 467 L 882 541 L 818 572 L 919 684 L 984 654 L 1040 715 L 830 812 L 605 565 Z' },
      { package: 'P1', code: 'FUR' , fill: '#42A5F5', path: 'M 818 573 L 883 542 L 983 654 L 919 684 L 817 572 Z' },
      { package: 'P1', code: 'DW' , fill: '#42A5F5', path: 'M 344 518 L 496 447 L 537 493 L 387 562 L 344 519 Z' },
      // { package: 'P1', code: 'STF' , fill: '#42A5F5', path: 'M 831 813 L 1349 577 L 1488 731 L 970 971 L 831 813 Z' },
      { package: 'P1', code: 'STF' , fill: '#42A5F5', path: 'M 835 814 L 1352 575 L 1494 732 L 1186 872 L 1214 901 L 1128 941 L 1099 911 L 974 968 L 835 813 Z' },
      //{ package: 'P2', code: 'LDPE' , fill: '#FFA726', path: 'M 541 538 L 903 378 L 996 484 L 643 643 L 540 536 Z' },
      //{ package: 'P2', code: 'GPPE' , fill: '#FFA726', path: 'M 733 746 L 865 686 L 887 709 L 1110 608 L 1192 698 L 839 862 L 735 749 Z' },
      //{ package: 'P2', code: 'PP' , fill: '#FFA726', path: 'M 645 648 L 995 483 L 1107 606 L 885 708 L 830 650 L 703 711 L 645 648 Z' },
      { package: 'P2', code: 'LDPE' , fill: '#FFA726', path: 'M 676 477 L 903 373 L 1000 480 L 717 609 L 678 567 L 734 541 Z' },
      { package: 'P2', code: 'PP' , fill: '#FFA726', path: 'M 717 609 L 1000 480 L 1114 605 L 888 708 L 833 650 L 777 675 Z' },
      { package: 'P2', code: 'GPPE' , fill: '#FFA726', path: 'M 793 668 L 833 650 L 888 708 L 1114 605 L 1198 697 L 915 826 L 816 718 L 832 710 Z' },
      { package: 'P3', code: 'WFS' , fill: '#ec407a', path: 'M 396 415 L 424 402 L 489 377 L 543 440 L 654 391 L 694 434 L 753 411 L 780 399 L 808 430 L 922 382 L 963 424 L 1230 717 L 1264 754 L 1549 625 L 1595 673 L 1447 741 L 1403 690 L 1266 753 L 1208 782 L 1173 744 L 1230 717 L 963 424 L 824 490 L 752 411 L 696 434 L 498 528 L 396 413 Z' },
      // { package: 'P3', code: 'STF' , fill: '#ec407a', path: 'M 672 408 L 755 371 L 780 400 L 754 410 L 824 491 L 1071 762 L 1154 724 L 1174 744 L 1232 719 L 1214 698 L 1493 566 L 1548 626 L 1327 726 L 1370 776 L 1311 803 L 1226 716 L 1173 744 L 1153 722 L 1071 762 L 824 491 L 753 411 L 695 434 L 673 408 Z' },
      { package: 'P3', code: 'STF' , fill: '#ec407a', path: 'M 671 408 L 754 371 L 778 399 L 753 411 L 1071 761 L 1154 724 L 1173 744 L 1232 718 L 1214 695 L 1496 567 L 1547 623 L 1327 728 L 1370 777 L 1311 805 L 1232 719 L 1175 744 L 1154 724 L 1071 761 L 754 412 L 696 435 L 673 409 Z' },
      { package: 'P3', code: 'EPF' , fill: '#ec407a', path: 'M 1326 729 L 1401 691 L 1445 742 L 1369 777 L 1326 728 Z' },
      { package: 'P3', code: 'MF' , fill: '#ec407a', path: 'M 308 455 L 394 415 L 452 481 L 369 520 L 404 609 L 533 749 L 498 763 L 399 661 L 375 671 L 365 658 L 404 609 L 369 520 L 309 455 Z' },
    ],
  }),
  
  methods: {
    loadPackage() {
      this.innerSvg = this.svg.append('svg')
      this.setPriority()
      
      let background = this.innerSvg
      .append('image')
      .attr('class','el_page_package_sector_background')
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/China1/EquipmentLayout/Sector/PACKAGE_${this.route.to.package}.jpg`)
      .style('opacity', 0)
      .on('load', this.imagePackageLoaded)

      if(
        this.route.from && 
        this.route.from.level < this.route.to.level
      ) background.attr('transform', `translate(${this.zoom.out.x}, ${this.zoom.out.y}) scale(${1-this.scale})`)
      else background.attr('transform', `translate(${this.zoom.in.x}, ${this.zoom.in.y}) scale(${1+this.scale})`)
    },
    postLoadPackage() {
      // draw area pathes
      this.areaRegions.filter(r => r.package == this.route.to.package)
      .forEach(r => {
        this.innerSvg
        .append('path')
        .attr('d', r.path)
        .style('fill', r.fill)
        .style('opacity', .0)
        .style('cursor', 'pointer')
        .on('click', () => {
          this.routeTo({
            level  : 2,
            lname  : 'area',
            package: this.route.to.package,
            area   : r.code,
          }, {
            level  : 1,
            lname  : 'package',
            package: this.route.to.package,
          })
        })
        .call(this.callFuncRegionalAction)
      })

      // draw area summary
      let lineidx = 0
      let linecnt = this.areaBox.lineItems[lineidx]
      let linefactor = 0
      this.dataset.stageSummary.area.filter(d => d.LV2 == this.route.to.package).forEach((d, i) => {
        if(i===linecnt) {
          linefactor = linecnt
          linecnt += this.areaBox.lineItems[++lineidx]
        }

        let x__ = this.areaBox.x - this.areaBox.w * (i-linefactor+1) - (i-linefactor?10:0)
        let y__ = this.areaBox.y + this.areaBox.h * lineidx
        
        this.drawStageStatusSmall(this.innerSvg, {
          x      : x__,
          y      : y__,
          w      : this.areaBox.w,
          h      : this.areaBox.h,
          title  : `Area ${d.LV3}`,
          tsize  : 10,
          shadow : true,
          source : d,
          filters: { LV2: d.LV2, LV3: d.LV3 }
        })
      })

      /**
       * Svg Path Tool to get the clicked mouse point (x, y)
       */
      // this.createPathReigon(this.innerSvg, {
      //   w: 1600,
      //   h: 1120,
      //   strokeWidth: 2,
      //   strokeColor: '#ff00ff'
      // })
    },
    unloadPackage() {
      let background = this.innerSvg.select('.el_page_package_sector_background')
      .transition().duration(350)

      if(
        this.route.from && 
        this.route.from.level < this.route.to.level
      ) background.attr('transform', `translate(${this.zoom.in.x}, ${this.zoom.in.y}) scale(${1+this.scale})`)
      else background.attr('transform', `translate(${this.zoom.out.x}, ${this.zoom.out.y}) scale(${1-this.scale})`)

      this.innerSvg
      .transition().delay(100).duration(250)
      .style('opacity', 0)

      return new Promise(resolve => {
        setTimeout(() => {
          this.innerSvg.remove()
          resolve() 
        }, 500)
      })
    },
    imagePackageLoaded() {
      this.innerSvg.select('.el_page_package_sector_background')
      .transition().duration(350)
      .attr('transform', 'translate(0, 0) scale(1)')
      .style('opacity', 1)

      setTimeout(() => { this.postLoadPackage() }, 400)
    }
  }
}