import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({
    style:[
      {Level:'L5',color:'#0B172D',Descr:'L5 - Multiple',},
      {Level:'L4',color:'#A8172C',Descr:'L4- Single',},
      {Level:'L3',color:'#EE531A',Descr:'L3 - Life Altering',},
      {Level:'L2',color:'#F78D0B',Descr:'L2 - Moderate',},
      {Level:'L1',color:'#F9B90E',Descr:'L1 - Minor',},
      {Level:'L0',color:'#F8E00E',Descr:'L0 - No Hurt',},
      {Level:'L' ,color:'#F2F2F2',Descr:'Actual Severity',},
    ]
  }),
  
  methods: {
    drawChart() {

      let pyramidChartWrap = this.svg
      .append('g')
      .attr('id', 'pyramid-chart-wrap')
      .attr('transform', `translate(0, 100)`)
      .style('font-family','roboto')

      let defs = pyramidChartWrap.append('defs')
      let dropShadowFilter = defs.append('svg:filter')
      .attr('id', 'drop-shadow')
      .attr('filterUnits', "userSpaceOnUse")
      .attr('width', '200%').attr('height', '200%')
      dropShadowFilter.append('svg:feGaussianBlur')
      .attr('in', 'SourceAlpha')
      .attr('stdDeviation', 5)
      .attr('result', 'blur-out')
      dropShadowFilter.append('svg:feOffset')
      .attr('in', 'color-out')
      .attr('dx', 2).attr('dy', 2)
      .attr('result', 'the-shadow')
      dropShadowFilter.append('svg:feBlend')
      .attr('in', 'SourceGraphic')
      .attr('in2', 'the-shadow')
      .attr('mode', 'normal')
      //---------------------------------------------pyramid side

      let width__ = this.TrapezoidX - 130 //side의 width

      let pyramidChart2 = pyramidChartWrap
      .append('g')  
      .attr('id', 'pyramid-pink')
      .attr('transform', `translate(150.5, -100.5)`)

      let k = 0
      this.style.forEach((sty,i) => {
        pyramidChart2
        .append('path')
        .attr('transform', `translate(${this.TrapezoidC*(i*-1)}, ${this.TrapezoidY*i})`)
        .attr('d', `
          M${this.TrapezoidC},0 
          L${this.TrapezoidX-(this.TrapezoidC*(k+1))},0 
          L${this.TrapezoidX-(this.TrapezoidC*k)},${this.TrapezoidY} 
          L0,${this.TrapezoidY}
          Z
          `)
        .style("filter", "url(#drop-shadow)")
        .attr('fill',sty.color)
        .style('opacity',`${0.9}`)
        k -= 2

        pyramidChart2
        .append('text')
        .attr('transform', `translate(${-135}, ${this.TrapezoidY*i+25})`)
        .style('font-size',10).attr('text-anchor', 'start').attr('fill',sty.color =='#F2F2F2'? '#9E9E9E':sty.color=='#F8E00E'? '#FFD600':sty.color)
        .text(sty.Descr)

        pyramidChart2
        .append('line')
        .attr('x1', -135).attr('y1', this.TrapezoidY*i+30).attr('x2', 10).attr('y2', this.TrapezoidY*i+30).attr('stroke-width', 0.5).attr('stroke', '#616161')
        
        let find =this.DataItems.find(d=>d.SEVERITY==sty.Level)
        if(!this.DataItems.find(d=>d.SEVERITY==sty.Level)) return
        pyramidChart2
        .append('text')
        .attr('transform', `translate(${sty.Level =='L5'?18:8}, ${this.TrapezoidY*i+23})`)
        .style('font-size',11).attr('text-anchor', 'end').attr('fill',sty.Level =='L5'?'#fff':'#000')
        .text(find.ACTUAL !='undefined'?find.ACTUAL:'')

        for(let li=0;li<5;li++){
          pyramidChart2
          .append('line')
          .attr('x1', 15).attr('y1', 43+li*30).attr('x2', 15).attr('y2', 55+li*30).attr('stroke-width', 0.5).attr('stroke', '#9E9E9E')
        }
        
        pyramidChart2
        .append('text')
        .attr('transform', `translate(${(i*1)+20}, ${this.TrapezoidY*i+23})`)
        .style('font-size',11).attr('text-anchor', 'start').attr('fill','#000')
        .text(find.POTENTIAL !='undefined'?find.POTENTIAL:'')
      
      })

      let pyramidText = pyramidChartWrap
      .append('g')
      .attr('id', 'pyramid-text')
      .attr('transform', `translate(-50, -193)`)
      pyramidText
      .append('text')
      .attr('x',222).attr('y', 298)
      .style('font-size',10).attr('text-anchor', 'middle').attr('fill', '#000')
      .text('China1-1 Project Safety')
      pyramidText
      .append('text')
      .attr('x',222).attr('y', 285)
      .style('font-size',10).attr('text-anchor', 'middle').attr('fill', '#9E9E9E')
      .text('Actaul | Potential')
    }
  }
}
