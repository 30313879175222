const Hull_LQ = [
  { Blk: '1906', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 593.17, y: 640.41, x1: 633, y1: 606.91, x2: 633, y2: 606.91, x3: 645, y3: 804.91, x4: 421, y4: 627.91, },
{ Blk: '3905', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 926, y: 678.8, x1: 792.583, y1: 635.414, x2: 792.583, y2: 635.414, x3: 804.583, y3: 833.41, x4: 929.58, y4: 696.41, },
{ Blk: '3903', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 828, y: 582.55, x1: 764.083, y1: 615.464, x2: 764.083, y2: 615.464, x3: 776.083, y3: 813.46, x4: 836.08, y4: 598.46, },
{ Blk: '4904', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 652, y: 696.54, x1: 704.083, y1: 650.914, x2: 704.083, y2: 650.914, x3: 716.083, y3: 848.91, x4: 670.08, y4: 679.91, },
{ Blk: '1903', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 905.02, y: 624.73, x1: 782.039, y1: 615.414, x2: 782.039, y2: 615.414, x3: 794.039, y3: 813.41, x4: 869.04, y4: 605.41, },
{ Blk: '2901', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 800, y: 601.58, x1: 752.083, y1: 626.436, x2: 752.083, y2: 626.436, x3: 764.083, y3: 824.432, x4: 814.08, y4: 616.44, },
{ Blk: '1909', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 743.31, y: 602.58, x1: 720.083, y1: 635.964, x2: 720.083, y2: 635.964, x3: 732.083, y3: 833.96, x4: 738.08, y4: 626.96, },
{ Blk: '2904', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 699.92, y: 703.8, x1: 735.083, y1: 661.914, x2: 735.083, y2: 661.914, x3: 747.083, y3: 859.91, x4: 717.08, y4: 692.91, },
{ Blk: '1904', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 674.06, y: 738.05, x1: 711.083, y1: 660.914, x2: 711.083, y2: 660.914, x3: 723.083, y3: 858.91, x4: 674.08, y4: 719.91, },
{ Blk: '2902', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 722, y: 654.72, x1: 727.583, y1: 643.414, x2: 727.583, y2: 643.414, x3: 739.583, y3: 841.41, x4: 743.58, y4: 665.41, },
{ Blk: '1902', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 806, y: 682.31, x1: 761.083, y1: 650.439, x2: 761.083, y2: 650.439, x3: 773.083, y3: 848.435, x4: 777.08, y4: 672.44, },
{ Blk: '3904', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 757.97, y: 710.88, x1: 743.083, y1: 659.914, x2: 743.083, y2: 659.914, x3: 755.083, y3: 857.91, x4: 741.08, y4: 693.91, },
{ Blk: '1901', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 850.69, y: 652.87, x1: 741.558, y1: 636.914, x2: 741.558, y2: 636.914, x3: 753.559, y3: 834.91, x4: 792.56, y4: 634.91, },
{ Blk: '2903', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 982, y: 633, x1: 804.983, y1: 624.936, x2: 804.983, y2: 624.936, x3: 816.983, y3: 822.932, x4: 994.98, y4: 648.94, },
{ Blk: '9907', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 1026.33, y: 629.75, x1: 814.583, y1: 625.946, x2: 814.583, y2: 625.946, x3: 826.583, y3: 823.942, x4: 1023.58, y4: 652.95, },
{ Blk: '3907', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 1026.67, y: 675.03, x1: 812.083, y1: 626.814, x2: 812.083, y2: 626.814, x3: 824.083, y3: 824.81, x4: 1034.08, y4: 657.81, },
{ Blk: '2905', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 978, y: 685.95, x1: 793.583, y1: 636.564, x2: 793.583, y2: 636.564, x3: 805.583, y3: 834.56, x4: 963.58, y4: 703.56, },
{ Blk: '1905', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 920, y: 716.13, x1: 784.083, y1: 651.514, x2: 784.083, y2: 651.514, x3: 796.083, y3: 849.51, x4: 937.08, y4: 730.51, },
{ Blk: '5904', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 838, y: 732.59, x1: 756.083, y1: 652.414, x2: 756.083, y2: 652.414, x3: 768.083, y3: 850.41, x4: 842.08, y4: 742.41, },
{ Blk: '1907', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 987.9, y: 723.93, x1: 804.083, y1: 645.331, x2: 804.083, y2: 645.331, x3: 816.083, y3: 843.327, x4: 991.08, y4: 715.33, },
{ Blk: '4908', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 893, y: 737.46, x1: 774.039, y1: 671.067, x2: 774.039, y2: 671.067, x3: 786.039, y3: 869.063, x4: 910.04, y4: 757.07, },
{ Blk: '2908', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 906.31, y: 792.9, x1: 780.558, y1: 666.939, x2: 780.558, y2: 666.939, x3: 792.559, y3: 864.935, x4: 915.56, y4: 768.94, },
{ Blk: '1908', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 859.4, y: 785.3, x1: 753.083, y1: 670.454, x2: 753.083, y2: 670.454, x3: 765.083, y3: 868.45, x4: 871.08, y4: 768.45, },
{ Blk: '7907', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 1114.98, y: 647.09, x1: 821.983, y1: 622.446, x2: 821.983, y2: 622.446, x3: 833.983, y3: 820.442, x4: 1060.98, y4: 643.45, },
{ Blk: '5907', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 1086.01, y: 667.5, x1: 830.483, y1: 628.964, x2: 830.483, y2: 628.964, x3: 842.483, y3: 826.96, x4: 1053.48, y4: 658.96, },
{ Blk: '8907', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 1109.24, y: 605.85, x1: 823.583, y1: 620.464, x2: 823.583, y2: 620.464, x3: 835.583, y3: 818.46, x4: 1080.58, y4: 630.46, },
{ Blk: '6907', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 1135.99, y: 708.97, x1: 834.493, y1: 628.831, x2: 834.493, y2: 628.831, x3: 846.493, y3: 826.827, x4: 1111.49, y4: 692.83, },
{ Blk: '4905', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 1059.04, y: 701.68, x1: 812.592, y1: 640.914, x2: 812.592, y2: 640.914, x3: 824.592, y3: 838.91, x4: 1016.59, y4: 714.91, },
{ Blk: '2906', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 964.5, y: 755.14, x1: 798.083, y1: 656.664, x2: 798.083, y2: 656.664, x3: 810.083, y3: 854.66, x4: 951.08, y4: 737.66, },
{ Blk: '3908', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 972.72, y: 792.75, x1: 790.083, y1: 664.914, x2: 790.083, y2: 664.914, x3: 802.083, y3: 862.91, x4: 941.08, y4: 776, },
{ Blk: '5905', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 1089.47, y: 739.75, x1: 826.083, y1: 646.929, x2: 826.083, y2: 646.929, x3: 838.083, y3: 844.925, x4: 1062.08, y4: 726.93, },
{ Blk: '3906', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 1026.11, y: 754.87, x1: 816.083, y1: 659.914, x2: 816.083, y2: 659.914, x3: 828.083, y3: 857.91, x4: 1002.08, y4: 744.91, },
{ Blk: '4907', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 995, y: 662.08, x1: 806.583, y1: 628.936, x2: 806.583, y2: 628.936, x3: 818.583, y3: 826.932, x4: 994.58, y4: 682.94, },
{ Blk: '909', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 711.57, y: 259, x1: 720.072, y1: 618.554, x2: 720.072, y2: 618.554, x3: 732.072, y3: 816.55, x4: 740.07, y4: 253.55, },
{ Blk: '993', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 952.28, y: 234.96, x1: 782.278, y1: 591.61, x2: 782.278, y2: 591.61, x3: 794.278, y3: 789.606, x4: 887.28, y4: 212.61, },
{ Blk: '903', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 914.57, y: 269.12, x1: 764.239, y1: 598.204, x2: 764.239, y2: 598.204, x3: 776.239, y3: 796.2, x4: 834.24, y4: 245.2, },
{ Blk: '901', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 857.58, y: 305, x1: 741.322, y1: 610.604, x2: 741.322, y2: 610.604, x3: 753.322, y3: 808.6, x4: 777.32, y4: 281.6, },
{ Blk: '902', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 801.63, y: 343, x1: 727.302, y1: 626.531, x2: 727.302, y2: 626.531, x3: 739.302, y3: 824.527, x4: 728.3, y4: 322.53, },
{ Blk: '997', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 890.2, y: 447.78, x1: 743.033, y1: 666.804, x2: 743.033, y2: 666.804, x3: 755.033, y3: 864.8, x4: 837.03, y4: 433.8, },
{ Blk: '904', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 748.82, y: 381, x1: 704.072, y1: 634.304, x2: 704.072, y2: 634.304, x3: 716.072, y3: 832.3, x4: 669.07, y4: 355.3, },
{ Blk: '991', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 670.25, y: 426, x1: 696.083, y1: 646.904, x2: 696.083, y2: 646.904, x3: 708.083, y3: 844.9, x4: 593.08, y4: 414.9, },
{ Blk: '994', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 1116.49, y: 230.59, x1: 823.322, y1: 599.954, x2: 823.322, y2: 599.954, x3: 835.322, y3: 797.95, x4: 1079.32, y4: 250.95, },
{ Blk: '907', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 1097.82, y: 305, x1: 805.572, y1: 605.854, x2: 805.572, y2: 605.854, x3: 817.572, y3: 803.85, x4: 1026.57, y4: 282.85, },
{ Blk: '905', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 1052.08, y: 343, x1: 783.083, y1: 619.504, x2: 783.083, y2: 619.504, x3: 795.083, y3: 817.5, x4: 969.08, y4: 320.5, },
{ Blk: '906', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 1002.17, y: 381, x1: 768.833, y1: 635.604, x2: 768.833, y2: 635.604, x3: 780.833, y3: 833.6, x4: 919.83, y4: 362.6, },
{ Blk: '2907', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 1212.33, y: 267, x1: 847.933, y1: 605.854, x2: 847.933, y2: 605.854, x3: 859.934, y3: 803.85, x4: 1136.93, y4: 263.85, },
{ Blk: '908', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 946.29, y: 426, x1: 751.795, y1: 644.987, x2: 751.795, y2: 644.987, x3: 763.795, y3: 842.983, x4: 867.79, y4: 395.99, },
{ Blk: '992', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV01', x: 872.64, y: 477.77, x1: 737.386, y1: 656.521, x2: 737.386, y2: 656.521, x3: 749.386, y3: 854.517, x4: 785.39, y4: 455.52, },
{ Blk: '913', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV23', x: 946.29, y: 552.79, x1: 763.795, y1: 585.044, x2: 763.795, y2: 585.044, x3: 775.795, y3: 642.042, x4: 863.79, y4: 538.04, },
{ Blk: '911', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV23', x: 869.94, y: 598, x1: 741.833, y1: 598.054, x2: 741.833, y2: 598.054, x3: 753.833, y3: 655.052, x4: 791.83, y4: 584.05, },
{ Blk: '912', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV23', x: 796.48, y: 650, x1: 727.033, y1: 613.954, x2: 727.033, y2: 613.954, x3: 739.033, y3: 670.952, x4: 727.03, y4: 636.95, },
{ Blk: '914', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV23', x: 735.1, y: 699, x1: 710.683, y1: 623.704, x2: 710.683, y2: 623.704, x3: 722.684, y3: 680.702, x4: 660.68, y4: 682.7, },
{ Blk: '917', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV23', x: 1131.85, y: 598, x1: 804.105, y1: 589.063, x2: 804.104, y2: 589.063, x3: 816.104, y3: 646.061, x4: 1054.1, y4: 571.06, },
{ Blk: '915', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV23', x: 1066.72, y: 650, x1: 782.028, y1: 606.454, x2: 782.028, y2: 606.454, x3: 794.028, y3: 663.452, x4: 982.03, y4: 621.45, },
{ Blk: '916', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV23', x: 999.83, y: 699, x1: 768.191, y1: 621.615, x2: 768.19, y2: 621.615, x3: 780.19, y3: 678.613, x4: 918.19, y4: 673.61, },
{ Blk: '918', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV23', x: 928.53, y: 741.85, x1: 751.333, y1: 631.579, x2: 751.333, y2: 631.579, x3: 763.333, y3: 688.577, x4: 851.33, y4: 719.58, },
{ Blk: '923', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV23', x: 948.18, y: 238.14, x1: 765.182, y1: 571.89, x2: 765.182, y2: 571.89, x3: 777.182, y3: 628.888, x4: 865.18, y4: 222.89, },
{ Blk: '921', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV23', x: 881.46, y: 282, x1: 743.123, y1: 584.292, x2: 743.123, y2: 584.292, x3: 755.123, y3: 641.29, x4: 793.12, y4: 268.29, },
{ Blk: '922', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV23', x: 801.94, y: 333, x1: 726.44, y1: 600.275, x2: 726.44, y2: 600.275, x3: 738.44, y3: 657.273, x4: 726.44, y4: 321.27, },
{ Blk: '924', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV23', x: 730.9, y: 384, x1: 712.147, y1: 611.292, x2: 712.146, y2: 611.292, x3: 724.146, y3: 668.29, x4: 662.15, y4: 368.29, },
{ Blk: '995', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV23', x: 667.48, y: 430, x1: 698.809, y1: 622.025, x2: 698.809, y2: 622.025, x3: 710.809, y3: 679.023, x4: 598.81, y4: 414.03, },
{ Blk: '927', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV23', x: 1128.49, y: 282, x1: 803.902, y1: 576.292, x2: 803.902, y2: 576.292, x3: 815.902, y3: 633.29, x4: 1054.9, y4: 256.29, },
{ Blk: '925', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV23', x: 1068.92, y: 333, x1: 783.002, y1: 593.292, x2: 783.002, y2: 593.292, x3: 795.002, y3: 650.29, x4: 983, y4: 306.29, },
{ Blk: '926', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV23', x: 1003.15, y: 384, x1: 766.902, y1: 608.704, x2: 766.902, y2: 608.704, x3: 778.902, y3: 665.702, x4: 916.9, y4: 358.7, },
{ Blk: '928', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV23', x: 933.87, y: 430, x1: 752.038, y1: 620.042, x2: 752.038, y2: 620.042, x3: 764.038, y3: 677.04, x4: 852.04, y4: 406.04, },
{ Blk: '996', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV23', x: 872.38, y: 470.14, x1: 738.629, y1: 630.142, x2: 738.629, y2: 630.142, x3: 750.629, y3: 687.14, x4: 788.63, y4: 451.14, },
{ Blk: '933', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV45', x: 898.4, y: 544.32, x1: 762.599, y1: 560.17, x2: 762.599, y2: 560.17, x3: 774.599, y3: 472.17, x4: 816.6, y4: 527.17, },
{ Blk: '931', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV45', x: 824.68, y: 588, x1: 741.932, y1: 573.693, x2: 741.933, y2: 573.693, x3: 753.933, y3: 485.693, x4: 745.93, y4: 574.69, },
{ Blk: '932', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV45', x: 751.72, y: 638, x1: 726.049, y1: 588.443, x2: 726.049, y2: 588.443, x3: 738.049, y3: 500.443, x4: 680.05, y4: 618.44, },
{ Blk: '934', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV45', x: 693.9, y: 685, x1: 710.9, y1: 599.231, x2: 710.9, y2: 599.231, x3: 722.9, y3: 511.231, x4: 614.9, y4: 664.23, },
{ Blk: '937', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV45', x: 1134.99, y: 588, x1: 803.018, y1: 564.113, x2: 803.018, y2: 564.113, x3: 815.018, y3: 476.113, x4: 1057.02, y4: 570.11, },
{ Blk: '935', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV45', x: 1062.63, y: 638, x1: 781.495, y1: 581.863, x2: 781.495, y2: 581.863, x3: 793.495, y3: 493.863, x4: 985.5, y4: 623.86, },
{ Blk: '936', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV45', x: 996.16, y: 685, x1: 766.908, y1: 596.883, x2: 766.907, y2: 596.883, x3: 778.908, y3: 508.883, x4: 920.91, y4: 665.88, },
{ Blk: '938', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV45', x: 933.82, y: 733.73, x1: 750.851, y1: 607.156, x2: 750.852, y2: 607.156, x3: 762.852, y3: 519.156, x4: 855.85, y4: 711.16, },
{ Blk: 'MS1', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV45', x: 872.15, y: 771.09, x1: 759.735, y1: 620.593, x2: 759.735, y2: 620.593, x3: 771.735, y3: 532.593, x4: 813.74, y4: 760.59, },
{ Blk: '943', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV45', x: 895.78, y: 235.82, x1: 762.2, y1: 546.823, x2: 762.2, y2: 546.823, x3: 774.2, y3: 458.823, x4: 816.2, y4: 213.82, },
{ Blk: '941', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV45', x: 826.67, y: 277, x1: 741.583, y1: 560.304, x2: 741.583, y2: 560.304, x3: 753.583, y3: 472.304, x4: 745.58, y4: 263.3, },
{ Blk: '942', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV45', x: 750.17, y: 328, x1: 726.173, y1: 574.804, x2: 726.173, y2: 574.804, x3: 738.172, y3: 486.804, x4: 680.17, y4: 304.8, },
{ Blk: '944', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV45', x: 688.67, y: 374, x1: 711, y1: 585.68, x2: 711, y2: 585.68, x3: 723, y3: 497.68, x4: 615, y4: 350.68, },
{ Blk: '947', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV45', x: 1132.5, y: 277, x1: 802.833, y1: 550.173, x2: 802.833, y2: 550.173, x3: 814.833, y3: 462.173, x4: 1056.83, y4: 256.17, },
{ Blk: '945', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV45', x: 1060.14, y: 328, x1: 782.222, y1: 568.173, x2: 782.222, y2: 568.173, x3: 794.222, y3: 480.173, x4: 986.22, y4: 310.17, },
{ Blk: '946', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV45', x: 997.7, y: 374, x1: 766.783, y1: 582.673, x2: 766.783, y2: 582.673, x3: 778.783, y3: 494.673, x4: 920.78, y4: 351.67, },
{ Blk: '948', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV45', x: 937.73, y: 417.76, x1: 751.563, y1: 593.83, x2: 751.563, y2: 593.83, x3: 763.563, y3: 505.83, x4: 855.56, y4: 397.83, },
{ Blk: '953', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV67', x: 929.5, y: 582.82, x1: 762.752, y1: 531.817, x2: 762.752, y2: 531.817, x3: 774.752, y3: 303.816, x4: 846.75, y4: 564.82, },
{ Blk: '951', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV67', x: 856.87, y: 634, x1: 741.702, y1: 545.317, x2: 741.702, y2: 545.317, x3: 753.702, y3: 317.316, x4: 775.7, y4: 614.32, },
{ Blk: '952', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV67', x: 784.87, y: 681, x1: 726.452, y1: 561.692, x2: 726.452, y2: 561.692, x3: 738.452, y3: 333.691, x4: 710.45, y4: 656.69, },
{ Blk: '954', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV67', x: 722.02, y: 726, x1: 710.85, y1: 572.442, x2: 710.85, y2: 572.442, x3: 722.85, y3: 344.441, x4: 644.85, y4: 702.44, },
{ Blk: '957', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV67', x: 1158.5, y: 634, x1: 802.833, y1: 535.942, x2: 802.833, y2: 535.942, x3: 814.833, y3: 307.941, x4: 1086.83, y4: 608.94, },
{ Blk: '955', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV67', x: 1090.54, y: 681, x1: 782.452, y1: 555.942, x2: 782.452, y2: 555.942, x3: 794.452, y3: 327.941, x4: 1016.45, y4: 664.94, },
{ Blk: '959', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'HD01', Lv4: 'Helideck', x: 954.265, y: 587.817, x1: 804.265, y1: 567.817, x2: 804.265, y2: 261.817, x3: 804.265, y3: 567.82, x4: null, y4: null, },
{ Blk: '956', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV67', x: 1024.2, y: 726, x1: 766.952, y1: 570.192, x2: 766.952, y2: 570.192, x3: 778.952, y3: 342.191, x4: 950.95, y4: 705.19, },
{ Blk: '958', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV67', x: 963.53, y: 772.11, x1: 751.526, y1: 580.817, x2: 751.526, y2: 580.817, x3: 763.526, y3: 352.816, x4: 885.53, y4: 750.82, },
{ Blk: '963', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV67', x: 920.3, y: 282.75, x1: 762.634, y1: 515.504, x2: 762.634, y2: 515.504, x3: 774.634, y3: 287.503, x4: 846.63, y4: 248.5, },
{ Blk: '961', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV67', x: 851.89, y: 319, x1: 741.725, y1: 533.531, x2: 741.725, y2: 533.531, x3: 753.725, y3: 305.53, x4: 775.73, y4: 302.53, },
{ Blk: '964', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV67', x: 1158.02, y: 319, x1: 807.85, y1: 521.15, x2: 807.85, y2: 521.15, x3: 819.85, y3: 293.149, x4: 1091.85, y4: 294.15, },
{ Blk: '965', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV67', x: 1089.86, y: 365, x1: 791.442, y1: 537.924, x2: 791.442, y2: 537.924, x3: 803.442, y3: 309.923, x4: 1025.44, y4: 346.92, },
{ Blk: '966', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV67', x: 945.17, y: 650.9, x1: 781.383, y1: 574.05, x2: 781.383, y2: 574.05, x3: 793.383, y3: 346.049, x4: 915.38, y4: 674.05, },
{ Blk: '971', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV67', x: 1165.18, y: 240.5, x1: 822.9, y1: 513.4, x2: 822.9, y2: 513.4, x3: 834.85, y3: 284.999, x4: 1106.85, y4: 236, },
{ Blk: '972', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV67', x: 815, y: 224, x1: 745.482, y1: 531.004, x2: 745.482, y2: 531.004, x3: 757.482, y3: 303.003, x4: 779.48, y4: 250, },
{ Blk: '962', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV67', x: 783.36, y: 365, x1: 723.223, y1: 546.453, x2: 723.223, y2: 546.453, x3: 735.224, y3: 318.452, x4: 707.22, y4: 341.45, },
{ Blk: 'MS2', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV67', x: 720.07, y: 403.1, x1: 714.476, y1: 561.231, x2: 714.476, y2: 561.231, x3: 726.477, y3: 333.23, x4: 648.48, y4: 387.23, },
{ Blk: '973', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: 'LV67', x: 670, y: 319, x1: 735, y1: 447, x2: 735, y2: 447, x3: 746, y3: 221, x4: 690, y4: 202, },
]

export default Hull_LQ