<template>
  <div :id="localId"></div>
</template>

<script>
import * as d3 from 'd3'
import mx_Core from './includes/mixins/importer'            // Global & Local Mixins

export default {
  name: "j-chart-donut",
  mixins: [mx_Core],
  props: {
    id: String,
    modeExport: Boolean,

    FilterValues: {
      type: Object,
      default: () => ({
        MOD: 'NP1UH',
        MOD_DESC: 'NW NODE/PONTOON P.E',
      })
    },

    // Environmental properties for the column
    ColumnProps: { 
      type: Array, 
      default: () => ([]) 
    },

    Canvas: { type: Object, default: () => ({}) },
    Queries: { type: Object, default: () => ({}) },
    DataItems: { type: Array, default: () => ([]) },
  },


  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
  },
  mounted() {
    this.register(this.draw)
  },

  methods: {
    draw() {
      this.clear()
      this.drawCanvas()

      if (!this.ready2draw) {
        this.drawNoContents()
        return
      }

      this.setDefaultValues()

      this.drawChart()
      this.complete()
      this.postComplete()
    },

    drawChart() {
      this.drawContents()
    },
    postComplete() {
      let chart_ = this.svg.select('#outfitting_status___chart_area').node().getBoundingClientRect()
      this.resizableCanvasWidth = this.Canvas.CanvasWidth
      this.resizableCanvasHeight = chart_.height + 30
      this.resizeCanvas()

      let dimention = { 
        width: this.resizableCanvasWidth, 
        height: this.resizableCanvasHeight, 
      }
      
      this.$emit('resize', { dimention })
    }
  }
}
</script>