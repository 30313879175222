import * as d3 from 'd3'
import __M from 'moment'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({
    thead: [     
      { key: 'NO', html: 'No.', row: 1, rowspan: 1 , color: '#E0E0E0'},
      { key: 'AREA', html: 'Area', row: 1, rowspan: 1, color: '#E0E0E0' },
      { key: 'MILESTONES_DESCRIPTION', html: 'Milestones Description', row: 1, rowspan: 1 , color: '#E0E0E0'},
      { key: 'MS_OWNER', html: 'MS Owner', row: 1, rowspan: 1 , color: '#E0E0E0'},
      { key: 'BASELINE_DATE', html: 'Baseline', row: 1, rowspan: 1 , color: '#E0E0E0'},
      { key: 'FORECAST_DATE', html: 'Forecast', row: 1, rowspan: 1 , color: '#E0E0E0'},
      { key: 'CONSTRUCTION_REQUIRED_DATE', html: 'Required', row: 1, rowspan: 1 , color: '#E0E0E0'},
      { key: 'ACTUAL_FINISH_DATE', html: 'Actual', row: 1, rowspan: 1 , color: '#E0E0E0'},
      { key: 'STATUS', html: 'Status', row: 1, rowspan: 1 , color: '#E0E0E0'},
    ],
    theadrows: 1,
    tbody: [
      { key: 'NO', w: 15  },
      { key: 'AREA', w: 100 },
      { key: 'MILESTONES_DESCRIPTION', w: 140,align: 'left', background: '#FAFAFA'},
      { key: 'MS_OWNER', w: 70 },
      { key: 'BASELINE_DATE', w: 60 },
      { key: 'FORECAST_DATE', w: 60 },
      { key: 'CONSTRUCTION_REQUIRED_DATE', w: 60 },
      { key: 'ACTUAL_FINISH_DATE', w: 60 },
      { key: 'STATUS', w: 90 },
    ],
    note:[
      {text:'Note : ',color:'#424242',w:50},
      {text:'Completed',color:'#43A047',w:50},
      {text:'On/Ahead Plan',color:'#212121',w:60},
      {text:'Delay not Pass Plan',color:'#FF8F00',w:70},
      {text:'Pass Plan Date',color:'#E53935',w:80},
    ],
    width: 1020,
    selectedStartDate: null,
    selectedStartColumn: null,
    selectedFinishDate: null,
    selectedFinishColumn: null,
    filteredList: null,
    scrollProps: {
      w: 1000,
      h: 350,
      g: 0,
      bar: {
        align : 'right',
        gap   : 3,
        radius: 2,
        width : 7,
        fill  : '#BDBDBD',
        stroke: '#BDBDBD',
      }
    },
  }),
  methods: {
    drawTable() {
      let svg = this.svg
      this.filteredList = this.DataItems
      let list = this.filteredList
      let this_ = this
      
      // let getOptionList = (column) => {
      //   let optionList = JSON.parse(JSON.stringify(this.DataItems))

      //   let sorted = optionList.sort(function(a,b){
      //     // Turn your strings into dates, and then subtract them
      //     // to get a value that is either negative, positive, or zero.
      //     return new Date(a[column]) - new Date(b[column])
      //   }).filter(d => d[column] != null)
      //   return [...new Set(sorted.map(d => __M(new Date(d[column])).format('DD-MMM-YY')))]
      // }
      
      let group = svg.append('g').attr('class', 'ogm_table_group__')
      this.note.forEach((note,i) => {
        group
        .append('text')
        .attr('transform', `translate(${i*note.w+3}, ${25})`)
        .style('font-size',10).attr('text-anchor', 'start').attr('fill',note.color)
        .text(note.text)
      });
      
      
      
      let table_group = group.append('g').attr('transform', `translate(0.5, 30.5)`)
      let actionColumns = ['SUBSYSTEM']

      if(this.DataItems.length * 20==400){
        // Set Scroll
        this.setScroll(table_group, this.scrollProps)
      //let y_ = this.DataItems.length * 20 + 24
      }
      
      let table_obj = table_group.append('foreignObject')
        .attr('y',0)
        .attr('width', '100%')
        .attr('height', '424px')
        .append('xhtml:body')
        .style('position', 'fixed')

      let table = table_obj.append('table')
      .attr('id', 'commodity_summary_table_')
      .style('border-collapse', 'collapse')
      .style('font-size', '10px')
      .style('width', '1000px')
      .style('table-layout', 'fixed')

      let colgroup = table.append('colgroup')
      this.tbody.forEach(k => {
        colgroup.append('col').style('width', k.w + 'px')
      })

      let thead = table.append('thead')
      for (let r = 1; r <= this.theadrows; r++) {
        let row = this.thead.filter(d=> d.row === r)
        let tr = thead.append('tr')

        row.forEach(item => {
            tr.append('th')
            .attr('class', 'ogm_table_group__th')
            .attr('rowspan', () => item.rowspan ? item.rowspan : '')
            .attr('colspan', () => item.colspan ? item.colspan : '')
            .style('border', 'solid 0.7px #eee')
            .style('height', '22px')
            .style('padding', '3px')
            .style('background', item.color ? item.color : '#FAFAFA')
            .html(item.html)
        })        
      }
        
      let tbodykeys = this.tbody

      let disc = ''
      let tbody = table.append('tbody').attr('id', 'commodity_summay_tbody_')
      if (list.length == 0) {
        
        let tr = tbody.append('tr')

        tr.append('td')
          .attr('colspan', 5)
          .style('background', '#F5F5F5')
          .style('height', '24px')
          .style('text-align', 'center')
          .style('color', '#424242')
          .style('padding', '0 3px 0 3px')
          .style('font-style', 'italic')
          .html('No data')
        
        return
      }
      let addClass = ''

      for (let r = 0; r < list.length; r++) {
        let tr = tbody.append('tr')
        tbodykeys.forEach((k, i) => {

          if (list[r][k.key] == disc) return
          else {
            if (i == 0) {
              disc = list[r][k.key]
            }
          }

          let td = tr.append('td')
            .style('border', 'solid 0.1px #eee')
            .style('height', '20px')
            .style('text-align', k.align ? k.align : 'center')
            .style('padding', '0 3px 0 3px')
            .style('background', k.background ? k.background : 'none')
            // .attr('rowspan', () => {
            //   if (i == 0) {
            //     let filtered = list.filter(d => d.MONTH == list[r][k.key])
            //     return filtered.length
            //   }
            // })

          if(i === 2) {
            td.style('white-space', 'nowrap')
              .style('overflow', 'hidden')
              .style('text-overflow', 'ellipsis')
              .attr('title', list[r][k.key])
          }
          if(i === 8) {

            td.style('color', list[r][k.key] =='Completed'? '#43A047':list[r][k.key] =='On/Ahead Plan'?'#212121':list[r][k.key] =='Delay not Pass Plan'?'#FF8F00':'#E53935')
          }
          if(actionColumns.includes(k.key)) {
            td.style('text-decoration', 'underline').style('color','#039BE5')
            td.on('mouseover', () => td.style('cursor', 'pointer').style('opacity',0.5))
            td.on('mouseout', () => td.style('cursor', 'pointer').style('opacity',1))
            td.on('click', () => {
              // console.log(k.key, list[r])
              let request_ = this.getRequestColumnProps('SUBSYSTEM', { SUBSYSTEM: list[r].SUBSYSTEM }, this.ColumnProps)
              
              this.$emit('request-action', request_)
            })
          }
          if(k.key.includes('PROG')) {
            addClass = ''
            if (k.key.includes('ACTUAL_PROG')) { 
              let diff = list[r]['PLAN_PROG'] - list[r]['ACTUAL_PROG']
              // addClass = diff >= 30 ? 'caution' : ''
            }
            td
            .style('position', 'relative')
            .style('color', list[r][k.key] < 0 ? 'red' : list[r][k.key] === 100 ? '#fff' : addClass == 'caution' ? 'red' : '#000')
            .style('z-index', '1')
            .html(list[r][k.key] == null ? 0+'%' :list[r][k.key]+'%')

            td.append('div')
            .style('position', 'absolute')
            .style('left', 0)
            .style('top', 0)
            .style('width', list[r][k.key] > 0 ? list[r][k.key] + '%' : 0)
            .style('height', '20px')
            .style('background-color', list[r][k.key] < 100 ? k.key.includes('PLAN') ? '#E0E0E0' : '#FFCA28' : '#4CAF50')
            .style('z-index', '-1')
          }
        
          else {
            if (k.type == 'float') {
              let regexp = /\B(?=(\d{3})+(?!\d))/g
              if (isNaN(Math.floor(list[r][k.key]))) td.html('')
              else td.html((Math.floor(list[r][k.key] * 100) / 100).toString().replace(regexp, ','))
            } else td.html(list[r][k.key])
          }
        })
      }
    },
  }
}
