// ------------------------------------------------------------------------------- Global
// Global Data & Defs

import Axios from "axios"


export default {
  methods: {
    Draw_Charts() {

      let RFSUNO = this.FilterValues.RFSU

      console.log('FILTER RFSU No: ', RFSUNO)

      let image = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0.5)`)

      
      // image
      // .append('image')
      // .attr('opacity', 1)
      // .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Systemboundary/RFSU/${RFSUNO ? RFSUNO : '21-9340-9343'}.png`)
      // .attr('transform', `translate(0, 0) scale(.5)`)


      if (RFSUNO == '21-9340-9343') {
        image
        .append('image')
        .attr('opacity', 1)
        .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Systemboundary/RFSU/${RFSUNO ? RFSUNO : '21-9340-9343'}.png`)
        .attr('transform', `translate(0, 0) scale(.5)`)

      }else {
        
        image
        .append('text')
        .text('TBD')
        .attr('x', 350)
        .attr('y', 350)
        .attr('font-size', 100)
        .attr('font-weight', 'bold')
        .attr('fill', '#BDBDBD')
      }


      
    },
  }
}