import * as d3 from 'd3'
// ------------------------------------------------------------------------------- Global
// Global Data & Defs
export default {
  methods: {  
    Draw_Charts() {
      let data = null
      let style = null 
      let cellX = null 
      let tmp = null 
      let Circle = null
      let Guideline = null

      let overallMCStatus = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0)`)

      // WeekPerformance_01
      data = this.dataSet.filter(f=> f.TYPE == 'WEEK')
      style = {
                x: 80,
                y: 30,
      }
      data.forEach(d=> {
        d.date = d.DATE
        if(d.DISC =='LWK' || d.DISC =='TWK') d.name = 'Total'; else d.name = d.DISC
        d.value = d.ACTUAL
      })
      this.WeekPerformance_01(overallMCStatus, data, style) // ---------------------------> 

      // Chart_Pie2
      style = {
        id: this.filters.TITLE_A,
        x: 100,
        y: 150,
        name: this.filters.TITLE_A,
        sort: 'N',
      }
      tmp = this.dataSet.find(f=> f.TYPE == 'CERT' && f.DISC == this.filters.TITLE_A)
      data = [
        {title: this.filters.LIST_A1   ,  value: null        ,  qty: tmp.PLAN},
        {title: this.filters.LIST_A2   ,  value: tmp.PROG    ,  qty: tmp.ACTUAL},
        {title: this.filters.LIST_A3   ,  value: 100-tmp.PROG,  qty: tmp.REMAIN}
      ]
      Circle = {
        CircleDiameter: 50,
        CircleColorSet: ['Gray','YellowGreen', 'LightGray', 'LightBlue', ],
        CircleHoleSize: 32,
        CircleStackSize: 2,
        CircleSliceSize: 5,
        CircleTitleSize: 12,
        CircleLegendSpace: 65,
      }
      Guideline = {
        GuideInside: 5,
        GuideOutside: 5,
        GuideExtend: 0,
      }
      this.Chart_Pie2(overallMCStatus, data, style, Circle, Guideline) // ---------------------------> 


      // Chart_Pie3
      style = {
        id: this.filters.TITLE_B,
        x: 280,
        y: 150,
        name: this.filters.TITLE_B,
        sort: 'N',
      }
      tmp = this.dataSet.find(f=> f.TYPE == 'CERT' && f.DISC == this.filters.TITLE_B)
      data =[
        {title: this.filters.LIST_B1   , value: null        , qty: tmp.PLAN},
        {title: this.filters.LIST_B2   , value: tmp.PROG    , qty: tmp.APPROVED},
        {title: this.filters.LIST_B3   , value: null        , qty: tmp.APPROVED_PARTIALLY},
        {title: this.filters.LIST_B4   , value: 100-tmp.PROG, qty: tmp.REMAIN }
      ]
      Circle = {
        CircleDiameter: 53,
        CircleColorSet: ['Gray','LightBlue','YellowGreen', 'LightGray', ],
        CircleHoleSize: 32,
        CircleStackSize: 2,
        CircleSliceSize: 5,
        CircleTitleSize: 12,
        CircleLegendSpace: 65,
      }
      Guideline = {
        GuideInside: 5,
        GuideOutside: 5,
        GuideExtend: 0,
      }
      this.Chart_Pie3(overallMCStatus, data, style, Circle, Guideline) // ---------------------------> 

      // Header_01 - A
      data  = this.dataSet.find(f=> f.TYPE == 'CERT' && f.DISC == this.filters.TITLE_A).PROG
      style = {
        title: this.filters.TITLE_A,
        x: 30,
        y: 310,
        width: 40,
        height: 18,
        radius: 3,
        tSize: 11,
        tColor: '#333',
        bColor: '#E0E0DF',
        progSize: 15,
      }
      this.Header_01( overallMCStatus, data, style) // ---------------------------> 

      // SummaryTable_01 - A
      let item = this.dataSet.find(f=> f.TYPE == 'CERT' && f.DISC == this.filters.TITLE_A)

      style = {
        title:    [this.filters.TITLE_A1, this.filters.TITLE_A2, this.filters.TITLE_A3],
        cell:     [30, 85, 130],
        x:        30,
        y:        330,
        y1:       10,
        y2:       23,
        width:    140,
        height:   32,
        radius:   3,
        opacity:  0.7,
        bColor:   '#83D2F5',
        tSize:    10,
        tColor:   '#757575',
        vSize:    11,
        vColor:   '#fff',
        value:    [item.TOTAL, item.ACTUAL, item.REMAIN],
        sqlfilter:  {...this.filters},

      }
      this.SummaryTable_01(overallMCStatus, style) // ---------------------------> 

      // Header_01 - B
      data  = this.dataSet.find(f=> f.TYPE == 'CERT' && f.DISC == this.filters.TITLE_B).PROG
      style = {
        title: this.filters.TITLE_B,
        x: 220,
        y: 310,
        width: 40,
        height: 18,
        radius: 3,
        tSize: 11,
        tColor: '#333',
        bColor: '#E0E0DF',
        progSize: 15,
      }
      this.Header_01( overallMCStatus, data, style) // ---------------------------> 

      // SummaryTable_01 - B
      item = this.dataSet.find(f=> f.TYPE == 'CERT' && f.DISC == this.filters.TITLE_B)
      let itemData = item.APPROVED + item.APPROVED_PARTIALLY

      style = {
        title:    [this.filters.TITLE_B1, this.filters.TITLE_B2, this.filters.TITLE_B3],
        cell:     [30, 85, 130],
        x:        220,
        y:        330,
        y1:       10,
        y2:       23,
        width:    140,
        height:   32,
        radius:   3,
        opacity:  0.7,
        bColor:   '#83D2F5',
        tSize:    10,
        tColor:   '#757575',
        vSize:    11,
        vColor:   '#fff',
        value:    [item.TOTAL, itemData, item.REMAIN],
        sqlfilter:  {...this.filters},
      }
      this.SummaryTable_01(overallMCStatus, style) // ---------------------------> 
    },
  }
}