import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  data: () => ({
    style : {
      x: 10,
      y: 20,
      stX: 30,
      opacity:  1,
    },
    gab: 20,
  }),
  methods: {

    Draw_Charts() {
      if(!this.DataItems || this.DataItems.length === 0) return

      let values_ = this.DataItems
      let overallPunchStatus = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0)`).style('font-family', 'roboto')

      let maxValue = Math.max(...this.DataItems.map(d => d.VALUE))
      maxValue = this.getMaxBound(maxValue)
      
      let canvasW = this.Canvas.CanvasWidth 
      let barCount = this.DataItems.length
      let barSpace = canvasW/barCount-10
      let barWidth = (canvasW/barCount)/3
      let barLen = this.Canvas.CanvasHeight-50


      //Scale for the radius
      let barScale = d3.scaleLinear()
      .domain([0, maxValue])
      .range([barLen, 0])

      let overallPunch = this.svg
      .append('g')
      .attr('transform', `translate(${-5}, ${this.style.y})`)
      
      for(var i=0; i<6; i++) {
        overallPunch
        .append('line')
        .attr('x1', this.style.stX - 5).attr('x2', canvasW)
        .attr('y1', barScale((maxValue/5)*i)).attr('y2', barScale((maxValue/5)*i))
        .attr('stroke', '#9E9E9E')
        .attr('stroke-width', 0.2)

        overallPunch
        .append('text')
        .attr('transform', `translate(${this.style.stX - 2}, ${barScale((maxValue/5)*i)})`)
        .style('font-size', 11).style('fill', '#9E9E9E').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text((maxValue/5)*i)
      }
      overallPunch
      .append('line')
      .attr('x1', this.style.stX - 5).attr('y1', barLen).attr('x2', canvasW).attr('y2', barLen)
      .attr('stroke', '#757575')
      .attr('stroke-width', 0.3)
      // overallPunch
      // .append('text')
      // .attr('class', 'yaxis_right_text')
      // .style('font-size', '11px')
      // .attr('transform', `translate(${5},${this.Canvas.CanvasHeight/2-30}),rotate(-90)`)
      // .style('text-anchor', 'middle')
      // .style('fill','#9E9E9E')
      // .text('Document Count')
      
      values_.forEach((attr, i) => {
        // console.log(attr)
        let x = this.style.stX
        let x__ = barSpace * i + this.gab
        let donut_ = overallPunchStatus
        .append('g')
        .attr('class', `_donut_chart__${attr.COLUMN.toLowerCase()}`)
        .attr('transform', `translate(${x__+5}, 20)`)

        donut_
        .append('path')
        .attr('d', `M ${x}, ${barLen}
                    V ${barScale((attr.VALUE))}
                    H ${x + barWidth}
                    V ${barLen} Z
                  `
        ).style('stroke-width', 0.5).style('opacity',1)
        .style('fill','#B2BECE')
        .style('stroke','#96A3B4')
        // .style('fill', i == 0 ? '#62C8D3': i == 1 ? '#637C8F':i == 2 ? '#CEACD1':i == 3 ? '#B2BECE':'#BCD0CC')
        // .style('stroke', i == 0 ? '#5BACB2': i == 1 ? '#576E7D':i == 2 ? '#AF91AD':i == 3 ? '#96A3B4':'#9BADA8')


        donut_
        .append('text')
        .attr('transform', `translate(${x + (barWidth/2)}, ${barScale((attr.VALUE)) - 6})`)
        .style('font-size', 11).style('fill', '#96A3B4').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text(`${(attr.VALUE)}`)

        // donut_
        // .append('rect')
        // .attr('transform', `translate(${x - (barWidth/2 )}, ${barLen})`)
        // .attr('width', this.width).attr('height', 25).style('fill', attr.fColor)

        donut_
        .append('text')
        .attr('transform', `translate(${x + (barWidth/2)}, ${barLen+15})`)
        .style('font-size', 11)
        .style('fill', '#212121')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(attr.COLUMN)
      })
      
    },
  }
}
