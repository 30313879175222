import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  data: () => ({
    legend: 
    [
      { column: 'PLAN_WK', color: '#E0E0E0', shape: 'rect', text: 'Weekly Plan', axis: 'left', x: 0, y: 0 },
      { column: 'ACTUAL_WK', color: '#81C784', shape: 'rect', text: 'Weekly Actual ', axis: 'left', x: 130, y: 0 },
      { column: 'PLAN_CUM', color: '#757575', shape: 'line', text: 'Cum. Plan', axis: 'right', lineShape: 'dash', x: 260, y: 0 },
      { column: 'ACTUAL_CUM', color: '#2E7D32', shape: 'line', text: 'Cum. Actual', axis: 'right',  x: 380, y: 0},
      // { column: 'WKS4_TREND', color: '#039BE5', shape: 'line', text: '4Weeks Trend', axis: 'right',lineShape: 'dash', x: 440, y: 0 }
    ],
    margin: { top: 80, left: 50, bottom: 50, right: 50 },
    width: 1500,
    height: 380,
    filter: []
  }),
  methods: {
    Draw_Charts() {
      // console.log(this.DataItems[0])
      //원래는 this.DataItems[0] === 0 이면 return 하면되는데 이상한 데이터가 들어와서 이렇게 작업함 
      // if (!this.DataItems[0].WK_NO && this.DataItems[0] == undefined) { 
      if (this.DataItems[0] == undefined) { 
        //console.log(this.Text.Text[0].text)
        let svg = this.svg
        svg.append('rect')
        .attr('x', 0).attr('y', 0).attr('width', 570).attr('height', 360).attr('fill', '#fff')
        svg.append('text').attr('x', 570/2).attr('y', 360/2).attr('text-anchor', 'middle')
        .text('N/A').attr('font-size', '30px')
        .attr('fill', '#ccc')
        return
      }

      const list = this.DataItems
        .map(v => {
          return {
            CATEGORY: v['CUTOFF'],
            VALUES: [
              { KEY: 'PLAN_WK', VALUE: v['PLAN_WK'] },
              { KEY: 'ACTUAL_WK', VALUE: v['ACTUAL_WK'] },
              { KEY: 'PLAN_CUM', VALUE: v['PLAN_CUM'] },
              { KEY: 'ACTUAL_CUM', VALUE: v['ACTUAL_CUM'] },
              // { KEY: 'WKS4_TREND', VALUE: v['WKS4_TREND'] }
            ]
          }
        })
      
      this.filter = this.legend.map(d => d.column)
      let name = [...new Set(this.DataItems.map(d => d.NAME))]

      const width = this.width - 30
      const height = this.height - 15

      const svg = this.svg
        .append('svg')
        .attr('width', `${width}`)
        .attr('height', `${height}`)

      svg
        .append('g')
        .attr('id','chart_progress')
        .attr('transform', `translate(${this.margin.left},${this.margin.top})`)

      let legendGoup = svg
        .append('g')
        .attr('id','chart_legend')
        .attr('transform', `translate(${width/2.7},30)`)

      const legendName = this.legend
      const legends = legendGoup.selectAll('.legend').data(legendName)

      const filter = this.filter
      const render = this.render

      const legend = legends
        .enter()
        .append('g')
        .attr('class', 'legend')
        .attr('transform', (d, i) => {
          return `translate(${d.x}, ${d.y})`
        })
        .style('user-select', 'none')
        .on('click', function (d) {
          const el = d3.select(this)
          const text = el.select('text')
          const rect = el.select('rect')
          const column = d.column
          if (!filter.includes(column)) {
            text.attr('fill', '#000')
            rect.style('fill', d.color)
            filter.push(column)
          } else {
            text.attr('fill', '#efefef')
            rect.style('fill', '#efefef')
            const idx = filter.indexOf(column)
            filter.splice(idx, 1)
          }

          const newValue = list.map(d => {
            const obj = {
              CATEGORY: d['CATEGORY'],
              VALUES: d['VALUES'].filter(v => filter.includes(v['KEY']))
            }
            return obj
          })
          render(newValue)
        })

      legend.append('rect')
        .attr('x', 0)
        .attr('width', 14)
        .attr('height', d => d.shape === 'line' ? 2 : 7)
        .attr('y', d => d.shape === 'line' ? 4 : 4)
        .style('fill', d => d.color)

      legend.append('text')
        .attr('x', 16)
        .attr('y', 6)
        .attr('dy', '.35em')
        .attr('text-anchor', 'start')
        .attr('font-size', '10px')
        .attr('font-family', 'arial')
        .style('cursor', 'pointer')
        .text(d => name + ' '+d.text)

        render(list)
        // console.log(list)
    },
    
    render (dataset) {
      const { top, left, bottom, right } = this.margin
      const width = this.width - left - right
      const height = this.height - top - bottom

      const g = d3.select(this.$el).select('svg g')

      const y0 = d3.scaleLinear().range([ height, 0 ])
      const y1 = d3.scaleLinear().range([ height, 0 ])

      const x0 = d3.scaleBand().range([ 0, width ])
      const x1 = d3.scaleBand().range([ 0, width ])
      //left Axis
      const leftColumns = this.legend.filter(d => d.axis === 'left').map(d => d['column'])
      const leftArr = dataset.map(d => d['VALUES']).flat().filter(v => v['VALUE'] && leftColumns.includes(v['KEY'])).map(v => v['VALUE'])
      const leftValues = Math.max.apply(null, leftArr)
      // console.log( leftArr )
      // console.log( leftValues )
      //right Axis
      const rightColumns = this.legend.filter(d => d.axis === 'right').map(d => d['column'])
      const rightArr = dataset.map(d => d['VALUES']).flat().filter(v => v['VALUE'] && rightColumns.includes(v['KEY'])).map(v => v['VALUE'])
      const rightValues = Math.max.apply(null, rightArr)

      const max = {
        left: leftArr.length === 0 ? 0 : leftValues,
        right: rightArr.length === 0 ? 0 : rightValues
      }

      max.left = max.left === 0 ? 100 : max.left * 1.5
      max.right = max.right === 0 ? 100 : max.right

      const axisLeftRange = d3.range(6).map(d => { const v = max.left / 5 * d; return String(v).indexOf('.') !== -1 ? v : Math.ceil(v) })
      const axisRightRange = d3.range(6).map(d => { const v = max.right / 5 * d; return String(v).indexOf('.') !== -1 ? v : Math.ceil(v) })

      const categoryNames = dataset.map(d => d.CATEGORY)

      // const duration = 0
      const bars = this.legend.filter(d => d.shape === 'rect').map(d => d['column'])

      y0.domain([ 0, max.left ])
      y1.domain([ 0, max.right ])

      x0.domain(categoryNames)
      x1.domain(dataset[0].VALUES.filter(d => bars.includes(d.KEY)).map(d => d.KEY))
        .rangeRound([ 0, x0.bandwidth() ]).padding(0.15)

      const xAxis = d3.axisBottom(x0) .tickFormat(d => d).tickValues(x0.domain().filter((d,i) => { return !(i%1)}))
      //xAxis의 보이는 값을 다보여줄것인지 작게 보여줄것인지

      const yAxis = {
        left: d3.axisLeft(y0).tickValues(axisLeftRange),
        right: d3.axisRight(y1).tickValues(axisRightRange)
      }

      g.selectAll('g').remove()

      g.append('g')
        .attr('class', 'grid')
        .call(d3.axisLeft(y0).tickValues(axisLeftRange).tickSize(-width).tickFormat(''))
        .selectAll('.tick line')
        .attr('stroke', (d, k) => k > 0 ? '#efefef' : '#ccc')
        .attr('stroke-width', 1)
        .attr('shape-rendering', 'crispEdges')

      g.append('g')
        .style('font-size', '11px')
        .attr('class', 'x axis')
        .attr('transform', `translate(0,${height + 10})`)
        .call(xAxis)
        .selectAll('text')
        .attr('y', 0)
        .attr('x', -55)
        .attr('dy', '.35em')
        .attr('transform', 'rotate(-90)')
        .style('text-anchor', 'start')
        .style('fill','#424242')
        
      g.append('text')
        .attr('class', 'yaxis_left_text')
        .style('font-size', '11px')
        .attr('transform', `translate(-35,${height/2}),rotate(-90)`)
        .style('text-anchor', 'middle')
        .style('fill','#9E9E9E')
        .text("Weekly")

        g.append('text')
        .attr('class', 'yaxis_right_text')
        .style('font-size', '11px')
        .attr('transform', `translate(${width+42},${height/2}),rotate(-90)`)
        .style('text-anchor', 'middle')
        .style('fill','#9E9E9E')
        .text('Cumulative')

      g.append('g')
        .attr('class', 'y axis axis-left')
        .style('font-size', '11px')
        .call(yAxis.left)

      g.append('g')
        .attr('class', 'y axis axis-right')
        .style('font-size', '11px')
        .attr('transform', `translate(${width},0)`)
        .call(yAxis.right)


      g.selectAll('.domain').remove()
      g.selectAll('.y .tick').attr('color', '#878787')
      g.selectAll('.y .tick line').remove()
      g.selectAll('.x .tick line').remove()

      const barGroups = g.selectAll('.bar')
        .data(dataset)
        .enter()
        .append('g')
        .attr('class', 'bar')
        .attr('transform', d => `translate(${x0(d.CATEGORY)},0)`)

      barGroups.selectAll('rect')
        .data(d => d.VALUES.filter(v => bars.includes(v.KEY)))
        .enter()
        .append('rect')
        .attr('width', x1.bandwidth() < 1 ? 1 : x1.bandwidth())
        .attr('x', d => x1(d.KEY))
        .attr('y', d => y0(d.VALUE))
        .attr('height', d => height - y0(d.VALUE) < 0 ? 0 : isNaN(height - y0(d.VALUE)) ? 0 : height - y0(d.VALUE))
        .attr('fill', d => {
          const color = this.legend.find(v => v['column'] === d['KEY']).color
          return color
        })
        .style('opacity', 1)

      barGroups.selectAll('text')
        .data(d => d.VALUES.filter(v => bars.includes(v.KEY)))
        .enter()
        .append('text')
        .attr('x', d => x1(d.KEY))
        .attr('dx', x1.bandwidth() / 2)
        .attr('y', d => y0(d.VALUE))
        .attr('dy', '-.5em')
        .attr('fill', d => d3.rgb(this.legend.find(v => v['column'] === d.KEY).color).darker(1))
        .attr('text-anchor', 'middle')
        .attr('font-size', '11px')
        .text(d => d.VALUE > 0 ? d.VALUE : null)
        .style('opacity', 1)

      barGroups.selectAll('rect')
        .transition()
        .style('opacity', 1)

      barGroups.selectAll('text')
        .transition()
        .style('opacity', 1)

      const lines = this.legend.filter(d => d.shape === 'line').map(d => d['column']).filter(d => this.filter.includes(d))
      const lineLength = d3.line()
        .x(d => x0(d.KEY))
        .y(d => y1(d.VALUE))
        .defined(d => { // return d.VALUE && d.VALUE >= 0 기존것 있는것만 나오기
          return d.VALUE >= 0 //0있는것은 0부터 시작하게
        })
      const linedata = []
      lines.forEach(c => {
        const obj = {}
        obj.KEY = c
        obj.COLOR = this.legend.find(v => v['column'] === c).color
        obj.VALUES = dataset
          .map(d => {
            d.VALUES.find(v => {
              return v.KEY === c
            })
            
            return {
              KEY: d.CATEGORY,
              VALUE: d.VALUES.find(v => v.KEY === c).VALUE
            }
          })
        obj.LINESHAPE = this.legend.find(v => v['column'] === c).lineShape
        linedata.push(obj)
      })

      const lineGroups = g
        .append('g')
        .attr('class', 'line')
        .attr('transform', `translate(${x0.bandwidth() / 2},0)`)

      linedata.forEach(v => {
        const line = lineGroups
        .append('path')
        .datum(v)
        .attr('class', d => `line-${d.KEY}`)
        .attr('fill', 'none')
        .attr('stroke', d => d.COLOR)
        .attr('stroke-width', 1)
        .attr('shape-rendering', 'geometricPrecision')
        .attr('d', d => lineLength(d.VALUES))
        v.LENGTH = line.node().getTotalLength()
      })

      //line cercle
      // const circles = lineGroups
      //   .selectAll('g')
      //   .data(linedata)
      //   .enter()
      //   .append('g')
      //   .attr('data-key', d => d.KEY)

      // circles
      //   .selectAll('circle')
      //   .data(d => d.VALUES)
      //   .enter()
      //   .append('circle')
      //   .attr('class', d => !d.VALUE ? 'not-value' : '')
      //   .attr('cx', d => x0(d.KEY))
      //   .attr('cy', d => y1(d.VALUE))
      //   .attr('r', function (d) {
      //     const wrapper = d3.select(this).node().parentNode
      //     const legend = linedata.find(v => v.KEY === wrapper.dataset.key)
      //     if (legend) return legend.LINESHAPE && legend.LINESHAPE === 'dash' ? 0 : '.15em'
      //     else return '.15em'
      //   })
      //   .attr('fill', '#fff')
      //   .attr('stroke-width', '.1em')
      //   .attr('stroke', function (d) {
      //     const wrapper = d3.select(this).node().parentNode
      //     return linedata.find(v => v.KEY === wrapper.dataset.key).COLOR
      //   })
      //   .attr('shape-rendering', 'geometricPrecision')
      //   .style('display', d => !d.VALUE ? 'none' : '')

      // circles
      //   .selectAll('text')
      //   .data(d => d.VALUES)
      //   .enter()
      //   .append('text')
      //   .attr('x', d => x0(d.KEY))
      //   .attr('y', d => y1(d.VALUE))
      //   .attr('dy', '-1em')
      //   .attr('text-anchor', 'middle')
      //   .attr('font-size', '10px')
      //   .attr('fill', function (d) {
      //     const wrapper = d3.select(this).node().parentNode
      //     return d3.rgb(linedata.find(v => v.KEY === wrapper.dataset.key).COLOR).darker(1)
      //   })
      //   .text(d => d.VALUE && d.VALUE > 0 ? d.VALUE : null)

      g.selectAll('.line path')
        .attr('stroke-dasharray', d => '')
        .attr('stroke-dashoffset', d => d.LENGTH)
        .transition()
        .attr('stroke-dashoffset', 0)
        .attr('stroke-dasharray', d => {
          const legend = this.legend.find(v => v['column'] === d['KEY'])
          if (legend) return legend.lineShape && legend.lineShape === 'dash' ? '4,3' : d.LENGTH + ' ' + d.LENGTH
          else return ''
        })
        .attr('d', d => lineLength(d.VALUES))

      g.selectAll('.line circle')
        .attr('opacity', 0)
        .transition()
        .attr('opacity', 1)
      g.selectAll('.line text')
        .attr('opacity', 0)
        .transition()
        .attr('opacity', 0)
    }
  }
}
