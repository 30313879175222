export default {
  width: 120,
  gab: 10,
  attrs: [
    { 
      text1: 'Total Subsystems',
      text2: 'with Test Pack',
      refColumn: 'TOTAL',
      codeValue   : 'Total', // fixed
      fill: 'lightGray',
      fColor: 'Transparent', 
      tColor: '#212121',
      on: true
    },
    { 
      text1: 'Subsystems with',
      text2: 'No Test Pack Started',
      refColumn: 'NOTSTARTED',
      codeValue   : 'Not Started',
      fill: 'fillWhite',
      fColor: '#BDBCBC', 
      tColor: '#818181',
      on: true
    },
    { 
      text1: 'Subsystems with',
      text2: 'Test Pack Started',
      refColumn: 'INPROGRESS',
      codeValue   : 'In Progress',
      fill: 'fillYellow',
      fColor: '#FFD600',
      tColor: '#fff',
      on: true
    },
    { 
      text1: 'Subsystems with',
      text2: 'Test Pack Complete',
      refColumn: 'COMPLETED',
      codeValue   : 'Completed',
      fill: 'fillGreen',
      fColor: '#66BB6A', 
      tColor: '#fff',
      on: true
    }
  ]
}
