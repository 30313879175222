import * as d3 from 'd3'

export default {
  methods: {

    draw_Forms() {

      this.stage_Header = this.svg
      .append('svg')

      this.stage_Header
      .append('rect')
      .attr('x', -1).attr('y', -1).attr('width', 1600).attr('height', 60).attr('fill', '#F4F5F5')

      this.stage_Header
      .append('text')
      .attr('x', 10).attr('y', 25).attr('fill', '#EC407A').attr('font-size', 20)
      .text('Livening Up Notices')

      this.stage_Header
      .append('line')
      .attr('x1', 0).attr('y1', 60).attr('x2', 1601).attr('y2', 60).attr('stroke', '#757575').attr('stroke-width', .2)
      

      let Legends = this.stage_Header
      .append('g')
      .attr('id', 'Legends')
      .attr('transform', `translate(12.5,43)`)

      this.Legends.forEach((d,i)=> {
        Legends
        .append('rect')
        .attr('id', `checkBox_${d.code}`)
        .attr('x', d.x).attr('y', 0).attr('rx', 3).attr('ry', 3)
        .style('width', 12).style('height', 12)
        .style('stroke', 'gray').style('stroke-width', 0.5)
        .style('fill', d.color)
      
        Legends
        .append('path')
        .attr('id', `checked_${d.code}`)
        .attr('transform', `translate(${d.x-2.5}, ${-2.5})scale(.5)`)
        .attr('d', 'M14,17.9h0L10.1,14,8,16.1l6,6,10-10L21.9,10Z')
        .attr('fill', '#E0E0DF')
        .attr('opacity', d.checked == true ? 1 : 1)

        Legends
        .append('text')
        .attr('id', `Text_${d.code}`)
        .attr('transform', `translate(${d.x + 17}, ${7})`)
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#333')
        .text(d.title)

        let size = d3.select(`#Text_${d.code}`).node().getBBox().width

        Legends
        .append('rect')
        .attr('id', `Button_${d.code}`)
        .attr('transform', `translate(${d.x}, ${-1})`)
        .style('width', size + 20).style('height', 14).attr('fill', d.color)
        .attr('opacity', 0).style('cursor', 'pointer')
        .call(this.call_Options, d) // ---> Event.js
      })


      // ---------------------------------------
      // Search Controller ---> Global Function
      // ---------------------------------------
      let SearchBox = this.stage_Header
      let data__ = {
        x         : 350,
        y         : 33,
        width     : 70,
        height    : 65.5,
        label     : 'Search',
        labelWidth: 40,
        targets: [
          { text: 'Enter the KMS/LMS number.' },
        ],
        callFunc: this.call_Search, // --->  in Event.js
      }
      this.drawSearchToolFree_Simple(SearchBox, data__) //---> Global function


      this.innerSvg = this.svg
      this.loadSvg(
        this.innerSvg, {
        x: 0,
        y: 60,
        url: '/China1/LiveUpdateNotice/TOP_4000_r2_1.svg'
      }).then((svg) => {

        d3.select('#CWA-TEXT').selectAll('text')
        .style('font-family', 'roboto')
        .attr('font-weight', 600)

        let zoom_ = d3.zoom()
        .translateExtent([[0, 0], [1200, 1120]])
        .scaleExtent([1,  4])
        .extent([[0, 0], [1200, 1120]])
        .on('zoom', this.zoom)

        this.innerSvg = svg
        this.innerSvg.call(zoom_)

        this.stage_Header.moveToFront()
        


        this.rawData.forEach(d => {
          
          if(this.rawData.find(f=> d.CWA == d.CWA)) {

            d3.select(`#CWA-${d.CWA}`)
            .style('fill', this.Legends.find(f => f.code == d.STATUS).color)
            .style('cursor', 'pointer')
            .call(this.call_CWA, d)


            d3.select(`#CWA-${d.CWA}`).selectAll('text')
            .style('font-family', 'roboto')
            .style('fill', '#000').style('font-weight', 600)

            if(d.CWA !== '150200' && d.CWA !== '150300' && d.CWA !=='150400' ) {
            d3.select(`#TEXT-${d.CWA}`)
            .append('tspan')
            .attr('x', 0.5)
            .attr('y', 2.4)
            .attr('font-size', '2.8px')
            .style('font-family', 'roboto')
            .style('fill', 'red').style('font-weight', 600)
            .text(d.FORE_DATE)
            } else {
            d3.select(`#TEXT-${d.CWA}`)
            .append('tspan')
            .attr('x', 9)
            .attr('y', 6)
            .attr('font-size', '5.5px')
            .style('font-family', 'roboto')
            .style('fill', 'red').style('font-weight', 600)
            .text(d.FORE_DATE)
            }

            d3.select(`#CWA-${d.CWA}`).selectAll('rect')
            .style('fill', this.Legends.find(f => f.code == d.STATUS).color)
            .style('opacity', .5)
            d3.select(`#CWA-${d.CWA}`).selectAll('path')
            .style('fill', this.Legends.find(f => f.code == d.STATUS).color)
            .style('opacity', .5)
            d3.select(`#CWA-${d.CWA}`).selectAll('polygon')
            .style('fill', this.Legends.find(f => f.code == d.STATUS).color)
            .style('opacity', .5)


          }


        })

        // Call functions
        // this.draw_Diagram()
        // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        // PAGESVG 에서는 drawNotes,drawTexts 는 loadsvg 가 제일 마지막에 실행이되므로
        // this.loadSvg().then 에서 처리해야 제일 상단에 그려진다.
        // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        this.drawNotes()
        this.drawTexts() 
      })

    },

  }
}
