const Topsides = [
{ BLK: 'ME01DA',    Lv1: 'TOPSIDES', Lv2: 'MODULES', Lv3: 'ME01', x1: 378,    y1: 809,    x2: 697,    y2: 543,    x3: 744.6,  y3: 672.37, x4: null,   y4: null,   x5: 502, y5: 448, layout: 'Y', },
{ BLK: 'ME01DB',    Lv1: 'TOPSIDES', Lv2: 'MODULES', Lv3: 'ME01', x1: 398.02, y1: 767.73, x2: 717.02, y2: 501.73, x3: 661.62, y3: 538.35, x4: null,   y4: null,   x5: 531, y5: 370, layout: 'Y', },
{ BLK: 'ME01DC',    Lv1: 'TOPSIDES', Lv2: 'MODULES', Lv3: 'ME01', x1: 398.37, y1: 724,    x2: 717.37, y2: 458,    x3: 560.97, y3: 404.62, x4: null,   y4: null,   x5: 569, y5: 410, layout: 'Y', },
{ BLK: 'ME02DA',    Lv1: 'TOPSIDES', Lv2: 'MODULES', Lv3: 'ME02', x1: 661.88, y1: 618,    x2: 726.88, y2: 456,    x3: 776.44, y3: 623.52, x4: null,   y4: null,   x5: 501, y5: 220, layout: 'Y', },
{ BLK: 'ME02DB',    Lv1: 'TOPSIDES', Lv2: 'MODULES', Lv3: 'ME02', x1: 653,    y1: 660.87, x2: 718,    y2: 498.87, x3: 653.56, y3: 575.39, x4: null,   y4: null,   x5: 586, y5: 340, layout: 'Y', },
{ BLK: 'ME02DC',    Lv1: 'TOPSIDES', Lv2: 'MODULES', Lv3: 'ME02', x1: 654.27, y1: 626,    x2: 719.27, y2: 464.53, x3: 546.83, y3: 444.05, x4: null,   y4: null,   x5: 579, y5: 360, layout: 'Y', },
{ BLK: 'ME04DA',    Lv1: 'TOPSIDES', Lv2: 'MODULES', Lv3: 'ME04', x1: 944,    y1: 584.94, x2: 726.26, y2: 563.9,  x3: 737.26, y3: 647.9,  x4: null,   y4: null,   x5: 548, y5: 365, layout: 'Y', },
{ BLK: 'ME04DB',    Lv1: 'TOPSIDES', Lv2: 'MODULES', Lv3: 'ME04', x1: 965,    y1: 443,    x2: 747.26, y2: 421.95, x3: 632.06, y3: 370.95, x4: null,   y4: null,   x5: 667, y5: 164, layout: 'Y', },
{ BLK: 'ME05DA',    Lv1: 'TOPSIDES', Lv2: 'MODULES', Lv3: 'ME05', x1: 1164,   y1: 535,    x2: 819,    y2: 549.59, x3: 899,    y3: 602.59, x4: 899,    y4: 647.59, x5: 532, y5: 451, layout: 'Y', },
{ BLK: 'ME05DA_DK', Lv1: 'TOPSIDES', Lv2: 'MODULES', Lv3: 'ME05', x1: 1174.46,y1: 511.11, x2: 829.46, y2: 525.11, x3: 909.46, y3: 574.11, x4: 909.46, y4: 463.11, x5: null,y5: null, layout: null, },
{ BLK: 'ME05DB',    Lv1: 'TOPSIDES', Lv2: 'MODULES', Lv3: 'ME05', x1: 1187  , y1: 498.28, x2: 842.8,  y2: 512.28, x3: 923.8,  y3: 559.28, x4: 923.8,  y4: 327.28, x5: 636, y5: 483, layout: 'Y', },
{ BLK: 'ME05DB_DK', Lv1: 'TOPSIDES', Lv2: 'MODULES', Lv3: 'ME05', x1: 1174.88,y1: 484.17, x2: 829.88, y2: 498.17, x3: 909.88, y3: 543.17, x4: 909.88, y4: 177.17, x5: null,y5: null, layout: null, },
{ BLK: 'ME05DC',    Lv1: 'TOPSIDES', Lv2: 'MODULES', Lv3: 'ME05', x1: 1174.5, y1: 473.92, x2: 829.5,  y2: 487.92, x3: 644.5,  y3: 447.92, x4: 644.5,  y4: 622.92, x5: 565, y5: 390, layout: 'Y', },
{ BLK: 'ME05DD',    Lv1: 'TOPSIDES', Lv2: 'MODULES', Lv3: 'ME05', x1: 1174.33,y1: 457.67, x2: 829.33, y2: 471.67, x3: 644.33, y3: 428.67, x4: 646.33, y4: 450.67, x5: 560, y5: 390, layout: 'Y', },
{ BLK: 'ME05DE',    Lv1: 'TOPSIDES', Lv2: 'MODULES', Lv3: 'ME05', x1: 1175.14,y1: 439,    x2: 830.14, y2: 453,    x3: 645.14, y3: 406, x4: 645.14,    y4: 260,    x5: 637, y5: 390, layout: 'Y', },
{ BLK: 'MW01DA',    Lv1: 'TOPSIDES', Lv2: 'MODULES', Lv3: 'MW01', x1: 166,    y1: 640,    x2: 732,    y2: 551.89, x3: 763,    y3: 720.99, x4: null,   y4: null,   x5: 503, y5: 400, layout: 'Y', },
{ BLK: 'MW01DB',    Lv1: 'TOPSIDES', Lv2: 'MODULES', Lv3: 'MW01', x1: 183.95, y1: 590.66, x2: 749.95, y2: 501.66, x3: 694.95, y3: 582.76, x4: null,   y4: null,   x5: 530, y5: 360, layout: 'Y', },
{ BLK: 'MW01DC',    Lv1: 'TOPSIDES', Lv2: 'MODULES', Lv3: 'MW01', x1: 192.07, y1: 562.1,  x2: 758.07, y2: 473.1,  x3: 616.07, y3: 468.2, x4: null,    y4: null,   x5: 550, y5: 360, layout: 'Y', },
{ BLK: 'MW01DD',    Lv1: 'TOPSIDES', Lv2: 'MODULES', Lv3: 'MW01', x1: 207.41, y1: 541,    x2: 776.41, y2: 457,    x3: 547.41, y3: 343.1, x4: null,    y4: null,   x5: 610, y5: 360, layout: 'Y', },
{ BLK: 'MW02DA',    Lv1: 'TOPSIDES', Lv2: 'MODULES', Lv3: 'MW02', x1: 453,    y1: 506,    x2: 750,    y2: 528.25, x3: 797,    y3: 691.25, x4: null,   y4: null,   x5: 530, y5: 360, layout: 'Y', },
{ BLK: 'MW02DB',    Lv1: 'TOPSIDES', Lv2: 'MODULES', Lv3: 'MW02', x1: 457.79, y1: 493,    x2: 754.79, y2: 515,    x3: 665.79, y3: 571, x4: null,      y4: null,   x5: 550, y5: 360, layout: 'Y', },
{ BLK: 'MW02DC',    Lv1: 'TOPSIDES', Lv2: 'MODULES', Lv3: 'MW02', x1: 457.79, y1: 460,    x2: 754.79, y2: 482.08, x3: 578.79, y3: 453.08, x4: null,   y4: null,   x5: 530, y5: 360, layout: 'Y', },
{ BLK: 'MW02DD',    Lv1: 'TOPSIDES', Lv2: 'MODULES', Lv3: 'MW02', x1: 477.32, y1: 433,    x2: 774.32, y2: 455,    x3: 510.32, y3: 352, x4: null,      y4: null,   x5: 585, y5: 360, layout: 'Y', },
{ BLK: 'MW04DA',    Lv1: 'TOPSIDES', Lv2: 'MODULES', Lv3: 'MW04', x1: 732,    y1: 406.56, x2: 749,    y2: 552.56, x3: 712.72, y3: 607.5, x4: null,    y4: null,   x5: 583, y5: 389, layout: 'Y', },
{ BLK: 'MW04DB',    Lv1: 'TOPSIDES', Lv2: 'MODULES', Lv3: 'MW04', x1: 742.5,  y1: 270,    x2: 759.5,  y2: 416,    x3: 571.22, y3: 333.93, x4: null,   y4: null,   x5: 607, y5: 170, layout: 'Y', },
{ BLK: 'MW05DA',    Lv1: 'TOPSIDES', Lv2: 'MODULES', Lv3: 'MW05', x1: 956.52, y1: 353,    x2: 708.52, y2: 548.75, x3: 743.33, y3: 675.75, x4: null,   y4: null,   x5: 550, y5: 370, layout: 'Y', },
{ BLK: 'MW05DB',    Lv1: 'TOPSIDES', Lv2: 'MODULES', Lv3: 'MW05', x1: 954,    y1: 272.45, x2: 706,    y2: 467.45, x3: 643.82, y3: 495.45, x4: null,   y4: null,   x5: 500, y5: 280, layout: 'Y', },
{ BLK: 'MW05DC',    Lv1: 'TOPSIDES', Lv2: 'MODULES', Lv3: 'MW05', x1: 954,    y1: 242,    x2: 706,    y2: 437,    x3: 535.82, y3: 372, x4: null,      y4: null,   x5: 555, y5: 300, layout: 'Y', },
{ BLK: 'LD00DA',    Lv1: 'TOPSIDES', Lv2: 'COMMON', Lv3: 'LD00DA',x1: 1170,   y1: 400,    x2: null,   y2: null,   x3: null,   y3: null, x4: null,     y4: null,   x5: 600, y5: 357, layout: 'Y', },
{ BLK: 'KO01',      Lv1: 'TOPSIDES', Lv2: 'COMMON', Lv3: 'KO01',  x1: 340,    y1: 740,    x2: null,   y2: null,   x3: null,   y3: null, x4: null,     y4: null,   x5: 520, y5: 350, layout: 'Y', },
{ BLK: 'FL01',      Lv1: 'TOPSIDES', Lv2: 'COMMON', Lv3: 'FL01',  x1: 355,    y1: 436,    x2: null,   y2: null,   x3: null,   y3: null, x4: null,     y4: null,   x5: 806, y5: 206, layout: 'Y', },


]

export default Topsides