import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  data: () => ({
    
  }),

  methods: {

    // ===============================================================================================================================================

    // Canvas_Division_Lv4(lv2) {
      
    //   this.CANVAS = this.svg.append('svg')

    //   this.CANVAS
    //   .attr('width', this.Canvas.CanvasWidth)
    //   .attr('height', this.Canvas.CanvasHeight)
    //   .style('opacity', 1)

    //   let style = null

    //   this.CANVAS
    //   .append('image')
    //   .attr('transform', `translate(0,-11)`)
    //   .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Canvas/Division_Lv4_${lv2}.svg`)
    //   .style('pointer-events', 'none')

    //   // console.log(lv2)
   
    //   // Block Status
    //   this.BlockStatus_Coordinate.filter(f=> f.Lv2 == lv2 && f.Lv4 !== null).forEach((prog,sn) => {
    //     // console.log(prog.Lv1, prog.Lv2, prog.Lv4)
    //     let itemSet = []
    //     style = {
    //               x: prog.x,
    //               y: prog.y,
    //               headTextSize: 16,
    //               headTitle: prog.NAME,
    //               progDisplay: 'N',
    //               deltaDisplay: 'N',
    //     }

    //     this.Status.forEach((d,i) => { 
    //       itemSet.push({
    //         name: d.title,
    //         total: this.dataSet.filter(f => f.Lv1 == 'HULL' && f.Lv2 == prog.Lv2 && f.Lv4 == prog.Lv4 && f.EREC == 'Y').length,
    //         actual: this.dataSet.filter(f => f.Lv1 == 'HULL' && f.Lv2 == prog.Lv2 && f.Lv4 == prog.Lv4 && f.EREC == 'Y' && f.STATUS == d.code).length,
    //         bColor: this.Status[i].bColor,
    //         opacity: this.Status[i].opacity,
    //       })
           
    //     })
    //     this.blockStatus(itemSet)

    //     this.Bar_Quantity_02(this.CANVAS, itemSet, style, sn) // <---- Chart Library
    //   })

      




  
      
    // },



  }
}