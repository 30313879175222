import * as d3 from 'd3'
import __M from 'moment'
import DonutData from '../primitives/donutProps'

export default {
  data: () => ({
    chartMounted: false,

    filtering: {},
    filterRequested: 'n/a', // n/a | full | dataitem

    scaleY: null,
    maxValue: 0,
    cutoff: null
    
  }),
  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },
  methods: {
    setDefaultValues(daterange) {
      this.filtering = {}

      
      let cutoff = __M(this.Queries.SQL2[0].CDATE).format('YYYY-MM-DD')
      this.cutoff = cutoff

      this.timelineKeyName = this.activity
      this.timeline.weekCount = []


      // force ranging the timeline -------
      let timelineRange_ = daterange
      this.setTimelineEnv(timelineRange_)
      // -------------------------- -------

      this.dataSet.forEach(d => {
        let date__ = this.getTimelineCDate(d[this.timelineKeyName])
        d.cDate = date__ ? date__.eDate : null
        d.sDate = date__ ? date__.sDate : null
        d.date = d3.timeParse('%Y-%m-%d')(d[this.timelineKeyName])
      })

      this.style.forEach(style => {
        style.item.forEach(item => {
          this.dataSet.filter(f => f[item.refColumn] == item.codeValue).forEach(d => {
            d.tStyle   = item.tStyle
            d.tSize    = item.tSize
            d.tColor   = item.tColor
            d.sWidth   = item.sWidth
            d.sColor   = d.sColor ? d.sColor : item.sColor
            d.bColor   = DonutData.attrs.find( donut => d.RFDC_STATUS == donut.codeValue) ? DonutData.attrs.find( donut => d.RFDC_STATUS == donut.codeValue).fColor : '#fff'
            d.opacity  = item.opacity
            d.radius   = item.radius
            if (item.dataColumn != '') d.progress = 'Y'
          })
        })
      })

      this.timeline.height = this.timeline.style.week.height
      if (this.timeline.style.month.display == 'Y') this.timeline.height += this.timeline.style.month.height
      if (this.timeline.style.year.display == 'Y') this.timeline.height += this.timeline.style.year.height
      
      this.timeline.week.forEach(w => {
        let count = 0
        this.dataSet.filter(f => f.cDate == w.eDate).forEach(() => {
          count += 1
        })
        this.timeline.weekCount.push(count)
      })
      let lightGray = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `lightGray`)
      .attr('x1', 0.772).attr('x2', 0.228).attr('y1', 0.228).attr('y2', 0.919)
      lightGray.append('stop').attr('stop-color', '#fff').attr('offset', '0').attr('stop-opacity', 1)
      lightGray.append('stop').attr('stop-color', '#d0d2d3').attr('offset', '1').attr('stop-opacity', 1)

      
      let fillWhite = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `fillWhite`)
      .attr('x1', 0.772).attr('x2', 0.228).attr('y1', 0.228).attr('y2', 0.919)
      fillWhite.append('stop').attr('stop-color', '#fff').attr('offset', '1').attr('stop-opacity', 1)
      fillWhite.append('stop').attr('stop-color', '#fff').attr('offset', '1').attr('stop-opacity', 1)

      
      let fillYellow = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `fillYellow`)
      .attr('x1', 0.772).attr('x2', 0.228).attr('y1', 0.228).attr('y2', 0.919)
      fillYellow.append('stop').attr('stop-color', '#FFC400').attr('offset', '1').attr('stop-opacity', 1)
      fillYellow.append('stop').attr('stop-color', '#FFC400').attr('offset', '1').attr('stop-opacity', 1)

      
      let fillBlue = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `fillBlue`)
      .attr('x1', 0.772).attr('x2', 0.228).attr('y1', 0.228).attr('y2', 0.919)
      fillBlue.append('stop').attr('stop-color', '#00B0FF').attr('offset', '1').attr('stop-opacity', 1)
      fillBlue.append('stop').attr('stop-color', '#00B0FF').attr('offset', '1').attr('stop-opacity', 1)

      
      let fillLightGreen = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `fillLightGreen`)
      .attr('x1', 0.772).attr('x2', 0.228).attr('y1', 0.228).attr('y2', 0.919)
      fillLightGreen.append('stop').attr('stop-color', '#689F38').attr('offset', '1').attr('stop-opacity', 1)
      fillLightGreen.append('stop').attr('stop-color', '#689F38').attr('offset', '1').attr('stop-opacity', 1)

      
      let fillGreen = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `fillGreen`)
      .attr('x1', 0.772).attr('x2', 0.228).attr('y1', 0.228).attr('y2', 0.919)
      fillGreen.append('stop').attr('stop-color', '#2E7D32').attr('offset', '1').attr('stop-opacity', 1)
      fillGreen.append('stop').attr('stop-color', '#2E7D32').attr('offset', '1').attr('stop-opacity', 1)




      let realHeight = Math.max(...this.timeline.weekCount) * (this.skyline.box.height)
      this.timeline.baseY = realHeight + this.skyline.marginTop
      this.realHeight = this.timeline.baseY
    },
    setData() {
      this.dataSet = JSON.parse(JSON.stringify(this.DataItems))
    },
    setFilter(type, colName, value) {
      if(type == 'multi') {
        // init the object for the column if not present
        if(!this.filtering[colName]) this.filtering[colName] = []

        let index_ = this.filtering[colName].findIndex(v_ => v_ == value)
        if(index_ >= 0) {
          this.filtering[colName][index_] = null
          this.filtering[colName] = this.filtering[colName].filter(v_ => !!v_)
        } else {
          this.filtering[colName].push(value)
        }

      } else if(type == 'multi-pretended') {
        // init the object for the column if not present
        if(!this.filtering[colName]) this.filtering[colName] = value
        else delete this.filtering[colName]

      } else {  // for the single select or a value required
        if(this.searchFields.includes(colName)) {
          this.searchFields.forEach(c_ => {
            if(Object.keys(this.filtering).findIndex(k_ => k_ == c_) >= 0) this.filtering[c_] = ''
          })
        }

        this.filtering[colName] = value
      }
    },
    visible(values) {
      console.log(this.filtering)
      let visible_ = true
      if (this.filtering['PUNCH_STATUS'] && !this.filtering['PUNCH_STATUS'].includes(values['PUNCH_STATUS'])) {
        console.log('here')
        visible_ = false
      }
      if (this.filtering['B_OV_STATUS'] && !this.filtering['B_OV_STATUS'].includes(values['B_OV_STATUS'])) {
        console.log('here')
        visible_ = false
      }
      if (this.filtering['RFDC_STATUS'] && !this.filtering['RFDC_STATUS'].includes(values['RFDC_STATUS'])) {
        console.log('here')
        visible_ = false
      }
      return visible_
    },

    // Input & Search functions --------------------------------------
    trimDivText(selection) {
      selection.html(selection.html().replace(/<div(.*?)\/div>/g, ''))
    },
    trimFilterPure() {
      let filters_ = JSON.parse(JSON.stringify(this.filtering))

      Object.keys(filters_).forEach(k_ => {
        if(typeof filters_[k_] == 'object') {
          if(Object.keys(filters_[k_]).length === 0) delete filters_[k_]
        } else {
          if(!filters_[k_]) delete filters_[k_]
        }
      })

      return filters_
    },
  }
}