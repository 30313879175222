import * as d3 from 'd3'
import __M from 'moment'
import DonutData from '../primitives/donutProps'

export default {
  data: () => ({
    chartMounted: false,

    filtering: {},
    filterRequested: 'n/a', // n/a | full | dataitem
    
    scaleY: null,
    maxValue: 0,
    cutoff: null,

    searchFields: ['SUBSYSTEM', 'SUBSYSTEM_DESCR', 'TAG_NO', 'PUNCH_NO'],
    searchFieldsRequestNewData: ['TAG_NO', 'PUNCH_NO']
  }),
  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },
  methods: {
    setDefaultValues(daterange) {

      this.filtering = {}

      
      let cutoff = __M(this.Queries.SQL2[0].CDATE).format('YYYY-MM-DD')
      this.cutoff = cutoff

      this.timelineKeyName = this.activity
      this.timeline.weekCount = []

      // this.setData()
      // this.dataSet.sort(this.compare)
      // this.setTimelineEnv(this.dataSet)

      // force ranging the timeline -------
      let timelineRange_ = daterange
      this.setTimelineEnv(timelineRange_)
      // -------------------------- -------

      this.dataSet.forEach(d => {
        let date__ = this.getTimelineCDate(d[this.timelineKeyName])
        d.cDate = date__ ? date__.eDate : null
        d.sDate = date__ ? date__.sDate : null
        d.date = d3.timeParse('%Y-%m-%d')(d[this.timelineKeyName])
      })
      
      this.style.forEach(style => {
        style.item.forEach(item => {
          this.dataSet.filter(f => f[item.refColumn] == item.codeValue).forEach(d => {
            d.tStyle   = item.tStyle
            d.tSize    = item.tSize
            d.tColor   = item.tColor
            d.sWidth   = item.sWidth
            d.sColor   = d.sColor ? d.sColor : item.sColor
            d.bColor   = DonutData.attrs.find( donut => d.SUBSYSTEM_STATUS == donut.codeValue) ? DonutData.attrs.find( donut => d.SUBSYSTEM_STATUS == donut.codeValue).fColor : '#fff'
            d.opacity  = item.opacity
            d.radius   = item.radius
            if (item.dataColumn != '') d.progress = 'Y'
          })
        })
      })

      this.timeline.height = this.timeline.style.week.height
      if (this.timeline.style.month.display == 'Y') this.timeline.height += this.timeline.style.month.height
      if (this.timeline.style.year.display == 'Y') this.timeline.height += this.timeline.style.year.height
      
      this.timeline.week.forEach(w => {
        let count = 0
        this.dataSet.filter(f => f.cDate == w.eDate).forEach(() => {
          count += 1
        })
        this.timeline.weekCount.push(count)
      })
      let lightGray = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `lightGray`).attr('x1', 0.772).attr('x2', 0.228).attr('y1', 0.228).attr('y2', 0.919)
      lightGray.append('stop').attr('stop-color', '#fff').attr('offset', '0').attr('stop-opacity', 1)
      lightGray.append('stop').attr('stop-color', '#d0d2d3').attr('offset', '1').attr('stop-opacity', 1)

      let fillWhite = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `fillWhite`)
      .attr('x1', 0.772).attr('x2', 0.228).attr('y1', 0.228).attr('y2', 0.919)
      fillWhite.append('stop').attr('stop-color', '#fff').attr('offset', '1').attr('stop-opacity', 1)
      fillWhite.append('stop').attr('stop-color', '#fff').attr('offset', '1').attr('stop-opacity', 1)

      
      let fillYellow = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `fillYellow`)
      .attr('x1', 0.772).attr('x2', 0.228).attr('y1', 0.228).attr('y2', 0.919)
      fillYellow.append('stop').attr('stop-color', '#FFC400').attr('offset', '1').attr('stop-opacity', 1)
      fillYellow.append('stop').attr('stop-color', '#FFC400').attr('offset', '1').attr('stop-opacity', 1)

      
      let fillBlue = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `fillBlue`)
      .attr('x1', 0.772).attr('x2', 0.228).attr('y1', 0.228).attr('y2', 0.919)
      fillBlue.append('stop').attr('stop-color', '#1976D2').attr('offset', '1').attr('stop-opacity', 1)
      fillBlue.append('stop').attr('stop-color', '#1976D2').attr('offset', '1').attr('stop-opacity', 1)


      let fillLightBlue = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `fillLightBlue`)
      .attr('x1', 0.772).attr('x2', 0.228).attr('y1', 0.228).attr('y2', 0.919)
      fillLightBlue.append('stop').attr('stop-color', '#4FC3F7').attr('offset', '1').attr('stop-opacity', 1)
      fillLightBlue.append('stop').attr('stop-color', '#4FC3F7').attr('offset', '1').attr('stop-opacity', 1)

      
      let fillLightGreen = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `fillLightGreen`)
      .attr('x1', 0.772).attr('x2', 0.228).attr('y1', 0.228).attr('y2', 0.919)
      fillLightGreen.append('stop').attr('stop-color', '#689F38').attr('offset', '1').attr('stop-opacity', 1)
      fillLightGreen.append('stop').attr('stop-color', '#689F38').attr('offset', '1').attr('stop-opacity', 1)

      
      let fillGreen = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `fillGreen`)
      .attr('x1', 0.772).attr('x2', 0.228).attr('y1', 0.228).attr('y2', 0.919)
      fillGreen.append('stop').attr('stop-color', '#2E7D32').attr('offset', '1').attr('stop-opacity', 1)
      fillGreen.append('stop').attr('stop-color', '#2E7D32').attr('offset', '1').attr('stop-opacity', 1)


      // // line data
      // if (this.on4Weeks) this.set4weekSummaryData()
      // else this.setSummaryData()

      // let realHeight = Math.max(...this.timeline.weekCount) * (this.skyline.box.height)
      // if (realHeight > this.skyline.minSize) {
      //   // this.skyline.line.height = realHeight
      //   this.timeline.baseY = realHeight + this.skyline.marginTop
      // } else {
      //   // this.timeline.baseY = this.skyline.line.height = this.skyline.minSize
      // }

      this.timeline.baseY = 750
    },
    setData() {
      this.dataSet = JSON.parse(JSON.stringify(this.DataItems))
    },
    setSummaryData() {
      this.timeline.plan = []
      this.timeline.actual = []
      this.timeline.trend = []

      if(!this.Queries.SQL3 || this.Queries.SQL3.length === 0) return

      this.lineChartData = JSON.parse(JSON.stringify(this.Queries.SQL3))
      this.lineChartData.forEach(d => {
        d.eDate = new Date(d.WD_PLAN)
      })

      let prevLineValues_ = {
        plan: null,
        actual: null,
        forecast: 0,
        trend: null
      }

      this.timeline.week.forEach(w => {
        let lineData_ = this.lineChartData.find(f => f.WD_PLAN == __M(w.eDate).format('YYYY-MM-DD'))

        if (lineData_ && lineData_.PLAN_CUM >= 0) {
          this.timeline.plan.push({ 
            eDate: w.eDate, 
            mDate: w.mDate,
            sDate: w.sDate,
            value: lineData_ && typeof lineData_.PLAN_CUM == 'number' ? lineData_.PLAN_CUM : prevLineValues_.plan
          })
        }

        if (lineData_ && lineData_.ACTUAL_CUM >= 0) {
          this.timeline.actual.push({ 
            eDate: w.eDate, 
            mDate: w.mDate,
            sDate: w.sDate,
            value: lineData_ && typeof lineData_.ACTUAL_CUM == 'number' ? lineData_.ACTUAL_CUM : prevLineValues_.actual
          })
        }

        
      })

    },
    setLineScale() {

      let arr = this.lineChartData.filter(d => this.on4Weeks ? d.TOTAL : d.ACTUAL_CUM).map(d => this.on4Weeks ? d.TOTAL : d.ACTUAL_CUM)
      let getMax = Math.max(...arr)


      this.scaleY = d3
      .scaleLinear()
      .domain([0, getMax])
      .range([this.timeline.baseY, this.timeline.baseY - this.skyline.line.height])

      this.lineZero = d3.line()
      .x(d => this.timeline.scale(d.eDate))
      .y(this.scaleY(0))

      this.lineFunc = d3.line()
      .x(d => this.timeline.scale(d.eDate))
      .y(d => this.scaleY(d.value))
    },
    setFilter(type, colName, value) {
      if(type == 'multi') {
        // init the object for the column if not present
        // console.log(type, colName, value, !this.filtering[colName])
        if(!this.filtering[colName]) this.filtering[colName] = []

        let index_ = this.filtering[colName].findIndex(v_ => v_ == value)
        // console.log(index_)
        if(index_ >= 0) {
          this.filtering[colName][index_] = null
          this.filtering[colName] = this.filtering[colName].filter(v_ => !!v_)
        } else {
          this.filtering[colName].push(value)
        }

      } else if(type == 'multi-pretended') {
        // init the object for the column if not present
        if(!this.filtering[colName]) this.filtering[colName] = value
        else delete this.filtering[colName]

      } else {  // for the single select or a value required
        let searchFields_ = ['SUBSYSTEM', 'TAG_NO', 'PUNCH_NO']
        if(searchFields_.includes(colName)) {
          searchFields_.forEach(c_ => {
            if(Object.keys(this.filtering).findIndex(k_ => k_ == c_) >= 0) this.filtering[c_] = ''
          })
        }

        this.filtering[colName] = value
      }
    },
    visible(values) {
      let visible_ = true

      if (this.filtering['SUBSYSTEM_STATUS'] && !this.filtering['SUBSYSTEM_STATUS'].includes(values['SUBSYSTEM_STATUS'])) {
        visible_ = false
      }
      if (this.filtering['SUBSYSTEM'] && !values['SUBSYSTEM'].includes(this.filtering['SUBSYSTEM'])) {
        visible_ = false
      }
      if (this.filtering['TAG_NO'] && (values.ACT_TAG_VALID == -1 && values.PUNCH_TAG_VALID == -1)) {
        visible_ = false
      }
      if (this.filtering['PUNCH_NO'] && values.PUNCH_NUMBER_VALID == -1) {
        visible_ = false
      }

      return visible_
    },
    compare(a, b) {
      // Use toUpperCase() to ignore character casing
      const bandA = `${a[this.activity]}-${a.SUBSYSTEM}`.toUpperCase();
      const bandB = `${b[this.activity]}-${b.SUBSYSTEM}`.toUpperCase();

      let comparison = 0
      if(bandA > bandB) comparison = 1
      else if(bandA < bandB) comparison = -1
      
      return comparison
    },

    // Input & Search functions --------------------------------------
    trimDivText(selection) {
      selection.html(selection.html().replace(/<div(.*?)\/div>/g, ''))
    },
    setCaret(el) {
      let range = document.createRange(),
          sel = window.getSelection(),
          lastKnownIndex = -1;
      for (let i = 0; i < el.childNodes.length; i++) {
        if (this.isTextNodeAndContentNoEmpty(el.childNodes[i])) {
          lastKnownIndex = i;
        }
      }
      if (lastKnownIndex === -1) {
        // throw new Error('Could not find valid text content');
        return
      }
      let row = el.childNodes[lastKnownIndex],
          col = row.textContent.length;
      range.setStart(row, col);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
      el.focus();
    },
    isTextNodeAndContentNoEmpty(node) {
      return node.nodeType == Node.TEXT_NODE && node.textContent.trim().length > 0
    },
    search() {
      let text = d3.select(`#${this.localId}`).select('.search_input').text()
      let targetFilter = this.inputFilter.values.find(i__ => i__.on)

      this.setFilter('single', targetFilter.colName, text)

      if(this.searchFieldsRequestNewData.includes(targetFilter.colName)) {
        this.filterRequested = 'dataitem'

        let filterTrimmed_ = this.trimFilter()
        let request_ = {
          dataType: 'row',
          loading : true,
          action  : filterTrimmed_.action,
          filters : filterTrimmed_.filters,
          iFilters: {
            filterString : '',
            inputFilter  : ''
          }
        }
        this.$emit('request-action', request_)

      } else this.Chart_filtering()
    },
    activityChanged() {
      // It must be different if not, the stored procedure will be falling in infinite loop.
      if(this.activity != 'MC_PLAN') var action = {
        name: 'Query Modifier',
        type: 'direct',    // important!
        target: 'none',
        command: 'replace',
        value: this.activity,
        substring: 'MC_PLAN',
        strings: [
          'MC_PLAN'
        ],
      }; else action = {
        type: 'direct',    // important!
        target: 'none',
      }

      let request_ = {
        id: 514,
        component: 'ServicePageSvgForMC',
        dataType: 'row',
        action,
        filters     : {
              ...this.FilterValues,
          // targetFilter.colName : TAG | PUNCH_NUMBER
          // if the filter value is empty, filter will be trimmed by store module.
          TAG_NO: this.filtering.TAG_NO || '',  
          PUNCH_NO: this.filtering.PUNCH_NO || '',
        },
        iFilters    : {
          filterString : `MC_PLAN <= (SELECT DATEADD(WW,5,[CUTOFF]) FROM [T01_Project].[DataSource].[Document] WHERE [PHASE] = 'SC' AND [CODE] = 'MC')'`,
          inputFilter  : ''
        }
      }

      this.$emit('request-action', request_)
      // this.redraw()
    },

    trimFilterPure() {
      let filters_ = JSON.parse(JSON.stringify(this.filtering))

      Object.keys(filters_).forEach(k_ => {
        if(typeof filters_[k_] == 'object') {
          if(Object.keys(filters_[k_]).length === 0) delete filters_[k_]
        } else {
          if(!filters_[k_]) delete filters_[k_]
        }
      })

      return filters_
    },
    trimFilter() {
      // It must be different if not, the stored procedure will be falling in infinite loop.
      if(this.activity != 'MC_PLAN') var action = {
        name: 'Query Modifier',
        type: 'direct',    // important!
        target: 'none',
        command: 'replace',
        value: this.activity,
        substring: 'MC_PLAN',
        strings: [
          'MC_PLAN'
        ],
      }; else action = {
        type: 'direct',    // important!
        target: 'none',
      }

      let filters = {
        PUNCH_STATUS    : !this.filtering.PUNCH_STATUS || this.filtering.PUNCH_STATUS.length == 3 ? [] : (this.filtering.PUNCH_STATUS.length === 0 ? ['NONE'] : this.filtering.PUNCH_STATUS),
        SUBSYSTEM_STATUS: !this.filtering.SUBSYSTEM_STATUS || this.filtering.SUBSYSTEM_STATUS.length == 5 ? [] : (this.filtering.SUBSYSTEM_STATUS.length === 0 ? ['NONE'] : this.filtering.SUBSYSTEM_STATUS),
        DISCIPLINE      : !this.filtering.DISCIPLINE || this.filtering.DISCIPLINE.length == 10 ? [] : (this.filtering.DISCIPLINE.length === 0 ? ['NONE'] : this.filtering.DISCIPLINE),

        MOC_OPEN        : this.filtering.MOC_OPEN || '',  
        PMCS_OPEN       : this.filtering.PMCS_OPEN || '',  
        DBSPO           : this.filtering.DBSPO || '',  
        FLOAT_OFF       : this.filtering.FLOAT_OFF || '',  
        OBS_OPEN        : this.filtering.OBS_OPEN || '',  

        SUBSYSTEM       : this.filtering.SUBSYSTEM || '',  
        TAG_NO             : this.filtering.TAG_NO || '',  
        PUNCH_NO    : this.filtering.PUNCH_NO || '',  
      }

      return JSON.parse(JSON.stringify({
        action,
        filters
      }))
    }
  }
}