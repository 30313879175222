// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  data: () => ({
    paths: [
      {
        module: 'LQ',
        path: '/TORTUE/Modules/LQDashboard/LQ/'
      },
      {
        module: 'HULL-FOR',
        path: '/TORTUE/Modules/HULLFORDashboard/'
      },{
        module: 'TOPSIDES MODULES',
        path: '/TORTUE/Division/Blocks/Topsides/'
      }
    ]
  }),
  methods: {
    Draw_Charts() {
      let lv = this.FilterValues.GWBS_LEVEL ? this.FilterValues.GWBS_LEVEL : this.FilterValues.GWBS_LV3 ? this.FilterValues.BLOCK : 'DECK A'

      let lv2 = this.FilterValues.LV2 ? this.FilterValues.LV2 :  this.FilterValues.GWBS_LV3 ? 'TOPSIDES MODULES' : 'LQ'

      let path = this.paths.find(d => d.module === lv2).path

      this.svg
        .append('image')
        .attr('opacity', 1)
        .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}${path}${lv}.png`)
    },
  }
}
