import * as d3 from 'd3'
import __M from 'moment'
import { thresholdFreedmanDiaconis } from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  data:() => ({
    props:[
      { item: 'Overall Plan Workforce',  itemColor:'#ccc' },
      { item: 'Overall COSCO Workforce',  itemColor:'#9E9E9E' },
      { item: 'Overall Actual Workforce',  itemColor:'#81D4FA' },
    ],
    imageProps:[
      { module: 'HULL & LQ', moduleDescr:'Flash Gas Compression',x: -32, y: 134 },
      { module: 'TOPSIDES', moduleDescr:'MEG & Fuel Gas',x: -32, y: 100 },
      { module: 'INTEGRATION', moduleDescr:'Power Generation',x: -10, y: 90 },
    ]
  }),
  methods: {  
    Draw_Charts() {
      let data = null, 
          style = null

      let overallPunchStatus = this.svg
      .append('g').attr('class', 'overall')
      .attr('transform', `translate(25.5, 0)`)
      
      overallPunchStatus
      .append('text')
      .attr('transform', `translate(210,50)`)
      .style('font-family', 'roboto').style('font-size', 12).style('fill', '#616161').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
      .text('Weekly Summary')

      overallPunchStatus
      .append('text')
      .attr('transform', `translate(840,50)`)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#bcbcbc').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
      .text('*Workforce plan based on 6 working days per week/10 hours per day')

      let imagesMap = [...new Set(this.DataItems.map(d=> d.GWBS_LV1))]
      let images = this.DataItems.find(d => d.GWBS_LV1) //이거는 해당하는 GWBS_LV1를 가져오지만 배열로 가져오지는 못함 그래서 props와 매치가 안되더라
      let props = this.imageProps.filter(d => d.module == imagesMap)[0]
      
      // console.log(imagesMap)
      // console.log(images)
      // console.log(props)

      overallPunchStatus
      .append('image')
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Images/${imagesMap}.png`)
      // .attr('transform', `translate(0, ${ ['ME04','MW04'].includes(moduleMap) ? 40 : 100}) `)
      .attr('transform', `translate(${props.x} ${props.y}) `)

      let chartX2 = this.DataItems.length * 83
      let tableX2 = this.DataItems.length * 93
      // -----------------------------------------------legend
      // let priortyLegend = overallPunchStatus
      // .append('g').attr('class', 'legend')
      // .attr('transform', `translate(420, 50)`)

      // this.props.forEach((d, i) =>{
      //   priortyLegend
      //   .append('rect')
      //   .attr('transform', `translate(${130*i}, 2)`)
      //   .attr('width', 15).attr('height', 10).attr('fill', d.itemColor)

      //   priortyLegend
      //   .append('text')
      //   .attr('transform', `translate(${130*i + 18}, 8)`)
      //   .style('font-family', 'Roboto-Regular, Roboto').style('font-size', 9)
      //   .style('fill', '#616161').attr('text-anchor', 'start')
      //   .attr('alignment-baseline', 'middle')
      //   .text(d.item)//priorty
      // })
      // -----------------------------------------------table priorty text and table Line 
      let prorityItem = overallPunchStatus
      .append('g').attr('class', 'prorityItem')
      .attr('transform', `translate(10.5, 270.5)`)

      this.props.forEach((d, i) =>{
        prorityItem
        .append('rect')
        .attr('transform', `translate(-20, ${20*i-6})`)
        .attr('width', 10).attr('height', 10).attr('fill', d.itemColor)

        prorityItem
        .append('text')
        .attr('transform', `translate(-4, ${20*i})`)
        .style('font-family', 'Roboto-Regular, Roboto').style('font-size', 11)
        .style('fill', '#616161').attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text(d.item)//priorty

        prorityItem
        .append('line').attr('x1', -24).attr('x2', tableX2).attr('y1', 20*i+8).attr('y2', 20*i+8)
        .attr('stroke', '#9E9E9E').attr('stroke-width', 0.2)
      })
      prorityItem
      .append('line').attr('x1', 130.5).attr('x2', 130.5).attr('y1', -10).attr('y2', 48)
      .attr('stroke', '#9E9E9E').attr('stroke-width', 0.2)

      prorityItem
        .append('line').attr('x1', -24).attr('x2', tableX2).attr('y1', -11).attr('y2', -11)
        .attr('stroke', '#9E9E9E').attr('stroke-width', 0.2)
      

      // -----------------------------------------------gridlines
      let values = [...this.DataItems.map(d => d.PLAN), ...this.DataItems.map(d => d.ACTUAL),...this.DataItems.map(d => d.COSCO)].filter(d => d)

      let maxValue = Math.max(...values)
      maxValue = this.getMaxBound(maxValue)
      //Scale for the radius
      let barScale = d3.scaleLinear()
      .domain([0, maxValue])
      .range([150, 0])

      let overallPunch = overallPunchStatus
      .append('g').attr('class', 'gridlines')
      .attr('transform', `translate(${100}, ${80.5})`)
      
      for(var i=0; i<6; i++) {
        overallPunch
        .append('line')
        .attr('x1', 50 - 5).attr('x2', chartX2)
        .attr('y1', barScale((maxValue/5)*i)).attr('y2', barScale((maxValue/5)*i))
        .attr('stroke', '#E0E0E0')
        .attr('stroke-width', i == 0 ? 0.8 :  0.2)

        overallPunch
        .append('text')
        .attr('transform', `translate(${50 - 7}, ${barScale((maxValue/5)*i)})`)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#bcbcbc').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text((maxValue/5)*i)
      }

      // -----------------------------------------------chart
      let weekD = [...new Set(this.DataItems.map(d => d.WK_NO))]
      let Cdate = [...new Set(this.DataItems.map(d => d.CUTOFF))]
      let chartX = 100
      weekD.forEach((p, i) => {
        data = this.DataItems.filter(f => f.WK_NO == p)
        style = {
                  week:p,
                  max:maxValue,
                  x: 10,
                  y: 80,
                  stX: 50,
                  barLen: 150,
                  width: 17,
                  space:7,
                  pColor:   ['#ccc', '#1976D2'],
                  bColor:   ['#F7BACF', '#FCE3EB'],
                  sColor:   ['#EC407A', '#F7BACF'],
                  tColor:   ['#fff','#757575'], 
                  opacity:  1,
        }
        let group = overallPunchStatus.append('g').attr('class', `chart_group_${p}_`).attr('transform', `translate(${chartX}, 80)`)

        this.Bar_Punch(group, data, style) // ---------------------------> 
        chartX += 80

        // ---------------------------------------------table
        // overallPunchStatus
        // .append('text').attr('transform', `translate(${60*i+72}, 244)`)
        // .style('font-family', 'roboto').style('font-size', 11).style('fill', '#616161').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        // .text(p)//weekNo
        
      })
      Cdate.forEach((c, i) => {
        overallPunchStatus
        .append('text').attr('transform', `translate(${80*i + 174}, 244)`)
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#616161').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text(__M(new Date(c)).format('D-MMM'))
      })
      
    },
    Bar_Punch(selection, _data, _style) {
      
      let overallPunch = selection
      let barScale = d3.scaleLinear()
      .domain([0, _style.max])
      .range([_style.barLen, 0])
      
      let x = _style.stX
      _data.forEach((d,i) => {

        if (d.PLAN) {

          // PLAN
          overallPunch
          .append('path')
          .attr('d', ` M ${x}, ${_style.barLen}
                      V ${barScale(d.PLAN)}
                      H ${x + _style.width}
                      V ${_style.barLen} Z
                    `
          ).style('fill', '#E0E0E0').attr('stroke-width', 0.5)
  
          overallPunch
          .append('text')
          .attr('transform', `translate(${x + (_style.width/2)}, ${barScale(d.PLAN) - 6})`)
          .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
          .text(`${d.PLAN}`)
  
          x += _style.width + _style.space
  
          overallPunch
          .append('text')
          .attr('transform', `translate(${70}, ${20*i+190})`)
          .style('font-family', 'roboto').style('font-size', 11).style('fill', '#757575').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
          .text(`${d.PLAN}`)
        }

        if (d.COSCO) {
          // COSCO
          overallPunch
          .append('path')
          .attr('d', ` M ${x}, ${_style.barLen}
                      V ${barScale(d.COSCO)}
                      H ${x + _style.width}
                      V ${_style.barLen} Z
                    `
          ).style('fill', '#BDBDBD').attr('stroke-width', 0.5)

          overallPunch
          .append('text')
          .attr('transform', `translate(${x + (_style.width/2)}, ${barScale(d.COSCO) - 6})`)
          .style('font-family', 'roboto').style('font-size', 10).style('fill', '#424242').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
          .text(`${d.COSCO}`)

          x += _style.width + _style.space

          overallPunch
          .append('text')
          .attr('transform', `translate(${70}, ${20*i+210})`)
          .style('font-family', 'roboto').style('font-size', 11).style('fill', '#424242').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
          .text(`${d.COSCO}`)
        }

        if (d.ACTUAL) {
          // ACTUAL
          overallPunch
          .append('path')
          .attr('d', ` M ${x}, ${_style.barLen}
                      V ${barScale(d.ACTUAL)}
                      H ${x + _style.width}
                      V ${_style.barLen} Z
                    `
          ).style('fill', '#81D4FA').attr('stroke-width', 0.5)

          overallPunch
          .append('text')
          .attr('transform', `translate(${x + (_style.width/2)}, ${barScale(d.ACTUAL) - 6})`)
          .style('font-family', 'roboto').style('font-size', 10).style('fill', '#03A9F4').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
          .text(`${d.ACTUAL}`)

          x += _style.width + _style.space

          overallPunch
          .append('text')
          .attr('transform', `translate(${70}, ${20*i+230})`)
          .style('font-family', 'roboto').style('font-size', 11).style('fill', '#03A9F4').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
          .text(`${d.ACTUAL}`)
        }

        
      })
    },
  }
}
