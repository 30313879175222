import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({

  }),


  methods: {

    controlTarget(prev, i) {
      let current = i

      d3.select(`#detailInfo`).attr('opacity', 1).transition().duration(200).attr('opacity', 0)
      setTimeout(() => {
        this.DetailInfo.html()
        this.DetailInfo.remove()
        this.drawDetailInfo(this.dataSet[i].MOD)
      }, 200);

      d3.select(`#svgISO`)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TCO/ISO/${this.dataSet[current].MOD}.svg`)


      this.pieData.forEach((d,i) => {

        d.prevTotal = this.dataSet[prev][`${d.column}_T`]
        d.prevRemain = this.dataSet[prev][`${d.column}_R`]
        d.prevProgress = (this.dataSet[prev][`${d.column}_A`] / this.dataSet[prev][`${d.column}_T`]) * 100

        d.total = this.dataSet[current][`${d.column}_T`]
        d.remain = this.dataSet[current][`${d.column}_R`]
        d.progress = (this.dataSet[current][`${d.column}_A`] / this.dataSet[current][`${d.column}_T`]) * 100

        // Progress
        d3.select(`#${this.localId}_progress_${i}`).transition().duration(300)
        .tween("text", function() {
          var i = d3.interpolateNumber(Number(d.prevProgress).toFixed(1), Number(d.progress).toFixed(1))
          return function(t) {
          d3.select(this).text( `${Number(i(t).toFixed(0)).toLocaleString()}%` )
          }
        })

        // Total
        d3.select(`#${this.localId}_total_${i}`).transition().duration(300)
        .tween("text", function() {
          var i = d3.interpolateNumber(d.prevTotal, d.total)
          return function(t) {
          d3.select(this).text(Number(i(t).toFixed(0)))
          }
        })

        // Remain
        d3.select(`#${this.localId}_remain_${i}`).transition().duration(300)
        .tween("text", function() {
          var i = d3.interpolateNumber(d.prevRemain, d.remain)
          return function(t) {
          d3.select(this).text(Number(i(t).toFixed(0)))
          }
        })
      


        let prevHydroTotal = this.dataSet[prev]['HT_T']
        let prevHydroRemain = this.dataSet[prev]['HT_R']
        let prevHydroProg = this.dataSet[prev]['HT_PG']
        let prevLoopTotal = this.dataSet[prev]['ICS_001_T'] + this.dataSet[prev]['ICS_002_T'] + this.dataSet[prev]['ICS_005_T'] + this.dataSet[prev]['ICS_008_T']
        let prevLoopActual = this.dataSet[prev]['ICS_001_A'] + this.dataSet[prev]['ICS_002_A'] + this.dataSet[prev]['ICS_005_A'] + this.dataSet[prev]['ICS_008_A']
        let prevLoopRemain = prevLoopTotal - prevLoopActual
        let prevLoopProg = (prevLoopActual / prevLoopTotal) * 100

        let hydroTotal = this.dataSet[current]['HT_T']
        let hydroRemain = this.dataSet[current]['HT_R']
        let hydroProg = this.dataSet[current]['HT_PG']
        let loopTotal = this.dataSet[current]['ICS_001_T'] + this.dataSet[current]['ICS_002_T'] + this.dataSet[current]['ICS_005_T'] + this.dataSet[current]['ICS_008_T']
        let loopActual = this.dataSet[current]['ICS_001_A'] + this.dataSet[current]['ICS_002_A'] + this.dataSet[current]['ICS_005_A'] + this.dataSet[current]['ICS_008_A']
        let loopRemain = loopTotal - loopActual
        let loopProg = (loopActual / loopTotal) * 100

        // Hydro Total
        d3.select(`#hydroTotal_`).transition().duration(300)
        .tween("text", function() {
          var i = d3.interpolateNumber(Number(prevHydroTotal), Number(hydroTotal))
          return function(t) {
            d3.select(this).text(Number(i(t).toFixed(0)))
          }
        })

        // Hydro Remain
        d3.select(`#hydroRemain_`).transition().duration(300)
        .tween("text", function() {
          var i = d3.interpolateNumber(prevHydroRemain, hydroRemain)
          return function(t) {
            d3.select(this).text(Number(i(t).toFixed(0)))
          }
        })

        // Hydro Bar Chart 
        d3.select(`#hydroBar_`).transition().duration(300).attr('width', hydroProg)

        // Hydro Progress hydroBar_
        d3.select(`#hydroProg_`).transition().duration(300)
        .attr('x', () => {if (hydroProg > 20) return this.pieData[0].x - this.pieData[0].Ra + hydroProg - 1; else return this.pieData[0].x - this.pieData[0].Ra + hydroProg + 1})
        .attr('text-anchor', () => {if (hydroProg > 20) return 'end'; else return 'start'})
        .tween("text", function() {
          var i = d3.interpolateNumber(Number(prevHydroProg).toFixed(1), Number(hydroProg).toFixed(1))
          return function(t) {
          d3.select(this).text( `${Number(i(t).toFixed(1)).toLocaleString()}%` )
          }
        })

        // Loops Total
        d3.select(`#loopTotal_`).transition().duration(300)
        .tween("text", function() {
          var i = d3.interpolateNumber(Number(prevLoopTotal), Number(loopTotal))
          return function(t) {
            d3.select(this).text(Number(i(t).toFixed(0)))
          }
        })

        // Loops Remain
        d3.select(`#loopRemain_`).transition().duration(300)
        .tween("text", function() {
          var i = d3.interpolateNumber(prevLoopRemain, loopRemain)
          return function(t) {
            d3.select(this).text(Number(i(t).toFixed(0)))
          }
        })

        // Loops Bar Chart 
        d3.select(`#loopBar_`).transition().duration(300).attr('width', loopProg)

        // Hydro Progress hydroBar_
        d3.select(`#loopProg_`).transition().duration(300)
        .attr('x', () => {if (loopProg > 20) return this.pieData[5].x - this.pieData[0].Ra + loopProg - 1; else return this.pieData[5].x - this.pieData[0].Ra + loopProg + 1})
        .attr('text-anchor', () => {if (loopProg > 20) return 'end'; else return 'start'})
        .tween("text", function() {
          var i = d3.interpolateNumber(Number(prevLoopProg).toFixed(1), Number(loopProg).toFixed(1))
          return function(t) {
          d3.select(this).text( `${Number(i(t).toFixed(1)).toLocaleString()}%` )
          }
        })
      
        let arc = d3.arc()
        .outerRadius(d => d.Ra)
        .innerRadius(d => d.Ra - d.stroke)
        .startAngle(d => d.angleStart * Math.PI / 180)
        .endAngle(d => d.endAngle || d.angleStart * Math.PI / 180)

        d3.select(`#donut_${i}`)
        .datum(d)
        .transition()
        .duration(300)
        .attrTween('d', d => {
          let endAngle_ = ((d.angleSize * (d.progress/100)) + d.angleStart) * Math.PI / 180
          let interpolate = d3.interpolate(d.angleStart * Math.PI / 180, endAngle_)
          return function(t) {
            d.endAngle = interpolate(t)
            return arc(d)
          }
        })
    })
      this.target = i
    },

    
    drawDetailInfo(module) {

      this.DetailInfo = this.svg
      .append('g')
      .attr('id', 'detailInfo')
      .attr('transform', `translate(0,450)`)
      .attr('opacity', 0)

      this.DetailInfo
      .append('image')
      .attr('id', 'mod_Svg')
      .attr('x', 0).attr('y', 0).attr('width', 600)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TCO/SystemBoundary/${module}.svg`)

      this.DetailInfo
      .append('text')
      .attr('id', 'mod_Name')
      .attr('x', 100).attr('y', 350)
      .style('font-family', 'roboto').attr('font-size', 24).attr('fill', '#333').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(module)

      this.DetailInfo
      .append('text')
      .attr('id', 'mod_Desc')
      .attr('x', 100).attr('y', 370)
      .style('font-family', 'roboto').attr('font-size', 14).attr('fill', '#bcbcbc').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(this.dataSet.find(f => f.MOD == module).MOD_DESC)

      d3.select(`#detailInfo`).attr('opacity', 0).transition().duration(200).attr('opacity', 1)
    },

  }
}
