export default {
  width: 140,
  gab: 30,
  attrs: [
    { 
      text: 'Plan',
      refColumn: 'OV_PLAN_PROG',
      fColor: '#BBDEFB', 
      tColor: '#42A5F5'
    },
    { 
      text: 'Actual',
      refColumn: 'OV_ACTUAL_PROG',
      fColor: '#EF9A9A', 
      tColor: '#F44336'
    },
  ]
}
