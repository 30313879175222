import * as d3 from 'd3'
export default {

  data: () => ({
    Project: []
  }),


  methods: {

    func_Controller(){
      this.timeline = this.init_gmx_TimelineValues_v1(this.timeline)

      this.set_Gradient()
      this.drawForms()
      this.drawTimeline()
      this.drawLegends()
      this.draw_Grid()

      // Assign the key value of this.barChart.charts to 'code:'
      this.barChart.charts = Object.keys(this.barChart.charts).map(k => ({
        code: k,
        ...this.barChart.charts[k]
      }))

      // To create a new array by removing duplicates
      let result = [ ...new Set(this.Schedule.map(d => d.ACT_GROUP)) ]

      /*
      To create a new array with the removed values of duplicates
      Project [ {name: , lastNo:} ]
      */
      result.forEach(d => {
        this.Project.push(
          {name: d, lastNo: null}
        )
      })

      this.Project.forEach((d, i) => {
        this.Project[i].lastNo = this.Schedule.find(f => f.ACT_GROUP.includes(d.name)).NO - 1
      })

      // Get the unique values of the group, and assign unique = 'Y' to the last item of the group
      this.Schedule.forEach(d => {d["unique"] = 'N'})
      this.Project.forEach(d => {
        this.Schedule[d.lastNo].unique = 'Y'
      })


      this.svgGroup
      .append('g').attr('id', 'id_title').attr('transform', `translate(0, 30)`)
      this.svgGroup
      .append('g').attr('id', 'id_barChart').attr('transform', `translate(${this.styles.margin.left}, ${30})`)
      this.svgGroup
      .append('g').attr('id', 'id_milestone').attr('transform', `translate(${this.styles.margin.left}, ${30})`)
      this.svgGroup
      .append('g').attr('id', 'id_overall').attr('transform', `translate(${this.styles.margin.left}, ${200})`)

      this.y_Coordinate = 200

      this.Schedule.forEach( (d,i) => {

        if (d.unique == 'Y') {
          this.draw_GroupTitle(this.svgGroup, '#id_title', d.ACT_GROUP) // ---> draw_Charts
          this.y_Coordinate += this.barChart.topMargin
        }

        this.draw_ActTitle(this.svgGroup, '#id_title', d.ACTIVITY, i) // ---> draw_Charts
        this.draw_BarChart(this.svgGroup, '#id_barChart', d) // ---> draw_Charts

        this.Milestone.filter(f => f.ACTIVITY == d.ACTIVITY).forEach(m => {
          this.draw_Milestone(this.svgGroup, '#id_milestone', m) // ---> draw_Charts
        })
          
        this.y_Coordinate += this.barChart.topMargin
      })

      this.draw_OverallMilestone(this.svgGroup, '#id_overall') // ---> draw_Charts
      this.draw_MovingLine() // ---> draw_Charts
      this.draw_CriticalPath() // ---> draw_Charts
      this.draw_Timenow() // ---> draw_Charts
    },

  }
}