export default {
  watch: {
    Canvas: { handler(val) { if(!val || Object.keys(val).length === 0) return; this.register(this.draw); }, deep: true },
    Text: { handler(val) { if(!val || Object.keys(val).length === 0) return; this.register(this.draw); }, deep: true },
    DataItems: { 
      handler(val) { 
        if(!val || val.length === 0) return
        // if(this.filterRequested) {
        //   this.filterRequested = false
        //   this.setData()
        //   this.Chart_filtering()

        //   setTimeout(() => {
        //     this.setSummaryData()
        //     this.draw_Table()
        //     this.draw_LineCharts()
        //   }, 150)

        // } else if(!this.chartMounted) this.register(this.draw)
        // else this.redraw()
        this.register(this.draw)
      }, 
      deep: true 
    },
  },
}
