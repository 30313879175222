// -------------------------------------------------------------------------------------- Global
import { SafeIdMixin } from '../../../../../../includes/mixins/safeid.mixin'                // ATAGlance Framework Globals
import gmx_Controller from '../../../../../../includes/mixins/Controller.mixin'       // SVG Control
import gmx_SvgToolPath        from '../../../../../../includes/mixins/SvgTools_Path.mixin'    // SVG Tools Path
// Global Drawings
import gmx_Canvas from '../../../../../../includes/mixins/Draw_Canvas.mixin'
import gmx_Texts from '../../../../../../includes/mixins/Text_Draw.mixin'
// Global Functions
import gmx_Miscellaneous from '../../../../../../includes/mixins/Miscellaneous.mixins'
import gmx_Movable from '../../../../../../includes/mixins/Movable.mixin'


// -------------------------------------------------------------------------------------- Functional Charts



// Locally declared common Data-Props & SVG Drawing Support
import lmx_ReactiveEvents from './Realtime_Monitor.mixin' // Reactive Events
import lmx_Declares from './Declares.mixin'
import lmx_Charts from './Draw_Charts.mixin'
import lmx_dataStyles from './dataStyles.mixin'

import event_PDF from './Event_PDF_mixin'
import event_callFunc from './event_callFunc.mixin'


export default {
  mixins: [
    SafeIdMixin,
    gmx_SvgToolPath,
    gmx_Controller,
    gmx_Canvas,
    gmx_Miscellaneous,
    gmx_Movable,
    gmx_Texts,


    // Functional Charts

    lmx_ReactiveEvents,
    lmx_Declares,
    lmx_Charts,
    lmx_dataStyles,

    event_PDF,
    event_callFunc,
  ],
}
