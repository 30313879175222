import * as d3 from 'd3'
import __M from 'moment'// Global Data & Defs

export default {
  data:() => ({
    thead: [
      // { key: 'Week', html: 'Week', row: 1 },
      // { key: 'Wk -7', html: 'Wk -7', row: 1 },
      // { key: 'Wk -6', html: 'Wk -6', row: 1 },
      // { key: 'Wk -5', html: 'Wk -5', row: 1 },
      // { key: 'Wk -4', html: 'Wk -4', row: 1 },
      // { key: 'Wk -3', html: 'Wk -3', row: 1 },
      // { key: 'Wk -2', html: 'Wk -2', row: 1 },
      // { key: 'Wk -1', html: 'Wk -1', row: 1 },
      // { key: 'Wk -0', html: 'Wk -0', row: 1 },

      { key: 'CRANE_MAIN', html: 'Crane', row: 2 },
      { key: 'CUTOFF1', html: '', row: 2 },
      { key: 'CUTOFF2', html: '', row: 2 },
      { key: 'CUTOFF3', html: '', row: 2 },
      { key: 'CUTOFF4', html: '', row: 2 },
      { key: 'CUTOFF5', html: '', row: 2 },
      { key: 'CUTOFF6', html: '', row: 2 },
      { key: 'CUTOFF7', html: '', row: 2 },
      { key: 'CUTOFF8', html: '', row: 2 },
    ],
    tbody: [
      { key: 'CRANE_MAIN', w: 48, align: 'center'},
      { key: 'DELIVERY_PLAN', w: 46,type: 'float', align: 'right'},
      { key: 'DELIVERY_PLAN', w: 46,type: 'float', align: 'right'},
      { key: 'DELIVERY_PLAN', w: 46,type: 'float', align: 'right'},
      { key: 'DELIVERY_PLAN', w: 46,type: 'float', align: 'right'},
      { key: 'DELIVERY_PLAN', w: 46,type: 'float', align: 'right'},
      { key: 'DELIVERY_PLAN', w: 46,type: 'float', align: 'right'},
      { key: 'DELIVERY_PLAN', w: 46,type: 'float', align: 'right'},
      { key: 'DELIVERY_PLAN', w: 46,type: 'float', align: 'right'},
    ],

    
  }),
  methods: {
    Draw_Charts() {
      
      let svg = this.svg

      let items = Array.from(new Set([ ...this.DataItems.map(data => data.CRANE_MAIN ) ]))

      let list = []

      items.forEach(item => {
        let obj = {}
        let filter = this.DataItems.filter(f => f.CRANE_MAIN == item)

        obj.CRANE_MAIN = item

        filter.map(d => d.CUTOFF).forEach((cutoff, i) => {
          obj[cutoff] = filter.find(f => f.CUTOFF == cutoff).DELIVERY_PLAN
          let find = this.thead.find(f => f.key == `CUTOFF${i+1}`)
          find.html = cutoff
        })
        list.push(obj)
      })

      const reducer = (previousValue, currentValue) => previousValue + currentValue

      let widths = this.tbody.map(d => d.w)
      let sumWidth = widths.reduce(reducer)

      let group = svg.append('g').attr('class', 'table_group__').attr('transform', `translate(0.5, 0.5)`)
      let table_group = group.append('g').attr('transform', `translate(0.5, 0.5)`)


      let table_obj = table_group.append('foreignObject')
        .attr('y',0)
        .attr('width', `${sumWidth + 10}px`)
        .attr('height', '300px')
        .append('xhtml:body')
        .style('position', 'fixed')

      let table = table_obj.append('table')
      .attr('id', 'commodity_summary_table_')
      .style('border-collapse', 'collapse')
      .style('font-size', '11px')
      .style('width', `${sumWidth}px`)
      .style('table-layout', 'fixed')

      let colgroup = table.append('colgroup')
      this.tbody.forEach(k => {
        colgroup.append('col').style('width', k.w + 'px')
      })

      let thead = table.append('thead')
      let tr = thead.append('tr')

      this.thead.forEach(th => {
        tr.append('th')
        .attr('class', 'table_group__th')
        .attr('rowspan', () => th.rowspan ? th.rowspan : '')
          .attr('colspan', () => th.colspan ? th.colspan : '')
          .style('height', '22px')
          .style('background', th.color ? th.color : '#B0BEC5')
          .html(() => {
            if (!th.key.includes('CUTOFF')) return th.html
            else {
              return __M(new Date(th.html)).format('DD-MMM')
            }
          })
      })
      // for (let r = 1; r <= 2; r++) {
      //   let row = this.thead.filter(d=> d.row === r)
      //   let tr = thead.append('tr')
      //   row.forEach(item => {
      //     tr.append('th')
      //     .attr('class', 'table_group__th')
      //     .attr('rowspan', () => item.rowspan ? item.rowspan : '')
      //     .attr('colspan', () => item.colspan ? item.colspan : '')
      //     .style('height', '20px')
      //     .style('background', item.color ? item.color : '#EEEEEE')
      //     .html(() => {
      //       if (!item.key.includes('CUTOFF')) return item.html
      //       else {
      //         return __M(new Date(item.html)).format('DD-MMM')
      //       }
      //     })
      //   })        
      // }

      let tbodykeys = Object.keys(list[0])
      
      let tbody = table.append('tbody').attr('id', 'summay_tbody_')

      let regexp = /\B(?=(\d{3})+(?!\d))/g

      for (let r = 0; r < list.length; r++) {
        let tr = tbody.append('tr')

        tbodykeys.forEach((k, i) => {
          //console.log(k)
          let td = tr.append('td')
            .style('border', 'solid 0.1px #e0dede')
            .style('height', '20px')
            .style('text-align', k == 'CRANE_MAIN' ? 'center' : 'right')
            .style('background', r%2==0 ? '#fff':'#EEEEEE' )
            //.style('color', list[r][k]==0 && r%2==0 ? '#fff':list[r][k]==0 && r%2!=0 ? '#EEEEEE': '#000000')
            .style('padding', '0 3px 0 3px')
            //td.html(typeof list[r][k] == 'number' ? Math.round(Math.floor(list[r][k] * 100) / 100).toString().replace(regexp, ',') : list[r][k])
            td.html( list[r][k]==0 ? '':list[r][k])
            .style('cursor', () => {
              if (i == 0) return
              if (list[r][k] == 0) return
              return 'pointer'
            })
            .on('click', () => {
              if (i == 0) return
              if (list[r][k] == 0) return

              let filterArr = []
              
              //console.log(list[r][k])
              // let filterString = list[r]['DISC'] != 'Total' ? ` DISC = '${list[r]['DISC']}' ` : ``

              if (list[r]['CRANE_MAIN'] != 'Total') filterArr.push(` CRANE_MAIN = '${list[r]['CRANE_MAIN']}' `)
              if (k == k) filterArr.push(` DELIVERY_PLAN_WK = '${k}' AND DRESSUP_REQ = 'Y' `)
              //if (list[r]['CUTOFF'] != 'Total') filterArr.push(` DELIVERY_PLAN = '${list[r]['CUTOFF']}' `)
              // if (k == 'OPEN') filterArr.push(` [CAL_REVIEW_STATUS_CODE] = 'OPEN' `)
              // if (k == 'CANCELLED') filterArr.push( ` [CAL_REVIEW_STATUS_CODE] = 'CANCELLED' `)

              let request_ = {
                dataType: 'row',
                action: {
                  type: 'direct',
                  target: 'slide-modal',
                  component: 'ServiceDatatable',
                  id: 381,
                  no: ''
                },
                filters: { 
                  ...this.FilterValues
                },
                iFilters: {
                  filterString : filterArr.join(' AND '),
                  inputFilter  : ''
                }
              }
              this.$emit('request-action', request_)
          })
          if (r == list.length -1 ) {
            td.style('font-weight', 'bold')
            .style('border', 'solid 2px #E4E4E4')
          }
        })
      }
    },
  }
}