import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  methods: {

    draw_Forms() {
      let timeline = this.svg
      .append('image')
      .attr('id', 'timeline')
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/ErectionTileline/Forms.svg`)
      .attr('transform', `translate(0, 0)`)
      // this.loadSvg(0, 0, '/TORTUE/ErectionTileline/Forms.svg')

      let turtle = this.svg
      .append('image')
      .attr('id', 'turtle')
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/ErectionTileline/turtle.png`)
      .attr('transform', `translate(0, 160)`)

      let button = this.svg
      .append('g')
      .attr('transform', `translate(350,77)`)

      button
      .append('path')
      .attr('id', 'btn_Back')
      .attr('d', `M3.5,14.1c-0.4,0-0.7-0.3-0.7-0.7V8c0-0.4-0.3-0.7-0.7-0.7H0.3C0,7.2-0.1,7,0.2,6.7
                  l6.4-6.5c0.3-0.3,0.7-0.3,1,0c0,0,0,0,0,0l6.4,6.5C14.1,7,14,7.2,13.7,7.2h-1.8c-0.4,0-0.7,0.3-0.7,0.7v5.4c0,0.4-0.3,0.7-0.7,0.7
                  L3.5,14.1z`)
      .attr('fill', `url(#${this.localId}__LinearA4${'LightBlue'}0)`)
      .attr('transform', `translate(0,14) rotate(-90)`)
      // .style('opacity', () => { if(this.slideSeq == 1) return 0.2; else return 1 })
      .style('cursor', 'pointer')
      .on('mouseover', () => { if(this.slideSeq !== 1) d3.select(`#btn_Back`).transition().style('opacity',0.2) })
      .on('mouseout', () => { if(this.slideSeq !== 1) d3.select(`#btn_Back`).transition().style('opacity', 1) })
      .on('click', () => { this.gotoBack() })
      button
      .append('path')
      .attr('id', 'btn_Next')
      .attr('d', `M3.5,14.1c-0.4,0-0.7-0.3-0.7-0.7V8c0-0.4-0.3-0.7-0.7-0.7H0.3C0,7.2-0.1,7,0.2,6.7
                  l6.4-6.5c0.3-0.3,0.7-0.3,1,0c0,0,0,0,0,0l6.4,6.5C14.1,7,14,7.2,13.7,7.2h-1.8c-0.4,0-0.7,0.3-0.7,0.7v5.4c0,0.4-0.3,0.7-0.7,0.7
                  L3.5,14.1z`)
      .attr('fill', `url(#${this.localId}__LinearA4${'LightBlue'}0)`)
      .attr('transform', `translate(320,0) rotate(90)`)
      .style('opacity', 1)
      .style('cursor', 'pointer')
      .on('mouseover', () => {d3.select(`#btn_Next`).transition().style('opacity',0.2) })
      .on('mouseout', () => {d3.select(`#btn_Next`).transition().style('opacity', 1)})
      .on('click', () => { this.gotoNext() })

      button
      .append('rect')
      .attr('id','arrowMask')
      .attr('transform', `translate(0,0)`)
      .attr('width',330)
      .attr('height',20)
      .attr('fill', 'Transparent')
      .style('visibility', 'hidden')

      this.dropDown()
      
  },
    dropDown(){
      let ddData = this.Queries.SQL1.map(d => { return { text: d.Title, value: parseInt(d.No) }})
      let ddWrapper = this.svg
      .append('g')
      .attr('transform', `translate(380,75)`)
      .attr('id', 'dropdown_wrapper')
      
      this.drawDropdown(ddWrapper, ddData, (value) => {
        if(value == this.slideSeq) return
        if(value > this.slideSeq) {
          this.slideSeq = value - 1
          this.gotoNext()
        } else {
          this.slideSeq = value + 1
          this.gotoBack()
        }
      })
      this.nextSeq()
      this.draw_Contents()
    }
  }
}
           