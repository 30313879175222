
// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  data: () => ({
    thead: [
      { key: 'CATEGORY', html: 'Category', row: 1,  },
      { key: 'BIDSECTION', html: 'Bid Section', row: 1,  },
      { key: 'TOTAL', html: 'Total', row:1, },
      { key: 'COMPLETE', html: 'Complete', row: 1, },
      { key: 'CONTRACTOR', html: 'Award Sub-Contractor', row: 1, },
      // { key: 'REMAIN', html: 'Remain', row: 1, rowspan: 1, },
      // { key: 'PROG', html: 'Prog', row: 1, },
    ],
    theadrows: 1,
    tbody: [
      { key: 'CATEGORY', w: 48 },
      { key: 'BIDSECTION', w: 40},
      { key: 'TOTAL', w: 40,  },
      { key: 'COMPLETE', w: 48,type: 'float', },
      { key: 'CONTRACTOR', w: 100, },
      // { key: 'REMAIN', w: 40, type: 'float', align: 'right'},
      // { key: 'PROG', w: 40},
    ],
    
    tableWidth: 320,
    
    scrollProps: {
      w: 932,
      h: 300,
      g: 0,
      bar: {
        align : 'right',
        gap   : 3,
        radius: 2,
        width : 4,
        fill  : '#9E9E9E',
        stroke: '#9E9E9E',
      }
    },
    selectedStartDate: null,
    selectedStartColumn: null,

    selectedFinishDate: null,
    selectedFinishColumn: null,

    filteredList: null
  }),
  methods: {
    Draw_Charts() {
      if(!this.DataItems || this.DataItems.length === 0) return

      let tableData = this.svg

        let table_group = tableData.append('g').attr('transform', `translate(0, 0)`).attr('class', `block_table_group_`)

        // SET HERE!
        let list = this.DataItems.filter(d => d.BIDSECTION)
        let tableViewHeight = 96 +700 // thead + tbody
        // let tableViewHeight = 96 + (list.length * 18) // thead + tbody
  
        // if (tableViewHeight > this.scrollProps.h) this.setScroll(table_group, this.scrollProps)
  
        let table_obj = table_group.append('foreignObject')
          .attr('y',0)
          .attr('width', '100%')
          .attr('height', `${tableViewHeight}px`)
          .append('xhtml:body')
  
        let table = table_obj.append('table')
                    .style('border', 'solid 0.7px #E0E0E0')
                    .style('border-collapse', 'collapse')
                    .style('font-size', '11px')
                    .style('width', `${this.tableWidth}px`)
                    .style('table-layout', 'fixed')
  
        let colgroup = table.append('colgroup')
        this.tbody.forEach(k => {
          colgroup.append('col').style('width', k.w + 'px')
        })
  
        let maxRow = Math.max(...this.thead.map(d => d.row))
  
        let thead = table.append('thead')
        for (let r = 1; r <= maxRow; r++) {
          let row = this.thead.filter(d=> d.row === r)
          let tr = thead.append('tr').style('background', '#F5F5F5')
  
          row.forEach(item => {
            tr.append('th')
            .attr('class', 'completion_dashboard_table_group___th')
            .attr('rowspan', () => item.rowspan ? item.rowspan : '')
            .attr('colspan', () => item.colspan ? item.colspan : '')
            .style('border', 'solid 0.7px #E0E0E0')
            .style('padding', '5px')
            .style('height', '40px')
            .style('font-weight','bold')
            .style('font-size',  '11px')
            .style('color','#2c3e50')
            .html(item.html)
          })        
        }
          
        let tbodykeys = this.tbody
        let tbody = table.append('tbody')
  
        // for (let r = 0; r < list.length; r++) {
        //   let tr = tbody.append('tr')
        //   // tr.on('mouseover', () => {
        //   //   tr.style('cursor', 'pointer')
        //   //   tr.style('background-color', '#C5E1A5')
        //   // })
        //   // tr.on('mouseout', () => {
        //   //   tr.style('background-color', '#fff')
        //   // })
        //   //tr mouseover color change


        //   tbodykeys.forEach((k, i) => {
        //     let td = tr.append('td')
        //       .style('border', 'solid 0.7px #E0E0E0')
        //       .style('height', '22px')
        //       .style('text-align', k.align ? k.align : 'right')
        //       .style('padding', '0 3px 0 3px')
  
        //     if(i === 0) {
        //       td.style('white-space', 'nowrap')
        //         .style('overflow', 'hidden')
        //         .style('text-overflow', 'ellipsis')
        //         .attr('title', list[r][k.key])
        //     }
        //     if (!list[r][k.key]) return
        //     td.html(list[r][k.key])
        //   })
        // }
        let disc = ''
        let addClass = ''
        for (let r = 0; r < list.length; r++) {
          let tr = tbody.append('tr')
  
          tbodykeys.forEach((k, i) => {
  
            if (list[r][k.key] == disc) return
            else {
              if (i == 0) {
                disc = list[r][k.key]
              }
            }
  
            let td = tr.append('td')
              .style('border', 'solid 0.1px #E0E0E0')
              .style('height', '21px')
              .style('text-align', k.align ? k.align : 'center')
              .style('padding', '10px')
              .attr('rowspan', () => {
                if (i == 0) {
                  let filtered = list.filter(d => d.CATEGORY == list[r][k.key])
                  return filtered.length
                }
              })
  
            // if(i === 1) {
            //   td.style('white-space', 'nowrap')
            //     .style('overflow', 'hidden')
            //     .style('text-overflow', 'ellipsis')
            //     .attr('title', list[r][k.key])
            // }
  
            // if(i === 4) {
            //   td.style('color', list[r][k.key] > 0 ? '#1E88E5' : '#000')
            //     .attr('title', list[r][k.key])
            // }
            // if(i === 5) {
            //   td.style('color', list[r][k.key] < 0 ? 'red' : list[r][k.key] > 0 ? 'green' : '#000')
            //     .attr('title', list[r][k.key])
            // }
  
            // td.on('mouseover', () => tr.style('cursor', 'pointer'))
            // td.on('click', () => {
            //   // console.log(list[r].GWBS_LV3,list[r].WCCODE)
            //   let dataSource = {
            //     GWBS_LV3: list[r].GWBS_LV3,
            //     WCCODE: list[r].WCCODE
            //   }
            //   let request_ = this.getRequestColumnProps('status', dataSource, this.ColumnProps)
            //   this.$emit('request-action', request_)
            // })
  
            if(k.key.includes('PROG')) {
              addClass = ''
              td
              .style('position', 'relative')
              .style('color', list[r][k.key] < 0 ? 'red' : list[r][k.key] === 100 ? '#fff' : addClass == 'caution' ? 'red' : '#000')
              .style('z-index', '1')
              .html(list[r][k.key] == null ? 0+'%' :list[r][k.key]+'%')
  
              td.append('div')
              .style('position', 'absolute')
              .style('left', 0)
              .style('top', 0)
              .style('width', list[r][k.key] > 0 ? list[r][k.key] + '%' : 0)
              .style('height', '20px')
              .style('background-color', list[r][k.key] < 100 ? k.key.includes('PLAN') ? '#E0E0E0' : '#FFCA28' : '#4CAF50')
              .style('z-index', '-1')
            }
            else {
              if (k.type == 'float') {
                let regexp = /\B(?=(\d{3})+(?!\d))/g
                if (isNaN(Math.floor(list[r][k.key]))) td.html('')
                else td.html(Math.round(Math.floor(list[r][k.key] * 100) / 100).toString().replace(regexp, ','))
              } else td.html(list[r][k.key])
            }
          })
        }
    },
  }
}
