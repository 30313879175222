import * as d3 from 'd3'

export default {
  data: () => ({
    dataSet: [],
    // Scales
    scaleXInfo: {
      scale: null,
      tickValues: []
    },
    scaleYInfo: {
      scale: null,
      tickValues: []
    },
    scaleChartVGroup: {
      groupScale: null,
      barScale: null
    },

    // Common
    setThickness: 0,
    setDistance: 0,
  }),
  computed: {
    ready2draw() {

      // console.log('------------------------------')
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)

      return (
        // this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },
  methods: {
    setDefaultValues(){
    
        this.dataSet = JSON.parse(JSON.stringify(this.DataItems))
        this.dataSet.forEach(data => {
         //
        })
    },
    setScaleInfo() {
      if (this.DataItems.length === 0) return

      this.setThickness = 0

      let dataLength = this.DataItems.length

        this.setScaleXInfo(this.scaleXInfo, this.Canvas.CanvasChartWidth)
        this.setScaleYInfo(this.scaleYInfo, this.Canvas.CanvasChartHeight)
        
    },
    setScaleXInfo(_scaleXY, _chartWH) {
      let dataLength = this.DataItems.length
      // The value's key name should be 'title' or others,
      // or the first column must be value?
      // Need to be regulated.
      let valueKeyName = 'TRANSPORT_COMPANY'
      _scaleXY.tickValues = this.DataItems.map(item => item[valueKeyName])

      if (this.Bar.BarAutoSize == 'Y') var scaleXRangeMax_ = _chartWH
      else scaleXRangeMax_ = (
        dataLength * (this.Bar.BarThickness + this.Bar.BarDistance) - this.Bar.BarDistance +
        this.Canvas.CanvasChartSpace * 2 -
        this.Canvas.CanvasChartSpace
      )

      _scaleXY.scale = d3.scaleBand()
        .domain(_scaleXY.tickValues)
        .rangeRound([0, scaleXRangeMax_])
        // .paddingInner((this.Bar.BarAutoSize == 'Y' ? this.Canvas.CanvasChartWidth / dataLength : this.Bar.BarDistance) / 100)
        .paddingInner(this.Bar.BarDistance / 100)
        .paddingOuter(this.Canvas.CanvasChartSpace / 50)
        // console.log(_scaleXY.tickValues)
        // console.log(scaleXRangeMax_)
      /* Reassigne the default value */
      this.setThickness = this.Bar.BarAutoSize == 'Y' ? _scaleXY.scale.bandwidth() : this.Bar.BarThickness

    },
    setScaleYInfo(_scaleXY, _chartWH) {
      // The value's key name should be 'value' or others,
      // or the second column must be value?
      // Need to be regulated.
      let targetValues_ = this.setTargetValues()


      // ...targetValues_ : enumerates the values out of the array, 
      // ex) [1, 2, 3, 4, 5] => 1, 2, 3, 4, 5
      let maxDataValue_ = this.DataItems.length === 0 ? 9 : Math.max(...targetValues_)
      let maxBoundValue_ = this.Axis.AxisYBoundsAuto == 'Y' ? this.getMaxBound(maxDataValue_) : this.Axis.AxisYBoundsMax

      // Portion of the increment for each
      let portionY = Math.round(maxBoundValue_ / 5)
      // console.log(targetValues_)
      // Calculate the data values for each of the Grid-Lines, 6, 
      // 0 to maxBoundValue_.
      _scaleXY.tickValues = Array.from({ length: 6 }, (_, i) => (i * portionY))

      // This Y Scale, calculated by the data values, will be used
      // wherever the Y Coordinates are needed.
      _scaleXY.scale = d3
        .scaleLinear()
        .domain([0, maxBoundValue_])
        .rangeRound([_chartWH, 0])

        // console.log(this.getMaxBound(maxDataValue_))
        // console.log(maxBoundValue_)
        // console.log(_chartWH)
    },
    setTargetValues() {

      // if (this.ChartType.includes('Stack')) {
        let targetValues_ = []
        this.DataItems.map(function (item) {
          targetValues_.push(Object.values(item).splice(1).reduce((a, b) => a + b, 0))
        })
        return targetValues_
      // }

      // let valueKeyName = 'value1'
      // return this.DataItems.map(item => item[valueKeyName])
    },
    setScaleBarGroup() {

      let dataLength = this.DataItems.length
      let keys_ = Object.keys(this.DataItems[0]).splice(1)

      if (this.Bar.BarAutoSize == 'Y') var scaleXRangeMax_ = this.Canvas.CanvasChartWidth
      else scaleXRangeMax_ = (
        dataLength * ((this.Bar.BarThickness + (this.Bar.BarThickness / 100)) * keys_.length) +
        this.Bar.BarDistance * dataLength +
        this.Canvas.CanvasChartSpace * 2
      )

      this.scaleChartVGroup.groupScale = d3.scaleBand()
        .domain(this.DataItems.map(d => d.WORK_TYPE_CODE))
        .rangeRound([0, scaleXRangeMax_])
        .paddingInner(this.Bar.BarDistance / 100)
        .paddingOuter(this.Canvas.CanvasChartSpace / 100)

      this.scaleChartVGroup.barScale = d3.scaleBand()
        .domain(keys_)
        .rangeRound([0, this.scaleChartVGroup.groupScale.bandwidth()])
        .paddingInner(0.1)

    },
  }
}