import * as d3 from 'd3'

export default {
  data: () => ({
    dataSet: [],
    defaultset: { 
      no:347,
      width: 1360,
      height: 46,
      color: '#d2e5fe',
      linkIcon: 'N',
      iconX: 915,
      iconY: 2.5,
      iconW: 20,
      iconH: 18,
      filer: 'Y',
      fX: '10',
      fY: '0',
      fFontSize: 13,
      fColor: '#000',
    },
    attrs: [
      { 
        text: '',
        refColumn: 'DEV_GROUP',
        fColor: '#616161', 
        tColor: '#ffffff',
        xLocation:0,
      },
      { 
        text: 'Issued',
        refColumn: 'ISSUED',
        fColor: '#D50000', 
        tColor: '#ffffff',
        xLocation:235,
      },
      { 
        text: 'Responded',
        refColumn: 'RESPONDED',
        fColor: '#ffffff', 
        tColor: '#424242',
        xLocation:426,
      },
      { 
        text: 'Total Open',
        refColumn: 'OPEN',
        fColor: '#ffffff', 
        tColor: '#424242',
        xLocation:605,
      },
      { 
        text: 'Not Overdue',
        refColumn: 'OPEN_NOT_OVERDUE',
        fColor: '#ffffff', 
        tColor: '#424242',
        xLocation:790,
      },
      { 
        text: 'Overdue',
        refColumn: 'OPEN_OVERDUE',
        fColor: '#ffffff', 
        tColor: '#424242',
        xLocation:956,
      },
      { 
        text: 'Cancelled',
        refColumn: 'CANCELLED',
        fColor: '#ffffff', 
        tColor: '#424242',
        xLocation:1140,
      },
    ],
  }),
  computed: {
    ready2draw() {

      // console.log('------------------------------')
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)

      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },
  methods: {
    setDefaultValues(){
    
        this.dataSet = JSON.parse(JSON.stringify(this.DataItems))
        
        this.dataSet.forEach(data => {
         //
        })
  
    },
 
    
  }
}