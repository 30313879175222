import * as d3 from 'd3'

export default {
  data: () => ({
    previewW: 330,
    previewH: 300,
    layerPopup: null,
    stylePopup: {
      taginfo: [
        { label: 'PO'               , textid: 'value_po'    , colName: { plan: 'PO_PLAN'            , actual: 'PO_ACTUAL' }, },
        { label: 'FAT'              , textid: 'value_fat'   , colName: { plan: 'FAT_PLAN'           , actual: 'FAT_ACTUAL' }, },
        { label: 'IRN'              , textid: 'value_irn'   , colName: { plan: 'IRN_PLAN'           , actual: 'IRN_ACTUAL' }, },
        { label: 'ON Site'          , textid: 'value_eta'   , colName: { plan: 'ETA_PLAN'           , actual: 'ETA_ACTUAL' }, },
        { label: 'ROS'              , textid: 'value_ros'   , colName: { plan: 'ROS'                , actual: '' }, },
        { label: 'Upper Block Erct.', textid: 'value_berct' , colName: { plan: 'UPPERBLOCK_ERECTION', actual: '' }, },
        // { label: 'Installation'     , textid: 'value_inst'  , colName: { plan: ''                   , actual: 'INSTALL' }, },
      ]
    }
  }),
  methods: {
    drawEQPopup() {
      this.layerPopup = this.svg
      .append('g')
      .attr('id', 'layer_eq_popup')
      .style('visibility', 'hidden')

      this.layerPopup
      .append('rect')
      .attr('class', '_overlay_eq_popup')
      .attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight)
      .attr('fill', '#fff')
      .style('opacity', 0)
      .on('click', this.closeEQPopup)

      let popup = this.layerPopup
      .append('g')
      .attr('class', '_popup_box')
      .attr('transform', `translate(718, 112)`)
      .call(this.popup2drag)
      .call(this.setPopupCurMoveing)

      popup
      .append('rect')
      .attr('class', '_popup_box_bg')
      .attr('width', 685)
      .attr('height', 383)
      .attr('fill', '#fff')
      .attr('rx', 3)
      .attr('ry', 3)
      .style('filter', 'url(#drop_shadow_eq_popup)')

      popup
      .append('text')
      .attr('transform', `translate(6.5, 12)`)
      .style('font-family', 'roboto')
      .style('font-size', 10)
      .style('fill', '#959595')
      .text('EQUIPMENT OVERVIEW')

      popup
      .append('text')
      .attr('transform', `translate(342.5, 14)`)
      .style('font-family', 'roboto')
      .style('font-size', 9)
      .style('fill', '#e5e5e5')
      .style('cursor', 'pointer')
      .attr('text-anchor', 'middle')
      .text('DRAG TO MOVE')

      popup
      .append('text')
      .attr('transform', `translate(670, 14)`)
      .style('font-family', 'roboto')
      .style('font-size', 12)
      .style('fill', '#000')
      .style('cursor', 'pointer')
      .text('×')
      .on('mouseorver', (_, i, a) => { d3.select(a[i]).style('fill', '#999') })
      .on('mouseout', (_, i, a) => { d3.select(a[i]).style('fill', '#000') })
      .on('click', this.closeEQPopup)

      // day information
      let daystatus = popup
      .append('g')
      .attr('class', '_day_status_group')
      .attr('transform', `translate(6.5, 30.5)`)

      this.drawDayStatus(daystatus)

      // tag information
      let taginfo = popup
      .append('g')
      .attr('class', '_tag_info_group')
      .attr('transform', `translate(6.5, 137.5)`)

      this.drawTagInfo(taginfo)

      // preview
      let preview = popup
      .append('g')
      .attr('class', '_preview_group')
      .attr('transform', `translate(348.5, 30.5)`)

      this.draw3DPreview(preview)
    },
    drawDayStatus(selection) {
      selection
      .append('rect')
      .attr('width', this.previewW)
      .attr('height', 95)
      .attr('rx', 4)
      .attr('ry', 4)
      .attr('stroke', '#dadada')
      .attr('stroke-width', .5)
      .style('fill', '#fff')

      selection
      .append('rect')
      .attr('transform', `translate(5, -3)`)
      .attr('width', 40)
      .attr('height', 10)
      .style('fill', '#fff')

      selection
      .append('text')
      .attr('transform', `translate(10, 3)`)
      .style('font-family', 'roboto')
      .style('font-size', 11)
      .style('fill', '#000')
      .text('Status')

      selection
      .append('image')
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/icon/svg/time.svg`)
      .attr('transform', `translate(28, 25)`).style('width', 40)

      selection
      .append('rect')
      .attr('transform', `translate(100.5, 15.5)`)
      .attr('width', 108)
      .attr('height', 24)
      .attr('rx', 3)
      .attr('ry', 3)
      .style('fill', '#e0e0df')

      selection
      .append('text')
      .attr('transform', `translate(154, 28)`)
      .style('font-family', 'roboto')
      .style('font-size', 12)
      .style('fill', '#333')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text('ROS-ETA')

      selection
      .append('text')
      .attr('id', 'value_ros_eta')
      .attr('transform', `translate(250, 30)`)
      .style('font-family', 'roboto')
      .style('font-size', 24)
      .style('fill', '#f36f9b')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text('-10')

      selection
      .append('text')
      .attr('transform', `translate(300, 30)`)
      .style('font-family', 'roboto')
      .style('font-size', 12)
      .style('fill', '#44ade3')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text('days')

      selection
      .append('rect')
      .attr('transform', `translate(100.5, 55.5)`)
      .attr('width', 108)
      .attr('height', 24)
      .attr('rx', 3)
      .attr('ry', 3)
      .style('fill', '#e0e0df')

      selection
      .append('text')
      .attr('transform', `translate(154, 69)`)
      .style('font-family', 'roboto')
      .style('font-size', 12)
      .style('fill', '#333')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text('Upper BLK - ETA')

      selection
      .append('text')
      .attr('id', 'value_upper_blk_eta')
      .attr('transform', `translate(250, 69)`)
      .style('font-family', 'roboto')
      .style('font-size', 24)
      .style('fill', '#44ade3')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text('20')

      selection
      .append('text')
      .attr('transform', `translate(300, 69)`)
      .style('font-family', 'roboto')
      .style('font-size', 12)
      .style('fill', '#44ade3')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text('days')
    },
    drawTagInfo(selection) {
      selection
      .append('rect')
      .attr('width', this.previewW)
      .attr('height', 238)
      .attr('rx', 4)
      .attr('ry', 4)
      .attr('stroke', '#dadada')
      .attr('stroke-width', .5)
      .style('fill', '#fff')
      
      selection
      .append('rect')
      .attr('transform', `translate(5, -3)`)
      .attr('width', 90)
      .attr('height', 10)
      .style('fill', '#fff')

      selection
      .append('text')
      .attr('transform', `translate(10, 3)`)
      .style('font-family', 'roboto')
      .style('font-size', 11)
      .style('fill', '#000')
      .text('Tag Information')

      let x = 110

      selection
      .append('text')
      .attr('transform', `translate(${x}, 20)`)
      .style('font-family', 'roboto')
      .style('font-size', 10)
      .style('fill', '#bbb')
      .attr('text-anchor', 'end')
      .attr('alignment-baseline', 'middle')
      .text('TAG No.')

      selection
      .append('text')
      .attr('id', 'value_tag_no')
      .attr('transform', `translate(120, 20)`)
      .style('font-family', 'roboto')
      .style('font-size', 11)
      .style('fill', '#333')
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .text('21-HAH-997101')

      selection
      .append('text')
      .attr('transform', `translate(${x}, 38)`)
      .style('font-family', 'roboto')
      .style('font-size', 10)
      .style('fill', '#bbb')
      .attr('text-anchor', 'end')
      .attr('alignment-baseline', 'middle')
      .text('TAG Description')

      selection
      .append('text')
      .attr('id', 'value_tag_descr')
      .attr('transform', `translate(120, 38)`)
      .style('font-family', 'roboto')
      .style('font-size', 11)
      .style('fill', '#333')
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .text('21-HAH-99710121-HAH-997101')

      selection
      .append('text')
      .attr('transform', `translate(${x}, 56)`)
      .style('font-family', 'roboto')
      .style('font-size', 10)
      .style('fill', '#bbb')
      .attr('text-anchor', 'end')
      .attr('alignment-baseline', 'middle')
      .text('Room Info.')

      selection
      .append('text')
      .attr('id', 'value_room')
      .attr('transform', `translate(120, 56)`)
      .style('font-family', 'roboto')
      .style('font-size', 11)
      .style('fill', '#333')
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .text('M103 SEWAGE TREATMENT ROOM')

      selection
      .append('text')
      .attr('transform', `translate(${x}, 74)`)
      .style('font-family', 'roboto')
      .style('font-size', 10)
      .style('fill', '#bbb')
      .attr('text-anchor', 'end')
      .attr('alignment-baseline', 'middle')
      .text('Method')

      selection
      .append('text')
      .attr('id', 'value_method')
      .attr('transform', `translate(120, 74)`)
      .style('font-family', 'roboto')
      .style('font-size', 11)
      .style('fill', '#333')
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .text('Direct')

      selection
      .append('text')
      .attr('transform', `translate(${x}, 92)`)
      .style('font-family', 'roboto')
      .style('font-size', 10)
      .style('fill', '#bbb')
      .attr('text-anchor', 'end')
      .attr('alignment-baseline', 'middle')
      .text('Supply by')

      selection
      .append('text')
      .attr('id', 'value_supply')
      .attr('transform', `translate(120, 92)`)
      .style('font-family', 'roboto')
      .style('font-size', 11)
      .style('fill', '#333')
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .text('?????')
      
      let xPlan = 150
      let xActual = 220
      let yStart = 120
      let lineHeight = 18

      // Tag status list
      selection
      .append('text')
      .attr('transform', `translate(${xPlan}, 106)`)
      .style('font-family', 'roboto')
      .style('font-size', 9)
      .style('fill', '#bbb')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text('Plan')
      
      selection
      .append('text')
      .attr('transform', `translate(${xActual}, 106)`)
      .style('font-family', 'roboto')
      .style('font-size', 9)
      .style('fill', '#bbb')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text('Actual')

      this.stylePopup.taginfo.forEach((style, i) => {
        selection
        .append('text')
        .attr('transform', `translate(${x}, ${lineHeight * i + yStart})`)
        .style('font-family', 'roboto')
        .style('font-size', 10)
        .style('fill', '#bbb')
        .attr('text-anchor', 'end')
        .attr('alignment-baseline', 'middle')
        .text(style.label)

        if(style.colName.plan) selection
          .append('rect')
          .attr('transform', `translate(${xPlan - 32.5}, ${lineHeight * i + yStart - 8})`)
          .attr('width', 65)
          .attr('height', 15)
          .style('fill', '#f5f5f5')

        if(style.colName.actual) selection
          .append('rect')
          .attr('transform', `translate(${xActual - 32.5}, ${lineHeight * i + yStart - 8})`)
          .attr('width', 65)
          .attr('height', 15)
          .style('fill', '#f5f5f5')

        selection
        .append('text')
        .attr('id', `${style.textid}_plan`)
        .attr('transform', `translate(${xPlan}, ${lineHeight * i + yStart})`)
        .style('font-family', 'roboto')
        .style('font-size', 10)
        .style('fill', '#44a9df')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(style.colName.plan ? '2020-08-09' : '')

        selection
        .append('text')
        .attr('id', `${style.textid}_actual`)
        .attr('transform', `translate(${xActual}, ${lineHeight * i + yStart})`)
        .style('font-family', 'roboto')
        .style('font-size', 10)
        .style('fill', '#44a9df')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(style.colName.actual ? '2020-08-09' : '')
      })
    },
    draw3DPreview(selection) {
      selection
      .append('rect')
      .attr('transform', `translate(0, 9)`)
      .attr('width', this.previewW)
      .attr('height', this.previewH)
      .attr('rx', 4)
      .attr('ry', 4)
      .attr('stroke', '#dadada')
      .attr('stroke-width', .5)
      .style('fill', '#fff')

      selection
      .append("foreignObject")
      .attr('clip-path', 'url(#mask_eq3d_viewer_)')
      .attr('transform', `translate(.5, 9.5)`)
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', this.previewW)
      .attr('height', this.previewH)
      .append('xhtml:iframe')
      .attr('id', 'eq_3d_viewer')
      .attr('width', this.previewW)
      .attr('height', this.previewH)
      .attr('frameBorder', 0)
      .attr('scrolling', 'no')
      .attr('style', 'border: none;')

      selection
      .append('text')
      .attr('transform', `translate(10, 3)`)
      .style('font-family', 'roboto')
      .style('font-size', 11)
      .style('fill', '#000')
      .text('3D Preview')

      // selection
      // .append('rect')
      // .attr('transform', `translate(250, -8)`)
      // .attr('width', 80)
      // .attr('height', 16)
      // .attr('rx', 4)
      // .attr('ry', 4)
      // .attr('stroke', '#f36f9b')
      // .attr('stroke-width', .5)
      // .style('fill', '#fff')

      // selection
      // .append('text')
      // .attr('transform', `translate(325, 3)`)
      // .style('font-family', 'roboto')
      // .style('font-size', 11)
      // .attr('text-anchor', 'end')
      // .style('fill', '#f36f9b')
      // .text('Revert to First')

      selection
      .append('text')
      .attr('transform', `translate(10, 327)`)
      .style('font-family', 'roboto')
      .style('font-size', 10)
      .style('fill', '#bbb')
      .attr('alignment-baseline', 'middle')
      .text('Dimension')

      selection
      .append('text')
      .attr('id', 'value_dimension')
      .attr('transform', `translate(70, 327)`)
      .style('font-family', 'roboto')
      .style('font-size', 11)
      .style('fill', '#333')
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .text('123 x 345 x 678')

      selection
      .append('text')
      .attr('transform', `translate(180, 327)`)
      .style('font-family', 'roboto')
      .style('font-size', 10)
      .style('fill', '#bbb')
      .attr('alignment-baseline', 'middle')
      .text('Weight')

      selection
      .append('text')
      .attr('id', 'value_weight')
      .attr('transform', `translate(220, 327)`)
      .style('font-family', 'roboto')
      .style('font-size', 11)
      .style('fill', '#333')
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .text('54.23t')
    },
    openEQPopup(tag) {
      let d = this.dataSet.find(d => d.TAG == tag)
      let popup = d3.select('#layer_eq_popup')
      
      popup.style('visibility', 'visible')
      popup.moveToFront()
      popup.select('#value_tag_no').text(d.TAG)
      popup.select('#value_tag_descr').text(d.TAG_DESCR)
      popup.select('#value_room').text(d.ROOM)
      popup.select('#value_method').text(d.METHOD)
      popup.select('#value_supply').text(d.PROCUREDBY)
      popup.select('#value_ros_eta').text(d.ROS_ETA_DELTA)
      popup.select('#value_upper_blk_eta').text(d.ETA_UPPERBLOCK_DELTA)
      popup.select('#value_dimension').text(d.DIMENSION == null ? 'N/A' : d.DIMENSION)
      popup.select('#value_weight').text(d.WEIGHT == null ? 'N/A' : d.WEIGHT)
      
      this.stylePopup.taginfo.forEach(style => {
        let plan = style.colName.plan ? d[style.colName.plan] : ''
        let actual = style.colName.actual ? d[style.colName.actual] : ''
        popup.select(`#${style.textid}_plan`).text(plan)
        popup.select(`#${style.textid}_actual`).text(actual)
      })
      d3.select('#eq_3d_viewer').attr('src', `${this.__HOST_NAME}/#/pub_3d_viewer/token/${d.TAG}/{"d":"eq","w":330,"h":300}`)
    },
    closeEQPopup() {
      d3.select('#layer_eq_popup').style('visibility', 'hidden')
    }
  }
}