import Defs from '../../../../../../includes/primitives/Color_Defs'
import Data from '../../../../../../includes/primitives/Color_Data'

import * as d3 from 'd3'

export default {
  data: () => ({
    packages: [],
    areas: [],
    phases: [],
    collapsed: {},
    
    svgGroup: null,
    selectedItems: null,
    getItems: null,

    collapsedObj: {},
    collapsedAry: [],
    collFlag: false,
    drawFlag: false,

  }),
  computed: {

    ready2draw() {

      // console.log('------------------------------')
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)

      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },
  methods: {
    initValues() {

      //console.log('INIT SQL DATAS: ', this.Queries.SQL1[0])

      this.packages = this.Queries.SQL1[0].PACKAGES
      this.areas = this.Queries.SQL1[0].AREAS
      this.phases = this.Queries.SQL1[0].PHASES

      this.collapsed = {}

      this.packages.forEach(p => {
        this.collapsed[p.P6_LV1] = true

        if (!this.areas) return
        this.areas.filter(a => a.P6_LV1 == p.P6_LV1).forEach(a => {
          this.collapsed[`${a.P6_LV1}${a.P6_LV2}`] = false

          if (!this.phases) return
          this.phases.filter(ph => ph.P6_LV1 == a.P6_LV1 && ph.P6_LV2 == a.P6_LV2).forEach(ph => {
            this.collapsed[`${ph.P6_LV1}${ph.P6_LV2}${ph.P6_LV3}`] = false
          })
        })
      })

      this.timeStartDate = this.Queries.SQL1[0].START_DATE
      this.timeEndDate = this.Queries.SQL1[0].END_DATE
      this.cutoff = this.Queries.SQL1[0].CUTOFF

    },
    loadSvg(x, y, path) {
      
      // this.loadSvg(70, 85, '/TORTUE/Milestones/Legends.svg')
      return d3.xml(`${this.__HOST_NAME_RESOURCE}${path}`).then(source => {
        let innerSvg = source.documentElement
        this.svg.node().append(innerSvg)
        innerSvg = d3.select(innerSvg)

        innerSvg
        .attr('x', x)
        .attr('y', y)

        return new Promise(resolve => {
          resolve(source)
        })
      })
    },
    setGradient(){

      let lightBlue = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `shadowTimeline`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      lightBlue.append('stop').attr('stop-color', '#3d3d3d').attr('offset', '0').attr('stop-opacity', 0.5)
      lightBlue.append('stop').attr('stop-color', '#fff').attr('offset', '1').attr('stop-opacity', 0.3)
      
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['LightGray'], [`LightGray`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['YellowGreen'], [`YellowGreen`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['LightBlue'], [`LightBlue`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['Pink'], [`Pink`, 0.5, 1.0, 0.5, -0.2], this.localId)
    },

    setDefaultValues(){
      // Append Overall svg group
      let svg = this.svg
      .append('g')
      .attr('id', '__svg_group_')
      .attr('transform', 'translate(0.1, 0.1)')
      this.svgGroup = svg

      // init y_coordinate
      this.y_coordinate = 0
    },
  }
}