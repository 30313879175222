import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {

    Draw_Charts() {
      
      let data = null, 
          style = null, 
          cellX = null, 
          tmp = null, 
          Circle = null, 
          Guideline = null,
          item = null

      let boundary = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0.5)`)


      boundary
      .append('image')
      .attr('id', `svg_${this.System}`)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/Visualization/SystemBoundary/svg/${this.System}.svg`)
      .attr('transform', `translate(100,0)`).style('width', 700).style('opacity', 0)
      d3.select(`#svg_${this.System}`).transition().delay(400).duration(500).style('opacity', 1)

    },
  }
}
           