import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  data: () => ({
    style : {
      x: 10,
      y: 60,
      stX: 30,
      barLen: 180,
      width: 28,
      space:28,
      opacity:  1,
    },
    attrs: [
      { text: 'Total',refColumn: 'TOTAL',fColor: '#E0E0E0', tColor: '#9E9E9E'},
      { text: '2weeks',refColumn: 'WEEKS2',fColor: '#C8E6C9',tColor: '#66BB6A'},
      { text: '4weeks',refColumn: 'WEEKS4',fColor: '#FFECB3', tColor: '#FFB300'},
      { text: '6weeks',refColumn: 'WEEKS6',fColor: '#FFD54F', tColor: '#FFA000'},
      { text: '8weeks',refColumn: 'WEEKS8',fColor: '#FFB74D', tColor: '#EF6C00'},
      { text: '10weeks',refColumn: 'WEEKS10',fColor: '#FFCDD2', tColor: '#E57373'},
      { text: '12weeks',refColumn: 'WEEKS12',fColor: '#EF9A9A', tColor: '#D32F2F'},
      { text: '>12weeks',refColumn: 'OVER_12WEEKS',fColor: '#E57373', tColor: '#B71C1C'},
    ],
    width: 52,
    gab: 10,
    lineWidth:430
  }),
  methods: {

    
    Draw_Charts() {
      if(!this.DataItems || this.DataItems.length === 0) return

      let values_ = this.DataItems[0]
      let overallPunchStatus = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0)`)

      let maxValue = Math.max(...this.DataItems.map(d => d.TOTAL))
      maxValue = this.getMaxBound(maxValue)

      //Scale for the radius
      let barScale = d3.scaleLinear()
      .domain([0, maxValue])
      .range([this.style.barLen, 0])

      let overallPunch = this.svg
      .append('g')
      .attr('transform', `translate(${-5}, ${this.style.y})`)

      overallPunch
      .append('text')
      .attr('transform', `translate(${this.lineWidth/2}, ${-40})`)
      .style('font-family', 'roboto')
      .style('font-size', 15)
      .style('fill', '#424242')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text(values_.MONTH)
      
      for(var i=0; i<6; i++) {
        overallPunch
        .append('line')
        .attr('x1', this.style.stX - 5).attr('x2', this.lineWidth)
        .attr('y1', barScale((maxValue/5)*i)).attr('y2', barScale((maxValue/5)*i))
        .attr('stroke', '#9E9E9E')
        .attr('stroke-width', 0.2)

        overallPunch
        .append('text')
        .attr('transform', `translate(${this.style.stX - 2}, ${barScale((maxValue/5)*i)})`)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#9E9E9E').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text((maxValue/5)*i)
      }
      overallPunch
      .append('line')
      .attr('x1', this.style.stX - 5).attr('y1', this.style.barLen).attr('x2', this.lineWidth).attr('y2', this.style.barLen)
      .attr('stroke', '#757575')
      .attr('stroke-width', 0.3)
      
      
      this.attrs.forEach((attr, i) => {
        // console.log(attr)
        let x = this.style.stX
        let x__ = this.width * i + this.gab
        let donut_ = overallPunchStatus
        .append('g')
        .attr('class', `_donut_chart__${attr.refColumn.toLowerCase()}`)
        .attr('transform', `translate(${x__+5}, 60)`)

        donut_
        .append('path')
        .attr('d', `M ${x}, ${this.style.barLen}
                    V ${barScale((values_[attr.refColumn]))}
                    H ${x + this.style.width}
                    V ${this.style.barLen} Z
                  `
        ).style('fill', attr.fColor).style('stroke', attr.tColor).style('stroke-width', 0.5).style('opacity',1)

        donut_
        .append('text')
        .attr('transform', `translate(${x + (this.style.width/2)}, ${barScale((values_[attr.refColumn])) - 6})`)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', attr.tColor).attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text(`${(values_[attr.refColumn])}`)

        donut_
        .append('rect')
        .attr('transform', `translate(${x - (this.style.width/2 )}, ${this.style.barLen})`)
        .attr('width', this.width).attr('height', 25).style('fill', attr.fColor)

        donut_
        .append('text')
        .attr('transform', `translate(${x + (this.style.width/2)}, ${this.style.barLen+15})`)
        .style('font-family', 'roboto')
        .style('font-size', 10)
        .style('fill', '#212121')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(attr.text)
      })
      
    },
  }
}
