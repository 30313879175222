import * as d3 from 'd3'
// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  data:() => ({
    thead: [
      
      { key: 'SCOPE', html: 'Scope', row: 1,},
      { key: 'PLAN_CUM', html: 'Plan', row: 1,colspan: 2, color: '#90CAF9' },
      { key: 'ACTUAL_CUM', html: 'Actual', row: 1,colspan: 2, color: '#F48FB1'},
      { key: 'REMAIN', html: 'Rem.', row: 1, color: '#FFF176' },
      { key: 'TOTAL', html: 'Total', row: 1},
    ],
    theadrows: 1,
    tbody: [
      
      { key: 'SCOPE', w: 50, align: 'center'},
      { key: 'PLAN_CUM', w: 50, align: 'left'},
      { key: 'PLAN_CUM_PROG', w: 50, align: 'left'},
      { key: 'ACTUAL_CUM', w: 50, align: 'left'},
      { key: 'ACTUAL_CUM_PROG', w: 50, align: 'left'},
      { key: 'REMAIN', w: 50, type: 'float', align: 'center'},
      { key: 'TOTAL', w: 50,type: 'float', align: 'center'},
    ],

    selectedStartDate: null,
    selectedStartColumn: null,

    selectedFinishDate: null,
    selectedFinishColumn: null,

    filteredList: null,
  }),
  methods: {
    Draw_Charts() {
      
      if(this.ColumnProps.length > 0) var props = this.ColumnProps.find(props_ => props_.value == 'comprops').props
      else props = this.defaultset

      // let propWidth = propsWidth/8
      // let propsWidth = 645
      let propWidth = props.width/8

      let groupX = 0
      let Summary = this.svg
      .append('g')
      .attr('id', 'summary_wrap')
      .attr('transform', `translate(0.5,0.5)`).style('font-family', 'roboto')

      let values_ = this.DataItems[0]

      Summary
      .append('text')
      .attr('transform', `translate(0, 10)`)
      .style('font-size',11).attr('text-anchor', 'start').attr('fill', '#424242').style('font-weight','bold')
      .text(values_.ITEM)

      Summary
      .append('text')
      // .attr('transform', `translate(${props.width-10-(values_.UOM.length == 3 ? values_.UOM.length*7.6 :values_.UOM.length > 11 ? values_.UOM.length*6 : values_.UOM.length > 16 ? values_.UOM.length*3 : values_.UOM.length > 21 ? values_.UOM.length*7.2: values_.UOM.length*6)}, 10)`)
      .attr('transform', `translate(${props.width-10-(values_.UOM.length <= 3 ? values_.UOM.length*7.6 :values_.UOM.length*5.6)}, 10)`)
      .style('font-size',11).attr('text-anchor', 'end').attr('fill', '#424242')
      .text('Unit : ')
      Summary
      .append('text')
      .attr('transform', `translate(${props.width-10}, 10)`)
      .style('font-size',11).attr('text-anchor', 'end').attr('fill', '#424242')
      .text(values_.UOM)

      Summary
      .append('line')
      .attr('x1', 0).attr('y1', 16).attr('x2', props.width-10).attr('y2', 16).style('stroke', '#bcbcbc').style('stroke-width', 0.5)
      
      //------------------------------------------------------summart inner
      const barScale = d3.scaleLinear()
      .domain([0,100])
      .range([0,propWidth])

      let regexp = /\B(?=(\d{3})+(?!\d))/g

      this.attrs.forEach((attr,i) => {
        let SummaryGroup = Summary
        .append('g').attr('class', 'SummaryGroup').attr('transform', `translate(${groupX}, 30)`)
        
        SummaryGroup
        .append('text')
        .attr('transform', `translate(${(propWidth*i+propWidth/2)}, 0)`)
        .style('font-size', 10).style('fill', '#212121').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text(attr.text)

        SummaryGroup
        .append('rect').attr("rx", 2).attr('x', `${propWidth*i}`).attr('y', 10)
        .attr('width', propWidth).attr('height', 17)
        .attr('stroke', attr.text =='Remain Qty'? '#D50000':'#1A237E').attr('stroke-width', 0.3).attr('fill', attr.fColor).style('opacity',.8)

        groupX +=6

        if(attr.text =='Complete%'){
          SummaryGroup
          .append('rect').attr('rx', 2).attr('x', propWidth*3).attr('y', 10)
          .attr('width', 0).attr('height', 17)
          .attr('fill',values_.PROG == 100 ? '#43A047' : '#FFEB3B')

          .transition().duration(150)
          .attr('width',(barScale(values_.PROG)))
        }
        SummaryGroup
        .append('text')
        .attr('transform', `translate(${(propWidth*i)+propWidth/2}, ${20})`)
        .style('font-size', 10).style('fill', attr.text =='Complete%' && values_.PROG ==100 ?'#ffffff': attr.tColor).attr('text-anchor','middle').attr('alignment-baseline', 'middle')
        .text(
          values_[attr.refColumn] != undefined? (attr.text =='Complete%' ? Number(values_[attr.refColumn]).toFixed(1) +'%' :Number(values_[attr.refColumn]).toString().replace(regexp, ',')):''
          )
      })

      let SummaryGroup2Wrap = Summary
      .append('g').attr('class', 'SummaryGroup2Wrap').attr('transform', `translate(${propWidth*4+3}, 30)`)

      SummaryGroup2Wrap
      .append('rect').attr('rx', 2).attr('x', 24).attr('y', 8)
      .attr('width', propWidth*3.5).attr('height', 20)
      .attr('fill','#FFF3E0')

      SummaryGroup2Wrap
      .append('rect').attr('rx', 2).attr('x', 24).attr('y', 28)
      .attr('width', propWidth*3.5).attr('height', 20)
      .attr('fill','#F9FBE7')

      SummaryGroup2Wrap
      .append('text')
      .attr('transform', `translate(${65}, ${20})`).style('font-weight','bold')
      .style('font-size', 10).style('fill','#000000').attr('text-anchor','end').attr('alignment-baseline', 'middle')
      .text('Weekly')

      SummaryGroup2Wrap
      .append('text')
      .attr('transform', `translate(${65}, ${39})`).style('font-weight','bold')
      .style('font-size', 10).style('fill','#000000').attr('text-anchor','end').attr('alignment-baseline', 'middle')
      .text('Cum.')

      this.Wattrs.forEach((Wattr,i)=>{
        let SummaryGroup2 = Summary
        .append('g').attr('class', 'SummaryGroup2').attr('transform', `translate(${propWidth*5.5}, 30)`)

        SummaryGroup2
        .append('text')
        .attr('transform', `translate(${(propWidth*i)}, 0)`)
        .style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text(Wattr.text)

        SummaryGroup2
        .append('text')
        .attr('transform', `translate(${(propWidth*i)}, ${20})`)
        .style('font-size', 10).attr('text-anchor','middle').attr('alignment-baseline', 'middle').attr('fill', Wattr.tColor)
        .text(values_[Wattr.refColumn] != undefined ? Number(values_[Wattr.refColumn]).toString().replace(regexp, ','):'')

        //'▲ ' '▼ '넣기
        // console.log(values_.DELTA_WK.toString().length)
        SummaryGroup2
        .append('text')
        .attr('transform', `translate(${Number(values_.DELTA_WK).toString().length == 2 ? (propWidth*i)-(Number(values_.DELTA_WK).toString().length*8.5) :Number(values_.DELTA_WK).toString().length == 3 ? (propWidth*i)-(Number(values_.DELTA_WK).toString().length*7): Number(values_.DELTA_WK).toString().length == 4 ?(propWidth*i)-(Number(values_.DELTA_WK).toString().length*6) : Number(values_.DELTA_WK).toString().length == 5 ?(propWidth*i)-(Number(values_.DELTA_WK).toString().length*5.5): Number(values_.DELTA_WK).toString().length == 6 ? (propWidth*i)-(Number(values_.DELTA_WK).toString().length*5): (propWidth*i)-(Number(values_.DELTA_WK).toString().length*4.6)}, ${20})`)
        .style('font-size', 10).attr('text-anchor','start').attr('alignment-baseline', 'middle')
        .style('fill', values_.DELTA_WK > 0 ? '#43A047':values_.DELTA_WK === 0 ? '#ccc': '#E53935')
        .text(Wattr.text =='Variation' ? (values_[Wattr.refColumn] > 0 ? '▲ ' :values_[Wattr.refColumn] === 0 ? '' : values_[Wattr.refColumn] < 0 ? '▼ ' :''): '')
      

      })
      this.Cattrs.forEach((Cattr,i)=>{
        let SummaryGroup2 = Summary
        .append('g').attr('class', 'SummaryGroup2').attr('transform', `translate(${propWidth*5.5}, 50)`)

        SummaryGroup2
        .append('text')
        .attr('transform', `translate(${(propWidth*i)}, ${20})`)
        .style('font-size', 10).attr('text-anchor','middle').attr('alignment-baseline', 'middle').attr('fill', Cattr.tColor)
        .text(values_[Cattr.refColumn] != undefined? Number(values_[Cattr.refColumn]).toString().replace(regexp, ','):'')

        SummaryGroup2
        .append('text')
        .attr('transform', `translate(${Number(values_.DELTA_CUM).toString().length == 2 ? (propWidth*i)-(Number(values_.DELTA_CUM).toString().length*8.5) :Number(values_.DELTA_CUM).toString().length == 3 ? (propWidth*i)-(Number(values_.DELTA_CUM).toString().length*7): Number(values_.DELTA_CUM).toString().length == 4 ?(propWidth*i)-(Number(values_.DELTA_CUM).toString().length*6) : Number(values_.DELTA_CUM).toString().length == 5 ?(propWidth*i)-(Number(values_.DELTA_CUM).toString().length*5.5): Number(values_.DELTA_CUM).toString().length == 6 ? (propWidth*i)-(Number(values_.DELTA_CUM).toString().length*5): (propWidth*i)-(Number(values_.DELTA_CUM).toString().length*4.6)}, ${20})`)
        .style('font-size', 10).attr('text-anchor','start').attr('alignment-baseline', 'middle')
        .style('fill', values_.DELTA_CUM > 0 ? '#43A047':values_.DELTA_CUM === 0 ? '#ccc': '#E53935')
        .text(Cattr.text =='Variation' ? (Number(values_[Cattr.refColumn]) > 0 ? '▲ ' :Number(values_[Cattr.refColumn]) === 0 ? '' : Number(values_[Cattr.refColumn]) < 0 ? '▼ ' :''): '')
      
      })


      // this.filteredList = this.DataItems

      // let list = this.filteredList

      // let group = svg.append('g')
      // let table_group = group.append('g').attr('transform', `translate(0.5, 0)`)
      // let tablepx = props.width/7
      // let tableHegith  = (props.width - 20) / 2
      // table_group
      // .append('text')
      // .style('font-size', '10px')
      // .attr('transform', `translate(7,32)`)
      // .style('text-anchor', 'start')
      // .style('fill','#212121')
      // .text('Cum.')
      // table_group
      // .append('text')
      // .style('font-size', '10px')
      // .attr('transform', `translate(7,45)`)
      // .style('text-anchor', 'start')
      // .style('fill','#212121')
      // .text('Weekly')

      // table_group
      // .append('text')
      // .style('font-size', '10px')
      // .attr('transform', `translate(${tablepx +4},45)`)
      // .style('text-anchor', 'start')
      // .style('fill','#1E88E5')
      // .text(list[0].PLAN_WK)

      // table_group
      // .append('text')
      // .style('font-size', '10px')
      // .attr('transform', `translate(${tablepx*3 +4},45)`)
      // .style('text-anchor', 'start')
      // .style('fill','#D81B60')
      // .text(list[0].ACTUAL_WK)
      
      // let table_obj = table_group.append('foreignObject')
      //   .attr('y',0)
      //   .attr('width', `${props.width + 10}px`)
      //   .attr('height', `${props.height + 10}px`)
      //   .append('xhtml:body')
      //   .style('position', 'fixed')

      // let propWidth = props.width
      // let propHeight = props.height

      // let table = table_obj.append('table')
      // .attr('id', 'commodity_summary_table_')
      // .style('border-collapse', 'collapse')
      // .style('font-size', '10px')
      // .style('width', `${propWidth}px`)
      // .style('height', `${propHeight}px`)
      // .style('table-layout', 'fixed')

      // let colgroup = table.append('colgroup')
      // this.tbody.forEach(k => {
      //   colgroup.append('col').style('width', k.w + 'px')
      // })

      // let thead = table.append('thead')
      // for (let r = 1; r <= this.theadrows; r++) {
      //   let row = this.thead.filter(d=> d.row === r)
      //   let tr = thead.append('tr')

      //   row.forEach(item => {

      //     tr.append('th')
      //     .attr('class', 'ogm_table_group__th')
      //     .attr('rowspan', () => item.rowspan ? item.rowspan : '')
      //     .attr('colspan', () => item.colspan ? item.colspan : '')
      //     .style('height', '18px')
      //     .style('padding', '3px')
      //     .style('background', item.color ? item.color : '#BDBDBD')
      //     .html(item.html)
      //   })        
      // }
      
      // let tbodykeys = this.tbody
      
      // let tbody = table.append('tbody').attr('id', 'testpack_summay_tbody_')

      // let addClass = ''
      
      // for (let r = 0; r < list.length; r++) {
      //   let tr = tbody.append('tr')

      //   tbodykeys.forEach((k, i) => {

      //     let td = tr.append('td')
      //       .style('border', 'solid 0.1px #E0E0E0')
      //       .style('height', `${propHeight - 20}px`)
      //       .style('text-align', k.align ? k.align : 'center')
      //       .style('color', '#000000')
      //       .style('padding', '0 3px 0 3px')

      //     if(i === 1) {
      //       td.style('padding', '0 3px 14px 3px')
      //       .style('color', '#1E88E5')
      //     }
      //     if(i === 3) {
      //       td.style('color', '#D81B60')
      //       .style('padding', '0 3px 14px 3px')
      //     }
      //     if(i === 5) {
      //       td.style('background-color', '#FFF9C4')
            
      //     }

      //     if(k.key.includes('PROG')) {
      //       addClass = ''
      //       td
      //       .style('position', 'relative')
      //       .style('color', list[r][k.key] < 0 ? 'red' : list[r][k.key] === 100 ? '#fff' : addClass == 'caution' ? 'red' : '#000')
      //       .style('z-index', '1')
      //       .html(list[r][k.key] == null ? 0+'%' :list[r][k.key]+'%')

      //       td.append('div')
      //       .style('position', 'absolute')
      //       .style('left', 0)
      //       .style('top', 0)
      //       .style('width', list[r][k.key] > 0 ? list[r][k.key] + '%' : 0)
      //       .style('height', `${propHeight - 20}px`)
      //       .style('background-color', k.key.includes('PLAN') ? '#BBDEFB' : '#F8BBD0' )
      //       .style('z-index', '-1')
      //     }
      //     else {
      //       if (k.type == 'float') {
      //         let regexp = /\B(?=(\d{3})+(?!\d))/g
      //         if (isNaN(Math.floor(list[r][k.key]))) td.html('')
      //         else td.html(Math.round(Math.floor(list[r][k.key] * 100) / 100).toString().replace(regexp, ','))
      //       } else td.html(list[r][k.key])
      //     }
      //   })
      // }
    },
  }
}

