import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {

  methods: {
    drawChart() {
      let lightBlue = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `lightBlue`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      lightBlue.append('stop').attr('stop-color', '#d4eaf3').attr('offset', '0')
      lightBlue .append('stop').attr('stop-color', '#a3d0e8').attr('offset', '1')

      let mainGroup = this.svg
      .append('g')
      .attr('transform', `translate(0, 0)`)

      let item = this.DataItems[0]


      let overallPunchStatus = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0)`)

      let data = null, 
          style = null, 
          cellX = null, 
          tmp = null, 
          Circle = null, 
          Guideline = null

      // Header_01
      // tmp.COMPETED / tmp.TOTAL * 100
      // data  =  Math.floor(item.COMPETED / item.TOTAL * 100)
      data  =  isNaN(Math.floor(item.COMPETED / item.TOTAL * 100)) == true ? 0 :Math.floor(item.COMPETED / item.TOTAL * 100)
      style = {
        title: item.CATEGORY,
        x: 0,
        y: 0,
        width: item.CATEGORY == 'WalkDown' ? 70 : item.CATEGORY == 'RFDC' ? 50 : 40,
        height: 18,
        radius: 3,
        tSize: 11,
        tColor: '#333',
        bColor: '#E0E0DF',
        progSize: 15,
      }
      this.drawHeader( overallPunchStatus, data, style) // ---------------------------> 



      item = this.DataItems[0]
      style = {
        id:       'PA',
        title:    ['Total', 'Complete', 'Remain'],
        cell:     [30, 95, 145],
        x:        0,
        y:        20,
        y1:       10,
        y2:       23,
        width:    155,
        height:   32,
        radius:   3,
        opacity:  0.7,
        bColor:   '#83D2F5',
        tSize:    9,
        tColor:   '#757575',
        vSize:    11,
        vColor:   '#fff',
        value:    [item.TOTAL, item.COMPETED, item.REMAIN]
      }
      this.drawSummaryTable(overallPunchStatus, style) // ---------------------------> 
      
    },
    
    drawHeader(selection, _data, _style) {
      let Header_01 = selection
      .append('g')
      .attr('id', '')
      .attr('transform', `translate(${_style.x}, ${_style.y})`)

      Header_01
      .append('rect')
      .attr('transform', `translate(0, 0)`)
      .attr('rx', _style.radius).attr('ry', _style.radius)
      .style('width', _style.width).style('height', _style.height).style('fill', _style.bColor)

      Header_01
      .append('text')
      .attr('transform', `translate(10, ${_style.height/2 + 1})`)
      .style('font-family', 'roboto').style('font-size', _style.tSize).style('fill', _style.tColor).style('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(_style.title)

      Header_01
      .append('text') // Delta Value
      .attr('transform', `translate(${_style.width + 5}, ${_style.height/2 + 1})`)
      .style('fill', () => {
        if (_data < 0) return '#EC407A'; else return '#44A9DF'
      })
      .style('font-family', 'roboto').style('font-size', _style.progSize).style('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(`${_data}%`)
    },
    
    drawSummaryTable(selection, d) {
      let _self = this
      let summaryTable = selection
      .append('g')
      .attr('id', '')
      .attr('transform', `translate(${d.x}, ${d.y})`)
      
      summaryTable
      .append('rect')
      .attr('transform', `translate(0, 0)`)
      .style('width', d.width).style('height', d.height).style('fill', d.bColor).attr('rx', d.radius).attr('ry', d.radius).style('opacity', d.opacity)

      summaryTable
      .append('text') 
      .attr('transform', `translate(${d.cell[0]}, ${d.y1})`)
      .style('font-family', 'roboto').style('font-size', d.tSize)
      .style('fill', d.tColor).style('text-anchor', 'end').style('alignment-baseline', 'middle').text(d.title[0])

      summaryTable
      .append('text') 
      .attr('transform', `translate(${d.cell[1]}, ${d.y1})`)
      .style('font-family', 'roboto').style('font-size', d.tSize)
      .style('fill', d.tColor).style('text-anchor', 'end').style('alignment-baseline', 'middle').text(d.title[1])

      summaryTable
      .append('text') 
      .attr('transform', `translate(${d.cell[2]}, ${d.y1})`)
      .style('font-family', 'roboto').style('font-size', d.tSize)
      .style('fill', d.tColor).style('text-anchor', 'end').style('alignment-baseline', 'middle').text(d.title[2])

      summaryTable // Total
      .append('text')
      .attr('id', `${d.id}_Total}`)
      .attr('transform', `translate(${d.cell[0]}, ${d.y2})`)
      .style('font-family', 'roboto').style('font-size', d.vSize)
      .style('fill', d.vColor).style('text-anchor', 'end').style('alignment-baseline', 'middle')
      .text(d.value[0])
      // .style('cursor', 'pointer')
      // .on('mouseover', function() {
      //   let node = _self.getNodeElValue(`#${d.id}_Total`,'width') / 5.5
      //   d3.select(this).transition().duration(100)
      //   .attr('transform', `translate(${d.cell[0] + node}, ${d.y2}) scale(1.3)`).style('fill', '#EC407A')
      // })
      // .on('mouseout', function(){
      //   d3.select(this).transition().duration(100)
      //   .attr('transform', `translate(${d.cell[0]}, ${d.y2}) scale(1)`).style('fill', d.vColor)
      // })
      // .on('click', () => { 
      //   this.$emit('request-action', d.requests ? d.requests[0] : {})
      // })
      
      summaryTable // Actual
      .append('text')
      .attr('id', `${d.id}_Actual`)
      .attr('transform', `translate(${d.cell[1]}, ${d.y2})`)
      .style('font-family', 'roboto').style('font-size', d.vSize)
      .style('fill', d.vColor).style('text-anchor', 'end').style('alignment-baseline', 'middle')
      .text(d.value[1])
      // .style('cursor', 'pointer')
      // .on('mouseover', function() {
      //   let node = _self.getNodeElValue(`#${d.id}_Actual`,'width') / 5.5
      //   d3.select(this).transition().duration(100)
      //   .attr('transform', `translate(${d.cell[1] + node}, ${d.y2}) scale(1.3)`).style('fill', '#EC407A')
      // })
      // .on('mouseout', function(){
      //   d3.select(this).transition().duration(100)
      //   .attr('transform', `translate(${d.cell[1]}, ${d.y2}) scale(1)`).style('fill', d.vColor)
      // })
      // .on('click', () => { 
      //   this.$emit('request-action', d.requests ? d.requests[1] : {})
      // })

      summaryTable // Remain
      .append('text')
      .attr('id', `${d.id}_Remain`)
      .attr('transform', `translate(${d.cell[2]}, ${d.y2})`)
      .style('font-family', 'roboto').style('font-size', d.vSize)
      .style('fill', d.vColor).style('text-anchor', 'end').style('alignment-baseline', 'middle')
      .text(d.value[2])
      // .style('cursor', 'pointer')
      // .on('mouseover', function() {
      //   let node = _self.getNodeElValue(`#${d.id}_Remain`,'width') / 5.5
      //   d3.select(this).transition().duration(100)
      //   .attr('transform', `translate(${d.cell[2] + node}, ${d.y2}) scale(1.3)`).style('fill', '#EC407A')
      // })
      // .on('mouseout', function(){
      //   d3.select(this).transition().duration(100)
      //   .attr('transform', `translate(${d.cell[2]}, ${d.y2}) scale(1)`).style('fill', d.vColor)
      // })
      // .on('click', () => { 
      //   this.$emit('request-action', d.requests ? d.requests[2] : {})
      // })
    }
  }
}
