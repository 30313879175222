import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {

    
    Draw_Charts() {
      
      let data = null


      let regulatory = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0.5)`)


      let drawX = [25, 50, 470, 550]
      let drawY = 50

      let table = regulatory
      .append('g')
      .attr('transform', `translate(0, 30)`)
      
      // Line for Header
      table.append('line')
      .attr('x1', 0).attr('x2', 600).attr('y1', 10).attr('y2', 10).attr('stroke-width', 2).attr('stroke', '#F4F5F5')

      table.append('line')
      .attr('x1', 0).attr('x2', 600).attr('y1', 35).attr('y2', 35).attr('stroke-width', 2).attr('stroke', '#F4F5F5')

      // Header
      table.append('text')
      .attr('transform', `translate(${drawX[0]}, 27)`)
      .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#757575').style('text-anchor', 'middle').text('No')

      table.append('text')
      .attr('transform', `translate(200, 27)`)
      .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#757575').style('text-anchor', 'start').text('Key Milestones')

      table.append('text')
      .attr('transform', `translate(${drawX[2]}, 27)`)
      .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#757575').style('text-anchor', 'middle').text('Forecast')

      table.append('text')
      .attr('transform', `translate(${drawX[3]}, 27)`)
      .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#757575').style('text-anchor', 'middle').text('Actual')

      // Data Cells
      this.dataSet.forEach(d=> {
        table.append('text')
        .attr('transform', `translate(${drawX[0]}, ${drawY})`)
        .style('font-size', 10).style('font-family', 'roboto').attr('fill', '#757575').style('text-anchor', 'middle').text(d.NO)

        table.append('text')
        .attr('transform', `translate(${drawX[1]}, ${drawY})`)
        .style('font-size', 10).style('font-family', 'roboto').attr('fill', '#757575').style('text-anchor', 'start').text(d.MILESTONE)

        table.append('text')
        .attr('transform', `translate(${drawX[2]}, ${drawY})`)
        .style('font-size', 10).style('font-family', 'roboto').attr('fill', '#757575').style('text-anchor', 'middle').text(d.FORECAST)

        table.append('text')
        .attr('transform', `translate(${drawX[3]}, ${drawY})`)
        .style('font-size', 10).style('font-family', 'roboto').attr('fill', '#757575').style('text-anchor', 'middle').text(d.ACTUAL)

        table.append('line')
        .attr('x1', 0).attr('x2', 600).attr('y1', drawY + 7).attr('y2', drawY + 7).attr('stroke-width', 1).attr('stroke', '#F4F5F5')

        drawY += 20
      })

    },
  }
}
           