import * as d3 from 'd3'
import { json } from 'd3'

export default {
  methods: {
    
    setEvent_PDF() {
      String.prototype.replaceAll = function(org, dest) {
        return this.split(org).join(dest);
      }
      let convert = this.getSelectItems.replaceAll('},' , '}|')
      let dataSet = convert.split('|')
      dataSet.forEach((getData, k ) => {
        let d = JSON.parse(getData)
        this.Export_Overall(d,k)
        this.Export_Topside(d,k)
      })
    },
  }
}