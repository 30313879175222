import Defs from '../../../../../includes/primitives/colorDefs'

export default {
  data: () => ({
    Package: [
      { 
        code: 'P1', 
        x: 967,  y: 458,
        px: 1042, py: 318, width: 230, height:160,  duration: 60,
        scale_in: 1.2, scale_out: 0.7, 
      },
      { 
        code: 'P2', 
        x: 653,  y: 485,
        px: 773,  py: 271, width: 191, height:83,   duration: 130,
        scale_in: 1.1, scale_out: 0.65, 
      },
      { 
        code: 'P3', 
        x: 860,  y: 673,
        px: 840,  py: 697, width: 295, height:233,  duration: 35,
        scale_in: 1.3, scale_out: 0.9, 
      },
    ],
  }),

  methods: {

    set_Gradients() {
      let defs = this.svg.append('defs')

      let legendA = defs.append('linearGradient')
      .attr('id', `PHOTO`).attr('x1', 0.5).attr('x2', 0.5).attr('y1', 0).attr('y2', 1)
      legendA.append('stop').attr('stop-color', '#F9EB85').attr('offset', '0').attr('stop-opacity', 1)
      legendA.append('stop').attr('stop-color', '#B5A31F').attr('offset', '1').attr('stop-opacity', 1)

      let legendB = defs.append('linearGradient')
      .attr('id', `BTN`).attr('x1', 0).attr('x2', 1).attr('y1', 0).attr('y2', 1)
      legendB.append('stop').attr('stop-color', '#D2D8D9').attr('offset', '0').attr('stop-opacity', 1)
      legendB.append('stop').attr('stop-color', '#FFFFFF').attr('offset', '1').attr('stop-opacity', 1)
    },

  }
}