import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  data: () => ({

  }),
  methods: {
    Draw_Charts() {      

      let svg = this.svg
        .append('g')
        .attr('id','chart_progress')
        .attr('transform', `translate(0,0)`)
        let keys_ = Object.keys(this.DataItems[0]).splice(1)

      let chartWrapper = svg
      .append('g')
      .selectAll('g')
      .data(d3.stack().keys(keys_)(this.DataItems))
      .enter()
      .append('g')
      .attr('fill', (_, i) => (this.Bar.BarColorSet[i]))
      .selectAll('rect')
      .data((d) => d)
      .enter()
      
      chartWrapper
      .append('rect')
      .attr('x', (d, i) => this.scaleXInfo.scale(this.DataItems[i].STEP_STATUS))
      .attr('y', (d) => d[1] >=  0 ? this.scaleYInfo.scale(d[1]) : this.scaleYInfo.scale(d[0]))
      .attr('width', this.setThickness * .75)
      .attr('height', (d) => Math.abs(this.scaleYInfo.scale(d[0]) - this.scaleYInfo.scale(d[1])))
      // ------------------------------------------------------------------------- Series & Value

      // chartWrapper
      // .append('text')
      // .attr('class', 'yaxis_right_text')
      // .style('font-size', '11px')
      // .attr('transform', `translate(${-36},${this.Canvas.CanvasHeight/2}),rotate(-90)`)
      // .style('text-anchor', 'middle')
      // .style('fill','#BDBDBD')
      // .text('Not Overdue  Overdue Count')

      let valueWrapper = svg
      .append('g')
      .selectAll('g')
      .attr('id', (_, i) => `value_wrapper_${i}`)
      .data(d3.stack().keys(keys_)(this.DataItems))
      .enter()
      .append('g')
      .attr('fill', (_, i) => (this.Bar.BarValueAutoColor == 'Y' ? (this.Bar.BarColorSet[i]) : this.Bar.BarValueColor))
      .selectAll('rect')
      .data((d) => d)
      .enter()

      if (this.Bar.BarValueDisplay == 'Y') {
        valueWrapper
        .append('text')
        .attr('id', (d, i) =>  `ValueText${i}`)
        .style('font-size', this.Bar.BarValueSize)
        .style('font-family', this.Bar.BarValueFont)
        .attr('text-anchor', 'middle')
        .attr('class', this.Bar.BarValueStyle)
        .attr('x', (d, i) => this.scaleXInfo.scale(this.DataItems[i].STEP_STATUS)+this.setThickness/3+1)
        .attr('y', (d) => {
          let middleOfBoxHeight = (d[1] - d[0]) / 2
          let textYPosition
          // Magic number for hanging text at top of Box
          let textAlign4Hang = 2
          if(d[1] >=  0) {
            if(this.Bar.BarValuePosition == 'middle') {
              textYPosition = this.scaleYInfo.scale(d[1]) + middleOfBoxHeight
            } else if(this.Bar.BarValuePosition == 'bottom') {
              textYPosition = this.scaleYInfo.scale(d[1]) + (this.Bar.BarValueSize/2)
            } else {
              textYPosition = this.scaleYInfo.scale(d[1])
            }
            return  textYPosition + (this.Bar.BarValuePosition == 'top' ? -this.Bar.BarValueSpace : this.Bar.BarValueSpace -textAlign4Hang)
          }
          else {
            return this.scaleYInfo.scale(d[0])
          } 
        })
        .text((d , i) =>{
          // console.log(i)
          let val = d[1] >= d[0] ? (d[1] - d[0]) : d[1]
          // return val == 0 ? '' : val.toFixed(this.Bar.BarValueRound)
          // return val == 0 ? '' : Number(val).toFixed(0)
          return val == 0 ? '' : Number(val).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

        })
        // .style('cursor', 'pointer')
        // .on('mouseover', function() {
        //   d3.select(this).transition().duration(50).style('opacity', 0.5)
        // })
        // .on('mouseout', function(){
        //   d3.select(this).transition().duration(50).style('opacity', 1)
        // })
        // .on('click', d => {
        //   // console.log(d)
        //   let thisdata = d[1] - d[0]
        //   let find = ''
        //   let keys = Object.keys(d.data)

        //   keys.forEach(k => {
        //     if (d.data[k] == thisdata) find = k
        //   })

        //   // console.log(d.data.STEP_STATUS)
        //   // console.log(find)

        //   // let request_ = this.getRequestColumnProps('request', { MR_NO: list[r].MR_NO }, this.ColumnProps)
        //   // this.$emit('request-action', request_)

        //   let filterString = ` [STEP_STATUS] = '${d.data.STEP_STATUS}' `

        //   if (find == 'COMPLETED') filterString += ` AND [ACTUAL] IS NOT NULL `
        //   if (find == 'INPROGRESS') filterString += ` AND [PLAN] IS NOT NULL AND [ACTUAL] IS NULL `
        //   if (find == 'NODATA') filterString += ` AND [PLAN] IS NULL AND [ACTUAL] IS NULL `

        //   // console.log(filterString)

        //   let request_ = {
        //     dataType: 'row',
        //     action: {
        //       type: 'direct',
        //       target: 'slide-modal',
        //       component: 'ServiceDatatable',
        //       id: 323,
        //       no: ''
        //     },
        //     filters: { 
        //       ...this.FilterValues
        //     },
        //     iFilters: {
        //       filterString : filterString,
        //       inputFilter  : ''
        //     }
        //   }
        //   this.$emit('request-action', request_)
        // })
      }
      }
    }
  }
