import * as d3 from 'd3'

import Data from '../../../../../../includes/primitives/Color_Data'

export default {
  data: () => ({
    legend: 
    [    
      { column: 'ACTUAL', color:  '#80DEEA', shape: 'rect', text: 'Actual ', axis: 'left', x:60, y: 0 },
      { column: 'REMAIN', color: '#FDD835', shape: 'rect', text: 'Remain', axis: 'left', x: 20, y: 0 },
      //{ column: 'P3', color: '#CFCFCF', shape: 'rect', text: 'P3', axis: 'left', x:120, y: 0 },
    ],
  }),
  methods: {
    //------------------------------------------------------------------ drawLegends
    drawLegendsCheck() {
      // this.drawLegendsLocal()
      let legendGoup = this.svg
        .append('g')
        .attr('id','chart_legend')
        .attr('transform', `translate(0,-30)`)

        this.legend.forEach((d,i) =>{
          legendGoup.append('rect')
          .attr('x', `${i*84 + 16}`).attr('y', 0)
          .attr('width', 14)
          .attr('height', 8)
          .style('fill', d.color)

          legendGoup.append('text')
          .attr('transform', `translate(${i*84 + 34},${7})`)
          .attr('text-anchor', 'start')
          .attr('font-size', '11px')
          .text(d.text)
        })

    },

    //------------------------------------------------------------------ drawAxis
    drawAxis() {
      this.drawXAxisLine()
      this.drawXAxisLevel()

      this.drawYAxisLine()
      this.drawYAxisLevel()
    },
    drawXAxisLine() {
      if (this.Axis.AxisXLineDisplay != 'Y') return

      // If some bunch of the lines is drawn in the SVG scope directly,
      // the line's weight couldn't be drawn as we expected. It could be
      // thickened. Draw the lines in a specified group instead.
      let chartArea = this.svg
        .append('g')
        .attr('transform', `translate(0,0)`)

        chartArea
        .append('g')
        .attr('class', '_x_axis_line__')
        .append('path')
        // Transform a data value to Y coordinate.
        // console.log(this.scaleYInfo)
        // .attr('d', `M 0,${this.scaleYInfo.scale(0)} H ${this.Canvas.CanvasChartWidth}`)
        .attr('d', `M 0,${this.Canvas.CanvasChartHeight} H ${this.Canvas.CanvasChartWidth}`)
        .style('stroke', '#BDBDBD')
        .style('stroke-width', this.Axis.AxisXLineWeight)
    },
    drawYAxisLine() {
      if (this.Axis.AxisYLineDisplay != 'Y') return

      // If some bunch of the lines is drawn in the SVG scope directly,
      // the line's weight couldn't be drawn as we expected. It could be
      // thickened. Draw the lines in a specified group instead.
      this.chartArea
        .append('g')
        .attr('class', '_y_axis_line__')
        .append('path')
        // Transform a data value to Y coordinate.
        .attr('d', `M 0,0 V ${this.Canvas.CanvasChartHeight}`)
        .style('stroke', '#BDBDBD')
        .style('stroke-width', this.Axis.AxisYLineWeight)
    },
    drawXAxisLevel() {
      if (this.Axis.AxisXLevelDisplay != 'Y') return
        // console.log(this.Bar.BarDistance/4)
      // if (this.ChartType.includes('Vertical')) {
        let levelBox = this.chartArea
          .append('g')
          .attr('class', '_x_axis_level_unit__')
          .selectAll('g')
          .data(this.DataItems)
          .enter()
          .append('g')
          .attr('transform', d => `translate(${this.scaleXInfo.scale(d.TRANSPORT_COMPANY)},-15)`)

        levelBox // Series Name
          .append('text')
          .attr('transform', `translate(
            ${Math.round(this.setThickness / 2 -this.Bar.BarDistance/3)},
            ${this.Canvas.CanvasChartHeight + Math.round(this.setThickness / 2) }
            )${this.Axis.AxisXLevelDirection == 'Vertical' ? ' rotate(-90) ' : ''}
          `)
          .attr('class', this.Axis.AxisXLevelStyle)
          .style('font-size', this.Axis.AxisXLevelSize)
          .style('font-family', this.Axis.AxisXLevelFont)

          //.style('font-weight', this.Bar.BarSeriesWeight)
          .attr('fill', (_, i) => (this.Axis.AxisXLevelAutoColor == 'Y' ? Data.setGradients['Represent'](this.Bar.BarColorSet[i]) : this.Axis.AxisXLevelColor))
          // .attr('text-anchor', this.Axis.AxisXLevelDirection == 'Vertical' ? 'end' : 'middle')
          .attr('text-anchor', 'middle')
          .text(d => d.TRANSPORT_COMPANY)

      // } else {

      //   let tickPoints_ = []
      //   this.scaleXInfo.tickValues.forEach((d, i) => {
      //     tickPoints_.push(this.scaleXInfo.scale(d))
      //   })
      //   tickPoints_.reverse()

      //   let levelBox = this.chartArea
      //     .append('g')
      //     .attr('class', '_x_axis_level_unit__')
      //     .selectAll('g')
      //     .data(this.scaleXInfo.tickValues)
      //     .enter()
      //     // Append every single box for a line value & unit. -------------------
      //     // Inited X, Y coordinate of the group is (0, 0), and its width
      //     // is 0 too. So, at the current time of that the '-this.Axis.AxisLevelSpace'
      //     // is applied to the X, X coordinate is '-this.Axis.AxisLevelSpace'.
      //     // And then the width will be extended toward left by the child
      //     // element's attribute ('text-anchor', 'end'). It doesn't need to 
      //     // recalculate the X coordinate of the every single boxes.
      //     .append('g')
      //     .attr('transform', (_, i) => `translate(${tickPoints_[i]},${this.Canvas.CanvasChartHeight + this.Axis.AxisXLevelSpace + (this.Axis.AxisXLevelSize / 2)})`)
      //   // .attr('transform', tickValue_ => {console.log(this.scaleXInfo.scale(tickValue_));})

      //   // Append Unit
      //   levelBox
      //     .append('text')
      //     .attr('id', (_, i) => `Level_Unit_${i}`)
      //     .style('font-size', this.Axis.AxisXLevelSize)
      //     .style('font-family', this.Axis.AxisXLevelFont)
      //     .attr('class', this.Axis.AxisXLevelStyle)
      //     .style('font-weight', this.Axis.AxisXLevelWeight)
      //     .attr('fill', this.Axis.AxisXLevelColor)
      //     .attr('alignment-baseline', 'middle')
      //     .attr('text-anchor', 'start')
      //     .text(tickValue_ => Math.round(tickValue_))
      //     .attr('x', (_, i) => -Math.round(this.getNodeElValue(`#Level_Unit_${i}`, 'width') / 2))

      //   // Append Unit
      //   levelBox
      //     .append('text')
      //     .attr('x', (_, i) => Math.round((this.getNodeElValue(`#Level_Unit_${i}`, 'width') / 2) * 1.1))
      //     .style('font-size', this.Axis.AxisXLevelUnitSize)
      //     .style('font-family', this.Axis.AxisXLevelFont)
      //     .style('font-weight', this.Axis.AxisXLevelWeight)
      //     .attr('class', this.Axis.AxisXLevelStyle)
      //     .attr('fill', this.Axis.AxisXLevelColor)
      //     .attr('alignment-baseline', 'middle')
      //     .attr('text-anchor', 'start')
      //     .text(this.Axis.AxisXLevelUnitFormat)
      // }

    },
    drawYAxisLevel() {

      if (this.Axis.AxisYLevelDisplay != 'Y') return

      // if (this.ChartType.includes('Vertical')) {
        

      
        let levelBox = this.chartArea
          .append('g')
          .attr('class', '_y_axis_level_unit__')
          .selectAll('g')
          .data(this.scaleYInfo.tickValues)
          .enter()
          // Append every single box for a line value & unit. -------------------
          // Inited X, Y coordinate of the group is (0, 0), and its width
          // is 0 too. So, at the current time of that the '-this.Axis.AxisLevelSpace'
          // is applied to the X, X coordinate is '-this.Axis.AxisLevelSpace'.
          // And then the width will be extended toward left by the child
          // element's attribute ('text-anchor', 'end'). It doesn't need to 
          // recalculate the X coordinate of the every single boxes.
          .append('g')
          .attr('class', (d, i) => `_y_axis_level_unit_group__${i}`)

        // Append Unit
        levelBox
          .append('text')
          .attr('id', (_, i) => `Level_Unit_${i}`)
          .style('font-size', this.Axis.AxisYLevelUnitSize)
          .style('font-family', this.Axis.AxisYLevelFont)
          .attr('class', this.Axis.AxisYLevelStyle)
          .style('font-weight', this.Axis.AxisYLevelWeight)
          .attr('fill', '#BDBDBD')
          .attr('alignment-baseline', 'middle')
          .attr('text-anchor', 'end')
          .text(this.Axis.AxisYLevelUnitFormat)

        // Append Unit
        levelBox
          .append('text')
          .attr('id', (_, i) => `Level_Unit_unit_${i}`)
          .attr('x', (_, i) => -Math.round(this.getNodeElValue(`#Level_Unit_${i}`, 'width') * 1.1))
          .style('font-size', this.Axis.AxisYLevelSize)
          .style('font-family', this.Axis.AxisYLevelFont)
          .attr('class', this.Axis.AxisYLevelStyle)
          .style('font-weight', this.Axis.AxisYLevelWeight)
          .attr('fill', '#BDBDBD')
          .attr('alignment-baseline', 'middle')
          .attr('text-anchor', 'end')
          .text(tickValue_ => Math.round(tickValue_))

        // Alignment of level
        levelBox.attr('transform', (tickValue_, i)=>  {
          let levelTextWidth = d3.select(`#Level_Unit_${i}`).node().getBoundingClientRect().width
          let levelUnitWidth = d3.select(`#Level_Unit_unit_${i}`).node().getBoundingClientRect().width         
            return `translate(${ this.Axis.AxisYLevelPosition == 'right' ?  levelTextWidth + levelUnitWidth + this.Axis.AxisYLevelSpace : -this.Axis.AxisYLevelSpace }, ${this.scaleYInfo.scale(tickValue_)})`
        })
      // } else {

      //   let levelBox = this.chartArea
      //     .append('g')
      //     .attr('class', '_y_axis_level_unit__')
      //     .selectAll('g')
      //     .data(this.DataItems)
      //     .enter()
      //     .append('g')
      //     .attr('transform', d => `translate(0,${this.scaleYInfo.scale(d.WORK_TYPE_CODE)})`)

      //   levelBox // Series Name
      //     .append('text')
      //     .attr('class', (d,i) => `_y_axis_level_text__${i}`)
      //     // .attr('transform', `translate(${Math.round(this.setThickness / 2)},${this.Canvas.CanvasChartHeight + Math.round(this.setThickness / 3) + this.Bar.BarSeriesSpace})`)
      //     // .attr('transform', `translate(-${this.Axis.AxisYLevelSpace}, ${Math.round(this.setThickness / 3) + this.Axis.AxisYLevelSize / 2})`)
      //     .attr('transform', (d,i) =>   {
      //       let levelTextWidth = d3.select(`._y_axis_level_text__${i}`).node().getBoundingClientRect().width
      //       return `translate(${this.Axis.AxisYLevelPosition == 'left' ? -this.Axis.AxisYLevelSpace 
      //         : this.Axis.AxisYLevelSpace +  levelTextWidth},  ${Math.round(this.setThickness / 3) + this.Axis.AxisYLevelSize / 2})`
      //     })
      //     .style('font-size', this.Axis.AxisYLevelSize)
      //     .style('font-family', this.Axis.AxisYLevelFont)
      //     .attr('class', this.Axis.AxisYLevelStyle)
      //     //.style('font-weight', this.Axis.BarSeriesWeight)
      //     .attr('fill', (_, i) => (this.Axis.AxisYLevelAutoColor == 'Y' ? Data.setGradients['Represent'](this.Bar.BarColorSet[i]) : this.Axis.AxisYLevelColor))
      //     .attr('text-anchor',` ${this.Axis.AxisYLevelPosition == 'left' ? 'end' : 'start'}`)
      //     .text(d => d.WORK_TYPE_CODE)


      //     // `#_y_axis_level_text__${this.axisy.o.levelPosition}_${i}`).node().getBoundingClientRect().width * 1.1
      // }
    },

    //------------------------------------------------------------------ drawGrid
    drawGrid() {
      this.drawGridYLines()
    },
    drawGridXLines() {
      if (this.Axis.AxisYGridDisplay != 'Y') return

      // If some bunch of the lines is drawn in the SVG scope directly,
      // the line's weight couldn't be drawn as we expected. It could be
      // thickened. Draw the lines in a specified group instead.
      this.chartArea
        .append('g')
        .attr('class', '_x_axis_grid_line__')
        .selectAll('path')
        .data(this.scaleXInfo.tickValues)
        .enter()
        .append('path')
        // Transform a data value to X coordinate.
        .attr('d', (tickValue_) => `M ${this.scaleXInfo.scale(tickValue_)},0 V ${this.Canvas.CanvasChartHeight}`)
        .style('stroke', this.Axis.AxisYGridColor)
        .style('stroke-width', this.Axis.AxisYGridWeight)
    },
    drawGridYLines() {
      if (this.Axis.AxisYGridDisplay != 'Y') return

      // If some bunch of the lines is drawn in the SVG scope directly,
      // the line's weight couldn't be drawn as we expected. It could be
      // thickened. Draw the lines in a specified group instead.

      this.chartArea
        .append('g')
        .attr('class', '_y_axis_grid_line__')
        .selectAll('path')
        .data(this.scaleYInfo.tickValues)
        .enter()
        .append('path')
        // Transform a data value to Y coordinate.
        .attr('d', tickValue_ => `M 0,${this.scaleYInfo.scale(tickValue_)} H ${this.Canvas.CanvasChartWidth}`)
        .style('stroke', this.Axis.AxisYGridColor)
        .style('stroke-width', this.Axis.AxisYGridWeight)
    },

  }
}
