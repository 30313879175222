import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({
    discTitle: ['Structural', 'Piping', 'Electrical', 'Mechanical',  'HVAC', 'Painting']
  }),

  methods: {
    drawChart() {
      let lightBlue = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `lightBlue`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      lightBlue.append('stop').attr('stop-color', '#d4eaf3').attr('offset', '0')
      lightBlue .append('stop').attr('stop-color', '#a3d0e8').attr('offset', '1')

      let mainGroup = this.svg
      .append('g')
      .attr('calss','group')
      .attr('transform', `translate(10, 0)`)

      let item = this.DataItems[0]

      let blockPhoto = mainGroup
      .append('g')
      .attr('class','image')
      .attr('transform', `translate(0, 0)`)

      blockPhoto
      .append('rect')
      .attr('x', 0).attr('y', 0).attr('rx', 5).attr('ry', 5).attr('width', 240).attr('height', 218)
      .attr('fill', '#fff')

      const path = `${this.__HOST_NAME_RESOURCE}/TORTUE/Images/Const_OverallDashboard/Topside.png`

      let imgname = this.DataItems[0].AREA

      blockPhoto
      .append('image')
      .attr('id', 'menuIcon')
      .attr('transform', `translate(-8, 5)scale(0.8)`)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Images/Const_OverallDashboard/${imgname}.png`)
      .attr('width', 260).attr('height', 175)
      // .style('cursor', 'pointer')
      // .on('click', () => {

      //   let request_ = this.getRequestColumnProps('topsides', {}, this.ColumnProps)
      //   if(request_) this.$emit('request-action', request_)
      //   // let request_ = {
      //   //   dataType: 'row',    
      //   //   action: {
      //   //     type: 'direct',
      //   //     target: 'url',
      //   //     path: `/service/construction/construction_dashboards/topside_dashboard/overall`
      //   //   },
      //   //   filters: {}
      //   // }
      //   // this.$emit('request-action', request_)
      // })


      let overallPunchStatus = mainGroup
      .append('g')
      .attr('class','box_group')
      .attr('transform', `translate(0.5, 0)`)

      let data = null, 
          style = null, 
          cellX = null, 
          tmp = null, 
          Circle = null, 
          Guideline = null

      // Header_01
      data  = this.dataSet.find(f=> f.TYPE == 'A').PROG
      style = {
        title: 'Punch A',
        x: 250,
        y: 260,
        width: 60,
        height: 18,
        radius: 3,
        tSize: 11,
        tColor: '#333',
        bColor: '#E0E0DF',
        progSize: 15,
      }
      this.Header_01( overallPunchStatus, data, style) // ---------------------------> 



      item = this.dataSet.find(f=> f.TYPE == 'A')
      style = {
        id:       'A',
        title:    ['Total', 'Closed', 'Open'],
        cell:     [45, 95, 145],
        x:        250,
        y:        280,
        y1:       10,
        y2:       23,
        width:    155,
        height:   32,
        radius:   3,
        opacity:  0.7,
        bColor:   '#83D2F5',
        tSize:    9,
        tColor:   '#757575',
        vSize:    11,
        vColor:   '#fff',
        area:     item.AREA,
        type: 'A',
        value:    [item.TOTAL, item.ACTUAL, item.REMAIN]
      }
      this.SummaryTable_06(overallPunchStatus, style) // ---------------------------> 

      //------------------------------------------------------------------------------------------------------MC
      let mcText = overallPunchStatus
      .append('g')
      .attr('class','mc_group')
      .attr('transform', `translate(0, 170)`)
      .style('font-family', 'roboto')


      let textData = null
      let tdata = null

      tdata = this.Queries.SQL2.find(f=> f.TYPE == 'CERT' && f.DISC == 'MC')
      // console.log(tdata)
      textData =[
        {title: 'Plan',  value: null,      qty: tdata.PLAN},
        {title: 'Full MC Complete'  , value: tdata.PROG,     qty: tdata.APPROVED},
        {title: 'Partial MC Complete', value: null,      qty: tdata.APPROVED_PARTIALLY},
        {title: 'Remain'            , value: 100-tdata.PROG, qty: tdata.REMAIN }
      ]
      textData.forEach((d,i) => {
        mcText
        .append('text')
        .attr('transform', `translate(${1}, ${(i*18) + 7})`)
        .style('font-size', 11).attr('fill', d.title == 'Remain' ? '#E53935':'#333').style('text-anchor', 'mistartddle')
        .text(textData[i].title)

        mcText
        .append('rect')
        .attr('transform', `translate(${125}, ${(i*18) - 4})`)
        .attr('width', 24).attr('height', 15).attr('stroke-width', 0.5).attr('stroke', '#bcbcbc').attr('fill', '#fff').attr('stroke','#B3E5FC')

        mcText
        .append('text')
        .attr('transform', `translate(${147}, ${(i*18) + 7})`)
        .style('font-size', 11).attr('fill', '#757575').style('text-anchor', 'end')
        .text(textData[i].qty.toLocaleString())
      })



      data  = this.Queries.SQL2.find(f=> f.TYPE == 'CERT' && f.DISC == 'MC').PROG
      style = {
        title: 'MC',
        x: 0,
        y: 260,
        width: 40,
        height: 18,
        radius: 3,
        tSize: 11,
        tColor: '#333',
        bColor: '#E0E0DF',
        progSize: 15,
      }
      this.Header_01( overallPunchStatus, data, style) // ---------------------------> 

      item = this.Queries.SQL2.find(f=> f.TYPE == 'CERT' && f.DISC == 'MC')
      let itemData = item.APPROVED + item.APPROVED_PARTIALLY
      style = {
        title:    ['Total', 'Complete', 'Remain'],
        cell:     [30, 85, 130],
        x:        0,
        y:        280,
        y1:       10,
        y2:       23,
        width:    140,
        height:   32,
        radius:   3,
        opacity:  0.7,
        bColor:   '#83D2F5',
        tSize:    10,
        tColor:   '#757575',
        vSize:    11,
        vColor:   '#fff',
        value:    [item.TOTAL, itemData, item.REMAIN]
      }
      this.SummaryTable_01(overallPunchStatus, style) // ---------------------------> 
      
    },
  }
}
