import * as d3 from 'd3'


export default {
  methods: {
    Activate_Topside_Module(_mod) {
      this.svgModeling = this.svg.append('svg')
      this.svgModeling
      .attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight)
      .attr('viewBox', `0 0 ${this.Canvas.CanvasWidth} ${this.Canvas.CanvasHeight}`)
      .style('opacity', 1)
      // Overall_Hull_Group
      let ModuleGroup = this.svgModeling
      .append('g')
      .attr('id', `Mask_Module`)
      .attr('transform', `translate(0,0) scale(1)` )
      .attr('opacity', 1)
        
      // ### Module Blocks
      this.TOPSIDE.filter(f => f.Lv3 == _mod).forEach(d => {
        ModuleGroup
        .append('image')
        .attr('id', `Blk_${d.Blk}`)
        .style('opacity', 1).style('cursor', 'pointer')
        .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Blocks/Topsides/${d.Blk}.png`)
        .attr('transform', `translate(${d.x1}, ${d.y1}) scale(${0.75})`)
        .call(s => { this.Goto_Block_Summary(s, d) }) // <--------
        
      })
      //----------------------바로 division
      setTimeout(()=>{
        this.TOPSIDE.filter(f => f.Lv3 == _mod).forEach(d => {
          d3.select(`#Blk_${d.Blk}`)
          .transition().duration(1000).attr('transform', `translate(${d.x2}, ${d.y2}) scale(0.5)`)
        })
        setTimeout(() => {

          let coordinate = this.Coordinate.find(f=> f.l2 == _mod)

          // Display
          this.draw_BlockCode_Module(_mod, coordinate.x, coordinate.y)

          this.Lev1 = 'TOPSIDE'
          this.stage = this.STG_Lv4_Block_Division
          let dStage = {
            stage: this.STG_Lv4_Block_Division,
            // mask : _polygon,
            level: _mod
          }
          this.selectedItems.push(dStage)            
          this.fncName = 'Goto_Topside_ModuleDivision'
          this.StageCode() // AREA / STAGE
          // this.displayGroupID(`#Menu_Back`, 0, 200, 1)
        }, 1000);
      })
      //----------------------바로 division



      // // Draw the event Mask of Module
      // let masks = this.Masks.find(f => f.mod == _mod)

      // ModuleGroup
      // .append('polygon')
      // .attr('id', `Mask_Mod_${_mod}`)
      // .attr('transform', `translate(${masks.x}, ${masks.y}) scale(1)`)
      // .attr('points', masks.point)
      // .attr('fill', '#fff').style('opacity', 0).style('visibility', 'visible').style('cursor', 'pointer')
      // .call(s => { this.Goto_Topside_ModuleDivision(s, this.polygonModule, _mod) }) // <--------
    },
  }
}
