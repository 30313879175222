import * as d3 from 'd3'

export default {
  data: () => ({
    legends: [
      {
        function: 'Business',
        bColor  : '#F8CBAD'
      },
      {
        function: 'Commercial',
        bColor  : '#BFBFBF',
        tColor  : '#fff'
      },
      {
        function: 'Completions',
        bColor  : '#9966FF',
        tColor  : '#fff'
      },
      {
        function: 'Construction',
        bColor  : '#00B0F0',
        tColor  : '#fff'
      },
      {
        function: 'DTC',
        bColor  : '#FFC000'
      },
      {
        function: 'Engineering',
        bColor  : '#E2EFDA'
      },
      {
        function: 'Infrastructure',
        bColor  : '#FF80AB'
      },
      {
        function: 'Mfg.',
        bColor  : '#B4C6E7',
        tColor  : '#fff'
      },
      {
        function: 'Olefins',
        bColor  : '#92D050',
        tColor  : '#fff'
      },
      {
        function: 'Polymers',
        bColor  : '#C65911',
        tColor  : '#fff'
      },
      {
        function: 'Supply Chain',
        bColor  : '#FFFF00'
      }
    ]
  }),
  methods: {
    draw_Legends() {

      let legends = this.SkylineCharts
      .append('g')
      .attr('transform', `translate(${this.skyline.legend.x},${this.skyline.legend.y})`)
      .attr('id', `skyline_boxes_legends`)
      .attr('fill', 'Transparent')

      let drawX = 0
      let drawY = 0

      this.legends.forEach((leg, i) => {
        let idx = i
        if (i > 5) {
          idx = i - 6
          drawY = 30
        }

        drawX = idx * 90

        let legend = legends.append('g')
        .attr('transform', `translate(${drawX},${drawY})`)
        
        legend.append('rect') 
        .attr('x', 0).attr('y', 12)
        .attr('width', 80).attr('height', 18)
        .attr('rx', 2)
        .attr('fill', leg.bColor)
        .attr('stroke', leg.bColor)
        .attr('stroke-width', 3)

        
        legend
        .append('g')
        .append('text')
        .attr('x', 40)
        .attr('y', this.timeline.style.week.height)
        .style('font-family', this.timeline.font)
        .style('font-size', 10)
        .attr('fill', leg.tColor ? leg.tColor : '#424242')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'hanging')
        .text(leg.function)



      })


    },
  }
}
