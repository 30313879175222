import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {

    
    Draw_Charts() {
      
      let data = null, 
          style = null,
          item = null

      let BITRStatus = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0)`)

     



      // Header_01
      data  = this.dataSet[0].PROG
      style = {
        title: 'B ITR',
        x: 130,
        y: 40,
        width: 50,
        height: 18,
        radius: 3,
        tSize: 11,
        tColor: '#333',
        bColor: '#E0E0DF',
        progSize: 15,
      }
      this.Header_01( BITRStatus, data, style) // ---------------------------> 


      // SummaryTable_01
      item = this.dataSet[0]
      style = {
        id:       'BITR',
        title:    ['Total', 'Closed', 'Remain'],
        cell:     [40, 90, 140],
        x:        130,
        y:        60,
        y1:       10,
        y2:       23,
        width:    155,
        height:   32,
        radius:   3,
        opacity:  0.7,
        bColor:   '#83D2F5',
        tSize:    9,
        tColor:   '#757575',
        vSize:    11,
        vColor:   '#fff',
        value:    [item.TOTAL, item.ACTUAL, item.REMAIN]
      }
      this.SummaryTable_01(BITRStatus, style) // ---------------------------> 


      // Chart_Tube_01
      data = this.dataSet[0]
      style = {
        x:        30,
        y:        90,
        color:    'LightBlue',
        height:   140,
        tickness: 60,
        base:   { tSize: 14, height: 40, padding: 30,},
        legend: { actualColor:'#44A9DF', remainColor: '#F7BACF', valueSize: 11, tSize: 10},
        value:  {TOTAL:data.TOTAL, ACTUAL:data.ACTUAL, REMAIN:data.REMAIN, PROG:data.PROG}
      }
      this.Chart_Tube_01(BITRStatus, style) // ---------------------------> 
    },
  }
}
           