import * as d3 from 'd3'

export default {
  methods: {
    Export_Hull_AFT(d,k) {
 
      // if (d.stage == 'Division_Lv3' && d.level == 'HULL-AFT') {
      //   setTimeout(() => {
      //     // Remove SVG
      //     this.removeSvg(this.CANVAS, 10)
      //   },1000);

      //   // Title & Keyplan
      //   d3.select(`#Title`).text(`Hull-AFT`)
      //   d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Keyplan/HULL_AFT.png`)
        
      //   // Hidden
      //   this.hiddenGroupID('#Menu_Back', 0, 10)

      //   this.hiddenGroupID('#Mask_AFT_Group', 0, 10)
       
      //   setTimeout(() => {
      //   // Explode
      //   this.Hull_AFT.forEach(d => {
      //     d3.select(`#Blk_${d.Blk}`)
      //     .transition().duration(1000).attr('transform', `translate(${d.x2}, ${d.y2}) scale(0.5)`)
      //   })
      //   },1000);

      //   setTimeout(() => {

      //     // Display
      //     this.displayGroupID(`#Mask_HL02`, 0, 10, 0)
      //     this.displayGroupID(`#Mask_MD00a`, 0, 10, 0)
      //     this.displayGroupID(`#Mask_RB01_02`, 0, 10, 0)

      //     this.Lev1 = 'HULL'; this.Lev2 = 'HULL-AFT'
      //     this.stage = this.STG_Division_Lv3
      //     this.fncName = 'Goto_AFT_Division_Lv3'
      //     this.StageCode() // AREA / STAGE
      //     this.displayGroupID(`#Menu_Back`, 0, 200, 1)

      //     // ### Call Canvas ###
      //     this.Canvas_Division_Lv3(this.Lev1, this.Lev2)
      //   }, 1000);

      // } 

      if (d.stage == 'Lv3_Block_Division' && ['Mask_MD00a','Mask_RB01_02'].includes(d.mask)) {
        setTimeout(() => {
          // Remove SVG
          this.removeSvg(this.CANVAS, 10)
        },1000);
        this.Lev2 = 'HULL-AFT'
        // Title & Keyplan
        d3.select(`#Title`).text(`Hull-AFT-${d.level}`)
        d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Keyplan/HULL_AFT_${d.level}.png`)

        // Hidden
        this.hiddenGroupID('#Menu_Back', 0, 10)

        this.hiddenGroupID('#Mask_HL02', 0, 10)
        this.hiddenGroupID('#Mask_MD00a', 0, 10)
        this.hiddenGroupID('#Mask_RB01_02', 0, 10)

        let coordinate = this.Coordinate.find(f=> f.l2 == this.Lev2 && f.code == d.level)

        setTimeout(() => {
          this.Hull_AFT.filter(f=> f.Lv3 !== d.level).forEach(d => {
            this.hiddenGroupID(`#Blk_${d.Blk}`, 0, 300)
          })
          // Scale
          d3.select(`#Hull_AFT_Group`)
          .transition().duration(1000).attr('transform', `translate(${coordinate.x}, ${coordinate.y}) scale(1)`)

            // Explode
            this.Hull_AFT.filter(f=> f.Lv3 == d.level).forEach(d => {
              d3.select(`#Blk_${d.Blk}`)
              .transition().duration(1000).attr('transform', `translate(${d.x4}, ${d.y4}) scale(0.5)`)
            })
        }, 1000);

        setTimeout(() => {

          this.draw_BlockCode_Lv3(d.level, coordinate.x, coordinate.y)

          this.Lev1 = 'HULL'; this.Lev2 = 'HULL-AFT'; this.Lev3 = d.level; this.Lev4 = null; 
          this.stage = this.STG_Lv3_Block_Division 
          this.fncName = 'Goto_AFT_BlockDivison_Lv3'
          this.StageCode() // AREA / STAGE
          this.displayGroupID(`#Menu_Back`, 0, 200, 1)
        }, 1000);
      }
      
      if (d.stage == 'Division_Lv4' && d.level == 'HULL-AFT' && k == 4) {
        setTimeout(() => {
          // Remove SVG
          this.removeSvg(this.CANVAS, 10)
        },1000);


        // Title & Keyplan
        d3.select(`#Title`).text('Hull-AFT-HL02')
        d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Keyplan/HULL_AFT_HL02.png`)

        // Hidden
        this.hiddenGroupID('#Menu_Back', 0, 10)

        this.hiddenGroupID('#Mask_HL02', 0, 10)
        this.hiddenGroupID('#Mask_MD00a', 0, 10)
        this.hiddenGroupID('#Mask_RB01_02', 0, 10)

        setTimeout(() => {

        this.Hull_AFT.filter(f=> f.Lv3 !== 'HL02').forEach(d => {
          this.hiddenGroupID(`#Blk_${d.Blk}`, 0, 300)
        })

        d3.select(`#Hull_AFT_Group`)
        .transition().duration(700).attr('transform', `translate(200,100) scale(0.8)`)
    
        // Explode
        this.Hull_AFT.filter(f=> f.Lv3 == 'HL02').forEach(d => {
          d3.select(`#Blk_${d.Blk}`)
          .transition().duration(1000).attr('transform', `translate(${d.x3}, ${d.y3}) scale(0.5)`)
        })
      }, 1000);

      setTimeout(() => {
          // Display
          this.displayGroupID(`#Mask_HL02_Main`, 0, 10, 0)
          this.displayGroupID(`#Mask_HL02_After`, 0, 10, 0)
          this.displayGroupID(`#Mask_HL02_Port`, 0, 10, 0)
          this.displayGroupID(`#Mask_HL02_Starboard`, 0, 10, 0)
          this.displayGroupID(`#Mask_HL02_Bulkhead`, 0, 10, 0)
          this.displayGroupID(`#Mask_HL02_Bottom`, 0, 10, 0)

          this.Lev1 = 'HULL'; this.Lev2 = 'HULL-AFT'
          this.stage = this.STG_Division_Lv4           
          this.fncName = 'Goto_AFT_Division_Lv4'
          this.StageCode() // AREA / STAGE
          this.displayGroupID(`#Menu_Back`, 0, 200, 1)
          
          // ### Call Canvas ###
          this.Canvas_Division_Lv4('HULL-AFT')
      }, 1000);   
      }     
  
      if (d.stage == 'Lv4_Block_Division' && ['Mask_HL02_Main', 'Mask_HL02_Port', 'Mask_HL02_Bulkhead',
       'Mask_HL02_After', 'Mask_HL02_Starboard', 'Mask_HL02_Bottom'].includes(d.mask)) {
       setTimeout(() => {
         // Remove SVG
         this.removeSvg(this.CANVAS, 10)
       },1000);
       this.Lev1 = 'HULL'
       this.Lev2 = 'HULL-AFT'
          // Title & Keyplan
        switch(d.level) {
          case 'PRT': d3.select(`#Title`).text('HL02_Port Blocks'); break;
          case 'STB': d3.select(`#Title`).text('HL02_Starboard Blocks'); break;
          case 'BLK': d3.select(`#Title`).text('HL02_Bulkhead Blocks'); break;
          case 'AFT': d3.select(`#Title`).text('HL02_After Blocks'); break;
          case 'MDK': d3.select(`#Title`).text('HL02_Maindeck Blocks'); break;
          case 'BTM': d3.select(`#Title`).text('HL02_Bottom Blocks'); break;
        }
        d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Keyplan/HL02_${d.level}.png`)

        // Hidden
        this.hiddenGroupID('#Menu_Back', 0, 10)
        
        this.hiddenGroupID('#Mask_HL02_Main', 0, 10)
        this.hiddenGroupID('#Mask_HL02_After', 0, 10)
        this.hiddenGroupID('#Mask_HL02_Port', 0, 10)
        this.hiddenGroupID('#Mask_HL02_Starboard', 0, 10)
        this.hiddenGroupID('#Mask_HL02_Bulkhead', 0, 10)
        this.hiddenGroupID('#Mask_HL02_Bottom', 0, 10)
       
        let coordinate = this.Coordinate.find(f=> f.l2 == this.Lev2 && f.code == d.level)

       setTimeout(() => {

        this.Hull_AFT.filter(f=> f.Lv4 !== d.level).forEach(d => {
          this.hiddenGroupID(`#Blk_${d.Blk}`, 0, 300)
        })
        
          // Scale
          d3.select(`#Hull_AFT_Group`)
          .transition().duration(1000).attr('transform', `translate(${coordinate.x}, ${coordinate.y}) scale(1)`)

          // Explode
          this.Hull_AFT.filter(f=> f.Lv4 == d.level).forEach(d => {
            d3.select(`#Blk_${d.Blk}`)
            .transition().duration(1000).attr('transform', `translate(${d.x4}, ${d.y4}) scale(0.5)`)
          })
       }, 1000);

       setTimeout(() => {
        this.draw_BlockCode_Lv4('HULL-AFT', d.level, coordinate.x, coordinate.y)

        this.Lev1 = 'HULL'; this.Lev2 = 'HULL-AFT'; this.Lev3 = 'HL02'; this.Lev4 = d.level; 
        this.stage = this.STG_Lv4_Block_Division 
        this.fncName = 'Goto_AFT_BlockDivison_Lv4'
        this.StageCode() // AREA / STAGE
        this.displayGroupID(`#Menu_Back`, 0, 200, 1)
      }, 1300);
     }   


    },
  }
}
           