export default {
  width: 130,
  gab: 10,
  attrs: [
    { 
      text1: 'Total ITRs',
      text2: '',
      refColumn: 'BITR_TOTAL',
      codeValue   : 'Total', // fixed
      filterColumn: 'TOTAL',
      fill : 'lightGray', 
      fColor: 'Transparent', 
      tColor: '#212121',
      on: true
    },
    { 
      text1: 'Not started ITRs',
      text2: '',
      refColumn: 'MC_NOT_COM_BITR',
      codeValue   : 'Belong to MC not completed system',
      filterColumn: 'SUBSYSTE_STATUS',
      fill : 'fillWhite', 
      fColor: '#BDBCBC', 
      tColor: '#818181',
      on: true
    },
    { 
      text1: 'In progress ITRs',
      text2: '',
      refColumn: 'MC_COM_BITR',
      codeValue   : 'Belong to MC completed system',
      filterColumn: 'SUBSYSTE_STATUS',
      fill : 'fillYellow', 
      fColor: '#FFD600',
      tColor: '#fff',
      on: true
    },
    { 
      text1: 'B ITR Completed',
      refColumn: 'BITR_COMPLETED',
      codeValue   : 'B ITR Completed',
      filterColumn: 'SUBSYSTE_STATUS',
      fill : 'fillBlue', 
      fColor: '#66CCFF', 
      tColor: '#fff',
      on: true
    },
    { 
      text1: 'Partial MC',
      text2: 'Complete Subsystems',
      refColumn: 'PARTIAL_MC_COMPLETE',
      codeValue   : 'Partial MC Complete',
      filterColumn: 'SUBSYSTE_STATUS',
      fill : 'fillLightGreen', 
      fColor: '#8BC34A', 
      tColor: '#fff',
      on: true
    },
    { 
      text1: 'Full MC Complete',
      text2: 'Subsystems',
      refColumn: 'FULL_MC_COMPLETE',
      codeValue   : 'Full MC Complete',
      filterColumn: 'SUBSYSTE_STATUS',
      fill : 'fillGreen', 
      fColor: '#43A047', 
      tColor: '#fff',
      on: true
    },
  ]
}
