import * as d3 from 'd3'


export default {
  methods: {

    Draw_Charts() {
      
      let data = null, 
          style = null, 
          item = null

      let sChartITR = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0.5)`)


      if (!this.dataSet) {
        
        sChartITR
        .append('text')
        .attr('x', 150)
        .attr('y', 120).style('font-size',16)
        .style('font-family','roboto')
        .attr('text-anchor', 'middle')
        .attr('fill', '#bcbcbc')
        .text('Not Applicable' )
        return 
      }


      // SummaryTable_01
      item = this.Queries.SQL1
      style = {
        id:       'AITR',
        title:    ['Raised', 'Closed', 'Active'],
        cell:     [50, 110, 160],
        x:        30,
        y:        40,
        y1:       10,
        y2:       26,
        width:    170,
        height:   34,
        radius:   3,
        opacity:  0.7,
        bColor:   '#E0E0DF',
        tSize:    9,
        tColor:   '#757575',
        vSize:    11,
        vColor:   '#333',
        value:    [item[0].TOTAL, item[0].ACTUAL, item[0].REMAIN]
      }
      this.SummaryTable_01(sChartITR, style) // ---------------------------> 





      let legend = sChartITR
      .append('g')
      .attr('transform', `translate(50, 100)`)

      legend
      .append('rect')
      .attr('transform', `translate(0, 5)`)
      .attr('width', 8).attr('height', 8).attr('fill','#E0E0DF')
      legend
      .append('text') 
      .attr('transform', `translate(13, 10)`).attr('font-size', 9).attr('text-anchor','start').attr('alignment-baseline','middle').attr('fill','#757575').text('Raised')

      legend
      .append('rect')
      .attr('transform', `translate(50, 5)`)
      .attr('width', 8).attr('height', 8).attr('fill','#B4E3FA')
      legend
      .append('text') 
      .attr('transform', `translate(63, 10)`).attr('font-size', 9).attr('text-anchor','start').attr('alignment-baseline','middle').attr('fill','#757575').text('Closed')


      legend
      .append('line')
      .attr('x1', 103).attr('x2', 115).attr('y1', 10).attr('y2', 10).attr('stroke-width', 1).attr('stroke', '#333')

      legend
      .append('circle')
      .attr('cx', 109).attr('cy', 10).attr('r', 2)
      .attr('fill', '#fff').attr('stroke-width', 1).attr('stroke', '#757575')
      
      legend
      .append('text') 
      .attr('transform', `translate(120, 10)`).attr('font-size', 9).attr('text-anchor','start').attr('alignment-baseline','middle').attr('fill','#757575').text(`Active`)



      let average = sChartITR
      .append('g')
      .attr('transform', `translate(220, 50)`)

      average
      .append('text') 
      .attr('transform', `translate(0, -4)`).attr('font-size', 10).attr('text-anchor','start').attr('alignment-baseline','middle').attr('fill','#757575').text('8 Weeks Average')

      average
      .append('text') 
      .attr('transform', `translate(0, 20)`).attr('font-size', 12).attr('text-anchor','start').attr('alignment-baseline','middle').attr('fill','#44A9DF').text(this.avgRaised.toFixed(0))

      average
      .append('text') 
      .attr('transform', `translate(0, 8)`).attr('font-size', 9).attr('text-anchor','start').attr('alignment-baseline','middle').attr('fill','#bcbcbc').text('Raised')

      average
      .append('text') 
      .attr('transform', `translate(50, 20)`).attr('font-size', 12).attr('text-anchor','start').attr('alignment-baseline','middle').attr('fill','#44A9DF').text(this.avgClosed.toFixed(0))

      average
      .append('text') 
      .attr('transform', `translate(50, 8)`).attr('font-size', 9).attr('text-anchor','start').attr('alignment-baseline','middle').attr('fill','#bcbcbc').text('Closed')




      let chartArea = sChartITR
      .append('g')
      .attr('transform', `translate(50, 130)`)
      
      
      chartArea // Axis X
      .append('line').attr('x1', 0).attr('x2', this.axisXWidth + 20).attr('y1', this.axisYHeight).attr('y2', this.axisYHeight).attr('stroke-width', .5).attr('stroke', '#333')
              
      // draw 0 and maxBounds on Axis Y1
      let gridY = 0
      let value1 = this.dataMaxValue1
      let value2 = this.dataMaxValue

      for(var i=0; i<6; i++) {
        chartArea
        .append('text') // Axis 1
        .attr('x', -2).attr('y',gridY).attr('font-size', 9).attr('text-anchor','end').attr('alignment-baseline','middle').attr('fill','#bcbcbc').text(value1)

        chartArea
        .append('text') // Axis 2
        .attr('x', this.axisXWidth+22).attr('y', gridY).attr('font-size', 9).attr('text-anchor','start').attr('alignment-baseline','middle').attr('fill','#bcbcbc').text(value2)

        chartArea
        .append('line')
        .attr('stroke', '#bcbcbc').attr('stroke-width', 0.3).attr('opacity', 0.5).attr('x1', 0).attr('x2', this.axisXWidth+20).attr('y1', gridY).attr('y2', gridY)

        gridY += (this.axisYHeight/5)
        value1 -= (this.dataMaxValue1/5)
        value2 -= (this.dataMaxValue/5)
      }



      // draw line chart in the line-group-box
      let raisedChart = chartArea
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .selectAll()
      .data(this.tickValues2)
      .enter()
      .append('path')
      .attr('d', d => `
              M${this.scaleXTimeline(this.parseDate(d.date))+0}, ${this.scaleY2(0)}
              L${this.scaleXTimeline(this.parseDate(d.date))+5}, ${this.scaleY2(0)}
              L${this.scaleXTimeline(this.parseDate(d.date))+10}, ${this.scaleY2(0)} Z`)
      .attr('fill', '#E0E0DF').attr('stroke', '#BDBCBC').attr('stroke-width', 0.3).attr('opacity', 1)
      .transition()
      .duration(500)
      .attr('d', d => `
              M${this.scaleXTimeline(this.parseDate(d.date))+0}, ${this.scaleY2(0)}
              L${this.scaleXTimeline(this.parseDate(d.date))+5}, ${this.scaleY2(d.raised)}
              L${this.scaleXTimeline(this.parseDate(d.date))+10}, ${this.scaleY2(0)} Z`)

      let ClosedChart = chartArea
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .selectAll()
      .data(this.tickValues3)
      .enter()
      .append('path')
      .attr('d', d => `
              M${this.scaleXTimeline(this.parseDate(d.date))+10}, ${this.scaleY2(0)}
              L${this.scaleXTimeline(this.parseDate(d.date))+15}, ${this.scaleY2(0)}
              L${this.scaleXTimeline(this.parseDate(d.date))+20}, ${this.scaleY2(0)} Z`)
      .attr('fill', '#B4E3FA').attr('stroke', '#44A9DF').attr('stroke-width', 0.3).attr('opacity', 1)
      .transition()
      .duration(500)
      .attr('d', d => `
              M${this.scaleXTimeline(this.parseDate(d.date))+10}, ${this.scaleY2(0)}
              L${this.scaleXTimeline(this.parseDate(d.date))+15}, ${this.scaleY2(d.closed)}
              L${this.scaleXTimeline(this.parseDate(d.date))+20}, ${this.scaleY2(0)} Z`)

      let raisedValue = chartArea
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .selectAll()
      .data(this.tickValues2)
      .enter()
      .append('text')
      .attr('x', d => this.scaleXTimeline(this.parseDate(d.date))+5)
      .attr('y', this.scaleY2(0))
      .attr('font-size', 9)
      .attr('text-anchor','middle')
      .attr('alignment-baseline','middle')
      .attr('fill','#bcbcbc')
      .text(d => d.raised)
      .transition()
      .duration(500)
      .attr('y', d => this.scaleY2(d.raised)-5)

      let closedValue = chartArea
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .selectAll()
      .data(this.tickValues3)
      .enter()
      .append('text')
      .attr('x', d => this.scaleXTimeline(this.parseDate(d.date))+15)
      .attr('y', this.scaleY2(0))
      .attr('font-size', 9)
      .attr('text-anchor','middle')
      .attr('alignment-baseline','middle')
      .attr('fill','#44A9DF')
      .text(d => d.closed)
      .transition()
      .duration(500)
      .attr('y', d => this.scaleY2(d.closed)-5)

      let lines = chartArea
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .append('path')
      .attr('d', this.lineZero(this.tickValues1))
      .style('stroke', '#757575').style('stroke-width', 1).attr('opacity', .7)
      .style('fill', 'none')
      .transition()
      .duration(500)
      .attr('d', this.lineFunc(this.tickValues1))

      let markup = chartArea
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .selectAll()
      .data(this.tickValues1)
      .enter()
      .append('circle')
      .attr('fill', '#fff')
      .attr('stroke', '#757575')
      .attr('stroke-width', 1)
      .attr('cx', d => this.scaleXTimeline(this.parseDate(d.date))+10)
      .attr('cy', this.axisYHeight)
      .attr('r', 2)
      .transition()
      .duration(500)
      .attr('cy', d => this.scaleY1(d.active))
      

      
      // put timeline-raised & month text under the aixs X(Timeline)
      chartArea // raised
      .append('g')
      .attr('transform', `translate(0, ${this.axisYHeight + 4})`)
      .selectAll()
      .data(this.dataXTimelineValues)
      .enter()
      .append('text')
      .attr('x', d => this.scaleXTimeline(d) + 10).attr('y', 6).attr('font-size', 9).attr('fill','#757575').style('font-family','roboto').attr('text-anchor', 'middle')
      .text(d => d3.timeFormat('%d')(d))

      chartArea // month
      .append('g')
      .attr('transform', `translate(0, ${this.axisYHeight + 7})`)
      .selectAll()
      .data(this.dataXTimelineValuesMonth)
      .enter()
      .append('text')
      .attr('x', d => this.scaleXTimeline(d) + 10).attr('y', 15).attr('font-size', 10).attr('fill','#333').style('font-family','roboto').attr('text-anchor', 'middle')
      .text(d => d3.timeFormat('%b')(d))
    },
  }
}
           