import * as d3 from 'd3'
import Defs from '../../../../../../includes/primitives/Color_Defs'
import Filter from '../../../../../../includes/primitives/Filter_Defs'
import { contourDensity } from 'd3'
export default {
  data: () => ({
    path: {
      TOPSIDES: '/topsides_dashboard',
      TOPSIDESMODULES: '/topsides_dashboard/topsides_modules',
      TOPSIDESCOMMON: '/topsides_dashboard/topsides_common',
      HULL: '/hull_dashboard/overall',
      LQ: '/lq_dashboard'
    },
    props:[
      {key:'ITEM',              head1: 'Stage',y:0,x: 65, tx: 25, textanchor: 'start'},
      {key:'TOTAL',              head1: 'Total', y:0,x: 152, tx: 165, },
      {key:'REMAIN',             head1: 'Remain',y:0,x: 202, tx: 217, },
      {key:'ACTUAL_CUM',        head1: 'Act. Cum.',y:-4,x: 260, tx: 272},
      {key:'ACTUAL_LAST_WK',    head1: 'Last',x: 310, tx: 320},
      {key:'ACTUAL_THIS_WK',    head1: 'This',x: 355,  tx: 365, color: ''},
      {key:'ACTUAL_LAST_MONTH', head1: 'Last',x: 405, tx: 415, color: ''},
      {key:'ACTUAL_THIS_MONTH', head1: 'This',x: 450, tx: 460, color: ''},
    ],
    overallData:[
      {AREA:"TOPSIDES",AREA_DESCR:"Topsides"},
      {AREA:"HULL",AREA_DESCR:"Hull"},
      {AREA:"TOPSIDES MODULES",AREA_DESCR:"Topsides Modules"},
      {AREA:"TOPSIDES COMMON",AREA_DESCR:"Topsides Common"},
      {AREA:"LQ",AREA_DESCR:"Living Quarter"},
      {AREA:"RCxx",AREA_DESCR:"Topsides Piperack"},
      {AREA:"ME01",AREA_DESCR:"Flash Gas Compression"},
      {AREA:"ME02",AREA_DESCR:"Meg & Fuel Gas"},
      {AREA:"ME04",AREA_DESCR:"Power Generation",MODULE_DESCR:"ME04-Power Gen."},
      {AREA:"ME05",AREA_DESCR:"E&I Building",MODULE_DESCR:"ME05-E&I Bldg."},
      {AREA:"MW01",AREA_DESCR:"Reception Facilities & Production Separation"},
      {AREA:"MW02",AREA_DESCR:"Hc Dewpointing & Condensate Stabilisation"},
      {AREA:"MW04",AREA_DESCR:"Power Generation",MODULE_DESCR:"MW04-Power Gen."},
      {AREA:"MW05",AREA_DESCR:"Utility"},
      {AREA:"FL01",AREA_DESCR:"Flare Stack"},
      {AREA:"KO01",AREA_DESCR:"Flare KO Drum"},
      {AREA:"INTEGRATION",AREA_DESCR:"Integration"},
      {AREA:"M000",AREA_DESCR:"Module Common"},
    ],
    width: 465,
    rowHeight: 18,
    disc: null,
    topsideId: ['TOPSIDES MODULES', 'TOPSIDES COMMON']
  }),

  methods: {
    drawAreaStatus() {
      this.overallData.forEach(item => {
        let id = this.topsideId.includes(item.AREA) ? item.AREA.replace(' ', '_') : item.AREA
        //우리 데이터에 TOPSIDES MODULES와 TOPSIDES COMMON은 한칸씩 띄어있기때문에 이렇게 가져오자
        let target = this.innerSvg.select(`#${id}`)
        if(target.empty()) return

        let translate = (target.attr('transform') || '').match(/translate(.*?)\)/g)
        let dimention = translate[0].match(/-?\d+\.?(\d+)?/g)

        let _self = this
        let chart = this.innerSvg.select('#spoolSummary') // jiyoun XD name
        .append('g')
        .attr('transform', `translate(${Number(dimention[0]) + 100}, ${Number(dimention[1]) + 20})`)

        // Area No.
        chart
        .append('text')
        .attr('transform', `translate(-100, 0)`) 
        .style('font-family', 'Roboto-Regular, Roboto')
        .style('font-size', 18)
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text(item.AREA)
        chart
        .append('text')
        .attr('transform', `translate(-100, -17)`) 
        .style('font-family', 'Roboto-Regular, Roboto')
        .style('font-size', 11)
        .style('fill','#0277BD')
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text(item.AREA_DESCR)

        if (['HULL', 'TOPSIDES', 'LQ','TOPSIDES MODULES','TOPSIDES COMMON'].includes(item.AREA)) {
          // Button & Label
          let buttonWrap = chart 
          .append('g')
          .attr('id', 'button_wrapper')
          .attr('transform', `translate(300, -10)`)
          .style('font-family', 'roboto')
          .style('cursor', 'pointer') 
          .on('mouseover', function(){  
            d3.select(this).transition().duration(100).style('opacity',.5) 
          }) 
          .on('mouseout', function(){ 
            d3.select(this).transition().duration(100).style('opacity',1) 
          })
          .on('click', () => {
            // console.log(item.AREA) 로 클릭했을때 제대로 들어오는것 확인하고 조건으로 페이지 이동
            let area = item.AREA.replace(' ','')
            let request_ = {
              dataType: 'row',    
              action: {
                type: 'direct',
                target: 'url',
                path: `/service/construction/construction_dashboards${this.path[area]}`
              },
              filters: {}
            }
            this.$emit('request-action', request_)
          })
          buttonWrap
          .append('rect')
          .attr('transform', `translate(0, 0)`)
          .attr('rx', 3)
          .attr('ry', 3)
          .attr('width', 40)
          .attr('height', 14)
          .attr('fill', '#e5e5e5')
          buttonWrap
          .append('text')
          .attr('transform', `translate(20, 8)`)
          .style('font-family', 'Roboto-Regular, Roboto')
          .style('font-size', 9)
          .style('fill', '#000')
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          .text('More')

          let requestImgs = ['HULL', 'TOPSIDES', 'LQ','TOPSIDES MODULES','TOPSIDES COMMON']

          if (requestImgs.includes(item.AREA)) {
            let id = this.topsideId.includes(item.AREA) ? item.AREA.replace(' ', '_') : item.AREA
            d3.select(`#img_${id}`)
            .on('mouseover', function(){ 
              d3.select(this).style('cursor', 'pointer')
              d3.select(this).style('opacity', 0.7)
            })
            .on('mouseleave', function(){ 
              d3.select(this).style('opacity', 1)
            })
            .on('click', () => {
              let area = id.replace('_','')
              let request_ = {
                dataType: 'row',    
                action: {
                  type: 'direct',
                  target: 'url',
                  path: `/service/construction/construction_dashboards${this.path[area]}`
                },
                filters: {}
              }
              this.$emit('request-action', request_)
            })
          }
        } 
        target.remove()
          /**--------------------------------------------------------------------------------------------------------------------------------- */

        let PreOutfitting = chart
        .append('g')
        .attr('transform', `translate(-120.5, 20.5)`)

        PreOutfitting
        .append('rect')
        .attr('transform', `translate(295, -14)`)
        .attr('width', 170)
        .attr('height', 33)
        .attr('fill', '#F1F8E9')
        .attr('opacity',0.7)

        // PreOutfitting.append('line').attr('x1',20).attr('y1',-10).attr('x2',this.width).attr('y2',-10).attr('stroke-width', 0.2).attr('stroke', '#000')
        PreOutfitting.append('line').attr('x1',20).attr('y1',20).attr('x2',this.width).attr('y2',20).attr('stroke-width', 0.2).attr('stroke', '#000')

        // header
        PreOutfitting
        .append('g')
        .attr('transform', `translate(0, 0)`)
        .selectAll('text')
        .data(this.props)
        .enter()
        .append('text')
        .attr('x', d => d.x)
        .attr('y', 12)
        .style('font-family','roboto')
        .style('font-size',10)
        .attr('text-anchor', 'middle')
        .attr('fill', '#333')
        .text(d => d.head1)

        PreOutfitting
        .append('text')
        .attr('transform', `translate(334, -2)`)
        .style('font-family','roboto')
        .style('font-size',10)
        .attr('text-anchor', 'middle')
        .attr('fill', '#2E7D32')
        .text('Weekly')
        PreOutfitting
        .append('text')
        .attr('transform', `translate(430, -2)`)
        .style('font-family','roboto')
        .style('font-size',10)
        .attr('text-anchor', 'middle')
        .attr('fill', '#2E7D32')
        .text('Monthly')
        

        let row_group = PreOutfitting
        .append('g')
        .attr('class', '_row_data_group')
        .attr('transform', `translate(0, 33)`)

        // console.log(item.AREA)
        let TableArea = this.DataItems.filter(d => d.AREA == item.AREA)

        let discs = [...new Set(TableArea.map(d => d.AREA))]
        let sumY = 0
        discs.forEach((disc, r) => {

          const group = TableArea.filter(d => disc.includes(d.AREA))
          // console.log(group)

          group.forEach((row, i) => {
            let keys = Object.keys(row)
            keys.shift()
            keys.forEach(key => {

              let idx = this.props.findIndex(e => e.key === key)
              if(idx == -1) return
              // idx가 -1이 되니까 에러난다 return을 시켜주면 나지않는다
              // console.log(key, idx)
              // if(0 < idx && idx < 4){
              // row_group
              //   .append('text')
              //   .text(row[key] != null ? row[key].toLocaleString() : '')
              //   .attr('x', (d,i) => this.props[idx].tx)
              //   .attr('y', () => sumY + (i * this.rowHeight))
              //   .attr('text-anchor', (d, i) =>  this.props[idx].textanchor ? this.props[idx].textanchor : 'end' )
              //   .attr('fill', (d, i) => {
              //     if (key !== 'REMAIN') {
              //       return this.props[idx].color && row[key] !== 0 ? this.props[idx].color : '#757575'
              //     }else {
              //       return row[key] > 0 ? 'red' : '#757575'
              //     }
              //   })
              //   .style('font-family','roboto')
              //   .style('font-size',10)
              //   .style('cursor', 'pointer')
              //   .on('mouseover', function() {
              //     d3.select(this).transition().duration(50)
              //     .style('opacity', 0.5)
              //   })
              //   .on('mouseout', function(){
              //     d3.select(this).transition().duration(50)
              //     .style('opacity', 1)
              //   })
              //   .on('click', () => { 
              //     console.log(row.ITEM)
              //     // let request = {CATEGORY : _tmp.CATEGORY, STATUS_CODE: _data[i].title == 'Open'? 'N' : 'C', SOURCE:_tmp.SOURCE}
              //     // let request_ = this.getRequestColumnProps('request', request, this.ColumnProps)
              //     // this.$emit('request-action', request_)
              //   })
              // }
              // else{
                row_group
                .append('text')
                .text(row[key] != null ? row[key].toLocaleString() : '')
                .attr('x', (d,i) => this.props[idx].tx)
                .attr('y', () => sumY + (i * this.rowHeight))
                .attr('text-anchor', (d, i) =>  this.props[idx].textanchor ? this.props[idx].textanchor : 'end' )
                .attr('fill', (d, i) => {
                  if (key !== 'REMAIN') {
                    return this.props[idx].color && row[key] !== 0 ? this.props[idx].color : '#757575'
                  }else {
                    return row[key] > 0 ? 'red' : '#757575'
                  }
                })
                .style('font-family','roboto')
                .style('font-size',10)
              // }
            })
          })
        })
      })

      this.drawPie()
    },
    drawPie(){
      let data = null, style = null, tmp = null,Circle = null,Guideline = null

      let overallSummary = this.svg
      .append('g')
      .attr('transform', `translate(40.5, 140.5)`)

      overallSummary
      .append('rect')
      .attr('transform', `translate(0, 0)`)
      .attr('rx', 10)
      .attr('ry', 10)
      .attr('width', 500)
      .attr('height', 600)
      .attr('fill', '#FAFAFA')
      overallSummary
      .append('text')
      .attr('transform', `translate(20, 30)`) 
      .style('font-family', 'Roboto-Regular, Roboto')
      .style('font-size', 16)
      .style('fill','#0277BD')
      .style('font-weight', 'bold')
      .attr('text-anchor', 'start')
      .text('Overall Summary')
      overallSummary
      .append('text')
      .attr('transform', `translate(20, 80)`) 
      .style('font-family', 'Roboto-Regular, Roboto')
      .style('font-size', 14)
      .style('fill','#0277BD')
      .attr('text-anchor', 'start')
      .text('Spool -')
      overallSummary
      .append('text')
      .attr('transform', `translate(20, 420)`) 
      .style('font-family', 'Roboto-Regular, Roboto')
      .style('font-size', 14)
      .style('fill','#0277BD')
      .attr('text-anchor', 'start')
      .text('Test pack -')

      // Circle Electrical
      style = {
        id: 'ELE1',
        x: 100,
        y: 130,
        name: 'Fabricated',
        sort: 'N',
      }
      tmp = this.Queries.SQL1.find(f=> f.DISC_DESCR == 'Piping' && f.ITEM == 'Spool Fabricated')
      // data = [
      //   {title: 'Remain',  value: 100-tmp.PROG,  qty: tmp.REMAIN},
      //   {title: 'Issued',  value: tmp.PROG,      qty: tmp.ACTUAL}
      // ]
      data = [
        {title: 'Remain'  ,  value: tmp.REMAIN / tmp.TOTAL * 100, qty: tmp.REMAIN},
        {title: 'Complete'   ,  value: tmp.COMPLETED / tmp.TOTAL * 100, qty: tmp.COMPLETED},
        
      ]
      Circle = {
        CircleDiameter: 80,
        // CircleColor: ['LightGray', 'Gray', 'Yellow', 'Gold', 'Orange','Pink','Violet','Red','Thistle','Purple','Indigo','LightBlue','SkyBlue','SteelBlue','YellowGreen','DarkGreen' ],
        CircleColorSet: ['LightGray','LightBlue' ],
        CircleRadius: 0,
        CircleHoleSize: 40,
        CircleStackSize: 5,
        CircleSliceSize: 3,
        CircleTitleSize: 11,
        CircleLegendSpace: 50,
        CircleLegendPosition: 'bottom',
        outerRadius: 30
      }
      Guideline = {
        GuideDisplay: 'N',
      }
      this.draw_pie(overallSummary, data, style, Circle, Guideline) // ---------------------------> 

      // Circle Electrical
      style = {
        id: 'ELE2',
        x: 240,
        y: 130,
        name: 'Erected',
        sort: 'N',
      }
      tmp = this.Queries.SQL1.find(f=> f.DISC_DESCR == 'Piping' && f.ITEM == 'Spool Erected')

      data = [
        {title: 'Remain'  ,  value: tmp.REMAIN / tmp.TOTAL * 100, qty: tmp.REMAIN},
        {title: 'Complete'   ,  value: tmp.COMPLETED / tmp.TOTAL * 100, qty: tmp.COMPLETED},
      ]
      Circle = {
        CircleDiameter: 80,
        CircleColorSet: ['LightGray','Yellow' ],
        CircleRadius: 0,
        CircleHoleSize: 40,
        CircleStackSize: 5,
        CircleSliceSize: 3,
        CircleTitleSize: 11,
        CircleLegendSpace: 50,
        CircleLegendPosition: 'bottom',
        outerRadius: 30
      }
      Guideline = {
        GuideDisplay: 'N',
      }
      this.draw_pie(overallSummary, data, style, Circle, Guideline) // ---------------------------> 

      // Circle Instrument
      style = {
        id: 'INS',
        x: 380,
        y: 130,
        name1: 'Welded',
        name: '/Bolted',
        sort: 'N',
      }
      tmp = this.Queries.SQL1.find(f=> f.DISC_DESCR == 'Piping' && f.ITEM == 'Spool Welded/Bolted')

      data = [
        {title: 'Remain'  ,  value: tmp.REMAIN / tmp.TOTAL * 100, qty: tmp.REMAIN},
        {title: 'Complete'   ,  value: tmp.COMPLETED / tmp.TOTAL * 100, qty: tmp.COMPLETED},
      ]
      Circle = {
        CircleDiameter: 80,
        CircleColorSet: ['LightGray','Thistle' ],
        CircleRadius: 0,
        CircleHoleSize: 40,
        CircleStackSize: 5,
        CircleSliceSize: 3,
        CircleTitleSize: 11,
        CircleLegendSpace: 50,
        CircleLegendPosition: 'bottom',
        outerRadius: 30
      }
      Guideline = {
        GuideDisplay: 'N',
      }
      this.draw_pie(overallSummary, data, style, Circle, Guideline) // ---------------------------> 

      // Circle Instrument
      style = {
        id: 'INS2',
        x: 170,
        y: 270,
        name: 'Supported',
        sort: 'N',
      }
      tmp = this.Queries.SQL1.find(f=> f.DISC_DESCR == 'Piping' && f.ITEM == 'Spool Supported')

      data = [
        {title: 'Remain'  ,  value: tmp.REMAIN / tmp.TOTAL * 100, qty: tmp.REMAIN},
        {title: 'Complete'   ,  value: tmp.COMPLETED / tmp.TOTAL * 100, qty: tmp.COMPLETED},
      ]
      Circle = {
        CircleDiameter: 80,
        CircleColorSet: ['LightGray','SkyBlue' ],
        CircleRadius: 0,
        CircleHoleSize: 40,
        CircleStackSize: 5,
        CircleSliceSize: 3,
        CircleTitleSize: 11,
        CircleLegendSpace: 50,
        CircleLegendPosition: 'bottom',
        outerRadius: 30
      }
      Guideline = {
        GuideDisplay: 'N',
      }
      this.draw_pie(overallSummary, data, style, Circle, Guideline) // ---------------------------> 

      // Circle Instrument
      style = {
        id: 'INS3',
        x: 320,
        y: 270,
        name: 'RFT',
        sort: 'N',
      }
      tmp = this.Queries.SQL1.find(f=> f.DISC_DESCR == 'Piping' && f.ITEM == 'Spool RFT')

      data = [
        {title: 'Remain'  ,  value: tmp.REMAIN / tmp.TOTAL * 100, qty: tmp.REMAIN},
        {title: 'Complete'   ,  value: tmp.COMPLETED / tmp.TOTAL * 100, qty: tmp.COMPLETED},
      ]
      Circle = {
        CircleDiameter: 80,
        CircleColorSet: ['LightGray','YellowGreen' ],
        CircleRadius: 0,
        CircleHoleSize: 40,
        CircleStackSize: 5,
        CircleSliceSize: 3,
        CircleTitleSize: 11,
        CircleLegendSpace: 50,
        CircleLegendPosition: 'bottom',
        outerRadius: 30
      }
      Guideline = {
        GuideDisplay: 'N',
      }
      this.draw_pie(overallSummary, data, style, Circle, Guideline) // ---------------------------> 
       // Circle Instrument
      style = {
        id: 'INS4',
        x: 170,
        y: 470,
        name: 'Tested',
        sort: 'N',
      }
      tmp = this.Queries.SQL1.find(f=> f.DISC_DESCR == 'Piping' && f.ITEM == 'Tested')

      data = [
        {title: 'Remain'  ,  value: tmp.REMAIN / tmp.TOTAL * 100, qty: tmp.REMAIN},
        {title: 'Complete'   ,  value: tmp.COMPLETED / tmp.TOTAL * 100, qty: tmp.COMPLETED},
      ]
      Circle = {
        CircleDiameter: 80,
        CircleColorSet: ['LightGray','Gold' ],
        CircleRadius: 0,
        CircleHoleSize: 40,
        CircleStackSize: 5,
        CircleSliceSize: 3,
        CircleTitleSize: 11,
        CircleLegendSpace: 50,
        CircleLegendPosition: 'bottom',
        outerRadius: 30
      }
      Guideline = {
        GuideDisplay: 'N',
      }
      this.draw_pie(overallSummary, data, style, Circle, Guideline) // ---------------------------> 
       // Circle Instrument
      style = {
        id: 'INS5',
        x: 320,
        y: 470,
        name: 'Reinstated',
        sort: 'N',
      }
      tmp = this.Queries.SQL1.find(f=> f.DISC_DESCR == 'Piping' && f.ITEM == 'Reinstated')

      data = [
        {title: 'Remain'  ,  value: tmp.REMAIN / tmp.TOTAL * 100, qty: tmp.REMAIN},
        {title: 'Complete'   ,  value: tmp.COMPLETED / tmp.TOTAL * 100, qty: tmp.COMPLETED},
      ]
      Circle = {
        CircleDiameter: 80,
        CircleColorSet: ['LightGray','DarkGreen' ],
        CircleRadius: 0,
        CircleHoleSize: 40,
        CircleStackSize: 5,
        CircleSliceSize: 3,
        CircleTitleSize: 11,
        CircleLegendSpace: 50,
        CircleLegendPosition: 'bottom',
        outerRadius: 30
      }
      Guideline = {
        GuideDisplay: 'N',
      }
      this.draw_pie(overallSummary, data, style, Circle, Guideline) // ---------------------------> 
    },
    draw_pie(selection, _data, _style, _Circle, _Guideline) { 
      this.Circle = { ...this.Circle, ..._Circle }
      this.Guideline = { ...this.Guideline, ..._Guideline }

      let dataLength = _data.length

      this.Circle.CircleColorSet.forEach((c,i)=> {
        Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', [c], [c, 0.5, 1.0, 0.5, -0.2], this.localId)
      })

      let Shadow = selection
      .append('defs') // Tube Color (Left)
      .append('radialGradient').attr('id', `${this.localId}__Shadow`)
      Shadow.append('stop').attr('stop-color', '#a4a4a4').attr('offset', '0.00').attr('stop-opacity', '1')
      Shadow.append('stop').attr('stop-color', '#ffffff').attr('offset', '1.00').attr('stop-opacity', '0.37')


      this.PieStacks = []
      this.pieCenterAngles = []
      this.pieSectors = []

      var sortData = _data
      if (_style.sort == 'Y') sortData.sort(this.executeSort)
      // Pushing Data [PieStacks], [pieCenterAngles]
      var sum = 0
      var total = 0
      var complete = 0
      _data.forEach((item, i) => {
        sum = sum + _data[i].value
        total += item.qty
      })
      // _data.forEach((item, i) => {
      //   sum = _data[0].value - _data[2].value -_data[1].value
      //   complete =item.qty
      // })
    
      let cumulative = 0
      _data.forEach((item, j) => {
        sortData.forEach((sItem, i) => {
          if (sItem.title == item.title) this.PieStacks.push(this.Circle.CircleDiameter - i * this.Circle.CircleStackSize)
        })
        cumulative = cumulative + (_data[j].value/sum)
        this.pieCenterAngles.push(cumulative - (_data[j].value/sum) / 2)
      })

      this.SharedColorSet = dataLength > this.Circle.CircleColorSet.length ? Array.from({ length: dataLength }, (_, i) => this.Circle.CircleColorSet[i % this.Circle.CircleColorSet.length]) : this.Circle.CircleColorSet
      this.SharedColorSetType = this.Circle.CircleColorType

      let centerTitle = selection

      centerTitle
      .append('text')
      .attr('transform', `translate(${_style.x}, ${_style.y})`)
      .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'middle')
      .text(_style.name)

      centerTitle
      .append('text')
      .attr('transform', `translate(${_style.x}, ${_style.y-12})`)
      .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'middle')
      .text(_style.name1)

      centerTitle
      .append('line')
      .attr('x1', _style.x-26).attr('y1', _style.y+4).attr('x2', _style.x+26).attr('y2', _style.y+4).attr('stroke-width', 0.5).attr('stroke', '#757575')

      centerTitle
      .append('text')
      .attr('transform', `translate(${_style.x}, ${_style.y+16})`)
      .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#757575').style('text-anchor', 'middle')
      .text(total.toLocaleString())


      if(this.Circle.CircleLegendPosition == 'bottom')
      var legendsPie = selection
      .append('g')
      .attr('transform', `translate(${_style.x-30}, ${_style.y + this.Circle.CircleLegendSpace})`)

      else if(this.Circle.CircleLegendPosition == 'right')
      legendsPie = selection
      .append('g')
      .attr('transform', `translate(${_style.x + this.Circle.CircleLegendSpace}, ${_style.y - this.Circle.CircleDiameter / 1.5})`)

      _data.forEach((d,i) => {
        legendsPie
        .append('rect')
        .attr('transform', `translate(${-14}, ${(i*16) + 0})`)
        .attr('width', 8).attr('height', 8).attr('stroke-width', 0.5).attr('stroke', '#bcbcbc').attr('fill', `url(#${this.localId}__LinearA4${this.SharedColorSet[i]}0)`)

        legendsPie
        .append('text')
        .attr('id', `${_style.id}_LG_${i}`)
        .attr('transform', `translate(${-3}, ${(i*16) + 7})`)
        .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'mistartddle')
        .text(_data[i].title)

        legendsPie
        .append('text')
        .attr('transform', `translate(${this.getNodeElValue(`#${_style.id}_LG_${i}`, 'width') + 2}, ${(i*16) + 7})`)
        .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#757575').style('text-anchor', 'mistartddle')
        .text(`(${_data[i].qty.toLocaleString()})`)
      })
      

      this.get_CoordinatorsXY(_style.x, _style.y, -this.Guideline.GuideInside, 'inX', 'inY')
      this.get_CoordinatorsXY(_style.x, _style.y, this.Guideline.GuideOutside, 'outX', 'outY')
      this.get_PieChartData(_data)

      Filter.setDefs_DropShadow(selection, `_DropShadow`, 1 , 0.1 , 1, 1)

      if (this.Circle.CircleShadowDisplay == 'Y') {
        selection // Shadow
        .append('ellipse')
        .attr('cx', _style.x)
        .attr('cy', _style.y + this.Circle.CircleDiameter)
        .attr('rx', this.Circle.CircleDiameter)
        .attr('ry', this.Circle.CircleDiameter * 0.1)
        .attr('fill', `url(#${this.localId}__Shadow)`)
      }
      var arc = d3.arc()
      .outerRadius(this.Circle.outerRadius)
      .innerRadius(this.Circle.CircleHoleSize)
      .cornerRadius(this.Circle.CircleRadius) // Coner Radius

      var pie = d3.pie()
      .sort(null)
      .value((d) => d.value)
      .padAngle(this.Circle.CircleSliceSize/100) // Padding Pie

      let donutGroup = selection
      .append('g')
      .attr('transform', `translate(${_style.x},${_style.y})`)

      donutGroup
      .selectAll('path')
      .data(pie(this.pieSectors))
      .enter()
      .append('path')
      .attr('d', arc)
      .attr('fill', (d, i) => `url(#${this.localId}__LinearA4${this.SharedColorSet[i]}0)`)
      .style('filter', `url(#_DropShadow)`)
      .transition()
      .duration(750)
      .attrTween('d', function(d) {
          var interpolate = d3.interpolate({startAngle: 0, endAngle: 0}, d);
          return function(t) {
              return arc(interpolate(t));
          };
        });
      if (_Guideline.GuideDisplay=='Y') this.drawGuidline(selection, _style.id)
    },
    get_CoordinatorsXY(Cx, Cy, size, nameX, nameY) {
      var ra
      var a = 0 // Angle
      var aCalc = 0 //
      var aRad = 0 // Angle in Rad
      var z = 0 // Size z
      var x = 0 // Side x
      var endX = 0 // endX coordinate
      var endY = 0 // endY coordinate

      this.pieCenterAngles.forEach((item, sn) => {
        ra = this.PieStacks[sn] + size
        a = 360 * item
        aCalc = a > 180 ? 360 - a : a
        aRad = (aCalc * Math.PI) / 180
        z = Math.sqrt(2 * ra * ra - 2 * ra * ra * Math.cos(aRad))
        x = aCalc <= 90 ? ra * Math.sin(aRad) : ra * Math.sin(((180 - aCalc) * Math.PI) / 180)
        endY = Math.sqrt(z * z - x * x)
        endX = a <= 180 ? ra + x : ra - x

        this.pieSectors[sn] = {
          ...this.pieSectors[sn],
          ...{
            [nameX]: endX + Cx - ra,
            [nameY]: endY + Cy - ra,
          }
        }
      })
    },
    get_PieChartData(_data) {
      var ra
      var a = 0 // Angle
      var aCalc = 0 //
      var arcSweep = 0
      var aRad = 0 // Angle in Rad
      var z = 0 // Size z
      var x = 0 // Side x
      var endX = 0 // SVG endX coordinate
      var endY = 0 // SVG endY coordinate
      var Ro = 0 // Rotation

      var h_ra
      var h_z = 0 // Size z
      var h_x = 0 // Side x
      var h_endX = 0 // SVG endX coordinate
      var h_endY = 0 // SVG endY coordinate

      var textSpace = 0
      var direct = 0

      _data.forEach((item, sn) => {
        ra = this.PieStacks[sn]
        h_ra = this.Circle.CircleHoleSize // Hole
        a = 360 * (item.value / 100)
        aCalc = a > 180 ? 360 - a : a
        aRad = (aCalc * Math.PI) / 180
        z = Math.sqrt(2 * ra * ra - 2 * ra * ra * Math.cos(aRad))
        h_z = Math.sqrt(2 * h_ra * h_ra - 2 * h_ra * h_ra * Math.cos(aRad)) // Hole
        if (aCalc <= 90) {
          x = ra * Math.sin(aRad)
          h_x = h_ra * Math.sin(aRad) // Hole
        } else {
          x = ra * Math.sin(((180 - aCalc) * Math.PI) / 180)
          h_x = h_ra * Math.sin(((180 - aCalc) * Math.PI) / 180)
        }

        endY = Math.sqrt(z * z - x * x)
        h_endY = Math.sqrt(h_z * h_z - h_x * h_x) // Hole

        if (a <= 180) {
          endX = ra + x
          h_endX = h_ra + h_x
          arcSweep = 0
        } else {
          endX = ra - x
          h_endX = h_ra - h_x
          arcSweep = 1
        }
        if (this.pieCenterAngles[sn] < 0.5) {
          direct = this.Guideline.GuideExtend
          // align = 'start'
          textSpace = 3
        } else {
          direct = -this.Guideline.GuideExtend
          // align = 'end'
          textSpace = -3
        }

        this.pieSectors[sn] = {
          ...this.pieSectors[sn],
          ...{
            angle: a,
            value: item.value,
            title: item.title,
            color: this.SharedColorSet[sn],
            arcSweep: arcSweep,
            Ra: ra,
            endX: endX,
            endY: endY,

            h_Ra: h_ra, // Hole
            h_endX: h_endX, // Hole
            h_endY: h_endY, // Hole
            Ro: Ro,
            direct: direct,
            // align: align,
            textSpace: textSpace,
            stack: this.PieStacks[sn]
          }
        }
        Ro = Ro + a
      })
    },
  }
}
