<template>
  <div :id="localId"></div>
</template>

<script>
import * as d3 from 'd3'
import mx_Core from './includes/mixins/importer'            // Global & Local Mixins

export default {
  name: "j-chart-donut",
  mixins: [mx_Core],
  props: {
    id: String,
    modeExport: Boolean,

    FilterValues: {
      type: Object,
      default: () => ({})
    },

    Canvas: { type: Object, default: () => ({}) },
    DataItems: { type: Array, default: () => ([]) },

    ColumnProps: { type: Array, default: () => ([]) },

    // Milestones Resulat
    // values: {
    //   type: Object,
    //   default: () => ({
    //     milestone: [],
    //     note: '',
    //   })
    // }
  },


  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
  },
  mounted() {
    this.register(this.draw)
  },

  methods: {
    draw() {
      if (!this.ready2draw) return

      this.clear()
      this.setDefaultValues()
      this.drawCanvas()

      this.drawChart()

      this.complete()
    },

    drawChart() {
      
      
      this.Draw_Charts()



    },

  }
}
</script>