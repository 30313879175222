import * as d3 from 'd3'
import __M from 'moment'

export default {
  data: () => ({
    dataSet: [],
    dataMinValue1: 0,
    dataMaxValue1: 0,
    dataAvgValue: 0,

    dataMinValue2: 0,
    dataMaxValue2: 0,

    axisYHeight: 350,
    axisXWidth: 1000,

    scaleY1: null,
    scaleY2: null,
    lineFunc: null,
    lineZero: null,

    dataXTimelineValues: '',
    dataXTimelineValuesMonth: '',

    tickValuesCumO: [],
    tickValuesWKO: [],
    lastDate: 0,
    lastValue: 0,


    PieStacks: [],
    pieCenterAngles: [],
    pieSectors: []
  }),
  computed: {
    ready2draw() {

      // console.log('------------------------------')
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)

      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },
  methods: {
    setDefaultValues(){
      this.timelineFor = 'history'
      this.timelineKeyName = 'CUTOFF'

      this.timeline.baseX = this.Canvas.CanvasChartX
      this.timeline.baseY = this.Canvas.CanvasChartY + this.axisYHeight
      this.timeline.width = this.axisXWidth

      this.dataSet = JSON.parse(JSON.stringify(this.DataItems))
      this.setTimelineEnv(this.dataSet)

      this.dataSet.forEach(d => {
        let date__ = this.getTimelineCDate(d[this.timelineKeyName])
        d.sDate = date__.sDate
        d.mDate = date__.mDate
        d.cDate = date__.eDate
        d.date = d3.timeParse('%Y-%m-%d')(d[this.timelineKeyName])
      })
      
      this.timeline.height = this.timeline.style.week.height
      if (this.timeline.style.month.display == 'Y') this.timeline.height += this.timeline.style.month.height
      if (this.timeline.style.year.display == 'Y') this.timeline.height += this.timeline.style.year.height

      // Get tickValuesCumO / tickValuesWKO..
      this.tickValuesCumO = this.dataSet.filter(d => typeof d.CUM_OPEN != 'undefined').map(d => ({ date: d.mDate, cum: d.CUM_OPEN })) //line
      this.tickValuesWKO  = this.dataSet.filter(d => typeof d.WK_OPEN != 'undefined').map(d => ({ date: d.mDate, week: d.WK_OPEN }))  //bar
      this.tickValuesCumC = this.dataSet.filter(d => typeof d.CUM_CLOSED != 'undefined').map(d => ({ date: d.mDate, cum: d.CUM_CLOSED }))   //line
      this.tickValuesWKC  = this.dataSet.filter(d => typeof d.WK_CLOSED != 'undefined').map(d => ({ date: d.mDate, week: d.WK_CLOSED }))    //bar
      
      // getMaxBound for cum / week
      this.dataMaxValue1 = this.getMaxBound(Math.max(...[...this.tickValuesCumO.map(d => d.cum), ...this.tickValuesCumC.map(d => d.cum)]))  //Max값- 두개의 line값 중에서 제일 큰값 구하기
      this.dataMaxValue2 = this.getMaxBound(Math.max(...[...this.tickValuesWKO.map(d => d.week), ...this.tickValuesWKC.map(d => d.week)]))  //Max값- 두개의 bar값 중에서 제일 큰값 구하기

      // Get Average
      this.tickValuesWKO.forEach(d => { this.dataAvgValue += Number(d.week)})
      if (this.dataAvgValue == 0) this.dataAvgValue = 0; else this.dataAvgValue = this.dataAvgValue/8

      this.lastWeek = this.tickValuesWKO[this.tickValuesWKO.length-1].week
      this.lastValue = this.tickValuesCumO[this.tickValuesCumO.length-1].cum
      this.lastDate = this.tickValuesCumO[this.tickValuesCumO.length-1].date

      this.scaleY1 = d3
      .scaleLinear()
      .domain([this.dataMinValue1, this.dataMaxValue1])
      .range([this.axisYHeight, 0])
      
      this.scaleY2 = d3
      .scaleLinear()
      .domain([this.dataMinValue2, this.dataMaxValue2])
      .range([this.axisYHeight, 0])

      this.lineZero = d3.line()
      .x(d => this.timeline.scale(d.date))
      .y(this.axisYHeight)
      
      this.lineFunc = d3.line()
      .x(d => this.timeline.scale(d.date))
      .y(d => this.scaleY1(d.cum))

      // Table Properties
      this.table.width = this.timeline.width
      this.table.height = this.table.lineHeight * this.table.headers.length
      this.table.cellWidth = (this.axisXWidth / this.timeline.week.length)-15

      // Summary Data
      this.style.value = [this.Queries.SQL2[0].TOTAL, this.Queries.SQL2[0].OPEN,this.Queries.SQL2[0].OVERDUE, this.Queries.SQL2[0].BP_REVIEW, this.Queries.SQL2[0].TFMC_DEV]
    
      let dataLength = this.Queries.SQL1.length

      var sortData = JSON.parse(JSON.stringify(this.Queries.SQL1))
      sortData.sort(this.executeSort)

      // Pushing Data [PieStacks], [pieCenterAngles]
      var sum = 0
      this.Queries.SQL1.forEach((item, i) => {
        sum = sum + this.Queries.SQL1[i].value
      })

    
      let cumulative = 0
      this.Queries.SQL1.forEach((item, j) => {
        sortData.forEach((sItem, i) => {
          if (sItem.title == item.title) this.PieStacks.push(this.Circle.CircleDiameter - i * this.Circle.CircleStackSize)
        })
        cumulative = cumulative + (this.Queries.SQL1[j].value/sum)
        this.pieCenterAngles.push(cumulative - (this.Queries.SQL1[j].value/sum) / 2)
      })
      
      // Set shared colorset & type -------------------------------
      // ColorSet cannot only be a 'this.Circle.CircleColorSet', other
      // colorset could be invoked for thier chart type.
      // Therefore the current colorset & its type should be shared
      // to the globally common variable to correctly run the global
      // drawings.

      // If the ColorSet's colors are less than the Data length,
      // number of the color should be set as much as the length
      // of the data. If not, NOT FOUND error will be occured.
      // this.SharedColorSet = dataLength > this.Circle.CircleColorSet.length ? Array.from({ length: dataLength }, (_, i) => this.Circle.CircleColorSet[i % this.Circle.CircleColorSet.length]) : this.Circle.CircleColorSet
      this.SharedColorSet = dataLength > this.Circle.CircleColorSet.length ? Array.from({ length: dataLength }, (_, i) => this.Circle.CircleColorSet[i % this.Circle.CircleColorSet.length]) : this.Circle.CircleColorSet
      this.SharedColorSetType = this.Circle.CircleColorType
    },
    executeSort(a, b) {
      if (a.value == b.value) return 0
      return a.value < b.value ? 1 : -1
  
    },
    get_CoordinatorsXY(Cx, Cy, size, nameX, nameY) {
      var ra
      var a = 0 // Angle
      var aCalc = 0 //
      var aRad = 0 // Angle in Rad
      var z = 0 // Size z
      var x = 0 // Side x
      var endX = 0 // SVG endX coordinate
      var endY = 0 // SVG endY coordinate

      this.pieCenterAngles.forEach((item, sn) => {
        ra = this.PieStacks[sn] + size
        a = 360 * item
        aCalc = a > 180 ? 360 - a : a
        aRad = (aCalc * Math.PI) / 180
        z = Math.sqrt(2 * ra * ra - 2 * ra * ra * Math.cos(aRad))
        x = aCalc <= 90 ? ra * Math.sin(aRad) : ra * Math.sin(((180 - aCalc) * Math.PI) / 180)
        endY = Math.sqrt(z * z - x * x)
        endX = a <= 180 ? ra + x : ra - x

        this.pieSectors[sn] = {
          ...this.pieSectors[sn],
          ...{
            [nameX]: endX + Cx - ra,
            [nameY]: endY + Cy - ra,
          }
        }


      })
    },

    // --------------------------------------------------------------------------------------------------------------------------------------------
    get_PieChartData() {
      
      var ra
      var a = 0 // Angle
      var aCalc = 0 //
      var arcSweep = 0
      var aRad = 0 // Angle in Rad
      var z = 0 // Size z
      var x = 0 // Side x
      var endX = 0 // SVG endX coordinate
      var endY = 0 // SVG endY coordinate
      var Ro = 0 // Rotation

      var h_ra
      var h_z = 0 // Size z
      var h_x = 0 // Side x
      var h_endX = 0 // SVG endX coordinate
      var h_endY = 0 // SVG endY coordinate

      var textSpace = 0
      var direct = 0
      var align = 0


      this.Queries.SQL1.forEach((item, sn) => {
        ra = this.PieStacks[sn]
        h_ra = this.Circle.CircleHoleSize // Hole
        a = 360 * (item.value / 100)
        aCalc = a > 180 ? 360 - a : a
        aRad = (aCalc * Math.PI) / 180
        z = Math.sqrt(2 * ra * ra - 2 * ra * ra * Math.cos(aRad))
        h_z = Math.sqrt(2 * h_ra * h_ra - 2 * h_ra * h_ra * Math.cos(aRad)) // Hole
        if (aCalc <= 90) {
          x = ra * Math.sin(aRad)
          h_x = h_ra * Math.sin(aRad) // Hole
        } else {
          x = ra * Math.sin(((180 - aCalc) * Math.PI) / 180)
          h_x = h_ra * Math.sin(((180 - aCalc) * Math.PI) / 180)
        }

        endY = Math.sqrt(z * z - x * x)
        h_endY = Math.sqrt(h_z * h_z - h_x * h_x) // Hole

        if (a <= 180) {
          endX = ra + x
          h_endX = h_ra + h_x
          arcSweep = 0
        } else {
          endX = ra - x
          h_endX = h_ra - h_x
          arcSweep = 1
        }
        if (this.pieCenterAngles[sn] < 0.5) {
          direct = this.Guideline.GuideExtend
          // align = 'start'
          textSpace = 10
        } else {
          direct = -this.Guideline.GuideExtend
          // align = 'end'
          textSpace = -10
        }

        this.pieSectors[sn] = {
          ...this.pieSectors[sn],
          ...{
            angle: a,
            value: item.value,
            title: item.title,
            color: this.SharedColorSet[sn],
            arcSweep: arcSweep,
            Ra: ra,
            endX: endX,
            endY: endY,

            h_Ra: h_ra, // Hole
            h_endX: h_endX, // Hole
            h_endY: h_endY, // Hole
            Ro: Ro,
            direct: direct,
            // align: align,
            textSpace: textSpace,
            stack: this.PieStacks[sn]
          }
        }

        Ro = Ro + a
      })
    }
  }
}