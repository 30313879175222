import '@babel/polyfill'
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import './polyfill'

/* With the File-Browser Features */
import Vue from '../lib/filebrowser/src/utils/vue'
// import Vue from 'vue'

import './plugins'

// Sync router with store
import { sync } from 'vuex-router-sync'
import App from './App'
import Axios from 'axios'
import router from './router'
import store from './store'
import i18n from './i18n'
import { ModalDraggableMixin } from './mixins/modal.draggable.mixin'
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
Vue.$cookies.config('7d')
import '@mdi/font/css/materialdesignicons.css'

Axios.defaults.withCredentials = true
Vue.config.productionTip = false
// Sync store with router
sync(store, router)

new Vue({
  router,
  store,
  i18n,
  mixins: [
    ModalDraggableMixin
  ],
  render: h => h(App)
}).$mount('#app')
