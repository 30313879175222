export default {
  data: () => ({

    Status: [
      { code: '', title: 'Total Interface Point by Status',  bColor: '#FFB74D', tColor: '#424242', opacity: 0.60,},
      { code: '', title: 'Total Interface Point by Contractor',bColor: '#FFB74D', tColor: '#ffffff', opacity: 0.60,},
      { code: '', title: 'Total IQ by Status',      bColor: '#FF9800', tColor: '#424242', opacity: 0.60,},
      { code: '', title: 'Total IQ by Requester',  bColor: '#FF9800', tColor: '#ffffff', opacity: 0.60,},
    ],
    Border: [
      {
        type: 'MEGA',
        shape: 'ellipse',
        width: 35,
        height: 15,
        color: '#9E9E9E',
        stroke: .2,
        textSize: 12,
        padding: 3,
      },
      {
        type: 'UNIT',
        shape: 'rect',
        width: 42,
        height: 26,
        color: '#9E9E9E',
        stroke: .2,
        textSize: 12,
        padding: 2.5
      },
    ],
    attrs: [
      { 
        text: 'Total',
        refColumn: 'TOTAL',
        fColor: '#E0E0E0', 
        tColor: '#424242',
      },
      { 
        text: 'Complete',
        refColumn: 'COMPLETE',
        fColor: '#66BB6A',
        tColor: '#ffffff'
      },
      { 
        text: 'On Track', 
        refColumn: 'ONTRACK',
        fColor: '#FFEB3B', 
        tColor: '#424242'
      },
      { 
        text: 'Behind Plan',
        refColumn: 'BEHINDPLAN',
        fColor: '#EF9A9A', 
        tColor: '#ffffff'
      },
    ],
    
    Coordinate: [
      // { CATEGORY: 'Contractor', CODE: 'E1', CONTRACTOR_DESCR2: 'HQC(E1)', x: 100, y: 450, COLOR: '#C6EFFF', },
      { CATEGORY: 'Contractor', CODE: 'E2', CONTRACTOR_DESCR2: 'SEI(E2)', x: 382, y: 481, COLOR: '#C6EFFF', },
      { CATEGORY: 'Contractor', CODE: 'E2_2', CONTRACTOR_DESCR2: 'SEI(E2)', x: 140, y: 405, COLOR: '#C6EFFF', },
      // { CATEGORY: 'Contractor', CODE: 'E3', CONTRACTOR_DESCR2: 'Simon Carves(E3)', x: 150, y: 620, COLOR: '#C6EFFF', },
      { CATEGORY: 'Contractor', CODE: 'GC', CONTRACTOR_DESCR2: 'GPEC+CPOE(GC)', x: 1246, y: 891, COLOR: '#C6EFFF', },
      { CATEGORY: 'Contractor', CODE: 'GF', CONTRACTOR_DESCR2: 'GPEC+FHDI(GF)', x: 1450, y: 1370, COLOR: '#C6EFFF', },
      { CATEGORY: 'Contractor', CODE: 'HS', CONTRACTOR_DESCR2: 'HAISUM(HS)', x: 140, y: 340, COLOR: '#C6EFFF', },
      { CATEGORY: 'Contractor', CODE: 'HQ', CONTRACTOR_DESCR2: 'HQGD(HQ)', x: 292, y: 1102, COLOR: '#C6EFFF', },
      { CATEGORY: 'Contractor', CODE: 'W1', CONTRACTOR_DESCR2: 'SHDI(W1)', x: 605, y: 1148, COLOR: '#C6EFFF', },
      { CATEGORY: 'Contractor', CODE: 'C4_CP6', CONTRACTOR_DESCR2: 'C4&CP6', x: 605, y: 1214, COLOR: '#C6EFFF', },
      { CATEGORY: 'Operator_JV', CODE: 'JOVO', CONTRACTOR_DESCR2: 'JOVO-LNG', x: 508, y: 922, COLOR: '#C5E1A5', },
      { CATEGORY: 'Operator_JV', CODE: 'VC1', CONTRACTOR_DESCR2: 'Vopak(VC)', x: 170, y: 1102, COLOR: '#C5E1A5', },
      { CATEGORY: 'Operator_JV', CODE: 'VC2', CONTRACTOR_DESCR2: 'Vopak(VC)', x: 728, y: 1148, COLOR: '#C5E1A5', },
      { CATEGORY: 'Third Parties', CODE: 'PG_2', CONTRACTOR_DESCR2: 'Power Grid', x: 410, y: 343, COLOR: '#FFD54F', },
      { CATEGORY: 'Third Parties', CODE: 'PG_1', CONTRACTOR_DESCR2: 'Power Grid', x: 60, y: 36, COLOR: '#FFD54F', },
      { CATEGORY: 'Operator_JV', CODE: 'HD', CONTRACTOR_DESCR2: 'HuaDe(HD)', x: 1236, y: 1400, COLOR: '#C5E1A5', },
      { CATEGORY: 'Third Parties', CODE: 'DP', CONTRACTOR_DESCR2: 'DPRC(DP)', x: 390, y: 718, COLOR: '#FFD54F', },
      { CATEGORY: 'Third Parties', CODE: 'CS', CONTRACTOR_DESCR2: 'CSPC(CS)', x: 570, y: 400, COLOR: '#FFD54F', },
      { CATEGORY: 'Third Parties', CODE: 'LD', CONTRACTOR_DESCR2: 'Linde(LD)', x: 760, y: 580, COLOR: '#FFD54F', },
      { CATEGORY: 'Third Parties', CODE: 'GN', CONTRACTOR_DESCR2: 'GEG NG', x: 630, y: 580, COLOR: '#FFD54F', },
      { CATEGORY: 'Third Parties', CODE: 'GO', CONTRACTOR_DESCR2: 'GEG COGEN', x: 630, y: 681, COLOR: '#FFD54F', },
      { CATEGORY: 'Third Parties', CODE: 'QY', CONTRACTOR_DESCR2: 'QY', x: 1307, y: 175, COLOR: '#FFD54F', },
      { CATEGORY: 'Third Parties', CODE: 'CRG', CONTRACTOR_DESCR2: 'CRG', x: 120, y: 470, COLOR: '#FFD54F', },
      { CATEGORY: 'Third Parties', CODE: 'DIP', CONTRACTOR_DESCR2: 'DIP', x: 510, y: 718, COLOR: '#FFD54F', },
      { CATEGORY: 'InterfaceGate', CODE: 'IG-E2-001', CONTRACTOR_DESCR2: 'IG-E2-001', x: 315, y: 675, COLOR: '#D50000', },
      { CATEGORY: 'InterfaceGate', CODE: 'IG-E2-002', CONTRACTOR_DESCR2: 'IG-E2-002', x: 487, y: 675, COLOR: '#D50000', },
      { CATEGORY: 'InterfaceGate', CODE: 'IG-E2-003', CONTRACTOR_DESCR2: 'IG-E2-003', x: 484, y: 409, COLOR: '#D50000', },
      { CATEGORY: 'InterfaceGate', CODE: 'IG-E2-004', CONTRACTOR_DESCR2: 'IG-E2-004', x: 370, y: 435, COLOR: '#D50000', },
      { CATEGORY: 'InterfaceGate', CODE: 'IG-E2-005', CONTRACTOR_DESCR2: 'IG-E2-005', x: 324, y: 371, COLOR: '#D50000', },
      { CATEGORY: 'InterfaceGate', CODE: 'IG-E2-006', CONTRACTOR_DESCR2: 'IG-E2-006', x: 160, y: 535, COLOR: '#D50000', },
      { CATEGORY: 'InterfaceGate', CODE: 'IG-E2-007', CONTRACTOR_DESCR2: 'IG-E2-007', x: 490, y: 560, COLOR: '#D50000', },
      { CATEGORY: 'InterfaceGate', CODE: 'IG-E2-008', CONTRACTOR_DESCR2: 'IG-E2-008', x: 403, y: 675, COLOR: '#D50000', },
      { CATEGORY: 'InterfaceGate', CODE: 'IG-E2-009', CONTRACTOR_DESCR2: 'IG-E2-009', x: 285, y: 349, COLOR: '#D50000', },
      { CATEGORY: 'InterfaceGate', CODE: 'IG-E2-010', CONTRACTOR_DESCR2: 'IG-E2-010', x: 275, y: 485, COLOR: '#D50000', },
      { CATEGORY: 'InterfaceGate', CODE: 'IG-HQ-002', CONTRACTOR_DESCR2: 'IG-HQ-002', x: 536, y: 1064, COLOR: '#D50000', },
      { CATEGORY: 'InterfaceGate', CODE: 'IG-HQ-001', CONTRACTOR_DESCR2: 'IG-HQ-001', x: 536, y: 1028, COLOR: '#D50000', },
      { CATEGORY: 'InterfaceGate', CODE: 'IG-W1-001', CONTRACTOR_DESCR2: 'IG-W1-001', x: 536, y: 1116, COLOR: '#D50000', },
      { CATEGORY: 'InterfaceGate', CODE: 'IG-CS-001', CONTRACTOR_DESCR2: 'IG-CS-001', x: 590, y: 530, COLOR: '#D50000', },
      { CATEGORY: 'InterfaceGate', CODE: 'IG-GC-002', CONTRACTOR_DESCR2: 'IG-GC-002', x: 870, y: 435, COLOR: '#D50000', },
      { CATEGORY: 'InterfaceGate', CODE: 'IG-E2-011', CONTRACTOR_DESCR2: 'IG-E2-011', x: 590, y: 554, COLOR: '#D50000', },
      { CATEGORY: 'InterfaceGate', CODE: 'IG-GC-001', CONTRACTOR_DESCR2: 'IG-GC-001', x: 1285, y: 1370, COLOR: '#D50000', },
      { CATEGORY: 'InterfaceGate', CODE: 'IG-GF-001', CONTRACTOR_DESCR2: 'IG-GF-001', x: 1500, y: 1460, COLOR: '#D50000', },

    ]
  }),
}