

// Canvas
import STG_Overall from                   './01_Overall.mixin'
import STG_Division_Lv1 from              './02_Division_Lv1.mixin'
import STG_Division_Lv2 from              './03_Division_Lv2.mixin'
import STG_Division_Lv3 from              './04_Division_Lv3.mixin'
import STG_Division_Lv4 from              './05_Division_Lv4.mixin'
import STG_Hull_Activate from             './06_Hull_Activates.mixin'
import STG_Topside_Activate from          './07_Topside_Activates.mixin'





export default {
  mixins: [

    STG_Overall,
    STG_Division_Lv1,
    STG_Division_Lv2,
    STG_Division_Lv3,
    STG_Division_Lv4,
    STG_Hull_Activate,
    STG_Topside_Activate,
  ],
}
