export default {
  data: () => ({

    Status: [
      { code: 'TOTAL'     , title: 'Total',  bColor: '#E0E0E0', tColor: '#424242', opacity: 0.60,},
      { code: 'COMPLETE',   title: 'Complete',      bColor: '#66BB6A', tColor: '#ffffff', opacity: 0.60,},
      { code: 'ONTRACK',    title: 'On Track',      bColor: '#FFEB3B', tColor: '#424242', opacity: 0.60,},
      { code: 'BEHINDPLAN', title: 'Behind Plan',  bColor: '#EF9A9A', tColor: '#ffffff', opacity: 0.60,},
      // { code: 'ASSEMBLY',    title: 'Assembly Completed',  bColor: '#CC93C1', tColor: '#FFFFFF',    opacity: 0.80,},
      // { code: 'ERECTION',    title: 'Erec. Commenced',     bColor: '#8DBA2D', tColor: '#FFFFFF',    opacity: 1.00,},
    ],
    Border: [
      {
        type: 'MEGA',
        shape: 'ellipse',
        width: 35,
        height: 15,
        color: '#9E9E9E',
        stroke: .2,
        textSize: 12,
        padding: 3,
      },
      {
        type: 'UNIT',
        shape: 'rect',
        width: 50,
        height: 23,
        color: '#9E9E9E',
        stroke: .2,
        textSize: 12,
        padding: 2.5
      },
    ],
    attrs: [
      { 
        text: 'Total',
        refColumn: 'TOTAL',
        fColor: '#E0E0E0', 
        tColor: '#424242',
      },
      { 
        text: 'Complete',
        refColumn: 'COMPLETE',
        fColor: '#66BB6A',
        tColor: '#ffffff'
      },
      { 
        text: 'On Track',
        refColumn: 'ONTRACK',
        fColor: '#FFEB3B', 
        tColor: '#424242'
      },
      { 
        text: 'Behind Plan',
        refColumn: 'BEHINDPLAN',
        fColor: '#EF9A9A', 
        tColor: '#ffffff'
      },
    ],
    
    Coordinate: [
      { BID_SECTION_PACK: 'S11', x1: 257, y1: 87, x: 290, y: 94, },
      { BID_SECTION_PACK: 'S9', x1: 559, y1: 95, x: 595, y: 110, },
      { BID_SECTION_PACK: 'S4', x1: 256, y1: 250, x: 290, y: 266, },
      { BID_SECTION_PACK: 'S12', x1: 406, y1: 250, x: 480, y: 260, },
      { BID_SECTION_PACK: 'S3', x1: 406, y1: 360, x: 440, y: 377, },
      { BID_SECTION_PACK: 'S2', x1: 661, y1: 360, x: 696, y: 375, },
      { BID_SECTION_PACK: 'S5', x1: 257, y1: 634, x: 297, y: 652, },
      { BID_SECTION_PACK: 'S1', x1: 838, y1: 550, x: 875, y: 560, },
      { BID_SECTION_PACK: 'S13', x1: 838, y1: 948, x: 875, y: 960, },
      { BID_SECTION_PACK: 'S14', x1: 1083, y1: 947, x: 1116, y: 960, },
      { BID_SECTION_PACK: 'S15', x1: 1286, y1: 947, x: 1320, y: 960, },
      { BID_SECTION_PACK: 'S17', x1: 982, y1: 1251, x: 1126, y: 1360, },
      { BID_SECTION_PACK: 'S16', x1: 1083, y1: 1200, x: 1160, y: 1207, },
      { BID_SECTION_PACK: 'S6', x1: 100, y1: 428, x: 130, y: 480, },
      { BID_SECTION_PACK: 'S7', x1: 153, y1: 653, x: 120, y: 795, },
      { BID_SECTION_PACK: 'S8', x1: 1110, y1: 288, x: 1144, y: 292, },
      { BID_SECTION_PACK: 'S10', x1: 1110, y1: 396, x: 1144, y: 400, },
      { BID_SECTION_PACK: 'S18', x1: 1110, y1: 504, x: 1144, y: 508, },
      { BID_SECTION_PACK: 'S19', x1: 1110, y1: 611, x: 1144, y: 615, },
      { BID_SECTION_PACK: 'S20', x1: 1110, y1: 719, x: 1144, y: 725, },
      { BID_SECTION_PACK: 'S21', x1: 1110, y1: 827, x: 1144, y: 833, },
    ]
  }),
}