import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {

    
    Draw_Charts() {
      
      let data = null, 
          style = null, 
          cellX = null, 
          tmp = null, 
          Circle = null, 
          Guideline = null,
          item = null

      let disc_ITRStatus = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0)`)

     



      // Header_01
      data  = this.dataSet.find(f=> f.TYPE == 'A' && f.DISC == 'Overall').PROG
      style = {
        title: 'A ITR',
        x: 30,
        y: 40,
        width: 50,
        height: 18,
        radius: 3,
        tSize: 11,
        tColor: '#333',
        bColor: '#E0E0DF',
        progSize: 15,
      }
      this.Header_01( disc_ITRStatus, data, style) // ---------------------------> 


      // SummaryTable_01
      item = this.dataSet.find(f=> f.TYPE == 'A' && f.DISC == 'Overall')
      style = {
        id:       'AITR',
        title:    ['Total', 'Completed', 'Remain'],
        cell:     [50, 110, 160],
        x:        30,
        y:        60,
        y1:       10,
        y2:       23,
        width:    170,
        height:   32,
        radius:   3,
        opacity:  0.7,
        bColor:   '#83D2F5',
        tSize:    9,
        tColor:   '#757575',
        vSize:    11,
        vColor:   '#fff',
        value:    [item.TOTAL, item.ACTUAL, item.REMAIN]
      }
      this.SummaryTable_01(disc_ITRStatus, style) // ---------------------------> 


      // WeekPerformance_01
      data = this.dataSet.filter(f=> f.TYPE == 'WEEK')
      style = {
                x: 470,
                y: 40,
      }
      data.forEach(d=> {
        d.date = d.DATE
        if(d.DISC =='LWK' || d.DISC =='TWK') d.name = 'Total'; else d.name = d.DISC
        d.value = d.ACTUAL
      })
      this.WeekPerformance_01(disc_ITRStatus, data, style) // ---------------------------> 


      this.Radar.chart.size = 175
      data = this.dataSet.filter(f => f.TYPE == 'A' && f.DISC !=='Overall')
      style = {
        x: 220,
        y: 240,
      }
      this.draw_Radar(disc_ITRStatus, data,  style)


       // Bar_Progress_01
       data = this.dataSet.filter(f=> f.TYPE == 'A' && f.DISC !== 'Overall')
       style = {
                x:        425,
                y:        150,
                width:    100,
                title:    {text: 'ITRs Status by discipline', x: 28, tSize: 11, tColor: '#333'},
                cell:     [70, 110, 150, 190, 200, 250, 300],
                lineHeight: 25,
                bColor:   '#B4E3FA', 
                sColor:   '#44A9DF', 
                tColor1:  '#fff', 
                tColor2:  '#757575', 
                opacity: 1
       }
       this.Bar_Progress_01(disc_ITRStatus, data, style) // ---------------------------> 
    },
  }
}
           