import * as d3 from 'd3'
import __M from 'moment'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  data: () => ({
    legend: 
    [
      { column: 'PB1_OV_TOT_DAILY', color: '#FFAB91', shape: 'line', text: 'Daily B1 Punch raised', axis: 'left', x: 40, y: 0 },
      { column: 'PB1_OV_COM_DAILY', color: '#4FC3F7', shape: 'line', text: 'Daily B1 Punch cleared', axis: 'left', x: 190, y: 0 },
      { column: 'PB1_OV_REM', color: '#FF6E40', shape: 'line', text: 'Cumulative Total Open', axis: 'right', x: 340, y: 0 }
      // { column: 'WEEKLY', color: '#81D4FA', shape: 'rect', text: 'Week', axis: 'left', x: 20, y: 0 },
      // { column: 'CUM_PLAN_PROG', color: '#757575', shape: 'line', text: 'Cum. Plan Prog.', axis: 'right', lineShape: 'dash', x: 220, y: 0 },
      // { column: 'CUM_ACTUAL_PROG', color: '#D81B60', shape: 'line', text: 'Cum. Actual Prog.', axis: 'right',  x: 330, y: 0},
    ],
    margin: { top: 80, left: 50, bottom: 50, right: 50 },
    width: 578,
    height: 300,
    filter: []
  }),
  methods: {
    Draw_Charts() {
      const list = this.DataItems
        .map(v => {
          return {
            CATEGORY: v['CDATE'],
            VALUES: [
              { KEY: 'PB1_OV_TOT_DAILY', VALUE: v['PB1_OV_TOT_DAILY'], AXIS: 'left' },
              { KEY: 'PB1_OV_COM_DAILY', VALUE: v['PB1_OV_COM_DAILY'], AXIS: 'left' },
              { KEY: 'PB1_OV_REM', VALUE: v['PB1_OV_REM'], AXIS: 'right' }
            ]
          }
        })
      
      this.filter = this.legend.map(d => d.column)

      const width = this.width - 30
      const height = this.height - 15
      

      const svg = this.svg
        .append('svg')
        .attr('width', `${width}`)
        .attr('height', `${height}`)

      svg
        .append('g')
        .attr('id','chart_progress')
        .attr('transform', `translate(${this.margin.left},${this.margin.top+40})`)

      let legendGoup = svg
        .append('g')
        .attr('id','chart_legend')
        .attr('transform', `translate(34,65)`)

      const legendName = this.legend
      const legends = legendGoup.selectAll('.legend').data(legendName)

      const filter = this.filter
      const render = this.render

      const legend = legends
        .enter()
        .append('g')
        .attr('class', 'legend')
        .attr('transform', (d, i) => {
          return `translate(${d.x}, ${d.y})`
        })
        .style('user-select', 'none')
        // .on('click', function (d) {
        //   const el = d3.select(this)
        //   const text = el.select('text')
        //   const rect = el.select('rect')
        //   const column = d.column
        //   if (!filter.includes(column)) {
        //     text.attr('fill', '#000')
        //     rect.style('fill', d.color)
        //     filter.push(column)
        //   } else {
        //     text.attr('fill', '#efefef')
        //     rect.style('fill', '#efefef')
        //     const idx = filter.indexOf(column)
        //     filter.splice(idx, 1)
        //   }

        //   const newValue = list.map(d => {
        //     const obj = {
        //       CATEGORY: d['CATEGORY'],
        //       VALUES: d['VALUES'].filter(v => filter.includes(v['KEY']))
        //     }
        //     return obj
        //   })
        //   render(newValue)
        // })

      legend.append('rect')
        .attr('x', 0)
        .attr('width', 14)
        .attr('height', d => d.shape === 'line' ? 2 : 6)
        .attr('y', d => d.shape === 'line' ? 4 : 4)
        .style('fill', d => d.color)

      legend.append('text')
        .attr('x', 16)
        .attr('y', 6)
        .attr('dy', '.35em')
        .attr('text-anchor', 'start')
        .attr('font-size', '9px')
        .attr('font-family', 'arial')
        // .style('cursor', 'pointer')
        .text(d => d.text)

        render(list)
    },
    render (dataset) {

      const { top, left, bottom, right } = this.margin
      const width = this.width - left - right
      const height = this.height - top - bottom
      const chartColumn = this.DataItems.length

      let singleWidth = (this.width - this.margin.left - this.margin.right) / chartColumn

      const g = d3.select(this.$el).select('svg g')

      const y0 = d3.scaleLinear().range([ height, 0 ])
      const y1 = d3.scaleLinear().range([ height, 0 ])

      const x0 = d3.scaleBand().range([ 0, width ])
      const x1 = d3.scaleBand().range([ 0, width ])
      //left Axis
      const leftColumns = this.legend.filter(d => d.axis === 'left').map(d => d['column'])
      const leftArr = dataset.map(d => d['VALUES']).flat().filter(v => v['VALUE'] && leftColumns.includes(v['KEY'])).map(v => v['VALUE'])
      const leftValues = Math.max.apply(null, leftArr)
      //right Axis
      const rightColumns = this.legend.filter(d => d.axis === 'right').map(d => d['column'])
      const rightArr = dataset.map(d => d['VALUES']).flat().filter(v => v['VALUE'] && rightColumns.includes(v['KEY'])).map(v => v['VALUE'])
      const rightValues = Math.max.apply(null, rightArr)

      const max = {
        left: leftArr.length === 0 ? 0 : leftValues,
        right: rightArr.length === 0 ? 0 : rightValues
      }

      max.left = max.left === 0 ? 100 : max.left * 1.2
      max.right = max.right === 0 ? 100 : max.right
      
      const axisLeftRange = d3.range(6).map(d => { const v = max.left / 5 * d; return String(v).indexOf('.') !== -1 ? v : Math.ceil(v) })
      const axisRightRange = d3.range(6).map(d => { const v = max.right / 5 * d; return String(v).indexOf('.') !== -1 ? v : Math.ceil(v) })

      const categoryNames = dataset.map(d => d.CATEGORY)

      // const duration = 0
      const bars = this.legend.filter(d => d.shape === 'rect').map(d => d['column'])

      y0.domain([ 0, max.left ])
      y1.domain([ 0, max.right ])

      x0.domain(categoryNames)
      x1.domain(dataset[0].VALUES.filter(d => bars.includes(d.KEY)).map(d => d.KEY))
        .rangeRound([ 0, x0.bandwidth() ]).padding(0.15)

      const xAxis = d3.axisBottom(x0) .tickFormat(d => d).tickValues(x0.domain().filter((d,i) => { return !(i%1)}))
      //xAxis의 보이는 값을 다보여줄것인지 작게 보여줄것인지

      const yAxis = {
        left: d3.axisLeft(y0).tickValues(axisLeftRange),
        right: d3.axisRight(y1).tickValues(axisRightRange)
      }

      g.selectAll('g').remove()

      g.append('g')
        .attr('class', 'grid')
        .call(d3.axisLeft(y0).tickValues(axisLeftRange).tickSize(-width).tickFormat(''))
        .selectAll('.tick line')
        .attr('stroke', (d, k) => k > 0 ? '#efefef' : '#ccc')
        .attr('stroke-width', 1)
        .attr('shape-rendering', 'crispEdges')

      g.append('g')
        .style('font-size', '10px')
        .attr('class', 'x axis')
        .attr('transform', `translate(${width / chartColumn},${height + 12})`)
        .call(xAxis)
        .selectAll('text')
        .attr('y', 0)
        .attr('x', -singleWidth)
        .attr('dy', '.35em')
        .style('text-anchor', 'middle')
        .style('fill','#424242')
        .text(d => __M(new Date(d)).format('DD-MMM'))
        
      // g.append('text')
      //   .attr('class', 'yaxis_left_text')
      //   .style('font-size', '10px')
      //   .attr('transform', `translate(-32,${height/2}),rotate(-90)`)
      //   .style('text-anchor', 'middle')
      //   .style('fill','#9E9E9E')
      //   .text("Weekly Q'ty")

      //   g.append('text')
      //   .attr('class', 'yaxis_right_text')
      //   .style('font-size', '10px')
      //   .attr('transform', `translate(${width+48},${height/2}),rotate(-90)`)
      //   .style('text-anchor', 'middle')
      //   .style('fill','#9E9E9E')
      //   .text('Cumulative Progress')

      g.append('g')
        .attr('class', 'y axis axis-left')
        .style('font-size', '10px')
        .call(yAxis.left)

      g.append('g')
        .attr('class', 'y axis axis-right')
        .style('font-size', '10px')
        .attr('transform', `translate(${width},0)`)
        .call(yAxis.right)


      g.selectAll('.domain').remove()
      g.selectAll('.y .tick').attr('color', '#878787')
      g.selectAll('.y .tick line').remove()
      g.selectAll('.x .tick line').remove()

      // const barGroups = g.selectAll('.bar')
      //   .data(dataset)
      //   .enter()
      //   .append('g')
      //   .attr('class', 'bar')
      //   .attr('transform', d => `translate(${x0(d.CATEGORY)},0)`)

      // barGroups.selectAll('rect')
      //   .data(d => d.VALUES.filter(v => bars.includes(v.KEY)))
      //   .enter()
      //   .append('rect')
      //   .attr('width', x1.bandwidth() < 1 ? 1 : x1.bandwidth())
      //   .attr('x', d => x1(d.KEY))
      //   .attr('y', d => y0(d.VALUE))
      //   .attr('height', d => height - y0(d.VALUE) < 0 ? 0 : isNaN(height - y0(d.VALUE)) ? 0 : height - y0(d.VALUE))
      //   .attr('fill', d => {
      //     const color = this.legend.find(v => v['column'] === d['KEY']).color
      //     return color
      //   })
      //   .style('opacity', 0)

      // barGroups.selectAll('text')
      //   .data(d => d.VALUES.filter(v => bars.includes(v.KEY)))
      //   .enter()
      //   .append('text')
      //   .attr('x', d => x1(d.KEY))
      //   .attr('dx', x1.bandwidth() / 2)
      //   .attr('y', d => y0(d.VALUE))
      //   .attr('dy', '-.5em')
      //   .attr('fill', d => d3.rgb(this.legend.find(v => v['column'] === d.KEY).color).darker(1))
      //   .attr('text-anchor', 'middle')
      //   .attr('font-size', '10px')
      //   .text(d => d.VALUE > 0 ? d.VALUE : null)
      //   .style('opacity', 0)

      // barGroups.selectAll('rect')
      //   .transition()
      //   .style('opacity', 1)

      // barGroups.selectAll('text')
      //   .transition()
      //   .style('opacity', 1)

      const lines = this.legend.filter(d => d.shape === 'line').map(d => d['column']).filter(d => this.filter.includes(d))


      const lineLength = d3.line()
        .x(d => x0(d.KEY))
        .y(d => {
          return d.AXIS == 'left' ? y0(d.VALUE) : y1(d.VALUE)
        })
        .defined(d => { // return d.VALUE && d.VALUE >= 0 기존것 있는것만 나오기
          return d.VALUE >= 0 //0있는것은 0부터 시작하게
        })
      const linedata = []
      lines.forEach(c => {
        const obj = {}
        obj.KEY = c
        obj.COLOR = this.legend.find(v => v['column'] === c).color
        obj.VALUES = dataset
          .map(d => {
            d.VALUES.find(v => {
              return v.KEY === c
            })
            
            return {
              KEY: d.CATEGORY,
              VALUE: d.VALUES.find(v => v.KEY === c).VALUE,
              AXIS: this.legend.find(v => v['column'] === c).axis
            }
          })
        obj.LINESHAPE = this.legend.find(v => v['column'] === c).lineShape
        linedata.push(obj)
      })


      const lineGroups = g
        .append('g')
        .attr('class', 'line')
        .attr('transform', `translate(${x0.bandwidth() / 2},0)`)

      linedata.forEach(v => {
        const line = lineGroups
        .append('path')
        .datum(v)
        .attr('class', d => `line-${d.KEY}`)
        .attr('fill', 'none')
        .attr('stroke', d => d.COLOR)
        .attr('stroke-width', 1)
        .attr('shape-rendering', 'geometricPrecision')
        .attr('d', d => lineLength(d.VALUES))
        v.LENGTH = line.node().getTotalLength()
      })

      // line cercle
      const circles = lineGroups
        .selectAll('g')
        .data(linedata)
        .enter()
        .append('g')
        .attr('data-key', d => d.KEY)

      circles
        .selectAll('circle')
        .data(d => d.VALUES)
        .enter()
        .append('circle')
        .attr('class', d => !d.VALUE ? 'not-value' : '')
        .attr('cx', d => x0(d.KEY))
        .attr('cy', d => d.AXIS == 'left' ? y0(d.VALUE) : y1(d.VALUE))
        .attr('r', function (d) {
          const wrapper = d3.select(this).node().parentNode
          const legend = linedata.find(v => v.KEY === wrapper.dataset.key)
          if (legend) return legend.LINESHAPE && legend.LINESHAPE === 'dash' ? 0 : '.15em'
          else return '.15em'
        })
        .attr('fill', '#fff')
        .attr('stroke-width', '.1em')
        .attr('stroke', function (d) {
          const wrapper = d3.select(this).node().parentNode
          return linedata.find(v => v.KEY === wrapper.dataset.key).COLOR
        })
        .attr('shape-rendering', 'geometricPrecision')
        // .style('display', d => !d.VALUE ? 'none' : '')
      // console.log(linedata)
      // console.log(linedata[0].VALUES[4].VALUE ,linedata[1].VALUES[4].VALUE)
        
      circles
        .selectAll('text')
        .data(d => d.VALUES)
        .enter()
        .append('text')
        .attr('x', d => x0(d.KEY))
        .attr('y', d => d.AXIS == 'left' ? y0(d.VALUE) : y1(d.VALUE))
        .attr('dy', '-1em')
        .attr('text-anchor', 'middle')
        .attr('font-size', '10px')
        .attr('fill', function (d) {
          const wrapper = d3.select(this).node().parentNode
          // return d3.rgb(linedata.find(v => v.KEY === wrapper.dataset.key).COLOR).darker(1) //text 글자 어둡게
          return d3.rgb(linedata.find(v => v.KEY === wrapper.dataset.key).COLOR)
        })
        .text(d => d.VALUE)

      g.selectAll('.line path')
        .attr('stroke-dasharray', d => '')
        .attr('stroke-dashoffset', d => d.LENGTH)
        .transition()
        .attr('stroke-dashoffset', 0)
        .attr('stroke-dasharray', d => {
          const legend = this.legend.find(v => v['column'] === d['KEY'])
          if (legend) return legend.lineShape && legend.lineShape === 'dash' ? '4,3' : d.LENGTH + ' ' + d.LENGTH
          else return ''
        })
        .attr('d', d => lineLength(d.VALUES))

      g.selectAll('.line circle')
        .attr('opacity', 0)
        .transition()
        .attr('opacity', 1)
      g.selectAll('.line text')
        .attr('opacity', 0)
        .transition()
        .attr('opacity', 1)
    }
  }
}
