import * as d3 from 'd3'
import Defs from '../../../../includes/primitives/Color_Defs'
import Filter from '../../../../includes/primitives/Filter_Defs'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({
    PieStacks: [],
    pieCenterAngles: [],
    pieSectors: [],

    Circle: {
      CircleDiameter: 65,
      CircleColorType: 'LinearA2',
      CircleColorSet: [],
      CircleRadius: 0,
      CircleHoleSize: 40,
      CircleStackSize: 5,
      CircleSliceSize: 0,
      CircleShadowDisplay: 'Y',
  
      CircleDropshadowDisplay: 'Y',
      CircleDropshadowDeviation: 1,
      CircleDropshadowOpacity: 0.1,
      CircleDropshadowX: 1,
      CircleDropshadowY: 1,
      CircleTitleSize: 12,
      CircleLegendSpace: 80,
    },
  
    Guideline: {
      GuideDisplay: 'Y',
      GuideWeight: 0.5,
      GuideColor: 'gray',
      GuideInside: 10,
      GuideOutside: 5,
      GuideExtend: 10,
  
      GuideBulletSize: 1,
      GuideBulletAutoColor: 'Y',
      GuideBulletColor: 'gray',
  
      GuideTextFont: 'roboto',
      GuideTextStyle: 'regular',
      GuideTextSize: 10,
      GuideTextColor: 'gray',
  
      GuideValueRound: 1,
      GuideValueSize: 10,
      GuideValueColor: '#333',
  
      GuideUnitFormat: '%',
      GuideUnitSize: 9,
      GuideUnitColor: '#757575'
    },
  }),


  methods: {

    Chart_Pie(selection, _data, _style, _Circle, _Guideline) { 

      this.Circle = { ...this.Circle, ..._Circle }
      this.Guideline = { ...this.Guideline, ..._Guideline }

      let dataLength = _data.length

      this.Circle.CircleColorSet.forEach((c,i)=> {
        Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', [c], [c, 0.5, 1.0, 0.5, -0.2], this.localId)
      })

      let Shadow = selection
      .append('defs') // Tube Color (Left)
      .append('radialGradient').attr('id', `${this.localId}__Shadow`)
      Shadow.append('stop').attr('stop-color', '#a4a4a4').attr('offset', '0.00').attr('stop-opacity', '1')
      Shadow.append('stop').attr('stop-color', '#ffffff').attr('offset', '1.00').attr('stop-opacity', '0.37')


      this.PieStacks = []
      this.pieCenterAngles = []
      this.pieSectors = []

      var sortData = _data
      if (_style.sort == 'Y') sortData.sort(this.executeSort)

      // Pushing Data [PieStacks], [pieCenterAngles]
      var sum = 0
      var total = 0
      _data.forEach((item, i) => {
        sum = sum + _data[i].value
        total += item.qty
      })
     
      let cumulative = 0
      _data.forEach((item, j) => {
        sortData.forEach((sItem, i) => {
          if (sItem.title == item.title) this.PieStacks.push(this.Circle.CircleDiameter - i * this.Circle.CircleStackSize)
        })
        cumulative = cumulative + (_data[j].value/sum)
        this.pieCenterAngles.push(cumulative - (_data[j].value/sum) / 2)
      })

      this.SharedColorSet = dataLength > this.Circle.CircleColorSet.length ? Array.from({ length: dataLength }, (_, i) => this.Circle.CircleColorSet[i % this.Circle.CircleColorSet.length]) : this.Circle.CircleColorSet
      this.SharedColorSetType = this.Circle.CircleColorType



      let centerTitle = selection

      centerTitle
      .append('text')
      .attr('transform', `translate(${_style.x}, ${_style.y})`)
      .style('font-size', this.Circle.CircleTitleSize).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'middle')
      .text(_style.name)

      centerTitle
      .append('line')
      .attr('x1', _style.x-26).attr('y1', _style.y+4).attr('x2', _style.x+26).attr('y2', _style.y+4).attr('stroke-width', 0.5).attr('stroke', '#757575')

      centerTitle
      .append('text')
      .attr('transform', `translate(${_style.x}, ${_style.y+17})`)
      .style('font-size', 12).style('font-family', 'roboto').attr('fill', '#757575').style('text-anchor', 'middle')
      .text(total.toLocaleString())

      let legendsPie = selection
      .append('g')
      .attr('transform', `translate(${_style.x-30}, ${_style.y + this.Circle.CircleLegendSpace})`)

      _data.forEach((d,i) => {
        legendsPie
        .append('rect')
        .attr('transform', `translate(${0}, ${(i*16) + 0})`)
        .attr('width', 8).attr('height', 8).attr('stroke-width', 0.5).attr('stroke', '#bcbcbc').attr('fill', `url(#${this.localId}__LinearA4${this.SharedColorSet[i]}0)`)

        legendsPie
        .append('text')
        .attr('id', `${_style.id}_LG_${i}`)
        .attr('transform', `translate(${11}, ${(i*16) + 9})`)
        .style('font-size', 12).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'mistartddle')
        .text(_data[i].title)

        legendsPie
        .append('text')
        .attr('transform', `translate(${this.getNodeElValue(`#${_style.id}_LG_${i}`, 'width') + 12}, ${(i*16) + 9})`)
        .style('font-size', 12).style('font-family', 'roboto').attr('fill', '#757575').style('text-anchor', 'mistartddle')
        .text(`(${_data[i].qty.toLocaleString()})`)
      })
      




      this.get_CoordinatorsXY(_style.x, _style.y, -this.Guideline.GuideInside, 'inX', 'inY')
      this.get_CoordinatorsXY(_style.x, _style.y, this.Guideline.GuideOutside, 'outX', 'outY')
      this.get_PieChartData(_data)

      Filter.setDefs_DropShadow(selection, `_DropShadow`, 1 , 0.1 , 1, 1)
 
      if (this.Circle.CircleShadowDisplay == 'Y') {
        selection // Shadow
        .append('ellipse')
        .attr('cx', _style.x)
        .attr('cy', _style.y + this.Circle.CircleDiameter)
        .attr('rx', this.Circle.CircleDiameter)
        .attr('ry', this.Circle.CircleDiameter * 0.1)
        .attr('fill', `url(#${this.localId}__Shadow)`)
      }
      
      var arc = d3.arc()
        .outerRadius(d => d.data.stack)
        .innerRadius(this.Circle.CircleHoleSize)
        .cornerRadius(this.Circle.CircleRadius) // Coner Radius

      var pie = d3.pie()
        .sort(null)
        .value((d) => d.value)
        .padAngle(this.Circle.CircleSliceSize/100) // Padding Pie

      let donutGroup = selection
        .append('g')
        .attr('transform', `translate(${_style.x},${_style.y})`)

        donutGroup
        .selectAll('path')
        .data(pie(this.pieSectors))
        .enter()
        .append('path')
        .attr('d', arc)
        .attr('fill', (d, i) => `url(#${this.localId}__LinearA4${this.SharedColorSet[i]}0)`)
        .style('filter', `url(#_DropShadow)`)
        .transition()
        .duration(750)
        .attrTween('d', function(d) {
            var interpolate = d3.interpolate({startAngle: 0, endAngle: 0}, d);
            return function(t) {
                return arc(interpolate(t));
            };
          });

      if (_Guideline.GuideDisplay=='Y') this.drawGuidline(selection, _style.id)
    },

    
    get_PieChartData(_data) {
      var ra
      var a = 0 // Angle
      var aCalc = 0 //
      var arcSweep = 0
      var aRad = 0 // Angle in Rad
      var z = 0 // Size z
      var x = 0 // Side x
      var endX = 0 // SVG endX coordinate
      var endY = 0 // SVG endY coordinate
      var Ro = 0 // Rotation

      var h_ra
      var h_z = 0 // Size z
      var h_x = 0 // Side x
      var h_endX = 0 // SVG endX coordinate
      var h_endY = 0 // SVG endY coordinate

      var textSpace = 0
      var direct = 0

      _data.forEach((item, sn) => {
        ra = this.PieStacks[sn]
        h_ra = this.Circle.CircleHoleSize // Hole
        a = 360 * (item.value / 100)
        aCalc = a > 180 ? 360 - a : a
        aRad = (aCalc * Math.PI) / 180
        z = Math.sqrt(2 * ra * ra - 2 * ra * ra * Math.cos(aRad))
        h_z = Math.sqrt(2 * h_ra * h_ra - 2 * h_ra * h_ra * Math.cos(aRad)) // Hole
        if (aCalc <= 90) {
          x = ra * Math.sin(aRad)
          h_x = h_ra * Math.sin(aRad) // Hole
        } else {
          x = ra * Math.sin(((180 - aCalc) * Math.PI) / 180)
          h_x = h_ra * Math.sin(((180 - aCalc) * Math.PI) / 180)
        }

        endY = Math.sqrt(z * z - x * x)
        h_endY = Math.sqrt(h_z * h_z - h_x * h_x) // Hole

        if (a <= 180) {
          endX = ra + x
          h_endX = h_ra + h_x
          arcSweep = 0
        } else {
          endX = ra - x
          h_endX = h_ra - h_x
          arcSweep = 1
        }
        if (this.pieCenterAngles[sn] < 0.5) {
          direct = this.Guideline.GuideExtend
          // align = 'start'
          textSpace = 3
        } else {
          direct = -this.Guideline.GuideExtend
          // align = 'end'
          textSpace = -3
        }

        this.pieSectors[sn] = {
          ...this.pieSectors[sn],
          ...{
            angle: a,
            value: item.value,
            title: item.title,
            color: this.SharedColorSet[sn],
            arcSweep: arcSweep,
            Ra: ra,
            endX: endX,
            endY: endY,

            h_Ra: h_ra, // Hole
            h_endX: h_endX, // Hole
            h_endY: h_endY, // Hole
            Ro: Ro,
            direct: direct,
            // align: align,
            textSpace: textSpace,
            stack: this.PieStacks[sn]
          }
        }

        Ro = Ro + a
      })
    },


    drawGuidline(selection, _id) {

      selection
        .append('g')
        .selectAll('line') // Create Line from inside to outside
        .data(this.pieSectors)
        .enter()
        .append('line')
        .attr('x1', (d, i) => this.pieSectors[i].inX)
        .attr('y1', (d, i) => this.pieSectors[i].inY)
        .attr('x2', (d, i) => this.pieSectors[i].outX)
        .attr('y2', (d, i) => this.pieSectors[i].outY)
        .attr('stroke', this.Guideline.GuideColor)
        .attr('stroke-width', this.Guideline.GuideWeight)
        
      selection
        .append('g')
        .selectAll('line') // Create Line from outside to bullet
        .data(this.pieSectors)
        .enter()
        .append('line')
        .attr('x1', (d, i) => this.pieSectors[i].outX)
        .attr('y1', (d, i) => this.pieSectors[i].outY)
        .attr('x2', (d, i) => this.pieSectors[i].outX + this.pieSectors[i].direct)
        .attr('y2', (d, i) => {
          // console.log(this.pieCenterAngles[i])
          return this.pieSectors[i].outY
        })
        .attr('stroke', this.Guideline.GuideColor)
        .attr('stroke-width', this.Guideline.GuideWeight)

      selection
        .append('g')
        .selectAll('circle') // Create bullets
        .data(this.pieSectors)
        .enter()
        .append('circle')
        // .attr('fill', (d, i) => `url(#${this.localId}__LinearA2${i})`)
        .style('fill', this.Guideline.GuideBulletColor)
        .attr('cx', (d, i) => this.pieSectors[i].outX + this.pieSectors[i].direct)
        .attr('cy', (d, i) => this.pieSectors[i].outY)
        .attr('r', this.Guideline.GuideBulletSize)

      let SeriesNameGroup = selection
        .append('g')
        .selectAll('g')
        .data(this.pieSectors)
        .enter()
        .append('g')
        .attr('id', (_, i) => `${_id}_series_group_${i}`)
        .attr(
          'transform',
          (d, i) => `translate(
            ${this.pieSectors[i].outX + this.pieSectors[i].direct + this.pieSectors[i].textSpace},
            ${this.pieSectors[i].outY}
            )`
          )

      SeriesNameGroup
        .append('text') // value
        .attr('id', (_, i) => `${_id}_series_value_${i}`)
        .attr('class', this.Guideline.GuideTextStyle)
        .style('font-size', this.Guideline.GuideValueSize)
        .style('font-family', this.Guideline.GuideTextFont)
        .attr('fill', this.Guideline.GuideValueColor)
        .attr('alignment-baseline', 'middle')
        .text((d, i) => this.pieSectors[i].value.toFixed(1))

      SeriesNameGroup
        .append('text') // unit
        .attr('id', (_, i) => `${_id}_series_unit_${i}`)
        .attr('class', this.Guideline.GuideTextStyle)
        .style('font-size', this.Guideline.GuideUnitSize)
        .style('font-family', this.Guideline.GuideTextFont)
        .attr('fill', this.Guideline.GuideUnitColor)
        .attr('alignment-baseline', 'middle')
        .text(this.Guideline.GuideUnitFormat)

      this.pieSectors.forEach((item, i) => {
        let valueWidth = this.getNodeElValue(`#${_id}_series_value_${i}`, 'width')

        d3.select(`#${_id}_series_value_${i}`).attr('transform', `translate(${1},0)`)
        d3.select(`#${_id}_series_unit_${i}`).attr('transform', `translate(${valueWidth + 1},0)`)

        if (this.pieCenterAngles[i] > 0.5) {
          let gWidth = this.getNodeElValue(`#${_id}_series_group_${i}`, 'width')
          d3.select(`#${_id}_series_group_${i}`)
          .attr('transform', `translate(${this.pieSectors[i].outX + this.pieSectors[i].direct + this.pieSectors[i].textSpace - gWidth},${this.pieSectors[i].outY})`)
        }
      })
    },


    get_CoordinatorsXY(Cx, Cy, size, nameX, nameY) {
      var ra
      var a = 0 // Angle
      var aCalc = 0 //
      var aRad = 0 // Angle in Rad
      var z = 0 // Size z
      var x = 0 // Side x
      var endX = 0 // endX coordinate
      var endY = 0 // endY coordinate

      this.pieCenterAngles.forEach((item, sn) => {
        ra = this.PieStacks[sn] + size
        a = 360 * item
        aCalc = a > 180 ? 360 - a : a
        aRad = (aCalc * Math.PI) / 180
        z = Math.sqrt(2 * ra * ra - 2 * ra * ra * Math.cos(aRad))
        x = aCalc <= 90 ? ra * Math.sin(aRad) : ra * Math.sin(((180 - aCalc) * Math.PI) / 180)
        endY = Math.sqrt(z * z - x * x)
        endX = a <= 180 ? ra + x : ra - x

        this.pieSectors[sn] = {
          ...this.pieSectors[sn],
          ...{
            [nameX]: endX + Cx - ra,
            [nameY]: endY + Cy - ra,
          }
        }
      })
    },

    executeSort(a, b) {
      if (a.value == b.value) return 0
      return a.value < b.value ? 1 : -1
    },

  }
}
