import Defs from '../../../../../../includes/primitives/Color_Defs'

export default {
  data: () => ({
    dataSet: [],
    lineChartData: [],
    activity: 'MC_B29',
    inputFilter: {
      values: [
        { colName: 'SUBSYSTEM'    , text: 'Subsystem' , value: '', x: 0   , textWidth: 67, on: true },
        { colName: 'TAG_NO'          , text: 'Tag'       , value: '', x: 72  , textWidth: 34, on: false },
        { colName: 'PUNCH_NO' , text: 'Punch'     , value: '', x: 111 , textWidth: 45, on: false },
      ]
    },
    filterCodes: {
      // Schedule: {
      //   attrs: { type: 'single', x: 0, y: 100, width: 150, height: 100, labelWidth: 65, textWidth: 140 },
      //   values: [
      //     { colName: 'DATE', text: 'MC (B25)' , value: 'MC_B25' , on: false },
      //     { colName: 'DATE', text: 'MC (B28)' , value: 'MC_B28' , on: false },
      //     { colName: 'DATE', text: 'MC (Reforecast Weekly)' , value: 'MC_B29' , on: true },
      //     { colName: 'DATE', text: 'Incentive MC', value: 'MC_INCENTIVE_DATE', on: false },
      //   ]
      // }
    },
    skyline: {
                sizeType    : 'auto',
                fixedSize   : 450,
                minSize     : 740,
                startFloat  : 2,      // Fixed
                endFloat    : 2,      // Fixed
                weekEnd     : 'thursday',
                font        : 'roboto',
                marginTop   : 350    ,

                box: {
                            font      : 'roboto', 
                            width     : 82,     // Fixed
                            height    : 15,     // Fixed
                            gap       : 1,      // Fixed
                },
                line : {
                            display   : 'Y',
                            height    : 390,
                            gap       : 1,
                },
                legend: {
                            x         : 25,
                            y         : 50,
                            font      : 'roboto', 
                            group: {
                                      tSize     : 11,
                                      tColot    : '#333',
                                      lineSpace : 15,
                            },
                            item: {
                                      tSize     : 10,
                                      tColor    : '#757575',
                                      space     : 10,
                            },
                            notes: {
                                      x         : 10,
                                      y         : 150,
                                      tSize     : 10,
                                      tColor    : '#bcbcbc',
                                      space     : 15,
                            },
                },
                dropdown: {
                            x         : 25,
                            y         : 90,
                            option: [
                                      {text: 'Steel Sutting',   value: 'SC'},
                                      {text: 'Block Assembly',  value: 'Assembly'},
                                      {text: 'Painting',        value: 'Painting'},
                                      {text: 'Block Erection',  value: 'Erection'},
                            ],
                },
    },
    timeline: { 
                week        : [],
                month       : [],
                year        : [],
                plan        : [],
                actual      : [],
                scale       : null,     

                weekCount   : [],
                weekSize    : null,    // Auto
                length      : null,    // Auto
                height      : null,    // Auto
                font        : 'roboto', 
                baseX       : 250,
                baseY       : 801,

                style: {
                        week: {
                                display   : 'Y',
                                height    : 15,
                                line      : 12,
                                sWidth    : 0.5,
                                sColor    : '#BCBCBC',
                                tSize     : 9,
                                tColor    : '#757575',
                        },
                        month: {
                                display   : 'Y',
                                height    : 14,
                                line      : 15,
                                sWidth    : 0.5,
                                sColor    : '#BCBCBC',
                                tSize     : 10,
                                tColor    : '#333',
                        },
                        year: {
                                display   : 'Y',
                                height    : 10,
                                line      : 20,
                                sWidth    : 0.5,
                                sColor    : '#BCBCBC',
                                tSize     : 11,
                                tColor    : '#000',
                        },
                },

    },
    style:[ 
              {
                group: 'Outstanding Punch',
                position: { x: 0, y: 160, rect: 'Y' },
                item: [
                  {
                    name        : 'None',
                    refColumn   : 'PUNCH_STATUS',
                    codeValue   : 'N',
                    dataColumn  : '',
                    tStyle      : 'regular',
                    tSize       : 9,
                    tColor      : '#000',
                    sWidth      : 0.5,
                    sColor      : '#BDBDBD',
                    bColor      : '#FEFEFE',
                    opacity     : 1,
                    radius      : 2,
                    on          : true,
                  },
                  {
                    name        : 'Category A',
                    refColumn   : 'PUNCH_STATUS',
                    codeValue   : 'A',
                    dataColumn  : '',
                    tStyle      : 'regular',
                    tSize       : 9,
                    tColor      : '#000',
                    sWidth      : 0.5,
                    sColor      : '#FF3D00',
                    bColor      : '#FEFEFE',
                    opacity     : 1,
                    radius      : 2,
                    on          : true,
                  },
                  {
                    name        : 'Category B1',
                    refColumn   : 'PUNCH_STATUS',
                    codeValue   : 'B1',
                    dataColumn  : '',
                    tStyle      : 'regular',
                    tSize       : 9,
                    tColor      : '#000',
                    sWidth      : 0.5,
                    sColor      : '#6200EA',
                    bColor      : '#FEFEFE',
                    opacity     : 1,
                    radius      : 2,
                    on          : true,
                  },
                  {
                    name        : 'Category B2',
                    refColumn   : 'PUNCH_STATUS',
                    codeValue   : 'B2',
                    dataColumn  : '',
                    tStyle      : 'regular',
                    tSize       : 9,
                    tColor      : '#000',
                    sWidth      : 0.5,
                    sColor      : '#F57F17',
                    bColor      : '#FEFEFE',
                    opacity     : 1,
                    radius      : 2,
                    on          : true,
                  },
                  {
                    name        : 'Category B3',
                    refColumn   : 'PUNCH_STATUS',
                    codeValue   : 'B3',
                    dataColumn  : '',
                    tStyle      : 'regular',
                    tSize       : 9,
                    tColor      : '#000',
                    sWidth      : 0.5,
                    sColor      : '#43A047',
                    bColor      : '#FEFEFE',
                    opacity     : 1,
                    radius      : 2,
                    on          : true,
                  },
                ]
              },
              {
                group: 'Priority',
                position: { x: 0, y: 260, rect: 'N' },
                item: [
                          {
                            name        : 'P1A Subsystem',
                            refColumn   : 'PRIORITY',
                            codeValue   : 'P1A',
                            dataColumn  : 'A_PG',
                            tStyle      : 'regular',
                            tSize       : 9,
                            tColor      : '#D50000',
                            sWidth      : 0.5,
                            sColor      : '#bcbcbc',
                            bColor      : '#FFEE58',
                            opacity     : 1,
                            radius      : 2,
                            on          : true,
                          },
                          {
                            name        : 'P1B Subsystem',
                            refColumn   : 'PRIORITY',
                            codeValue   : 'P1B',
                            dataColumn  : 'A_PG',
                            tStyle      : 'regular',
                            tSize       : 9,
                            tColor      : '#F57F17',
                            sWidth      : 0.5,
                            sColor      : '#bcbcbc',
                            bColor      : '#FFEE58',
                            opacity     : 1,
                            radius      : 2,
                            on          : true,
                          },
                          {
                            name        : 'P1 Subsystem',
                            refColumn   : 'PRIORITY',
                            codeValue   : 'P1',
                            dataColumn  : 'A_PG',
                            tStyle      : 'regular',
                            tSize       : 9,
                            tColor      : '#000',
                            sWidth      : 0.5,
                            sColor      : '#bcbcbc',
                            bColor      : '#FFEE58',
                            opacity     : 1,
                            radius      : 2,
                            on          : true,
                          },
                          {
                            name        : 'P2 Subsystem',
                            refColumn   : 'PRIORITY',
                            codeValue   : 'P2',
                            dataColumn  : 'A_PG',
                            tStyle      : 'regular',
                            tSize       : 9,
                            tColor      : '#000',
                            sWidth      : 0.5,
                            sColor      : '#e1bee7',
                            bColor      : '#FFEE58',
                            opacity     : 1,
                            radius      : 2,
                            on          : true,
                          },
                          {
                            name        : 'P3 Subsystem',
                            refColumn   : 'PRIORITY',
                            codeValue   : 'P3',
                            dataColumn  : 'A_PG',
                            tStyle      : 'regular',
                            tSize       : 9,
                            tColor      : '#000',
                            sWidth      : 0.5,
                            sColor      : '#e1bee7',
                            bColor      : '#FFEE58',
                            opacity     : 1,
                            radius      : 2,
                            on          : true,
                          },
                          {
                            name        : 'NA Subsystem',
                            refColumn   : 'PRIORITY',
                            codeValue   : 'NA',
                            dataColumn  : 'A_PG',
                            tStyle      : 'regular',
                            tSize       : 9,
                            tColor      : '#9E9E9E',
                            sWidth      : 0.5,
                            sColor      : '#e1bee7',
                            bColor      : '#FFEE58',
                            opacity     : 1,
                            radius      : 2,
                            on          : true,
                          }
                ],
              },
              {
                group: 'Comment',
                position: { x: 0, y: 310, rect: 'N' },
                item: [   
                          {
                            name        : 'Y',
                            refColumn   : 'COMMENTS',
                            codeValue   : 'Y',
                            dataColumn  : '',
                            tStyle      : 'regular',
                            tSize       : 9,
                            tColor      : '#212121',
                            sWidth      : 0.5,
                            sColor      : '#212121',
                            bColor      : 'Transparent',
                            opacity     : 1,
                            radius      : 2,
                            on          : true,
                          },
                          {
                            name        : 'N',
                            refColumn   : 'COMMENTS',
                            codeValue   : 'NULL',
                            dataColumn  : '',
                            tStyle      : 'regular',
                            tSize       : 9,
                            tColor      : '#212121',
                            sWidth      : 0.5,
                            sColor      : '#212121',
                            bColor      : '#FEFEFE',
                            opacity     : 1,
                            radius      : 2,
                            on          : true,
                          }
                ],
              },
              
              {
                group: 'A ITR Progress',
                position: { x: 0, y: 210, rect: 'Y' },
                item: [   {
                            name        : 'N/A',
                            refColumn   : 'A_OV_STATUS',
                            codeValue   : 'NA',
                            dataColumn  : '',
                            tStyle      : 'regular',
                            tSize       : 9,
                            tColor      : '#000',
                            sWidth      : 0.5,
                            sColor      : '#BDBDBD',
                            bColor      : 'FEFEFE',
                            opacity     : 1,
                            radius      : 2,
                            on          : true,
                          },
                          {
                            name        : 'Less than 70%',
                            refColumn   : 'A_OV_STATUS',
                            codeValue   : 'less than 70%',
                            dataColumn  : '',
                            tStyle      : 'regular',
                            tSize       : 9,
                            tColor      : '#212121',
                            sWidth      : 0.5,
                            sColor      : '#212121',
                            bColor      : 'Transparent',
                            opacity     : 1,
                            radius      : 2,
                            on          : true,
                          },
                          {
                            name        : 'Greater than 80%',
                            refColumn   : 'A_OV_STATUS',
                            codeValue   : 'greater than 80%',
                            dataColumn  : '',
                            tStyle      : 'regular',
                            tSize       : 9,
                            tColor      : '#212121',
                            sWidth      : 0.5,
                            sColor      : '#212121',
                            bColor      : '#FEFEFE',
                            opacity     : 1,
                            radius      : 2,
                            on          : true,
                          },
                          {
                            name        : 'Greater than 90%',
                            refColumn   : 'A_OV_STATUS',
                            codeValue   : 'greater than 90%',
                            dataColumn  : '',
                            tStyle      : 'regular',
                            tSize       : 9,
                            tColor      : '#212121',
                            sWidth      : 0.5,
                            sColor      : '#212121',
                            bColor      : '#FEFEFE',
                            opacity     : 1,
                            radius      : 2,
                            on          : true,
                          },
                          {
                            name        : 'Greater than 95%',
                            refColumn   : 'A_OV_STATUS',
                            codeValue   : 'greater than 95%',
                            dataColumn  : '',
                            tStyle      : 'regular',
                            tSize       : 9,
                            tColor      : '#212121',
                            sWidth      : 0.5,
                            sColor      : '#212121',
                            bColor      : '#FEFEFE',
                            opacity     : 1,
                            radius      : 2,
                            on          : true,
                          }
                ],
              },
    ],
    styleFilter: {
      over: {
        stroke: '#ffb300',
        fill: '#ffd54f',
        color: '#fff'
      },
      out: {
        stroke: '#bcbcbc',
        fill: '#fff',
        color: '#c9c9c9'
      },
      selected: {
        stroke: '#1565c0',
        fill: '#64b5f6',
        color: '#757575'
      }
    },
    table: {
      values: [
        { text: 'A ITR Required for Week'        , colName: 'A_T'      },
        { text: 'A ITR Completed for Week'    , colName: 'A_A'      },
        { text: 'A ITR Client Signed', colName: 'A_C'      },
        { text: 'A ITR Remained'     , colName: 'A_O'      , tColor: '#d32f2f' },
        { text: 'A ITR Progress(%)'  , colName: 'A_PG'     },
        { text: 'Total MCs required Cum.'    , colName: 'MC_TOTAL'     },
        { text: 'Total MCs Complete Cum.'    , colName: 'MC_ACTUAL'     },
        { text: 'Open A Punch '    , colName: 'PA_O'     },
        { text: 'Open B1 Punch'    , colName: 'PB1_O'     },
        { text: 'Open B2 Punch'    , colName: 'PB2_O'     },
        { text: 'Open B3 Punch'    , colName: 'PB3_O'     },
      ]
    }
  }),
  methods: {
    setStyles() {
      let lightBlue = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `shadowTimeline`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      lightBlue.append('stop').attr('stop-color', '#3d3d3d').attr('offset', '0').attr('stop-opacity', 0.5)
      lightBlue.append('stop').attr('stop-color', '#fff').attr('offset', '1').attr('stop-opacity', 0.3)

      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['LightGray'], [`LightGray`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['YellowGreen'], [`YellowGreen`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['LightBlue'], [`LightBlue`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['Pink'], [`Pink`, 0.5, 1.0, 0.5, -0.2], this.localId)
    },
  }
}