import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({
    path: {
      // ME01: '/topside_dashboard/me01_flash_gas_compression',
      // ME02: '/topside_dashboard/me02_meg_and_fuel_gas',
      // ME04: '/topside_dashboard/me04_power_generator',
      // ME05: '/topside_dashboard/me05_e_i_building',
      // MW01: '/topside_dashboard/mw01_recep_fac_prod_sepa_',
      // MW02: '/topside_dashboard/mw02_hc_dew_cond_stab_',
      // MW04: '/topside_dashboard/mw04_power_generator',
      // MW05: '/topside_dashboard/mw05_utility',
      HULLFOR: '/hull_dashboard/hull_for',
      HULLAFT: '/hull_dashboard/hull_aft',
      LQ: '/lq_dashboard'
    }
  }),

  methods: {
    draw_ModuleInfo(seletion, data, x, y) {
      let moduleSummary = seletion
      .append('g')
      .attr('class','modulewrap')
      .attr('transform', `translate(${['ME04','ME05','MW04'].includes(data.MODULE) ? x +90 :data.MODULE == 'LQ' ? x + 25 :['HULL-FOR','HULL-AFT'].includes(data.MODULE) ? x + 80:x + 70}, ${y - 63})`)
      
      moduleSummary
      .append('image')
      .attr('id', `img_${data.MODULE}`)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Modules/${data.MODULE}.png`)
      // .attr('x', data.MODULE == 'HULL-AFT' ? 150 : 50)
      .attr('x', 50)
      .attr('y', -140).attr('width', 230).attr('height', 150).attr('opacity', 1)
      .on('mouseover', () => {
        moduleSummary.style('cursor', 'pointer')
        d3.select(`#img_${data.MODULE}`)
        .transition().duration(300).attr('opacity', .5)
      })
      .on('mouseout', () => { 
        moduleSummary.style('cursor', 'default')
          d3.select(`#img_${data.MODULE}`)
          .transition().duration(300).attr('opacity', 1)
      })
      .on('click', () => { 
        let area = data.MODULE.replace('-','')
        // let request_
        // if (data.MODULE === 'LQ' || data.MODULE === 'HULL-FOR' || data.MODULE === 'HULL-AFT') request_ = this.getRequestColumnProps('level2', { GWBS_LV2: data.MODULE }, this.ColumnProps)
        // else request_ = this.getRequestColumnProps('area', { GWBS_LV3: data.MODULE }, this.ColumnProps)
        // if(request_) this.$emit('request-action', request_)

        // console.log(data.MODULE)
        //data.MODULE로 클릭했을때 제대로 들어오는것을 확인하고 조건으로 hull은 페이지 이동 , 모듈은 modal 나오게 하기
        if (data.MODULE === 'LQ' || data.MODULE === 'HULL-FOR' || data.MODULE === 'HULL-AFT'){
          let request_ = {
            dataType: 'row',    
            action: {
              type: 'direct',
              target: 'url',
              path: `/service/construction/construction_dashboards${this.path[area]}`
            },
            filters: {}
          }
          this.$emit('request-action', request_)
        }else{
          let request_ = this.getRequestColumnProps(data.MODULE, {}, this.ColumnProps)
          if(request_) this.$emit('request-action', request_)
        }
      })

      // if (data.MODULE == 'HULL-AFT') {
      //   moduleSummary
      //   .append('text')
      //   .attr('x', 150).attr('y', 15).style('font-size', 20).style('fill', '#44A9DF').attr('text-anchor', 'start').text(data.MODULE)
      //   moduleSummary
      //   .append('text')
      //   .attr('x', 150).attr('y', 30).style('font-size', 14).style('fill', '#757575').attr('text-anchor', 'start').text(data.MODULE_DESCR)
  
      //   // Progress Summary for Construction & MC -------------------------------------
      //   // draw Rects
      //   moduleSummary
      //   .append('rect')
      //   .attr('x', 150).attr('y', 35).attr('rx', 3).attr('ry', 3).attr('width', 70).attr('height', 40).attr('fill',  `url(#${this.localId}__LinearA4${'LightBlue'}0)`).attr('opacity', 0.7)
      //   moduleSummary
      //   .append('rect')
      //   .attr('x', 225).attr('y', 35).attr('rx', 3).attr('ry', 3).attr('width', 130).attr('height', 40).attr('fill', `url(#${this.localId}__LinearA4${'LightBlue'}0)`).attr('opacity', 0.7)
      //   moduleSummary
      //   .append('rect')
      //   .attr('x', 360).attr('y', 35).attr('rx', 3).attr('ry', 3).attr('width', 45).attr('height', 40).attr('fill', `url(#${this.localId}__LinearA4${'LightGray'}0)`).attr('opacity', 0.9)
  
      //   // draw Titles
      //   moduleSummary
      //   .append('text')
      //   .attr('x', 185).attr('y', 47).style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'middle').text('Construction')
      //   moduleSummary
      //   .append('text')
      //   .attr('x', 290).attr('y', 47).style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'middle').text('Mechanical Completion')
      //   moduleSummary
      //   .append('text')
      //   .attr('x', 382).attr('y', 47).style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'middle').text('A Punch')
  
      //   // Draw Values
      //   moduleSummary // Progress(%)
      //   .append('text')
      //   .attr('x', 215).attr('y', 67).style('font-size', 16).style('fill', '#fff').attr('text-anchor', 'end')
      //   .transition().duration(1000)
      //   .tween("text", function() {
      //     var i = d3.interpolateNumber(1, data.CON_PROG || 0)
      //     return function(t) {
      //       d3.select(this).text(`${Number(i(t).toFixed(1)).toLocaleString()}%`)
      //     }
      //   })
  
      //   moduleSummary 
      //   .append('text')
      //   .attr('x', 233).attr('y', 67).style('font-size', 16).style('fill', '#757575').attr('text-anchor', 'start').text('A ITR')
  
      //   moduleSummary // A1 QVD
      //   .append('text')
      //   .attr('x', 350).attr('y', 67).style('font-size', 16).style('fill', '#fff').attr('text-anchor', 'end')
      //   .transition().duration(1000)
      //   .tween("text", function() {
      //     var i = d3.interpolateNumber(1, data.MC_PROG)
      //     return function(t) {
      //       d3.select(this).text(`${Number(i(t)).toFixed(1).toLocaleString()}%`)
      //     }
      //   })
  
      //   moduleSummary // Punch(Active)
      //   .append('text')
      //   .attr('x', 397).attr('y', 67).style('font-size', 16).style('fill', '#fff').attr('text-anchor', 'end')
      //   .transition().duration(1000)
      //   .tween("text", function() {
      //     var i = d3.interpolateNumber(1, data.PUNCH)
      //     return function(t) {
      //       d3.select(this).text(Number(i(t)).toFixed().toLocaleString())
      //     }
      //   })
      // }else {
        moduleSummary
        .append('text')
        .attr('x', 50).attr('y', 15).style('font-size', 20).style('fill', '#44A9DF').attr('text-anchor', 'start').text(data.MODULE)
        moduleSummary
        .append('text')
        .attr('x', 50).attr('y', 30).style('font-size', 14).style('fill', '#757575').attr('text-anchor', 'start').text(data.MODULE_DESCR)
  
        // Progress Summary for Construction & MC -------------------------------------
        // draw Rects
        moduleSummary
        .append('rect')
        .attr('x', 50).attr('y', 35).attr('rx', 3).attr('ry', 3).attr('width', 70).attr('height', 40).attr('fill',  `url(#${this.localId}__LinearA4${'LightBlue'}0)`).attr('opacity', 0.7)
        moduleSummary
        .append('rect')
        .attr('x', 125).attr('y', 35).attr('rx', 3).attr('ry', 3).attr('width', 130).attr('height', 40).attr('fill', `url(#${this.localId}__LinearA4${'LightBlue'}0)`).attr('opacity', 0.7)
        moduleSummary
        .append('rect')
        .attr('x', 260).attr('y', 35).attr('rx', 3).attr('ry', 3).attr('width', 45).attr('height', 40).attr('fill', `url(#${this.localId}__LinearA4${'LightGray'}0)`).attr('opacity', 0.9)
  
        // draw Titles
        moduleSummary
        .append('text')
        .attr('x', 85).attr('y', 47).style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'middle').text('Construction')
        moduleSummary
        .append('text')
        .attr('x', 190).attr('y', 47).style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'middle').text('Mechanical Completion')
        moduleSummary
        .append('text')
        .attr('x', 282).attr('y', 47).style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'middle').text('A Punch')
  
        // Draw Values
        moduleSummary // Progress(%)
        .append('text')
        .attr('x', 115).attr('y', 67).style('font-size', 16).style('fill', '#fff').attr('text-anchor', 'end')
        .transition().duration(1000)
        .tween("text", function() {
          var i = d3.interpolateNumber(1, data.CON_PROG || 0)
          return function(t) {
            d3.select(this).text(`${Number(i(t).toFixed(1)).toLocaleString()}%`)
          }
        })
  
        moduleSummary 
        .append('text')
        .attr('x', 133).attr('y', 67).style('font-size', 16).style('fill', '#757575').attr('text-anchor', 'start').text('A ITR')
  
        moduleSummary // A1 QVD
        .append('text')
        .attr('x', 250).attr('y', 67).style('font-size', 16).style('fill', '#fff').attr('text-anchor', 'end')
        .transition().duration(1000)
        .tween("text", function() {
          var i = d3.interpolateNumber(1, data.MC_PROG)
          return function(t) {
            d3.select(this).text(`${Number(i(t)).toFixed(1).toLocaleString()}%`)
          }
        })
  
        moduleSummary // Punch(Active)
        .append('text')
        .attr('x', 297).attr('y', 67).style('font-size', 16).style('fill', '#fff').attr('text-anchor', 'end')
        .transition().duration(1000)
        .tween("text", function() {
          var i = d3.interpolateNumber(1, data.PUNCH)
          return function(t) {
            d3.select(this).text(Number(i(t)).toFixed().toLocaleString())
          }
        })
    // }
      
      let buttonWrap = moduleSummary
      .append('g')
      .attr('id', 'button_wrapper')
      .on('mouseover', function(){ 
        d3.select(this).transition().duration(100).style('opacity',.5) 
      })
      .on('mouseout', function(){ 
        d3.select(this).transition().duration(100).style('opacity',1) 
      })
      .on('click', () => {

        let area = data.MODULE.replace('-','')
        // let request_
        // if (data.MODULE === 'LQ' || data.MODULE === 'HULL-FOR' || data.MODULE === 'HULL-AFT') request_ = this.getRequestColumnProps('level2', { GWBS_LV2: data.MODULE }, this.ColumnProps)
        // else request_ = this.getRequestColumnProps('area', { GWBS_LV3: data.MODULE }, this.ColumnProps)
        // if(request_) this.$emit('request-action', request_)

        // console.log(data.MODULE)
        //data.MODULE로 클릭했을때 제대로 들어오는것을 확인하고 조건으로 hull은 페이지 이동 , 모듈은 modal 나오게 하기
        if (data.MODULE === 'LQ' || data.MODULE === 'HULL-FOR' || data.MODULE === 'HULL-AFT'){
          let request_ = {
            dataType: 'row',    
            action: {
              type: 'direct',
              target: 'url',
              path: `/service/construction/construction_dashboards${this.path[area]}`
            },
            filters: {}
          }
          this.$emit('request-action', request_)
        }else{
          let request_ = this.getRequestColumnProps(data.MODULE, {}, this.ColumnProps)
          if(request_) this.$emit('request-action', request_)
        }
      })
      buttonWrap
      .append('rect')
      .attr('transform', `translate(${265}, -148)`)
      .attr('rx', 3)
      .attr('ry', 3)
      .attr('width', 40)
      .attr('height', 14)
      .attr('fill', '#e5e5e5')
      .style('cursor', 'pointer') 
      buttonWrap
      .append('text')
      .attr('transform', `translate(${285}, -140)`)
      .style('font-family', 'Roboto-Regular, Roboto')
      .style('font-size', 9)
      .style('fill', '#000')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text('More')
      .style('cursor', 'pointer') 
    }, 
  }
}
