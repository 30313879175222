import * as d3 from 'd3'
import __M from 'moment'

export default {
  data: () => ({
    chartMounted: false,

    filtering: {},
    filterRequested: false,

    scaleY: null,
  }),
  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },
  methods: {
    setDefaultValues() {
      if(!this.chartMounted) {
        this.filtering = {}
      }

      this.timelineKeyName = 'date'
      this.timeline.weekCount = []

      this.setData()
      this.setTimelineEnv(this.dataSet)

      this.dataSet.forEach(d => {
        let date__ = this.getTimelineCDate(d[this.timelineKeyName])
        d.cDate = date__.eDate
        d.sDate = date__.sDate
        d.date = d3.timeParse('%Y-%m-%d')(d[this.timelineKeyName])
      })

      this.style.forEach(style => {
        style.item.forEach(item => {
          this.dataSet.filter(f => f[item.refColumn] == item.codeValue).forEach(d => {
            d.tStyle   = item.tStyle
            d.tSize    = item.tSize
            d.tColor   = item.tColor
            d.sWidth   = item.sWidth
            d.sColor   = d.sColor ? d.sColor : item.sColor
            d.pColor   = item.pColor
            d.bColor   = item.bColor
            d.opacity  = item.opacity
            d.radius   = item.radius
            if (item.dataColumn != '') d.progress = 'Y'
          })
        })
      })

      this.timeline.height = this.timeline.style.week.height
      if (this.timeline.style.month.display == 'Y') this.timeline.height += this.timeline.style.month.height
      if (this.timeline.style.year.display == 'Y') this.timeline.height += this.timeline.style.year.height
      
      this.timeline.week.forEach(w => {
        let count = 0
        this.dataSet.filter(f => f.cDate == w.eDate).forEach(() => {
          count += 1
        })
        this.timeline.weekCount.push(count)
      })

      // line data
      this.setSummaryData()

      let realHeight = Math.max(...this.timeline.weekCount) * (this.skyline.box.height + (this.skyline.box.gap * 2))
      if (realHeight > this.skyline.minSize) {
        this.skyline.line.height = realHeight
        this.timeline.baseY = realHeight + this.skyline.marginTop
      } else {
        this.timeline.baseY = this.skyline.line.height = this.skyline.minSize
      }

      let lightGray = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `lightGray`).attr('x1', 0.772).attr('x2', 0.228).attr('y1', 0.228).attr('y2', 0.919)
      lightGray.append('stop').attr('stop-color', '#fff').attr('offset', '0').attr('stop-opacity', 1)
      lightGray.append('stop').attr('stop-color', '#d0d2d3').attr('offset', '1').attr('stop-opacity', 1)
    },
    setData() {
      this.dataSet = JSON.parse(JSON.stringify(this.DataItems.filter(f => !!f[this.timelineKeyName])))
    },
    setSummaryData() {
      // this.timeline.plan = []
      // this.timeline.actual = []

      // if(!this.Queries.SQL4 || this.Queries.SQL4.length === 0) return

      // this.lineChartData = JSON.parse(JSON.stringify(this.Queries.SQL4))
      // this.lineChartData.forEach(d => {
      //   d.cDate = this.getTimelineCDate(d.MC_PLAN).eDate
      // })

      // let prevLineValues_ = {
      //   plan: 0,
      //   actual: 0,
      //   forecast: 0,
      // }

      // this.timeline.week.forEach(w => {
      //   let lineData_ = this.lineChartData.find(f => f.cDate == w.eDate)
      //   if((this.activity != 'WD_PLAN' && __M(w.eDate).format('YYYY-MM-DD') < '2019-09-20') || __M(w.eDate).format('YYYY-MM-DD') > this.Queries.SQL2[0].CDATE) return

      //   // this.timeline.plan.push({ 
      //   //   eDate: w.eDate, 
      //   //   mDate: w.mDate,
      //   //   sDate: w.sDate,
      //   //   value: this.lineChartData.find(f => f.cDate == w.eDate).plan
      //   // })
      //   // prevLineValues_.plan = lineData_.A_CUM_PG

      //   this.timeline.actual.push({ 
      //     eDate: w.eDate, 
      //     mDate: w.mDate,
      //     sDate: w.sDate,
      //     value: lineData_ && typeof lineData_.A_CUM_PG == 'number' ? lineData_.A_CUM_PG : prevLineValues_.actual
      //   })
      //   if(lineData_ && typeof lineData_.A_CUM_PG == 'number') prevLineValues_.actual = lineData_.A_CUM_PG
      // })
    },
    setLineScale() {
      this.scaleY = d3
      .scaleLinear()
      .domain([0, 100])
      .range([this.timeline.baseY, this.skyline.marginTop])

      this.lineZero = d3.line()
      .x(d => this.timeline.scale(d.eDate))
      .y(this.scaleY(0))

      this.lineFunc = d3.line()
      .x(d => this.timeline.scale(d.eDate))
      .y(d => this.scaleY(d.value))
    },
    setFilter(type, colName, value) {
      if(type == 'multi') {
        // init the object for the column if not present
        if(!this.filtering[colName]) this.filtering[colName] = []

        let index_ = this.filtering[colName].findIndex(v_ => v_ == value)
        if(index_ >= 0) {
          this.filtering[colName][index_] = null
          this.filtering[colName] = this.filtering[colName].filter(v_ => !!v_)
        } else {
          this.filtering[colName].push(value)
        }

      } else if(type == 'multi-pretended') {
        // init the object for the column if not present
        if(!this.filtering[colName]) this.filtering[colName] = value
        else delete this.filtering[colName]

      } else {  // for the single select or a value required
        let searchFields_ = ['SUBSYSTEM', 'TAG', 'PUNCH_NUMBER']
        if(searchFields_.includes(colName)) {
          searchFields_.forEach(c_ => {
            if(Object.keys(this.filtering).findIndex(k_ => k_ == c_) >= 0) this.filtering[c_] = ''
          })
        }

        this.filtering[colName] = value
      }
    },
    visible(values) {
      // let filters_ = this.trimFilters()
      let visible_ = true

      Object.keys(this.filtering).forEach(colName => {
        if(colName == 'DATE') return
        
        // console.log(
        //   colName, 
        //   values[colName],
        //   typeof this.filtering[colName], 
        //   typeof this.filtering[colName] == 'object' ? this.filtering[colName].includes(values[colName]) : this.filtering[colName] == values[colName]
        // )

        if(values.ACT_AREA_VALID === -1) visible_ = false
        else if(typeof this.filtering[colName] == 'object') {
          if(!this.filtering[colName].includes(values[colName])) visible_ = false
        } else {
          if(colName == 'SUBSYSTEM') {
            if(this.filtering[colName] && !values[colName].includes(this.filtering[colName])) visible_ = false
          } else if(colName == 'TAG') {
            if(this.filtering[colName] && (values.ACT_TAG_VALID == -1 || values.PUNCH_TAG_VALID == -1)) visible_ = false
          } else if(colName == 'PUNCH_NUMBER') {
            if(this.filtering[colName] && values.PUNCH_NUMBER_VALID == -1) visible_ = false
          } else if(this.filtering[colName] != values[colName]) visible_ = false
        }
      })

      return visible_
    },
    compare(a, b) {
      // Use toUpperCase() to ignore character casing
      const bandA = `${a[this.activity]}-${a.SUBSYSTEM}`.toUpperCase();
      const bandB = `${b[this.activity]}-${b.SUBSYSTEM}`.toUpperCase();

      let comparison = 0
      if(bandA > bandB) comparison = 1
      else if(bandA < bandB) comparison = -1
      
      return comparison
    },

    // Input & Search functions --------------------------------------
    trimDivText(selection) {
      selection.html(selection.html().replace(/<div(.*?)\/div>/g, ''))
    },
    setCaret(el) {
      let range = document.createRange(),
          sel = window.getSelection(),
          lastKnownIndex = -1;
      for (let i = 0; i < el.childNodes.length; i++) {
        if (this.isTextNodeAndContentNoEmpty(el.childNodes[i])) {
          lastKnownIndex = i;
        }
      }
      if (lastKnownIndex === -1) {
        throw new Error('Could not find valid text content');
      }
      let row = el.childNodes[lastKnownIndex],
          col = row.textContent.length;
      range.setStart(row, col);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
      el.focus();
    },
    isTextNodeAndContentNoEmpty(node) {
      return node.nodeType == Node.TEXT_NODE && node.textContent.trim().length > 0
    },
    search(loading=true) {
      setTimeout(() => {
        this.Chart_filtering(this.svg.select('.search_input').text())
        if (this.svg.select('.search_input').text().length > 0) this.visiblexBox()
        else if (this.svg.select('.search_input').text().length == 0) this.hiddenXbox()
      }, 300);
    },
    visiblexBox() {

      this.svg.selectAll('.clearBox').style('opacity', 1)
    },
    hiddenXbox() {
      this.svg.selectAll('.clearBox').style('opacity', 0)
    },
    textClear() {
      setTimeout(() => {
        if (this.svg.select('.search_input').text()) {
          this.svg.select('.search_input').text('')
          this.search(false)
          this.Chart_filtering()
        }
      }, 500);
    },  
    // ---------------------------------------------------------------

    activityChanged() {
      // It must be different if not, the stored procedure will be falling in infinite loop.
      if(this.activity != 'MC_PLAN') var action = {
        name: 'Query Modifier',
        type: 'direct',    // important!
        target: 'none',
        command: 'replace',
        value: this.activity,
        substring: 'MC_PLAN',
        strings: [
          'MC_PLAN'
        ],
      }; else action = {
        type: 'direct',    // important!
        target: 'none',
      }

      let request_ = {
        dataType: 'row',
        action,
        filters     : {
          // targetFilter.colName : TAG | PUNCH_NUMBER
          // if the filter value is empty, filter will be trimmed by store module.
          TAG: this.filtering.TAG || '',  
          PUNCH_NUMBER: this.filtering.PUNCH_NUMBER || '',  
        },
        iFilters    : {
          filterString : '',
          inputFilter  : ''
        }
      }

      this.$emit('request-action', request_)
    },

    trimFilterPure() {
      let filters_ = JSON.parse(JSON.stringify(this.filtering))

      Object.keys(filters_).forEach(k_ => {
        if(typeof filters_[k_] == 'object') {
          if(Object.keys(filters_[k_]).length === 0) delete filters_[k_]
        } else {
          if(!filters_[k_]) delete filters_[k_]
        }
      })

      return filters_
    },
    trimFilter() {
      // It must be different if not, the stored procedure will be falling in infinite loop.
      if(this.activity != 'MC_PLAN') var action = {
        name: 'Query Modifier',
        type: 'direct',    // important!
        target: 'none',
        command: 'replace',
        value: this.activity,
        substring: 'MC_PLAN',
        strings: [
          'MC_PLAN'
        ],
      }; else action = {
        type: 'direct',    // important!
        target: 'none',
      }

      let filters = {
        PUNCH_STATUS    : !this.filtering.PUNCH_STATUS || this.filtering.PUNCH_STATUS.length == 3 ? [] : (this.filtering.PUNCH_STATUS.length === 0 ? ['NONE'] : this.filtering.PUNCH_STATUS),
        SUBSYSTEM_STATUS: !this.filtering.SUBSYSTEM_STATUS || this.filtering.SUBSYSTEM_STATUS.length == 5 ? [] : (this.filtering.SUBSYSTEM_STATUS.length === 0 ? ['NONE'] : this.filtering.SUBSYSTEM_STATUS),
        DISCIPLINE      : !this.filtering.DISCIPLINE || this.filtering.DISCIPLINE.length == 10 ? [] : (this.filtering.DISCIPLINE.length === 0 ? ['NONE'] : this.filtering.DISCIPLINE),

        MOC_OPEN        : this.filtering.MOC_OPEN || '',  
        PMCS_OPEN       : this.filtering.PMCS_OPEN || '',  
        DBSPO           : this.filtering.DBSPO || '',  
        FLOAT_OFF       : this.filtering.FLOAT_OFF || '',  
        OBS_OPEN        : this.filtering.OBS_OPEN || '',  

        SUBSYSTEM       : this.filtering.SUBSYSTEM || '',  
        TAG             : this.filtering.TAG || '',  
        PUNCH_NUMBER    : this.filtering.PUNCH_NUMBER || '',  
      }

      return JSON.parse(JSON.stringify({
        action,
        filters
      }))
    }
  }
}