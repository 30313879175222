import * as d3 from 'd3'
import { svg } from 'd3'
// ------------------------------------------------------------------------------- Global

export default {
  methods: {
    Draw_Charts() {
      this.SendRequestAction = (selection, tag) => {
        
        selection
        .on('mouseover', (_, i, a) => {
          let el = d3.select(a[i])
          el.style('cursor', 'pointer')
          if(el.node().tagName == 'g') el.style('opacity', .5)
          else el.attr('fill', '#81d4fa')
        })
        .on('mouseout', (_, i, a) => {
          let el = d3.select(a[i])
          el.style('cursor', 'pointer')
          if(el.node().tagName == 'g') el.style('opacity', 1)
          else el.attr('fill', '#333')
        })
        .on('click', () => {
          let request_ = this.getRequestColumnProps('eqpopup', { TAG: tag }, this.ColumnProps)
          // console.log(tag)--tag 잘들어오는지 확인! JSON Properties에 eqpopup연결시키기!
          this.$emit('request-action', request_)
        })
      }
      this.loadSvg(0, 0, '/TORTUE/Common/Project_Logo_r1.svg')

      let Form_Hull = this.svg

      Form_Hull
      .append('text')
      .attr('transform', `translate(30, 90)`)
      .style('font-family', 'roboto').style('font-size', 16).style('fill', '#333').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Equipment Layout -')

      Form_Hull
      .append('text')
      .attr('id', 'Title')
      .attr('transform', `translate(175, 90)`)
      .style('font-family', 'roboto').style('font-size', 16).style('fill', '#44A9DF').attr('text-anchor', 'start').attr('alignment-baseline', 'middle').attr('font-weight', 400)
      .text('Hull Common')

      // Legends
      Form_Hull
      .append('image')
      .attr('id', 'Legends')
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/SVG/Legends.svg`)
      .attr('transform', `translate(45.5,230)`).style('opacity', 1)

     // Overall_Hull_Group
     let Hull_Common = this.svg
     .append('g')
     .attr('id', 'Hull_AFT_Group')
     .attr('opacity', 1)
     .attr('transform', `translate(0,0) scale(1)`)

    //  setTimeout(() => {
    //     Hull_Common
    //     .append('image')
    //     .attr('opacity', 1).style('cursor', 'pointer')
    //     .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Blocks/Hull/HULL_FOR.png`)
    //     .attr('transform', `translate(810,308) scale(1)`)

    //     Hull_Common
    //      .append('image')
    //      .attr('opacity', 1).style('cursor', 'pointer')
    //      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Blocks/Hull/HULL_AFT.png`)
    //      .attr('transform', `translate(350,650) scale(1)`)
    //  }, 600);
     setTimeout(() => {
       this.loadSvg(0, 0, `/TORTUE/Equipment/SVG/HULL-COMMON.svg`).then((innerSvg) => {
           this.Draw_EQ_Code(innerSvg, 'HULL-COMMON')
           this.Draw_EQ_Block(innerSvg, 'HULL-COMMON')
           this.Draw_EQ_Tag(innerSvg, 'HULL-COMMON')
           this.innerSvg = innerSvg // SVG  
       })      
     }, 50)

     this.drawProgress() 
    },

  }
}
