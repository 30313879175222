import * as d3 from 'd3'

export default {

  methods: {
    /*
      loadMain()
      imageMainLoaded()
      postLoadMain()
    */

    load_MainPlant() {
      this.innerSvg = this.svg.append('svg')

      this.stage_Header.moveToFront()
      
      let background = this.innerSvg
      .append('image')
      .attr('id','id_mainplant_background')
      .attr('transform', `translate(-330, -330) scale(1.3)`)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/China1/Information_Guide/images/MAINPLANT.jpg`)
      .style('opacity', 0)

      d3.select('#id_mainplant_background')
      .transition().duration(350)
      .attr('transform', `translate(-90, -110) scale(1)`)
      .style('opacity', 1)

      // After loading the SVG, perform initialization
      this.loadSvg(
        this.innerSvg, {
        x   : 0,
        y   : 0,
        url : '/China1/Information_Guide/svg/MainPlant.svg'
      }).then(() => {
        this.Queries.SQL1.forEach(r => {
          let xy_ = d3.select(`#PNT-${r.CODE}`).attr('visibility', 'hidden')
          r.x = xy_.attr('cx')
          r.y = xy_.attr('cy')

          this.innerSvg.select(`#AREA-${r.CODE}`).style('opacity', 0)
          .attr('transform', `translate(${r.x}, ${r.y}) scale(0)`)

          this.innerSvg.select(`#BOX-${r.CODE}`).style('opacity', 0)
          this.innerSvg.select(`#BTN-${r.CODE}`)
          .style('cursor', 'pointer')
          .style('opacity', 0)
          .on('click', () => {
            this.goto_ImformationPage(r)
          })
          .call(this.call_Coordinate, r.CODE, r.x, r.y) // ---> Event.js
        })
      })
    },
    
    draw_MainPlant_Titles() {
      let areaList = this.innerSvg
      .append('g')
      .attr('id', 'id_area_list')
      .attr('transform', `translate(30, 200)`)

      this.Queries.SQL1.forEach( (d, i) => {
        // Title List of Area
        areaList
        .append('g')
        .attr('id', `id_area_list${i}`)
        .attr('transform', `translate(0, 0)`)
        .style('opacity', 0)
        .style('cursor', 'pointer')
        .on('click', () => {
          this.goto_ImformationPage(d)
        })
        .call(this.call_AreaList, d.CODE, d.x, d.y) // ---> Event.js

        areaList.select(`#id_area_list${i}`)
        .append("text")
        .attr('class', `AREATEXT_${i}`)
        .attr('transform', `translate(0, 0)`)
        .attr('font-size', 12)
        .style('fill', 'black' )
        .text(this.Toggle.status == 'EN'? d.NAME:d.CNAME )
        .call(this.draw_gmx_TextBox_v1, {
          selection: areaList,
          x: 0,
          y: 0,
          id: `#id_area_list${i}`,
          padding: {left: 8, right: 8, top: 2.3, bottom: 2.3},
          color: '#B4E3FA',
          border:{ color: '#44A9DF', size: 0.1, rx: 3, ry: 3 },
          opacity: 1
        })
      })

      this.Queries.SQL1.forEach( (d,i) => {
        d3.select(`#id_area_list${i}`)
        .transition().duration(i*30)
        .attr('transform', `translate(0, ${(i*23)})`).style('opacity', 1)
      })
    },
    
  }
}