import * as d3 from 'd3'

export default {
  data: () => ({
    dataSet: [],
    rowHeight: 19,
    level1Names: [],
    donutData:null,
    filters:null,
  }),
  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },
  methods: {
    setDefaultValues(){
      this.level1Names = [...new Set(this.DataItems.map(d => d.DISC))]
      this.dataSet = JSON.parse(JSON.stringify(this.DataItems))
      this.filters = JSON.parse(JSON.stringify(this.Queries.SQL1[0]))

      if(this.filters){
        this.donutData = {
          width: this.filters.WIDTH ? this.filters.WIDTH : 100,
          gab: this.filters.GAB ? this.filters.GAB : 30,
          title: this.filters.TITLE ? this.filters.TITLE : 'A Check Sheet',
          attrs: [
            { 
              text: this.filters.TITLE1 ? this.filters.TITLE1 : 'Total',
              refColumn: this.filters.COL1 ? this.filters.COL1 : 'TOTAL',
              fColor: this.filters.FCOLOR1 ? this.filters.FCOLOR1 : '#BDBCBC',
              tColor: this.filters.TCOLOR1 ? this.filters.TCOLOR1 : '#818181',
              filter: this.filters.FILTER1 ? this.filters.FILTER1 : ''
            },
            { 
              text: this.filters.TITLE2 ? this.filters.TITLE2 : 'Open',
              refColumn: this.filters.COL2 ? this.filters.COL2 : 'OPEN',
              refColumnProg: this.filters.VALUE2 ? this.filters.VALUE2 : 'OPEN_PROG',
              fColor: this.filters.FCOLOR2 ? this.filters.FCOLOR2 : '#F44336',
              tColor: this.filters.TCOLOR2 ? this.filters.TCOLOR2 : '#D50000',
              filter: this.filters.FILTER2 ? this.filters.FILTER2 : ''
            },
            { 
              text: this.filters.TITLE3 ? this.filters.TITLE3 : 'Closed',
              refColumn: this.filters.COL3 ? this.filters.COL3 : 'CLOSED',
              refColumnProg: this.filters.VALUE3 ? this.filters.VALUE3 : 'CLOSED_PROG',
              fColor: this.filters.FCOLOR3 ? this.filters.FCOLOR3 : '#82B22E',
              tColor: this.filters.TCOLOR3 ? this.filters.TCOLOR3 : '#2E7D32',
              filter: this.filters.FILTER3 ? this.filters.FILTER3 : ''
            },
          ]
        }
      }
      
      let lightGray = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `lightGray`).attr('x1', 0.772).attr('x2', 0.228).attr('y1', 0.228).attr('y2', 0.919)
      lightGray.append('stop').attr('stop-color', '#fff').attr('offset', '0').attr('stop-opacity', 1)
      lightGray.append('stop').attr('stop-color', '#d0d2d3').attr('offset', '1').attr('stop-opacity', 1)
      
      this.svg
      .append('clipPath')
      .attr('id', 'activity_overflow_none')
      .append('rect')
      .attr('transform', `translate(175, 0)`)
      .attr('width', 375)
      .attr('height', this.rowHeight)
    },
  }
}