import * as d3 from 'd3'
import { sum } from 'd3'
import Data from '../../../../../../includes/primitives/Color_Data'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  data: () => ({
    summary: [
      {key: 'TOTAL', text: 'Total MR'},
      {key: 'LOA_PLANNED', text: 'LOA Planned'},
      {key: 'LOA_ISSUED', text: 'LOA Issued'},
      {key: 'PO_PLANNED', text: 'PO Planned'},
      {key: 'PO_ISSUED', text: 'PO Issued'},
    ],
    position: [
      {key: 'summary', x: 20.1, y: 60.1},
      {key: 'table', x: 0.1, y: 220.1},
      {key: 'chart1', x: 290, y: 450.1},
      {key: 'chart2', x: 740, y: 450.1},
      
    ],
    tables:[
      {
        key: 'LOA',
        title: 'LOA Summary Table',
        dataSource: 'SQL1',
        x: 20, y: 0,
        thead: [
          { key: 'PSRNUM', html: 'PSR Num', bColor: '#EEEEEE' },
          { key: 'LOA_NOT_ISSUED', html: 'LOA Not Issued', bColor: '#EEEEEE' },
          { key: 'LOA_ISSUED', html: 'LOA Issued', bColor: '#EEEEEE' },
          { key: 'TOTAL', html: 'Grand Total',bColor: '#EEEEEE' },
        ],
        tbody: [
          { key: 'PSRNUM', w: 100, align: 'left' },
          { key: 'LOA_NOT_ISSUED', w: 80, filterString: ` LOA_ISSUE_ACTUAL IS NULL AND LOA_ISSUE_PLAN IS NOT NULL ` },
          { key: 'LOA_ISSUED', w: 80, filterString: ` LOA_ISSUE_ACTUAL IS NOT NULL ` },
          { key: 'TOTAL', w: 80, filterString: ` LOA_ISSUE_PLAN IS NOT NULL ` },  // fix me: 다른 테이블에도 filter string 추가~
        ]
      },
      {
        key: 'PO',
        title: 'PO Summary Table',
        dataSource: 'SQL2',
        x: 440, y: 0,
        thead: [
          { key: 'PSRNUM', html: 'PSR Num', bColor: '#EEEEEE' },
          { key: 'PO_NOT_ISSUED', html: 'PO Not Issued', bColor: '#EEEEEE' },
          { key: 'PO_ISSUED', html: 'PO Issued', bColor: '#EEEEEE' },
          { key: 'TOTAL', html: 'Grand Total', bColor: '#EEEEEE' },
        ],
        tbody: [
          { key: 'PSRNUM', w: 100, align: 'left' },
          { key: 'PO_NOT_ISSUED', w: 80, filterString: ` PO_ISSUE_ACTUAL IS NULL AND [PO_ISSUE_PLAN] IS NOT NULL ` },
          { key: 'PO_ISSUED', w: 80, filterString: ` PO_ISSUE_ACTUAL IS NOT NULL ` },
          { key: 'TOTAL', w: 80, filterString: ` PO_ISSUE_PLAN IS NOT NULL ` },
        ]
      },
      {
        key: 'Calendar',
        title: 'Calendar days between LOA and PO Issue',
        subtitle: '(Historical performance)',
        dataSource: 'SQL3',
        x: 860, y: 0,
        thead: [
          { key: 'PSRNUM', html: 'PSR Num', bColor: '#EEEEEE' },
          { key: 'AVG', html: 'Average', bColor: '#EEEEEE' },
        ],
        tbody: [
          { key: 'PSRNUM', w: 120, align: 'left' },
          { key: 'AVG', w: 120 },
        ]
      },
      {
        key: 'LOA_PO_EXCUTED',
        title: 'LOA and PO Executed',
        dataSource: 'SQL4',
        x: 20, y: 200,
        thead: [
          { key: 'PSRNUM', html: 'PSR Num', bColor: '#EEEEEE' },
          { key: 'TOTAL', html: 'LOA and PO Issued', bColor: '#EEEEEE' },
        ],
        tbody: [
          { key: 'PSRNUM', w: 80, align: 'left' },
          { key: 'TOTAL', w: 120 , filterString: ` [LOA_ISSUE_ACTUAL] IS NOT NULL AND [PO_ISSUE_ACTUAL] IS NOT NULL `},
        ]
      },
      {
        key: 'LOA_EXCUTED_NOT_PO',
        title: 'LOA Executed(Not yet PO)',
        dataSource: 'SQL5',
        x: 20, y: 380,
        thead: [
          { key: 'PSRNUM', html: 'PSR Num', bColor: '#EEEEEE' },
          { key: 'TOTAL', html: 'PO Pending', bColor: '#EEEEEE' },
        ],
        tbody: [
          { key: 'PSRNUM', w: 80, align: 'left' },
          { key: 'TOTAL', w: 120, filterString: ` [LOA_ISSUE_ACTUAL] IS NOT NULL AND [PO_ISSUE_ACTUAL] IS NULL ` },
        ]
      }
    ],
    legend: 
    [
      { column: 'P1_PSR', color: '#FB8C00', shape: 'rect', text: 'P1_PSR', axis: 'left', x: 355, y: 4 },
      { column: 'P2_PSR', color: '#E91E63', shape: 'rect', text: 'P2_PSR', axis: 'left', x: 355, y: 16 },
      { column: 'P3_PSR', color: '#7C4DFF', shape: 'rect', text: 'P3_PSR', axis: 'left', x: 355, y: 28 },
      
    ],
  }),
  methods: {
    getPosition(key){
      return this.position.find(f => f.key == key)
    },
    //drawTitleBar(){
      // this.svg.append('rect')
      // .attr('transform', `translate(20, 10)`)
      // .attr('width', 1090)
      // .attr('height', 30)
      // .attr('stroke-width', 0.5).attr('stroke', '#bcbcbc')
      // .attr('fill', '#01579B')
      
      // this.svg
      // .append('text')
      // .attr('transform', `translate(30, 30)`)
      // .style('font-size', 13).style('font-family', 'roboto').attr('fill', '#fff').style('text-anchor', 'start')
      // .text('LOAs with no POs')
    //},
    drawSummary() {
      const g = this.svg
      let summary_group = g.append('g')
      .attr('transform', `translate(${this.getPosition('summary').x}, ${this.getPosition('summary').y})`)
      .attr('class', 'summary_group')
      
      let summarydata = this.DataItems[0]

      this.summary.forEach((summ, i) => {
        
        summary_group
        .append('rect')
        .attr('transform', `translate(${i * 230}, 0)`)
        .attr('width', 170)
        .attr('height', 100)
        .attr('stroke-width', 0.5).attr('stroke', '#bcbcbc')
        .attr('fill', '#fff')
        
        summary_group
        .append('rect')
        .attr('transform', `translate(${i * 230}, 0)`)
        .attr('width', 170)
        .attr('height', 25)
        .attr('stroke-width', 0.5).attr('stroke', '#bcbcbc')
        .attr('fill', '#01579B')

        
        summary_group
        .append('text')
        .attr('transform', `translate(${(i * 230) + 85}, 17)`)
        .style('font-size', 12).style('font-family', 'roboto').attr('fill', '#fff').style('text-anchor', 'middle')
        .text(summ.text)

        
        summary_group
        .append('text')
        .attr('transform', `translate(${(i * 230) + 85}, 75)`)
        .style('font-size', 30).style('font-family', 'roboto').attr('fill', '#000').style('text-anchor', 'middle')
        .text(summarydata[summ.key].toLocaleString('en'))
      })

      
      

    },
    drawTables(){
      const g = this.svg

      let table_group = g.append('g')
      .attr('transform', `translate(${this.getPosition('table').x}, ${this.getPosition('table').y})`)
      .attr('class', 'table_group')

      this.tables.forEach(tdata => {
        // Title Text
        table_group
        .append('text')
        .attr('transform', `translate(${tdata.x}, ${tdata.y - 10})`)
        .style('font-family','roboto')
        .style('font-size', 13)
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .attr('fill', '#000')
        .text(tdata.title)
        
        // Sub Title Text
        table_group
        .append('text')
        .attr('transform', `translate(${tdata.x}, ${tdata.y + 138})`)
        .style('font-family','roboto')
        .style('font-size', 11)
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .attr('fill', '#757575')
        .text(tdata.subtitle)

        
        const reducer = (previousValue, currentValue) => previousValue + currentValue

        let widths = tdata.tbody.map(d => d.w)
        let sumWidth = widths.reduce(reducer)


        let object = table_group.append('foreignObject')
        .attr('class', `table_group_foreign_object_${tdata.key}`)
        .attr('x', tdata.x)
        .attr('y', tdata.y)
        .attr('width', sumWidth + 'px')
        .attr('height', '100%')
        .append('xhtml:body')
        .style('position', 'fixed')

        
        let table = object.append('table')
        .attr('id', 'commodity_summary_table_')
        .style('border-collapse', 'collapse')
        .style('font-size', '11px')
        .style('width', `${sumWidth}px`)
        .style('table-layout', 'fixed')
        .style('border', 'solid 1px #eee')

        let thead = table.append('thead')
        let tr = thead.append('tr')

        tdata.thead.forEach(item => {

          tr.append('th')
          .attr('class', `table_head_${tdata.key}`)
          .style('height', '25px')
          .style('padding', '3px')
          .style('font-size','11px')
          .style('background', item.bColor ? item.bColor : '#FAFAFA')
          .html(item.html)
        })
          
        let tbodykeys = tdata.tbody
        let tbody = table.append('tbody').attr('id', `table_group_foreign_object_${tdata.key}`)
        let list = this.Queries[`${tdata.dataSource}`]

        list.forEach((row, r) => {
          let tr = tbody.append('tr')
          let lastRow = r == list.length - 1

          tbodykeys.forEach(column => {
            let td = tr.append('td')
            .style('border', 'solid 1px #eee')
            .style('height', '20px')
            .style('text-align', column.align ? column.align : 'right')
            .style('color', '#000000')
            .style('padding', '0 3px 0 3px')
            .style('background', lastRow ? '#fafafa' : '#fff')
            td.html(list[r][column.key] ? list[r][column.key].toLocaleString('en') : list[r][column.key])
            td.style('cursor', () => {
              if (list[r][column.key] == 0 || !list[r][column.key]) return
              if (!column.filterString) return
              return 'pointer'
            })
              .on('mouseover', () => {
                if (list[r][column.key] == 0 || !list[r][column.key]) return
                if (!column.filterString) return
                td.style('opacity',0.7)
              })
              .on('mouseout', () => {
                if (list[r][column.key] == 0 || !list[r][column.key]) return
                if (!column.filterString) return
                td.style('opacity', 1)
              })
              .on('click', () => {
                if (list[r][column.key] == 0 || !list[r][column.key]) return
                if (!column.filterString) return
                
                let filterString = ``
                if (list[r].PSRNUM != 'Grand Total') filterString += ` [PSRNUM] = '${list[r].PSRNUM}' AND `
                if (column.filterString) filterString += ` ${column.filterString}`

                let request_ = {
                  dataType: 'row',
                  action: {
                    type: 'direct',
                    target: 'slide-modal',
                    component: 'ServiceDatatable',
                    id: 336,
                    no: ''
                  },
                  filters: {
                  },
                  iFilters: {
                    filterString : filterString,
                    inputFilter  : ''
                  }
                }
                this.$emit('request-action', request_)
              })
          })

        })
      
      })


    },
    drawAxis(){
      
      // drawXAxisLine
      let chartArea = this.svg
        .append('g')
        .attr('transform', `translate(${this.getPosition('chart1').x}, ${this.getPosition('chart1').y})`)
        .attr('class', 'axis_group')
        .append('g')
        .attr('transform', `translate(0,0)`)

        chartArea
        .append('g')
        .attr('class', '_x_axis_line__')
        .append('path')
        .attr('d', `M 0,${this.Canvas.CanvasChartHeight} H ${this.Canvas.CanvasChartWidth}`)
        .style('stroke', '#BDBDBD')
        .style('stroke-width', this.Axis.AxisXLineWeight)


        // drawXAxisLevel
        let levelBox = chartArea
          .append('g')
          .attr('class', '_x_axis_level_unit__')
          .selectAll('g')
          .data(this.Queries.SQL6)
          .enter()
          .append('g')
          .attr('transform', d => `translate(${this.scaleXInfo.scale(d.CATEGORY)},0)`)

        levelBox // Series Name
          .append('text')
          .attr('transform', `translate(
            ${Math.round(this.setThickness / 2 -this.Bar.BarDistance/4)},
            ${this.Canvas.CanvasChartHeight + Math.round(this.setThickness / 2) }
            )${this.Axis.AxisXLevelDirection == 'Vertical' ? ' rotate(-90) ' : ''}
          `)
          .attr('class', this.Axis.AxisXLevelStyle)
          .style('font-size', 11)
          .style('font-family', this.Axis.AxisXLevelFont)
          .attr('fill', (_, i) => (this.Axis.AxisXLevelAutoColor == 'Y' ? Data.setGradients['Represent'](this.Bar.BarColorSet[i]) : this.Axis.AxisXLevelColor))
          .attr('text-anchor', 'middle')
          .text(d => {
            let words = d.CATEGORY.split('/')
            // let html = ''
        
            // for (var i = 0; i < words.length; i++) {
            //   let length = -(words[0].length * 4.5)
            //   html += `<tspan dy="${i > 0 ? 9 : 0}" dx="${i > 0 ? length : 0}">${words[i]}</tspan>`
            // }

            // return html
            return words[0]
          })

          levelBox // Series Name
          .append('text')
          .attr('transform', `translate(
            ${Math.round(this.setThickness / 2 -this.Bar.BarDistance/4)},
            ${this.Canvas.CanvasChartHeight + Math.round(this.setThickness / 2) + 12 }
            )${this.Axis.AxisXLevelDirection == 'Vertical' ? ' rotate(-90) ' : ''}
          `)
          .attr('class', this.Axis.AxisXLevelStyle)
          .style('font-size',11)
          .style('font-family', this.Axis.AxisXLevelFont)
          .attr('fill', (_, i) => (this.Axis.AxisXLevelAutoColor == 'Y' ? Data.setGradients['Represent'](this.Bar.BarColorSet[i]) : this.Axis.AxisXLevelColor))
          .attr('text-anchor', 'middle')
          .html(d => {
            let words = d.CATEGORY.split('/')
            return words[1]
          })

          
        // drawYAxisLine
        chartArea
        .append('g')
        .attr('class', '_y_axis_line__')
        .append('path')
        // Transform a data value to Y coordinate.
        .attr('d', `M 0,0 V ${this.Canvas.CanvasChartHeight}`)
        .style('stroke', '#BDBDBD')
        .style('stroke-width', this.Axis.AxisYLineWeight)

        // drawYAxisLevel
        let levelYBox = chartArea
          .append('g')
          .attr('class', '_y_axis_level_unit__')
          .selectAll('g')
          .data(this.scaleYInfo.tickValues)
          .enter()
          // Append every single box for a line value & unit. -------------------
          // Inited X, Y coordinate of the group is (0, 0), and its width
          // is 0 too. So, at the current time of that the '-this.Axis.AxisLevelSpace'
          // is applied to the X, X coordinate is '-this.Axis.AxisLevelSpace'.
          // And then the width will be extended toward left by the child
          // element's attribute ('text-anchor', 'end'). It doesn't need to 
          // recalculate the X coordinate of the every single boxes.
          .append('g')
          .attr('class', (d, i) => `_y_axis_level_unit_group__${i}`)

        // Append Unit
        levelYBox
          .append('text')
          .attr('id', (_, i) => `Level_Unit_${i}`)
          .style('font-size', this.Axis.AxisYLevelUnitSize)
          .style('font-family', this.Axis.AxisYLevelFont)
          .attr('class', this.Axis.AxisYLevelStyle)
          .style('font-weight', this.Axis.AxisYLevelWeight)
          .attr('fill', '#BDBDBD')
          .attr('alignment-baseline', 'middle')
          .attr('text-anchor', 'end')
          .text(this.Axis.AxisYLevelUnitFormat)

        // Append Unit
        levelYBox
          .append('text')
          .attr('id', (_, i) => `Level_Unit_unit_${i}`)
          .attr('x', (_, i) => -Math.round(this.getNodeElValue(`#Level_Unit_${i}`, 'width') * 1.1))
          .style('font-size', this.Axis.AxisYLevelSize)
          .style('font-family', this.Axis.AxisYLevelFont)
          .attr('class', this.Axis.AxisYLevelStyle)
          .style('font-weight', this.Axis.AxisYLevelWeight)
          .attr('fill', '#BDBDBD')
          .attr('alignment-baseline', 'middle')
          .attr('text-anchor', 'end')
          .text(tickValue_ => Math.round(tickValue_))

        // Alignment of level
        levelYBox.attr('transform', (tickValue_, i)=>  {
          let levelTextWidth = d3.select(`#Level_Unit_${i}`).node().getBoundingClientRect().width
          let levelUnitWidth = d3.select(`#Level_Unit_unit_${i}`).node().getBoundingClientRect().width         
            return `translate(${ this.Axis.AxisYLevelPosition == 'right' ?  levelTextWidth + levelUnitWidth + this.Axis.AxisYLevelSpace : -this.Axis.AxisYLevelSpace }, ${this.scaleYInfo.scale(tickValue_)})`
        })
        
        
      chartArea
      .append('g')
      .attr('class', '_y_axis_grid_line__')
      .selectAll('path')
      .data(this.scaleYInfo.tickValues)
      .enter()
      .append('path')
      // Transform a data value to Y coordinate.
      .attr('d', tickValue_ => `M 0,${this.scaleYInfo.scale(tickValue_)} H ${this.Canvas.CanvasChartWidth}`)
      .style('stroke', this.Axis.AxisYGridColor)
      .style('stroke-width', this.Axis.AxisYGridWeight)

    },
    drawBarchart(){

      let legendGoup = this.svg
        .append('g')
        .attr('id','chart_legend')
        .attr('transform', `translate(${this.getPosition('chart1').x}, ${this.getPosition('chart1').y-50})`)
        
        legendGoup.append('text')
        .attr('x', 0)
        .attr('y', 10)
        .attr('text-anchor', 'start')
        .attr('font-size', '13px')
        .text('Calendar Days Between LOA and PO Executed')

        this.legend.forEach((d,i) =>{
          legendGoup.append('rect')
          .attr('x', d.x)
          .attr('y', d.y)
          .attr('width', 14)
          .attr('height', 8)
          .style('fill', d.color)

          legendGoup.append('text')
          .attr('x', d.x + 16)
          .attr('y', d.y + 8)
          .attr('text-anchor', 'start')
          .attr('font-size', '10px')
          .text(d.text)
        })

      this.drawAxis()
      
      let g = this.svg
        .append('g')
        .attr('transform', `translate(${this.getPosition('chart1').x}, ${this.getPosition('chart1').y})`)
        .attr('class', 'chart1_group')

        // let keys_ = Object.keys(this.Queries.SQL6[0]).splice(1)
        // console.log(keys_)
        let keys_ = ['P1_PSR', 'P2_PSR', 'P3_PSR']


      let chartWrapper = g
      .append('g')
      .selectAll('g')
      .data(d3.stack().keys(keys_)(this.Queries.SQL6))
      .enter()
      .append('g')
      .attr('fill', (_, i) => (this.Bar.BarColorSet[i]))
      .selectAll('rect')
      .data((d) => d)
      .enter()
      
      chartWrapper
      .append('rect')
      .attr('x', (d, i) => this.scaleXInfo.scale(this.Queries.SQL6[i].CATEGORY))
      .attr('y', (d) => d[1] >=  0 ? this.scaleYInfo.scale(d[1]) : this.scaleYInfo.scale(d[0]))
      .attr('width', this.setThickness * .75)
      .attr('height', (d) => Math.abs(this.scaleYInfo.scale(d[0]) - this.scaleYInfo.scale(d[1])))
      // ------------------------------------------------------------------------- Series & Value

      let valueWrapper = g
      .append('g')
      .selectAll('g')
      .attr('id', (_, i) => `value_wrapper_${i}`)
      .data(d3.stack().keys(keys_)(this.Queries.SQL6))
      .enter()
      .append('g')
      .attr('fill', (_, i) => (this.Bar.BarValueAutoColor == 'Y' ? (this.Bar.BarColorSet[i]) : this.Bar.BarValueColor))
      .selectAll('rect')
      .data((d) => d)
      .enter()

      valueWrapper
      .append('text')
      .attr('id', (d, i) =>  `ValueText${i}`)
      .style('font-size', this.Bar.BarValueSize)
      .style('font-family', this.Bar.BarValueFont)
      .attr('text-anchor', 'middle')
      .attr('class', this.Bar.BarValueStyle)
      .attr('x', (d, i) => this.scaleXInfo.scale(this.Queries.SQL6[i].CATEGORY)+this.setThickness/3+1)
      .attr('y', (d) => {
        let middleOfBoxHeight = ((d[1] - d[0]) / 2) + 2
        let textYPosition
        // Magic number for hanging text at top of Box
        let textAlign4Hang = 2
        if(d[1] >=  0) {
          if(this.Bar.BarValuePosition == 'middle') {
            textYPosition = this.scaleYInfo.scale(d[1]) + middleOfBoxHeight
          } else if(this.Bar.BarValuePosition == 'bottom') {
            textYPosition = this.scaleYInfo.scale(d[1]) + this.Bar.BarValueSize
          } else {
            textYPosition = this.scaleYInfo.scale(d[1])
          }
          return  textYPosition + (this.Bar.BarValuePosition == 'top' ? -this.Bar.BarValueSpace : this.Bar.BarValueSpace -textAlign4Hang)
        }
        else {
          return this.scaleYInfo.scale(d[0])
        } 
      })
      .text((d , i) =>{
        let val = d[1] >= d[0] ? (d[1] - d[0]) : d[1]
        if (val < 3) return
        else return val == 0 ? '' : val
      })

      
      // Total Values
      g
      .append('g')
      .selectAll('text')
      .data(this.Queries.SQL6)
      .enter()
      .append('text')
      .style('font-size', '14px')
      .style('font-family', this.Bar.BarValueFont)
      .attr('text-anchor', 'middle')
      .style('font-weight', 900)
      .style('text-decoration', 'underline')
      .attr('x', (d, i) => this.scaleXInfo.scale(this.Queries.SQL6[i].CATEGORY)+this.setThickness/3+1)
      .attr('y', d => this.scaleYInfo.scale(d.TOTAL) - 7)
      .text(d => d.TOTAL)
      .style('cursor', 'pointer')
      .on('click', d => {
        let filterString = ` [CALENDAR_DAYS_BETWEEN_LOA_AND_PO_ISSUE_CATEGORY] = '${d.CATEGORY}' `

        let request_ = {
          dataType: 'row',
          action: {
            type: 'direct',
            target: 'slide-modal',
            component: 'ServiceDatatable',
            id: 336,
            no: ''
          },
          filters: {
          },
          iFilters: {
            filterString : filterString,
            inputFilter  : ''
          }
        }
        this.$emit('request-action', request_)
      })

      
    },
    drawAxis2(){
      
      // drawXAxisLine
      let chartArea = this.svg
        .append('g')
        .attr('transform', `translate(${this.getPosition('chart2').x}, ${this.getPosition('chart2').y})`)
        .attr('class', 'axis_group')
        .append('g')
        .attr('transform', `translate(0,0)`)

        chartArea
        .append('g')
        .attr('class', '_x_axis_line__')
        .append('path')
        .attr('d', `M 0,${this.Canvas.CanvasChartHeight} H ${this.Canvas.CanvasChartWidth}`)
        .style('stroke', '#BDBDBD')
        .style('stroke-width', this.Axis.AxisXLineWeight)


        // drawXAxisLevel
        let levelBox = chartArea
          .append('g')
          .attr('class', '_x_axis_level_unit__')
          .selectAll('g')
          .data(this.Queries.SQL7)
          .enter()
          .append('g')
          .attr('transform', d => `translate(${this.scaleXInfo2.scale(d.CATEGORY)},0)`)

        levelBox // Series Name
          .append('text')
          .attr('transform', `translate(
            ${Math.round(this.setThickness / 2 -this.Bar.BarDistance/4)},
            ${this.Canvas.CanvasChartHeight + Math.round(this.setThickness / 2) }
            )${this.Axis.AxisXLevelDirection == 'Vertical' ? ' rotate(-90) ' : ''}
          `)
          .attr('class', this.Axis.AxisXLevelStyle)
          .style('font-size', 11)
          .style('font-family', this.Axis.AxisXLevelFont)
          .attr('fill', (_, i) => (this.Axis.AxisXLevelAutoColor == 'Y' ? Data.setGradients['Represent'](this.Bar.BarColorSet[i]) : this.Axis.AxisXLevelColor))
          .attr('text-anchor', 'middle')
          .text(d => {
            let words = d.CATEGORY.split('/')
            return words[0]
          })

          levelBox // Series Name
          .append('text')
          .attr('transform', `translate(
            ${Math.round(this.setThickness / 2 -this.Bar.BarDistance/4)},
            ${this.Canvas.CanvasChartHeight + Math.round(this.setThickness / 2) + 12 }
            )${this.Axis.AxisXLevelDirection == 'Vertical' ? ' rotate(-90) ' : ''}
          `)
          .attr('class', this.Axis.AxisXLevelStyle)
          .style('font-size', 11)
          .style('font-family', this.Axis.AxisXLevelFont)
          .attr('fill', (_, i) => (this.Axis.AxisXLevelAutoColor == 'Y' ? Data.setGradients['Represent'](this.Bar.BarColorSet[i]) : this.Axis.AxisXLevelColor))
          .attr('text-anchor', 'middle')
          .html(d => {
            let words = d.CATEGORY.split('/')
            return words[1]
          })

          
        // drawYAxisLine
        chartArea
        .append('g')
        .attr('class', '_y_axis_line__')
        .append('path')
        // Transform a data value to Y coordinate.
        .attr('d', `M 0,0 V ${this.Canvas.CanvasChartHeight}`)
        .style('stroke', '#BDBDBD')
        .style('stroke-width', this.Axis.AxisYLineWeight)

        // drawYAxisLevel
        let levelYBox = chartArea
          .append('g')
          .attr('class', '_y_axis_level_unit__')
          .selectAll('g')
          .data(this.scaleYInfo2.tickValues)
          .enter()
          // Append every single box for a line value & unit. -------------------
          // Inited X, Y coordinate of the group is (0, 0), and its width
          // is 0 too. So, at the current time of that the '-this.Axis.AxisLevelSpace'
          // is applied to the X, X coordinate is '-this.Axis.AxisLevelSpace'.
          // And then the width will be extended toward left by the child
          // element's attribute ('text-anchor', 'end'). It doesn't need to 
          // recalculate the X coordinate of the every single boxes.
          .append('g')
          .attr('class', (d, i) => `_y_axis_level_unit_group__${i}`)

        // Append Unit
        levelYBox
          .append('text')
          .attr('id', (_, i) => `Level_Unit_${i}`)
          .style('font-size', this.Axis.AxisYLevelUnitSize)
          .style('font-family', this.Axis.AxisYLevelFont)
          .attr('class', this.Axis.AxisYLevelStyle)
          .style('font-weight', this.Axis.AxisYLevelWeight)
          .attr('fill', '#BDBDBD')
          .attr('alignment-baseline', 'middle')
          .attr('text-anchor', 'end')
          .text(this.Axis.AxisYLevelUnitFormat)

        // Append Unit
        levelYBox
          .append('text')
          .attr('id', (_, i) => `Level_Unit_unit_${i}`)
          .attr('x', (_, i) => -Math.round(this.getNodeElValue(`#Level_Unit_${i}`, 'width') * 1.1))
          .style('font-size', this.Axis.AxisYLevelSize)
          .style('font-family', this.Axis.AxisYLevelFont)
          .attr('class', this.Axis.AxisYLevelStyle)
          .style('font-weight', this.Axis.AxisYLevelWeight)
          .attr('fill', '#BDBDBD')
          .attr('alignment-baseline', 'middle')
          .attr('text-anchor', 'end')
          .text(tickValue_ => Math.round(tickValue_))

        // Alignment of level
        levelYBox.attr('transform', (tickValue_, i)=>  {
          let levelTextWidth = d3.select(`#Level_Unit_${i}`).node().getBoundingClientRect().width
          let levelUnitWidth = d3.select(`#Level_Unit_unit_${i}`).node().getBoundingClientRect().width         
            return `translate(${ this.Axis.AxisYLevelPosition == 'right' ?  levelTextWidth + levelUnitWidth + this.Axis.AxisYLevelSpace : -this.Axis.AxisYLevelSpace }, ${this.scaleYInfo2.scale(tickValue_)})`
        })
        
        
      chartArea
      .append('g')
      .attr('class', '_y_axis_grid_line__')
      .selectAll('path')
      .data(this.scaleYInfo2.tickValues)
      .enter()
      .append('path')
      // Transform a data value to Y coordinate.
      .attr('d', tickValue_ => `M 0,${this.scaleYInfo2.scale(tickValue_)} H ${this.Canvas.CanvasChartWidth}`)
      .style('stroke', this.Axis.AxisYGridColor)
      .style('stroke-width', this.Axis.AxisYGridWeight)

    },
    drawBarchart2(){

      let legendGoup = this.svg
        .append('g')
        .attr('id','chart_legend')
        .attr('transform', `translate(${this.getPosition('chart2').x}, ${this.getPosition('chart2').y-50})`)

        
        legendGoup.append('text')
        .attr('x', 0)
        .attr('y', 10)
        .attr('text-anchor', 'start')
        .attr('font-size', '13px')
        .text('LOA Executed (not yet PO)')

        this.legend.forEach((d,i) =>{
          legendGoup.append('rect')
          .attr('x', d.x)
          .attr('y', d.y)
          .attr('width', 14)
          .attr('height', 8)
          .style('fill', d.color)

          legendGoup.append('text')
          .attr('x', d.x + 16)
          .attr('y', d.y + 8)
          .attr('text-anchor', 'start')
          .attr('font-size', '10px')
          .text(d.text)
        })

      this.drawAxis2()
      
      let g = this.svg
        .append('g')
        .attr('transform', `translate(${this.getPosition('chart2').x}, ${this.getPosition('chart2').y})`)
        .attr('class', 'chart2_group')

        let keys_ = ['P1_PSR', 'P2_PSR', 'P3_PSR']


      let chartWrapper = g
      .append('g')
      .selectAll('g')
      .data(d3.stack().keys(keys_)(this.Queries.SQL7))
      .enter()
      .append('g')
      .attr('fill', (_, i) => (this.Bar.BarColorSet[i]))
      .selectAll('rect')
      .data((d) => d)
      .enter()
      
      chartWrapper
      .append('rect')
      .attr('x', (d, i) => this.scaleXInfo2.scale(this.Queries.SQL7[i].CATEGORY))
      .attr('y', (d) => d[1] >=  0 ? this.scaleYInfo2.scale(d[1]) : this.scaleYInfo2.scale(d[0]))
      .attr('width', this.setThickness * .75)
      .attr('height', (d) => Math.abs(this.scaleYInfo2.scale(d[0]) - this.scaleYInfo2.scale(d[1])))
      // ------------------------------------------------------------------------- Series & Value

      let valueWrapper = g
      .append('g')
      .selectAll('g')
      .attr('id', (_, i) => `value_wrapper_${i}`)
      .data(d3.stack().keys(keys_)(this.Queries.SQL7))
      .enter()
      .append('g')
      .attr('fill', (_, i) => (this.Bar.BarValueAutoColor == 'Y' ? (this.Bar.BarColorSet[i]) : this.Bar.BarValueColor))
      .selectAll('rect')
      .data((d) => d)
      .enter()

      valueWrapper
      .append('text')
      .attr('id', (d, i) =>  `ValueText${i}`)
      .style('font-size', this.Bar.BarValueSize)
      .style('font-family', this.Bar.BarValueFont)
      .attr('text-anchor', 'middle')
      .attr('class', this.Bar.BarValueStyle)
      .attr('x', (d, i) => this.scaleXInfo2.scale(this.Queries.SQL7[i].CATEGORY)+this.setThickness/3+1)
      .attr('y', (d) => {
        let middleOfBoxHeight = ((d[1] - d[0]) / 2) + 2
        let textYPosition
        // Magic number for hanging text at top of Box
        let textAlign4Hang = 2
        if(d[1] >=  0) {
          if(this.Bar.BarValuePosition == 'middle') {
            textYPosition = this.scaleYInfo2.scale(d[1]) + middleOfBoxHeight
          } else if(this.Bar.BarValuePosition == 'bottom') {
            textYPosition = this.scaleYInfo2.scale(d[1]) + this.Bar.BarValueSize
          } else {
            textYPosition = this.scaleYInfo2.scale(d[1])
          }
          return  textYPosition + (this.Bar.BarValuePosition == 'top' ? -this.Bar.BarValueSpace : this.Bar.BarValueSpace -textAlign4Hang)
        }
        else {
          return this.scaleYInfo.scale(d[0])
        } 
      })
      .text((d , i) =>{
        let val = d[1] >= d[0] ? (d[1] - d[0]) : d[1]
        if (val < 3) return
        else return val == 0 ? '' : val
      })

      
      // Total Values
      g
      .append('g')
      .selectAll('text')
      .data(this.Queries.SQL7)
      .enter()
      .append('text')
      .style('font-size', '14px')
      .style('font-family', this.Bar.BarValueFont)
      .attr('text-anchor', 'middle')
      .style('font-weight', 900)
      .style('text-decoration', 'underline')
      .attr('x', (d, i) => this.scaleXInfo.scale(this.Queries.SQL6[i].CATEGORY)+this.setThickness/3+1)
      .attr('y', d => this.scaleYInfo2.scale(d.TOTAL) - 7)
      .text(d => d.TOTAL)
      .style('cursor', 'pointer')
      .on('click', d => {
        let filterString = ` [LOA_EXECUTED_NOT_PO_ISSUE_CATEGORY] = '${d.CATEGORY}' `

        let request_ = {
          dataType: 'row',
          action: {
            type: 'direct',
            target: 'slide-modal',
            component: 'ServiceDatatable',
            id: 336,
            no: ''
          },
          filters: {
          },
          iFilters: {
            filterString : filterString,
            inputFilter  : ''
          }
        }
        this.$emit('request-action', request_)
      })

      
    }
    
  }
}
