
import * as d3 from 'd3'
import Data from '../primitives/donutProps'

export default {
  data:() => ({
    thead: [
      { key: 'ITEM', html: 'Discipline', row: 1 },
      { key: 'WEIGHT', html: 'Weight', row: 1 },
      { key: 'PLAN_CUM', html: 'Plan<br>(Cum.)', row: 1 },
      { key: 'ACTUAL_CUM', html: 'Actual<br>(Cum.)', row: 1 },
      { key: 'CUM_VAR', html: 'Cum.<br>Var.', row: 1 },
      { key: 'PLAN_MTH', html: 'Plan<br>(Monthly)', row: 1 },
      { key: 'ACTUAL_MTH', html: 'Actual<br>(Monthly)', row: 1 },
      { key: 'MONTH_VAR', html: 'Monthly<br>Var.', row: 1 },
    ],
    theadrows: 1,
    tbody: [
      { key: 'ITEM', w: 70, align: 'left'},
      { key: 'WEIGHT', w: 45, align: 'center'},
      { key: 'PLAN_CUM', w: 50, align: 'center'},
      { key: 'ACTUAL_CUM', w: 50, align: 'center',tcolor:'#D50000'},
      { key: 'CUM_VAR', w: 50,  align: 'center'},
      { key: 'PLAN_MTH', w: 50, align: 'center'},
      { key: 'ACTUAL_MTH', w: 50,  align: 'center',tcolor:'#D50000'},
      { key: 'MONTH_VAR', w: 50,  align: 'center'},
    ],
    width: 420,
    filteredList: null
  }),
  methods: {
    drawInformation() {
      this.chartArea
      .append('g')
      .attr('class', 'overall_info')
      .attr('transform', `translate(15.5, 15.5)`).style('font-family', 'roboto')
    },
    loadInformation(sender='in-yard') {
      let infoPannel = d3.select('.overall_info')
      
      infoPannel.selectAll('*').remove()
      infoPannel.transition().delay(150).duration(250).style('opacity', 1)

      if(this.stage == 'OverAll') this.drawOverallInfo()
      else this.drawOtherInfo(sender)
    },
    closeInformation() {
      d3.select('.overall_info')
      .transition().duration(150)
      .style('opacity', 0)
    },
    // ###---------------------------------------- Yard Layout Overall Infomation
    drawOverallInfo() {
      let yardLayout = d3.select('.overall_info')
      let box = yardLayout
      .append('g')
      .attr('class', 'info_content_main_overall')
      
      box
      .append('rect')
      .attr('transform', `translate(0, 0)`)
      .attr('width',220)//BarProg있을때는 495
      .attr('height',132).attr('opacity', 0.95)
      .attr('rx',3)
      .style('fill', '#fff')
      box
      .append('rect')
      .attr('transform', `translate(5, 5)`)
      .attr('width',210)
      .attr('height',20)
      .attr('rx',3)
      .style('fill', '#e0e0df')
      box
      .append('text')
      .attr('id', 'value_code_item')
      .attr('transform', `translate(112, 19)`)
      .style('font-size', 12)
      .style('fill', '#000')
      .attr('text-anchor', 'middle')
      .text('China1 Integrated Overall')
      this.drawOverallSummary()
    },
    drawOverallSummary() {
      let overallSummary = d3.select('.info_content_main_overall')
      .append('g')
      .attr('transform', `translate(0, 80)`)
      
      // Overall Plan
      // let tmp = this.Queries.SQL5[0]

      let tmp =this.DataItems.find(d => d.LV1=='HCCP' & d.LV2 =='OVERALL')
      let style = {
        id: 'CERT',
        x: 40,
        y: 0,
        ty: 4,
        name: Number(tmp.OV_PLAN_PROG).toFixed(1),
        sort: 'N',
      }
      let data = [
        {title: 'Completed',  value: tmp.OV_PLAN_PROG },
        {title: 'Remain',     value: 100-tmp.OV_PLAN_PROG }
      ]
      let Circle = {
        CircleDiameter: 33,
        CircleColorSet: ['SkyBlue', 'LightGray'],
        CircleHoleSize: 22,
        CircleStackSize: 2,
        CircleValueSize: 12,
        CircleSliceSize: 12,
        CircleTitleSize: 12,
        CircleLegendSpace: 60,
      }
      let Guideline = {
        GuideDisplay: 'N',
      }
      overallSummary
      .append('text')
      .attr('transform', `translate(${style.x}, ${style.y - Circle.CircleDiameter - 5})`)
      .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#757575').style('text-anchor', 'middle')
      .text('Plan (%)')

      this.Chart_PieSimple(overallSummary, data, style, Circle, Guideline)

      style = {
        id: 'SHI',
        x: 175,
        y: 0,
        ty: 4,
        name: Number(tmp.OV_ACTUAL_PROG).toFixed(1),
        sort: 'N',
      }
      data = [
        {title: 'Completed',  value: tmp.OV_ACTUAL_PROG },
        {title: 'Remain',     value: 100-tmp.OV_ACTUAL_PROG }
      ]
      Circle.CircleColorSet = ['Red', 'LightGray']
      
      overallSummary
      .append('text')
      .attr('transform', `translate(${style.x}, ${style.y - Circle.CircleDiameter - 5})`)
      .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#757575').style('text-anchor', 'middle')
      .text('Actual (%)')

      this.Chart_PieSimple(overallSummary, data, style, Circle, Guideline)

      // Delta
      let box = overallSummary
      .append('g')
      .attr('id', 'delta')
      .attr('transform', `translate(67, -16)`)

      box
      .append('text')
      .attr('transform', `translate(40, 12)`)
      .style('font-size', 11)
      .attr('fill', '#757575')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text('Delta (%)')

      box
      .append('text')
      .attr('transform', `translate(19, 27)`)
      .style('font-size', 15).attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      // .attr('fill', tmp.OV_DELTA >= 0 ? '#43A047' : '#D50000')
      // .text(`${tmp.OV_DELTA > 0 ? '▲ ' : tmp.OV_DELTA === 0 ? '': '▼ '}` + Number(tmp.OV_DELTA).toFixed(1))
      .attr('fill',tmp.OV_ACTUAL_PROG == 0? '#BDBDBD': tmp.OV_ACTUAL_PROG ==100?'#4C8A76': tmp.OV_DELTA >=-2 ?'#4C8A76': tmp.OV_DELTA >=-5 ?'#F6AD1D' : tmp.OV_DELTA <-5 ?'#E84D24':'#000')
      .text(`${tmp.OV_ACTUAL_PROG ==100? '▼ ' :tmp.OV_ACTUAL_PROG == 0? '○ ':'● '}`)

      box
      .append('text')
      .attr('transform', `translate(60, 27)`)
      .style('font-size', 12).attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
      .attr('fill',tmp.OV_ACTUAL_PROG == 0? '#BDBDBD': tmp.OV_ACTUAL_PROG ==100?'#4C8A76': tmp.OV_DELTA >=-2 ?'#4C8A76': tmp.OV_DELTA >=-5 ?'#F6AD1D' : tmp.OV_DELTA <-5 ?'#E84D24':'#000')
      .text( Number(tmp.OV_DELTA).toFixed(1))

      //------------------------------------------------------------------------------------------------------Week
      // let week = overallSummary
      // .append('g').attr('id', 'week').attr('transform', `translate(26, 40)`)
      
      // week
      // .append('rect')
      // .attr('rx', 4).attr('ry', 4).attr('width', 170).attr('height', 24)
      // .attr('fill-opacity', 0).attr('stroke', '#dadada').attr('stroke-width', .5)
      // week
      // .append('rect')
      // .attr('transform', `translate(126, -5)`)
      // .attr('width', 40).attr('height', 10).attr('fill', '#fff') 
      // week
      // .append('text')
      // .attr('transform', `translate(146, 0)`).style('font-size', 8)
      // .attr('fill', '#959595').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle').text('Weekly')
      // week
      // .append('text')
      // .attr('transform', `translate(26, 13)`)
      // .style('font-size', 11).attr('fill', '#333').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle').text(tmp.PLAN_WK)
      // week
      // .append('text')
      // .attr('transform', `translate(88, 13)`).style('font-size', 11)
      // .attr('fill', tmp.DELTA_WK >= 0 ? '#1e88e5' : '#bf360c')
      // .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle').text(`${tmp.DELTA_WK > 0 ? '▲ ' : tmp.DELTA_WK === 0 ? '': '▼ '}` + tmp.DELTA_WK)
      // week
      // .append('text')
      // .attr('transform', `translate(146, 13)`).attr('alignment-baseline', 'middle')
      // .style('font-size', 11).attr('fill', '#333').attr('text-anchor', 'middle').text(tmp.ACTUAL_WK)

      //------------------------------------------------------------------------------------------------------Bar Progress
      // let progBarGroup = overallSummary
      // .append('g').attr('class','progresBarGroup')
      // .attr('transform', `translate(226, -65)`).style('font-family','roboto')

      // let progBar = progBarGroup
      // .append('g').attr('class','progresBar')
      // .attr('transform', `translate(50, 0)`)

      // //DED, Procurement, Construction 글자
      // this.Status.forEach((d,i)=>{
      //   progBarGroup
      //   .append('text')
      //   .attr('transform', `translate(0, ${37+i*30})`) 
      //   .style('font-size',11).attr('text-anchor', 'start').attr('fill','#424242')
      //   .text(d.text)
      //   progBarGroup
      //   .append('line')
      //   .attr('x1', 0).attr('y1', `${48+i*32}`).attr('x2', 250).attr('y2', `${48+i*32}`).style('stroke', '#E0E0E0').style('stroke-width', 0.5)
      
      //   // if(data[d.refColumn]!= 0) {} //0을 편하게 없애는 방법
      //   progBar
      //   .append('text')
      //   .attr('transform', `translate(165, ${37+i*30})`)
      //   .style('font-size', 11)
      //   .attr('fill', tmp[d.refColumn] > 0 ? '#43A047' : tmp[d.refColumn] < 0 ?'#D50000' :'#E0E0E0')
      //   .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      //   .text(`${tmp[d.refColumn] > 0 ? '▲ ' :tmp[d.refColumn] === 0 ? '' : '▼ '}`+Number(tmp[d.refColumn]).toFixed(1)+'%')
      // })
      
      // //DED, Procurement, Construction 에 해당하는 bar progress
      // const barScale = d3.scaleLinear()
      // .domain([0,100])
      // .range([0,40])
      
      // this.attrs.forEach((attr, i) => {
      //   //PROG Background Bar
      //   progBar
      //   .append('rect')
      //   .attr('x', 70).attr('y', `${18+i*16}`).attr('width', 40).attr('height', 10)
      //   .attr('fill', '#fff').attr('stroke', attr.fColor) .attr('stroke-width', 0.5)
      //   //PROG Actual Bar
      //   progBar
      //   .append('rect')
      //   .attr('x', 70).attr('y', `${18+i*16}`)
      //   .attr('width',0).attr('height',10).attr('fill',attr.tColor)
      //   .transition().duration(100)
      //   .attr('width',Number(barScale(tmp[attr.refColumn])).toFixed(1))

      //   progBar
      //   .append('text')
      //   .attr('transform', `translate(60, ${27+i*16})`) 
      //   .style('font-size',11).attr('text-anchor', 'end').attr('fill',attr.tColor)
      //   .text(attr.text)

      //   progBar
      //   .append('text')
      //   .attr('transform', `translate(120, ${27+i*16})`) 
      //   .style('font-size',11).attr('text-anchor', 'start').attr('fill','#424242')
      //   .text(tmp[attr.refColumn] > 0? Number(tmp[attr.refColumn]).toFixed(1) +'%':'')
      // })
    },

    // ###----------------------------------------- Area Infomation Box 
    drawOtherInfo(sender) {
      let infobox = d3.select('.overall_info')

      // Back Button
      infobox
      .append('image')
      .attr('class', 'Back_Btn')
      .attr('transform', `translate(1500, 0)`)
      .style('cursor', 'pointer')
      .attr('xlink:href', require('../../../../../../src/assets/svg/icons/back_btn3.svg'))
      .on('click', () => { 
        let del = []
        this.selectedItems.forEach((d,index) => {
          del.push(index)
        })
        del.forEach(d => {
          this.selectedItems.pop()
        })

        // this.gotoStage(this.state)
        if (this.stage == 'P2' || this.stage == 'P1' || this.stage == 'P3') {
          this.gotoStage('Mainplant')
        } else if (this.stage == 'PP'|| this.stage == 'GPPE' || this.stage == 'LDPE') {
          this.gotoStage('P2')
        } else if (this.stage == 'OR'|| this.stage == 'DW' || this.stage == 'BOILER'|| this.stage == 'BTX'|| this.stage == 'GHU'|| this.stage == 'BD'|| this.stage == 'DIB'){
          this.gotoStage('P1')
        } else {
          this.gotoStage('OverAll')
        }
      })
      // Information Box
      infobox
      .append('rect')
      .attr('class', '_infobox_box_bg')
      .attr('width', 280)
      .attr('height', this.stage == 'Offplots'? 118 :230)
      .attr('fill', '#fff').attr('rx', 5)
      .style('filter', 'url(#dropshadow)')
      
      infobox
      .append('text')
      .attr('class', 'information_title')
      .attr('transform', `translate(6.5, 14)`)
      .style('font-size', 12)
      .style('font-weight', 'bold')
      .style('fill', '#212121')
      .text(`${this.stage.toUpperCase()} Overall`)

      // infobox
      // .append('text')
      // .attr('transform', `translate(200, 12)`)
      // .style('font-family', 'roboto')
      // .style('font-size', 10)
      // .style('fill', '#44A9DF')
      // .attr('text-anchor', 'start')
      // .attr('alignment-baseline', 'middle')
      // .text('Show All')
      // .style('cursor', 'pointer')
      // .on('mouseover', (_, i, a) => { d3.select(a[i]).style('fill', '#000') })
      // .on('mouseout', (_, i, a) => { d3.select(a[i]).style('fill', '#44A9DF') })
      // .on('click', () => { 
      //   let dStage = {
      //     stage: this.stage,
      //     item: 'ALL'
      //   }
      //   this.selectedItems.push(dStage)
      //   this.cValue = 3         
      //   this.loadInformation()
      // })

      let infoOverall = infobox
      .append('g')
      .attr('class', 'info_content_overall')
      .attr('transform', `translate(6.5, 35.5)`)

      let infoTable = infobox
      .append('g')
      .attr('class', 'info_table')
      .attr('transform', `translate(0.5, 0.5)`)
      
      // if(this.stage == 'P1' || this.stage == 'P2'|| this.stage == 'P3'){
      //   // infoOverall
      //   // .append('text')
      //   // .attr('transform', `translate(0, ${220})`) 
      //   // .style('font-size',14).attr('text-anchor', 'start').attr('fill','#000000')
      //   // .text('* Unit progress is under development.')
      //}
      
      this.drawOverallProgress(sender)
      this.drawTable(infoTable) //----------------------------Discipline table chart
      // this.drawDiscProgress(sender)
    },
    drawTable(selection){
      if(this.stage == 'P1' &&this.Queries.SQL1.filter((d) => { return this.stage == d.PACKAGE})[0].VISIBLE== "true" || this.stage == 'P2' &&this.Queries.SQL1.filter((d) => { return this.stage == d.PACKAGE})[0].VISIBLE== "true" || this.stage == 'P3' &&this.Queries.SQL1.filter((d) => { return this.stage == d.PACKAGE})[0].VISIBLE== "true"){
        selection
        .append('rect').attr('transform', `translate(-5.5, 690.5)`)
        .attr('class', '_infoTable_box_bg')
        .attr('width', 430)
        .attr('height', 350)
        .attr('fill', '#fff').attr('rx', 5)
        .style('filter', 'url(#dropshadow)')

        let data = this.elProps.coordinates.filter(c => c.STAGE == this.stage && c.DIRECT != null).forEach(c => {
          this.state = c.PACKAGE
          let list = this.Queries.SQL2.filter(d => (d.PACKAGE == c.STAGE ))
  
          let table_group = selection.append('g').attr('class', 'table_chart').attr('transform', `translate(0.5, 724.5)`)
          
          table_group
            .append('text')
            .attr('class', 'infotable_title')
            .attr('transform', `translate(0, ${this.Queries.SQL1.filter((d) => { return this.stage == d.PACKAGE})[0].TITLE2 != ""?-19:-10})`)
            .style('font-size', 12)
            .style('font-weight', 'bold')
            .style('fill', '#212121')
            .text(`Construction Progress by Discipline`)
            .text(this.Queries.SQL1.filter((d) => { return this.stage == d.PACKAGE})[0].TITLE1)
        
          if(this.stage == 'P3'){
            table_group
            .append('text')
            .attr('class', 'infotable_title')
            .attr('transform', `translate(0, -6)`)
            .style('font-size', 12)
            .style('font-weight', 'bold')
            .style('fill', '#212121')
            .text(this.Queries.SQL1.filter((d) => { return this.stage == d.PACKAGE})[0].TITLE2)
          }
          
          let table_obj = table_group.append('foreignObject')
          .attr('y',0)
          .attr('width', '440px')
          .attr('height', '370px')
          .append('xhtml:body')
          .style('position', 'fixed')
  
        const reducer = (previousValue, currentValue) => previousValue + currentValue
  
        let widths = this.tbody.map(d => d.w)
        let sumWidth = widths.reduce(reducer)
  
        let table = table_obj.append('table')
        .attr('id', 'commodity_summary_table_')
        .style('border-collapse', 'collapse')
        .style('font-size', '10px')
        .style('width', `${sumWidth}px`)
        .style('table-layout', 'fixed')
  
        let colgroup = table.append('colgroup')
        this.tbody.forEach(k => {
          colgroup.append('col').style('width', k.w + 'px')
        })

        let thead = table.append('thead')
        for (let r = 1; r <= this.theadrows; r++) {
          let row = this.thead.filter(d=> d.row === r)
          let tr = thead.append('tr')
  
          row.forEach(item => {
            tr.append('th')
            .attr('class', 'ogm_table_group__th')
            .attr('rowspan', () => item.rowspan ? item.rowspan : '')
            .attr('colspan', () => item.colspan ? item.colspan : '')
            .style('height', '20px')
            .style('padding', '3px')
            .style('background', item.color ? item.color : '#BDBDBD')
            .html(item.html)
          })        
        }   
        let tbodykeys = this.tbody
        
        let tbody = table.append('tbody').attr('id', 'testpack_summay_tbody_')
        let addClass = ''
          for (let r = 0; r < list.length; r++) {
            let tr = tbody.append('tr')
            tbodykeys.forEach((k, i) => {
              let td = tr.append('td')
                .style('border', 'solid 0.1px #eee')
                .style('height', '18px')
                .style('text-align', k.align ? k.align : 'center')
                .style('color', k.tcolor ? k.tcolor : '#000000')
                .style('padding', '0 3px 0 3px')
    
              if(i === 0) {
                td.style('white-space', 'nowrap')
                  .style('overflow', 'hidden')
                  .style('text-overflow', 'ellipsis')
                  .attr('title', list[r][k.key])
              }
              if(i === 4 ) {
                td.style('color', list[r][k.key] == 0 ? '#000000' : list[r][k.key] < 0 ?'#E53935': list[r][k.key] > 0 ? '#42A5F5':'')
              }
              if(i === 7 ) {
                td.style('color', list[r][k.key] == 0 ? '#000000' : list[r][k.key] < 0 ?'#E53935': list[r][k.key] > 0 ? '#42A5F5':'')
              }
              // if(k.key.includes('CUM')) {
              if(k.key== 'ACTUAL_CUM' || k.key=='PLAN_CUM' ) {
                addClass = ''
                td
                .style('position', 'relative')
                .style('color', k.key.includes('PLAN') ? '#000000' : '#D50000')
                .style('z-index', '1')
                .html(list[r][k.key] == null ? 0+'%' :list[r][k.key]+'%')
    
                td.append('div')
                .style('position', 'absolute')
                .style('left', 0).style('top', 0)
                .style('width', list[r][k.key] > 0 ? list[r][k.key] + '%' : 0)
                .style('height', '17px')
                .style('background-color', k.key.includes('PLAN') ? '#E0E0E0' : '#F8BBD0')
                .style('z-index', '-1')
              }
              else {
                if (k.type == 'float') {
                  let regexp = /\B(?=(\d{3})+(?!\d))/g
                  if (isNaN(Math.floor(list[r][k.key]))) td.html('')
                  else td.html(Math.round(Math.floor(list[r][k.key] * 100) / 100).toString().replace(regexp, ','))
                } else td.html(list[r][k.key])
              }
            })
          }
        })
      }
    },
    // Ovarall Pie Chart Status of activity
    drawOverallProgress(sender) {
      let drawbox = d3.select('.info_content_overall')

      if(sender == 'in-yard') this.levelCode = ''
      else {
        let titletxt = d3.select('.information_title')
        let title = titletxt.text()
        titletxt.text(title + ` (${this.levelCode})`)
      }

      if(this.stage == 'Mainplant') {
        if(sender == 'in-yard') var item__ = this.DataItems.filter(d =>d.LV1 == 'MAINPLANT' & d.LV2 == 'OVERALL' & d.LV3 == 'OVERALL')
        else item__ = this.DataItems.filter(d => d.LV3 == this.levelCode)
      
      } else if(this.stage == 'Offplots') {
        if(sender == 'in-yard') item__ = this.DataItems.filter(d => d.LV1 == 'HCCP' & d.LV2 == 'OP' & d.LV3 == 'OVERALL')
        else item__ = this.DataItems.filter(d => d.LV3 == this.levelCode) 

      }else if(this.stage == 'P2') {
        if(sender == 'in-yard') item__ = this.DataItems.filter(d => d.LV2 == this.stage && d.LV3 == 'OVERALL')
        else item__ = this.DataItems.filter(d => d.LV3 == this.levelCode) 

      } else if(this.stage == 'P3') {
        if(sender == 'in-yard') item__ = this.DataItems.filter(d => d.LV2 == this.stage && d.LV3 == 'OVERALL')
        else item__ = this.DataItems.filter(d => d.LV3 == this.levelCode) 
      
      } else if(this.stage == 'P1') {
        if(sender == 'in-yard') item__ = this.DataItems.filter(d => d.LV2 == this.stage && d.LV3 == 'OVERALL')
        else item__ = this.DataItems.filter(d => d.LV3 == this.levelCode) 
      }
      else {
        if(sender == 'in-yard') item__ = this.DataItems.filter(d => d.LV3 == this.stage)
        else item__ = this.DataItems.filter(d => d.LV3 == this.levelCode) 
      }

      let item = {
        OV_PLAN_PROG: 0,
        OV_ACTUAL_PROG: 0,
        OV_DELTA: 0,
        ENG_PLAN_PROG: 0,
        ENG_ACTUAL_PROG: 0,
        ENG_DELTA:0,
        PRO_PLAN_PROG:0,
        PRO_ACTUAL_PROG:0,
        PRO_DELTA:0,
        CON_PLAN_PROG:0,
        CON_ACTUAL_PROG:0,
        CON_DELTA:0,
      }
      item__.forEach(d => {
        Object.keys(item).forEach(k => {
          if(typeof d[k] == 'undefined') return
          item[k] += d[k]
        })
      })
      // item.PLAN_PROG = item.PLAN_PROG / item__.length
      // item.ACTUAL_PROG = item.ACTUAL_PROG / item__.length
      // item.DELTA = item.DELTA / item__.length
      // item.PLAN_PROG_WK = item.PLAN_PROG_WK / item__.length
      // item.ACTUAL_PROG_WK = item.ACTUAL_PROG_WK / item__.length
      // item.DELTA_WK = item.DELTA_WK / item__.length

      // this.drawDonuts(drawbox, item.OV_PLAN_PROG, item.OV_ACTUAL_PROG, item.OV_DELTA, item.ENG_PLAN_PROG, item.ENG_ACTUAL_PROG, item.ENG_DELTA, item.PRO_PLAN_PROG, item.PRO_ACTUAL_PROG, item.PRO_DELTA, item.CON_PLAN_PROG, item.CON_ACTUAL_PROG, item.CON_DELTA)
      this.drawDonuts(drawbox, item__)
    },
    drawDonuts(selection, item) {
      let arcProps = {
        angleSize: 360,
        angleStart: 0,
        bColor: '#F4F5F5',
        stroke: 8,
        Ra: 30
      }
      let itemData = item[0]
      
      let donutGroup = selection
      .append('g')
      .attr('class', '_donut_group__for_all')
      .attr('transform', `translate(35, 40)`)

      donutGroup
      .append('text')
      .attr('transform','translate(100,-20)')
      .style('font-size', 11)
      .attr('fill', '#757575')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text('Delta (%)')
      donutGroup
      .append('text')
      .attr('transform', `translate(80,3)`)
      .style('font-size', 15).attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      // .attr('fill', itemData.OV_DELTA >= 0 ? '#43A047' : '#D50000')
      // .text(`${itemData.OV_DELTA > 0 ? '▲ ' : itemData.OV_DELTA === 0 ? '': '▼ '}` + Number(itemData.OV_DELTA).toFixed(1))
      .attr('fill',itemData.OV_ACTUAL_PROG == 0? '#BDBDBD': itemData.OV_ACTUAL_PROG ==100?'#4C8A76': itemData.OV_DELTA >=-2 ?'#4C8A76': itemData.OV_DELTA >=-5 ?'#F6AD1D' : itemData.OV_DELTA <-5 ?'#E84D24':'#000')
      .text(`${itemData.OV_ACTUAL_PROG ==100? '▼ ' :itemData.OV_ACTUAL_PROG == 0? '○ ':'● '}`)
      donutGroup
      .append('text')
      .attr('transform', `translate(120,3)`)
      .style('font-size', 11).attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
      .attr('fill',itemData.OV_ACTUAL_PROG == 0? '#BDBDBD': itemData.OV_ACTUAL_PROG ==100?'#4C8A76': itemData.OV_DELTA >=-2 ?'#4C8A76': itemData.OV_DELTA >=-5 ?'#F6AD1D' : itemData.OV_DELTA <-5 ?'#E84D24':'#000')
      .text( Number(itemData.OV_DELTA).toFixed(1))


      Data.attrs.forEach((attr, i) => {
        let arc = d3.arc()
        .outerRadius(d => d.Ra)
        .innerRadius(d => d.Ra - d.stroke)
        .startAngle(d => d.angleStart * Math.PI / 180)
        .endAngle(d => d.angleEnd || d.angleStart * Math.PI / 180)
        let x__ = Data.width * i + Data.gab
        let donut_ = donutGroup
        .append('g')
        .attr('class', `_donut_chart__${attr.refColumn.toLowerCase()}`)
        .attr('transform', `translate(${x__}, 0)`)

        // Draw Background Donut Chart
        donut_
        .append('circle')
        .attr('cx', 0).attr('cy', 0).attr('r', arcProps.Ra-3)
        .attr('stroke', arcProps.bColor).attr('stroke-width', arcProps.stroke)
        .attr('fill', 'url(#lightGray)')

        // Draw Foreground Donut Chart
        donut_
        .datum(arcProps)
        .append('path')
        .attr('d', arc)
        .attr('fill', attr.tColor)
        .transition()
        .duration(100)
        .attrTween('d',(d) => {
          let endAngle_ = ((d.angleSize * (itemData[attr.refColumn] / 100)) + d.angleStart) * Math.PI / 180
          let interpolate = d3.interpolate(d.angleStart * Math.PI / 180, endAngle_)
          return function(t) {
            d.angleEnd = interpolate(t)
            return arc(d);
          }
        })

        donut_
        .append('text')
        .attr('transform', `translate(0, 1)`)
        .style('font-size', 12)
        .style('fill', attr.tColor)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(Number(itemData[attr.refColumn]).toFixed(1) || 0)

        // Legend
        donut_
        .append('text')
        .attr('transform', `translate(0, -40)`)
        .style('font-family', 'roboto')
        .style('font-size', 11)
        .style('fill', '#757575')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(attr.text + ' (%)')
      })
      if(this.stage != 'Offplots'){
        let progBarGroup = selection
        .append('g').attr('class','progresBarGroup')
        .attr('transform', `translate(10, 70)`).style('font-family','roboto')
  
        let progBar = progBarGroup
        .append('g').attr('class','progresBar')
        .attr('transform', `translate(50, 0)`)
  
        //DED, Procurement, Construction 글자
        this.Status.forEach((d,i)=>{
          progBarGroup
          .append('text')
          .attr('transform', `translate(0, ${37+i*30})`) 
          .style('font-size',11).attr('text-anchor', 'start').attr('fill','#424242')
          .text(d.text)
          progBarGroup
          .append('line')
          .attr('x1', 0).attr('y1', `${48+i*32}`).attr('x2', 250).attr('y2', `${48+i*32}`).style('stroke', '#E0E0E0').style('stroke-width', 0.5)
  
          progBar
          .append('text')
          .attr('transform', `translate(156, ${37+i*30})`)
          .style('font-size', 15).attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
          // .attr('fill', itemData[d.refColumn] > 0 ? '#43A047' : itemData[d.refColumn] < 0 ?'#D50000' :'#E0E0E0')
          // .text(`${itemData[d.refColumn] > 0 ? '▲ ' :itemData[d.refColumn] === 0 ? '' : '▼ '}`+Number(itemData[d.refColumn]).toFixed(1)+'%')
          .attr('fill',itemData[d.refColumn]== 0? '#BDBDBD': itemData[d.refColumn] ==100?'#4C8A76': itemData[d.refColumn] >=-2 ?'#4C8A76': itemData[d.refColumn] >=-5 ?'#F6AD1D' : itemData[d.refColumn] <-5 ?'#E84D24':'#000')
          .text(`${itemData[d.refColumn] ==100? '▼ ' :itemData[d.refColumn] === 0 ? '○ ' : '● '}`)
          progBar
          .append('text')
          .attr('transform', `translate(205, ${37+i*30})`)
          .style('font-size', 11).attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
          .attr('fill',itemData[d.refColumn]== 0? '#BDBDBD': itemData[d.refColumn] ==100?'#4C8A76': itemData[d.refColumn] >=-2 ?'#4C8A76': itemData[d.refColumn] >=-5 ?'#F6AD1D' : itemData[d.refColumn] <-5 ?'#E84D24':'#000')
          .text(Number(itemData[d.refColumn]).toFixed(1)+'%')
        
        })
        
        //DED, Procurement, Construction 에 해당하는 bar progress
        const barScale = d3.scaleLinear()
        .domain([0,100])
        .range([0,40])
        
        this.attrs.forEach((attr, i) => {
          //PROG Background Bar
          progBar
          .append('rect')
          .attr('x', 70).attr('y', `${18+i*16}`).attr('width', 40).attr('height', 10)
          .attr('fill', '#fff').attr('stroke', attr.fColor) .attr('stroke-width', 0.5)
          //PROG Actual Bar
          progBar
          .append('rect')
          .attr('x', 70).attr('y', `${18+i*16}`)
          .attr('width',0).attr('height',10).attr('fill',attr.tColor)
          .transition().duration(100)
          .attr('width',Number(barScale(itemData[attr.refColumn])).toFixed(1))
  
          progBar
          .append('text')
          .attr('transform', `translate(60, ${27+i*16})`) 
          .style('font-size',11).attr('text-anchor', 'end').attr('fill',attr.tColor)
          .text(attr.text)
  
          progBar
          .append('text')
          .attr('transform', `translate(120, ${27+i*16})`) 
          .style('font-size',11).attr('text-anchor', 'start').attr('fill','#424242')
          .text(itemData[attr.refColumn] > 0? Number(itemData[attr.refColumn]).toFixed(1) +'%':'')
        })
      }
    },

    // drawDiscProgress(sender) {
    //   let drawbox = d3.select('.info_content_discprog')

    //   if(this.stage == 'Hccp') {
    //     if(sender == 'in-yard') var item__ = this.Queries.SQL2.filter(d => d.LV2 == 'OVERALL')
    //     else item__ = this.Queries.SQL2.filter(d => d.LV3 == this.levelCode)

    //   } else if(this.stage == 'P2') {
    //     if(sender == 'in-yard') item__ = this.Queries.SQL2.filter(d => d.LV2 == this.stage && d.LV3 == 'OVERALL')
    //     else item__ = this.Queries.SQL2.filter(d => d.LV3 == this.levelCode) 

    //   } else if(this.stage == 'P3') {
    //     if(sender == 'in-yard') item__ = this.Queries.SQL2.filter(d => d.LV2 == this.stage && d.LV3 == 'OVERALL')
    //     else item__ = this.Queries.SQL2.filter(d => d.LV3 == this.levelCode) 
      
    //   } else if(this.stage == 'P1') {
    //     if(sender == 'in-yard') item__ = this.Queries.SQL2.filter(d => d.LV2 == this.stage && d.LV3 == 'OVERALL')
    //     else item__ = this.Queries.SQL2.filter(d => d.LV3 == this.levelCode) 
    //   }
    //   else {
    //     if(sender == 'in-yard') item__ = this.Queries.SQL2.filter(d => d.LV3 == this.stage)
    //     else item__ = this.Queries.SQL2.filter(d => d.LV3 == this.levelCode) 
    //   }

    //   // Bar_Quantity_02
    //   let style = {
    //           x: -10,
    //           y: -5,
    //           cell: [70, 88, 130, 180],
    //           bColor: '#B4E3FA', 
    //           sColor:'#44A9DF', 
    //           tColor1: '#fff', 
    //           tColor2: '#757575', 
    //           opacity: 1,
    //           length: 110,
    //           lineHeight: 25,
    //           min: 25
    //   }
    //   // console.log(item__)
    //   this.Bar_Quantity_02_Percent(drawbox, item__, style) // ---------------------------> 
    // },
    // drawImage() {
    //   let drawbox = d3.select('.infoImage')
    // },
  }
}
