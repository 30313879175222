import * as d3 from 'd3'
// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  data:() => ({
    thead: [
      
      { key: 'ITEM', html: 'Item', row: 1 },
      { key: 'UOM', html: 'UOM', row: 1 },
      { key: 'TOTAL', html: 'Total', row: 1 },
      { key: 'PLAN_WK', html: 'Plan Wk.', row: 1 },
      { key: 'ACTUAL_WK', html: 'Act. Wk.', row: 1 },
      { key: 'PLAN_CUM', html: 'Plan Cum.', row: 1 },
      { key: 'ACTUAL_CUM', html: 'Act. Cum.', row: 1 },
      { key: 'DELTA_CUM', html: 'Delta Cum.', row: 1 },
      { key: 'PROG', html: 'Prog(%)', row: 1 },
    ],
    theadrows: 1,
    tbody: [
      { key: 'ITEM', w: 120, align: 'left'},
      { key: 'UOM', w: 70, align: 'center'},
      { key: 'TOTAL', w: 80,type: 'float', align: 'right'},
      { key: 'PLAN_WK', w: 80,type: 'float', align: 'right'},
      { key: 'ACTUAL_WK', w: 80, type: 'float', align: 'right'},
      { key: 'PLAN_CUM', w: 80, type: 'float', align: 'right'},
      { key: 'ACTUAL_CUM', w: 80, type: 'float', align: 'right'},
      { key: 'DELTA_CUM', w: 80, type: 'float', align: 'right'},
      { key: 'PROG', w: 60},
    ],
    width: 530,
    scrollProps: {
      w: 1000,
      h: 401,
      g: 0,
      bar: {
        align : 'right',
        gap   : 1,
        radius: 1,
        width : 6,
        fill  : '#29b6f6',
        stroke: '#03a9f4',
      }
    },
  }),
  methods: {
    Draw_Charts() {
      let svg = this.svg

      this.filteredList = this.DataItems

      let list = this.filteredList
      // let firstColumName = [...new Set(this.Queries.SQL1.map(d => d.ORIGINATING_COMPANY))]
      // console.log(firstColumName)

      let group = svg.append('g').attr('class', 'table_group__').attr('transform', `translate(0.5, 0.5)`)

      
      let table_group = group.append('g').attr('transform', `translate(0.5, 30.5)`)
      // if(this.DataItems.length * 20==400){
      //   // Set Scroll
      //   this.setScroll(table_group, this.scrollProps)
      // //let y_ = this.DataItems.length * 20 + 24
      // }
      let y_ = this.DataItems.length * 21 + 40

      let table_obj = table_group.append('foreignObject')
        .attr('y',0)
        .attr('width', '100%')
        .attr('height', `${y_}px`) 
        //.attr('height', '424px')
        .append('xhtml:body')
        .style('position', 'fixed')

      const reducer = (previousValue, currentValue) => previousValue + currentValue
      let widths = this.tbody.map(d => d.w)
      let sumWidth = widths.reduce(reducer)

      let table = table_obj.append('table')
      .style('border-collapse', 'collapse')
      .style('font-size', '10px')
      .style('width', `${sumWidth}px`)
      .style('table-layout', 'fixed')

      let colgroup = table.append('colgroup')
      this.tbody.forEach(k => {
        colgroup.append('col').style('width', k.w + 'px')
      })

      // Set Scroll
      if(this.DataItems.length * 20==400){
        // Scroll Width
        this.scrollProps.w = sumWidth
        // Set Scroll
        this.setScroll(table_group, this.scrollProps)
      }

      let thead = table.append('thead')
      let tr = thead.append('tr')

      this.thead.forEach(th => {
        tr.append('th')
        .attr('class', 'table_group__th')
        .style('height', '22px')
        .style('padding', '3px')
        .style('font-size', '10px')
        .style('background', th.color ? th.color : '#E0E0E0')
        .html(th.html)

      })

      let tbodykeys = this.tbody
      let actionColumns = ['SUBSYSTEM']
      let tbody = table.append('tbody').attr('id', 'summay_tbody_')

      if (list.length == 0) {
        
        let tr = tbody.append('tr')

        tr.append('td')
          .attr('colspan', 9)
          .style('background', '#F5F5F5')
          .style('height', '24px')
          .style('text-align', 'center')
          .style('color', '#424242')
          .style('padding', '0 3px 0 3px')
          .style('font-style', 'italic')
          .html('No data')
        
        return
      }

      for (let r = 0; r < list.length; r++) {
        let tr = tbody.append('tr')
        // tr.on('mouseover', () => tr.style('background-color', '#FAFAFA' ))
        // tr.on('mouseout', () => tr.style('background-color', 'Transparent' ))
        tbodykeys.forEach((k, i) => {
          let td = tr.append('td')
            .style('border', 'solid 0.1px #eee')
            .style('height', '20px')
            .style('text-align', k.align ? k.align : 'center')
            .style('color', '#000000')
            .style('padding', '0 3px 0 3px')
            .style('background', k.background ? k.background : 'none')
            
            if(i === 0) {
              td.style('white-space', 'nowrap')
                .style('overflow', 'hidden')
                .style('text-overflow', 'ellipsis')
                .attr('title', list[r][k.key])
                td.style('background-color', '#FFF8E1')
            }
            if(i === 2) {
              td.style('white-space', 'nowrap')
                .style('overflow', 'hidden')
                .style('text-overflow', 'ellipsis')
                .attr('title', list[r][k.key])
            }
            if(i === 8) {
  
              td.style('color', list[r][k.key] =='Completed'? '#43A047':list[r][k.key] =='On/Ahead Plan'?'#212121':list[r][k.key] =='Delay not Pass Plan'?'#FF8F00':'#E53935')
            }
            if(actionColumns.includes(k.key)) {
              td.style('text-decoration', 'underline').style('color','#039BE5')
              td.on('mouseover', () => td.style('cursor', 'pointer').style('opacity',0.5))
              td.on('mouseout', () => td.style('cursor', 'pointer').style('opacity',1))
              td.on('click', () => {
                // console.log(k.key, list[r])
                let request_ = this.getRequestColumnProps('SUBSYSTEM', { SUBSYSTEM: list[r].SUBSYSTEM }, this.ColumnProps)
                
                this.$emit('request-action', request_)
              })
            }
            if(k.key.includes('PROG')) {
              if (k.key.includes('ACTUAL_PROG')) { 
                let diff = list[r]['PLAN_PROG'] - list[r]['ACTUAL_PROG']
                // addClass = diff >= 30 ? 'caution' : ''
              }
              td
              .style('position', 'relative')
              .style('color', list[r][k.key] < 0 ? 'red' : list[r][k.key] === 100 ? '#fff' : '#000')
              .style('z-index', '1')
              .html(list[r][k.key] == null ? 0+'%' :list[r][k.key]+'%')
  
              td.append('div')
              .style('position', 'absolute')
              .style('left', 0)
              .style('top', 0)
              .style('width', list[r][k.key] > 0 ? list[r][k.key] + '%' : 0)
              .style('height', '20px')
              .style('background-color', list[r][k.key] < 100 ? k.key.includes('PLAN') ? '#E0E0E0' : '#FFCA28' : '#4CAF50')
              .style('z-index', '-1')
            }
          
            else {
              if (k.type == 'float') {
                let regexp = /\B(?=(\d{3})+(?!\d))/g
                if (isNaN(Math.floor(list[r][k.key]))) td.html('')
                else td.html((Math.floor(list[r][k.key] * 100) / 100).toString().replace(regexp, ','))
              } else td.html(list[r][k.key])
            }

        })
      }
    },
  }
}
