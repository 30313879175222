import * as d3 from 'd3'

export default {
  methods: {

    draw_Diagram() {

      this.rawData.forEach((d,i) => {

        let item = d3.select(`#SYS-${d.system}`)
        .attr('cursor', 'pointer')
        .attr('opacity', 1)

        let box = item.select('polygon').node().getBBox()
        d.cx = (box.x + (box.width/2)) - ((box.x + (box.width/2)) * 1.6)
        d.cy = (box.y + (box.height/2)) - ((box.y + (box.height/2)) * 1.6)

        item.call(this.call_StartUpItem, d)

   
        
      
      })
    },

  }
}
           