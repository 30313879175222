// ------------------------------------------------------------------------------- Global
// Global Data & Defs

import values from "core-js/fn/array/values"
import __M from 'moment'


export default {
  data:() => ({
  }),
  methods: {
    Draw_Charts() {
      if (this.Queries.SQL1.length == 0) return

      let tableGroup = this.svg
      .append('g')
      .attr('class', '_table_group__for_all')
      .attr('transform', `translate(20.5, 0.5)`)

      tableGroup
      .append('text')
      .attr('transform', `translate(${6}, ${26})`)
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#000').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(`Construction %`)

      tableGroup
      .append('line').attr('x1', -10).attr('x2', 823).attr('y1', 38).attr('y2', 38)
      .attr('stroke', '#212121').attr('stroke-width', 0.2)

      let phase = [...new Set(this.DataItems.map(d => d.PHASE))]
      let y_ = (this.DataItems.length - phase.length) / 4 * 20 + 38 //세로축 값에따라 자동으로 늘어남

      tableGroup
      .append('line').attr('x1', 56).attr('x2', 56).attr('y1', 38).attr('y2', y_)
      .attr('stroke', '#9E9E9E').attr('stroke-width', 0.2)
      tableGroup
      .append('line').attr('x1', 102).attr('x2', 102).attr('y1', 38).attr('y2', y_)
      .attr('stroke', '#9E9E9E').attr('stroke-width', 0.2)

      let chartY = 0
      let tableColumn = tableGroup
      .append('g')
      .attr('class', '_table_chart__')
      .attr('transform', `translate(140.5, 0.5)`)
      
      phase.forEach((d, i) =>{
        // console.log(d)
        let data = this.DataItems.filter(f => f.PHASE == d)
        // console.log(data)

        let tableChart = tableGroup
        .append('g')
        .attr('class', `_table_chart_${d}_`)
        .attr('transform', `translate(20.5, ${chartY+10})`)

        let cutoff = this.Queries.SQL1[0].CUTOFF

        let thisWeek = data.find(f => f.CUTOFF == cutoff) //현재의 cutoff를 가져오고
        let thisWeekDiffFourDate = __M(new Date(thisWeek.CUTOFF)).add(-4, 'weeks').format('YYYY-MM-DD') //-4주 cutoff를 구하기
        let thisWeekDiffFour = data.find(f => f.CUTOFF == thisWeekDiffFourDate) // -4주 cutoff의 데이터 구하기
        let actualThisAvg = ((thisWeek.ACTUAL_PROG - thisWeekDiffFour.ACTUAL_PROG) / 4).toFixed(1) // 현재 cutoff - 4주전 cutoff /4
        // console.log(thisWeek.ACTUAL_PROG)

        let planThisAvg = ((thisWeek.PLAN_PROG - thisWeekDiffFour.PLAN_PROG) / 4).toFixed(1)

        let preWeekDate = __M(new Date(thisWeek.CUTOFF)).add(-1, 'weeks').format('YYYY-MM-DD')//cutoff의 -1주 구하기
        let preWeek = data.find(f => f.CUTOFF == preWeekDate)
        let preWeekDiffFourDate = __M(new Date(preWeek.CUTOFF)).add(-4, 'weeks').format('YYYY-MM-DD')
        let preWeekDiffFour = data.find(f => f.CUTOFF == preWeekDiffFourDate)
        let actualPreAvg = (actualThisAvg - ((preWeek.ACTUAL_PROG - preWeekDiffFour.ACTUAL_PROG) / 4)).toFixed(1)
        // console.log(actualPreAvg)
        let planPreAvg = (planThisAvg - ((preWeek.PLAN_PROG - preWeekDiffFour.PLAN_PROG) / 4)).toFixed(1)

        data.push({ACTUAL_THIS_AVG: actualThisAvg, ACTUAL_PRE_AVG: actualPreAvg, PLAN_THIS_AVG: planThisAvg, PLAN_PRE_AVG: planPreAvg})
        // data 넣기

        data.shift() // data 맨앞에것 빼기

        // console.log(data)

        data.forEach((f, fi) =>{

          if(i == 0){
            tableColumn
            .append('text')
            .attr('transform', `translate(${80*fi}, ${26})`).style('font-weight',`${f.CUTOFF == cutoff ?'500' : 'normal'}`)
            .style('font-family', 'roboto').style('font-size', 11).style('fill', `${f.CUTOFF == cutoff ?'#03A9F4' : '#000'}`).attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
            .text(`${f.CUTOFF ? f.CUTOFF : '4 Wks Avg'}`) //cutoff가 없으면 text로 넣기
          }
          //Actual
          tableColumn
          .append('text')
          .attr('transform', `translate(${80*fi}, ${49+chartY})`)
          .style('font-family', 'roboto').style('font-size', 11).style('fill', `${f.CUTOFF == cutoff ?'#000' : '#757575'}`).attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
          .text(`${!f.ACTUAL_PROG ? '': f.ACTUAL_PROG+'%'}`)
          //Plan
          tableColumn
          .append('text')
          .attr('transform', `translate(${80*fi}, ${69+chartY})`)
          .style('font-family', 'roboto').style('font-size', 11).style('fill', `${f.CUTOFF == cutoff ?'#000' : '#757575'}`).attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
          .text(`${!f.PLAN_PROG ? '': f.PLAN_PROG+'%'}`)
          //4wks avg Actual
          tableColumn
          .append('text')
          .attr('transform', `translate(${80*fi-14}, ${49+chartY})`)
          .style('font-family', 'roboto').style('font-size', 11).style('fill', '#757575').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
          .text(`${thisWeek.ACTUAL_PROG ? (!f.ACTUAL_THIS_AVG ? '': f.ACTUAL_THIS_AVG+'%') : ''}`)
          //4wks avg Actual()
          tableColumn
          .append('text')
          .attr('transform', `translate(${80*fi+1}, ${49+chartY})`)
          .style('font-family', 'roboto').style('font-size', 11).style('fill', f.ACTUAL_PRE_AVG > 0 ?'#1e88e5' : f.ACTUAL_PRE_AVG < 0 ? '#EC407A' : '#757575').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
          .text(`${thisWeek.ACTUAL_PROG ? (!f.ACTUAL_PRE_AVG ? '': '('+f.ACTUAL_PRE_AVG+'%'+')') : ''}`)
          //4wks avg Plan()
          tableColumn
          .append('text')
          .attr('transform', `translate(${80*fi-14}, ${69+chartY})`)
          .style('font-family', 'roboto').style('font-size', 11).style('fill', '#757575').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
          .text(`${thisWeek.PLAN_PROG ? (!f.PLAN_THIS_AVG ? '': f.PLAN_THIS_AVG+'%') : ''}`)
          //4wks avg Plan()
          tableColumn
          .append('text')
          .attr('transform', `translate(${80*fi+1}, ${69+chartY})`)
          .style('font-family', 'roboto').style('font-size', 11).style('fill', f.PLAN_PRE_AVG > 0 ?'#1e88e5' : f.PLAN_PRE_AVG < 0 ? '#EC407A' : '#757575').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
          .text(`${thisWeek.PLAN_PROG ? (!f.PLAN_PRE_AVG ? '': '('+f.PLAN_PRE_AVG+'%'+')' ) : ''}`)
        })

        tableChart
        .append('text')
        .attr('transform', `translate(${0}, ${50})`)
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#000').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text(`${d}`)//phase
        
        tableChart
        .append('line').attr('x1', -30).attr('x2', 803).attr('y1', 68).attr('y2', 68)
        .attr('stroke', '#9E9E9E').attr('stroke-width', 0.2) //horizontal(가로)line 

        tableChart
        .append('text')
        .attr('transform', `translate(${60}, ${40})`)
        .style('font-family', 'roboto').style('font-size', 10.5).style('fill', '#29B6F6').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text(`Actual`)//Actual
        tableChart
        .append('text')
        .attr('transform', `translate(${60}, ${60})`)
        .style('font-family', 'roboto').style('font-size', 10.5).style('fill', '#9E9E9E').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text(`Plan`)//Plan

        chartY += 40
      })
    },
  }
}
