import * as d3 from 'd3'

export default {
  methods: {
    draw_Charts() {

      d3.select(`#${this.localId}`).select('.skyline_boxes').remove()

      let self = this
      let BoxesChart = this.SkylineCharts
      .append('g')
      .attr('class', 'skyline_boxes')
      .attr('transform', `translate(${this.timeline.baseX},0)`)
    
      d3.selection.prototype.moveToFront = function() {
        return this.each(function(){
        this.parentNode.appendChild(this);
        });
      }

      // let priorityItem = this.style.find(s => s.group == 'Priority').item

      this.timeline.week.forEach((w, i) => {
        let drawY = this.timeline.baseY - this.skyline.box.height - (this.skyline.box.gap*2)

        let drawLengthX = 0

        this.dataSet.filter(f => f.cDate == w.eDate).forEach(d => {
          let x1 = this.timeline.scale(d.sDate) + this.skyline.box.gap
          let y1 = drawY

          drawLengthX = x1

          let Boxes = BoxesChart
          .append('g')
          .attr('id', () => {
            return `SYS_${d.TAG_NO.replace('&', '')}`
          })
          .attr('transform', `translate(${x1},${y1})`)
          .style('cursor', 'pointer')
          .on('mouseover', function() {
            d3.select(this).moveToFront().transition().duration(150)
            .attr('transform', `translate(${x1-(self.skyline.box.width/4)},${y1-(self.skyline.box.height/4)}) scale(1.5)`)
          })
          .on('mouseout', function(){
            d3.select(this).transition().duration(150)
            .attr('transform', `translate(${x1},${y1}) scale(1)`)
          })
          .on('click', () => {

            let request_ = this.getRequestColumnProps('hleqpopup', { TAG_NO: d.TAG_NO }, this.ColumnProps)
            this.$emit('request-action', request_)
          })

          if (this.skyline.box.width) {
            
            Boxes
            .append('rect') // the background color should be white(#fff)
            .attr('rx', d.radius)
            .attr('ry', d.radius)
            .attr('width', this.skyline.box.width)
            .attr('height', this.skyline.box.height)
            .attr('fill', '#fff')
            .attr('stroke', 'Transparent')
            .attr('stroke-width', d.sWidth)

            Boxes
            .append('rect') // Fill Color
            .attr('rx', d.radius)
            .attr('ry', d.radius)
            .attr('width', () => {
              if (d.DRESSUP_PROG && d.DRESSUP_PROG > 0 && d.STATUS == 'Dressup') {
                return this.skyline.box.width * (d.DRESSUP_PROG/100)
              }else return this.skyline.box.width
            })
            .attr('height', this.skyline.box.height)
            .attr('fill', () => {
              return d.bColor
            })
            .attr('fill-opacity', d.opacity)
            .attr('stroke', 'Transparent')
            .attr('stroke-width', d.sWidth)
            
            Boxes
            .append('rect') // Stroke
            .attr('rx', d.radius)
            .attr('ry', d.radius)
            .attr('width', this.skyline.box.width)
            .attr('height', this.skyline.box.height)
            .attr('fill', 'Transparent')
            .attr('stroke', d.sColor)
            .attr('stroke-width', d.sWidth)
          }
          

          Boxes
          .append('text')
          .attr('x', this.timeline.weekSize/2 - 1)
          .attr('y', this.skyline.box.height/2 + 1)
          .attr('font-family', this.skyline.box.font)
          .attr('font-size', d.tSize)
          .attr('font-weight', 'normal')
          .attr('fill', '#000')
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          .style('pointer-events', 'none')
          .text(d.TAG_NO)

          drawY = drawY - this.skyline.box.height - (this.skyline.box.gap*2)
        })

        let length = this.dataSet.filter(f => f.cDate == w.eDate).length
        if (length == 0) return
        BoxesChart
          .append('text')
          .attr('x', function () {
            return drawLengthX + self.skyline.box.width/2
          })
          .attr('y', drawY + 10)
          .attr('font-family', this.skyline.box.font)
          .attr('font-size', 11)
          .attr('fill', '#64B5F6')
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          .style('pointer-events', 'none')
          .text(length)
      })
    }, 
    Chart_filtering() {
      this.dataSet.forEach(d => { 
        if(this.visible(d)) d3.select(`#${this.localId}`).selectAll(`#SYS_${d.TAG_NO.replace('&', '')}`).style('pointer-events', 'auto').transition().duration(500).style('opacity', 1)
        else d3.select(`#${this.localId}`).selectAll(`#SYS_${d.TAG_NO.replace('&', '')}`).style('pointer-events', 'none').transition().duration(500).style('opacity', .1)
      })
    }
  }
}
