import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({

    props:[
      {key:'DISC_DESCR', head1: 'Disc.', x: 30, tx: 20, textanchor: 'start'},
      {key:'ITEM', head1: 'Item', x: 106, tx: 95, textanchor: 'start'},
      {key:'UNIT', head1: 'Unit', x: 202, tx: 202, textanchor: 'middle'},
      {key:'TOTAL', head1: 'Initial Qty', x: 253, tx: 271},
      {key:'PLAN', head1: 'C2 Qty', x: 317, tx: 337},
      {key:'ACTUAL', head1: 'Actual', x: 385,  tx: 400, color: '#1976D2'},
      {key:'DELTA', head1: 'Delta', x: 450, tx: 465, color: 'green'},
    ],
    width: 470,
    rowHeight: 18,
    disc: null
  }),

  methods: {
    drawChart() {
      let PreOutfitting = this.svg
      .append('g')
      .attr('transform', `translate(0, 30.5)`)

      // PreOutfitting
      // .append('rect')
      // .attr('x',20).attr('y', 5).attr('width',this.width - 20).attr('height',30).attr('stroke-width', 0).attr('fill', '#F4F5F5').attr('opacity', 0.4)
      PreOutfitting.append('line').attr('x1',10).attr('y1',5).attr('x2',this.width).attr('y2',5).attr('stroke-width', 0.2).attr('stroke', '#000')
      PreOutfitting.append('line').attr('x1',10).attr('y1',30).attr('x2',this.width).attr('y2',30).attr('stroke-width', 0.2).attr('stroke', '#000')

      // header
      PreOutfitting
        .append('g')
        .attr('transform', `translate(0, 20)`)
        .selectAll('text')
        .data(this.props)
        .enter()
        .append('text')
        .attr('x', d => d.x)
        .attr('y', 2)
        .style('font-family','roboto')
        .style('font-size',10)
        .attr('text-anchor', 'middle')
        .attr('fill', '#333')
        .text(d => d.head1)

      let row_group = PreOutfitting
        .append('g')
        .attr('class', '_row_data_group')
        .attr('transform', `translate(0, 43)`)

      let discs = [...new Set(this.dataSet.map(d => d.DISC_DESCR))]
      let sumY = 0
      discs.forEach((disc, r) => {
        const beforeDisc = r > 0 ? discs[r-1] : null
        const filtered = this.dataSet.filter(d => d.DISC_DESCR === beforeDisc)

        sumY += r !== 0 ? this.rowHeight * filtered.length : 0

        row_group.append('text')
        .text(disc)
        .attr('x', (d,i) => this.props[i].tx)
        .attr('y', () => sumY)
        .style('font-family','roboto')
        .style('font-size',10)
        .attr('fill', '#757575')

        const group = this.dataSet.filter(d => disc.includes(d.DISC_DESCR))

        group.forEach((row, i) => {
          let keys = Object.keys(row)
          keys.shift()

          if (row.TOTAL === row.ACTUAL) {
            row_group
              .append('rect')
              .attr('class', '_rect_actual')
              .attr('x', 90).attr('y', sumY + (i * this.rowHeight) - 12).attr('width',330).attr('height',18)
              .attr('stroke-width', 0).attr('fill', '#B3E5FC').attr('opacity', 0.4)
          }

            keys.forEach(key => {
              let idx = this.props.findIndex(e => e.key === key)
              row_group
                .append('text')
                .text(row[key] != null ? row[key].toLocaleString() : '')
                .attr('x', (d,i) => this.props[idx].tx)
                .attr('y', () => sumY + (i * this.rowHeight))
                .attr('text-anchor', (d, i) =>  this.props[idx].textanchor ? this.props[idx].textanchor : 'end' )
                .attr('fill', (d, i) => {
                  if (key !== 'DELTA') {
                    return this.props[idx].color && row[key] !== 0 ? this.props[idx].color : '#757575'
                  }else {
                    return row[key] > 0 ? 'green' : row[key] < 0 ? 'red' : '#757575'
                  }
                })
                .style('font-family','roboto')
                .style('font-size',10)
            })
        })
      })

        // table body line
        let sumLineY1 = 0
        let sumLineY2 = 0
        PreOutfitting
          .append('g')
          .attr('transform', `translate(10, 30)`)
          .selectAll('line')
          .data(discs)
          .enter()
          .append('line')
          .attr('class', '_body_line')
          .attr('x1', 0)
          .attr('y1', (d,i) => {
            const filter = this.dataSet.filter(s => s.DISC_DESCR === discs[i])
            sumLineY1 += (18 * filter.length)
            return sumLineY1
          })
          .attr('x2', this.width -10)
          .attr('y2', (d,i) => {
            const filter = this.dataSet.filter(s => s.DISC_DESCR === discs[i])
            sumLineY2 += (18 * filter.length)
            return sumLineY2
          })
          .attr('stroke-width', 0.3)
          .attr('stroke', '#ccc')
    }

  }
}
