import Defs from '../../../../../../includes/primitives/Color_Defs'
import Data from '../../../../../../includes/primitives/Color_Data'

import Data_Hull_After from   '../primitives/BlockData/Data_Hull_After'
import Data_Hull_Forward from '../primitives/BlockData/Data_Hull_Forward'
import Data_Hull_LQ from      '../primitives/BlockData/Data_Hull_LQ'
import Data_Topsides from     '../primitives/BlockData/Data_Topsides'

import * as d3 from 'd3'

export default {
  data: () => ({
    dataSet: [],

    Status: [
      // { code: null,           title: 'Fab. not Started',    bColor: '#44A9DF', tColor: '#bcbcbc', opacity: 0.25, code_bColor: '#fff',    code_tColor: '#333',},
      { code: 'FABSTART',     title: 'Fab. Commenced',       bColor: '#44A9DF', tColor: '#44A9DF', opacity: 0.20, code_bColor: '#84C7E5', code_tColor: '#fff',},
      { code: 'FABRICATION',  title: 'Fab. Completed',           bColor: '#44A9DF', tColor: '#FFFFFF', opacity: 0.40, code_bColor: '#2281C5', code_tColor: '#fff',},
      { code: 'PAINTING',     title: 'Painting Completed',       bColor: '#44A9DF', tColor: '#44A9DF', opacity: 0.60, code_bColor: '#F6D15E', code_tColor: '#333',},
      { code: 'ASSEMBLY',     title: 'Assembly Completed', bColor: '#44A9DF', tColor: '#333',    opacity: 0.80, code_bColor: '#CC93C1', code_tColor: '#333',},
      { code: 'ERECTION',     title: 'Erec. Commenced',  bColor: '#44A9DF', tColor: '#333',    opacity: 1.00, code_bColor: '#8DBA2D', code_tColor: '#fff',},
    ],

    Border: [
      {
        type: 'MEGA',
        shape: 'ellipse',
        width: 35,
        height: 15,
        color: '#9E9E9E',
        stroke: .2,
        textSize: 10,
        padding: 3,
      },
      {
        type: 'UNIT',
        shape: 'rect',
        width: 30,
        height: 14,
        color: '#9E9E9E',
        stroke: .2,
        textSize: 10,
        padding: 2.5,
      },
    ],

    Hull_AFT: Data_Hull_After,
    Hull_FOR: Data_Hull_Forward,
    Hull_LQ: Data_Hull_LQ,
    TOPSIDE: Data_Topsides,

    BlockStatus_Coordinate1: [
      {SCRNAME: 'Overall',          Lv1: 'PROJECT'  , Lv2: 'OVERALL',   Lv3: null,      NAME: 'Tortue FPSO', x: 1130,  y: 508,},

      {SCRNAME: 'Hull & Top',       Lv1: 'HULL'   ,  Lv2: 'OVERALL',    Lv3: null,      NAME: 'Hull & LQ',  x: 300, y: 978,},
      {SCRNAME: 'Hull & Top',       Lv1: 'TOPSIDE',  Lv2: 'OVERALL',    Lv3: null,      NAME: 'Topside',    x: 1130, y: 508,},
      {SCRNAME: "Hull & LQ",        Lv1:"HULL",     Lv2:"HULL-AFT",    Lv3:'OVERALL',   NAME:"AFT",         x: 355, y: 828,},
      {SCRNAME: "Hull & LQ",        Lv1:"HULL",     Lv2:"HULL-FOR",    Lv3:'OVERALL',   NAME:"Forward",     x: 900, y: 923,},
      {SCRNAME: "Hull & LQ",        Lv1:"HULL",     Lv2:"LQ",          Lv3:'OVERALL',   NAME:"LQ",          x: 860, y: 408,},
 
      {SCRNAME: "HULL-AFT-SINGLE",  Lv1:"HULL",     Lv2:"HULL-AFT",   Lv3:"OVERALL",  NAME:"HULL-AFT", },
      {SCRNAME: "HULL-FOR-SINGLE",  Lv1:"HULL",     Lv2:"HULL-FOR",   Lv3:"OVERALL",  NAME:"HULL-FOR", },
      {SCRNAME: "Hull LQ-SINGLE",   Lv1:"HULL",     Lv2:"LQ",         Lv3:"OVERALL",  NAME:"LQ",       },
      
      {SCRNAME: "HULL-AFT",         Lv1:"HULL",     Lv2:"HULL-AFT",   Lv3:"MD00a",    NAME:"MD00a",       x: 987, y: 237,},
      {SCRNAME: "HULL-AFT",         Lv1:"HULL",     Lv2:"HULL-AFT",   Lv3:"RB01-02",  NAME:"RB01-02",     x: 1060, y: 858,},
      {SCRNAME: "HULL-AFT",         Lv1:"HULL",     Lv2:"HULL-AFT",   Lv3:"HL02",     NAME:"HL02",        x: 550, y: 388,},
 
      {SCRNAME: "HULL-FOR",         Lv1:"HULL",     Lv2:"HULL-FOR",   Lv3:"MD00f",    NAME:"MD00f",       x: 508, y: 370,},
      {SCRNAME: "HULL-FOR",         Lv1:"HULL",     Lv2:"HULL-FOR",   Lv3:"VS04-5",   NAME:"VS04-5",      x: 1022, y: 419,},
      {SCRNAME: "HULL-FOR",         Lv1:"HULL",     Lv2:"HULL-FOR",   Lv3:"HL03",     NAME:"HL03",        x: 1064, y: 825,},
 
      {SCRNAME: "LQ",      Lv1:"HULL",     Lv2:"LQ",        Lv3:"LQ00",      NAME:"LQ00",             x: 976, y: 665,},
      {SCRNAME: "LQ",      Lv1:"HULL",     Lv2:"LQ",        Lv3:"HD01",      NAME:"HD01",             x: 976, y: 400,},

      {SCRNAME: 'Module & Common',  Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'OVERALL', NAME: 'Modules',  x: 900, y: 468,},
      {SCRNAME: 'Module & Common',  Lv1: 'TOPSIDE', Lv2: 'COMMON' , Lv3: 'OVERALL', NAME: 'Common',   x: 515, y: 838,},
    
      {SCRNAME: 'MODULES', Lv1: 'TOPSIDE', Lv2: 'MODULES' , Lv3: 'ME01',    NAME: 'ME01',   x: 276, y: 460, },
      {SCRNAME: 'MODULES', Lv1: 'TOPSIDE', Lv2: 'MODULES' , Lv3: 'ME02',    NAME: 'ME02',   x: 505, y: 793, },
      {SCRNAME: 'MODULES', Lv1: 'TOPSIDE', Lv2: 'MODULES' , Lv3: 'ME04',    NAME: 'ME04',   x: 817, y: 855, },
      {SCRNAME: 'MODULES', Lv1: 'TOPSIDE', Lv2: 'MODULES' , Lv3: 'ME05',    NAME: 'ME05',   x: 1122, y: 838,},
      {SCRNAME: 'MODULES', Lv1: 'TOPSIDE', Lv2: 'MODULES' , Lv3: 'MW01',    NAME: 'MW01',   x: 528, y: 248, },
      {SCRNAME: 'MODULES', Lv1: 'TOPSIDE', Lv2: 'MODULES' , Lv3: 'MW02',    NAME: 'MW02',   x: 852, y: 293, },
      {SCRNAME: 'MODULES', Lv1: 'TOPSIDE', Lv2: 'MODULES' , Lv3: 'MW04',    NAME: 'MW04',   x: 1132, y: 333,},
      {SCRNAME: 'MODULES', Lv1: 'TOPSIDE', Lv2: 'MODULES' , Lv3: 'MW05',    NAME: 'MW05',   x: 1226, y: 653,},
    
      {SCRNAME: 'COMMON', Lv1: 'TOPSIDE',   Lv2: 'COMMON' , Lv3: 'FL01'  ,  NAME: 'FL01'  , x: 511, y: 298, },
      {SCRNAME: 'COMMON', Lv1: 'TOPSIDE',   Lv2: 'COMMON' , Lv3: 'KO01'  ,  NAME: 'KO01'  , x: 146, y: 788, },
      {SCRNAME: 'COMMON', Lv1: 'TOPSIDE',   Lv2: 'COMMON' , Lv3: 'LD00DA',  NAME: 'LD00DA', x: 1260, y: 487,},
      {SCRNAME: 'COMMON', Lv1: 'TOPSIDE',   Lv2: 'COMMON' , Lv3: 'RC01',  NAME: 'RC01', x: 464, y: 738, },
      {SCRNAME: 'COMMON', Lv1: 'TOPSIDE',   Lv2: 'COMMON' , Lv3: 'RC02',  NAME: 'RC02', x: 732, y: 773, },
      {SCRNAME: 'COMMON', Lv1: 'TOPSIDE',   Lv2: 'COMMON' , Lv3: 'RC03',  NAME: 'RC03', x: 960, y: 568, },
      {SCRNAME: 'COMMON', Lv1: 'TOPSIDE',   Lv2: 'COMMON' , Lv3: 'RC04',  NAME: 'RC04', x: 1019, y: 877,},
      {SCRNAME: 'COMMON', Lv1: 'TOPSIDE',   Lv2: 'COMMON' , Lv3: 'RC05',  NAME: 'RC05', x: 1275, y: 872,},
    ],
    BlockStatus_Coordinate: [
      { Lv1: 'HULL', Lv2: 'OVERALL', Lv3: null, Lv4: null, NAME: 'HULL & LQ', SUBNAME: null, x: 300, y: 850,},
      { Lv1: 'HULL', Lv2: 'HULL-AFT', Lv3: 'OVERALL', Lv4: null, NAME: 'AFT', SUBNAME: null, x: 355, y: 700,},
      { Lv1: 'HULL', Lv2: 'HULL-AFT', Lv3: 'HL02', Lv4: null, NAME: 'HL02', SUBNAME: null, x: 550, y: 260, },
      { Lv1: 'HULL', Lv2: 'HULL-AFT', Lv3: 'MD00a', Lv4: null, NAME: 'MD00a', SUBNAME: null, x: 987, y: 108, },
      { Lv1: 'HULL', Lv2: 'HULL-AFT', Lv3: 'RB01-02', Lv4: null, NAME: 'RB01-02', SUBNAME: null, x: 1060, y: 730,},
      { Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'OVERALL', Lv4: null, NAME: 'FORWARD', SUBNAME: null, x: 900, y: 795,},
      { Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'HL03', Lv4: null, NAME: 'HL03', SUBNAME: null, x: 1064, y: 697,},
      { Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'MD00f', Lv4: null, NAME: 'MD00f', SUBNAME: null, x: 508, y: 242,},
      { Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'VS04-5', Lv4: null, NAME: 'VS04-5', SUBNAME: null, x: 1022, y: 291,},
      { Lv1: 'HULL', Lv2: 'LQ', Lv3: 'OVERALL', Lv4: null, NAME: 'LQ', SUBNAME: null, x: 860, y: 280, },
      { Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', Lv4: null, NAME: 'LQ00', SUBNAME: null, x: 976, y: 535, },
      { Lv1: 'HULL', Lv2: 'LQ', Lv3: 'HD01', Lv4: null, NAME: 'HD01', SUBNAME: null, x: 976, y: 270, },
      { Lv1: 'TOPSIDE', Lv2: 'OVERALL',Lv3: null,       Lv4: null, NAME: 'TOPSIDE', SUBNAME: null, x: 1130, y: 380, },
      { Lv1: 'TOPSIDE', Lv2: 'COMMON', Lv3: 'OVERALL',  Lv4: null, NAME: 'Common',  SUBNAME: null, x: 515, y: 800, },
      { Lv1: 'TOPSIDE', Lv2: 'COMMON', Lv3: 'FL01',     Lv4: null, NAME: 'FL01',    SUBNAME: 'Flare Stack',       x: 511, y: 261, },
      { Lv1: 'TOPSIDE', Lv2: 'COMMON', Lv3: 'KO01',     Lv4: null, NAME: 'KO01',    SUBNAME: 'Flare KO Drum',     x: 146, y: 752, },
      { Lv1: 'TOPSIDE', Lv2: 'COMMON', Lv3: 'RC01',   Lv4: null, NAME: 'RC01',  SUBNAME: 'Piperack',          x: 464, y: 700, },
      { Lv1: 'TOPSIDE', Lv2: 'COMMON', Lv3: 'RC02',   Lv4: null, NAME: 'RC02',  SUBNAME: 'Piperack',          x: 732, y: 736, },
      { Lv1: 'TOPSIDE', Lv2: 'COMMON', Lv3: 'RC03',   Lv4: null, NAME: 'RC03',  SUBNAME: 'Piperack',          x: 960, y: 529, },
      { Lv1: 'TOPSIDE', Lv2: 'COMMON', Lv3: 'RC04',   Lv4: null, NAME: 'RC04',  SUBNAME: 'Piperack',          x: 1019, y: 838, },
      { Lv1: 'TOPSIDE', Lv2: 'COMMON', Lv3: 'RC05',   Lv4: null, NAME: 'RC05',  SUBNAME: 'Piperack',          x: 1275, y: 834, },
      { Lv1: 'TOPSIDE', Lv2: 'COMMON', Lv3: 'LD00DA',   Lv4: null, NAME: 'LD00DA',  SUBNAME: 'Laydown',    x: 1260, y: 448, },
      { Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'OVERALL', Lv4: null, NAME: 'Modules', SUBNAME: 'Modules',           x: 900, y: 430, },
      { Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW01',    Lv4: null, NAME: 'MW01',    SUBNAME: 'Recpt. Facil. & Prod. Sepn.',  x: 528, y: 208, },
      { Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW02',    Lv4: null, NAME: 'MW02',    SUBNAME: 'HC Dewpt. & Conden. Stabil.',  x: 852, y: 254, },
      { Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW04',    Lv4: null, NAME: 'MW04',    SUBNAME: 'Power Generation',      x: 1132, y: 295, },
      { Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'MW05',    Lv4: null, NAME: 'MW05',    SUBNAME: 'Utility',               x: 1226, y: 615, },
      { Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME01',    Lv4: null, NAME: 'ME01',    SUBNAME: 'Flash Gas Compression', x: 276, y: 420, },
      { Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME02',    Lv4: null, NAME: 'ME02',    SUBNAME: 'MEG & Fule Gas',        x: 505, y: 755, },
      { Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME04',    Lv4: null, NAME: 'ME04',    SUBNAME: 'Power Generation',      x: 817, y: 817, },
      { Lv1: 'TOPSIDE', Lv2: 'MODULES', Lv3: 'ME05',    Lv4: null, NAME: 'ME05',    SUBNAME: 'E/I Building',          x: 1122, y: 799, },
      { Lv1: 'Lev4', Lv2: 'HULL-AFT', Lv3: null, Lv4: 'MDK', NAME: 'Maindeck', SUBNAME: null, x: 1022, y: 215, },
      { Lv1: 'Lev4', Lv2: 'HULL-AFT', Lv3: null, Lv4: 'PRT', NAME: 'Port', SUBNAME: null, x: 1142, y: 441, },
      { Lv1: 'Lev4', Lv2: 'HULL-AFT', Lv3: null, Lv4: 'STB', NAME: 'Starboard', SUBNAME: null, x: 387, y: 680, },
      { Lv1: 'Lev4', Lv2: 'HULL-AFT', Lv3: null, Lv4: 'BLK', NAME: 'Bulkhead', SUBNAME: null, x: 971, y: 624, },
      { Lv1: 'Lev4', Lv2: 'HULL-AFT', Lv3: null, Lv4: 'BTM', NAME: 'Bottom', SUBNAME: null, x: 995, y: 881, },
      { Lv1: 'Lev4', Lv2: 'HULL-AFT', Lv3: null, Lv4: 'AFT', NAME: 'AFT', SUBNAME: null, x: 387, y: 285, },
      { Lv1: 'Lev4', Lv2: 'HULL-FOR', Lv3: null, Lv4: 'MDK', NAME: 'Maindeck', SUBNAME: null, x: 1041, y: 224, },
      { Lv1: 'Lev4', Lv2: 'HULL-FOR', Lv3: null, Lv4: 'PRT', NAME: 'Port', SUBNAME: null, x: 1161, y: 412, },
      { Lv1: 'Lev4', Lv2: 'HULL-FOR', Lv3: null, Lv4: 'STB', NAME: 'Starboard', SUBNAME: null, x: 364, y: 667, },
      { Lv1: 'Lev4', Lv2: 'HULL-FOR', Lv3: null, Lv4: 'BLK', NAME: 'Bulkhead', SUBNAME: null, x: 477, y: 372, },
      { Lv1: 'Lev4', Lv2: 'HULL-FOR', Lv3: null, Lv4: 'BTM', NAME: 'Bottom', SUBNAME: null, x: 700, y: 898, },
      { Lv1: 'Lev4', Lv2: 'HULL-FOR', Lv3: null, Lv4: 'FOR', NAME: 'Forward', SUBNAME: null, x: 1207, y: 667, },
      { Lv1: 'LQ', Lv2: 'LQ', Lv3: null, Lv4: 'LV67', NAME: 'LQ - Level 6/7', SUBNAME: null, x: 1015, y: 196, },
      { Lv1: 'LQ', Lv2: 'LQ', Lv3: null, Lv4: 'LV45', NAME: 'LQ - Level 4/5', SUBNAME: null, x: 1015, y: 410, },
      { Lv1: 'LQ', Lv2: 'LQ', Lv3: null, Lv4: 'LV23', NAME: 'LQ - Level 2/3', SUBNAME: null, x: 1015, y: 614, },
      { Lv1: 'LQ', Lv2: 'LQ', Lv3: null, Lv4: 'LV01', NAME: 'LQ - Level 0/1', SUBNAME: null, x: 1015, y: 839, },
      ],

    Coordinate: [
      {l2: 'HULL-AFT', code: 'RB01-02', x: 70,    y: 50},
      {l2: 'HULL-AFT', code: 'MD00a',   x: 0,     y: 0},
      {l2: 'HULL-FOR', code: 'MD00f',   x: -100,  y: 100},
      {l2: 'HULL-FOR', code: 'VS04-5',  x: 0,     y: 100},
      {l2: 'LQ',       code: 'LQ00',    x: 0,     y: 100},
      {l2: 'LQ',       code: 'HD01',    x: 0,     y: 100},

      {l2: 'HULL-AFT', code: 'AFT',     x: -100,  y: 0},
      {l2: 'HULL-AFT', code: 'PRT',     x: -100,  y: 50},
      {l2: 'HULL-AFT', code: 'STB',     x: -100,  y: 100},
      {l2: 'HULL-AFT', code: 'MDK',     x: 100,   y: 200},
      {l2: 'HULL-AFT', code: 'BLK',     x: 0,     y: 0},
      {l2: 'HULL-AFT', code: 'BTM',     x: 0,     y: 100},

      {l2: 'HULL-FOR', code: 'FOR',     x: -100,  y: 0},
      {l2: 'HULL-FOR', code: 'PRT',     x: 0,     y: 100},
      {l2: 'HULL-FOR', code: 'STB',     x: 0,     y: 100},
      {l2: 'HULL-FOR', code: 'MDK',     x: -100,  y: 200},
      {l2: 'HULL-FOR', code: 'BLK',     x: 0,     y: 100},
      {l2: 'HULL-FOR', code: 'BTM',     x: 50,    y: 100},

      {l2: 'LQ',       code: 'LV01',    x: 0,     y: 0},
      {l2: 'LQ',       code: 'LV23',    x: -100,  y: 0},
      {l2: 'LQ',       code: 'LV45',    x: 0,     y: 50},
      {l2: 'LQ',       code: 'LV67',    x: -100,  y: 0},

      // Topside
      {l2: 'FL01',     code: null,      x: 0,     y: 0},
      {l2: 'KO01',     code: null,      x: 0,     y: 0},
      {l2: 'RC01',   code: null,      x: 0,     y: 0},
      {l2: 'RC02',   code: null,      x: 0,     y: 0},
      {l2: 'RC03',   code: null,      x: 0,     y: 0},
      {l2: 'RC04',   code: null,      x: 0,     y: 0},
      {l2: 'RC05',   code: null,      x: 0,     y: 0},
      {l2: 'LD00DA',   code: null,      x: 0,     y: 0},

      {l2: 'MW01',     code: null,      x: -100,     y: 0},
      {l2: 'MW02',     code: null,      x: -100,     y: 0},
      {l2: 'MW04',     code: null,      x: -100,     y: 0},
      {l2: 'MW05',     code: null,      x: -100,     y: 0},
      {l2: 'ME01',     code: null,      x: -100,     y: 0},
      {l2: 'ME02',     code: null,      x: -100,     y: 0},
      {l2: 'ME04',     code: null,      x: -100,     y: 0},
      {l2: 'ME05',     code: null,      x: -100,     y: 0},
    ],

    Masks: [
      // Topside
      {mod: 'FL01',    x: 742, y: 198,   point: '69,0 48,30 59,88 0,633 102,689 204,641 204,556 146,545 125,7'},
      {mod: 'KO01',    x: 734, y: 508,   point: '6,6 0,103 130,146 230,80 214,47 159,32 115,0'},
      {mod: 'TSRC01',  x: 735, y: 484,   point: '0,42 0,116 190,152 220,131 220,38 47,0'},
      {mod: 'TSRC02',  x: 743, y: 483,   point: '0,20 0,107 175,148 201,128 201,34 22,0'},
      {mod: 'TSRC03',  x: 736, y: 498,   point: '0,22 0,79 195,118 222,99 222,38 14,0'},
      {mod: 'TSRC04',  x: 757, y: 503,   point: '0,20 0,81 151,112 175,94 175,30 21,0'},
      {mod: 'TSRC05',  x: 757, y: 502,   point: '0,13 0,81 148,111 173,96 173,29 22,0'},
      {mod: 'LD00DA',  x: 719, y: 494,   point: '0,90 8,122 99,154 251,51 251,9 158,0 45,51'},

      {mod: 'MW01',    x: 731, y: 445,   point: '0,69 0,201 165,234 230,192 230,73 203.5,19.5 96.5,-0.5'},
      {mod: 'MW02',    x: 725, y: 447,   point: '-0.5,57.5 -0.5,202.5 159.5,242.5 240.5,183.5 196.5,42.5 128.5,7.5 51.5,-0.5'},
      {mod: 'MW04',    x: 753, y: 407,   point: '0,236 8,255 124,282 181,243 181,153 146,122 133,7 59,0 34,157 8,186'},
      {mod: 'MW05',    x: 727, y: 458,   point: '0,59 0,175 158,217 238,163 238,56 176,6 119,0 54,21'},
      {mod: 'ME01',    x: 723, y: 495,   point: '0,145 10,171 178,208 239,169 239,21 69,0 39,29'},
      {mod: 'ME02',    x: 720, y: 454,   point: '0,36 6,184 174,220 262,152 215,126 184,29 49,0'},
      {mod: 'ME04',    x: 756, y: 415,   point: '57,0 42,146 3,181 0,240 28,262 128,278 180,234 180,166 143,88 133,6'},
      {mod: 'ME05',    x: 737, y: 494,   point: '0,163 0,188 148,227 213,181 213,56 174,56 174,21 68,0 20,48 20,137'},
    ]    
  }),

  computed: {
  },

  methods: {
    setStyles() {

      let lightBlue = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `shadowTimeline`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      lightBlue.append('stop').attr('stop-color', '#3d3d3d').attr('offset', '0').attr('stop-opacity', 0.5)
      lightBlue.append('stop').attr('stop-color', '#fff').attr('offset', '1').attr('stop-opacity', 0.3)

      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['LightGray'], [`LightGray`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['YellowGreen'], [`YellowGreen`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['LightBlue'], [`LightBlue`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['Pink'], [`Pink`, 0.5, 1.0, 0.5, -0.2], this.localId)
    },
  }
}