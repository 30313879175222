import * as d3 from 'd3'
import __C from '../../../../../../../../../src/primitives/_constant_'

export default {
  data: () => ({
    innerSvg: null,
    
    cmmSharedArea: null,
    cmmHeaderArea: null,
    cmmTableFilteredResult: null,

    selectedItems: [],

    dataset: {
      eqList: [],
      summary: {
        total: 40,
        installed: 40,
        items: [],
      }
    },
    displayDataFilter: {
      searchInput : '',
      stage: {
        CUR: { checked: true , name: 'Current Status'          , width: 70  },
        DOL: { checked: false, name: 'Dress Out Location'      , width: 140 },
        DO : { checked: false, name: 'Dress Out Complete'      , width: 90  },
        CFL: { checked: false, name: 'Crane Final Location'    , width: 145 },
        EFL: { checked: false, name: 'Equipment Final Location', width: 170 },
      }
    },
    reqTitle: {
      summary : {
        total : 'Equipment Summary - Total',
        p2w   : 'Equipment Summary - Critical. Over 2 weeks from ROS',
        w2w   : 'Equipment Summary - Critical. Within 2 weeks from ROS',
        nmr   : 'Equipment Summary - Critical. Not the MR Item',
      }
    },
    reqData: {
      title: '',
      persistent: true,
      overlay: false,
    },

    prevReq: null,
    indicator: null,
    indipath: 'M 0 0 L 30 0 L 30 30 L 42 30 L 15 60 L -12 30 L 0 30 Z',

    prevcallback: ''
  }),
  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0 
      )
    },
  },
  methods: {
    setDefaultValues(){
      d3.selection.prototype.moveToFront = function() {
        return this.each(function(){
          this.parentNode.appendChild(this)
        })
      }
      d3.selection.prototype.moveToBack = function() {  
        return this.each(function() { 
            var firstChild = this.parentNode.firstChild; 
            if (firstChild) { 
                this.parentNode.insertBefore(this, firstChild); 
            } 
        })
      }

      // Drow-Shadows ---------------------------
      this.svg
      .append('defs')
      .append('filter')
      .attr('id', 'drop_shadow_el')
      .append('feDropShadow')
      .attr('dx', 1)
      .attr('dy', 1)
      .attr('stdDeviation', 1)
      .attr('flood-opacity', .07)

      this.svg
      .append('defs')
      .append('filter')
      .attr('id', 'drop_shadow_popup')
      .append('feDropShadow')
      .attr('dx', 2)
      .attr('dy', 2)
      .attr('stdDeviation', 3)
      .attr('flood-opacity', .25)
      // ----------------------------------------

      this.dataset.eqList = [ ...this.DataItems ]
    },
    setSummaryData() {
      this.dataset.summary.items = []
      
      if(this.Queries.SQL1) this.dataset.summary.total = this.Queries.SQL1[0].TOTAL
      if(this.Queries.SQL2) this.Queries.SQL2.forEach(d => {
        this.dataset.summary.items.push({
          ...d,
          // DELTA : this.dataset.summary.total - d.ACTUAL,
          DELTA : d.ACTUAL -d.PLAN,
          PROG  : !d.ACTUAL ? 0 : Number((d.ACTUAL / this.dataset.summary.total * 100).toFixed(1))
        })

        if(d.STAGE == 'Installed') this.dataset.summary.installed = d.ACTUAL
      })
    },
    loadSvg(params) {
      return d3.xml(`${this.__HOST_NAME_RESOURCE}${params.path}`).then(source => {
        let svg = source.documentElement
        params.selection.node().append(svg)

        svg = d3.select(svg)
        if(params.x === 0 || params.x) svg.attr('x', params.x)
        if(params.y === 0 || params.y) svg.attr('y', params.y)
        if(params.width === 0 || params.width) svg.attr('width', params.width)
        if(params.height === 0 || params.height) svg.attr('height', params.height)

        return new Promise(resolve => {
          resolve(svg)
        })
      })
    },
    zoom() {
      let transform = d3.event.transform
      let el = this.innerSvg.select('#svg_content_wrapper')
      el.attr('transform', transform)

      // let translate = eltransform.match(/translate(.*?)\)/)
      // let coodinate = translate[0].match(/-?\d+\.?(\d+)?/g)
    },
    buildRequest(title) {
      let request = this.getRequestColumnProps(__C.REQUEST_ACTION.TARGET_POPUP_FREE, null, this.ColumnProps)
      if(!request) return {}

      request.filters = this.FilterValues || {}
      request.data = { ...this.reqData }
      request.data.title = title

      return request
    },
    callHLSPopup(d, checkact=[]) {
      let request_ = this.getRequestColumnProps(__C.REQUEST_ACTION.TARGET_POPUP_HLS, d, this.ColumnProps)
      request_.data = { ...this.reqData }

      // // check eq-popup already opened before sending the open command
      // // sending the command only when the eq-popup already opened
      // if(checkact.includes('equipment-search')) request_.action.purpose = 'equipment-search'
      // // shared can share the data with any child & parent component //
      // else if(checkact.includes('share')) request_.shared = d

      this.$emit('request-action', request_)
    },
    search(input=null) {
      // apply filter-values -----------------------
      let eqList = JSON.parse(JSON.stringify(this.DataItems))
      let fvKeys = this.FilterValues ? Object.keys(this.FilterValues) : []

      if(fvKeys.length > 0) {
        fvKeys.forEach(k => {
          if((['MOF_SHIP_NO', 'TAG_NO']).includes(k)) {
            eqList = eqList.filter(item => this.FilterValues[k].some(v => item[k] == v))
          } else {
            eqList = eqList.filter(item => item[k] == this.FilterValues[k])
          }
        })
      }
      // -------------------------------------------
      
      // apply search input ------------------------
      if(input != null) this.displayDataFilter.searchInput = input
      input = this.displayDataFilter.searchInput.toLowerCase()
      
      let targets = ['EQ_NAME', 'TAG_NO']
      eqList = input ? eqList.filter(item => targets.some(col => item[col].toLowerCase().includes(input))) : eqList
      // -------------------------------------------

      this.dataset.eqList = eqList
      this.equipmentFilter()
    },
    equipmentFilter() {
      let curcheck = this.displayDataFilter.stage.CUR.checked

      Object.keys(this.displayDataFilter.stage).forEach(k => {
        if(k == 'CUR') return

        let el = d3.select(`#${this.localId}`).select(`#${k}`)
        let elcheck = this.displayDataFilter.stage[k].checked

        el.style('opacity', curcheck||elcheck?1:0)

        // make invisible all the items //
        this.DataItems.forEach(item => {
          if(k == 'CFL') {
            el = d3.select(`#_${item.TAG_REPR}_MCFL`)
            if(el) el.style('opacity', 0)

            el = d3.select(`#_${item.TAG_REPR}_TCFL`)
            if(el) el.style('opacity', 0)

          } else {
            el = d3.select(`#_${item.TAG_REPR}_${k}`)
            if(el) el.style('opacity', 0)

            // make invisible all the tag relatives //
            if((['DO', 'EFL']).includes(k)) {
              // lines //
              el = d3.select(`#${k}-LINE-${item.SN}`)
              if(el) el.style('opacity', 0)

              // circles & tags //
              el = d3.select(`#${k}-${item.SN}`)
              if(el) {
                el.style('pointer-events', 'none')
                el.style('opacity', 0)
              }
            }
          }
        })
      })

      let elVisible = Object.keys(this.displayDataFilter.stage).filter(k => this.displayDataFilter.stage[k].checked)
      if(elVisible.length === 0) {
        this.indicatorMoveTo(null)
        return
      }

      elVisible.forEach(k => {
        let el = null

        if(k == 'CUR') {
          this.dataset.eqList.forEach(item => {
            if(k == 'CFL') {
              el = d3.select(`#_${item.TAG_REPR}_MCFL`)
              if(el) el.style('opacity', 1)
  
              el = d3.select(`#_${item.TAG_REPR}_TCFL`)
              if(el) el.style('opacity', 1)
  
              el = d3.select(`#_${item.TAG_REPR}_DO`)
              if(el) el.style('opacity', 1)

            } else if(item.CONST_STAGE) { // if CONST_STAGE is not undefined
              el = d3.select(`#_${item.TAG_REPR}_${item.CONST_STAGE}`)
              if(el) el.style('opacity', 1)

              // make visible all the tag relatives //
              // lines //
              el = d3.select(`#${(['DOL', 'DO']).includes(item.CONST_STAGE) ? 'DO' : item.CONST_STAGE}-LINE-${item.SN}`)
              if(el) el.style('opacity', 1)

              // circles & tags //
              el = d3.select(`#${(['DOL', 'DO']).includes(item.CONST_STAGE) ? 'DO' : item.CONST_STAGE}-${item.SN}`)
              if(el) {
                el.style('pointer-events', 'auto')
                el.style('opacity', 1)
              }
            }
          })
        } else {
          this.dataset.eqList.forEach(item => {
            if(k == 'CFL') {
              el = d3.select(`#_${item.TAG_REPR}_MCFL`)
              if(el) el.style('opacity', 1)
  
              el = d3.select(`#_${item.TAG_REPR}_TCFL`)
              if(el) el.style('opacity', 1)

            } else {
              el = d3.select(`#_${item.TAG_REPR}_${k}`)
              if(el) el.style('opacity', 1)

              // make visible all the tag relatives //
              // lines //
              el = d3.select(`#${(['DOL', 'DO']).includes(k) ? 'DO' : k}-LINE-${item.SN}`)
              if(el) el.style('opacity', 1)

              // circles & tags //
              el = d3.select(`#${(['DOL', 'DO']).includes(k) ? 'DO' : k}-${item.SN}`)
              if(el) {
                el.style('pointer-events', 'auto')
                el.style('opacity', 1)
              }
            }
          })
        }
      })
    },
    sendRequestAction(selection, d) {
      selection
      .on('mouseover', (_, i, a) => {
        let el = d3.select(a[i])

        if(el.node().tagName == 'g') el.style('opacity', .5)
        else el.attr('fill', '#81d4fa')
      })
      .on('mouseout', (_, i, a) => {
        let el = d3.select(a[i])

        if(el.node().tagName == 'g') el.style('opacity', 1)
        else el.attr('fill', '#333')
      })
      .on('click', () => {
        this.indicatorMoveTo(d)
        this.callHLSPopup(d, ['share'])

        let req = this.buildRequest(this.reqTitle.summary.total)
        req.shared = d
        this.$emit('request-action', req)
      })
    },
    parseMatrixCoordinate(selection) {
      let matrix = (selection.attr('transform') || '').match(/matrix(.*?)\)/g)
      matrix = matrix[0].match(/-?\d+\.?(\d+)?/g)
      
      if(matrix.length == 6) var coordinate = {
        x: parseInt(matrix[4]),
        y: parseInt(matrix[5])
      }; else coordinate = {
        x: parseInt(matrix[6]),
        y: parseInt(matrix[7])
      }

      return coordinate
    },
    rebuildEqCode() {
      // CODE Definition
      // The code must be in the 'CODE' layer of AI.
      let codeLayer = this.innerSvg.select(`#CODE`)

      if(codeLayer.empty()) {
        console.log('[DEBUG] Svg element #CODE does not exist.')
        return
      }

      codeLayer.selectAll('g').each((_, i, a) => {
        let group = d3.select(a[i])
        let id = group.attr('id')
        let elText = group.select('text')
        let coordinate = this.parseMatrixCoordinate(elText)

        let no_ = elText.text()
        let idx = this.DataItems.findIndex(d => d.SN == parseInt(no_))
        group.remove()

        if(idx < 0) return
        
        let data = this.DataItems[idx]
        let styleStage = this.eqStyle.Stage.find(styl => styl.code == data.CONST_STAGE)
        let g = codeLayer
        .append('g')
        .attr('id', `${id}`)
        .attr('transform', `translate(${coordinate.x+12}, ${coordinate.y-7})`)
        .style('cursor', 'pointer')
        .call(s => { this.sendRequestAction(s, data) })

        g.append('ellipse')
        .attr('rx', this.eqStyle.CircleBorder.rx).attr('ry', this.eqStyle.CircleBorder.ry)
        .attr('stroke', '#757575').attr('stroke-width', this.eqStyle.CircleBorder.stroke)
        .attr('fill', styleStage ? styleStage.bcolor : '#fff')

        g.append('text')
        .attr('transform', `translate(0, ${this.eqStyle.CircleBorder.padding})`)
        .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .style('font-family', 'roboto').style('font-size', this.eqStyle.CircleBorder.tsize)
        .style('fill', '#454545')
        .text(no_)
      })
    },
    rebuildEqTag() {
      let tagLayer = this.innerSvg.select(`#TAG`)

      if(tagLayer.empty()) {
        console.log('[DEBUG] Svg element #TAG does not exist.')
        return
      }

      tagLayer.selectAll('text').each((_, i, a) => {
        let text = d3.select(a[i]).text()
        let code = text.split('-')
        let type = code[0]
        let no = code[1]
        let direction_ = code[2]
        let group = d3.select(`#${type}-${no}`)
        let data = this.DataItems.find(d => d.SN == parseInt(no))

        if(!data) return

        group
        .append('text')
        .attr('class', `TAG_${type}_${no}`)
        .attr('x', direction_ == 'L' ? -35 : 35)
        .attr('y', 5.5)
        .attr('fill', '#333')
        .attr('text-anchor', direction_ == 'L' ? 'end' : 'start')
        .attr('alignment-baseline', 'middle')
        .style('font-family', 'roboto')
        .style('font-size', this.eqStyle.CircleBorder.tsize)
        .text(`${data.TAG_REPR} / ${data.EQ_NAME}`)
      })

      tagLayer.remove()
    },
    indicatorMoveTo(d) {
      this.prevReq = d

      let invisible = () => {
        this.indicator
        .transition().duration(150)
        .attr('transform', 'translate(0, 0)')
        .style('opacity', 0)
      }

      if(!d || this.dataset.eqList.findIndex(item => item.TAG_NO == d.TAG_NO) === -1) {
        invisible()
        return
      }

      let prefix = ''
      let elVisible = Object.keys(this.displayDataFilter.stage).filter(k => this.displayDataFilter.stage[k].checked)

      if(elVisible[0] == 'CUR') {
        if(!d.CONST_STAGE) {
          invisible()
          return

        } else if((['DOL', 'DO']).includes(d.CONST_STAGE)) prefix = 'DO'
        else prefix = 'EFL'

      } else {
        let idx = elVisible.indexOf('CFL')
        if(idx > -1) elVisible.splice(idx, 1)
        if(elVisible.length === 0) {
          invisible()
          return
        }

        if((['DOL', 'DO']).includes(elVisible[0])) prefix = 'DO'
        else prefix = 'EFL'
      }

      let el = d3.select(`#${prefix}-${d.SN}`)
      let box = this.getCoordinates(el)

      this.indicator
      .transition().duration(150)
      .attr('transform', `translate(${box[0]-15}, ${box[1]-87})`)
      .style('opacity', 1)
    }
  },
}