import * as d3 from 'd3'

export default {
  data: () => ({
    dataSet: [],
  }),
  computed: {
    ready2draw() {

      // console.log('------------------------------')
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)

      return (
        // this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },
  methods: {
    setDefaultValues(){
      this.dataSet = JSON.parse(JSON.stringify(this.DataItems))

      this.svg
      .append('clipPath')
      .attr('id', 'overflow_free2')
      .append('rect')
      .attr('x', 20)
      .attr('y', 0)
      .attr('width', 190)
      .attr('height', this.rowHeight)
    },
  }
}