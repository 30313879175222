import gmx_zMixins         from '../../../../../includes/Jmixins/zMixins'


// Locally declared common Data-Props & SVG Drawing Support
import lmx_LocalValues          from './set_LocalValues'
import lmx_Styles               from '../primitives/styles'
import lmx_Forms                from './draw_Forms'
import lmx_Events               from './Events'
import lmx_Watching             from './Watching'
import lmx_MainPlant            from './draw_MainPlant'

import lmx_drawToggle           from './draw_Toggle'
import lmx_drawList             from './draw_List'
import lmx_drawCheckbox         from './draw_Checkbox'

export default {
  mixins: [
    gmx_zMixins, 

    // Local Mixins
    
    lmx_Styles,
    lmx_Watching,
    lmx_Events,

    lmx_LocalValues,
    lmx_Forms,
    lmx_MainPlant,

    lmx_drawToggle,
    lmx_drawList,
    lmx_drawCheckbox,
    
  ],
}
