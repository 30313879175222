import Defs from '../../../../../../includes/primitives/Color_Defs'

export default {
  data: () => ({
    dataSet: [],
    lineChartData: [],

    activity: 'MC_PLAN',
    
    inputFilter: {
      values: [
        { colName: 'SUBSYSTEM'    , text: 'Subsystem' , value: '', x: 0   , textWidth: 67, on: true },
        { colName: 'TAG'          , text: 'Tag'       , value: '', x: 72  , textWidth: 34, on: false },
        { colName: 'PUNCH_NUMBER' , text: 'Punch'     , value: '', x: 111 , textWidth: 45, on: false },
      ]
    },
    filterCodes: {
      Filters: {
        attrs: { type: 'single', x: 0, y: 0, width: 155, height: 40, labelWidth: 45, textWidth: 40 },
        values: [
          { colName: 'STATUS', text: 'All'  , value: 'MC_PLAN' , on: true },
          { colName: 'STATUS', text: 'RTP'  , value: '[RTP_DESCR] IS NOT NULL', on: false },
          { colName: 'STATUS', text: 'COI'  , value: "FILES > 0 AND [STATUS] != 'Completed'" , on: false },
        ]
      }
    },
    skyline: {
                sizeType    : 'auto',
                fixedSize   : 450,
                minSize     : 450,
                startFloat  : 2,      // Fixed
                endFloat    : 2,      // Fixed
                weekEnd     : 'friday',
                font        : 'roboto',
                marginTop   : 150     ,

                box: {
                            font      : 'roboto', 
                            width     : 38,     // Fixed
                            height    : 15,     // Fixed
                            gap       : 1,      // Fixed
                },
                line : {
                            display   : 'Y',
                            height    : 300,
                            gap       : 1,
                },
                legend: {
                            x         : 25,
                            y         : 50,
                            font      : 'roboto', 
                            group: {
                                      tSize     : 11,
                                      tColot    : '#333',
                                      lineSpace : 15,
                            },
                            item: {
                                      tSize     : 10,
                                      tColor    : '#757575',
                                      space     : 10,
                            },
                            notes: {
                                      x         : 10,
                                      y         : 150,
                                      tSize     : 10,
                                      tColor    : '#bcbcbc',
                                      space     : 15,
                            },
                },
                dropdown: {
                            x         : 25,
                            y         : 90,
                            option: [
                                      {text: 'Steel Sutting',   value: 'SC'},
                                      {text: 'Block Assembly',  value: 'Assembly'},
                                      {text: 'Painting',        value: 'Painting'},
                                      {text: 'Block Erection',  value: 'Erection'},
                            ],
                },
    },
    timeline: { 
                week        : [],
                month       : [],
                year        : [],
                plan        : [],
                actual      : [],
                scale       : null,     

                weekCount   : [],
                weekSize    : null,    // Auto
                length      : null,    // Auto
                height      : null,    // Auto
                font        : 'roboto', 
                baseX       : 115,
                baseY       : 801,

                style: {
                        week: {
                                display   : 'Y',
                                height    : 15,
                                line      : 12,
                                sWidth    : 0.5,
                                sColor    : '#BCBCBC',
                                tSize     : 9,
                                tColor    : '#757575',
                        },
                        month: {
                                display   : 'Y',
                                height    : 14,
                                line      : 15,
                                sWidth    : 0.5,
                                sColor    : '#BCBCBC',
                                tSize     : 10,
                                tColor    : '#333',
                        },
                        year: {
                                display   : 'Y',
                                height    : 10,
                                line      : 20,
                                sWidth    : 0.5,
                                sColor    : '#BCBCBC',
                                tSize     : 11,
                                tColor    : '#000',
                        },
                },

    },
    style:[ 
              {
                group: 'Outstanding Punch',
                position: { x: 0, y: 160 },
                item: [
                  {
                    name        : 'None',
                    refColumn   : 'PUNCH_STATUS',
                    codeValue   : 'N',
                    dataColumn  : '',
                    tStyle      : 'regular',
                    tSize       : 9,
                    tColor      : '#000',
                    sWidth      : 0.5,
                    sColor      : '#fdd835',
                    bColor      : '#FEFEFE',
                    opacity     : 1,
                    radius      : 2,
                    on          : true,
                  },
                  {
                    name        : 'Category A',
                    refColumn   : 'PUNCH_STATUS',
                    codeValue   : 'A',
                    dataColumn  : '',
                    tStyle      : 'regular',
                    tSize       : 9,
                    tColor      : '#000',
                    sWidth      : 0.5,
                    sColor      : '#d32f2f',
                    bColor      : '#FEFEFE',
                    opacity     : 1,
                    radius      : 2,
                    on          : true,
                  },
                  {
                    name        : 'Category B',
                    refColumn   : 'PUNCH_STATUS',
                    codeValue   : 'B',
                    dataColumn  : '',
                    tStyle      : 'regular',
                    tSize       : 9,
                    tColor      : '#000',
                    sWidth      : 0.5,
                    sColor      : '#0362fc',
                    bColor      : '#FEFEFE',
                    opacity     : 1,
                    radius      : 2,
                    on          : true,
                  },
                ]
              },
              {
                group: 'Subsystem Status',
                position: { x: 0, y: 210 },
                item: [
                          {
                            name        : 'Completed',
                            refColumn   : 'STATUS',
                            codeValue   : 'Completed',
                            dataColumn  : '',
                            tStyle      : 'regular',
                            tSize       : 9,
                            tColor      : '#827717',
                            sWidth      : 0.5,
                            sColor      : '#8BC248',
                            bColor      : '#CDDC37',
                            opacity     : 1,
                            radius      : 2,
                            on          : true,
                          },
                          {
                            name        : 'Incompleted',
                            refColumn   : 'STATUS',
                            codeValue   : 'IR',
                            dataColumn  : '',
                            tStyle      : 'regular',
                            tSize       : 9,
                            tColor      : '#757575',
                            sWidth      : 0.5,
                            sColor      : '#bcbcbc',
                            bColor      : '#fff',
                            opacity     : 1,
                            radius      : 2,
                            on          : true,
                          },
                ],
              },
    ],
    styleFilter: {
      over: {
        stroke: '#ffb300',
        fill: '#ffd54f',
        color: '#fff'
      },
      out: {
        stroke: '#bcbcbc',
        fill: '#fff',
        color: '#c9c9c9'
      },
      selected: {
        stroke: '#1565c0',
        fill: '#64b5f6',
        color: '#757575'
      }
    },
    table: {
      values: [
        { text: 'Cum. Total', colName: 'A_CUM' },
        { text: 'Completed' , colName: 'A_C' },
      ]
    }
  }),
  computed: {
  },
  methods: {
    setStyles() {
      let lightBlue = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `shadowTimeline`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      lightBlue.append('stop').attr('stop-color', '#3d3d3d').attr('offset', '0').attr('stop-opacity', 0.5)
      lightBlue.append('stop').attr('stop-color', '#fff').attr('offset', '1').attr('stop-opacity', 0.3)

      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['LightGray'], [`LightGray`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['YellowGreen'], [`YellowGreen`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['LightBlue'], [`LightBlue`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['Pink'], [`Pink`, 0.5, 1.0, 0.5, -0.2], this.localId)
    },
  }
}