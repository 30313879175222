<template>
  <div :id="localId"></div>
</template>

<script>
import mx_Core from './includes/mixins/importer'

export default {
  name: "j-chart-donut",
  mixins: [mx_Core],
  props: {
    id: String,
    getSelectItems: String,     
    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },
    DataItems: { type: Array, default: () => ([]) },
    Queries: { type: Object, default: () => ({}) },
    ColumnProps: { type: Array, default: () => ([]) },
  },
  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
  },
  mounted() {
    this.register(this.draw)
  },
  methods: {
    draw() {
      
      // Check all the necessary values are available
      if (!this.ready2draw) return

      this.clear()
      this.drawCanvas()
      this.drawTexts()
      this.drawChart()
      if (this.getSelectItems) {
        this.setEvent_PDF()
      }            
      this.complete()
    },
    drawChart() {
      this.setDefaultValues()
      this.drawBlkPopup()
      this.drawBackground()
      this.drawFloats()
      this.drawInformation()

      this.gotoStage('OverAll')
    },
  }
}
</script>