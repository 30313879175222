import * as d3 from 'd3'
import __M from 'moment'
export default {
  data: () => ({
    y_coordinate: 0,
    height: {
      mainTimeline: 100,
      subTimeline: 50,
      barLarge: 35,
      barSmall: 25,
    },
    margin: {
      left: 120,
      mainTimeline: 50,
      subTimeline: 100,
      barPhase: 15,
      milestone: 100,
      info: 20,
      foldButton: 50
    }
  }),

  methods: {
    //drawChart()
    //drawPackage(p)
    //drawLv0Info(__d)
    //drawArea(a)
    //drawLegend()
    //drawPhase(ph)
    //drawLv1Info(__d, list)
    //drawSubTimeline(d, type='subTimeline')
    //drawBar(d, type='barLarge')
    //drawMileston(__d, type='milestone')
    drawChart(){
      this.packages.forEach(p => {
        if (this.collapsed[p.P6_LV1]) this.drawPackage(p)
      })
      this.drawLegend()
    },

    drawPackage(p) {
      // draw milestone
      let d__ = this.DataItems.filter(d => d.ACTIVITY_TYPE == 'MS' && d.LEVEL == '0' && d.P6_LV1 == p.P6_LV1)
      // d__.forEach( (d, i) => { this.drawMileston(d, i) })
      this.drawMileston(d__)

      // draw timeline & button
      d__ = this.DataItems.filter(d => d.LEVEL == '0' && d.P6_LV1 == p.P6_LV1 && d.ITEM_DESC == 'Overall')
      this.drawSubTimeline(d__)
      this.drawLv0Info(d__)

      // draw bar
      d__ = this.DataItems.filter(d => d.ACTIVITY_TYPE == 'TASK' && d.LEVEL == '0' && d.P6_LV1 == p.P6_LV1)
      d__.forEach(d => { this.drawBar(d) })

      if (!this.areas) return
      this.areas.filter(a => a.P6_LV1 == p.P6_LV1).forEach(a => {
        if (this.collapsed[`${a.P6_LV1}${a.P6_LV2}`]) this.drawArea(a)
      })
    },

    drawLv0Info(__d){
      let key = 'P6_LV1'
      let d = __d[0]
      let g = this.svgGroup
      .append('g')
      .attr('transform', `translate(${this.margin.left +20}, ${this.y_coordinate - 30})`)
      .attr('class', '__info_')
      
      g.append('text')
      .attr('x', 0).attr('y', 0)
      .text(d[key])
      .style('font-size', 16).style('fill', '#000')
      .style('font-weight', 'bold')
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .style('cursor', 'pointer')
      

      g
      .append('g')
      .attr('transform', `translate(-25, 20)`)
      .append('image')
      .attr('opacity', 1)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/China1/Level2/${d.P6_LV1}.png`)
      .attr('width', '230px')
        
      if (!this.areas) return
      let area1 = this.areas.filter(a => a.P6_LV1 == d.P6_LV1)[0]
      if (!area1) return

      // Angles down
      g
      .append('path')
      .attr('transform', `translate(-25, -13)`)
      .attr('class', '__draw_info_arrow_')
      .attr('d', this.collapsed[`${area1.P6_LV1}${area1.P6_LV2}`] ? 
      `M11.3,11.5c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3l3-3c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0L12,9.3L9.7,7c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4L11.3,11.5z M14.3,12.5L12,14.8l-2.3-2.3c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l3,3c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3l3-3c0.4-0.4,0.4-1,0-1.4C15.3,12.2,14.7,12.2,14.3,12.5z` :
      `M12.7,12.5C12.7,12.5,12.7,12.5,12.7,12.5c-0.4-0.4-1-0.4-1.4,0l-3,3c-0.4,0.4-0.4,1,0,1.4c0.4,0.4,1,0.4,1.4,0l2.3-2.3l2.3,2.3c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4L12.7,12.5z M9.7,11.5L12,9.2l2.3,2.3c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4l-3-3c0,0,0,0,0,0c-0.4-0.4-1-0.4-1.4,0l-3,3c-0.4,0.4-0.4,1,0,1.4C8.7,11.8,9.3,11.8,9.7,11.5z`)

      g
      .on('mouseover', function(){ 
        d3.select(this).transition().duration(100).style('opacity',.8) 
      })
      .on('mouseout', function(){ 
        d3.select(this).transition().duration(100).style('opacity',1) 
      })
      .style('cursor', 'pointer')
      .on('click', () => {
        this.areas.filter(a => a.P6_LV1 == d.P6_LV1).forEach(a => {
          let key = `${a.P6_LV1}${a.P6_LV2}`
          this.collapsed[key] = !this.collapsed[key]
        })
        //console.log('drawLv0Info click collapsed: ', this.collapsed)
        //for pdf
        this.drawFlag = !this.drawFlag
        this.selectedItems = { 
          LEVEL: 'DRAW',
          P6_LV1: d.P6_LV1,
          P6_LV2: '',
          P6_LV3: '',
          FLAG : false,
          CALLFLAG : this.collFlag,
          DRAWFLAG : this.drawFlag
        }
        this.redraw()
      })
    },

    drawArea(a) {
      // draw milestone
      let d__ = this.DataItems.filter(d => d.ACTIVITY_TYPE == 'MS' && d.LEVEL == '1' && d.P6_LV1 == a.P6_LV1 && d.P6_LV2 == a.P6_LV2)
      // d__.forEach(d => { this.drawMileston(d) })
      this.drawMileston(d__)

      // draw timeline & button
      d__ = this.DataItems.filter(d => d.LEVEL == '1' && d.P6_LV1 == a.P6_LV1 && d.P6_LV2 == a.P6_LV2 && d.ITEM_DESC == 'Overall')
      
      this.drawSubTimeline(d__)
      let fd = this.DataItems.filter(d => d.ACTIVITY_TYPE == 'TASK' && d.LEVEL == '1' && d.P6_LV1 == a.P6_LV1 && d.P6_LV2 == a.P6_LV2)
      this.drawLv1Info(d__, fd)

      // draw bar
      d__ = this.DataItems.filter(d => d.ACTIVITY_TYPE == 'TASK' && d.LEVEL == '1' && d.P6_LV1 == a.P6_LV1 && d.P6_LV2 == a.P6_LV2)

      d__.forEach(d => {
        this.drawBar(d)
        
        if (!this.phases) return
        this.phases.filter(ph => ph.P6_LV1 == a.P6_LV1 && ph.P6_LV2 == a.P6_LV2 && ph.P6_LV3 == d.ITEM_DESC).forEach(ph => {
          if (this.collapsed[`${ph.P6_LV1}${ph.P6_LV2}${ph.P6_LV3}`]) this.drawPhase(ph)
        })
      })
    },

    drawLegend(){
      
      let legend = this.svgGroup
      .append('g')
      .attr('transform', `translate(${this.margin.left + 140}, ${this.margin.mainTimeline + 40})`)
      .attr('class', '__legend_group_')
      
      // Baseline
      legend.append('rect')
      .attr('x', 0).attr('y', 0)
      .attr('width', 50)
      .attr('height', 3).attr('fill', '#ccc')
      
      legend.append('text')
      .attr('x', 60).attr('y', 0)
      .text('Baseline').style('font-size', 10).style('fill', '#9E9E9E')
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      
      // Current Plan
      legend.append('rect')
      .attr('x', 120).attr('y', -6)
      .attr('width', 50)
      .attr('height', 10).attr('fill', '#B3E5FC') 
      
      legend.append('text')
      .attr('x', 180).attr('y', 0)
      .text('Current Plan(Summary Level)').style('font-size', 10).style('fill', '#9E9E9E')
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      
      // Current Plan
      legend.append('rect')
      .attr('x', 330).attr('y', -6)
      .attr('width', 50)
      .attr('height', 10).attr('fill', '#4DB6AC') 
      
      legend.append('text')
      .attr('x', 390).attr('y', 0)
      .text('Current Plan(Discipline Level)').style('font-size', 10).style('fill', '#9E9E9E')
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')

      // Milestone plan
      let msPlan = legend
      .append('g')
      .attr('id', 'item_indicator')
      .attr('transform', `translate(560, 5), rotate(-180) scale(.2)`)
      
      msPlan
      .append('path')
      .attr('d', this.pointerPath)
      .attr('fill', '#424242')
      .attr('stroke', '#424242')
      .attr('stroke-width', 1)

      legend.append('text')
      .attr('x', 565).attr('y', 0)
      .text('Milestone Plan').style('font-size', 10).style('fill', '#9E9E9E')
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')

      // Milestone Actual
      let msActual = legend
      .append('g')
      .attr('id', 'item_indicator')
      .attr('transform', `translate(660, 5), rotate(-180) scale(.2)`)
      
      msActual
      .append('path')
      .attr('d', this.pointerPath)
      .attr('fill', '#D50000')
      .attr('stroke', '#D50000')
      .attr('stroke-width', 1)

      legend.append('text')
      .attr('x', 665).attr('y', 0)
      .text('Milestone Actual').style('font-size', 10).style('fill', '#9E9E9E')
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')

      // CUTOFF LINE
      this.svgGroup.append('line')
      .attr('x1', `${this.timeScale(__M(new Date(this.cutoff)).toDate()) + this.margin.left - 5}`).attr('y1', this.height.mainTimeline + 40)
      .attr('x2',`${this.timeScale(__M(new Date(this.cutoff)).toDate()) + this.margin.left - 5}`).attr('y2', this.y_coordinate)
      .attr('stroke-width', 1).attr('stroke', '#D50000').attr('opacity', 0.7)
    },

    drawPhase(ph) {
      // draw bar
      let d__ = this.DataItems.filter(d => d.ACTIVITY_TYPE == 'TASK' && d.LEVEL == '2' && d.P6_LV1 == ph.P6_LV1 && d.P6_LV2 == ph.P6_LV2 && d.P6_LV3 == ph.P6_LV3)
      d__.forEach(d => { this.drawBar(d, 'barSmall') })
      
      this.y_coordinate += this.height.barSmall
    },

    drawLv1Info(__d, list){
      let d = __d[0]

      let g = this.svgGroup
      .append('g')
      .attr('transform', `translate(${!d.BL0_SD && !d.PLAN_SD ? this.margin.left : this.timeScale(d.BL0_SD < d.PLAN_SD ? new Date(d.BL0_SD) : new Date(d.PLAN_SD))}, ${this.y_coordinate - 10.1})`)
      .attr('class', '__info_')

      let titles = d.P6_LV2.split(' ')
      let ty = 0
      titles.forEach(t => {
        
        g.append('text')
        .attr('x', 0).attr('y', ty)
        .text(t)
        .style('font-size', 14).style('fill', '#000')
        .style('font-wieght', 'bold')
        .attr('text-anchor', 'end').attr('alignment-baseline', 'middle')

        ty += 16
      })
      
      // g.append('rect')
      // .attr('transform', `translate(-100, 20)`)
      //   .attr('width', 150)
      //   .attr('height', 105)
      //   .attr('fill', '#fff')
      //   .attr('stroke', '#ccc').attr('stroke-width', 1)
      // g.append('text')
      // .attr('transform', `translate(-25, 75)`)
      // .text('TBD')
      // .style('font-size', 20).style('fill', '#ccc')
      // .style('font-wieght', 'bold')
      // .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      // g
      // .append('g')
      // .attr('transform', `translate(-100, 20)`)
      // .append('image')
      // .attr('opacity', 1)
      // .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/China1/Level2/${d.P6_LV1}${d.P6_LV2}.png`)
      // .attr('width', '150px')
    },

    drawSubTimeline(d, type='subTimeline'){
      let d__ = d[0]
      this.y_coordinate += this.margin[type]

      let timeStart = d__.BL0_SD ? 'BL0_SD' : 'PLAN_SD'
      let timeEnd = 'PLAN_FD'

      // Get start, end
      let timeStartDate = __M(new Date(d__[timeStart])).startOf('month').add(-this.subTimelineFloat.start, 'months').toDate()
      let timeEndDate = __M(new Date(d__[timeEnd])).endOf('month').add(this.subTimelineFloat.end, 'months').toDate()

      // Get timeline months
      let months = timeEndDate.getMonth() - timeStartDate.getMonth()
      let years = timeEndDate.getYear() - timeStartDate.getYear()
      months = months + (years*12)
      
      let tickValues_ = []
      
      for(var i = 0; i <= months; i++) {
        let _nextMonthDate__ = new Date(new Date(timeStartDate).setMonth(timeStartDate.getMonth() + i))

        // For the Month ---
        let endMonth_ = new Date(new Date(_nextMonthDate__).setMonth(_nextMonthDate__.getMonth() + 1) - 1)
        let midDate = new Date(
          d3.mean([
            _nextMonthDate__, // 1st date of the month
            endMonth_         // last date of the month
          ])
        )
        tickValues_.push({ 
          name: d3.timeFormat('%b')(midDate), 
          value: new Date(midDate),
          endDate: endMonth_
        })
      }

      // Draw timeline
      let startX = this.timeScale(timeStartDate)
      let endX = this.timeScale(timeEndDate)
      let timelineWidth = endX - startX
      
      let g = this.svgGroup
      .append('g')
      .attr('transform', `translate(${this.margin.left}, ${this.y_coordinate})`)
      .attr('class', '__group_timeline_')
  
      g
      .append('path') // shadow
      .attr('d', `M${startX + 50},10 H ${endX - 50} L ${endX},20 H${startX} Z`).attr('fill', `url(#shadowTimeline)`) 

      g
      .append('rect')
      .attr('x', startX).attr('y', 0).attr('width', timelineWidth).attr('height', 15).attr('fill', `url(#${this.localId}__LinearA4LightGray0)`)

      // Timeline Text using the scale function
      // Month
      g
      .append('g')
      .selectAll('text')
      .data(tickValues_)
      .enter()
      .append('text')
      .attr('x', d => this.timeScale(d.value))
      .attr('y', 8).style('font-size', 10).style('fill', '#333')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(d => d.name.toUpperCase())

      // Timeline Seperator
      g
      .append('g')
      .selectAll('path')
      .data(tickValues_)
      .enter()
      .append('path')
      .attr('d', (d,i) => {
        if (tickValues_.length-1 > i) {
          return `M${this.timeScale(d.endDate)}, 4 V12`
        }
      })
      .attr('stroke', '#757575').attr('stroke-width', 0.3)
      
      this.y_coordinate += this.height[type]
    },

    drawBar(d, type='barLarge') {
      if (!(d.PLAN_SD && d.PLAN_FD) || !(d.BL0_SD && d.BL0_FD)) return
      
      // draw bars
      let g = this.svgGroup
      .append('g')
      .attr('transform', `translate(${this.margin.left}, ${this.y_coordinate})`)
      .attr('class', '__bar_')
      
      // BL0_SD, PLAN_SD 중 큰 값 계산해서 x로 사용
      let barText = g.append('text')
      .attr('x', this.timeScale(d.BL0_SD < d.PLAN_SD ? new Date(d.BL0_SD) : new Date(d.PLAN_SD)) - 10).attr('y', 8)
      .text(`${d.ITEM_DESC}`).style('font-size', 10).style('fill', '#9E9E9E')
      .attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
      
      // draw - baseline
      let barBaseline = g.append('rect')
      .attr('x', this.timeScale(new Date(d.BL0_SD))).attr('y', 0)
      .attr('width', this.timeScale(new Date(d.BL0_FD))-this.timeScale(new Date(d.BL0_SD)))
      .attr('height', 3).attr('fill', '#ccc') 

      // draw - current bar
      let barCurrent = g.append('rect')
      .attr('x', this.timeScale(new Date(d.PLAN_SD))).attr('y', 5)
      .attr('width', this.timeScale(new Date(d.PLAN_FD))-this.timeScale(new Date(d.PLAN_SD)))
      .attr('height', type == 'barSmall' ? 6 : 10).attr('fill', type == 'barSmall' ? '#4DB6AC' : '#B3E5FC') 

      // draw Plan Progress(BL0_SD) text
      if (d.PLAN_PRO && d.BL0_SD && d.BL0_FD) {
        // BL0_SD, PLAN_SD 중 큰 값 계산해서 x로 사용
        let barProgPlan = g.append('text')
        .attr('x', this.timeScale(d.BL0_FD < d.PLAN_FD ? new Date(d.PLAN_FD) : new Date(d.BL0_FD)) + 10).attr('y', 10)
        .style('font-size', 10).style('fill', '#9E9E9E')
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text(`Plan ${d.PLAN_PRO}%`)
      }

      if (d.PLAN_PRO && d.ACT_PRO && d.BL0_SD && d.BL0_FD && d.PLAN_FD && d.PLAN_SD){
        g.append('text')
        .attr('x', this.timeScale(d.BL0_FD < d.PLAN_FD ? new Date(d.PLAN_FD) : new Date(d.BL0_FD)) + 55).attr('y', 10)
        .style('font-size', 10).style('fill', '#9E9E9E')
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text(' / ')
      }

      // draw Actaul Progress (PLAN_SD, PLAN_FD) text
      if (d.ACT_PRO && d.PLAN_FD && d.PLAN_SD){
        let barProg = g.append('text')
        .attr('x',  () => {
          if (d.BL0_FD && d.PLAN_PRO) {
            // BL0_FD, PLAN_FD 비교 -> 더 늦은 스케쥴 기준으로 그리기
            if (new Date(d.BL0_FD) < new Date(d.PLAN_FD)) {
              return this.timeScale(new Date(d.PLAN_FD)) + 64
            }
            else return this.timeScale(new Date(d.BL0_FD)) + 64
          }else {
            return this.timeScale(new Date(d.PLAN_FD)) + 10
          }
        })
        .attr('y', 10)
        .style('font-size', 10).style('fill', '#D50000')
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text(`Actual ${d.ACT_PRO}%`)
      }
      
      if (this.timeScale(new Date(d.BL0_SD))){
        let plSDate = g.append('text')
        .attr('x', this.timeScale(new Date(d.BL0_SD))).attr('y', -4)
        .style('font-size', 8).style('fill', '#9E9E9E')
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text(`${__M(d.BL0_SD).format('MM/DD')}`)
      }
      
      if (this.timeScale(new Date(d.BL0_FD))){

        let plFDate = g.append('text')
        .attr('x', this.timeScale(new Date(d.BL0_FD))).attr('y', -4)
        .style('font-size', 8).style('fill', '#9E9E9E')
        .attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text(`${__M(d.BL0_FD).format('MM/DD')}`)
      }
      
      if (this.timeScale(new Date(d.PLAN_SD))){
        let planSDate = g.append('text')
        .attr('x', this.timeScale(new Date(d.PLAN_SD))).attr('y', 21)
        .style('font-size', 8).style('fill', '#9E9E9E')
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text(`${__M(d.PLAN_SD).format('MM/DD')}`)
      }

      if (this.timeScale(new Date(d.PLAN_FD))){
        let planFDate = g.append('text')
        .attr('x', this.timeScale(new Date(d.PLAN_FD))).attr('y', 21)
        .style('font-size', 8).style('fill', '#9E9E9E')
        .attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text(`${__M(d.PLAN_FD).format('MM/DD')}`)
      }

      if (d.LEVEL == '1'){
        
        // if (d.P6_LV1 == 'P1' && d.P6_LV2 == 'PBL'){
        //   console.log(d.BL0_FD, d.PLAN_FD)
        // }

        if (d.P6_LV2 && !d.P6_LV3 && d.ITEM_DESC != 'Overall') {

          barText
          .style('cursor', 'pointer')
          .on('mouseover', function(){ 
            d3.select(this).transition().duration(100).style('opacity',.5) 
          })
          .on('mouseout', function(){ 
            d3.select(this).transition().duration(100).style('opacity',1) 
          })
          .on('click', () => {
            this.phases.filter(ph => ph.P6_LV1 == d.P6_LV1 && ph.P6_LV2 == d.P6_LV2 && ph.P6_LV3 == d.ITEM_DESC)
            .forEach(ph => {
              let key = `${ph.P6_LV1}${ph.P6_LV2}${ph.P6_LV3}`
              this.collapsed[key] = !this.collapsed[key]
              if(this.collFlag){
                if(!this.collapsed[key]) {
                  this.collapsedObj[key] = this.collapsed[key]
                }
              }
              if(this.collapsed[key]) {
                this.collapsedObj[key] = this.collapsed[key]
              }
            })
            //console.log(`barText click collapsed: `, this.collapsedObj)
            //for pdf
            this.selectedItems = { 
              LEVEL: 'BAR',
              P6_LV1: d.P6_LV1,
              P6_LV2: d.P6_LV2,
              P6_LV3: d.ITEM_DESC,
              FLAG : false,
              CALLFLAG : this.collFlag,
              DRAWFLAG : this.drawFlag,
              COLL : {...this.collapsedObj}
            }
            this.redraw()
          })

          barBaseline
          .style('cursor', 'pointer')
          .on('mouseover', function(){ 
            d3.select(this).transition().duration(100).style('opacity',.5) 
          })
          .on('mouseout', function(){ 
            d3.select(this).transition().duration(100).style('opacity',1) 
          })
          .on('click', () => {
            this.phases.filter(ph => ph.P6_LV1 == d.P6_LV1 && ph.P6_LV2 == d.P6_LV2 && ph.P6_LV3 == d.ITEM_DESC)
            .forEach(ph => {
              let key = `${ph.P6_LV1}${ph.P6_LV2}${ph.P6_LV3}`
              this.collapsed[key] = !this.collapsed[key]
              if(this.collapsed[key]) {
                if(this.collapsed[key]) {
                  this.collapsedObj[key] = this.collapsed[key]
                }
              }
            })
            //console.log(`barBaseline click collapsed: `, this.collapsedObj)
            //for pdf
            this.selectedItems = { 
              LEVEL: 'BAR',
              P6_LV1: d.P6_LV1,
              P6_LV2: d.P6_LV2,
              P6_LV3: d.ITEM_DESC,
              FLAG : false,
              CALLFLAG : this.collFlag,
              DRAWFLAG : this.drawFlag,
              COLL : {...this.collapsedObj}
            }
            this.redraw()
          })
          
          barCurrent
          .style('cursor', 'pointer')
          .on('mouseover', function(){ 
            d3.select(this).transition().duration(100).style('opacity',.5) 
          })
          .on('mouseout', function(){ 
            d3.select(this).transition().duration(100).style('opacity',1) 
          })
          .on('click', () => {
            this.phases.filter(ph => ph.P6_LV1 == d.P6_LV1 && ph.P6_LV2 == d.P6_LV2 && ph.P6_LV3 == d.ITEM_DESC)
            .forEach(ph => {
              let key = `${ph.P6_LV1}${ph.P6_LV2}${ph.P6_LV3}`
              this.collapsed[key] = !this.collapsed[key]
              if(this.collapsed[key]) {
                if(this.collapsed[key]) {
                  this.collapsedObj[key] = this.collapsed[key]
                }
              }
            })
            //console.log(`barCurrent click collapsed: `, this.collapsedObj)
            //for pdf
            this.selectedItems = { 
              LEVEL: 'BAR',
              P6_LV1: d.P6_LV1,
              P6_LV2: d.P6_LV2,
              P6_LV3: d.ITEM_DESC,
              FLAG : false,
              CALLFLAG : this.collFlag,
              DRAWFLAG : this.drawFlag,
              COLL : {...this.collapsedObj}
            }
            this.redraw()
          })
        }
      }
      // Add Y cordinate
      this.y_coordinate += this.height[type]
    },

    drawMileston(__d, type='milestone') {

      // draw milestone
      let g = this.svgGroup
      .append('g')
      .attr('transform', `translate(${this.margin.left}, ${this.y_coordinate + this.margin[type]})`)
      .attr('class', '__milestone_')

      __d.forEach((d, i) => {
        // milestone Plan
        if (d.MS_PLAN != d.MS_ACT) {
          let filter = __d.filter(f => {
            // 현 MS와 비슷한 기간의 MS가 있을 때, P6_LV5_SEQ 를 비교하여 더 큰놈이 그려지기
            return new Date(d.MS_PLAN) >= __M(new Date(f.MS_PLAN)).add(-15, 'days').toDate() &&
            new Date(d.MS_PLAN) <= __M(new Date(f.MS_PLAN)).add(15, 'days').toDate()
          })
          if (filter.length > 1) {
            let minSeq = Math.min.apply(Math, filter.map(m => m.P6_LV5_SEQ))
            let minSeqs = filter.map(m => m.P6_LV5_SEQ)

            let msPlan = g
            .append('g')
            .attr('id', 'item_indicator')
            .attr('transform', `translate(${this.timeScale(__M(new Date(d.MS_PLAN)).toDate())-3}, 0), rotate(-180) scale(.2)`)

            msPlan
            .append('path')
            .attr('d', this.pointerPath)
            .attr('fill', '#424242')
            .attr('stroke', '#424242')
            .attr('stroke-width', 1)
            .attr('opacity', minSeq == d.P6_LV5_SEQ ? 1 : 0.4)
            
            g
            .append('text')
            .attr('x', this.timeScale(__M(new Date(d.MS_PLAN)).toDate())-8)
            .attr('y', () => {
              let seq = minSeqs.findIndex(f => f == d.P6_LV5_SEQ)

              if (seq == 0) return -20
              else return -20 + (seq * -10)
            })
            .style('font-size', 9).style('fill', '#424242')
            .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
            .text(d.ITEM_DESC)
          }else {

            let msPlan = g
            .append('g')
            .attr('id', 'item_indicator')
            .attr('transform', `translate(${this.timeScale(__M(new Date(d.MS_PLAN)).toDate())-3}, 0), rotate(-180) scale(.2)`)
  
            msPlan
            .append('path')
            .attr('d', this.pointerPath)
            .attr('fill', '#424242')
            .attr('stroke', '#424242')
            .attr('stroke-width', 1)
            
            g
            .append('text')
            .attr('x', this.timeScale(__M(new Date(d.MS_PLAN)).toDate())-8).attr('y', -20)
            .style('font-size', 9).style('fill', '#424242')
            .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
            .text(d.ITEM_DESC)
          }
          
        }
        // milestone Actual
        if (d.MS_ACT) {
          let filter = __d.filter(f => {
            // 현 MS와 비슷한 기간의 MS가 있을 때, P6_LV5_SEQ 를 비교하여 더 큰놈이 그려지기
            return new Date(d.MS_ACT) >= __M(new Date(f.MS_ACT)).add(-15, 'days').toDate() &&
            new Date(d.MS_ACT) <= __M(new Date(f.MS_ACT)).add(15, 'days').toDate()
          })
          
          if (filter.length > 1){
            // SEQ 의 최소값을 찾아서 최소값만 opacity가 1, 나머지는 0.5 처리
            let minSeq = Math.min.apply(Math, filter.map(m => m.P6_LV5_SEQ))
            let minSeqs = filter.map(m => m.P6_LV5_SEQ)

            let msActual = g
            .append('g')
            .attr('id', 'item_indicator')
            .attr('transform', `translate(${this.timeScale(__M(new Date(d.MS_ACT)).toDate())-3}, 0), rotate(-180) scale(.2)`)

            msActual
            .append('path')
            .attr('d', this.pointerPath)
            .attr('fill', '#D50000')
            .attr('stroke', '#D50000')
            .attr('stroke-width', 1)
            .attr('opacity', minSeq == d.P6_LV5_SEQ ? 1 : 0.4)
            
            g
            .append('text')
            .attr('x', this.timeScale(__M(new Date(d.MS_ACT)).toDate())-8)
            .attr('y', () => {
              let seq = minSeqs.findIndex(f => f == d.P6_LV5_SEQ)

              if (seq == 0) return -20
              else return -20 + (seq * -10)
            })
            .style('font-size', 9).style('fill', '#D50000')
            .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
            .text(d.ITEM_DESC)
            
          }else {
            
            let msActual = g
            .append('g')
            .attr('id', 'item_indicator')
            .attr('transform', `translate(${this.timeScale(__M(new Date(d.MS_ACT)).toDate())-3}, 0), rotate(-180) scale(.2)`)

            msActual
            .append('path')
            .attr('d', this.pointerPath)
            .attr('fill', '#D50000')
            .attr('stroke', '#D50000')
            .attr('stroke-width', 1)
            
            g
            .append('text')
            .attr('x', this.timeScale(__M(new Date(d.MS_ACT)).toDate())-8).attr('y', -20)
            .style('font-size', 9).style('fill', '#D50000')
            .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
            .text(d.ITEM_DESC)
          }
          
        }
      })
      
    }
  }
}