// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {
    Draw_Charts() {
      let imgname = this.DataItems[0].IMGNAME

      let overallSummary = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0.5)`)
      
      overallSummary
      .append('image')
      .attr('opacity', 1)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Modules/${imgname}`)
      .attr('transform', `translate(25, 70.5) scale(.70)`)
      .attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight)


    },
  }
}