import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({

  }),


  methods: {
    
    Sailaway(mod, x) {

      let drawX = 40
      let drawY = 0
      var sortData = []

      sortData = this.sailAway.filter(f => f.MOD== mod)

      sortData.sort(function(a, b) { 
        return a.PAU > b.PAU ? -1 : a.PAU < b.PAU ? 1 : 0;
      })

        sortData.forEach((data,i) => {

        // Module images
        let sailaway = this.ModuleStatus
        .append('g')
        .attr('transform', `translate(${x},350)`)
          
          sailaway
          .append('image')
          .attr('x', 50).attr('y', drawY).attr('width', 60).attr('height', 30)
          .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TCO/PAUs/${data.PAU}.jpg`)

          sailaway 
          .append('rect')
          .attr('x', drawX + 75).attr('y', drawY).attr('rx', 3).attr('ry', 3).attr('width', 45).attr('height', 30).attr('fill', '#fff')
          .attr('stroke', () => {
            if (data.SA_ACTUAL) return '#44A9DF'; else return '#bcbcbc'
          })
          .attr('stroke-width', () => {
            if (data.SA_ACTUAL) return 0.7; else return 0.5
          })

          sailaway
          .append('rect')
          .attr('x', drawX + 125).attr('y', drawY).attr('rx', 3).attr('ry', 3).attr('width', 65).attr('height', 30).attr('fill', '#fff')
          .attr('stroke', () => {
            if (data.SA_ACTUAL) return '#44A9DF'; else return '#bcbcbc'
          })
          .attr('stroke-width', () => {
            if (data.SA_ACTUAL) return 0.7; else return 0.5
          })

          sailaway 
          .append('text')
          .attr('x', drawX + 98).attr('y', drawY + 15)
          .style('font-family', 'roboto').attr('font-size', 10).attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
          .attr('fill', () => {
            if (data.SA_ACTUAL) return '#44A9DF'; else return '#bcbcbc'
          })
          .text(`PAU ${data.PAU.substr(-1)}`)

          sailaway 
          .append('text')
          .attr('x', drawX + 157).attr('y', drawY + 10)
          .style('font-family', 'roboto').attr('font-size', 10).attr('fill', '#bcbcbc').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
          .text(data.SA_PLAN)

          sailaway 
          .append('text')
          .attr('x', drawX + 157).attr('y', drawY + 23)
          .style('font-family', 'roboto').attr('font-size', 10).attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
          .attr('fill', () => {
            if (data.SA_ACTUAL) return '#44A9DF'; else return '#bcbcbc'
          })
          .text(data.SA_ACTUAL)
          



          drawY += 35
      })
    },


  }
}
