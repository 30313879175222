import * as d3 from 'd3'
import __C from '../../../../../includes/primitives/_constant_'
export default {
  data: () => ({
    stage_Header: null,
    innerSvg: null,
    commissioningBox: null,
    commissioningList: null,

    CurrentStep: '',

    rawData: []
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {
    /*
      set_LocalValues()
      loadSvg()
    */

    set_LocalValues(){

      this.rawData = JSON.parse(JSON.stringify(this.DataItems))
      
      this.rawData.forEach(d => {
        d.story = []
        d.delay = []
        d.duration = []

        if(d.STORY !== '') {
          d.storyLen = d.STORY.split('/').length

          let arrStory = d.STORY.split('/')
          let arrDelay = d.DELAY.split('/')
          let arrDuration = d.DURATION.split('/')

          for(var i = 0; i < arrStory.length; i++) {
            let getStory = arrStory[i].split('-')
            d.story.push(getStory)

            let getDelay = arrDelay[i].split('-')
            let getDelayNo = getDelay.map(Number)
            d.delay.push(getDelayNo)

            let getduration = arrDuration[i].split('-')
            let getdurationNo = getduration.map(Number)
            d.duration.push(getdurationNo)
          }
        }
        
      })
    },



    loadSvg(selection, data) {
      return d3.xml(`${this.__HOST_NAME_RESOURCE}${data.url}`).then(source => {
        let svg_ = source.documentElement
        selection.node().append(svg_)

        svg_ = d3.select(svg_)
        .attr('x', data.x)
        .attr('y', data.y)

        return new Promise(resolve => {
          resolve(svg_)
        })
      })
    },

  }
}