import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {

  methods: {
    drawChart() {
      let values_ = this.DataItems

      
      let svg = this.svg

      let legendGroup = svg.append('g').attr('class', 'ogm_status_group_legend__').attr('transform', `translate(0, 0)`)
      let legendW = 52

      // Plan
      legendGroup.append('rect')
      .attr('x', 0).attr('y', 10).attr('rx', 3).attr('ry', 3).attr('width', legendW).attr('height', 20).attr('fill', '#EEEEEE')

      legendGroup.append('text').attr('x', legendW/2).attr('y', 23).attr('text-anchor', 'middle')
      .text('Plan')
      .style('font-size',10)
      .attr('fill', '#212121')

      // Actual
      legendGroup.append('rect')
      .attr('x', legendW+10).attr('y', 10).attr('rx', 3).attr('ry', 3).attr('width', legendW).attr('height', 20).attr('fill', '#66BB6A')

      legendGroup.append('text').attr('x', (legendW*3+20)/2).attr('y', 23).attr('text-anchor', 'middle')
      .text('Actual')
      .style('font-size',10)
      .attr('fill', '#fff')

      // Delta
      legendGroup.append('rect')
      .attr('x', (legendW+10)*2).attr('y', 10.5).attr('rx', 3).attr('ry', 3).attr('width', legendW).attr('height', 20).attr('fill', 'Transparent').attr('stroke', '#ccc').attr('stroke-width',0.5)

      legendGroup.append('text').attr('x', (legendW*5+40)/2).attr('y', 23).attr('text-anchor', 'middle')
      .text('Delta')
      .style('font-size',10)
      .attr('fill', 'red')

      let group = svg.append('g').attr('class', 'ogm_status_group__').attr('transform', `translate(0.5, 40)`)

      values_.find((v) => {
        let g = group.append('g').attr('class', `${v.DISC_DESCR}_status__`).attr('transform', `translate(0, 6)`)
        const imageBox = 180
        // Imgae box
        g.append('rect')
        .attr('x', 0).attr('y', 0)
        .attr('rx', 3).attr('ry', 3).attr('width', imageBox).attr('height', imageBox)
        .attr('fill', v.DELTA > 0 ? '#4CAF50' : v.DELTA === 0 ? '#fff' : '#F44336')
        .attr('opacity', '0.7')

        if (v.ACTUAL !== 0) {
          g.append('rect')
          .attr('x', 0).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', imageBox)
          .attr('height', parseInt(imageBox - (v.ACTUAL < 0 ? v.ACTUAL * -1 : v.ACTUAL) / 100 * imageBox))
          .attr('fill', '#fff')
          .attr('opacity', '0.6')
        } else {
          g.append('rect')
          .attr('x', 0).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', imageBox)
          .attr('height', imageBox)
          .attr('fill', '#fff')
          .attr('opacity', '0.6')
        }

        g.append('image')
        .attr('id', `omg_img_${v.DISC_DESCR.toLowerCase()}`)
        .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Modules/LQDashboard/icons/${v.DISC_DESCR.toLowerCase()+'_scale'}.png`)
        .attr('transform','translate(0,0)')
        
        // Plan
        g.append('rect')
        .attr('x', 10).attr('y', imageBox+30).attr('rx', 3).attr('ry', 3).attr('width', imageBox-20).attr('height', 27).attr('fill', '#EEEEEE')

        g.append('text').attr('x', imageBox/2).attr('y', imageBox+49).attr('text-anchor', 'middle')
        .text(v.PLAN != null ? v.PLAN.toFixed(2) + '%' : 0 + '%')
        .style('font-size',16)
        .attr('fill', '#212121')

        // Actual
        g.append('rect')
        .attr('x', 10).attr('y', imageBox+70).attr('rx', 3).attr('ry', 3).attr('width', imageBox-20).attr('height', 27).attr('fill', '#66BB6A')

        g.append('text').attr('x', imageBox/2).attr('y', imageBox+89).attr('text-anchor', 'middle')
        .text(v.ACTUAL != null ? v.ACTUAL.toFixed(2) + '%': 0 + '%')
        .style('font-size',16)
        .attr('fill', '#fff')

        // Delta
        g.append('rect')
        .attr('x', 10.5).attr('y', imageBox+110.5).attr('rx', 3).attr('ry', 3).attr('width', imageBox-20).attr('height', 27).attr('fill', 'Transparent').attr('stroke', '#ccc')

        if (v.DELTA && v.DELTA !== 0) {

          g.append('polygon')
          .attr('transform','translate(32,52)')
          .attr('points', v.DELTA > 0 ? '14 257, 19 247, 24 257' : '14 247, 24 247, 19 257')
          .attr('fill', v.DELTA > 0 ? 'green' : v.DELTA === 0 ? '#ccc' : 'red')
        }

        g.append('text').attr('x', imageBox/2).attr('y', imageBox+129).attr('text-anchor', 'middle')
        .text(v.DELTA == null ? 0 + '%' : v.DELTA.toFixed(2) + '%')
        .style('font-size',16)
        .attr('fill', v.DELTA > 0 ? 'green' : v.DELTA === 0 ? '#ccc' : 'red')


      })

    },
    
  }
}