import * as d3 from 'd3'
import Data from '../primitives/donutProps'

// ------------------------------------------------------------------------------- Global

export default {
  methods: {
    drawDonuts() {
      if(!this.DataItems || this.DataItems.length === 0) return

      let arcProps = {
        angleSize: 360,
        angleStart: 0,
        bColor: '#F4F5F5',
        stroke: 8,
        Ra: 38
      }
      let values_ = this.DataItems[0]

      let punchType = this.DataItems.map(d => d.CATEGORY)[0]
      let donutGroup = this.svg
      .append('g')
      .attr('class', '_donut_group__for_all')
      .attr('transform', `translate(50.5, 70.5)`)

      donutGroup
      .append('rect')
      .attr('transform', 'translate(-30,-60)')
      .attr('width',320).attr('height',130).attr('rx',5).attr('ry',5).attr('stroke','#ccc').attr('stroke-width',0.3).attr('fill', '#ffffff')
      
      donutGroup
      .append('rect')
      .attr('transform', 'translate(92,-70)')
      .attr('width',66).attr('height',20).attr('fill', '#fff')
      
      donutGroup
      .append('text')
      .attr('transform', 'translate(117,-55)')
      .style('font-family','roboto').style('font-size',12).attr('text-anchor', 'middle').attr('fill', '#424242')
      .text('Punch')

      donutGroup
      .append('text')
      .attr('transform', 'translate(145,-55)')
      .style('font-family','roboto').style('font-size',12).attr('text-anchor', 'middle').attr('fill', '#424242')
      .text(punchType)
      
      Data.attrs.forEach((attr, i) => {
        let arc = d3.arc()
        
        .outerRadius(d => d.Ra)
        .innerRadius(d => d.Ra - d.stroke)
        .startAngle(d => d.angleStart * Math.PI / 180)
        .endAngle(d => d.angleEnd || d.angleStart * Math.PI / 180)
        let x__ = Data.width * i + Data.gab
        let donut_ = donutGroup
        .append('g')
        .attr('class', `_donut_chart__${attr.refColumn.toLowerCase()}`)
        .attr('transform', `translate(${x__}, 0)`)

        // Draw Background Donut Chart
        donut_
        .append('circle')
        .attr('cx', 0).attr('cy', 0).attr('r', arcProps.Ra-3)
        .attr('stroke', arcProps.bColor).attr('stroke-width', arcProps.stroke)
        .attr('fill', '#fff')

        let circleRate = values_['TOTAL'] ? (values_[attr.refColumn] || 0) / values_['TOTAL'] : 0

        // Draw Foreground Donut Chart
        donut_
        .datum(arcProps)
        .append('path')
        .attr('d', arc)
        .attr('fill', attr.fColor)
        .attr('opacity', 0.5)
        .transition()
        .duration(1500)
        .attrTween('d',(d) => {
          let endAngle_ = ((d.angleSize * circleRate) + d.angleStart) * Math.PI / 180
          let interpolate = d3.interpolate(d.angleStart * Math.PI / 180, endAngle_)
          return function(t) {
            d.angleEnd = interpolate(t)
            return arc(d);
          }
        })

        // console.log(values_['OPEN'])
        // console.log(attr.refColumn)
        donut_
        .append('text')
        .attr('transform', `translate(2, 11)`)
        .style('font-family', 'roboto')
        .style('font-size', 11)
        .style('fill', attr.tColor)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(values_[attr.refColumnProg] ? values_[attr.refColumnProg]+ '%' : '' )
        .text(values_[attr.refColumnProg] ? '('+values_[attr.refColumnProg]+'%'+')'  : '' )

        donut_
        .append('text')
        .attr('transform', `translate(0, ${!values_[attr.refColumnProg] ? 1 : -2 })`)
        .style('font-family', 'roboto')
        .style('font-size', 12)
        .style('fill', attr.tColor)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(values_[attr.refColumn] || 0)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(50)
          .style('opacity', 0.5)
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(50)
          .style('opacity', 1)
        })
        .on('click', () => { 
          // console.log(values_[attr.refColumn] )
          let request = {CATEGORY : values_.CATEGORY, STATUS_CODE: attr.refColumn == 'OPEN' ?'N': attr.refColumn == 'CLOSED' ? 'C' : ''}
          let request_ = this.getRequestColumnProps('request', request, this.ColumnProps)
          this.$emit('request-action', request_)
        })

        // Legend
        donut_
        .append('text')
        .attr('transform', `translate(0, 52)`)
        .style('font-family', 'roboto')
        .style('font-size', 11)
        .style('fill', attr.tColor)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(attr.text)
      })
    }
  }
}
