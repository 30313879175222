import * as d3 from 'd3'
import Data from '../primitives/donutProps'

export default {
  data: () => ({
    refColumn: 'TEST_PACKAGE_STATUS'
  }),
  methods: {
    drawDonuts() {
      if(!this.Queries.SQL5 || this.Queries.SQL5.length === 0) return

      d3.select(`._donut_group__for_all`).remove()

      let arcProps = {
        angleSize: 360,
        angleStart: 0,
        bColor: '#F4F5F5',
        stroke: 8,
        Ra: 30
      }
      let values_ = this.Queries.SQL5[0]

      let donutGroup = this.SkylineCharts
      .append('g')
      .attr('class', '_donut_group__for_all')
      .attr('transform', `translate(70, 135)`)


      Data.attrs.forEach((attr, i) => {
        let arc = d3.arc()
        .outerRadius(d => d.Ra)
        .innerRadius(d => d.Ra - d.stroke)
        .startAngle(d => d.angleStart * Math.PI / 180)
        .endAngle(d => d.angleEnd || d.angleStart * Math.PI / 180)


        // let x__ = Data.width * i + (i > 0 ? Data.gab : 0)
        let x__ = Data.width * i
        let donut_ = donutGroup 
        .append('g')
        .attr('class', `_donut_chart__${attr.refColumn.toLowerCase()}`)
        .attr('transform', `translate(${x__}, 0)`)

        // Draw Background Donut Chart
        donut_
        .append('circle')
        .attr('cx', 0).attr('cy', 0).attr('r', arcProps.Ra-3)
        .attr('stroke', arcProps.bColor).attr('stroke-width', arcProps.stroke)
        .attr('fill', `url(#${attr.fill})`)

        let circleRate = values_[attr.refColumn] / values_['TOTAL']

        // Draw Foreground Donut Chart
        donut_
        .datum(arcProps)
        .append('path')
        .attr('d', arc)
        .attr('fill', attr.fColor)
        .attr('opacity', 0.8)
        .transition()
        .duration(1500)
        .attrTween('d',(d) => {
          let endAngle_ = ((d.angleSize * circleRate) + d.angleStart) * Math.PI / 180
          let interpolate = d3.interpolate(d.angleStart * Math.PI / 180, endAngle_)
          return function(t) {
            d.angleEnd = interpolate(t)
            return arc(d);
          }
        })
        

        donut_
        .append('text')
        .attr('transform', `translate(0, 1)`)
        .style('font-family', 'roboto')
        .style('font-size', 13)
        .style('fill', attr.tColor)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(values_[attr.refColumn] ? values_[attr.refColumn] : 0)

        // Legend
        donut_
        .append('text')
        .attr('transform', `translate(0, 42)`)
        .style('font-family', 'roboto')
        .style('font-size', 10)
        .style('fill', '#757575')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(attr.text1)

        donut_
        .append('text')
        .attr('transform', `translate(0, 52)`)
        .style('font-family', 'roboto')
        .style('font-size', 10)
        .style('fill', '#757575')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(attr.text2)
        
        donut_.on('click', (d, i, a) => {
          if (!values_[attr.refColumn] || values_[attr.refColumn] == 0) return

          if (attr.codeValue == 'Total') {
            // Total Init Donuts
            attr.on = !attr.on

            let attrs = []

            if (!attr.on) {
              // Total false, all off
              Data.attrs.forEach(attr => {
                attr.on = false
                d3.select(`._donut_chart__${attr.refColumn.toLowerCase()}`).style('opacity', 0.3)
                attrs.push(attr.codeValue)
                this.filtering[this.refColumn] = []
              })
            } else {
              // Total true, all on
              Data.attrs.forEach(attr => {
                attr.on = true
                d3.select(`._donut_chart__${attr.refColumn.toLowerCase()}`).style('opacity', 1)
                attrs.push(attr.codeValue)
              })

              this.filtering[this.refColumn] = []
              this.filtering[this.refColumn] = attrs
            }

          } else {
            attr.on = !attr.on
            if(attr.on) d3.select(a[i]).style('opacity', 1)
            else d3.select(a[i]).style('opacity', 0.3)
    
            this.setFilter('multi', this.refColumn, attr.codeValue)
            // this.search(false)
            
          }

          // console.log(this.filtering)
          this.Chart_filtering()

        })
        .on('mouseover', (_, i, a) => { d3.select(a[i]).style('cursor', 'pointer') })

        if(attr.on) this.setFilter('multi', this.refColumn, attr.codeValue)
      })
    },
  }
}