export default {
  data: () => ({
    // dropDownBox: [
    //   {CODE:'Steel Cutting'       , SEQ:1,},
    //   {CODE:"Assembly"            , SEQ:2,},
    //   {CODE:"Skidway"             , SEQ:3,},
    //   {CODE:"Outfitting"          , SEQ:4,},
    //   {CODE:"Dry Dock"            , SEQ:5,},
    //   {CODE:"B&P"                 , SEQ:6,},
    //   {CODE:"Living Quarter"      , SEQ:7,},
    //   {CODE:"Module Fitting Area" , SEQ:8,},
    //   {CODE:"Nantong Yard"        , SEQ:9,},
    //   {CODE:"Honghua Yard"        , SEQ:10},
    // ],
    codeDescr: [
      {DESCR:'Workplace and Site Condition', SEQ:1,},
      {DESCR:'PPE'                         , SEQ:2,},
      {DESCR:'Hazards and Activities'      , SEQ:3,},
      {DESCR:'HSE Management System'       , SEQ:4,},
    ],
    // month: [
    //   {MONTH:'Jan'},
    //   {MONTH:'Feb'},
    //   {MONTH:'Mar'},
    //   {MONTH:'Apr'},
    //   {MONTH:'May'},
    //   {MONTH:'Jun'},
    //   {MONTH:'Jul'},
    //   {MONTH:'Aug'},
    //   {MONTH:'Sep'},
    //   {MONTH:'Oct'},
    //   {MONTH:'Nov'},
    //   {MONTH:'Dec'},
    // ]
  })
}