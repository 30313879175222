import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  data: () => ({
    
  }),

  methods: {

    Canvas_Overall_Division_Lv1() {
      
      this.CANVAS = this.svg.append('svg')

      this.CANVAS
      .attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight)
      .style('opacity', 1)

      let style = null
      
      // Block Status
      this.BlockStatus_Coordinate.filter(f=> f.Lv1 !== 'PROJECT' && f.Lv2 == 'OVERALL').forEach((prog,sn) => {
        let itemSet = []
        style = {
          x: prog.x,
          y: prog.y,
          headTitle: prog.NAME,
          prog: this.Queries.MasterProg.find(f=> f.Lv1 == prog.Lv1 && f.Lv2 == prog.Lv2),
        }

        this.Status.forEach((d,i) => {
          itemSet.push({
            name: d.title,
            total: this.dataSet.filter(f => f.Lv1 == prog.Lv1 && f.EREC == 'Y').length,
            actual: this.dataSet.filter(f => f.Lv1 == prog.Lv1 && f.EREC == 'Y' && f.STATUS == d.code).length,
            bColor: this.Status[i].bColor,
            opacity: this.Status[i].opacity,
          })
        })
        this.blockStatus(itemSet)
        this.Bar_Quantity_01(this.CANVAS, itemSet, style, sn) // <---- Chart Library
      })
      
      this.drawProgress(this.CANVAS, 'Hull & Top')
    },
  }
}