import * as d3 from 'd3'

export default {
  methods: {
    
    setEvent_Topside() {

      // Goto_AFT_Division_Lv3
      // ---------------------------------------------------------------------------------------------------------------------
      this.Goto_Topside_ModuleDivision = (selection, _polygon, _mod) => {
        selection
        .on('mouseover', () => {
          if(this.stage != this.STG_Activate) return
          d3.select(`#Mask_Mod_${_mod}`).transition().style('opacity',0.5)
        })
        .on('mouseout', () => {
          if(this.stage != this.STG_Activate) return
          d3.select(`#Mask_Mod_${_mod}`).transition().style('opacity',0)
        })
        .on('click', () => {
          if(this.stage != this.STG_Activate) return

          // Remove SVG
          this.removeSvg(this.CANVAS, 1000)

          // Hidden
          this.hiddenGroupID('#Menu_Back', 0, 10)
          this.hiddenGroupID(`#Mask_Mod_${_mod}`, 0, 10)

          // Explode
          this.TOPSIDE.filter(f => f.Lv3 == _mod).forEach(d => {
            d3.select(`#Blk_${d.Blk}`)
            .transition().duration(1000).attr('transform', `translate(${d.x2}, ${d.y2}) scale(0.5)`)
          })
  
          setTimeout(() => {

            let coordinate = this.Coordinate.find(f=> f.l2 == _mod)

            // Display
            this.draw_BlockCode_Module(_mod, coordinate.x, coordinate.y)

            this.Lev1 = 'TOPSIDE'
            this.stage = this.STG_Lv4_Block_Division
            let dStage = {
              stage: this.STG_Lv4_Block_Division,
              mask : _polygon,
              level: _mod
            }
            this.selectedItems.push(dStage)            
            this.fncName = 'Goto_Topside_ModuleDivision'
            this.StageCode() // AREA / STAGE
            this.displayGroupID(`#Menu_Back`, 0, 200, 1)
          }, 1000);
          
        })
      }


    },

  }
}