import * as d3 from 'd3'

export default {
  methods: {
    removeSvg(selection) {
      selection
      .transition().duration(1000).style('opacity', 0)

      setTimeout(() => {
        selection.html()
        selection.remove()
        selection = null
      }, 1000);
    },
    displayGroupID(_id, _delay, _duration, _opacity) {
      d3.select(`${_id}`)
      .style('opacity', 0)
      .style('visibility', 'visible')
      .transition()
      .delay(_delay)
      .duration(_duration)
      .style('opacity', _opacity)
    },
    hiddenGroupID(_id, _delay, _duration) {
      d3.select(`${_id}`)
      .transition()
      .delay(_delay)
      .duration(_duration)
      .style('opacity', 0)

      d3.select(`${_id}`)
      .transition()
      .delay(_delay + _duration)
      .style('visibility', 'hidden')
    },
    StageCode() {
      d3.select('#stage').text(this.stage)
    },


    
  }
}
