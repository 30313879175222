import * as d3 from 'd3'

export default {

  methods: {
    /*
      load_Lev3()
      async load_Photos()
      draw_PhotoTitle()
      draw_SEI_List()
      unload_Lev3()
    */
    load_Lev3() {

      // Calculate the scale value and coordinates in 'set_LocalValues.js'
      let area_ = this.Areas.find(f => f.code == this.route.to.area)
      
      d3.select('#HeaderTitle_Area')
      .transition().duration(500)
      .attr('fill', '#44A9DF')
      .text(`${area_.code} - ${area_.name}`)

      this.innerSvg = this.svg.append('svg')
      this.setPriority()
      
      this.Areas.forEach(d=> {
        if(d.code == area_.code) {
          d3.select(`#PLAN-${d.code}`)
          .transition().duration(300)
          .style('opacity', .7)
        }
        else {
          d3.select(`#PLAN-${d.code}`)
          .transition().duration(300)
          .style('opacity', 0)
        }
      })

      // After loading the SVG, perform initialization
      this.loadSvg(
        this.innerSvg, {
        x   : 0,
        y   : 0,
        url : `/China1/Area_Summary/svg/${this.route.to.area}.svg`
      }).then(() => {
        
        this.SEIs.filter(f=> f.AREA == this.route.to.area).forEach((d,i)=> {
          d3.select(`#BOX-${d.SEI}`).transition().duration(10).style('opacity', 0)
          d3.select(`#CODE-${d.SEI}`).transition().duration(10).style('opacity', 0)
          d.x =  d3.select(`#LINE-${d.SEI}`).select('line').attr('x1')
          d.y =  d3.select(`#LINE-${d.SEI}`).select('line').attr('y1')
          
          if(d.XY == 2) {
            d.x = d.x - 130
            d.y = d.y - 9
          }
          if(d.XY == 3) {
            d.y = d.y - 45
            d.y = d.y - 9
          }
          if(d.XY == 4) {
            d.x = d.x - 130
            d.y = d.y - 45 - 9
          }
          
        })
        this.draw_SEI_List()
        this.status_MDR()
        
      })
    },

    draw_SEI_List() {
      let sei_List = this.innerSvg
      .append('g')
      .attr('id', 'sei_List_group')
      .attr('transform', `translate(10, 320)`)

      this.SEIs.filter(f=> f.AREA == this.route.to.area).forEach((d,i)=> {

        sei_List.append('g')
        .attr('id', `SEI-${d.SEI}`)
        .attr('transform', `translate(0, ${i*22})`)
        .style('cursor', 'pointer')
        .call(this.call_Link_SEI, d)
        
        .append("rect")
        .attr('x', 12).attr('y', -12)
        .attr('width', 45).attr('height', 16).attr('opacity', 0.7)
        .style('fill', 'url(#PHOTO)' )

        d3.select(`#SEI-${d.SEI}`)
        .append('text')
        .attr('x', 16).attr('y', 0)
        .attr('fill', '#000').attr('font-size', 11)
        .text(d.SEI)

        d3.select(`#SEI-${d.SEI}`)
        .append('text')
        .attr('x', 63).attr('y', 0)
        .attr('fill', '#757575').attr('font-size', 10)
        .attr('font-family', 'roboto')
        .text(d.SEI_ENG)
        .call(this.draw_gmx_TextBox_v1, {
          selection: sei_List, 
          id: `#SEI-${d.SEI}`,
          x: 0,
          y: 0,
          padding: {left: 0, right: 5, top: 4, bottom: 4},
          color: '#fff',
          border:{ color: '#333', size: 0, rx: 0, ry: 0 },
          opacity: 1
        })

      })
    },

    status_MDR() {
      this.SEIs.filter(f=> f.AREA == this.route.to.area).forEach((d,i)=> {

        this.innerSvg
        .append('g')
        .attr('id', `MDR-${d.SEI}`)
        .attr('transform', `translate(${d.x}, ${d.y})`)
        .style('cursor', 'pointer')
        .call(this.call_Link_SEI, d)

        d3.select(`#MDR-${d.SEI}`)
        .append('rect')
        .attr('x', 0).attr('y', 9)
        .attr('width', 130).attr('height', 45)
        .attr('fill', '#fff').attr('stroke', '#757575').attr('stroke-width', 0.2)

        d3.select(`#MDR-${d.SEI}`)
        .append('text')
        .attr('x', 4).attr('y', 12)
        .attr('fill', '#000').attr('font-size', 11)
        .attr('font-family', 'roboto')
        .text(d.SEI)
        .call(this.draw_gmx_TextBox_v1, {
          selection: this.innerSvg, 
          id: `#MDR-${d.SEI}`,
          boxID:  `MDR-CODE-${d.SEI}`,
          x: 0,
          y: 0,
          padding: {left: 5, right: 4, top: 2, bottom: 1.5},
          color: '#fff',
          border:{ color: '#848484', size: 0.3, rx: 0, ry: 0 },
          opacity: 1
        })

        //title
        d3.select(`#MDR-${d.SEI}`)
        .append('text')
        .attr('x', 4).attr('y', 30).attr('fill', '#757575').attr('font-size', 8.5)
        .text(this.styles.LEV3_TITLE)

        //%
        d3.select(`#MDR-${d.SEI}`)
        .append('text')
        .attr('id', `progress-${i}`)
        .attr('x', 4).attr('y', 45).attr('fill', '#757575').attr('font-size', 10)
        .text(0 + '%')

        d3.select(`#MDR-${d.SEI}`)
        .append('rect')
        .attr('x', 35).attr('y', 36).attr('width', 65).attr('height', 11).attr('fill', '#F4F5F5') // ---------------

        //bar actual rect
        d3.select(`#MDR-${d.SEI}`)
        .append('rect')
        .attr('id', `bar-${i}`)
        .attr('x', 35).attr('y', 36).attr('width', 0).attr('height', 11).attr('fill', '#83D2F5')

        //bar actual text
        d3.select(`#MDR-${d.SEI}`)
        .append('text')
        .attr('id', `actual-${i}`)
        .attr('x', 37).attr('y', 45).attr('fill', '#757575').attr('font-size', 10)
        .text(0)

        //bar remain text
        d3.select(`#MDR-${d.SEI}`)
        .append('text')
        .attr('id', `remain-${i}`)
        .attr('x', 102).attr('y', 45).attr('fill', '#757575').attr('font-size', 10) // ---------------
        .text(d.TOTAL)
        
      })

      //animate
      this.SEIs.filter(f=> f.AREA == this.route.to.area).forEach((d,i)=> {

        d3.select(`#bar-${i}`)
        .transition()
        .ease(d3.easeLinear)
        .duration(1500)
        .attr('width', 65 * (d.PRO/100)) // -------------------------

        d3.select(`#progress-${i}`)
        .transition()
        .ease(d3.easeLinear)
        .duration(1500)
        .tween("text", function() {
          const interpolator = d3.interpolateNumber(0, d.PRO)
          return function(t) {
            d3.select(`#progress-${i}`).text(`${interpolator(t).toFixed(1)}%`)
          }
         })

        d3.select(`#actual-${i}`)
        .transition()
        .ease(d3.easeLinear)
        .duration(1500)
        .tween("text", function() {
          const interpolator = d3.interpolateNumber(0, d.ACTUAL)
          return function(t) {
            d3.select(`#actual-${i}`).text(Math.round(interpolator(t)))
          }
         })

         d3.select(`#remain-${i}`)
        .transition()
        .ease(d3.easeLinear)
        .duration(1500)
        .tween("text", function() {
          const interpolator = d3.interpolateNumber(d.TOTAL, d.REMAIN)
          return function(t) {
            d3.select(`#remain-${i}`).text(Math.round(interpolator(t)))
          }
         })
      })
    },

    unload_Lev3() {
      return new Promise(resolve => {
        setTimeout(() => {
          this.innerSvg.remove()
          resolve() 
        }, 350)
      })
    },
    
  }
}