import Defs from '../../../../../includes/primitives/colorDefs'
import Filter from '../../../../../includes/primitives/Filter_Defs'

export default {
  data: () => ({

    Legend: 
    [ 
      { code: 'A', name: 'MC_MEET',       color: '#4DC499', },
      { code: 'B', name: 'SEI',           color: '#4DC499', },
      { code: 'C', name: 'MC_NOT',        color: '#E29FE2', },
      { code: 'D', name: 'PRE_MC',        color: '#F4E12C', },
      { code: 'E', name: 'MILESTONE',     color: '#51B8F2', },
     
    ],

    Schedule: 
    [ 
      { no: 1,  code: '2023-09', act: 0, x: 0,},
      { no: 2,  code: '2023-10', act: 1, x: 450,},
      { no: 3,  code: '2023-11', act: 1, x: 550},
      { no: 4,  code: '2023-12', act: 2, x: 600},
      { no: 5,  code: '2024-01', act: 2, x: 750},
      { no: 6,  code: '2024-02', act: 3, x: 900},
      { no: 7,  code: '2024-03', act: 0, x: 0},
      { no: 8,  code: '2024-04', act: 2, x: 1100},
      { no: 9,  code: '2024-05', act: 0, x: 0},
      { no: 10, code: '2024-06', act: 0, x: 0},
      { no: 11, code: '2024-07', act: 0, x: 0},
      { no: 12, code: '2024-08', act: 0, x: 0},
      { no: 13, code: '2024-09', act: 0, x: 0},
      { no: 14, code: '2024-10', act: 0, x: 0},
      { no: 15, code: '2024-11', act: 0, x: 0},
      { no: 16, code: '2024-12', act: 0, x: 0},
      { no: 17, code: '2025-08', act: 0, x: 0},
    ],
  }),

  methods: {

    set_Gradients() {

      let defs = this.svg.append('defs')

      let filter = defs.append('filter')
      .attr('id', 'dropshadow')
      filter.append('feGaussianBlur').attr('in', 'SourceAlpha').attr('stdDeviation', 3).attr('result', 'blur')
      filter.append('feOffset').attr('in', 'blur').attr('dx', .3).attr('dy', .3) .attr('result', 'offsetBlur')
      filter.append('feFlood').attr('in', 'offsetBlur').attr('flood-color', '#727272').attr('flood-opacity', '0.3').attr('result', 'offsetColor')
      filter.append('feComposite').attr('in', 'offsetColor').attr('in2', 'offsetBlur').attr('operator', 'in').attr('result', 'offsetBlur')


      let header = defs.append('linearGradient')
      .attr('id', `menu_box`).attr('x1', 0.5).attr('x2', 0.5).attr('y1', 0).attr('y2', 1)

      header.append('stop').attr('stop-color', '#F4F5F6').attr('offset', '0').attr('stop-opacity', 1)
      header.append('stop').attr('stop-color', '#F1F2F3').attr('offset', '0.5').attr('stop-opacity', 1)
      header.append('stop').attr('stop-color', '#E9EAEA').attr('offset', '0.8').attr('stop-opacity', 1)
      header.append('stop').attr('stop-color', '#E1E1E1').attr('offset', '1').attr('stop-opacity', 1)

      let legendA = defs.append('linearGradient')
      .attr('id', `MC_MEET`).attr('x1', 0.5).attr('x2', 0.5).attr('y1', 0).attr('y2', 1)
      legendA.append('stop').attr('stop-color', '#D7E549').attr('offset', '0').attr('stop-opacity', 1)
      legendA.append('stop').attr('stop-color', '#9BCF5C').attr('offset', '1').attr('stop-opacity', 1)

      let legendB = defs.append('linearGradient')
      .attr('id', `SEI`).attr('x1', 0.5).attr('x2', 0.5).attr('y1', 0).attr('y2', 1)
      legendB.append('stop').attr('stop-color', '#4DC499').attr('offset', '0').attr('stop-opacity', 1)
      legendB.append('stop').attr('stop-color', '#128255').attr('offset', '1').attr('stop-opacity', 1)

      let legendC = defs.append('linearGradient')
      .attr('id', `MC_NOT`).attr('x1', 0.5).attr('x2', 0.5).attr('y1', 0).attr('y2', 1)
      legendC.append('stop').attr('stop-color', '#E7B8E8').attr('offset', '0').attr('stop-opacity', 1)
      legendC.append('stop').attr('stop-color', '#B562C4').attr('offset', '1').attr('stop-opacity', 1)

      let legendD = defs.append('linearGradient')
      .attr('id', `PRE_MC`).attr('x1', 0.5).attr('x2', 0.5).attr('y1', 0).attr('y2', 1)
      legendD.append('stop').attr('stop-color', '#F9EB85').attr('offset', '0').attr('stop-opacity', 1)
      legendD.append('stop').attr('stop-color', '#B5A31F').attr('offset', '1').attr('stop-opacity', 1)

      let legendE = defs.append('linearGradient')
      .attr('id', `MILESTONE`).attr('x1', 0.5).attr('x2', 0.5).attr('y1', 0).attr('y2', 1)
      legendE.append('stop').attr('stop-color', '#73CAEF').attr('offset', '0').attr('stop-opacity', 1)
      legendE.append('stop').attr('stop-color', '#0780AA').attr('offset', '1').attr('stop-opacity', 1)
    }
  }
}