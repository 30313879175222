import * as d3 from 'd3'
import __C from '../../../../../../../../../src/primitives/_constant_'

export default {
  data: () => ({
    innerSvg: null,
    cmmSharedArea: null,
    cmmHeaderArea: null,
    cmmTableFilteredResult: null,

    selectedItems: null,

    stageCode: [],
    dataset: {
      eqList: [],
      stageSummary: {
        main: {},
        package: [],
        area: [],
        equipment: [],
      }
    },
    displayDataFilter: {
      tag         : 'tagno', // tagno | tagdesc | none
      searchOption: 'TAG' , // Column Name: TAG | TAG_DESCR
      searchInput : ''    ,
      p2w         : { type: 'CRITICAL', checked: false, colName: 'CRITICAL'   , value: 'P2W'      },
      w2w         : { type: 'CRITICAL', checked: false, colName: 'CRITICAL'   , value: 'W2W'      },
      nmr         : { type: 'CRITICAL', checked: false, colName: 'CRITICAL'   , value: 'NMR'      },
      mr          : { type: 'STAGE'   , checked: false, colName: 'STATUS_CODE', value: 'MR'       },
      loa         : { type: 'STAGE'   , checked: false, colName: 'STATUS_CODE', value: 'LOA'      },
      po          : { type: 'STAGE'   , checked: false, colName: 'STATUS_CODE', value: 'PO'       },
      delivery    : { type: 'STAGE'   , checked: false, colName: 'STATUS_CODE', value: 'DELIVERY' },
      install     : { type: 'STAGE'   , checked: false, colName: 'STATUS_CODE', value: 'INSTALL'  },
      not         : { type: 'STAGE'   , checked: false, colName: 'STATUS_CODE', value: 'NOT'      },
    },
    scale: .5,
    scaleW: 0,
    scaleH: 0,
    zoom: {
      in: { x: 0, y: 0 },
      out: { x: 0, y: 0 }
    },
    reqTitle: {
      summary: {
        TOTAL: 'Equipment Summary - Total',
        P2W  : 'Equipment Summary - Critical. Over 2 weeks from ROS',
        W2W  : 'Equipment Summary - Critical. Within 2 weeks from ROS',
        NMR  : 'Equipment Summary - Critical. Not the MR Item',
        NPO  : 'Equipment Summary - Critical. Not the PO Item',
      }
    },
    reqData: {
      title: '',
      persistent: true,
      overlay: false,
    }
  }),
  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0 
      )
    },
  },
  methods: {
    setDefaultValues(){
      d3.selection.prototype.moveToFront = function() {
        return this.each(function(){
          this.parentNode.appendChild(this)
        })
      }
      d3.selection.prototype.moveToBack = function() {  
        return this.each(function() { 
            var firstChild = this.parentNode.firstChild; 
            if (firstChild) { 
                this.parentNode.insertBefore(this, firstChild); 
            } 
        })
      }

      this.zoom.in.x = -Math.round(this.Canvas.CanvasWidth * this.scale / 2)
      this.zoom.in.y = -Math.round(this.Canvas.CanvasHeight * this.scale / 2)
      this.zoom.out.x = Math.round(this.Canvas.CanvasWidth * this.scale / 2)
      this.zoom.out.y = Math.round(this.Canvas.CanvasHeight * this.scale / 2)

      this.scaleW = this.Canvas.CanvasWidth * this.scale
      this.scaleH = this.Canvas.CanvasHeight * this.scale
      
      // Drow-Shadows ---------------------------
      this.svg
      .append('defs')
      .append('filter')
      .attr('id', 'drop_shadow_el')
      .append('feDropShadow')
      .attr('dx', 1)
      .attr('dy', 1)
      .attr('stdDeviation', 1)
      .attr('flood-opacity', .07)

      this.svg
      .append('defs')
      .append('filter')
      .attr('id', 'drop_shadow_popup')
      .append('feDropShadow')
      .attr('dx', 2)
      .attr('dy', 2)
      .attr('stdDeviation', 3)
      .attr('flood-opacity', .25)
      // ----------------------------------------

      this.stageCode = [ ...this.eqStyle.Status ]
      this.stageCode.splice(-1)

      /**
       * *** NOTICE ***
       * When using the FOR JSON PATH Statement in the SQL query, 
       * type Object is not parsed in JS Object while type Array does by ATAGlance Framework.
       * So, only the "this.DataItems[0].stageSummaryMain" should be parsed by JSON.parse().
       */
      this.dataset.eqList = this.DataItems[0].eqList
      this.dataset.stageSummary.main = JSON.parse(this.DataItems[0].stageSummaryMain)
      this.dataset.stageSummary.package = this.DataItems[0].stageSummaryPackage
      this.dataset.stageSummary.area = this.DataItems[0].stageSummaryArea
      this.dataset.stageSummary.equipment = this.DataItems[0].stageSummaryEq

      /**
       * PDF Print
       */
      if(this.getSelectItems) {
        let pageOptions = JSON.parse(this.getSelectItems)
        if(pageOptions.filterOptions) {
          this.displayDataFilter.tag              = pageOptions.filterOptions.tag
          this.displayDataFilter.searchOption     = pageOptions.filterOptions.searchOption
          this.displayDataFilter.searchInput      = pageOptions.filterOptions.searchInput
          this.displayDataFilter.p2w.checked      = pageOptions.filterOptions.p2w     
          this.displayDataFilter.w2w.checked      = pageOptions.filterOptions.w2w
          this.displayDataFilter.nmr.checked      = pageOptions.filterOptions.nmr
          this.displayDataFilter.mr.checked       = pageOptions.filterOptions.mr
          this.displayDataFilter.loa.checked      = pageOptions.filterOptions.loa
          this.displayDataFilter.po.checked       = pageOptions.filterOptions.po
          this.displayDataFilter.delivery.checked = pageOptions.filterOptions.delivery
          this.displayDataFilter.install.checked  = pageOptions.filterOptions.install
          this.displayDataFilter.not.checked      = pageOptions.filterOptions.not
        }
      }
    },
    loadSvg(selection, data) {
      return d3.xml(`${this.__HOST_NAME_RESOURCE}${data.url}`).then(source => {
        let svg_ = source.documentElement
        selection.node().append(svg_)

        svg_ = d3.select(svg_)
        .attr('x', data.x)
        .attr('y', data.y)

        return new Promise(resolve => {
          resolve(svg_)
        })
      })
    },
    setPriority() {
      if(this.cmmSharedArea) this.cmmSharedArea.moveToFront()
      if(this.cmmHeaderArea) this.cmmHeaderArea.moveToFront()
      if(this.cmmTableFilteredResult) this.cmmTableFilteredResult.moveToFront()
      d3.select(`#${this.localId}`).select('._global_elements__text__').moveToFront()
    },
    buildRequest(title, filters) {
      let request = this.getRequestColumnProps(__C.REQUEST_ACTION.TARGET_POPUP_FREE, null, this.ColumnProps)
      if(!request) return {}

      // from the input search //
      if(!filters) {
        let f  = [
          {},
          { LV2: this.route.to.package },
          { LV2: this.route.to.package, LV3: this.route.to.area },
          { LV2: this.route.to.package, LV3: this.route.to.area, AREA_UNIT: this.route.to.equipment },
        ]
        var filters__ = f[this.route.to.level]
      }

      request.filters = filters || filters__
      request.data = { ...this.reqData }
      request.data.title = title

      return request
    },
    // Input & Search functions --------------------------------------
    trimDivText(selection) {
      selection.html(selection.html().replace(/<div(.*?)\/div>/g, ''))
    },
    setCaret(el) {
      let range = document.createRange(),
          sel = window.getSelection(),
          lastKnownIndex = -1;
      for (let i = 0; i < el.childNodes.length; i++) {
        if (this.isTextNodeAndContentNoEmpty(el.childNodes[i])) {
          lastKnownIndex = i;
        }
      }
      if (lastKnownIndex === -1) {
        // throw new Error('Could not find valid text content');
        return
      }
      let row = el.childNodes[lastKnownIndex],
          col = row.textContent.length;
      range.setStart(row, col);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
      el.focus();
    },
    isTextNodeAndContentNoEmpty(node) {
      return node.nodeType == Node.TEXT_NODE && node.textContent.trim().length > 0
    },
    callEqPopup(d, checkact=[]) {
      let request_ = this.getRequestColumnProps(__C.REQUEST_ACTION.TARGET_POPUP_EQ, d, this.ColumnProps)
      request_.data = { ...this.reqData }

      // check eq-popup already opened before sending the open command
      // sending the command only when the eq-popup already opened
      if(checkact.includes('equipment-search')) request_.action.purpose = 'equipment-search'
      // shared can share the data with any child & parent component //
      else if(checkact.includes('share')) request_.shared = d

      this.$emit('request-action', request_)
    }
  }
}