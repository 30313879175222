import * as d3 from 'd3'
import __M from 'moment'

export default {
  methods: {
    draw_Charts() {
      if(this.chartMounted) {
        d3.select(`#${this.localId}`).select('.skyline_boxes').remove()
      }

      let self = this
      let BoxesChart = this.SkylineCharts
      .append('g')
      .attr('class', 'skyline_boxes')
      .attr('transform', `translate(${this.timeline.baseX},10)`)
     
      d3.selection.prototype.moveToFront = function() {
        return this.each(function(){
          this.parentNode.appendChild(this);
        });
      }

      this.timeline.week.forEach((w, i) => {
        let drawY = this.timeline.baseY - this.skyline.box.height - (this.skyline.box.gap*2)

        let dateFiltered = this.dataSet.filter(f => f.cDate == w.eDate)

        dateFiltered.forEach((d, i) => {
          let x1 = this.timeline.scale(d.sDate) + this.skyline.box.gap
          let y1 = drawY

          let Boxes = BoxesChart
          .append('g')
          .attr('id', `SYS_${d.name.replace(/[^0-9a-zA-Z]/g, '')}`)
          .attr('transform', `translate(${x1},${y1})`)
          .style('cursor', 'pointer')
          .on('mouseover', function() {
            d3.select(this).moveToFront().transition().duration(150)
            .attr('transform', `translate(${x1-(self.skyline.box.width/4)},${y1-(self.skyline.box.height/4)}) scale(1.5)`)
          })
          .on('mouseout', function(){
            d3.select(this).transition().duration(150)
            .attr('transform', `translate(${x1},${y1}) scale(1)`)
          })
          .on('click', () => {
            // console.log(this.FilterValues);
            // let requestTrimmed_ = this.trimFilterPure()
            let request_ = {
              dataType: 'row',
              action: {
                type: 'direct',
                target: 'slide-modal',
                component: 'ServiceSvgTabs',
                id: 286,
                no: 'LIBSVGTB-0001'
              },
              filters: { 
                GWBS_LV3: d.GWBS_LV3,
                BLOCK: d.name,
                MBLOCK: d.name,
                DESC: d.Activity
              },
              iFilters: {
                filterString : '',
                inputFilter  : ''
              }
            }
            this.$emit('request-action', request_)
          })

          Boxes
          .append('rect') // the background color should be white(#fff)
          .attr('rx', d.radius)
          .attr('ry', d.radius)
          .attr('width', this.skyline.box.width)
          .attr('height', this.skyline.box.height)
          .attr('fill', d.bColor)
          .attr('stroke', 'Transparent')
          .attr('stroke-width', d.sWidth)

          Boxes
          .append('rect') // Fill Color
          .attr('rx', d.radius)
          .attr('ry', d.radius)
          .attr('width', d.PROG ? this.skyline.box.width * d.PROG/100 : this.skyline.box.width)
          .attr('height', this.skyline.box.height)
          .attr('fill', d.pColor)
          .attr('fill-opacity', d.opacity)
          .attr('stroke', 'Transparent')
          .attr('stroke-width', d.sWidth)

          Boxes
          .append('rect') // Stroke
          .attr('rx', d.radius)
          .attr('ry', d.radius)
          .attr('width', this.skyline.box.width)
          .attr('height', this.skyline.box.height)
          .attr('fill', 'Transparent')
          .attr('stroke', d.sColor)
          .attr('stroke-width', d.sWidth)

          Boxes
          .append('text')
          .attr('x', this.timeline.weekSize/2 - 1)
          .attr('y', this.skyline.box.height/2 + 1)
          .attr('font-family', this.skyline.box.font)
          .attr('font-size', d.tSize)
          .attr('fill', d.tColor)
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          .style('pointer-events', 'none')
          .text(d.name)

          drawY = drawY - this.skyline.box.height - (this.skyline.box.gap*2)

          if(i == dateFiltered.length-1) {
            BoxesChart
            .append('text')
            .attr('class', 'block_group_count')
            .attr('transform', `translate(${x1 + this.skyline.box.width / 2},${drawY + 10})`)
            .style('font-family', 'roboto')
            .style('font-size', 10)
            .style('fill', '#44A9DF')
            .attr('text-anchor', 'middle')
            .attr('alignment-baseline', 'middle')
            .text(dateFiltered.length)
          }
        })
      })
    },
    drawCutoff() {
      //   if((this.activity != 'WD_PLAN' && __M(w.eDate).format('YYYY-MM-DD') < '2019-09-20') || __M(w.eDate).format('YYYY-MM-DD') > this.Queries.SQL2[0].CDATE) return
      // console.log(this.timeline.scale(__M(this.Queries.SQL3[0])))
    },
    Chart_filtering(text=null) {
      if(!text) this.blockCountOpacity(1)
      else this.blockCountOpacity(.15)
      
      this.dataSet.forEach(d => { 
        let codeName_ = d.name.replace(/[^0-9a-zA-Z]/g, '')

        if(!text || (codeName_||'').toLowerCase().includes((text||'').toLowerCase()))
          this.svg.selectAll(`#SYS_${codeName_}`).each((_, i, a) => {
            d3.select(a[i]).style('pointer-events', 'auto').transition().duration(500).style('opacity', 1)
          })
        else
          this.svg.selectAll(`#SYS_${codeName_}`).each((_, i, a) => {
            d3.select(a[i]).style('pointer-events', 'none').transition().duration(500).style('opacity', .1)
          })
      })
    },
    blockCountOpacity(opacity) {
      this.svg.selectAll('.block_group_count').each((_, i, a) => {
        d3.select(a[i]).transition().duration(500).style('opacity', opacity)
      })
    },
  }
}
