import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {
    Draw_Charts() {
      const item = this.DataItems
      let imagesBox = this.svg

      item.forEach( (d,i) => {
        imagesBox
        .append('image')
        .attr('id', `Image___${this.localId}`)
        .attr('clip-path', 'url(#mask_image)')
        // .attr('preserveAspectRatio', 'xMidYMid')
        .attr('preserveAspectRatio', 'none')
        .attr('transform', i <= 1 ? `translate(${27+(280*i)}, 24)` :  i == 2 ? `translate(27, ${(105*i)})` : `translate(310, ${(70*i)})`)
        .attr('width', 250)
        .attr('height', 150)
        .style('filter', 'url(#dropshadow)')
        .attr('href', d.DATAURL)

        imagesBox
        .append('text')
        .attr('clip-path', 'url(#mask_text)')//각각의 text에 clipPath주기위해 g을 주지 않고 바로 url넣는다
        .attr('transform', i <= 1 ? `translate(${27+(280*i)}, 18)` :  i == 2 ? `translate(27, ${(102*i)})` : `translate(310, ${(68*i)})`)
        .style('font-size', 14)
        .style('fill', '#424242')
        .attr('text-anchor', 'start')
        .text(d.TITLE)
      })
    }
  }
}
