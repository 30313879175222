import * as d3 from 'd3'
import __C from '../../../../../includes/primitives/_constant_'
export default {
  data: () => ({
    innerSvg: null,
    stage_Main: null,
    stage_Header: null,
    stage_List: null,
    stage_Box: null,
    stage_SEI: null,
    selectedItems: null,
    getItems: null,

    stageCode: [],
    dataset: [],

    scale: .5,
    scaleW: 0,
    scaleH: 0,
    zoom: {
      in: { x: 0, y: 0 },
      out: { x: 0, y: 0 },
    },

    CurrentPkg: 'P1',
    CurrentArea: '',
    CurrentPlant: '',
    CurrentInfo:'off',

    Toggle: 'on',
    Toggle_SEI: 'on',

    SEIs: null,
    Areas: null,
    SubContractor: null,
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {
    /*
      set_LocalValues()
      loadSvg()
    */

    set_LocalValues(){  

      //json data
      this.Areas = JSON.parse(JSON.stringify(this.DataItems[0].seiCodeL1))
      this.SubContractor = JSON.parse(JSON.stringify(this.DataItems[0].conCode))
      this.SEIs = JSON.parse(JSON.stringify(this.DataItems[0].areaInfo))

      //for pdf & change area,lang
      //console.log('set_LocalValues-SEICODE_L1', this.FilterValues)
      this.CurrentArea = this.FilterValues && this.FilterValues.SEICODE_L1 ? this.FilterValues.SEICODE_L1 : ''
      this.getItems = this.getSelectItems ? JSON.parse(this.getSelectItems) : {}
      this.Toggle = this.getItems && this.getItems.lang ? this.getItems.lang : this.Toggle

    },

    loadSvg(selection, data) {
      return d3.xml(`${this.__HOST_NAME_RESOURCE}${data.url}`).then(source => {
        let svg_ = source.documentElement
        selection.node().append(svg_)

        svg_ = d3.select(svg_)
        .attr('x', data.x)
        .attr('y', data.y)

        return new Promise(resolve => {
          resolve(svg_)
        })
      })
    },
    //not used... for routeto another page(menu)
    // buildRequest(seicode_l1) {
    //   let d = this.DataItems.find( r => r.SEICODE_L1 === seicode_l1 )
    //   if(!d) return {}

    //   d.TOGGLE = this.Toggle // Add selected language code
    //   let request = this.getRequestColumnProps('filter', d, this.ColumnProps)
    //   if(!request) return null

    //   request.action.path = `${request.action.path}?useinfo=true&filters=${JSON.stringify(request.filters)}`

    //   return request
    // },
  }
}