import * as d3 from 'd3'

export default {
  data: () => ({
    dataSet: [],
  }),
  computed: {
    ready2draw() {

      // console.log('------------------------------')
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)

      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },
  methods: {
    // loadSvg(svgURL, prefix) {
    //   return d3.xml(`${this.__HOST_NAME_RESOURCE}${svgURL}`).then(source => {
    //     if(this.innerSvg) {
    //       this.innerSvg.html()
    //       this.innerSvg.remove() //svg를 지우고 
    //       this.innerSvg = null
    //     }
    //     this.innerSvg = source.documentElement //새로운 데이터 만든다 비동기 처리가 1번 밖에 리턴되지 않기 때문에 
    //     this.svg.node().append(this.innerSvg)
    //     this.innerSvg = d3.select(this.innerSvg)
    //     // this.intiPosition(prefix)

    //     return new Promise(resolve => { //여기서 다시 리턴해준다 그러면  draw.mixin.js에서 받는다
    //       resolve(source)
    //     })
    //   })
    // },
    loadSvg(x, y, path) {
      return d3.xml(`${this.__HOST_NAME_RESOURCE}${path}`).then(source => {
        let innerSvg = source.documentElement
        this.svg.node().append(innerSvg)
        innerSvg = d3.select(innerSvg)

        innerSvg
        .attr('x', x)
        .attr('y', y)

        return new Promise(resolve => {
          resolve(source)
        })
      })
    },
    setDefaultValues(){

      this.setEvent_Block()

      
      this.dataSet = JSON.parse(JSON.stringify(this.DataItems))
      this.dataSet.forEach(data => {
        let bItem = this.Border.find(t_ => t_.type == data.TYPE)
      if(bItem) {
        data.shape = bItem.shape
        data.width = bItem.width
        data.height = bItem.height
        data.color = bItem.color
        data.stroke = bItem.stroke
        data.textSize = bItem.textSize
        data.padding = bItem.padding
      }
    })
    // Style - Coloring
    this.dataSet.forEach(data => {
      data.bColor = '#fff'
      data.tColor = '#333'
      // console.log(data.bColor)
      let bItem = this.Status.find(t_ => t_.code == data.STATUS)
      // console.log(bItem)
      if(bItem) {
        data.bColor = bItem.bColor
        data.tColor = bItem.tColor
      }
    })

    // 텍스트 위치 x,y를 DATASET에 저장한다.
    this.dataSet.forEach(data => {
      let bItem = this.Coordinate.find(f => f.BID_SECTION_PACK == data.BID_SECTION_PACK)
      // if(data.GWBS_LV2 == 'HULL-COMMON') {
      //   bItem = this.Coordinate.find(f => f.Blk == data.BLOCK)
      // }
      if(bItem) {
        data.x = bItem.x
        data.y = bItem.y
        // data.Lv4 = bItem.Lv4
      }
      // console.log(data.BID_SECTION_PACK)
      })
    },
  }
}