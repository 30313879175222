import * as d3 from 'd3'

export default {
  data: () => ({
    innerSvg: null,
    selectedItems: null
  }),
  computed: {
    ready2draw() {

      // console.log('------------------------------')
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)

      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
    itemsSubcontract() { return this.DataItems[0].Subcontract },
    itemsSupervision() { return this.DataItems[0].Supervision },
    itemsQualityStation() { return this.DataItems[0].QualityStation },
    itemsSubconExecution() { return this.DataItems[0].SubconExecution },
  },
  methods: {
    setDefaultValue() {
      d3.selection.prototype.moveToFront = function() {
        return this.each(function(){
          this.parentNode.appendChild(this)
        })
      }
    },
    loadSvg(selection, data) {
      return d3.xml(`${this.__HOST_NAME_RESOURCE}${data.url}`).then(source => {
        let svg_ = source.documentElement
        selection.node().append(svg_)

        svg_ = d3.select(svg_)
        .attr('x', data.x)
        .attr('y', data.y)

        return new Promise(resolve => {
          resolve(svg_)
        })
      })
    },
    setStatus(d) {
      let index_ = d.filter.values.findIndex(v_ => v_.text == d.text)
      let values_ = JSON.parse(JSON.stringify(d.filter.values))
      let length_ = values_.length

      this.selectedItems = d

      var prevIndex_ = d.filter.values.findIndex(v_ => v_.on)
      for(let i = 0; i<length_; i++) {
        if(values_[i].text == d.text) values_[i].on = true
        else values_[i].on = false
      } 
      d.filter.values = values_

      if(prevIndex_ != index_) {
        d3.select(`#${this.localId}`)
        .select(`.search_circle__${prevIndex_}`)
        .style('stroke', this.styleFilter.out.stroke)
        .style('fill', this.styleFilter.out.fill)

        d3.select(`#${this.localId}`)
        .select(`.search_text__${prevIndex_}`)
        .style('fill', this.styleFilter.out.color)
      }

      if(values_[index_].on) {
        d3.select(`#${this.localId}`)
        .select(`.search_circle__${index_}`)
        .style('stroke', this.styleFilter.selected.stroke)
        .style('fill', this.styleFilter.selected.fill)
        

        d3.select(`#${this.localId}`)
        .select(`.search_text__${index_}`)
        .style('fill', this.styleFilter.selected.color)
        .style('opacity', 1)
        
      } else {
        d3.select(`#${this.localId}`)
        .select(`.search_circle__${index_}`)
        .style('stroke', this.styleFilter.out.stroke)
        .style('fill', this.styleFilter.out.fill)

        d3.select(`#${this.localId}`)
        .select(`.search_text__${index_}`)
        .style('fill', this.styleFilter.out.color)
      }

      d3.select(`#${this.localId}`).select(`#area__${d.colName}`).moveToFront()
      d3.select(`#${this.localId}`).select(`#area__labe_button`).moveToFront()
      //d3.select(`#${this.localId}`).select(`#area__layer_svg`).moveToFront()
    }
  }
}