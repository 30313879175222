import * as d3 from 'd3'

export default {
  data: () => ({
    Back_Dashboard_Overall: null,
  }),
  computed: {
  },
  methods: {
    setEvent_Gohome() {
      this.btnGoHome = (selection) => {
        selection
        .on('mouseover', () => {
          d3.select(`.Home_Btn`).transition().duration(100).style('opacity', 0.5)
        })
        .on('mouseout', () => {
          d3.select(`.Home_Btn`).transition().duration(100).style('opacity', 1)
        })
        .on('click', () => {
          this.selectedItems.pop()
          d3.select(`.Home_Btn`).transition().duration(100).style('opacity', 1)
          let del = []
          this.selectedItems.forEach((d,index) => {
             del.push(index)
          })
          del.forEach(d => {
            this.selectedItems.pop()
          })
          setTimeout(() => {
              if (this.innerSvg) this.removeSvg(this.innerSvg, 10)
              if (this.svgModeling) this.removeSvg(this.svgModeling, 10)            
          }, 700)

          // Remove SVG
          setTimeout(() => {
            this.removeSvg(this.CANVAS, 10)
            // this.removeSvg(this.draw_BlockCode_Lv3,0)
            // this.removeSvg(this.draw_BlockCode_Lv4,0)
            this.removeSvg(d3.select('#BlockCodes'), 0)
            if(this.Dashboard_Overall) this.removeSvg(this.Dashboard_Overall, 10) 
          }, 1000)

          setTimeout(() => {
            this.Activate_Overall()            
          }, 1300)
 
          // Hidden
          // this.hiddenGroupID('#Mask_Topside', 0, 10)
          // this.hiddenGroupID('#Mask_Hull', 0, 10)
          this.hiddenGroupID('#Menu_Back', 0, 10)
          // this.hiddenGroupID('#Keyplan_Group', 0, 10)
          this.hiddenGroupID('#Legends', 0, 10)

          d3.select(`#Title`).text(`Topsides`)

          setTimeout(() => {
            d3.select(`#Topside_Group`).transition().duration(1000).attr('transform', `translate(0, 0)`)
            d3.select(`#FL01`)  .transition().duration(1000).attr('transform', `translate(451, 384) scale(0.75)`)
            d3.select(`#KO01`)  .transition().duration(1000).attr('transform', `translate(384, 670) scale(0.75)`)
            d3.select(`#TSRC01`).transition().duration(1000).attr('transform', `translate(494, 676) scale(0.75)`)
            d3.select(`#TSRC02`).transition().duration(1000).attr('transform', `translate(591, 694) scale(0.75)`)
            d3.select(`#TSRC03`).transition().duration(1000).attr('transform', `translate(680, 727) scale(0.75)`)
            d3.select(`#TSRC04`).transition().duration(1000).attr('transform', `translate(780, 744) scale(0.75)`)
            d3.select(`#TSRC05`).transition().duration(1000).attr('transform', `translate(857, 759) scale(0.75)`)
            d3.select(`#LD00DA`).transition().duration(1000).attr('transform', `translate(897, 779) scale(0.75)`)


            d3.select(`#MW01`).transition().duration(1000).attr('transform', `translate(783, 221) scale(0.75)`)
            d3.select(`#MW02`).transition().duration(1000).attr('transform', `translate(870, 232) scale(0.75)`)
            d3.select(`#MW04`).transition().duration(1000).attr('transform', `translate(1076, 244) scale(0.75)`)
            d3.select(`#MW05`).transition().duration(1000).attr('transform', `translate(1133, 284) scale(0.75)`)
            d3.select(`#ME01`).transition().duration(1000).attr('transform', `translate(744, 265) scale(0.75)`)
            d3.select(`#ME02`).transition().duration(1000).attr('transform', `translate(828, 272) scale(0.75)`)
            d3.select(`#ME04`).transition().duration(1000).attr('transform', `translate(1037, 271) scale(0.75)`)
            d3.select(`#ME05`).transition().duration(1000).attr('transform', `translate(1095, 322) scale(0.75)`)
          }, 1800)

          setTimeout(() => {
            // Display
            this.displayGroupID(`#Mask_TS_Common`, 10, 10, 1)
            this.displayGroupID(`#Mask_TS_Modules`, 10, 10, 1)
            this.stage = this.STG_Overall
            this.Lev1 = null
            this.Lev2 = null
            this.Lev3 = null
            this.Lev4 = null
            this.fncName = 'Back_Overall_FPSO' 
            this.StageCode() // AREA / STAGE
          }, 1200);
        })
      }
    },
    setEvent_Goback() {

      // Set Event Listener Back Button
      this.btnGoBack = (selection) => {
        selection
        .on('mouseover', () => {
          d3.select(`.Back_Btn`).transition().duration(100).style('opacity', 0.5)
        })
        .on('mouseout', () => {
          d3.select(`.Back_Btn`).transition().duration(100).style('opacity', 1)
        })
        .on('click', () => {
          d3.select(`.Back_Btn`).transition().duration(100).style('opacity', 1)

          this.selectedItems.pop()
          this.hiddenGroupID('#Menu_Back', 0, 10)
          // console.log(this.stage,"------------1")
          // console.log(this.STG_Division_Lv3,"------------2")
          // console.log(this.STG_Activate,"---------------3")
          // console.log(this.STG_Division_Lv4,"-----------4")
          // console.log(this.STG_Lv4_Block_Division,"---------5")
          // console.log(this.Lev1,"----------------6")
          // console.log(this.Lev2,"----------------7")
          // console.log(this.Lev3,"----------------8")
          // console.log(this.Lev4,"----------------9")          
          switch(this.stage) {

            case this.STG_Division_Lv2: 
                                        this.Back_Overall_FPSO()
                                        break;
            // case this.STG_Division_Lv2: 
            //                             if (this.Lev1 == 'TOPSIDE')   this.Topside_Division_Lv1()
            //                             else if(this.Lev1 == 'HULL')   this.Hull_Division_Lv1()
            //                             break;
            case this.STG_Activate: 
                                        if(this.Lev1 == 'HULL')           this.Hull_Back_Division_Lv2()
                                        // else if(this.Lev1 == 'TOPSIDE')   this.Topside_Back_Division_Lv2()
                                        else if(['ME01', 'ME02', 'ME04', 'ME05','FL01','KO01','LD00DA','RC01','RC02','RC03','RC04','RC05',
                                        'MW01', 'MW02', 'MW04', 'MW05'].includes(this.Lev3)) this.Topside_Back_Division_Lv2()
                                        break;

            case this.STG_Division_Lv3: 
                                        if(this.Lev2 == 'HULL-AFT')       this.Hull_Back_Division_Lv2()
                                        else if(this.Lev2 == 'HULL-FOR')  this.Hull_Back_Division_Lv2()
                                        else if(this.Lev2 == 'LQ')        this.Hull_Back_Division_Lv2()
                                        else if(this.Lev2 == 'COMMON' ||  this.Lev2 == 'MODULES') this.TS_Division_Lv2(this.Lev2)
                                        break;

            case this.STG_Division_Lv4: 
                                        if(this.Lev2 == 'HULL-AFT')       this.AFT_Division_Hull_Lv4()
                                        else if(this.Lev2 == 'HULL-FOR')  this.FOR_Division_Hull_Lv4()
                                        else if(this.Lev2 == 'LQ')        this.LQ_Division_Hull_Lv4()
                                        break;

            case this.STG_Lv3_Block_Division: 
                                        if(this.Lev2 == 'HULL-AFT')       this.AFT_Lv3_Block_Division()
                                        else if(this.Lev2 == 'HULL-FOR')  this.FOR_Lv3_Block_Division()
                                        else if(this.Lev2 == 'LQ')        this.LQ_Lv3_Block_Division()
                                        break;

            case this.STG_Lv4_Block_Division: 
                                        if(this.Lev2 == 'HULL-AFT')       this.AFT_Lv4_Block_Division()
                                        else if(this.Lev2 == 'HULL-FOR')  this.FOR_Lv4_Block_Division()
                                        else if(this.Lev2 == 'LQ')        this.LQ_Lv4_Block_Division()

                                        // if(this.Lev1 == 'TOPSIDE')        this.Topside_Activate_Module()
                                        else if(['ME01', 'ME02', 'ME04', 'ME05','FL01','KO01','LD00DA','RC01','RC02','RC03','RC04','RC05',
                                        'MW01', 'MW02', 'MW04', 'MW05'].includes(this.Lev3)) this.Topside_Back_Division_Lv2()
                                        break;
          }
          
          this.stage = null //2020-11-26 이렇게 넣으면 잠깐 this.stage의 틈에 해당조건이 아니라서 return된다
        })
      }
      
      this.Back_Overall_FPSO = () => { // ---------------------------------------------------------------------------------------------------------------------

        // Remove SVG
        this.removeSvg(this.CANVAS, 1000)

        // Hidden
        this.hiddenGroupID('#Mask_Topside', 0, 10)
        this.hiddenGroupID('#Mask_Hull', 0, 10)
        this.hiddenGroupID('#Menu_Back', 0, 10)
        this.hiddenGroupID('#Keyplan_Group', 0, 10)
        this.hiddenGroupID('#Legends', 0, 10)
        
        d3.select(`#Topside_Group`).transition().duration(1000).attr('transform', `translate(0, 0)`)

        d3.select(`#FL01`)  .transition().duration(1000).attr('transform', `translate(451, 384) scale(0.75)`)
        d3.select(`#KO01`)  .transition().duration(1000).attr('transform', `translate(384, 670) scale(0.75)`)
        d3.select(`#TSRC01`).transition().duration(1000).attr('transform', `translate(494, 676) scale(0.75)`)
        d3.select(`#TSRC02`).transition().duration(1000).attr('transform', `translate(591, 694) scale(0.75)`)
        d3.select(`#TSRC03`).transition().duration(1000).attr('transform', `translate(680, 727) scale(0.75)`)
        d3.select(`#TSRC04`).transition().duration(1000).attr('transform', `translate(780, 744) scale(0.75)`)
        d3.select(`#TSRC05`).transition().duration(1000).attr('transform', `translate(857, 759) scale(0.75)`)
        d3.select(`#LD00DA`).transition().duration(1000).attr('transform', `translate(897, 779) scale(0.75)`)


        d3.select(`#MW01`).transition().duration(1000).attr('transform', `translate(783, 221) scale(0.75)`)
        d3.select(`#MW02`).transition().duration(1000).attr('transform', `translate(870, 232) scale(0.75)`)
        d3.select(`#MW04`).transition().duration(1000).attr('transform', `translate(1076, 244) scale(0.75)`)
        d3.select(`#MW05`).transition().duration(1000).attr('transform', `translate(1133, 284) scale(0.75)`)
        d3.select(`#ME01`).transition().duration(1000).attr('transform', `translate(744, 265) scale(0.75)`)
        d3.select(`#ME02`).transition().duration(1000).attr('transform', `translate(828, 272) scale(0.75)`)
        d3.select(`#ME04`).transition().duration(1000).attr('transform', `translate(1037, 271) scale(0.75)`)
        d3.select(`#ME05`).transition().duration(1000).attr('transform', `translate(1095, 322) scale(0.75)`)

        setTimeout(() => {
          d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Keyplan/TOPSIDE.png`)
          // Display
          this.displayGroupID(`#Mask_TS_Common`, 10, 10, 0)
          this.displayGroupID(`#Mask_TS_Modules`, 10, 10, 0)

          this.stage = this.STG_Overall
          this.fncName = 'Back_Overall_FPSO' 
          this.StageCode() // AREA / STAGE

          // ### Display Canvas ###
          this.Canvas_Topside_Division_Lv2()
        }, 1000);
      }

      this.Topside_Division_Lv1 = () => { // ---------------------------------------------------------------------------------------------------------------------

        // Remove SVG
        this.removeSvg(this.CANVAS, 1000)

        // Title & Keyplan
        d3.select(`#Title`).text('Tortue Overall')
        d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Keyplan/TORTUE.png`)

        // Hidden
        this.hiddenGroupID('#Mask_TS_Common', 0, 10)
        this.hiddenGroupID('#Mask_TS_Modules', 0, 10)
        
        d3.select(`#Topside_Group`).transition().duration(1000).attr('transform', `translate(0, -100)`)

        d3.select(`#FL01`)  .transition().duration(1000).attr('transform', `translate(506, 228.5) scale(0.75)`)
        d3.select(`#KO01`)  .transition().duration(1000).attr('transform', `translate(439, 504)   scale(0.75)`)
        d3.select(`#TSRC01`).transition().duration(1000).attr('transform', `translate(549, 510)   scale(0.75)`)
        d3.select(`#TSRC02`).transition().duration(1000).attr('transform', `translate(646, 528)   scale(0.75)`)
        d3.select(`#TSRC03`).transition().duration(1000).attr('transform', `translate(735, 562)   scale(0.75)`)
        d3.select(`#TSRC04`).transition().duration(1000).attr('transform', `translate(835, 579)   scale(0.75)`)
        d3.select(`#TSRC05`).transition().duration(1000).attr('transform', `translate(912, 594)   scale(0.75)`)
        d3.select(`#LD00DA`).transition().duration(1000).attr('transform', `translate(952, 615)   scale(0.75)`)

        d3.select(`#MW01`).transition().duration(1000).attr('transform', `translate(567,   472)   scale(0.75)`)
        d3.select(`#MW02`).transition().duration(1000).attr('transform', `translate(654,   483)   scale(0.75)`)
        d3.select(`#MW04`).transition().duration(1000).attr('transform', `translate(861,   495)   scale(0.75)`)
        d3.select(`#MW05`).transition().duration(1000).attr('transform', `translate(918,   535)   scale(0.75)`)
        d3.select(`#ME01`).transition().duration(1000).attr('transform', `translate(526.5, 515.6) scale(0.75)`)
        d3.select(`#ME02`).transition().duration(1000).attr('transform', `translate(613.4, 523)   scale(0.75)`)
        d3.select(`#ME04`).transition().duration(1000).attr('transform', `translate(821.8, 522.3) scale(0.75)`)
        d3.select(`#ME05`).transition().duration(1000).attr('transform', `translate(880,   572.8) scale(0.75)`)

        // Display
        this.displayGroupID(`#Area_AFT`, 10, 1000, 1)
        this.displayGroupID(`#Area_FOR`, 10, 1000, 1)
        this.displayGroupID(`#Area_LQ`, 10, 1000, 1)
        this.displayGroupID(`#Area_VS`, 10, 1000, 1)

        setTimeout(() => {
          // Display Mask
          this.displayGroupID(`#Mask_Topside`, 10, 10, 0)
          this.displayGroupID(`#Mask_Hull`, 10, 10, 0)

          this.stage = this.STG_Division_Lv1

          if (this.Goto_Plag) {
            let dStage = {
              stage: this.STG_Division_Lv1,
              mask : 'OVERALL',
              level: ''
            }
            this.selectedItems.push(dStage)
            this.Goto_Plag = false
          }
          this.fncName = 'Topside_Division_Lv1'  
          this.StageCode() // AREA / STAGE
          this.displayGroupID(`#Menu_Back`, 0, 200, 1)

          // ### Display Canvas ###
          this.Canvas_Overall_Division_Lv1()
          
        }, 1000);
      }

      this.Hull_Division_Lv1 = () => { // ------------------------------------------------------------------------------------------ Hull Activate

        // Remove SVG
        this.removeSvg(this.CANVAS, 1000)

        // Title & Keyplan
        d3.select(`#Title`).text('Tortue Overall')
        d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Keyplan/TORTUE.png`)

        // Hidden
        this.hiddenGroupID('#Mask_Hull_AFT', 0, 10)
        this.hiddenGroupID('#Mask_Hull_FOR', 0, 10)
        this.hiddenGroupID('#Mask_Hull_LQ', 0, 10)
        
        d3.select(`#Area_AFT`).transition().duration(1000).attr('transform', `translate(418, 668) scale(0.75)`)
        d3.select(`#Area_FOR`).transition().duration(1000).attr('transform', `translate(727, 653) scale(0.75)`)
        d3.select(`#Area_LQ`).transition().duration(1000).attr('transform', `translate(983, 663) scale(0.75)`)
        d3.select(`#Area_VS`).transition().duration(1000).attr('transform', `translate(926, 734) scale(0.75)`)

        setTimeout(() => {
          // Display
          this.displayGroupID(`#Topside_Group`, 0, 500, 1)
          this.displayGroupID(`#Mask_Topside`, 10, 10, 0)
          this.displayGroupID(`#Mask_Hull`, 10, 10, 0)

          this.stage = this.STG_Division_Lv1
          if (this.Goto_Plag) {
            let dStage = {
              stage: this.STG_Division_Lv1,
              mask : 'OVERALL',
              level: ''
            }
            this.selectedItems.push(dStage)
            this.Goto_Plag = false
          }
          this.fncName = 'Hull_Division_Lv1'  
          this.StageCode() // AREA / STAGE
          this.displayGroupID(`#Menu_Back`, 0, 200, 1)

          // ### Display Canvas ###
          this.Canvas_Overall_Division_Lv1()
        }, 1000);
      }

      this.Topside_Back_Division_Lv2 = () => { // ------------------------------------------------------------------------------------------ Topside Activate

        // Remove SVG
        this.removeSvg(this.CANVAS, 1000)

        // Title & Keyplan
        d3.select(`#Title`).text(`Topside_${this.Lev2}`)
        d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Keyplan/TOPSIDE_${this.Lev2}.png`)

        // Hidden
        this.hiddenGroupID('#Menu_Back', 0, 10)
        
        // Remove SVG
        this.removeSvg(this.svgModeling, 500) 
        this.removeSvg(d3.select('#BlockCodes'), 0)
        
        // Call
        this.Activate_OverallnMasks()

        // Set Hull Position
        // d3.select(`#Area_AFT`).attr('transform', `translate(418, 668) scale(0.75)`)
        // d3.select(`#Area_FOR`).attr('transform', `translate(727, 653) scale(0.75)`)
        // d3.select(`#Area_LQ`) .attr('transform', `translate(983, 663) scale(0.75)`)
        // d3.select(`#Area_VS`) .attr('transform', `translate(926, 734) scale(0.75)`)

        // Set Topside Position
        d3.select(`#MW01`).attr('transform', `translate(581,  357) scale(0.75)`)
        d3.select(`#MW02`).attr('transform', `translate(797,  393) scale(0.75)`)
        d3.select(`#MW04`).attr('transform', `translate(1069, 419) scale(0.75)`)
        d3.select(`#MW05`).attr('transform', `translate(1273, 488) scale(0.75)`)
        d3.select(`#ME01`).attr('transform', `translate(287,  583) scale(0.75)`)
        d3.select(`#ME02`).attr('transform', `translate(498,  614) scale(0.75)`)
        d3.select(`#ME04`).attr('transform', `translate(774,  626) scale(0.75)`)
        d3.select(`#ME05`).attr('transform', `translate(981,  707) scale(0.75)`)
        
        d3.select(`#FL01`)  .attr('transform', `translate(420,  269) scale(0.75)`)
        d3.select(`#KO01`)  .attr('transform', `translate(261,  630) scale(0.75)`)
        d3.select(`#TSRC01`).attr('transform', `translate(579,  580) scale(0.75)`)
        d3.select(`#TSRC02`).attr('transform', `translate(749,  612) scale(0.75)`)
        d3.select(`#TSRC03`).attr('transform', `translate(907,  659) scale(0.75)`)
        d3.select(`#TSRC04`).attr('transform', `translate(1082, 691) scale(0.75)`)
        d3.select(`#TSRC05`).attr('transform', `translate(1234, 722) scale(0.75)`)
        d3.select(`#LD00DA`).attr('transform', `translate(1294, 592) scale(0.75)`)

        if(this.Lev2 == 'COMMON') {
          // hide module
          this.hiddenGroupID('#MW01', 0, 1000)
          this.hiddenGroupID('#MW02', 0, 1000)
          this.hiddenGroupID('#MW04', 0, 1000)
          this.hiddenGroupID('#MW05', 0, 1000)
          this.hiddenGroupID('#ME01', 0, 1000)
          this.hiddenGroupID('#ME02', 0, 1000)
          this.hiddenGroupID('#ME04', 0, 1000)
          this.hiddenGroupID('#ME05', 0, 1000)
        }
        if(this.Lev2 == 'MODULES') {
          // hide module
          this.hiddenGroupID('#FL01', 0, 1000)
          this.hiddenGroupID('#KO01', 0, 1000)
          this.hiddenGroupID('#TSRC01', 0, 1000)
          this.hiddenGroupID('#TSRC02', 0, 1000)
          this.hiddenGroupID('#TSRC03', 0, 1000)
          this.hiddenGroupID('#TSRC04', 0, 1000)
          this.hiddenGroupID('#TSRC05', 0, 1000)
          this.hiddenGroupID('#LD00DA', 0, 1000)
        } 
        
        setTimeout(() => {

          this.displayGroupID(`#Topside_Group`, 0, 500, 1)

          // Mask Display
          if(this.Lev2 == 'COMMON') {
            this.displayGroupID(`#Mask_FL01`,   0, 10, 0)
            this.displayGroupID(`#Mask_KO01`,   0, 10, 0)
            this.displayGroupID(`#Mask_TSRC01`, 0, 10, 0)
            this.displayGroupID(`#Mask_TSRC02`, 0, 10, 0)
            this.displayGroupID(`#Mask_TSRC03`, 0, 10, 0)
            this.displayGroupID(`#Mask_TSRC04`, 0, 10, 0)
            this.displayGroupID(`#Mask_TSRC05`, 0, 10, 0)
            this.displayGroupID(`#Mask_LD00DA`, 0, 10, 0)
          } 
          if(this.Lev2 == 'MODULES') {
            this.displayGroupID(`#Mask_MW01`, 0, 10, 0)
            this.displayGroupID(`#Mask_MW02`, 0, 10, 0)
            this.displayGroupID(`#Mask_MW04`, 0, 10, 0)
            this.displayGroupID(`#Mask_MW05`, 0, 10, 0)
            this.displayGroupID(`#Mask_ME01`, 0, 10, 0)
            this.displayGroupID(`#Mask_ME02`, 0, 10, 0)
            this.displayGroupID(`#Mask_ME04`, 0, 10, 0)
            this.displayGroupID(`#Mask_ME05`, 0, 10, 0)
          }
          
          this.stage = this.STG_Division_Lv3

          this.stage = this.STG_Division_Lv3
          if (this.Goto_Plag) {
          let dStage = {
            stage: this.STG_Division_Lv3,
            mask : this.Lev2 == 'COMMON' ? 'Mask_TS_Common' : 'Mask_TS_Modules',
            level: this.Lev2
          }
          this.selectedItems.push(dStage)
            this.Goto_Plag = false
          }          
          this.fncName = 'Topside_Back_Division_Lv2'  
          this.StageCode() // AREA / STAGE
          this.displayGroupID(`#Menu_Back`, 0, 200, 1)

          // ### Call Canvas ###
          this.Canvas_Division_Lv3(this.Lev1, this.Lev2)
        }, 1000);
      }

      this.Hull_Back_Division_Lv2 = () => { // ---------------------------------------------------------------------------------------------------------------------

        // Remove SVG
        this.removeSvg(this.CANVAS, 1000)

        // Remove SVG
        this.removeSvg(this.svgModeling, 1000) 

        // Title & Keyplan
        d3.select(`#Title`).text('Hull & LQ')
        d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Keyplan/HULL.png`)

        this.Activate_OverallnMasks()
        d3.select(`#Topside_Group`).attr('transform', `translate(0, -100)`)

        setTimeout(() => {
          // Display
          d3.select(`#Area_AFT`).attr('transform', `translate(364, 477) scale(0.75)`).transition().duration(500).style('visibility', 'visible').style('opacity', 1)
          d3.select(`#Area_FOR`).attr('transform', `translate(842, 496) scale(0.75)`).transition().duration(500).style('visibility', 'visible').style('opacity', 1)
          d3.select(`#Area_LQ`).attr('transform', `translate(1109, 295) scale(0.75)`).transition().duration(500).style('visibility', 'visible').style('opacity', 1)
          d3.select(`#Area_VS`).attr('transform', `translate(1041, 577) scale(0.75)`).transition().duration(500).style('visibility', 'visible').style('opacity', 1)
        }, 500);

        setTimeout(() => {
          // Display
          this.displayGroupID(`#Mask_Hull_AFT`, 0, 10, 0)
          this.displayGroupID(`#Mask_Hull_FOR`, 0, 10, 0)
          this.displayGroupID(`#Mask_Hull_LQ`, 0, 10, 0)

          this.stage = this.STG_Division_Lv2
          this.fncName = 'Hull_Back_Division_Lv2'
          if (this.Goto_Plag) {
            let dStage = {
              stage: this.STG_Division_Lv2,
              mask : 'Mask_Hull',
              level: ''
            }
            this.selectedItems.push(dStage)
            this.Goto_Plag = false 
          }
          this.Lev3 = null
          this.Lev4 = null

          this.StageCode() // AREA / STAGE
          this.displayGroupID(`#Menu_Back`, 0, 200, 1)

          // ### Display Canvas ###
          this.Canvas_Hull_Division_Lv2()
        }, 1000);
      }


      this.TS_Division_Lv2 = (lv2) => { // ---------------------------------------------------------------------------------------------------------------------

        // Remove SVG
        this.removeSvg(this.CANVAS, 1000)

        // Title & Keyplan
        d3.select(`#Title`).text('Topside')
        d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Keyplan/TOPSIDE.png`)

        // Hidden
        this.hiddenGroupID('#Menu_Back', 0, 10)

        this.hiddenGroupID('#Mask_TS_Common', 0, 10)
        this.hiddenGroupID('#Mask_TS_Modules', 0, 10)

        if(this.Lev2 == 'COMMON') {

          // Hide Mask
          this.hiddenGroupID('#Mask_FL01', 0, 10)
          this.hiddenGroupID('#Mask_KO01', 0, 10)
          this.hiddenGroupID('#Mask_TSRC01', 0, 10)
          this.hiddenGroupID('#Mask_TSRC02', 0, 10)
          this.hiddenGroupID('#Mask_TSRC03', 0, 10)
          this.hiddenGroupID('#Mask_TSRC04', 0, 10)
          this.hiddenGroupID('#Mask_TSRC05', 0, 10)
          this.hiddenGroupID('#Mask_LD00DA', 0, 10)

          // Move module
          d3.select(`#FL01`)  .transition().duration(1000).attr('transform', `translate(451, 384) scale(0.75)`)
          d3.select(`#KO01`)  .transition().duration(1000).attr('transform', `translate(384, 670) scale(0.75)`)
          d3.select(`#TSRC01`).transition().duration(1000).attr('transform', `translate(494, 676) scale(0.75)`)
          d3.select(`#TSRC02`).transition().duration(1000).attr('transform', `translate(591, 694) scale(0.75)`)
          d3.select(`#TSRC03`).transition().duration(1000).attr('transform', `translate(680, 727) scale(0.75)`)
          d3.select(`#TSRC04`).transition().duration(1000).attr('transform', `translate(780, 744) scale(0.75)`)
          d3.select(`#TSRC05`).transition().duration(1000).attr('transform', `translate(857, 759) scale(0.75)`)
          d3.select(`#LD00DA`).transition().duration(1000).attr('transform', `translate(897, 779) scale(0.75)`)

          d3.select(`#MW01`).attr('transform', `translate(783, 221) scale(0.75)`)
          d3.select(`#MW02`).attr('transform', `translate(870, 232) scale(0.75)`)
          d3.select(`#MW04`).attr('transform', `translate(1076, 244) scale(0.75)`)
          d3.select(`#MW05`).attr('transform', `translate(1133, 284) scale(0.75)`)
          d3.select(`#ME01`).attr('transform', `translate(744, 265) scale(0.75)`)
          d3.select(`#ME02`).attr('transform', `translate(828, 272) scale(0.75)`)
          d3.select(`#ME04`).attr('transform', `translate(1037, 271) scale(0.75)`)
          d3.select(`#ME05`).attr('transform', `translate(1095, 322) scale(0.75)`)
          
          // Display Module
          this.displayGroupID(`#MW01`, 0, 1000, 1)
          this.displayGroupID(`#MW02`, 0, 1000, 1)
          this.displayGroupID(`#MW04`, 0, 1000, 1)
          this.displayGroupID(`#MW05`, 0, 1000, 1)
          this.displayGroupID(`#ME01`, 0, 1000, 1)
          this.displayGroupID(`#ME02`, 0, 1000, 1)
          this.displayGroupID(`#ME04`, 0, 1000, 1)
          this.displayGroupID(`#ME05`, 0, 1000, 1)
        }
        if (this.Lev2 == 'MODULES') {

          // Hide Mask
          this.hiddenGroupID('#Mask_MW01', 0, 10)
          this.hiddenGroupID('#Mask_MW02', 0, 10)
          this.hiddenGroupID('#Mask_MW04', 0, 10)
          this.hiddenGroupID('#Mask_MW05', 0, 10)
          this.hiddenGroupID('#Mask_ME01', 0, 10)
          this.hiddenGroupID('#Mask_ME02', 0, 10)
          this.hiddenGroupID('#Mask_ME04', 0, 10)
          this.hiddenGroupID('#Mask_ME05', 0, 10)

          // Move module
          d3.select(`#FL01`)  .attr('transform', `translate(451, 384) scale(0.75)`)
          d3.select(`#KO01`)  .attr('transform', `translate(384, 670) scale(0.75)`)
          d3.select(`#TSRC01`).attr('transform', `translate(494, 676) scale(0.75)`)
          d3.select(`#TSRC02`).attr('transform', `translate(591, 694) scale(0.75)`)
          d3.select(`#TSRC03`).attr('transform', `translate(680, 727) scale(0.75)`)
          d3.select(`#TSRC04`).attr('transform', `translate(780, 744) scale(0.75)`)
          d3.select(`#TSRC05`).attr('transform', `translate(857, 759) scale(0.75)`)
          d3.select(`#LD00DA`).attr('transform', `translate(897, 779) scale(0.75)`)

          d3.select(`#MW01`).transition().duration(1000).attr('transform', `translate(783, 221) scale(0.75)`)
          d3.select(`#MW02`).transition().duration(1000).attr('transform', `translate(870, 232) scale(0.75)`)
          d3.select(`#MW04`).transition().duration(1000).attr('transform', `translate(1076, 244) scale(0.75)`)
          d3.select(`#MW05`).transition().duration(1000).attr('transform', `translate(1133, 284) scale(0.75)`)
          d3.select(`#ME01`).transition().duration(1000).attr('transform', `translate(744, 265) scale(0.75)`)
          d3.select(`#ME02`).transition().duration(1000).attr('transform', `translate(828, 272) scale(0.75)`)
          d3.select(`#ME04`).transition().duration(1000).attr('transform', `translate(1037, 271) scale(0.75)`)
          d3.select(`#ME05`).transition().duration(1000).attr('transform', `translate(1095, 322) scale(0.75)`)

           // Display Module
           this.displayGroupID(`#FL01`, 0, 1000, 1)
           this.displayGroupID(`#KO01`, 0, 1000, 1)
           this.displayGroupID(`#TSRC01`, 0, 1000, 1)
           this.displayGroupID(`#TSRC02`, 0, 1000, 1)
           this.displayGroupID(`#TSRC03`, 0, 1000, 1)
           this.displayGroupID(`#TSRC04`, 0, 1000, 1)
           this.displayGroupID(`#TSRC05`, 0, 1000, 1)
           this.displayGroupID(`#LD00DA`, 0, 1000, 1)
        }
        setTimeout(() => {
          // Display
          this.displayGroupID(`#Mask_TS_Common`, 0, 10, 0)
          this.displayGroupID(`#Mask_TS_Modules`, 0, 10, 0)

          this.stage = this.STG_Division_Lv2
          if (this.Goto_Plag) {
            let dStage = {
              stage: this.STG_Division_Lv2,
              mask : 'Mask_Topside',
              level: ''
            }
            this.selectedItems.push(dStage)
            this.Goto_Plag = false
          }
          this.fncName = 'TS_Division_Lv2'
          this.StageCode() // AREA / STAGE
          // this.displayGroupID(`#Menu_Back`, 0, 200, 1)

          // ### Call Canvas ###
          this.Canvas_Topside_Division_Lv2()
        }, 1000);
      }


      // ===================================================================================================================
      // Activate
      // ===================================================================================================================
      
      // Topsides --------------------------------------------------------------------------------------
      this.Topside_Activate_Module = () => { 

        // Remove SVG
        this.removeSvg(this.CANVAS, 1000)
        
        // Remove SVG
        this.removeSvg(this.svg_BlockCode, 1000) 

        // Hidden
        this.hiddenGroupID('#Menu_Back', 0, 10)
        
        // Explode
        this.TOPSIDE.filter(f => f.Lv3 == this.Lev3).forEach(d => {
          d3.select(`#Blk_${d.Blk}`)
          .transition().duration(1000).attr('transform', `translate(${d.x1}, ${d.y1}) scale(0.75)`)
        })

        setTimeout(() => {
          // Display
          this.displayGroupID(`#Mask_Mod_${this.Lev3}`, 0, 10, 0)

          this.stage = this.STG_Activate
          if (this.Goto_Plag) {
            let dStage = {
              stage: this.STG_Lv4_Block_Division,
              mask : '',
              level: this.Lev3
            }
            this.selectedItems.push(dStage) 
            this.Goto_Plag = false
          }
          this.fncName = 'Topside_Activate_Module'
          this.StageCode() // AREA / STAGE
          this.displayGroupID(`#Menu_Back`, 0, 200, 1)

          // ### Display Canvas ###
          this.Canvas_Topside_Activate(this.Lev3)
        }, 1000);
      }

      // ===================================================================================================================
      // Division_Hull_Lv4
      // ===================================================================================================================

      // HULL-AFT --------------------------------------------------------------------------------------
      this.AFT_Division_Hull_Lv4 = () => { 

        // Remove SVG
        this.removeSvg(this.CANVAS, 1000)

        // Title & Keyplan
        d3.select(`#Title`).text(`Hull-AFT`)
        d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Keyplan/HULL_AFT.png`)

        // Hidden
        this.hiddenGroupID('#Mask_HL02_Main', 0, 10)
        this.hiddenGroupID('#Mask_HL02_After', 0, 10)
        this.hiddenGroupID('#Mask_HL02_Port', 0, 10)
        this.hiddenGroupID('#Mask_HL02_Starboard', 0, 10)
        this.hiddenGroupID('#Mask_HL02_Bulkhead', 0, 10)
        this.hiddenGroupID('#Mask_HL02_Bottom', 0, 10)
        
        // Explode
        this.Hull_AFT.forEach(d => {
          d3.select(`#Blk_${d.Blk}`)
          .transition().duration(1000).attr('transform', `translate(${d.x2}, ${d.y2}) scale(0.5)`)

          d3.select(`#Hull_AFT_Group`)
          .transition().duration(1000).attr('transform', `translate(0,0) scale(1)`)
        })

        setTimeout(() => {
          // Display
          this.Hull_AFT.filter(f=> f.Lv3 !== 'HL02').forEach(d => {
            this.displayGroupID(`#Blk_${d.Blk}`, 0, 500, 1)
          })
    
          // Display
          this.displayGroupID(`#Mask_HL02`, 0, 10, 0)
          this.displayGroupID(`#Mask_MD00a`, 0, 10, 0)
          this.displayGroupID(`#Mask_RB01_02`, 0, 10, 0)

          this.stage = this.STG_Division_Lv3
          this.fncName = 'AFT_Division_Hull_Lv4'
          this.StageCode() // AREA / STAGE
          this.displayGroupID(`#Menu_Back`, 0, 200, 1)

           // ### Call Canvas ###
           this.Canvas_Division_Lv3(this.Lev1, this.Lev2)
        }, 1000);
      }

      // HULL-FOR --------------------------------------------------------------------------------------
      this.FOR_Division_Hull_Lv4 = () => { 
        
        // Remove SVG
        this.removeSvg(this.CANVAS, 1000)

        // Title & Keyplan
        d3.select(`#Title`).text(`Hull-FOR`)
        d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Keyplan/HULL_FOR.png`)

        // Hidden
        this.hiddenGroupID('#Mask_HL03_Main', 0, 10)
        this.hiddenGroupID('#Mask_HL03_Forward', 0, 10)
        this.hiddenGroupID('#Mask_HL03_Port', 0, 10)
        this.hiddenGroupID('#Mask_HL03_Starboard', 0, 10)
        this.hiddenGroupID('#Mask_HL03_Bulkhead', 0, 10)
        this.hiddenGroupID('#Mask_HL03_Bottom', 0, 10)
        
        // Explode
        this.Hull_FOR.forEach(d => {
          d3.select(`#Blk_${d.Blk}`)
          .transition().duration(1000).attr('transform', `translate(${d.x2}, ${d.y2}) scale(0.5)`)
  
          d3.select(`#Hull_FOR_Group`)
          .transition().duration(1000).attr('transform', `translate(0,0) scale(1)`)
        })

        setTimeout(() => {
          // Display
          this.Hull_FOR.filter(f=> f.Lv3 !== 'HL03').forEach(d => {
            this.displayGroupID(`#Blk_${d.Blk}`, 0, 500, 1)
          })
     
          // Display
          this.displayGroupID(`#Mask_HL03`, 0, 10, 0)
          this.displayGroupID(`#Mask_MD00f`, 0, 10, 0)
          this.displayGroupID(`#Mask_VS4_05`, 0, 10, 0)

          this.stage = this.STG_Division_Lv3
          this.fncName = 'FOR_Division_Hull_Lv4'
          this.StageCode() // AREA / STAGE
          this.displayGroupID(`#Menu_Back`, 0, 200, 1)

           // ### Call Canvas ###
           this.Canvas_Division_Lv3(this.Lev1, this.Lev2)
        }, 1000);
      }

      // LQ --------------------------------------------------------------------------------------
      this.LQ_Division_Hull_Lv4 = () => { 

        // Remove SVG
        this.removeSvg(this.CANVAS, 1000)

        // Title & Keyplan
        d3.select(`#Title`).text(`HULL-LQ`)
        d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Keyplan/HULL_LQ.png`)

        // Hidden
        this.hiddenGroupID('#Mask_LV01', 0, 10)
        this.hiddenGroupID('#Mask_LV23', 0, 10)
        this.hiddenGroupID('#Mask_LV45', 0, 10)
        this.hiddenGroupID('#Mask_LV67', 0, 10)
        
        // Explode
        this.Hull_LQ.forEach(d => {
          d3.select(`#Blk_${d.Blk}`)
          .transition().duration(1000).attr('transform', `translate(${d.x2}, ${d.y2}) scale(0.5)`)
        })

        d3.select(`#Hull_LQ_Group`)
        .transition().duration(1000).attr('transform', `translate(0,0) scale(1)`)

        setTimeout(() => {
          // Display
          this.Hull_LQ.filter(f=> f.Lv3 !== 'LQ00').forEach(d => {
            this.displayGroupID(`#Blk_${d.Blk}`, 0, 500, 1)
          })
        }, 1000);
        
        setTimeout(() => {
          // Display
          this.displayGroupID(`#Mask_LQ00`, 0, 10, 0)
          this.displayGroupID(`#Mask_HD01`, 0, 10, 0)

          this.stage = this.STG_Division_Lv3
          this.fncName = 'LQ_Division_Hull_Lv4'
          this.StageCode() // AREA / STAGE
          this.displayGroupID(`#Menu_Back`, 0, 200, 1)

           // ### Call Canvas ###
           this.Canvas_Division_Lv3(this.Lev1, this.Lev2)
        }, 1500);
      }

      // ===================================================================================================================
      // Lv4_Block_Division
      // ===================================================================================================================

      // HULL-AFT --------------------------------------------------------------------------------------
      this.AFT_Lv4_Block_Division = () => { 
        
        // Remove SVG
        this.removeSvg(this.svg_BlockCode, 1000) 
        
        // Title & Keyplan
        d3.select(`#Title`).text('Hull-AFT-HL03')
        d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Keyplan/HULL_AFT_HL02.png`)

        // Scale
        d3.select(`#Hull_AFT_Group`)
        .transition().duration(1000).attr('transform', `translate(200,100) scale(0.8)`)

        // Unexplode
        this.Hull_AFT.filter(f=> f.Lv4 == this.Lev4).forEach(d => {
          d3.select(`#Blk_${d.Blk}`)
          .transition().duration(1000).attr('transform', `translate(${d.x3}, ${d.y3}) scale(0.5)`)
        })

        setTimeout(() => {
          // Display
          this.Hull_AFT.filter(f=> f.Lv3 == this.Lev3 && f.Lv4 !== this.Lev4).forEach(d => {
            this.displayGroupID(`#Blk_${d.Blk}`, 0, 500, 1)
          })
        }, 1000);
        
        setTimeout(() => {
          // Display
          this.displayGroupID(`#Mask_HL02_Main`, 0, 10, 0)
          this.displayGroupID(`#Mask_HL02_After`, 0, 10, 0)
          this.displayGroupID(`#Mask_HL02_Port`, 0, 10, 0)
          this.displayGroupID(`#Mask_HL02_Starboard`, 0, 10, 0)
          this.displayGroupID(`#Mask_HL02_Bulkhead`, 0, 10, 0)
          this.displayGroupID(`#Mask_HL02_Bottom`, 0, 10, 0)

          this.stage = this.STG_Division_Lv4
          this.fncName = 'AFT_Lv4_Block_Division'
          this.StageCode() // AREA / STAGE
          this.displayGroupID(`#Menu_Back`, 0, 200, 1)

          // ### Call Canvas ###
          this.Canvas_Division_Lv4('HULL-AFT')
        }, 1500);
      }


      // HULL-FOR --------------------------------------------------------------------------------------
      this.FOR_Lv4_Block_Division = () => { 
        
        // Remove SVG
        this.removeSvg(this.svg_BlockCode, 1000) 
        
        // Title & Keyplan
          d3.select(`#Title`).text('Hull-FOR-HL03')
          d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Keyplan/HULL_FOR_HL03.png`)

        // Scale
        d3.select(`#Hull_FOR_Group`)
        .transition().duration(1000).attr('transform', `translate(200,100) scale(0.8)`)

        // Unexplode
        this.Hull_FOR.filter(f=> f.Lv4 == this.Lev4).forEach(d => {
          d3.select(`#Blk_${d.Blk}`)
          .transition().duration(1000).attr('transform', `translate(${d.x3}, ${d.y3}) scale(0.5)`)
        })

        setTimeout(() => {
          // Display
          this.Hull_FOR.filter(f=> f.Lv3 == this.Lev3 && f.Lv4 !== this.Lev4).forEach(d => {
            this.displayGroupID(`#Blk_${d.Blk}`, 0, 500, 1)
          })
        }, 1000);
        
        setTimeout(() => {
          // Display
          this.displayGroupID(`#Mask_HL03_Main`, 0, 10, 0)
          this.displayGroupID(`#Mask_HL03_Forward`, 0, 10, 0)
          this.displayGroupID(`#Mask_HL03_Port`, 0, 10, 0)
          this.displayGroupID(`#Mask_HL03_Starboard`, 0, 10, 0)
          this.displayGroupID(`#Mask_HL03_Bulkhead`, 0, 10, 0)
          this.displayGroupID(`#Mask_HL03_Bottom`, 0, 10, 0)

          this.stage = this.STG_Division_Lv4
          this.fncName = 'FOR_Lv4_Block_Division'
          this.StageCode() // AREA / STAGE
          this.displayGroupID(`#Menu_Back`, 0, 200, 1)

          // ### Call Canvas ###
          this.Canvas_Division_Lv4('HULL-FOR')
        }, 1500);
      }


      // LQ --------------------------------------------------------------------------------------
      this.LQ_Lv4_Block_Division = () => { 
 
        // Remove SVG
        this.removeSvg(this.svg_BlockCode, 1000) 
        
        d3.select(`#Title`).text('Hull-LQ-LQ00')
        d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Keyplan/HULL_LQ00.png`)

        // Scale
        d3.select(`#Hull_LQ_Group`)
        .transition().duration(1000).attr('transform', `translate(0,0) scale(1)`)

        // Unexplode
        this.Hull_LQ.filter(f=> f.Lv4 == this.Lev4).forEach(d => {
          d3.select(`#Blk_${d.Blk}`)
          .transition().duration(1000).attr('transform', `translate(${d.x3}, ${d.y3}) scale(0.5)`)
        })

        setTimeout(() => {
          // Display
          this.Hull_LQ.filter(f=> f.Lv3 == this.Lev3 && f.Lv4 !== this.Lev4).forEach(d => {
            this.displayGroupID(`#Blk_${d.Blk}`, 0, 500, 1)
          })
        }, 1000);
        
        setTimeout(() => {
          // Display
          this.displayGroupID(`#Mask_LV01`, 0, 10, 0)
          this.displayGroupID(`#Mask_LV23`, 0, 10, 0)
          this.displayGroupID(`#Mask_LV45`, 0, 10, 0)
          this.displayGroupID(`#Mask_LV67`, 0, 10, 0)

          this.stage = this.STG_Division_Lv4
          this.fncName = 'LQ_Lv4_Block_Division'
          this.StageCode() // AREA / STAGE
          this.displayGroupID(`#Menu_Back`, 0, 200, 1)

           // ### Call Canvas ###
           this.Canvas_Division_Lv4(this.Lev2)
        }, 1500);
      }


      // ===================================================================================================================
      // Lv3_Block_Division
      // ===================================================================================================================

      // HULL-AFT --------------------------------------------------------------------------------------
      this.AFT_Lv3_Block_Division = () => { 
        
        // Remove SVG
        this.removeSvg(this.svg_BlockCode, 1000) 
        this.removeSvg(this.CANVAS, 1000)
        
        // Title & Keyplan
        d3.select(`#Title`).text(`HULL-AFT`)
        d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Keyplan/HULL_AFT.png`)

        // Scale
        d3.select(`#Hull_AFT_Group`)
        .transition().duration(1000).attr('transform', `translate(0,0) scale(1)`)

        // Unexplode
        this.Hull_AFT.filter(f=> f.Lv4 == this.Lev4).forEach(d => {
          d3.select(`#Blk_${d.Blk}`)
          .transition().duration(1000).attr('transform', `translate(${d.x2}, ${d.y2}) scale(0.5)`)
        })

        setTimeout(() => {
          // Display
          this.Hull_AFT.filter(f=> f.Lv2 == this.Lev2 && f.Lv3 !== this.Lev3).forEach(d => {
            this.displayGroupID(`#Blk_${d.Blk}`, 0, 500, 1)
          })
        }, 1000);
        
        setTimeout(() => {
          // Display
          this.displayGroupID(`#Mask_HL02`, 0, 10, 0)
          this.displayGroupID(`#Mask_MD00a`, 0, 10, 0)
          this.displayGroupID(`#Mask_RB01_02`, 0, 10, 0)

          this.stage = this.STG_Division_Lv3
          if (this.Goto_Plag) {
            let dStage = {
              stage: this.STG_Division_Lv3,
              mask : "AFT",
              level: this.Lev2
             }
             this.selectedItems.push(dStage)
            this.Goto_Plag = false
          }
          this.fncName = 'AFT_Lv3_Block_Division'
          this.StageCode() // AREA / STAGE
          this.displayGroupID(`#Menu_Back`, 0, 200, 1)

          // ### Call Canvas ###
          this.Canvas_Division_Lv3(this.Lev1, this.Lev2)
        }, 1500);
      }


      // HULL-FOR --------------------------------------------------------------------------------------
      this.FOR_Lv3_Block_Division = () => { 
        
        // Remove SVG
        this.removeSvg(this.svg_BlockCode, 1000) 
        this.removeSvg(this.CANVAS, 1000)
        
        // Title & Keyplan
        d3.select(`#Title`).text(`HULL-FOR`)
        d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Keyplan/HULL_FOR.png`)

        // Scale
        d3.select(`#Hull_FOR_Group`)
        .transition().duration(1000).attr('transform', `translate(0,0) scale(1)`)

        // Unexplode
        this.Hull_FOR.filter(f=> f.Lv4 == this.Lev4).forEach(d => {
          d3.select(`#Blk_${d.Blk}`)
          .transition().duration(1000).attr('transform', `translate(${d.x2}, ${d.y2}) scale(0.5)`)
        })

        setTimeout(() => {
          // Display
          this.Hull_FOR.filter(f=> f.Lv2 == this.Lev2 && f.Lv3 !== this.Lev3).forEach(d => {
            this.displayGroupID(`#Blk_${d.Blk}`, 0, 500, 1)
          })
        }, 1000);
        
        setTimeout(() => {
          // Display
          this.displayGroupID(`#Mask_HL03`, 0, 10, 0)
          this.displayGroupID(`#Mask_MD00f`, 0, 10, 0)
          this.displayGroupID(`#Mask_VS4_05`, 0, 10, 0)

          this.stage = this.STG_Division_Lv3
          if (this.Goto_Plag) {
            let dStage = {
              stage: this.STG_Division_Lv3,
              mask : "FOR",
              level: this.Lev2
             }
             this.selectedItems.push(dStage)
            this.Goto_Plag = false
          }
          this.fncName = 'FOR_Lv3_Block_Division'
          this.StageCode() // AREA / STAGE
          this.displayGroupID(`#Menu_Back`, 0, 200, 1)

          // ### Call Canvas ###
          this.Canvas_Division_Lv3(this.Lev1, this.Lev2)
        }, 1500);
      }


      // LQ --------------------------------------------------------------------------------------
      this.LQ_Lv3_Block_Division = () => { 
        
        // Remove SVG
        this.removeSvg(this.svg_BlockCode, 1000)
        this.removeSvg(this.CANVAS, 1000)
        
        // Title & Keyplan
        d3.select(`#Title`).text(`HULL-LQ`)
        d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Keyplan/HULL_LQ.png`)

        // Scale
        d3.select(`#Hull_LQ_Group`)
        .transition().duration(1000).attr('transform', `translate(0,0) scale(1)`)

        // Unexplode
        this.Hull_LQ.forEach(d => {
          d3.select(`#Blk_${d.Blk}`)
          .transition().duration(1000).attr('transform', `translate(${d.x2}, ${d.y2}) scale(0.5)`)
        })

        setTimeout(() => {
          // Display
          this.Hull_LQ.filter(f=> f.Lv2 == this.Lev2 && f.Lv3 !== this.Lev3).forEach(d => {
            this.displayGroupID(`#Blk_${d.Blk}`, 0, 500, 1)
          })
        }, 1000);
        
        setTimeout(() => {
          // Display
          this.displayGroupID(`#Mask_LQ00`, 0, 10, 0)
          this.displayGroupID(`#Mask_HD01`, 0, 10, 0)

          this.stage = this.STG_Division_Lv3
          if (this.Goto_Plag) {
            let dStage = {
              stage: this.STG_Division_Lv3,
              mask : "HULL-LQ",
              level: this.Lev2
             }
             this.selectedItems.push(dStage)
            this.Goto_Plag = false
          }
          this.fncName = 'LQ_Lv3_Block_Division'
          this.StageCode() // AREA / STAGE
          this.displayGroupID(`#Menu_Back`, 0, 200, 1)

          // ### Call Canvas ###
          this.Canvas_Division_Lv3(this.Lev1, this.Lev2)
        }, 1500);
      }
    },
  }
}