import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  data: () => ({

    Progress: [
      {phase: 'CON', name: 'Construction',      size: 88,  plan: null, actual: null, delta: null,},
      {phase: 'MC',  name: 'Mech. Completion',  size: 115, plan: null, actual: null, delta: null,},
      {phase: 'COM', name: 'Pre-Commissioning', size: 125, plan: null, actual: null, delta: null,},
    ],

  }),

  methods: {

    SummaryTable_Punch(selection, d, _data) {
      let _self = this
      let summaryTable = selection
      .append('g')
      .attr('id', '')
      .attr('transform', `translate(${d.x}, ${d.y})`)
      
      summaryTable
      .append('rect')
      .attr('transform', `translate(0, 0)`)
      .style('width', d.width).style('height', d.height).style('fill', d.bColor).attr('rx', d.radius).attr('ry', d.radius).style('opacity', d.opacity)

      summaryTable
      .append('text') 
      .attr('transform', `translate(${d.cell[0]}, ${d.y1})`)
      .style('font-family', 'roboto').style('font-size', d.tSize)
      .style('fill', d.tColor).style('text-anchor', 'end').style('alignment-baseline', 'middle').text(d.title[0])

      summaryTable
      .append('text') 
      .attr('transform', `translate(${d.cell[1]}, ${d.y1})`)
      .style('font-family', 'roboto').style('font-size', d.tSize)
      .style('fill', d.tColor).style('text-anchor', 'end').style('alignment-baseline', 'middle').text(d.title[1])

      summaryTable
      .append('text') 
      .attr('transform', `translate(${d.cell[2]}, ${d.y1})`)
      .style('font-family', 'roboto').style('font-size', d.tSize)
      .style('fill', d.tColor).style('text-anchor', 'end').style('alignment-baseline', 'middle').text(d.title[2])

      summaryTable // Total
      .append('text')
      .attr('id', `${d.id}_Total}`)
      .attr('transform', `translate(${d.cell[0]}, ${d.y2+2})`)
      .style('font-family', 'roboto').style('font-size', d.vSize)
      .style('fill', d.vColor).style('text-anchor', 'end').style('alignment-baseline', 'middle')
      .text(d.value[0])
      .style('cursor', 'pointer')
      .on('mouseover', function() {
        let node = _self.getNodeElValue(`#${d.id}_Total`,'width') / 5.5
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(${d.cell[0] + node}, ${d.y2}) scale(1.3)`).style('fill', '#EC407A')
      })
      .on('mouseout', function(){
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(${d.cell[0]}, ${d.y2}) scale(1)`).style('fill', d.vColor)
      })
      .on('click', () => {
        let dataSource = {}
        let request_ = this.getRequestColumnProps('requestsum', dataSource, this.ColumnProps)        
        if(!request_) return
        let filterString_ = ''
        if(d.sqlfilter.FILTER1 && request_.iFilters.filterString) {
          filterString_ = request_.iFilters.filterString + ' AND ' + d.sqlfilter.FILTER1
        }else if(d.sqlfilter.FILTER1) {
          filterString_ = d.sqlfilter.FILTER1
        }else if(request_.iFilters.filterString) {
          filterString_ = request_.iFilters.filterString
        }
        request_ = {...request_,
          iFilters: { filterString : filterString_},
        }
        this.$emit('request-action', request_)
      })
      
      summaryTable // Actual
      .append('text')
      .attr('id', `${d.id}_Actual`)
      .attr('transform', `translate(${d.cell[1]}, ${d.y2+2})`)
      .style('font-family', 'roboto').style('font-size', d.vSize)
      .style('fill', d.vColor).style('text-anchor', 'end').style('alignment-baseline', 'middle')
      .text(d.value[1])
      .style('cursor', 'pointer')
      .on('mouseover', function() {
        let node = _self.getNodeElValue(`#${d.id}_Actual`,'width') / 5.5
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(${d.cell[1] + node}, ${d.y2}) scale(1.3)`).style('fill', '#EC407A')
      })
      .on('mouseout', function(){
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(${d.cell[1]}, ${d.y2}) scale(1)`).style('fill', d.vColor)
      })
      .on('click', () => { 
        let dataSource = {}
        let request_ = this.getRequestColumnProps('requestsum', dataSource, this.ColumnProps)        
        if(!request_) return
        let filterString_ = ''
        if(d.sqlfilter.FILTER2 && request_.iFilters.filterString) {
          filterString_ = request_.iFilters.filterString + ' AND ' + d.sqlfilter.FILTER2
        }else if(d.sqlfilter.FILTER2) {
          filterString_ = d.sqlfilter.FILTER2
        }else if(request_.iFilters.filterString) {
          filterString_ = request_.iFilters.filterString
        }
        request_ = {...request_,
          iFilters: { filterString : filterString_},
        }
        this.$emit('request-action', request_)
      })

      summaryTable // Remain
      .append('text')
      .attr('id', `${d.id}_Remain`)
      .attr('transform', `translate(${d.cell[2]}, ${d.y2+2})`)
      .style('font-family', 'roboto').style('font-size', d.vSize)
      .style('fill', d.vColor).style('text-anchor', 'end').style('alignment-baseline', 'middle')
      .text(d.value[2])
      .style('cursor', 'pointer')
      .on('mouseover', function() {
        let node = _self.getNodeElValue(`#${d.id}_Remain`,'width') / 5.5
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(${d.cell[2] + node}, ${d.y2}) scale(1.3)`).style('fill', '#EC407A')
      })
      .on('mouseout', function(){
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(${d.cell[2]}, ${d.y2}) scale(1)`).style('fill', d.vColor)
      })
      .on('click', () => { 
        let dataSource = {}
        let request_ = this.getRequestColumnProps('requestsum', dataSource, this.ColumnProps)        
        if(!request_) return
        let filterString_ = ''
        if(d.sqlfilter.FILTER3 && request_.iFilters.filterString) {
          filterString_ = request_.iFilters.filterString + ' AND ' + d.sqlfilter.FILTER3
        }else if(d.sqlfilter.FILTER3) {
          filterString_ = d.sqlfilter.FILTER3
        }else if(request_.iFilters.filterString) {
          filterString_ = request_.iFilters.filterString
        }
        request_ = {...request_,
          iFilters: { filterString : filterString_},
        }
        this.$emit('request-action', request_)
      })
    },

    SummaryTable_Single(selection, d) {
      let _self = this
      let summaryTable = selection
      .append('g')
      .attr('id', '')
      .attr('transform', `translate(${d.x}, ${d.y})`)
      
      summaryTable
      .append('rect')
      .attr('transform', `translate(0, 0)`)
      .style('width', d.width).style('height', d.height).style('fill', d.bColor).attr('rx', d.radius).attr('ry', d.radius).style('opacity', d.opacity)

      summaryTable
      .append('text') 
      .attr('transform', `translate(${d.cell[0]}, ${d.y1})`)
      .style('font-family', 'roboto').style('font-size', d.tSize)
      .style('fill', d.tColor).style('text-anchor', 'end').style('alignment-baseline', 'middle').text(d.title[0])

      summaryTable // Total
      .append('text')
      .attr('id', `${d.id}_Total}`)
      .attr('transform', `translate(${d.cell[0]}, ${d.y2})`)
      .style('font-family', 'roboto').style('font-size', d.vSize)
      .style('fill', d.vColor).style('text-anchor', 'end').style('alignment-baseline', 'middle')
      .text(d.value[0])
      .style('cursor', 'pointer')
      .on('mouseover', function() {
        let node = _self.getNodeElValue(`#${d.id}_Total`,'width') / 5.5
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(${d.cell[0] + node}, ${d.y2}) scale(1.3)`).style('fill', '#EC407A')
      })
      .on('mouseout', function(){
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(${d.cell[0]}, ${d.y2}) scale(1)`).style('fill', d.vColor)
      })
      .on('click', () => { 
        this.$emit('request-action', d.requests ? d.requests[0] : {})
      })
    },
    SummaryTable_01(selection, d) {
      let _self = this
      let summaryTable = selection
      .append('g')
      .attr('id', '')
      .attr('transform', `translate(${d.x}, ${d.y})`)
      
      summaryTable
      .append('rect')
      .attr('transform', `translate(0, 0)`)
      .style('width', d.width).style('height', d.height).style('fill', d.bColor).attr('rx', d.radius).attr('ry', d.radius).style('opacity', d.opacity)

      summaryTable
      .append('text') 
      .attr('transform', `translate(${d.cell[0]}, ${d.y1})`)
      .style('font-family', 'roboto').style('font-size', d.tSize)
      .style('fill', d.tColor).style('text-anchor', 'end').style('alignment-baseline', 'middle').text(d.title[0])

      summaryTable
      .append('text') 
      .attr('transform', `translate(${d.cell[1]}, ${d.y1})`)
      .style('font-family', 'roboto').style('font-size', d.tSize)
      .style('fill', d.tColor).style('text-anchor', 'end').style('alignment-baseline', 'middle').text(d.title[1])

      summaryTable
      .append('text') 
      .attr('transform', `translate(${d.cell[2]}, ${d.y1})`)
      .style('font-family', 'roboto').style('font-size', d.tSize)
      .style('fill', d.tColor).style('text-anchor', 'end').style('alignment-baseline', 'middle').text(d.title[2])

      summaryTable // Total
      .append('text')
      .attr('id', `${d.id}_Total}`)
      .attr('transform', `translate(${d.cell[0]}, ${d.y2})`)
      .style('font-family', 'roboto').style('font-size', d.vSize)
      .style('fill', d.vColor).style('text-anchor', 'end').style('alignment-baseline', 'middle')
      .text(d.value[0])
      .style('cursor', 'pointer')
      .on('mouseover', function() {
        let node = _self.getNodeElValue(`#${d.id}_Total`,'width') / 5.5
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(${d.cell[0] + node}, ${d.y2}) scale(1.3)`).style('fill', '#EC407A')
      })
      .on('mouseout', function(){
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(${d.cell[0]}, ${d.y2}) scale(1)`).style('fill', d.vColor)
      })
      .on('click', () => { 
        let dataSource = {}
        let request_ = this.getRequestColumnProps('requestsum', dataSource, this.ColumnProps)
        if(!request_) return

        let filterString_ = ''

        if(d.sqlfilter.FILTER1 && request_.iFilters.filterString) {
          filterString_ = request_.iFilters.filterString + ' AND ' + d.sqlfilter.FILTER1
        }else if(d.sqlfilter.FILTER1) {
          filterString_ = d.sqlfilter.FILTER1
        }else if(request_.iFilters.filterString) {
          filterString_ = request_.iFilters.filterString
        }

        request_ = {...request_,
          iFilters: { filterString : filterString_},
        }

        this.$emit('request-action', request_)
      })
      
      summaryTable // Actual
      .append('text')
      .attr('id', `${d.id}_Actual`)
      .attr('transform', `translate(${d.cell[1]}, ${d.y2})`)
      .style('font-family', 'roboto').style('font-size', d.vSize)
      .style('fill', d.vColor).style('text-anchor', 'end').style('alignment-baseline', 'middle')
      .text(d.value[1])
      .style('cursor', 'pointer')
      .on('mouseover', function() {
        let node = _self.getNodeElValue(`#${d.id}_Actual`,'width') / 5.5
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(${d.cell[1] + node}, ${d.y2}) scale(1.3)`).style('fill', '#EC407A')
      })
      .on('mouseout', function(){
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(${d.cell[1]}, ${d.y2}) scale(1)`).style('fill', d.vColor)
      })
      .on('click', () => { 
        let dataSource = {}
        let request_ = this.getRequestColumnProps('requestsum', dataSource, this.ColumnProps)
        if(!request_) return

        let filterString_ = ''

        if(d.sqlfilter.FILTER2 && request_.iFilters.filterString) {
          filterString_ = request_.iFilters.filterString + ' AND ' + d.sqlfilter.FILTER2
        }else if(d.sqlfilter.FILTER2) {
          filterString_ = d.sqlfilter.FILTER2
        }else if(request_.iFilters.filterString) {
          filterString_ = request_.iFilters.filterString
        }
        request_ = {...request_,
          iFilters: { filterString : filterString_},
        }
        this.$emit('request-action', request_)
      })

      summaryTable // Remain
      .append('text')
      .attr('id', `${d.id}_Remain`)
      .attr('transform', `translate(${d.cell[2]}, ${d.y2})`)
      .style('font-family', 'roboto').style('font-size', d.vSize)
      .style('fill', d.vColor).style('text-anchor', 'end').style('alignment-baseline', 'middle')
      .text(d.value[2])
      .style('cursor', 'pointer')
      .on('mouseover', function() {
        let node = _self.getNodeElValue(`#${d.id}_Remain`,'width') / 5.5
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(${d.cell[2] + node}, ${d.y2}) scale(1.3)`).style('fill', '#EC407A')
      })
      .on('mouseout', function(){
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(${d.cell[2]}, ${d.y2}) scale(1)`).style('fill', d.vColor)
      })
      .on('click', () => { 
        let dataSource = {}
        let request_ = this.getRequestColumnProps('requestsum', dataSource, this.ColumnProps)
        if(!request_) return

        let filterString_ = ''

        if(d.sqlfilter.FILTER3 && request_.iFilters.filterString) {
          filterString_ = request_.iFilters.filterString + ' AND ' + d.sqlfilter.FILTER3
        }else if(d.sqlfilter.FILTER3) {
          filterString_ = d.sqlfilter.FILTER3
        }else if(request_.iFilters.filterString) {
          filterString_ = request_.iFilters.filterString
        }
        request_ = {...request_,
          iFilters: { filterString : filterString_},
        }
        this.$emit('request-action', request_)
      })
    },

    SummaryTable_02(selection, _data, _style) {
      let _self = this
      let summaryTable = selection
      .append('g')
      .attr('id', '')
      .attr('transform', `translate(${_style.x}, ${_style.y})`)
      
      summaryTable
      .append('rect')
      .attr('transform', `translate(0, 0)`)
      .style('width', _style.width).style('height', _style.height).style('fill', _style.bColor).attr('rx', _style.radius).attr('ry', _style.radius).style('opacity', _style.opacity)


      _style.title.forEach((d,i)=> {
        summaryTable
        .append('text') 
        .attr('transform', `translate(${_style.cell[i]}, ${_style.y1})`)
        .style('font-family', 'roboto').style('font-size', _style.tSize)
        .style('fill', _style.tColor).style('text-anchor', 'end').style('alignment-baseline', 'middle').text(d)
      })
      

      _data.forEach((d,i) => {

        summaryTable // CAT
        .append('text')
        .attr('transform', `translate(${_style.cell[0]}, ${_style.cellY[i]})`)
        .style('font-family', 'roboto').style('font-size', _style.vSize)
        .style('fill', '#757575').style('text-anchor', 'end').style('alignment-baseline', 'middle')
        .text(d.CAT)

        summaryTable // Total
        .append('text')
        .attr('id', `${d.CAT}_Raised`)
        .attr('transform', `translate(${_style.cell[1]}, ${_style.cellY[i]})`)
        .style('font-family', 'roboto').style('font-size', _style.vSize)
        .style('fill', _style.vColor).style('text-anchor', 'end').style('alignment-baseline', 'middle')
        .text(d.TOTAL)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          let node = _self.getNodeElValue(`#${d.CAT}_Raised`,'width') / 5.5
          d3.select(this).transition().duration(100)
          .attr('transform', `translate(${_style.cell[1] + node}, ${_style.cellY[i]}) scale(1.3)`).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100)
          .attr('transform', `translate(${_style.cell[1]}, ${_style.cellY[i]}) scale(1)`).style('fill', _style.vColor)
        })
        .on('click', () => { 
          if(_style.requests) {
            _style.requests[0].filters.CAT = d.CAT
            this.$emit('request-action', _style.requests[0])
          }
        })
        
        summaryTable // Actual
        .append('text')
        .attr('id', `${d.CAT}_Closed`)
        .attr('transform', `translate(${_style.cell[2]}, ${_style.cellY[i]})`)
        .style('font-family', 'roboto').style('font-size', _style.vSize)
        .style('fill', _style.vColor).style('text-anchor', 'end').style('alignment-baseline', 'middle')
        .text(d.ACTUAL)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          let node = _self.getNodeElValue(`#${d.CAT}_Closed`,'width') / 5.5
          d3.select(this).transition().duration(100)
          .attr('transform', `translate(${_style.cell[2] + node}, ${_style.cellY[i]}) scale(1.3)`).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100)
          .attr('transform', `translate(${_style.cell[2]}, ${_style.cellY[i]}) scale(1)`).style('fill', _style.vColor)
        })
        .on('click', () => { 
          if(_style.requests) {
            _style.requests[1].filters.CAT = d.CAT
            this.$emit('request-action', _style.requests[1])
          }
        })

        summaryTable // Remain
        .append('text')
        .attr('id', `${d.CAT}_Opened`)
        .attr('transform', `translate(${_style.cell[3]}, ${_style.cellY[i]})`)
        .style('font-family', 'roboto').style('font-size', _style.vSize)
        .style('fill', _style.vColor).style('text-anchor', 'end').style('alignment-baseline', 'middle')
        .text(d.REMAIN)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          let node = _self.getNodeElValue(`#${d.CAT}_Opened`,'width') / 5.5
          d3.select(this).transition().duration(100)
          .attr('transform', `translate(${_style.cell[3] + node}, ${_style.cellY[i]}) scale(1.3)`).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100)
          .attr('transform', `translate(${_style.cell[3]}, ${_style.cellY[i]}) scale(1)`).style('fill', _style.vColor)
        })
        .on('click', () => { 
          if(_style.requests) {
            _style.requests[2].filters.CAT = d.CAT
            this.$emit('request-action', _style.requests[2])
          }
        })
      })
    },

    SummaryTable_03(selection, d) {
      let _self = this
      let summaryTable = selection
      .append('g')
      .attr('transform', `translate(${d.x}, ${d.y})`)
      
      summaryTable
      .append('rect')
      .attr('transform', `translate(0, 0)`)
      .style('width', d.width).style('height', d.height).style('fill', d.bColor).attr('rx', d.radius).attr('ry', d.radius).style('opacity', d.opacity)
      
      summaryTable
      .append('text') 
      .attr('transform', `translate(${d.cell[0]}, ${d.y1})`)
      .style('font-family', 'roboto').style('font-size', d.tSize)
      .style('fill', d.tColor).style('text-anchor', 'end').style('alignment-baseline', 'middle').text(d.title[0])

      summaryTable
      .append('text') 
      .attr('transform', `translate(${d.cell[1]}, ${d.y1})`)
      .style('font-family', 'roboto').style('font-size', d.tSize)
      .style('fill', d.tColor).style('text-anchor', 'end').style('alignment-baseline', 'middle').text(d.title[1])


      summaryTable // Total
      .append('text')
      .attr('id', `${d.id}_${d.title[0]}`)
      .attr('transform', `translate(${d.cell[0]}, ${d.y2})`)
      .style('font-family', 'roboto').style('font-size', d.vSize)
      .style('fill', d.vColor).style('text-anchor', 'end').style('alignment-baseline', 'middle')
      .text(d.value[0])
      .style('cursor', 'pointer')
      .on('mouseover', function() {
        let node = _self.getNodeElValue(`#${d.id}_${d.title[0]}`,'width') / 5.5
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(${d.cell[0] + node}, ${d.y2}) scale(1.3)`).style('fill', '#EC407A')
      })
      .on('mouseout', function(){
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(${d.cell[0]}, ${d.y2}) scale(1)`).style('fill', d.vColor)
      })
      .on('click', () => { 
        if(d.requests) this.$emit('request-action', d.requests[0])
      })
      

      summaryTable // Remain
      .append('text')
      .attr('id', `${d.id}_${d.title[1]}`)
      .attr('transform', `translate(${d.cell[1]}, ${d.y2})`)
      .style('font-family', 'roboto').style('font-size', d.vSize)
      .style('fill', d.vColor).style('text-anchor', 'end').style('alignment-baseline', 'middle').text(d.value[1])
      .style('cursor', 'pointer')
      .on('mouseover', function() {
        let node = _self.getNodeElValue(`#${d.id}_${d.title[1]}`,'width') / 5.5
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(${d.cell[2] + node}, ${d.y2}) scale(1.3)`).style('fill', '#EC407A')
      })
      .on('mouseout', function(){
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(${d.cell[1]}, ${d.y2}) scale(1)`).style('fill', d.vColor)
      })
      .on('click', () => { 
        if(d.requests) this.$emit('request-action', d.requests[1])
      })
    },
    SummaryTable_04(selection, d) {
      let _self = this
      let summaryTable = selection
      .append('g')
      .attr('id', 'CDRESummary')
      .attr('transform', `translate(${d.x}, ${d.y})`)
      
      summaryTable
      .append('rect')
      .attr('transform', `translate(0, 0)`)
      .style('width', d.width).style('height', d.height).style('fill', d.bColor).attr('rx', d.radius).attr('ry', d.radius).style('opacity', d.opacity)

      summaryTable
      .append('text') 
      .attr('transform', `translate(${d.cell[0]}, ${d.y0})`)
      .style('font-family', 'roboto').style('font-size', d.tSize)
      .style('fill', d.tColor).style('text-anchor', 'middle').style('alignment-baseline', 'middle').text(d.title[0])

      summaryTable
      .append('text') 
      .attr('transform', `translate(${d.cell[1]}, ${d.y0})`)
      .style('font-family', 'roboto').style('font-size', d.tSize)
      .style('fill', d.tColor).style('text-anchor', 'middle').style('alignment-baseline', 'middle').text(d.title[1])

      summaryTable
      .append('text') 
      .attr('transform', `translate(${d.cell[2]}, ${d.y1})`)
      .style('font-family', 'roboto').style('font-size', d.tSize)
      .style('fill', d.tColor).style('text-anchor', 'middle').style('alignment-baseline', 'middle').text(d.title[2])
      summaryTable
      .append('text') 
      .attr('transform', `translate(${d.cell[2]}, ${d.y2})`)
      .style('font-family', 'roboto').style('font-size', d.tSize)
      .style('fill', d.tColor).style('text-anchor', 'middle').style('alignment-baseline', 'middle').text(d.underTitle[0])

      summaryTable
      .append('text') 
      .attr('transform', `translate(${d.cell[3]}, ${d.y1})`)
      .style('font-family', 'roboto').style('font-size', d.tSize)
      .style('fill', d.tColor).style('text-anchor', 'middle').style('alignment-baseline', 'middle').text(d.title[3])
      summaryTable
      .append('text') 
      .attr('transform', `translate(${d.cell[3]}, ${d.y2})`)
      .style('font-family', 'roboto').style('font-size', d.tSize)
      .style('fill', d.tColor).style('text-anchor', 'middle').style('alignment-baseline', 'middle').text(d.underTitle[1])

      summaryTable // Total
      .append('text')
      .attr('id', `${d.id}_Total}`)
      .attr('transform', `translate(${d.cell[0]}, ${d.y3})`)
      .style('font-family', 'roboto').style('font-size', d.vSize)
      .style('fill', d.vColor).style('text-anchor', 'middle').style('alignment-baseline', 'middle')
      .text(d.value[0])
      // .style('cursor', 'pointer')
      // .on('mouseover', function() {
      //   let node = _self.getNodeElValue(`#${d.id}_Total`,'width') / 5.5
      //   d3.select(this).transition().duration(100)
      //   .attr('transform', `translate(${d.cell[0] + node}, ${d.y3}) scale(1.3)`).style('fill', '#EC407A')
      // })
      // .on('mouseout', function(){
      //   d3.select(this).transition().duration(100)
      //   .attr('transform', `translate(${d.cell[0]}, ${d.y3}) scale(1)`).style('fill', d.vColor)
      // })
      // .on('click', () => { 
      //   this.$emit('request-action', d.requests ? d.requests[0] : {})
      // })
      
      summaryTable // Actual
      .append('text')
      .attr('id', `${d.id}_Actual`)
      .attr('transform', `translate(${d.cell[1]}, ${d.y3})`)
      .style('font-family', 'roboto').style('font-size', d.vSize)
      .style('fill', d.vColor).style('text-anchor', 'middle').style('alignment-baseline', 'middle')
      .text(d.value[1])
      // .style('cursor', 'pointer')
      // .on('mouseover', function() {
      //   let node = _self.getNodeElValue(`#${d.id}_Actual`,'width') / 5.5
      //   d3.select(this).transition().duration(100)
      //   .attr('transform', `translate(${d.cell[1] + node}, ${d.y3}) scale(1.3)`).style('fill', '#EC407A')
      // })
      // .on('mouseout', function(){
      //   d3.select(this).transition().duration(100)
      //   .attr('transform', `translate(${d.cell[1]}, ${d.y3}) scale(1)`).style('fill', d.vColor)
      // })
      // .on('click', () => { 
      //   this.$emit('request-action', d.requests ? d.requests[1] : {})
      // })

      summaryTable // Remain
      .append('text')
      .attr('id', `${d.id}_Remain`)
      .attr('transform', `translate(${d.cell[2]}, ${d.y3})`)
      .style('font-family', 'roboto').style('font-size', d.vSize)
      .style('fill', d.vColor).style('text-anchor', 'middle').style('alignment-baseline', 'middle')
      .text(d.value[2])
      // .style('cursor', 'pointer')
      // .on('mouseover', function() {
      //   let node = _self.getNodeElValue(`#${d.id}_Remain`,'width') / 5.5
      //   d3.select(this).transition().duration(100)
      //   .attr('transform', `translate(${d.cell[2] + node}, ${d.y3}) scale(1.3)`).style('fill', '#EC407A')
      // })
      // .on('mouseout', function(){
      //   d3.select(this).transition().duration(100)
      //   .attr('transform', `translate(${d.cell[2]}, ${d.y3}) scale(1)`).style('fill', d.vColor)
      // })
      // .on('click', () => { 
      //   this.$emit('request-action', d.requests ? d.requests[2] : {})
      // })
      summaryTable // Remain
      .append('text')
      .attr('id', `${d.id}_Dev`)
      .attr('transform', `translate(${d.cell[3]}, ${d.y3})`)
      .style('font-family', 'roboto').style('font-size', d.vSize)
      .style('fill', d.vColor).style('text-anchor', 'middle').style('alignment-baseline', 'middle')
      .text(d.value[3])
      // .style('cursor', 'pointer')
      // .on('mouseover', function() {
      //   let node = _self.getNodeElValue(`#${d.id}_Dev`,'width') / 5.5
      //   d3.select(this).transition().duration(100)
      //   .attr('transform', `translate(${d.cell[3] + node}, ${d.y3}) scale(1.3)`).style('fill', '#EC407A')
      // })
      // .on('mouseout', function(){
      //   d3.select(this).transition().duration(100)
      //   .attr('transform', `translate(${d.cell[3]}, ${d.y3}) scale(1)`).style('fill', d.vColor)
      // })
      // .on('click', () => { 
      //   this.$emit('request-action', d.requests ? d.requests[2] : {})
      // })
      
    },
    SummaryTable_05(selection, d) {
      let _self = this
      let summaryTable = selection
      .append('g')
      .attr('id', 'TQSummary')
      .attr('transform', `translate(${d.x}, ${d.y})`)
      
      summaryTable
      .append('rect')
      .attr('transform', `translate(0, 0)`)
      .style('width', d.width).style('height', d.height).style('fill', d.bColor).attr('rx', d.radius).attr('ry', d.radius).style('opacity', d.opacity)

      summaryTable
      .append('text') 
      .attr('transform', `translate(${d.cell[0]}, ${d.y0})`)
      .style('font-family', 'roboto').style('font-size', d.tSize)
      .style('fill', d.tColor).style('text-anchor', 'middle').style('alignment-baseline', 'middle').text(d.title[0])

      summaryTable
      .append('text') 
      .attr('transform', `translate(${d.cell[1]}, ${d.y0})`)
      .style('font-family', 'roboto').style('font-size', d.tSize)
      .style('fill', d.tColor).style('text-anchor', 'middle').style('alignment-baseline', 'middle').text(d.title[1])

      summaryTable
      .append('text') 
      .attr('transform', `translate(${d.cell[2]}, ${d.y0})`)
      .style('font-family', 'roboto').style('font-size', d.tSize)
      .style('fill', d.tColor).style('text-anchor', 'middle').style('alignment-baseline', 'middle').text(d.title[2])

      summaryTable
      .append('text') 
      .attr('transform', `translate(${d.cell[3]}, ${d.y1})`)
      .style('font-family', 'roboto').style('font-size', d.tSize)
      .style('fill', d.tColor).style('text-anchor', 'middle').style('alignment-baseline', 'middle').text(d.title[3])
      summaryTable
      .append('text') 
      .attr('transform', `translate(${d.cell[3]}, ${d.y2})`)
      .style('font-family', 'roboto').style('font-size', d.tSize)
      .style('fill', d.tColor).style('text-anchor', 'middle').style('alignment-baseline', 'middle').text(d.underTitle[0])
      
      summaryTable
      .append('text') 
      .attr('transform', `translate(${d.cell[4]}, ${d.y1})`)
      .style('font-family', 'roboto').style('font-size', d.tSize)
      .style('fill', d.tColor).style('text-anchor', 'middle').style('alignment-baseline', 'middle').text(d.title[4])
      summaryTable
      .append('text') 
      .attr('transform', `translate(${d.cell[4]}, ${d.y2})`)
      .style('font-family', 'roboto').style('font-size', d.tSize)
      .style('fill', d.tColor).style('text-anchor', 'middle').style('alignment-baseline', 'middle').text(d.underTitle[1])

      summaryTable // Total
      .append('text')
      .attr('id', `${d.id}_Total}`)
      .attr('transform', `translate(${d.cell[0]}, ${d.y3})`)
      .style('font-family', 'roboto').style('font-size', d.vSize)
      .style('fill', d.vColor).style('text-anchor', 'middle').style('alignment-baseline', 'middle')
      .text(d.value[0])
      // .style('cursor', 'pointer')
      // .on('mouseover', function() {
      //   let node = _self.getNodeElValue(`#${d.id}_Total`,'width') / 5.5
      //   d3.select(this).transition().duration(100)
      //   .attr('transform', `translate(${d.cell[0] + node}, ${d.y3}) scale(1.3)`).style('fill', '#EC407A')
      // })
      // .on('mouseout', function(){
      //   d3.select(this).transition().duration(100)
      //   .attr('transform', `translate(${d.cell[0]}, ${d.y3}) scale(1)`).style('fill', d.vColor)
      // })
      // .on('click', () => { 
      //   this.$emit('request-action', d.requests ? d.requests[0] : {})
      // })
      
      summaryTable // Actual
      .append('text')
      .attr('id', `${d.id}_Actual`)
      .attr('transform', `translate(${d.cell[1]}, ${d.y3})`)
      .style('font-family', 'roboto').style('font-size', d.vSize)
      .style('fill', d.vColor).style('text-anchor', 'middle').style('alignment-baseline', 'middle')
      .text(d.value[1])
      // .style('cursor', 'pointer')
      // .on('mouseover', function() {
      //   let node = _self.getNodeElValue(`#${d.id}_Actual`,'width') / 5.5
      //   d3.select(this).transition().duration(100)
      //   .attr('transform', `translate(${d.cell[1] + node}, ${d.y3}) scale(1.3)`).style('fill', '#EC407A')
      // })
      // .on('mouseout', function(){
      //   d3.select(this).transition().duration(100)
      //   .attr('transform', `translate(${d.cell[1]}, ${d.y3}) scale(1)`).style('fill', d.vColor)
      // })
      // .on('click', () => { 
      //   this.$emit('request-action', d.requests ? d.requests[1] : {})
      // })

      summaryTable // Remain
      .append('text')
      .attr('id', `${d.id}_Remain`)
      .attr('transform', `translate(${d.cell[2]}, ${d.y3})`)
      .style('font-family', 'roboto').style('font-size', d.vSize)
      .style('fill', d.vColor).style('text-anchor', 'middle').style('alignment-baseline', 'middle')
      .text(d.value[2])
      // .style('cursor', 'pointer')
      // .on('mouseover', function() {
      //   let node = _self.getNodeElValue(`#${d.id}_Remain`,'width') / 5.5
      //   d3.select(this).transition().duration(100)
      //   .attr('transform', `translate(${d.cell[2] + node}, ${d.y3}) scale(1.3)`).style('fill', '#EC407A')
      // })
      // .on('mouseout', function(){
      //   d3.select(this).transition().duration(100)
      //   .attr('transform', `translate(${d.cell[2]}, ${d.y3}) scale(1)`).style('fill', d.vColor)
      // })
      // .on('click', () => { 
      //   this.$emit('request-action', d.requests ? d.requests[2] : {})
      // })
      summaryTable // Remain
      .append('text')
      .attr('id', `${d.id}_Dev`)
      .attr('transform', `translate(${d.cell[3]}, ${d.y3})`)
      .style('font-family', 'roboto').style('font-size', d.vSize)
      .style('fill', d.vColor).style('text-anchor', 'middle').style('alignment-baseline', 'middle')
      .text(d.value[3])
      // .style('cursor', 'pointer')
      // .on('mouseover', function() {
      //   let node = _self.getNodeElValue(`#${d.id}_Dev`,'width') / 5.5
      //   d3.select(this).transition().duration(100)
      //   .attr('transform', `translate(${d.cell[3] + node}, ${d.y3}) scale(1.3)`).style('fill', '#EC407A')
      // })
      // .on('mouseout', function(){
      //   d3.select(this).transition().duration(100)
      //   .attr('transform', `translate(${d.cell[3]}, ${d.y3}) scale(1)`).style('fill', d.vColor)
      // })
      // .on('click', () => { 
      //   this.$emit('request-action', d.requests ? d.requests[2] : {})
      // })
      summaryTable // Remain
      .append('text')
      .attr('id', `${d.id}_Dev`)
      .attr('transform', `translate(${d.cell[4]}, ${d.y3})`)
      .style('font-family', 'roboto').style('font-size', d.vSize)
      .style('fill', d.vColor).style('text-anchor', 'middle').style('alignment-baseline', 'middle')
      .text(d.value[4])
      // .style('cursor', 'pointer')
      // .on('mouseover', function() {
      //   let node = _self.getNodeElValue(`#${d.id}_Dev`,'width') / 5.5
      //   d3.select(this).transition().duration(100)
      //   .attr('transform', `translate(${d.cell[3] + node}, ${d.y3}) scale(1.3)`).style('fill', '#EC407A')
      // })
      // .on('mouseout', function(){
      //   d3.select(this).transition().duration(100)
      //   .attr('transform', `translate(${d.cell[3]}, ${d.y3}) scale(1)`).style('fill', d.vColor)
      // })
      // .on('click', () => { 
      //   this.$emit('request-action', d.requests ? d.requests[2] : {})
      // })
    },
    ProgressWithTable(selection, _size, _phase, _x, _y, _data, _title, _table) {

      let moduleProg = selection
      .append('g')
      .attr('id', '')
      .attr('transform', `translate(${_x}, ${_y})`)

      moduleProg
      .append('rect')
      .attr('transform', `translate(0, 0)`)
      .attr('rx', 3).attr('ry', 3)
      .style('width', _size).style('height', 20).style('fill', '#44A9DF')

      moduleProg
      .append('text')
      .attr('transform', `translate(10, 13)`)
      .style('font-family', 'roboto').style('font-size', 12).style('fill', '#fff').style('text-anchor', 'start')
      .text(_phase)

      moduleProg
      .append('text')
      .attr('transform', `translate(${_size + 10}, 20)`)
      .style('font-family', 'roboto').style('font-size', 9).style('fill', '#bcbcbc').style('text-anchor', 'start').text('Actual Cuml.')

      moduleProg
      .append('text') // Actual Value
      .attr('transform', `translate(${_size + 10}, 10)`)
      .style('font-family', 'roboto').style('font-size', 12).style('fill', '#44A9DF').style('text-anchor', 'start').text(`${_data.PROG}%`)

      if (_table == 'on') {
        moduleProg
        .append('rect')
        .attr('transform', `translate(0, 25)`)
        .style('width', 230).style('height', 35).style('fill', '#88C8EB').attr('rx', 3).attr('ry', 3)

        moduleProg
        .append('text') .attr('transform', `translate(110, 39)`)
        .style('font-family', 'roboto').style('font-size', 9).style('fill', '#757575').style('text-anchor', 'end').text('Total')

        moduleProg
        .append('text') .attr('transform', `translate(170, 39)`)
        .style('font-family', 'roboto').style('font-size', 9).style('fill', '#757575').style('text-anchor', 'end').text('Closed')

        moduleProg
        .append('text') .attr('transform', `translate(220, 39)`)
        .style('font-family', 'roboto').style('font-size', 9).style('fill', '#757575').style('text-anchor', 'end').text('Remain')

        moduleProg
        .append('text') .attr('transform', `translate(10, 41)`)
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#333').style('text-anchor', 'start').text('Quantity')

        moduleProg // Total
        .append('text').attr('transform', `translate(110, 53)`)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#F4F5F5').style('text-anchor', 'end').text(_data.STG1)

        moduleProg // Actual
        .append('text').attr('transform', `translate(170, 53)`)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#F4F5F5').style('text-anchor', 'end').text(_data.STG2)

        moduleProg // Remain
        .append('text').attr('transform', `translate(220, 53)`)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#F4F5F5').style('text-anchor', 'end').text(_data.STG3)
      }
    },



    SummaryTable2(selection, _data, _style) {

      let _self = this
      let summaryTable = selection
      .append('g')
      .attr('id', '')
      .attr('transform', `translate(${_style.x}, ${_style.y})`)
      
      summaryTable
      .append('rect')
      .attr('transform', `translate(0, 0)`)
      .style('width', _style.width).style('height', _style.height).style('fill', _style.bColor).attr('rx', 3).attr('ry', 3).style('opacity', _style.opacity)

      summaryTable
      .append('text') .attr('transform', `translate(${_style.cell[0]}, 15)`)
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#333').style('text-anchor', 'start').text(_style.id)

      summaryTable
      .append('text') .attr('transform', `translate(${_style.cell[1]}, 17)`)
      .style('font-family', 'roboto').style('font-size', 9).style('fill', '#757575').style('text-anchor', 'end').text('Total')

      summaryTable
      .append('text') .attr('transform', `translate(${_style.cell[2]}, 17)`)
      .style('font-family', 'roboto').style('font-size', 9).style('fill', '#757575').style('text-anchor', 'end').text('Closed')

      summaryTable
      .append('text') .attr('transform', `translate(${_style.cell[3]}, 17)`)
      .style('font-family', 'roboto').style('font-size', 9).style('fill', '#757575').style('text-anchor', 'end').text('Remain')

      summaryTable
      .append('text') .attr('transform', `translate(${_style.cell[0]}, 33)`)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#F4F5F5').style('text-anchor', 'start').text(_style.title)

      summaryTable // Total
      .append('text')
      .attr('id', `${_style.id}_Total`)
      .attr('transform', `translate(${_style.cell[1]}, 33)`)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#F4F5F5').style('text-anchor', 'end')
      .text(_data.TOTAL)
      .style('cursor', 'pointer')
      .on('mouseover', function() {
        let node = _self.getNodeElValue(`#${_style.id}_Total`,'width') / 5.5
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(${_style.cell[1] + node}, 33) scale(1.3)`).style('fill', '#EC407A')
      })
      .on('mouseout', function(){
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(${_style.cell[1]}, 33) scale(1)`).style('fill', '#fff')
      })
      .on('click', () => { 
        this.$emit('request-action', _style.requests ? _style.requests[0] : {})
      })

      summaryTable // Actual
      .append('text')
      .attr('id', `${_style.id}_Actual`)
      .attr('transform', `translate(${_style.cell[2]}, 33)`)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#F4F5F5').style('text-anchor', 'end')
      .text(_data.ACTUAL)
      .style('cursor', 'pointer')
      .on('mouseover', function() {
        let node = _self.getNodeElValue(`#${_style.id}_Actual`,'width') / 5.5
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(${_style.cell[2] + node}, 33) scale(1.3)`).style('fill', '#EC407A')
      })
      .on('mouseout', function(){
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(${_style.cell[2]}, 33) scale(1)`).style('fill', '#fff')
      })
      .on('click', () => { 
        this.$emit('request-action', _style.requests ? _style.requests[1] : {})
      })

      summaryTable // Remain
      .append('text')
      .attr('id', `${_style.id}_Remain`)
      .attr('transform', `translate(${_style.cell[3]}, 33)`)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#F4F5F5').style('text-anchor', 'end')
      .text(_data.REMAIN)
      .style('cursor', 'pointer')
      .on('mouseover', function() {
        let node = _self.getNodeElValue(`#${_style.id}_Remain`,'width') / 5.5
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(${_style.cell[3] + node}, 33) scale(1.3)`).style('fill', '#EC407A')
      })
      .on('mouseout', function(){
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(${_style.cell[3]}, 33) scale(1)`).style('fill', '#fff')
      })
      .on('click', () => { 
        this.$emit('request-action', _style.requests ? _style.requests[2] : {})
      })
    },



    SummaryTable3(selection, _data1, _data2, _style) {
      let _self = this
      let SummaryLibrary3 = selection
      .append('g')
      .attr('id', '')
      .attr('transform', `translate(${_style.x}, ${_style.y})`)

      SummaryLibrary3
      .append('rect').attr('transform', `translate(0, 0)`)
      .style('width', _style.width).style('height', _style.height).style('fill', _style.bColor).attr('rx', 3).attr('ry', 3).style('opacity', _style.opacity)

      SummaryLibrary3
      .append('text') .attr('transform', `translate(${_style.cell[0]}, 15)`)
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#333').style('text-anchor', 'start').text(_style.id)

      SummaryLibrary3
      .append('text') .attr('transform', `translate(${_style.cell[1]}, 17)`)
      .style('font-family', 'roboto').style('font-size', 9).style('fill', '#757575').style('text-anchor', 'end').text('Total')

      SummaryLibrary3
      .append('text').attr('transform', `translate(${_style.cell[2]}, 17)`)
      .style('font-family', 'roboto').style('font-size', 9).style('fill', '#757575').style('text-anchor', 'end').text('Closed')

      SummaryLibrary3
      .append('text') .attr('transform', `translate(${_style.cell[3]}, 17)`)
      .style('font-family', 'roboto').style('font-size', 9).style('fill', '#757575').style('text-anchor', 'end').text('Remain')

      SummaryLibrary3
      .append('text') 
      .attr('transform', `translate(${_style.cell[0]}, 35)`)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#F4F5F5').style('text-anchor', 'start').text(_data1[0])

      // Punch A - Total
      SummaryLibrary3 
      .append('text')
      .attr('id', `PA_Total`)
      .attr('transform', `translate(${_style.cell[1]}, 35)`)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#F4F5F5').style('text-anchor', 'end')
      .text(_data1[1])
      .style('cursor', 'pointer')
      .on('mouseover', function() {
        let node = _self.getNodeElValue(`#PA_Total`,'width') / 5.5
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(${_style.cell[1] + node}, 35) scale(1.3)`).style('fill', '#EC407A')
      })
      .on('mouseout', function(){
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(${_style.cell[1]}, 35) scale(1)`).style('fill', '#fff')
      })
      .on('click', () => { 
        if(_style.requests) {
          _style.requests[0].filters.CAT = 'A'
          this.$emit('request-action', _style.requests[0])
        }
      })

      // Punch A - Actual
      SummaryLibrary3 
      .append('text')
      .attr('id', `PA_Actual`)
      .attr('transform', `translate(${_style.cell[2]}, 35)`)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#F4F5F5').style('text-anchor', 'end')
      .text(_data1[2])
      .style('cursor', 'pointer')
      .on('mouseover', function() {
        let node = _self.getNodeElValue(`#PA_Actual`,'width') / 5.5
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(${_style.cell[2] + node}, 35) scale(1.3)`).style('fill', '#EC407A')
      })
      .on('mouseout', function(){
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(${_style.cell[2]}, 35) scale(1)`).style('fill', '#fff')
      })
      .on('click', () => { 
        if(_style.requests) {
          _style.requests[1].filters.CAT = 'A'
          this.$emit('request-action', _style.requests[1])
        }
      })

      // Punch A - Remain
      SummaryLibrary3 
      .append('text')
      .attr('id', `PA_Remain`)
      .attr('transform', `translate(${_style.cell[3]}, 35)`)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#F4F5F5').style('text-anchor', 'end')
      .text(_data1[3])
      .style('cursor', 'pointer')
      .on('mouseover', function() {
        let node = _self.getNodeElValue(`#PA_Remain`,'width') / 5.5
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(${_style.cell[3] + node}, 35) scale(1.3)`).style('fill', '#EC407A')
      })
      .on('mouseout', function(){
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(${_style.cell[3]}, 35) scale(1)`).style('fill', '#fff')
      })
      .on('click', () => { 
        if(_style.requests) {
          _style.requests[2].filters.CAT = 'A'
          this.$emit('request-action', _style.requests[2])
        }
      })

      
      SummaryLibrary3
      .append('text') 
      .attr('transform', `translate(${_style.cell[0]}, 55)`)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#F4F5F5').style('text-anchor', 'start').text(_data2[0])

      // Punch B - Total
      SummaryLibrary3 
      .append('text')
      .attr('id', `PB_Total`)
      .attr('transform', `translate(${_style.cell[1]}, 55)`)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#F4F5F5').style('text-anchor', 'end')
      .text(_data2[1])
      .style('cursor', 'pointer')
      .on('mouseover', function() {
        let node = _self.getNodeElValue(`#PB_Actual`,'width') / 5.5
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(${_style.cell[1] + node}, 55) scale(1.3)`).style('fill', '#EC407A')
      })
      .on('mouseout', function(){
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(${_style.cell[1]}, 55) scale(1)`).style('fill', '#fff')
      })
      .on('click', () => { 
        if(_style.requests) {
          _style.requests[0].filters.CAT = 'B'
          this.$emit('request-action', _style.requests[0])
        }
      })

      // Punch B - Actual
      SummaryLibrary3 
      .append('text')
      .attr('id', `PB_Actual`)
      .attr('transform', `translate(${_style.cell[2]}, 55)`)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#F4F5F5').style('text-anchor', 'end')
      .text(_data2[2])
      .style('cursor', 'pointer')
      .on('mouseover', function() {
        let node = _self.getNodeElValue(`#PB_Actual`,'width') / 5.5
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(${_style.cell[2] + node}, 55) scale(1.3)`).style('fill', '#EC407A')
      })
      .on('mouseout', function(){
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(${_style.cell[2]}, 55) scale(1)`).style('fill', '#fff')
      })
      .on('click', () => { 
        if(_style.requests) {
          _style.requests[1].filters.CAT = 'B'
          this.$emit('request-action', _style.requests[1])
        }
      })

      // Punch B - Remain
      SummaryLibrary3 
      .append('text')
      .attr('id', `PB_Remain`)
      .attr('transform', `translate(${_style.cell[3]}, 55)`)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#F4F5F5').style('text-anchor', 'end')
      .text(_data2[3])
      .style('cursor', 'pointer')
      .on('mouseover', function() {
        let node = _self.getNodeElValue(`#PB_Remain`,'width') / 5.5
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(${_style.cell[3] + node}, 55) scale(1.3)`).style('fill', '#EC407A')
      })
      .on('mouseout', function(){
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(${_style.cell[3]}, 55) scale(1)`).style('fill', '#fff')
      })
      .on('click', () => { 
        if(_style.requests) {
          _style.requests[2].filters.CAT = 'B'
          this.$emit('request-action', _style.requests[2])
        }
      })
    },


    ModuleInformation(selection) {

      let ModuleInfo = [
        {name: 'Area'       , value: `${this.CurrentArea}`               },
        {name: 'Module No.' , value: `${this.Module}`              },
        {name: 'Description', value: `${this.dataSet.find(f=> f.MODULE == this.Module).DESCR}`},
        {name: 'Length'     , value: '64'                 },
        {name: 'Depth'      , value: '35'                 },
        {name: 'Height'     , value: '14.3'               },
        {name: 'Weight(ton)', value: '3682'               },
      ]

      let moduleInfo = selection
      .append('g')
      .attr('transform', `translate(170, 560)`)

      moduleInfo
      .append('text')
      .attr('transform', `translate(0, -20)`)
      .style('font-family', 'roboto').style('font-size', 12).style('font-weight', 700).style('fill', '#000').style('text-anchor', 'end').attr('alignment-baseline', 'middle').text('Module Information')

      ModuleInfo.forEach((d,i) => {
        moduleInfo
        .append('text')
        .attr('transform', `translate(0, ${i*20})`)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#333').style('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text(d.name)
  
        moduleInfo
        .append('text')
        .attr('transform', `translate(5, ${i*20})`)
        .style('font-family', 'roboto').style('font-size', 9).style('fill', '#757575').style('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text(d.value)
      })
    },


    StatusActivity(selection, _x, _y, _bColor, _opacity, _height, _len, _dist, _group, _size) {

      let drawY = 25
      let statusActivity = this.SVG_ModuleSummary
      .append('g')
      .attr('transform', `translate(${_x}, ${_y})`)

      statusActivity
      .append('rect')
      .attr('transform', `translate(${-_size}, 0)`)
      .style('width', _size).style('height', 18).style('fill', '#E0E0DF').attr('rx', 3).attr('ry', 3)

      statusActivity
      .append('text')
      .attr('transform', `translate(${-_size/2}, 10)`)
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#333').style('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(_group)


      this.Queries.SQL4.filter(f=> f.GROUP == _group).forEach( (d,i) => {
        
        if (i == 0 || i == 3 || i == 6) {
          drawY += 7

          statusActivity
          .append('text')
          .attr('transform', `translate(0, ${drawY})`)
          .style('font-family', 'roboto').style('font-size', 11).style('fill', '#333').style('text-anchor', 'end').attr('alignment-baseline', 'hanging')
          .text(d.ACT)

          if (i == 0) {
            statusActivity
            .append('text')
            .attr('transform', `translate(10, ${drawY+5})`).style('font-family', 'roboto').style('font-size', 9).style('fill', '#bcbcbc').style('text-anchor', 'middle').attr('alignment-baseline', 'hanging').text('0%')
            statusActivity
            .append('text')
            .attr('transform', `translate(${10 + (_len/2)}, ${drawY+5})`).style('font-family', 'roboto').style('font-size', 9).style('fill', '#bcbcbc').style('text-anchor', 'middle').attr('alignment-baseline', 'hanging').text('50%')
            statusActivity
            .append('text')
            .attr('transform', `translate(${10 + _len}, ${drawY+5})`).style('font-family', 'roboto').style('font-size', 9).style('fill', '#bcbcbc').style('text-anchor', 'middle').attr('alignment-baseline', 'hanging').text('100%')

            statusActivity
            .append('line')
            .attr('x1', 10).attr('y1', drawY+13).attr('x2', 10).attr('y2', 255).attr('stroke', '#bcbcbc').attr('stroke-width', 0.3)
            statusActivity
            .append('line')
            .attr('x1', 10 + (_len/2)).attr('y1', drawY+13).attr('x2', 10 + (_len/2)).attr('y2', 255).attr('stroke', '#bcbcbc').attr('stroke-width', 0.3)
            statusActivity
            .append('line')
            .attr('x1', 10 + _len).attr('y1', drawY+13).attr('x2', 10 + _len).attr('y2', 255).attr('stroke', '#bcbcbc').attr('stroke-width', 0.3)
          }
          drawY += _dist

        } 
          statusActivity
          .append('text')
          .attr('transform', `translate(0, ${drawY})`)
          .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').style('text-anchor', 'end').attr('alignment-baseline', 'hanging')
          .text(d.TITLE)

          statusActivity
          .append('rect')
          .attr('transform', `translate(10, ${drawY - 1})`)
          .style('width', _len).style('height', _height).style('fill', '#F4F5F5')
          statusActivity
          .append('rect')
          .attr('transform', `translate(10, ${drawY - 1})`)
          .style('width', (d.PROG/100)*_len).style('height', _height).style('fill', '#B4E3FA').attr('opacity', _opacity)
        drawY += _dist
      })
      



    },






  }
}
           