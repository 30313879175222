import * as d3 from 'd3'

export default {
  methods: {
    
    setEvent_Hull_LQ() {
      // Goto_LQ_Division_Lv4
      // ---------------------------------------------------------------------------------------------------------------------
      this.Goto_LQ_Division_Lv4 = (selection, mask, lv2) => {
        selection
        .on('mouseover', () => {
          d3.select(`#${mask}`).transition().style('opacity',0.5)
        })
        .on('mouseout', () => {
          d3.select(`#${mask}`).transition().style('opacity',0)
        })
        .on('click', () => {
          // Title & Keyplan
          d3.select(`#Title`).text(`HULL-LQ-LQ00`)
          d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Keyplan/HULL_LQ00.png`)

          // Remove SVG
          this.removeSvg(this.CANVAS, 1000)
          
          // Hidden
          this.hiddenGroupID('#Menu_Back', 0, 10)

          this.hiddenGroupID('#Mask_LQ00', 0, 10)
          this.hiddenGroupID('#Mask_HD01', 0, 10)

          this.Hull_LQ.filter(f=> f.Lv3 !== lv2).forEach(d => {
            this.hiddenGroupID(`#Blk_${d.Blk}`, 0, 300)
          })

          setTimeout(() => {
            // Explode
            this.Hull_LQ.filter(f=> f.Lv3 == lv2).forEach(d => {
              d3.select(`#Blk_${d.Blk}`)
              .transition().duration(1000).attr('transform', `translate(${d.x3}, ${d.y3}) scale(0.5)`)
            })
          }, 300);

          setTimeout(() => {
            // Display
            this.displayGroupID(`#Mask_LV01`, 0, 10, 0)
            this.displayGroupID(`#Mask_LV23`, 0, 10, 0)
            this.displayGroupID(`#Mask_LV45`, 0, 10, 0)
            this.displayGroupID(`#Mask_LV67`, 0, 10, 0)

            this.Lev1 = 'HULL'; this.Lev2 = 'LQ'
            this.stage = this.STG_Division_Lv4
            let dStage = {
              stage: this.STG_Division_Lv4,
              mask : mask,
              level: lv2
             }
             this.selectedItems.push(dStage)
            this.fncName = 'Goto_LQ_Division_Lv4'
            this.StageCode() // AREA / STAGE
            this.displayGroupID(`#Menu_Back`, 0, 200, 1)

             // ### Call Canvas ###
            this.Canvas_Division_Lv4(this.Lev2)
          }, 1300);
        })
      }

      // Goto_LQ_BlockDivison_Lv3
      // ---------------------------------------------------------------------------------------------------------------------
      this.Goto_LQ_BlockDivison_Lv3 = (selection, mask, lv3) => {
        selection
        .on('mouseover', () => {
          d3.select(`#${mask}`).transition().style('opacity',0.5)
        })
        .on('mouseout', () => {
          d3.select(`#${mask}`).transition().style('opacity',0)
        })
        .on('click', () => {

          // Remove SVG
          this.removeSvg(this.CANVAS, 1000)
          // Title & Keyplan
          d3.select(`#Title`).text(`HULL_LQ_${lv3}`)
          d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Keyplan/HULL_LQ_${lv3}.png`)

          // Hidden
          this.hiddenGroupID('#Menu_Back', 0, 10)
          this.hiddenGroupID('#Mask_LQ00', 0, 10)
          this.hiddenGroupID('#Mask_HD01', 0, 10)

          this.Hull_LQ.filter(f=> f.Lv3 !== lv3).forEach(d => {
            this.hiddenGroupID(`#Blk_${d.Blk}`, 0, 300)
          })
          let coordinate = this.Coordinate.find(f=> f.l2 == this.Lev2 && f.code == lv3)

          setTimeout(() => {

            // Scale
            d3.select(`#Hull_LQ_Group`)
            .transition().duration(1000).attr('transform', `translate(${coordinate.x}, ${coordinate.y}) scale(1)`)
            // Explode
            this.Hull_LQ.filter(f=> f.Lv3 == lv3).forEach(d => {
              d3.select(`#Blk_${d.Blk}`)
              .transition().duration(1000).attr('transform', `translate(${d.x3}, ${d.y3}) scale(0.5)`)
            })
          }, 300);

          setTimeout(() => {

            this.draw_BlockCode_Lv3(lv3, coordinate.x, coordinate.y)
            this.Lev1 = 'HULL'; this.Lev2 = 'LQ'; this.Lev3 = lv3; this.Lev4 = null; 
            this.stage = this.STG_Lv3_Block_Division
            let dStage = {
              stage: this.STG_Lv3_Block_Division,
              mask : mask,
              level: lv3
             }
             this.selectedItems.push(dStage)
            this.fncName = 'Goto_LQ_BlockDivison_Lv3'
            this.StageCode() // AREA / STAGE
            this.displayGroupID(`#Menu_Back`, 0, 200, 1)
          }, 1300);
        })
      }


      // Goto_LQ_BlockDivison_Lv4
      // ---------------------------------------------------------------------------------------------------------------------
      this.Goto_LQ_BlockDivison_Lv4 = (selection, mask, lv4) => {
        selection
        .on('mouseover', () => {
          d3.select(`#${mask}`).transition().style('opacity',0.5)
        })
        .on('mouseout', () => {
          d3.select(`#${mask}`).transition().style('opacity',0)
        })
        .on('click', () => {
          
          // Remove SVG
          this.removeSvg(this.CANVAS, 1000)

          // Title & Keyplan
          d3.select(`#Title`).text(`Hull-${lv4}`)
          d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Keyplan/HULL_LQ_${lv4}.png`)
          
          // Hidden
          this.hiddenGroupID('#Menu_Back', 0, 10)
          
          this.hiddenGroupID('#Mask_LV01', 0, 10)
          this.hiddenGroupID('#Mask_LV23', 0, 10)
          this.hiddenGroupID('#Mask_LV45', 0, 10)
          this.hiddenGroupID('#Mask_LV67', 0, 10)

          this.Hull_LQ.filter(f=> f.Lv4 !== lv4).forEach(d => {
            this.hiddenGroupID(`#Blk_${d.Blk}`, 0, 300)
          })

          let coordinate = this.Coordinate.find(f=> f.l2 == this.Lev2 && f.code == lv4)

          setTimeout(() => {
            // Scale
            d3.select(`#Hull_LQ_Group`)
            .transition().duration(1000).attr('transform', `translate(${coordinate.x}, ${coordinate.y}) scale(1)`)
  
            // Explode
            this.Hull_LQ.filter(f=> f.Lv4 == lv4).forEach(d => {
              d3.select(`#Blk_${d.Blk}`)
              .transition().duration(1000).attr('transform', `translate(${d.x4}, ${d.y4}) scale(0.5)`)
            })
          }, 300);

          setTimeout(() => {
            this.draw_BlockCode_Lv4('LQ', lv4, coordinate.x, coordinate.y)

            this.Lev1 = 'HULL'; this.Lev2 = 'LQ'; this.Lev3 = 'LQ00'; this.Lev4 = lv4; 
            this.stage = this.STG_Lv4_Block_Division
            let dStage = {
              stage: this.STG_Lv4_Block_Division,
              mask : mask,
              level: lv4
             }
             this.selectedItems.push(dStage)
            this.fncName = 'Goto_LQ_BlockDivison_Lv4'
            this.StageCode() // AREA / STAGE
            this.displayGroupID(`#Menu_Back`, 0, 200, 1)
          }, 1300);
          
        })
      }
      //Goto_LQ_Division_Lv3
     // ---------------------------------------------------------------------------------------------------------------------
      // this.Goto_LQ_Division_Lv3 = (selection) => {
      //   selection
      //   .on('mouseover', () => {
      //     d3.select(`#Mask_LQ_Group`).transition().style('opacity',0.5)
      //   })
      //   .on('mouseout', () => {
      //     d3.select(`#Mask_LQ_Group`).transition().style('opacity',0)
      //   })
      //   .on('click', () => {
          
      //     // Remove SVG
      //     this.removeSvg(this.CANVAS, 1000)

      //     // Hidden
      //     this.hiddenGroupID('#Menu_Back', 0, 10)

      //     this.hiddenGroupID('#Mask_LQ_Group', 0, 10)

      //     // Explode
      //     this.Hull_LQ.forEach(d => {
      //       d3.select(`#Blk_${d.Blk}`)
      //       .transition().duration(1000).attr('transform', `translate(${d.x2}, ${d.y2}) scale(0.5)`)
      //     })
  
      //     setTimeout(() => {

      //       // Display
      //       this.displayGroupID(`#Mask_LQ00`, 0, 10, 0)
      //       this.displayGroupID(`#Mask_HD01`, 0, 10, 0)

      //       this.Lev1 = 'HULL'
      //       this.Lev2 = 'LQ'
      //       this.stage = this.STG_Division_Lv3
      //       let dStage = {
      //         stage: this.STG_Division_Lv3,
      //         mask : "HULL-LQ",
      //         level: this.Lev2
      //        }
      //        this.selectedItems.push(dStage)
      //       this.fncName = 'Goto_LQ_Division_Lv3'
      //       this.StageCode() // AREA / STAGE
      //       this.displayGroupID(`#Menu_Back`, 0, 200, 1)

      //       // ### Call Canvas ###
      //       this.Canvas_Division_Lv3(this.Lev1, this.Lev2)
      //     }, 1000);
      //   })
      // }
    },
  }
}