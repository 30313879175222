import * as d3 from 'd3'
// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  data:() => ({
    boxprops:[
      { key:'LOCATIONS',text: 'Location'    ,color:'#BDBDBD',border:'#9E9E9E',w:264,},
      { key:'COORDINATION',text: 'Coordination'    ,color:'#81C784',border:'#388E3C',w:180,},
      { key:'REMARKS',text: 'Remarks' ,color:'#EF9A9A',border:'#D32F2F',w:348,}
    ],
  }),
  methods: {
    Draw_Charts() {
      let svg = this.svg

      let data_ = this.DataItems[0]

      let group = svg.append('g').attr('class', 'group__').attr('transform', `translate(0.5, 0.5)`).style('font-family', 'roboto')

      group
      .append('rect')
      .attr('transform', `translate(${0}, ${0})`)
      .attr('width', 795).attr('height', 28).attr('fill','#B71C1C').attr('rx',5).attr('ry',5)
      // .attr('stroke-width', 0.5).attr('stroke', '#bcbcbc')

      group
      .append('text')
      .attr('transform', `translate(${10}, ${20})`)
      .style('font-size', 20).attr('fill', '#fff').style('text-anchor', 'start')
      .text(data_.IG_NO)

      let groupBox = group
      .append('g').attr('class', 'groupbox__').attr('transform', `translate(0, 32)`)
      this.boxprops.forEach((d, i) => {
        if(i<=1){
          groupBox
        .append('rect')
        .attr('transform', `translate(${i==0?0:265}, ${0})`)
        .attr('width', d.w).attr('height', 50).attr('fill','#E0E0E0').attr('rx',5).attr('ry',5)
        groupBox
        .append('text')
        .attr('transform', `translate(${i==0?8:273}, ${16})`)
        .style('font-size', 12).attr('fill', '#333').style('text-anchor', 'start').style('font-weight',600)
        .text(`${d.text} :`)
        groupBox
        .append('text')
        .attr('transform', `translate(${i==0?8:273}, ${38})`)
        .style('font-size',12).attr('fill', '#424242').style('text-anchor', 'start')
        .text(data_[d.key])
        }else{
          groupBox
          .append('rect')
          .attr('transform', `translate(${446}, ${0})`)
          .attr('width', d.w).attr('height', 50).attr('fill','#E0E0E0').attr('rx',5).attr('ry',5)
          groupBox
          .append('text')
          .attr('transform', `translate(${451}, ${16})`)
          .style('font-size', 12).attr('fill', '#333').style('text-anchor', 'start').style('font-weight',600)
          .text(`${d.text} :`)

          let groupBox3 = groupBox.append('foreignObject')
          .attr('transform', `translate(450, 22)`)
          .attr('width', '340px').attr('color', '#333')
          .attr('height', '50px').style('font-size',12).style('text-align', 'left').style('line-height','90%')
          .append('xhtml:body')

          groupBox3.append('div')
          .html(data_[d.key])
          .append('text')
          .attr('transform', `translate(${450}, ${38})`)
          .attr('fill', '#424242')
          .style('overflow', 'auto')
          
          // .style('white-space', 'nowrap')
          // .style('overflow', 'hidden')
          // .style('text-overflow', 'ellipsis')
        }
        
      });
      
      let imageBox = group
      .append('g').attr('class', 'imagebox__').attr('transform', `translate(2, 90)`)

      imageBox
      .append('image')
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/China1/Interface/Location/${data_.IG_NO}.png`)
      .attr('stroke', '#dadada')
      .attr('stroke-width', .5)
    },
  }
}
