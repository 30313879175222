import * as d3 from 'd3'

export default {
  data: () => ({
    popup2drag: null,
    setPopupCurMoveing: null
  }),
  created() {
    this.popup2drag = d3.drag()
    .on('start', this.pstart)
    .on('drag', this.pdrag)

    this.setPopupCurMoveing = (selection) => {
      selection
        .on('mouseover', (_, i, a) => { d3.select(a[i]).style('cursor', 'pointer') })
        .on('mouseout', (_, i, a) => { d3.select(a[i]).style('cursor', 'default') })
    }
  },
  methods: {
    pstart(_, i, a) {
      let coordinate = this.getCoordinates(d3.select(a[i]))
      this.delta.x = coordinate[0] - d3.event.x
      this.delta.y = coordinate[1] - d3.event.y
    },
    pdrag(_, i, a) {
      let transform_ = d3.select(a[i]).attr('transform')
      let translate_ = `translate(${d3.event.x + this.delta.x},${d3.event.y + this.delta.y})`
      d3.select(a[i]).attr('transform', transform_.replace(/translate(.*?)\)/gi, translate_))
    }
  }
}
