import * as d3 from 'd3'
import Moment from 'moment'
// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {

    Draw_Charts() {
      
      let data = null, 
          style = null, 
          cellX = null, 
          tmp = null, 
          Circle = null, 
          Guideline = null,
          item = null

      let systemsummary = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0.5)`)

      // Header_01
      data  = this.Queries.SQL1[0]
      style = {
        title: 'Pre-Commissioning',
        x: 30,
        y: 70,
        width: 123,
        height: 25,
        radius: 3,
        tSize: 12,
        tColor: '#333',
        bColor: '#E0E0DF',
        progSize: 15,
      }
      this.Header_01( systemsummary, data.PROG, style) // ---------------------------> 


      let days = Moment().diff(Moment('2020-03-11'), 'days')

      systemsummary
      .append('image')
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/icon/svg/time.svg`)
      .attr('transform', `translate(40,165)`).style('width', 40)

      systemsummary
      .append('text')
      .attr('transform', `translate(${120}, ${210})`)
      .style('font-family', 'roboto').style('font-size', 20).style('font-weight', 600)
      .style('text-anchor', 'end').style('alignment-baseline', 'ideographic')
      .text( ()=> {
        if (days) return days
      })
      .style('fill', ()=> {
        if (days < 0) return '#EC407A'; else return '#44A9DF'
      })

      systemsummary
      .append('text')
      .attr('transform', `translate(${123}, ${210})`)
      .style('font-family', 'roboto').style('font-size', 14)
      .style('text-anchor', 'start').style('alignment-baseline', 'ideographic').text('days')
      .style('fill', ()=> {
        if (days < 0) return '#EC407A'; else return '#44A9DF'
      })

      systemsummary
      .append('text')
      .attr('transform', `translate(${35}, ${220})`)
      .style('font-family', 'roboto').style('font-size', 11)
      .style('fill', '#757575').style('text-anchor', 'start').style('alignment-baseline', 'middle').text('Commissioning Target Date')




      // SummaryTable_01
      data  = this.Queries.SQL1[0]
      style = {
        id:       'BITR',
        title:    ['Total', 'Completed', 'Remain'],
        cell:     [50, 110, 160],
        x:        30,
        y:        100,
        y1:       10,
        y2:       26,
        width:    170,
        height:   35,
        radius:   3,
        opacity:  1,
        bColor:   '#83D2F5',
        tSize:    9,
        tColor:   '#757575',
        vSize:    11,
        vColor:   '#fff',
        value:    [data.TOTAL, data.ACTUAL, data.REMAIN],
        requests: [
          {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: 104,
              no: ''
            },
            filters: { 
              SUBSYSTEM: this.FilterValues.SYS
            },
            iFilters: {
              filterString : "[STAGE] = 'B'",
              inputFilter  : ''
            }
          },
          {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: 104,
              no: ''
            },
            filters: { 
              SUBSYSTEM: this.FilterValues.SYS
            },
            iFilters: {
              filterString : "[STAGE] = 'B' AND [STATUS] = 'Y'",
              inputFilter  : ''
            }
          },
          {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: 104,
              no: ''
            },
            filters: { 
              SUBSYSTEM: this.FilterValues.SYS
            },
            iFilters: {
              filterString : "[STAGE] = 'B' AND [STATUS] = 'N'",
              inputFilter  : ''
            }
          },
        ]
      }
      this.SummaryTable_01(systemsummary, style) // ---------------------------> 




      let systemInfo = this.svg
      .append('g')
      .attr('transform', `translate(270.5, 40.5)`)

      let drawY = 0
      this.dataSet.forEach((d,i) => {
        if(i>5) return
        systemInfo // CAT
        .append('text')
        .attr('transform', `translate(${150}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 11)
        .style('fill', '#757575').style('text-anchor', 'end').style('alignment-baseline', 'middle').text(d.title)

        systemInfo // CAT
        .append('text')
        .attr('transform', `translate(${160}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 11)
        .style('fill', '#333').style('text-anchor', 'start').style('alignment-baseline', 'middle').text(d.value)

        if (i==1 || i ==3) drawY += 28; else drawY += 18
      })

      drawY = 140
      this.dataSet.forEach((d,i) => {
        if(i == 6 || i == 8 || i == 11) {
        systemInfo
        .append('text')
        .attr('transform', `translate(${150}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 11)
        .style('fill', '#757575').style('text-anchor', 'end').style('alignment-baseline', 'middle').text(d.title)

        systemInfo
        .append('text')
        .attr('transform', `translate(${180}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 10)
        .style('fill', '#bcbcbc').style('text-anchor', 'end').style('alignment-baseline', 'middle').text('Plan')

        systemInfo
        .append('rect')
        .attr('transform', `translate(315, ${drawY-9})`)
        .style('width', 65).style('height', 15).style('fill', '#E0E0DF').attr('rx', 0).attr('ry', 0).style('opacity', 0.3)

        systemInfo
        .append('text')
        .attr('transform', `translate(${310}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 10)
        .style('fill', '#bcbcbc').style('text-anchor', 'end').style('alignment-baseline', 'middle')
        .text(() => { if(i == 8) return 'Forecast'; else return 'Actual'})
        
        systemInfo
        .append('rect')
        .attr('transform', `translate(185, ${drawY-9})`)
        .style('width', 65).style('height', 16).style('fill', '#E0E0DF').attr('rx', 0).attr('ry', 0).style('opacity', 0.3)


        systemInfo
        .append('text')
        .attr('transform', `translate(${320}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 11)
        .style('fill', '#44A9DF').style('text-anchor', 'start').style('alignment-baseline', 'middle').text(this.dataSet[i+1].value)

        if(i == 8) {
          systemInfo
          .append('text')
          .attr('transform', `translate(${430}, ${drawY})`)
          .style('font-family', 'roboto').style('font-size', 10)
          .style('fill', '#bcbcbc').style('text-anchor', 'end').style('alignment-baseline', 'middle')
          .text(() => { if(i == 8) return 'Actual'})
          
          systemInfo
          .append('rect')
          .attr('transform', `translate(435, ${drawY-9})`)
          .style('width', 65).style('height', 16).style('fill', '#E0E0DF').attr('rx', 0).attr('ry', 0).style('opacity', 0.3)

          systemInfo
          .append('text')
          .attr('transform', `translate(${440}, ${drawY})`)
          .style('font-family', 'roboto').style('font-size', 11)
          .style('fill', '#44A9DF').style('text-anchor', 'start').style('alignment-baseline', 'middle').text(this.dataSet[i+2].value)
        }


        systemInfo 
        .append('text')
        .attr('transform', `translate(${190}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 11)
        .style('fill', '#44A9DF').style('text-anchor', 'start').style('alignment-baseline', 'middle').text(d.value)

        if (i==1 || i ==3) drawY += 28; else drawY += 23
        }
      })
      



      



    }, 
  }
}
           