import * as d3 from 'd3'
import { contourDensity } from 'd3'
export default {
  data: () => ({
    path: {
      // ME01: '/topsides_dashboard_pdf/me01_flash_gas_compression',
      // ME02: '/topsides_dashboard_pdf/me02_meg_and_fuel_gas',
      // ME04: '/topsides_dashboard_pdf/me04_power_generator',
      // ME05: '/topsides_dashboard_pdf/me05_e_i_building',
      // MW01: '/topsides_dashboard_pdf/mw01_recep_fac_prod_sepa_',
      // MW02: '/topsides_dashboard_pdf/mw02_hc_dew_cond_stab_',
      // MW04: '/topsides_dashboard_pdf/mw04_power_generator',
      // MW05: '/topsides_dashboard_pdf/mw05_utility',
      TOPSIDESMODULES: '/topsides_dashboard/topsides_modules',
      TOPSIDESCOMMON: '/topsides_dashboard/topsides_common',
      HULLCOMMON: '/hull_dashboard/hull_common',
      HULLFOR: '/hull_dashboard/hull_for',
      HULLAFT: '/hull_dashboard/hull_aft',
      LQ: '/lq_dashboard'
    }
  }),

  methods: {
    drawAreaStatus() {
      this.DataItems.forEach(item => {
        let target = this.innerSvg.select(`#${item.AREA}`)
        if(target.empty()) return
        console.log(target)
        // let translate = (target.attr('transform') || '').replace(/\s/g, '').match(/translate(.*?)\)/g)
        let translate = (target.attr('transform') || '').match(/translate(.*?)\)/g)
        let dimention = translate[0].match(/-?\d+\.?(\d+)?/g)

        let style = [
          { name: 'Fab. Commenced'     , bColor:'#44A9DF', opacity: 0.25 },
          { name: 'Fab. Completed'     , bColor:'#44A9DF', opacity: 0.40 },
          { name: 'Painting Completed' , bColor:'#44A9DF', opacity: 0.55 },
          { name: 'Assembly Completed' , bColor:'#44A9DF', opacity: 0.70 },
          { name: 'Erec. Commenced'    , bColor:'#44A9DF', opacity: 0.85 },
        ]
        let data = [
          { TOTAL: item.FAB_START_TOTAL || 0, ACTUAL: item.FAB_START_ACTUAL || 0 },
          { TOTAL: item.FAB_COMP_TOTAL  || 0, ACTUAL: item.FAB_COMP_ACTUAL  || 0 },
          { TOTAL: item.PAINTING_TOTAL  || 0, ACTUAL: item.PAINTING_ACTUAL  || 0 },
          { TOTAL: item.BLOCKASSY_TOTAL || 0, ACTUAL: item.BLOCKASSY_ACTUAL || 0 },
          { TOTAL: item.EREC_TOTAL      || 0, ACTUAL: item.EREC_ACTUAL      || 0 },
        ]
        let _self = this
        let chart = this.innerSvg.select('#SummarybyArea_P3') // jiyoun XD name
        .append('g')
        .attr('transform', `translate(${Number(dimention[0]) + 100}, ${Number(dimention[1]) + 20})`)

        // Area No.
        chart
        .append('text')
        .attr('transform', `translate(-100, 0)`) 
        .style('font-family', 'Roboto-Regular, Roboto')
        .style('font-size', 18)
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text(item.AREA)

        chart
        .append('text')
        .attr('transform', `translate(-100, -17)`) 
        .style('font-family', 'Roboto-Regular, Roboto')
        .style('font-size', 11)
        .style('fill','#0277BD')
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text(item.AREA_DESCR)

        // Plan Cuml Label & Value
        chart
        .append('text')
        .attr('transform', `translate(-99, 15)`)
        .style('font-family', 'Roboto-Regular, Roboto')
        .style('font-size', 9)
        .style('fill', '#bdbcbc')
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text('Plan Cuml.')

        chart
        .append('text')
        .attr('transform', `translate(-50, 14)`)
        .style('font-family', 'Roboto-Regular, Roboto')
        .style('font-size', 11)
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text(`${item.PLAN_PROG.toFixed(1)}%`)

        // Actual Cuml Label & Value
        chart
        .append('text')
        .attr('transform', `translate(-10, 15)`)
        .style('font-family', 'Roboto-Regular, Roboto')
        .style('font-size', 9)
        .style('fill', '#bdbcbc')
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text('Actual Cuml.')
        
        chart
        .append('text')
        .attr('transform', `translate(75, 14)`)
        .style('font-family', 'Roboto-Regular, Roboto')
        .style('font-size', 11)
        .style('fill', '#44a9df')
        .attr('text-anchor', 'end')
        .attr('alignment-baseline', 'middle')
        .text(`${item.ACTUAL_PROG.toFixed(1)}%`)

        // Chart Title
        chart
        .append('text')
        .attr('transform', `translate(-99, 28)`)
        .style('font-family', 'Roboto-Regular, Roboto')
        .style('font-size', 9)
        .style('fill', '#bdbcbc')
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text('Status of Block Activites')

        // Button & Label
        let buttonWrap = chart 
        .append('g')
        .attr('id', 'button_wrapper')
        .attr('transform', `translate(38, -10)`)
        .style('font-family', 'roboto')
        .style('cursor', 'pointer') 
        .on('mouseover', function(){  
          d3.select(this).transition().duration(100).style('opacity',.5) 
        }) 
        .on('mouseout', function(){ 
          d3.select(this).transition().duration(100).style('opacity',1) 
        })
        .on('click', () => {
          // if (['HULL-FOR', 'HULL-AFT', 'LQ'].includes(item.AREA)) {
          //   let request_ = this.getRequestColumnProps('level2', { GWBS_LV2: item.AREA }, this.ColumnProps)
          //   if(request_) this.$emit('request-action', request_)
          // } else {
          //   let request_ = this.getRequestColumnProps('area', { GWBS_LV3: item.AREA }, this.ColumnProps)
          //   if(request_) this.$emit('request-action', request_)
          // }
          /************************MODAL 과 PAGE MOVE로 변경******************************************/
          // console.log(item.AREA) 로 클릭했을때 제대로 들어오는것 확인하고 조건으로 hull은 페이지 이동, topsides는 modal나오게 작업
          let area = item.AREA.replace('-','')
          if (item.AREA === 'LQ' || item.AREA === 'HULL-FOR' || item.AREA === 'HULL-AFT' || item.AREA === 'HULL-COMMON'){
            let request_ = {
              dataType: 'row',    
              action: {
                type: 'direct',
                target: 'url',
                path: `/service/construction/construction_dashboards${this.path[area]}`
              },
              filters: {}
            }
            this.$emit('request-action', request_)
          }else{
            let request_ = this.getRequestColumnProps(item.AREA, {}, this.ColumnProps)
            if(request_) this.$emit('request-action', request_)
          }
        })

        buttonWrap
        .append('rect')
        .attr('transform', `translate(0, 0)`)
        .attr('rx', 3)
        .attr('ry', 3)
        .attr('width', 40)
        .attr('height', 14)
        .attr('fill', '#e5e5e5')

        buttonWrap
        .append('text')
        .attr('transform', `translate(20, 8)`)
        .style('font-family', 'Roboto-Regular, Roboto')
        .style('font-size', 9)
        .style('fill', '#000')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text('More')

        this.Bar_Activity_01(chart, item.AREA, 0, 25, data, 10, 65, 13, 10, style, 'off')

        let requestImgs = ['MW01', 'MW02', 'MW04', 'MW05', 'ME01', 'ME02', 'ME04', 'ME05','FL01','KO01','Rcxx','LQ', 'HULL-FOR', 'HULL-AFT','HULL-COMMON']

        if (requestImgs.includes(item.AREA) || requestImgs.includes(item.GWBS_LV2)) {

          d3.select(`#img_${item.AREA}`) // 원래는 d3.select(`#img_${item.GWBS_LV2}`)이렇게 두개로 나눠서 작업되어있었음
          .on('mouseover', function(){ 
            d3.select(this).style('cursor', 'pointer')
            d3.select(this).style('opacity', 0.7)
          })
          .on('mouseleave', function(){ 
            d3.select(this).style('opacity', 1)
          })
          .on('click', () => {
            let area = item.AREA.replace('-','')
            
            if (item.AREA === 'LQ' || item.AREA === 'HULL-FOR' || item.AREA === 'HULL-AFT' || item.AREA === 'HULL-COMMON'){
              let request_ = {
                dataType: 'row',    
                action: {
                  type: 'direct',
                  target: 'url',
                  path: `/service/construction/construction_dashboards${this.path[area]}`
                },
                filters: {}
              }
              this.$emit('request-action', request_)
            }else{
              let request_ = this.getRequestColumnProps(item.AREA, {}, this.ColumnProps)
              if(request_) this.$emit('request-action', request_)
            }
            // let request_ = this.getRequestColumnProps('area', { GWBS_LV3: item.AREA }, this.ColumnProps)
            // if(request_) this.$emit('request-action', request_)

            // let request_ = this.getRequestColumnProps('level2', { GWBS_LV2: item.AREA }, this.ColumnProps)
            // if(request_) this.$emit('request-action', request_)
          })
        }
          target.remove()
        })
    }
  }
}
