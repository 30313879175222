export default {
  data: () => ({

    Status: [
      { code: 'FABSTART',    title: 'Fab. Commenced',     colName: { total: 'FABSTART_TOTAL'   , actual: 'FABSTART_ACTUAL'    }, bColor: '#84C7E5', tColor: '#FFFFFF', opacity: 0.20,},
      { code: 'FABRICATION', title: 'Fab. Completed',     colName: { total: 'FABRICATION_TOTAL', actual: 'FABRICATION_ACTUAL'   }, bColor: '#2281C5', tColor: '#FFFFFF', opacity: 0.40,},
      { code: 'PAINTING',    title: 'Painting Completed', colName: { total: 'PAINTING_TOTAL'   , actual: 'PAINTING_ACTUAL'   }, bColor: '#F6D15E', tColor: '#333333', opacity: 0.60,},
      { code: 'ASSEMBLY',    title: 'Assembly Completed', colName: { total: 'ASSEMBLY_TOTAL'   , actual: 'ASSEMBLY_ACTUAL'}, bColor: '#CC93C1', tColor: '#FFFFFF',    opacity: 0.80,},
      { code: 'ERECTION',    title: 'Erec. Commenced',    colName: { total: 'ERECTION_TOTAL'   , actual: 'ERECTION_ACTUAL'  }, bColor: '#8DBA2D', tColor: '#FFFFFF',    opacity: 1.00,},
    ],
    Border: [
      {
        type: 'MEGA',
        shape: 'ellipse',
        width: 35,
        height: 15,
        color: '#9E9E9E',
        stroke: .2,
        textSize: 10,
        padding: 3,
      },
      {
        type: 'UNIT',
        shape: 'rect',
        width: 30,
        height: 14,
        color: '#9E9E9E',
        stroke: .2,
        textSize: 10,
        padding: 2.5,
      },
    ],
    Coordinate: [
      { Blk: '010', Lv1: 'HULL', Lv2: 'HULL-AFT', Lv3: 'HL02', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 580.99, y: 882.05, x1: 552.24, y1: 869.38, },
      { Blk: '020', Lv1: 'HULL', Lv2: 'HULL-AFT', Lv3: 'HL02', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 523.77, y: 625.66, x1: 555.49, y1: 577.3, },
      { Blk: '021', Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'HL03', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 959.85, y: 693.97, x1: 974.85, y1: 613.55, },
      { Blk: '030', Lv1: 'HULL', Lv2: 'HULL-AFT', Lv3: 'HL02', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 550.49, y: 942.44, x1: 522.49, y1: 863.4, },
      { Blk: '031', Lv1: 'HULL', Lv2: 'HULL-AFT', Lv3: 'HL02', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 723.88, y: 970.27, x1: 692.65, y1: 893.5, },
      { Blk: '038', Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'HL03', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 894.48, y: 1008.77, x1: 866.51, y1: 933.26, },
      { Blk: '039', Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'HL03', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 1005.85, y: 1012.43, x1: 974.78, y1: 944.76, },
      { Blk: '040', Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'HL03', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 1157.2, y: 724.5, x1: 1128.89, y1: 645, },
      { Blk: '081', Lv1: 'HULL', Lv2: 'HULL-AFT', Lv3: 'HL02', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 686.22, y: 627.68, x1: 590.99, y1: 622, },
      { Blk: '082', Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'HL03', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 1263.71, y: 764.22, x1: 1192.74, y1: 718, },
      { Blk: '083', Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'HL03', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 1290.35, y: 846.18, x1: 1307.16, y1: 824.3, },
      { Blk: '084', Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'HL03', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 1343.28, y: 803.94, x1: 1330.37, y1: 832.94, },
      { Blk: '085', Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'HL03', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 1395.56, y: 865.11, x1: 1360.45, y1: 837.31, },
      { Blk: '086', Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'HL03', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 1354.38, y: 897.88, x1: 1325.96, y1: 870.4, },
      { Blk: '089', Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'HL03', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 480, y: 533.66, x1: 327, y1: 495, },
      { Blk: '090', Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'HL03', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 543.77, y: 494.55, x1: 453, y1: 433, },
      { Blk: '091', Lv1: 'HULL', Lv2: 'HULL-AFT', Lv3: 'HL02', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 402.68, y: 844.81, x1: 431.71, y1: 848.55, },
      { Blk: '092', Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'HL03', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 1081.93, y: 1008.77, x1: 1036, y1: 969.84, },
      { Blk: '093', Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'HL03', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 1170.63, y: 969.09, x1: 1163.39, y1: 948.03, },
      { Blk: '094', Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'HL03', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 1223.58, y: 982.12, x1: 1193.1, y1: 950, },
      { Blk: '095', Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'HL03', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 1274.03, y: 954.53, x1: 1246.05, y1: 925.03, },
      { Blk: '096', Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'HL03', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 1314.38, y: 926.46, x1: 1286.56, y1: 899.83, },
      { Blk: '139', Lv1: 'HULL', Lv2: 'HULL-AFT', Lv3: 'HL02', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 390.2, y: 682, x1: 429.58, y1: 662.5, },
      { Blk: '721', Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'VS04-5', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 1070.58, y: 652.25, x1: 1034.85, y1: 587.3, },
      { Blk: '731', Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'VS04-5', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 854.42, y: 704.25, x1: 888.26, y1: 636.76, },
      { Blk: '740', Lv1: 'HULL', Lv2: 'HULL-AFT', Lv3: 'HL02', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 525.2, y: 269.51, x1: 522.02, y1: 244.44, },
      { Blk: '741', Lv1: 'HULL', Lv2: 'HULL-AFT', Lv3: 'HL02', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 668.5, y: 298.8, x1: 665.21, y1: 273.58, },
      { Blk: '742', Lv1: 'HULL', Lv2: 'HULL-AFT', Lv3: 'HL02', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 721.04, y: 306.9, x1: 716.5, y1: 282.82, },
      { Blk: '743', Lv1: 'HULL', Lv2: 'HULL-AFT', Lv3: 'HL02', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 895.86, y: 343.45, x1: 895.66, y1: 319.54, },
      { Blk: '744', Lv1: 'HULL', Lv2: 'HULL-AFT', Lv3: 'HL02', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 1017.17, y: 371.31, x1: 1015.92, y1: 343.55, },
      { Blk: '745', Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'HL03', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 1153.2, y: 401.51, x1: 1150.02, y1: 376.44, },
      { Blk: '746', Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'HL03', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 1253.5, y: 421.8, x1: 1250.21, y1: 396.58, },
      { Blk: '747', Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'HL03', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 1398.04, y: 448.9, x1: 1393.5, y1: 424.82, },
      { Blk: '748', Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'HL03', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 1440.86, y: 458.45, x1: 1440.66, y1: 434.54, },
      { Blk: '749', Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'HL03', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 1569.5, y: 481.42, x1: 1567.92, y1: 458.55, },
      { Blk: '750', Lv1: 'HULL', Lv2: 'HULL-AFT', Lv3: 'HL02', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 461, y: 314.51, x1: 458.2, y1: 292.1, },
      { Blk: '751', Lv1: 'HULL', Lv2: 'HULL-AFT', Lv3: 'HL02', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 603.09, y: 341.62, x1: 601.04, y1: 321.12, },
      { Blk: '752', Lv1: 'HULL', Lv2: 'HULL-AFT', Lv3: 'HL02', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 658.07, y: 352.17, x1: 652.87, y1: 330.12, },
      { Blk: '753', Lv1: 'HULL', Lv2: 'HULL-AFT', Lv3: 'HL02', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 834.07, y: 390.92, x1: 832.4, y1: 367.05, },
      { Blk: '754', Lv1: 'HULL', Lv2: 'HULL-AFT', Lv3: 'HL02', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 953.89, y: 415.29, x1: 952.27, y1: 390.84, },
      { Blk: '755', Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'HL03', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 1080, y: 454.51, x1: 1076.2, y1: 428.1, },
      { Blk: '756', Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'HL03', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 1179.09, y: 473.62, x1: 1176.04, y1: 448.12, },
      { Blk: '757', Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'HL03', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 1317.07, y: 498.17, x1: 1319.87, y1: 476.12, },
      { Blk: '758', Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'HL03', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 1369.07, y: 509.92, x1: 1367.4, y1: 486.05, },
      { Blk: '759', Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'HL03', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 1495.89, y: 534.29, x1: 1494.27, y1: 509.84, },
      { Blk: '760', Lv1: 'HULL', Lv2: 'HULL-AFT', Lv3: 'HL02', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 570.99, y: 236.51, x1: 569.35, y1: 211.74, },
      { Blk: '761', Lv1: 'HULL', Lv2: 'HULL-AFT', Lv3: 'HL02', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 715.83, y: 264.51, x1: 712.13, y1: 240.31, },
      { Blk: '762', Lv1: 'HULL', Lv2: 'HULL-AFT', Lv3: 'HL02', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 764.49, y: 275.29, x1: 764.12, y1: 249.69, },
      { Blk: '763', Lv1: 'HULL', Lv2: 'HULL-AFT', Lv3: 'HL02', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 943.41, y: 311.72, x1: 943.6, y1: 286.08, },
      { Blk: '764', Lv1: 'HULL', Lv2: 'HULL-AFT', Lv3: 'HL02', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 1064.65, y: 337.7, x1: 1062.84, y1: 310.68, },
      { Blk: '765', Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'HL03', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 1188.99, y: 373.51, x1: 1187.35, y1: 348.74, },
      { Blk: '766', Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'HL03', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 1290.83, y: 392.51, x1: 1287.13, y1: 368.31, },
      { Blk: '767', Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'HL03', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 1431.49, y: 420.29, x1: 1431.12, y1: 396.69, },
      { Blk: '768', Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'HL03', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 1482.41, y: 429.72, x1: 1478.6, y1: 406.08, },
      { Blk: '769', Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'HL03', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 1606.65, y: 456.7, x1: 1604.84, y1: 430.68, },
      { Blk: '770', Lv1: 'HULL', Lv2: 'HULL-AFT', Lv3: 'HL02', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 416.58, y: 349.51, x1: 416.8, y1: 320.12, },
      { Blk: '771', Lv1: 'HULL', Lv2: 'HULL-AFT', Lv3: 'HL02', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 562.97, y: 375.55, x1: 560.39, y1: 349.05, },
      { Blk: '772', Lv1: 'HULL', Lv2: 'HULL-AFT', Lv3: 'HL02', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 615.13, y: 385.38, x1: 611.33, y1: 358.08, },
      { Blk: '773', Lv1: 'HULL', Lv2: 'HULL-AFT', Lv3: 'HL02', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 793.04, y: 420.73, x1: 791.13, y1: 395.04, },
      { Blk: '774', Lv1: 'HULL', Lv2: 'HULL-AFT', Lv3: 'HL02', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 914.08, y: 444.3, x1: 910.58, y1: 418.45, },
      { Blk: '775', Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'HL03', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 1038.58, y: 482.51, x1: 1038.8, y1: 453.12, },
      { Blk: '776', Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'HL03', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 1141.97, y: 499.55, x1: 1139.39, y1: 473.05, },
      { Blk: '777', Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'HL03', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 1286.13, y: 528.38, x1: 1282.33, y1: 501.08, },
      { Blk: '778', Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'HL03', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 1332.04, y: 536.73, x1: 1330.13, y1: 511.04, },
      { Blk: '779', Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'HL03', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 1463.08, y: 561.3, x1: 1456.58, y1: 535.45, },
      { Blk: '780', Lv1: 'HULL', Lv2: 'HULL-AFT', Lv3: 'MD00a', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 699.29, y: 142.98, x1: 656.72, y1: 90.61, },
      { Blk: '781', Lv1: 'HULL', Lv2: 'HULL-AFT', Lv3: 'MD00a', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 818.22, y: 167.09, x1: 774.82, y1: 120.33, },
      { Blk: '782', Lv1: 'HULL', Lv2: 'HULL-AFT', Lv3: 'MD00a', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 888.55, y: 183.09, x1: 871.8, y1: 142.21, },
      { Blk: '783', Lv1: 'HULL', Lv2: 'HULL-AFT', Lv3: 'MD00a', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 987.41, y: 199.09, x1: 965.25, y1: 158.98, },
      { Blk: '784', Lv1: 'HULL', Lv2: 'HULL-AFT', Lv3: 'MD00a', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 1087.7, y: 217.69, x1: 1050.7, y1: 176.96, },
      { Blk: '785', Lv1: 'HULL', Lv2: 'HULL-AFT', Lv3: 'MD00a', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 1161.77, y: 233.69, x1: 1159.08, y1: 199.09, },
      { Blk: '786', Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'MD00f', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 1288.88, y: 267.35, x1: 1259.66, y1: 228.83, },
      { Blk: '787', Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'MD00f', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 1362.3, y: 283.35, x1: 1340.99, y1: 244.35, },
      { Blk: '788', Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'MD00f', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 1472.41, y: 305.01, x1: 1424.85, y1: 260.65, },
      { Blk: '789', Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'MD00f', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 1549.5, y: 319.28, x1: 1529.75, y1: 282.01, },
      { Blk: '790', Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'MD00f', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 1647.8, y: 335.28, x1: 1605.55, y1: 294.51, },
      { Blk: '791', Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'MD00f', Lv4: 'MD01-2', GWBS_LV1: 'HULL & LQ', GWBS_LV2: 'HULL-COMMON', GWBS_LV3: 'MD01-2', x: 1734.01, y: 352.31, x1: 1715.51, y1: 319.28, },

    ]
  }),
}