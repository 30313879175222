import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  methods: {
    drawChart() {

      this.svg
      .append('image')
      .attr('xlink:href', require('../primitives/visionwheel.png'))
      .style('width', '1495px')
    },
  }
}