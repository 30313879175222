import * as d3 from 'd3'
// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  data:() => ({
    legendText:[
      {text1:'Houston',text2:'',stroke:'#AEAEAE',color:'#ffffff'},
      {text1:'Beijing',text2:'',stroke:'#AEAEAE',color:'#DC9A95'},
      {text1:'Dayawan',text2:'',stroke:'#AEAEAE',color:'#E8FEBE'},
      {text1:'Local China',text2:'',stroke:'#707070',color:'#ffffff'},
      {text1:'Expat China',text2:'',stroke:'#707070',color:'#ffffff'},
      {text1:'Contractor',text2:'',stroke:'#707070',color:'#ffffff'},
      {text1:'Manufacturing',text2:'Integrated',stroke:'#24C16D',color:'#ffffff'},
      {text1:'Shared Services',text2:'Contractor',stroke:'#6C7B1B',color:'#ffffff'},
      {text1:'Functionally',text2:'Assigned',stroke:'#4877FF',color:'#ffffff'},
      {text1:'Mandarin',text2:'Speakers'},
    ],

    thead: [
      { key: 'NAME', html: 'Name', row: 1,  },
      { key: 'SCOPE', html: 'Scope', row: 1,  },
      { key: 'FUNCTIONAL', html: 'Functional', row:1, },
      { key: 'DAY', html: 'Day to Day', row: 1, },
      { key: 'ADMIN', html: 'Admin', row: 1, },
      { key: 'NOTES', html: 'Notes', row: 1,},
    ],
    theadrows: 1,
    tbody: [
      { key: 'NAME', w: 80 },
      { key: 'SCOPE', w: 80},
      { key: 'FUNCTIONAL', w: 80,  },
      { key: 'DAY', w: 100, },
      { key: 'ADMIN', w: 80, },
      { key: 'NOTES', w: 200, },
    ],
    
    tableWidth: 1000,
  }),
  methods: {
    Draw_Charts() {
      let svg = this.svg

      let data_ = this.DataItems[0]

      let group = svg.append('g').attr('class', 'group__').attr('transform', `translate(0.5, 100.5)`).style('font-family', 'roboto')

      let imageBox = group
      .append('g').attr('class', 'imagebox__').attr('transform', `translate(0, 0)`)
      imageBox
      .append('image')
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/China1/Images/TeamOrganization.jpg`)
      .attr('stroke', '#dadada')
      .attr('stroke-width', .5)

      imageBox
      .append('image').attr('transform', `translate(5, 998)`)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/China1/Images/leading.jpg`)
      .attr('stroke', '#dadada')
      .attr('stroke-width', .5)

      // let legend = group
      // .append('g').attr('class', 'legend__').attr('transform', `translate(50, 0)`)

      // legend
      // .append('text')
      // .attr('transform', `translate(-25,10)`)
      // .style('font-size', 12).style('fill', '#BDBDBD').attr('text-anchor', 'start').style('font-weight', 600)
      // .text('LEGEND')
      // legend
      // .append('polygon')
      // .attr('transform', `translate(715, 20)`)
      // .attr('points', `9.5,9.333 12.509,0 15.5,9 25,9 17.333,14.333 20.833,23 12.5,17.167 4.833,23 7.667,14 0,8.833`)
      // .attr('fill', `#388E3C`)
      
      // this.legendText.forEach((d,i)=>{

      //   if(i < 9){
      //   legend
      //   .append('rect')
      //   .attr('x', `${80*i-25}`).attr('y', 20)
      //   .attr('width', 70).attr('height', 25)
      //   .attr('stroke', d.stroke).attr('stroke-width', 0.7).attr('fill', d.color)
      // }
      //   legend
      //   .append('text')
      //   .attr('transform', `translate(${80*i+8},60)`)
      //   .style('font-size', 11).style('fill', '#212121').attr('text-anchor', 'middle')
      //   .text(d.text1)
      //   legend
      //   .append('text')
      //   .attr('transform', `translate(${80*i+8},70)`)
      //   .style('font-size', 11).style('fill', '#212121').attr('text-anchor', 'middle')
      //   .text(d.text2)
      // })

      // legend
      // .append('polygon')
      // .attr('transform', `translate(260, 20)`)
      // .attr('points', `0,25 25,0 25,25`).attr('fill', `#C3242A`)

      // legend
      // .append('polygon')
      // .attr('transform', `translate(340, 20)`)
      // .attr('points', `0,25 25,0 25,25`).attr('fill', `#0099FF`)
      
      // legend
      // .append('polygon')
      // .attr('transform', `translate(420, 20)`)
      // .attr('points', `25,25.001 0,0.001 25,0.001`).attr('fill', `#000000`)
      

      if(!this.DataItems || this.DataItems.length === 0) return

      let tableData = group

        let table_group = tableData.append('g').attr('transform', `translate(270.5, 572.5)`).attr('class', `block_table_group_`)

        // SET HERE!
        let list = this.DataItems.filter(d => d.TYPE)
        // console.log(list)
        let tableViewHeight = 96 +500 // thead + tbody

        let table_obj = table_group.append('foreignObject')
          .attr('y',0)
          .attr('width', '100%')
          .attr('height', `${tableViewHeight}px`)
          .append('xhtml:body')
  
        let table = table_obj.append('table')
                    .style('border', 'solid 0.7px #E0E0E0')
                    .style('border-collapse', 'collapse')
                    .style('font-size', '12px')
                    .style('width', `${this.tableWidth}px`)
                    .style('table-layout', 'fixed')
  
        let colgroup = table.append('colgroup')
        this.tbody.forEach(k => {
          colgroup.append('col').style('width', k.w + 'px')
        })
  
        let maxRow = Math.max(...this.thead.map(d => d.row))
  
        let thead = table.append('thead')
        for (let r = 1; r <= maxRow; r++) {
          let row = this.thead.filter(d=> d.row === r)
          let tr = thead.append('tr').style('background', '#BDBDBD')
  
          row.forEach(item => {
            tr.append('th')
            .attr('class', 'completion_dashboard_table_group___th')
            .attr('rowspan', () => item.rowspan ? item.rowspan : '')
            .attr('colspan', () => item.colspan ? item.colspan : '')
            .style('border', 'solid 0.7px #E0E0E0')
            .style('padding', '5px')
            .style('height', '40px')
            .style('font-weight','bold')
            .style('font-size',  '12px')
            .style('color','#212121')
            .html(item.html)
          })        
        }
          
        let tbodykeys = this.tbody
        let tbody = table.append('tbody')
  
        let disc = ''
        let addClass = ''
        for (let r = 0; r < list.length; r++) {
          let tr = tbody.append('tr')
  
          tbodykeys.forEach((k, i) => {
  
            if (list[r][k.key] == disc) return
            else {
              if (i == 0) {
                disc = list[r][k.key]
              }
            }
  
            let td = tr.append('td')
              .style('border', 'solid 0.1px #E0E0E0')
              .style('height', '21px')
              .style('text-align', k.align ? k.align : 'center')
              .style('padding', '10px')
              // .attr('rowspan', () => {
              //   if (i == 0) {
              //     let filtered = list.filter(d => d.CATEGORY == list[r][k.key])
              //     return filtered.length
              //   }
              // })
            if(k.key.includes('PROG')) {
              addClass = ''
              td
              .style('position', 'relative')
              .style('color', list[r][k.key] < 0 ? 'red' : list[r][k.key] === 100 ? '#fff' : addClass == 'caution' ? 'red' : '#000')
              .style('z-index', '1')
              .html(list[r][k.key] == null ? 0+'%' :list[r][k.key]+'%')
  
              td.append('div')
              .style('position', 'absolute')
              .style('left', 0)
              .style('top', 0)
              .style('width', list[r][k.key] > 0 ? list[r][k.key] + '%' : 0)
              .style('height', '20px')
              .style('background-color', list[r][k.key] < 100 ? k.key.includes('PLAN') ? '#E0E0E0' : '#FFCA28' : '#4CAF50')
              .style('z-index', '-1')
            }
            else {
              if (k.type == 'float') {
                let regexp = /\B(?=(\d{3})+(?!\d))/g
                if (isNaN(Math.floor(list[r][k.key]))) td.html('')
                else td.html(Math.round(Math.floor(list[r][k.key] * 100) / 100).toString().replace(regexp, ','))
              } else td.html(list[r][k.key])
            }
          })
        }
      
    },
  }
}
