import * as d3 from 'd3'
// ------------------------------------------------------------------------------- Global
export default {
  data: () => ({
    items: [
      { code: 'SC_P1' , color:'#B3E5FC', stroke:'#81D4FA'},
      { code: 'SC_P2' , color:'#FFECB3', stroke:'#FFE082'},
      { code: 'SC_P3' , color:'#FFCDD2', stroke:'#EF9A9A'},
      { code: 'SC_Overall' , color:'#FFCDD2', stroke:'#EF9A9A'},
      { code: 'CON_P1' , color:'#B3E5FC', stroke:'#81D4FA'},
      { code: 'CON_P2' , color:'#FFECB3', stroke:'#FFE082'},
      { code: 'CON_P3' , color:'#FFCDD2', stroke:'#EF9A9A'},
      { code: 'CON_Overall' , color:'#FFCDD2', stroke:'#EF9A9A'},
    ]
  }),
  methods: {
    Draw_Charts() {
      if(this.ColumnProps.length > 0) var props = this.ColumnProps.find(props_ => props_.value == 'comprops').props
      else props = this.defaultset


      this.innerSvg = this.svg.append('svg')
      let Overall = this.innerSvg
      .append('g')
      .attr('id', '_OverallGroup').attr('transform', `translate(0.5, 0.5)`)

      let packcage= this.DataItems.map(d=>d.PACKAGE)

      let pItem= this.items.filter(d=> d.code ==packcage)[0];

      Overall 
      .append('image')
      .attr('id', `_image_map`)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/China1/Images/${pItem.code}.png`)
      .attr('transform', `translate(0, 0)`).attr('width', props.width).attr('filter', `grayscale(100)`)
      .transition().duration(300)
      .delay(300)
      .transition().duration(300).attr('filter', `grayscale(0)`)

      Overall
      .append('line')
      .attr('x1', 10).attr('y1', props.lineHeight+8).attr('x2', props.width).attr('y2', props.lineHeight+8).attr('stroke-width', 0.5).attr('stroke', '#777777')
      
      // .style('cursor', 'pointer')
      // .on('mouseover', (_, i, a) => {
      //   let el = d3.select(a[i])
      //   // el.transition().duration(300).attr('transform', `translate(0,0) scale(1.5)`)
      //   // el.transition().attr('transform', `translate(0,0) scale(1.5)`)
      //   el.transition().attr('filter', `grayscale(100%)`)
      // })
      // .on('mouseout', (_, i, a) => {
      //   let el = d3.select(a[i])
      //   // el.transition().attr('transform', `translate(0,0) scale(1)`)
      //   el.transition().attr('filter', `grayscale(0)`)
      // })


      // /**
      //  * Svg Path Tool to get the clicked mouse point (x, y)
      //  */
      // this.createPathReigon(this.innerSvg, {
      //   w: 1600,
      //   h: 1600,
      //   strokeWidth: 2,
      //   strokeColor: '#ff00ff'
      // })

      
    },
  }
}
