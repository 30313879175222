import * as d3 from 'd3'
// ------------------------------------------------------------------------------- Global
// Global Data & Defs

import { geoConicConformal } from "d3-geo"

export default {
  data:() => ({
    legend: [
      {key:'SUBSYSTEM_STATUS', value:'Not Started', text: 'Not Started',  background: '#ccc', stroke: '#757575', tColor: '#000'},
      {key:'SUBSYSTEM_STATUS', value:'A ITR in progress', text: 'A ITR in Progress', background: '#FFFF2B', stroke: '#FDD835', tColor: '#000'},
      {key:'SUBSYSTEM_STATUS', value:'A ITR Completed', text: 'A ITR Completed', background: '#9DC3E6', stroke: '#64B5F6', tColor: '#000'},
      {key:'SUBSYSTEM_STATUS', value:'MC Completed', text: 'MC Completed', background: '#1E88E5', stroke: '#1565C0', tColor: '#fff'},
      {key:'SUBSYSTEM_STATUS', value:'B ITR in progress', text: 'B ITR in Progress', background: '#FFD600', stroke: '#FFAB00', tColor: '#000'},
      {key:'SUBSYSTEM_STATUS', value:'B ITR Completed', text: 'B ITR Completed', background: '#8BC34A', stroke: '#8BC34A', tColor: '#000'},
      // {key:'SUBSYSTEM_STATUS', value:'Completed', text: 'Completed', background: '#43A047', stroke: '#43A047', tColor: '#fff'},
      {key:'PUNCH_STATUS', value:'A', text: 'PL-A left', background: '#fff', stroke: '#D50000', tColor: '#000'},
    ],
    colors:[
      {key: 'PAS', color: '#FFEBEE'},
      {key: 'FGS', color: '#E3F2FD'},
      {key: 'SIS', color: '#E0F2F1'},
      {key: 'PROCESS CCTV', color: '#E8F5E9', text1: 'PROCESS', text2: 'CCTV'},
    ]
  }),
  methods: {
    Draw_Charts() {
      let svg = this.svg

      let dataSet = this.DataItems

      let overallGroup = svg.append('g').attr('class', `overall_group__`).attr('transform', `translate(30, 60)`)
      let legend = this.svg
      .append('g')
      .attr('transform', `translate(30, 120)`)

      this.legend.forEach((d, i) => {
        legend
        .append('rect')
        .attr('transform', `translate(${110*i}, 0)`)
        .attr('rx', 3)
        .attr('ry', 3)
        .attr('width', 100)
        .attr('height', 16)
        .attr('fill', d.background)
        .attr('stroke', d.stroke).attr('stroke-width', 1)

        legend
        .append('text')
        .attr('transform', `translate(${(110*i)+50}, 8 )`)
        .style('font-family', 'Roboto-Regular, Roboto')
        .style('font-size', 10)
        .style('fill', d.tColor)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(d.text)
      })
      //-------------------------------------------------------------------------------legend end
      let sysTypes = [...new Set(dataSet.map(d => d.SYS_TYPE))]


      let typeY = 0

      let typeGroup = overallGroup.append('g').attr('class', `type_group__`).attr('transform', `translate(0, 120)`)
      
      
      sysTypes.forEach((systype) => {
        let filter = dataSet.filter(f => f.SYS_TYPE == systype)
        if (!filter) return

        let colMax = Math.max(...filter.map(d => d.COL_NO))
        let rowMax = Math.max(...filter.map(d => d.ROW_NO))


        // Title
        typeGroup
        .append('rect')
        .attr('transform', (d, i) => `translate(0, ${((rowMax * 140)) / 2 + typeY - 50})`)
        .attr('width', '120px')
        .attr('height', '95px').attr('rx', 5)
        .attr('fill', () => {
          return this.colors.find(d => d.key == systype).color
        })

        if (this.colors.find(d => d.key == systype).text1) {
          typeGroup
          .append('text')
          // .attr('transform', (d, i) => `translate(60, ${typeY + 180})`)
          .attr('transform', (d, i) => `translate(60, ${((rowMax * 140)) / 2 + typeY})`)
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          .style('font-weight', 'bold')
          .style('font-size', 24)
          .append('tspan').text(this.colors.find(d => d.key == systype).text1)
          .attr('dy', -5)
          .attr('dx', 0)
          .append('tspan').text(this.colors.find(d => d.key == systype).text2)
          .attr('dy', 25)
          .attr('x', 0)

        }else {
          typeGroup
          .append('text')
          // .attr('transform', (d, i) => `translate(60, ${typeY + 180})`)
          .attr('transform', (d, i) => `translate(60, ${((rowMax * 140)) / 2 + typeY})`)
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          .style('font-weight', 'bold')
          .style('font-size', 30)
          .text(systype)
        }


        // background box
        typeGroup
        .append('rect')
        .attr('transform', `translate(150, ${typeY -13})`)
        .attr('width', '1410px')
        .attr('height', (rowMax * 140) + 'px').attr('rx', 5)
        .attr('fill', () => {
          return this.colors.find(d => d.key == systype).color
        })


        let itemGroup = typeGroup.append('g').attr('class', 'type_group__'+ systype).attr('transform', `translate(164, ${typeY})`)

        filter.forEach(item => {
          let itemY = 0
          for (let ri = 1; ri <= rowMax; ri++) {
            let itemX = 0
            for (let ci = 1; ci < 5; ci++) {

              
              if (item.ROW_NO == ri && item.COL_NO == ci) {

                let legend = this.legend.find(d => d.value == item.SUBSYSTEM_STATUS)
                if (!legend) return

                let subsystem = itemGroup.append('g').attr('class', `${systype}_`)
                .attr('transform', `translate(${itemX}, ${itemY})`)
                .on('mouseover', () => subsystem.style('cursor', 'pointer').style('opacity',0.7))
                .on('mouseout', () => subsystem.style('opacity',1))
                .on('click', () => {
                  let request_ = this.getRequestColumnProps('request', {SUBSYSTEM: item.SUBSYSTEM, DESC: item.SUBSYSTEM_DESCR}, this.ColumnProps)
                  this.$emit('request-action', request_)
                })

                subsystem
                .append('rect').attr('transform', `translate(0,0)`)
                .attr('width', '330px').attr('height', '115px').attr('rx', 3)
                .attr('fill', '#fff').attr('stroke', item.PUNCH_STATUS != 'A' ? legend.stroke : '#D50000').attr('stroke-width', '1px')

                // SUBSYSTEM
                subsystem.append('rect').attr('transform', `translate(1,1)`).attr('rx', 3)
                .attr('width', '328px').attr('height', '29px')
                .attr('opacity', 1).attr('stroke-width', 0)
                .attr('stroke', legend.stroke ? legend.stroke : '#ccc')
                .attr('fill', legend.background)

                subsystem.append('text').text(item.SUBSYSTEM).attr('transform', `translate(165, 16)`)
                .attr('text-anchor', 'middle')
                .attr('alignment-baseline', 'middle')
                .style('font-size', '18px')
                .attr('fill', legend.tColor)

                // DESC
                let textarea = subsystem.append('foreignObject')
                .attr('transform', `translate(5, 35)`)
                .attr('width', '320px')
                .attr('height', '50px')
                .append('xhtml:body')

                textarea.append('div')
                .html(item.SUBSYSTEM_DESCR)
                .style('overflow', 'auto')
                .style('text-align', 'left')
                .style('font-size', '14px')
                
                // MC Progress
                let mc = subsystem.append('g').attr('transform', `translate(5, 100)`)

                mc.append('rect').attr('transform', `translate(0,-11)`)
                .attr('width', '25px').attr('height', '20px')
                .attr('opacity', 1).attr('stroke-width', 0.3)
                .attr('fill', '#ccc')

                mc.append('text').text('MC').attr('transform', `translate(13, 0)`)
                .attr('text-anchor', 'middle')
                .attr('alignment-baseline', 'middle')
                .style('font-size', '11px')

                mc.append('rect').attr('transform', `translate(25,-11)`)
                .attr('width', '70px').attr('height', '20px')
                .attr('opacity', 1).attr('stroke-width', 0.1)
                .attr('stroke', '#424242')
                .attr('fill', 'Transparent')
                
                mc.append('rect').attr('transform', `translate(25,-11)`)
                .attr('width', item.A_OV_PROG ? item.A_OV_PROG / 100 * 70 + 'px' : '0px').attr('height', '20px')
                .attr('opacity', 1).attr('stroke-width', 0.1)
                .attr('fill', item.A_OV_PROG == 100 ? '#C8E6C9' : '#FFFF8D')
                
                mc.append('text').text(item.A_OV_REM).attr('transform', `translate(40, 0)`)
                .attr('text-anchor', 'middle')
                .attr('alignment-baseline', 'middle')
                .attr('fill', '#D50000')
                .style('font-size', '11px')
                
                mc.append('text').text(' / ').attr('transform', `translate(60, 0)`)
                .attr('text-anchor', 'middle')
                .attr('alignment-baseline', 'middle')
                .style('font-size', '11px')

                mc.append('text').text(item.A_OV_TOT).attr('transform', `translate(80, 0)`)
                .attr('text-anchor', 'middle')
                .attr('alignment-baseline', 'middle')
                .style('font-size', '11px')


                // PL-A Progress
                let pa = subsystem.append('g').attr('transform', `translate(110, 100)`)

                pa.append('rect').attr('transform', `translate(0,-11)`)
                .attr('width', '35px').attr('height', '20px')
                .attr('opacity', 1).attr('stroke-width', 0.3)
                .attr('fill', '#ccc')

                pa.append('text').text('PL-A').attr('transform', `translate(16, 0)`)
                .attr('text-anchor', 'middle')
                .attr('alignment-baseline', 'middle')
                .style('font-size', '11px')

                pa.append('rect').attr('transform', `translate(35,-11)`)
                .attr('width', '70px').attr('height', '20px')
                .attr('opacity', 1).attr('stroke-width', 0.1)
                .attr('stroke', '#424242')
                .attr('fill', 'Transparent')
                
                pa.append('rect').attr('transform', `translate(35,-11)`)
                .attr('width', item.PA_OV_PROG ? item.PA_OV_PROG / 100 * 70 + 'px' : '0').attr('height', '20px')
                .attr('opacity', 1).attr('stroke-width', 0.1)
                .attr('fill', item.PA_OV_PROG == 100 ? '#C8E6C9' : '#FFFF8D')
                
                pa.append('text').text(item.PA_OV_REM).attr('transform', `translate(50, 0)`)
                .attr('text-anchor', 'middle')
                .attr('alignment-baseline', 'middle')
                .attr('fill', '#D50000')
                .style('font-size', '11px')
                
                pa.append('text').text(' / ').attr('transform', `translate(72, 0)`)
                .attr('text-anchor', 'middle')
                .attr('alignment-baseline', 'middle')
                .style('font-size', '11px')

                pa.append('text').text(item.PA_OV_TOT).attr('transform', `translate(90, 0)`)
                .attr('text-anchor', 'middle')
                .attr('alignment-baseline', 'middle')
                .style('font-size', '11px')

                // PC Progress
                let pc = subsystem.append('g').attr('transform', `translate(220, 100)`)

                pc.append('rect').attr('transform', `translate(0,-11)`)
                .attr('width', '25px').attr('height', '20px')
                .attr('opacity', 1).attr('stroke-width', 1)
                .attr('fill', '#ccc')

                pc.append('text').text('PC').attr('transform', `translate(13, 0)`)
                .attr('text-anchor', 'middle')
                .attr('alignment-baseline', 'middle')
                .style('font-size', '11px')

                pc.append('rect').attr('transform', `translate(25,-11)`)
                .attr('width', '70px').attr('height', '20px')
                .attr('opacity', 1).attr('stroke-width', 0.1)
                .attr('stroke', '#424242')
                .attr('fill', 'Transparent')
                
                pc.append('rect').attr('transform', `translate(25,-11)`)
                .attr('width', item.B_OV_PROG ? item.B_OV_PROG / 100 * 70 + 'px' : '0').attr('height', '20px')
                .attr('opacity', 1)
                .attr('stroke', item.B_OV_PROG == 100 ? '#C8E6C9' : '#FFFF8D')
                .attr('stroke-width', '0.1')
                .attr('fill', item.B_OV_PROG == 100 ? '#C8E6C9' : '#FFFF8D')
                
                pc.append('text').text(item.B_OV_REM).attr('transform', `translate(40, 0)`)
                .attr('text-anchor', 'middle')
                .attr('alignment-baseline', 'middle')
                .attr('fill', '#D50000')
                .style('font-size', '11px')
                
                pc.append('text').text(' / ').attr('transform', `translate(60, 0)`)
                .attr('text-anchor', 'middle')
                .attr('alignment-baseline', 'middle')
                .style('font-size', '11px')

                pc.append('text').text(item.B_OV_TOT).attr('transform', `translate(80, 0)`)
                .attr('text-anchor', 'middle')
                .attr('alignment-baseline', 'middle')
                .style('font-size', '11px')
              }
              itemX += 350
            }
            itemY += 130
          }
        })
        typeY += rowMax * 145
      })
    },
  }
}
