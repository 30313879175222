import * as d3 from 'd3'

export default {
  methods: {

    draw_Summary(selection, unit, d_) {

      let Summary = selection
      .append('g')
      .attr('transform', `translate(${d_.x}, ${d_.y - 150})`)
      .attr('font-family', 'roboto')

      Summary
      .append('text')
      .attr('transform', `translate(15, 55)`)
      .attr('font-size', 10).attr('fill', '#757575').attr('text-anchor', 'end')
      .text('CheckSheets')

      Summary
      .append('text')
      .attr('transform', `translate(15, 130)`)
      .attr('font-size', 10).attr('fill', '#757575').attr('text-anchor', 'end')
      .text('Punch')

      Summary
      .append('line')
      .attr('x1', 20).attr('y1', 35 - 3).attr('x2', 25).attr('y2', 35 - 3)
      .attr('stroke', '#bcbcbc').attr('stroke-width', 1).attr('stroke-opacity', .3)

      Summary
      .append('line')
      .attr('x1', 20).attr('y1', 75 - 2).attr('x2', 25).attr('y2', 75 - 2)
      .attr('stroke', '#bcbcbc').attr('stroke-width', 1).attr('stroke-opacity', .3)

      Summary
      .append('line')
      .attr('x1', 20).attr('y1', 35 - 3).attr('x2', 20).attr('y2', 75 - 2)
      .attr('stroke', '#bcbcbc').attr('stroke-width', 1).attr('stroke-opacity', .3)

      Summary
      .append('line')
      .attr('x1', 20).attr('y1', 110 - 3).attr('x2', 25).attr('y2', 110 - 3)
      .attr('stroke', '#bcbcbc').attr('stroke-width', 1).attr('stroke-opacity', .3)

      Summary
      .append('line')
      .attr('x1', 20).attr('y1', 150 - 2).attr('x2', 25).attr('y2', 150 - 2)
      .attr('stroke', '#bcbcbc').attr('stroke-width', 1).attr('stroke-opacity', .3)

      Summary
      .append('line')
      .attr('x1', 20).attr('y1', 110 - 3).attr('x2', 20).attr('y2', 150 - 2)
      .attr('stroke', '#bcbcbc').attr('stroke-width', 1).attr('stroke-opacity', .3)

      let items = {
        code:  ['A',       'B',       'C',       'AP',      'BP',      'CP'      ],
        name:  ['A',       'B',       'C',       'A',       'B',       'C'       ],
        y:     [35,        55,        75,        110,        130,       150      ],
        color: ['#83D2F5', '#FEED57', '#FCE3EB', '#E0E0DF', '#E0E0DF', '#E0E0DF' ],
      }
      
      items.code.forEach((d,i)=> {
        this.draw_barChart(Summary, {
          idx:    d_.idx,
          unit:   unit, 
          name:   items.name[i], 
          code:   items.code[i], 
          y:      items.y[i], 
          color:  items.color[i], 
        })
      })

    },

    
    draw_barChart(selection, d) {

      let barX = 45
      let proLen = 100
      let TOT = d.unit[`${d.code}_TOT`]

      let progress = selection
      .append('g')
      .attr('transform', `translate(30.5, ${d.y - .5})`)
      .attr('font-size', 10)

      progress
      .append('rect')
      .attr('x', -2.5).attr('y', -8.5)
      .attr('width', 11).attr('height', 11)
      .attr('fill', '#fff').attr('stroke', '#bcbcbc').attr('stroke-width', 1).attr('stroke-opacity', .5)

      // Item Name
      progress
      .append('text')
      .attr('id', `ITEM_${d.idx}_${d.code}_NAME`)
      .attr('x', 0).attr('y', 0)
      .attr('fill', '#333').attr('opacity', TOT == 0 ? 0.2 : 1)
      .text(d.name)
      
      // Total
      progress
      .append('text')
      .attr('id', `ITEM_${d.idx}_${d.code}_TOT`)
      .attr('x', barX-3).attr('y', 0)
      .attr('fill', '#333').attr('text-anchor', 'end')
      .attr('cursor', 'pointer').attr('opacity', TOT == 0 ? 0.2 : 1)
      .text(d.unit[`${d.code}_TOT`])
      .call(this.call_Summary, {unit: d.unit.UNIT, sSys: 'OV', item: d.code, cols: 'TOT'}) // ---> Event.js

      progress
      .append('rect')
      .attr('x', barX).attr('y', -9).attr('opacity', .3)
      .attr('width', proLen).attr('height', 11).attr('fill', '#E0E0DF')

      let PRO = d.unit[`${d.code}_PRO`]
      progress
      .append('rect')
      .attr('id', `ITEM_${d.idx}_${d.code}_PRO`)
      .attr('x', barX).attr('y', -9).attr('opacity', 1)
      .attr('width', PRO).attr('height', 11).attr('fill', d.color)

      // Actual
      progress
      .append('text')
      .attr('id', `ITEM_${d.idx}_${d.code}_ACT`)
      .attr('y', 0).attr('fill', '#333')
      .attr('x', PRO < 70 ? barX + PRO + 2 : barX + PRO - 2)
      .attr('text-anchor', PRO < 70 ?  'start' : 'end')
      .attr('cursor', 'pointer').attr('opacity', TOT == 0 ? 0.2 : 1)
      .text(d.unit[`${d.code}_ACT`])
      .call(this.call_Summary, {unit: d.unit.UNIT,sSys: 'OV', item: d.code, cols: 'ACT'}) // ---> Event.js

      // Remain
      progress
      .append('text')
      .attr('id', `ITEM_${d.idx}_${d.code}_REM`)
      .attr('x', barX + proLen + 3).attr('y', 0)
      .attr('fill', '#333').attr('text-anchor', 'start')
      .attr('cursor', 'pointer').attr('opacity', TOT == 0 ? 0.2 : 1)
      .text(d.unit[`${d.code}_REM`])
      .call(this.call_Summary, {unit: d.unit.UNIT, sSys: 'OV', item: d.code, cols: 'REM'}) // ---> Event.js
    },
    
    
  }
}