import * as d3 from 'd3'
// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  data:() => ({
    legend: [
      {key:'SUBSYSTEM_STATUS', value:'Not Started', text: 'Not Started',  background: '#ccc', stroke: '#757575', tColor: '#000'},
      {key:'SUBSYSTEM_STATUS', value:'A ITR in progress', text: 'A ITR in Progress', background: '#FFFF2B', stroke: '#FDD835', tColor: '#000'},
      {key:'SUBSYSTEM_STATUS', value:'A ITR Completed', text: 'A ITR Completed', background: '#9DC3E6', stroke: '#64B5F6', tColor: '#000'},
      {key:'SUBSYSTEM_STATUS', value:'MC Completed', text: 'MC Completed', background: '#1E88E5', stroke: '#1565C0', tColor: '#fff'},
      {key:'SUBSYSTEM_STATUS', value:'B ITR in progress', text: 'B ITR in Progress', background: '#FFD600', stroke: '#FFAB00', tColor: '#000'},
      {key:'SUBSYSTEM_STATUS', value:'B ITR Completed', text: 'B ITR Completed', background: '#8BC34A', stroke: '#8BC34A', tColor: '#000'},
      // {key:'SUBSYSTEM_STATUS', value:'Completed', text: 'Completed', background: '#43A047', stroke: '#43A047', tColor: '#fff'},
      {key:'PUNCH_STATUS', value:'A', text: 'PL-A left', background: '#fff', stroke: '#D50000', tColor: '#000'},
    ]
  }),
  methods: {
    Draw_Charts() {
      let svg = this.svg

      let dataSet = JSON.parse(JSON.stringify(this.DataItems))

      let overallGroup = svg.append('g').attr('class', `overall_equipment_commence_group__`).attr('transform', `translate(30, 70)`)


      
      
      let legend = this.svg
      .append('g')
      .attr('transform', `translate(40, 120)`)

      this.legend.forEach((d, i) => {

        legend
        .append('rect')
        .attr('transform', `translate(10, ${20*i})`)
        .attr('rx', 3)
        .attr('ry', 3)
        .attr('width', 100)
        .attr('height', 16)
        .attr('fill', d.background)
        .attr('stroke', d.stroke).attr('stroke-width', 1)

        legend
        .append('text')
        .attr('transform', `translate(60, ${(20*i) + 8} )`)
        .style('font-family', 'Roboto-Regular, Roboto')
        .style('font-size', 10)
        .style('fill', d.tColor)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(d.text)
      })


      let rooms = [...new Set(dataSet.map(d => d.ROOM))]
      let sysTypes = [...new Set(dataSet.map(d => d.SYS_TYPE))]

      let roomGroup = overallGroup.append('g').attr('class', `equipment_commence_room_group__`).attr('transform', `translate(200, 50)`)
      let typeGroup = overallGroup.append('g').attr('class', `equipment_commence_type_group__`).attr('transform', `translate(0, 250)`)

      
      roomGroup
      .selectAll('image')
      .data(rooms)
      .enter()
      .append('image')
      .attr('transform', (d, i) => `translate(${(i * 280) + 50}, 0) scale(0.80)`)
      .attr('xlink:href', d => {
        let filename = d
        if (d == 'SafeHaven & SOP') filename = 'SafeHaven'
        return `${this.__HOST_NAME_RESOURCE}/TORTUE/SystemCompletion/LoopTesting/${filename}.png`
      })

      roomGroup
      .selectAll('text')
      .data(rooms)
      .enter()
      .append('text')
      .attr('transform', (d, i) => `translate(${(i * 280) + 130}, 190)`)
      .attr('text-anchor', 'middle')
      .style('font-weight', 'bold')
      .style('font-size', '20px')
      .attr('alignment-baseline', 'middle')
      .text(d => d)


      typeGroup
      .selectAll('rect')
      .data(sysTypes)
      .enter()
      .append('rect')
      .attr('transform', (d, i) => `translate(7, ${(i * 120) + 25})`)
      .attr('width', '120px')
      .attr('height', '95px').attr('rx', 5)
      .attr('fill', (d) => {
        if (d == 'ICSS') return '#FFE0B2'
        else if(d == 'PAS') return '#FFAB91'
        else if(d == 'FGS') return '#B3E5FC'
        else if(d == 'SIS') return '#90CAF9'
        else return '#29B6F6'
      })


      typeGroup
      .selectAll('text')
      .data(sysTypes)
      .enter()
      .append('text')
      .attr('transform', (d, i) => {
        if (d == 'ICSS') return `translate(67, ${(i * 120) + 105})`
        else return `translate(67, ${(i * 120) + 75})`
      })
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .style('font-weight', 'bold')
      .style('font-size', (d) => {
        if (d == 'ICSS') return '22px'
        else return '30px'
      })
      .text(d => d)


      typeGroup
      .append('image')
      .attr('transform', (d, i) => `translate(30, 40)  scale(0.75)`)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/SystemCompletion/LoopTesting/ICSS.png`)


      let subsystemGroup = overallGroup.append('g').attr('class', 'subsystem_group__').attr('transform', `translate(200, 270)`)

      rooms.forEach((room, rIdx) => {
        sysTypes.forEach((systype, tIdx) => {
          let filter = dataSet.filter(f => f.ROOM == room && f.SYS_TYPE == systype)[0]
          if (!filter) return

          let legend = this.legend.find(d => d.value == filter.SUBSYSTEM_STATUS)
          if (!legend) return

          let subsystem = subsystemGroup.append('g').attr('class', `${room}_${systype}_`).attr('transform', `translate(${280 * rIdx}, ${120 * tIdx})`)
          .on('mouseover', () => subsystem.style('cursor', 'pointer').style('opacity',0.7))
          .on('mouseout', () => subsystem.style('opacity',1))
          .on('click', () => {
            let request_ = this.getRequestColumnProps('request', {SUBSYSTEM: filter.SUBSYSTEM, DESC: filter.SUBSYSTEM_DESCR}, this.ColumnProps)
            this.$emit('request-action', request_)
          })
          
          subsystem.append('rect').attr('transform', `translate(0,0)`)
          .attr('width', '260px').attr('height', '100px').attr('rx', 3)
          .attr('fill', '#fff')
          .attr('stroke', filter.PUNCH_STATUS == 'A' ? 'red' : legend.stroke).attr('stroke-width', '1px')
          
          // SUBSYSTEM
          subsystem.append('rect').attr('transform', `translate(1,1)`).attr('rx', 3)
          .attr('width', '258px').attr('height', '29px')
          .attr('opacity', 1).attr('stroke-width', 0)
          .attr('stroke', legend.stroke ? legend.stroke : '#ccc')
          .attr('fill', legend.background)

          subsystem.append('text').text(filter.SUBSYSTEM).attr('transform', `translate(130, 16)`)
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          .style('font-size', '18px')
          .attr('fill', legend.tColor)

          // DESC
          let textarea = subsystem.append('foreignObject')
          .attr('transform', `translate(5, 35)`)
          .attr('width', '250px')
          .attr('height', '36px')
          .append('xhtml:body')

          textarea.append('div')
          .html(filter.SUBSYSTEM_DESCR)
          .style('overflow', 'auto')
          .style('text-align', 'left')
          .style('font-size', '12px')


          if (filter.SUBSYSTEM == '21-6030-6036-05') {
            subsystem.append('g').attr('transform', `translate(130, -20)`)
            .append('text').text('L5 Telecom Room').attr('transform', `translate(0, 0)`)
            .attr('text-anchor', 'middle')
            .attr('alignment-baseline', 'middle')
            .style('font-weight', 'bold')
            .style('font-size', '20px')
          }

          
          // MC Progress
          let mc = subsystem.append('g').attr('transform', `translate(5, 85)`)

          mc.append('rect').attr('transform', `translate(0,-11)`)
          .attr('width', '25px').attr('height', '20px')
          .attr('opacity', 1).attr('stroke-width', 0.3)
          .attr('fill', '#ccc')

          mc.append('text').text('MC').attr('transform', `translate(13, 0)`)
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          .style('font-size', '10px')

          mc.append('rect').attr('transform', `translate(25,-11)`)
          .attr('width', '50px').attr('height', '20px')
          .attr('opacity', 1).attr('stroke-width', 0.1)
          .attr('stroke', '#424242')
          .attr('fill', 'Transparent')
          
          mc.append('rect').attr('transform', `translate(25,-11)`)
          .attr('width', filter.A_OV_PROG ? filter.A_OV_PROG / 100 * 50 + 'px' : '0px').attr('height', '20px')
          .attr('opacity', 1).attr('stroke-width', 0.1)
          .attr('fill', filter.A_OV_PROG == 100 ? '#C8E6C9' : '#FFFF8D')
          
          mc.append('text').text(filter.A_OV_REM).attr('transform', `translate(40, 0)`)
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          .attr('fill', '#D50000')
          .style('font-size', '9px')
          
          mc.append('text').text(' / ').attr('transform', `translate(50, 0)`)
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          .style('font-size', '9px')

          mc.append('text').text(filter.A_OV_TOT).attr('transform', `translate(65, 0)`)
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          .style('font-size', '9px')


          // PL-A Progress
          let pa = subsystem.append('g').attr('transform', `translate(87, 85)`)

          pa.append('rect').attr('transform', `translate(0,-11)`)
          .attr('width', '35px').attr('height', '20px')
          .attr('opacity', 1).attr('stroke-width', 0.3)
          .attr('fill', '#ccc')

          pa.append('text').text('PL-A').attr('transform', `translate(16, 0)`)
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          .style('font-size', '10px')

          pa.append('rect').attr('transform', `translate(35,-11)`)
          .attr('width', '50px').attr('height', '20px')
          .attr('opacity', 1).attr('stroke-width', 0.1)
          .attr('stroke', '#424242')
          .attr('fill', 'Transparent')
          
          pa.append('rect').attr('transform', `translate(35,-11)`)
          .attr('width', filter.PA_OV_PROG ? filter.PA_OV_PROG / 100 * 50 + 'px' : '0').attr('height', '20px')
          .attr('opacity', 1).attr('stroke-width', 0.1)
          .attr('fill', filter.PA_OV_PROG == 100 ? '#C8E6C9' : '#FFFF8D')
          
          pa.append('text').text(filter.PA_OV_REM).attr('transform', `translate(50, 0)`)
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          .attr('fill', '#D50000')
          .style('font-size', '9px')
          
          pa.append('text').text(' / ').attr('transform', `translate(62, 0)`)
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          .style('font-size', '9px')

          pa.append('text').text(filter.PA_OV_TOT).attr('transform', `translate(75, 0)`)
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          .style('font-size', '9px')

          // PC Progress
          let pc = subsystem.append('g').attr('transform', `translate(180, 85)`)

          pc.append('rect').attr('transform', `translate(0,-11)`)
          .attr('width', '25px').attr('height', '20px')
          .attr('opacity', 1).attr('stroke-width', 1)
          .attr('fill', '#ccc')

          pc.append('text').text('PC').attr('transform', `translate(13, 0)`)
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          .style('font-size', '10px')

          pc.append('rect').attr('transform', `translate(25,-11)`)
          .attr('width', '50px').attr('height', '20px')
          .attr('opacity', 1).attr('stroke-width', 0.1)
          .attr('stroke', '#424242')
          .attr('fill', 'Transparent')
          
          pc.append('rect').attr('transform', `translate(25,-11)`)
          .attr('width', filter.B_OV_PROG ? filter.B_OV_PROG / 100 * 50 + 'px' : '0').attr('height', '20px')
          .attr('opacity', 1)
          .attr('stroke', filter.B_OV_PROG == 100 ? '#C8E6C9' : '#FFFF8D')
          .attr('stroke-width', '0.1')
          .attr('fill', filter.B_OV_PROG == 100 ? '#C8E6C9' : '#FFFF8D')
          
          pc.append('text').text(filter.B_OV_REM).attr('transform', `translate(36, 0)`)
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          .attr('fill', '#D50000')
          .style('font-size', '9px')
          
          pc.append('text').text(' / ').attr('transform', `translate(50, 0)`)
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          .style('font-size', '9px')

          pc.append('text').text(filter.B_OV_TOT).attr('transform', `translate(65, 0)`)
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          .style('font-size', '9px')

        })
      })

    },
  }
}
