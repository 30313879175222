<template>
  <div :id="localId" class="svg_wrapper"></div>
</template>

<script>
import * as d3 from 'd3'
import mx_Core from './includes/mixins/importer'

export default {
  name: "k-chart-el",
  mixins: [mx_Core],
  props: {
    id: String,
    modeExport: Boolean,
    getSelectItems: String,  
    
    FilterValues: { type: Object, default: () => ({}) },
    ColumnProps: { type: Array, default: () => ([]) },

    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },
    Queries: { type: Object, default: () => ({}) },
    DataItems: { type: Array, default: () => ([]) },
    
    callback: { type: Object, default: () => ({}) },
  },
  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
  },
  mounted() {
    this.register(this.draw)
  },
  methods: {
    draw() {
      if (!this.ready2draw) return

      this.clear()
      this.setDefaultValues()
      this.drawCanvas()
      this.drawChart()
    },
    drawChart() {
      this.loadSvg({
        selection : this.svg,
        width     : this.Canvas.CanvasWidth,
        height    : this.Canvas.CanvasHeight,
        path      : '/China1/HeavyLifting/HeavyLiftingProgram/svg/HeavyLiftingProgram.svg'
      }).then((svg) => {
        let zoom_ = d3.zoom()
        .translateExtent([[0, 0], [6000, 4200]])
        .scaleExtent([1, 3.75])
        .extent([[0, 0], [6000, 4200]])
        .on('zoom', this.zoom)

        this.innerSvg = svg
        this.innerSvg.call(zoom_)

        this.drawHeaderInfo()
        this.drawSharedInfo()
        this.drawTexts()

        this.rebuildEqCode()
        this.rebuildEqTag()

        this.putIndicator()

        this.complete()

        this.search()
      })
    },
    reforming(code) {
      // this.loadSvg(`${this.__HOST_NAME_RESOURCE}/TCO/Equipment/${this.FilterValues.MOD}/${this.FilterValues.MOD}_${code}.svg`).then(() => {
      //   this.Draw_Code()
      // })
    }
  }
}
</script>

<style lang="stylus" scoped>
.svg_wrapper {
  width: 1600px;
  height: 1120px;

  overflow: hidden;
}
</style>