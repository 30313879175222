import * as d3 from 'd3'
// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  data:() => ({
    thead: [
      { key: 'ITEM', html: 'Type' },
      { key: 'TOTAL_ISSUE', html: 'Total Issued' },
      { key: 'TOTAL_CLOSED', html: 'Total Closed' },
      { key: 'ISSUED_THISWK', html: 'Issued This Week' },
      { key: 'TOTAL_OPEN', html: 'Total Open' },
      { key: 'CLOSED_RATE', html: 'Closed Rate' }
    ],
    tbody: [

      { key: 'ITEM', w: 100, align: 'center'},
      { key: 'TOTAL_ISSUE', w: 95,align: 'center'},
      { key: 'TOTAL_CLOSED', w: 95, align: 'center'},
      { key: 'ISSUED_THISWK', w: 100, align: 'center'},
      { key: 'TOTAL_OPEN', w: 95, align: 'center'}, 
      { key: 'CLOSED_RATE', w: 95, align: 'center'}, 
    ],
    
  }),
  methods: {
    Draw_Charts() {
      let svg = this.svg

      this.filteredList = this.DataItems

      let list = this.filteredList
      // let firstColumName = [...new Set(this.Queries.SQL1.map(d => d.ORIGINATING_COMPANY))]
      // console.log(firstColumName)

      let group = svg.append('g').attr('class', 'table_group__').attr('transform', `translate(0.5, 0.5)`)
      let table_group = group.append('g').attr('transform', `translate(0.5, 0.5)`)
      
      let y_ = this.DataItems.length * 22 + 40

      let table_obj = table_group.append('foreignObject')
        .attr('y',0)
        .attr('width', '100%')
        .attr('height', `${y_}px`)
        .append('xhtml:body')
        .style('position', 'fixed')

      const reducer = (previousValue, currentValue) => previousValue + currentValue
      let widths = this.tbody.map(d => d.w)
      let sumWidth = widths.reduce(reducer)

      let table = table_obj.append('table')
      .style('border-collapse', 'collapse')
      .style('font-size', '11px')
      .style('width', `${sumWidth}px`)
      .style('table-layout', 'fixed')

      let colgroup = table.append('colgroup')
      this.tbody.forEach(k => {
        colgroup.append('col').style('width', k.w + 'px')
      })

      let thead = table.append('thead')
      let tr = thead.append('tr')

      this.thead.forEach(th => {
        tr.append('th')
        .attr('class', 'table_group__th')
        .style('border', 'solid 0.7px #eee')
        .style('height', '24px')
        .style('padding', '3px')
        .style('font-size', '11px').style('color', '#ffffff')
        .style('background', th.color ? th.color : '#607D8B')
        .html(th.html)
      })

      let tbodykeys = this.tbody
      
      let tbody = table.append('tbody').attr('id', 'summay_tbody_')
      if (list.length == 0) {
        
        let tr = tbody.append('tr')
        tr.append('td')
          .attr('colspan', 5)
          .style('background', '#F5F5F5')
          .style('height', '24px')
          .style('text-align', 'center')
          .style('color', '#424242')
          .style('padding', '0 3px 0 3px')
          .style('font-style', 'italic')
          .html('No data')
        
        return
      }
      for (let r = 0; r < list.length; r++) {
        let tr = tbody.append('tr')
        tr.on('mouseover', () => tr.style('background-color', '#FAFAFA' ))
        tr.on('mouseout', () => tr.style('background-color', 'Transparent' ))
        tbodykeys.forEach((k, i) => {
          let td = tr.append('td')
            .style('border', 'solid 0.1px #E4E4E4')
            .style('height', '22px')
            .style('text-align', k.align ? k.align : 'center')
            .style('color', '#000000')
            .style('padding', '0 3px 0 3px')
            .style('background', r%2==0 ? '#ECEFF1':'#fff' )
            td.html(list[r][k.key])
            .style('cursor', () => {
              if (i == 0) return
              if (list[r][k.key] == 0) return
              return 'pointer'
            })
            .on('click', () => {
              if (i == 0) return
              if (list[r][k.key] == 0) return
              
              let filterArr = []

              // let filterString = list[r]['DISC'] != 'Total' ? ` DISC = '${list[r]['DISC']}' ` : ``

              
              // if (list[r]['ORIGINATING_COMPANY'] != 'Total') filterArr.push(` ORIGINATING_COMPANY = '${list[r]['ORIGINATING_COMPANY']}' `)
              // if (k.key == 'RESPONDED') filterArr.push(` [CAL_REVIEW_STATUS_CODE] = 'RESPONDED' `)
              // if (k.key == 'OPEN') filterArr.push(` [CAL_REVIEW_STATUS_CODE] = 'OPEN' `)
              // if (k.key == 'CANCELLED') filterArr.push( ` [CAL_REVIEW_STATUS_CODE] = 'CANCELLED' `)

              // let request_ = {
              //   dataType: 'row',
              //   action: {
              //     type: 'direct',
              //     target: 'slide-modal',
              //     component: 'ServiceDatatable',
              //     id: 345,
              //     no: ''
              //   },
              //   filters: { 
              //     ...this.FilterValues
              //   },
              //   iFilters: {
              //     filterString : filterArr.join(' AND '),
              //     inputFilter  : ''
              //   }
              // }
              // this.$emit('request-action', request_)
            })
            // if (r == list.length -1 ) {
            //   td.style('font-weight', 'bold')
            //   .style('border', 'solid 2px #E4E4E4')
            // }
        })
      }
    },
  }
}
