const Hull_LQ = [
  { BLK: 'LQ01',    Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', x1: 996,     y1: 267.46, x2: 681,    y2: 494.46, x3: 580,    y3: 401.46, x4: 416,    y4: 538.46, x5: 510, y5: 407, layout: 'Y', },
  { BLK: 'LQ02',    Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', x1: 1011.14, y1: 280.45, x2: 696.14, y2: 507.45, x3: 595.14, y3: 414.45, x4: 602.14, y4: 414.45, x5: 532, y5: 471, layout: 'Y', },
  { BLK: 'LQ01_EQ', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', x1: 1163.33, y1: 346.31, x2: 845.33, y2: 571.31, x3: 747.33, y3: 480.31, x4: 580.33, y4: 614,    x5: 975, y5: 627, layout: 'Y', },
  { BLK: 'LQ03',    Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', x1: 1010.92, y1: 265.92, x2: 695.92, y2: 492.92, x3: 594.92, y3: 399.92, x4: 749.15, y4: 309.92, x5: 564, y5: 380, layout: 'Y', },
  { BLK: 'LQ04',    Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', x1: 1010.8,  y1: 254.33, x2: 695.8,  y2: 481.33, x3: 845.8,  y3: 584.33, x4: 701,    y4: 682.03, x5: 549, y5: 370, layout: 'Y', },
  { BLK: 'LQ03_EQ', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', x1: 1130.01, y1: 331.09, x2: 812.01, y2: 557.09, x3: 714.01, y3: 465.09, x4: 869,    y4: 375,    x5: 897, y5: 565, layout: 'Y', },
  { BLK: 'LQ05',    Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', x1: 1010.93, y1: 240.21, x2: 695.93, y2: 466.21, x3: 845.93, y3: 570.21, x4: 843.17, y4: 549.43, x5: 604, y5: 367, layout: 'Y', },
  { BLK: 'LQ06',    Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', x1: 1010.95, y1: 213.85, x2: 695.95, y2: 439.85, x3: 845.95, y3: 543.85, x4: 980,    y4: 445,    x5: 554, y5: 506, layout: 'Y', },
  { BLK: 'LQ07',    Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', x1: 1000.67, y1: 195,    x2: 685.67, y2: 420,    x3: 835.67, y3: 525,    x4: 1155   ,y4: 312,    x5: 595, y5: 350, layout: 'Y', },
  { BLK: 'LQ04_EQ', Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', x1: 1189.19, y1: 311.41, x2: 871.19, y2: 538.41, x3: 1025.19,y3: 640.41, x4: 874,    y4: 736,    x5: 1045,y5: 532, layout: 'Y', },
  
]

export default Hull_LQ