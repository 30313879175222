export default {
  data: () => ({

    Status: [ 
      { code: 'PO',          title: 'PO',                          colName: { total: 'PO_TOTAL'                 , actual: 'PO_ACTUAL'    }, bColor: '#84C7E5', sColor: '#00838F', tColor: '#333', opacity: 1.00, },
      { code: 'FAT',         title: 'FAT',                         colName: { total: 'FAT_TOTAL'                , actual: 'FAT_ACTUAL'   }, bColor: '#2281C5', sColor: '#9F368B', tColor: '#fff', opacity: 1.00, },
      { code: 'IRN',         title: 'IRN',                         colName: { total: 'IRN_TOTAL'                , actual: 'IRN_ACTUAL'   }, bColor: '#CC93C1', sColor: '#9F368B', tColor: '#333', opacity: 1.00, },
      { code: 'ETA',         title: 'ON Site',                     colName: { total: 'ONSITE_TOTAL'             , actual: 'ONSITE_ACTUAL'}, bColor: '#F6D15E', sColor: '#F9A825', tColor: '#333', opacity: 1.00, },
      { code: 'INST',        title: 'Installed',                   colName: { total: 'INST_TOTAL'               , actual: 'INST_ACTUAL'  }, bColor: '#8DBA2D', sColor: '#558B2F', tColor: '#fff', opacity: 1.00, },
      { code: 'NOT_PO',      title: 'Not PO',                      colName: { total: 'NOT_PO_CNT'               , actual: ''             }, bColor: '#ffffff', sColor: '#BDBCBC', tColor: '#333', opacity: 1.00, },
      { code: 'ROS_PAST',    title: 'ETA is past from ROS',        colName: { total: 'ETA_PAST_FROM_ROS'        , actual: ''             }, bColor: '#FF9800', sColor: '#BDBCBC', tColor: '#333', opacity: 1.00, },
      { code: 'UPBLOCK_PAST',title: 'ETA is past from  Upper Blk.',colName: { total: 'ETA_PAST_FROM_UPPERBLOCK' , actual: ''             }, bColor: '#EC407A', sColor: '#BDBCBC', tColor: '#fff', opacity: 1.00, },
    ],
    Border: [
      {
        type: 'MEGA',
        shape: 'ellipse',
        width: 35,
        height: 15,
        color: '#9E9E9E',
        stroke: .2,
        textSize: 10,
        padding: 3,
      },
      {
        type: 'UNIT',
        shape: 'rect',
        width: 30,
        height: 14,
        color: '#9E9E9E',
        stroke: .2,
        textSize: 10,
        padding: 2.5,
      },
    ],
    LoadedTextProps: {
      x: 6.5,
      y: 6.5,
      color: '#757575',
      bColor: '#efefef',
      textSize: 8.5,
      padding: 1,
    },
    CircleBorder: {
      rx: 6.5,
      ry: 6.5,
      color: '#757575',
      bColor: '#efefef',
      stroke: .5,
      textSize: 8.5,
      padding: 1,
    },
    BlockStyle: {
      MEGA:{
        shape: 'ellipse',
        color: '#F5F5F5',
        stroke: .2,
        textSize: 9,
        padding: 2,
        rx: 20,
        ry: 8,
      },
      UNIT:{
        shape: 'rect',
        color: '#F5F5F5',
        stroke: .2,
        textSize: 9,
        padding: 2,
        width: 26,
        height:16
      },
    },
    BlockStatus_Coordinate: [
      {SCRNAME: 'Overall',        LV1: null     , LV2: null,        LV3: null,      LV4: null,    NAME: 'Tortue FPSO',    x: 1160,  y: 400, },
    ],
  }),
}