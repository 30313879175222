import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({

    dDMembers: [
      {text: 'Disciplines', value: 0},
      {text: 'BMW', value: 1},
      {text: 'Audi',value: 2},
      {text: 'Mercedes',value: 3},
      {text: 'Hyundai',value: 4},
    ],

    pupMenu: {
      optionDisplay: 'Y',
      linkDisplay: 'Y',
      width: 0, // automatically set
      x: 250,
      y: 0,
    }
  }),


  methods: {
    
    chart_Compare() {
      //


      



      

    },

    

  }
}
