import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {  
    Draw_Charts() {
      let data = null, 
          style = null, 
          cellX = null, 
          tmp = null, 
          Circle = null, 
          Guideline = null

      let overallMCStatus = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0)`)

      // // WeekPerformance_01
      // data = this.dataSet.filter(f=> f.TYPE == 'WEEK')
      // style = {
      //           x: 50,
      //           y: 30,
      // }
      // data.forEach(d=> {
      //   d.date = d.DATE
      //   if(d.DISC =='LWK' || d.DISC =='TWK') d.name = 'Total'; else d.name = d.DISC
      //   d.value = d.ACTUAL
      // })
      // this.WeekPerformance_01(overallMCStatus, data, style) // ---------------------------> 

      style = {
        id: 'WD',
        x: 100,
        y: 150,
        name: 'WD',
        sort: 'N',
      }
      tmp = this.dataSet.find(f=> f.TYPE == 'CERT' && f.DISC == 'WD')
      data = [
        {title: 'Plan',  value: null,      qty: tmp.PLAN},
        {title: 'Complete',  value: tmp.PROG,      qty: tmp.ACTUAL},
        {title: 'Remain',     value: 100-tmp.PROG,  qty: tmp.REMAIN}
      ]
      Circle = {
        CircleDiameter: 60,
        CircleColorSet: ['Gray','YellowGreen', 'LightGray', 'LightBlue', ],
        CircleHoleSize: 35,
        CircleStackSize: 5,
        CircleSliceSize: 3,
        CircleTitleSize: 14,
        CircleLegendSpace: 80,
      }
      Guideline = {
        GuideInside: 5,
        GuideOutside: 5,
        GuideExtend: 0,
      }
      this.Chart_Pie2(overallMCStatus, data, style, Circle, Guideline) // ---------------------------> 



      style = {
        id: 'MC',
        x: 280,
        y: 150,
        name: 'MC',
        sort: 'N',
      }
      tmp = this.dataSet.find(f=> f.TYPE == 'CERT' && f.DISC == 'MC')
      data =[
        {title: 'Plan',  value: null,      qty: tmp.PLAN},
        {title: 'Full MC Complete'  , value: tmp.PROG,     qty: tmp.APPROVED},
        {title: 'Partial MC Complete', value: null,      qty: tmp.APPROVED_PARTIALLY},
        {title: 'Remain'            , value: 100-tmp.PROG, qty: tmp.REMAIN }
      ]
      Circle = {
        CircleDiameter: 65,
        CircleColorSet: ['Gray','LightBlue','YellowGreen', 'LightGray', ],
        CircleHoleSize: 35,
        CircleStackSize: 5,
        CircleSliceSize: 3,
        CircleTitleSize: 14,
        CircleLegendSpace: 80,
      }
      Guideline = {
        GuideInside: 5,
        GuideOutside: 5,
        GuideExtend: 0,
      }
      this.Chart_Pie3(overallMCStatus, data, style, Circle, Guideline) // ---------------------------> 


      data  = this.dataSet.find(f=> f.TYPE == 'CERT' && f.DISC == 'WD').PROG
      style = {
        title: 'WD',
        x: 30,
        y: 320,
        width: 40,
        height: 18,
        radius: 3,
        tSize: 11,
        tColor: '#333',
        bColor: '#E0E0DF',
        progSize: 15,
      }
      this.Header_01( overallMCStatus, data, style) // ---------------------------> 

      let item = this.dataSet.find(f=> f.TYPE == 'CERT' && f.DISC == 'WD')
      style = {
        title:    ['Total', 'Complete', 'Remain'],
        cell:     [30, 85, 130],
        x:        30,
        y:        340,
        y1:       10,
        y2:       23,
        width:    140,
        height:   32,
        radius:   3,
        opacity:  0.7,
        bColor:   '#83D2F5',
        tSize:    10,
        tColor:   '#757575',
        vSize:    11,
        vColor:   '#fff',
        value:    [item.TOTAL, item.ACTUAL, item.REMAIN]
      }
      this.SummaryTable_01(overallMCStatus, style) // ---------------------------> 



      data  = this.dataSet.find(f=> f.TYPE == 'CERT' && f.DISC == 'MC').PROG
      style = {
        title: 'MC',
        x: 220,
        y: 320,
        width: 40,
        height: 18,
        radius: 3,
        tSize: 11,
        tColor: '#333',
        bColor: '#E0E0DF',
        progSize: 15,
      }
      this.Header_01( overallMCStatus, data, style) // ---------------------------> 

      item = this.dataSet.find(f=> f.TYPE == 'CERT' && f.DISC == 'MC')
      let itemData = item.APPROVED + item.APPROVED_PARTIALLY
      style = {
        title:    ['Total', 'Complete', 'Remain'],
        cell:     [30, 85, 130],
        x:        220,
        y:        340,
        y1:       10,
        y2:       23,
        width:    140,
        height:   32,
        radius:   3,
        opacity:  0.7,
        bColor:   '#83D2F5',
        tSize:    10,
        tColor:   '#757575',
        vSize:    11,
        vColor:   '#fff',
        value:    [item.TOTAL, itemData, item.REMAIN]
      }
      this.SummaryTable_01(overallMCStatus, style) // ---------------------------> 
    },
  }
}