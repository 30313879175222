import * as d3 from 'd3'

export default {
  methods: {

    draw_Forms() {
      if(this.stage_Header) this.stage_Header.remove()

      this.stage_Header = this.svg
      .append('g')
      .attr('id', 'common_header_area')
      .attr('transform', 'translate(0, 0)')

      this.loadSvg(
        this.stage_Header, {
        x   : 20,
        y   : 20,
        url : '/China1/icons/3d_Logo.svg'
      })

      this.stage_Header
      .append('text')
      .attr('transform', `translate(20, 95)`)
      .attr('font-size', 12).attr('fill', '#757575')
      .text('Area Progress Summary2')

      this.stage_Header
      .append('text')
      .attr('id', 'HeaderTitle_Area')
      .attr('transform', `translate(20, 115)`)
      .attr('font-size', 14).attr('fill', '#44A9DF')
      .text('')


      // Setting the 'HOME' & 'BACK' buttons
      var btn_Home = this.stage_Header
      .append('g')
      .attr('id', 'HOME')
      .attr('transform', 'translate(320, 33)scale(.8)')
      .style('cursor', 'pointer')
      .on('mouseover', () => {
        btn_Home.transition().duration(150).style("opacity", 0.5)
      })
      .on('mouseout', () => {
        btn_Home.transition().duration(150).style("opacity", 1)
      })
      .on('click', () => { this.routeTo( this.route.to, {path: 'Lev1'} )}) // --->

      btn_Home
      .append('path').attr('fill', '#B5BCBF')
      .attr('d', 'M2,29.5c0,5,4.1,9.1,9.1,9.1h18.2c5,0,9.1-4.1,9.1-9.1V11.2c0-5-4.1-9.1-9.1-9.1H11.1 c-5,0-9.1,4.1-9.1,9.1C2,11.2,2,29.5,2,29.5z')
      btn_Home
      .append('path').attr('fill', 'url(#BTN)')
      .attr('d', 'M0.6,28c0,5,4.1,9.1,9.1,9.1h18.2c5,0,9.1-4.1,9.1-9.1V9.8c0-5-4.1-9.1-9.1-9.1H9.7  c-5,0-9.1,4.1-9.1,9.1C0.6,9.8,0.6,28,0.6,28z')
      btn_Home
      .append('path').attr('fill', '#5B6678')
      .attr('d', 'M29.9,19.4l-5.2-5.2l-5.2-5l-5.2,5l-5.2,5.2h3.4v9.2h5.1v-7.2h3.8v7.2h5.1v-9.2  C26.5,19.4,29.9,19.4,29.9,19.4z')

      var btn_Back = this.stage_Header
      .append('g')
      .attr('id', 'BACK')
      .attr('transform', 'translate(360, 33)scale(.8)')
      .style('cursor', 'pointer')
      .on('mouseover', () => {
        btn_Back.transition().duration(150).style("opacity", 0.5)
      })
      .on('mouseout', () => {
        btn_Back.transition().duration(150).style("opacity", 1)
      })
      .on('click', () => { this.routeTo(this.route.to, 'back') }) // --->

      btn_Back
      .append('path').attr('fill', '#B5BCBF')
      .attr('d', 'M2,29.5c0,5,4.1,9.1,9.1,9.1h18.2c5,0,9.1-4.1,9.1-9.1V11.2c0-5-4.1-9.1-9.1-9.1H11.1 c-5,0-9.1,4.1-9.1,9.1C2,11.2,2,29.5,2,29.5z')
      btn_Back
      .append('path').attr('fill', 'url(#BTN)')
      .attr('d', 'M0.6,28c0,5,4.1,9.1,9.1,9.1h18.2c5,0,9.1-4.1,9.1-9.1V9.8c0-5-4.1-9.1-9.1-9.1H9.7  c-5,0-9.1,4.1-9.1,9.1C0.6,9.8,0.6,28,0.6,28z')
      btn_Back
      .append('path').attr('fill', '#5B6678')
      .attr('d', 'M25.2,27.3c0.4,0.2,0.7,0,0.7-0.4V11.5c0-0.5-0.3-0.7-0.7-0.4l-13.3,7.8c-0.4,0.2-0.4,0.6,0,0.9  L25.2,27.3z')


      this.loadSvg(
        this.stage_Header, {
        x   : 25,
        y   : 130,
        url : `/China1/Area_Summary/svg/Keyplan.svg`
      }).then(() => {
        this.Areas.forEach(d=> {
          d3.select(`#PLAN-${d.code}`).style('opacity', 0)
        })
      })

      this.stage_Header
      .append('text')
      .attr('transform', `translate(90, 290)`)
      .style('font-family', 'roboto').style('font-size', 12).style('fill', '#000')
      .text('KEYPLAN')
    },

  }
}
           