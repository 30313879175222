import * as d3 from 'd3'
// ------------------------------------------------------------------------------- Global
// Global Data & Defs
export default {
  methods: {  
    Draw_Charts() {   
      let data = null
      let style = null 
      let cellX = null 
      let tmp = null 
      let Circle = null
      let Guideline = null
      let item = null

      let overallPunchStatus = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0)`)

      let punchCategory = this.dataSet.map(d => d.CATEGORY)[0]

      // Header_01
      data  = this.dataSet.find(f=> f.CATEGORY == punchCategory && f.DISC == 'Overall').PROG
      style = {
        title: this.filters.TITLE,
        x: 10,
        y: 7,
        width: 90,
        height: 18,
        radius: 3,
        tSize: 11,
        tColor: '#333',
        bColor: '#E0E0DF',
        progSize: 15,
      }
      this.Header_01( overallPunchStatus, data, style) // ---------------------------> 

      // SummaryTable_01
      item = this.dataSet.find(f=> f.CATEGORY == punchCategory && f.DISC == 'Overall')
 
      style = {
        id:       'Punch',
        title:    [this.filters.TITLE1, this.filters.TITLE2, this.filters.TITLE3],
        cell:     [45, 95, 145],
        x:        10,
        y:        30,
        y1:       10,
        y2:       25,
        width:    155,
        height:   34,
        radius:   3,
        opacity:  0.7,
        bColor:   '#83D2F5',
        tSize:    11,
        tColor:   '#757575',
        vSize:    11,
        vColor:   '#fff',
        value:    [item.TOTAL, item.ACTUAL, item.REMAIN],
        sqlfilter:  {...this.filters},
      }
      
      data = {
        type: punchCategory
      }
      this.SummaryTable_Punch(overallPunchStatus, style, data) // ---------------------------> 

      // // Header_01
      // data  = this.dataSet.find(f=> f.CATEGORY == 'B' && f.DISC == 'Overall').PROG
      // style = {
      //   title: 'Punch B',
      //   x: 200,
      //   y: 30,
      //   width: 60,
      //   height: 18,
      //   radius: 3,
      //   tSize: 11,
      //   tColor: '#333',
      //   bColor: '#E0E0DF',
      //   progSize: 15,
      // }
      // this.Header_01( overallPunchStatus, data, style) // ---------------------------> 

      // // SummaryTable_01
      // item = this.dataSet.find(f=> f.CATEGORY == 'B' && f.DISC == 'Overall')
      // style = {
      //   id:       'PB',
      //   title:    ['Total', 'Closed', 'Active'],
      //   cell:     [45, 95, 145],
      //   x:        200,
      //   y:        50,
      //   y1:       10,
      //   y2:       23,
      //   width:    155,
      //   height:   32,
      //   radius:   3,
      //   opacity:  0.7,
      //   bColor:   '#83D2F5',
      //   tSize:    9,
      //   tColor:   '#757575',
      //   vSize:    11,
      //   vColor:   '#fff',
      //   value:    [item.TOTAL, item.ACTUAL, item.REMAIN]
      // }
      // this.SummaryTable_01(overallPunchStatus, style) // ---------------------------> 

      // Bar_Punch_01
      data = this.dataSet.filter(f=> f.CATEGORY == punchCategory && f.DISC !== 'Overall')
      style = {
        id:       'Punch',
        x:        0,
        y:        70,
        cell:     [30, 65, 100, 105, 0, 0],
        bColor:   '#F7BACF', 
        sColor:   '#EC407A', 
        tColor1:  '#fff', 
        tColor2:  '#757575', 
        opacity:  1,
        length:   75,
        lineHeight: 16,
        min: 25,
        sqlfilter:  {...this.filters},
      }
      this.Bar_Punch_01(overallPunchStatus, data, style) // ---------------------------> 

      // // Bar_Punch_01
      // data = this.dataSet.filter(f=> f.CATEGORY == 'B' && f.DISC !== 'Overall')
      // style = {
      //   id:       'PB',
      //   x:        185,
      //   y:        90,
      //   cell:     [30, 65, 100, 105, 0, 0],
      //   bColor:   '#FCE3EB', 
      //   sColor:   '#F7BACF', 
      //   tColor1:  '#fff', 
      //   tColor2:  '#757575', 
      //   opacity:  1,
      //   length:   75,
      //   lineHeight: 16,
      //   min: 25,
      // }
      // this.Bar_Punch_01(overallPunchStatus, data, style) // ---------------------------> 
    },
  }
}