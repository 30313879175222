import * as d3 from 'd3'
import { text } from 'd3'

// ------------------------------------------------------------------------------- Global

export default {

  methods: {
    drawChart() {
      let values_ = this.DataItems

      
      let svg = this.svg

      let iconGroup = svg.append('g').attr('class', 'welding_repair_summary_group_icon__').attr('transform', `translate(0, 0)`)
      let iconWidth = 170

      iconGroup.append('rect')
      .attr('x', 5).attr('y', 5).attr('rx', 3).attr('ry', 3).attr('width', iconWidth).attr('height', iconWidth).attr('fill', 'Transparent')
      .attr('stroke', '#9E9E9E').attr('stroke-width', 3)

      iconGroup
      .append('text').text('MT').attr('x', iconWidth / 2).attr('y', iconWidth - 10)
      .attr('text-anchor', 'middle').style('font-size',100).attr('fill', '#9E9E9E').style('font-weight', 'bold')

      iconGroup.append('image')
      .attr('id', `welding_repair_img_rt}`)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Icons/QC/mt.png`)
      .attr('transform','translate(20,10)')

      let textGroup = svg.append('g').attr('class', 'welding_repair_summary_group_icon__').attr('transform', `translate(190, 0.5)`).style('font-size', 12)

      textGroup
      .append('text').text('Structure').attr('x', 170).attr('y', 12).attr('text-anchor', 'middle').style('font-size', 12).style('font-weight', 'bold')
      textGroup
      .append('text').text('(Length, mm)').attr('x', 170).attr('y', 24).attr('text-anchor', 'middle').style('font-size', 11).style('fill', '#9E9E9E')

      textGroup
      .append('text').text('Pipe').attr('x', 280).attr('y', 12).attr('text-anchor', 'middle').style('font-size', 12).style('font-weight', 'bold')
      textGroup
      .append('text').text('(Joint)').attr('x', 280).attr('y', 24).attr('text-anchor', 'middle').style('font-size', 11).style('fill', '#9E9E9E')


      textGroup
      .append('text').text('Total Test').attr('x', 0).attr('y', 50).attr('text-anchor', 'start').style('font-weight', 'bold')

      textGroup
      .append('line')
        .attr('x1', 0).attr('x2', 320)
        .attr('y1', 55).attr('y2', 55).attr('stroke', '#9E9E9E').attr('stroke-width', 1)

      textGroup
      .append('text').attr('x', 170).attr('y', 50).attr('text-anchor', 'middle').text(values_[0].MT_TEST_LEN >= 0 ? values_[0].MT_TEST_LEN.toLocaleString('en'): '')

      textGroup
      .append('text').attr('x', 280).attr('y', 50).attr('text-anchor', 'middle').text(values_[1].MT_TEST_LEN >= 0 ? values_[1].MT_TEST_LEN.toLocaleString('en'): '')

      textGroup
      .append('text').text('Total Repair').attr('x', 0).attr('y', 110).attr('text-anchor', 'start').style('font-weight', 'bold')

      textGroup
      .append('line')
        .attr('x1', 0).attr('x2', 320)
        .attr('y1', 115).attr('y2', 115).attr('stroke', '#9E9E9E').attr('stroke-width', 1)

      textGroup
      .append('text').attr('x', 170).attr('y', 110).attr('text-anchor', 'middle').text(values_[0].MT_REPAIR_LEN >= 0 ? values_[0].MT_REPAIR_LEN.toLocaleString('en') :'')

      textGroup
      .append('text').attr('x', 280).attr('y', 110).attr('text-anchor', 'middle').text(values_[1].MT_REPAIR_LEN >= 0 ? values_[1].MT_REPAIR_LEN.toLocaleString('en') :'')

      textGroup
      .append('text').text('Rate (%)').attr('x', 0).attr('y', 170).attr('text-anchor', 'start').style('font-weight', 'bold')

      textGroup
      .append('line')
        .attr('x1', 0).attr('x2', 320)
        .attr('y1', 175).attr('y2', 175).attr('stroke', '#9E9E9E').attr('stroke-width', 1)

      textGroup
      .append('text').attr('x', 170).attr('y', 170).attr('text-anchor', 'middle').text(values_[0].MT_REPAIR_RATE >= 0 ? values_[0].MT_REPAIR_RATE.toLocaleString('en') :'')

      textGroup
      .append('text').attr('x', 280).attr('y', 170).attr('text-anchor', 'middle').text(values_[1].MT_REPAIR_RATE >= 0 ? values_[1].MT_REPAIR_RATE.toLocaleString('en') :'')


      // textGroup
      // .append('text').text('Test').attr('x', 0).attr('y', 25).attr('text-anchor', 'start').style('font-size', 12).style('font-weight', 'bold')

      // textGroup
      // .append('rect')
      // .attr('x', 0).attr('y', 35).attr('width', 100).attr('height', 20).attr('fill', '#9E9E9E')

      // textGroup
      // .append('text').text(values_[2].MT_TEST_LEN.toLocaleString('en')).attr('x', 50).attr('y', 50).attr('text-anchor', 'middle').style('font-size', 12).style('fill', '#fff')
      
      // textGroup
      // .append('line')
      //   .attr('x1', 0).attr('x2', 320)
      //   .attr('y1', 55).attr('y2', 55).attr('stroke', '#9E9E9E').attr('stroke-width', 1)

      // textGroup
      // .append('text').text(values_[0].MT_TEST_LEN.toLocaleString('en')).attr('x', 170).attr('y', 50).attr('text-anchor', 'middle').style('font-size', 12)

      // textGroup
      // .append('text').text(values_[1].MT_TEST_LEN.toLocaleString('en')).attr('x', 280).attr('y', 50).attr('text-anchor', 'middle').style('font-size', 12)

      // textGroup
      // .append('text').text('Repair').attr('x', 0).attr('y', 85).attr('text-anchor', 'start').style('font-size', 12).style('font-weight', 'bold')

      // textGroup
      // .append('rect')
      // .attr('x', 0).attr('y', 95).attr('width', 100).attr('height', 20).attr('fill', '#9E9E9E')

      // textGroup
      // .append('text').text(values_[2].MT_REPAIR_LEN.toLocaleString('en')).attr('x', 50).attr('y', 110).attr('text-anchor', 'middle').style('font-size', 12).style('fill', '#fff')

      // textGroup
      // .append('line')
      //   .attr('x1', 0).attr('x2', 320)
      //   .attr('y1', 115).attr('y2', 115).attr('stroke', '#9E9E9E').attr('stroke-width', 1)

      // textGroup
      // .append('text').text(values_[0].MT_REPAIR_LEN.toLocaleString('en')).attr('x', 170).attr('y', 110).attr('text-anchor', 'middle').style('font-size', 12)

      // textGroup
      // .append('text').text(values_[1].MT_REPAIR_LEN.toLocaleString('en')).attr('x', 280).attr('y', 110).attr('text-anchor', 'middle').style('font-size', 12)

      // textGroup
      // .append('text').text('Rate (%)').attr('x', 0).attr('y', 145).attr('text-anchor', 'start').style('font-size', 12).style('font-weight', 'bold')

      // textGroup
      // .append('rect')
      // .attr('x', 0).attr('y', 155).attr('width', 100).attr('height', 20).attr('fill', '#9E9E9E')

      // textGroup
      // .append('text').text(values_[2].MT_REPAIR_RATE.toLocaleString('en')).attr('x', 50).attr('y', 170).attr('text-anchor', 'middle').style('font-size', 12).style('fill', '#fff')

      // textGroup
      // .append('line')
      //   .attr('x1', 0).attr('x2', 320)
      //   .attr('y1', 175).attr('y2', 175).attr('stroke', '#9E9E9E').attr('stroke-width', 1)

      // textGroup
      // .append('text').text(values_[0].MT_REPAIR_RATE.toLocaleString('en')).attr('x', 170).attr('y', 170).attr('text-anchor', 'middle').style('font-size', 12)

      // textGroup
      // .append('text').text(values_[1].MT_REPAIR_RATE.toLocaleString('en')).attr('x', 280).attr('y', 170).attr('text-anchor', 'middle').style('font-size', 12)
    },
    
  }
}