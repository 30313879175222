export default {
  methods: {
    drawProgress(canva, scrName=null, modNo=null, blkNo=null) {
      let coordinates = this.BlockStatus_Coordinate1.filter(c => (
        c.SCRNAME == (scrName || c.SCRNAME) &&
        c.Lv2 == (blkNo || c.Lv2) &&
        c.Lv3 == (modNo || c.Lv3)
      ))
      if(!coordinates) return

      coordinates.forEach(c => {
        let d = this.Queries.SQL1.find((q) => (
          q.Lv1 == c.Lv1 && 
          q.Lv2 == c.Lv2 && 
          q.Lv3 == (c.Lv3 || q.Lv3)
        ))
        if(!d) return

        // console.log(c) //errer를 찾는 방법
        // console.log(d)

        let style ={
          x1: 1090,
          y1: 528
        }

        let box = canva
        .append('g')
        .attr('class', '_progress_text')
        .attr('transform', `translate( 
          ${blkNo !== null || modNo !== null ? style.x1 : c.x},
          ${blkNo !== null || modNo !== null ? style.y1 : c.y}
        )`)
        // Lable 1
        box
        .append('text')
        .attr('transform', 'translate(0, 0)')
        .style('font-family', 'roboto')
        .style('font-size', 10)
        .style('fill', '#333')
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text('Total Welded Length')
        
        // Value 1
        box
        .append('text')
        .attr('transform', 'translate(160, 0)')
        .style('font-family', 'roboto')
        .style('font-size', 10)
        .style('fill', '#ec407a')
        .attr('text-anchor', 'end')
        .attr('alignment-baseline', 'middle')
        .text(d.TOTAL_WELDED_LENGTH || '')
        // .text(d.TEXT1_DATE || '')
  
        // Lable 2
        box
        .append('text')
        .attr('transform', 'translate(0, 14)')
        .style('font-family', 'roboto')
        .style('font-size', 10)
        .style('fill', '#333')
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text('Inspection Length')
        
        // Value 2
        box
        .append('text')
        .attr('transform', 'translate(160, 14)')
        .style('font-family', 'roboto')
        .style('font-size', 10)
        .style('fill', '#ec407a')
        .attr('text-anchor', 'end')
        .attr('alignment-baseline', 'middle')
        .text(d.INSPECTION_LENGTH || '') 

        // Lable 3
        box
        .append('text')
        .attr('transform', 'translate(0, 28)')
        .style('font-family', 'roboto')
        .style('font-size', 10)
        .style('fill', '#333')
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text('Defect Length')
        
        // Value 2
        box
        .append('text')
        .attr('transform', 'translate(160, 28)')
        .style('font-family', 'roboto')
        .style('font-size', 10)
        .style('fill', '#ec407a')
        .attr('text-anchor', 'end')
        .attr('alignment-baseline', 'middle')
        .text(d.DEFECT_LENGTH || '') 

        box
        .append('text')
        .attr('transform', 'translate(145, -38)')
        .style('font-family', 'roboto')
        .style('font-size', 14)
        .style('fill', '#44A9DF')
        .attr('text-anchor', 'end')
        .attr('alignment-baseline', 'middle') 
        .text(d.DEFECT_RATE ? d.DEFECT_RATE*100+'%' : '')
      })
    }
  }
}