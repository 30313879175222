import * as d3 from 'd3'

export default {
  data: () => ({
    leftItems: [],
    rightItems: [],
    bottomItems: [],
    bottomSubItems: []
  }),
  computed: {
    ready2draw() {

      // console.log('------------------------------')
      // console.log('this.LeftItems.length', this.LeftItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)
      
      return (
        Object.keys(this.Canvas).length > 0
      )
    },

  },

  methods: {
    setDefaultValues(){
      this.leftItems = [ ...this.Queries.SQL1 ].map(d => d.value.toString().length  === 1 ? `0${d.value}` : d.value)
      this.rightItems = [ ...this.Queries.SQL2 ].map(d => d.value.toString().length  === 1 ? `0${d.value}` : d.value)
      this.bottomItems = [ ...this.Queries.SQL3 ].reduce((acc, current) => acc.value + '/ ' + current.value)
      this.bottomSubItems = [ ...this.Queries.SQL4 ].reduce((acc, current) => acc.value + '/ ' + current.value)
    },
  }
}