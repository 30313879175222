/**
 * Event Call Functions
 */

import * as d3 from 'd3'

export default {
  methods: {
    callFuncSearchFilter(selection) {
      selection
      .on('mouseover', (_, i, a) => {
        d3.select(a[i]).style('cursor', 'pointer')

        let directionEls_ = d3.select(a[i]).attr('id').split('__')

        d3.select(`#${this.localId}`)
        .select(`.search_circle__${directionEls_[1]}`)
        .style('stroke', this.styleFilter.over.stroke)
        .style('fill', this.styleFilter.over.fill)

        d3.select(`#${this.localId}`)
        .select(`.search_text__${directionEls_[1]}`)
        .style('fill', '#000')
      })
      .on('mouseout', (d, i, a) => { 
        d3.select(a[i]).style('cursor', 'default')

        let index_ = d.filter.values.findIndex(v_ => v_.text == d.text)
        let values_ = JSON.parse(JSON.stringify(d.filter.values))
        let directionEls_ = d3.select(a[i]).attr('id').split('__')

        if(values_[index_].on) {
          d3.select(`#${this.localId}`)
          .select(`.search_circle__${directionEls_[1]}`)
          .style('stroke', this.styleFilter.selected.stroke)
          .style('fill', this.styleFilter.selected.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.search_text__${directionEls_[1]}`)
          .style('fill', this.styleFilter.selected.color)
          
        } else {
          d3.select(`#${this.localId}`)
          .select(`.search_circle__${directionEls_[1]}`)
          .style('stroke', this.styleFilter.out.stroke)
          .style('fill', this.styleFilter.out.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.search_text__${directionEls_[1]}`)
          .style('fill', this.styleFilter.out.color)
        }
      })
      .on('click', (d, i, a) => {
        let index_ = d.filter.values.findIndex(v_ => v_.text == d.text)
        let values_ = JSON.parse(JSON.stringify(d.filter.values))
        let length_ = values_.length

        var prevIndex_ = d.filter.values.findIndex(v_ => v_.on)
        for(let i = 0; i<length_; i++) {
          if(values_[i].text == d.text) values_[i].on = true
          else values_[i].on = false
        } 

        d.filter.values = values_

        if(prevIndex_ != index_) {
          d3.select(`#${this.localId}`)
          .select(`.search_circle__${prevIndex_}`)
          .style('stroke', this.styleFilter.out.stroke)
          .style('fill', this.styleFilter.out.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.search_text__${prevIndex_}`)
          .style('fill', this.styleFilter.out.color)
        }

        if(values_[index_].on) {
          d3.select(`#${this.localId}`)
          .select(`.search_circle__${index_}`)
          .style('stroke', this.styleFilter.selected.stroke)
          .style('fill', this.styleFilter.selected.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.search_text__${index_}`)
          .style('fill', this.styleFilter.selected.color)
          
        } else {
          d3.select(`#${this.localId}`)
          .select(`.search_circle__${index_}`)
          .style('stroke', this.styleFilter.out.stroke)
          .style('fill', this.styleFilter.out.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.search_text__${index_}`)
          .style('fill', this.styleFilter.out.color)
        }

        d3.select(`#${this.localId}`).select(`#area__${d.colName}`).moveToFront()
      })
    },
  }
}