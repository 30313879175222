import * as d3 from 'd3'
import Data from '../primitives/donutProps'

export default {
  data: () => ({
    refColumn: 'STATUS',
    lastPutCol: ''
  }),
  watch: {
    scrollLeft(val){
      d3.select(`._donut_group__for_all`)
      .transition().duration(300).attr('transform', `translate(${val + 70},135)`)

      
      d3.select(`#skyline_boxes_legends`)
      .transition().duration(300).attr('transform', `translate(${val + this.skyline.legend.x},${this.skyline.legend.y})`)

      d3.select(`._global_elements__text__text_`).transition().duration(300).attr('transform', `translate(${val},0)`)

      d3.select(`._global_elements__text__sql_`).select('text').transition().duration(300).attr('transform', `translate(${val + 48},192)`)

    }
  },
  methods: {
    drawDonuts() {

      let arcProps = {
        angleSize: 360,
        angleStart: 0,
        bColor: '#F4F5F5',
        stroke: 8,
        Ra: 30
      }
      let values_ = this.Queries.SQL3[0]

      
      let donutGroup = this.SkylineCharts
      .append('g')
      .attr('class', '_donut_group__for_all')
      .attr('transform', `translate(70, 135)`)


      Data.attrs.forEach((attr, i) => {
        let arc = d3.arc()
        .outerRadius(d => d.Ra)
        .innerRadius(d => d.Ra - d.stroke)
        .startAngle(d => d.angleStart * Math.PI / 180)
        .endAngle(d => d.angleEnd || d.angleStart * Math.PI / 180)


        let x__ = attr.refColumn.includes('PARTIALLY') ? attr.refColumn.includes('COMPLETED') ? 610 : 710 : Data.width * i + (i > 0 ? Data.gab : 0)
        let y__ = attr.refColumn.includes('PARTIALLY') ? 115 : 0
        let donut_ = donutGroup 
        .append('g')
        .attr('class', `_donut_chart__${attr.refColumn.toLowerCase()}`)
        .attr('transform', `translate(${x__}, ${y__})`)

        // Draw Background Donut Chart
        donut_
        .append('circle')
        .attr('cx', 0).attr('cy', 0).attr('r', arcProps.Ra-3)
        .attr('stroke', arcProps.bColor).attr('stroke-width', arcProps.stroke)
        .attr('fill', `url(#${attr.fill})`)

        let circleRate = values_[attr.refColumn] / values_['TOTAL']

        // Draw Foreground Donut Chart
        donut_
        .datum(arcProps)
        .append('path')
        .attr('d', arc)
        .attr('fill', attr.fColor)
        .attr('opacity', 0.8)
        .transition()
        .duration(1500)
        .attrTween('d',(d) => {
          let endAngle_ = ((d.angleSize * circleRate) + d.angleStart) * Math.PI / 180
          let interpolate = d3.interpolate(d.angleStart * Math.PI / 180, endAngle_)
          return function(t) {
            d.angleEnd = interpolate(t)
            return arc(d);
          }
        })
        

        donut_
        .append('text')
        .attr('transform', `translate(0, 1)`)
        .style('font-family', 'roboto')
        .style('font-size', 13)
        .style('fill', attr.tColor)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(values_[attr.refColumn] ? values_[attr.refColumn] : 0)

        // Legend
        donut_
        .append('text')
        .attr('transform', `translate(0, 42)`)
        .attr('class', 'donut_text')
        .style('font-family', 'roboto')
        .style('font-size', 10)
        .style('fill', '#757575')
        .attr('text-anchor', 'middle')
        .style('font-weight', 'normal')
        .attr('alignment-baseline', 'middle')
        .text(attr.text)

        donut_
        .append('text')
        .attr('transform', `translate(0, 52)`)
        .style('font-family', 'roboto')
        .style('font-size', 10)
        .style('fill', '#757575')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(attr.text2)
        
        donut_.on('click', (d, i, a) => {

          if (attr.codeValue == 'Total') {
            this.filtering[this.refColumn] = ['Total']
              
          }else {
            
            let totalIdx = this.filtering[this.refColumn].indexOf('Total')
            if (totalIdx > -1) {
              this.filtering[this.refColumn].splice(totalIdx, 1)
            }
              
            let valueIdx = this.filtering[this.refColumn].indexOf(attr.codeValue)
            if (valueIdx > -1) {
              this.filtering[this.refColumn].splice(totalIdx, 1)
            }else {
              this.filtering[this.refColumn].push(attr.codeValue)
            }
          }
          
          Data.attrs.forEach(element => {
            if (this.filtering[this.refColumn].includes(element.codeValue) || attr.codeValue == 'Total'){
              element.on = true
              d3.select(`._donut_chart__${element.refColumn.toLowerCase()}`).attr('opacity', '1')
            }else {
              element.on = false
              d3.select(`._donut_chart__${element.refColumn.toLowerCase()}`).attr('opacity', '0.6')
            }
          })


          this.Chart_filtering()
        })
        .on('mouseover', (_, i, a) => { d3.select(a[i]).style('cursor', 'pointer') })

        if(attr.on) this.setFilter('multi', this.refColumn, attr.codeValue)
      })
    },
  }
}