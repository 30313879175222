<template>
  <div :id="localId"></div>
</template>

<script>
import mx_Core from './includes/mixins/importer'

export default {
  name: "j-svg-page",
  mixins: [mx_Core],
  props: {
    id: String,
    
    // ### Necessaries for the SVG-Page ###
    Action: {
      type: Object,
      default: () => ({})
    },
    FilterValues: {
      type: Object,
      default: () => ({})
    },
    // ### ---------------------------- ###

    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },
    
    DataItems: { type: Array, default: () => ([]) },

    ColumnProps: { type: Array, default: () => ([]) },

    values: {
      type: Object,
      default: () => ({
      })
    }
  },
  created() {
    this.localId = this.id || 'j-svg-page__' + this.safeId('')
  },
  mounted() {
    this.register(this.draw)
  },
  methods: {
    draw() {
      // Check all the necessary values are available
      if (!this.ready2draw) return

      this.clear()
      this.setDefaultValues()
      this.drawCanvas()
      this.drawChart()
      this.draw_Timeline2020()
      this.drawTexts()

      // 'complete' must be executed to finish the parent process.
      // It sends 'this.localId' & XmlString for some case to make 
      // displaying image easy.
      this.complete()
      this.postComplete()
    },
    drawChart() {
      this.milestoneCharts = this.svg
      .append('g')
      .attr('transform', `translate(0,0)`)
    },
    postComplete() {
      let chart_ = this.milestoneCharts.node().getBoundingClientRect()
      // this.resizableCanvasWidth = chart_.width + 80
      this.resizableCanvasHeight = chart_.height + 150
      this.resizeCanvas()

      let dimention = { 
        width: this.Canvas.CanvasWidth, 
        height: Math.ceil(chart_.height) + 100, 
        isreal: true, 
      }
      
      this.$emit('resize', dimention)
    }
  }
}
</script>