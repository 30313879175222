import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({

    props:[
      {key:'SIZE' , head1: 'Area'  , x: 38,    tx: 28, textanchor: 'start'},
      {key:'TOTAL'       , head1: '# of CWAs'   , x: 180,   tx: 180, textanchor: 'middle'},
      {key:'DEL'       , head1: 'Pro. (%)'   , x: 260,   tx: 260, textanchor: 'middle'},
      {key:'INST'      , head1: 'In Pro. (See note *)'  , x: 360,   tx: 360},
      // {key:'TARGET'     , head1: 'Target' , x: 300,   tx: 314,},
      // {key:'PLAN'       , head1: 'Plan'   , x: 350,y:-6,  tx: 364,},
      // {key:'ACTUAL'     , head1: 'Actual' , x: 400,y:-6,  tx: 414, color: '#1976D2'},
      // {key:'ACTUAL_TW'  , head1: 'Actual' , x: 450,y:-6,  tx: 465, color: '#1976D2'},
      // {key:'DELTA'      , head1: 'Plan'   , x: 505,y:-6,  tx: 515, color: '#1976D2'},
      // {key:'REMAIN'     , head1: 'Total'  , x: 550,y:-6,  tx: 565, color: 'red'},
    ],
    width: 450,
    rowHeight: 18,
    disc: null
  }),

  methods: {
    drawChart() {
      let PreOutfitting = this.svg
      .append('g')
      .attr('transform', `translate(0, 30.5)`)

      PreOutfitting
      .append('rect')
      .attr('x',20).attr('y', 5).attr('width',this.width - 20).attr('height',25).attr('stroke-width', 0).attr('fill', '#F4F5F5').attr('opacity', 0.4)

      PreOutfitting.append('line').attr('x1',20).attr('y1',3).attr('x2',this.width).attr('y2',3).attr('stroke-width', 0.2).attr('stroke', '#000')
      PreOutfitting.append('line').attr('x1',20).attr('y1',30).attr('x2',this.width).attr('y2',30).attr('stroke-width', 0.2).attr('stroke', '#000')

      // header
      PreOutfitting
      .append('g')
      .attr('transform', `translate(0, 20)`)
      .selectAll('text')
      .data(this.props)
      .enter()
      .append('text')
      .attr('x', d => d.x)
      .attr('y', d => d.y ? d.y :0)
      .style('font-family','roboto')
      .style('font-size',10)
      .attr('text-anchor', 'middle')
      .attr('fill', '#333')
      .text(d => d.head1)

      let row_group = PreOutfitting
      .append('g')
      .attr('class', '_row_data_group')
      .attr('transform', `translate(0, 43)`)

      let discs = [...new Set(this.dataSet.map(d => d.SIZE))]
      // console.log(discs)
      let sumY = 0
      discs.forEach((disc, r) => {
        const beforeDisc = r > 0 ? discs[r-1] : null
        const filtered = this.dataSet.filter(d => d.SIZE === beforeDisc)

        sumY += r !== 0 ? this.rowHeight * filtered.length : 0

        row_group.append('text')
        .text(disc)
        .attr('x', (d,i) => this.props[i].tx)
        .attr('y', () => sumY)
        .style('font-family','roboto')
        .style('font-size',10)
        .attr('fill', '#757575')

        const group = this.dataSet.filter(d => disc.includes(d.SIZE))

        group.forEach((row, i) => {
          let keys = Object.keys(row)
          keys.shift()

            keys.forEach(key => {
              let idx = this.props.findIndex(e => e.key === key)
              if(idx == -1) return
              row_group
                .append('text')
                .text(row[key] != null ? row[key].toLocaleString() : '')
                .attr('x', (d,i) => this.props[idx].tx)
                .attr('y', () => sumY + (i * this.rowHeight))
                .attr('text-anchor', (d, i) =>  this.props[idx].textanchor ? this.props[idx].textanchor : 'end' )
                .attr('fill', (d, i) => {
                  if (key !== 'DELTA') {
                    return this.props[idx].color && row[key] !== 0 ? this.props[idx].color : '#757575'
                  }else {
                    return row[key] > 0 ? 'green' : row[key] < 0 ? 'red' : '#757575'
                  }
                })
                .style('font-weight', () => row.SIZE === 'Total' ? 'bold' : 'normal')
                .style('font-family','roboto')
                .style('font-size',10)
            })
        })
      })

        // table body line
        let sumLineY1 = 0
        let sumLineY2 = 0
        PreOutfitting
          .append('g')
          .attr('transform', `translate(20, 30)`)
          .selectAll('line')
          .data(discs)
          .enter()
          .append('line')
          .attr('class', '_body_line')
          .attr('x1', 0)
          .attr('y1', (d,i) => {
            const filter = this.dataSet.filter(s => s.SIZE === discs[i])
            sumLineY1 += (18 * filter.length)
            return sumLineY1
          })
          .attr('x2', this.width -20)
          .attr('y2', (d,i) => {
            const filter = this.dataSet.filter(s => s.SIZE === discs[i])
            sumLineY2 += (18 * filter.length)
            return sumLineY2
          })
          .attr('stroke-width', 0.3)
          .attr('stroke', '#ccc')
    }

  }
}
