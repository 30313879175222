import * as d3 from 'd3'

export default {
  data: () => ({
    dataSet: [],
    innerSvg: null,
    keyPlanSvg: null,
    Module: '41M001',
    monthTimeline: [], // year: 2018, month: 'JAN' ,days: 31

    timelineLength: 15,
    timelineWidth: 750,
    timeScale: null,
    timelineTickValues: null
  }),
  computed: {

    ready2draw() {

      // console.log('------------------------------')
      // console.log('this.ChartType', this.ChartType)
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)
      // console.log('this.Status.length', this.Status.length)
      // console.log('this.Border.length', this.Border.length)

      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },

  },



  methods: {
    // 1. PlanStart과 actualStart 에서 최소값구하기
    // 2. 1에서 구한 최소값에서 -2개월의 1일 날짜 구하기
    // 3. 2의 값부터 15개월 출력하기(JAN)

    setDefaultValues(){
      this.timeScale = null
      this.timelineValues = {
        year: [],
        month: [],
      }

      this.dataSet = JSON.parse(JSON.stringify(this.DataItems)).filter(d => d.planStart)

      this.dataSet.forEach(data => {
        if (data.actualStart != null && data.actualFinish != null) data.status = 'Completion'
        if (data.actualStart != null && data.actualFinish == null) {
          data.status = 'Progress'
          data.actualFinish = data.CUTOFF
        }
        if (data.actualStart == null && data.actualFinish == null) data.status = 'NotStart'

        data.planStart = this.parseDate(data.planStart)
        data.planFinish = this.parseDate(data.planFinish)
        data.actualStart = this.parseDate(data.actualStart)
        data.actualFinish = this.parseDate(data.actualFinish)
      })


      // let psMin = new Date(Math.min(...this.dataSet.map(data => data.planStart)))
      // let asMin = new Date(Math.min(...this.dataSet.map(data => data.actualStart).filter(data => !!data)))

      // ### Set Timeline-Scale ### -----------------------------------------
      // Calculate the total range of the dates
      let realMin = new Date(Math.min(
        Math.min(...this.dataSet.map(data => data.planStart)),
        Math.min(...this.dataSet.map(data => data.actualStart).filter(data => !!data))
      ))

      let realMax = new Date(Math.max(
        Math.max(...this.dataSet.map(data => data.planFinish)),
        Math.max(...this.dataSet.map(data => data.actualFinish).filter(data => !!data))
      ))

      function monthDiff(d1, d2) {
        var months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();
        return months <= 0 ? 0 : months;
      }
      
      this.timelineLength = monthDiff(realMin, realMax) < 15 ? 15 : monthDiff(realMin, realMax) + 3

      let startMonth_ = new Date(realMin.setMonth(realMin.getMonth() - 1))
      let startDate_ = new Date(startMonth_.getFullYear(), startMonth_.getMonth(), 1)
      let endDate_ = new Date(new Date(startDate_).setMonth(startDate_.getMonth() + this.timelineLength + 1) - 1)
      
      // get min & max values of the timeline-scale
      this.timeScale = d3.scaleTime()
          .domain([startDate_, endDate_])
          // .range([0, this.Canvas.CanvasWidth])
          .range([0, this.timelineWidth])
      // ### Set Timeline-Scale ### -----------------------------------------

      
      // ### Set Timeline-Values for the Year ### ---------------------------
      let prevYearDate_ = startDate_

      for(var i=0; i<this.timelineLength; i++) {
        let _nextMonthDate__ = new Date(new Date(startDate_).setMonth(startDate_.getMonth() + i))

        // For the Year ---
        if(d3.timeFormat('%Y')(prevYearDate_) != d3.timeFormat('%Y')(_nextMonthDate__)) {
          let midDateYear_ = new Date(
            d3.mean([
              prevYearDate_,                                                                    // 1st date of the year
              new Date(new Date(_nextMonthDate__).setMonth(_nextMonthDate__.getMonth() + 1) - 1)  // last date of the year
            ])
          )
          this.timelineValues.year.push({ name: d3.timeFormat('%Y')(midDateYear_), value: new Date(midDateYear_) })
          prevYearDate_ = _nextMonthDate__
        }
      }

      // Year remained
      let midDateYear_ = new Date(
        d3.mean([
          prevYearDate_,  // 1st date of the year
          endDate_        // last date of the year
        ])
      )
      this.timelineValues.year.push({ name: d3.timeFormat('%Y')(midDateYear_), value: new Date(midDateYear_) })
      // ### Set Timeline-Values for the Year ### ---------------------------


      // ### Set Timeline-Values for the Month ### --------------------------
      for(i=0; i<=this.timelineLength; i++) {
        let _nextMonthDate__ = new Date(new Date(startDate_).setMonth(startDate_.getMonth() + i))

        // For the Month ---
        let endMonth_ = new Date(new Date(_nextMonthDate__).setMonth(_nextMonthDate__.getMonth() + 1) - 1)
        let midDate = new Date(
          d3.mean([
            _nextMonthDate__, // 1st date of the month
            endMonth_         // last date of the month
          ])
        )

        this.timelineValues.month.push({ 
          name: d3.timeFormat('%b')(midDate), 
          value: new Date(midDate),
          endDate: endMonth_
        })
      }

      console.log(this.timelineValues)

      

      // ### Set Timeline-Values for the Month ### --------------------------
    },
  }
}