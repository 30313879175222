import * as d3 from 'd3'

export default {
  methods: {
    
    setEvent_Goto() {

      // Stage_Overall -> Stage_Area
      // ----------------------------------
      this.Event_Overall = (selection) => {

        selection
        .on('mouseover', () => {
          if(this.Stage != 'OVERALL') return
        })
        .on('mouseout', () => {
          if(this.Stage != 'OVERALL') return
        })
        .on('click', () => {
          if(this.Stage != 'OVERALL') return

          this.Stage = 'transforming'
          d3.select(`#MD2_FPU`).transition().duration(750).attr('transform', `translate(0,0) scale(1)`)

          setTimeout(() => {
            d3.select(`#MD2_Topside`).transition().duration(1000).attr('transform', `translate(157,142) scale(0.4)`)
            d3.select(`#MD2_Deckbox`).transition().duration(1000).attr('transform', `translate(118,431) scale(0.4)`)
            d3.select(`#MD2_Hull`).transition().duration(1000).attr('transform', `translate(128,644) scale(0.4)`)
          }, 750);

          setTimeout(() => {
            this.Stage = 'AREA'
            this.Charts_AreaSummary()
          }, 1750);
        })
      }


      // Stage_Area Summary
      // --------------------------------------
      this.Event_Area = (selection, _area) => {
        selection
        .on('mouseover', () => {
          if(this.Stage != 'AREA') return
        })
        .on('mouseout', () => {
          if(this.Stage != 'AREA') return
        })
        .on('click', () => {
          if(this.Stage != 'AREA') return

          this.Stage = 'transforming'
          this.removeSvg(this.SVG_AreaSummary) // remove SVG

          if (_area == 'Hull') this.Activate_Hull()
          if (_area == 'Deckbox') this.Activate_Deckbox()
          if (_area == 'Topside') this.Activate_Topside()
          
          this.SVG_Overall
          .transition().duration(500).style('opacity', 0)

          setTimeout(() => {
            d3.select(`#keyPlan`).transition().duration(500)
            .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/Demo/Equipment_Layout/MD2/keyplan_${_area}.svg`)
            .style('opacity', 1)
            this.removeSvg(this.SVG_Overall) // remove SVG
          }, 500);
          
          setTimeout(() => {
            this.Area = _area
            this.Charts_Area()
            this.Stage = 'MODULE'
          }, 1000);

        })
      }


      // Event_Module
      // ---------------------------------
      this.Event_Module = (selection) => {
        selection
        .on('mouseover', () => {
          if(this.Stage != 'MODULE') return
        })
        .on('mouseout', () => {
          if(this.Stage != 'MODULE') return
        })
        .on('click', () => {
          if(this.Stage != 'MODULE') return

          this.Stage = 'transforming'
          this.removeSvg(this.SVG_Area) // remove SVG
          d3.select(`#${this.Area}_Group`).style('opacity', 1)
         
          // move to center
          d3.select(`#${this.Area}_Group`)
          .transition()
          .duration(1000)
          .attr('transform', `translate(0,0) scale(1)`)

          // Explode
          setTimeout(() => {
            if(this.Area == 'Hull') {
              this.hiddenGroupID(`#MISC`, 0, 300)
            }
            this.MODULES[this.Area].forEach(mod => {
              d3.select(`#${mod.module}`)
              .transition().duration(1000)
              .attr('transform', ()=> {
                if (this.Area == 'Hull') return `translate(${mod.x2}, ${mod.y2}) scale(${0.25})`; else return `translate(${mod.x2}, ${mod.y2})`
              })
            })
          }, 1000);

          setTimeout(() => {
            this.Stage = 'DECK'
            if (this.Area == 'Hull') this.Stage = 'EQUIPMENT'
            this.Charts_ModuleDivision() // --> Chart
          }, 2000);
          
        })
      }
      

      // Event_Deck
      // -------------------------------------
      this.Event_Deck = (selection, _mod) => {
        selection
        .on('mouseover', () => {
          if(this.Stage != 'DECK') return
        })
        .on('mouseout', () => {
          if(this.Stage != 'DECK') return
        })
        .on('click', () => {
          if(this.Stage != 'DECK') return
          this.Stage = 'transforming'

          this.removeSvg(this.SVG_Module) // remove SVG

          this.MODULES[this.Area].forEach(d => {
            if(d.module !== _mod) {
              this.hiddenGroupID(`#${d.module}`, 0, 200)
              this.hiddenGroupID(`#Key_${d.module}`, 0, 0)
            } else {
              this.displayGroupID(`#Key_${d.module}`, 0, 300, 0.5) // Key_Plan
              this.displayGroupID(`#Legends`, 0, 300, 1) // Legends
            }
          })
          
          let m = this.MODULES[this.Area].find(f=> f.module == _mod)
          d3.select(`#${_mod}`)
          .style('opacity', 1)
          .transition()
          .duration(1000)
          .attr('transform', `translate(${m.x3}, ${m.y3})`)

          setTimeout(() => {
            this.DECKS[this.Area].filter(f=> f.module == _mod).forEach(d => {
              d3.select(`#${d.deck}`)
              .transition()
              .duration(1000)
              .attr('transform', `translate(${d.x2}, ${d.y2-250}) scale(0.25)`)
            })
          }, 1000);

          setTimeout(() => {
            this.Module = _mod
            this.Charts_ModuleDeck(_mod) // --> Chart
            this.Stage = 'EQUIPMENT'
          }, 2000);
          
        })
      }


      // Event_Equipment
      // -------------------------------------------------
      this.Event_Equipment = (selection, _mod, _deck) => {
        selection
        .on('mouseover', () => {
          if(this.Stage != 'EQUIPMENT') return
        })
        .on('mouseout', () => {
          if(this.Stage != 'EQUIPMENT') return
        })
        .on('click', () => {
          if(this.Stage != 'EQUIPMENT') return

          this.Stage = 'transforming'
          d3.select(`#${_deck}`).style('opacity', 1)

          this.removeSvg(this.SVG_ModuleDeck) // remove SVG

          this.DECKS[this.Area].filter(f=> f.module == _mod).forEach(d => {
            if(d.deck !== _deck) {
              this.hiddenGroupID(`#${d.deck}`, 0, 200)
            }
          })

          let dk = this.DECKS[this.Area].find(f=> f.deck == _deck)

          setTimeout(() => {
            d3.select(`#${_mod}`)
            .transition()
            .duration(1000)
            .attr('transform', `translate(0, 0)`)

            d3.select(`#${_deck}`)
            .transition()
            .duration(1000)
            .attr('transform', `translate(300, 100) scale(${dk.scale})`)
          }, 200);

          setTimeout(() => {
            this.loadSvgFile(`${this.__HOST_NAME_RESOURCE}/Demo/Equipment_Layout/MD2/svg/${_deck}.svg`,300 , 100, dk.scale).then(() => {
              this.draw_EqNumber(_deck, 300, 100, dk.scale)
            })
            this.Stage = 'SVG'
            this.Deck = _deck
            this.draw_DataTable(_deck)
            console.log(this.Stage)
          }, 1200);
          
        })
      }


      // Event_Equipment for Hull only
      // -------------------------------------------------
      this.Event_EquipmentHull = (selection, _mod) => {
        
        selection
        .on('mouseover', () => {
          if(this.Stage != 'EQUIPMENT') return
          // console.log('mouseover', _mod)
        })
        .on('mouseout', () => {
          if(this.Stage != 'EQUIPMENT', _mod) return
          // console.log('mouseout')
        })
        .on('click', () => {
          // console.log('click', _mod)
          if(this.Stage != 'EQUIPMENT') return

          
          
          // this.Stage = 'transforming'
          // d3.select(`#${_mod}`).style('opacity', 1)

          this.removeSvg(this.SVG_Module) // remove SVG

          this.MODULES[this.Area].forEach(d => {
            if(d.module !== _mod) {
              this.hiddenGroupID(`#${d.module}`, 0, 200)
            }
          })

          setTimeout(() => {
            d3.select(`#${_mod}`)
            .transition()
            .duration(1000)
            .attr('transform', `translate(300, 100) scale(1)`)
          }, 200);

          setTimeout(() => {
            let svg = this.MODULES[this.Area].find(f => f.module ==_mod)
            this.loadSvgFile(`${this.__HOST_NAME_RESOURCE}/Demo/Equipment_Layout/MD2/svg/${_mod}.svg`,(300 + svg.sx) , (100 + svg.sy), 1).then(() => {
              this.draw_EqNumber(_mod, 300, 100, 1)
            })
            this.draw_DataTable(_mod)
          }, 1200);
          
        })
      }


    },
    
  }
}