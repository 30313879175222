import Defs from '../../../../../../includes/primitives/Color_Defs'
import __M from 'moment'

import * as d3 from 'd3'

export default {
  data: () => ({
    dataSet: [],
    innerSvg: null,

    chart: null,


    timelineWidth: 1400, // Timeline Total length
    timeScale: null,
    timelineTickValues: null
  }),
  computed: {

    ready2draw() {

      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },

  },



  methods: {
    setDefaultValues(){

      this.timeScale = null
      this.timelineValues = {
        year: [],
        month: []
      }

      this.dataSet = JSON.parse(JSON.stringify(this.DataItems))

      let allDates = [...new Set([...this.dataSet.map(d => d.RAS_ROS), ...this.dataSet.map(d => d.PLAN), ...this.dataSet.map(d => d.ACTUAL), ...this.dataSet.map(d => d.FORECAST)].filter(d => d))]

      let startDate = allDates.length > 0 ? new Date(__M(Math.min.apply(null,allDates.map(d => new Date(d)))).startOf('month')) : new Date(__M().add('-10', 'M').startOf('month'))
      let endDate = allDates.length > 0 ? new Date(__M(Math.max.apply(null,allDates.map(d => new Date(d)))).endOf('month')) : new Date(__M().add('10', 'M').endOf('month'))


      // console.log(__M(startDate).format('YYYY-MM-DD'), __M(endDate).format('YYYY-MM-DD'))
      
      let noOfMonth = endDate.getMonth() - startDate.getMonth()
      let noOfYear = endDate.getYear() - startDate.getYear()
      noOfMonth = noOfMonth + (noOfYear*12)

      // get min & max values of the timeline-scale
      this.timeScale = d3.scaleTime()
      .domain([new Date(__M(startDate).startOf('month')), new Date(__M(endDate).endOf('month'))])
      .range([0, this.timelineWidth])
      
      // ### Set Timeline-Values for the Year ### ---------------------------
      let prevYearDate_ = startDate

      for(var i=0; i<noOfMonth; i++) {
        let _nextMonthDate__ = new Date(new Date(startDate).setMonth(startDate.getMonth() + i))

        // For the Year ---
        if(d3.timeFormat('%Y')(prevYearDate_) != d3.timeFormat('%Y')(_nextMonthDate__)) {
          let midDateYear_ = new Date(
            d3.mean([
              prevYearDate_,                                                                    // 1st date of the year
              new Date(new Date(_nextMonthDate__).setMonth(_nextMonthDate__.getMonth() + 1) - 1)  // last date of the year
            ])
          )
          this.timelineValues.year.push({ name: d3.timeFormat('%Y')(midDateYear_), value: new Date(midDateYear_) })
          prevYearDate_ = _nextMonthDate__
        }
      }

      // Year remained
      let midDateYear_ = new Date(
        d3.mean([
          prevYearDate_,  // 1st date of the year
          endDate        // last date of the year
        ])
      )
      this.timelineValues.year.push({ name: d3.timeFormat('%Y')(midDateYear_), value: new Date(midDateYear_) })
      // ### Set Timeline-Values for the Year ### ---------------------------


      // ### Set Timeline-Values for the Month ### --------------------------
      for(i=0; i<=noOfMonth; i++) {
        let _nextMonthDate__ = new Date(new Date(startDate).setMonth(startDate.getMonth() + i))

        // For the Month ---
        let endMonth_ = new Date(new Date(_nextMonthDate__).setMonth(_nextMonthDate__.getMonth() + 1) - 1)
        let midDate = new Date(
          d3.mean([
            _nextMonthDate__, // 1st date of the month
            endMonth_         // last date of the month
          ])
        )
        this.timelineValues.month.push({ 
          name: d3.timeFormat('%b')(midDate), 
          value: new Date(midDate),
          endDate: endMonth_
        })
      }
      // ### Set Timeline-Values for the Month ### --------------------------

      let lightBlue = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `shadowTimeline`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      lightBlue.append('stop').attr('stop-color', '#3d3d3d').attr('offset', '0').attr('stop-opacity', 0.5)
      lightBlue.append('stop').attr('stop-color', '#fff').attr('offset', '1').attr('stop-opacity', 0.3)
      
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['LightGray'], [`LightGray`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['YellowGreen'], [`YellowGreen`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['LightBlue'], [`LightBlue`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['Pink'], [`Pink`, 0.5, 1.0, 0.5, -0.2], this.localId)


      

      this.svg
      .append('clipPath')
      .attr('id', 'mask_barchart')
      .append('rect')
      .attr('transform', `translate(0, 0)`)
      .attr('width', this.timelineWidth)
      .attr('height', (this.DataItems.length+1) * 23 + 200 )
      .attr('user-select', 'none')

    },

    
  }
}