import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {

    
    Draw_Charts() {
      
      let data = null, 
          style = null, 
          cellX = null, 
          tmp = null, 
          Circle = null, 
          Guideline = null,
          item = null

      let overallPunchStatus = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0)`)



      // Header_01
      data  = this.dataSet.find(f=> f.TYPE == 'A' && f.DISC == 'Overall').PROG
      style = {
        title: 'Punch A',
        x: 10,
        y: 30,
        width: 60,
        height: 18,
        radius: 3,
        tSize: 11,
        tColor: '#333',
        bColor: '#E0E0DF',
        progSize: 15,
      }
      this.Header_01( overallPunchStatus, data, style) // ---------------------------> 


      // SummaryTable_Punch
      item = this.dataSet.find(f=> f.TYPE == 'A' && f.DISC == 'Overall')
      style = {
        id:       'PA',
        title:    ['Total', 'Closed', 'Open'],
        cell:     [45, 95, 145],
        x:        10,
        y:        50,
        y1:       10,
        y2:       23,
        width:    155,
        height:   32,
        radius:   3,
        opacity:  0.7,
        bColor:   '#83D2F5',
        tSize:    9,
        tColor:   '#757575',
        vSize:    11,
        vColor:   '#fff',
        value:    [item.TOTAL, item.ACTUAL, item.REMAIN]
      }
      data = {
        type: 'A'
      }
      this.SummaryTable_Punch(overallPunchStatus, style, data) // ---------------------------> 


      // Header_01
      data  = this.dataSet.find(f=> f.TYPE == 'B1' && f.DISC == 'Overall').PROG
      style = {
        title: 'Punch B1',
        x: 200,
        y: 30,
        width: 60,
        height: 18,
        radius: 3,
        tSize: 11,
        tColor: '#333',
        bColor: '#E0E0DF',
        progSize: 15,
      }
      this.Header_01( overallPunchStatus, data, style) // ---------------------------> 


      // SummaryTable_Punch
      item = this.dataSet.find(f=> f.TYPE == 'B1' && f.DISC == 'Overall')
      style = {
        id:       'PB',
        title:    ['Total', 'Closed', 'Open'],
        cell:     [45, 95, 145],
        x:        200,
        y:        50,
        y1:       10,
        y2:       23,
        width:    155,
        height:   32,
        radius:   3,
        opacity:  0.7,
        bColor:   '#83D2F5',
        tSize:    9,
        tColor:   '#757575',
        vSize:    11,
        vColor:   '#fff',
        value:    [item.TOTAL, item.ACTUAL, item.REMAIN]
      }
      data = {
        type: 'B1'
      }
      this.SummaryTable_Punch(overallPunchStatus, style, data) // ---------------------------> 

      // Bar_Punch_01
      data = this.dataSet.filter(f=> f.TYPE == 'A' && f.DISC !== 'Overall')
      style = {
        id:       'PA',
        x:        -15,
        y:        90,
        cell:     [30, 65, 100, 105, 0, 0],
        bColor:   '#F7BACF', 
        sColor:   '#EC407A', 
        tColor1:  '#fff', 
        tColor2:  '#757575', 
        opacity:  1,
        length:   75,
        lineHeight: 16,
        min: 25,
      }
      this.Bar_Punch_01(overallPunchStatus, data, style) // ---------------------------> 


      // Bar_Punch_01
      data = this.dataSet.filter(f=> f.TYPE == 'B1' && f.DISC !== 'Overall')
      style = {
        id:       'PB',
        x:        185,
        y:        90,
        cell:     [30, 65, 100, 105, 0, 0],
        bColor:   '#FCE3EB', 
        sColor:   '#F7BACF', 
        tColor1:  '#fff', 
        tColor2:  '#757575', 
        opacity:  1,
        length:   75,
        lineHeight: 16,
        min: 25,
      }
      this.Bar_Punch_01(overallPunchStatus, data, style) // ---------------------------> 




      // // Bar_Punch_02
      // data = this.dataSet.filter(f=> f.DISC == 'Overall')
      // style = {
      //           x: 10,
      //           y: 80,
      //           stX: 50,
      //           barLen: 215,
      //           width: 20,
      //           space:5,
      //           pColor:   ['#F4F5F5', '#BDBCBC'],
      //           bColor:   ['#F7BACF', '#FCE3EB'],
      //           sColor:   ['#EC407A', '#F7BACF'],
      //           tColor:   ['#fff','#757575'], 
      //           opacity:  1,
      // }
      // this.Bar_Punch_02(overallPunchStatus, data, style) // ---------------------------> 
      
    },
  }
}
           