import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({
    blockStatus: [
      { code: 'FABSTART',     title: 'Fab. Commenced',       bColor: '#44A9DF', tColor: '#44A9DF', opacity: 0.20, code_bColor: '#84C7E5', code_tColor: '#333',},
      { code: 'FABRICATION',  title: 'Fab. Completed',           bColor: '#44A9DF', tColor: '#FFFFFF', opacity: 0.40, code_bColor: '#2281C5', code_tColor: '#fff',},
      { code: 'PAINTING',     title: 'Painting Completed',       bColor: '#44A9DF', tColor: '#44A9DF', opacity: 0.60, code_bColor: '#F6D15E', code_tColor: '#333',},
      { code: 'ASSEMBLY',     title: 'Assembly Completed', bColor: '#44A9DF', tColor: '#333',    opacity: 0.80, code_bColor: '#CC93C1', code_tColor: '#333',},
      { code: 'ERECTION',     title: 'Erec. Commenced',  bColor: '#44A9DF', tColor: '#333',    opacity: 1.00, code_bColor: '#8DBA2D', code_tColor: '#fff',},
    ],
  }),

  methods: {
    drawChart() {
      let blocks_ = this.DataItems
      let svg = this.svg
      let g = svg.append('g').attr('class', 'block_bar_status__').attr('transform', `translate(0.5, 0.5)`)
      
      let blockItemSet = []
      let blockStyle = {
                x: 10,
                y: 10,
                headTitle: '',
                deltaDisplay: 'N',
                progDisplay: 'N',
                barLength: 60,
                barHeight: 9,
                subTextSize: 14
      }

      this.blockStatus.forEach((d,i) => {

        if (this.FilterValues && this.FilterValues.GWBS_LEVEL) {
          blockItemSet.push({
            name: d.title,
            total: blocks_.filter(f => f.EREC == 'Y' && f.Lv4 === this.FilterValues.GWBS_LEVEL).length,
            actual: blocks_.filter(f => f.EREC == 'Y' && f.STATUS == d.code && f.Lv4 === this.FilterValues.GWBS_LEVEL).length,
            bColor: this.blockStatus[i].bColor,
            opacity: this.blockStatus[i].opacity,
          })
        } else {
          blockItemSet.push({
            name: d.title,
            total: blocks_.filter(f => f.EREC == 'Y').length,
            actual: blocks_.filter(f => f.EREC == 'Y' && f.STATUS == d.code).length,
            bColor: this.blockStatus[i].bColor,
            opacity: this.blockStatus[i].opacity,
          })
        }
        
      })

      this.blockStatusSum(blockItemSet)

      this.Bar_Quantity_01(g, blockItemSet, blockStyle, 0) // <---- Chart Library


    },
    
    blockStatusSum(item){  //status count
      let sum = 0
      for (let i = this.blockStatus.length - 1 ; i >= 0; i--) {
        sum += item[i].actual
        item[i].actual = sum
      }
    }

  }
}