import * as d3 from 'd3'
import __M from 'moment'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({
    thead: [
      { key: 'DISC_DESCR', html: 'Discipline', row: 1, rowspan: 3 },
      { key: 'UOM', html: 'UOM', row:1, rowspan: 3 },
      { key: 'WCCODE_DESCR', html: 'Work Class', row: 1, rowspan: 3 },
      { key: 'PLAN_START_WK', html: 'Plan(Week No.)', row: 1,rowspan: 2, colspan: 2, color: '#D1C4E9' },
      { key: 'PLAN_FINISH_WK', html: 'Actual(Week No.)', row: 1, rowspan: 2,colspan: 2, color: '#D1C4E9' },
      { key: 'Forecast', html: 'Forecast', row: 1, rowspan: 2, colspan: 2, color: '#D1C4E9' },
      { key: 'Progress', html: 'Progress', row: 1, rowspan: 1, colspan: 4, color: '#FFF9C4' },
      { key: 'Qty', html: 'Qty', row: 1, rowspan: 1, colspan: 6, color: '#DCEDC8' },

      // { key: 'PLAN_START_WK', html: 'Start', ref: 'PLAN_START_DATE', val: 'Plan Start', row: 2, rowspan: 2, color: '#D1C4E9' },
      // { key: 'PLAN_FINISH_WK', html: 'Finish', ref: 'PLAN_FINISH_DATE', val: 'Plan Finish' , row: 2, rowspan: 2, color: '#D1C4E9' },
      // { key: 'ACTUAL_START_WK', html: 'Start', ref: 'ACTUAL_START_DATE', val: 'Actual Start' , row: 2, rowspan: 2, color: '#D1C4E9' },
      // { key: 'ACTUAL_FINISH_WK', html: 'Finish', ref: 'ACTUAL_FINISH_DATE', val: 'Actual Finish', row: 2, rowspan: 2, color: '#D1C4E9' },
      // { key: 'FORECAST_START_WK', html: 'Start', ref: 'FORECAST_START_DATE', val: 'Forecast Start',  row: 2, rowspan: 2, color: '#D1C4E9' },
      // { key: 'FORECAST_FINISH_WK', html: 'Finish', ref: 'FORECAST_FINISH_DATE', val: 'Forecast Finish', row: 2, rowspan: 2, color: '#D1C4E9' },
      { key: 'Progress', html: 'Cumulative', row: 2, rowspan: 1, colspan: 2, color: '#FFF9C4' },
      { key: 'Progress', html: 'Weekly', row: 2, rowspan: 1, colspan: 2, color: '#FFF9C4' },
      { key: 'Qty', html: 'Cumulative', row: 2, rowspan: 1,colspan: 4, color: '#DCEDC8' },
      { key: 'Qty', html: 'Weekly', row: 2, rowspan: 1, colspan: 2, color: '#DCEDC8' },

      { key: 'PLAN_START_WK', html: 'Start', ref: 'PLAN_START_DATE', val: 'Plan Start', row: 3, rowspan: 2, color: '#D1C4E9' },
      { key: 'PLAN_FINISH_WK', html: 'Finish', ref: 'PLAN_FINISH_DATE', val: 'Plan Finish' , row: 3, rowspan: 2, color: '#D1C4E9' },
      { key: 'ACTUAL_START_WK', html: 'Start', ref: 'ACTUAL_START_DATE', val: 'Actual Start' , row: 3, rowspan: 2, color: '#D1C4E9' },
      { key: 'ACTUAL_FINISH_WK', html: 'Finish', ref: 'ACTUAL_FINISH_DATE', val: 'Actual Finish', row: 3, rowspan: 2, color: '#D1C4E9' },
      { key: 'FORECAST_START_WK', html: 'Start', ref: 'FORECAST_START_DATE', val: 'Forecast Start',  row: 3, rowspan: 2, color: '#D1C4E9' },
      { key: 'FORECAST_FINISH_WK', html: 'Finish', ref: 'FORECAST_FINISH_DATE', val: 'Forecast Finish', row: 3, rowspan: 2, color: '#D1C4E9' },
      { key: 'PLAN_PROG', html: 'Plan', row: 3, rowspan: 1, color: '#FFF9C4' },
      { key: 'ACTUAL_PROG', html: 'Actual', row: 3, rowspan: 1, color: '#FFF9C4' },
      { key: 'PLAN_PROG_WK', html: 'Plan', row: 3, rowspan: 1, color: '#FFF9C4' },
      { key: 'ACTUAL_PROG_WK', html: 'Actual', row: 3, rowspan: 1, color: '#FFF9C4' },
      { key: 'INITIALQTY', html: 'Initial', row: 3, rowspan: 1, color: '#DCEDC8' },
      { key: 'C2_QTY', html: 'C2', row: 3, rowspan: 1, color: '#DCEDC8' },
      { key: 'EQV_QTY', html: 'Actual', row: 3, rowspan: 1, color: '#DCEDC8' },
      { key: 'REMAIN_QTY', html: 'Remain', row: 3, rowspan: 1, color: '#DCEDC8' },
      { key: 'C2_QTY_WK', html: 'C2', row: 3, rowspan: 1, color: '#DCEDC8' },
      { key: 'EQV_QTY_WK', html: 'Actual', row: 3, rowspan: 1, color: '#DCEDC8' },
    ],
    theadrows: 3,
    tbody: [
      { key: 'DISC_DESCR', w: 80 },
      { key: 'UOM', w: 50 },
      { key: 'WCCODE_DESCR', w: 250, align: 'left' },
      { key: 'PLAN_START_WK', w: 85 },
      { key: 'PLAN_FINISH_WK', w: 85 },
      { key: 'ACTUAL_START_WK', w: 85 },
      { key: 'ACTUAL_FINISH_WK', w: 85 },
      { key: 'FORECAST_START_WK', w: 85 },
      { key: 'FORECAST_FINISH_WK', w: 85 },
      { key: 'PLAN_PROG', w: 60 },
      { key: 'ACTUAL_PROG', w: 60 },
      { key: 'PLAN_PROG_WK', w: 60 },
      { key: 'ACTUAL_PROG_WK', w: 60 },
      { key: 'INITIALQTY', w: 60, type: 'float', align: 'right' },
      { key: 'C2_QTY', w: 60, type: 'float', align: 'right' },
      { key: 'EQV_QTY', w: 60, type: 'float', align: 'right' },
      { key: 'REMAIN_QTY', w: 60, type: 'float', align: 'right' },
      { key: 'C2_QTY_WK', w: 60, type: 'float', align: 'right' },
      { key: 'EQV_QTY_WK', w: 60, type: 'float', align: 'right' },
    ],
    width: 945,


    selectedStartDate: null,
    selectedStartColumn: null,

    selectedFinishDate: null,
    selectedFinishColumn: null,

    filteredList: null
  }),

  methods: {

    drawTable() {
      let svg = this.svg

      this.filteredList = this.DataItems

      let list = this.filteredList

      let this_ = this

      

      let getOptionList = (column) => {
        let optionList = JSON.parse(JSON.stringify(this.DataItems))

        let sorted = optionList.sort(function(a,b){
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(a[column]) - new Date(b[column])
        }).filter(d => d[column] != null)

        return [...new Set(sorted.map(d => __M(new Date(d[column])).format('DD-MMM-YY')))]
      }

      



      let options = {
        PLAN_START_DATE_OPTIONS: ['Start', ...getOptionList('PLAN_START_DATE')],
        PLAN_FINISH_DATE_OPTIONS: ['Finish', ...getOptionList('PLAN_FINISH_DATE')],
        FORECAST_START_DATE_OPTIONS: ['Start', ...getOptionList('FORECAST_START_DATE')],
        FORECAST_FINISH_DATE_OPTIONS: ['Finish', ...getOptionList('FORECAST_FINISH_DATE')],
        ACTUAL_START_DATE_OPTIONS: ['Start', ...getOptionList('ACTUAL_START_DATE')],
        ACTUAL_FINISH_DATE_OPTIONS: ['Finish', ...getOptionList('ACTUAL_FINISH_DATE')],
      }


      let group = svg.append('g').attr('class', 'ogm_table_group__')
      
      let table_group = group.append('g').attr('transform', `translate(0.5, 30)`)

      

      let table_obj = table_group.append('foreignObject')
        .attr('y',0)
        .attr('width', '100%')
        .attr('height', '1500px')
        .append('xhtml:body')
        .style('position', 'fixed')

      let table = table_obj.append('table')
      .attr('id', 'commodity_summary_table_')
      .style('border-collapse', 'collapse')
      .style('font-size', '10px')
      .style('width', '1320px')
      .style('table-layout', 'fixed')

      let colgroup = table.append('colgroup')
      this.tbody.forEach(k => {
        colgroup.append('col').style('width', k.w + 'px')
      })

      let thead = table.append('thead')
      for (let r = 1; r <= this.theadrows; r++) {
        let row = this.thead.filter(d=> d.row === r)
        let tr = thead.append('tr')

        row.forEach(item => {
          
          if (item.row == 3 && (item.html == 'Start' || item.html == 'Finish')) {

            let div = tr.append('th')
            .attr('class', 'ogm_table_group__th')
            .style('border', 'solid 0.7px #eee').append('div').style('margin-right', '2px')
            
            let select = div.append('select')

            select.selectAll('option')
            .data(options[`${item.ref}_OPTIONS`])
            .enter()
            .append('option')
            .attr('value', (d) => d)
            // .style('display', (d) => {
            //   if (d.includes('Start') || d.includes('Finish')) return 'none'
            // })
            .text((d => d))

            select
            .attr('id', item.ref)
            .style('width', this.tbody.find( d => d.key == item.key).w + 'px')
            .style('padding', '.3em .5em')
            .style('border', 'none')
            .style('-webkit-appearance', 'auto')
            .style('cursor', 'pointer')
            .on('change', function() {
              let selectDate = d3.select(this).property('value')
              let column = item.ref

              if (column.includes('START')) {
                
                this_.selectedStartDate = selectDate.includes('Start') || selectDate.includes('Finish') ? null : __M(new Date(selectDate)).format('YYYY-MM-DD')
                this_.selectedStartColumn = column

              } else {
                this_.selectedFinishDate = selectDate.includes('Start') || selectDate.includes('Finish') ? null : __M(new Date(selectDate)).format('YYYY-MM-DD')
                this_.selectedFinishColumn = column
              }

              // console.log(this_.selectedFinishColumn, this_.selectedFinishDate,this_.selectedStartColumn, this_.selectedStartDate)

              this_.getFilteredList()
            })

          }else {
            tr.append('th')
            .attr('class', 'ogm_table_group__th')
            .attr('rowspan', () => item.rowspan ? item.rowspan : '')
            .attr('colspan', () => item.colspan ? item.colspan : '')
            .style('border', 'solid 0.7px #eee')
            .style('height', '25px')
            .style('padding', '3px')
            .style('background', item.color ? item.color : '#FAFAFA')
            .html(item.html)
          }
        })        
      }
        
      let tbodykeys = this.tbody

      let disc = ''
      
      let tbody = table.append('tbody').attr('id', 'commodity_summay_tbody_')

      let addClass = ''

      for (let r = 0; r < list.length; r++) {
        let tr = tbody.append('tr')

        tbodykeys.forEach((k, i) => {

          if (list[r][k.key] == disc) return
          else {
            if (i == 0) {
              disc = list[r][k.key]
            }
          }

          let td = tr.append('td')
            .style('border', 'solid 0.1px #eee')
            .style('height', '23px')
            .style('text-align', k.align ? k.align : 'center')
            .style('padding', '0 3px 0 3px')
            .attr('rowspan', () => {
              if (i == 0) {
                let filtered = list.filter(d => d.DISC_DESCR == list[r][k.key])
                return filtered.length
              }
            })

          if(i === 2) {
            td.style('white-space', 'nowrap')
              .style('overflow', 'hidden')
              .style('text-overflow', 'ellipsis')
              .attr('title', list[r][k.key])
          }

          td.on('mouseover', () => tr.style('cursor', 'pointer'))
          td.on('click', () => {
            let dataSource = {
              GWBS_LV2: list[r].GWBS_LV2,
              GWBS_LV3: list[r].GWBS_LV3,
              WCCODE: list[r].WCCODE
            }
            let request_ = this.getRequestColumnProps('status', dataSource, this.ColumnProps)
            this.$emit('request-action', request_)
          })

          if(k.key.includes('PROG')) {
            addClass = ''

            if (k.key.includes('ACTUAL_PROG')) {
              let diff = list[r]['PLAN_PROG'] - list[r]['ACTUAL_PROG']
              addClass = diff >= 30 ? 'caution' : ''
            }
            td
            .style('position', 'relative')
            .style('color', list[r][k.key] < 0 ? 'red' : list[r][k.key] === 100 ? '#fff' : addClass == 'caution' ? 'red' : '#000')
            .style('z-index', '1')
            .html(list[r][k.key] == null ? 0+'%' :list[r][k.key]+'%')

            td.append('div')
            .style('position', 'absolute')
            .style('left', 0)
            .style('top', 0)
            .style('width', list[r][k.key] > 0 ? list[r][k.key] + '%' : 0)
            .style('height', '22px')
            .style('background-color', list[r][k.key] < 100 ? k.key.includes('PLAN') ? '#E0E0E0' : '#FFCA28' : '#4CAF50')
            .style('z-index', '-1')

          }
          else {
            if (k.type == 'float') {
              let regexp = /\B(?=(\d{3})+(?!\d))/g
              if (isNaN(Math.floor(list[r][k.key]))) td.html('')
              else td.html((Math.floor(list[r][k.key] * 100) / 100).toString().replace(regexp, ','))
            } else td.html(list[r][k.key])
          }

        })
      }

    },
    getFilteredList() {
      this.filteredList = null
      this.filteredList = this.DataItems.filter(d => {
        if (this.selectedStartDate && this.selectedFinishDate) return d[this.selectedStartColumn] >= this.selectedStartDate && d[this.selectedFinishColumn] <= this.selectedFinishDate
        else if (this.selectedStartDate && !this.selectedFinishDate) return d[this.selectedStartColumn] >= this.selectedStartDate
        else if (this.selectedFinishDate && !this.selectedStartDate) return d[this.selectedFinishColumn] <= this.selectedFinishDate
        else return this.DataItems
      })

      this.redrawTable()
      this.draw_Timeline2021()
    },


    redrawTable() {
      d3.select('#commodity_summay_tbody_').remove()

      let list = this.filteredList

      let tbodykeys = this.tbody

      let disc = ''
      
      let tbody = d3.select('#commodity_summary_table_').append('tbody').attr('id', 'commodity_summay_tbody_')

      for (let r = 0; r < list.length; r++) {
        let tr = tbody.append('tr')

        tbodykeys.forEach((k, i) => {

          if (list[r][k.key] == disc) return
          else {
            if (i == 0) {
              disc = list[r][k.key]
            }
          }

          let td = tr.append('td')
            .style('border', 'solid 0.1px #eee')
            .style('height', '23px')
            .style('text-align', k.align ? k.align : 'center')
            .style('padding', '0 3px 0 3px')
            .attr('rowspan', () => {
              if (i == 0) {
                let filtered = list.filter(d => d.DISC_DESCR == list[r][k.key])
                return filtered.length
              }
            })

          if(i === 2) {
            td.style('white-space', 'nowrap')
              .style('overflow', 'hidden')
              .style('text-overflow', 'ellipsis')
              .attr('title', list[r][k.key])
          }

          td.on('mouseover', () => tr.style('cursor', 'pointer'))
          td.on('click', () => {
            let dataSource = {
              GWBS_LV2: list[r].GWBS_LV2,
              GWBS_LV3: list[r].GWBS_LV3,
              WCCODE: list[r].WCCODE
            }
            let request_ = this.getRequestColumnProps('status', dataSource, this.ColumnProps)
            this.$emit('request-action', request_)
          })

          if(k.key.includes('PROG')) {
            td
            .style('position', 'relative')
            .style('color', list[r][k.key] < 0 ? 'red' : list[r][k.key] === 100 ? '#fff' : '#000')
            .style('z-index', '1')
            .html(list[r][k.key]+'%')

            td.append('div')
            .style('position', 'absolute')
            .style('left', 0)
            .style('top', 0)
            .style('width', list[r][k.key] > 0 ? list[r][k.key] + '%' : 0)
            .style('height', '90%')
            .style('background-color', list[r][k.key] < 100 ? k.key.includes('PLAN') ? '#E0E0E0' : '#FFCA28' : '#4CAF50')
            .style('z-index', '-1')
          }
          else {
            td.html(list[r][k.key])
          }
        })
      }
    }
  }
}
