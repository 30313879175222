import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({
    eqStatus: [
      { code: 'PO',          title: 'PO',                          colName: { total: 'PO_TOTAL'                 , actual: 'PO_ACTUAL'     }, bColor: '#84C7E5', sColor: '#00838F', tColor: '#333', opacity: 1.00, },
      { code: 'FAT',         title: 'FAT',                         colName: { total: 'FAT_TOTAL'                , actual: 'FAT_ACTUAL'    }, bColor: '#2281C5', sColor: '#9F368B', tColor: '#fff', opacity: 1.00, },
      { code: 'IRN',         title: 'IRN',                         colName: { total: 'IRN_TOTAL'                , actual: 'IRN_ACTUAL'    }, bColor: '#CC93C1', sColor: '#9F368B', tColor: '#333', opacity: 1.00, },
      { code: 'ETA',         title: 'ON Site',                     colName: { total: 'ONSITE_TOTAL'             , actual: 'ONSITE_ACTUAL' }, bColor: '#F6D15E', sColor: '#F9A825', tColor: '#333', opacity: 1.00, },
      { code: 'INST',        title: 'Installed',                   colName: { total: 'INST_TOTAL'               , actual: 'INST_ACTUAL'   }, bColor: '#8DBA2D', sColor: '#558B2F', tColor: '#fff', opacity: 1.00, },
      { code: 'NOT_PO',      title: 'Not PO',                      colName: { total: 'NOT_PO_CNT'               , actual: ''              }, bColor: '#ffffff', sColor: '#BDBCBC', tColor: '#333', opacity: 1.00, },
      { code: 'ROS_PAST',    title: 'ETA is past from ROS',        colName: { total: 'ETA_PAST_FROM_ROS'        , actual: ''              }, bColor: '#F7BACF', sColor: '#BDBCBC', tColor: '#333', opacity: 1.00, },
      { code: 'UPBLOCK_PAST',title: 'ETA is past from  Upper Blk.',colName: { total: 'ETA_PAST_FROM_UPPERBLOCK' , actual: ''              }, bColor: '#EC407A', sColor: '#BDBCBC', tColor: '#fff', opacity: 1.00, },
    ],
    blockStatus: [
      // { code: null,           title: 'Fab. not Started',    bColor: '#44A9DF', tColor: '#bcbcbc', opacity: 0.25, code_bColor: '#fff',    code_tColor: '#333',},
      { code: 'FABSTART',     title: 'Fab. Commenced',       bColor: '#44A9DF', tColor: '#44A9DF', opacity: 0.20, code_bColor: '#84C7E5', code_tColor: '#333',},
      { code: 'FABRICATION',  title: 'Fab. Completed',           bColor: '#44A9DF', tColor: '#FFFFFF', opacity: 0.40, code_bColor: '#2281C5', code_tColor: '#fff',},
      { code: 'PAINTING',     title: 'Painting Completed',       bColor: '#44A9DF', tColor: '#44A9DF', opacity: 0.60, code_bColor: '#F6D15E', code_tColor: '#333',},
      { code: 'ASSEMBLY',     title: 'Assembly Completed', bColor: '#44A9DF', tColor: '#333',    opacity: 0.80, code_bColor: '#CC93C1', code_tColor: '#333',},
      { code: 'ERECTION',     title: 'Erec. Commenced',  bColor: '#44A9DF', tColor: '#333',    opacity: 1.00, code_bColor: '#8DBA2D', code_tColor: '#fff',},
    ],
    width: 1610,
    paths: [
      {
        module: 'LQ',
        path: '/TORTUE/Modules/LQDashboard/LQ/'
      },
      {
        module: 'HULL-FOR',
        path: '/TORTUE/Modules/HULLFORDashboard/'
      }

    ]
  }),

  methods: {
    drawChart() {

      let equipments_ = this.DataItems
      let blocks_ = this.Queries.SQL1
      let level = [...new Set(blocks_.filter(d => d.Lv4).map(d => d.Lv4))]
      let module = blocks_[0].Lv2  // LQ, Hullfor ...

      let path = this.paths.find(d => d.module === module).path

      let itemWidth = this.width / level.length

      let svg = this.svg
      let group = svg.append('g').attr('class', 'status_by_level_group__').attr('transform', `translate(0.5, 40)`)

      group.append('rect').attr('class', '_level_background_bar__')
      .attr('x', 0).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', itemWidth).attr('height', 460).attr('fill', '#eee').attr('opacity', '0.7')

      let eqNotAvailable = []

      level.forEach((lv, i) => {
        let eqFilter = equipments_.filter(d => d.GWBS_LEVEL === lv)
        if (eqFilter.length === 0) eqNotAvailable.push(lv)

        let g = group.append('g').attr('class', `${lv}_status__`).attr('transform', `translate(${i*itemWidth}, 0.5)`)

        let blank = itemWidth - 200 // itemwidth - imagewidth

        let itemX = blank < 0 ? 0 : parseInt(blank/2)

        g.append('image')
        .attr('id', `lq_${lv}`)
        .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}${path}${lv}.png`)
        .attr('transform', `translate(${itemX}, 0) scale(0.9)`)
        .attr('width', '215px')
        .attr('height', '145px')

        // // Level
        g.append('text')
        .attr('x', itemWidth / 2).attr('y', 155).attr('text-anchor', 'middle')
        .text(lv)
        .style('font-size',12)


        // equipment status
        let eqstatusGroup = g.append('g').attr('id',`${lv}_eqstatusGroup`)
        
        let eqItemSet = []
        let eqStyle = {
          x: itemX > 0 ? itemX + 20 : 30,
          y: 160,
          headTextSize: 16,
          headTitle: '',
          subTextSize: 11
        }

        this.eqStatus.forEach((d__, i) => {
          if (!equipments_.filter(d => d.GWBS_LEVEL === lv)[0]) return
          let tColName = this.eqStatus[i]['colName']['total']
          let aColName = this.eqStatus[i]['colName']['actual']

          eqItemSet.push({
            name: d__.title,
            total: equipments_.filter(d => d.GWBS_LEVEL === lv)[0][tColName],
            actual: equipments_.filter(d => d.GWBS_LEVEL === lv)[0][aColName],
            filter: {
              STATUS: d__.code,
              LV2: module,
              GWBS_LEVEL: lv,
              LV4: equipments_.filter(d => d.GWBS_LEVEL === lv)[0].LV4
            },
            bColor: this.eqStatus[i].bColor,
            opacity: this.eqStatus[i].opacity,
          })
        })

        this.Bar_Quantity_02(eqstatusGroup, eqItemSet, eqStyle, i) // draw equipment bar chart

        if (eqNotAvailable.includes(lv)) {
          eqstatusGroup.append('text')
          .attr('x', itemWidth / 2).attr('y', 270).attr('text-anchor', 'middle')
          .style('font-size', '20px')
          .style('fill', '#ccc')
          .style('font-weight', 'bold')
          .text('N/A')
        }


        // block status
        let blockstatusGroup = g.append('g').attr('id',`${lv}_blockstatusGroup`)

        let blockItemSet = []
        let blockStyle = {
                  x: itemX > 0 ? itemX + 20 : 20,
                  y: 330,
                  headTitle: '',
                  deltaDisplay: 'N',
                  progDisplay: 'N',
                  barLength: 60,
                  barHeight: 9
        }

        this.blockStatus.forEach((d,i) => {
          blockItemSet.push({
            name: d.title,
            total: blocks_.filter(f => f.EREC == 'Y' && f.Lv4 === lv).length,
            actual: blocks_.filter(f => f.EREC == 'Y' && f.STATUS == d.code && f.Lv4 === lv).length,
            bColor: this.blockStatus[i].bColor,
            opacity: this.blockStatus[i].opacity,
          })
        })

        this.blockStatusSum(blockItemSet)
        this.Bar_Quantity_01(blockstatusGroup, blockItemSet, blockStyle, i) // <---- Chart Library


        // Mask
        let mask = g.append('rect').attr('class', '_level_group_mask__')
        .attr('x', 0).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', itemWidth).attr('height', 145).attr('fill', 'Transparent')

        mask.on('mouseover', () => {
          mask.style('cursor', 'pointer')
          d3.select('._level_background_bar__').transition().duration(100).attr('x', `${i*itemWidth}`)
        })

        mask.on('click', () => { 
          let dataSource = {
            LV2: module,
            GWBS_LV2: module,
            GWBS_LEVEL: lv,
            LV4: lv === 'HD01' ? 'HD01' : lv === 'MS02' ? 'MS02' : equipments_.filter(d => d.GWBS_LEVEL === lv)[0].LV4
          }
          // let request_ = {
          //   dataType: 'row',
          //   action: {
          //     type: 'direct',
          //     target: 'slide-modal',
          //     component: 'ServiceSvgTabs',
          //     id: 555,
          //     no: 'LIBSVGTB-0002'
          //   },
          //   filters: {
          //     LV2: 'LQ',
          //     GWBS_LEVEL: lv,
          //     LV4: lv === 'HD01' ? 'HD01' : equipments_.filter(d => d.GWBS_LEVEL === lv)[0].LV4
          //   },
          //   iFilters: {}
          // }
          let request_ = this.getRequestColumnProps('levelInfo', dataSource, this.ColumnProps)
          this.$emit('request-action', request_)
        })
      })

    },
    
    blockStatusSum(item){  //status count
      let sum = 0
      for (let i = this.blockStatus.length - 1 ; i >= 0; i--) {
        sum += item[i].actual
        item[i].actual = sum
      }
    }

  }
}