const Data_Hull_Mechinaery = [
{ BLK: 'MS04_PRT', Lv1: 'HULL', Lv2: 'MS', Lv3: 'MS02', x1: 951, y1: 490.74, x2: 577, y2: 517.74, x3: 324, y3: 657.74, x4: 325, y4: 564.74, x5: null, y5: null, layout: null, },
{ BLK: 'MS04_EQ', Lv1: 'HULL', Lv2: 'MS', Lv3: 'MS02', x1: 968, y1: 492, x2: 594, y2: 519, x3: 341, y3: 659, x4: 413, y4: 604, x5: 634, y5: 392, layout: 'Y', },
{ BLK: 'MS04_STB', Lv1: 'HULL', Lv2: 'MS', Lv3: 'MS02', x1: 1067, y1: 549, x2: 693, y2: 576, x3: 440, y3: 716, x4: 594, y4: 706, x5: null, y5: null, layout: null, },
{ BLK: 'MS04_AFT', Lv1: 'HULL', Lv2: 'MS', Lv3: 'MS02', x1: 957, y1: 518.74, x2: 583, y2: 545.74, x3: 330, y3: 685.74, x4: 316, y4: 662.74, x5: null, y5: null, layout: null, },
{ BLK: 'MS03_EQ', Lv1: 'HULL', Lv2: 'MS', Lv3: 'MS03', x1: 985, y1: 461.5, x2: 611, y2: 488.5, x3: 500, y3: 575.5, x4: 573, y4: 521, x5: 592.374, y5: 374.626, layout: 'Y', },
{ BLK: 'MS02_PRT', Lv1: 'HULL', Lv2: 'MS', Lv3: 'MS04', x1: 952.4, y1: 425.4, x2: 578.4, y2: 452.4, x3: 638.4, y3: 476.4, x4: 641, y4: 384, x5: null, y5: null, layout: null, },
{ BLK: 'MS02_EQ', Lv1: 'HULL', Lv2: 'MS', Lv3: 'MS04', x1: 975.3, y1: 459.4, x2: 601.3, y2: 486.4, x3: 661.3, y3: 510.4, x4: 735, y4: 456, x5: 641, y5: 377, layout: 'Y', },
{ BLK: 'MS06_PRT', Lv1: 'HULL', Lv2: 'MS', Lv3: 'MS06', x1: 1078.5, y1: 386.29, x2: 704.5, y2: 413.29, x3: 1109.5, y3: 309.29, x4: 1180.5, y4: 256.29, x5: null, y5: null, layout: null, },
{ BLK: 'MS01_PRT', Lv1: 'HULL', Lv2: 'MS', Lv3: 'MS05', x1: 947.5, y1: 391, x2: 573.5, y2: 418, x3: 805.5, y3: 377, x4: 808, y4: 285, x5: null, y5: null, layout: null, },
{ BLK: 'MS01_EQ', Lv1: 'HULL', Lv2: 'MS', Lv3: 'MS05', x1: 985.5, y1: 426.74, x2: 611.5, y2: 453.74, x3: 843.5, y3: 412.74, x4: 917, y4: 361.74, x5: 442.001, y5: 522.476, layout: 'Y', },
{ BLK: 'MS02_STB', Lv1: 'HULL', Lv2: 'MS', Lv3: 'MS04', x1: 1067.8, y1: 486.79, x2: 693.8, y2: 513.79, x3: 753.8, y3: 537.79, x4: 909, y4: 528, x5: null, y5: null, layout: null, },
{ BLK: 'MS01_AFT', Lv1: 'HULL', Lv2: 'MS', Lv3: 'MS05', x1: 974.8, y1: 460, x2: 600.8, y2: 487, x3: 832.8, y3: 446, x4: 907, y4: 444, x5: null, y5: null, layout: null, },
{ BLK: 'MS06_STB', Lv1: 'HULL', Lv2: 'MS', Lv3: 'MS06', x1: 1147.8, y1: 420.71, x2: 773.8, y2: 447.71, x3: 1178.8, y3: 343.71, x4: 1249.8, y4: 290.71, x5: null, y5: null, layout: null, },
{ BLK: 'MS06_DK', Lv1: 'HULL', Lv2: 'MS', Lv3: 'MS06', x1: 967, y1: 399, x2: 593, y2: 426, x3: 998, y3: 322, x4: 1069, y4: 269, x5: null, y5: null, layout: null, },
{ BLK: 'MS01_STB', Lv1: 'HULL', Lv2: 'MS', Lv3: 'MS05', x1: 1062.5, y1: 451, x2: 688.5, y2: 478, x3: 920.5, y3: 437, x4: 1076, y4: 428, x5: null, y5: null, layout: null, },

]

export default Data_Hull_Mechinaery