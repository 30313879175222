import * as d3 from 'd3'
// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  data:() => ({
    props:[
      { module: 'ME01', moduleDescr:'Flash Gas Compression',x: -20, y: 100 },
      { module: 'ME02', moduleDescr:'MEG & Fuel Gas',x: -20, y: 100 },
      { module: 'ME04', moduleDescr:'Power Generation',x: 0, y: 80 },
      { module: 'ME05', moduleDescr:'E/I Building',x: -10, y: 100 },
      { module: 'MW01', moduleDescr:'Reception Facilities & Production Separation',x: -20, y: 100 },
      { module: 'MW02', moduleDescr:'HC Dewpointing & Condensate Stabilisation',x: -10, y: 100 },
      { module: 'MW04', moduleDescr:'Power Generation',x: 0, y: 80 },
      { module: 'MW05', moduleDescr:'Utility',x: -10, y: 100 },
      { module: 'FL01', moduleDescr:'Utility',x: 30, y: 80 },
      { module: 'KO01', moduleDescr:'Utility',x: -10, y: 100 },
      { module: 'LD00DA', moduleDescr:'Utility',x: -10, y: 100 },
      { module: 'RCxx', moduleDescr:'Utility',x: -30, y: 110 },
    ],
    blockStatus: [
      { code: 'FABSTART',     title: 'Fab. Commenced',       bColor: '#44A9DF', tColor: '#44A9DF', opacity: 0.20, code_bColor: '#84C7E5', code_tColor: '#333',},
      { code: 'FABRICATION',  title: 'Fab. Completed',           bColor: '#44A9DF', tColor: '#FFFFFF', opacity: 0.40, code_bColor: '#2281C5', code_tColor: '#fff',},
      { code: 'PAINTING',     title: 'Painting Completed',       bColor: '#44A9DF', tColor: '#44A9DF', opacity: 0.60, code_bColor: '#F6D15E', code_tColor: '#333',},
      { code: 'ASSEMBLY',     title: 'Assembly Completed', bColor: '#44A9DF', tColor: '#333',    opacity: 0.80, code_bColor: '#CC93C1', code_tColor: '#333',},
      { code: 'ERECTION',     title: 'Erec. Commenced',  bColor: '#44A9DF', tColor: '#333',    opacity: 1.00, code_bColor: '#8DBA2D', code_tColor: '#fff',},
    ],
    eqStatus: [
      { code: 'PO',          title: 'PO',                          colName: { total: 'PO_TOTAL'                 , actual: 'PO_ACTUAL'     }, bColor: '#84C7E5', sColor: '#00838F', tColor: '#333', opacity: 1.00, },
      { code: 'FAT',         title: 'FAT',                         colName: { total: 'FAT_TOTAL'                , actual: 'FAT_ACTUAL'    }, bColor: '#2281C5', sColor: '#9F368B', tColor: '#fff', opacity: 1.00, },
      { code: 'IRN',         title: 'IRN',                         colName: { total: 'IRN_TOTAL'                , actual: 'IRN_ACTUAL'    }, bColor: '#CC93C1', sColor: '#9F368B', tColor: '#333', opacity: 1.00, },
      { code: 'ETA',         title: 'ON Site',                     colName: { total: 'ONSITE_TOTAL'             , actual: 'ONSITE_ACTUAL' }, bColor: '#F6D15E', sColor: '#F9A825', tColor: '#333', opacity: 1.00, },
      { code: 'INST',        title: 'Installed',                   colName: { total: 'INST_TOTAL'               , actual: 'INST_ACTUAL'   }, bColor: '#8DBA2D', sColor: '#558B2F', tColor: '#fff', opacity: 1.00, },
      { code: 'NOT_PO',      title: 'Not PO',                      colName: { total: 'NOT_PO_CNT'               , actual: ''              }, bColor: '#ffffff', sColor: '#BDBCBC', tColor: '#333', opacity: 1.00, },
      { code: 'ROS_PAST',    title: 'ETA is past from ROS',        colName: { total: 'ETA_PAST_FROM_ROS'        , actual: ''              }, bColor: '#F7BACF', sColor: '#BDBCBC', tColor: '#333', opacity: 1.00, },
      { code: 'UPBLOCK_PAST',title: 'ETA is past from  Upper Blk.',colName: { total: 'ETA_PAST_FROM_UPPERBLOCK' , actual: ''              }, bColor: '#EC407A', sColor: '#BDBCBC', tColor: '#fff', opacity: 1.00, },
    ],
  }),
  methods: {
    Draw_Charts() {
      let data = null, style = null, tmp = null
      let moduleMap = this.DataItems.map(d => d.GWBS_LV3)
      let props = this.props.filter(d => d.module == moduleMap)[0]

      let overallSummary = this.svg
      .append('g')
      .attr('class','moduleName')
      .attr('transform', `translate(40, 50)`)

      overallSummary
      .append('image')
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Modules/${moduleMap}.png`)
      .attr('transform', `translate(${props.x} ${props.y}) `)
      .style('cursor', 'pointer')
      .on('mouseover', () => {
        overallSummary.style('opacity', '0.7')
      })
      .on('mouseleave', () => {
        overallSummary.style('opacity', '1')
      })
      .on('click', () => {
        if (!moduleMap[0]) return
        
        let request_ = this.getRequestColumnProps(moduleMap, {}, this.ColumnProps)
        this.$emit('request-action', request_)
      })

      // Overall Plan
      tmp = this.DataItems[0]
      style = {
        id: 'CERT',
        x: 0,
        y: 0,
        ty: 5,
        name: tmp.PLAN,
        sort: 'N',
      }
      data = [
        {title: 'Completed',  value: tmp.PLAN },
        {title: 'Remain',     value: 100-tmp.PLAN }
      ]
      let Circle = {
        CircleDiameter: 28,
        CircleColorSet: ['Gray', 'LightGray'],
        CircleHoleSize: 20,
        CircleStackSize: 2,
        CircleSliceSize: 5,
        CircleValueSize: 14,
        CircleTitleSize: 12,
        CircleLegendSpace: 80,
      }
      let Guideline = {
        GuideDisplay: 'N',
      }
      overallSummary
      .append('text')
      .attr('transform', `translate(${style.x}, ${style.y - Circle.CircleDiameter - 5})`)
      .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'middle')
      .text('Plan (%)')

      this.Chart_PieSimple(overallSummary, data, style, Circle, Guideline)

      style = {
        id: 'SHI',
        x: 128,
        y: 0,
        ty: 5,
        name: tmp.ACTUAL,
        sort: 'N',
      }
      data = [
        {title: 'Completed',  value: tmp.ACTUAL },
        {title: 'Remain',     value: 100-tmp.ACTUAL }
      ]
      Circle.CircleColorSet = ['LightBlue', 'LightGray']
      
      overallSummary
      .append('text')
      .attr('transform', `translate(${style.x}, ${style.y - Circle.CircleDiameter - 5})`)
      .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'middle')
      .text('Actual (%)')

      this.Chart_PieSimple(overallSummary, data, style, Circle, Guideline)

      let deltabox = overallSummary
      .append('g')
      .attr('transform', `translate(40, -50)`)

      deltabox
      .append('text')
      .attr('transform', `translate(25, 52)`)
      .style('font-size', 9)
      .style('font-family', 'roboto')
      .attr('fill', '#959595')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text('Delta (%)')

      deltabox
      .append('text')
      .attr('transform', `translate(25, 70)`)
      .style('font-size', 14)
      .style('font-family', 'roboto')
      .attr('fill', tmp.DELTA >= 0 ? '#1e88e5' : '#bf360c')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text(`${tmp.DELTA > 0 ? '▲ ' : tmp.DELTA === 0 ? '': '▼ '}` + tmp.DELTA)

      //Week
      let week = overallSummary
      .append('g')
      .attr('id', 'week')
      .attr('transform', `translate(-28, 40)`)
      week
      .append('rect')
      .attr('rx', 4)
      .attr('ry', 4)
      .attr('width', 185)
      .attr('height', 30)
      .attr('fill-opacity', 0)
      .attr('stroke', '#dadada')
      .attr('stroke-width', .5)
      week
      .append('rect')
      .attr('transform', `translate(141, -5)`)
      .attr('width', 40)
      .attr('height', 10)
      .attr('fill', '#fff')
      week
      .append('text')
      .attr('transform', `translate(161, 0)`)
      .style('font-size', 9)
      .style('font-family', 'roboto')
      .attr('fill', '#959595')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text('Weekly')
      week
      .append('text')
      .attr('transform', `translate(28, 17)`)
      .style('font-size', 12)
      .style('font-family', 'roboto')
      .attr('fill', '#333')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text(tmp.PLAN_WK)
      week
      .append('text')
      .attr('transform', `translate(90, 17)`)
      .style('font-size', 12)
      .style('font-family', 'roboto')
      .attr('fill', tmp.DELTA_WK >= 0 ? '#1e88e5' : '#bf360c')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text(`${tmp.DELTA_WK > 0 ? '▲ ' : tmp.DELTA_WK === 0 ? '': '▼ '}` + Number(tmp.DELTA_WK).toFixed(2))
      week
      .append('text')
      .attr('transform', `translate(159, 17)`)
      .style('font-size', 12)
      .style('font-family', 'roboto')
      .attr('fill', '#333')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text(tmp.ACTUAL_WK)

      //----------------------------------------------------------------------------------------------simple text
      if(!this.Queries.SQL1[0].TEXT1 && !this.Queries.SQL1[0].TEXT1_DATE){
        let nosvg = this.svg
        nosvg.append('text').attr('x', 110/2).attr('y', 130/2).attr('text-anchor', 'middle')
        .text('N/A').attr('font-size', '30px')
        .attr('fill', '#ccc')
        return
      }
  
      let item = this.Queries.SQL1[0]
      let ogmBox = this.svg
      .append('g')
      .attr('id', 'ogm_simple_box')
      .attr('transform', `translate(240, 80)`)

      let ovdata = ogmBox
      .append('g')
      .attr('transform', `translate(10, 20)`)

      ovdata
      .append('rect')
      .attr('x', 0).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', 90).attr('height', 36)
      .attr('fill', '#ffffff').attr('stroke', '#8BC34A') .attr('stroke-width', 0.5)
      ovdata
      .append('text')
      .attr('x', 90/2).attr('y', 13)
      .style('font-family','roboto').style('font-size',11).attr('text-anchor', 'middle').attr('fill', '#424242')
      .text(item.TEXT2 ? item.TEXT2 : '')

      const barScale = d3.scaleLinear()
      .domain([0,100])
      .range([0,90])
      
      //PROG Background Bar
      ovdata
      .append('rect')
      .attr('x', 0).attr('y', 18).attr('width', 90).attr('height', 20)
      .attr('fill', '#fff').attr('stroke', '#8BC34A') .attr('stroke-width', 0.5)
              
      //PROG Actual Bar
      ovdata
      .append('rect')
      .attr('x', 0).attr('y', 18)
      .attr('width',0)
      .attr('height',20)
      .attr('fill','#8BC34A')
      .transition().duration(150)
      .attr('width',barScale(item.ACTUAL))

      ovdata
      .append('text')
      .attr('x', 90/2).attr('y', 31)
      .style('font-family','roboto').style('font-size',11).attr('text-anchor', 'middle')
      .attr('fill',item.ACTUAL >=60 ? '#fff' : '#424242')
      .text(item.TEXT2_PROG ? item.TEXT2_PROG + '%' : '')

      let datedata = ogmBox
      .append('g')
      .attr('transform', `translate(10, 70)`)

      datedata
      .append('rect')
      .attr('x', 0).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', 90).attr('height', 18)
      .attr('fill', '#fff').attr('stroke', '#9E9E9E') .attr('stroke-width', 0.5)
      datedata
      .append('text')
      .attr('x', 90/2).attr('y', 13)
      .style('font-family','roboto').style('font-size',11).attr('text-anchor', 'middle').attr('fill', '#424242')
      .text(item.TEXT1 ? item.TEXT1 : '')

      datedata
      .append('rect')
      .attr('x', 0).attr('y', 18).attr('width', 90).attr('height', 20)
      .attr('fill', '#9E9E9E').attr('stroke', '#9E9E9E') .attr('stroke-width', 0.5)
      datedata
      .append('text')
      .attr('x', 90/2).attr('y', 33)
      .style('font-family','roboto').style('font-size',12).attr('text-anchor', 'middle').attr('fill', '#FFF')
      .text(item.TEXT1_DATE ? item.TEXT1_DATE : '')

      //----------------------------------------------------------------------------------------------Erectoin Block
      let blocks_ = this.Queries.SQL2
      let blockProg = this.svg
      let g = blockProg.append('g').attr('class', 'block_bar_status__').attr('transform', `translate(420.5, 0.5)`)
      
      let blockItemSet = []
      let blockStyle = {
                x: 10,
                y: 10,
                headTitle: '',
                deltaDisplay: 'N',
                progDisplay: 'N',
                barLength: 60,
                barHeight: 9,
                subTextSize: 14
      }
      this.blockStatus.forEach((d,i) => {
        if (this.FilterValues && this.FilterValues.GWBS_LEVEL) {
          blockItemSet.push({
            name: d.title,
            total: blocks_.filter(f => f.EREC == 'Y' && f.Lv4 === this.FilterValues.GWBS_LEVEL).length,
            actual: blocks_.filter(f => f.EREC == 'Y' && f.STATUS == d.code && f.Lv4 === this.FilterValues.GWBS_LEVEL).length,
            bColor: this.blockStatus[i].bColor,
            opacity: this.blockStatus[i].opacity,
          })
        } else {
          blockItemSet.push({
            name: d.title,
            total: blocks_.filter(f => f.EREC == 'Y').length,
            actual: blocks_.filter(f => f.EREC == 'Y' && f.STATUS == d.code).length,
            bColor: this.blockStatus[i].bColor,
            opacity: this.blockStatus[i].opacity,
          })
        }
      })
      this.blockStatusSum(blockItemSet)
      this.Bar_Quantity_01(g, blockItemSet, blockStyle, 0) // <---- Chart Library

      //----------------------------------------------------------------------------------------------Equipment Block
      let equipments_ = this.Queries.SQL3

      let eqProg = this.svg
      let g2 = eqProg.append('g').attr('class', 'equipment_bar_status__').attr('transform', `translate(420.5, 140.5)`)
      
      if(this.Queries.SQL3 == undefined){
        let nosvg = g2
        nosvg.append('text').attr('x', 110/2).attr('y', 130/2).attr('text-anchor', 'middle')
        .text('N/A').attr('font-size', '30px')
        .attr('fill', '#ccc')
        return
      }

      let eqItemSet = []
      let eqStyle = {
        x: 10,
        y: 10,
        headTextSize: 12,
        headTitle: '',
        subTextSize: 14
      }

      this.eqStatus.forEach((d__, i) => {
        let tColName = this.eqStatus[i]['colName']['total']
        let aColName = this.eqStatus[i]['colName']['actual']

        if (this.FilterValues && this.FilterValues.GWBS_LEVEL) {

          eqItemSet.push({
            name: d__.title,
            total: equipments_.filter(d => d.GWBS_LEVEL === this.FilterValues.GWBS_LEVEL)[0][tColName],
            actual: equipments_.filter(d => d.GWBS_LEVEL === this.FilterValues.GWBS_LEVEL)[0][aColName],
            filter: {
              STATUS: d__.code,
              LV2: equipments_[0].LV2,
              LV4: equipments_.filter(d => d.GWBS_LEVEL === this.FilterValues.GWBS_LEVEL)[0].LV4
            },
            bColor: this.eqStatus[i].bColor,
            opacity: this.eqStatus[i].opacity,
          })
          
        }else {
          if (equipments_ && equipments_[0].LV2 === 'MODULES') {
            eqItemSet.push({
              name: d__.title,
              total: equipments_.reduce((a, b) => a + (b[tColName] || 0), 0),
              actual: equipments_.reduce((a, b) => a + (b[aColName] || 0), 0),
              filter: {
                STATUS: d__.code,
                LV2: equipments_[0].LV2,
                GWBS_LV3: equipments_[0].GWBS_LV3
              },
              bColor: this.eqStatus[i].bColor,
              opacity: this.eqStatus[i].opacity,
            })
          } else {
            eqItemSet.push({
              name: d__.title,
              total: equipments_.reduce((a, b) => a + (b[tColName] || 0), 0),
              actual: equipments_.reduce((a, b) => a + (b[aColName] || 0), 0),
              filter: {
                STATUS: d__.code,
                LV2: equipments_[0].LV2,
              },
              bColor: this.eqStatus[i].bColor,
              opacity: this.eqStatus[i].opacity,
            })
          }
        }
      })
      this.Bar_Quantity_02(g2, eqItemSet, eqStyle, 0) // draw equipment bar chart
    },
    blockStatusSum(item){  //status count
      let sum = 0
      for (let i = this.blockStatus.length - 1 ; i >= 0; i--) {
        sum += item[i].actual
        item[i].actual = sum
      }
    }
  }
}
