import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  methods: {
    Draw_Timeline() {
      // let canvas = this.svg
      // .append('g')
      // .attr('transform', `translate(2,0)`)
      // .attr('id','canvasFrame')

      // canvas
      // .append('rect')
      // .attr('width', 1250).attr('height', 800).attr('stroke','#f5f5f5').attr('stroke-width',10).attr('fill','transparent')
      let Timeline = this.svg
      .append('g')
      .attr('transform', `translate(${this.timeline.baseX}, ${this.timeline.baseY})`)

      // Timeline Text using the scale function
      if (this.timeline.style.week.display == 'Y') {
        // Weeks Text & Timeline Seperator for the Week
        Timeline
        .append('g')
        .selectAll('text')
        .data(this.timeline.week)
        .enter()
        .append('text')
        .attr('transform', d => `translate(${this.timeline.scale(d.mDate)}, ${this.timeline.style.week.tSize})`)
        .style('font-family', this.timeline.font)
        .style('font-size', this.timeline.style.week.tSize)
        .attr('fill', this.timeline.style.week.tColor)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(d => d3.timeFormat('%d')(d.eDate))
      }
      if (this.timeline.style.month.display == 'Y') {
        // Month Text & Timeline Seperator for the Month
        Timeline
        .append('g')
        .selectAll('text')
        .data(this.timeline.month)
        .enter()
        .append('text')
        .attr('x', d => this.timeline.scale(d.mDate))
        .attr('y', this.timeline.style.week.height)
        .style('font-family', this.timeline.font)
        .style('font-size', this.timeline.style.month.tSize)
        .attr('fill', this.timeline.style.month.tColor)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'hanging')
        .text(d => d.name.toUpperCase())
      }
      // Year Text & Timeline Seperator for the Year
      if (this.timeline.style.year.display == 'Y') {
        Timeline
        .append('g')
        .selectAll('text')
        .data(this.timeline.year)
        .enter()
        .append('text')
        .attr('x', d => this.timeline.scale(d.mDate))
        .attr('y', this.timeline.style.week.height +  this.timeline.style.month.height)
        .style('font-family', this.timeline.font)
        .style('font-size', this.timeline.style.year.tSize)
        .attr('fill', this.timeline.style.year.tColor)
        .attr('font-weight', 500)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'hanging')
        .text(d => d.name)
      }
    },
    Draw_AxesGrid() {
      // let axes = this.svg
      // .append('g')
      // .attr('transform', `translate(${this.Canvas.CanvasChartX}, ${this.Canvas.CanvasChartY})`)

      // axes
      // .append('text') 
      // .attr('transform', `translate(-35, 175) rotate(-90)`).style('font-family', 'roboto').attr('font-size', 10).attr('text-anchor','middle').attr('fill','#757575').text('Cumulative')
      // axes
      // .append('text') 
      // .attr('transform', `translate(1030, 170) rotate(-90)`).style('font-family', 'roboto').attr('font-size', 10).attr('text-anchor','middle').attr('fill','#757575').text('Weekly')

      let chartArea = this.svg
      .append('g')
      .attr('id', 'chart_area')
      .attr('transform', `translate(${this.Canvas.CanvasChartX}, ${this.Canvas.CanvasChartY})`)
      
      chartArea // Axis X
      .append('line')
      .attr('x1', 0)
      .attr('x2', this.axisXWidth)
      .attr('y1', this.axisYHeight)
      .attr('y2', this.axisYHeight)
      .style('stroke', '#333')
      .style('stroke-width', .5)
              

      // draw 0 and maxBounds on Axis Y1
      let gridY = 0.1 //line이 얇게 보이기 위해 0.1을 넣었음 ????이상함
      let textGapY = 3
      let value1 = this.dataMaxValue1
      let value2 = this.dataMaxValue2

      for(var i=0; i<6; i++) {
        chartArea
        .append('text') // Axis 1
        .attr('x', -textGapY).attr('y',gridY).attr('font-size', 9).attr('text-anchor','end').attr('alignment-baseline','middle').attr('fill','#bcbcbc')
        .text(value1.toLocaleString())

        chartArea
        .append('text') // Axis 2
        .attr('x', this.axisXWidth + textGapY).attr('y', gridY).attr('font-size', 9).attr('text-anchor','start').attr('alignment-baseline','middle').attr('fill','#bcbcbc')
        .text(value2.toLocaleString())

        if(i < 6) {
          chartArea
          .append('line')
          .style('stroke', '#000').style('stroke-width', 0.11).attr('x1', 0).attr('x2', this.axisXWidth).attr('y1', gridY).attr('y2', gridY)
        }

        gridY += (this.axisYHeight/5)
        value1 -= (this.dataMaxValue1/5)
        value2 -= (this.dataMaxValue2/5)
      }
    },
    Draw_BarLines() {

      let legend = this.svg
      .append('g')
      .attr('transform', `translate(-86,323)`)

      legend
      .append('rect')
      .attr('transform', `translate(0, 0)`)
      .attr('width', 8).attr('height', 8).attr('fill','#E0E0E0')
      legend
      .append('rect')
      .attr('transform', `translate(0, 20)`)
      .attr('width', 8).attr('height', 8).attr('fill','#4FC3F7')
      legend
      .append('rect')
      .attr('transform', `translate(0, 43)`)
      .attr('width', 8).attr('height', 3).attr('fill','#757575')
      legend
      .append('rect')
      .attr('transform', `translate(0, 63)`)
      .attr('width', 8).attr('height', 3).attr('fill','#039BE5')


      // legend
      // .append('rect')
      // .attr('transform', `translate(0, 5)`)
      // .attr('width', 8).attr('height', 8).attr('fill','#B4E3FA')
      // legend
      // .append('text') 
      // .attr('transform', `translate(13, 10)`).attr('font-size', 9).attr('text-anchor','start').attr('alignment-baseline','middle').attr('fill','#757575')
      // .text('Submitted by Weekly')

      // legend
      // .append('rect')
      // .attr('transform', `translate(115, 5)`)
      // .attr('width', 8).attr('height', 8).attr('fill','#CDDC37')
      // legend
      // .append('text') 
      // .attr('transform', `translate(128, 10)`).attr('font-size', 9).attr('text-anchor','start').attr('alignment-baseline','middle').attr('fill','#757575')
      // .text('Approved by Weekly')

      // legend
      // .append('line')
      // .attr('x1', 225).attr('x2', 240).attr('y1', 10).attr('y2', 10).attr('stroke-width', 1).attr('stroke', '#44A9DF')
      // legend
      // .append('circle')
      // .attr('cx', 232).attr('cy', 10).attr('r', 3)
      // .attr('fill', '#fff').attr('stroke-width', 1).attr('stroke', '#44A9DF')
      // legend
      // .append('text') 
      // .attr('transform', `translate(245, 10)`).attr('font-size', 9).attr('text-anchor','start').attr('alignment-baseline','middle').attr('fill','#757575')
      // .text(`Cumulative Submitted`)

      // legend
      // .append('line')
      // .attr('x1', 355).attr('x2', 370).attr('y1', 10).attr('y2', 10).attr('stroke-width', 1).attr('stroke', '#ACAE4E')
      // legend
      // .append('circle')
      // .attr('cx', 362).attr('cy', 10).attr('r', 3)
      // .attr('fill', '#fff').attr('stroke-width', 1).attr('stroke', '#ACAE4E')
      // legend
      // .append('text') 
      // .attr('transform', `translate(375, 10)`).attr('font-size', 9).attr('text-anchor','start').attr('alignment-baseline','middle').attr('fill','#757575')
      // .text(`Cumulative Approved`)

      let chartArea = this.svg
      .append('g')
      .attr('transform', `translate(${this.Canvas.CanvasChartX-6}, ${this.Canvas.CanvasChartY})`)

      // bar-chart
      chartArea
      .append('g')
      .attr('transform', `translate(0, -0.2)`)
      .selectAll()
      .data(this.tickValuesWKS)
      .enter()
      .append('line')
      .attr('stroke', '#E0E0E0')
      .attr('stroke-width', 10).attr('opacity', 1)
      .attr('x1', d => this.timeline.scale(d.date))
      .attr('x2', d => this.timeline.scale(d.date))
      .attr('y1', this.scaleY2(0))
      .attr('y2', this.scaleY2(0))
      .transition()
      .duration(500)
      .attr('y1', this.scaleY2(0))
      .attr('y2', d => this.scaleY2(d.week))

      // bar-chart values
      chartArea
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .selectAll()
      .data(this.tickValuesWKS)
      .enter()
      .append('text')
      .attr('transform', d => `translate(${this.timeline.scale(d.date)}, ${this.scaleY2(0)})`)
      .attr('font-size', 9)
      .attr('text-anchor','middle')
      .attr('alignment-baseline','start')
      .attr('fill','#757575')
      .text(d => d.week.toLocaleString() > 0 ? d.week.toLocaleString() : '')
      .transition()
      .duration(500)
      .attr('transform', d => `translate(${this.timeline.scale(d.date)}, ${this.scaleY2(d.week)-3})`)

      // line-chart
      chartArea
      .append('g')
      .attr('transform', `translate(6, 0)`)
      .append('path')
      .attr('d', this.lineZero(this.tickValuesCumS))
      .style('stroke', '#757575').style('stroke-width', 1.5).attr('opacity', .7)
      .style('fill', 'none')
      .transition()
      .duration(500)
      .attr('d', this.lineFunc(this.tickValuesCumS))

      // line-chart markup
      chartArea
      .append('g')
      .attr('transform', `translate(6, 0)`)
      .selectAll()
      .data(this.tickValuesCumS)
      .enter()
      .append('circle')
      .attr('fill', '#fff')
      .attr('stroke', '#424242')
      .attr('stroke-width', 1)
      .attr('cx', d => this.timeline.scale(d.date))
      .attr('cy', this.axisYHeight)
      .attr('r', 3)
      .transition()
      .duration(500)
      .attr('cy', d => this.scaleY1(d.cum))
      

      // Draw the average (Value / Note / Line)
      chartArea
      .append('g')
      .attr('transform', `translate(6, 0)`)
      .selectAll()
      .data(this.tickValuesCumS)
      .enter()
      .append('text')
      .attr('transform', d => `translate(${this.timeline.scale(d.date)}, ${this.scaleY1(0)})`)
      .attr('font-size', 9)
      .attr('text-anchor','middle')
      .attr('alignment-baseline','start')
      .attr('fill','#424242')
      .text(d => d.cum.toLocaleString() > 0 ? d.cum.toLocaleString() : '')
      .transition()
      .duration(500)
      .attr('transform', d => `translate(${this.timeline.scale(d.date)}, ${this.scaleY1(d.cum)-8})`)

      //otherChartArea Bar Chart
      let otherChartArea = this.svg
      .append('g')
      .attr('transform', `translate(${this.Canvas.CanvasChartX+6}, ${this.Canvas.CanvasChartY})`)

      // bar-chart
      otherChartArea
      .append('g')
      .attr('transform', `translate(0, -0.2)`)
      .selectAll()
      .data(this.tickValuesWKA)
      .enter()
      .append('line')
      .attr('stroke', '#4FC3F7')
      .attr('stroke-width', 10).attr('opacity', 1)
      .attr('x1', d => this.timeline.scale(d.date))
      .attr('x2', d => this.timeline.scale(d.date))
      .attr('y1', this.scaleY2(0))
      .attr('y2', this.scaleY2(0))
      .transition()
      .duration(500)
      .attr('y1', this.scaleY2(0))
      .attr('y2', d => this.scaleY2(d.week))

      // bar-chart values
      otherChartArea
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .selectAll()
      .data(this.tickValuesWKA)
      .enter()
      .append('text')
      .attr('transform', d => `translate(${this.timeline.scale(d.date)}, ${this.scaleY2(0)})`)
      .attr('font-size', 9)
      .attr('text-anchor','middle')
      .attr('alignment-baseline','start')
      .attr('fill','#039BE5')
      .text(d => d.week.toLocaleString() > 0 ? d.week.toLocaleString() : '')
      .transition()
      .duration(500)
      .attr('transform', d => `translate(${this.timeline.scale(d.date)}, ${this.scaleY2(d.week)-2})`)

      // line-chart
      otherChartArea
      .append('g')
      .attr('transform', `translate(-6, 0)`)
      .append('path')
      .attr('d', this.lineZero(this.tickValuesCumA))
      .style('stroke', '#0288D1').style('stroke-width', 1.5).attr('opacity', .7)
      .style('fill', 'none')
      .transition()
      .duration(500)
      .attr('d', this.lineFunc(this.tickValuesCumA))

      // line-chart markup
      otherChartArea
      .append('g')
      .attr('transform', `translate(-6, 0)`)
      .selectAll()
      .data(this.tickValuesCumA)
      .enter()
      .append('circle')
      .attr('fill', '#fff')
      .attr('stroke', '#0288D1')
      .attr('stroke-width', 1)
      .attr('cx', d => this.timeline.scale(d.date))
      .attr('cy', this.axisYHeight)
      .attr('r', 3)
      .transition()
      .duration(500)
      .attr('cy', d => this.scaleY1(d.cum))
      

      // Draw the average (Value / Note / Line)
      otherChartArea
      .append('g')
      .attr('transform', `translate(-6, 0)`)
      .selectAll()
      .data(this.tickValuesCumA)
      .enter()
      .append('text')
      .attr('transform', d => `translate(${this.timeline.scale(d.date)}, ${this.scaleY1(0)})`)
      .attr('font-size', 9)
      .attr('text-anchor','middle')
      .attr('alignment-baseline','start')
      .attr('fill','#01579B')
      .text(d => d.cum.toLocaleString() > 0 ? d.cum.toLocaleString() : '')
      .transition()
      .duration(500)
      .attr('transform', d => `translate(${this.timeline.scale(d.date)}, ${this.scaleY1(d.cum)-8})`)
    },
    Draw_Charts() {
      let sChartITR = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0.5)`)

      if (!this.dataSet) {
        sChartITR
        .append('text')
        .attr('x', 150)
        .attr('y', 120).style('font-size',16)
        .style('font-family','roboto')
        .attr('text-anchor', 'middle')
        .attr('fill', '#bcbcbc')
        .text('Not Applicable' )
        return 
      }

      // SummaryTable_01
      // this.SummaryTable_04(sChartITR, this.style) // ---------------------------> 
    },
  }
}