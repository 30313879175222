// -------------------------------------------------------------------------------------- Global
import { SafeIdMixin } from '../../../../../../includes/mixins/safeid.mixin'                // ATAGlance Framework Globals
import gmx_Controller from '../../../../../../includes/mixins/Controller.mixin'       // SVG Control
// Global Drawings
import gmx_Canvas from '../../../../../../includes/mixins/Draw_Canvas.mixin'
import gmx_Texts from '../../../../../../includes/mixins/Text_Draw.mixin'
// Global Functions
import gmx_Miscellaneous from '../../../../../../includes/mixins/Miscellaneous.mixins'
import gmx_Movable from '../../../../../../includes/mixins/Movable.mixin'
import gmx_MovablePopup from '../../../../../../includes/mixins/MovablePopup.mixin'
import gmx_ScrollableHorizon from '../../../../../../includes/mixins/ScrollableHorizon.mixin'
// -------------------------------------------------------------------------------------- Local
import lmx_Custom_BarCharts from     '../../../../include/ChartLibrary/Custom_BarCharts.mixin'
import lmx_Cht_Charts_Pie from           '../../../../include/ChartLibrary/Charts_Pie.mixin'

// Locally declared common Data-Props & SVG Drawing Support
import lmx_ReactiveEvents from './Realtime_Monitor.mixin' // Reactive Events
import lmx_Declares from './Declares.mixin'
import lmx_DataStyles from './DataStyles.mixin'
import lmx_Backgound from './Draw_Backgound.mixin'
import lmx_Blocks from './Draw_Blocks.mixin'
import lmx_Floats from './Draw_Floats.mixin'
import lmx_Information from './Draw_Information.mixin'
import lmx_BlkPopup from './Draw_Blk_Popup.mixin'
import lmx_PDF from './Event_PDF_mixin'


export default {
  mixins: [
    SafeIdMixin,
    gmx_Controller,
    gmx_Canvas,
    gmx_Texts,
    gmx_Miscellaneous,
    gmx_Movable,
    gmx_MovablePopup,
    gmx_ScrollableHorizon,

    lmx_PDF,
    lmx_Cht_Charts_Pie,
    lmx_Custom_BarCharts, 
    lmx_DataStyles,
    lmx_ReactiveEvents,
    lmx_Declares,
    lmx_Backgound,
    lmx_Blocks,
    lmx_Floats,
    lmx_Information,
    lmx_BlkPopup,
  ],
}
