import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({

    props:[
      // {key:'AREA', head1: 'Area', x: 50, tx: 40, cy: 0, textanchor: 'start'},
      // {key:'TOTAL', head1: 'Total', x: 200, tx: 200, cy: 0},
      // {key:'FAB', head1: 'Fabricated', x: 260, tx: 260, cy: 0},
      // {key:'DEL', head1: 'Delivered', x: 320, tx: 320, cy: 0},
      // {key:'INST', head1: 'Installed', x: 380, tx: 380, cy: 0},
      // {key:'REM', head1: 'Remained', x: 440,  tx: 440, cy: 0},
      {key:'AREA', head1: 'Area',     x: 40,  tx: 30, cy: 0, textanchor: 'start'},
      {key:'TOTAL', head1: 'Total',   x: 110, tx: 110, cy: 0},
      {key:'FAB', head1: 'Fabricated',x: 170, tx: 170, cy: 0},
      {key:'PAT', head1: 'Painted',x: 230, tx: 230, cy: 0},
      {key:'INST', head1: 'Erected',x: 290, tx: 290, cy: 0, color: 'green'},
      {key:'COM', head1: 'Complete', x: 350, tx: 350, cy: 0, color: 'green'},
      {key:'REM', head1: 'Remain',  x: 410, tx: 410, cy: 0, color: 'red'},
    ],
    width: 460,//480
    rowHeight: 18,
    
    scrollProps: {
      w: 460,
      h: 240,
      g: 5,
      bar: {
        align : 'right',
        gap   : 3,
        radius: 2,
        width : 4,
        fill  : '#9E9E9E',
        stroke: '#9E9E9E',
      }
    }
  }),
  
  methods: {
    drawChart() {
      let PreOutfitting = this.svg
      .append('g')
      .attr('transform', `translate(0, 20.5)`)

      PreOutfitting
      .append('rect')
      .attr('x',20).attr('y', 0).attr('width',this.width - 20).attr('height',25).attr('stroke-width', 0).attr('fill', '#F4F5F5').attr('opacity', 0.4)

      PreOutfitting.append('line').attr('x1',20).attr('y1',1).attr('x2',this.width).attr('y2',1).attr('stroke-width', 0.2).attr('stroke', '#000')
      PreOutfitting.append('line').attr('x1',20).attr('y1',25).attr('x2',this.width).attr('y2',25).attr('stroke-width', 0.2).attr('stroke', '#000')

      // PreOutfitting
      // .append('rect').attr('class', 'background_bar__')
      // .attr('x', 20).attr('y', 25).attr('rx', 3).attr('ry', 3).attr('width', this.width - 20).attr('height', 20).attr('fill', '#eee').attr('opacity', '0.7')

      this.setScroll(PreOutfitting, this.scrollProps)

      // header
      PreOutfitting
        .append('g')
        .attr('transform', `translate(0, 15)`)
        .selectAll('text')
        .data(this.props)
        .enter()
        .append('text')
        .attr('x', d => d.x)
        .attr('y', 2)
        .style('font-family','roboto')
        .style('font-size',10)
        .attr('text-anchor', 'middle')
        .attr('fill', '#333')
        .text(d => d.head1)

      let row_group = PreOutfitting
        .append('g')
        .attr('class', '_row_data_group')
        .attr('transform', `translate(0, 40)`)

      let line_group = PreOutfitting
        .append('g')
        .attr('class', '_row_line_group')
        .attr('transform', `translate(0, 48)`)

      this.DataItems.forEach((row, r) => {
        let keys = Object.keys(row)

        keys.forEach(key => {
          let idx = this.props.findIndex(e => e.key === key)
          row_group
            .append('text')
            .text(row[key])
            .attr('x', (d,i) => this.props[idx].tx)
            .attr('y', () => r * 20)
            .attr('text-anchor', (d, i) =>  this.props[idx].textanchor ? this.props[idx].textanchor : 'middle' )
            .style('font-family','roboto')
            .style('font-size',10)
            .style('font-weight', () => row.AREA === 'Total' ? 'bold' : 'normal')
            .attr('fill', (d, i) => this.props[idx].color && row[key] !== 0 ? this.props[idx].color : '#757575' )

            // // Mask  tr mouseover change color 
            // let mask = row_group.append('rect').attr('class', 'group_mask__')
            // .attr('x', 20).attr('y', r*20).attr('rx', 3).attr('ry', 3).attr('width', this.width - 20).attr('height', 20).attr('fill', 'Transparent')

            // mask.on('mouseover', () => {
            //   mask.style('cursor', 'pointer')
            //   d3.select('.background_bar__')
            //   .attr('x', 20)
            //   .attr('y', r*20+25)
            // })

            line_group
            .append('line')
            .attr('x1',20).attr('y1', r * 20)
            .attr('x2', this.width).attr('y2', r * 20)
            .attr('stroke-width', 0.2).attr('stroke', '#E0E0E0')
        })
      })
    }
  }
}
