import * as d3 from 'd3'
// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  data:() => ({
    thead: [
      { key: 'NO', html: 'No', row: 1 },
      { key: 'INTERFACE_NO', html: 'Interface No.', row: 1 },
      { key: 'INTERFACE_DESCR', html: 'Interface Description', row: 1 },
      { key: 'IG_NO', html: 'Interface Gate(IG)', row: 1 },
      { key: 'SUPPLIER_OWNER', html: 'Supplier Owner', row: 1 },
      { key: 'REQUESTER_OWNER', html: 'Requster Owner', row: 1 },
      { key: 'PRIORITY', html: 'Priority', row: 1 },
      { key: 'STATUS', html: 'Status', row: 1 },
    ],
    theadrows: 1,
    tbody: [
      
      { key: 'NO', w: 35, align: 'center'},
      { key: 'INTERFACE_NO', w: 110,align: 'center'},
      { key: 'INTERFACE_DESCR', w: 190, align: 'left'},
      { key: 'IG_NO', w: 130, align: 'center'},
      { key: 'SUPPLIER_OWNER', w: 110, align: 'center'},//, type: 'float', 
      { key: 'REQUESTER_OWNER', w: 100, align: 'center'},
      { key: 'PRIORITY', w: 60, align: 'center'},
      { key: 'STATUS', w: 60, align: 'center'},
    ],
    width: 770,
    statusprops:[
      { status: 'New'    ,color:'#03A9F4',border:'#9E9E9E'},//Teal2
      { status: 'Deleted'    ,color:'#E0E0E0',border:'#9E9E9E'},//DeepOrange3
      { status: 'In Progress'    ,color:'#FFCA28',border:'#388E3C'},//Amber4
      { status: 'Delivered' ,color:'#D4E157',border:'#D32F2F'},//Lime4
      { status: 'Closed' ,color:'#4DD0E1',border:'#D32F2F'},//Cyan3
      { status: 'Rejected' ,color:'#EF5350',border:'#D32F2F'},//Red4
      { status: 'Resubmitted' ,color:'#4CAF50',border:'#D32F2F'},//Green5
      { status: 'Overdue Ip' ,color:'#1E88E5',border:'#D32F2F'},//Blue6
      { status: 'Critical Ip' ,color:'#7986CB',border:'#D32F2F'}//Indigo3
    ],
    
    scrollProps: {
      w: 796,
      h: 980,
      g: 0,
      bar: {
        align : 'right',
        gap   : 3,
        radius: 2,
        width : 4,
        fill  : '#1e88e5',
        stroke: '#1e88e5',
      }
    },
    selectedStartDate: null,
    selectedStartColumn: null,
    selectedFinishDate: null,
    selectedFinishColumn: null,
    filteredList: null
  }),
  methods: {
    Draw_Charts() {
      let svg = this.svg

      this.filteredList = this.DataItems

      let list = this.filteredList

      let group = svg.append('g').attr('class', 'testpack_table_group__')
      let table_group = group.append('g').attr('transform', `translate(0.5, 0)`)
      
      // Set Scroll
      // this.setScroll(table_group, this.scrollProps)
      let y_ = this.DataItems.length * 18 + 40 //th 길이까지 더해야한다

      let table_obj = table_group.append('foreignObject')
        .attr('y',0)
        .attr('width', '100%')
        .attr('height', `${y_}px`)
        .append('xhtml:body')
        .style('position', 'fixed')

      const reducer = (previousValue, currentValue) => previousValue + currentValue
      let widths = this.tbody.map(d => d.w)
      let sumWidth = widths.reduce(reducer)

      let table = table_obj.append('table')
      .attr('id', 'commodity_summary_table_')
      .style('border-collapse', 'collapse')
      .style('font-size', '10px')
      .style('width', `${sumWidth}px`)
      .style('table-layout', 'fixed')

      let colgroup = table.append('colgroup')
      this.tbody.forEach(k => {
        colgroup.append('col').style('width', k.w + 'px')
      })

      let thead = table.append('thead')
      for (let r = 1; r <= this.theadrows; r++) {
        let row = this.thead.filter(d=> d.row === r)
        let tr = thead.append('tr')

        row.forEach(item => {
          tr.append('th')
          .attr('class', 'ogm_table_group__th')
          .attr('rowspan', () => item.rowspan ? item.rowspan : '')
          .attr('colspan', () => item.colspan ? item.colspan : '')
          .style('height', '20px')
          .style('padding', '3px')
          .style('font-size', '11px')
          .style('background', item.color ? item.color : '#EEEEEE')
          .html(item.html)
        })        
      }

      let tbodykeys = this.tbody
      
      // console.log(list)
      let tbody = table.append('tbody').attr('id', 'testpack_summay_tbody_')
      let addClass = ''

      for (let r = 0; r < list.length; r++) {
        let tr = tbody.append('tr')
        tr.on('mouseover', () => tr.style('background-color', '#E1F5FE' ))
        tr.on('mouseout', () => tr.style('background-color', 'Transparent' ))
        tbodykeys.forEach((k, i) => {

          let td = tr.append('td')
            .style('border', 'solid 0.1px #eee')
            .style('height', '18px')
            .style('text-align', k.align ? k.align : 'center')
            .style('color', '#000000')
            .style('padding', '0 3px 0 3px')

          if(i === 3) {
            td.style('background-color', '#E0E0E0' )
            .style('white-space', 'nowrap')
              .style('overflow', 'hidden')
              .style('text-overflow', 'ellipsis')
              .attr('title', list[r][k.key])
          }
          if(i === 2) {
            td.style('white-space', 'nowrap')
              .style('overflow', 'hidden')
              .style('text-overflow', 'ellipsis')
              .attr('title', list[r][k.key])
          }
          if(i === 7) {
            td.style('background-color', list[r][k.key] == 'Closed' ? '#4CAF50' : list[r][k.key] == 'Deleted' ? '#E0E0E0' : list[r][k.key] == 'In Progress' ? '#FFEE58': list[r][k.key] == 'Delivered' ? '#FFCA28': list[r][k.key] == 'Rejected' ? '#EF5350': list[r][k.key] == 'Resubmitted' ? '#4DD0E1' : list[r][k.key] == 'Overdue IP' ? '#E57373': list[r][k.key] == 'Critical IP' ? '#7986CB' :  '#03A9F4' )
          }

          // if(i === 2) {
          //   // Total
          //   td.style('color', '#9E9E9E')
          //   .style('cursor', 'pointer')
          //   .on('click', () => {
          //     // let data = {CATEGORY : value_.CATEGORY, SUBSYSTEM: this.FilterValues.SUBSYSTEM, LOOPTEST: 'Y', STATUS_CODE:'C'}
          //     // let request_ = this.getRequestColumnProps('status', data, this.ColumnProps)
          //     // this.$emit('request-action', request_)
    
          //     let request_ = {
          //       dataType: 'row',
          //       action: {
          //         type: 'direct',
          //         target: 'slide-modal',
          //         component: 'ServiceDatatable',
          //         id: 255,
          //         no: ''
          //       },
          //       filters     : {SUBSYSTEM: this.FilterValues.SUBSYSTEM, CATEGORY: category, KSG_PHASE: ksg , QCF: list[r].QCF},
          //       iFilters    : {
          //         filterString : '',
          //         inputFilter  : ''
          //       }
          //     }
          //     if (list[r].GENERAL_DESIGN_SCOPE) {
          //       request_.filters.GENERAL_DESIGN_SCOPE = list[r].GENERAL_DESIGN_SCOPE
          //       console.log(list[r].GENERAL_DESIGN_SCOPE)
          //     }
          //     this.$emit('request-action', request_)
          //   })
          // }
          // if(i === 3) {
          //   // Act
          //   td.style('color', '#4CAF50')
          //   .style('cursor', 'pointer')
          //   .on('click', () => {
          //     let request_ = {
          //       dataType: 'row',
          //       action: {
          //         type: 'direct',
          //         target: 'slide-modal',
          //         component: 'ServiceDatatable',
          //         id: 255,
          //         no: ''
          //       },
          //       filters     : {SUBSYSTEM: this.FilterValues.SUBSYSTEM, CATEGORY: category, KSG_PHASE: ksg , QCF: list[r].QCF, STATUS_CODE: 'C'},
          //       iFilters    : {
          //         filterString : '',
          //         inputFilter  : ''
          //       }
          //     }
          //     if (list[r].GENERAL_DESIGN_SCOPE) {
          //       request_.filters.GENERAL_DESIGN_SCOPE = list[r].GENERAL_DESIGN_SCOPE
          //       console.log(list[r].GENERAL_DESIGN_SCOPE)
          //     }
          //     this.$emit('request-action', request_)
          //   })
          // }

          // if(i === 4) {
          //   // Remain
          //   td.style('color', '#F44336')
          //   .style('cursor', 'pointer')
          //   .on('click', () => {
          //     let request_ = {
          //       dataType: 'row',
          //       action: {
          //         type: 'direct',
          //         target: 'slide-modal',
          //         component: 'ServiceDatatable',
          //         id: 255,
          //         no: ''
          //       },
          //       filters     : {SUBSYSTEM: this.FilterValues.SUBSYSTEM, CATEGORY: category, KSG_PHASE: ksg , QCF: list[r].QCF, STATUS_CODE: 'N'},
          //       iFilters    : {
          //         filterString : '',
          //         inputFilter  : ''
          //       }
          //     }
          //     if (list[r].GENERAL_DESIGN_SCOPE) {
          //       request_.filters.GENERAL_DESIGN_SCOPE = list[r].GENERAL_DESIGN_SCOPE
          //       console.log(list[r].GENERAL_DESIGN_SCOPE)
          //     }
          //     this.$emit('request-action', request_)
          //   })
          // }

          if(k.key.includes('PROG')) {
            addClass = ''
            td
            .style('position', 'relative')
            .style('color', list[r][k.key] < 0 ? 'red' : list[r][k.key] === 100 ? '#fff' : addClass == 'caution' ? 'red' : '#000')
            .style('z-index', '1')
            .html(list[r][k.key] == null ? 0+'%' :list[r][k.key]+'%')

            td.append('div')
            .style('position', 'absolute')
            .style('left', 0)
            .style('top', 0)
            .style('width', list[r][k.key] > 0 ? list[r][k.key] + '%' : 0)
            .style('height', '17px')
            .style('background-color', list[r][k.key] < 100 ? k.key.includes('PLAN') ? '#E0E0E0' : '#FFCA28' : '#4CAF50')
            .style('z-index', '-1')
          }
          else {
            if (k.type == 'float') {
              let regexp = /\B(?=(\d{3})+(?!\d))/g
              if (isNaN(Math.floor(list[r][k.key]))) td.html('')
              else td.html(Math.round(Math.floor(list[r][k.key] * 100) / 100).toString().replace(regexp, ','))
            } else td.html(list[r][k.key])
          }
        })
      }
    },
  }
}
