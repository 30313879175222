import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({
    pieData: [
      {title: 'QVD A1',   column: 'A1',   x: 50,  progress: 0, total: 0, remain:0, fColor: '#BDBCBC', tColor: '#818181', link: 'Y', },
      {title: 'Walkdown', column: 'WD',   x: 170, progress: 0, total: 0, remain:0, fColor: '#F7BACF', tColor: '#FF75A5', link: '', },
      {title: 'DAC',      column: 'DAC',  x: 260, progress: 0, total: 0, remain:0, fColor: '#83D2F5', tColor: '#44A9DF', link: '', },
      {title: 'FMC',      column: 'FMC',  x: 350, progress: 0, total: 0, remain:0, fColor: '#CDDC37', tColor: '#8BC248', link: '', },
      {title: 'QVD B1',   column: 'B1',   x: 470, progress: 0, total: 0, remain:0, fColor: '#FFDA00', tColor: '#FAAA18', link: 'Y', },
      {title: 'QVD D1',   column: 'D1',   x: 590, progress: 0, total: 0, remain:0, fColor: '#82B22E', tColor: '#4CAE4E', link: 'Y', },
    ],
  }),


  methods: {

    draw_ChartsOverall() {

      let overall = this.svg
      .append('g')
      .attr('transform', `translate(650, 650)`)

      overall
      .append('line')
      .attr('x1', 50).attr('y1', 0)
      .attr('x2', 0).attr('y2', 0)
      .style('stroke', '#E0E0DF').attr('stroke-width', 2)
      .transition().duration(1000).attr('x2',670)
       
      this.pieData.forEach((d,i) => {

        d.angleSize = 360
        d.angleStart = 0
        d.angleEnd = 360
        d.total = this.dataSet[this.target][`${d.column}_T`]
        d.completed = this.dataSet[this.target][`${d.column}_A`]
        d.remain = this.dataSet[this.target][`${d.column}_R`]
        d.progress = (this.dataSet[this.target][`${d.column}_A`] / this.dataSet[this.target][`${d.column}_T`]) * 100
        d.bColor = '#F4F5F5'
        d.stroke = 6
        d.Ra = 35

        // Draw Background Donut Chart
        let arc = d3.arc()
            .outerRadius(d => d.Ra)
            .innerRadius(d => d.Ra - d.stroke)
            .startAngle(d => d.angleStart * Math.PI / 180)
            .endAngle(d => d.endAngle || d.angleStart * Math.PI / 180)

        let donutChart = overall
          .append('g')
          .attr('transform', `translate(${d.x},${0})`)

          donutChart
          .append('circle')
          .attr('cx', 0).attr('cy', 0).attr('r', d.Ra-3)
          .attr('stroke', d.bColor).attr('stroke-width', d.stroke)
          .attr('fill', 'url(#lightGray)')

          donutChart
          .datum(d)
          .append('path')
          .attr('id', `donut_${i}`)
          .attr('d', arc)
          .attr('fill', d.fColor)
          .attr('opacity', 0.5)
          .transition()
          .duration(750)
          .attrTween('d',(d) => {
            let endAngle_ = ((d.angleSize * (d.progress/100)) + d.angleStart) * Math.PI / 180
            let interpolate = d3.interpolate(d.angleStart * Math.PI / 180, endAngle_)
            return function(t) {
              d.endAngle = interpolate(t)
              return arc(d);
            }
          })

        overall
        .append('text')
        .attr('id', `${this.localId}_progress_${i}`)
        .attr('x', d.x).attr('y', 1)
        .style('font-family', 'roboto').style('font-size', 15).style('fill', d.tColor).attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text(`${Number(d.progress).toFixed(1)}%`)

        overall
        .append('text')
        .attr('x', d.x).attr('y', 50)
        .style('font-family', 'roboto').style('font-size', 13).style('fill', '#333').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text(d.title)
        
        overall // Total
        .append('text')
        .attr('id', `${this.localId}_total_${i}`)
        .attr('transform', `translate(${d.x - 5}, 65)`)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text(d.total)
        .style('cursor', 'pointer')
        .on('mouseover', () => {
          d3.select(`#${this.localId}_total_${i}`).transition().duration(100).attr('transform', `translate(${d.x - 5 + (this.getNodeElValue(`#${this.localId}_total_${i}`, 'width')/10)}, 66) scale(1.2)`)
        })
        .on('mouseout', () => {
          d3.select(`#${this.localId}_total_${i}`).transition().duration(100).attr('transform', `translate(${d.x - 5}, 65)`)
        })
        .on('click', () => {
          this.draw_ChartsActions(d.column, 'total')
        })

        overall
        .append('text')
        .attr('x', d.x).attr('y', 65)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text('/')

        overall
        .append('text')
        .attr('id', `${this.localId}_remain_${i}`)
        .attr('transform', `translate(${d.x + 5}, 65)`)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text(d.remain)
        .style('cursor', 'pointer')
        .on('mouseover', () => {
          d3.select(`#${this.localId}_remain_${i}`).transition().duration(100).attr('transform', `translate(${d.x + 5 - (this.getNodeElValue(`#${this.localId}_remain_${i}`, 'width')/10)}, 66) scale(1.2)`)
        })
        .on('mouseout', () => {
          d3.select(`#${this.localId}_remain_${i}`).transition().duration(100).attr('transform', `translate(${d.x + 5}, 65)`)
        })
        .on('click', () => {
          this.draw_ChartsActions(d.column, 'remain')
        })
      })

      
      let hydroTotal = this.dataSet[this.target]['HT_T']
      let hydroRemain = this.dataSet[this.target]['HT_R']
      let hydroProg = this.dataSet[this.target]['HT_PG']
      let loopTotal = this.dataSet[this.target]['ICS_001_T'] + this.dataSet[this.target]['ICS_002_T'] + this.dataSet[this.target]['ICS_005_T'] + this.dataSet[this.target]['ICS_008_T']
      let loopActual = this.dataSet[this.target]['ICS_001_A'] + this.dataSet[this.target]['ICS_002_A'] + this.dataSet[this.target]['ICS_005_A'] + this.dataSet[this.target]['ICS_008_A']
      let loopRemain = loopTotal - loopActual
      let loopProg = (loopActual / loopTotal) * 100

      // Piping Test Package
      overall
      .append('text')
      .attr('x', this.pieData[0].x - this.pieData[0].Ra).attr('y', 100)
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#333').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(`Test Package`)

      overall
      .append('text')
      .attr('id', 'hydroTotal_')
      .attr('x', this.pieData[0].x - this.pieData[0].Ra).attr('y', 130)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(hydroTotal)

      overall
      .append('text')
      .attr('id', 'hydroRemain_')
      .attr('x', this.pieData[0].x - this.pieData[0].Ra + 100).attr('y', 130)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
      .text(hydroRemain)

      overall
      .append('rect')
      .attr('x', this.pieData[0].x - this.pieData[0].Ra).attr('y', 110).attr('width', 100).attr('height', 10).style('fill', '#F4F5F5')
      .attr('stroke', '#E0E0DF').attr('stroke-width', 0.5).style('stroke-opacity', 0.5)

      overall
      .append('rect')
      .attr('id', `hydroBar_`)
      .attr('x', this.pieData[0].x - this.pieData[0].Ra).attr('y', 110).attr('width', 0).attr('height', 10).style('fill', '#B4E3FA')
      .attr('stroke', '#44A9DF').attr('stroke-width', 0.5).style('stroke-opacity', 0.5)
      .transition().duration(700).attr('width', hydroProg)

      overall
      .append('text')
      .attr('id', `hydroProg_`)
      .attr('y', 111)
      .attr('x', () => {if (hydroProg > 20) return this.pieData[0].x - this.pieData[0].Ra + hydroProg - 1; else return this.pieData[0].x - this.pieData[0].Ra + hydroProg + 1})
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#333').attr('alignment-baseline', 'hanging')
      .attr('text-anchor', () => {if (hydroProg > 20) return 'end'; else return 'start'})
      .text(`${Number(hydroProg).toFixed(1)}%`)


      // E&I Loop Test
      overall
      .append('text')
      .attr('x', this.pieData[5].x - this.pieData[0].Ra).attr('y', 100)
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#333').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(`Loop Test`)

      overall
      .append('text')
      .attr('id', `loopTotal_`)
      .attr('x', this.pieData[5].x - this.pieData[0].Ra).attr('y', 130)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(loopTotal)

      overall
      .append('text')
      .attr('id', `loopRemain_`)
      .attr('x', this.pieData[5].x - this.pieData[0].Ra + 100).attr('y', 130)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
      .text(loopRemain)
      
      overall
      .append('rect')
      .attr('x', this.pieData[5].x - this.pieData[0].Ra).attr('y', 110).attr('width', 100).attr('height', 10).style('fill', '#F4F5F5')
      .attr('stroke', '#E0E0DF').attr('stroke-width', 0.5).style('stroke-opacity', 0.5)

      overall
      .append('rect')
      .attr('id', `loopBar_`)
      .attr('x', this.pieData[5].x - this.pieData[0].Ra).attr('y', 110).attr('width', 0).attr('height', 10).style('fill', '#FEED57')
      .attr('stroke', '#FFDA00').attr('stroke-width', 0.5).style('stroke-opacity', 0.5)
      .transition().duration(700).attr('width', loopProg)

      overall
      .append('text')
      .attr('id', `loopProg_`)
      .attr('y', 111)
      .attr('x', () => {if (loopProg > 20) return this.pieData[5].x - this.pieData[0].Ra + loopProg - 1; else return this.pieData[5].x - this.pieData[0].Ra + loopProg + 1})
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#333').attr('alignment-baseline', 'hanging')
      .attr('text-anchor', () => {if (loopProg > 20) return 'end'; else return 'start'})
      .text(`${Number(loopProg).toFixed(1)}%`)

      // ISO SVG
      overall
      .append('image')
      .attr('id', 'svgISO')
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TCO/ISO/${this.dataSet[this.target].MOD}.svg`)
      .attr('x', 670).attr('y', -120).attr('width', 150).attr('height', 250)

      overall
      .append('line')
      .attr('x1', 670).attr('y1', -100).attr('x2', 670).attr('y2', 100).style('stroke', '#E0E0DF').attr('stroke-width', 1)

      overall
      .append('line')
      .attr('x1', 670).attr('y1', -100).attr('x2', 675).attr('y2', -100).style('stroke', '#E0E0DF').attr('stroke-width', 1)
      overall
      .append('line')
      .attr('x1', 670).attr('y1', 100).attr('x2', 675).attr('y2', 100).style('stroke', '#E0E0DF').attr('stroke-width', 1)
    },
  }
}
