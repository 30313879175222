import * as d3 from 'd3'
// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {
    Draw_Content() {

      if(this.ColumnProps.length > 0) var props = this.ColumnProps.find(props_ => props_.value == 'comprops').props
      else props = this.defaultset

      let svg = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0.5)`)

      // svg
      // .append('image')
      // .attr('transform', `translate(0, 50)`)
      // .attr('opacity', 1)
      // .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/China1/Images/${props.imageName}.svg`)

      let BtnWrap = this.svg
      .append('g')
      .attr('id', 'btn_wrap')
      .attr('transform', `translate(1400,10)`).style('font-family', 'roboto')
      .style('cursor', 'pointer')
      .on('mouseover', function() {
        d3.select(this).transition().duration(100).style('opacity', 0.5)
      })
      .on('mouseout', function(){
        d3.select(this).transition().duration(100).style('opacity', 1)
      })
      .on('click', () => {
        let request_ = this.getRequestColumnProps('single_file_download', null, this.ColumnProps)
        this.$emit('request-action', request_)
      })

      BtnWrap
      .append('rect').attr('rx', 3).attr('x', 0).attr('y', 0)
      .attr('width', 150).attr('height', 24)
      .attr('fill','#FFAB00')
      
      BtnWrap
      .append('text')
      .attr('transform', `translate(${(75)}, ${12})`)
      .style('font-size', 14).style('fill','#ffffff').attr('text-anchor','middle').attr('alignment-baseline', 'middle')
      .text('⇩ Download Original')
        
    }
  }
}