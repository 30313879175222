import * as d3 from 'd3'

export default {
  methods: {
    
    setEvent_Topside() {
      // Goto_Topside Module
      // ---------------------------------------------------------------------------------------------------------------------
      this.Goto_TS_Level = (selection, _mask, _level, _mod) => {
        selection
        .on('mouseover', () => {
          if(this.stage != this.STG_Lv4_Block_Division) return
          d3.select(_mask).transition().style('opacity', 0.5)
        })
        .on('mouseout', () => {
          if(this.stage != this.STG_Lv4_Block_Division) return
          d3.select(_mask).transition().style('opacity', 0)
        })
        .on('click', () => {
          if(this.stage != this.STG_Lv4_Block_Division) return
          // Remove SVG
          this.removeSvg(this.CANVAS, 1000)

          // Hidden
          this.hiddenGroupID('#Menu_Back', 0, 10)
          d3.selectAll(`[id*="Mask_${_mod}"]`).each((_, i, a) => {
            this.hiddenGroupID(`#${d3.select(a[i]).attr('id')}`, 0, 10)
          })
          
          // Explode
          setTimeout(() => {
            this.TOPSIDES.forEach(d => {
              if(d.BLK == _level) {
                d3.select(`#Blk_${_level}`).transition().duration(1000).attr('transform', `translate(${d.x5}, ${d.y5}) scale(${1})`)
              } else {
                this.hiddenGroupID(`#Blk_${d.BLK}`, 0, 500)
              }
            })
          }, 700);
   
          setTimeout(() => {
            this.loadSvg(0, 0, `/TORTUE/Equipment/SVG/${_level}.svg`).then((innerSvg) => {
              this.Draw_EQ_Code(innerSvg, _level)
              this.Draw_EQ_Tag(innerSvg, _level)
              // this.Draw_EQ_Block(innerSvg, _level)
              this.innerSvg = innerSvg
           })
            this.Lev1 = 'Topside'
            this.Lev2 = 'Module'
            this.Lev3 = _level
            this.Lev4 = _mod
            this.stage = this.STG_Division_Lv3
            let dStage = {
              stage: this.STG_Division_Lv3,
              mask : _mod,
              level: _level
             }
            this.selectedItems.push(dStage)
            this.fncName = 'Goto_TS_Level'
            this.StageCode() // AREA / STAGE
            this.displayGroupID(`#Menu_Back`, 0, 200, 1)

            // ### Call Summary ###
            this.drawProgress(_level, false) 

            // Internal-Filter-Value for the common control
            this.inFilters.LV1 = 'TOPSIDES'
            this.inFilters.LV4 = _level                 
          }, 1500);
        })
      }
      
    },
  }
}