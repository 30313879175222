export default {
  data: () => ({
    dataSet: [],
    lineChartData: [],
    table: {
      display: 'Y',
      bottom: 500,
      dwarY:45,
      gapFromChart: 0,
      cellWidth: 0,
      labelX: -72,

      width:290, 
      hHeight: 20,
      lineHeight: 20,

      sColor:'#757575', 
      sWidth: 0.5, 
    },
    // ----------------------------------------------- AXIS
  Axis: {
    AxisXLineDisplay: 'Y',
    AxisXLineWeight: 0.5,
    AxisXLineColor: '#37474f',

    AxisXLevelDisplay: 'Y',
    AxisXLevelPosition: '',
    AxisXLevelSpace: 10,
    AxisXLevelFont: 'roboto',
    AxisXLevelStyle: 'regular',
    AxisXLevelSize: 11,
    AxisXLevelWeight: 500,
    AxisXLevelAutoColor: 'Y',
    AxisXLevelColor: '#37474f',
    AxisXLevelUnitSize: 11,
    AxisXLevelUnitFormat: '%',
    AxisXLevelDirection: 'Vertical',

    AxisYLineDisplay: 'Y',
    AxisYLineWeight: 0.5,
    AxisYLineColor: '#37474f',

    AxisYLevelDisplay: 'Y',
    AxisYLevelPosition: 'left',
    AxisYLevelSpace: 10,
    AxisYLevelFont: 'roboto',
    AxisYLevelStyle: 'regular',
    AxisYLevelSize: 11,
    AxisYLevelWeight: 500,
    AxisYLevelAutoColor: 'Y',
    AxisYLevelColor: '#37474f',
    AxisYLevelUnitSize: 11,
    AxisYLevelUnitFormat: '',

    AxisYBoundsAuto: 'Y',
    AxisYBoundsMax: 100,

    AxisYGridDisplay: 'Y',
    AxisYGridWeight: 0.5,
    AxisYGridColor: '#E0E0E0'
  },

  // ----------------------------------------------- TITLE
  Title: {
    TitleMainDisplay: 'Y',
    TitleMainText: 'Spool Installation',
    TitleMainX: 400,
    TitleMainY: 50,
    TitleMainFont: 'roboto',
    TitleMainStyle: 'regular',
    TitleMainSize: 24,
    TitleMainColor: '#44A9DF',
    TitleMainAlign: 'middle',

    TitleSubDisplay: 'Y',
    TitleSubText: 'MD2 Project',
    TitleSubX: 400,
    TitleSubY: 70,
    TitleSubFont: 'roboto',
    TitleSubStyle: 'regular',
    TitleSubSize: 12,
    TitleSubColor: '#BDBCBC',
    TitleSubAlign: 'middle',

    TitleExtraDisplay: 'N',
    TitleExtraText: 'Discipline',
    TitleExtraX: 400,
    TitleExtraY: 430,
    TitleExtraFont: 'roboto',
    TitleExtraStyle: 'regular',
    TitleExtraSize: 14,
    TitleExtraColor: 'gray',
    TitleExtraAlign: 'middle',

    TitleSqlDisplay: 'N',
    TitleSqlText: 'SQL Query...',
    TitleSqlQuery: 'SQL Query...',
    TitleSqlX: 400,
    TitleSqlY: 90,
    TitleSqlFont: 'roboto',
    TitleSqlStyle: 'regular',
    TitleSqlSize: 11,
    TitleSqlColor: 'pink',
    TitleSqlAlign: 'middle',

    TitleLineDisplay: 'N',
    TitleLineX: 30,
    TitleLineY: 30,
    TitleLineLength: 100,
    TitleLineWeight: 1,
    TitleLineColor: 'black'
  },

  // ----------------------------------------------- BAR
  Bar: {
    BarAutoSize: 'Y',
    BarThickness: 100,
    BarDistance: 20,
    BarRadius: 10, // Embossed only
    // BarColorType: "LinearA9",
    // BarColorSet: ["Gold", "Orange", "Pink", "Gray", "SkyBlue", "LightBlue", "YellowGreen", "Red", "Thistle", "Purple", "Indigo", "LightBlue", "SkyBlue", "SteelBlue", "YellowGreen","DarkGreen"],
    BarColorType: 'LinearA2',
    BarColorSet: ['#50BF8A', '#FFAB40', '#FF3D00', '#ED680E', '#D1B1CF', '#AED581', '#DCE775'],
    BarReflection: 'Y',

    BarBackDisplay: 'Y',
    BarBackColor: '#efefef',

    BarSeriesDisplay: 'Y',
    BarSeriesSpace: 5,
    BarSeriesFont: 'roboto',
    BarSeriesStyle: 'regular',
    BarSeriesSize: 12,
    BarSeriesAutoColor: 'Y',
    BarSeriesColor: '#757575',

    BarValueDisplay: 'Y',
    BarValueFont: 'roboto',
    BarValueStyle: 'regular',
    BarValueSize: 11,
    BarValueRound: 1,
    BarValueAutoColor: 'N',
    BarValueColor: '#ffffff',
    BarValuePosition: 'bottom',
    BarValueSpace: 7, // Tube only

    BarUnitDisplay: 'Y',
    BarUnitFormat: '%',
    BarUnitSize: 11,
    BarUnitColor: '#757575',

    BarSeriesPosition: 'above' 
  },

  // ----------------------------------------------- LEGENDS
  Legends: {
    LegendDisplay: 'Y',
    LegendX: 240,
    LegendY: -28,
    LegendDirection: 'Horizontal',
    LegendBasepoint: 'end',

    LegendTitleDisplay: 'Y',
    LegendTitleFont: 'roboto',
    LegendTitleStyle: 'regular',
    LegendTitleSize: 12,
    LegendTitleColor: '#FF5722',

    LegendBulletDisplay: 'Y',
    LegendBulletType: 'square',
    LegendBulletSize: 10,
    LegendBulletSpace: 5,

    LegendSeriesFont: 'roboto',
    LegendSeriesStyle: 'regular',
    LegendSeriesSize: 10,
    LegendSeriesColor: '#757575',
    LegendSeriesSpace: 10,

    LegendValueDisplay: 'Y',
    LegendValueSize: 10,
    LegendValueColor: '#757575',
    LegendValueRound: 1,

    LegendUnitDisplay: 'Y',
    LegendUnitFormat: '%',
    LegendUnitSize: 8,
    LegendUnitColor: '#757575',

    LegendLineSpace: 5
  }
  })
}