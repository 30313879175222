export default {
  data: () => ({
    attrs: [
      { 
        text: 'Plan',
        refColumn: 'ENG_PLAN_PROG',
        fColor: '#BBDEFB', 
        tColor: '#42A5F5',
      },
      { 
        text: 'Actual',
        refColumn: 'ENG_ACTUAL_PROG',
        fColor: '#FFCDD2',
        tColor: '#EF5350'
      },
      { 
        text: 'Plan',
        refColumn: 'PRO_PLAN_PROG',
        fColor: '#BBDEFB', 
        tColor: '#42A5F5'
      },
      { 
        text: 'Actual',
        refColumn: 'PRO_ACTUAL_PROG',
        fColor: '#FFCDD2', 
        tColor: '#EF5350'
      },
      { 
        text: 'Plan',
        refColumn: 'CON_PLAN_PROG',
        fColor: '#BBDEFB', 
        tColor: '#42A5F5'
      },
      { 
        text: 'Actual',
        refColumn: 'CON_ACTUAL_PROG',
        fColor: '#FFCDD2', 
        tColor: '#EF5350'
      },
    ],
    Status: [
      // { code: 'TOTAL'     , title: 'Total',  bColor: '#E0E0E0', tColor: '#424242', opacity: 0.60,},
      { text: 'DED',refColumn: 'ENG_DELTA', },
      { text: 'Procurement', refColumn: 'PRO_DELTA',},
      { text: 'Construction', refColumn: 'CON_DELTA',},
    ],
    elProps: {
      coordinates: [
        { STAGE: 'OverAll', TYPE: 'Area', DIRECT: null, LV1:"HCCP",LV2:"MAINPLANT", GOTO: 'Mainplant',  x: 250, y: 170 },
        { STAGE: 'OverAll', TYPE: 'Area', DIRECT: null, LV1:"HCCP",LV2:"OP"       , GOTO: 'Offplots'  ,  x: 900, y: 170 },

        { STAGE: 'Mainplant', PARENT:'OverAll', TYPE: 'Area', LV2: 'P3' , DIRECT: 'R', GOTO: 'P3', COLOR: '#FFEB3B', x: 1150, y:780 },
        { STAGE: 'Mainplant', PARENT:'OverAll', TYPE: 'Area', LV2: 'P1' , DIRECT: 'L', GOTO: 'P1', COLOR: '#E57373', x: 950, y: 300 },
        { STAGE: 'Mainplant', PARENT:'OverAll', TYPE: 'Area', LV2: 'P2' , DIRECT: 'L', GOTO: 'P2', COLOR: '#7E57C2', x: 600, y: 800 },

        { STAGE: 'Offplots', PARENT:'OverAll', TYPE: 'Area', LV2: 'MOF' , DIRECT: 'L', GOTO: 'P2', COLOR: '#DCE775'    , x: 914, y: 395 },
        { STAGE: 'Offplots', PARENT:'OverAll', TYPE: 'Area', LV2: 'JETTY' , DIRECT: 'L', GOTO: 'P2', COLOR: '#DCE775'  , x: 240, y: 838 },
        { STAGE: 'Offplots', PARENT:'OverAll', TYPE: 'Area', LV2: 'TERMINAL', DIRECT: 'L', GOTO: 'P2', COLOR: '#DCE775', x: 358, y: 620 },
        { STAGE: 'Offplots', PARENT:'OverAll', TYPE: 'Area', LV2: 'PIPELINE', DIRECT: 'L', GOTO: 'P2', COLOR: '#DCE775', x: 1220, y: 590 },
        { STAGE: 'Offplots', PARENT:'OverAll', TYPE: 'Area', LV2: 'MBZ' , DIRECT: 'L', GOTO: 'P2', COLOR: '#DCE775'  , x: 980, y: 926 },
        
        { STAGE: 'P1', PARENT:'Mainplant', TYPE: 'Area', DIRECT: 'L', LV2: 'P1',LV3: 'OR',GOTO: 'OR',COLOR: '#EF9A9A'        ,x: 1200,y: 250 },
        // { STAGE: 'P1', PARENT:'Mainplant', TYPE: 'Area', DIRECT: 'L', LV2: 'P1',LV3: 'DW',GOTO: 'DW',COLOR: '#E57373'        ,x: 300, y: 250 },
        // { STAGE: 'P1', PARENT:'Mainplant', TYPE: 'Area', DIRECT: 'L', LV2: 'P1',LV3: 'BOILER',GOTO: 'BOILER',COLOR: '#EF5350',x: 600, y: 250 },
        // { STAGE: 'P1', PARENT:'Mainplant', TYPE: 'Area', DIRECT: 'R', LV2: 'P1',LV3: 'BTX',GOTO: 'BTX',COLOR: '#F44336'     , x: 50,  y: 620 },
        // { STAGE: 'P1', PARENT:'Mainplant', TYPE: 'Area', DIRECT: 'R', LV2: 'P1',LV3: 'GHU',GOTO: 'GHU',COLOR: '#EF9A9A'     , x: 350, y: 730 },
        // { STAGE: 'P1', PARENT:'Mainplant', TYPE: 'Area', DIRECT: 'R', LV2: 'P1',LV3: 'DIB',GOTO: 'DIB',COLOR: '#EF5350'      ,x: 1000, y: 880 },
        // { STAGE: 'P1', PARENT:'Mainplant', TYPE: 'Area', DIRECT: 'R', LV2: 'P1',LV3: 'BD',GOTO: 'BD',COLOR: '#EF5350'       , x: 650,y: 870 },
        // { STAGE: 'P1', PARENT:'Mainplant', TYPE: 'Area', DIRECT: 'R', LV2: 'P1',LV3: 'FUR',GOTO: 'FUR',COLOR: '#EF5350'      ,x: 900,  y: 250 },

        { STAGE: 'P2', PARENT:'Mainplant', TYPE: 'Area', DIRECT: 'L', LV2: 'P2',LV3: 'GPPE',GOTO: 'GPPE',COLOR: '#9FA8DA', CODE: 'Area_P2', x: 1260,y: 270 },
        // { STAGE: 'P2', PARENT:'Mainplant', TYPE: 'Area', DIRECT: 'L', LV2: 'P2',LV3: 'LDPE',GOTO: 'LDPE',COLOR: '#7986CB', CODE: 'Area_P2', x: 500, y: 100 },
        // { STAGE: 'P2', PARENT:'Mainplant', TYPE: 'Area', DIRECT: 'L', LV2: 'P2',LV3: 'PP'  ,GOTO: 'PP'  ,COLOR: '#5C6BC0'  ,CODE: 'Area_P2',x: 900, y: 100 },
        // { STAGE: 'P2', PARENT:'Mainplant', TYPE: 'Area', DIRECT: 'R', LV2: 'P2',LV3: 'PBL' ,GOTO: 'PBL' ,COLOR: '#3F51B5' , CODE: 'Area_P2',x: 40 , y: 900 },
      
        { STAGE: 'P3', PARENT:'Mainplant', TYPE: 'Area', DIRECT: 'R', LV2: 'P3',LV3: 'WFS',GOTO: 'WFS',COLOR: '#EF5350'      ,x: 900,  y: 250 },
        // { STAGE: 'P3', PARENT:'Mainplant', TYPE: 'Area', DIRECT: 'R', LV2: 'P3',LV3: 'STF',GOTO: 'STF',COLOR: '#EF5350'      ,x: 900,  y: 250 },
        // { STAGE: 'P3', PARENT:'Mainplant', TYPE: 'Area', DIRECT: 'R', LV2: 'P3',LV3: 'MF',GOTO: 'MF',COLOR: '#EF5350'      ,x: 900,  y: 250 },
        // { STAGE: 'P3', PARENT:'Mainplant', TYPE: 'Area', DIRECT: 'R', LV2: 'P3',LV3: 'EPF',GOTO: 'EPF',COLOR: '#EF5350'      ,x: 900,  y: 250 },
      
      ],
    }
  })
}