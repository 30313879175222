// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  data:() => ({
    props:[
      {GWBS_LV3:"ME01",AREA_DESCR:"- Flash Gas Compression",x: 0, y: 0},
      {GWBS_LV3:"ME02",AREA_DESCR:"- Meg & Fuel Gas",x: 0, y: 0},
      {GWBS_LV3:"ME04",AREA_DESCR:"- Power Generation",x: 0, y: 0},
      {GWBS_LV3:"ME05",AREA_DESCR:"- E&I Building",x: 0, y: 0},
      {GWBS_LV3:"MW01",AREA_DESCR:"- Reception Facilities & Production Separation",x: 0, y: 0 },
      {GWBS_LV3:"MW02",AREA_DESCR:"- Hc Dewpointing & Condensate Stabilisation",x: 0, y: 0},
      {GWBS_LV3:"MW04",AREA_DESCR:"- Power Generation",x: 0, y: 6},
      {GWBS_LV3:"MW05",AREA_DESCR:"- Utility",x: 0, y: 6},
      {GWBS_LV3:"FL01",AREA_DESCR:"- Flare Stack",x: 50, y: 20},
      {GWBS_LV3:"KO01",AREA_DESCR:"- Flare KO Drum",x: 10, y: 40},
      {GWBS_LV3:"RCxx",AREA_DESCR:"- Topsides Piperack",x: 0, y: 30},      
      {GWBS_LV3:"IG90",AREA_DESCR:"- Integration",x: 5, y: 30},
    ],
    thead: [
      { key: 'DISC_DESCR', html: 'Discipline', row: 1,  },
      { key: 'ITEM', html: 'Item', row: 1,  },
      { key: 'UOM', html: 'UOM', row:1, },
      { key: 'TOTAL', html: 'Total', row: 1, },
      { key: 'COMPLETED', html: 'Actual', row: 1, },
      { key: 'REMAIN', html: 'Remain', row: 1, rowspan: 1, },
      { key: 'PROG', html: 'Prog', row: 1, },
    ],
    theadrows: 1,
    tbody: [
      { key: 'DISC_DESCR', w: 45 },
      { key: 'ITEM', w: 75, align: 'left'},
      { key: 'UOM', w: 20 },
      { key: 'TOTAL', w: 40,type: 'float', align: 'right'},
      { key: 'COMPLETED', w: 40,type: 'float', align: 'right'},
      { key: 'REMAIN', w: 40, type: 'float', align: 'right'},
      { key: 'PROG', w: 40},
    ],
    width: 300,

    selectedStartDate: null,
    selectedStartColumn: null,

    selectedFinishDate: null,
    selectedFinishColumn: null,

    filteredList: null
  }),
  methods: {
    Draw_Charts() {
      let svg = this.svg

      this.filteredList = this.DataItems

      let list = this.filteredList

      let this_ = this

      let gwbsLv3 = this.DataItems.map(d =>d.GWBS_LV3)[0]
      // console.log(gwbsLv3)
      let props = this.props.filter(d => d.GWBS_LV3 == gwbsLv3)[0]
      // console.log(props)
      let overallSummary = this.svg
      .append('g')
      .attr('class','moduleName')
      .attr('transform', `translate(0, 0)`)

      overallSummary
      .append('image')
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Modules/AreaDashboard/${gwbsLv3}.png`)
      .attr('transform', `translate(${props.x} ${props.y})scale(0.7) `)

      overallSummary
      .append('text')
      .attr('transform', `translate(120,22)`)
      .style('font-size', 16).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'start')
      .text(props.GWBS_LV3 == 'IG90' ? 'INTEGRATION' : props.GWBS_LV3)

      overallSummary
      .append('text')
      .attr('transform', `translate(${props.GWBS_LV3 == 'IG90' ? 228 : 172},22)`)
      .style('font-size', 12).style('font-family', 'roboto').attr('fill', '#039BE5').style('text-anchor', 'start')
      .text(props.AREA_DESCR)

      let group = svg.append('g').attr('class', 'ogm_table_group__')
      let table_group = group.append('g').attr('transform', `translate(120.5, 30)`)
      
      let table_obj = table_group.append('foreignObject')
        .attr('y',0)
        .attr('width', '100%')
        .attr('height', '300px')
        .append('xhtml:body')
        .style('position', 'fixed')

      let table = table_obj.append('table')
      .attr('id', 'commodity_summary_table_')
      .style('border-collapse', 'collapse')
      .style('font-size', '10px')
      .style('width', '420px')
      .style('table-layout', 'fixed')

      let colgroup = table.append('colgroup')
      this.tbody.forEach(k => {
        colgroup.append('col').style('width', k.w + 'px')
      })

      let thead = table.append('thead')
      for (let r = 1; r <= this.theadrows; r++) {
        let row = this.thead.filter(d=> d.row === r)
        let tr = thead.append('tr')

        row.forEach(item => {

          tr.append('th')
          .attr('class', 'ogm_table_group__th')
          .attr('rowspan', () => item.rowspan ? item.rowspan : '')
          .attr('colspan', () => item.colspan ? item.colspan : '')
          .style('border', 'solid 0.7px #eee')
          .style('height', '25px')
          .style('padding', '3px')
          .style('background', item.color ? item.color : '#FAFAFA')
          .html(item.html)
        })        
      }

      let tbodykeys = this.tbody

      let disc = ''
      
      let tbody = table.append('tbody').attr('id', 'commodity_summay_tbody_')

      let addClass = ''

      for (let r = 0; r < list.length; r++) {
        let tr = tbody.append('tr')

        tbodykeys.forEach((k, i) => {

          if (list[r][k.key] == disc) return
          else {
            if (i == 0) {
              disc = list[r][k.key]
            }
          }

          let td = tr.append('td')
            .style('border', 'solid 0.1px #eee')
            .style('height', '21px')
            .style('text-align', k.align ? k.align : 'center')
            .style('padding', '0 3px 0 3px')
            .attr('rowspan', () => {
              if (i == 0) {
                let filtered = list.filter(d => d.DISC_DESCR == list[r][k.key])
                return filtered.length
              }
            })

          if(i === 1) {
            td.style('white-space', 'nowrap')
              .style('overflow', 'hidden')
              .style('text-overflow', 'ellipsis')
              .attr('title', list[r][k.key])
          }

          if(i === 4) {
            td.style('color', list[r][k.key] > 0 ? '#1E88E5' : '#000')
              .attr('title', list[r][k.key])
          }
          if(i === 5) {
            td.style('color', list[r][k.key] < 0 ? 'red' : list[r][k.key] > 0 ? 'green' : '#000')
              .attr('title', list[r][k.key])
          }

          td.on('mouseover', () => tr.style('cursor', 'pointer'))
          td.on('click', () => {
            // console.log(list[r].GWBS_LV3,list[r].WCCODE)
            let dataSource = {
              GWBS_LV3: list[r].GWBS_LV3,
              WCCODE: list[r].WCCODE
            }
            let request_ = this.getRequestColumnProps('status', dataSource, this.ColumnProps)
            this.$emit('request-action', request_)
          })

          if(k.key.includes('PROG')) {
            addClass = ''
            td
            .style('position', 'relative')
            .style('color', list[r][k.key] < 0 ? 'red' : list[r][k.key] === 100 ? '#fff' : addClass == 'caution' ? 'red' : '#000')
            .style('z-index', '1')
            .html(list[r][k.key] == null ? 0+'%' :list[r][k.key]+'%')

            td.append('div')
            .style('position', 'absolute')
            .style('left', 0)
            .style('top', 0)
            .style('width', list[r][k.key] > 0 ? list[r][k.key] + '%' : 0)
            .style('height', '20px')
            .style('background-color', list[r][k.key] < 100 ? k.key.includes('PLAN') ? '#E0E0E0' : '#FFCA28' : '#4CAF50')
            .style('z-index', '-1')
          }
          else {
            if (k.type == 'float') {
              let regexp = /\B(?=(\d{3})+(?!\d))/g
              if (isNaN(Math.floor(list[r][k.key]))) td.html('')
              else td.html(Math.round(Math.floor(list[r][k.key] * 100) / 100).toString().replace(regexp, ','))
            } else td.html(list[r][k.key])
          }
        })
      }
    },
  }
}
