export default {
  data: () => ({
    legend: [
      {CATEGORY:'TEN Procurement'         , SEQ:1, COLOR:'#ffffff',},
      {CATEGORY:'COSCO Procurement'       , SEQ:2, COLOR:'#FFF7AD',},
      {CATEGORY:'TEN Engineering'         , SEQ:3, COLOR:'#B2DFDB',},
      {CATEGORY:'COSCO Engineering'       , SEQ:4, COLOR:'#FFC773',},
      {CATEGORY:'Hull & LQ Construction'  , SEQ:5, COLOR:'#C5EAFF',},
      {CATEGORY:'Topside Construction'    , SEQ:6, COLOR:'#7FD2FF',},
      {CATEGORY:'MCs & Commissioning'     , SEQ:7, COLOR:'#C7FF8F',},
    ],
    status: [
      {TEXT:'Qidong – Due Date', COLOR:'#C2DDA0',},
      {TEXT:'Paris – Due Date' , COLOR:'#B39BCD',},
      {TEXT:'Due Date'         , COLOR:'#A1CEF6',},
    ],
    codeDescr: [
      {DESCR:'Workplace and site condition', SEQ:1,},
      {DESCR:'PPE'                         , SEQ:2,},
      {DESCR:'Hazards and activities'      , SEQ:3,},
      {DESCR:'HSE Management System'       , SEQ:4,},
    ],
    monthDate: [
      {MONTH:'January'},
      {MONTH:'February'},
      {MONTH:'March'},
      {MONTH:'April'},
      {MONTH:'May'},
      {MONTH:'June'},
      {MONTH:'July'},
      {MONTH:'August'},
      {MONTH:'September'},
      {MONTH:'October'},
      {MONTH:'November'},
      {MONTH:'December'},
      {MONTH:'Previous'},
    ]
  })
}