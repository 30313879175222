import * as d3 from 'd3'
// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  data:() => ({
    statusprops:[
      { key: 'TOTAL'      , status: 'Total'    ,color:'#eeeeee',border:'#9E9E9E'},
      { key: 'NEW'        , status: 'New'    ,color:'#80CBC4',border:'#9E9E9E'},//Teal2
      { key: 'DELETED'    , status: 'Deleted'    ,color:'#E0E0E0',border:'#9E9E9E'},
      { key: 'INPROGRESS' , status: 'In Progress'    ,color:'#FFEE58',border:'#388E3C'},//Yellow3
      { key: 'DELIVERED'  , status: 'Delivered' ,color:'#FFCA28',border:'#D32F2F'},//Amber4
      { key: 'CLOSED'     , status: 'Closed' ,color:'#4CAF50',border:'#D32F2F'},//Green5
      { key: 'REJECTED'   , status: 'Rejected' ,color:'#EF5350',border:'#D32F2F'},//Red4
      { key: 'RESUBMITTED', status: 'Resubmitted' ,color:'#4DD0E1',border:'#D32F2F'},//Cyan3
      { key: 'OVERDUE_IP' , status: 'Overdue IP' ,color:'#E57373',border:'#D32F2F'},//Red3
      { key: 'CRITICAL_IP', status: 'Critical IP' ,color:'#7986CB',border:'#D32F2F'}//Indigo3
    ],
  }),
  methods: {
    Draw_Charts() {
      let svg = this.svg

      let data_ = this.DataItems[0]

      let group = svg.append('g').attr('class', 'group__').attr('transform', `translate(0.5, 0.5)`).style('font-family', 'roboto')

      // group
      // .append('rect')
      // .attr('transform', `translate(${0}, ${0})`)
      // .attr('width', 795).attr('height', 28).attr('fill','#B71C1C').attr('rx',5).attr('ry',5)
      // // .attr('stroke-width', 0.5).attr('stroke', '#bcbcbc')

      // group
      // .append('text')
      // .attr('transform', `translate(${10}, ${20})`)
      // .style('font-size', 20).attr('fill', '#fff').style('text-anchor', 'start')
      // .text(data_.IG_NO)

      let groupBox = group
      .append('g').attr('class', 'groupbox__').attr('transform', `translate(0, 0)`)

      groupBox
      .append('line')
      .attr('x1', 728).attr('y1', 7).attr('x2', 728).attr('y2', 40).attr('stroke-width', 0.5).attr('stroke', '#757575')
      
      this.statusprops.forEach((d, i) => {
        if(i <= 7){
          groupBox
          .append('rect')
          .attr('transform', `translate(${90*i}, ${0})`)//
          .attr('width', 85).attr('height', 46).attr('fill',d.color).attr('rx',5).attr('ry',5).attr('stroke', '#cccccc').attr('stroke-width', 0.3)
          groupBox
          .append('text')
          .attr('transform', `translate(${90*i+42.5}, ${18})`)
          .style('font-size', 12).attr('fill', '#333').style('text-anchor', 'middle').style('font-weight',600)
          .text(d.status)
          groupBox
          .append('text')
          .attr('transform', `translate(${90*i+42.5}, ${38})`)
          .style('font-size', 14).attr('fill', '#424242').style('text-anchor', 'middle')
          .text(data_[d.key])
        }else{
          groupBox
          .append('rect')
          .attr('transform', `translate(${90*i+20}, ${0})`)//
          .attr('width', 85).attr('height', 46).attr('fill',d.color).attr('rx',5).attr('ry',5).attr('stroke', '#cccccc').attr('stroke-width', 0.3)
          groupBox
          .append('text')
          .attr('transform', `translate(${90*i+62.5}, ${18})`)
          .style('font-size', 12).attr('fill', '#333').style('text-anchor', 'middle').style('font-weight',600)
          .text(d.status)
          groupBox
          .append('text')
          .attr('transform', `translate(${90*i+62.5}, ${38})`)
          .style('font-size', 14).attr('fill', '#424242').style('text-anchor', 'middle')
          .text(data_[d.key])
        }

      });
      
    },
  }
}
