// -------------------------------------------------------------------------------------- Global
import { SafeIdMixin } from '../../../../../../includes/mixins/safeid.mixin'                // ATAGlance Framework Globals
import gmx_Controller from '../../../../../../includes/mixins/Controller.mixin'       // SVG Control
// Global Drawings
import gmx_Canvas from '../../../../../../includes/mixins/Draw_Canvas.mixin'
// Global Functions
import gmx_Miscellaneous from '../../../../../../includes/mixins/Miscellaneous.mixins'
import gmx_Movable from '../../../../../../includes/mixins/Movable.mixin'

// -------------------------------------------------------------------------------------- Functional Charts
import Cht_Custom_BarCharts from      '../../../../include/ChartLibrary/Custom_BarCharts.mixin'
import Cht_Custom_Header from         '../../../../include/ChartLibrary/Custom_Header.mixin'
import Cht_Custom_SummaryTable from   '../../../../include/ChartLibrary/Custom_SummaryTable.mixin'

// Locally declared common Data-Props & SVG Drawing Support
import lmx_ReactiveEvents from './Realtime_Monitor.mixin' // Reactive Events
import lmx_Declares from './Declares.mixin'
import lmx_Charts from './Draw_Charts.mixin'


export default {
  mixins: [
    SafeIdMixin,
    gmx_Controller,
    gmx_Canvas,
    gmx_Miscellaneous,
    gmx_Movable,

    // Functional Charts
    Cht_Custom_BarCharts,
    Cht_Custom_Header,
    Cht_Custom_SummaryTable,

    lmx_ReactiveEvents,
    lmx_Declares,
    lmx_Charts,
  ],
}
