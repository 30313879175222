import { SafeIdMixin } from '../../../../../../includes/mixins/safeid.mixin'
import gmx_Controller from '../../../../../../includes/mixins/Controller.mixin'
import gmx_Canvas from '../../../../../../includes/mixins/Draw_Canvas.mixin'
import gmx_Miscellaneous from '../../../../../../includes/mixins/Miscellaneous.mixins'

import lmx_ReactiveEvents from './Realtime_Monitor.mixin'
import lmx_Declares from './Declares'


export default {
  mixins: [
    SafeIdMixin,
    gmx_Controller,
    gmx_Canvas,
    gmx_Miscellaneous,

    lmx_ReactiveEvents,
    lmx_Declares,
  ],
}
