import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({

    icons: [
      {name: 'SteelCutting' , y: 20, center: 7, width: 15, height: 15},
      {name: 'Assembly'     , y: 20, center: 6, width: 13, height: 50},
      {name: 'Painting'     , y: 45, center: 7, width: 15, height: 15},
      {name: 'Erection'     , y: 70, center: 6, width: 13, height: 19},
    ],
    

    pupMenu: {
      optionDisplay: 'Y',
      linkDisplay: 'Y',
      width: 0, // automatically set
      x: 1190,
      y: 10,
    }
  }),

  methods: {
    
    drawChart() {

      let lightPink = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `lightPink`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      lightPink.append('stop').attr('stop-color', '#fce3eb').attr('offset', '0')
      lightPink.append('stop').attr('stop-color', '#fcafc9').attr('offset', '1')

      let lightGreen = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `lightGreen`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      lightGreen.append('stop').attr('stop-color', '#ddf1b6').attr('offset', '0')
      lightGreen.append('stop').attr('stop-color', '#c6e481').attr('offset', '1')

      let lightBlue = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `lightBlue`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      lightBlue.append('stop').attr('stop-color', '#d4eaf3').attr('offset', '0')
      lightBlue .append('stop').attr('stop-color', '#a3d0e8').attr('offset', '1')

      let lightGray = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `lightGray`).attr('x1', 0.5).attr('x2', 0.5).attr('y1', 1).attr('y2', 0)
      lightGray.append('stop').attr('stop-color', '#bfbfbf').attr('offset', '0')
      lightGray .append('stop').attr('stop-color', '#fff').attr('offset', '1')

      let shadowTimeline = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `shadowTimeline`).attr('x1', 0.5).attr('x2', 0.5).attr('y1', 0).attr('y2', 1)
      shadowTimeline.append('stop').attr('stop-color', '#3d3d3d').attr('offset', '0').attr('stop-opacity', '0.4')
      shadowTimeline .append('stop').attr('stop-color', '#fff').attr('offset', '1').attr('stop-opacity', '0.3')




      let ganttGroup = this.svg
      .append('g')
      .attr('transform', `translate(0, 0)`)

      // Legends -------------------------------------------------------------------------------------------------------------------------
      let legends = ganttGroup
      .append('g')
      .attr('transform', `translate(10, 43)`)

      legends
      .append('text').attr('x',0).attr('y',0).style('font-family','roboto').style('font-size',11).attr('fill', '#333').text('Legends')

      legends
      .append('path').attr('d', 'M5,8 H21 L25,12 L21,16 H5 L9,12 Z').attr('fill', `url(#lightBlue)`) 
      legends
      .append('text').attr('x',30).attr('y',15).style('font-family','roboto').style('font-size',8.5).attr('fill', '#757575').text('Ahead Schedule')

      legends
      .append('path').attr('d', 'M5,23 H21 L25,27 L21,31 H5 L9,27 Z').attr('fill', `url(#lightPink)`) 
      legends
      .append('text').attr('x',30).attr('y',30).style('font-family','roboto').style('font-size',8.5).attr('fill', '#757575').text('Behind Schedule')

      legends
      .append('rect').attr('x', 5).attr('y', 38).attr('width', 20).attr('height', 8).attr('fill', `url(#lightGreen)`)
      legends
      .append('text').attr('x',30).attr('y',45).style('font-family','roboto').style('font-size',8.5).attr('fill', '#757575').text('Completion 100%')

      // Timeline -------------------------------------------------------------------------------------------------------------------------
      let timeline = ganttGroup
      .append('g')
      .attr('transform', `translate(90, 200)`)

      timeline
      .append('path')
      .attr('d', 'M50,20 H700 L750,30 H0 Z').attr('fill', `url(#shadowTimeline)`) 

      timeline
      .append('rect')
      .attr('x', 0).attr('y', 0).attr('width', this.timelineWidth).attr('height', 20).attr('fill', `url(#lightGray)`)

      timeline
      .append('rect') 
      .attr('x', 0).attr('y', 20).attr('width', this.timelineWidth).attr('height', 5).attr('fill', `url(#lightGray)`)

      // Timeline Text using the scale function
      timeline
      .append('g')
      .selectAll('text')
      .data(this.timelineValues.month)
      .enter()
      .append('text')
      // .attr('transform', d => `translate(${this.timeScale(d.value)}, 10)`)
      .attr('x', d => this.timeScale(d.value))
      .attr('y', 12).style('font-size', 10).style('fill', '#333')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(d => d.name.toUpperCase())

      // Timeline Seperator
      timeline
      .append('g')
      .selectAll('path')
      .data(this.timelineValues.month)
      .enter()
      .append('path')
      .attr('d', d => `M${this.timeScale(d.endDate)}, 6 V16`)
      .attr('stroke', '#757575').attr('stroke-width', 0.3)

      // Timeline Seperator
      let baseLine = 200
      let baseGap = 35
      let StartLine = baseLine - (this.dataSet.length * baseGap) + 10

      let barChart = ganttGroup
      .append('g')
      .attr('transform', `translate(90, 0)`)

      let allPlanNotNull = this.dataSet.filter(d => d.planStart)
      if(allPlanNotNull.length == 0) {
        barChart
        .append('text')
        .attr('transform', `translate(${this.Canvas.CanvasWidth/2.5}, 150)`)
        .style('font-weight', 500)
        .style('font-size', 20)
        .style('font-style', 'italic')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .attr('fill', '#CFCFCF')
        .text('No Data Available.')
      }

      this.dataSet.forEach(d => {
        StartLine += baseGap
        StartLine = StartLine > baseLine ? baseLine : StartLine

        let group = barChart.append('g').attr('class', 'barchartgroup')

        let PS = d.planStart
        let PF = d.planFinish
        let AS = d.actualStart
        let AF = d.actualFinish

        group
        .append('text') // Activity
        .attr('x', this.timeScale(PS)-2).attr('y', StartLine).style('font-size', 9).attr('text-anchor', 'end').attr('alignment-baseline', 'ideographic')
        .text(d.activity)
        .style('fill', `${ !AS ? '#ccc' : '#333'}`)

        if(d.status == 'NotStart' && !d.planStart) return

        group
        .append('line')
        .attr('x1', this.timeScale(PS)).attr('x2', this.timeScale(PS))
        .attr('y1', StartLine).attr('y2', 200).attr('stroke', '#bcbcbc').attr('stroke-width', 0.3)

        group
        .append('line')
        .attr('x1', this.timeScale(PF)).attr('x2', this.timeScale(PF))
        .attr('y1', StartLine).attr('y2', 200).attr('stroke', '#bcbcbc').attr('stroke-width', 0.3)
        
        group
        .append('rect') // Plan Bar Chart
        .attr('x', this.timeScale(PS)).attr('y', StartLine).attr('width', this.timeScale(PF)-this.timeScale(PS)).attr('height', 3).attr('fill', '#E0E0DF')

        if (d.status == 'Completion') {
          group
          .append('rect') // Actual Bar Chart
          .attr('x', this.timeScale(AS)).attr('y', StartLine - 9).attr('width', 0).attr('height', 8).attr('fill', `url(#lightGreen)`)
          .transition().duration(500).attr('width', this.timeScale(AF)-this.timeScale(AS))


          group
          .append('text') // Actual Start Date & Finish Date
          .attr('x', this.timeScale(AF)).attr('y', StartLine - 10 ).style('font-size', 9).style('fill', '#BDBCBC').attr('text-anchor', 'end')//.attr('alignment-baseline', 'ideographic')
          .text(`${d3.timeFormat('%m-%d')(AS)} ~ ${d3.timeFormat('%m-%d')(AF)}`)

          group
          .append('text') // Progress
          .attr('x', this.timeScale(AF)+1).attr('y', StartLine - 8).style('font-size', 9).style('fill', '#CDDC37').attr('text-anchor', 'start').attr('alignment-baseline', 'hanging')
          .text('100%')

        } else if (d.status == 'Progress') {
          group
          .append('path').attr('d', `
                                    M${this.timeScale(AS)}, ${StartLine-9}
                                    H${this.timeScale(new Date())-4}  
                                    L${this.timeScale(new Date())}, ${StartLine-5} 
                                    L${this.timeScale(new Date())-4}, ${StartLine-1} 
                                    L${this.timeScale(AS)}, ${StartLine-1}
                                    L${this.timeScale(AS)+4}, ${StartLine-5} Z
                              `)
          .attr('fill', () => {
            if (d.actualProg >= d.planProg) {return `url(#lightBlue)` }
            else {return `url(#lightPink)`}
          })

          group
          .append('text') // Progress
          .attr('x', this.timeScale(new Date())+1).attr('y', StartLine - 8).style('font-size', 9).attr('text-anchor', 'start').attr('alignment-baseline', 'hanging')
          .style('fill', () => {
            if (d.actualProg >= d.planProg) {return '#83D2F5' }
            else {return '#F7BACF'}
          })
          .text(d.actualProg)
        }

        

        let icon_ = this.icons.find(icon => icon.name == d.activity)
        if(!icon_) return

        // Icon for Milestones
        group
        .append('image') 
        .attr('xlink:href', require(`../../../../../../src/assets/svg/icons/${icon_.name}.svg`))
        .attr('x', this.timeScale(PS) - icon_.center)
        .attr('y', baseLine + icon_.y)
        .attr('width', icon_.width)
        .attr('height', icon_.height)

        group
        .append('text')
        .attr('x', this.timeScale(PS))
        .attr('y', baseLine + icon_.y + icon_.height + 2)
        .style('font-size', 9)
        .style('fill', '#333')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'hanging')
        .text(d.activity)

        group
        .append('text')
        .attr('x', this.timeScale(PS))
        .attr('y', baseLine + icon_.y + icon_.height + 12)
        .style('font-size', 8.5).style('fill', '#bcbcbc')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'hanging')
        .text(d3.timeFormat('%Y-%m-%d')(d.planStart))
        
      })
    }, 
  }
}
