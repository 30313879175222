import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {

  methods: {
    drawChart() {
      let svg = this.svg
      svg
      .append('g')
      .attr('transform', `translate(20, 20)`)


      
      svg
      .append('image')
      .attr('id', `image_wrap`)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/icon/image/movebutton.png`)
      .style('cursor', 'pointer') 
      .on('mouseover', function(){ 
        d3.select(this).transition().duration(100).style('opacity',.5) 
      })
      .on('mouseout', function(){ 
        d3.select(this).transition().duration(100).style('opacity',1) 
      })
      .on('click', () => {
        let request_ = this.getRequestColumnProps('mcdashboard', {}, this.ColumnProps)
        if(request_) this.$emit('request-action', request_)
      })
      
    }
  }
}
