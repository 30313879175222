<template>
  <div class="j_datatable_container" :style="styleLayout">

    <div class='table_header_wrapper'>
      <j-data-grid-title 
        v-if="showInfoBox"
        :attrs="titleAttrs"
        :input-filter="inputFilter"
        :legend-items="tableLegend" 
        :pagination="pagination"
        :sideslide="true"
        @first="onPageTo(1)"
        @previous="onPageTo"
        @next="onPageTo"
        @last="onPageTo"
        @excel-download="excelDownload"
        @input-filter="onInputFilter"
      />
    </div>

    <div class='table_content_wrapper'>
      <j-data-grid-grouped-column
        ref="datatable"
        :headers="headers"
        :items="items"
        :summaries="summaries"
        :table-attrs="tableAttrs"
        :table-type="tableType"
        :title-attrs="titleAttrs" 
        @complete="onComplete"
        @flattened="onFlattened"
        @header-props-assigned="onHPropsAssigned"
        @request-action="onRequestedAction"
        @pregress="onProgress"
      />
    </div>

    <j-alert
      v-model="msgOpen"
      :type="msgInfo.type"
      :title="msgInfo.title"
      :titleDescription="msgInfo.titleDescription"
      :message="msgInfo.message"
      :button="msgInfo.button"
      :buttonText="msgInfo.buttonText"
      @yes="yes()"
      @cancel="msgOpen = false"
    ></j-alert>

    
    <j-modal-slide-component 
      v-model="maOpened"
      :component="maComponent"
      :filters="maFilters"
      :target="maTarget"
    />

  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex"

import __C from '@/primitives/_constant_'
import { ApplicationService } from '@/services'
import Comment from '@/mixins/comment.mixin'
import Url from '../../../src/services/api/request.url'
export default {
  name: 'service-datatable',
  mixins: [
    Comment
  ],
  props: {
    catCode: {
      type: String,
      default: 'Page'
    },
    filters: {
      type: Object,
      default: () => ({})
    },
    target: {
      type: Object,
      default: () => ({
        code: String,
        id: Number,
        no: String
      })
    },
  },
  data: () => ({
    appService: null,

    headerProperties: null,
    execRequested: [],
    flattened: [],

    inputFilter: '',
    pagination: { page: 1, rowsPerPage: 50 /* -1 for All */ },
    sleepRequest: false,

    headers: [],
    items: [],
    summaries: [],
    tableAttrs: {},
    tableType: '',
    titleData: null,

    // For the modal action
    maOpened: false,
    maComponent: '',
    maFilters: null,
    maTarget: null,
    maKeyValue: '',

  msgOpen: false,
  msgInfo: {
    type: "",
    title: "",
    titleDescription: "",
    message: "",
    button: [true, false, true],
    buttonText: ["Yes", "No", "Cancel"]
  },
  yes: () => { },
  }),
  computed: {
     ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']),
    showInfoBox() { return this.tableAvailable && this.tableAttrs.table.showInfoBox == 'Y' },
    styleLayout() {
      if (
        !this.tableAttrs.table || 
        !this.tableAttrs.table.layout ||
        this.tableAttrs.table.layout.applied != 'Y'
      ) return ''
      return `background-color: ${this.tableAttrs.table.layout.bColor}; padding: ${this.tableAttrs.table.layout.margin}px;`
    },
    tableAvailable() { return !this.tableAttrs || !this.tableAttrs.table || Object.keys(this.tableAttrs.table).length === 0 ? false : true },
    tableLegend() { 
      if (
        !this.tableAttrs || 
        !this.tableAttrs.style ||
        Object.keys(this.tableAttrs.style).length === 0
      ) return {}
      return this.tableAttrs.style
    },
    titleAttrs() {
      if (
        !this.tableAttrs || 
        !this.tableAttrs.table
      ) return {}

      let attrs_ = {
        ...JSON.parse(JSON.stringify(this.tableAttrs.title || {})),
        ...JSON.parse(JSON.stringify(this.tableAttrs.table)),
      }
      attrs_.queries = this.titleData
      if(!attrs_.main) attrs_.main = {}
      if(!attrs_.main.text) attrs_.main.text = '[Not Titled]'

      return attrs_
    },
    isInfiniteScroll() { 
      if(
        !this.tableAttrs ||
        !this.tableAttrs.table ||
        !this.tableAttrs.table.pagination ||
        !this.tableAttrs.table.pagination.type
      ) return false

      return this.tableAttrs.table.pagination.type == 'scroll' 
    },
  },
  created() {
    this.appService = new ApplicationService()
    this.inputFilter = this.filters.iFilters.inputFilter
  },
  mounted() {
    window.document.documentElement.querySelector('#app .j-modal-slide--content').addEventListener("scroll", this.onScroll)
  },
  beforeDestroy() {
    if (window.document.documentElement.querySelector('#app .j-modal-slide--content')) {
      window.document.documentElement.querySelector('#app .j-modal-slide--content').removeEventListener("scroll", this.onScroll)
    } else return
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.APP_SERVICE, [
      'setPopupEqOpened',
    ]),
    ...mapActions(__C.STORE_NAMESPACE.INTENDED_DATAGRID, [
      'GET_EXCEL',
    ]),
    onComplete() {
      this.$emit('complete', {
        name: this.titleAttrs.main.text,
        background: this.tableAttrs.table.layout.bColor,
        dimention: this.tableAttrs.table.layout.dimention 
      })
    },
    onFlattened(f) {
      this.flattened = [...f]
    },
    onHPropsAssigned(props) {
      this.headerProperties = props
    },
    onInputFilter(val) {
      this.inputFilter = val
      this.run('input')
    },
    onPageTo(p) {
      this.pagination.page = p
      this.run('page')
    },
    onProgress(e) {

    },
    onRequestedAction(request) {
      if(request.action.target == 'threedviewer') {
        this.$emit('request-action', request)
        this.maOpened = false
        return
      }

      if(!request.action) {
        console.log(`[USER: undefined 'action'] Target is not defined.`)
        return
      }
      if(request.action.target != 'url' && !request.action.component) {
        console.log(`[USER: undefined 'Component Name'] Target Name is not defined.`)
        return
      }
      if(request.action.target == 'url') {
        window.open(request.action.path)
        return
      }
      // For the Pop-Up process
      if(request.action.target != __C.REQUEST_ACTION.TARGET_POPUP && !request.action.component) {
        console.log(`[USER: undefined 'Component Name'] Target Name is not defined.`)
        return
      }
      if([
        __C.REQUEST_ACTION.TARGET_POPUP,
        __C.REQUEST_ACTION.TARGET_ROOM_POPUP
      ].includes(request.action.target)) {
        this.setPopupEqOpened({
          open: true,
          queryid: request.action.id,
          filters: request.filters
        })
        return
      }

      this.maOpened = true
      this.maComponent = request.action.component
      this.maTarget = {
        code: __C.PAGE_COMPONENT.TYPE_MODAL,
        type: '',
        id: request.action.id,
        no: request.action.no,
      }
      this.maFilters = {
        filters: { ...this.filters.filters, ...request.filters },
        iFilters: { ...request.iFilters, inputFilter: this.inputFilter }
      }

      if(this.maFilters && this.maFilters.filters && Object.keys(this.maFilters.filters).includes('SYS')) {
        this.setCommentProps({
          refCode: this.maFilters.filters.SYS
        })
      }

      console.log(`[USER: #DEBUG] Target  [0]: ${this.maComponent} ${JSON.stringify(this.maTarget)}`)
      console.log(`[USER: #DEBUG] Filters [1]: ${JSON.stringify(this.maFilters.filters)}`)
      console.log(`[USER: #DEBUG] Filters [2]: ${JSON.stringify(this.maFilters.iFilters)}`)
    },
    onScroll(e) {
      if(!this.isInfiniteScroll) return
      
      let taget_ = e.currentTarget
      this.scrollTopVisible = taget_.scrollTop > 100

      if(this.sleepRequest || taget_.offsetHeight + taget_.scrollTop < taget_.scrollHeight) return
      if(this.prevScrollTop && this.prevScrollTop >= taget_.scrollTop) {
        this.prevScrollTop = taget_.scrollTop
        return
      }
      this.prevScrollTop = taget_.scrollTop
      this.register(() => {
        this.loading = true
        this.sleepRequest = true
        this.pagination.page += 1
        this.run('scroll').then(res => {
          if(!res) this.loading = false
          setTimeout(() => { 
            this.sleepRequest = false 
          }, 1000)
        })
      })
    },

    appendDataList(dataList) {
      if(!dataList) return
      this.items = [ ...this.items, ...dataList ]
    },
    async excelDownload() {
      this.loading = true
      this.msgOpen = false
      if(this.pagination.totalRows){
        
        console.log(`[USER: #DEBUG] [EXCEL TOTALROWS: ${parseInt(this.pagination.totalRows)}]`)
        console.log(`[USER: #DEBUG] [excelExportLimit: ${parseInt(this.tableAttrs.table.excelExportLimit)}]`)
        //console.log(`[USER: #DEBUG] [EXCEL]`,parseInt(this.pagination.totalRows) > parseInt(this.tableAttrs.table.excelExportLimit)? 'TRUE':'FALSE')
        if(parseInt(this.pagination.totalRows) > ( parseInt(this.tableAttrs.table.excelExportLimit,10) ? parseInt(this.tableAttrs.table.excelExportLimit) :1000) ) {
          this.loading = false
          //alert(`The number of total rows(${this.pagination.totalRows}) is over ${this.tableAttrs.table.excelExportLimit}.\n Please narrow down the search criteria.`)
          this.msgInfo.type = "WARN"
          this.msgInfo.title = "Can Not Download"
          this.msgInfo.titleDescription = ""
          this.msgInfo.message = `The number of total rows(${this.pagination.totalRows}) is over ${this.tableAttrs.table.excelExportLimit}.<br> Please narrow down the search criteria.`
          this.msgInfo.button = [false, false, true]
          this.msgOpen = true
          return
        }
      }
      let res_ = await this.run('full-small')
      let data_ = {
        title: this.titleAttrs,
        legend: this.tableLegend,
        items: res_
      }
      let iFilter_ = { ...this.filters.iFilters }
      iFilter_.inputFilter = this.inputFilter
      let fValues = this.filters.filters == null ? "" : JSON.stringify(this.filters.filters)
      
      let params = new URLSearchParams()
      params.append('idx', this.target.id)    // menu-item or sub-item's idx
      params.append('fValues', fValues)
      params.append('iFilter',  JSON.stringify(iFilter_))
      params.append('easyFormedHeaders',JSON.stringify(this.easyFormedHeaders))
      params.append('pagination', JSON.stringify({page:1,rowsPerPage:40,reset:1}))
      params.append('requestType', 'full-small')
      params.append('token', this.account.token)

      const send_Data = {
        url_ : `${Url.fetch.excel.get}?${params.toString()}`,
        title : data_.title.main.text
      }

      await this.GET_EXCEL(send_Data)
      
      // let excel = await this.$refs.datatable.toxlsx(data_)
      // let url = window.URL.createObjectURL(excel)
      // let a = document.createElement('a')
      // document.body.appendChild(a)
      // a.href = url
      // a.download = this.titleAttrs.main.text
      // a.click()
      // window.URL.revokeObjectURL(url)
      // document.body.removeChild(a)
      this.loading = false
    },
    exec(requested) {
      if(requested.num != this.execRequested.length) return
      
      this.execRequested = []
      requested.executor()
    },
    register(executor) {
      let requested = {
        num: this.execRequested.length + 1,
        time: new Date().getTime(),
        executor: () => { executor() }
      }
      this.execRequested.push(requested)
      setTimeout(() => { this.exec(requested) }, 100)
    },    

    scrollFn() {
      window.document.documentElement.querySelector('#app .j-modal-slide--content')
      .scrollTo({top: 0, behavior: 'smooth'})
    },

    run(type='init-small') {
      let iFilter_ = { ...this.filters.iFilters }
      iFilter_.inputFilter = this.inputFilter

      let params = new URLSearchParams()
      params.append('idx', this.target.id)        // datatable idx
      params.append('catCode', this.target.code)  // Page | Modal
      params.append('info', '')
      params.append('iFilter', JSON.stringify(iFilter_))
      params.append('fValues', JSON.stringify(this.filters.filters))
      params.append('cProps', '')                 // Configuring properties for the Intended DataGrid
      params.append('pInfo', type == 'input' ? JSON.stringify({page: 1, rowsPerPage: 40, totalRows: 1}) : JSON.stringify(this.pagination))   // User updated pagenation info
      params.append('reqType', type)

      // console.log('idx', this.target.id)
      // console.log('catCode', this.target.code)
      // console.log('iFilter', JSON.stringify(iFilter_))
      // console.log('fValues', JSON.stringify(this.filters.filters))
      // console.log('pInfo', JSON.stringify(this.pagination))
      // console.log('reqType', type)
      

      return new Promise((resolve) => { 
        this.appService.requestDataCollectionIDG(params, (resultSet) => {
          if(type == 'init-small') {
            this.headers = resultSet.dataProps
            this.items = resultSet.dataList
            this.summaries = resultSet.summaryData
            this.tableAttrs = resultSet.tableAttrs
            this.tableType = resultSet.tableType
            this.pagination = resultSet.pagination

          } else if(type == 'filtered' || type == 'input') {
            this.items = resultSet.dataList
            this.summaries = resultSet.summaryData
            this.pagination = resultSet.pagination

          } else if(type == 'page') {
            this.items = resultSet.dataList

          } else if(type == 'scroll') {
            this.appendDataList(resultSet.dataList)

          } else { // full-small
            resolve(resultSet.dataList)
          }

          if(type != 'full-small') {
            resolve(true)
          }
        })
      })
    },
  }
}


</script>
