/**
 * Event Call Functions
 */

import * as d3 from 'd3'

export default {
  methods: {
    /*
      call_Coordinate()
      call_AreaList()
      goto_ImformationPage(d)
    */

    call_Coordinate(selection, code,x,y) {

      selection
      .on('mouseover', () => {
        this.innerSvg.select(`#BOX-${code}`)
        .transition().duration(250)
        .style('opacity', .75)

        this.innerSvg.select(`#AREA-${code}`)
        .transition().duration(250)
        .attr('transform', `translate(0, 0) scale(1)`)
        .style('opacity', 1)
      })

      .on('mouseout', () => {
        this.innerSvg.select(`#BOX-${code}`)
        .transition().duration(250)
        .style('opacity', 0)

        this.innerSvg.select(`#AREA-${code}`)
        .transition().duration(250)
        .attr('transform', `translate(${x}, ${y}) scale(0)`)
        .style('opacity', 0)
      })

      .on('click', () => {
        let request = this.buildRequest(code)
        if(request) this.$emit('request-action', request)
      })
    },


    call_AreaList(selection, code, x, y) {

      selection
      .on('mouseover', () => {
        selection.select('rect')
        .transition().duration(100).style('fill', '#44A9DF')

        selection.select('text')
        .transition().duration(100).style('fill', 'white')

        this.innerSvg.select(`#BOX-${code}`)
        .transition().duration(150).style('opacity', .75)

        this.innerSvg.select(`#AREA-${code}`)
        .transition().duration(150)
        .attr('transform', `translate(0, 0) scale(1)`).style('opacity', 1)

        this.innerSvg.select(`#LIST_${code}`)
        .transition().duration(150)
        .style('opacity', .3)
      })

      .on('mouseout', () => {
        selection.select('rect')
        .transition().duration(100).style('fill', '#fff')

        selection.select('text')
        .transition().duration(100).style('fill', 'black')

        this.innerSvg.select(`#BOX-${code}`)
        .transition().duration(150) .style('opacity', 0)

        this.innerSvg.select(`#AREA-${code}`)
        .transition().duration(150)
        .attr('transform', `translate(${x}, ${y}) scale(0)`).style('opacity', 0)

      })

      .on('click', () => {
        let request = this.buildRequest(code)
        if(request) this.$emit('request-action', request)
      })
    },

    call_CheckList(selection, code) {

      selection
      .on('mouseover', () => {
        this.svg.select(`#package_checkbox__${code}`).attr('stroke', '#555')
      })
      .on('mouseout', () => {
        this.svg.select(`#package_checkbox__${code}`).attr('stroke', '#bcbcbc')
      })
      .on('click', () => {
        if(this.CurrentPkg == code) return

        let checked = this.svg.select(`#package_checkbox_checked__${code}`).text()
        checked = !checked

        // not checked 
        this.Legend.filter(d => d.CODE != code).forEach((d,i) => {
          this.svg.select(`#package_checkbox_checked__${d.CODE}`).text('')       
          d.CHECKED = !checked
        })

        this.Legend.find(d => d.CODE == code).CHECKED = checked
        this.svg.select(`#package_checkbox_checked__${code}`).text(checked ? 'v' : '')
        this.CurrentPkg = code

        this.Queries.SQL1.forEach((d,i) => {
          d3.select(`#Line${d.NO}`).remove()
          d3.select(`#LIST_${d.NO}`).remove()
        })
        this.draw_ListboxDetail()
        this.svg.select(`#list_title`).text(`PACKAGE LIST - ${code}`)
      })
    },

    goto_ImformationPage(d) {
      console.log(d.code, '-', d.name)
    }
  }
}