import * as d3 from 'd3'

export default {
  data: () => ({
    dataSet: [],
    defaultset: { 
      width: 260,
      height: 46,
      color: '#d2e5fe',
      linkIcon: 'N',
      iconX: 915,
      iconY: 2.5,
      iconW: 20,
      iconH: 18,
      filer: 'Y',
      fX: '10',
      fY: '0',
      fFontSize: 13,
      fColor: '#000',
    },
    attrs: [
      { 
        text: 'Current Est.Qty',
        refColumn: 'TOTAL',
        fColor: '#616161', 
        tColor: '#ffffff',
      },
      { 
        text: 'Completed Qty',
        refColumn: 'COMPLETED',
        fColor: '#D50000', 
        tColor: '#ffffff'
      },
      { 
        text: 'Remain Qty',
        refColumn: 'REMAIN',
        fColor: '#ffffff', 
        tColor: '#D50000'
      },
      { 
        text: 'Complete%',
        refColumn: 'PROG',
        fColor: '#ffffff', 
        tColor: '#424242'
      },

    ],
    Wattrs: [

      { 
        text: 'Plan',
        refColumn: 'INDIRECT_PLAN',
        fColor: '#BDBDBD',
        tColor: '#1565C0'
      },
      { 
        text: 'Actual',
        refColumn: 'INDIRECT_ACTUAL',
        fColor: '#BDBDBD', 
        tColor: '#F50057'
      },
      { 
        text: 'Delta',
        refColumn: 'INDIRECT_DELTA',
        fColor: '#BDBDBD', 
        tColor: '#D50000'
      },
    ],
    Cattrs: [

      { 
        text: 'Plan',
        refColumn: 'DIRECT_PLAN',
        fColor: '#BDBDBD',
        tColor: '#1565C0'
      },
      { 
        text: 'Actual',
        refColumn: 'DIRECT_ACTUAL',
        fColor: '#BDBDBD', 
        tColor: '#F50057'
      },
      { 
        text: 'Delta',
        refColumn: 'DIRECT_DELTA',
        fColor: '#BDBDBD', 
        tColor: '#D50000'
      },
    ]
  }),
  computed: {
    ready2draw() {

      // console.log('------------------------------')
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)

      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },
  methods: {
    setDefaultValues(){
    
        this.dataSet = JSON.parse(JSON.stringify(this.DataItems))
        
        this.dataSet.forEach(data => {
         //
        })
  
    },
 
    
  }
}