import * as d3 from 'd3'

export default {
  
  data: () => ({
    innerSvg: null,
    keyPlanSvg: null,
    
    SVG_Activate: null,
    SVG_AreaSummary: null,
    SVG_Area: null,
    SVG_Module: null,
    SVG_Equipment: null,

    SVG_dataTable: null,

    Event_Overall: null,
    Event_Area: null,

    Deck: null,
    Module: null,
    Area: null,
    Stage: 'OVERALL', // OVERALL - AREA - MODULE - DECK - EQUIPMENT

    BackButtonUse: 'on',

    ScaleOverall: {Hull: 0.725, Deckbox: 0.45,  Topside: 1},
    ScaleModule:  {Hull: 0.5,   Deckbox: 1,     Topside: 1.25},
    ScaleSummary: {Hull: 1,     Deckbox: 1.5,   Topside: 1.75},
    ScaleBlock:   {Hull: 0.725, Deckbox: 0.45,  Topside: 0.3},
  }),


  computed: {

    ready2draw() {
      // console.log('------------------------------')
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)

      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },

  },

  methods: {
    
    setDefaultValues(){

      this.dataSet = JSON.parse(JSON.stringify(this.DataItems))
      this.dataSet.forEach(d => {
        //
      })

      // Style & Coloring
      this.dataSet.forEach(d => {
        // Change Type for Pre-Erection Block
      })

    },
    

  }
}