import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  data: () => ({
    margin: {top: 0, right: 30, bottom: 40, left: 30},
    width: 740,
    height: '',
    value: 'ROS_ETA_FORECAST',
    desc: 'REQ_DESCR',
    dataType: null
  }),
  methods: {
    Draw_Charts() {
      d3.select('#'+this.localId).selectAll('.progressbar-contents').remove()
      this.render(this.DataItems)
    },
    setSchedule(type) {
      if (type === 'plan') this.value = 'ROS_ETA_PLAN'
      if (type === 'forecast') this.value = 'ROS_ETA_FORECAST'
      this.dataType = 'All'
      this.svg.selectAll('.progressbar-contents').remove()
      this.render(this.DataItems)
    },
    setData(type) {
      let data = this.DataItems
      if (type === 'except') data = data.filter(d => !d.ETA_ACTUAL)
      this.svg.selectAll('.progressbar-contents').remove()
      this.render(data)
    },
    render(data) {
      let value = this.value
      let desc = this.desc
      
      this.height = this.DataItems.length <10 ? 1000 : 2050
      let width = this.width - this.margin.left - this.margin.right,
          height = this.height - this.margin.top - this.margin.bottom

      let x = d3.scaleLinear().range([0,width])
      let y = d3.scaleBand().rangeRound([height,0]).padding(0.2)


      const xAxis = d3.axisBottom(x).tickFormat(d => d).tickSize(-height)

      const xAxisTop = d3.axisTop(x).tickFormat(d => d).tickSize(-height)

      let min = d3.min(data, d => d[value])
      let max = d3.max(data, d => d[value])
      let compare = min < 0 ? d3.max([min * -1, max]) : d3.max([min, max])

      x.domain([compare * -1, compare])
      y.domain(data.map( d => d[desc] ))

      let g = this.svg
      // .append('g').attr('transform', `translate(0.5, 14.5)`).attr('class', 'progressbar-contents')

      

      let contents = g.append('g').attr('transform', `translate(.5, 14.5)`).attr('class', 'progressbar-contents')

      contents.append('g')
        .style('font-size', '10px')
        .attr('class', 'x axis')
        .attr('transform', `translate(0.5,${height + 1.5})`)
        .call(xAxis)
        .selectAll('text')
        .attr('y', 10.5)
        .attr('x', 0.5)
        .attr('dy', '.35em')
        .attr('fill', '#757575')
        .style('text-anchor', 'middle')

      contents.append('g')
        .style('font-size', '10px')
        .attr('class', 'x axis')
        .attr('transform', `translate(.5,.5)`)
        .call(xAxisTop)
        .selectAll('text')
        .attr('y', 0.5)
        .attr('x', 0.5)
        .attr('dy', '-.5em')
        .attr('fill', '#757575')
        .style('text-anchor', 'middle')

      contents.append('g')
        .attr('transform', `translate(0.5,0.5)`)
        .attr('class', 'bar')
        .selectAll('.bar')
        .data(data)
        .enter().append('rect')
        .attr('x', (d) => d[value] < 0 ? x(d[value]) : x(0) )
        .attr('y', (d) => y(d[desc]) )
        .attr('height', () => {
          if (y.bandwidth() - 2 > 20) return 20
          else return y.bandwidth() - 2
        })
        // .attr('width', (d) => d[value] > 0 ? x(d[value] * -1) - x(0) : x(d[value]) - x(0) )
        // .transition()
        // .duration(duration)
        .attr('width', (d) => d[value] < 0 ? x(d[value] * -1) - x(0) : x(d[value]) - x(0) )
        .attr('fill', (d) => d[value] < 0 ? '#EF5350' : '#26A69A')

      contents.append('g')
      .attr('transform', `translate(0.5,0.5)`)
        .attr('class', 'ROS_ETA_PLAN')
        .selectAll('.ROS_ETA_PLAN')
        .data(data)
        .enter().append('text')
        .attr('font-size', '10px')
        .attr('x', (d) => {
          if (d[value] < 0){
            return (x(d[value] * -1) - x(0)) > 20 ? x(d[value]) + 2 : x(d[value]) - 1
          } else {
            return (x(d[value]) - x(0)) > 20 ? x(d[value]) - 2 : x(d[value]) + 1
          }
        })
        .attr('y', (d) => y(d[desc]) )
        .attr('dy', () => {
          // (y.bandwidth() / 2) + 2
          if (y.bandwidth() - 2 > 20) return 13
          else return (y.bandwidth() / 2) + 2
        })
        .attr('text-anchor', (d) => {
          if (d[value] < 0){
            return (x(d[value] * -1) - x(0)) > 20 ? 'start' : 'end'
          } else {
            return (x(d[value]) - x(0)) > 20 ? 'end' : 'start'
          }
        })
        .style('fill', (d) => {
          if (d[value] < 0){
            return (x(d[value] * -1) - x(0)) > 20 ? '#fff' : '#3a403d'
          }else if (d[value] === 0) {
            return '#ccc'
          } else {
            return (x(d[value]) - x(0)) > 20 ? '#fff' : '#3a403d'
          }
        })
        .text((d) => d[value] )

      contents.append('g')
      .attr('transform', `translate(0.5,0.5)`)
        .attr('class', 'REQ_DESCR')
        .selectAll('.REQ_DESCR')
        .data(data)
        .enter().append('text')
        .attr('x', (d) => d[value] < 0 ? x(0) + 2.55 : x(0) - 2.55 )
        .attr('y', (d) => y(d[desc]) )
        .attr('dy', () => {
          // (y.bandwidth() / 2) + 4
          if (y.bandwidth() - 2 > 20) return 13
          else return (y.bandwidth() / 2) + 4
        })
        .attr('font-size', '10px')
        .attr('text-anchor', (d) => d[value] < 0 ? 'start' : 'end')
        .text((d) => d[desc] )
        .style('cursor', (d) => {
          if (!d.MR_NO) return
          return 'pointer'
        })
        .on('click', (d)=> {
          if (!d.MR_NO) return
          let request_ = this.getRequestColumnProps('request', { MR_NO: d.MR_NO }, this.ColumnProps)
          this.$emit('request-action', request_)
        })

      contents.append('g')
      .attr('transform', `translate(0.5,0.5)`)
        .attr('class', 'line')
        .append('line')
        .attr('x1', x(0)+.5)
        .attr('x2', x(0)+.5)
        .attr('y1', 0 + this.margin.top+.5)
        .attr('y2', height - this.margin.top+.5)
        .attr('stroke', '#3a403d')
        .attr('stroke-width', '.5px')

      contents.selectAll('.x .tick line').attr('stroke', '#ccc').attr('stroke-opacity', 0.3)//.attr('dx', 0.5).attr('dy', 0.5)
      contents.selectAll('.domain').attr('stroke', '#ccc').attr('stroke-opacity', 0.5)

      
    },

  }
}
