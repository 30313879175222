import * as d3 from 'd3'
export default {

  methods: {
    /*
      draw_Grid()
      draw_GroupTitle()
      draw_ActTitle()
      draw_BarChart()
      draw_Milestone()
      draw_OverallMilestone()
      draw_MovingLine()
      draw_CriticalPath()
      draw_Timenow()
    */
    
    draw_Grid(){
      let grid = this.svgGroup

      grid.append('g')
      .attr('transform', `translate(${this.styles.margin.left}, ${this.styles.margin.top + 50})`)

      .selectAll('line')
      .data(['2022-01-01', '2023-01-01', '2024-01-01', '2025-01-01'])
      .enter()
      .append('line')
      .attr('x1', d => this.timeline.scale(new Date(d))).attr('y1', 0)
      .attr('x2',d => this.timeline.scale(new Date(d))).attr('y2', 1100)
      .attr('stroke-width', 0.5).attr('stroke', '#bcbcbc').attr('opacity', 0.5)
    },


    draw_GroupTitle(selection, id_, d){

      selection.select(id_)
      .append('rect')
      .attr('x', 20)
      .attr('y', this.y_Coordinate - 12)
      .attr('width', this.styles.margin.left + this.timeline.width - 20)
      .attr('height', 20)
      .attr('fill', '#bcbcbc')
      .attr('opacity', 0.1)

      selection.select(id_)
      .append('text')
      .attr('x', 30)
      .attr('y', this.y_Coordinate)
      .style('font-size', 14).style('fill', '#000')
      .style('font-weight', 500)
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(d)
    },


    draw_ActTitle(selection, id_, d, i){
      selection.select(id_)
      .append('text')
      .attr('id', `id_title_${i}`)
      .attr('x', 40)
      .attr('y', this.y_Coordinate)
      .style('font-size', 11.5).style('fill', '#000')
      .style('font-weight', 400)
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(d)

      selection.select(id_)
      .append('rect')
      .attr('x', 20)
      .attr('y', this.y_Coordinate - 13)
      .attr('width', this.styles.margin.left + this.timeline.width - 20)
      .attr('height', 25)
      .attr('fill', '#bcbcbc')
      .attr('opacity', 0)
      .style('cursor', 'pointer')
      .on("mouseover",function(){
        d3.select(this)
        .transition().duration(150)
        .attr("opacity",0.2)
        
        d3.select(`#id_title_${i}`)
        .transition().duration(150)
        .style('font-weight', 600)
      })
      .on("mouseout",function(){
        d3.select(this)
        .transition().duration(150)
        .attr("opacity",0)
        d3.select(`#id_title_${i}`)
        .transition().duration(150)
        .style('font-weight', 400)
      })
    },


    draw_BarChart(selection, id_, d){
      this.barChart.charts.forEach( (c, i) => {
        if (d[`${c.code}_SD`]) {
          selection.select(id_)
          .append('rect')
          .attr('x', this.timeline.scale(new Date(d[`${c.code}_SD`])))
          .attr('y', this.y_Coordinate - (c.size/2))
          .attr('width', this.timeline.scale(new Date(d[`${c.code}_FD`]))-this.timeline.scale(new Date(d[`${c.code}_SD`])))
          .attr('height', c.size).attr('fill', c.color)

          // draw circle
          selection.select(id_)
          .append('circle')
          .attr('cx', this.timeline.scale(new Date(d[`${c.code}_SD`])))
          .attr('cy', this.y_Coordinate)
          .attr('r', 4)
          .attr('stroke', '#000').attr('stroke-width', 0.5).attr('stroke-opacity', .5)
          .attr('fill', '#bcbcbc')

          selection.select(id_)
          .append('circle')
          .attr('cx', this.timeline.scale(new Date(d[`${c.code}_FD`])))
          .attr('cy', this.y_Coordinate)
          .attr('r', 4)
          .attr('stroke', '#000').attr('stroke-width', 0.5)
          .attr('fill', '#bcbcbc')
        }
      })
    },


    draw_Milestone(selection, id_, d){
      selection.select(id_)
      .append('path')
        .attr('transform', `translate(${this.timeline.scale(new Date(d.PLAN)) + this.milestone.icon.water1.x}, ${this.y_Coordinate + this.milestone.icon.water1.y - 4})`)
        .attr('d', `${this.milestone.icon.water1.path}`)
        .attr('fill', '#44A9DF')
        // .attr('stroke', '#970234')
        // .attr('stroke-width', 1)

      selection.select(id_)
      .append('text')
        .attr('x', this.timeline.scale(new Date(d.PLAN)))
        .attr('y', this.y_Coordinate - 18)
        .style('font-size', 9).style('fill', 'gray')
        // .style('font-weight', 400)
        .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text(d.MILESTONE)
    },


    draw_OverallMilestone(selection, id_){

      this.Milestone.filter(f => f.ACTIVITY == 'Overall').forEach( d => {
        selection.select(id_)
        .append('path')
        .attr('transform', `translate(${this.timeline.scale(new Date(d.PLAN)) + this.milestone.icon.water1.x}, 0)`)
        .attr('d', `${this.milestone.icon.water1.path}`)
        .attr('fill', '#44A9DF')

        selection.select(id_)
        .append('text')
        .attr('x', this.timeline.scale(new Date(d.PLAN)))
        .attr('y', (d.LEV==1) ? -1 : -12)
        .style('font-size', 9).style('fill', '#000')
        .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text(d.MILESTONE)
      })
    },

    draw_MovingLine(){
      
      var moveLine = this.svgGroup

      moveLine
      .append('line')
      .attr('id', 'id_mouse')
      .attr('x1', 0).attr('y1', this.styles.margin.top + 46)
      .attr('x2', 0).attr('y2', this.timeline.width)
      .attr('stroke-width', 0.5).attr('stroke', '#B5549B').attr('opacity', 1)

      moveLine
      .append('rect')
      .attr('x', this.styles.margin.left)
      .attr('y', this.styles.margin.top + 46)
      .attr('width', this.timeline.width)
      .attr('height', this.y_Coordinate)
      .attr('fill', 'gray').attr('fill-opacity', 0)
      .on("click", function(){
          d3.select('#id_mouse')
          .attr("x1",d3.mouse(this)[0])
          .attr("x2",d3.mouse(this)[0])
        }
      )
    },


    draw_CriticalPath() {

      let s_Critical = this.svgGroup
      .append('g')
      .attr('transform', `translate(${this.styles.margin.left}, ${this.milestone.margin.top + 210})`)

      s_Critical.append('line')
      .attr('x1', this.timeline.scale(new Date('2022-02-01'))).attr('y1', 0)
      .attr('x2', this.timeline.scale(new Date('2024-12-31'))).attr('y2', 0)
      .attr('stroke-width', 3).attr('stroke', 'red').attr('opacity', 1)
      .style("stroke-dasharray", ("10, 5"))

      s_Critical.append('line')
      .attr('x1', this.timeline.scale(new Date('2022-02-01'))).attr('y1', 300)
      .attr('x2', this.timeline.scale(new Date('2024-07-01'))).attr('y2', 300)
      .attr('stroke-width', 3).attr('stroke', 'red').attr('opacity', 1)
      .style("stroke-dasharray", ("10, 5"))

      s_Critical.append('line')
      .attr('x1', this.timeline.scale(new Date('2024-07-01'))).attr('y1', 0)
      .attr('x2', this.timeline.scale(new Date('2024-07-01'))).attr('y2', 300)
      .attr('stroke-width', 3).attr('stroke', 'red').attr('opacity', 1)
      .style("stroke-dasharray", ("10, 5"))
    },


    draw_Timenow() {
      // Time-now line
      this.svgGroup.append('line')
      .attr('x1', `${this.timeline.scale(new Date(this.cutoff)) + this.styles.margin.left}`).attr('y1', this.styles.margin.top + 40)
      .attr('x2',`${this.timeline.scale(new Date(this.cutoff)) + this.styles.margin.left}`).attr('y2', this.y_Coordinate + this.barChart.topMargin)
      .attr('stroke-width', 1).attr('stroke', '#D50000').attr('opacity', 0.7)
    }
  }
}