import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {

    Draw_Charts() {
      
      let systemsummary = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0.5)`)

      systemsummary
      .append('g')
      .attr('transform', 'translate(30, 100)')

      systemsummary.append('rect').attr('class', 'download_btn_rect')
      .attr('transform', `translate(0, 0)`)
      .style('width', 90).style('height', 45)
      .style('fill', '#BDBDBD').attr('rx', 3).attr('ry', 3)
      .style('box-shadow', '12px 12px 2px 1px rgba(0, 0, 255, .2)')

      // Titles
      systemsummary.append('text')
      .attr('transform', 'translate(45, 28)')
      .style('font-family', 'roboto').style('font-size', 18).style('fill', '#fff').style('text-anchor', 'middle')
      .text('LOSH')

      if (this.DataItems[0].LOSH) d3.select('.download_btn_rect').style('fill', '#29B6F6')
      else return

      systemsummary
      .style('cursor', 'pointer')
      .on('mouseover', function() {
        d3.select(this).transition().duration(100).style('opacity', '0.7')
      })
      .on('mouseout', function(){
        d3.select(this).transition().duration(100).style('opacity', '1')
      })
      .on('click', () => {
        let request_ = {
          dataType: 'row',
          action: {
            name: 'Download',
            type: 'direct',
            target: 'Download',
            component: 'none',
            id: 0,
            no: ''
          },
          filters     : {
            METADATA: this.FilterValues.SUBSYSTEM,
            folder: 'LOSH'
          },
          iFilters    : {
            filterString : '',
            inputFilter  : ''
          }
        }
        this.$emit('request-action', request_)
      })


    }, 
  }
}
