import * as d3 from 'd3'

export default {
  methods: {
    draw_Event() {
      this.SendRequestAction = (selection, tag, lv4) => {
        
        selection
        .on('mouseover', (_, i, a) => {
          let el = d3.select(a[i])
          el.style('cursor', 'pointer')
          if(el.node().tagName == 'g') el.style('opacity', .5)
          else el.attr('fill', '#81d4fa')
        })
        .on('mouseout', (_, i, a) => {
          let el = d3.select(a[i])
          el.style('cursor', 'pointer')
          if(el.node().tagName == 'g') el.style('opacity', 1)
          else el.attr('fill', '#333')
        })
        .on('click', () => {
          // this.openEQPopup(tag) SVg Equipment Popup 안쓰고 component popup쓰기로함 
          let request_ = this.getRequestColumnProps('eqpopup', { TAG: tag, LV4: lv4}, this.ColumnProps)
          // if(request_) { //예시
          //   request_.filters = {
          //     ...request_.filters,
          //     ...this.FilterValues
          //   }
          //   this.$emit('request-action', request_)
          // }
          this.$emit('request-action', request_)
        })
      }
      this.Goto_Activate = (selection, mask, level) => {
        
        selection
        .on('mouseover', () => {
          d3.select(`#${mask}`).transition().style('opacity',0.5)
        })
        .on('mouseout', () => {
          d3.select(`#${mask}`).transition().style('opacity',0)
        })
        .on('click', () => {
          // console.log(level)
          this.currentLevel = level //listBtn 눌렀을때 조건에 따라 나오게 하려고 

          this.removeSvg(this.CANVAS, 1000) 
          // Title 이랑 data랑 다르기 때문에 임의로 이렇게 바꿔준다
          // d3.select(`#Title`)
          // .text(() => level == 'MS03'? 'Electrical Rooms - MS 3rd Level' 
          // : level == 'MS01'? 'Instrument Room - MS 1st Level'
          // : level == 'FOR' ? 'Electrical Room - LQ' 
          // : level == 'LQ01' ? 'Central Contorl Room(CCR)' 
          // : level == 'LQ02' ? 'Central Equipment Room(CER)'
          // : level == 'LQ06' ? 'Telecom Equipment Room(TER)'
          // : level == 'ME05DA'? 'Electrical HV Room - Level 1' :'Electrical LV Room - Level 2')     
          switch (level) {
            case 'MS01': d3.select(`#Title`).text('Instrument Room - MS 1st Level'); break
            case 'MS03': d3.select(`#Title`).text('Electrical Rooms - MS 3rd Level');break
            case 'FOR': d3.select(`#Title`).text('Equipment Room - Hull Deck 32000 A.B');break
            case 'LQ06': d3.select(`#Title`).text('Telecom Equipment Room(TER) - LQ 6th Lv.');break
            case 'LQ01': d3.select(`#Title`).text('Central Control Room(CCR) - LQ 1st Lv.');break
            case 'LQ02': d3.select(`#Title`).text('Central Equipment Room(CER) - LQ 2nd Lv.');break
            case 'ME05DA': d3.select(`#Title`).text('HV Room - E/I Building(ME05) 1st Lv.');break
            case 'ME05DB': d3.select(`#Title`).text('LV Room - E/I Building(ME05) 2nd Lv.');break
          }


          this.displayGroupID(`#Menu_Back`, 100, 500, 1)
          this.displayGroupID(`#Keyplan_Group`, 100, 500, 1)
          d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Rooms/Keyplan/${level}.png`)
          this.displayGroupID(`#Legends`, 100, 500, 1)

          this.hiddenGroupID('#Mask_LQ01', 0, 10)
          this.hiddenGroupID('#Mask_LQ02', 0, 10)
          this.hiddenGroupID('#Mask_LQ06', 0, 10)
          this.hiddenGroupID('#Mask_FOR', 0, 10)
          this.hiddenGroupID('#Mask_ME05DB', 0, 10)
          this.hiddenGroupID('#Mask_ME05DA', 0, 10)
          this.hiddenGroupID('#Mask_MS01', 0, 10)
          this.hiddenGroupID('#Mask_MS03', 0, 10)

          setTimeout(() => {
            this.location.forEach(d => {
              if(d.LV4 == level) {
                d3.select(`#${d.LV4}`).transition().duration(400).attr('transform', `translate(${d.x5}, ${d.y5}) scale(${1})`)
              } else {
                this.hiddenGroupID(`#${d.LV4}`, 0, 400)
              }
            })
          }, 400);
          setTimeout(() => {
            this.loadSvg(0, 0, `/TORTUE/Equipment/Rooms/SVG/${level}.svg`).then((innerSvg) => {
                this.Draw_EQ_Code(innerSvg, level)
                this.Draw_EQ_Tag(innerSvg, level)
                this.Draw_EQ_Block(innerSvg, level)
                this.innerSvg = innerSvg
            })
            //-----------------------------------pdf overall이랑 다른 이유는 받은대로 pdf로 바로 함수 부를 것이기때문에//

            let dStage = {
              mask: mask,
              level: level
            }
            this.selectedItems.push(dStage)
            //-----------------------------------pdf//
            this.drawProgress(level, false)              
          }, 800)
          
        })
      }
    },
  }
}
