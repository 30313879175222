import * as d3 from 'd3'

export default {
  methods: {
    Export_Hull_For(d,k) {

      // if (d.stage == 'Division_Lv3' && d.level == 'HULL-FOR') {
      //   setTimeout(() => {
      //     // Remove SVG
      //     this.removeSvg(this.CANVAS, 10)
      //   },1000);

      //   // Title & Keyplan
      //   d3.select(`#Title`).text(`Hull-FOR`)
      //   d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Keyplan/HULL_FOR.png`)

      //   // Hidden
        
      //   this.hiddenGroupID('#Menu_Back', 0, 10)
      //   this.hiddenGroupID('#Mask_FOR_Group', 0, 10)
      //   setTimeout(() => {
      //   // Explode
      //   this.Hull_FOR.forEach(d => {
      //     d3.select(`#Blk_${d.Blk}`)
      //     .transition().duration(1000).attr('transform', `translate(${d.x2}, ${d.y2}) scale(0.5)`)
      //   })
      // },1000);
      //   setTimeout(() => {

      //     // Display
      //     this.displayGroupID(`#Mask_HL03`, 0, 10, 0)
      //     this.displayGroupID(`#Mask_MD00f`, 0, 10, 0)
      //     this.displayGroupID(`#Mask_VS4_05`, 0, 10, 0)

      //     this.Lev1 = 'HULL'; this.Lev2 = 'HULL-FOR'
      //     this.stage = this.STG_Division_Lv3
      //     this.fncName = 'Goto_FOR_Division_Lv3'
      //     this.StageCode() // AREA / STAGE
      //     this.displayGroupID(`#Menu_Back`, 0, 200, 1)

      //     // ### Call Canvas ###
      //     this.Canvas_Division_Lv3(this.Lev1, this.Lev2)
      //   }, 1000);

      // }

      if (d.stage == 'Lv3_Block_Division' && ['Mask_VS4_05','Mask_MD00f'].includes(d.mask)) {
        setTimeout(() => {
          // Remove SVG
          this.removeSvg(this.CANVAS, 10)
        },1000);
        this.Lev2 = 'HULL-FOR'
        // Title & Keyplan
        d3.select(`#Title`).text(`Hull-FOR-${d.level}`)
        d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Keyplan/HULL_FOR_${d.level}.png`)

        // Hidden
        this.hiddenGroupID('#Menu_Back', 0, 10)

        this.hiddenGroupID('#Mask_HL03', 0, 10)
        this.hiddenGroupID('#Mask_MD00f', 0, 10)
        this.hiddenGroupID('#Mask_VS4_05', 0, 10)

        let coordinate = this.Coordinate.find(f=> f.l2 == this.Lev2 && f.code == d.level)

        setTimeout(() => {
          this.Hull_FOR.filter(f=> f.Lv3 !== d.level).forEach(d => {
            this.hiddenGroupID(`#Blk_${d.Blk}`, 0, 300)
          })
          // Scale
          d3.select(`#Hull_FOR_Group`)
          .transition().duration(1000).attr('transform', `translate(${coordinate.x}, ${coordinate.y}) scale(1)`)

          // Explode
          this.Hull_FOR.filter(f=> f.Lv3 == d.level).forEach(d => {
            d3.select(`#Blk_${d.Blk}`)
            .transition().duration(1000).attr('transform', `translate(${d.x4}, ${d.y4}) scale(0.5)`)
          })
        }, 1000);
        setTimeout(() => {

          this.draw_BlockCode_Lv3(d.level, coordinate.x, coordinate.y)

          this.Lev1 = 'HULL'; this.Lev2 = 'HULL-FOR'; this.Lev3 = d.level; this.Lev4 = null; 
          this.stage = this.STG_Lv3_Block_Division
          this.fncName = 'Goto_FOR_BlockDivison_Lv3'
          this.StageCode() // AREA / STAGE
          this.displayGroupID(`#Menu_Back`, 0, 200, 1)
        }, 1000);
      }

      if (d.stage == 'Division_Lv4' && d.level == 'HULL-FOR') {
        setTimeout(() => {
          // Remove SVG
          this.removeSvg(this.CANVAS, 10)
        },1000);

        // Title & Keyplan
        d3.select(`#Title`).text('Hull-FOR-HL03')
        d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Keyplan/HULL_FOR_HL03.png`)

        // Hidden
        this.hiddenGroupID('#Menu_Back', 0, 10)

        this.hiddenGroupID('#Mask_HL03', 0, 10)
        this.hiddenGroupID('#Mask_MD00f', 0, 10)
        this.hiddenGroupID('#Mask_VS4_05', 0, 10)

 

        setTimeout(() => {

        this.Hull_FOR.filter(f=> f.Lv3 !== 'HL03').forEach(d => {
          this.hiddenGroupID(`#Blk_${d.Blk}`, 0, 10)
        }) 

        d3.select(`#Hull_FOR_Group`)
        .transition().duration(1000).attr('transform', `translate(200,100) scale(0.8)`)
    
        // Explode
        this.Hull_FOR.filter(f=> f.Lv3 == 'HL03').forEach(d => {
          d3.select(`#Blk_${d.Blk}`)
          .transition().duration(1000).attr('transform', `translate(${d.x3}, ${d.y3}) scale(0.5)`)
        })
      }, 1000);

        setTimeout(() => {
          // Display
          this.displayGroupID(`#Mask_HL03_Main`, 0, 10, 0)
          this.displayGroupID(`#Mask_HL03_Forward`, 0, 10, 0)
          this.displayGroupID(`#Mask_HL03_Port`, 0, 10, 0)
          this.displayGroupID(`#Mask_HL03_Starboard`, 0, 10, 0)
          this.displayGroupID(`#Mask_HL03_Bulkhead`, 0, 10, 0)
          this.displayGroupID(`#Mask_HL03_Bottom`, 0, 10, 0)

          this.Lev1 = 'HULL'; this.Lev2 = 'HULL-FOR'
          this.stage = this.STG_Division_Lv4
          this.fncName = 'Goto_FOR_Division_Lv4'
          this.StageCode() // AREA / STAGE
          this.displayGroupID(`#Menu_Back`, 0, 200, 1)

          // ### Call Canvas ###
          this.Canvas_Division_Lv4('HULL-FOR')
        }, 1000);    
      }
  
      if (d.stage == 'Lv4_Block_Division' && ['Mask_HL03_Starboard', 'Mask_HL03_Bottom' ,
      'Mask_HL03_Bulkhead', 'Mask_HL03_After', 'Mask_HL03_Port', 'Mask_HL03_Main'].includes(d.mask)) {
       setTimeout(() => {
         // Remove SVG
         this.removeSvg(this.CANVAS, 10)
       },1000);
       this.Lev1 = 'HULL'
       this.Lev2 = 'HULL-FOR'
       // Title & Keyplan
       switch(d.level) {
         case 'PRT': d3.select(`#Title`).text('HL03_Forward Blocks'); break;
         case 'STB': d3.select(`#Title`).text('HL03_Starboard Blocks'); break;
         case 'BLK': d3.select(`#Title`).text('HL03_Bulkhead Blocks'); break;
         case 'FOR': d3.select(`#Title`).text('HL03_After Blocks'); break;
         case 'MDK': d3.select(`#Title`).text('HL03_Maindeck Blocks'); break;
         case 'BTM': d3.select(`#Title`).text('HL03_Bottom Blocks'); break;
       }
       d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Keyplan/HL03_${d.level}.png`)

       // Hidden
       this.hiddenGroupID('#Menu_Back', 0, 10)

       this.hiddenGroupID('#Mask_HL03_Main', 0, 10)
       this.hiddenGroupID('#Mask_HL03_Forward', 0, 10)
       this.hiddenGroupID('#Mask_HL03_Port', 0, 10)
       this.hiddenGroupID('#Mask_HL03_Starboard', 0, 10)
       this.hiddenGroupID('#Mask_HL03_Bulkhead', 0, 10)
       this.hiddenGroupID('#Mask_HL03_Bottom', 0, 10)
       
       let coordinate = this.Coordinate.find(f=> f.l2 == this.Lev2 && f.code == d.level)

       setTimeout(() => {

         this.Hull_FOR.filter(f=> f.Lv4 !== d.level).forEach(d => {
           this.hiddenGroupID(`#Blk_${d.Blk}`, 0, 10)
         })
         
         // Scale
         d3.select(`#Hull_FOR_Group`)
         .transition().duration(1000).attr('transform', `translate(${coordinate.x}, ${coordinate.y}) scale(1)`)
       
         // Explode
         this.Hull_FOR.filter(f=> f.Lv4 == d.level).forEach(d => {
           d3.select(`#Blk_${d.Blk}`)
           .transition().duration(1000).attr('transform', `translate(${d.x4}, ${d.y4}) scale(0.5)`)
         })
       }, 1000);

       setTimeout(() => {

         this.draw_BlockCode_Lv4('HULL-FOR', d.level, coordinate.x, coordinate.y)

         this.Lev1 = 'HULL'; this.Lev2 = 'HULL-FOR'; this.Lev3 = 'HL03'; this.Lev4 = d.level; 
         this.stage = this.STG_Lv4_Block_Division
         this.fncName = 'Goto_FOR_BlockDivison_Lv4'
         this.StageCode() // AREA / STAGE
         this.displayGroupID(`#Menu_Back`, 0, 200, 1)
       }, 1000);
     }   
      
    },

  }
}
           