import * as d3 from 'd3'

export default {
  methods: {
    draw_Legends() {

      let styleAttrs_ = JSON.parse(JSON.stringify(this.style))
      let Legends = this.SkylineCharts
      .append('g')
      .attr('transform', `translate(${this.skyline.legend.x},${this.skyline.legend.y})`)
      .attr('id', `skyline_boxes_legends`)
      .attr('fill', 'Transparent')

      Legends
      .append('rect')
      .attr('x', 0).attr('y', 0)
      .attr('width', 750).attr('height', 170)
      .attr('fill', '#fff')

      Legends
      .append('rect')
      .attr('x', 0).attr('y', 165)
      .attr('width', 380).attr('height', 350)
      .attr('fill', '#fff')

      
      Legends
      .append('text')
      .attr('x', 0).attr('y', 30)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(this.Queries.SQL1[0].DESC)
    
      styleAttrs_.forEach((style, i) => {
        
        Legends
        .append('text')
        .attr('x', style.group != 'A ITR Progress' ? 0 : style.position.x)
        .attr('y', style.position.y)
        .style('font-family', this.skyline.legend.font)
        .style('font-size', this.skyline.legend.group.tSize)
        .style('fill', this.skyline.legend.group.tColot)
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text(style.group)

        style.position.y += this.skyline.legend.group.lineSpace

        style.item.forEach((item,j) => {
          let legendItem = Legends
          .datum(item)
          .append('g')
          .attr('id', `item_${i}_${j}`)
          .attr('transform', `translate(0,0)`)
          .style('cursor', 'pointer')
          .call(this.callFuncLegend)
          
          if (style.position.rect == 'Y' ) {
            legendItem
            .append('rect')
            .attr('x', 10)
            .attr('y', style.position.y)
            .attr('width', 10)
            .attr('height', 10)
            .attr('fill', item.bColor)
            .attr('stroke', item.sColor)
            .attr('stroke-width', item.sWidth)
          }


          legendItem
          .append('text')
          .attr('id', `item_${i}_${j}_text`)
          .attr('x', style.position.rect == 'Y' ? 23 : 10)
          .attr('y', style.position.y + 6)
          .style('font-family', this.skyline.legend.font)
          .style('font-size', this.skyline.legend.item.tSize)
          .style('fill', item.tColor)
          .attr('text-anchor', 'start')
          .attr('alignment-baseline', 'middle')
          .text(() => {
            if (item.codeValue == 'NULL') return `${item.name} (${this.dataSet.filter(f => !f[item.refColumn]).length})`
            else return `${item.name} (${this.dataSet.filter(f => f[item.refColumn] == item.codeValue).length})`
          })

          if (j > 0) {
            style.position.x += this.getNodeElValue(`#item_${i}_${j-1}`, 'width') + this.skyline.legend.item.space
            d3.select(`#item_${i}_${j}`).attr('transform', `translate(${style.position.x},0)`)
          }

          if (style.group == 'A ITR Progress' && j == 0) {
            style.position.x += this.getNodeElValue(`#item_${i}_${j-1}`, 'width') - 5 // + this.skyline.legend.item.space
            d3.select(`#item_${i}_${j}`).attr('transform', `translate(${style.position.x},0)`)
          }

          if (style.position.legend == 'Y') {
            let item_ = d3.select(`#item_${i}_${j}_text`).node().getBoundingClientRect()
            let width_ = item_.width + 10
  
            legendItem
            .append('rect')
            .attr('x', 4)
            .attr('rx', 4)
            .attr('y', style.position.y - 4)
            .attr('width', width_)
            .attr('height', 18)
            .attr('fill', 'Transparent')
            .attr('stroke', item.sColor)
            .attr('stroke-width', item.sWidth)
          }
          
          if(item.on) { this.setFilter('multi', item.refColumn, item.codeValue) }

        })
      })

      // let jsonProps = this.ColumnProps.find(props_ => props_.value == this.activity)

      // this.SkylineCharts
      // .append('g')
      // .attr('transform', `translate(25,60)`)
      // .style('font-family', 'roboto').style('font-size', 26).style('fill', '#0091ea').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      // .append('text')
      // .text(jsonProps && jsonProps.title ? jsonProps.title : 'MC Skyline')

    
      // this.putFilters()
      this.putSearch()
      this.putCharts()
      this.putWeekCharts()

    },
    putSearch() {

      let searchArea_ = this.SkylineCharts
      .append('g')
      .attr('transform', `translate(20, 515)`)
      .attr('id', `skyline_boxes_legends_searchs`)

      searchArea_
      .append('rect')
      .attr('rx', 4)
      .attr('ry', 4)
      .attr('width', 181)
      .attr('height', 80)
      .attr('fill-opacity', 0)
      .attr('stroke', '#dadada')
      .attr('stroke-width', .5)

      searchArea_
      .append('rect')
      .attr('transform', `translate(10, -5)`)
      .attr('width', 55)
      .attr('height', 10)
      .attr('fill', '#fff')

      searchArea_
      .append('text')
      .attr('transform', `translate(20, 1)`)
      .style('font-family', this.skyline.legend.font)
      .style('font-size', this.skyline.legend.group.tSize)
      .style('fill', this.skyline.legend.group.tColot)
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .text('Search')

      searchArea_
      .append('rect')
      .attr('transform', `translate(15, 40)`)
      .attr('width', 150)
      .attr('height', 18)
      .attr('fill', '#fff')
      .attr('stroke', '#dadada')
      .attr('stroke-width', .5)

      let searchOptions_ = searchArea_
      .append('g')
      .attr('transform', `translate(20, 20)`)

      // ### draw search options (filtering target) in the search group box
      this.inputFilter.values.forEach((v_, i) => {
        searchOptions_
        .append('circle')
        .attr('class', `search_circle__${i}`)
        .attr('cx', v_.x)
        .attr('cy', 0)
        .attr('r', 3)
        .style('stroke', v_.on ? this.styleFilter.selected.stroke : this.styleFilter.out.stroke)
        .style('stroke-width', .5)
        .style('fill', v_.on ? this.styleFilter.selected.fill : this.styleFilter.out.fill)

        searchOptions_
        .append('text')
        .attr('class', `search_text__${i}`)
        .attr('transform', `translate(${v_.x + 7}, 1)`)
        .style('font-family', this.skyline.legend.font)
        .style('font-size', this.skyline.legend.item.tSize)
        .style('fill', v_.on ? this.styleFilter.selected.color : this.styleFilter.out.color)
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text(v_.text)

        searchOptions_
        .datum({ filter: this.inputFilter, ...v_})
        .append('rect')
        .attr('id', `search_mask__${i}`)
        .attr('transform', `translate(${v_.x - 5}, -8)`)
        .attr('width', v_.textWidth)
        .attr('height', 16)
        .attr('fill', '#000')
        .attr('fill-opacity', 0)
        .call(this.callFuncSearchFilter)

        // if(v_.on) this.setFilter(this.filterCodes[k_].attrs.type, v_.colName, v_.value)
      })
      // ### draw search input in the search group box
      searchArea_
      .append("foreignObject")
      .attr('x', 15)
      .attr('y', 40)
      .attr('width', 150)
      .attr('height', 18)
      .append('xhtml:div')
      .append('div')
      .attr('id', 'search_input')     // for the javascript document.getElementById
      .attr('class', 'search_input')  // for the d3 general purpose
      .attr('contentEditable', true)
      .attr('style', `padding: 2px 5px; font-size: 10px; line-height: 14px; text-align: left; color: ${this.skyline.legend.item.tColor}`)
      .call(this.callFuncSearchInput)

      // mask for the icon background area
      searchArea_
      .append('rect')
      .attr('transform', `translate(140, 41)`)
      .attr('width', 24)
      .attr('height', 16)
      .attr('fill', '#fff')

      searchArea_
      .append('text')
      .attr('class', 'delete_search_text')
      .attr('transform', `translate(135, 49)`)
      .style('font-family', this.skyline.legend.font)
      .style('font-size', this.skyline.legend.group.tSize)
      .style('fill', this.skyline.legend.group.tColot)
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .text('x')
      .style('cursor', 'pointer')
      .style('opacity', .8)
      .on('mouseover', (_, i, a) => { d3.select(a[i]).transition().duration(100).style('opacity', .7) })
      .on('mouseout', (_, i, a) => { d3.select(a[i]).transition().duration(100).style('opacity', .3) })
      .on('click', () => { 
        d3.select(`#${this.localId}`).select('.search_input').text('')
        this.search() 
      })

      searchArea_
      .append('image') 
      .attr('transform', `translate(148,43)`)
      .attr('xlink:href', require('../../../../../../src/assets/svg/icons/iconmonstr-magnifier-2.svg'))
      .attr('width', 12)
      .attr('height', 12)
      .style('cursor', 'pointer')
      .style('opacity', .3)
      .on('mouseover', (_, i, a) => { d3.select(a[i]).transition().duration(100).style('opacity', .7) })
      .on('mouseout', (_, i, a) => { d3.select(a[i]).transition().duration(100).style('opacity', .3) })
      .on('click', () => { this.search() })
    },
    putWeekCharts(){

      let weekChartsGroup = this.SkylineCharts
      .append('g')
      .attr('transform', `translate(0.5, 360)`)
      .attr('id', `skyline_boxes_legends_weekChartsGroup`)

      let aItrData = this.Queries.SQL10 ? this.Queries.SQL10[0] : []

      // if (aItrData) {
        weekChartsGroup.append('rect').attr('transform', `translate(20, 0)`)
        .style('width', 310).style('height', 70).style('fill', 'Transparent').attr('rx', 3).attr('ry', 3)
        .attr('stroke', '#dadada').attr('stroke-width', .5)

        weekChartsGroup.append('rect').attr('transform', `translate(30, -10)`)
        .style('width', 100).style('height', 14).style('fill', '#fff')
        
        weekChartsGroup.append('text').attr('transform', `translate(40, 1)`)
        .style('font-family', this.skyline.legend.font)
        .style('font-size', this.skyline.legend.group.tSize)
        .style('fill', this.skyline.legend.group.tColot)
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text('ITR-A Complte')

        let aItrStyle = { x: 50, y: 10 }
        this.WeekPerformanceFlatdata(weekChartsGroup, aItrData, aItrStyle)
      // }
      

      let bItrData = this.Queries.SQL11 ? this.Queries.SQL11[0] : []

      // if (bItrData) {
        weekChartsGroup.append('rect').attr('transform', `translate(350, 0)`)
        .style('width', 310).style('height', 70).style('fill', 'Transparent').attr('rx', 3).attr('ry', 3)
        .attr('stroke', '#dadada').attr('stroke-width', .5)
        
        weekChartsGroup.append('rect').attr('transform', `translate(360, -10)`)
        .style('width', 100).style('height', 14).style('fill', '#fff')
        
        weekChartsGroup.append('text').attr('transform', `translate(370, 1)`)
        .style('font-family', this.skyline.legend.font)
        .style('font-size', this.skyline.legend.group.tSize)
        .style('fill', this.skyline.legend.group.tColot)
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text('ITR-B Complte')

        let bItrStyle = { x: 380, y: 10 }
        this.WeekPerformanceFlatdata(weekChartsGroup, bItrData, bItrStyle)
      // }

      

    },
    putCharts(){
      let chartArea_ = this.SkylineCharts
      .append('g')
      .attr('transform', `translate(10, 190)`)
      .attr('id', `skyline_boxes_legends_searchs_charts`)

      // chart A ITR
      let aItr = chartArea_
      .append('g')
      .attr('transform', `translate(0.5, 0.5)`)

      let aItrData = this.Queries.SQL6[0]
      let style = {
        title: aItrData.CATEGORY,
        x: 10,
        y: 70,
        width: 50,
        height: 25,
        radius: 3,
        tSize: 12,
        tColor: '#333',
        bColor: '#E0E0DF',
        progSize: 15,
      }
      this.Header_01_( aItr, aItrData.PROG, style)

      let aItrGroup = aItr
      .append('g')
      .attr('transform', 'translate(10, 100)')

      aItrGroup.append('rect').attr('transform', `translate(0, 0)`)
      .style('width', 165).style('height', 40).style('fill', '#83D2F5').attr('rx', 3).attr('ry', 3)

      // Titles
      aItrGroup.append('text')
      .attr('transform', 'translate(40, 13)')
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').style('text-anchor', 'end')
      .text('Total')

      aItrGroup.append('text')
      .attr('transform', 'translate(100, 13)')
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').style('text-anchor', 'end')
      .text('Complete')
      
      aItrGroup.append('text')
      .attr('transform', 'translate(150, 13)')
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').style('text-anchor', 'end')
      .text('Remain')
      
      aItrGroup.append('text')
      .attr('transform', 'translate(40, 30)')
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
      .text(aItrData.TOTAL)

      aItrGroup.append('text')
        .attr('transform', 'translate(100, 30)')
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        .text(aItrData.COMPLETED)

      aItrGroup.append('text')
      .attr('transform', 'translate(150, 30)')
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
      .text(aItrData.REMAIN)


      // WD
      let wd = chartArea_
      .append('g')
      .attr('transform', `translate(185, 0.5)`)

      let wdData = this.Queries.SQL7[0]
      let wdStyle = {
        title: wdData.CATEGORY,
        x: 10,
        y: 70,
        width: 40,
        height: 25,
        radius: 3,
        tSize: 12,
        tColor: '#333',
        bColor: '#E0E0DF',
        progSize: 15,
      }
      this.Header_01_( wd, wdData.PROG, wdStyle)

      let wdGroup = wd
      .append('g')
      .attr('transform', 'translate(10, 100)')

      wdGroup.append('rect').attr('transform', `translate(0, 0)`)
      .style('width', 165).style('height', 40).style('fill', '#83D2F5').attr('rx', 3).attr('ry', 3)

      // Titles
      wdGroup.append('text')
      .attr('transform', 'translate(40, 13)')
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').style('text-anchor', 'end')
      .text('Total')

      wdGroup.append('text')
      .attr('transform', 'translate(100, 13)')
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').style('text-anchor', 'end')
      .text('Complete')
      
      wdGroup.append('text')
      .attr('transform', 'translate(150, 13)')
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').style('text-anchor', 'end')
      .text('Remain')
      
      wdGroup.append('text')
      .attr('transform', 'translate(40, 30)')
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
      .text(wdData.TOTAL)

      wdGroup.append('text')
        .attr('transform', 'translate(100, 30)')
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        .text(wdData.ACTUAL)

      wdGroup.append('text')
      .attr('transform', 'translate(150, 30)')
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
      .text(wdData.REMAIN)



      // Punch A
      let punchA = chartArea_
      .append('g')
      .attr('transform', `translate(370, 0.5)`)

      let punchAData = this.Queries.SQL8[0]
      let punchAStyle = {
        title: punchAData.CATEGORY,
        x: 10,
        y: 70,
        width: 70,
        height: 25,
        radius: 3,
        tSize: 12,
        tColor: '#333',
        bColor: '#E0E0DF',
        progSize: 15,
      }
      this.Header_01_( punchA, punchAData.PROG, punchAStyle)

      let punchAGroup = punchA
      .append('g')
      .attr('transform', 'translate(10, 100)')

      punchAGroup.append('rect').attr('transform', `translate(0, 0)`)
      .style('width', 165).style('height', 40).style('fill', '#83D2F5').attr('rx', 3).attr('ry', 3)

      // Titles
      punchAGroup.append('text')
      .attr('transform', 'translate(40, 13)')
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').style('text-anchor', 'end')
      .text('Total')

      punchAGroup.append('text')
      .attr('transform', 'translate(100, 13)')
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').style('text-anchor', 'end')
      .text('Closed')
      
      punchAGroup.append('text')
      .attr('transform', 'translate(150, 13)')
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').style('text-anchor', 'end')
      .text('Open')
      
      punchAGroup.append('text')
      .attr('transform', 'translate(40, 30)')
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
      .text(punchAData.TOTAL)

      punchAGroup.append('text')
        .attr('transform', 'translate(100, 30)')
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        .text(punchAData.ACTUAL)

      punchAGroup.append('text')
      .attr('transform', 'translate(150, 30)')
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
      .text(punchAData.REMAIN)

      

      // B ITR
      let bItrA = chartArea_
      .append('g')
      .attr('transform', `translate(555, 0.5)`)

      let bItrData = this.Queries.SQL9[0]
      let bItrStyle = {
        title: bItrData.CATEGORY,
        x: 10,
        y: 70,
        width: 50,
        height: 25,
        radius: 3,
        tSize: 12,
        tColor: '#333',
        bColor: '#E0E0DF',
        progSize: 15,
      }
      this.Header_01_( bItrA, bItrData.PROG, bItrStyle)

      let bItrGroup = bItrA
      .append('g')
      .attr('transform', 'translate(10, 100)')

      bItrGroup.append('rect').attr('transform', `translate(0, 0)`)
      .style('width', 165).style('height', 40).style('fill', '#83D2F5').attr('rx', 3).attr('ry', 3)

      // Titles
      bItrGroup.append('text')
      .attr('transform', 'translate(40, 13)')
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').style('text-anchor', 'end')
      .text('Total')

      bItrGroup.append('text')
      .attr('transform', 'translate(100, 13)')
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').style('text-anchor', 'end')
      .text('Complete')
      
      bItrGroup.append('text')
      .attr('transform', 'translate(150, 13)')
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').style('text-anchor', 'end')
      .text('Remain')
      
      bItrGroup.append('text')
      .attr('transform', 'translate(40, 30)')
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
      .text(bItrData.TOTAL)

      bItrGroup.append('text')
        .attr('transform', 'translate(100, 30)')
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        .text(bItrData.COMPLETED)

      bItrGroup.append('text')
      .attr('transform', 'translate(150, 30)')
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
      .text(bItrData.REMAIN)
    },
    putFilters() {

      let filterArea_ = this.SkylineCharts
      .append('g')
      .attr('transform', `translate(${this.skyline.legend.x}, 610)`)
      .attr('id', `skyline_boxes_legends_searchs_filters`)

      Object.keys(this.filterCodes).forEach((k_, i) => {
        let filterItem_ = filterArea_
        .append('g')
        .attr('transform', `translate(${this.filterCodes[k_].attrs.x},${this.filterCodes[k_].attrs.y})`)
        .attr('id', `skyline_boxes_legends_searchs_filters_group`)

        console.log(filterItem_)

        filterItem_
        .append('rect')
        .attr('rx', 4)
        .attr('ry', 4)
        .attr('width', this.filterCodes[k_].attrs.width)
        .attr('height', this.filterCodes[k_].attrs.height)
        .attr('fill-opacity', 0)
        .attr('stroke', '#dadada')
        .attr('stroke-width', .5)

        filterItem_
        .append('rect')
        .attr('transform', `translate(10, -5)`)
        .attr('width', this.filterCodes[k_].attrs.labelWidth)
        .attr('height', 10)
        .attr('fill', '#fff')

        filterItem_
        .append('text')
        .attr('transform', `translate(20, 1)`)
        .style('font-family', this.skyline.legend.font)
        .style('font-size', this.skyline.legend.group.tSize)
        .style('fill', this.skyline.legend.group.tColot)
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text(k_)

        let yGap_ = 20
        this.filterCodes[k_].values.forEach((v_, j) => {
          filterItem_
          .append('circle')
          .attr('class', `option_circle__${i}__${j}`)
          .attr('cx', 15)
          .attr('cy', yGap_ * (j+1))
          .attr('r', 3)
          .style('stroke', v_.on ? this.styleFilter.selected.stroke : this.styleFilter.out.stroke)
          .style('stroke-width', .5)
          .style('fill', v_.on ? this.styleFilter.selected.fill : this.styleFilter.out.fill)

          filterItem_
          .append('text')
          .attr('class', `option_text__${i}__${j}`)
          .attr('transform', `translate(23, ${yGap_ * (j+1) + 1})`)
          .style('font-family', this.skyline.legend.font)
          .style('font-size', this.skyline.legend.item.tSize)
          .style('fill', v_.on ? this.styleFilter.selected.color : this.styleFilter.out.color)
          .attr('text-anchor', 'start')
          .attr('alignment-baseline', 'middle')
          .text(v_.text)

          filterItem_
          .datum({ filters: this.filterCodes, name: k_, type: this.filterCodes[k_].attrs.type, ...v_})
          .append('rect')
          .attr('id', `option_mask__${i}__${j}`)
          .attr('transform', `translate(10, ${yGap_ * (j+1) - 8})`)
          .attr('width', this.filterCodes[k_].attrs.textWidth)
          .attr('height', 16)
          .attr('fill', '#000')
          .attr('fill-opacity', 0)
          .call(this.callFuncFilter)

          if(v_.on) this.setFilter(this.filterCodes[k_].attrs.type, v_.colName, v_.value)
        })
      })
    },

    // Event Call Functions ---------------------------------------------
    callFuncSearchFilter(selection) {
      selection
      .on('mouseover', (_, i, a) => {
        d3.select(a[i]).style('cursor', 'pointer')

        let directionEls_ = d3.select(a[i]).attr('id').split('__')

        d3.select(`#${this.localId}`)
        .select(`.search_circle__${directionEls_[1]}`)
        .style('stroke', this.styleFilter.over.stroke)
        .style('fill', this.styleFilter.over.fill)

        d3.select(`#${this.localId}`)
        .select(`.search_text__${directionEls_[1]}`)
        .style('fill', '#000')
      })
      .on('mouseout', (d, i, a) => { 
        d3.select(a[i]).style('cursor', 'default')

        let index_ = d.filter.values.findIndex(v_ => v_.text == d.text)
        let values_ = JSON.parse(JSON.stringify(d.filter.values))
        let directionEls_ = d3.select(a[i]).attr('id').split('__')

        if(values_[index_].on) {
          d3.select(`#${this.localId}`)
          .select(`.search_circle__${directionEls_[1]}`)
          .style('stroke', this.styleFilter.selected.stroke)
          .style('fill', this.styleFilter.selected.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.search_text__${directionEls_[1]}`)
          .style('fill', this.styleFilter.selected.color)
          
        } else {
          d3.select(`#${this.localId}`)
          .select(`.search_circle__${directionEls_[1]}`)
          .style('stroke', this.styleFilter.out.stroke)
          .style('fill', this.styleFilter.out.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.search_text__${directionEls_[1]}`)
          .style('fill', this.styleFilter.out.color)
        }
      })
      .on('click', (d, i, a) => {
        let index_ = d.filter.values.findIndex(v_ => v_.text == d.text)
        let values_ = JSON.parse(JSON.stringify(d.filter.values))
        let length_ = values_.length

        var prevIndex_ = d.filter.values.findIndex(v_ => v_.on)
        for(let i = 0; i<length_; i++) {
          if(values_[i].text == d.text) values_[i].on = true
          else values_[i].on = false
        } 

        d.filter.values = values_

        if(prevIndex_ != index_) {
          d3.select(`#${this.localId}`)
          .select(`.search_circle__${prevIndex_}`)
          .style('stroke', this.styleFilter.out.stroke)
          .style('fill', this.styleFilter.out.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.search_text__${prevIndex_}`)
          .style('fill', this.styleFilter.out.color)
        }

        if(values_[index_].on) {
          d3.select(`#${this.localId}`)
          .select(`.search_circle__${index_}`)
          .style('stroke', this.styleFilter.selected.stroke)
          .style('fill', this.styleFilter.selected.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.search_text__${index_}`)
          .style('fill', this.styleFilter.selected.color)
          
        } else {
          d3.select(`#${this.localId}`)
          .select(`.search_circle__${index_}`)
          .style('stroke', this.styleFilter.out.stroke)
          .style('fill', this.styleFilter.out.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.search_text__${index_}`)
          .style('fill', this.styleFilter.out.color)
        }

        let text = d3.select(`#${this.localId}`).select('.search_input').text()
        if (text.length > 0) {
          d3.select(`#${this.localId}`).select('.search_input').text('')
          this.search()
          // -- don't call Chart_filtering() after calling search(), 
          // -- it will be called in search() once for the right condition.
          // this.Chart_filtering()
        }
      })
    },
    callFuncSearchInput(selection) {
      selection
      .on('keydown', (_, i, a) => {
        if(d3.event.keyCode === 13){
          this.trimDivText(d3.select(a[i]))   // declared in Declares.mixins
          setTimeout(() => { this.setCaret(document.getElementById('search_input')) })
        }
        // this.register(this.search, 1000)       // declared in Declares.mixins
      })
      .on('keyup', (_, i, a) => {
        if(d3.event.keyCode === 13){
          this.trimDivText(d3.select(a[i]))
          this.setCaret(document.getElementById('search_input'))
          setTimeout(() => { this.search() }, 100)
        }
      })
    },
    callFuncLegend(selection) {
      selection
      .on('click', (d, i, a) => {
        d.on = !d.on
        if(d.on) d3.select(a[i]).style('opacity', 1)
        else d3.select(a[i]).style('opacity', 0.3)

        this.setFilter('multi', d.refColumn, d.codeValue)
        // this.search(false)
        this.Chart_filtering()
      })
    },
    callFuncFilter(selection) {
      selection
      .on('mouseover', (_, i, a) => {
        d3.select(a[i]).style('cursor', 'pointer')

        let directionEls_ = d3.select(a[i]).attr('id').split('__')

        d3.select(`#${this.localId}`)
        .select(`.option_circle__${directionEls_[1]}__${directionEls_[2]}`)
        .style('stroke', this.styleFilter.over.stroke)
        .style('fill', this.styleFilter.over.fill)

        d3.select(`#${this.localId}`)
        .select(`.option_text__${directionEls_[1]}__${directionEls_[2]}`)
        .style('fill', '#000')
      })
      .on('mouseout', (d, i, a) => { 
        d3.select(a[i]).style('cursor', 'default')

        let index_ = d.filters[d.name].values.findIndex(v_ => v_.text == d.text)
        let values_ = JSON.parse(JSON.stringify(d.filters[d.name].values))
        let directionEls_ = d3.select(a[i]).attr('id').split('__')

        if(values_[index_].on) {
          d3.select(`#${this.localId}`)
          .select(`.option_circle__${directionEls_[1]}__${directionEls_[2]}`)
          .style('stroke', this.styleFilter.selected.stroke)
          .style('fill', this.styleFilter.selected.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.option_text__${directionEls_[1]}__${directionEls_[2]}`)
          .style('fill', this.styleFilter.selected.color)
          
        } else {
          d3.select(`#${this.localId}`)
          .select(`.option_circle__${directionEls_[1]}__${directionEls_[2]}`)
          .style('stroke', this.styleFilter.out.stroke)
          .style('fill', this.styleFilter.out.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.option_text__${directionEls_[1]}__${directionEls_[2]}`)
          .style('fill', this.styleFilter.out.color)
        }
      })
      .on('click', (d, i, a) => {
        let index_ = d.filters[d.name].values.findIndex(v_ => v_.text == d.text)
        let values_ = JSON.parse(JSON.stringify(d.filters[d.name].values))
        let length_ = values_.length

        if(d.type == 'single') {
          var prevIndex_ = d.filters[d.name].values.findIndex(v_ => v_.on)
          for(let i = 0; i<length_; i++) {
            if(values_[i].text == d.text) values_[i].on = true
            else values_[i].on = false
          } 
        } else {
          values_[index_].on = !values_[index_].on
        }

        d.filters[d.name].values = values_

        let directionEls_ = d3.select(a[i]).attr('id').split('__')

        if(prevIndex_ >= 0 && prevIndex_ != index_) {
          d3.select(`#${this.localId}`)
          .select(`.option_circle__${directionEls_[1]}__${prevIndex_}`)
          .style('stroke', this.styleFilter.out.stroke)
          .style('fill', this.styleFilter.out.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.option_text__${directionEls_[1]}__${prevIndex_}`)
          .style('fill', this.styleFilter.out.color)
        }

        if(values_[index_].on) {
          d3.select(`#${this.localId}`)
          .select(`.option_circle__${directionEls_[1]}__${directionEls_[2]}`)
          .style('stroke', this.styleFilter.selected.stroke)
          .style('fill', this.styleFilter.selected.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.option_text__${directionEls_[1]}__${directionEls_[2]}`)
          .style('fill', this.styleFilter.selected.color)
          
        } else {
          d3.select(`#${this.localId}`)
          .select(`.option_circle__${directionEls_[1]}__${directionEls_[2]}`)
          .style('stroke', this.styleFilter.out.stroke)
          .style('fill', this.styleFilter.out.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.option_text__${directionEls_[1]}__${directionEls_[2]}`)
          .style('fill', this.styleFilter.out.color)
        }

        if(d.colName == 'DATE') {
          if(this.activity == d.value) return 

          this.activity = d.value
          this.activityChanged()

        } else {
          this.setFilter(d.type, d.colName, d.value)
          this.search(false)
          this.Chart_filtering()
        }
      })
    },
    Header_01_(selection, _data, _style) {
      let Header_01 = selection
      .append('g')
      .attr('id', '')
      .attr('transform', `translate(${_style.x}, ${_style.y})`)

      Header_01
      .append('rect')
      .attr('transform', `translate(0, 0)`)
      .attr('rx', _style.radius).attr('ry', _style.radius)
      .style('width', _style.width).style('height', _style.height).style('fill', _style.bColor)

      Header_01
      .append('text')
      .attr('transform', `translate(10, ${_style.height/2 + 1})`)
      .style('font-family', 'roboto').style('font-size', _style.tSize).style('fill', _style.tColor).style('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(_style.title)

      Header_01
      .append('text') // Delta Value
      .attr('transform', `translate(${_style.width + 5}, ${_style.height/2 + 1})`)
      .style('fill', () => {
        if (_data < 0) return '#EC407A'; else return '#44A9DF'
      })
      .style('font-family', 'roboto').style('font-size', _style.progSize).style('text-anchor', 'start').text(`${_data}%`).attr('alignment-baseline', 'middle')
    }
  }
}
