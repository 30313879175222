<template>
  <div :id="localId"></div>
</template>

<script>
import * as d3 from 'd3'
import mx_Core from './includes/mixins/importer'            // Global & Local Mixins

export default {
  name: "j-chart-donut",
  mixins: [mx_Core],
  props: {
    id: String,
    modeExport: Boolean,

    FilterValues: {
      type: Object,
      default: () => ({
        MOD: 'MOD-NO',
        MOD_DESC: 'Module Description',
        GWBS_LV2: 'ME01'
      })
    },

    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },
    DataItems: { type: Array, default: () => ([]) },
    ColumnProps: { type: Array, default: () => ([]) },
    Queries: { type: Object, default: () => ({}) },
  },

  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
  },

  mounted() {
    this.register(this.draw)
  },

  methods: {
    draw() {
      if (!this.ready2draw) return

      this.clear()
      this.setDefaultValues()
      this.drawCanvas()

      this.drawChart()
      this.drawTexts()
      this.complete()
    },

    drawChart() {
      this.Draw_Charts()
    },

  }
}
</script>