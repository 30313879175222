// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  data: () => ({
    props: [
      { module: 'LQ',  x: 80, y: 150 }
    ]
  }),
  methods: {
    Draw_Charts() {
      let data = null, style = null, tmp = null
      let overallSummary = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0.5)`)
      let module = 'HULL-FOR'
      let props = this.props.filter(d => d.module === module)[0]

      overallSummary
        .append('image')
        .attr('opacity', 1)
        .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Modules/HULL-FOR.png`)
        .attr('transform', `translate(80, 150) scale(0.8)`)

      // Overall Plan
      tmp = this.DataItems[0]
      style = {
        id: 'CERT',
        x: 110,
        y: 60,
        name: tmp.PLAN,
        sort: 'N',
      }
      data = [
        {title: 'Completed',  value: tmp.PLAN },
        {title: 'Remain',     value: 100-tmp.PLAN }
      ]
      let Circle = {
        CircleDiameter: 35,
        CircleColorSet: ['Gray', 'LightGray'],
        CircleHoleSize: 25,
        CircleStackSize: 2,
        CircleSliceSize: 5,
        CircleValueSize: 14,
        CircleTitleSize: 14,
        CircleLegendSpace: 80,
      }
      let Guideline = {
        GuideDisplay: 'N',
      }

      let chartSumamry = overallSummary.append('g').attr('transform', `translate(0, 0)`)


      chartSumamry
      .append('text')
      .attr('transform', `translate(${style.x}, ${style.y - Circle.CircleDiameter - 5})`)
      .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'middle')
      .text('Plan (%)')

      this.Chart_PieSimple(chartSumamry, data, style, Circle, Guideline)

      style = {
        id: 'SHI',
        x: 285,
        y: 60,
        name: tmp.ACTUAL,
        sort: 'N',
      }
      data = [
        {title: 'Completed',  value: tmp.ACTUAL },
        {title: 'Remain',     value: 100-tmp.ACTUAL }
      ]
      Circle.CircleColorSet = ['LightBlue', 'LightGray']
      
      chartSumamry
      .append('text')
      .attr('transform', `translate(${style.x}, ${style.y - Circle.CircleDiameter - 5})`)
      .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'middle')
      .text('Actual (%)')

      this.Chart_PieSimple(chartSumamry, data, style, Circle, Guideline)


      let box = chartSumamry
      .append('g')
      .attr('transform', `translate(160, 35)`)

      box
      .append('text')
      .attr('transform', `translate(40, 12)`)
      .style('font-size', 9)
      .style('font-family', 'roboto')
      .attr('fill', '#959595')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text('Delta (%)')

      box
      .append('text')
      .attr('transform', `translate(40, 33)`)
      .style('font-size', 14)
      .style('font-family', 'roboto')
      .attr('fill', tmp.DELTA >= 0 ? '#1e88e5' : '#bf360c')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text(`${tmp.DELTA > 0 ? '▲ ' : tmp.DELTA === 0 ? '': '▼ '}` + tmp.DELTA)

      //Week
      let week = overallSummary
      .append('g')
      .attr('id', 'week')
      .attr('transform', `translate(80, 110)`)
      
      week
      .append('rect')
      .attr('rx', 4)
      .attr('ry', 4)
      .attr('width', 240)
      .attr('height', 30)
      .attr('fill-opacity', 0)
      .attr('stroke', '#dadada')
      .attr('stroke-width', .5)

      week
      .append('rect')
      .attr('transform', `translate(196, -5)`)
      .attr('width', 40)
      .attr('height', 10)
      .attr('fill', '#fff')

      week
      .append('text')
      .attr('transform', `translate(216, 0)`)
      .style('font-size', 9)
      .style('font-family', 'roboto')
      .attr('fill', '#959595')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text('Weekly')

      week
      .append('text')
      .attr('transform', `translate(34, 17)`)
      .style('font-size', 14)
      .style('font-family', 'roboto')
      .attr('fill', '#333')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text(tmp.PLAN_WK)

      week
      .append('text')
      .attr('transform', `translate(120, 17)`)
      .style('font-size', 14)
      .style('font-family', 'roboto')
      .attr('fill', tmp.DELTA_WK >= 0 ? '#1e88e5' : '#bf360c')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text(`${tmp.DELTA_WK > 0 ? '▲ ' : tmp.DELTA_WK === 0 ? '': '▼ '}` + tmp.DELTA_WK)

      week
      .append('text')
      .attr('transform', `translate(208, 17)`)
      .style('font-size', 14)
      .style('font-family', 'roboto')
      .attr('fill', '#333')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text(tmp.ACTUAL_WK)
    },
  }
}
