export default {
  width: 120,
  gab: 10,
  attrs: [
    { 
      text1: 'Total',
      text2: '',
      refColumn: 'TOTAL_PACKS_DEV_REQ',
      codeValue   : 'Total', // fixed
      fill : 'lightGray', 
      fColor: 'Transparent', 
      tColor: '#212121',
      on: true
    },
    { 
      text1: 'Not started',
      text2: '',
      refColumn: 'TOTAL_PACKS_DEV_NOT_STARTED',
      codeValue   : 'Not started',
      fill : 'fillWhite', 
      fColor: '#BDBCBC', 
      tColor: '#818181',
      on: true
    },
    { 
      text1: 'TT Pack',
      text2: 'issued A01',
      refColumn: 'TOTAL_PACKS_DEV_A01',
      codeValue   : 'TT Pack issued A01',
      fill : 'fillLightYellow', 
      fColor: '#FDD835',
      tColor: '#fff',
      on: true
    },
    { 
      text1: 'TT Pack',
      text2: 'available for use',
      refColumn: 'TOTAL_PACKS_DEV_IFU',
      codeValue   : 'TT Pack available for use',
      fill : 'fillYellow', 
      fColor: '#FFB300', 
      tColor: '#fff',
      on: true
    },
    { 
      text1: 'TT Preparation',
      text2: 'Completed',
      refColumn: 'TOTAL_PACK_DEV_PREPARATION',
      codeValue   : 'TT Preparation Completed',
      fill : 'fillLightGreen', 
      fColor: '#8BC34A', 
      tColor: '#fff',
      on: true
    },
    { 
      text1: 'TT Test Pass',
      text2: '',
      refColumn: 'TOTAL_PACKS_TEST_PASS',
      codeValue   : 'TT Test Pass',
      fill : 'fillLightBlue', 
      fColor: '#81D4FA', 
      tColor: '#fff',
      on: true
    },
    { 
      text1: 'TT Retest Required',
      text2: '',
      refColumn: 'TOTAL_PACKS_TEST_RETEST',
      codeValue   : 'TT Retest Required',
      fill : 'fillRed', 
      fColor: '#EF9A9A', 
      tColor: '#fff',
      on: true
    },
    { 
      text1: 'TT Reinstatement',
      text2: 'Completed',
      refColumn: 'TOTAL_PACKS_TEST_COMPLETED',
      codeValue   : 'TT Reinstatement Completed',
      fill : 'fillBlue', 
      fColor: '#2196F3', 
      tColor: '#fff',
      on: true
    },
  ]
}
