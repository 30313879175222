import * as d3 from 'd3'

export default {
  methods: {
    
    call_StartUpItem(selection, d) {

      selection
      .on('mouseover', () => {
       selection.moveToFront()

        if(selection.attr('opacity') == 1 ){
          selection
          .attr('cursor', 'pointer')
          .transition().duration(150)
          .attr('transform', `translate(${d.cx}, ${d.cy})scale(1.6)`)
        } else {
          selection.attr('cursor', '')
        }
      })

      .on('mouseout', () => {
        selection
        .transition().duration(150)
        .attr('transform', `translate(${0}, ${0})scale(1)`)
      })
    },

    call_Search(input=null) {
      
      let eqList = JSON.parse(JSON.stringify(this.rawData))

      // apply search input ------------------------
      if(input != null) this.search_Input = input
      input = input.toLowerCase()
      
      let targets = ['system', 'name']
      this.searched_Data = input ? eqList.filter(f => targets.some(col => f[col].toLowerCase().includes(input))) : []
     
      this.rawData.forEach(d => {
        let searched = this.searched_Data.find(f => f.system == d.system)

        d3.select(`#LINE`).attr('opacity', .1)
        d3.select(`#TEXT`).attr('opacity', .1)

        if(searched) {
          d3.select(`#${d.system}`).attr('opacity', 1)
        } else {
          d3.select(`#${d.system}`).attr('opacity', .1)
        }

        if (this.search_Input == '') {
          d3.select(`#${d.system}`).attr('opacity', 1)
          d3.select(`#LINE`).attr('opacity', 1)
          d3.select(`#TEXT`).attr('opacity', 1)
        }
      })
    },


  }
}