import * as d3 from 'd3'

export default {
  data: () => ({
    prevActual: null,
  }),
  methods: {
    draw_LineCharts() {
      d3.select(`#${this.localId}`).select('.skyline_line_actual').remove()

      if(!this.chartMounted) this.setLineScale()

      let LineChart = this.SkylineCharts
      .append('g')
      .attr('class', 'skyline_line_actual')
      .attr('transform', `translate(${this.timeline.baseX},0)`)

      // PLAN
      let lineChartPlan = LineChart
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .attr('class', 'lineChartPlan')
      .style('opacity', 1)

      lineChartPlan.append('path')
      .attr('d', this.lineZero(this.timeline.plan))
      .style('stroke', '#424242').style('stroke-width', 2.5).style('stroke-dasharray', '4,4').attr('opacity', .5).style('fill', 'none')
      .transition()
      .duration(500)
      .attr('d', this.lineFunc(this.timeline.plan))

      lineChartPlan
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .selectAll('circle')
      .data(this.timeline.plan)
      .enter()

      .append('circle')
      .attr('cx', d => this.timeline.scale(d.eDate))
      .attr('cy', d => this.scaleY(0))
      .attr('r', 3)
      .style('stroke', '#424242').style('stroke-width', 2).attr('stroke-opacity', 0.5)
      .style('fill', '#fff')
      .transition()
      .duration(500)
      .attr('cx', d => this.timeline.scale(d.eDate))
      .attr('cy', d => this.scaleY(d.value))

      
      lineChartPlan
      .append('g')
      .attr('class', 'ChartActualValueGroup')
      .attr('transform', `translate(0, 0)`)
      .selectAll('text')
      .data(this.timeline.plan)
      .enter()
      .append('text')
      .attr('id', 'ChartActualValue')
      .attr('x', d => this.timeline.scale(d.eDate))
      .attr('y', d => this.scaleY(0))
      .style('font-family', 'roboto').style('font-size', 8.5).style('fill', '#000000').attr('text-anchor', 'middle')
      .style('font-weight', 'bold')
      .text(d => d.value)
      .transition()
      .duration(500)
      .attr('x', d => this.timeline.scale(d.eDate))
      .attr('y', d => this.scaleY(d.value)-7)
      



      // Actual
      let lineChartActual = LineChart
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .attr('class', 'lineChartActual')

      lineChartActual.append('path')
      .attr('d', this.lineZero(this.timeline.actual))
      .style('stroke', '#40C4FF').style('stroke-width', 2).attr('opacity', .95)
      .style('fill', 'none')
      .transition()
      .duration(500)
      .attr('d', this.lineFunc(this.timeline.actual))

      lineChartActual
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .selectAll('circle')
      .data(this.timeline.actual)
      .enter()

      .append('circle')
      .attr('cx', d => this.timeline.scale(d.eDate))
      .attr('cy', this.scaleY(0))
      .attr('r', 3)
      .style('stroke', '#40C4FF').style('stroke-width', 2).attr('stroke-opacity', 0.5)
      .style('fill', '#fff')
      .transition()
      .duration(500)
      .attr('cx', d => this.timeline.scale(d.eDate))
      .attr('cy', d => this.scaleY(d.value))
      

      lineChartActual
      .append('g')
      .attr('class', 'ChartActualValueGroup')
      .attr('transform', `translate(0, 0)`)
      .selectAll('text')
      .data(this.timeline.actual)
      .enter()


      .append('text')
      .attr('id', 'ChartActualValue')
      .attr('x', d => this.timeline.scale(d.eDate))
      .attr('y', d => this.scaleY(0))
      .style('font-family', 'roboto').style('font-size', 8.5).style('fill', '#EC407A').attr('text-anchor', 'middle')
      .style('font-weight', 'bold')
      .text(d => d.value)
      .transition()
      .duration(500)
      .attr('x', d => this.timeline.scale(d.eDate))
      .attr('y', d => this.scaleY(d.value)-7)
      
    }
  }
}
