import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  data: () => ({
    legend: 
    [
      { column: 'PSR_PLAN_CUM', color: '#212121', shape: 'line', text: 'Plan', axis: 'right',lineShape: 'dash', dasharray: 12, x: 0, y: 0, width: 60 },
      { column: 'PSR_FORECAST_CUM', color: '#212121', shape: 'line', text: 'Forecast', axis: 'right',lineShape: 'dash',dasharray: 4,  x: 110, y: 0, width: 50 },
      { column: 'PSR_ACTUAL_CUM', color: '#212121', shape: 'line', text: 'Actual', axis: 'right', lineShape: 'solid',dasharray: 0,  x: 230, y: 0, width: 50 },
      { column: 'RAS_ROS', color: '#000000', shape: 'line', text: 'ROS', axis: 'right', lineShape: 'solid',dasharray: 0,  x: 340, y: 0, width: 50 }
    ],
    margin: { top: 80, left: 5, bottom: 50, right: 50 },
    width: 1280,
    height: 540,
    filter: []
  }),
  methods: {
    Draw_Charts() {
      const g = d3.select(this.$el).select('svg g')
      g.selectAll('g').remove()
      
      this.svg.select('#chart_progress').remove()
      this.svg.select('#chart_legend').remove()


      let cdate = [...new Set(this.filteredList.map(d => d.CUTOFF))]

      // const list = this.cdate.map(v => 
      
      let list = []
      cdate.forEach(date => {
        let obj = {CATEGORY: date}
        obj.VALUES = []

        let filter = this.filteredList.filter(f => f.CUTOFF == date)
        filter.forEach(fdata => {
          obj.VALUES.push({WORK_TYPE_CODE: fdata.WORK_TYPE_CODE, KEY: 'PSR_PLAN_CUM', VALUE: fdata['PSR_PLAN_CUM'], COLOR: fdata['WORK_TYPE_COLOR']})
          obj.VALUES.push({WORK_TYPE_CODE: fdata.WORK_TYPE_CODE, KEY: 'PSR_FORECAST_CUM', VALUE: fdata['PSR_FORECAST_CUM'], COLOR: fdata['WORK_TYPE_COLOR']})
          obj.VALUES.push({WORK_TYPE_CODE: fdata.WORK_TYPE_CODE, KEY: 'PSR_ACTUAL_CUM', VALUE: fdata['PSR_ACTUAL_CUM'], COLOR: fdata['WORK_TYPE_COLOR']})
          obj.VALUES.push({WORK_TYPE_CODE: fdata.WORK_TYPE_CODE, KEY: 'RAS_ROS', VALUE: fdata['RAS_ROS'], COLOR: fdata['#000000']})
        })
        list.push(obj)
      })

      
      this.filter = this.legend.map(d => d.column)

      const width = this.width - 30
      const height = this.height - 15
      

      const svg = this.svg
        .append('svg')
        .attr('id','chart_wrap')
        .attr('transform', `translate(0,0)`)
        .attr('width', `${width}`)
        .attr('height', `${height}`)

      svg
        .append('g')
        .attr('id','chart_progress')
        .attr('transform', `translate(${this.margin.left},${this.margin.top+40})`)

      let legendGoup = svg
        .append('g')
        .attr('id','chart_legend')
        .attr('transform', `translate(34,65)`)

        let axisText = legendGoup
        .append('g')
        .attr('class', 'prog_group')
        .attr('transform', `translate(0, 250)`)
        
        axisText.append('text')
        .attr('class', 'yaxis_right_text')
        .style('font-size', '10px')
        .attr('transform', `translate(${this.width-35},0),rotate(-90)`)
        .style('text-anchor', 'middle')
        .style('fill','#9E9E9E')
        .text("Cumulative")

      const legendName = this.legend
      const legends = legendGoup.selectAll('.legend').data(legendName)

      const filter = this.filter
      const render = this.render

      const legend = legends
        .enter()
        .append('g')
        .attr('class', 'legend')
        .attr('transform', (d, i) => {
          return `translate(${d.x}, ${d.y})`
        })
        .style('user-select', 'none')
        .on('click', function (d) {
          const el = d3.select(this)
          const text = el.select('text')
          const rect = el.select('line')
          const column = d.column
          if (!filter.includes(column)) {
            text.attr('fill', '#000')
            rect.style('stroke', d.color)
            filter.push(column)
          } else {
            text.attr('fill', '#efefef')
            rect.style('stroke', '#efefef')
            const idx = filter.indexOf(column)
            filter.splice(idx, 1)
          }

          const newValue = list.map(d => {
            const obj = {
              CATEGORY: d['CATEGORY'],
              VALUES: d['VALUES'].filter(v => filter.includes(v['KEY']))
            }
            return obj
          })
          render(newValue)
        })

      legend
        .append('line')
        .attr('x1',0).attr('y1',5)
        .attr('x2', d=> d.width).attr('y2',5)
        .attr('stroke', d => d.color)
        .attr('stroke-dasharray', d => d.dasharray)
        .style('stroke-width', d => d.column=='RAS_ROS'? 2: 1)

      legend.append('text')
        .attr('x', d=> d.width + 5)
        .attr('y', 6)
        .attr('dy', '.35em')
        .attr('text-anchor', 'start')
        .attr('font-size', '9px')
        .attr('font-family', 'arial')
        .style('cursor', 'pointer')
        .text(d => d.text)

        render(list)
    },
    render (dataset) {
      const { top, left, bottom, right } = this.margin
      const width = this.width - left - right
      const height = this.height - top - bottom

      const g = d3.select(this.$el).select('svg g')

      const y0 = d3.scaleLinear().range([ height, 0 ])
      const y1 = d3.scaleLinear().range([ height, 0 ])

      const x0 = d3.scaleBand().range([ 0, width ])
      const x1 = d3.scaleBand().range([ 0, width ])
      //left Axis
      const leftColumns = this.legend.filter(d => d.axis === 'left').map(d => d['column'])
      const leftArr = dataset.map(d => d['VALUES']).flat().filter(v => v['VALUE'] && leftColumns.includes(v['KEY'])).map(v => v['VALUE'])
      const leftValues = Math.max.apply(null, leftArr)
      //right Axis
      const rightColumns = this.legend.filter(d => d.axis === 'right').map(d => d['column'])
      const rightArr = dataset.map(d => d['VALUES']).flat().filter(v => v['VALUE'] && rightColumns.includes(v['KEY'])).map(v => v['VALUE'])
      const rightValues = Math.max.apply(null, rightArr)

      const max = {
        left: leftArr.length === 0 ? 0 : leftValues,
        right: rightArr.length === 0 ? 0 : rightValues
      }

      max.left = max.left === 0 ? 100 : max.left * 1.5
      max.right = max.right === 0 ? 100 : max.right

      const axisLeftRange = d3.range(6).map(d => { const v = max.left / 5 * d; return String(v).indexOf('.') !== -1 ? v : Math.ceil(v) })
      const axisRightRange = d3.range(6).map(d => { const v = max.right / 5 * d; return String(v).indexOf('.') !== -1 ? v : Math.ceil(v) })

      const categoryNames = dataset.map(d => d.CATEGORY)


      let wkno = this.Queries.SQL2[0].CUTOFF
      const bars = this.legend.filter(d => d.shape === 'rect').map(d => d['column'])

      y0.domain([ 0, max.left ])
      y1.domain([ 0, max.right ])

      x0.domain(categoryNames)
      x1.domain(dataset[0].VALUES.filter(d => bars.includes(d.KEY)).map(d => d.KEY))
        .rangeRound([ 0, x0.bandwidth() ]).padding(0.15)

      const xAxis = d3.axisBottom(x0) .tickFormat(d => d).tickValues(x0.domain().filter((d,i) => { return !(i%1)}))
      //xAxis의 보이는 값을 다보여줄것인지 작게 보여줄것인지

      const yAxis = {
        left: d3.axisLeft(y0).tickValues(axisLeftRange),
        right: d3.axisRight(y1).tickValues(axisRightRange)
      }

      g.selectAll('g').remove()

      g.append('g')
        .attr('class', 'grid')
        .call(d3.axisLeft(y0).tickValues(axisLeftRange).tickSize(-width).tickFormat(''))
        .selectAll('.tick line')
        .attr('stroke', (d, k) => k > 0 ? '#efefef' : '#ccc')
        .attr('stroke-width', 1)
        .attr('shape-rendering', 'crispEdges')

      g.append('g')
        .style('font-size', '10px')
        .attr('class', 'x axis')
        // .attr('transform', `translate(${width / chartColumn},${height + 12})`)
        .attr('transform', `translate(0,${height - 20})`)
        .call(xAxis)
        .selectAll('text')
        .attr('y', 0)
        .attr('x', -55)
        .attr('dy', '.35em')
        .attr('transform', 'rotate(-90)')
        .style('text-anchor', 'middle')
        // .style('color','#424242')
        .style('color', d => d == wkno ? '#039BE5':'#424242')
        

      g.append('g')
        .attr('class', 'y axis axis-left')
        .style('font-size', '10px')
        .style('opacity', 0)
        .call(yAxis.left)

      g.append('g')
        .attr('class', 'y axis axis-right')
        .style('font-size', '10px')
        .attr('transform', `translate(${width},0)`)
        .call(yAxis.right)


      g.selectAll('.domain').remove()
      g.selectAll('.y .tick').attr('color', '#878787')
      g.selectAll('.y .tick line').remove()
      g.selectAll('.x .tick line').remove()


      const lines = dataset[0].VALUES

      const lineLength = d3.line()
        .x(d => x0(d.KEY))
        .y(d => y1(d.VALUE))
        .defined(d => { // return d.VALUE && d.VALUE >= 0 기존것 있는것만 나오기
          return d.VALUE >= 0 //0있는것은 0부터 시작하게
        })

      const linedata = []

      
      lines.forEach(c => {
        const obj = {}
        obj.KEY = c.KEY
        obj.WORK_TYPE_CODE = c.WORK_TYPE_CODE
        obj.COLOR = c.KEY =='RAS_ROS' ? '#000000' :c.COLOR 
        obj.VALUES = dataset
        .map(d => {
          // d.VALUES.find(v => {
          //   return v.KEY === c.KEY && v.WORK_TYPE_CODE == c.WORK_TYPE_CODE
          // })
          
          return {
            KEY: d.CATEGORY,
            VALUE: d.VALUES.find(v => v.KEY === c.KEY && v.WORK_TYPE_CODE == c.WORK_TYPE_CODE).VALUE
          }
        })
        obj.LINESHAPE = this.legend.find(v => v['column'] === c.KEY).lineShape
        linedata.push(obj)
      })

      const lineGroups = g
        .append('g')
        .attr('class', 'line')
        .attr('transform', `translate(${x0.bandwidth() / 2},0)`)

      
      linedata.forEach(v => {
        

        const line = lineGroups
        .append('path')
        .datum(v)
        .attr('class', d => `line-${d.KEY}-${d.WORK_TYPE_CODE}`)
        .attr('fill', 'none')
        .attr('stroke', d => d.COLOR)
        .attr('stroke-width', d => d.KEY == 'RAS_ROS' ? 2 :1)
        .attr('shape-rendering', 'geometricPrecision')
        .attr('stroke-dasharray', d => {
          const legend = this.legend.find(v => v['column'] === d['KEY'])
          if (legend) return legend.dasharray
          else return ''
        })
        .attr('d', d => lineLength(d.VALUES))
        v.LENGTH = line.node().getTotalLength()
      })
        
      // g.selectAll('.line path')
      //   .attr('stroke-dashoffset', d => d.LENGTH)
      //   // .transition()
      //   // .attr('stroke-dashoffset', 0)
      //   .attr('stroke-dasharray', d => {
      //     const legend = this.legend.find(v => v['column'] === d['KEY'])
      //     if (legend) return legend.dasharray
      //     else return ''
      //   })
      //   .attr('d', d => lineLength(d.VALUES))

      // g.selectAll('.line circle')
      //   .attr('opacity', 0)
      //   .transition()
      //   .attr('opacity', 1)
      // g.selectAll('.line text')
      //   .attr('opacity', 0)
      //   .transition()
      //   .attr('opacity', 1)

      // tooltip
      let gridRect = d3.select('.grid').node().getBoundingClientRect()

      const tooltip = this.svg.append('g').attr('class','tooltip_wrap')
      .attr('transform', `translate(${this.margin.left},${this.margin.top+40})`)

      const _this = this
        
      tooltip.selectAll('rect')
      .data(dataset)
      .enter()
      .append('rect')
      .attr('width', gridRect.width / dataset.length)
      .attr('x', d => x0(d.CATEGORY))
      .attr('y', 0)
      .attr('height', gridRect.height)
      .attr('fill', 'Transparent')
      .on('mouseover', function(d,i) {
        d3.select(`#${_this.localId}`).selectAll('.tooltipBox_obj_value').remove()
        d3.select(this).transition().duration(50).style('opacity', '0.3').attr('fill', '#EEEEEE')

        let barvaluess = d3.select(`#${_this.localId}`).select('.bar_'+ d.CATEGORY).selectAll('.text_group_value')
        barvaluess.transition().duration(50).style('opacity', 1)

        let linevaluess = d3.select(`#${_this.localId}`).selectAll('.text_group_value_'+d.CATEGORY)
        linevaluess.transition().duration(50).style('opacity', 1)

        let codes = [...new Set(d.VALUES.map(d => d.WORK_TYPE_CODE))]

        let tooltipbox = d3.select(`#${_this.localId}`).select('#tooltipBox_group')

        let tooltipY = 30

        codes.forEach(f => {
          let fdata = d.VALUES.filter(ft => ft.WORK_TYPE_CODE == f)
          fdata.forEach((obj, j) => {
            let key = obj.KEY
            
            tooltipbox.append('text')
            .attr('class', 'tooltipBox_obj_value')
            .attr('x', 20).attr('y', tooltipY)
            .style('font-weight', 'bold')
            .attr('text-anchor', 'end').style('fill', obj.COLOR)
            .text(obj.WORK_TYPE_CODE)
            

            if (key.includes('PLAN')){
              tooltipbox.append('text')
              .attr('class', 'tooltipBox_obj_value')
              .attr('x', 60).attr('y', tooltipY)
              .attr('text-anchor', 'end').style('fill','#616161')
              .text(obj.VALUE ? obj.VALUE.toLocaleString(): obj.VALUE)
            }

            if (key.includes('FORECAST')){
              tooltipbox.append('text')
              .attr('class', 'tooltipBox_obj_value')
              .attr('x', 105).attr('y', tooltipY)
              .attr('text-anchor', 'end').style('fill','#616161')
              .text(obj.VALUE ? obj.VALUE.toLocaleString() : obj.VALUE)
            }

            if (key.includes('ACTUAL')){
              tooltipbox.append('text')
              .attr('class', 'tooltipBox_obj_value')
              .attr('x', 150).attr('y', tooltipY)
              .attr('text-anchor', 'end').style('fill','#616161')
              .text(obj.VALUE ? obj.VALUE.toLocaleString() : obj.VALUE)
            }

            if (key.includes('ROS')){
              tooltipbox.append('text')
              .attr('class', 'tooltipBox_obj_value')
              .attr('x', 195).attr('y', tooltipY)
              .attr('text-anchor', 'end').style('fill','#616161')
              .text(Number(obj.VALUE.toFixed(0)).toLocaleString())
            }
            
          })
          tooltipY +=15
        })

        let tooltipboxX = x0(d.CATEGORY)
        // i == dataset.length - 1  || i == dataset.length - 2 ?  x0(d.CATEGORY) - 130: x0(d.CATEGORY)
        if (
          i == dataset.length - 1 ||
          i == dataset.length - 2 ||
          i == dataset.length - 3 || 
          i == dataset.length - 4 || 
          i == dataset.length - 5
          ) tooltipboxX -= 80

        
        if (i == 0 || i == 1 || i == 2 || i == 3 || i == 4) {
          tooltipboxX += 80
        }

        d3.select(`#${_this.localId}`).select('#tooltipBox_group').style('opacity', 1).attr('transform', `translate(${tooltipboxX},120)`)

        d3.select(`#${_this.localId}`).select('#tooltipBox_group_rect').attr('height', tooltipY - 5)

      })
      .on('mouseout', function(d) {
        d3.select(this).transition().duration(50).attr('fill', 'Transparent')

        d3.selectAll('.text_group_value').transition().duration(50).style('opacity', 0)
        d3.selectAll('.text_group_value_'+d.CATEGORY).transition().duration(50).style('opacity', 0)
        d3.select(`#${_this.localId}`).select('#tooltipBox_group').style('opacity', 0)

        d3.select(`#${_this.localId}`).selectAll('.tooltipBox_obj_value').remove()
      })

      const tooltipBox = this.svg.append('g')
      .attr('transform', `translate(${this.margin.left},${this.margin.top+40})`)
      .attr('id', 'tooltipBox_group')
      .attr('opacity', 0).attr('font-size', 10)

      tooltipBox.append('rect')
      .attr('id', 'tooltipBox_group_rect')
      .attr('width', 205).attr('height', 50)
      .attr('x', 0).attr('y', 5).attr('rx', 5)
      .attr('fill', '#FFFDE7').style('opacity', .8)

      tooltipBox.append('text')
      .attr('class', 'tooltipBox')
      .attr('x', 40).attr('y', 15)
      .attr('text-anchor', 'start').style('fill','#616161')
      .text('Plan')

      tooltipBox.append('text')
      .attr('class', 'tooltipBox')
      .attr('x', 85).attr('y', 15)
      .attr('text-anchor', 'start').style('fill','#0288D1')
      .text('Fore.')

      tooltipBox.append('text')
      .attr('class', 'tooltipBox')
      .attr('x', 133).attr('y', 15)
      .attr('text-anchor', 'start').style('fill','#2E7D32')
      .text('Act.')

      tooltipBox.append('text')
      .attr('class', 'tooltipBox')
      .attr('x', 175).attr('y', 15)
      .attr('text-anchor', 'start').style('fill','#000000')
      .text('ROS')
      
      // tooltipBox.append('text')
      // .attr('class', 'tooltipBox')
      // .attr('x', 30).attr('y', 30)
      // .attr('text-anchor', 'end').style('fill','#E65100')
      // .text('Week.')
      
      // tooltipBox.append('text')
      // .attr('class', 'tooltipBox')
      // .attr('x', 30).attr('y', 45)
      // .attr('text-anchor', 'end').style('fill','#E65100')
      // .text('Cum.')


      // tooltipBox.append('text')
      // .attr('id', 'TOOLTIP_PSR_PLAN_WK')
      // .attr('x', 60).attr('y', 30)
      // .attr('text-anchor', 'end')

      // tooltipBox.append('text')
      // .attr('id', 'TOOLTIP_PSR_FORECAST_WK')
      // .attr('x', 95).attr('y', 30)
      // .attr('text-anchor', 'end')

      // tooltipBox.append('text')
      // .attr('id', 'TOOLTIP_PSR_ACTUAL_WK')
      // .attr('x', 130).attr('y', 30)
      // .attr('text-anchor', 'end')

      // tooltipBox.append('text')
      // .attr('id', 'TOOLTIP_PSR_PLAN_CUM')
      // .attr('x', 60).attr('y', 45)
      // .attr('text-anchor', 'end')

      // tooltipBox.append('text')
      // .attr('id', 'TOOLTIP_PSR_FORECAST_CUM')
      // .attr('x', 95).attr('y', 45)
      // .attr('text-anchor', 'end')

      // tooltipBox.append('text')
      // .attr('id', 'TOOLTIP_PSR_ACTUAL_CUM')
      // .attr('x', 130).attr('y', 45)
      // .attr('text-anchor', 'end')
    },
  }
}
