import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  methods: {
    draw_Timeline2020() {
      // Title -------------------------------------------------------------------------------------------------------------------------
      // let headerTitle = this.milestoneCharts
      // .append('g')
      // .attr('transform', `translate(0, 0)`)

      // headerTitle
      // .append('text')
      // .attr('x', 0).attr('y', 45).style('font-size', 24).style('fill', '#B4E3FA').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      // .text('for Construction')

      this.loadSvg(70, 85, '/TORTUE/Milestones/Legends.svg')

      // Timeline -------------------------------------------------------------------------------------------------------------------------
      let mainTimeline = this.milestoneCharts
      .append('g')
      .attr('transform', `translate(0, 100)`)

      mainTimeline
      .append('path') // shadow
      .attr('d', `M50,42 H ${this.timelineWidth-50} L ${this.timelineWidth},52 H0 Z`).attr('fill', `url(#shadowTimeline)`) 

      mainTimeline
      .append('rect')
      .attr('x', 0).attr('y', 0).attr('width', this.timelineWidth).attr('height', 40).attr('fill', `url(#${this.localId}__LinearA4LightGray0)`).attr('opacity', .5)

      mainTimeline
      .append('rect') 
      .attr('x', 0).attr('y', 40).attr('width', this.timelineWidth).attr('height', 6).attr('fill', `url(#${this.localId}__LinearA4LightGray0)`)

      // Timeline Text using the scale function
      // Year
      mainTimeline
      .append('g')
      .selectAll('text')
      .data(this.timelineValues.year)
      .enter()
      .append('text')
      .attr('x', d => this.timeScale(d.value))
      .attr('y', 15).style('font-size', 15).style('fill', '#EC407A').attr('font-weight', 500)
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(d => d.name.toUpperCase())

      // Month
      mainTimeline
      .append('g')
      .selectAll('text')
      .data(this.timelineValues.month)
      .enter()
      .append('text')
      .attr('x', d => this.timeScale(d.value))
      .attr('y', 30).style('font-size', 10).style('fill', '#333')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(d => d.name.toUpperCase())

      // Timeline Seperator
      mainTimeline
      .append('g')
      .selectAll('path')
      .data(this.timelineValues.month)
      .enter()
      .append('path')
      .attr('d', (d, i) => {
        if (this.timelineValues.month.length-1 > i) {
          if (d.name == 'Dec')return `M${this.timeScale(d.endDate)}, 7 V35`
          else return `M${this.timeScale(d.endDate)}, 25 V35`
        }
      })
      .attr('stroke', '#757575').attr('stroke-width', 0.3)

      // 2020 Modulws -------------------------------------------------------------------------------------------------------------------------
      this.modules2020 = this.milestoneCharts
      .append('g')
      .attr('transform', `translate(0, 200)`)

      this.draw_TimelineModule()
      // this.draw_GanttChart()
    }, 
  }
}
