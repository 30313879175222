// -------------------------------------------------------------------------------------- Global
import { SafeIdMixin } from '../../../../../../includes/mixins/safeid.mixin'                // ATAGlance Framework Globals
import gmx_Controller from '../../../../../../includes/mixins/Controller.mixin'       // SVG Control
// Global Drawings
import gmx_Canvas from '../../../../../../includes/mixins/Draw_Canvas.mixin'
import gmx_Texts from '../../../../../../includes/mixins/Text_Draw.mixin'
// Global Functions
import gmx_Timeline from '../../../../../../includes/mixins/Timeline.mixin'
import gmx_Miscellaneous from '../../../../../../includes/mixins/Miscellaneous.mixins'
import gmx_Movable from '../../../../../../includes/mixins/Movable.mixin'
// -------------------------------------------------------------------------------------- Functional Charts
import Cht_Custom_BarCharts from    '../../../../../../09_Projects/Tortue/include/ChartLibrary/Custom_BarCharts.mixin'
import Cht_Custom_Header from       '../../../../../../09_Projects/Tortue/include/ChartLibrary/Custom_Header.mixin'
import Cht_Custom_SummaryTable from '../../../../../../09_Projects/Tortue/include/ChartLibrary/Custom_SummaryTable.mixin'
import Cht_Custom_Tables from       '../../../../../../09_Projects/Tortue/include/ChartLibrary/Custom_Tables.mixin'

// Locally declared common Data-Props & SVG Drawing Support
import lmx_ReactiveEvents from './Realtime_Monitor.mixin' // Reactive Events
import lmx_ChartStyleData from './ChartStyleData.mixin'
import lmx_Declares from './Declares.mixin'
import lmx_Charts from './Draw_Charts.mixin'
import lmx_Table from './Draw_Table.mixin'


export default {
  mixins: [
    SafeIdMixin,
    gmx_Controller,
    gmx_Canvas,
    gmx_Texts,
    gmx_Timeline,
    gmx_Miscellaneous,
    gmx_Movable,

    // Functional Charts
    Cht_Custom_BarCharts,
    Cht_Custom_Header,
    Cht_Custom_SummaryTable,
    Cht_Custom_Tables,

    lmx_ReactiveEvents,
    lmx_ChartStyleData,
    lmx_Declares,
    lmx_Charts,
    lmx_Table
  ],
}
