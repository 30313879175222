export default {
  watch: {
    DataItems: { handler(val) {
      if(!val || val.length === 0) return; 
      this.register(this.draw); 
    }, deep: true },

    callback: { handler(d) { this.onCallbackData(d) }, deep: true },
  },
}
