import * as d3 from 'd3'

export default {
  data: () => ({
    dataSet: [],
    axisYHeight: 140,
    axisXWidth: 190,
    TrapezoidX:32,
    TrapezoidY:30,
    TrapezoidC:16,
    innerSvg: null,
    keyPlanSvg: null,
    Module: '41M001',
  }),
  computed: {

    ready2draw() {

      // console.log('------------------------------')
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)

      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },

  },



  methods: {
    setDefaultValues(){
      this.dataSet = JSON.parse(JSON.stringify(this.DataItems))
      
      this.dataSet.forEach(data => {
        //
      })

      
      this.svg
      .append('clipPath')
      .attr('id', 'activity_overflow_none_discipline')
      .append('rect')
      .attr('transform', `translate(180, 0)`)
      .attr('width', 280)
      .attr('height', 20)
      
    },

    
    
    
  }
}