import * as d3 from 'd3'
import __M from 'moment'

export default {
  methods: {
    
    draw_Timeline() {
      d3.select(`#skyline_boxes_timeline`).remove()
      

      let Timeline = this.SkylineCharts
      .append('g')
      .attr('transform', `translate(${this.timeline.baseX - 3.5}, ${this.timeline.baseY})`)
      .attr('id', `skyline_boxes_timeline`)


      Timeline
      .append('rect') 
      .attr('x', 0).attr('y', 0).attr('width', this.timeline.length).attr('height', 2).attr('fill', `url(#${this.localId}__LinearA4LightGray0)`).attr('opacity', .99)

      Timeline
      .append('rect')
      .attr('x', 0).attr('y', 2).attr('width', this.timeline.length).attr('height', this.timeline.height).attr('fill', `url(#${this.localId}__LinearA4LightGray0)`).attr('opacity', .5)

      Timeline
      .append('rect') 
      .attr('x', 0).attr('y', this.timeline.height + 1).attr('width', this.timeline.length).attr('height', 6).attr('fill', `url(#${this.localId}__LinearA4LightGray0)`)

      // let singleWidth = this.timeline.length / this.timeline.months.length

      // console.log(singleWidth)

      // if (this.timeline.months.filter(f => __M(f.date).format('YY-MM') == __M(new Date(this.cutoff)).format('YY-MM')).length > 0) {
      //   Timeline
      //   .append('rect')
      //   .attr('x', this.timeline.scale(__M(new Date(this.cutoff)).startOf('month'))+14)
      //   .attr('y', 2).attr('width', (this.timeline.length / this.timeline.months.length) - 10)
      //   .attr('height', this.timeline.height)
      //   .attr('fill', '#90CAF9').attr('opacity', .5)
      // }



      this.timeline.months.forEach((m, i) => {
        let thisMonth = __M(m.date).format('YY-MM') == __M(new Date(this.cutoff)).format('YY-MM')

        let drawX = ((this.skyline.box.width + (this.skyline.box.gap)) * i) + ((this.skyline.box.width / 2) + (this.skyline.box.gap / 2))
        
        Timeline
        .append('g')
        .append('text')
        .attr('x', drawX )
        .attr('y', this.timeline.style.week.height)
        .style('font-family', this.timeline.font)
        .style('font-size', thisMonth ? this.timeline.style.month.tSize + 3 : this.timeline.style.month.tSize)
        .attr('fill', thisMonth ? '#00B0FF' : this.timeline.style.month.tColor)
        .attr('font-weight', thisMonth ? 'bold' : 'normal')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'hanging')
        .text(m.name.toUpperCase())

        let drawPathX = ((this.skyline.box.width + (this.skyline.box.gap)) * i) + ((this.skyline.box.width) + (this.skyline.box.gap))
        
        Timeline
        .append('g')
        .append('path')
        .attr('d', () => {
          if (i != this.timeline.months.length-1) return `M${drawPathX}, ${this.timeline.style.week.line} V${this.timeline.style.week.line + this.timeline.style.month.line}`
        })
        .attr('stroke', this.timeline.style.month.sColor)
        .attr('stroke-width', this.timeline.style.month.sWidth)

      })



      // // Year Text & Timeline Seperator for the Year
      if (this.timeline.style.year.display == 'Y') {
        Timeline
        .append('g')
        .selectAll('text')
        .data(this.timeline.year)
        .enter()
        .append('text')
        .attr('x', d => this.timeline.scale(d.mDate) + 80)
        .attr('y', this.timeline.style.week.height +  this.timeline.style.month.height)
        .style('font-family', this.timeline.font)
        .style('font-size', this.timeline.style.year.tSize)
        .attr('fill', this.timeline.style.year.tColor)
        .attr('font-weight', 500)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'hanging')
        .text(d => d.name)

   
      }


    }, 
  }
}
