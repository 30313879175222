import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({
  }),
  methods: {
    drawChart() {
      let itemWidth = 120
      let itemHeight = itemWidth+10

      let svg = this.svg
      let group = svg.append('g').attr('class', 'topsides_group__').attr('transform', `translate(0, 0)`)

      group.append('rect').attr('class', 'background_bar__')
      .attr('x', 0).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', itemWidth).attr('height', 150).attr('fill', '#eee').attr('opacity', '0.7')

      this.module.forEach((v, i) => {

        let g = group.append('g').attr('class', `_status_${v}_`).attr('transform', `translate(0, 0)`)

        // Image box
        g.append('image')
        .attr('id', `moduleImage`)
        .attr('transform', `translate(${i*itemWidth}, 0)`)
        .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Modules/AreaDashboard/${v}.png`)
        .attr('width', itemWidth + 'px')
        .attr('height', itemWidth + 'px')
        

        g.append('rect')
        .attr('x', itemWidth*i+5).attr('y', itemHeight-10).attr('rx', 3).attr('ry', 3).attr('width', itemWidth*0.9).attr('height', 24)
        .attr('fill', '#fff').attr('stroke','#BDBDBD').attr('stroke-width', .3)
        // Module text
        g.append('text')
        .attr('x', itemWidth*i+itemWidth/2).attr('y', itemHeight+6).attr('text-anchor', 'middle')
        .text(v)
        .style('font-size',12)
        .style('color', '#212121')

        // Mask
        let mask = g.append('rect').attr('class', 'group_mask__')
        .attr('x', `${itemWidth*i}`).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', itemWidth).attr('height', 150).attr('fill', 'Transparent')

        mask.on('mouseover', () => {
          mask.style('cursor', 'pointer')
          d3.select('.background_bar__').transition().duration(100)
          .attr('x', `${itemWidth*i}`)
          .attr('y', 0)
        })

        mask.on('click', () => {
          let dataSource = {
            GWBS_LV3: v
          }

          let request_ = this.getRequestColumnProps('level3', dataSource, this.ColumnProps)
          this.$emit('request-action', request_)
        })
      })
    },
  }
}