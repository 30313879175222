import * as d3 from 'd3'
// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  data:() => ({
    attrs: [
      { 
        text: 'Total',
        refColumn: 'TOTAL',
        fColor: '#BDBCBC', 
        tColor: '#818181'
      },
      { 
        text: 'Completed',
        refColumn: 'COMPLETED',
        fColor: '#4CAF50',
        tColor: '#4CAF50'
      },
      { 
        text: 'Remain',
        refColumn: 'REMAIN',
        fColor: '#F44336', 
        tColor: '#2E7D32'
      },
    ],
    location: [
      {ROOM:"DECK_A"    ,DESCR:"Level A"        ,x: 0,y: 0,     Path:'level_hull_deck_detail'},
      {ROOM:"HULL_LEVEL",DESCR:"Level Hull deck",x: 600,y: 0,   Path:'level_a_detail'},
      {ROOM:"LEVEL1"    ,DESCR:"Level 1"        ,x: 0,y: 330,   Path:'level_1_detail'},
      {ROOM:"LEVEL2"    ,DESCR:"Level 2"        ,x: 600,y: 330, Path:'level_2_detail'},
      {ROOM:"LEVEL3"    ,DESCR:"Level 3"        ,x: 0,y: 660,   Path:'level_3_detail'},
      {ROOM:"LEVEL4"    ,DESCR:"Level 4"        ,x: 600,y: 660, Path:'level_4_detail'},
      {ROOM:"LEVEL5"    ,DESCR:"Level 5"        ,x: 0,y: 990,   Path:'level_5_detail'},
      {ROOM:"LEVEL6"    ,DESCR:"Level 6"        ,x: 600,y: 990, Path:'level_6_detail'},
    ],

    selectedStartDate: null,
    selectedStartColumn: null,

    selectedFinishDate: null,
    selectedFinishColumn: null,

    filteredList: null
  }),
  methods: {
    Draw_Charts() {
      let svg = this.svg

      this.filteredList = this.DataItems

      let Level = this.location.map(d =>d.ROOM)


      this.location.forEach((d,i) => {
        // console.log(d)
        let matchLevel = this.DataItems.filter(e => e.LEVEL == d.ROOM)[0]
        // console.log(matchLevel)
        
        let group = svg.append('g').attr('class', 'imagegroup__').attr('transform', `translate(${d.x}, ${d.y})`)
        
        let image = group.append('g').attr('class', 'image__').attr('transform', `translate(0,0)`)
        image
        .append('image')
        .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Room/${matchLevel.LEVEL}.png`)
        .attr('transform', `translate(150, 0) `)
        .on('mouseover', () => image.style('opacity', 0.7))
        .on('mouseleave', () => image.style('opacity', 1))
        .style('cursor', 'pointer')
        .on('click', () => {
          console.log(matchLevel.LEVEL)
        let request_ = {
          dataType: 'row',    
          action: {
            type: 'direct',
            target: 'url',
            path: `/service/system_completion/commissioning_dashboards/lq_habitation_readiness/${d.Path}`
          },
          filters: {}
        }
        this.$emit('request-action', request_)


          // let request_ = this.getRequestColumnProps('level', {}, this.ColumnProps)
          // if(request_) this.$emit('request-action', request_)
        })

        let chart = group.append('g').attr('class','chart').attr('transform', `translate(10,30)`)
        chart
        .append('text').attr('transform', `translate(0, 0)`)
        .style('font-family','roboto')
        .style('font-size',16).style('font-weight','bold')
        .text(matchLevel.LEVEL_DESCR)

        let chartBar = chart.append('g').attr('class','chartbar').attr('transform', `translate(0, 0)`)
        const barScale = d3.scaleLinear()
        .domain([0,100])
        .range([0,120])
        
        //PROG Background Bar
        chartBar
        .append('rect')
        .attr('x', 0).attr('y', 18).attr('width', 120).attr('height', 20)
        .attr('fill', '#fff').attr('stroke', '#8BC34A') .attr('stroke-width', 0.5)
                
        //PROG Actual Bar
        chartBar
        .append('rect')
        .attr('x', 0).attr('y', 18)
        .attr('width',0)
        .attr('height',20)
        .attr('fill','#8BC34A')

        .transition().duration(150)
        .attr('width',barScale(matchLevel.PROG))

        chartBar
        .append('text')
        .attr('x', barScale(matchLevel.PROG)).attr('y', 32)
        .style('font-family','roboto').style('font-size',12)
        .attr('text-anchor', matchLevel.PROG <=30 ?'start':'end')
        .attr('fill',matchLevel.PROG >=60 ? '#fff' : '#212121')
        .text(matchLevel.PROG ? matchLevel.PROG + '%' : '')

        let chartSummary = chart.append('g').attr('class','chartsummary').attr('transform', `translate(0, 0)`)
        let chartSummaryRect = chartSummary.append('g').attr('class','chartsummaryRect').attr('transform', `translate(0, 0)`)

        this.attrs.forEach((attr, i)=>{
          chartSummaryRect
          .append('rect')
          .attr('transform', `translate(70, ${50+i*36})`)
          .attr('width',50).attr('height',30).attr('rx',5).attr('ry',5).attr('stroke','#ccc').attr('stroke-width',0.3).attr('fill', attr.fColor)
          .style('cursor', 'pointer')
          .on('mouseover', function() {
            d3.select(this).transition().duration(50)
            .style('opacity', 0.5)
          })
          .on('mouseout', function(){
            d3.select(this).transition().duration(50)
            .style('opacity', 1)
          })
          .on('click', () => {
            console.log(matchLevel.LEVEL)
            let filter = ``
            if (attr.refColumn == 'REMAIN') filter = ` [ROOM_STATUS_CODE] != 'AREACOMPLETION_COM' `
            if (attr.refColumn == 'COMPLETED') filter = ` [ROOM_STATUS_CODE] = 'AREACOMPLETION_COM' `
            //filterString을 써야하는데 조건이 있을때 하는 방법
            let request_ = {
              dataType: 'row',
              action: {
                type: 'direct',
                target: 'slide-modal',
                component: 'ServiceDatatable',
                id: 313,
                no: ''
              },
              filters: { 
                LEVEL: matchLevel.LEVEL
              },
              iFilters: {
                filterString : filter,
                inputFilter  : ''
              }
            }
            console.log(filter)
            this.$emit('request-action', request_)
          })
          chartSummaryRect
          .append('text').attr('transform', `translate(110, ${70+i*36})`)
          .style('font-family','roboto')
          .style('font-size',12).style('fill','#fff').attr('text-anchor', 'end')
          .text(matchLevel[attr.refColumn])
          

          chartSummary
          .append('text').attr('transform', `translate(0, ${70+i*36})`)
          .style('font-family','roboto')
          .style('font-size',12)
          .text(attr.text)
        }) 
      })
    },
  }
}
