import * as d3 from 'd3'
import { svg } from 'd3'
// ------------------------------------------------------------------------------- Global

export default {
  methods: {
    Draw_Charts() {

    if(!this.DataItems[0].TEXT1 && !this.DataItems[0].TEXT1_DATE){
      let nosvg = this.svg
      nosvg.append('text').attr('x', 110/2).attr('y', 130/2).attr('text-anchor', 'middle')
      .text('N/A').attr('font-size', '30px')
      .attr('fill', '#ccc')
      return
    }

      let item = this.DataItems[0]
      let chart = this.svg

      let ovdata = chart
      .append('g')
      .attr('transform', `translate(10, 20)`)

      ovdata
      .append('rect')
      .attr('x', 0).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', 90).attr('height', 36)
      .attr('fill', '#ffffff').attr('stroke', '#8BC34A') .attr('stroke-width', 0.5)
      ovdata
      .append('text')
      .attr('x', 90/2).attr('y', 13)
      .style('font-family','roboto').style('font-size',11).attr('text-anchor', 'middle').attr('fill', '#424242')
      .text(item.TEXT2 ? item.TEXT2 : '')

      const barScale = d3.scaleLinear()
      .domain([0,100])
      .range([0,90])
      
      //PROG Background Bar
      ovdata
      .append('rect')
      .attr('x', 0).attr('y', 18).attr('width', 90).attr('height', 20)
      .attr('fill', '#fff').attr('stroke', '#8BC34A') .attr('stroke-width', 0.5)
              
      //PROG Actual Bar
      ovdata
      .append('rect')
      .attr('x', 0).attr('y', 18)
      .attr('width',0)
      .attr('height',20)
      .attr('fill','#8BC34A')

      .transition().duration(150)
      .attr('width',barScale(item.ACTUAL))

      ovdata
      .append('text')
      .attr('x', 90/2).attr('y', 31)
      .style('font-family','roboto').style('font-size',11).attr('text-anchor', 'middle')
      .attr('fill',item.ACTUAL >=60 ? '#fff' : '#424242')
      .text(item.TEXT2_PROG ? item.TEXT2_PROG + '%' : '')

      let datedata = chart
      .append('g')
      .attr('transform', `translate(10, 70)`)

      datedata
      .append('rect')
      .attr('x', 0).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', 90).attr('height', 18)
      .attr('fill', '#fff').attr('stroke', '#9E9E9E') .attr('stroke-width', 0.5)
      datedata
      .append('text')
      .attr('x', 90/2).attr('y', 13)
      .style('font-family','roboto').style('font-size',11).attr('text-anchor', 'middle').attr('fill', '#424242')
      .text(item.TEXT1 ? item.TEXT1 : '')

      datedata
      .append('rect')
      .attr('x', 0).attr('y', 18).attr('width', 90).attr('height', 20)
      .attr('fill', '#9E9E9E').attr('stroke', '#9E9E9E') .attr('stroke-width', 0.5)
      datedata
      .append('text')
      .attr('x', 90/2).attr('y', 33)
      .style('font-family','roboto').style('font-size',12).attr('text-anchor', 'middle').attr('fill', '#FFF')
      .text(item.TEXT1_DATE ? item.TEXT1_DATE : '')

      

    },
  }
}
