
export default {
  methods: {
    
    setEvent_PDF() {
      String.prototype.replaceAll = function(org, dest) {
        return this.split(org).join(dest);
      }
      let convert = this.getSelectItems.replaceAll('},' , '}|')
      let dataSet = convert.split('|')
      dataSet.forEach((getData) => {
        let d = JSON.parse(getData)
        this.gotoStage(d.stage)
        if (d.item != '' && d.item != 'ALL') {
          setTimeout(() => {
            this.levelCode = d.item
            this.loadInformation('item')
          },1300)
        }else if (d.item == 'ALL') {
          setTimeout(() => {
            this.loadInformation()
          },1300)
        }
      })
    },
  }
}