import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  methods: {
    
    draw_Contents() {
      this.Contents = this.svg.append('svg')

      this.Contents
      .attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight)
      .style('opacity', 1)

      let item = this.Erection.find(f=> f.SN == this.slideSeq)
      
      let Contents = this.Contents
      .append('g')
      .attr('transform', `translate(50, 198)`)
      
      Contents
      .append('text')
      .attr('transform', `translate(0, 0)`)
      .style('font-family', 'roboto').style('font-size', 16).style('fill', '#44A9DF').attr('text-anchor', 'start').attr('alignment-baseline', 'middle').attr('font-weight', 400)
      .text(`Stage. ${this.slideSeq}`)

      Contents
      .append('text')
      .attr('transform', `translate(80, 0)`)
      .style('font-family', 'roboto').style('font-size', 14).style('fill', '#000').attr('text-anchor', 'start').attr('alignment-baseline', 'middle').attr('font-weight', 400)
      .text(`${item.title}`)      

      item.Contents.forEach(d => {
        let style = {
          x: 0,
          y: d.y + 20,
          text: d.text,
          tColor: d.tColor,
          width: 315,
          strokeWidth: 0,
          textAlign: 'start',
          fontSize: 11,
          height: d.height,
          verticalAlign: 'top',
          lineHeignt: 18,
        }
        this.multiLineText(Contents, style)
      })

      Contents
      .append('image')
      .attr('id', 'YARD')
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/ErectionTileline//Erec_${this.slideSeq}.jpg`)
      .attr('transform', `translate(330, 23)`)
      .style('opacity', 0)
      d3.select(`#YARD`).transition().duration(800).style('opacity', 1)
      
      d3.select('#dropdown_wrapper').bringToFront() 
      // this.content를 지우고 다시 그리니까 layer순서가 뒤로가서 가려진다 다시 리로드할때 맨 앞으로 와라
    },
  }
}
           