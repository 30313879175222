<template>
  <div :id="localId" class="svg_wrapper"></div> 
</template>

<script>
import mx_Core from './includes/mixins/zMixins'
import * as d3 from 'd3'

export default {
  name: "j-level1-schedule-new",
  mixins: [mx_Core],
  props: {
    id: String,
    modeExport: Boolean,
    getSelectItems: String,  

    FilterValues: { type: Object, default: () => ({}) },
    ColumnProps: { type: Array, default: () => ([]) },
    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },
    Note: { type: Object, default: () => ({}) },
    DataItems: { type: Array, default: () => ([]) },
    Queries: { type: Object, default: () => ({}) },

    callback: { type: Object, default: () => ({}) },
    //Milestone: { type: Array, default: () => ([]) },
    Action: { type: Object, default: () => ({}) },
    values: { type: Object, default: () => ({}) }
  },

  created() {
    this.localId = this.id || 'j-chart-custom__' + this.safeId('')
  },

  mounted() {
    this.register(this.draw)
  },

  methods: {
    draw() {
      // Check all the necessary values are available
      if (!this.ready2draw) return

      this.clear()
      this.drawCanvas()
      this.set_LocalValues() 

      this.func_Controller()

      this.drawTexts()
      this.drawNotes()
      
      this.complete()

    },

  }
}
</script>


<style lang="scss" scoped>
  .svg_wrapper {
    width: 1600px;
    height: 1400px;

    overflow: hidden;
  }
</style>