import * as d3 from 'd3'

export default {
  methods: {
    
    draw_Checkbox() {

      let select_package = this.innerSvg
      .append('g')
      .attr('id', 'package_group')
      .attr('transform', `translate(30, 150)`)
      
      select_package
      .append('rect')
      .attr('rx', 3).attr('ry', 3)
      .attr('width', 280).attr('height', 50)
      .attr('fill', '#fff').attr('stroke', '#dadada')
      .attr('stroke-width', .5)

      select_package
      .append('rect')
      .attr('transform', `translate(12, -5)`)
      .attr('width', 55).attr('height', 10).attr('fill', '#fff')

      select_package
      .append('text')
      .attr('transform', `translate(17, 1)`)
      .style('font-size', 12).attr('alignment-baseline', 'middle')
      .text('Pakcage')
      
      let x_ = 10

      this.Legend.forEach((d, i) => {

        let check = d3.select('#package_group')
        .append('g')
        .attr('id', `package_checkbox_area__${d.CODE}`)
        .attr('transform', `translate(${x_}, 27)`)
        .style('cursor', 'pointer')
        .call(this.call_CheckList, d) // ---> Event.js

        check
        .append('rect')
        .attr('x', x_ - 10).attr('y', -10).attr('width', 70).attr('height', 20)
        .style('opacity', 0)

        check
        .append('circle')
        .attr('id', `package_checkbox__${d.CODE}`)
        .attr('cx', x_ + 10).attr('cy', 0).attr('r', 6)
        .attr('stroke', d.bcolor).attr('stroke-width', 2)
        .style('fill-opacity', 0)

        check
        .append('circle')
        .attr('id', `package_checkbox_checked__${d.CODE}`)
        .attr('cx', x_ + 10).attr('cy', 0).attr('r', 3)
        .style('fill', d.bcolor)
        .style('visibility', d.CHECKED ? 'visible' : 'hidden')

        check
        .append('text')
        .attr('id', `package_label__${d.CODE}`)
        .attr('x', x_ + 27).attr('y', 5)
        .style('font-family', 'roboto').style('font-size', 12).style('fill', '#000')
        .text(d.NAME)

        x_ += 40
      })

    }
  }
}
