import * as d3 from 'd3'

export default {
  data: () => ({
    floatGroup: null,
    path: {
      // ME01: '/topside_dashboard/me01_flash_gas_compression',
      // ME02: '/topside_dashboard/me02_meg_and_fuel_gas',
      // ME04: '/topside_dashboard/me04_power_generator',
      // ME05: '/topside_dashboard/me05_e_i_building',
      // MW01: '/topside_dashboard/mw01_recep_fac_prod_sepa_',
      // MW02: '/topside_dashboard/mw02_hc_dew_cond_stab_',
      // MW04: '/topside_dashboard/mw04_power_generator',
      // MW05: '/topside_dashboard/mw05_utility',
      // HULLFOR: '/hull_dashboard/hull_for',
      // HULLAFT: '/hull_dashboard/hull_aft',
      // LQ: '/lq_dashboard'
    }
  }),
  methods: {
    drawFloats() {
      this.floatGroup = this.chartArea
      .append('g')
      .attr('class', 'float_group')
      .attr('transform', `translate(0, 0)`)
      .style('opacity', 0)
    },
    loadFloats() {
      setTimeout(() => {
        this.floatGroup.selectAll("*").remove()

        if(this.stage == 'OverAll'){
          this.drawShopTooltipGroup()
          this.drawAreaTooltipGroup()
        }else{
          this.drawItemTooltipGroup()
        }
        this.floatGroup
        .transition().duration(250)
        .style('opacity', 1)
      }, 150);
    },
    closeFloats() {
      this.floatGroup
      .transition().duration(150)
      .style('opacity', 0)
    },
    drawShopTooltipGroup() {
      this.elProps.coordinates.filter(c => c.STAGE == this.stage && c.TYPE == 'Shop').forEach(c => {
        let data = this.Queries.SQL3.find(d => d.CODE == c.CODE)

        let shopTooltip = this.floatGroup
        .append('g')
        .attr('class', 'shop_tooltip')
        .attr('transform', `translate(${c.x}, ${c.y})`)
        .attr('filter', 'url(#dropshadow)')
        .style('font-family', 'roboto')
        .style('cursor', 'pointer')
        .on('click', (_, i, a) => {
          this.openBlkPopup(c.CODE)
        })

        shopTooltip
        .append('rect')
        .attr('width', 150)
        .attr('height', 150)
        .attr('fill', '#fff')
        .attr('rx', '5')
        .attr('opacity', 0.9)
  
        shopTooltip
        .append('path')
        .attr('transform', `translate(120, 156) rotate(180)`)
        .attr('fill', '#fff')
        .attr('d', 'M5,0l5,6H0Z')
        .attr('opacity', 0.9)
  
        shopTooltip
        .append('line')
        .attr('transform', `translate(12, 24)`)
        .attr('x2', 125)
        .attr('stroke', '#e0e0df')
        .attr('stroke-width', 0.6)
        //------------------------------------------------------------tooltip inner text
        //title
        shopTooltip
        .append('text')
        .attr('id', 'value_code_item')
        .attr('transform', `translate(70, 16)`)
        .style('font-size', 12)
        .style('fill', '#000')
        .attr('text-anchor', 'middle')
        .text(data.ITEM)

        //delta
        shopTooltip
        .append('text')
        .attr('id', 'value_code_delta')
        .attr('transform', `translate(15, 50)`)
        .style('font-size', 12)
        .style('fill', '#EC407A')
        .attr('text-anchor', 'start')
        .text(data.DELTA+'%')

        //plan
        shopTooltip
        .append('text')
        .attr('transform', `translate(70, 40)`)
        .style('font-size', 10)
        .style('fill', '#707070')
        .attr('text-anchor', 'start')
        .text('Plan')

        shopTooltip
        .append('text')
        .attr('id', 'value_code_plan')
        .attr('transform', `translate(140, 40)`)
        .style('font-size', 10)
        .style('fill', '#333')
        .attr('text-anchor', 'end')
        .text(data.PLAN+'%')

        //actual
        shopTooltip
        .append('text')
        .attr('transform', `translate(70, 55)`)
        .style('font-size', 10)
        .style('fill', '#707070')
        .attr('text-anchor', 'start')
        .text('Actual')
        shopTooltip
        .append('text')
        .attr('id', 'value_code_actual')
        .attr('transform', `translate(140, 55)`)
        .style('font-size', 10)
        .style('fill', '#039BE5')
        .attr('text-anchor', 'end')
        .text(data.ACTUAL+'%')

        //total
        shopTooltip
        .append('text')
        .attr('transform', `translate(15, 80)`)
        .style('font-size', 10)
        .style('fill', '#707070')
        .attr('text-anchor', 'start')
        .text('Total :')
        shopTooltip
        .append('text')
        .attr('id', 'value_code_total')
        .attr('transform', `translate(45, 80)`)
        .style('font-size', 10)
        .style('fill', '#707070')
        .attr('text-anchor', 'start')
        .text(data.TOTAL+' Blocks')

        //in progress
        shopTooltip
        .append('text')
        .attr('transform', `translate(15, 100)`)
        .style('font-size', 10)
        .style('fill', '#FAAA18')
        .attr('text-anchor', 'start')
        .text('In Progress')
        shopTooltip
        .append('text')
        .attr('id', 'value_code_inProgress')
        .attr('transform', `translate(135, 100)`)
        .style('font-size', 12)
        .style('fill', '#FAAA18')
        .attr('text-anchor', 'end')
        .text(data.INPROGRESS)

        //complete
        shopTooltip
        .append('text')
        .attr('transform', `translate(15, 118)`)
        .style('font-size', 10)
        .style('fill', '#4CAE4E')
        .attr('text-anchor', 'start')
        .text('Completed')
        shopTooltip
        .append('text')
        .attr('id', 'value_code_completed')
        .attr('transform', `translate(135, 118)`)
        .style('font-size', 12)
        .style('fill', '#4CAE4E')
        .attr('text-anchor', 'end')
        .text(data.COMPLETED)

        //not start
        shopTooltip
        .append('text')
        .attr('transform', `translate(15, 136)`)
        .style('font-size', 10)
        .style('fill', '#707070')
        .attr('text-anchor', 'start')
        .text('Not Started')
        shopTooltip
        .append('text')
        .attr('id', 'value_code_notStart')
        .attr('transform', `translate(135, 136)`)
        .style('font-size', 12)
        .style('fill', '#707070')
        .attr('text-anchor', 'end')
        .text(data.NOTSTARTED)
        //------------------------------------------------------------tooltip inner text
      })
    },
    drawAreaTooltipGroup() {
      this.elProps.coordinates.filter((c, i) => c.STAGE == this.stage && c.TYPE == 'Area').forEach(c => {

        let data = this.Queries.SQL3.find(d => d.CODE == c.CODE)
        let areaTooltip = this.floatGroup
        .append('g')
        .attr('class', 'area_tooltip')
        .attr('transform', `translate(${c.x}, ${c.y})`)
        .attr('filter', 'url(#dropshadow)')
        .style('cursor', 'pointer')
        .on('click', () => {
          let dStage = {
            stage: c.GOTO,
            item: ''
          }
          console.log('pre' ,this.cValue)
          this.selectedItems.push(dStage)
          this.cValue = this.cValue == 1 ? 2 : 1
          console.log( this.cValue)
          this.gotoStage(c.GOTO)
        })
  
        areaTooltip
        .append('rect')
        .attr('width', 195)
        .attr('height', 100)
        .attr('fill', '#fff')
        .attr('rx', '5')
        .attr('opacity', 0.9)
  
        areaTooltip
        .append('path')
        .attr('transform', `translate(30, -6) rotate(0)`)
        .attr('fill', '#fff')
        .attr('d', 'M5,0l5,6H0Z')
        .attr('opacity', 0.9)
        //------------------------------------------------------------tooltip inner tex

         //console.log(data) //처음에 어떤 데이터가 들어오는지 확인 

        let LV1 = (
          data.ITEM == 'LQ Area' ? 'LQ' : (   //삼항연산자인데 if,else처럼 계속 들어갈수 있다
            data.ITEM == 'Topsides Area' ? 'TOPSIDES' : data.ITEM == 'TS Modules' ? 'TOPSIDES MODULES' : data.ITEM == 'TS Common' ? 'TOPSIDES COMMON' : 'HULL & LQ'
          )
        )
        let item = this.Queries.SQL6.find(q => q.GWBS_LV1 == LV1) //lv1과 data의 lv1이 같은걸로 맞춘다
        //console.log(item)

        //------------------------------------------------------------------처음작업 이미지만 안나오게 만듬
        // console.log(item)--이미지가 없으니 item에서 lv1을 찾지 못한다 그렇기 때문에 조건을 더 넣자
        // areaTooltip
        // .append('image')
        // .attr('id', `ToolBtn___${this.localId}`)
        // .attr('clip-path', 'url(#mask_toplevel_image)')
        // .attr('transform', `translate(5, 5)`)
        // .attr('href', item && item.DATAURL ? item.DATAURL : '') //&&로 조건 2개 걸기 하나일때는 error
        // .attr('height', 90)
        //-----------------------------------------------------------------------------------

        //image --------------------이미지가 있을때와 없을때로 구분 작업
        if(item && item.DATAURL){
          let img = areaTooltip
          .append('image')
          .attr('id', `ToolBtn___${this.localId}`)
          .attr('clip-path', 'url(#mask_toplevel_image)')
          .attr('transform', `translate(5, 5)`)
          .attr('href', item.DATAURL)

          let imgWidth = item.DIMENTION.split(',')[0]
          let imgHeight = item.DIMENTION.split(',')[1]
          
          if (imgHeight > imgWidth) img.attr('width', 90)
          if (imgHeight < imgWidth) img.attr('height', 90)
        } else {
          areaTooltip
          .append('g')
          .append('rect')
          .attr('rx', '5')
          .attr('width', 90)
          .attr('height', 90)
          .attr('transform', `translate(5, 5)`)
          .attr('fill', '#EEEEEE')
          .attr('opacity', 0.9)

          areaTooltip
          .append('text')
          .attr('transform', `translate(50, 52)`)
          .style('font-size', 12)
          .style('fill', '#d1d1d1')
          .style('font-weight',500)
          .attr('text-anchor', 'middle')
          .text('No Image')
        }

        //title
        areaTooltip
        .append('rect')
        .attr('transform', `translate(100, 6)`)
        .attr('width',90)
        .attr('height',25)
        .attr('rx',3)
        .style('fill', '#e0e0df')
        areaTooltip
        .append('text')
        .attr('id', 'value_code_item')
        .attr('transform', `translate(145, 22)`)
        .style('font-size', 12)
        .style('fill', '#000')
        .attr('text-anchor', 'middle')
        .text(data.ITEM)

        //OGM
        areaTooltip
        .append('text')
        .attr('transform', `translate(103, 45)`)
        .style('font-size', 10)
        .style('fill', '#EC407A')
        .attr('text-anchor', 'start')
        .text('OGM goal')
        areaTooltip
        .append('text')
        .attr('id', 'value_code_ogm')
        .attr('transform', `translate(184, 45)`)
        .style('font-size', 10)
        .style('fill', '#EC407A')
        .attr('text-anchor', 'end')
        .text(data.OGM_GOAL+'%')

        //plan
        areaTooltip
        .append('text')
        .attr('transform', `translate(103, 60)`)
        .style('font-size', 10)
        .style('fill', '#707070')
        .attr('text-anchor', 'start')
        .text('Plan')
        areaTooltip
        .append('text')
        .attr('id', 'value_code_plan')
        .attr('transform', `translate(184, 60)`)
        .style('font-size', 10)
        .style('fill', '#333')
        .attr('text-anchor', 'end')
        .text(data.PLAN+'%')

        //actual
        areaTooltip
        .append('text')
        .attr('transform', `translate(103, 75)`)
        .style('font-size', 10)
        .style('fill', '#707070')
        .attr('text-anchor', 'start')
        .text('Actual')
        areaTooltip
        .append('text')
        .attr('id', 'value_code_actual')
        .attr('transform', `translate(184, 75)`)
        .style('font-size', 10)
        .style('fill', '#039BE5')
        .attr('text-anchor', 'end')
        .text(data.ACTUAL+'%')

        //delta
        areaTooltip
        .append('text')
        .attr('transform', `translate(103, 90)`)
        .style('font-size', 10)
        .style('fill', '#707070')
        .attr('text-anchor', 'start')
        .text('Delta')
        areaTooltip
        .append('text')
        .attr('id', 'value_code_actual')
        .attr('transform', `translate(184, 90)`)
        .style('font-size', 10)
        .style('fill', '#EC407A')
        .attr('text-anchor', 'end')
        .text(data.DELTA+'%')
      })
    },
    drawItemTooltipGroup() {
      this.elProps.coordinates.filter(c => c.STAGE == this.stage && c.DIRECT != null).forEach(c => {
        // console.log(c)
        let data = this.Queries.SQL2.find(d => (typeof c.LV2 != 'undefined' && d.LV2 == c.LV2) || (typeof c.LV3 != 'undefined' && d.LV3 == c.LV3))
        // console.log(data)
        let areaItemTooltip = this.floatGroup
        .append('g')
        .attr('class', 'area_item_tooltip')
        .attr('transform', `translate(${c.x}, ${c.y})`)
        .attr('filter', 'url(#dropshadow)')
        // .style('cursor', 'pointer')
        .on('click', () => { 
          // console.log(this.levelCode, c.STAGE)
          if(c.STAGE == 'Modules' || c.STAGE == 'Common') return
          this.levelCode = c.LV3 || c.LV2 
          let dStage = {
            stage: this.stage,
            item: c.LV3 || c.LV2
          }
          this.selectedItems.push(dStage)
          let ran = Math.random()
          this.cValue = this.cValue == ran ? ran + 1 : ran          
          this.loadInformation('item')
        })

        areaItemTooltip
        .append('rect')
        .attr('width', 160)
        .attr('height', data.LV2 === 'MODULES' || data.LV2 === 'COMMON'? 114 : 90) // topside일때의 flaot의 height 달라지게
        .attr('fill', '#fff')
        .attr('rx', '5')
        .attr('opacity', 0.9)
  
        if(c.DIRECT == 'R'){
          areaItemTooltip
          .append('path')
          .attr('transform', `translate(166, 16) rotate(90)`)
          .attr('fill', '#fff')
          .attr('d', 'M5,0l5,6H0Z')
          .attr('opacity', 0.9)
        } else{
          areaItemTooltip
          .append('path')
          .attr('transform', `translate(-6, 48) rotate(-90)`)
          .attr('fill', '#fff')
          .attr('d', 'M5,0l5,6H0Z')
          .attr('opacity', 0.9)
        }
        //------------------------------------------------------------topside에서는 button이 생기게 만들자
        // if(data.LV2 == (c.STAGE).toUpperCase() && data.LV2 == 'MODULES')
        if(data.LV2 == 'COMMON' || data.LV2 == 'MODULES'){
          //  summary button 눌렀을때
          let summaryBtn = areaItemTooltip
          .append('g')
          .attr('class', 'summary_Button')
          .attr('transform', `translate(0, 0)`)
          .style('cursor', 'pointer')
          .on('click', () => { 
            // console.log(data.LV3)
            this.levelCode = c.LV3 || c.LV2 
            let dStage = {
              stage: this.stage,
              item: c.LV3 || c.LV2
            }
            this.selectedItems.push(dStage)
            let ran = Math.random()
            this.cValue = this.cValue == ran ? ran + 1 : ran          
            this.loadInformation('item')
          })
          summaryBtn
          .append('rect')
          .attr('transform', `translate(8, 6)`)
          .attr('width', 69)
          .attr('height', 19)
          .attr('rx',3)
          .attr('fill', '#B2DFDB')
          summaryBtn
          .append('text')
          .attr('transform', `translate(42, 18)`)
          .style('font-size', 10)
          .style('fill', '#424242')
          .attr('text-anchor', 'middle')
          .text('Summary')

          // summaryBtn
          // .append('text')
          // .attr('transform', `translate(78, -3)`)
          // .style('font-size', 11)
          // .style('fill', '#D81B60')
          // .attr('text-anchor', 'middle')
          // .text(c.LIFT == 'Y' ? 'This Module lifted on the Hull' :'')

          //  dashboard button 눌렀을때 각 페이지로 이동
          let linkBtn = areaItemTooltip
          .append('g')
          .attr('class', 'link_Button')
          .attr('transform', `translate(0, 0)`)
          .style('cursor', 'pointer')
          .on('click', () => {

            // console.log(data.LV3) //이걸로 data.LV3을 클릭하는지 확인해보고

            let request_ = this.getRequestColumnProps(data.LV3, {}, this.ColumnProps)
            if(request_) this.$emit('request-action', request_)

            // dashboard modal 로 나오게 바꾼다! (원래는 path로 페이지 이동이었지만 지금은 columnProps로 각각의 module modal id값 바뀌어야하기때문에 바뀌었음)
            
            // let request_ = {
            //   dataType: 'row',    
            //   action: {
            //     type: 'direct',
            //     target: 'url',
            //     path: `/service/construction/construction_dashboards${this.path[data.LV3]}`
            //   },
            //   filters: {}
            // }
            // this.$emit('request-action', request_)
          })
          linkBtn
          .append('rect')
          .attr('transform', `translate(82, 6)`)
          .attr('width', 69)
          .attr('height', 19)
          .attr('rx',3)
          .attr('fill', '#80CBC4')
          linkBtn
          .append('text')
          .attr('transform', `translate(116, 18)`)
          .style('font-size', 10)
          .style('fill', '#fff')
          .attr('text-anchor', 'middle')
          .text('Dashboard')
        }

        //------------------------------------------------------------tooltip inner textWrap
        let textWrap = areaItemTooltip
        .append('g')
        .attr('transform', `translate(0, ${data.LV2 === 'MODULES' || data.LV2 == 'COMMON'? 23 : 0})`)

        //title
        textWrap
        .append('rect')
        .attr('transform', `translate(8, 6)`)
        .attr('width',60)
        .attr('height',25)
        .attr('rx',3)
        .style('fill', '#e0e0df')

        textWrap
        .append('text')
        .attr('transform', `translate(38, 23)`)
        .style('font-size', 11)
        .style('fill', '#000')
        .attr('text-anchor', 'middle')
        .text(this.stage === 'Modules' || this.stage === 'Common' ? data.LV3 : data.LV2 )

        textWrap
        .append('rect')
        .attr('transform', `translate(8, 33)`)
        .attr('width',144)
        .attr('height',20)
        .attr('rx',3)
        .style('fill', '#e0e0df')

        textWrap
        .append('text')
        .attr('transform', `translate(80, 47)`)
        .style('font-size', 11)
        .style('fill', '#000')
        .attr('text-anchor', 'middle')
        .text(this.DataItems.filter((d) => {
          return this.stage === 'Modules' || this.stage === 'Common' ? d.LV3 === data.LV3 : d.LV2 === data.LV2})[0].LV3_DESCR)

        //OGM
        textWrap
        .append('text')
        .attr('transform', `translate(8, 67)`)
        .style('font-size', 10)
        .style('fill', '#333')
        .attr('text-anchor', 'start')
        .text(data.TEXT2 == null ? '' : data.TEXT2 )
        textWrap
        .append('text')
        .attr('transform', `translate(72, 67)`)
        .style('font-size', 11)
        .style('fill', '#EC407A')
        .attr('text-anchor', 'start')
        .text(data.TEXT2_PROG ? data.TEXT2_PROG+'%' : '')

        //Date
        textWrap
        .append('text')
        .attr('transform', `translate(8, 83)`)
        .style('font-size', 10)
        .style('fill', '#333')
        .attr('text-anchor', 'start')
        .text(data.TEXT1== null ? '' : data.TEXT1+' :')
        textWrap
        .append('text')
        .attr('transform', `translate(72, 83)`)
        .style('font-size', 11)
        .style('fill', '#EC407A')
        .attr('text-anchor', 'start')
        .text(data.TEXT1_DATE || '')

        //###------------------------------------------------------tooitip Progress
        let tooltipchart = textWrap
        .append('g')
        .attr('transform', `translate(70, 6)`) 

        tooltipchart
        .append('rect')
        .attr('transform', `translate(0, 0)`)
        .attr('width',82)
        .attr('height',25)
        .attr('rx',3)
        .style('fill', '#83d2f5')
        .attr('opacity', 0.9)
        // Plan Cuml Label & Value
        tooltipchart
        .append('text')
        .attr('transform', `translate(8, 8)`)
        .style('font-family', 'Roboto-Regular, Roboto')
        .style('font-size', 9)
        .style('fill', '#757575')
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text('Plan')
        tooltipchart
        .append('text')
        .attr('transform', `translate(8, 18)`)
        .style('font-family', 'Roboto-Regular, Roboto')
        .style('font-size', 10)
        .style('fill', '#fff')
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text(this.DataItems.filter((d) => { return this.stage === 'Modules' || this.stage === 'Common' ? d.LV3 === data.LV3 : d.LV2 === data.LV2})[0].PLAN_PROG.toFixed(1)+'%')
        
        // Actual Cuml Label & Value
        tooltipchart
        .append('text')
        .attr('transform', `translate(74, 8)`)
        .style('font-family', 'Roboto-Regular, Roboto')
        .style('font-size', 9)
        .style('fill', '#757575')
        .attr('text-anchor', 'end')
        .attr('alignment-baseline', 'middle')
        .text('Actual')
        tooltipchart
        .append('text')
        .attr('transform', `translate(74, 18)`)
        .style('font-family', 'Roboto-Regular, Roboto')
        .style('font-size', 10)
        .style('fill', this.DataItems.filter((d) => { return this.stage === 'Modules' || this.stage === 'Common' ? d.LV3 === data.LV3 : d.LV2 === data.LV2})[0].PLAN_PROG > this.DataItems.filter((d) => { return this.stage == 'Topside' ? d.LV3 === data.LV3 : d.LV2 === data.LV2})[0].ACTUAL_PROG ? '#B71C1C' :'#fff')
        .attr('text-anchor', 'end')
        .attr('alignment-baseline', 'middle')
        .text(this.DataItems.filter((d) => {
          return this.stage === 'Modules' || this.stage === 'Common' ? d.LV3 === data.LV3 : d.LV2 === data.LV2})[0].ACTUAL_PROG.toFixed(1)+'%')
      })
    },
  },
}