export default {
  width: 100,
  gab: 30,
  attrs: [
    { 
      text: 'Total',
      refColumn: 'TOTAL',
      fColor: '#BDBCBC', 
      tColor: '#818181'
    },
    { 
      text: 'Open',
      refColumn: 'OPEN',
      refColumnProg: 'OPEN_PROG',
      fColor: '#F44336',
      tColor: '#D50000'
    },
    { 
      text: 'Closed',
      refColumn: 'CLOSED',
      refColumnProg: 'CLOSED_PROG',
      fColor: '#82B22E', 
      tColor: '#2E7D32'
    },
    // { 
    //   text: 'Remain',
    //   refColumn: 'REMAIN',
    //   fColor: '#F7BACF', 
    //   tColor: '#FF75A5'
    // },
  ]
}
