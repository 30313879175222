import * as d3 from 'd3'

export default {

  methods: {

    
    draw_package(selection, pkg) {
      
      let packageGroup = this.innerSvg

      .append('g')
      .attr('id', `package_group_${pkg.code}`)
      .attr('transform', `translate(${pkg.px}, ${pkg.py})`)
      .style('opacity', 0)

      .append('rect')
      .attr('id', `package_box_${pkg.code}`)
      .attr('transform', `translate(0,0)`)
      .attr('width', pkg.width).attr('height', 0).attr('fill', 'gray').style('opacity', .3)
      
      // Display the list of area 
      this.Areas.filter(f => f.package == pkg.code).forEach((d,i) => {
        d3.select(`#package_group_${pkg.code}`)
        .append('g')
        .attr('id', `package_area_list_${d.code}`)
        .attr('transform', `translate(${5}, ${15})`)
        .style('opacity', 0)

        .append('text')
        .attr('x', 0).attr('y', 0)
        .attr('fill', '#000').attr('font-size', 10)
        .text(d.code)

        d3.select(`#package_area_list_${d.code}`)
        .append('text')
        .attr('x', 45).attr('y', 0)
        .attr('fill', '#000').attr('font-size', 10)
        .text(d.name)
      })

    },
    


    draw_AreaListMenu(pkg) {
    
      let areaListMenu = this.innerSvg
      .append('g')
      .attr('id', 'area_list_menu_group')
      // .attr('transform', `translate(10, 150)`)
      .attr('transform', `translate(10, 320)`)

      this.Areas.filter(f => f.package == pkg).forEach( (d,i) => {

        // Title List of Area
        areaListMenu
        .append('g')
        .attr('id', `area_list_menu${i}`)
        .attr('transform', `translate(0, ${(i * 40)})`)
        .style('cursor', d.link == 'Y' ? 'pointer' : 'default')
        .on('mouseover', () => {
          if (d.link == 'N' ) return
          areaListMenu.select(`#area_list_menu${i}`)
          .transition().duration(100)
          .style('opacity', .5)

          this.innerSvg.select(`#BOX-${d.code}`)
          .transition().duration(250)
          .style('opacity', .75)

          this.innerSvg.select(`#AREA-${d.code}`)
          .transition().duration(250)
          .attr('transform', `translate(0, 0) scale(1)`)
          .style('opacity', 1)
        })
        .on('mouseout', () => {
          if (d.link == 'N' ) return
          areaListMenu.select(`#area_list_menu${i}`)
          .transition().duration(100)
          .style('opacity', 1)

          this.innerSvg.select(`#BOX-${d.code}`)
          .transition().duration(250)
          .style('opacity', 0)

          this.innerSvg.select(`#AREA-${d.code}`)
          .transition().duration(250)
          .attr('transform', `translate(${d.x}, ${d.y}) scale(0)`)
          .style('opacity', 0)
        })
        .on('click', () => {
          if (d.link == 'N' ) return
          this.routeTo({
            path  : 'Lev2', package: d.package
          }, {
            path: 'Lev3',  package: d.package, area: d.code
          })
        })

        .append("rect")
        .attr('x', 12).attr('y', -10)
        .attr('width', 5).attr('height', 24)
        .style('fill', d.link == 'Y' ? 'url(#PHOTO)' : 'transparent')

        areaListMenu.select(`#area_list_menu${i}`)
        .append("text")
        .attr('x', 20).attr('y', 0).attr('font-size', 12)
        .style('fill', '#000' ).text(d.code )

        areaListMenu.select(`#area_list_menu${i}`)
        .append("text")
        .attr('x', 20).attr('y', 13).attr('font-size', 11)
        .style('fill', 'gray' )
        .text(d.name)
      })

     
    },

    draw_CommonAreaList() {
    
      let areaListMenu = this.innerSvg
      .append('g')
      .attr('id', 'Common_Area_list')
      .attr('transform', `translate(20, 350)`)

      this.commonAreas.forEach( (d,i) => {

        // Title List of Area
        areaListMenu
        .append('g')
        .attr('id', `Common_Area_list_${i}`)
        .attr('transform', `translate(0, ${(i * 40)})`)
        .style('cursor', 'pointer')
        .on('mouseover', () => {
          d3.select(`#Common_Area_list_${i}`).attr('opacity', 0.5)
        })
        .on('mouseout', () => {
          d3.select(`#Common_Area_list_${i}`).attr('opacity', 1)
        })
        .on('click', () => { 
          this.currentPKG = d.package
          this.currentSEI = d.code
          this.Link_Actions('request3') // --->
        }) 
        .append("rect")
        .attr('x', 12).attr('y', -10)
        .attr('width', 5).attr('height', 24)
        .style('fill', 'url(#PHOTO)')

        areaListMenu.select(`#Common_Area_list_${i}`)
        .append("text")
        .attr('x', 20).attr('y', 0).attr('font-size', 12)
        .style('fill', '#000' ).text(d.code )

        areaListMenu.select(`#Common_Area_list_${i}`)
        .append("text")
        .attr('x', 20).attr('y', 13).attr('font-size', 11)
        .style('fill', 'gray' ).text(d.name )
      })

     
    },
    
  }
}