import * as d3 from 'd3'

export default {
  methods: {
    PDF_Activate_Hull_AFT() {

      setTimeout(() => {
      // Remove SVG
      this.removeSvg(this.CANVAS, 10)
      },1000);

      this.svgModeling = this.svg.append('svg')
      this.svgModeling
      .attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight)
      .attr('viewBox', `0 0 ${this.Canvas.CanvasWidth} ${this.Canvas.CanvasHeight}`)
      .attr('opacity', 1)

      // Overall_Hull_Group
      let Hull_AFT = this.svgModeling
      .append('g')
      .attr('id', 'Hull_AFT_Group')
      .attr('opacity', 1)
      .attr('transform', `translate(0,0) scale(1)`)

      setTimeout(() => {
        this.Hull_AFT.forEach(d => {
          Hull_AFT
          .append('image')
          .attr('id', `Blk_${d.Blk}`)
          .attr('opacity', 1).style('cursor', 'pointer')
          .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Blocks/Hull/${d.Blk}.png`)
          .attr('transform', `translate(${d.x1}, ${d.y1}) scale(${0.5})`)
          .call(s => { this.Goto_Block_Summary(s, d) }) // <--------
        })
      }, 500);

      setTimeout(() => {
        // Mask_AFT
        Hull_AFT
        .append('path')
        .attr('id', 'Mask_AFT_Group')
        .attr('d', 'M92.29,7.323,0,72.355v49.161l48.161,74.71L411.645,269,527,199.323V75.065L404.677,53.774V31.323L249.065,0,233.194,24.355Z')
        .attr('transform', `translate(545,350)`)
        .attr('fill', '#fff')
        .style('opacity', 0)
        .style('cursor', 'pointer')
        .call(s => { this.Goto_AFT_Division_Lv3(s) }) // <--------

        this.Masks_Hull_AFT() // ######

        this.Lev1 = 'HULL'
        this.Lev2 = 'HULL-AFT'
        this.stage = this.STG_Activate
        this.fncName = 'Activate_Hull_AFT'
        this.StageCode() // AREA / STAGE
        this.displayGroupID(`#Menu_Back`, 0, 200, 1)

        // ### Call Canvas ###
        this.Canvas_Hull_Activate(this.Lev2)
      }, 1000);
    },

    PDF_Activate_Hull_FOR() {
      setTimeout(() => {
      // Remove SVG
      this.removeSvg(this.CANVAS, 10)
      },1000);

      this.svgModeling = this.svg.append('svg')
      this.svgModeling
      .attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight)
      .attr('viewBox', `0 0 ${this.Canvas.CanvasWidth} ${this.Canvas.CanvasHeight}`)
      .attr('opacity', 1)

      // Overall_Hull_Group
      let Hull_FOR = this.svgModeling
      .append('g')
      .attr('id', 'Hull_FOR_Group')
      .attr('opacity', 1)
      .attr('transform', `translate(0,0) scale(1)`)

      setTimeout(() => {
        this.Hull_FOR.forEach(d => {
          Hull_FOR
          .append('image')
          .attr('id', `Blk_${d.Blk}`)
          .attr('opacity', 1).style('cursor', 'pointer')
          .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Blocks/Hull/${d.Blk}.png`)
          .attr('transform', `translate(${d.x1}, ${d.y1}) scale(${0.5})`)
          .call(s => { this.Goto_Block_Summary(s, d) }) // <--------
        })
      }, 300);

      setTimeout(() => {
        // Mask_FOR 
        Hull_FOR 
        .append('polygon')
        .attr('id', 'Mask_FOR_Group')
        .attr('points', '0,100.69 0,216.31 422.68,301 578,192.05 578,125.47 426.81,94.5 426.81,0 402.03,0 402.03,76.44 98.55,31.53 39.71,52.69 ')
        .attr('transform', `translate(481,411)`)
        .attr('fill', '#fff')
        .style('opacity', 0)
        .style('cursor', 'pointer')
        .call(s => { this.Goto_FOR_Division_Lv3(s) }) // <--------

        this.Masks_Hull_FOR() // ######

        this.Lev1 = 'HULL'
        this.Lev2 = 'HULL-FOR'
        this.stage = this.STG_Activate
        // this.fncName = 'Activate_Hull_FOR'
        this.StageCode() // AREA / STAGE
        this.displayGroupID(`#Menu_Back`, 0, 200, 1)
        // ### Call Canvas ###
        this.Canvas_Hull_Activate(this.Lev2)
      }, 1000);
      
    },

  }
}
           