import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({
    colors: [
      {key: 'Completed', code: '#8BC34A'},
      {key: 'To Go', code: '#FF6D00'},
      {key: 'At Risk', code: '#FFAB00'},
      {key: 'Delayed', code: '#D50000'},
    ],
    legends: [
      {
        function: 'Business',
        bColor  : '#F8CBAD'
      },
      {
        function: 'Commercial',
        bColor  : '#BFBFBF',
        tColor  : '#fff'
      },
      {
        function: 'Completions',
        bColor  : '#9966FF',
        tColor  : '#fff'
      },
      {
        function: 'Construction',
        bColor  : '#00B0F0',
        tColor  : '#fff'
      },
      {
        function: 'DTC',
        bColor  : '#FFC000'
      },
      {
        function: 'Engineering',
        bColor  : '#E2EFDA'
      },
      {
        function: 'Infrastructure',
        bColor  : '#FF80AB'
      },
      {
        function: 'Mfg.',
        bColor  : '#B4C6E7',
        tColor  : '#fff'
      },
      {
        function: 'Olefins',
        bColor  : '#92D050',
        tColor  : '#fff'
      },
      {
        function: 'Polymers',
        bColor  : '#C65911',
        tColor  : '#fff'
      },
      {
        function: 'Supply Chain',
        bColor  : '#FFFF00'
      }
    ]
  }),
  methods: {
    draw_Timeline() {

      // d3.select('#commodity_summary_timeline_chart').remove()
      
      this.chart = this.svg
      .append('g')
      .attr('transform', `translate(200.5, 96.5)`)
      .attr('id', 'timeline_chart')
      .attr('user-select', 'none')





      let legend = this.chart
      .append('g')
      .attr('transform', `translate(1200.5, 5.5)`)
      .attr('class', 'legendGroup')

      legend
      .append('rect')
      .attr('width', 10).attr('height', 10)
      .attr('transform', `translate(-135, -10) rotate(45)`)
      .attr('fill', '#BDBDBD')

      legend.append('text')
      .attr('x', -120)
      .attr('y', -2).style('font-size', 10).style('fill', '#212121').attr('font-weight', 500)
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Baseline Delivery Date')

      legend
      .append('rect')
      .attr('width', 10).attr('height', 10)
      .attr('transform', `translate(5, -10) rotate(45)`)
      .attr('fill', '#FAFAFA')
      .attr('stroke', '#212121')

      legend.append('text')
      .attr('x', 20)
      .attr('y', -2).style('font-size', 10).style('fill', '#212121').attr('font-weight', 500)
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Forecast')

      
      legend
      .append('rect') 
      .attr('width', 1).attr('height', 16)
      .attr('transform', `translate(70, -10)`)
      .attr('fill', '#ccc')

      
      legend
      .append('rect') 
      .attr('width', 10).attr('height', 10)
      .attr('transform', `translate(85, -10) rotate(45)`)
      .attr('fill', '#FF6D00')

      legend.append('text')
      .attr('x', 100)
      .attr('y', -2).style('font-size', 10).style('fill', '#212121').attr('font-weight', 500)
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('To Go')
      
      legend
      .append('rect') 
      .attr('width', 10).attr('height', 10)
      .attr('transform', `translate(150, -10) rotate(45)`)
      .attr('fill', '#FFAB00')

      legend.append('text')
      .attr('x', 165)
      .attr('y', -2).style('font-size', 10).style('fill', '#212121').attr('font-weight', 500)
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('At Risk')

      legend
      .append('rect') 
      .attr('width', 10).attr('height', 10)
      .attr('transform', `translate(220, -10) rotate(45)`)
      .attr('fill', '#D50000')

      legend.append('text')
      .attr('x', 235)
      .attr('y', -2).style('font-size', 10).style('fill', '#212121').attr('font-weight', 500)
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Delayed')
      
      legend
      .append('rect') 
      .attr('width', 10).attr('height', 10)
      .attr('transform', `translate(300, -10) rotate(45)`)
      .attr('fill', '#8BC34A')

      legend.append('text')
      .attr('x', 315)
      .attr('y', -2).style('font-size', 10).style('fill', '#212121').attr('font-weight', 500)
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Completed')



      // Timeline ----------------------------------------------------------------------------
      let mainTimeline = this.chart
      .append('g')
      .attr('transform', `translate(190, 15.5)`)//0
      .attr('user-select', 'none')

      mainTimeline
      .append('rect')
      .attr('x', 0).attr('y', 0).attr('width', this.timelineWidth)
      .attr('height', 75).attr('fill', `url(#${this.localId}__LinearA4LightGray0)`).attr('opacity', .5)//50
      .attr('id', 'timeline_chart_rect')

      // Timeline Text using the scale function
      // Year
      mainTimeline
      .append('g')
      .selectAll('text')
      .data(this.timelineValues.year)
      .enter()
      .append('text')
      .attr('x', d => this.timeScale(d.value))
      .attr('y', 25).style('font-size', 15).style('fill', '#EC407A').attr('font-weight', 500)//15
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(d => d.name.toUpperCase())

      // Month
      mainTimeline
      .append('g')
      .selectAll('text')
      .data(this.timelineValues.month)
      .enter()
      .append('text')
      .attr('x', d => this.timeScale(d.value))
      .attr('y', 55).style('font-size', 10).style('fill', '#333') //30
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(d => d.name.toUpperCase())

      // Timeline Seperator
      mainTimeline
      .append('g')
      .selectAll('path')
      .data(this.timelineValues.month)
      .enter()
      .append('path')
      .attr('d', (d, i) => {
        if (this.timelineValues.month.length-1 > i) {
          if (d.name == 'Dec')return `M${this.timeScale(d.endDate)}, 10 V35`
          else return `M${this.timeScale(d.endDate)}, 50 V60`
        }
      })
      .attr('stroke', '#757575').attr('stroke-width', 0.3)

      // Draw Line Charts
      let groupY = 5

      let chartGroup = this.chart
      .append('g')
      .attr('transform', `translate(190, 100.5)`)

      // let rosdate = new Date(this.DataItems[0].RAS_ROS)
      let thisDate = new Date(this.Queries.SQL1[0].CUTOFF)
      

      // This Date Rect Bar
      chartGroup
        .append('rect') 
        .attr('x', this.timeScale(thisDate)).attr('y', 10)
        .attr('width', 0.7)
        .attr('height', (this.DataItems.length * 30) -10).attr('fill', '#29B6F6') 
      chartGroup
        .append('text') 
        .text('Cutoff')
        .attr('x', this.timeScale(thisDate) - 14).attr('y', 0).style('font-size', 10)
        .style('fill', '#29B6F6').attr('font-weight', 500)
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      
      // if (this.DataItems[0].RAS_ROS) {
      //   chartGroup
      //   .append('rect') 
      //   .attr('x', this.timeScale(rosdate)).attr('y', 10)
      //   .attr('width', 0.7)
      //   .attr('height', (this.DataItems.length * 30) - 10).attr('fill', '#EC407A') 
      //   chartGroup
      //     .append('text') 
      //     .text('ROS')
      //     .attr('x', this.timeScale(rosdate) - 10).attr('y', 0).style('font-size', 10)
      //     .style('fill', '#EC407A').attr('font-weight', 500)
      //     .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      //   }

      
      let descGroup = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 210.5)`)

      
      legend
        .append('rect') 
        .attr('x',-1390).attr('y',10)
        .attr('width', 370)
        .attr('height', (this.DataItems.length * 30) + 85).attr('fill', '#F5F5F5') 
        .style('opacity', 0.7)

        
      descGroup.append('line')
      .attr('x1', 10).attr('x2', 370)
      .attr('y1', -24).attr('y2', -24)
      .attr('stroke', '#BDBDBD').attr('stroke-width',0.3)



      descGroup.append('text')
      .text('Functions')
      .attr('x', 20).attr('y', -50).style('font-size', 10).style('fill', '#212121').attr('font-weight', 'bold')

      descGroup.append('text')
      .text('LMS No.')
      .attr('x', 100).attr('y', -50).style('font-size', 10).style('fill', '#212121').attr('font-weight', 'bold')


      
        
      descGroup.append('line')
      .attr('x1', 160).attr('x2', 160)
      .attr('y1', -90).attr('y2', -30)
      .attr('stroke', '#BDBDBD').attr('stroke-width',0.3)

      descGroup.append('text')
      .text('KMS')
      .attr('x', 265).attr('y', -80).style('font-size', 10).style('fill', '#212121').attr('font-weight', 'bold').attr('text-anchor', 'middle')

      
      descGroup.append('text')
      .text('Total')
      .attr('x', 190).attr('y', -50).style('font-size', 10).style('fill', '#212121').attr('font-weight', 'bold').attr('text-anchor', 'middle')

      
      descGroup.append('text')
      .text('Actual')
      .attr('x', 240).attr('y', -50).style('font-size', 10).style('fill', '#212121').attr('font-weight', 'bold').attr('text-anchor', 'middle')

      descGroup.append('text')
      .text('Remain')
      .attr('x', 290).attr('y', -50).style('font-size', 10).style('fill', '#212121').attr('font-weight', 'bold').attr('text-anchor', 'middle')

      descGroup.append('text')
      .text('Prog.')
      .attr('x', 340).attr('y', -50).style('font-size', 10).style('fill', '#212121').attr('font-weight', 'bold').attr('text-anchor', 'middle')

      this.functions = [...new Set(this.dataSet.map(d => d.FUNCTIONS))]


      let sumY = 0
      let sumLineY = 0
        
      let functionGroups = descGroup
      .append('g')
      .attr('transform', `translate(0, 0)`)

      this.functions.forEach((item, i) => {
        
        const filter = this.dataSet.filter(s => s.FUNCTIONS === this.functions[i - 1])
        sumY += i > 0 ? (30 * filter.length) : 0


        const filterLine = this.dataSet.filter(s => s.FUNCTIONS === this.functions[i - 1])
        sumLineY += i > 0 ? (30 * filterLine.length) : 0

        // console.log(item, this.dataSet.filter(f => f.FUNCTIONS == item).length)
        legend
        .append('rect') 
        .attr('x',-1385).attr('y',sumY + 100).attr('rx', 5)
        .attr('width', 360)
        .attr('height', (this.dataSet.filter(f => f.FUNCTIONS == item).length * 30) - 10)
        .attr('fill', this.legends.find(f => f.function == item).bColor)
        .style('opacity', 0.2)
        .attr('class', 'legend_groups_'+item)
        
      
      
      // Functions Name
      functionGroups
        .append('text')
        .attr('x', 20)
        .attr('y', sumY - 4)
        .style('font-family','roboto')
        .style('font-size',10)
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'hanging')
        .attr('fill', '#000')
        .text(item)

        if (i > 0) {
          functionGroups
          .append('line')
          .attr('x1', 10).attr('x2', this.timelineWidth + 390)
          .attr('y1', sumLineY - 15)
          .attr('y2', sumLineY - 15)
          .attr('stroke', '#000')
          // .attr('stroke', '#9E9E9E')
          .attr('stroke-width', 0.23)
        }
        
        
      })


      this.dataSet.forEach(item => {

        

        
        descGroup.append('line')
        .attr('x1', 390).attr('x2', this.timelineWidth + 390).attr('y1', groupY + 10).attr('y2', groupY + 10)
        .attr('stroke', '#BDBDBD').attr('stroke-width', 0.23)
        // .attr('stroke-dasharray', 5)

        // descGroup.append('text')
        // .text(item.FUNCTIONS)
        // .attr('x', 20).attr('y', groupY).style('font-size', 12).style('fill', '#212121').attr('font-weight', 400)

        descGroup.append('text')
        .text(item.LMS_NO)
        .attr('x', 100).attr('y', groupY).style('font-size', 10).style('fill', '#212121').attr('font-weight', 400)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(150)
          .style('opacity', 0.7)
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(150)
          .style('opacity', 1)
        })
        .on('click', () => {
          let request_ = this.getRequestColumnProps('request', { LMS_NO: item.LMS_NO }, this.ColumnProps)
          this.$emit('request-action', request_)
        })

        descGroup.append('text').text(item.KMS_TOTAL)
        .attr('x', 190).attr('y', groupY).style('font-size', 10)
        .style('fill', '#212121').attr('font-weight', 400).attr('text-anchor', 'middle')
        
        descGroup.append('text').text(item.KMS_ACTUAL)
        .attr('x', 240).attr('y', groupY).style('font-size', 10)
        .style('fill', '#8BC34A').attr('font-weight', 400).attr('text-anchor', 'middle')

        descGroup.append('text').text(item.KMS_REMAIN)
        .attr('x', 290).attr('y', groupY).style('font-size', 10)
        .style('fill', '#D50000').attr('font-weight', 400).attr('text-anchor', 'middle')

        descGroup.append('text').text(item.KMS_PROG ? item.KMS_PROG + '%' : '')
        .attr('x', 340).attr('y', groupY).style('font-size', 10)
        .style('fill', '#212121').attr('font-weight', 400).attr('text-anchor', 'middle')

        let itemGroup = chartGroup
        .append('g')
        .attr('transform', `translate(0.5, ${groupY+0.5})`)
        .attr('class', `chartItemGroup`)


        let dates = []
        if (item.PLAN) dates.push(item.PLAN)
        if (item.FORECAST && !item.ACTUAL) dates.push(item.FORECAST)
        if (item.ACTUAL) dates.push(item.ACTUAL)

        if (dates.length > 0){
          
          let minDate = new Date(Math.min.apply(null,dates.map(d => new Date(d))))
          let maxDate = new Date(Math.max.apply(null,dates.map(d => new Date(d))))

          itemGroup.append('line')
          .attr('x1', this.timeScale(minDate)).attr('x2', this.timeScale(maxDate))
          .attr('y1', 9).attr('y2', 9)
          .attr('stroke', '#212121').attr('stroke-width',0.3)
        }
        
        // Plan
        if (item.PLAN) {
          let PD = new Date(item.PLAN)

          itemGroup.append('g')
          .attr('class', item.LMS_NO + '_TOOLTIP_PLAN')
          .style('opacity', 0)
          .attr('transform', `translate(${this.timeScale(PD)}, -2)`)
          .append('text')
          .attr('text-anchor', 'middle')
          .attr('font-size', '10px').style('fill', '#424242')
          .text(item.PLAN)

          itemGroup
          .append('rect') 
          .attr('class', 'PLAN_'+item.PLAN)
          .attr('width', 12).attr('height', 12)
          .attr('transform', `translate(${this.timeScale(PD)}, 0) rotate(45)`)
          .attr('fill', '#BDBDBD')
          .style('cursor', 'pointer')
          .on('mouseover', function() {
            // d3.select(this).transition().duration(50).style('opacity', 0.5)
            d3.select(`.${item.LMS_NO}_TOOLTIP_PLAN`).style('opacity', 1)
          })
          .on('mouseout', function(){
            // d3.select(this).transition().duration(50).style('opacity', 1)
            d3.select(`.${item.LMS_NO}_TOOLTIP_PLAN`).style('opacity', 0)
          })
          .on('click', () => {
            let request_ = this.getRequestColumnProps('request', { LMS_NO: item.LMS_NO }, this.ColumnProps)
            this.$emit('request-action', request_)
          })
          
        }

        // Forecast
        if (item.FORECAST && !item.ACTUAL) {
          let FD = new Date(item.FORECAST)

          itemGroup.append('g')
          .attr('class', item.LMS_NO + '_TOOLTIP_FORECAST')
          .style('opacity', 0)
          .attr('transform', `translate(${this.timeScale(FD)}, -3)`)
          .append('text')
          .attr('text-anchor', 'middle')
          .attr('font-size', '10px').style('fill', '#424242')
          .text(item.FORECAST)

          itemGroup
          .append('rect')
          .attr('class', 'FORECAST_'+item.FORECAST)
          .attr('width', 12).attr('height', 12)
          .attr('transform', `translate(${this.timeScale(FD)}, 0) rotate(45)`)
          .attr('fill', () => {
            let find = this.colors.find(f => f.key == item.STATUS)
            return find ? find.code : '#FAFAFA'
          })
          .attr('stroke', '#212121')
          .style('cursor', 'pointer')
          .on('mouseover', function() {
            // d3.select(this).transition().duration(50).style('opacity', 0.5)
            d3.select(`.${item.LMS_NO}_TOOLTIP_FORECAST`).style('opacity', 1)
          })
          .on('mouseout', function(){
            // d3.select(this).transition().duration(50).style('opacity', 1)
            d3.select(`.${item.LMS_NO}_TOOLTIP_FORECAST`).style('opacity', 0)
          })
          .on('click', () => {
            let request_ = this.getRequestColumnProps('request', { LMS_NO: item.LMS_NO }, this.ColumnProps)
            this.$emit('request-action', request_)
          })
        }

        // Actual 
        if (item.ACTUAL) {
          let AD = new Date(item.ACTUAL)

          itemGroup.append('g')
          .attr('class', item.LMS_NO + '_TOOLTIP_ACTUAL')
          .style('opacity', 0)
          .attr('transform', `translate(${this.timeScale(AD)}, -3)`)
          .append('text')
          .attr('text-anchor', 'middle')
          .attr('font-size', '10px').style('fill', '#424242')
          .text(item.ACTUAL)

          itemGroup
          .append('rect')
          .attr('class', 'ACTUAL'+item.ACTUAL)
          .attr('width', 12).attr('height', 12)
          .attr('transform', `translate(${this.timeScale(AD)}, 0) rotate(45)`)
          .attr('fill', () => {
            let find = this.colors.find(f => f.key == item.STATUS)
            return find ? find.code : '#fff'
          })
          .style('cursor', 'pointer')
          .on('mouseover', function() {
            // d3.select(this).transition().duration(50).style('opacity', 0.5)
            d3.select(`.${item.LMS_NO}_TOOLTIP_ACTUAL`).style('opacity', 1)
          })
          .on('mouseout', function(){
            // d3.select(this).transition().duration(50).style('opacity', 1)
            d3.select(`.${item.LMS_NO}_TOOLTIP_ACTUAL`).style('opacity', 0)
          })
          .on('click', () => {
            let request_ = this.getRequestColumnProps('request', { LMS_NO: item.LMS_NO }, this.ColumnProps)
            this.$emit('request-action', request_)
          })
        }

        groupY += 30
        
      })

    }, 
  }
}
