import * as d3 from 'd3'

export default {
  methods: {

    draw_Forms() {
      if(this.stage_Header) this.stage_Header.remove()

      this.stage_Header = this.svg
      .append('g')
      .attr('id', 'common_header_area')
      .attr('transform', 'translate(0, 0)')

      // China1 project logo 
      this.loadSvg(
        this.stage_Header, {
        x   : 30,
        y   : 30,
        url : '/China1/Information_Guide/svg/3d_Logo.svg'
      })

      // title
      this.stage_Header
        .append('text')
        .attr('transform', `translate(40, 105)`)
        .style('font-family', 'roboto').style('font-size', 16).style('fill', '#333').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text('Information Guide -')

      this.stage_Header
        .append('text')
        .attr('id', 'el_location_subtitle')
        .attr('transform', `translate(180, 105)`)
        .style('font-family', 'roboto').style('font-size', 16).style('fill', '#44A9DF').attr('text-anchor', 'start').attr('alignment-baseline', 'middle').attr('font-weight', 400)
        .text('Main Plant')

      // Tag Button Controller
      let tagcntr = this.stage_Header
      .append('g')
      .attr('id', 'tagbtn_control_wrapper')
      .attr('transform', `translate(1509, 80)`)
      .style('font-size', 10)
      .style('font-family', 'roboto')
      .style('cursor', 'pointer')  

      this.draw_Toggle(tagcntr)
    },
  }
}
