import Defs from '../../../../../../includes/primitives/Color_Defs'
import { reject } from 'core-js/fn/promise'

export default {
  data: () => ({
    dataSet: [],
    Status: [ 
      // status가 바뀌거나 추가 될때 Tortue_BarCharts.mixin.js의 Bar_Quantity_02 도  바꿔줘야한다 
      { code: 'PO',          title: 'PO',                          colName: { total: 'PO_TOTAL'                 , actual: 'PO_ACTUAL'    }, bColor: '#84C7E5', sColor: '#00838F', tColor: '#333', opacity: 1.00, },
      { code: 'FAT',         title: 'FAT',                         colName: { total: 'FAT_TOTAL'                , actual: 'FAT_ACTUAL'   }, bColor: '#2281C5', sColor: '#9F368B', tColor: '#fff', opacity: 1.00, },
      { code: 'IRN',         title: 'IRN',                         colName: { total: 'IRN_TOTAL'                , actual: 'IRN_ACTUAL'   }, bColor: '#CC93C1', sColor: '#9F368B', tColor: '#333', opacity: 1.00, },
      { code: 'ETA',         title: 'ON Site',                     colName: { total: 'ONSITE_TOTAL'             , actual: 'ONSITE_ACTUAL'}, bColor: '#F6D15E', sColor: '#F9A825', tColor: '#333', opacity: 1.00, },
      { code: 'INST',        title: 'Installed',                   colName: { total: 'INST_TOTAL'               , actual: 'INST_ACTUAL'  }, bColor: '#8DBA2D', sColor: '#558B2F', tColor: '#fff', opacity: 1.00, },
      { code: 'NOT_PO',      title: 'Not PO',                      colName: { total: 'NOT_PO_CNT'               , actual: ''             }, bColor: '#ffffff', sColor: '#BDBCBC', tColor: '#333', opacity: 1.00, },
      { code: 'ROS_PAST',    title: 'ETA is past from ROS',        colName: { total: 'ETA_PAST_FROM_ROS'        , actual: ''             }, bColor: '#F7BACF', sColor: '#BDBCBC', tColor: '#333', opacity: 1.00, },
      { code: 'UPBLOCK_PAST',title: 'ETA is past from  Upper Blk.',colName: { total: 'ETA_PAST_FROM_UPPERBLOCK' , actual: ''             }, bColor: '#EC407A', sColor: '#BDBCBC', tColor: '#fff', opacity: 1.00, },
      // NOT_PO        => [NOT_PO_CNT] = [PO_ACTUAL] IS NULL
      // ROS_PAST      => [ETA_PAST_FROM_ROS] = [PO_ACTUAL] IS NOT NULL AND [ETA_ACTUAL] IS NULL AND [ROS_ETA_DELTA] < 0 
      // UPBLOCK_PAST  => [ETA_PAST_FROM_UPPERBLOCK] = [PO_ACTUAL] IS NOT NULL AND [ETA_ACTUAL] IS NULL AND [ETA_UPPERBLOCK_DELTA] < 0 
    ],
    Border: [
      {
        type: 'MEGA',
        shape: 'ellipse',
        width: 35,height: 15,
        color: 'gray',
        stroke: .2,
        textSize: 10,
        padding: 3,
        rx: 10,ry: 4.5,
      },
      {
        type: 'UNIT',
        shape: 'rect',
        width: 30,height: 14,
        color: 'gray',
        stroke: .2,
        textSize: 10,
        padding: 2.5,
      },
    ],
    LoadedTextProps: {
      x: 6.5,
      y: 6.5,
      color: '#757575',
      bColor: '#efefef',
      textSize: 8.5,
      padding: 1,
    },
    CircleBorder: {
      rx: 6.5,
      ry: 6.5,
      color: '#757575',
      bColor: '#efefef',
      stroke: .5,
      textSize: 8.5,
      padding: 1,
    },
    BlockStyle: {
      MEGA:{
        shape: 'ellipse',
        color: '#F5F5F5',
        stroke: .2,
        textSize: 9,
        padding: 2,
        rx: 20,
        ry: 8,
      },
      UNIT:{
        shape: 'rect',
        color: '#F5F5F5',
        stroke: .2,
        textSize: 9,
        padding: 2,
        width: 26,
        height:16
      },
    },
    //2020-11-26 이사님 MS01~04까지 번호 바꾸라고 하심 (벌써 2번째)data까지는 바꾸지 못했음 web에 보이는 text만(NAME)수정
    BlockStatus_Coordinate: [
      {SCRNAME:"Rooms", LV1:"HULL",     LV2:"HULL-FOR", LV3:"FOR",  LV4:"FOR",    NAME:null,NAME2:"Equipment Room - Hull Deck 32000 A.B",    x: 590,  y: 380,},
      {SCRNAME:"Rooms", LV1:"HULL",     LV2:"HULL-FOR", LV3:"MS",   LV4:"MS03",   NAME:null,NAME2:"Electrical Rooms - MS 3rd Level", x: 590,  y: 835,},
      {SCRNAME:"Rooms", LV1:"HULL",     LV2:"HULL-FOR", LV3:"MS",   LV4:"MS01",   NAME:null,NAME2:"Instrument Room - MS 1st Level",  x: 590,  y: 610,},
      {SCRNAME:"Rooms", LV1:"HULL",     LV2:"LQ",      LV3:"LQ00",  LV4:"LQ01",   NAME:null,NAME2:"Central Control Room(CCR) - LQ 1st Lv.",  x: 1150,  y: 835,},
      {SCRNAME:"Rooms", LV1:"HULL",     LV2:"LQ",      LV3:"LQ00",  LV4:"LQ02",   NAME:null,NAME2:"Central Equipment Room(CER) - LQ 2nd Lv.",x: 1150,  y: 610,},
      {SCRNAME:"Rooms", LV1:"HULL",     LV2:"LQ",      LV3:"LQ00",  LV4:"LQ06",   NAME:null,NAME2:"Telecom Equipment Room(TER) - LQ 6th Lv.",x: 1150,  y: 380,},
      {SCRNAME:"Rooms", LV1:"TOPSIDES", LV2:"MODULES", LV3:"ME05",  LV4:"ME05DA", NAME:null,NAME2:"HV Room - E/I Building(ME05) 1st Lv.",    x: 590,  y: 150,},
      {SCRNAME:"Rooms", LV1:"TOPSIDES", LV2:"MODULES", LV3:"ME05",  LV4:"ME05DB", NAME:null,NAME2:"LV Room - E/I Building(ME05) 2nd Lv.",    x: 1150,  y: 150,},
    ],
    location: [
      { LV1: 'HULL',     LV2: 'HULL-FOR', LV3: 'FOR'  , LV4: 'FOR'    , x: 380,y: 380, x5: 390, y5: 190, scale:0.18 },
      { LV1: 'HULL',     LV2: 'HULL-FOR', LV3: 'MS'   , LV4: 'MS01'   , x: 380,y: 610, x5: 575, y5: 260, scale:0.22 },
      { LV1: 'HULL',     LV2: 'HULL-FOR', LV3: 'MS'   , LV4: 'MS03'   , x: 380,y: 840, x5: 541, y5: 225, scale:0.20 },
      { LV1: 'HULL',     LV2: 'LQ'      , LV3: 'LQ00' , LV4: 'LQ01'   , x: 950,y: 840, x5: 527, y5: 260, scale:0.20 },
      { LV1: 'HULL',     LV2: 'LQ'      , LV3: 'LQ00' , LV4: 'LQ02'   , x: 950,y: 610, x5: 592, y5: 222, scale:0.20 },
      { LV1: 'HULL',     LV2: 'LQ'      , LV3: 'LQ00' , LV4: 'LQ06'   , x: 950,y: 380, x5: 595, y5: 230, scale:0.20 },
      { LV1: 'TOPSIDES', LV2: 'MODULES' , LV3: 'ME05' , LV4: 'ME05DA' , x: 380,y: 150, x5: 456, y5: 280, scale:0.21 },
      { LV1: 'TOPSIDES', LV2: 'MODULES' , LV3: 'ME05' , LV4: 'ME05DB' , x: 950,y: 150, x5: 600, y5: 288, scale:0.21 },
    ],
  }),

  computed: {
  },
  methods: {
    setStyles() {
      let lightBlue = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `shadowTimeline`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      lightBlue.append('stop').attr('stop-color', '#3d3d3d').attr('offset', '0').attr('stop-opacity', 0.5)
      lightBlue.append('stop').attr('stop-color', '#fff').attr('offset', '1').attr('stop-opacity', 0.3)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['LightGray'], [`LightGray`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['YellowGreen'], [`YellowGreen`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['LightBlue'], [`LightBlue`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['Pink'], [`Pink`, 0.5, 1.0, 0.5, -0.2], this.localId)
    },
  }
}