import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {

  methods: {

    Charts_AreaSummary() {

      this.SVG_AreaSummary = this.svg.append('svg')

      this.SVG_AreaSummary
      .attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight)


      this.AREAS.forEach (d => {

        this.SVG_AreaSummary
        .append('text')
        .attr('transform', `translate(${d.x}, ${d.y})`)
        .style('font-family', 'roboto').style('font-size', 15).style('fill', '#333').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text(d.area)

        let AreaProgress = this.SVG_AreaSummary
        .append('g')
        .attr('id', `AreaSummaryTable`)
        .attr('opacity', 1)

        let data = this.Queries.MasterProgress.find(f => f.AREA == d.area)
        // console.log(data)
        this.Header_Progress_PAD( AreaProgress,'Construction', 130, (d.y + 10), 88, 20, 3, 
                                [data.CON_P_PG, data.CON_A_PG, data.CON_D_PG],
                                '#fff', '#44A9DF'
                              ) // ------------------------>
        })


    },


  }
}
           