import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({

    Status: [
      {name: 'System Completion',             tColor: '#ffffff', x: 15, y: 13},
      {name: 'A1',                code: 'A1', tColor: '#333333', x: 20, y: 37},
      {name: 'Punch A',           code: 'PA', tColor: '#E0E0DF', x: 20, y: 53},
      {name: 'Punch B',           code: 'PB', tColor: '#E0E0DF', x: 20, y: 69},
      {name: 'Commissioning',                 tColor: '#ffffff', x: 15, y: 92},
      {name: 'B1',                code: 'B1', tColor: '#333333', x: 20, y: 100},
      {name: 'Destack',                       tColor: '#ffffff', x: 15, y: 129},
      {name: 'D1',                code: 'D1', tColor: '#333333', x: 20, y: 136},
    ]
  }),


  methods: {
    
    drawStatus() {

      let SCstatus = this.svg
      .append('g')
      .attr('id', `a`)
      .attr('transform', `translate(620,0)`)

      SCstatus
      .append('rect')
      .attr('x', 0).attr('y', 0).attr('rx', 5).attr('ry', 5).attr('width', 245).attr('height', 75).attr('fill', '#F57AA4')
      SCstatus
      .append('rect')
      .attr('x', 0).attr('y', 80).attr('rx', 5).attr('ry', 5).attr('width', 245).attr('height', 32).attr('fill', '#FF75A5')
      SCstatus
      .append('rect')
      .attr('x', 0).attr('y', 117).attr('rx', 5).attr('ry', 5).attr('width', 245).attr('height', 32).attr('fill', '#F7BACF')

      SCstatus
      .append('rect')
      .attr('x', 250).attr('y', 0).attr('rx', 5).attr('ry', 5).attr('width', 50).attr('height', 75).attr('fill', '#E0E0DF')
      SCstatus
      .append('rect')
      .attr('x', 250).attr('y', 80).attr('rx', 5).attr('ry', 5).attr('width', 50).attr('height', 32).attr('fill', '#E0E0DF')
      SCstatus
      .append('rect')
      .attr('x', 250).attr('y', 117).attr('rx', 5).attr('ry', 5).attr('width', 50).attr('height', 32).attr('fill', '#E0E0DF')

      SCstatus
      .append('text')
      .attr('x', 275).attr('y', 13).attr('fill', '#818181') .style('font-size', 10).style('font-family', 'roboto').attr('text-anchor', 'middle').text('PCOW')
     
      SCstatus
      .append('text')
      .attr('x', 125).attr('y', 23)
      .style('font-family', 'roboto').attr('font-size', 8.5).attr('fill', '#FCE3EB').attr('text-anchor', 'end').attr('alignment-baseline', 'middle').text('Total')

      SCstatus
      .append('text')
      .attr('x', 180).attr('y', 23)
      .style('font-family', 'roboto').attr('font-size', 8.5).attr('fill', '#FCE3EB').attr('text-anchor', 'end').attr('alignment-baseline', 'middle').text('Completed')

      SCstatus
      .append('text')
      .attr('x', 230).attr('y', 23)
      .style('font-family', 'roboto').attr('font-size', 8.5).attr('fill', '#FCE3EB').attr('text-anchor', 'end').attr('alignment-baseline', 'middle').text('Remain')

      this.Status.forEach(d => {
        SCstatus
        .append('text')
        .attr('x', d.x)
        .attr('y', () => {
          if (d.name == 'B1' || d.name == 'D1') return d.y + 8; else return d.y
        })
        .attr('fill', d.tColor) .style('font-size', 10).style('font-family', 'roboto').attr('text-anchor', 'start').text(d.name)
      })


      // draw the values of status
      this.Status.filter(f => f.code != null).forEach((d,i) => {
        // Total
        SCstatus
        .append('text')
        .attr('id', `${d.code}_T_${i}`)
        .attr('transform', `translate(${130},${d.y})`)
        .attr('fill', d.tColor).style('font-size', 10).style('font-family', 'roboto').attr('text-anchor', 'end')
        .text(() => {if (this.dataSet[`${d.code}_T`] > 0) return this.dataSet[`${d.code}_T`].toLocaleString()})
        .style('cursor', 'pointer')
        .on('mouseover', () => {
          d3.select(`#${d.code}_T_${i}`).transition().duration(100).attr('transform', `translate(${130 + (this.getNodeElValue(`#${d.code}_T_${i}`,'width')/6)},${d.y+1}) scale(1.3)`)
        })
        .on('mouseout', () => {
          d3.select(`#${d.code}_T_${i}`).transition().duration(100).attr('transform', `translate(130,${d.y})`)
        })
        .on('click', () => {
          let request_ = {
            dataType: 'row',
            action: {
              type: 'direct', target: 'slide-modal', component: 'ServiceDatatable', id: 64, no: ''
            },
            filters     : {},
            iFilters    : {
              inputFilter  : '', filterString : `RFS = '2020' AND CHK_TYPE = '${d.code}'`
            }
          }
          this.$emit('request-action', request_)
        })


        // Completed
        SCstatus
        .append('text')
        .attr('id', `${d.code}_A_${i}`)
        .attr('transform', `translate(${180},${d.y})`)
        .attr('fill', d.tColor).style('font-size', 10).style('font-family', 'roboto').attr('text-anchor', 'end')
        .text(() => {if (this.dataSet[`${d.code}_A`] > 0) return this.dataSet[`${d.code}_A`].toLocaleString()})
        .style('cursor', 'pointer')
        .on('mouseover', () => {
          d3.select(`#${d.code}_A_${i}`).transition().duration(100).attr('transform', `translate(${180 + (this.getNodeElValue(`#${d.code}_A_${i}`,'width')/6)},${d.y+1}) scale(1.3)`)
        })
        .on('mouseout', () => {
          d3.select(`#${d.code}_A_${i}`).transition().duration(100).attr('transform', `translate(180,${d.y})`)
        })
        .on('click', () => {
          let request_ = {
            dataType: 'row',
            action: {
              type: 'direct', target: 'slide-modal', component: 'ServiceDatatable', id: 64, no: ''
            },
            filters     : {},
            iFilters    : {
              inputFilter  : '', filterString : `RFS = '2020' AND CHK_TYPE = '${d.code}' AND QVD_STATUS = 'Not Started' AND EXCEPT_NO = 'N'`
            }
          }
          this.$emit('request-action', request_)
        })


        // Remain
        SCstatus
        .append('text')
        .attr('id', `${d.code}_R_${i}`)
        .attr('transform', `translate(${230},${d.y})`)
        .attr('fill', d.tColor).style('font-size', 10).style('font-family', 'roboto').attr('text-anchor', 'end')
        .text( () => {
          if (d.code == 'PA' || d.code == 'PB') {
            if (this.dataSet[`${d.code}`] > 0) return this.dataSet[`${d.code}`].toLocaleString()
          } else {
            if (this.dataSet[`${d.code}_R`] > 0) return this.dataSet[`${d.code}_R`].toLocaleString()
          }
        })
        .style('cursor', 'pointer')
        .on('mouseover', () => {
          d3.select(`#${d.code}_R_${i}`).transition().duration(100).attr('transform', `translate(${230 + (this.getNodeElValue(`#${d.code}_R_${i}`,'width')/6)},${d.y+1}) scale(1.3)`)
        })
        .on('mouseout', () => {
          d3.select(`#${d.code}_R_${i}`).transition().duration(100).attr('transform', `translate(230,${d.y})`)
        })
        .on('click', () => {
          let request_ = {
            dataType: 'row',
            action: {
              type: 'direct', target: 'slide-modal', component: 'ServiceDatatable', id: 64, no: ''
            },
            filters     : {},
            iFilters    : {
              inputFilter  : '', filterString : ``
            }
          }
          if (d.code == 'PA' || d.code == 'PB') {
            request_.action.id = 65
            request_.iFilters.filterString = `RFS = '2020' AND CATEGORY= '${d.code.substr(-1)}' AND STATUS = 'Not Started PL' AND EXCEPT_NO = 'N'`
          } else {
            request_.action.id = 64
            request_.iFilters.filterString = `RFS = '2020' AND CHK_TYPE = '${d.code}' AND QVD_STATUS = 'Not Started' AND EXCEPT_NO = 'N'`
          }
          this.$emit('request-action', request_)
        })


        // PCOW
        SCstatus
        .append('text')
        .attr('id', `${d.code}_PCOW_${i}`)
        .attr('transform', `translate(${285},${d.y})`)
        .attr('fill', '#333') .style('font-size', 10).style('font-family', 'roboto').attr('text-anchor', 'end')
        .text( () => {
          if (this.dataSet[`${d.code}_PCOW`] > 0) return this.dataSet[`${d.code}_PCOW`].toLocaleString()
        })
        .style('cursor', 'pointer')
        .on('mouseover', () => {
          d3.select(`#${d.code}_PCOW_${i}`).transition().duration(100).attr('transform', `translate(${285 + (this.getNodeElValue(`#${d.code}_PCOW_${i}`,'width')/6)},${d.y+1}) scale(1.3)`)
        })
        .on('mouseout', () => {
          d3.select(`#${d.code}_PCOW_${i}`).transition().duration(100).attr('transform', `translate(285,${d.y})`)
        })
        .on('click', () => {
          let request_ = {
            dataType: 'row',
            action: {
              type: 'direct', target: 'slide-modal', component: 'ServiceDatatable', id: 64, no: ''
            },
            filters     : {},
            iFilters    : {
              inputFilter  : '', filterString : ``
            }
          }
          if (d.code == 'PA' || d.code == 'PB') {
            request_.action.id = 65
            request_.iFilters.filterString = `RFS = '2020' AND CATEGORY= '${d.code.substr(-1)}' AND STATUS = 'Not Started PL' AND EXCEPT_NO = 'Y'`
          } else {
            request_.action.id = 64
            request_.iFilters.filterString = `RFS = '2020' AND CHK_TYPE = '${d.code}' AND QVD_STATUS = 'Not Started' AND EXCEPT_NO = 'Y'`
          }
          // console.log(request_.iFilters.filterString)
          this.$emit('request-action', request_)
        })
      })

    },

    

  }
}
