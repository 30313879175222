/**
 * Event Call Functions
 */

import * as d3 from 'd3'

export default {
  methods: {

    call_timeSchedule(selection, d) {
      selection
      .on('mouseover', () => {
        this.stage_Header.select(`#STEP-${d.no}`)
        .transition().duration(150)
        .style('opacity', .5)
      })

      .on('mouseout', () => {
        this.stage_Header.select(`#STEP-${d.no}`)
        .transition().duration(150)
        .style('opacity', 1)
      })
    },



    call_Activity(selection, d, no) {
      selection
      .on('mouseover', () => {
        this.stage_Header.select(`#LIST_${no}`)
        .transition().duration(150)
        .style('opacity', .7)

        if(d.STORY == '') this.ani_Box('over', d)
        else this.fun_Router('over', d)
      })

      .on('mouseout', () => {
        this.stage_Header.select(`#LIST_${no}`)
        .transition().duration(150)
        .style('opacity', 1)

        if(d.STORY == '') this.ani_Box('out', d)
        else this.fun_Router('out', d)
      })
      .on('click', () => {
        
      })
    },


    fun_Router(mouse, act) {

      act.story.forEach((story,s) => {

        story.forEach((d,i)=> {
          switch(d) {
            case 'flow':
              this.ani_Flowline(mouse, act, s, i)
              break;

            case 'line':
              this.ani_Line(mouse, act, s, i)
              break;

            case 'arrow':
              this.ani_Arrow(mouse, act, s, i)
              break;

            case 'icon':
              this.ani_Icon(mouse, act, s, i)
              break;

            case 'text':
              this.ani_Text(mouse, act, s, i)
              
              break;

            case 'pulling':
              this.ani_Pulling(mouse, act, s, i)
              break;

            case 'pulling3':
              this.ani_Pulling3(mouse, act, s, i)
              break;

            case 'water':
              this.ani_Water(mouse, act, s, i)
              break;

            case 'truk':
              this.ani_Truk(mouse, act, s, i)
              break;
          }
        })
      })
    },


  }
}