import * as d3 from 'd3'
// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  data:() => ({
  }),
  methods: {
    Draw_Charts() {
      
      if(this.ColumnProps.length > 0) var props = this.ColumnProps.find(props_ => props_.value == 'comprops').props
      else props = this.defaultset

      let propWidth = props.width/4

      let Summary = this.svg
      .append('g')
      .attr('id', 'summary_wrap')
      .attr('transform', `translate(0.5,0.5)`).style('font-family', 'roboto')



      let values_ = this.DataItems[0]
      
      //------------------------------------------------------summart inner
      const barScale = d3.scaleLinear()
      .domain([0,100])
      .range([0,propWidth])

      let regexp = /\B(?=(\d{3})+(?!\d))/g

      let SummaryGroup2Wrap = Summary
      .append('g').attr('class', 'SummaryGroup2Wrap').attr('transform', `translate(${0}, 10)`)

      SummaryGroup2Wrap
      .append('rect').attr('rx', 2).attr('x', 24).attr('y', -12)
      .attr('width', propWidth*3.5).attr('height', 64)
      .attr('stroke', '#FFB300').attr('stroke-width', 2).attr('fill','#FFF8E1')

      // Summary
      // .append('line')
      // .attr('x1', 24).attr('y1', 18).attr('x2', propWidth*3.5).attr('y2', 18).style('stroke', '#FFB300').style('stroke-width', 1)

      SummaryGroup2Wrap
      .append('rect').attr('rx', 2).attr('x', 24).attr('y', 7).attr('width', propWidth*3.5).attr('height', 1).attr('fill','#FFB300')

      SummaryGroup2Wrap
      .append('text')
      .attr('transform', `translate(${68}, ${20})`).style('font-weight','bold')
      .style('font-size', 11).style('fill','#000000').attr('text-anchor','end').attr('alignment-baseline', 'middle')
      .text('Indirect')

      SummaryGroup2Wrap
      .append('text')
      .attr('transform', `translate(${68}, ${39})`).style('font-weight','bold')
      .style('font-size', 11).style('fill','#000000').attr('text-anchor','end').attr('alignment-baseline', 'middle')
      .text('Direct')

      this.Wattrs.forEach((Wattr,i)=>{
        let SummaryGroup2 = Summary
        .append('g').attr('class', 'SummaryGroup2').attr('transform', `translate(${97}, 10)`)

        SummaryGroup2
        .append('text')
        .attr('transform', `translate(${(propWidth*i+5)}, 0)`)
        .style('font-size', 11).style('fill', '#424242').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text(Wattr.text)

        SummaryGroup2
        .append('text')
        .attr('transform', `translate(${(propWidth*i+17)}, ${20})`)
        .style('font-size', 11).attr('text-anchor','end').attr('alignment-baseline', 'middle').attr('fill', Wattr.text =='Delta' && values_[Wattr.refColumn] === 0 ?'#9E9E9E' :Wattr.tColor)
        .text(values_[Wattr.refColumn] != undefined ? Number(values_[Wattr.refColumn]).toString().replace(regexp, ','):'')

        //'▲ ' '▼ '넣기
        // console.log(values_.DELTA_WK.toString().length)
        SummaryGroup2
        .append('text')
        .attr('transform', `translate(${(propWidth*i-26)}, ${20})`)
        // .attr('transform', `translate(${Number(values_.DELTA_WK).toString().length == 2 ? (propWidth*i)-(Number(values_.DELTA_WK).toString().length*8.5) :Number(values_.DELTA_WK).toString().length == 3 ? (propWidth*i)-(Number(values_.DELTA_WK).toString().length*7): Number(values_.DELTA_WK).toString().length == 4 ?(propWidth*i)-(Number(values_.DELTA_WK).toString().length*6) : Number(values_.DELTA_WK).toString().length == 5 ?(propWidth*i)-(Number(values_.DELTA_WK).toString().length*5.5): Number(values_.DELTA_WK).toString().length == 6 ? (propWidth*i)-(Number(values_.DELTA_WK).toString().length*5): (propWidth*i)-(Number(values_.DELTA_WK).toString().length*4.6)}, ${20})`)
        .style('font-size', 11).attr('text-anchor','start').attr('alignment-baseline', 'middle')
        .style('fill', values_.INDIRECT_DELTA > 0 ? '#43A047':values_.INDIRECT_DELTA == 0 ? '#ccc': '#E53935')
        .text(Wattr.text =='Delta' ? (values_[Wattr.refColumn] > 0 ? '▲ ' :values_[Wattr.refColumn] === 0 ? '' : values_[Wattr.refColumn] < 0 ? '▼ ' :''): '')
      

      })
      this.Cattrs.forEach((Cattr,i)=>{
        let SummaryGroup2 = Summary
        .append('g').attr('class', 'SummaryGroup2').attr('transform', `translate(${97}, 30)`)

        SummaryGroup2
        .append('text')
        .attr('transform', `translate(${(propWidth*i+17)}, ${20})`)
        .style('font-size', 11).attr('text-anchor','end').attr('alignment-baseline', 'middle').attr('fill', Cattr.text =='Delta' && values_[Cattr.refColumn] === 0 ?'#9E9E9E' :Cattr.tColor)
        .text(values_[Cattr.refColumn] != undefined? Number(values_[Cattr.refColumn]).toString().replace(regexp, ','):'')

        SummaryGroup2
        .append('text')
        .attr('transform', `translate(${(propWidth*i-26)}, ${20})`)
        // .attr('transform', `translate(${Number(values_.DELTA_CUM).toString().length == 2 ? (propWidth*i)-(Number(values_.DELTA_CUM).toString().length*8.5) :Number(values_.DELTA_CUM).toString().length == 3 ? (propWidth*i)-(Number(values_.DELTA_CUM).toString().length*7): Number(values_.DELTA_CUM).toString().length == 4 ?(propWidth*i)-(Number(values_.DELTA_CUM).toString().length*6) : Number(values_.DELTA_CUM).toString().length == 5 ?(propWidth*i)-(Number(values_.DELTA_CUM).toString().length*5.5): Number(values_.DELTA_CUM).toString().length == 6 ? (propWidth*i)-(Number(values_.DELTA_CUM).toString().length*5): (propWidth*i)-(Number(values_.DELTA_CUM).toString().length*4.6)}, ${20})`)
        .style('font-size', 11).attr('text-anchor','start').attr('alignment-baseline', 'middle')
        .style('fill', values_.DIRECT_DELTA > 0 ? '#43A047':values_.DIRECT_DELTA == 0 ? '#ccc': '#E53935')
        .text(Cattr.text =='Delta' ? (Number(values_[Cattr.refColumn]) > 0 ? '▲ ' :Number(values_[Cattr.refColumn]) === 0 ? '' : Number(values_[Cattr.refColumn]) < 0 ? '▼ ' :''): '')
      
      })
    },
  }
}

