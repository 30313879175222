import * as d3 from 'd3'
// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  data:() => ({
    attrs: [
      { 
        text: 'Total',
        refColumn: 'TOTAL',
        fColor: '#BDBCBC', 
        tColor: '#818181'
      },
      { 
        text: 'Completed',
        refColumn: 'COMPLETED',
        fColor: '#4CAF50',
        tColor: '#4CAF50'
      },
      { 
        text: 'Remain',
        refColumn: 'REMAIN',
        fColor: '#F44336', 
        tColor: '#2E7D32'
      },
    ],
    thead: [
      // { key: 'LEVEL', html: 'LEVEL', row: 1 },
      { key: 'LEVEL_DESCR', html: 'Level', row: 1 },
      { key: 'TOTAL', html: 'Total', row: 1 },
      { key: 'COMPLETED', html: 'Completed.', row: 1 },
      { key: 'REMAIN', html: 'Remain', row: 1 },
      { key: 'PROG', html: 'Progress(%)', row: 1 },
    ],
    theadrows: 1,
    tbody: [
      
      { key: 'LEVEL_DESCR', w: 120, align: 'center'},
      // { key: 'LEVEL_DESCR', w: 270, align: 'left'},
      { key: 'TOTAL', w: 80,type: 'float', align: 'center'},
      { key: 'COMPLETED', w: 80,type: 'float', align: 'center'},
      { key: 'REMAIN', w: 80, type: 'float', align: 'center'},
      { key: 'PROG', w: 80},
    ],
    width: 230,

    selectedStartDate: null,
    selectedStartColumn: null,

    selectedFinishDate: null,
    selectedFinishColumn: null,

    filteredList: null
  }),
  methods: {
    Draw_Charts() {
      let svg = this.svg

      this.filteredList = this.DataItems

      let list = this.filteredList
      // let levelWrap = this.DataItems.map(d=>d.LEVEL)
      // let levelWrap = this.DataItems.filter(d=>d.LEVEL =='OVERALL')
      // console.log(levelWrap2)
      // let propLevel = this.props.find(d => d.Level)
      let wantLevel = this.DataItems[this.DataItems.length-1]

      let group = svg.append('g').attr('class', 'imagegroup__')
      group
      .append('image')
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Room/RoomLQ.png`)
      .attr('transform', `translate(${0} ${0})scale(.45) `)

      let chart = group.append('g').attr('class','chart').attr('transform', `translate(310, 30)`)
      chart
      .append('text').attr('transform', `translate(0, 0)`)
      .style('font-family','roboto')
      .style('font-size',16).style('font-weight','bold')
      .text(wantLevel.LEVEL== 'OVERALL' ? 'LQ' : '')

      let chartBar = chart.append('g').attr('class','chart').attr('transform', `translate(0, 0)`)
      const barScale = d3.scaleLinear()
      .domain([0,100])
      .range([0,120])
      
      //PROG Background Bar
      chartBar
      .append('rect')
      .attr('x', 0).attr('y', 18).attr('width', 120).attr('height', 20)
      .attr('fill', '#fff').attr('stroke', '#8BC34A') .attr('stroke-width', 0.5)
              
      //PROG Actual Bar
      chartBar
      .append('rect')
      .attr('x', 0).attr('y', 18)
      .attr('width',0)
      .attr('height',20)
      .attr('fill','#8BC34A')

      .transition().duration(150)
      .attr('width',barScale(wantLevel.PROG))

      chartBar
      .append('text')
      .attr('x', barScale(wantLevel.PROG)-15).attr('y', 32)
      .style('font-family','roboto').style('font-size',12).attr('text-anchor', 'middle')
      .attr('fill',wantLevel.PROG >=60 ? '#fff' : '#212121')
      .text(wantLevel.PROG ? wantLevel.PROG + '%' : '')

      this.attrs.forEach((d, i)=>{
        chart
        .append('rect')
        .attr('transform', `translate(70, ${50+i*36})`)
        .attr('width',50).attr('height',30).attr('rx',5).attr('ry',5).attr('stroke','#ccc').attr('stroke-width',0.3).attr('fill', d.fColor)
      
        chart
        .append('text').attr('transform', `translate(110, ${70+i*36})`)
        .style('font-family','roboto')
        .style('font-size',12).style('fill','#fff').attr('text-anchor', 'end')
        .text(wantLevel[d.refColumn])
        // .style('cursor', 'pointer')
        // .on('mouseover', function() {
        //   d3.select(this).transition().duration(50)
        //   .style('opacity', 0.5)
        // })
        // .on('mouseout', function(){
        //   d3.select(this).transition().duration(50)
        //   .style('opacity', 1)
        // })
        // .on('click', () => {
        //   console.log(wantLevel[d.refColumn])
        //   let request = {LEVEL : 'LQ', TOTAL: wantLevel[d.refColumn]}
        //   let request_ = this.getRequestColumnProps('LQRoom', request, this.ColumnProps)
        //   this.$emit('request-action', request_)
        // })

        chart
        .append('text').attr('transform', `translate(0, ${70+i*36})`)
        .style('font-family','roboto')
        .style('font-size',12)
        .text(d.text)
      }) 
      
      
      let table_group = svg.append('g').attr('class', 'table_group__').attr('transform', `translate(600.5, 30.5)`)
      table_group
      .append('text').attr('transform', `translate(0, -8)`)
      .style('font-family','roboto')
      .style('font-size',12).style('font-weight','bold')
      .text('Summary by Level')

      let table_obj = table_group.append('foreignObject')
        .attr('y',0)
        // .attr('width', '100%')
        .attr('width', '500px')
        .attr('height', '370px')
        .append('xhtml:body')
        .style('position', 'fixed')

      const reducer = (previousValue, currentValue) => previousValue + currentValue

      let widths = this.tbody.map(d => d.w)
      let sumWidth = widths.reduce(reducer)

      let table = table_obj.append('table')
      .attr('id', 'commodity_summary_table_')
      .style('border-collapse', 'collapse')
      .style('font-size', '11px')
      .style('width', `${sumWidth}px`)
      .style('table-layout', 'fixed')

      let colgroup = table.append('colgroup')
      this.tbody.forEach(k => {
        colgroup.append('col').style('width', k.w + 'px')
      })

      let thead = table.append('thead')
      for (let r = 1; r <= this.theadrows; r++) {
        let row = this.thead.filter(d=> d.row === r)
        let tr = thead.append('tr')

        row.forEach(item => {
          tr.append('th')
          .attr('class', 'ogm_table_group__th')
          .attr('rowspan', () => item.rowspan ? item.rowspan : '')
          .attr('colspan', () => item.colspan ? item.colspan : '')
          .style('height', '25px')
          .style('padding', '3px')
          .style('background', item.color ? item.color : '#EEEEEE')
          .html(item.html)
        })        
      }

      
      let tbodykeys = this.tbody

      
      let tbody = table.append('tbody').attr('id', 'testpack_summay_tbody_')

      let addClass = ''

      for (let r = 0; r < list.length; r++) {
        let tr = tbody.append('tr')

        tbodykeys.forEach((k, i) => {

          let td = tr.append('td')
            .style('border', 'solid 0.1px #e0dede')
            .style('height', '22px')
            .style('text-align', k.align ? k.align : 'center')
            .style('color', '#000000')
            .style('padding', '0 3px 0 3px')

          // if(i === 1) {
          //   td.style('white-space', 'nowrap')
          //     .style('overflow', 'hidden')
          //     .style('text-overflow', 'ellipsis')
          //     .attr('title', list[r][k.key])
          //     td.style('background-color', '#FFF8E1')
          // }

          if(i === 2) {
            td.style('color', '#43A047')
          }
          if(i === 3) {
            td.style('color', '#E53935')
          }

          // if(i === 4) {
          //   // Remain
          //   td.style('color', '#F44336')
          //   .style('cursor', 'pointer')
          //   .on('click', () => {
          //     let request_ = {
          //       dataType: 'row',
          //       action: {
          //         type: 'direct',
          //         target: 'slide-modal',
          //         component: 'ServiceDatatable',
          //         id: 255,
          //         no: ''
          //       },
          //       filters     : {SUBSYSTEM: this.FilterValues.SUBSYSTEM, LOOPTEST: 'Y', QCF: list[r].QCF, STATUS_CODE: 'N'},
          //       iFilters    : {
          //         filterString : '',
          //         inputFilter  : ''
          //       }
          //     }
          //     if (list[r].GENERAL_DESIGN_SCOPE) {
          //       request_.filters.GENERAL_DESIGN_SCOPE = list[r].GENERAL_DESIGN_SCOPE
          //       console.log(list[r].GENERAL_DESIGN_SCOPE)
          //     }
          //     this.$emit('request-action', request_)
          //   })
          // }
          if(list[r][k.key] =='Overall') {
            td.style('font-weight','bold')
          }
          if(k.key.includes('PROG')) {
            addClass = ''
            td
            .style('position', 'relative')
            .style('color', list[r][k.key] < 0 ? 'red' : list[r][k.key] === 100 ? '#fff' : addClass == 'caution' ? 'red' : '#000')
            .style('z-index', '1')
            .html(list[r][k.key] == null ? 0+'%' :list[r][k.key]+'%')

            td.append('div')
            .style('position', 'absolute')
            .style('left', 0)
            .style('top', 0)
            .style('width', list[r][k.key] > 0 ? list[r][k.key] + '%' : 0)
            .style('height', '21px')
            .style('background-color', list[r][k.key] < 100 ? k.key.includes('PLAN') ? '#E0E0E0' : '#AED581' : '#4CAF50')
            .style('z-index', '-1')
          }
          else {
            if (k.type == 'float') {
              let regexp = /\B(?=(\d{3})+(?!\d))/g
              if (isNaN(Math.floor(list[r][k.key]))) td.html('')
              else td.html(Math.round(Math.floor(list[r][k.key] * 100) / 100).toString().replace(regexp, ','))
            } else td.html(list[r][k.key])
          }
        })
      }
    },
  }
}
