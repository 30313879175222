// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  data:() => ({
    types: ['1.Priority MC Complete', '2.MC 7 Days Look Ahead', '3.Priority MC Overdue', '4.MC Overdue(except Priority)'],
    thead: [
      { html: 'No', row: 1, rowspan: 2 },
      { html: 'Priority', row: 1, rowspan: 2 },
      { html: 'Module', row:1, rowspan: 2 },
      { html: 'Subsystem', row: 1, rowspan: 2 },
      { html: 'Subsystem Description', row: 1, rowspan: 2 },
      { html: 'MC Dates', row: 1, rowspan: 2 },
      { html: 'A ITR', row: 1, colspan: 6 },
      { html: 'Total', row: 2 },
      { html: 'Com.', row: 2 },
      { html: 'Rem.', row: 2 },
      { html: 'Last<br>Week', row: 2 },
      { html: 'This<br>Week', row: 2 },
      { html: 'Prog(%)', row: 2 },
      { html: 'A Punch', row: 1, colspan: 6 },
      { html: 'Total', row: 2 },
      { html: 'Com.', row: 2 },
      { html: 'Rem.', row: 2 },
      { html: 'Last<br>Week', row: 2 },
      { html: 'This<br>Week', row: 2 },
      { html: 'Prog(%)', row: 2 },
    ],
    tbody: [
      { key: 'NO', w: 35 },
      { key: 'PRIORITY', w: 65 },
      { key: 'MODULE', w: 60 },
      { key: 'SUBSYSTEM', w: 100, background: '#FAFAFA' },
      { key: 'SUBSYSTEM_DESCR', w: 460, align: 'left' },
      { key: 'MC_PLAN', w: 80 },
      { key: 'A_OV_TOT', w: 40, align: 'right', type: 'number', background: '#f3fbfe' },
      { key: 'A_OV_COM', w: 40, align: 'right', type: 'number', background: '#f3fbfe' },
      { key: 'A_OV_REM', w: 40, align: 'right', color: '#D50000', type: 'number', background: '#f3fbfe' },
      { key: 'A_OV_COM_LW', w: 40, align: 'right', type: 'number', background: '#f3fbfe' },
      { key: 'A_OV_COM_TW', w: 40, align: 'right', type: 'number', background: '#f3fbfe' },
      { key: 'A_OV_PROG', w: 80 },
      { key: 'PA_OV_TOT', w: 40, align: 'right', type: 'number', background: '#FFF8E1' },
      { key: 'PA_OV_COM', w: 40, align: 'right', type: 'number', background: '#FFF8E1' },
      { key: 'PA_OV_REM', w: 40, align: 'right', color: '#D50000', type: 'number', background: '#FFF8E1' },
      { key: 'PA_OV_COM_LW', w: 40, align: 'right', type: 'number', background: '#FFF8E1' },
      { key: 'PA_OV_COM_TW', w: 40, align: 'right', type: 'number', background: '#FFF8E1' },
      { key: 'PA_OV_PROG', w: 80 },
    ],
    groupY: 0
  }),
  methods: {
    Draw_Charts() {
      let svg = this.svg

      // Set Default Datas
      const reducer = (previousValue, currentValue) => previousValue + currentValue
      let columnWidths = this.tbody.map(d => d.w)
      let sumColumnWidth = columnWidths.reduce(reducer)

      let dataSet = JSON.parse(JSON.stringify(this.DataItems))
      let theadrows = Math.max(...this.thead.map(d => d.row))

      let types = [...new Set(dataSet.map(d => d.TYPE))]
      // let types = this.types

      let overallGroup = svg.append('g').attr('class', `overall_wkreport_table_group__`).attr('transform', `translate(30, 70)`)

      let actionColumns = ['SUBSYSTEM', 'A_OV_TOT', 'A_OV_COM', 'A_OV_REM', 'PA_OV_TOT', 'PA_OV_COM', 'PA_OV_REM']
      let actionQCF = ['A_OV_TOT', 'A_OV_COM', 'A_OV_REM']
      let actionPunch = ['PA_OV_TOT', 'PA_OV_COM', 'PA_OV_REM']

      types.forEach((type) => {

        let typeLength = dataSet.filter(d => d.TYPE == type).length
        
        let typeGroup = overallGroup.append('g').attr('class', `${type}_wkreport_table_group__`)
        .attr('transform', `translate(0, ${this.groupY })`)


        // Append Type Text
        typeGroup.append('text').text(type).style('font-weight', 'bold').style('font-size', '14px')

        let table_obj = typeGroup.append('foreignObject')
        .attr('y', 10)
        .attr('width', '100%')
        .attr('height', '100%')
        .append('xhtml:body')
        .style('position', 'fixed')

      
        // Draw Table
        let table = table_obj.append('table')
        .attr('id', 'wkreport_table_')
        .style('border-collapse', 'collapse')
        .style('font-size', '10px')
        .style('width', `${sumColumnWidth}px`)
        .style('table-layout', 'fixed')

        let colgroup = table.append('colgroup')
        this.tbody.forEach(k => {
          colgroup.append('col').style('width', k.w + 'px')
        })

        let thead = table.append('thead')
        for (let r = 1; r <= theadrows; r++) {
          let row = this.thead.filter(d=> d.row === r)
          let tr = thead.append('tr')

          row.forEach(item => {

            tr.append('th')
            .attr('class', 'wkreport_table_group__th')
            .attr('rowspan', () => item.rowspan ? item.rowspan : '')
            .attr('colspan', () => item.colspan ? item.colspan : '')
            .style('border', 'solid 0.7px #eee')
            .style('height', '25px')
            .style('padding', '3px')
            .style('background', item.color ? item.color : '#FAFAFA')
            .html(item.html)
          })        
        }

        let list = dataSet.filter(d=> d.TYPE == type)
        
        let tbodykeys = this.tbody
        
        let tbody = table.append('tbody')
        // .attr('id', 'commodity_summay_tbody_')

        let addClass = ''

        for (let r = 0; r < list.length; r++) {
          let tr = tbody.append('tr')

          tbodykeys.forEach((k, i) => {

            let td = tr.append('td')
              .style('border', 'solid 0.1px #eee')
              .style('height', '20px')
              .style('text-align', k.align ? k.align : 'center')
              .style('padding', '0 3px 0 3px')
              .style('color', k.color ? k.color : '#000')
              .style('background', k.background ? k.background : 'none')

            if (actionColumns.includes(k.key)) {
              td.on('mouseover', () => td.style('cursor', 'pointer').style('text-decoration', 'underline'))
              td.on('mouseout', () => td.style('cursor', 'pointer').style('text-decoration', 'none'))
              td.on('click', () => {
                console.log(k.key, list[r])
                let request_ = this.getRequestColumnProps('SUBSYSTEM', { SUBSYSTEM: list[r].SUBSYSTEM }, this.ColumnProps)

                if (actionQCF.includes(k.key)) {
                  // QCF Popup
                  if (k.key == 'A_OV_TOT') request_ = this.getRequestColumnProps('QCF', { SUBSYSTEM: list[r].SUBSYSTEM, CATEGORY: 'A' }, this.ColumnProps)
                  else if (k.key == 'A_OV_COM') request_ = this.getRequestColumnProps('QCF', { SUBSYSTEM: list[r].SUBSYSTEM, CATEGORY: 'A', STATUS_CODE: 'C' }, this.ColumnProps)
                  else if (k.key == 'A_OV_REM') request_ = this.getRequestColumnProps('QCF', { SUBSYSTEM: list[r].SUBSYSTEM, CATEGORY: 'A', STATUS_CODE: 'N' }, this.ColumnProps)
                } else if (actionPunch.includes(k.key)) {
                  // PUNCH Popup
                  if (k.key == 'PA_OV_TOT') request_ = this.getRequestColumnProps('PUNCH', { SUBSYSTEM: list[r].SUBSYSTEM, CATEGORY: 'A' }, this.ColumnProps)
                  else if (k.key == 'PA_OV_COM') request_ = this.getRequestColumnProps('PUNCH', { SUBSYSTEM: list[r].SUBSYSTEM, CATEGORY: 'A', STATUS_CODE: 'C' }, this.ColumnProps)
                  else if (k.key == 'PA_OV_REM') request_ = this.getRequestColumnProps('PUNCH', { SUBSYSTEM: list[r].SUBSYSTEM, CATEGORY: 'A', STATUS_CODE: 'N' }, this.ColumnProps)
                }
                
                this.$emit('request-action', request_)

              })
            }

            

            if(k.key.includes('PROG')) {
              addClass = ''
              td
              .style('position', 'relative')
              .style('color', list[r][k.key] < 0 ? 'red' : list[r][k.key] === 100 ? '#fff' : addClass == 'caution' ? 'red' : '#000')
              .style('z-index', '1')
              .html(list[r][k.key] == null ? 0+'%' :list[r][k.key]+'%')

              td.append('div')
              .style('position', 'absolute')
              .style('left', 0)
              .style('top', 0)
              .style('width', list[r][k.key] > 0 ? list[r][k.key] + '%' : 0)
              .style('height', '19px')
              .style('background-color', list[r][k.key] < 100 ? k.key.includes('PLAN') ? '#E0E0E0' : '#FFCA28' : '#4CAF50')
              .style('z-index', '-1')
            } else if (k.key == 'NO') {
              td.html(r+1)
            }
            else td.html(k.type && k.type == 'number' && list[r][k.key] ? list[r][k.key].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : list[r][k.key])
            
          })
        }

        
        this.groupY += (20 * typeLength) + 150

      })


    },
  }
}
