// ------------------------------------------------------------------------------- Global
// Global Data & Defs

import { group } from "d3-array"


export default {
  data:() => ({
    legend: [
      {key:'SUBSYSTEM_STATUS', value:'Not Started', text: 'Not Started',  background: '#ccc', stroke: '#757575', tColor: '#000'},
      {key:'SUBSYSTEM_STATUS', value:'Loop Test Prerequisites in progress', text: 'Loop Test Prerequisites in progress', background: '#FFD600', stroke: '#FFAB00', tColor: '#000'},
      {key:'SUBSYSTEM_STATUS', value:'Loop Test Prerequisites Completed', text: 'Loop Test Prerequisites Completed', background: '#64B5F6', stroke: '#2196F3', tColor: '#fff'},
      {key:'SUBSYSTEM_STATUS', value:'MC Completed', text: 'MC Completed', background: '#1E88E5', stroke: '#1565C0', tColor: '#fff'},
      {key:'SUBSYSTEM_STATUS', value:'Loop Test in progress', text: 'Loop Test in progress', background: '#8BC34A', stroke: '#8BC34A', tColor: '#fff'},
      {key:'SUBSYSTEM_STATUS', value:'Loop Test complete', text: 'Loop Test complete', background: '#43A047', stroke: '#43A047', tColor: '#fff'},
    ],
    colors: [
      {key: 'CER', color: '#FFEBEE'},
      {key: 'HER', color: '#E3F2FD'},
      {key: 'LER', color: '#E0F2F1'},
    ],
    scopesColors: [
      {key: 'COSCO Scope', color: '#FFEBEE'},
      {key: 'TEN Scope', color: '#FFF8E1'},
      {key: 'All COSCO Scope', color: '#E3F2FD'},
      {key: 'All TEN Scope', color: '#E0F2F1'},
    ],
  }),
  methods: {
    Draw_Charts() {
      let svg = this.svg

      let dataSet = JSON.parse(JSON.stringify(this.DataItems))

      let overallGroup = svg.append('g').attr('class', `overall_equipment_commence_group__`).attr('transform', `translate(30, 50)`)

      let legend = this.svg
      .append('g')
      .attr('transform', `translate(40, 90)`)


      this.legend.forEach((d, i) => {

        legend
        .append('rect')
        .attr('transform', `translate(${(172*i)}, 0 )`)
        .attr('rx', 3)
        .attr('ry', 3)
        .attr('width', 160)
        .attr('height', 16)
        .attr('fill', d.background)
        .attr('stroke', d.stroke).attr('stroke-width', 1)

        legend
        .append('text')
        .attr('transform', `translate(${(172*i)+80}, 8 )`)
        .style('font-family', 'Roboto-Regular, Roboto')
        .style('font-size', 10)
        .style('fill', d.tColor)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(d.text)
      })

      
      // let sysTypes = [...new Set(dataSet.map(d => d.SYS_TYPE))]
      let sysTypes = [...new Set(dataSet.map(d => d.SYS_TYPE))]
      // let scopes = [...new Set(dataSet.map(d => d.SCOPE))]
      let scopes = ['COSCO Scope', 'TEN Scope', 'All COSCO Scope', 'All TEN Scope']

      let groupY = 0
      let groupX = 0
      let rowEA = 4
      let scopeY = 0
      let typeY = 0

      
      let itemHeight = 135
      let rectMargin = [60, 100, 100, 100]
      // let imageMargin = [30, 100, 100]


      let subsystemGroup = overallGroup.append('g').attr('class', 'subsystem_group__').attr('transform', `translate(0, 100)`)

        sysTypes.forEach((systype, idx) => {
          let filter = dataSet.filter(f => f.SYS_TYPE == systype)
          let max = Math.max(...filter.map(d => d.ROW_NO))
          let rowMax = idx > 0 && max > 0 ? max - 1 : max
          // console.log(systype, rowMax)

          subsystemGroup
            .append('image')
            .attr('transform', (d, i) => `translate(0, ${typeY + (rowMax * itemHeight) - 50}) scale(0.85)`)
            .attr('xlink:href', () => {
              return `${this.__HOST_NAME_RESOURCE}/TORTUE/SystemCompletion/LoopTesting/${systype}.png`
            })

          subsystemGroup
            .append('text')
            .attr('transform', (d, i) => `translate(80, ${typeY + (rowMax * itemHeight) + 140})`)
            .attr('text-anchor', 'middle')
            .style('font-weight', 'bold')
            .style('font-size', '20px')
            .attr('alignment-baseline', 'middle')
            .text(systype)

          // console.log(filter.length, rowEA)
            
          typeY += ((filter.length / rowEA) * itemHeight)
        })

        scopes.forEach((scope, idx) => {
          let filter = dataSet.filter(f => f.SCOPE == scope)
          if (!filter) return

          // let row = filter.length / rowEA
          // let colMax = Math.max(...filter.map(d => d.COL_NO))
          let rowMax = Math.max(...filter.map(d => d.ROW_NO))


          subsystemGroup
            .append('rect')
            .attr('transform', `translate(190, ${scopeY})`)
            .attr('width', '1410px')
            .attr('height', ((rowMax * itemHeight) + 40 ) + 'px').attr('rx', 5)
            .attr('fill', () => {
              return this.scopesColors.find(d => d.key == scope).color
            })
          
            
          legend
            .append('text')
            .attr('transform', `translate(200, ${scopeY + 80})`)
            .style('font-family', 'Roboto-Regular, Roboto')
            .style('font-size', 18)
            .style('font-weight', 'bold')
            .style('fill', '#01579B')
            .attr('text-anchor', 'start')
            .attr('alignment-baseline', 'middle')
            .text(scope)


          let itemGroup = subsystemGroup.append('g').attr('class', 'type_group__').attr('transform', `translate(200, ${scopeY + 40})`)
          filter.forEach(item => {
            let itemY = 0
            for (let ri = 1; ri <= rowMax; ri++) {
              let itemX = 0
              for (let ci = 1; ci < 5; ci++) {
  
                
                if (item.ROW_NO == ri && item.COL_NO == ci) {
  
                  let legend = this.legend.find(d => d.value == item.SUBSYSTEM_STATUS)
                  if (!legend) return
  
                  let subsystem = itemGroup.append('g').attr('class', `${scope}_`)
                  .attr('transform', `translate(${itemX}, ${itemY})`)
                  .on('mouseover', () => subsystem.style('cursor', 'pointer').style('opacity',0.7))
                  .on('mouseout', () => subsystem.style('opacity',1))
                  .on('click', () => {
                    let request_ = this.getRequestColumnProps('request', {SUBSYSTEM: item.SUBSYSTEM, DESC: item.SUBSYSTEM_DESCR}, this.ColumnProps)
                    this.$emit('request-action', request_)
                  })
  
                  subsystem
                  .append('rect').attr('transform', `translate(0,0)`)
                  .attr('width', '330px').attr('height', '115px').attr('rx', 3)
                  .attr('fill', '#fff').attr('stroke', legend.stroke).attr('stroke-width', '1px')
  
                  // SUBSYSTEM
                  subsystem.append('rect').attr('transform', `translate(1,1)`).attr('rx', 3)
                  .attr('width', '328px').attr('height', '29px')
                  .attr('opacity', 1).attr('stroke-width', 0)
                  .attr('stroke', legend.stroke ? legend.stroke : '#ccc')
                  .attr('fill', legend.background)
  
                  subsystem.append('text').text(item.SUBSYSTEM).attr('transform', `translate(165, 16)`)
                  .attr('text-anchor', 'middle')
                  .attr('alignment-baseline', 'middle')
                  .style('font-size', '18px')
                  .attr('fill', legend.tColor)
  
                  // DESC
                  let textarea = subsystem.append('foreignObject')
                  .attr('transform', `translate(5, 35)`)
                  .attr('width', '320px')
                  .attr('height', '50px')
                  .append('xhtml:body')
  
                  textarea.append('div')
                  .html(item.SUBSYSTEM_DESCR)
                  .style('overflow', 'auto')
                  .style('text-align', 'left')
                  .style('font-size', '14px')
                  
                  // MC Progress
                  let mc = subsystem.append('g').attr('transform', `translate(5, 100)`)
  
                  mc.append('rect').attr('transform', `translate(0,-11)`)
                  .attr('width', '25px').attr('height', '20px')
                  .attr('opacity', 1).attr('stroke-width', 0.3)
                  .attr('fill', '#ccc')
  
                  mc.append('text').text('MC').attr('transform', `translate(13, 0)`)
                  .attr('text-anchor', 'middle')
                  .attr('alignment-baseline', 'middle')
                  .style('font-size', '11px')
  
                  mc.append('rect').attr('transform', `translate(25,-11)`)
                  .attr('width', '70px').attr('height', '20px')
                  .attr('opacity', 1).attr('stroke-width', 0.1)
                  .attr('stroke', '#424242')
                  .attr('fill', 'Transparent')
                  
                  mc.append('rect').attr('transform', `translate(25,-11)`)
                  .attr('width', item.A_OV_PROG ? item.A_OV_PROG / 100 * 70 + 'px' : '0px').attr('height', '20px')
                  .attr('opacity', 1).attr('stroke-width', 0.1)
                  .attr('fill', item.A_OV_PROG == 100 ? '#C8E6C9' : '#FFFF8D')
                  
                  mc.append('text').text(item.A_OV_REM).attr('transform', `translate(40, 0)`)
                  .attr('text-anchor', 'middle')
                  .attr('alignment-baseline', 'middle')
                  .attr('fill', '#D50000')
                  .style('font-size', '11px')
                  
                  mc.append('text').text(' / ').attr('transform', `translate(60, 0)`)
                  .attr('text-anchor', 'middle')
                  .attr('alignment-baseline', 'middle')
                  .style('font-size', '11px')
  
                  mc.append('text').text(item.A_OV_TOT).attr('transform', `translate(80, 0)`)
                  .attr('text-anchor', 'middle')
                  .attr('alignment-baseline', 'middle')
                  .style('font-size', '11px')
  
  
                  // PL-A Progress
                  let pa = subsystem.append('g').attr('transform', `translate(110, 100)`)
  
                  pa.append('rect').attr('transform', `translate(0,-11)`)
                  .attr('width', '35px').attr('height', '20px')
                  .attr('opacity', 1).attr('stroke-width', 0.3)
                  .attr('fill', '#ccc')
  
                  pa.append('text').text('PL-A').attr('transform', `translate(16, 0)`)
                  .attr('text-anchor', 'middle')
                  .attr('alignment-baseline', 'middle')
                  .style('font-size', '11px')
  
                  pa.append('rect').attr('transform', `translate(35,-11)`)
                  .attr('width', '70px').attr('height', '20px')
                  .attr('opacity', 1).attr('stroke-width', 0.1)
                  .attr('stroke', '#424242')
                  .attr('fill', 'Transparent')
                  
                  pa.append('rect').attr('transform', `translate(35,-11)`)
                  .attr('width', item.PA_OV_PROG ? item.PA_OV_PROG / 100 * 70 + 'px' : '0').attr('height', '20px')
                  .attr('opacity', 1).attr('stroke-width', 0.1)
                  .attr('fill', item.PA_OV_PROG == 100 ? '#C8E6C9' : '#FFFF8D')
                  
                  pa.append('text').text(item.PA_OV_REM).attr('transform', `translate(50, 0)`)
                  .attr('text-anchor', 'middle')
                  .attr('alignment-baseline', 'middle')
                  .attr('fill', '#D50000')
                  .style('font-size', '11px')
                  
                  pa.append('text').text(' / ').attr('transform', `translate(72, 0)`)
                  .attr('text-anchor', 'middle')
                  .attr('alignment-baseline', 'middle')
                  .style('font-size', '11px')
  
                  pa.append('text').text(item.PA_OV_TOT).attr('transform', `translate(90, 0)`)
                  .attr('text-anchor', 'middle')
                  .attr('alignment-baseline', 'middle')
                  .style('font-size', '11px')
  
                  // PC Progress
                  let pc = subsystem.append('g').attr('transform', `translate(220, 100)`)
  
                  pc.append('rect').attr('transform', `translate(0,-11)`)
                  .attr('width', '25px').attr('height', '20px')
                  .attr('opacity', 1).attr('stroke-width', 1)
                  .attr('fill', '#ccc')
  
                  pc.append('text').text('PC').attr('transform', `translate(13, 0)`)
                  .attr('text-anchor', 'middle')
                  .attr('alignment-baseline', 'middle')
                  .style('font-size', '11px')
  
                  pc.append('rect').attr('transform', `translate(25,-11)`)
                  .attr('width', '70px').attr('height', '20px')
                  .attr('opacity', 1).attr('stroke-width', 0.1)
                  .attr('stroke', '#424242')
                  .attr('fill', 'Transparent')
                  
                  pc.append('rect').attr('transform', `translate(25,-11)`)
                  .attr('width', item.B_OV_PROG ? item.B_OV_PROG / 100 * 70 + 'px' : '0').attr('height', '20px')
                  .attr('opacity', 1)
                  .attr('stroke', item.B_OV_PROG == 100 ? '#C8E6C9' : '#FFFF8D')
                  .attr('stroke-width', '0.1')
                  .attr('fill', item.B_OV_PROG == 100 ? '#C8E6C9' : '#FFFF8D')
                  
                  pc.append('text').text(item.B_OV_REM).attr('transform', `translate(40, 0)`)
                  .attr('text-anchor', 'middle')
                  .attr('alignment-baseline', 'middle')
                  .attr('fill', '#D50000')
                  .style('font-size', '11px')
                  
                  pc.append('text').text(' / ').attr('transform', `translate(60, 0)`)
                  .attr('text-anchor', 'middle')
                  .attr('alignment-baseline', 'middle')
                  .style('font-size', '11px')
  
                  pc.append('text').text(item.B_OV_TOT).attr('transform', `translate(80, 0)`)
                  .attr('text-anchor', 'middle')
                  .attr('alignment-baseline', 'middle')
                  .style('font-size', '11px')
                }
                itemX += 350
              }
              itemY += 130
            }
          })


          // rect row + margin
          scopeY += (rowMax * itemHeight) + rectMargin[idx]
        })
    },
  }
}
