import * as d3 from 'd3'
import { svg } from 'd3'
import { on } from 'stylus/lib/renderer'
// ------------------------------------------------------------------------------- Global

export default {
  methods: {
    Draw_Charts() {

      setTimeout(() => {
        let Overall = this.svg
        .append('g')
        .attr('id', '_Group')
        .attr('opacity', 1)
        .attr('transform', `translate(0,0) scale(1)`)

        Overall
        .append('image')
        .attr('id', `image_wrap`)
        .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/China1/SiteDashboard/site.png`)
        .attr('transform', `translate(0,0)`)
        /*---------------------------------------IMAGES*/
        this.Coordinate.forEach(d => {

          let data = this.DataItems.find(c => (d.BID_SECTION_PACK == c.BID_SECTION_PACK))
          // console.log(data)
          Overall
          .append('image')
          .attr('id', `Blk_${d.BID_SECTION_PACK}`)
          .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/China1/SiteDashboard/${d.BID_SECTION_PACK}.png`)
          .attr('transform', `translate(${d.x1}, ${d.y1})`)
          // .call(s => { this.Goto_Block_Summary(s, d) })
          
          /*-----------------------------------------------Mask---------------------------*/
          // if(['S5', 'S6'].includes(data.BID_SECTION_PACK)){
          // if(data.BID_SECTION_PACK == 'S10'){
          //   let S10 = Overall
          //   .append('g')
          //   .attr('id', 'Mask_S10').style('cursor', 'pointer').style('visibility', 'visible')
          //   .attr('transform', `translate(258, 87)`).style('opacity', 0.7)
          //   .call(s => { this.Goto_Block_Summary(s,'Mask_S10','S10')})
          
          //   S10
          //   .append('polygon')
          //   .attr('transform', `translate(0, 0)`)
          //   .attr('points', `1.25,7 7.5,1.5 215,1.5 302.5,9.75 302.5,165.5 8,165.5 1.5,159.75`)
          //   .attr('fill', `${data.STATUS_COLOR}`)
            
            
          //   S10
          //   .append('text')
          //   .attr('transform', `translate(${150}, ${70})`)
          //   .style('font-family', 'roboto').style('font-size', 22).style('font-weight', 600).style('fill', '#000000').attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging')
          //   .text(`S10`)
          // }
          if(data.BID_SECTION_PACK == 'S11'){//-------------P3
            Overall
            .append('polygon')
            .attr('id', 'Mask_S11')
            .attr('transform', `translate(258, 87)`)
            // .attr('points', `1.25,7 7.5,1.5 215,1.5 302.5,9.75 302.5,165.5 8,165.5 1.5,159.75`)
            .attr('points', `6.157,4.373 196.269,4.373 296.7,14.563 296.7,155.778 6.157,155.778`)
            .attr('fill', `${data.STATUS_COLOR}`).style('visibility', 'visible')
            .style('opacity', 0.5).style('cursor', 'pointer')
            .attr('stroke-width', 6).attr('stroke', '#AD1457')
            // .style('visibility', 'hidden')
            .call(s => { this.Goto_Block_Summary(s,'Mask_S11','S11')})
          }
          if(data.BID_SECTION_PACK == 'S9'){//-------------P3
            Overall
            .append('polygon')
            .attr('id', 'Mask_S9')
            .attr('transform', `translate(559, 96)`)
            // .attr('points', `0.5,2.917 4.083,1.125 96.875,8.063 104.271,15.948 103,154.5 0.5,154.5 `)
            .attr('points', `7.157,7.68 96.393,15.523 96.393,147.243 7.157,147.243`)
            .attr('fill', `${data.STATUS_COLOR}`).style('visibility', 'visible').attr('stroke-width', 6).attr('stroke', '#AD1457')
            .style('opacity', 0.5).style('cursor', 'pointer')
            .call(s => { this.Goto_Block_Summary(s,'Mask_S9','S9')})
          }
          if(data.BID_SECTION_PACK == 'S4'){//-------------P2
            Overall
            .append('polygon')
            .attr('id', 'Mask_S4')
            .attr('transform', `translate(256, 249)`)
            // .attr('points', `0.667,35.25 9.5,3.458 153.583,1.646 152,283.5 406.5,283.5 406.5,481 151.5,481.333 151.5,388 8,387.333`)
            .attr('points', `13.04,8.027 142.451,8.027 142.451,286.498 399.314,286.498 399.314,473.693 155.523,473.693 155.523,378.576 13.04,378.576 13.04,201.491 5.85,164.236 5.85,40.053  `)
            .attr('fill', `${data.STATUS_COLOR}`).style('visibility', 'visible').attr('stroke-width', 6).attr('stroke', '#EF6C00')
            .style('opacity', 0.5).style('cursor', 'pointer')
            .call(s => { this.Goto_Block_Summary(s,'Mask_S4','S4')})
          }
          if(data.BID_SECTION_PACK == 'S12'){//-------------P3
            Overall
            .append('polygon')
            .attr('id', 'Mask_S12')
            .attr('transform', `translate(407, 249)`)
            // .attr('points', `0,0 334.274,0 342.027,7.753 342.027,36.055 474,36.055 487.014,49.068 487.014,184.457 662.128,184.457 672.217,194.546 672.217,300 431.991,300 431.991,181.717 431.991,115.507 0,115.507`)
            .attr('points', `7.464,6.066 333.301,6.066 333.301,44.628 477.785,44.628 477.785,192.994 667.941,192.994 667.941,293.34 438.759,293.34 438.759,104.452 7.464,104.452`)
            .attr('fill', `${data.STATUS_COLOR}`).style('visibility', 'visible').style('visibility', 'visible').attr('stroke-width', 6).attr('stroke', '#AD1457')
            .style('opacity', 0.5).style('cursor', 'pointer')
            .call(s => { this.Goto_Block_Summary(s,'Mask_S12','S12')})
          }
          if(data.BID_SECTION_PACK == 'S3'){//-------------P2
            Overall
            .append('polygon')
            .attr('id', 'Mask_S3')
            .attr('transform', `translate(407, 360)`) 
            // .attr('points', `0.5,1.5 433,1.5 432.167,516.5 256,516.5 256.083,172 0.5,172.333`)
            .attr('points', `4.667,5.333 248.333,5.333 248.333,160.333 241.667,165 4.667,165`)
            .attr('fill', `${data.STATUS_COLOR}`).style('visibility', 'visible').attr('stroke-width', 6).attr('stroke', '#EF6C00')//ED8B00
            .style('opacity', 0.5).style('cursor', 'pointer')
            .call(s => { this.Goto_Block_Summary(s,'Mask_S3','S3')})
          }
          if(data.BID_SECTION_PACK == 'S2'){//-------------P1
            Overall
            .append('polygon')
            .attr('id', 'Mask_S2')
            .attr('transform', `translate(661, 360)`)
            // .attr('points', `0,3.635 3.68,0 175.827,0 180,3.635 180,514 0,514 `)
            .attr('points', `6.5,5.5 172,5.5 172,504 167,509.5 6.5,509.5 `)
            .attr('fill', `${data.STATUS_COLOR}`).style('visibility', 'visible').attr('stroke-width', 6).attr('stroke', '#007096')
            .style('opacity', 0.5).style('cursor', 'pointer')
            .call(s => { this.Goto_Block_Summary(s,'Mask_S2','S2')})
          }
          if(data.BID_SECTION_PACK == 'S5'){//-------------P2
            Overall
            .append('polygon')
            .attr('id', 'Mask_S5')
            .attr('transform', `translate(258, 635)`)
            // .attr('points', `6,1.5 149.5,1.5 149.5,95.5 404.5,95.5 404.5,240.5 150,240.5 149.333,485.334 137,492.584 136,624.5 102,624.5 43.083,539.334 0.5,464.334 0.5,208`)
            .attr('points', `12.386,6.373 143.144,6.373 143.144,100.49 398.006,100.49 398.006,234.17 142.797,234.17 142.797,616.483 104.503,616.483 37.222,517.83 6.503,459.007 6.503,209.333 11.079,191.687`)
            .attr('fill', `${data.STATUS_COLOR}`).style('visibility', 'visible').attr('stroke-width', 6).attr('stroke', '#EF6C00')
            .style('opacity', 0.5).style('cursor', 'pointer')
            .call(s => { this.Goto_Block_Summary(s,'Mask_S5','S5')})
          }
          if(data.BID_SECTION_PACK == 'S1'){//-------------P1
            Overall
            .append('polygon')
            .attr('id', 'Mask_S1')
            .attr('transform', `translate(838, 548)`)
            .attr('points', `1,3.5 3,3.5 244.25,2.5 245.5,3.625 244.25,401.5 2,401.5`)
            .attr('points', `5.5,5 239.5,5 239.5,386 235.5,392 5.5,392 `)
            .attr('fill', `${data.STATUS_COLOR}`).style('visibility', 'visible').attr('stroke-width', 6).attr('stroke', '#007096')
            .style('opacity', 0.5).style('cursor', 'pointer')
            .call(s => { this.Goto_Block_Summary(s,'Mask_S1','S1')})
          }
          if(data.BID_SECTION_PACK == 'S13'){//-------------P3
            Overall
            .append('polygon')
            .attr('id', 'Mask_S13')
            .attr('transform', `translate(839, 948)`)
            .attr('points', `5,5 240,5 240,296 151,296 151,244 5,244 `)
            // .attr('points', `7.425,6.72 236.608,6.72 236.608,238.5 234,244.36 7.425,244.36`)
            .attr('fill', `${data.STATUS_COLOR}`).style('visibility', 'visible').attr('stroke-width', 6).attr('stroke', '#007096')
            .style('opacity', 0.5).style('cursor', 'pointer')
            .call(s => { this.Goto_Block_Summary(s,'Mask_S13','S13')})
          }
          if(data.BID_SECTION_PACK == 'S14'){//-------------P3
            Overall
            .append('polygon')
            .attr('id', 'Mask_S14')
            .attr('transform', `translate(1081, 947)`)
            // .attr('points', `0.5,1.5 2,1.5 204,1.5 205.5,2.334 205.5,254 0.5,254.167 `)
            .attr('points', `5,5 199.5,5 199.5,244.248 196.158,248 5,248`)
            .attr('fill', `${data.STATUS_COLOR}`).style('visibility', 'visible').attr('stroke-width', 6).attr('stroke', '#007096')
            .style('opacity', 0.5).style('cursor', 'pointer')
            .call(s => { this.Goto_Block_Summary(s,'Mask_S14','S14')})
          }
          if(data.BID_SECTION_PACK == 'S15'){//-------------P3
            Overall
            .append('polygon')
            .attr('id', 'Mask_S15')
            .attr('transform', `translate(1286, 947)`)
            // .attr('points', `0.75,2.414 2.875,1.125 146.021,1.645 147.5,3.831 147.5,254.5 1,254.5 `)
            .attr('points', `4.5,5 141.5,5 141.5,244.248 139.146,248 4.5,248  `)
            .attr('fill', `${data.STATUS_COLOR}`).style('visibility', 'visible').attr('stroke-width', 6).attr('stroke', '#007096')
            .style('opacity', 0.5).style('cursor', 'pointer')
            .call(s => { this.Goto_Block_Summary(s,'Mask_S15','S15')})
          }
          if(data.BID_SECTION_PACK == 'S17'){//-------------P3
            Overall
            .append('polygon')
            .attr('id', 'Mask_S17')
            .attr('transform', `translate(982, 1251)`)
            // .attr('points', `5,5 96,5 96,40 139,40 139,94 577,94 577,208 5,208 `)
            .attr('points', `0,0 144,0 144,91.333 584,91.333 584,215 98.667,215 98.667,90.667 0,90.667 `)
            .attr('fill', `${data.STATUS_COLOR}`).style('visibility', 'visible').attr('stroke-width', 6).attr('stroke', '#D81B60')
            .style('opacity', 0.5).style('cursor', 'pointer')
            .call(s => { this.Goto_Block_Summary(s,'Mask_S17','S17')})
          }
          if(data.BID_SECTION_PACK == 'S16'){//-------------P3
            Overall
            .append('polygon')
            .attr('id', 'Mask_S16')
            .attr('transform', `translate(1083, 1200)`)
            .attr('points', `1,0 484,0 484,141 44.667,141 44.667,50 1,50`)
            // .attr('points', `5.5,4.25 478.5,4.25 478.5,134.75 45.252,134.75 45.252,84.464 5.5,84.464`)
            .attr('fill', `${data.STATUS_COLOR}`).style('visibility', 'visible').attr('stroke-width', 6).attr('stroke', '#D81B60')
            .style('opacity', 0.5).style('cursor', 'pointer')
            .call(s => { this.Goto_Block_Summary(s,'Mask_S16','S16')})
          }
          if(data.BID_SECTION_PACK == 'S6'){//-------------P3
            Overall
            .append('polygon')
            .attr('id', 'Mask_S6')
            .attr('transform', `translate(100, 428)`)
            // .attr('points', `1.446,58.265 8.623,48.278 31.038,34.267 93.635,1.5 101.403,1.5 108.5,5.461 108.5,225.5 54,225.5 54.068,309.5 5,309.5 1.757,306.584 `)
            .attr('points', `15,49.333 97,5.333 102.333,5.333 104.667,9 104.667,220.667 49.667,220.667 49.667,303.667 6,303.667 6,67.667 7,60.667 9,55.667`)
            .attr('fill', `${data.STATUS_COLOR}`).style('visibility', 'visible').attr('stroke-width', 6).attr('stroke', '#D81B60')
            .style('opacity', 0.5).style('cursor', 'pointer')
            .call(s => { this.Goto_Block_Summary(s,'Mask_S6','S6')})
            }
          if(data.BID_SECTION_PACK == 'S7'){//-------------P3
            Overall
            .append('polygon')
            .attr('id', 'Mask_S7')
            .attr('transform', `translate(153, 653)`)
            // .attr('points', `1,1.5 2,1.5 57.5,1.283 57.5,112.5 2,112.5`)
            .attr('points', `4,3 53,3 53,106.666 52.258,107.5 4,107.5`)
            .attr('fill', `${data.STATUS_COLOR}`).style('visibility', 'visible').attr('stroke-width', 6).attr('stroke', '#D81B60')
            .style('opacity', 0.5).style('cursor', 'pointer')
            .call(s => { this.Goto_Block_Summary(s,'Mask_S7','S7')})
          }
          if(data.BID_SECTION_PACK == 'S8'){//-------------P3
            Overall
            .append('polygon')
            .attr('id', 'Mask_S8')
            .attr('transform', `translate(1110, 288)`)
            // .attr('points', `0.75,6.083 4.667,1.5 130.5,1.5 130.5,92.5 1,92.5`)
            .attr('points', `4.5,3.667 125.5,3.667 125.5,85.838 123.668,86.5 4.5,86.5 `)
            .attr('fill', `${data.STATUS_COLOR}`).style('visibility', 'visible').attr('stroke-width', 6).attr('stroke', '#D81B60')
            .style('opacity', 0.5).style('cursor', 'pointer')
            .call(s => { this.Goto_Block_Summary(s,'Mask_S8','S8')})
          }
          if(data.BID_SECTION_PACK == 'S10'){//-------------P3
            Overall
            .append('polygon')
            .attr('id', 'Mask_S10')
            .attr('transform', `translate(1110, 396)`)
            .attr('points', `4.5,3.667 125.5,3.667 125.5,85.838 123.668,86.5 4.5,86.5 `)
            .attr('fill', `${data.STATUS_COLOR}`).style('visibility', 'visible').attr('stroke-width', 6).attr('stroke', '#D81B60')
            .style('opacity', 0.5).style('cursor', 'pointer')
            .call(s => { this.Goto_Block_Summary(s,'Mask_S10','S10')})
          }
          if(data.BID_SECTION_PACK == 'S18'){//-------------P3
            Overall
            .append('polygon')
            .attr('id', 'Mask_S18')
            .attr('transform', `translate(1110, 504)`)
            .attr('points', `4.5,3.667 125.5,3.667 125.5,85.838 123.668,86.5 4.5,86.5 `)
            .attr('fill', `${data.STATUS_COLOR}`).style('visibility', 'visible').attr('stroke-width', 6).attr('stroke', '#D81B60')
            .style('opacity', 0.5).style('cursor', 'pointer')
            .call(s => { this.Goto_Block_Summary(s,'Mask_S18','S18')})
          }
          if(data.BID_SECTION_PACK == 'S19'){//-------------P3
            Overall
            .append('polygon')
            .attr('id', 'Mask_S19')
            .attr('transform', `translate(1110, 611)`)
            .attr('points', `4.5,3.667 125.5,3.667 125.5,85.838 123.668,86.5 4.5,86.5 `)
            .attr('fill', `${data.STATUS_COLOR}`).style('visibility', 'visible').attr('stroke-width', 6).attr('stroke', '#D81B60')
            .style('opacity', 0.5).style('cursor', 'pointer')
            .call(s => { this.Goto_Block_Summary(s,'Mask_S19','S19')})
          }
          if(data.BID_SECTION_PACK == 'S20'){//-------------Other
            Overall
            .append('polygon')
            .attr('id', 'Mask_S20')
            .attr('transform', `translate(1110, 719)`)
            .attr('points', `4.5,3.667 125.5,3.667 125.5,85.838 123.668,86.5 4.5,86.5 `)
            .attr('fill', `${data.STATUS_COLOR}`).style('visibility', 'visible').attr('stroke-width', 6).attr('stroke', '#2E7D32')
            .style('opacity', 0.5).style('cursor', 'pointer')
            .call(s => { this.Goto_Block_Summary(s,'Mask_S20','S20')})
          }
          if(data.BID_SECTION_PACK == 'S21'){//-------------Other
            Overall
            .append('polygon')
            .attr('id', 'Mask_S21')
            .attr('transform', `translate(1110, 827)`)
            .attr('points', `4.5,3.667 125.5,3.667 125.5,85.838 123.668,86.5 4.5,86.5 `)
            .attr('fill', `${data.STATUS_COLOR}`).style('visibility', 'visible').attr('stroke-width', 6).attr('stroke', '#2E7D32')
            .style('opacity', 0.5).style('cursor', 'pointer')
            .call(s => { this.Goto_Block_Summary(s,'Mask_S21','S21')})
          }
          
          
        })

        /*---------------------------------------CODE BLOCK BOX*/
        this.dataSet.forEach(data => {
          if(!this.Queries.SQL2.find(d =>d.BID_SECTION_PACK ==data.BID_SECTION_PACK)) return
          //console.log(data)
          let mask = `Mask_${data.BID_SECTION_PACK}`
          let TextBox = Overall
          .append('g')
          .attr('class', `textBox_${data.BID_SECTION_PACK}`).style('font-family', 'roboto')
          .attr('transform', `translate(0, 0)`).style('cursor', 'pointer')
          
          TextBox
          .append('rect')
          .attr('x', data.x - data.width/2).attr('y', data.y)
          .attr('width', data.width).attr('height', data.height)
          .attr('stroke', data.color).attr('stroke-width', data.stroke).attr('fill', '#388E3C')
          TextBox
          .append('rect')
          .attr('x', data.x - data.width/2 + data.width+3).attr('y', data.y)
          .attr('width', data.width).attr('height', data.height)
          .attr('stroke', data.color).attr('stroke-width', data.stroke).attr('fill', '#1976D2')
          TextBox
          .append('rect')
          .attr('x', data.x - data.width/2).attr('y', data.y+data.height+3)
          .attr('width', data.width).attr('height', data.height)
          .attr('stroke', data.color).attr('stroke-width', data.stroke).attr('fill', '#B71C1C')
          TextBox
          .append('rect')
          .attr('x', data.x - data.width/2+ data.width+3).attr('y', data.y+data.height+3)
          .attr('width', data.width).attr('height', data.height)
          .attr('stroke', data.color).attr('stroke-width', data.stroke).attr('fill', '#FFA000')
          
          TextBox
          .append('text')
          .attr('transform', `translate(${data.x}, ${data.y + data.padding + data.padding})`)
          .style('font-size', 16).style('font-weight', 600).style('fill', '#ffffff').attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging')
          .text(`${data.BID_SECTION_PACK}`)
          TextBox
          .append('text')
          .attr('transform', `translate(${data.x + data.width+3}, ${data.y + data.padding + data.padding})`)
          .style('font-size', 16).style('font-weight', 600).style('fill', '#ffffff').attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging')
          .text(this.Queries.SQL2.find(d => d.BID_SECTION_PACK == data.BID_SECTION_PACK)&& this.Queries.SQL2.find(d => d.BID_SECTION_PACK == data.BID_SECTION_PACK).AWARD_SUBCONTRACTOR_CODE !='NA'? this.Queries.SQL2.find(d => d.BID_SECTION_PACK == data.BID_SECTION_PACK).AWARD_SUBCONTRACTOR_CODE : '')
          TextBox
          .append('text')
          .attr('transform', `translate(${data.x}, ${data.y + data.height+ data.padding*3})`)
          .style('font-size', 16).style('font-weight', 600).style('fill', '#ffffff').attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging')
          .text(this.Queries.SQL2.find(d => d.BID_SECTION_PACK == data.BID_SECTION_PACK)&& this.Queries.SQL2.find(d => d.BID_SECTION_PACK == data.BID_SECTION_PACK).JIANLI_SUPERVISION_WORK_PACKAGE !='NA'? this.Queries.SQL2.find(d => d.BID_SECTION_PACK == data.BID_SECTION_PACK).JIANLI_SUPERVISION_WORK_PACKAGE : '')
          TextBox
          .append('text')
          .attr('transform', `translate(${data.x + data.width+3}, ${data.y + data.height+ data.padding*3})`)
          .style('font-size', 16).style('font-weight', 600).style('fill', '#ffffff').attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging')
          .text(this.Queries.SQL2.find(d => d.BID_SECTION_PACK == data.BID_SECTION_PACK)&& this.Queries.SQL2.find(d => d.BID_SECTION_PACK == data.BID_SECTION_PACK).QUALITY_STATION_AREA !='NA'? this.Queries.SQL2.find(d => d.BID_SECTION_PACK == data.BID_SECTION_PACK).QUALITY_STATION_AREA : '')

          TextBox
          .on('mouseover', () => {
            d3.select(`#${mask}`).transition().style('opacity',0)
          })
          .on('mouseout', () => {
            d3.select(`#${mask}`).transition().style('opacity', 0.5)
          })
          .on('click', () => {
            // console.log(`${data.BID_SECTION_PACK}`)
            let dataSource = {
              BID_SECTION_PACK: data.BID_SECTION_PACK
            }
            let request_ = this.getRequestColumnProps('subcontractor', dataSource, this.ColumnProps)
            this.$emit('request-action', request_)
          })
          
          
          if (data.STATUS != 'COMPLETE') {
            TextBox
            .append('text')
            .attr('transform', `translate(${data.x + -25}, ${data.y +65})`)
            .style('font-size', 13).style('fill', '#212121').attr('text-anchor', 'start').style('font-weight', 600)
            .text(`${data.ISSUE_ITB_PLAN}`)
            TextBox
            .append('text')
            .attr('transform', `translate(${data.x + -25}, ${data.y +80})`)
            .style('font-size', 13).style('fill', '#212121').attr('text-anchor', 'start').style('font-weight', 600)
            .text(`${data.LOA_PLAN? data.LOA_PLAN:''}`)
          }else{
            //이미지에 공백있는것 제거하고 가져오기!!
            //console.log(data.AWARD_SUBCONTRACTOR.replace(/(\s*)/g, ""))
            TextBox
            .append('image')
            .attr('id', `company_${data.BID_SECTION_PACK}`)
            // .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/China1/SiteDashboard/${data.AWARD_SUBCONTRACTOR.replace(/(\s*)/g, "")}.jpg`)
            .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/China1/SiteDashboard/${data.AWARD_SUBCONTRACTOR_CODE}.jpg`)
            .attr('transform', `translate(${data.x-25}, ${data.y+50})`)

            // TextBox
            // .append('text')
            // .attr('transform', `translate(${data.x  + -21}, ${data.BID_SECTION_PACK =='S13'?data.y +110 : data.y +88})`)
            // .style('font-size', 13).style('fill', '#212121').attr('text-anchor', 'start').style('font-weight', 600)
            // .text(`${data.AWARD_SUBCONTRACTOR_CODE}`) 
          }
        })
        this.Chart_Summary()
      },50)

      
    },
    setEvent_Block() {
      this.Goto_Block_Summary = (selection, mask, section) => {
        selection
        .on('mouseover', () => {
          d3.select(`#${mask}`).transition().style('opacity',0)
        })
        .on('mouseout', () => {
          d3.select(`#${mask}`).transition().style('opacity', 0.5)
        })
        .on('click', () => {
          // console.log(section)
          let dataSource = {
            BID_SECTION_PACK: section
          }
          let request_ = this.getRequestColumnProps('subcontractor', dataSource, this.ColumnProps)
          this.$emit('request-action', request_)
        //   let request_ = {
        //     dataType: 'row',
        //     action: {
        //       type: 'direct',
        //       target: 'slide-modal',
        //       component: 'ServiceSvgTabs',
        //       id: 286,
        //       no: 'LIBSVGTB-0001'
        //     },
        //     filters: { 
        //       GWBS_LV1: d.Lv2, //받을값을 보낸다
        //       BLOCK: d.Blk,
        //       MBLOCK: d.Blk,
        //       DESC: '',
        //       // DESC: `${d.AREA} (${d.STATUS})`,
        //     },
        //     iFilters: {
        //       filterString : '',
        //       inputFilter  : ''
        //     }
        //   }
        //   this.$emit('request-action', request_)
        })
      }
    },
    Chart_Summary(){
      let Summary = this.svg
      .append('g')
      .attr('id', 'summary_wrap')
      .attr('transform', `translate(860,20)`)
      .style('font-family', 'roboto').style('cursor', 'pointer')

      if(!this.Queries.SQL1 || this.Queries.SQL1.length === 0) return
      let values_ = this.Queries.SQL1[0]
      this.attrs.forEach((attr, i) => {

        let SummaryGroup = Summary
        .append('g')
        .attr('transform', `translate(${0, 0})`)
        // 

        SummaryGroup
        .append('rect').attr("rx", 5).attr('x', 180* i).attr('y', 0)
        .attr('width', 150).attr('height', 60)
        .attr('stroke', '#cccccc').attr('stroke-width', 0.3).attr('fill', attr.fColor).style('opacity',.7)

        SummaryGroup
        .append('text')
        .attr('transform', `translate(${180* i+75}, 18)`)
        .style('font-size', 16).style('fill', '#212121').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text(attr.text)

        SummaryGroup
        .append('text')
        .attr('transform', `translate(${180* i+75}, 42)`)
        .style('font-size', 22).style('fill', '#212121').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle').style('font-weight',600)
        .text(values_[attr.refColumn])

        // console.log(Summary)

        SummaryGroup.on('click', () => {
          if(values_[attr.refColumn] == 0) return
          // console.log(attr.refColumn)
          let dataSource = {
            LOA_STATUS: attr.refColumn
          }
          let request_ = this.getRequestColumnProps('subcontractor', attr.refColumn == 'TOTAL' ? {} : dataSource, this.ColumnProps)
          this.$emit('request-action', request_)
        })
      })




      /*--------------------------------------------------------TEXT---------------*/
      let Text = this.svg
      .append('g')
      .attr('id', 'text_wrap')
      .attr('transform', `translate(1260,20)`)
      .style('font-family', 'roboto')

      Text
      .append('text')
      .attr('transform', `translate(-150,230)`)
      .style('font-size', 14).style('fill', '#000000').attr('text-anchor', 'start').attr('alignment-baseline', 'hanging')
      .text(`Other construction and installation works :`).style('text-decoration','underline')
      Text
      .append('text')
      .attr('transform', `translate(0,280)`)
      .style('font-size', 13).style('fill', '#000000').attr('text-anchor', 'start').attr('alignment-baseline', 'hanging')
      .text(`High Quality Decoration of Buildings`)
      Text
      .append('text')
      .attr('transform', `translate(0,395)`)
      .style('font-size', 13).style('fill', '#000000').attr('text-anchor', 'start').attr('alignment-baseline', 'hanging')
      .text(`Factory Greening`)

      Text
      .append('text')
      .attr('transform', `translate(0,500)`)
      .style('font-size', 13).style('fill', '#000000').attr('text-anchor', 'start').attr('alignment-baseline', 'hanging')
      .text(`Overall interconnecting pipe(A.G) 1`)

      Text
      .append('text')
      .attr('transform', `translate(0,605)`)
      .style('font-size', 13).style('fill', '#000000').attr('text-anchor', 'start').attr('alignment-baseline', 'hanging')
      .text(`Overall interconnecting pipe(A.G) 2,`)
      Text
      .append('text')
      .attr('transform', `translate(0,620)`)
      .style('font-size', 13).style('fill', '#000000').attr('text-anchor', 'start').attr('alignment-baseline', 'hanging')
      .text(`Eastward pipeline`)

      Text
      .append('text')
      .attr('transform', `translate(0,710)`)
      .style('font-size', 13).style('fill', '#000000').attr('text-anchor', 'start').attr('alignment-baseline', 'hanging')
      .text(`Heavy Lifting`)

      Text
      .append('text')
      .attr('transform', `translate(0,820)`)
      .style('font-size', 13).style('fill', '#000000').attr('text-anchor', 'start').attr('alignment-baseline', 'hanging')
      .text(`CO-Site Temporary Construction Facilities(TCF)`)
      Text
      .append('text')
      .attr('transform', `translate(0,835)`)
      .style('font-size', 13).style('fill', '#000000').attr('text-anchor', 'start').attr('alignment-baseline', 'hanging')
      .text(`extension, including office building and `)
      Text
      .append('text')
      .attr('transform', `translate(0,850)`)
      .style('font-size', 13).style('fill', '#000000').attr('text-anchor', 'start').attr('alignment-baseline', 'hanging')
      .text(`membrane structure corridor`)
    }
  }
}
