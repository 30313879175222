import * as d3 from 'd3'

export default {
  methods: {
    Goto_Screen_Dirty(val) { //Lnb direct filter working
      let del = []
      this.selectedItems.forEach((d,index) => {
         del.push(index)
      })
      del.forEach(d => {
        this.selectedItems.pop()
      })
      this.Goto_Plag = true
      this.displayGroupID(`#Keyplan_Group`, 10, 10, 1)
      this.displayGroupID(`#Legends`, 10, 10, 1)

      if (['HULL-AFT','HULL-FOR'].includes(val.LV2))  {
        this.removeSvg(this.CANVAS, 1000)

        this.removeSvg(d3.select('#tag_button_wrapper'), 1000)
        if (this.innerSvg)
        // Remove SVG
        this.removeSvg(this.innerSvg, 1000) //SVG 지우기
          
        // console.log(val, val.LV4)
        // Title & Keyplan
        d3.select(`#Title`).text(`Hull-${val.LV3}`).style('visibility', 'visible').style('opacity', 1)
        d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Keyplan/HULL_${val.LV3}.jpg`).style('visibility', 'visible').style('opacity', 1)

        // Hidden
        this.hiddenGroupID('#Menu_Back', 0, 10)

        this.hiddenGroupID('#Mask_Hull_AFT', 0, 10)
        this.hiddenGroupID('#Mask_Hull_FOR', 0, 10)
        this.hiddenGroupID('#Mask_Hull_LQ', 0, 10)
       
        // Remove SVG
        this.removeSvg(this.Dashboard_Overall, 1000) 

        if(this.svgModeling && !this.svgModeling.empty()) this.removeSvg(this.svgModeling, 1000)
        setTimeout(() => {
          // Activate
          if(val.LV3 =='AFT') this.Activate_Hull_AFT()
          if(val.LV3 =='FOR') this.Activate_Hull_FOR()
          if(val.LV3 =='MS') this.Activate_Hull_MS()
        }, 1100);
    
        this.Stage = this.STG_Activate
        let dStage = {
          stage: this.STG_Division_Lv3,
          mask : `Mask_Hull_${val.LV3}`,
          level: val.LV3
         }
         this.selectedItems.push(dStage)
        this.fncName = 'Goto_Hull_Activate'
        this.StageCode() // AREA / STAGE
        this.displayGroupID(`#Menu_Back`, 0, 200, 1)

      
      } else if ('LQ' == val.LV2)  {
       // console.log(val, val.LV3)
        // Title & Keyplan
        d3.select(`#Title`).text(`Hull-${val.LV2}`).style('visibility', 'visible').style('opacity', 1)
        d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Keyplan/HULL_${val.LV2}.jpg`).style('visibility', 'visible').style('opacity', 1)

        // Hidden
        this.hiddenGroupID('#Menu_Back', 0, 10)
        // Remove SVG
        this.removeSvg(this.Dashboard_Overall, 1000) 
       

        if(this.svgModeling && !this.svgModeling.empty()) this.removeSvg(this.svgModeling, 1000)
        let _level = val.LV3
        this.removeSvg(this.CANVAS, 1000)

        this.removeSvg(d3.select('#tag_button_wrapper'), 1000)
        if (this.innerSvg)
        // Remove SVG
        this.removeSvg(this.innerSvg, 1000) //SVG 지우기
          
        // Hidden
        this.hiddenGroupID('#Menu_Back', 0, 10)
        this.hiddenGroupID('#Mask_LQ01', 0, 10)
        this.hiddenGroupID('#Mask_LQ02', 0, 10)
        this.hiddenGroupID('#Mask_LQ03', 0, 10)
        this.hiddenGroupID('#Mask_LQ04', 0, 10)
        this.hiddenGroupID('#Mask_LQ05', 0, 10)
        this.hiddenGroupID('#Mask_LQ06', 0, 10)
        this.hiddenGroupID('#Mask_LQ07', 0, 10)
  
        // Explode
        setTimeout(() => {
          // Activate
          let result = this.Queries.SQL2.find(d => d.LV3 == val.LV3 )
          if(['LQ01','LQ02','LQ03','LQ04','LQ05','LQ06','LQ07'].includes(result.LV4)) this.Activate_Hull_LQ()
        }, 1100);
    
        this.Stage = this.STG_Activate
        let dStage = {
          stage: this.STG_Division_Lv3,
          mask : `Mask_Hull_${val.LV2}`,
          level: val.LV2
         }
         this.selectedItems.push(dStage)
        this.fncName = 'Goto_Hull_Activate'
        this.StageCode() // AREA / STAGE
        this.displayGroupID(`#Menu_Back`, 0, 200, 1)

  

      } else if(['COMMON', 'MODULES'].includes(val.LV2)) {
        this.removeSvg(this.CANVAS, 1000)

        this.removeSvg(d3.select('#tag_button_wrapper'), 1000)
        if (this.innerSvg)
        // Remove SVG
        this.removeSvg(this.innerSvg, 1000) //SVG 지우기
          
        // console.log(val, val.LV3)
        // Title & Keyplan
        d3.select(`#Title`).text(`Topside_${val.LV3}`)
        d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Keyplan/TOPSIDE_${val.LV3}.jpg`)
        
        // Hidden
        this.hiddenGroupID('#Menu_Back', 0, 0)

        // Remove SVG
        this.removeSvg(this.Dashboard_Overall, 1000) 

        if(this.svgModeling && !this.svgModeling.empty()) this.removeSvg(this.svgModeling, 1000)
        setTimeout(() => {
          // Activate
          let _mod = val.LV3
          // let lv2 = this.Lev2.toUpperCase()
          if(['LD00DA', 'KO01','FL01'].includes(_mod)) this.Activate_Topside_Module_Single(_mod)
          else this.Activate_Topside_Module(_mod)

          if(['LD00DA', 'KO01','FL01'].includes(_mod)) this.Lev2 = 'COMMON'
          else this.Lev2 = 'MODULES'

          this.Lev1 = 'TOPSIDES'
          this.Lev4 = _mod
          this.stage = this.STG_Activate
          let dStage = {
            stage: this.STG_Division_Lv3,
            mask : `Mask_${_mod}`,
            level: _mod
          }
          this.selectedItems.push(dStage)
          this.StageCode() // AREA / STAGE
          this.displayGroupID(`#Menu_Back`, 0, 200, 1)
        }, 1100);
      }
    }
  },
}