import * as d3 from 'd3'

export default {
  data: () => ({
  }),
  methods: {
    
    draw_Timeline() {
      d3.select(`#skyline_boxes_timeline`).remove()
      

      let Timeline = this.chartGroup
      .append('g')
      .attr('transform', `translate(${this.timeline.baseX}, ${this.timeline.baseY})`)
      .attr('id', `skyline_boxes_timeline`)

      /////////// draw the timeline background without the text
      Timeline
      .append('path') // shadow
      .attr('d', `M50,${this.timeline.height + 6} H ${this.timeline.length-50} L ${this.timeline.length},${this.timeline.height + 16} H0 Z`).attr('fill', `url(#shadowTimeline)`) 

      Timeline
      .append('rect') 
      .attr('x', 0).attr('y', 0).attr('width', this.timeline.length).attr('height', 2).attr('fill', `url(#${this.localId}__LinearA4LightGray0)`).attr('opacity', .99)

      Timeline
      .append('rect')
      .attr('x', 0).attr('y', 2).attr('width', this.timeline.length).attr('height', this.timeline.height).attr('fill', `url(#${this.localId}__LinearA4LightGray0)`).attr('opacity', .5)

      Timeline
      .append('rect') 
      .attr('x', 0).attr('y', this.timeline.height + 1).attr('width', this.timeline.length).attr('height', 6).attr('fill', `url(#${this.localId}__LinearA4LightGray0)`)

      let singleWidth = this.timeline.length / this.timeline.week.length

      Timeline
      .append('rect')
      .attr('x', this.timeline.scale(new Date(this.cutoff)) - singleWidth + 7)
      .attr('y', 2).attr('width', this.timeline.length / this.timeline.week.length)
      .attr('height', this.timeline.height)
      .attr('fill', '#90CAF9').attr('opacity', .5)
      

      // Timeline Text using the scale function
      if (this.timeline.style.week.display == 'Y') {
        // Weeks Text & Timeline Seperator for the Week
        Timeline
        .append('g')
        .selectAll('text')
        .data(this.timeline.week)
        .enter()
        .append('text')
        .attr('x', d => this.timeline.scale(d.mDate))
        .attr('y', 3)
        .style('font-family', this.timeline.font)
        .style('font-size', this.timeline.style.week.tSize)
        .attr('fill', this.timeline.style.week.tColor)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'hanging')
        .text(d => d3.timeFormat('%d')(d.eDate))

        Timeline
        .append('g')
        .selectAll('path')
        .data(this.timeline.week)
        .enter()
        .append('path')
        .attr('d', (d, i) => {
          if (i != this.timeline.week.length-1) return `M${this.timeline.scale(d.eDate)}, 3 V${this.timeline.style.week.line}`
        })
        .attr('stroke', this.timeline.style.week.sColor)
        .attr('stroke-width', this.timeline.style.week.sWidth)
      }

      if (this.timeline.style.month.display == 'Y') {
        // Month Text & Timeline Seperator for the Month
        Timeline
        .append('g')
        .selectAll('text')
        .data(this.timeline.month)
        .enter()
        .append('text')
        .attr('x', d => this.timeline.scale(d.mDate))
        .attr('y', this.timeline.style.week.height)
        .style('font-family', this.timeline.font)
        .style('font-size', this.timeline.style.month.tSize)
        .attr('fill', this.timeline.style.month.tColor)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'hanging')
        .text(d => d.name.toUpperCase())

        Timeline
        .append('g')
        .selectAll('path')
        .data(this.timeline.month)
        .enter()
        .append('path')
        .attr('d', (d, i) => {
          if (i != this.timeline.month.length-1) return `M${this.timeline.scale(d.eDate)}, ${this.timeline.style.week.line} V${this.timeline.style.week.line + this.timeline.style.month.line}`
        })
        .attr('stroke', this.timeline.style.month.sColor)
        .attr('stroke-width', this.timeline.style.month.sWidth)
      }

      // Year Text & Timeline Seperator for the Year
      if (this.timeline.style.year.display == 'Y') {
        Timeline
        .append('g')
        .selectAll('text')
        .data(this.timeline.year)
        .enter()
        .append('text')
        .attr('x', d => this.timeline.scale(d.mDate))
        .attr('y', this.timeline.style.week.height +  this.timeline.style.month.height)
        .style('font-family', this.timeline.font)
        .style('font-size', this.timeline.style.year.tSize)
        .attr('fill', this.timeline.style.year.tColor)
        .attr('font-weight', 500)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'hanging')
        .text(d => d.name)

        Timeline
        .append('g')
        .selectAll('path')
        .data(this.timeline.year)
        .enter()
        .append('path')
        .attr('d', (d, i) => {
          if (i != this.timeline.year.length-1) return `M${this.timeline.scale(d.eDate)}, ${this.timeline.style.week.line + this.timeline.style.month.line} V${this.timeline.style.week.line + this.timeline.style.month.line + this.timeline.style.year.line}`
        })
        .attr('stroke', this.timeline.style.year.sColor)
        .attr('stroke-width', this.timeline.style.year.sWidth)
      }

      // // draw Chart Grid
      // let grid = []
      // let step = this.realHeight / 5

      // for (let i = 1; i < 6; i++) {
      //   grid.push(this.realHeight - (step * i))
      // }

      // // if(this.chartMounted) return
      // d3.select(`#skyline_boxes_linechart_grid`).remove()

      // let chartGrid = this.chartGroup
      // .append('g')
      // .attr('transform', `translate(${this.timeline.baseX},${this.timeline.baseY-this.realHeight})`)
      // .attr('id', `skyline_boxes_linechart_grid`)
      
      // chartGrid
      // .append('g')
      // .selectAll('line')
      // .data(grid)
      // .enter()
      // .append('line')
      // .attr('x1', 0)
      // .attr('x2', this.timeline.length)
      // .attr('y1', d => d)
      // .attr('y2', d => d)
      // .attr('stroke-width', 0.3)
      // .attr('stroke', '#bcbcbc')
    }, 
  }
}
