import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({
    thead: [
      { key: 'WC_DESCRIPTION', html: 'Work Class Description', row: 1, rowspan: 3 },
      { key: 'UOM', html: 'UOM', row:1, rowspan: 3 },
      { key: 'INITIALQTY', html: 'Initial <br/> Q\'ty', row: 1, rowspan: 3 },
      { key: 'C2_QTY', html: 'C2<br/>Q\'ty', row: 1, rowspan: 3 },
      { key: 'TARGET', html: 'Plan<br/><span class="thead_subtitle__">(Based on C2 Q\'ty)</span>', row: 1, colspan: 2 },  // custom
      { key: 'ACTUAL', html: 'Actual<br/><span class="thead_subtitle__">(Actual / C2 Q\'ty)</span>', row: 1, colspan: 4 },  // custom
      { key: 'Remain', html: 'Remain', row: 1 },  // custom

      { key: 'TARGET_QTY', html: 'Q\'ty', row: 2, rowspan: 2 },
      { key: 'TARGET_PROG', html: 'Prog(%)', row: 2, rowspan: 2 },
      { key: 'ACTUAL_CUMULATIVE', html: 'Cumulative', row: 2, colspan: 2 },
      { key: 'ACTUAL_WEEKLY', html: 'Weekly', row: 2, colspan: 2 },
      { key: 'REMAIN_QTY', html: 'Q\'ty<br/><span class="thead_subtitle__">(C2-Actual)</span>', row: 2, rowspan: 2 },
      // { key: 'REMAIN_PROG', html: 'Prog(%)', row: 2, rowspan: 2 },

      { key: 'ACTUAL_CUM_QTY', html: 'Q\'ty', row: 3 },
      { key: 'ACTUAL_CUM_PROG', html: 'Prog(%)', row: 3 },
      { key: 'ACTUAL_WEEK_QTY', html: 'Q\'ty', row: 3 },
      { key: 'ACTUAL_WEEK_PROG', html: 'Prog(%)', row: 3 },
    ],
    theadrows: 3,
    tbody: [
      { key: 'WC_DESCRIPTION', w: 260, align: 'left' },
      { key: 'UOM', w: 50, align: 'center' } ,
      { key: 'INITIALQTY', w: 50 },
      { key: 'C2_QTY', w: 50 },
      { key: 'TARGET_QTY', w: 60 },
      { key: 'TARGET_PROG', w: 60 },
      { key: 'ACTUAL_CUM_QTY', w: 60 },
      { key: 'ACTUAL_CUM_PROG', w: 60 },
      { key: 'ACTUAL_WEEK_QTY', w: 60 },
      { key: 'ACTUAL_WEEK_PROG', w: 60 },
      { key: 'REMAIN_QTY', w: 60 },
      // { key: 'REMAIN_PROG', w: 60 },
    ],
    width: 900,
    
    scrollProps: {
      w: 902,
      h: 180,
      g: 0,
      bar: {
        align : 'right',
        gap   : 3,
        radius: 2,
        width : 4,
        fill  : '#9E9E9E',
        stroke: '#9E9E9E',
      }
    }
  }),

  methods: {
    drawChart() {
      let values_ = this.DataItems
      let disc = values_.map(d => d.DISC_DESCR)
      let itemWidth = values_.length > 6 ? this.width / 6 : this.width / disc.length

      let imgWidth = parseInt(itemWidth * 0.55)
      let textWidth = parseInt(itemWidth * 0.35)
      let textHeight = parseInt(imgWidth / 5)

      let itemHeight = imgWidth + 25

      let svg = this.svg
      let legendGroup = svg.append('g').attr('class', 'ogm_status_group_legend__').attr('transform', `translate(0, 0)`)

      // Target
      legendGroup.append('rect')
      .attr('x', 0).attr('y', 10).attr('rx', 3).attr('ry', 3).attr('width', 50).attr('height', 20).attr('fill', '#E1F5FE')

      legendGroup.append('text').attr('x', 25).attr('y', 23).attr('text-anchor', 'middle')
      .text('Target')
      .style('font-size',10)
      .attr('fill', '#01579B')

      // Plan
      legendGroup.append('rect')
      .attr('x', 55).attr('y', 10).attr('rx', 3).attr('ry', 3).attr('width', 45).attr('height', 20).attr('fill', '#EEEEEE')

      legendGroup.append('text').attr('x', 78).attr('y', 23).attr('text-anchor', 'middle')
      .text('Plan')
      .style('font-size',10)
      .attr('fill', '#212121')

      // Actual
      legendGroup.append('rect')
      .attr('x', 105).attr('y', 10).attr('rx', 3).attr('ry', 3).attr('width', 50).attr('height', 20).attr('fill', '#66BB6A')

      legendGroup.append('text').attr('x', 130).attr('y', 23).attr('text-anchor', 'middle')
      .text('Actual')
      .style('font-size',10)
      .attr('fill', '#fff')

      // Delta
      legendGroup.append('rect')
      .attr('x', 160).attr('y', 10).attr('rx', 3).attr('ry', 3).attr('width', 45).attr('height', 20).attr('fill', 'Transparent').attr('stroke', '#ccc')

      legendGroup.append('text').attr('x', 183).attr('y', 23).attr('text-anchor', 'middle')
      .text('Delta')
      .style('font-size',10)
      .attr('fill', 'red')


      let group = svg.append('g').attr('class', 'ogm_status_group__').attr('transform', `translate(0.5, 40)`)

      group.append('rect').attr('class', 'background_bar__')
      .attr('x', 0).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', itemWidth -10).attr('height', itemHeight).attr('fill', '#eee').attr('opacity', '0.7')

      let idx = 0
      values_.forEach((v, i) => {
        idx = i > 5 ? i % 6 : i
        // idx = i > 5 ? 0 : i
        let g = group.append('g').attr('class', `${v.DISC_DESCR}_status__`).attr('transform', `translate(${idx * itemWidth}, ${i > 5 ? 0.5 + itemHeight : 0.5})`)

        // Image box
        g.append('rect')
        .attr('x', 0).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', imgWidth).attr('height', imgWidth)
        .attr('fill', v.DELTA > 0 ? '#4CAF50' : !v.DELTA || v.DELTA === 0 ? '#fff' : '#F44336')
        .attr('opacity', '0.7')

        if (v.ACTUAL_PROG !== 0) {
          g.append('rect')
          .attr('x', 0).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', imgWidth)
          .attr('height', parseInt(imgWidth - (v.ACTUAL_PROG < 0 ? v.ACTUAL_PROG * -1 : v.ACTUAL_PROG) / 100 * imgWidth))
          .attr('fill', '#fff')
          .attr('opacity', '0.6')
        } else {
          g.append('rect')
          .attr('x', 0).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', imgWidth)
          .attr('height', imgWidth)
          .attr('fill', '#fff')
          .attr('opacity', '0.6')
        }


        if (v.ACTUAL_PROG == 100) {
          g.append('rect')
          .attr('x', 0).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', imgWidth)
          .attr('height', imgWidth)
          .attr('fill', '#43A047')
          .attr('opacity', '0.6')
        }

        g.append('image')
        .attr('id', `omg_img_${v.DISC_DESCR.toLowerCase()}`)
        .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Modules/LQDashboard/icons/${v.DISC_DESCR.toLowerCase()}.png`)
        .attr('width', imgWidth + 'px')
        .attr('height', imgWidth + 'px')

        // Discipline desc
        g.append('text')
        .attr('x', itemWidth / 2.2).attr('y', imgWidth + 15).attr('text-anchor', 'middle')
        .text(v.DISC_DESCR)
        .style('font-size',12)
        .style('color', '#0D47A1')
        .style('font-weight', 'bold')

        // Target
        g.append('rect')
        .attr('x', imgWidth + 5).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', textWidth).attr('height', textHeight).attr('fill', '#E1F5FE')

        g.append('text').attr('x', imgWidth + (textWidth / 1.7) ).attr('y', (textHeight / 2) + 4).attr('text-anchor', 'middle')
        .text(v.TARGET_PROG ? v.TARGET_PROG + '%' :'')
        .style('font-size',textHeight / 1.8)
        .attr('fill', '#00B0FF')

        // Plan
        g.append('rect')
        .attr('x', imgWidth + 5).attr('y', imgWidth * 0.25).attr('rx', 3).attr('ry', 3).attr('width', textWidth).attr('height', textHeight).attr('fill', '#EEEEEE')

        g.append('text').attr('x', imgWidth + (textWidth / 1.7) ).attr('y', (imgWidth * 0.25) + (textHeight / 2) + 4).attr('text-anchor', 'middle')
        .text(v.PLAN_PROG ? v.PLAN_PROG + '%' : '')
        .style('font-size',textHeight / 1.8)
        .attr('fill', '#212121')

        // Actual
        g.append('rect')
        .attr('x', imgWidth + 5).attr('y', imgWidth * 0.5).attr('rx', 3).attr('ry', 3).attr('width', textWidth).attr('height', textHeight).attr('fill', '#66BB6A')

        g.append('text').attr('x', imgWidth + (textWidth / 1.7) ).attr('y', (imgWidth * 0.5) + (textHeight / 2) + 4).attr('text-anchor', 'middle')
        .text(v.ACTUAL_PROG ? v.ACTUAL_PROG + '%': v.ACTUAL_PROG)
        .style('font-size',textHeight / 1.8)
        .attr('fill', '#fff')

        // Delta
        g.append('rect')
        .attr('x', imgWidth + 5).attr('y', imgWidth * 0.75).attr('rx', 3).attr('ry', 3).attr('width', textWidth).attr('height', textHeight).attr('fill', 'Transparent').attr('stroke', '#ccc')

        g.append('text').attr('x', imgWidth + (textWidth / 1.7) + 5 ).attr('y', (imgWidth * 0.75) + (textHeight / 2) + 4).attr('text-anchor', 'middle')
        .text(v.DELTA == null ? 0 + '%' : v.DELTA > 0 ? '▲ '+ v.DELTA + '%' : v.DELTA === 0 ? v.DELTA + '%' :'▼ '+ v.DELTA + '%')
        .style('font-size',textHeight / 1.8)
        .attr('fill', v.DELTA > 0 ? 'green' : v.DELTA === 0 ? '#ccc' : 'red')

        // Mask
        let mask = g.append('rect').attr('class', 'group_mask__')
        .attr('x', 0).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', itemWidth).attr('height', itemHeight).attr('fill', 'Transparent')

        mask.on('mouseover', () => {
          let maskIdx = i > 5 ? i % 6 : i
          mask.style('cursor', 'pointer')
          d3.select('.background_bar__').transition().duration(100)
          // .attr('x', `${idx * itemWidth}`)
          .attr('x', `${maskIdx * itemWidth}`)
          .attr('y', `${i > 5 ? itemHeight : 0}`)
        })

        mask.on('click', () => {
          this.drawTable(v.DISC, values_.length > 6 ? itemHeight * 2 : itemHeight)
        })

      })

      this.drawTable(values_[0].DISC, values_.length > 6 ? itemHeight * 2 : itemHeight)
    },
    drawTable(disc, height) {
      this.svg.selectAll('.ogm_table_group__').remove()

      let svg = this.svg

      let list = this.Queries.SQL1.filter(d => d.DISC === disc)

      let group = svg.append('g').attr('class', 'ogm_table_group__')

      let viewHeight = 600 - 66 - height
      let viewY = 42 + height

      // let table_group = group.append('g').attr('transform', `translate(0.5, ${height + 40})`)
      let table_group = group.append('g').attr('transform', `translate(0.5, ${viewY})`)

      let tableViewHeight = 96 + (list.length * 18) // thead + tbody
      this.scrollProps.h = viewHeight
      if (tableViewHeight > this.scrollProps.h) this.setScroll(table_group, this.scrollProps)

      let table_obj = table_group.append('foreignObject')
      .attr('y',0)
      .attr('width', '100%')
      .attr('height', `${tableViewHeight}px`)
      .append('xhtml:body')

      let table = table_obj.append('table')
                  .style('border', 'solid 0.7px #eee')
                  .style('border-collapse', 'collapse')
                  .style('font-size', '10px')
                  .style('width', this.width -5 + 'px')
                  .style('table-layout', 'fixed')

      let colgroup = table.append('colgroup')
      this.tbody.forEach(k => {
        colgroup.append('col').style('width', k.w + 'px')
      })

      let thead = table.append('thead')
      for (let r = 1; r <= this.theadrows; r++) {
        let row = this.thead.filter(d=> d.row === r)
        let tr = thead.append('tr').style('background', '#FAFAFA')

        row.forEach(item => {
          tr.append('th')
          .attr('class', 'ogm_table_group__th')
          .attr('rowspan', () => item.rowspan ? item.rowspan : '')
          .attr('colspan', () => item.colspan ? item.colspan : '')
          .style('border', 'solid 0.7px #eee')
          .style('height', '18px')
          .style('padding', '5px')
          .html(item.html)
        })        
      }
        
      let tbodykeys = this.tbody
      
      let tbody = table.append('tbody')

      for (let r = 0; r < list.length; r++) {
        let tr = tbody.append('tr')

        tbodykeys.forEach((k, i) => {
          let td = tr.append('td')
            .style('border', 'solid 0.7px #eee')
            .style('height', '18px')
            .style('text-align', k.align ? k.align : 'right')
            .style('padding', '0 3px 0 3px')

          if(i === 0) {
            td.style('white-space', 'nowrap')
              .style('overflow', 'hidden')
              .style('text-overflow', 'ellipsis')
              .attr('title', list[r][k.key])
          }

          td.on('mouseover', () => tr.style('cursor', 'pointer'))
          td.on('click', () => {
            let dataSource = {
              WCCODE: list[r].WCCODE
            }
            let request_ = this.getRequestColumnProps('status', dataSource, this.ColumnProps)
            this.$emit('request-action', request_)
          })

          if (!list[r][k.key]) return

          if(k.key.includes('PROG') && k.key != 'REMAIN_PROG') {
            td.style('position', 'relative')
              .append('div')
              .style('width', list[r][k.key] > 0 ? list[r][k.key] + '%' : 0)
              .style('height', '12px')
              .style('background-color', list[r][k.key] < 100 ? '#fde05d' : '#43A047')

            td.append('div')
            .style('position', 'absolute')
            .style('top', '1px')
            .style('right', '5px')
            .style('color', list[r][k.key] < 0 ? 'red' : list[r][k.key] === 100 ? '#fff' : '#000')
            .html(list[r][k.key]+'%')
          } else if(k.key === 'REMAIN_PROG') {
            td
            .style('color', list[r][k.key] < 0 ? 'red' : list[r][k.key] === 100 ? '#fff' : '#000')
            .html(list[r][k.key])
          }else {
            let type = typeof list[r][k.key]
            if (type == 'number') {
              let regexp = /\B(?=(\d{3})+(?!\d))/g
              td.html(list[r][k.key].toString().replace(regexp, ','))
            } else {
              td.html(list[r][k.key])
            }
          }


        })
      }

      d3.selectAll('.thead_subtitle__').style('color', '#00B0FF').style('font-weight', 'normal')
        
    }



  }
}