import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {
    Draw_Charts() {
      const item = this.DataItems
      let imagesBox = this.svg

      item.forEach( (d,i) => {
        imagesBox
        .append('image')
        .attr('id', `Image___${this.localId}`)
        .attr('clip-path', 'url(#mask_image)')
        .attr('preserveAspectRatio', 'none')
        .attr('transform', i <= 2 ? `translate(${27+(230*i)}, 24)` : `translate(${(230*i)-662},160)`)
        .attr('width', 200)//250
        .attr('height', 110)//150
        .style('filter', 'url(#dropshadow)')
        .attr('href', d.DATAURL)

        // imagesBox
        // .append('rect')
        // .attr('preserveAspectRatio', 'none')
        // .attr('transform', i <= 2 ? `translate(${27+(230*i)}, 24)` : `translate(${(230*i)-662},160)`)
        // .attr('width', 200)
        // .attr('height', 110)

        imagesBox
        .append('text')
        .attr('clip-path', 'url(#mask_text)')//각각의 text에 clipPath주기위해 g을 주지 않고 바로 url넣는다
        .attr('transform', i <= 2 ? `translate(${27+(230*i)}, 21)` : `translate(${(230*i)-662},157)`)
        .style('font-size', 14)
        .style('fill', '#424242')
        .attr('text-anchor', 'start')
        .text(d.TITLE)
      })
    }
  }
}
