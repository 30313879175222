import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({
  }),
  methods: {
    drawChart() {
      let level1 = this.DataItems.map(d => d.LV1)

      let lightBlue = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `lightBlue`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      lightBlue.append('stop').attr('stop-color', '#d4eaf3').attr('offset', '0')
      lightBlue .append('stop').attr('stop-color', '#a3d0e8').attr('offset', '1')

      let mainGroup = this.svg
      .append('g')
      .attr('transform', `translate(0, 0)`)

      let item = this.DataItems[0]

      let blockPhoto = mainGroup
      .append('g')
      .attr('transform', `translate(0, 40)`)

      blockPhoto
      .append('rect')
      .attr('x', 0).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', 300).attr('height', 218)
      .attr('fill', '#fff')

      const path = `${this.__HOST_NAME_RESOURCE}/TORTUE/Modules/${level1}.png`

      let topside = 'topsides'
      let integration = 'integrantion'

      blockPhoto
      .append('image')
      .attr('id', 'menuIcon')
      .attr('xlink:href', path)
      .attr('transform',`translate(${level1 == 'TOPSIDES' ? -8 : 20},${level1 == 'TOPSIDES' ? 5 : 30})scale(${level1 == 'TOPSIDES' ? 1 : 0.8})`)
      .attr('width', 260).attr('height', 175)
      .style('cursor', 'pointer')
      .on('click', () => {

        if(level1 == 'TOPSIDES'){
          let request_ = this.getRequestColumnProps(topside, {}, this.ColumnProps)
          if(request_) this.$emit('request-action', request_)
        }else{
          let request_ = this.getRequestColumnProps(integration, {}, this.ColumnProps)
          if(request_) this.$emit('request-action', request_)
        }
        // let request_ = {
        //   dataType: 'row',    
        //   action: {
        //     type: 'direct',
        //     target: 'url',
        //     path: `/service/construction/construction_dashboards/topside_dashboard/overall`
        //   },
        //   filters: {}
        // }
        // this.$emit('request-action', request_)
      })

      // Text
      let textgroup = mainGroup
      .append('g')
      .attr('transform', 'translate(0, 268)')

      textgroup
      .append('text')
      .attr('x', 0).attr('y', 0)
      .style('font-family','roboto').style('font-size',10).attr('text-anchor', 'start').attr('fill', '#757575')
      .text('Cum.')
      textgroup
      .append('text')
      .attr('x', 0).attr('y', 22)
      .style('font-family','roboto').style('font-size',10).attr('text-anchor', 'start').attr('fill', '#757575')
      .text('Week.')

      // Plan
      let plangroup = mainGroup
      .append('g')
      .attr('transform', 'translate(35, 235)')

      plangroup
      .append('rect')
      .attr('x', 0).attr('y', 19).attr('rx', 3).attr('ry', 3).attr('width', 60).attr('height', 20)
      // .attr('fill', `url(#lightBlue)`)
      .attr('fill', '#B3E5FC').attr('opacity', .7)
      // .attr('stroke-width', 0.2).attr('stroke', '#bcbcbc').attr('opacity', .7)
      plangroup
      .append('text')
      .attr('x', 30).attr('y', 12)
      .style('font-family','roboto').style('font-size',10).attr('text-anchor', 'middle').attr('fill', '#757575')
      .text('Plan')
      plangroup
      .append('text')
      .attr('x', 50).attr('y', 33)
      .style('font-family','roboto').style('font-size',11).attr('text-anchor', 'end').attr('fill', '#424242')
      .text(item.PLAN + '%')
      
      plangroup
      .append('rect')
      .attr('x', 0).attr('y', 42).attr('rx', 3).attr('ry', 3).attr('width', 60).attr('height', 20)
      // .attr('fill', `url(#lightBlue2)`)
      .attr('fill', '#EEEEEE')
      // .attr('stroke-width', 0.2).attr('stroke', '#bcbcbc').attr('opacity', .7)
      plangroup
      .append('text')
      .attr('x', 50).attr('y', 56)
      .style('font-family','roboto').style('font-size',11).attr('text-anchor', 'end').attr('fill', '#424242')
      .text(item.PLAN_WK + '%')

      // Actual
      let actualgroup = mainGroup
      .append('g')
      .attr('transform', 'translate(105, 235)')

      actualgroup
      .append('rect')
      .attr('x', 0).attr('y', 19).attr('rx', 3).attr('ry', 3).attr('width', 60).attr('height', 20)
      .attr('fill', '#B3E5FC').attr('opacity', .7)
      actualgroup
      .append('text')
      .attr('x', 30).attr('y', 12)
      .style('font-family','roboto').style('font-size',10).attr('text-anchor', 'middle').attr('fill', '#757575')
      .text('Actual')
      actualgroup
      .append('text')
      .attr('x', 50).attr('y', 33)
      .style('font-family','roboto').style('font-size',11).attr('text-anchor', 'end').attr('fill', '#2E7D32')
      .text(item.ACTUAL + '%')

      actualgroup
      .append('rect')
      .attr('x', 0).attr('y', 42).attr('rx', 3).attr('ry', 3).attr('width', 60).attr('height', 20)
      .attr('fill', '#EEEEEE')
      actualgroup
      .append('text')
      .attr('x', 50).attr('y', 56)
      .style('font-family','roboto').style('font-size',11).attr('text-anchor', 'end').attr('fill', '#2E7D32')
      .text(item.ACTUAL_WK + '%')

      // Delta
      let deltagroup = mainGroup
      .append('g')
      .attr('transform', 'translate(175, 235)')

      deltagroup
      .append('rect')
      .attr('x', 0).attr('y', 19).attr('rx', 3).attr('ry', 3).attr('width', 60).attr('height', 20)
      .attr('fill', '#B3E5FC').attr('opacity', .7)
      deltagroup
      .append('text')
      .attr('x', 30).attr('y', 12)
      .style('font-family','roboto').style('font-size',10).attr('text-anchor', 'middle').attr('fill', '#757575')
      .text('Delta')
      deltagroup
      .append('text')
      .attr('x', 50).attr('y', 33)
      .style('font-family','roboto').style('font-size',11).attr('text-anchor', 'end').attr('fill', '#D32F2F')
      .text(item.DELTA + '%')

      deltagroup
      .append('rect')
      .attr('x', 0).attr('y', 42).attr('rx', 3).attr('ry', 3).attr('width', 60).attr('height', 20)
      .attr('fill', '#EEEEEE')
      deltagroup
      .append('text')
      .attr('x', 50).attr('y', 56)
      .style('font-family','roboto').style('font-size',11).attr('text-anchor', 'end').attr('fill', '#D32F2F')
      .text(item.DELTA_WK + '%')


    let buttonWrap = mainGroup
      .append('g')
      .attr('id', 'button_wrapper')
      .on('mouseover', function(){ 
        d3.select(this).transition().duration(100).style('opacity',.5)
      })
      .on('mouseout', function(){ 
        d3.select(this).transition().duration(100).style('opacity',1) 
      })
      .on('click', () => {

        if(level1 == 'TOPSIDES'){
          let request_ = this.getRequestColumnProps(topside, {}, this.ColumnProps)
          if(request_) this.$emit('request-action', request_)
        }else{
          let request_ = this.getRequestColumnProps(integration, {}, this.ColumnProps)
          if(request_) this.$emit('request-action', request_)
        }
        // let request_ = {
        //   dataType: 'row',    
        //   action: {
        //     type: 'direct',
        //     target: 'url',
        //     path: `/service/construction/construction_dashboards/topsides_dashboard/overall`
        //   },
        //   filters: {}
        // }
        // this.$emit('request-action', request_)
      })

    buttonWrap
      .append('rect')
      .attr('transform', `translate(210, 40)`)
      .attr('rx', 3)
      .attr('ry', 3)
      .attr('width', 40)
      .attr('height', 14)
      .attr('fill', '#e5e5e5')
      .style('cursor', 'pointer') 

    buttonWrap
      .append('text')
      .attr('transform', `translate(230, 48)`)
      .style('font-family', 'Roboto-Regular, Roboto')
      .style('font-size', 9)
      .style('fill', '#000')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text('More')
      .style('cursor', 'pointer')
    },
  }
}
