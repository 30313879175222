export default {
  data: () => ({
    dataSet: [],
    lineChartData: [],
    Circle: {
      CircleX: 1000,
      CircleY: 100,
      CircleDiameter: 80,
      CircleColorType: 'LinearA2',
      // CircleColorSet: ['LightBlue', 'LightGray','Gray','DarkGreen' ,'SkyBlue', 'YellowGreen'],
      CircleColorSet: ['#80DEEA', '#80CBC4','#A5D6A7','#C5E1A5' ,'#FFD54F', '#FFCC80'],
      CircleRadius: 0,
      CircleHoleSize: 40,
      CircleStackSize: 5,
      CircleSliceSize: 0,
      CircleShadowDisplay: 'Y',
      CircleDropshadowDisplay: 'Y',
      CircleDropshadowDeviation: 1,
      CircleDropshadowOpacity: 0.1,
      CircleDropshadowX: 1,
      CircleDropshadowY: 1,
    },
    Guideline: {
      GuideDisplay: 'Y',
      GuideWeight: 0.5,
      GuideColor: 'gray',
      GuideInside: 5,
      GuideOutside: 15,
      GuideExtend: 30,
      GuideBulletSize: 3,
      GuideBulletAutoColor: 'Y',
      GuideBulletColor: 'gray',
      GuideTextFont: 'roboto',
      GuideTextStyle: 'regular',
      GuideTextSize: 11,
      GuideTextColor: '#9E9E9E',
      GuideValueRound: 1,
      GuideValueSize: 11,
      GuideValueColor: '#424242',
      GuideUnitFormat: '%',
      GuideUnitSize: 10,
      GuideUnitColor: '#424242'
    },
    style: {
      id          : 'AITR',
      title       : ['Total', 'Open','Overdue', 'BP', 'TFMC'],
      underTitle  : ['Review', 'development'],
      cell        : [30, 90, 156, 230, 315],
      x           : 70,
      y           : 80,
      y1          : 17,
      y0          : 21,
      y2          : 27,
      y3          : 45,
      width       : 370,
      height      : 63,
      radius      : 3,
      bColor      : '#94D5F5',
      tSize       : 11,
      tColor      : '#2c3e50',
      vSize       : 12,
      vColor      : '#fff',
      value       : []
    },
    timeline: { 
      week        : [],
      month       : [],
      year        : [],
      plan        : [],
      actual      : [],
      scale       : null,     

      weekSize    : null,     // Auto
      length      : null,     // Auto
      weight      : 0,        // Manual, Should be assigned by 'SetDefaultValues()'
      height      : null,     // Auto
      font        : 'roboto', 
      baseX       : 115,
      baseY       : 550,

      style: {
        week: {
          display   : 'N',
          height    : 15,
          line      : 12,
          sWidth    : 0.5,
          sColor    : '#BCBCBC',
          tSize     : 9,
          tColor    : '#757575',
        },
        month: {
          display   : 'N',
          height    : 14,
          line      : 15,
          sWidth    : 0.5,
          sColor    : '#BCBCBC',
          tSize     : 10,
          tColor    : '#333',
        },
        year: {
          display   : 'N',
          height    : 10,
          line      : 20,
          sWidth    : 0.5,
          sColor    : '#BCBCBC',
          tSize     : 11,
          tColor    : '#000',
        }
      }
    },
    table: {
      display: 'Y',
      bottom: 500,
      dwarY:45,
      gapFromChart: 0,
      cellWidth: 0,
      labelX: -72,

      width:290, 
      hHeight: 20,
      lineHeight: 20,

      sColor:'#757575', 
      sWidth: 0.5, 
  
      headers: [
        {name: 'Weekly Open'    , colName: 'WK_OPEN'      , x: 250, y: 13,  col: 'Y', link: 'N', align: 'middle',  tSize: 10, tColor: '#000', dColor: '#757575', dSize: 10, markupFill: '#e0e0e0'},
        {name: 'Weekly Closed'  , colName: 'WK_CLOSED'    , x: 110, y: 20,  col: 'Y', link: 'N', align: 'middle',  tSize: 10, tColor: '#000', dColor: '#757575', dSize: 10, markupFill: '#e53935'}, 
        {name: 'Cum. Open'      , colName: 'CUM_OPEN'     , x: 110, y: 20,  col: 'Y', link: 'N', align: 'middle',  tSize: 10, tColor: '#000', dColor: '#757575', dSize: 10, markupFill: '#9c27b0'},
        {name: 'Cum. Closed'    , colName: 'CUM_CLOSED'   , x: 175, y: 13,  col: 'Y', link: 'N', align: 'middle',  tSize: 10, tColor: '#000', dColor: '#757575', dSize: 10, markupFill: '#9e9d24'}, 
      ],
    }  
  })
}