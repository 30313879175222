import * as d3 from 'd3'

export default {
  data: () => ({
    resent: false,
  }),
  methods: {
    DrawSearch(){
      let searchArea = this.svg
      .append('g')
      .attr('id','searchWapper')
      .attr('transform', `translate(1243, 55)`)
      .style('font-size', 10)
      .style('font-family', 'roboto')
      // searchArea_
      // .append('rect')
      // .attr('rx', 4)
      // .attr('ry', 4)
      // .attr('width', 181)
      // .attr('height', 40)
      // .attr('fill-opacity', 0)
      // .attr('stroke', '#dadada')
      // .attr('stroke-width', .5)

      // searchArea_
      // .append('rect')
      // .attr('transform', `translate(10, -5)`)
      // .attr('width', 55)
      // .attr('height', 10)
      // .attr('fill', '#fff')
      searchArea
      .append('rect')
      .attr('transform', `translate(0, 11)`)
      .attr('width', 45)
      .attr('height', 18)
      .attr('rx',3)
      .attr('fill', '#D3D3D3')

      searchArea
      .append('text')
      .attr('transform', `translate(22.5, 20)`)
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text('Search')
      .attr('fill','#707070')

      let searchInputWrapper = searchArea
      .append('g')
      .attr('id','searchInputWapp')
      .attr('transform', `translate(30, 0)`)

      searchInputWrapper
      .append('rect')
      .attr('transform', `translate(15, 11)`)
      .attr('width', 180)
      .attr('height', 18)
      .attr('rx',3)
      .attr('fill', '#fff')
      .attr('stroke', '#D3D3D3')
      .attr('stroke-width', .5)

      // ### draw search input in the search group box
      searchInputWrapper
      .append("foreignObject")
      .attr('x', 15)
      .attr('y', 11)
      .attr('rx',3)
      .attr('width', 180)
      .attr('height', 18)
      .append('xhtml:div')
      .append('div')
      .attr('id', 'search_input')     // for the javascript document.getElementById
      .attr('class', 'search_input')  // for the d3 general purpose
      .attr('contentEditable', true)
      .attr('style', `padding: 2px 5px; font-size: 10px; line-height: 14px; text-align: left;`)
      // .call(this.callFuncSearchInput)

      // mask for the icon background area
      searchInputWrapper
      .append('rect')
      .attr('transform', `translate(170, 12)`)
      .attr('width', 24)
      .attr('height', 16)
      .attr('fill', '#fff')

      searchInputWrapper
      .append('image') 
      .attr('transform', `translate(178,14)`)
      .attr('xlink:href', require('../../../../../../src/assets/svg/icons/iconmonstr-magnifier-2.svg'))
      .attr('width', 12)
      .attr('height', 12)
      .style('cursor', 'pointer')
      .style('opacity', .3)
      // .on('mouseover', (_, i, a) => { d3.select(a[i]).transition().duration(100).style('opacity', .7) })
      // .on('mouseout', (_, i, a) => { d3.select(a[i]).transition().duration(100).style('opacity', .3) })
      // .on('click', () => { this.search() })
    },

  }
}