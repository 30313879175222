import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  data: () => ({
    header: {
      // Cells
      x: 25,
      y: 630.5,
      height: 40, 
      cx: [0, 32, 90, 170, 390, 440, 500, 560, 620, 680, 740, 800, 860, 920, 980, 1090, 1200, 1250],
      cm: [],

      ref: ['SN', 'PKG_NO', 'TAG_NO', 'TAG_DESCR', 'BLOCK', 'PO_PLAN', 'PO_ACTUAL', 'FAT_PLAN', 'FAT_ACTUAL', 'ETA_PLAN', 'ETA_ACTUAL', 'ROS', 'INSTALL','UP_BLOCK_PE', 'ROS_ETA', 'ROS_ETA_UPBLOCK', 'STATUS', ],
      depthY: [13, 20, 33],

      depth1: [
        {col: 0  ,name: 'No.'            ,colspan:1 ,depth: 1},
        {col: 1  ,name: 'Pkg No.'        ,colspan:1 ,depth: 1},
        {col: 2  ,name: 'Tag Number'     ,colspan:1 ,depth: 1}, 
        {col: 3  ,name: 'Tag Description',colspan:1 ,depth: 1}, 
        {col: 4  ,name: 'Block'          ,colspan:1 ,depth: 1},
        {col: 5  ,name: 'PO'             ,colspan:2 ,depth: 0},
        {col: 7  ,name: 'FAT'            ,colspan:2 ,depth: 0},
        {col: 9  ,name: 'ETA'            ,colspan:2 ,depth: 0},
        {col: 11 ,name: 'ROS'            ,colspan:1 ,depth: 1},
        {col: 12 ,name: 'Install'        ,colspan:1 ,depth: 1}, 
        {col: 13 ,name: 'Upper'          ,colspan:1 ,depth: 1},
        {col: 14 ,name: 'Float'          ,colspan:2 ,depth: 0},
        {col: 16 ,name: 'Status'         ,colspan:1 ,depth: 1},
      ],

      depth2: [
        {col: 5  ,name: 'Plan.'            ,depth: 2},
        {col: 6  ,name: 'Actual'           ,depth: 2},
        {col: 7  ,name: 'Plan'             ,depth: 2},
        {col: 8  ,name: 'Actual'           ,depth: 2}, 
        {col: 9  ,name: 'Plan'             ,depth: 2}, 
        {col: 10 ,name: 'Actual'           ,depth: 2},
        {col: 14 ,name: 'ROS-ETA'          ,depth: 2},
        {col: 15 ,name: 'Upper Blk.PE-ETA' ,depth: 2}, 
      ],      
    },

  }),

  
  methods: {

    draw_DataTable(_deck) {

      this.header.cx.forEach((d,i) => {
        if (i == this.header.cx.length-1) return
        this.header.cm[i] = d + ((this.header.cx[i+1] - d) / 2)
      })

      this.header.depth1.forEach((d,i) => {
        let x1 = this.header.cx[d.col]
        let x2 = this.header.cx[d.col + d.colspan]

        this.header.depth1[i].cm = x1 + ((x2 - x1) / 2)
      })

      this.header.depth2.forEach((d,i) => {
        let x1 = this.header.cx[d.col]
        let x2 = this.header.cx[d.col + 1]

        this.header.depth2[i].cm = x1 + ((x2 - x1) / 2)
      })

      let dataNo = this.dataSet.filter(f=> f.SVG == _deck).length + 1
      if(dataNo < 10) {
        this.header.y += (10 - dataNo) * 20
      }

      this.SVG_dataTable = this.svg
      .append('g')
      .attr('id', `dataTable`)
      .attr('transform', `translate(${this.header.x}, ${this.header.y})`)

      // Draw the Header
      this.SVG_dataTable
      .append('line')
      .attr('x1', 0).attr('y1', 0).attr('x2', 1250).attr('y2', 0).style('stroke-width', .5).style('stroke', '#757575')

      this.SVG_dataTable
      .append('line')
      .attr('x1', 0).attr('y1', this.header.height).attr('x2', 1250).attr('y2', this.header.height).style('stroke-width', .5).style('stroke', '#757575')

      this.SVG_dataTable
      .append('line')
      .attr('x1', this.header.cx[5]).attr('y1', 24).attr('x2', this.header.cx[11]).attr('y2', 24).style('stroke-width', .5).style('stroke', '#757575')

      this.SVG_dataTable
      .append('line')
      .attr('x1', this.header.cx[14]).attr('y1', 24).attr('x2', this.header.cx[16]).attr('y2', 24).style('stroke-width', .5).style('stroke', '#757575')

      this.SVG_dataTable
      .append('line') // Table right border
      .attr('x1', 1250).attr('y1', 0)
      .attr('x2', 1250).attr('y2', this.header.height)
      .style('stroke-width', 0.5).style('stroke', '#757575')

      this.header.depth1.forEach(d => {
        this.SVG_dataTable
        .append('text')
        .attr('transform', `translate(${d.cm}, ${this.header.depthY[d.depth]})`)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#333').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text(`${d.name}`)
      })

      this.header.depth2.forEach(d => {
        this.SVG_dataTable
        .append('text')
        .attr('transform', `translate(${d.cm}, ${this.header.depthY[d.depth]})`)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text(`${d.name}`)
      })

      this.header.depth1.forEach(d => {
        this.SVG_dataTable
        .append('line')
        .attr('x1', this.header.cx[d.col]).attr('y1', 0)
        .attr('x2', this.header.cx[d.col]).attr('y2', this.header.height)
        .style('stroke-width', 0.5).style('stroke', '#757575')
      })
      this.header.depth2.forEach(d => {
        this.SVG_dataTable
        .append('line')
        .attr('x1', this.header.cx[d.col]).attr('y1', 24)
        .attr('x2', this.header.cx[d.col]).attr('y2', this.header.height)
        .style('stroke-width', 0.4).style('stroke', '#bcbcbc')
      })



      // Draw the Cells
      let dataCells = this.SVG_dataTable
      .append('g')
      .attr('id', `dataCells`)
      .attr('transform', `translate(0, ${this.header.height})`)

      // let props = {
      //   w: 1255,
      //   h: 200,
      //   g: 0,
      //   bar: {
      //     align: 'right',
      //     gap  : 1,
      //     fill: '#83D2F5',
      //     stroke: '##44A9DF',
      //   }
      // }
      // this.setScroll(dataCells, props)

      let drawY = 10
      this.dataSet.filter(f=> f.SVG == _deck).forEach((d,i) => {

        dataCells
        .append('line')
        .attr('x1', 0).attr('y1', drawY+10)
        .attr('x2', 1250).attr('y2', drawY+10)
        .style('stroke-width', 0.3).style('stroke', '#bcbcbc')
        
        this.header.ref.forEach((r,i) => {
          if(d[`${r}`] == null) return

          let barLen = Math.abs(d[`${r}`])
          if(barLen > 80) barLen = 80

          if(i == 14) this.draw_BarChart(dataCells, 'ROS', d[`${r}`], this.header.cx[i], drawY-5.5, barLen) // ----->
          if(i == 15) this.draw_BarChart(dataCells, 'ETA', d[`${r}`], this.header.cx[i], drawY-5.5, barLen) // ----->
          if(i == 16) this.draw_BarChart(dataCells, 'INS', d[`${r}`], this.header.cx[i], drawY-5.5, barLen) // ----->
 

          dataCells
          .append('text')
          .attr('id', `data_${i}`)
          .attr('transform', () => {
            if(i == 3) return `translate(${this.header.cx[i] + 5}, ${drawY})`
            else if(i == 14 || i == 15) return `translate(${this.header.cx[i] + barLen + 2}, ${drawY})`
            else return `translate(${this.header.cm[i]}, ${drawY})`
          })
          .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('alignment-baseline', 'middle')
          .attr('text-anchor', () => {
            if(i == 3 || i == 14 || i == 15) return 'start'; else return 'middle'
          })
          .text(`${d[`${r}`]}`)
        })
        drawY += 20
      })

      this.header.depth1.forEach(d => {
        dataCells
        .append('line')
        .attr('x1', this.header.cx[d.col]).attr('y1', 0)
        .attr('x2', this.header.cx[d.col]).attr('y2', drawY-10)
        .style('stroke-width', 0.5).style('stroke', '#757575')
      })
      this.header.depth2.forEach(d => {
        dataCells
        .append('line')
        .attr('x1', this.header.cx[d.col]).attr('y1', 0)
        .attr('x2', this.header.cx[d.col]).attr('y2', drawY-10)
        .style('stroke-width', 0.4).style('stroke', '#bcbcbc')
      })

      dataCells
      .append('line') // Table right border
      .attr('x1', 1250).attr('y1', 0)
      .attr('x2', 1250).attr('y2', drawY-10)
      .style('stroke-width', 0.5).style('stroke', '#757575')

      dataCells
      .append('line') // Table bottom border
      .attr('x1', 0).attr('y1', drawY-10)
      .attr('x2', 1250).attr('y2', drawY-10)
      .style('stroke-width', 0.5).style('stroke', '#757575')




    },

    draw_BarChart(selection, col, value, x, y, len) {
      let bColor = ''
      if(col == 'ROS') {
        if(value < 0) bColor = '#F7BACF'; else bColor = '#8BC248'
      }

      if(col == 'ETA') {
        if(value < 0) bColor = '#B5549B'; else bColor = '#8BC248'
      }
      
      selection
      .append('rect')
      .attr('transform', `translate(${x}, ${y})`)
      .attr('width', len)
      .attr('height', 11)
      .attr('fill', bColor)
    },

  }
}