import * as d3 from 'd3'


export default {
  methods: {

    Draw_Lgends() {
      
      let data = null, 
          style = null, 
          item = null

      let chartPunch = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0.5)`)


      if (!this.dataSet) {
        
        chartPunch
        .append('text')
        .attr('x', 150)
        .attr('y', 120).style('font-size',16)
        .style('font-family','roboto')
        .attr('text-anchor', 'middle')
        .attr('fill', '#bcbcbc')
        .text('Not Applicable' )
        return 
      }


      // SummaryTable_01
      item = this.Queries.SQL1
      style = {
        id:       'AITR',
        title:    ['Raised', 'Closed', 'Active'],
        cell:     [50, 110, 160],
        x:        600,
        y:        70,
        y1:       10,
        y2:       26,
        width:    170,
        height:   34,
        radius:   3,
        opacity:  0.7,
        bColor:   '#E0E0DF',
        tSize:    9,
        tColor:   '#757575',
        vSize:    11,
        vColor:   '#333',
        value:    [item[0].TOTAL, item[0].ACTUAL, item[0].REMAIN]
      }
      this.SummaryTable_01(chartPunch, style) // ---------------------------> 


      let axes = chartPunch
      .append('g')
      .attr('transform', `translate(0,0.5)`)

      axes
      .append('text') 
      .attr('transform', `translate(50, 280) rotate(-90)`).style('font-family', 'roboto').attr('font-size', 11).attr('text-anchor','middle').attr('fill','#333').text('Outstanding Active Punch (Line)')

      axes
      .append('text') 
      .attr('transform', `translate(970, 280) rotate(-90)`).style('font-family', 'roboto').attr('font-size', 11).attr('text-anchor','middle').attr('fill','#333').text('Weekly Raised / Closed Punch (Bar)')


      let legend = chartPunch
      .append('g')
      .attr('transform', `translate(100,100)`)
      legend
      .append('rect')
      .attr('transform', `translate(0, 5)`)
      .attr('width', 8).attr('height', 8).attr('fill','#E0E0DF')
      legend
      .append('text') 
      .attr('transform', `translate(13, 10)`).style('font-family', 'roboto').attr('font-size', 10).attr('text-anchor','start').attr('alignment-baseline','middle').attr('fill','#757575').text('Raised (Weelky)')

      legend
      .append('rect')
      .attr('transform', `translate(100, 5)`)
      .attr('width', 8).attr('height', 8).attr('fill','#B4E3FA')
      legend
      .append('text') 
      .attr('transform', `translate(113, 10)`).attr('font-size', 10).attr('text-anchor','start').attr('alignment-baseline','middle').attr('fill','#757575').text('Closed (Weelky)')


      legend
      .append('line')
      .attr('x1', 203).attr('x2', 215).attr('y1', 10).attr('y2', 10).attr('stroke-width', 1).attr('stroke', '#333')

      legend
      .append('circle')
      .attr('cx', 209).attr('cy', 10).attr('r', 2)
      .attr('fill', '#fff').attr('stroke-width', 1).attr('stroke', '#757575')
      
      legend
      .append('text') 
      .attr('transform', `translate(220, 10)`).style('font-family', 'roboto').attr('font-size', 10).attr('text-anchor','start').attr('alignment-baseline','middle').attr('fill','#757575').text(`Active`)



      let average = chartPunch
      .append('g')
      .attr('transform', `translate(800, 70)`)

      average
      .append('rect')
      .attr('transform', `translate(0, 0)`)
      .attr('width', 120).attr('height', 35).attr('rx', 3).attr('ry', 3).attr('fill','#fff').attr('stroke','#bcbcbc').attr('stroke-width',0.5)

      average
      .append('rect')
      .attr('transform', `translate(18, -1)`)
      .attr('width', 80).attr('height', 5).attr('fill','#fff').attr('stroke','#fff')

      average
      .append('text') 
      .attr('transform', `translate(20, 0)`).style('font-family', 'roboto').attr('font-size', 10).attr('text-anchor','start').attr('alignment-baseline','middle').attr('fill','#333').text('8 Weeks Average')

      average
      .append('text') 
      .attr('transform', `translate(15, 12)`).style('font-family', 'roboto').attr('font-size', 9).attr('text-anchor','start').attr('alignment-baseline','middle').attr('fill','#bcbcbc').text('Raised')
      average
      .append('text') 
      .attr('transform', `translate(15, 26)`).style('font-family', 'roboto').attr('font-size', 12).attr('text-anchor','start').attr('alignment-baseline','middle').attr('fill','#44A9DF').text(this.avgRaised.toFixed(0))

      average
      .append('text') 
      .attr('transform', `translate(75, 12)`).style('font-family', 'roboto').attr('font-size', 9).attr('text-anchor','start').attr('alignment-baseline','middle').attr('fill','#bcbcbc').text('Closed')
      average
      .append('text') 
      .attr('transform', `translate(75, 26)`).style('font-family', 'roboto').attr('font-size', 12).attr('text-anchor','start').attr('alignment-baseline','middle').attr('fill','#44A9DF').text(this.avgClosed.toFixed(0))

      
    },
  }
}
           