<template>
  <div :id="localId"></div>
</template>

<script>
import * as d3 from 'd3'
import mx_Core from './includes/mixins/importer'            // Global & Local Mixins

export default {
  name: "j-chart-donut",
  mixins: [mx_Core],
  props: {
    id: String,
    modeExport: Boolean,

    FilterValues: {
      type: Object,
      default: () => ({})
    },
    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },
    Note: { type: Object, default: () => ({}) },
    DataItems: { type: Array, default: () => ([]) },
    ColumnProps: { type: Array, default: () => ([]) },
  },

  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
  },

  mounted() {
    this.register(this.draw)
  },

  methods: {

    draw() {
      if (!this.ready2draw) return

      this.clear()
      this.setDefaultValues()
      this.drawCanvas()
  
      this.drawNotes()
      this.drawTexts()


      this.loadSvg({
        selection : this.svg,
        width     : this.Canvas.CanvasWidth,
        height    : this.Canvas.CanvasHeight,
        path      : '/China1/Images/P2_LDPE_CSU.svg'
      }).then((svg) => {
        let zoom_ = d3.zoom()
        .translateExtent([[0, 0], [6000, 2500]])
        .scaleExtent([1, 3.75])
        .extent([[0, 0], [6000, 2500]])
        .on('zoom', this.zoom)

        this.innerSvg = svg
        this.innerSvg.call(zoom_)

        this.drawTexts()

        this.drawChart()
        this.complete()
      })
    },

    drawChart() {
      this.Draw_Content()
    },

  }
}
</script>
<style lang="stylus" scoped>
.svg_wrapper {
  width: 1600px;
  height: 667px;

  overflow: hidden;
}
</style>