import { forEach } from 'core-js/fn/array'
import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  data: () => ({
    props:[
      {head:'Package', key: 'REQ_DESCR', x: 90, tx: 5},
      {head:'Issued', key: 'OWL_TOTAL', x: 380, tx: 380},
      {head:'Open', key: 'OWL_OPEN', x: 420, tx: 420},
      {head:'Closed', key: 'OWL_CLOSED', x: 460, tx: 460},
      {head:'Overdue', key: 'OVER_DUE', x: 505, tx: 505},
    ],
    width: 530,
    height: 600,
    scrollProps: {
      w: 530,
      h: 390,
      g: 0,
      bar: {
        align : 'right',
        gap   : 3,
        radius: 2,
        width : 4,
        fill  : '#9E9E9E',
        stroke: '#9E9E9E',
      }
    }
  }),
  methods: {
    Draw_Charts() {

      const svg = this.svg
      const g = svg.append('g').attr('transform', `translate(0, 0)`)

      let data = this.DataItems
      let keys = this.props.map(d => d.key)

      let summary = [
        {key: 'Issued', value: data.reduce((accum,item) => accum + item.OWL_TOTAL, 0), color: '#E0E0E0', tx: 40},
        {key: 'Closed', value: data.reduce((accum,item) => accum + item.OWL_CLOSED, 0), color: '#00695C', tx: 160},
        {key: 'Open', value: data.reduce((accum,item) => accum + item.OWL_OPEN, 0), color: '#EF5350', tx: 280},
      ]

      let summaryGroup = g.append('g').attr('class', 'summary-group').attr('transform', `translate(110, 0.5)`)


      summary.forEach((s, i) => {
        summaryGroup
          .append('text')
          .style('font-size', 13).style('font-family', 'roboto').attr('fill', '#000')
          .attr('x', i * 120)
          .attr('y', 10)
          .attr('text-anchor', 'start')
          .text(s.key)

          summaryGroup
          .append('rect')
          .attr('x', i * 120)
          .attr('rx', 4)
          .attr('y', 15)
          .attr('width', 80)
          .attr('height', 50)
          .attr('fill', s.color)
          .attr('fill-opacity', 0.7)
          .on('click', () => {
            let request_ = {
              dataType: 'row',
              action: {
                type: 'direct',
                target: 'slide-modal',
                component: 'ServiceDatatable',
                id: 186,
                no: ''
              },
              filters: { 
              },
            }
  
            if (s.key !== 'Issued') request_.filters = { STATUS: s.key }
            this.$emit('request-action', request_)
          })
          

          summaryGroup
          .append('text')
          .style('font-size', 22).style('font-family', 'roboto').attr('fill', s.key === 'Issued' ? '#757575' : '#fff')
          .attr('x', s.tx)
          .attr('y', 50)
          .attr('text-anchor', 'middle')
          .text(s.value)
          .on('click', () => {
            let request_ = {
              dataType: 'row',
              action: {
                type: 'direct',
                target: 'slide-modal',
                component: 'ServiceDatatable',
                id: 186,
                no: ''
              },
              filters: { 
              },
            }
  
            if (s.key !== 'Issued') request_.filters = { STATUS: s.key }
            this.$emit('request-action', request_)
          })

          summaryGroup.style('cursor', 'pointer')
        
        

      })

      let table = g.append('g').attr('class', 'thead-group').attr('transform', `translate(0, 90)`)

      this.setScroll(table, this.scrollProps)

      let thead = table.append('g').attr('class', 'thead-group').attr('transform', `translate(0, 13)`)
      let head = this.props.map(d => d.head)

      head.forEach((k, i) => {
        thead.append('text')
          .attr('x', this.props.filter(d=> d.head === k)[0].x)
          .attr('y', 0)
          .attr('text-anchor', 'middle')
          .style('font-size',10)
          .attr('fill', () => '#212121')
          .style('font-weight', 'bold')
          .text(k)
      })


      // tbody
      let tbody = table.append('g').attr('class', 'tbody-group').attr('transform', `translate(0, 35)`)
      let line = table.append('g').attr('class', 'line-group').attr('transform', `translate(0.5, 0.5)`)

      let sumY = 40

      data.forEach((r, ri) => {
        keys.forEach((k, i) => {
          let value_ = tbody.append('text')
          .attr('x', this.props.filter(d=> d.key === k)[0].tx)
          .attr('y', ri * 20)
          .attr('text-anchor', i === 0 ? 'start' : 'middle')
          .attr('fill', () => r === 0 ? '#ccc' : '#757575')
          .style('font-size',10)
          .text(r[k])
          .style('cursor', () => {
            if (k === 'REQ_DESCR' || k === 'OVER_DUE' || r[k] === 0) return
            return 'pointer'
          })
          .on('mouseover', () => {
            if (k === 'REQ_DESCR' || k === 'OVER_DUE' || r[k] === 0) return
            value_.transition().duration(200).attr('fill', 'red')
            .style('font-size','12px')
            .style('font-weight', 'bold')
          })
          .on('mouseout', () => {
            if (k === 'REQ_DESCR' || k === 'OVER_DUE' || r[k] === 0) return
            value_.transition().duration(200).attr('fill', () => r === 0 ? '#ccc' : '#757575')
            .style('font-weight', 'normal')
            .style('font-size', '10px')
          })
          .on('click', () => {
            if (k === 'REQ_DESCR' || k === 'OVER_DUE' || r[k] === 0) return
            let request_ = {
              dataType: 'row',
              action: {
                type: 'direct',
                target: 'slide-modal',
                component: 'ServiceDatatable',
                id: 186,
                no: ''
              },
              filters: { 
                PO_CODE: r.PO_CODE
                
              },
              iFilters: {}
            }
            k === 'OWL_OPEN' ? request_.filters.STATUS = 'OPEN' : k === 'OWL_CLOSED' ? request_.filters.STATUS = 'CLOSED' : false
            this.$emit('request-action', request_)
          })
        })

        line.append('line')
          .attr('x1', 0)
          .attr('y1', sumY)
          .attr('x2', this.width)
          .attr('y2', sumY)
          .attr('stroke-width', '0.5px')
          .attr('stroke', '#ccc')

        sumY = sumY + 20
      })

      line.append('rect')
      .attr('x',0).attr('y', 0).attr('width',this.width).attr('height',20).attr('stroke-width', 0).attr('fill', '#e5e5e5').attr('opacity', 0.4)

      line.append('line').attr('x1',0).attr('y1',0).attr('x2',this.width).attr('y2',0).attr('stroke-width', 0.3).attr('stroke', '#333')
      line.append('line').attr('x1',0).attr('y1',20).attr('x2',this.width).attr('y2',20).attr('stroke-width', 0.3).attr('stroke', '#333')


    },
  }
}
