import * as d3 from 'd3'
import __C from '../../../../../includes/primitives/_constant_'
export default {
  data: () => ({
    stage_Header: null,
    innerSvg: null,

    search_Input : '',
    searched_Data: [],
    
    rawData: [],
    cwaCode: '',
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {
    /*
      set_LocalValues()
      loadSvg()
      zoom()
    */

    set_LocalValues(){
      this.rawData = JSON.parse(JSON.stringify(this.DataItems))
      this.cwaCode = this.rawData[0] ? this.rawData[0].CWA : ''
    },


    loadSvg(selection, d) {
      return d3.xml(`${this.__HOST_NAME_RESOURCE}${d.url}`).then(source => {
        let svg_ = source.documentElement
        selection.node().append(svg_)

        svg_ = d3.select(svg_)
        .attr('x', d.x)
        .attr('y', d.y)
        // .attr('transform', `translate(${d.x}, ${d.y}) scale(.1)`)

        return new Promise(resolve => {
          resolve(svg_)
        })
      })
    },
  }
}