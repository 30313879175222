import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  methods: {
    drawChart() {

      let item = this.DataItems[0]

      let data = this.svg
        .append('g')
        .attr('transform', `translate(0, 0)`)

      data
        .append('rect')
        .attr('x', 0).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', 175).attr('height', 38)
        .attr('fill', '#A8E0F8')

        data
        .append('text')
        .attr('x', 10).attr('y', 15)
        .style('font-family','roboto').style('font-size',10).attr('text-anchor', 'start').attr('fill', '#7A7F7D')
        .text(item.TEXT1)

        data
        .append('text')
        .attr('x', 10).attr('y', 30)
        .style('font-family','roboto').style('font-size',11).attr('text-anchor', 'start').attr('fill', '#FFF')
        .text(item.PLAN + '%')

        data
        .append('text')
        .attr('x', 68).attr('y', 15)
        .style('font-family','roboto').style('font-size',10).attr('text-anchor', 'start').attr('fill', '#7A7F7D')
        .text('Actual')

        data
        .append('text')
        .attr('x', 68).attr('y', 30)
        .style('font-family','roboto').style('font-size',11).attr('text-anchor', 'start').attr('fill', '#FFF')
        .text(item.ACTUAL + '%')

        data
        .append('text')
        .attr('x', 125).attr('y', 15)
        .style('font-family','roboto').style('font-size',10).attr('text-anchor', 'start').attr('fill', '#7A7F7D')
        .text('Delta')

        data
        .append('text')
        .attr('x', 125).attr('y', 30)
        .style('font-family','roboto').style('font-size',11).attr('text-anchor', 'start').attr('fill', '#FFF')
        .text(item.DELTA + '%')
      
    },
  }
}
