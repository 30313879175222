import Defs from '../../../../../../includes/primitives/Color_Defs'

import * as d3 from 'd3'

export default {
  data: () => ({
    
    dataSet: [],
    innerSvg: null,
    keyPlanSvg: null,

    chart: null,

    disc: [],
    uom: [],
    wccode: [],

    timelineWidth: 480, // Timeline Total length
    timeScale: null,
    timelineTickValues: null
  }),
  computed: {

    ready2draw() {

      // console.log('------------------------------')
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)

      return (
        // this.DataItems.length > 0 && //filter NA값때문에 이렇게 작업됨 
        Object.keys(this.Canvas).length > 0
      )
    },

  },



  methods: {
    setDefaultValues(){
      // this.chart = this.svg
      // .append('g')
      // .attr('transform', `translate(1321, 16)`)
      // .attr('id', 'commodity_summary_timeline_chart')


    // 1. PlanStart과 actualStart 에서 최소값구하기
    // 2. 1에서 구한 최소값에서 -2개월의 1일 날짜 구하기
    // 3. 2의 값부터 15개월 출력하기(JAN)
      this.level1Names = [...new Set(this.DataItems.map(d => d.FILTER))]

      this.disc = [...new Set(this.DataItems.map(d => d.DISC_DESCR))]
      this.uom = [...new Set(this.DataItems.map(d => d.UOM))]
      this.wccode = [...(this.DataItems.map(d => d.WCCODE))]

      this.timeScale = null
      this.timelineValues = {
        year: [],
        month: [],
      }

      this.dataSet = JSON.parse(JSON.stringify(this.DataItems))

      let startDate = new Date('2021-01-01 00:00:00')
      let endDate = new Date('2021-12-31 23:59:59')
      let noOfMonth = endDate.getMonth() - startDate.getMonth()
      let noOfYear = endDate.getYear() - startDate.getYear()
      noOfMonth = noOfMonth + (noOfYear*12)


      // get min & max values of the timeline-scale
      this.timeScale = d3.scaleTime()
      .domain([startDate, endDate])
      .range([0, this.timelineWidth])
      
      // ### Set Timeline-Values for the Year ### ---------------------------
      let prevYearDate_ = startDate

      for(var i=0; i<noOfMonth; i++) {
        let _nextMonthDate__ = new Date(new Date(startDate).setMonth(startDate.getMonth() + i))

        // For the Year ---
        if(d3.timeFormat('%Y')(prevYearDate_) != d3.timeFormat('%Y')(_nextMonthDate__)) {
          let midDateYear_ = new Date(
            d3.mean([
              prevYearDate_,                                                                    // 1st date of the year
              new Date(new Date(_nextMonthDate__).setMonth(_nextMonthDate__.getMonth() + 1) - 1)  // last date of the year
            ])
          )
          this.timelineValues.year.push({ name: d3.timeFormat('%Y')(midDateYear_), value: new Date(midDateYear_) })
          prevYearDate_ = _nextMonthDate__
        }
      }

      // Year remained
      let midDateYear_ = new Date(
        d3.mean([
          prevYearDate_,  // 1st date of the year
          endDate        // last date of the year
        ])
      )
      this.timelineValues.year.push({ name: d3.timeFormat('%Y')(midDateYear_), value: new Date(midDateYear_) })
      // ### Set Timeline-Values for the Year ### ---------------------------


      // ### Set Timeline-Values for the Month ### --------------------------
      for(i=0; i<=noOfMonth; i++) {
        let _nextMonthDate__ = new Date(new Date(startDate).setMonth(startDate.getMonth() + i))

        // For the Month ---
        let endMonth_ = new Date(new Date(_nextMonthDate__).setMonth(_nextMonthDate__.getMonth() + 1) - 1)
        let midDate = new Date(
          d3.mean([
            _nextMonthDate__, // 1st date of the month
            endMonth_         // last date of the month
          ])
        )
        this.timelineValues.month.push({ 
          name: d3.timeFormat('%b')(midDate), 
          value: new Date(midDate),
          endDate: endMonth_
        })
      }
      // ### Set Timeline-Values for the Month ### --------------------------

      let lightBlue = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `shadowTimeline`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      lightBlue.append('stop').attr('stop-color', '#3d3d3d').attr('offset', '0').attr('stop-opacity', 0.5)
      lightBlue.append('stop').attr('stop-color', '#fff').attr('offset', '1').attr('stop-opacity', 0.3)
      
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['LightGray'], [`LightGray`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['YellowGreen'], [`YellowGreen`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['LightBlue'], [`LightBlue`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['Pink'], [`Pink`, 0.5, 1.0, 0.5, -0.2], this.localId)


      

      this.svg
      .append('clipPath')
      .attr('id', 'mask_barchart')
      .append('rect')
      .attr('transform', `translate(0, 0)`)
      .attr('width', this.timelineWidth)
      .attr('height', (this.DataItems.length+1) * 23 + 200 )

    },

    
  }
}