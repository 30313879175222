import * as d3 from 'd3'

export default {
  methods: {
    removeSvg(selection, _time) {
      try {
        selection.transition().duration(_time).style('opacity', 0)
      } catch(e) {
        // console.log(e)
      }

      setTimeout(() => {
        if (selection.innerHTML) selection.html()
        selection.remove()
        selection = null
      }, _time);
    },

    displayGroupID(_id, _delay, _duration, _opacity) {
      d3.select(`${_id}`)
      .style('opacity', 0)
      .style('visibility', 'visible')
      .transition()
      .delay(_delay)
      .duration(_duration)
      .style('opacity', _opacity)
    },

    hiddenGroupID(_id, _delay, _duration) {
      d3.select(`${_id}`)
      .transition()
      .delay(_delay)
      .duration(_duration)
      .style('opacity', 0)

      d3.select(`${_id}`)
      .transition()
      .delay(_delay + _duration)
      .style('visibility', 'hidden')
    },
    
    // StageCode() {
    //   d3.select('#L1').text(this.Lev1)
    //   d3.select('#L2').text(this.Lev2)
    //   d3.select('#L3').text(this.Lev3)
    //   d3.select('#L4').text(this.Lev4)
    //   d3.select('#stage').text(this.stage)
    //   d3.select('#Func').text(this.Func)
    // },


    
  }
}
