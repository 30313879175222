import * as d3 from 'd3'
import __M from 'moment'

export default {
  methods: {
    draw_Charts() {

      d3.select(`#${this.localId}`).select('.skyline_boxes').remove()

      let BoxesChart = this.SkylineCharts
      .append('g')
      .attr('class', 'skyline_boxes')
      .attr('transform', `translate(${this.timeline.baseX},0)`)

      this.timeline.months.forEach((m, i) => {
        let drawY = this.timeline.baseY + 50 // + timeline height
        let drawX = (this.skyline.box.width + (this.skyline.box.gap)) * i

        this.dataSet.filter(f => __M(f.monthDate).format('YYYY-MM') == __M(m.date).format('YYYY-MM')).forEach(d => {
          let x1 = drawX // + this.skyline.box.gap
          let y1 = drawY


          let Boxes = BoxesChart
          .append('g')
          .attr('id', `SYS_${d.NO}`)
          .attr('transform', `translate(${x1},${y1})`)
          .style('cursor', 'pointer')
          .on('mouseover', function() {
            d3.select(this).transition().duration(150)
            .style('opacity', 0.7)
            
          })
          .on('mouseout', function(){
            d3.select(this).transition().duration(150)
            .style('opacity', 1)
          })
          .on('click', () => {
            let request_ = this.getRequestColumnProps('request', { LMS_NO: d.LMS_NO }, this.ColumnProps)
            this.$emit('request-action', request_)
          })

          if (this.skyline.box.width) {

            let lColor = this.legends.find(f => f.function == d.FUNCTIONS) ? this.legends.find(f => f.function == d.FUNCTIONS).bColor : '#424242'
            
            Boxes
            .append('rect') // the background color should be white(#fff)
            .attr('rx', d.radius)
            .attr('ry', d.radius)
            .attr('width', this.skyline.box.width)
            .attr('height', this.skyline.box.height)
            .attr('fill', '#fff')
            .attr('stroke', lColor)
            .attr('stroke-width', 2)

            // LMS_NO BOX
            Boxes
            .append('rect')
            .attr('rx', d.radius)
            .attr('ry', d.radius)
            .attr('width', this.skyline.box.width)
            .attr('height', 24)
            .attr('fill', lColor)
            .attr('stroke', lColor)
            .attr('stroke-width', 1)

            // DonutData.attrs.find( donut => d.STATUS == donut.codeValue) ? DonutData.attrs.find( donut => d.STATUS == donut.codeValue).fColor : '#fff'
            // LEAD
            Boxes
            .append('rect') // Stroke
            .attr('rx', d.radius)
            .attr('y', this.skyline.box.height - 18)
            .attr('width', this.skyline.box.width)
            .attr('height', 18)
            .attr('fill', lColor)
            .attr('stroke', lColor)
            .attr('stroke-width', 2)
          }
          

          Boxes
          .append('text')
          .attr('x', this.skyline.box.width / 2)
          .attr('y', 13)
          .attr('font-family', this.skyline.box.font)
          .attr('font-size', 12)
          .attr('font-weight', 'bold')
          .attr('fill', '#000')
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          .style('pointer-events', 'none')
          .text(d.LMS_NO)
          .style('cursor', 'pointer')
          .on('mouseover', function() {
            d3.select(this).transition().duration(150)
            .style('opacity', 0.7)
            
          })
          .on('mouseout', function(){
            d3.select(this).transition().duration(150)
            .style('opacity', 1)
          })
          .on('click', () => {
            let request_ = this.getRequestColumnProps('request', { LMS_NO: d.LMS_NO }, this.ColumnProps)
            this.$emit('request-action', request_)
          })



          Boxes
          .append("foreignObject")
          .attr('x', 0)
          .attr('y', 30)
          .attr('width', this.skyline.box.width)
          .attr('height', this.skyline.box.height - 25)
          .append('xhtml:div')
          .append('div')
          .attr('id', 'lms_description')     // for the javascript document.getElementById
          // .attr('class', 'search_input')  // for the d3 general purpose
          // .attr('contentEditable', true)
          .attr('style', `padding: 2px 5px; font-size: 10px; line-height: 14px; text-align: left; color: ${this.skyline.legend.item.tColor}; width: ${this.skyline.box.width}px; height: ${this.skyline.box.height - 60}px; overflow: hidden; `)
          .attr('title', d.LMS_DESCR)
          .text(d.LMS_DESCR)




          Boxes
          .append('text')
          .attr('x', this.skyline.box.width / 2)
          .attr('y', this.skyline.box.height - 8)
          .attr('font-family', this.skyline.box.font)
          .attr('font-size', 12)
          .attr('fill', '#000')
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          .style('pointer-events', 'none')
          .text(d.EM_LEAD)



          if (d.STATUS == 'Completed') {
            Boxes
            .append('rect')
            .attr('x', this.skyline.box.width - 30 ).attr('y', this.skyline.box.height - 50)
            .attr('rx', 50).attr('ry', 50)
            .attr('width', 25)
            .attr('height', 25)
            .attr('fill', '#8BC34A')
            .attr('stroke-width', 0)
  
            Boxes
            .append('text')
            .attr('x', this.skyline.box.width - 18 )
            .attr('y', this.skyline.box.height - 35)
            .attr('font-family', this.skyline.box.font)
            .attr('font-size', 20)
            .attr('fill', '#fff')
            .attr('font-weight', 'bold')
            .attr('text-anchor', 'middle')
            .attr('alignment-baseline', 'middle')
            .style('pointer-events', 'none')
            .text('✓')

          }

          // Comment(s) //
          let commentWrapper = BoxesChart
          .append('g')
          .attr('class', 'skyline_boxes__comment_wrapper')
          .attr('transform', `translate(${x1}, ${y1})`)
          
          // commentWrapper
          // .append('image')
          // .attr('xlink:href', require('../../../../../../src/images/message-text.png'))
          // .attr('width', 24).attr('height', 24)

          commentWrapper
          .append('path')
          .attr('d', 'M20,2H4A2,2 0 0,0 2,4V22L6,18H20A2,2 0 0,0 22,16V4C22,2.89 21.1,2 20,2Z')
          .attr('fill', '#000')
          .attr('fill-opacity', .2)
          .style('cursor', 'pointer')
          .on('mouseover', (_, i, a) => { d3.select(a[i]).attr('fill-opacity', .6) })
          .on('mouseout', (_, i, a) => { d3.select(a[i]).attr('fill-opacity', .2) })
          .on('click', () => { 
            let request_ = this.getRequestColumnProps('comment-service', null, this.ColumnProps)
            request_.action.commentProps.title = `LMS COMMENT(s) FOR ${d.LMS_NO}`
            request_.action.commentProps.refCode = `PLANNING_LMSCOMMENT_${d.LMS_NO}`
            this.$emit('request-action', request_)
           })

          // commentWrapper
          // .append('circle')
          // .attr('transform', `translate(22, 8)`)
          // .attr('r', 8)
          // .attr('fill', '#fff')
          // .style('opacity', .85)

          commentWrapper
          .append('text')
          .attr('class', `TEXT___PLANNING_LMSCOMMENT_${d.LMS_NO}`)
          .attr('transform', 'translate(12, 11)')
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          // .attr('fill-opacity', .85)
          .style('font-family', 'roboto')
          .style('font-size', 9)
          .style('fill', '#fff')
          .style('pointer-events', 'none')
          .text(d.COMMENTS_COUNT ? d.COMMENTS_COUNT : '')
  
          drawY = drawY + this.skyline.box.height + (this.skyline.box.gap*2)
        })

      })
    }, 
    Chart_filtering() {
      this.dataSet.forEach(d => { 
        if(this.visible(d)) d3.select(`#${this.localId}`).select(`#SYS_${d.NO}`).style('pointer-events', 'auto').transition().duration(500).style('opacity', 1)
        else d3.select(`#${this.localId}`).select(`#SYS_${d.NO}`).style('pointer-events', 'none').transition().duration(500).style('opacity', .1)
      })
    }
  }
}
