import Defs from '../../../../../../includes/primitives/Color_Defs'
import Data from '../../../../../../includes/primitives/Color_Data'

import * as d3 from 'd3'

export default {
  data: () => ({
    prevFilterValues: {},

    svgModeling: null,
    svg_BlockCode: null,
    SVG_AreaSummary: null,
    SVG_ModuleDivision: null,
    SVG_ModuleSummary: null,
    innerSvg: null,
    selectedItems: [],
    currentStage:           'OVERALL',
    STG_Overall:            'OVERALL',
    STG_Division_Lv1:       'Division_Lv1',
    STG_Division_Lv2:       'Division_Lv2',
    STG_Activate:           'Activate',
    STG_Level:              'EQ_LEVELMENU',
    STG_Layout:             'EQ_LAYOUT',

    STG_Module:             'Module',
    STG_Division_Lv3:       'Division_Lv3',
    STG_Division_Lv4:       'Division_Lv4',
    STG_Lv3_Block_Division: 'Lv3_Block_Division',
    STG_Lv4_Block_Division: 'Lv4_Block_Division',

    SendRequestAction: null,

    Goto_Division_Lv1: null,
    Goto_Hull_Division_Lv2: null,
    Goto_TS_Division_Lv2: null,
    Goto_TS_Division_Lv3: null,
    Goto_TS_Activate: null,
    Goto_Hull_Activate: null,
    Goto_Plag: false,
    
    Module: null,
    Area: null,
    fncName: null,

    Lev1: null,
    Lev2: null,
    Lev3: null,
    Lev4: null,

    MOVING: false,
    inFilters: {} 
  }),

  computed: {
    ready2draw() {
      // console.log('------------------------------')
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)

      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0 
      )
    },
    stage: {
      get() { return this.currentStage },
      set(val) { this.currentStage = val },
    },
  },

  methods: {
    setDefaultValues(){
      d3.selection.prototype.moveToFront = function() {
        return this.each(function(){
        this.parentNode.appendChild(this)
        })
      }
      
      // Call Events
      this.setEvent_Overall()
      this.setEvent_Hull_AFT()
      this.setEvent_Hull_FOR()
      this.setEvent_Hull_MS()
      this.setEvent_Hull_LQ()
      this.setEvent_Topside()
      this.setEvent_Goback()
      this.setEvent_Gohome()
      this.setEvent_Block()
      this.setEvent_Note()

      // Data stringify
      this.dataSet = JSON.parse(JSON.stringify(this.DataItems))

      // Style - Border
      this.dataSet.forEach(data => {

        let bItem = this.Border.find(t_ => t_.type == data.TYPE)
        if(bItem) {
          data.shape = bItem.shape
          data.width = bItem.width
          data.height = bItem.height
          data.color = bItem.color
          data.stroke = bItem.stroke
          data.textSize = bItem.textSize
          data.padding = bItem.padding
        }
      })

      // Style - Coloring
      this.dataSet.forEach(data => {
        data.bColor = '#fff'
        data.tColor = '#333'

        let bItem = this.Status.find(t_ => t_.code == data.STATUS)

        if(bItem) {
          data.bColor = bItem.code_bColor
          data.tColor = bItem.code_tColor
        }
      })

      // 텍스트 위치 x,y를 DATASET에 저장한다.

      this.dataSet.forEach(data => {
        let bItem = null
        if (data.Lv2 == 'HULL-AFT') {
          bItem = this.Hull_AFT.find(f => f.Blk == data.BLOCK)
        } else if(data.Lv2 == 'HULL-FOR') {
          bItem = this.Hull_FOR.find(f => f.Blk == data.BLOCK)
        } else if(data.Lv2 == 'LQ') {
          bItem = this.Hull_LQ.find(f => f.Blk == data.BLOCK)
        } 
        if(data.Lv1 == 'TOPSIDES') {
          bItem = this.TOPSIDES.find(f => f.Blk == data.BLOCK)
        }
        // console.log(bItem)
        if(bItem) {
          data.x = bItem.x
          data.y = bItem.y
          data.Lv4 = bItem.Lv4
        }
        // console.log(data.BLOCK, data.Lv2, data.Lv4)
      })

      this.svg
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['LightGray'], [`LightGray`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['YellowGreen'], [`YellowGreen`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['LightBlue'], [`LightBlue`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['Pink'], [`Pink`, 0.5, 1.0, 0.5, -0.2], this.localId)
      //.attr('fill', `url(#${this.localId}__LinearA4${'LightBlue'}0)`)

      // Popup Drow-Shadow ----------------------
      this.svg
      .append('defs')
      .append('filter')
      .attr('id', 'drop_shadow_eq_popup')
      .append('feDropShadow')
      .attr('dx', 1)
      .attr('dy', 1)
      .attr('stdDeviation', 2)
      .attr('flood-opacity', .2)
      // ----------------------------------------

      this.svg
      .append('clipPath')
      .attr('id', 'mask_eq3d_viewer_')
      .attr('transform', `translate(1, 1)`)
      .append('rect')
      .attr('width', 327)
      .attr('height', 297)
      .attr('rx', 4)
      .attr('ry', 4)
    },
    
    loadSvg(x, y, path) {
      return d3.xml(`${this.__HOST_NAME_RESOURCE}${path}`).then(source => {
        let innerSvg = source.documentElement
        this.svg.node().append(innerSvg)
        innerSvg = d3.select(innerSvg)

        innerSvg
        .attr('x', x)
        .attr('y', y)

        return new Promise(resolve => {
          resolve(innerSvg)
        })
      })
    },

    blockStatus(item){  //status count
      let sum = 0
      for (let i = this.Status.length - 1 ; i >= 0; i--) {
        sum += item[i].actual
        item[i].actual = sum
      }
    }

  }
}