import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({
    headers: [
      { name: 'Disciplines' , colname: 'DISC', tColor: '#454545', align: 'middle', x: 30, cx: 55, y: 10 },
      { name: 'Item' , colname: 'ACTIVITY', tColor: '#454545', align: 'start', x: 230, cx: 130, y: 10 },
      
      { name: 'Unit.', colname: 'UOM', tColor: '#c5c5c5', align: 'middle', x: 390 , cx: 400 },
      { name: 'Total', colname: 'INITIALQTY', tColor: '#454545', align: 'middle', type: 'float', x: 450, cx: 460 },
      { name: 'B/Lifting', colname: 'OGM', tColor: '#c5c5c5', align: 'middle', type: 'float', x: 510, cx: 530 },
      { name: 'Integration', colname: 'INTEGRATION', tColor: '#454545', align: 'middle', type: 'float', x: 570, cx: 590 },
      { name: 'Current', colname: 'CURRENT_QTY', tColor: '#454545', align: 'middle', type: 'float', x: 640, cx: 655 },
      { name: 'Plan', colname: 'PLAN_QTY', tColor: '#454545', align: 'middle', type: 'float', x: 700, cx: 710 },
      { name: 'Actual', colname: 'ACTUAL_QTY', tColor: '#454545', align: 'middle', type: 'float', x: 755, cx: 765 },
      
      { name: 'Plan', colname: 'PLAN_PROG', tColor: '#454545', align: 'middle', type: 'float', column: 'prog', x: 815, cx: 820 },
      { name: 'Actual', colname: 'ACTUAL_PROG', tColor: '#c5c5c5', align: 'middle', type: 'float', column: 'prog', x: 875, cx: 880 },

      { name: 'OGM', colname: 'OGM_DELTA', tColor: '#454545', align: 'middle', type: 'float', column: 'delta-ogm', x: 935, cx: 945 },
      { name: 'Plan', colname: 'PLAN_DELTA', tColor: '#454545', align: 'middle', type: 'float', column: 'delta-plan', x: 1000, cx: 1010 },
    ],
    lines: {
      horizon: {
        level1: [120, 370, 795, 915],
        level2: [430, 495, 560, 625, 683, 738, 855, 980]
      },
    },
    colors: {
      PLAN_PROG: '#FFB74D',
      ACTUAL_PROG: '#FFB74D',
      OGM_DELTA_PROG: '#E57373',
      PLAN_DELTA: '#E57373'
    },
    tWidth: 1040,

    scrollProps: {
      w: 500, // set clip width
      h: 350, // full clip height
      g: 0,
      bar: {
        align : 'right',
        gap   : 3,
        radius: 2,
        height : 6,
        fill  : '#9E9E9E',
        stroke: '#9E9E9E',
      }
    }
  }),
  methods: {
    drawChart() {
      let table__ = this.svg
      .append('g')
      .attr('class', '__progress_summary_table')
      .attr('transform', `translate(5.5, 5.5)`)

      // this.setHorizonScroll(table__, this.scrollProps)

      // Header Rows
      table__.append('rect').attr('x', 0).attr('y', 0).attr('width', this.tWidth - 110).attr('height', 40).attr('stroke-width', 0).attr('fill', '#F4F5F5').attr('opacity', 0.4)
      table__.append('line').attr('x1', 0).attr('y1', 0).attr('x2', this.tWidth).attr('y2', 0).attr('stroke-width', 0.3).attr('stroke', '#333')
      table__.append('line').attr('x1', 0).attr('y1', 41).attr('x2', this.tWidth).attr('y2', 41).attr('stroke-width', 0.3).attr('stroke', '#333')
      table__.append('line').attr('x1', 370).attr('y1', 22).attr('x2', this.tWidth).attr('y2', 22).attr('stroke-width', 0.3).attr('stroke', '#959595')
      
      // Header Text
      let headerTextGroup = table__
      .append('g')
      .attr('class', '__header_text')
      .attr('transform', `translate(0, 11)`)

      headerTextGroup
      .append('text')
      .attr('transform', `translate(570, 2)`)
      .style('font-family','roboto')
      .style('font-size', 11)
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .attr('fill', '#000')
      .text('Quantity')

      headerTextGroup
      .append('text')
      .attr('transform', `translate(850, 2)`)
      .style('font-family','roboto')
      .style('font-size', 11)
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .attr('fill', '#000')
      .text('Progress')

      headerTextGroup
      .append('text')
      .attr('transform', `translate(970, 2)`)
      .style('font-family','roboto')
      .style('font-size', 11)
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .attr('fill', '#000')
      .text('Delta')

      this.headers.forEach(h => {
        headerTextGroup
        .append('text')
        .attr('x', h.x)
        .attr('y', `${h.y ? h.y: 21}`)
        .style('font-family','roboto')
        .style('font-size', `${h.y ? 11 : 9}`)
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .attr('fill', `${h.y ? '#000' : h.colname === 'OGM' ? '#D50000' : '#757575'}`)
        .text(h.name)
      })

      // Row-Data Text
      let rowTextGroup = table__
      .append('g')
      .attr('class', '__row_text')
      .attr('transform', `translate(0, 52)`)

      let rowLineStartY = 60
      let items = this.DataItems
      let rowCount = items.length
      items.forEach((item, i) => {
        this.headers.forEach((h, j) => {
          rowTextGroup
          .append('text')
          .attr('x', h.cx)
          .attr('y', 18*i)
          .style('font-family','roboto')
          .style('font-size', 10)
          .attr('text-anchor', h.align)
          .attr('fill', item[h.colname] === 0 ? '#ccc' : '#000')
          .text(item[h.colname] && h.type && h.type === 'float' ? item[h.colname].toFixed(1) : item[h.colname]) // fix me: 이사님과 협의 후 fix
          if (h.column && h.column === 'prog') {
            rowTextGroup
            .append('rect').attr('x', h.x - 18).attr('y', (18 * i) - 10)
            .attr('width', 0).attr('height', 15).attr('stroke-width', 0)
            .attr('fill', this.colors[h.colname]).attr('opacity', 0.4)
            .transition().duration(500)
            .attr('width', item[h.colname] < 0 ? (((item[h.colname]||0) * -1) * 0.0001) * 55 
            : item[h.colname] > 100 ? ((item[h.colname]||0) * 0.0001) * 55 : ((item[h.colname]||0) * 0.01) * 55)
          }
          else if (h.column && h.column === 'delta-ogm' && item['OGM_DELTA_PROG']) {
            let nx = d3.scaleLinear().range([948, 980])
            let px = d3.scaleLinear().range([979, 948])
            
            if (item['OGM_DELTA_PROG'] < 0) {
              rowTextGroup
                .append('rect').attr('x', nx(item['OGM_DELTA_PROG'] * 0.01)).attr('y', (18 * i) - 10)
                .attr('class', 'ogm-delta-prog')
                .attr('width', Math.abs(nx(item['OGM_DELTA_PROG'] * 0.01) - nx(0)))
                .attr('height', 15)
                .attr('fill', '#F06292').attr('opacity', 0.3)
            } else {
              rowTextGroup
                .append('rect').attr('x', 948).attr('y', (18 * i) - 10)
                .attr('class', 'ogm-delta-prog')
                .attr('width', Math.abs(px(item['OGM_DELTA_PROG'] * 0.01) - px(0)))
                .attr('height', 15)
                .attr('fill', '#4DB6AC').attr('opacity', 0.3)
            }
          }
          else if (h.column && h.column === 'delta-plan' && item['PLAN_DELTA_PROG']) {
            let nx = d3.scaleLinear().range([1010, 1038])
            let pw = d3.scaleLinear().range([0, 29])
            
            if (item['PLAN_DELTA_PROG'] < 0) {
              rowTextGroup
                .append('rect').attr('x', nx(item['PLAN_DELTA_PROG'] * 0.01)).attr('y', (18 * i) - 10)
                .attr('class', 'plan-delta-prog')
                .attr('width', Math.abs(nx(item['PLAN_DELTA_PROG'] * 0.01) - nx(0)))
                .attr('height', 15)
                .attr('fill', '#F06292').attr('opacity', 0.3)
            } else {
              rowTextGroup
                .append('rect').attr('x', 1010).attr('y', (18 * i) - 10)
                .attr('class', 'plan-delta-prog')
                .attr('width', pw(item['PLAN_DELTA_PROG'] * 0.01 ))
                .attr('height', 15)
                .attr('fill', '#4DB6AC').attr('opacity', 0.3)
            }
            
          }
        })

        if(i < rowCount) table__
          .append('line')
          .attr('x1', 0)
          .attr('y1', 18*i+rowLineStartY)
          .attr('x2', this.tWidth)
          .attr('y2', 18*i+rowLineStartY)
          .attr('stroke-width', 0.3)
          .attr('stroke', '#959595')
          .attr('opacity', 0.4)
      })

      let tableHeight = table__.node().getBoundingClientRect().height
      this.lines.horizon.level1.forEach(i => {
        headerTextGroup
          .append('line')
          .attr('x1', i)
          .attr('y1', - 10)
          .attr('x2', i)
          .attr('y2', tableHeight - 10)
          .attr('stroke-width', 0.3)
          .attr('stroke', '#959595')
          // .attr('opacity', 0.4)
      })

      this.lines.horizon.level2.forEach(i => {
        headerTextGroup
          .append('line')
          .attr('x1', i)
          .attr('y1', 12)
          .attr('x2', i)
          .attr('y2', tableHeight - 10)
          .attr('stroke-width', 0.3)
          .attr('stroke', '#959595')
          // .attr('opacity', 0.4)
      })

    }, 
  }
}
