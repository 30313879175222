import * as d3 from 'd3'
// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  data:() => ({
    thead: [
      
      { key: 'ITEM', html: 'Item', row: 1 },
      { key: 'UOM', html: 'UOM', row: 1 },
      { key: 'TOTAL', html: 'Total', row: 1 },
      { key: 'PLAN_WK', html: 'Plan Wk.', row: 1 },
      { key: 'ACTUAL_WK', html: 'Act. Wk.', row: 1 },
      { key: 'PLAN_CUM', html: 'Plan Cum.', row: 1 },
      { key: 'ACTUAL_CUM', html: 'Act. Cum.', row: 1 },
      { key: 'DELTA_CUM', html: 'Delta Cum.', row: 1 },
      { key: 'PROG', html: 'Prog(%)', row: 1 },
    ],
    theadrows: 1,
    tbody: [
      
      { key: 'ITEM', w: 120, align: 'left'},
      { key: 'UOM', w: 70, align: 'center'},
      { key: 'TOTAL', w: 80,type: 'float', align: 'right'},
      { key: 'PLAN_WK', w: 80,type: 'float', align: 'right'},
      { key: 'ACTUAL_WK', w: 80, type: 'float', align: 'right'},
      { key: 'PLAN_CUM', w: 80, type: 'float', align: 'right'},
      { key: 'ACTUAL_CUM', w: 80, type: 'float', align: 'right'},
      { key: 'DELTA_CUM', w: 80, type: 'float', align: 'right'},
      { key: 'PROG', w: 60},
    ],
    width: 530,

    selectedStartDate: null,
    selectedStartColumn: null,

    selectedFinishDate: null,
    selectedFinishColumn: null,

    filteredList: null
  }),
  methods: {
    Draw_Charts() {
      let svg = this.svg

      this.filteredList = this.DataItems

      let list = this.filteredList


      let group = svg.append('g').attr('class', 'testpack_table_group__')
      let table_group = group.append('g').attr('transform', `translate(0.5, 0)`)
      
      let table_obj = table_group.append('foreignObject')
        .attr('y',0)
        .attr('width', '100%')
        .attr('height', '370px')
        .append('xhtml:body')
        .style('position', 'fixed')

      const reducer = (previousValue, currentValue) => previousValue + currentValue

      let widths = this.tbody.map(d => d.w)
      let sumWidth = widths.reduce(reducer)
      let actionColumns = ['ITEM']


      let table = table_obj.append('table')
      .attr('id', 'commodity_summary_table_')
      .style('border-collapse', 'collapse')
      .style('font-size', '10px')
      .style('width', `${sumWidth}px`)
      .style('table-layout', 'fixed')

      let colgroup = table.append('colgroup')
      this.tbody.forEach(k => {
        colgroup.append('col').style('width', k.w + 'px')
      })

      let thead = table.append('thead')
      for (let r = 1; r <= this.theadrows; r++) {
        let row = this.thead.filter(d=> d.row === r)
        let tr = thead.append('tr')

        row.forEach(item => {

          tr.append('th')
          .attr('class', 'ogm_table_group__th')
          .attr('rowspan', () => item.rowspan ? item.rowspan : '')
          .attr('colspan', () => item.colspan ? item.colspan : '')
          .style('height', '24px').style('font-size', '10px')
          .style('padding', '3px')
          .style('background', item.color ? item.color : '#E0E0E0')
          .html(item.html)
        })        
      }

      
      let tbodykeys = this.tbody

      
      let tbody = table.append('tbody').attr('id', 'testpack_summay_tbody_')

      let addClass = ''

      for (let r = 0; r < list.length; r++) {
        let tr = tbody.append('tr')

        tbodykeys.forEach((k, i) => {

          let td = tr.append('td')
            .style('border', 'solid 0.1px #eee')
            .style('height', '22px')
            .style('text-align', k.align ? k.align : 'center')
            .style('color', '#000000')
            .style('padding', '0 3px 0 3px')
          // if(i === 2) {
          //   // Total
          //   td.style('color', '#9E9E9E')
          //   .style('cursor', 'pointer')
          //   .on('click', () => {
          //     // let data = {CATEGORY : value_.CATEGORY, SUBSYSTEM: this.FilterValues.SUBSYSTEM, LOOPTEST: 'Y', STATUS_CODE:'C'}
          //     // let request_ = this.getRequestColumnProps('status', data, this.ColumnProps)
          //     // this.$emit('request-action', request_)
    
          //     let request_ = {
          //       dataType: 'row',
          //       action: {
          //         type: 'direct',
          //         target: 'slide-modal',
          //         component: 'ServiceDatatable',
          //         id: 255,
          //         no: ''
          //       },
          //       filters     : {SUBSYSTEM: this.FilterValues.SUBSYSTEM, LOOPTEST: 'Y', QCF: list[r].QCF},
          //       iFilters    : {
          //         filterString : '',
          //         inputFilter  : ''
          //       }
          //     }
          //     if (list[r].GENERAL_DESIGN_SCOPE) {
          //       request_.filters.GENERAL_DESIGN_SCOPE = list[r].GENERAL_DESIGN_SCOPE
          //       console.log(list[r].GENERAL_DESIGN_SCOPE)
          //     }
          //     this.$emit('request-action', request_)
          //   })
          // }


          if(i === 0) {
            td.style('white-space', 'nowrap')
              .style('overflow', 'hidden')
              .style('text-overflow', 'ellipsis')
              .attr('title', list[r][k.key])
              td.style('background-color', '#FFF8E1')
          }
          if(i === 7) {
            td.style('color', list[r][k.key] < 0 ? 'red':list[r][k.key] ==0 ? '#9E9E9E':'green')
          }
          if(actionColumns.includes(k.key)) {
            // td.style('text-decoration', 'underline').style('color','#039BE5')
            td.on('mouseover', () => td.style('cursor', 'pointer').style('opacity',0.5))
            td.on('mouseout', () => td.style('cursor', 'pointer').style('opacity',1))
            td.on('click', () => {
              console.log(k.key, list[r])
              // let request_ = this.getRequestColumnProps('ITEM', { ITEM: list[r].ITEM }, this.ColumnProps)
              // this.$emit('request-action', request_)
            })
          }
          if(k.key.includes('PROG')) {
            addClass = ''
            td
            .style('position', 'relative')
            .style('color', list[r][k.key] < 0 ? 'red' : list[r][k.key] === 100 ? '#fff' : addClass == 'caution' ? 'red' : '#000')
            .style('z-index', '1')
            // .html(list[r][k.key] == null ? 0+'%' :list[r][k.key]+'%')
            .html(list[r][k.key] == null ? 0+'%' :Number(list[r][k.key]).toFixed(1)+'%')

            td.append('div')
            .style('position', 'absolute')
            .style('left', 0)
            .style('top', 0)
            .style('width', list[r][k.key] > 0 ? list[r][k.key] + '%' : 0)
            .style('height', '21px')
            .style('background-color', list[r][k.key] < 100 ? k.key.includes('PLAN') ? '#E0E0E0' : '#FFCA28' : '#4CAF50')
            .style('z-index', '-1')
          }
          else {
            if (k.type == 'float') {
              let regexp = /\B(?=(\d{3})+(?!\d))/g
              if (isNaN(Math.floor(list[r][k.key]))) td.html('')
              else td.html(Math.round(Math.floor(list[r][k.key] * 100) / 100).toString().replace(regexp, ','))
            } else td.html(list[r][k.key])
          }
        })
      }
    },
  }
}
