import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  data: () => ({
    PieData: [],



  }),

  methods: {
    Dashboard_Overall() {

      this.SVG_Overall = this.svg.append('svg')

      this.SVG_Overall
      .attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight)
      .style('opacity', 1)

      let MD2_FPU = this.SVG_Overall

      .append('g')
      .attr('id', 'MD2_FPU')
      .attr('transform', `translate(0,-300) scale(1.5)`)
      .style('opacity', 0)
      .style('cursor', 'pointer')
      .call(s => this.Event_Overall(s))

      MD2_FPU
      .append('image')
      .attr('id', 'MD2_Hull')
      .attr('transform', `translate(128,415) scale(0.4)`)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/Demo/Equipment_Layout/MD2/Overall/Hull.png`)
      .style('opacity', 1)
      .style('cursor', 'pointer')
      .call(s => this.Event_Area(s,'Hull'))

      MD2_FPU
      .append('image')
      .attr('id', 'MD2_Deckbox')
      .attr('transform', `translate(118,401) scale(0.4)`)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/Demo/Equipment_Layout/MD2/Overall/Deckbox.png`)
      .style('opacity', 1)
      .style('cursor', 'pointer')
      .call(s => this.Event_Area(s,'Deckbox'))

      MD2_FPU
      .append('image')
      .attr('id', 'MD2_Topside')
      .attr('transform', `translate(157,345) scale(0.4)`)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/Demo/Equipment_Layout/MD2/Overall/Topside.png`)
      .style('opacity', 1)
      .style('cursor', 'pointer')
      .call(s => this.Event_Area(s,'Topside'))

      d3.select(`#MD2_FPU`).transition().duration(500).style('opacity', 1)
  
    },



  }
}
           