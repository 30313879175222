import * as d3 from 'd3'

export default {
  methods: {
    Activate_Hull_LQ() {

      // Remove SVG
      this.removeSvg(this.CANVAS, 1000)

      this.svgModeling = this.svg.append('svg')
      .attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight)
      .attr('viewBox', `0 0 ${this.Canvas.CanvasWidth} ${this.Canvas.CanvasHeight}`)
      .attr('opacity', 1)

      // Overall_Hull_Group
      let Hull_LQ = this.svgModeling
      .append('g')
      .attr('id', 'Hull_LQ_Group')
      .attr('opacity', 1)
      .attr('transform', `translate(0,0) scale(1)`)
      
      setTimeout(() => {
        // Draw the Block image & Codes
        this.Hull_LQ.forEach(d => {
          Hull_LQ
          .append('image')
          .attr('id', `Blk_${d.BLK}`)
          .attr('opacity', 1).style('visibility', 'visible')
          .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Blocks/Hull/${d.BLK}.png`)
          .attr('transform', `translate(${d.x1}, ${d.y1}) scale(${0.35})`)
        })
      }, 500);

      setTimeout(() => {
        this.Hull_LQ.forEach(d => {
          d3.select(`#Blk_${d.BLK}`).transition().duration(700).attr('transform', `translate(${d.x2}, ${d.y2}) scale(${0.35})`)
        })
      }, 1200);

      setTimeout(() => {
        this.Hull_LQ.forEach(d => {
          d3.select(`#Blk_${d.BLK}`).transition().duration(1000).attr('transform', `translate(${d.x3}, ${d.y3}) scale(${0.35})`)
        })
      }, 1700);

      setTimeout(() => {
        this.Hull_LQ.forEach(d => {
          d3.select(`#Blk_${d.BLK}`).transition().duration(1000).attr('transform', `translate(${d.x4}, ${d.y4}) scale(${0.35})`)
        })
      },2400);

      setTimeout(() => {
        // Display
        this.displayGroupID(`#Mask_LQ01`, 0, 0, 0)
        this.displayGroupID(`#Mask_LQ02`, 0, 0, 0)
        this.displayGroupID(`#Mask_LQ03`, 0, 0, 0)
        this.displayGroupID(`#Mask_LQ04`, 0, 0, 0)
        this.displayGroupID(`#Mask_LQ05`, 0, 0, 0)
        this.displayGroupID(`#Mask_LQ06`, 0, 0, 0)
        this.displayGroupID(`#Mask_LQ07`, 0, 0, 0)

        this.Lev1 = 'HULL'
        this.Lev2 = 'LQ'
        this.stage = this.STG_Activate
        this.fncName = 'Activate_Hull_LQ'
        this.StageCode() // AREA / STAGE
        this.displayGroupID(`#Menu_Back`, 0, 200, 1)

        // ### Call Canvas ###
        this.drawProgress('Hull LQ')
      },3200);

      this.Mask_LQ_Group()
    },

    Mask_LQ_Group(){
      this.svgModeling 
      .append('polygon')
      .attr('id', 'Mask_LQ01') // Mask_LQ01
      .attr('points', '0,59.75 81,28 81,11 101.5,0 120,17 133,16 188,52 180,59.5 248,95 264,92.5 287,105 287,136 240,160 226,152 157,179 129,163.5 113.5,171.5 97,159 96,145.5 84,153 58.5,139 69.5,128 22,106 21,92 0,74.5')
      .attr('fill', '#ffffff').attr('transform', `translate(404,522)`)
      .style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_LQ_Level(s, '#Mask_LQ01', 'LQ01') }) // <--------

      this.svgModeling 
      .append('polygon')
      .attr('id', 'Mask_LQ02') // Mask_LQ02
      .attr('points', '1,33.169 79.118,0.125 104,14.5 104,25 118,31 133.5,27.5 218,73 218,96 123,133.5 94.5,118.5 61.75,137 39,120 39,98 56.743,95 1,64.282')
      .attr('fill', '#ffffff').attr('transform', `translate(593,403)`)
      .style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_LQ_Level(s, '#Mask_LQ02', 'LQ02') }) // <--------

      this.svgModeling 
      .append('polygon')
      .attr('id', 'Mask_LQ03') // Mask_LQ03
      .attr('points', '1,34.75 79,0 106.875,15.25 119.438,8.75 257.469,82.75 149.484,125.25 124.242,130.75 1,62.5')
      .attr('fill', '#ffffff').attr('transform', `translate(738,300)`)
      .style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_LQ_Level(s, '#Mask_LQ03', 'LQ03') }) // <--------

      this.svgModeling 
      .append('polygon')
      .attr('id', 'Mask_LQ04') // Mask_LQ04
      .attr('points', '1,35.125 32,24.75 32,17 75.25,0 162.75,49.75 176,45.25 193.25,52.25 200,45.25 212,49.25 212,61 234,70 234,96 197.25,109.75 182.25,101.5 122.375,123.75 1,57.5')
      .attr('fill', '#ffffff').attr('transform', `translate(696,678)`)
      .style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_LQ_Level(s, '#Mask_LQ04', 'LQ04') }) // <--------

      this.svgModeling 
      .append('polygon')
      .attr('id', 'Mask_LQ05') // Mask_LQ05
      .attr('points', '1,35.25 31,24.75 31,17 72.25,1.5 164,51.5 174.75,47.25 216,69.75 216,89 121.625,125.5 1,59.5')
      .attr('fill', '#ffffff').attr('transform', `translate(837,545)`)
      .style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_LQ_Level(s, '#Mask_LQ05', 'LQ05') }) // <--------

      this.svgModeling 
      .append('polygon')
      .attr('id', 'Mask_LQ06') // Mask_LQ06
      .attr('points', '1,43.125 70.125,9.688 137.688,45.969 149.969,40.984 157.984,15.617 195.5,1 241,1 277.25,23.484 273,49.75 218,71.25 216.75,102.75 122.375,136.75 1,69')
      .attr('fill', '#ffffff').attr('transform', `translate(975,440)`)
      .style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_LQ_Level(s, '#Mask_LQ06', 'LQ06') }) // <--------

      this.svgModeling 
      .append('polygon')
      .attr('id', 'Mask_LQ07') // Mask_LQ07
      .attr('points', '1.375,44.25 41.063,28.5 41.25,14.25 78.453,1.313 100,10 100,26 153,53.5 153,76 166.5,84.5 175.75,72.25 193,67.5 230,86.5 230,103 210.25,114.25 197.25,105.25 125.25,139 8,70.156')
      .attr('fill', '#ffffff').attr('transform', `translate(1148,306)`)
      .style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_LQ_Level(s, '#Mask_LQ07', 'LQ07') }) // <--------
    },
  }
}