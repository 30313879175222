export default {
  data: () => ({
    dataSet: [],
    rowHeight: 19,
    level1Names: [],
    exportItems: []
  }),
  computed: {
    ready2draw() {

      // console.log('------------------------------')
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)

      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },
  methods: {
    setDefaultValues(){
      this.level1Names = [...new Set(this.DataItems.map(d => d.DISC))]
      this.dataSet = JSON.parse(JSON.stringify(this.DataItems))

      let lightGray = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `lightGray`).attr('x1', 0.772).attr('x2', 0.228).attr('y1', 0.228).attr('y2', 0.919)
      lightGray.append('stop').attr('stop-color', '#fff').attr('offset', '0').attr('stop-opacity', 1)
      lightGray.append('stop').attr('stop-color', '#d0d2d3').attr('offset', '1').attr('stop-opacity', 1)
      
      this.svg
      .append('clipPath')
      .attr('id', 'activity_overflow_none')
      .append('rect')
      .attr('transform', `translate(0, 0)`)
      .attr('width', 390)
      .attr('height', this.rowHeight)
    },
  }
}