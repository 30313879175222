import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {

  methods: {
    Activate_Overall() {

      this.Activate_OverallnMasks()

      d3.select(`#Area_AFT`).transition().duration(500).style('visibility', 'visible').style('opacity', 1)
      d3.select(`#Area_MS`).transition().duration(500).style('visibility', 'visible').style('opacity', 1)
      d3.select(`#Area_FOR`).transition().duration(500).style('visibility', 'visible').style('opacity', 1)
      d3.select(`#Topside_Group`).transition().duration(500).style('visibility', 'visible').style('opacity', 1)
      d3.select(`#Area_LQ`).transition().duration(500).style('visibility', 'visible').style('opacity', 1)
      d3.select(`#Area_VS`).transition().duration(500).style('visibility', 'visible').style('opacity', 1)

      this.displayGroupID(`#Mask_Overall`, 10, 10, 0)

      // ### Call Canvas ###
      this.drawProgress('Overall') 
    },


    Activate_OverallnMasks() {

      this.Dashboard_Overall = this.svg.append('svg')

      this.Dashboard_Overall
      .attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight)
      .style('opacity', 1)
      .attr('x', 0).attr('y', 0)

      this.Dashboard_Overall
      .append('image')
      .attr('id', 'Area_MS')
      .attr('transform', `translate(854, 357) scale(0.75)`)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Area/HULL_MS.png`)
      .style('visibility', 'hidden').style('opacity', 0)

      this.Dashboard_Overall
      .append('image')
      .attr('id', 'Area_FOR')
      .attr('transform', `translate(706, 481) scale(0.75)`)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Area/HULL_FOR.png`)
      .style('visibility', 'hidden').style('opacity', 0)
      
      this.Dashboard_Overall
      .append('image')
      .attr('id', 'Area_AFT')
      .attr('transform', `translate(439, 553) scale(0.75)`)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Area/HULL_AFT.png`)
      .style('visibility', 'hidden').style('opacity', 0)

      this.Dashboard_Overall
      .append('image')
      .attr('id', 'Area_LQ')
      .attr('transform', `translate(946, 342) scale(0.75)`)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Area/HULL_LQ.png`)
      .style('visibility', 'hidden').style('opacity', 0)
      
      let Topside_Group = this.Dashboard_Overall
      .append('g')
      .attr('id', 'Topside_Group')
      .attr('transform', `translate(0,0) scale(1)`)
      .attr('opacity', 0).style('visibility', 'hidden')

      Topside_Group
      .append('image')
      .attr('id', 'MW05')
      .attr('transform', `translate(841, 412) scale(0.75)`).style('visibility', 'visible').style('opacity', 1)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Area/MW05.png`)

      Topside_Group
      .append('image')
      .attr('id', 'MW04')
      .attr('transform', `translate(793, 402) scale(0.75)`).style('visibility', 'visible').style('opacity', 1)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Area/MW04.png`)

      Topside_Group
      .append('image')
      .attr('id', 'MW02')
      .attr('transform', `translate(615, 499) scale(0.75)`).style('visibility', 'visible').style('opacity', 1)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Area/MW02.png`)

      Topside_Group
      .append('image')
      .attr('id', 'MW01')
      .attr('transform', `translate(527, 518) scale(0.75)`).style('visibility', 'visible').style('opacity', 1)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Area/MW01.png`)

      Topside_Group
      .append('image')
      .attr('id', 'FL01')
      .attr('transform', `translate(485, 333) scale(0.75)`).style('visibility', 'visible').style('opacity', 1)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Area/FL01.png`)

      Topside_Group
      .append('image')
      .attr('id', 'LD00DA')
      .attr('transform', `translate(918, 444) scale(0.75)`).style('visibility', 'visible').style('opacity', 1)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Area/LD00DA.png`)   

      Topside_Group
      .append('image')
      .attr('id', 'TSRC05')
      .attr('transform', `translate(882, 463) scale(0.75)`).style('visibility', 'visible').style('opacity', 1)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Area/TSRC05.png`)

      Topside_Group
      .append('image')
      .attr('id', 'TSRC04')
      .attr('transform', `translate(815, 487) scale(0.75)`).style('visibility', 'visible').style('opacity', 1)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Area/TSRC04.png`)

      Topside_Group
      .append('image')
      .attr('id', 'TSRC03')
      .attr('transform', `translate(731, 513) scale(0.75)`).style('visibility', 'visible').style('opacity', 1)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Area/TSRC03.png`)

      Topside_Group
      .append('image')
      .attr('id', 'TSRC02')
      .attr('transform', `translate(652, 532) scale(0.75)`).style('visibility', 'visible').style('opacity', 1)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Area/TSRC02.png`)

      Topside_Group
      .append('image')
      .attr('id', 'TSRC01')
      .attr('transform', `translate(569, 558) scale(0.75)`).style('visibility', 'visible').style('opacity', 1)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Area/TSRC01.png`)

      Topside_Group
      .append('image')
      .attr('id', 'KO01')
      .attr('transform', `translate(472, 611) scale(0.75)`).style('visibility', 'visible').style('opacity', 1)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Area/KO01.png`)

      Topside_Group
      .append('image')
      .attr('id', 'ME05')
      .attr('transform', `translate(897, 452) scale(0.75)`).style('visibility', 'visible').style('opacity', 1)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Area/ME05.png`)

      Topside_Group
      .append('image')
      .attr('id', 'ME04')
      .attr('transform', `translate(843, 431) scale(0.75)`).style('visibility', 'visible').style('opacity', 1)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Area/ME04.png`)

      Topside_Group
      .append('image')
      .attr('id', 'ME02')
      .attr('transform', `translate(663, 540) scale(0.75)`).style('visibility', 'visible').style('opacity', 1)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Area/ME02.png`)

      Topside_Group
      .append('image')
      .attr('id', 'ME01')
      .attr('transform', `translate(592, 557) scale(0.75)`).style('visibility', 'visible').style('opacity', 1)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Area/ME01.png`)
        
      this.Dashboard_Overall
      .append('image')
      .attr('id', 'Area_VS')
      .attr('transform', `translate(1000, 437) scale(0.75)`)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Area/HULL_VS.png`)
      .style('visibility', 'hidden').style('opacity', 0)


      // Goto_Division_Lv1
      this.Dashboard_Overall
      .append('polygon')
      .attr('id', 'Mask_Overall')
      .attr('transform', `translate(140, 150) scale(1)`)
      .attr('points', `340.599,465 365.271,201.535 356.833,186 376.557,170.486 404.729,178.222 417.407,378.217 432.196,361.313 492.76,337.367 539.944,336.663 582.197,355.677 605.387,377.507 649,359.197 649,325 664.592,310.605 674.451,250.747 712.479,226.803 704.732,197.226 737.831,188.774 795.577,238.07 802.62,226.803 814.592,197.226 845.577,176.099 880.789,185.958 884.31,218.352 904.732,226.803 955.437,212.718 986,235.253 986,251 959.662,272.578 971.634,292.296 
      978.676,317.608 979.38,371.873 935.014,442.296 424.451,633 321.634,573.281 294,544.408 294,497 321.634,465.535 `)
      .attr('fill', '#fff').style('opacity', 0).style('visibility', 'hidden').style('cursor', 'pointer')
      .call(this.Goto_Division_Lv1) // <--------

      // Goto_Division_Lv2
      this.Dashboard_Overall
      .append('polygon')
      .attr('id', 'Mask_Topside')
      .attr('transform', `translate(424, 225) scale(1)`)
      .attr('points', `70.167,29.5 63.584,17.583 82.292,-0.042 111.647,10.313 122.991,210.323 136.996,192.495 194.999,169.665 247,170.999 292.334,192.5 309.833,210.333 356,189.667 356,159 373.001,140.333 387.668,79.667 426.334,69 437.001,105.167 484.334,76.333 512.334,87.667 528.334,127 620,171.667 620,196 473.001,267.667 423.001,264.333 143.001,385 57.668,373.667 0,331 31.001,297.667 45.668,297`)
      .attr('fill', '#fff').style('opacity', 0).style('visibility', 'hidden').style('cursor', 'pointer')
      .call(s => { this.Goto_TS_Division_Lv2(s, 'Mask_Topside') }) // <--------

      this.Dashboard_Overall
      .append('polygon')
      .attr('id', 'Mask_Hull')
      .attr('transform', `translate(435, 475) scale(1)`)
      .attr('points', `23,295 56.667,270.665 79.334,279.334 87.334,254 194.001,217.334 210.668,225.666 256.001,212 271.167,218 467,143.334 467,71 442.001,49 419,49 414.001,27.333 443.334,20.667 494,56.667 494,135 511.334,100 520.001,20.667 573.334,-0.333 601.334,48.667 660.668,40 691.666,56.667 686.668,78 664.001,90 678.668,136.667 679.334,203.334 633.334,270 129.334,451.666 34.168,397.334 0,358.667 0,320`)
      .attr('fill', '#fff').style('opacity', 0).style('visibility', 'hidden').style('cursor', 'pointer')
      .call(s => { this.Goto_Hull_Division_Lv2(s, 'Mask_Hull') }) // <--------

      this.Dashboard_Overall
      .append('polygon')
      .attr('id', 'Mask_Hull_FOR')
      .attr('transform', `translate(680,541) scale(1)`)
      .attr('points', `0,78.155 211.774,-0.028 296.282,30.31 319,62.718 319,145 99.099,226 0,170.465`)
      .attr('fill', '#fff').style('opacity', 0).style('visibility', 'hidden').style('cursor', 'pointer')
      .call(s => { this.Goto_Hull_Activate(s, 'Mask_Hull_FOR', 'FOR') }) // <--------
      
      // Goto_Hull_Activate
      this.Dashboard_Overall
      .append('polygon')
      .attr('id', 'Mask_Hull_AFT')
      .attr('transform', `translate(293,664) scale(1)`)
      .attr('points', `2.62,107.324 26.563,79.739 56.845,59.257 79.38,68.382 86.422,42.311 192.761,4.275 210.366,15.539 256.141,-0.058 294.873,7.085 377,50.042 377,147 128.676,239.94 33.605,185.958 0,146.521`)
      .attr('fill', '#fff').style('opacity', 0).style('visibility', 'hidden').style('cursor', 'pointer')
      .call(s => { this.Goto_Hull_Activate(s, 'Mask_Hull_AFT', 'AFT') }) // <--------

      this.Dashboard_Overall
      .append('polygon')
      .attr('id', 'Mask_Hull_MS')
      .attr('transform', `translate(952,373) scale(1)`)
      .attr('points', `5.985,31.634 -0.078,8.444 32.003,-0.024 84,38.774 84,121 95.578,85.253 125.155,97.226 168.817,79.62 222.338,97.93 232.901,117.648 273,137.5 273,181 228.676,246.521 156.845,275 60.366,220.465 54.732,55.676`)
      .attr('fill', '#fff').style('opacity', 0).style('visibility', 'hidden').style('cursor', 'pointer')
      .call(s => { this.Goto_Hull_Activate(s, 'Mask_Hull_MS', 'MS') }) // <--------

      this.Dashboard_Overall
      .append('polygon')
      .attr('id', 'Mask_Hull_LQ')
      .attr('transform', `translate(1046,187) scale(1)`)
      .attr('points', `11.958,21.049 68.908,-0.06 78.722,38.041 97.009,54.957 154.04,36.655 183.006,54.968 177.271,78.914 150.691,96.999 172,119.761 172,166 91.5,194 5.437,150.747 0,102.155`)
      .attr('fill', '#fff').style('opacity', 0).style('visibility', 'hidden').style('cursor', 'pointer')
      .call(s => { this.Goto_Hull_Activate(s, 'Mask_Hull_LQ', 'LQ') }) // <--------


      // Mask_TS_Common
      this.Dashboard_Overall
      .append('polygon')
      .attr('id', 'Mask_TS_Common')
      .attr('transform', `translate(730, 530) scale(1)`)
      .attr('points', `34.31,285.253 58.958,31.028 50.507,16.944 73.042,0 99.803,9.197 111.774,261.31 125.155,266.944 136.422,250.042 283.605,200.042 300.507,209.901 464.592,142.627 494.169,108.493 611,176.099 603.324,198.634 541.352,211.31 496.282,190.183 185.718,307.789 213.887,340.183 139.239,379.62 90.648,388 0,361.31 11.774,299.338`)
      .attr('fill', '#fff').style('opacity', 0).style('visibility', 'hidden').style('cursor', 'pointer')
      .call(s => { this.Goto_TS_Division_Lv3(s, 'Mask_TS_Common', 'COMMON') }) // <--------
      
      this.Dashboard_Overall
        .append('polygon')
        .attr('id', 'Mask_FL01')
        .attr('transform', `translate(336, 425) scale(1)`).attr('points', `0,340 28.68,33.14 22.34,21.87 51.92,0 77.27,26.8 71.63,35.96 77.27,257.79 114,281.73 114,316 71.63,334.55 44.87,315.59 27.27,340`)
        .attr('fill', '#fff').style('opacity', 0).style('visibility', 'hidden').style('cursor', 'pointer')
        .call(s => { this.Goto_TS_Activate(s, 'Mask_FL01', 'FL01') }) // <--------

      this.Dashboard_Overall
      .append('polygon')
      .attr('id', 'Mask_KO01')
      .attr('transform', `translate(372, 753) scale(1)`).attr('points', `0,44.102 9.662,22.776 73.747,0.1 99,19.106 126.563,17.821 177.972,44 198,44 198,59 174.451,89.479 77.972,117 0,90.183`)
      .attr('fill', '#fff').style('opacity', 0).style('visibility', 'hidden').style('cursor', 'pointer')
      .call(s => { this.Goto_TS_Activate(s, 'Mask_KO01', 'KO01') }) // <--------

      this.Dashboard_Overall
      .append('polygon')
      .attr('id', 'Mask_LD00DA')
      .attr('transform', `translate(1177, 407) scale(1)`).attr('points', `26.563,0 138.535,57.789 146,83.141 86.282,106 5.437,53 0,28.211`)
      .attr('fill', '#fff').style('opacity', 0).style('visibility', 'hidden').style('cursor', 'pointer')
      .call(s => { this.Goto_TS_Activate(s, 'Mask_LD00DA', 'LD00DA') }) // <--------



      // Mask_TS_Modules
      this.Dashboard_Overall
      .append('polygon')
      .attr('id', 'Mask_TS_Modules')
      .attr('transform', `translate(201, 12) scale(1)`)
      .attr('points', `245.165,464.501 286.25,371.083 352.334,339 399,339 448,349.667 448,379 473.668,386.333 509.001,370.333 513.001,325.667 537.001,297 539.668,250.333 578.334,240.667 593.668,275.667 621.001,263 632.334,247 659.668,254.333 669.668,275.667 723.001,309 738.333,362.333 676.334,417.667 649.001,430.334 633.001,419.667 555.668,427 525.001,459.667 351.001,516.666 `)
      .attr('fill', '#fff').style('opacity', 0).style('visibility', 'hidden').style('cursor', 'pointer')
      .call(s => { this.Goto_TS_Division_Lv3(s, 'Mask_TS_Modules', 'MODULES') }) // <--------

      this.Dashboard_Overall
      .append('polygon')
      .attr('id', 'Mask_MW01')
      .attr('transform', `translate(284, 604) scale(1)`).attr('points', `58,0 93,0 135,38.774 135,104 47.69,134 0,107.084 22.338,31.028`)
      .attr('fill', '#fff').style('opacity', 0).style('visibility', 'hidden').style('cursor', 'pointer')
      .call(s => { this.Goto_TS_Activate(s, 'Mask_MW01', 'MW01') }) // <--------

      this.Dashboard_Overall
      .append('polygon')
      .attr('id', 'Mask_MW02')
      .attr('transform', `translate(518, 524) scale(1)`).attr('points', `3.324,28.916 55.437,0 120.226,24.69 123.747,96.521 142,113.422 98.395,133 80.084,118.352 
      44.873,133 0,110.605`)
      .attr('fill', '#fff').style('opacity', 0).style('visibility', 'hidden').style('cursor', 'pointer')
      .call(s => { this.Goto_TS_Activate(s, 'Mask_MW02', 'MW02') }) // <--------

      this.Dashboard_Overall
      .append('polygon')
      .attr('id', 'Mask_MW04')
      .attr('transform', `translate(762, 401) scale(1)`).attr('points', `19.521,75.042 30.084,15.711 68.817,0.088 105,113.422 105,144 52.5,169 0,145.113 0,92`)
      .attr('fill', '#fff').style('opacity', 0).style('visibility', 'hidden').style('cursor', 'pointer')
      .call(s => { this.Goto_TS_Activate(s, 'Mask_MW04', 'MW04') }) // <--------

      this.Dashboard_Overall
      .append('polygon')
      .attr('id', 'Mask_MW05')
      .attr('transform', `translate(969, 360) scale(1)`).attr('points', `44.873,126.943 127,95.113 127,45 111.774,11.31 89.239,0 39.239,17.648 3.324,20.465 0,97.93`)
      .attr('fill', '#fff').style('opacity', 0).style('visibility', 'hidden').style('cursor', 'pointer')
      .call(s => { this.Goto_TS_Activate(s, 'Mask_MW05', 'MW05') }) // <--------

      this.Dashboard_Overall
      .append('polygon')
      .attr('id', 'Mask_ME01')
      .attr('transform', `translate(491, 718) scale(1)`).attr('points', `0,38.753 78.676,-0.011 126,35.958 126,100 50.507,130 0,102.155`)
      .attr('fill', '#fff').style('opacity', 0).style('visibility', 'hidden').style('cursor', 'pointer')
      .call(s => { this.Goto_TS_Activate(s, 'Mask_ME01', 'ME01') }) // <--------

      this.Dashboard_Overall
      .append('polygon')
      .attr('id', 'Mask_ME02')
      .attr('transform', `translate(710, 647) scale(1)`).attr('points', `0,29.845 45.578,0.113 111.774,9.958 127.268,46.549 144,52.859 144,85 46.282,116 0,89.479`)
      .attr('fill', '#fff').style('opacity', 0).style('visibility', 'hidden').style('cursor', 'pointer')
      .call(s => { this.Goto_TS_Activate(s, 'Mask_ME02', 'ME02') }) // <--------

      this.Dashboard_Overall
      .append('polygon')
      .attr('id', 'Mask_ME04')
      .attr('transform', `translate(960, 512) scale(1)`).attr('points', `18.817,64.993 30.084,12.095 68.817,0.041 80.789,13.442 83.605,64.841 109,101.451 109,158 
      77.972,176 78.676,148.634 42.056,157.789 0,133.141 0,91`)
      .attr('fill', '#fff').style('opacity', 0).style('visibility', 'hidden').style('cursor', 'pointer')
      .call(s => { this.Goto_TS_Activate(s, 'Mask_ME04', 'ME04') }) // <--------

      this.Dashboard_Overall
      .append('polygon')
      .attr('id', 'Mask_ME05')
      .attr('transform', `translate(1170, 477) scale(1)`).attr('points', `9.662,19.965 56.5,0.102 113,30.324 113,92 42.761,118 0,88.07`)
      .attr('fill', '#fff').style('opacity', 0).style('visibility', 'hidden').style('cursor', 'pointer')
      .call(s => { this.Goto_TS_Activate(s, 'Mask_ME05', 'ME05') }) // <--------
    },
  }
}