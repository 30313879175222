export default {
  width: 100,
  gab: 25,
  attrs: [
    { 
      text: 'Total',
      refColumn: 'TOTAL',
      fColor: '#BDBCBC', 
      tColor: '#818181'
    },
    { 
      text: 'Plan',
      refColumn: 'PLAN',
      fColor: '#FFDA00',
      tColor: '#FAAA18'
    },
    { 
      text: 'Actual',
      refColumn: 'ACTUAL',
      fColor: '#82B22E', 
      tColor: '#4CAE4E'
    },
    { 
      text: 'Remain',
      refColumn: 'REMAIN',
      fColor: '#F7BACF', 
      tColor: '#FF75A5'
    },
  ]
}
