import * as d3 from 'd3'
export default {
  data: () => ({
    stage: '',
    state: '',
    levelCode: '',
    selectedItems: [],
    cValue:''
  }),
  computed: {
    ready2draw() {

      // console.log('------------------------------')
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)

      return (
        Object.keys(this.Canvas).length > 0
      )
    },
  },
  methods: {
    setDefaultValues() {
      d3.selection.prototype.moveToFront = function() {
        return this.each(function(){
        this.parentNode.appendChild(this)
        })
      }

      //------------------------------- For the drop shadow filter
      let defs = this.svg.append('defs')
      let filter = defs.append('filter')
      .attr('id', 'dropshadow')

      filter.append('feGaussianBlur')
      .attr('in', 'SourceAlpha')
      .attr('stdDeviation', 3)
      .attr('result', 'blur');
      filter.append('feOffset')
      .attr('in', 'blur')
      .attr('dx', 0)
      .attr('dy', 3)
      .attr('result', 'offsetBlur');
      filter.append('feFlood')
      .attr('in', 'offsetBlur')
      .attr('flood-color', '#727272')
      .attr('flood-opacity', '0.5')
      .attr('result', 'offsetColor');
      filter.append('feComposite')
      .attr('in', 'offsetColor')
      .attr('in2', 'offsetBlur')
      .attr('operator', 'in')
      .attr('result', 'offsetBlur');

      var feMerge = filter.append('feMerge');
      feMerge.append('feMergeNode')
      .attr('in', 'offsetBlur')
      feMerge.append('feMergeNode')
      .attr('in', 'SourceGraphic');
      //------------------------------- For the drop shadow filter

      this.svg
      .append('clipPath')
      .attr('id', 'mask_horizontal_img_slide')
      .append('rect')
      .attr('transform', `translate(0, 0)`)
      .attr('width', 572)
      .attr('height', 120)
      .attr('rx', 4)
      .attr('ry', 4)

      this.svg
      .append('clipPath')
      .attr('id', 'mask_overall')
      .append('rect')
      .attr('transform', `translate(0, 0)`)
      .attr('width', 1600)
      .attr('height', 1120)

      this.chartArea
      .attr('clip-path', 'url(#mask_overall)')

      this.chartArea
      .append('clipPath')
      .attr('id', 'mask_toplevel_image')
      .append('rect')
      .attr('transform', `translate(0, 0)`) //처음 기준이 0,0이어야한다 //내가 만드는 이미지의 크기와 동일하게 작업
      .attr('width', 90)
      .attr('height', 90)
      .attr('rx', 3)

      this.chartArea
      .append('clipPath')
      .attr('id', 'mask_infolevel_image')
      .append('rect')
      .attr('transform', `translate(0, 0)`)  
      .attr('width', 238)  
      .attr('height', 210)
      .attr('rx', 5)
    },
    gotoStage(stage) {
      // console.log(stage,"this.stage")
      this.stage = stage

      this.closeFloats()
      this.closeInformation()

      this.loadBackground().then(() => {
        this.loadFloats()
        this.loadInformation()
      })
    }
  }
}