export default {
  watch: {
    // Canvas: { handler(val) { if(!val || Object.keys(val).length === 0) return; this.register(this.draw); }, deep: true },
    // Text: { handler(val) { if(!val || Object.keys(val).length === 0) return; this.register(this.draw); }, deep: true },
    // Queries: { handler(val) { if(!val || Object.keys(val).length === 0) return; this.register(this.draw); }, deep: true },
    // DataItems: { handler(val) { if(!val || val.length === 0) return; this.register(this.draw); }, deep: true },

    selectedItems() {
      this.$emit('selectedItems', this.selectedItems)
    },
    FilterValues: {
      handler() {
        if (!this.svg) {
          setTimeout(() => {
            this.register(this.draw)
          })
        }else {
          // this.checkFV()
        }
      },
      deep: true
    },

    // callback will be called from the popup-free modal (EqFilteredList)
    callback: {
      handler(d) {
        if(!d) return

        let to = {
          level    : 3,
          lname    : 'equipment',
          package  : d.LV2,
          area     : d.LV3,
          equipment: d.AREA_UNIT,
        }

        if(!d.AREA_UNIT) return

        if(JSON.stringify(to) != JSON.stringify(this.route.to)) this.routeTo(to, this.route.to)

        // declared in the page_qeuipment.mixin //
        this.reqIndicator = d
        this.indicatorMoveTo()
        // declared in the Declare.mixin //
        this.callEqPopup(d, ['equipment-search', 'share'])
      },
      deep: true
    },
  },
}
