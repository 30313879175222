import * as d3 from 'd3'
// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  data:() => ({
    thead: [
      { key: 'PERIOD', html: 'Period / Status', row: 1, rowspan: 2 },
      { key: 'STATUS', html: 'Status', row:1, colspan: 5 },
      // { key: 'CANCELLED_RATE', html: 'Rate Cancelled', row:1, rowspan: 2 },

      { key: 'PLANNED_WK', html: 'Planned', row: 2 },
      { key: 'ACT_WK', html: 'Actual', row: 2 },
      { key: 'NOTE1_WK', html: 'Note 1', row: 2 },
      { key: 'NOTE2_WK', html: 'Note 2', row: 2 },
      { key: 'NOTE3_WK', html: 'Note 3', row: 2 },
    ],
    tbody: [

      { key: 'PERIOD', w: 100, align: 'center'},
      { key: 'PLANNED_WK', w: 95,align: 'center', type: 'number'},
      { key: 'ACT_WK', w: 95, align: 'center', type: 'number'},
      { key: 'NOTE1_WK', w: 95, align: 'center', type: 'number'},
      { key: 'NOTE2_WK', w: 95, align: 'center'}, 
      { key: 'NOTE3_WK', w: 95, align: 'center'}, 
      // { key: 'CANCELLED_RATE', w: 80, align: 'center'}, 
    ],
    
  }),
  methods: {
    Draw_Charts() {
      let svg = this.svg

      this.filteredList = this.DataItems

      let list = this.filteredList
      // let firstColumName = [...new Set(this.Queries.SQL1.map(d => d.ORIGINATING_COMPANY))]
      // console.log(firstColumName)

      let group = svg.append('g').attr('class', 'table_group__').attr('transform', `translate(0.5, 0.5)`).style('font-family', 'roboto')
      let table_group = group.append('g').attr('transform', `translate(0.5, 0.5)`)
      
      let y_ = this.DataItems.length * 26 + 40

      let table_obj = table_group.append('foreignObject')
        .attr('y',0)
        .attr('width', '100%')
        .attr('height', '100%')
        //.attr('height', `${y_}px`)
        .append('xhtml:body')
        .style('position', 'fixed')

      const reducer = (previousValue, currentValue) => previousValue + currentValue
      let widths = this.tbody.map(d => d.w)
      let sumWidth = widths.reduce(reducer)

      let table = table_obj.append('table')
      .style('border-collapse', 'collapse')
      .style('font-size', '11px')
      .style('width', `${sumWidth}px`)
      .style('table-layout', 'fixed')

      let colgroup = table.append('colgroup')
      this.tbody.forEach(k => {
        colgroup.append('col').style('width', k.w + 'px')
      })
      let maxRow = Math.max(...this.thead.map(d => d.row))
  
      let thead = table.append('thead')
      for (let r = 1; r <= maxRow; r++) {
        let row = this.thead.filter(d=> d.row === r)
        let tr = thead.append('tr').style('background', '#F5F5F5')

        row.forEach(item => {
          tr.append('th')
          .attr('class', 'completion_dashboard_table_group___th')
          .attr('rowspan', () => item.rowspan ? item.rowspan : '')
          .attr('colspan', () => item.colspan ? item.colspan : '')
          .style('background', item.color ? item.color : '#607D8B')
          .style('border', 'solid 0.7px #eee')
          .style('padding', '3px')
          .style('font-weight','bold')
          .style('font-size', '11px').style('color', '#ffffff')
          .html(item.html)
        })        
      }

      let tbodykeys = this.tbody
      
      let tbody = table.append('tbody').attr('id', 'summay_tbody_')
      if (list.length == 0) {
        
        let tr = tbody.append('tr')
        tr.append('td')
          .attr('colspan', 6)
          .style('background', '#F5F5F5')
          .style('height', '24px')
          .style('text-align', 'center')
          .style('color', '#424242')
          .style('padding', '0 3px 0 3px')
          .style('font-style', 'italic')
          .html('No data')
        
        return
      }
      for (let r = 0; r < list.length; r++) {
        let regexp = /\B(?=(\d{3})+(?!\d))/g
        let tr = tbody.append('tr')
        tr.on('mouseover', () => tr.style('background-color', '#FAFAFA' ))
        tr.on('mouseout', () => tr.style('background-color', 'Transparent' ))
        tbodykeys.forEach((k, i) => {
          let td = tr.append('td')
            .style('border', 'solid 0.1px #E4E4E4')
            .style('height', '24px')
            .style('text-align', k.align ? k.align : 'center')
            .style('color', '#000000')
            .style('padding', '0 3px 0 3px')
            .style('background', r%2!=0 ? '#ECEFF1':'#fff' )
            td.html(k.type && k.type == 'number' && list[r][k.key] ? list[r][k.key].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : list[r][k.key])

            // .style('cursor', () => {
            //   if (i == 0) return
            //   if (list[r][k.key] == 0) return
            //   return 'pointer'
            // })
            // .on('click', () => {
            //   if (i == 0) return
            //   if (list[r][k.key] == 0) return
              
            //   let filterArr = []

            //   // let filterString = list[r]['DISC'] != 'Total' ? ` DISC = '${list[r]['DISC']}' ` : ``

              
            //   // if (list[r]['ORIGINATING_COMPANY'] != 'Total') filterArr.push(` ORIGINATING_COMPANY = '${list[r]['ORIGINATING_COMPANY']}' `)
            //   // if (k.key == 'RESPONDED') filterArr.push(` [CAL_REVIEW_STATUS_CODE] = 'RESPONDED' `)
            //   // if (k.key == 'OPEN') filterArr.push(` [CAL_REVIEW_STATUS_CODE] = 'OPEN' `)
            //   // if (k.key == 'CANCELLED') filterArr.push( ` [CAL_REVIEW_STATUS_CODE] = 'CANCELLED' `)

            //   // let request_ = {
            //   //   dataType: 'row',
            //   //   action: {
            //   //     type: 'direct',
            //   //     target: 'slide-modal',
            //   //     component: 'ServiceDatatable',
            //   //     id: 345,
            //   //     no: ''
            //   //   },
            //   //   filters: { 
            //   //     ...this.FilterValues
            //   //   },
            //   //   iFilters: {
            //   //     filterString : filterArr.join(' AND '),
            //   //     inputFilter  : ''
            //   //   }
            //   // }
            //   // this.$emit('request-action', request_)
            // })
            // if (r == list.length -1 ) {
            //   td.style('font-weight', 'bold')
            //   .style('border', 'solid 2px #E4E4E4')
            // }
        })
      }
      let table_under = group.append('g').attr('transform', `translate(0.5, 110.5)`)
      table_under
      .append('text')
      .attr('transform', `translate(${1}, ${0})`)
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle').attr('font-size', 12).attr('fill', '#424242')
      .text('Note 1 : Acceptable = No Action')

      table_under
      .append('text')
      .attr('transform', `translate(${1}, ${18})`)
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle').attr('font-size', 12).attr('fill', '#424242')
      .text('Note 2 : Acceptable with Minor Comment = Improvements can be made = Monitor')

      table_under
      .append('text')
      .attr('transform', `translate(${1}, ${36})`)
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle').attr('font-size', 12).attr('fill', '#424242')
      .text('Note 3 : Concern Major Issue = Action immediately = Increase Inspection or Verify concern /issue has been actioned')

    },
  }
}
