import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({

    props:[
      {key:'SEVERITY_DESCR', head1: 'Actual Severity', x: 60, tx: 30, cy: 0, textanchor: 'start'},
      {key:'ACTUAL', head1: '', x: 150, tx: 220, cy: 0,},
      {key:'POTENTIAL', head1: '', x: 180, tx: 255, cy: 0},
    ],
    width: 230,
    rowHeight: 18
    //Actual Potential
  }),
  

  methods: {
    drawChart() {
      let PunchTable = this.svg
      .append('g')
      .attr('class','_tableWrap').style('font-family', 'roboto')
      .attr('transform', `translate(0.5, 0.5)`)

      PunchTable
      .append('text')
      .attr('transform', `translate(230, 0)`)
      .style('font-size', 12)
      .style('fill', '#000')
      .attr('text-anchor', 'middle')
      .text('China-1 Project Safety - PTD')

      PunchTable
      .append('text')
      .attr('transform', `translate(212, 282)`)
      .style('font-size', 11)
      .style('fill', '#000')
      .attr('text-anchor', 'middle')
      .text('Actual')

      PunchTable
      .append('text')
      .attr('transform', `translate(270, 282)`)
      .style('font-size', 11)
      .style('fill', '#000')
      .attr('text-anchor', 'middle')
      .text('Potential')

      PunchTable
      .append('image')
      .attr('id', `image_wrap`)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/China1/Images/ptd.jpg`)
      .attr('transform', `translate(90,30) scale(0.8)`)

      // PunchTable
      // .append('rect')
      // .attr('x',20).attr('y', 5).attr('width',this.width - 20).attr('height',25).attr('stroke-width', 0).attr('fill', '#F4F5F5').attr('opacity', 0.4)

      // PunchTable.append('line').attr('x1',20).attr('y1',5).attr('x2',this.width).attr('y2',5).attr('stroke-width', 0.2).attr('stroke', '#000')
      // PunchTable.append('line').attr('x1',20).attr('y1',30).attr('x2',this.width).attr('y2',30).attr('stroke-width', 0.2).attr('stroke', '#000')

      // header
      PunchTable
      .append('g')
      .attr('transform', `translate(0, 20)`)
      .selectAll('text')
      .data(this.props)
      .enter()
      .append('text')
      .attr('x', d => d.x)
      .attr('y', 2)
      .style('font-size',12)
      .attr('text-anchor', 'middle')
      .attr('fill', '#757575')
      .text(d => d.head1)

      let row_group = PunchTable
      .append('g')
      .attr('class', '_row_data_group')
      .attr('transform', `translate(0, 48)`)

      // let line_group = PunchTable
      // .append('g')
      // .attr('class', '_row_line_group')
      // .attr('transform', `translate(0, 50)`)

      let sum = 0;
      
      this.DataItems.forEach((row, r) => {
        if(row.SEVERITY !='L5'){
          let keys = Object.keys(row)
          let sumOpen = row.OPEN
          sum += sumOpen
          
          keys.forEach(key => {
            // console.log(key)
            // console.log(row[key])
            
            let idx = this.props.findIndex(e => e.key === key)
            if(idx == -1) return
            // console.log(this.props[idx])
            row_group
            .append('text')
            .text(idx != 2 ? row[key] : row[key])
            // .attr('x', this.props[idx].tx )
            .attr('x', row[key]== 'ACTUAL' ? 236: this.props[idx].tx )
            .attr('y', r * 40)
            .attr('text-anchor', this.props[idx].textanchor ? this.props[idx].textanchor : 'middle' )
            .style('font-size',11)
            .style('font-weight', row.SIZE === 'Total' ? 'bold' : 'normal')
            .attr('fill', this.props[idx].color && row[key] !== 0 ? this.props[idx].color : '#000' )
            // .attr('fill', row.SEVERITY == 'L5' ? '#fff': '#000' )

            // line_group
            // .append('line')
            // .attr('x1',20).attr('y1', r * 20)
            // .attr('x2', this.width).attr('y2', r * 20)
            // .attr('stroke-width', 0.2).attr('stroke', r == 5 ? '#757575' : '#E0E0E0')
          })
        }
      })
      PunchTable
      .append('text')
      .attr('id', 'L5')
      .attr('transform', `translate(60, 52)`)
      .style('font-size', 11)
      .style('fill', '#000')
      .attr('text-anchor', 'middle')
      .text('L5 - Multiple')
      
      PunchTable
      .append('text')
      .attr('id', 'L5Act')
      .attr('transform', `translate(237, 52)`)
      .style('font-size', 11)
      .style('fill', '#fff')
      .attr('text-anchor', 'middle')
      .text(this.Queries.SQL1[0].ACTUAL)
      
      // let y_ = this.DataItems.length * 20
      // let idx = this.props.findIndex(e => e.key === 'DISC_DESCR')
      
      // let keys = Object.keys(this.Queries.SQL1[0])
      // keys.forEach(key => {
      //   idx = this.props.findIndex(e => e.key === key)
      //   row_group
      //   .append('text')
      //   .text(this.Queries.SQL1[0][key])
      //   .attr('x', this.props[idx].tx)
      //   .attr('y', y_)
      //   .attr('text-anchor', this.props[idx].textanchor ? this.props[idx].textanchor : 'middle')
      //   .style('font-family','roboto')
      //   .style('font-size',11)
      //   .attr('fill', '#000')
      // })
    }
  }
}
