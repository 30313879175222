export default {
  width: 160,
  gab: 50,
  attrs: [
    { 
      text1: 'A-ITR',
      text2: '',
      refColumn: 'AITR',
      codeValue   : 'Total', // fixed
      filterColumn: 'TOTAL',
      fill : '#fff', 
      fColor: '#69BAE5', 
      tColor: '#69BAE5',
      on: true
    },
    { 
      text1: 'MC',
      text2: '',
      refColumn: 'MC',
      codeValue   : 'Belong to MC not completed system',
      filterColumn: 'SUBSYSTE_STATUS',
      fill : '#fff', 
      fColor: '#69BAE5', 
      tColor: '#69BAE5',
      on: true
    },
    { 
      text1: 'B-ITR',
      text2: '',
      refColumn: 'BTIR',
      codeValue   : 'Belong to MC completed system',
      filterColumn: 'SUBSYSTE_STATUS',
      fill : '#fff', 
      fColor: '#69BAE5',
      tColor: '#69BAE5',
      on: true
    },
    { 
      text1: 'CTP/SAT',
      refColumn: 'CTPSAT',
      codeValue   : 'B ITR Completed',
      filterColumn: 'SUBSYSTE_STATUS',
      fill : '#fff', 
      fColor: '#69BAE5', 
      tColor: '#69BAE5',
      on: true
    }
  ]
}
