import * as d3 from 'd3'

export default {
  data: () => ({
    Milestone: {
      MilestoneDisplay    : 'Y',           // Y, N
      MilestoneLineStyle  : 0,             // length of dash (0, 1, 2, 3, ... : 0 = solid), 
      MilestoneLineWeight : 0.5,
      MilestoneLineHeight : 280,
      MilestoneLineColor  : '#ff8a80',
  
      MilestoneTextFont   : 'roboto',
      MilestoneTextStyle  : 'regular',
      MilestoneTextSize   : 9,
      MilestoneTextColor  : '#ff8a80',
      
      MilestoneQApplied   : '',   // json, sql, sp
      MilestoneQJson      : '',
      MilestoneQSql       : '',
      MilestoneQSp        : '',
    },

    isMilestoneDataValid: true,
    milestoneArea: null,
    milestoneAreaX: null,
    milestoneAreaY: null,
    milestoneData: [],
    milestoneLineWeight: null,
    milestoneLineHeight: null,
    milestoneXCPoint: 10,
    scaleMilestone: Function,
    
    dataSet: [],
  }),
  computed: {
    ready2draw() {

      // console.log('------------------------------')
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)

      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },
  methods: {
    setDefaultValues(){
    
        this.dataSet = JSON.parse(JSON.stringify(this.DataItems))
        
        this.dataSet.forEach(data => {
         //
        })
  
    },

    setMilestoneValues(mValues) {
      this.milestoneAreaX = mValues.x
      this.milestoneAreaY = mValues.y
      this.milestoneAreaWeight = mValues.w
      this.milestoneLineHeight = mValues.h
      this.milestoneXCPoint = mValues.c || 0
      this.scaleMilestone = mValues.scaleX

      this.isMilestoneDataValid = true
      if(this.Queries.SQL1==undefined) return
      this.milestoneData = this.Queries.SQL1.map(d => ({ CDATE: d.WK_NO, desc: d.DESC }))
      
      
      
    },
  }
}




