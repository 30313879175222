import * as d3 from 'd3'

export default {
  data: () => ({

  }),

  methods: {

    draw_SkylineChart() {

      let self = this
      let BoxesChart = this.SkylineCharts
      .append('g')
      .attr('transform', `translate(${this.timeline.baseX},0)`)

     
      d3.selection.prototype.moveToFront = function() {
        return this.each(function(){
        this.parentNode.appendChild(this);
        });
      }
      // console.log(this.timeline.week)

      this.timeline.week.forEach( (w,i) => {

        let drawY = this.timeline.baseY - this.skyline.box.height - (this.skyline.box.gap*2)

        this.dataSet.filter(f => f.cDate == w.eDate).forEach(d => {

          let x1 = this.timeline.scale(d.sDate) + this.skyline.box.gap
          let y1 = drawY

          let Boxes = BoxesChart
          .append('g')
          .attr('id', `SYS_${d.SYSTEM}`)
          .attr('transform', `translate(${x1},${y1})`)
          .style('cursor', 'pointer')
          .on('mouseover', function() {
            d3.select(this).moveToFront().transition().duration(100)
            .attr('transform', `translate(${x1-(self.skyline.box.width/4)},${y1-(self.skyline.box.height/4)}) scale(1.5)`)
          })
          .on('mouseout', function(){
            d3.select(this).transition().duration(100)
            .attr('transform', `translate(${x1},${y1}) scale(1)`)
          })
          .on('click', () => { 
            this.draw_SystemBoundary(d.SYSTEM, d.SYS_DESCR)
          })

          Boxes
          .append('rect') // the background color should be white(#fff)
          .attr('rx', d.radius)
          .attr('ry', d.radius)
          .attr('width', this.skyline.box.width)
          .attr('height', this.skyline.box.height)
          .attr('fill', '#fff')
          .attr('stroke', 'Transparent')
          .attr('stroke-width', d.sWidth)

          Boxes
          .append('rect') // Fill Color
          .attr('rx', d.radius)
          .attr('ry', d.radius)
          .attr('width', () => {100
            if (d.progress == 'Y') return this.skyline.box.width * (d.B_P/100); else return this.skyline.box.width
          })
          .attr('height', this.skyline.box.height)
          .attr('fill', d.bColor)
          .attr('stroke', 'Transparent')
          .attr('stroke-width', d.sWidth)

          Boxes
          .append('rect') // Stroke
          .attr('rx', d.radius)
          .attr('ry', d.radius)
          .attr('width', this.skyline.box.width)
          .attr('height', this.skyline.box.height)
          .attr('fill', 'Transparent')
          .attr('stroke', d.sColor)
          .attr('stroke-width', d.sWidth)

          Boxes
          .append('text')
          .attr('x', this.timeline.weekSize/2 - 1)
          .attr('y', this.skyline.box.height/2 + 1)
          .attr('font-family', this.skyline.box.font)
          .attr('font-size', d.tSize)
          .attr('fill', d.tColor)
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          .text(d.SYSTEM)

          drawY = drawY - this.skyline.box.height - (this.skyline.box.gap*2)
        })
      })
    }, 


    Chart_filtering(_style, _item, _col, _value) {

      if (this.style[_style].item[_item].onOff == false) {
        d3.select(`#Legend_${_style}_${_item}`).style('opacity', 0.3)
        this.style[_style].item[_item].onOff = true
        this.filtering.push({column: _col, value: _value})
      } else {
        d3.select(`#Legend_${_style}_${_item}`).style('opacity', 1)
        this.style[_style].item[_item].onOff = false

        let idx = this.filtering.findIndex(f => f.column == _col && f.value == _value)
        this.filtering[idx] = null
        this.filtering = this.filtering.filter(f_ => !!f_)
      }

      // Reset
      this.dataSet.forEach(d => { d.display = 'on' })

      this.filtering.forEach(f => {
        this.dataSet.filter(d => d[f.column] == f.value && d[f.column] == f.value).forEach(data => { data.display = 'off' })
      })
      
      // Display control
      this.dataSet.forEach(d => { 
        if (d.display == 'on') {
          d3.select(`#SYS_${d.SYSTEM}`).transition().duration(500).style('opacity', 1)
        } else {
          d3.select(`#SYS_${d.SYSTEM}`).transition().duration(500).style('opacity', 0)
        }
       })

    },
  }
}
