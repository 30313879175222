import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {

    Draw_Charts() {
      
      let data = null, 
          style = null

      let systemsummary = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0.5)`)

      // Header_01
      data  = this.Queries.SQL1[0]
      style = {
        title: 'A ITR Progress',
        x: 10,
        y: 50,
        width: 100,
        height: 25,
        radius: 3,
        tSize: 12,
        tColor: '#333',
        bColor: '#E0E0DF',
        progSize: 15,
      }
      this.Header_01( systemsummary, data.PROG, style) // ---------------------------> 

      let summary = systemsummary
      .append('g')
      .attr('transform', 'translate(10, 80)')


      summary.append('rect').attr('transform', `translate(0, 0)`)
      .style('width', 240).style('height', 65).style('fill', '#83D2F5').attr('rx', 3).attr('ry', 3)

      // Titles
      summary.append('text')
      .attr('transform', 'translate(35, 13)')
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').style('text-anchor', 'end')
      .text('Type')

      summary.append('text')
      .attr('transform', 'translate(75, 13)')
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').style('text-anchor', 'end')
      .text('Total')
      
      summary.append('text')
      .attr('transform', 'translate(135, 13)')
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').style('text-anchor', 'end')
      .text('Completed')

      summary.append('text')
      .attr('transform', 'translate(185, 13)')
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').style('text-anchor', 'end')
      .text('Signed')

      summary.append('text')
      .attr('transform', 'translate(230, 13)')
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').style('text-anchor', 'end')
      .text('Remain')


      summary.append('text')
      .attr('transform', 'translate(35, 30)')
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').style('text-anchor', 'end')
      .text('QCF')

      summary.append('text')
      .attr('transform', 'translate(35, 45)')
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').style('text-anchor', 'end')
      .text('Vendor')

      summary.append('text')
      .attr('transform', 'translate(35, 60)')
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').style('text-anchor', 'end')
      .text('A ITR')

      
      //QCF Data
      let qcf = this.Queries.SQL3[0]
      if (qcf) {
        summary.append('text')
        .attr('transform', 'translate(75, 30)')
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        .text(qcf.TOTAL)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100).style('fill', '#fff')
        })
        .on('click', () => {
          let acsData = {CATEGORY : 'QCF', SUBSYSTEM: this.FilterValues.SUBSYSTEM}
          let request_ = this.getRequestColumnProps('qcfStatus', acsData, this.ColumnProps)
          this.$emit('request-action', request_)
        })

        summary.append('text')
        .attr('transform', 'translate(135, 30)')
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        .text(qcf.ACTUAL)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100).style('fill', '#fff')
        })
        .on('click', () => {
          let acsData = {CATEGORY : 'QCF', SUBSYSTEM: this.FilterValues.SUBSYSTEM, STATUS_CODE:'C'}
          let request_ = this.getRequestColumnProps('qcfStatus', acsData, this.ColumnProps)
          this.$emit('request-action', request_)
        })

        summary.append('text')
        .attr('transform', 'translate(185, 30)')
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        .text(qcf.SIGNED)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100).style('fill', '#fff')
        })
        .on('click', () => {
          // let acsData = {CATEGORY : 'QCF', SUBSYSTEM: this.FilterValues.SUBSYSTEM, STATUS_CODE:'C'}
          // let request_ = this.getRequestColumnProps('qcfStatus', acsData, this.ColumnProps)
          
          let request_ = {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: 255,
              no: ''
            },
            filters     : {},
            iFilters    : {
              filterString : ` CATEGORY = 'QCF' AND SUBSYSTEM = '${this.FilterValues.SUBSYSTEM}' AND STATUS_CODE = 'C' AND [CLIENT_SIGN_DATE] IS NOT NULL  `,
              inputFilter  : ''
            }
          }
          this.$emit('request-action', request_)
        })

        summary.append('text')
        .attr('transform', 'translate(230, 30)')
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        .text(qcf.REMAIN)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100).style('fill', '#fff')
        })
        .on('click', () => {
          let acsData = {CATEGORY : 'QCF', SUBSYSTEM: this.FilterValues.SUBSYSTEM, STATUS_CODE:'N'}
          let request_ = this.getRequestColumnProps('qcfStatus', acsData, this.ColumnProps)
          this.$emit('request-action', request_)
        })
      }

      let vendor = this.Queries.SQL4[0]

      if (vendor) {
        summary.append('text')
        .attr('transform', 'translate(75, 45)')
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        .text(vendor.TOTAL)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100).style('fill', '#fff')
        })
        .on('click', () => {
          if (vendor.TOTAL == 0) return
          let acsData = {CATEGORY : 'V', SUBSYSTEM: this.FilterValues.SUBSYSTEM}
          let request_ = this.getRequestColumnProps('ascStatus', acsData, this.ColumnProps)
          this.$emit('request-action', request_)
        })
  
        summary.append('text')
        .attr('transform', 'translate(135, 45)')
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        .text(vendor.ACTUAL)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100).style('fill', '#fff')
        })
        .on('click', () => {
          if (vendor.ACTUAL == 0) return
          let acsData = {CATEGORY : 'V', SUBSYSTEM: this.FilterValues.SUBSYSTEM, STATUS_CODE:'C'}
          let request_ = this.getRequestColumnProps('ascStatus', acsData, this.ColumnProps)
          this.$emit('request-action', request_)
        })

        summary.append('text')
        .attr('transform', 'translate(185, 45)')
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        .text(vendor.SIGNED)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100).style('fill', '#fff')
        })
        .on('click', () => {
          // let acsData = {CATEGORY : 'A', SUBSYSTEM: this.FilterValues.SUBSYSTEM, STATUS_CODE:''}
          // let request_ = this.getRequestColumnProps('ascStatus', acsData, this.ColumnProps)

          if (vendor.SIGNED == 0) return
          
          let request_ = {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: 255,
              no: ''
            },
            filters     : {},
            iFilters    : {
              filterString : ` CATEGORY = 'V' AND SUBSYSTEM = '${this.FilterValues.SUBSYSTEM}' AND STATUS_CODE = 'C' AND [CLIENT_SIGN_DATE] IS NOT NULL  `,
              inputFilter  : ''
            }
          }
          this.$emit('request-action', request_)
        })
  
        summary.append('text')
        .attr('transform', 'translate(230, 45)')
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        .text(vendor.REMAIN)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100).style('fill', '#fff')
        })
        .on('click', () => {
          if (vendor.REMAIN == 0) return
          let acsData = {CATEGORY : 'V', SUBSYSTEM: this.FilterValues.SUBSYSTEM, STATUS_CODE:'N'}
          // 이사님이 Done 아닌것은 Not Done 넣어주시기로 하셨음 2021-07-23
          // STATUS: 'Not Done' -> STATUS_CODE: 'N', STATUS: 'Done' -> STATUS_CODE: 'C' 변경 2021-08-10

          let request_ = this.getRequestColumnProps('ascStatus', acsData, this.ColumnProps)
          this.$emit('request-action', request_)
        })

      }


      // itr data Summary Table Not Used Common Chart
      let itr = this.Queries.SQL1[0]

      if (itr) {
        summary.append('text')
        .attr('transform', 'translate(75, 60)')
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        .text(itr.TOTAL)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100).style('fill', '#fff')
        })
        .on('click', () => {
          if (itr.TOTAL == 0) return
          let acsData = {CATEGORY : 'A', SUBSYSTEM: this.FilterValues.SUBSYSTEM}
          let request_ = this.getRequestColumnProps('ascStatus', acsData, this.ColumnProps)
          this.$emit('request-action', request_)
        })
  
        summary.append('text')
        .attr('transform', 'translate(135, 60)')
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        .text(itr.ACTUAL)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100).style('fill', '#fff')
        })
        .on('click', () => {
          if (itr.ACTUAL == 0) return
          let acsData = {CATEGORY : 'A', SUBSYSTEM: this.FilterValues.SUBSYSTEM, STATUS_CODE:'C'}
          let request_ = this.getRequestColumnProps('ascStatus', acsData, this.ColumnProps)
          this.$emit('request-action', request_)
        })

        summary.append('text')
        .attr('transform', 'translate(185, 60)')
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        .text(itr.SIGNED)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100).style('fill', '#fff')
        })
        .on('click', () => {
          // let acsData = {CATEGORY : 'A', SUBSYSTEM: this.FilterValues.SUBSYSTEM, STATUS_CODE:''}
          // let request_ = this.getRequestColumnProps('ascStatus', acsData, this.ColumnProps)

          if (itr.SIGNED == 0) return
          
          let request_ = {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: 255,
              no: ''
            },
            filters     : {},
            iFilters    : {
              filterString : ` CATEGORY = 'A' AND SUBSYSTEM = '${this.FilterValues.SUBSYSTEM}' AND STATUS_CODE = 'C' AND [CLIENT_SIGN_DATE] IS NOT NULL  `,
              inputFilter  : ''
            }
          }
          this.$emit('request-action', request_)
        })
  
        summary.append('text')
        .attr('transform', 'translate(230, 60)')
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        .text(itr.REMAIN)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100).style('fill', '#fff')
        })
        .on('click', () => {
          if (itr.REMAIN == 0) return
          let acsData = {CATEGORY : 'A', SUBSYSTEM: this.FilterValues.SUBSYSTEM, STATUS_CODE:'N'}
          // 이사님이 Done 아닌것은 Not Done 넣어주시기로 하셨음 2021-07-23
          // STATUS: 'Not Done' -> STATUS_CODE: 'N', STATUS: 'Done' -> STATUS_CODE: 'C' 변경 2021-08-10

          let request_ = this.getRequestColumnProps('ascStatus', acsData, this.ColumnProps)
          this.$emit('request-action', request_)
        })
      }
    
      // Punch Summary Table
      let punch_summary = systemsummary
      .append('g')
      .attr('transform', 'translate(10, 155)')

      punch_summary.append('rect').attr('transform', `translate(0, 0)`)
      .style('width', 210).style('height', 55).style('fill', '#A8E0F8').attr('rx', 3).attr('ry', 3)

      // Titles
      punch_summary.append('text')
      .attr('transform', 'translate(35, 13)')
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').style('text-anchor', 'end')
      .text('Punch')

      punch_summary.append('text')
      .attr('transform', 'translate(90, 13)')
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').style('text-anchor', 'end')
      .text('Raised')
      
      
      punch_summary.append('text')
      .attr('transform', 'translate(140, 13)')
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').style('text-anchor', 'end')
      .text('Closed')

      punch_summary.append('text')
      .attr('transform', 'translate(190, 13)')
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').style('text-anchor', 'end')
      .text('Open')


      punch_summary.append('text')
      .attr('transform', 'translate(35, 30)')
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').style('text-anchor', 'end')
      .text('A')

      punch_summary.append('text')
      .attr('transform', 'translate(35, 45)')
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').style('text-anchor', 'end')
      .text('B1')

      // console.log(this.Queries.SQL2)

      
      // punch data Summary Table Not Used Common Chart
      let punch_a = this.Queries.SQL2 ? this.Queries.SQL2.find(d => d.CAT == 'A') : null
      if (punch_a) {
        punch_summary.append('text')
        .attr('transform', 'translate(90, 30)')
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        .text(punch_a.TOTAL)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100).style('fill', '#fff')
        })
        .on('click', () => {          
          let punchData = {CATEGORY : punch_a.CAT, SUBSYSTEM: this.FilterValues.SUBSYSTEM}
          let request_ = this.getRequestColumnProps('punchStatus', punchData, this.ColumnProps)
          this.$emit('request-action', request_)
        })

        punch_summary.append('text')
        .attr('transform', 'translate(140, 30)')
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        .text(punch_a.ACTUAL)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100).style('fill', '#fff')
        })
        .on('click', () => {
          let punchData = {CATEGORY : punch_a.CAT, SUBSYSTEM: this.FilterValues.SUBSYSTEM, STATUS_CODE:'C'}
          let request_ = this.getRequestColumnProps('punchStatus', punchData, this.ColumnProps)
          this.$emit('request-action', request_)
        })

        punch_summary.append('text')
        .attr('transform', 'translate(190, 30)')
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        .text(punch_a.REMAIN)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100).style('fill', '#fff')
        })
        .on('click', () => {          
          let punchData = {CATEGORY : punch_a.CAT, SUBSYSTEM: this.FilterValues.SUBSYSTEM, STATUS_CODE:'N'}
          let request_ = this.getRequestColumnProps('punchStatus', punchData, this.ColumnProps)
          this.$emit('request-action', request_)
        })
      }



      // punch data Summary Table Not Used Common Chart
      let punch_b = this.Queries.SQL2 ? this.Queries.SQL2.find(d => d.CAT == 'B1') : null

      if (punch_b) {
        punch_summary.append('text')
        .attr('transform', 'translate(90, 45)')
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        .text(punch_b.TOTAL)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100).style('fill', '#fff')
        })
        .on('click', () => {
          let acsData = {CATEGORY : punch_b.CAT, SUBSYSTEM: this.FilterValues.SUBSYSTEM}
          let request_ = this.getRequestColumnProps('punchStatus', acsData, this.ColumnProps)
          this.$emit('request-action', request_)
        })
  
        punch_summary.append('text')
        .attr('transform', 'translate(140, 45)')
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        .text(punch_b.ACTUAL)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100).style('fill', '#fff')
        })
        .on('click', () => {
          let acsData = {CATEGORY : punch_b.CAT, SUBSYSTEM: this.FilterValues.SUBSYSTEM, STATUS_CODE:'C'}
          let request_ = this.getRequestColumnProps('punchStatus', acsData, this.ColumnProps)
          this.$emit('request-action', request_)
        })
  
        punch_summary.append('text')
        .attr('transform', 'translate(190, 45)')
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        .text(punch_b.REMAIN)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100).style('fill', '#fff')
        })
        .on('click', () => {
          let acsData = {CATEGORY : punch_b.CAT, SUBSYSTEM: this.FilterValues.SUBSYSTEM, STATUS_CODE:'N'}
          // 이사님이 Done 아닌것은 Not Done 넣어주시기로 하셨음 2021-07-23
          // STATUS: 'Not Done' -> STATUS_CODE: 'N', STATUS: 'Done' -> STATUS_CODE: 'C' 변경 2021-08-10

          let request_ = this.getRequestColumnProps('punchStatus', acsData, this.ColumnProps)
          this.$emit('request-action', request_)
        })
      }



      // Draw Text Data
      let systemInfo = this.svg
      .append('g')
      .attr('transform', `translate(230.5, 20.5)`)

      let drawY = 0
      this.dataSet.forEach((d,i) => {
        if(i > 9) return
        systemInfo // CAT
        .append('text')
        .attr('transform', `translate(${150}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 11)
        .style('fill', '#757575').style('text-anchor', 'end').style('alignment-baseline', 'middle').text(d.title)

        systemInfo // CAT
        .append('text')
        .attr('transform', `translate(${160}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 11)
        .style('fill', '#333').style('text-anchor', 'start').style('alignment-baseline', 'middle').text(d.value)

        if (i==1 || i ==3 || i ==5) drawY += 24; else drawY += 16
      })

      
      let systemInfoDate = this.svg
      .append('g')
      .attr('transform', `translate(460.5, 20.5)`)
      drawY = 100
      this.dataSet.forEach((d,i) => {
        if(i == 10 || i == 11 || i == 13 || i == 15) {
        systemInfoDate
        .append('text')
        .attr('transform', `translate(${150}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 11)
        .style('fill', '#757575').style('text-anchor', 'end').style('alignment-baseline', 'middle')
        .text(i == 15 ? '' :d.title)

        systemInfoDate
        .append('text')
        .attr('transform', `translate(${180}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 10)
        .style('fill', '#bcbcbc').style('text-anchor', 'end').style('alignment-baseline', 'middle')
        .text(i == 10 ? 'Issue' : i == 15 ? 'Actual' : 'Plan')

        if(i != 10) {
          systemInfoDate
          .append('rect')
          .attr('transform', `translate(315, ${drawY-9})`)
          .style('width', 65).style('height', 15).style('fill', '#E0E0DF').attr('rx', 0).attr('ry', 0).style('opacity', 0.3)
        }

        
        systemInfoDate
        .append('text')
        .attr('transform', `translate(${310}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 10)
        .style('fill', '#bcbcbc').style('text-anchor', 'end').style('alignment-baseline', 'middle')
        .text(() => { if(i == 10) return 'Forecast'; else return 'Actual'})
        .text( i == 10 ? '': i == 13 ? 'Forecast' : i == 15 ? 'Approved':'Actual')
        
        systemInfoDate
        .append('rect')
        .attr('transform', `translate(185, ${drawY-9})`)
        .style('width', 65).style('height', 16).style('fill', '#E0E0DF').attr('rx', 0).attr('ry', 0).style('opacity', 0.3)


        systemInfoDate 
        .append('text')
        .attr('transform', `translate(${190}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 11)
        .style('fill', '#44A9DF').style('text-anchor', 'start').style('alignment-baseline', 'middle').text(d.value)

        if(i == 11 || i == 13 || i == 15) {
          if ( !this.dataSet[i+1] ) return
          systemInfoDate
          .append('text')
          .attr('transform', `translate(${320}, ${drawY})`)
          .style('font-family', 'roboto').style('font-size', 11)
          .style('fill', '#44A9DF').style('text-anchor', 'start').style('alignment-baseline', 'middle')
          .text(this.dataSet[i+1].value)
        }
        if (i==1 || i ==3) drawY += 28; else drawY += 23
        }
      })


    }, 
  }
}
