export default {
  data: () => ({

    Legends: [
      {code:'SEI', title: 'SEI Control',               color: '#4CAE4E', checked: true, x: 0,  },
      {code:'EM',  title: 'EM Commissioning Control',  color: '#44A9DF', checked: true, x: 80, },
      {code:'MFG', title: 'EM MFG Control',            color: '#FB9BBC', checked: true, x: 230,},
    ],

    search: {
      currentStatus: 'SEI',
      targets: ['CWA'], // Target columns for searching
      input : '',
      results: [],
      x: 40,
      y: 210,
    },

    // CWA_List: [
    //   { AREA: '170000', CWA: '170000', },
    //   { AREA: '170091', CWA: '170091', },
    //   { AREA: '170092', CWA: '170092', },
    //   { AREA: '170100', CWA: '170100', },
    //   { AREA: '170300', CWA: '170300', },
    //   { AREA: '170800', CWA: '170800', },
    //   { AREA: '177100', CWA: '177100', },
    //   { AREA: '178000', CWA: '178000', },
    //   { AREA: '178001', CWA: '178001', },
    //   { AREA: '178600', CWA: '178600', },
    //   { AREA: '178900', CWA: '178900', },
    //   { AREA: '178903', CWA: '178903', },
    //   { AREA: '178992', CWA: '178992', },
    //   { AREA: '179000', CWA: '179000', },
    //   { AREA: '179100', CWA: '179100', },
    //   { AREA: '179600', CWA: '179600', },
    //   { AREA: '180200', CWA: '180200', },
    //   { AREA: '180292', CWA: '180292', },
    //   { AREA: '180401', CWA: '180401', },
    //   { AREA: '180402', CWA: '180402', },
    //   { AREA: '180403', CWA: '180403', },
    //   { AREA: '180404', CWA: '180404', },
    //   { AREA: '180600', CWA: '180600', },
    //   { AREA: '181000', CWA: '181000', },
    //   { AREA: '181001', CWA: '181001', },
    //   { AREA: '181200', CWA: '181200', },
    //   { AREA: '181301', CWA: '181301', },
    //   { AREA: '181302', CWA: '181302', },
    //   { AREA: '181303', CWA: '181303', },
    //   { AREA: '181304', CWA: '181304', },
    //   { AREA: '182200', CWA: '182200', },
    //   { AREA: '182400', CWA: '182400', },
    //   { AREA: '182492', CWA: '182492', },
    //   { AREA: '183000', CWA: '183000', },
    //   { AREA: '183100', CWA: '183100', },
    //   { AREA: '184300', CWA: '184300', },
    //   { AREA: '184301', CWA: '184301', },
    //   { AREA: '184302', CWA: '184302', },
    //   { AREA: '184303', CWA: '184303', },
    //   { AREA: '184800', CWA: '184800', },
    //   { AREA: '184891', CWA: '184891', },
    //   { AREA: '184892', CWA: '184892', },
    //   { AREA: '185300', CWA: '185300', },
    //   { AREA: '185392', CWA: '185392', },
    //   { AREA: '185394', CWA: '185394', },
    //   { AREA: '186000', CWA: '186000', },
    //   { AREA: '187501', CWA: '187501', },
    //   { AREA: '190400', CWA: '190400', },
    //   { AREA: '252701', CWA: '252701', },
    //   { AREA: '252702', CWA: '252702', },
    //   { AREA: '252703', CWA: '252703', },
    //   { AREA: '270100', CWA: '270100', },
    //   { AREA: '270300', CWA: '270300', },
    //   { AREA: '277000', CWA: '277000', },
    //   { AREA: '277082', CWA: '277082', },
    //   { AREA: '277100', CWA: '277100', },
    //   { AREA: '280200', CWA: '280200', },
    //   { AREA: '280292', CWA: '280292', },
    //   { AREA: '280294', CWA: '280294', },
    //   { AREA: '281000', CWA: '281000', },
    //   { AREA: '281001', CWA: '281001', },
    //   { AREA: '370100', CWA: '370100', },
    //   { AREA: '377100', CWA: '377100', },
    //   { AREA: '381006', CWA: '381006', },
    //   { AREA: '127900', CWA: '127993', },
    //   { AREA: '121900', CWA: '121910', },
    //   { AREA: '121900', CWA: '121920', },
    //   { AREA: '152400', CWA: '152405', },
    //   { AREA: '152400', CWA: '152408', },
    //   { AREA: '152400', CWA: '152492', },
    //   { AREA: '179100', CWA: '179100-05', },
    //   { AREA: '179100', CWA: '179100-06', },
    //   { AREA: '179100', CWA: '179100-07', },
    //   { AREA: '121400', CWA: '152406', },
    //   { AREA: '152400', CWA: '152401', },
    //   { AREA: '152400', CWA: '152402', },
    //   { AREA: '152400', CWA: '152403', },
    //   { AREA: '152400', CWA: '152404', },
    //   { AREA: '152400', CWA: '152407', },
    //   { AREA: '179000', CWA: '179000-01', },
    //   { AREA: '179000', CWA: '179000-02', },
    //   { AREA: '179100', CWA: '179100-03', },
    //   { AREA: '179100', CWA: '179100-04', },
    //   { AREA: '121902', CWA: '121902', },
    //   { AREA: '121903', CWA: '121903', },
    //   { AREA: '121904', CWA: '121904', },
    //   { AREA: '121905', CWA: '121905', },
    //   { AREA: '121906', CWA: '121906', },
    //   { AREA: '121907', CWA: '121907', },
    //   { AREA: '121908', CWA: '121908', },
    //   { AREA: '121909', CWA: '121909', },
    //   { AREA: '121911', CWA: '121911', },
    //   { AREA: '121912', CWA: '121912', },
    //   { AREA: '121913', CWA: '121913', },
    //   { AREA: '121914', CWA: '121914', },
    //   { AREA: '121915', CWA: '121915', },
    //   { AREA: '121916', CWA: '121916', },
    //   { AREA: '121917', CWA: '121917', },
    //   { AREA: '121921', CWA: '121921', },
    //   { AREA: '121922', CWA: '121922', },
    //   { AREA: '121923', CWA: '121923', },
    //   { AREA: '121924', CWA: '121924', },
    //   { AREA: '121925', CWA: '121925', },
    //   { AREA: '121926', CWA: '121926', },
    //   { AREA: '121927', CWA: '121927', },
    //   { AREA: '121931', CWA: '121931', },
    //   { AREA: '121932', CWA: '121932', },
    //   { AREA: '121942', CWA: '121942', },
    //   { AREA: '121991', CWA: '121991', },
    //   { AREA: '121992', CWA: '121992', },
    //   { AREA: '124700', CWA: '124700', },
    //   { AREA: '127700', CWA: '127700', },
    //   { AREA: '127742', CWA: '127742', },
    //   { AREA: '127791', CWA: '127791', },
    //   { AREA: '127792', CWA: '127792', },
    //   { AREA: '127800', CWA: '127800', },
    //   { AREA: '127900', CWA: '127900', },
    //   { AREA: '127901', CWA: '127901', },
    //   { AREA: '127908', CWA: '127908', },
    //   { AREA: '127909', CWA: '127909', },
    //   { AREA: '127942', CWA: '127942', },
    //   { AREA: '127982', CWA: '127982', },
    //   { AREA: '127991', CWA: '127991', },
    //   { AREA: '127992', CWA: '127992', },
    //   { AREA: '136200', CWA: '136200', },
    //   { AREA: '150200', CWA: '150200', },
    //   { AREA: '150300', CWA: '150300', },
    //   { AREA: '150400', CWA: '150400', },
    //   { AREA: '150401', CWA: '150401', },
    //   { AREA: '150402', CWA: '150402', },
    //   { AREA: '150403', CWA: '150403', },
    //   { AREA: '150404', CWA: '150404', },
    //   { AREA: '150405', CWA: '150405', },
    //   { AREA: '150406', CWA: '150406', },
    //   { AREA: '151000', CWA: '151000', },
    //   { AREA: '152000', CWA: '152000', },
    //   { AREA: '152004', CWA: '152004', },
    //   { AREA: '152014', CWA: '152014', },
    //   { AREA: '152200', CWA: '152200', },
    //   { AREA: '152300', CWA: '152300', },
    //   { AREA: '152400', CWA: '152400', },
    //   { AREA: '152700', CWA: '152700', },
    //   { AREA: '152800', CWA: '152800', },
    //   { AREA: '152801', CWA: '152801', },
    //   { AREA: '152802', CWA: '152802', },
    //   { AREA: '152803', CWA: '152803', },
    //   { AREA: '152804', CWA: '152804', },
    //   { AREA: '152805', CWA: '152805', },
    //   { AREA: '154000', CWA: '154000', },
    //   { AREA: '154001', CWA: '154001', },
    //   { AREA: '166800', CWA: '166800', },
    //   { AREA: '168000', CWA: '168000', },
    //   { AREA: '111300', CWA: '111300', },
    //   { AREA: '111342', CWA: '111342', },
    //   { AREA: '111394', CWA: '111394', },
    //   { AREA: '121300', CWA: '121300', },
    //   { AREA: '121301', CWA: '121301', },
    //   { AREA: '121302', CWA: '121302', },
    //   { AREA: '121303', CWA: '121303', },
    //   { AREA: '121304', CWA: '121304', },
    //   { AREA: '121305', CWA: '121305', },
    //   { AREA: '121306', CWA: '121306', },
    //   { AREA: '121307', CWA: '121307', },
    //   { AREA: '121308', CWA: '121308', },
    //   { AREA: '121311', CWA: '121311', },
    //   { AREA: '121312', CWA: '121312', },
    //   { AREA: '121313', CWA: '121313', },
    //   { AREA: '121321', CWA: '121321', },
    //   { AREA: '121322', CWA: '121322', },
    //   { AREA: '121323', CWA: '121323', },
    //   { AREA: '121332', CWA: '121332', },
    //   { AREA: '121342', CWA: '121342', },
    //   { AREA: '121391', CWA: '121391', },
    //   { AREA: '121392', CWA: '121392', },
    //   { AREA: '121400', CWA: '121400', },
    //   { AREA: '121401', CWA: '121401', },
    //   { AREA: '121402', CWA: '121402', },
    //   { AREA: '121403', CWA: '121403', },
    //   { AREA: '121404', CWA: '121404', },
    //   { AREA: '121405', CWA: '121405', },
    //   { AREA: '121406', CWA: '121406', },
    //   { AREA: '121407', CWA: '121407', },
    //   { AREA: '121408', CWA: '121408', },
    //   { AREA: '121409', CWA: '121409', },
    //   { AREA: '121410', CWA: '121410', },
    //   { AREA: '121411', CWA: '121411', },
    //   { AREA: '121412', CWA: '121412', },
    //   { AREA: '121413', CWA: '121413', },
    //   { AREA: '121414', CWA: '121414', },
    //   { AREA: '121415', CWA: '121415', },
    //   { AREA: '121442', CWA: '121442', },
    //   { AREA: '121491', CWA: '121491', },
    //   { AREA: '121492', CWA: '121492', },
    //   { AREA: '121900', CWA: '121900', },
    //   { AREA: '121901', CWA: '121901', },
    //   { AREA: '127902', CWA: '127902', },
    //   { AREA: '127903', CWA: '127903', },
    //   { AREA: '127904', CWA: '127904', },
    //   { AREA: '127905', CWA: '127905', },
    //   { AREA: '127906', CWA: '127906', },
    //   { AREA: '127907', CWA: '127907', },


    // ],

  }),

}