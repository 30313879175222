import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {

    Draw_Charts() {

      let data = null, style = null, tmp = null

      let overallSummary = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0.5)`)

      // Overall Certificate of Inspection
      style = {
        id: 'CERT',
        x: 150,
        y: 140,
        name: 'Total',
        sort: 'N',
      }
      tmp = this.DataItems[0]
      data = [
        {title: 'No need IFC' ,  value: tmp.NO_NEEDED_IFC / tmp.TOTAL * 100, qty: tmp.NO_NEEDED_IFC},
        {title: 'To be IFC'   ,  value: tmp.NEED_ISSUE / tmp.TOTAL * 100, qty: tmp.NEED_ISSUE},
        {title: 'Issued IFC'  ,  value: tmp.ISSUED_IFC / tmp.TOTAL * 100, qty: tmp.ISSUED_IFC},

        // {title: 'Not Started' ,  value: tmp.NOTSTARTED / tmp.TOTAL * 100, qty: tmp.NOTSTARTED},
        // {title: 'Deleted'     ,  value: tmp.DELETED / tmp.TOTAL * 100, qty: tmp.DELETED},
        // {title: 'Void'        ,  value: tmp.VOID / tmp.TOTAL * 100, qty: tmp.VOID},
        // {title: 'In Review'   ,  value: tmp.REVIEW / tmp.TOTAL * 100, qty: tmp.REVIEW},
        // {title: 'AFC'         ,  value: tmp.AFC / tmp.TOTAL * 100, qty: tmp.AFC}
      ]
      let Circle = {
        CircleDiameter: 100,
        // CircleColorSet: ['LightGray', 'Pink', 'Yellow', 'LightBlue', 'DarkGreen' ],
        CircleColorSet: ['LightGray','Yellow', 'DarkGreen' ],
        CircleHoleSize: 60,
        CircleStackSize: 5,
        CircleSliceSize: 3,
        CircleTitleSize: 16,
        CircleLegendSpace: 150,
        CircleLegendPosition: 'right',
      }
      let Guideline = {
        GuideDisplay: 'Y',
      }
      this.Chart_Pie(overallSummary, data, style, Circle, Guideline) // ---------------------------> 
    },
  }
}
           