import * as d3 from 'd3'

export default {
  methods: {
    Activate_Hull_AFT() {

      // Remove SVG
      this.removeSvg(this.CANVAS, 1000)

      this.svgModeling = this.svg.append('svg')
      this.svgModeling
      .attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight)
      .attr('viewBox', `0 0 ${this.Canvas.CanvasWidth} ${this.Canvas.CanvasHeight}`)
      .attr('opacity', 1)

      // Overall_Hull_Group
      let Hull_AFT = this.svgModeling
      .append('g')
      .attr('id', 'Hull_AFT_Group')
      .attr('opacity', 1)
      .attr('transform', `translate(0,0) scale(1)`)

      setTimeout(() => {
        this.Hull_AFT.forEach(d => {
          Hull_AFT
          .append('image')
          .attr('id', `Blk_${d.Blk}`)
          .attr('opacity', 1).style('cursor', 'pointer')
          .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Blocks/Hull/${d.Blk}.png`)
          .attr('transform', `translate(${d.x1}, ${d.y1}) scale(${0.5})`)
          .call(s => { this.Goto_Block_Summary(s, d) }) // <--------
        })
      }, 1000);
      setTimeout(() => {
        this.Hull_AFT.forEach(d => {
          d3.select(`#Blk_${d.Blk}`)
          .transition().duration(1000).attr('transform', `translate(${d.x2}, ${d.y2}) scale(0.5)`)
        })
      }, 1000);
      setTimeout(() => {
        this.Masks_Hull_AFT()
        // Display
        this.displayGroupID(`#Mask_HL02`, 0, 10, 0)
        this.displayGroupID(`#Mask_MD00a`, 0, 10, 0)
        this.displayGroupID(`#Mask_RB01_02`, 0, 10, 0)

        this.Lev1 = 'HULL'; this.Lev2 = 'HULL-AFT'
        this.stage = this.STG_Division_Lv3
        this.fncName = 'Activate_Hull_AFT + Goto_AFT_Division_Lv3'
        this.StageCode() // AREA / STAGE
        this.displayGroupID(`#Menu_Back`, 0, 200, 1)

        // ### Call Canvas ###
        this.Canvas_Division_Lv3(this.Lev1, this.Lev2)
      }, 1000);

      // setTimeout(() => {
      //   // Mask_AFT
      //   Hull_AFT
      //   .append('path')
      //   .attr('id', 'Mask_AFT_Group')
      //   .attr('d', 'M92.29,7.323,0,72.355v49.161l48.161,74.71L411.645,269,527,199.323V75.065L404.677,53.774V31.323L249.065,0,233.194,24.355Z')
      //   .attr('transform', `translate(545,350)`)
      //   .attr('fill', '#fff')
      //   .style('opacity', 0)
      //   .style('cursor', 'pointer')
      //   .call(s => { this.Goto_AFT_Division_Lv3(s) }) // <--------

      //   this.Masks_Hull_AFT() // ######

      //   this.Lev1 = 'HULL'
      //   this.Lev2 = 'HULL-AFT'
      //   this.stage = this.STG_Activate
      //   this.fncName = 'Activate_Hull_AFT'
      //   this.StageCode() // AREA / STAGE
      //   this.displayGroupID(`#Menu_Back`, 0, 200, 1)

      //   // ### Call Canvas ###
      //   this.Canvas_Hull_Activate(this.Lev2)
      // }, 1000);
    },

    Masks_Hull_AFT() {

      // Goto_AFT_Division_Lv3
      this.svgModeling 
      .append('polygon')
      .attr('id', 'Mask_HL02') // Mask_HL02
      .attr('points', '0,58 0,135 48,207 419,281 522,212 522,79 94,0')
      .attr('fill', '#fff').attr('transform', `translate(486,418)`)
      .style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_AFT_Division_Lv4(s) }) // <--------

      this.svgModeling  
      .append('polygon')
      .attr('id', 'Mask_MD00a') // Mask_MD00a
      .attr('points', '16.58,0 0.94,11.53 0.94,35.05 358.94,105.11 358.94,73.08 249.23,43.66')
      .attr('fill', '#fff').attr('transform', `translate(597,142)`)
      .style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_AFT_BlockDivison_Lv3(s, 'Mask_MD00a', 'MD00a') }) // <--------

      let RB01_02 = this.svgModeling  
      .append('g')
      .attr('id', 'Mask_RB01_02') // Mask_RB01_02
      .style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_AFT_BlockDivison_Lv3(s, 'Mask_RB01_02', 'RB01-02') }) // <--------

        RB01_02
        .append('polygon')
        .attr('points', '0,0 0,60 8.13,91.42 216.45,131.68 245.32,102.81 245.32,48.61').attr('fill', '#fff').attr('transform', `translate(423,690.5)`)
        RB01_02
        .append('polygon')
        .attr('points', '16.65,0 0,12.77 0,96 29.42,118.52 275.61,167.21 282.97,152.13 282.97,73.16 168.77,51.48 168.77,27.1').attr('fill', '#fff').attr('transform', `translate(905,299)`)

      
      // Goto_Division_AFT_Lv4
      this.svgModeling
      .append('polygon')
      .attr('id', 'Mask_HL02_Main') // Mask_HL02_Main
      .attr('points', '78.39,0 0,55.47 0,84.5 419.81,169 516,91.08')
      .attr('fill', '#fff').attr('transform', `translate(594,195) scale(0.8)`).style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_AFT_BlockDivison_Lv4(s, 'Mask_HL02_Main', 'MDK') }) // <--------

      this.svgModeling
      .append('polygon')
      .attr('id', 'Mask_HL02_After') // Mask_HL02_After
      .attr('points', '0,71.69 0,128.21 53.95,203 159,125.11 159,15.18 98.85,0')
      .attr('fill', '#fff').attr('transform', `translate(376,395) scale(0.8)`).style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_AFT_BlockDivison_Lv4(s, 'Mask_HL02_After', 'AFT') }) // <--------

      this.svgModeling
      .append('polygon')
      .attr('id', 'Mask_HL02_Port') // Mask_HL02_Port
      .attr('points', '0,0 0,118.24 388,197 388,75.66')
      .attr('fill', '#fff').attr('transform', `translate(792,375) scale(0.8)`).style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_AFT_BlockDivison_Lv4(s, 'Mask_HL02_Port', 'PRT') }) // <--------

      this.svgModeling
      .append('polygon')
      .attr('id', 'Mask_HL02_Starboard') // Mask_HL02_Starboard
      .attr('points', '0,0 0,127.21 386,201 386,73.82')
      .attr('fill', '#fff').attr('transform', `translate(495,582) scale(0.8)`).style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_AFT_BlockDivison_Lv4(s, 'Mask_HL02_Starboard', 'STB') }) // <--------

      this.svgModeling
      .append('polygon')
      .attr('id', 'Mask_HL02_Bulkhead') // Mask_HL02_Bulkhead
      .attr('points', '0,52.06 0,96.97 21.9,132.58 365.65,204 392.74,185.23 420.61,180.58 434,134.13 434,72.19 73,0')
      .attr('fill', '#fff').attr('transform', `translate(625,467) scale(0.8)`).style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_AFT_BlockDivison_Lv4(s, 'Mask_HL02_Bulkhead', 'BLK') }) // <--------

      this.svgModeling
      .append('polygon')
      .attr('id', 'Mask_HL02_Bottom') // Mask_HL02_Bottom
      .attr('points', '0,62.56 0,100.5 367.79,177 477,101.27 477,75.73 85.21,0')
      .attr('fill', '#fff').attr('transform', `translate(624,768) scale(0.8)`).style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_AFT_BlockDivison_Lv4(s, 'Mask_HL02_Bottom', 'BTM') }) // <--------
    },

  }
}
           