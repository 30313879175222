import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {

    draw_ModuleCode(seletion) {
      
      // Draw border of code
      // The border of code provides two types Rect and ellipse.

      let Module_No_Group = seletion
      .append('g')
      .attr('id', `Module_No_Group`)
      .style('visibility', 'hidden')
      
      this.MODULE[this.CurrentArea].filter(f=> f.type == 'Module').forEach(d => {

        Module_No_Group
        .append('text')
        .attr('id', `${d.module}_No`)
        .attr('transform', `translate(${d.x}, ${d.y + .5})`)
        .style('font-family', 'roboto').style('font-size', 14).style('fill', '#333').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle').text(`${d.module}`)
      })
    },


    draw_EqNumber(_deck, _x, _y, _scale) {
      
      let codeLayer = this.innerSvg.select(`#NO`)
      let countCode = codeLayer.node().children.length // SVG의 "NO" 레이어에 있는children의 갯수를 구한다 

      for(var i=0; i < countCode; i++) {
          let equipNo = codeLayer.node().children[i].textContent
          let idx = this.dataSet.findIndex(f => f.SN == equipNo && f.SVG == _deck) // SVG의 No를 database에서 찾아온다. 

          if (idx < 0) continue // SVG의 No를 database에 존재하지않으면 다음 값을 가져온다.

          // getMatrixProps()를 통하여 EQ. No.의 가로세로의 중앙좌표값(cx, cy)을 구한다.
          let target_ = this.getMatrixProps(d3.select(codeLayer.node().children[i]))
          this.dataSet[idx].idx = idx
          this.dataSet[idx].x = target_.cx 
          this.dataSet[idx].y = target_.cy

          // Draw border of code
          // The border of code provides two types Rect and ellipse.
          let data = this.dataSet[idx]
          let equipmentNo = this.innerSvg
          .append('g')
          .attr('id', `cpi${this.PAU}_${idx}`)
          .on('mouseover', () => {
          })
          .on('mouseout', () => {
          })
          .on('click', () => {
          })
        
          equipmentNo
          .append('circle')
          .attr('cx', data.x).attr('cy', data.y).attr('r', 9)
          .attr('stroke', '#18634C').attr('stroke-width', 1).attr('fill', '#8BC248')
          // .attr('stroke', data.sColor).attr('stroke-width', 1).attr('fill', data.bColor)
        
          equipmentNo
          .append('text')
          .attr('x', data.x).attr('y', data.y + 0.5)
          .style('font-family', 'roboto').style('font-size', 10).attr('fill', data.tColor).attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
          .text(data.SN)
      }
    },


    loadSvgFile(path, _x, _y, _scale) {

      return d3.xml(path).then(source => {
    
        if(this.innerSvg) {
          this.innerSvg.html()
          this.innerSvg.remove()
          this.innerSvg = null
        }
    
        this.innerSvg = source.documentElement
        this.svg.node().append(this.innerSvg)
        this.innerSvg = d3.select(this.innerSvg)

        let width = this.innerSvg.node().getBoundingClientRect().width
        let height = this.innerSvg.node().getBoundingClientRect().height
        this.innerSvg
        .attr('x', _x).attr('y', _y)
        .attr('width',width*_scale).attr('height',height*_scale)
        .attr('opacity', 0)
        .transition()
        .duration(500)
        .attr('opacity', 1)
       })
    
    },

    getMatrixProps: (selection) => {

      let transform = selection.attr('transform')
      let matrixEls = transform.substring(transform.indexOf('(') + 1, transform.indexOf(')')).split(' ')
      let elWidth = selection.node().getBoundingClientRect().width
      let elHeight = selection.node().getBoundingClientRect().height

      return {
        cx: Number(matrixEls[0]) + elWidth / 2,
        cy: Number(matrixEls[1]) - elHeight / 4,
      }

}

    
  }
}