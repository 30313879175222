import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({

    props:[
      {key:'DISC_DESCR', head1: 'Discipline', x: 50, tx: 30, cy: 0, textanchor: 'start'},
      {key:'OPEN', head1: 'Open', x: 150, tx: 160, cy: 0,textanchor: 'end'},
      {key:'VOL', head1: 'Vol (%)', x: 200, tx: 200, cy: 0},
    ],
    width: 230,
    rowHeight: 18
  }),
  

  methods: {
    drawChart() {
      let PunchTable = this.svg
      .append('g')
      .attr('class','_tableWrap')
      .attr('transform', `translate(0, 0.5)`)

      PunchTable
      .append('rect')
      .attr('x',20).attr('y', 5).attr('width',this.width - 20).attr('height',25).attr('stroke-width', 0).attr('fill', '#F4F5F5').attr('opacity', 0.4)

      PunchTable.append('line').attr('x1',20).attr('y1',5).attr('x2',this.width).attr('y2',5).attr('stroke-width', 0.2).attr('stroke', '#000')
      PunchTable.append('line').attr('x1',20).attr('y1',30).attr('x2',this.width).attr('y2',30).attr('stroke-width', 0.2).attr('stroke', '#000')

      // header
      PunchTable
      .append('g')
      .attr('transform', `translate(0, 20)`)
      .selectAll('text')
      .data(this.props)
      .enter()
      .append('text')
      .attr('x', d => d.x)
      .attr('y', 2)
      .style('font-family','roboto')
      .style('font-size',11)
      .attr('text-anchor', 'middle')
      .attr('fill', '#000')
      .text(d => d.head1)

      let row_group = PunchTable
      .append('g')
      .attr('class', '_row_data_group')
      .attr('transform', `translate(0, 45)`)

      let line_group = PunchTable
      .append('g')
      .attr('class', '_row_line_group')
      .attr('transform', `translate(0, 50)`)

      let sum = 0;
      this.DataItems.forEach((row, r) => {
        let keys = Object.keys(row)
        let sumOpen = row.OPEN
        sum += sumOpen
        
        keys.forEach(key => {
          
          let idx = this.props.findIndex(e => e.key === key)
          if(idx == -1) return
          
          row_group
          .append('text')
          .text(idx != 2 ? row[key] : row[key] + ' %')
          .attr('x', this.props[idx].tx)
          .attr('y', r * 20)
          .attr('text-anchor', this.props[idx].textanchor ? this.props[idx].textanchor : 'middle' )
          .style('font-family','roboto')
          .style('font-size',11)
          .style('font-weight', row.SIZE === 'Total' ? 'bold' : 'normal')
          .attr('fill', this.props[idx].color && row[key] !== 0 ? this.props[idx].color : '#757575' )

          line_group
          .append('line')
          .attr('x1',20).attr('y1', r * 20)
          .attr('x2', this.width).attr('y2', r * 20)
          .attr('stroke-width', 0.2).attr('stroke', r == 5 ? '#757575' : '#E0E0E0')

        })
      })

      let y_ = this.DataItems.length * 20
      let idx = this.props.findIndex(e => e.key === 'DISC_DESCR')
      
      let keys = Object.keys(this.Queries.SQL1[0])
      keys.forEach(key => {
        idx = this.props.findIndex(e => e.key === key)
        row_group
        .append('text')
        .text(this.Queries.SQL1[0][key])
        .attr('x', this.props[idx].tx)
        .attr('y', y_)
        .attr('text-anchor', this.props[idx].textanchor ? this.props[idx].textanchor : 'middle')
        .style('font-family','roboto')
        .style('font-size',11)
        .attr('fill', '#000')
      })
    }
  }
}
