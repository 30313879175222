import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({
    GaugeData: [],
  }),


  methods: {

    Chart_Gauge_01(selection, _style) {

      let procedures = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0)`)

      // Draw Background Chart
      var arcBg = d3.arc()
      .innerRadius(_style.ra)
      .outerRadius(_style.ra - _style.stroke)
      .startAngle(_style.angleStart * Math.PI / 180)
      .endAngle(_style.angleEnd * Math.PI / 180)
  
      procedures
      .append("path")
      .attr("d", arcBg)
      .attr('fill', _style.bgColor)
      .attr('transform', `translate(${_style.CircleCenterX},${_style.CircleCenterY})`)

      // Gauge Chart
      let st = 0
      let an = 180
      let ro = an / 64
      let Y1 = _style.CircleCenterY - _style.ra 
      let Y2 = Y1 + _style.stroke
      st = 270
      an = 450

      let Gauges = procedures
      .append('g')
      
      for (var n = st;n <= an;n += ro) {
        Gauges
          .append('line')
          .attr('x1', _style.CircleCenterX).attr('y1', Y1)
          .attr('x2', _style.CircleCenterX).attr('y2', Y2)
          .attr('stroke', '#fff').attr('stroke-width', 1.5).attr('opacity', 1)
          .attr('transform', () => `rotate(${n},${_style.CircleCenterX},${_style.CircleCenterY})`)
      }

      // Draw Front Chart
      let arc = d3.arc()
      .outerRadius(_style.ra)
      .innerRadius(_style.ra - _style.stroke)
      .startAngle(_style.angleStart * Math.PI / 180)
      .endAngle(() => _style.endAngle || _style.angleStart * Math.PI / 180)

      procedures
      .append('path')
      .attr('d', arc)
      .attr('fill', _style.ftColor).attr('opacity', 0.8)
      .attr('transform', `translate(${_style.CircleCenterX},${_style.CircleCenterY})`)
      .transition()
      .duration(750)
      .attrTween('d', ()=> {
        let endAngle_ = ( ((_style.angleSize * (_style.percentage/100)) + _style.angleStart) * Math.PI / 180)
        let interpolate = d3.interpolate(_style.angleStart * Math.PI / 180, endAngle_)
        return function(t) {
          _style.endAngle = interpolate(t)
          return arc();
        }
      })
      

      // needle
      let needleLen = _style.ra - _style.stroke
      let needleRadius = 5
      let centerX = _style.CircleCenterX
      let centerY = _style.CircleCenterY

      let percent = 0
      let thetaRad = percent * Math.PI

      let topX = centerX - needleLen * Math.cos(thetaRad)
      let topY = centerY - needleLen * Math.sin(thetaRad)

      let leftX = centerX - needleRadius * Math.cos(thetaRad - Math.PI / 2)
      let leftY = centerY - needleRadius * Math.sin(thetaRad - Math.PI / 2)

      let rightX = centerX - needleRadius * Math.cos(thetaRad + Math.PI / 2)
      let rightY = centerY - needleRadius * Math.sin(thetaRad + Math.PI / 2)

      procedures.
      append('path')
      .attr('id', `needle_${this.localId}`)
      .attr('d', `M ${leftX} ${leftY} L ${topX} ${topY} L ${rightX} ${rightY}`)
      .attr('fill', _style.needleColor)

      let trans = _style.percentage / 100
      let ang = 0

      for (let n = 1; n <= 101; n += 1) {

        percent = ang / 100
        thetaRad = percent * Math.PI

        topX = centerX - needleLen * Math.cos(thetaRad)
        topY = centerY - needleLen * Math.sin(thetaRad)

        leftX = centerX - needleRadius * Math.cos(thetaRad - Math.PI / 2)
        leftY = centerY - needleRadius * Math.sin(thetaRad - Math.PI / 2)

        rightX = centerX - needleRadius * Math.cos(thetaRad + Math.PI / 2)
        rightY = centerY - needleRadius * Math.sin(thetaRad + Math.PI / 2)

        d3.select(`#needle_${this.localId}`)
        .transition().delay(6*n).duration(6)
        .attr('d', `M ${leftX} ${leftY} L ${topX} ${topY} L ${rightX} ${rightY}`)
        
        ang += trans
      }

      procedures
      .append('circle')
      .attr('cx', _style.CircleCenterX).attr('cy', _style.CircleCenterY).attr('r', 5).attr('fill', _style.needleColor)

      procedures
      .append('circle')
      .attr('cx', _style.CircleCenterX).attr('cy', _style.CircleCenterY).attr('r', 2).attr('fill', '#bcbcbc')

      
      // Grid
      procedures
      .append('text')
      .attr('transform', `translate(${_style.CircleCenterX - _style.ra - 3},${_style.CircleCenterY})`)
      .style('font-family', 'roboto').style('font-size', 10)
      .attr('fill', '#bcbcbc').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
      .text('0%')

      procedures
      .append('text')
      .attr('transform', `translate(${_style.CircleCenterX - (_style.ra/2) - _style.stroke},${_style.CircleCenterY - (_style.ra/2) - _style.stroke})`)
      .style('font-family', 'roboto').style('font-size', 10)
      .attr('fill', '#bcbcbc').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
      .text('25%')

      procedures
      .append('text')
      .attr('transform', `translate(${_style.CircleCenterX},${_style.CircleCenterY - _style.ra - 7})`)
      .style('font-family', 'roboto').style('font-size', 10)
      .attr('fill', '#bcbcbc').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text('50%')

      procedures
      .append('text')
      .attr('transform', `translate(${_style.CircleCenterX + (_style.ra/2) + _style.stroke},${_style.CircleCenterY - (_style.ra/2) - _style.stroke})`)
      .style('font-family', 'roboto').style('font-size', 10)
      .attr('fill', '#bcbcbc').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('75%')

      procedures
      .append('text')
      .attr('transform', `translate(${_style.CircleCenterX + _style.ra + 3},${_style.CircleCenterY})`)
      .style('font-family', 'roboto').style('font-size', 10)
      .attr('fill', '#bcbcbc').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('100%')

      // Progress(%)
      procedures
      .append('text')
      .attr('transform', `translate(${_style.CircleCenterX},${_style.CircleCenterY + 25})`)
      .style('font-family', 'roboto').style('font-size', 18)
      .attr('fill', _style.proColor).attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .transition().duration(1000)
      .tween("text", function() {
        var i = d3.interpolateNumber(1, _style.percentage)
        return function(t) {
        d3.select(this).text( `${Number(i(t).toFixed(1))}%` )
        }
      })

    },

  }
}
