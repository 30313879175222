import * as d3 from 'd3'

export default {

  methods: {
    /*
      load_Commissioning()
      draw_CommListBox()
    */
    ani_Box(mouse, d) {
      if(mouse == 'over') {
      d3.select(`#TEXT-${d.SN}`)
      .transition().duration(500)
      .attr('transform', `translate(0, 0)scale(1)`)

      
      } else {
        d3.select(`#TEXT-${d.SN}`)
        .transition().duration(500)
        .attr('transform', `translate(${d.cx}, ${d.cy})scale(0)`)
      }
    },



    ani_Flowline(mouse_, act_, arr_, seq_) {

      if(mouse_ == 'over') {
        d3.select(`#ANI-${act_.SN}`).attr('visibility', 'visible')
        
        for(let i = 1; i < (act_.NO+1); i++) {
          d3.select(`#ANI-${act_.SN}-FLOW-${i}`)
          .transition().duration(1)
          .attr('opacity', 0)
        }

        // Action
        for(let i = 1; i < (act_.NO+1); i++) {
          d3.select(`#ANI-${act_.SN}-FLOW-${i}`)
          .transition().delay(i * act_.duration[arr_][seq_]).duration(1000)
          .attr('opacity', 1)
        }
      } else {
        for(let i = 1; i < (act_.NO+1); i++) {
          d3.select(`#ANI-${act_.SN}-FLOW-${i}`)
          .transition().duration(1)
          .attr('opacity', 0)
        }
      }

    },



    ani_Line(mouse_, act_, arr_, seq_) {
      
      let getID = ''
      if(act_.story.length == 1) {
        getID = `#ANI-${act_.SN}-LINE`
      }
      else {
        getID = `#ANI-${act_.SN}-LINE-${arr_ + 1}`
      }

      if(mouse_ == 'over') {
        d3.select(`#ANI-${act_.SN}`).attr('visibility', 'visible')
        
        d3.select(getID)
        .style('stroke-dasharray', 1500)
        .style('stroke-dashoffset', 1500)

        // Action
        d3.select(getID)
        .transition().delay(act_.delay[arr_][seq_]).duration(act_.duration[arr_][seq_])
        .ease(d3.easeSin)
        .style('stroke-dashoffset', 0)
      } else {
        d3.select(getID)
        .transition().duration(1)
        .style('stroke-dashoffset', 1500)
      }
    },



    ani_Arrow(mouse_, act_, arr_, seq_) {

      let getID = ''
      if(act_.story.length == 1) {
        getID = `#ANI-${act_.SN}-ARROW`
      }
      else {
        getID = `#ANI-${act_.SN}-ARROW-${arr_ + 1}`
      }

      if(mouse_ == 'over') {
        d3.select(`#ANI-${act_.SN}`).attr('visibility', 'visible')
        
        d3.select(getID)
        .attr('opacity', 0)

        // Action 
        d3.select(getID)
        .transition().delay(act_.delay[arr_][seq_]).duration(act_.duration[arr_][seq_])
        .attr('opacity', 1)
      } else {
        d3.select(getID)
        .transition().duration(1)
        .attr('opacity', 0)
      }
    },
    


    ani_Icon(mouse_, act_, arr_, seq_) {

      let getID = ''
      if(act_.story.length == 1) {
        getID = `#ANI-${act_.SN}-ICON`
      }
      else {
        getID = `#ANI-${act_.SN}-ICON-${arr_ + 1}`
      }

      if(mouse_ == 'over') {
        d3.select(getID).attr('transform', `translate(${act_.cx}, ${act_.cy})scale(0)`)
        d3.select(getID).attr('visibility', 'visible')
        
        // Action
        d3.select(getID)
        .transition().delay(act_.delay[arr_][seq_]).duration(act_.duration[arr_][seq_])
        .attr('transform', `translate(0,0)scale(1)`)
      } else {
        d3.select(getID).
        transition().duration(1)
        .attr('transform', `translate(${act_.cx}, ${act_.cy})scale(0)`)
      }

    },



    ani_Text(mouse_, act_, arr_, seq_) {

      let getID = ''
      if(act_.story.length == 1) {
        getID = `#ANI-${act_.SN}-TEXT`
      }
      else {
        getID = `#ANI-${act_.SN}-TEXT-${arr_ + 1}`
      }

      if(mouse_ == 'over') {
      d3.select(`#ANI-${act_.SN}`).attr('visibility', 'visible')
      d3.select(getID).attr('opacity', 0).attr('font-family', 'roboto')
      
      // Action
      d3.select(getID)
      .transition().delay(act_.delay[arr_][seq_]).duration(act_.duration[arr_][seq_])
      .attr('opacity', 1)
      } else {
        d3.select(getID).transition().duration(1).attr('opacity', 0)
      }

    },



    ani_Pulling(mouse_, act_, arr_, seq_) {

      if(mouse_ == 'over') {
        d3.select(`#ANI-${act_.SN}`).attr('visibility', 'visible')
        
        for(let i = 1; i < 26; i++) {
          d3.select(`#ANI-${act_.SN}-PULLING-${i}`)
          .transition().duration(1)
          .attr('opacity', 0)
        }
        // Action
        for(let i = 1; i < 26; i++) {
          d3.select(`#ANI-${act_.SN}-PULLING-${i}`)
          .transition().delay(i * act_.delay[arr_][seq_]).duration(act_.duration[arr_][seq_])
          .attr('opacity', 1)
        }
      } else {
        for(let i = 1; i < 26; i++) {
          d3.select(`#ANI-${act_.SN}-PULLING-${i}`)
          .transition().duration(1)
          .attr('opacity', 0)
        }
      }

    },



    ani_Pulling3(mouse_, act_, arr_, seq_) {

      if(mouse_ == 'over') {
        d3.select(`#ANI-${act_.SN}`).attr('visibility', 'visible')
        
        d3.select(`#ANI-${act_.SN}-PULLING`)
        .attr('transform', `translate(${act_.cx}, ${act_.cy}) scale(0)`)

        // Action
        d3.select(`#ANI-${act_.SN}-PULLING`)
        .transition().delay(act_.delay[arr_][seq_]).duration(act_.duration[arr_][seq_])
        .attr('transform', `translate(0,0) scale(1)`)
      } else {
        d3.select(`#ANI-${act_.SN}-PULLING`)
        .transition().duration(1)
        .attr('transform', `translate(${act_.cx}, ${act_.cy}) scale(0)`)
      }

    },



    ani_Water(mouse_, act_, arr_, seq_) {

      if(mouse_ == 'over') {
        d3.select(`#ANI-${act_.SN}`).attr('visibility', 'visible')
        
        for(let i = 1; i < (act_.NO+1); i++) {
          d3.select(`#ANI-${act_.SN}-WATER-${i}`)
          .transition().duration(1)
          .attr('opacity', 0)
        }

        // Action
        for(let i = 1; i < (act_.NO+1); i++) {
          d3.select(`#ANI-${act_.SN}-WATER-${i}`)
          .transition().delay(i * act_.delay[arr_][seq_]).duration(act_.duration[arr_][seq_])
          .attr('opacity', 1)
        }
      } else {
        for(let i = 1; i < (act_.NO+1); i++) {
          d3.select(`#ANI-${act_.SN}-WATER-${i}`)
          .transition().duration(1)
          .attr('opacity', 0)
        }
      }

    },



    ani_Truk(mouse_, act_, arr_, seq_) {

      if(mouse_ == 'over') {
        d3.select(`#ANI-${act_.SN}`).attr('visibility', 'visible')
        
        d3.select(`#ANI-${act_.SN}-TRUK`)
        .attr('opacity', 0)
        .attr('transform', `translate(400,-180)`)

        // Action
        d3.select(`#ANI-${act_.SN}-TRUK`)
        .transition().delay(act_.delay[arr_][seq_]).duration(act_.duration[arr_][seq_])
        .attr('opacity', 1)
        .attr('transform', `translate(0,0)`)
      } else {
        d3.select(`#ANI-${act_.SN}-TRUK`)
        .transition().duration(1)
        .attr('transform', `translate(${act_.cx}, ${act_.cy})`)
        .attr('opacity', 0)
      }

    },

  }
}