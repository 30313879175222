import * as d3 from 'd3'
// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  data:() => ({
    selectedStartDate: null,
    selectedStartColumn: null,

    selectedFinishDate: null,
    selectedFinishColumn: null,

    filteredList: null,
  }),
  methods: {
    Draw_Charts() {
      
      if(this.ColumnProps.length > 0) var props = this.ColumnProps.find(props_ => props_.value == 'comprops').props
      else props = this.defaultset

      let propWidth = props.width/7

      let Summary = this.svg
      .append('g')
      .attr('id', 'summary_wrap')
      .attr('transform', `translate(0.5,0.5)`).style('font-family', 'roboto')

      // if (this.DataItems[0] == undefined) { 
      //   let svg = this.svg
      //   svg.append('rect')
      //   .attr('x', 0).attr('y', 0).attr('width', 570).attr('height', 360).attr('fill', '#fff')
      //   svg.append('text').attr('x', 570/2).attr('y', 360/2).attr('text-anchor', 'middle')
      //   .text('N/A').attr('font-size', '30px')
      //   .attr('fill', '#ccc')
      //   return
      // }
      let values_ = this.DataItems
      let keys_ = Object.keys(values_[0])
      // console.log(keys_)

      this.attrs.forEach((attr,i)=>{
        Summary
        .append('text')
        // .attr('transform', `translate(${((propWidth-15)*i)+60}, 18)`)
        .attr('transform', `translate(${attr.xLocation}, 18)`)
        .style('font-size', 11.5).style('fill', i ==3 || i ==4 ||i ==5 ?'#D05D67':'#716F71').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text( attr.text)
      })
      

      values_.forEach((value,i) => {
        keys_.forEach((key, k) => {
          
          // console.log(value, key)
          // console.log(value[key])
          let attr = this.attrs.find(f => f.refColumn == key)

          // console.log(attr)

          let SummaryBox = Summary
          .append('g')
          .attr('class', 'summary-box')
          .attr('transform', `translate(${k ==2?-15 :k ==3 ? -30 :k ==4 ? -45 :k ==5 ? -60 :k ==6 ? -75 :0}, 30)`)
          if(k == 0){
            SummaryBox
            .append('polygon').attr('transform', `translate(${(propWidth*k+10)}, ${50*i})`)
            .attr('points',`0,0 ${propWidth+5},0 ${propWidth+5-20},48 0,48`)
            .attr('fill', value.DEV_GROUP =='Total' ? '#3B505D': value.DEV_GROUP =='EM to SEI'? '#38A2AA': '#E1BF5B')
          }
          if(k > 0){
            // SummaryBox
            // .append('rect')
            // .attr('x',(propWidth*k)).attr('y', i <= 1? i*58 :i*48).attr('rx', 3).attr('ry', 3).attr('width', propWidth-10).attr('height', i == 0 ? 50 :30)  
            // // .attr('fill', i == 0 ? '#3B505D': i == 1 ?'#38A2AA':'#E1BF5B')
            // .attr('fill', value.DEV_GROUP =='Total' ? '#3B505D': value.DEV_GROUP =='EM to SEI'? '#38A2AA': '#E1BF5B')

            SummaryBox
            .append('polygon').attr('transform', `translate(${(propWidth*k)}, ${50*i})`)
            .attr('points',`20,0 ${propWidth},0 ${propWidth-20},48 0,48`)
            .attr('fill', value.DEV_GROUP =='Total' ? '#3B505D': value.DEV_GROUP =='EM to SEI'? '#38A2AA': '#E1BF5B')
          }
          SummaryBox
          .append('text')
          // .attr('transform', `translate(${(propWidth*k)+90}, ${i == 0 ? 50*i+27 : i == 1 ? i*74 :i*56})`)
          .attr('transform', `translate(${k==0? 30 :(propWidth*k)+80}, ${50*i+27 })`)
          .style('font-size', 16)
          .style('fill', '#ffffff')
          // .style('fill', i == 0 && k != 0 ? '#ffffff' :'#424242')
          .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
          .text(value[attr.refColumn] == 'Total' ? 'DEV Summary' :value[attr.refColumn])

          if(k != 0){
            SummaryBox
            .style('cursor', 'pointer')
            .on('click', () => {
              
            if(values_[attr.refColumn] == 0) return

            let filterArr = []
              if (value['DEV_GROUP'] != 'Total') filterArr.push(` [DEV_GROUP] = '${value['DEV_GROUP']}' `)
              if (key == 'RESPONDED') filterArr.push(` [CAL_REVIEW_STATUS_CODE] = 'RESPONDED' `)
              if (key == 'OPEN') filterArr.push(` [CAL_REVIEW_STATUS_CODE] = 'OPEN' `)
              if (key == 'OPEN_NOT_OVERDUE') filterArr.push(` [CAL_REVIEW_STATUS_CODE] = 'OPEN' AND [OVERDUE_STATUS] = 'N' `)
              if (key == 'OPEN_OVERDUE') filterArr.push(` [CAL_REVIEW_STATUS_CODE] = 'OPEN' AND [OVERDUE_STATUS] = 'Y' `)
              if (key == 'CANCELLED') filterArr.push( ` [CAL_REVIEW_STATUS_CODE] = 'CANCELLED' `)

                let request_ = {
                  dataType: 'row',
                  action: {
                    type: 'direct',
                    target: 'slide-modal',
                    component: 'ServiceDatatable',
                    id: props.no,
                    no: ''
                  },
                  filters: { 
                    ...this.FilterValues
                  },
                  iFilters: {
                    filterString : filterArr.join(' AND '),
                    inputFilter  : ''
                  }
                }
                this.$emit('request-action', request_)
          })
        }
        })
      })
    },
  }
}

