import * as d3 from 'd3'
import __M from 'moment'
import Defs from '../../../../../../includes/primitives/Color_Defs'
import Filter from '../../../../../../includes/primitives/Filter_Defs'
// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  data:() => ({
    thead: [
      
      { key: 'NO', html: 'No.', row: 1, rowspan: 1 , color: '#EEEEEE'},
      { key: 'PSRNUM', html: 'Package', row: 1, rowspan: 1 , color: '#EEEEEE'},
      { key: 'PROCESS_UNIT', html: 'Process Unit', row: 1, rowspan: 1 , color: '#EEEEEE'},
      { key: 'MR_NO', html: 'MR No.', row: 1, rowspan: 1, color: '#EEEEEE' },
      { key: 'MR_DESCR', html: 'MR Description ', row: 1, rowspan: 1 , color: '#EEEEEE'},
      { key: 'PP_FLOAT', html: 'PP', row: 1, rowspan: 1 , color: '#E0E0E0'},
      { key: 'PPA_FLOAT', html: 'PPA', row: 1, rowspan: 1 , color: '#E0E0E0'},
      { key: 'MR_FLOAT', html: 'MR', row: 1, rowspan: 1 , color: '#E0E0E0'},
      { key: 'RFQ_FLOAT', html: 'RFQ', row: 1, rowspan: 1 , color: '#E0E0E0'},
      { key: 'BC_FLOAT', html: 'BC', row: 1, rowspan: 1 , color: '#E0E0E0'},
      { key: 'TBE_FLOAT', html: 'TBE', row: 1, rowspan: 1 , color: '#E0E0E0'},
      { key: 'CBE_FLOAT', html: 'CBE', row: 1, rowspan: 1 , color: '#E0E0E0'},
      { key: 'AR_FLOAT', html: 'AR', row: 1, rowspan: 1 , color: '#E0E0E0'},
      { key: 'ARA_FLOAT', html: 'ARA', row: 1, rowspan: 1 , color: '#E0E0E0'},
      { key: 'LOA_FLOAT', html: 'LOA', row: 1, rowspan: 1 , color: '#E0E0E0'},
      { key: 'PO_FLOAT', html: 'PO', row: 1, rowspan: 1 , color: '#E0E0E0'},
      { key: 'MDI_FLOAT', html:'MD', row: 1, rowspan: 1 , color: '#E0E0E0'},
      { key: 'MO_FLOAT', html: 'MO', row: 1, rowspan: 1 , color: '#E0E0E0'},
      { key: 'MMR_FLOAT', html:'MMR', row: 1, rowspan: 1 , color: '#E0E0E0'},
      { key: 'FI_FLOAT', html: 'FI', row: 1, rowspan: 1 , color: '#E0E0E0'},
      { key: 'EW_FLOAT', html: 'EW', row: 1, rowspan: 1 , color: '#E0E0E0'},
      { key: 'SA_FLOAT', html: 'SA', row: 1, rowspan: 1 , color: '#E0E0E0'},
      { key: 'CC_FLOAT', html: 'CC', row: 1, rowspan: 1 , color: '#E0E0E0'},
      { key: 'DJS_FLOAT', html:'DJS', row: 1, rowspan: 1 , color: '#E0E0E0'},

      // { key: 'PP_STATUS', w: 60 },
      // { key: 'PPA_STATUS', w: 60 },
      // { key: 'MR_STATUS', w: 60 },
      // { key: 'RFQ_STATUS', w: 60 },
      // { key: 'BC_STATUS', w: 60 },
      // { key: 'TBE_STATUS', w: 60 },
      // { key: 'CBE_STATUS', w: 60 },
      // { key: 'AR_STATUS', w: 60 },
      // { key: 'ARA_STATUS', w: 60 },
      // { key: 'LOA_STATUS', w: 60 },
      // { key: 'PO_STATUS', w: 60 },
      // { key: 'MDI_STATUS', w: 60 },
      // { key: 'MO_STATUS', w: 60 },
      // { key: 'MMR_STATUS', w: 60 },
      // { key: 'FI_STATUS', w: 60 },
      // { key: 'EW_STATUS', w: 60 },
      // { key: 'SA_STATUS', w: 60 },
      // { key: 'CC_STATUS', w: 60 },
      // { key: 'DJS_STATUS', w: 60 },
    ],
    theadrows: 1,
    tbody: [
      { key: 'NO', w: 30 },
      { key: 'PSRNUM', w: 50, rowspan: 'Y' },
      { key: 'PROCESS_UNIT', w: 70, rowspan: 'Y' },
      { key: 'MR_NO', w: 200 ,background: '#FAFAFA'},
      { key: 'MR_DESCR', w: 200, align: 'left' },
      { key: 'PP_FLOAT', w: 60 ,status:'PP_STATUS'},//각 해당하는 status를 찾아와야할때 이렇게 넣어주자!
      { key: 'PPA_FLOAT', w: 60,status:'PPA_STATUS' },
      { key: 'MR_FLOAT', w: 60 ,status:'MR_STATUS' },
      { key: 'RFQ_FLOAT', w: 60,status:'RFQ_STATUS' },
      { key: 'BC_FLOAT', w: 60 ,status:'BC_STATUS' },
      { key: 'TBE_FLOAT', w: 60,status:'TBE_STATUS' },
      { key: 'CBE_FLOAT', w: 60,status:'CBE_STATUS' },
      { key: 'AR_FLOAT', w: 60 ,status:'AR_STATUS'},
      { key: 'ARA_FLOAT', w: 60,status:'ARA_STATUS' },
      { key: 'LOA_FLOAT', w: 60,status:'LOA_STATUS' },
      { key: 'PO_FLOAT', w: 60 ,status:'PO_STATUS'},
      { key: 'MDI_FLOAT', w: 60,status:'MDI_STATUS'},
      { key: 'MO_FLOAT', w: 60 ,status:'MO_STATUS'},
      { key: 'MMR_FLOAT', w: 60,status:'MMR_STATUS' },
      { key: 'FI_FLOAT', w: 60 ,status:'FI_STATUS'},
      { key: 'EW_FLOAT', w: 60 ,status:'EW_STATUS'},
      { key: 'SA_FLOAT', w: 60 ,status:'SA_STATUS'},
      { key: 'CC_FLOAT', w: 60 ,status:'CC_STATUS'},
      { key: 'DJS_FLOAT', w: 60,status:'DJS_STATUS'},
    ],
    legendprops:[
      { legendText: 'Complete'    ,color:'#BDBDBD',border:'#9E9E9E'},
      { legendText: 'On track'    ,color:'#81C784',border:'#388E3C'},
      { legendText: 'Behind plan' ,color:'#EF9A9A',border:'#D32F2F'}
    ],
    legend:[
      { column: 'VALUE', color: '#E57373', shape: 'rect', text: 'VALUE', axis: 'left', x: 100, y: 0 }
    ],
    margin: { top: 80, left: 50, bottom: 50, right: 50 },
    width: 400,
    height: 230,
    // filter: [],

    selectedStartDate: null,
    selectedStartColumn: null,

    selectedFinishDate: null,
    selectedFinishColumn: null,

    filteredList: null
  }),
  methods: {
    Draw_Charts() {
      let svg = this.svg

      this.filteredList = this.DataItems

      let list = this.filteredList

      let chartY = 120


      if (this.DataItems.length == 0) {
        let svg = this.svg
        .append('g')
        .attr('transform',`translate(40,0)`)
        
        svg
        .append('text').attr('x', 570/2).attr('y', 360/2).attr('text-anchor', 'middle')
        .text('N/A').attr('font-size', '30px')
        .attr('fill', '#ccc')
        // return

      }
      

      let tableWrap = svg
      .append('g').attr('id','table_wrap')
      .attr('transform',`translate(40,${chartY +50} )`)

      let legendBox = tableWrap
      .append('g').attr('class','legend')
      .attr('transform',`translate(0,0)`)
      
      this.legendprops.forEach((d,i)=>{
        legendBox
        .append('rect')
        .attr('transform',`translate(${i*80},${0})`)
        .attr('width',10).attr('height',10)
        .attr('fill',d.color).attr('stroke',d.border).attr('stroke-width',.3)
        legendBox
        .append('text')
        .attr('transform',`translate(${i*80+15},9)`)
        .style('font-size',11)
        .text(d.legendText)
      })
      legendBox
      .append('text')
      .attr('transform',`translate(-10,${chartY*-1-20})`)
      .style('font-size',14)
      .style('font-weight','bold')
      .text('Package Challenged Status Overview - ROS Float < 30d')  

      legendBox
      .append('text')
      .attr('transform',`translate(-10,${chartY*-1-5})`)
      .style('font-size',11)
      .style('fill','#9E9E9E')
      .text(this.Queries.SQL3[0].text)



        //--------------------------------------------------------------------------------------

      let group = tableWrap.append('g').attr('class', 'testpack_table_group__')
      let table_group = group.append('g').attr('transform', `translate(-20.5, ${25})`)
      
      let table_obj = table_group.append('foreignObject')
        .attr('y',0)
        .attr('width', '100%')
        .attr('height', '100%')
        .append('xhtml:body')
        .style('position', 'fixed')

      const reducer = (previousValue, currentValue) => previousValue + currentValue

      let widths = this.tbody.map(d => d.w)
      let sumWidth = widths.reduce(reducer)

      let table = table_obj.append('table')
      .attr('id', 'commodity_summary_table_')
      .style('border-collapse', 'collapse')
      .style('font-size', '10px')
      .style('width', `${sumWidth}px`)
      .style('table-layout', 'fixed')

      let colgroup = table.append('colgroup')
      this.tbody.forEach(k => {
        colgroup.append('col').style('width', k.w + 'px')
      })

      let thead = table.append('thead')
      for (let r = 1; r <= this.theadrows; r++) {
        let row = this.thead.filter(d=> d.row === r)
        let tr = thead.append('tr')

        row.forEach(item => {

          tr.append('th')
          .attr('class', 'ogm_table_group__th')
          .attr('rowspan', () => item.rowspan ? item.rowspan : '')
          .attr('colspan', () => item.colspan ? item.colspan : '')
          .style('height', '25px')
          .style('padding', '3px')
          // .style('font-size','11px')
          .style('background', item.color ? item.color : '#FAFAFA')
          .html(item.html)
        })        
      }

      
      let tbodykeys = this.tbody

      // console.log(list)
      
      let tbody = table.append('tbody').attr('id', 'testpack_summay_tbody_')
      let actionColumns = ['MR_NO']
      let addClass = ''

      let psrnum = ''
      let process_unit = ''

      for (let r = 0; r < list.length; r++) {
        let tr = tbody.append('tr')

        tbodykeys.forEach((k, i) => {
          // console.log(psrnum)
          //테이블 rowspan 할때 넣어줘야함
          // console.log(list[r][k.key] )
          // if (list[r][k.key] == psrnum || list[r][k.key] == process_unit) return
          // else {
          //   if (i == 1) {
          //     psrnum = list[r][k.key]
          //   }else if (i == 2) {
          //     process_unit = list[r][k.key]
          //   }
          // }
          // 이렇게 하는 이유는 rowspan하면 그 옆의 colum은 없어져야하기때문에


          let td = tr.append('td')
          .style('border', 'solid 0.1px #eee')
          .style('height', '18px')
          .style('text-align', k.align ? k.align : 'center')
          .style('color', '#000000')
          .style('padding', '0 3px 0 3px')
          // .attr('rowspan', () => {
          //   if (k.rowspan == 'Y') {
          //     let filtered = list.filter(d => d[k.key] == list[r][k.key])
          //     return filtered.length
          //   }
          // })

          if(i === 4 || i === 2) {
          td.style('white-space', 'nowrap')
            .style('overflow', 'hidden')
            .style('text-overflow', 'ellipsis')
            .attr('title', list[r][k.key])
          }
          if(actionColumns.includes(k.key)) {
            td.style('background-color', '#F5F5F5')
            td.style('text-decoration', 'underline').style('color','#039BE5')
            td.on('mouseover', () => td.style('cursor', 'pointer').style('opacity',0.5))
            td.on('mouseout', () => td.style('cursor', 'pointer').style('opacity',1))
            td.on('click', () => {
              // console.log(k.key, list[r])
              let request_ = this.getRequestColumnProps('request', { MR_NO: list[r].MR_NO }, this.ColumnProps)
              
              this.$emit('request-action', request_)
            })
          }

          if(k.key.includes('FLOAT')) {
            addClass = ''
            td
            .style('position', 'relative')
            .style('color', list[r][k.status] == 'Complete'? '#424242': list[r][k.key] >= 0 ? '#1B5E20': '#D50000' )
            // .style('color', '#424242')
            .style('z-index', '1')
            .html(list[r][k.key])
            // if (list[r][k.status] && list[r][k.status] == 'Complete'){
            //   td.append('div')
            //   .style('position', 'absolute')
            //   .style('left', 0)
            //   .style('top', 0)
            //   .style('width', '100%')
            //   .style('height', '17px')
            //   .style('background-color',  '#EEEEEE')
            //   .style('z-index', '-3')
            // }

            if(list[r][k.key] < 0){
            // console.log(list[r][k.status])
              td.append('div')
              .style('position', 'absolute')
              .style('right', '30px')
              .style('top', 0)
              .style('width', (list[r][k.key]*-1) >=50? 50+'%':(list[r][k.key]*-1)+'%')
              .style('height', '17px')
              .style('z-index', '-1')
              .style('background-color', list[r][k.status] == 'Complete'?'#BDBDBD' : '#EF9A9A')
              
            }else{
              td.append('div')
              .style('position', 'absolute')
              .style('left', '30px')
              .style('top', 0)
              .style('width', list[r][k.key] > 50 ? 50+ '%' : list[r][k.key] + '%')
              .style('height', '17px')
              .style('z-index', '-2')
              // .style('background-color', list[r][k.key] < 100 ? k.key.includes('PLAN') ? '#E0E0E0' : '#FFCA28' : '#4CAF50')
              .style('background-color', list[r][k.status] == 'Complete'?'#BDBDBD' : '#81C784')
              
            }
          }
          else {
            // if (k.type == 'float') {
            //   let regexp = /\B(?=(\d{3})+(?!\d))/g
            //   if (isNaN(Math.floor(list[r][k.key]))) td.html('')
            //   else td.html(Math.round(Math.floor(list[r][k.key] * 100) / 100).toString().replace(regexp, ','))
            // } else 
            td.html(list[r][k.key])
          }
        })
      }
      if (this.DataItems.length == 0) return
      // this.Draw_BarCharts()//-------------------------------------------------------------------------------
      this.Draw_PieCharts()//-------------------------------------------------------------------------------
      this.Draw_Pie2Charts()//-------------------------------------------------------------------------------
    },
    Draw_PieCharts() {
      
      let data = [], //처음에는 null이었는데 data에 push해서 넣어주려고 []배열로 변경함!
          style = null, 
          cellX = null, 
          Circle = null, 
          Guideline = null,
          tmpE = null, 
          tmpM = null, 
          tmpI = null, 
          tmpH = null

      let overallMCStatus = this.svg
      .append('g')
      .attr('class','donut_wrap')
      .attr('transform', `translate(1180.5, 20.5)`)

      let disc = this.Queries.SQL1.map(d => d.PLAN_FLOAT_STATUS)
      // console.log(disc)

      style = {
        id: 'SC',
        x: 80,
        y: 80,
        name: disc,
        sort: 'N',
      }
      // tmpE = this.Queries.SQL1.find(f=> f.PLAN_FLOAT_STATUS == '< 1 Week' )
      // tmpM = this.Queries.SQL1.find(f=> f.PLAN_FLOAT_STATUS == '1-2 Weeks')
      // tmpI = this.Queries.SQL1.find(f=> f.PLAN_FLOAT_STATUS == '2-4 Weeks')
      // tmpH = this.Queries.SQL1.find(f=> f.PLAN_FLOAT_STATUS == '> 4 Weeks')

      //SQL1이 필터에 따라 2개가 될때도 있고 4개가 될때도 있어서 for를 돌면서 있는것만 가져오기로 하자!
      this.Queries.SQL1.forEach((d,i)=>{
        data.push({title: d.PLAN_FLOAT_STATUS ,  value: d.VALUE, qty: d.VALUE})
      })
      // data = [
      //   {title: '< 1 Week'  ,  value: tmpE.VALUE, qty: tmpE.VALUE},
      //   {title: '1-2 Weeks'   ,  value: tmpI.VALUE, qty: tmpI.VALUE},
      //   {title: '2-4 Weeks'   ,  value: tmpM.VALUE, qty: tmpM.VALUE},
      //   {title: '> 4 Weeks'   ,  value: tmpH.VALUE, qty: tmpH.VALUE},
      // ]
      Circle = {
        CircleDiameter: 80,
        // CircleColor: ['LightGray', 'Gray', 'Yellow', 'Gold', 'Orange','Pink','Violet','Red','Thistle','Purple','Indigo','LightBlue','SkyBlue','SteelBlue','YellowGreen','DarkGreen' ],
        CircleColorSet: ['Red1', 'Red2','Red3','Red4' ],
        CircleRadius: 0,
        CircleHoleSize: 50,
        CircleStackSize: 5,
        CircleSliceSize: 3,
        CircleTitleSize: 11,
        CircleLegendSpace: -40,
        CircleLegendPosition: 'bottom',
        outerRadius: 80
      }
      Guideline = {
        GuideDisplay: 'Y',
      }
      this.draw_pie(overallMCStatus, data, style, Circle, Guideline) // ---------------------------> 
    },
    draw_pie(selection, _data, _style, _Circle, _Guideline) { 
      
      this.Circle = { ...this.Circle, ..._Circle }
      this.Guideline = { ...this.Guideline, ..._Guideline }
      // console.log(this.Guideline)
      let dataLength = _data.length

      this.Circle.CircleColorSet.forEach((c,i)=> {
        Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', [c], [c, 0.5, 1.0, 0.5, -0.2], this.localId)
      })

      let Shadow = selection
      .append('defs') // Tube Color (Left)
      .append('radialGradient').attr('id', `${this.localId}__Shadow`)
      Shadow.append('stop').attr('stop-color', '#a4a4a4').attr('offset', '0.00').attr('stop-opacity', '1')
      Shadow.append('stop').attr('stop-color', '#ffffff').attr('offset', '1.00').attr('stop-opacity', '0.37')

      this.PieStacks = []
      this.pieCenterAngles = []
      this.pieSectors = []

      var sortData = _data
      if (_style.sort == 'Y') sortData.sort(this.executeSort)
      var sum = 0
      var total = 0
      var complete = 0
      _data.forEach((item, i) => {
        sum = sum + _data[i].value
        total += item.qty
      })
    
      let cumulative = 0
      _data.forEach((item, j) => {
        sortData.forEach((sItem, i) => {
          if (sItem.title == item.title) this.PieStacks.push(this.Circle.CircleDiameter - i * this.Circle.CircleStackSize)
        })
        cumulative = cumulative + (_data[j].value/sum)
        this.pieCenterAngles.push(cumulative - (_data[j].value/sum) / 2)
      })

      this.SharedColorSet = dataLength > this.Circle.CircleColorSet.length ? Array.from({ length: dataLength }, (_, i) => this.Circle.CircleColorSet[i % this.Circle.CircleColorSet.length]) : this.Circle.CircleColorSet
      this.SharedColorSetType = this.Circle.CircleColorType

      let centerTitle = selection
      
      centerTitle
      .append('text')
      .attr('transform', `translate(${_style.x}, ${_style.y-5})`)
      .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'middle')
      .text('PSR Plan')
      centerTitle
      .append('text')
      .attr('transform', `translate(${_style.x}, ${_style.y+7})`)
      .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'middle')
      .text('Late MR by')
      centerTitle
      .append('text')
      .attr('transform', `translate(${_style.x}, ${_style.y+19})`)
      .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'middle')
      .text('Week Categories')


      if(this.Circle.CircleLegendPosition == 'bottom')
      var legendsPie = selection
      .append('g')
      .attr('class','donutlegend')
      .attr('transform', `translate(${_style.x+100}, ${_style.y + this.Circle.CircleLegendSpace-50})`)

      else if(this.Circle.CircleLegendPosition == 'right')
      legendsPie = selection
      .append('g')
      .attr('transform', `translate(${_style.x + this.Circle.CircleLegendSpace}, ${_style.y - this.Circle.CircleDiameter / 1.5})`)

      _data.forEach((d,i) => {
        legendsPie
        .append('rect')
        .attr('transform', `translate(${-11}, ${(i*16)})`)
        .attr('width', 8).attr('height', 8).attr('stroke-width', 0.5).attr('stroke', '#bcbcbc').attr('fill', `url(#${this.localId}__LinearA4${this.SharedColorSet[i]}0)`)

        legendsPie
        .append('text')
        .attr('id', `${_style.id}_LG_${i}`)
        .attr('transform', `translate(${0}, ${(i*16) + 7})`)
        .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'mistartddle')
        .text(`${_data[i].title} (${d.value})`)

        // legendsPie
        // .append('text')
        // .attr('transform', `translate(${this.getNodeElValue(`#${_style.id}_LG_${i}`, 'width') + 2}, ${(i*16) + 7})`)
        // .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'mistartddle')
        // // .text(`(${_data[i].qty.toLocaleString()})`)
        // .text(`(${_data[i].qty} %)`)
      })
      

      this.get_CoordinatorsXY(_style.x, _style.y, -this.Guideline.GuideInside, 'inX', 'inY')
      this.get_CoordinatorsXY(_style.x, _style.y, this.Guideline.GuideOutside, 'outX', 'outY')
      this.get_PieChartData(_data)

      Filter.setDefs_DropShadow(selection, `_DropShadow`, 1 , 0.1 , 1, 1)

      var arc = d3.arc()
      .outerRadius(this.Circle.outerRadius)
      .innerRadius(this.Circle.CircleHoleSize)
      .cornerRadius(this.Circle.CircleRadius) // Coner Radius

      var pie = d3.pie()
      .sort(null)
      .value((d) => d.value)
      .padAngle(this.Circle.CircleSliceSize/100) // Padding Pie

      let donutGroup = selection
      .append('g')
      .attr('class','donut')
      .attr('transform', `translate(${_style.x},${_style.y})`)

      donutGroup
      .selectAll('path')
      .data(pie(this.pieSectors))
      .enter()
      .append('path')
      .attr('d', arc)
      .attr('fill', (d, i) => `url(#${this.localId}__LinearA4${this.SharedColorSet[i]}0)`)
      .style('filter', `url(#_DropShadow)`)
      .transition()
      .duration(750)
      .attrTween('d', function(d) {
          var interpolate = d3.interpolate({startAngle: 0, endAngle: 0}, d);
          return function(t) {
              return arc(interpolate(t));
          };
        });
      // if (_Guideline.GuideDisplay=='Y') this.drawGuidline(selection, _style.id)
    },
    Draw_Pie2Charts() {
      
      let data = [], 
          style = null, 
          cellX = null, 
          Circle = null, 
          Guideline = null,
          tmpE = null, 
          tmpM = null, 
          tmpI = null, 
          tmpH = null

      let overallMCStatus = this.svg
      .append('g')
      .attr('class','donut_wrap2')
      .attr('transform', `translate(1450.5, 20.5)`)

      let disc = this.Queries.SQL2.map(d => d.FORECAST_FLOAT_STATUS)
      // console.log(disc)

      style = {
        id: 'SC',
        x: 80,
        y: 80,
        name: disc,
        sort: 'N',
      }

      // console.log(this.Queries.SQL2)
      // tmpE = this.Queries.SQL2.find(f=> f.FORECAST_FLOAT_STATUS == '< 1 Week' )
      // tmpM = this.Queries.SQL2.find(f=> f.FORECAST_FLOAT_STATUS == '1-2 Weeks')
      // tmpI = this.Queries.SQL2.find(f=> f.FORECAST_FLOAT_STATUS == '2-4 Weeks')
      // tmpH = this.Queries.SQL2.find(f=> f.FORECAST_FLOAT_STATUS == '> 4 Weeks')

      // data = [
      //   {title: '< 1 Week'  ,  value: tmpE.VALUE, qty: tmpE.VALUE},
      //   {title: '1-2 Weeks'   ,  value: tmpI.VALUE, qty: tmpI.VALUE},
      //   {title: '2-4 Weeks'   ,  value: tmpM.VALUE, qty: tmpM.VALUE},
      //   {title: '> 4 Weeks'   ,  value: tmpH.VALUE, qty: tmpH.VALUE},
        
      // ]
      
      this.Queries.SQL2.forEach((d,i)=>{
        data.push({title: d.FORECAST_FLOAT_STATUS ,  value: d.VALUE, qty: d.VALUE})
      })
      Circle = {
        CircleDiameter: 80,
        // CircleColor: ['LightGray', 'Gray', 'Yellow', 'Gold', 'Orange','Pink','Violet','Red','Thistle','Purple','Indigo','LightBlue','SkyBlue','SteelBlue','YellowGreen','DarkGreen' ],
        CircleColorSet: ['Red1', 'Red2','Red3','Red4' ],
        CircleRadius: 0,
        CircleHoleSize: 50,
        CircleStackSize: 5,
        CircleSliceSize: 3,
        CircleTitleSize: 11,
        CircleLegendSpace: -40,
        CircleLegendPosition: 'bottom',
        outerRadius: 80
      }
      Guideline = {
        GuideDisplay: 'Y',
      }
      this.draw_pie2(overallMCStatus, data, style, Circle, Guideline) // ---------------------------> 
    },
    draw_pie2(selection, _data, _style, _Circle, _Guideline) { 
      
      this.Circle = { ...this.Circle, ..._Circle }
      this.Guideline = { ...this.Guideline, ..._Guideline }
      // console.log(this.Guideline)
      let dataLength = _data.length

      this.Circle.CircleColorSet.forEach((c,i)=> {
        Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', [c], [c, 0.5, 1.0, 0.5, -0.2], this.localId)
      })

      let Shadow = selection
      .append('defs') // Tube Color (Left)
      .append('radialGradient').attr('id', `${this.localId}__Shadow`)
      Shadow.append('stop').attr('stop-color', '#a4a4a4').attr('offset', '0.00').attr('stop-opacity', '1')
      Shadow.append('stop').attr('stop-color', '#ffffff').attr('offset', '1.00').attr('stop-opacity', '0.37')

      this.PieStacks = []
      this.pieCenterAngles = []
      this.pieSectors = []

      var sortData = _data
      if (_style.sort == 'Y') sortData.sort(this.executeSort)
      var sum = 0
      var total = 0
      var complete = 0
      _data.forEach((item, i) => {
        sum = sum + _data[i].value
        total += item.qty
      })
    
      let cumulative = 0
      _data.forEach((item, j) => {
        sortData.forEach((sItem, i) => {
          if (sItem.title == item.title) this.PieStacks.push(this.Circle.CircleDiameter - i * this.Circle.CircleStackSize)
        })
        cumulative = cumulative + (_data[j].value/sum)
        this.pieCenterAngles.push(cumulative - (_data[j].value/sum) / 2)
      })

      this.SharedColorSet = dataLength > this.Circle.CircleColorSet.length ? Array.from({ length: dataLength }, (_, i) => this.Circle.CircleColorSet[i % this.Circle.CircleColorSet.length]) : this.Circle.CircleColorSet
      this.SharedColorSetType = this.Circle.CircleColorType

      let centerTitle = selection
      
      centerTitle
      .append('text')
      .attr('transform', `translate(${_style.x}, ${_style.y-5})`)
      .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'middle')
      .text('PSR Forecast')
      centerTitle
      .append('text')
      .attr('transform', `translate(${_style.x}, ${_style.y+7})`)
      .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'middle')
      .text('Late MR by')
      centerTitle
      .append('text')
      .attr('transform', `translate(${_style.x}, ${_style.y+19})`)
      .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'middle')
      .text('Week Categories')


      if(this.Circle.CircleLegendPosition == 'bottom')
      var legendsPie = selection
      .append('g')
      .attr('class','donutlegend')
      .attr('transform', `translate(${_style.x+100}, ${_style.y + this.Circle.CircleLegendSpace-50})`)

      else if(this.Circle.CircleLegendPosition == 'right')
      legendsPie = selection
      .append('g')
      .attr('transform', `translate(${_style.x + this.Circle.CircleLegendSpace}, ${_style.y - this.Circle.CircleDiameter / 1.5})`)

      _data.forEach((d,i) => {
        legendsPie
        .append('rect')
        .attr('transform', `translate(${-11}, ${(i*16)})`)
        .attr('width', 8).attr('height', 8).attr('stroke-width', 0.5).attr('stroke', '#bcbcbc').attr('fill', `url(#${this.localId}__LinearA4${this.SharedColorSet[i]}0)`)

        legendsPie
        .append('text')
        .attr('id', `${_style.id}_LG_${i}`)
        .attr('transform', `translate(${0}, ${(i*16) + 7})`)
        .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'mistartddle')
        .text(`${_data[i].title} (${d.value})`)


        // legendsPie
        // .append('text')
        // .attr('transform', `translate(${this.getNodeElValue(`#${_style.id}_LG_${i}`, 'width') + 2}, ${(i*16) + 7})`)
        // .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'mistartddle')
        // // .text(`(${_data[i].qty.toLocaleString()})`)
        // .text(`(${_data[i].qty} %)`)
      })
      

      this.get_CoordinatorsXY(_style.x, _style.y, -this.Guideline.GuideInside, 'inX', 'inY')
      this.get_CoordinatorsXY(_style.x, _style.y, this.Guideline.GuideOutside, 'outX', 'outY')
      this.get_PieChartData(_data)

      Filter.setDefs_DropShadow(selection, `_DropShadow`, 1 , 0.1 , 1, 1)

      var arc = d3.arc()
      .outerRadius(this.Circle.outerRadius)
      .innerRadius(this.Circle.CircleHoleSize)
      .cornerRadius(this.Circle.CircleRadius) // Coner Radius

      var pie = d3.pie()
      .sort(null)
      .value((d) => d.value)
      .padAngle(this.Circle.CircleSliceSize/100) // Padding Pie

      let donutGroup = selection
      .append('g')
      .attr('class','donut')
      .attr('transform', `translate(${_style.x},${_style.y})`)

      donutGroup
      .selectAll('path')
      .data(pie(this.pieSectors))
      .enter()
      .append('path')
      .attr('d', arc)
      .attr('fill', (d, i) => `url(#${this.localId}__LinearA4${this.SharedColorSet[i]}0)`)
      .style('filter', `url(#_DropShadow)`)
      .transition()
      .duration(750)
      .attrTween('d', function(d) {
          var interpolate = d3.interpolate({startAngle: 0, endAngle: 0}, d);
          return function(t) {
              return arc(interpolate(t));
          };
        });
      // if (_Guideline.GuideDisplay=='Y') this.drawGuidline(selection, _style.id)
    },
    get_CoordinatorsXY(Cx, Cy, size, nameX, nameY) {
      var ra
      var a = 0 // Angle
      var aCalc = 0 //
      var aRad = 0 // Angle in Rad
      var z = 0 // Size z
      var x = 0 // Side x
      var endX = 0 // endX coordinate
      var endY = 0 // endY coordinate

      this.pieCenterAngles.forEach((item, sn) => {
        ra = this.PieStacks[sn] + size
        a = 360 * item
        aCalc = a > 180 ? 360 - a : a
        aRad = (aCalc * Math.PI) / 180
        z = Math.sqrt(2 * ra * ra - 2 * ra * ra * Math.cos(aRad))
        x = aCalc <= 90 ? ra * Math.sin(aRad) : ra * Math.sin(((180 - aCalc) * Math.PI) / 180)
        endY = Math.sqrt(z * z - x * x)
        endX = a <= 180 ? ra + x : ra - x

        this.pieSectors[sn] = {
          ...this.pieSectors[sn],
          ...{
            [nameX]: endX + Cx - ra,
            [nameY]: endY + Cy - ra,
          }
        }
      })
    },
    get_PieChartData(_data) {
      var ra
      var a = 0 // Angle
      var aCalc = 0 //
      var arcSweep = 0
      var aRad = 0 // Angle in Rad
      var z = 0 // Size z
      var x = 0 // Side x
      var endX = 0 // SVG endX coordinate
      var endY = 0 // SVG endY coordinate
      var Ro = 0 // Rotation

      var h_ra
      var h_z = 0 // Size z
      var h_x = 0 // Side x
      var h_endX = 0 // SVG endX coordinate
      var h_endY = 0 // SVG endY coordinate

      var textSpace = 0
      var direct = 0

      _data.forEach((item, sn) => {
        ra = this.PieStacks[sn]
        h_ra = this.Circle.CircleHoleSize // Hole
        a = 360 * (item.value / 100)
        aCalc = a > 180 ? 360 - a : a
        aRad = (aCalc * Math.PI) / 180
        z = Math.sqrt(2 * ra * ra - 2 * ra * ra * Math.cos(aRad))
        h_z = Math.sqrt(2 * h_ra * h_ra - 2 * h_ra * h_ra * Math.cos(aRad)) // Hole
        if (aCalc <= 90) {
          x = ra * Math.sin(aRad)
          h_x = h_ra * Math.sin(aRad) // Hole
        } else {
          x = ra * Math.sin(((180 - aCalc) * Math.PI) / 180)
          h_x = h_ra * Math.sin(((180 - aCalc) * Math.PI) / 180)
        }

        endY = Math.sqrt(z * z - x * x)
        h_endY = Math.sqrt(h_z * h_z - h_x * h_x) // Hole

        if (a <= 180) {
          endX = ra + x
          h_endX = h_ra + h_x
          arcSweep = 0
        } else {
          endX = ra - x
          h_endX = h_ra - h_x
          arcSweep = 1
        }
        if (this.pieCenterAngles[sn] < 0.5) {
          direct = this.Guideline.GuideExtend
          // align = 'start'
          textSpace = 3
        } else {
          direct = -this.Guideline.GuideExtend
          // align = 'end'
          textSpace = -3
        }

        this.pieSectors[sn] = {
          ...this.pieSectors[sn],
          ...{
            angle: a,
            value: item.value,
            title: item.title,
            color: this.SharedColorSet[sn],
            arcSweep: arcSweep,
            Ra: ra,
            endX: endX,
            endY: endY,

            h_Ra: h_ra, // Hole
            h_endX: h_endX, // Hole
            h_endY: h_endY, // Hole
            Ro: Ro,
            direct: direct,
            // align: align,
            textSpace: textSpace,
            stack: this.PieStacks[sn]
          }
        }
        Ro = Ro + a
      })
    },
    DrawNa(){
      let svg = this.svg
      .append('g')
      .attr('transform',`translate(40,0)`)
      
      svg
      .append('text').attr('x', 570/2).attr('y', 360/2).attr('text-anchor', 'middle')
      .text('N/A').attr('font-size', '30px')
      .attr('fill', '#ccc')
        
    }
  }
}
