// -------------------------------------------------------------------------------------- Global
import { SafeIdMixin } from '../../../../../../includes/mixins/safeid.mixin'                // ATAGlance Framework Globals
import gmx_Controller from '../../../../../../includes/mixins/Controller.mixin'       // SVG Control
// Global Drawings
import gmx_Canvas from '../../../../../../includes/mixins/Draw_Canvas.mixin'
import gmx_Texts from '../../../../../../includes/mixins/Text_Draw.mixin'
// Global Functions
import gmx_Miscellaneous from '../../../../../../includes/mixins/Miscellaneous.mixins'
import gmx_Movable from '../../../../../../includes/mixins/Movable.mixin'

// -------------------------------------------------------------------------------------- Local
// Locally declared common Data-Props & SVG Drawing Support
import lmx_ReactiveEvents from        './Realtime_Monitor.mixin' // Reactive Events
import lmx_Miscellaneous from         '../../../../include/mixins/Miscellaneous.mixins'
  
// Custom Charts  
import chart_BarCharts from           '../../../../include/ChartLibrary/Tortue_BarCharts.mixin'
import chart_SummaryTables from       '../../../../include/ChartLibrary/Tortue_SummaryTables.mixin'
import chart_Geometrical from         '../../../../include/ChartLibrary/Tortue_Geometrical.mixin'

import lmx_MovablePopup from              './MovablePopup.mixin'
import lmx_DataStyles from            './DataStyles.mixin'
import lmx_Declares from              './Declares.mixin'
import lmx_Codes from                 './Draw_Codes.mixin'
import lmx_Forms from                 './Draw_Forms.mixin'
import lmx_Popup from                 './Draw_EQ_Popup'
import lmx_Tag_Buttons from           './Draw_Tag_Buttons'
import lmx_Search_Button from         './Draw_Search_Button.mixin'
import lmx_Progress from              './Draw_Progress.mixin'
import lmx_GotoScreen from            './Goto_Screen_Dirty.mixin'

// Activate
import activate_Overall from          './Activate_Overall.mixin'
import activate_Hull_AFT from         './Activate_Hull_AFT.mixin'
import activate_Hull_FOR from         './Activate_Hull_FOR.mixin'
import activate_Hull_MS from          './Activate_Hull_MS.mixin'
import activate_Hull_LQ from          './Activate_Hull_LQ.mixin'
import activate_Topsides from         './Activate_Topsides.mixin'

// Canvas
import Canvas from                    './../Canvas'

// Events
import event_Overall from             './Event_Overall.mixin'
import event_Hull_AFT from            './Event_Hull_AFT.mixin'
import event_Hull_FOR from            './Event_Hull_FOR.mixin'
import event_Hull_MS from             './Event_Hull_MS.mixin'
import event_Hull_LQ from             './Event_Hull_LQ.mixin'
import event_Topside from             './Event_Topside.mixin'
import event_PDF from                 './Event_PDF_mixin'
import export_PDF_Overall from        './Export_PDF_Overall.mixin'
import export_PDF_Topside from        './Export_PDF_Topside.mixin'
import export_PDF_Hull_LQ from        './Export_PDF_Hull_LQ.mixin'
import export_PDF_Hull_MS from        './Export_PDF_Hull_MS.mixin'


import event_Block from               './Event_Block.mixin'
import goback_Event from              './Goback_Events.mixin'
import event_Note from                './Event_Note.mixin'

export default {
  mixins: [
    SafeIdMixin,
    gmx_Controller,
    gmx_Canvas,
    gmx_Texts,
    gmx_Miscellaneous,
    gmx_Movable,
    
    lmx_MovablePopup,
    lmx_ReactiveEvents,
    lmx_Miscellaneous,

    // Chart Library
    chart_BarCharts,
    chart_SummaryTables,
    chart_Geometrical,

    // Mixin
    lmx_DataStyles,
    lmx_Declares,
    lmx_Codes,
    lmx_Forms,
    lmx_Popup,
    lmx_Tag_Buttons,
    lmx_Search_Button,
    lmx_Progress,
    lmx_GotoScreen,

    activate_Overall,
    activate_Hull_AFT,
    activate_Hull_FOR,
    activate_Hull_MS,
    activate_Hull_LQ,
    activate_Topsides,

    // Mouse Event
    event_Overall,
    event_Hull_AFT,
    event_Hull_FOR,
    event_Hull_MS,
    event_Hull_LQ,
    event_Topside,
    event_PDF,
    export_PDF_Overall,
    export_PDF_Topside,
    export_PDF_Hull_LQ,
    export_PDF_Hull_MS,

    event_Block,
    goback_Event,
    event_Note,
 
    ...Canvas.mixins,
  ],
}
