import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {

  methods: {

    Charts_Area() {

      this.SVG_Area = this.svg.append('svg')

      this.SVG_Area
      .attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight)

     
      this.SVG_Area
      .append('text')
      .attr('transform', 'translate(300, 500)')
      .style('font-family', 'roboto').style('font-size', 16).style('fill', '#333').attr('font-weight', 400)
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Deckbox')


      let AreaProgress = this.SVG_Area
      .append('g')
      .attr('id', `AreaSummaryTable`)
      .attr('opacity', 1)

      let data = this.Queries.MasterProgress.find(f => f.AREA == this.Area)
      // console.log(data)
      this.Header_Progress_PAD( AreaProgress,'Construction', 300, 510, 88, 20, 3, 
                              [data.CON_P_PG, data.CON_A_PG, data.CON_D_PG],
                              '#fff', '#44A9DF'
                            ) // ------------------------>

    },


  }
}
           