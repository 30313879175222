import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  data:() => ({
    props:[
      { skyline: 'A ITR Completed',color:'#66CCFF'},
      { skyline: 'Partial MC Complete'   ,color:'#8BC34A'},
      { skyline: 'Full MC Complete' ,color:'#43A047'}
    ]
  }),
  methods: {  
    Draw_Charts() {   
      let legend = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0)`)

      this.props.forEach((d,i) => {
        legend
        .append('rect')
        .attr('transform', `translate(${130*i}, 0)`)
        .attr('rx', 3)
        .attr('ry', 3)
        .attr('width', 120)
        .attr('height', 16)
        .attr('fill', d.color)
        .attr('stroke','#9E9E9E').attr('stroke-width', .3)

        legend
        .append('text')
        .attr('transform', `translate(${(130*i) + 60}, 8)`)
        .style('font-family', 'Roboto-Regular, Roboto')
        .style('font-size', 10)
        .style('fill', '#ffffff')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(d.skyline)
      });
    },
  }
}