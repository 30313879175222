import * as d3 from 'd3'

export default {
  methods: {
    /*
      draw_HomePage()
      load_Menus()
      load_Plants()
    */
    draw_HomePage() {

      this.stage_Main = this.svg.append('svg')

      this.loadSvg(
        this.stage_Main, {
        x   : 0,
        y   : 0,
        url : '/China1/Information_Guide/svg2/HomePage.svg'
      }).then(() => {
        d3.select(`#TEXT-BG`).attr('height', 0).style('opacity', 0.3)

        this.MainMenu.forEach(d => {
          d3.select(`#TEXT-${d.code}`).style('opacity', 0)

          let menu = d3.select(`#MENU-${d.code}`).node().getBBox()
          d3.select(`#MENU-${d.code}`)
          .attr('opacity', 1)
          .attr('transform', `translate(${-(menu.x + menu.width + 5)}, ${0})`)
          .style('cursor', 'pointer')
          .call(this.call_MainMenu, d)

          this.svg.select(`#MENU-${d.code}`).select('g')
          .style('opacity', this.CurrentPlant == d.code ? 1 : 0.6)
        })
        
        this.Plants.forEach(d => {
          d.x = d3.select(`#DOT-${d.code}`).attr('cx')
          d.y = d3.select(`#DOT-${d.code}`).attr('cy')
          d3.select(`#DOT-${d.code}`).attr('visibility', 'hidden')

          d3.select(`#TAG-${d.code}`)
          .attr('transform', this.CurrentPlant == d.group ? `translate(0, 0) scale(1)` : `translate(${d.x}, ${d.y}) scale(0)`)
          .call(this.call_Plants, d)
          .style('cursor', 'pointer')
          
          d3.select(`#BOX-${d.code}`).attr('opacity', this.CurrentPlant == d.group ? 1 : 0)
        })
        this.load_Menus()
      })
    },
    

    load_Menus() {
      this.MainMenu.forEach((d,i) => {
        d3.select(`#MENU-${d.code}`)
        .transition().delay(100*i).duration(500+(500*i))
        .ease(d3.easeElasticOut.amplitude(10).period(.75))
        .attr('transform', `translate(${0}, ${0})`)
        .attr('opacity', 1)
      })
    },


    load_Plants(d_) {
      this.Plants.forEach(d => {
        if(d.group == d_.code) {
          d3.select(`#BOX-${d.code}`)
          .transition().duration(350)
          .attr('opacity', 1)

          d3.select(`#TAG-${d.code}`)
          .transition().duration(350)
          .attr('transform', `translate(0,0) scale(1)`)
        } else {
          d3.select(`#BOX-${d.code}`)
          .transition().duration(350)
          .attr('opacity', 0)

          d3.select(`#TAG-${d.code}`)
          .transition().duration(350)
          .attr('transform', `translate(${d.x}, ${d.y}) scale(0)`)
        }
      })
    },
    
  }
}
