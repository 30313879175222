export default {
  data: () => ({

    Legends: [
      {code:'SEI', title: 'SEI Control',               color: '#4CAE4E', checked: true, x: 0,  },
      {code:'EM',  title: 'EM Commissioning Control',  color: '#44A9DF', checked: true, x: 90, },
      {code:'MFG', title: 'EM MFG Control',            color: '#FB9BBC', checked: true, x: 260,},
    ],

    CWA_List: [
      { AREA: '111300', CWA: '111300', },
      { AREA: '111342', CWA: '111342', },
      { AREA: '111394', CWA: '111394', },
      { AREA: '121300', CWA: '121300', },
      { AREA: '121300', CWA: '121301', },
      { AREA: '121300', CWA: '121302', },
      { AREA: '121300', CWA: '121303', },
      { AREA: '121300', CWA: '121304', },
      { AREA: '121300', CWA: '121305', },
      { AREA: '121300', CWA: '121306', },
      { AREA: '121300', CWA: '121307', },
      { AREA: '121300', CWA: '121308', },
      { AREA: '121300', CWA: '121311', },
      { AREA: '121300', CWA: '121312', },
      { AREA: '121300', CWA: '121313', },
      { AREA: '121300', CWA: '121321', },
      { AREA: '121300', CWA: '121322', },
      { AREA: '121300', CWA: '121323', },
      { AREA: '121300', CWA: '121332', },
      { AREA: '121300', CWA: '121342', },
      { AREA: '121300', CWA: '121391', },
      { AREA: '121300', CWA: '121392', },
      { AREA: '121400', CWA: '121400', },
      { AREA: '121400', CWA: '121401', },
      { AREA: '121400', CWA: '121402', },
      { AREA: '121400', CWA: '121403', },
      { AREA: '121400', CWA: '121404', },
      { AREA: '121400', CWA: '121405', },
      { AREA: '121400', CWA: '121406', },
      { AREA: '121400', CWA: '121407', },
      { AREA: '121400', CWA: '121408', },
      { AREA: '121400', CWA: '121409', },
      { AREA: '121400', CWA: '121410', },
      { AREA: '121400', CWA: '121411', },
      { AREA: '121400', CWA: '121412', },
      { AREA: '121400', CWA: '121413', },
      { AREA: '121400', CWA: '121414', },
      { AREA: '121400', CWA: '121415', },
      { AREA: '121400', CWA: '121442', },
      { AREA: '121400', CWA: '121491', },
      { AREA: '121400', CWA: '121492', },
      { AREA: '121900', CWA: '121900', },
      { AREA: '121900', CWA: '121901', },
      { AREA: '121900', CWA: '121902', },
      { AREA: '121900', CWA: '121903', },
      { AREA: '121900', CWA: '121904', },
      { AREA: '121900', CWA: '121905', },
      { AREA: '121900', CWA: '121906', },
      { AREA: '121900', CWA: '121907', },
      { AREA: '121900', CWA: '121908', },
      { AREA: '121900', CWA: '121909', },
      { AREA: '121900', CWA: '121911', },
      { AREA: '121900', CWA: '121912', },
      { AREA: '121900', CWA: '121913', },
      { AREA: '121900', CWA: '121914', },
      { AREA: '121900', CWA: '121915', },
      { AREA: '121900', CWA: '121916', },
      { AREA: '121900', CWA: '121917', },
      { AREA: '121900', CWA: '121921', },
      { AREA: '121900', CWA: '121922', },
      { AREA: '121900', CWA: '121923', },
      { AREA: '121900', CWA: '121924', },
      { AREA: '121900', CWA: '121925', },
      { AREA: '121900', CWA: '121926', },
      { AREA: '121900', CWA: '121927', },
      { AREA: '121900', CWA: '121931', },
      { AREA: '121900', CWA: '121932', },
      { AREA: '121900', CWA: '121942', },
      { AREA: '121900', CWA: '121991', },
      { AREA: '121900', CWA: '121992', },
      { AREA: '170000', CWA: '124700', },
      { AREA: '127700', CWA: '127700', },
      { AREA: '127700', CWA: '127742', },
      { AREA: '127700', CWA: '127791', },
      { AREA: '127700', CWA: '127792', },
      { AREA: '127800', CWA: '127800', },
      { AREA: '127900', CWA: '127900', },
      { AREA: '127900', CWA: '127901', },
      { AREA: '127900', CWA: '127902', },
      { AREA: '127900', CWA: '127903', },
      { AREA: '127900', CWA: '127904', },
      { AREA: '127900', CWA: '127905', },
      { AREA: '127900', CWA: '127906', },
      { AREA: '127900', CWA: '127907', },
      { AREA: '127900', CWA: '127908', },
      { AREA: '127900', CWA: '127909', },
      { AREA: '127900', CWA: '127942', },
      { AREA: '127900', CWA: '127982', },
      { AREA: '127900', CWA: '127991', },
      { AREA: '127900', CWA: '127992', },
      { AREA: '136200', CWA: '136200', },
      { AREA: '150200', CWA: '150200', },
      { AREA: '150300', CWA: '150300', },
      { AREA: '150400', CWA: '150400', },
      { AREA: '152400', CWA: '150401', },
      { AREA: '152400', CWA: '150402', },
      { AREA: '152800', CWA: '150403', },
      { AREA: '152700', CWA: '150404', },
      { AREA: '150405', CWA: '150405', },
      { AREA: '151000', CWA: '150406', },
      { AREA: '151000', CWA: '151000', },
      { AREA: '151000', CWA: '152000', },
      { AREA: '178900', CWA: '152004', },
      { AREA: '151000', CWA: '152014', },
      { AREA: '152400', CWA: '152200', },
      { AREA: '152400', CWA: '152300', },
      { AREA: '152400', CWA: '152400', },
      { AREA: '152700', CWA: '152700', },
      { AREA: '152800', CWA: '152800', },
      { AREA: '152800', CWA: '152801', },
      { AREA: '152800', CWA: '152802', },
      { AREA: '152800', CWA: '152803', },
      { AREA: '152800', CWA: '152804', },
      { AREA: '152800', CWA: '152805', },
      { AREA: '151000', CWA: '154000', },
      { AREA: '151000', CWA: '154001', },
      { AREA: '178900', CWA: '166800', },
      { AREA: '277000', CWA: '168000', },
      { AREA: '170000', CWA: '170000', },
      { AREA: '170092', CWA: '170091', },
      { AREA: '170092', CWA: '170092', },
      { AREA: '170000', CWA: '170100', },
      { AREA: '170000', CWA: '170300', },
      { AREA: '277100', CWA: '170800', },
      { AREA: '277000', CWA: '177100', },
      { AREA: '178000', CWA: '178000', },
      { AREA: '178000', CWA: '178001', },
      { AREA: '178900', CWA: '178600', },
      { AREA: '178900', CWA: '178900', },
      { AREA: '178900', CWA: '178903', },
      { AREA: '178900', CWA: '178992', },
      { AREA: '277000', CWA: '179600', },
      { AREA: '180200', CWA: '180200', },
      { AREA: '180292', CWA: '180292', },
      { AREA: '151000', CWA: '180401', },
      { AREA: '170000', CWA: '180402', },
      { AREA: '277000', CWA: '180403', },
      { AREA: '152400', CWA: '180404', },
      { AREA: '180600', CWA: '180600', },
      { AREA: '181000', CWA: '181000', },
      { AREA: '151000', CWA: '181001', },
      { AREA: '152400', CWA: '181200', },
      { AREA: '170000', CWA: '181301', },
      { AREA: '277000', CWA: '181302', },
      { AREA: '277000', CWA: '181303', },
      { AREA: '277000', CWA: '181304', },
      { AREA: '152400', CWA: '182200', },
      { AREA: '182400', CWA: '182400', },
      { AREA: '182400', CWA: '182492', },
      { AREA: '183000', CWA: '183000', },
      { AREA: '151000', CWA: '183100', },
      { AREA: '184300', CWA: '184300', },
      { AREA: '184300', CWA: '184301', },
      { AREA: '184300', CWA: '184302', },
      { AREA: '184300', CWA: '184303', },
      { AREA: '184800', CWA: '184800', },
      { AREA: '184800', CWA: '184891', },
      { AREA: '184800', CWA: '184892', },
      { AREA: '185300', CWA: '185300', },
      { AREA: '185300', CWA: '185392', },
      { AREA: '185300', CWA: '185394', },
      { AREA: '151000', CWA: '186000', },
      { AREA: '183000', CWA: '187501', },
      { AREA: '178900', CWA: '190400', },
      { AREA: '152800', CWA: '252701', },
      { AREA: '152800', CWA: '252702', },
      { AREA: '152400', CWA: '252703', },
      { AREA: '170000', CWA: '270100', },
      { AREA: '170000', CWA: '270300', },
      { AREA: '277000', CWA: '277000', },
      { AREA: '277000', CWA: '277082', },
      { AREA: '277100', CWA: '277100', },
      { AREA: '280200', CWA: '280200', },
      { AREA: '280200', CWA: '280292', },
      { AREA: '280200', CWA: '280294', },
      { AREA: '281000', CWA: '281000', },
      { AREA: '281000', CWA: '281001', },
      { AREA: '170000', CWA: '370100', },
      { AREA: '277100', CWA: '377100', },
      { AREA: '151000', CWA: '381006', },
      { AREA: '127900', CWA: '127993', },
      { AREA: '121900', CWA: '121910', },
      { AREA: '121900', CWA: '121920', },
      { AREA: '152400', CWA: '152405', },
      { AREA: '152400', CWA: '152408', },
      { AREA: '152400', CWA: '152492', },
      { AREA: '121400', CWA: '152406', },
      { AREA: '152400', CWA: '152401', },
      { AREA: '152400', CWA: '152402', },
      { AREA: '152400', CWA: '152403', },
      { AREA: '152400', CWA: '152404', },
      { AREA: '152400', CWA: '152407', },
      { AREA: '179001', CWA: '179001', },
      { AREA: '179002', CWA: '179002', },
      { AREA: '179003', CWA: '179003', },
      { AREA: '179004', CWA: '179004', },
      { AREA: '179105', CWA: '179105', },
      { AREA: '179106', CWA: '179106', },
      { AREA: '179107', CWA: '179107', },
      { AREA: '179000-01', CWA: '179000-01', },
      { AREA: '179000-02', CWA: '179000-02', },
      { AREA: '179100-03', CWA: '179100-03', },
      { AREA: '179100-04', CWA: '179100-04', },
      { AREA: '179100-05', CWA: '179100-05', },
      { AREA: '179100-06', CWA: '179100-06', },
      { AREA: '179100-07', CWA: '179100-07', },
      { AREA: '152800', CWA: '152803_TEMP', },
      { AREA: '152800', CWA: '5180BN001', },
    ],

  }),

}