import * as d3 from 'd3'

export default {
  data: () => ({
    dataSet: [],
    // Scales
    scaleXInfo: {
      scale: null,
      tickValues: []
    },
    scaleXInfo2: {
      scale: null,
      tickValues: []
    },
    scaleYInfo: {
      scale: null,
      tickValues: []
    },
    scaleYInfo2: {
      scale: null,
      tickValues: []
    },
    scaleChartVGroup: {
      groupScale: null,
      barScale: null
    },

    // Common
    setThickness: 0,
    setDistance: 0,
    Bar: {
      BarAutoSize: 'Y',
      BarThickness: 100,
      BarDistance: 20,
      BarRadius: 10, // Embossed only
      // BarColorType: "LinearA9",
      // BarColorSet: ["Gold", "Orange", "Pink", "Gray", "SkyBlue", "LightBlue", "YellowGreen", "Red", "Thistle", "Purple", "Indigo", "LightBlue", "SkyBlue", "SteelBlue", "YellowGreen","DarkGreen"],
      BarColorType: 'LinearA2',
      BarColorSet: ['#FB8C00', '#E91E63', '#7C4DFF', '#18FFFF'],
      BarReflection: 'Y',
      // ["#ffee58","#ffeb3b","#fdd835","#fbc02d","#f9a825","#f57f17"]
  
      BarBackDisplay: 'Y',
      BarBackColor: '#efefef',
  
      BarSeriesDisplay: 'Y',
      BarSeriesSpace: 5,
      BarSeriesFont: 'roboto',
      BarSeriesStyle: 'regular',
      BarSeriesSize: 12,
      BarSeriesAutoColor: 'Y',
      BarSeriesColor: '#757575',
  
      BarValueDisplay: 'Y',
      BarValueFont: 'roboto',
      BarValueStyle: 'regular',
      BarValueSize: 11,
      BarValueRound: 1,
      BarValueAutoColor: 'N',
      BarValueColor: '#fff',
      BarValuePosition: 'middle',
      BarValueSpace: 7, // Tube only
  
      BarUnitDisplay: 'Y',
      BarUnitFormat: '%',
      BarUnitSize: 11,
      BarUnitColor: '#757575',
  
      BarSeriesPosition: 'above' 
    },
    Axis: {
      AxisXLineDisplay: 'Y',
      AxisXLineWeight: 0.5,
      AxisXLineColor: '#37474f',
  
      AxisXLevelDisplay: 'Y',
      AxisXLevelPosition: '',
      AxisXLevelSpace: 10,
      AxisXLevelFont: 'roboto',
      AxisXLevelStyle: 'regular',
      AxisXLevelSize: 11,
      AxisXLevelWeight: 500,
      AxisXLevelAutoColor: 'Y',
      AxisXLevelColor: '#37474f',
      AxisXLevelUnitSize: 11,
      AxisXLevelUnitFormat: '%',
      AxisXLevelDirection: 'Horizontal',
  
      AxisYLineDisplay: 'Y',
      AxisYLineWeight: 0.5,
      AxisYLineColor: '#37474f',
  
      AxisYLevelDisplay: 'Y',
      AxisYLevelPosition: 'left',
      AxisYLevelSpace: 10,
      AxisYLevelFont: 'roboto',
      AxisYLevelStyle: 'regular',
      AxisYLevelSize: 11,
      AxisYLevelWeight: 500,
      AxisYLevelAutoColor: 'Y',
      AxisYLevelColor: '#37474f',
      AxisYLevelUnitSize: 11,
      AxisYLevelUnitFormat: '',
  
      AxisYBoundsAuto: 'Y',
      AxisYBoundsMax: 100,
  
      AxisYGridDisplay: 'Y',
      AxisYGridWeight: 0.5,
      AxisYGridColor: '#E0E0E0'
    },
    Title: {
      TitleMainDisplay: 'Y',
      TitleMainText: 'Spool Installation',
      TitleMainX: 400,
      TitleMainY: 50,
      TitleMainFont: 'roboto',
      TitleMainStyle: 'regular',
      TitleMainSize: 24,
      TitleMainColor: '#44A9DF',
      TitleMainAlign: 'middle',
  
      TitleSubDisplay: 'Y',
      TitleSubText: 'MD2 Project',
      TitleSubX: 400,
      TitleSubY: 70,
      TitleSubFont: 'roboto',
      TitleSubStyle: 'regular',
      TitleSubSize: 12,
      TitleSubColor: '#BDBCBC',
      TitleSubAlign: 'middle',
  
      TitleExtraDisplay: 'N',
      TitleExtraText: 'Discipline',
      TitleExtraX: 400,
      TitleExtraY: 430,
      TitleExtraFont: 'roboto',
      TitleExtraStyle: 'regular',
      TitleExtraSize: 14,
      TitleExtraColor: 'gray',
      TitleExtraAlign: 'middle',
  
      TitleSqlDisplay: 'N',
      TitleSqlText: 'SQL Query...',
      TitleSqlQuery: 'SQL Query...',
      TitleSqlX: 400,
      TitleSqlY: 90,
      TitleSqlFont: 'roboto',
      TitleSqlStyle: 'regular',
      TitleSqlSize: 11,
      TitleSqlColor: 'pink',
      TitleSqlAlign: 'middle',
  
      TitleLineDisplay: 'N',
      TitleLineX: 30,
      TitleLineY: 30,
      TitleLineLength: 100,
      TitleLineWeight: 1,
      TitleLineColor: 'black'
    },
  }),
  computed: {
    ready2draw() {

      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },
  methods: {
    setDefaultValues(){
  
    },
    setScaleInfo() {
      if (this.Queries.SQL6.length === 0) return

      this.setThickness = 0

      let dataLength = this.Queries.SQL6.length

        this.setScaleXInfo(this.scaleXInfo, this.Canvas.CanvasChartWidth)
        this.setScaleXInfo2(this.scaleXInfo2, this.Canvas.CanvasChartWidth)
        this.setScaleYInfo(this.scaleYInfo, this.Canvas.CanvasChartHeight)
        this.setScaleYInfo2(this.scaleYInfo2, this.Canvas.CanvasChartHeight)
        
    },
    setScaleXInfo(_scaleXY, _chartWH) {
      let dataLength = this.Queries.SQL6.length
      // The value's key name should be 'title' or others,
      // or the first column must be value?
      // Need to be regulated.
      let valueKeyName = 'CATEGORY'
      _scaleXY.tickValues = this.Queries.SQL6.map(item => item[valueKeyName])

      if (this.Bar.BarAutoSize == 'Y') var scaleXRangeMax_ = _chartWH
      else scaleXRangeMax_ = (
        dataLength * (this.Bar.BarThickness + this.Bar.BarDistance) - this.Bar.BarDistance +
        this.Canvas.CanvasChartSpace * 2 -
        this.Canvas.CanvasChartSpace
      )

      _scaleXY.scale = d3.scaleBand()
        .domain(_scaleXY.tickValues)
        .rangeRound([0, scaleXRangeMax_])
        // .paddingInner((this.Bar.BarAutoSize == 'Y' ? this.Canvas.CanvasChartWidth / dataLength : this.Bar.BarDistance) / 100)
        .paddingInner(this.Bar.BarDistance / 100)
        .paddingOuter(this.Canvas.CanvasChartSpace / 50)
        // console.log(_scaleXY.tickValues)
        // console.log(scaleXRangeMax_)
      /* Reassigne the default value */
      this.setThickness = this.Bar.BarAutoSize == 'Y' ? _scaleXY.scale.bandwidth() : this.Bar.BarThickness

    },
    setScaleXInfo2(_scaleXY, _chartWH) {
      let dataLength = this.Queries.SQL7.length
      // The value's key name should be 'title' or others,
      // or the first column must be value?
      // Need to be regulated.
      let valueKeyName = 'CATEGORY'
      _scaleXY.tickValues = this.Queries.SQL7.map(item => item[valueKeyName])

      if (this.Bar.BarAutoSize == 'Y') var scaleXRangeMax_ = _chartWH
      else scaleXRangeMax_ = (
        dataLength * (this.Bar.BarThickness + this.Bar.BarDistance) - this.Bar.BarDistance +
        this.Canvas.CanvasChartSpace * 2 -
        this.Canvas.CanvasChartSpace
      )

      _scaleXY.scale = d3.scaleBand()
        .domain(_scaleXY.tickValues)
        .rangeRound([0, scaleXRangeMax_])
        // .paddingInner((this.Bar.BarAutoSize == 'Y' ? this.Canvas.CanvasChartWidth / dataLength : this.Bar.BarDistance) / 100)
        .paddingInner(this.Bar.BarDistance / 100)
        .paddingOuter(this.Canvas.CanvasChartSpace / 50)
        // console.log(_scaleXY.tickValues)
        // console.log(scaleXRangeMax_)
      /* Reassigne the default value */
      this.setThickness = this.Bar.BarAutoSize == 'Y' ? _scaleXY.scale.bandwidth() : this.Bar.BarThickness

    },
    setScaleYInfo(_scaleXY, _chartWH) {
      // The value's key name should be 'value' or others,
      // or the second column must be value?
      // Need to be regulated.
      let targetValues_ = this.setTargetValues()


      // ...targetValues_ : enumerates the values out of the array, 
      // ex) [1, 2, 3, 4, 5] => 1, 2, 3, 4, 5
      let maxDataValue_ = this.Queries.SQL6.length === 0 ? 9 : Math.max(...targetValues_)
      let maxBoundValue_ = this.Axis.AxisYBoundsAuto == 'Y' ? this.getMaxBound(maxDataValue_) : this.Axis.AxisYBoundsMax

      // Portion of the increment for each
      let portionY = Math.round(maxBoundValue_ / 5)
      // console.log(targetValues_)
      // Calculate the data values for each of the Grid-Lines, 6, 
      // 0 to maxBoundValue_.
      _scaleXY.tickValues = Array.from({ length: 6 }, (_, i) => (i * portionY))

      
      // This Y Scale, calculated by the data values, will be used
      // wherever the Y Coordinates are needed.
      _scaleXY.scale = d3
        .scaleLinear()
        .domain([0, maxBoundValue_])
        .rangeRound([_chartWH, 0])

    },
    setScaleYInfo2(_scaleXY, _chartWH) {
      // The value's key name should be 'value' or others,
      // or the second column must be value?
      // Need to be regulated.
      let targetValues_ = this.setTargetValues2()


      // ...targetValues_ : enumerates the values out of the array, 
      // ex) [1, 2, 3, 4, 5] => 1, 2, 3, 4, 5
      let maxDataValue_ = this.Queries.SQL7.length === 0 ? 9 : Math.max(...targetValues_)
      let maxBoundValue_ = this.Axis.AxisYBoundsAuto == 'Y' ? this.getMaxBound(maxDataValue_) : this.Axis.AxisYBoundsMax

      // Portion of the increment for each
      let portionY = Math.round(maxBoundValue_ / 5)
      // console.log(targetValues_)
      // Calculate the data values for each of the Grid-Lines, 6, 
      // 0 to maxBoundValue_.
      _scaleXY.tickValues = Array.from({ length: 6 }, (_, i) => (i * portionY))

      
      // This Y Scale, calculated by the data values, will be used
      // wherever the Y Coordinates are needed.
      _scaleXY.scale = d3
        .scaleLinear()
        .domain([0, maxBoundValue_])
        .rangeRound([_chartWH, 0])

    },
    setTargetValues() {

      // if (this.ChartType.includes('Stack')) {
        let targetValues_ = []
        this.Queries.SQL6.map(function (item) {
          let newItem = JSON.parse(JSON.stringify(item))
          delete newItem.TOTAL
          targetValues_.push(Object.values(newItem).splice(1).reduce((a, b) => a + b, 0))
        })
        return targetValues_
    },
    setTargetValues2() {

      // if (this.ChartType.includes('Stack')) {
        let targetValues_ = []
        this.Queries.SQL7.map(function (item) {
          let newItem = JSON.parse(JSON.stringify(item))
          delete newItem.TOTAL
          targetValues_.push(Object.values(newItem).splice(1).reduce((a, b) => a + b, 0))
        })
        return targetValues_
    },
    setScaleBarGroup() {

      let dataLength = this.Queries.SQL6.length
      let keys_ = Object.keys(this.Queries.SQL6[0]).splice(1)

      if (this.Bar.BarAutoSize == 'Y') var scaleXRangeMax_ = this.Canvas.CanvasChartWidth
      else scaleXRangeMax_ = (
        dataLength * ((this.Bar.BarThickness + (this.Bar.BarThickness / 100)) * keys_.length) +
        this.Bar.BarDistance * dataLength +
        this.Canvas.CanvasChartSpace * 2
      )

      this.scaleChartVGroup.groupScale = d3.scaleBand()
        .domain(this.Queries.SQL6.map(d => d.CATEGORY))
        .rangeRound([0, scaleXRangeMax_])
        .paddingInner(this.Bar.BarDistance / 100)
        .paddingOuter(this.Canvas.CanvasChartSpace / 100)

      this.scaleChartVGroup.barScale = d3.scaleBand()
        .domain(keys_)
        .rangeRound([0, this.scaleChartVGroup.groupScale.bandwidth()])
        .paddingInner(0.1)

    },
 
    
  }
}