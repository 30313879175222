import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({
    Phase:[
      {name: 'Engineering',               code: 'ENG',  title: 'delta',     tColor: '#FF75A5'},
      {name: 'Procurement',               code: 'PUR',  title: 'delta',     tColor: '#FF75A5'},
      {name: 'Construction',              code: 'FAB',  title: 'delta',     tColor: '#FF75A5'},
      {name: 'Mechanical Copletion(A1)',  code: 'A1',   title: 'Last Week', tColor: '#83D2F5'},
      {name: 'Commissioning(B1)',         code: 'B1',   title: 'Last Week', tColor: '#83D2F5'},
    ]
  }),


  methods: {
    
    drawChart() {

      this.Phase.forEach(d=> {
        d.progress = this.dataSet.find(f => f.PHASE == d.code).TOTAL
        d.delta = this.dataSet.find(f => f.PHASE == d.code).WEEK
      })

      let drawX = 0
      let Progress = this.svg
      .append('g')
      .attr('id', `chartMenu__${this.localId}`)
      .attr('transform', `translate(${10.5}, ${0})`)

      this.Phase.forEach((d,i)=> {

        Progress
        .append('rect')
        .attr('x', drawX).attr('y', 5).attr('rx', 5).attr('ry', 5).attr('width', 288).attr('height', 80)
        .attr('stroke', '#F4F5F5').attr('stroke-width', 0.5).attr('fill', '#F4F5F5')

        Progress
        .append('text')
        .attr('x', drawX + 20).attr('y', 25).attr('fill', '#333') .style('font-size', 14).style('font-family', 'roboto')
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle').text(d.name)

        Progress
        .append('text')
        .attr('x', drawX + 20).attr('y', 48).attr('fill', '#818181') .style('font-size', 22).style('font-family', 'roboto')
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle').text(`${d.progress.toFixed(1)}%`)

        Progress
        .append('text')
        .attr('x', drawX + 220).attr('y', 45).attr('fill', '#333') .style('font-size', 14).style('font-family', 'roboto')
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle').text(`${d.delta.toFixed(1)}%`)

        Progress
        .append('text')
        .attr('x', drawX + 220).attr('y', 60).attr('fill', d.tColor) .style('font-size', 10).style('font-family', 'roboto')
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle').text(d.title)

        Progress
        .append('image')
        .attr('x', drawX + 200).attr('y', 36).attr('width', 12)
        .attr('xlink:href', () => {
          if (d.delta > 0) return `${this.__HOST_NAME_RESOURCE}/icon/svg/prog_up.svg`; else return `${this.__HOST_NAME_RESOURCE}/icon/svg/prog_down.svg`
        })

        if (i > 0) {
          Progress
          .append('line')
          .attr('x1', drawX - 10).attr('y1', 5).attr('x2', drawX - 10).attr('y2', 420).attr('stroke-width', 0.5).attr('stroke', '#E0E0DF')
        }

        drawX +=308
      })
      

        



      
      



      

    },

    

  }
}
