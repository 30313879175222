import * as d3 from 'd3'
// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  data:() => ({
    thead: [
      { key: 'NO', html: 'No.', row: 1, rowspan: 2 },
      { key: 'TEST_PACKAGE', html: 'Test Package', row: 1, rowspan: 2 },
      { key: 'GWBS_LV3', html: 'Module', row: 1, rowspan: 2 },
      { key: 'ITR Completed Status', html: 'ITR Completed Status', row: 1, colspan: 5 },
      { key: 'P01A', html: 'P01A', row: 2 },
      { key: 'P03A', html: 'P03A', row: 2 },
      { key: 'IC01A', html: 'IC01A', row: 2 },
      { key: 'P05A', html: 'P05A', row: 2 },
      { key: 'P06A', html: 'P06A', row: 2 },
      // { key: 'P10A', html: 'P10A', row: 2 },
      { key: 'PA_0', html: 'Open Punch', row: 1, colspan: 2 },
      { key: 'PB_O', html: 'A', row: 2 },
      { key: 'PB_O', html: 'B', row: 2 },
      { key: 'MC_ACTUAL', html: 'MC Actual', row: 1, rowspan: 2 },
      { key: 'PDF', html: 'PDF', row: 1, rowspan: 2 },
    ],
    theadrows: 2,
    tbody: [
      { key: 'NO', w: 40, align: 'center'},
      { key: 'TEST_PACKAGE', w: 190, align: 'center'},
      { key: 'GWBS_LV3', w: 50, align: 'center'},
      { key: 'P01A', w: 65, align: 'center'},
      { key: 'P03A', w: 65, align: 'center'},
      { key: 'IC01A', w: 65, align: 'center'},
      { key: 'P05A', w: 65, align: 'center'},
      { key: 'P06A', w: 65, align: 'center'},
      // { key: 'P10A', w: 65, align: 'center'},
      { key: 'PA_0', w: 60, align: 'center'},
      { key: 'PB_O', w: 60, align: 'center'},
      { key: 'MC_ACTUAL', w: 65, align: 'center'},
      { key: 'TEST_PACKAGE_PDF', w: 50, align: 'center'}
    ],
  }),
  methods: {
    Draw_Charts() {
      let svg = this.svg

      this.filteredList = this.DataItems

      let list = this.filteredList

      let group = svg.append('g').attr('class', 'testpack_table_group__')
      let table_group = group.append('g').attr('transform', `translate(0.5, 0)`)
      
      let table_obj = table_group.append('foreignObject')
        .attr('y',0)
        .attr('width', '100%')
        .attr('height', '100%')
        .append('xhtml:body')
        .style('position', 'fixed')

      const reducer = (previousValue, currentValue) => previousValue + currentValue

      let widths = this.tbody.map(d => d.w)
      let sumWidth = widths.reduce(reducer)

      let table = table_obj.append('table')
      .attr('id', 'testpack_summary_table_')
      .style('border-collapse', 'collapse')
      .style('font-size', '10px')
      .style('width', `${sumWidth}px`)
      .style('table-layout', 'fixed')

      let colgroup = table.append('colgroup')
      this.tbody.forEach(k => {
        colgroup.append('col').style('width', k.w + 'px')
      })
      let thead = table.append('thead')
      for (let r = 1; r <= this.theadrows; r++) {
        let row = this.thead.filter(d=> d.row === r)
        let tr = thead.append('tr')

        row.forEach(item => {
          tr.append('th')
          .attr('class', 'testpack_table_group__th')
          .attr('rowspan', () => item.rowspan ? item.rowspan : '')
          .attr('colspan', () => item.colspan ? item.colspan : '')
          .style('border', 'solid 0.7px #eee')
          .style('height', '20px')
          .style('padding', '3px')
          .style('background', item.color ? item.color : '#FAFAFA')
          .html(item.html)
        })        
      }
      
      let tbodykeys = this.tbody
      
      let tbody = table.append('tbody').attr('id', 'testpack_summay_tbody_')

      let addClass = ''

      for (let r = 0; r < list.length; r++) {
        let tr = tbody.append('tr')
        tbodykeys.forEach((k, i) => {

          let td = tr.append('td')
            .style('border', 'solid 0.1px #eee')
            .style('height', '18px')
            .style('text-align', k.align ? k.align : 'center')
            .style('color', '#000000')
            .style('padding', '0 3px 0 3px')

          if(k.key.includes('PROG')) {
            addClass = ''
            td
            .style('position', 'relative')
            .style('color', list[r][k.key] < 0 ? 'red' : list[r][k.key] === 100 ? '#fff' : addClass == 'caution' ? 'red' : '#000')
            .style('z-index', '1')
            .html(list[r][k.key] == null ? 0+'%' :list[r][k.key]+'%')

            td.append('div')
            .style('position', 'absolute')
            .style('left', 0)
            .style('top', 0)
            .style('width', list[r][k.key] > 0 ? list[r][k.key] + '%' : 0)
            .style('height', '17px')
            .style('background-color', list[r][k.key] < 100 ? k.key.includes('PLAN') ? '#E0E0E0' : '#FFCA28' : '#4CAF50')
            .style('z-index', '-1')
          }
          else if (k.key.includes('PDF') && list[r][k.key] == 'Y') {
            let g = td.append('svg')
            .attr('width', '30').attr('height', '15')
            
            g.append('rect')
            .attr('transform', `translate(0, 2)`)
            .attr('rx', 3)
            .attr('ry', 3)
            .attr('width', 30)
            .attr('height', 15)
            .attr('fill', '#D50000')
            
            g.append('text').text('PDF')
            .attr('transform', `translate(15, 10)`)
            .attr('text-anchor', 'middle')
            .attr('alignment-baseline', 'middle')
            .style('fill', '#ffffff')
            
            td.style('cursor', 'pointer')
            td.on('mouseover', function() {
              d3.select(this).transition().duration(100).style('opacity', '0.7')
            })
            td.on('mouseout', function(){
              d3.select(this).transition().duration(100).style('opacity', '1')
            })
            td.on('click', () => {
              let request_ = {
                dataType: 'row',
                action: {
                  name: 'Download',
                  type: 'direct',
                  target: 'Download',
                  component: 'none',
                  id: 0,
                  no: ''
                },
                filters     : {
                  SUBSYSTEM: this.FilterValues.SUBSYSTEM,
                  METADATA: list[r]['TEST_PACKAGE'],
                  folder: 'TestPackage'
                },
                iFilters    : {
                  filterString : '',
                  inputFilter  : ''
                }
              }
              console.log(request_.filters)
              this.$emit('request-action', request_)
            })
          }
          else {
            if (k.type == 'float') {
              let regexp = /\B(?=(\d{3})+(?!\d))/g
              if (isNaN(Math.floor(list[r][k.key]))) td.html('')
              else td.html(Math.round(Math.floor(list[r][k.key] * 100) / 100).toString().replace(regexp, ','))
            } else td.html(list[r][k.key])
          }
        })
      }
    },
  }
}
