<template>
  <div :id="localId"></div>
</template>

<script>
import * as d3 from 'd3'
import mx_Core from './includes/mixins/importer'            // Global & Local Mixins
import __M from 'moment'

export default {
  name: "j-chart-donut",
  mixins: [mx_Core],
  props: {
    id: String,
    
    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },
    DataItems: { type: Array, default: () => ([]) },
    Queries: {type: Object, default: () => ({}) },
    ColumnProps: { type: Array, default: () => ([]) },
    values: {
      type: Object,
      default: () => ({})
    },
    FilterValues: {
      type: Object,
      default: () => ({})
    },
  },
  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
  },
  mounted() {
    this.register(this.draw)
  },
  methods: {
    draw() {
      // Check all the necessary values are available
      if(!this.ready2draw) return

      this.clear()
      this.setStyles()
      this.setData()
      
      let dataSet = JSON.parse(JSON.stringify(this.DataItems))

      let cutoff = new Date(this.Queries.SQL2[0].CDATE)
  
      let daterange = [
        {[this.activity]:__M(cutoff).add(-2,'months').startOf('month').format('YYYY-MM-DD')},
        {[this.activity]:__M(cutoff).add(1,'months').endOf('month').format('YYYY-MM-DD')}
      ]

      if (daterange[1][this.activity] < new Date(this.Queries.SQL2[0].CDATE)) daterange[1][this.activity] = new Date(this.Queries.SQL2[0].CDATE)


      if (this.maxValue != 0 ) {
        let fixed_width = 38
        this.skyline.box.width = parseInt((fixed_width * this.maxValue) / 7)
      }  

      console.log(daterange)
      this.setDefaultValues(daterange)   
      this.drawCanvas()
      this.drawChart()
      this.drawTexts()

      
      // 'complete' must be executed to finish the parent process.
      // It sends 'this.localId' & XmlString for some case to make 
      // displaying image easy.
      this.complete()
    
      this.chartMounted = true

    },
    drawChart() {

      this.SkylineCharts = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0.5)`)

      this.chartGroup = this.SkylineCharts
      .append('g')
      .attr('transform', `translate(0,0) scale(${this.scale})`)
      .attr('class', 'svg_skylinechart_group')


      this.draw_Timeline()
      this.draw_Charts()
      this.drawDonuts()
      this.postComplete()

    },
    postComplete() {
      let svg_skylinechart_group = d3.select('.svg_skylinechart_group').node().getBoundingClientRect()

      // console.log('chart box height: ', svg_skylinechart_group)
      // end date가 늘어나면 height이 줄어들면서 diff는 커진다

      let diff = 320 - svg_skylinechart_group.height
      this.diffHeight = diff * 2



      let chart_ = d3.select('#skyline_boxes_timeline').node().getBoundingClientRect()
      let width_ = chart_.width + 330
      let height_ = this.realHeight + 400

      if (width_ < 950) width_ = 1225

      this.resizableCanvasWidth = width_
      this.resizableCanvasHeight = height_
      this.resizeCanvas()

      let dimention = { 
        width: Math.ceil(width_), 
        height: Math.ceil(height_), 
        isreal: true, 
      }
      this.$emit('resize', dimention)
    }
  },
}
</script>