import Defs from '../../../../../../includes/primitives/Color_Defs'

export default {
  data: () => ({
    eqStyle: {
      Stage: [ 
        { code: 'DOL' , name: 'Dress Out Location' , bcolor: '#e3e3e3' },
        { code: 'DO'  , name: 'Dress Out Complete' , bcolor: '#ffc107' },
        { code: 'CFL' , name: 'Crane Final Location', bcolor: '#9693fc' },
        { code: 'EFL' , name: 'Eq. Final Location' , bcolor: '#64d969' },
      ],
      Border: [
        {
          type: 'MEGA',
          shape: 'ellipse',
          width: 35,height: 15,
          color: 'gray',
          stroke: .2,
          textSize: 10,
          padding: 3,
          rx: 10,ry: 4.5,
        },
        {
          type: 'UNIT',
          shape: 'rect',
          width: 30,height: 14,
          color: 'gray',
          stroke: .2,
          textSize: 10,
          padding: 2.5,
        },
      ],
      LoadedTextProps: {
        x: 6.5,
        y: 6.5,
        color: '#757575',
        bColor: '#efefef',
        textSize: 8.5,
        padding: 1,
      },
      CircleBorder: {
        rx: 6.5*3.75,
        ry: 6.5*3.75,
        color: '#757575',
        bcolor: '#efefef',
        stroke: .5*3.75,
        tsize: 8.5*3.75,
        padding: 1*3.75,
      },
      tagControl: {
        labelWidth: 20,
        on: {
          bcolor: '#4FBE79',
          tcolor: '#fff',
          textx: 26,
          texty: 10,
          slidex: 51,
          slidey: 9,
        },
        off: {
          bcolor: '#D3D3D3',
          tcolor: '#707070',
          textx: 37,
          texty: 10,
          slidex: 29,
          slidey: 9,
        }
      },
      // this.eqStyle.critical
      critical: {
        tcolor: {
          p2w: '#ec407a',
          w2w: '#39b1e6',
          nmr: '#e99709',
        }
      },
      search: {
        over: {
          stroke: '#333',
          fill: '#fff',
          color: '#fff'
        },
        out: {
          stroke: '#bcbcbc',
          fill: '#fff',
          color: '#c9c9c9'
        },
        selected: {
          stroke: '#1565c0',
          fill: '#64b5f6',
          color: '#757575'
        }
      },
      legend: {
        labelx1: 20,
        labelx2: 30,
      }
    }
  }),
  methods: {
    setStyles() {
      let lightBlue = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `shadowTimeline`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      lightBlue.append('stop').attr('stop-color', '#3d3d3d').attr('offset', '0').attr('stop-opacity', 0.5)
      lightBlue.append('stop').attr('stop-color', '#fff').attr('offset', '1').attr('stop-opacity', 0.3)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['LightGray'], [`LightGray`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['YellowGreen'], [`YellowGreen`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['LightBlue'], [`LightBlue`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['Pink'], [`Pink`, 0.5, 1.0, 0.5, -0.2], this.localId)
    },
  }
}