export default {
  width: 100,
  gab: 10,
  attrs: [
    { 
      text: 'Total',
      refColumn: 'TOTAL',
      fColor: '#F7BACF', 
      tColor: '#FF75A5'
    },
    { 
      text: 'PO',
      refColumn: 'PO',
      fColor: '#BDBCBC', 
      tColor: '#818181'
    },
    { 
      text: 'FAT',
      refColumn: 'FAT',
      fColor: '#FFDA00',
      tColor: '#FAAA18'
    },
    { 
      text: 'ETA',
      refColumn: 'ETA',
      fColor: '#dce775', 
      tColor: '#fdd835'
    },
    { 
      text: 'Lifting',
      refColumn: 'LIFTING',
      fColor: '#64b5f6', 
      tColor: '#0d47a1'
    },
    { 
      text: 'Install.',
      refColumn: 'INSTALL',
      fColor: '#82B22E', 
      tColor: '#4CAE4E'
    },
  ]
}
