import * as d3 from 'd3'

export default {
  
  methods: {

    /*
      drawLegends()
      drawCriticalPath()
    */

    drawLegends() {

      let s_Legends = this.svgGroup
      .append('g')
      .attr('transform', `translate(${this.styles.margin.left + 10}, ${this.milestone.margin.top + 50})`)
      .style('font-size', 10).style('fill', '#9E9E9E')

      // Milestone
      let s_MS = s_Legends
      .append('g')
      .attr('transform', `translate(0, 0)`)

      s_MS.append('path')
      .attr('transform', `translate(0, 0)scale(1.5)`)
      .attr('d', `${this.milestone.icon.water1.path}`)
      .attr('fill', '#44A9DF')

      s_MS.append('text')
      .attr('x', 23).attr('y', 10)
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Milestone')


      // FSR
      let s_FSR = s_Legends
      .append('g')
      .attr('transform', `translate(90, 10)`)

      s_FSR.append('rect')
      .attr('x', 0).attr('y', -(this.barChart.charts.FSR.size/2)).attr('width', 50)
      .attr('height', this.barChart.charts.FSR.size)
      .attr('fill',   this.barChart.charts.FSR.color)

      s_FSR.append('circle')
      .attr('cx', 0).attr('cy', 0).attr('r', 4).attr('stroke', '#000').attr('stroke-width', 0.5).attr('fill', '#bcbcbc')

      s_FSR.append('circle')
      .attr('cx', 50).attr('cy', 0).attr('r', 4).attr('stroke', '#000').attr('stroke-width', 0.5).attr('fill', '#bcbcbc')

      s_FSR.append('text')
      .attr('x', 60).attr('y', 0).attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('FSR / BED')

      // EXE
      let s_EXE = s_Legends
      .append('g')
      .attr('transform', `translate(220, 10)`)

      s_EXE.append('rect')
      .attr('x', 0).attr('y', -(this.barChart.charts.EXE.size/2)).attr('width', 50)
      .attr('height', this.barChart.charts.EXE.size)
      .attr('fill',   this.barChart.charts.EXE.color)

      s_EXE.append('circle')
      .attr('cx', 0).attr('cy', 0).attr('r', 4).attr('stroke', '#000').attr('stroke-width', 0.5).attr('fill', '#bcbcbc')

      s_EXE.append('circle')
      .attr('cx', 50).attr('cy', 0).attr('r', 4).attr('stroke', '#000').attr('stroke-width', 0.5).attr('fill', '#bcbcbc')

      s_EXE.append('text')
      .attr('x', 60).attr('y', 0).attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Execution')

      // Execution
      let s_CSU = s_Legends
      .append('g')
      .attr('transform', `translate(350, 10)`)

      s_CSU.append('rect')
      .attr('x', 0).attr('y', -(this.barChart.charts.CSU.size/2)).attr('width', 50)
      .attr('height', this.barChart.charts.CSU.size)
      .attr('fill',   this.barChart.charts.CSU.color)
      

      s_CSU.append('circle')
      .attr('cx', 0).attr('cy', 0).attr('r', 4).attr('stroke', '#000').attr('stroke-width', 0.5).attr('fill', '#bcbcbc')

      s_CSU.append('circle')
      .attr('cx', 50).attr('cy', 0).attr('r', 4).attr('stroke', '#000').attr('stroke-width', 0.5).attr('fill', '#bcbcbc')

      s_CSU.append('text')
      .attr('x', 60).attr('y', 0).attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('CSU (post-MC scope)')
      

      // Critical Path
      let s_Critical = s_Legends
      .append('g')
      .attr('transform', `translate(530, 10)`)

      s_Critical.append('line')
      .attr('x1', 0).attr('y1', 0)
      .attr('x2', 50).attr('y2', 0)
      .attr('stroke-width', 3).attr('stroke', 'red').attr('opacity', 1)
      .style("stroke-dasharray", ("10, 5"))

      s_Critical.append('text')
      .attr('x', 60).attr('y', 0).attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Critical Path)')
    }, 
    
  }
}