import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  methods: {
    drawDonuts() {
      if(!this.DataItems || this.DataItems.length === 0) return

      let value_ = this.DataItems[0]

      let arcProps = {
        angleSize: 360,
        angleStart: 0,
        bColor: '#F4F5F5',
        stroke: 10,
        Ra: 50
      }
      
      
      let donutGroup = this.svg
      .append('g')
      .attr('class', '_donut_group__for_all')
      .attr('transform', `translate(80, 60)`)


      let arc = d3.arc()
        .outerRadius(d => d.Ra)
        .innerRadius(d => d.Ra - d.stroke)
        .startAngle(d => d.angleStart * Math.PI / 180)
        .endAngle(d => d.angleEnd || d.angleStart * Math.PI / 180)
        let donut_ = donutGroup
        .append('g')
        .attr('class', `_donut_chart__${value_.CATEGORY}`)
        .attr('transform', `translate(10, 0)`)

        // Draw Background Donut Chart
        donut_
        .append('circle')
        .attr('cx', 0).attr('cy', 0).attr('r', arcProps.Ra-3)
        .attr('stroke', arcProps.bColor).attr('stroke-width', arcProps.stroke)
        .attr('fill', 'url(#lightGray)')


        let circleRate = value_['TOTAL'] ? (value_['COMPLETED'] || 0) / value_['TOTAL'] : 0


        // Draw Foreground Donut Chart
        donut_
        .datum(arcProps)
        .append('path')
        .attr('d', arc)
        .attr('fill', value_.COLOR)
        .attr('opacity', 0.5)
        .transition()
        .duration(1500)
        .attrTween('d',(d) => {
          let endAngle_ = ((d.angleSize * circleRate) + d.angleStart) * Math.PI / 180
          let interpolate = d3.interpolate(d.angleStart * Math.PI / 180, endAngle_)
          return function(t) {
            d.angleEnd = interpolate(t)
            return arc(d);
          }
        })

        donut_
        .append('text')
        .attr('transform', `translate(0, 1)`)
        .style('font-family', 'roboto')
        .style('font-size', 14)
        .style('fill', value_.COLOR)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(value_['PROG'] ? value_['PROG'] + '%' : 0 + '%')


        
      // Draw Summary Table
      let summaryGroup = this.svg
      .append('g')
      .attr('transform', 'translate(10, 120)')

      
      summaryGroup.append('rect').attr('transform', `translate(0, 4)`)
      .style('width', 50).style('height', 22).style('fill', '#E0E0DF').attr('rx', 3).attr('ry', 3)

    
      summaryGroup.append('text')
      .attr('transform', 'translate(25, 20)')
      .style('font-family', 'roboto').style('font-size', 12).style('fill', '#333333').style('text-anchor', 'middle')
      // .style('font-weight', 'bold')
      .text(value_.CATEGORY)

      let summary = summaryGroup.append('g').attr('transform', 'translate(0, 30)')
      
      summary.append('rect').attr('transform', `translate(0, 0)`)
      .style('width', 155).style('height', 40).style('fill', '#A8E0F8').attr('rx', 3).attr('ry', 3)

      // Titles
      summary.append('text')
      .attr('transform', 'translate(32, 15)')
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#757575').style('text-anchor', 'end')
      .text('Total')

      summary.append('text')
      .attr('transform', 'translate(95, 15)')
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#757575').style('text-anchor', 'end')
      .text('Complete')
      
      
      summary.append('text')
      .attr('transform', 'translate(146, 15)')
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#757575').style('text-anchor', 'end')
      .text('Remain')


      
      // data Summary Table Not Used Common Chart
      if (value_) {

        summary.append('text')
        .attr('transform', 'translate(32, 32)')
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        .text(value_.TOTAL)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100).style('fill', '#fff')
        })
        .on('click', () => {
          // Not working
          // let data = {CATEGORY : value_.CATEGORY, SUBSYSTEM: this.FilterValues.SUBSYSTEM, LOOPTEST: 'Y'}
          // let request_ = this.getRequestColumnProps('requestdata', data, this.ColumnProps)
          // console.log(request_)
          // this.$emit('request-action', request_)


          let request_ = {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: 255,
              no: ''
            },
            filters     : {CATEGORY : value_.CATEGORY == 'QCF&A' ? 'A' : value_.CATEGORY, SUBSYSTEM: this.FilterValues.SUBSYSTEM, LOOPTEST: 'Y'},
            iFilters    : {
              filterString : '',
              inputFilter  : ''
            }
          }
          this.$emit('request-action', request_)

        })


        summary.append('text')
        .attr('transform', 'translate(92, 32)')
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        .text(value_.COMPLETED)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100).style('fill', '#fff')
        })
        .on('click', () => {
          // let data = {CATEGORY : value_.CATEGORY, SUBSYSTEM: this.FilterValues.SUBSYSTEM, LOOPTEST: 'Y', STATUS_CODE:'C'}
          // let request_ = this.getRequestColumnProps('status', data, this.ColumnProps)
          // this.$emit('request-action', request_)

          let request_ = {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: 255,
              no: ''
            },
            filters     : {CATEGORY : value_.CATEGORY == 'QCF&A' ? 'A' : value_.CATEGORY, SUBSYSTEM: this.FilterValues.SUBSYSTEM, LOOPTEST: 'Y', STATUS_CODE:'C'},
            iFilters    : {
              filterString : '',
              inputFilter  : ''
            }
          }
          this.$emit('request-action', request_)
        })

        summary.append('text')
        .attr('transform', 'translate(146, 32)')
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        .text(value_.REMAIN)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100).style('fill', '#fff')
        })
        .on('click', () => {
          // let data = {CATEGORY : value_.CATEGORY, SUBSYSTEM: this.FilterValues.SUBSYSTEM, LOOPTEST: 'Y', STATUS_CODE:'N'}
          // let request_ = this.getRequestColumnProps('status', data, this.ColumnProps)
          // this.$emit('request-action', request_)

          let request_ = {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: 255,
              no: ''
            },
            filters     : {CATEGORY : value_.CATEGORY == 'QCF&A' ? 'A' : value_.CATEGORY, SUBSYSTEM: this.FilterValues.SUBSYSTEM, LOOPTEST: 'Y', STATUS_CODE:'N'},
            iFilters    : {
              filterString : '',
              inputFilter  : ''
            }
          }
          this.$emit('request-action', request_)
        })
      }
    }
  }
}
