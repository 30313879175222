import * as d3 from 'd3'
// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  data:() => ({
    thead: [
      { key: 'COMMENT', html: 'Comment', row: 1 },
      { key: 'Blocker', html: 'Blocker', row: 1 },
      { key: 'Blocker Description', html: 'Blocker Description', row: 1 },
      { key: 'Forecast Date', html: 'Forecast Date', row: 1 },
    ],
    theadrows: 1,
    tbody: [
      // { key: 'IDX', w: 40, align: 'center'},
      { key: 'COMMENT', w: 720, align: 'left', overflow: true},
      // { key: 'EXT_COMMENT', w: 230, align: 'left', overflow: true}
    ],
    width: 720,

    scrollProps: {
      w: 730,
      h:400,
      g: 0,
      bar: {
        align : 'right',
        gap   : 3,
        radius: 2,
        width : 6,
        fill  : '#B3E5FC',
        stroke: '#B3E5FC',
      }
    },
    filteredList: null
  }),
  methods: {
    drawBttons() {

      let buttonWrapper = this.svg.append('g')
      .attr('class', 'button_wrapper')
      .attr('transform', `translate(0.5, 20.5)`)

      buttonWrapper
      .append('rect')
      .attr('x', 0).attr('y', 3)
      .attr('width',690).attr('height',13)
      .attr('fill', '#B3E5FC')
      //.attr('rx', 2).attr('ry', 2).attr('stroke', '#8BC34A').attr('stroke-width',1)

      // Button Comment ---------------------------------
      let buttonComment = buttonWrapper.append('g')
      .attr('class', 'request_comment_box')
      .attr('transform', `translate(700.5, 0.5)`)

      buttonComment
      .append('path')
      .attr('d', 'M20,2A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H6L2,22V4C2,2.89 2.9,2 4,2H20M4,4V17.17L5.17,16H20V4H4M6,7H18V9H6V7M6,11H15V13H6V11Z')
      .attr('fill', '#039BE5')
      .style('opacity', .6)

      buttonComment
      .append('circle')
      .attr('cx', 12)
      .attr('cy', 11)
      .attr('r', 14)
      .style('fill', 'transparent')
      .style('cursor', 'pointer')
      .on('mouseover', () => { d3.select(`#${this.localId}`).select('.request_comment_box').select('path').transition().duration(100).style('opacity', 1) })
      .on('mouseout', () => { d3.select(`#${this.localId}`).select('.request_comment_box').select('path').transition().duration(100).style('opacity', .6) })
      .on('click', () => {
        // if (!this.account__) return
        // let fName = this.Queries.SQL1.find(f => f.EMAIL == this.account__.email)
        // if (!fName) return

        let req = this.getRequestColumnProps('comment-service', null, this.ColumnProps)
        if(req) this.$emit('request-action', req)
      })


    },
    drawTable() {
      let tableWrapper = d3.select(`#${this.localId}`).select('.table_wrapper')
      if(tableWrapper) tableWrapper.remove()

      tableWrapper = this.svg.append('g')
      .attr('class', 'table_wrapper')
      .attr('transform', `translate(0, 20)`)
      
      this.filteredList = this.dataSet

      let list = this.filteredList

      let group = tableWrapper.append('g').attr('class', 'table_group__huc')
      let table_group = group.append('g').attr('transform', `translate(0.5, 30)`)
      
      
      
      let y_ = (this.DataItems.length *10 ) + 26.5
      if(y_>=500){
        // Set Scroll
        this.setScroll(table_group, this.scrollProps)
      }

      let table_obj = table_group.append('foreignObject')
        .attr('class', 'foreignObject_table_offplot')
        .attr('y', 0)
        .attr('width', '100%')
        .attr('height', `${y_}px`)
        // .attr('height', `100%`)
        .append('xhtml:body')
        .style('position', 'fixed')

      const reducer = (previousValue, currentValue) => previousValue + currentValue
      let widths = this.tbody.map(d => d.w)
      let sumWidth = widths.reduce(reducer)

      let table = table_obj.append('table')
      .attr('id', 'request_comment_box_table_offplot')
      .style('border-collapse', 'collapse')
      .style('font-size', '10px')
      .style('width', `${sumWidth}px`)
      .style('table-layout', 'fixed')

      let colgroup = table.append('colgroup')
      this.tbody.forEach(k => {
        colgroup.append('col').style('width', k.w + 'px')
      })

      // let thead = table.append('thead')
      // for (let r = 1; r <= this.theadrows; r++) {
      //   let row = this.thead.filter(d=> d.row === r)
      //   let tr = thead.append('tr')

      //   row.forEach(item => {
      //     tr.append('th')
      //     .attr('class', 'request_comment_box_table_group__th')
      //     .attr('rowspan', () => item.rowspan ? item.rowspan : '')
      //     .attr('colspan', () => item.colspan ? item.colspan : '')
      //     .style('height', '20px')
      //     .style('padding', '3px')
      //     .style('background', item.color ? item.color : '#FAFAFA')
      //     .html(item.html)
      //   })        
      // }

      let tbodykeys = this.tbody
      let tbody = table.append('tbody').attr('id', 'request_comment_box_tbody_')

      for (let r = 0; r < list.length; r++) {
        let tr = tbody.append('tr')

        tbodykeys.forEach((k, i) => {

          let td = tr.append('td')
            .style('border', 'solid 0.1px #E1F5FE')
            .style('height', '10px')
            .style('text-align', k.align ? k.align : 'center')
            .style('color', '#000000')
            .style('padding', '0 3px 0 3px')

          // if(k.overflow) {
          //   td.style('white-space', 'nowrap')
          //     .style('overflow', 'hidden')
          //     .style('text-overflow', 'ellipsis')
          //     .attr('title', list[r][k.key])
          // }

          // if (i == 0) {
          //   td.html(list.length - r)
          // }else 
          // {
            td.html(list[r][k.key])
          // }

        })
      }

      this.getTableheight()
    },
    getTableheight() {
      let table_ = d3.select('#request_comment_box_table_offplot').node().getBoundingClientRect()
      d3.select(`#${this.localId}`).select('.foreignObject_table_offplot').attr('height', `${table_.height + 5}px`)
    }
  }
}
