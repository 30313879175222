import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({
    icons: [
      {code: 'ST010', name: 'SteelCutting',  schedule: 'start',  activity: 'Block Pre-Fabrication'        , center: 7, width: 16, height: 16},
      {code: 'ST030', name: 'Assembly',      schedule: 'start',  activity: 'Block Assembly'               , center: 6, width: 15, height: 50},
      {code: 'ST080', name: 'Crain',         schedule: 'finish', activity: 'Block Erection'               , center: 6, width: 20, height: 40},
      {code: 'PI030', name: 'Spool',         schedule: 'start',  activity: 'Piping Installation'          , center: 6, width: 18, height: 21},
      {code: 'EI060', name: 'Pulling',       schedule: 'start',  activity: 'Cable Tray'                   , center: 6, width: 18, height: 20},
      {code: 'MC',    name: 'MC',            schedule: 'finish', activity: 'Hydro Test'                   , center: 6, width: 20, height: 20},
      {code: 'OT090', name: 'RollPainting',  schedule: 'start',  activity: 'Structure Painting'           , center: 7, width: 20, height: 20},
    ],
  }),
  methods: {
    draw_TimelineModule() {
      // For the Timeline
      let ModuleLine = 50
      // To draw Gantt Chart
      let baseGap = 35

      this.level1Names.forEach(name => {
        let level2Names__ = [...new Set(this.DataItems.filter(d => d.FILTER == name).map(d => d.MODULE))]

        level2Names__.forEach(module => {
          let level3Items__ = this.DataItems.filter(d => d.FILTER == name && d.MODULE == module)

          let scheduleMinA = new Date(Math.min(...level3Items__.map(item => new Date(item.ACTUAL_SD)))) //timeline의 ACTUAL_SD와 PLAN_SD 비교
          let scheduleMin = new Date(Math.min(...level3Items__.map(item => new Date(item.PLAN_SD))))
          let scheduleMax = new Date(Math.max(...level3Items__.map(item => new Date(item.PLAN_FD))))

          scheduleMin = scheduleMinA < scheduleMin ? scheduleMinA : scheduleMin //timeline이 ACTUAL_SD가 먼저 나오니까 먼저 그려져야한다

          let modStartMonth_ = new Date(scheduleMin.setMonth(scheduleMin.getMonth() - 1))
          let modStartDate_ = new Date(modStartMonth_.getFullYear(), modStartMonth_.getMonth(), 1, 0, 0, 0)
          let modEndMonth_ = new Date(scheduleMax.setMonth(scheduleMax.getMonth() + 1))
          modEndMonth_ = new Date(new Date(modEndMonth_.getFullYear(), modEndMonth_.getMonth() + 1, 1, 0, 0, 0) - 1)
          let modEndDate_ = new Date(new Date(modEndMonth_).setMonth(modEndMonth_.getMonth() + 1) - 1)
    
          let noOfMonth = modEndDate_.getMonth() - modStartDate_.getMonth()
          let noOfYear = modEndDate_.getYear() - modStartDate_.getYear()
          noOfMonth = noOfMonth + (noOfYear*12)

          // ### Set Timeline-Values for the Month ### --------------------------
          let tickValues_ = []

          for(var i=0; i<=noOfMonth; i++) {
            let _nextMonthDate__ = new Date(new Date(modStartDate_).setMonth(modStartDate_.getMonth() + i))

            // For the Month ---
            let endMonth_ = new Date(new Date(_nextMonthDate__).setMonth(_nextMonthDate__.getMonth() + 1) - 1)
            let midDate = new Date(
              d3.mean([
                _nextMonthDate__, // 1st date of the month
                endMonth_         // last date of the month
              ])
            )
            tickValues_.push({ 
              name: d3.timeFormat('%b')(midDate), 
              value: new Date(midDate),
              endDate: endMonth_
            })
          }

          // ### Set Timeline-Values for the Month ### --------------------------
          let Timeline = this.modules2020
          .append('g')
          .attr('transform', `translate(0, ${ModuleLine})`)

          let startX = this.timeScale(modStartDate_)
          let endX = this.timeScale(modEndDate_)
          let timelineWidth = endX - startX
    
          Timeline
          .append('path') // shadow
          .attr('d', `M${startX + 50},10 H ${endX - 50} L ${endX},20 H${startX} Z`).attr('fill', `url(#shadowTimeline)`) 

          Timeline
          .append('rect')
          .attr('x', startX).attr('y', 0).attr('width', timelineWidth).attr('height', 15).attr('fill', `url(#${this.localId}__LinearA4LightGray0)`)

          // Timeline Text using the scale function
          // Month
          Timeline
          .append('g')
          .selectAll('text')
          .data(tickValues_)
          .enter()
          .append('text')
          .attr('x', d => this.timeScale(d.value))
          .attr('y', 8).style('font-size', 10).style('fill', '#333')
          .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
          .text(d => d.name.toUpperCase())

          // Timeline Seperator
          Timeline
          .append('g')
          .selectAll('path')
          .data(tickValues_)
          .enter()
          .append('path')
          .attr('d', (d,i) => {
            if (tickValues_.length-1 > i) {
              return `M${this.timeScale(d.endDate)}, 4 V12`
            }
          })
          .attr('stroke', '#757575').attr('stroke-width', 0.3)
              

          // ### Draw Gantt Chart ##################
          let barCharts = this.modules2020
          .append('g')
          .attr('transform', `translate(0, ${ModuleLine})`)

          level3Items__.forEach((item, i) => {
            let icon = this.icons.find(icon => icon.activity == item.WCDESCRIPTION)
            if(!icon) return

            if(item.ACTUAL_SD && item.ACTUAL_FD) icon.status = 'Completion'
            else if(item.ACTUAL_SD && !item.ACTUAL_FD) icon.status = 'Progress'
            else if(!item.ACTUAL_SD && !item.ACTUAL_FD) icon.status = 'NotStart'
            icon.planProg = item.PLAN_PROG
            icon.actualProg = item.ACTUAL_PROG
                
            let PS = new Date(item.PLAN_SD)
            let PF = new Date(item.PLAN_FD)
            let AS = icon.status == 'NotStart' ? null : new Date(item.ACTUAL_SD)
            let AF = icon.status == 'Progress' ? new Date(item.CUTOFF) : (icon.status == 'NotStart' ? null : new Date(item.ACTUAL_FD))

            let StartLine = (i * baseGap) + 40
            let schedule = ''
          
            if (icon.status == 'Completion') {
              if (icon.schedule == 'start') schedule = this.timeScale(AS); else schedule = this.timeScale(AF)
            } else {
              if (icon.schedule == 'start') schedule = this.timeScale(PS); else schedule = this.timeScale(PF)
            }

            if (icon.name == 'RFS') {
              if (icon.status == 'Progress') 
                // AF = Cutoff
                schedule = this.timeScale(AS); else schedule = this.timeScale(AF)
            }

            barCharts
            .append('image') 
            .attr('xlink:href', require(`../../../../../../src/assets/svg/icons/${icon.name}.svg`))
            .attr('x', schedule - icon.width/2).attr('y', - icon.height).attr('width', icon.width).attr('height', icon.height)

            barCharts
            .append('text') 
            .attr('x', schedule).attr('y', -icon.height - 20).style('font-size', 9).style('fill', '#333').attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging').text(icon.activity)

            barCharts
            .append('text')
            .attr('x', schedule).attr('y', -icon.height - 9).style('font-size', 8.5).style('fill', '#bcbcbc').attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging')
            .text(() => {
              if (icon.schedule == 'start') {
                if (icon.status == 'Completion') return d3.timeFormat('%Y-%m-%d')(AS)
                else return d3.timeFormat('%Y-%m-%d')(PS)
              } else if (icon.schedule == 'finish') {
                if (icon.status == 'Completion') return d3.timeFormat('%Y-%m-%d')(AF)
                else return d3.timeFormat('%Y-%m-%d')(PF)
              }
  
              if (icon.activity == 'RFS') {
                if (icon.status == 'Progress') return d3.timeFormat('%Y-%m-%d')(AS)
                else return d3.timeFormat('%Y-%m-%d')(PS)
              }
            })

            barCharts
            .append('line').attr('x1', this.timeScale(PS)).attr('x2', this.timeScale(PS)).attr('y1', StartLine).attr('y2', 10).attr('stroke', '#bcbcbc').attr('stroke-width', 0.3)
            barCharts
            .append('line').attr('x1', this.timeScale(PF)).attr('x2', this.timeScale(PF)).attr('y1', StartLine).attr('y2', 10).attr('stroke', '#bcbcbc').attr('stroke-width', 0.3)
  
            // draw Actual bar chart & Date ------------------------------------------------------------------------------------------------------------
            // if (icon.schedule !== 'event') {
              this.draw_PlanChart(barCharts, icon.activity, StartLine, PS, PF) // Plan

              if (icon.status == 'Completion') {
                this.draw_Actual_Completion(barCharts, icon.activity, StartLine, AS, AF) // --->
              }
              if (icon.status == 'Progress') {
                this.draw_Actual_Progress(barCharts, icon, StartLine, AS) // --->
              }
            // } else {
            //   barCharts
            //   .append('text') // Activity
            //   .attr('x', this.timeScale(PS)).attr('y', StartLine + 2).style('font-size', 9).style('fill', '#333').attr('text-anchor', 'middle').attr('alignment-baseline', 'ideographic')
            //   .text(icon.activity)
              
            //   barCharts
            //   .append('text') // Plan Start Date
            //   .attr('x', this.timeScale(PS)).attr('y', StartLine + 9 ).style('font-size', 9).style('fill', '#bcbcbc').attr('text-anchor', 'middle')
            //   .text(`${d3.timeFormat('%d/%m')(PS)}`)
  
            //   barCharts
            //   .append('image') 
            //   .attr('xlink:href', require(`../../../../../../src/assets/svg/icons/event_Gray.svg`))
            //   .attr('x', this.timeScale(PS) -6).attr('y', StartLine + 10).attr('width', 12).attr('height', 12)
                
            //   if (icon.status !== 'Progress') this.draw_ModuleInfo(barCharts, item, endX - 70, StartLine + 100)
            //   if (icon.status == 'Progress') {
            //     barCharts
            //     .append('text') // Activity
            //     .attr('x', this.timeScale(AS)).attr('y', StartLine + 2).style('font-size', 9).style('fill', '#333').attr('text-anchor', 'middle').attr('alignment-baseline', 'ideographic')
            //     .text(icon.activity)
  
            //     barCharts
            //     .append('text') // Actual Start
            //     .attr('x', this.timeScale(AS)).attr('y', StartLine + 9 ).style('font-size', 9).style('fill', '#818181').attr('text-anchor', 'middle')
            //     .text(`${d3.timeFormat('%d/%m')(AS)}`)
  
            //     barCharts
            //     .append('image') 
            //     .attr('xlink:href', require(`../../../../../../src/assets/svg/icons/event_Green.svg`))
            //     .attr('x', this.timeScale(AS) -6).attr('y', StartLine + 10).attr('width', 12).attr('height', 12)
        
            //     this.draw_ModuleInfo(barCharts, item, endX - 70, StartLine + 100)
            //   }
            // }

            // if(i === 0) this.draw_ModuleInfo(barCharts, item, endX - 80, StartLine + 180)

            StartLine += baseGap
          })

          ModuleLine += 400
        })
      })
    }, 
  }
}
