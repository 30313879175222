import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {

    
    draw_Overall() {

      this.loadSvg(0, 0, '/TORTUE/Common/Project_Logo_r1.svg')
      //-----------------------------------pdf object로 넣어줘야한다//
      let dStage = {
        level:  0,
        stage: 'Rooms'
      }
      this.selectedItems.push(dStage)
      //-----------------------------------pdf//
      let Rooms = this.svg
      Rooms
      .append('text')
      .attr('transform', `translate(30, 90)`)
      .style('font-family', 'roboto').style('font-size', 16).style('fill', '#333').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Equipment Room Layout -')
      Rooms
      .append('text')
      .attr('id', 'Title')
      .attr('transform', `translate(220, 90)`)
      .style('font-family', 'roboto').style('font-size', 16).style('fill', '#44A9DF').attr('text-anchor', 'start').attr('alignment-baseline', 'middle').attr('font-weight', 400)
      .text('Rooms')
      

      // Keyplan
      let Keyplan = Rooms
      .append('g')
      .attr('id', 'Keyplan_Group')
      .attr('transform', `translate(20, 130)`)
      .style('visibility', 'hidden')
      .style('font-family', 'roboto').style('font-size', 7.5).style('fill', '#333').attr('text-anchor', 'middle')

      Keyplan
      .append('image')
      .attr('id', 'Keyplan')
      // .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Rooms/Keyplan/TORTUE.jpg`)
      .attr('transform', `translate(10, 0)`)
      // Keyplan
      // .append('text')
      // .attr('transform', `translate(120, 170)`)
      // .style('font-size', 14).attr('font-weight', 400).text('Keyplan')

      // Legends
      Rooms
      .append('image')
      .attr('id', 'Legends')
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/SVG/Legends.svg`)
      .attr('transform', `translate(45.5,360.5)`).style('visibility', 'hidden').style('opacity', 1)

      //button Menu_Back
      let Menu_Back_Wrap = this.svg

      .append('g')
      .attr('id', 'Menu_Back')
      .style('visibility', 'hidden')
      .attr('opacity', 1)
      .attr('transform', `translate(330, 120)`)

      let Menu_Back = Menu_Back_Wrap

      Menu_Back
      .append('image')
      .attr('class', 'Back_Btn')
      .attr('transform', `translate(0, 0)`)
      .style('cursor', 'pointer')
      .attr('xlink:href', require('../../../../../../src/assets/svg/icons/back_btn.svg'))
      .call(this.btnGoBack) // --------------------------------------------------------------## btnGoBack

      this.Activate_Overall()
    },
    Activate_Overall() {

      this.Activate_OverallnMasks()

      d3.select(`#LQ01`).transition().duration(500).style('visibility', 'visible').style('opacity', 1)
      d3.select(`#LQ02`).transition().duration(500).style('visibility', 'visible').style('opacity', 1)
      d3.select(`#LQ06`).transition().duration(500).style('visibility', 'visible').style('opacity', 1)
      d3.select(`#FOR`).transition().duration(500).style('visibility', 'visible').style('opacity', 1)
      d3.select(`#ME05DB`).transition().duration(500).style('visibility', 'visible').style('opacity', 1)
      d3.select(`#ME05DA`).transition().duration(500).style('visibility', 'visible').style('opacity', 1)
      d3.select(`#MS01`).transition().duration(500).style('visibility', 'visible').style('opacity', 1)
      d3.select(`#MS03`).transition().duration(500).style('visibility', 'visible').style('opacity', 1)

      this.displayGroupID(`#Mask_Overall`, 10, 10, 0)

      // ### Call Canvas ###
      this.drawProgress('Rooms') 
    },

    Activate_OverallnMasks() {

      this.Rooms_Overall = this.svg.append('svg')

      this.Rooms_Overall
      .attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight)
      .style('opacity', 1)
      .attr('x', 0).attr('y', 0)

      this.Rooms_Overall
      .append('image')
      .attr('id', 'LQ01')
      .attr('transform', `translate(950, 840) scale(0.20)`)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Rooms/LQ01.png`)
      .style('visibility', 'hidden').style('opacity', 0)

      this.Rooms_Overall
      .append('image')
      .attr('id', 'LQ02')
      .attr('transform', `translate(950, 610) scale(0.20)`)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Rooms/LQ02.png`)
      .style('visibility', 'hidden').style('opacity', 0)

      this.Rooms_Overall
      .append('image')
      .attr('id', 'LQ06')
      .attr('transform', `translate(950, 380) scale(0.20)`)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Rooms/LQ06.png`)
      .style('visibility', 'hidden').style('opacity', 0)

      this.Rooms_Overall
      .append('image')
      .attr('id', 'FOR')
      .attr('transform', `translate(360, 380) scale(0.18)`)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Rooms/FOR.png`)
      .style('visibility', 'hidden').style('opacity', 0)

      this.Rooms_Overall
      .append('image')
      .attr('id', 'MS03')
      .attr('transform', `translate(380, 840) scale(0.20)`)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Rooms/MS03.png`)
      .style('visibility', 'hidden').style('opacity', 0)

      this.Rooms_Overall
      .append('image')
      .attr('id', 'MS01')
      .attr('transform', `translate(380, 610) scale(0.22)`)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Rooms/MS01.png`)
      .style('visibility', 'hidden').style('opacity', 0)

      this.Rooms_Overall
      .append('image')
      .attr('id', 'ME05DB')
      .attr('transform', `translate(950, 150) scale(0.21)`)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Rooms/ME05DB.png`)
      .style('visibility', 'hidden').style('opacity', 0)

      this.Rooms_Overall
      .append('image')
      .attr('id', 'ME05DA')
      .attr('transform', `translate(380, 150) scale(0.21)`)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Rooms/ME05DA.png`)
      .style('visibility', 'hidden').style('opacity', 0)
    
      // Goto_Event
      this.Rooms_Overall
      .append('polygon')
      .attr('id', 'Mask_LQ01')
      .attr('transform', `translate(937, 820) scale(0.9)`)
      .attr('points', `0,70.875 77.25,12.75 84.125,0 148.5,41.5 172.25,47.75 221,95.25 155.375,149 139.75,144 110.5,165.25 20.375,110.875 4.125,114`)
      .attr('fill', '#fff').style('opacity', 0).style('cursor', 'pointer')
      // .style('visibility', 'hidden')
      .call(s => { this.Goto_Activate(s, 'Mask_LQ01', 'LQ01') })

      this.Rooms_Overall
      .append('polygon')
      .attr('id', 'Mask_LQ02')
      .attr('transform', `translate(937, 590) scale(0.9)`)
      .attr('points', `2,95.313 41,62.75 41,38 88.5,2.75 159.125,39 205,82.125 202.25,105.875 95.25,191 2,125.875`)
      .attr('fill', '#fff').style('opacity', 0).style('cursor', 'pointer')
      .call(s => { this.Goto_Activate(s, 'Mask_LQ02', 'LQ02') }) // <--------

      this.Rooms_Overall
      .append('polygon')
      .attr('id', 'Mask_LQ06')
      .attr('transform', `translate(950, 355) scale(0.9)`)
      .attr('points', `0,63.375 83.5,0 199,80.875 108.5,150.875 104.125,184 0,111.5 `)
      .attr('fill', '#fff').style('opacity', 0).style('cursor', 'pointer')
      .call(s => { this.Goto_Activate(s, 'Mask_LQ06', 'LQ06') }) // <--------

      this.Rooms_Overall
      .append('polygon')
      .attr('id', 'Mask_FOR')
      .attr('transform', `translate(350, 370) scale(0.7)`)
      .attr('points', `0,81.5 99.75,0 267,118.375 263.5,139 172.875,220 0,115.25`)
      .attr('fill', '#fff').style('opacity', 0).style('cursor', 'pointer')
      .call(s => { this.Goto_Activate(s, 'Mask_FOR', 'FOR') }) // <--------

      this.Rooms_Overall
      .append('polygon')
      .attr('id', 'Mask_MS03')
      .attr('transform', `translate(360, 830) scale(0.9)`)
      .attr('points', `0,82.75 107,0 140.375,23.375 136.625,36.5 210.375,90 169.75,169.625 105.188,180 17.25,119 5.375,123.375`)
      .attr('fill', '#fff').style('opacity', 0).style('cursor', 'pointer')
      .call(s => { this.Goto_Activate(s, 'Mask_MS03', 'MS03') }) // <--------

      this.Rooms_Overall
      .append('polygon')
      .attr('id', 'Mask_MS01')
      .attr('transform', `translate(360, 590) scale(0.9)`)
      .attr('points', `4.75,69.188 90.375,0.094 199,74 199,110 182.25,132.75 163.5,137.125 114.125,171 57.875,151.5 37.875,137.75 0,97.75`)
      .attr('fill', '#fff').style('opacity', 0).style('cursor', 'pointer')
      .call(s => { this.Goto_Activate(s, 'Mask_MS01', 'MS01') })// <--------

      this.Rooms_Overall
      .append('polygon')
      .attr('id', 'Mask_ME05DB')
      .attr('transform', `translate(937, 130) scale(0.9)`)
      .attr('points', `0,81.5 105.375,0 181,54 181,84 77.25,168 0,117.125`)
      .attr('fill', '#fff').style('opacity', 0).style('cursor', 'pointer')
      .call(s => { this.Goto_Activate(s, 'Mask_ME05DB', 'ME05DB') }) // <--------

      this.Rooms_Overall
      .append('polygon')
      .attr('id', 'Mask_ME05DA')
      .attr('transform', `translate(360, 140) scale(0.9)`)
      .attr('points', `0,94.625 122.25,0 189.125,27.125 205.375,47.313 213,70.25 209.75,79.625 97.875,171 2.875,106.5 `)
      .attr('fill', '#fff').style('opacity', 0).style('cursor', 'pointer')
      .call(s => { this.Goto_Activate(s, 'Mask_ME05DA', 'ME05DA') }) // <-------
      
    },

  }
}
           