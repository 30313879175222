import * as d3 from 'd3'

export default {
  methods: {

    draw_Header(selection, unit, x_, y_, idx_) {

      let UnitHeader = selection
      .append('g')
      .attr('transform', `translate(${x_}, ${y_})`)
      .attr('font-family', 'Segoe UI')

      let UnitName = UnitHeader
      .append('g')
      .attr('transform', `translate(0, 10)`)
      .attr('opacity', 1).attr('cursor', 'pointer')
      .on('mouseover', function() {
        d3.select(this).transition().duration(150).attr('opacity', .5)
      })
      .on('mouseout', function() {
        d3.select(this).transition().duration(150).attr('opacity', 1)
      })
      .on('click', ()=> {
        this.redraw_Overall(unit, idx_) // ---> redraw
      })

      UnitName
      .append('text')
      .attr('id', `UNIT_${idx_}`)
      .attr('x', -30).attr('y', -151)
      .attr('font-size', 14).attr('fill', '#333').attr('font-weight', 500)
      .text(`${unit.UNIT}`)

      UnitName
      .append('text')
      .attr('x', -30).attr('y', -138).attr('font-size', 9).attr('fill', '#bcbcbc')
      .text(`${unit.UNIT_DESC}`)

      UnitName
      .append('text')
      .attr('x', -30).attr('y', -125).attr('font-size', 11).attr('fill', '#757575')
      .text(unit.MAX_PLAN)



      // Draw Header Line Elements ---------------------------------------------------------
      let unitlen = this.getNodeElValue( `#UNIT_${idx_}`, 'width') + 15 - 40
      UnitHeader
      .append('line')
      .attr('x1', unitlen).attr('y1', -145).attr('x2', 1220).attr('y2', -145)
      .attr('stroke', '#BCBCBC').attr('stroke-width', 1).attr('stroke-opacity', .2)

      UnitHeader
      .append('rect')
      .attr('x', 468).attr('y', -153)
      .attr('width', 90).attr('height', 16)
      .attr('fill', '#fff').attr('stroke', '#757575').attr('stroke-opacity', .2)

      // System No
      UnitHeader
      .append('text')
      .attr('id', `SYS_${idx_}`) 
      .attr('x', 480).attr('y', -142)
      .attr('font-size', 11).attr('fill', '#333').attr('text-anchor', 'start')
      .text(`Overall Status`)

      // A ITRs
      UnitHeader
      .append('rect')
      .attr('x', 774).attr('y', -153)
      .attr('width', 83).attr('height', 15)
      .attr('fill', '#fff').attr('stroke', '#757575').attr('stroke-opacity', .2)

      // B ITRs
      UnitHeader
      .append('rect')
      .attr('x', 1054).attr('y', -153)
      .attr('width', 83).attr('height', 15)
      .attr('fill', '#fff').attr('stroke', '#757575').attr('stroke-opacity', .2)

      // Walk down / Turnover
      UnitHeader
      .append('text')
      .attr('x', 250).attr('y', -128)
      .attr('font-size', 10).attr('fill', '#757575')
      .text(`Walked down: ${unit.SSMCC_WD}`)

      UnitHeader
      .append('text')
      .attr('x', 350).attr('y', -128)
      .attr('font-size', 10).attr('fill', '#757575')
      .text(`Turned over: ${unit.SSMCC_ACT}`)
    },

  }
}