import * as d3 from 'd3'

export default {
  watch: {
    DataItems: { 
      handler(val) { 
        if(!val || val.length === 0) return

        this.register(this.draw)
      }, 
      deep: true 
    },
    diffHeight(val) {
      d3.select('.svg_skylinechart_group').attr('transform', `translate(0,${val}) scale(${this.scale})`)
    }
  },
}
