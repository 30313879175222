import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {

    Draw_Charts() {
      
      let data = null, 
          style = null

      let AitrDiscipline= this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0.5)`).style('font-family','roboto')

      // let overallText = AitrDiscipline
      // .append('g')
      // .attr('transform', `translate(40, 10)`)
      // .style('font-size',12).style('font-weight','bold')
      // .append('text')
      // .text('Piping Test Packages')
       // Bar_Progress_01
      data = this.dataSet.filter(f=> f.DISC !== 'Overall')
      style = {
              x:        250,
              y:        20,
              width:    70,
              title:    {text: '', x: 28, tSize: 11, tColor: '#333'},
              cell:     [70, 105, 140, 175, 190],
              lineHeight: 22,
              bColor:   '#B4E3FA', 
              sColor:   '#44A9DF', 
              tColor1:  '#fff', 
              tColor2:  '#757575', 
              opacity:  1,
              // requests: [
              //   {
              //     dataType: 'row',
              //     action: {
              //       type: 'direct',
              //       target: 'slide-modal',
              //       component: 'ServiceDatatable',
              //       id: 104,
              //       no: ''
              //     },
              //     filters: { 
              //       SUBSYSTEM: this.FilterValues.SYS
              //     },
              //     iFilters: {
              //       filterString : "[STAGE] = 'A'",
              //       inputFilter  : ''
              //     }
              //   },
              // ]
            }
      this.drawProgress(AitrDiscipline, data, style) // ---------------------------> 
    },
    drawProgress(selection, _data, _style) {

      _style.cell[5] = _style.cell[4] + (_style.width / 2)
      _style.cell[6] = _style.cell[4] + _style.width

      let discITR = selection
      .append('g')
      .attr('transform', `translate(${_style.x}, ${_style.y})`)

      discITR
      .append('text')
      .attr('x', _style.title.x).attr('y', -10)
      .style('font-family', 'roboto').style('font-size', _style.title.tSize).style('fill', _style.title.tColor)
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(_style.title.text)

      discITR
      .append('text')
      .attr('x', _style.cell[0]-50).attr('y', 8)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#212121').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
      .text('Test Package')

      discITR
      .append('text')
      .attr('x', _style.cell[0]-5).attr('y', 8)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#212121').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
      .text('QCF')

      discITR
      .append('text')
      .attr('x', _style.cell[1]).attr('y', 8)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#212121').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
      .text('Total')

      discITR
      .append('text')
      .attr('x', _style.cell[2]).attr('y', 8)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#212121').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
      .text('Act.')

      discITR
      .append('text')
      .attr('x', _style.cell[3]).attr('y', 8)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#212121').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
      .text('Rem.')

      discITR
      .append('text')
      .attr('x', _style.cell[4]).attr('y', 15)
      .style('font-family', 'roboto').style('font-size', 9).style('fill', '#bcbcbc').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text('0')

      discITR
      .append('text')
      .attr('x', _style.cell[5]).attr('y', 15)
      .style('font-family', 'roboto').style('font-size', 9).style('fill', '#bcbcbc').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text('50')

      discITR
      .append('text')
      .attr('x', _style.cell[6]).attr('y', 15)
      .style('font-family', 'roboto').style('font-size', 9).style('fill', '#bcbcbc').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text('100')

      discITR
      .append('line')
      .attr('x1', _style.cell[4]).attr('y1', 20).attr('x2', _style.cell[4]).attr('y2', 23).style('stroke', '#bcbcbc').style('stroke-width', 0.5)
      discITR
      .append('line')
      .attr('x1', _style.cell[5]).attr('y1', 20).attr('x2', _style.cell[5]).attr('y2', 23).style('stroke', '#bcbcbc').style('stroke-width', 0.5)
      discITR
      .append('line')
      .attr('x1', _style.cell[6]).attr('y1', 20).attr('x2', _style.cell[6]).attr('y2', 23).style('stroke', '#bcbcbc').style('stroke-width', 0.5)
      
      let drawY = 30
      _data.forEach((d,i) => {
        d.y = drawY

        discITR
        .append('text')
        .attr('x', _style.cell[0]-50).attr('y', drawY)
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#333').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text(d.QCF_DESCR)

        discITR
        .append('text')
        .attr('x', _style.cell[0]).attr('y', drawY)
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#333').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text(d.QCF)
        //Total
        discITR
        .append('text')
        .attr('id', `disc_Tot_${this.localId}_${i}`)
        .attr('transform', `translate(${_style.cell[1]}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#757575').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text(d.TOTAL)
        // .style('cursor', 'pointer')
        // .on('mouseover', function() {
        //   d3.select(this).transition().duration(100)
        //   .attr('transform', `translate(${_style.cell[1]+2}, ${d.y}) scale(1.3)`).style('fill', '#EC407A')
        // })
        // .on('mouseout', function(){
        //   d3.select(this).transition().duration(100)
        //   .attr('transform', `translate(${_style.cell[1]}, ${d.y}) scale(1)`).style('fill', '#757575')
        // })
        // .on('click', () => { 
        //   // if(_style.requests) {
        //   //   _style.requests[0].filters.DISC = d.DISC
        //   //   this.$emit('request-action', _style.requests[0])
        //   // }
        //   // let acsData = {CATEGORY : 'B', SUBSYSTEM: this.FilterValues.SUBSYSTEM, DISC:d.DISC}
        //   let acsData = {QCF:d.QCF}
        //   // console.log(acsData , d)
        //   let request_ = this.getRequestColumnProps('ascStatus', acsData, this.ColumnProps)
        //   this.$emit('request-action', request_)
        // })
        //Actual=Completed
        discITR
        .append('text')
        .attr('id', `disc_Act_${this.localId}_${i}`)
        .attr('transform', `translate(${_style.cell[2]}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#039BE5').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text(d.COMPLETED)
        // .style('cursor', 'pointer')
        // .on('mouseover', function() {
        //   d3.select(this).transition().duration(100)
        //   .attr('transform', `translate(${_style.cell[2]+2}, ${d.y}) scale(1.3)`).style('fill', '#EC407A')
        // })
        // .on('mouseout', function(){
        //   d3.select(this).transition().duration(100)
        //   .attr('transform', `translate(${_style.cell[2]}, ${d.y}) scale(1)`).style('fill', '#757575')
        // })
        // .on('click', () => { 
        //   let acsData = {STATUS_CODE:'C', QCF:d.QCF}
        //   let request_ = this.getRequestColumnProps('ascStatus', acsData, this.ColumnProps)
        //   this.$emit('request-action', request_)
        // })
        //REMAIN
        discITR
        .append('text')
        .attr('id', `disc_Rem_${this.localId}_${i}`)
        .attr('transform', `translate(${_style.cell[3]}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 11).style('fill', () => d.REMAIN == 0 ? '#757575' : '#F44336' )
        .attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text(d.REMAIN)
        // .style('cursor', 'pointer')
        // .on('mouseover', function() {
        //   d3.select(this).transition().duration(100)
        //   .attr('transform', `translate(${_style.cell[3]+2}, ${d.y}) scale(1.3)`).style('fill', '#EC407A')
        // })
        // .on('mouseout', function(){
        //   d3.select(this).transition().duration(100)
        //   .attr('transform', `translate(${_style.cell[3]}, ${d.y}) scale(1)`).style('fill', () => d.REMAIN == 0 ? '#757575' : '#F44336' )
        // })
        // .on('click', () => { 
        //   let acsData = {STATUS_CODE:'N', QCF:d.QCF}
        //   let request_ = this.getRequestColumnProps('ascStatus', acsData, this.ColumnProps)
        //   this.$emit('request-action', request_)
        // })

        discITR
        .append('rect')
        .attr('transform', `translate(${_style.cell[4]}, ${drawY - 7})`)
        .attr('width', _style.width).attr('height', 12)
        .attr('fill', '#F4F5F5')

        discITR
        .append('rect')
        .attr('transform', `translate(${_style.cell[4]}, ${drawY - 7})`)
        .attr('width', 0).attr('height', 12)
        .attr('stroke-width', 0.3).attr('stroke', _style.sColor).attr('fill', _style.bColor).style('opacity', _style.opacity)
        .transition().duration(500).attr('width', (d.PROG/100) * _style.width)

        discITR
        .append('text')
        .attr('y', drawY) 
        .attr('x', () => {
          if (d.PROG < 25) return _style.cell[4]; else return _style.cell[4] + 20
        })
        .style('font-family', 'roboto').style('font-size', 10).attr('alignment-baseline', 'middle')
        .text(`${d.PROG.toFixed(1)}%`)
        .style('fill', () => {
          if (d.PROG < 25) return _style.tColor2; else return _style.tColor1
        })
        .attr('text-anchor', () => {
          if (d.PROG < 25) return 'start'; else return 'end'
        })
        .transition().duration(500)
        .attr('x', () => {
          if (d.PROG < 25) return _style.cell[4] + d.PROG+1; else return _style.cell[4] + ((d.PROG/100) * _style.width) -1
        })
        drawY += _style.lineHeight
      })
    }
  }
}