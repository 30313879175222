<template>
  <div :id="localId"></div>
</template>

<script>
import mx_Core from './includes/mixins/importer'            // Global & Local Mixins

export default {
  name: "j-chart-pyramid",
  mixins: [mx_Core],
  props: {
    id: String,
    
    Canvas: {type: Object, default: () => ({}) },
    Queries: {type: Object, default: () => ({}) },
  },
  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
  },
  mounted() {
    this.register(this.draw)
  },
  methods: {
    draw() {
      // Check all the necessary values are available
      if (!this.ready2draw) return

      this.clear()
      this.setDefaultValues()
      this.drawCanvas()
      this.drawChart()

      this.complete()
    }
  }
}
</script>