export default {
  methods: {
    drawHeaderInfo() {
      if(this.cmmHeaderArea) this.cmmHeaderArea.remove()

      this.cmmHeaderArea = this.svg
      .append('g')
      .attr('id', 'common_header_area')
      .attr('transform', 'translate(0, 0)')

      // China1 project logo 
      this.loadSvg(
        this.cmmHeaderArea, {
        x   : 30,
        y   : 30,
        url : '/China1/EquipmentLayout/logo.svg'
      })

      // Equipment Layout title
      this.cmmHeaderArea
      .append('text')
      .attr('transform', `translate(40, 105)`)
      .style('font-family', 'roboto').style('font-size', 16).style('fill', '#333').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Equipment Layout -')

      this.cmmHeaderArea
      .append('text')
      .attr('id', 'el_location_subtitle')
      .attr('transform', `translate(180, 105)`)
      .style('font-family', 'roboto').style('font-size', 16).style('fill', '#44A9DF').attr('text-anchor', 'start').attr('alignment-baseline', 'middle').attr('font-weight', 400)
      .text('Main Plant')

      // Home & Back Button 
      let routebtnArea = this.cmmHeaderArea
      .append('g')
      .attr('id', 'el_g_route_to')
      .attr('transform', 'translate(1520, 30)')
      .style('pointer-events', 'none')
      .style('opacity', 0)

      routebtnArea
      .append('image')
      .attr('xlink:href', require('../../../../../../src/assets/svg/icons/btn_home.svg'))
      .attr('transform', 'translate(0, 0)')
      .attr('width', 24).attr('height', 24)
      .style('cursor', 'pointer')
      .on('click', () => { this.routeTo({ level: 0, lname: 'main' }, this.route.to)})

      routebtnArea
      .append('image')
      .attr('xlink:href', require('../../../../../../src/assets/svg/icons/btn_back.svg'))
      .attr('transform', 'translate(26, 0)')
      .attr('width', 24).attr('height', 24)
      .style('cursor', 'pointer')
      .on('click', () => { this.routeTo(-1) })

      // Tag Button Controller
      let tagcntr = this.cmmHeaderArea
      .append('g')
      .attr('id', 'tagbtn_control_wrapper')
      .attr('transform', `translate(1509, 80)`)
      .style('font-size', 10)
      .style('font-family', 'roboto')
      .style('pointer-events', 'none')
      .style('opacity', 0)
      .style('cursor', 'pointer')
      this.drawTagButton(tagcntr)
    },
    drawSharedInfo(__data) {
      /**
       * Put Shared Data & Controllers (Tools) on the main SVG layer.
       */





      /**
       * Put Shared Data & Controllers (Tools) on the left side of the current page.
       */

      if(this.cmmSharedArea) this.cmmSharedArea.remove()

      this.cmmSharedArea = this.svg
      .append('g')
      .attr('id', 'common_shared_area')
      .attr('transform', 'translate(30, 155)')

      // Search Controller ----------------------------------------
      let data__ = {
        x: 10.5,
        y: 10.5,
        optionValues: [
          { name: 'tagno'  , colName: 'TAG'      , text: 'Tag No.'  , value: '', x: 0 , textWidth: 45 },
          { name: 'tagdesc', colName: 'TAG_DESCR', text: 'Tag Desc.', value: '', x: 55, textWidth: 55 },
        ],
        selected: this.displayDataFilter.tag,
        input: this.displayDataFilter.searchInput,
        callFunc: {
          searchOption: this.callFuncSearchFilter,
          searchInput: this.callFuncSearchInput,
          search: () => { this.equipmentFilter('search') },
        },
        style: {
          searchOptions: {
            over: {
              stroke: '#ffb300',
              fill: '#ffd54f',
              color: '#fff'
            },
            out: {
              stroke: '#bcbcbc',
              fill: '#fff',
              color: '#c9c9c9'
            },
            selected: {
              stroke: '#1565c0',
              fill: '#64b5f6',
              color: '#757575'
            }
          }
        }
      }
      let box__ = this.cmmSharedArea
      .append('g')
      .attr('id', 'common_search_controller_area')
      .attr('transform', `translate(0, 221)`)

      box__
      .append('rect')
      .attr('rx', 5)
      .attr('ry', 5)
      .style('width', 271)
      .style('height', 95)
      .style('fill', '#fff')
      // .style('filter', 'url(#drop_shadow_el)')

      this.drawSearchTool(box__, data__)
      // ----------------------------------------------------------
      
      // Legend Critical ------------------------------------------
      let legend = this.cmmSharedArea
      .append('g')
      .attr('id', 'common_legend_area')
      .attr('transform', `translate(0, 326)`)

      legend
      .append('rect')
      .attr('rx', 5)
      .attr('ry', 5)
      .style('width', 271)
      .style('height', 137)
      .style('fill', '#fff')
      .style('opacity', .97)

      legend
      .append('text')
      .attr('transform', `translate(10, 20)`)
      .style('font-weight', 400)
      .style('font-size', 14)
      .style('text-anchor', 'start')
      .style('alignment-baseline', 'middle')
      .text('Legend')

      let legendData = [
        { name: 'Total of the Equipment.', bcolor: '#83D2F5', tcolor: '#fff' },
        ...this.eqStyle.critical
      ]
      legendData.forEach((l, i) => {
        // legend critical box & label //
        this.drawBoxedSummarySingle(legend, {
          x         : 10,
          y         : (16 * i) + (2*i) + 38,
          width     : 16,
          height    : 16,
          radius    : 3,
          bColor    : l.bcolor,
          value     : 'xx',
          valuex    : 8,
          valuey    : 9,
          valueSize : 9,
          valueColor: l.tcolor,
          vanchor   : 'middle',
          label     : l.name,
          labelx    : this.eqStyle.legend.labelx1,
          labely    : 10,
          labelSize : 9,
          class     : 'legend_delivery_item'
        })

        // legend critical checkbox //
        if(l.checkable) this.drawLegendCheckbox(legend, {
          x: 29.5,
          y: (16 * i) + (2*i) + 42.5,
          d: l,
          class: `legend_critical_checkbox`,
          action: {
            x: -19,
            y: -2,
            w: l.width,
            h: 12,
            callFunc: () => {}
          }
        })
      })
      // ----------------------------------------------------------

      // legend stage color code ----------------------------------
      let legendColorCode = this.cmmSharedArea
      .append('g')
      .attr('id', 'common_legend_color_code_area')
      .attr('transform', `translate(0.5, 460.5)`)
      .style('opacity', 0)
      
      legendColorCode
      .append('rect')
      .attr('rx', 5)
      .attr('ry', 5)
      .style('width', 271)
      .style('height', 113)
      .style('fill', '#fff')
      .style('opacity', .97)
      // .style('filter', 'url(#drop_shadow_el)')

      legendColorCode
      .append('text')
      .attr('transform', `translate(20, 55) rotate(-90)`)
      .style('font-size', 10)
      .style('text-anchor', 'middle')
      .style('alignment-baseline', 'middle')
      .text('Color Code')

      legendColorCode
      .append('path')
      .attr('d', 'M 35 16 H 30 V 96 H 35')
      .attr('stroke', '#e7e7e7')
      .attr('stroke-width', 1)
      .attr('fill-opacity', 0)

      let x__ = 40
      let y__ = 16

      this.eqStyle.Status.forEach((c, i) => {
        // legend circle & text //
        legendColorCode
        .append('ellipse')
        .attr('cx', x__+10)
        .attr('cy', y__)
        .attr('rx', this.eqStyle.CircleBorder.rx)
        .attr('ry', this.eqStyle.CircleBorder.ry)
        .attr('stroke', '#757575')
        .attr('stroke-width', this.eqStyle.CircleBorder.stroke)
        .attr('fill', c.bColor)
  
        legendColorCode
        .append('text')
        .attr('transform', `translate(${x__+6}, ${y__})`)
        .style('font-size', 8)
        .style('text-anchor', 'start')
        .style('alignment-baseline', 'middle')
        .style('fill', '#333')
        .text('xx')

        // label //
        legendColorCode
        .append('text')
        .attr('transform', `translate(${x__+32}, ${y__+1})`)
        .style('font-size', 9)
        .style('text-anchor', 'start')
        .style('alignment-baseline', 'middle')
        .text(c.name)
  
        // checkbox //
        this.drawLegendCheckbox(legendColorCode, {
          x: x__+22,
          y: y__-4,
          d: c,
          class: `legend_stage_checkbox_wrapper`,
          action: {
            x: -18,
            y: -2,
            w: c.width,
            h: 12,
            callFunc: () => {}
          }
        })

        y__ += 16
      })
      // ----------------------------------------------------------

      // Keyplan --------------------------------------------------
      let keyplan = this.cmmSharedArea
      .append('g')
      .attr('id', 'common_keyplan_area')
      .attr('transform', `translate(0, 665)`)

      keyplan
      .append('rect')
      .attr('rx', 5)
      .attr('ry', 5)
      .style('width', 271)
      .style('height', 271)
      .style('fill', '#fff')
      .style('opacity', .97)

      keyplan
      .append('text')
      .attr('transform', `translate(10, 20)`)
      .style('font-weight', 400)
      .style('font-size', 14)
      .style('text-anchor', 'start')
      .style('alignment-baseline', 'middle')
      .text('Keyplan')

      keyplan
      .append('image')
      .attr('class','el_keyplan')
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/China1/EquipmentLayout/Keyplan/MAIN.png`)
      .attr('transform', `translate(10, 30)`)
      .style('width', 251)
      // ----------------------------------------------------------
    },
    drawStageStatus(selection, data, idtail='') {
      let box__ = selection.select(`#el_stage_status_box${idtail}`)
      if(box__) box__.remove()

      let req = this.buildRequest(this.reqTitle.summary.TOTAL, data.filters)
      let d = {
        x         : 10,
        y         : 10,
        width     : 120,
        height    : 32,
        radius    : 3,
        bColor    : '#83D2F5',
        value     : data.source.TOTAL,
        valuex    : 80,
        valuey    : 18,
        valueSize : 20,
        valueColor: '#fff',
        vanchor   : 'end',
        linkable  : true,
        label     : 'Total',
        labelx    : 85,
        labely    : 20,
        labelSize : 9,
        labelColor: '#e3f5fd',
        request   : req,
      }

      box__ = selection
      .append('g')
      .attr('id', `#el_stage_status_box${idtail}`)
      .attr('transform', `translate(${data.x}, ${data.y})`)

      let boxbg = box__
      .append('rect')
      .attr('rx', 5).attr('ry', 5)
      .style('width', 271).style('height', 220)//210
      .style('fill', '#fff')
      if(data.shadow) boxbg.style('filter', 'url(#drop_shadow_el)')

      if(data.title) {
        let title__ = box__
        .append('text')
        .attr('transform', `translate(${d.x + d.width + 10}, ${d.y + d.height - 10})`)
        .style('text-anchor', 'start')
        .style('alignment-baseline', 'middle')
        .style('font-size', 12)
        .text(data.title)

        if(data.tcolor) title__.attr('fill', data.tcolor)
      }

      this.drawBoxedSummarySingle(box__, d) // draw total

      let body = []
      this.stageCode.forEach(c => {
        let prog = (
          data.source['TOTAL'] === 0 || 
          data.source[`${c.code}_ACTUAL`] === 0 ?
          0 : 
          Number((data.source[`${c.code}_ACTUAL`] / data.source['TOTAL'] * 100).toFixed(1))
        )
        body.push({
          STATUS_CODE       : c.code,
          STAGE             : c.nameShort,
          PLAN              : data.source[`${c.code}_PLAN`],
          ACTUAL            : data.source[`${c.code}_ACTUAL`],
          DELTA             : data.source[`${c.code}_DELTA`],
          PROG              : prog,
          FILTER_STR_PLAN   : `${c.code}_PLAN <= CUTOFF`,
          FILTER_STR_ACTUAL : `${c.code}_ACTUAL IS NOT NULL`,
          TITLE_PLAN        : `Equipment Summary - Plan on ${c.name}`,
          TITLE_ACTUAL      : `Equipment Summary - Actual on ${c.name}`,
        })
      })

      this.tableBarProgress(box__, {
        data: {
          header: [
            { text: 'Stage' , colName: 'STAGE' , width: 52, vcolor: '#757575' },
            { text: 'Plan'  , colName: 'PLAN'  , width: 35, vcolor: '#757575', linkable: true, values: [], filterStringCol: 'FILTER_STR_PLAN'  , titleCol: 'TITLE_PLAN'   },
            { text: 'Actual', colName: 'ACTUAL', width: 35, vcolor: '#757575', linkable: true, values: [], filterStringCol: 'FILTER_STR_ACTUAL', titleCol: 'TITLE_ACTUAL' },
            { text: 'Delta' , colName: 'DELTA' , width: 35, vcolor: '#F44336' },
            { type: 'space' , width: 10 },
            { type: 'bar'   , colName: 'PROG'  , width: 80 },
          ],
          body
        },
        form: {
          x         : d.x,
          y         : d.y + d.height + 5,//10
          title     : { text: '', x: 28, tSize: 11, tColor: '#333' },
          lineHeight: 17,
          bColor    : '#B4E3FA', 
          sColor    : '#44A9DF', 
          tColor1   : '#053a54', 
          tColor2   : '#757575', 
          opacity   : 1,
        },
        request: req,
      })

      d.y           = d.y + d.height * 2 + 110//94
      d.width       = 61
      d.bColor      = '#F4F5F5'
      d.valuex      = 30
      d.valuey      = 17
      d.valueSize   = 11
      d.vanchor     = 'middle'
      d.label       = ''

      this.eqStyle.critical.forEach((c, i) => {
        req = this.buildRequest(this.reqTitle.summary[c.code], { ...data.filters, CRITICAL: c.code })

        d.x           = i ? d.x + d.width + 2 : d.x
        d.value       = data.source[`${c.code}_CNT`]
        d.valueColor  = c.tcolor
        d.request     = req

        this.drawBoxedSummarySingle(box__, d)
      })
    },
    drawStageStatusSmall(selection, data) {
      let req = this.buildRequest(this.reqTitle.summary.TOTAL, data.filters)
      let d = {
        x         : 10,
        y         : 10,
        width     : 56,
        height    : 22,
        radius    : 3,
        bColor    : '#83D2F5',
        value     : data.source.TOTAL,
        valuex    : 28,
        valuey    : 13,
        valueSize : 12,
        valueColor: '#fff',
        vanchor   : 'middle',
        linkable  : true,
        label     : '',
        labelx    : 85,
        labely    : 20,
        labelSize : 9,
        labelColor: '#e3f5fd',
        request   : req,
      }

      let box__ = selection
      .append('g')
      .attr('id', '_el_stage_status_small_box')
      .attr('transform', `translate(${data.x}, ${data.y})`)

      let boxbg = box__
      .append('rect')
      .attr('rx', 5).attr('ry', 5)
      .style('width', data.w).style('height', data.h)
      .style('fill', '#fff')
      if(data.shadow) boxbg.style('filter', 'url(#drop_shadow_el)')

      if(data.title) {
        let title__ = box__
        .append('text')
        .attr('transform', `translate(${d.x + d.width + 7}, ${d.y + d.height - 10})`)
        .style('text-anchor', 'start')
        .style('alignment-baseline', 'middle')
        .style('font-size', data.tsize ? data.tsize : 12)
        .text(data.title)

        if(data.tcolor) title__.attr('fill', data.tcolor)
      }
      
      this.drawBoxedSummarySingle(box__, d) // draw total

      let body = []
      this.stageCode.forEach(c => {
        let prog = (
          data.source['TOTAL'] === 0 || 
          data.source[`${c.code}_ACTUAL`] === 0 ?
          0 : 
          Number((data.source[`${c.code}_ACTUAL`] / data.source['TOTAL'] * 100).toFixed(1))
        )
        body.push({
          STAGE             : c.nameShort,
          PLAN              : data.source[`${c.code}_PLAN`],
          ACTUAL            : data.source[`${c.code}_ACTUAL`],
          DELTA             : data.source[`${c.code}_DELTA`],
          PROG              : prog,
          FILTER_STR_PLAN   : `${c.code}_PLAN <= CUTOFF`,
          FILTER_STR_ACTUAL : `${c.code}_ACTUAL IS NOT NULL`,
          TITLE_PLAN        : `Equipment Summary - Plan on ${c.name}`,
          TITLE_ACTUAL      : `Equipment Summary - Actual on ${c.name}`,
        })
      })

      this.tableBarQuantityOverlapped(box__, {
        data: {
          header: [
            { text: ''      , colName: 'STAGE' , width: 52, x: 48 , align: 'end'   , vcolor: '#757575', },
            { text: 'Plan'  , colName: 'PLAN'  , width: 35, x: 71 , align: 'middle', vcolor: '#757575', linkable: true, values: [], filterStringCol: 'FILTER_STR_PLAN'  , titleCol: 'TITLE_PLAN'   },
            { text: 'Actual', colName: 'ACTUAL', width: 35, x: 106, align: 'middle', vcolor: '#757575', linkable: true, values: [], filterStringCol: 'FILTER_STR_ACTUAL', titleCol: 'TITLE_ACTUAL' },
            { text: ''      , colName: 'DELTA' , width: 35, x: 128, align: 'start' , vcolor: '#F44336' },
            { type: 'bar'   , colName: 'PROG'  , width: 70, x: 54 },
          ],
          body
        },
        form: {
          x         : d.x,
          y         : d.y + d.height,
          title     : { text: '', x: 28, tSize: 11, tColor: '#333' },
          lineHeight: 16,
          bColor    : '#B4E3FA',
          sColor    : '#44A9DF',
          tColor1   : '#053a54',
          tColor2   : '#757575',
          opacity   : 1,
        },
        request: req,
      })
      
      d.y           = d.y + d.height + 108
      d.width       = 37
      d.bColor      = '#F4F5F5'
      d.valuex      = 18
      d.valuey      = 12
      d.valueSize   = 10
      d.vanchor     = 'middle'
      d.label       = ''

      this.eqStyle.critical.forEach((c, i) => {
        req = this.buildRequest(this.reqTitle.summary[c.code], { ...data.filters, CRITICAL: c.code })

        d.x           = i ? d.x + d.width + 2 : d.x
        d.value       = data.source[`${c.code}_CNT`]
        d.valueColor  = c.tcolor
        d.request     = req

        this.drawBoxedSummarySingle(box__, d)
      })

      return box__
    },
    drawLegendCheckbox(selection, data) {
      let checkbox = selection
      .append('g')
      .attr('class', data.class)
      .attr('transform', `translate(${data.x}, ${data.y})`)

      // check box
      checkbox
      .append('rect')
      .attr('class', `legend_checkbox__${data.d.code}`)
      .attr('transform', 'translate(0, 0)')
      .attr('rx', 2)
      .attr('ry', 2)
      .attr('stroke', this.eqStyle.search.out.stroke)
      .attr('stroke-width', .5)
      .style('width', 8)
      .style('height', 8)
      .style('fill-opacity', 0)

      // check box checked status
      checkbox
      .append('text')
      .attr('class', `legend_checkbox_checked__${data.d.code}`)
      .attr('transform', `translate(2, 4)`)
      // .style('fill', '#999')
      .style('font-size', 8)
      .style('text-anchor', 'start')
      .style('alignment-baseline', 'middle')
      .text(this.displayDataFilter[data.d.code.toLowerCase()] && this.displayDataFilter[data.d.code.toLowerCase()].checked ? 'v' : '')

      // action area
      checkbox
      .datum(data.d)
      .append('rect')
      .attr('class', `legend_action_area__${data.d.code}`)
      .attr('transform', `translate(${data.action.x}, ${data.action.y})`)
      .style('width', data.action.w)
      .style('height', data.action.h)
      .style('cursor', 'pointer')
      .style('fill-opacity', 0)
      // .call(data.action.callFunc)
      .call(selection => {
        selection.on('mouseover', d => {
          this.svg.select(`.legend_checkbox__${d.code}`).attr('stroke', '#555')
        })
        selection.on('mouseout', d => {
          this.svg.select(`.legend_checkbox__${d.code}`).attr('stroke', this.eqStyle.search.out.stroke)
        })
        selection.on('click', d => {
          let checked = this.svg.select(`.legend_checkbox_checked__${d.code}`).text()

          this.svg.select(`.legend_checkbox_checked__${d.code}`).text(checked ? '' : 'v')
          this.displayDataFilter[d.code.toLowerCase()].checked = !checked
          this.equipmentFilter()
        })
      })
    },
  }
}