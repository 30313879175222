export default {
  data: () => ({
    elProps: {
      coordinates: [
        { STAGE: 'OverAll', TYPE: 'Shop', DIRECT: null, CODE: 'Shop_F', x: 80, y: 530 },
        { STAGE: 'OverAll', TYPE: 'Shop', DIRECT: null, CODE: 'Shop_A', x: 240, y: 420 },
        { STAGE: 'OverAll', TYPE: 'Shop', DIRECT: null, CODE: 'Shop_P', x: 400, y: 280 },
        { STAGE: 'OverAll', TYPE: 'Shop', DIRECT: null, CODE: 'Shop_E', x: 560, y: 210 },
        // { STAGE: 'OverAll', TYPE: 'Area', DIRECT: null, CODE: 'Area_T', GOTO: 'Topside',  x: 600, y: 620 },
        { STAGE: 'OverAll', TYPE: 'Area', DIRECT: null, CODE: 'Area_M', GOTO: 'Modules',  x: 960, y: 570 },
        { STAGE: 'OverAll', TYPE: 'Area', DIRECT: null, CODE: 'Area_C', GOTO: 'Common',   x: 1100, y: 180 },
        { STAGE: 'OverAll', TYPE: 'Area', DIRECT: null, CODE: 'Area_L', GOTO: 'LQ',       x: 720, y: 700 },
        { STAGE: 'OverAll', TYPE: 'Area', DIRECT: null, CODE: 'Area_H', GOTO: 'Hull',     x: 1080, y: 420 },
        
        { STAGE: 'Modules', TYPE: 'Area', LV3: 'ME01'    , DIRECT: 'R', CODE: 'Area_M', LIFT:'Y',x: 740, y: 640 },
        { STAGE: 'Modules', TYPE: 'Area', LV3: 'ME02'    , DIRECT: 'R', CODE: 'Area_M', LIFT:'Y',x: 905, y: 640 },
        { STAGE: 'Modules', TYPE: 'Area', LV3: 'MW01'    , DIRECT: 'R', CODE: 'Area_M', LIFT:'Y',x: 1070, y: 640 },
        { STAGE: 'Modules', TYPE: 'Area', LV3: 'MW02'    , DIRECT: 'R', CODE: 'Area_M', LIFT:'Y',x: 1235, y: 640 },
        { STAGE: 'Modules', TYPE: 'Area', LV3: 'ME04'    , DIRECT: 'R', CODE: 'Area_M', LIFT:'Y',x: 1235, y: 770 },
        { STAGE: 'Modules', TYPE: 'Area', LV3: 'ME05'    , DIRECT: 'R', CODE: 'Area_M', LIFT:'Y',x: 1070, y: 770 },
        { STAGE: 'Modules', TYPE: 'Area', LV3: 'MW04'    , DIRECT: 'R', CODE: 'Area_M', LIFT:'Y',x: 905, y: 770 },
        { STAGE: 'Modules', TYPE: 'Area', LV3: 'MW05'    , DIRECT: 'R', CODE: 'Area_M', LIFT:'Y',x: 740, y: 770 },
        { STAGE: 'Common',  TYPE: 'Area', LV3: 'RCxx'    , DIRECT: 'R', CODE: 'Area_C', LIFT:'Y',x: 1235, y: 770 },
        { STAGE: 'Common',  TYPE: 'Area', LV3: 'FL01'    , DIRECT: 'R', CODE: 'Area_C', LIFT:'Y',x: 1070, y: 770 },
        { STAGE: 'Common',  TYPE: 'Area', LV3: 'KO01'    , DIRECT: 'R', CODE: 'Area_C', LIFT:'Y',x: 905, y: 770 },
        { STAGE: 'LQ',      TYPE: 'Area', LV2: 'LQ'      , DIRECT: 'L', CODE: 'Area_L', LIFT:'N',x: 1110, y: 360 },
        { STAGE: 'Hull',    TYPE: 'Area', LV2: 'HULL-AFT', DIRECT: 'R', CODE: 'Area_H', LIFT:'N',x: 520, y: 600 },
        { STAGE: 'Hull',    TYPE: 'Area', LV2: 'HULL-FOR', DIRECT: 'L', CODE: 'Area_H', LIFT:'N',x: 1080, y: 300 },
      ]
    }
  })
}