<template>
  <div>
    <div class="worktype-selectbox">
      <multiselect
        v-model="worktype"
        :options="worktypeOptions"
        :allow-empty="false"
        :multiple="true"
        :close-on-select="false"
        label="WORK_TYPE_DESCR"
        track-by="WORK_TYPE_CODE">
      </multiselect>
    </div>
    <div :id="localId"></div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import mx_Core from './includes/mixins/importer'            // Global & Local Mixins
import * as d3 from 'd3'
export default {
  components: { Multiselect },
  name: "j-chart-psrcombo-mr",
  mixins: [mx_Core],
  props: {
    id: String,
    modeExport: Boolean,

    FilterValues: {
      type: Object,
      default: () => ({
        MOD: 'MOD-NO',
        MOD_DESC: 'Module Description',
      })
    },

    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },
    DataItems: { type: Array, default: () => ([]) },
    ColumnProps: { type: Array, default: () => ([]) },
    Queries: { type: Object, default: () => ({}) },
  },
  data: () => ({
    worktype: [],
  }),
  computed: {
    worktypelength () {
      return this.worktype.length
    }
  },
  created() {
    this.worktype = [{WORK_TYPE_SEQ:11,WORK_TYPE_CODE:"PO",WORK_TYPE_DESCR:"PO Issue"}]
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
  },

  mounted() {
    this.register(this.draw)
  },

  methods: {
    updateSelected (newSelected) {
      this.selected = newSelected
    },
    draw() {
      if (!this.ready2draw) return

      this.clear()
      this.setDefaultValues()
      this.drawCanvas()
      this.drawChart()
      this.drawTexts()
      this.complete()
    },

    drawChart() {
      this.Draw_Charts()
    },

  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.worktype-selectbox{
  position: absolute;
  right: 20px;
  top: 50px;
  width:380px;
}
.PO {
  background: #000 !important;
}
</style>