import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {

    
    Draw_Charts() {
      
      let data = null, 
          style = null,
          total = 0

      let disc_ITRStatus = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0)`)


      data = this.dataSet.filter(f=> f.TYPE !== 'WEEK' && f.DISC !== 'Overall')
      this.Queries.SQL1.forEach(d=> { total += d.PERIOD})
      total = total/4

      disc_ITRStatus
      .append('text')
      .attr('x', 280).attr('y', 53)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#333').attr('text-anchor', 'end')
      .text(`Average ${data[0].TYPE} Completed per Week (Last 4Weeks)`)

      disc_ITRStatus
      .append('text')
      .attr('x', 215).attr('y', 75)
      .style('font-family', 'roboto').style('font-size', 16).style('fill', '#44A9DF').attr('text-anchor', 'end')
      .text(total.toFixed(0))

      disc_ITRStatus
      .append('text')
      .attr('x', 280).attr('y', 75)
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#83D2F5').attr('text-anchor', 'end')
      .text(`ITRs / Week`)



      // WeekPerformance_01
      data = this.dataSet.filter(f=> f.TYPE == 'WEEK')
      style = {
                x: 28,
                y: 90,
      }
      data.forEach(d=> {
        d.date = d.DATE

        if(d.DISC =='LWK') d.name = 'Last Week' 
        else if (d.DISC =='TWK') d.name = 'This Week' 
        else  d.name = d.DISC

        d.value = d.ACTUAL
      })
      this.WeekPerformance_02(disc_ITRStatus, data, style) // ---------------------------> 



       // Bar_Progress_01
       data = this.dataSet.filter(f=> f.TYPE !== 'WEEK' && f.DISC !== 'Overall')

       style = {
                id: `${data[0].TYPE}`,
                x:        0,
                y:        190,
                width:    80,
                title:    {text: `${data[0].TYPE} Status by discipline`, x: 28, tSize: 11, tColor: '#333'},
                cell:     [70, 110, 150, 190, 200, 240, 280],
                lineHeight: 20,
                bColor:   '#B4E3FA', 
                sColor:   '#44A9DF', 
                tColor1:  '#fff', 
                tColor2:  '#757575', 
                opacity: 1
       }
       this.Bar_Progress_01(disc_ITRStatus, data, style) // ---------------------------> 
    },
  }
}
           