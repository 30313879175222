import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({
    
  }),


  methods: {

    SummaryTable_01(selection, _data, _style) {

      let summaryTable = selection
      .append('g')
      .attr('id', '')
      .attr('transform', `translate(${_style.x}, ${_style.y})`)
      
      summaryTable
      .append('rect')
      .attr('transform', `translate(0, 0)`)
      .style('width', _style.boxWidth).style('height', _style.boxHeight)
      .attr('fill', `url(#${this.localId}__LinearA4${'LightBlue'}0)`)
      .attr('rx', 3).attr('ry', 3)
      
      summaryTable
      .append('text')
      .attr('transform', `translate(${_style.boxWidth/2}, ${_style.boxHeight/2 + 1})`)
      .style('font-family', 'roboto').style('font-size', 12).style('fill', _style.headColor).attr('font-weight', 400)
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(_style.headTitle)

      summaryTable
      .append('text')
      .attr('transform', `translate(${_style.boxWidth + 10}, ${_style.boxHeight/2 + 1})`)
      .style('font-family', 'roboto').style('font-size', 14).style('fill', '#EC407A')
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(`${_style.prog}%`)

      summaryTable
      .append('rect')
      .attr('transform', `translate(0, ${25})`)
      .style('width', _style.tblWidth).style('height', _style.tblHeight).style('fill', '#83D2F5').attr('rx', 3).attr('ry', 3).style('opacity', .65)

      _style.title.forEach((d,i) => {
        summaryTable
        .append('text') 
        .attr('transform', `translate(${_style.cell[i]}, ${38})`)
        .style('font-family', 'roboto').style('font-size', 9)
        .style('fill', '#333').style('text-anchor', 'end').style('alignment-baseline', 'middle').text(d)
      })
      
      let drawY = 53
      _data.forEach((d,i) => {

        summaryTable
        .append('text') 
        .attr('transform', `translate(${10}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 9.5)
        .style('fill', _style.tColor).style('text-anchor', 'start').style('alignment-baseline', 'middle').text(d.Lv2)

        summaryTable
        .append('text') 
        .attr('transform', `translate(${_style.cell[1]}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 9.5)
        .style('fill', _style.tColor).style('text-anchor', 'end').style('alignment-baseline', 'middle').text(`${d.PLAN}%`)

        summaryTable
        .append('text')
        .attr('transform', `translate(${_style.cell[2]}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 9.5)
        .style('fill', _style.tColor).style('text-anchor', 'end').style('alignment-baseline', 'middle').text(`${d.ACTUAL}%`)

        summaryTable
        .append('text') 
        .attr('transform', `translate(${_style.cell[3]}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 9.5)
        .style('fill', _style.tColor).style('text-anchor', 'end').style('alignment-baseline', 'middle').text(`${d.DELTA}%`)

        drawY += _style.lineHeight
      })

  

    },

  }
}
