// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  data:() => ({
    thead: [
      { key: 'NO'         , html: 'No'       , row: 1,rowspan: 2 ,hColor:'#000', },
      { key: 'SUBSYSTEM'  , html: 'Subsystem', row: 1,rowspan: 2 ,hColor:'#000', },
      { key: 'MC_PLAN'    , html: 'MC'       , row:1,colspan: 2  ,hColor:'#000', },
      { key: 'PA_OV_TOT'  , html: 'Punch A' , row: 1,colspan: 2  ,hColor:'#E91E63', },
      { key: 'PB1_OV_TOT' , html: 'Punch B1', row: 1,colspan: 2  ,hColor:'#039BE5', },
      { key: 'PB2_OV_TOT' , html: 'Punch B2', row: 1,colspan: 2  ,hColor:'#039BE5', },
      { key: 'PB3_OV_TOT' , html: 'Punch B3', row: 1,colspan: 2  ,hColor:'#039BE5', },

      
      { key: 'MC_PLAN'   , html: 'Date' , row: 2,},
      { key: 'A_OV_PROG' , html: 'Prog' , row: 2,},
      { key: 'PA_OV_TOT' , html: 'Total', row: 2,},
      { key: 'PA_OV_REM' , html: 'Open' , row: 2,},
      { key: 'PB1_OV_TOT', html: 'Total', row: 2,},
      { key: 'PB1_OV_REM', html: 'Open' , row: 2,},
      { key: 'PB2_OV_TOT', html: 'Total', row: 2,},
      { key: 'PB2_OV_REM', html: 'Open' , row: 2,},
      { key: 'PB3_OV_TOT', html: 'Total', row: 2,},
      { key: 'PB3_OV_REM', html: 'Open' , row: 2,},
    ],
    theadrows: 2,
    tbody: [
      { key: 'NO', w: 35 },
      { key: 'SUBSYSTEM', w: 110,},
      { key: 'MC_PLAN', w: 70},
      { key: 'A_OV_PROG', w: 50},
      { key: 'PA_OV_TOT',  w: 40 ,type: 'float', align: 'right',},
      { key: 'PA_OV_REM',  w: 40 ,type: 'float', align: 'right',},
      { key: 'PB1_OV_TOT', w: 40 ,type: 'float', align: 'right',},
      { key: 'PB1_OV_REM', w: 40 ,type: 'float', align: 'right',},
      { key: 'PB2_OV_TOT', w: 40 ,type: 'float', align: 'right',},
      { key: 'PB2_OV_REM', w: 40 ,type: 'float', align: 'right',},
      { key: 'PB3_OV_TOT', w: 40 ,type: 'float', align: 'right',},
      { key: 'PB3_OV_REM', w: 40 ,type: 'float', align: 'right',},
      
    ],
    width: 300,

    selectedStartDate: null,
    selectedStartColumn: null,

    selectedFinishDate: null,
    selectedFinishColumn: null,

    filteredList: null
  }),
  methods: {
    Draw_Charts() {
      let svg = this.svg

      this.filteredList = this.DataItems

      let list = this.filteredList

      let this_ = this

      let overallSummary = this.svg
      .append('g')
      .attr('class','moduleName')
      .attr('transform', `translate(0, 0)`)

      let group = svg.append('g').attr('class', 'ogm_table_group__')
      let table_group = group.append('g').attr('transform', `translate(0.5, 0)`)
      
      let table_obj = table_group.append('foreignObject')
        .attr('y',0)
        .attr('width', '100%')
        .attr('height', '300px')
        .append('xhtml:body')
        .style('position', 'fixed')

      let table = table_obj.append('table')
      .attr('id', 'commodity_summary_table_')
      .style('border-collapse', 'collapse')
      .style('font-size', '10px')
      .style('width', '420px')
      .style('table-layout', 'fixed')

      let colgroup = table.append('colgroup')
      this.tbody.forEach(k => {
        colgroup.append('col').style('width', k.w + 'px')
      })

      let thead = table.append('thead')
      for (let r = 1; r <= this.theadrows; r++) {
        let row = this.thead.filter(d=> d.row === r)
        let tr = thead.append('tr')

        row.forEach(item => {

          tr.append('th')
          .attr('class', 'ogm_table_group__th')
          .attr('rowspan', () => item.rowspan ? item.rowspan : '')
          .attr('colspan', () => item.colspan ? item.colspan : '')
          .style('border', 'solid 0.7px #eee')
          .style('height', '25px')
          .style('padding', '3px')
          .style('background', item.color ? item.color : '#FAFAFA')
          .style('color',item.hColor ? item.hColor : '#757575')
          .html(item.html)
        })        
      }

      let tbodykeys = this.tbody

      let disc = ''
      
      let tbody = table.append('tbody').attr('id', 'commodity_summay_tbody_')

      let addClass = ''

      for (let r = 0; r < list.length; r++) {
        let tr = tbody.append('tr')

        tbodykeys.forEach((k, i) => {

          if (list[r][k.key] == disc) return
          else {
            if (i == 0) {
              disc = list[r][k.key]
            }
          }

          let td = tr.append('td')
            .style('border', 'solid 0.1px #eee')
            .style('height', '21px')
            .style('text-align', k.align ? k.align : 'center')
            .style('padding', '0 3px 0 3px')

          // if(i === 1) {
          //   td.style('white-space', 'nowrap')
          //     .style('overflow', 'hidden')
          //     .style('text-overflow', 'ellipsis')
          //     .attr('title', list[r][k.key])
          // }
          if(i === 5) {
            td.style('color', list[r][k.key] > 0 ? 'red' : '#757575')
              .attr('title', list[r][k.key])
          }
          if(i === 7) {
            td.style('color', list[r][k.key] > 0 ? 'red' : '#757575')
              .attr('title', list[r][k.key])
          }
          if(i === 9) {
            td.style('color', list[r][k.key] > 0 ? 'red' : '#757575')
              .attr('title', list[r][k.key])
          }
          if(i === 11) {
            td.style('color', list[r][k.key] > 0 ? 'red' : '#757575')
              .attr('title', list[r][k.key])
          }

          // td.on('mouseover', () => tr.style('cursor', 'pointer'))
          // td.on('click', () => {
          //   // console.log(list[r].GWBS_LV3,list[r].WCCODE)
          //   let dataSource = {
          //     GWBS_LV3: list[r].GWBS_LV3,
          //     WCCODE: list[r].WCCODE
          //   }
          //   let request_ = this.getRequestColumnProps('status', dataSource, this.ColumnProps)
          //   this.$emit('request-action', request_)
          // })

          if(k.key.includes('PROG')) {
            addClass = ''
            td
            .style('position', 'relative')
            .style('color', list[r][k.key] < 0 ? 'red' : list[r][k.key] === 100 ? '#fff' : addClass == 'caution' ? 'red' : '#000')
            .style('z-index', '1')
            .html(list[r][k.key] == null ? 0+'%' :list[r][k.key]+'%')

            td.append('div')
            .style('position', 'absolute')
            .style('left', 0)
            .style('top', 0)
            .style('width', list[r][k.key] > 0 ? list[r][k.key] + '%' : 0)
            .style('height', '20px')
            .style('background-color', list[r][k.key] < 100 ? k.key.includes('PLAN') ? '#E0E0E0' : '#FFCA28' : '#4CAF50')
            .style('z-index', '-1')
          }
          else {
            if (k.type == 'float') {
              let regexp = /\B(?=(\d{3})+(?!\d))/g
              if (isNaN(Math.floor(list[r][k.key]))) td.html('')
              else td.html(Math.round(Math.floor(list[r][k.key] * 100) / 100).toString().replace(regexp, ','))
            } else td.html(list[r][k.key])
          }
        })
      }
    },
  }
}
