import Defs from '../../../../../includes/primitives/colorDefs'
import Filter from '../../../../../includes/primitives/Filter_Defs'

export default {
  data: () => ({

    Legend: 
    [ 
      { code: 'A', name: 'MC_MEET',      color: '#4DC499', },
      { code: 'B', name: 'MC_NOT',       color: '#E29FE2', },
      { code: 'C', name: 'PRE_MC',       color: '#F4E12C', },
      { code: 'D', name: 'MILESTONE',    color: '#51B8F2', },
    ],

    Schedule: 
    [ 
      { no: 1, code: '2023-09', width: 150, },
      { no: 2, code: '2023-10', width: 190, },
      { no: 3, code: '2023-11', width: 200, },
      { no: 4, code: '2023-12', width: 130, },
      { no: 5, code: '2023-01', width: 110, },
      { no: 6, code: '2023-02', width: 180, },
      { no: 7, code: '2023-03', width: 90,  },
      { no: 8, code: '2023-04', width: 130, },
      { no: 9, code: '2023-05', width: 220, },
      { no: 10, code: '2023-06', width: 130, },
      { no: 11, code: '2023-07', width: 140, },
      { no: 12, code: '2023-08', width: 100, },
      // { no: 13, code: '2023-08', width: 100, },
      // { no: 14, code: '2023-08', width: 100, },
      // { no: 15, code: '2023-08', width: 100, },
      // { no: 16, code: '2023-08', width: 100, },
      // { no: 17, code: '2023-08', width: 100, },
    ],
  }),

  methods: {

    set_Gradients() {

      let defs = this.svg.append('defs')

      let filter = defs.append('filter')
      .attr('id', 'dropshadow')
      filter.append('feGaussianBlur').attr('in', 'SourceAlpha').attr('stdDeviation', 3).attr('result', 'blur')
      filter.append('feOffset').attr('in', 'blur').attr('dx', .3).attr('dy', .3) .attr('result', 'offsetBlur')
      filter.append('feFlood').attr('in', 'offsetBlur').attr('flood-color', '#727272').attr('flood-opacity', '0.3').attr('result', 'offsetColor')
      filter.append('feComposite').attr('in', 'offsetColor').attr('in2', 'offsetBlur').attr('operator', 'in').attr('result', 'offsetBlur')


      let header = defs.append('linearGradient')
      .attr('id', `menu_box`).attr('x1', 0.5).attr('x2', 0.5).attr('y1', 0).attr('y2', 1)

      header.append('stop').attr('stop-color', '#F4F5F6').attr('offset', '0').attr('stop-opacity', 1)
      header.append('stop').attr('stop-color', '#F1F2F3').attr('offset', '0.5').attr('stop-opacity', 1)
      header.append('stop').attr('stop-color', '#E9EAEA').attr('offset', '0.8').attr('stop-opacity', 1)
      header.append('stop').attr('stop-color', '#E1E1E1').attr('offset', '1').attr('stop-opacity', 1)


      let legendA = defs.append('linearGradient')
      .attr('id', `MC_MEET`).attr('x1', 0.5).attr('x2', 0.5).attr('y1', 0).attr('y2', 1)
      legendA.append('stop').attr('stop-color', '#4DC499').attr('offset', '0').attr('stop-opacity', 1)
      legendA.append('stop').attr('stop-color', '#128255').attr('offset', '1').attr('stop-opacity', 1)

      let legendB = defs.append('linearGradient')
      .attr('id', `MC_NOT`).attr('x1', 0.5).attr('x2', 0.5).attr('y1', 0).attr('y2', 1)
      legendB.append('stop').attr('stop-color', '#E7B8E8').attr('offset', '0').attr('stop-opacity', 1)
      legendB.append('stop').attr('stop-color', '#B562C4').attr('offset', '1').attr('stop-opacity', 1)

      let legendC = defs.append('linearGradient')
      .attr('id', `PRE_MC`).attr('x1', 0.5).attr('x2', 0.5).attr('y1', 0).attr('y2', 1)
      legendC.append('stop').attr('stop-color', '#F9EB85').attr('offset', '0').attr('stop-opacity', 1)
      legendC.append('stop').attr('stop-color', '#B5A31F').attr('offset', '1').attr('stop-opacity', 1)

      let legendD = defs.append('linearGradient')
      .attr('id', `MILESTONE`).attr('x1', 0.5).attr('x2', 0.5).attr('y1', 0).attr('y2', 1)
      legendD.append('stop').attr('stop-color', '#73CAEF').attr('offset', '0').attr('stop-opacity', 1)
      legendD.append('stop').attr('stop-color', '#0780AA').attr('offset', '1').attr('stop-opacity', 1)
    }
  }
}