// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  data:() => ({
    props:[
      { module: 'ME01', moduleDescr:'Flash Gas Compression',x: 10, y: 85 },
      { module: 'ME02', moduleDescr:'MEG & Fuel Gas',x: 20, y: 80 },
      { module: 'ME04', moduleDescr:'Power Generation',x: 30, y: 60 },
      { module: 'ME05', moduleDescr:'E/I Building',x: 10, y: 80 },
      { module: 'MW01', moduleDescr:'Reception Facilities & Production Separation',x: 10, y: 80 },
      { module: 'MW02', moduleDescr:'HC Dewpointing & Condensate Stabilisation',x: 20, y: 80 },
      { module: 'MW04', moduleDescr:'Power Generation',x: 30, y: 60 },
      { module: 'MW05', moduleDescr:'Utility',x: 10, y: 80 }
    ]
  }),
  methods: {
    Draw_Charts() {
      let data = null, style = null, tmp = null
      let module = this.FilterValues && this.FilterValues.GWBS_LV3 ? this.FilterValues.GWBS_LV3 : 'ME04'
      let props = this.props.filter(d => d.module === module)[0]
      let overallSummary = this.svg
      .append('g')
      .attr('class','moduleName')
      .attr('transform', `translate(0, 0)`)

      overallSummary
      .append('image')
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Modules/${module}.png`)
      // .attr('transform', props ? `translate(${props.x} ${props.y}) ` : `translate(10, 80) `)
      .attr('transform', `translate(${props.x} ${props.y}) `)

      // Overall Plan
      tmp = this.DataItems[0]
      style = {
        id: 'CERT',
        x: 200,
        y: 100,
        ty: 109,
        name: tmp.PLAN,
        sort: 'N',
      }
      data = [
        {title: 'Completed',  value: tmp.PLAN },
        {title: 'Remain',     value: 100-tmp.PLAN }
      ]
      let Circle = {
        CircleDiameter: 28,
        CircleColorSet: ['Gray', 'LightGray'],
        CircleHoleSize: 20,
        CircleStackSize: 2,
        CircleSliceSize: 5,
        CircleValueSize: 14,
        CircleTitleSize: 12,
        CircleLegendSpace: 80,
      }
      let Guideline = {
        GuideDisplay: 'N',
      }
      overallSummary
      .append('text')
      .attr('transform', `translate(${style.x}, ${style.y - Circle.CircleDiameter - 10})`)
      .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'middle')
      .text('Plan (%)')

      this.Chart_PieSimple(overallSummary, data, style, Circle, Guideline)

      style = {
        id: 'SHI',
        x: 328,
        y: 100,
        ty: 109,
        name: tmp.ACTUAL,
        sort: 'N',
      }
      data = [
        {title: 'Completed',  value: tmp.ACTUAL },
        {title: 'Remain',     value: 100-tmp.ACTUAL }
      ]
      Circle.CircleColorSet = ['LightBlue', 'LightGray']
      
      overallSummary
      .append('text')
      .attr('transform', `translate(${style.x}, ${style.y - Circle.CircleDiameter - 10})`)
      .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'middle')
      .text('Actual (%)')

      this.Chart_PieSimple(overallSummary, data, style, Circle, Guideline)

      let box = overallSummary
      .append('g')
      .attr('transform', `translate(236, 40)`)

      box
      .append('text')
      .attr('transform', `translate(25, 52)`)
      .style('font-size', 9)
      .style('font-family', 'roboto')
      .attr('fill', '#959595')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text('Delta (%)')

      box
      .append('text')
      .attr('transform', `translate(25, 70)`)
      .style('font-size', 14)
      .style('font-family', 'roboto')
      .attr('fill', tmp.DELTA >= 0 ? '#1e88e5' : '#bf360c')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text(tmp.DELTA)

      let title = overallSummary
      .append('g')
      .attr('transform', `translate(10, 30)`)

      title
      .append('text')
      .attr('transform', `translate(0,0)`)
      .style('font-size',16).style('font-family','roboto').attr('fill','#01579B')
      .text(`${module} -`)

      title
      .append('text')
      .attr('transform', `translate(60,0)`)
      .attr('text-anchor', 'start')
      .style('font-size', 13).style('font-family', 'roboto').attr('fill', '#0277BD')
      .text(props.moduleDescr)
    },
  }
}
           