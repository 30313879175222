import * as d3 from 'd3'
// ------------------------------------------------------------------------------- Global
export default {
  data: () => ({
    items: [
      { code: 'P1' , color:'#B3E5FC', stroke:'#81D4FA',path: 'M 240 107 L 304 105 L 303 172 L 391 174 L 392 311 L 518 313 L 518 397 L 391 397 L 393 416 L 356 416 L 356 398 L 303 397 L 303 289 L 241 289 L 240 105 Z' },
      { code: 'P2' , color:'#FFECB3', stroke:'#FFE082',path: 'M 97 66 L 147 66 L 147 106 L 240 106 L 241 291 L 148 292 L 146 418 L 130 418 L 94 361 L 94 280 L 97 273 L 97 142 L 93 127 L 93 80 L 98 67 Z' },
      { code: 'P3' , color:'#FFCDD2', stroke:'#EF9A9A',path: 'M 96 65 L 94 62 L 94 8 L 167 7 L 240 14 L 240 63 L 270 65 L 270 79 L 321 80 L 325 130 L 392 130 L 392 399 L 568 399 L 566 488 L 393 487 L 394 445 L 357 444 L 357 417 L 391 417 L 392 173 L 305 173 L 304 106 L 149 104 L 148 65 L 86 65 L 86 64 L 86 166 L 75 167 L 74 250 L 48 251 L 37 238 L 38 148 L 71 130 L 74 133 L 75 167 L 86 166 L 86 65 L 96 65 Z' },
    ]
  }),
  methods: {
    Draw_Charts() {

      this.innerSvg = this.svg.append('svg')
      let Overall = this.innerSvg
      .append('g')
      .attr('id', '_OverallGroup').attr('transform', `translate(0.5, 0.5)`)

      Overall 
      .append('image')
      .attr('id', `_image_map`)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/China1/Images/Execution.png`)
      .attr('transform', `translate(0, 0)`)

      let packcage= this.DataItems.map(d=>d.PACKAGE)

      let pItem= this.items.filter(d=> d.code ==packcage)[0];
      //pItem.forEach(k => {
        let layer = Overall
        .append('g')
        .attr('id', `area__`)
        .attr('transform', `translate(0, 0)`)

        let layerPath = layer
        .append('g').attr('transform', `translate(-20, 0)scale(0.725)`)

        layerPath
        .append('path').attr('id', `Mask_`)
        .attr('d', pItem.path)
        .style('fill', pItem.stroke)
        .style('fill-opacity', .6).attr('stroke-width', 1.5).attr('stroke', pItem.color)
        .style('cursor', 'pointer')

        // this.pathes[k].items.forEach(item => {
        //   if(!this.DataItems[0][k].find(d => d.CODE == item.code)) return

        //   let layerPath = layer
        //   .append('g').attr('transform', `translate(0, 0)`)

        //   layerPath
        //   .append('path').attr('id', `Mask_${item.code}`)
        //   .attr('d', item.path)
        //   // .style('fill', this.pathes[k].bcolor)
        //   .style('fill', '#f4f4f4')
        //   .style('fill-opacity', .4).attr('stroke-width', 6).attr('stroke', this.pathes[k].lcolor)
        //   .style('cursor', 'pointer')
        //   .on('mouseover', (_, i, a) => {
        //     let el = d3.select(a[i])
        //     el.transition().style('fill-opacity', 0.8).style('fill', this.pathes[k].bcolor)
        //     d3.select(`.textBox_${item.code}`).transition().attr('font-size', 30).attr('fill', '#000')
        //   })
        //   .on('mouseout', (_, i, a) => {
        //     let el = d3.select(a[i])
        //     el.transition().style('fill-opacity', .4).style('fill', '#f4f4f4')
        //     d3.select(`.textBox_${item.code}`).transition().attr('font-size', 14).attr('fill', '#fff')
        //   })
        //   .on('click', () => {
        //     let dataSource = { BID_SECTION_PACK: this.DataItems[0][k].find(d => d.CODE == item.code).CODE ,JIANLI_SUPERVISION_WORK_PACKAGE: this.DataItems[0][k].find(d => d.CODE == item.code).CODE ,QUALITY_STATION_AREA: this.DataItems[0][k].find(d => d.CODE == item.code).CODE }
        //     let request_ = this.getRequestColumnProps(k, dataSource, this.ColumnProps)
        //     this.$emit('request-action', request_)
        //   })
        
      //})

        //.call(this.callFuncSearchFilter)



      // /**
      //  * Svg Path Tool to get the clicked mouse point (x, y)
      //  */
      // this.createPathReigon(this.innerSvg, {
      //   w: 1600,
      //   h: 1600,
      //   strokeWidth: 2,
      //   strokeColor: '#ff00ff'
      // })

      
    },
  }
}
