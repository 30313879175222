import * as d3 from 'd3'
import __M from 'moment'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({
    mainTimeline: null,
    timelineFloat: {
      start: 10,
      end: 6
    },
    subTimelineFloat: {
      start: 2,
      end: 2
    },
    timeStartDate: '',
    timeEndDate: '',
    timelineWidth: 0,
    timeScale: null,
    timelineTickValues: null,

    timelineMonthWidth: 35,
    cutoff: '',
    pointerPath: 'M25,0 50,50 0,50 z',
  }),
  methods: {
    drawTimeline() {
      
      this.timeScale = null
      this.timelineValues = {
        year: [],
        month: [],
      }

      let startDate = __M(__M(`${this.timeStartDate} 00:00:00`).toDate()).add(-this.timelineFloat.start, 'months').toDate()
      let endDate = __M(__M(`${this.timeEndDate} 23:59:59`).toDate()).add(this.timelineFloat.end, 'months').toDate()
      

      let noOfMonth = endDate.getMonth() - startDate.getMonth()
      let noOfYear = endDate.getYear() - startDate.getYear()
      noOfMonth = noOfMonth + (noOfYear*12)
      
      // ### Set Timeline-Values for the Year ### ---------------------------
      let prevYearDate_ = startDate

      for(var i=0; i<noOfMonth; i++) {
        let _nextMonthDate__ = new Date(new Date(startDate).setMonth(startDate.getMonth() + i))

        // For the Year ---
        if(d3.timeFormat('%Y')(prevYearDate_) != d3.timeFormat('%Y')(_nextMonthDate__)) {
          let midDateYear_ = new Date(
            d3.mean([
              prevYearDate_,                                                                    // 1st date of the year
              new Date(new Date(_nextMonthDate__).setMonth(_nextMonthDate__.getMonth() + 1) - 1)  // last date of the year
            ])
          )
          this.timelineValues.year.push({ name: d3.timeFormat('%Y')(midDateYear_), value: new Date(midDateYear_) })
          prevYearDate_ = _nextMonthDate__
        }
      }

      // Year remained
      let midDateYear_ = new Date(
        d3.mean([
          prevYearDate_,  // 1st date of the year
          endDate        // last date of the year
        ])
      )
      this.timelineValues.year.push({ name: d3.timeFormat('%Y')(midDateYear_), value: new Date(midDateYear_) })
      // ### Set Timeline-Values for the Year ### ---------------------------

      // ### Set Timeline-Values for the Month ### --------------------------
      for(i=0; i<=noOfMonth; i++) {
        let _nextMonthDate__ = new Date(new Date(startDate).setMonth(startDate.getMonth() + i))

        // For the Month ---
        let endMonth_ = new Date(new Date(_nextMonthDate__).setMonth(_nextMonthDate__.getMonth() + 1) - 1)
        let midDate = new Date(
          d3.mean([
            _nextMonthDate__, // 1st date of the month
            endMonth_         // last date of the month
          ])
        )
        this.timelineValues.month.push({ 
          name: d3.timeFormat('%b')(midDate), 
          value: new Date(midDate),
          endDate: endMonth_
        })
      }
      
      this.timelineWidth = this.timelineValues.month.length * this.timelineMonthWidth

      // get min & max values of the timeline-scale
      this.timeScale = d3.scaleTime()
      .domain([startDate, endDate])
      .range([0, this.timelineWidth])
      
      // Timeline -------------------------------------------------------------------------------------------------------------------------
      
      this.y_coordinate += this.height.mainTimeline

      let mainTimeline = this.svgGroup
      .append('g')
      .attr('transform', `translate(${this.margin.left}, ${this.y_coordinate})`)  // Will be Fixed : y position
      .attr('id', 'MainTineline')

      mainTimeline
      .append('path') // shadow
      .attr('d', `M50,42 H ${this.timelineWidth-50} L ${this.timelineWidth},52 H0 Z`).attr('fill', `url(#shadowTimeline)`) 

      mainTimeline
      .append('rect')
      .attr('x', 0).attr('y', 0).attr('width', this.timelineWidth).attr('height', 40).attr('fill', `url(#${this.localId}__LinearA4LightGray0)`).attr('opacity', .5)

      mainTimeline
      .append('rect') 
      .attr('x', 0).attr('y', 40).attr('width', this.timelineWidth).attr('height', 6).attr('fill', `url(#${this.localId}__LinearA4LightGray0)`)

      // Timeline Text using the scale function
      // Year
      mainTimeline
      .append('g')
      .selectAll('text')
      .data(this.timelineValues.year)
      .enter()
      .append('text')
      .attr('x', d => this.timeScale(d.value))
      .attr('y', 15).style('font-size', 15).style('fill', '#EC407A').attr('font-weight', 500)
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(d => d.name.toUpperCase())

      // Month
      mainTimeline
      .append('g')
      .selectAll('text')
      .data(this.timelineValues.month)
      .enter()
      .append('text')
      .attr('x', d => this.timeScale(d.value))
      .attr('y', 30).style('font-size', 10).style('fill', '#333')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(d => d.name.toUpperCase())

      // Timeline Seperator
      mainTimeline
      .append('g')
      .selectAll('path')
      .data(this.timelineValues.month)
      .enter()
      .append('path')
      .attr('d', (d, i) => {
        if (this.timelineValues.month.length-1 > i) {
          if (d.name == 'Dec')return `M${this.timeScale(d.endDate)}, 7 V35`
          else return `M${this.timeScale(d.endDate)}, 25 V35`
        }
      })
      .attr('stroke', '#757575').attr('stroke-width', 0.3)

      //  ===========================================================
      
      let collapsedBtn = mainTimeline
      .append('g')
      .attr('transform', `translate(0, 55) `)

      collapsedBtn.append('rect')
      .attr('width', 40)
      .attr('height', 40)
      .attr('fill', '#fff')
      .attr('stroke', '#ccc').attr('stroke-width', 1)
      .attr('rx', 8)

      collapsedBtn
      .append('g')
      .append('path')
      .attr('transform', `translate(12, 12)`)
      .attr('class', '__draw_info_arrow_')
      .attr('d', `M2 12.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z`)

      // `M3.646 14.854a.5.5 0 0 0 .708 0L8 11.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708zm0-13.708a.5.5 0 0 1 .708 0L8 4.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zM1 8a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 8z`
      // `M3.646 10.146a.5.5 0 0 1 .708 0L8 13.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-4.292a.5.5 0 0 0 .708 0L8 2.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708zM1 8a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 8z`
      
      collapsedBtn
      .on('mouseover', function(){ d3.select(this).transition().duration(100).style('opacity',.8) })
      .on('mouseout', function(){ d3.select(this).transition().duration(100).style('opacity',1) })
      .style('cursor', 'pointer')
      .on('click', () => {

        this.Collapsed_Click(collapsedBtn,false)
        
        this.redraw()
      })

      // draw indicator ---------------------------
      let pointer = mainTimeline
      .append('g')
      .attr('id', 'item_indicator')
      .attr('transform', `translate(${this.timeScale(__M(new Date(this.cutoff)).toDate())}, 45), rotate(-180) scale(.2)`)

      // shadow //
      pointer
      .append('g')
      .attr('id', 'item_indicator_shadow')
      .attr('transform', 'translate(0, 1) scale(1.12)')
      .append('path')
      .attr('d', this.pointerPath)
      .attr('fill', '#000')
      .attr('fill-opacity', .08)

      pointer
      .append('g')
      .attr('id', 'item_indicator_shadow')
      .attr('transform', 'translate(0, 1) scale(1.1)')
      .append('path')
      .attr('d', this.pointerPath)
      .attr('fill', '#000')
      .attr('fill-opacity', .08)

      pointer
      .append('g')
      .attr('id', 'item_indicator_shadow')
      .attr('transform', 'translate(0, 1) scale(1.07)')
      .append('path')
      .attr('d', this.pointerPath)
      .attr('fill', '#000')
      .attr('fill-opacity', .08)

      // indicator //
      pointer
      .append('path')
      .attr('d', this.pointerPath)
      .attr('fill', '#fa2c72')
      .attr('stroke', '#970234')
      .attr('stroke-width', 1)

    },

    Collapsed_Click (selection,flag_) {
      // areas, phases 중 무언가 하나라도 열려있으면 다 닫기
      // pacakages true, areas, phases false면 다 열기
      let flag = false
      this.packages.forEach(p => {
        this.collapsed[p.P6_LV1] = true
        this.areas.filter(a => a.P6_LV1 == p.P6_LV1).forEach(a => {
          this.phases.filter(ph => ph.P6_LV1 == a.P6_LV1 && ph.P6_LV2 == a.P6_LV2).forEach(ph => {
            if (this.collapsed[`${a.P6_LV1}${a.P6_LV2}`] || this.collapsed[`${ph.P6_LV1}${ph.P6_LV2}${ph.P6_LV3}`]){
              flag = true
            }
          })
        })
      })
      // pacakages & ( areas || phases ) == true 면 다 닫기
      if (flag) {
        this.packages.forEach(p => {
        this.collapsed[p.P6_LV1] = true

          this.areas.filter(a => a.P6_LV1 == p.P6_LV1).forEach(a => {
            this.collapsed[`${a.P6_LV1}${a.P6_LV2}`] = false
            this.phases.filter(ph => ph.P6_LV1 == a.P6_LV1 && ph.P6_LV2 == a.P6_LV2).forEach(ph => {
              this.collapsed[`${ph.P6_LV1}${ph.P6_LV2}${ph.P6_LV3}`] = false
            })
          })
        })
      // pacakages & ( areas || phases ) == false 면 다 열기
      }else {
        this.packages.forEach(p => {
        this.collapsed[p.P6_LV1] = true

          this.areas.filter(a => a.P6_LV1 == p.P6_LV1).forEach(a => {
            this.collapsed[`${a.P6_LV1}${a.P6_LV2}`] = true
            this.phases.filter(ph => ph.P6_LV1 == a.P6_LV1 && ph.P6_LV2 == a.P6_LV2).forEach(ph => {
              this.collapsed[`${ph.P6_LV1}${ph.P6_LV2}${ph.P6_LV3}`] = true
            })
          })
        })
      }

      //console.log(`Collapsed_Click `, this.collapsed, flag)
      
      if (flag) {
        selection
        .append('g')
        .append('path')
        .attr('transform', `translate(12, 12)`)
        .attr('class', '__draw_info_arrow_')
        .attr('d', `M3.646 14.854a.5.5 0 0 0 .708 0L8 11.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708zm0-13.708a.5.5 0 0 1 .708 0L8 4.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zM1 8a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 8z`
        )
      } else {
        selection
        .append('g')
        .append('path')
        .attr('transform', `translate(12, 12)`)
        .attr('class', '__draw_info_arrow_')
        .attr('d', `M3.646 10.146a.5.5 0 0 1 .708 0L8 13.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-4.292a.5.5 0 0 0 .708 0L8 2.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708zM1 8a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 8z`)
      }

      //for pdf
      this.collFlag = !this.collFlag
      this.selectedItems = { 
        LEVEL: 'COLL',
        P6_LV1: '',
        P6_LV2: '',
        P6_LV3: '',
        FLAG : flag,
        CALLFLAG : this.collFlag,
        DRAWFLAG : this.drawFlag,
      }
    }
  }

}
