export default {
  watch: {
    DataItems: { 
      handler(val) { 
        if(!val || val.length === 0) return

        if(this.filterRequested != 'n/a') {
          if(this.filterRequested == 'dataitem') {
            this.setData()
            this.Chart_filtering()

          } else { 
            // this.filterRequested == 'full' => "Schedule" option was changed
            this.draw()
          }

          this.filterRequested = 'n/a'

        } else if(this.chartMounted) {
          // 1. not ready to draw on mounted
          // 2. Filter Values are changed

          // this.activity = 'MC_B29'
          // this.filterCodes.Schedule.values.forEach(value => { value.on = false })
          // this.filterCodes.Schedule.values[1].on = true
    
          this.register(this.draw)
        }
      }, 
      deep: true 
    },
    FilterValues: {
      handler(val) { 
        // 
      }
    }
  },
}
