import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  methods: {
    Charts_ModuleDivision() {

      this.SVG_Module = this.svg.append('svg')

      this.SVG_Module
      .attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight)

      
      this.MODULES[this.Area].forEach (mod => {

        this.SVG_Module
        .append('text')
        .attr('id', `mText_${mod.module}`)
        .attr('transform', `translate(${mod.x}, ${mod.y})`)
        .style('font-family', 'roboto').style('font-size', 14).style('fill', '#333').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text(mod.module)

        // Not applicable to BSEE
        if(mod.item !== 'Y') {
          this.SVG_Module
          .append('text')
          .attr('transform', `translate(${mod.x}, ${mod.y+ 12})`)
          .style('font-family', 'roboto').style('font-size', 10).style('fill', '#F7BACF').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
          .text('Not applicable to BSEE')
        }

        d3.select(`#${mod.module}`)
        .transition().duration(500)
        .attr('opacity', () => {
          if(mod.item == 'Y') return 1; else return 0.3
        })
        .style('cursor', () => {
          if(mod.item == 'Y') return 'pointer'; else return 'default'
        })

        if(mod.item == 'Y') {
          let data = this.dataSet.filter(f => f.MODULE == mod.module)
          let status = [
                      {name: 'N/A',         code: 'PO_PLAN'     ,bColor:'#FFE000',  opacity: 0.7},
                      {name: 'REQ',         code: 'PO_ACTUAL'   ,bColor:'#CFCD1A',  opacity: 0.7},
                      {name: 'PO',          code: 'PO_ACTUAL'   ,bColor:'#7BAF30',  opacity: 0.7},
                      {name: 'Delivered',   code: 'ETA_ACTUAL'  ,bColor:'#2F993D',  opacity: 0.7},
                      {name: 'Installed',   code: 'INSTALL'     ,bColor:'#18634C',  opacity: 0.7},
              ] 
          let style = {
                      barSize: 100,       
                      distance: 16, 
                      fSize: 10,
                      height: 12,
                      x: mod.x,
                      y: mod.y,
          }
          this.Bar_Activity_02(this.SVG_Module, mod.module, data, status, style)
        }
      })

    },


    Bar_Activity_02(selection, _mod, _data, _status, _style) {
  
      _status.forEach(s => {
        s.total = _data.length
        s.actual = _data.filter(f=> f[s.code] !== null).length
      })

      let x_ = _style.x + this.getNodeElValue(`#mText_${_mod}`, 'width')
      let y_ = _style.y


      let EQstatus = selection
      .append('g')
      .attr('transform', `translate(${x_}, ${y_})`)
      
      _status.forEach((d, i)=> {
        let actualLen = (d.actual / d.total) * _style.barSize
        let distance = _style.distance

        EQstatus
        .append('text')
        .attr('transform', `translate(${-3}, ${(i*distance) + 18})`)
        .style('font-family', 'roboto').style('font-size', _style.fSize).style('fill', '#757575').style('text-anchor', 'end').attr('alignment-baseline', 'middle').text(d.name)

        EQstatus
        .append('rect')
        .attr('id', `bar_${_mod}_${i}`)
        .attr('transform', `translate(0, ${(i*distance) + 12})`)
        .attr('width', 0).attr('height', _style.height).style('fill', d.bColor).style('opacity', d.opacity)
        
        d3.select(`#bar_${_mod}_${i}`)
        .transition().duration(500).attr('width', actualLen)

        EQstatus
        .append('text')
        .attr('transform', `translate(${actualLen - 2}, ${(i*distance) + 18})`)
        .style('font-family', 'roboto').style('font-size', 9).style('fill', '#fff').style('text-anchor', 'end').attr('alignment-baseline', 'middle').text(d.actual)

        EQstatus
        .append('text')
        .attr('transform', `translate(${_style.barSize + 2}, ${(i*distance) + 18})`)
        .style('font-family', 'roboto').style('font-size', 9).style('fill', '#757575').style('text-anchor', 'start').attr('alignment-baseline', 'middle').text(d.total)
      })
    },


  }
}
           