import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({

    props:[
      {head1: 'Discipline', x: 90, tx: 60, cy: 0, textanchor: 'start'},
      {head1: 'Activity', x: 300, tx: 180, cy: 0, textanchor: 'start'},
      {head1: 'Unit', x: 500, tx: 500, cy: 0},
      {head1: 'Total', x: 550, tx: 550, cy: 0},
      {head1: 'Target', x: 610, tx: 610, cy: 0},
      {head1: 'Actual', x: 670,  tx: 670, cy: 0},
      {head1: 'Remain', x: 730, tx: 730, cy: 0},
    ],
    width: 760,
    disc: null,

    scrollProps: {
      w: 760,
      h: 630,
      g: 0,
      bar: {
        align : 'right',
        gap   : 3,
        radius: 2,
        width : 4,
        fill  : '#9E9E9E',
        stroke: '#9E9E9E',
      }
    }
  }),
  

  methods: {
    
    drawChart() {
      this.disc = [...new Set(this.dataSet.map(d => d.DISC))]

      let PreOutfitting = this.svg
      .append('g')
      .attr('transform', `translate(0, 5.5)`)

      

      PreOutfitting
      .append('rect')
      .attr('x',20).attr('y',40).attr('width',this.width - 20).attr('height',30).attr('stroke-width', 0).attr('fill', '#F4F5F5').attr('opacity', 0.4)

      PreOutfitting.append('line').attr('x1',20).attr('y1',40).attr('x2',this.width).attr('y2',40).attr('stroke-width', 0.3).attr('stroke', '#333')
      PreOutfitting.append('line').attr('x1',20).attr('y1',70).attr('x2',this.width).attr('y2',70).attr('stroke-width', 0.3).attr('stroke', '#333')
    

      // header
      PreOutfitting
        .append('g')
        .attr('transform', `translate(0, 58)`)
        .selectAll('text')
        .data(this.props)
        .enter()
        .append('text')
        .attr('x', d => d.x)
        .attr('y', 0)
        .style('font-family','roboto')
        .style('font-size',10)
        .attr('text-anchor', 'middle')
        .attr('fill', '#757575')
        .text(d => d.head1)


        // tbody group
        let Items = PreOutfitting
        .append('g')
        .attr('transform', `translate(0,70)`)
        .attr('class', 'item-group')

        this.setScroll(Items, this.scrollProps)

        
        let sumY = 0
        // Discipline Name
        Items
          .append('g')
          .attr('transform', `translate(0, 0)`)
          .selectAll('text')
          .data(this.disc)
          .enter()
          .append('text')
          .attr('x', 90)
          .attr('y', (d,i) => {
            const filter = this.dataSet.filter(s => s.DISC === this.disc[i - 1])
            sumY += i > 0 ? (18 * filter.length) : 0
            return sumY + 3.5
          })
          .style('font-family','roboto')
          .style('font-size',10)
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'hanging')
          .attr('fill', '#000')
          .text(d => d)


        this.dataSet.forEach((data, r) => {
          let values_ = Object.values(data)
          let sub_Item_ = Items
          .append('g')
          .attr('class', '_one_row_data_group')
          .attr('transform', `translate(0, ${r * 18})`)

          sub_Item_
          .selectAll('text')
          .data(values_.slice(2,8))
          .enter()
          .append('text')
          .attr('x', (d,i) => this.props[i+2].tx)
          .attr('y', () => 3.5)
          .style('font-family','roboto')
          .style('font-size',10)
          .attr('text-anchor', (d, i) =>  this.props[i+1].textanchor ? this.props[i+1].textanchor : 'middle' )
          .attr('alignment-baseline', 'hanging')
          .attr('fill', '#757575')
          .text((d) => d)


          sub_Item_
          .append('g')
          .attr('clip-path', 'url(#activity_overflow_none_discipline)')
          .append('text')
          .attr('transform', () => { 
            return `translate(${this.props[1].tx}, 3.5)`
          })
            .style('font-size',10)
            .attr('fill', '#454545')
            .attr('alignment-baseline', 'hanging')
            .text(values_.slice(1, 2))
        })

        // table body line
        let sumLineY1 = 0
        let sumLineY2 = 0
        Items
          .append('g')
          .attr('transform', `translate(20, 0)`)
          .selectAll('line')
          .data(this.disc)
          .enter()
          .append('line')
          .attr('x1', 0)
          .attr('y1', (d,i) => {
            const filter = this.dataSet.filter(s => s.DISC === this.disc[i])
            sumLineY1 += (18 * filter.length)
            return sumLineY1
          })
          .attr('x2', this.width -20)
          .attr('y2', (d,i) => {
            const filter = this.dataSet.filter(s => s.DISC === this.disc[i])
            sumLineY2 += (18 * filter.length)
            return sumLineY2
          })
          .attr('stroke-width', 0.3)
          .attr('stroke', '#bcbcbc')
          
    }, 

  }
}
