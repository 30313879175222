import * as d3 from 'd3'
import __M from 'moment'

export default {
  data: () => ({
    SVG_SystemBoundary: null,
    System: 3020,
    mode_Menu: 'B',

    filtering: [],

    ChartPlan: false,
    ChartActual: false,
    ChartValue: 'quantity',
  }),
  computed: {

    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },

  },



  methods: {
    setDefaultValues(){
      this.cutoff = '2020-02-28'
      this.timelineKeyName = 'COMM_DATE'

      this.timeline.plan = []
      this.timeline.actual = []

      this.dataSet = JSON.parse(JSON.stringify(this.DataItems.filter(f => f.COMM_DATE !== null)))
      this.setTimelineEnv(this.dataSet)

      this.dataSet.forEach(d => {
        d.cDate = this.getTimelineCDate(d.COMM_DATE).eDate
        d.sDate = this.getTimelineCDate(d.COMM_DATE).sDate
        d.date = d3.timeParse('%Y-%m-%d')(d.COMM_DATE)
        d.display = 'on'
      })

      this.lineChartData = JSON.parse(JSON.stringify(this.Queries.SQL2.filter(f => f.PHASE == 'B')))
      this.lineChartData.forEach(d => {
        d.cDate = this.getTimelineCDate(d.DATE).eDate
      })

      this.style[0].item.forEach(item => {
        this.dataSet.filter( f => f[item.refColumn] == item.codeValue).forEach(d => {
          d.tStyle   = item.tStyle
          d.tSize    = item.tSize
          d.tColor   = item.tColor
          d.sWidth   = item.sWidth
          d.sColor   = item.sColor
          d.bColor   = item.bColor
          d.opacity  = item.opacity
          d.radius   = item.radius
          if (item.dataColumn != '') d.progress = 'Y'
        })
      })

      this.style[1].item.forEach(item => {
        this.dataSet.filter( f => f[item.refColumn] == item.codeValue).forEach(d => {
          d.sWidth   = item.sWidth
          d.sColor   = item.sColor
          if (item.dataColumn != '') d.progress = 'Y'
        })
      })

     

      this.timeline.height = this.timeline.style.week.height
      if (this.timeline.style.month.display == 'Y') this.timeline.height += this.timeline.style.month.height
      if (this.timeline.style.year.display == 'Y') this.timeline.height += this.timeline.style.year.height
      
      // Count thr number of Box by week
      this.timeline.week.forEach( (w, i) => {
        let count = 0
        this.dataSet.filter(f => f.cDate == w.eDate).forEach( d => {
          count += 1
        })
        this.timeline.weekCount.push(count)

        this.timeline.plan.push({ 
          eDate: w.eDate, 
          mDate: w.mDate,
          sDate: w.sDate,
          qty: this.lineChartData.find(f => f.cDate == w.eDate).BPQ,
          prog: this.lineChartData.find(f => f.cDate == w.eDate).BPP
        })

        if(__M(w.eDate).format('YYYY-MM-DD') > __M(this.cutoffDate).format('YYYY-MM-DD')) return

        this.timeline.actual.push({ 
          eDate: w.eDate, 
          mDate: w.mDate,
          sDate: w.sDate,
          qty: this.lineChartData.find(f => f.cDate == w.eDate).BAQ,
          prog: this.lineChartData.find(f => f.cDate == w.eDate).BAP
        })
      })

      

      let realHeight = Math.max(...this.timeline.weekCount) * (this.skyline.box.height + (this.skyline.box.gap * 2))
      if (realHeight > this.skyline.minSize) {
        this.timeline.baseY = realHeight
      } else {
        this.timeline.baseY = this.skyline.minSize
      }

      // console.log(Math.max(...this.timeline.weekCount))
      // console.log(this.dataSet)
      this.SVG_SystemBoundary = this.svg.append('svg')
    },
    
  }
}