export default {
  watch: {
    Canvas: { handler(val) { if(!val || Object.keys(val).length === 0) return; this.register(this.draw); }, deep: true },
    Queries: { handler(val) { if(!val || Object.keys(val).length === 0) return; this.register(this.draw); }, deep: true },
    Note: { handler(val) { if(!val || Object.keys(val).length === 0) return; this.register(this.draw); }, deep: true },
    Text: { handler(val) { if(!val || Object.keys(val).length === 0) return; this.register(this.draw); }, deep: true },
    //for pdf
    selectedItems() {
      this.$emit('selectedItems', this.selectedItems)
    },
    //for pdf & change area
    FilterValues: {
      handler(val) {
        if(!this.CurrentArea || this.CurrentArea == this.FilterValues.SEICODE_L1) return        
        // console.log('FilterValues-val', val)
        // console.log('FilterValues-CurrentArea', this.CurrentArea)
        // console.log('FilterValues-SEICODE_L1', this.FilterValues.SEICODE_L1)
        this.register(this.redraw)
      },
      deep: true
    },
  },
}