import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({
    eqStatus: [
      { code: 'PO',          title: 'PO',                          colName: { total: 'PO_TOTAL'                 , actual: 'PO_ACTUAL'     }, bColor: '#84C7E5', sColor: '#00838F', tColor: '#333', opacity: 1.00, },
      { code: 'FAT',         title: 'FAT',                         colName: { total: 'FAT_TOTAL'                , actual: 'FAT_ACTUAL'    }, bColor: '#2281C5', sColor: '#9F368B', tColor: '#fff', opacity: 1.00, },
      { code: 'IRN',         title: 'IRN',                         colName: { total: 'IRN_TOTAL'                , actual: 'IRN_ACTUAL'    }, bColor: '#CC93C1', sColor: '#9F368B', tColor: '#333', opacity: 1.00, },
      { code: 'ETA',         title: 'ON Site',                     colName: { total: 'ONSITE_TOTAL'             , actual: 'ONSITE_ACTUAL' }, bColor: '#F6D15E', sColor: '#F9A825', tColor: '#333', opacity: 1.00, },
      { code: 'INST',        title: 'Installed',                   colName: { total: 'INST_TOTAL'               , actual: 'INST_ACTUAL'   }, bColor: '#8DBA2D', sColor: '#558B2F', tColor: '#fff', opacity: 1.00, },
      { code: 'NOT_PO',      title: 'Not PO',                      colName: { total: 'NOT_PO_CNT'               , actual: ''              }, bColor: '#ffffff', sColor: '#BDBCBC', tColor: '#333', opacity: 1.00, },
      { code: 'ROS_PAST',    title: 'ETA is past from ROS',        colName: { total: 'ETA_PAST_FROM_ROS'        , actual: ''              }, bColor: '#F7BACF', sColor: '#BDBCBC', tColor: '#333', opacity: 1.00, },
      { code: 'UPBLOCK_PAST',title: 'ETA is past from  Upper Blk.',colName: { total: 'ETA_PAST_FROM_UPPERBLOCK' , actual: ''              }, bColor: '#EC407A', sColor: '#BDBCBC', tColor: '#fff', opacity: 1.00, },
    ],
  }),

  methods: {
    drawChart() {
      let equipments_ = this.DataItems

      let svg = this.svg
      let g = svg.append('g').attr('class', 'equipment_bar_status__').attr('transform', `translate(0.5, 0.5)`)
      
      let eqItemSet = []
      let eqStyle = {
        x: 10,
        y: 10,
        headTextSize: 12,
        headTitle: '',
        subTextSize: 14
      }

      this.eqStatus.forEach((d__, i) => {
        let tColName = this.eqStatus[i]['colName']['total']
        let aColName = this.eqStatus[i]['colName']['actual']

        if (this.FilterValues && this.FilterValues.GWBS_LEVEL) {

          eqItemSet.push({
            name: d__.title,
            total: equipments_.filter(d => d.GWBS_LEVEL === this.FilterValues.GWBS_LEVEL)[0][tColName],
            actual: equipments_.filter(d => d.GWBS_LEVEL === this.FilterValues.GWBS_LEVEL)[0][aColName],
            filter: {
              STATUS: d__.code,
              LV2: equipments_[0].LV2,
              LV4: equipments_.filter(d => d.GWBS_LEVEL === this.FilterValues.GWBS_LEVEL)[0].LV4
            },
            bColor: this.eqStatus[i].bColor,
            opacity: this.eqStatus[i].opacity,
          })
          
          
        }else {
          if (equipments_[0].LV2 === 'MODULES') {
            eqItemSet.push({
              name: d__.title,
              total: equipments_.reduce((a, b) => a + (b[tColName] || 0), 0),
              actual: equipments_.reduce((a, b) => a + (b[aColName] || 0), 0),
              filter: {
                STATUS: d__.code,
                LV2: equipments_[0].LV2,
                GWBS_LV3: equipments_[0].GWBS_LV3
              },
              bColor: this.eqStatus[i].bColor,
              opacity: this.eqStatus[i].opacity,
            })
          } else {
            eqItemSet.push({
              name: d__.title,
              total: equipments_.reduce((a, b) => a + (b[tColName] || 0), 0),
              actual: equipments_.reduce((a, b) => a + (b[aColName] || 0), 0),
              filter: {
                STATUS: d__.code,
                LV2: equipments_[0].LV2,
              },
              bColor: this.eqStatus[i].bColor,
              opacity: this.eqStatus[i].opacity,
            })
          }
        }
      })
      this.Bar_Quantity_02(g, eqItemSet, eqStyle, 0) // draw equipment bar chart

    },

  }
}