import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  methods: {

    Charts_ModuleDeck(_mod) {

      this.SVG_ModuleDeck = this.svg.append('svg')

      this.SVG_ModuleDeck
      .attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight)

      // console.log('module:', _mod)
      // console.log(
      //   this.MODULES[this.Area].find(f=> f.module == _mod).x3,
      //   this.MODULES[this.Area].find(f=> f.module == _mod).y3
      // )

      let x3 = this.MODULES[this.Area].find(f=> f.module == _mod).x3
      let y3 = this.MODULES[this.Area].find(f=> f.module == _mod).y3 - 250

      this.DECKS[this.Area].filter(f=> f.module == _mod).forEach (dk => {

        this.SVG_ModuleDeck
        .append('text')
        .attr('id', `mText_${dk.deck}`)
        .attr('transform', `translate(${x3 + dk.x}, ${y3 +dk.y - 13})`)
        .style('font-family', 'roboto').style('font-size', 14).style('fill', '#000').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text(_mod)

        this.SVG_ModuleDeck
        .append('text')
        .attr('id', `mText_${dk.deck}`)
        .attr('transform', `translate(${x3 + dk.x}, ${y3 +dk.y})`)
        .style('font-family', 'roboto').style('font-size', 13).style('fill', '#757575').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text(dk.name)

        d3.select(`#${dk.deck}`)
        .transition().duration(300)
        .attr('opacity', () => {
          if(dk.item == 'Y') return 1; else return 0.3
        })
        .style('cursor', () => {
          if(dk.item == 'Y') return 'pointer'; else return 'default'
        })

        if(dk.item == 'Y') {
          let data = this.dataSet.filter(f => f.SVG == dk.deck)
          let status = [
                      {name: 'N/A',         code: 'PO_PLAN'     ,bColor:'#FFE000',  opacity: 0.7},
                      {name: 'REQ',         code: 'PO_ACTUAL'   ,bColor:'#CFCD1A',  opacity: 0.7},
                      {name: 'PO',          code: 'PO_ACTUAL'   ,bColor:'#7BAF30',  opacity: 0.7},
                      {name: 'Delivered',   code: 'ETA_ACTUAL'  ,bColor:'#2F993D',  opacity: 0.7},
                      {name: 'Installed',   code: 'INSTALL'     ,bColor:'#18634C',  opacity: 0.7},
              ] 
          let style = {
                      barSize: 100,       
                      distance: 16, 
                      fSize: 10,
                      height: 12,
                      x: x3 + dk.x,
                      y: y3 + dk.y,
          }
          this.Bar_Activity_03(this.SVG_ModuleDeck, dk.deck, data, status, style)
        } else
        {
          this.SVG_ModuleDeck
          .append('text')
          .attr('transform', `translate(${x3 + dk.x}, ${y3 + dk.y + 12})`)
          .style('font-family', 'roboto').style('font-size', 10).style('fill', '#F7BACF').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
          .text('Not applicable to BSEE')
        }
      })

    },


    Bar_Activity_03(selection, _deck, _data, _status, _style) {
      console.log()
      if (_data == null) {
        return
      }
      _status.forEach(s => {
        s.total = _data.length
        s.actual = _data.filter(f=> f[s.code] !== null).length
      })

      let x_ = _style.x + this.getNodeElValue(`#mText_${_deck}`, 'width')
      let y_ = _style.y


      let EQstatus = selection
      .append('g')
      .attr('transform', `translate(${x_}, ${y_})`)
      
      _status.forEach((d, i)=> {
        let actualLen = (d.actual / d.total) * _style.barSize
        let distance = _style.distance

        EQstatus
        .append('text')
        .attr('transform', `translate(${-3}, ${(i*distance) + 18})`)
        .style('font-family', 'roboto').style('font-size', _style.fSize).style('fill', '#757575').style('text-anchor', 'end').attr('alignment-baseline', 'middle').text(d.name)

        EQstatus
        .append('rect')
        .attr('id', `bar_${_deck}_${i}`)
        .attr('transform', `translate(0, ${(i*distance) + 12})`)
        .attr('width', 0).attr('height', _style.height).style('fill', d.bColor).style('opacity', d.opacity)
        
        d3.select(`#bar_${_deck}_${i}`)
        .transition().duration(500).attr('width', actualLen)

        EQstatus
        .append('text')
        .attr('transform', `translate(${actualLen - 2}, ${(i*distance) + 18})`)
        .style('font-family', 'roboto').style('font-size', 9).style('fill', '#fff').style('text-anchor', 'end').attr('alignment-baseline', 'middle').text(d.actual)

        EQstatus
        .append('text')
        .attr('transform', `translate(${_style.barSize + 2}, ${(i*distance) + 18})`)
        .style('font-family', 'roboto').style('font-size', 9).style('fill', '#757575').style('text-anchor', 'start').attr('alignment-baseline', 'middle').text(d.total)
      })
    },


  }
}
           