<template>
  <div :id="localId"></div>
</template>

<script>
import * as d3 from 'd3'
import mx_Core from './includes/mixins/importer'            // Global & Local Mixins
import __M from 'moment'

export default {
  name: "j-lms-skyline",
  mixins: [mx_Core],
  data: () =>  ({
    counters: '',
    scrollLeft: 0
  }),
  props: {
    id: String,
    
    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },
    DataItems: { type: Array, default: () => ([]) },
    Queries: {type: Object, default: () => ({}) },
    ColumnProps: { type: Array, default: () => ([]) },
    values: {
      type: Object,
      default: () => ({})
    },
    FilterValues: {
      type: Object,
      default: () => ({})
    },

    callback: { type: Object, default: () => ({}) },
  },
  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
  },
  mounted() {
    this.register(this.draw)

    
    setTimeout(() => {
      document.getElementById('contentScrollTarget').scrollLeft = (this.timeline.scale(new Date(this.cutoff))) + this.timeline.baseX
    }, 1000)

    this.counters = setInterval(() => {
      this.scrollLeft = document.getElementById('contentScrollTarget').scrollLeft
    }, 1080)



  },

  methods: {
    draw() {
      // Check all the necessary values are available
      if(!this.ready2draw) return

      this.clear()
      this.setStyles()
      this.setData()
      this.setDefaultValues()   
      this.drawCanvas()
      this.drawChart()
      this.drawTexts()

      
      // 'complete' must be executed to finish the parent process.
      // It sends 'this.localId' & XmlString for some case to make 
      // displaying image easy.
      this.complete()
    
      this.chartMounted = true

    },
    drawChart() {

      this.SkylineCharts = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0.5)`)

      this.draw_Timeline()
      this.draw_Charts()
      this.draw_Legends()
      this.drawDonuts()
      this.postComplete()
    },
    postComplete() {
      let chart_ = d3.select('.skyline_boxes').node().getBoundingClientRect()
      let width_ = chart_.width + 100
      let height_ = chart_.height + 400

      if (width_ < 1500) width_ = 1500

      this.resizableCanvasWidth = width_
      this.resizableCanvasHeight = height_
      this.resizeCanvas()

      let dimention = { 
        width: Math.ceil(width_), 
        height: Math.ceil(height_), 
        isreal: true, 
      }
      this.$emit('resize', dimention)
    }
  },

  destroyed(){
    clearInterval(this.counters)
  },
  
}
</script>