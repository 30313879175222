import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {

    Draw_Charts() {
      
      let data = null, 
          style = null

      let systemsummary = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0.5)`)

      // Header_01
      data  = this.Queries.SQL1[0]
      style = {
        title: 'B ITR Progress',
        x: 10,
        y: 70,
        width: 100,
        height: 25,
        radius: 3,
        tSize: 12,
        tColor: '#333',
        bColor: '#E0E0DF',
        progSize: 15,
      }
      this.Header_01_( systemsummary, data.PROG, style) // ---------------------------> 

      let summary = systemsummary
      .append('g')
      .attr('transform', 'translate(10, 100)')


      summary.append('rect').attr('transform', `translate(0, 0)`)
      .style('width', 210).style('height', 70).style('fill', '#83D2F5').attr('rx', 3).attr('ry', 3)

      // Titles
      summary.append('text')
      .attr('transform', 'translate(35, 13)')
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').style('text-anchor', 'end')
      .text('Type')

      summary.append('text')
      .attr('transform', 'translate(75, 13)')
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').style('text-anchor', 'end')
      .text('Total')
      
      
      summary.append('text')
      .attr('transform', 'translate(140, 13)')
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').style('text-anchor', 'end')
      .text('Completed')

      // summary.append('text')
      // .attr('transform', 'translate(185, 13)')
      // .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').style('text-anchor', 'end')
      // .text('Signed')

      summary.append('text')
      .attr('transform', 'translate(190, 13)')
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').style('text-anchor', 'end')
      .text('Remain')


      summary.append('text')
      .attr('transform', 'translate(35, 30)')
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').style('text-anchor', 'end')
      .text('B ITR')

      summary.append('text')
      .attr('transform', 'translate(35, 45)')
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').style('text-anchor', 'end')
      .text('RFDC')

      summary.append('text')
      .attr('transform', 'translate(35, 60)')
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').style('text-anchor', 'end')
      .text('CTP')

      // itr data Summary Table Not Used Common Chart
      let itr = this.Queries.SQL1[0]

      if (itr) {
        // TOTAL
        summary.append('text')
        .attr('transform', 'translate(75, 30)')
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        .text(itr.TOTAL)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100).style('fill', '#fff')
        })
        .on('click', () => {
          if (itr.TOTAL == 0) return
          // let data = {CATEGORY : 'B', SUBSYSTEM: this.FilterValues.SUBSYSTEM}
          // let request_ = this.getRequestColumnProps('bitr', data, this.ColumnProps)
          let request_ = {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: 255,
              no: ''
            },
            filters     : {},
            iFilters    : {
              filterString : ` CATEGORY = 'B' AND SUBSYSTEM = '${this.FilterValues.SUBSYSTEM}' AND DISC NOT IN ('RFDC', 'CTP') `,
              inputFilter  : ''
            }
          }
          this.$emit('request-action', request_)
        })
        
        // Completed
        summary.append('text')
        .attr('transform', 'translate(140, 30)')
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        .text(itr.ACTUAL)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100).style('fill', '#fff')
        })
        .on('click', () => {
          if (itr.ACTUAL == 0) return
          // let data = {CATEGORY : 'B', SUBSYSTEM: this.FilterValues.SUBSYSTEM, STATUS_CODE:'C'}
          // let request_ = this.getRequestColumnProps('bitr', data, this.ColumnProps)
          // this.$emit('request-action', request_)
                    
          let request_ = {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: 255,
              no: ''
            },
            filters     : {},
            iFilters    : {
              filterString : ` CATEGORY = 'B' AND SUBSYSTEM = '${this.FilterValues.SUBSYSTEM}' AND STATUS_CODE = 'C'  AND DISC NOT IN ('RFDC', 'CTP') `,
              inputFilter  : ''
            }
          }
          this.$emit('request-action', request_)

        })
        
        // summary.append('text')
        // .attr('transform', 'translate(185, 30)')
        // .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        // .text(itr.SIGNED)
        // .style('cursor', 'pointer')
        // .on('mouseover', function() {
        //   d3.select(this).transition().duration(100).style('fill', '#EC407A')
        // })
        // .on('mouseout', function(){
        //   d3.select(this).transition().duration(100).style('fill', '#fff')
        // })
        // .on('click', () => {
        //   // let data = {CATEGORY : 'B', SUBSYSTEM: this.FilterValues.SUBSYSTEM, STATUS_CODE:'N'}
        //   // let request_ = this.getRequestColumnProps('bitr', data, this.ColumnProps)
        //   if (itr.SIGNED == 0) return
          
        //   let request_ = {
        //     dataType: 'row',
        //     action: {
        //       type: 'direct',
        //       target: 'slide-modal',
        //       component: 'ServiceDatatable',
        //       id: 255,
        //       no: ''
        //     },
        //     filters     : {},
        //     iFilters    : {
        //       filterString : ` CATEGORY = 'B' AND SUBSYSTEM = '${this.FilterValues.SUBSYSTEM}' AND STATUS_CODE = 'C' AND [CLIENT_SIGN_DATE] IS NOT NULL  `,
        //       inputFilter  : ''
        //     }
        //   }
        //   this.$emit('request-action', request_)
        // })
  
        summary.append('text')
        .attr('transform', 'translate(190, 30)')
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        .text(itr.REMAIN)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100).style('fill', '#fff')
        })
        .on('click', () => {
          if (itr.REMAIN == 0) return
          // let data = {CATEGORY : 'B', SUBSYSTEM: this.FilterValues.SUBSYSTEM, STATUS_CODE:'N'}
          // 이사님이 Done 아닌것은 Not Done 넣어주시기로 하셨음 2021-07-23
          // STATUS: 'Not Done' -> STATUS_CODE: 'N', STATUS: 'Done' -> STATUS_CODE: 'C' 변경 2021-08-10

          // let request_ = this.getRequestColumnProps('bitr', data, this.ColumnProps)
          
          let request_ = {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: 255,
              no: ''
            },
            filters     : {},
            iFilters    : {
              filterString : ` CATEGORY = 'B' AND SUBSYSTEM = '${this.FilterValues.SUBSYSTEM}' AND STATUS_CODE = 'N'  AND DISC NOT IN ('RFDC', 'CTP') `,
              inputFilter  : ''
            }
          }
          this.$emit('request-action', request_)
        })
      }

      
      //RFDC Data
      let rfdc = this.Queries.SQL3[0]
      if (rfdc) {
        summary.append('text')
        .attr('transform', 'translate(75, 45)')
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        .text(rfdc.TOTAL)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100).style('fill', '#fff')
        })
        .on('click', () => {
          if (rfdc.TOTAL == 0) return
          let data = {CATEGORY : 'B', SUBSYSTEM: this.FilterValues.SUBSYSTEM, DISC: 'RFDC'}
          let request_ = this.getRequestColumnProps('status', data, this.ColumnProps)
          this.$emit('request-action', request_)
        })

        summary.append('text')
        .attr('transform', 'translate(140, 45)')
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        .text(rfdc.ACTUAL)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100).style('fill', '#fff')
        })
        .on('click', () => {
          if (rfdc.ACTUAL == 0) return
          let data = {CATEGORY : 'B', SUBSYSTEM: this.FilterValues.SUBSYSTEM, STATUS_CODE:'C', DISC: 'RFDC'}
          let request_ = this.getRequestColumnProps('status', data, this.ColumnProps)
          this.$emit('request-action', request_)
        })
        
        // summary.append('text')
        // .attr('transform', 'translate(185, 45)')
        // .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        // .text(rfdc.SIGNED)
        // .style('cursor', 'pointer')
        // .on('mouseover', function() {
        //   d3.select(this).transition().duration(100).style('fill', '#EC407A')
        // })
        // .on('mouseout', function(){
        //   d3.select(this).transition().duration(100).style('fill', '#fff')
        // })
        // .on('click', () => {
        //   // let data = {CATEGORY : 'B', SUBSYSTEM: this.FilterValues.SUBSYSTEM, STATUS_CODE:'C', DISC: 'RFDC'}
        //   // let request_ = this.getRequestColumnProps('status', data, this.ColumnProps)

        //   if (rfdc.SIGNED == 0) return
          
        //   let request_ = {
        //     dataType: 'row',
        //     action: {
        //       type: 'direct',
        //       target: 'slide-modal',
        //       component: 'ServiceDatatable',
        //       id: 255,
        //       no: ''
        //     },
        //     filters     : {},
        //     iFilters    : {
        //       filterString : ` CATEGORY = 'B' AND SUBSYSTEM = '${this.FilterValues.SUBSYSTEM}' AND STATUS_CODE = 'C' AND [CLIENT_SIGN_DATE] IS NOT NULL AND DISC = 'RFDC'  `,
        //       inputFilter  : ''
        //     }
        //   }
        //   this.$emit('request-action', request_)
        // })

        summary.append('text')
        .attr('transform', 'translate(190, 45)')
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        .text(rfdc.REMAIN)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100).style('fill', '#fff')
        })
        .on('click', () => {
          if (rfdc.REMAIN == 0) return
          let data = {CATEGORY : 'B', SUBSYSTEM: this.FilterValues.SUBSYSTEM, STATUS_CODE:'N', DISC: 'RFDC'}
          let request_ = this.getRequestColumnProps('status', data, this.ColumnProps)
          this.$emit('request-action', request_)
        })
      }

      
      //CTP Data
      let ctp = this.Queries.SQL4[0]
      if (ctp) {
        summary.append('text')
        .attr('transform', 'translate(75, 60)')
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        .text(ctp.TOTAL)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100).style('fill', '#fff')
        })
        .on('click', () => {
          if (ctp.TOTAL == 0) return
          let data = {CATEGORY : 'B', SUBSYSTEM: this.FilterValues.SUBSYSTEM, DISC: 'CTP'}
          let request_ = this.getRequestColumnProps('status', data, this.ColumnProps)
          this.$emit('request-action', request_)
        })

        summary.append('text')
        .attr('transform', 'translate(140, 60)')
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        .text(ctp.ACTUAL)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100).style('fill', '#fff')
        })
        .on('click', () => {
          if (ctp.ACTUAL == 0) return
          let data = {CATEGORY : 'B', SUBSYSTEM: this.FilterValues.SUBSYSTEM, STATUS_CODE:'C', DISC: 'CTP'}
          let request_ = this.getRequestColumnProps('status', data, this.ColumnProps)
          this.$emit('request-action', request_)
        })
        
        // summary.append('text')
        // .attr('transform', 'translate(190, 60)')
        // .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        // .text(ctp.SIGNED)
        // .style('cursor', 'pointer')
        // .on('mouseover', function() {
        //   d3.select(this).transition().duration(100).style('fill', '#EC407A')
        // })
        // .on('mouseout', function(){
        //   d3.select(this).transition().duration(100).style('fill', '#fff')
        // })
        // .on('click', () => {
        //   // let data = {CATEGORY : 'B', SUBSYSTEM: this.FilterValues.SUBSYSTEM, STATUS_CODE:'N', DISC: 'CTP'}
        //   // let request_ = this.getRequestColumnProps('status', data, this.ColumnProps)
          
        //   if (ctp.SIGNED == 0) return
          
        //   let request_ = {
        //     dataType: 'row',
        //     action: {
        //       type: 'direct',
        //       target: 'slide-modal',
        //       component: 'ServiceDatatable',
        //       id: 255,
        //       no: ''
        //     },
        //     filters     : {},
        //     iFilters    : {
        //       filterString : ` CATEGORY = 'B' AND SUBSYSTEM = '${this.FilterValues.SUBSYSTEM}' AND STATUS_CODE = 'C' AND [CLIENT_SIGN_DATE] IS NOT NULL AND DISC = 'CTP' `,
        //       inputFilter  : ''
        //     }
        //   }
        //   this.$emit('request-action', request_)
        // })

        summary.append('text')
        .attr('transform', 'translate(190, 60)')
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        .text(ctp.REMAIN)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100).style('fill', '#fff')
        })
        .on('click', () => {
          if (ctp.REMAIN == 0) return
          let data = {CATEGORY : 'B', SUBSYSTEM: this.FilterValues.SUBSYSTEM, STATUS_CODE:'N', DISC: 'CTP'}
          let request_ = this.getRequestColumnProps('status', data, this.ColumnProps)
          this.$emit('request-action', request_)
        })
      }


      // Punch Summary Table
      let punch_summary = systemsummary
      .append('g')
      .attr('transform', 'translate(10, 180)')

      punch_summary.append('rect').attr('transform', `translate(0, 0)`)
      .style('width', 210).style('height', 90).style('fill', '#A8E0F8').attr('rx', 3).attr('ry', 3)

      // Titles
      punch_summary.append('text')
      .attr('transform', 'translate(35, 13)')
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').style('text-anchor', 'end')
      .text('Punch')

      punch_summary.append('text')
      .attr('transform', 'translate(90, 13)')
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').style('text-anchor', 'end')
      .text('Raised')
      
      
      punch_summary.append('text')
      .attr('transform', 'translate(140, 13)')
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').style('text-anchor', 'end')
      .text('Closed')

      punch_summary.append('text')
      .attr('transform', 'translate(190, 13)')
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').style('text-anchor', 'end')
      .text('Open')

      punch_summary.append('text')
      .attr('transform', 'translate(35, 30)')
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').style('text-anchor', 'end')
      .text('A')

      punch_summary.append('text')
      .attr('transform', 'translate(35, 45)')
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').style('text-anchor', 'end')
      .text('B1')

      punch_summary.append('text')
      .attr('transform', 'translate(35, 60)')
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').style('text-anchor', 'end')
      .text('B2')

      punch_summary.append('text')
      .attr('transform', 'translate(35, 75)')
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').style('text-anchor', 'end')
      .text('B3')


      let punch_a = this.Queries.SQL2 ? this.Queries.SQL2.find(d => d.CAT == 'A') : null
      if (punch_a) {
        punch_summary.append('text')
        .attr('transform', 'translate(90, 30)')
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        .text(punch_a.TOTAL)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100).style('fill', '#fff')
        })
        .on('click', () => {          
          let punchData = {CATEGORY : punch_a.CAT, SUBSYSTEM: this.FilterValues.SUBSYSTEM}
          let request_ = this.getRequestColumnProps('punchStatus', punchData, this.ColumnProps)
          this.$emit('request-action', request_)
        })

        punch_summary.append('text')
        .attr('transform', 'translate(140, 30)')
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        .text(punch_a.ACTUAL)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100).style('fill', '#fff')
        })
        .on('click', () => {
          let punchData = {CATEGORY : punch_a.CAT, SUBSYSTEM: this.FilterValues.SUBSYSTEM, STATUS_CODE:'C'}
          let request_ = this.getRequestColumnProps('punchStatus', punchData, this.ColumnProps)
          this.$emit('request-action', request_)
        })

        punch_summary.append('text')
        .attr('transform', 'translate(190, 30)')
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        .text(punch_a.REMAIN)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100).style('fill', '#fff')
        })
        .on('click', () => {          
          let punchData = {CATEGORY : punch_a.CAT, SUBSYSTEM: this.FilterValues.SUBSYSTEM, STATUS_CODE:'N'}
          let request_ = this.getRequestColumnProps('punchStatus', punchData, this.ColumnProps)
          this.$emit('request-action', request_)
        })
      }

      let punch_b1 = this.Queries.SQL2 ? this.Queries.SQL2.find(d => d.CAT == 'B1') : null
      if (punch_b1) {
        punch_summary.append('text')
        .attr('transform', 'translate(90, 45)')
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        .text(punch_b1.TOTAL)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100).style('fill', '#fff')
        })
        .on('click', () => {          
          let punchData = {CATEGORY : punch_b1.CAT, SUBSYSTEM: this.FilterValues.SUBSYSTEM}
          let request_ = this.getRequestColumnProps('punchStatus', punchData, this.ColumnProps)
          this.$emit('request-action', request_)
        })

        punch_summary.append('text')
        .attr('transform', 'translate(140, 45)')
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        .text(punch_b1.ACTUAL)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100).style('fill', '#fff')
        })
        .on('click', () => {
          let punchData = {CATEGORY : punch_b1.CAT, SUBSYSTEM: this.FilterValues.SUBSYSTEM, STATUS_CODE:'C'}
          let request_ = this.getRequestColumnProps('punchStatus', punchData, this.ColumnProps)
          this.$emit('request-action', request_)
        })

        punch_summary.append('text')
        .attr('transform', 'translate(190, 45)')
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        .text(punch_b1.REMAIN)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100).style('fill', '#fff')
        })
        .on('click', () => {          
          let punchData = {CATEGORY : punch_b1.CAT, SUBSYSTEM: this.FilterValues.SUBSYSTEM, STATUS_CODE:'N'}
          let request_ = this.getRequestColumnProps('punchStatus', punchData, this.ColumnProps)
          this.$emit('request-action', request_)
        })
      }

      
      // punch data Summary Table Not Used Common Chart
      let punch_b2 = this.Queries.SQL2 ? this.Queries.SQL2.find(d => d.CAT == 'B2') : null
      if (punch_b2) {
        punch_summary.append('text')
        .attr('transform', 'translate(90, 60)')
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        .text(punch_b2.TOTAL)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100).style('fill', '#fff')
        })
        .on('click', () => {          
          let punchData = {CATEGORY : punch_b2.CAT, SUBSYSTEM: this.FilterValues.SUBSYSTEM}
          let request_ = this.getRequestColumnProps('punchStatus', punchData, this.ColumnProps)
          this.$emit('request-action', request_)
        })

        punch_summary.append('text')
        .attr('transform', 'translate(140, 60)')
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        .text(punch_b2.ACTUAL)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100).style('fill', '#fff')
        })
        .on('click', () => {
          let punchData = {CATEGORY : punch_b2.CAT, SUBSYSTEM: this.FilterValues.SUBSYSTEM, STATUS_CODE:'C'}
          let request_ = this.getRequestColumnProps('punchStatus', punchData, this.ColumnProps)
          this.$emit('request-action', request_)
        })

        punch_summary.append('text')
        .attr('transform', 'translate(190, 60)')
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        .text(punch_b2.REMAIN)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100).style('fill', '#fff')
        })
        .on('click', () => {          
          let punchData = {CATEGORY : punch_b2.CAT, SUBSYSTEM: this.FilterValues.SUBSYSTEM, STATUS_CODE:'N'}
          let request_ = this.getRequestColumnProps('punchStatus', punchData, this.ColumnProps)
          this.$emit('request-action', request_)
        })
      }



      // punch data Summary Table Not Used Common Chart
      let punch_b3 = this.Queries.SQL2 ? this.Queries.SQL2.find(d => d.CAT == 'B3') : null

      if (punch_b3) {
        punch_summary.append('text')
        .attr('transform', 'translate(90, 75)')
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        .text(punch_b3.TOTAL)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100).style('fill', '#fff')
        })
        .on('click', () => {
          let data = {CATEGORY : punch_b3.CAT, SUBSYSTEM: this.FilterValues.SUBSYSTEM}
          let request_ = this.getRequestColumnProps('punchStatus', data, this.ColumnProps)
          this.$emit('request-action', request_)
        })
  
        punch_summary.append('text')
        .attr('transform', 'translate(140, 75)')
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        .text(punch_b3.ACTUAL)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100).style('fill', '#fff')
        })
        .on('click', () => {
          let data = {CATEGORY : punch_b3.CAT, SUBSYSTEM: this.FilterValues.SUBSYSTEM, STATUS_CODE:'C'}
          let request_ = this.getRequestColumnProps('punchStatus', data, this.ColumnProps)
          this.$emit('request-action', request_)
        })
  
        punch_summary.append('text')
        .attr('transform', 'translate(190, 75)')
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end')
        .text(punch_b3.REMAIN)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100).style('fill', '#fff')
        })
        .on('click', () => {
          let data = {CATEGORY : punch_b3.CAT, SUBSYSTEM: this.FilterValues.SUBSYSTEM, STATUS_CODE:'N'}
          // 이사님이 Done 아닌것은 Not Done 넣어주시기로 하셨음 2021-07-23
          // STATUS: 'Not Done' -> STATUS_CODE: 'N', STATUS: 'Done' -> STATUS_CODE: 'C' 변경 2021-08-10

          let request_ = this.getRequestColumnProps('punchStatus', data, this.ColumnProps)
          this.$emit('request-action', request_)
        })
      }



      // Draw Text Data
      let systemInfo = this.svg
      .append('g')
      .attr('transform', `translate(230.5, 90.5)`)

      let drawY = 0
      this.dataSet.forEach((d,i) => {
        if(i > 9) return
        systemInfo // CAT
        .append('text')
        .attr('transform', `translate(${150}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 11)
        .style('fill', '#757575').style('text-anchor', 'end').style('alignment-baseline', 'middle').text(d.title)

        systemInfo // CAT
        .append('text')
        .attr('transform', `translate(${160}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 11)
        .style('fill', '#333').style('text-anchor', 'start').style('alignment-baseline', 'middle').text(d.value)

        if (i==1 || i ==3 || i ==5) drawY += 24; else drawY += 16
      })

      
      let systemInfoDate = this.svg
      .append('g')
      .attr('transform', `translate(460.5, 20.5)`)
      drawY = 100
      this.dataSet.forEach((d,i) => {
        if(i == 10 || i == 11 || i == 13 || i == 15) {
        systemInfoDate
        .append('text')
        .attr('transform', `translate(${150}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 11)
        .style('fill', '#757575').style('text-anchor', 'end').style('alignment-baseline', 'middle')
        .text(i == 15 ? '' :d.title)

        systemInfoDate
        .append('text')
        .attr('transform', `translate(${180}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 10)
        .style('fill', '#bcbcbc').style('text-anchor', 'end').style('alignment-baseline', 'middle')
        .text(i == 10 ? 'Issue' : i == 15 ? 'Actual' : 'Plan')

        if(i != 10) {
          systemInfoDate
          .append('rect')
          .attr('transform', `translate(315, ${drawY-9})`)
          .style('width', 65).style('height', 15).style('fill', '#E0E0DF').attr('rx', 0).attr('ry', 0).style('opacity', 0.3)
        }

        
        systemInfoDate
        .append('text')
        .attr('transform', `translate(${310}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 10)
        .style('fill', '#bcbcbc').style('text-anchor', 'end').style('alignment-baseline', 'middle')
        .text(() => { if(i == 10) return 'Forecast'; else return 'Actual'})
        .text( i == 10 ? '': i == 13 ? 'Forecast' : i == 15 ? 'Approved':'Actual')
        
        systemInfoDate
        .append('rect')
        .attr('transform', `translate(190, ${drawY-9})`)
        .style('width', 65).style('height', 16).style('fill', '#E0E0DF').attr('rx', 0).attr('ry', 0).style('opacity', 0.3)


        systemInfoDate 
        .append('text')
        .attr('transform', `translate(${190}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 11)
        .style('fill', '#44A9DF').style('text-anchor', 'start').style('alignment-baseline', 'middle').text(d.value)

        if(i == 11 || i == 13 || i == 15) {
          if ( !this.dataSet[i+1] ) return
          systemInfoDate
          .append('text')
          .attr('transform', `translate(${320}, ${drawY})`)
          .style('font-family', 'roboto').style('font-size', 11)
          .style('fill', '#44A9DF').style('text-anchor', 'start').style('alignment-baseline', 'middle')
          .text(this.dataSet[i+1].value)
        }
        if (i==1 || i ==3) drawY += 28; else drawY += 23
        }
      })


    }, 
    
    Header_01_(selection, _data, _style) {
      let Header_01 = selection
      .append('g')
      .attr('id', '')
      .attr('transform', `translate(${_style.x}, ${_style.y})`)

      Header_01
      .append('rect')
      .attr('transform', `translate(0, 0)`)
      .attr('rx', _style.radius).attr('ry', _style.radius)
      .style('width', _style.width).style('height', _style.height).style('fill', _style.bColor)

      Header_01
      .append('text')
      .attr('transform', `translate(10, ${_style.height/2 + 1})`)
      .style('font-family', 'roboto').style('font-size', _style.tSize).style('fill', _style.tColor).style('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(_style.title)

      Header_01
      .append('text') // Delta Value
      .attr('transform', `translate(${_style.width + 5}, ${_style.height/2 + 1})`)
      .style('fill', () => {
        if (_data < 0) return '#EC407A'; else return '#44A9DF'
      })
      .style('font-family', 'roboto').style('font-size', _style.progSize).style('text-anchor', 'start').text(`${_data}%`).attr('alignment-baseline', 'middle')
    },
  }
}
