import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {

    
    Draw_Charts() {
      
      let data = null, 
          style = null, 
          cellX = null, 
          tmp = null, 
          Circle = null, 
          Guideline = null,
          item = null

      // let type = this.dataSet.map(d => d.TYPE) -- 배열로 TYPE B만 가져오는것
      let type = [...new Set(this.dataSet.map(d => d.TYPE))]  //--배열로 하나만 가져오는것


      let overallMCStatus = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0)`).style('font-family','roboto')

      let overallText = overallMCStatus
      .append('g')
      .attr('transform', `translate(0, 10)`)
      .style('font-size',12).style('font-weight','bold')
      .append('text')
      .text(type+` ITR Status`)

      // Header_01
      data  = this.dataSet.find(f=> f.DISC == 'Overall').PROG

      style = {
        title: type + ` ITR`,
        x: 0,
        y: 20,
        width: 50,
        height: 18,
        radius: 3,
        tSize: 11,
        tColor: '#333',
        bColor: '#E0E0DF',
        progSize: 15,
      }
      this.Header_01( overallMCStatus, data, style) // ---------------------------> 


      // SummaryTable_01
      item = this.dataSet.find(f=> f.DISC == 'Overall')
      style = {
        id:       'ITR',
        title:    ['Total', 'Complete', 'Remain'],
        cell:     [50, 110, 160],
        x:        0,
        y:        42,
        y1:       10,
        y2:       23,
        width:    170,
        height:   32,
        radius:   3,
        opacity:  0.7,
        bColor:   '#83D2F5',
        tSize:    9,
        tColor:   '#757575',
        vSize:    11,
        vColor:   '#fff',
        value:    [item.TOTAL, item.ACTUAL, item.REMAIN],
        type: type
      }
      this.SummaryTable_01(overallMCStatus, style) // ---------------------------> 



       // Bar_Progress_01
      data = this.dataSet.filter(f=>  f.DISC !== 'Overall')
      style = {
                x:        -20,
                y:        100,
                width:    50,
                title:    {text: type + ` ITR Status by discipline`, x: 28, tSize: 11, tColor: '#333'},
                cell:     [70, 110, 150, 190, 200, 250, 300],
                lineHeight: 25,
                bColor:   '#B4E3FA', 
                sColor:   '#44A9DF', 
                tColor1:  '#fff', 
                tColor2:  '#757575', 
                opacity: 1,
      }
       this.Bar_Progress_GWBS(overallMCStatus, data, style) // ---------------------------> 
    },
  }
}
