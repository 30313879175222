import * as d3 from 'd3'

export default {

  methods: {
    /*
      load_Lev2()
      setCoordinate_Lev2()
      unload_Lev2()
    */
    load_Lev2() {
      this.stage_Header.select(`#HOME`).attr('visibility', 'visible')
      this.stage_Header.select(`#BACK`).attr('visibility', 'visible')

      this.Areas.forEach(d=> {
        if(d.package == this.route.to.package) {
          d3.select(`#PLAN-${d.code}`)
          .transition().duration(300)
          .style('opacity', .7)
        }
        else {
          d3.select(`#PLAN-${d.code}`)
          .transition().duration(300)
          .style('opacity', 0)
        }
      })

      d3.select('#HeaderTitle_Area')
      .transition().duration(500)
      .attr('fill', '#F35E90')
      .text(`Main Plant - Package (${this.route.to.package})`)

      // Calculate the scale value and coordinates in 'set_LocalValues.js'
      let pkg = this.Package.find(f => f.code == this.route.to.package)
      this.setZoomScale(this.zoom.width, this.zoom.height, pkg.scale_in, pkg.scale_out)

      this.innerSvg = this.svg.append('svg')
      this.setPriority()
      
      this.innerSvg
      .append('image')
      .attr('id','lev2_background')
      .attr('transform', `translate(${this.zoom.in.x + 100}, ${this.zoom.in.y}) scale(${this.zoom.in.scale})`)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/China1/Area_Summary/images/${this.route.to.package}.jpg`)
      .style('opacity', 0)
      .style('stroke','red').style('stroke-width',2)

      d3.select('#lev2_background')
      .transition().duration(500)
      .attr('transform', `translate(${ this.zoom.out.x + 100}, ${ this.zoom.out.y}) scale(${this.zoom.out.scale})`)
      .style('opacity', 1)

      // After loading the SVG, perform initialization
      this.loadSvg(
        this.innerSvg, {
        x   : 0,
        y   : 0,
        url : `/China1/Area_Summary/svg/${this.route.to.package}.svg`
      }).then(() => {
        this.Areas.filter(f=> f.package == this.route.to.package).forEach(d => {
          let xy_ = d3.select(`#XY-${d.code}`).attr('visibility', 'hidden')
          d.x = xy_.attr('cx')
          d.y = xy_.attr('cy')

          this.innerSvg.select(`#BTN-${d.code}`).style('opacity', 0)
          this.innerSvg.select(`#AREA-${d.code}`).attr('transform', `translate(${d.x}, ${d.y})scale(0)`).style('opacity', 0)
          this.innerSvg.select(`#BOX-${d.code}`).style('opacity', 0)
        })
        this.summary_Package() // ---> this function below
        this.setCoordinate_Lev2() // ---> this function below
        this.draw_AreaListMenu(this.route.to.package) // ---> in draw_Package.js
      })
    },

    summary_Package() {

      let pkg = this.Package.find(f=> f.code == this.route.to.package)
 
      let x = this.styles[`LEV2_X_${pkg.code}`]
      let y = this.styles[`LEV2_Y_${pkg.code}`]
      let initY = y - 15

      this.stage_Lev2 = this.svg.append('svg')
      .append('g')
      .attr('id', `Group_Summary`)
      .attr('transform', `translate(${x},${y})`)
      .style('cursor', 'pointer')
      .call(this.call_Link_Summary, 'request2', pkg.code, 'OV') // ------->

      //main title
      this.stage_Lev2
      .append('text')
      .attr('x', 4).attr('y', y).attr('fill', '#000').attr('font-size', 13).attr('font-weight', 500)
      .text(`${this.styles.LEV2_TITLE}(${this.route.to.package})`)

      y = y + 8
      
      //title,bar,text
      this.packageSum.filter(f=> f.PKG == this.route.to.package).forEach((d,i) => {
        //title
        this.stage_Lev2
        .append('text')
        .attr('x', 4).attr('y', y + 9).attr('fill', '#757575').attr('font-size', 8.5)
        .text(d.DISC)

        //bar tot rect
        this.stage_Lev2
        .append('rect')
        .attr('x', 200).attr('y', y).attr('width', 100).attr('height', 11).attr('fill', '#F4F5F5')
        
        //bar actual rect
        this.stage_Lev2
        .append('rect')
        .attr('id', `bar-${i}`)
        .attr('x', 200).attr('y', y).attr('width', 0).attr('height', 11).attr('fill', '#83D2F5')
        
        //bar actual text
        this.stage_Lev2
        .append('text')
        .attr('id', `actual-${i}`)
        .attr('x', 201).attr('y', y + 9).attr('fill', '#757575').attr('font-size', 10)
        .text(0)
        
        //bar remain text
        this.stage_Lev2
        .append('text')
        .attr('id', `remain-${i}`)
        .attr('x', 301).attr('y', y + 9).attr('fill', '#757575').attr('font-size', 10)
        .text(d.TOTAL)
        
        //click event
        // this.stage_Lev2
        // .append('rect')
        // .attr('id', `link_${d.CODE}`)
        // .attr('x', 4).attr('y', y).attr('width', 325).attr('height', 11).attr('fill',`transparent`)
        // .attr('opacity', 0.5)
        // .call(this.linkEvent_Summary, d, 'request2') // ------->

        y = y + 18
      })

      this.stage_Lev2
      .append('rect')
      .attr('x', 4).attr('y', initY).attr('width', this.styles.WIDTH).attr('height', (this.packageSum.filter(f=> f.PKG == this.route.to.package).length * 18) + 15).attr('fill',`transparent`) // transparent
      .attr('opacity', .5)

       // animate
       this.packageSum.filter(f=> f.PKG == this.route.to.package).forEach((d,i) => {

        d3.select(`#bar-${i}`)
        .transition()
        .ease(d3.easeLinear)
        .duration(1500)
        .attr('width', 100 * (d.PRO/100))

        d3.select(`#actual-${i}`)
        .transition()
        .ease(d3.easeLinear)
        .duration(1500)
        .tween("text", function() {
          const interpolator = d3.interpolateNumber(0, d.ACTUAL)
          return function(t) {
            d3.select(`#actual-${i}`).text(Math.round(interpolator(t)))
          }
         })

         d3.select(`#remain-${i}`)
        .transition()
        .ease(d3.easeLinear)
        .duration(1500)
        .tween("text", function() {
          const interpolator = d3.interpolateNumber(d.TOTAL, d.REMAIN)
          return function(t) {
            d3.select(`#remain-${i}`).text(Math.round(interpolator(t)))
          }
         })
      })
      //packageSummary.moveToBack()
      this.stage_Lev2.moveToFront()
    },

    setCoordinate_Lev2() {
      // Set mouse events on the area button
      this.Areas.filter(f=> f.package == this.route.to.package).forEach(d => {
        this.innerSvg
        .select(`#BTN-${d.code}`)
        .style('cursor', 'pointer')
        .on('click', () => {
          this.innerSvg.select(`#AREA-${d.code}`).attr('visibility', 'hidden')
          this.innerSvg.select(`#BOX-${d.code}`).attr('visibility', 'hidden')

          this.routeTo({
            path: 'Lev2', package: d.package
          }, {
            path: 'Lev3', package: d.package, area: d.code
          })
        })
        .call(this.call_Coordinate, d) // ---> in Event.js
      })
    },

    unload_Lev2() {
      this.innerSvg.select('#lev2_background')
      .transition().duration(500)
      .attr('transform', `translate(${this.zoom.in.x}, ${this.zoom.in.y}) scale(${this.zoom.in.scale})`)
      .style('opacity', 0)
      
      return new Promise(resolve => {
        setTimeout(() => {
          this.innerSvg.remove()
          this.stage_Lev2.remove()
          resolve() 
        }, 350)
      })
    },
    
  }
}