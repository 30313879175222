import * as d3 from 'd3'
import Defs from '../../../../../../includes/primitives/Color_Defs'
import Filter from '../../../../../../includes/primitives/Filter_Defs'
// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {    
    Draw_Charts() {
      
      let data = null, 
          style = null, 
          cellX = null, 
          tmp = null, 
          Circle = null, 
          Guideline = null

      let overallMCStatus = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0.5)`)

      let categorys = this.dataSet.map(d => d.CATEGORY)[0]
      let source = this.dataSet.map(d => d.SOURCE)[0]
      let progData = this.dataSet.map(d => d.PROG)[0]

      style = {
        id: 'SC',
        x: 70,
        y: 60,
        category: categorys == 'A' ? 'Punch ' + categorys : categorys == 'B' ? 'Punch ' + categorys : 'Category ' + categorys,
        categoryText: categorys == 'A' ? '' : categorys == 'B' ? '' : 'Punch OWL',
        source:source,
        sort: 'N',
      }
      tmp = this.dataSet.find(f=> f.CATEGORY == categorys)
      data = [
        {title: 'Open'  ,  value: tmp.OPEN / tmp.TOTAL * 100, qty: tmp.OPEN},
        {title: 'Closed'   ,  value: tmp.CLOSED / tmp.TOTAL * 100, qty: tmp.CLOSED},
        
      ]
      Circle = {
        CircleDiameter: 70,
        // CircleColor: ['LightGray', 'Gray', 'Yellow', 'Gold', 'Orange','Pink','Violet','Red','Thistle','Purple','Indigo','LightBlue','SkyBlue','SteelBlue','YellowGreen','DarkGreen' ],
        CircleColorSet: ['PunchRed', 'PunchGreen' ],
        CircleRadius: 0,
        CircleHoleSize: 45,
        CircleStackSize: 5,
        CircleSliceSize: 3,
        CircleTitleSize: 11,
        CircleLegendSpace: 75,
        CircleLegendPosition: 'bottom',
        outerRadius: 70
      }
      Guideline = {
        GuideDisplay: 'N',
      }
      this.draw_pie(overallMCStatus, data, style, Circle, Guideline,progData,tmp) // ---------------------------> 
    },
    draw_pie(selection, _data, _style, _Circle, _Guideline, _progData,_tmp) { 
      this.Circle = { ...this.Circle, ..._Circle }
      this.Guideline = { ...this.Guideline, ..._Guideline }

      let dataLength = _data.length

      this.Circle.CircleColorSet.forEach((c,i)=> {
        Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', [c], [c, 0.5, 1.0, 0.5, -0.2], this.localId)
      })

      let Shadow = selection
      .append('defs') // Tube Color (Left)
      .append('radialGradient').attr('id', `${this.localId}__Shadow`)
      Shadow.append('stop').attr('stop-color', '#a4a4a4').attr('offset', '0.00').attr('stop-opacity', '1')
      Shadow.append('stop').attr('stop-color', '#ffffff').attr('offset', '1.00').attr('stop-opacity', '0.37')

      this.PieStacks = []
      this.pieCenterAngles = []
      this.pieSectors = []

      var sortData = _data
      if (_style.sort == 'Y') sortData.sort(this.executeSort)
      var sum = 0
      var total = 0
      var complete = 0
      _data.forEach((item, i) => {
        sum = sum + _data[i].value
        total += item.qty
      })
    // console.log(_style)
      let cumulative = 0
      _data.forEach((item, j) => {
        sortData.forEach((sItem, i) => {
          if (sItem.title == item.title) this.PieStacks.push(this.Circle.CircleDiameter - i * this.Circle.CircleStackSize)
        })
        cumulative = cumulative + (_data[j].value/sum)
        this.pieCenterAngles.push(cumulative - (_data[j].value/sum) / 2)
      })

      this.SharedColorSet = dataLength > this.Circle.CircleColorSet.length ? Array.from({ length: dataLength }, (_, i) => this.Circle.CircleColorSet[i % this.Circle.CircleColorSet.length]) : this.Circle.CircleColorSet
      this.SharedColorSetType = this.Circle.CircleColorType

      let centerTitle = selection

      centerTitle
      .append('text')
      .attr('transform', `translate(${_style.x}, ${_style.y-3})`)
      .style('font-size', 16).style('font-family', 'roboto').attr('fill', '#424242').style('text-anchor', 'middle').style('font-weight','bold')
      // .text(total.toLocaleString())
      .text(_progData+'%')

      centerTitle
      .append('text')
      .attr('transform', `translate(${_style.x}, ${_style.y+13})`)
      .style('font-size', 12).style('font-family', 'roboto').attr('fill', '#424242').style('text-anchor', 'middle')
      .text(_style.category)

      centerTitle
      .append('text')
      .attr('transform', `translate(${_style.x}, ${_style.y+27})`)
      .style('font-size', 10).style('font-family', 'roboto').attr('fill', '#9E9E9E').style('text-anchor', 'middle')
      .text(_style.categoryText)
      
      if(this.Circle.CircleLegendPosition == 'bottom')
      var legendsPie = selection
      .append('g')
      .attr('transform', `translate(${_style.x-30}, ${_style.y + this.Circle.CircleLegendSpace +5})`)

      else if(this.Circle.CircleLegendPosition == 'right')
      legendsPie = selection
      .append('g')
      .attr('transform', `translate(${_style.x + this.Circle.CircleLegendSpace}, ${_style.y - this.Circle.CircleDiameter / 1.5})`)

      _data.forEach((d,i) => {
        
        legendsPie
        .append('rect')
        .attr('transform', `translate(${-11}, ${(i*16) + 0})`)
        .attr('width', 8).attr('height', 8).attr('stroke-width', 0.5).attr('stroke', '#bcbcbc').attr('fill', `url(#${this.localId}__LinearA4${this.SharedColorSet[i]}0)`)

        legendsPie
        .append('text')
        .attr('id', `${_style.id}_LG_${i}`)
        .attr('transform', `translate(${2}, ${(i*16) + 7})`)
        .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'mistartddle')
        .text(_data[i].title)

        legendsPie
        .append('text')
        .attr('id', `${_style.id}_LG_${i}`)
        .attr('transform', `translate(${64}, ${(i*16) + 7})`)
        .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'end')
        .text(_data[i].qty)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(50)
          .style('opacity', 0.5)
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(50)
          .style('opacity', 1)
        })
        .on('click', () => { 
          let request = {CATEGORY : _tmp.CATEGORY, STATUS_CODE: _data[i].title == 'Open'? 'N' : 'C', SOURCE:_tmp.SOURCE}
          let request_ = this.getRequestColumnProps('request', request, this.ColumnProps)
          this.$emit('request-action', request_)
        })
        // legendsPie
        // .append('text')
        // .attr('transform', `translate(${this.getNodeElValue(`#${_style.id}_LG_${i}`, 'width') + 8}, ${(i*16) + 7})`)
        // .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#757575').style('text-anchor', 'mistartddle')
        // // .text(`(${_data[i].qty.toLocaleString()})`)
        // .text(`(${_data[i].qty})`)
      })

      legendsPie
      .append('line')
      .attr('x1', _style.x-85).attr('y1', _style.y-30).attr('x2', _style.x+0).attr('y2', _style.y-30).attr('stroke-width', 0.5).attr('stroke', '#757575')
      
      legendsPie
      .append('text')
      .attr('transform', `translate(${_style.x-55}, ${_style.y-18})`)
      .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'middle')
      .text('Total')

      legendsPie
      .append('text')
      .attr('transform', `translate(${_style.x-6}, ${_style.y-18})`)
      .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'end')
      // .text(total.toLocaleString())
      .text(`${total}`)
      .on('mouseover', function() {
        d3.select(this).transition().duration(50)
        .style('opacity', 0.5)
      })
      .on('mouseout', function(){
        d3.select(this).transition().duration(50)
        .style('opacity', 1)
      })
      .style('cursor', 'pointer')
        .on('click', () => { 
          let request = {CATEGORY : _tmp.CATEGORY, STATUS_CODE: '', SOURCE:_tmp.SOURCE}
          let request_ = this.getRequestColumnProps('request', request, this.ColumnProps)
          this.$emit('request-action', request_)
        })
      

      this.get_CoordinatorsXY(_style.x, _style.y, -this.Guideline.GuideInside, 'inX', 'inY')
      this.get_CoordinatorsXY(_style.x, _style.y, this.Guideline.GuideOutside, 'outX', 'outY')
      this.get_PieChartData(_data)

      Filter.setDefs_DropShadow(selection, `_DropShadow`, 1 , 0.1 , 1, 1)

      var arc = d3.arc()
      .outerRadius(this.Circle.outerRadius)
      .innerRadius(this.Circle.CircleHoleSize)
      .cornerRadius(this.Circle.CircleRadius) // Coner Radius

      var pie = d3.pie()
      .sort(null)
      .value((d) => d.value)
      .padAngle(this.Circle.CircleSliceSize/100) // Padding Pie

      let donutGroup = selection
      .append('g')
      .attr('transform', `translate(${_style.x},${_style.y})`)

      donutGroup
      .selectAll('path')
      .data(pie(this.pieSectors))
      .enter()
      .append('path')
      .attr('d', arc)
      .attr('fill', (d, i) => `url(#${this.localId}__LinearA4${this.SharedColorSet[i]}0)`)
      .style('filter', `url(#_DropShadow)`)
      .transition()
      .duration(750)
      .attrTween('d', function(d) {
          var interpolate = d3.interpolate({startAngle: 0, endAngle: 0}, d);
          return function(t) {
              return arc(interpolate(t));
          };
        });
      if (_Guideline.GuideDisplay=='Y') this.drawGuidline(selection, _style.id)
    },
    get_CoordinatorsXY(Cx, Cy, size, nameX, nameY) {
      var ra
      var a = 0 // Angle
      var aCalc = 0 //
      var aRad = 0 // Angle in Rad
      var z = 0 // Size z
      var x = 0 // Side x
      var endX = 0 // endX coordinate
      var endY = 0 // endY coordinate

      this.pieCenterAngles.forEach((item, sn) => {
        ra = this.PieStacks[sn] + size
        a = 360 * item
        aCalc = a > 180 ? 360 - a : a
        aRad = (aCalc * Math.PI) / 180
        z = Math.sqrt(2 * ra * ra - 2 * ra * ra * Math.cos(aRad))
        x = aCalc <= 90 ? ra * Math.sin(aRad) : ra * Math.sin(((180 - aCalc) * Math.PI) / 180)
        endY = Math.sqrt(z * z - x * x)
        endX = a <= 180 ? ra + x : ra - x

        this.pieSectors[sn] = {
          ...this.pieSectors[sn],
          ...{
            [nameX]: endX + Cx - ra,
            [nameY]: endY + Cy - ra,
          }
        }
      })
    },
    get_PieChartData(_data) {
      var ra
      var a = 0 // Angle
      var aCalc = 0 //
      var arcSweep = 0
      var aRad = 0 // Angle in Rad
      var z = 0 // Size z
      var x = 0 // Side x
      var endX = 0 // SVG endX coordinate
      var endY = 0 // SVG endY coordinate
      var Ro = 0 // Rotation

      var h_ra
      var h_z = 0 // Size z
      var h_x = 0 // Side x
      var h_endX = 0 // SVG endX coordinate
      var h_endY = 0 // SVG endY coordinate

      var textSpace = 0
      var direct = 0

      _data.forEach((item, sn) => {
        ra = this.PieStacks[sn]
        h_ra = this.Circle.CircleHoleSize // Hole
        a = 360 * (item.value / 100)
        aCalc = a > 180 ? 360 - a : a
        aRad = (aCalc * Math.PI) / 180
        z = Math.sqrt(2 * ra * ra - 2 * ra * ra * Math.cos(aRad))
        h_z = Math.sqrt(2 * h_ra * h_ra - 2 * h_ra * h_ra * Math.cos(aRad)) // Hole
        if (aCalc <= 90) {
          x = ra * Math.sin(aRad)
          h_x = h_ra * Math.sin(aRad) // Hole
        } else {
          x = ra * Math.sin(((180 - aCalc) * Math.PI) / 180)
          h_x = h_ra * Math.sin(((180 - aCalc) * Math.PI) / 180)
        }

        endY = Math.sqrt(z * z - x * x)
        h_endY = Math.sqrt(h_z * h_z - h_x * h_x) // Hole

        if (a <= 180) {
          endX = ra + x
          h_endX = h_ra + h_x
          arcSweep = 0
        } else {
          endX = ra - x
          h_endX = h_ra - h_x
          arcSweep = 1
        }
        if (this.pieCenterAngles[sn] < 0.5) {
          direct = this.Guideline.GuideExtend
          // align = 'start'
          textSpace = 3
        } else {
          direct = -this.Guideline.GuideExtend
          // align = 'end'
          textSpace = -3
        }

        this.pieSectors[sn] = {
          ...this.pieSectors[sn],
          ...{
            angle: a,
            value: item.value,
            title: item.title,
            color: this.SharedColorSet[sn],
            arcSweep: arcSweep,
            Ra: ra,
            endX: endX,
            endY: endY,

            h_Ra: h_ra, // Hole
            h_endX: h_endX, // Hole
            h_endY: h_endY, // Hole
            Ro: Ro,
            direct: direct,
            // align: align,
            textSpace: textSpace,
            stack: this.PieStacks[sn]
          }
        }
        Ro = Ro + a
      })
    },
  }
}