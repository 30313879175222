import * as d3 from 'd3'

export default {
  data: () => ({
    dataSet: [],
  }),
  computed: {
    ready2draw() {

      // console.log('------------------------------')
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)

      return (
        // this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },
  methods: {
    setDefaultValues(){
    
        this.dataSet = JSON.parse(JSON.stringify(this.DataItems))
        
        this.dataSet.forEach(data => {
         //
        })
  
    },
    
    getMaxBound_v2(v) {
      if(isNaN(v)) return 0
 
      let autoValues = [5, 10, 15, 20, 25, 40, 50, 75, 100, 125, 150, 200, 250, 300, 400, 500, 750, 1000, 1250, 1500, 2000, 2500, 3000, 4000, 5000, 7500, 10000, 12500, 15000, 17500, 20000, 25000, 30000, 40000, 50000, 75000, 100000]
      let valid_ = autoValues.find(val => val >= v)
      let increment_ = 100

      while(!valid_) {
        valid_ = autoValues.map(v => v * increment_).find(val => val > v)
        increment_ = increment_ * 100
      }
      return valid_
    },
    
  }
}