import Defs from '../../../../../../includes/primitives/Color_Defs'
import Data from '../../../../../../includes/primitives/Color_Data'

import * as d3 from 'd3'
import __M from 'moment'

export default {
  data: () => ({
    slideSeq: 1,
    slideMin: 1,
    slideMax: 31,
    selectedItems: [],

    timelineX: 17, // 27 이었는데 줄였음 2021-03-22
    timelineWidth: 1452,
    timeScale: null,
    timelineTickValues: null,

  }),
  computed: {
    ready2draw() {
      // console.log('------------------------------')
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0 
      )
    },
  },
  
  methods: {
    setDefaultValues(){

      // Data stringify
      this.dataSet = JSON.parse(JSON.stringify(this.DataItems))

      this.svg
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['LightGray'], [`LightGray`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['YellowGreen'], [`YellowGreen`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['LightBlue'], [`LightBlue`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['Pink'], [`Pink`, 0.5, 1.0, 0.5, -0.2], this.localId)
      //.attr('fill', `url(#${this.localId}__LinearA4${'LightBlue'}0)`)

      // ### Set Timeline-Scale ### -----------------------------------------
      let startDate_ = __M('2019-01-01').toDate()
      let endDate_ = __M('2022-12-31').toDate()

      this.timeScale = d3.scaleTime()
      .domain([startDate_, endDate_])
      .range([this.timelineX, this.timelineWidth + this.timelineX])

    },
    loadSvg(x, y, path) {
      return d3.xml(`${this.__HOST_NAME_RESOURCE}${path}`).then(source => {
        let innerSvg = source.documentElement
        this.svg.node().append(innerSvg)
        innerSvg = d3.select(innerSvg)

        innerSvg
        .attr('x', x)
        .attr('y', y)

        return new Promise(resolve => {
          resolve(source)
        })
      })
    },
    nextSeq(){ //현재날짜의 최근 미래의 content page가 바뀐다
      let today = __M().format('YYYY-MM-DD')
      let data__ = this.Queries.SQL1.filter(d => d.Cutoff <= today)
      
      if(data__ && data__.length > 0) data__= data__[data__.length-1] // data__를 filter해서 그 배열에서 맨 마지막번째것을 구하자! 그래야 cutoff근처의 날짜페이지로 이동한다!
      else return

      this.slideSeq = parseInt(data__.No)
      this.runTurtle('right')
      
    },
    gotoNext() {
      if(this.slideSeq !== this.slideMax) {
        this.slideSeq += 1
        
        d3.select(`#btn_Back`).transition().style('opacity', 1)
        d3.select(`#arrowMask`).style('visibility', 'visible')

        // Remove SVG
        this.removeSvg(this.Contents, 800)

        setTimeout(() => {
          this.draw_Contents()
          d3.select(`#arrowMask`).style('visibility', 'hidden')
        }, 800);
        let dStage = {
          stage: this.slideSeq,
          mask : 'right',
          level: this.slideMax
        }
        this.selectedItems.push(dStage)
        
      } else {     
        this.slideSeq = 1
        d3.select(`#btn_Back`).transition().style('opacity',0.2)
        d3.select(`#arrowMask`).style('visibility', 'visible')

        // Remove SVG
        this.removeSvg(this.Contents, 800)

        setTimeout(() => {
          this.draw_Contents()
          d3.select(`#arrowMask`).style('visibility', 'hidden')
        }, 800);
        let dStage = {
          stage: this.slideSeq,
          mask : 'right',
          level: this.slideMax
        }
        this.selectedItems.push(dStage)
      }

      this.runTurtle('right')
    },
    gotoBack() {
      if(this.slideSeq == this.slideMin) return
      this.slideSeq -= 1
      d3.select(`#arrowMask`).style('visibility', 'visible')
      // Remove SVG
      this.removeSvg(this.Contents, 800)

      setTimeout(() => {
        this.draw_Contents()
        d3.select(`#arrowMask`).style('visibility', 'hidden')
      }, 800);

      // this.selectedItems.pop()
      let dStage = {
        stage: this.slideSeq,
        mask : 'left',
        level: this.slideMax
      }
      this.selectedItems.push(dStage)
      this.runTurtle('left')
    },

    runTurtle(direction='right') {
      let data_ = this.Queries.SQL1.find(d => parseInt(d.No) == this.slideSeq)
      if(!data_) return

      d3.select(`#dDTitle__${this.localId}`).text(data_.Title) //dropdown title 바꿈
      let curoff = __M(data_.Cutoff).toDate() //Moment로 문자열인 날짜를 int로 바꾼다

      if(direction=='left'){
        d3.select(`#turtle`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/ErectionTileline/turtle_back.png`)
        d3.select('#turtle').transition().duration(500).attr('transform', `translate(${this.timeScale(curoff)}, 160)`)
        if(this.slideSeq == 1 && parseInt(data_.No) == 1 ){
          d3.select(`#btn_Back`).style('opacity', 0.2)
        }
      }else{
        d3.select(`#turtle`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/ErectionTileline/turtle.png`)
        d3.select('#turtle').transition().duration(500).attr('transform', `translate(${this.timeScale(curoff)}, 160)`)
      }
    }
  }
}