import * as d3 from 'd3'
// ------------------------------------------------------------------------------- Global
export default {
  methods: {
    Draw_Charts() {
      this.innerSvg = this.svg.append('svg')
      let Overall = this.innerSvg
      .append('g')
      .attr('id', '_OverallGroup').attr('transform', `translate(0.5, 0.5)`)

      Overall 
      .append('image')
      .attr('id', `_image_map`)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/China1/Images/map.jpg`)
      .attr('transform', `translate(0, 0)scale(0.7)`)
      // .attr('transform', `translate(0, 0)`)

      Object.keys(this.pathes).forEach(k => {
        
        let layer = Overall
        .append('g')
        .attr('id', `area__${k}`)
        .attr('transform', `translate(0, 0)scale(0.7)`)
        // .attr('transform', `translate(0, 0)`)

        layer
        .append('rect').attr('transform', `translate(0, 50)`)
        .attr('width', 1600).attr('height', 1600).attr('opacity', 0.1).style('fill', 'Transparent')

        if(k=='Subcontract'){
          this.pathes[k].items.forEach(item => {
            if(!this.DataItems[0][k].find(d => d.CODE == item.code)) return

            let layerPath = layer
            .append('g').attr('transform', `translate(0, 0)`)

            layerPath
            .append('path').attr('id', `Mask_${item.code}`)
            .attr('d', item.path)
            .style('fill', item.bcolor)
            .style('fill-opacity', .15).attr('stroke-width', 6)
            .attr('stroke', item.lcolor)
            .style('cursor', 'pointer')
            .on('mouseover', (_, i, a) => {
              let el = d3.select(a[i])
              el.transition().style('fill-opacity', 0.6).style('fill', '#43A047')
            })
            .on('mouseout', (_, i, a) => {
              let el = d3.select(a[i])
              el.transition().style('fill-opacity', .15).style('fill', item.bcolor)
            })
            .on('click', () => {
              let dataSource = { BID_SECTION_PACK: this.DataItems[0][k].find(d => d.CODE == item.code).CODE ,JIANLI_SUPERVISION_WORK_PACKAGE: this.DataItems[0][k].find(d => d.CODE == item.code).CODE ,QUALITY_STATION_AREA: this.DataItems[0][k].find(d => d.CODE == item.code).CODE,SUB_CONTRACTOR_ABBREVIATION: this.DataItems[0][k].find(d => d.CODE == item.code).CODE }
              let request_ = this.getRequestColumnProps(k, dataSource, this.ColumnProps)
              this.$emit('request-action', request_)
            })
          })
        }else{
          this.pathes[k].items.forEach(item => {
            if(!this.DataItems[0][k].find(d => d.CODE == item.code)) return

            let layerPath = layer
            .append('g').attr('transform', `translate(0, 0)`)

            layerPath
            .append('path').attr('id', `Mask_${item.code}`)
            .attr('d', item.path)
            .style('fill', '#757575')
            .style('fill-opacity', .15).attr('stroke-width', 6)
            // .attr('stroke', '#757575')
            .style('cursor', 'pointer')
            .on('mouseover', (_, i, a) => {
              let el = d3.select(a[i])
              el.transition().style('fill-opacity', 0.6).style('fill', item.bcolor)
            })
            .on('mouseout', (_, i, a) => {
              let el = d3.select(a[i])
              el.transition().style('fill-opacity', .15).style('fill', '#757575')
            })
            .on('click', () => {
              let dataSource = { BID_SECTION_PACK: this.DataItems[0][k].find(d => d.CODE == item.code).CODE ,JIANLI_SUPERVISION_WORK_PACKAGE: this.DataItems[0][k].find(d => d.CODE == item.code).CODE ,QUALITY_STATION_AREA: this.DataItems[0][k].find(d => d.CODE == item.code).CODE,SUB_CONTRACTOR_ABBREVIATION: this.DataItems[0][k].find(d => d.CODE == item.code).CODE }
              let request_ = this.getRequestColumnProps(k, dataSource, this.ColumnProps)
              this.$emit('request-action', request_)
            })
          })
        }
      })

      let layerButton = Overall
      .append('g')
      .attr('id', 'area__labe_button')
      .attr('transform', `translate(0, 0)scale(0.7)`)
      // .attr('transform', `translate(0, 0)`)

      this.Coordinate.Text.forEach((t,i)=>{
        if(!this.Queries.SQL1.find(d => d.BID_SECTION_PACK == t.code)) return
        let TextBox = layerButton
        .append('g').attr('class', `textBox_${t.code}`)
        .attr('transform', `translate(0, 0)`).attr('font-size', 14).attr('fill', '#fff')

        TextBox
        .append('rect')//BID_SECTION_PACK
        .attr('transform', `translate(${t.x-22}, ${t.y-12})`)
        .attr('width', 45).attr('height', 24).attr('fill', '#388E3C')
        TextBox
        .append('rect')//AWARD_SUBCONTRACTOR_CODE
        .attr('transform', `translate(${t.x+27}, ${t.y-12})`)
        .attr('width', 45).attr('height', 24).attr('fill', '#1976D2')
        TextBox
        .append('rect')//JIANLI_SUPERVISION_WORK_PACKAGE
        .attr('transform', `translate(${t.x-22}, ${t.y+16})`)
        .attr('width', 45).attr('height', 24).attr('fill', '#B71C1C')
        TextBox
        .append('rect')//QUALITY_STATION_AREA
        .attr('transform', `translate(${t.x+27}, ${t.y+16})`)
        .attr('width', 45).attr('height', 24).attr('fill', '#FFA000')

        TextBox
        .append('text')
        .attr('transform', `translate(${t.x}, ${t.y+2})`)
        .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle').attr('font-weight', 'bold')
        .text(this.Queries.SQL1.find(d => d.BID_SECTION_PACK == t.code)&& this.Queries.SQL1.find(d => d.BID_SECTION_PACK == t.code).BID_SECTION_PACK !='NA'? this.Queries.SQL1.find(d => d.BID_SECTION_PACK == t.code).BID_SECTION_PACK : '')
        TextBox
        .append('text')
        .attr('transform', `translate(${t.x+50}, ${t.y+2})`)
        .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle').attr('font-weight', 'bold')
        .text(this.Queries.SQL1.find(d => d.BID_SECTION_PACK == t.code)&& this.Queries.SQL1.find(d => d.BID_SECTION_PACK == t.code).AWARD_SUBCONTRACTOR_CODE !='NA'? this.Queries.SQL1.find(d => d.BID_SECTION_PACK == t.code).AWARD_SUBCONTRACTOR_CODE : '')
        TextBox
        .append('text')
        .attr('transform', `translate(${t.x}, ${t.y+30})`)
        .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle').attr('font-weight', 'bold')
        .text(this.Queries.SQL1.find(d => d.BID_SECTION_PACK == t.code)&& this.Queries.SQL1.find(d => d.BID_SECTION_PACK == t.code).JIANLI_SUPERVISION_WORK_PACKAGE !='NA'? this.Queries.SQL1.find(d => d.BID_SECTION_PACK == t.code).JIANLI_SUPERVISION_WORK_PACKAGE : '')
        TextBox
        .append('text')
        .attr('transform', `translate(${t.x+50}, ${t.y+30})`)
        .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle').attr('font-weight', 'bold')
        .text(this.Queries.SQL1.find(d => d.BID_SECTION_PACK == t.code)&& this.Queries.SQL1.find(d => d.BID_SECTION_PACK == t.code).QUALITY_STATION_AREA !='NA'? this.Queries.SQL1.find(d => d.BID_SECTION_PACK == t.code).QUALITY_STATION_AREA : '')
      })
      layerButton
      .append('rect').attr('transform', `translate(${420}, ${737})`)
      .attr('width', 45).attr('height', 24).attr('fill', '#388E3C')
      layerButton
      .append('text')
      .attr('transform', `translate(${442}, ${751})`)
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle').attr('font-weight', 'bold').attr('font-size', 14).attr('fill', '#fff')
      .text('S5')

      layerButton
      .append('rect').attr('transform', `translate(${420}, ${765})`)
      .attr('width', 45).attr('height', 24).attr('fill', '#B71C1C')
      layerButton
      .append('text')
      .attr('transform', `translate(${442}, ${779})`)
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle').attr('font-weight', 'bold').attr('font-size', 14).attr('fill', '#fff')
      .text('WP3')

      layerButton
      .append('rect').attr('transform', `translate(${469}, ${737})`)
      .attr('width', 45).attr('height', 24).attr('fill', '#1976D2')
      layerButton
      .append('text')
      .attr('transform', `translate(${491}, ${751})`)
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle').attr('font-weight', 'bold').attr('font-size', 14).attr('fill', '#fff')
      .text('FCC')
      
      layerButton
      .append('rect').attr('transform', `translate(${469}, ${765})`)
      .attr('width', 45).attr('height', 24).attr('fill', '#FFA000')
      layerButton
      .append('text')
      .attr('transform', `translate(${491}, ${779})`)
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle').attr('font-weight', 'bold').attr('font-size', 14).attr('fill', '#fff')
      .text('Area1')
      


      //Package Legend
      layerButton
      .append('text')
      .attr('transform', `translate(${1420}, ${25})`)
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle').attr('font-size', 14).attr('fill', '#424242')
      .text('Construction Package Area')
      this.Legend.forEach((d,i)=>{
        layerButton
        .append('rect').attr('transform', `translate(${d.x}, ${d.y})`).attr('width', 40).attr('height', 40).attr('fill', d.color).attr('stroke', d.stroke).attr('stroke-width', 2)
        layerButton
        .append('text').attr('transform', `translate(${d.x+20}, ${d.y+20})`).attr('text-anchor', 'middle').attr('alignment-baseline', 'middle').attr('font-weight', 'bold').attr('font-size', 14).attr('fill', d.tcolor)
        .text(d.package)
      })

      //Text Area
      layerButton
      this.AreaText.forEach((d,i)=>{
        layerButton
        .append('text').attr('transform', `translate(${d.x}, ${d.y})`).attr('text-anchor', 'start'
        ).attr('alignment-baseline', 'middle').attr('font-weight', 'bold').attr('font-size', 15).attr('fill', '#650000')
        .text(d.AREA)
      })

      layerButton
      this.Description.forEach((d,i)=>{
        layerButton
        .append('text').attr('transform', `translate(${d.x+1260}, ${d.y})`).attr('text-anchor', 'start').attr('alignment-baseline', 'middle').attr('font-size', 15).attr('fill', '#000000').style('text-decoration',d.deco ? 'underline': 'none')
        .text(d.DESC)
      })

      let radioW = Overall
      .append('g')
      .attr('id', '_radioWrap').attr('transform', `translate(10, 10)scale(0.9)`)

      radioW
      .append('rect').attr('x', 0).attr('y', 0).attr("rx", 5)
      .attr('width', 500).attr('height', 36)
      .attr('stroke', '#000').attr('stroke-width', 0.3).attr('fill','#ffffff')

      this.radioOptions.values.forEach((d, i) => {
        radioW
        .append('rect').attr('transform', `translate(${d.x+5},4)`)
        .attr('width', 92).attr('height', 25).attr('fill',i ==0?'#43A047':i ==1?'#1E88E5':i ==2?'#FFA000':'#B71C1C')
        //PDF할때 데이터에 #이있으면 작동이 안되서 색깔을 이런식으로

        radioW
        .append('circle')
        .attr('class', `search_circle__${i}`)
        .attr('cx', d.x-5).attr('cy', 17).attr('r', 6)
        .style('stroke', d.on ? this.styleFilter.selected.stroke : this.styleFilter.out.stroke)
        .style('opacity', d.on ? this.styleFilter.selected.opacity : this.styleFilter.out.opacity)
        .style('stroke-width', 1)
        .style('fill', d.on ? this.styleFilter.selected.fill : this.styleFilter.out.fill)

        radioW
        .append('text')
        .attr('class', `search_text__${i}`)
        .attr('transform', `translate(${d.x + 10}, 17)`)
        .style('font-size', 12).attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .style('fill', d.on ? this.styleFilter.selected.color : this.styleFilter.out.color)
        .style('opacity', d.on ? this.styleFilter.selected.opacity : this.styleFilter.out.opacity)
        .text(d.text)

        radioW
        .datum({ filter: this.radioOptions, ...d})
        .append('rect')
        .attr('id', `search_mask__${i}`)
        .attr('transform', `translate(${d.x - 8}, 7)`)
        .attr('width', d.textWidth).attr('height', 16)
        .attr('fill', '#000').attr('fill-opacity', 0)
        .call(this.callFuncLayerFilter)
        
      })

      // this.loadSvg(
      //   layerSvg, {
      //   x   : 0,
      //   y   : 0,
      //   url : '/China1/Images/text.svg'
      // })

      // /**
      //  * Svg Path Tool to get the clicked mouse point (x, y)
      //  */
      // this.createPathReigon(this.innerSvg, {
      //   w: 1600,
      //   h: 1600,
      //   strokeWidth: 2,
      //   strokeColor: '#ff00ff'
      // })

      
    },
  }
}
