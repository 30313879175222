import * as d3 from 'd3'

export default {
  
  methods: {

    drawTimeline() {

      let mainTimeline = this.svgGroup
      .append('g')
      .attr('transform', `translate(${this.styles.margin.left}, ${this.styles.margin.top})`)  // Will be Fixed : y position
      .attr('id', 'MainTineline')

      mainTimeline
      .append('path') // shadow
      .attr('d', `M50,42 H ${this.timeline.width-50} L ${this.timeline.width},52 H0 Z`).attr('fill', `url(#shadowTimeline)`) 

      mainTimeline
      .append('rect')
      .attr('x', 0).attr('y', 0).attr('width', this.timeline.width).attr('height', 40).attr('fill', `url(#${this.localId}_LightGray_timeline)`).attr('opacity', .5)

      mainTimeline
      .append('rect') 
      .attr('x', 0).attr('y', 40).attr('width', this.timeline.width).attr('height', 6).attr('fill', `url(#${this.localId}_LightGray_timeline)`)

      // Timeline Text using the scale function
      // Year

      mainTimeline
      .append('g')
      .selectAll('text')
      .data(this.timeline.values.year)
      .enter()
      .append('text')
      .attr('x', d => this.timeline.scale(d.midDate))
      .attr('y', 15).style('font-size', this.styles.text.year).style('fill', '#EC407A').attr('font-weight', 500)
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(d => d.name.toUpperCase())

      // Month
      mainTimeline
      .append('g')
      .selectAll('text')
      .data(this.timeline.values.month)
      .enter()
      .append('text')
      .attr('x', d => this.timeline.scale(d.midDate))
      .attr('y', 32).style('font-size', this.styles.text.month).style('fill', '#333')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(d => d.name.toUpperCase())

      // Timeline Seperator
      mainTimeline
      .append('g')
      .selectAll('path')
      .data(this.timeline.values.month)
      .enter()
      .append('path')
      .attr('d', (d, i) => {
        if (this.timeline.values.month.length-1 > i) {
          if (d.name == 'Dec')return `M${this.timeline.scale(d.endDate)}, 7 V35`
          else return `M${this.timeline.scale(d.endDate)}, 25 V35`
        }
      })
      .attr('stroke', '#757575').attr('stroke-width', 0.3)

      
      // draw Time-Now icon
      // ---------------------------
      let pointer = mainTimeline
      .append('g')
      .attr('transform', `translate(${this.timeline.scale(new Date(this.cutoff))}, 45)`)

      pointer
      .append('path')
      .attr('transform', `translate(${this.milestone.icon.triangle.x}, ${this.milestone.icon.triangle.y})`)
      .attr('d', `${this.milestone.icon.triangle.path}`)
      .attr('fill', '#fa2c72')
      // .attr('stroke', '#970234')
      // .attr('stroke-width', 1)

    },

  }
}