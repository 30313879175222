import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  data: () => ({
    
  }),

  methods: {

    // ===============================================================================================================================================

    // Canvas_Topside_Activate(lv3) {
  
    //   this.CANVAS = this.svg.append('svg')

    //   this.CANVAS
    //   .attr('width', this.Canvas.CanvasWidth)
    //   .attr('height', this.Canvas.CanvasHeight)
    //   .style('opacity', 1)

    //   let style = null


    //   // console.log(lv3)
    //   this.BlockStatus_Coordinate.filter(f=> f.Lv3 == lv3 && f.Lv4 != null).forEach((d, sn) => {
    //     // Block Status
    //     let itemSet = []
    //     style = {
    //               x: d.x,
    //               y: d.y,
    //               headTextSize: 16,
    //               headTitle: d.NAME,
    //               prog: this.Queries.MasterProg.find(f=> f.Lv3 == lv3),
    //     }
    //     // console.log(this.Queries.MasterProg.find(f=> f.Lv3 == lv3))
    //     this.Status.forEach((d,i) => {
    //       itemSet.push({
    //         name: d.title,
    //         total: this.dataSet.filter(f => f.Lv4 == lv3 && f.EREC == 'Y').length,
    //         actual: this.dataSet.filter(f => f.Lv4 == lv3 && f.EREC == 'Y' && f.STATUS == d.code).length,
    //         bColor: this.Status[i].bColor,
    //         opacity: this.Status[i].opacity,
    //       })
          
    //     })
    //     this.blockStatus(itemSet)

    //     this.Bar_Quantity_02(this.CANVAS, itemSet, style, sn) // <---- Chart Library
    //   })
    // },
  }
}