<template>
  <div :id="localId"></div>
</template>

<script>
import * as d3 from 'd3'
import mx_Core from './includes/mixins/importer'            // Global & Local Mixins

export default {
  name: "j-chart-donut",
  mixins: [mx_Core],
  props: {
    id: String,
    
    FilterValues: {
      type: Object,
      default: () => ({})
    },
    
    // Chart Data
    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },
    DataItems: { type: Array, default: () => ([]) },
    Queries: {type: Object, default: () => ({}) },

    values: {
      type: Object,
      default: () => ({
        milestone: [],
      })
    }
  },
  created() {
    this.localId = this.id || 'j-chart-donut__' + this.safeId('')
  },
  mounted() {
    this.register(this.draw)
  },

  methods: {
    draw() {
      // Check all the necessary values are available
      this.clear()
      this.drawCanvas()

      if (!this.ready2draw) {
        this.drawNoContents()//SDIActive
        return
      }

      this.setDefaultValues()
      
      this.drawChart()
      this.drawDonuts()
      this.drawTexts()

      // 'complete' must be executed to finish the parent process.
      // It sends 'this.localId' & XmlString for some case to make 
      // displaying image easy.
      this.complete()
      this.postComplete()
      let obj = {
        key        : 'Shop Drawing',
        headers    : this.headers,
        items      : this.DataItems,
        level1     : this.level1Names,
        datasource : this.Text.Sql[0].text
      }
      this.exportItems.push(obj) 
    },
    postComplete() {
      let chart_ = this.svg.select('.__table').node().getBoundingClientRect()
      this.resizableCanvasWidth = this.Canvas.CanvasWidth
      this.resizableCanvasHeight = chart_.height + 180
      this.resizeCanvas()

      let dimention = { 
        width: this.resizableCanvasWidth, 
        height: this.resizableCanvasHeight, 
      }
      
      this.$emit('resize', { dimention })
    }
  }
}
</script>