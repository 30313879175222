export default {
  width: 100,
  gab: 10,
  attrs: [
    { 
      text: 'Total',
      refColumn: 'TOTAL',
      codeValue   : 'Total', // fixed
      fColor: '#F7BACF', 
      tColor: '#FF75A5',
      on: true
    },
    { 
      text: 'Not Started',
      refColumn: 'NOTSTARTED',
      codeValue   : 'Not Started',
      fColor: '#BDBCBC', 
      tColor: '#818181',
      on: true
    },
    { 
      text: 'In Progress',
      refColumn: 'INPROGRESS',
      codeValue   : 'A ITR in progress',
      fColor: '#FFD600',
      tColor: '#FFAB00',
      on: true
    },
    { 
      text: 'A ITR Completed',
      refColumn: 'AITR_COMPLETED',
      codeValue   : 'A ITR Completed',
      fColor: '#66CCFF', 
      tColor: '#66CCFF',
      on: true
    },
    { 
      text: 'Partial MC Complete',
      refColumn: 'PARTIAL_MC_COMPLETE',
      codeValue   : 'Partial MC Complete',
      fColor: '#8BC34A', 
      tColor: '#8BC34A',
      on: true
    },
    { 
      text: 'Full MC Complete',
      refColumn: 'FULL_MC_COMPLETE',
      codeValue   : 'Full MC Complete',
      fColor: '#43A047', 
      tColor: '#43A047',
      on: true
    },
  ]
}
