import * as d3 from 'd3'

export default {
  methods: {
    /*
      draw_SEI_Page()
      draw_Header()
      draw_Buttons()
      draw_Legends()
      draw_Keyplan()
      draw_SEI_Title()
    */

    draw_SEI_Page(area_) {
      //for pdf
      if(this.stage_Main) this.stage_Main.remove()
      if(this.stage_Header) this.stage_Header.remove()
      if(this.innerSvg) this.innerSvg.remove()
      if(this.stage_List) this.stage_List.remove()
      
      this.innerSvg = this.svg.append('svg')
      this.stage_SEI = this.svg.append('svg')

      this.loadSvg(
        this.innerSvg, {
        x   : 0,
        y   : 0,
        url : `/China1/Information_Guide/svg2/${area_}.svg`
      }).then(() => {
        this.CurrentArea = area_

        if(this.CurrentPlant && this.CurrentPlant == 'MN') {
          this.SEIs.filter(f=> f.AREA == area_).forEach(d => {
            if(d3.select(`#BOX-${d.SEI}`)) d3.select(`#BOX-${d.SEI}`).style('opacity', 0)
            if(d3.select(`#CODE-${d.SEI}`)) d3.select(`#CODE-${d.SEI}`).style('visibility', 'hidden')
          })
        } else {
          this.Plants.filter(f=> f.code == area_).forEach(d => {
            if(d3.select(`#BOX-${d.code}`)) d3.select(`#BOX-${d.code}`).style('opacity', 0)
            if(d3.select(`#CODE-${d.code}`)) d3.select(`#CODE-${d.code}`).style('visibility', 'hidden')
          })
        }
      })
      
      setTimeout(() => { 
        if(this.CurrentPlant && this.CurrentPlant == 'MN') {
          this.draw_Header(area_)
          this.draw_Buttons()
          this.draw_Legends(area_)
          this.draw_Keyplan(area_)
          this.draw_SEI_Title(area_)
        } else {
          this.draw_Header(area_)
          this.draw_Buttons()
          //this.draw_Legends(area_)
          //this.draw_Keyplan(area_)
          this.draw_SEI_Title(area_)
        }
        //for pdf
        this.complete()
      }, 350)
    },


    draw_Header(area_) {
      //main plant : this.Areas
      //off-plots,enabling project,other plant : this.Plants
      let area = this.CurrentPlant == 'MN' ? this.Areas.find(f=> f.CODE == area_) : this.Plants.find(f=> f.code == area_)
      this.stage_SEI
      .append('text')
      .attr('x', 120).attr('y', 40)
      .attr('font-size', 24).attr('fill', '#000' )
      .text(area_)

      this.stage_SEI
      .append('text')
      .attr('x', 120).attr('y', 67)
      .attr('font-size', 24).attr('fill', '#fff' ).attr('font-weight', 300)
      .text(this.CurrentPlant == 'MN' ? `${area.NAME} (${area.CNAME})`:`${area.name}` )
    },

    draw_Buttons() {
      this.draw_Toggle_v1(this.stage_SEI, {
        variable: 'Toggle', // variable for the value of toggle status
        id: 'Language2', // Group ID
        status: 'on',
        x: 10,
        y: 116,
        width: 40,
        text: 'English/中文',
        ccolor: '#fff',
        on: {
          value: 'EN', // <- on
          bcolor: '#44A9DF',
          tcolor: '#fff',
          text_x: 26, text_y: 9.5,
          slide_x: 51, slide_y: 9,
        },
        off: {
          value: 'CN', // <- off
          bcolor: '#D92129',
          tcolor: '#fff',
          text_x: 40, text_y: 9.5,
          slide_x: 29, slide_y: 9,
        }      
      },
      this.call_Language_SEI
      )
      this.button_Home(this.stage_SEI, {x: 150, y: 110, scale: 0.8,}, this.call_Home)
      if(this.CurrentPlant == 'MN') this.button_Back(this.stage_SEI, {x: 190, y: 110, scale: 0.8,}, this.call_Back)
    },

    draw_Legends(area_) {

      let data_Sub1 = Array.from(new Set([ ...this.SEIs.filter(f=> f.AREA == area_).map(data => data.CC ) ]))
      let data_Sub2 = Array.from(new Set([ ...this.SEIs.filter(f=> f.AREA == area_).map(data => data.JIANLI ) ]))
      let _y = 18

      let legends = this.stage_SEI
      .append('g')
      .attr('transform', `translate(30, 160)`)

      legends
      .append('text')
      .attr('x', 0).attr('y', 0).attr('font-size', 13).attr('fill', '#000' ).attr('font-weight', 430).text(`Legends`)

      legends
      .append('rect')
      .attr('x', 10).attr('y', 10).attr('width', 40).attr('height', 15).attr('fill', '#E09BCD')

      legends
      .append('text')
      .attr('x', 30).attr('y', 21).attr('font-size', 10.5).attr('fill', '#fff' ).attr('text-anchor', 'middle').text(`CC`)

      data_Sub1.forEach(d=> {
        let fData = this.SubContractor.find(f=> f.SUB_CON == d)
        legends
        .append('text')
        .attr('x', 55).attr('y',_y).attr('font-size', 10.5).attr('fill', '#333' ).attr('font-weight', 430)
        .text(fData.SUB_CON)

        legends
        .append('text')
        .attr('id', `${fData.SUB_CON}`)
        .attr('x', 93).attr('y',_y).attr('font-size', 10.5).attr('fill', '#757575' )
        .text(this.Toggle=='on' ? fData.ENG : fData.CHN)
        _y += 17
      })
      
      legends
      .append('rect')
      .attr('x', 10).attr('y', _y).attr('width', 40).attr('height', 15).attr('fill', '#8BBC44')

      legends
      .append('text')
      .attr('x', 30).attr('y', _y + 11).attr('font-size', 10.5).attr('fill', '#fff' ).attr('text-anchor', 'middle').text(`Jian Li`)

      data_Sub2.forEach(d=> {
        let fData = this.SubContractor.find(f=> f.SUB_CON == d)
        legends
        .append('text')
        .attr('x', 55).attr('y',_y + 8).attr('font-size', 10.5).attr('fill', '#333' ).attr('font-weight', 430)
        .text(fData.SUB_CON)

        legends
        .append('text')
        .attr('id', `${fData.SUB_CON}`)
        .attr('x', 93).attr('y',_y + 8).attr('font-size', 10.5).attr('fill', '#757575' )
        .text(this.Toggle=='on' ? fData.ENG : fData.CHN)

        _y += 17
      })
    },
    
    
    draw_Keyplan(area_) {
      this.loadSvg(
        this.stage_SEI, {
        x   : 25,
        y   : 330,
        url : `/China1/Information_Guide/svg2/Keyplan_185.svg`
      }).then(() => {
        this.Areas.forEach(d=> {
          if(area_ == d.CODE) {
            d3.select(`#PLAN-${d.CODE}`).style('opacity', .5)
          } else {
            d3.select(`#PLAN-${d.CODE}`).style('opacity', 0)
          }
        })
      })

      this.stage_SEI
      .append('text')
      .attr('transform', `translate(30, 315)`)
      .style('font-family', 'roboto').style('font-size', 12).style('fill', '#000').attr('font-weight', 430)
      .text('KEYPLAN')
    },


    draw_SEI_Title(area_) {

      let sei_title = this.stage_SEI

      this.SEIs.filter(f=> f.AREA == area_).forEach(d => {

        if(!d3.select(`#LINE-${d.SEI}`)) {
          console.log(`Error: The SEI Code(${d.SEI}) couln't find in svg file!`)
          return
        }

        d.x = d3.select(`#LINE-${d.SEI}`).select('line').attr('x1')
        d.y = d3.select(`#LINE-${d.SEI}`).select('line').attr('y1')

        sei_title
        .append('g')
        .attr('id', `Desc_${d.SEI}`)
        .attr('transform', `translate(${d.x}, ${d.y})`)
        .style('cursor', 'pointer')
        .on('mouseover', () => {
          d3.select(`#BOX-${d.SEI}`).transition().duration(250).style('opacity',1)
        })
        .on('mouseout', () => {
          d3.select(`#BOX-${d.SEI}`).transition().duration(250).style('opacity', 0)
        })


        d3.select(`#Desc_${d.SEI}`)
        .append('circle')
        .attr('x', d.x).attr('y', d.y).attr('r', 2).attr('fill', '#ccc')

        if(d.DIR == 'R') {
          // CC
          d3.select(`#Desc_${d.SEI}`)
          .append('rect')
          .attr('x', 5).attr('y', -7)
          .attr('width', 35).attr('height', 14).attr('fill', '#E09BCD')
          d3.select(`#Desc_${d.SEI}`)
          .append('text')
          .attr('x', 22.5).attr('y',4)
          .attr('font-size', 10).attr('fill', '#333' ).attr('text-anchor', 'middle')
          .text(d.CC)

          // JIANLI
          d3.select(`#Desc_${d.SEI}`)
          .append('rect')
          .attr('x', 45).attr('y', -7)
          .attr('width', 35).attr('height', 14).attr('fill', '#8BBC44')
          d3.select(`#Desc_${d.SEI}`)
          .append('text')
          .attr('x', 62.5).attr('y',4)
          .attr('font-size', 10).attr('fill', '#333' ).attr('text-anchor', 'middle')
          .text(d.JIANLI)

          // Title
          d3.select(`#Desc_${d.SEI}`)
          .append('text')
          .attr('x', 5).attr('y',18)
          .attr('font-size', 11).attr('fill', '#333' ).attr('text-anchor', 'start').attr('font-weight', 430)
          .text(d.SEI)
          d3.select(`#Desc_${d.SEI}`)
          .append('text')
          .attr('id', `SEI_Lang_${d.SEI}`)
          .attr('x', 45).attr('y',18)
          .attr('font-size', 11).attr('fill', '#757575' ).attr('text-anchor', 'start')
          .text(this.Toggle=='on' ? d.SEI_ENG : d.SEI_CHI)
        } else {
          // CC
          d3.select(`#Desc_${d.SEI}`)
          .append('rect')
          .attr('x', -80).attr('y', -7)
          .attr('width', 35).attr('height', 14).attr('fill', '#E09BCD')
          d3.select(`#Desc_${d.SEI}`)
          .append('text')
          .attr('x', -62.5).attr('y',4)
          .attr('font-size', 10).attr('fill', '#333' ).attr('text-anchor', 'middle')
          .text(d.CC)

          // JIANLI
          d3.select(`#Desc_${d.SEI}`)
          .append('rect')
          .attr('x', -40).attr('y', -7)
          .attr('width', 35).attr('height', 14).attr('fill', '#8BBC44')
          d3.select(`#Desc_${d.SEI}`)
          .append('text')
          .attr('x', -22.5).attr('y',4)
          .attr('font-size', 10).attr('fill', '#333' ).attr('text-anchor', 'middle')
          .text(d.JIANLI)

          // Title
          d3.select(`#Desc_${d.SEI}`)
          .append('text')
          .attr('x', -5).attr('y',18)
          .attr('font-size', 11).attr('fill', '#333' ).attr('text-anchor', 'end').attr('font-weight', 430)
          .text(d.SEI)
          d3.select(`#Desc_${d.SEI}`)
          .append('text')
          .attr('id', `SEI_Lang_${d.SEI}`)
          .attr('x', -45).attr('y',18)
          .attr('font-size', 11).attr('fill', '#757575' ).attr('text-anchor', 'end')
          .text(this.Toggle=='on' ? d.SEI_ENG : d.SEI_CHI)
        }
      })
 
      
    },

  }
}
