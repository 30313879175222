import * as d3 from 'd3'

export default {
  methods: {

    draw_Header() {

      this.formSvg = this.svg
      
      this.formSvg
      .append('text')
      .attr('transform', `translate(${this.timeline.baseX}, 40)`)
      .style('font-family', 'roboto').style('font-size', 28).style('fill', 'skyblue')
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(`${this.skyline.title}`)

      this.formSvg
      .append('text')
      .attr('transform', `translate(${this.timeline.baseX}, 60)`)
      .style('font-size', 13)
      .style('fill', '#bcbcbc')
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(`${this.skyline.title2}`)
    },
  }
}