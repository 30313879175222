import * as d3 from 'd3'

export default {
  methods: {
    Activate_Hull_LQ() { //Activate_Hull_LQ + Goto_LQ_Division_Lv3

      // Remove SVG
      this.removeSvg(this.CANVAS, 1000)

      this.svgModeling = this.svg.append('svg')
      this.svgModeling
      .attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight)
      .attr('viewBox', `0 0 ${this.Canvas.CanvasWidth} ${this.Canvas.CanvasHeight}`)
      .attr('opacity', 1)

      // Overall_Hull_Group
      let Hull_LQ = this.svgModeling
      .append('g')
      .attr('id', 'Hull_LQ_Group')
      .attr('opacity', 1)
      .attr('transform', `translate(0,0) scale(1)`)
      .style('cursor', 'pointer')
      
      setTimeout(() => {
        // Draw the Block image & Codes
        this.Hull_LQ.forEach((d,i) => {
          Hull_LQ
          .append('image')
          .attr('id', `Blk_${d.Blk}`)
          .attr('opacity', 1).style('cursor', 'pointer')
          .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Blocks/Hull/${d.Blk}.png`)
          .attr('transform', `translate(${d.x1}, ${d.y1}) scale(${0.5})`)
          .call(s => { this.Goto_Block_Summary(s, d) }) // <--------
        })
      }, 1000);
      setTimeout(() => {
        this.Hull_LQ.forEach(d => {
          d3.select(`#Blk_${d.Blk}`)
          .transition().duration(1000).attr('transform', `translate(${d.x2}, ${d.y2}) scale(0.5)`)
        })
      }, 1000);

      setTimeout(() => {
        this.Masks_Hull_LQ()
        // Display
        this.displayGroupID(`#Mask_LQ00`, 0, 10, 0)
        this.displayGroupID(`#Mask_HD01`, 0, 10, 0)

        this.Lev1 = 'HULL'
        this.Lev2 = 'LQ'
        this.stage = this.STG_Division_Lv3
        this.fncName = 'Activate_Hull_LQ + Goto_LQ_Division_Lv3'
        this.StageCode() // AREA / STAGE
        this.displayGroupID(`#Menu_Back`, 0, 200, 1)

        // ### Call Canvas ###
        this.Canvas_Division_Lv3(this.Lev1, this.Lev2)
      }, 1000);
      // setTimeout(() => {
      //   // Mask_AFT
      //   Hull_LQ
      //   .append('polygon')
      //   .attr('id', 'Mask_LQ_Group')
      //   .attr('points', '0,155 33,180 80,195 109,195 164,147 182,147 238,97 244,69 222,55 173,49 173,0 95,0 51,19 19,56')
      //   .attr('transform', `translate(691,507)`)
      //   .attr('fill', '#fff')
      //   .style('opacity', 0)
      //   .style('cursor', 'pointer')
      //   .call(s => { this.Goto_LQ_Division_Lv3(s) }) // <--------

      //   this.Masks_Hull_LQ() // ######

      //   this.Lev1 = 'HULL'
      //   this.Lev2 = 'LQ'
      //   this.stage = this.STG_Activate
      //   this.StageCode() // AREA / STAGE
      //   this.displayGroupID(`#Menu_Back`, 0, 200, 1)

      //   // ### Call Canvas ###
      //   this.Canvas_Hull_Activate(this.Lev2)
      // }, 1000);
    },

    Masks_Hull_LQ() {

      // Goto_AFT_Division_Lv3
      this.svgModeling  
      .append('polygon')
      .attr('id', 'Mask_LQ00') // Mask_LQ00
      .attr('points', '0,185.5 16,176 16,126 37,104 37,7 59.38,0 84,7.5 84,70 141.25,69 172.75,77.75 184.75,157 213,163.25 213,190 106.5,263 70.13,260 0,231.25')
      .attr('fill', '#fff').attr('transform', `translate(693,440)`)
      .style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_LQ_Division_Lv4(s, 'Mask_LQ00', 'LQ00') }) // <--------

      this.svgModeling  
      .append('polygon')
      .attr('id', 'Mask_HD01') // Mask_HD01
      .attr('points', '0,17 0,66 68,56 120,56 136,28 120,4 86,0 22,0')
      .attr('fill', '#fff').attr('transform', `translate(799,257)`)
      .style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_LQ_BlockDivison_Lv3(s, 'Mask_HD01', 'HD01') }) // <--------

      this.svgModeling 
      .append('polygon')
      .attr('id', 'Mask_LV01') // Mask_LV01
      .attr('points', '0,79 16,97 107,120 215,48 219,20 103.5,0.5 7.5,45.5')
      .attr('fill', '#fff').attr('transform', `translate(704,782)`)
      .style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_LQ_BlockDivison_Lv4(s, 'Mask_LV01', 'LV01') }) // <--------

      this.svgModeling 
      .append('polygon')
      .attr('id', 'Mask_LV23') // Mask_LV23
      .attr('points', '0,66 11,88 104,108 162,68 185,16 86,0')
      .attr('fill', '#fff').attr('transform', `translate(705,620)`)
      .style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_LQ_BlockDivison_Lv4(s, 'Mask_LV23', 'LV23') }) // <--------

      this.svgModeling 
      .append('polygon')
      .attr('id', 'Mask_LV45') // Mask_LV45
      .attr('points', '0,54 0,85 80,112 116,89 163,43 163,12 74,0')
      .attr('fill', '#fff').attr('transform', `translate(717,453)`)
      .style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_LQ_BlockDivison_Lv4(s, 'Mask_LV45', 'LV45') }) // <--------

      this.svgModeling 
      .append('polygon')
      .attr('id', 'Mask_LV67') // Mask_LV67
      .attr('points', '0.13,127.88 24,102 24,1 71,1 71,62 158,64.31 158,121 90.25,174 0,155.75')
      .attr('fill', '#fff').attr('transform', `translate(719,216)`)
      .style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_LQ_BlockDivison_Lv4(s, 'Mask_LV67', 'LV67') }) // <--------
    }
  }
}