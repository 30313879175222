// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  data:() => ({
    thead: [
      { key: 'TYPE', html: 'Category', row: 1,  },
      { key: 'CATEGORY', html: 'Item', row: 1,  },
      { key: 'TOTAL', html: 'Total', row: 1, },
      { key: 'COMPLETED', html: 'Actual', row: 1, },
      { key: 'REMAIN', html: 'Remain', row: 1, rowspan: 1, },
      { key: 'PROG', html: 'Prog', row: 1, },
    ],
    theadrows: 1,
    tbody: [
      { key: 'TYPE', w: 45 },
      { key: 'CATEGORY', w: 75, align: 'left'},
      { key: 'TOTAL', w: 35,type: 'float', align: 'right'},
      { key: 'ACTUAL', w: 35,type: 'float', align: 'right'},
      { key: 'REMAIN', w: 35, type: 'float', align: 'right'},
      { key: 'PROG', w: 40},
    ],
    width: 428,

    selectedStartDate: null,
    selectedStartColumn: null,

    selectedFinishDate: null,
    selectedFinishColumn: null,

    filteredList: null,
    colors:[
      
    ]
  }),
  methods: {
    Draw_Charts() {
      let svg = this.svg

      this.filteredList = this.DataItems

      let list = this.filteredList

      let this_ = this

      let group = svg.append('g').attr('class', 'ogm_table_group__')
      let table_group = group.append('g').attr('transform', `translate(0.5, 0)`)
      
      let table_obj = table_group.append('foreignObject')
        .attr('y',0)
        .attr('width', '100%')
        .attr('height', '370px')
        .append('xhtml:body')
        .style('position', 'fixed')

      let table = table_obj.append('table')
      .attr('id', 'commodity_summary_table_')
      .style('border-collapse', 'collapse')
      .style('font-size', '10px')
      .style('width', '420px')
      .style('table-layout', 'fixed')

      let colgroup = table.append('colgroup')
      this.tbody.forEach(k => {
        colgroup.append('col').style('width', k.w + 'px')
      })

      let thead = table.append('thead')
      for (let r = 1; r <= this.theadrows; r++) {
        let row = this.thead.filter(d=> d.row === r)
        let tr = thead.append('tr')

        row.forEach(item => {

          tr.append('th')
          .attr('class', 'ogm_table_group__th')
          .attr('rowspan', () => item.rowspan ? item.rowspan : '')
          .attr('colspan', () => item.colspan ? item.colspan : '')
          .style('border', 'solid 0.7px #eee')
          .style('height', '20px')
          .style('padding', '3px')
          .style('background', item.color ? item.color : '#FAFAFA')
          .html(item.html)
        })        
      }

      let Type = this.DataItems.map(d => d.TYPE)

      let colorType = new Set(Type)


      let tbodykeys = this.tbody

      let disc = ''
      
      let tbody = table.append('tbody').attr('id', 'commodity_summay_tbody_')

      let addClass = ''

      for (let r = 0; r < list.length; r++) {
        let tr = tbody.append('tr')

        tbodykeys.forEach((k, i) => {

          if (list[r][k.key] == disc) return
          else {
            if (i == 0) {
              disc = list[r][k.key]
            }
          }

          let td = tr.append('td')
            .style('border', 'solid 0.1px #eee')
            .style('height', '18px')
            .style('text-align', k.align ? k.align : 'center')
            .style('padding', '0 3px 0 3px')
            .attr('rowspan', () => {
              if (i == 0) {
                let filtered = list.filter(d => d.TYPE == list[r][k.key])
                return filtered.length
              }
            })
          if(i === 0) {
              td.style('color','#000000')
              td.style('background-color', list[r][k.key] == 'MC' ? '#E3F2FD' : list[r][k.key] == 'Comm.' ? '#BBDEFB' : list[r][k.key] == 'RFSU' ?'#B3E5FC' : list[r][k.key] == 'Checksheet' ?'#F1F8E9' :'#FFFDE7')
          }
          if(i === 1) {
            td.style('white-space', 'nowrap')
              .style('overflow', 'hidden')
              .style('text-overflow', 'ellipsis')
              .attr('title', list[r][k.key])
          }
          if(i === 4) {
            td.style('color', list[r][k.key] > 0 ? '#F44336' : '#000')
              .attr('title', list[r][k.key])
          }
          if(i === 5) {
            td.style('color', list[r][k.key] < 0 ? 'red' : list[r][k.key] > 0 ? 'green' : '#000')
              .attr('title', list[r][k.key])
          }

          // td.on('mouseover', () => tr.style('cursor', 'pointer'))
          // td.on('click', () => {
          //   // console.log(list[r].GWBS_LV3,list[r].WCCODE)
          //   let dataSource = {
          //     GWBS_LV3: list[r].GWBS_LV3,
          //     WCCODE: list[r].WCCODE
          //   }
          //   let request_ = this.getRequestColumnProps('status', dataSource, this.ColumnProps)
          //   this.$emit('request-action', request_)
          // })

          if(k.key.includes('PROG')) {
            addClass = ''
            td
            .style('position', 'relative')
            .style('color', list[r][k.key] < 0 ? 'red' : list[r][k.key] === 100 ? '#fff' : addClass == 'caution' ? 'red' : '#000')
            .style('z-index', '1')
            .html(list[r][k.key] == null ? 0+'%' :list[r][k.key]+'%')

            td.append('div')
            .style('position', 'absolute')
            .style('left', 0)
            .style('top', 0)
            .style('width', list[r][k.key] > 0 ? list[r][k.key] + '%' : 0)
            .style('height', '17px')
            .style('background-color', list[r][k.key] < 100 ? k.key.includes('PLAN') ? '#E0E0E0' : '#FFCA28' : '#4CAF50')
            .style('z-index', '-1')
          }
          else {
            if (k.type == 'float') {
              let regexp = /\B(?=(\d{3})+(?!\d))/g
              if (isNaN(Math.floor(list[r][k.key]))) td.html('')
              else td.html(Math.round(Math.floor(list[r][k.key] * 100) / 100).toString().replace(regexp, ','))
            } else td.html(list[r][k.key])
          }
        })
      }
    },
  }
}
