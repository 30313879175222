import * as d3 from 'd3'

export default {
  data: () => ({
    defaultset: { 
      imageName:'P3_CSU'
    },
  }),
  computed: {
    ready2draw() {

      // console.log('------------------------------')
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)

      return (
        Object.keys(this.Canvas).length > 0
      )
    },
  },
  methods: {
    setDefaultValues(){
      d3.selection.prototype.moveToFront = function() {
        return this.each(function(){
          this.parentNode.appendChild(this)
        })
      }
      d3.selection.prototype.moveToBack = function() {  
        return this.each(function() { 
            var firstChild = this.parentNode.firstChild; 
            if (firstChild) { 
                this.parentNode.insertBefore(this, firstChild); 
            } 
        })
      }

    },
    loadSvg(params) {
      return d3.xml(`${this.__HOST_NAME_RESOURCE}${params.path}`).then(source => {
        let svg = source.documentElement
        params.selection.node().append(svg)

        svg = d3.select(svg)
        if(params.x === 0 || params.x) svg.attr('x', params.x)
        if(params.y === 0 || params.y) svg.attr('y', params.y)
        if(params.width === 0 || params.width) svg.attr('width', params.width)
        if(params.height === 0 || params.height) svg.attr('height', params.height)

        return new Promise(resolve => {
          resolve(svg)
        })
      })
    },
    zoom() {
      let transform = d3.event.transform
      let el = this.innerSvg.select('#svg_content_wrapper')
      el.attr('transform', transform)

      // let translate = eltransform.match(/translate(.*?)\)/)
      // let coodinate = translate[0].match(/-?\d+\.?(\d+)?/g)
    },
  }
}