import * as d3 from 'd3'

export default {
  methods: {
    /*    
      call_MainMenu(selection, d_)
      call_Plants(selection, d_)
      call_Coordinate(selection, d_)
      call_AreaList(selection, d_)
      call_CheckList(selection, data_)
      call_Home(selection)
      call_Back(selection)
      call_Language(selection, style_)
      call_Language_SEI(selection, style_)
    */
    call_MainMenu(selection, d_) {
      selection
      .on('mouseover', () => {
        this.svg.select(`#MENU-${d_.code}`).select('g')
        .style('opacity', 1)
        d3.select(`#TEXT-BG`)
        .transition().duration(250).attr('height', d_.height)
        d3.select(`#TEXT-${d_.code}`)
        .transition().delay(100).duration(250).style('opacity', .8)
      })
      .on('mouseout', () => {
        this.svg.select(`#MENU-${d_.code}`).select('g')
        .style('opacity', this.CurrentPlant == d_.code ? 1 : 0.5)
        
        d3.select(`#TEXT-BG`)
        .transition().duration(250).attr('height', 0)
        d3.select(`#TEXT-${d_.code}`)
        .transition().duration(250).style('opacity', 0)
      })
      .on('click', () => {
        // Clicking here will show the plants of that group.
        this.svg.select(`#MENU-${this.CurrentPlant}`).select('g')
        .style('opacity',  0.5)

        this.CurrentPlant = d_.code
        this.load_Plants(d_) // --> draw_Homepage.js 
        
        //for pdf
        this.selectedItems = { 
          ...this.selectedItems,
          level: 'plant',
          lang: this.Toggle,
          plant: this.CurrentPlant,
          pkg: '',
          area: '',
          info: 'off',
        }
      }) 
    },
    
    call_Plants(selection, d_) {
      selection
      .on('mouseover', () => {
        d3.select(`#TAG-${d_.code}`)
        .transition().duration(250).style('opacity', .5)
      })
      .on('mouseout', () => {
        d3.select(`#TAG-${d_.code}`)
        .transition().duration(250).style('opacity', 1)
      })
      .on('click', () => {
        if(this.CurrentPlant == 'MN') {
          this.stage_Main.remove()

          this.draw_Forms()
          this.load_MainPlant()
          this.draw_Checkbox()
          this.draw_ListboxHeader()
          setTimeout(() => { this.draw_ListboxDetail() }, 350)

          //for pdf
          this.selectedItems = { 
          ...this.selectedItems,
          level: 'pkg',
          lang: this.Toggle,
          plant: this.CurrentPlant,
          pkg: this.CurrentPkg,
          area: '',
          info: 'off',
        }
        } else if(this.CurrentPlant == 'OF' || this.CurrentPlant == 'EN') {
          this.stage_Main.remove()
          //console.log(d_.code)
          this.draw_SEI_Page(d_.code)
          
          //for pdf
          this.selectedItems = { 
            ...this.selectedItems,
            level: 'area',
            lang: this.Toggle,
            plant: this.CurrentPlant,
            pkg: this.CurrentPkg,
            area: d_.code,
            info: 'on',
          }
          
        }
      }) 
    },

    call_Coordinate(selection, d_) {

      selection
      .on('mouseover', () => {
        this.innerSvg.select(`#BOX-${d_.CODE}`)
        .transition().duration(250)
        .style('opacity', .75)

        this.innerSvg.select(`#AREA-${d_.CODE}`)
        .transition().duration(250)
        .attr('transform', `translate(0, 0) scale(1)`)
        .style('opacity', 1)
      })

      .on('mouseout', () => {
        this.innerSvg.select(`#BOX-${d_.CODE}`)
        .transition().duration(250)
        .style('opacity', 0)

        this.innerSvg.select(`#AREA-${d_.CODE}`)
        .transition().duration(250)
        .attr('transform', `translate(${d_.x}, ${d_.y}) scale(0)`)
        .style('opacity', 0)
      })

      .on('click', () => {
        // let request = this.buildRequest(d_.CODE)
        // if(request) this.$emit('request-action', request)
        this.draw_SEI_Page(d_.CODE)
        //for pdf
        this.selectedItems = { 
          ...this.selectedItems,
          level: 'area',
          lang: this.Toggle,
          plant: this.CurrentPlant,
          pkg: this.CurrentPkg,
          area: d_.CODE,
          info: 'on',
        }
      })
    },

    call_AreaList(selection, d_) {
      let legend = this.Legend.find(f=> f.NAME == d_.PACKAGE)
      selection
      .on('mouseover', () => {
        selection.select('rect')
        .transition().duration(100).style('fill', legend.bcolor).style('opacity', 0.5)

        this.innerSvg.select(`#BOX-${d_.CODE}`)
        .transition().duration(150).style('opacity', .9)

        this.innerSvg.select(`#AREA-${d_.CODE}`)
        .transition().duration(150)
        .attr('transform', `translate(0, 0) scale(1)`).style('opacity', 1)

        this.innerSvg.select(`#LIST_${d_.CODE}`)
        .transition().duration(150)
        .style('opacity', .3)
      })

      .on('mouseout', () => {
        selection.select('rect')
        .transition().duration(100).style('fill', '#fff').style('opacity', 1)

        this.innerSvg.select(`#BOX-${d_.CODE}`)
        .transition().duration(150) .style('opacity', 0)

        this.innerSvg.select(`#AREA-${d_.CODE}`)
        .transition().duration(150)
        .attr('transform', `translate(${d_.x}, ${d_.y}) scale(0)`).style('opacity', 0)
      })

      .on('click', () => {
        // let request = this.buildRequest(d_.CODE)
        // if(request) this.$emit('request-action', request)
        this.draw_SEI_Page(d_.CODE)

        //for pdf
        this.selectedItems = { 
          ...this.selectedItems,
          level: 'area',
          lang: this.Toggle,
          plant: this.CurrentPlant,
          pkg: this.CurrentPkg,
          area: d_.CODE,
          info: 'on',
        }
      })
    },

    call_CheckList(selection, data_,) {

      selection
      .on('mouseover', () => {
        this.svg.select(`#package_checkbox__${data_.CODE}`).attr('stroke', '#bcbcbc')
      })
      .on('mouseout', () => {
        this.svg.select(`#package_checkbox__${data_.CODE}`).attr('stroke', data_.bcolor)
      })
      .on('click', () => {
        if(data_.CHECKED) return
        
        data_.CHECKED = true
        this.CurrentPkg = data_.CODE
        d3.select(`#package_checkbox_checked__${data_.CODE}`).style('visibility', 'visible')
        d3.select(`#PKG-${this.CurrentPkg}`).style('opacity', 0.3)

        this.Legend.filter(f => f.CODE != this.CurrentPkg).forEach(d => {
          d3.select(`#PKG-${d.CODE}`).style('opacity', 0)
          d3.select(`#package_checkbox_checked__${d.CODE}`).style('visibility', 'hidden')
          d.CHECKED = false
        })

        this.stage_List.remove()

        this.draw_ListboxDetail()
        this.svg.select(`#list_title`).text(`PACKAGE LIST - ${data_.CODE}`)

        //for pdf
        this.selectedItems = { 
          ...this.selectedItems,
          level: 'check',
          lang: this.Toggle,
          plant: this.CurrentPlant,
          pkg: this.CurrentPkg,
          area: this.CurrentArea,
          info: 'off',
        }
      })
    },

    call_Home(selection) {
      selection
      .on('click', () => {
        if(this.innerSvg) this.innerSvg.remove()
        if(this.stage_SEI) this.stage_SEI.remove()
        if(this.stage_Header) this.stage_Header.remove()
        if(this.stage_List) this.stage_List.remove()
        this.draw_HomePage()

        //for pdf
        this.selectedItems = { 
          ...this.selectedItems,
          level: 'home',
          lang: this.Toggle,
          plant: this.CurrentPlant,
          pkg: this.CurrentPkg,
          area: this.CurrentArea,
          info: 'off',
        }
      })
    },


    call_Back(selection) {
      selection
      .on('click', () => {
        if(this.innerSvg) this.innerSvg.remove()
        if(this.stage_SEI) this.stage_SEI.remove()
        if(this.stage_Header) this.stage_Header.remove()
        if(this.stage_List) this.stage_List.remove()

        this.draw_Forms()
        this.load_MainPlant()
        this.draw_Checkbox()
        this.draw_ListboxHeader()
        setTimeout(() => { this.draw_ListboxDetail() }, 350)

        //for pdf
        this.selectedItems = { 
          ...this.selectedItems,
          level: 'back',
          lang: this.Toggle,
          plant: this.CurrentPlant,
          pkg: this.CurrentPkg,
          area: '',
          info: 'off',
        }
      })
    },

    call_Language(selection, style_) {
      selection.on('click', () => { 
        (this.Toggle == 'on') ? this[style_.variable] = 'off' : this[style_.variable] = 'on'

        //this.Toggle_SEI  =this.Toggle

        this.toggle_Action_v1(this.Toggle, style_) // ---> below
        this.rebuild_Text()

        this.selectedItems = { 
          ...this.selectedItems,
          lang: this.Toggle,
        }
      })
    },

    call_Language_SEI(selection, style_) {
      selection.on('click', () => { 
        (this.Toggle == 'on') ? this[style_.variable] = 'off' : this[style_.variable] = 'on'

        this.toggle_Action_v1(this.Toggle, style_) // ---> below

        let data_Sub1 = Array.from(new Set([ ...this.SEIs.filter(f=> f.AREA == this.CurrentArea).map(data => data.CC ) ]))
        let data_Sub2 = Array.from(new Set([ ...this.SEIs.filter(f=> f.AREA == this.CurrentArea).map(data => data.JIANLI ) ]))
        let data_SubAll = [...data_Sub1, ...data_Sub2]

        data_SubAll.forEach(d=>{
          let fData = this.SubContractor.find(f=> f.SUB_CON == d)
          d3.select(`#${d}`)
          .text((this.Toggle == 'on') ? fData.ENG : fData.CHN)
        })

        this.SEIs.filter(f=> f.AREA == this.CurrentArea).forEach(d => {
          d3.select(`#SEI_Lang_${d.SEI}`)
          .text((this.Toggle == 'on') ? d.SEI_ENG : d.SEI_CHI)
        })

        this.selectedItems = { 
          ...this.selectedItems,
          lang: this.Toggle
        }
      })
    },
  }
}