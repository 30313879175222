// ------------------------------------------------------------------------------- Global
import * as d3 from 'd3'

export default {
  data: () => ({
    
  }),


  methods: {

    // --------------------------------------------------------------------------------------------------------------------------- Bar_Quantity_01
    Bar_Quantity_01(svg, _data, _style, _id) {

      let style = {
        headY: 0, 
        headTextSize: 20,
        headTextColor: '#000',
        headTitle: null,
        headDescrEQ3D: null,

        subY: 40, 
        subText: 'Status of Erection Blocks',
        subTextColor: '#333',
        subTextSize: 11,

        progDisplay: 'Y',
        deltaDisplay: 'Y',
        prog: null,
        proY: 20,
        progTextSize: 14,

        barX: 80,
        barY: 55,
        bartextSize: 9,
        barTextColor: '#bcbcbc',
        barLength: 90,
        barHeight: 10,
        barSpace: 4,
      }

      style = { ...style, ..._style }
      let blockstatus = svg
      .append('g')
      .attr('transform', `translate(${style.x}, ${style.y})`)
   
      blockstatus
      .append('text')
      .attr('id', `Title_${_id}`)
      .attr('x', 0).attr('y', style.headY)
      .style('font-family', 'roboto')
      .style('font-size', style.headTextSize)
      .style('fill', style.headTextColor)
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(style.headTitle)

      blockstatus
      .append('text')
      .attr('id', `Title_${_id}`)
      .attr('x', 0).attr('y', style.headY-17)
      .style('font-family', 'roboto')
      .style('font-size', 11)
      .style('fill', '#0277BD')
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(style.headDescr)

      // Delta Progress(%)
      if (style.deltaDisplay == 'Y') {
        blockstatus
        .append('text')
        .attr('id', `Delta_${_id}`)
        .attr('x', this.getNodeElValue(`#Title_${_id}`, 'width') + 10).attr('y', style.headY)
        .style('font-family', 'roboto')
        .style('font-size', style.progTextSize)
        .style('fill', () => {
          if(style.prog.DELTA < 0) return '#EC407A'
          if(style.prog.DELTA == 0) return '#bcbcbc'
          if(style.prog.DELTA > 0) return '#4CAE4E'
        })
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text(`${style.prog.DELTA}%`)
      
        this.Progress_Icon(blockstatus, style.prog.DELTA, this.getNodeElValue(`#Title_${_id}`, 'width') + this.getNodeElValue(`#Delta_${_id}`, 'width') + 12, -6, 0.4 )
      }

      //Plan & Actual Progress(%)
      if (style.progDisplay == 'Y') {
        blockstatus
        .append('text')
        .attr('x', 0).attr('y', style.proY)
        .style('font-family', 'roboto').style('font-size', 9).style('fill', '#bcbcbc').attr('text-anchor', 'start').attr('alignment-baseline', 'start')
        .text('Plan Cuml.')

        blockstatus
        .append('text')
        .attr('x', 100).attr('y', style.proY)
        .style('font-family', 'roboto').style('font-size', 9).style('fill', '#bcbcbc').attr('text-anchor', 'start').attr('alignment-baseline', 'start')
        .text('Actual Cuml.')

        blockstatus
        .append('text')
        .attr('x', 50).attr('y', style.proY)
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#333').attr('text-anchor', 'start').attr('alignment-baseline', 'start')
        .text(`${style.prog.PLAN}%`)

        blockstatus
        .append('text')
        .attr('x', 155).attr('y', style.proY)
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#44A9DF').attr('text-anchor', 'start').attr('alignment-baseline', 'start')
        .text(`${style.prog.ACTUAL}%`)
      } else {
        style.barY -= style.proY
        style.subY -= style.proY
      }
    
      blockstatus
      .append('text')
      .attr('x', 0).attr('y', style.subY)
      .style('font-family', 'roboto')
      .style('font-size', style.subTextSize)
      .style('fill', style.subTextColor)
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(style.subText)
 

      let drawY = style.barY

      _data.forEach((d)=> {
        let actualLen = d.total ? (d.actual / d.total) * style.barLength : 0 //!d.total ? 0 : (d.actual / d.total) * style.barLength 분모가 0 이면 오류 생기기 때문에 분모가 0 이면 0으로!

        blockstatus
        .append('text')
        .attr('transform', `translate(${style.barX-5}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', style.bartextSize).style('fill', style.barTextColor).style('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text(d.name)

        blockstatus
        .append('rect')
        .attr('transform', `translate(${style.barX}, ${drawY - (style.barHeight/2) - .5})`)
        .style('width', style.barLength).style('height', style.barHeight).style('fill', '#F4F5F5').attr('opacity', 1)
        
        blockstatus
        .append('rect')
        .attr('transform', `translate(${style.barX}, ${drawY - (style.barHeight/2) - .5})`)
        .style('width', actualLen).style('height', style.barHeight).style('fill', d.bColor).attr('opacity', d.opacity)

        blockstatus
        .append('text')
        .attr('transform', () => {
          if (actualLen > 20) return `translate(${style.barX + actualLen - 2}, ${drawY + 0.5})`; else return `translate(${style.barX + actualLen + 2}, ${drawY})`
        })
        .style('text-anchor', () => {
          if (actualLen > 20) return 'end'; else return 'start'
        })
        .style('fill', () => {
          if (actualLen > 20) return '#fff'; else return '#757575'
        })
        .style('font-family', 'roboto').style('font-size', style.bartextSize).attr('alignment-baseline', 'middle')
        .text(d.actual)

        blockstatus
        .append('text')
        .attr('transform', `translate(${style.barX + style.barLength + 2}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 9).style('fill', '#757575').style('text-anchor', 'start').attr('alignment-baseline', 'middle').text(d.total)

        drawY += style.barHeight + style.barSpace
      })
    },
    Bar_Quantity_02(svg, _data, _style, _id) {
      let style = {
        headY: 0, 
        headTextSize: 20,
        headTextColor: '#000',
        headTitle: null,
        headTitle2: null,
        headDescrEQ3D: null,

        subY: 20, 
        subText: 'Status of Equipment',
        subTextColor: '#616161',
        subTextSize: 12,

        progDisplay: 'Y',
        deltaDisplay: 'Y',
        prog: null,
        proY: 20,
        progTextSize: 14,

        barX: 40,
        barY: 45,
        bartextSize: 10,
        barTextColor: '#757575',
        TextColor: '#212121',
        barLength: 90,
        barHeight: 10,
        barSpace: 4,
      }
      let _self = this
      style = { ...style, ..._style }

      let blockstatus = svg
      .append('g')
      .attr('transform', `translate(${style.x}, ${style.y})`)
   
      blockstatus
      .append('text')
      .attr('id', `Title_${_id}`)
      .attr('x', 0).attr('y', style.headY)
      .style('font-family', 'roboto')
      .style('font-size', style.headTextSize)
      .style('fill', style.headTextColor)
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(style.headTitle)

      blockstatus
      .append('text')
      .attr('id', `Title_${_id}`)
      .attr('x', 0).attr('y', style.headY)
      .style('font-family', 'roboto')
      .style('font-size', 15)
      .style('fill', '#0288D1')
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(style.headTitle2)

      blockstatus
      .append('text')
      .attr('id', `Title_${_id}`)
      .attr('x', 0).attr('y', style.headY-17)
      .style('font-family', 'roboto')
      .style('font-size', 11)
      .style('fill', '#0277BD')
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(style.headDescrEQ3D)

      blockstatus
      .append('text')
      .attr('x', 0).attr('y', style.subY)
      .style('font-family', 'roboto')
      .style('font-size', style.subTextSize)
      .style('fill', style.subTextColor)
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(style.subText)
 
      let drawY = style.barY

      _data.forEach((d)=> {
        
        if(['PO','FAT','IRN','ON Site','Installed'].includes(d.name)){
          let actualLen = d.total ? (d.actual / d.total) * style.barLength : 0 //!d.total ? 0 : (d.actual / d.total) * style.barLength 분모가 0 이면 오류 생기기 때문에 분모가 0 이면 0으로!

        blockstatus
        .append('text')
        .attr('transform', `translate(${style.barX-5}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', style.bartextSize).style('fill', style.barTextColor).style('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text(d.name)

        blockstatus
        .append('rect')
        .attr('transform', `translate(${style.barX}, ${drawY - (style.barHeight/2) - .5})`)
        .style('width', style.barLength).style('height', style.barHeight).style('fill', '#F4F5F5').attr('opacity', 1)
        
        blockstatus
        .append('rect')
        .attr('transform', `translate(${style.barX}, ${drawY - (style.barHeight/2) - .5})`)
        .style('width', actualLen).style('height', style.barHeight).style('fill', d.bColor).attr('opacity', d.opacity)

        blockstatus
        .append('text')
        .attr('transform', () => {
          if (actualLen > 20) return `translate(${style.barX + actualLen - 2}, ${drawY })`; else return `translate(${style.barX + actualLen + 2}, ${drawY})`
        })
        .style('text-anchor', () => {
          if (actualLen > 20) return 'end'; else return 'start'
        })
        .style('fill', () => {
          if (actualLen > 20) return '#fff'; else return '#757575'
        })
        .style('font-family', 'roboto').style('font-size', style.bartextSize).attr('alignment-baseline', 'middle')
        .text(d.actual)

        blockstatus
        .append('text')
        .attr('transform', `translate(${style.barX + style.barLength + 2}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 9).style('fill', '#757575').style('text-anchor', 'start').attr('alignment-baseline', 'middle').text(d.total)
        
        } else {
          blockstatus
          .append('text')
          .attr('transform', `translate(${style.barX+88}, ${drawY+10})`)
          .style('font-family', 'roboto').style('font-size', style.bartextSize).style('fill', style.TextColor).style('text-anchor', 'end').attr('alignment-baseline', 'middle')
          .text(d.name)

          blockstatus
          .append('text')
          .attr('transform', `translate(${style.barX+93}, ${drawY+10})`)
          .style('font-family', 'roboto').style('font-size', style.bartextSize).style('fill', '#EC407A').style('text-anchor', 'start').attr('alignment-baseline', 'middle')
          .text(d.total)
          .style('cursor', 'pointer')
          .style('text-decoration', 'underline')  
          .on('mouseover', function(){ 
            d3.select(this).transition().duration(100)
            .style('fill', '#000').style('text-decoration', 'underline')  
          })
          .on('mouseout', function(){ 
            d3.select(this).transition().duration(100).style('fill', '#EC407A')
          })
          .on('click', () => {
            console.log(_data)
            let colPropsUsed = false

            if(this.ColumnProps && this.ColumnProps.length > 0) {
              let colProps_ = this.ColumnProps.find(props_ => props_.value == 'blockInfo')
              
              if(colProps_ && colProps_.link) {
                colPropsUsed = true

                let dataSource = { ...d.filter, ...this.FilterValues }
                let request_ = this.getRequestColumnProps('blockInfo', dataSource, this.ColumnProps)
                if(request_) this.$emit('request-action', request_)
              }
            }

            if(colPropsUsed) return
            // console.log(d.filter)

            // Con_OvEqBarProg_Hull_Common------해당되는것
            let request_ = {
              dataType: 'row',    
              action: {
                type: 'direct',     // direct | parent
                target: 'slide-modal',
                component: 'ServiceDatatable',
                id: 176, //filterString때문에 dev만 166 tortue 176바꿔야함
                no: ''
              },
              filters: { 
                ...this.FilterValues,
                ...this.inFilters,
                STATUS: d.filter.STATUS,
                // STATUS: d.status, --이것만들어가니 각 LV별 해당 status가 나눠지지않음
                LV1:d.filter.LV1,
                LV2:d.filter.LV2,
                LV3:d.filter.LV3,
                LV4:d.filter.LV4,
              },
              iFilters: {}
            }
            if (d.filter.LV2 === 'HULL-COMMON') request_.iFilters.filterString = `[LV1] = 'HULL' AND [LV3] IN ('FOR','AFT')`
            this.$emit('request-action', request_)
          })
        }
        drawY += style.barHeight + style.barSpace
      })
    },
    Bar_Quantity_03(svg, _data, _style, _id) {

      let style = {
        headY: 0, 
        headTextSize: 20,
        headTextColor: '#000',
        headTitle: null,
        headDescrEQ3D: null,

        subY: 20, 
        subText: 'Status of Welding Defect Rate',
        subTextColor: '#9E9E9E',
        subTextSize: 11,

        progDisplay: 'Y',
        deltaDisplay: 'Y',
        prog: null,
        proY: 20,
        progTextSize: 14,

        barX: 80,
        barY: 55,
        bartextSize: 9,
        barTextColor: '#bcbcbc',
        barLength: 90,
        barHeight: 10,
        barSpace: 4,
      }

      style = { ...style, ..._style }
      let blockstatus = svg
      .append('g')
      .attr('transform', `translate(${style.x}, ${style.y})`)
      // console.log(_data)
      // console.log(_style)
      blockstatus
      .append('text')
      .attr('id', `Title_${_id}`)
      .attr('x', 0).attr('y', style.headY)
      .style('font-family', 'roboto')
      .style('font-size', style.headTextSize)
      .style('fill', style.headTextColor)
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(style.headTitle)

      blockstatus
      .append('text')
      .attr('id', `Title_${_id}`)
      .attr('x', 0).attr('y', style.headY-17)
      .style('font-family', 'roboto')
      .style('font-size', 11)
      .style('fill', '#0277BD')
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(style.headDescr)

      // Delta Progress(%)
      // if (style.deltaDisplay == 'Y') {
      //   blockstatus
      //   .append('text')
      //   .attr('id', `Delta_${_id}`)
      //   .attr('x', this.getNodeElValue(`#Title_${_id}`, 'width') + 10).attr('y', style.headY)
      //   .style('font-family', 'roboto')
      //   .style('font-size', style.progTextSize)
      //   .style('fill', () => {
      //     if(style.prog.DELTA < 0) return '#EC407A'
      //     if(style.prog.DELTA == 0) return '#bcbcbc'
      //     if(style.prog.DELTA > 0) return '#4CAE4E'
      //   })
      //   .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      //   .text(`${style.prog.DELTA}%`)
      
      //   this.Progress_Icon(blockstatus, style.prog.DELTA, this.getNodeElValue(`#Title_${_id}`, 'width') + this.getNodeElValue(`#Delta_${_id}`, 'width') + 12, -6, 0.4 )
      // }

      //Plan & Actual Progress(%)
      // if (style.progDisplay == 'Y') {
      //   blockstatus
      //   .append('text')
      //   .attr('x', 0).attr('y', style.proY)
      //   .style('font-family', 'roboto').style('font-size', 9).style('fill', '#bcbcbc').attr('text-anchor', 'start').attr('alignment-baseline', 'start')
      //   .text('Plan Cuml.')

      //   blockstatus
      //   .append('text')
      //   .attr('x', 100).attr('y', style.proY)
      //   .style('font-family', 'roboto').style('font-size', 9).style('fill', '#bcbcbc').attr('text-anchor', 'start').attr('alignment-baseline', 'start')
      //   .text('Actual Cuml.')

      //   blockstatus
      //   .append('text')
      //   .attr('x', 50).attr('y', style.proY)
      //   .style('font-family', 'roboto').style('font-size', 11).style('fill', '#333').attr('text-anchor', 'start').attr('alignment-baseline', 'start')
      //   .text(`${style.prog.PLAN}%`)

      //   blockstatus
      //   .append('text')
      //   .attr('x', 155).attr('y', style.proY)
      //   .style('font-family', 'roboto').style('font-size', 11).style('fill', '#44A9DF').attr('text-anchor', 'start').attr('alignment-baseline', 'start')
      //   .text(`${style.prog.ACTUAL}%`)
      // } else {
      //   style.barY -= style.proY
      //   style.subY -= style.proY
      // }
    
      blockstatus
      .append('text')
      .attr('x', 0).attr('y', style.subY)
      .style('font-family', 'roboto')
      .style('font-size', style.subTextSize)
      .style('fill', style.subTextColor)
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(style.subText)
 

      let drawY = style.barY

      // _data.forEach((d)=> {
      //   let actualLen = d.total ? (d.actual / d.total) * style.barLength : 0 //!d.total ? 0 : (d.actual / d.total) * style.barLength 분모가 0 이면 오류 생기기 때문에 분모가 0 이면 0으로!

      //   blockstatus
      //   .append('text')
      //   .attr('transform', `translate(${style.barX-5}, ${drawY})`)
      //   .style('font-family', 'roboto').style('font-size', style.bartextSize).style('fill', style.barTextColor).style('text-anchor', 'end').attr('alignment-baseline', 'middle')
      //   .text(d.name)

      //   blockstatus
      //   .append('rect')
      //   .attr('transform', `translate(${style.barX}, ${drawY - (style.barHeight/2) - .5})`)
      //   .style('width', style.barLength).style('height', style.barHeight).style('fill', '#F4F5F5').attr('opacity', 1)
        
      //   blockstatus
      //   .append('rect')
      //   .attr('transform', `translate(${style.barX}, ${drawY - (style.barHeight/2) - .5})`)
      //   .style('width', actualLen).style('height', style.barHeight).style('fill', d.bColor).attr('opacity', d.opacity)

      //   blockstatus
      //   .append('text')
      //   .attr('transform', () => {
      //     if (actualLen > 20) return `translate(${style.barX + actualLen - 2}, ${drawY + 0.5})`; else return `translate(${style.barX + actualLen + 2}, ${drawY})`
      //   })
      //   .style('text-anchor', () => {
      //     if (actualLen > 20) return 'end'; else return 'start'
      //   })
      //   .style('fill', () => {
      //     if (actualLen > 20) return '#fff'; else return '#757575'
      //   })
      //   .style('font-family', 'roboto').style('font-size', style.bartextSize).attr('alignment-baseline', 'middle')
      //   .text(d.actual)

      //   blockstatus
      //   .append('text')
      //   .attr('transform', `translate(${style.barX + style.barLength + 2}, ${drawY})`)
      //   .style('font-family', 'roboto').style('font-size', 9).style('fill', '#757575').style('text-anchor', 'start').attr('alignment-baseline', 'middle').text(d.total)

      //   drawY += style.barHeight + style.barSpace
      // })
    },
    Bar_Quantity_04(svg, _data, _style, _id) {
      let style = {
        headY: 0, 
        headTextSize: 20,
        headTextColor: '#000',
        headTitle: null,
        headTitle2: null,
        headDescrEQ3D: null,

        subY: 20, 
        subText: 'Status of Equipment',
        subTextColor: '#616161',
        subTextSize: 12,

        progDisplay: 'Y',
        deltaDisplay: 'Y',
        prog: null,
        proY: 20,
        progTextSize: 14,

        barX: 40,
        barY: 45,
        bartextSize: 10,
        barTextColor: '#757575',
        TextColor: '#212121',
        barLength: 90,
        barHeight: 10,
        barSpace: 4,
      }
      let _self = this
      style = { ...style, ..._style }
    
      let blockstatus = svg
      .append('g')
      .attr('transform', `translate(${style.x}, ${style.y})`)
    
      blockstatus
      .append('text')
      .attr('id', `Title_${_id}`)
      .attr('x', 0).attr('y', style.headY)
      .style('font-family', 'roboto')
      .style('font-size', style.headTextSize)
      .style('fill', style.headTextColor)
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(style.headTitle)

      blockstatus
      .append('text')
      .attr('id', `Title_${_id}`)
      .attr('x', 0).attr('y', style.headY-17)
      .style('font-family', 'roboto')
      .style('font-size', 11)
      .style('fill', '#0277BD')
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(style.headDescrEQ3D)
    
      blockstatus
      .append('text')
      .attr('x', 0).attr('y', style.subY)
      .style('font-family', 'roboto')
      .style('font-size', 14)
      .style('fill', style.subTextColor)
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(style.subText)
    
    
      let drawY = style.barY
    
      _data.forEach((d)=> {
        
        if(['PO','FAT','IRN','ON Site','Installed'].includes(d.name)){
          let actualLen = d.total ? (d.actual / d.total) * style.barLength : 0 //!d.total ? 0 : (d.actual / d.total) * style.barLength 분모가 0 이면 오류 생기기 때문에 분모가 0 이면 0으로!
    
        blockstatus
        .append('text')
        .attr('transform', `translate(${style.barX-5}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', style.bartextSize).style('fill', style.barTextColor).style('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text(d.name)
    
        blockstatus
        .append('rect')
        .attr('transform', `translate(${style.barX}, ${drawY - (style.barHeight/2) - .5})`)
        .style('width', style.barLength).style('height', style.barHeight).style('fill', '#F4F5F5').attr('opacity', 1)
        
        blockstatus
        .append('rect')
        .attr('transform', `translate(${style.barX}, ${drawY - (style.barHeight/2) - .5})`)
        .style('width', actualLen).style('height', style.barHeight).style('fill', d.bColor).attr('opacity', d.opacity)
    
        blockstatus
        .append('text')
        .attr('transform', () => {
          if (actualLen > 20) return `translate(${style.barX + actualLen - 2}, ${drawY })`; else return `translate(${style.barX + actualLen + 2}, ${drawY})`
        })
        .style('text-anchor', () => {
          if (actualLen > 20) return 'end'; else return 'start'
        })
        .style('fill', () => {
          if (actualLen > 20) return '#fff'; else return '#757575'
        })
        .style('font-family', 'roboto').style('font-size', style.bartextSize).attr('alignment-baseline', 'middle')
        .text(d.actual)
    
        blockstatus
        .append('text')
        .attr('transform', `translate(${style.barX + style.barLength + 2}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 9).style('fill', '#757575').style('text-anchor', 'start').attr('alignment-baseline', 'middle').text(d.total)
        
        } else {
          blockstatus
          .append('text')
          .attr('transform', `translate(${style.barX+88}, ${drawY+10})`)
          .style('font-family', 'roboto').style('font-size', style.bartextSize).style('fill', style.TextColor).style('text-anchor', 'end').attr('alignment-baseline', 'middle')
          .text(d.name)
    
          blockstatus
          .append('text')
          .attr('transform', `translate(${style.barX+93}, ${drawY+10})`)
          .style('font-family', 'roboto').style('font-size', style.bartextSize).style('fill', '#EC407A').style('text-anchor', 'start').attr('alignment-baseline', 'middle')
          .text(d.total)
          .style('cursor', 'pointer')
          .style('text-decoration', 'underline')  
          .on('mouseover', function(){ 
            d3.select(this).transition().duration(100)
            .style('fill', '#000').style('text-decoration', 'underline')  
          })
          .on('mouseout', function(){ 
            d3.select(this).transition().duration(100).style('fill', '#EC407A')
          })
          .on('click', () => {
            let request_ = {
              dataType: 'row',    
              action: {
                type: 'parent',     // direct | parent
                target: '',
                component: '',
                id: 176,
                no: ''
              },
              filters: { 
                ...this.FilterValues,
                ...this.inFilters,
                ...d.filter, //그래서 filter에 다담아서 보내기
                // STATUS: d.status, --이것만들어가니 각 LV별 해당 status가 나눠지지않음
              },
              iFilters: {}
            }
            this.$emit('request-action', request_)
          })
        }
        drawY += style.barHeight + style.barSpace
      })
    },
  }
}
