import * as d3 from 'd3'

export default {
  data: () => ({
    scaleY: null,
  }),

  methods: {
    draw_LineCharts() {

      if (this.skyline.line.display != 'Y') return

      this.scaleY = d3
          .scaleLinear()
          .domain([0, 25000])
          .range([this.skyline.minSize, this.timeline.baseY - this.skyline.line.height])

      this.lineZero = d3.line()
          .x(d => this.timeline.scale(d.eDate))
          .y(this.scaleY(0))

      this.lineFunc = d3.line()
          .x(d => this.timeline.scale(d.eDate))
          .y(d => this.scaleY(d.qty))


      let LineChart = this.SkylineCharts
      .append('g')
      .attr('transform', `translate(${this.timeline.baseX},0)`)



      // PLAN
      let ChartPlanLine = LineChart
      .append('g')
      .attr('id', 'LineChartPlan')
      .attr('transform', `translate(0, 0)`)

      ChartPlanLine
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .append('path')
      .attr('d', this.lineZero(this.timeline.plan))
      .style('stroke', '#bcbcbc').style('stroke-width', 1.5).attr('opacity', .5).style('fill', 'none')
      .transition()
      .duration(500)
      .attr('d', this.lineFunc(this.timeline.plan))

      ChartPlanLine
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .selectAll('circle')
      .data(this.timeline.plan)
      .enter()

      .append('circle')
      .attr('cx', d => this.timeline.scale(d.eDate))
      .attr('cy', d => this.scaleY(0))
      .attr('r', 3).style('stroke', '#bcbcbc').style('stroke-width', 1.5).attr('stroke-opacity', 0.5).style('fill', '#fff')
      .transition()
      .duration(500)
      .attr('cx', d => this.timeline.scale(d.eDate))
      .attr('cy', d => this.scaleY(d.qty))

      ChartPlanLine
      .append('g')
      .attr('id', 'ChartPlanValueGroup')
      .attr('transform', `translate(0, 0)`)
      .selectAll('ChartPlanValue')
      .data(this.timeline.plan)
      .enter()

      .append('text')
      .attr('id', 'ChartPlanValue')
      .attr('x', d => this.timeline.scale(d.eDate))
      .attr('y', d => this.scaleY(0))
      .style('font-family', 'roboto').style('font-size', 8.5).style('fill', '#757575').attr('text-anchor', 'middle')
      .text(d => d.qty)
      .transition()
      .duration(500)
      .attr('x', d => this.timeline.scale(d.eDate))
      .attr('y', d => this.scaleY(d.qty)-7)


      // Actual
      LineChart
      let ChartActualLine = LineChart
      .append('g')
      .attr('id', 'LineChartActual')
      .attr('transform', `translate(0, 0)`)

      ChartActualLine
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .append('path')
      .attr('d', this.lineZero(this.timeline.actual))
      .style('stroke', '#FF75A5').style('stroke-width', 1.5).attr('opacity', .5).style('fill', 'none')
      .transition()
      .duration(500)
      .attr('d', this.lineFunc(this.timeline.actual))

      ChartActualLine
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .selectAll('circle')
      .data(this.timeline.actual)
      .enter()

      .append('circle')
      .attr('cx', d => this.timeline.scale(d.eDate))
      .attr('cy', d => this.scaleY(0))
      .attr('r', 3)
      .style('stroke', '#FF75A5').style('stroke-width', 1.5).attr('stroke-opacity', 0.5).style('fill', '#fff')
      .transition()
      .duration(500)
      .attr('cx', d => this.timeline.scale(d.eDate))
      .attr('cy', d => this.scaleY(d.qty))

      ChartActualLine
      .append('g')
      .attr('id', 'ChartActualValueGroup')
      .attr('transform', `translate(0, 0)`)
      .selectAll('valuePlan')
      .data(this.timeline.actual)
      .enter()

      .append('text')
      .attr('id', 'ChartActualValue')
      .attr('x', d => this.timeline.scale(d.eDate))
      .attr('y', d => this.scaleY(0))
      .style('font-family', 'roboto').style('font-size', 8.5).style('fill', '#EC407A').attr('text-anchor', 'middle')
      .text(d => d.qty)
      .transition()
      .duration(500)
      .attr('x', d => this.timeline.scale(d.eDate))
      .attr('y', d => this.scaleY(d.qty)-7)
    }, 

   

  }
}
