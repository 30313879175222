import * as d3 from 'd3'
import _ColorData from '../../../../../../includes/primitives/Color_Data'
import Filter from '../../../../../../includes/primitives/Filter_Defs'
// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  methods: {
    Draw_Timeline() {
      let canvas = this.svg
      .append('g')
      .attr('transform', `translate(2,0)`)
      .attr('id','canvasFrame')

      canvas
      .append('rect')
      .attr('width', 1250).attr('height', 800).attr('stroke','#f5f5f5').attr('stroke-width',10).attr('fill','transparent')
      let Timeline = this.svg
      .append('g')
      .attr('transform', `translate(${this.timeline.baseX}, ${this.timeline.baseY})`)

      // Timeline Text using the scale function
      if (this.timeline.style.week.display == 'Y') {
        // Weeks Text & Timeline Seperator for the Week
        Timeline
        .append('g')
        .selectAll('text')
        .data(this.timeline.week)
        .enter()
        .append('text')
        .attr('transform', d => `translate(${this.timeline.scale(d.mDate)}, ${this.timeline.style.week.tSize})`)
        .style('font-family', this.timeline.font)
        .style('font-size', this.timeline.style.week.tSize)
        .attr('fill', this.timeline.style.week.tColor)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(d => d3.timeFormat('%d')(d.eDate))
      }
      if (this.timeline.style.month.display == 'Y') {
        // Month Text & Timeline Seperator for the Month
        Timeline
        .append('g')
        .selectAll('text')
        .data(this.timeline.month)
        .enter()
        .append('text')
        .attr('x', d => this.timeline.scale(d.mDate))
        .attr('y', this.timeline.style.week.height)
        .style('font-family', this.timeline.font)
        .style('font-size', this.timeline.style.month.tSize)
        .attr('fill', this.timeline.style.month.tColor)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'hanging')
        .text(d => d.name.toUpperCase())
      }
      // Year Text & Timeline Seperator for the Year
      if (this.timeline.style.year.display == 'Y') {
        Timeline
        .append('g')
        .selectAll('text')
        .data(this.timeline.year)
        .enter()
        .append('text')
        .attr('x', d => this.timeline.scale(d.mDate))
        .attr('y', this.timeline.style.week.height +  this.timeline.style.month.height)
        .style('font-family', this.timeline.font)
        .style('font-size', this.timeline.style.year.tSize)
        .attr('fill', this.timeline.style.year.tColor)
        .attr('font-weight', 500)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'hanging')
        .text(d => d.name)
      }
    },
    Draw_AxesGrid() {
      // let axes = this.svg
      // .append('g')
      // .attr('transform', `translate(${this.Canvas.CanvasChartX}, ${this.Canvas.CanvasChartY})`)

      // axes
      // .append('text') 
      // .attr('transform', `translate(-35, 175) rotate(-90)`).style('font-family', 'roboto').attr('font-size', 10).attr('text-anchor','middle').attr('fill','#757575').text('Cumulative')
      // axes
      // .append('text') 
      // .attr('transform', `translate(1030, 170) rotate(-90)`).style('font-family', 'roboto').attr('font-size', 10).attr('text-anchor','middle').attr('fill','#757575').text('Weekly')

      let chartArea = this.svg
      .append('g')
      .attr('id', 'chart_area')
      .attr('transform', `translate(${this.Canvas.CanvasChartX}, ${this.Canvas.CanvasChartY})`)
      
      chartArea // Axis X
      .append('line')
      .attr('x1', 0)
      .attr('x2', this.axisXWidth)
      .attr('y1', this.axisYHeight)
      .attr('y2', this.axisYHeight)
      .style('stroke', '#333')
      .style('stroke-width', .5)
              

      // draw 0 and maxBounds on Axis Y1
      let gridY = 0.1 //line이 얇게 보이기 위해 0.1을 넣었음 ????이상함
      let textGapY = 3
      let value1 = this.dataMaxValue1
      let value2 = this.dataMaxValue2

      for(var i=0; i<6; i++) {
        chartArea
        .append('text') // Axis 1
        .attr('x', -textGapY).attr('y',gridY).attr('font-size', 9).attr('text-anchor','end').attr('alignment-baseline','middle').attr('fill','#bcbcbc')
        .text(value1.toLocaleString())

        chartArea
        .append('text') // Axis 2
        .attr('x', this.axisXWidth + textGapY).attr('y', gridY).attr('font-size', 9).attr('text-anchor','start').attr('alignment-baseline','middle').attr('fill','#bcbcbc')
        .text(value2.toLocaleString())

        if(i < 6) {
          chartArea
          .append('line')
          .style('stroke', '#000').style('stroke-width', 0.11).attr('x1', 0).attr('x2', this.axisXWidth).attr('y1', gridY).attr('y2', gridY)
        }

        gridY += (this.axisYHeight/5)
        value1 -= (this.dataMaxValue1/5)
        value2 -= (this.dataMaxValue2/5)
      }
    },
    Draw_BarLines() {
      // legend
      let legend = this.svg
      .append('g')
      .attr('transform', `translate(${this.Canvas.CanvasChartX+250}, ${this.Canvas.CanvasChartY-35})`)
      legend
      .append('rect')
      .attr('transform', `translate(0, 5)`)
      .attr('width', 8).attr('height', 8).attr('fill','#B4E3FA')
      legend
      .append('text') 
      .attr('transform', `translate(13, 10)`).attr('font-size', 9).attr('text-anchor','start').attr('alignment-baseline','middle').attr('fill','#757575')
      .text('Submitted by Weekly')

      legend
      .append('rect')
      .attr('transform', `translate(115, 5)`)
      .attr('width', 8).attr('height', 8).attr('fill','#CDDC37')
      legend
      .append('text') 
      .attr('transform', `translate(128, 10)`).attr('font-size', 9).attr('text-anchor','start').attr('alignment-baseline','middle').attr('fill','#757575')
      .text('Approved by Weekly')

      legend
      .append('line')
      .attr('x1', 225).attr('x2', 240).attr('y1', 10).attr('y2', 10).attr('stroke-width', 1).attr('stroke', '#44A9DF')
      legend
      .append('circle')
      .attr('cx', 232).attr('cy', 10).attr('r', 3)
      .attr('fill', '#fff').attr('stroke-width', 1).attr('stroke', '#44A9DF')
      legend
      .append('text') 
      .attr('transform', `translate(245, 10)`).attr('font-size', 9).attr('text-anchor','start').attr('alignment-baseline','middle').attr('fill','#757575')
      .text(`Cumulative Submitted`)

      legend
      .append('line')
      .attr('x1', 355).attr('x2', 370).attr('y1', 10).attr('y2', 10).attr('stroke-width', 1).attr('stroke', '#ACAE4E')
      legend
      .append('circle')
      .attr('cx', 362).attr('cy', 10).attr('r', 3)
      .attr('fill', '#fff').attr('stroke-width', 1).attr('stroke', '#ACAE4E')
      legend
      .append('text') 
      .attr('transform', `translate(375, 10)`).attr('font-size', 9).attr('text-anchor','start').attr('alignment-baseline','middle').attr('fill','#757575')
      .text(`Cumulative Approved`)

      let chartArea = this.svg
      .append('g')
      .attr('transform', `translate(${this.Canvas.CanvasChartX-6}, ${this.Canvas.CanvasChartY})`)

      // bar-chart
      chartArea
      .append('g')
      .attr('transform', `translate(0, -0.2)`)
      .selectAll()
      .data(this.tickValuesWKS)
      .enter()
      .append('line')
      .attr('stroke', '#B4E3FA')
      .attr('stroke-width', 10).attr('opacity', 1)
      .attr('x1', d => this.timeline.scale(d.date))
      .attr('x2', d => this.timeline.scale(d.date))
      .attr('y1', this.scaleY2(0))
      .attr('y2', this.scaleY2(0))
      .transition()
      .duration(500)
      .attr('y1', this.scaleY2(0))
      .attr('y2', d => this.scaleY2(d.week))

      // bar-chart values
      chartArea
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .selectAll()
      .data(this.tickValuesWKS)
      .enter()
      .append('text')
      .attr('transform', d => `translate(${this.timeline.scale(d.date)}, ${this.scaleY2(0)})`)
      .attr('font-size', 9)
      .attr('text-anchor','middle')
      .attr('alignment-baseline','start')
      .attr('fill','#757575')
      .text(d => d.week.toLocaleString() > 0 ? d.week.toLocaleString() : '')
      .transition()
      .duration(500)
      .attr('transform', d => `translate(${this.timeline.scale(d.date)}, ${this.scaleY2(d.week)-3})`)

      // line-chart
      chartArea
      .append('g')
      .attr('transform', `translate(6, 0)`)
      .append('path')
      .attr('d', this.lineZero(this.tickValuesCumS))
      .style('stroke', '#44A9DF').style('stroke-width', 1.5).attr('opacity', .7)
      .style('fill', 'none')
      .transition()
      .duration(500)
      .attr('d', this.lineFunc(this.tickValuesCumS))

      // line-chart markup
      chartArea
      .append('g')
      .attr('transform', `translate(6, 0)`)
      .selectAll()
      .data(this.tickValuesCumS)
      .enter()
      .append('circle')
      .attr('fill', '#fff')
      .attr('stroke', '#44A9DF')
      .attr('stroke-width', 1)
      .attr('cx', d => this.timeline.scale(d.date))
      .attr('cy', this.axisYHeight)
      .attr('r', 3)
      .transition()
      .duration(500)
      .attr('cy', d => this.scaleY1(d.cum))
      

      // Draw the average (Value / Note / Line)
      chartArea
      .append('g')
      .attr('transform', `translate(6, 0)`)
      .selectAll()
      .data(this.tickValuesCumS)
      .enter()
      .append('text')
      .attr('transform', d => `translate(${this.timeline.scale(d.date)}, ${this.scaleY1(0)})`)
      .attr('font-size', 9)
      .attr('text-anchor','middle')
      .attr('alignment-baseline','start')
      .attr('fill','#44A9DF')
      .text(d => d.cum.toLocaleString() > 0 ? d.cum.toLocaleString() : '')
      .transition()
      .duration(500)
      .attr('transform', d => `translate(${this.timeline.scale(d.date)}, ${this.scaleY1(d.cum)-8})`)

      //otherChartArea Bar Chart
      let otherChartArea = this.svg
      .append('g')
      .attr('transform', `translate(${this.Canvas.CanvasChartX+6}, ${this.Canvas.CanvasChartY})`)

      // bar-chart
      otherChartArea
      .append('g')
      .attr('transform', `translate(0, -0.2)`)
      .selectAll()
      .data(this.tickValuesWKA)
      .enter()
      .append('line')
      .attr('stroke', '#CDDC37')
      .attr('stroke-width', 10).attr('opacity', 1)
      .attr('x1', d => this.timeline.scale(d.date))
      .attr('x2', d => this.timeline.scale(d.date))
      .attr('y1', this.scaleY2(0))
      .attr('y2', this.scaleY2(0))
      .transition()
      .duration(500)
      .attr('y1', this.scaleY2(0))
      .attr('y2', d => this.scaleY2(d.week))

      // bar-chart values
      otherChartArea
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .selectAll()
      .data(this.tickValuesWKA)
      .enter()
      .append('text')
      .attr('transform', d => `translate(${this.timeline.scale(d.date)}, ${this.scaleY2(0)})`)
      .attr('font-size', 9)
      .attr('text-anchor','middle')
      .attr('alignment-baseline','start')
      .attr('fill','#757575')
      .text(d => d.week.toLocaleString() > 0 ? d.week.toLocaleString() : '')
      .transition()
      .duration(500)
      .attr('transform', d => `translate(${this.timeline.scale(d.date)}, ${this.scaleY2(d.week)-2})`)

      // line-chart
      otherChartArea
      .append('g')
      .attr('transform', `translate(-6, 0)`)
      .append('path')
      .attr('d', this.lineZero(this.tickValuesCumA))
      .style('stroke', '#ACAE4E').style('stroke-width', 1.5).attr('opacity', .7)
      .style('fill', 'none')
      .transition()
      .duration(500)
      .attr('d', this.lineFunc(this.tickValuesCumA))

      // line-chart markup
      otherChartArea
      .append('g')
      .attr('transform', `translate(-6, 0)`)
      .selectAll()
      .data(this.tickValuesCumA)
      .enter()
      .append('circle')
      .attr('fill', '#fff')
      .attr('stroke', '#ACAE4E')
      .attr('stroke-width', 1)
      .attr('cx', d => this.timeline.scale(d.date))
      .attr('cy', this.axisYHeight)
      .attr('r', 3)
      .transition()
      .duration(500)
      .attr('cy', d => this.scaleY1(d.cum))
      

      // Draw the average (Value / Note / Line)
      otherChartArea
      .append('g')
      .attr('transform', `translate(-6, 0)`)
      .selectAll()
      .data(this.tickValuesCumA)
      .enter()
      .append('text')
      .attr('transform', d => `translate(${this.timeline.scale(d.date)}, ${this.scaleY1(0)})`)
      .attr('font-size', 9)
      .attr('text-anchor','middle')
      .attr('alignment-baseline','start')
      .attr('fill','#9E9D24')
      .text(d => d.cum.toLocaleString() > 0 ? d.cum.toLocaleString() : '')
      .transition()
      .duration(500)
      .attr('transform', d => `translate(${this.timeline.scale(d.date)}, ${this.scaleY1(d.cum)-8})`)
    },
    Draw_Charts() {
      let sChartITR = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0.5)`)

      if (!this.dataSet) {
        sChartITR
        .append('text')
        .attr('x', 150)
        .attr('y', 120).style('font-size',16)
        .style('font-family','roboto')
        .attr('text-anchor', 'middle')
        .attr('fill', '#bcbcbc')
        .text('Not Applicable' )
        return 
      }

      // SummaryTable_01
      this.SummaryTable_04(sChartITR, this.style) // ---------------------------> 
    },
    drawPieChart() {
      let pieWrap = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0.5)`)
      .attr('class', 'piewrap')

      pieWrap
      .append('text')
      .attr('transform', `translate(1000, 100)`)
      .style('font-family', 'roboto').style('font-size', 12).style('fill', '#424242').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text('Category')


      this.get_CoordinatorsXY(this.Circle.CircleX, this.Circle.CircleY, -this.Guideline.GuideInside, 'inX', 'inY')
      this.get_CoordinatorsXY(this.Circle.CircleX, this.Circle.CircleY, this.Guideline.GuideOutside, 'outX', 'outY')
      this.get_PieChartData()

      Filter.setDefs_DropShadow(
        pieWrap, 
        `${this.localId}__DropShadow`, 
        this.Circle.CircleDropshadowDeviation , 
        this.Circle.CircleDropshadowOpacity , 
        this.Circle.CircleDropshadowX, 
        this.Circle.CircleDropshadowY
      )
      var self_ = this
      if (this.Circle.CircleShadowDisplay == 'Y') {
        pieWrap // Shadow
        .append('ellipse')
        .attr('cx', this.Circle.CircleX)
        .attr('cy', this.Circle.CircleY + this.Circle.CircleDiameter)
        .attr('rx', this.Circle.CircleDiameter)
        .attr('ry', this.Circle.CircleDiameter * 0.1)
        .attr('fill', `url(#${this.localId}__Shadow)`)
      }
      
      var arc = d3.arc()
        .outerRadius(d => d.data.stack)
        .innerRadius(this.Circle.CircleHoleSize)
        .cornerRadius(this.Circle.CircleRadius) // Coner Radius

      var pie = d3.pie()
        .sort(null)
        .value((d) => d.value)
        .padAngle(this.Circle.CircleSliceSize/100) // Padding Pie

      let donutGroup = pieWrap
        .append('g')
        .attr('transform', `translate(${this.Circle.CircleX},${this.Circle.CircleY})`)
        
        donutGroup
        .selectAll('path')
        .data(pie(this.pieSectors))
        .enter()
        .append('path')
        .attr('d', arc)
        .attr('fill', (d, i) =>  this.SharedColorSet[i])
        // .attr('fill', (d, i) => Object.keys(_ColorData.setGradients.Length).includes(this.Circle.CircleColorType) ? `url(#${self_.localId}__${this.Circle.CircleColorType}${i})` : this.SharedColorSet[i])
        .style('filter', function() {
          if (self_.Circle.CircleDropshadowDisplay == 'Y' ) return `url(#${self_.localId}__DropShadow)`;
          })
        .transition()
        .duration(750)
        .attrTween('d', function(d) {
            var interpolate = d3.interpolate({startAngle: 0, endAngle: 0}, d);
            return function(t) {
                return arc(interpolate(t));
            };
          });

      // pieWrap
      //   .append('g')
      //   .selectAll('path')
      //   .data(this.pieSectors)
      //   .enter()
      //   .append('path')
      //   .attr('fill', (_, i) => this.SharedColorSet[i])
      //   .attr('d', (_, i) => `
      //     M ${this.Circle.CircleX}, ${this.Circle.CircleY - this.pieSectors[i].h_Ra} 
      //     L ${this.Circle.CircleX}, ${this.Circle.CircleY - this.pieSectors[i].Ra} 
      //     A ${this.pieSectors[i].Ra}, ${this.pieSectors[i].Ra} 
      //       0 ${this.pieSectors[i].arcSweep}, 1 
      //       ${this.Circle.CircleX - this.pieSectors[i].Ra + this.pieSectors[i].endX}, ${this.Circle.CircleY - this.pieSectors[i].Ra + this.pieSectors[i].endY} 
      //     L ${this.Circle.CircleX - this.pieSectors[i].h_Ra + this.pieSectors[i].h_endX}, ${this.Circle.CircleY - this.pieSectors[i].h_Ra + this.pieSectors[i].h_endY} 
      //     A ${this.pieSectors[i].h_Ra}, ${this.pieSectors[i].h_Ra} 
      //       0 ${this.pieSectors[i].arcSweep}, 0 
      //         ${this.Circle.CircleX}, ${this.Circle.CircleY - this.pieSectors[i].h_Ra}
      //   `)
      //   .attr('stroke', '#ffffff')
      //   .attr('fill', (d, i) => `url(#${this.localId}__LinearA2${i})`)
      //   .attr('stroke-width', this.Circle.CircleSliceSize)
      //   .attr('transform', (d, i) => `rotate(${this.pieSectors[i].Ro},${this.Circle.CircleX},${this.Circle.CircleY})`)

    },

    // --------------------------------------------------------------------------------------------------------------------------------------------
    drawGuidline() {
      let filterPieSectors = JSON.parse(JSON.stringify(this.pieSectors)).slice(0, 4)
      //this.pieSectors 를 filterPieSectors으로 만듬. 필터연동될때마다 차트가 새로 그려져서 원래 데이터 4개를 두고 나머지 데이터 추가되는것을 삭제 시킴
      let pieWrapLine = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0.5)`)
      .attr('class', 'pieWrapLine')


      if (this.Guideline.GuideDisplay != 'Y') return
      var self_ = this
      pieWrapLine
        .append('g')
        .selectAll('line') // Create Line from inside to outside
        .data(filterPieSectors)
        .enter()
        .append('line')
        .attr('x1', (d, i) => filterPieSectors[i].inX)
        .attr('y1', (d, i) => filterPieSectors[i].inY)
        .attr('x2', (d, i) => filterPieSectors[i].outX)
        .attr('y2', (d, i) => filterPieSectors[i].outY)
        .attr('stroke', this.Guideline.GuideColor)
        .attr('stroke-width', this.Guideline.GuideWeight)
        
      pieWrapLine
        .append('g')
        .selectAll('line') // Create Line from outside to bullet
        .data(filterPieSectors)
        .enter()
        .append('line')
        .attr('x1', (d, i) => filterPieSectors[i].outX)
        .attr('y1', (d, i) => filterPieSectors[i].outY)
        .attr('x2', (d, i) => filterPieSectors[i].outX + filterPieSectors[i].direct)
        .attr('y2', (d, i) => {
          // console.log(this.pieCenterAngles[i])
          return filterPieSectors[i].outY
        })
        .attr('stroke', this.Guideline.GuideColor)
        .attr('stroke-width', this.Guideline.GuideWeight)

      pieWrapLine
        .append('g')
        .selectAll('circle') // Create bullets
        .data(filterPieSectors)
        .enter()
        .append('circle')
        // .attr('fill', (d, i) => `url(#${this.localId}__LinearA2${i})`)
        .style('fill', this.Guideline.GuideBulletAutoColor == 'Y' ? (d, i) => `url(#${this.localId}__LinearA2${i})` : this.Guideline.GuideBulletColor)
        .attr('cx', (d, i) => filterPieSectors[i].outX + filterPieSectors[i].direct)
        .attr('cy', (d, i) => filterPieSectors[i].outY)
        .attr('r', this.Guideline.GuideBulletSize)

      let SeriesNameGroup = pieWrapLine
        .append('g')
        .selectAll('g')
        .data(filterPieSectors)
        .enter()
        .append('g')
        .attr('id', (_, i) => `series_group_${i}`)
        .attr(
          'transform',
          (d, i) => `translate(
            ${filterPieSectors[i].outX + filterPieSectors[i].direct + filterPieSectors[i].textSpace},
            ${filterPieSectors[i].outY}
            )`
          )
      SeriesNameGroup.append('text') // title
        .attr('id', (_, i) => `series_title_${i}`)
        .attr('class', this.Guideline.GuideTextStyle)
        .style('font-size', this.Guideline.GuideTextSize)
        .style('font-family', this.Guideline.GuideTextFont)
        .attr('fill', this.Guideline.GuideTextColor)
        // .attr('text-anchor', (d,i) =>filterPieSectors[i].align)
        .attr('alignment-baseline', 'middle')
        .text((d, i) => filterPieSectors[i].title)

      SeriesNameGroup.append('text') // value
        .attr('id', (_, i) => `series_value_${i}`)
        .attr('class', this.Guideline.GuideTextStyle)
        .style('font-size', this.Guideline.GuideValueSize)
        .style('font-family', this.Guideline.GuideTextFont)
        .attr('fill', this.Guideline.GuideValueColor)
        // .attr('text-anchor', (d,i) =>filterPieSectors[i].align)
        .attr('alignment-baseline', 'middle')
        .text((d, i) => filterPieSectors[i].value.toFixed(this.Guideline.GuideValueRound))

      SeriesNameGroup.append('text') // unit
        .attr('id', (_, i) => `series_unit_${i}`)
        .attr('class', this.Guideline.GuideTextStyle)
        .style('font-size', this.Guideline.GuideUnitSize)
        .style('font-family', this.Guideline.GuideTextFont)
        .attr('fill', this.Guideline.GuideUnitColor)
        // .attr('text-anchor', (d,i) =>filterPieSectors[i].align)
        .attr('alignment-baseline', 'middle')
        .text(this.Guideline.GuideUnitFormat)

      filterPieSectors.forEach((item, i) => {
        let titleWidth = this.getNodeElValue(`#series_title_${i}`, 'width')
        let valueWidth = this.getNodeElValue(`#series_value_${i}`, 'width')

        pieWrapLine.select(`#series_value_${i}`).attr('transform', `translate(${titleWidth + 1},0)`)
        pieWrapLine.select(`#series_unit_${i}`).attr('transform', `translate(${titleWidth + 1 + valueWidth + 1},0)`)

        if (this.pieCenterAngles[i] > 0.5) {
          let gWidth = this.getNodeElValue(`#series_group_${i}`, 'width')
          pieWrapLine.select(`#series_group_${i}`).attr('transform', `translate(${filterPieSectors[i].outX + filterPieSectors[i].direct + this.pieSectors[i].textSpace - gWidth},${this.pieSectors[i].outY})`)
        }
      })
    }
  }
}
