export default {
  watch: {
    ChartType(typeName) { if(!typeName) return; this.register(this.draw); },
    Canvas: { handler(val) { if(!val || Object.keys(val).length === 0) return; this.register(this.draw); }, deep: true },
    Text: { handler(val) { if(!val || Object.keys(val).length === 0) return; this.register(this.draw); }, deep: true },
    Status: { handler(val) { if(!val || this.Status.length === 0) return; this.register(this.draw); }, deep: true },
    Border: { handler(val) { if(!val || this.Border.length === 0) return; this.register(this.draw); }, deep: true },
    ChartData: { handler(val) { if(!val || val.length === 0) return; this.register(this.draw); }, deep: true },
    DataItems: { handler(val) { if(!val || val.length === 0) return; this.register(this.draw); }, deep: true },
  },
}
