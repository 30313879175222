import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {  
    Draw_Charts() {   
      let data = null, 
      headerStyle = null,
      summaryStyle = null,
      barStyle = null,
          cellX = null, 
          tmp = null, 
          Circle = null, 
          Guideline = null,
          item = null

      let overallPunch = this.svg
      .append('g')
      .attr('transform', `translate(0, 0)`)

      let categories = [...new Set(this.dataSet.map(d => d.CATEGORY))]

      let chartY = 0

      headerStyle = {
          x: 10,
          y: 7,
          width: 68,
          height: 20,
          radius: 3,
          tSize: 12,
          tColor: '#333',
          bColor: '#E0E0DF',
          progSize: 15,
        }
      
      
      // barStyle = {
      //   id:       'Punch',
      //   x:        0,
      //   y:        70,
      //   cell:     [30, 65, 100, 105, 0, 0],
      //   bColor:   '#F7BACF', 
      //   sColor:   '#EC407A', 
      //   tColor1:  '#fff', 
      //   tColor2:  '#757575', 
      //   opacity:  1,
      //   length:   75,
      //   lineHeight: 16,
      //   min: 25,
      // }

      categories.forEach((category, cIdx) => {
        let prog = this.dataSet.find(f=> f.CATEGORY == category && f.DISC == 'Overall').PROG
        let ovSummary = this.dataSet.find(f=> f.CATEGORY == category && f.DISC == 'Overall')
        // let list = this.dataSet.filter(f=> f.CATEGORY == category && f.DISC !== 'Overall')

        summaryStyle = {
          id:       'Punch',
          title:    ['Total', 'Closed', 'Open'],
          cell:     [45, 95, 145],
          x:        10,
          y:        30,
          y1:       10,
          y2:       25,
          width:    155,
          height:   34,
          radius:   3,
          opacity:  0.7,
          bColor:   '#83D2F5',
          tSize:    11,
          tColor:   '#757575',
          vSize:    11,
          vColor:   '#fff',
          value:    [ovSummary.TOTAL, ovSummary.ACTUAL, ovSummary.REMAIN]
        }

        
        headerStyle.title = 'Punch ' + category

        let group = overallPunch.append('g').attr('class', `group_${category}_`).attr('transform', `translate(0, ${chartY})`)


        this.Header_01( group, prog, headerStyle)
        this.drawSummaryTable_Punch(group, summaryStyle, ovSummary)
        // this.drawBarchart(group, list, barStyle)

        // chartY += ((list.length + 1)  * 20) + 100
        chartY += 100
      })


    },
    drawSummaryTable_Punch(selection, d, _data) {
      let _self = this
      let summaryTable = selection
      .append('g')
      .attr('id', '')
      .attr('transform', `translate(${d.x}, ${d.y})`)
      
      summaryTable
      .append('rect')
      .attr('transform', `translate(0, 0)`)
      .style('width', d.width).style('height', d.height).style('fill', d.bColor).attr('rx', d.radius).attr('ry', d.radius).style('opacity', d.opacity)

      summaryTable
      .append('text') 
      .attr('transform', `translate(${d.cell[0]}, ${d.y1})`)
      .style('font-family', 'roboto').style('font-size', d.tSize)
      .style('fill', d.tColor).style('text-anchor', 'end').style('alignment-baseline', 'middle').text(d.title[0])

      summaryTable
      .append('text') 
      .attr('transform', `translate(${d.cell[1]}, ${d.y1})`)
      .style('font-family', 'roboto').style('font-size', d.tSize)
      .style('fill', d.tColor).style('text-anchor', 'end').style('alignment-baseline', 'middle').text(d.title[1])

      summaryTable
      .append('text') 
      .attr('transform', `translate(${d.cell[2]}, ${d.y1})`)
      .style('font-family', 'roboto').style('font-size', d.tSize)
      .style('fill', d.tColor).style('text-anchor', 'end').style('alignment-baseline', 'middle').text(d.title[2])

      summaryTable // Total
      .append('text')
      .attr('id', `${d.id}_Total}`)
      .attr('transform', `translate(${d.cell[0]}, ${d.y2+2})`)
      .style('font-family', 'roboto').style('font-size', d.vSize)
      .style('fill', d.vColor).style('text-anchor', 'end').style('alignment-baseline', 'middle')
      .text(d.value[0])
      .style('cursor', 'pointer')
      .on('mouseover', function() {
        let node = _self.getNodeElValue(`#${d.id}_Total`,'width') / 5.5
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(${d.cell[0] + node}, ${d.y2}) scale(1.3)`).style('fill', '#EC407A')
      })
      .on('mouseout', function(){
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(${d.cell[0]}, ${d.y2}) scale(1)`).style('fill', d.vColor)
      })
      .on('click', () => {
        let punch = {CATEGORY : _data.CATEGORY}
        
        let request_ = this.getRequestColumnProps('punch', punch, this.ColumnProps)
        this.$emit('request-action', request_)
      })
      
      summaryTable // Actual
      .append('text')
      .attr('id', `${d.id}_Actual`)
      .attr('transform', `translate(${d.cell[1]}, ${d.y2+2})`)
      .style('font-family', 'roboto').style('font-size', d.vSize)
      .style('fill', d.vColor).style('text-anchor', 'end').style('alignment-baseline', 'middle')
      .text(d.value[1])
      .style('cursor', 'pointer')
      .on('mouseover', function() {
        let node = _self.getNodeElValue(`#${d.id}_Actual`,'width') / 5.5
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(${d.cell[1] + node}, ${d.y2}) scale(1.3)`).style('fill', '#EC407A')
      })
      .on('mouseout', function(){
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(${d.cell[1]}, ${d.y2}) scale(1)`).style('fill', d.vColor)
      })
      .on('click', () => { 
        let punch = {CATEGORY : _data.CATEGORY, STATUS_CODE:'C'}
        let request_ = this.getRequestColumnProps('punch', punch, this.ColumnProps)
        this.$emit('request-action', request_)
      })

      summaryTable // Remain
      .append('text')
      .attr('id', `${d.id}_Remain`)
      .attr('transform', `translate(${d.cell[2]}, ${d.y2+2})`)
      .style('font-family', 'roboto').style('font-size', d.vSize)
      .style('fill', d.vColor).style('text-anchor', 'end').style('alignment-baseline', 'middle')
      .text(d.value[2])
      .style('cursor', 'pointer')
      .on('mouseover', function() {
        let node = _self.getNodeElValue(`#${d.id}_Remain`,'width') / 5.5
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(${d.cell[2] + node}, ${d.y2}) scale(1.3)`).style('fill', '#EC407A')
      })
      .on('mouseout', function(){
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(${d.cell[2]}, ${d.y2}) scale(1)`).style('fill', d.vColor)
      })
      .on('click', () => { 
        let punch = {CATEGORY : _data.CATEGORY, STATUS_CODE:'N'}
        if (this.FilterValues && this.FilterValues.SUBSYSTEM) punch.SUBSYSTEM  = this.FilterValues.SUBSYSTEM
        let request_ = this.getRequestColumnProps('punch', punch, this.ColumnProps)
        this.$emit('request-action', request_)
      })
    },
    drawBarchart(selection, _data, _style) {
      let _self = this

      _style.cell[4] =  _style.cell[3] + (_style.length/2)
      _style.cell[5] =  _style.cell[3] + (_style.length)

      let maxValue = Math.max(..._data.map(d => d.TOTAL))
      maxValue = this.getMaxBound(maxValue)

      let gridLength = ((_data.length+1) * _style.lineHeight) + _style.lineHeight/2

      //Scale for the radius
      let barScale = d3.scaleLinear()
      .domain([0, maxValue])
      .range([0, _style.length])

      _data.forEach(data => {
        data.lenTot = _style.length * (data.ACTUAL/data.TOTAL)
        data.lenAct = _style.length * (data.ACTUAL/data.TOTAL)
      })

      let discPunch = selection
      .append('g')
      .attr('transform', `translate(${_style.x}, ${_style.y})`)

      discPunch
      .append('text')
      .attr('x', _style.cell[1]).attr('y', 10)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#333').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
      .text('Total')

      discPunch
      .append('text')
      .attr('x', _style.cell[2]).attr('y', 10)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#333').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
      .text('Closed')

      discPunch
      .append('text')
      .attr('x', _style.cell[3]).attr('y', 10)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#333').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Open')


      discPunch
      .append('text')
      .attr('x', _style.cell[3]).attr('y', 25)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#bcbcbc').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text('0')

      discPunch
      .append('text')
      .attr('x', _style.cell[4]).attr('y', 25)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#bcbcbc').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(maxValue/2)

      discPunch
      .append('text')
      .attr('x', _style.cell[5]).attr('y', 25)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#bcbcbc').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(maxValue)

      discPunch
      .append('line')
      .attr('x1', _style.cell[3]).attr('y1', 30).attr('x2', _style.cell[3]).attr('y2', gridLength).style('stroke', '#bcbcbc').style('stroke-width', 0.3)
      discPunch
      .append('line')
      .attr('x1', _style.cell[4]).attr('y1', 30).attr('x2', _style.cell[4]).attr('y2', gridLength).style('stroke', '#bcbcbc').style('stroke-width', 0.3)
      discPunch
      .append('line')
      .attr('x1', _style.cell[5]).attr('y1', 30).attr('x2', _style.cell[5]).attr('y2', gridLength).style('stroke', '#bcbcbc').style('stroke-width', 0.3)
      
      let drawY = 40
      _data.forEach((d,i) => {
        d.y = drawY

        discPunch
        .append('text')
        .attr('x', _style.cell[0]).attr('y', drawY)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#333').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text(d.DISC)

        discPunch
        .append('text')
        .attr('id', `${_style.id}_TOT_${i}`)
        .attr('transform', `translate(${_style.cell[1]}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text(d.TOTAL)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          let node = _self.getNodeElValue(`#${_style.id}_TOT_${i}`, 'width') / 5.5
          d3.select(this).transition().duration(100)
          .attr('transform', `translate(${_style.cell[1] + node}, ${d.y}) scale(1.3)`).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100)
          .attr('transform', `translate(${_style.cell[1]}, ${d.y}) scale(1)`).style('fill', '#757575')
        })
        .on('click', () => {
          let request = {CATEGORY : d.CATEGORY, DISC: d.DISC}
          console.log(request)
          let request_ = this.getRequestColumnProps('request', request, this.ColumnProps)
          this.$emit('request-action', request_)
        })


        discPunch
        .append('text')
        .attr('id', `${_style.id}_ACT_${i}`)
        .attr('transform', `translate(${_style.cell[2]}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text(d.ACTUAL)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          let node = _self.getNodeElValue(`#${_style.id}_ACT_${i}`, 'width') / 5.5
          d3.select(this).transition().duration(100)
          .attr('transform', `translate(${_style.cell[2] + node}, ${d.y}) scale(1.3)`).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100)
          .attr('transform', `translate(${_style.cell[2]}, ${d.y}) scale(1)`).style('fill', '#757575')
        })
        .on('click', () => { 
          let request = {CATEGORY : d.CATEGORY, STATUS_CODE: 'C', DISC: d.DISC}
          console.log(request)
          let request_ = this.getRequestColumnProps('request', request, this.ColumnProps)
          this.$emit('request-action', request_)
        })


        discPunch
        .append('rect')
        .attr('transform', `translate(${_style.cell[3]}, ${drawY - 7})`)
        .attr('width', barScale(d.TOTAL)).attr('height', 12)
        .attr('fill', '#F4F5F5')

        discPunch
        .append('rect')
        .attr('transform', `translate(${_style.cell[3]}, ${drawY - 7})`)
        .attr('width', 0).attr('height', 12)
        .attr('stroke-width', 0.3).attr('stroke', _style.sColor).attr('fill', _style.bColor).style('opacity', _style.opacity)
        .transition().duration(500).attr('width', barScale(d.ACTUAL))

        discPunch
        .append('text')
        .attr('id', `${_style.id}_REM_${i}`)
        .attr('transform', `translate(${_style.cell[3] + barScale(d.ACTUAL + 1)}, ${drawY})`)
        .attr('text-anchor', ()=> {
          if(barScale(d.ACTUAL) < _style.min) return 'start'; else return 'end'
        })
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#333').attr('alignment-baseline', 'middle')
        .text(()=> { if(d.TOTAL-d.ACTUAL > 0) return d.TOTAL-d.ACTUAL })

        .style('cursor', 'pointer')
        .on('mouseover', function() {
          let node = _self.getNodeElValue(`#${_style.id}_REM_${i}`, 'width') / 5.5
          d3.select(this).transition().duration(100)
          .attr('transform', ()=> {
            if(barScale(d.ACTUAL) < _style.min) return `translate(${_style.cell[3] + barScale(d.ACTUAL) - node}, ${d.y}) scale(1.3)`
            else return `translate(${_style.cell[3] + barScale(d.ACTUAL) + node}, ${d.y}) scale(1.3)`
          })
          .style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100)
          .attr('transform', `translate(${_style.cell[3] + barScale(d.ACTUAL)}, ${d.y}) scale(1)`).style('fill', '#333')
        })
        .on('click', () => { 
          let request = {CATEGORY : d.CATEGORY, STATUS_CODE: 'N', DISC: d.DISC}
          console.log(request)
          let request_ = this.getRequestColumnProps('request', request, this.ColumnProps)
          this.$emit('request-action', request_)
        })

        drawY += _style.lineHeight
      })

    },
  }
}