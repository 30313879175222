import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  data: () => ({
  }),

  
  methods: {

    Arrow_Phase(selection, _st, _len, _color, _height, _arrowSize, _opacity) {
      selection
      .append('path')
      .attr('d', `M${_st}, 0 
                  L${_st + _len - _arrowSize}, 0 
                  L${_st + _len}, ${_height/2}   
                  L${_st + _len - _arrowSize}, ${_height} 
                  L${_st}, ${_height} 
                  L${_st + _arrowSize}, ${_height/2} Z
      `)
      .attr('fill', _color).style('opacity', _opacity)
    },

    Arrow_PhaseTrans(selection, _st, _len, _color, _height, _arrowSize, _opacity, _duration) {
      selection
      .append('path')
      .attr('d', `M${_st}, 0 
                  L${_st - _arrowSize}, 0 
                  L${_st}, ${_height/2}   
                  L${_st - _arrowSize}, ${_height}   
                  L${_st}, ${_height}  
                  L${_st + _arrowSize}, ${_height/2} Z
      `)
      .attr('fill', _color).style('opacity', _opacity)
      .transition().duration(_duration)
      .attr('d', `M${_st}, 0 
                  L${_st + _len - _arrowSize}, 0 
                  L${_st + _len}, ${_height/2}  
                  L${_st + _len - _arrowSize}, ${_height}   
                  L${_st}, ${_height}  
                  L${_st + _arrowSize}, ${_height/2} Z
      `)
    },




  }
}
           