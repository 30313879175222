import * as d3 from 'd3'
// ------------------------------------------------------------------------------- Global
export default {
  data: () => ({
    items: [
      { code: 'Jetty' , color:'#F48FB1', stroke:'#E91E63',path: 'M 97 235 L 119 235 L 119 267 L 97 267 L 97 235 Z' },
      { code: 'Terminal' , color:'#F48FB1', stroke:'#E91E63',path: '67 204 L 89 205 L 89 223 L 68 221 L 66 206' },
      { code: 'Pipeline' , color:'#F48FB1', stroke:'#E91E63',path: '304 152 L 325 150 L 325 166 L 306 169 L 305 152' },
      { code: 'MBZ' , color:'#F48FB1', stroke:'#E91E63',path: '374 333 L 393 332 L 393 349 L 374 348 L 374 335' },
    ]
  }),
  methods: {
    Draw_Charts() {

      this.innerSvg = this.svg.append('svg')
      let Overall = this.innerSvg
      .append('g')
      .attr('id', '_OverallGroup').attr('transform', `translate(0.5, 0.5)`)

      Overall 
      .append('image')
      .attr('id', `_image_map`)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/China1/Images/Execution_off.png`)
      .attr('transform', `translate(0, 0)`)

      let packcage= this.DataItems.map(d=>d.PACKAGE)

      let pItem= this.items.filter(d=> d.code ==packcage)[0];
      //pItem.forEach(k => {
        let layer = Overall
        .append('g')
        .attr('id', `area__`)
        .attr('transform', `translate(0, 0)`)

        let layerPath = layer
        .append('g').attr('transform', `translate(0, 0)`)

        layerPath
        .append('path').attr('id', `Mask_`)
        .attr('d', pItem.path)
        .style('fill', pItem.stroke)
        .style('fill-opacity', .6).attr('stroke-width', 1.5).attr('stroke', pItem.color)
        .style('cursor', 'pointer')

        // this.pathes[k].items.forEach(item => {
        //   if(!this.DataItems[0][k].find(d => d.CODE == item.code)) return

        //   let layerPath = layer
        //   .append('g').attr('transform', `translate(0, 0)`)

        //   layerPath
        //   .append('path').attr('id', `Mask_${item.code}`)
        //   .attr('d', item.path)
        //   // .style('fill', this.pathes[k].bcolor)
        //   .style('fill', '#f4f4f4')
        //   .style('fill-opacity', .4).attr('stroke-width', 6).attr('stroke', this.pathes[k].lcolor)
        //   .style('cursor', 'pointer')
        //   .on('mouseover', (_, i, a) => {
        //     let el = d3.select(a[i])
        //     el.transition().style('fill-opacity', 0.8).style('fill', this.pathes[k].bcolor)
        //     d3.select(`.textBox_${item.code}`).transition().attr('font-size', 30).attr('fill', '#000')
        //   })
        //   .on('mouseout', (_, i, a) => {
        //     let el = d3.select(a[i])
        //     el.transition().style('fill-opacity', .4).style('fill', '#f4f4f4')
        //     d3.select(`.textBox_${item.code}`).transition().attr('font-size', 14).attr('fill', '#fff')
        //   })
        //   .on('click', () => {
        //     let dataSource = { BID_SECTION_PACK: this.DataItems[0][k].find(d => d.CODE == item.code).CODE ,JIANLI_SUPERVISION_WORK_PACKAGE: this.DataItems[0][k].find(d => d.CODE == item.code).CODE ,QUALITY_STATION_AREA: this.DataItems[0][k].find(d => d.CODE == item.code).CODE }
        //     let request_ = this.getRequestColumnProps(k, dataSource, this.ColumnProps)
        //     this.$emit('request-action', request_)
        //   })
        
      //})

        //.call(this.callFuncSearchFilter)



      // /**
      //  * Svg Path Tool to get the clicked mouse point (x, y)
      //  */
      // this.createPathReigon(this.innerSvg, {
      //   w: 1600,
      //   h: 1600,
      //   strokeWidth: 2,
      //   strokeColor: '#ff00ff'
      // })
      
    },
  }
}
