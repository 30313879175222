import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {

    Draw_Charts() {

      let data = null, style = null

      let dataTable = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0)`)


      // DataTable_01
      style = {
        id: 'PP',
        x: 20,
        y: 35,
        table: {
                no: 15,
                width:290, 
                head: 35, 
                sColor:'#757575', 
                sWidth: 0.5, 
                dwarY:45,
                lineHeight: 16,
                bottom: 330,
        },
        head1: [
          {name: 'SN',          x: 15,  y: 20,  col: 'Y', link: 'N', align: 'end',     tSize: 10, tColor: '#000', dColor: '#757575', dSize: 10}, 
          {name: 'Sub-System',  x: 55,  y: 20,  col: 'Y', link: 'N', align: 'middle',  tSize: 10, tColor: '#000', dColor: '#757575', dSize: 10}, 
          {name: 'MC Date',     x: 110, y: 20,  col: 'Y', link: 'N', align: 'middle',  tSize: 10, tColor: '#000', dColor: '#757575', dSize: 10}, 
          {name: 'A Punch',     x: 175, y: 13,  col: 'N', link: 'N', align: 'middle',  tSize: 10, tColor: '#000'}, 
          {name: 'B Punch',     x: 250, y: 13,  col: 'N', link: 'N', align: 'middle',  tSize: 10, tColor: '#000'},
        ],
        head2: [
          {name: 'Total',   x: 170, y: 25,    col: 'Y', link: 'Y',  align: 'end', tSize: 9, tColor: '#BCBCBC', dColor: '#757575', dSize: 10}, 
          {name: 'Open',    x: 205, y: 25,    col: 'Y', link: 'Y',  align: 'end', tSize: 9, tColor: '#BCBCBC', dColor: '#333', dSize: 10}, 
          {name: 'Total',   x: 245, y: 25,    col: 'Y', link: 'Y',  align: 'end', tSize: 9, tColor: '#BCBCBC', dColor: '#757575', dSize: 10}, 
          {name: 'Open',    x: 280, y: 25,    col: 'Y', link: 'Y',  align: 'end', tSize: 9, tColor: '#BCBCBC', dColor: '#333', dSize: 10}, 
        ],
      }
      data  = this.dataSet

      this.DataTable_01(dataTable, data, style) // ---------------------------> 
    },
    
  

  }
}
           