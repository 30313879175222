import * as d3 from 'd3'
import Data from '../primitives/donutProps'

export default {
  data: () => ({
    refColumn: 'SUBSYSTEM_STATUS'
  }),
  methods: {
    drawDonuts() {
      if(!this.Queries.SQL5 || this.Queries.SQL5.length === 0) return

      d3.select(`._donut_group__for_all`).remove()

      let arcProps = {
        angleSize: 360,
        angleStart: 0,
        bColor: '#F4F5F5',
        stroke: 8,
        Ra: 30
      }
      let values_ = this.Queries.SQL5[0]


      // let partRect = this.SkylineCharts
      // .append('g')
      // .attr('transform', `translate(610, 210)`)

      // partRect.append('rect')
      // .attr('rx', 5)
      // .attr('ry', 5)
      // .attr('x', 25)
      // .attr('width', 190)
      // .attr('height', 95)
      // .attr('fill', 'Transparent')
      // .attr('stroke', '#FFE57F')
      // .attr('stroke-width', 0.9)

      // partRect.append('rect')
      // .attr('x', 90)
      // .attr('y', -10)
      // .attr('width', 54)
      // .attr('height', 15)
      // .attr('fill', '#fff')

      // partRect.append('text')
      // .attr('transform', `translate(105, 0)`).attr('font-size', 10).attr('text-anchor','center').attr('alignment-baseline','middle')
      // .text('Partial')

      
      let donutGroup = this.SkylineCharts
      .append('g')
      .attr('class', '_donut_group__for_all')
      .attr('transform', `translate(70, 135)`)

      donutGroup
      .append('text')
      .attr('transform', `translate(-7,72)`)
      .style('font-size', 10).style('fill', '#424242').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Open A Punch Exceptions')
      donutGroup
      .append('rect')
      .attr('transform', `translate(-30, ${64})`)
      .attr('width', 16).attr('height', 16).attr('rx',50).attr('fill', '#EF6C00')
      donutGroup
      .append('text')
      .attr('transform', `translate(-25,73)`)
      .style('font-size', 10).style('fill', '#ffffff').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('0')

      donutGroup
      .append('text')
      .attr('transform', `translate(150,72)`)
      .style('font-size', 10).style('fill', '#424242').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Open A ITR Exceptions')
      donutGroup
      .append('rect')
      .attr('transform', `translate(125, ${64})`)
      .attr('width', 16).attr('height', 16).attr('rx',50).attr('fill', '#BF360C')
      donutGroup
      .append('text')
      .attr('transform', `translate(130,73)`)
      .style('font-size', 10).style('fill', '#ffffff').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('0')



      // let donutGroupPartial = this.SkylineCharts
      // .append('g')
      // .attr('class', '_donut_group__for_partial')
      // .attr('transform', `translate(610, 235)`)

      // this.SkylineCharts
      // .append('g')
      // .attr('class', '_donut_group__for_partial')
      // .attr('transform', `translate(610, 235)`)
      // .append('rect')
      // .attr('rx', 5)
      // .attr('ry', 5)
      // .attr('width', 610)
      // .attr('height', )
      // .attr('fill', 'Transparent')
      // .attr('stroke', '#F57C00')
      // .attr('stroke-width', 0.9)

      Data.attrs.forEach((attr, i) => {
        let arc = d3.arc()
        .outerRadius(d => d.Ra)
        .innerRadius(d => d.Ra - d.stroke)
        .startAngle(d => d.angleStart * Math.PI / 180)
        .endAngle(d => d.angleEnd || d.angleStart * Math.PI / 180)


        let x__ = attr.refColumn.includes('PARTIALLY') ? attr.refColumn.includes('COMPLETED') ? 610 : 710 : Data.width * i + (i > 0 ? Data.gab : 0)
        let y__ = attr.refColumn.includes('PARTIALLY') ? 115 : 0
        let donut_ = donutGroup 
        .append('g')
        .attr('class', `_donut_chart__${attr.refColumn.toLowerCase()}`)
        .attr('transform', `translate(${x__}, ${y__})`)

        // Draw Background Donut Chart
        donut_
        .append('circle')
        .attr('cx', 0).attr('cy', 0).attr('r', arcProps.Ra-3)
        .attr('stroke', arcProps.bColor).attr('stroke-width', arcProps.stroke)
        .attr('fill', `url(#${attr.fill})`)

        let circleRate = values_[attr.refColumn] / values_['TOTAL']

        // Draw Foreground Donut Chart
        donut_
        .datum(arcProps)
        .append('path')
        .attr('d', arc)
        .attr('fill', attr.fColor)
        .attr('opacity', 0.8)
        .transition()
        .duration(1500)
        .attrTween('d',(d) => {
          let endAngle_ = ((d.angleSize * circleRate) + d.angleStart) * Math.PI / 180
          let interpolate = d3.interpolate(d.angleStart * Math.PI / 180, endAngle_)
          return function(t) {
            d.angleEnd = interpolate(t)
            return arc(d);
          }
        })
        

        donut_
        .append('text')
        .attr('transform', `translate(0, 1)`)
        .style('font-family', 'roboto')
        .style('font-size', 13)
        .style('fill', attr.tColor)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(values_[attr.refColumn] ? values_[attr.refColumn] : 0)

        // Legend
        donut_
        .append('text')
        .attr('transform', `translate(0, 42)`)
        .style('font-family', 'roboto')
        .style('font-size', 10)
        .style('fill', '#757575')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(attr.text)

        donut_
        .append('text')
        .attr('transform', `translate(0, 52)`)
        .style('font-family', 'roboto')
        .style('font-size', 10)
        .style('fill', '#757575')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(attr.text2)
        
        donut_.on('click', (d, i, a) => {
          if (!values_[attr.refColumn] || values_[attr.refColumn] == 0) return

          if (attr.codeValue == 'Total') {
            // Total Init Donuts
            attr.on = !attr.on

            let attrs = []

            if (!attr.on) {
              // Total false, all off
              Data.attrs.forEach(attr => {
                attr.on = false
                d3.select(`._donut_chart__${attr.refColumn.toLowerCase()}`).style('opacity', 0.3)
                attrs.push(attr.codeValue)
                this.filtering[this.refColumn] = []
              })
            } else {
              // Total true, all on
              Data.attrs.forEach(attr => {
                attr.on = true
                d3.select(`._donut_chart__${attr.refColumn.toLowerCase()}`).style('opacity', 1)
                attrs.push(attr.codeValue)
              })

              this.filtering[this.refColumn] = []
              this.filtering[this.refColumn] = attrs
            }

          } else {
            attr.on = !attr.on
            if(attr.on) d3.select(a[i]).style('opacity', 1)
            else d3.select(a[i]).style('opacity', 0.3)
    
            this.setFilter('multi', this.refColumn, attr.codeValue)
          }

          this.Chart_filtering()
        })
        .on('mouseover', (_, i, a) => { d3.select(a[i]).style('cursor', 'pointer') })

        if(attr.on) this.setFilter('multi', this.refColumn, attr.codeValue)
      })
    },
  }
}