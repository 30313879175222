import * as d3 from 'd3'

export default {

  methods: {
    /*
      load_InitialSVG()
      draw_ActivityMenu()
    */

    load_InitialSVG(step) {

      if(this.innerSvg) this.innerSvg.remove()
      if(this.innerSvg) this.commissioningList.remove()

      this.innerSvg = this.svg.append('svg')
      this.commissioningBox.moveToFront()
      // After loading the SVG, perform initialization
      this.loadSvg(
        this.innerSvg, {
        x   : 0,
        y   : 80,
        url : `/China1/Utility_Commissioning/svg2/STEP-${step}.svg`
      }).then(() => {

        this.innerSvg
        .style('opacity', 0)
        .transition().duration(150).style('opacity', 1)

        let no_ = this.rawData.filter(f => f.step == step).length
        this.svg.select('#list_box_filter').transition().duration(150).attr('height', 5)
        this.svg.select('#list_box_background').transition().duration(150).attr('height', 5)
        
        setTimeout(() => {
          this.draw_ActivityMenu(step) // ---> below
        }, 200)

        this.rawData.filter(f => f.STEP == step).forEach((d,i) => {
          
          d3.select(`#NUMBER-${d.SN}`).attr('visibility', 'hidden')
          let xy_ = d3.select(`#NO-${d.SN}`).attr('visibility', 'hidden')
          d.cx = xy_.attr('cx')
          d.cy = xy_.attr('cy')
          this.innerSvg.selectAll('text').style('font-family', 'roboto')
          if(d.STORY == '') {
            d3.select(`#TEXT-${d.SN}`).attr('transform', `translate(${d.cx}, ${d.cy})scale(0)`)
          } else {
            d3.select(`#ANI-${d.SN}`).attr('visibility', 'hidden')
          }
        })
      })     
    },
    

    load_KeyActivities(step) {

      if(step.act == 0) return

      this.Schedule.filter(f=> f.act > 0).forEach((d,i)=> {
        d3.select(`#ACT-${d.no}`)
        .selectAll('text').attr('font-weight', 'normal')
      })

      for( let i=1; i <= step.act; i++) {
        d3.select(`#ACT-${step.no}-ITEM-${i}`).selectAll('text').attr('font-weight', 'bold')
        d3.select(`#ACT-${step.no}-ITEM-${i}`)
        .attr('transform', `translate(${-step.x}, 0)`).attr('opacity', 0)

        d3.select(`#ACT-${step.no}-ITEM-${i}`)
        .transition().delay(100*i).duration(500)
        .ease(d3.easeElasticOut.amplitude(1).period(.75))
        .attr('transform', `translate(0,0)`).attr('opacity', 10)
      }
      
    },


    draw_ActivityMenu(step) {

      this.commissioningList = this.svg.append('svg')
      let ListCommItem = this.commissioningList
      let y_ = 292
      let gap = 30
      let wrapHeight = 0
      let wrapTotal = 0
      let wrapLine = 0
      
      this.rawData.filter(f => f.STEP == step).forEach((d,i) => {

        ListCommItem
        .append('g')
        .attr('id', `LIST_${d.SN}`)
        .attr('transform', `translate(25, ${292 + 1})`)
        .attr('opacity', 0)
        .style('cursor', 'pointer')
        .call(this.call_Activity, d, d.SN)

        this.draw_gmx_TextBox_Wrap_v1(
          ListCommItem, 
          {
            text: d.TITLE,
            width: 300,
            x: 12,
            y: (gap/2) - 11,
            id: {parents: `#LIST_${d.SN}`, group: `group_${i}`, rect: `rect_${i}`,},
            box: { visible: 'Y', color: '#fff', opacity: 0.5},
            padding: {left: 5, right: 5, top: 5, bottom: 5},
            font:{ color: '#000', size: 11,},
            border:{ color: '#fff', size: 0.1, rx: 0, ry: 0},
          },
          null
        )
        
        let getHeight = d3.select(`#rect_${i}`).attr('height')
        if(getHeight > 25) {
          wrapHeight = 12
          wrapLine = 30
          wrapTotal +=  wrapHeight
        } else {
          wrapHeight = 0
          wrapLine = 0
        }
        d.lineY = y_ + gap + wrapHeight
        d.listY = y_+1 

        ListCommItem
        .append("line")
        .attr('id', `Line${d.SN}`)
        .attr('x1', 25).attr('y1', 292 + gap + wrapHeight + wrapLine)
        .attr('x2', 345).attr('y2', 292 + gap + wrapHeight + wrapLine)
        .attr('stroke', '#bcbcbc').attr('stroke-width', 0.5).attr('opacity', 0)

        d3.select(`#LIST_${d.SN}`)
        .append("rect")
        .attr('x', 0).attr('y', 0).attr('opacity', 0.7)
        .attr('width', 7).attr('height', gap - 2 + wrapHeight).attr('fill',`url(#${this.Legend.find(f=> f.code == d.CAT).name})`)

        d3.select(`#LIST_${d.SN}`)
        .append("rect")
        .attr('x', 0).attr('y', 0)
        .attr('width', 320).attr('height', gap - 2 + wrapHeight)
        .attr('fill',`transparent`)

        y_ += gap + wrapHeight
      })


      // Animation effects for commissioning menu header and list
      this.rawData.filter(f => f.STEP == step).forEach((d,i) => {

        d3.select(`#Line${d.SN}`)
        .transition().duration(100+(i*25))
        .attr('x1', 25).attr('y1', d.lineY)
        .attr('x2', 345).attr('y2', d.lineY)
        .attr('opacity', 1)

        d3.select(`#LIST_${d.SN}`)
        .transition().duration(100+(i*25))
        .attr('transform', `translate(25, ${d.listY})`)
        .attr('opacity', 1)
      })

      let no_ = this.rawData.filter(f => f.STEP == step).length
      this.svg.select('#list_box_filter').transition().duration(200)
      .attr('height', (no_ * gap ) + wrapTotal)
      this.svg.select('#list_box_background').transition().duration(200)
      .attr('height', (no_ * gap ) + wrapTotal)
    },

    
  }
}