import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({
    props:[
      { module: 'ME01', moduleDescr:'Flash Gas Compression',x: 0, y: 0 },
      { module: 'ME02', moduleDescr:'MEG & Fuel Gas',x: 0, y: 0 },
      { module: 'ME04', moduleDescr:'Power Generation',x: 10, y: 0 },
      { module: 'ME05', moduleDescr:'E/I Building',x: 10, y: 0 },
      { module: 'MW01', moduleDescr:'Reception Facilities & Production Separation',x: 0, y: 0 },
      { module: 'MW02', moduleDescr:'HC Dewpointing & Condensate Stabilisation',x: 0, y: 0 },
      { module: 'MW04', moduleDescr:'Power Generation',x: 10, y: 0 },
      { module: 'MW05', moduleDescr:'Utility',x: 5, y: 0 },
      { module: 'FL01', moduleDescr:'Flare Tower',x: 55, y: 15 },
      { module: 'KO01', moduleDescr:'KO drum',x: 10, y: 40 },
      // { module: 'LD00DA', moduleDescr:'Utility',x: 0, y: 0 },
      { module: 'RCxx', moduleDescr:'Piperack',x: 0, y: 45 },
    ],
    width: 945,
    scrollProps: {
      w: 932,
      h: 300,
      g: 0,
      bar: {
        align : 'right',
        gap   : 3,
        radius: 2,
        width : 4,
        fill  : '#9E9E9E',
        stroke: '#9E9E9E',
      }
    }
  }),

  methods: {
    drawChart() {

      //------------------------------------------------------------------------------------OGM chart
      let values_ = this.DataItems
      let disc = values_.map(d => d.DISC_DESCR)
      let itemWidth = this.width / disc.length
      let centerX = (itemWidth -100) / 2

      let svg = this.svg
      let group = svg.append('g').attr('class', 'ogm_status_group__').attr('transform', `translate(380.5, 50)`)

      values_.forEach((v, i) => {
        let g = group.append('g').attr('class', `${v.DISC_DESCR}_status__`).attr('transform', `translate(${i*itemWidth}, 0.5)`)

        // Imgae box
        g.append('rect')
        .attr('x', centerX).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', 90).attr('height', 90)
        .attr('fill', v.ACTUAL_PROG == 100 ? '#4CAF50' : v.DELTA > 0 ? '#4CAF50' : '#F44336')
        .attr('opacity', '0.7')

        if (v.ACTUAL_PROG !== 0) {
          g.append('rect')
          .attr('x', centerX).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', 90)
          .attr('height', parseInt(90 - (v.ACTUAL_PROG < 0 ? v.ACTUAL_PROG * -1 : v.ACTUAL_PROG) / 100 * 90))
          .attr('fill', '#fff')
          .attr('opacity', '0.6')
        } else {
          g.append('rect')
          .attr('x', centerX).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', 90)
          .attr('height', 90)
          .attr('fill', '#fff')
          .attr('opacity', '0.6')
        }
        g.append('image')
        .attr('id', `omg_img_${v.DISC_DESCR.toLowerCase()}`)
        .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Modules/LQDashboard/icons/${v.DISC_DESCR.toLowerCase()}.png`)
        .attr('x', centerX)
        .attr('width', '90px')
        .attr('height', '90px')

        // Discipline desc
        g.append('text')
        .attr('x', 45 + centerX).attr('y', 90 + 15).attr('text-anchor', 'middle')
        .text(v.DISC_DESCR)
        .style('font-size',12)
        .style('color', '#000000')
        .style('font-weight', 'bold')
      })
      //-----------------------------------------------------------------------------------Module Images and Progress
      let moduleMap = this.Queries.SQL1.map(d => d.LV3)[0] ? this.Queries.SQL1.map(d => d.LV3)[0] : 'ME01'
      let props = this.props.filter(d => d.module == moduleMap)[0]

      // console.log(moduleMap)
      // console.log(props)
      let mainImage = this.svg
      .append('g')
      .attr('transform', `translate(0,0)`)

      const path = `${this.__HOST_NAME_RESOURCE}/TORTUE/Modules/AreaDashboard/${moduleMap}.png`

      mainImage
      .append('image')
      .attr('id', 'menuIcon')
      .attr('transform', `translate(${props.x}, ${props.y}) `)
      .attr('xlink:href', path)
      .style('cursor', 'pointer')
      .on('mouseover', () => {
        mainImage.style('opacity', '0.7')
      })
      .on('mouseleave', () => {
        mainImage.style('opacity', '1')
      })
      .on('click', () => {
        if (!moduleMap[0]) return
        
        let request_ = this.getRequestColumnProps(moduleMap, {}, this.ColumnProps)
        this.$emit('request-action', request_)
      })

      mainImage
      .append('text')
      .attr('x', 174).attr('y', 44)
      .style('font-family','roboto').style('font-size',14).attr('text-anchor', 'start').attr('fill', '#0277BD')
      .text((props.module+' - '+props.moduleDescr))

      if(!this.Queries.SQL1 || this.Queries.SQL1.length === 0) return
      let item = this.Queries.SQL1[0]

      let X = 170
      let Y = 50
  
      let data = mainImage
      .append('g')
      .attr('transform', `translate(${X}, ${Y})`)

      data
      .append('rect')
      .attr('x', 4).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', 190).attr('height', 54)
      .attr('fill', '#83d2f5')

      data
      .append('text')
      .attr('x', 7).attr('y', 28)
      .style('font-family','roboto').style('font-size',10).attr('text-anchor', 'start').attr('fill', '#424242')
      .text('Cum.')
      data
      .append('text')
      .attr('x', 7).attr('y', 48)
      .style('font-family','roboto').style('font-size',10).attr('text-anchor', 'start').attr('fill', '#424242')
      .text('Week.')

      data
      .append('text')
      .attr('x', 49).attr('y', 13)
      .style('font-family','roboto').style('font-size',10).attr('text-anchor', 'start').attr('fill', '#424242')
      .text('Plan')

      data
      .append('text')
      .attr('x', 80).attr('y', 30)
      .style('font-family','roboto').style('font-size',11).attr('text-anchor', 'end').attr('fill', '#FFF')
      .text(item.PLAN + '%')
      data
      .append('text')
      .attr('x', 80).attr('y', 48)
      .style('font-family','roboto').style('font-size',11).attr('text-anchor', 'end').attr('fill', '#FFF')
      .text(item.PLAN_WK + '%')

      data
      .append('text')
      .attr('x', 99).attr('y', 13)
      .style('font-family','roboto').style('font-size',10).attr('text-anchor', 'start').attr('fill', '#424242')
      .text('Actual')

      data
      .append('text')
      .attr('x', 134).attr('y', 30)
      .style('font-family','roboto').style('font-size',11).attr('text-anchor', 'end').attr('fill', '#FFF')
      .text(item.ACTUAL + '%')
      data
      .append('text')
      .attr('x', 134).attr('y', 48)
      .style('font-family','roboto').style('font-size',11).attr('text-anchor', 'end').attr('fill', '#FFF')
      .text(item.ACTUAL_WK + '%')

      data
      .append('text')
      .attr('x', 180).attr('y', 13)
      .style('font-family','roboto').style('font-size',10).attr('text-anchor', 'end').attr('fill', '#424242')
      .text('Delta')

      data
      .append('text')
      .attr('x', 184).attr('y', 30)
      .style('font-family','roboto').style('font-size',11).attr('text-anchor', 'end').attr('fill', '#FFF')
      .text(item.DELTA + '%')
      data
      .append('text')
      .attr('x', 184).attr('y', 48)
      .style('font-family','roboto').style('font-size',11).attr('text-anchor', 'end').attr('fill', '#FFF')
      .text(item.DELTA_WK + '%')

      let ogmdata = mainImage
      .append('g')
      .attr('transform', `translate(${X+3}, ${Y+70})`)

      ogmdata
      .append('rect')
      .attr('x', 0).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', 86).attr('height', 36)
      .attr('fill', '#ffffff').attr('stroke', '#8BC34A') .attr('stroke-width', 0.5)
      ogmdata
      .append('text')
      .attr('x', 86/2).attr('y', 13)
      .style('font-family','roboto').style('font-size',10).attr('text-anchor', 'middle').attr('fill', '#424242')
      .text(item.TEXT2 ? item.TEXT2 : '')

      const barScale = d3.scaleLinear()
      .domain([0,100])
      .range([0,86])

      //PROG Background Bar
      ogmdata
      .append('rect')
      .attr('x', 0).attr('y', 18).attr('width', 86).attr('height', 18)
      .attr('fill', '#fff').attr('stroke', '#8BC34A') .attr('stroke-width', 0.5)

      //PROG Actual Bar
      ogmdata
      .append('rect')
      .attr('x', 0).attr('y', 18)
      .attr('width',0)
      .attr('height',18)
      .attr('fill','#8BC34A')

      .transition().duration(150)
      .attr('width',barScale(item.ACTUAL))

      ogmdata
      .append('text')
      .attr('x', 86/2).attr('y', 31)
      .style('font-family','roboto').style('font-size',11).attr('text-anchor', 'middle')
      .attr('fill',item.ACTUAL >=60 ? '#fff' : '#424242')
      .text(item.TEXT2_PROG ? item.TEXT2_PROG + '%' : '')

      ogmdata
      .append('rect')
      .attr('x', 102).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', 86).attr('height', 18)
      .attr('fill', '#fff').attr('stroke', '#9E9E9E') .attr('stroke-width', 0.5)
      ogmdata
      .append('text')
      .attr('x', 145).attr('y', 13)
      .style('font-family','roboto').style('font-size',10).attr('text-anchor', 'middle').attr('fill', '#424242')
      .text(item.TEXT1 ? item.TEXT1 : '')

      ogmdata
      .append('rect')
      .attr('x', 102).attr('y', 18).attr('width', 86).attr('height', 18)
      .attr('fill', '#9E9E9E').attr('stroke', '#9E9E9E') .attr('stroke-width', 0.5)
      ogmdata
      .append('text')
      .attr('x', 145).attr('y', 31)
      .style('font-family','roboto').style('font-size',11).attr('text-anchor', 'middle').attr('fill', '#FFF')
      .text(item.TEXT1_DATE ? item.TEXT1_DATE : '')
    },
  }
}