import router from '@/router'
import axios from 'axios'
import { Service } from 'axios-middleware'
import Vue from 'vue'
import __M from 'moment'
import __C from '../../primitives/_constant_'

export default class Api {

  constructor() {

    this.service = new Service(axios)
    this.service.register({
      onResponse(response) {
        let token_ = response.headers['refresh-token']

        if(token_ && token_ != localStorage.getItem(__C.LOCAL_STORAGE_NAME.TOKEN)) {
          let account = JSON.parse(localStorage.getItem(__C.LOCAL_STORAGE_NAME.ACCOUNT))
          account.token = response.headers['refresh-token']
          
          localStorage.setItem(__C.LOCAL_STORAGE_NAME.ACCOUNT, JSON.stringify(account))
          localStorage.setItem(__C.LOCAL_STORAGE_NAME.TOKEN, response.headers['refresh-token'])
          localStorage.setItem(__C.LOCAL_STORAGE_NAME.TIMEOUT, __M().format('YYYY-MM-DD HH:mm:ss'))
        }
        if(response && response.data) {
          // .
        } else {
          response.data = {}
        }
        return response
      },

      onResponseError(err) {
        if (err.response) {
          console.log(`[USER #API RESPONSE#] err.response.status: ${err.response.status}`)
          switch(err.response.status) {
            case 401: // ErrUnauthorized
              // alert(`[API #SERVICE#] 401`)
              break
            case 428: // ErrExpired
              // alert(`[API #SERVICE#] 428`)
              break
            case 511: // ErrNoTokenFound
              // alert(`[API #SERVICE#] 511`)
              break
            case 501: // ErrAlgoInvalid
              // alert(`[API #SERVICE#] 501`)
              break
            case 406: // ErrInvalidToken
              // alert(`[API #SERVICE#] 406`)
              // 인증 관련 에러 처리
              //Vue.$cookies.remove(__C.LOCAL_STORAGE_NAME.TOKEN)
              //router.push('/signin')
              break
            default:
              //alert(`[API #SERVICE#] ${err.response.status}`)
              // 기타 에러 처리
          }
        }
        // 인증 관련 에러 처리
        Vue.$cookies.remove(__C.LOCAL_STORAGE_NAME.TOKEN)
        localStorage.removeItem(__C.LOCAL_STORAGE_NAME.ACCOUNT)
        window.location.href = __C.CONFIG.FOR_LOCAL ? __C.HOST_NAME : __C.LANDING_PAGE_NAME
      }
    })
  }

  requestHeader(auth = true, options = {}) {
    let requestOptions = options
    if(!!auth) {
      // // return authorization header with jwt token
      // let account = JSON.parse(localStorage.getItem(__C.LOCAL_STORAGE_NAME.ACCOUNT))
      // if (account && account.token) {
      //   requestOptions['Authorization'] = 'Bearer ' + account.token
      // }
      const token = this.tokenString() // tokenString 메서드 사용
      if(token) {
        requestOptions['Authorization'] = token
      }
    }
    return requestOptions // 반환값 추가
  }

  tokenString() {
    let account = JSON.parse(localStorage.getItem(__C.LOCAL_STORAGE_NAME.ACCOUNT))
    if (account && account.token) return 'Bearer ' + account.token
    return ''
  }

  axios(tokenAuth=true, reqCancel=true) {

    if(tokenAuth) {
      axios.defaults.headers.common['Authorization'] = this.tokenString()
    } else {
      //
    }

    return axios
  }
  // axios() {
  //   console.log(`[API #SERVICE#] axios create`,axios.defaults.withCredentials)
  //   return axios.create({
  //   })
  // }
}