import * as d3 from 'd3'

export default {
  methods: {
    draw_Back() {
      this.btnGoBack = (selection) => {
        selection
        .on('mouseover', () => {
          d3.select(`.Back_Btn`).transition().duration(100).style('opacity', 0.5)
        })
        .on('mouseout', () => {
          d3.select(`.Back_Btn`).transition().duration(100).style('opacity', 1)
        })
        .on('click', () => {
          d3.select(`.Back_Btn`).transition().duration(100).style('opacity', 1)

          this.hiddenGroupID('#Menu_Back', 0, 10)
          this.hiddenGroupID('#Keyplan_Group', 0, 10)
          this.hiddenGroupID('#Legends', 0, 10)
          
          this.removeSvg(this.CANVAS, 500)

          if (this.innerSvg) this.removeSvg(this.innerSvg, 300)
          if (d3.select('#tag_button_wrapper')) this.removeSvg(d3.select('#tag_button_wrapper'), 300)

          d3.select(`#Title`).text(`Rooms`)

          this.displayGroupID(`#Mask_LQ01`, 0, 0, 0)
          this.displayGroupID(`#Mask_LQ02`, 0, 0, 0)
          this.displayGroupID(`#Mask_LQ06`, 0, 0, 0)
          this.displayGroupID(`#Mask_FOR`, 0, 0, 0)
          this.displayGroupID(`#Mask_MS03`, 0, 0, 0)
          this.displayGroupID(`#Mask_MS01`, 0, 0, 0)
          this.displayGroupID(`#Mask_ME05DB`, 0, 0, 0)
          this.displayGroupID(`#Mask_ME05DA`, 0, 0, 0)

          setTimeout(() => {
            this.location.forEach(d => {
              if(d.LV4 == this.currentLevel) { //this.currentLevel에 변수로 담겨있기때문에 이것으로만 선택 가능
                d3.select(`#${d.LV4}`).transition().duration(300).attr('transform', `translate(${d.x}, ${d.y}) scale(${d.scale})`)
              } else {
                this.displayGroupID(`#${d.LV4}`, 300, 1)
              }
            })
          }, 500);

          setTimeout(() => {
            this.drawProgress('Rooms')    
            
            this.selectedItems.pop()
          }, 600)
        })
      }
    },
  }
}