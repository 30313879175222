import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  methods: {
    drawChart() {
      let svg = this.svg
      .append('g')
      .attr('class', '__datasource')
      .attr('transform', `translate(20, 10)`)

      svg
      .append('text')
      .style('font-family','roboto')
      .style('font-size', 10)
      .attr('fill', '#000')
      // .attr('text-anchor', 'middle')
      // .attr('alignment-baseline', 'middle')
      .text(this.DataItems[0].CUTOFF)
    }, 
  }
}
