import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({
    radarData: [],

    Radar: {
      chart: {
              x1:              1030,
              y1:              885,
              x2:              1320,
              y2:              885,
              size:            150,
              data:           1,
      },
      level: {
              no:             5,
              stWidth:        0.3,
              stColor:        '#BCBCBC',
              opacity:        0.5,
              lastStWidth:    0.5,
              lastStColor:    '#757575',
              lastOpacity:    1,
              maxValue:       100,
              space:          3,
              font:           'roboto',
              tSize:           9,
              tColor:         '#757575',
      },  
      axes: { 
              stWidth:        0.5,
              stColor:        '#bcbcbc',
              opacity:        0.7,
      },  
      series: { 
              font:           'roboto',
              tSize:           10,
              tColor:         '#818181',
              factor:         0.55,
      },  
      value: {  
              display:        'Y',
              font:           'roboto',
              tSize:           9,
              tColor:         '#000',
      },  
      area: { 
              stWidth:        1,
              stColor:        "#44A9DF",
              fColor:         "LightBlue",
              opacity:        0.3,
              colorType:      '',
      },  
      node: { 
              size:           2,
              stWidth:        1,
              stColor:        "#44A9DF",
              fColor:         "#44A9DF",
              opacity:        0.7,
      },
    },

    Radians: 2 * Math.PI,
  }),


  methods: {

    draw_Radar(selection, _radar, _style) {

      this.radarData = JSON.parse(JSON.stringify(_radar))

      let allAxis = this.radarData.map(d => d.DISC)
      let total = allAxis.length
      let radarSize = this.Radar.chart.size
      let radius = radarSize / 2
      let angleSlice  = this.Radians / total

      //Circular segments
      let RadarChart = selection
      .append('g')
      .attr('transform', `translate(${_style.x - (radarSize/2)}, ${_style.y - (radarSize/2)})`)

      for(let j=0; j<this.Radar.level.no; j++) {
        let levelFactor = radius * ((j + 1) / this.Radar.level.no)
        RadarChart
        .append('circle')
        .attr('cx', radarSize/2)
        .attr('cy', radarSize/2)
        .attr('r', levelFactor)
        .attr('fill', 'Transparent')
        .attr('stroke-width', () => {if (j == (this.Radar.level.no - 1)) return this.Radar.level.lastStWidth; else return this.Radar.level.stWidth})
        .attr('stroke', () => {if (j == (this.Radar.level.no - 1)) return this.Radar.level.lastStColor; else return this.Radar.level.stColor})
        .attr('opacity', () => {if (j == (this.Radar.level.no - 1)) return this.Radar.level.lastOpacity; else return this.Radar.level.opacity})
      }

      //Level Text indicating at what % each level is
      for(let j=0; j<this.Radar.level.no; j++){
        let levelFactor = radius * ((j + 1) / this.Radar.level.no)

        RadarChart
        .append('text')
        .attr('transform', `translate(${radarSize / 2 - levelFactor + this.Radar.level.space}, ${radarSize/2-levelFactor})`)
        .attr('x', levelFactor * (1 - Math.sin(0)))
        .attr('y', levelFactor * (1 - Math.cos(0)))
        .attr('fill', this.Radar.level.tColor)
        .style('font-family', this.Radar.level.font)
        .attr('font-size', this.Radar.level.tSize)
        .text(`${(j+1)*100/this.Radar.level.no}%`)
      }

      // group of the axes in the Radar Chart 
      let Axes = RadarChart
      .selectAll('.axis')
      .data(allAxis)
      .enter()
      .append('g')
      .attr('class', 'axis')

      // draw lines for each axis
      Axes
      .append('line')
      .attr('x1', radarSize / 2)
      .attr('y1', radarSize / 2)
      .attr('x2', (d, i) => radarSize / 2 * (1 - Math.sin(i * this.Radians / total)))
      .attr('y2', (d, i) => radarSize / 2 * (1 - Math.cos(i * this.Radians / total)))
      .style('stroke', this.Radar.axes.stColor)
      .attr('stroke-width', this.Radar.axes.stWidth)
      .attr('opacity', this.Radar.axes.opacity)

      // put the title of the axis
      Axes
      .append('text')
      .attr('transform', 'translate(0, -10)')
      .attr('text-anchor', 'middle')
      .attr('dy', '1.5em')
      .attr('x', (d, i) => radarSize / 2 * (1 - this.Radar.series.factor * Math.sin(i * this.Radians / total)) - 60 * Math.sin(i * this.Radians / total))
      .attr('y', (d, i) => radarSize / 2 * (1 - Math.cos(i * this.Radians / total)) - 20 * Math.cos(i * this.Radians / total))
      .style('font-family', this.Radar.series.font)
      .attr('font-size', this.Radar.series.tSize)
      .attr('fill', this.Radar.series.tColor)
      .text(d => d)

      
      let dataValues = JSON.parse(JSON.stringify(this.radarData))

      //Scale for the radius
      let rScale = d3.scaleLinear()
      .domain([0, this.Radar.level.maxValue])
      .range([0, radius])

      //The radial line function
      let defaultRadial = d3.lineRadial()
      .angle((_, i) => (i * angleSlice) * -1)
      .radius(() => rScale(0))
      
      //The radial line function
      let lineRadial = d3.lineRadial()
      .angle((_, i) => (i * angleSlice) * -1)
      .radius(d => rScale(d.PROG))

      defaultRadial.curve(d3.curveCardinalClosed.tension(0))
      lineRadial.curve(d3.curveCardinalClosed.tension(0))
      
      RadarChart
      .append('g')
      .style('fill-opacity', this.Radar.area.opacity)
      .attr('transform', `translate(${radarSize/2}, ${radarSize/2})`)

      .append('path')
      .attr('d', defaultRadial(dataValues))
      .attr('stroke-width', this.Radar.area.stWidth)
      .attr('stroke', this.Radar.area.stColor)
      .attr('fill', this.Radar.area.fColor)
      .attr('fill', '#B4E3FA')
      .transition().duration(700)
      .attr('d', lineRadial(dataValues))
      

      // Draw Node on area
      dataValues = []

      RadarChart
      .selectAll('.nodes')
      .data(this.radarData, (d, i) => {
        dataValues.push([
          radarSize / 2 * (1 - (parseFloat(Math.max(d.PROG, 0)) / this.Radar.level.maxValue) * Math.sin(i * this.Radians / total)), 
          radarSize / 2 * (1 - (parseFloat(Math.max(d.PROG, 0)) / this.Radar.level.maxValue) * Math.cos(i * this.Radians / total))
        ])
      })

      RadarChart
      .selectAll('.circle')
      .data(dataValues)
      .enter()
      .append('circle')
      .attr('cx', radarSize/2)
      .attr('cy', radarSize/2)
      .attr('r', this.Radar.node.size)
      .attr('fill', this.Radar.node.fColor)
      .attr('stroke-width', this.Radar.node.stWidth)
      .attr('stroke', this.Radar.node.stColor)
      .attr('opacity', this.Radar.node.opacity)
      .transition().duration(700)
      .attr('cx', d => d[0])
      .attr('cy', d => d[1])

      let RadarValue = RadarChart
      .append('g')
      .attr('opacity', () => { if(this.Radar.value.display == 'Y') return 1; else return 0 })

      RadarValue
      .selectAll('.text')
      .data(dataValues)
      .enter()
      .append('text')        
      .attr('x', radarSize/2)
      .attr('y', radarSize/2)
      .style('font-family', this.Radar.value.font)
      .attr('font-size', this.Radar.value.tSize)
      .attr('fill', this.Radar.value.tColor)
      .attr('text-anchor', 'middle')
      .text((d,i) => `${Number(this.radarData[i].PROG).toFixed(1)}%`)
      .transition().duration(700)
      .attr('x', d => d[0])
      .attr('y', d => d[1] - 5)
    },

  }
}
