export default {
  watch: {
    Canvas: { handler(val) { if(!val || Object.keys(val).length === 0) return; this.register(this.draw); }, deep: true },
    Text: { handler(val) { if(!val || Object.keys(val).length === 0) return; this.register(this.draw); }, deep: true },
    DataItems: { handler(val) { if(!val || val.length === 0) return; this.register(this.draw); }, deep: true },

    callback: { 
      handler(val) { 
        if(!val) return

        if (val.refCode != this.ColumnProps[0].link.action.commentProps.refCode) return

        let list = JSON.parse(JSON.stringify(val.comments)).map(c => ({
          IDX           : c.idx,
          USER_ID       : c.userId,
          COMMENT       : c.comment,
          EXT_COMMENT    : c.ext1
        }))

        this.dataSet = list.reverse()
        this.drawTable()
        
      }, 
      deep: true 
    },
  },
}
