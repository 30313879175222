import * as d3 from 'd3'

export default {
  data: () => ({
    dataSet: [],
    sailAway: [],
    target: null,
    moveX: 0,
    DetailInfo: null,
  }),
  computed: {

    ready2draw() {

      // console.log('------------------------------')
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)

      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },

  },



  methods: {
    setDefaultValues(){
      this.dataSet = JSON.parse(JSON.stringify(this.DataItems))
      this.sailAway = JSON.parse(JSON.stringify(this.Queries.SQL1))
      

      this.dataSet.forEach(data => {
        let planDate = ''
        let actualDate = ''

        data.planDate = this.sailAway.find(f => f.MOD == data.MOD && f.PAU.substr(-1) == 'A').SA_PLAN
        data.actualDate = this.sailAway.find(f => f.MOD == data.MOD && f.PAU.substr(-1) == 'A').SA_ACTUAL
      })

      this.dataSet.sort(function(a, b) { 
        return a.planDate < b.planDate ? -1 : a.planDate > b.planDate ? 1 : 0;
      })

      this.sailAway.sort(function(a, b) { 
        return a.SA_PLAN < b.SA_PLAN ? -1 : a.SA_PLAN > b.SA_PLAN ? 1 : 0;
      })

      this.target = this.dataSet.findIndex(f=> f.actualDate == null) - 1
      this.moveX = (this.target) * -308  // -5852
      if (this.moveX < -5852) this.moveX = -5852

      // this.dataSet.forEach((d,i) => { 
      //   console.log(i+1, d.MOD, d.planDate)
      // })
      

      let lightGray = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `lightGray`).attr('x1', 0.772).attr('x2', 0.228).attr('y1', 0.228).attr('y2', 0.919)
      lightGray.append('stop').attr('stop-color', '#fff').attr('offset', '0').attr('stop-opacity', 1)
      lightGray.append('stop').attr('stop-color', '#d0d2d3').attr('offset', '1').attr('stop-opacity', 1)

    },

    
    
    
  }
}