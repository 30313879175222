import Defs from '../../../../../../includes/primitives/Color_Defs'
import Data from '../../../../../../includes/primitives/Color_Data'

import * as d3 from 'd3'

export default {
  data: () => ({
    dataSet: [],

    Status: [
      { status: null,       bColor: '#ffffff', tColor: '#bcbcbc'},
      { status: 'Cutting',  bColor: '#9AD0E9', tColor: '#44A9DF'},
      { status: 'Assembly', bColor: '#4492CF', tColor: '#FFFFFF'},
      { status: 'Painting', bColor: '#F2DA77', tColor: '#44A9DF'},
      { status: '1PE',      bColor: '#CFD88C', tColor: '#333'},
      { status: '3PE',      bColor: '#91C643', tColor: '#333'},
    ],

    Border: [
      {
        type: 'PE',
        shape: 'ellipse',
        width: 55,
        height: 20,
        color: 'gray',
        stroke: .2,
        textSize: 13,
        padding: 1,
      },
      {
        type: '3PE',
        shape: 'ellipse',
        width: 38,
        height: 16,
        color: 'gray',
        stroke: .2,
        textSize: 9.5,
        padding: 0.5,
      },
      {
        type: '1PE',
        shape: 'rect',
        width: 40,
        height: 16,
        color: 'gray',
        stroke: .2,
        textSize: 10,
        padding: 1,
      },
    ],
    
    AREAS: [
              { area: 'Topside' ,x: 130  ,y: 320 },
              { area: 'Deckbox' ,x: 130  ,y: 570 },
              { area: 'Hull'    ,x: 130  ,y: 820 },
    ],

    MODULES: {
      Hull: [
        { module: 'NP1UH'   ,item: 'Y'  ,x: 992     ,y: 495       ,x1: 580.50  ,y1: 371.82      ,x2: 771.00  ,y2: 429.82      ,x3: 100.00  ,y3: 100.00  ,sx: -0, sy:0},
        { module: 'NP2UH'   ,item: 'Y'  ,x: 1030    ,y: 672       ,x1: 671.63  ,y1: 432.50      ,x2: 850.63  ,y2: 619.04      ,x3: 100.00  ,y3: 100.00  ,sx: -0, sy:0},
        { module: 'NP4UH'   ,item: 'Y'  ,x: 391     ,y: 543       ,x1: 464.00  ,y1: 404.00      ,x2: 395.00  ,y2: 420.40      ,x3: 100.00  ,y3: 100.00  ,sx: -53, sy:0},
        { module: 'C10UH'   ,item: 'N'  ,x: 889     ,y: 157       ,x1: 604.50  ,y1: 296.00      ,x2: 793.08  ,y2: 140.00      ,x3: 100.00  ,y3: 100.00  ,sx: -0, sy:0},
        { module: 'C20UH'   ,item: 'N'  ,x: 1043    ,y: 342       ,x1: 756.00  ,y1: 363.50      ,x2: 940.91  ,y2: 293.50      ,x3: 100.00  ,y3: 100.00  ,sx: -0, sy:0},
        { module: 'C40UH'   ,item: 'N'  ,x: 492     ,y: 220       ,x1: 464.00  ,y1: 368.79      ,x2: 397.00  ,y2: 165.79      ,x3: 100.00  ,y3: 100.00  ,sx: -0, sy:0},
        { module: 'NP3UH'   ,item: 'Y'  ,x: 688     ,y: 776       ,x1: 524.00  ,y1: 472.70      ,x2: 522.03  ,y2: 666.82      ,x3: 100.00  ,y3: 100.00  ,sx: -0, sy:0},
        { module: 'C30UH'   ,item: 'N'  ,x: 610     ,y: 500       ,x1: 614.17  ,y1: 425.56      ,x2: 606.17  ,y2: 364.56      ,x3: 100.00  ,y3: 100.00  ,sx: -0, sy:0},
      ],
      Deckbox: [
        { module: 'N10UH'   ,item: 'Y'  ,x: 1077    ,y: 410       ,x1: 576.11   ,y1: 332.53     ,x2: 809.11  ,y2: 343.53      ,x3: 375.11  ,y3: 418.53 ,sx: -0, sy:0},
        { module: 'W10UH'   ,item: 'Y'  ,x: 673     ,y: 452       ,x1: 505.32   ,y1: 346.00     ,x2: 486.32  ,y2: 420.00      ,x3: 416.32  ,y3: 417.00 ,sx: -0, sy:0},
        { module: 'E10UH'   ,item: 'Y'  ,x: 980     ,y: 660       ,x1: 611.49   ,y1: 378.50     ,x2: 809.49  ,y2: 546.50      ,x3: 425.49  ,y3: 403.50 ,sx: -0, sy:0},
        { module: 'S10UH'   ,item: 'Y'  ,x: 640     ,y: 760       ,x1: 445.00   ,y1: 372.88     ,x2: 396.00  ,y2: 588.88      ,x3: 380.00  ,y3: 400.88 ,sx: -0, sy:0},
        { module: 'LQ1UH'   ,item: 'N'  ,x: 457     ,y: 307       ,x1: 443.50   ,y1: 360.63     ,x2: 308.50  ,y2: 296.63                               },
        { module: 'HD1UH'   ,item: 'N'  ,x: 616     ,y: 189       ,x1: 424.00   ,y1: 349.75     ,x2: 523.00  ,y2: 173.75                               },
      ],
      Topside: [
        { module: 'PM1UT'   ,item: 'Y'  ,x: 437     ,y: 366       ,x1: 543      ,y1: 328        ,x2: 385     ,y2: 178         ,x3: 410     ,y3: 399    ,sx: -0, sy:0},
        { module: 'CM1UT'   ,item: 'Y'  ,x: 996     ,y: 270       ,x1: 662      ,y1: 412        ,x2: 837     ,y2: 212         ,x3: 430     ,y3: 421    ,sx: -0, sy:0},
        { module: 'FB1UT'   ,item: 'N'  ,x: 1062    ,y: 590       ,x1: 798      ,y1: 329        ,x2: 967     ,y2: 467         ,x3: 430     ,y3: 421    ,sx: -0, sy:0},
        { module: 'WM1UT'   ,item: 'Y'  ,x: 704     ,y: 685       ,x1: 602      ,y1: 419        ,x2: 594     ,y2: 507         ,x3: 410     ,y3: 400    ,sx: -0, sy:0},
        { module: 'GM1UT'   ,item: 'Y'  ,x: 245     ,y: 575       ,x1: 465      ,y1: 458        ,x2: 251     ,y2: 420         ,x3: 425     ,y3: 417    ,sx: -0, sy:0},
      ],
    },

    DECKS: {
      Deckbox: [
        // S10UH
        { module: 'S10UH'  ,deck: 'S10UH_L1' ,name: 'Cellar Deck' ,item: 'Y'  ,scale: 0.75   ,x: 280   ,y: 566  ,x1: 0.02  ,y1: 32.13    ,x2: 0      ,y2: 455.13 },
        { module: 'S10UH'  ,deck: 'S10UH_L2' ,name: 'Tween Deck'  ,item: 'Y'  ,scale: 0.75   ,x: 280   ,y: 353  ,x1: 0     ,y1: 37.38    ,x2: 0      ,y2: 255.38 },
        { module: 'S10UH'  ,deck: 'S10UH_L3' ,name: 'Main Deck'   ,item: 'Y'  ,scale: 0.75   ,x: 280   ,y: 138  ,x1: 19.14 ,y1: 0        ,x2: 19.14  ,y2: 0      },

        // N10UH
        { module: 'N10UH'  ,deck: 'N10UH_L1' ,name: 'Cellar Deck' ,item: 'Y'  ,scale: 0.75   ,x: 310   ,y: 538  ,x1: 0     ,y1: 6.47     ,x2: 0      ,y2: 454.47 },
        { module: 'N10UH'  ,deck: 'N10UH_L2' ,name: 'Tween Deck'  ,item: 'Y'  ,scale: 0.75   ,x: 310   ,y: 330  ,x1: 19.62 ,y1: 1.97     ,x2: 19.62  ,y2: 244.97 },
        { module: 'N10UH'  ,deck: 'N10UH_L3' ,name: 'Main Deck'   ,item: 'Y'  ,scale: 0.75   ,x: 310   ,y: 75   ,x1: 13.62 ,y1: 0        ,x2: 13.89  ,y2: 0      },

        // W10UH
        { module: 'W10UH'  ,deck: 'W10UH_L1' ,name: 'Cellar Deck' ,item: 'Y'  ,scale: 1.0    ,x: 210   ,y: 385  ,x1: 0     ,y1: 26.05    ,x2: 0     ,y2: 317.05 },
        { module: 'W10UH'  ,deck: 'W10UH_L2' ,name: 'Main Deck'   ,item: 'Y'  ,scale: 1.0    ,x: 210   ,y: 78   ,x1: 3.07  ,y1: 0        ,x2: 3.07  ,y2: 0      },

        // E10UH
        { module: 'E10UH'  ,deck: 'E10UH_L1' ,name: 'Cellar Deck' ,item: 'Y'  ,scale: 1.0    ,x: 200   ,y: 372  ,x1: 0.75  ,y1: 37.7     ,x2: 0.750  ,y2: 327.7 },
        { module: 'E10UH'  ,deck: 'E10UH_L2' ,name: 'Main Deck'   ,item: 'Y'  ,scale: 1.0    ,x: 200   ,y: 85   ,x1: 0     ,y1: 0        ,x2: 0      ,y2: 0     },
      ],

      Topside: [
        // GM1UT
        { module: 'GM1UT'  ,deck: 'GM1UT_L1' ,name: 'Lower Deck'  ,item: 'Y'  ,scale: 0.90   ,x: 190   ,y: 386   ,x1: 5    ,y1: 10     ,x2: 0    ,y2: 287.00  },
        { module: 'GM1UT'  ,deck: 'GM1UT_L2' ,name: 'Upper Deck'  ,item: 'Y'  ,scale: 0.90   ,x: 190   ,y: 95    ,x1: 0    ,y1: 0      ,x2: 5    ,y2: 10 },

        // PM1UT
        { module: 'PM1UT'  ,deck: 'PM1UT_L1' ,name: 'Lower Deck'  ,item: 'Y'  ,scale: 0.90   ,x: 190   ,y: 587   ,x1: 12   ,y1: 49     ,x2: 12   ,y2: 507 },
        { module: 'PM1UT'  ,deck: 'PM1UT_L2' ,name: 'Middle Deck' ,item: 'Y'  ,scale: 0.90   ,x: 190   ,y: 345   ,x1: 9    ,y1: 59     ,x2: 9    ,y2: 298 },
        { module: 'PM1UT'  ,deck: 'PM1UT_L3' ,name: 'Upper Deck'  ,item: 'Y'  ,scale: 0.80   ,x: 190   ,y: 130   ,x1: 0    ,y1: 0      ,x2: 0    ,y2: 0   },

        // CM1UT
        { module: 'CM1UT'  ,deck: 'CM1UT_L1' ,name: 'Lower Deck'  ,item: 'Y'  ,scale: 1.00   ,x: 190   ,y: 587   ,x1: 0    ,y1: 35     ,x2: 0    ,y2: 521 },
        { module: 'CM1UT'  ,deck: 'CM1UT_L2' ,name: 'Middle Deck' ,item: 'Y'  ,scale: 1.00   ,x: 190   ,y: 353   ,x1: 8    ,y1: 34     ,x2: 8    ,y2: 284 },
        { module: 'CM1UT'  ,deck: 'CM1UT_L3' ,name: 'Upper Deck'  ,item: 'Y'  ,scale: 1.00   ,x: 190   ,y: 110   ,x1: 2    ,y1: 0      ,x2: 2    ,y2: 0   },

        // WM1UT
        { module: 'WM1UT'  ,deck: 'WM1UT_L1' ,name: 'Lower Deck'  ,item: 'Y'  ,scale: 1.00   ,x: 190   ,y: 580   ,x1: 11   ,y1: 78     ,x2: 11  ,y2: 521 },
        { module: 'WM1UT'  ,deck: 'WM1UT_L2' ,name: 'Middle Deck' ,item: 'Y'  ,scale: 1.00   ,x: 190   ,y: 350   ,x1: 48   ,y1: 54     ,x2: 48  ,y2: 280 },
        { module: 'WM1UT'  ,deck: 'WM1UT_L3' ,name: 'Upper Deck'  ,item: 'Y'  ,scale: 0.90   ,x: 190   ,y: 108   ,x1: 0    ,y1: 0      ,x2: 0   ,y2: 0 },
      ],
    },  
    
    
  }),


  computed: {
  },


  methods: {

    setStyles() {

      let lightBlue = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `shadowTimeline`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      lightBlue.append('stop').attr('stop-color', '#3d3d3d').attr('offset', '0').attr('stop-opacity', 0.5)
      lightBlue.append('stop').attr('stop-color', '#fff').attr('offset', '1').attr('stop-opacity', 0.3)

      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['LightGray'], [`LightGray`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['YellowGreen'], [`YellowGreen`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['LightBlue'], [`LightBlue`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['Pink'], [`Pink`, 0.5, 1.0, 0.5, -0.2], this.localId)
      
    },

  }
}