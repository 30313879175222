import * as d3 from 'd3'

export default {
  methods: {
    drawNoContents() {
      let noData = this.svg
      .append('g')
      .attr('transform', `translate(${this.Canvas.CanvasWidth / 2}, 200)`)
      noData
      .append('text')
      .style('font-weight', 500)
      .style('font-size', 30)
      .attr('text-anchor', 'middle')
      .style('font-style', 'italic')
      .attr('alignment-baseline', 'middle')
      .attr('fill', '#CFCFCF')
      .text('No Data Available.')
    },
    drawContents() {
      let headerStyle = {
        title: 'A ITR',
        x: 5,
        y: 2,
        width: 50,
        height: 18,
        radius: 3,
        tSize: 11,
        tColor: '#333',
        bColor: '#E0E0DF',
        progSize: 15,
      }
      let style__ = {
        tx: [25, 150, 200, 255, 310],
        ty: 40,
        gx: [320, 360, 400],
        x: [150, 200, 255, 310],
        y: [10, 25, 40],
        width: 80,
      }
      let chartArea___ = this.svg
      .append('g')
      .attr('id', 'outfitting_status___chart_area')
      .attr('transform', `translate(0, 30)`)

      let prevHeight = 0
      let level1NameRows = [].concat.apply([], this.level1Names.map((_, i) => i % 2 ? [] : [this.level1Names.slice(i, i + 2)]))

      level1NameRows.forEach((names, i) => {
        let areaRow___ = chartArea___
        .append('g')
        .attr('transform', `translate(5, ${prevHeight})`)

        names.forEach((name, j) => {
          let itemData = this.DataItems.filter(d => d.DISC == name)
          let discArea = areaRow___
          .append('g')
          .attr('transform', `translate(${j * 435}, 0)`)

          discArea
          .append('text')
          .attr('transform', `translate(5, 12.5)`)
          .style('font-family', 'roboto')
          .style('font-weight', 500)
          .style('font-size', 12)
          .attr('text-anchor', 'start')
          .attr('alignment-baseline', 'middle')
          .attr('fill', '#000')
          .text(name)

          discArea.append('line').attr('x1', 0).attr('y1', 22).attr('x2', 410).attr('y2', 22).attr('stroke-width', 0.3).attr('stroke', '#333')

          // Header
          discArea
          .append('text')
          .attr('transform', `translate(${style__.tx[0]}, ${style__.ty})`)
          .style('font-family', 'roboto').style('font-size', 10).style('fill', '#333').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
          .text('Item')

          discArea
          .append('text')
          .attr('transform', `translate(${style__.tx[1]}, ${style__.ty})`)
          .style('font-family', 'roboto').style('font-size', 10).style('fill', '#333').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
          .text('Work Step')

          discArea
          .append('text')
          .attr('transform', `translate(${style__.tx[2]}, ${style__.ty})`)
          .style('font-family', 'roboto').style('font-size', 10).style('fill', '#333').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
          .text('Total')
    
          discArea
          .append('text')
          .attr('transform', `translate(${style__.tx[3]}, ${style__.ty})`)
          .style('font-family', 'roboto').style('font-size', 10).style('fill', '#333').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
          .text('Complete')
    
          discArea
          .append('text')
          .attr('transform', `translate(${style__.tx[4]}, ${style__.ty})`)
          .style('font-family', 'roboto').style('font-size', 10).style('fill', '#333').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
          .text('Remained')

          // Bar Gauge
          discArea
          .append('text')
          .attr('transform', `translate(${style__.gx[0]}, ${style__.ty})`)
          .style('font-family', 'roboto').style('font-size', 8).style('fill', '#bcbcbc').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
          .text('0')
    
          discArea
          .append('text')
          .attr('transform', `translate(${style__.gx[1]}, ${style__.ty})`)
          .style('font-family', 'roboto').style('font-size', 8).style('fill', '#bcbcbc').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
          .text('50')
    
          discArea
          .append('text')
          .attr('transform', `translate(${style__.gx[2]}, ${style__.ty})`)
          .style('font-family', 'roboto').style('font-size', 8).style('fill', '#bcbcbc').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
          .text('100')
    
          discArea
          .append('line')
          .attr('x1', style__.gx[0]).attr('y1', style__.ty + 5).attr('x2', style__.gx[0]).attr('y2', style__.ty + 10).style('stroke', '#bcbcbc').style('stroke-width', 0.5)
          
          discArea
          .append('line')
          .attr('x1', style__.gx[1]).attr('y1', style__.ty + 5).attr('x2', style__.gx[1]).attr('y2', style__.ty + 10).style('stroke', '#bcbcbc').style('stroke-width', 0.5)
          
          discArea
          .append('line')
          .attr('x1', style__.gx[2]).attr('y1', style__.ty + 5).attr('x2', style__.gx[2]).attr('y2', style__.ty + 10).style('stroke', '#bcbcbc').style('stroke-width', 0.5)
          
          let discItemHeight = 0
          itemData.forEach((item, k) => {
            let filter = itemData.filter(d => d.DISC === name && d.ITEM === item.ITEM)
            let discItem = discArea
            .append('g')
            .attr('class', 'discItem')
            .attr('transform', `translate(0, ${discItemHeight + 50})`)

            let itemGroup = discArea
            .append('g')
            .attr('transform', `translate(0, ${discItemHeight + 50})`)

            if (filter.length > 1) {
              if ((k+1 < itemData.length && item.ITEM === itemData[k + 1].ITEM)
                  || (k-1 > 0 && item.ITEM !== itemData[k - 1].ITEM)
                  || (k === 0)
                )
              {
                headerStyle.title = item.ITEM
                this.HeaderFlexible(itemGroup, null, headerStyle)

                if (k !== 0) {
                  let filter = itemData.filter(d => d.ITEM === item.ITEM).length
                  let height = discItem.node().getBoundingClientRect().height

                  itemGroup.append('line')
                  .attr('x1', 0).attr('y1', height * filter).attr('x2', 400).attr('y2', height * filter)
                  .style('stroke', '#bcbcbc').style('stroke-width', 0.3)
                }
              }
            } else {
              headerStyle.title = item.ITEM
              this.HeaderFlexible(itemGroup, null, headerStyle)

              if (k !== 0) {
                let filter = itemData.filter(d => d.ITEM === item.ITEM).length
                let height = discItem.node().getBoundingClientRect().height

                itemGroup.append('line')
                .attr('x1', 0).attr('y1', height * filter).attr('x2', 400).attr('y2', height * filter)
                .style('stroke', '#bcbcbc').style('stroke-width', 0.3)
              }
            }



            discItem
            .append('text')
            .attr('transform', `translate(${style__.x[0]}, ${style__.y[0]})`)
            .style('font-family', 'roboto').style('font-size', 10).style('fill', '#03a9f4').style('text-decoration', 'underline')
            .attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
            .text(item.WC)
            .on('mouseover', (_, i, a) => { d3.select(a[i]).style('cursor', 'pointer').style('fill', '#000') })
            .on('mouseout', (_, i, a) => { d3.select(a[i]).style('cursor', 'pointer').style('fill', '#03a9f4') })
            .on('click', () => {
              let request_ = {
                dataType: 'row',
                action: {
                  type: 'direct',
                  target: 'slide-modal',
                  component: 'ServiceDatatable',
                  id: 173,
                  no: ''
                },
                filters: { 
                  BLOCK: this.FilterValues.BLOCK || '',
                  MBLOCK: this.FilterValues.MBLOCK || '',
                  WCCODE: item.WCCODE
                },
                iFilters: {
                  filterString : '',
                  inputFilter  : ''
                }
              }
              this.$emit('request-action', request_)
            })

            discItem
            .append('text')
            .attr('transform', `translate(${style__.x[1]}, ${style__.y[0]})`)
            .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575')
            .attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
            .text(item.TOTAL)

            discItem
            .append('text')
            .attr('transform', `translate(${style__.x[2]}, ${style__.y[0]})`)
            .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575')
            .attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
            .text(item.COM)

            discItem
            .append('text')
            .attr('transform', `translate(${style__.x[3]}, ${style__.y[0]})`)
            .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575')
            .attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
            .text(item.REM)

            // bar progress
            discItem
            .append('rect')
            .attr('transform', `translate(${style__.x[3] + 10}, ${style__.y[0] - 7})`)
            .attr('width', 0).attr('height', 12)
            .attr('stroke-width', 0.3).attr('stroke', '#44A9DF').attr('fill', '#B4E3FA')
            .transition().duration(500).attr('width', item.PROG ? (item.PROG/100) * style__.width : 0)
    
            discItem
            .append('text')
            .attr('y', style__.y[0]) 
            .attr('x', item.PROG && item.PROG > 0 ? style__.x[3] + 12 : style__.x[3] + 12)
            .style('font-family', 'roboto').style('font-size', 9).attr('alignment-baseline', 'middle')
            .text(`${(item.PROG || 0)}%`)
            .style('fill', (item.PROG || 0) < 25 ? '#757575' : '#fff')
            .attr('text-anchor', (item.PROG || 0) < 25 ? 'start' : 'end')
            .transition().duration(500)
            .attr('x', (item.PROG || 0) < 25 ? style__.x[3] + 12 + (item.PROG || 0) : style__.x[3] + 12 + ((item.PROG/100) * style__.width) - 3)

            discItemHeight += discItem.node().getBoundingClientRect().height + 10
          })
        })
        prevHeight += areaRow___.node().getBoundingClientRect().height + 40
      })
    },
  }
}
