import * as d3 from 'd3'
// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {
    Draw_Content() {
      if(this.ColumnProps.length > 0) var props = this.ColumnProps.find(props_ => props_.value == 'comprops').props
      else props = this.defaultset

      let ImageName =props.image

      let svg = this.svg
        .append('g')
        .attr('transform', `translate(0, 0)`)

      svg
        .append('image')
        .attr('opacity', 1)
        .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/China1/ImageSvg/${ImageName}.jpg`)
        // .attr('width', '1400px')
        
    }
  }
}