import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {

    
    Draw_Charts() {
      
      let data = null, 
          PA = null,
          PB = null,
          Circle = null,
          Guideline = null,
          style = null


      let overallPunchStatus = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0)`)

      style = {
        id: 'WD',
        x: 165,
        y: 115,
        name: 'Active',
        sort: 'N',
      }
      PA = this.dataSet.find(f=> f.TYPE == 'A' && f.DISC == 'Overall')
      PB = this.dataSet.find(f=> f.TYPE == 'B' && f.DISC == 'Overall')
      data = [
        {title: 'A Punch',     value: PA.PROG,      qty: PA.REMAIN},
        {title: 'B Punch',     value: 100-PA.PROG,  qty: PB.REMAIN}
      ]
     
      Circle = {
        CircleDiameter: 60,
        CircleColorSet: ['SkyBlue', 'LightBlue'],
        CircleHoleSize: 40,
        CircleStackSize: 2,
        CircleSliceSize: 5,
        CircleTitleSize: 12,
        CircleLegendSpace: 80,
      }
      Guideline = {
        GuideInside: 5,
        GuideOutside: 5,
        GuideExtend: 0,
      }
      this.Chart_Pie(overallPunchStatus, data, style, Circle, Guideline) // ---------------------------> 


      // Bar_Punch_03
      data = this.dataSet.filter(f=> f.TYPE == 'A' && f.DISC !== 'Overall')
      style = {
        id:       'PA',
        x:        20,
        y:        250,
        cell:     [30, 40, 0, 0],
        bColor:   '#309CD5', 
        sColor:   '#44A9DF', 
        tColor1:  '#fff', 
        tColor2:  '#757575', 
        opacity:  1,
        length:   75,
        lineHeight: 16,
        min: 25,
      }
      this.Bar_Punch_03(overallPunchStatus, data, style) // ---------------------------> 


      // Bar_Punch_03
      data = this.dataSet.filter(f=> f.TYPE == 'B' && f.DISC !== 'Overall')
      style = {
        id:       'PB',
        x:        170,
        y:        250,
        cell:     [30, 40, 0, 0],
        bColor:   '#83D2F5', 
        sColor:   '#44A9DF', 
        tColor1:  '#fff', 
        tColor2:  '#757575', 
        opacity:  1,
        length:   75,
        lineHeight: 16,
        min: 25,
      }
      this.Bar_Punch_03(overallPunchStatus, data, style) // ---------------------------> 




      
    },
  }
}
           