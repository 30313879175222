import * as d3 from 'd3'
import Data from '../primitives/donutProps'

// ------------------------------------------------------------------------------- Global

export default {
  methods: {
    drawDonuts() {
      if(!this.DataItems || this.DataItems.length === 0) return

      let arcProps = {
        angleSize: 360,
        angleStart: 0,
        bColor: '#F4F5F5',
        stroke: 8,
        Ra: 38
      }
      let values_ = this.DataItems[0]
      
      let donutGroup = this.svg
      .append('g')
      .attr('class', '_donut_group__for_all')
      .attr('transform', `translate(70, 90)`)

      // donutGroup.append('line').attr('x1', - 63).attr('y1', 0).attr('x2', this.tWidth - 63).attr('y2', 0).attr('stroke-width', 2).attr('stroke', '#f5f5f5')

      Data.attrs.forEach((attr, i) => {
        let arc = d3.arc()
        .outerRadius(d => d.Ra)
        .innerRadius(d => d.Ra - d.stroke)
        .startAngle(d => d.angleStart * Math.PI / 180)
        .endAngle(d => d.angleEnd || d.angleStart * Math.PI / 180)
        let x__ = Data.width * i + Data.gab
        let donut_ = donutGroup
        .append('g')
        .attr('class', `_donut_chart__${attr.refColumn.toLowerCase()}`)
        .attr('transform', `translate(${x__}, 0)`)

        // Draw Background Donut Chart
        donut_
        .append('circle')
        .attr('cx', 0).attr('cy', 0).attr('r', arcProps.Ra-3)
        .attr('stroke', arcProps.bColor).attr('stroke-width', arcProps.stroke)
        .attr('fill', 'url(#lightGray)')

        let circleRate = values_['TOTAL'] ? (values_[attr.refColumn] || 0) / values_['TOTAL'] : 0

        // Draw Foreground Donut Chart
        donut_
        .datum(arcProps)
        .append('path')
        .attr('d', arc)
        .attr('fill', attr.fColor)
        .attr('opacity', 0.5)
        .transition()
        .duration(1500)
        .attrTween('d',(d) => {
          let endAngle_ = ((d.angleSize * circleRate) + d.angleStart) * Math.PI / 180
          let interpolate = d3.interpolate(d.angleStart * Math.PI / 180, endAngle_)
          return function(t) {
            d.angleEnd = interpolate(t)
            return arc(d);
          }
        })

        donut_
        .append('text')
        .attr('transform', `translate(0, 1)`)
        .style('font-family', 'roboto')
        .style('font-size', 12)
        .style('fill', attr.tColor)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(values_[attr.refColumn] || 0)

        // Legend
        donut_
        .append('text')
        .attr('transform', `translate(0, 50)`)
        .style('font-family', 'roboto')
        .style('font-size', 9)
        .style('fill', '#757575')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(attr.text)
      })
    }
  }
}
