import Defs from '../../../../../../includes/primitives/Color_Defs'
import __M from 'moment'
import * as d3 from 'd3'

export default {
  data: () => ({

  }),
  computed: {

    ready2draw() {

      // console.log('------------------------------')
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)

      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },
  methods: {
    setDefaultValues(){
      
      let dradient = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `shadowTimeline`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      dradient.append('stop').attr('stop-color', '#3d3d3d').attr('offset', '0').attr('stop-opacity', 0.5)
      dradient.append('stop').attr('stop-color', '#fff').attr('offset', '1').attr('stop-opacity', 0.3)

      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['LightGray'], [`LightGray`, 0.5, 1.0, 0.5, -0.2], this.localId)
    },
  }
}