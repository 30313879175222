export default {
  data: () => ({
    radioOptions: {
      values: [
        { colName: 'Supervision'    , text: 'Supervision WP' , value: '', x: 25  , color:'#D50000',textWidth: 100, on: false  },
        { colName: 'Subcontract'    , text: 'Subcontracting' , value: '', x: 145 , color:'#0D47A1',textWidth: 100, on: false },
        { colName: 'QualityStation' , text: 'Quality Station', value: '', x: 265 , color:'#FFA000',textWidth: 100, on: true },
      ]
    },
    styleFilter: {
      over: {
        stroke: '#bcbcbc',
        fill: '#fff',
        color: '#BDBDBD'
      },
      out: {
        stroke: '#bcbcbc',
        fill: '#fff',
        color: '#BDBDBD'
      },
      selected: {
        stroke: '#424242',
        fill: '#757575',
        color: '#212121'
      }
    },
  }),
}