import * as d3 from 'd3'
import Data from '../primitives/donutProps'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({
    headers: [
      { name: 'Client Reference', colname: 'DOC_CLIENT_REF' , tColor: '#b5b5b5', x: 50 , cx: 30 },
      { name: 'Title'           , colname: 'DOC_DESCR'      , tColor: '#454545', x: 220, cx: 200 },
      { name: 'Rev. Date'       , colname: 'REV_DATE'       , tColor: '#b5b5b5', x: 766, cx: 760 },
    ],
    tWidth: 820,
  }),
  methods: {
    drawNoContents() {
      let noData = this.svg
      .append('g')
      .attr('transform', `translate(${this.Canvas.CanvasWidth / 2}, 200)`)
      noData
      .append('text')
      .style('font-weight', 500)
      .style('font-size', 30)
      .attr('text-anchor', 'middle')
      .style('font-style', 'italic')
      .attr('alignment-baseline', 'middle')
      .attr('fill', '#CFCFCF')
      .text('No Data Available.')
    },

    drawChart() {
      let table__ = this.svg
      .append('g')
      .attr('class', '__table')
      .attr('transform', `translate(5.5, 35.5)`)

      // Header Text
      table__
      .append('g')
      .attr('class', '__header_text')
      .attr('transform', `translate(0, 13)`)

      this.headers.forEach(d => {
        table__
        .append('text')
        .attr('x', d.x)
        .attr('y', 30)
        .style('font-family','roboto')
        .style('font-size', 10)
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .attr('fill', '#757575')
        .text(d.name)
      })

      let tablebody = table__
      .append('g')
      .attr('class', '__table_body')
      .attr('transform', `translate(0, 42)`)

      let mOverColor = '#03a9f4'
      let sectorHeight = 0
      this.level1Names.forEach(name => {
        let items___ = this.DataItems.filter(item => item.DISC == name)
        let sector__ = tablebody
        .append('g')
        .attr('transform', `translate(0, ${sectorHeight})`)

        sector__.append('rect').attr('x', 0).attr('y', 0).attr('width', this.tWidth).attr('height', 19).attr('stroke-width', 0).attr('fill', '#F4F5F5').attr('opacity', .45)
        sector__.append('line').attr('x1', 0).attr('y1', 19).attr('x2', this.tWidth).attr('y2', 19).attr('stroke-width', 0.3).attr('stroke', '#b5b5b5')
        sector__
        .append('text')
        .attr('transform', `translate(15, 10)`)
        .style('font-family','roboto')
        .style('font-weight', 500)
        .style('font-size', 12)
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .attr('fill', mOverColor)
        .text(name)
    
        items___.forEach((item, i) => {
          let rowdata = sector__
          .append('g')
          .attr('class', '__row_data')
          .attr('transform', `translate(0, ${this.rowHeight * i + 25})`)
  
          if(i % 2 == 1)
            rowdata.append('rect').attr('width', this.tWidth).attr('height', this.rowHeight).attr('stroke-width', 0).attr('fill', '#F4F5F5').attr('opacity', 0.4)
  
          this.headers.forEach((h, j) => {
            if(j === 0){
              let linkText = rowdata
              .append('text')
              .attr('transform', `translate(${h.cx}, 10)`)
              .style('font-family','roboto')
              .style('font-size',10)
              .attr('fill', h.tColor)
              .attr('alignment-baseline', 'middle')
              .text(item[h.colname] || '')
              .on('mouseover', (_, i, a) => {
                if(!item.LINK) return

                d3.select(a[i])
                .style('cursor', 'pointer')
                .attr('fill', '#000')
              })
              .on('mouseout', (_, i, a) => {
                if(!item.LINK) return

                d3.select(a[i])
                .style('cursor', 'default')
                .attr('fill', mOverColor)
              })
              .on('click', () => {
                if(!item.LINK) return

                let request_ = {
                  dataType: 'row',
                  action: {
                    type: 'direct',
                    target: 'url',
                    path: item.LINK
                  },
                }
                this.$emit('request-action', request_)
              })

              if(item.LINK) {
                linkText.attr('fill', '#03a9f4')
                linkText.style('text-decoration', 'underline')
              }
              
            } else if(j === 1) {
              rowdata
              .append('text')
              .attr('transform', `translate(12, 9.5)`)
              .style('font-family','roboto')
              .style('font-size', 10)
              .attr('fill', '#c5c5c5')
              .attr('text-anchor', 'middle')
              .attr('alignment-baseline', 'middle')
              .text(i + 1)
              
              rowdata
              .append('g')
              .attr('clip-path', 'url(#activity_overflow_none)')
              .append('text')
              .attr('transform', `translate(${h.cx}, 9.5)`)
              .style('font-family','roboto')
              .style('font-size', 10)
              .attr('fill', h.tColor)
              .attr('alignment-baseline', 'middle')
              .text(item[h.colname] || '')
  
            } else {
              rowdata
              .append('text')
              .attr('transform', `translate(${h.cx}, 10)`)
              .style('font-family','roboto')
              .style('font-size',10)
              .attr('fill', h.tColor)
              .attr('alignment-baseline', 'middle')
              .text(item[h.colname] || '')
            }
          })
        })
        
        sectorHeight += this.rowHeight * items___.length + 28
      })
    }, 
  }
}
