// -------------------------------------------------------------------------------------- Global
import { SafeIdMixin } from '../../../../../../includes/mixins/safeid.mixin'                // ATAGlance Framework Globals
import gmx_Controller from '../../../../../../includes/mixins/Controller.mixin'       // SVG Control
// Global Drawings
import gmx_Canvas from '../../../../../../includes/mixins/Draw_Canvas.mixin'
import gmx_Texts from '../../../../../../includes/mixins/Text_Draw.mixin'
// Global Functions
import gmx_Miscellaneous from '../../../../../../includes/mixins/Miscellaneous.mixins'
import gmx_Movable from '../../../../../../includes/mixins/Movable.mixin'


// -------------------------------------------------------------------------------------- Functional Charts
import Cht_Charts_Pie from           '../../../../include/ChartLibrary/Charts_Pie.mixin'
import chart_BarCharts from           '../../../../include/ChartLibrary/Tortue_BarCharts.mixin'


// Locally declared common Data-Props & SVG Drawing Support
import lmx_ReactiveEvents from './Realtime_Monitor.mixin' // Reactive Events
import lmx_Declares from './Declares.mixin'
import lmx_Charts from './Draw_Charts.mixin'
import lmx_dataStyles     from './dataStyles.mixin'
import lmx_Progress from              './Draw_Progress.mixin'
import event_PDF from                 './Event_PDF_mixin'

import lmx_Codes from                 './Draw_Codes.mixin'
import lmx_MovablePopup from              './MovablePopup.mixin'
import lmx_Popup from                 './Draw_EQ_Popup'
import lmx_Tag_Buttons from           './Draw_Tag_Buttons'

export default {
  mixins: [
    SafeIdMixin,
    gmx_Controller,
    gmx_Canvas,
    gmx_Miscellaneous,
    gmx_Movable,
    gmx_Texts,


    // Functional Charts
    Cht_Charts_Pie,
    chart_BarCharts,

    lmx_ReactiveEvents,
    lmx_Declares,
    lmx_Charts,
    lmx_dataStyles,
    lmx_Progress,

    lmx_Codes,
    lmx_MovablePopup,
    lmx_Popup,
    lmx_Tag_Buttons,
    event_PDF,
  ],
}
