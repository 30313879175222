import * as d3 from 'd3'
import { json } from 'd3'

export default {
  methods: {
    
    setEvent_PDF() {
      String.prototype.replaceAll = function(org, dest) {
        return this.split(org).join(dest);
      }
      let convert = this.getSelectItems.replaceAll('},' , '}|')
      let dataSet = convert.split('|')
      dataSet.forEach(getData => {
        let d = JSON.parse(getData)
        this.slideSeq = d.stage
        this.slideMax = d.level

        if(this.slideSeq !== this.slideMax) {
          
          setTimeout(() => {
            d3.select(`#btn_Back`).transition().style('opacity', 1)
            d3.select(`#arrowMask`).style('visibility', 'visible')
          }, 1000);

          setTimeout(() => {
          // Remove SVG
          this.removeSvg(this.Contents, 10)
          }, 1000);
          setTimeout(() => {
            setTimeout(() => {
              this.draw_Contents()
            }, 300);
            d3.select(`#arrowMask`).style('visibility', 'hidden')
          }, 1000);
        } else {
          setTimeout(() => {
            this.slideSeq = 1
            d3.select(`#btn_Back`).transition().style('opacity',0.2)
            d3.select(`#arrowMask`).style('visibility', 'visible')
          }, 1000);
          setTimeout(() => {
            // Remove SVG
            this.removeSvg(this.Contents, 10)
            }, 1000);
          setTimeout(() => {
            setTimeout(() => {
             this.draw_Contents()
            }, 300);
            d3.select(`#arrowMask`).style('visibility', 'hidden')
          }, 1000);
        }
      })
    },
  }
}