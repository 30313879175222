import Defs from '../../../../../../includes/primitives/Color_Defs'

import Data_Hull_After from   '../primitives/BlockData/Data_Hull_After'
import Data_Hull_Forward from '../primitives/BlockData/Data_Hull_Forward'
import Data_Hull_LQ from      '../primitives/BlockData/Data_Hull_LQ'
import Data_Topsides from     '../primitives/BlockData/Data_Topsides'
import Data_Hull_Mechinaery from   '../primitives/BlockData/Data_Hull_Mechinaery'
import { reject } from 'core-js/fn/promise'

export default {
  data: () => ({
    dataSet: [],

    Status: [ 
      // status가 바뀌거나 추가 될때 Tortue_BarCharts.mixin.js의 Bar_Quantity_02 도  바꿔줘야한다 
      // { code: null,    title: '',     bColor: '#fff', sColor: '#fff', tColor: '#333', opacity: 1, },
      { code: 'PO',          title: 'PO',                          colName: { total: 'PO_TOTAL'                 , actual: 'PO_ACTUAL'    }, bColor: '#84C7E5', sColor: '#00838F', tColor: '#333', opacity: 1.00, },
      { code: 'FAT',         title: 'FAT',                         colName: { total: 'FAT_TOTAL'                , actual: 'FAT_ACTUAL'   }, bColor: '#2281C5', sColor: '#9F368B', tColor: '#fff', opacity: 1.00, },
      { code: 'IRN',         title: 'IRN',                         colName: { total: 'IRN_TOTAL'                , actual: 'IRN_ACTUAL'   }, bColor: '#CC93C1', sColor: '#9F368B', tColor: '#333', opacity: 1.00, },
      { code: 'ETA',         title: 'ON Site',                     colName: { total: 'ONSITE_TOTAL'             , actual: 'ONSITE_ACTUAL'}, bColor: '#F6D15E', sColor: '#F9A825', tColor: '#333', opacity: 1.00, },
      { code: 'INST',        title: 'Installed',                   colName: { total: 'INST_TOTAL'               , actual: 'INST_ACTUAL'  }, bColor: '#8DBA2D', sColor: '#558B2F', tColor: '#fff', opacity: 1.00, },
      { code: 'NOT_PO',      title: 'Not PO',                      colName: { total: 'NOT_PO_CNT'               , actual: ''             }, bColor: '#ffffff', sColor: '#BDBCBC', tColor: '#333', opacity: 1.00, },
      { code: 'ROS_PAST',    title: 'ETA is past from ROS',        colName: { total: 'ETA_PAST_FROM_ROS'        , actual: ''             }, bColor: '#FF9800', sColor: '#BDBCBC', tColor: '#333', opacity: 1.00, },
      { code: 'UPBLOCK_PAST',title: 'ETA is past from  Upper Blk.',colName: { total: 'ETA_PAST_FROM_UPPERBLOCK' , actual: ''             }, bColor: '#EC407A', sColor: '#BDBCBC', tColor: '#fff', opacity: 1.00, },
      // NOT_PO        => [NOT_PO_CNT] = [PO_ACTUAL] IS NULL
      // ROS_PAST      => [ETA_PAST_FROM_ROS] = [PO_ACTUAL] IS NOT NULL AND [ETA_ACTUAL] IS NULL AND [ROS_ETA_DELTA] < 0 
      // UPBLOCK_PAST  => [ETA_PAST_FROM_UPPERBLOCK] = [PO_ACTUAL] IS NOT NULL AND [ETA_ACTUAL] IS NULL AND [ETA_UPPERBLOCK_DELTA] < 0 
    ],
 
    Border: [
      {
        type: 'MEGA',
        shape: 'ellipse',
        width: 35,height: 15,
        color: 'gray',
        stroke: .2,
        textSize: 10,
        padding: 3,
        rx: 10,ry: 4.5,
      },
      {
        type: 'UNIT',
        shape: 'rect',
        width: 30,height: 14,
        color: 'gray',
        stroke: .2,
        textSize: 10,
        padding: 2.5,
      },
    ],
    LoadedTextProps: {
      x: 6.5,
      y: 6.5,
      color: '#757575',
      bColor: '#efefef',
      textSize: 8.5,
      padding: 1,
    },
    CircleBorder: {
      rx: 6.5,
      ry: 6.5,
      color: '#757575',
      bColor: '#efefef',
      stroke: .5,
      textSize: 8.5,
      padding: 1,
    },
    BlockStyle: {
      MEGA:{
        shape: 'ellipse',
        color: '#F5F5F5',
        stroke: .2,
        textSize: 9,
        padding: 2,
        rx: 20,
        ry: 8,
      },
      UNIT:{
        shape: 'rect',
        color: '#F5F5F5',
        stroke: .2,
        textSize: 9,
        padding: 2,
        width: 26,
        height:16
      },
    },

    Hull_Mechinaery: Data_Hull_Mechinaery,

    Hull_AFT: Data_Hull_After,
    Hull_FOR: Data_Hull_Forward,
    Hull_LQ: Data_Hull_LQ,
    TOPSIDES: Data_Topsides,
    //2020-11-26 이사님 MS01~04까지 번호 바꾸라고 하심 (벌써 2번째)data까지는 바꾸지 못했음 web에 보이는 text만(NAME)수정
    BlockStatus_Coordinate: [
      {SCRNAME: 'Overall',        LV1: null     , LV2: null,        LV3: null,      LV4: null,    NAME: 'Tortue FPSO',    x: 1160,  y: 400, },
      
      {SCRNAME: 'Hull & Top',     LV1: 'HULL'    , LV2: null,        LV3: null,      LV4: null,    NAME: 'Hull & LQ',      x: 1150,   y: 580, },
      {SCRNAME: 'Hull & Top',     LV1: 'TOPSIDES', LV2: null,        LV3: null,      LV4: null,    NAME: 'Topside',        x: 1080,  y: 250, },

      {SCRNAME:"Hull & LQ",       LV1:"HULL",     LV2:"HULL-AFT",   LV3:"AFT",     LV4:"AFT",     NAME:"AFT",             x: 576,    y: 870,},
      {SCRNAME:"Hull & LQ",       LV1:"HULL",     LV2:"HULL-FOR",   LV3:"FOR",     LV4:"FOR",     NAME:"Forward",         x: 880,    y: 775,},
      {SCRNAME:"Hull & LQ",       LV1:"HULL",     LV2:"HULL-FOR",   LV3:"MS",       LV4:null,     NAME:"Machinery Space", x: 1211,   y: 631,},
      {SCRNAME:"Hull & LQ",       LV1:"HULL",     LV2:"LQ",         LV3:null,       LV4:null,     NAME:"LQ",              x: 820,    y: 190,},

      {SCRNAME:"Hull MS",         LV1:"HULL",     LV2:"HULL-FOR",   LV3:"MS",       LV4:"MS01",   NAME:"MS04",            x: 1233,  y: 559,},
      {SCRNAME:"Hull MS",         LV1:"HULL",     LV2:"HULL-FOR",   LV3:"MS",       LV4:"MS02",   NAME:"MS03",            x: 1062,  y: 652,},
      {SCRNAME:"Hull MS",         LV1:"HULL",     LV2:"HULL-FOR",   LV3:"MS",       LV4:"MS03",   NAME:"MS02",            x: 888,   y: 732,},
      {SCRNAME:"Hull MS",         LV1:"HULL",     LV2:"HULL-FOR",   LV3:"MS",       LV4:"MS04",   NAME:"MS01",            x: 700,   y: 801,},

      {SCRNAME:"Hull LQ",          LV1:"HULL",     LV2:"LQ",        LV3:"LQ00",     LV4:"LQ01",  NAME:"LQ -Level 01",    x: 275,  y: 675,},
      {SCRNAME:"Hull LQ",          LV1:"HULL",     LV2:"LQ",        LV3:"LQ00",     LV4:"LQ02",  NAME:"LQ -Level 02",    x: 488,  y: 245,},
      {SCRNAME:"Hull LQ",          LV1:"HULL",     LV2:"LQ",        LV3:"LQ00",     LV4:"LQ03",  NAME:"LQ -Level 03",    x: 742,  y: 130,},
      {SCRNAME:"Hull LQ",          LV1:"HULL",     LV2:"LQ",        LV3:"LQ00",     LV4:"LQ04",  NAME:"LQ -Level 04",    x: 735,  y: 845,},
      {SCRNAME:"Hull LQ",          LV1:"HULL",     LV2:"LQ",        LV3:"LQ00",     LV4:"LQ05",  NAME:"LQ -Level 05",    x: 1020, y: 700,},
      {SCRNAME:"Hull LQ",          LV1:"HULL",     LV2:"LQ",        LV3:"LQ00",     LV4:"LQ06",  NAME:"LQ -Level 06",    x: 1230, y: 560,},
      {SCRNAME:"Hull LQ",          LV1:"HULL",     LV2:"LQ",        LV3:"LQ00",     LV4:"LQ07",  NAME:"LQ -Level 07",    x: 1080, y: 140,},

      {SCRNAME: 'Module & Common',  LV1: 'TOPSIDES', LV2: 'MODULES', LV3: null,     LV4: null,  NAME: 'Modules',  x: 1040, y: 300, },
      {SCRNAME: 'Module & Common',  LV1: 'TOPSIDES', LV2: 'COMMON' , LV3: null,     LV4: null,  NAME: 'Common',   x: 515,  y: 800, },
  
      {SCRNAME: 'Topside Modules', LV1: 'TOPSIDES', LV2: 'MODULES' , LV3: 'ME01',   LV4: null,   NAME: 'ME01',  NAMEDESC:'Flash Gas Compression',x: 561,  y: 861, },
      {SCRNAME: 'Topside Modules', LV1: 'TOPSIDES', LV2: 'MODULES' , LV3: 'ME02',   LV4: null,   NAME: 'ME02',  NAMEDESC:'MEG & Fule Gas',x: 857,  y: 750, },
      {SCRNAME: 'Topside Modules', LV1: 'TOPSIDES', LV2: 'MODULES' , LV3: 'ME04',   LV4: null,   NAME: 'ME04',  NAMEDESC:'Power Generation',x: 1110, y: 642, },
      {SCRNAME: 'Topside Modules', LV1: 'TOPSIDES', LV2: 'MODULES' , LV3: 'ME05',   LV4: null,   NAME: 'ME05',  NAMEDESC:'E/I Building',x: 1260, y: 310, },
      {SCRNAME: 'Topside Modules', LV1: 'TOPSIDES', LV2: 'MODULES' , LV3: 'MW01',   LV4: null,   NAME: 'MW01',  NAMEDESC:'Recpt. Facil. & Prod. Sepn.',x: 265,  y: 430, },
      {SCRNAME: 'Topside Modules', LV1: 'TOPSIDES', LV2: 'MODULES' , LV3: 'MW02',   LV4: null,   NAME: 'MW02',  NAMEDESC:'HC Dewpt. & Conden. Stabil.',x: 485,  y: 355, },
      {SCRNAME: 'Topside Modules', LV1: 'TOPSIDES', LV2: 'MODULES' , LV3: 'MW04',   LV4: null,   NAME: 'MW04',  NAMEDESC:'Power Generation',x: 710,  y: 235, },
      {SCRNAME: 'Topside Modules', LV1: 'TOPSIDES', LV2: 'MODULES' , LV3: 'MW05',   LV4: null,   NAME: 'MW05',  NAMEDESC:'Utility',x: 988,  y: 185, },
  
      {SCRNAME: 'Topside Common', LV1: 'TOPSIDES',   LV2: 'COMMON' , LV3: 'FL01'  , LV4: 'FL01'  ,NAME: 'FL01'  ,NAMEDESC:'Flare Stack', x: 462,  y: 398, },
      {SCRNAME: 'Topside Common', LV1: 'TOPSIDES',   LV2: 'COMMON' , LV3: 'KO01'  , LV4: 'KO01'  ,NAME: 'KO01'  ,NAMEDESC:'Flare KO Drum', x: 610,  y: 800, },
      {SCRNAME: 'Topside Common', LV1: 'TOPSIDES',   LV2: 'COMMON' , LV3: 'LD00DA', LV4: 'LD00DA',NAME: 'LD00DA',NAMEDESC:'Laydown', x: 1157, y: 235, },

      {SCRNAME:"ME01",            LV1:"TOPSIDES",  LV2:"MODULES",   LV3:"ME01",     LV4:"ME01DA", NAME:"ME01DA",  x: 1040, y: 621,},
      {SCRNAME:"ME01",            LV1:"TOPSIDES",  LV2:"MODULES",   LV3:"ME01",     LV4:"ME01DB", NAME:"ME01DB",  x: 910,  y: 435,},
      {SCRNAME:"ME01",            LV1:"TOPSIDES",  LV2:"MODULES",   LV3:"ME01",     LV4:"ME01DC", NAME:"ME01DC",  x: 765,  y: 245,},
      {SCRNAME:"ME02",            LV1:"TOPSIDES",  LV2:"MODULES",   LV3:"ME02",     LV4:"ME02DA", NAME:"ME02DA",  x: 1050, y: 620,},
      {SCRNAME:"ME02",            LV1:"TOPSIDES",  LV2:"MODULES",   LV3:"ME02",     LV4:"ME02DB", NAME:"ME02DB",  x: 890,  y: 440,},
      {SCRNAME:"ME02",            LV1:"TOPSIDES",  LV2:"MODULES",   LV3:"ME02",     LV4:"ME02DC", NAME:"ME02DC",  x: 720,  y: 270,},
      {SCRNAME:"ME04",            LV1:"TOPSIDES",  LV2:"MODULES",   LV3:"ME04",     LV4:"ME04DA", NAME:"ME04DA",  x: 970, y: 621,},
      {SCRNAME:"ME04",            LV1:"TOPSIDES",  LV2:"MODULES",   LV3:"ME04",     LV4:"ME04DB", NAME:"ME04DB",  x: 830,  y: 417,},
      {SCRNAME:"ME05",            LV1:"TOPSIDES",  LV2:"MODULES",   LV3:"ME05",     LV4:"ME05DA", NAME:"ME05DA",  x: 1173, y: 640,},
      {SCRNAME:"ME05",            LV1:"TOPSIDES",  LV2:"MODULES",   LV3:"ME05",     LV4:"ME05DB", NAME:"ME05DB",  x: 1173, y: 319,},
      {SCRNAME:"ME05",            LV1:"TOPSIDES",  LV2:"MODULES",   LV3:"ME05",     LV4:"ME05DC", NAME:"ME05DC",  x: 408,  y: 650,},
      {SCRNAME:"ME05",            LV1:"TOPSIDES",  LV2:"MODULES",   LV3:"ME05",     LV4:"ME05DD", NAME:"ME05DD",  x: 408,  y: 439,},
      {SCRNAME:"ME05",            LV1:"TOPSIDES",  LV2:"MODULES",   LV3:"ME05",     LV4:"ME05DE", NAME:"ME05DE",  x: 408,  y: 224,},
      {SCRNAME:"MW01",            LV1:"TOPSIDES",  LV2:"MODULES",   LV3:"MW01",     LV4:"MW01DA", NAME:"MW01DA",  x: 1070, y: 727,},
      {SCRNAME:"MW01",            LV1:"TOPSIDES",  LV2:"MODULES",   LV3:"MW01",     LV4:"MW01DB", NAME:"MW01DB",  x: 990,  y: 545,},
      {SCRNAME:"MW01",            LV1:"TOPSIDES",  LV2:"MODULES",   LV3:"MW01",     LV4:"MW01DC", NAME:"MW01DC",  x: 870,  y: 370,},
      {SCRNAME:"MW01",            LV1:"TOPSIDES",  LV2:"MODULES",   LV3:"MW01",     LV4:"MW01DD", NAME:"MW01DD",  x: 725,  y: 195,},
      {SCRNAME:"MW02",            LV1:"TOPSIDES",  LV2:"MODULES",   LV3:"MW02",     LV4:"MW02DA", NAME:"MW02DA",  x: 1109, y: 707,},
      {SCRNAME:"MW02",            LV1:"TOPSIDES",  LV2:"MODULES",   LV3:"MW02",     LV4:"MW02DB", NAME:"MW02DB",  x: 975,  y: 525,},
      {SCRNAME:"MW02",            LV1:"TOPSIDES",  LV2:"MODULES",   LV3:"MW02",     LV4:"MW02DC", NAME:"MW02DC",  x: 820,  y: 355,},
      {SCRNAME:"MW02",            LV1:"TOPSIDES",  LV2:"MODULES",   LV3:"MW02",     LV4:"MW02DD", NAME:"MW02DD",  x: 666,  y: 185,},
      {SCRNAME:"MW04",            LV1:"TOPSIDES",  LV2:"MODULES",   LV3:"MW04",     LV4:"MW04DA", NAME:"MW04DA",  x: 940,  y: 580,},
      {SCRNAME:"MW04",            LV1:"TOPSIDES",  LV2:"MODULES",   LV3:"MW04",     LV4:"MW04DB", NAME:"MW04DB",  x: 790,  y: 377,},
      {SCRNAME:"MW05",            LV1:"TOPSIDES",  LV2:"MODULES",   LV3:"MW05",     LV4:"MW05DA", NAME:"MW05DA",  x: 1037, y: 657,},
      {SCRNAME:"MW05",            LV1:"TOPSIDES",  LV2:"MODULES",   LV3:"MW05",     LV4:"MW05DB", NAME:"MW05DB",  x: 925,  y: 473,},
      {SCRNAME:"MW05",            LV1:"TOPSIDES",  LV2:"MODULES",   LV3:"MW05",     LV4:"MW05DC", NAME:"MW05DC",  x: 810,  y: 290,},
    ],

    Coordinate: [
      {l2: 'HULL-AFT', code: 'RB01-02', x: 70,    y: 50},
      {l2: 'HULL-AFT', code: 'MD00a',   x: 0,     y: 0},
      {l2: 'HULL-FOR', code: 'MD00f',   x: -100,  y: 100},
      {l2: 'HULL-FOR', code: 'VS04-5',  x: 0,     y: 100},
      {l2: 'LQ',       code: 'LQ00',    x: 0,     y: 100},
      {l2: 'LQ',       code: 'HD01',    x: 0,     y: 100},

      {l2: 'HULL-AFT', code: 'AFT',     x: -100,  y: 0},
      {l2: 'HULL-AFT', code: 'PRT',     x: -100,  y: 50},
      {l2: 'HULL-AFT', code: 'STB',     x: -100,  y: 100},
      {l2: 'HULL-AFT', code: 'MDK',     x: 100,   y: 200},
      {l2: 'HULL-AFT', code: 'BLK',     x: 0,     y: 0},
      {l2: 'HULL-AFT', code: 'BTM',     x: 0,     y: 100},

      {l2: 'HULL-FOR', code: 'FOR',     x: -100,  y: 0},
      {l2: 'HULL-FOR', code: 'PRT',     x: 0,     y: 100},
      {l2: 'HULL-FOR', code: 'STB',     x: 0,     y: 100},
      {l2: 'HULL-FOR', code: 'MDK',     x: -100,  y: 200},
      {l2: 'HULL-FOR', code: 'BLK',     x: 0,     y: 100},
      {l2: 'HULL-FOR', code: 'BTM',     x: 50,    y: 100},

      {l2: 'LQ',       code: 'LV01',    x: 0,     y: 0},
      {l2: 'LQ',       code: 'LV23',    x: -100,  y: 0},
      {l2: 'LQ',       code: 'LV45',    x: 0,     y: 50},
      {l2: 'LQ',       code: 'LV67',    x: -100,  y: 0},

      // Topside
      {l2: 'FL01',     code: null,      x: 0,     y: 0},
      {l2: 'KO01',     code: null,      x: 0,     y: 0},
      {l2: 'TSRC01',   code: null,      x: 0,     y: 0},
      {l2: 'TSRC02',   code: null,      x: 0,     y: 0},
      {l2: 'TSRC03',   code: null,      x: 0,     y: 0},
      {l2: 'TSRC04',   code: null,      x: 0,     y: 0},
      {l2: 'TSRC05',   code: null,      x: 0,     y: 0},
      {l2: 'LD00DA',   code: null,      x: 0,     y: 0},

      {l2: 'MW01',     code: null,      x: -100,     y: 0},
      {l2: 'MW02',     code: null,      x: -100,     y: 0},
      {l2: 'MW04',     code: null,      x: -100,     y: 0},
      {l2: 'MW05',     code: null,      x: -100,     y: 0},
      {l2: 'ME01',     code: null,      x: -100,     y: 0},
      {l2: 'ME02',     code: null,      x: -100,     y: 0},
      {l2: 'ME04',     code: null,      x: -100,     y: 0},
      {l2: 'ME05',     code: null,      x: -100,     y: 0},
    ],

    Masks: [
      // Topside
      {mod: 'FL01',    level: 'ME01DA', x: 742, y: 198,   point: '69,0 48,30 59,88 0,633 102,689 204,641 204,556 146,545 125,7'},
      {mod: 'LD00DA',  level: 'ME01DA', x: 719, y: 494,   point: '0,90 8,122 99,154 251,51 251,9 158,0 45,51'},
      {mod: 'MW01',    level: 'MW01DA', x: 755, y: 709,   point: '0,64.33 176.83,0 285,62 285,87 118.25,151.33 0,89.67'},
      {mod: 'MW01',    level: 'MW01DB', x: 688, y: 578,   point: '0,57.5 155.5,-0.08 254,55.33 254,111 101.83,174 0,120'},
      {mod: 'MW01',    level: 'MW01DC', x: 611, y: 461,   point: '1,54.17 145.17,0.08 247,54 247,94 93.5,153.67 1,108'},
      {mod: 'MW01',    level: 'MW01DD', x: 544, y: 330,   point: '2,51.83 124.67,0.08 211,44.67 211,89 71.67,141.67 2,102'},
      {mod: 'MW02',    level: 'MW02DA', x: 790, y: 684,   point: '2,100.5 45.17,37.58 156.42,-0.04 276,88.67 276,122 97.83,188.67 2,138'},
      {mod: 'MW02',    level: 'MW02DB', x: 657, y: 561,   point: '2,60.83 171,0.08 228,40.91 272,46 272,108 98.33,176.33 2,120'},
      {mod: 'MW02',    level: 'MW02DC', x: 571, y: 445,   point: '1,61.83 161.17,0.08 252,53.67 252,95 97.5,154 1,103.33'},
      {mod: 'MW02',    level: 'MW02DD', x: 505, y: 328,   point: '1,37.67 114.83,0 208,52 208,102 79.5,152.67 1,113.33'},
      {mod: 'MW04',    level: 'MW04DA', x: 707, y: 597,   point: '1.33,132.33 26.33,33 110.5,1.33 149,55.33 221,108.33 221,143 105,187.67'},
      {mod: 'MW04',    level: 'MW04DB', x: 565, y: 316,   point: '1,218.17 44.17,185.92 64.58,21.63 145.29,-0.02 157.98,123.32 207,242.33 207,281 101.83,327.67 1,270'},
      {mod: 'MW05',    level: 'MW05DA', x: 737, y: 656,   point: '1,69.5 167.5,1.75 269,65 269,94 98.83,164.67 1,108.33'},
      {mod: 'MW05',    level: 'MW05DB', x: 632, y: 491,   point: '2,94.17 169,29.08 179.33,0 215,0 215,55 272,89 272,145 101.75,211.67 2,153.67'},
      {mod: 'MW05',    level: 'MW05DC', x: 526, y: 364,   point: '1,98.83 139.83,44.92 151.58,1.96 243.96,12.31 245.31,63.66 277,83.67 277,129 104.5,196 1,137.33'},
      {mod: 'ME01',    level: 'ME01DA', x: 713, y: 654,   point: '0,76.063 182.901,-0.018 305,65.535 305,88 123.747,158'},
      {mod: 'ME01',    level: 'ME01DB', x: 655, y: 525,   point: '0,59.33 144.83,0 251,62.67 251,107 93.17,170.33 0,125'},
      {mod: 'ME01',    level: 'ME01DC', x: 550, y: 394,   point: '2,61.5 165.33,0.08 259,55 259,103 98.67,170 2,113'},
      {mod: 'ME02',    level: 'ME02DA', x: 764, y: 611,   point: '1,154.17 135,93.33 135,30 153,2.33 178,0 243.33,28 264,135.33 303.67,162 95.33,242 1,189.33'},
      {mod: 'ME02',    level: 'ME02DB', x: 649, y: 569,   point: '0,64.67 136.17,1.33 241,66 241,114 107.17,165 0,107'},
      {mod: 'ME02',    level: 'ME02DC', x: 544, y: 437,   point: '1,55.33 124.5,0 213,51.67 213,113 101.83,156 1,106'},
      {mod: 'ME04',    level: 'ME04DA', x: 734, y: 661,   point: '1,56.33 127.5,0 193.92,18.33 234.96,100 98.81,153 1,94.67'},
      {mod: 'ME04',    level: 'ME04DB', x: 626, y: 366,   point: '2,217.5 44,125.67 44,34 81,0 137.33,2.67 156.67,203.33 200,231.33 200,271 84.5,319 2,268'},
      {mod: 'ME05',    level: 'ME05DA', x: 889, y: 635,   point: '1,60.33 165.5,0 250,46 250,87 101.83,138.33 1,83'},
      {mod: 'ME05',    level: 'ME05DB', x: 920, y: 319,   point: '2,45.67 121.17,0 206,41 206,91 82.17,126 2,85.33'},
      {mod: 'ME05',    level: 'ME05DC', x: 640, y: 617,   point: '1.17,68 19.67,5 39,5 48.67,28 138.67,0 226,35.33 226,65 91.67,115'},
      {mod: 'ME05',    level: 'ME05DD', x: 643, y: 437,   point: '2,49.5 126.17,0.08 219,49.33 219,82 90.17,132 2,84.67'},
      {mod: 'ME05',    level: 'ME05DE', x: 641, y: 251,   point: '0,42.83 94.67,0.08 180,47.67 180,92 90,125.33 0,79.33'},
    ]
  }),

  computed: {
  },

  methods: {
    setStyles() {

      let lightBlue = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `shadowTimeline`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      lightBlue.append('stop').attr('stop-color', '#3d3d3d').attr('offset', '0').attr('stop-opacity', 0.5)
      lightBlue.append('stop').attr('stop-color', '#fff').attr('offset', '1').attr('stop-opacity', 0.3)

      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['LightGray'], [`LightGray`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['YellowGreen'], [`YellowGreen`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['LightBlue'], [`LightBlue`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['Pink'], [`Pink`, 0.5, 1.0, 0.5, -0.2], this.localId)
      
      
    },

  }
}