import * as d3 from 'd3'

export default {
  methods: {
    draw_Legends() {
      let Legends = this.SkylineCharts
      .append('g')
      .attr('transform', `translate(${this.skyline.legend.x},${this.skyline.legend.y})`)

      // Background for whole the legend contents
      Legends
      .append('path')
      .attr('d', `
        M-10,-20
        L450,-20
        C550,300,350,300,-10,300
        L-10,450
        Z
      `)
      .attr('fill', '#fff')
    

      Legends
      .append('text')
      .attr('transform', `translate(0, 0)`)
      .style('font-family', 'roboto').style('font-size', 24).style('fill', '#44A9DF').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(`Skyline Chart of Block Assembly`)
      .text(`Block Skyline Chart ${this.FilterValues && this.FilterValues.Activity ? 'for ' + this.FilterValues.Activity : ''}`)

      Legends
      .append('text')
      .attr('transform', `translate(0, 20)`)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#bcbcbc').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(`Datasource:`)

      Legends
      .append('text')
      .attr('transform', `translate(65, 20)`)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#bcbcbc').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(this.Queries.SQL1[0].DESC)

      Legends
      .append('text')
      .attr('transform', `translate(65, 32)`)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#bcbcbc').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(this.Queries.SQL2[0].DESC)
     
      // this.putFilters()
      this.putSearch()
      this.putClear()
    },

    putClear() {
      let clearArea_ = this.SkylineCharts
      .append('g')
      .attr('transform', `translate(10,260)`)
      .attr('class', 'clearBox')
      .style('opacity', 0)


      clearArea_
      .append('text')
      .attr('transform', `translate(136, 22)`)
      .style('font-family', this.skyline.legend.font)
      .style('font-size', this.skyline.legend.group.tSize)
      .style('fill', this.skyline.legend.group.tColot)
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .text('X')
      .style('cursor', 'pointer')
      .style('opacity', 1)
      .on('mouseover', (_, i, a) => { d3.select(a[i]).transition().duration(100).style('opacity', .7) })
      .on('mouseout', (_, i, a) => { d3.select(a[i]).transition().duration(100).style('opacity', .3) })
      .on('click', () => { this.textClear() })
    },

    putSearch() {
      let searchArea_ = this.SkylineCharts
      .append('g')
      .attr('transform', `translate(10, 260)`)

      searchArea_
      .append('rect')
      .attr('rx', 4)
      .attr('ry', 4)
      .attr('width', 181)
      .attr('height', 40)
      .attr('fill-opacity', 0)
      .attr('stroke', '#dadada')
      .attr('stroke-width', .5)

      searchArea_
      .append('rect')
      .attr('transform', `translate(10, -5)`)
      .attr('width', 55)
      .attr('height', 10)
      .attr('fill', '#fff')

      searchArea_
      .append('text')
      .attr('transform', `translate(20, 1)`)
      .style('font-family', this.skyline.legend.font)
      .style('font-size', this.skyline.legend.group.tSize)
      .style('fill', this.skyline.legend.group.tColot)
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .text('Search')

      searchArea_
      .append('rect')
      .attr('transform', `translate(15, 11)`)
      .attr('width', 150)
      .attr('height', 18)
      .attr('fill', '#fff')
      .attr('stroke', '#dadada')
      .attr('stroke-width', .5)

      // ### draw search input in the search group box
      searchArea_
      .append("foreignObject")
      .attr('x', 15)
      .attr('y', 11)
      .attr('width', 150)
      .attr('height', 18)
      .append('xhtml:div')
      .append('div')
      .attr('id', 'search_input')     // for the javascript document.getElementById
      .attr('class', 'search_input')  // for the d3 general purpose
      .attr('contentEditable', true)
      .attr('style', `padding: 2px 5px; font-size: 10px; line-height: 14px; text-align: left; color: ${this.skyline.legend.item.tColor}`)
      .call(this.callFuncSearchInput)

      // mask for the icon background area
      searchArea_
      .append('rect')
      .attr('transform', `translate(140, 12)`)
      .attr('width', 24)
      .attr('height', 16)
      .attr('fill', '#fff')

      searchArea_
      .append('image') 
      .attr('transform', `translate(148,14)`)
      .attr('xlink:href', require('../../../../../../src/assets/svg/icons/iconmonstr-magnifier-2.svg'))
      .attr('width', 12)
      .attr('height', 12)
      .style('cursor', 'pointer')
      .style('opacity', .3)
      .on('mouseover', (_, i, a) => { d3.select(a[i]).transition().duration(100).style('opacity', .7) })
      .on('mouseout', (_, i, a) => { d3.select(a[i]).transition().duration(100).style('opacity', .3) })
      .on('click', () => { this.search() })

    },
    putFilters() {
      let filterArea_ = this.SkylineCharts
      .append('g')
      .attr('transform', `translate(${this.skyline.legend.x}, 180)`)

      Object.keys(this.filterCodes).forEach((k_, i) => {
        let filterItem_ = filterArea_
        .append('g')
        .attr('transform', `translate(${this.filterCodes[k_].attrs.x},${this.filterCodes[k_].attrs.y})`)

        filterItem_
        .append('rect')
        .attr('rx', 4)
        .attr('ry', 4)
        .attr('width', this.filterCodes[k_].attrs.width)
        .attr('height', this.filterCodes[k_].attrs.height)
        .attr('fill-opacity', 0)
        .attr('stroke', '#dadada')
        .attr('stroke-width', .5)

        filterItem_
        .append('rect')
        .attr('transform', `translate(10, -5)`)
        .attr('width', this.filterCodes[k_].attrs.labelWidth)
        .attr('height', 10)
        .attr('fill', '#fff')

        filterItem_
        .append('text')
        .attr('transform', `translate(20, 1)`)
        .style('font-family', this.skyline.legend.font)
        .style('font-size', this.skyline.legend.group.tSize)
        .style('fill', this.skyline.legend.group.tColot)
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text(k_)

        let xGap_ = 20 + 30
        this.filterCodes[k_].values.forEach((v_, j) => {
          filterItem_
          .append('circle')
          .attr('class', `option_circle__${i}__${j}`)
          .attr('cx', 15 + xGap_ * j)
          .attr('cy', 20)
          .attr('r', 3)
          .style('stroke', v_.on ? this.styleFilter.selected.stroke : this.styleFilter.out.stroke)
          .style('stroke-width', .5)
          .style('fill', v_.on ? this.styleFilter.selected.fill : this.styleFilter.out.fill)

          filterItem_
          .append('text')
          .attr('class', `option_text__${i}__${j}`)
          .attr('transform', `translate(${22 + xGap_ * j + 1}, 21)`)
          .style('font-family', this.skyline.legend.font)
          .style('font-size', this.skyline.legend.item.tSize)
          .style('fill', v_.on ? this.styleFilter.selected.color : this.styleFilter.out.color)
          .attr('text-anchor', 'start')
          .attr('alignment-baseline', 'middle')
          .text(v_.text)

          filterItem_
          .datum({ filters: this.filterCodes, name: k_, type: this.filterCodes[k_].attrs.type, ...v_})
          .append('rect')
          .attr('id', `option_mask__${i}__${j}`)
          .attr('transform', `translate(${20 + xGap_ * j - 8}, 12)`)
          .attr('width', this.filterCodes[k_].attrs.textWidth)
          .attr('height', 16)
          .attr('fill', '#000')
          .attr('fill-opacity', 0)
          .call(this.callFuncFilter)

          if(v_.on) this.setFilter(this.filterCodes[k_].attrs.type, v_.colName, v_.value)
        })
      })
    },

    // Event Call Functions ---------------------------------------------
    callFuncSearchFilter(selection) {
      selection
      .on('mouseover', (_, i, a) => {
        d3.select(a[i]).style('cursor', 'pointer')

        let directionEls_ = d3.select(a[i]).attr('id').split('__')

        d3.select(`#${this.localId}`)
        .select(`.search_circle__${directionEls_[1]}`)
        .style('stroke', this.styleFilter.over.stroke)
        .style('fill', this.styleFilter.over.fill)

        d3.select(`#${this.localId}`)
        .select(`.search_text__${directionEls_[1]}`)
        .style('fill', '#000')
      })
      .on('mouseout', (d, i, a) => { 
        d3.select(a[i]).style('cursor', 'default')

        let index_ = d.filter.values.findIndex(v_ => v_.text == d.text)
        let values_ = JSON.parse(JSON.stringify(d.filter.values))
        let directionEls_ = d3.select(a[i]).attr('id').split('__')

        if(values_[index_].on) {
          d3.select(`#${this.localId}`)
          .select(`.search_circle__${directionEls_[1]}`)
          .style('stroke', this.styleFilter.selected.stroke)
          .style('fill', this.styleFilter.selected.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.search_text__${directionEls_[1]}`)
          .style('fill', this.styleFilter.selected.color)
          
        } else {
          d3.select(`#${this.localId}`)
          .select(`.search_circle__${directionEls_[1]}`)
          .style('stroke', this.styleFilter.out.stroke)
          .style('fill', this.styleFilter.out.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.search_text__${directionEls_[1]}`)
          .style('fill', this.styleFilter.out.color)
        }
      })
      .on('click', (d, i, a) => {
        let index_ = d.filter.values.findIndex(v_ => v_.text == d.text)
        let values_ = JSON.parse(JSON.stringify(d.filter.values))
        let length_ = values_.length

        var prevIndex_ = d.filter.values.findIndex(v_ => v_.on)
        for(let i = 0; i<length_; i++) {
          if(values_[i].text == d.text) values_[i].on = true
          else values_[i].on = false
        } 

        d.filter.values = values_

        if(prevIndex_ != index_) {
          d3.select(`#${this.localId}`)
          .select(`.search_circle__${prevIndex_}`)
          .style('stroke', this.styleFilter.out.stroke)
          .style('fill', this.styleFilter.out.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.search_text__${prevIndex_}`)
          .style('fill', this.styleFilter.out.color)
        }

        if(values_[index_].on) {
          d3.select(`#${this.localId}`)
          .select(`.search_circle__${index_}`)
          .style('stroke', this.styleFilter.selected.stroke)
          .style('fill', this.styleFilter.selected.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.search_text__${index_}`)
          .style('fill', this.styleFilter.selected.color)
          
        } else {
          d3.select(`#${this.localId}`)
          .select(`.search_circle__${index_}`)
          .style('stroke', this.styleFilter.out.stroke)
          .style('fill', this.styleFilter.out.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.search_text__${index_}`)
          .style('fill', this.styleFilter.out.color)
        }

        d3.select(`#${this.localId}`).select('.search_input').text('')
        this.search(false)
        this.Chart_filtering()
      })
    },
    callFuncSearchInput(selection) {
      selection
      .on('keydown', (_, i, a) => {
        if(d3.event.keyCode === 13){
          // declared in Declares.mixins
          this.trimDivText(d3.select(a[i]))   
          setTimeout(() => { this.setCaret(document.getElementById('search_input')) })
        }
        // declared in Declares.mixins
        this.search()
      })
      .on('keyup', (_, i, a) => {
        if(d3.event.keyCode === 13){
          this.trimDivText(d3.select(a[i]))
          this.setCaret(document.getElementById('search_input'))
        }
      })
    },
    callFuncLegend(selection) {
      selection
      .on('click', (d, i, a) => {
        d.on = !d.on
        if(d.on) d3.select(a[i]).style('opacity', 1)
        else d3.select(a[i]).style('opacity', 0.3)

        this.setFilter('multi', d.refColumn, d.codeValue)
        this.search(false)
        this.Chart_filtering()
      })
    },
    callFuncFilter(selection) {
      selection
      .on('mouseover', (_, i, a) => {
        d3.select(a[i]).style('cursor', 'pointer')

        let directionEls_ = d3.select(a[i]).attr('id').split('__')

        d3.select(`#${this.localId}`)
        .select(`.option_circle__${directionEls_[1]}__${directionEls_[2]}`)
        .style('stroke', this.styleFilter.over.stroke)
        .style('fill', this.styleFilter.over.fill)

        d3.select(`#${this.localId}`)
        .select(`.option_text__${directionEls_[1]}__${directionEls_[2]}`)
        .style('fill', '#000')
      })
      .on('mouseout', (d, i, a) => { 
        d3.select(a[i]).style('cursor', 'default')

        let index_ = d.filters[d.name].values.findIndex(v_ => v_.text == d.text)
        let values_ = JSON.parse(JSON.stringify(d.filters[d.name].values))
        let directionEls_ = d3.select(a[i]).attr('id').split('__')

        if(values_[index_].on) {
          d3.select(`#${this.localId}`)
          .select(`.option_circle__${directionEls_[1]}__${directionEls_[2]}`)
          .style('stroke', this.styleFilter.selected.stroke)
          .style('fill', this.styleFilter.selected.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.option_text__${directionEls_[1]}__${directionEls_[2]}`)
          .style('fill', this.styleFilter.selected.color)
          
        } else {
          d3.select(`#${this.localId}`)
          .select(`.option_circle__${directionEls_[1]}__${directionEls_[2]}`)
          .style('stroke', this.styleFilter.out.stroke)
          .style('fill', this.styleFilter.out.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.option_text__${directionEls_[1]}__${directionEls_[2]}`)
          .style('fill', this.styleFilter.out.color)
        }
      })
      .on('click', (d, i, a) => {
        let index_ = d.filters[d.name].values.findIndex(v_ => v_.text == d.text)
        let values_ = JSON.parse(JSON.stringify(d.filters[d.name].values))
        let length_ = values_.length

        if(d.type == 'single') {
          var prevIndex_ = d.filters[d.name].values.findIndex(v_ => v_.on)
          for(let i = 0; i<length_; i++) {
            if(values_[i].text == d.text) values_[i].on = true
            else values_[i].on = false
          } 
        } else {
          values_[index_].on = !values_[index_].on
        }

        d.filters[d.name].values = values_

        let directionEls_ = d3.select(a[i]).attr('id').split('__')

        if(prevIndex_ >= 0 && prevIndex_ != index_) {
          d3.select(`#${this.localId}`)
          .select(`.option_circle__${directionEls_[1]}__${prevIndex_}`)
          .style('stroke', this.styleFilter.out.stroke)
          .style('fill', this.styleFilter.out.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.option_text__${directionEls_[1]}__${prevIndex_}`)
          .style('fill', this.styleFilter.out.color)
        }

        if(values_[index_].on) {
          d3.select(`#${this.localId}`)
          .select(`.option_circle__${directionEls_[1]}__${directionEls_[2]}`)
          .style('stroke', this.styleFilter.selected.stroke)
          .style('fill', this.styleFilter.selected.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.option_text__${directionEls_[1]}__${directionEls_[2]}`)
          .style('fill', this.styleFilter.selected.color)
          
        } else {
          d3.select(`#${this.localId}`)
          .select(`.option_circle__${directionEls_[1]}__${directionEls_[2]}`)
          .style('stroke', this.styleFilter.out.stroke)
          .style('fill', this.styleFilter.out.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.option_text__${directionEls_[1]}__${directionEls_[2]}`)
          .style('fill', this.styleFilter.out.color)
        }

        if(d.colName == 'DATE') {
          if(this.activity == d.value) return 

          this.activity = d.value
          this.activityChanged()

        } else {
          this.setFilter(d.type, d.colName, d.value)
          this.search(false)
          this.Chart_filtering()
        }
      })
    },
  }
}
