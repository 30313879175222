export default {
  width: 150,
  gab: 10,
  attrs: [
    { 
      text1: 'Total Subsystems',
      // text2: 'with Loops',
      refColumn: 'TOTAL',
      codeValue   : 'Total', // fixed
      fill : 'lightGray',
      fColor: 'Transparent', 
      tColor: '#212121',
      on: true
    },
    { 
      text1: 'Subsystems with',
      text2: 'No MC Started',
      refColumn: 'NOTSTARTED',
      codeValue   : 'Subsystems with No MC Started',
      fill : 'fillWhite',
      fColor: '#BDBCBC', 
      tColor: '#818181',
      on: true
    },
    { 
      text1: 'Subsystems with',
      text2: 'MC Started',
      refColumn: 'APROGRESS',
      codeValue   : 'Subsystems with MC Started',
      fill : 'fillYellow',
      fColor: '#FFD600',
      tColor: '#fff',
      on: true
    },
    { 
      text1: 'Subsystems with',
      text2: 'Partial MC Complete',
      refColumn: 'PAR_MC',
      codeValue   : 'Subsystems with Partial MC Complete',
      fill : 'fillLightGreen',
      fColor: '#8BC34A',
      tColor: '#fff',
      on: true
    },
    { 
      text1: 'Subsystems with',
      text2: 'Full MC Complte',
      refColumn: 'FULL_MC',
      codeValue   : 'Subsystems with Full MC Complete',
      fill : 'fillGreen',
      fColor: '#43A047',
      tColor: '#fff',
      on: true
    }
  ]
}
