
import __C from '../../../../includes/primitives/_constant_'
import Filter from '../../../../includes/primitives/Filter_Defs'

export default {
  data: () => ({
    // Scales
    scaleXInfo: {
      scale: null,
      tickValues: []
    },
    scaleYInfo: {
      scale: null,
      tickValues: []
    },

    // Common
    setThickness: 0,
    setDistance: 0,
    drawPoint_x: 0,
    drawPoint_y: 0,
    get_BarLength: 0,
  }),
  computed: {
    __C_() { return __C },

    ready2draw() {
      // console.log('------------------------------')
      // console.log('this.ChartType', this.ChartType)
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)
      // console.log('Object.keys(this.Title).length', Object.keys(this.Title).length)
      // console.log('Object.keys(this.Bar).length', Object.keys(this.Bar).length)
      // console.log('Object.keys(this.Value).length', Object.keys(this.Value).length)
      // console.log('Object.keys(this.Note).length', Object.keys(this.Note).length)
      return  this.ChartType &&
              this.DataItems.length > 0 &&
              Object.keys(this.Canvas).length > 0 &&
              Object.keys(this.Title).length > 0 &&
              Object.keys(this.Bar).length > 0 &&
              Object.keys(this.Value).length > 0 &&
              Object.keys(this.Note).length > 0
    },
  },
  methods: {
    setDefaultValues() {
      Filter.setDefs_DropShadow(this.svg, `${this.localId}__DropShadow`, .75, .2 , 1, 1)

      if(this.DataItems.length === 0) return
      
      if (this.Bar.BarAutoSize == 'Y') {
        this.setThickness = this.Canvas.CanvasChartHeight / this.DataItems.length - this.Bar.BarDistance
      } else if (this.Bar.BarAutoSize == 'N'){
        this.setThickness = this.Bar.BarThickness
      }
      this.drawPoint_y = this.Canvas.CanvasChartY

      // this.setThickness = 0

      let dataLength = this.DataItems.length

      // Set shared colorset & type -------------------------------
      // ColorSet cannot only be a 'this.Bar.BarColorSet', other
      // colorset could be invoked for thier chart type.
      // Therefore the current colorset & its type should be shared
      // to the globally common variable to correctly run the global
      // drawings.

      // If the ColorSet's colors are less than the Data length, 
      // number of the color should be set as much as the length 
      // of the data. If not, NOT FOUND error will be occured.
      this.SharedColorSet = (
        dataLength > this.Bar.BarColorSet.length ?
        Array.from({ length: dataLength }, (_, i) => this.Bar.BarColorSet[i % this.Bar.BarColorSet.length]) :
        this.Bar.BarColorSet
      )
      this.SharedColorSetType = this.Bar.BarColorType
      // ----------------------------------------------------------
    },
    setScaleXInfo() {
    },
    setScaleYInfo() {
    },
  }
}