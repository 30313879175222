<template>
  <div :id="localId"></div>
</template>

<script>
import * as d3 from 'd3'
import mx_Core from './includes/mixins/importer'            // Global & Local Mixins

export default {
  name: "j-chart-donut",
  mixins: [mx_Core],
  data: () =>  ({
    counters: '',
    scrollLeft: 0
  }),
  props: {
    id: String,
    
    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },
    DataItems: { type: Array, default: () => ([]) },
    Queries: {type: Object, default: () => ({}) },
    ColumnProps: { type: Array, default: () => ([]) },
    values: {
      type: Object,
      default: () => ({})
    },
    FilterValues: {
      type: Object,
      default: () => ({})
    },
  },
  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
  },
  mounted() {
    this.register(this.draw)

    this.counters = setInterval(() => {
      this.scrollLeft = document.getElementById('contentScrollTarget').scrollLeft
    }, 1000)
  },

  methods: {
    draw() {
      // Check all the necessary values are available
      if(!this.ready2draw) return

      this.clear()
      this.setStyles()
      this.setData()
      
      let dataSet = JSON.parse(JSON.stringify(this.DataItems.filter(f => !!f[this.activity])))

      dataSet.forEach(d => {
        if (d.RFDC && d.RFDC.length > 10) {
          this.maxValue = d.RFDC.length
        }
      })
      let daterange = [
        { [this.activity]: dataSet.length > 0 ? new Date(Math.min.apply(null,dataSet.map(d => new Date(d[this.activity])))) : '2021-06-11' },
        { [this.activity]: dataSet.length > 0 ? new Date(Math.max.apply(null,dataSet.map(d => new Date(d[this.activity])))) : '2022-04-08' },
      ]
      if (daterange[1][this.activity] < new Date(this.Queries.SQL2[0].CDATE)) daterange[1][this.activity] = new Date(this.Queries.SQL2[0].CDATE)


      // if (this.maxValue != 0 ) {
      //   let fixed_width = 47
      //   this.skyline.box.width = parseInt((fixed_width * this.maxValue) / 7)
      // }  

      this.setDefaultValues(daterange)   
      this.drawCanvas()
      this.drawChart()
      this.drawTexts()
      
      // 'complete' must be executed to finish the parent process.
      // It sends 'this.localId' & XmlString for some case to make 
      // displaying image easy.
      this.complete()
      // this.chartMounted = true
    },
    drawChart() {
      this.SkylineCharts = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0.5)`)

        this.draw_Table()
        this.draw_Timeline()
        this.draw_Charts()
        this.draw_Legends()
        this.drawDonuts()
        this.postComplete()

    },
    postComplete() {
      let chart_ = d3.select('#skyline_boxes_timeline').node().getBoundingClientRect()
      let width_ = chart_.width + 400
      let height_ = this.realHeight + 300

      if (width_ < 950) width_ = 1225

      this.resizableCanvasWidth = width_
      this.resizableCanvasHeight = height_
      this.resizeCanvas()

      let dimention = { 
        width: Math.ceil(width_), 
        height: Math.ceil(height_), 
        isreal: true, 
      }
      this.$emit('resize', dimention)
    }
  },
  destroyed(){
    clearInterval(this.counters)
  },
}
</script>