import * as d3 from 'd3'

export default {
  data: () => ({
    innerSvg: null,
  }),
  computed: {
    ready2draw() {

      // console.log('------------------------------')
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)

      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
    itemsSubcontract() { return this.DataItems[0].Subcontract },
    itemsSupervision() { return this.DataItems[0].Supervision },
    itemsQualityStation() { return this.DataItems[0].QualityStation },
  },
  methods: {
    setDefaultValue() {
      d3.selection.prototype.moveToFront = function() {
        return this.each(function(){
          this.parentNode.appendChild(this)
        })
      }
    }
  }
}