export default {
  width: 120,
  gab: 10,
  attrs: [
    { 
      text: 'Total',
      refColumn: 'TOTAL',
      codeValue   : 'Total', // fixed
      fill : 'lightGray', 
      fColor: 'Transparent', 
      tColor: '#212121',
      on: true
    },
    { 
      text: 'To Go',
      refColumn: 'TOGO',
      codeValue   : 'To Go',
      fill : 'fillOrange', 
      fColor: '#FF6D00',
      bColor: '#FF6D00',
      tColor: '#fff',
      on: false
    },
    { 
      text: 'At Risk',
      refColumn: 'ATRISK',
      codeValue   : 'At Risk',
      fill : 'fillYellow', 
      fColor: '#FFD600',
      bColor: '#FFAB00',
      tColor: '#000',
      on: false
    },
    { 
      text: 'Delayed',
      refColumn: 'DELAYED',
      codeValue   : 'Delayed',
      fill : 'fillRed', 
      fColor: '#D50000',
      bColor: '#D50000',
      tColor: '#fff',
      on: false
    },
    { 
      text: 'Completed',
      refColumn: 'COMPLETED',
      codeValue   : 'Completed',
      fill : 'fillLightGreen', 
      fColor: '#8BC34A',
      bColor: '#8BC34A',
      tColor: '#fff',
      on: false
    },
  ]
}
