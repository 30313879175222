// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  methods: {
    
    drawBackElements() {
      if (this.ChartType != 'Compare') return

      let ShadowWidth = 70
      let Shadow = this.svg
        .append('defs')
        .append('radialGradient').attr('id', `${this.localId}__Shadow`)
        Shadow.append('stop').attr('stop-color', '#a8a8a8').attr('offset', '0.00').attr('stop-opacity', '1')
        Shadow.append('stop').attr('stop-color', '#eaeaea').attr('offset', '0.63').attr('stop-opacity', '1')
        Shadow.append('stop').attr('stop-color', '#ffffff').attr('offset', '1.00').attr('stop-opacity', '1')

      this.svg // Shadow Left
        .append('ellipse')
        .attr('cx', this.Canvas.CanvasChartX + (this.Canvas.CanvasChartWidth / 2) - (this.Compare.CompareAreaWidth / 2))
        .attr('cy', this.Canvas.CanvasChartY - this.Compare.CompareAreaSpace + (this.Compare.CompareAreaHeight / 2))
        .attr('rx', ShadowWidth / 2)
        .attr('ry', this.Compare.CompareAreaHeight / 2)
        .attr('fill', `url(#${this.localId}__Shadow)`)

      this.svg // Shadow Right
        .append('ellipse')
        .attr('cx', this.Canvas.CanvasChartX + (this.Canvas.CanvasChartWidth / 2) + (this.Compare.CompareAreaWidth / 2))
        .attr('cy', this.Canvas.CanvasChartY - this.Compare.CompareAreaSpace + (this.Compare.CompareAreaHeight / 2))
        .attr('rx', ShadowWidth / 2)
        .attr('ry', this.Compare.CompareAreaHeight / 2)
        .attr('fill', `url(#${this.localId}__Shadow)`)

      this.svg // // Comparison Area
        .append('rect')
        .attr('x', this.Canvas.CanvasChartX + (this.Canvas.CanvasChartWidth / 2) - (this.Compare.CompareAreaWidth / 2))
        .attr('y', this.Canvas.CanvasChartY - this.Compare.CompareAreaSpace)
        .attr('width', this.Compare.CompareAreaWidth)
        .attr('height', this.Compare.CompareAreaHeight)
        .attr('stroke', '#bebebe')
        .attr('stroke-width', 0)
        .attr('fill', '#ffffff')
    },


    drawFrontElements() {
      if (this.ChartType != this.__C_.CHART.TYPE_CODE_COMPARATIVE) return

      // VS
      this.svg 
        .append('text')
        .attr('x', this.Canvas.CanvasChartX + (this.Canvas.CanvasChartWidth / 2))
        .attr('y', this.Canvas.CanvasChartY - (this.Compare.CompareAreaHeight / 2))
        .style('font-size', 18)
        .style('font-weight', 600)
        .style('font-family', this.Bar.CompareTitleFont)
        .attr('fill', '#EC407A')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        // .attr('alignment-baseline', 'hanging')
        .text('VS')

      // ----------------------------------------------- Title
      this.svg // Left
        .append('text')
        .attr('class', this.Compare.CompareTitleStyle)
        .attr('x', this.Canvas.CanvasChartX + ((this.Canvas.CanvasChartWidth - this.Compare.CompareAreaWidth) / 2) / 2)
        .attr('y', this.Canvas.CanvasChartY - (this.Compare.CompareAreaSpace / 2))
        .style('font-size', this.Compare.CompareTitleSize)
        .style('font-family', this.Compare.CompareTitleFont)
        .attr('fill', this.Compare.CompareTitleColor)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(this.Compare.CompareTitleLeft)

      this.svg // Right
        .append('text')
        .attr('class', this.Compare.CompareTitleStyle)
        .attr('x', this.Canvas.CanvasChartX + this.Canvas.CanvasChartWidth - ((this.Canvas.CanvasChartWidth - this.Compare.CompareAreaWidth) / 2) / 2)
        .attr('y', this.Canvas.CanvasChartY - (this.Compare.CompareAreaSpace / 2))
        .style('font-size', this.Compare.CompareTitleSize)
        .style('font-size', this.Compare.CompareTitleSize)
        .style('font-family', this.Compare.CompareTitleFont)
        .attr('fill', this.Compare.CompareTitleColor)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(this.Compare.CompareTitleRight)
    },



  }
}
