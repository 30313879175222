<template>
  <div :id="localId"></div>
</template>

<script>
import * as d3 from 'd3'
import mx_Core from './includes/mixins/importer'            // Global & Local Mixins

export default {
  name: "lqcom-omg",
  mixins: [mx_Core],
  props: {
    id: String,
    
    FilterValues: {
      type: Object,
      default: () => ({})
    },
    
    // Chart Data
    Canvas: { type: Object, default: () => ({}) },

    Text: { type: Object, default: () => ({}) },

    // Database
    DataItems: { type: Array, default: () => ([]) },
    Queries: {type: Object, default: () => ({}) },
    ColumnProps: { type: Array, default: () => ([]) },

  },
  watch: {
    FilterValues(val) {
      // d3.select('#'+this.localId).selectAll('.progressbar-contents').remove()
      d3.select('#'+this.localId).selectAll('.table_wrap').remove()
      d3.select('#'+this.localId).selectAll('.donut_wrap').remove()
      d3.select('#'+this.localId).selectAll('.donut_wrap2').remove()
    }
  },
  created() {
    this.localId = this.id || 'commodity_summary_' + this.safeId('')
  },
  mounted() {
    this.register(this.draw)
  },

  methods: {
    draw() {
      // Check all the necessary values are available
      // console.log(this.DataItems)
      // if (this.DataItems.length == 0){
      //   this.drawCanvas()
      //   this.DrawNa()
      // } else{
        this.clear()
        this.setDefaultValues()
        this.drawTexts()
        this.drawCanvas()
        this.Draw_Charts()
        // this.drawTable()
        // this.draw_Timeline2021()

        // 'complete' must be executed to finish the parent process.
        // It sends 'this.localId' & XmlString for some case to make 
        // displaying image easy.
        this.complete()
        this.postComplete()

      // }

    },
    //svg height size 
    postComplete() {
      if (this.DataItems.length == 0) return
      let chart_ = d3.select('#commodity_summary_table_').node().getBoundingClientRect()

      let height_ = chart_.height + 300
      let width_ =chart_.width+50

      this.resizableCanvasHeight = height_
      this.resizeCanvas()

      let dimention = { 
        // width: Math.ceil(chart_.width), 
        width: Math.ceil(width_), 
        height: Math.ceil(height_), 
        isreal: true, 
      }
      this.$emit('resize', dimention)
    }

  }
}
</script>
