
import * as d3 from 'd3'
export default {
  methods: {
    
    setEvent_PDF() {
      String.prototype.replaceAll = function(org, dest) {
        return this.split(org).join(dest);
      }
      let convert = this.getSelectItems.replaceAll('},' , '}|')
      
      let dataSet = convert.split('|')
      // console.log(dataSet)
      dataSet.forEach((getData, k ) => {
        
        let index = JSON.parse(getData)

        //여기 까지가 기본 골격
        if (index.level == 0 && index.stage == 'Rooms' && k == 0 ) {
          //
        } else {
          this.removeSvg(this.CANVAS, 1000)
          
          // Title 이랑 data랑 다르기 때문에 임의로 이렇게 바꿔준다
          d3.select(`#Title`)
          .text(() => index.level == 'MS03'? 'Electrical Rooms - MS 3rd Level' 
          : index.level == 'MS01'? 'Instrument Room - MS 1st Level'
          : index.level == 'FOR' ? 'Electrical Room - LQ' 
          : index.level == 'LQ01' ? 'Central Contorl Room(CCR)' 
          : index.level == 'LQ02' ? 'Central Equipment Room(CER)'
          : index.level == 'LQ06' ? 'Telecom Equipment Room(TER)'
          : index.level == 'ME05DA'? 'Electrical HV Room - Level 1' :'Electrical LV Room - Level 2')     

          this.displayGroupID(`#Menu_Back`, 100, 500, 1)
          this.displayGroupID(`#Keyplan_Group`, 100, 500, 1)
          d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Rooms/Keyplan/${index.level}.png`)
          this.displayGroupID(`#Legends`, 100, 500, 1)

          this.hiddenGroupID('#Mask_LQ01', 0, 10)
          this.hiddenGroupID('#Mask_LQ02', 0, 10)
          this.hiddenGroupID('#Mask_LQ06', 0, 10)
          this.hiddenGroupID('#Mask_FOR', 0, 10)
          this.hiddenGroupID('#Mask_ME05DB', 0, 10)
          this.hiddenGroupID('#Mask_ME05DA', 0, 10)
          this.hiddenGroupID('#Mask_MS01', 0, 10)
          this.hiddenGroupID('#Mask_MS03', 0, 10)

          setTimeout(() => {
            this.location.forEach(d => {
              if(d.LV4 == index.level) {
                d3.select(`#${d.LV4}`).transition().duration(400).attr('transform', `translate(${d.x5}, ${d.y5}) scale(${1})`)
              } else {
                this.hiddenGroupID(`#${d.LV4}`, 0, 400)
              }
            })
          }, 400);
          setTimeout(() => {
            this.loadSvg(0, 0, `/TORTUE/Equipment/Rooms/SVG/${index.level}.svg`).then((innerSvg) => {
               this.Draw_EQ_Code(innerSvg, index.level)
               this.Draw_EQ_Tag(innerSvg, index.level)
               this.Draw_EQ_Block(innerSvg, index.level)
               this.innerSvg = innerSvg
            })
            //-----------------------------------pdf overall이랑 다른 이유는 받은대로 pdf로 바로 함수 부를 것이기때문에//
            //-----------------------------------pdf//
            this.drawProgress(index.level, false)              
          }, 800)
        }
      })
    },
  }
}