import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global L450H
// Global Data & Defs

export default {
  methods: {
    Activate_Hull() {

      this.Area = 'Hull'

      this.SVG_Activate = this.svg.append('svg')
      this.SVG_Activate
      .attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight)
      .attr('viewBox', `0 0 ${this.Canvas.CanvasWidth} ${this.Canvas.CanvasHeight}`)
      .attr('opacity', 1)

      // Area_Group
      let Hull_Group = this.SVG_Activate
      .append('g')
      .attr('id', 'Hull_Group')
      .attr('transform', `translate(-1100,-800) scale(2.5)` )
      .attr('opacity', 0)
      .style('cursor', 'pointer')
      .call(s => this.Event_Module(s)) // ------>


      this.MODULES.Hull.forEach(mod => {
        // Draw the Block image & Codes
        // ### Module
        Hull_Group
        .append('image')
        .attr('id', `${mod.module}`)
        .attr('transform', `translate(${mod.x1}, ${mod.y1}) scale(${0.25})`)
        .attr('opacity', 1)
        .style('cursor', 'pointer')
        .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/Demo/Equipment_Layout/MD2/Hull/${mod.module}.png`)
        .call(s => {
          if(mod.item == 'Y') return this.Event_EquipmentHull(s, mod.module) // ------>
        })
      })

      Hull_Group
      .append('image')
      .attr('id', 'MISC')
      .attr('opacity', 1)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/Demo/Equipment_Layout/MD2/Hull/MISC.png`)
      .attr('transform', `translate(446.66,302.35) scale(${0.25})`)

       d3.select('#Hull_Group')
      .transition()
      .delay(300)
      .duration(1000)
      .attr('transform', `translate(-400,-300) scale(1.5)` )
      .attr('opacity', 1)
    },
  }
}
           