<template>
  <div :id="localId"></div>
</template>

<script>
import * as d3 from 'd3'
import mx_Core from './includes/mixins/importer'            // Global & Local Mixins

export default {
  name: "mcskyline-for-home",
  mixins: [mx_Core],
  props: {
    id: String,
    
    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },
    DataItems: { type: Array, default: () => ([]) },
    Queries: {type: Object, default: () => ({}) },
    ColumnProps: { type: Array, default: () => ([]) },
    values: {
      type: Object,
      default: () => ({})
    },
    FilterValues: {
      type: Object,
      default: () => ({})
    },
  },
  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
  },
  mounted() {
    this.register(this.draw)
  },

  methods: {
    draw() {
      if(!this.ready2draw) return

      this.clear()
      this.setStyles()


      this.setDefaultValues()
      this.drawCanvas()
      this.drawChart()
      this.drawTexts()

      // 'complete' must be executed to finish the parent process.
      // It sends 'this.localId' & XmlString for some case to make 
      // displaying image easy.
      this.complete()
    },
    drawChart() {
      this.SkylineCharts = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0.5)`)

        this.draw_Timeline()
        this.draw_Charts()
        this.draw_4wks_LineCharts()
        this.postComplete()
      
    
    },
    postComplete() {
      let chart_ = d3.select('#skyline_boxes_timeline').node().getBoundingClientRect()
      let width_ = chart_.width + 230
      let height_ = 500

      // if (width_ < 950) width_ = 1225

      this.resizableCanvasWidth = width_
      this.resizableCanvasHeight = height_
      this.resizeCanvas()

      let dimention = { 
        width: Math.ceil(width_), 
        height: Math.ceil(height_), 
        isreal: true, 
      }
      this.$emit('resize', dimention)
    }
  }
}
</script>