import * as d3 from 'd3'

export default {
  data: () => ({
    target: 'Hull',
    innerSvg: null,
  }),
  methods: {
    loadSvg() {
      return d3.xml(`${this.__HOST_NAME_RESOURCE}/TORTUE/Milestones/${this.target}.svg`).then(source => {
        if(this.innerSvg) {
          this.innerSvg.html()
          this.innerSvg.remove()
          this.innerSvg = null
        }
    
        this.innerSvg = source.documentElement
        this.svg.node().append(this.innerSvg)
        this.innerSvg = d3.select(this.innerSvg)

        return new Promise(resolve => {
          resolve(source)
        })
      })
    },
    setDefaultValues() {
      if(this.FilterValues && this.FilterValues.TARGET) this.target = this.FilterValues.TARGET
    }
  }
}