import * as d3 from 'd3'

export default {
  data: () => ({
    visible: 2,
  }),
  computed: {
    ready2draw() {

      // console.log('------------------------------')
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)

      return (
        Object.keys(this.Canvas).length > 0
      )
    },
  },
  methods: {
    setDefaultValues(){
    },
 
    
  }
}