import * as d3 from 'd3'
// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  data:() => ({
    thead: [
      { key: 'ACONEX_LATEST_CALC_REVIEW_STATUS', html: 'Reviwed Status Long' },
      { key: 'ACONEX', html: 'In Aconex' },
      // { key: 'RESPONDED', html: 'Responded' },
    ],
    tbody: [
      { key: 'ACONEX_LATEST_CALC_REVIEW_STATUS', w: 310, align: 'left'},
      { key: 'ACONEX', w: 85,align: 'center'},
      // { key: 'RESPONDED', w: 85, align: 'center'},
    ],
  }),
  methods: {
    Draw_Charts() {

      // if(this.ColumnProps.length > 0) var props = this.ColumnProps.find(props_ => props_.value == 'comprops').props
      // else props = this.defaultset

      let svg = this.svg

      this.filteredList = this.DataItems

      let list = this.filteredList

      let group = svg.append('g').attr('class', 'table_group__')
      let table_group = group.append('g').attr('transform', `translate(0.5, 0)`)
      
      let y_ = this.DataItems.length * 26 + 45

      let table_obj = table_group.append('foreignObject')
        .attr('y',0)
        .attr('width', '100%')
        .attr('height', `${y_}px`)
        .append('xhtml:body')
        .style('position', 'fixed')

      const reducer = (previousValue, currentValue) => previousValue + currentValue
      let widths = this.tbody.map(d => d.w)
      let sumWidth = widths.reduce(reducer)

      let table = table_obj.append('table')
      .style('border-collapse', 'collapse')
      .style('font-size', '11px')
      .style('width', `${sumWidth}px`)
      .style('table-layout', 'fixed')

      let colgroup = table.append('colgroup')
      this.tbody.forEach(k => {
        colgroup.append('col').style('width', k.w + 'px')
      })


      let thead = table.append('thead')
      let tr = thead.append('tr')

      this.thead.forEach(th => {
        tr.append('th')
        .attr('class', 'table_group__th')
        .style('height', '30px')
        .style('padding', '3px')
        .style('font-size', '11px')
        .style('background', th.color ? th.color : '#F0F1F3')
        .html(th.html)
      })

      let tbodykeys = this.tbody

      if (list.length == 0) {
        
        let tr = tbody.append('tr')

        tr.append('td')
          .attr('colspan', 2)
          .style('background', '#F5F5F5')
          .style('height', '26px')
          .style('text-align', 'center')
          .style('color', '#424242')
          .style('padding', '0 3px 0 3px')
          .style('font-style', 'italic')
          .html('No data')
        return
      }
      
      let tbody = table.append('tbody').attr('id', 'summay_tbody_')

      for (let r = 0; r < list.length; r++) {
        let tr = tbody.append('tr')
        tr.on('mouseover', () => tr.style('background-color', '#FAFAFA' ))
        tr.on('mouseout', () => tr.style('background-color', 'Transparent' ))
        tbodykeys.forEach((k, i) => {

          let td = tr.append('td')
            // .style('border', 'solid 0.1px #DCD6D4')
            .style('background', r%2 ==0 ?'#fff':'#FAFBFD')
            .style('height', '20px')
            .style('text-align', k.align ? k.align : 'center')
            .style('color', '#858791')
            .style('padding', '0 3px 0 3px')
          
            td.html(list[r][k.key])
            // .style('cursor', () => {
            //   if (i == 0) return
            //   if (list[r][k.key] == 0) return
            //   return 'pointer'
            // })
            // .on('click', () => {
            //   if (i == 0) return
            //   if (list[r][k.key] == 0) return

            //   let filterArr = []

            //   // let filterString = list[r]['DISC'] != 'Total' ? ` DISC = '${list[r]['DISC']}' ` : ``

            //   if (list[r]['DISC'] != 'Total') filterArr.push(` DISC = '${list[r]['DISC']}' `)
            //   if (k.key == 'RESPONDED') filterArr.push(` [CAL_REVIEW_STATUS_CODE] = 'RESPONDED' `)
            //   if (k.key == 'OPEN') filterArr.push(` [CAL_REVIEW_STATUS_CODE] = 'OPEN' `)
            //   if (k.key == 'CANCELLED') filterArr.push( ` [CAL_REVIEW_STATUS_CODE] = 'CANCELLED' `)

            //   let request_ = {
            //     dataType: 'row',
            //     action: {
            //       type: 'direct',
            //       target: 'slide-modal',
            //       component: 'ServiceDatatable',
            //       id: props.no,
            //       no: ''
            //     },
            //     filters: { 
            //       ...this.FilterValues
            //     },
            //     iFilters: {
            //       filterString : filterArr.join(' AND '),
            //       inputFilter  : ''
            //     }
            //   }
            //   this.$emit('request-action', request_)
            // })

            if (r == list.length -1 ) {
              td.style('font-weight', 'bold')
              .style('color', '#2c3e50')
              .style('font-size', '11.5px')
              .style('border-top','solid 1px #EEEEEE')
              // .style('border', 'solid 2px #EEEEEE')
            }
        })
      }
    },
  }
}
