<template>
  <div class="j_service_dashboard">
    
    <!-- <div style="position: absolute; top:30px; right: 20px;">
      <j-button class="type01 red" @click="createExport('pdf', exportInfo, createAction, null, null)">EXPORT PDF</j-button>
    </div> -->

    <j-dashboard-layout-builder
      :mode="__C_.DASHBOARD.BUILDER_MODE_VIEWER"
      :mode-export="modeExport"
      
      :filters="filters.filters"
      :layout="dashboard.layoutAttrs"
      :containers="dashboard.containerAttrs"
      :chart-items="chartItems"
      :request-updated="requestUpdated"
      :getSelectItems ="getSelectItems"
      @complete="onComplete"
      @request-action="onRequestedAction"
      @resize="onResize"
    />

    <j-modal-slide-component 
      v-model="maOpened"
      :component="maComponent"
      :filters="maFilters"
      :target="maTarget"
    />

  </div>
</template>

<script>

import __C from '@/primitives/_constant_'
import { ApplicationService, ChartLibraryService, PageComponentService } from "@/services"
import Comment from '@/mixins/comment.mixin'
import Download from '@/mixins/download.mixin'
import JServiceComponents from '@/components/JServiceComponentTargets'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: "service-dashboard-single",
  mixins: [
    Comment,
    Download            // included mixin 'Loading'
  ],
  components: {
    ...JServiceComponents
  },
  props: {
    modeExport: Boolean,
    filters: {
      type: Object,
      default: () => ({})
    },
    target: {
      type: Object,
      default: () => ({
        code: 'Modal',  // Page | Modal
        type: 'viewer', // viewer | export
        id: Number,
        no: String
      })
    },
  },
  data: () => ({
    // Service Instances -------------
    appService: null,
    chartLibraryService: null,
    pageComponentService: null,
    
    // general data props ------------
    chartItems: [],
    dashboardId: null,
    dashboard: {
      layoutAttrs: {},
      containerAttrs: []
    },
    dimention: {},

    maOpened: false,
    maComponent: '',
    maFilters: null,
    maTarget: null,

    requestUpdated: {},

    prevComplete: ''
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']),
    ...mapState(__C.STORE_NAMESPACE.SLIDE_MODAL, [
      'modals', 'tabs', 'modalComponent', 'modalIndexs'
    ]),
    ...mapGetters(__C.STORE_NAMESPACE.SLIDE_MODAL, [ 'exportInfo' ]),
    ...mapGetters(__C.STORE_NAMESPACE.APP_SERVICE, [
      'getModalExport', 'getSelectItems'
    ]),    

    __C_() { return __C },
  },
  watch: {
    // target: {
    //   handler(val) {
    //     console.log(val);
    //     if(!val) return
    //     this.getDashboard({
    //       idx: this.target.id,
    //       filters: this.filters.filters,
    //       iFilters: this.filters.iFilters
    //     })
    //   }
    // },
    // modalComponent(val) {
    //   console.log(val)
    // }
  },
  created() {
    this.appService = new ApplicationService()
    this.chartLibraryService = new ChartLibraryService()
    this.pageComponentService = new PageComponentService()

    // console.log('modal component ', this.modalComponent)
  },
  // mounted() {
  //   if(this.target.id) this.getDashboard({
  //     idx: this.target.id,
  //     filters: this.filters.filters,
  //     iFilters: this.filters.iFilters
  //   })
  // },
  methods: {
    ...mapActions(__C.STORE_NAMESPACE.SLIDE_MODAL, [ 'createAction' ]),
    onComplete(v) {
      if(this.prevComplete == JSON.stringify(v)) return
      this.prevComplete = JSON.stringify(v)

      this.$emit('complete', {
        name: this.dashboard.name,
        background: this.dashboard.layoutAttrs.background,
        dimention: this.dashboard.layoutAttrs.dimention
      })
    },
    onResize(d) {
      this.dimention = {
        width: Math.round(d.width),
        height: Math.round(d.height)
      }
      this.$emit('resize', this.dimention)
    },
    onRequestedAction(request) {
      if(request.parent.roleNo && request.parent.roleNo.indexOf('CTL') === 0) this.actionContainer(request)
      else if (request.sender.action.name == 'Download') this.actionContainer(request)
      else this.actionChild(request.sender)
    },

    actionChild(request) {
      if(!request.action) {
        console.log(`[USER: undefined 'action'] Target is not defined.`)
        return
      }
      if(!request.action.component) {
        console.log(`[USER: undefined 'Component Name'] Target Name is not defined.`)
        return
      }

      this.maOpened = true
      this.maComponent = request.action.component
      this.maTarget = {
        code: __C.PAGE_COMPONENT.TYPE_MODAL,
        type: '',
        id: request.action.id,
        no: request.action.no,
      }
      this.maFilters = {
        filters: { ...this.filters.filters, ...request.filters },
        iFilters: { ...request.iFilters }
      }

      if(this.maFilters && this.maFilters.filters && Object.keys(this.maFilters.filters).includes('SYS')) {
        this.setCommentProps({
          refCode: this.maFilters.filters.SYS
        })
      }

      console.log(`[USER: #DEBUG] Target  [0]: ${this.maComponent} ${JSON.stringify(this.maTarget)}`)
      console.log(`[USER: #DEBUG] Filters [1]: ${JSON.stringify(this.maFilters.filters)}`)
      console.log(`[USER: #DEBUG] Filters [2]: ${JSON.stringify(this.maFilters.iFilters)}`)
    },
    actionContainer(request) {
      let action___ = (
        request.sender.action && 
        request.sender.action.type == 'direct' ? 
        request.sender.action : 
        request.parent.action
      )
      if(!action___) {
        console.log(`[USER: undefined 'action'] Target is not defined.`)
        return
      }

      switch(action___.name) {
        case 'Query Modifier':
          if(action___.command == 'replace') {
            this.getContainerActionQResult({
              idx: this.dashboard.idx,
              action: {
                containerNo: request.parent.containerNo,
                ...request.parent.action,
                ...request.sender.action
              },
              filters: {
                ...request.sender.filters
              }
            }).then(res => {
              this.requestUpdated = {
                chartItems: res ? res : [],
                location: request.parent.location
              }
            })
          } else {
            // 
          }
          break
        
        case 'Download':
          if (!request.sender.filters.METADATA) return
          var iframe = document.createElement('iframe')
          document.body.appendChild(iframe)
          iframe.src = `${__C.HOST_NAME_API}/v0/filebrowser/raw?path=/TORTUE/${request.sender.filters.folder}/${request.sender.filters.METADATA}.pdf&name=&`

          break
          
        case 'File Downloader':
          var info_ = { 
            name: `${this.dashboard.name}___${request.parent.containerName||'CONTAINER_UNTITLED'}`,
            width: request.parent.dimention.width,
            height: request.parent.dimention.height
          }
          var type_ = (
            action___.command == 'exportPdf' ? 'pdf' : (
              action___.command == 'exportPng' ? 'png' : 'svg'
            )
          )
          var targetAction_ = JSON.stringify({
            component: __C.REQUEST_ACTION.COMPONENT_DASHBOARD_CONTAINER,
            filter: this.filters,
            target: {
              code: __C.PAGE_COMPONENT.TYPE_PAGE,
              type: __C.PAGE_COMPONENT.REQUEST_TYPE_EXPORT,
              id: this.dashboard.idx,
              no: request.parent.containerNo,
            }
          })
          this.createExport(type_, info_, targetAction_)

          break
      }
    },
    getContainerActionQResult(request) {
      if(!request.idx) {
        console.error('No Dashboard Idx found.')
        return
      }

      let params_ = new URLSearchParams()
      params_.append('idx', request.idx)
      params_.append('action', JSON.stringify(request.action))
      params_.append('filters', JSON.stringify(request.filters))

      return new Promise((resolve) => {
        this.appService.getContainerActionQResult(params_, (res) => {
          resolve(res)
        })
      })
    },
    getDashboard(request) {
      let params_ = new URLSearchParams()
      params_.append('idx', request.idx)
      params_.append('catCode', 'Modal')
      params_.append('fValues', request.filters ? JSON.stringify(request.filters) : '')
      params_.append('iFilters', request.iFilters ? JSON.stringify(request.iFilters) : '')
      params_.append('actions', '')
      params_.append('reqType', this.target.type || 'viewer')
      
      console.log(`[USER: #DEBUG] idx     [0]: ${request.idx}`)
      console.log(`[USER: #DEBUG] fValues [1]: ${request.filters ? JSON.stringify(request.filters) : ''}`)
      console.log(`[USER: #DEBUG] iFilters[2]: ${request.iFilters ? JSON.stringify(request.iFilters) : ''}`)
      console.log(`[USER: #DEBUG] reqType [3]: ${this.target.type || 'viewer'}`)
      console.log(`[USER: #DEBUG] getselectitem : ${this.getSelectItems}`)

      return new Promise(resolve => {
        this.appService.getDashboard(params_, (res) => {
          this.dashboard = {}
          this.chartItems = []
          
          setTimeout(() => {
            this.dashboard = res.dashboard
            this.chartItems = res.chartItems
            resolve(null)
          })
        })
      })
    },

    run() {
      return this.getDashboard({
        idx: this.target.id,
        filters: this.filters.filters,
        iFilters: this.filters.iFilters
      })
    }
  }
}
</script>
