import * as d3 from 'd3'

export default {
  data: () => ({
    worktypeOptions: [],
    filteredList: [],
  }),
  computed: {
    ready2draw() {
      return (
        Object.keys(this.Canvas).length > 0
      )
    },
  },
  watch: {
    worktype(value) {
      let dataSet = JSON.parse(JSON.stringify(this.DataItems))

      let codes = [...new Set(this.worktype.map(d => d.WORK_TYPE_CODE))]
      this.filteredList = dataSet.filter(d =>  codes.includes(d.WORK_TYPE_CODE))

      this.Draw_Charts(this.filteredList)


    }
  },
  methods: {
    setDefaultValues(){
      this.worktypeOptions = this.Queries.SQL1
      
      let dataSet = JSON.parse(JSON.stringify(this.DataItems))

      let codes = [...new Set(this.worktype.map(d => d.WORK_TYPE_CODE))]
      this.filteredList = dataSet.filter(d =>  codes.includes(d.WORK_TYPE_CODE))
    }
  }
}