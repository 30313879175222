import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({
    props:[
      {key:'DISC_DESCR', head1: 'Discipline', x: 55, tx: 35, y: 6, textanchor: 'start'},
      {key:'PLAN', head1: 'Plan', x: 180, tx: 185, y: 1,textanchor: 'end'},
      {key:'ACTUAL', head1: 'Actual', x: 270, tx: 275, y: 1},
    ],
    tableWidth: 310,
    rowHeight: 18,

    // margin: { top: 30, left: 35, bottom: 30, right: 10 },
    // width: 480,
    // height: 240,
    // dateRange: [],
    // chart: {},
    // max: null,
    // column: 'DISC',
    // value: 'VOL'
  }),
  

  methods: {
    drawChart() {   
      //----------------------------------------------------------------------------TableChart 
      let PunchTable = this.svg
      .append('g')
      .attr('class','_tableWrap')
      .attr('transform', `translate(0.5, 0.5)`)

      PunchTable
      .append('rect')
      .attr('x',20).attr('y', 5).attr('width',this.tableWidth - 20).attr('height',35).attr('stroke-width', 0).attr('fill', '#F4F5F5').attr('opacity', 0.4)

      PunchTable.append('line').attr('x1',20).attr('y1',5).attr('x2',this.tableWidth).attr('y2',5).attr('stroke-width', 0.2).attr('stroke', '#000')
      PunchTable.append('line').attr('x1',20).attr('y1',40).attr('x2',this.tableWidth).attr('y2',40).attr('stroke-width', 0.2).attr('stroke', '#000')

      PunchTable
      .append('text')
      .attr('transform','translate(180,33)')
      .style('font-size',11).attr('text-anchor', 'middle').attr('fill', '#000')
      .text('Workforce')
      PunchTable
      .append('text')
      .attr('transform','translate(270,33)')
      .style('font-size',11).attr('text-anchor', 'middle').attr('fill', '#000')
      .text('Workforce')
      // header
      PunchTable
      .append('g')
      .attr('transform', `translate(0, 20)`)
      .selectAll('text')
      .data(this.props)
      .enter()
      .append('text')
      .attr('x', d => d.x)
      .attr('y', d => d.y)
      .style('font-family','roboto')
      .style('font-size',11)
      .attr('text-anchor', 'middle')
      .attr('fill', '#000')
      .text(d => d.head1)

      let row_group = PunchTable
      .append('g')
      .attr('class', '_row_data_group')
      .attr('transform', `translate(0, 45)`)

      let line_group = PunchTable
      .append('g')
      .attr('class', '_row_line_group')
      .attr('transform', `translate(0, 50)`)

      let sum = 0;
      this.DataItems.forEach((row, r) => {
        let keys = Object.keys(row)
        let sumOpen = row.OPEN
        sum += sumOpen
        // console.log(keys)
        keys.forEach(key => {
          let idx = this.props.findIndex(e => e.key === key)
          if(idx == -1) return
          // console.log(idx)
          row_group
          .append('text')
          .text(idx != 3 ? row[key] : row[key] + ' %')
          .attr('x', this.props[idx].tx)
          .attr('y', r * 20+10)
          .attr('text-anchor', this.props[idx].textanchor ? this.props[idx].textanchor : 'middle' )
          .style('font-family','roboto')
          .style('font-size',11)
          .style('font-weight', row.SIZE === 'Total' ? 'bold' : 'normal')
          // .attr('fill', this.props[idx].color && row[key] !== 0 ? this.props[idx].color : '#424242' )
          .attr('fill',idx == 2 && r == this.DataItems.length - 1 ? '#03A9F4' : r == this.DataItems.length - 1 ? '#000':'#616161' )

          line_group
            .append('line')
            .attr('x1',20).attr('y1', r * 20+10)
            .attr('x2', this.tableWidth).attr('y2', r * 20+10)
            .attr('stroke-width', 0.2 )
            .attr('stroke', r === this.DataItems.length - 2 || r === this.DataItems.length - 1 ? '#9E9E9E':'#E0E0E0')
          // .attr('stroke-width', 0.2).attr('stroke', r == 5 ? '#757575' : '#E0E0E0')
        })
      })

      let y_ = this.DataItems.length * 20
      // console.log(this.DataItems.length)
      // let idx = this.props.findIndex(e => e.key === 'DISC_DESCR')
      
      // let keys = Object.keys(this.Queries.SQL1[0])
      // keys.forEach(key => {
      //   idx = this.props.findIndex(e => e.key === key)
      //   row_group
      //   .append('text')
      //   .text(this.Queries.SQL1[0][key])
      //   .attr('x', this.props[idx].tx)
      //   .attr('y', y_)
      //   .attr('text-anchor', this.props[idx].textanchor ? this.props[idx].textanchor : 'middle')
      //   .style('font-family','roboto')
      //   .style('font-size',11)
      //   .attr('fill', '#000')
      // })
      // row_group
      // .append('line')
      // .attr('x1',20).attr('y1', y_-24 )
      // .attr('x2', this.tableWidth).attr('y2', y_-24 )
      // .attr('stroke-width', 0.2).attr('stroke','#424242')
    }
  }
}
