import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({

    dDMembers: [
      {text: 'Disciplines', value: 0},
      {text: 'BMW', value: 1},
      {text: 'Audi',value: 2},
      {text: 'Mercedes',value: 3},
      {text: 'Hyundai',value: 4},
    ],

    discTitle: ['Structure', 'Piping', 'Electrical & Instrument', 'Mechanical',  'HVAC', 'Painting'],

    scrollProps: {
      w: 300,
      h: 530,
      g: 0,
      bar: {
        align : 'right',
        gap   : 3,
        radius: 2,
        width : 4,
        fill  : '#9E9E9E',
        stroke: '#9E9E9E',
      }
    }
  }),
  methods: {
    drawChart() {
      let mainGroup = this.svg
      .append('g')
      .attr('class', 'mainGroup')
      .attr('transform', `translate(0, 0)`)

      this.setScroll(mainGroup, this.scrollProps)
      
      let isNoData = this.dataSet.filter(d => d.disc)
      if( isNoData.length == 0){
        mainGroup
        .append('text')
        .attr('transform', `translate(${this.Canvas.CanvasWidth/2.2}, 0)`)
        .style('font-weight', 500)
        .style('font-size', 20)
        .style('font-style', 'italic')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .attr('fill', '#CFCFCF')
        .text('No Data Available.')
      }
      let header = mainGroup
      .append('g')
      .attr('class', 'bar_header_n_linegauge')
      .attr('visibility', `${isNoData.length == 0 ? 'hidden' : 'visible'}`)
      .attr('transform', `translate(0, 20)`)
      header.append('text').attr('x', 175).attr('y', 0).style('font-family','roboto').style('font-size',9).attr('text-anchor', 'start').attr('fill', '#BCBCBC').text('0%')
      header.append('text').attr('x', 243).attr('y', 0).style('font-family','roboto').style('font-size',9).attr('text-anchor', 'end').attr('fill', '#BCBCBC').text('50%')
      header.append('text').attr('x', 295).attr('y', 0).style('font-family','roboto').style('font-size',9).attr('text-anchor', 'end').attr('fill', '#BCBCBC').text('100%')

      

      let yPos = 20
      let lineHeight = 19
      let contentGroup = mainGroup
      .append('g')
      .attr('transform', `translate(10, 5)`)

      this.level1Names.forEach(name => {
        contentGroup
        .append('text')
        .attr('x', 160).attr('y', yPos + 12).style('font-family','roboto').style('font-size',11).style('font-weight', 500).attr('text-anchor', 'end')
        .attr('fill', '#333')
        .text(name)

        yPos += lineHeight

        let ativities = this.DataItems.filter(item => item.disc == name)
        ativities.forEach(item => {
          contentGroup
          .append('text')
          .attr('x', 160).attr('y', yPos + 10).style('font-family', 'roboto').style('font-size', 9.5).attr('text-anchor', 'end')
          .attr('fill', !item.plan && !item.actual ? '#E0E0DF' : '#757575')
          .text(item.activity)

          contentGroup 
          .append('rect') // Plan
          .attr('x', 165).attr('y', yPos + 2).attr('width', 0).attr('height', 10).attr('fill', '#F4F5F5').attr('opacity', 1)
          .transition().duration(500).attr('width', ((item.plan||0)*0.01) * 120)

          contentGroup
          .append('rect') // Actual
          .attr('x', 165).attr('y', yPos + 2).attr('width', 0).attr('height', 10).attr('opacity', .7)
          .attr('fill',  () =>{
            if (((item.actual||0)) > 99.5) return `url(#lightGreen)`
            if (((item.actual||0)-(item.plan||0)) > 0) return `url(#lightBlue)`
            if (((item.actual||0)-(item.plan||0)) < 0) return `url(#lightPink)`
          })
          .transition().duration(500).attr('width', ((item.actual||0)*0.01) * 120)

          if ((item.actual||0) >= 20) {
            contentGroup
            .append('text')
            .attr('x', 175).attr('y', yPos + 10)
            .style('font-family','roboto').style('font-size',9).attr('text-anchor', 'end')
            .attr('fill', '#757575')
            .text(`${item.actual||0}%`)
            .transition().duration(500).attr('x', 165 + (((item.actual||0)*0.01) * 120)-2)

          } else if ((item.actual||0) > 0) {
            contentGroup
            .append('text')
            .attr('x', 175 + 12).attr('y', yPos + 10)
            .style('font-family','roboto').style('font-size',9).attr('text-anchor', 'end')
            .attr('fill', '#757575')
            .text(`${item.actual||0}%`)
            .transition().duration(500).attr('x', 194 + (((item.actual||0)*0.01) * 120))
          }

          yPos += lineHeight
        })
        
        yPos += lineHeight / 2
      })

      let contentHeight = contentGroup.node().getBoundingClientRect().height
      header.append('line').attr('x1', 175.5).attr('y1', 3).attr('x2', 175.5).attr('y2', contentHeight).attr('stroke', '#E0E0DF').attr('stroke-width', .5)
      header.append('line').attr('x1', 235.5).attr('y1', 3).attr('x2', 235.5).attr('y2', contentHeight).attr('stroke', '#E0E0DF').attr('stroke-width', .5)
      header.append('line').attr('x1', 295.5).attr('y1', 3).attr('x2', 295.5).attr('y2', contentHeight).attr('stroke', '#E0E0DF').attr('stroke-width', .5)
    }, 
  }
}
