import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  methods: {
    draw_Timeline() {

      // d3.select('#commodity_summary_timeline_chart').remove()
      
      this.chart = this.svg
      .append('g')
      .attr('transform', `translate(200.5, 16.5)`)
      .attr('id', 'timeline_chart')
      .attr('user-select', 'none')

      let descinfo = this.chart
      .append('g')
      .attr('transform', `translate(-180.5, -5.5)`)
      .attr('class', 'legendGroup')

      descinfo.append('text')
      .attr('x', 0)
      .attr('y', 0).style('font-size', 14).style('fill', '#212121').attr('font-weight', 'bold')
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('MR No.')

      descinfo.append('text')
      .attr('x', 100)
      .attr('y', 0).style('font-size', 10).style('fill', '#212121').attr('font-weight', 500)
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(this.DataItems[0].MR_NO)
      

      descinfo.append('text')
      .attr('x', 0)
      .attr('y', 15).style('font-size', 14).style('fill', '#212121').attr('font-weight', 'bold')
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('MR Description')
      
      descinfo
      .append("foreignObject")
      .attr('x', 100)
      .attr('y', 6)
      .attr('width', 900)
      .attr('height', 14)
      .append('xhtml:div')
      .append('div')
      .attr('style', `padding: 2px 5px; font-size: 10px; line-height: 14px; text-align: left; overflow: hidden; text-overflow:ellipsis; white-space: nowrap; `)
      .attr('title', this.DataItems[0].MR_DESCR)
      .text(this.DataItems[0].MR_DESCR)



      let legend = this.chart
      .append('g')
      .attr('transform', `translate(900.5, 5.5)`)
      .attr('class', 'legendGroup')

      legend
      .append('rect')
      .attr('width', 10).attr('height', 10)
      .attr('transform', `translate(0, -10) rotate(45)`)
      .attr('fill', '#BDBDBD')

      legend.append('text')
      .attr('x', 15)
      .attr('y', -2).style('font-size', 10).style('fill', '#212121').attr('font-weight', 500)
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Baseline Delivery Date')

      legend
      .append('rect')
      .attr('width', 10).attr('height', 10)
      .attr('transform', `translate(140, -10) rotate(45)`)
      .attr('fill', '#FAFAFA')
      .attr('stroke', '#212121')

      legend.append('text')
      .attr('x', 155)
      .attr('y', -2).style('font-size', 10).style('fill', '#212121').attr('font-weight', 500)
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Forecast')

      
      legend
      .append('rect') 
      .attr('width', 10).attr('height', 10)
      .attr('transform', `translate(220, -10) rotate(45)`)
      .attr('fill', '#33691E')

      legend.append('text')
      .attr('x', 235)
      .attr('y', -2).style('font-size', 10).style('fill', '#212121').attr('font-weight', 500)
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Actual')

      // Timeline ----------------------------------------------------------------------------
      let mainTimeline = this.chart
      .append('g')
      .attr('transform', `translate(170.5, 15.5)`)//0
      .attr('user-select', 'none')

      mainTimeline
      .append('rect')
      .attr('x', 0).attr('y', 0).attr('width', this.timelineWidth).attr('height', 75).attr('fill', `url(#${this.localId}__LinearA4LightGray0)`).attr('opacity', .5)//50

      // Timeline Text using the scale function
      // Year
      mainTimeline
      .append('g')
      .selectAll('text')
      .data(this.timelineValues.year)
      .enter()
      .append('text')
      .attr('x', d => this.timeScale(d.value))
      .attr('y', 25).style('font-size', 15).style('fill', '#EC407A').attr('font-weight', 500)//15
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(d => d.name.toUpperCase())

      // Month
      mainTimeline
      .append('g')
      .selectAll('text')
      .data(this.timelineValues.month)
      .enter()
      .append('text')
      .attr('x', d => this.timeScale(d.value))
      .attr('y', 55).style('font-size', 10).style('fill', '#333') //30
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(d => d.name.toUpperCase())

      // Timeline Seperator
      mainTimeline
      .append('g')
      .selectAll('path')
      .data(this.timelineValues.month)
      .enter()
      .append('path')
      .attr('d', (d, i) => {
        if (this.timelineValues.month.length-1 > i) {
          if (d.name == 'Dec')return `M${this.timeScale(d.endDate)}, 10 V35`
          else return `M${this.timeScale(d.endDate)}, 50 V60`
        }
      })
      .attr('stroke', '#757575').attr('stroke-width', 0.3)

      // Draw Line Charts
      let groupY = 5

      let chartGroup = this.chart
      .append('g')
      .attr('transform', `translate(170.5, 100.5)`)

      let rosdate = new Date(this.DataItems[0].RAS_ROS)
      let thisDate = new Date(this.Queries.SQL1[0].CUTOFF)
      

      // This Date Rect Bar
      chartGroup
        .append('rect') 
        .attr('x', this.timeScale(thisDate)).attr('y', 10)
        .attr('width', 0.7)
        .attr('height', (this.DataItems.length * 30) -10).attr('fill', '#29B6F6') 
      chartGroup
        .append('text') 
        .text('Cutoff')
        .attr('x', this.timeScale(thisDate) - 14).attr('y', 0).style('font-size', 10)
        .style('fill', '#29B6F6').attr('font-weight', 500)
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      
      if (this.DataItems[0].RAS_ROS) {
        chartGroup
        .append('rect') 
        .attr('x', this.timeScale(rosdate)).attr('y', 10)
        .attr('width', 0.7)
        .attr('height', (this.DataItems.length * 30) - 10).attr('fill', '#EC407A') 
        chartGroup
          .append('text') 
          .text('ROS')
          .attr('x', this.timeScale(rosdate) - 10).attr('y', 0).style('font-size', 10)
          .style('fill', '#EC407A').attr('font-weight', 500)
          .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        }

      
      let descGroup = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 130.5)`)

      descGroup.append('text')
      .text('Stage')
      .attr('x', 20).attr('y', -30).style('font-size', 14).style('fill', '#212121').attr('font-weight', 'bold')

      descGroup.append('text')
      .text('Stage Description')
      .attr('x', 100).attr('y', -30).style('font-size', 14).style('fill', '#212121').attr('font-weight', 'bold')

      this.dataSet.forEach(item => {
        
        descGroup.append('line')
        .attr('x1', 0).attr('x2', this.timelineWidth + 370).attr('y1', groupY + 10).attr('y2', groupY + 10)
        .attr('stroke', '#9E9E9E').attr('stroke-width', 0.23)
        // .attr('stroke-dasharray', 5)

        descGroup.append('text')
        .text(item.WORK_TYPE_CODE)
        .attr('x', 20).attr('y', groupY).style('font-size', 12).style('fill', '#212121').attr('font-weight', 400)

        descGroup.append('text')
        .text(item.WORK_TYPE_DESCR)
        .attr('x', 100).attr('y', groupY).style('font-size', 12).style('fill', '#212121').attr('font-weight', 400)

        let itemGroup = chartGroup
        .append('g')
        .attr('transform', `translate(0.5, ${groupY+0.5})`)
        .attr('class', `chartItemGroup`)


        let dates = []
        if (item.PLAN) dates.push(item.PLAN)
        if (item.FORECAST && !item.ACTUAL) dates.push(item.FORECAST)
        if (item.ACTUAL) dates.push(item.ACTUAL)

        if (dates.length > 0){
          
          let minDate = new Date(Math.min.apply(null,dates.map(d => new Date(d))))
          let maxDate = new Date(Math.max.apply(null,dates.map(d => new Date(d))))

          itemGroup.append('line')
          .attr('x1', this.timeScale(minDate)).attr('x2', this.timeScale(maxDate))
          .attr('y1', 9).attr('y2', 9)
          .attr('stroke', '#212121').attr('stroke-width',0.3)
        }


        // Plan
        if (item.PLAN) {
          let PD = new Date(item.PLAN)

          itemGroup.append('g')
          .attr('class', item.WORK_TYPE_CODE + '_TOOLTIP_PLAN')
          .style('opacity', 0)
          .attr('transform', `translate(${this.timeScale(PD)}, -2)`)
          .append('text')
          .attr('text-anchor', 'middle')
          .attr('font-size', '10px').style('fill', '#424242')
          .text(item.PLAN)

          itemGroup
          .append('rect') 
          .attr('class', 'PLAN_'+item.PLAN)
          .attr('width', 12).attr('height', 12)
          .attr('transform', `translate(${this.timeScale(PD)}, 0) rotate(45)`)
          .attr('fill', '#BDBDBD')
          .style('cursor', 'pointer')
          .on('mouseover', function() {
            // d3.select(this).transition().duration(50).style('opacity', 0.5)
            d3.select(`.${item.WORK_TYPE_CODE}_TOOLTIP_PLAN`).style('opacity', 1)
          })
          .on('mouseout', function(){
            // d3.select(this).transition().duration(50).style('opacity', 1)
            d3.select(`.${item.WORK_TYPE_CODE}_TOOLTIP_PLAN`).style('opacity', 0)
          })
          
        }

        // Forecast
        if (item.FORECAST && !item.ACTUAL) {
          let FD = new Date(item.FORECAST)

          itemGroup.append('g')
          .attr('class', item.WORK_TYPE_CODE + '_TOOLTIP_FORECAST')
          .style('opacity', 0)
          .attr('transform', `translate(${this.timeScale(FD)}, -3)`)
          .append('text')
          .attr('text-anchor', 'middle')
          .attr('font-size', '10px').style('fill', '#424242')
          .text(item.FORECAST)

          itemGroup
          .append('rect')
          .attr('class', 'FORECAST_'+item.FORECAST)
          .attr('width', 12).attr('height', 12)
          .attr('transform', `translate(${this.timeScale(FD)}, 0) rotate(45)`)
          .attr('fill', '#FAFAFA')
          .attr('stroke', '#212121')
          .style('cursor', 'pointer')
          .on('mouseover', function() {
            // d3.select(this).transition().duration(50).style('opacity', 0.5)
            d3.select(`.${item.WORK_TYPE_CODE}_TOOLTIP_FORECAST`).style('opacity', 1)
          })
          .on('mouseout', function(){
            // d3.select(this).transition().duration(50).style('opacity', 1)
            d3.select(`.${item.WORK_TYPE_CODE}_TOOLTIP_FORECAST`).style('opacity', 0)
          })
        }

        // Actual 
        if (item.ACTUAL) {
          let AD = new Date(item.ACTUAL)

          itemGroup.append('g')
          .attr('class', item.WORK_TYPE_CODE + '_TOOLTIP_ACTUAL')
          .style('opacity', 0)
          .attr('transform', `translate(${this.timeScale(AD)}, -3)`)
          .append('text')
          .attr('text-anchor', 'middle')
          .attr('font-size', '10px').style('fill', '#424242')
          .text(item.ACTUAL)

          itemGroup
          .append('rect')
          .attr('class', 'ACTUAL'+item.ACTUAL)
          .attr('width', 12).attr('height', 12)
          .attr('transform', `translate(${this.timeScale(AD)}, 0) rotate(45)`)
          .attr('fill', '#33691E')
          .style('cursor', 'pointer')
          .on('mouseover', function() {
            // d3.select(this).transition().duration(50).style('opacity', 0.5)
            d3.select(`.${item.WORK_TYPE_CODE}_TOOLTIP_ACTUAL`).style('opacity', 1)
          })
          .on('mouseout', function(){
            // d3.select(this).transition().duration(50).style('opacity', 1)
            d3.select(`.${item.WORK_TYPE_CODE}_TOOLTIP_ACTUAL`).style('opacity', 0)
          })
        }

        groupY += 30
        
      })

    }, 
  }
}
