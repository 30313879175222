import * as d3 from 'd3'

export default {
  methods: {
    

    call_CWA(selection, d) {
      selection
      .on('click', ()=> {
        
      })
      .on('mouseover', () => {
        d3.select(`#CWA-${d.CWA}`).style('opacity', 1)
      })
      .on('mouseout', () => {
        d3.select(`#CWA-${d.CWA}`).style('opacity', .5)
      })
    },


    call_Filtering(opt, status) {
      // this.rawData.filter(f => f.STATUS == opt.code).forEach(d => {
      //   d3.select(`#CWA-${d.CWA}`)
      //   .style('fill', (status == 'visible') ? opt.color : '#fff')

      //   d3.select(`#CWA-${d.CWA}`).selectAll('rect')
      //   .style('fill', (status == 'visible') ? opt.color : '#fff')
      //   d3.select(`#CWA-${d.CWA}`).selectAll('path')
      //   .style('fill', (status == 'visible') ? opt.color : '#fff')
      //   d3.select(`#CWA-${d.CWA}`).selectAll('polygon')
      //   .style('fill', (status == 'visible') ? opt.color : '#fff')
      // })
    },







  }
}