import * as d3 from 'd3'

export default {
  methods: {
    Export_Overall(d) {
      if (d != null) {
        this.displayGroupID(`#Keyplan_Group`, 10, 10, 1)
        this.displayGroupID(`#Legends`, 10, 10, 1)
      }
      // this.removeSvg(this.CANVAS, 1000)
      // this.removeSvg(this.Dashboard_Overall, 10) 
      // this.removeSvg(d3.select('#BlockCodes'), 0)
      if (d.stage == 'Division_Lv1' && d.mask == 'OVERALL') {
        
        setTimeout(() => {
        // Remove SVG
        this.removeSvg(this.CANVAS, 10)          
        },1000);
        // Hidden
        this.hiddenGroupID('#Mask_Overall', 0, 10)

        setTimeout(() => {

          d3.select(`#Topside_Group`).transition().attr('transform', `translate(0, -100)`)
          d3.select(`#Area_AFT`).transition().attr('transform', `translate(418, 668) scale(0.75)`)
          d3.select(`#Area_FOR`).transition().attr('transform', `translate(727, 653) scale(0.75)`)
          d3.select(`#Area_LQ`).transition().attr('transform', `translate(983, 663) scale(0.75)`)
          d3.select(`#Area_VS`).transition().attr('transform', `translate(926, 734) scale(0.75)`)

        },1000);

        setTimeout(() => {

          // Display
          this.displayGroupID(`#Menu_Back`, 10, 10, 1)
          this.displayGroupID(`#Keyplan_Group`, 10, 10, 1)
          this.displayGroupID(`#Legends`, 10, 10, 1)
          this.displayGroupID(`#Mask_Topside`, 10, 10, 0)
          this.displayGroupID(`#Mask_Hull`, 10, 10, 0)

          this.stage = this.STG_Division_Lv1
          this.fncName = 'Goto_Division_Lv1'
          this.StageCode() // AREA / STAGE
          this.displayGroupID(`#Menu_Back`, 0, 200, 1)

          // ### Call Canvas ###
          this.Canvas_Overall_Division_Lv1()
        },1000);
      // })        
  }
      if (d.stage == 'Division_Lv2' && d.mask == 'Mask_Hull') {
        setTimeout(() => {
        // Remove SVG
        this.removeSvg(this.CANVAS, 10)
        },1000);
        // Title & Keyplan
        d3.select(`#Title`).text('Hull & LQ')
        d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Keyplan/HULL.png`)

        // Hidden
        this.hiddenGroupID('#Menu_Back', 0, 10)

        this.hiddenGroupID('#Mask_Topside', 0, 10)
        this.hiddenGroupID('#Mask_Hull', 0, 10)

        this.hiddenGroupID('#Topside_Group', 0, 1000)

        setTimeout(() => {

          d3.select(`#Area_AFT`).transition().duration(1000).attr('transform', `translate(364, 477) scale(0.75)`)
          d3.select(`#Area_FOR`).transition().duration(1000).attr('transform', `translate(842,496) scale(0.75)`)
          d3.select(`#Area_LQ`).transition().duration(1000).attr('transform', `translate(1109,295) scale(0.75)`)
          d3.select(`#Area_VS`).transition().duration(1000).attr('transform', `translate(1041,577) scale(0.75)`)
        }, 1000); 

        setTimeout(() => {

          // Mask Display
          this.displayGroupID(`#Mask_Hull_AFT`, 10, 10, 0)
          this.displayGroupID(`#Mask_Hull_FOR`, 10, 10, 0)
          this.displayGroupID(`#Mask_Hull_LQ`, 10, 10, 0)

          this.Lev1 = 'HULL'
          this.stage = this.STG_Division_Lv2
          this.fncName = 'Goto_Hull_Division_Lv2'
          this.StageCode() // AREA / STAGE
          this.displayGroupID(`#Menu_Back`, 0, 200, 1)

          // ### Call Canvas ###
          this.Canvas_Hull_Division_Lv2()
        }, 1000);          
      }

      if (d.stage == 'Division_Lv2' && d.mask == 'Mask_Topside') {

        setTimeout(() => {
          // Remove SVG
          this.removeSvg(this.CANVAS, 10)
        },1000);        

        // Title & Keyplan
        d3.select(`#Title`).text('Topside')
        d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Keyplan/TOPSIDE.png`)
        // Hidden
        this.hiddenGroupID('#Menu_Back', 0, 10)   
        this.hiddenGroupID('#Mask_Topside', 0, 10)
        this.hiddenGroupID('#Mask_Hull', 0, 10)

        this.hiddenGroupID('#Area_AFT', 0, 1000)
        this.hiddenGroupID('#Area_FOR', 0, 1000)
        this.hiddenGroupID('#Area_LQ', 0, 1000)
        this.hiddenGroupID('#Area_VS', 0, 1000)

        setTimeout(() => {

        d3.select(`#Topside_Group`).transition().duration(1000).attr('transform', `translate(0, 0)`)

        d3.select(`#FL01`)  .transition().duration(1000).attr('transform', `translate(451, 384) scale(0.75)`)
        d3.select(`#FR01`)  .transition().duration(1000).attr('transform', `translate(485.47, 676.52) scale(0.75)`)
        d3.select(`#KO01`)  .transition().duration(1000).attr('transform', `translate(384, 670) scale(0.75)`)
        d3.select(`#TSRC01`).transition().duration(1000).attr('transform', `translate(494, 676) scale(0.75)`)
        d3.select(`#TSRC02`).transition().duration(1000).attr('transform', `translate(591, 694) scale(0.75)`)
        d3.select(`#TSRC03`).transition().duration(1000).attr('transform', `translate(680, 727) scale(0.75)`)
        d3.select(`#TSRC04`).transition().duration(1000).attr('transform', `translate(780, 744) scale(0.75)`)
        d3.select(`#TSRC05`).transition().duration(1000).attr('transform', `translate(857, 759) scale(0.75)`)
        d3.select(`#LD00DA`).transition().duration(1000).attr('transform', `translate(897, 779) scale(0.75)`)


        d3.select(`#MW01`).transition().duration(1000).attr('transform', `translate(783, 221) scale(0.75)`)
        d3.select(`#MW02`).transition().duration(1000).attr('transform', `translate(870, 232) scale(0.75)`)
        d3.select(`#MW04`).transition().duration(1000).attr('transform', `translate(1076, 244) scale(0.75)`)
        d3.select(`#MW05`).transition().duration(1000).attr('transform', `translate(1133, 284) scale(0.75)`)
        d3.select(`#ME01`).transition().duration(1000).attr('transform', `translate(744, 265) scale(0.75)`)
        d3.select(`#ME02`).transition().duration(1000).attr('transform', `translate(828, 272) scale(0.75)`)
        d3.select(`#ME04`).transition().duration(1000).attr('transform', `translate(1037, 271) scale(0.75)`)
        d3.select(`#ME05`).transition().duration(1000).attr('transform', `translate(1095, 322) scale(0.75)`)
        
      },1000);

        setTimeout(() => {

          // Mask Display
          this.displayGroupID(`#Mask_TS_Common`, 10, 10, 0)
          this.displayGroupID(`#Mask_TS_Modules`, 10, 10, 0)


          this.Lev1 = 'TOPSIDE'
          this.stage = this.STG_Division_Lv2
          this.fncName = 'Goto_TS_Division_Lv2'
          this.StageCode() // AREA / STAGE
          this.displayGroupID(`#Menu_Back`, 0, 200, 1)

          // ### Call Canvas ###
          this.Canvas_Topside_Division_Lv2()
        }, 1000);          
      } 
      
      // Hull Select
      if (d.stage == 'Division_Lv3' && ['AFT','FOR','LQ'].includes(d.level)) {
        setTimeout(() => {
          // Remove SVG
          this.removeSvg(this.CANVAS, 10)
        },1000);

        // Title & Keyplan
        d3.select(`#Title`).text(`Hull-${d.level}`)
        d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Keyplan/HULL_${d.level}.png`)

         // Hidden
         this.hiddenGroupID('#Menu_Back', 0, 10)

         this.hiddenGroupID('#Mask_Hull_AFT', 0, 10)
         this.hiddenGroupID('#Mask_Hull_FOR', 0, 10)
         this.hiddenGroupID('#Mask_Hull_LQ', 0, 10)
   
         setTimeout(() => {
         // Remove SVG
         this.removeSvg(this.Dashboard_Overall, 10) 
        },1000);
         // Activate

         if(d.level =='AFT') this.Activate_Hull_AFT()
         if(d.level =='FOR') this.Activate_Hull_FOR()
         if(d.level =='LQ') this.Activate_Hull_LQ()

         this.Stage = this.STG_Activate
         this.fncName = 'Goto_Hull_Activate'
         this.StageCode() // AREA / STAGE
         this.displayGroupID(`#Menu_Back`, 0, 200, 1) 
      }      
  
    },

  }
}
           