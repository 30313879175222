import * as d3 from 'd3'

export default {
  data: () => ({
    dataSet: [],
  }),
  computed: {
    ready2draw() {

      // console.log('------------------------------')
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)

      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },
methods: {
  setDefaultValues(){
  
    this.dataSet = JSON.parse(JSON.stringify(this.DataItems))
    
    this.dataSet.forEach(data => { })
    
    //------------------------------- For the drop shadow filter
    let defs = this.svg.append('defs')
    let filter = defs.append('filter')
    .attr('id', 'dropshadow')

    filter.append('feGaussianBlur')
    .attr('in', 'SourceAlpha')
    .attr('stdDeviation', 3)
    .attr('result', 'blur');
    filter.append('feOffset')
    .attr('in', 'blur')
    .attr('dx', 0)
    .attr('dy', 3)
    .attr('result', 'offsetBlur');
    filter.append('feFlood')
    .attr('in', 'offsetBlur')
    .attr('flood-color', '#727272')
    .attr('flood-opacity', '0.5')
    .attr('result', 'offsetColor');
    filter.append('feComposite')
    .attr('in', 'offsetColor')
    .attr('in2', 'offsetBlur')
    .attr('operator', 'in')
    .attr('result', 'offsetBlur');

    var feMerge = filter.append('feMerge');
    feMerge.append('feMergeNode')
    .attr('in', 'offsetBlur')
    feMerge.append('feMergeNode')
    .attr('in', 'SourceGraphic');
    //------------------------------- For the drop shadow filter

    this.svg
    .append('clipPath')
    .attr('id', 'mask_image')
    .append('rect')
    .attr('transform', `translate(0, 0)`) //처음 기준이 0,0이어야한다 //내가 만드는 이미지의 크기와 동일하게 작업
    .attr('width', 250)
    .attr('height', 160)
    .attr('rx',3)

    this.svg
    .append('clipPath')
    .attr('id', 'mask_text')
    .append('rect')
    .attr('transform', `translate(0, -30)`) //text에 각각 주기 위해 rect위치를 (0,0)이아닌 (0,-30) 이다
    .attr('width',240)
    .attr('height', 30)
  },
}
}