import * as d3 from 'd3'

export default {
  data: () => ({

  svgGroup: null,
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },


  methods: {
    /*
      initValues()
      loadSvg(selection, data)
      set_LocalValues
    */
   
      set_LocalValues() {
       // Database에서 값 가져오기
      this.timeline.startDate = this.Queries.SQL1[0].START_DATE
      this.timeline.endDate = this.Queries.SQL1[0].END_DATE
      this.cutoff = this.Queries.SQL1[0].CUTOFF

      this.Milestone = JSON.parse(JSON.stringify(this.Queries.SQL2))
      this.Schedule = JSON.parse(JSON.stringify(this.DataItems))
   },


    loadSvg(selection, data) {
      return d3.xml(`${this.__HOST_NAME_RESOURCE}${data.url}`).then(source => {
        let innerSvg = source.documentElement
        this.svg.node().append(innerSvg)
        innerSvg = d3.select(innerSvg)

        innerSvg
        .attr('x', data.x)
        .attr('y', data.y)

        return new Promise(resolve => {
          resolve(source)
        })
      })
    },
    
  }
}