import * as d3 from 'd3'
// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  data:() => ({
    thead: [
      { key: 'ITEM', html: 'Name', row: 1,  },
      { key: 'DESC', html: 'Description', row: 1,  },
      { key: 'CONTRACT', html: 'Single Point of Contract (SPOC)', row:1, },
    ],
    theadrows: 1,
    tbody: [
      { key: 'ITEM', w: 300 },
      { key: 'DESC', w: 400},
      { key: 'CONTRACT', w: 300,  },
    ],
    
    tableWidth: 1250,
  }),
  methods: {
    Draw_Charts() {
      let svg = this.svg

      let data_ = this.DataItems[0]

      let group = svg.append('g').attr('class', 'group__').attr('transform', `translate(0.5, 0.5)`).style('font-family', 'roboto')

      let imageBox = group
      .append('g').attr('class', 'imagebox__').attr('transform', `translate(0, 0)`)
      imageBox
      .append('image')
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/China1/Interface/InterfaceTeam.jpg`)
      .attr('stroke', '#dadada')
      .attr('stroke-width', .5)
      

      if(!this.DataItems || this.DataItems.length === 0) return

      let tableData = group

        let table_group = tableData.append('g').attr('transform', `translate(70.5, 1000.5)`).attr('class', `block_table_group_`)

        // SET HERE!
        let list = this.DataItems.filter(d => d.ITEM)
        // console.log(list)
        let tableViewHeight = 96 +800 // thead + tbody

        let table_obj = table_group.append('foreignObject')
          .attr('y',0)
          .attr('width', '100%')
          // .attr('height', `${tableViewHeight}px`)
          .attr('height', '100%')
          .append('xhtml:body')
  
        let table = table_obj.append('table')
                    .style('border', 'solid 0.7px #E0E0E0')
                    .style('border-collapse', 'collapse')
                    .style('font-size', '12px')
                    .style('width', `${this.tableWidth}px`)
                    .style('table-layout', 'fixed')
  
        let colgroup = table.append('colgroup')
        this.tbody.forEach(k => {
          colgroup.append('col').style('width', k.w + 'px')
        })
  
        let maxRow = Math.max(...this.thead.map(d => d.row))
  
        let thead = table.append('thead')
        for (let r = 1; r <= maxRow; r++) {
          let row = this.thead.filter(d=> d.row === r)
          let tr = thead.append('tr').style('background', '#BDBDBD')
  
          row.forEach(item => {
            tr.append('th')
            .attr('class', 'completion_dashboard_table_group___th')
            .attr('rowspan', () => item.rowspan ? item.rowspan : '')
            .attr('colspan', () => item.colspan ? item.colspan : '')
            .style('border', 'solid 0.7px #E0E0E0')
            .style('padding', '5px')
            .style('height', '36px')
            .style('font-weight','bold')
            .style('font-size',  '12px')
            .style('color','#212121')
            .html(item.html)
          })        
        }
          
        let tbodykeys = this.tbody
        let tbody = table.append('tbody')
  
        let disc = ''
        let addClass = ''
        for (let r = 0; r < list.length; r++) {
          let tr = tbody.append('tr')
  
          tbodykeys.forEach((k, i) => {
  
            if (list[r][k.key] == disc) return
            else {
              if (i == 0) {
                disc = list[r][k.key]
              }
            }
  
            let td = tr.append('td')
              .style('border', 'solid 0.1px #E0E0E0')
              .style('height', '21px')
              .style('text-align', k.align ? k.align : 'left')
              .style('padding', '10px')
              // .attr('rowspan', () => {
              //   if (i == 0) {
              //     let filtered = list.filter(d => d.CATEGORY == list[r][k.key])
              //     return filtered.length
              //   }
              // })
            if(k.key.includes('PROG')) {
              addClass = ''
              td
              .style('position', 'relative')
              .style('color', list[r][k.key] < 0 ? 'red' : list[r][k.key] === 100 ? '#fff' : addClass == 'caution' ? 'red' : '#000')
              .style('z-index', '1')
              .html(list[r][k.key] == null ? 0+'%' :list[r][k.key]+'%')
  
              td.append('div')
              .style('position', 'absolute')
              .style('left', 0)
              .style('top', 0)
              .style('width', list[r][k.key] > 0 ? list[r][k.key] + '%' : 0)
              .style('height', '20px')
              .style('background-color', list[r][k.key] < 100 ? k.key.includes('PLAN') ? '#E0E0E0' : '#FFCA28' : '#4CAF50')
              .style('z-index', '-1')
            }
            else {
              if (k.type == 'float') {
                let regexp = /\B(?=(\d{3})+(?!\d))/g
                if (isNaN(Math.floor(list[r][k.key]))) td.html('')
                else td.html(Math.round(Math.floor(list[r][k.key] * 100) / 100).toString().replace(regexp, ','))
              } else td.html(list[r][k.key])
            }
          })
        }
      
    },
  }
}
