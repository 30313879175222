import * as d3 from 'd3'
import __C from '../../../../../includes/primitives/_constant_'
export default {
  data: () => ({
    innerSvg: null,
    stage_Header: null,
    stage_List: null,
    stage_Box: null,
    check_Box: null,
    selectedItems: null,
    // rawData: null,

    stageCode: [],
    dataset: [],

    scale: .5,
    scaleW: 0,
    scaleH: 0,
    zoom: {
      in: { x: 0, y: 0 },
      out: { x: 0, y: 0 }
    },

    CurrentPkg: '',
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {
    /*
      set_LocalValues()
      loadSvg()
    */

    set_LocalValues(){
      d3.selection.prototype.moveToFront = function() {
        return this.each(function(){
          this.parentNode.appendChild(this)
        })
      }

      d3.selection.prototype.moveToBack = function() {  
        return this.each(function() { 
            var firstChild = this.parentNode.firstChild; 
            if (firstChild) { 
                this.parentNode.insertBefore(this, firstChild); 
            } 
        })
      }
      this.selectedItems = this.getSelectItems ? JSON.parse(this.getSelectItems) : {}

      this.Toggle.status = this.FilterValues && this.FilterValues.TOGGLE ? this.FilterValues.TOGGLE : this.Toggle.status
      this.CurrentPkg = 'P1'
    },

    loadSvg(selection, data) {
      return d3.xml(`${this.__HOST_NAME_RESOURCE}${data.url}`).then(source => {
        let svg_ = source.documentElement
        selection.node().append(svg_)

        svg_ = d3.select(svg_)
        .attr('x', data.x)
        .attr('y', data.y)

        return new Promise(resolve => {
          resolve(svg_)
        })
      })
    },

    buildRequest(seicode_l1) {
      let d = this.DataItems.find( r => r.SEICODE_L1 === seicode_l1 )
      if(!d) return {}

      d.TOGGLE = this.Toggle.status // Add selected language code
      let request = this.getRequestColumnProps('routeto', d, this.ColumnProps)
      if(!request) return null

      request.action.path = `${request.action.path}?useinfo=true&filters=${JSON.stringify(request.filters)}`

      return request
    },
  }
}