import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs

import Defs from '../../../../includes/primitives/Color_Defs'

export default {
  methods: {
    chart_Normal(sn) {
      // ----------------------------------------------- Progress Bar
      this.svg // Background
        .append('rect')
        .attr('x', this.Canvas.CanvasChartX)
        .attr('y', this.drawPoint_y)
        .attr('width', this.Canvas.CanvasChartWidth)
        .attr('height', this.setThickness)
        .attr('fill', this.Bar.BarBackColor)

      this.svg // actual Bar
        .append('rect')
        .attr('x', this.Canvas.CanvasChartX)
        .attr('y', this.drawPoint_y)
        .attr('width', 0)
        .attr('height', this.setThickness)
        .attr('fill', this.SharedColorSet[sn])
        .transition()
        .duration(750)
        .attr('width', this.get_BarLength)


      // ----------------------------------------------- Series Name
      if (this.Bar.BarSeriesDisplay == 'Y') {
        this.svg // Series Name
          .append('text')
          .attr('class', this.Bar.BarSeriesStyle)
          .attr('x', this.Canvas.CanvasChartX - this.Bar.BarSeriesSpace)
          .attr('y', this.drawPoint_y + this.setThickness / 2 + 1)
          .style('font-size', this.Bar.BarSeriesSize)
          .style('font-family', this.Bar.BarSeriesFont)
          .attr('fill', this.Bar.BarSeriesAutoColor == 'Y' ? this.SharedColorSet[sn] : this.Bar.BarSeriesColor)
          .attr('text-anchor', 'end')
          .attr('alignment-baseline', 'middle')
          .text(this.DataItems[sn].title)
      }

      // ----------------------------------------------- Actual Value Text
      let actualValue = this.svg // actual Value
        .append('text')
        .attr('id', `Actual${sn}`)
        .attr('class', this.Value.ValueActualStyle)
        .attr('x', this.Canvas.CanvasChartX)
        .attr('y', this.drawPoint_y + this.setThickness / 2 + 1)
        .style('font-size', this.Value.ValueActualSize)
        .style('font-family', this.Value.ValueActualFont)
        .attr('alignment-baseline', 'middle')

      if (this.Value.ValueUnitConvert == 'Y') {
        actualValue.text(`${((this.DataItems[sn].actual / this.DataItems[sn].total) * 100).toFixed(this.Value.ValueActualRound)}%`)
      } else {
        actualValue.text(this.DataItems[sn].actual)
      }
      
      let textWidth = this.getNodeElValue(`#Actual${sn}`, 'width')
      
      let textX = 0; let textAlign = ''; let textColor = ''
      if (this.get_BarLength > textWidth + 2) {
        textX = this.Canvas.CanvasChartX + this.get_BarLength - 2
        textAlign = 'end'
        textColor = this.Value.ValueActualColor1
      } else {
        textX = this.Canvas.CanvasChartX + this.get_BarLength + 2
        textAlign = 'start'
        textColor = this.Value.ValueActualColor2
      }

      actualValue
      .attr('text-anchor', textAlign)
      .attr('fill', textColor)
      .transition()
      .duration(750)
      .attr('x', textX)
      .tween("text", () => {
        if (this.Value.ValueUnitConvert == 'Y') {
          let i = d3.interpolate(0, (this.DataItems[sn].actual / this.DataItems[sn].total) * 100)
          return (t) => {
            actualValue.text(`${i(t).toFixed(this.Value.ValueActualRound)}%`)
          }
        } else {
          let i = d3.interpolate(0, this.DataItems[sn].actual);
          return function(t) {
            actualValue.text(Math.round(i(t)))
          }
        }
      })
    



      // ----------------------------------------------- Total Value Text
      if (this.Value.ValueTotalDisplay == 'Y') {
        this.svg // Total Value
          .append('text')
          .attr('id', `Total${sn}`)
          .attr('class', this.Value.ValueTotalStyle)
          .attr('x', this.Canvas.CanvasChartX + this.Canvas.CanvasChartWidth + 2)
          .attr('y', this.drawPoint_y + this.setThickness / 2 + 1)
          .style('font-size', this.Value.ValueTotalSize)
          .style('font-family', this.Value.BarSeriesFont)
          .attr('fill', this.Value.ValueTotalColor)
          .attr('text-anchor', 'start')
          .attr('alignment-baseline', 'middle')
          .text(this.DataItems[sn].total)
      }


      // ----------------------------------------------- Outstand Value Text
      if (this.Value.ValueOutstandDisplay == 'Y') {
        this.svg // Outstandinf Value
          .append('text')
          .attr('class', this.Value.ValueOutstandStyle)
          .attr('x', this.Canvas.CanvasChartX)
          .attr('y', this.drawPoint_y + this.setThickness + 2)
          .style('font-size', this.Value.ValueOutstandSize)
          .style('font-family', this.Value.ValueOutstandFont)
          .attr('fill', this.Value.ValueOutstandColor)
          .attr('text-anchor', 'start')
          .attr('alignment-baseline', 'hanging')
          .text(this.getOutstand)
          .transition()
          .duration(1000)
          .attr('x', this.Canvas.CanvasChartX + this.get_BarLength)
      }
    },

    
  }
}
