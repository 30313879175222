import * as d3 from 'd3'
import __C from '../../../../../includes/primitives/_constant_'
export default {
  data: () => ({
    stage_Header: null,
    innerSvg: null,
    commissioningBox: null,
    commissioningList: null,

    CurrentStep: '',

    rawData: []
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {
    /*
      set_LocalValues()
      loadSvg()
    */

    set_LocalValues(){

      this.rawData = JSON.parse(JSON.stringify(this.DataItems))
      
    },



    loadSvg(selection, data) {
      return d3.xml(`${this.__HOST_NAME_RESOURCE}${data.url}`).then(source => {
        let svg_ = source.documentElement
        selection.node().append(svg_)

        svg_ = d3.select(svg_)
        .attr('x', data.x)
        .attr('y', data.y)

        return new Promise(resolve => {
          resolve(svg_)
        })
      })
    },

  }
}