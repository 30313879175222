import * as d3 from 'd3'
import __C from '../../../../../../includes/primitives/_constant_'
import __M from 'moment'

export default {
  data: () => ({
    scaleY: null,
    labelY: 45,
    labelYGap: 18,
  }),

  methods: {
    draw_Table() {
      this.putTableForm()
      this.putTableValues()
    }, 
    putTableForm() {
      d3.select(`#skyline_boxes_table`).remove()

      // let drawX = 0
      let drawY = 30

      let dataTable = this.SkylineCharts
      .append('g')
      .attr('class', 'table_form')
      .attr('transform', `translate(${this.timeline.baseX},${this.timeline.baseY + 60})`)
      .attr('id', `skyline_boxes_table`)

      // Vertical Line
      dataTable
      .append('line').attr('x1', -140).attr('x2', -140).attr('y1', 20).attr('y2', 197).attr('stroke-width', 0.5).attr('stroke', '#757575')
      dataTable
      .append('line').attr('x1', 0).attr('x2', 0).attr('y1', 20).attr('y2', 197).attr('stroke-width', 0.5).attr('stroke', '#757575')

      
      dataTable
      .append('line').attr('x1', this.timeline.length).attr('x2', this.timeline.length).attr('y1', 20).attr('y2', 197).attr('stroke-width', 0.5).attr('stroke', '#757575')
      

      drawY = 20

      dataTable
      .append('line').attr('x1', -140).attr('x2', this.timeline.length).attr('y1', drawY).attr('y2', drawY).attr('stroke-width', 0.5).attr('stroke', '#757575')
      drawY += 15

      drawY = 35
      dataTable
      .append('line').attr('x1', -140).attr('x2', this.timeline.length).attr('y1', drawY).attr('y2', drawY).attr('stroke-width', 0.5).attr('stroke', '#757575')
      drawY += 18

      dataTable
      .append('line').attr('x1', -140).attr('x2', this.timeline.length).attr('y1', drawY).attr('y2', drawY).attr('stroke-width', 0.5).attr('stroke', '#bcbcbc')
      drawY += 18

      dataTable
      .append('line').attr('x1', -140).attr('x2', this.timeline.length).attr('y1', drawY).attr('y2', drawY).attr('stroke-width', 0.5).attr('stroke', '#bcbcbc')
      drawY += 18

      dataTable
      .append('line').attr('x1', -140).attr('x2', this.timeline.length).attr('y1', drawY).attr('y2', drawY).attr('stroke-width', 0.5).attr('stroke', '#bcbcbc')
      drawY += 18

      // Weekly End
      dataTable
      .append('line').attr('x1', -140).attr('x2', this.timeline.length).attr('y1', drawY).attr('y2', drawY).attr('stroke-width', 0.5).attr('stroke', '#757575')
      drawY += 18

      dataTable
      .append('line').attr('x1', -140).attr('x2', this.timeline.length).attr('y1', drawY).attr('y2', drawY).attr('stroke-width', 0.5).attr('stroke', '#bcbcbc')
      drawY += 18

      dataTable
      .append('line').attr('x1', -140).attr('x2', this.timeline.length).attr('y1', drawY).attr('y2', drawY).attr('stroke-width', 0.5).attr('stroke', '#bcbcbc')
      drawY += 18

      dataTable
      .append('line').attr('x1', -140).attr('x2', this.timeline.length).attr('y1', drawY).attr('y2', drawY).attr('stroke-width', 0.5).attr('stroke', '#bcbcbc')
      drawY += 18

      dataTable
      .append('line').attr('x1', -140).attr('x2', this.timeline.length).attr('y1', drawY).attr('y2', drawY).attr('stroke-width', 0.5).attr('stroke', '#bcbcbc')
      drawY += 18

      dataTable
      .append('line').attr('x1', -140).attr('x2', this.timeline.length).attr('y1', drawY).attr('y2', drawY).attr('stroke-width', 0.5).attr('stroke', '#757575')
      drawY += 18
      

      dataTable
      .append('g')
      .attr('transform', `translate(30,0)`)
      .selectAll('text')
      .data(this.table.values1)
      .enter()
      .append('text')
      .attr('x', -150)
      .attr('y', (_, i) => this.labelY + this.labelYGap * i)
      .style('font-family', this.timeline.font)
      .style('font-size', this.timeline.style.month.tSize)
      .attr('fill', d => d.tColor || this.timeline.style.month.tColor)
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .text(d => d.text)

      dataTable
      .append('g').attr('transform', `translate(0,0)`)
      .append('text')
      .attr('x', -130)
      .attr('y', 28)
      .style('font-family', this.timeline.font)
      .style('font-size', this.timeline.style.month.tSize)
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .text('Weekly')


      
      dataTable
      .append('g')
      .attr('transform', `translate(30,90)`)
      .selectAll('text')
      .data(this.table.values2)
      .enter()
      .append('text')
      .attr('x', -150)
      .attr('y', (_, i) => this.labelY + this.labelYGap * i)
      .style('font-family', this.timeline.font)
      .style('font-size', this.timeline.style.month.tSize)
      .attr('fill', d => d.tColor || this.timeline.style.month.tColor)
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .text(d => d.text)

      dataTable
      .append('g').attr('transform', `translate(0,90)`)
      .append('text')
      .attr('x', -130)
      .attr('y', 28)
      .style('font-family', this.timeline.font)
      .style('font-size', this.timeline.style.month.tSize)
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .text('Cummulative')


      // dataTable
      // .append('g')
      // .selectAll('path')
      // .data(this.timeline.month)
      // .enter()
      // .append('path')
      // .attr('d', (d, i) => `M${this.timeline.scale(d.eDate)}, 20 V 97`)
      // .attr('stroke', (d,i) => {
      //   if (i == this.timeline.month.length-1) return '#757575'; else return '#bcbcbc'
      // })
      // .attr('stroke-width', 0.5)


      let headerData = this.Queries.SQL4

      // Weeks Text & Timeline Seperator for the Week
      dataTable
      .append('g')
      .selectAll('text')
      .data(this.timeline.week)
      .enter()
      .append('text')
      .attr('x', d => this.timeline.scale(d.mDate))
      .attr('y', 23)
      .style('font-family', this.timeline.font)
      .style('font-size', this.timeline.style.week.tSize)
      .attr('fill', d => {
        if (this.cutoff == __M(d.eDate).format('YYYY-MM-DD')) return '#212121'
        else return '#bababa'
      })
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'hanging')
      .text(d => {
        let date = headerData.find(h => h.CUTOFF == __M(d.eDate).format('YYYY-MM-DD'))
        return date ? date.CUTOFF : ''
      })

      
      dataTable
      .append('g')
      .selectAll('path')
      .data(this.timeline.week)
      .enter()
      .append('path')
      .attr('d', (d, i) => `M${this.timeline.scale(d.eDate)}, 20 V 107`)
      .attr('stroke', this.timeline.style.week.sColor)
      .attr('stroke-width', 0.5)
      

      dataTable
      .append('g')
      .selectAll('path')
      .data(this.timeline.week)
      .enter()
      .append('path')
      .attr('d', (d, i) => `M${this.timeline.scale(d.eDate)}, 124 V 197`)
      .attr('stroke', this.timeline.style.week.sColor)
      .attr('stroke-width', 0.5)



    },
    putTableValues() {
      d3.select(`#${this.localId}`).select('.table_values').remove()

      if(!this.Queries.SQL3 || this.Queries.SQL3.length === 0) return

      let dataTable = this.SkylineCharts
      .append('g')
      .attr('class', 'table_values')
      .attr('transform', `translate(${this.timeline.baseX},${this.timeline.baseY + 60})`)

      let prevWeek_ = null
      // Group of all values
      dataTable
      .append('g')
      .selectAll('g')
      .data(this.timeline.week)
      .enter()
      .append('g')
      .attr('transform', (_, i) => `translate(${this.timeline.weekSize * i}, 45)`)
      .selectAll('text')
      .data(d => {
        let data_ = {...this.Queries.SQL3.find(d___ => {
          return __M(d.eDate).format('YYYY-MM-DD') == __M(d___.CUTOFF).day(5).format('YYYY-MM-DD')
        })}

        if(!data_) {
          if(!prevWeek_) return {}
          
          data_ = prevWeek_
          Object.keys(data_).forEach(k_ => { if(!['A_CUM_PG'].includes(k_)) data_[k_] = 0 })
        } else prevWeek_ = { ...data_ }

        return this.table.values1.map((v_, j) => ({
          date: data_[this.activity],
          y: this.labelYGap * j,
          tColor: v_.tColor || '#757575',
          value: data_[v_.colName]
        }))
      })
      .enter()
      .append('text')
      .attr('transform', d => `translate(${this.timeline.weekSize-2},${d.y})`)
      .attr('font-family', 'roboto')
      .attr('font-size', 9)
      .attr('fill', d => d.tColor)
      .attr('text-anchor', 'end')
      .attr('alignment-baseline', 'middle')
      .text(d => d.value ? Number(d.value).toLocaleString() : '')



      
      let dataTable2 = this.SkylineCharts
      .append('g')
      .attr('class', 'table_values')
      .attr('transform', `translate(${this.timeline.baseX},${this.timeline.baseY + 150})`)

      dataTable2
      .append('g')
      .selectAll('g')
      .data(this.timeline.week)
      .enter()
      .append('g')
      .attr('transform', (_, i) => `translate(${this.timeline.weekSize * i}, 45)`)
      .selectAll('text')
      .data(d => {
        let data_ = {...this.Queries.SQL3.find(d___ => {
          return __M(d.eDate).format('YYYY-MM-DD') == __M(d___.CUTOFF).day(5).format('YYYY-MM-DD')
        })}

        if(!data_) {
          if(!prevWeek_) return {}
          
          data_ = prevWeek_
          Object.keys(data_).forEach(k_ => { if(!['A_CUM_PG'].includes(k_)) data_[k_] = 0 })
        } else prevWeek_ = { ...data_ }

        return this.table.values2.map((v_, j) => ({
          date: data_[this.activity],
          y: this.labelYGap * j,
          tColor: v_.tColor || '#757575',
          value: data_[v_.colName]
        }))
      })
      .enter()
      .append('text')
      .attr('transform', d => `translate(${this.timeline.weekSize-2},${d.y})`)
      .attr('font-family', 'roboto')
      .attr('font-size', 9)
      .attr('fill', d => d.tColor)
      .attr('text-anchor', 'end')
      .attr('alignment-baseline', 'middle')
      .text(d => d.value ? Number(d.value).toLocaleString() : '')

    }
  }
}
