import * as d3 from 'd3'
import Data from '../primitives/donutProps'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({
    headers: [
      { name: 'Tag No.'             , colname: 'TAG'                , tColor: '#b5b5b5', x: 35 , cx: 30 },
      { name: 'Description'         , colname: 'TAG_DESCR'          , tColor: '#454545', x: 140, cx: 117 },
      { name: 'PO'                  , colname: 'PO_ACTUAL'          , tColor: '#b5b5b5', x: 420, cx: 400 },
      { name: 'FAT'                 , colname: 'FAT_ACTUAL'         , tColor: '#454545', x: 476, cx: 460 },
      { name: 'ETA'                 , colname: 'ETA_ACTUAL'         , tColor: '#b5b5b5', x: 540, cx: 520 },
      { name: 'ROS'                 , colname: 'ROS'                , tColor: '#454545', x: 595, cx: 580 },
      { name: 'Upper Block Erection', colname: 'UPPERBLOCK_ERECTION', tColor: '#b5b5b5', x: 637, cx: 640 },
      { name: 'Lifting'             , colname: 'LIFTING'            , tColor: '#454545', x: 710, cx: 700 },
      { name: 'Install'             , colname: 'INSTALL'            , tColor: '#b5b5b5', x: 769, cx: 760 },
    ],
    tWidth: 820,
  }),
  methods: {

    drawNoContents() {
      let noData = this.svg
      .append('g')
      .attr('transform', `translate(${this.Canvas.CanvasWidth / 2}, 200)`)
      noData
      .append('text')
      .style('font-weight', 500)
      .style('font-size', 30)
      .attr('text-anchor', 'middle')
      .style('font-style', 'italic')
      .attr('alignment-baseline', 'middle')
      .attr('fill', '#CFCFCF')
      .text('No Data Available.')
    },
    drawChart() {
      let table__ = this.svg
      .append('g')
      .attr('class', '__table')
      .attr('transform', `translate(5.5, 160.5)`)

      // Header Rows
      table__.append('rect').attr('x', 395).attr('y', 0.5).attr('width', this.tWidth - 395).attr('height', 40.5).attr('stroke-width', 0).attr('fill', '#F4F5F5').attr('opacity', 0.75)
      table__.append('line').attr('x1', 395).attr('y1', 0).attr('x2', this.tWidth).attr('y2', 0).attr('stroke-width', 0.3).attr('stroke', '#333')
      table__.append('line').attr('x1', 0).attr('y1', 40).attr('x2', this.tWidth).attr('y2', 40).attr('stroke-width', 0.3).attr('stroke', '#333')

      // Header Text
      table__
      .append('g')
      .attr('class', '__header_text')
      .attr('transform', `translate(0, 13)`)

      this.headers.forEach(d => {
        if(d.colname == 'UPPERBLOCK_ERECTION')
          table__
          .append('text')
          .attr('transform', `translate(${d.x}, 9.5)`)
          .style('font-family','roboto')
          .style('font-size',10)
          .attr('fill', '#757575')
          .attr('alignment-baseline', 'middle')
          .append('tspan').attr('x', 0).attr('y', 8).text('Upper Block')
          .append('tspan').attr('x', 9).attr('y', 23).text('Erection')

        else
          table__
          .append('text')
          .attr('x', d.x)
          .attr('y', 30)
          .style('font-family','roboto')
          .style('font-size', 10)
          .attr('text-anchor', 'start')
          .attr('alignment-baseline', 'middle')
          .attr('fill', '#757575')
          .text(d.name)
      })

      let tablebody = table__
      .append('g')
      .attr('class', '__table_body')
      .attr('transform', `translate(0, 42)`)

      let sectorHeight = 0
      this.level1Names.forEach(name => {
        let items___ = this.DataItems.filter(item => item.DISC == name)
        let sector__ = tablebody
        .append('g')
        .attr('transform', `translate(0, ${sectorHeight})`)

        sector__.append('rect').attr('x', 0).attr('y', 0).attr('width', this.tWidth).attr('height', 19).attr('stroke-width', 0).attr('fill', '#F4F5F5').attr('opacity', .45)
        sector__.append('line').attr('x1', 0).attr('y1', 19).attr('x2', this.tWidth).attr('y2', 19).attr('stroke-width', 0.3).attr('stroke', '#b5b5b5')
        sector__
        .append('text')
        .attr('transform', `translate(15, 10)`)
        .style('font-family','roboto')
        .style('font-weight', 500)
        .style('font-size', 12)
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .attr('fill', '#03a9f4')
        .text(name)
    
        items___.forEach((item, i) => {
          let rowdata = sector__
          .append('g')
          .attr('class', '__row_data')
          .attr('transform', `translate(0, ${this.rowHeight * i + 25})`)
  
          if(i % 2 == 1)
            rowdata.append('rect').attr('width', this.tWidth).attr('height', this.rowHeight).attr('stroke-width', 0).attr('fill', '#F4F5F5').attr('opacity', 0.4)
  
          this.headers.forEach((h, j) => {
            if(j === 1) {
              rowdata
              .append('text')
              .attr('transform', `translate(12, 9.5)`)
              .style('font-family','roboto')
              .style('font-size', 10)
              .attr('fill', '#c5c5c5')
              .attr('text-anchor', 'middle')
              .attr('alignment-baseline', 'middle')
              .text(i + 1)
              
              rowdata
              .append('g')
              // .attr('clip-path', 'url(#activity_overflow_none)')
              .append('text')
              .attr('transform', `translate(${h.cx}, 9.5)`)
              .style('font-family','roboto')
              .style('font-size', 10)
              .attr('fill', h.tColor)
              .attr('alignment-baseline', 'middle')
              .text(item[h.colname] || '')

            } else {
              
              let text = rowdata
              .append('text')
              .attr('transform', `translate(${item[h.colname] && item[h.colname].length < 10 ? h.cx + 11 : h.cx}, 9.5)`)
              .style('font-family','roboto')
              .style('font-size',10)
              .attr('fill', h.tColor)
              .attr('alignment-baseline', 'middle')
              .text(item[h.colname] || '')

              if(j==0) {
                text
                .attr('fill', '#03a9f4')
                .on('mouseover', (_, i, a) => {
                  d3.select(a[i])
                  .style('cursor', 'pointer')
                  .attr('fill', '#000')
                })
                .on('mouseout', (_, i, a) => {
                  d3.select(a[i])
                  .style('cursor', 'default')
                  .attr('fill', '#03a9f4')
                })
                .on('click', () => { 
                  let dataSource = { TAG: item[h.colname] || '' }
                  let request_ = this.getRequestColumnProps('eqpopup', dataSource, this.ColumnProps)
                  // if(request_) { //예시
                  //   request_.filters = {
                  //     ...request_.filters,
                  //     ...this.FilterValues
                  //   }
                  //   this.$emit('request-action', request_)
                  // }
                  this.$emit('request-action', request_)
                })
              }
            }
          })
        })
        
        sectorHeight += this.rowHeight * items___.length + 28
      })
    }, 
    drawDonuts() {
      if(!this.Queries.SQL1 || this.Queries.SQL1.length === 0) return

      let arcProps = {
        angleSize: 360,
        angleStart: 0,
        bColor: '#F4F5F5',
        stroke: 4,
        Ra: 28
      }
      let values_ = this.Queries.SQL1[0]
      
      let donutGroup = this.svg
      .append('g')
      .attr('class', '_donut_group__for_all')
      .attr('transform', `translate(70, 90)`)

      donutGroup.append('line').attr('x1', - 63).attr('y1', 0).attr('x2', this.tWidth - 63).attr('y2', 0).attr('stroke-width', 2).attr('stroke', '#f5f5f5')

      Data.attrs.forEach((attr, i) => {
        let arc = d3.arc()
        .outerRadius(d => d.Ra)
        .innerRadius(d => d.Ra - d.stroke)
        .startAngle(d => d.angleStart * Math.PI / 180)
        .endAngle(d => d.angleEnd || d.angleStart * Math.PI / 180)

        let x__ = Data.width * i + (i > 0 ? Data.gab : 0)
        let donut_ = donutGroup
        .append('g')
        .attr('class', `_donut_chart__${attr.refColumn.toLowerCase()}`)
        .attr('transform', `translate(${x__}, 0)`)

        // Draw Background Donut Chart
        donut_
        .append('circle')
        .attr('cx', 0).attr('cy', 0).attr('r', arcProps.Ra-3)
        .attr('stroke', arcProps.bColor).attr('stroke-width', arcProps.stroke)
        .attr('fill', 'url(#lightGray)')

        let circleRate = values_[attr.refColumn] / values_['TOTAL']

        // Draw Foreground Donut Chart
        donut_
        .datum(arcProps)
        .append('path')
        .attr('d', arc)
        .attr('fill', attr.fColor)
        .attr('opacity', 0.5)
        .transition()
        .duration(1500)
        .attrTween('d',(d) => {
          let endAngle_ = ((d.angleSize * circleRate) + d.angleStart) * Math.PI / 180
          let interpolate = d3.interpolate(d.angleStart * Math.PI / 180, endAngle_)
          return function(t) {
            d.angleEnd = interpolate(t)
            return arc(d);
          }
        })

        donut_
        .append('text')
        .attr('transform', `translate(0, 1)`)
        .style('font-family', 'roboto')
        .style('font-size', 12)
        .style('fill', attr.tColor)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(values_[attr.refColumn])

        // Legend
        donut_
        .append('text')
        .attr('transform', `translate(0, 40)`)
        .style('font-family', 'roboto')
        .style('font-size', 9)
        .style('fill', '#757575')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(attr.text)
      })
    }
  }
}

