import * as d3 from 'd3'
import __M from 'moment'
import DonutData from '../primitives/donutProps'

export default {
  data: () => ({
    chartMounted: false,

    filtering: {},
    filterRequested: false,

    on4Weeks: true,
    
    scaleY: null,
    maxValue: 0,
    cutoff: null,

    pageSchedule: '',

    onRundownPlan: true,
    onRundownActual: true,
    onRundownTrend: true
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },
  methods: {
    setDefaultValues() {
      
      let cutoff = __M(this.Queries.SQL2[0].CDATE).format('YYYY-MM-DD')
      this.cutoff = cutoff

      this.timelineKeyName = this.activity
      this.timeline.weekCount = []

      let data = JSON.parse(JSON.stringify(this.DataItems))
      let dateList = data.map(d => d[this.activity] && new Date(d[this.activity])).filter(d => !!d)
      
      let minDate = __M(new Date(Math.min.apply(null, dateList))).format('YYYY-MM-DD')


      // const startDate = minDate   // 기존
      const startDate = __M(new Date(cutoff)).subtract(4, 'weeks').format('YYYY-MM-DD')
      const finishDate = __M(new Date(cutoff)).add(4, 'weeks').format('YYYY-MM-DD')

      let daterange = [
        { [this.activity]: startDate },
        { [this.activity]: finishDate },
      ]


      this.setData()
      // this.dataSet.sort(this.compare)
      // this.setTimelineEnv(this.dataSet)

      // force ranging the timeline -------
      let timelineRange_ = daterange
      this.setTimelineEnv(timelineRange_)
      // -------------------------- -------

      this.dataSet.forEach(d => {
        let date__ = this.getTimelineCDate(d[this.timelineKeyName])
        d.cDate = date__ ? date__.eDate : null
        d.sDate = date__ ? date__.sDate : null
        d.date = d3.timeParse('%Y-%m-%d')(d[this.timelineKeyName])
      })

      this.style.forEach(style => {
        style.item.forEach(item => {
          this.dataSet.filter(f => f[item.refColumn] == item.codeValue).forEach(d => {
            d.tStyle   = item.tStyle
            d.tSize    = item.tSize
            d.tColor   = item.tColor
            d.sWidth   = item.sWidth
            d.sColor   = d.sColor ? d.sColor : item.sColor
            d.bColor   = d.A_PG == 100 ? DonutData.attrs.find( donut => d.SUBSYSTEM_STATUS == donut.codeValue).fColor : '#ffee58'
            d.opacity  = item.opacity
            d.radius   = item.radius
            if (item.dataColumn != '') d.progress = 'Y'
          })
        })
      })

      this.timeline.height = this.timeline.style.week.height
      if (this.timeline.style.month.display == 'Y') this.timeline.height += this.timeline.style.month.height
      if (this.timeline.style.year.display == 'Y') this.timeline.height += this.timeline.style.year.height
      
      this.timeline.week.forEach(w => {
        let count = 0
        this.dataSet.filter(f => f.cDate == w.eDate).forEach(() => {
          count += 1
        })
        this.timeline.weekCount.push(count)
      })
      let lightGray = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `lightGray`).attr('x1', 0.772).attr('x2', 0.228).attr('y1', 0.228).attr('y2', 0.919)
      lightGray.append('stop').attr('stop-color', '#fff').attr('offset', '0').attr('stop-opacity', 1)
      lightGray.append('stop').attr('stop-color', '#d0d2d3').attr('offset', '1').attr('stop-opacity', 1)
      // line data
      this.set4weekSummaryData()

      // let realHeight = Math.max(...this.timeline.weekCount) * (this.skyline.box.height)
      // if (realHeight > this.skyline.minSize) {
      //   // this.skyline.line.height = realHeight
      //   this.timeline.baseY = realHeight + this.skyline.marginTop
      // } else {
      //   // this.timeline.baseY = this.skyline.line.height = this.skyline.minSize
      // }

      this.timeline.baseY = 450

    },
    setData() {
      this.dataSet = JSON.parse(JSON.stringify(this.DataItems.filter(f => !!f[this.timelineKeyName])))
    },
    setSummaryData() {
      this.timeline.plan = []
      this.timeline.actual = []
      this.timeline.trend = []

      if(!this.Queries.SQL3 || this.Queries.SQL3.length === 0) return

      this.lineChartData = JSON.parse(JSON.stringify(this.Queries.SQL3))
      this.lineChartData.forEach(d => {
        d.eDate = new Date(d.WD_PLAN)
      })

      let prevLineValues_ = {
        plan: null,
        actual: null,
        forecast: 0,
        trend: null
      }

      this.timeline.week.forEach(w => {
        let lineData_ = this.lineChartData.find(f => f.WD_PLAN == __M(w.eDate).format('YYYY-MM-DD'))

        if (lineData_ && lineData_.PLAN_CUM >= 0) {
          this.timeline.plan.push({ 
            eDate: w.eDate, 
            mDate: w.mDate,
            sDate: w.sDate,
            value: lineData_ && typeof lineData_.PLAN_CUM == 'number' ? lineData_.PLAN_CUM : prevLineValues_.plan
          })
        }

        if (lineData_ && lineData_.ACTUAL_CUM >= 0) {
          this.timeline.actual.push({ 
            eDate: w.eDate, 
            mDate: w.mDate,
            sDate: w.sDate,
            value: lineData_ && typeof lineData_.ACTUAL_CUM == 'number' ? lineData_.ACTUAL_CUM : prevLineValues_.actual
          })
        }
        
      })

    },
    set4weekSummaryData() {
      this.timeline.plan = []
      this.timeline.actual = []
      this.timeline.trend = []

      if(!this.Queries.SQL6 || this.Queries.SQL6.length === 0) return

      this.lineChartData = JSON.parse(JSON.stringify(this.Queries.SQL6))
      this.lineChartData.forEach(d => {
        d.eDate = new Date(d.CUTOFF)
      })

      let prevLineValues_ = {
        plan: null,
        actual: null,
        forecast: 0,
        trend: null
      }

      this.timeline.week.forEach(w => {
        let lineData_ = this.lineChartData.find(f => f.CUTOFF == __M(w.eDate).format('YYYY-MM-DD'))

        if (lineData_ && lineData_.TOTAL >= 0) {
          this.timeline.plan.push({ 
            eDate: w.eDate, 
            mDate: w.mDate,
            sDate: w.sDate,
            value: lineData_ && typeof lineData_.TOTAL == 'number' ? lineData_.TOTAL : prevLineValues_.plan
          })
        }

        if (lineData_ && lineData_.REMAIN >= 0) {
          this.timeline.actual.push({ 
            eDate: w.eDate, 
            mDate: w.mDate,
            sDate: w.sDate,
            value: lineData_ && typeof lineData_.REMAIN == 'number' ? lineData_.REMAIN : prevLineValues_.actual
          })
        }

        if (lineData_ && lineData_.WKS_TREND >= 0) {
          this.timeline.trend.push({ 
            eDate: w.eDate, 
            mDate: w.mDate,
            sDate: w.sDate,
            value: lineData_ && typeof lineData_.WKS_TREND == 'number' ? lineData_.WKS_TREND : prevLineValues_.trend
          })
        }

        
      })

    },
    setLineScale() {

      // let max = this.on4Weeks ? 20000 : 72000

      // let arr = this.lineChartData.filter(d => this.on4Weeks ? d.REMAIN : d.TOTAL).map(d => this.on4Weeks ? d.REMAIN : d.TOTAL)
      // let getMax = Math.max(...arr)

      
      let chartData = ['PLAN_CUM', 'ACTUAL_CUM', 'CUR_PLAN_CUM', 'CUR_ACTUAL_CUM']
      let weeksChartData = ['TOTAL', 'WKS_TREND', '4WKS']

      let arr = []
      this.lineChartData.forEach(data => {
        if (this.on4Weeks) {
          weeksChartData.forEach(column => {
            if (data[column]) arr.push(data[column])
          })
        }else {
          chartData.forEach(column => {
            if (data[column]) arr.push(data[column])
          })
        }
        
      })

      let getMax = Math.max(...arr)

      this.scaleY = d3
      .scaleLinear()
      .domain([0, getMax])
      .range([this.timeline.baseY, this.timeline.baseY - this.skyline.line.height])

      this.lineZero = d3.line()
      .x(d => this.timeline.scale(d.eDate))
      .y(this.scaleY(0))

      this.lineFunc = d3.line()
      .x(d => this.timeline.scale(d.eDate))
      .y(d => this.scaleY(d.value))
    },
    get4Weeks() {
      let data = JSON.parse(JSON.stringify(this.DataItems))

      let cutoff = this.cutoff
      let dateList = data.map(d => d[this.activity] && new Date(d[this.activity])).filter(d => !!d)
      
      let minDate = __M(new Date(Math.min.apply(null, dateList))).format('YYYY-MM-DD')


      const startDate = minDate
      const finishDate = __M(new Date(cutoff)).add(4, 'weeks').format('YYYY-MM-DD')

      this.filteredList = data.filter(d => __M(new Date(d[this.activity])).format('YYYY-MM-DD') >= startDate &&  __M(new Date(d[this.activity])).format('YYYY-MM-DD') <= finishDate )

      // this.draw()

    },

  }
}