import * as d3 from 'd3'
import Defs from '../../../../../../includes/primitives/Color_Defs'
import Filter from '../../../../../../includes/primitives/Filter_Defs'
// ------------------------------------------------------------------------------- Global

export default {
  methods: {
    Draw_Charts() {

      setTimeout(() => {
        let Overall = this.svg
        .append('g')
        .attr('id', '_Group')
        .attr('opacity', 1).style('font-family', 'roboto')
        .attr('transform', `translate(0,0) scale(1)`)

        Overall
        .append('image')
        .attr('id', `image_wrap`)//C6EFFF
        .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/China1/Interface/overall.jpg`)
        .attr('transform', `translate(0,0)`)

        this.floatGroup = Overall
        .append('g')
        .attr('class', 'float_group')
        .attr('transform', `translate(0, 0)`)

        /*---------------------------------------IMAGES*/
        this.Coordinate.filter(d => d.CATEGORY != 'InterfaceGate').forEach(d => {
          let data = this.DataItems.find(c => (d.CODE == c.CODE))
          let category =`${data.CODE}`
          // console.log(category)
          let floatBox = this.floatGroup
            .append('g')
            .attr('id', category)
            .attr('transform', `translate(${d.x}, ${d.y})`)
          if(d.CATEGORY == 'Contractor'){
            floatBox
            .style('cursor', 'pointer')
            .on('mouseover', () => {
              d3.select(`#${category}`).transition().style('opacity',0.9)
            })
            .on('mouseout', () => {
              d3.select(`#${category}`).transition().style('opacity', 1)
            })
            .on('click', () => {
              // console.log(`${data.CODE}`)
              let dataSource = {
                REQUESTER: data.CODE
              }
              let request_ = this.getRequestColumnProps('area', dataSource, this.ColumnProps)
              this.$emit('request-action', request_)
            })
          }
          
          floatBox
          .append('rect')
          .attr('width', 116)
          .attr('height', 58)
          .attr('fill', d.COLOR)
          .attr('rx', '5')
          .attr('opacity', 0.8)
          // if(c.DIRECT == 'R'){
          //   areaItemTooltip
          //   .append('path')
          //   .attr('transform', `translate(166, 16) rotate(90)`)
          //   .attr('fill', '#fff')
          //   .attr('d', 'M5,0l5,6H0Z')
          //   .attr('opacity', 0.9)
          // } else{
          //   areaItemTooltip
          //   .append('path')
          //   .attr('transform', `translate(-6, 48) rotate(-90)`)
          //   .attr('fill', '#fff')
          //   .attr('d', 'M5,0l5,6H0Z')
          //   .attr('opacity', 0.9)
          // }
          floatBox
          .append('text')
          .attr('id', 'value_code_item')
          .attr('transform', `translate(60, 18)`)
          .style('font-size', 13).style('font-weight','bold')
          .style('fill', '#000')
          .attr('text-anchor', 'middle')
          .text(data.CONTRACTOR_DESCR2)
          floatBox
          .append('text')
          .attr('id', 'value_code_item')
          .attr('transform', `translate(60, 35)`)
          .style('font-size', 11)
          .style('fill', '#000')
          .attr('text-anchor', 'middle')
          .text(data.CONTRACTOR_AREA)
          floatBox
          .append('text')
          .attr('id', 'value_code_item')
          .attr('transform', `translate(60, 50)`)
          .style('font-size', 11)
          .style('fill', d.CATEGORY == 'Third Parties'?'#000':'#1E88E5')
          .attr('text-anchor', 'middle')
          .text(data.CONTRACTOR_SCOPE)
  
        })
        
        this.Coordinate.filter(d => d.CATEGORY == 'InterfaceGate').forEach(d => {
          let data = this.DataItems.find(c => (d.CODE == c.CODE))
          let category =`${data.CODE}`

          let IG = this.floatGroup
          .append('g')
          .attr('id', category)
          .attr('transform', `translate(${d.x}, ${d.y})`)
          .style('cursor', 'pointer')
          .on('mouseover', () => {
            d3.select(`#${category}`).transition().style('opacity',0.9)
          })
          .on('mouseout', () => {
            d3.select(`#${category}`).transition().style('opacity', 1)
          })
          .on('click', () => {
            // console.log(`${data.CODE}`)
            let dataSource = {
              IG_NO: data.CODE
            }
            let request_ = this.getRequestColumnProps('interface', dataSource, this.ColumnProps)
            this.$emit('request-action', request_)
          })
          IG
          .append('rect')
          .attr('width', 80)
          .attr('height', 20)
          .attr('fill', d.COLOR)
          .attr('rx', '5')
          .attr('opacity', 0.9)

          IG
          .append('text')
          .attr('id', 'value_code_item')
          .attr('transform', `translate(40, 14)`)
          .style('font-size', 12)
          .style('fill', '#fff')
          .attr('text-anchor', 'middle')
          .text(data.CODE)
        })
        this.Chart_Summary()
      },50)
      
    },
    setEvent_Block() {
      this.Goto_Block_Summary = (selection, mask, section) => {
        selection
        .on('mouseover', () => {
          d3.select(`#${mask}`).transition().style('opacity',0)
        })
        .on('mouseout', () => {
          d3.select(`#${mask}`).transition().style('opacity', 0.5)
        })
        .on('click', () => {
          // console.log(section)
          let dataSource = {
            BID_SECTION_PACK: section
          }
          let request_ = this.getRequestColumnProps('subcontractor', dataSource, this.ColumnProps)
          this.$emit('request-action', request_)
        //   let request_ = {
        //     dataType: 'row',
        //     action: {
        //       type: 'direct',
        //       target: 'slide-modal',
        //       component: 'ServiceSvgTabs',
        //       id: 286,
        //       no: 'LIBSVGTB-0001'
        //     },
        //     filters: { 
        //       GWBS_LV1: d.Lv2, //받을값을 보낸다
        //       BLOCK: d.Blk,
        //       MBLOCK: d.Blk,
        //       DESC: '',
        //       // DESC: `${d.AREA} (${d.STATUS})`,
        //     },
        //     iFilters: {
        //       filterString : '',
        //       inputFilter  : ''
        //     }
        //   }
        //   this.$emit('request-action', request_)
        })
      }
    },
    Chart_Summary(){
      let SummaryGroup = this.svg
      .append('g')
      .attr('id', 'summary_wrap')
      .attr('transform', `translate(10,1340)`)
      .style('font-family', 'roboto')

      SummaryGroup
      .append('rect')
      .attr('width', 1070)
      .attr('height', 250)
      .attr('fill', '#fff')
      .attr('rx', '5').attr('opacity', 0.6)

      let Summary = SummaryGroup
        .append('g')
        .attr('id', 'summary')
        .attr('transform', `translate(20,10)`)

      this.Status.forEach((d,i)=>{
        Summary
        .append('rect').attr("rx", 5).attr('x', 260* i).attr('y', 0)
        .attr('width', 250).attr('height', 20)
        .attr('stroke', '#cccccc').attr('stroke-width', 0.3).attr('fill', d.bColor).style('opacity',.7)

        Summary
        .append('text')
        .attr('transform', `translate(${260* i+125}, 12)`)
        .style('font-size', 12).style('fill', '#212121').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text(d.title)

        Summary
        .append('rect').attr("rx", 5).attr('x', 260* i).attr('y', 26)
        .attr('width', 250).attr('height', 200)
        .attr('stroke', '#cccccc').attr('stroke-width', 0.3).attr('fill', '#fff').style('opacity',.9)

      })
      
      this.Draw_PieCharts()
      this.Draw_Pie3Charts()
      this.Draw_Pie2Charts()
      this.Draw_Pie5Charts()
    },
    Draw_PieCharts() {
      
      let data = null, data2 = null,
          style = null, 
          cellX = null, 
          Circle = null, 
          Guideline = null, tmp = null

      let overallMCStatus = this.svg
      .append('g')
      .attr('class','donut_wrap')
      .attr('transform', `translate(20.5, 1400.5)`)

      style = {
        id: 'SC',
        x: 80,
        y: 80,
        name: 'Total',
        sort: 'N',
      }
      tmp = this.Queries.SQL1[0]
      // console.log(tmp)
      data = [
        // {title: 'New' ,  value: tmp.NEW / tmp.TOTAL * 100, qty: tmp.NEW},
        {title: 'New' ,  value: tmp.NEW, qty: tmp.NEW},
        {title: 'Deleted'   ,  value: tmp.DELETED, qty: tmp.DELETED},
        {title: 'In progress'  ,  value: tmp.INPROGRESS, qty: tmp.INPROGRESS},
        {title: 'Delivered'  ,  value: tmp.DELIVERED, qty: tmp.DELIVERED},
        {title: 'Closed'  ,  value: tmp.CLOSED, qty: tmp.CLOSED},
        {title: 'Rejected'  ,  value: tmp.REJECTED, qty: tmp.REJECTED},
        {title: 'Resubmitted'  ,  value: tmp.RESUBMITTED, qty: tmp.RESUBMITTED},
      ]
      Circle = {
        CircleDiameter: 60,
        // CircleColor: ['LightGray', 'Gray', 'Yellow', 'Gold', 'Orange','Pink','Violet','Red','Thistle','Purple','Indigo','LightBlue','SkyBlue','SteelBlue','YellowGreen','DarkGreen' ],
        CircleColorSet: ['LightBlue5', 'DeepOrange3','Amber4','Lime4','Teal2','Cyan3','Green5','Blue6','Indigo3'],
        CircleRadius: 0,
        CircleHoleSize: 40,
        CircleStackSize: 5,
        CircleSliceSize: 3,
        CircleTitleSize: 11,
        CircleLegendSpace: -40,
        CircleLegendPosition: 'bottom',
        outerRadius: 60
      }
      Guideline = {
        GuideDisplay: 'N',
      }
      data2 =[
        {title: 'Overdue IP'  ,  value: tmp.OVERDUE_IP, qty: tmp.OVERDUE_IP,color:'#1E88E5',width:52},
        {title: 'Critical IP'  ,  value: tmp.CRITICAL_IP, qty: tmp.CRITICAL_IP,color:'#7986CB',width:45},
      ]
      this.draw_pie2(overallMCStatus, data, style, Circle, Guideline,data2) // ---------------------------> 

    },
    
    Draw_Pie3Charts() {
      
      let data = null, data2=null,
          style = null, 
          cellX = null, 
          Circle = null, 
          Guideline = null, tmp = null

      let overallMCStatus = this.svg
      .append('g')
      .attr('class','donut_wrap')
      .attr('transform', `translate(540.5, 1400.5)`)

      style = {
        id: 'SC',
        x: 80,
        y: 80,
        name: 'Total',
        sort: 'N',
      }
      tmp = this.Queries.SQL3[0]
      // console.log(tmp)
      data = [
        // {title: 'New' ,  value: tmp.NEW / tmp.TOTAL * 100, qty: tmp.NEW},
        {title: 'New' ,  value: tmp.NEW, qty: tmp.NEW},                             //LightBlue5
        {title: 'Deleted'   ,  value: tmp.DELETED, qty: tmp.DELETED},               //DeepOrange3
        {title: 'In progress'  ,  value: tmp.INPROGRESS, qty: tmp.INPROGRESS},      //Amber4
        {title: 'Delivered'  ,  value: tmp.DELIVERED, qty: tmp.DELIVERED},          //Lime4
        {title: 'Closed'  ,  value: tmp.CLOSED, qty: tmp.CLOSED},                   //Teal2
        {title: 'Rejected'  ,  value: tmp.REJECTED, qty: tmp.REJECTED},             //Cyan3
        {title: 'Resubmitted'  ,  value: tmp.RESUBMITTED, qty: tmp.RESUBMITTED},    //Green5
      ]
      data2 =[
        {title: 'Overdue IP'  ,  value: tmp.OVERDUE_IP, qty: tmp.OVERDUE_IP,color:'#1E88E5',width:52},
        {title: 'Critical IP'  ,  value: tmp.CRITICAL_IP, qty: tmp.CRITICAL_IP,color:'#7986CB',width:45},
      ]
      Circle = {
        CircleDiameter: 60,
        // CircleColor: ['LightGray', 'Gray', 'Yellow', 'Gold', 'Orange','Pink','Violet','Red','Thistle','Purple','Indigo','LightBlue','SkyBlue','SteelBlue','YellowGreen','DarkGreen' ],
        CircleColorSet: ['LightBlue5', 'DeepOrange3','Amber4','Lime4','Teal2','Cyan3','Green5','Blue6','Indigo3'],
        CircleRadius: 0,
        CircleHoleSize: 40,
        CircleStackSize: 5,
        CircleSliceSize: 3,
        CircleTitleSize: 11,
        CircleLegendSpace: -40,
        CircleLegendPosition: 'bottom',
        outerRadius: 60
      }
      Guideline = {
        GuideDisplay: 'N',
      }
      this.draw_pie2(overallMCStatus, data, style, Circle, Guideline,data2) // ---------------------------> 
    },
    Draw_Pie2Charts() {
      
      let data = [], 
          style = null, 
          cellX = null, 
          Circle = null, 
          Guideline = null,
          tmpE = null, 
          tmpM = null, 
          tmpI = null, 
          tmpH = null

      let chart2 = this.svg
      .append('g')
      .attr('class','donut2')
      .attr('transform', `translate(290.5, 1400.5)`)

      let disc = this.Queries.SQL2.map(d => d.REQUESTER)
      // console.log(disc)

      style = {
        id: 'pie4',
        x: 80,
        y: 80,
        name: disc,
        sort: 'N',
      }

      // tmpE = this.Queries.SQL2.find(f=> f.CONTRACTOR_DESCR == 'E2-SEI' )
      // tmpM = this.Queries.SQL2.find(f=> f.CONTRACTOR_DESCR == 'HQ-HQGD')
      // tmpI = this.Queries.SQL2.find(f=> f.CONTRACTOR_DESCR == 'E7-COOEC')
      // tmpH = this.Queries.SQL2.find(f=> f.CONTRACTOR_DESCR == 'W1-SHDI')

      // data = [
      //   {title: 'E2-SEI'  ,  value: tmpE.TOTAL, qty: tmpE.TOTAL},
      //   {title: 'HQ-HQGD'   ,  value: tmpM.TOTAL, qty: tmpM.TOTAL},
      //   // {title: 'E7-COOEC'   ,  value: tmpI.TOTAL, qty: tmpI.TOTAL},
      //   // {title: 'W1-SHDI'   ,  value: tmpH.TOTAL, qty: tmpH.TOTAL},
      // ]
      this.Queries.SQL2.forEach((d,i)=>{
        data.push({title: d.REQUESTER  ,  value: d.TOTAL, qty: d.TOTAL})
      })
      Circle = {
        CircleDiameter: 60,
        // CircleColor: ['LightGray', 'Gray', 'Yellow', 'Gold', 'Orange','Pink','Violet','Red','Thistle','Purple','Indigo','LightBlue','SkyBlue','SteelBlue','YellowGreen','DarkGreen' ],
        CircleColorSet: ['Red1', 'Red2','Red3','Red4','Red5' ],
        CircleRadius: 0,
        CircleHoleSize: 40,
        CircleStackSize: 5,
        CircleSliceSize: 3,
        CircleTitleSize: 11,
        CircleLegendSpace: -40,
        CircleLegendPosition: 'bottom',
        outerRadius: 60
      }
      Guideline = {
        GuideDisplay: 'N',
      }
      this.draw_pie2(chart2, data, style, Circle, Guideline) // ---------------------------> 
    },
    Draw_Pie5Charts() {
      
      let data = [], 
          style = null, 
          cellX = null, 
          Circle = null, 
          Guideline = null,
          tmpE = null, 
          tmpM = null, 
          tmpI = null, 
          tmpH = null

      let chart2 = this.svg
      .append('g')
      .attr('class','donut2')
      .attr('transform', `translate(810.5, 1400.5)`)

      let disc = this.Queries.SQL4.map(d => d.REQUESTER)
      // console.log(disc)

      style = {
        id: 'pie4',
        x: 80,
        y: 80,
        name: disc,
        sort: 'N',
      }
      this.Queries.SQL4.forEach((d,i)=>{
        data.push({title: d.REQUESTER  ,  value: d.TOTAL, qty: d.TOTAL})
      })
      Circle = {
        CircleDiameter: 60,
        // CircleColor: ['LightGray', 'Gray', 'Yellow', 'Gold', 'Orange','Pink','Violet','Red','Thistle','Purple','Indigo','LightBlue','SkyBlue','SteelBlue','YellowGreen','DarkGreen' ],
        CircleColorSet: ['Red1', 'Red2','Red3','Red4','Red5','Red6','Red7' ],
        CircleRadius: 0,
        CircleHoleSize: 40,
        CircleStackSize: 5,
        CircleSliceSize: 3,
        CircleTitleSize: 11,
        CircleLegendSpace: -40,
        CircleLegendPosition: 'bottom',
        outerRadius: 60
      }
      Guideline = {
        GuideDisplay: 'N',
      }
      this.draw_pie2(chart2, data, style, Circle, Guideline) // ---------------------------> 
    },
    Draw_Pie4Charts() {
      
      let data = null, 
          style = null, 
          cellX = null, 
          Circle = null, 
          Guideline = null,
          tmpE = null, 
          tmpM = null, 
          tmpI = null, 
          tmpH = null

      let chart2 = this.svg
      .append('g')
      .attr('class','donut2')
      .attr('transform', `translate(810.5, 1400.5)`)

      let disc = this.Queries.SQL4.map(d => d.REQUESTER)
      // console.log(disc)

      style = {
        id: 'pie4',
        x: 80,
        y: 80,
        name: disc,
        sort: 'N',
      }
      tmpE = this.Queries.SQL4.find(f=> f.REQUESTER == 'E2' )
      tmpM = this.Queries.SQL4.find(f=> f.REQUESTER == 'HQ')
      tmpI = this.Queries.SQL4.find(f=> f.REQUESTER == 'E7')
      tmpH = this.Queries.SQL4.find(f=> f.REQUESTER == 'W1')

      data = [
        {title: 'E2-SEI'  ,  value: tmpE.TOTAL, qty: tmpE.TOTAL},
        {title: 'HQ-HQGD'   ,  value: tmpM.TOTAL, qty: tmpM.TOTAL},
        {title: 'E7-COOEC'   ,  value: tmpI.TOTAL, qty: tmpI.TOTAL},
        {title: 'W1-SHDI'   ,  value: tmpH.TOTAL, qty: tmpH.TOTAL},
        
      ]
      Circle = {
        CircleDiameter: 60,
        // CircleColor: ['LightGray', 'Gray', 'Yellow', 'Gold', 'Orange','Pink','Violet','Red','Thistle','Purple','Indigo','LightBlue','SkyBlue','SteelBlue','YellowGreen','DarkGreen' ],
        CircleColorSet: ['Red1', 'Red2','Red3','Red4' ],
        CircleRadius: 0,
        CircleHoleSize: 40,
        CircleStackSize: 5,
        CircleSliceSize: 3,
        CircleTitleSize: 11,
        CircleLegendSpace: -40,
        CircleLegendPosition: 'bottom',
        outerRadius: 60
      }
      Guideline = {
        GuideDisplay: 'N',
      }
      this.draw_pie2(chart2, data, style, Circle, Guideline) // ---------------------------> 
    },
    
    draw_pie2(selection, _data, _style, _Circle, _Guideline,_data2) { 
      
      this.Circle = { ...this.Circle, ..._Circle }
      this.Guideline = { ...this.Guideline, ..._Guideline }
      // console.log(this.Guideline)
      let dataLength = _data.length

      this.Circle.CircleColorSet.forEach((c,i)=> {
        Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', [c], [c, 0.5, 1.0, 0.5, -0.2], this.localId)
      })

      let Shadow = selection
      .append('defs') // Tube Color (Left)
      .append('radialGradient').attr('id', `${this.localId}__Shadow`)
      Shadow.append('stop').attr('stop-color', '#a4a4a4').attr('offset', '0.00').attr('stop-opacity', '1')
      Shadow.append('stop').attr('stop-color', '#ffffff').attr('offset', '1.00').attr('stop-opacity', '0.37')

      this.PieStacks = []
      this.pieCenterAngles = []
      this.pieSectors = []

      var sortData = _data
      if (_style.sort == 'Y') sortData.sort(this.executeSort)
      var sum = 0
      var total = 0
      var complete = 0
      _data.forEach((item, i) => {
        sum = sum + _data[i].value
        total += item.qty
      })
    
      let cumulative = 0
      _data.forEach((item, j) => {
        sortData.forEach((sItem, i) => {
          if (sItem.title == item.title) this.PieStacks.push(this.Circle.CircleDiameter - i * this.Circle.CircleStackSize)
        })
        cumulative = cumulative + (_data[j].value/sum)
        this.pieCenterAngles.push(cumulative - (_data[j].value/sum) / 2)
      })

      this.SharedColorSet = dataLength > this.Circle.CircleColorSet.length ? Array.from({ length: dataLength }, (_, i) => this.Circle.CircleColorSet[i % this.Circle.CircleColorSet.length]) : this.Circle.CircleColorSet
      this.SharedColorSetType = this.Circle.CircleColorType

      let centerTitle = selection
      
      centerTitle
      .append('text')
      .attr('transform', `translate(${_style.x}, ${_style.y})`)
      .style('font-size', 14).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'middle')
      .text(`${total}`)
      centerTitle
      .append('text')
      .attr('transform', `translate(${_style.x}, ${_style.y+12})`)
      .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#9E9E9E').style('text-anchor', 'middle')
      .text(`Total`)



      if(this.Circle.CircleLegendPosition == 'bottom')
      var legendsPie = selection
      .append('g')
      .attr('class','donutlegend')
      .attr('transform', `translate(${_style.x+90}, ${_style.y + this.Circle.CircleLegendSpace-30})`)

      else if(this.Circle.CircleLegendPosition == 'right')
      legendsPie = selection
      .append('g')
      .attr('transform', `translate(${_style.x + this.Circle.CircleLegendSpace}, ${_style.y - this.Circle.CircleDiameter / 1.5})`)

      _data.forEach((d,i) => {
        legendsPie
        .append('rect')
        .attr('transform', `translate(${-11}, ${(i*16)})`)
        .attr('width', 6).attr('height', 6).attr('stroke-width', 0.5).attr('stroke', '#bcbcbc').attr('fill', `url(#${this.localId}__LinearA4${this.SharedColorSet[i]}0)`)

        legendsPie
        .append('text')
        .attr('id', `${_style.id}_LG_${i}`)
        .attr('transform', `translate(${0}, ${(i*16) + 6})`)
        .style('font-size', 10).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'start')
        .text(_data[i].title)

        legendsPie
        .append('text')
        .attr('transform', `translate(${this.getNodeElValue(`#${_style.id}_LG_${i}`, 'width') + 3}, ${(i*16) + 7})`)
        .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'start')
        .text(`(${_data[i].qty.toLocaleString()})`)
        // .text(_data[i].qty)
      })


      // if(!_data2) return
      if(_data2){
        _data2.forEach((d,i) => {
          legendsPie
          .append('rect')
          .attr('transform', `translate(${-11}, ${(i*16 +118)})`)
          .attr('width', 6).attr('height', 6).attr('stroke-width', 0.5).attr('stroke', '#bcbcbc').attr('fill', d.color)

          legendsPie
          .append('text')
          .attr('transform', `translate(${0}, ${(i*16) + 6+118})`)
          .style('font-size', 10).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'start')
          .text(_data2[i].title)

          legendsPie
          .append('text')
          .attr('transform', `translate(${d.width}, ${(i*16) + 7+118})`)
          .style('font-size', 11).style('font-family', 'roboto').attr('fill', '#333').style('text-anchor', 'start')
          .text(`(${_data2[i].qty.toLocaleString()})`)
        })
        legendsPie
        .append('line')
        .attr('x1', -10).attr('y1', 111).attr('x2', 70).attr('y2', 111).attr('stroke-width', 1).attr('stroke', '#BDBDBD')
      }
      
      

      this.get_CoordinatorsXY(_style.x, _style.y, -this.Guideline.GuideInside, 'inX', 'inY')
      this.get_CoordinatorsXY(_style.x, _style.y, this.Guideline.GuideOutside, 'outX', 'outY')
      this.get_PieChartData(_data)

      Filter.setDefs_DropShadow(selection, `_DropShadow`, 1 , 0.1 , 1, 1)

      var arc = d3.arc()
      .outerRadius(this.Circle.outerRadius)
      .innerRadius(this.Circle.CircleHoleSize)
      .cornerRadius(this.Circle.CircleRadius) // Coner Radius

      var pie = d3.pie()
      .sort(null)
      .value((d) => d.value)
      .padAngle(this.Circle.CircleSliceSize/100) // Padding Pie

      let donutGroup = selection
      .append('g')
      .attr('class','donut')
      .attr('transform', `translate(${_style.x},${_style.y})`)

      donutGroup
      .selectAll('path')
      .data(pie(this.pieSectors))
      .enter()
      .append('path')
      .attr('d', arc)
      .attr('fill', (d, i) => `url(#${this.localId}__LinearA4${this.SharedColorSet[i]}0)`)
      .style('filter', `url(#_DropShadow)`)
      .transition()
      .duration(750)
      .attrTween('d', function(d) {
          var interpolate = d3.interpolate({startAngle: 0, endAngle: 0}, d);
          return function(t) {
              return arc(interpolate(t));
          };
        });
      if (_Guideline.GuideDisplay=='Y') this.drawGuidline2(selection, _style.id)
    },
    
    get_CoordinatorsXY(Cx, Cy, size, nameX, nameY) {
      var ra
      var a = 0 // Angle
      var aCalc = 0 //
      var aRad = 0 // Angle in Rad
      var z = 0 // Size z
      var x = 0 // Side x
      var endX = 0 // endX coordinate
      var endY = 0 // endY coordinate

      this.pieCenterAngles.forEach((item, sn) => {
        ra = this.PieStacks[sn] + size
        a = 360 * item
        aCalc = a > 180 ? 360 - a : a
        aRad = (aCalc * Math.PI) / 180
        z = Math.sqrt(2 * ra * ra - 2 * ra * ra * Math.cos(aRad))
        x = aCalc <= 90 ? ra * Math.sin(aRad) : ra * Math.sin(((180 - aCalc) * Math.PI) / 180)
        endY = Math.sqrt(z * z - x * x)
        endX = a <= 180 ? ra + x : ra - x

        this.pieSectors[sn] = {
          ...this.pieSectors[sn],
          ...{
            [nameX]: endX + Cx - ra,
            [nameY]: endY + Cy - ra,
          }
        }
      })
    },
    get_PieChartData(_data) {
      var ra
      var a = 0 // Angle
      var aCalc = 0 //
      var arcSweep = 0
      var aRad = 0 // Angle in Rad
      var z = 0 // Size z
      var x = 0 // Side x
      var endX = 0 // SVG endX coordinate
      var endY = 0 // SVG endY coordinate
      var Ro = 0 // Rotation

      var h_ra
      var h_z = 0 // Size z
      var h_x = 0 // Side x
      var h_endX = 0 // SVG endX coordinate
      var h_endY = 0 // SVG endY coordinate

      var textSpace = 0
      var direct = 0

      _data.forEach((item, sn) => {
        ra = this.PieStacks[sn]
        h_ra = this.Circle.CircleHoleSize // Hole
        a = 360 * (item.value / 100)
        aCalc = a > 180 ? 360 - a : a
        aRad = (aCalc * Math.PI) / 180
        z = Math.sqrt(2 * ra * ra - 2 * ra * ra * Math.cos(aRad))
        h_z = Math.sqrt(2 * h_ra * h_ra - 2 * h_ra * h_ra * Math.cos(aRad)) // Hole
        if (aCalc <= 90) {
          x = ra * Math.sin(aRad)
          h_x = h_ra * Math.sin(aRad) // Hole
        } else {
          x = ra * Math.sin(((180 - aCalc) * Math.PI) / 180)
          h_x = h_ra * Math.sin(((180 - aCalc) * Math.PI) / 180)
        }

        endY = Math.sqrt(z * z - x * x)
        h_endY = Math.sqrt(h_z * h_z - h_x * h_x) // Hole

        if (a <= 180) {
          endX = ra + x
          h_endX = h_ra + h_x
          arcSweep = 0
        } else {
          endX = ra - x
          h_endX = h_ra - h_x
          arcSweep = 1
        }
        if (this.pieCenterAngles[sn] < 0.5) {
          direct = this.Guideline.GuideExtend
          // align = 'start'
          textSpace = 3
        } else {
          direct = -this.Guideline.GuideExtend
          // align = 'end'
          textSpace = -3
        }

        this.pieSectors[sn] = {
          ...this.pieSectors[sn],
          ...{
            angle: a,
            value: item.value,
            title: item.title,
            color: this.SharedColorSet[sn],
            arcSweep: arcSweep,
            Ra: ra,
            endX: endX,
            endY: endY,

            h_Ra: h_ra, // Hole
            h_endX: h_endX, // Hole
            h_endY: h_endY, // Hole
            Ro: Ro,
            direct: direct,
            // align: align,
            textSpace: textSpace,
            stack: this.PieStacks[sn]
          }
        }
        Ro = Ro + a
      })
    },
  }
}
