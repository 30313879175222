export default {
  data: () => ({

    Toggle: {
      status: 'EN',
      labelWidth: 20,
      en: {
        bcolor: '#004D82',
        tcolor: '#fff',
        textx: 26,
        texty: 10,
        slidex: 51,
        slidey: 9,
      },
      cn: {
        bcolor: '#D92129',
        tcolor: '#fff',
        textx: 37,
        texty: 10,
        slidex: 29,
        slidey: 9,
      }      
    },
    Legend:
    [ 
      { CODE: 'P1', NAME: 'P1' , COLOR: '#51B8F2', CHECKED: true,},
      { CODE: 'P2', NAME: 'P2' , COLOR: '#F4E12C', CHECKED: false,},
      { CODE: 'P3', NAME: 'P3' , COLOR: '#E29FE2', CHECKED: false,},
    ],
  }),

  methods: {
    set_Gradients() {

      let defs = this.svg.append('defs')

      let filter = defs.append('filter')
      .attr('id', 'dropshadow')
      filter.append('feGaussianBlur').attr('in', 'SourceAlpha').attr('stdDeviation', 3).attr('result', 'blur')
      filter.append('feOffset').attr('in', 'blur').attr('dx', .3).attr('dy', .3) .attr('result', 'offsetBlur')
      filter.append('feFlood').attr('in', 'offsetBlur').attr('flood-color', '#727272').attr('flood-opacity', '0.3').attr('result', 'offsetColor')
      filter.append('feComposite').attr('in', 'offsetColor').attr('in2', 'offsetBlur').attr('operator', 'in').attr('result', 'offsetBlur')


      let header = defs.append('linearGradient')
      .attr('id', `menu_box`).attr('x1', 0.5).attr('x2', 0.5).attr('y1', 0).attr('y2', 1)

      header.append('stop').attr('stop-color', '#F4F5F6').attr('offset', '0').attr('stop-opacity', 1)
      header.append('stop').attr('stop-color', '#F1F2F3').attr('offset', '0.5').attr('stop-opacity', 1)
      header.append('stop').attr('stop-color', '#E9EAEA').attr('offset', '0.8').attr('stop-opacity', 1)
      header.append('stop').attr('stop-color', '#E1E1E1').attr('offset', '1').attr('stop-opacity', 1)
      
      let legendP3 = defs.append('linearGradient')
      .attr('id', `PACKAGE_P3`).attr('x1', 0.5).attr('x2', 0.5).attr('y1', 0).attr('y2', 1)
      legendP3.append('stop').attr('stop-color', '#E7B8E8').attr('offset', '0').attr('stop-opacity', 1)
      legendP3.append('stop').attr('stop-color', '#B562C4').attr('offset', '1').attr('stop-opacity', 1)

      let legendP2 = defs.append('linearGradient')
      .attr('id', `PACKAGE_P2`).attr('x1', 0.5).attr('x2', 0.5).attr('y1', 0).attr('y2', 1)
      legendP2.append('stop').attr('stop-color', '#F9EB85').attr('offset', '0').attr('stop-opacity', 1)
      legendP2.append('stop').attr('stop-color', '#B5A31F').attr('offset', '1').attr('stop-opacity', 1)

      let legendP1 = defs.append('linearGradient')
      .attr('id', `PACKAGE_P1`).attr('x1', 0.5).attr('x2', 0.5).attr('y1', 0).attr('y2', 1)
      legendP1.append('stop').attr('stop-color', '#73CAEF').attr('offset', '0').attr('stop-opacity', 1)
      legendP1.append('stop').attr('stop-color', '#0780AA').attr('offset', '1').attr('stop-opacity', 1)
    }
  }
}