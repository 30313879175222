import __C from '../primitives/_constant_'
import { AccountApi } from './api'
import Vue from 'vue'
export default class AccountService {
    
  constructor() {
    this.accountApi = new AccountApi()
  }

  signin(creds, callback) {
    //alert(`[ACCOUNT #SERVICE#]`)
    this.accountApi.signin(creds, account => {
      // // login success if there's a jwt token in the response
      if(account.token) {
        localStorage.setItem(__C.LOCAL_STORAGE_NAME.ACCOUNT, JSON.stringify(account))
        if(account.approval == "WAITING") account.error = true
      } else {
        account.error = true
      }
      callback(account)
    })
  }

  signout(callback) {
    // 쿠키에서 토큰 삭제
    Vue.$cookies.remove('token')
    // remove user from local storage to sign user out
    localStorage.removeItem(__C.LOCAL_STORAGE_NAME.ACCOUNT)
    localStorage.removeItem(__C.LOCAL_STORAGE_NAME.TOKEN)
    callback()
  }
}
