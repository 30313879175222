import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({

    iconSet: [
      {code: 'ST010', name: 'SteelCutting',  schedule: 'start',  activity: 'Steel Cutting',       center: 7, width: 16, height: 16},
      {code: 'ST030', name: 'Assembly',      schedule: 'start',  activity: 'Block Assembly',      center: 6, width: 15, height: 50},
      {code: 'ST080', name: 'Crain',         schedule: 'finish', activity: 'Erection Completion', center: 6, width: 20, height: 40},
      {code: 'PI030', name: 'Spool',         schedule: 'start',  activity: 'Spool Install',       center: 6, width: 18, height: 21},
      {code: 'EI060', name: 'Pulling',       schedule: 'start',  activity: 'Cable Pulling',       center: 6, width: 18, height: 20},
      {code: 'MC',    name: 'MC',            schedule: 'finish', activity: 'MC Completion',       center: 6, width: 20, height: 20},
      {code: 'OT090', name: 'RFS',           schedule: 'event',  activity: 'RFS',                 center: 7, width: 25, height: 45},
    ],
  }),

  methods: {
    
    draw_GanttChart() {
      
      // Timeline Seperator
      let baseGap = 35
      let ModuleLine = 50

      this.dataSet.forEach(data => {

        let barCharts = this.modules2020
        .append('g')
        .attr('transform', `translate(0, ${ModuleLine})`)

        this.iconSet.forEach((icon,i) => {

          if (data[`${icon.code}_ASD`] != null && data[`${icon.code}_AFD`] != null) icon.status = 'Completion'
          if (data[`${icon.code}_ASD`] != null && data[`${icon.code}_AFD`] == null) icon.status = 'Progress'
          if (data[`${icon.code}_ASD`] == null && data[`${icon.code}_AFD`] == null) icon.status = 'NotStart'
          icon.planProg = data[`${icon.code}_P`]
          icon.actualProg = data[`${icon.code}_A`]

          let PS = new Date(data[`${icon.code}_ESD`])
          let PF = new Date(data[`${icon.code}_EFD`])
          let AS = new Date(data[`${icon.code}_ASD`])
          let AF = new Date(data[`${icon.code}_AFD`])
          let StartLine = (i * baseGap) + 40
          // console.log(
          //   PS,
          //   PF,
          //   AS,
          //   AF,
          //   '---',
          // )
          // Icon for Milestones
          let schedule = ''
          
          if (icon.status == 'Completion') {
            if (icon.schedule == 'start') schedule = this.timeScale(AS); else schedule = this.timeScale(AF)
          } else {
            if (icon.schedule == 'start') schedule = this.timeScale(PS); else schedule = this.timeScale(PF)
          }

          if (icon.name == 'RFS') {
            if (icon.status == 'Progress') schedule = this.timeScale(AS); else schedule = this.timeScale(PS)
          }
          
          barCharts
          .append('image') 
          .attr('xlink:href', require(`../../../../../../src/assets/svg/icons/${icon.name}.svg`))
          .attr('x', schedule - icon.width/2).attr('y', - icon.height).attr('width', icon.width).attr('height', icon.height)

          barCharts
          .append('text') 
          .attr('x', schedule).attr('y', -icon.height - 20).style('font-size', 9).style('fill', '#333').attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging').text(icon.activity)
          barCharts
          .append('text')
          .attr('x', schedule).attr('y', -icon.height - 9).style('font-size', 8.5).style('fill', '#bcbcbc').attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging')
          .text(() => {
            if (icon.schedule == 'start') {

              if (icon.status == 'Completion') return d3.timeFormat('%Y-%m-%d')(AS)
              else return d3.timeFormat('%Y-%m-%d')(PS)

            } else if (icon.schedule == 'finish') {

              if (icon.status == 'Completion') return d3.timeFormat('%Y-%m-%d')(AF)
              else return d3.timeFormat('%Y-%m-%d')(PF)

            }

            if (icon.activity == 'RFS') {

              if (icon.status == 'Progress') return d3.timeFormat('%Y-%m-%d')(AS)
              else return d3.timeFormat('%Y-%m-%d')(PS)
            }
          })

          barCharts
          .append('line').attr('x1', this.timeScale(PS)).attr('x2', this.timeScale(PS)).attr('y1', StartLine).attr('y2', 10).attr('stroke', '#bcbcbc').attr('stroke-width', 0.3)
          barCharts
          .append('line').attr('x1', this.timeScale(PF)).attr('x2', this.timeScale(PF)).attr('y1', StartLine).attr('y2', 10).attr('stroke', '#bcbcbc').attr('stroke-width', 0.3)
          


          // draw Actual bar chart & Date ------------------------------------------------------------------------------------------------------------
          if (icon.schedule !== 'event') {

            this.draw_PlanChart(barCharts, icon.activity, StartLine, PS, PF) // Plan

            if (icon.status == 'Completion') {
              this.draw_Actual_Completion(barCharts, icon.activity, StartLine, AS, AF) // --->
            }
            if (icon.status == 'Progress') {
              this.draw_Actual_Progress(barCharts, icon, StartLine, AS) // --->
            }

          } else {
            barCharts
            .append('text') // Activity
            .attr('x', this.timeScale(PS)).attr('y', StartLine + 2).style('font-size', 9).style('fill', '#333').attr('text-anchor', 'middle').attr('alignment-baseline', 'ideographic')
            .text(icon.activity)
            
            barCharts
            .append('text') // Plan Start Date
            .attr('x', this.timeScale(PS)).attr('y', StartLine + 9 ).style('font-size', 9).style('fill', '#bcbcbc').attr('text-anchor', 'middle')
            .text(`${d3.timeFormat('%d/%m')(PS)}`)

            barCharts
            .append('image') 
            .attr('xlink:href', require(`../../../../../../src/assets/svg/icons/event_Gray.svg`))
            .attr('x', this.timeScale(PS) -6).attr('y', StartLine + 10).attr('width', 12).attr('height', 12)
              
            if (icon.status !== 'Progress') this.draw_ModuleInfo(barCharts, data, this.timeScale(PS) + 10, StartLine + 10)

            if (icon.status == 'Progress') {
              barCharts
              .append('text') // Activity
              .attr('x', this.timeScale(AS)).attr('y', StartLine + 2).style('font-size', 9).style('fill', '#333').attr('text-anchor', 'middle').attr('alignment-baseline', 'ideographic')
              .text(icon.activity)

              barCharts
              .append('text') // Actual Start
              .attr('x', this.timeScale(AS)).attr('y', StartLine + 9 ).style('font-size', 9).style('fill', '#818181').attr('text-anchor', 'middle')
              .text(`${d3.timeFormat('%d/%m')(AS)}`)

              barCharts
              .append('image') 
              .attr('xlink:href', require(`../../../../../../src/assets/svg/icons/event_Green.svg`))
              .attr('x', this.timeScale(AS) -6).attr('y', StartLine + 10).attr('width', 12).attr('height', 12)
      
              this.draw_ModuleInfo(barCharts, data, this.timeScale(AS), StartLine + 10)
            }

          }
          StartLine += baseGap
        })
        ModuleLine += 400
      })
    }, 




    draw_PlanChart(seletion, activity, StartLine, PS, PF) {
      seletion
      .append('rect') // BarChart
      .attr('x', this.timeScale(PS)).attr('y', StartLine).attr('width', this.timeScale(PF)-this.timeScale(PS)).attr('height', 3).attr('fill', '#E0E0DF') 
      seletion
      .append('text') // Start Date
      .attr('x', this.timeScale(PS)).attr('y', StartLine + 0 ).style('font-size', 9).style('fill', '#bcbcbc').attr('text-anchor', 'start').attr('alignment-baseline', 'ideographic').text(`${d3.timeFormat('%d/%m')(PS)}`)
      seletion
      .append('text') // Finish Date
      .attr('x', this.timeScale(PF)).attr('y', StartLine + 0 ).style('font-size', 9).style('fill', '#bcbcbc').attr('text-anchor', 'end').attr('alignment-baseline', 'ideographic').text(`${d3.timeFormat('%d/%m')(PF)}`)
    },


    draw_Actual_Completion(seletion, activity, StartLine, AS, AF) {
      seletion
      .append('text') // Activity
      .attr('x', this.timeScale(AS)-2).attr('y', StartLine + 13).style('font-size', 9).style('fill', '#333').attr('text-anchor', 'end').attr('alignment-baseline', 'ideographic')
      .text(activity)

      seletion
      .append('rect') // Barhart
      .attr('x', this.timeScale(AS)).attr('y', StartLine + 4).attr('rx', 4).attr('ry', 4).attr('width', 0).attr('height', 8).attr('fill', `url(#${this.localId}__LinearA4${'YellowGreen'}0)`).attr('opacity', 0.7)
      .transition().duration(500).attr('width', this.timeScale(AF)-this.timeScale(AS))

      seletion
      .append('text') // Actual Start Date & Finish Date
      .attr('x', this.timeScale(AF)).attr('y', StartLine + 20 ).style('font-size', 9).style('fill', '#818181').attr('text-anchor', 'end')
      .text(`${d3.timeFormat('%d/%m')(AS)} ~ ${d3.timeFormat('%d/%m')(AF)}`)

      seletion
      .append('text') // Progress
      .attr('x', this.timeScale(AF)+1).attr('y', StartLine + 5).style('font-size', 9).style('fill', '#CDDC37').attr('text-anchor', 'start').attr('alignment-baseline', 'hanging')
      .text('100%')
    },


    draw_Actual_Progress(seletion, d, StartLine, AS, modEndDate) {
      let endDate = modEndDate < new Date() ? modEndDate : new Date()

      seletion
      .append('text') // Activity
      .attr('x', this.timeScale(AS)-2).attr('y', StartLine + 13).style('font-size', 9).style('fill', '#333').attr('text-anchor', 'end').attr('alignment-baseline', 'ideographic')
      .text(d.activity)
      
      seletion
      .append('path')
      .attr('d', `
                  M${this.timeScale(AS)}, ${StartLine + 4}
                  H${this.timeScale(AS) - 4}
                  L${this.timeScale(AS)}, ${StartLine + 8}
                  L${this.timeScale(AS) - 4}, ${StartLine + 12}
                  L${this.timeScale(AS)}, ${StartLine + 12}
                  L${this.timeScale(AS)+4}, ${StartLine + 8} Z
      `)
      .attr('fill', () => {
        if (d.actualProg >= d.planProg) {return `url(#${this.localId}__LinearA4${'LightBlue'}0)` }
        else {return `url(#${this.localId}__LinearA4${'Pink'}0)`}
      })
      .attr('opacity', 0.7)
      .transition().duration(500)
      .attr('d', `
                  M${this.timeScale(AS)}, ${StartLine + 4}
                  H${this.timeScale(endDate) - 4}
                  L${this.timeScale(endDate)}, ${StartLine + 8}
                  L${this.timeScale(endDate) - 4}, ${StartLine + 12}
                  L${this.timeScale(AS)}, ${StartLine + 12}
                  L${this.timeScale(AS)+4}, ${StartLine + 8} Z
      `)

      seletion
      .append('text') // Progress
      .attr('x', this.timeScale(endDate)+1).attr('y', StartLine + 5).style('font-size', 9).attr('text-anchor', 'start').attr('alignment-baseline', 'hanging')
      .style('fill', () => {
        if (d.actualProg >= d.planProg) {return '#83D2F5' }
        else {return '#F7BACF'}
      })
      .text(`${d.actualProg}%`)
    },
  }
}
