import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  methods: {
    drawChart() {

      this.svg
      .append('image')
      .attr('xlink:href', require('../primitives/yard.jpg'))
      // .style('height', '3000px')
      .style('width', '2000px')
    },
  }
}