import * as THREE from 'three'

export default {
  methods: {
    createBox(scene, id, position, color) {
      const geometry = new THREE.BoxGeometry(1, 1, 1)
      const material = new THREE.MeshBasicMaterial({ color: color})
      const box = new THREE.Mesh(geometry, material)

      box.userData.id = id
      box.position.set(position.x, position.y, position.z)

      scene.add(box)
    },

    // 객체를 ID로 찾는 함수
    findObjectById(scene, id) {
        return scene.children.find(child => child.userData.id === id);
    },

    // ID를 찾아서 해당하는 객체를 제거하는 함수
    removeObjectById(scene, id) {
        const object = this.findObjectById(scene, id);
        if (object) {
            scene.remove(object);
            // 메모리 해제를 위해 직접적으로 객체를 삭제
            object.geometry.dispose();
            object.material.dispose();
        }
    },

    resetColors() {
      this.scene.traverse((child) => {
        if (child.isMesh && this.originalColors[child.uuid]) {
          child.material.color.copy(this.originalColors[child.uuid]) // 원래 색상으로 되돌림
        }
      })
    },

    hideObjects() {
      if (this.hide == 'ON') {
        this.hide = 'OFF'
        document.getElementById('hide_Btn_box').setAttribute('fill', 'pink')
      } else {
        this.hide = 'ON'
        document.getElementById('hide_Btn_box').setAttribute('fill', 'lightblue')
      }
      console.log('Hide status: ', this.hide)
    },

    unhideObjects() {
      this.scene.traverse((child) => {
        if (child.isMesh) {
          child.visible = true // 모든 오브젝트를 다시 보이게 함
        }
      })
    },

    onMouseMove(event) {
      if (this.hide == 'ON') {
        this.mouse.x = (event.offsetX / this.SceneWidth) * 2 - 1
        this.mouse.y = -(event.offsetY / this.SceneHeight) * 2 + 1

        this.raycaster.setFromCamera(this.mouse, this.camera)

        const intersects = this.raycaster.intersectObjects(this.scene.children, true)
        if (intersects.length > 0) {
            this.$refs.threeContainer.style.cursor = 'pointer' // 오브젝트 위에 마우스가 있을 때 커서를 손 모양으로 변경
        } else {
            this.$refs.threeContainer.style.cursor = 'auto' // 그렇지 않으면 기본 커서로 변경
        }
      }
    },

    onMouseClick(event) {
      if (this.hide == 'ON') {
        this.mouse.x = (event.offsetX / this.SceneWidth) * 2 - 1
        this.mouse.y = -(event.offsetY / this.SceneHeight) * 2 + 1
  
        this.raycaster.setFromCamera(this.mouse, this.camera)
  
        const intersects = this.raycaster.intersectObjects(this.scene.children, true)
        if (intersects.length > 0) {
            const intersectedObject = intersects[0].object
            intersectedObject.visible = false // 클릭된 오브젝트 숨기기
        }
      }
    },

    changeObjColor(obj) {
      this.scene.traverse((child) => {
        if (child.name == obj) {
          child.material.color.set(Math.random() * 0xffffff) // 임의의 색상으로 변경
        }
      })
    },

    moveObj(obj) {
      this.scene.traverse((child) => {
        if (child.name) {
          if (child.type == obj) {
            child.position.set(child.position.x * 2, child.position.y * 2, child.position.z * 2)
          }
        }
      })
    },

    resetPosition() {
      this.scene.traverse((child) => {
        if (child.name) {
          if (!this.originPosition[child.name].equals(child.position)) {
            child.position.set(this.originPosition[child.name].x, this.originPosition[child.name].y, this.originPosition[child.name].z)
          }
        }
      })
    },

    disposeFBXModel(model) {
      this.scene.remove(model)
      
      model.traverse((node) => {
        if (node.isMesh) {
          this.disposeMesh(node)
        }
      })

      this.renderer.dispose()

      cancelAnimationFrame(this.animationFrameId)

      window.removeEventListener('resize', this.onWindowResize)

      this.scene.clear()
    },

    disposeMesh(mesh) {
      if (mesh.geometry) {
          mesh.geometry.dispose()
      }
      if (mesh.material) {
        if (Array.isArray(mesh.material)) {
            mesh.material.forEach((material) => {
              if (material.map) material.map.dispose()
              material.dispose()
            })
        } else {
            if (mesh.material.map) mesh.material.map.dispose()
            mesh.material.dispose()
        }
      }
    }
  }
}