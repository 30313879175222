import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {

    Draw_Charts() {
      // Draw Text Data
      let systemInfo = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 20.5)`)

      let drawY = 0
      this.dataSet.forEach((d,i) => {
        
        systemInfo
        .append('text')
        .attr('transform', `translate(${150}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 11)
        .style('fill', '#757575').style('text-anchor', 'end').style('alignment-baseline', 'middle').text(d.title)

        if (i == 4 || i == 5) {
          systemInfo
          .append('rect')
          .attr('transform', `translate(155, ${drawY-9})`)
          .style('width', 65).style('height', 16).style('fill', '#E0E0DF').attr('rx', 0).attr('ry', 0).style('opacity', 0.3)

          systemInfo
          .append('text')
          .attr('transform', `translate(${160}, ${drawY})`)
          .style('font-family', 'roboto').style('font-size', 11)
          .style('fill', '#44A9DF').style('text-anchor', 'start').style('alignment-baseline', 'middle').text(d.value)  

          drawY += 2
        } else {
          systemInfo
          .append('text')
          .attr('transform', `translate(${160}, ${drawY})`)
          .style('font-family', 'roboto').style('font-size', 11)
          .style('fill', '#333').style('text-anchor', 'start').style('alignment-baseline', 'middle').text(d.value)  
        }
        

        if (i==1 || i ==3 || i == 5) drawY += 24; else drawY += 16
      })



    }
  }
}
