import gmx_zMixins         from '../../../../../includes/Jmixins/zMixins'

// Locally declared common Data-Props & SVG Drawing Support

import lmx_Styles                 from '../primitives/styles'
import lmx_Watching               from './Watching' // Reactive Events
import set_LocalValues            from '././set_LocalValues'
import draw_Forms                 from './draw_Forms'
import draw_Timeline              from './draw_Timeline'
import draw_Legends               from './draw_Legends'
import func_Controller            from './func_Controller'
import draw_Charts                from './draw_Charts'




export default {

  mixins: [
    gmx_zMixins, 

    // Local Mixins

    lmx_Styles,
    lmx_Watching,
    set_LocalValues,
    draw_Forms,
    draw_Timeline,
    draw_Legends,
    func_Controller,
    draw_Charts,
  ],
}
